'use strict';

module.exports = {
    lexi: [
		{"word":"a","word_nosc":"a","lemma":"avoir","pos":"VER"} ,
		{"word":"abaissa","word_nosc":"abaissa","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaissai","word_nosc":"abaissai","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaissaient","word_nosc":"abaissaient","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaissait","word_nosc":"abaissait","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaissant","word_nosc":"abaissant","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaisse","word_nosc":"abaisse","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaissent","word_nosc":"abaissent","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaisser","word_nosc":"abaisser","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaissera","word_nosc":"abaissera","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaisserai","word_nosc":"abaisserai","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaisseraient","word_nosc":"abaisseraient","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaisserais","word_nosc":"abaisserais","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaisserait","word_nosc":"abaisserait","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaisses","word_nosc":"abaisses","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaissez","word_nosc":"abaissez","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaissons","word_nosc":"abaissons","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaissèrent","word_nosc":"abaisserent","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaissé","word_nosc":"abaisse","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaissée","word_nosc":"abaissee","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaissées","word_nosc":"abaissees","lemma":"abaisser","pos":"VER"} ,
		{"word":"abaissés","word_nosc":"abaisses","lemma":"abaisser","pos":"VER"} ,
		{"word":"abandonna","word_nosc":"abandonna","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnai","word_nosc":"abandonnai","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnaient","word_nosc":"abandonnaient","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnais","word_nosc":"abandonnais","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnait","word_nosc":"abandonnait","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnant","word_nosc":"abandonnant","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnas","word_nosc":"abandonnas","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonne","word_nosc":"abandonne","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnent","word_nosc":"abandonnent","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonner","word_nosc":"abandonner","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnera","word_nosc":"abandonnera","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnerai","word_nosc":"abandonnerai","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonneraient","word_nosc":"abandonneraient","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnerais","word_nosc":"abandonnerais","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnerait","word_nosc":"abandonnerait","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonneras","word_nosc":"abandonneras","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnerez","word_nosc":"abandonnerez","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonneriez","word_nosc":"abandonneriez","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnerions","word_nosc":"abandonnerions","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnerons","word_nosc":"abandonnerons","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonneront","word_nosc":"abandonneront","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnes","word_nosc":"abandonnes","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnez","word_nosc":"abandonnez","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonniez","word_nosc":"abandonniez","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnions","word_nosc":"abandonnions","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnons","word_nosc":"abandonnons","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnâmes","word_nosc":"abandonnames","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnât","word_nosc":"abandonnat","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnèrent","word_nosc":"abandonnerent","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonné","word_nosc":"abandonne","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnée","word_nosc":"abandonnee","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnées","word_nosc":"abandonnees","lemma":"abandonner","pos":"VER"} ,
		{"word":"abandonnés","word_nosc":"abandonnes","lemma":"abandonner","pos":"VER"} ,
		{"word":"abasourdi","word_nosc":"abasourdi","lemma":"abasourdir","pos":"VER"} ,
		{"word":"abasourdie","word_nosc":"abasourdie","lemma":"abasourdir","pos":"VER"} ,
		{"word":"abasourdir","word_nosc":"abasourdir","lemma":"abasourdir","pos":"VER"} ,
		{"word":"abasourdis","word_nosc":"abasourdis","lemma":"abasourdir","pos":"VER"} ,
		{"word":"abat","word_nosc":"abat","lemma":"abattre","pos":"VER"} ,
		{"word":"abats","word_nosc":"abats","lemma":"abattre","pos":"VER"} ,
		{"word":"abattaient","word_nosc":"abattaient","lemma":"abattre","pos":"VER"} ,
		{"word":"abattais","word_nosc":"abattais","lemma":"abattre","pos":"VER"} ,
		{"word":"abattait","word_nosc":"abattait","lemma":"abattre","pos":"VER"} ,
		{"word":"abattant","word_nosc":"abattant","lemma":"abattre","pos":"VER"} ,
		{"word":"abatte","word_nosc":"abatte","lemma":"abattre","pos":"VER"} ,
		{"word":"abattent","word_nosc":"abattent","lemma":"abattre","pos":"VER"} ,
		{"word":"abattes","word_nosc":"abattes","lemma":"abattre","pos":"VER"} ,
		{"word":"abattez","word_nosc":"abattez","lemma":"abattre","pos":"VER"} ,
		{"word":"abattiez","word_nosc":"abattiez","lemma":"abattre","pos":"VER"} ,
		{"word":"abattions","word_nosc":"abattions","lemma":"abattre","pos":"VER"} ,
		{"word":"abattirent","word_nosc":"abattirent","lemma":"abattre","pos":"VER"} ,
		{"word":"abattis","word_nosc":"abattis","lemma":"abattre","pos":"VER"} ,
		{"word":"abattit","word_nosc":"abattit","lemma":"abattre","pos":"VER"} ,
		{"word":"abattons","word_nosc":"abattons","lemma":"abattre","pos":"VER"} ,
		{"word":"abattra","word_nosc":"abattra","lemma":"abattre","pos":"VER"} ,
		{"word":"abattrai","word_nosc":"abattrai","lemma":"abattre","pos":"VER"} ,
		{"word":"abattraient","word_nosc":"abattraient","lemma":"abattre","pos":"VER"} ,
		{"word":"abattrais","word_nosc":"abattrais","lemma":"abattre","pos":"VER"} ,
		{"word":"abattrait","word_nosc":"abattrait","lemma":"abattre","pos":"VER"} ,
		{"word":"abattras","word_nosc":"abattras","lemma":"abattre","pos":"VER"} ,
		{"word":"abattre","word_nosc":"abattre","lemma":"abattre","pos":"VER"} ,
		{"word":"abattrez","word_nosc":"abattrez","lemma":"abattre","pos":"VER"} ,
		{"word":"abattriez","word_nosc":"abattriez","lemma":"abattre","pos":"VER"} ,
		{"word":"abattrons","word_nosc":"abattrons","lemma":"abattre","pos":"VER"} ,
		{"word":"abattront","word_nosc":"abattront","lemma":"abattre","pos":"VER"} ,
		{"word":"abattu","word_nosc":"abattu","lemma":"abattre","pos":"VER"} ,
		{"word":"abattue","word_nosc":"abattue","lemma":"abattre","pos":"VER"} ,
		{"word":"abattues","word_nosc":"abattues","lemma":"abattre","pos":"VER"} ,
		{"word":"abattus","word_nosc":"abattus","lemma":"abattre","pos":"VER"} ,
		{"word":"abdiqua","word_nosc":"abdiqua","lemma":"abdiquer","pos":"VER"} ,
		{"word":"abdiquai","word_nosc":"abdiquai","lemma":"abdiquer","pos":"VER"} ,
		{"word":"abdiquaient","word_nosc":"abdiquaient","lemma":"abdiquer","pos":"VER"} ,
		{"word":"abdiquais","word_nosc":"abdiquais","lemma":"abdiquer","pos":"VER"} ,
		{"word":"abdiquait","word_nosc":"abdiquait","lemma":"abdiquer","pos":"VER"} ,
		{"word":"abdiquant","word_nosc":"abdiquant","lemma":"abdiquer","pos":"VER"} ,
		{"word":"abdique","word_nosc":"abdique","lemma":"abdiquer","pos":"VER"} ,
		{"word":"abdiquer","word_nosc":"abdiquer","lemma":"abdiquer","pos":"VER"} ,
		{"word":"abdiquera","word_nosc":"abdiquera","lemma":"abdiquer","pos":"VER"} ,
		{"word":"abdiquerais","word_nosc":"abdiquerais","lemma":"abdiquer","pos":"VER"} ,
		{"word":"abdiquerait","word_nosc":"abdiquerait","lemma":"abdiquer","pos":"VER"} ,
		{"word":"abdiquez","word_nosc":"abdiquez","lemma":"abdiquer","pos":"VER"} ,
		{"word":"abdiqué","word_nosc":"abdique","lemma":"abdiquer","pos":"VER"} ,
		{"word":"abdiquée","word_nosc":"abdiquee","lemma":"abdiquer","pos":"VER"} ,
		{"word":"aberrant","word_nosc":"aberrant","lemma":"aberrer","pos":"VER"} ,
		{"word":"abhorrais","word_nosc":"abhorrais","lemma":"abhorrer","pos":"VER"} ,
		{"word":"abhorrait","word_nosc":"abhorrait","lemma":"abhorrer","pos":"VER"} ,
		{"word":"abhorrant","word_nosc":"abhorrant","lemma":"abhorrer","pos":"VER"} ,
		{"word":"abhorre","word_nosc":"abhorre","lemma":"abhorrer","pos":"VER"} ,
		{"word":"abhorrer","word_nosc":"abhorrer","lemma":"abhorrer","pos":"VER"} ,
		{"word":"abhorrez","word_nosc":"abhorrez","lemma":"abhorrer","pos":"VER"} ,
		{"word":"abhorré","word_nosc":"abhorre","lemma":"abhorrer","pos":"VER"} ,
		{"word":"abhorrée","word_nosc":"abhorree","lemma":"abhorrer","pos":"VER"} ,
		{"word":"abhorrés","word_nosc":"abhorres","lemma":"abhorrer","pos":"VER"} ,
		{"word":"abjurant","word_nosc":"abjurant","lemma":"abjurer","pos":"VER"} ,
		{"word":"abjure","word_nosc":"abjure","lemma":"abjurer","pos":"VER"} ,
		{"word":"abjurer","word_nosc":"abjurer","lemma":"abjurer","pos":"VER"} ,
		{"word":"abjurez","word_nosc":"abjurez","lemma":"abjurer","pos":"VER"} ,
		{"word":"abjurons","word_nosc":"abjurons","lemma":"abjurer","pos":"VER"} ,
		{"word":"abjuré","word_nosc":"abjure","lemma":"abjurer","pos":"VER"} ,
		{"word":"aboie","word_nosc":"aboie","lemma":"aboyer","pos":"VER"} ,
		{"word":"aboient","word_nosc":"aboient","lemma":"aboyer","pos":"VER"} ,
		{"word":"aboiera","word_nosc":"aboiera","lemma":"aboyer","pos":"VER"} ,
		{"word":"aboierait","word_nosc":"aboierait","lemma":"aboyer","pos":"VER"} ,
		{"word":"aboieront","word_nosc":"aboieront","lemma":"aboyer","pos":"VER"} ,
		{"word":"aboies","word_nosc":"aboies","lemma":"aboyer","pos":"VER"} ,
		{"word":"aboli","word_nosc":"aboli","lemma":"abolir","pos":"VER"} ,
		{"word":"abolie","word_nosc":"abolie","lemma":"abolir","pos":"VER"} ,
		{"word":"abolies","word_nosc":"abolies","lemma":"abolir","pos":"VER"} ,
		{"word":"abolir","word_nosc":"abolir","lemma":"abolir","pos":"VER"} ,
		{"word":"abolira","word_nosc":"abolira","lemma":"abolir","pos":"VER"} ,
		{"word":"abolirait","word_nosc":"abolirait","lemma":"abolir","pos":"VER"} ,
		{"word":"aboliras","word_nosc":"aboliras","lemma":"abolir","pos":"VER"} ,
		{"word":"abolis","word_nosc":"abolis","lemma":"abolir","pos":"VER"} ,
		{"word":"abolissaient","word_nosc":"abolissaient","lemma":"abolir","pos":"VER"} ,
		{"word":"abolissais","word_nosc":"abolissais","lemma":"abolir","pos":"VER"} ,
		{"word":"abolissait","word_nosc":"abolissait","lemma":"abolir","pos":"VER"} ,
		{"word":"abolissant","word_nosc":"abolissant","lemma":"abolir","pos":"VER"} ,
		{"word":"abolissent","word_nosc":"abolissent","lemma":"abolir","pos":"VER"} ,
		{"word":"abolissions","word_nosc":"abolissions","lemma":"abolir","pos":"VER"} ,
		{"word":"abolissons","word_nosc":"abolissons","lemma":"abolir","pos":"VER"} ,
		{"word":"abolit","word_nosc":"abolit","lemma":"abolir","pos":"VER"} ,
		{"word":"abominaient","word_nosc":"abominaient","lemma":"abominer","pos":"VER"} ,
		{"word":"abomine","word_nosc":"abomine","lemma":"abominer","pos":"VER"} ,
		{"word":"abominer","word_nosc":"abominer","lemma":"abominer","pos":"VER"} ,
		{"word":"abonda","word_nosc":"abonda","lemma":"abonder","pos":"VER"} ,
		{"word":"abondai","word_nosc":"abondai","lemma":"abonder","pos":"VER"} ,
		{"word":"abondaient","word_nosc":"abondaient","lemma":"abonder","pos":"VER"} ,
		{"word":"abondais","word_nosc":"abondais","lemma":"abonder","pos":"VER"} ,
		{"word":"abondait","word_nosc":"abondait","lemma":"abonder","pos":"VER"} ,
		{"word":"abondant","word_nosc":"abondant","lemma":"abonder","pos":"VER"} ,
		{"word":"abondassent","word_nosc":"abondassent","lemma":"abonder","pos":"VER"} ,
		{"word":"abonde","word_nosc":"abonde","lemma":"abonder","pos":"VER"} ,
		{"word":"abondent","word_nosc":"abondent","lemma":"abonder","pos":"VER"} ,
		{"word":"abonder","word_nosc":"abonder","lemma":"abonder","pos":"VER"} ,
		{"word":"abondé","word_nosc":"abonde","lemma":"abonder","pos":"VER"} ,
		{"word":"abonnai","word_nosc":"abonnai","lemma":"abonner","pos":"VER"} ,
		{"word":"abonnant","word_nosc":"abonnant","lemma":"abonner","pos":"VER"} ,
		{"word":"abonne","word_nosc":"abonne","lemma":"abonner","pos":"VER"} ,
		{"word":"abonner","word_nosc":"abonner","lemma":"abonner","pos":"VER"} ,
		{"word":"abonnez","word_nosc":"abonnez","lemma":"abonner","pos":"VER"} ,
		{"word":"abonnât","word_nosc":"abonnat","lemma":"abonner","pos":"VER"} ,
		{"word":"abonné","word_nosc":"abonne","lemma":"abonner","pos":"VER"} ,
		{"word":"abonnée","word_nosc":"abonnee","lemma":"abonner","pos":"VER"} ,
		{"word":"abonnées","word_nosc":"abonnees","lemma":"abonner","pos":"VER"} ,
		{"word":"abonnés","word_nosc":"abonnes","lemma":"abonner","pos":"VER"} ,
		{"word":"aborda","word_nosc":"aborda","lemma":"aborder","pos":"VER"} ,
		{"word":"abordai","word_nosc":"abordai","lemma":"aborder","pos":"VER"} ,
		{"word":"abordaient","word_nosc":"abordaient","lemma":"aborder","pos":"VER"} ,
		{"word":"abordais","word_nosc":"abordais","lemma":"aborder","pos":"VER"} ,
		{"word":"abordait","word_nosc":"abordait","lemma":"aborder","pos":"VER"} ,
		{"word":"abordant","word_nosc":"abordant","lemma":"aborder","pos":"VER"} ,
		{"word":"aborde","word_nosc":"aborde","lemma":"aborder","pos":"VER"} ,
		{"word":"abordent","word_nosc":"abordent","lemma":"aborder","pos":"VER"} ,
		{"word":"aborder","word_nosc":"aborder","lemma":"aborder","pos":"VER"} ,
		{"word":"abordera","word_nosc":"abordera","lemma":"aborder","pos":"VER"} ,
		{"word":"aborderai","word_nosc":"aborderai","lemma":"aborder","pos":"VER"} ,
		{"word":"aborderaient","word_nosc":"aborderaient","lemma":"aborder","pos":"VER"} ,
		{"word":"aborderais","word_nosc":"aborderais","lemma":"aborder","pos":"VER"} ,
		{"word":"aborderait","word_nosc":"aborderait","lemma":"aborder","pos":"VER"} ,
		{"word":"aborderiez","word_nosc":"aborderiez","lemma":"aborder","pos":"VER"} ,
		{"word":"aborderions","word_nosc":"aborderions","lemma":"aborder","pos":"VER"} ,
		{"word":"aborderons","word_nosc":"aborderons","lemma":"aborder","pos":"VER"} ,
		{"word":"aborderont","word_nosc":"aborderont","lemma":"aborder","pos":"VER"} ,
		{"word":"abordes","word_nosc":"abordes","lemma":"aborder","pos":"VER"} ,
		{"word":"abordez","word_nosc":"abordez","lemma":"aborder","pos":"VER"} ,
		{"word":"abordiez","word_nosc":"abordiez","lemma":"aborder","pos":"VER"} ,
		{"word":"abordions","word_nosc":"abordions","lemma":"aborder","pos":"VER"} ,
		{"word":"abordons","word_nosc":"abordons","lemma":"aborder","pos":"VER"} ,
		{"word":"abordâmes","word_nosc":"abordames","lemma":"aborder","pos":"VER"} ,
		{"word":"abordèrent","word_nosc":"aborderent","lemma":"aborder","pos":"VER"} ,
		{"word":"abordé","word_nosc":"aborde","lemma":"aborder","pos":"VER"} ,
		{"word":"abordée","word_nosc":"abordee","lemma":"aborder","pos":"VER"} ,
		{"word":"abordées","word_nosc":"abordees","lemma":"aborder","pos":"VER"} ,
		{"word":"abordés","word_nosc":"abordes","lemma":"aborder","pos":"VER"} ,
		{"word":"abouchaient","word_nosc":"abouchaient","lemma":"aboucher","pos":"VER"} ,
		{"word":"abouchais","word_nosc":"abouchais","lemma":"aboucher","pos":"VER"} ,
		{"word":"abouchait","word_nosc":"abouchait","lemma":"aboucher","pos":"VER"} ,
		{"word":"abouchant","word_nosc":"abouchant","lemma":"aboucher","pos":"VER"} ,
		{"word":"aboucher","word_nosc":"aboucher","lemma":"aboucher","pos":"VER"} ,
		{"word":"abouché","word_nosc":"abouche","lemma":"aboucher","pos":"VER"} ,
		{"word":"abouchée","word_nosc":"abouchee","lemma":"aboucher","pos":"VER"} ,
		{"word":"abouchés","word_nosc":"abouches","lemma":"aboucher","pos":"VER"} ,
		{"word":"aboule","word_nosc":"aboule","lemma":"abouler","pos":"VER"} ,
		{"word":"abouler","word_nosc":"abouler","lemma":"abouler","pos":"VER"} ,
		{"word":"aboules","word_nosc":"aboules","lemma":"abouler","pos":"VER"} ,
		{"word":"aboulez","word_nosc":"aboulez","lemma":"abouler","pos":"VER"} ,
		{"word":"aboutait","word_nosc":"aboutait","lemma":"abouter","pos":"VER"} ,
		{"word":"abouti","word_nosc":"abouti","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutie","word_nosc":"aboutie","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutir","word_nosc":"aboutir","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutira","word_nosc":"aboutira","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutirai","word_nosc":"aboutirai","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutiraient","word_nosc":"aboutiraient","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutirais","word_nosc":"aboutirais","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutirait","word_nosc":"aboutirait","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutiras","word_nosc":"aboutiras","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutirent","word_nosc":"aboutirent","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutirons","word_nosc":"aboutirons","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutiront","word_nosc":"aboutiront","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutis","word_nosc":"aboutis","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutissaient","word_nosc":"aboutissaient","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutissait","word_nosc":"aboutissait","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutissant","word_nosc":"aboutissant","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutisse","word_nosc":"aboutisse","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutissent","word_nosc":"aboutissent","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutissez","word_nosc":"aboutissez","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutissiez","word_nosc":"aboutissiez","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutissions","word_nosc":"aboutissions","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutit","word_nosc":"aboutit","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboutonnai","word_nosc":"aboutonnai","lemma":"aboutonner","pos":"VER"} ,
		{"word":"aboutons","word_nosc":"aboutons","lemma":"abouter","pos":"VER"} ,
		{"word":"aboutîmes","word_nosc":"aboutimes","lemma":"aboutir","pos":"VER"} ,
		{"word":"aboya","word_nosc":"aboya","lemma":"aboyer","pos":"VER"} ,
		{"word":"aboyaient","word_nosc":"aboyaient","lemma":"aboyer","pos":"VER"} ,
		{"word":"aboyait","word_nosc":"aboyait","lemma":"aboyer","pos":"VER"} ,
		{"word":"aboyant","word_nosc":"aboyant","lemma":"aboyer","pos":"VER"} ,
		{"word":"aboyer","word_nosc":"aboyer","lemma":"aboyer","pos":"VER"} ,
		{"word":"aboyons","word_nosc":"aboyons","lemma":"aboyer","pos":"VER"} ,
		{"word":"aboyèrent","word_nosc":"aboyerent","lemma":"aboyer","pos":"VER"} ,
		{"word":"aboyé","word_nosc":"aboye","lemma":"aboyer","pos":"VER"} ,
		{"word":"abracadabré","word_nosc":"abracadabre","lemma":"abracadabrer","pos":"VER"} ,
		{"word":"abrasé","word_nosc":"abrase","lemma":"abraser","pos":"VER"} ,
		{"word":"abreuva","word_nosc":"abreuva","lemma":"abreuver","pos":"VER"} ,
		{"word":"abreuvaient","word_nosc":"abreuvaient","lemma":"abreuver","pos":"VER"} ,
		{"word":"abreuvait","word_nosc":"abreuvait","lemma":"abreuver","pos":"VER"} ,
		{"word":"abreuvant","word_nosc":"abreuvant","lemma":"abreuver","pos":"VER"} ,
		{"word":"abreuve","word_nosc":"abreuve","lemma":"abreuver","pos":"VER"} ,
		{"word":"abreuvent","word_nosc":"abreuvent","lemma":"abreuver","pos":"VER"} ,
		{"word":"abreuver","word_nosc":"abreuver","lemma":"abreuver","pos":"VER"} ,
		{"word":"abreuvera","word_nosc":"abreuvera","lemma":"abreuver","pos":"VER"} ,
		{"word":"abreuveraient","word_nosc":"abreuveraient","lemma":"abreuver","pos":"VER"} ,
		{"word":"abreuvez","word_nosc":"abreuvez","lemma":"abreuver","pos":"VER"} ,
		{"word":"abreuvons","word_nosc":"abreuvons","lemma":"abreuver","pos":"VER"} ,
		{"word":"abreuvé","word_nosc":"abreuve","lemma":"abreuver","pos":"VER"} ,
		{"word":"abreuvée","word_nosc":"abreuvee","lemma":"abreuver","pos":"VER"} ,
		{"word":"abreuvés","word_nosc":"abreuves","lemma":"abreuver","pos":"VER"} ,
		{"word":"abricotez","word_nosc":"abricotez","lemma":"abricoter","pos":"VER"} ,
		{"word":"abrita","word_nosc":"abrita","lemma":"abriter","pos":"VER"} ,
		{"word":"abritai","word_nosc":"abritai","lemma":"abriter","pos":"VER"} ,
		{"word":"abritaient","word_nosc":"abritaient","lemma":"abriter","pos":"VER"} ,
		{"word":"abritais","word_nosc":"abritais","lemma":"abriter","pos":"VER"} ,
		{"word":"abritait","word_nosc":"abritait","lemma":"abriter","pos":"VER"} ,
		{"word":"abritant","word_nosc":"abritant","lemma":"abriter","pos":"VER"} ,
		{"word":"abrite","word_nosc":"abrite","lemma":"abriter","pos":"VER"} ,
		{"word":"abritent","word_nosc":"abritent","lemma":"abriter","pos":"VER"} ,
		{"word":"abriter","word_nosc":"abriter","lemma":"abriter","pos":"VER"} ,
		{"word":"abritera","word_nosc":"abritera","lemma":"abriter","pos":"VER"} ,
		{"word":"abriterait","word_nosc":"abriterait","lemma":"abriter","pos":"VER"} ,
		{"word":"abriterons","word_nosc":"abriterons","lemma":"abriter","pos":"VER"} ,
		{"word":"abriteront","word_nosc":"abriteront","lemma":"abriter","pos":"VER"} ,
		{"word":"abrites","word_nosc":"abrites","lemma":"abriter","pos":"VER"} ,
		{"word":"abritez","word_nosc":"abritez","lemma":"abriter","pos":"VER"} ,
		{"word":"abritiez","word_nosc":"abritiez","lemma":"abriter","pos":"VER"} ,
		{"word":"abritions","word_nosc":"abritions","lemma":"abriter","pos":"VER"} ,
		{"word":"abritons","word_nosc":"abritons","lemma":"abriter","pos":"VER"} ,
		{"word":"abritâmes","word_nosc":"abritames","lemma":"abriter","pos":"VER"} ,
		{"word":"abritèrent","word_nosc":"abriterent","lemma":"abriter","pos":"VER"} ,
		{"word":"abrité","word_nosc":"abrite","lemma":"abriter","pos":"VER"} ,
		{"word":"abritée","word_nosc":"abritee","lemma":"abriter","pos":"VER"} ,
		{"word":"abritées","word_nosc":"abritees","lemma":"abriter","pos":"VER"} ,
		{"word":"abrités","word_nosc":"abrites","lemma":"abriter","pos":"VER"} ,
		{"word":"abrogent","word_nosc":"abrogent","lemma":"abroger","pos":"VER"} ,
		{"word":"abroger","word_nosc":"abroger","lemma":"abroger","pos":"VER"} ,
		{"word":"abrogé","word_nosc":"abroge","lemma":"abroger","pos":"VER"} ,
		{"word":"abrogée","word_nosc":"abrogee","lemma":"abroger","pos":"VER"} ,
		{"word":"abrogées","word_nosc":"abrogees","lemma":"abroger","pos":"VER"} ,
		{"word":"abrogés","word_nosc":"abroges","lemma":"abroger","pos":"VER"} ,
		{"word":"abruti","word_nosc":"abruti","lemma":"abrutir","pos":"VER"} ,
		{"word":"abrutie","word_nosc":"abrutie","lemma":"abrutir","pos":"VER"} ,
		{"word":"abruties","word_nosc":"abruties","lemma":"abrutir","pos":"VER"} ,
		{"word":"abrutir","word_nosc":"abrutir","lemma":"abrutir","pos":"VER"} ,
		{"word":"abrutira","word_nosc":"abrutira","lemma":"abrutir","pos":"VER"} ,
		{"word":"abrutis","word_nosc":"abrutis","lemma":"abrutir","pos":"VER"} ,
		{"word":"abrutissaient","word_nosc":"abrutissaient","lemma":"abrutir","pos":"VER"} ,
		{"word":"abrutissais","word_nosc":"abrutissais","lemma":"abrutir","pos":"VER"} ,
		{"word":"abrutissait","word_nosc":"abrutissait","lemma":"abrutir","pos":"VER"} ,
		{"word":"abrutissant","word_nosc":"abrutissant","lemma":"abrutir","pos":"VER"} ,
		{"word":"abrutissent","word_nosc":"abrutissent","lemma":"abrutir","pos":"VER"} ,
		{"word":"abrutissions","word_nosc":"abrutissions","lemma":"abrutir","pos":"VER"} ,
		{"word":"abrutit","word_nosc":"abrutit","lemma":"abrutir","pos":"VER"} ,
		{"word":"abrège","word_nosc":"abrege","lemma":"abréger","pos":"VER"} ,
		{"word":"abrègent","word_nosc":"abregent","lemma":"abréger","pos":"VER"} ,
		{"word":"abrégea","word_nosc":"abregea","lemma":"abréger","pos":"VER"} ,
		{"word":"abrégeai","word_nosc":"abregeai","lemma":"abréger","pos":"VER"} ,
		{"word":"abrégeaient","word_nosc":"abregeaient","lemma":"abréger","pos":"VER"} ,
		{"word":"abrégeait","word_nosc":"abregeait","lemma":"abréger","pos":"VER"} ,
		{"word":"abrégeant","word_nosc":"abregeant","lemma":"abréger","pos":"VER"} ,
		{"word":"abrégeons","word_nosc":"abregeons","lemma":"abréger","pos":"VER"} ,
		{"word":"abréger","word_nosc":"abreger","lemma":"abréger","pos":"VER"} ,
		{"word":"abrégera","word_nosc":"abregera","lemma":"abréger","pos":"VER"} ,
		{"word":"abrégerai","word_nosc":"abregerai","lemma":"abréger","pos":"VER"} ,
		{"word":"abrégerait","word_nosc":"abregerait","lemma":"abréger","pos":"VER"} ,
		{"word":"abrégez","word_nosc":"abregez","lemma":"abréger","pos":"VER"} ,
		{"word":"abrégeât","word_nosc":"abregeat","lemma":"abréger","pos":"VER"} ,
		{"word":"abrégé","word_nosc":"abrege","lemma":"abréger","pos":"VER"} ,
		{"word":"abrégée","word_nosc":"abregee","lemma":"abréger","pos":"VER"} ,
		{"word":"absenta","word_nosc":"absenta","lemma":"absenter","pos":"VER"} ,
		{"word":"absentai","word_nosc":"absentai","lemma":"absenter","pos":"VER"} ,
		{"word":"absentaient","word_nosc":"absentaient","lemma":"absenter","pos":"VER"} ,
		{"word":"absentais","word_nosc":"absentais","lemma":"absenter","pos":"VER"} ,
		{"word":"absentait","word_nosc":"absentait","lemma":"absenter","pos":"VER"} ,
		{"word":"absentant","word_nosc":"absentant","lemma":"absenter","pos":"VER"} ,
		{"word":"absente","word_nosc":"absente","lemma":"absenter","pos":"VER"} ,
		{"word":"absenter","word_nosc":"absenter","lemma":"absenter","pos":"VER"} ,
		{"word":"absentera","word_nosc":"absentera","lemma":"absenter","pos":"VER"} ,
		{"word":"absenterai","word_nosc":"absenterai","lemma":"absenter","pos":"VER"} ,
		{"word":"absenterait","word_nosc":"absenterait","lemma":"absenter","pos":"VER"} ,
		{"word":"absentes","word_nosc":"absentes","lemma":"absenter","pos":"VER"} ,
		{"word":"absentèrent","word_nosc":"absenterent","lemma":"absenter","pos":"VER"} ,
		{"word":"absenté","word_nosc":"absente","lemma":"absenter","pos":"VER"} ,
		{"word":"absentée","word_nosc":"absentee","lemma":"absenter","pos":"VER"} ,
		{"word":"absentés","word_nosc":"absentes","lemma":"absenter","pos":"VER"} ,
		{"word":"absinthant","word_nosc":"absinthant","lemma":"absinther","pos":"VER"} ,
		{"word":"absolvaient","word_nosc":"absolvaient","lemma":"absoudre","pos":"VER"} ,
		{"word":"absolvait","word_nosc":"absolvait","lemma":"absoudre","pos":"VER"} ,
		{"word":"absolve","word_nosc":"absolve","lemma":"absoudre","pos":"VER"} ,
		{"word":"absolvent","word_nosc":"absolvent","lemma":"absoudre","pos":"VER"} ,
		{"word":"absolves","word_nosc":"absolves","lemma":"absoudre","pos":"VER"} ,
		{"word":"absorba","word_nosc":"absorba","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbai","word_nosc":"absorbai","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbaient","word_nosc":"absorbaient","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbais","word_nosc":"absorbais","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbait","word_nosc":"absorbait","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbant","word_nosc":"absorbant","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbe","word_nosc":"absorbe","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbent","word_nosc":"absorbent","lemma":"absorber","pos":"VER"} ,
		{"word":"absorber","word_nosc":"absorber","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbera","word_nosc":"absorbera","lemma":"absorber","pos":"VER"} ,
		{"word":"absorberai","word_nosc":"absorberai","lemma":"absorber","pos":"VER"} ,
		{"word":"absorberaient","word_nosc":"absorberaient","lemma":"absorber","pos":"VER"} ,
		{"word":"absorberait","word_nosc":"absorberait","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbez","word_nosc":"absorbez","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbions","word_nosc":"absorbions","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbons","word_nosc":"absorbons","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbèrent","word_nosc":"absorberent","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbé","word_nosc":"absorbe","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbée","word_nosc":"absorbee","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbées","word_nosc":"absorbees","lemma":"absorber","pos":"VER"} ,
		{"word":"absorbés","word_nosc":"absorbes","lemma":"absorber","pos":"VER"} ,
		{"word":"absoudrai","word_nosc":"absoudrai","lemma":"absoudre","pos":"VER"} ,
		{"word":"absoudrait","word_nosc":"absoudrait","lemma":"absoudre","pos":"VER"} ,
		{"word":"absoudre","word_nosc":"absoudre","lemma":"absoudre","pos":"VER"} ,
		{"word":"absoudriez","word_nosc":"absoudriez","lemma":"absoudre","pos":"VER"} ,
		{"word":"absous","word_nosc":"absous","lemma":"absoudre","pos":"VER"} ,
		{"word":"absout","word_nosc":"absout","lemma":"absoudre","pos":"VER"} ,
		{"word":"absoute","word_nosc":"absoute","lemma":"absoudre","pos":"VER"} ,
		{"word":"absoutes","word_nosc":"absoutes","lemma":"absoudre","pos":"VER"} ,
		{"word":"abstenaient","word_nosc":"abstenaient","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstenais","word_nosc":"abstenais","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstenait","word_nosc":"abstenait","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstenant","word_nosc":"abstenant","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstenez","word_nosc":"abstenez","lemma":"abstenir","pos":"VER"} ,
		{"word":"absteniez","word_nosc":"absteniez","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstenions","word_nosc":"abstenions","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstenir","word_nosc":"abstenir","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstenons","word_nosc":"abstenons","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstenu","word_nosc":"abstenu","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstenue","word_nosc":"abstenue","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstenues","word_nosc":"abstenues","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstenus","word_nosc":"abstenus","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstiendrai","word_nosc":"abstiendrai","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstiendraient","word_nosc":"abstiendraient","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstiendrais","word_nosc":"abstiendrais","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstiendrait","word_nosc":"abstiendrait","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstiendras","word_nosc":"abstiendras","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstiendrions","word_nosc":"abstiendrions","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstiendrons","word_nosc":"abstiendrons","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstiendront","word_nosc":"abstiendront","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstienne","word_nosc":"abstienne","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstiennent","word_nosc":"abstiennent","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstiennes","word_nosc":"abstiennes","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstiens","word_nosc":"abstiens","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstient","word_nosc":"abstient","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstinrent","word_nosc":"abstinrent","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstins","word_nosc":"abstins","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstint","word_nosc":"abstint","lemma":"abstenir","pos":"VER"} ,
		{"word":"abstraire","word_nosc":"abstraire","lemma":"abstraire","pos":"VER"} ,
		{"word":"abstrais","word_nosc":"abstrais","lemma":"abstraire","pos":"VER"} ,
		{"word":"abstrait","word_nosc":"abstrait","lemma":"abstraire","pos":"VER"} ,
		{"word":"abstraite","word_nosc":"abstraite","lemma":"abstraire","pos":"VER"} ,
		{"word":"abstraites","word_nosc":"abstraites","lemma":"abstraire","pos":"VER"} ,
		{"word":"abstraits","word_nosc":"abstraits","lemma":"abstraire","pos":"VER"} ,
		{"word":"abstînt","word_nosc":"abstint","lemma":"abstenir","pos":"VER"} ,
		{"word":"abusa","word_nosc":"abusa","lemma":"abuser","pos":"VER"} ,
		{"word":"abusai","word_nosc":"abusai","lemma":"abuser","pos":"VER"} ,
		{"word":"abusaient","word_nosc":"abusaient","lemma":"abuser","pos":"VER"} ,
		{"word":"abusais","word_nosc":"abusais","lemma":"abuser","pos":"VER"} ,
		{"word":"abusait","word_nosc":"abusait","lemma":"abuser","pos":"VER"} ,
		{"word":"abusant","word_nosc":"abusant","lemma":"abuser","pos":"VER"} ,
		{"word":"abuse","word_nosc":"abuse","lemma":"abuser","pos":"VER"} ,
		{"word":"abusent","word_nosc":"abusent","lemma":"abuser","pos":"VER"} ,
		{"word":"abuser","word_nosc":"abuser","lemma":"abuser","pos":"VER"} ,
		{"word":"abusera","word_nosc":"abusera","lemma":"abuser","pos":"VER"} ,
		{"word":"abuserai","word_nosc":"abuserai","lemma":"abuser","pos":"VER"} ,
		{"word":"abuserais","word_nosc":"abuserais","lemma":"abuser","pos":"VER"} ,
		{"word":"abuserait","word_nosc":"abuserait","lemma":"abuser","pos":"VER"} ,
		{"word":"abuserez","word_nosc":"abuserez","lemma":"abuser","pos":"VER"} ,
		{"word":"abuseriez","word_nosc":"abuseriez","lemma":"abuser","pos":"VER"} ,
		{"word":"abuses","word_nosc":"abuses","lemma":"abuser","pos":"VER"} ,
		{"word":"abusez","word_nosc":"abusez","lemma":"abuser","pos":"VER"} ,
		{"word":"abusiez","word_nosc":"abusiez","lemma":"abuser","pos":"VER"} ,
		{"word":"abusons","word_nosc":"abusons","lemma":"abuser","pos":"VER"} ,
		{"word":"abusèrent","word_nosc":"abuserent","lemma":"abuser","pos":"VER"} ,
		{"word":"abusé","word_nosc":"abuse","lemma":"abuser","pos":"VER"} ,
		{"word":"abusée","word_nosc":"abusee","lemma":"abuser","pos":"VER"} ,
		{"word":"abusées","word_nosc":"abusees","lemma":"abuser","pos":"VER"} ,
		{"word":"abusés","word_nosc":"abuses","lemma":"abuser","pos":"VER"} ,
		{"word":"abâtardi","word_nosc":"abatardi","lemma":"abâtardir","pos":"VER"} ,
		{"word":"abâtardies","word_nosc":"abatardies","lemma":"abâtardir","pos":"VER"} ,
		{"word":"abâtardir","word_nosc":"abatardir","lemma":"abâtardir","pos":"VER"} ,
		{"word":"abâtardirai","word_nosc":"abatardirai","lemma":"abâtardir","pos":"VER"} ,
		{"word":"abâtardissant","word_nosc":"abatardissant","lemma":"abâtardir","pos":"VER"} ,
		{"word":"abêti","word_nosc":"abeti","lemma":"abêtir","pos":"VER"} ,
		{"word":"abêtir","word_nosc":"abetir","lemma":"abêtir","pos":"VER"} ,
		{"word":"abêtis","word_nosc":"abetis","lemma":"abêtir","pos":"VER"} ,
		{"word":"abêtissait","word_nosc":"abetissait","lemma":"abêtir","pos":"VER"} ,
		{"word":"abêtit","word_nosc":"abetit","lemma":"abêtir","pos":"VER"} ,
		{"word":"abîma","word_nosc":"abima","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmai","word_nosc":"abimai","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmaient","word_nosc":"abimaient","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmait","word_nosc":"abimait","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmant","word_nosc":"abimant","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîme","word_nosc":"abime","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîment","word_nosc":"abiment","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmer","word_nosc":"abimer","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmera","word_nosc":"abimera","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmerai","word_nosc":"abimerai","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmerais","word_nosc":"abimerais","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmerait","word_nosc":"abimerait","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmeras","word_nosc":"abimeras","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmerez","word_nosc":"abimerez","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmeront","word_nosc":"abimeront","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmes","word_nosc":"abimes","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmez","word_nosc":"abimez","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmions","word_nosc":"abimions","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmons","word_nosc":"abimons","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmé","word_nosc":"abime","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmée","word_nosc":"abimee","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmées","word_nosc":"abimees","lemma":"abîmer","pos":"VER"} ,
		{"word":"abîmés","word_nosc":"abimes","lemma":"abîmer","pos":"VER"} ,
		{"word":"académisé","word_nosc":"academise","lemma":"académiser","pos":"VER"} ,
		{"word":"acagnardai","word_nosc":"acagnardai","lemma":"acagnarder","pos":"VER"} ,
		{"word":"acagnardait","word_nosc":"acagnardait","lemma":"acagnarder","pos":"VER"} ,
		{"word":"acagnarder","word_nosc":"acagnarder","lemma":"acagnarder","pos":"VER"} ,
		{"word":"acagnardé","word_nosc":"acagnarde","lemma":"acagnarder","pos":"VER"} ,
		{"word":"acagnardée","word_nosc":"acagnardee","lemma":"acagnarder","pos":"VER"} ,
		{"word":"accabla","word_nosc":"accabla","lemma":"accabler","pos":"VER"} ,
		{"word":"accablai","word_nosc":"accablai","lemma":"accabler","pos":"VER"} ,
		{"word":"accablaient","word_nosc":"accablaient","lemma":"accabler","pos":"VER"} ,
		{"word":"accablais","word_nosc":"accablais","lemma":"accabler","pos":"VER"} ,
		{"word":"accablait","word_nosc":"accablait","lemma":"accabler","pos":"VER"} ,
		{"word":"accablant","word_nosc":"accablant","lemma":"accabler","pos":"VER"} ,
		{"word":"accable","word_nosc":"accable","lemma":"accabler","pos":"VER"} ,
		{"word":"accablent","word_nosc":"accablent","lemma":"accabler","pos":"VER"} ,
		{"word":"accabler","word_nosc":"accabler","lemma":"accabler","pos":"VER"} ,
		{"word":"accableraient","word_nosc":"accableraient","lemma":"accabler","pos":"VER"} ,
		{"word":"accablez","word_nosc":"accablez","lemma":"accabler","pos":"VER"} ,
		{"word":"accablions","word_nosc":"accablions","lemma":"accabler","pos":"VER"} ,
		{"word":"accablât","word_nosc":"accablat","lemma":"accabler","pos":"VER"} ,
		{"word":"accablèrent","word_nosc":"accablerent","lemma":"accabler","pos":"VER"} ,
		{"word":"accablé","word_nosc":"accable","lemma":"accabler","pos":"VER"} ,
		{"word":"accablée","word_nosc":"accablee","lemma":"accabler","pos":"VER"} ,
		{"word":"accablées","word_nosc":"accablees","lemma":"accabler","pos":"VER"} ,
		{"word":"accablés","word_nosc":"accables","lemma":"accabler","pos":"VER"} ,
		{"word":"accalmit","word_nosc":"accalmit","lemma":"accalmir","pos":"VER"} ,
		{"word":"accapara","word_nosc":"accapara","lemma":"accaparer","pos":"VER"} ,
		{"word":"accaparaient","word_nosc":"accaparaient","lemma":"accaparer","pos":"VER"} ,
		{"word":"accaparait","word_nosc":"accaparait","lemma":"accaparer","pos":"VER"} ,
		{"word":"accaparant","word_nosc":"accaparant","lemma":"accaparer","pos":"VER"} ,
		{"word":"accapare","word_nosc":"accapare","lemma":"accaparer","pos":"VER"} ,
		{"word":"accaparer","word_nosc":"accaparer","lemma":"accaparer","pos":"VER"} ,
		{"word":"accaparerait","word_nosc":"accaparerait","lemma":"accaparer","pos":"VER"} ,
		{"word":"accaparez","word_nosc":"accaparez","lemma":"accaparer","pos":"VER"} ,
		{"word":"accaparé","word_nosc":"accapare","lemma":"accaparer","pos":"VER"} ,
		{"word":"accaparée","word_nosc":"accaparee","lemma":"accaparer","pos":"VER"} ,
		{"word":"accaparées","word_nosc":"accaparees","lemma":"accaparer","pos":"VER"} ,
		{"word":"accaparés","word_nosc":"accapares","lemma":"accaparer","pos":"VER"} ,
		{"word":"accentua","word_nosc":"accentua","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentuai","word_nosc":"accentuai","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentuaient","word_nosc":"accentuaient","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentuais","word_nosc":"accentuais","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentuait","word_nosc":"accentuait","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentuant","word_nosc":"accentuant","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentue","word_nosc":"accentue","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentuent","word_nosc":"accentuent","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentuer","word_nosc":"accentuer","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentuerait","word_nosc":"accentuerait","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentuez","word_nosc":"accentuez","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentuât","word_nosc":"accentuat","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentuèrent","word_nosc":"accentuerent","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentué","word_nosc":"accentue","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentuée","word_nosc":"accentuee","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentuées","word_nosc":"accentuees","lemma":"accentuer","pos":"VER"} ,
		{"word":"accentués","word_nosc":"accentues","lemma":"accentuer","pos":"VER"} ,
		{"word":"accepta","word_nosc":"accepta","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptai","word_nosc":"acceptai","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptaient","word_nosc":"acceptaient","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptais","word_nosc":"acceptais","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptait","word_nosc":"acceptait","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptant","word_nosc":"acceptant","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptassent","word_nosc":"acceptassent","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptasses","word_nosc":"acceptasses","lemma":"accepter","pos":"VER"} ,
		{"word":"accepte","word_nosc":"accepte","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptent","word_nosc":"acceptent","lemma":"accepter","pos":"VER"} ,
		{"word":"accepter","word_nosc":"accepter","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptera","word_nosc":"acceptera","lemma":"accepter","pos":"VER"} ,
		{"word":"accepterai","word_nosc":"accepterai","lemma":"accepter","pos":"VER"} ,
		{"word":"accepteraient","word_nosc":"accepteraient","lemma":"accepter","pos":"VER"} ,
		{"word":"accepterais","word_nosc":"accepterais","lemma":"accepter","pos":"VER"} ,
		{"word":"accepterait","word_nosc":"accepterait","lemma":"accepter","pos":"VER"} ,
		{"word":"accepteras","word_nosc":"accepteras","lemma":"accepter","pos":"VER"} ,
		{"word":"accepterez","word_nosc":"accepterez","lemma":"accepter","pos":"VER"} ,
		{"word":"accepteriez","word_nosc":"accepteriez","lemma":"accepter","pos":"VER"} ,
		{"word":"accepterions","word_nosc":"accepterions","lemma":"accepter","pos":"VER"} ,
		{"word":"accepterons","word_nosc":"accepterons","lemma":"accepter","pos":"VER"} ,
		{"word":"accepteront","word_nosc":"accepteront","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptes","word_nosc":"acceptes","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptez","word_nosc":"acceptez","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptiez","word_nosc":"acceptiez","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptions","word_nosc":"acceptions","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptons","word_nosc":"acceptons","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptâmes","word_nosc":"acceptames","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptât","word_nosc":"acceptat","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptèrent","word_nosc":"accepterent","lemma":"accepter","pos":"VER"} ,
		{"word":"accepté","word_nosc":"accepte","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptée","word_nosc":"acceptee","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptées","word_nosc":"acceptees","lemma":"accepter","pos":"VER"} ,
		{"word":"acceptés","word_nosc":"acceptes","lemma":"accepter","pos":"VER"} ,
		{"word":"accessoirise","word_nosc":"accessoirise","lemma":"accessoiriser","pos":"VER"} ,
		{"word":"accessoiriser","word_nosc":"accessoiriser","lemma":"accessoiriser","pos":"VER"} ,
		{"word":"accidente","word_nosc":"accidente","lemma":"accidenter","pos":"VER"} ,
		{"word":"accidenter","word_nosc":"accidenter","lemma":"accidenter","pos":"VER"} ,
		{"word":"accidenté","word_nosc":"accidente","lemma":"accidenter","pos":"VER"} ,
		{"word":"accidentée","word_nosc":"accidentee","lemma":"accidenter","pos":"VER"} ,
		{"word":"acclama","word_nosc":"acclama","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclamaient","word_nosc":"acclamaient","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclamait","word_nosc":"acclamait","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclamant","word_nosc":"acclamant","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclame","word_nosc":"acclame","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclament","word_nosc":"acclament","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclamer","word_nosc":"acclamer","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclamera","word_nosc":"acclamera","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclamerait","word_nosc":"acclamerait","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclameront","word_nosc":"acclameront","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclamez","word_nosc":"acclamez","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclamiez","word_nosc":"acclamiez","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclamons","word_nosc":"acclamons","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclamé","word_nosc":"acclame","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclamée","word_nosc":"acclamee","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclamées","word_nosc":"acclamees","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclamés","word_nosc":"acclames","lemma":"acclamer","pos":"VER"} ,
		{"word":"acclimata","word_nosc":"acclimata","lemma":"acclimater","pos":"VER"} ,
		{"word":"acclimatai","word_nosc":"acclimatai","lemma":"acclimater","pos":"VER"} ,
		{"word":"acclimate","word_nosc":"acclimate","lemma":"acclimater","pos":"VER"} ,
		{"word":"acclimatent","word_nosc":"acclimatent","lemma":"acclimater","pos":"VER"} ,
		{"word":"acclimater","word_nosc":"acclimater","lemma":"acclimater","pos":"VER"} ,
		{"word":"acclimaterait","word_nosc":"acclimaterait","lemma":"acclimater","pos":"VER"} ,
		{"word":"acclimates","word_nosc":"acclimates","lemma":"acclimater","pos":"VER"} ,
		{"word":"acclimatez","word_nosc":"acclimatez","lemma":"acclimater","pos":"VER"} ,
		{"word":"acclimaté","word_nosc":"acclimate","lemma":"acclimater","pos":"VER"} ,
		{"word":"acclimatés","word_nosc":"acclimates","lemma":"acclimater","pos":"VER"} ,
		{"word":"accointé","word_nosc":"accointe","lemma":"accointer","pos":"VER"} ,
		{"word":"accointés","word_nosc":"accointes","lemma":"accointer","pos":"VER"} ,
		{"word":"accola","word_nosc":"accola","lemma":"accoler","pos":"VER"} ,
		{"word":"accolait","word_nosc":"accolait","lemma":"accoler","pos":"VER"} ,
		{"word":"accolant","word_nosc":"accolant","lemma":"accoler","pos":"VER"} ,
		{"word":"accole","word_nosc":"accole","lemma":"accoler","pos":"VER"} ,
		{"word":"accolent","word_nosc":"accolent","lemma":"accoler","pos":"VER"} ,
		{"word":"accoler","word_nosc":"accoler","lemma":"accoler","pos":"VER"} ,
		{"word":"accolerais","word_nosc":"accolerais","lemma":"accoler","pos":"VER"} ,
		{"word":"accolé","word_nosc":"accole","lemma":"accoler","pos":"VER"} ,
		{"word":"accolée","word_nosc":"accolee","lemma":"accoler","pos":"VER"} ,
		{"word":"accolées","word_nosc":"accolees","lemma":"accoler","pos":"VER"} ,
		{"word":"accolés","word_nosc":"accoles","lemma":"accoler","pos":"VER"} ,
		{"word":"accommoda","word_nosc":"accommoda","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommodai","word_nosc":"accommodai","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommodaient","word_nosc":"accommodaient","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommodais","word_nosc":"accommodais","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommodait","word_nosc":"accommodait","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommodant","word_nosc":"accommodant","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommode","word_nosc":"accommode","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommodent","word_nosc":"accommodent","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommoder","word_nosc":"accommoder","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommodera","word_nosc":"accommodera","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommoderai","word_nosc":"accommoderai","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommoderaient","word_nosc":"accommoderaient","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommoderais","word_nosc":"accommoderais","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommoderait","word_nosc":"accommoderait","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommoderez","word_nosc":"accommoderez","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommoderions","word_nosc":"accommoderions","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommoderont","word_nosc":"accommoderont","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommodions","word_nosc":"accommodions","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommodons","word_nosc":"accommodons","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommodât","word_nosc":"accommodat","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommodèrent","word_nosc":"accommoderent","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommodé","word_nosc":"accommode","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommodée","word_nosc":"accommodee","lemma":"accommoder","pos":"VER"} ,
		{"word":"accommodés","word_nosc":"accommodes","lemma":"accommoder","pos":"VER"} ,
		{"word":"accompagna","word_nosc":"accompagna","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnai","word_nosc":"accompagnai","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnaient","word_nosc":"accompagnaient","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnais","word_nosc":"accompagnais","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnait","word_nosc":"accompagnait","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnant","word_nosc":"accompagnant","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagne","word_nosc":"accompagne","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnent","word_nosc":"accompagnent","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagner","word_nosc":"accompagner","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnera","word_nosc":"accompagnera","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnerai","word_nosc":"accompagnerai","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagneraient","word_nosc":"accompagneraient","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnerais","word_nosc":"accompagnerais","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnerait","word_nosc":"accompagnerait","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagneras","word_nosc":"accompagneras","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnerez","word_nosc":"accompagnerez","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagneriez","word_nosc":"accompagneriez","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnerons","word_nosc":"accompagnerons","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagneront","word_nosc":"accompagneront","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnes","word_nosc":"accompagnes","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnez","word_nosc":"accompagnez","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagniez","word_nosc":"accompagniez","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnions","word_nosc":"accompagnions","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnons","word_nosc":"accompagnons","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnâmes","word_nosc":"accompagnames","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnât","word_nosc":"accompagnat","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnèrent","word_nosc":"accompagnerent","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagné","word_nosc":"accompagne","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnée","word_nosc":"accompagnee","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnées","word_nosc":"accompagnees","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompagnés","word_nosc":"accompagnes","lemma":"accompagner","pos":"VER"} ,
		{"word":"accompli","word_nosc":"accompli","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplie","word_nosc":"accomplie","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplies","word_nosc":"accomplies","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplir","word_nosc":"accomplir","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplira","word_nosc":"accomplira","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplirai","word_nosc":"accomplirai","lemma":"accomplir","pos":"VER"} ,
		{"word":"accompliraient","word_nosc":"accompliraient","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplirais","word_nosc":"accomplirais","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplirait","word_nosc":"accomplirait","lemma":"accomplir","pos":"VER"} ,
		{"word":"accompliras","word_nosc":"accompliras","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplirent","word_nosc":"accomplirent","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplirez","word_nosc":"accomplirez","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplirons","word_nosc":"accomplirons","lemma":"accomplir","pos":"VER"} ,
		{"word":"accompliront","word_nosc":"accompliront","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplis","word_nosc":"accomplis","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplissaient","word_nosc":"accomplissaient","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplissais","word_nosc":"accomplissais","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplissait","word_nosc":"accomplissait","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplissant","word_nosc":"accomplissant","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplisse","word_nosc":"accomplisse","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplissent","word_nosc":"accomplissent","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplissez","word_nosc":"accomplissez","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplissiez","word_nosc":"accomplissiez","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplissions","word_nosc":"accomplissions","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplissons","word_nosc":"accomplissons","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplit","word_nosc":"accomplit","lemma":"accomplir","pos":"VER"} ,
		{"word":"accomplît","word_nosc":"accomplit","lemma":"accomplir","pos":"VER"} ,
		{"word":"accorda","word_nosc":"accorda","lemma":"accorder","pos":"VER"} ,
		{"word":"accordai","word_nosc":"accordai","lemma":"accorder","pos":"VER"} ,
		{"word":"accordaient","word_nosc":"accordaient","lemma":"accorder","pos":"VER"} ,
		{"word":"accordais","word_nosc":"accordais","lemma":"accorder","pos":"VER"} ,
		{"word":"accordait","word_nosc":"accordait","lemma":"accorder","pos":"VER"} ,
		{"word":"accordant","word_nosc":"accordant","lemma":"accorder","pos":"VER"} ,
		{"word":"accordas","word_nosc":"accordas","lemma":"accorder","pos":"VER"} ,
		{"word":"accordassent","word_nosc":"accordassent","lemma":"accorder","pos":"VER"} ,
		{"word":"accorde","word_nosc":"accorde","lemma":"accorder","pos":"VER"} ,
		{"word":"accordent","word_nosc":"accordent","lemma":"accorder","pos":"VER"} ,
		{"word":"accorder","word_nosc":"accorder","lemma":"accorder","pos":"VER"} ,
		{"word":"accordera","word_nosc":"accordera","lemma":"accorder","pos":"VER"} ,
		{"word":"accorderai","word_nosc":"accorderai","lemma":"accorder","pos":"VER"} ,
		{"word":"accorderaient","word_nosc":"accorderaient","lemma":"accorder","pos":"VER"} ,
		{"word":"accorderais","word_nosc":"accorderais","lemma":"accorder","pos":"VER"} ,
		{"word":"accorderait","word_nosc":"accorderait","lemma":"accorder","pos":"VER"} ,
		{"word":"accorderas","word_nosc":"accorderas","lemma":"accorder","pos":"VER"} ,
		{"word":"accorderez","word_nosc":"accorderez","lemma":"accorder","pos":"VER"} ,
		{"word":"accorderiez","word_nosc":"accorderiez","lemma":"accorder","pos":"VER"} ,
		{"word":"accorderons","word_nosc":"accorderons","lemma":"accorder","pos":"VER"} ,
		{"word":"accorderont","word_nosc":"accorderont","lemma":"accorder","pos":"VER"} ,
		{"word":"accordes","word_nosc":"accordes","lemma":"accorder","pos":"VER"} ,
		{"word":"accordez","word_nosc":"accordez","lemma":"accorder","pos":"VER"} ,
		{"word":"accordiez","word_nosc":"accordiez","lemma":"accorder","pos":"VER"} ,
		{"word":"accordions","word_nosc":"accordions","lemma":"accorder","pos":"VER"} ,
		{"word":"accordons","word_nosc":"accordons","lemma":"accorder","pos":"VER"} ,
		{"word":"accordât","word_nosc":"accordat","lemma":"accorder","pos":"VER"} ,
		{"word":"accordèrent","word_nosc":"accorderent","lemma":"accorder","pos":"VER"} ,
		{"word":"accordé","word_nosc":"accorde","lemma":"accorder","pos":"VER"} ,
		{"word":"accordée","word_nosc":"accordee","lemma":"accorder","pos":"VER"} ,
		{"word":"accordées","word_nosc":"accordees","lemma":"accorder","pos":"VER"} ,
		{"word":"accordés","word_nosc":"accordes","lemma":"accorder","pos":"VER"} ,
		{"word":"accosta","word_nosc":"accosta","lemma":"accoster","pos":"VER"} ,
		{"word":"accostaient","word_nosc":"accostaient","lemma":"accoster","pos":"VER"} ,
		{"word":"accostais","word_nosc":"accostais","lemma":"accoster","pos":"VER"} ,
		{"word":"accostait","word_nosc":"accostait","lemma":"accoster","pos":"VER"} ,
		{"word":"accostant","word_nosc":"accostant","lemma":"accoster","pos":"VER"} ,
		{"word":"accoste","word_nosc":"accoste","lemma":"accoster","pos":"VER"} ,
		{"word":"accostent","word_nosc":"accostent","lemma":"accoster","pos":"VER"} ,
		{"word":"accoster","word_nosc":"accoster","lemma":"accoster","pos":"VER"} ,
		{"word":"accosterez","word_nosc":"accosterez","lemma":"accoster","pos":"VER"} ,
		{"word":"accosterons","word_nosc":"accosterons","lemma":"accoster","pos":"VER"} ,
		{"word":"accostez","word_nosc":"accostez","lemma":"accoster","pos":"VER"} ,
		{"word":"accostiez","word_nosc":"accostiez","lemma":"accoster","pos":"VER"} ,
		{"word":"accostons","word_nosc":"accostons","lemma":"accoster","pos":"VER"} ,
		{"word":"accosté","word_nosc":"accoste","lemma":"accoster","pos":"VER"} ,
		{"word":"accostée","word_nosc":"accostee","lemma":"accoster","pos":"VER"} ,
		{"word":"accostées","word_nosc":"accostees","lemma":"accoster","pos":"VER"} ,
		{"word":"accota","word_nosc":"accota","lemma":"accoter","pos":"VER"} ,
		{"word":"accotaient","word_nosc":"accotaient","lemma":"accoter","pos":"VER"} ,
		{"word":"accotait","word_nosc":"accotait","lemma":"accoter","pos":"VER"} ,
		{"word":"accotant","word_nosc":"accotant","lemma":"accoter","pos":"VER"} ,
		{"word":"accote","word_nosc":"accote","lemma":"accoter","pos":"VER"} ,
		{"word":"accotent","word_nosc":"accotent","lemma":"accoter","pos":"VER"} ,
		{"word":"accoter","word_nosc":"accoter","lemma":"accoter","pos":"VER"} ,
		{"word":"accotons","word_nosc":"accotons","lemma":"accoter","pos":"VER"} ,
		{"word":"accotèrent","word_nosc":"accoterent","lemma":"accoter","pos":"VER"} ,
		{"word":"accoté","word_nosc":"accote","lemma":"accoter","pos":"VER"} ,
		{"word":"accotée","word_nosc":"accotee","lemma":"accoter","pos":"VER"} ,
		{"word":"accotées","word_nosc":"accotees","lemma":"accoter","pos":"VER"} ,
		{"word":"accotés","word_nosc":"accotes","lemma":"accoter","pos":"VER"} ,
		{"word":"accoucha","word_nosc":"accoucha","lemma":"accoucher","pos":"VER"} ,
		{"word":"accouchaient","word_nosc":"accouchaient","lemma":"accoucher","pos":"VER"} ,
		{"word":"accouchais","word_nosc":"accouchais","lemma":"accoucher","pos":"VER"} ,
		{"word":"accouchait","word_nosc":"accouchait","lemma":"accoucher","pos":"VER"} ,
		{"word":"accouchant","word_nosc":"accouchant","lemma":"accoucher","pos":"VER"} ,
		{"word":"accouche","word_nosc":"accouche","lemma":"accoucher","pos":"VER"} ,
		{"word":"accouchent","word_nosc":"accouchent","lemma":"accoucher","pos":"VER"} ,
		{"word":"accoucher","word_nosc":"accoucher","lemma":"accoucher","pos":"VER"} ,
		{"word":"accouchera","word_nosc":"accouchera","lemma":"accoucher","pos":"VER"} ,
		{"word":"accoucherait","word_nosc":"accoucherait","lemma":"accoucher","pos":"VER"} ,
		{"word":"accoucheras","word_nosc":"accoucheras","lemma":"accoucher","pos":"VER"} ,
		{"word":"accouches","word_nosc":"accouches","lemma":"accoucher","pos":"VER"} ,
		{"word":"accouchez","word_nosc":"accouchez","lemma":"accoucher","pos":"VER"} ,
		{"word":"accouché","word_nosc":"accouche","lemma":"accoucher","pos":"VER"} ,
		{"word":"accouchée","word_nosc":"accouchee","lemma":"accoucher","pos":"VER"} ,
		{"word":"accouchés","word_nosc":"accouches","lemma":"accoucher","pos":"VER"} ,
		{"word":"accouda","word_nosc":"accouda","lemma":"accouder","pos":"VER"} ,
		{"word":"accoudai","word_nosc":"accoudai","lemma":"accouder","pos":"VER"} ,
		{"word":"accoudaient","word_nosc":"accoudaient","lemma":"accouder","pos":"VER"} ,
		{"word":"accoudais","word_nosc":"accoudais","lemma":"accouder","pos":"VER"} ,
		{"word":"accoudait","word_nosc":"accoudait","lemma":"accouder","pos":"VER"} ,
		{"word":"accoudant","word_nosc":"accoudant","lemma":"accouder","pos":"VER"} ,
		{"word":"accoude","word_nosc":"accoude","lemma":"accouder","pos":"VER"} ,
		{"word":"accouder","word_nosc":"accouder","lemma":"accouder","pos":"VER"} ,
		{"word":"accoudions","word_nosc":"accoudions","lemma":"accouder","pos":"VER"} ,
		{"word":"accoudons","word_nosc":"accoudons","lemma":"accouder","pos":"VER"} ,
		{"word":"accoudèrent","word_nosc":"accouderent","lemma":"accouder","pos":"VER"} ,
		{"word":"accoudé","word_nosc":"accoude","lemma":"accouder","pos":"VER"} ,
		{"word":"accoudée","word_nosc":"accoudee","lemma":"accouder","pos":"VER"} ,
		{"word":"accoudées","word_nosc":"accoudees","lemma":"accouder","pos":"VER"} ,
		{"word":"accoudés","word_nosc":"accoudes","lemma":"accouder","pos":"VER"} ,
		{"word":"accoupla","word_nosc":"accoupla","lemma":"accoupler","pos":"VER"} ,
		{"word":"accouplaient","word_nosc":"accouplaient","lemma":"accoupler","pos":"VER"} ,
		{"word":"accouplais","word_nosc":"accouplais","lemma":"accoupler","pos":"VER"} ,
		{"word":"accouplait","word_nosc":"accouplait","lemma":"accoupler","pos":"VER"} ,
		{"word":"accouplant","word_nosc":"accouplant","lemma":"accoupler","pos":"VER"} ,
		{"word":"accouple","word_nosc":"accouple","lemma":"accoupler","pos":"VER"} ,
		{"word":"accouplent","word_nosc":"accouplent","lemma":"accoupler","pos":"VER"} ,
		{"word":"accoupler","word_nosc":"accoupler","lemma":"accoupler","pos":"VER"} ,
		{"word":"accouplera","word_nosc":"accouplera","lemma":"accoupler","pos":"VER"} ,
		{"word":"accouplerait","word_nosc":"accouplerait","lemma":"accoupler","pos":"VER"} ,
		{"word":"accoupleront","word_nosc":"accoupleront","lemma":"accoupler","pos":"VER"} ,
		{"word":"accouplèrent","word_nosc":"accouplerent","lemma":"accoupler","pos":"VER"} ,
		{"word":"accouplé","word_nosc":"accouple","lemma":"accoupler","pos":"VER"} ,
		{"word":"accouplée","word_nosc":"accouplee","lemma":"accoupler","pos":"VER"} ,
		{"word":"accouplées","word_nosc":"accouplees","lemma":"accoupler","pos":"VER"} ,
		{"word":"accouplés","word_nosc":"accouples","lemma":"accoupler","pos":"VER"} ,
		{"word":"accouraient","word_nosc":"accouraient","lemma":"accourir","pos":"VER"} ,
		{"word":"accourais","word_nosc":"accourais","lemma":"accourir","pos":"VER"} ,
		{"word":"accourait","word_nosc":"accourait","lemma":"accourir","pos":"VER"} ,
		{"word":"accourant","word_nosc":"accourant","lemma":"accourir","pos":"VER"} ,
		{"word":"accourcissant","word_nosc":"accourcissant","lemma":"accourcir","pos":"VER"} ,
		{"word":"accoure","word_nosc":"accoure","lemma":"accourir","pos":"VER"} ,
		{"word":"accourent","word_nosc":"accourent","lemma":"accourir","pos":"VER"} ,
		{"word":"accoures","word_nosc":"accoures","lemma":"accourir","pos":"VER"} ,
		{"word":"accourez","word_nosc":"accourez","lemma":"accourir","pos":"VER"} ,
		{"word":"accouriez","word_nosc":"accouriez","lemma":"accourir","pos":"VER"} ,
		{"word":"accourir","word_nosc":"accourir","lemma":"accourir","pos":"VER"} ,
		{"word":"accourons","word_nosc":"accourons","lemma":"accourir","pos":"VER"} ,
		{"word":"accourra","word_nosc":"accourra","lemma":"accourir","pos":"VER"} ,
		{"word":"accourrai","word_nosc":"accourrai","lemma":"accourir","pos":"VER"} ,
		{"word":"accourraient","word_nosc":"accourraient","lemma":"accourir","pos":"VER"} ,
		{"word":"accourrais","word_nosc":"accourrais","lemma":"accourir","pos":"VER"} ,
		{"word":"accourrait","word_nosc":"accourrait","lemma":"accourir","pos":"VER"} ,
		{"word":"accourront","word_nosc":"accourront","lemma":"accourir","pos":"VER"} ,
		{"word":"accours","word_nosc":"accours","lemma":"accourir","pos":"VER"} ,
		{"word":"accourt","word_nosc":"accourt","lemma":"accourir","pos":"VER"} ,
		{"word":"accouru","word_nosc":"accouru","lemma":"accourir","pos":"VER"} ,
		{"word":"accourue","word_nosc":"accourue","lemma":"accourir","pos":"VER"} ,
		{"word":"accourues","word_nosc":"accourues","lemma":"accourir","pos":"VER"} ,
		{"word":"accoururent","word_nosc":"accoururent","lemma":"accourir","pos":"VER"} ,
		{"word":"accourus","word_nosc":"accourus","lemma":"accourir","pos":"VER"} ,
		{"word":"accourussent","word_nosc":"accourussent","lemma":"accourir","pos":"VER"} ,
		{"word":"accourut","word_nosc":"accourut","lemma":"accourir","pos":"VER"} ,
		{"word":"accourût","word_nosc":"accourut","lemma":"accourir","pos":"VER"} ,
		{"word":"accoutre","word_nosc":"accoutre","lemma":"accoutrer","pos":"VER"} ,
		{"word":"accoutré","word_nosc":"accoutre","lemma":"accoutrer","pos":"VER"} ,
		{"word":"accoutrée","word_nosc":"accoutree","lemma":"accoutrer","pos":"VER"} ,
		{"word":"accoutrés","word_nosc":"accoutres","lemma":"accoutrer","pos":"VER"} ,
		{"word":"accoutuma","word_nosc":"accoutuma","lemma":"accoutumer","pos":"VER"} ,
		{"word":"accoutumai","word_nosc":"accoutumai","lemma":"accoutumer","pos":"VER"} ,
		{"word":"accoutumaient","word_nosc":"accoutumaient","lemma":"accoutumer","pos":"VER"} ,
		{"word":"accoutumais","word_nosc":"accoutumais","lemma":"accoutumer","pos":"VER"} ,
		{"word":"accoutumait","word_nosc":"accoutumait","lemma":"accoutumer","pos":"VER"} ,
		{"word":"accoutumant","word_nosc":"accoutumant","lemma":"accoutumer","pos":"VER"} ,
		{"word":"accoutume","word_nosc":"accoutume","lemma":"accoutumer","pos":"VER"} ,
		{"word":"accoutument","word_nosc":"accoutument","lemma":"accoutumer","pos":"VER"} ,
		{"word":"accoutumer","word_nosc":"accoutumer","lemma":"accoutumer","pos":"VER"} ,
		{"word":"accoutumerait","word_nosc":"accoutumerait","lemma":"accoutumer","pos":"VER"} ,
		{"word":"accoutumé","word_nosc":"accoutume","lemma":"accoutumer","pos":"VER"} ,
		{"word":"accoutumée","word_nosc":"accoutumee","lemma":"accoutumer","pos":"VER"} ,
		{"word":"accoutumées","word_nosc":"accoutumees","lemma":"accoutumer","pos":"VER"} ,
		{"word":"accoutumés","word_nosc":"accoutumes","lemma":"accoutumer","pos":"VER"} ,
		{"word":"accouvée","word_nosc":"accouvee","lemma":"accouver","pos":"VER"} ,
		{"word":"accrocha","word_nosc":"accrocha","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochai","word_nosc":"accrochai","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochaient","word_nosc":"accrochaient","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochais","word_nosc":"accrochais","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochait","word_nosc":"accrochait","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochant","word_nosc":"accrochant","lemma":"accrocher","pos":"VER"} ,
		{"word":"accroche","word_nosc":"accroche","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochent","word_nosc":"accrochent","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrocher","word_nosc":"accrocher","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochera","word_nosc":"accrochera","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrocherai","word_nosc":"accrocherai","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrocheraient","word_nosc":"accrocheraient","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrocherais","word_nosc":"accrocherais","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrocherait","word_nosc":"accrocherait","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrocherez","word_nosc":"accrocherez","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrocheriez","word_nosc":"accrocheriez","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrocheront","word_nosc":"accrocheront","lemma":"accrocher","pos":"VER"} ,
		{"word":"accroches","word_nosc":"accroches","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochez","word_nosc":"accrochez","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochiez","word_nosc":"accrochiez","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochions","word_nosc":"accrochions","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochons","word_nosc":"accrochons","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochât","word_nosc":"accrochat","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochèrent","word_nosc":"accrocherent","lemma":"accrocher","pos":"VER"} ,
		{"word":"accroché","word_nosc":"accroche","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochée","word_nosc":"accrochee","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochées","word_nosc":"accrochees","lemma":"accrocher","pos":"VER"} ,
		{"word":"accrochés","word_nosc":"accroches","lemma":"accrocher","pos":"VER"} ,
		{"word":"accroire","word_nosc":"accroire","lemma":"accroire","pos":"VER"} ,
		{"word":"accrois","word_nosc":"accrois","lemma":"accroître","pos":"VER"} ,
		{"word":"accroissaient","word_nosc":"accroissaient","lemma":"accroître","pos":"VER"} ,
		{"word":"accroissait","word_nosc":"accroissait","lemma":"accroître","pos":"VER"} ,
		{"word":"accroissant","word_nosc":"accroissant","lemma":"accroître","pos":"VER"} ,
		{"word":"accroisse","word_nosc":"accroisse","lemma":"accroître","pos":"VER"} ,
		{"word":"accroissent","word_nosc":"accroissent","lemma":"accroître","pos":"VER"} ,
		{"word":"accroissez","word_nosc":"accroissez","lemma":"accroître","pos":"VER"} ,
		{"word":"accroupi","word_nosc":"accroupi","lemma":"accroupir","pos":"VER"} ,
		{"word":"accroupie","word_nosc":"accroupie","lemma":"accroupir","pos":"VER"} ,
		{"word":"accroupies","word_nosc":"accroupies","lemma":"accroupir","pos":"VER"} ,
		{"word":"accroupir","word_nosc":"accroupir","lemma":"accroupir","pos":"VER"} ,
		{"word":"accroupirent","word_nosc":"accroupirent","lemma":"accroupir","pos":"VER"} ,
		{"word":"accroupis","word_nosc":"accroupis","lemma":"accroupir","pos":"VER"} ,
		{"word":"accroupissaient","word_nosc":"accroupissaient","lemma":"accroupir","pos":"VER"} ,
		{"word":"accroupissais","word_nosc":"accroupissais","lemma":"accroupir","pos":"VER"} ,
		{"word":"accroupissait","word_nosc":"accroupissait","lemma":"accroupir","pos":"VER"} ,
		{"word":"accroupissant","word_nosc":"accroupissant","lemma":"accroupir","pos":"VER"} ,
		{"word":"accroupisse","word_nosc":"accroupisse","lemma":"accroupir","pos":"VER"} ,
		{"word":"accroupissent","word_nosc":"accroupissent","lemma":"accroupir","pos":"VER"} ,
		{"word":"accroupissons","word_nosc":"accroupissons","lemma":"accroupir","pos":"VER"} ,
		{"word":"accroupit","word_nosc":"accroupit","lemma":"accroupir","pos":"VER"} ,
		{"word":"accroît","word_nosc":"accroit","lemma":"accroître","pos":"VER"} ,
		{"word":"accroîtra","word_nosc":"accroitra","lemma":"accroître","pos":"VER"} ,
		{"word":"accroîtrait","word_nosc":"accroitrait","lemma":"accroître","pos":"VER"} ,
		{"word":"accroître","word_nosc":"accroitre","lemma":"accroître","pos":"VER"} ,
		{"word":"accroîtront","word_nosc":"accroitront","lemma":"accroître","pos":"VER"} ,
		{"word":"accru","word_nosc":"accru","lemma":"accroître","pos":"VER"} ,
		{"word":"accrue","word_nosc":"accrue","lemma":"accroître","pos":"VER"} ,
		{"word":"accrues","word_nosc":"accrues","lemma":"accroître","pos":"VER"} ,
		{"word":"accrurent","word_nosc":"accrurent","lemma":"accroître","pos":"VER"} ,
		{"word":"accrus","word_nosc":"accrus","lemma":"accroître","pos":"VER"} ,
		{"word":"accrut","word_nosc":"accrut","lemma":"accroître","pos":"VER"} ,
		{"word":"accrédita","word_nosc":"accredita","lemma":"accréditer","pos":"VER"} ,
		{"word":"accréditaient","word_nosc":"accreditaient","lemma":"accréditer","pos":"VER"} ,
		{"word":"accréditait","word_nosc":"accreditait","lemma":"accréditer","pos":"VER"} ,
		{"word":"accréditant","word_nosc":"accreditant","lemma":"accréditer","pos":"VER"} ,
		{"word":"accréditer","word_nosc":"accrediter","lemma":"accréditer","pos":"VER"} ,
		{"word":"accrédité","word_nosc":"accredite","lemma":"accréditer","pos":"VER"} ,
		{"word":"accréditée","word_nosc":"accreditee","lemma":"accréditer","pos":"VER"} ,
		{"word":"accrédités","word_nosc":"accredites","lemma":"accréditer","pos":"VER"} ,
		{"word":"accrût","word_nosc":"accrut","lemma":"accroître","pos":"VER"} ,
		{"word":"accueillaient","word_nosc":"accueillaient","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillais","word_nosc":"accueillais","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillait","word_nosc":"accueillait","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillant","word_nosc":"accueillant","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueille","word_nosc":"accueille","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillent","word_nosc":"accueillent","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillera","word_nosc":"accueillera","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillerai","word_nosc":"accueillerai","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueilleraient","word_nosc":"accueilleraient","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillerait","word_nosc":"accueillerait","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueilleras","word_nosc":"accueilleras","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillerez","word_nosc":"accueillerez","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueilleriez","word_nosc":"accueilleriez","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillerons","word_nosc":"accueillerons","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueilleront","word_nosc":"accueilleront","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueilles","word_nosc":"accueilles","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillez","word_nosc":"accueillez","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueilli","word_nosc":"accueilli","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillie","word_nosc":"accueillie","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillies","word_nosc":"accueillies","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillir","word_nosc":"accueillir","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillirent","word_nosc":"accueillirent","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillis","word_nosc":"accueillis","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillit","word_nosc":"accueillit","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillons","word_nosc":"accueillons","lemma":"accueillir","pos":"VER"} ,
		{"word":"accueillît","word_nosc":"accueillit","lemma":"accueillir","pos":"VER"} ,
		{"word":"accula","word_nosc":"accula","lemma":"acculer","pos":"VER"} ,
		{"word":"acculaient","word_nosc":"acculaient","lemma":"acculer","pos":"VER"} ,
		{"word":"acculais","word_nosc":"acculais","lemma":"acculer","pos":"VER"} ,
		{"word":"acculait","word_nosc":"acculait","lemma":"acculer","pos":"VER"} ,
		{"word":"acculant","word_nosc":"acculant","lemma":"acculer","pos":"VER"} ,
		{"word":"accule","word_nosc":"accule","lemma":"acculer","pos":"VER"} ,
		{"word":"acculent","word_nosc":"acculent","lemma":"acculer","pos":"VER"} ,
		{"word":"acculer","word_nosc":"acculer","lemma":"acculer","pos":"VER"} ,
		{"word":"acculera","word_nosc":"acculera","lemma":"acculer","pos":"VER"} ,
		{"word":"acculeraient","word_nosc":"acculeraient","lemma":"acculer","pos":"VER"} ,
		{"word":"acculez","word_nosc":"acculez","lemma":"acculer","pos":"VER"} ,
		{"word":"acculât","word_nosc":"acculat","lemma":"acculer","pos":"VER"} ,
		{"word":"acculèrent","word_nosc":"acculerent","lemma":"acculer","pos":"VER"} ,
		{"word":"acculé","word_nosc":"accule","lemma":"acculer","pos":"VER"} ,
		{"word":"acculée","word_nosc":"acculee","lemma":"acculer","pos":"VER"} ,
		{"word":"acculées","word_nosc":"acculees","lemma":"acculer","pos":"VER"} ,
		{"word":"acculés","word_nosc":"accules","lemma":"acculer","pos":"VER"} ,
		{"word":"accumula","word_nosc":"accumula","lemma":"accumuler","pos":"VER"} ,
		{"word":"accumulai","word_nosc":"accumulai","lemma":"accumuler","pos":"VER"} ,
		{"word":"accumulaient","word_nosc":"accumulaient","lemma":"accumuler","pos":"VER"} ,
		{"word":"accumulais","word_nosc":"accumulais","lemma":"accumuler","pos":"VER"} ,
		{"word":"accumulait","word_nosc":"accumulait","lemma":"accumuler","pos":"VER"} ,
		{"word":"accumulant","word_nosc":"accumulant","lemma":"accumuler","pos":"VER"} ,
		{"word":"accumule","word_nosc":"accumule","lemma":"accumuler","pos":"VER"} ,
		{"word":"accumulent","word_nosc":"accumulent","lemma":"accumuler","pos":"VER"} ,
		{"word":"accumuler","word_nosc":"accumuler","lemma":"accumuler","pos":"VER"} ,
		{"word":"accumulerez","word_nosc":"accumulerez","lemma":"accumuler","pos":"VER"} ,
		{"word":"accumulez","word_nosc":"accumulez","lemma":"accumuler","pos":"VER"} ,
		{"word":"accumulèrent","word_nosc":"accumulerent","lemma":"accumuler","pos":"VER"} ,
		{"word":"accumulé","word_nosc":"accumule","lemma":"accumuler","pos":"VER"} ,
		{"word":"accumulée","word_nosc":"accumulee","lemma":"accumuler","pos":"VER"} ,
		{"word":"accumulées","word_nosc":"accumulees","lemma":"accumuler","pos":"VER"} ,
		{"word":"accumulés","word_nosc":"accumules","lemma":"accumuler","pos":"VER"} ,
		{"word":"accusa","word_nosc":"accusa","lemma":"accuser","pos":"VER"} ,
		{"word":"accusai","word_nosc":"accusai","lemma":"accuser","pos":"VER"} ,
		{"word":"accusaient","word_nosc":"accusaient","lemma":"accuser","pos":"VER"} ,
		{"word":"accusais","word_nosc":"accusais","lemma":"accuser","pos":"VER"} ,
		{"word":"accusait","word_nosc":"accusait","lemma":"accuser","pos":"VER"} ,
		{"word":"accusant","word_nosc":"accusant","lemma":"accuser","pos":"VER"} ,
		{"word":"accuse","word_nosc":"accuse","lemma":"accuser","pos":"VER"} ,
		{"word":"accusent","word_nosc":"accusent","lemma":"accuser","pos":"VER"} ,
		{"word":"accuser","word_nosc":"accuser","lemma":"accuser","pos":"VER"} ,
		{"word":"accusera","word_nosc":"accusera","lemma":"accuser","pos":"VER"} ,
		{"word":"accuseraient","word_nosc":"accuseraient","lemma":"accuser","pos":"VER"} ,
		{"word":"accuserais","word_nosc":"accuserais","lemma":"accuser","pos":"VER"} ,
		{"word":"accuserait","word_nosc":"accuserait","lemma":"accuser","pos":"VER"} ,
		{"word":"accuserez","word_nosc":"accuserez","lemma":"accuser","pos":"VER"} ,
		{"word":"accuseriez","word_nosc":"accuseriez","lemma":"accuser","pos":"VER"} ,
		{"word":"accuserions","word_nosc":"accuserions","lemma":"accuser","pos":"VER"} ,
		{"word":"accuserons","word_nosc":"accuserons","lemma":"accuser","pos":"VER"} ,
		{"word":"accuseront","word_nosc":"accuseront","lemma":"accuser","pos":"VER"} ,
		{"word":"accuses","word_nosc":"accuses","lemma":"accuser","pos":"VER"} ,
		{"word":"accusez","word_nosc":"accusez","lemma":"accuser","pos":"VER"} ,
		{"word":"accusiez","word_nosc":"accusiez","lemma":"accuser","pos":"VER"} ,
		{"word":"accusions","word_nosc":"accusions","lemma":"accuser","pos":"VER"} ,
		{"word":"accusons","word_nosc":"accusons","lemma":"accuser","pos":"VER"} ,
		{"word":"accusât","word_nosc":"accusat","lemma":"accuser","pos":"VER"} ,
		{"word":"accusèrent","word_nosc":"accuserent","lemma":"accuser","pos":"VER"} ,
		{"word":"accusé","word_nosc":"accuse","lemma":"accuser","pos":"VER"} ,
		{"word":"accusée","word_nosc":"accusee","lemma":"accuser","pos":"VER"} ,
		{"word":"accusées","word_nosc":"accusees","lemma":"accuser","pos":"VER"} ,
		{"word":"accusés","word_nosc":"accuses","lemma":"accuser","pos":"VER"} ,
		{"word":"accède","word_nosc":"accede","lemma":"accéder","pos":"VER"} ,
		{"word":"accèdent","word_nosc":"accedent","lemma":"accéder","pos":"VER"} ,
		{"word":"accèdes","word_nosc":"accedes","lemma":"accéder","pos":"VER"} ,
		{"word":"accéda","word_nosc":"acceda","lemma":"accéder","pos":"VER"} ,
		{"word":"accédai","word_nosc":"accedai","lemma":"accéder","pos":"VER"} ,
		{"word":"accédaient","word_nosc":"accedaient","lemma":"accéder","pos":"VER"} ,
		{"word":"accédais","word_nosc":"accedais","lemma":"accéder","pos":"VER"} ,
		{"word":"accédait","word_nosc":"accedait","lemma":"accéder","pos":"VER"} ,
		{"word":"accédant","word_nosc":"accedant","lemma":"accéder","pos":"VER"} ,
		{"word":"accéder","word_nosc":"acceder","lemma":"accéder","pos":"VER"} ,
		{"word":"accédera","word_nosc":"accedera","lemma":"accéder","pos":"VER"} ,
		{"word":"accéderai","word_nosc":"accederai","lemma":"accéder","pos":"VER"} ,
		{"word":"accéderaient","word_nosc":"accederaient","lemma":"accéder","pos":"VER"} ,
		{"word":"accéderais","word_nosc":"accederais","lemma":"accéder","pos":"VER"} ,
		{"word":"accéderait","word_nosc":"accederait","lemma":"accéder","pos":"VER"} ,
		{"word":"accéderas","word_nosc":"accederas","lemma":"accéder","pos":"VER"} ,
		{"word":"accédez","word_nosc":"accedez","lemma":"accéder","pos":"VER"} ,
		{"word":"accédons","word_nosc":"accedons","lemma":"accéder","pos":"VER"} ,
		{"word":"accédâmes","word_nosc":"accedames","lemma":"accéder","pos":"VER"} ,
		{"word":"accédé","word_nosc":"accede","lemma":"accéder","pos":"VER"} ,
		{"word":"accélère","word_nosc":"accelere","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélèrent","word_nosc":"accelerent","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélères","word_nosc":"acceleres","lemma":"accélérer","pos":"VER"} ,
		{"word":"accéléra","word_nosc":"accelera","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélérai","word_nosc":"accelerai","lemma":"accélérer","pos":"VER"} ,
		{"word":"accéléraient","word_nosc":"acceleraient","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélérais","word_nosc":"accelerais","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélérait","word_nosc":"accelerait","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélérant","word_nosc":"accelerant","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélérer","word_nosc":"accelerer","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélérera","word_nosc":"accelerera","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélérerait","word_nosc":"accelererait","lemma":"accélérer","pos":"VER"} ,
		{"word":"accéléreras","word_nosc":"accelereras","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélérez","word_nosc":"accelerez","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélériez","word_nosc":"acceleriez","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélérons","word_nosc":"accelerons","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélérèrent","word_nosc":"accelererent","lemma":"accélérer","pos":"VER"} ,
		{"word":"accéléré","word_nosc":"accelere","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélérée","word_nosc":"acceleree","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélérées","word_nosc":"accelerees","lemma":"accélérer","pos":"VER"} ,
		{"word":"accélérés","word_nosc":"acceleres","lemma":"accélérer","pos":"VER"} ,
		{"word":"achalandaient","word_nosc":"achalandaient","lemma":"achalander","pos":"VER"} ,
		{"word":"achalandés","word_nosc":"achalandes","lemma":"achalander","pos":"VER"} ,
		{"word":"achaler","word_nosc":"achaler","lemma":"achaler","pos":"VER"} ,
		{"word":"acharna","word_nosc":"acharna","lemma":"acharner","pos":"VER"} ,
		{"word":"acharnai","word_nosc":"acharnai","lemma":"acharner","pos":"VER"} ,
		{"word":"acharnaient","word_nosc":"acharnaient","lemma":"acharner","pos":"VER"} ,
		{"word":"acharnais","word_nosc":"acharnais","lemma":"acharner","pos":"VER"} ,
		{"word":"acharnait","word_nosc":"acharnait","lemma":"acharner","pos":"VER"} ,
		{"word":"acharnant","word_nosc":"acharnant","lemma":"acharner","pos":"VER"} ,
		{"word":"acharne","word_nosc":"acharne","lemma":"acharner","pos":"VER"} ,
		{"word":"acharnent","word_nosc":"acharnent","lemma":"acharner","pos":"VER"} ,
		{"word":"acharner","word_nosc":"acharner","lemma":"acharner","pos":"VER"} ,
		{"word":"acharnera","word_nosc":"acharnera","lemma":"acharner","pos":"VER"} ,
		{"word":"acharnerai","word_nosc":"acharnerai","lemma":"acharner","pos":"VER"} ,
		{"word":"acharnerait","word_nosc":"acharnerait","lemma":"acharner","pos":"VER"} ,
		{"word":"acharnes","word_nosc":"acharnes","lemma":"acharner","pos":"VER"} ,
		{"word":"acharnez","word_nosc":"acharnez","lemma":"acharner","pos":"VER"} ,
		{"word":"acharnèrent","word_nosc":"acharnerent","lemma":"acharner","pos":"VER"} ,
		{"word":"acharné","word_nosc":"acharne","lemma":"acharner","pos":"VER"} ,
		{"word":"acharnée","word_nosc":"acharnee","lemma":"acharner","pos":"VER"} ,
		{"word":"acharnées","word_nosc":"acharnees","lemma":"acharner","pos":"VER"} ,
		{"word":"acharnés","word_nosc":"acharnes","lemma":"acharner","pos":"VER"} ,
		{"word":"achemina","word_nosc":"achemina","lemma":"acheminer","pos":"VER"} ,
		{"word":"acheminai","word_nosc":"acheminai","lemma":"acheminer","pos":"VER"} ,
		{"word":"acheminaient","word_nosc":"acheminaient","lemma":"acheminer","pos":"VER"} ,
		{"word":"acheminait","word_nosc":"acheminait","lemma":"acheminer","pos":"VER"} ,
		{"word":"acheminant","word_nosc":"acheminant","lemma":"acheminer","pos":"VER"} ,
		{"word":"achemine","word_nosc":"achemine","lemma":"acheminer","pos":"VER"} ,
		{"word":"acheminent","word_nosc":"acheminent","lemma":"acheminer","pos":"VER"} ,
		{"word":"acheminer","word_nosc":"acheminer","lemma":"acheminer","pos":"VER"} ,
		{"word":"achemineront","word_nosc":"achemineront","lemma":"acheminer","pos":"VER"} ,
		{"word":"acheminons","word_nosc":"acheminons","lemma":"acheminer","pos":"VER"} ,
		{"word":"acheminâmes","word_nosc":"acheminames","lemma":"acheminer","pos":"VER"} ,
		{"word":"acheminèrent","word_nosc":"acheminerent","lemma":"acheminer","pos":"VER"} ,
		{"word":"acheminé","word_nosc":"achemine","lemma":"acheminer","pos":"VER"} ,
		{"word":"acheminée","word_nosc":"acheminee","lemma":"acheminer","pos":"VER"} ,
		{"word":"acheminées","word_nosc":"acheminees","lemma":"acheminer","pos":"VER"} ,
		{"word":"acheminés","word_nosc":"achemines","lemma":"acheminer","pos":"VER"} ,
		{"word":"acheta","word_nosc":"acheta","lemma":"acheter","pos":"VER"} ,
		{"word":"achetai","word_nosc":"achetai","lemma":"acheter","pos":"VER"} ,
		{"word":"achetaient","word_nosc":"achetaient","lemma":"acheter","pos":"VER"} ,
		{"word":"achetais","word_nosc":"achetais","lemma":"acheter","pos":"VER"} ,
		{"word":"achetait","word_nosc":"achetait","lemma":"acheter","pos":"VER"} ,
		{"word":"achetant","word_nosc":"achetant","lemma":"acheter","pos":"VER"} ,
		{"word":"achetassent","word_nosc":"achetassent","lemma":"acheter","pos":"VER"} ,
		{"word":"acheter","word_nosc":"acheter","lemma":"acheter","pos":"VER"} ,
		{"word":"achetez","word_nosc":"achetez","lemma":"acheter","pos":"VER"} ,
		{"word":"achetiez","word_nosc":"achetiez","lemma":"acheter","pos":"VER"} ,
		{"word":"achetions","word_nosc":"achetions","lemma":"acheter","pos":"VER"} ,
		{"word":"achetons","word_nosc":"achetons","lemma":"acheter","pos":"VER"} ,
		{"word":"achetâmes","word_nosc":"achetames","lemma":"acheter","pos":"VER"} ,
		{"word":"achetât","word_nosc":"achetat","lemma":"acheter","pos":"VER"} ,
		{"word":"achetèrent","word_nosc":"acheterent","lemma":"acheter","pos":"VER"} ,
		{"word":"acheté","word_nosc":"achete","lemma":"acheter","pos":"VER"} ,
		{"word":"achetée","word_nosc":"achetee","lemma":"acheter","pos":"VER"} ,
		{"word":"achetées","word_nosc":"achetees","lemma":"acheter","pos":"VER"} ,
		{"word":"achetés","word_nosc":"achetes","lemma":"acheter","pos":"VER"} ,
		{"word":"acheva","word_nosc":"acheva","lemma":"achever","pos":"VER"} ,
		{"word":"achevai","word_nosc":"achevai","lemma":"achever","pos":"VER"} ,
		{"word":"achevaient","word_nosc":"achevaient","lemma":"achever","pos":"VER"} ,
		{"word":"achevais","word_nosc":"achevais","lemma":"achever","pos":"VER"} ,
		{"word":"achevait","word_nosc":"achevait","lemma":"achever","pos":"VER"} ,
		{"word":"achevant","word_nosc":"achevant","lemma":"achever","pos":"VER"} ,
		{"word":"achever","word_nosc":"achever","lemma":"achever","pos":"VER"} ,
		{"word":"achevez","word_nosc":"achevez","lemma":"achever","pos":"VER"} ,
		{"word":"acheviez","word_nosc":"acheviez","lemma":"achever","pos":"VER"} ,
		{"word":"achevions","word_nosc":"achevions","lemma":"achever","pos":"VER"} ,
		{"word":"achevons","word_nosc":"achevons","lemma":"achever","pos":"VER"} ,
		{"word":"achevâmes","word_nosc":"achevames","lemma":"achever","pos":"VER"} ,
		{"word":"achevât","word_nosc":"achevat","lemma":"achever","pos":"VER"} ,
		{"word":"achevèrent","word_nosc":"acheverent","lemma":"achever","pos":"VER"} ,
		{"word":"achevé","word_nosc":"acheve","lemma":"achever","pos":"VER"} ,
		{"word":"achevée","word_nosc":"achevee","lemma":"achever","pos":"VER"} ,
		{"word":"achevées","word_nosc":"achevees","lemma":"achever","pos":"VER"} ,
		{"word":"achevés","word_nosc":"acheves","lemma":"achever","pos":"VER"} ,
		{"word":"achoppa","word_nosc":"achoppa","lemma":"achopper","pos":"VER"} ,
		{"word":"achoppai","word_nosc":"achoppai","lemma":"achopper","pos":"VER"} ,
		{"word":"achoppaient","word_nosc":"achoppaient","lemma":"achopper","pos":"VER"} ,
		{"word":"achoppais","word_nosc":"achoppais","lemma":"achopper","pos":"VER"} ,
		{"word":"achoppait","word_nosc":"achoppait","lemma":"achopper","pos":"VER"} ,
		{"word":"achoppe","word_nosc":"achoppe","lemma":"achopper","pos":"VER"} ,
		{"word":"achopper","word_nosc":"achopper","lemma":"achopper","pos":"VER"} ,
		{"word":"achoppé","word_nosc":"achoppe","lemma":"achopper","pos":"VER"} ,
		{"word":"achoppée","word_nosc":"achoppee","lemma":"achopper","pos":"VER"} ,
		{"word":"achète","word_nosc":"achete","lemma":"acheter","pos":"VER"} ,
		{"word":"achètent","word_nosc":"achetent","lemma":"acheter","pos":"VER"} ,
		{"word":"achètera","word_nosc":"achetera","lemma":"acheter","pos":"VER"} ,
		{"word":"achèterai","word_nosc":"acheterai","lemma":"acheter","pos":"VER"} ,
		{"word":"achèteraient","word_nosc":"acheteraient","lemma":"acheter","pos":"VER"} ,
		{"word":"achèterais","word_nosc":"acheterais","lemma":"acheter","pos":"VER"} ,
		{"word":"achèterait","word_nosc":"acheterait","lemma":"acheter","pos":"VER"} ,
		{"word":"achèteras","word_nosc":"acheteras","lemma":"acheter","pos":"VER"} ,
		{"word":"achèterez","word_nosc":"acheterez","lemma":"acheter","pos":"VER"} ,
		{"word":"achèteriez","word_nosc":"acheteriez","lemma":"acheter","pos":"VER"} ,
		{"word":"achèterions","word_nosc":"acheterions","lemma":"acheter","pos":"VER"} ,
		{"word":"achèterons","word_nosc":"acheterons","lemma":"acheter","pos":"VER"} ,
		{"word":"achèteront","word_nosc":"acheteront","lemma":"acheter","pos":"VER"} ,
		{"word":"achètes","word_nosc":"achetes","lemma":"acheter","pos":"VER"} ,
		{"word":"achève","word_nosc":"acheve","lemma":"achever","pos":"VER"} ,
		{"word":"achèvent","word_nosc":"achevent","lemma":"achever","pos":"VER"} ,
		{"word":"achèvera","word_nosc":"achevera","lemma":"achever","pos":"VER"} ,
		{"word":"achèverai","word_nosc":"acheverai","lemma":"achever","pos":"VER"} ,
		{"word":"achèveraient","word_nosc":"acheveraient","lemma":"achever","pos":"VER"} ,
		{"word":"achèverait","word_nosc":"acheverait","lemma":"achever","pos":"VER"} ,
		{"word":"achèverez","word_nosc":"acheverez","lemma":"achever","pos":"VER"} ,
		{"word":"achèverons","word_nosc":"acheverons","lemma":"achever","pos":"VER"} ,
		{"word":"achèveront","word_nosc":"acheveront","lemma":"achever","pos":"VER"} ,
		{"word":"achèves","word_nosc":"acheves","lemma":"achever","pos":"VER"} ,
		{"word":"acidulé","word_nosc":"acidule","lemma":"aciduler","pos":"VER"} ,
		{"word":"acidulée","word_nosc":"acidulee","lemma":"aciduler","pos":"VER"} ,
		{"word":"acidulées","word_nosc":"acidulees","lemma":"aciduler","pos":"VER"} ,
		{"word":"acidulés","word_nosc":"acidules","lemma":"aciduler","pos":"VER"} ,
		{"word":"acoquina","word_nosc":"acoquina","lemma":"acoquiner","pos":"VER"} ,
		{"word":"acoquine","word_nosc":"acoquine","lemma":"acoquiner","pos":"VER"} ,
		{"word":"acoquiner","word_nosc":"acoquiner","lemma":"acoquiner","pos":"VER"} ,
		{"word":"acoquiné","word_nosc":"acoquine","lemma":"acoquiner","pos":"VER"} ,
		{"word":"acoquinée","word_nosc":"acoquinee","lemma":"acoquiner","pos":"VER"} ,
		{"word":"acoquinés","word_nosc":"acoquines","lemma":"acoquiner","pos":"VER"} ,
		{"word":"acquerra","word_nosc":"acquerra","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquerrai","word_nosc":"acquerrai","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquerraient","word_nosc":"acquerraient","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquerront","word_nosc":"acquerront","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquiers","word_nosc":"acquiers","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquiert","word_nosc":"acquiert","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquiesce","word_nosc":"acquiesce","lemma":"acquiescer","pos":"VER"} ,
		{"word":"acquiescent","word_nosc":"acquiescent","lemma":"acquiescer","pos":"VER"} ,
		{"word":"acquiescer","word_nosc":"acquiescer","lemma":"acquiescer","pos":"VER"} ,
		{"word":"acquiesceront","word_nosc":"acquiesceront","lemma":"acquiescer","pos":"VER"} ,
		{"word":"acquiesces","word_nosc":"acquiesces","lemma":"acquiescer","pos":"VER"} ,
		{"word":"acquiescèrent","word_nosc":"acquiescerent","lemma":"acquiescer","pos":"VER"} ,
		{"word":"acquiescé","word_nosc":"acquiesce","lemma":"acquiescer","pos":"VER"} ,
		{"word":"acquiesça","word_nosc":"acquiesca","lemma":"acquiescer","pos":"VER"} ,
		{"word":"acquiesçai","word_nosc":"acquiescai","lemma":"acquiescer","pos":"VER"} ,
		{"word":"acquiesçaient","word_nosc":"acquiescaient","lemma":"acquiescer","pos":"VER"} ,
		{"word":"acquiesçais","word_nosc":"acquiescais","lemma":"acquiescer","pos":"VER"} ,
		{"word":"acquiesçait","word_nosc":"acquiescait","lemma":"acquiescer","pos":"VER"} ,
		{"word":"acquiesçant","word_nosc":"acquiescant","lemma":"acquiescer","pos":"VER"} ,
		{"word":"acquiesçons","word_nosc":"acquiescons","lemma":"acquiescer","pos":"VER"} ,
		{"word":"acquirent","word_nosc":"acquirent","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquis","word_nosc":"acquis","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquise","word_nosc":"acquise","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquises","word_nosc":"acquises","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquit","word_nosc":"acquit","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquitta","word_nosc":"acquitta","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquittai","word_nosc":"acquittai","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquittaient","word_nosc":"acquittaient","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquittais","word_nosc":"acquittais","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquittait","word_nosc":"acquittait","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquittant","word_nosc":"acquittant","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquitte","word_nosc":"acquitte","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquittent","word_nosc":"acquittent","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquitter","word_nosc":"acquitter","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquittera","word_nosc":"acquittera","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquitterai","word_nosc":"acquitterai","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquitterais","word_nosc":"acquitterais","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquitterait","word_nosc":"acquitterait","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquitterez","word_nosc":"acquitterez","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquittes","word_nosc":"acquittes","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquittez","word_nosc":"acquittez","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquittions","word_nosc":"acquittions","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquittât","word_nosc":"acquittat","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquitté","word_nosc":"acquitte","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquittée","word_nosc":"acquittee","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquittés","word_nosc":"acquittes","lemma":"acquitter","pos":"VER"} ,
		{"word":"acquière","word_nosc":"acquiere","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquièrent","word_nosc":"acquierent","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquéraient","word_nosc":"acqueraient","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquérais","word_nosc":"acquerais","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquérait","word_nosc":"acquerait","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquérant","word_nosc":"acquerant","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquérions","word_nosc":"acquerions","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquérir","word_nosc":"acquerir","lemma":"acquérir","pos":"VER"} ,
		{"word":"acquérons","word_nosc":"acquerons","lemma":"acquérir","pos":"VER"} ,
		{"word":"acta","word_nosc":"acta","lemma":"acter","pos":"VER"} ,
		{"word":"actionna","word_nosc":"actionna","lemma":"actionner","pos":"VER"} ,
		{"word":"actionnai","word_nosc":"actionnai","lemma":"actionner","pos":"VER"} ,
		{"word":"actionnaient","word_nosc":"actionnaient","lemma":"actionner","pos":"VER"} ,
		{"word":"actionnais","word_nosc":"actionnais","lemma":"actionner","pos":"VER"} ,
		{"word":"actionnait","word_nosc":"actionnait","lemma":"actionner","pos":"VER"} ,
		{"word":"actionnant","word_nosc":"actionnant","lemma":"actionner","pos":"VER"} ,
		{"word":"actionne","word_nosc":"actionne","lemma":"actionner","pos":"VER"} ,
		{"word":"actionnent","word_nosc":"actionnent","lemma":"actionner","pos":"VER"} ,
		{"word":"actionner","word_nosc":"actionner","lemma":"actionner","pos":"VER"} ,
		{"word":"actionneront","word_nosc":"actionneront","lemma":"actionner","pos":"VER"} ,
		{"word":"actionnez","word_nosc":"actionnez","lemma":"actionner","pos":"VER"} ,
		{"word":"actionnât","word_nosc":"actionnat","lemma":"actionner","pos":"VER"} ,
		{"word":"actionné","word_nosc":"actionne","lemma":"actionner","pos":"VER"} ,
		{"word":"actionnée","word_nosc":"actionnee","lemma":"actionner","pos":"VER"} ,
		{"word":"actionnées","word_nosc":"actionnees","lemma":"actionner","pos":"VER"} ,
		{"word":"actionnés","word_nosc":"actionnes","lemma":"actionner","pos":"VER"} ,
		{"word":"actions","word_nosc":"actions","lemma":"acter","pos":"VER"} ,
		{"word":"activa","word_nosc":"activa","lemma":"activer","pos":"VER"} ,
		{"word":"activai","word_nosc":"activai","lemma":"activer","pos":"VER"} ,
		{"word":"activaient","word_nosc":"activaient","lemma":"activer","pos":"VER"} ,
		{"word":"activait","word_nosc":"activait","lemma":"activer","pos":"VER"} ,
		{"word":"activant","word_nosc":"activant","lemma":"activer","pos":"VER"} ,
		{"word":"active","word_nosc":"active","lemma":"activer","pos":"VER"} ,
		{"word":"activent","word_nosc":"activent","lemma":"activer","pos":"VER"} ,
		{"word":"activer","word_nosc":"activer","lemma":"activer","pos":"VER"} ,
		{"word":"activera","word_nosc":"activera","lemma":"activer","pos":"VER"} ,
		{"word":"activerai","word_nosc":"activerai","lemma":"activer","pos":"VER"} ,
		{"word":"activerait","word_nosc":"activerait","lemma":"activer","pos":"VER"} ,
		{"word":"activeras","word_nosc":"activeras","lemma":"activer","pos":"VER"} ,
		{"word":"actives","word_nosc":"actives","lemma":"activer","pos":"VER"} ,
		{"word":"activez","word_nosc":"activez","lemma":"activer","pos":"VER"} ,
		{"word":"activons","word_nosc":"activons","lemma":"activer","pos":"VER"} ,
		{"word":"activèrent","word_nosc":"activerent","lemma":"activer","pos":"VER"} ,
		{"word":"activé","word_nosc":"active","lemma":"activer","pos":"VER"} ,
		{"word":"activée","word_nosc":"activee","lemma":"activer","pos":"VER"} ,
		{"word":"activées","word_nosc":"activees","lemma":"activer","pos":"VER"} ,
		{"word":"activés","word_nosc":"actives","lemma":"activer","pos":"VER"} ,
		{"word":"actualise","word_nosc":"actualise","lemma":"actualiser","pos":"VER"} ,
		{"word":"actualiser","word_nosc":"actualiser","lemma":"actualiser","pos":"VER"} ,
		{"word":"actualisez","word_nosc":"actualisez","lemma":"actualiser","pos":"VER"} ,
		{"word":"actualisé","word_nosc":"actualise","lemma":"actualiser","pos":"VER"} ,
		{"word":"actualisée","word_nosc":"actualisee","lemma":"actualiser","pos":"VER"} ,
		{"word":"actualisées","word_nosc":"actualisees","lemma":"actualiser","pos":"VER"} ,
		{"word":"acéré","word_nosc":"acere","lemma":"acérer","pos":"VER"} ,
		{"word":"acérées","word_nosc":"acerees","lemma":"acérer","pos":"VER"} ,
		{"word":"acérés","word_nosc":"aceres","lemma":"acérer","pos":"VER"} ,
		{"word":"adapta","word_nosc":"adapta","lemma":"adapter","pos":"VER"} ,
		{"word":"adaptai","word_nosc":"adaptai","lemma":"adapter","pos":"VER"} ,
		{"word":"adaptaient","word_nosc":"adaptaient","lemma":"adapter","pos":"VER"} ,
		{"word":"adaptais","word_nosc":"adaptais","lemma":"adapter","pos":"VER"} ,
		{"word":"adaptait","word_nosc":"adaptait","lemma":"adapter","pos":"VER"} ,
		{"word":"adaptant","word_nosc":"adaptant","lemma":"adapter","pos":"VER"} ,
		{"word":"adapte","word_nosc":"adapte","lemma":"adapter","pos":"VER"} ,
		{"word":"adaptent","word_nosc":"adaptent","lemma":"adapter","pos":"VER"} ,
		{"word":"adapter","word_nosc":"adapter","lemma":"adapter","pos":"VER"} ,
		{"word":"adaptera","word_nosc":"adaptera","lemma":"adapter","pos":"VER"} ,
		{"word":"adapterai","word_nosc":"adapterai","lemma":"adapter","pos":"VER"} ,
		{"word":"adapterais","word_nosc":"adapterais","lemma":"adapter","pos":"VER"} ,
		{"word":"adapterait","word_nosc":"adapterait","lemma":"adapter","pos":"VER"} ,
		{"word":"adapteras","word_nosc":"adapteras","lemma":"adapter","pos":"VER"} ,
		{"word":"adapterez","word_nosc":"adapterez","lemma":"adapter","pos":"VER"} ,
		{"word":"adapterons","word_nosc":"adapterons","lemma":"adapter","pos":"VER"} ,
		{"word":"adapteront","word_nosc":"adapteront","lemma":"adapter","pos":"VER"} ,
		{"word":"adaptes","word_nosc":"adaptes","lemma":"adapter","pos":"VER"} ,
		{"word":"adaptez","word_nosc":"adaptez","lemma":"adapter","pos":"VER"} ,
		{"word":"adaptât","word_nosc":"adaptat","lemma":"adapter","pos":"VER"} ,
		{"word":"adaptèrent","word_nosc":"adapterent","lemma":"adapter","pos":"VER"} ,
		{"word":"adapté","word_nosc":"adapte","lemma":"adapter","pos":"VER"} ,
		{"word":"adaptée","word_nosc":"adaptee","lemma":"adapter","pos":"VER"} ,
		{"word":"adaptées","word_nosc":"adaptees","lemma":"adapter","pos":"VER"} ,
		{"word":"adaptés","word_nosc":"adaptes","lemma":"adapter","pos":"VER"} ,
		{"word":"additionnaient","word_nosc":"additionnaient","lemma":"additionner","pos":"VER"} ,
		{"word":"additionnais","word_nosc":"additionnais","lemma":"additionner","pos":"VER"} ,
		{"word":"additionnait","word_nosc":"additionnait","lemma":"additionner","pos":"VER"} ,
		{"word":"additionnant","word_nosc":"additionnant","lemma":"additionner","pos":"VER"} ,
		{"word":"additionne","word_nosc":"additionne","lemma":"additionner","pos":"VER"} ,
		{"word":"additionnent","word_nosc":"additionnent","lemma":"additionner","pos":"VER"} ,
		{"word":"additionner","word_nosc":"additionner","lemma":"additionner","pos":"VER"} ,
		{"word":"additionneraient","word_nosc":"additionneraient","lemma":"additionner","pos":"VER"} ,
		{"word":"additionnerait","word_nosc":"additionnerait","lemma":"additionner","pos":"VER"} ,
		{"word":"additionnez","word_nosc":"additionnez","lemma":"additionner","pos":"VER"} ,
		{"word":"additionné","word_nosc":"additionne","lemma":"additionner","pos":"VER"} ,
		{"word":"additionnée","word_nosc":"additionnee","lemma":"additionner","pos":"VER"} ,
		{"word":"additionnées","word_nosc":"additionnees","lemma":"additionner","pos":"VER"} ,
		{"word":"additionnés","word_nosc":"additionnes","lemma":"additionner","pos":"VER"} ,
		{"word":"adhère","word_nosc":"adhere","lemma":"adhérer","pos":"VER"} ,
		{"word":"adhèrent","word_nosc":"adherent","lemma":"adhérer","pos":"VER"} ,
		{"word":"adhéra","word_nosc":"adhera","lemma":"adhérer","pos":"VER"} ,
		{"word":"adhéraient","word_nosc":"adheraient","lemma":"adhérer","pos":"VER"} ,
		{"word":"adhérais","word_nosc":"adherais","lemma":"adhérer","pos":"VER"} ,
		{"word":"adhérait","word_nosc":"adherait","lemma":"adhérer","pos":"VER"} ,
		{"word":"adhérant","word_nosc":"adherant","lemma":"adhérer","pos":"VER"} ,
		{"word":"adhérer","word_nosc":"adherer","lemma":"adhérer","pos":"VER"} ,
		{"word":"adhérez","word_nosc":"adherez","lemma":"adhérer","pos":"VER"} ,
		{"word":"adhéré","word_nosc":"adhere","lemma":"adhérer","pos":"VER"} ,
		{"word":"adira","word_nosc":"adira","lemma":"adire","pos":"VER"} ,
		{"word":"adjoignait","word_nosc":"adjoignait","lemma":"adjoindre","pos":"VER"} ,
		{"word":"adjoignant","word_nosc":"adjoignant","lemma":"adjoindre","pos":"VER"} ,
		{"word":"adjoigne","word_nosc":"adjoigne","lemma":"adjoindre","pos":"VER"} ,
		{"word":"adjoignit","word_nosc":"adjoignit","lemma":"adjoindre","pos":"VER"} ,
		{"word":"adjoignît","word_nosc":"adjoignit","lemma":"adjoindre","pos":"VER"} ,
		{"word":"adjoindra","word_nosc":"adjoindra","lemma":"adjoindre","pos":"VER"} ,
		{"word":"adjoindre","word_nosc":"adjoindre","lemma":"adjoindre","pos":"VER"} ,
		{"word":"adjoindront","word_nosc":"adjoindront","lemma":"adjoindre","pos":"VER"} ,
		{"word":"adjoins","word_nosc":"adjoins","lemma":"adjoindre","pos":"VER"} ,
		{"word":"adjoint","word_nosc":"adjoint","lemma":"adjoindre","pos":"VER"} ,
		{"word":"adjointe","word_nosc":"adjointe","lemma":"adjoindre","pos":"VER"} ,
		{"word":"adjoints","word_nosc":"adjoints","lemma":"adjoindre","pos":"VER"} ,
		{"word":"adjuge","word_nosc":"adjuge","lemma":"adjuger","pos":"VER"} ,
		{"word":"adjugeait","word_nosc":"adjugeait","lemma":"adjuger","pos":"VER"} ,
		{"word":"adjugeant","word_nosc":"adjugeant","lemma":"adjuger","pos":"VER"} ,
		{"word":"adjuger","word_nosc":"adjuger","lemma":"adjuger","pos":"VER"} ,
		{"word":"adjugez","word_nosc":"adjugez","lemma":"adjuger","pos":"VER"} ,
		{"word":"adjugé","word_nosc":"adjuge","lemma":"adjuger","pos":"VER"} ,
		{"word":"adjugée","word_nosc":"adjugee","lemma":"adjuger","pos":"VER"} ,
		{"word":"adjugés","word_nosc":"adjuges","lemma":"adjuger","pos":"VER"} ,
		{"word":"adjura","word_nosc":"adjura","lemma":"adjurer","pos":"VER"} ,
		{"word":"adjurai","word_nosc":"adjurai","lemma":"adjurer","pos":"VER"} ,
		{"word":"adjuraient","word_nosc":"adjuraient","lemma":"adjurer","pos":"VER"} ,
		{"word":"adjurais","word_nosc":"adjurais","lemma":"adjurer","pos":"VER"} ,
		{"word":"adjurait","word_nosc":"adjurait","lemma":"adjurer","pos":"VER"} ,
		{"word":"adjurant","word_nosc":"adjurant","lemma":"adjurer","pos":"VER"} ,
		{"word":"adjure","word_nosc":"adjure","lemma":"adjurer","pos":"VER"} ,
		{"word":"adjurer","word_nosc":"adjurer","lemma":"adjurer","pos":"VER"} ,
		{"word":"adjurerai","word_nosc":"adjurerai","lemma":"adjurer","pos":"VER"} ,
		{"word":"adjurerait","word_nosc":"adjurerait","lemma":"adjurer","pos":"VER"} ,
		{"word":"adjuré","word_nosc":"adjure","lemma":"adjurer","pos":"VER"} ,
		{"word":"admet","word_nosc":"admet","lemma":"admettre","pos":"VER"} ,
		{"word":"admets","word_nosc":"admets","lemma":"admettre","pos":"VER"} ,
		{"word":"admettaient","word_nosc":"admettaient","lemma":"admettre","pos":"VER"} ,
		{"word":"admettais","word_nosc":"admettais","lemma":"admettre","pos":"VER"} ,
		{"word":"admettait","word_nosc":"admettait","lemma":"admettre","pos":"VER"} ,
		{"word":"admettant","word_nosc":"admettant","lemma":"admettre","pos":"VER"} ,
		{"word":"admette","word_nosc":"admette","lemma":"admettre","pos":"VER"} ,
		{"word":"admettent","word_nosc":"admettent","lemma":"admettre","pos":"VER"} ,
		{"word":"admettes","word_nosc":"admettes","lemma":"admettre","pos":"VER"} ,
		{"word":"admettez","word_nosc":"admettez","lemma":"admettre","pos":"VER"} ,
		{"word":"admettiez","word_nosc":"admettiez","lemma":"admettre","pos":"VER"} ,
		{"word":"admettions","word_nosc":"admettions","lemma":"admettre","pos":"VER"} ,
		{"word":"admettons","word_nosc":"admettons","lemma":"admettre","pos":"VER"} ,
		{"word":"admettra","word_nosc":"admettra","lemma":"admettre","pos":"VER"} ,
		{"word":"admettrai","word_nosc":"admettrai","lemma":"admettre","pos":"VER"} ,
		{"word":"admettraient","word_nosc":"admettraient","lemma":"admettre","pos":"VER"} ,
		{"word":"admettrais","word_nosc":"admettrais","lemma":"admettre","pos":"VER"} ,
		{"word":"admettrait","word_nosc":"admettrait","lemma":"admettre","pos":"VER"} ,
		{"word":"admettras","word_nosc":"admettras","lemma":"admettre","pos":"VER"} ,
		{"word":"admettre","word_nosc":"admettre","lemma":"admettre","pos":"VER"} ,
		{"word":"admettrez","word_nosc":"admettrez","lemma":"admettre","pos":"VER"} ,
		{"word":"admettriez","word_nosc":"admettriez","lemma":"admettre","pos":"VER"} ,
		{"word":"admettrons","word_nosc":"admettrons","lemma":"admettre","pos":"VER"} ,
		{"word":"admettront","word_nosc":"admettront","lemma":"admettre","pos":"VER"} ,
		{"word":"administra","word_nosc":"administra","lemma":"administrer","pos":"VER"} ,
		{"word":"administrai","word_nosc":"administrai","lemma":"administrer","pos":"VER"} ,
		{"word":"administraient","word_nosc":"administraient","lemma":"administrer","pos":"VER"} ,
		{"word":"administrais","word_nosc":"administrais","lemma":"administrer","pos":"VER"} ,
		{"word":"administrait","word_nosc":"administrait","lemma":"administrer","pos":"VER"} ,
		{"word":"administrant","word_nosc":"administrant","lemma":"administrer","pos":"VER"} ,
		{"word":"administre","word_nosc":"administre","lemma":"administrer","pos":"VER"} ,
		{"word":"administrent","word_nosc":"administrent","lemma":"administrer","pos":"VER"} ,
		{"word":"administrer","word_nosc":"administrer","lemma":"administrer","pos":"VER"} ,
		{"word":"administrera","word_nosc":"administrera","lemma":"administrer","pos":"VER"} ,
		{"word":"administrerai","word_nosc":"administrerai","lemma":"administrer","pos":"VER"} ,
		{"word":"administrerons","word_nosc":"administrerons","lemma":"administrer","pos":"VER"} ,
		{"word":"administres","word_nosc":"administres","lemma":"administrer","pos":"VER"} ,
		{"word":"administrez","word_nosc":"administrez","lemma":"administrer","pos":"VER"} ,
		{"word":"administrât","word_nosc":"administrat","lemma":"administrer","pos":"VER"} ,
		{"word":"administré","word_nosc":"administre","lemma":"administrer","pos":"VER"} ,
		{"word":"administrée","word_nosc":"administree","lemma":"administrer","pos":"VER"} ,
		{"word":"administrées","word_nosc":"administrees","lemma":"administrer","pos":"VER"} ,
		{"word":"administrés","word_nosc":"administres","lemma":"administrer","pos":"VER"} ,
		{"word":"admira","word_nosc":"admira","lemma":"admirer","pos":"VER"} ,
		{"word":"admirai","word_nosc":"admirai","lemma":"admirer","pos":"VER"} ,
		{"word":"admiraient","word_nosc":"admiraient","lemma":"admirer","pos":"VER"} ,
		{"word":"admirais","word_nosc":"admirais","lemma":"admirer","pos":"VER"} ,
		{"word":"admirait","word_nosc":"admirait","lemma":"admirer","pos":"VER"} ,
		{"word":"admirant","word_nosc":"admirant","lemma":"admirer","pos":"VER"} ,
		{"word":"admire","word_nosc":"admire","lemma":"admirer","pos":"VER"} ,
		{"word":"admirent","word_nosc":"admirent","lemma":"admettre","pos":"VER"} ,
		{"word":"admirent","word_nosc":"admirent","lemma":"admirer","pos":"VER"} ,
		{"word":"admirer","word_nosc":"admirer","lemma":"admirer","pos":"VER"} ,
		{"word":"admirera","word_nosc":"admirera","lemma":"admirer","pos":"VER"} ,
		{"word":"admirerai","word_nosc":"admirerai","lemma":"admirer","pos":"VER"} ,
		{"word":"admirerais","word_nosc":"admirerais","lemma":"admirer","pos":"VER"} ,
		{"word":"admirerait","word_nosc":"admirerait","lemma":"admirer","pos":"VER"} ,
		{"word":"admirerez","word_nosc":"admirerez","lemma":"admirer","pos":"VER"} ,
		{"word":"admireront","word_nosc":"admireront","lemma":"admirer","pos":"VER"} ,
		{"word":"admires","word_nosc":"admires","lemma":"admirer","pos":"VER"} ,
		{"word":"admirez","word_nosc":"admirez","lemma":"admirer","pos":"VER"} ,
		{"word":"admiriez","word_nosc":"admiriez","lemma":"admirer","pos":"VER"} ,
		{"word":"admirions","word_nosc":"admirions","lemma":"admirer","pos":"VER"} ,
		{"word":"admirons","word_nosc":"admirons","lemma":"admirer","pos":"VER"} ,
		{"word":"admirâmes","word_nosc":"admirames","lemma":"admirer","pos":"VER"} ,
		{"word":"admirèrent","word_nosc":"admirerent","lemma":"admirer","pos":"VER"} ,
		{"word":"admiré","word_nosc":"admire","lemma":"admirer","pos":"VER"} ,
		{"word":"admirée","word_nosc":"admiree","lemma":"admirer","pos":"VER"} ,
		{"word":"admirées","word_nosc":"admirees","lemma":"admirer","pos":"VER"} ,
		{"word":"admirés","word_nosc":"admires","lemma":"admirer","pos":"VER"} ,
		{"word":"admis","word_nosc":"admis","lemma":"admettre","pos":"VER"} ,
		{"word":"admise","word_nosc":"admise","lemma":"admettre","pos":"VER"} ,
		{"word":"admises","word_nosc":"admises","lemma":"admettre","pos":"VER"} ,
		{"word":"admit","word_nosc":"admit","lemma":"admettre","pos":"VER"} ,
		{"word":"admonesta","word_nosc":"admonesta","lemma":"admonester","pos":"VER"} ,
		{"word":"admonestait","word_nosc":"admonestait","lemma":"admonester","pos":"VER"} ,
		{"word":"admoneste","word_nosc":"admoneste","lemma":"admonester","pos":"VER"} ,
		{"word":"admonester","word_nosc":"admonester","lemma":"admonester","pos":"VER"} ,
		{"word":"admonestât","word_nosc":"admonestat","lemma":"admonester","pos":"VER"} ,
		{"word":"admonesté","word_nosc":"admoneste","lemma":"admonester","pos":"VER"} ,
		{"word":"admonestés","word_nosc":"admonestes","lemma":"admonester","pos":"VER"} ,
		{"word":"admît","word_nosc":"admit","lemma":"admettre","pos":"VER"} ,
		{"word":"adonisant","word_nosc":"adonisant","lemma":"adoniser","pos":"VER"} ,
		{"word":"adonna","word_nosc":"adonna","lemma":"adonner","pos":"VER"} ,
		{"word":"adonnai","word_nosc":"adonnai","lemma":"adonner","pos":"VER"} ,
		{"word":"adonnaient","word_nosc":"adonnaient","lemma":"adonner","pos":"VER"} ,
		{"word":"adonnais","word_nosc":"adonnais","lemma":"adonner","pos":"VER"} ,
		{"word":"adonnait","word_nosc":"adonnait","lemma":"adonner","pos":"VER"} ,
		{"word":"adonnant","word_nosc":"adonnant","lemma":"adonner","pos":"VER"} ,
		{"word":"adonne","word_nosc":"adonne","lemma":"adonner","pos":"VER"} ,
		{"word":"adonnent","word_nosc":"adonnent","lemma":"adonner","pos":"VER"} ,
		{"word":"adonner","word_nosc":"adonner","lemma":"adonner","pos":"VER"} ,
		{"word":"adonnerait","word_nosc":"adonnerait","lemma":"adonner","pos":"VER"} ,
		{"word":"adonnez","word_nosc":"adonnez","lemma":"adonner","pos":"VER"} ,
		{"word":"adonnions","word_nosc":"adonnions","lemma":"adonner","pos":"VER"} ,
		{"word":"adonné","word_nosc":"adonne","lemma":"adonner","pos":"VER"} ,
		{"word":"adonnée","word_nosc":"adonnee","lemma":"adonner","pos":"VER"} ,
		{"word":"adonnées","word_nosc":"adonnees","lemma":"adonner","pos":"VER"} ,
		{"word":"adonnés","word_nosc":"adonnes","lemma":"adonner","pos":"VER"} ,
		{"word":"adopta","word_nosc":"adopta","lemma":"adopter","pos":"VER"} ,
		{"word":"adoptai","word_nosc":"adoptai","lemma":"adopter","pos":"VER"} ,
		{"word":"adoptaient","word_nosc":"adoptaient","lemma":"adopter","pos":"VER"} ,
		{"word":"adoptais","word_nosc":"adoptais","lemma":"adopter","pos":"VER"} ,
		{"word":"adoptait","word_nosc":"adoptait","lemma":"adopter","pos":"VER"} ,
		{"word":"adoptant","word_nosc":"adoptant","lemma":"adopter","pos":"VER"} ,
		{"word":"adopte","word_nosc":"adopte","lemma":"adopter","pos":"VER"} ,
		{"word":"adoptent","word_nosc":"adoptent","lemma":"adopter","pos":"VER"} ,
		{"word":"adopter","word_nosc":"adopter","lemma":"adopter","pos":"VER"} ,
		{"word":"adoptera","word_nosc":"adoptera","lemma":"adopter","pos":"VER"} ,
		{"word":"adopterai","word_nosc":"adopterai","lemma":"adopter","pos":"VER"} ,
		{"word":"adopteraient","word_nosc":"adopteraient","lemma":"adopter","pos":"VER"} ,
		{"word":"adopterait","word_nosc":"adopterait","lemma":"adopter","pos":"VER"} ,
		{"word":"adopterez","word_nosc":"adopterez","lemma":"adopter","pos":"VER"} ,
		{"word":"adopterons","word_nosc":"adopterons","lemma":"adopter","pos":"VER"} ,
		{"word":"adopteront","word_nosc":"adopteront","lemma":"adopter","pos":"VER"} ,
		{"word":"adoptez","word_nosc":"adoptez","lemma":"adopter","pos":"VER"} ,
		{"word":"adoptions","word_nosc":"adoptions","lemma":"adopter","pos":"VER"} ,
		{"word":"adoptons","word_nosc":"adoptons","lemma":"adopter","pos":"VER"} ,
		{"word":"adoptât","word_nosc":"adoptat","lemma":"adopter","pos":"VER"} ,
		{"word":"adoptèrent","word_nosc":"adopterent","lemma":"adopter","pos":"VER"} ,
		{"word":"adopté","word_nosc":"adopte","lemma":"adopter","pos":"VER"} ,
		{"word":"adoptée","word_nosc":"adoptee","lemma":"adopter","pos":"VER"} ,
		{"word":"adoptées","word_nosc":"adoptees","lemma":"adopter","pos":"VER"} ,
		{"word":"adoptés","word_nosc":"adoptes","lemma":"adopter","pos":"VER"} ,
		{"word":"adora","word_nosc":"adora","lemma":"adorer","pos":"VER"} ,
		{"word":"adorai","word_nosc":"adorai","lemma":"adorer","pos":"VER"} ,
		{"word":"adoraient","word_nosc":"adoraient","lemma":"adorer","pos":"VER"} ,
		{"word":"adorais","word_nosc":"adorais","lemma":"adorer","pos":"VER"} ,
		{"word":"adorait","word_nosc":"adorait","lemma":"adorer","pos":"VER"} ,
		{"word":"adorant","word_nosc":"adorant","lemma":"adorer","pos":"VER"} ,
		{"word":"adore","word_nosc":"adore","lemma":"adorer","pos":"VER"} ,
		{"word":"adorent","word_nosc":"adorent","lemma":"adorer","pos":"VER"} ,
		{"word":"adorer","word_nosc":"adorer","lemma":"adorer","pos":"VER"} ,
		{"word":"adorera","word_nosc":"adorera","lemma":"adorer","pos":"VER"} ,
		{"word":"adorerai","word_nosc":"adorerai","lemma":"adorer","pos":"VER"} ,
		{"word":"adoreraient","word_nosc":"adoreraient","lemma":"adorer","pos":"VER"} ,
		{"word":"adorerais","word_nosc":"adorerais","lemma":"adorer","pos":"VER"} ,
		{"word":"adorerait","word_nosc":"adorerait","lemma":"adorer","pos":"VER"} ,
		{"word":"adoreras","word_nosc":"adoreras","lemma":"adorer","pos":"VER"} ,
		{"word":"adorerez","word_nosc":"adorerez","lemma":"adorer","pos":"VER"} ,
		{"word":"adoreriez","word_nosc":"adoreriez","lemma":"adorer","pos":"VER"} ,
		{"word":"adorerions","word_nosc":"adorerions","lemma":"adorer","pos":"VER"} ,
		{"word":"adorerons","word_nosc":"adorerons","lemma":"adorer","pos":"VER"} ,
		{"word":"adoreront","word_nosc":"adoreront","lemma":"adorer","pos":"VER"} ,
		{"word":"adores","word_nosc":"adores","lemma":"adorer","pos":"VER"} ,
		{"word":"adorez","word_nosc":"adorez","lemma":"adorer","pos":"VER"} ,
		{"word":"adoriez","word_nosc":"adoriez","lemma":"adorer","pos":"VER"} ,
		{"word":"adorions","word_nosc":"adorions","lemma":"adorer","pos":"VER"} ,
		{"word":"adornaient","word_nosc":"adornaient","lemma":"adorner","pos":"VER"} ,
		{"word":"adornait","word_nosc":"adornait","lemma":"adorner","pos":"VER"} ,
		{"word":"adorne","word_nosc":"adorne","lemma":"adorner","pos":"VER"} ,
		{"word":"adorné","word_nosc":"adorne","lemma":"adorner","pos":"VER"} ,
		{"word":"adorons","word_nosc":"adorons","lemma":"adorer","pos":"VER"} ,
		{"word":"adorèrent","word_nosc":"adorerent","lemma":"adorer","pos":"VER"} ,
		{"word":"adoré","word_nosc":"adore","lemma":"adorer","pos":"VER"} ,
		{"word":"adorée","word_nosc":"adoree","lemma":"adorer","pos":"VER"} ,
		{"word":"adorées","word_nosc":"adorees","lemma":"adorer","pos":"VER"} ,
		{"word":"adorés","word_nosc":"adores","lemma":"adorer","pos":"VER"} ,
		{"word":"adossa","word_nosc":"adossa","lemma":"adosser","pos":"VER"} ,
		{"word":"adossai","word_nosc":"adossai","lemma":"adosser","pos":"VER"} ,
		{"word":"adossaient","word_nosc":"adossaient","lemma":"adosser","pos":"VER"} ,
		{"word":"adossais","word_nosc":"adossais","lemma":"adosser","pos":"VER"} ,
		{"word":"adossait","word_nosc":"adossait","lemma":"adosser","pos":"VER"} ,
		{"word":"adossant","word_nosc":"adossant","lemma":"adosser","pos":"VER"} ,
		{"word":"adosse","word_nosc":"adosse","lemma":"adosser","pos":"VER"} ,
		{"word":"adossent","word_nosc":"adossent","lemma":"adosser","pos":"VER"} ,
		{"word":"adosser","word_nosc":"adosser","lemma":"adosser","pos":"VER"} ,
		{"word":"adossez","word_nosc":"adossez","lemma":"adosser","pos":"VER"} ,
		{"word":"adossèrent","word_nosc":"adosserent","lemma":"adosser","pos":"VER"} ,
		{"word":"adossé","word_nosc":"adosse","lemma":"adosser","pos":"VER"} ,
		{"word":"adossée","word_nosc":"adossee","lemma":"adosser","pos":"VER"} ,
		{"word":"adossées","word_nosc":"adossees","lemma":"adosser","pos":"VER"} ,
		{"word":"adossés","word_nosc":"adosses","lemma":"adosser","pos":"VER"} ,
		{"word":"adouba","word_nosc":"adouba","lemma":"adouber","pos":"VER"} ,
		{"word":"adouber","word_nosc":"adouber","lemma":"adouber","pos":"VER"} ,
		{"word":"adoubé","word_nosc":"adoube","lemma":"adouber","pos":"VER"} ,
		{"word":"adouci","word_nosc":"adouci","lemma":"adoucir","pos":"VER"} ,
		{"word":"adoucie","word_nosc":"adoucie","lemma":"adoucir","pos":"VER"} ,
		{"word":"adoucies","word_nosc":"adoucies","lemma":"adoucir","pos":"VER"} ,
		{"word":"adoucir","word_nosc":"adoucir","lemma":"adoucir","pos":"VER"} ,
		{"word":"adoucira","word_nosc":"adoucira","lemma":"adoucir","pos":"VER"} ,
		{"word":"adoucirait","word_nosc":"adoucirait","lemma":"adoucir","pos":"VER"} ,
		{"word":"adoucirent","word_nosc":"adoucirent","lemma":"adoucir","pos":"VER"} ,
		{"word":"adoucis","word_nosc":"adoucis","lemma":"adoucir","pos":"VER"} ,
		{"word":"adoucissaient","word_nosc":"adoucissaient","lemma":"adoucir","pos":"VER"} ,
		{"word":"adoucissait","word_nosc":"adoucissait","lemma":"adoucir","pos":"VER"} ,
		{"word":"adoucissant","word_nosc":"adoucissant","lemma":"adoucir","pos":"VER"} ,
		{"word":"adoucisse","word_nosc":"adoucisse","lemma":"adoucir","pos":"VER"} ,
		{"word":"adoucissent","word_nosc":"adoucissent","lemma":"adoucir","pos":"VER"} ,
		{"word":"adoucissez","word_nosc":"adoucissez","lemma":"adoucir","pos":"VER"} ,
		{"word":"adoucit","word_nosc":"adoucit","lemma":"adoucir","pos":"VER"} ,
		{"word":"adoucît","word_nosc":"adoucit","lemma":"adoucir","pos":"VER"} ,
		{"word":"adressa","word_nosc":"adressa","lemma":"adresser","pos":"VER"} ,
		{"word":"adressai","word_nosc":"adressai","lemma":"adresser","pos":"VER"} ,
		{"word":"adressaient","word_nosc":"adressaient","lemma":"adresser","pos":"VER"} ,
		{"word":"adressais","word_nosc":"adressais","lemma":"adresser","pos":"VER"} ,
		{"word":"adressait","word_nosc":"adressait","lemma":"adresser","pos":"VER"} ,
		{"word":"adressant","word_nosc":"adressant","lemma":"adresser","pos":"VER"} ,
		{"word":"adressassent","word_nosc":"adressassent","lemma":"adresser","pos":"VER"} ,
		{"word":"adresse","word_nosc":"adresse","lemma":"adresser","pos":"VER"} ,
		{"word":"adressent","word_nosc":"adressent","lemma":"adresser","pos":"VER"} ,
		{"word":"adresser","word_nosc":"adresser","lemma":"adresser","pos":"VER"} ,
		{"word":"adressera","word_nosc":"adressera","lemma":"adresser","pos":"VER"} ,
		{"word":"adresserai","word_nosc":"adresserai","lemma":"adresser","pos":"VER"} ,
		{"word":"adresseraient","word_nosc":"adresseraient","lemma":"adresser","pos":"VER"} ,
		{"word":"adresserais","word_nosc":"adresserais","lemma":"adresser","pos":"VER"} ,
		{"word":"adresserait","word_nosc":"adresserait","lemma":"adresser","pos":"VER"} ,
		{"word":"adresseras","word_nosc":"adresseras","lemma":"adresser","pos":"VER"} ,
		{"word":"adresserez","word_nosc":"adresserez","lemma":"adresser","pos":"VER"} ,
		{"word":"adresserons","word_nosc":"adresserons","lemma":"adresser","pos":"VER"} ,
		{"word":"adresseront","word_nosc":"adresseront","lemma":"adresser","pos":"VER"} ,
		{"word":"adresses","word_nosc":"adresses","lemma":"adresser","pos":"VER"} ,
		{"word":"adressez","word_nosc":"adressez","lemma":"adresser","pos":"VER"} ,
		{"word":"adressiez","word_nosc":"adressiez","lemma":"adresser","pos":"VER"} ,
		{"word":"adressions","word_nosc":"adressions","lemma":"adresser","pos":"VER"} ,
		{"word":"adressons","word_nosc":"adressons","lemma":"adresser","pos":"VER"} ,
		{"word":"adressâmes","word_nosc":"adressames","lemma":"adresser","pos":"VER"} ,
		{"word":"adressât","word_nosc":"adressat","lemma":"adresser","pos":"VER"} ,
		{"word":"adressèrent","word_nosc":"adresserent","lemma":"adresser","pos":"VER"} ,
		{"word":"adressé","word_nosc":"adresse","lemma":"adresser","pos":"VER"} ,
		{"word":"adressée","word_nosc":"adressee","lemma":"adresser","pos":"VER"} ,
		{"word":"adressées","word_nosc":"adressees","lemma":"adresser","pos":"VER"} ,
		{"word":"adressés","word_nosc":"adresses","lemma":"adresser","pos":"VER"} ,
		{"word":"adulaient","word_nosc":"adulaient","lemma":"aduler","pos":"VER"} ,
		{"word":"adulais","word_nosc":"adulais","lemma":"aduler","pos":"VER"} ,
		{"word":"adulait","word_nosc":"adulait","lemma":"aduler","pos":"VER"} ,
		{"word":"adulent","word_nosc":"adulent","lemma":"aduler","pos":"VER"} ,
		{"word":"aduler","word_nosc":"aduler","lemma":"aduler","pos":"VER"} ,
		{"word":"adultéraient","word_nosc":"adulteraient","lemma":"adultérer","pos":"VER"} ,
		{"word":"adultéré","word_nosc":"adultere","lemma":"adultérer","pos":"VER"} ,
		{"word":"adultérées","word_nosc":"adulterees","lemma":"adultérer","pos":"VER"} ,
		{"word":"adulé","word_nosc":"adule","lemma":"aduler","pos":"VER"} ,
		{"word":"adulée","word_nosc":"adulee","lemma":"aduler","pos":"VER"} ,
		{"word":"adulés","word_nosc":"adules","lemma":"aduler","pos":"VER"} ,
		{"word":"advenait","word_nosc":"advenait","lemma":"advenir","pos":"VER"} ,
		{"word":"advenir","word_nosc":"advenir","lemma":"advenir","pos":"VER"} ,
		{"word":"advenu","word_nosc":"advenu","lemma":"advenir","pos":"VER"} ,
		{"word":"advenue","word_nosc":"advenue","lemma":"advenir","pos":"VER"} ,
		{"word":"advenues","word_nosc":"advenues","lemma":"advenir","pos":"VER"} ,
		{"word":"adviendra","word_nosc":"adviendra","lemma":"advenir","pos":"VER"} ,
		{"word":"adviendrait","word_nosc":"adviendrait","lemma":"advenir","pos":"VER"} ,
		{"word":"advienne","word_nosc":"advienne","lemma":"advenir","pos":"VER"} ,
		{"word":"adviennent","word_nosc":"adviennent","lemma":"advenir","pos":"VER"} ,
		{"word":"advient","word_nosc":"advient","lemma":"advenir","pos":"VER"} ,
		{"word":"advint","word_nosc":"advint","lemma":"advenir","pos":"VER"} ,
		{"word":"advînt","word_nosc":"advint","lemma":"advenir","pos":"VER"} ,
		{"word":"affabule","word_nosc":"affabule","lemma":"affabuler","pos":"VER"} ,
		{"word":"affabuler","word_nosc":"affabuler","lemma":"affabuler","pos":"VER"} ,
		{"word":"affabules","word_nosc":"affabules","lemma":"affabuler","pos":"VER"} ,
		{"word":"affabulé","word_nosc":"affabule","lemma":"affabuler","pos":"VER"} ,
		{"word":"affadi","word_nosc":"affadi","lemma":"affadir","pos":"VER"} ,
		{"word":"affadie","word_nosc":"affadie","lemma":"affadir","pos":"VER"} ,
		{"word":"affadies","word_nosc":"affadies","lemma":"affadir","pos":"VER"} ,
		{"word":"affadir","word_nosc":"affadir","lemma":"affadir","pos":"VER"} ,
		{"word":"affadis","word_nosc":"affadis","lemma":"affadir","pos":"VER"} ,
		{"word":"affadissaient","word_nosc":"affadissaient","lemma":"affadir","pos":"VER"} ,
		{"word":"affadissait","word_nosc":"affadissait","lemma":"affadir","pos":"VER"} ,
		{"word":"affadissant","word_nosc":"affadissant","lemma":"affadir","pos":"VER"} ,
		{"word":"affadisse","word_nosc":"affadisse","lemma":"affadir","pos":"VER"} ,
		{"word":"affadit","word_nosc":"affadit","lemma":"affadir","pos":"VER"} ,
		{"word":"affaibli","word_nosc":"affaibli","lemma":"affaiblir","pos":"VER"} ,
		{"word":"affaiblie","word_nosc":"affaiblie","lemma":"affaiblir","pos":"VER"} ,
		{"word":"affaiblies","word_nosc":"affaiblies","lemma":"affaiblir","pos":"VER"} ,
		{"word":"affaiblir","word_nosc":"affaiblir","lemma":"affaiblir","pos":"VER"} ,
		{"word":"affaiblira","word_nosc":"affaiblira","lemma":"affaiblir","pos":"VER"} ,
		{"word":"affaiblis","word_nosc":"affaiblis","lemma":"affaiblir","pos":"VER"} ,
		{"word":"affaiblissaient","word_nosc":"affaiblissaient","lemma":"affaiblir","pos":"VER"} ,
		{"word":"affaiblissais","word_nosc":"affaiblissais","lemma":"affaiblir","pos":"VER"} ,
		{"word":"affaiblissait","word_nosc":"affaiblissait","lemma":"affaiblir","pos":"VER"} ,
		{"word":"affaiblissant","word_nosc":"affaiblissant","lemma":"affaiblir","pos":"VER"} ,
		{"word":"affaiblisse","word_nosc":"affaiblisse","lemma":"affaiblir","pos":"VER"} ,
		{"word":"affaiblissent","word_nosc":"affaiblissent","lemma":"affaiblir","pos":"VER"} ,
		{"word":"affaiblissez","word_nosc":"affaiblissez","lemma":"affaiblir","pos":"VER"} ,
		{"word":"affaiblissons","word_nosc":"affaiblissons","lemma":"affaiblir","pos":"VER"} ,
		{"word":"affaiblit","word_nosc":"affaiblit","lemma":"affaiblir","pos":"VER"} ,
		{"word":"affaira","word_nosc":"affaira","lemma":"affairer","pos":"VER"} ,
		{"word":"affairai","word_nosc":"affairai","lemma":"affairer","pos":"VER"} ,
		{"word":"affairaient","word_nosc":"affairaient","lemma":"affairer","pos":"VER"} ,
		{"word":"affairais","word_nosc":"affairais","lemma":"affairer","pos":"VER"} ,
		{"word":"affairait","word_nosc":"affairait","lemma":"affairer","pos":"VER"} ,
		{"word":"affairant","word_nosc":"affairant","lemma":"affairer","pos":"VER"} ,
		{"word":"affaire","word_nosc":"affaire","lemma":"affairer","pos":"VER"} ,
		{"word":"affairent","word_nosc":"affairent","lemma":"affairer","pos":"VER"} ,
		{"word":"affairer","word_nosc":"affairer","lemma":"affairer","pos":"VER"} ,
		{"word":"affaireraient","word_nosc":"affaireraient","lemma":"affairer","pos":"VER"} ,
		{"word":"affaires","word_nosc":"affaires","lemma":"affairer","pos":"VER"} ,
		{"word":"affairions","word_nosc":"affairions","lemma":"affairer","pos":"VER"} ,
		{"word":"affairons","word_nosc":"affairons","lemma":"affairer","pos":"VER"} ,
		{"word":"affairèrent","word_nosc":"affairerent","lemma":"affairer","pos":"VER"} ,
		{"word":"affairé","word_nosc":"affaire","lemma":"affairer","pos":"VER"} ,
		{"word":"affairée","word_nosc":"affairee","lemma":"affairer","pos":"VER"} ,
		{"word":"affairées","word_nosc":"affairees","lemma":"affairer","pos":"VER"} ,
		{"word":"affairés","word_nosc":"affaires","lemma":"affairer","pos":"VER"} ,
		{"word":"affaissa","word_nosc":"affaissa","lemma":"affaisser","pos":"VER"} ,
		{"word":"affaissaient","word_nosc":"affaissaient","lemma":"affaisser","pos":"VER"} ,
		{"word":"affaissait","word_nosc":"affaissait","lemma":"affaisser","pos":"VER"} ,
		{"word":"affaissant","word_nosc":"affaissant","lemma":"affaisser","pos":"VER"} ,
		{"word":"affaisse","word_nosc":"affaisse","lemma":"affaisser","pos":"VER"} ,
		{"word":"affaissent","word_nosc":"affaissent","lemma":"affaisser","pos":"VER"} ,
		{"word":"affaisser","word_nosc":"affaisser","lemma":"affaisser","pos":"VER"} ,
		{"word":"affaisseraient","word_nosc":"affaisseraient","lemma":"affaisser","pos":"VER"} ,
		{"word":"affaisserait","word_nosc":"affaisserait","lemma":"affaisser","pos":"VER"} ,
		{"word":"affaissions","word_nosc":"affaissions","lemma":"affaisser","pos":"VER"} ,
		{"word":"affaissèrent","word_nosc":"affaisserent","lemma":"affaisser","pos":"VER"} ,
		{"word":"affaissé","word_nosc":"affaisse","lemma":"affaisser","pos":"VER"} ,
		{"word":"affaissée","word_nosc":"affaissee","lemma":"affaisser","pos":"VER"} ,
		{"word":"affaissées","word_nosc":"affaissees","lemma":"affaisser","pos":"VER"} ,
		{"word":"affaissés","word_nosc":"affaisses","lemma":"affaisser","pos":"VER"} ,
		{"word":"affala","word_nosc":"affala","lemma":"affaler","pos":"VER"} ,
		{"word":"affalai","word_nosc":"affalai","lemma":"affaler","pos":"VER"} ,
		{"word":"affalaient","word_nosc":"affalaient","lemma":"affaler","pos":"VER"} ,
		{"word":"affalais","word_nosc":"affalais","lemma":"affaler","pos":"VER"} ,
		{"word":"affalait","word_nosc":"affalait","lemma":"affaler","pos":"VER"} ,
		{"word":"affalant","word_nosc":"affalant","lemma":"affaler","pos":"VER"} ,
		{"word":"affale","word_nosc":"affale","lemma":"affaler","pos":"VER"} ,
		{"word":"affalent","word_nosc":"affalent","lemma":"affaler","pos":"VER"} ,
		{"word":"affaler","word_nosc":"affaler","lemma":"affaler","pos":"VER"} ,
		{"word":"affalez","word_nosc":"affalez","lemma":"affaler","pos":"VER"} ,
		{"word":"affalons","word_nosc":"affalons","lemma":"affaler","pos":"VER"} ,
		{"word":"affalèrent","word_nosc":"affalerent","lemma":"affaler","pos":"VER"} ,
		{"word":"affalé","word_nosc":"affale","lemma":"affaler","pos":"VER"} ,
		{"word":"affalée","word_nosc":"affalee","lemma":"affaler","pos":"VER"} ,
		{"word":"affalées","word_nosc":"affalees","lemma":"affaler","pos":"VER"} ,
		{"word":"affalés","word_nosc":"affales","lemma":"affaler","pos":"VER"} ,
		{"word":"affamaient","word_nosc":"affamaient","lemma":"affamer","pos":"VER"} ,
		{"word":"affamait","word_nosc":"affamait","lemma":"affamer","pos":"VER"} ,
		{"word":"affamant","word_nosc":"affamant","lemma":"affamer","pos":"VER"} ,
		{"word":"affame","word_nosc":"affame","lemma":"affamer","pos":"VER"} ,
		{"word":"affament","word_nosc":"affament","lemma":"affamer","pos":"VER"} ,
		{"word":"affamer","word_nosc":"affamer","lemma":"affamer","pos":"VER"} ,
		{"word":"affamera","word_nosc":"affamera","lemma":"affamer","pos":"VER"} ,
		{"word":"affamez","word_nosc":"affamez","lemma":"affamer","pos":"VER"} ,
		{"word":"affamé","word_nosc":"affame","lemma":"affamer","pos":"VER"} ,
		{"word":"affamée","word_nosc":"affamee","lemma":"affamer","pos":"VER"} ,
		{"word":"affamées","word_nosc":"affamees","lemma":"affamer","pos":"VER"} ,
		{"word":"affamés","word_nosc":"affames","lemma":"affamer","pos":"VER"} ,
		{"word":"affecta","word_nosc":"affecta","lemma":"affecter","pos":"VER"} ,
		{"word":"affectai","word_nosc":"affectai","lemma":"affecter","pos":"VER"} ,
		{"word":"affectaient","word_nosc":"affectaient","lemma":"affecter","pos":"VER"} ,
		{"word":"affectais","word_nosc":"affectais","lemma":"affecter","pos":"VER"} ,
		{"word":"affectait","word_nosc":"affectait","lemma":"affecter","pos":"VER"} ,
		{"word":"affectant","word_nosc":"affectant","lemma":"affecter","pos":"VER"} ,
		{"word":"affectassent","word_nosc":"affectassent","lemma":"affecter","pos":"VER"} ,
		{"word":"affecte","word_nosc":"affecte","lemma":"affecter","pos":"VER"} ,
		{"word":"affectent","word_nosc":"affectent","lemma":"affecter","pos":"VER"} ,
		{"word":"affecter","word_nosc":"affecter","lemma":"affecter","pos":"VER"} ,
		{"word":"affectera","word_nosc":"affectera","lemma":"affecter","pos":"VER"} ,
		{"word":"affecterai","word_nosc":"affecterai","lemma":"affecter","pos":"VER"} ,
		{"word":"affecteraient","word_nosc":"affecteraient","lemma":"affecter","pos":"VER"} ,
		{"word":"affecterait","word_nosc":"affecterait","lemma":"affecter","pos":"VER"} ,
		{"word":"affecteriez","word_nosc":"affecteriez","lemma":"affecter","pos":"VER"} ,
		{"word":"affecteront","word_nosc":"affecteront","lemma":"affecter","pos":"VER"} ,
		{"word":"affectez","word_nosc":"affectez","lemma":"affecter","pos":"VER"} ,
		{"word":"affectiez","word_nosc":"affectiez","lemma":"affecter","pos":"VER"} ,
		{"word":"affectionnaient","word_nosc":"affectionnaient","lemma":"affectionner","pos":"VER"} ,
		{"word":"affectionnais","word_nosc":"affectionnais","lemma":"affectionner","pos":"VER"} ,
		{"word":"affectionnait","word_nosc":"affectionnait","lemma":"affectionner","pos":"VER"} ,
		{"word":"affectionnant","word_nosc":"affectionnant","lemma":"affectionner","pos":"VER"} ,
		{"word":"affectionne","word_nosc":"affectionne","lemma":"affectionner","pos":"VER"} ,
		{"word":"affectionnent","word_nosc":"affectionnent","lemma":"affectionner","pos":"VER"} ,
		{"word":"affectionner","word_nosc":"affectionner","lemma":"affectionner","pos":"VER"} ,
		{"word":"affectionnez","word_nosc":"affectionnez","lemma":"affectionner","pos":"VER"} ,
		{"word":"affectionné","word_nosc":"affectionne","lemma":"affectionner","pos":"VER"} ,
		{"word":"affectionnée","word_nosc":"affectionnee","lemma":"affectionner","pos":"VER"} ,
		{"word":"affections","word_nosc":"affections","lemma":"affecter","pos":"VER"} ,
		{"word":"affectons","word_nosc":"affectons","lemma":"affecter","pos":"VER"} ,
		{"word":"affectâmes","word_nosc":"affectames","lemma":"affecter","pos":"VER"} ,
		{"word":"affectât","word_nosc":"affectat","lemma":"affecter","pos":"VER"} ,
		{"word":"affectèrent","word_nosc":"affecterent","lemma":"affecter","pos":"VER"} ,
		{"word":"affecté","word_nosc":"affecte","lemma":"affecter","pos":"VER"} ,
		{"word":"affectée","word_nosc":"affectee","lemma":"affecter","pos":"VER"} ,
		{"word":"affectées","word_nosc":"affectees","lemma":"affecter","pos":"VER"} ,
		{"word":"affectés","word_nosc":"affectes","lemma":"affecter","pos":"VER"} ,
		{"word":"affermi","word_nosc":"affermi","lemma":"affermir","pos":"VER"} ,
		{"word":"affermie","word_nosc":"affermie","lemma":"affermir","pos":"VER"} ,
		{"word":"affermir","word_nosc":"affermir","lemma":"affermir","pos":"VER"} ,
		{"word":"affermira","word_nosc":"affermira","lemma":"affermir","pos":"VER"} ,
		{"word":"affermis","word_nosc":"affermis","lemma":"affermir","pos":"VER"} ,
		{"word":"affermissaient","word_nosc":"affermissaient","lemma":"affermir","pos":"VER"} ,
		{"word":"affermissait","word_nosc":"affermissait","lemma":"affermir","pos":"VER"} ,
		{"word":"affermissant","word_nosc":"affermissant","lemma":"affermir","pos":"VER"} ,
		{"word":"affermisse","word_nosc":"affermisse","lemma":"affermir","pos":"VER"} ,
		{"word":"affermissent","word_nosc":"affermissent","lemma":"affermir","pos":"VER"} ,
		{"word":"affermit","word_nosc":"affermit","lemma":"affermir","pos":"VER"} ,
		{"word":"afficha","word_nosc":"afficha","lemma":"afficher","pos":"VER"} ,
		{"word":"affichaient","word_nosc":"affichaient","lemma":"afficher","pos":"VER"} ,
		{"word":"affichais","word_nosc":"affichais","lemma":"afficher","pos":"VER"} ,
		{"word":"affichait","word_nosc":"affichait","lemma":"afficher","pos":"VER"} ,
		{"word":"affichant","word_nosc":"affichant","lemma":"afficher","pos":"VER"} ,
		{"word":"affiche","word_nosc":"affiche","lemma":"afficher","pos":"VER"} ,
		{"word":"affichent","word_nosc":"affichent","lemma":"afficher","pos":"VER"} ,
		{"word":"afficher","word_nosc":"afficher","lemma":"afficher","pos":"VER"} ,
		{"word":"affichera","word_nosc":"affichera","lemma":"afficher","pos":"VER"} ,
		{"word":"afficherai","word_nosc":"afficherai","lemma":"afficher","pos":"VER"} ,
		{"word":"afficherait","word_nosc":"afficherait","lemma":"afficher","pos":"VER"} ,
		{"word":"affiches","word_nosc":"affiches","lemma":"afficher","pos":"VER"} ,
		{"word":"affichez","word_nosc":"affichez","lemma":"afficher","pos":"VER"} ,
		{"word":"affichions","word_nosc":"affichions","lemma":"afficher","pos":"VER"} ,
		{"word":"affichons","word_nosc":"affichons","lemma":"afficher","pos":"VER"} ,
		{"word":"affichât","word_nosc":"affichat","lemma":"afficher","pos":"VER"} ,
		{"word":"affichèrent","word_nosc":"afficherent","lemma":"afficher","pos":"VER"} ,
		{"word":"affiché","word_nosc":"affiche","lemma":"afficher","pos":"VER"} ,
		{"word":"affichée","word_nosc":"affichee","lemma":"afficher","pos":"VER"} ,
		{"word":"affichées","word_nosc":"affichees","lemma":"afficher","pos":"VER"} ,
		{"word":"affichés","word_nosc":"affiches","lemma":"afficher","pos":"VER"} ,
		{"word":"affile","word_nosc":"affile","lemma":"affiler","pos":"VER"} ,
		{"word":"affiler","word_nosc":"affiler","lemma":"affiler","pos":"VER"} ,
		{"word":"affilia","word_nosc":"affilia","lemma":"affilier","pos":"VER"} ,
		{"word":"affiliaient","word_nosc":"affiliaient","lemma":"affilier","pos":"VER"} ,
		{"word":"affiliant","word_nosc":"affiliant","lemma":"affilier","pos":"VER"} ,
		{"word":"affilier","word_nosc":"affilier","lemma":"affilier","pos":"VER"} ,
		{"word":"affilié","word_nosc":"affilie","lemma":"affilier","pos":"VER"} ,
		{"word":"affiliée","word_nosc":"affiliee","lemma":"affilier","pos":"VER"} ,
		{"word":"affiliés","word_nosc":"affilies","lemma":"affilier","pos":"VER"} ,
		{"word":"affilée","word_nosc":"affilee","lemma":"affiler","pos":"VER"} ,
		{"word":"affina","word_nosc":"affina","lemma":"affiner","pos":"VER"} ,
		{"word":"affinaient","word_nosc":"affinaient","lemma":"affiner","pos":"VER"} ,
		{"word":"affinait","word_nosc":"affinait","lemma":"affiner","pos":"VER"} ,
		{"word":"affinant","word_nosc":"affinant","lemma":"affiner","pos":"VER"} ,
		{"word":"affine","word_nosc":"affine","lemma":"affiner","pos":"VER"} ,
		{"word":"affinent","word_nosc":"affinent","lemma":"affiner","pos":"VER"} ,
		{"word":"affiner","word_nosc":"affiner","lemma":"affiner","pos":"VER"} ,
		{"word":"affinera","word_nosc":"affinera","lemma":"affiner","pos":"VER"} ,
		{"word":"affinerait","word_nosc":"affinerait","lemma":"affiner","pos":"VER"} ,
		{"word":"affinons","word_nosc":"affinons","lemma":"affiner","pos":"VER"} ,
		{"word":"affiné","word_nosc":"affine","lemma":"affiner","pos":"VER"} ,
		{"word":"affinée","word_nosc":"affinee","lemma":"affiner","pos":"VER"} ,
		{"word":"affinées","word_nosc":"affinees","lemma":"affiner","pos":"VER"} ,
		{"word":"affirma","word_nosc":"affirma","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmai","word_nosc":"affirmai","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmaient","word_nosc":"affirmaient","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmais","word_nosc":"affirmais","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmait","word_nosc":"affirmait","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmant","word_nosc":"affirmant","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirme","word_nosc":"affirme","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirment","word_nosc":"affirment","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmer","word_nosc":"affirmer","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmerai","word_nosc":"affirmerai","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmerait","word_nosc":"affirmerait","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmeras","word_nosc":"affirmeras","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmerez","word_nosc":"affirmerez","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmeriez","word_nosc":"affirmeriez","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmeront","word_nosc":"affirmeront","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmes","word_nosc":"affirmes","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmez","word_nosc":"affirmez","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmiez","word_nosc":"affirmiez","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmions","word_nosc":"affirmions","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmons","word_nosc":"affirmons","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmât","word_nosc":"affirmat","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmèrent","word_nosc":"affirmerent","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmé","word_nosc":"affirme","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmée","word_nosc":"affirmee","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmées","word_nosc":"affirmees","lemma":"affirmer","pos":"VER"} ,
		{"word":"affirmés","word_nosc":"affirmes","lemma":"affirmer","pos":"VER"} ,
		{"word":"afflanqué","word_nosc":"afflanque","lemma":"afflanquer","pos":"VER"} ,
		{"word":"affleura","word_nosc":"affleura","lemma":"affleurer","pos":"VER"} ,
		{"word":"affleuraient","word_nosc":"affleuraient","lemma":"affleurer","pos":"VER"} ,
		{"word":"affleurait","word_nosc":"affleurait","lemma":"affleurer","pos":"VER"} ,
		{"word":"affleurant","word_nosc":"affleurant","lemma":"affleurer","pos":"VER"} ,
		{"word":"affleure","word_nosc":"affleure","lemma":"affleurer","pos":"VER"} ,
		{"word":"affleurent","word_nosc":"affleurent","lemma":"affleurer","pos":"VER"} ,
		{"word":"affleurer","word_nosc":"affleurer","lemma":"affleurer","pos":"VER"} ,
		{"word":"affleurât","word_nosc":"affleurat","lemma":"affleurer","pos":"VER"} ,
		{"word":"affleurèrent","word_nosc":"affleurerent","lemma":"affleurer","pos":"VER"} ,
		{"word":"affleuré","word_nosc":"affleure","lemma":"affleurer","pos":"VER"} ,
		{"word":"afflige","word_nosc":"afflige","lemma":"affliger","pos":"VER"} ,
		{"word":"affligea","word_nosc":"affligea","lemma":"affliger","pos":"VER"} ,
		{"word":"affligeaient","word_nosc":"affligeaient","lemma":"affliger","pos":"VER"} ,
		{"word":"affligeait","word_nosc":"affligeait","lemma":"affliger","pos":"VER"} ,
		{"word":"affligeant","word_nosc":"affligeant","lemma":"affliger","pos":"VER"} ,
		{"word":"affligent","word_nosc":"affligent","lemma":"affliger","pos":"VER"} ,
		{"word":"affliger","word_nosc":"affliger","lemma":"affliger","pos":"VER"} ,
		{"word":"affligez","word_nosc":"affligez","lemma":"affliger","pos":"VER"} ,
		{"word":"affligèrent","word_nosc":"affligerent","lemma":"affliger","pos":"VER"} ,
		{"word":"affligé","word_nosc":"afflige","lemma":"affliger","pos":"VER"} ,
		{"word":"affligée","word_nosc":"affligee","lemma":"affliger","pos":"VER"} ,
		{"word":"affligés","word_nosc":"affliges","lemma":"affliger","pos":"VER"} ,
		{"word":"afflua","word_nosc":"afflua","lemma":"affluer","pos":"VER"} ,
		{"word":"affluaient","word_nosc":"affluaient","lemma":"affluer","pos":"VER"} ,
		{"word":"affluait","word_nosc":"affluait","lemma":"affluer","pos":"VER"} ,
		{"word":"affluant","word_nosc":"affluant","lemma":"affluer","pos":"VER"} ,
		{"word":"afflue","word_nosc":"afflue","lemma":"affluer","pos":"VER"} ,
		{"word":"affluent","word_nosc":"affluent","lemma":"affluer","pos":"VER"} ,
		{"word":"affluer","word_nosc":"affluer","lemma":"affluer","pos":"VER"} ,
		{"word":"afflueraient","word_nosc":"afflueraient","lemma":"affluer","pos":"VER"} ,
		{"word":"affluèrent","word_nosc":"affluerent","lemma":"affluer","pos":"VER"} ,
		{"word":"afflué","word_nosc":"afflue","lemma":"affluer","pos":"VER"} ,
		{"word":"affola","word_nosc":"affola","lemma":"affoler","pos":"VER"} ,
		{"word":"affolai","word_nosc":"affolai","lemma":"affoler","pos":"VER"} ,
		{"word":"affolaient","word_nosc":"affolaient","lemma":"affoler","pos":"VER"} ,
		{"word":"affolais","word_nosc":"affolais","lemma":"affoler","pos":"VER"} ,
		{"word":"affolait","word_nosc":"affolait","lemma":"affoler","pos":"VER"} ,
		{"word":"affolant","word_nosc":"affolant","lemma":"affoler","pos":"VER"} ,
		{"word":"affole","word_nosc":"affole","lemma":"affoler","pos":"VER"} ,
		{"word":"affolent","word_nosc":"affolent","lemma":"affoler","pos":"VER"} ,
		{"word":"affoler","word_nosc":"affoler","lemma":"affoler","pos":"VER"} ,
		{"word":"affolera","word_nosc":"affolera","lemma":"affoler","pos":"VER"} ,
		{"word":"affolerais","word_nosc":"affolerais","lemma":"affoler","pos":"VER"} ,
		{"word":"affolerait","word_nosc":"affolerait","lemma":"affoler","pos":"VER"} ,
		{"word":"affoleront","word_nosc":"affoleront","lemma":"affoler","pos":"VER"} ,
		{"word":"affoles","word_nosc":"affoles","lemma":"affoler","pos":"VER"} ,
		{"word":"affolez","word_nosc":"affolez","lemma":"affoler","pos":"VER"} ,
		{"word":"affoliez","word_nosc":"affoliez","lemma":"affoler","pos":"VER"} ,
		{"word":"affolons","word_nosc":"affolons","lemma":"affoler","pos":"VER"} ,
		{"word":"affolât","word_nosc":"affolat","lemma":"affoler","pos":"VER"} ,
		{"word":"affolèrent","word_nosc":"affolerent","lemma":"affoler","pos":"VER"} ,
		{"word":"affolé","word_nosc":"affole","lemma":"affoler","pos":"VER"} ,
		{"word":"affolée","word_nosc":"affolee","lemma":"affoler","pos":"VER"} ,
		{"word":"affolées","word_nosc":"affolees","lemma":"affoler","pos":"VER"} ,
		{"word":"affolés","word_nosc":"affoles","lemma":"affoler","pos":"VER"} ,
		{"word":"affouillait","word_nosc":"affouillait","lemma":"affouiller","pos":"VER"} ,
		{"word":"affouillé","word_nosc":"affouille","lemma":"affouiller","pos":"VER"} ,
		{"word":"affranchi","word_nosc":"affranchi","lemma":"affranchir","pos":"VER"} ,
		{"word":"affranchie","word_nosc":"affranchie","lemma":"affranchir","pos":"VER"} ,
		{"word":"affranchies","word_nosc":"affranchies","lemma":"affranchir","pos":"VER"} ,
		{"word":"affranchir","word_nosc":"affranchir","lemma":"affranchir","pos":"VER"} ,
		{"word":"affranchirait","word_nosc":"affranchirait","lemma":"affranchir","pos":"VER"} ,
		{"word":"affranchis","word_nosc":"affranchis","lemma":"affranchir","pos":"VER"} ,
		{"word":"affranchissais","word_nosc":"affranchissais","lemma":"affranchir","pos":"VER"} ,
		{"word":"affranchissait","word_nosc":"affranchissait","lemma":"affranchir","pos":"VER"} ,
		{"word":"affranchissant","word_nosc":"affranchissant","lemma":"affranchir","pos":"VER"} ,
		{"word":"affranchisse","word_nosc":"affranchisse","lemma":"affranchir","pos":"VER"} ,
		{"word":"affranchit","word_nosc":"affranchit","lemma":"affranchir","pos":"VER"} ,
		{"word":"affriandai","word_nosc":"affriandai","lemma":"affriander","pos":"VER"} ,
		{"word":"affriander","word_nosc":"affriander","lemma":"affriander","pos":"VER"} ,
		{"word":"affriandé","word_nosc":"affriande","lemma":"affriander","pos":"VER"} ,
		{"word":"affriandés","word_nosc":"affriandes","lemma":"affriander","pos":"VER"} ,
		{"word":"affriolait","word_nosc":"affriolait","lemma":"affrioler","pos":"VER"} ,
		{"word":"affriolant","word_nosc":"affriolant","lemma":"affrioler","pos":"VER"} ,
		{"word":"affriole","word_nosc":"affriole","lemma":"affrioler","pos":"VER"} ,
		{"word":"affrioler","word_nosc":"affrioler","lemma":"affrioler","pos":"VER"} ,
		{"word":"affriolerait","word_nosc":"affriolerait","lemma":"affrioler","pos":"VER"} ,
		{"word":"affriolés","word_nosc":"affrioles","lemma":"affrioler","pos":"VER"} ,
		{"word":"affronta","word_nosc":"affronta","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontai","word_nosc":"affrontai","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontaient","word_nosc":"affrontaient","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontais","word_nosc":"affrontais","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontait","word_nosc":"affrontait","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontant","word_nosc":"affrontant","lemma":"affronter","pos":"VER"} ,
		{"word":"affronte","word_nosc":"affronte","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontent","word_nosc":"affrontent","lemma":"affronter","pos":"VER"} ,
		{"word":"affronter","word_nosc":"affronter","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontera","word_nosc":"affrontera","lemma":"affronter","pos":"VER"} ,
		{"word":"affronterai","word_nosc":"affronterai","lemma":"affronter","pos":"VER"} ,
		{"word":"affronteraient","word_nosc":"affronteraient","lemma":"affronter","pos":"VER"} ,
		{"word":"affronterais","word_nosc":"affronterais","lemma":"affronter","pos":"VER"} ,
		{"word":"affronterait","word_nosc":"affronterait","lemma":"affronter","pos":"VER"} ,
		{"word":"affronteras","word_nosc":"affronteras","lemma":"affronter","pos":"VER"} ,
		{"word":"affronterez","word_nosc":"affronterez","lemma":"affronter","pos":"VER"} ,
		{"word":"affronterons","word_nosc":"affronterons","lemma":"affronter","pos":"VER"} ,
		{"word":"affronteront","word_nosc":"affronteront","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontes","word_nosc":"affrontes","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontez","word_nosc":"affrontez","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontiez","word_nosc":"affrontiez","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontions","word_nosc":"affrontions","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontons","word_nosc":"affrontons","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontèrent","word_nosc":"affronterent","lemma":"affronter","pos":"VER"} ,
		{"word":"affronté","word_nosc":"affronte","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontée","word_nosc":"affrontee","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontées","word_nosc":"affrontees","lemma":"affronter","pos":"VER"} ,
		{"word":"affrontés","word_nosc":"affrontes","lemma":"affronter","pos":"VER"} ,
		{"word":"affrète","word_nosc":"affrete","lemma":"affréter","pos":"VER"} ,
		{"word":"affréter","word_nosc":"affreter","lemma":"affréter","pos":"VER"} ,
		{"word":"affréterons","word_nosc":"affreterons","lemma":"affréter","pos":"VER"} ,
		{"word":"affrétez","word_nosc":"affretez","lemma":"affréter","pos":"VER"} ,
		{"word":"affrété","word_nosc":"affrete","lemma":"affréter","pos":"VER"} ,
		{"word":"affubla","word_nosc":"affubla","lemma":"affubler","pos":"VER"} ,
		{"word":"affublait","word_nosc":"affublait","lemma":"affubler","pos":"VER"} ,
		{"word":"affublant","word_nosc":"affublant","lemma":"affubler","pos":"VER"} ,
		{"word":"affuble","word_nosc":"affuble","lemma":"affubler","pos":"VER"} ,
		{"word":"affublent","word_nosc":"affublent","lemma":"affubler","pos":"VER"} ,
		{"word":"affubler","word_nosc":"affubler","lemma":"affubler","pos":"VER"} ,
		{"word":"affublerais","word_nosc":"affublerais","lemma":"affubler","pos":"VER"} ,
		{"word":"affublerait","word_nosc":"affublerait","lemma":"affubler","pos":"VER"} ,
		{"word":"affublons","word_nosc":"affublons","lemma":"affubler","pos":"VER"} ,
		{"word":"affublé","word_nosc":"affuble","lemma":"affubler","pos":"VER"} ,
		{"word":"affublée","word_nosc":"affublee","lemma":"affubler","pos":"VER"} ,
		{"word":"affublées","word_nosc":"affublees","lemma":"affubler","pos":"VER"} ,
		{"word":"affublés","word_nosc":"affubles","lemma":"affubler","pos":"VER"} ,
		{"word":"affurait","word_nosc":"affurait","lemma":"affurer","pos":"VER"} ,
		{"word":"affure","word_nosc":"affure","lemma":"affurer","pos":"VER"} ,
		{"word":"affurer","word_nosc":"affurer","lemma":"affurer","pos":"VER"} ,
		{"word":"affures","word_nosc":"affures","lemma":"affurer","pos":"VER"} ,
		{"word":"affuré","word_nosc":"affure","lemma":"affurer","pos":"VER"} ,
		{"word":"affurée","word_nosc":"affuree","lemma":"affurer","pos":"VER"} ,
		{"word":"affûta","word_nosc":"affuta","lemma":"affûter","pos":"VER"} ,
		{"word":"affûtait","word_nosc":"affutait","lemma":"affûter","pos":"VER"} ,
		{"word":"affûtant","word_nosc":"affutant","lemma":"affûter","pos":"VER"} ,
		{"word":"affûte","word_nosc":"affute","lemma":"affûter","pos":"VER"} ,
		{"word":"affûter","word_nosc":"affuter","lemma":"affûter","pos":"VER"} ,
		{"word":"affûterai","word_nosc":"affuterai","lemma":"affûter","pos":"VER"} ,
		{"word":"affûtez","word_nosc":"affutez","lemma":"affûter","pos":"VER"} ,
		{"word":"affûtât","word_nosc":"affutat","lemma":"affûter","pos":"VER"} ,
		{"word":"affûté","word_nosc":"affute","lemma":"affûter","pos":"VER"} ,
		{"word":"affûtée","word_nosc":"affutee","lemma":"affûter","pos":"VER"} ,
		{"word":"affûtées","word_nosc":"affutees","lemma":"affûter","pos":"VER"} ,
		{"word":"affûtés","word_nosc":"affutes","lemma":"affûter","pos":"VER"} ,
		{"word":"agace","word_nosc":"agace","lemma":"agacer","pos":"VER"} ,
		{"word":"agacent","word_nosc":"agacent","lemma":"agacer","pos":"VER"} ,
		{"word":"agacer","word_nosc":"agacer","lemma":"agacer","pos":"VER"} ,
		{"word":"agacera","word_nosc":"agacera","lemma":"agacer","pos":"VER"} ,
		{"word":"agacerait","word_nosc":"agacerait","lemma":"agacer","pos":"VER"} ,
		{"word":"agaceras","word_nosc":"agaceras","lemma":"agacer","pos":"VER"} ,
		{"word":"agaceront","word_nosc":"agaceront","lemma":"agacer","pos":"VER"} ,
		{"word":"agaces","word_nosc":"agaces","lemma":"agacer","pos":"VER"} ,
		{"word":"agacez","word_nosc":"agacez","lemma":"agacer","pos":"VER"} ,
		{"word":"agacèrent","word_nosc":"agacerent","lemma":"agacer","pos":"VER"} ,
		{"word":"agacé","word_nosc":"agace","lemma":"agacer","pos":"VER"} ,
		{"word":"agacée","word_nosc":"agacee","lemma":"agacer","pos":"VER"} ,
		{"word":"agacées","word_nosc":"agacees","lemma":"agacer","pos":"VER"} ,
		{"word":"agacés","word_nosc":"agaces","lemma":"agacer","pos":"VER"} ,
		{"word":"agaça","word_nosc":"agaca","lemma":"agacer","pos":"VER"} ,
		{"word":"agaçaient","word_nosc":"agacaient","lemma":"agacer","pos":"VER"} ,
		{"word":"agaçais","word_nosc":"agacais","lemma":"agacer","pos":"VER"} ,
		{"word":"agaçait","word_nosc":"agacait","lemma":"agacer","pos":"VER"} ,
		{"word":"agaçant","word_nosc":"agacant","lemma":"agacer","pos":"VER"} ,
		{"word":"agence","word_nosc":"agence","lemma":"agencer","pos":"VER"} ,
		{"word":"agencer","word_nosc":"agencer","lemma":"agencer","pos":"VER"} ,
		{"word":"agencé","word_nosc":"agence","lemma":"agencer","pos":"VER"} ,
		{"word":"agencée","word_nosc":"agencee","lemma":"agencer","pos":"VER"} ,
		{"word":"agencées","word_nosc":"agencees","lemma":"agencer","pos":"VER"} ,
		{"word":"agencés","word_nosc":"agences","lemma":"agencer","pos":"VER"} ,
		{"word":"agenouilla","word_nosc":"agenouilla","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouillai","word_nosc":"agenouillai","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouillaient","word_nosc":"agenouillaient","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouillais","word_nosc":"agenouillais","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouillait","word_nosc":"agenouillait","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouillant","word_nosc":"agenouillant","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouille","word_nosc":"agenouille","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouillent","word_nosc":"agenouillent","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouiller","word_nosc":"agenouiller","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouillera","word_nosc":"agenouillera","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouilleront","word_nosc":"agenouilleront","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouilles","word_nosc":"agenouilles","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouillez","word_nosc":"agenouillez","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouillons","word_nosc":"agenouillons","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouillât","word_nosc":"agenouillat","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouillèrent","word_nosc":"agenouillerent","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouillé","word_nosc":"agenouille","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouillée","word_nosc":"agenouillee","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouillées","word_nosc":"agenouillees","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agenouillés","word_nosc":"agenouilles","lemma":"agenouiller","pos":"VER"} ,
		{"word":"agença","word_nosc":"agenca","lemma":"agencer","pos":"VER"} ,
		{"word":"agençaient","word_nosc":"agencaient","lemma":"agencer","pos":"VER"} ,
		{"word":"agençait","word_nosc":"agencait","lemma":"agencer","pos":"VER"} ,
		{"word":"agençant","word_nosc":"agencant","lemma":"agencer","pos":"VER"} ,
		{"word":"agglomère","word_nosc":"agglomere","lemma":"agglomérer","pos":"VER"} ,
		{"word":"agglomèrent","word_nosc":"agglomerent","lemma":"agglomérer","pos":"VER"} ,
		{"word":"aggloméraient","word_nosc":"agglomeraient","lemma":"agglomérer","pos":"VER"} ,
		{"word":"agglomérant","word_nosc":"agglomerant","lemma":"agglomérer","pos":"VER"} ,
		{"word":"agglomérer","word_nosc":"agglomerer","lemma":"agglomérer","pos":"VER"} ,
		{"word":"agglomérera","word_nosc":"agglomerera","lemma":"agglomérer","pos":"VER"} ,
		{"word":"aggloméreront","word_nosc":"agglomereront","lemma":"agglomérer","pos":"VER"} ,
		{"word":"aggloméré","word_nosc":"agglomere","lemma":"agglomérer","pos":"VER"} ,
		{"word":"agglomérée","word_nosc":"agglomeree","lemma":"agglomérer","pos":"VER"} ,
		{"word":"agglomérées","word_nosc":"agglomerees","lemma":"agglomérer","pos":"VER"} ,
		{"word":"agglomérés","word_nosc":"agglomeres","lemma":"agglomérer","pos":"VER"} ,
		{"word":"agglutina","word_nosc":"agglutina","lemma":"agglutiner","pos":"VER"} ,
		{"word":"agglutinaient","word_nosc":"agglutinaient","lemma":"agglutiner","pos":"VER"} ,
		{"word":"agglutinait","word_nosc":"agglutinait","lemma":"agglutiner","pos":"VER"} ,
		{"word":"agglutinant","word_nosc":"agglutinant","lemma":"agglutiner","pos":"VER"} ,
		{"word":"agglutine","word_nosc":"agglutine","lemma":"agglutiner","pos":"VER"} ,
		{"word":"agglutinent","word_nosc":"agglutinent","lemma":"agglutiner","pos":"VER"} ,
		{"word":"agglutiner","word_nosc":"agglutiner","lemma":"agglutiner","pos":"VER"} ,
		{"word":"agglutinez","word_nosc":"agglutinez","lemma":"agglutiner","pos":"VER"} ,
		{"word":"agglutinèrent","word_nosc":"agglutinerent","lemma":"agglutiner","pos":"VER"} ,
		{"word":"agglutiné","word_nosc":"agglutine","lemma":"agglutiner","pos":"VER"} ,
		{"word":"agglutinée","word_nosc":"agglutinee","lemma":"agglutiner","pos":"VER"} ,
		{"word":"agglutinées","word_nosc":"agglutinees","lemma":"agglutiner","pos":"VER"} ,
		{"word":"agglutinés","word_nosc":"agglutines","lemma":"agglutiner","pos":"VER"} ,
		{"word":"aggrava","word_nosc":"aggrava","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggravai","word_nosc":"aggravai","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggravaient","word_nosc":"aggravaient","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggravait","word_nosc":"aggravait","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggravant","word_nosc":"aggravant","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggrave","word_nosc":"aggrave","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggravent","word_nosc":"aggravent","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggraver","word_nosc":"aggraver","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggravera","word_nosc":"aggravera","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggraverait","word_nosc":"aggraverait","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggraveriez","word_nosc":"aggraveriez","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggravez","word_nosc":"aggravez","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggraviez","word_nosc":"aggraviez","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggravons","word_nosc":"aggravons","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggravèrent","word_nosc":"aggraverent","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggravé","word_nosc":"aggrave","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggravée","word_nosc":"aggravee","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggravées","word_nosc":"aggravees","lemma":"aggraver","pos":"VER"} ,
		{"word":"aggravés","word_nosc":"aggraves","lemma":"aggraver","pos":"VER"} ,
		{"word":"agi","word_nosc":"agi","lemma":"agir","pos":"VER"} ,
		{"word":"agie","word_nosc":"agie","lemma":"agir","pos":"VER"} ,
		{"word":"agir","word_nosc":"agir","lemma":"agir","pos":"VER"} ,
		{"word":"agira","word_nosc":"agira","lemma":"agir","pos":"VER"} ,
		{"word":"agirai","word_nosc":"agirai","lemma":"agir","pos":"VER"} ,
		{"word":"agiraient","word_nosc":"agiraient","lemma":"agir","pos":"VER"} ,
		{"word":"agirais","word_nosc":"agirais","lemma":"agir","pos":"VER"} ,
		{"word":"agirait","word_nosc":"agirait","lemma":"agir","pos":"VER"} ,
		{"word":"agiras","word_nosc":"agiras","lemma":"agir","pos":"VER"} ,
		{"word":"agirent","word_nosc":"agirent","lemma":"agir","pos":"VER"} ,
		{"word":"agirez","word_nosc":"agirez","lemma":"agir","pos":"VER"} ,
		{"word":"agiriez","word_nosc":"agiriez","lemma":"agir","pos":"VER"} ,
		{"word":"agirons","word_nosc":"agirons","lemma":"agir","pos":"VER"} ,
		{"word":"agiront","word_nosc":"agiront","lemma":"agir","pos":"VER"} ,
		{"word":"agis","word_nosc":"agis","lemma":"agir","pos":"VER"} ,
		{"word":"agissaient","word_nosc":"agissaient","lemma":"agir","pos":"VER"} ,
		{"word":"agissais","word_nosc":"agissais","lemma":"agir","pos":"VER"} ,
		{"word":"agissait","word_nosc":"agissait","lemma":"agir","pos":"VER"} ,
		{"word":"agissant","word_nosc":"agissant","lemma":"agir","pos":"VER"} ,
		{"word":"agisse","word_nosc":"agisse","lemma":"agir","pos":"VER"} ,
		{"word":"agissent","word_nosc":"agissent","lemma":"agir","pos":"VER"} ,
		{"word":"agisses","word_nosc":"agisses","lemma":"agir","pos":"VER"} ,
		{"word":"agissez","word_nosc":"agissez","lemma":"agir","pos":"VER"} ,
		{"word":"agissiez","word_nosc":"agissiez","lemma":"agir","pos":"VER"} ,
		{"word":"agissions","word_nosc":"agissions","lemma":"agir","pos":"VER"} ,
		{"word":"agissons","word_nosc":"agissons","lemma":"agir","pos":"VER"} ,
		{"word":"agit","word_nosc":"agit","lemma":"agir","pos":"VER"} ,
		{"word":"agita","word_nosc":"agita","lemma":"agiter","pos":"VER"} ,
		{"word":"agitai","word_nosc":"agitai","lemma":"agiter","pos":"VER"} ,
		{"word":"agitaient","word_nosc":"agitaient","lemma":"agiter","pos":"VER"} ,
		{"word":"agitais","word_nosc":"agitais","lemma":"agiter","pos":"VER"} ,
		{"word":"agitait","word_nosc":"agitait","lemma":"agiter","pos":"VER"} ,
		{"word":"agitant","word_nosc":"agitant","lemma":"agiter","pos":"VER"} ,
		{"word":"agitassent","word_nosc":"agitassent","lemma":"agiter","pos":"VER"} ,
		{"word":"agite","word_nosc":"agite","lemma":"agiter","pos":"VER"} ,
		{"word":"agitent","word_nosc":"agitent","lemma":"agiter","pos":"VER"} ,
		{"word":"agiter","word_nosc":"agiter","lemma":"agiter","pos":"VER"} ,
		{"word":"agitera","word_nosc":"agitera","lemma":"agiter","pos":"VER"} ,
		{"word":"agiterai","word_nosc":"agiterai","lemma":"agiter","pos":"VER"} ,
		{"word":"agiterait","word_nosc":"agiterait","lemma":"agiter","pos":"VER"} ,
		{"word":"agiteront","word_nosc":"agiteront","lemma":"agiter","pos":"VER"} ,
		{"word":"agites","word_nosc":"agites","lemma":"agiter","pos":"VER"} ,
		{"word":"agitez","word_nosc":"agitez","lemma":"agiter","pos":"VER"} ,
		{"word":"agitiez","word_nosc":"agitiez","lemma":"agiter","pos":"VER"} ,
		{"word":"agitions","word_nosc":"agitions","lemma":"agiter","pos":"VER"} ,
		{"word":"agitons","word_nosc":"agitons","lemma":"agiter","pos":"VER"} ,
		{"word":"agitât","word_nosc":"agitat","lemma":"agiter","pos":"VER"} ,
		{"word":"agitèrent","word_nosc":"agiterent","lemma":"agiter","pos":"VER"} ,
		{"word":"agité","word_nosc":"agite","lemma":"agiter","pos":"VER"} ,
		{"word":"agitée","word_nosc":"agitee","lemma":"agiter","pos":"VER"} ,
		{"word":"agitées","word_nosc":"agitees","lemma":"agiter","pos":"VER"} ,
		{"word":"agités","word_nosc":"agites","lemma":"agiter","pos":"VER"} ,
		{"word":"agnela","word_nosc":"agnela","lemma":"agneler","pos":"VER"} ,
		{"word":"agonie","word_nosc":"agonie","lemma":"agonir","pos":"VER"} ,
		{"word":"agonir","word_nosc":"agonir","lemma":"agonir","pos":"VER"} ,
		{"word":"agonirent","word_nosc":"agonirent","lemma":"agonir","pos":"VER"} ,
		{"word":"agonisa","word_nosc":"agonisa","lemma":"agoniser","pos":"VER"} ,
		{"word":"agonisai","word_nosc":"agonisai","lemma":"agoniser","pos":"VER"} ,
		{"word":"agonisaient","word_nosc":"agonisaient","lemma":"agoniser","pos":"VER"} ,
		{"word":"agonisais","word_nosc":"agonisais","lemma":"agoniser","pos":"VER"} ,
		{"word":"agonisait","word_nosc":"agonisait","lemma":"agoniser","pos":"VER"} ,
		{"word":"agonisant","word_nosc":"agonisant","lemma":"agoniser","pos":"VER"} ,
		{"word":"agonise","word_nosc":"agonise","lemma":"agoniser","pos":"VER"} ,
		{"word":"agonisent","word_nosc":"agonisent","lemma":"agoniser","pos":"VER"} ,
		{"word":"agoniser","word_nosc":"agoniser","lemma":"agoniser","pos":"VER"} ,
		{"word":"agonisez","word_nosc":"agonisez","lemma":"agoniser","pos":"VER"} ,
		{"word":"agonisiez","word_nosc":"agonisiez","lemma":"agoniser","pos":"VER"} ,
		{"word":"agonissait","word_nosc":"agonissait","lemma":"agonir","pos":"VER"} ,
		{"word":"agonisé","word_nosc":"agonise","lemma":"agoniser","pos":"VER"} ,
		{"word":"agonit","word_nosc":"agonit","lemma":"agonir","pos":"VER"} ,
		{"word":"agrafa","word_nosc":"agrafa","lemma":"agrafer","pos":"VER"} ,
		{"word":"agrafait","word_nosc":"agrafait","lemma":"agrafer","pos":"VER"} ,
		{"word":"agrafant","word_nosc":"agrafant","lemma":"agrafer","pos":"VER"} ,
		{"word":"agrafe","word_nosc":"agrafe","lemma":"agrafer","pos":"VER"} ,
		{"word":"agrafent","word_nosc":"agrafent","lemma":"agrafer","pos":"VER"} ,
		{"word":"agrafer","word_nosc":"agrafer","lemma":"agrafer","pos":"VER"} ,
		{"word":"agraferai","word_nosc":"agraferai","lemma":"agrafer","pos":"VER"} ,
		{"word":"agrafes","word_nosc":"agrafes","lemma":"agrafer","pos":"VER"} ,
		{"word":"agrafez","word_nosc":"agrafez","lemma":"agrafer","pos":"VER"} ,
		{"word":"agrafons","word_nosc":"agrafons","lemma":"agrafer","pos":"VER"} ,
		{"word":"agrafé","word_nosc":"agrafe","lemma":"agrafer","pos":"VER"} ,
		{"word":"agrafée","word_nosc":"agrafee","lemma":"agrafer","pos":"VER"} ,
		{"word":"agrafées","word_nosc":"agrafees","lemma":"agrafer","pos":"VER"} ,
		{"word":"agrafés","word_nosc":"agrafes","lemma":"agrafer","pos":"VER"} ,
		{"word":"agrandi","word_nosc":"agrandi","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandie","word_nosc":"agrandie","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandies","word_nosc":"agrandies","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandir","word_nosc":"agrandir","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandira","word_nosc":"agrandira","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandirai","word_nosc":"agrandirai","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandirait","word_nosc":"agrandirait","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandirent","word_nosc":"agrandirent","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandiront","word_nosc":"agrandiront","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandis","word_nosc":"agrandis","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandissaient","word_nosc":"agrandissaient","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandissait","word_nosc":"agrandissait","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandissant","word_nosc":"agrandissant","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandissent","word_nosc":"agrandissent","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandissez","word_nosc":"agrandissez","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandissons","word_nosc":"agrandissons","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandit","word_nosc":"agrandit","lemma":"agrandir","pos":"VER"} ,
		{"word":"agrandît","word_nosc":"agrandit","lemma":"agrandir","pos":"VER"} ,
		{"word":"agressa","word_nosc":"agressa","lemma":"agresser","pos":"VER"} ,
		{"word":"agressaient","word_nosc":"agressaient","lemma":"agresser","pos":"VER"} ,
		{"word":"agressais","word_nosc":"agressais","lemma":"agresser","pos":"VER"} ,
		{"word":"agressait","word_nosc":"agressait","lemma":"agresser","pos":"VER"} ,
		{"word":"agressant","word_nosc":"agressant","lemma":"agresser","pos":"VER"} ,
		{"word":"agresse","word_nosc":"agresse","lemma":"agresser","pos":"VER"} ,
		{"word":"agressent","word_nosc":"agressent","lemma":"agresser","pos":"VER"} ,
		{"word":"agresser","word_nosc":"agresser","lemma":"agresser","pos":"VER"} ,
		{"word":"agresseraient","word_nosc":"agresseraient","lemma":"agresser","pos":"VER"} ,
		{"word":"agresserait","word_nosc":"agresserait","lemma":"agresser","pos":"VER"} ,
		{"word":"agressez","word_nosc":"agressez","lemma":"agresser","pos":"VER"} ,
		{"word":"agressions","word_nosc":"agressions","lemma":"agresser","pos":"VER"} ,
		{"word":"agressé","word_nosc":"agresse","lemma":"agresser","pos":"VER"} ,
		{"word":"agressée","word_nosc":"agressee","lemma":"agresser","pos":"VER"} ,
		{"word":"agressées","word_nosc":"agressees","lemma":"agresser","pos":"VER"} ,
		{"word":"agressés","word_nosc":"agresses","lemma":"agresser","pos":"VER"} ,
		{"word":"agriche","word_nosc":"agriche","lemma":"agricher","pos":"VER"} ,
		{"word":"agriches","word_nosc":"agriches","lemma":"agricher","pos":"VER"} ,
		{"word":"agrippa","word_nosc":"agrippa","lemma":"agripper","pos":"VER"} ,
		{"word":"agrippai","word_nosc":"agrippai","lemma":"agripper","pos":"VER"} ,
		{"word":"agrippaient","word_nosc":"agrippaient","lemma":"agripper","pos":"VER"} ,
		{"word":"agrippais","word_nosc":"agrippais","lemma":"agripper","pos":"VER"} ,
		{"word":"agrippait","word_nosc":"agrippait","lemma":"agripper","pos":"VER"} ,
		{"word":"agrippant","word_nosc":"agrippant","lemma":"agripper","pos":"VER"} ,
		{"word":"agrippe","word_nosc":"agrippe","lemma":"agripper","pos":"VER"} ,
		{"word":"agrippent","word_nosc":"agrippent","lemma":"agripper","pos":"VER"} ,
		{"word":"agripper","word_nosc":"agripper","lemma":"agripper","pos":"VER"} ,
		{"word":"agrippes","word_nosc":"agrippes","lemma":"agripper","pos":"VER"} ,
		{"word":"agrippez","word_nosc":"agrippez","lemma":"agripper","pos":"VER"} ,
		{"word":"agrippions","word_nosc":"agrippions","lemma":"agripper","pos":"VER"} ,
		{"word":"agrippèrent","word_nosc":"agripperent","lemma":"agripper","pos":"VER"} ,
		{"word":"agrippé","word_nosc":"agrippe","lemma":"agripper","pos":"VER"} ,
		{"word":"agrippée","word_nosc":"agrippee","lemma":"agripper","pos":"VER"} ,
		{"word":"agrippées","word_nosc":"agrippees","lemma":"agripper","pos":"VER"} ,
		{"word":"agrippés","word_nosc":"agrippes","lemma":"agripper","pos":"VER"} ,
		{"word":"agrège","word_nosc":"agrege","lemma":"agréger","pos":"VER"} ,
		{"word":"agrègent","word_nosc":"agregent","lemma":"agréger","pos":"VER"} ,
		{"word":"agréa","word_nosc":"agrea","lemma":"agréer","pos":"VER"} ,
		{"word":"agréaient","word_nosc":"agreaient","lemma":"agréer","pos":"VER"} ,
		{"word":"agréais","word_nosc":"agreais","lemma":"agréer","pos":"VER"} ,
		{"word":"agréait","word_nosc":"agreait","lemma":"agréer","pos":"VER"} ,
		{"word":"agrée","word_nosc":"agree","lemma":"agréer","pos":"VER"} ,
		{"word":"agréent","word_nosc":"agreent","lemma":"agréer","pos":"VER"} ,
		{"word":"agréer","word_nosc":"agreer","lemma":"agréer","pos":"VER"} ,
		{"word":"agréera","word_nosc":"agreera","lemma":"agréer","pos":"VER"} ,
		{"word":"agréerait","word_nosc":"agreerait","lemma":"agréer","pos":"VER"} ,
		{"word":"agrées","word_nosc":"agrees","lemma":"agréer","pos":"VER"} ,
		{"word":"agréez","word_nosc":"agreez","lemma":"agréer","pos":"VER"} ,
		{"word":"agrégeaient","word_nosc":"agregeaient","lemma":"agréger","pos":"VER"} ,
		{"word":"agrégeait","word_nosc":"agregeait","lemma":"agréger","pos":"VER"} ,
		{"word":"agréger","word_nosc":"agreger","lemma":"agréger","pos":"VER"} ,
		{"word":"agrégé","word_nosc":"agrege","lemma":"agréger","pos":"VER"} ,
		{"word":"agrégée","word_nosc":"agregee","lemma":"agréger","pos":"VER"} ,
		{"word":"agrégées","word_nosc":"agregees","lemma":"agréger","pos":"VER"} ,
		{"word":"agrégés","word_nosc":"agreges","lemma":"agréger","pos":"VER"} ,
		{"word":"agréions","word_nosc":"agreions","lemma":"agréer","pos":"VER"} ,
		{"word":"agrémenta","word_nosc":"agrementa","lemma":"agrémenter","pos":"VER"} ,
		{"word":"agrémentaient","word_nosc":"agrementaient","lemma":"agrémenter","pos":"VER"} ,
		{"word":"agrémentait","word_nosc":"agrementait","lemma":"agrémenter","pos":"VER"} ,
		{"word":"agrémentant","word_nosc":"agrementant","lemma":"agrémenter","pos":"VER"} ,
		{"word":"agrémente","word_nosc":"agremente","lemma":"agrémenter","pos":"VER"} ,
		{"word":"agrémentent","word_nosc":"agrementent","lemma":"agrémenter","pos":"VER"} ,
		{"word":"agrémenter","word_nosc":"agrementer","lemma":"agrémenter","pos":"VER"} ,
		{"word":"agrémenté","word_nosc":"agremente","lemma":"agrémenter","pos":"VER"} ,
		{"word":"agrémentée","word_nosc":"agrementee","lemma":"agrémenter","pos":"VER"} ,
		{"word":"agrémentées","word_nosc":"agrementees","lemma":"agrémenter","pos":"VER"} ,
		{"word":"agrémentés","word_nosc":"agrementes","lemma":"agrémenter","pos":"VER"} ,
		{"word":"agréé","word_nosc":"agree","lemma":"agréer","pos":"VER"} ,
		{"word":"agréée","word_nosc":"agreee","lemma":"agréer","pos":"VER"} ,
		{"word":"agréées","word_nosc":"agreees","lemma":"agréer","pos":"VER"} ,
		{"word":"agréés","word_nosc":"agrees","lemma":"agréer","pos":"VER"} ,
		{"word":"aguerri","word_nosc":"aguerri","lemma":"aguerrir","pos":"VER"} ,
		{"word":"aguerrie","word_nosc":"aguerrie","lemma":"aguerrir","pos":"VER"} ,
		{"word":"aguerries","word_nosc":"aguerries","lemma":"aguerrir","pos":"VER"} ,
		{"word":"aguerrir","word_nosc":"aguerrir","lemma":"aguerrir","pos":"VER"} ,
		{"word":"aguerrira","word_nosc":"aguerrira","lemma":"aguerrir","pos":"VER"} ,
		{"word":"aguerris","word_nosc":"aguerris","lemma":"aguerrir","pos":"VER"} ,
		{"word":"aguerrissait","word_nosc":"aguerrissait","lemma":"aguerrir","pos":"VER"} ,
		{"word":"aguerrit","word_nosc":"aguerrit","lemma":"aguerrir","pos":"VER"} ,
		{"word":"aguichait","word_nosc":"aguichait","lemma":"aguicher","pos":"VER"} ,
		{"word":"aguiche","word_nosc":"aguiche","lemma":"aguicher","pos":"VER"} ,
		{"word":"aguichent","word_nosc":"aguichent","lemma":"aguicher","pos":"VER"} ,
		{"word":"aguicher","word_nosc":"aguicher","lemma":"aguicher","pos":"VER"} ,
		{"word":"aguillera","word_nosc":"aguillera","lemma":"aguiller","pos":"VER"} ,
		{"word":"agît","word_nosc":"agit","lemma":"agir","pos":"VER"} ,
		{"word":"ahana","word_nosc":"ahana","lemma":"ahaner","pos":"VER"} ,
		{"word":"ahanaient","word_nosc":"ahanaient","lemma":"ahaner","pos":"VER"} ,
		{"word":"ahanait","word_nosc":"ahanait","lemma":"ahaner","pos":"VER"} ,
		{"word":"ahanant","word_nosc":"ahanant","lemma":"ahaner","pos":"VER"} ,
		{"word":"ahane","word_nosc":"ahane","lemma":"ahaner","pos":"VER"} ,
		{"word":"ahanent","word_nosc":"ahanent","lemma":"ahaner","pos":"VER"} ,
		{"word":"ahaner","word_nosc":"ahaner","lemma":"ahaner","pos":"VER"} ,
		{"word":"ahané","word_nosc":"ahane","lemma":"ahaner","pos":"VER"} ,
		{"word":"ahuri","word_nosc":"ahuri","lemma":"ahurir","pos":"VER"} ,
		{"word":"ahuries","word_nosc":"ahuries","lemma":"ahurir","pos":"VER"} ,
		{"word":"ahurir","word_nosc":"ahurir","lemma":"ahurir","pos":"VER"} ,
		{"word":"ahuris","word_nosc":"ahuris","lemma":"ahurir","pos":"VER"} ,
		{"word":"ahurissait","word_nosc":"ahurissait","lemma":"ahurir","pos":"VER"} ,
		{"word":"ahurissant","word_nosc":"ahurissant","lemma":"ahurir","pos":"VER"} ,
		{"word":"ahurit","word_nosc":"ahurit","lemma":"ahurir","pos":"VER"} ,
		{"word":"ai","word_nosc":"ai","lemma":"avoir","pos":"VER"} ,
		{"word":"aicher","word_nosc":"aicher","lemma":"aicher","pos":"VER"} ,
		{"word":"aida","word_nosc":"aida","lemma":"aider","pos":"VER"} ,
		{"word":"aidai","word_nosc":"aidai","lemma":"aider","pos":"VER"} ,
		{"word":"aidaient","word_nosc":"aidaient","lemma":"aider","pos":"VER"} ,
		{"word":"aidais","word_nosc":"aidais","lemma":"aider","pos":"VER"} ,
		{"word":"aidait","word_nosc":"aidait","lemma":"aider","pos":"VER"} ,
		{"word":"aidant","word_nosc":"aidant","lemma":"aider","pos":"VER"} ,
		{"word":"aidassent","word_nosc":"aidassent","lemma":"aider","pos":"VER"} ,
		{"word":"aide","word_nosc":"aide","lemma":"aider","pos":"VER"} ,
		{"word":"aident","word_nosc":"aident","lemma":"aider","pos":"VER"} ,
		{"word":"aider","word_nosc":"aider","lemma":"aider","pos":"VER"} ,
		{"word":"aidera","word_nosc":"aidera","lemma":"aider","pos":"VER"} ,
		{"word":"aiderai","word_nosc":"aiderai","lemma":"aider","pos":"VER"} ,
		{"word":"aideraient","word_nosc":"aideraient","lemma":"aider","pos":"VER"} ,
		{"word":"aiderais","word_nosc":"aiderais","lemma":"aider","pos":"VER"} ,
		{"word":"aiderait","word_nosc":"aiderait","lemma":"aider","pos":"VER"} ,
		{"word":"aideras","word_nosc":"aideras","lemma":"aider","pos":"VER"} ,
		{"word":"aiderez","word_nosc":"aiderez","lemma":"aider","pos":"VER"} ,
		{"word":"aideriez","word_nosc":"aideriez","lemma":"aider","pos":"VER"} ,
		{"word":"aiderions","word_nosc":"aiderions","lemma":"aider","pos":"VER"} ,
		{"word":"aiderons","word_nosc":"aiderons","lemma":"aider","pos":"VER"} ,
		{"word":"aideront","word_nosc":"aideront","lemma":"aider","pos":"VER"} ,
		{"word":"aides","word_nosc":"aides","lemma":"aider","pos":"VER"} ,
		{"word":"aidez","word_nosc":"aidez","lemma":"aider","pos":"VER"} ,
		{"word":"aidiez","word_nosc":"aidiez","lemma":"aider","pos":"VER"} ,
		{"word":"aidions","word_nosc":"aidions","lemma":"aider","pos":"VER"} ,
		{"word":"aidons","word_nosc":"aidons","lemma":"aider","pos":"VER"} ,
		{"word":"aidât","word_nosc":"aidat","lemma":"aider","pos":"VER"} ,
		{"word":"aidèrent","word_nosc":"aiderent","lemma":"aider","pos":"VER"} ,
		{"word":"aidé","word_nosc":"aide","lemma":"aider","pos":"VER"} ,
		{"word":"aidée","word_nosc":"aidee","lemma":"aider","pos":"VER"} ,
		{"word":"aidées","word_nosc":"aidees","lemma":"aider","pos":"VER"} ,
		{"word":"aidés","word_nosc":"aides","lemma":"aider","pos":"VER"} ,
		{"word":"aie","word_nosc":"aie","lemma":"avoir","pos":"VER"} ,
		{"word":"aient","word_nosc":"aient","lemma":"avoir","pos":"VER"} ,
		{"word":"aies","word_nosc":"aies","lemma":"avoir","pos":"VER"} ,
		{"word":"aigri","word_nosc":"aigri","lemma":"aigrir","pos":"VER"} ,
		{"word":"aigrie","word_nosc":"aigrie","lemma":"aigrir","pos":"VER"} ,
		{"word":"aigrir","word_nosc":"aigrir","lemma":"aigrir","pos":"VER"} ,
		{"word":"aigrirent","word_nosc":"aigrirent","lemma":"aigrir","pos":"VER"} ,
		{"word":"aigris","word_nosc":"aigris","lemma":"aigrir","pos":"VER"} ,
		{"word":"aigrissaient","word_nosc":"aigrissaient","lemma":"aigrir","pos":"VER"} ,
		{"word":"aigrissait","word_nosc":"aigrissait","lemma":"aigrir","pos":"VER"} ,
		{"word":"aigrissent","word_nosc":"aigrissent","lemma":"aigrir","pos":"VER"} ,
		{"word":"aigrit","word_nosc":"aigrit","lemma":"aigrir","pos":"VER"} ,
		{"word":"aigue","word_nosc":"aigue","lemma":"aiguer","pos":"VER"} ,
		{"word":"aiguilla","word_nosc":"aiguilla","lemma":"aiguiller","pos":"VER"} ,
		{"word":"aiguillant","word_nosc":"aiguillant","lemma":"aiguiller","pos":"VER"} ,
		{"word":"aiguille","word_nosc":"aiguille","lemma":"aiguiller","pos":"VER"} ,
		{"word":"aiguiller","word_nosc":"aiguiller","lemma":"aiguiller","pos":"VER"} ,
		{"word":"aiguilles","word_nosc":"aiguilles","lemma":"aiguiller","pos":"VER"} ,
		{"word":"aiguillonnaient","word_nosc":"aiguillonnaient","lemma":"aiguillonner","pos":"VER"} ,
		{"word":"aiguillonnait","word_nosc":"aiguillonnait","lemma":"aiguillonner","pos":"VER"} ,
		{"word":"aiguillonnant","word_nosc":"aiguillonnant","lemma":"aiguillonner","pos":"VER"} ,
		{"word":"aiguillonne","word_nosc":"aiguillonne","lemma":"aiguillonner","pos":"VER"} ,
		{"word":"aiguillonnent","word_nosc":"aiguillonnent","lemma":"aiguillonner","pos":"VER"} ,
		{"word":"aiguillonner","word_nosc":"aiguillonner","lemma":"aiguillonner","pos":"VER"} ,
		{"word":"aiguillonné","word_nosc":"aiguillonne","lemma":"aiguillonner","pos":"VER"} ,
		{"word":"aiguillonnée","word_nosc":"aiguillonnee","lemma":"aiguillonner","pos":"VER"} ,
		{"word":"aiguillonnées","word_nosc":"aiguillonnees","lemma":"aiguillonner","pos":"VER"} ,
		{"word":"aiguillonnés","word_nosc":"aiguillonnes","lemma":"aiguillonner","pos":"VER"} ,
		{"word":"aiguillons","word_nosc":"aiguillons","lemma":"aiguiller","pos":"VER"} ,
		{"word":"aiguillé","word_nosc":"aiguille","lemma":"aiguiller","pos":"VER"} ,
		{"word":"aiguillée","word_nosc":"aiguillee","lemma":"aiguiller","pos":"VER"} ,
		{"word":"aiguillées","word_nosc":"aiguillees","lemma":"aiguiller","pos":"VER"} ,
		{"word":"aiguillés","word_nosc":"aiguilles","lemma":"aiguiller","pos":"VER"} ,
		{"word":"aiguisa","word_nosc":"aiguisa","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguisai","word_nosc":"aiguisai","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguisaient","word_nosc":"aiguisaient","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguisait","word_nosc":"aiguisait","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguisant","word_nosc":"aiguisant","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguisas","word_nosc":"aiguisas","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguise","word_nosc":"aiguise","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguisent","word_nosc":"aiguisent","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguiser","word_nosc":"aiguiser","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguisera","word_nosc":"aiguisera","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguiserai","word_nosc":"aiguiserai","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguisez","word_nosc":"aiguisez","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguisons","word_nosc":"aiguisons","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguisèrent","word_nosc":"aiguiserent","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguisé","word_nosc":"aiguise","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguisée","word_nosc":"aiguisee","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguisées","word_nosc":"aiguisees","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aiguisés","word_nosc":"aiguises","lemma":"aiguiser","pos":"VER"} ,
		{"word":"aillais","word_nosc":"aillais","lemma":"ailler","pos":"VER"} ,
		{"word":"aille","word_nosc":"aille","lemma":"aller","pos":"VER"} ,
		{"word":"aillent","word_nosc":"aillent","lemma":"aller","pos":"VER"} ,
		{"word":"ailler","word_nosc":"ailler","lemma":"ailler","pos":"VER"} ,
		{"word":"ailles","word_nosc":"ailles","lemma":"aller","pos":"VER"} ,
		{"word":"aillions","word_nosc":"aillions","lemma":"ailler","pos":"VER"} ,
		{"word":"aillons","word_nosc":"aillons","lemma":"ailler","pos":"VER"} ,
		{"word":"aillé","word_nosc":"aille","lemma":"ailler","pos":"VER"} ,
		{"word":"aillée","word_nosc":"aillee","lemma":"ailler","pos":"VER"} ,
		{"word":"aillés","word_nosc":"ailles","lemma":"ailler","pos":"VER"} ,
		{"word":"ailé","word_nosc":"aile","lemma":"ailer","pos":"VER"} ,
		{"word":"ailée","word_nosc":"ailee","lemma":"ailer","pos":"VER"} ,
		{"word":"ailées","word_nosc":"ailees","lemma":"ailer","pos":"VER"} ,
		{"word":"ailés","word_nosc":"ailes","lemma":"ailer","pos":"VER"} ,
		{"word":"aima","word_nosc":"aima","lemma":"aimer","pos":"VER"} ,
		{"word":"aimai","word_nosc":"aimai","lemma":"aimer","pos":"VER"} ,
		{"word":"aimaient","word_nosc":"aimaient","lemma":"aimer","pos":"VER"} ,
		{"word":"aimais","word_nosc":"aimais","lemma":"aimer","pos":"VER"} ,
		{"word":"aimait","word_nosc":"aimait","lemma":"aimer","pos":"VER"} ,
		{"word":"aimant","word_nosc":"aimant","lemma":"aimer","pos":"VER"} ,
		{"word":"aimantaient","word_nosc":"aimantaient","lemma":"aimanter","pos":"VER"} ,
		{"word":"aimantait","word_nosc":"aimantait","lemma":"aimanter","pos":"VER"} ,
		{"word":"aimante","word_nosc":"aimante","lemma":"aimanter","pos":"VER"} ,
		{"word":"aimanter","word_nosc":"aimanter","lemma":"aimanter","pos":"VER"} ,
		{"word":"aimanté","word_nosc":"aimante","lemma":"aimanter","pos":"VER"} ,
		{"word":"aimantée","word_nosc":"aimantee","lemma":"aimanter","pos":"VER"} ,
		{"word":"aimantées","word_nosc":"aimantees","lemma":"aimanter","pos":"VER"} ,
		{"word":"aimantés","word_nosc":"aimantes","lemma":"aimanter","pos":"VER"} ,
		{"word":"aimasse","word_nosc":"aimasse","lemma":"aimer","pos":"VER"} ,
		{"word":"aimassent","word_nosc":"aimassent","lemma":"aimer","pos":"VER"} ,
		{"word":"aimassions","word_nosc":"aimassions","lemma":"aimer","pos":"VER"} ,
		{"word":"aime","word_nosc":"aime","lemma":"aimer","pos":"VER"} ,
		{"word":"aiment","word_nosc":"aiment","lemma":"aimer","pos":"VER"} ,
		{"word":"aimer","word_nosc":"aimer","lemma":"aimer","pos":"VER"} ,
		{"word":"aimera","word_nosc":"aimera","lemma":"aimer","pos":"VER"} ,
		{"word":"aimerai","word_nosc":"aimerai","lemma":"aimer","pos":"VER"} ,
		{"word":"aimeraient","word_nosc":"aimeraient","lemma":"aimer","pos":"VER"} ,
		{"word":"aimerais","word_nosc":"aimerais","lemma":"aimer","pos":"VER"} ,
		{"word":"aimerait","word_nosc":"aimerait","lemma":"aimer","pos":"VER"} ,
		{"word":"aimeras","word_nosc":"aimeras","lemma":"aimer","pos":"VER"} ,
		{"word":"aimerez","word_nosc":"aimerez","lemma":"aimer","pos":"VER"} ,
		{"word":"aimeriez","word_nosc":"aimeriez","lemma":"aimer","pos":"VER"} ,
		{"word":"aimerions","word_nosc":"aimerions","lemma":"aimer","pos":"VER"} ,
		{"word":"aimerons","word_nosc":"aimerons","lemma":"aimer","pos":"VER"} ,
		{"word":"aimeront","word_nosc":"aimeront","lemma":"aimer","pos":"VER"} ,
		{"word":"aimes","word_nosc":"aimes","lemma":"aimer","pos":"VER"} ,
		{"word":"aimez","word_nosc":"aimez","lemma":"aimer","pos":"VER"} ,
		{"word":"aimiez","word_nosc":"aimiez","lemma":"aimer","pos":"VER"} ,
		{"word":"aimions","word_nosc":"aimions","lemma":"aimer","pos":"VER"} ,
		{"word":"aimons","word_nosc":"aimons","lemma":"aimer","pos":"VER"} ,
		{"word":"aimâmes","word_nosc":"aimames","lemma":"aimer","pos":"VER"} ,
		{"word":"aimât","word_nosc":"aimat","lemma":"aimer","pos":"VER"} ,
		{"word":"aimâtes","word_nosc":"aimates","lemma":"aimer","pos":"VER"} ,
		{"word":"aimèrent","word_nosc":"aimerent","lemma":"aimer","pos":"VER"} ,
		{"word":"aimé","word_nosc":"aime","lemma":"aimer","pos":"VER"} ,
		{"word":"aimée","word_nosc":"aimee","lemma":"aimer","pos":"VER"} ,
		{"word":"aimées","word_nosc":"aimees","lemma":"aimer","pos":"VER"} ,
		{"word":"aimés","word_nosc":"aimes","lemma":"aimer","pos":"VER"} ,
		{"word":"ait","word_nosc":"ait","lemma":"avoir","pos":"VER"} ,
		{"word":"ajointer","word_nosc":"ajointer","lemma":"ajointer","pos":"VER"} ,
		{"word":"ajointée","word_nosc":"ajointee","lemma":"ajointer","pos":"VER"} ,
		{"word":"ajouraient","word_nosc":"ajouraient","lemma":"ajourer","pos":"VER"} ,
		{"word":"ajourait","word_nosc":"ajourait","lemma":"ajourer","pos":"VER"} ,
		{"word":"ajourant","word_nosc":"ajourant","lemma":"ajourer","pos":"VER"} ,
		{"word":"ajourna","word_nosc":"ajourna","lemma":"ajourner","pos":"VER"} ,
		{"word":"ajournait","word_nosc":"ajournait","lemma":"ajourner","pos":"VER"} ,
		{"word":"ajourne","word_nosc":"ajourne","lemma":"ajourner","pos":"VER"} ,
		{"word":"ajourner","word_nosc":"ajourner","lemma":"ajourner","pos":"VER"} ,
		{"word":"ajournez","word_nosc":"ajournez","lemma":"ajourner","pos":"VER"} ,
		{"word":"ajourné","word_nosc":"ajourne","lemma":"ajourner","pos":"VER"} ,
		{"word":"ajournée","word_nosc":"ajournee","lemma":"ajourner","pos":"VER"} ,
		{"word":"ajournés","word_nosc":"ajournes","lemma":"ajourner","pos":"VER"} ,
		{"word":"ajouré","word_nosc":"ajoure","lemma":"ajourer","pos":"VER"} ,
		{"word":"ajourée","word_nosc":"ajouree","lemma":"ajourer","pos":"VER"} ,
		{"word":"ajourées","word_nosc":"ajourees","lemma":"ajourer","pos":"VER"} ,
		{"word":"ajourés","word_nosc":"ajoures","lemma":"ajourer","pos":"VER"} ,
		{"word":"ajouta","word_nosc":"ajouta","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutai","word_nosc":"ajoutai","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutaient","word_nosc":"ajoutaient","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutais","word_nosc":"ajoutais","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutait","word_nosc":"ajoutait","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutant","word_nosc":"ajoutant","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutassent","word_nosc":"ajoutassent","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoute","word_nosc":"ajoute","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutent","word_nosc":"ajoutent","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajouter","word_nosc":"ajouter","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutera","word_nosc":"ajoutera","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajouterai","word_nosc":"ajouterai","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajouteraient","word_nosc":"ajouteraient","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajouterais","word_nosc":"ajouterais","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajouterait","word_nosc":"ajouterait","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajouteras","word_nosc":"ajouteras","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajouterez","word_nosc":"ajouterez","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajouterons","word_nosc":"ajouterons","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajouteront","word_nosc":"ajouteront","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutes","word_nosc":"ajoutes","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutez","word_nosc":"ajoutez","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutiez","word_nosc":"ajoutiez","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutions","word_nosc":"ajoutions","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutons","word_nosc":"ajoutons","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutât","word_nosc":"ajoutat","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutèrent","word_nosc":"ajouterent","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajouté","word_nosc":"ajoute","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutée","word_nosc":"ajoutee","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutées","word_nosc":"ajoutees","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajoutés","word_nosc":"ajoutes","lemma":"ajouter","pos":"VER"} ,
		{"word":"ajusta","word_nosc":"ajusta","lemma":"ajuster","pos":"VER"} ,
		{"word":"ajustaient","word_nosc":"ajustaient","lemma":"ajuster","pos":"VER"} ,
		{"word":"ajustais","word_nosc":"ajustais","lemma":"ajuster","pos":"VER"} ,
		{"word":"ajustait","word_nosc":"ajustait","lemma":"ajuster","pos":"VER"} ,
		{"word":"ajustant","word_nosc":"ajustant","lemma":"ajuster","pos":"VER"} ,
		{"word":"ajuste","word_nosc":"ajuste","lemma":"ajuster","pos":"VER"} ,
		{"word":"ajustent","word_nosc":"ajustent","lemma":"ajuster","pos":"VER"} ,
		{"word":"ajuster","word_nosc":"ajuster","lemma":"ajuster","pos":"VER"} ,
		{"word":"ajusterai","word_nosc":"ajusterai","lemma":"ajuster","pos":"VER"} ,
		{"word":"ajustez","word_nosc":"ajustez","lemma":"ajuster","pos":"VER"} ,
		{"word":"ajustons","word_nosc":"ajustons","lemma":"ajuster","pos":"VER"} ,
		{"word":"ajustèrent","word_nosc":"ajusterent","lemma":"ajuster","pos":"VER"} ,
		{"word":"ajusté","word_nosc":"ajuste","lemma":"ajuster","pos":"VER"} ,
		{"word":"ajustée","word_nosc":"ajustee","lemma":"ajuster","pos":"VER"} ,
		{"word":"ajustées","word_nosc":"ajustees","lemma":"ajuster","pos":"VER"} ,
		{"word":"ajustés","word_nosc":"ajustes","lemma":"ajuster","pos":"VER"} ,
		{"word":"alambiqué","word_nosc":"alambique","lemma":"alambiquer","pos":"VER"} ,
		{"word":"alambiquée","word_nosc":"alambiquee","lemma":"alambiquer","pos":"VER"} ,
		{"word":"alangui","word_nosc":"alangui","lemma":"alanguir","pos":"VER"} ,
		{"word":"alanguie","word_nosc":"alanguie","lemma":"alanguir","pos":"VER"} ,
		{"word":"alanguies","word_nosc":"alanguies","lemma":"alanguir","pos":"VER"} ,
		{"word":"alanguir","word_nosc":"alanguir","lemma":"alanguir","pos":"VER"} ,
		{"word":"alanguis","word_nosc":"alanguis","lemma":"alanguir","pos":"VER"} ,
		{"word":"alanguissaient","word_nosc":"alanguissaient","lemma":"alanguir","pos":"VER"} ,
		{"word":"alanguissait","word_nosc":"alanguissait","lemma":"alanguir","pos":"VER"} ,
		{"word":"alanguissant","word_nosc":"alanguissant","lemma":"alanguir","pos":"VER"} ,
		{"word":"alanguissent","word_nosc":"alanguissent","lemma":"alanguir","pos":"VER"} ,
		{"word":"alanguit","word_nosc":"alanguit","lemma":"alanguir","pos":"VER"} ,
		{"word":"alarma","word_nosc":"alarma","lemma":"alarmer","pos":"VER"} ,
		{"word":"alarmai","word_nosc":"alarmai","lemma":"alarmer","pos":"VER"} ,
		{"word":"alarmaient","word_nosc":"alarmaient","lemma":"alarmer","pos":"VER"} ,
		{"word":"alarmait","word_nosc":"alarmait","lemma":"alarmer","pos":"VER"} ,
		{"word":"alarmant","word_nosc":"alarmant","lemma":"alarmer","pos":"VER"} ,
		{"word":"alarme","word_nosc":"alarme","lemma":"alarmer","pos":"VER"} ,
		{"word":"alarmer","word_nosc":"alarmer","lemma":"alarmer","pos":"VER"} ,
		{"word":"alarmeraient","word_nosc":"alarmeraient","lemma":"alarmer","pos":"VER"} ,
		{"word":"alarmerait","word_nosc":"alarmerait","lemma":"alarmer","pos":"VER"} ,
		{"word":"alarmes","word_nosc":"alarmes","lemma":"alarmer","pos":"VER"} ,
		{"word":"alarmez","word_nosc":"alarmez","lemma":"alarmer","pos":"VER"} ,
		{"word":"alarmé","word_nosc":"alarme","lemma":"alarmer","pos":"VER"} ,
		{"word":"alarmée","word_nosc":"alarmee","lemma":"alarmer","pos":"VER"} ,
		{"word":"alarmées","word_nosc":"alarmees","lemma":"alarmer","pos":"VER"} ,
		{"word":"alarmés","word_nosc":"alarmes","lemma":"alarmer","pos":"VER"} ,
		{"word":"alcaliniser","word_nosc":"alcaliniser","lemma":"alcaliniser","pos":"VER"} ,
		{"word":"alcoolise","word_nosc":"alcoolise","lemma":"alcooliser","pos":"VER"} ,
		{"word":"alcoolisé","word_nosc":"alcoolise","lemma":"alcooliser","pos":"VER"} ,
		{"word":"alcoolisée","word_nosc":"alcoolisee","lemma":"alcooliser","pos":"VER"} ,
		{"word":"alcoolisées","word_nosc":"alcoolisees","lemma":"alcooliser","pos":"VER"} ,
		{"word":"alcoolisés","word_nosc":"alcoolises","lemma":"alcooliser","pos":"VER"} ,
		{"word":"alenti","word_nosc":"alenti","lemma":"alentir","pos":"VER"} ,
		{"word":"alentie","word_nosc":"alentie","lemma":"alentir","pos":"VER"} ,
		{"word":"alenties","word_nosc":"alenties","lemma":"alentir","pos":"VER"} ,
		{"word":"alentis","word_nosc":"alentis","lemma":"alentir","pos":"VER"} ,
		{"word":"alerta","word_nosc":"alerta","lemma":"alerter","pos":"VER"} ,
		{"word":"alertait","word_nosc":"alertait","lemma":"alerter","pos":"VER"} ,
		{"word":"alertant","word_nosc":"alertant","lemma":"alerter","pos":"VER"} ,
		{"word":"alerte","word_nosc":"alerte","lemma":"alerter","pos":"VER"} ,
		{"word":"alertent","word_nosc":"alertent","lemma":"alerter","pos":"VER"} ,
		{"word":"alerter","word_nosc":"alerter","lemma":"alerter","pos":"VER"} ,
		{"word":"alertera","word_nosc":"alertera","lemma":"alerter","pos":"VER"} ,
		{"word":"alerterai","word_nosc":"alerterai","lemma":"alerter","pos":"VER"} ,
		{"word":"alerterais","word_nosc":"alerterais","lemma":"alerter","pos":"VER"} ,
		{"word":"alerterait","word_nosc":"alerterait","lemma":"alerter","pos":"VER"} ,
		{"word":"alerterez","word_nosc":"alerterez","lemma":"alerter","pos":"VER"} ,
		{"word":"alerteront","word_nosc":"alerteront","lemma":"alerter","pos":"VER"} ,
		{"word":"alertes","word_nosc":"alertes","lemma":"alerter","pos":"VER"} ,
		{"word":"alertez","word_nosc":"alertez","lemma":"alerter","pos":"VER"} ,
		{"word":"alertions","word_nosc":"alertions","lemma":"alerter","pos":"VER"} ,
		{"word":"alertons","word_nosc":"alertons","lemma":"alerter","pos":"VER"} ,
		{"word":"alertât","word_nosc":"alertat","lemma":"alerter","pos":"VER"} ,
		{"word":"alertèrent","word_nosc":"alerterent","lemma":"alerter","pos":"VER"} ,
		{"word":"alerté","word_nosc":"alerte","lemma":"alerter","pos":"VER"} ,
		{"word":"alertée","word_nosc":"alertee","lemma":"alerter","pos":"VER"} ,
		{"word":"alertées","word_nosc":"alertees","lemma":"alerter","pos":"VER"} ,
		{"word":"alertés","word_nosc":"alertes","lemma":"alerter","pos":"VER"} ,
		{"word":"aligna","word_nosc":"aligna","lemma":"aligner","pos":"VER"} ,
		{"word":"alignaient","word_nosc":"alignaient","lemma":"aligner","pos":"VER"} ,
		{"word":"alignais","word_nosc":"alignais","lemma":"aligner","pos":"VER"} ,
		{"word":"alignait","word_nosc":"alignait","lemma":"aligner","pos":"VER"} ,
		{"word":"alignant","word_nosc":"alignant","lemma":"aligner","pos":"VER"} ,
		{"word":"aligne","word_nosc":"aligne","lemma":"aligner","pos":"VER"} ,
		{"word":"alignent","word_nosc":"alignent","lemma":"aligner","pos":"VER"} ,
		{"word":"aligner","word_nosc":"aligner","lemma":"aligner","pos":"VER"} ,
		{"word":"alignera","word_nosc":"alignera","lemma":"aligner","pos":"VER"} ,
		{"word":"aligneraient","word_nosc":"aligneraient","lemma":"aligner","pos":"VER"} ,
		{"word":"alignerez","word_nosc":"alignerez","lemma":"aligner","pos":"VER"} ,
		{"word":"alignerons","word_nosc":"alignerons","lemma":"aligner","pos":"VER"} ,
		{"word":"aligneront","word_nosc":"aligneront","lemma":"aligner","pos":"VER"} ,
		{"word":"alignes","word_nosc":"alignes","lemma":"aligner","pos":"VER"} ,
		{"word":"alignez","word_nosc":"alignez","lemma":"aligner","pos":"VER"} ,
		{"word":"alignons","word_nosc":"alignons","lemma":"aligner","pos":"VER"} ,
		{"word":"alignèrent","word_nosc":"alignerent","lemma":"aligner","pos":"VER"} ,
		{"word":"aligné","word_nosc":"aligne","lemma":"aligner","pos":"VER"} ,
		{"word":"alignée","word_nosc":"alignee","lemma":"aligner","pos":"VER"} ,
		{"word":"alignées","word_nosc":"alignees","lemma":"aligner","pos":"VER"} ,
		{"word":"alignés","word_nosc":"alignes","lemma":"aligner","pos":"VER"} ,
		{"word":"alimenta","word_nosc":"alimenta","lemma":"alimenter","pos":"VER"} ,
		{"word":"alimentaient","word_nosc":"alimentaient","lemma":"alimenter","pos":"VER"} ,
		{"word":"alimentais","word_nosc":"alimentais","lemma":"alimenter","pos":"VER"} ,
		{"word":"alimentait","word_nosc":"alimentait","lemma":"alimenter","pos":"VER"} ,
		{"word":"alimentant","word_nosc":"alimentant","lemma":"alimenter","pos":"VER"} ,
		{"word":"alimente","word_nosc":"alimente","lemma":"alimenter","pos":"VER"} ,
		{"word":"alimentent","word_nosc":"alimentent","lemma":"alimenter","pos":"VER"} ,
		{"word":"alimenter","word_nosc":"alimenter","lemma":"alimenter","pos":"VER"} ,
		{"word":"alimentera","word_nosc":"alimentera","lemma":"alimenter","pos":"VER"} ,
		{"word":"alimentez","word_nosc":"alimentez","lemma":"alimenter","pos":"VER"} ,
		{"word":"alimentons","word_nosc":"alimentons","lemma":"alimenter","pos":"VER"} ,
		{"word":"alimentèrent","word_nosc":"alimenterent","lemma":"alimenter","pos":"VER"} ,
		{"word":"alimenté","word_nosc":"alimente","lemma":"alimenter","pos":"VER"} ,
		{"word":"alimentée","word_nosc":"alimentee","lemma":"alimenter","pos":"VER"} ,
		{"word":"alimentées","word_nosc":"alimentees","lemma":"alimenter","pos":"VER"} ,
		{"word":"alimentés","word_nosc":"alimentes","lemma":"alimenter","pos":"VER"} ,
		{"word":"alita","word_nosc":"alita","lemma":"aliter","pos":"VER"} ,
		{"word":"alitai","word_nosc":"alitai","lemma":"aliter","pos":"VER"} ,
		{"word":"alitait","word_nosc":"alitait","lemma":"aliter","pos":"VER"} ,
		{"word":"aliter","word_nosc":"aliter","lemma":"aliter","pos":"VER"} ,
		{"word":"alité","word_nosc":"alite","lemma":"aliter","pos":"VER"} ,
		{"word":"alitée","word_nosc":"alitee","lemma":"aliter","pos":"VER"} ,
		{"word":"alitées","word_nosc":"alitees","lemma":"aliter","pos":"VER"} ,
		{"word":"aliène","word_nosc":"aliene","lemma":"aliéner","pos":"VER"} ,
		{"word":"aliéna","word_nosc":"aliena","lemma":"aliéner","pos":"VER"} ,
		{"word":"aliénant","word_nosc":"alienant","lemma":"aliéner","pos":"VER"} ,
		{"word":"aliéner","word_nosc":"aliener","lemma":"aliéner","pos":"VER"} ,
		{"word":"aliénerait","word_nosc":"alienerait","lemma":"aliéner","pos":"VER"} ,
		{"word":"aliénerez","word_nosc":"alienerez","lemma":"aliéner","pos":"VER"} ,
		{"word":"aliéniez","word_nosc":"alieniez","lemma":"aliéner","pos":"VER"} ,
		{"word":"aliénât","word_nosc":"alienat","lemma":"aliéner","pos":"VER"} ,
		{"word":"aliéné","word_nosc":"aliene","lemma":"aliéner","pos":"VER"} ,
		{"word":"aliénée","word_nosc":"alienee","lemma":"aliéner","pos":"VER"} ,
		{"word":"aliénées","word_nosc":"alienees","lemma":"aliéner","pos":"VER"} ,
		{"word":"aliénés","word_nosc":"alienes","lemma":"aliéner","pos":"VER"} ,
		{"word":"alla","word_nosc":"alla","lemma":"aller","pos":"VER"} ,
		{"word":"allai","word_nosc":"allai","lemma":"aller","pos":"VER"} ,
		{"word":"allaient","word_nosc":"allaient","lemma":"aller","pos":"VER"} ,
		{"word":"allais","word_nosc":"allais","lemma":"aller","pos":"VER"} ,
		{"word":"allait","word_nosc":"allait","lemma":"aller","pos":"VER"} ,
		{"word":"allaitais","word_nosc":"allaitais","lemma":"allaiter","pos":"VER"} ,
		{"word":"allaitait","word_nosc":"allaitait","lemma":"allaiter","pos":"VER"} ,
		{"word":"allaitant","word_nosc":"allaitant","lemma":"allaiter","pos":"VER"} ,
		{"word":"allaite","word_nosc":"allaite","lemma":"allaiter","pos":"VER"} ,
		{"word":"allaitent","word_nosc":"allaitent","lemma":"allaiter","pos":"VER"} ,
		{"word":"allaiter","word_nosc":"allaiter","lemma":"allaiter","pos":"VER"} ,
		{"word":"allaiterait","word_nosc":"allaiterait","lemma":"allaiter","pos":"VER"} ,
		{"word":"allaité","word_nosc":"allaite","lemma":"allaiter","pos":"VER"} ,
		{"word":"allaitée","word_nosc":"allaitee","lemma":"allaiter","pos":"VER"} ,
		{"word":"allaités","word_nosc":"allaites","lemma":"allaiter","pos":"VER"} ,
		{"word":"allant","word_nosc":"allant","lemma":"aller","pos":"VER"} ,
		{"word":"allas","word_nosc":"allas","lemma":"aller","pos":"VER"} ,
		{"word":"allassent","word_nosc":"allassent","lemma":"aller","pos":"VER"} ,
		{"word":"aller","word_nosc":"aller","lemma":"aller","pos":"VER"} ,
		{"word":"allez","word_nosc":"allez","lemma":"aller","pos":"VER"} ,
		{"word":"allia","word_nosc":"allia","lemma":"allier","pos":"VER"} ,
		{"word":"alliait","word_nosc":"alliait","lemma":"allier","pos":"VER"} ,
		{"word":"alliant","word_nosc":"alliant","lemma":"allier","pos":"VER"} ,
		{"word":"allie","word_nosc":"allie","lemma":"allier","pos":"VER"} ,
		{"word":"allient","word_nosc":"allient","lemma":"allier","pos":"VER"} ,
		{"word":"allier","word_nosc":"allier","lemma":"aller","pos":"VER"} ,
		{"word":"allier","word_nosc":"allier","lemma":"allier","pos":"VER"} ,
		{"word":"alliera","word_nosc":"alliera","lemma":"allier","pos":"VER"} ,
		{"word":"allierait","word_nosc":"allierait","lemma":"allier","pos":"VER"} ,
		{"word":"alliez","word_nosc":"alliez","lemma":"aller","pos":"VER"} ,
		{"word":"allions","word_nosc":"allions","lemma":"aller","pos":"VER"} ,
		{"word":"alliât","word_nosc":"alliat","lemma":"allier","pos":"VER"} ,
		{"word":"allié","word_nosc":"allie","lemma":"allier","pos":"VER"} ,
		{"word":"alliée","word_nosc":"alliee","lemma":"allier","pos":"VER"} ,
		{"word":"alliées","word_nosc":"alliees","lemma":"allier","pos":"VER"} ,
		{"word":"alliés","word_nosc":"allies","lemma":"allier","pos":"VER"} ,
		{"word":"allonge","word_nosc":"allonge","lemma":"allonger","pos":"VER"} ,
		{"word":"allongea","word_nosc":"allongea","lemma":"allonger","pos":"VER"} ,
		{"word":"allongeai","word_nosc":"allongeai","lemma":"allonger","pos":"VER"} ,
		{"word":"allongeaient","word_nosc":"allongeaient","lemma":"allonger","pos":"VER"} ,
		{"word":"allongeais","word_nosc":"allongeais","lemma":"allonger","pos":"VER"} ,
		{"word":"allongeait","word_nosc":"allongeait","lemma":"allonger","pos":"VER"} ,
		{"word":"allongeant","word_nosc":"allongeant","lemma":"allonger","pos":"VER"} ,
		{"word":"allongent","word_nosc":"allongent","lemma":"allonger","pos":"VER"} ,
		{"word":"allongeons","word_nosc":"allongeons","lemma":"allonger","pos":"VER"} ,
		{"word":"allonger","word_nosc":"allonger","lemma":"allonger","pos":"VER"} ,
		{"word":"allongera","word_nosc":"allongera","lemma":"allonger","pos":"VER"} ,
		{"word":"allongerai","word_nosc":"allongerai","lemma":"allonger","pos":"VER"} ,
		{"word":"allongeraient","word_nosc":"allongeraient","lemma":"allonger","pos":"VER"} ,
		{"word":"allongerais","word_nosc":"allongerais","lemma":"allonger","pos":"VER"} ,
		{"word":"allongerait","word_nosc":"allongerait","lemma":"allonger","pos":"VER"} ,
		{"word":"allongeras","word_nosc":"allongeras","lemma":"allonger","pos":"VER"} ,
		{"word":"allonges","word_nosc":"allonges","lemma":"allonger","pos":"VER"} ,
		{"word":"allongez","word_nosc":"allongez","lemma":"allonger","pos":"VER"} ,
		{"word":"allongeâmes","word_nosc":"allongeames","lemma":"allonger","pos":"VER"} ,
		{"word":"allongeât","word_nosc":"allongeat","lemma":"allonger","pos":"VER"} ,
		{"word":"allongiez","word_nosc":"allongiez","lemma":"allonger","pos":"VER"} ,
		{"word":"allongions","word_nosc":"allongions","lemma":"allonger","pos":"VER"} ,
		{"word":"allongèrent","word_nosc":"allongerent","lemma":"allonger","pos":"VER"} ,
		{"word":"allongé","word_nosc":"allonge","lemma":"allonger","pos":"VER"} ,
		{"word":"allongée","word_nosc":"allongee","lemma":"allonger","pos":"VER"} ,
		{"word":"allongées","word_nosc":"allongees","lemma":"allonger","pos":"VER"} ,
		{"word":"allongés","word_nosc":"allonges","lemma":"allonger","pos":"VER"} ,
		{"word":"allons","word_nosc":"allons","lemma":"aller","pos":"VER"} ,
		{"word":"alloua","word_nosc":"alloua","lemma":"allouer","pos":"VER"} ,
		{"word":"allouait","word_nosc":"allouait","lemma":"allouer","pos":"VER"} ,
		{"word":"allouant","word_nosc":"allouant","lemma":"allouer","pos":"VER"} ,
		{"word":"alloue","word_nosc":"alloue","lemma":"allouer","pos":"VER"} ,
		{"word":"allouer","word_nosc":"allouer","lemma":"allouer","pos":"VER"} ,
		{"word":"allouerait","word_nosc":"allouerait","lemma":"allouer","pos":"VER"} ,
		{"word":"alloué","word_nosc":"alloue","lemma":"allouer","pos":"VER"} ,
		{"word":"allouée","word_nosc":"allouee","lemma":"allouer","pos":"VER"} ,
		{"word":"alloués","word_nosc":"alloues","lemma":"allouer","pos":"VER"} ,
		{"word":"alluma","word_nosc":"alluma","lemma":"allumer","pos":"VER"} ,
		{"word":"allumai","word_nosc":"allumai","lemma":"allumer","pos":"VER"} ,
		{"word":"allumaient","word_nosc":"allumaient","lemma":"allumer","pos":"VER"} ,
		{"word":"allumais","word_nosc":"allumais","lemma":"allumer","pos":"VER"} ,
		{"word":"allumait","word_nosc":"allumait","lemma":"allumer","pos":"VER"} ,
		{"word":"allumant","word_nosc":"allumant","lemma":"allumer","pos":"VER"} ,
		{"word":"allume","word_nosc":"allume","lemma":"allumer","pos":"VER"} ,
		{"word":"allument","word_nosc":"allument","lemma":"allumer","pos":"VER"} ,
		{"word":"allumer","word_nosc":"allumer","lemma":"allumer","pos":"VER"} ,
		{"word":"allumera","word_nosc":"allumera","lemma":"allumer","pos":"VER"} ,
		{"word":"allumerai","word_nosc":"allumerai","lemma":"allumer","pos":"VER"} ,
		{"word":"allumeraient","word_nosc":"allumeraient","lemma":"allumer","pos":"VER"} ,
		{"word":"allumerais","word_nosc":"allumerais","lemma":"allumer","pos":"VER"} ,
		{"word":"allumerait","word_nosc":"allumerait","lemma":"allumer","pos":"VER"} ,
		{"word":"allumeras","word_nosc":"allumeras","lemma":"allumer","pos":"VER"} ,
		{"word":"allumerons","word_nosc":"allumerons","lemma":"allumer","pos":"VER"} ,
		{"word":"allumeront","word_nosc":"allumeront","lemma":"allumer","pos":"VER"} ,
		{"word":"allumes","word_nosc":"allumes","lemma":"allumer","pos":"VER"} ,
		{"word":"allumez","word_nosc":"allumez","lemma":"allumer","pos":"VER"} ,
		{"word":"allumiez","word_nosc":"allumiez","lemma":"allumer","pos":"VER"} ,
		{"word":"allumions","word_nosc":"allumions","lemma":"allumer","pos":"VER"} ,
		{"word":"allumons","word_nosc":"allumons","lemma":"allumer","pos":"VER"} ,
		{"word":"allumâmes","word_nosc":"allumames","lemma":"allumer","pos":"VER"} ,
		{"word":"allumât","word_nosc":"allumat","lemma":"allumer","pos":"VER"} ,
		{"word":"allumèrent","word_nosc":"allumerent","lemma":"allumer","pos":"VER"} ,
		{"word":"allumé","word_nosc":"allume","lemma":"allumer","pos":"VER"} ,
		{"word":"allumée","word_nosc":"allumee","lemma":"allumer","pos":"VER"} ,
		{"word":"allumées","word_nosc":"allumees","lemma":"allumer","pos":"VER"} ,
		{"word":"allumés","word_nosc":"allumes","lemma":"allumer","pos":"VER"} ,
		{"word":"allusionne","word_nosc":"allusionne","lemma":"allusionner","pos":"VER"} ,
		{"word":"allâmes","word_nosc":"allames","lemma":"aller","pos":"VER"} ,
		{"word":"allât","word_nosc":"allat","lemma":"aller","pos":"VER"} ,
		{"word":"allèche","word_nosc":"alleche","lemma":"allécher","pos":"VER"} ,
		{"word":"allège","word_nosc":"allege","lemma":"alléger","pos":"VER"} ,
		{"word":"allègent","word_nosc":"allegent","lemma":"alléger","pos":"VER"} ,
		{"word":"allègue","word_nosc":"allegue","lemma":"alléguer","pos":"VER"} ,
		{"word":"allèrent","word_nosc":"allerent","lemma":"aller","pos":"VER"} ,
		{"word":"allé","word_nosc":"alle","lemma":"aller","pos":"VER"} ,
		{"word":"allécha","word_nosc":"allecha","lemma":"allécher","pos":"VER"} ,
		{"word":"alléchait","word_nosc":"allechait","lemma":"allécher","pos":"VER"} ,
		{"word":"alléchant","word_nosc":"allechant","lemma":"allécher","pos":"VER"} ,
		{"word":"allécher","word_nosc":"allecher","lemma":"allécher","pos":"VER"} ,
		{"word":"allécherait","word_nosc":"allecherait","lemma":"allécher","pos":"VER"} ,
		{"word":"alléché","word_nosc":"alleche","lemma":"allécher","pos":"VER"} ,
		{"word":"alléchée","word_nosc":"allechee","lemma":"allécher","pos":"VER"} ,
		{"word":"alléchés","word_nosc":"alleches","lemma":"allécher","pos":"VER"} ,
		{"word":"allée","word_nosc":"allee","lemma":"aller","pos":"VER"} ,
		{"word":"allées","word_nosc":"allees","lemma":"aller","pos":"VER"} ,
		{"word":"allégea","word_nosc":"allegea","lemma":"alléger","pos":"VER"} ,
		{"word":"allégeaient","word_nosc":"allegeaient","lemma":"alléger","pos":"VER"} ,
		{"word":"allégeais","word_nosc":"allegeais","lemma":"alléger","pos":"VER"} ,
		{"word":"allégeait","word_nosc":"allegeait","lemma":"alléger","pos":"VER"} ,
		{"word":"allégeant","word_nosc":"allegeant","lemma":"alléger","pos":"VER"} ,
		{"word":"alléger","word_nosc":"alleger","lemma":"alléger","pos":"VER"} ,
		{"word":"allégera","word_nosc":"allegera","lemma":"alléger","pos":"VER"} ,
		{"word":"allégerait","word_nosc":"allegerait","lemma":"alléger","pos":"VER"} ,
		{"word":"allégez","word_nosc":"allegez","lemma":"alléger","pos":"VER"} ,
		{"word":"allégorisé","word_nosc":"allegorise","lemma":"allégoriser","pos":"VER"} ,
		{"word":"allégua","word_nosc":"allegua","lemma":"alléguer","pos":"VER"} ,
		{"word":"alléguaient","word_nosc":"alleguaient","lemma":"alléguer","pos":"VER"} ,
		{"word":"alléguais","word_nosc":"alleguais","lemma":"alléguer","pos":"VER"} ,
		{"word":"alléguait","word_nosc":"alleguait","lemma":"alléguer","pos":"VER"} ,
		{"word":"alléguant","word_nosc":"alleguant","lemma":"alléguer","pos":"VER"} ,
		{"word":"alléguer","word_nosc":"alleguer","lemma":"alléguer","pos":"VER"} ,
		{"word":"alléguera","word_nosc":"alleguera","lemma":"alléguer","pos":"VER"} ,
		{"word":"allégué","word_nosc":"allegue","lemma":"alléguer","pos":"VER"} ,
		{"word":"alléguée","word_nosc":"alleguee","lemma":"alléguer","pos":"VER"} ,
		{"word":"allégé","word_nosc":"allege","lemma":"alléger","pos":"VER"} ,
		{"word":"allégée","word_nosc":"allegee","lemma":"alléger","pos":"VER"} ,
		{"word":"allégées","word_nosc":"allegees","lemma":"alléger","pos":"VER"} ,
		{"word":"allégés","word_nosc":"alleges","lemma":"alléger","pos":"VER"} ,
		{"word":"allés","word_nosc":"alles","lemma":"aller","pos":"VER"} ,
		{"word":"alourdi","word_nosc":"alourdi","lemma":"alourdir","pos":"VER"} ,
		{"word":"alourdie","word_nosc":"alourdie","lemma":"alourdir","pos":"VER"} ,
		{"word":"alourdies","word_nosc":"alourdies","lemma":"alourdir","pos":"VER"} ,
		{"word":"alourdir","word_nosc":"alourdir","lemma":"alourdir","pos":"VER"} ,
		{"word":"alourdirai","word_nosc":"alourdirai","lemma":"alourdir","pos":"VER"} ,
		{"word":"alourdiraient","word_nosc":"alourdiraient","lemma":"alourdir","pos":"VER"} ,
		{"word":"alourdirent","word_nosc":"alourdirent","lemma":"alourdir","pos":"VER"} ,
		{"word":"alourdis","word_nosc":"alourdis","lemma":"alourdir","pos":"VER"} ,
		{"word":"alourdissaient","word_nosc":"alourdissaient","lemma":"alourdir","pos":"VER"} ,
		{"word":"alourdissait","word_nosc":"alourdissait","lemma":"alourdir","pos":"VER"} ,
		{"word":"alourdissant","word_nosc":"alourdissant","lemma":"alourdir","pos":"VER"} ,
		{"word":"alourdissent","word_nosc":"alourdissent","lemma":"alourdir","pos":"VER"} ,
		{"word":"alourdit","word_nosc":"alourdit","lemma":"alourdir","pos":"VER"} ,
		{"word":"alourdît","word_nosc":"alourdit","lemma":"alourdir","pos":"VER"} ,
		{"word":"alpa","word_nosc":"alpa","lemma":"alper","pos":"VER"} ,
		{"word":"alpaguait","word_nosc":"alpaguait","lemma":"alpaguer","pos":"VER"} ,
		{"word":"alpague","word_nosc":"alpague","lemma":"alpaguer","pos":"VER"} ,
		{"word":"alpaguent","word_nosc":"alpaguent","lemma":"alpaguer","pos":"VER"} ,
		{"word":"alpaguer","word_nosc":"alpaguer","lemma":"alpaguer","pos":"VER"} ,
		{"word":"alpaguerait","word_nosc":"alpaguerait","lemma":"alpaguer","pos":"VER"} ,
		{"word":"alpagué","word_nosc":"alpague","lemma":"alpaguer","pos":"VER"} ,
		{"word":"alpaguée","word_nosc":"alpaguee","lemma":"alpaguer","pos":"VER"} ,
		{"word":"alpagués","word_nosc":"alpagues","lemma":"alpaguer","pos":"VER"} ,
		{"word":"alphabétiser","word_nosc":"alphabetiser","lemma":"alphabétiser","pos":"VER"} ,
		{"word":"alterna","word_nosc":"alterna","lemma":"alterner","pos":"VER"} ,
		{"word":"alternaient","word_nosc":"alternaient","lemma":"alterner","pos":"VER"} ,
		{"word":"alternait","word_nosc":"alternait","lemma":"alterner","pos":"VER"} ,
		{"word":"alternant","word_nosc":"alternant","lemma":"alterner","pos":"VER"} ,
		{"word":"alterne","word_nosc":"alterne","lemma":"alterner","pos":"VER"} ,
		{"word":"alternent","word_nosc":"alternent","lemma":"alterner","pos":"VER"} ,
		{"word":"alterner","word_nosc":"alterner","lemma":"alterner","pos":"VER"} ,
		{"word":"alternera","word_nosc":"alternera","lemma":"alterner","pos":"VER"} ,
		{"word":"alternez","word_nosc":"alternez","lemma":"alterner","pos":"VER"} ,
		{"word":"alternions","word_nosc":"alternions","lemma":"alterner","pos":"VER"} ,
		{"word":"alternèrent","word_nosc":"alternerent","lemma":"alterner","pos":"VER"} ,
		{"word":"alterné","word_nosc":"alterne","lemma":"alterner","pos":"VER"} ,
		{"word":"alternée","word_nosc":"alternee","lemma":"alterner","pos":"VER"} ,
		{"word":"alternées","word_nosc":"alternees","lemma":"alterner","pos":"VER"} ,
		{"word":"alternés","word_nosc":"alternes","lemma":"alterner","pos":"VER"} ,
		{"word":"altère","word_nosc":"altere","lemma":"altérer","pos":"VER"} ,
		{"word":"altèrent","word_nosc":"alterent","lemma":"altérer","pos":"VER"} ,
		{"word":"altéra","word_nosc":"altera","lemma":"altérer","pos":"VER"} ,
		{"word":"altéraient","word_nosc":"alteraient","lemma":"altérer","pos":"VER"} ,
		{"word":"altérait","word_nosc":"alterait","lemma":"altérer","pos":"VER"} ,
		{"word":"altérant","word_nosc":"alterant","lemma":"altérer","pos":"VER"} ,
		{"word":"altérer","word_nosc":"alterer","lemma":"altérer","pos":"VER"} ,
		{"word":"altérera","word_nosc":"alterera","lemma":"altérer","pos":"VER"} ,
		{"word":"altéreront","word_nosc":"altereront","lemma":"altérer","pos":"VER"} ,
		{"word":"altérez","word_nosc":"alterez","lemma":"altérer","pos":"VER"} ,
		{"word":"altériez","word_nosc":"alteriez","lemma":"altérer","pos":"VER"} ,
		{"word":"altérât","word_nosc":"alterat","lemma":"altérer","pos":"VER"} ,
		{"word":"altérèrent","word_nosc":"altererent","lemma":"altérer","pos":"VER"} ,
		{"word":"altéré","word_nosc":"altere","lemma":"altérer","pos":"VER"} ,
		{"word":"altérée","word_nosc":"alteree","lemma":"altérer","pos":"VER"} ,
		{"word":"altérées","word_nosc":"alterees","lemma":"altérer","pos":"VER"} ,
		{"word":"altérés","word_nosc":"alteres","lemma":"altérer","pos":"VER"} ,
		{"word":"aluni","word_nosc":"aluni","lemma":"alunir","pos":"VER"} ,
		{"word":"alunir","word_nosc":"alunir","lemma":"alunir","pos":"VER"} ,
		{"word":"alunira","word_nosc":"alunira","lemma":"alunir","pos":"VER"} ,
		{"word":"alésait","word_nosc":"alesait","lemma":"aléser","pos":"VER"} ,
		{"word":"aléser","word_nosc":"aleser","lemma":"aléser","pos":"VER"} ,
		{"word":"amadoua","word_nosc":"amadoua","lemma":"amadouer","pos":"VER"} ,
		{"word":"amadouaient","word_nosc":"amadouaient","lemma":"amadouer","pos":"VER"} ,
		{"word":"amadouait","word_nosc":"amadouait","lemma":"amadouer","pos":"VER"} ,
		{"word":"amadouant","word_nosc":"amadouant","lemma":"amadouer","pos":"VER"} ,
		{"word":"amadoue","word_nosc":"amadoue","lemma":"amadouer","pos":"VER"} ,
		{"word":"amadouer","word_nosc":"amadouer","lemma":"amadouer","pos":"VER"} ,
		{"word":"amadoueras","word_nosc":"amadoueras","lemma":"amadouer","pos":"VER"} ,
		{"word":"amadouez","word_nosc":"amadouez","lemma":"amadouer","pos":"VER"} ,
		{"word":"amadoué","word_nosc":"amadoue","lemma":"amadouer","pos":"VER"} ,
		{"word":"amaigri","word_nosc":"amaigri","lemma":"amaigrir","pos":"VER"} ,
		{"word":"amaigrie","word_nosc":"amaigrie","lemma":"amaigrir","pos":"VER"} ,
		{"word":"amaigries","word_nosc":"amaigries","lemma":"amaigrir","pos":"VER"} ,
		{"word":"amaigrissait","word_nosc":"amaigrissait","lemma":"amaigrir","pos":"VER"} ,
		{"word":"amaigrit","word_nosc":"amaigrit","lemma":"amaigrir","pos":"VER"} ,
		{"word":"amalgamaient","word_nosc":"amalgamaient","lemma":"amalgamer","pos":"VER"} ,
		{"word":"amalgamais","word_nosc":"amalgamais","lemma":"amalgamer","pos":"VER"} ,
		{"word":"amalgamait","word_nosc":"amalgamait","lemma":"amalgamer","pos":"VER"} ,
		{"word":"amalgamant","word_nosc":"amalgamant","lemma":"amalgamer","pos":"VER"} ,
		{"word":"amalgame","word_nosc":"amalgame","lemma":"amalgamer","pos":"VER"} ,
		{"word":"amalgamer","word_nosc":"amalgamer","lemma":"amalgamer","pos":"VER"} ,
		{"word":"amalgamez","word_nosc":"amalgamez","lemma":"amalgamer","pos":"VER"} ,
		{"word":"amalgamé","word_nosc":"amalgame","lemma":"amalgamer","pos":"VER"} ,
		{"word":"amalgamée","word_nosc":"amalgamee","lemma":"amalgamer","pos":"VER"} ,
		{"word":"amalgamées","word_nosc":"amalgamees","lemma":"amalgamer","pos":"VER"} ,
		{"word":"amalgamés","word_nosc":"amalgames","lemma":"amalgamer","pos":"VER"} ,
		{"word":"amarra","word_nosc":"amarra","lemma":"amarrer","pos":"VER"} ,
		{"word":"amarrai","word_nosc":"amarrai","lemma":"amarrer","pos":"VER"} ,
		{"word":"amarraient","word_nosc":"amarraient","lemma":"amarrer","pos":"VER"} ,
		{"word":"amarrais","word_nosc":"amarrais","lemma":"amarrer","pos":"VER"} ,
		{"word":"amarrait","word_nosc":"amarrait","lemma":"amarrer","pos":"VER"} ,
		{"word":"amarrant","word_nosc":"amarrant","lemma":"amarrer","pos":"VER"} ,
		{"word":"amarre","word_nosc":"amarre","lemma":"amarrer","pos":"VER"} ,
		{"word":"amarrent","word_nosc":"amarrent","lemma":"amarrer","pos":"VER"} ,
		{"word":"amarrer","word_nosc":"amarrer","lemma":"amarrer","pos":"VER"} ,
		{"word":"amarres","word_nosc":"amarres","lemma":"amarrer","pos":"VER"} ,
		{"word":"amarrez","word_nosc":"amarrez","lemma":"amarrer","pos":"VER"} ,
		{"word":"amarré","word_nosc":"amarre","lemma":"amarrer","pos":"VER"} ,
		{"word":"amarrée","word_nosc":"amarree","lemma":"amarrer","pos":"VER"} ,
		{"word":"amarrées","word_nosc":"amarrees","lemma":"amarrer","pos":"VER"} ,
		{"word":"amarrés","word_nosc":"amarres","lemma":"amarrer","pos":"VER"} ,
		{"word":"amassa","word_nosc":"amassa","lemma":"amasser","pos":"VER"} ,
		{"word":"amassai","word_nosc":"amassai","lemma":"amasser","pos":"VER"} ,
		{"word":"amassaient","word_nosc":"amassaient","lemma":"amasser","pos":"VER"} ,
		{"word":"amassait","word_nosc":"amassait","lemma":"amasser","pos":"VER"} ,
		{"word":"amassant","word_nosc":"amassant","lemma":"amasser","pos":"VER"} ,
		{"word":"amasse","word_nosc":"amasse","lemma":"amasser","pos":"VER"} ,
		{"word":"amassent","word_nosc":"amassent","lemma":"amasser","pos":"VER"} ,
		{"word":"amasser","word_nosc":"amasser","lemma":"amasser","pos":"VER"} ,
		{"word":"amassera","word_nosc":"amassera","lemma":"amasser","pos":"VER"} ,
		{"word":"amassez","word_nosc":"amassez","lemma":"amasser","pos":"VER"} ,
		{"word":"amassons","word_nosc":"amassons","lemma":"amasser","pos":"VER"} ,
		{"word":"amassèrent","word_nosc":"amasserent","lemma":"amasser","pos":"VER"} ,
		{"word":"amassé","word_nosc":"amasse","lemma":"amasser","pos":"VER"} ,
		{"word":"amassée","word_nosc":"amassee","lemma":"amasser","pos":"VER"} ,
		{"word":"amassées","word_nosc":"amassees","lemma":"amasser","pos":"VER"} ,
		{"word":"amassés","word_nosc":"amasses","lemma":"amasser","pos":"VER"} ,
		{"word":"amati","word_nosc":"amati","lemma":"amatir","pos":"VER"} ,
		{"word":"ambitionnais","word_nosc":"ambitionnais","lemma":"ambitionner","pos":"VER"} ,
		{"word":"ambitionnait","word_nosc":"ambitionnait","lemma":"ambitionner","pos":"VER"} ,
		{"word":"ambitionnant","word_nosc":"ambitionnant","lemma":"ambitionner","pos":"VER"} ,
		{"word":"ambitionne","word_nosc":"ambitionne","lemma":"ambitionner","pos":"VER"} ,
		{"word":"ambitionnent","word_nosc":"ambitionnent","lemma":"ambitionner","pos":"VER"} ,
		{"word":"ambitionner","word_nosc":"ambitionner","lemma":"ambitionner","pos":"VER"} ,
		{"word":"ambitionnez","word_nosc":"ambitionnez","lemma":"ambitionner","pos":"VER"} ,
		{"word":"ambitionné","word_nosc":"ambitionne","lemma":"ambitionner","pos":"VER"} ,
		{"word":"ambler","word_nosc":"ambler","lemma":"ambler","pos":"VER"} ,
		{"word":"ambras","word_nosc":"ambras","lemma":"ambrer","pos":"VER"} ,
		{"word":"ambrer","word_nosc":"ambrer","lemma":"ambrer","pos":"VER"} ,
		{"word":"ambré","word_nosc":"ambre","lemma":"ambrer","pos":"VER"} ,
		{"word":"ambrée","word_nosc":"ambree","lemma":"ambrer","pos":"VER"} ,
		{"word":"ambrées","word_nosc":"ambrees","lemma":"ambrer","pos":"VER"} ,
		{"word":"ambrés","word_nosc":"ambres","lemma":"ambrer","pos":"VER"} ,
		{"word":"amena","word_nosc":"amena","lemma":"amener","pos":"VER"} ,
		{"word":"amenai","word_nosc":"amenai","lemma":"amener","pos":"VER"} ,
		{"word":"amenaient","word_nosc":"amenaient","lemma":"amener","pos":"VER"} ,
		{"word":"amenais","word_nosc":"amenais","lemma":"amener","pos":"VER"} ,
		{"word":"amenait","word_nosc":"amenait","lemma":"amener","pos":"VER"} ,
		{"word":"amenant","word_nosc":"amenant","lemma":"amener","pos":"VER"} ,
		{"word":"amendai","word_nosc":"amendai","lemma":"amender","pos":"VER"} ,
		{"word":"amendait","word_nosc":"amendait","lemma":"amender","pos":"VER"} ,
		{"word":"amende","word_nosc":"amende","lemma":"amender","pos":"VER"} ,
		{"word":"amendent","word_nosc":"amendent","lemma":"amender","pos":"VER"} ,
		{"word":"amender","word_nosc":"amender","lemma":"amender","pos":"VER"} ,
		{"word":"amenderez","word_nosc":"amenderez","lemma":"amender","pos":"VER"} ,
		{"word":"amendes","word_nosc":"amendes","lemma":"amender","pos":"VER"} ,
		{"word":"amendez","word_nosc":"amendez","lemma":"amender","pos":"VER"} ,
		{"word":"amendât","word_nosc":"amendat","lemma":"amender","pos":"VER"} ,
		{"word":"amendé","word_nosc":"amende","lemma":"amender","pos":"VER"} ,
		{"word":"amendée","word_nosc":"amendee","lemma":"amender","pos":"VER"} ,
		{"word":"amener","word_nosc":"amener","lemma":"amener","pos":"VER"} ,
		{"word":"amenez","word_nosc":"amenez","lemma":"amener","pos":"VER"} ,
		{"word":"ameniez","word_nosc":"ameniez","lemma":"amener","pos":"VER"} ,
		{"word":"amenions","word_nosc":"amenions","lemma":"amener","pos":"VER"} ,
		{"word":"amenons","word_nosc":"amenons","lemma":"amener","pos":"VER"} ,
		{"word":"amenuisaient","word_nosc":"amenuisaient","lemma":"amenuiser","pos":"VER"} ,
		{"word":"amenuisait","word_nosc":"amenuisait","lemma":"amenuiser","pos":"VER"} ,
		{"word":"amenuisant","word_nosc":"amenuisant","lemma":"amenuiser","pos":"VER"} ,
		{"word":"amenuise","word_nosc":"amenuise","lemma":"amenuiser","pos":"VER"} ,
		{"word":"amenuisent","word_nosc":"amenuisent","lemma":"amenuiser","pos":"VER"} ,
		{"word":"amenuiser","word_nosc":"amenuiser","lemma":"amenuiser","pos":"VER"} ,
		{"word":"amenuiserait","word_nosc":"amenuiserait","lemma":"amenuiser","pos":"VER"} ,
		{"word":"amenuisèrent","word_nosc":"amenuiserent","lemma":"amenuiser","pos":"VER"} ,
		{"word":"amenuisé","word_nosc":"amenuise","lemma":"amenuiser","pos":"VER"} ,
		{"word":"amenuisée","word_nosc":"amenuisee","lemma":"amenuiser","pos":"VER"} ,
		{"word":"amenuisés","word_nosc":"amenuises","lemma":"amenuiser","pos":"VER"} ,
		{"word":"amenât","word_nosc":"amenat","lemma":"amener","pos":"VER"} ,
		{"word":"amenèrent","word_nosc":"amenerent","lemma":"amener","pos":"VER"} ,
		{"word":"amené","word_nosc":"amene","lemma":"amener","pos":"VER"} ,
		{"word":"amenée","word_nosc":"amenee","lemma":"amener","pos":"VER"} ,
		{"word":"amenées","word_nosc":"amenees","lemma":"amener","pos":"VER"} ,
		{"word":"amenés","word_nosc":"amenes","lemma":"amener","pos":"VER"} ,
		{"word":"amerri","word_nosc":"amerri","lemma":"amerrir","pos":"VER"} ,
		{"word":"amerrir","word_nosc":"amerrir","lemma":"amerrir","pos":"VER"} ,
		{"word":"amerrit","word_nosc":"amerrit","lemma":"amerrir","pos":"VER"} ,
		{"word":"ameuta","word_nosc":"ameuta","lemma":"ameuter","pos":"VER"} ,
		{"word":"ameutaient","word_nosc":"ameutaient","lemma":"ameuter","pos":"VER"} ,
		{"word":"ameutait","word_nosc":"ameutait","lemma":"ameuter","pos":"VER"} ,
		{"word":"ameutant","word_nosc":"ameutant","lemma":"ameuter","pos":"VER"} ,
		{"word":"ameute","word_nosc":"ameute","lemma":"ameuter","pos":"VER"} ,
		{"word":"ameutent","word_nosc":"ameutent","lemma":"ameuter","pos":"VER"} ,
		{"word":"ameuter","word_nosc":"ameuter","lemma":"ameuter","pos":"VER"} ,
		{"word":"ameutera","word_nosc":"ameutera","lemma":"ameuter","pos":"VER"} ,
		{"word":"ameuterait","word_nosc":"ameuterait","lemma":"ameuter","pos":"VER"} ,
		{"word":"ameutât","word_nosc":"ameutat","lemma":"ameuter","pos":"VER"} ,
		{"word":"ameuté","word_nosc":"ameute","lemma":"ameuter","pos":"VER"} ,
		{"word":"ameutée","word_nosc":"ameutee","lemma":"ameuter","pos":"VER"} ,
		{"word":"ameutées","word_nosc":"ameutees","lemma":"ameuter","pos":"VER"} ,
		{"word":"ameutés","word_nosc":"ameutes","lemma":"ameuter","pos":"VER"} ,
		{"word":"amidonnaient","word_nosc":"amidonnaient","lemma":"amidonner","pos":"VER"} ,
		{"word":"amidonnent","word_nosc":"amidonnent","lemma":"amidonner","pos":"VER"} ,
		{"word":"amidonner","word_nosc":"amidonner","lemma":"amidonner","pos":"VER"} ,
		{"word":"amidonnez","word_nosc":"amidonnez","lemma":"amidonner","pos":"VER"} ,
		{"word":"amidonné","word_nosc":"amidonne","lemma":"amidonner","pos":"VER"} ,
		{"word":"amidonnée","word_nosc":"amidonnee","lemma":"amidonner","pos":"VER"} ,
		{"word":"amidonnées","word_nosc":"amidonnees","lemma":"amidonner","pos":"VER"} ,
		{"word":"amidonnés","word_nosc":"amidonnes","lemma":"amidonner","pos":"VER"} ,
		{"word":"amignoter","word_nosc":"amignoter","lemma":"amignoter","pos":"VER"} ,
		{"word":"aminci","word_nosc":"aminci","lemma":"amincir","pos":"VER"} ,
		{"word":"amincie","word_nosc":"amincie","lemma":"amincir","pos":"VER"} ,
		{"word":"amincies","word_nosc":"amincies","lemma":"amincir","pos":"VER"} ,
		{"word":"amincir","word_nosc":"amincir","lemma":"amincir","pos":"VER"} ,
		{"word":"amincissaient","word_nosc":"amincissaient","lemma":"amincir","pos":"VER"} ,
		{"word":"amincissait","word_nosc":"amincissait","lemma":"amincir","pos":"VER"} ,
		{"word":"amincissant","word_nosc":"amincissant","lemma":"amincir","pos":"VER"} ,
		{"word":"amincissent","word_nosc":"amincissent","lemma":"amincir","pos":"VER"} ,
		{"word":"amincit","word_nosc":"amincit","lemma":"amincir","pos":"VER"} ,
		{"word":"amnistie","word_nosc":"amnistie","lemma":"amnistier","pos":"VER"} ,
		{"word":"amnistier","word_nosc":"amnistier","lemma":"amnistier","pos":"VER"} ,
		{"word":"amnistierons","word_nosc":"amnistierons","lemma":"amnistier","pos":"VER"} ,
		{"word":"amnistié","word_nosc":"amnistie","lemma":"amnistier","pos":"VER"} ,
		{"word":"amnistiée","word_nosc":"amnistiee","lemma":"amnistier","pos":"VER"} ,
		{"word":"amnistiés","word_nosc":"amnisties","lemma":"amnistier","pos":"VER"} ,
		{"word":"amochait","word_nosc":"amochait","lemma":"amocher","pos":"VER"} ,
		{"word":"amoche","word_nosc":"amoche","lemma":"amocher","pos":"VER"} ,
		{"word":"amocher","word_nosc":"amocher","lemma":"amocher","pos":"VER"} ,
		{"word":"amochez","word_nosc":"amochez","lemma":"amocher","pos":"VER"} ,
		{"word":"amochie","word_nosc":"amochie","lemma":"amochir","pos":"VER"} ,
		{"word":"amoché","word_nosc":"amoche","lemma":"amocher","pos":"VER"} ,
		{"word":"amochée","word_nosc":"amochee","lemma":"amocher","pos":"VER"} ,
		{"word":"amochés","word_nosc":"amoches","lemma":"amocher","pos":"VER"} ,
		{"word":"amoindri","word_nosc":"amoindri","lemma":"amoindrir","pos":"VER"} ,
		{"word":"amoindrie","word_nosc":"amoindrie","lemma":"amoindrir","pos":"VER"} ,
		{"word":"amoindries","word_nosc":"amoindries","lemma":"amoindrir","pos":"VER"} ,
		{"word":"amoindrir","word_nosc":"amoindrir","lemma":"amoindrir","pos":"VER"} ,
		{"word":"amoindrira","word_nosc":"amoindrira","lemma":"amoindrir","pos":"VER"} ,
		{"word":"amoindris","word_nosc":"amoindris","lemma":"amoindrir","pos":"VER"} ,
		{"word":"amoindrissait","word_nosc":"amoindrissait","lemma":"amoindrir","pos":"VER"} ,
		{"word":"amoindrit","word_nosc":"amoindrit","lemma":"amoindrir","pos":"VER"} ,
		{"word":"amolli","word_nosc":"amolli","lemma":"amollir","pos":"VER"} ,
		{"word":"amollie","word_nosc":"amollie","lemma":"amollir","pos":"VER"} ,
		{"word":"amollies","word_nosc":"amollies","lemma":"amollir","pos":"VER"} ,
		{"word":"amollir","word_nosc":"amollir","lemma":"amollir","pos":"VER"} ,
		{"word":"amollirent","word_nosc":"amollirent","lemma":"amollir","pos":"VER"} ,
		{"word":"amollis","word_nosc":"amollis","lemma":"amollir","pos":"VER"} ,
		{"word":"amollissaient","word_nosc":"amollissaient","lemma":"amollir","pos":"VER"} ,
		{"word":"amollissait","word_nosc":"amollissait","lemma":"amollir","pos":"VER"} ,
		{"word":"amollissant","word_nosc":"amollissant","lemma":"amollir","pos":"VER"} ,
		{"word":"amollissent","word_nosc":"amollissent","lemma":"amollir","pos":"VER"} ,
		{"word":"amollit","word_nosc":"amollit","lemma":"amollir","pos":"VER"} ,
		{"word":"amoncelaient","word_nosc":"amoncelaient","lemma":"amonceler","pos":"VER"} ,
		{"word":"amoncelait","word_nosc":"amoncelait","lemma":"amonceler","pos":"VER"} ,
		{"word":"amoncelant","word_nosc":"amoncelant","lemma":"amonceler","pos":"VER"} ,
		{"word":"amonceler","word_nosc":"amonceler","lemma":"amonceler","pos":"VER"} ,
		{"word":"amoncelle","word_nosc":"amoncelle","lemma":"amonceler","pos":"VER"} ,
		{"word":"amoncellent","word_nosc":"amoncellent","lemma":"amonceler","pos":"VER"} ,
		{"word":"amoncelleraient","word_nosc":"amoncelleraient","lemma":"amonceler","pos":"VER"} ,
		{"word":"amoncelèrent","word_nosc":"amoncelerent","lemma":"amonceler","pos":"VER"} ,
		{"word":"amoncelé","word_nosc":"amoncele","lemma":"amonceler","pos":"VER"} ,
		{"word":"amoncelée","word_nosc":"amoncelee","lemma":"amonceler","pos":"VER"} ,
		{"word":"amoncelées","word_nosc":"amoncelees","lemma":"amonceler","pos":"VER"} ,
		{"word":"amoncelés","word_nosc":"amonceles","lemma":"amonceler","pos":"VER"} ,
		{"word":"amorce","word_nosc":"amorce","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorcent","word_nosc":"amorcent","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorcer","word_nosc":"amorcer","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorcerait","word_nosc":"amorcerait","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorces","word_nosc":"amorces","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorcez","word_nosc":"amorcez","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorcèrent","word_nosc":"amorcerent","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorcé","word_nosc":"amorce","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorcée","word_nosc":"amorcee","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorcées","word_nosc":"amorcees","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorcés","word_nosc":"amorces","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorti","word_nosc":"amorti","lemma":"amortir","pos":"VER"} ,
		{"word":"amortie","word_nosc":"amortie","lemma":"amortir","pos":"VER"} ,
		{"word":"amorties","word_nosc":"amorties","lemma":"amortir","pos":"VER"} ,
		{"word":"amortir","word_nosc":"amortir","lemma":"amortir","pos":"VER"} ,
		{"word":"amortirent","word_nosc":"amortirent","lemma":"amortir","pos":"VER"} ,
		{"word":"amortiront","word_nosc":"amortiront","lemma":"amortir","pos":"VER"} ,
		{"word":"amortis","word_nosc":"amortis","lemma":"amortir","pos":"VER"} ,
		{"word":"amortissaient","word_nosc":"amortissaient","lemma":"amortir","pos":"VER"} ,
		{"word":"amortissait","word_nosc":"amortissait","lemma":"amortir","pos":"VER"} ,
		{"word":"amortissant","word_nosc":"amortissant","lemma":"amortir","pos":"VER"} ,
		{"word":"amortisse","word_nosc":"amortisse","lemma":"amortir","pos":"VER"} ,
		{"word":"amortissent","word_nosc":"amortissent","lemma":"amortir","pos":"VER"} ,
		{"word":"amortit","word_nosc":"amortit","lemma":"amortir","pos":"VER"} ,
		{"word":"amorça","word_nosc":"amorca","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorçai","word_nosc":"amorcai","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorçaient","word_nosc":"amorcaient","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorçais","word_nosc":"amorcais","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorçait","word_nosc":"amorcait","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorçant","word_nosc":"amorcant","lemma":"amorcer","pos":"VER"} ,
		{"word":"amorçons","word_nosc":"amorcons","lemma":"amorcer","pos":"VER"} ,
		{"word":"amouracha","word_nosc":"amouracha","lemma":"amouracher","pos":"VER"} ,
		{"word":"amourache","word_nosc":"amourache","lemma":"amouracher","pos":"VER"} ,
		{"word":"amourachent","word_nosc":"amourachent","lemma":"amouracher","pos":"VER"} ,
		{"word":"amouracher","word_nosc":"amouracher","lemma":"amouracher","pos":"VER"} ,
		{"word":"amourachera","word_nosc":"amourachera","lemma":"amouracher","pos":"VER"} ,
		{"word":"amouraches","word_nosc":"amouraches","lemma":"amouracher","pos":"VER"} ,
		{"word":"amouraché","word_nosc":"amourache","lemma":"amouracher","pos":"VER"} ,
		{"word":"amourachée","word_nosc":"amourachee","lemma":"amouracher","pos":"VER"} ,
		{"word":"amourer","word_nosc":"amourer","lemma":"amourer","pos":"VER"} ,
		{"word":"amplifia","word_nosc":"amplifia","lemma":"amplifier","pos":"VER"} ,
		{"word":"amplifiaient","word_nosc":"amplifiaient","lemma":"amplifier","pos":"VER"} ,
		{"word":"amplifiait","word_nosc":"amplifiait","lemma":"amplifier","pos":"VER"} ,
		{"word":"amplifiant","word_nosc":"amplifiant","lemma":"amplifier","pos":"VER"} ,
		{"word":"amplifie","word_nosc":"amplifie","lemma":"amplifier","pos":"VER"} ,
		{"word":"amplifient","word_nosc":"amplifient","lemma":"amplifier","pos":"VER"} ,
		{"word":"amplifier","word_nosc":"amplifier","lemma":"amplifier","pos":"VER"} ,
		{"word":"amplifiera","word_nosc":"amplifiera","lemma":"amplifier","pos":"VER"} ,
		{"word":"amplifierais","word_nosc":"amplifierais","lemma":"amplifier","pos":"VER"} ,
		{"word":"amplifiez","word_nosc":"amplifiez","lemma":"amplifier","pos":"VER"} ,
		{"word":"amplifions","word_nosc":"amplifions","lemma":"amplifier","pos":"VER"} ,
		{"word":"amplifièrent","word_nosc":"amplifierent","lemma":"amplifier","pos":"VER"} ,
		{"word":"amplifié","word_nosc":"amplifie","lemma":"amplifier","pos":"VER"} ,
		{"word":"amplifiée","word_nosc":"amplifiee","lemma":"amplifier","pos":"VER"} ,
		{"word":"amplifiées","word_nosc":"amplifiees","lemma":"amplifier","pos":"VER"} ,
		{"word":"amplifiés","word_nosc":"amplifies","lemma":"amplifier","pos":"VER"} ,
		{"word":"amputa","word_nosc":"amputa","lemma":"amputer","pos":"VER"} ,
		{"word":"amputait","word_nosc":"amputait","lemma":"amputer","pos":"VER"} ,
		{"word":"amputant","word_nosc":"amputant","lemma":"amputer","pos":"VER"} ,
		{"word":"ampute","word_nosc":"ampute","lemma":"amputer","pos":"VER"} ,
		{"word":"amputent","word_nosc":"amputent","lemma":"amputer","pos":"VER"} ,
		{"word":"amputer","word_nosc":"amputer","lemma":"amputer","pos":"VER"} ,
		{"word":"amputerai","word_nosc":"amputerai","lemma":"amputer","pos":"VER"} ,
		{"word":"amputerait","word_nosc":"amputerait","lemma":"amputer","pos":"VER"} ,
		{"word":"amputez","word_nosc":"amputez","lemma":"amputer","pos":"VER"} ,
		{"word":"amputé","word_nosc":"ampute","lemma":"amputer","pos":"VER"} ,
		{"word":"amputée","word_nosc":"amputee","lemma":"amputer","pos":"VER"} ,
		{"word":"amputées","word_nosc":"amputees","lemma":"amputer","pos":"VER"} ,
		{"word":"amputés","word_nosc":"amputes","lemma":"amputer","pos":"VER"} ,
		{"word":"amura","word_nosc":"amura","lemma":"amurer","pos":"VER"} ,
		{"word":"amusa","word_nosc":"amusa","lemma":"amuser","pos":"VER"} ,
		{"word":"amusai","word_nosc":"amusai","lemma":"amuser","pos":"VER"} ,
		{"word":"amusaient","word_nosc":"amusaient","lemma":"amuser","pos":"VER"} ,
		{"word":"amusais","word_nosc":"amusais","lemma":"amuser","pos":"VER"} ,
		{"word":"amusait","word_nosc":"amusait","lemma":"amuser","pos":"VER"} ,
		{"word":"amusant","word_nosc":"amusant","lemma":"amuser","pos":"VER"} ,
		{"word":"amuse","word_nosc":"amuse","lemma":"amuser","pos":"VER"} ,
		{"word":"amusent","word_nosc":"amusent","lemma":"amuser","pos":"VER"} ,
		{"word":"amuser","word_nosc":"amuser","lemma":"amuser","pos":"VER"} ,
		{"word":"amusera","word_nosc":"amusera","lemma":"amuser","pos":"VER"} ,
		{"word":"amuserai","word_nosc":"amuserai","lemma":"amuser","pos":"VER"} ,
		{"word":"amuseraient","word_nosc":"amuseraient","lemma":"amuser","pos":"VER"} ,
		{"word":"amuserais","word_nosc":"amuserais","lemma":"amuser","pos":"VER"} ,
		{"word":"amuserait","word_nosc":"amuserait","lemma":"amuser","pos":"VER"} ,
		{"word":"amuseras","word_nosc":"amuseras","lemma":"amuser","pos":"VER"} ,
		{"word":"amuserez","word_nosc":"amuserez","lemma":"amuser","pos":"VER"} ,
		{"word":"amuseriez","word_nosc":"amuseriez","lemma":"amuser","pos":"VER"} ,
		{"word":"amuserons","word_nosc":"amuserons","lemma":"amuser","pos":"VER"} ,
		{"word":"amuseront","word_nosc":"amuseront","lemma":"amuser","pos":"VER"} ,
		{"word":"amuses","word_nosc":"amuses","lemma":"amuser","pos":"VER"} ,
		{"word":"amusez","word_nosc":"amusez","lemma":"amuser","pos":"VER"} ,
		{"word":"amusiez","word_nosc":"amusiez","lemma":"amuser","pos":"VER"} ,
		{"word":"amusions","word_nosc":"amusions","lemma":"amuser","pos":"VER"} ,
		{"word":"amusons","word_nosc":"amusons","lemma":"amuser","pos":"VER"} ,
		{"word":"amusâmes","word_nosc":"amusames","lemma":"amuser","pos":"VER"} ,
		{"word":"amusât","word_nosc":"amusat","lemma":"amuser","pos":"VER"} ,
		{"word":"amusèrent","word_nosc":"amuserent","lemma":"amuser","pos":"VER"} ,
		{"word":"amusé","word_nosc":"amuse","lemma":"amuser","pos":"VER"} ,
		{"word":"amusée","word_nosc":"amusee","lemma":"amuser","pos":"VER"} ,
		{"word":"amusées","word_nosc":"amusees","lemma":"amuser","pos":"VER"} ,
		{"word":"amusés","word_nosc":"amuses","lemma":"amuser","pos":"VER"} ,
		{"word":"amène","word_nosc":"amene","lemma":"amener","pos":"VER"} ,
		{"word":"amènent","word_nosc":"amenent","lemma":"amener","pos":"VER"} ,
		{"word":"amènera","word_nosc":"amenera","lemma":"amener","pos":"VER"} ,
		{"word":"amènerai","word_nosc":"amenerai","lemma":"amener","pos":"VER"} ,
		{"word":"amèneraient","word_nosc":"ameneraient","lemma":"amener","pos":"VER"} ,
		{"word":"amènerais","word_nosc":"amenerais","lemma":"amener","pos":"VER"} ,
		{"word":"amènerait","word_nosc":"amenerait","lemma":"amener","pos":"VER"} ,
		{"word":"amèneras","word_nosc":"ameneras","lemma":"amener","pos":"VER"} ,
		{"word":"amènerez","word_nosc":"amenerez","lemma":"amener","pos":"VER"} ,
		{"word":"amèneriez","word_nosc":"ameneriez","lemma":"amener","pos":"VER"} ,
		{"word":"amènerions","word_nosc":"amenerions","lemma":"amener","pos":"VER"} ,
		{"word":"amènerons","word_nosc":"amenerons","lemma":"amener","pos":"VER"} ,
		{"word":"amèneront","word_nosc":"ameneront","lemma":"amener","pos":"VER"} ,
		{"word":"amènes","word_nosc":"amenes","lemma":"amener","pos":"VER"} ,
		{"word":"améliora","word_nosc":"ameliora","lemma":"améliorer","pos":"VER"} ,
		{"word":"amélioraient","word_nosc":"amelioraient","lemma":"améliorer","pos":"VER"} ,
		{"word":"améliorait","word_nosc":"ameliorait","lemma":"améliorer","pos":"VER"} ,
		{"word":"améliorant","word_nosc":"ameliorant","lemma":"améliorer","pos":"VER"} ,
		{"word":"améliore","word_nosc":"ameliore","lemma":"améliorer","pos":"VER"} ,
		{"word":"améliorent","word_nosc":"ameliorent","lemma":"améliorer","pos":"VER"} ,
		{"word":"améliorer","word_nosc":"ameliorer","lemma":"améliorer","pos":"VER"} ,
		{"word":"améliorera","word_nosc":"ameliorera","lemma":"améliorer","pos":"VER"} ,
		{"word":"améliorerait","word_nosc":"ameliorerait","lemma":"améliorer","pos":"VER"} ,
		{"word":"amélioreront","word_nosc":"amelioreront","lemma":"améliorer","pos":"VER"} ,
		{"word":"améliores","word_nosc":"ameliores","lemma":"améliorer","pos":"VER"} ,
		{"word":"améliorez","word_nosc":"ameliorez","lemma":"améliorer","pos":"VER"} ,
		{"word":"améliorons","word_nosc":"ameliorons","lemma":"améliorer","pos":"VER"} ,
		{"word":"améliorèrent","word_nosc":"ameliorerent","lemma":"améliorer","pos":"VER"} ,
		{"word":"amélioré","word_nosc":"ameliore","lemma":"améliorer","pos":"VER"} ,
		{"word":"améliorée","word_nosc":"amelioree","lemma":"améliorer","pos":"VER"} ,
		{"word":"améliorées","word_nosc":"ameliorees","lemma":"améliorer","pos":"VER"} ,
		{"word":"améliorés","word_nosc":"ameliores","lemma":"améliorer","pos":"VER"} ,
		{"word":"aménage","word_nosc":"amenage","lemma":"aménager","pos":"VER"} ,
		{"word":"aménagea","word_nosc":"amenagea","lemma":"aménager","pos":"VER"} ,
		{"word":"aménageai","word_nosc":"amenageai","lemma":"aménager","pos":"VER"} ,
		{"word":"aménageaient","word_nosc":"amenageaient","lemma":"aménager","pos":"VER"} ,
		{"word":"aménageait","word_nosc":"amenageait","lemma":"aménager","pos":"VER"} ,
		{"word":"aménageant","word_nosc":"amenageant","lemma":"aménager","pos":"VER"} ,
		{"word":"aménagent","word_nosc":"amenagent","lemma":"aménager","pos":"VER"} ,
		{"word":"aménager","word_nosc":"amenager","lemma":"aménager","pos":"VER"} ,
		{"word":"aménagerai","word_nosc":"amenagerai","lemma":"aménager","pos":"VER"} ,
		{"word":"aménagerait","word_nosc":"amenagerait","lemma":"aménager","pos":"VER"} ,
		{"word":"aménagez","word_nosc":"amenagez","lemma":"aménager","pos":"VER"} ,
		{"word":"aménagèrent","word_nosc":"amenagerent","lemma":"aménager","pos":"VER"} ,
		{"word":"aménagé","word_nosc":"amenage","lemma":"aménager","pos":"VER"} ,
		{"word":"aménagée","word_nosc":"amenagee","lemma":"aménager","pos":"VER"} ,
		{"word":"aménagées","word_nosc":"amenagees","lemma":"aménager","pos":"VER"} ,
		{"word":"aménagés","word_nosc":"amenages","lemma":"aménager","pos":"VER"} ,
		{"word":"américanisais","word_nosc":"americanisais","lemma":"américaniser","pos":"VER"} ,
		{"word":"américaniser","word_nosc":"americaniser","lemma":"américaniser","pos":"VER"} ,
		{"word":"américanisé","word_nosc":"americanise","lemma":"américaniser","pos":"VER"} ,
		{"word":"américanisés","word_nosc":"americanises","lemma":"américaniser","pos":"VER"} ,
		{"word":"analysa","word_nosc":"analysa","lemma":"analyser","pos":"VER"} ,
		{"word":"analysaient","word_nosc":"analysaient","lemma":"analyser","pos":"VER"} ,
		{"word":"analysais","word_nosc":"analysais","lemma":"analyser","pos":"VER"} ,
		{"word":"analysait","word_nosc":"analysait","lemma":"analyser","pos":"VER"} ,
		{"word":"analysant","word_nosc":"analysant","lemma":"analyser","pos":"VER"} ,
		{"word":"analyse","word_nosc":"analyse","lemma":"analyser","pos":"VER"} ,
		{"word":"analysent","word_nosc":"analysent","lemma":"analyser","pos":"VER"} ,
		{"word":"analyser","word_nosc":"analyser","lemma":"analyser","pos":"VER"} ,
		{"word":"analysera","word_nosc":"analysera","lemma":"analyser","pos":"VER"} ,
		{"word":"analyserai","word_nosc":"analyserai","lemma":"analyser","pos":"VER"} ,
		{"word":"analyserait","word_nosc":"analyserait","lemma":"analyser","pos":"VER"} ,
		{"word":"analyserons","word_nosc":"analyserons","lemma":"analyser","pos":"VER"} ,
		{"word":"analyseront","word_nosc":"analyseront","lemma":"analyser","pos":"VER"} ,
		{"word":"analyses","word_nosc":"analyses","lemma":"analyser","pos":"VER"} ,
		{"word":"analysez","word_nosc":"analysez","lemma":"analyser","pos":"VER"} ,
		{"word":"analysions","word_nosc":"analysions","lemma":"analyser","pos":"VER"} ,
		{"word":"analysons","word_nosc":"analysons","lemma":"analyser","pos":"VER"} ,
		{"word":"analysèrent","word_nosc":"analyserent","lemma":"analyser","pos":"VER"} ,
		{"word":"analysé","word_nosc":"analyse","lemma":"analyser","pos":"VER"} ,
		{"word":"analysée","word_nosc":"analysee","lemma":"analyser","pos":"VER"} ,
		{"word":"analysées","word_nosc":"analysees","lemma":"analyser","pos":"VER"} ,
		{"word":"analysés","word_nosc":"analyses","lemma":"analyser","pos":"VER"} ,
		{"word":"anathématisait","word_nosc":"anathematisait","lemma":"anathématiser","pos":"VER"} ,
		{"word":"anathématisé","word_nosc":"anathematise","lemma":"anathématiser","pos":"VER"} ,
		{"word":"anatomiser","word_nosc":"anatomiser","lemma":"anatomiser","pos":"VER"} ,
		{"word":"ancra","word_nosc":"ancra","lemma":"ancrer","pos":"VER"} ,
		{"word":"ancraient","word_nosc":"ancraient","lemma":"ancrer","pos":"VER"} ,
		{"word":"ancrais","word_nosc":"ancrais","lemma":"ancrer","pos":"VER"} ,
		{"word":"ancrait","word_nosc":"ancrait","lemma":"ancrer","pos":"VER"} ,
		{"word":"ancrant","word_nosc":"ancrant","lemma":"ancrer","pos":"VER"} ,
		{"word":"ancre","word_nosc":"ancre","lemma":"ancrer","pos":"VER"} ,
		{"word":"ancrer","word_nosc":"ancrer","lemma":"ancrer","pos":"VER"} ,
		{"word":"ancrera","word_nosc":"ancrera","lemma":"ancrer","pos":"VER"} ,
		{"word":"ancres","word_nosc":"ancres","lemma":"ancrer","pos":"VER"} ,
		{"word":"ancrez","word_nosc":"ancrez","lemma":"ancrer","pos":"VER"} ,
		{"word":"ancré","word_nosc":"ancre","lemma":"ancrer","pos":"VER"} ,
		{"word":"ancrée","word_nosc":"ancree","lemma":"ancrer","pos":"VER"} ,
		{"word":"ancrées","word_nosc":"ancrees","lemma":"ancrer","pos":"VER"} ,
		{"word":"ancrés","word_nosc":"ancres","lemma":"ancrer","pos":"VER"} ,
		{"word":"anesthésia","word_nosc":"anesthesia","lemma":"anesthésier","pos":"VER"} ,
		{"word":"anesthésiaient","word_nosc":"anesthesiaient","lemma":"anesthésier","pos":"VER"} ,
		{"word":"anesthésiait","word_nosc":"anesthesiait","lemma":"anesthésier","pos":"VER"} ,
		{"word":"anesthésiant","word_nosc":"anesthesiant","lemma":"anesthésier","pos":"VER"} ,
		{"word":"anesthésie","word_nosc":"anesthesie","lemma":"anesthésier","pos":"VER"} ,
		{"word":"anesthésient","word_nosc":"anesthesient","lemma":"anesthésier","pos":"VER"} ,
		{"word":"anesthésier","word_nosc":"anesthesier","lemma":"anesthésier","pos":"VER"} ,
		{"word":"anesthésierai","word_nosc":"anesthesierai","lemma":"anesthésier","pos":"VER"} ,
		{"word":"anesthésies","word_nosc":"anesthesies","lemma":"anesthésier","pos":"VER"} ,
		{"word":"anesthésiez","word_nosc":"anesthesiez","lemma":"anesthésier","pos":"VER"} ,
		{"word":"anesthésions","word_nosc":"anesthesions","lemma":"anesthésier","pos":"VER"} ,
		{"word":"anesthésié","word_nosc":"anesthesie","lemma":"anesthésier","pos":"VER"} ,
		{"word":"anesthésiée","word_nosc":"anesthesiee","lemma":"anesthésier","pos":"VER"} ,
		{"word":"anesthésiées","word_nosc":"anesthesiees","lemma":"anesthésier","pos":"VER"} ,
		{"word":"anesthésiés","word_nosc":"anesthesies","lemma":"anesthésier","pos":"VER"} ,
		{"word":"anglais","word_nosc":"anglais","lemma":"angler","pos":"VER"} ,
		{"word":"angler","word_nosc":"angler","lemma":"angler","pos":"VER"} ,
		{"word":"angliciser","word_nosc":"angliciser","lemma":"angliciser","pos":"VER"} ,
		{"word":"anglicisé","word_nosc":"anglicise","lemma":"angliciser","pos":"VER"} ,
		{"word":"angoissa","word_nosc":"angoissa","lemma":"angoisser","pos":"VER"} ,
		{"word":"angoissaient","word_nosc":"angoissaient","lemma":"angoisser","pos":"VER"} ,
		{"word":"angoissais","word_nosc":"angoissais","lemma":"angoisser","pos":"VER"} ,
		{"word":"angoissait","word_nosc":"angoissait","lemma":"angoisser","pos":"VER"} ,
		{"word":"angoissant","word_nosc":"angoissant","lemma":"angoisser","pos":"VER"} ,
		{"word":"angoisse","word_nosc":"angoisse","lemma":"angoisser","pos":"VER"} ,
		{"word":"angoissent","word_nosc":"angoissent","lemma":"angoisser","pos":"VER"} ,
		{"word":"angoisser","word_nosc":"angoisser","lemma":"angoisser","pos":"VER"} ,
		{"word":"angoisserait","word_nosc":"angoisserait","lemma":"angoisser","pos":"VER"} ,
		{"word":"angoisses","word_nosc":"angoisses","lemma":"angoisser","pos":"VER"} ,
		{"word":"angoissez","word_nosc":"angoissez","lemma":"angoisser","pos":"VER"} ,
		{"word":"angoissiez","word_nosc":"angoissiez","lemma":"angoisser","pos":"VER"} ,
		{"word":"angoissé","word_nosc":"angoisse","lemma":"angoisser","pos":"VER"} ,
		{"word":"angoissée","word_nosc":"angoissee","lemma":"angoisser","pos":"VER"} ,
		{"word":"angoissées","word_nosc":"angoissees","lemma":"angoisser","pos":"VER"} ,
		{"word":"angoissés","word_nosc":"angoisses","lemma":"angoisser","pos":"VER"} ,
		{"word":"anhèle","word_nosc":"anhele","lemma":"anhéler","pos":"VER"} ,
		{"word":"anima","word_nosc":"anima","lemma":"animer","pos":"VER"} ,
		{"word":"animai","word_nosc":"animai","lemma":"animer","pos":"VER"} ,
		{"word":"animaient","word_nosc":"animaient","lemma":"animer","pos":"VER"} ,
		{"word":"animais","word_nosc":"animais","lemma":"animer","pos":"VER"} ,
		{"word":"animait","word_nosc":"animait","lemma":"animer","pos":"VER"} ,
		{"word":"animalisait","word_nosc":"animalisait","lemma":"animaliser","pos":"VER"} ,
		{"word":"animalise","word_nosc":"animalise","lemma":"animaliser","pos":"VER"} ,
		{"word":"animant","word_nosc":"animant","lemma":"animer","pos":"VER"} ,
		{"word":"animas","word_nosc":"animas","lemma":"animer","pos":"VER"} ,
		{"word":"anime","word_nosc":"anime","lemma":"animer","pos":"VER"} ,
		{"word":"animent","word_nosc":"animent","lemma":"animer","pos":"VER"} ,
		{"word":"animer","word_nosc":"animer","lemma":"animer","pos":"VER"} ,
		{"word":"animeraient","word_nosc":"animeraient","lemma":"animer","pos":"VER"} ,
		{"word":"animerait","word_nosc":"animerait","lemma":"animer","pos":"VER"} ,
		{"word":"animes","word_nosc":"animes","lemma":"animer","pos":"VER"} ,
		{"word":"animât","word_nosc":"animat","lemma":"animer","pos":"VER"} ,
		{"word":"animèrent","word_nosc":"animerent","lemma":"animer","pos":"VER"} ,
		{"word":"animé","word_nosc":"anime","lemma":"animer","pos":"VER"} ,
		{"word":"animée","word_nosc":"animee","lemma":"animer","pos":"VER"} ,
		{"word":"animées","word_nosc":"animees","lemma":"animer","pos":"VER"} ,
		{"word":"animés","word_nosc":"animes","lemma":"animer","pos":"VER"} ,
		{"word":"anise","word_nosc":"anise","lemma":"aniser","pos":"VER"} ,
		{"word":"anisé","word_nosc":"anise","lemma":"aniser","pos":"VER"} ,
		{"word":"anisée","word_nosc":"anisee","lemma":"aniser","pos":"VER"} ,
		{"word":"anisées","word_nosc":"anisees","lemma":"aniser","pos":"VER"} ,
		{"word":"ankylosait","word_nosc":"ankylosait","lemma":"ankyloser","pos":"VER"} ,
		{"word":"ankyloser","word_nosc":"ankyloser","lemma":"ankyloser","pos":"VER"} ,
		{"word":"ankylosé","word_nosc":"ankylose","lemma":"ankyloser","pos":"VER"} ,
		{"word":"ankylosée","word_nosc":"ankylosee","lemma":"ankyloser","pos":"VER"} ,
		{"word":"ankylosées","word_nosc":"ankylosees","lemma":"ankyloser","pos":"VER"} ,
		{"word":"ankylosés","word_nosc":"ankyloses","lemma":"ankyloser","pos":"VER"} ,
		{"word":"annelé","word_nosc":"annele","lemma":"anneler","pos":"VER"} ,
		{"word":"annelées","word_nosc":"annelees","lemma":"anneler","pos":"VER"} ,
		{"word":"annelés","word_nosc":"anneles","lemma":"anneler","pos":"VER"} ,
		{"word":"annexa","word_nosc":"annexa","lemma":"annexer","pos":"VER"} ,
		{"word":"annexaient","word_nosc":"annexaient","lemma":"annexer","pos":"VER"} ,
		{"word":"annexait","word_nosc":"annexait","lemma":"annexer","pos":"VER"} ,
		{"word":"annexant","word_nosc":"annexant","lemma":"annexer","pos":"VER"} ,
		{"word":"annexe","word_nosc":"annexe","lemma":"annexer","pos":"VER"} ,
		{"word":"annexer","word_nosc":"annexer","lemma":"annexer","pos":"VER"} ,
		{"word":"annexèrent","word_nosc":"annexerent","lemma":"annexer","pos":"VER"} ,
		{"word":"annexé","word_nosc":"annexe","lemma":"annexer","pos":"VER"} ,
		{"word":"annexée","word_nosc":"annexee","lemma":"annexer","pos":"VER"} ,
		{"word":"annexées","word_nosc":"annexees","lemma":"annexer","pos":"VER"} ,
		{"word":"annexés","word_nosc":"annexes","lemma":"annexer","pos":"VER"} ,
		{"word":"annihila","word_nosc":"annihila","lemma":"annihiler","pos":"VER"} ,
		{"word":"annihilait","word_nosc":"annihilait","lemma":"annihiler","pos":"VER"} ,
		{"word":"annihilant","word_nosc":"annihilant","lemma":"annihiler","pos":"VER"} ,
		{"word":"annihile","word_nosc":"annihile","lemma":"annihiler","pos":"VER"} ,
		{"word":"annihilent","word_nosc":"annihilent","lemma":"annihiler","pos":"VER"} ,
		{"word":"annihiler","word_nosc":"annihiler","lemma":"annihiler","pos":"VER"} ,
		{"word":"annihilez","word_nosc":"annihilez","lemma":"annihiler","pos":"VER"} ,
		{"word":"annihilons","word_nosc":"annihilons","lemma":"annihiler","pos":"VER"} ,
		{"word":"annihilé","word_nosc":"annihile","lemma":"annihiler","pos":"VER"} ,
		{"word":"annihilée","word_nosc":"annihilee","lemma":"annihiler","pos":"VER"} ,
		{"word":"annonce","word_nosc":"annonce","lemma":"annoncer","pos":"VER"} ,
		{"word":"annoncent","word_nosc":"annoncent","lemma":"annoncer","pos":"VER"} ,
		{"word":"annoncer","word_nosc":"annoncer","lemma":"annoncer","pos":"VER"} ,
		{"word":"annoncera","word_nosc":"annoncera","lemma":"annoncer","pos":"VER"} ,
		{"word":"annoncerai","word_nosc":"annoncerai","lemma":"annoncer","pos":"VER"} ,
		{"word":"annonceraient","word_nosc":"annonceraient","lemma":"annoncer","pos":"VER"} ,
		{"word":"annoncerais","word_nosc":"annoncerais","lemma":"annoncer","pos":"VER"} ,
		{"word":"annoncerait","word_nosc":"annoncerait","lemma":"annoncer","pos":"VER"} ,
		{"word":"annonceras","word_nosc":"annonceras","lemma":"annoncer","pos":"VER"} ,
		{"word":"annoncerez","word_nosc":"annoncerez","lemma":"annoncer","pos":"VER"} ,
		{"word":"annoncerons","word_nosc":"annoncerons","lemma":"annoncer","pos":"VER"} ,
		{"word":"annonceront","word_nosc":"annonceront","lemma":"annoncer","pos":"VER"} ,
		{"word":"annonces","word_nosc":"annonces","lemma":"annoncer","pos":"VER"} ,
		{"word":"annoncez","word_nosc":"annoncez","lemma":"annoncer","pos":"VER"} ,
		{"word":"annonciez","word_nosc":"annonciez","lemma":"annoncer","pos":"VER"} ,
		{"word":"annoncions","word_nosc":"annoncions","lemma":"annoncer","pos":"VER"} ,
		{"word":"annoncèrent","word_nosc":"annoncerent","lemma":"annoncer","pos":"VER"} ,
		{"word":"annoncé","word_nosc":"annonce","lemma":"annoncer","pos":"VER"} ,
		{"word":"annoncée","word_nosc":"annoncee","lemma":"annoncer","pos":"VER"} ,
		{"word":"annoncées","word_nosc":"annoncees","lemma":"annoncer","pos":"VER"} ,
		{"word":"annoncés","word_nosc":"annonces","lemma":"annoncer","pos":"VER"} ,
		{"word":"annonça","word_nosc":"annonca","lemma":"annoncer","pos":"VER"} ,
		{"word":"annonçai","word_nosc":"annoncai","lemma":"annoncer","pos":"VER"} ,
		{"word":"annonçaient","word_nosc":"annoncaient","lemma":"annoncer","pos":"VER"} ,
		{"word":"annonçais","word_nosc":"annoncais","lemma":"annoncer","pos":"VER"} ,
		{"word":"annonçait","word_nosc":"annoncait","lemma":"annoncer","pos":"VER"} ,
		{"word":"annonçant","word_nosc":"annoncant","lemma":"annoncer","pos":"VER"} ,
		{"word":"annonçons","word_nosc":"annoncons","lemma":"annoncer","pos":"VER"} ,
		{"word":"annonçât","word_nosc":"annoncat","lemma":"annoncer","pos":"VER"} ,
		{"word":"annotais","word_nosc":"annotais","lemma":"annoter","pos":"VER"} ,
		{"word":"annotait","word_nosc":"annotait","lemma":"annoter","pos":"VER"} ,
		{"word":"annotant","word_nosc":"annotant","lemma":"annoter","pos":"VER"} ,
		{"word":"annote","word_nosc":"annote","lemma":"annoter","pos":"VER"} ,
		{"word":"annoter","word_nosc":"annoter","lemma":"annoter","pos":"VER"} ,
		{"word":"annoté","word_nosc":"annote","lemma":"annoter","pos":"VER"} ,
		{"word":"annotée","word_nosc":"annotee","lemma":"annoter","pos":"VER"} ,
		{"word":"annotés","word_nosc":"annotes","lemma":"annoter","pos":"VER"} ,
		{"word":"annula","word_nosc":"annula","lemma":"annuler","pos":"VER"} ,
		{"word":"annulai","word_nosc":"annulai","lemma":"annuler","pos":"VER"} ,
		{"word":"annulaient","word_nosc":"annulaient","lemma":"annuler","pos":"VER"} ,
		{"word":"annulais","word_nosc":"annulais","lemma":"annuler","pos":"VER"} ,
		{"word":"annulait","word_nosc":"annulait","lemma":"annuler","pos":"VER"} ,
		{"word":"annulant","word_nosc":"annulant","lemma":"annuler","pos":"VER"} ,
		{"word":"annule","word_nosc":"annule","lemma":"annuler","pos":"VER"} ,
		{"word":"annulent","word_nosc":"annulent","lemma":"annuler","pos":"VER"} ,
		{"word":"annuler","word_nosc":"annuler","lemma":"annuler","pos":"VER"} ,
		{"word":"annulera","word_nosc":"annulera","lemma":"annuler","pos":"VER"} ,
		{"word":"annulerai","word_nosc":"annulerai","lemma":"annuler","pos":"VER"} ,
		{"word":"annuleraient","word_nosc":"annuleraient","lemma":"annuler","pos":"VER"} ,
		{"word":"annulerais","word_nosc":"annulerais","lemma":"annuler","pos":"VER"} ,
		{"word":"annulerait","word_nosc":"annulerait","lemma":"annuler","pos":"VER"} ,
		{"word":"annulerez","word_nosc":"annulerez","lemma":"annuler","pos":"VER"} ,
		{"word":"annulerons","word_nosc":"annulerons","lemma":"annuler","pos":"VER"} ,
		{"word":"annuleront","word_nosc":"annuleront","lemma":"annuler","pos":"VER"} ,
		{"word":"annules","word_nosc":"annules","lemma":"annuler","pos":"VER"} ,
		{"word":"annulez","word_nosc":"annulez","lemma":"annuler","pos":"VER"} ,
		{"word":"annuliez","word_nosc":"annuliez","lemma":"annuler","pos":"VER"} ,
		{"word":"annulons","word_nosc":"annulons","lemma":"annuler","pos":"VER"} ,
		{"word":"annulèrent","word_nosc":"annulerent","lemma":"annuler","pos":"VER"} ,
		{"word":"annulé","word_nosc":"annule","lemma":"annuler","pos":"VER"} ,
		{"word":"annulée","word_nosc":"annulee","lemma":"annuler","pos":"VER"} ,
		{"word":"annulées","word_nosc":"annulees","lemma":"annuler","pos":"VER"} ,
		{"word":"annulés","word_nosc":"annules","lemma":"annuler","pos":"VER"} ,
		{"word":"anobli","word_nosc":"anobli","lemma":"anoblir","pos":"VER"} ,
		{"word":"anoblie","word_nosc":"anoblie","lemma":"anoblir","pos":"VER"} ,
		{"word":"anoblir","word_nosc":"anoblir","lemma":"anoblir","pos":"VER"} ,
		{"word":"anoblissait","word_nosc":"anoblissait","lemma":"anoblir","pos":"VER"} ,
		{"word":"anoblissant","word_nosc":"anoblissant","lemma":"anoblir","pos":"VER"} ,
		{"word":"anoblit","word_nosc":"anoblit","lemma":"anoblir","pos":"VER"} ,
		{"word":"anodisé","word_nosc":"anodise","lemma":"anodiser","pos":"VER"} ,
		{"word":"anthropomorphiser","word_nosc":"anthropomorphiser","lemma":"anthropomorphiser","pos":"VER"} ,
		{"word":"anticipa","word_nosc":"anticipa","lemma":"anticiper","pos":"VER"} ,
		{"word":"anticipais","word_nosc":"anticipais","lemma":"anticiper","pos":"VER"} ,
		{"word":"anticipait","word_nosc":"anticipait","lemma":"anticiper","pos":"VER"} ,
		{"word":"anticipant","word_nosc":"anticipant","lemma":"anticiper","pos":"VER"} ,
		{"word":"anticipe","word_nosc":"anticipe","lemma":"anticiper","pos":"VER"} ,
		{"word":"anticipent","word_nosc":"anticipent","lemma":"anticiper","pos":"VER"} ,
		{"word":"anticiper","word_nosc":"anticiper","lemma":"anticiper","pos":"VER"} ,
		{"word":"anticipera","word_nosc":"anticipera","lemma":"anticiper","pos":"VER"} ,
		{"word":"anticiperait","word_nosc":"anticiperait","lemma":"anticiper","pos":"VER"} ,
		{"word":"anticipez","word_nosc":"anticipez","lemma":"anticiper","pos":"VER"} ,
		{"word":"anticipons","word_nosc":"anticipons","lemma":"anticiper","pos":"VER"} ,
		{"word":"anticipé","word_nosc":"anticipe","lemma":"anticiper","pos":"VER"} ,
		{"word":"anticipée","word_nosc":"anticipee","lemma":"anticiper","pos":"VER"} ,
		{"word":"anticipées","word_nosc":"anticipees","lemma":"anticiper","pos":"VER"} ,
		{"word":"anticipés","word_nosc":"anticipes","lemma":"anticiper","pos":"VER"} ,
		{"word":"antidatait","word_nosc":"antidatait","lemma":"antidater","pos":"VER"} ,
		{"word":"antidater","word_nosc":"antidater","lemma":"antidater","pos":"VER"} ,
		{"word":"antidaté","word_nosc":"antidate","lemma":"antidater","pos":"VER"} ,
		{"word":"antidatée","word_nosc":"antidatee","lemma":"antidater","pos":"VER"} ,
		{"word":"anté","word_nosc":"ante","lemma":"anter","pos":"VER"} ,
		{"word":"anuité","word_nosc":"anuite","lemma":"anuiter","pos":"VER"} ,
		{"word":"anuitées","word_nosc":"anuitees","lemma":"anuiter","pos":"VER"} ,
		{"word":"anéanti","word_nosc":"aneanti","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantie","word_nosc":"aneantie","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéanties","word_nosc":"aneanties","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantir","word_nosc":"aneantir","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantira","word_nosc":"aneantira","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantirai","word_nosc":"aneantirai","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantiraient","word_nosc":"aneantiraient","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantirait","word_nosc":"aneantirait","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantirent","word_nosc":"aneantirent","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantiriez","word_nosc":"aneantiriez","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantirons","word_nosc":"aneantirons","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantiront","word_nosc":"aneantiront","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantis","word_nosc":"aneantis","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantissaient","word_nosc":"aneantissaient","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantissait","word_nosc":"aneantissait","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantissant","word_nosc":"aneantissant","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantisse","word_nosc":"aneantisse","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantissent","word_nosc":"aneantissent","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantissez","word_nosc":"aneantissez","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantissons","word_nosc":"aneantissons","lemma":"anéantir","pos":"VER"} ,
		{"word":"anéantit","word_nosc":"aneantit","lemma":"anéantir","pos":"VER"} ,
		{"word":"anémiais","word_nosc":"anemiais","lemma":"anémier","pos":"VER"} ,
		{"word":"anémie","word_nosc":"anemie","lemma":"anémier","pos":"VER"} ,
		{"word":"anémié","word_nosc":"anemie","lemma":"anémier","pos":"VER"} ,
		{"word":"anémiée","word_nosc":"anemiee","lemma":"anémier","pos":"VER"} ,
		{"word":"apaisa","word_nosc":"apaisa","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisai","word_nosc":"apaisai","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisaient","word_nosc":"apaisaient","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisais","word_nosc":"apaisais","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisait","word_nosc":"apaisait","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisant","word_nosc":"apaisant","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaise","word_nosc":"apaise","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisent","word_nosc":"apaisent","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaiser","word_nosc":"apaiser","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisera","word_nosc":"apaisera","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaiseraient","word_nosc":"apaiseraient","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaiserais","word_nosc":"apaiserais","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaiserait","word_nosc":"apaiserait","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisez","word_nosc":"apaisez","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisiez","word_nosc":"apaisiez","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisons","word_nosc":"apaisons","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisât","word_nosc":"apaisat","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisèrent","word_nosc":"apaiserent","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisé","word_nosc":"apaise","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisée","word_nosc":"apaisee","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisées","word_nosc":"apaisees","lemma":"apaiser","pos":"VER"} ,
		{"word":"apaisés","word_nosc":"apaises","lemma":"apaiser","pos":"VER"} ,
		{"word":"apercevaient","word_nosc":"apercevaient","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevais","word_nosc":"apercevais","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevait","word_nosc":"apercevait","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevant","word_nosc":"apercevant","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevez","word_nosc":"apercevez","lemma":"apercevoir","pos":"VER"} ,
		{"word":"aperceviez","word_nosc":"aperceviez","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevions","word_nosc":"apercevions","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevoir","word_nosc":"apercevoir","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevons","word_nosc":"apercevons","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevra","word_nosc":"apercevra","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevrai","word_nosc":"apercevrai","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevraient","word_nosc":"apercevraient","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevrais","word_nosc":"apercevrais","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevrait","word_nosc":"apercevrait","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevras","word_nosc":"apercevras","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevrez","word_nosc":"apercevrez","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevrons","word_nosc":"apercevrons","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apercevront","word_nosc":"apercevront","lemma":"apercevoir","pos":"VER"} ,
		{"word":"aperçois","word_nosc":"apercois","lemma":"apercevoir","pos":"VER"} ,
		{"word":"aperçoit","word_nosc":"apercoit","lemma":"apercevoir","pos":"VER"} ,
		{"word":"aperçoive","word_nosc":"apercoive","lemma":"apercevoir","pos":"VER"} ,
		{"word":"aperçoivent","word_nosc":"apercoivent","lemma":"apercevoir","pos":"VER"} ,
		{"word":"aperçoives","word_nosc":"apercoives","lemma":"apercevoir","pos":"VER"} ,
		{"word":"aperçu","word_nosc":"apercu","lemma":"apercevoir","pos":"VER"} ,
		{"word":"aperçue","word_nosc":"apercue","lemma":"apercevoir","pos":"VER"} ,
		{"word":"aperçues","word_nosc":"apercues","lemma":"apercevoir","pos":"VER"} ,
		{"word":"aperçurent","word_nosc":"apercurent","lemma":"apercevoir","pos":"VER"} ,
		{"word":"aperçus","word_nosc":"apercus","lemma":"apercevoir","pos":"VER"} ,
		{"word":"aperçussent","word_nosc":"apercussent","lemma":"apercevoir","pos":"VER"} ,
		{"word":"aperçut","word_nosc":"apercut","lemma":"apercevoir","pos":"VER"} ,
		{"word":"aperçûmes","word_nosc":"apercumes","lemma":"apercevoir","pos":"VER"} ,
		{"word":"aperçût","word_nosc":"apercut","lemma":"apercevoir","pos":"VER"} ,
		{"word":"apeura","word_nosc":"apeura","lemma":"apeurer","pos":"VER"} ,
		{"word":"apeuraient","word_nosc":"apeuraient","lemma":"apeurer","pos":"VER"} ,
		{"word":"apeurait","word_nosc":"apeurait","lemma":"apeurer","pos":"VER"} ,
		{"word":"apeure","word_nosc":"apeure","lemma":"apeurer","pos":"VER"} ,
		{"word":"apeurer","word_nosc":"apeurer","lemma":"apeurer","pos":"VER"} ,
		{"word":"apeuré","word_nosc":"apeure","lemma":"apeurer","pos":"VER"} ,
		{"word":"apeurée","word_nosc":"apeuree","lemma":"apeurer","pos":"VER"} ,
		{"word":"apeurées","word_nosc":"apeurees","lemma":"apeurer","pos":"VER"} ,
		{"word":"apeurés","word_nosc":"apeures","lemma":"apeurer","pos":"VER"} ,
		{"word":"apitoie","word_nosc":"apitoie","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoient","word_nosc":"apitoient","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoierai","word_nosc":"apitoierai","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoierait","word_nosc":"apitoierait","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoies","word_nosc":"apitoies","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoya","word_nosc":"apitoya","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoyaient","word_nosc":"apitoyaient","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoyait","word_nosc":"apitoyait","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoyant","word_nosc":"apitoyant","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoyer","word_nosc":"apitoyer","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoyez","word_nosc":"apitoyez","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoyons","word_nosc":"apitoyons","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoyèrent","word_nosc":"apitoyerent","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoyé","word_nosc":"apitoye","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoyée","word_nosc":"apitoyee","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoyées","word_nosc":"apitoyees","lemma":"apitoyer","pos":"VER"} ,
		{"word":"apitoyés","word_nosc":"apitoyes","lemma":"apitoyer","pos":"VER"} ,
		{"word":"aplani","word_nosc":"aplani","lemma":"aplanir","pos":"VER"} ,
		{"word":"aplanie","word_nosc":"aplanie","lemma":"aplanir","pos":"VER"} ,
		{"word":"aplanies","word_nosc":"aplanies","lemma":"aplanir","pos":"VER"} ,
		{"word":"aplanir","word_nosc":"aplanir","lemma":"aplanir","pos":"VER"} ,
		{"word":"aplanira","word_nosc":"aplanira","lemma":"aplanir","pos":"VER"} ,
		{"word":"aplaniront","word_nosc":"aplaniront","lemma":"aplanir","pos":"VER"} ,
		{"word":"aplanis","word_nosc":"aplanis","lemma":"aplanir","pos":"VER"} ,
		{"word":"aplanissaient","word_nosc":"aplanissaient","lemma":"aplanir","pos":"VER"} ,
		{"word":"aplanissait","word_nosc":"aplanissait","lemma":"aplanir","pos":"VER"} ,
		{"word":"aplanissant","word_nosc":"aplanissant","lemma":"aplanir","pos":"VER"} ,
		{"word":"aplanissez","word_nosc":"aplanissez","lemma":"aplanir","pos":"VER"} ,
		{"word":"aplanissons","word_nosc":"aplanissons","lemma":"aplanir","pos":"VER"} ,
		{"word":"aplanit","word_nosc":"aplanit","lemma":"aplanir","pos":"VER"} ,
		{"word":"aplati","word_nosc":"aplati","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplatie","word_nosc":"aplatie","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplaties","word_nosc":"aplaties","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplatir","word_nosc":"aplatir","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplatirais","word_nosc":"aplatirais","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplatirait","word_nosc":"aplatirait","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplatirent","word_nosc":"aplatirent","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplatirez","word_nosc":"aplatirez","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplatis","word_nosc":"aplatis","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplatissaient","word_nosc":"aplatissaient","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplatissais","word_nosc":"aplatissais","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplatissait","word_nosc":"aplatissait","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplatissant","word_nosc":"aplatissant","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplatisse","word_nosc":"aplatisse","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplatissent","word_nosc":"aplatissent","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplatissez","word_nosc":"aplatissez","lemma":"aplatir","pos":"VER"} ,
		{"word":"aplatit","word_nosc":"aplatit","lemma":"aplatir","pos":"VER"} ,
		{"word":"apostasié","word_nosc":"apostasie","lemma":"apostasier","pos":"VER"} ,
		{"word":"apostillée","word_nosc":"apostillee","lemma":"apostiller","pos":"VER"} ,
		{"word":"apostropha","word_nosc":"apostropha","lemma":"apostropher","pos":"VER"} ,
		{"word":"apostrophaient","word_nosc":"apostrophaient","lemma":"apostropher","pos":"VER"} ,
		{"word":"apostrophait","word_nosc":"apostrophait","lemma":"apostropher","pos":"VER"} ,
		{"word":"apostrophant","word_nosc":"apostrophant","lemma":"apostropher","pos":"VER"} ,
		{"word":"apostrophe","word_nosc":"apostrophe","lemma":"apostropher","pos":"VER"} ,
		{"word":"apostrophent","word_nosc":"apostrophent","lemma":"apostropher","pos":"VER"} ,
		{"word":"apostropher","word_nosc":"apostropher","lemma":"apostropher","pos":"VER"} ,
		{"word":"apostrophes","word_nosc":"apostrophes","lemma":"apostropher","pos":"VER"} ,
		{"word":"apostrophèrent","word_nosc":"apostropherent","lemma":"apostropher","pos":"VER"} ,
		{"word":"apostrophé","word_nosc":"apostrophe","lemma":"apostropher","pos":"VER"} ,
		{"word":"apostrophée","word_nosc":"apostrophee","lemma":"apostropher","pos":"VER"} ,
		{"word":"apostées","word_nosc":"apostees","lemma":"aposter","pos":"VER"} ,
		{"word":"apparais","word_nosc":"apparais","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaissaient","word_nosc":"apparaissaient","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaissais","word_nosc":"apparaissais","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaissait","word_nosc":"apparaissait","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaissant","word_nosc":"apparaissant","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaisse","word_nosc":"apparaisse","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaissent","word_nosc":"apparaissent","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaissez","word_nosc":"apparaissez","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaissiez","word_nosc":"apparaissiez","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaissions","word_nosc":"apparaissions","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaissons","word_nosc":"apparaissons","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaît","word_nosc":"apparait","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaîtra","word_nosc":"apparaitra","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaîtrai","word_nosc":"apparaitrai","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaîtraient","word_nosc":"apparaitraient","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaîtrais","word_nosc":"apparaitrais","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaîtrait","word_nosc":"apparaitrait","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaîtras","word_nosc":"apparaitras","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaître","word_nosc":"apparaitre","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaîtrez","word_nosc":"apparaitrez","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaîtrions","word_nosc":"apparaitrions","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaîtrons","word_nosc":"apparaitrons","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparaîtront","word_nosc":"apparaitront","lemma":"apparaître","pos":"VER"} ,
		{"word":"appareilla","word_nosc":"appareilla","lemma":"appareiller","pos":"VER"} ,
		{"word":"appareillaient","word_nosc":"appareillaient","lemma":"appareiller","pos":"VER"} ,
		{"word":"appareillait","word_nosc":"appareillait","lemma":"appareiller","pos":"VER"} ,
		{"word":"appareillant","word_nosc":"appareillant","lemma":"appareiller","pos":"VER"} ,
		{"word":"appareille","word_nosc":"appareille","lemma":"appareiller","pos":"VER"} ,
		{"word":"appareillent","word_nosc":"appareillent","lemma":"appareiller","pos":"VER"} ,
		{"word":"appareiller","word_nosc":"appareiller","lemma":"appareiller","pos":"VER"} ,
		{"word":"appareilleraient","word_nosc":"appareilleraient","lemma":"appareiller","pos":"VER"} ,
		{"word":"appareillez","word_nosc":"appareillez","lemma":"appareiller","pos":"VER"} ,
		{"word":"appareillions","word_nosc":"appareillions","lemma":"appareiller","pos":"VER"} ,
		{"word":"appareillons","word_nosc":"appareillons","lemma":"appareiller","pos":"VER"} ,
		{"word":"appareillé","word_nosc":"appareille","lemma":"appareiller","pos":"VER"} ,
		{"word":"appareillés","word_nosc":"appareilles","lemma":"appareiller","pos":"VER"} ,
		{"word":"apparentaient","word_nosc":"apparentaient","lemma":"apparenter","pos":"VER"} ,
		{"word":"apparentait","word_nosc":"apparentait","lemma":"apparenter","pos":"VER"} ,
		{"word":"apparentant","word_nosc":"apparentant","lemma":"apparenter","pos":"VER"} ,
		{"word":"apparente","word_nosc":"apparente","lemma":"apparenter","pos":"VER"} ,
		{"word":"apparentent","word_nosc":"apparentent","lemma":"apparenter","pos":"VER"} ,
		{"word":"apparenter","word_nosc":"apparenter","lemma":"apparenter","pos":"VER"} ,
		{"word":"apparenté","word_nosc":"apparente","lemma":"apparenter","pos":"VER"} ,
		{"word":"apparentée","word_nosc":"apparentee","lemma":"apparenter","pos":"VER"} ,
		{"word":"apparentés","word_nosc":"apparentes","lemma":"apparenter","pos":"VER"} ,
		{"word":"appariait","word_nosc":"appariait","lemma":"apparier","pos":"VER"} ,
		{"word":"appariant","word_nosc":"appariant","lemma":"apparier","pos":"VER"} ,
		{"word":"apparie","word_nosc":"apparie","lemma":"apparier","pos":"VER"} ,
		{"word":"apparier","word_nosc":"apparier","lemma":"apparier","pos":"VER"} ,
		{"word":"apparié","word_nosc":"apparie","lemma":"apparier","pos":"VER"} ,
		{"word":"appariée","word_nosc":"appariee","lemma":"apparier","pos":"VER"} ,
		{"word":"appariées","word_nosc":"appariees","lemma":"apparier","pos":"VER"} ,
		{"word":"appariés","word_nosc":"apparies","lemma":"apparier","pos":"VER"} ,
		{"word":"appartenaient","word_nosc":"appartenaient","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartenais","word_nosc":"appartenais","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartenait","word_nosc":"appartenait","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartenant","word_nosc":"appartenant","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartenez","word_nosc":"appartenez","lemma":"appartenir","pos":"VER"} ,
		{"word":"apparteniez","word_nosc":"apparteniez","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartenions","word_nosc":"appartenions","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartenir","word_nosc":"appartenir","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartenons","word_nosc":"appartenons","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartenu","word_nosc":"appartenu","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartenues","word_nosc":"appartenues","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartiendra","word_nosc":"appartiendra","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartiendrai","word_nosc":"appartiendrai","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartiendraient","word_nosc":"appartiendraient","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartiendrais","word_nosc":"appartiendrais","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartiendrait","word_nosc":"appartiendrait","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartiendras","word_nosc":"appartiendras","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartiendrons","word_nosc":"appartiendrons","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartiendront","word_nosc":"appartiendront","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartienne","word_nosc":"appartienne","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartiennent","word_nosc":"appartiennent","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartiens","word_nosc":"appartiens","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartient","word_nosc":"appartient","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartins","word_nosc":"appartins","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartinssent","word_nosc":"appartinssent","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartint","word_nosc":"appartint","lemma":"appartenir","pos":"VER"} ,
		{"word":"appartînt","word_nosc":"appartint","lemma":"appartenir","pos":"VER"} ,
		{"word":"apparu","word_nosc":"apparu","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparue","word_nosc":"apparue","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparues","word_nosc":"apparues","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparurent","word_nosc":"apparurent","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparus","word_nosc":"apparus","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparussent","word_nosc":"apparussent","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparut","word_nosc":"apparut","lemma":"apparaître","pos":"VER"} ,
		{"word":"apparût","word_nosc":"apparut","lemma":"apparaître","pos":"VER"} ,
		{"word":"appauvri","word_nosc":"appauvri","lemma":"appauvrir","pos":"VER"} ,
		{"word":"appauvrie","word_nosc":"appauvrie","lemma":"appauvrir","pos":"VER"} ,
		{"word":"appauvries","word_nosc":"appauvries","lemma":"appauvrir","pos":"VER"} ,
		{"word":"appauvrir","word_nosc":"appauvrir","lemma":"appauvrir","pos":"VER"} ,
		{"word":"appauvris","word_nosc":"appauvris","lemma":"appauvrir","pos":"VER"} ,
		{"word":"appauvrissaient","word_nosc":"appauvrissaient","lemma":"appauvrir","pos":"VER"} ,
		{"word":"appauvrissait","word_nosc":"appauvrissait","lemma":"appauvrir","pos":"VER"} ,
		{"word":"appauvrissez","word_nosc":"appauvrissez","lemma":"appauvrir","pos":"VER"} ,
		{"word":"appauvrissions","word_nosc":"appauvrissions","lemma":"appauvrir","pos":"VER"} ,
		{"word":"appauvrissons","word_nosc":"appauvrissons","lemma":"appauvrir","pos":"VER"} ,
		{"word":"appauvrit","word_nosc":"appauvrit","lemma":"appauvrir","pos":"VER"} ,
		{"word":"appela","word_nosc":"appela","lemma":"appeler","pos":"VER"} ,
		{"word":"appelai","word_nosc":"appelai","lemma":"appeler","pos":"VER"} ,
		{"word":"appelaient","word_nosc":"appelaient","lemma":"appeler","pos":"VER"} ,
		{"word":"appelais","word_nosc":"appelais","lemma":"appeler","pos":"VER"} ,
		{"word":"appelait","word_nosc":"appelait","lemma":"appeler","pos":"VER"} ,
		{"word":"appelant","word_nosc":"appelant","lemma":"appeler","pos":"VER"} ,
		{"word":"appeler","word_nosc":"appeler","lemma":"appeler","pos":"VER"} ,
		{"word":"appelez","word_nosc":"appelez","lemma":"appeler","pos":"VER"} ,
		{"word":"appeliez","word_nosc":"appeliez","lemma":"appeler","pos":"VER"} ,
		{"word":"appelions","word_nosc":"appelions","lemma":"appeler","pos":"VER"} ,
		{"word":"appelle","word_nosc":"appelle","lemma":"appeler","pos":"VER"} ,
		{"word":"appellent","word_nosc":"appellent","lemma":"appeler","pos":"VER"} ,
		{"word":"appellera","word_nosc":"appellera","lemma":"appeler","pos":"VER"} ,
		{"word":"appellerai","word_nosc":"appellerai","lemma":"appeler","pos":"VER"} ,
		{"word":"appelleraient","word_nosc":"appelleraient","lemma":"appeler","pos":"VER"} ,
		{"word":"appellerais","word_nosc":"appellerais","lemma":"appeler","pos":"VER"} ,
		{"word":"appellerait","word_nosc":"appellerait","lemma":"appeler","pos":"VER"} ,
		{"word":"appelleras","word_nosc":"appelleras","lemma":"appeler","pos":"VER"} ,
		{"word":"appellerez","word_nosc":"appellerez","lemma":"appeler","pos":"VER"} ,
		{"word":"appelleriez","word_nosc":"appelleriez","lemma":"appeler","pos":"VER"} ,
		{"word":"appellerions","word_nosc":"appellerions","lemma":"appeler","pos":"VER"} ,
		{"word":"appellerons","word_nosc":"appellerons","lemma":"appeler","pos":"VER"} ,
		{"word":"appelleront","word_nosc":"appelleront","lemma":"appeler","pos":"VER"} ,
		{"word":"appelles","word_nosc":"appelles","lemma":"appeler","pos":"VER"} ,
		{"word":"appelons","word_nosc":"appelons","lemma":"appeler","pos":"VER"} ,
		{"word":"appelât","word_nosc":"appelat","lemma":"appeler","pos":"VER"} ,
		{"word":"appelèrent","word_nosc":"appelerent","lemma":"appeler","pos":"VER"} ,
		{"word":"appelé","word_nosc":"appele","lemma":"appeler","pos":"VER"} ,
		{"word":"appelée","word_nosc":"appelee","lemma":"appeler","pos":"VER"} ,
		{"word":"appelées","word_nosc":"appelees","lemma":"appeler","pos":"VER"} ,
		{"word":"appelés","word_nosc":"appeles","lemma":"appeler","pos":"VER"} ,
		{"word":"appendait","word_nosc":"appendait","lemma":"appendre","pos":"VER"} ,
		{"word":"appendrait","word_nosc":"appendrait","lemma":"appendre","pos":"VER"} ,
		{"word":"appendre","word_nosc":"appendre","lemma":"appendre","pos":"VER"} ,
		{"word":"appends","word_nosc":"appends","lemma":"appendre","pos":"VER"} ,
		{"word":"appendu","word_nosc":"appendu","lemma":"appendre","pos":"VER"} ,
		{"word":"appendues","word_nosc":"appendues","lemma":"appendre","pos":"VER"} ,
		{"word":"appendus","word_nosc":"appendus","lemma":"appendre","pos":"VER"} ,
		{"word":"appert","word_nosc":"appert","lemma":"appert","pos":"VER"} ,
		{"word":"appesanti","word_nosc":"appesanti","lemma":"appesantir","pos":"VER"} ,
		{"word":"appesantie","word_nosc":"appesantie","lemma":"appesantir","pos":"VER"} ,
		{"word":"appesanties","word_nosc":"appesanties","lemma":"appesantir","pos":"VER"} ,
		{"word":"appesantir","word_nosc":"appesantir","lemma":"appesantir","pos":"VER"} ,
		{"word":"appesantis","word_nosc":"appesantis","lemma":"appesantir","pos":"VER"} ,
		{"word":"appesantissais","word_nosc":"appesantissais","lemma":"appesantir","pos":"VER"} ,
		{"word":"appesantissait","word_nosc":"appesantissait","lemma":"appesantir","pos":"VER"} ,
		{"word":"appesantissant","word_nosc":"appesantissant","lemma":"appesantir","pos":"VER"} ,
		{"word":"appesantit","word_nosc":"appesantit","lemma":"appesantir","pos":"VER"} ,
		{"word":"applaudi","word_nosc":"applaudi","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudie","word_nosc":"applaudie","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudies","word_nosc":"applaudies","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudir","word_nosc":"applaudir","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudira","word_nosc":"applaudira","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudirai","word_nosc":"applaudirai","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudiraient","word_nosc":"applaudiraient","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudirais","word_nosc":"applaudirais","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudirait","word_nosc":"applaudirait","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudirent","word_nosc":"applaudirent","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudirez","word_nosc":"applaudirez","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudirons","word_nosc":"applaudirons","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudis","word_nosc":"applaudis","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudissaient","word_nosc":"applaudissaient","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudissais","word_nosc":"applaudissais","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudissait","word_nosc":"applaudissait","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudissant","word_nosc":"applaudissant","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudisse","word_nosc":"applaudisse","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudissent","word_nosc":"applaudissent","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudissez","word_nosc":"applaudissez","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudissiez","word_nosc":"applaudissiez","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudissions","word_nosc":"applaudissions","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudissons","word_nosc":"applaudissons","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudit","word_nosc":"applaudit","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudîmes","word_nosc":"applaudimes","lemma":"applaudir","pos":"VER"} ,
		{"word":"applaudît","word_nosc":"applaudit","lemma":"applaudir","pos":"VER"} ,
		{"word":"appliqua","word_nosc":"appliqua","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquai","word_nosc":"appliquai","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquaient","word_nosc":"appliquaient","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquais","word_nosc":"appliquais","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquait","word_nosc":"appliquait","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquant","word_nosc":"appliquant","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquasse","word_nosc":"appliquasse","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquassions","word_nosc":"appliquassions","lemma":"appliquer","pos":"VER"} ,
		{"word":"applique","word_nosc":"applique","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquent","word_nosc":"appliquent","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquer","word_nosc":"appliquer","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquera","word_nosc":"appliquera","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquerai","word_nosc":"appliquerai","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquerais","word_nosc":"appliquerais","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquerait","word_nosc":"appliquerait","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliqueras","word_nosc":"appliqueras","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquerez","word_nosc":"appliquerez","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquerons","word_nosc":"appliquerons","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliques","word_nosc":"appliques","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquez","word_nosc":"appliquez","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquions","word_nosc":"appliquions","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquons","word_nosc":"appliquons","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquât","word_nosc":"appliquat","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquèrent","word_nosc":"appliquerent","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliqué","word_nosc":"applique","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquée","word_nosc":"appliquee","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliquées","word_nosc":"appliquees","lemma":"appliquer","pos":"VER"} ,
		{"word":"appliqués","word_nosc":"appliques","lemma":"appliquer","pos":"VER"} ,
		{"word":"appointaient","word_nosc":"appointaient","lemma":"appointer","pos":"VER"} ,
		{"word":"appointait","word_nosc":"appointait","lemma":"appointer","pos":"VER"} ,
		{"word":"appointe","word_nosc":"appointe","lemma":"appointer","pos":"VER"} ,
		{"word":"appointer","word_nosc":"appointer","lemma":"appointer","pos":"VER"} ,
		{"word":"appointé","word_nosc":"appointe","lemma":"appointer","pos":"VER"} ,
		{"word":"appointée","word_nosc":"appointee","lemma":"appointer","pos":"VER"} ,
		{"word":"appointés","word_nosc":"appointes","lemma":"appointer","pos":"VER"} ,
		{"word":"apponter","word_nosc":"apponter","lemma":"apponter","pos":"VER"} ,
		{"word":"apporta","word_nosc":"apporta","lemma":"apporter","pos":"VER"} ,
		{"word":"apportai","word_nosc":"apportai","lemma":"apporter","pos":"VER"} ,
		{"word":"apportaient","word_nosc":"apportaient","lemma":"apporter","pos":"VER"} ,
		{"word":"apportais","word_nosc":"apportais","lemma":"apporter","pos":"VER"} ,
		{"word":"apportait","word_nosc":"apportait","lemma":"apporter","pos":"VER"} ,
		{"word":"apportant","word_nosc":"apportant","lemma":"apporter","pos":"VER"} ,
		{"word":"apportas","word_nosc":"apportas","lemma":"apporter","pos":"VER"} ,
		{"word":"apporte","word_nosc":"apporte","lemma":"apporter","pos":"VER"} ,
		{"word":"apportent","word_nosc":"apportent","lemma":"apporter","pos":"VER"} ,
		{"word":"apporter","word_nosc":"apporter","lemma":"apporter","pos":"VER"} ,
		{"word":"apportera","word_nosc":"apportera","lemma":"apporter","pos":"VER"} ,
		{"word":"apporterai","word_nosc":"apporterai","lemma":"apporter","pos":"VER"} ,
		{"word":"apporteraient","word_nosc":"apporteraient","lemma":"apporter","pos":"VER"} ,
		{"word":"apporterais","word_nosc":"apporterais","lemma":"apporter","pos":"VER"} ,
		{"word":"apporterait","word_nosc":"apporterait","lemma":"apporter","pos":"VER"} ,
		{"word":"apporteras","word_nosc":"apporteras","lemma":"apporter","pos":"VER"} ,
		{"word":"apporterez","word_nosc":"apporterez","lemma":"apporter","pos":"VER"} ,
		{"word":"apporteriez","word_nosc":"apporteriez","lemma":"apporter","pos":"VER"} ,
		{"word":"apporterons","word_nosc":"apporterons","lemma":"apporter","pos":"VER"} ,
		{"word":"apporteront","word_nosc":"apporteront","lemma":"apporter","pos":"VER"} ,
		{"word":"apportes","word_nosc":"apportes","lemma":"apporter","pos":"VER"} ,
		{"word":"apportez","word_nosc":"apportez","lemma":"apporter","pos":"VER"} ,
		{"word":"apportiez","word_nosc":"apportiez","lemma":"apporter","pos":"VER"} ,
		{"word":"apportions","word_nosc":"apportions","lemma":"apporter","pos":"VER"} ,
		{"word":"apportons","word_nosc":"apportons","lemma":"apporter","pos":"VER"} ,
		{"word":"apportât","word_nosc":"apportat","lemma":"apporter","pos":"VER"} ,
		{"word":"apportèrent","word_nosc":"apporterent","lemma":"apporter","pos":"VER"} ,
		{"word":"apporté","word_nosc":"apporte","lemma":"apporter","pos":"VER"} ,
		{"word":"apportée","word_nosc":"apportee","lemma":"apporter","pos":"VER"} ,
		{"word":"apportées","word_nosc":"apportees","lemma":"apporter","pos":"VER"} ,
		{"word":"apportés","word_nosc":"apportes","lemma":"apporter","pos":"VER"} ,
		{"word":"apposa","word_nosc":"apposa","lemma":"apposer","pos":"VER"} ,
		{"word":"apposai","word_nosc":"apposai","lemma":"apposer","pos":"VER"} ,
		{"word":"apposait","word_nosc":"apposait","lemma":"apposer","pos":"VER"} ,
		{"word":"apposant","word_nosc":"apposant","lemma":"apposer","pos":"VER"} ,
		{"word":"appose","word_nosc":"appose","lemma":"apposer","pos":"VER"} ,
		{"word":"apposent","word_nosc":"apposent","lemma":"apposer","pos":"VER"} ,
		{"word":"apposer","word_nosc":"apposer","lemma":"apposer","pos":"VER"} ,
		{"word":"apposerait","word_nosc":"apposerait","lemma":"apposer","pos":"VER"} ,
		{"word":"apposeras","word_nosc":"apposeras","lemma":"apposer","pos":"VER"} ,
		{"word":"apposez","word_nosc":"apposez","lemma":"apposer","pos":"VER"} ,
		{"word":"apposé","word_nosc":"appose","lemma":"apposer","pos":"VER"} ,
		{"word":"apposée","word_nosc":"apposee","lemma":"apposer","pos":"VER"} ,
		{"word":"apposées","word_nosc":"apposees","lemma":"apposer","pos":"VER"} ,
		{"word":"apposés","word_nosc":"apposes","lemma":"apposer","pos":"VER"} ,
		{"word":"apprenaient","word_nosc":"apprenaient","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprenais","word_nosc":"apprenais","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprenait","word_nosc":"apprenait","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprenant","word_nosc":"apprenant","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprend","word_nosc":"apprend","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprendra","word_nosc":"apprendra","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprendrai","word_nosc":"apprendrai","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprendraient","word_nosc":"apprendraient","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprendrais","word_nosc":"apprendrais","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprendrait","word_nosc":"apprendrait","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprendras","word_nosc":"apprendras","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprendre","word_nosc":"apprendre","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprendrez","word_nosc":"apprendrez","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprendriez","word_nosc":"apprendriez","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprendrions","word_nosc":"apprendrions","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprendrons","word_nosc":"apprendrons","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprendront","word_nosc":"apprendront","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprends","word_nosc":"apprends","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprenez","word_nosc":"apprenez","lemma":"apprendre","pos":"VER"} ,
		{"word":"appreniez","word_nosc":"appreniez","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprenions","word_nosc":"apprenions","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprenne","word_nosc":"apprenne","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprennent","word_nosc":"apprennent","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprennes","word_nosc":"apprennes","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprenons","word_nosc":"apprenons","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprirent","word_nosc":"apprirent","lemma":"apprendre","pos":"VER"} ,
		{"word":"appris","word_nosc":"appris","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprise","word_nosc":"apprise","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprises","word_nosc":"apprises","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprissent","word_nosc":"apprissent","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprit","word_nosc":"apprit","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprivoisa","word_nosc":"apprivoisa","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"apprivoisaient","word_nosc":"apprivoisaient","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"apprivoisais","word_nosc":"apprivoisais","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"apprivoisait","word_nosc":"apprivoisait","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"apprivoisant","word_nosc":"apprivoisant","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"apprivoise","word_nosc":"apprivoise","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"apprivoisent","word_nosc":"apprivoisent","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"apprivoiser","word_nosc":"apprivoiser","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"apprivoiserai","word_nosc":"apprivoiserai","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"apprivoisez","word_nosc":"apprivoisez","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"apprivoisons","word_nosc":"apprivoisons","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"apprivoisèrent","word_nosc":"apprivoiserent","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"apprivoisé","word_nosc":"apprivoise","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"apprivoisée","word_nosc":"apprivoisee","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"apprivoisées","word_nosc":"apprivoisees","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"apprivoisés","word_nosc":"apprivoises","lemma":"apprivoiser","pos":"VER"} ,
		{"word":"approcha","word_nosc":"approcha","lemma":"approcher","pos":"VER"} ,
		{"word":"approchai","word_nosc":"approchai","lemma":"approcher","pos":"VER"} ,
		{"word":"approchaient","word_nosc":"approchaient","lemma":"approcher","pos":"VER"} ,
		{"word":"approchais","word_nosc":"approchais","lemma":"approcher","pos":"VER"} ,
		{"word":"approchait","word_nosc":"approchait","lemma":"approcher","pos":"VER"} ,
		{"word":"approchant","word_nosc":"approchant","lemma":"approcher","pos":"VER"} ,
		{"word":"approchassent","word_nosc":"approchassent","lemma":"approcher","pos":"VER"} ,
		{"word":"approche","word_nosc":"approche","lemma":"approcher","pos":"VER"} ,
		{"word":"approchent","word_nosc":"approchent","lemma":"approcher","pos":"VER"} ,
		{"word":"approcher","word_nosc":"approcher","lemma":"approcher","pos":"VER"} ,
		{"word":"approchera","word_nosc":"approchera","lemma":"approcher","pos":"VER"} ,
		{"word":"approcherai","word_nosc":"approcherai","lemma":"approcher","pos":"VER"} ,
		{"word":"approcherais","word_nosc":"approcherais","lemma":"approcher","pos":"VER"} ,
		{"word":"approcherait","word_nosc":"approcherait","lemma":"approcher","pos":"VER"} ,
		{"word":"approcheras","word_nosc":"approcheras","lemma":"approcher","pos":"VER"} ,
		{"word":"approcherez","word_nosc":"approcherez","lemma":"approcher","pos":"VER"} ,
		{"word":"approcherions","word_nosc":"approcherions","lemma":"approcher","pos":"VER"} ,
		{"word":"approcherons","word_nosc":"approcherons","lemma":"approcher","pos":"VER"} ,
		{"word":"approcheront","word_nosc":"approcheront","lemma":"approcher","pos":"VER"} ,
		{"word":"approches","word_nosc":"approches","lemma":"approcher","pos":"VER"} ,
		{"word":"approchez","word_nosc":"approchez","lemma":"approcher","pos":"VER"} ,
		{"word":"approchiez","word_nosc":"approchiez","lemma":"approcher","pos":"VER"} ,
		{"word":"approchions","word_nosc":"approchions","lemma":"approcher","pos":"VER"} ,
		{"word":"approchons","word_nosc":"approchons","lemma":"approcher","pos":"VER"} ,
		{"word":"approchâmes","word_nosc":"approchames","lemma":"approcher","pos":"VER"} ,
		{"word":"approchât","word_nosc":"approchat","lemma":"approcher","pos":"VER"} ,
		{"word":"approchèrent","word_nosc":"approcherent","lemma":"approcher","pos":"VER"} ,
		{"word":"approché","word_nosc":"approche","lemma":"approcher","pos":"VER"} ,
		{"word":"approchée","word_nosc":"approchee","lemma":"approcher","pos":"VER"} ,
		{"word":"approchées","word_nosc":"approchees","lemma":"approcher","pos":"VER"} ,
		{"word":"approchés","word_nosc":"approches","lemma":"approcher","pos":"VER"} ,
		{"word":"approfondi","word_nosc":"approfondi","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondie","word_nosc":"approfondie","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondies","word_nosc":"approfondies","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondir","word_nosc":"approfondir","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondirai","word_nosc":"approfondirai","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondirent","word_nosc":"approfondirent","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondirions","word_nosc":"approfondirions","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondirons","word_nosc":"approfondirons","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondis","word_nosc":"approfondis","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondissaient","word_nosc":"approfondissaient","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondissais","word_nosc":"approfondissais","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondissait","word_nosc":"approfondissait","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondissant","word_nosc":"approfondissant","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondisse","word_nosc":"approfondisse","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondissent","word_nosc":"approfondissent","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondissons","word_nosc":"approfondissons","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondit","word_nosc":"approfondit","lemma":"approfondir","pos":"VER"} ,
		{"word":"approfondît","word_nosc":"approfondit","lemma":"approfondir","pos":"VER"} ,
		{"word":"appropriaient","word_nosc":"appropriaient","lemma":"approprier","pos":"VER"} ,
		{"word":"appropriait","word_nosc":"appropriait","lemma":"approprier","pos":"VER"} ,
		{"word":"appropriant","word_nosc":"appropriant","lemma":"approprier","pos":"VER"} ,
		{"word":"approprie","word_nosc":"approprie","lemma":"approprier","pos":"VER"} ,
		{"word":"approprient","word_nosc":"approprient","lemma":"approprier","pos":"VER"} ,
		{"word":"approprier","word_nosc":"approprier","lemma":"approprier","pos":"VER"} ,
		{"word":"approprieraient","word_nosc":"approprieraient","lemma":"approprier","pos":"VER"} ,
		{"word":"approprierais","word_nosc":"approprierais","lemma":"approprier","pos":"VER"} ,
		{"word":"approprierait","word_nosc":"approprierait","lemma":"approprier","pos":"VER"} ,
		{"word":"approprieront","word_nosc":"approprieront","lemma":"approprier","pos":"VER"} ,
		{"word":"appropriez","word_nosc":"appropriez","lemma":"approprier","pos":"VER"} ,
		{"word":"approprié","word_nosc":"approprie","lemma":"approprier","pos":"VER"} ,
		{"word":"appropriée","word_nosc":"appropriee","lemma":"approprier","pos":"VER"} ,
		{"word":"appropriées","word_nosc":"appropriees","lemma":"approprier","pos":"VER"} ,
		{"word":"appropriés","word_nosc":"appropries","lemma":"approprier","pos":"VER"} ,
		{"word":"approuva","word_nosc":"approuva","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvai","word_nosc":"approuvai","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvaient","word_nosc":"approuvaient","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvais","word_nosc":"approuvais","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvait","word_nosc":"approuvait","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvant","word_nosc":"approuvant","lemma":"approuver","pos":"VER"} ,
		{"word":"approuve","word_nosc":"approuve","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvent","word_nosc":"approuvent","lemma":"approuver","pos":"VER"} ,
		{"word":"approuver","word_nosc":"approuver","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvera","word_nosc":"approuvera","lemma":"approuver","pos":"VER"} ,
		{"word":"approuverai","word_nosc":"approuverai","lemma":"approuver","pos":"VER"} ,
		{"word":"approuverais","word_nosc":"approuverais","lemma":"approuver","pos":"VER"} ,
		{"word":"approuverait","word_nosc":"approuverait","lemma":"approuver","pos":"VER"} ,
		{"word":"approuveras","word_nosc":"approuveras","lemma":"approuver","pos":"VER"} ,
		{"word":"approuverons","word_nosc":"approuverons","lemma":"approuver","pos":"VER"} ,
		{"word":"approuveront","word_nosc":"approuveront","lemma":"approuver","pos":"VER"} ,
		{"word":"approuves","word_nosc":"approuves","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvez","word_nosc":"approuvez","lemma":"approuver","pos":"VER"} ,
		{"word":"approuviez","word_nosc":"approuviez","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvions","word_nosc":"approuvions","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvons","word_nosc":"approuvons","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvâmes","word_nosc":"approuvames","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvât","word_nosc":"approuvat","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvèrent","word_nosc":"approuverent","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvé","word_nosc":"approuve","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvée","word_nosc":"approuvee","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvées","word_nosc":"approuvees","lemma":"approuver","pos":"VER"} ,
		{"word":"approuvés","word_nosc":"approuves","lemma":"approuver","pos":"VER"} ,
		{"word":"approvisionnait","word_nosc":"approvisionnait","lemma":"approvisionner","pos":"VER"} ,
		{"word":"approvisionnant","word_nosc":"approvisionnant","lemma":"approvisionner","pos":"VER"} ,
		{"word":"approvisionne","word_nosc":"approvisionne","lemma":"approvisionner","pos":"VER"} ,
		{"word":"approvisionnent","word_nosc":"approvisionnent","lemma":"approvisionner","pos":"VER"} ,
		{"word":"approvisionner","word_nosc":"approvisionner","lemma":"approvisionner","pos":"VER"} ,
		{"word":"approvisionnera","word_nosc":"approvisionnera","lemma":"approvisionner","pos":"VER"} ,
		{"word":"approvisionniez","word_nosc":"approvisionniez","lemma":"approvisionner","pos":"VER"} ,
		{"word":"approvisionnons","word_nosc":"approvisionnons","lemma":"approvisionner","pos":"VER"} ,
		{"word":"approvisionné","word_nosc":"approvisionne","lemma":"approvisionner","pos":"VER"} ,
		{"word":"approvisionnée","word_nosc":"approvisionnee","lemma":"approvisionner","pos":"VER"} ,
		{"word":"approvisionnées","word_nosc":"approvisionnees","lemma":"approvisionner","pos":"VER"} ,
		{"word":"approvisionnés","word_nosc":"approvisionnes","lemma":"approvisionner","pos":"VER"} ,
		{"word":"apprécia","word_nosc":"apprecia","lemma":"apprécier","pos":"VER"} ,
		{"word":"appréciai","word_nosc":"appreciai","lemma":"apprécier","pos":"VER"} ,
		{"word":"appréciaient","word_nosc":"appreciaient","lemma":"apprécier","pos":"VER"} ,
		{"word":"appréciais","word_nosc":"appreciais","lemma":"apprécier","pos":"VER"} ,
		{"word":"appréciait","word_nosc":"appreciait","lemma":"apprécier","pos":"VER"} ,
		{"word":"appréciant","word_nosc":"appreciant","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécie","word_nosc":"apprecie","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécient","word_nosc":"apprecient","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécier","word_nosc":"apprecier","lemma":"apprécier","pos":"VER"} ,
		{"word":"appréciera","word_nosc":"appreciera","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécierai","word_nosc":"apprecierai","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécieraient","word_nosc":"apprecieraient","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécierais","word_nosc":"apprecierais","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécierait","word_nosc":"apprecierait","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécieras","word_nosc":"apprecieras","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécierez","word_nosc":"apprecierez","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécieriez","word_nosc":"apprecieriez","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécierions","word_nosc":"apprecierions","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécierons","word_nosc":"apprecierons","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécieront","word_nosc":"apprecieront","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécies","word_nosc":"apprecies","lemma":"apprécier","pos":"VER"} ,
		{"word":"appréciez","word_nosc":"appreciez","lemma":"apprécier","pos":"VER"} ,
		{"word":"appréciions","word_nosc":"appreciions","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécions","word_nosc":"apprecions","lemma":"apprécier","pos":"VER"} ,
		{"word":"appréciât","word_nosc":"appreciat","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécièrent","word_nosc":"apprecierent","lemma":"apprécier","pos":"VER"} ,
		{"word":"apprécié","word_nosc":"apprecie","lemma":"apprécier","pos":"VER"} ,
		{"word":"appréciée","word_nosc":"appreciee","lemma":"apprécier","pos":"VER"} ,
		{"word":"appréciées","word_nosc":"appreciees","lemma":"apprécier","pos":"VER"} ,
		{"word":"appréciés","word_nosc":"apprecies","lemma":"apprécier","pos":"VER"} ,
		{"word":"appréhendai","word_nosc":"apprehendai","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhendaient","word_nosc":"apprehendaient","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhendais","word_nosc":"apprehendais","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhendait","word_nosc":"apprehendait","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhendant","word_nosc":"apprehendant","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhende","word_nosc":"apprehende","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhendent","word_nosc":"apprehendent","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhender","word_nosc":"apprehender","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhendera","word_nosc":"apprehendera","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhenderons","word_nosc":"apprehenderons","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhendez","word_nosc":"apprehendez","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhendions","word_nosc":"apprehendions","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhendons","word_nosc":"apprehendons","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhendé","word_nosc":"apprehende","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhendée","word_nosc":"apprehendee","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhendées","word_nosc":"apprehendees","lemma":"appréhender","pos":"VER"} ,
		{"word":"appréhendés","word_nosc":"apprehendes","lemma":"appréhender","pos":"VER"} ,
		{"word":"apprêta","word_nosc":"appreta","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêtai","word_nosc":"appretai","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêtaient","word_nosc":"appretaient","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêtais","word_nosc":"appretais","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêtait","word_nosc":"appretait","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêtant","word_nosc":"appretant","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprête","word_nosc":"apprete","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêtent","word_nosc":"appretent","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêter","word_nosc":"appreter","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêterait","word_nosc":"appreterait","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêtes","word_nosc":"appretes","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêtez","word_nosc":"appretez","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêtiez","word_nosc":"appretiez","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêtions","word_nosc":"appretions","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêtèrent","word_nosc":"appreterent","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêté","word_nosc":"apprete","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêtée","word_nosc":"appretee","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêtées","word_nosc":"appretees","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprêtés","word_nosc":"appretes","lemma":"apprêter","pos":"VER"} ,
		{"word":"apprîmes","word_nosc":"apprimes","lemma":"apprendre","pos":"VER"} ,
		{"word":"apprît","word_nosc":"apprit","lemma":"apprendre","pos":"VER"} ,
		{"word":"appuie","word_nosc":"appuie","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuient","word_nosc":"appuient","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuiera","word_nosc":"appuiera","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuierai","word_nosc":"appuierai","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuieraient","word_nosc":"appuieraient","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuierais","word_nosc":"appuierais","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuierait","word_nosc":"appuierait","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuieras","word_nosc":"appuieras","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuierez","word_nosc":"appuierez","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuierons","word_nosc":"appuierons","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuieront","word_nosc":"appuieront","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuies","word_nosc":"appuies","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuya","word_nosc":"appuya","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyai","word_nosc":"appuyai","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyaient","word_nosc":"appuyaient","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyais","word_nosc":"appuyais","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyait","word_nosc":"appuyait","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyant","word_nosc":"appuyant","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyer","word_nosc":"appuyer","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyez","word_nosc":"appuyez","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyiez","word_nosc":"appuyiez","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyons","word_nosc":"appuyons","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyâmes","word_nosc":"appuyames","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyât","word_nosc":"appuyat","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyèrent","word_nosc":"appuyerent","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyé","word_nosc":"appuye","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyée","word_nosc":"appuyee","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyées","word_nosc":"appuyees","lemma":"appuyer","pos":"VER"} ,
		{"word":"appuyés","word_nosc":"appuyes","lemma":"appuyer","pos":"VER"} ,
		{"word":"appâtais","word_nosc":"appatais","lemma":"appâter","pos":"VER"} ,
		{"word":"appâtant","word_nosc":"appatant","lemma":"appâter","pos":"VER"} ,
		{"word":"appâte","word_nosc":"appate","lemma":"appâter","pos":"VER"} ,
		{"word":"appâtent","word_nosc":"appatent","lemma":"appâter","pos":"VER"} ,
		{"word":"appâter","word_nosc":"appater","lemma":"appâter","pos":"VER"} ,
		{"word":"appâtera","word_nosc":"appatera","lemma":"appâter","pos":"VER"} ,
		{"word":"appâterait","word_nosc":"appaterait","lemma":"appâter","pos":"VER"} ,
		{"word":"appâtes","word_nosc":"appates","lemma":"appâter","pos":"VER"} ,
		{"word":"appâtez","word_nosc":"appatez","lemma":"appâter","pos":"VER"} ,
		{"word":"appâtons","word_nosc":"appatons","lemma":"appâter","pos":"VER"} ,
		{"word":"appâté","word_nosc":"appate","lemma":"appâter","pos":"VER"} ,
		{"word":"appâtée","word_nosc":"appatee","lemma":"appâter","pos":"VER"} ,
		{"word":"appâtées","word_nosc":"appatees","lemma":"appâter","pos":"VER"} ,
		{"word":"appâtés","word_nosc":"appates","lemma":"appâter","pos":"VER"} ,
		{"word":"après-déjeuner","word_nosc":"apres-dejeuner","lemma":"après-déjeuner","pos":"VER"} ,
		{"word":"apurait","word_nosc":"apurait","lemma":"apurer","pos":"VER"} ,
		{"word":"apurer","word_nosc":"apurer","lemma":"apurer","pos":"VER"} ,
		{"word":"apuré","word_nosc":"apure","lemma":"apurer","pos":"VER"} ,
		{"word":"apurés","word_nosc":"apures","lemma":"apurer","pos":"VER"} ,
		{"word":"apâli","word_nosc":"apali","lemma":"apâlir","pos":"VER"} ,
		{"word":"apâlis","word_nosc":"apalis","lemma":"apâlir","pos":"VER"} ,
		{"word":"aquarellait","word_nosc":"aquarellait","lemma":"aquareller","pos":"VER"} ,
		{"word":"arabisant","word_nosc":"arabisant","lemma":"arabiser","pos":"VER"} ,
		{"word":"arabisé","word_nosc":"arabise","lemma":"arabiser","pos":"VER"} ,
		{"word":"arasant","word_nosc":"arasant","lemma":"araser","pos":"VER"} ,
		{"word":"araser","word_nosc":"araser","lemma":"araser","pos":"VER"} ,
		{"word":"arasée","word_nosc":"arasee","lemma":"araser","pos":"VER"} ,
		{"word":"arbitrait","word_nosc":"arbitrait","lemma":"arbitrer","pos":"VER"} ,
		{"word":"arbitre","word_nosc":"arbitre","lemma":"arbitrer","pos":"VER"} ,
		{"word":"arbitrer","word_nosc":"arbitrer","lemma":"arbitrer","pos":"VER"} ,
		{"word":"arbitrera","word_nosc":"arbitrera","lemma":"arbitrer","pos":"VER"} ,
		{"word":"arbitres","word_nosc":"arbitres","lemma":"arbitrer","pos":"VER"} ,
		{"word":"arbitré","word_nosc":"arbitre","lemma":"arbitrer","pos":"VER"} ,
		{"word":"arbitrée","word_nosc":"arbitree","lemma":"arbitrer","pos":"VER"} ,
		{"word":"arbitrées","word_nosc":"arbitrees","lemma":"arbitrer","pos":"VER"} ,
		{"word":"arbora","word_nosc":"arbora","lemma":"arborer","pos":"VER"} ,
		{"word":"arboraient","word_nosc":"arboraient","lemma":"arborer","pos":"VER"} ,
		{"word":"arborais","word_nosc":"arborais","lemma":"arborer","pos":"VER"} ,
		{"word":"arborait","word_nosc":"arborait","lemma":"arborer","pos":"VER"} ,
		{"word":"arborant","word_nosc":"arborant","lemma":"arborer","pos":"VER"} ,
		{"word":"arbore","word_nosc":"arbore","lemma":"arborer","pos":"VER"} ,
		{"word":"arborent","word_nosc":"arborent","lemma":"arborer","pos":"VER"} ,
		{"word":"arborer","word_nosc":"arborer","lemma":"arborer","pos":"VER"} ,
		{"word":"arborerait","word_nosc":"arborerait","lemma":"arborer","pos":"VER"} ,
		{"word":"arbores","word_nosc":"arbores","lemma":"arborer","pos":"VER"} ,
		{"word":"arborez","word_nosc":"arborez","lemma":"arborer","pos":"VER"} ,
		{"word":"arborions","word_nosc":"arborions","lemma":"arborer","pos":"VER"} ,
		{"word":"arborât","word_nosc":"arborat","lemma":"arborer","pos":"VER"} ,
		{"word":"arborèrent","word_nosc":"arborerent","lemma":"arborer","pos":"VER"} ,
		{"word":"arboré","word_nosc":"arbore","lemma":"arborer","pos":"VER"} ,
		{"word":"arborée","word_nosc":"arboree","lemma":"arborer","pos":"VER"} ,
		{"word":"arborés","word_nosc":"arbores","lemma":"arborer","pos":"VER"} ,
		{"word":"arc-bouta","word_nosc":"arc-bouta","lemma":"arc-bouter","pos":"VER"} ,
		{"word":"arc-boutais","word_nosc":"arc-boutais","lemma":"arc-bouter","pos":"VER"} ,
		{"word":"arc-boutait","word_nosc":"arc-boutait","lemma":"arc-bouter","pos":"VER"} ,
		{"word":"arc-boutant","word_nosc":"arc-boutant","lemma":"arc-bouter","pos":"VER"} ,
		{"word":"arc-boute","word_nosc":"arc-boute","lemma":"arc-bouter","pos":"VER"} ,
		{"word":"arc-boutent","word_nosc":"arc-boutent","lemma":"arc-bouter","pos":"VER"} ,
		{"word":"arc-bouter","word_nosc":"arc-bouter","lemma":"arc-bouter","pos":"VER"} ,
		{"word":"arc-boutera","word_nosc":"arc-boutera","lemma":"arc-bouter","pos":"VER"} ,
		{"word":"arc-boutèrent","word_nosc":"arc-bouterent","lemma":"arc-bouter","pos":"VER"} ,
		{"word":"arc-bouté","word_nosc":"arc-boute","lemma":"arc-bouter","pos":"VER"} ,
		{"word":"arc-boutée","word_nosc":"arc-boutee","lemma":"arc-bouter","pos":"VER"} ,
		{"word":"arc-boutées","word_nosc":"arc-boutees","lemma":"arc-bouter","pos":"VER"} ,
		{"word":"arc-boutés","word_nosc":"arc-boutes","lemma":"arc-bouter","pos":"VER"} ,
		{"word":"architecturant","word_nosc":"architecturant","lemma":"architecturer","pos":"VER"} ,
		{"word":"architecture","word_nosc":"architecture","lemma":"architecturer","pos":"VER"} ,
		{"word":"architecturent","word_nosc":"architecturent","lemma":"architecturer","pos":"VER"} ,
		{"word":"architecturées","word_nosc":"architecturees","lemma":"architecturer","pos":"VER"} ,
		{"word":"archive","word_nosc":"archive","lemma":"archiver","pos":"VER"} ,
		{"word":"archiver","word_nosc":"archiver","lemma":"archiver","pos":"VER"} ,
		{"word":"archives","word_nosc":"archives","lemma":"archiver","pos":"VER"} ,
		{"word":"archivez","word_nosc":"archivez","lemma":"archiver","pos":"VER"} ,
		{"word":"archivions","word_nosc":"archivions","lemma":"archiver","pos":"VER"} ,
		{"word":"archivons","word_nosc":"archivons","lemma":"archiver","pos":"VER"} ,
		{"word":"archivé","word_nosc":"archive","lemma":"archiver","pos":"VER"} ,
		{"word":"archivées","word_nosc":"archivees","lemma":"archiver","pos":"VER"} ,
		{"word":"archivés","word_nosc":"archives","lemma":"archiver","pos":"VER"} ,
		{"word":"ardais","word_nosc":"ardais","lemma":"arder","pos":"VER"} ,
		{"word":"ardait","word_nosc":"ardait","lemma":"arder","pos":"VER"} ,
		{"word":"ardant","word_nosc":"ardant","lemma":"arder","pos":"VER"} ,
		{"word":"arde","word_nosc":"arde","lemma":"arder","pos":"VER"} ,
		{"word":"arder","word_nosc":"arder","lemma":"arder","pos":"VER"} ,
		{"word":"ardoisées","word_nosc":"ardoisees","lemma":"ardoiser","pos":"VER"} ,
		{"word":"ardre","word_nosc":"ardre","lemma":"ardre","pos":"VER"} ,
		{"word":"ardé","word_nosc":"arde","lemma":"arder","pos":"VER"} ,
		{"word":"argentait","word_nosc":"argentait","lemma":"argenter","pos":"VER"} ,
		{"word":"argente","word_nosc":"argente","lemma":"argenter","pos":"VER"} ,
		{"word":"argenté","word_nosc":"argente","lemma":"argenter","pos":"VER"} ,
		{"word":"argentée","word_nosc":"argentee","lemma":"argenter","pos":"VER"} ,
		{"word":"argentées","word_nosc":"argentees","lemma":"argenter","pos":"VER"} ,
		{"word":"argentés","word_nosc":"argentes","lemma":"argenter","pos":"VER"} ,
		{"word":"argougnasses","word_nosc":"argougnasses","lemma":"argougner","pos":"VER"} ,
		{"word":"argougne","word_nosc":"argougne","lemma":"argougner","pos":"VER"} ,
		{"word":"argua","word_nosc":"argua","lemma":"arguer","pos":"VER"} ,
		{"word":"arguais","word_nosc":"arguais","lemma":"arguer","pos":"VER"} ,
		{"word":"arguait","word_nosc":"arguait","lemma":"arguer","pos":"VER"} ,
		{"word":"arguant","word_nosc":"arguant","lemma":"arguer","pos":"VER"} ,
		{"word":"argue","word_nosc":"argue","lemma":"arguer","pos":"VER"} ,
		{"word":"arguer","word_nosc":"arguer","lemma":"arguer","pos":"VER"} ,
		{"word":"argumenta","word_nosc":"argumenta","lemma":"argumenter","pos":"VER"} ,
		{"word":"argumentait","word_nosc":"argumentait","lemma":"argumenter","pos":"VER"} ,
		{"word":"argumentant","word_nosc":"argumentant","lemma":"argumenter","pos":"VER"} ,
		{"word":"argumente","word_nosc":"argumente","lemma":"argumenter","pos":"VER"} ,
		{"word":"argumentent","word_nosc":"argumentent","lemma":"argumenter","pos":"VER"} ,
		{"word":"argumenter","word_nosc":"argumenter","lemma":"argumenter","pos":"VER"} ,
		{"word":"argumenterai","word_nosc":"argumenterai","lemma":"argumenter","pos":"VER"} ,
		{"word":"argumentes","word_nosc":"argumentes","lemma":"argumenter","pos":"VER"} ,
		{"word":"argumentez","word_nosc":"argumentez","lemma":"argumenter","pos":"VER"} ,
		{"word":"argumenté","word_nosc":"argumente","lemma":"argumenter","pos":"VER"} ,
		{"word":"argué","word_nosc":"argue","lemma":"arguer","pos":"VER"} ,
		{"word":"arma","word_nosc":"arma","lemma":"armer","pos":"VER"} ,
		{"word":"armai","word_nosc":"armai","lemma":"armer","pos":"VER"} ,
		{"word":"armaient","word_nosc":"armaient","lemma":"armer","pos":"VER"} ,
		{"word":"armait","word_nosc":"armait","lemma":"armer","pos":"VER"} ,
		{"word":"armant","word_nosc":"armant","lemma":"armer","pos":"VER"} ,
		{"word":"armaturent","word_nosc":"armaturent","lemma":"armaturer","pos":"VER"} ,
		{"word":"arme","word_nosc":"arme","lemma":"armer","pos":"VER"} ,
		{"word":"arment","word_nosc":"arment","lemma":"armer","pos":"VER"} ,
		{"word":"armer","word_nosc":"armer","lemma":"armer","pos":"VER"} ,
		{"word":"armeraient","word_nosc":"armeraient","lemma":"armer","pos":"VER"} ,
		{"word":"armerais","word_nosc":"armerais","lemma":"armer","pos":"VER"} ,
		{"word":"armeront","word_nosc":"armeront","lemma":"armer","pos":"VER"} ,
		{"word":"armes","word_nosc":"armes","lemma":"armer","pos":"VER"} ,
		{"word":"armez","word_nosc":"armez","lemma":"armer","pos":"VER"} ,
		{"word":"armons","word_nosc":"armons","lemma":"armer","pos":"VER"} ,
		{"word":"armorié","word_nosc":"armorie","lemma":"armorier","pos":"VER"} ,
		{"word":"armoriée","word_nosc":"armoriee","lemma":"armorier","pos":"VER"} ,
		{"word":"armoriées","word_nosc":"armoriees","lemma":"armorier","pos":"VER"} ,
		{"word":"armoriés","word_nosc":"armories","lemma":"armorier","pos":"VER"} ,
		{"word":"armât","word_nosc":"armat","lemma":"armer","pos":"VER"} ,
		{"word":"armé","word_nosc":"arme","lemma":"armer","pos":"VER"} ,
		{"word":"armée","word_nosc":"armee","lemma":"armer","pos":"VER"} ,
		{"word":"armées","word_nosc":"armees","lemma":"armer","pos":"VER"} ,
		{"word":"armés","word_nosc":"armes","lemma":"armer","pos":"VER"} ,
		{"word":"arnaquait","word_nosc":"arnaquait","lemma":"arnaquer","pos":"VER"} ,
		{"word":"arnaquant","word_nosc":"arnaquant","lemma":"arnaquer","pos":"VER"} ,
		{"word":"arnaque","word_nosc":"arnaque","lemma":"arnaquer","pos":"VER"} ,
		{"word":"arnaquent","word_nosc":"arnaquent","lemma":"arnaquer","pos":"VER"} ,
		{"word":"arnaquer","word_nosc":"arnaquer","lemma":"arnaquer","pos":"VER"} ,
		{"word":"arnaquera","word_nosc":"arnaquera","lemma":"arnaquer","pos":"VER"} ,
		{"word":"arnaques","word_nosc":"arnaques","lemma":"arnaquer","pos":"VER"} ,
		{"word":"arnaquez","word_nosc":"arnaquez","lemma":"arnaquer","pos":"VER"} ,
		{"word":"arnaqué","word_nosc":"arnaque","lemma":"arnaquer","pos":"VER"} ,
		{"word":"arnaquée","word_nosc":"arnaquee","lemma":"arnaquer","pos":"VER"} ,
		{"word":"arnaqués","word_nosc":"arnaques","lemma":"arnaquer","pos":"VER"} ,
		{"word":"aromatiser","word_nosc":"aromatiser","lemma":"aromatiser","pos":"VER"} ,
		{"word":"aromatisé","word_nosc":"aromatise","lemma":"aromatiser","pos":"VER"} ,
		{"word":"aromatisée","word_nosc":"aromatisee","lemma":"aromatiser","pos":"VER"} ,
		{"word":"aromatisées","word_nosc":"aromatisees","lemma":"aromatiser","pos":"VER"} ,
		{"word":"aromatisés","word_nosc":"aromatises","lemma":"aromatiser","pos":"VER"} ,
		{"word":"arpenta","word_nosc":"arpenta","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpentai","word_nosc":"arpentai","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpentaient","word_nosc":"arpentaient","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpentais","word_nosc":"arpentais","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpentait","word_nosc":"arpentait","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpentant","word_nosc":"arpentant","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpente","word_nosc":"arpente","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpentent","word_nosc":"arpentent","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpenter","word_nosc":"arpenter","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpenterai","word_nosc":"arpenterai","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpenteront","word_nosc":"arpenteront","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpentez","word_nosc":"arpentez","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpentions","word_nosc":"arpentions","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpentons","word_nosc":"arpentons","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpentèrent","word_nosc":"arpenterent","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpenté","word_nosc":"arpente","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpentée","word_nosc":"arpentee","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpentés","word_nosc":"arpentes","lemma":"arpenter","pos":"VER"} ,
		{"word":"arpégé","word_nosc":"arpege","lemma":"arpéger","pos":"VER"} ,
		{"word":"arqua","word_nosc":"arqua","lemma":"arquer","pos":"VER"} ,
		{"word":"arquaient","word_nosc":"arquaient","lemma":"arquer","pos":"VER"} ,
		{"word":"arquait","word_nosc":"arquait","lemma":"arquer","pos":"VER"} ,
		{"word":"arquant","word_nosc":"arquant","lemma":"arquer","pos":"VER"} ,
		{"word":"arque","word_nosc":"arque","lemma":"arquer","pos":"VER"} ,
		{"word":"arquebusât","word_nosc":"arquebusat","lemma":"arquebuser","pos":"VER"} ,
		{"word":"arquent","word_nosc":"arquent","lemma":"arquer","pos":"VER"} ,
		{"word":"arquepince","word_nosc":"arquepince","lemma":"arquepincer","pos":"VER"} ,
		{"word":"arquer","word_nosc":"arquer","lemma":"arquer","pos":"VER"} ,
		{"word":"arqué","word_nosc":"arque","lemma":"arquer","pos":"VER"} ,
		{"word":"arquée","word_nosc":"arquee","lemma":"arquer","pos":"VER"} ,
		{"word":"arquées","word_nosc":"arquees","lemma":"arquer","pos":"VER"} ,
		{"word":"arqués","word_nosc":"arques","lemma":"arquer","pos":"VER"} ,
		{"word":"arracha","word_nosc":"arracha","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachai","word_nosc":"arrachai","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachaient","word_nosc":"arrachaient","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachais","word_nosc":"arrachais","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachait","word_nosc":"arrachait","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachant","word_nosc":"arrachant","lemma":"arracher","pos":"VER"} ,
		{"word":"arrache","word_nosc":"arrache","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachent","word_nosc":"arrachent","lemma":"arracher","pos":"VER"} ,
		{"word":"arracher","word_nosc":"arracher","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachera","word_nosc":"arrachera","lemma":"arracher","pos":"VER"} ,
		{"word":"arracherai","word_nosc":"arracherai","lemma":"arracher","pos":"VER"} ,
		{"word":"arracheraient","word_nosc":"arracheraient","lemma":"arracher","pos":"VER"} ,
		{"word":"arracherais","word_nosc":"arracherais","lemma":"arracher","pos":"VER"} ,
		{"word":"arracherait","word_nosc":"arracherait","lemma":"arracher","pos":"VER"} ,
		{"word":"arracheras","word_nosc":"arracheras","lemma":"arracher","pos":"VER"} ,
		{"word":"arracherons","word_nosc":"arracherons","lemma":"arracher","pos":"VER"} ,
		{"word":"arracheront","word_nosc":"arracheront","lemma":"arracher","pos":"VER"} ,
		{"word":"arraches","word_nosc":"arraches","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachez","word_nosc":"arrachez","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachiez","word_nosc":"arrachiez","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachions","word_nosc":"arrachions","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachons","word_nosc":"arrachons","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachât","word_nosc":"arrachat","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachèrent","word_nosc":"arracherent","lemma":"arracher","pos":"VER"} ,
		{"word":"arraché","word_nosc":"arrache","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachée","word_nosc":"arrachee","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachées","word_nosc":"arrachees","lemma":"arracher","pos":"VER"} ,
		{"word":"arrachés","word_nosc":"arraches","lemma":"arracher","pos":"VER"} ,
		{"word":"arraisonner","word_nosc":"arraisonner","lemma":"arraisonner","pos":"VER"} ,
		{"word":"arraisonnerons","word_nosc":"arraisonnerons","lemma":"arraisonner","pos":"VER"} ,
		{"word":"arraisonné","word_nosc":"arraisonne","lemma":"arraisonner","pos":"VER"} ,
		{"word":"arraisonnées","word_nosc":"arraisonnees","lemma":"arraisonner","pos":"VER"} ,
		{"word":"arraisonnés","word_nosc":"arraisonnes","lemma":"arraisonner","pos":"VER"} ,
		{"word":"arrange","word_nosc":"arrange","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangea","word_nosc":"arrangea","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangeai","word_nosc":"arrangeai","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangeaient","word_nosc":"arrangeaient","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangeais","word_nosc":"arrangeais","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangeait","word_nosc":"arrangeait","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangeant","word_nosc":"arrangeant","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangent","word_nosc":"arrangent","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangeons","word_nosc":"arrangeons","lemma":"arranger","pos":"VER"} ,
		{"word":"arranger","word_nosc":"arranger","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangera","word_nosc":"arrangera","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangerai","word_nosc":"arrangerai","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangeraient","word_nosc":"arrangeraient","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangerais","word_nosc":"arrangerais","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangerait","word_nosc":"arrangerait","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangeras","word_nosc":"arrangeras","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangerez","word_nosc":"arrangerez","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangerions","word_nosc":"arrangerions","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangerons","word_nosc":"arrangerons","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangeront","word_nosc":"arrangeront","lemma":"arranger","pos":"VER"} ,
		{"word":"arranges","word_nosc":"arranges","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangez","word_nosc":"arrangez","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangeât","word_nosc":"arrangeat","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangiez","word_nosc":"arrangiez","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangions","word_nosc":"arrangions","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangèrent","word_nosc":"arrangerent","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangé","word_nosc":"arrange","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangée","word_nosc":"arrangee","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangées","word_nosc":"arrangees","lemma":"arranger","pos":"VER"} ,
		{"word":"arrangés","word_nosc":"arranges","lemma":"arranger","pos":"VER"} ,
		{"word":"arrima","word_nosc":"arrima","lemma":"arrimer","pos":"VER"} ,
		{"word":"arrimais","word_nosc":"arrimais","lemma":"arrimer","pos":"VER"} ,
		{"word":"arrimait","word_nosc":"arrimait","lemma":"arrimer","pos":"VER"} ,
		{"word":"arrimant","word_nosc":"arrimant","lemma":"arrimer","pos":"VER"} ,
		{"word":"arrime","word_nosc":"arrime","lemma":"arrimer","pos":"VER"} ,
		{"word":"arriment","word_nosc":"arriment","lemma":"arrimer","pos":"VER"} ,
		{"word":"arrimer","word_nosc":"arrimer","lemma":"arrimer","pos":"VER"} ,
		{"word":"arrimez","word_nosc":"arrimez","lemma":"arrimer","pos":"VER"} ,
		{"word":"arrimé","word_nosc":"arrime","lemma":"arrimer","pos":"VER"} ,
		{"word":"arrimée","word_nosc":"arrimee","lemma":"arrimer","pos":"VER"} ,
		{"word":"arrimées","word_nosc":"arrimees","lemma":"arrimer","pos":"VER"} ,
		{"word":"arrimés","word_nosc":"arrimes","lemma":"arrimer","pos":"VER"} ,
		{"word":"arriva","word_nosc":"arriva","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivai","word_nosc":"arrivai","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivaient","word_nosc":"arrivaient","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivais","word_nosc":"arrivais","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivait","word_nosc":"arrivait","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivant","word_nosc":"arrivant","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivas","word_nosc":"arrivas","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivassent","word_nosc":"arrivassent","lemma":"arriver","pos":"VER"} ,
		{"word":"arrive","word_nosc":"arrive","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivent","word_nosc":"arrivent","lemma":"arriver","pos":"VER"} ,
		{"word":"arriver","word_nosc":"arriver","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivera","word_nosc":"arrivera","lemma":"arriver","pos":"VER"} ,
		{"word":"arriverai","word_nosc":"arriverai","lemma":"arriver","pos":"VER"} ,
		{"word":"arriveraient","word_nosc":"arriveraient","lemma":"arriver","pos":"VER"} ,
		{"word":"arriverais","word_nosc":"arriverais","lemma":"arriver","pos":"VER"} ,
		{"word":"arriverait","word_nosc":"arriverait","lemma":"arriver","pos":"VER"} ,
		{"word":"arriveras","word_nosc":"arriveras","lemma":"arriver","pos":"VER"} ,
		{"word":"arriverez","word_nosc":"arriverez","lemma":"arriver","pos":"VER"} ,
		{"word":"arriveriez","word_nosc":"arriveriez","lemma":"arriver","pos":"VER"} ,
		{"word":"arriverions","word_nosc":"arriverions","lemma":"arriver","pos":"VER"} ,
		{"word":"arriverons","word_nosc":"arriverons","lemma":"arriver","pos":"VER"} ,
		{"word":"arriveront","word_nosc":"arriveront","lemma":"arriver","pos":"VER"} ,
		{"word":"arrives","word_nosc":"arrives","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivez","word_nosc":"arrivez","lemma":"arriver","pos":"VER"} ,
		{"word":"arriviez","word_nosc":"arriviez","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivions","word_nosc":"arrivions","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivons","word_nosc":"arrivons","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivâmes","word_nosc":"arrivames","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivât","word_nosc":"arrivat","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivâtes","word_nosc":"arrivates","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivèrent","word_nosc":"arriverent","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivé","word_nosc":"arrive","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivée","word_nosc":"arrivee","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivées","word_nosc":"arrivees","lemma":"arriver","pos":"VER"} ,
		{"word":"arrivés","word_nosc":"arrives","lemma":"arriver","pos":"VER"} ,
		{"word":"arriéré","word_nosc":"arriere","lemma":"arriérer","pos":"VER"} ,
		{"word":"arriérée","word_nosc":"arrieree","lemma":"arriérer","pos":"VER"} ,
		{"word":"arriérées","word_nosc":"arrierees","lemma":"arriérer","pos":"VER"} ,
		{"word":"arriérés","word_nosc":"arrieres","lemma":"arriérer","pos":"VER"} ,
		{"word":"arroge","word_nosc":"arroge","lemma":"arroger","pos":"VER"} ,
		{"word":"arrogeaient","word_nosc":"arrogeaient","lemma":"arroger","pos":"VER"} ,
		{"word":"arrogeait","word_nosc":"arrogeait","lemma":"arroger","pos":"VER"} ,
		{"word":"arrogeant","word_nosc":"arrogeant","lemma":"arroger","pos":"VER"} ,
		{"word":"arrogent","word_nosc":"arrogent","lemma":"arroger","pos":"VER"} ,
		{"word":"arroger","word_nosc":"arroger","lemma":"arroger","pos":"VER"} ,
		{"word":"arrogèrent","word_nosc":"arrogerent","lemma":"arroger","pos":"VER"} ,
		{"word":"arrogé","word_nosc":"arroge","lemma":"arroger","pos":"VER"} ,
		{"word":"arrogée","word_nosc":"arrogee","lemma":"arroger","pos":"VER"} ,
		{"word":"arrondi","word_nosc":"arrondi","lemma":"arrondir","pos":"VER"} ,
		{"word":"arrondie","word_nosc":"arrondie","lemma":"arrondir","pos":"VER"} ,
		{"word":"arrondies","word_nosc":"arrondies","lemma":"arrondir","pos":"VER"} ,
		{"word":"arrondir","word_nosc":"arrondir","lemma":"arrondir","pos":"VER"} ,
		{"word":"arrondira","word_nosc":"arrondira","lemma":"arrondir","pos":"VER"} ,
		{"word":"arrondirait","word_nosc":"arrondirait","lemma":"arrondir","pos":"VER"} ,
		{"word":"arrondirent","word_nosc":"arrondirent","lemma":"arrondir","pos":"VER"} ,
		{"word":"arrondis","word_nosc":"arrondis","lemma":"arrondir","pos":"VER"} ,
		{"word":"arrondissaient","word_nosc":"arrondissaient","lemma":"arrondir","pos":"VER"} ,
		{"word":"arrondissait","word_nosc":"arrondissait","lemma":"arrondir","pos":"VER"} ,
		{"word":"arrondissant","word_nosc":"arrondissant","lemma":"arrondir","pos":"VER"} ,
		{"word":"arrondissent","word_nosc":"arrondissent","lemma":"arrondir","pos":"VER"} ,
		{"word":"arrondissons","word_nosc":"arrondissons","lemma":"arrondir","pos":"VER"} ,
		{"word":"arrondit","word_nosc":"arrondit","lemma":"arrondir","pos":"VER"} ,
		{"word":"arrosa","word_nosc":"arrosa","lemma":"arroser","pos":"VER"} ,
		{"word":"arrosaient","word_nosc":"arrosaient","lemma":"arroser","pos":"VER"} ,
		{"word":"arrosais","word_nosc":"arrosais","lemma":"arroser","pos":"VER"} ,
		{"word":"arrosait","word_nosc":"arrosait","lemma":"arroser","pos":"VER"} ,
		{"word":"arrosant","word_nosc":"arrosant","lemma":"arroser","pos":"VER"} ,
		{"word":"arrose","word_nosc":"arrose","lemma":"arroser","pos":"VER"} ,
		{"word":"arrosent","word_nosc":"arrosent","lemma":"arroser","pos":"VER"} ,
		{"word":"arroser","word_nosc":"arroser","lemma":"arroser","pos":"VER"} ,
		{"word":"arrosera","word_nosc":"arrosera","lemma":"arroser","pos":"VER"} ,
		{"word":"arroserais","word_nosc":"arroserais","lemma":"arroser","pos":"VER"} ,
		{"word":"arroserait","word_nosc":"arroserait","lemma":"arroser","pos":"VER"} ,
		{"word":"arroseras","word_nosc":"arroseras","lemma":"arroser","pos":"VER"} ,
		{"word":"arroserez","word_nosc":"arroserez","lemma":"arroser","pos":"VER"} ,
		{"word":"arroseront","word_nosc":"arroseront","lemma":"arroser","pos":"VER"} ,
		{"word":"arroses","word_nosc":"arroses","lemma":"arroser","pos":"VER"} ,
		{"word":"arrosez","word_nosc":"arrosez","lemma":"arroser","pos":"VER"} ,
		{"word":"arrosiez","word_nosc":"arrosiez","lemma":"arroser","pos":"VER"} ,
		{"word":"arrosions","word_nosc":"arrosions","lemma":"arroser","pos":"VER"} ,
		{"word":"arrosons","word_nosc":"arrosons","lemma":"arroser","pos":"VER"} ,
		{"word":"arrosèrent","word_nosc":"arroserent","lemma":"arroser","pos":"VER"} ,
		{"word":"arrosé","word_nosc":"arrose","lemma":"arroser","pos":"VER"} ,
		{"word":"arrosée","word_nosc":"arrosee","lemma":"arroser","pos":"VER"} ,
		{"word":"arrosées","word_nosc":"arrosees","lemma":"arroser","pos":"VER"} ,
		{"word":"arrosés","word_nosc":"arroses","lemma":"arroser","pos":"VER"} ,
		{"word":"arrêta","word_nosc":"arreta","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtai","word_nosc":"arretai","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtaient","word_nosc":"arretaient","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtais","word_nosc":"arretais","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtait","word_nosc":"arretait","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtant","word_nosc":"arretant","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtassent","word_nosc":"arretassent","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrête","word_nosc":"arrete","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtent","word_nosc":"arretent","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêter","word_nosc":"arreter","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtera","word_nosc":"arretera","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêterai","word_nosc":"arreterai","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêteraient","word_nosc":"arreteraient","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêterais","word_nosc":"arreterais","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêterait","word_nosc":"arreterait","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêteras","word_nosc":"arreteras","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêterez","word_nosc":"arreterez","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêteriez","word_nosc":"arreteriez","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêterions","word_nosc":"arreterions","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêterons","word_nosc":"arreterons","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêteront","word_nosc":"arreteront","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtes","word_nosc":"arretes","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtez","word_nosc":"arretez","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtiez","word_nosc":"arretiez","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtions","word_nosc":"arretions","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtons","word_nosc":"arretons","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtâmes","word_nosc":"arretames","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtât","word_nosc":"arretat","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtèrent","word_nosc":"arreterent","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêté","word_nosc":"arrete","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtée","word_nosc":"arretee","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtées","word_nosc":"arretees","lemma":"arrêter","pos":"VER"} ,
		{"word":"arrêtés","word_nosc":"arretes","lemma":"arrêter","pos":"VER"} ,
		{"word":"arsouiller","word_nosc":"arsouiller","lemma":"arsouiller","pos":"VER"} ,
		{"word":"articula","word_nosc":"articula","lemma":"articuler","pos":"VER"} ,
		{"word":"articulai","word_nosc":"articulai","lemma":"articuler","pos":"VER"} ,
		{"word":"articulaient","word_nosc":"articulaient","lemma":"articuler","pos":"VER"} ,
		{"word":"articulait","word_nosc":"articulait","lemma":"articuler","pos":"VER"} ,
		{"word":"articulant","word_nosc":"articulant","lemma":"articuler","pos":"VER"} ,
		{"word":"articule","word_nosc":"articule","lemma":"articuler","pos":"VER"} ,
		{"word":"articulent","word_nosc":"articulent","lemma":"articuler","pos":"VER"} ,
		{"word":"articuler","word_nosc":"articuler","lemma":"articuler","pos":"VER"} ,
		{"word":"articulez","word_nosc":"articulez","lemma":"articuler","pos":"VER"} ,
		{"word":"articulé","word_nosc":"articule","lemma":"articuler","pos":"VER"} ,
		{"word":"articulée","word_nosc":"articulee","lemma":"articuler","pos":"VER"} ,
		{"word":"articulées","word_nosc":"articulees","lemma":"articuler","pos":"VER"} ,
		{"word":"articulés","word_nosc":"articules","lemma":"articuler","pos":"VER"} ,
		{"word":"as","word_nosc":"as","lemma":"avoir","pos":"VER"} ,
		{"word":"ascendre","word_nosc":"ascendre","lemma":"ascendre","pos":"VER"} ,
		{"word":"ascendu","word_nosc":"ascendu","lemma":"ascendre","pos":"VER"} ,
		{"word":"ascensionne","word_nosc":"ascensionne","lemma":"ascensionner","pos":"VER"} ,
		{"word":"ascensionner","word_nosc":"ascensionner","lemma":"ascensionner","pos":"VER"} ,
		{"word":"aseptisant","word_nosc":"aseptisant","lemma":"aseptiser","pos":"VER"} ,
		{"word":"aseptisé","word_nosc":"aseptise","lemma":"aseptiser","pos":"VER"} ,
		{"word":"aseptisée","word_nosc":"aseptisee","lemma":"aseptiser","pos":"VER"} ,
		{"word":"asiatisé","word_nosc":"asiatise","lemma":"asiatiser","pos":"VER"} ,
		{"word":"asperge","word_nosc":"asperge","lemma":"asperger","pos":"VER"} ,
		{"word":"aspergea","word_nosc":"aspergea","lemma":"asperger","pos":"VER"} ,
		{"word":"aspergeaient","word_nosc":"aspergeaient","lemma":"asperger","pos":"VER"} ,
		{"word":"aspergeait","word_nosc":"aspergeait","lemma":"asperger","pos":"VER"} ,
		{"word":"aspergeant","word_nosc":"aspergeant","lemma":"asperger","pos":"VER"} ,
		{"word":"aspergent","word_nosc":"aspergent","lemma":"asperger","pos":"VER"} ,
		{"word":"asperger","word_nosc":"asperger","lemma":"asperger","pos":"VER"} ,
		{"word":"aspergerait","word_nosc":"aspergerait","lemma":"asperger","pos":"VER"} ,
		{"word":"asperges","word_nosc":"asperges","lemma":"asperger","pos":"VER"} ,
		{"word":"aspergez","word_nosc":"aspergez","lemma":"asperger","pos":"VER"} ,
		{"word":"aspergèrent","word_nosc":"aspergerent","lemma":"asperger","pos":"VER"} ,
		{"word":"aspergé","word_nosc":"asperge","lemma":"asperger","pos":"VER"} ,
		{"word":"aspergée","word_nosc":"aspergee","lemma":"asperger","pos":"VER"} ,
		{"word":"aspergées","word_nosc":"aspergees","lemma":"asperger","pos":"VER"} ,
		{"word":"aspergés","word_nosc":"asperges","lemma":"asperger","pos":"VER"} ,
		{"word":"asphalte","word_nosc":"asphalte","lemma":"asphalter","pos":"VER"} ,
		{"word":"asphalter","word_nosc":"asphalter","lemma":"asphalter","pos":"VER"} ,
		{"word":"asphalté","word_nosc":"asphalte","lemma":"asphalter","pos":"VER"} ,
		{"word":"asphaltée","word_nosc":"asphaltee","lemma":"asphalter","pos":"VER"} ,
		{"word":"asphaltées","word_nosc":"asphaltees","lemma":"asphalter","pos":"VER"} ,
		{"word":"asphaltés","word_nosc":"asphaltes","lemma":"asphalter","pos":"VER"} ,
		{"word":"asphyxia","word_nosc":"asphyxia","lemma":"asphyxier","pos":"VER"} ,
		{"word":"asphyxiaient","word_nosc":"asphyxiaient","lemma":"asphyxier","pos":"VER"} ,
		{"word":"asphyxiait","word_nosc":"asphyxiait","lemma":"asphyxier","pos":"VER"} ,
		{"word":"asphyxiant","word_nosc":"asphyxiant","lemma":"asphyxier","pos":"VER"} ,
		{"word":"asphyxie","word_nosc":"asphyxie","lemma":"asphyxier","pos":"VER"} ,
		{"word":"asphyxient","word_nosc":"asphyxient","lemma":"asphyxier","pos":"VER"} ,
		{"word":"asphyxier","word_nosc":"asphyxier","lemma":"asphyxier","pos":"VER"} ,
		{"word":"asphyxiera","word_nosc":"asphyxiera","lemma":"asphyxier","pos":"VER"} ,
		{"word":"asphyxié","word_nosc":"asphyxie","lemma":"asphyxier","pos":"VER"} ,
		{"word":"asphyxiée","word_nosc":"asphyxiee","lemma":"asphyxier","pos":"VER"} ,
		{"word":"asphyxiées","word_nosc":"asphyxiees","lemma":"asphyxier","pos":"VER"} ,
		{"word":"asphyxiés","word_nosc":"asphyxies","lemma":"asphyxier","pos":"VER"} ,
		{"word":"aspira","word_nosc":"aspira","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspirai","word_nosc":"aspirai","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspiraient","word_nosc":"aspiraient","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspirais","word_nosc":"aspirais","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspirait","word_nosc":"aspirait","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspirant","word_nosc":"aspirant","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspire","word_nosc":"aspire","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspirent","word_nosc":"aspirent","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspirer","word_nosc":"aspirer","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspirera","word_nosc":"aspirera","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspireraient","word_nosc":"aspireraient","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspirerait","word_nosc":"aspirerait","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspires","word_nosc":"aspires","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspirez","word_nosc":"aspirez","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspiriez","word_nosc":"aspiriez","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspirions","word_nosc":"aspirions","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspirons","word_nosc":"aspirons","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspirât","word_nosc":"aspirat","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspiré","word_nosc":"aspire","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspirée","word_nosc":"aspiree","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspirées","word_nosc":"aspirees","lemma":"aspirer","pos":"VER"} ,
		{"word":"aspirés","word_nosc":"aspires","lemma":"aspirer","pos":"VER"} ,
		{"word":"assagi","word_nosc":"assagi","lemma":"assagir","pos":"VER"} ,
		{"word":"assagie","word_nosc":"assagie","lemma":"assagir","pos":"VER"} ,
		{"word":"assagir","word_nosc":"assagir","lemma":"assagir","pos":"VER"} ,
		{"word":"assagirai","word_nosc":"assagirai","lemma":"assagir","pos":"VER"} ,
		{"word":"assagis","word_nosc":"assagis","lemma":"assagir","pos":"VER"} ,
		{"word":"assagissant","word_nosc":"assagissant","lemma":"assagir","pos":"VER"} ,
		{"word":"assagisse","word_nosc":"assagisse","lemma":"assagir","pos":"VER"} ,
		{"word":"assagit","word_nosc":"assagit","lemma":"assagir","pos":"VER"} ,
		{"word":"assaillaient","word_nosc":"assaillaient","lemma":"assaillir","pos":"VER"} ,
		{"word":"assaillait","word_nosc":"assaillait","lemma":"assaillir","pos":"VER"} ,
		{"word":"assaillant","word_nosc":"assaillant","lemma":"assaillir","pos":"VER"} ,
		{"word":"assaille","word_nosc":"assaille","lemma":"assaillir","pos":"VER"} ,
		{"word":"assaillent","word_nosc":"assaillent","lemma":"assaillir","pos":"VER"} ,
		{"word":"assailles","word_nosc":"assailles","lemma":"assaillir","pos":"VER"} ,
		{"word":"assailli","word_nosc":"assailli","lemma":"assaillir","pos":"VER"} ,
		{"word":"assaillie","word_nosc":"assaillie","lemma":"assaillir","pos":"VER"} ,
		{"word":"assaillies","word_nosc":"assaillies","lemma":"assaillir","pos":"VER"} ,
		{"word":"assaillir","word_nosc":"assaillir","lemma":"assaillir","pos":"VER"} ,
		{"word":"assaillira","word_nosc":"assaillira","lemma":"assaillir","pos":"VER"} ,
		{"word":"assaillirent","word_nosc":"assaillirent","lemma":"assaillir","pos":"VER"} ,
		{"word":"assaillis","word_nosc":"assaillis","lemma":"assaillir","pos":"VER"} ,
		{"word":"assaillit","word_nosc":"assaillit","lemma":"assaillir","pos":"VER"} ,
		{"word":"assaillons","word_nosc":"assaillons","lemma":"assaillir","pos":"VER"} ,
		{"word":"assaini","word_nosc":"assaini","lemma":"assainir","pos":"VER"} ,
		{"word":"assainie","word_nosc":"assainie","lemma":"assainir","pos":"VER"} ,
		{"word":"assainir","word_nosc":"assainir","lemma":"assainir","pos":"VER"} ,
		{"word":"assainirait","word_nosc":"assainirait","lemma":"assainir","pos":"VER"} ,
		{"word":"assainis","word_nosc":"assainis","lemma":"assainir","pos":"VER"} ,
		{"word":"assaisonna","word_nosc":"assaisonna","lemma":"assaisonner","pos":"VER"} ,
		{"word":"assaisonnait","word_nosc":"assaisonnait","lemma":"assaisonner","pos":"VER"} ,
		{"word":"assaisonne","word_nosc":"assaisonne","lemma":"assaisonner","pos":"VER"} ,
		{"word":"assaisonner","word_nosc":"assaisonner","lemma":"assaisonner","pos":"VER"} ,
		{"word":"assaisonnerait","word_nosc":"assaisonnerait","lemma":"assaisonner","pos":"VER"} ,
		{"word":"assaisonnèrent","word_nosc":"assaisonnerent","lemma":"assaisonner","pos":"VER"} ,
		{"word":"assaisonné","word_nosc":"assaisonne","lemma":"assaisonner","pos":"VER"} ,
		{"word":"assaisonnée","word_nosc":"assaisonnee","lemma":"assaisonner","pos":"VER"} ,
		{"word":"assaisonnées","word_nosc":"assaisonnees","lemma":"assaisonner","pos":"VER"} ,
		{"word":"assaisonnés","word_nosc":"assaisonnes","lemma":"assaisonner","pos":"VER"} ,
		{"word":"assassina","word_nosc":"assassina","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassinai","word_nosc":"assassinai","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassinaient","word_nosc":"assassinaient","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassinais","word_nosc":"assassinais","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassinait","word_nosc":"assassinait","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassinant","word_nosc":"assassinant","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassine","word_nosc":"assassine","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassinent","word_nosc":"assassinent","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassiner","word_nosc":"assassiner","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassinera","word_nosc":"assassinera","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassinerai","word_nosc":"assassinerai","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassinerais","word_nosc":"assassinerais","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassinerez","word_nosc":"assassinerez","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassines","word_nosc":"assassines","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassinez","word_nosc":"assassinez","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassinons","word_nosc":"assassinons","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassiné","word_nosc":"assassine","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassinée","word_nosc":"assassinee","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassinées","word_nosc":"assassinees","lemma":"assassiner","pos":"VER"} ,
		{"word":"assassinés","word_nosc":"assassines","lemma":"assassiner","pos":"VER"} ,
		{"word":"assavoir","word_nosc":"assavoir","lemma":"assavoir","pos":"VER"} ,
		{"word":"assembla","word_nosc":"assembla","lemma":"assembler","pos":"VER"} ,
		{"word":"assemblaient","word_nosc":"assemblaient","lemma":"assembler","pos":"VER"} ,
		{"word":"assemblait","word_nosc":"assemblait","lemma":"assembler","pos":"VER"} ,
		{"word":"assemblant","word_nosc":"assemblant","lemma":"assembler","pos":"VER"} ,
		{"word":"assemble","word_nosc":"assemble","lemma":"assembler","pos":"VER"} ,
		{"word":"assemblent","word_nosc":"assemblent","lemma":"assembler","pos":"VER"} ,
		{"word":"assembler","word_nosc":"assembler","lemma":"assembler","pos":"VER"} ,
		{"word":"assemblera","word_nosc":"assemblera","lemma":"assembler","pos":"VER"} ,
		{"word":"assemblerez","word_nosc":"assemblerez","lemma":"assembler","pos":"VER"} ,
		{"word":"assemblez","word_nosc":"assemblez","lemma":"assembler","pos":"VER"} ,
		{"word":"assemblons","word_nosc":"assemblons","lemma":"assembler","pos":"VER"} ,
		{"word":"assemblé","word_nosc":"assemble","lemma":"assembler","pos":"VER"} ,
		{"word":"assemblée","word_nosc":"assemblee","lemma":"assembler","pos":"VER"} ,
		{"word":"assemblées","word_nosc":"assemblees","lemma":"assembler","pos":"VER"} ,
		{"word":"assemblés","word_nosc":"assembles","lemma":"assembler","pos":"VER"} ,
		{"word":"assena","word_nosc":"assena","lemma":"assener","pos":"VER"} ,
		{"word":"assenai","word_nosc":"assenai","lemma":"assener","pos":"VER"} ,
		{"word":"assenaient","word_nosc":"assenaient","lemma":"assener","pos":"VER"} ,
		{"word":"assenais","word_nosc":"assenais","lemma":"assener","pos":"VER"} ,
		{"word":"assenait","word_nosc":"assenait","lemma":"assener","pos":"VER"} ,
		{"word":"assenant","word_nosc":"assenant","lemma":"assener","pos":"VER"} ,
		{"word":"assener","word_nosc":"assener","lemma":"assener","pos":"VER"} ,
		{"word":"assené","word_nosc":"assene","lemma":"assener","pos":"VER"} ,
		{"word":"assenée","word_nosc":"assenee","lemma":"assener","pos":"VER"} ,
		{"word":"assenées","word_nosc":"assenees","lemma":"assener","pos":"VER"} ,
		{"word":"assenés","word_nosc":"assenes","lemma":"assener","pos":"VER"} ,
		{"word":"asseoir","word_nosc":"asseoir","lemma":"asseoir","pos":"VER"} ,
		{"word":"assermenter","word_nosc":"assermenter","lemma":"assermenter","pos":"VER"} ,
		{"word":"assermentez","word_nosc":"assermentez","lemma":"assermenter","pos":"VER"} ,
		{"word":"assermenté","word_nosc":"assermente","lemma":"assermenter","pos":"VER"} ,
		{"word":"assermentée","word_nosc":"assermentee","lemma":"assermenter","pos":"VER"} ,
		{"word":"assermentés","word_nosc":"assermentes","lemma":"assermenter","pos":"VER"} ,
		{"word":"asservi","word_nosc":"asservi","lemma":"asservir","pos":"VER"} ,
		{"word":"asservie","word_nosc":"asservie","lemma":"asservir","pos":"VER"} ,
		{"word":"asservies","word_nosc":"asservies","lemma":"asservir","pos":"VER"} ,
		{"word":"asservir","word_nosc":"asservir","lemma":"asservir","pos":"VER"} ,
		{"word":"asservirai","word_nosc":"asservirai","lemma":"asservir","pos":"VER"} ,
		{"word":"asservirait","word_nosc":"asservirait","lemma":"asservir","pos":"VER"} ,
		{"word":"asserviront","word_nosc":"asserviront","lemma":"asservir","pos":"VER"} ,
		{"word":"asservis","word_nosc":"asservis","lemma":"asservir","pos":"VER"} ,
		{"word":"asservissaient","word_nosc":"asservissaient","lemma":"asservir","pos":"VER"} ,
		{"word":"asservissait","word_nosc":"asservissait","lemma":"asservir","pos":"VER"} ,
		{"word":"asservissant","word_nosc":"asservissant","lemma":"asservir","pos":"VER"} ,
		{"word":"asservissent","word_nosc":"asservissent","lemma":"asservir","pos":"VER"} ,
		{"word":"asservit","word_nosc":"asservit","lemma":"asservir","pos":"VER"} ,
		{"word":"asseyaient","word_nosc":"asseyaient","lemma":"asseoir","pos":"VER"} ,
		{"word":"asseyais","word_nosc":"asseyais","lemma":"asseoir","pos":"VER"} ,
		{"word":"asseyait","word_nosc":"asseyait","lemma":"asseoir","pos":"VER"} ,
		{"word":"asseyant","word_nosc":"asseyant","lemma":"asseoir","pos":"VER"} ,
		{"word":"asseye","word_nosc":"asseye","lemma":"asseoir","pos":"VER"} ,
		{"word":"asseyent","word_nosc":"asseyent","lemma":"asseoir","pos":"VER"} ,
		{"word":"asseyes","word_nosc":"asseyes","lemma":"asseoir","pos":"VER"} ,
		{"word":"asseyez","word_nosc":"asseyez","lemma":"asseoir","pos":"VER"} ,
		{"word":"asseyiez","word_nosc":"asseyiez","lemma":"asseoir","pos":"VER"} ,
		{"word":"asseyions","word_nosc":"asseyions","lemma":"asseoir","pos":"VER"} ,
		{"word":"asseyons","word_nosc":"asseyons","lemma":"asseoir","pos":"VER"} ,
		{"word":"assied","word_nosc":"assied","lemma":"asseoir","pos":"VER"} ,
		{"word":"assieds","word_nosc":"assieds","lemma":"asseoir","pos":"VER"} ,
		{"word":"assigna","word_nosc":"assigna","lemma":"assigner","pos":"VER"} ,
		{"word":"assignai","word_nosc":"assignai","lemma":"assigner","pos":"VER"} ,
		{"word":"assignais","word_nosc":"assignais","lemma":"assigner","pos":"VER"} ,
		{"word":"assignait","word_nosc":"assignait","lemma":"assigner","pos":"VER"} ,
		{"word":"assignant","word_nosc":"assignant","lemma":"assigner","pos":"VER"} ,
		{"word":"assigne","word_nosc":"assigne","lemma":"assigner","pos":"VER"} ,
		{"word":"assignent","word_nosc":"assignent","lemma":"assigner","pos":"VER"} ,
		{"word":"assigner","word_nosc":"assigner","lemma":"assigner","pos":"VER"} ,
		{"word":"assignera","word_nosc":"assignera","lemma":"assigner","pos":"VER"} ,
		{"word":"assignerai","word_nosc":"assignerai","lemma":"assigner","pos":"VER"} ,
		{"word":"assignerait","word_nosc":"assignerait","lemma":"assigner","pos":"VER"} ,
		{"word":"assignerons","word_nosc":"assignerons","lemma":"assigner","pos":"VER"} ,
		{"word":"assigneront","word_nosc":"assigneront","lemma":"assigner","pos":"VER"} ,
		{"word":"assignes","word_nosc":"assignes","lemma":"assigner","pos":"VER"} ,
		{"word":"assigné","word_nosc":"assigne","lemma":"assigner","pos":"VER"} ,
		{"word":"assignée","word_nosc":"assignee","lemma":"assigner","pos":"VER"} ,
		{"word":"assignées","word_nosc":"assignees","lemma":"assigner","pos":"VER"} ,
		{"word":"assignés","word_nosc":"assignes","lemma":"assigner","pos":"VER"} ,
		{"word":"assimila","word_nosc":"assimila","lemma":"assimiler","pos":"VER"} ,
		{"word":"assimilaient","word_nosc":"assimilaient","lemma":"assimiler","pos":"VER"} ,
		{"word":"assimilais","word_nosc":"assimilais","lemma":"assimiler","pos":"VER"} ,
		{"word":"assimilait","word_nosc":"assimilait","lemma":"assimiler","pos":"VER"} ,
		{"word":"assimilant","word_nosc":"assimilant","lemma":"assimiler","pos":"VER"} ,
		{"word":"assimile","word_nosc":"assimile","lemma":"assimiler","pos":"VER"} ,
		{"word":"assimilent","word_nosc":"assimilent","lemma":"assimiler","pos":"VER"} ,
		{"word":"assimiler","word_nosc":"assimiler","lemma":"assimiler","pos":"VER"} ,
		{"word":"assimilerait","word_nosc":"assimilerait","lemma":"assimiler","pos":"VER"} ,
		{"word":"assimilez","word_nosc":"assimilez","lemma":"assimiler","pos":"VER"} ,
		{"word":"assimilèrent","word_nosc":"assimilerent","lemma":"assimiler","pos":"VER"} ,
		{"word":"assimilé","word_nosc":"assimile","lemma":"assimiler","pos":"VER"} ,
		{"word":"assimilée","word_nosc":"assimilee","lemma":"assimiler","pos":"VER"} ,
		{"word":"assimilées","word_nosc":"assimilees","lemma":"assimiler","pos":"VER"} ,
		{"word":"assimilés","word_nosc":"assimiles","lemma":"assimiler","pos":"VER"} ,
		{"word":"assirent","word_nosc":"assirent","lemma":"asseoir","pos":"VER"} ,
		{"word":"assis","word_nosc":"assis","lemma":"asseoir","pos":"VER"} ,
		{"word":"assise","word_nosc":"assise","lemma":"asseoir","pos":"VER"} ,
		{"word":"assises","word_nosc":"assises","lemma":"asseoir","pos":"VER"} ,
		{"word":"assista","word_nosc":"assista","lemma":"assister","pos":"VER"} ,
		{"word":"assistai","word_nosc":"assistai","lemma":"assister","pos":"VER"} ,
		{"word":"assistaient","word_nosc":"assistaient","lemma":"assister","pos":"VER"} ,
		{"word":"assistais","word_nosc":"assistais","lemma":"assister","pos":"VER"} ,
		{"word":"assistait","word_nosc":"assistait","lemma":"assister","pos":"VER"} ,
		{"word":"assistant","word_nosc":"assistant","lemma":"assister","pos":"VER"} ,
		{"word":"assistas","word_nosc":"assistas","lemma":"assister","pos":"VER"} ,
		{"word":"assiste","word_nosc":"assiste","lemma":"assister","pos":"VER"} ,
		{"word":"assistent","word_nosc":"assistent","lemma":"assister","pos":"VER"} ,
		{"word":"assister","word_nosc":"assister","lemma":"assister","pos":"VER"} ,
		{"word":"assistera","word_nosc":"assistera","lemma":"assister","pos":"VER"} ,
		{"word":"assisterai","word_nosc":"assisterai","lemma":"assister","pos":"VER"} ,
		{"word":"assisteraient","word_nosc":"assisteraient","lemma":"assister","pos":"VER"} ,
		{"word":"assisterais","word_nosc":"assisterais","lemma":"assister","pos":"VER"} ,
		{"word":"assisterait","word_nosc":"assisterait","lemma":"assister","pos":"VER"} ,
		{"word":"assisteras","word_nosc":"assisteras","lemma":"assister","pos":"VER"} ,
		{"word":"assisterez","word_nosc":"assisterez","lemma":"assister","pos":"VER"} ,
		{"word":"assisteriez","word_nosc":"assisteriez","lemma":"assister","pos":"VER"} ,
		{"word":"assisterons","word_nosc":"assisterons","lemma":"assister","pos":"VER"} ,
		{"word":"assisteront","word_nosc":"assisteront","lemma":"assister","pos":"VER"} ,
		{"word":"assistez","word_nosc":"assistez","lemma":"assister","pos":"VER"} ,
		{"word":"assistiez","word_nosc":"assistiez","lemma":"assister","pos":"VER"} ,
		{"word":"assistions","word_nosc":"assistions","lemma":"assister","pos":"VER"} ,
		{"word":"assistons","word_nosc":"assistons","lemma":"assister","pos":"VER"} ,
		{"word":"assistâmes","word_nosc":"assistames","lemma":"assister","pos":"VER"} ,
		{"word":"assistât","word_nosc":"assistat","lemma":"assister","pos":"VER"} ,
		{"word":"assistèrent","word_nosc":"assisterent","lemma":"assister","pos":"VER"} ,
		{"word":"assisté","word_nosc":"assiste","lemma":"assister","pos":"VER"} ,
		{"word":"assistée","word_nosc":"assistee","lemma":"assister","pos":"VER"} ,
		{"word":"assistées","word_nosc":"assistees","lemma":"assister","pos":"VER"} ,
		{"word":"assistés","word_nosc":"assistes","lemma":"assister","pos":"VER"} ,
		{"word":"assit","word_nosc":"assit","lemma":"asseoir","pos":"VER"} ,
		{"word":"assiège","word_nosc":"assiege","lemma":"assiéger","pos":"VER"} ,
		{"word":"assiègent","word_nosc":"assiegent","lemma":"assiéger","pos":"VER"} ,
		{"word":"assiégea","word_nosc":"assiegea","lemma":"assiéger","pos":"VER"} ,
		{"word":"assiégeaient","word_nosc":"assiegeaient","lemma":"assiéger","pos":"VER"} ,
		{"word":"assiégeait","word_nosc":"assiegeait","lemma":"assiéger","pos":"VER"} ,
		{"word":"assiégeant","word_nosc":"assiegeant","lemma":"assiéger","pos":"VER"} ,
		{"word":"assiéger","word_nosc":"assieger","lemma":"assiéger","pos":"VER"} ,
		{"word":"assiégera","word_nosc":"assiegera","lemma":"assiéger","pos":"VER"} ,
		{"word":"assiégeraient","word_nosc":"assiegeraient","lemma":"assiéger","pos":"VER"} ,
		{"word":"assiégé","word_nosc":"assiege","lemma":"assiéger","pos":"VER"} ,
		{"word":"assiégée","word_nosc":"assiegee","lemma":"assiéger","pos":"VER"} ,
		{"word":"assiégées","word_nosc":"assiegees","lemma":"assiéger","pos":"VER"} ,
		{"word":"assiégés","word_nosc":"assieges","lemma":"assiéger","pos":"VER"} ,
		{"word":"assiéra","word_nosc":"assiera","lemma":"asseoir","pos":"VER"} ,
		{"word":"assiérai","word_nosc":"assierai","lemma":"asseoir","pos":"VER"} ,
		{"word":"assiérais","word_nosc":"assierais","lemma":"asseoir","pos":"VER"} ,
		{"word":"assiérait","word_nosc":"assierait","lemma":"asseoir","pos":"VER"} ,
		{"word":"assiéras","word_nosc":"assieras","lemma":"asseoir","pos":"VER"} ,
		{"word":"assiérions","word_nosc":"assierions","lemma":"asseoir","pos":"VER"} ,
		{"word":"assiéront","word_nosc":"assieront","lemma":"asseoir","pos":"VER"} ,
		{"word":"associa","word_nosc":"associa","lemma":"associer","pos":"VER"} ,
		{"word":"associai","word_nosc":"associai","lemma":"associer","pos":"VER"} ,
		{"word":"associaient","word_nosc":"associaient","lemma":"associer","pos":"VER"} ,
		{"word":"associais","word_nosc":"associais","lemma":"associer","pos":"VER"} ,
		{"word":"associait","word_nosc":"associait","lemma":"associer","pos":"VER"} ,
		{"word":"associant","word_nosc":"associant","lemma":"associer","pos":"VER"} ,
		{"word":"associassent","word_nosc":"associassent","lemma":"associer","pos":"VER"} ,
		{"word":"associe","word_nosc":"associe","lemma":"associer","pos":"VER"} ,
		{"word":"associent","word_nosc":"associent","lemma":"associer","pos":"VER"} ,
		{"word":"associer","word_nosc":"associer","lemma":"associer","pos":"VER"} ,
		{"word":"associera","word_nosc":"associera","lemma":"associer","pos":"VER"} ,
		{"word":"associerai","word_nosc":"associerai","lemma":"associer","pos":"VER"} ,
		{"word":"associeraient","word_nosc":"associeraient","lemma":"associer","pos":"VER"} ,
		{"word":"associerais","word_nosc":"associerais","lemma":"associer","pos":"VER"} ,
		{"word":"associerait","word_nosc":"associerait","lemma":"associer","pos":"VER"} ,
		{"word":"associeront","word_nosc":"associeront","lemma":"associer","pos":"VER"} ,
		{"word":"associez","word_nosc":"associez","lemma":"associer","pos":"VER"} ,
		{"word":"associions","word_nosc":"associions","lemma":"associer","pos":"VER"} ,
		{"word":"associons","word_nosc":"associons","lemma":"associer","pos":"VER"} ,
		{"word":"associât","word_nosc":"associat","lemma":"associer","pos":"VER"} ,
		{"word":"associèrent","word_nosc":"associerent","lemma":"associer","pos":"VER"} ,
		{"word":"associé","word_nosc":"associe","lemma":"associer","pos":"VER"} ,
		{"word":"associée","word_nosc":"associee","lemma":"associer","pos":"VER"} ,
		{"word":"associées","word_nosc":"associees","lemma":"associer","pos":"VER"} ,
		{"word":"associés","word_nosc":"associes","lemma":"associer","pos":"VER"} ,
		{"word":"assoie","word_nosc":"assoie","lemma":"asseoir","pos":"VER"} ,
		{"word":"assoient","word_nosc":"assoient","lemma":"asseoir","pos":"VER"} ,
		{"word":"assoies","word_nosc":"assoies","lemma":"asseoir","pos":"VER"} ,
		{"word":"assoiffent","word_nosc":"assoiffent","lemma":"assoiffer","pos":"VER"} ,
		{"word":"assoiffer","word_nosc":"assoiffer","lemma":"assoiffer","pos":"VER"} ,
		{"word":"assoiffez","word_nosc":"assoiffez","lemma":"assoiffer","pos":"VER"} ,
		{"word":"assoiffé","word_nosc":"assoiffe","lemma":"assoiffer","pos":"VER"} ,
		{"word":"assoiffée","word_nosc":"assoiffee","lemma":"assoiffer","pos":"VER"} ,
		{"word":"assoiffées","word_nosc":"assoiffees","lemma":"assoiffer","pos":"VER"} ,
		{"word":"assoiffés","word_nosc":"assoiffes","lemma":"assoiffer","pos":"VER"} ,
		{"word":"assoira","word_nosc":"assoira","lemma":"asseoir","pos":"VER"} ,
		{"word":"assoirai","word_nosc":"assoirai","lemma":"asseoir","pos":"VER"} ,
		{"word":"assoiraient","word_nosc":"assoiraient","lemma":"asseoir","pos":"VER"} ,
		{"word":"assoirais","word_nosc":"assoirais","lemma":"asseoir","pos":"VER"} ,
		{"word":"assoirait","word_nosc":"assoirait","lemma":"asseoir","pos":"VER"} ,
		{"word":"assoiras","word_nosc":"assoiras","lemma":"asseoir","pos":"VER"} ,
		{"word":"assoiront","word_nosc":"assoiront","lemma":"asseoir","pos":"VER"} ,
		{"word":"assois","word_nosc":"assois","lemma":"asseoir","pos":"VER"} ,
		{"word":"assoit","word_nosc":"assoit","lemma":"asseoir","pos":"VER"} ,
		{"word":"assombri","word_nosc":"assombri","lemma":"assombrir","pos":"VER"} ,
		{"word":"assombrie","word_nosc":"assombrie","lemma":"assombrir","pos":"VER"} ,
		{"word":"assombries","word_nosc":"assombries","lemma":"assombrir","pos":"VER"} ,
		{"word":"assombrir","word_nosc":"assombrir","lemma":"assombrir","pos":"VER"} ,
		{"word":"assombrira","word_nosc":"assombrira","lemma":"assombrir","pos":"VER"} ,
		{"word":"assombrirent","word_nosc":"assombrirent","lemma":"assombrir","pos":"VER"} ,
		{"word":"assombris","word_nosc":"assombris","lemma":"assombrir","pos":"VER"} ,
		{"word":"assombrissaient","word_nosc":"assombrissaient","lemma":"assombrir","pos":"VER"} ,
		{"word":"assombrissait","word_nosc":"assombrissait","lemma":"assombrir","pos":"VER"} ,
		{"word":"assombrissant","word_nosc":"assombrissant","lemma":"assombrir","pos":"VER"} ,
		{"word":"assombrissent","word_nosc":"assombrissent","lemma":"assombrir","pos":"VER"} ,
		{"word":"assombrit","word_nosc":"assombrit","lemma":"assombrir","pos":"VER"} ,
		{"word":"assombrît","word_nosc":"assombrit","lemma":"assombrir","pos":"VER"} ,
		{"word":"assomma","word_nosc":"assomma","lemma":"assommer","pos":"VER"} ,
		{"word":"assommaient","word_nosc":"assommaient","lemma":"assommer","pos":"VER"} ,
		{"word":"assommait","word_nosc":"assommait","lemma":"assommer","pos":"VER"} ,
		{"word":"assommant","word_nosc":"assommant","lemma":"assommer","pos":"VER"} ,
		{"word":"assomme","word_nosc":"assomme","lemma":"assommer","pos":"VER"} ,
		{"word":"assomment","word_nosc":"assomment","lemma":"assommer","pos":"VER"} ,
		{"word":"assommer","word_nosc":"assommer","lemma":"assommer","pos":"VER"} ,
		{"word":"assommera","word_nosc":"assommera","lemma":"assommer","pos":"VER"} ,
		{"word":"assommerai","word_nosc":"assommerai","lemma":"assommer","pos":"VER"} ,
		{"word":"assommeraient","word_nosc":"assommeraient","lemma":"assommer","pos":"VER"} ,
		{"word":"assommerais","word_nosc":"assommerais","lemma":"assommer","pos":"VER"} ,
		{"word":"assommerait","word_nosc":"assommerait","lemma":"assommer","pos":"VER"} ,
		{"word":"assommeras","word_nosc":"assommeras","lemma":"assommer","pos":"VER"} ,
		{"word":"assommes","word_nosc":"assommes","lemma":"assommer","pos":"VER"} ,
		{"word":"assommez","word_nosc":"assommez","lemma":"assommer","pos":"VER"} ,
		{"word":"assommiez","word_nosc":"assommiez","lemma":"assommer","pos":"VER"} ,
		{"word":"assommons","word_nosc":"assommons","lemma":"assommer","pos":"VER"} ,
		{"word":"assommât","word_nosc":"assommat","lemma":"assommer","pos":"VER"} ,
		{"word":"assommé","word_nosc":"assomme","lemma":"assommer","pos":"VER"} ,
		{"word":"assommée","word_nosc":"assommee","lemma":"assommer","pos":"VER"} ,
		{"word":"assommées","word_nosc":"assommees","lemma":"assommer","pos":"VER"} ,
		{"word":"assommés","word_nosc":"assommes","lemma":"assommer","pos":"VER"} ,
		{"word":"assorti","word_nosc":"assorti","lemma":"assortir","pos":"VER"} ,
		{"word":"assortie","word_nosc":"assortie","lemma":"assortir","pos":"VER"} ,
		{"word":"assorties","word_nosc":"assorties","lemma":"assortir","pos":"VER"} ,
		{"word":"assortir","word_nosc":"assortir","lemma":"assortir","pos":"VER"} ,
		{"word":"assortirai","word_nosc":"assortirai","lemma":"assortir","pos":"VER"} ,
		{"word":"assortirais","word_nosc":"assortirais","lemma":"assortir","pos":"VER"} ,
		{"word":"assortis","word_nosc":"assortis","lemma":"assortir","pos":"VER"} ,
		{"word":"assortissaient","word_nosc":"assortissaient","lemma":"assortir","pos":"VER"} ,
		{"word":"assortissais","word_nosc":"assortissais","lemma":"assortir","pos":"VER"} ,
		{"word":"assortissait","word_nosc":"assortissait","lemma":"assortir","pos":"VER"} ,
		{"word":"assortissant","word_nosc":"assortissant","lemma":"assortir","pos":"VER"} ,
		{"word":"assortissent","word_nosc":"assortissent","lemma":"assortir","pos":"VER"} ,
		{"word":"assortit","word_nosc":"assortit","lemma":"assortir","pos":"VER"} ,
		{"word":"assoté","word_nosc":"assote","lemma":"assoter","pos":"VER"} ,
		{"word":"assotés","word_nosc":"assotes","lemma":"assoter","pos":"VER"} ,
		{"word":"assoupi","word_nosc":"assoupi","lemma":"assoupir","pos":"VER"} ,
		{"word":"assoupie","word_nosc":"assoupie","lemma":"assoupir","pos":"VER"} ,
		{"word":"assoupies","word_nosc":"assoupies","lemma":"assoupir","pos":"VER"} ,
		{"word":"assoupir","word_nosc":"assoupir","lemma":"assoupir","pos":"VER"} ,
		{"word":"assoupira","word_nosc":"assoupira","lemma":"assoupir","pos":"VER"} ,
		{"word":"assoupirais","word_nosc":"assoupirais","lemma":"assoupir","pos":"VER"} ,
		{"word":"assoupis","word_nosc":"assoupis","lemma":"assoupir","pos":"VER"} ,
		{"word":"assoupissaient","word_nosc":"assoupissaient","lemma":"assoupir","pos":"VER"} ,
		{"word":"assoupissais","word_nosc":"assoupissais","lemma":"assoupir","pos":"VER"} ,
		{"word":"assoupissait","word_nosc":"assoupissait","lemma":"assoupir","pos":"VER"} ,
		{"word":"assoupissant","word_nosc":"assoupissant","lemma":"assoupir","pos":"VER"} ,
		{"word":"assoupisse","word_nosc":"assoupisse","lemma":"assoupir","pos":"VER"} ,
		{"word":"assoupissent","word_nosc":"assoupissent","lemma":"assoupir","pos":"VER"} ,
		{"word":"assoupit","word_nosc":"assoupit","lemma":"assoupir","pos":"VER"} ,
		{"word":"assoupli","word_nosc":"assoupli","lemma":"assouplir","pos":"VER"} ,
		{"word":"assouplie","word_nosc":"assouplie","lemma":"assouplir","pos":"VER"} ,
		{"word":"assouplies","word_nosc":"assouplies","lemma":"assouplir","pos":"VER"} ,
		{"word":"assouplir","word_nosc":"assouplir","lemma":"assouplir","pos":"VER"} ,
		{"word":"assouplira","word_nosc":"assouplira","lemma":"assouplir","pos":"VER"} ,
		{"word":"assouplis","word_nosc":"assouplis","lemma":"assouplir","pos":"VER"} ,
		{"word":"assouplissaient","word_nosc":"assouplissaient","lemma":"assouplir","pos":"VER"} ,
		{"word":"assouplissait","word_nosc":"assouplissait","lemma":"assouplir","pos":"VER"} ,
		{"word":"assouplissent","word_nosc":"assouplissent","lemma":"assouplir","pos":"VER"} ,
		{"word":"assouplit","word_nosc":"assouplit","lemma":"assouplir","pos":"VER"} ,
		{"word":"assourdi","word_nosc":"assourdi","lemma":"assourdir","pos":"VER"} ,
		{"word":"assourdie","word_nosc":"assourdie","lemma":"assourdir","pos":"VER"} ,
		{"word":"assourdies","word_nosc":"assourdies","lemma":"assourdir","pos":"VER"} ,
		{"word":"assourdir","word_nosc":"assourdir","lemma":"assourdir","pos":"VER"} ,
		{"word":"assourdiraient","word_nosc":"assourdiraient","lemma":"assourdir","pos":"VER"} ,
		{"word":"assourdiras","word_nosc":"assourdiras","lemma":"assourdir","pos":"VER"} ,
		{"word":"assourdirent","word_nosc":"assourdirent","lemma":"assourdir","pos":"VER"} ,
		{"word":"assourdis","word_nosc":"assourdis","lemma":"assourdir","pos":"VER"} ,
		{"word":"assourdissaient","word_nosc":"assourdissaient","lemma":"assourdir","pos":"VER"} ,
		{"word":"assourdissait","word_nosc":"assourdissait","lemma":"assourdir","pos":"VER"} ,
		{"word":"assourdissant","word_nosc":"assourdissant","lemma":"assourdir","pos":"VER"} ,
		{"word":"assourdissent","word_nosc":"assourdissent","lemma":"assourdir","pos":"VER"} ,
		{"word":"assourdit","word_nosc":"assourdit","lemma":"assourdir","pos":"VER"} ,
		{"word":"assouvi","word_nosc":"assouvi","lemma":"assouvir","pos":"VER"} ,
		{"word":"assouvie","word_nosc":"assouvie","lemma":"assouvir","pos":"VER"} ,
		{"word":"assouvies","word_nosc":"assouvies","lemma":"assouvir","pos":"VER"} ,
		{"word":"assouvir","word_nosc":"assouvir","lemma":"assouvir","pos":"VER"} ,
		{"word":"assouvira","word_nosc":"assouvira","lemma":"assouvir","pos":"VER"} ,
		{"word":"assouvirai","word_nosc":"assouvirai","lemma":"assouvir","pos":"VER"} ,
		{"word":"assouvirait","word_nosc":"assouvirait","lemma":"assouvir","pos":"VER"} ,
		{"word":"assouviras","word_nosc":"assouviras","lemma":"assouvir","pos":"VER"} ,
		{"word":"assouvirent","word_nosc":"assouvirent","lemma":"assouvir","pos":"VER"} ,
		{"word":"assouvis","word_nosc":"assouvis","lemma":"assouvir","pos":"VER"} ,
		{"word":"assouvissaient","word_nosc":"assouvissaient","lemma":"assouvir","pos":"VER"} ,
		{"word":"assouvissait","word_nosc":"assouvissait","lemma":"assouvir","pos":"VER"} ,
		{"word":"assouvissant","word_nosc":"assouvissant","lemma":"assouvir","pos":"VER"} ,
		{"word":"assouvisse","word_nosc":"assouvisse","lemma":"assouvir","pos":"VER"} ,
		{"word":"assouvissent","word_nosc":"assouvissent","lemma":"assouvir","pos":"VER"} ,
		{"word":"assouvit","word_nosc":"assouvit","lemma":"assouvir","pos":"VER"} ,
		{"word":"assoyait","word_nosc":"assoyait","lemma":"asseoir","pos":"VER"} ,
		{"word":"assoyez","word_nosc":"assoyez","lemma":"asseoir","pos":"VER"} ,
		{"word":"assoyons","word_nosc":"assoyons","lemma":"asseoir","pos":"VER"} ,
		{"word":"assujetti","word_nosc":"assujetti","lemma":"assujettir","pos":"VER"} ,
		{"word":"assujettie","word_nosc":"assujettie","lemma":"assujettir","pos":"VER"} ,
		{"word":"assujetties","word_nosc":"assujetties","lemma":"assujettir","pos":"VER"} ,
		{"word":"assujettir","word_nosc":"assujettir","lemma":"assujettir","pos":"VER"} ,
		{"word":"assujettiraient","word_nosc":"assujettiraient","lemma":"assujettir","pos":"VER"} ,
		{"word":"assujettis","word_nosc":"assujettis","lemma":"assujettir","pos":"VER"} ,
		{"word":"assujettissaient","word_nosc":"assujettissaient","lemma":"assujettir","pos":"VER"} ,
		{"word":"assujettissait","word_nosc":"assujettissait","lemma":"assujettir","pos":"VER"} ,
		{"word":"assujettissant","word_nosc":"assujettissant","lemma":"assujettir","pos":"VER"} ,
		{"word":"assujettisse","word_nosc":"assujettisse","lemma":"assujettir","pos":"VER"} ,
		{"word":"assujettissent","word_nosc":"assujettissent","lemma":"assujettir","pos":"VER"} ,
		{"word":"assujettit","word_nosc":"assujettit","lemma":"assujettir","pos":"VER"} ,
		{"word":"assuma","word_nosc":"assuma","lemma":"assumer","pos":"VER"} ,
		{"word":"assumai","word_nosc":"assumai","lemma":"assumer","pos":"VER"} ,
		{"word":"assumaient","word_nosc":"assumaient","lemma":"assumer","pos":"VER"} ,
		{"word":"assumais","word_nosc":"assumais","lemma":"assumer","pos":"VER"} ,
		{"word":"assumait","word_nosc":"assumait","lemma":"assumer","pos":"VER"} ,
		{"word":"assumant","word_nosc":"assumant","lemma":"assumer","pos":"VER"} ,
		{"word":"assume","word_nosc":"assume","lemma":"assumer","pos":"VER"} ,
		{"word":"assument","word_nosc":"assument","lemma":"assumer","pos":"VER"} ,
		{"word":"assumer","word_nosc":"assumer","lemma":"assumer","pos":"VER"} ,
		{"word":"assumera","word_nosc":"assumera","lemma":"assumer","pos":"VER"} ,
		{"word":"assumerai","word_nosc":"assumerai","lemma":"assumer","pos":"VER"} ,
		{"word":"assumeraient","word_nosc":"assumeraient","lemma":"assumer","pos":"VER"} ,
		{"word":"assumerais","word_nosc":"assumerais","lemma":"assumer","pos":"VER"} ,
		{"word":"assumerait","word_nosc":"assumerait","lemma":"assumer","pos":"VER"} ,
		{"word":"assumeras","word_nosc":"assumeras","lemma":"assumer","pos":"VER"} ,
		{"word":"assumerez","word_nosc":"assumerez","lemma":"assumer","pos":"VER"} ,
		{"word":"assumerions","word_nosc":"assumerions","lemma":"assumer","pos":"VER"} ,
		{"word":"assumerons","word_nosc":"assumerons","lemma":"assumer","pos":"VER"} ,
		{"word":"assumez","word_nosc":"assumez","lemma":"assumer","pos":"VER"} ,
		{"word":"assumions","word_nosc":"assumions","lemma":"assumer","pos":"VER"} ,
		{"word":"assumons","word_nosc":"assumons","lemma":"assumer","pos":"VER"} ,
		{"word":"assumât","word_nosc":"assumat","lemma":"assumer","pos":"VER"} ,
		{"word":"assumèrent","word_nosc":"assumerent","lemma":"assumer","pos":"VER"} ,
		{"word":"assumé","word_nosc":"assume","lemma":"assumer","pos":"VER"} ,
		{"word":"assumée","word_nosc":"assumee","lemma":"assumer","pos":"VER"} ,
		{"word":"assumées","word_nosc":"assumees","lemma":"assumer","pos":"VER"} ,
		{"word":"assumés","word_nosc":"assumes","lemma":"assumer","pos":"VER"} ,
		{"word":"assura","word_nosc":"assura","lemma":"assurer","pos":"VER"} ,
		{"word":"assurai","word_nosc":"assurai","lemma":"assurer","pos":"VER"} ,
		{"word":"assuraient","word_nosc":"assuraient","lemma":"assurer","pos":"VER"} ,
		{"word":"assurais","word_nosc":"assurais","lemma":"assurer","pos":"VER"} ,
		{"word":"assurait","word_nosc":"assurait","lemma":"assurer","pos":"VER"} ,
		{"word":"assurant","word_nosc":"assurant","lemma":"assurer","pos":"VER"} ,
		{"word":"assurassent","word_nosc":"assurassent","lemma":"assurer","pos":"VER"} ,
		{"word":"assure","word_nosc":"assure","lemma":"assurer","pos":"VER"} ,
		{"word":"assurent","word_nosc":"assurent","lemma":"assurer","pos":"VER"} ,
		{"word":"assurer","word_nosc":"assurer","lemma":"assurer","pos":"VER"} ,
		{"word":"assurera","word_nosc":"assurera","lemma":"assurer","pos":"VER"} ,
		{"word":"assurerai","word_nosc":"assurerai","lemma":"assurer","pos":"VER"} ,
		{"word":"assureraient","word_nosc":"assureraient","lemma":"assurer","pos":"VER"} ,
		{"word":"assurerais","word_nosc":"assurerais","lemma":"assurer","pos":"VER"} ,
		{"word":"assurerait","word_nosc":"assurerait","lemma":"assurer","pos":"VER"} ,
		{"word":"assureras","word_nosc":"assureras","lemma":"assurer","pos":"VER"} ,
		{"word":"assurerez","word_nosc":"assurerez","lemma":"assurer","pos":"VER"} ,
		{"word":"assurerons","word_nosc":"assurerons","lemma":"assurer","pos":"VER"} ,
		{"word":"assureront","word_nosc":"assureront","lemma":"assurer","pos":"VER"} ,
		{"word":"assures","word_nosc":"assures","lemma":"assurer","pos":"VER"} ,
		{"word":"assurez","word_nosc":"assurez","lemma":"assurer","pos":"VER"} ,
		{"word":"assuriez","word_nosc":"assuriez","lemma":"assurer","pos":"VER"} ,
		{"word":"assurions","word_nosc":"assurions","lemma":"assurer","pos":"VER"} ,
		{"word":"assurons","word_nosc":"assurons","lemma":"assurer","pos":"VER"} ,
		{"word":"assurât","word_nosc":"assurat","lemma":"assurer","pos":"VER"} ,
		{"word":"assurèrent","word_nosc":"assurerent","lemma":"assurer","pos":"VER"} ,
		{"word":"assuré","word_nosc":"assure","lemma":"assurer","pos":"VER"} ,
		{"word":"assurée","word_nosc":"assuree","lemma":"assurer","pos":"VER"} ,
		{"word":"assurées","word_nosc":"assurees","lemma":"assurer","pos":"VER"} ,
		{"word":"assurés","word_nosc":"assures","lemma":"assurer","pos":"VER"} ,
		{"word":"assèche","word_nosc":"asseche","lemma":"assécher","pos":"VER"} ,
		{"word":"assèchent","word_nosc":"assechent","lemma":"assécher","pos":"VER"} ,
		{"word":"assène","word_nosc":"assene","lemma":"assener","pos":"VER"} ,
		{"word":"assènent","word_nosc":"assenent","lemma":"assener","pos":"VER"} ,
		{"word":"assènerai","word_nosc":"assenerai","lemma":"assener","pos":"VER"} ,
		{"word":"assécha","word_nosc":"assecha","lemma":"assécher","pos":"VER"} ,
		{"word":"asséchaient","word_nosc":"assechaient","lemma":"assécher","pos":"VER"} ,
		{"word":"asséchais","word_nosc":"assechais","lemma":"assécher","pos":"VER"} ,
		{"word":"asséchait","word_nosc":"assechait","lemma":"assécher","pos":"VER"} ,
		{"word":"asséchant","word_nosc":"assechant","lemma":"assécher","pos":"VER"} ,
		{"word":"assécher","word_nosc":"assecher","lemma":"assécher","pos":"VER"} ,
		{"word":"assécherait","word_nosc":"assecherait","lemma":"assécher","pos":"VER"} ,
		{"word":"asséchez","word_nosc":"assechez","lemma":"assécher","pos":"VER"} ,
		{"word":"asséchons","word_nosc":"assechons","lemma":"assécher","pos":"VER"} ,
		{"word":"asséché","word_nosc":"asseche","lemma":"assécher","pos":"VER"} ,
		{"word":"asséchée","word_nosc":"assechee","lemma":"assécher","pos":"VER"} ,
		{"word":"asséchées","word_nosc":"assechees","lemma":"assécher","pos":"VER"} ,
		{"word":"asséchés","word_nosc":"asseches","lemma":"assécher","pos":"VER"} ,
		{"word":"asséna","word_nosc":"assena","lemma":"asséner","pos":"VER"} ,
		{"word":"assénait","word_nosc":"assenait","lemma":"asséner","pos":"VER"} ,
		{"word":"asséner","word_nosc":"assener","lemma":"asséner","pos":"VER"} ,
		{"word":"asséné","word_nosc":"assene","lemma":"asséner","pos":"VER"} ,
		{"word":"assénées","word_nosc":"assenees","lemma":"asséner","pos":"VER"} ,
		{"word":"assîmes","word_nosc":"assimes","lemma":"asseoir","pos":"VER"} ,
		{"word":"assît","word_nosc":"assit","lemma":"asseoir","pos":"VER"} ,
		{"word":"asticota","word_nosc":"asticota","lemma":"asticoter","pos":"VER"} ,
		{"word":"asticotaient","word_nosc":"asticotaient","lemma":"asticoter","pos":"VER"} ,
		{"word":"asticotais","word_nosc":"asticotais","lemma":"asticoter","pos":"VER"} ,
		{"word":"asticotait","word_nosc":"asticotait","lemma":"asticoter","pos":"VER"} ,
		{"word":"asticotant","word_nosc":"asticotant","lemma":"asticoter","pos":"VER"} ,
		{"word":"asticote","word_nosc":"asticote","lemma":"asticoter","pos":"VER"} ,
		{"word":"asticotent","word_nosc":"asticotent","lemma":"asticoter","pos":"VER"} ,
		{"word":"asticoter","word_nosc":"asticoter","lemma":"asticoter","pos":"VER"} ,
		{"word":"asticotez","word_nosc":"asticotez","lemma":"asticoter","pos":"VER"} ,
		{"word":"asticoté","word_nosc":"asticote","lemma":"asticoter","pos":"VER"} ,
		{"word":"asticotés","word_nosc":"asticotes","lemma":"asticoter","pos":"VER"} ,
		{"word":"astiqua","word_nosc":"astiqua","lemma":"astiquer","pos":"VER"} ,
		{"word":"astiquaient","word_nosc":"astiquaient","lemma":"astiquer","pos":"VER"} ,
		{"word":"astiquais","word_nosc":"astiquais","lemma":"astiquer","pos":"VER"} ,
		{"word":"astiquait","word_nosc":"astiquait","lemma":"astiquer","pos":"VER"} ,
		{"word":"astiquant","word_nosc":"astiquant","lemma":"astiquer","pos":"VER"} ,
		{"word":"astique","word_nosc":"astique","lemma":"astiquer","pos":"VER"} ,
		{"word":"astiquent","word_nosc":"astiquent","lemma":"astiquer","pos":"VER"} ,
		{"word":"astiquer","word_nosc":"astiquer","lemma":"astiquer","pos":"VER"} ,
		{"word":"astiquera","word_nosc":"astiquera","lemma":"astiquer","pos":"VER"} ,
		{"word":"astiquerai","word_nosc":"astiquerai","lemma":"astiquer","pos":"VER"} ,
		{"word":"astiquerons","word_nosc":"astiquerons","lemma":"astiquer","pos":"VER"} ,
		{"word":"astiquez","word_nosc":"astiquez","lemma":"astiquer","pos":"VER"} ,
		{"word":"astiqué","word_nosc":"astique","lemma":"astiquer","pos":"VER"} ,
		{"word":"astiquée","word_nosc":"astiquee","lemma":"astiquer","pos":"VER"} ,
		{"word":"astiquées","word_nosc":"astiquees","lemma":"astiquer","pos":"VER"} ,
		{"word":"astiqués","word_nosc":"astiques","lemma":"astiquer","pos":"VER"} ,
		{"word":"astreignais","word_nosc":"astreignais","lemma":"astreindre","pos":"VER"} ,
		{"word":"astreignait","word_nosc":"astreignait","lemma":"astreindre","pos":"VER"} ,
		{"word":"astreignant","word_nosc":"astreignant","lemma":"astreindre","pos":"VER"} ,
		{"word":"astreigne","word_nosc":"astreigne","lemma":"astreindre","pos":"VER"} ,
		{"word":"astreignions","word_nosc":"astreignions","lemma":"astreindre","pos":"VER"} ,
		{"word":"astreignis","word_nosc":"astreignis","lemma":"astreindre","pos":"VER"} ,
		{"word":"astreignit","word_nosc":"astreignit","lemma":"astreindre","pos":"VER"} ,
		{"word":"astreindre","word_nosc":"astreindre","lemma":"astreindre","pos":"VER"} ,
		{"word":"astreins","word_nosc":"astreins","lemma":"astreindre","pos":"VER"} ,
		{"word":"astreint","word_nosc":"astreint","lemma":"astreindre","pos":"VER"} ,
		{"word":"astreinte","word_nosc":"astreinte","lemma":"astreindre","pos":"VER"} ,
		{"word":"astreints","word_nosc":"astreints","lemma":"astreindre","pos":"VER"} ,
		{"word":"atermoyé","word_nosc":"atermoye","lemma":"atermoyer","pos":"VER"} ,
		{"word":"atomisait","word_nosc":"atomisait","lemma":"atomiser","pos":"VER"} ,
		{"word":"atomise","word_nosc":"atomise","lemma":"atomiser","pos":"VER"} ,
		{"word":"atomisent","word_nosc":"atomisent","lemma":"atomiser","pos":"VER"} ,
		{"word":"atomiser","word_nosc":"atomiser","lemma":"atomiser","pos":"VER"} ,
		{"word":"atomisez","word_nosc":"atomisez","lemma":"atomiser","pos":"VER"} ,
		{"word":"atomisé","word_nosc":"atomise","lemma":"atomiser","pos":"VER"} ,
		{"word":"atomisée","word_nosc":"atomisee","lemma":"atomiser","pos":"VER"} ,
		{"word":"atomisés","word_nosc":"atomises","lemma":"atomiser","pos":"VER"} ,
		{"word":"atourné","word_nosc":"atourne","lemma":"atourner","pos":"VER"} ,
		{"word":"atrophiait","word_nosc":"atrophiait","lemma":"atrophier","pos":"VER"} ,
		{"word":"atrophie","word_nosc":"atrophie","lemma":"atrophier","pos":"VER"} ,
		{"word":"atrophient","word_nosc":"atrophient","lemma":"atrophier","pos":"VER"} ,
		{"word":"atrophier","word_nosc":"atrophier","lemma":"atrophier","pos":"VER"} ,
		{"word":"atrophieraient","word_nosc":"atrophieraient","lemma":"atrophier","pos":"VER"} ,
		{"word":"atrophies","word_nosc":"atrophies","lemma":"atrophier","pos":"VER"} ,
		{"word":"atrophié","word_nosc":"atrophie","lemma":"atrophier","pos":"VER"} ,
		{"word":"atrophiée","word_nosc":"atrophiee","lemma":"atrophier","pos":"VER"} ,
		{"word":"atrophiés","word_nosc":"atrophies","lemma":"atrophier","pos":"VER"} ,
		{"word":"attabla","word_nosc":"attabla","lemma":"attabler","pos":"VER"} ,
		{"word":"attablai","word_nosc":"attablai","lemma":"attabler","pos":"VER"} ,
		{"word":"attablaient","word_nosc":"attablaient","lemma":"attabler","pos":"VER"} ,
		{"word":"attablais","word_nosc":"attablais","lemma":"attabler","pos":"VER"} ,
		{"word":"attablait","word_nosc":"attablait","lemma":"attabler","pos":"VER"} ,
		{"word":"attablant","word_nosc":"attablant","lemma":"attabler","pos":"VER"} ,
		{"word":"attable","word_nosc":"attable","lemma":"attabler","pos":"VER"} ,
		{"word":"attablent","word_nosc":"attablent","lemma":"attabler","pos":"VER"} ,
		{"word":"attabler","word_nosc":"attabler","lemma":"attabler","pos":"VER"} ,
		{"word":"attablions","word_nosc":"attablions","lemma":"attabler","pos":"VER"} ,
		{"word":"attablons","word_nosc":"attablons","lemma":"attabler","pos":"VER"} ,
		{"word":"attablèrent","word_nosc":"attablerent","lemma":"attabler","pos":"VER"} ,
		{"word":"attablé","word_nosc":"attable","lemma":"attabler","pos":"VER"} ,
		{"word":"attablée","word_nosc":"attablee","lemma":"attabler","pos":"VER"} ,
		{"word":"attablées","word_nosc":"attablees","lemma":"attabler","pos":"VER"} ,
		{"word":"attablés","word_nosc":"attables","lemma":"attabler","pos":"VER"} ,
		{"word":"attacha","word_nosc":"attacha","lemma":"attacher","pos":"VER"} ,
		{"word":"attachai","word_nosc":"attachai","lemma":"attacher","pos":"VER"} ,
		{"word":"attachaient","word_nosc":"attachaient","lemma":"attacher","pos":"VER"} ,
		{"word":"attachais","word_nosc":"attachais","lemma":"attacher","pos":"VER"} ,
		{"word":"attachait","word_nosc":"attachait","lemma":"attacher","pos":"VER"} ,
		{"word":"attachant","word_nosc":"attachant","lemma":"attacher","pos":"VER"} ,
		{"word":"attache","word_nosc":"attache","lemma":"attacher","pos":"VER"} ,
		{"word":"attachent","word_nosc":"attachent","lemma":"attacher","pos":"VER"} ,
		{"word":"attacher","word_nosc":"attacher","lemma":"attacher","pos":"VER"} ,
		{"word":"attachera","word_nosc":"attachera","lemma":"attacher","pos":"VER"} ,
		{"word":"attacherai","word_nosc":"attacherai","lemma":"attacher","pos":"VER"} ,
		{"word":"attacherais","word_nosc":"attacherais","lemma":"attacher","pos":"VER"} ,
		{"word":"attacherait","word_nosc":"attacherait","lemma":"attacher","pos":"VER"} ,
		{"word":"attacheras","word_nosc":"attacheras","lemma":"attacher","pos":"VER"} ,
		{"word":"attacherez","word_nosc":"attacherez","lemma":"attacher","pos":"VER"} ,
		{"word":"attacherions","word_nosc":"attacherions","lemma":"attacher","pos":"VER"} ,
		{"word":"attacheront","word_nosc":"attacheront","lemma":"attacher","pos":"VER"} ,
		{"word":"attaches","word_nosc":"attaches","lemma":"attacher","pos":"VER"} ,
		{"word":"attachez","word_nosc":"attachez","lemma":"attacher","pos":"VER"} ,
		{"word":"attachiez","word_nosc":"attachiez","lemma":"attacher","pos":"VER"} ,
		{"word":"attachions","word_nosc":"attachions","lemma":"attacher","pos":"VER"} ,
		{"word":"attachons","word_nosc":"attachons","lemma":"attacher","pos":"VER"} ,
		{"word":"attachât","word_nosc":"attachat","lemma":"attacher","pos":"VER"} ,
		{"word":"attachèrent","word_nosc":"attacherent","lemma":"attacher","pos":"VER"} ,
		{"word":"attaché","word_nosc":"attache","lemma":"attacher","pos":"VER"} ,
		{"word":"attachée","word_nosc":"attachee","lemma":"attacher","pos":"VER"} ,
		{"word":"attachées","word_nosc":"attachees","lemma":"attacher","pos":"VER"} ,
		{"word":"attachés","word_nosc":"attaches","lemma":"attacher","pos":"VER"} ,
		{"word":"attaqua","word_nosc":"attaqua","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquai","word_nosc":"attaquai","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquaient","word_nosc":"attaquaient","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquais","word_nosc":"attaquais","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquait","word_nosc":"attaquait","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquant","word_nosc":"attaquant","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaque","word_nosc":"attaque","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquent","word_nosc":"attaquent","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquer","word_nosc":"attaquer","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquera","word_nosc":"attaquera","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquerai","word_nosc":"attaquerai","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaqueraient","word_nosc":"attaqueraient","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquerais","word_nosc":"attaquerais","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquerait","word_nosc":"attaquerait","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaqueras","word_nosc":"attaqueras","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquerez","word_nosc":"attaquerez","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaqueriez","word_nosc":"attaqueriez","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquerions","word_nosc":"attaquerions","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquerons","word_nosc":"attaquerons","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaqueront","word_nosc":"attaqueront","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaques","word_nosc":"attaques","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquez","word_nosc":"attaquez","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquiez","word_nosc":"attaquiez","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquons","word_nosc":"attaquons","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquâmes","word_nosc":"attaquames","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquât","word_nosc":"attaquat","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquèrent","word_nosc":"attaquerent","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaqué","word_nosc":"attaque","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquée","word_nosc":"attaquee","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaquées","word_nosc":"attaquees","lemma":"attaquer","pos":"VER"} ,
		{"word":"attaqués","word_nosc":"attaques","lemma":"attaquer","pos":"VER"} ,
		{"word":"attarda","word_nosc":"attarda","lemma":"attarder","pos":"VER"} ,
		{"word":"attardai","word_nosc":"attardai","lemma":"attarder","pos":"VER"} ,
		{"word":"attardaient","word_nosc":"attardaient","lemma":"attarder","pos":"VER"} ,
		{"word":"attardais","word_nosc":"attardais","lemma":"attarder","pos":"VER"} ,
		{"word":"attardait","word_nosc":"attardait","lemma":"attarder","pos":"VER"} ,
		{"word":"attardant","word_nosc":"attardant","lemma":"attarder","pos":"VER"} ,
		{"word":"attarde","word_nosc":"attarde","lemma":"attarder","pos":"VER"} ,
		{"word":"attardent","word_nosc":"attardent","lemma":"attarder","pos":"VER"} ,
		{"word":"attarder","word_nosc":"attarder","lemma":"attarder","pos":"VER"} ,
		{"word":"attardera","word_nosc":"attardera","lemma":"attarder","pos":"VER"} ,
		{"word":"attarderai","word_nosc":"attarderai","lemma":"attarder","pos":"VER"} ,
		{"word":"attarderaient","word_nosc":"attarderaient","lemma":"attarder","pos":"VER"} ,
		{"word":"attarderait","word_nosc":"attarderait","lemma":"attarder","pos":"VER"} ,
		{"word":"attarderont","word_nosc":"attarderont","lemma":"attarder","pos":"VER"} ,
		{"word":"attardez","word_nosc":"attardez","lemma":"attarder","pos":"VER"} ,
		{"word":"attardions","word_nosc":"attardions","lemma":"attarder","pos":"VER"} ,
		{"word":"attardons","word_nosc":"attardons","lemma":"attarder","pos":"VER"} ,
		{"word":"attardâmes","word_nosc":"attardames","lemma":"attarder","pos":"VER"} ,
		{"word":"attardât","word_nosc":"attardat","lemma":"attarder","pos":"VER"} ,
		{"word":"attardèrent","word_nosc":"attarderent","lemma":"attarder","pos":"VER"} ,
		{"word":"attardé","word_nosc":"attarde","lemma":"attarder","pos":"VER"} ,
		{"word":"attardée","word_nosc":"attardee","lemma":"attarder","pos":"VER"} ,
		{"word":"attardées","word_nosc":"attardees","lemma":"attarder","pos":"VER"} ,
		{"word":"attardés","word_nosc":"attardes","lemma":"attarder","pos":"VER"} ,
		{"word":"atteignaient","word_nosc":"atteignaient","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteignais","word_nosc":"atteignais","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteignait","word_nosc":"atteignait","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteignant","word_nosc":"atteignant","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteigne","word_nosc":"atteigne","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteignent","word_nosc":"atteignent","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteignes","word_nosc":"atteignes","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteignez","word_nosc":"atteignez","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteignions","word_nosc":"atteignions","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteignirent","word_nosc":"atteignirent","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteignis","word_nosc":"atteignis","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteignissent","word_nosc":"atteignissent","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteignit","word_nosc":"atteignit","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteignons","word_nosc":"atteignons","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteignîmes","word_nosc":"atteignimes","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteignît","word_nosc":"atteignit","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteindra","word_nosc":"atteindra","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteindrai","word_nosc":"atteindrai","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteindraient","word_nosc":"atteindraient","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteindrais","word_nosc":"atteindrais","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteindrait","word_nosc":"atteindrait","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteindras","word_nosc":"atteindras","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteindre","word_nosc":"atteindre","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteindrez","word_nosc":"atteindrez","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteindrons","word_nosc":"atteindrons","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteindront","word_nosc":"atteindront","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteins","word_nosc":"atteins","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteint","word_nosc":"atteint","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteinte","word_nosc":"atteinte","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteintes","word_nosc":"atteintes","lemma":"atteindre","pos":"VER"} ,
		{"word":"atteints","word_nosc":"atteints","lemma":"atteindre","pos":"VER"} ,
		{"word":"attela","word_nosc":"attela","lemma":"atteler","pos":"VER"} ,
		{"word":"attelai","word_nosc":"attelai","lemma":"atteler","pos":"VER"} ,
		{"word":"attelaient","word_nosc":"attelaient","lemma":"atteler","pos":"VER"} ,
		{"word":"attelais","word_nosc":"attelais","lemma":"atteler","pos":"VER"} ,
		{"word":"attelait","word_nosc":"attelait","lemma":"atteler","pos":"VER"} ,
		{"word":"attelant","word_nosc":"attelant","lemma":"atteler","pos":"VER"} ,
		{"word":"atteler","word_nosc":"atteler","lemma":"atteler","pos":"VER"} ,
		{"word":"attelez","word_nosc":"attelez","lemma":"atteler","pos":"VER"} ,
		{"word":"attelions","word_nosc":"attelions","lemma":"atteler","pos":"VER"} ,
		{"word":"attelle","word_nosc":"attelle","lemma":"atteler","pos":"VER"} ,
		{"word":"attellent","word_nosc":"attellent","lemma":"atteler","pos":"VER"} ,
		{"word":"attellera","word_nosc":"attellera","lemma":"atteler","pos":"VER"} ,
		{"word":"attellerais","word_nosc":"attellerais","lemma":"atteler","pos":"VER"} ,
		{"word":"attelles","word_nosc":"attelles","lemma":"atteler","pos":"VER"} ,
		{"word":"attelons","word_nosc":"attelons","lemma":"atteler","pos":"VER"} ,
		{"word":"attelâmes","word_nosc":"attelames","lemma":"atteler","pos":"VER"} ,
		{"word":"attelèrent","word_nosc":"attelerent","lemma":"atteler","pos":"VER"} ,
		{"word":"attelé","word_nosc":"attele","lemma":"atteler","pos":"VER"} ,
		{"word":"attelée","word_nosc":"attelee","lemma":"atteler","pos":"VER"} ,
		{"word":"attelées","word_nosc":"attelees","lemma":"atteler","pos":"VER"} ,
		{"word":"attelés","word_nosc":"atteles","lemma":"atteler","pos":"VER"} ,
		{"word":"attend","word_nosc":"attend","lemma":"attendre","pos":"VER"} ,
		{"word":"attendaient","word_nosc":"attendaient","lemma":"attendre","pos":"VER"} ,
		{"word":"attendais","word_nosc":"attendais","lemma":"attendre","pos":"VER"} ,
		{"word":"attendait","word_nosc":"attendait","lemma":"attendre","pos":"VER"} ,
		{"word":"attendant","word_nosc":"attendant","lemma":"attendre","pos":"VER"} ,
		{"word":"attende","word_nosc":"attende","lemma":"attendre","pos":"VER"} ,
		{"word":"attendent","word_nosc":"attendent","lemma":"attendre","pos":"VER"} ,
		{"word":"attendes","word_nosc":"attendes","lemma":"attendre","pos":"VER"} ,
		{"word":"attendez","word_nosc":"attendez","lemma":"attendre","pos":"VER"} ,
		{"word":"attendiez","word_nosc":"attendiez","lemma":"attendre","pos":"VER"} ,
		{"word":"attendions","word_nosc":"attendions","lemma":"attendre","pos":"VER"} ,
		{"word":"attendirent","word_nosc":"attendirent","lemma":"attendre","pos":"VER"} ,
		{"word":"attendis","word_nosc":"attendis","lemma":"attendre","pos":"VER"} ,
		{"word":"attendisse","word_nosc":"attendisse","lemma":"attendre","pos":"VER"} ,
		{"word":"attendissent","word_nosc":"attendissent","lemma":"attendre","pos":"VER"} ,
		{"word":"attendit","word_nosc":"attendit","lemma":"attendre","pos":"VER"} ,
		{"word":"attendons","word_nosc":"attendons","lemma":"attendre","pos":"VER"} ,
		{"word":"attendra","word_nosc":"attendra","lemma":"attendre","pos":"VER"} ,
		{"word":"attendrai","word_nosc":"attendrai","lemma":"attendre","pos":"VER"} ,
		{"word":"attendraient","word_nosc":"attendraient","lemma":"attendre","pos":"VER"} ,
		{"word":"attendrais","word_nosc":"attendrais","lemma":"attendre","pos":"VER"} ,
		{"word":"attendrait","word_nosc":"attendrait","lemma":"attendre","pos":"VER"} ,
		{"word":"attendras","word_nosc":"attendras","lemma":"attendre","pos":"VER"} ,
		{"word":"attendre","word_nosc":"attendre","lemma":"attendre","pos":"VER"} ,
		{"word":"attendrez","word_nosc":"attendrez","lemma":"attendre","pos":"VER"} ,
		{"word":"attendri","word_nosc":"attendri","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendrie","word_nosc":"attendrie","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendries","word_nosc":"attendries","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendriez","word_nosc":"attendriez","lemma":"attendre","pos":"VER"} ,
		{"word":"attendrions","word_nosc":"attendrions","lemma":"attendre","pos":"VER"} ,
		{"word":"attendrir","word_nosc":"attendrir","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendrirait","word_nosc":"attendrirait","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendriras","word_nosc":"attendriras","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendrirent","word_nosc":"attendrirent","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendris","word_nosc":"attendris","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendrissaient","word_nosc":"attendrissaient","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendrissais","word_nosc":"attendrissais","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendrissait","word_nosc":"attendrissait","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendrissant","word_nosc":"attendrissant","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendrisse","word_nosc":"attendrisse","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendrissent","word_nosc":"attendrissent","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendrissons","word_nosc":"attendrissons","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendrit","word_nosc":"attendrit","lemma":"attendrir","pos":"VER"} ,
		{"word":"attendrons","word_nosc":"attendrons","lemma":"attendre","pos":"VER"} ,
		{"word":"attendront","word_nosc":"attendront","lemma":"attendre","pos":"VER"} ,
		{"word":"attends","word_nosc":"attends","lemma":"attendre","pos":"VER"} ,
		{"word":"attendu","word_nosc":"attendu","lemma":"attendre","pos":"VER"} ,
		{"word":"attendue","word_nosc":"attendue","lemma":"attendre","pos":"VER"} ,
		{"word":"attendues","word_nosc":"attendues","lemma":"attendre","pos":"VER"} ,
		{"word":"attendus","word_nosc":"attendus","lemma":"attendre","pos":"VER"} ,
		{"word":"attendîmes","word_nosc":"attendimes","lemma":"attendre","pos":"VER"} ,
		{"word":"attendît","word_nosc":"attendit","lemma":"attendre","pos":"VER"} ,
		{"word":"attenta","word_nosc":"attenta","lemma":"attenter","pos":"VER"} ,
		{"word":"attentais","word_nosc":"attentais","lemma":"attenter","pos":"VER"} ,
		{"word":"attentait","word_nosc":"attentait","lemma":"attenter","pos":"VER"} ,
		{"word":"attentant","word_nosc":"attentant","lemma":"attenter","pos":"VER"} ,
		{"word":"attente","word_nosc":"attente","lemma":"attenter","pos":"VER"} ,
		{"word":"attenter","word_nosc":"attenter","lemma":"attenter","pos":"VER"} ,
		{"word":"attenterai","word_nosc":"attenterai","lemma":"attenter","pos":"VER"} ,
		{"word":"attenterait","word_nosc":"attenterait","lemma":"attenter","pos":"VER"} ,
		{"word":"attentez","word_nosc":"attentez","lemma":"attenter","pos":"VER"} ,
		{"word":"attentiez","word_nosc":"attentiez","lemma":"attenter","pos":"VER"} ,
		{"word":"attentionné","word_nosc":"attentionne","lemma":"attentionner","pos":"VER"} ,
		{"word":"attentionnée","word_nosc":"attentionnee","lemma":"attentionner","pos":"VER"} ,
		{"word":"attentionnés","word_nosc":"attentionnes","lemma":"attentionner","pos":"VER"} ,
		{"word":"attenté","word_nosc":"attente","lemma":"attenter","pos":"VER"} ,
		{"word":"atterraient","word_nosc":"atterraient","lemma":"atterrer","pos":"VER"} ,
		{"word":"atterrait","word_nosc":"atterrait","lemma":"atterrer","pos":"VER"} ,
		{"word":"atterre","word_nosc":"atterre","lemma":"atterrer","pos":"VER"} ,
		{"word":"atterri","word_nosc":"atterri","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrir","word_nosc":"atterrir","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrira","word_nosc":"atterrira","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrirai","word_nosc":"atterrirai","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrirais","word_nosc":"atterrirais","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterriras","word_nosc":"atterriras","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrirent","word_nosc":"atterrirent","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrirez","word_nosc":"atterrirez","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrirons","word_nosc":"atterrirons","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterris","word_nosc":"atterris","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrissaient","word_nosc":"atterrissaient","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrissais","word_nosc":"atterrissais","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrissait","word_nosc":"atterrissait","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrissant","word_nosc":"atterrissant","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrisse","word_nosc":"atterrisse","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrissent","word_nosc":"atterrissent","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrisses","word_nosc":"atterrisses","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrissez","word_nosc":"atterrissez","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrissons","word_nosc":"atterrissons","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrit","word_nosc":"atterrit","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrèrent","word_nosc":"atterrerent","lemma":"atterrer","pos":"VER"} ,
		{"word":"atterré","word_nosc":"atterre","lemma":"atterrer","pos":"VER"} ,
		{"word":"atterrée","word_nosc":"atterree","lemma":"atterrer","pos":"VER"} ,
		{"word":"atterrées","word_nosc":"atterrees","lemma":"atterrer","pos":"VER"} ,
		{"word":"atterrés","word_nosc":"atterres","lemma":"atterrer","pos":"VER"} ,
		{"word":"atterrîmes","word_nosc":"atterrimes","lemma":"atterrir","pos":"VER"} ,
		{"word":"atterrît","word_nosc":"atterrit","lemma":"atterrir","pos":"VER"} ,
		{"word":"attesta","word_nosc":"attesta","lemma":"attester","pos":"VER"} ,
		{"word":"attestaient","word_nosc":"attestaient","lemma":"attester","pos":"VER"} ,
		{"word":"attestait","word_nosc":"attestait","lemma":"attester","pos":"VER"} ,
		{"word":"attestant","word_nosc":"attestant","lemma":"attester","pos":"VER"} ,
		{"word":"atteste","word_nosc":"atteste","lemma":"attester","pos":"VER"} ,
		{"word":"attestent","word_nosc":"attestent","lemma":"attester","pos":"VER"} ,
		{"word":"attester","word_nosc":"attester","lemma":"attester","pos":"VER"} ,
		{"word":"attestera","word_nosc":"attestera","lemma":"attester","pos":"VER"} ,
		{"word":"attestons","word_nosc":"attestons","lemma":"attester","pos":"VER"} ,
		{"word":"attestèrent","word_nosc":"attesterent","lemma":"attester","pos":"VER"} ,
		{"word":"attesté","word_nosc":"atteste","lemma":"attester","pos":"VER"} ,
		{"word":"attestée","word_nosc":"attestee","lemma":"attester","pos":"VER"} ,
		{"word":"attestées","word_nosc":"attestees","lemma":"attester","pos":"VER"} ,
		{"word":"attifa","word_nosc":"attifa","lemma":"attifer","pos":"VER"} ,
		{"word":"attifait","word_nosc":"attifait","lemma":"attifer","pos":"VER"} ,
		{"word":"attifant","word_nosc":"attifant","lemma":"attifer","pos":"VER"} ,
		{"word":"attife","word_nosc":"attife","lemma":"attifer","pos":"VER"} ,
		{"word":"attifer","word_nosc":"attifer","lemma":"attifer","pos":"VER"} ,
		{"word":"attifé","word_nosc":"attife","lemma":"attifer","pos":"VER"} ,
		{"word":"attifée","word_nosc":"attifee","lemma":"attifer","pos":"VER"} ,
		{"word":"attifées","word_nosc":"attifees","lemma":"attifer","pos":"VER"} ,
		{"word":"attifés","word_nosc":"attifes","lemma":"attifer","pos":"VER"} ,
		{"word":"attige","word_nosc":"attige","lemma":"attiger","pos":"VER"} ,
		{"word":"attigeait","word_nosc":"attigeait","lemma":"attiger","pos":"VER"} ,
		{"word":"attigent","word_nosc":"attigent","lemma":"attiger","pos":"VER"} ,
		{"word":"attiges","word_nosc":"attiges","lemma":"attiger","pos":"VER"} ,
		{"word":"attigés","word_nosc":"attiges","lemma":"attiger","pos":"VER"} ,
		{"word":"attira","word_nosc":"attira","lemma":"attirer","pos":"VER"} ,
		{"word":"attirai","word_nosc":"attirai","lemma":"attirer","pos":"VER"} ,
		{"word":"attiraient","word_nosc":"attiraient","lemma":"attirer","pos":"VER"} ,
		{"word":"attirais","word_nosc":"attirais","lemma":"attirer","pos":"VER"} ,
		{"word":"attirait","word_nosc":"attirait","lemma":"attirer","pos":"VER"} ,
		{"word":"attirant","word_nosc":"attirant","lemma":"attirer","pos":"VER"} ,
		{"word":"attire","word_nosc":"attire","lemma":"attirer","pos":"VER"} ,
		{"word":"attirent","word_nosc":"attirent","lemma":"attirer","pos":"VER"} ,
		{"word":"attirer","word_nosc":"attirer","lemma":"attirer","pos":"VER"} ,
		{"word":"attirera","word_nosc":"attirera","lemma":"attirer","pos":"VER"} ,
		{"word":"attirerai","word_nosc":"attirerai","lemma":"attirer","pos":"VER"} ,
		{"word":"attireraient","word_nosc":"attireraient","lemma":"attirer","pos":"VER"} ,
		{"word":"attirerais","word_nosc":"attirerais","lemma":"attirer","pos":"VER"} ,
		{"word":"attirerait","word_nosc":"attirerait","lemma":"attirer","pos":"VER"} ,
		{"word":"attirerez","word_nosc":"attirerez","lemma":"attirer","pos":"VER"} ,
		{"word":"attireriez","word_nosc":"attireriez","lemma":"attirer","pos":"VER"} ,
		{"word":"attirerons","word_nosc":"attirerons","lemma":"attirer","pos":"VER"} ,
		{"word":"attireront","word_nosc":"attireront","lemma":"attirer","pos":"VER"} ,
		{"word":"attires","word_nosc":"attires","lemma":"attirer","pos":"VER"} ,
		{"word":"attirez","word_nosc":"attirez","lemma":"attirer","pos":"VER"} ,
		{"word":"attiriez","word_nosc":"attiriez","lemma":"attirer","pos":"VER"} ,
		{"word":"attirions","word_nosc":"attirions","lemma":"attirer","pos":"VER"} ,
		{"word":"attirons","word_nosc":"attirons","lemma":"attirer","pos":"VER"} ,
		{"word":"attirât","word_nosc":"attirat","lemma":"attirer","pos":"VER"} ,
		{"word":"attirèrent","word_nosc":"attirerent","lemma":"attirer","pos":"VER"} ,
		{"word":"attiré","word_nosc":"attire","lemma":"attirer","pos":"VER"} ,
		{"word":"attirée","word_nosc":"attiree","lemma":"attirer","pos":"VER"} ,
		{"word":"attirées","word_nosc":"attirees","lemma":"attirer","pos":"VER"} ,
		{"word":"attirés","word_nosc":"attires","lemma":"attirer","pos":"VER"} ,
		{"word":"attisa","word_nosc":"attisa","lemma":"attiser","pos":"VER"} ,
		{"word":"attisaient","word_nosc":"attisaient","lemma":"attiser","pos":"VER"} ,
		{"word":"attisait","word_nosc":"attisait","lemma":"attiser","pos":"VER"} ,
		{"word":"attisant","word_nosc":"attisant","lemma":"attiser","pos":"VER"} ,
		{"word":"attise","word_nosc":"attise","lemma":"attiser","pos":"VER"} ,
		{"word":"attisent","word_nosc":"attisent","lemma":"attiser","pos":"VER"} ,
		{"word":"attiser","word_nosc":"attiser","lemma":"attiser","pos":"VER"} ,
		{"word":"attisera","word_nosc":"attisera","lemma":"attiser","pos":"VER"} ,
		{"word":"attises","word_nosc":"attises","lemma":"attiser","pos":"VER"} ,
		{"word":"attisez","word_nosc":"attisez","lemma":"attiser","pos":"VER"} ,
		{"word":"attisons","word_nosc":"attisons","lemma":"attiser","pos":"VER"} ,
		{"word":"attisé","word_nosc":"attise","lemma":"attiser","pos":"VER"} ,
		{"word":"attisée","word_nosc":"attisee","lemma":"attiser","pos":"VER"} ,
		{"word":"attisées","word_nosc":"attisees","lemma":"attiser","pos":"VER"} ,
		{"word":"attisés","word_nosc":"attises","lemma":"attiser","pos":"VER"} ,
		{"word":"attitré","word_nosc":"attitre","lemma":"attitrer","pos":"VER"} ,
		{"word":"attitrée","word_nosc":"attitree","lemma":"attitrer","pos":"VER"} ,
		{"word":"attitrés","word_nosc":"attitres","lemma":"attitrer","pos":"VER"} ,
		{"word":"attiédi","word_nosc":"attiedi","lemma":"attiédir","pos":"VER"} ,
		{"word":"attiédie","word_nosc":"attiedie","lemma":"attiédir","pos":"VER"} ,
		{"word":"attiédis","word_nosc":"attiedis","lemma":"attiédir","pos":"VER"} ,
		{"word":"attiédissait","word_nosc":"attiedissait","lemma":"attiédir","pos":"VER"} ,
		{"word":"attouchant","word_nosc":"attouchant","lemma":"attoucher","pos":"VER"} ,
		{"word":"attrait","word_nosc":"attrait","lemma":"attraire","pos":"VER"} ,
		{"word":"attrapa","word_nosc":"attrapa","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapai","word_nosc":"attrapai","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapaient","word_nosc":"attrapaient","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapais","word_nosc":"attrapais","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapait","word_nosc":"attrapait","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapant","word_nosc":"attrapant","lemma":"attraper","pos":"VER"} ,
		{"word":"attrape","word_nosc":"attrape","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapent","word_nosc":"attrapent","lemma":"attraper","pos":"VER"} ,
		{"word":"attraper","word_nosc":"attraper","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapera","word_nosc":"attrapera","lemma":"attraper","pos":"VER"} ,
		{"word":"attraperai","word_nosc":"attraperai","lemma":"attraper","pos":"VER"} ,
		{"word":"attraperaient","word_nosc":"attraperaient","lemma":"attraper","pos":"VER"} ,
		{"word":"attraperais","word_nosc":"attraperais","lemma":"attraper","pos":"VER"} ,
		{"word":"attraperait","word_nosc":"attraperait","lemma":"attraper","pos":"VER"} ,
		{"word":"attraperas","word_nosc":"attraperas","lemma":"attraper","pos":"VER"} ,
		{"word":"attraperez","word_nosc":"attraperez","lemma":"attraper","pos":"VER"} ,
		{"word":"attraperiez","word_nosc":"attraperiez","lemma":"attraper","pos":"VER"} ,
		{"word":"attraperons","word_nosc":"attraperons","lemma":"attraper","pos":"VER"} ,
		{"word":"attraperont","word_nosc":"attraperont","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapes","word_nosc":"attrapes","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapez","word_nosc":"attrapez","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapiez","word_nosc":"attrapiez","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapions","word_nosc":"attrapions","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapons","word_nosc":"attrapons","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapèrent","word_nosc":"attraperent","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapé","word_nosc":"attrape","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapée","word_nosc":"attrapee","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapées","word_nosc":"attrapees","lemma":"attraper","pos":"VER"} ,
		{"word":"attrapés","word_nosc":"attrapes","lemma":"attraper","pos":"VER"} ,
		{"word":"attribua","word_nosc":"attribua","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuai","word_nosc":"attribuai","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuaient","word_nosc":"attribuaient","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuais","word_nosc":"attribuais","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuait","word_nosc":"attribuait","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuant","word_nosc":"attribuant","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribue","word_nosc":"attribue","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuent","word_nosc":"attribuent","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuer","word_nosc":"attribuer","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuera","word_nosc":"attribuera","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribueraient","word_nosc":"attribueraient","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuerais","word_nosc":"attribuerais","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuerait","word_nosc":"attribuerait","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribueront","word_nosc":"attribueront","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribues","word_nosc":"attribues","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuez","word_nosc":"attribuez","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuions","word_nosc":"attribuions","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuons","word_nosc":"attribuons","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuâmes","word_nosc":"attribuames","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuât","word_nosc":"attribuat","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuèrent","word_nosc":"attribuerent","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribué","word_nosc":"attribue","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuée","word_nosc":"attribuee","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribuées","word_nosc":"attribuees","lemma":"attribuer","pos":"VER"} ,
		{"word":"attribués","word_nosc":"attribues","lemma":"attribuer","pos":"VER"} ,
		{"word":"attriquait","word_nosc":"attriquait","lemma":"attriquer","pos":"VER"} ,
		{"word":"attriquant","word_nosc":"attriquant","lemma":"attriquer","pos":"VER"} ,
		{"word":"attrique","word_nosc":"attrique","lemma":"attriquer","pos":"VER"} ,
		{"word":"attriquent","word_nosc":"attriquent","lemma":"attriquer","pos":"VER"} ,
		{"word":"attriquer","word_nosc":"attriquer","lemma":"attriquer","pos":"VER"} ,
		{"word":"attriquerait","word_nosc":"attriquerait","lemma":"attriquer","pos":"VER"} ,
		{"word":"attriqué","word_nosc":"attrique","lemma":"attriquer","pos":"VER"} ,
		{"word":"attriquée","word_nosc":"attriquee","lemma":"attriquer","pos":"VER"} ,
		{"word":"attrista","word_nosc":"attrista","lemma":"attrister","pos":"VER"} ,
		{"word":"attristai","word_nosc":"attristai","lemma":"attrister","pos":"VER"} ,
		{"word":"attristaient","word_nosc":"attristaient","lemma":"attrister","pos":"VER"} ,
		{"word":"attristais","word_nosc":"attristais","lemma":"attrister","pos":"VER"} ,
		{"word":"attristait","word_nosc":"attristait","lemma":"attrister","pos":"VER"} ,
		{"word":"attristant","word_nosc":"attristant","lemma":"attrister","pos":"VER"} ,
		{"word":"attriste","word_nosc":"attriste","lemma":"attrister","pos":"VER"} ,
		{"word":"attristent","word_nosc":"attristent","lemma":"attrister","pos":"VER"} ,
		{"word":"attrister","word_nosc":"attrister","lemma":"attrister","pos":"VER"} ,
		{"word":"attristerait","word_nosc":"attristerait","lemma":"attrister","pos":"VER"} ,
		{"word":"attristez","word_nosc":"attristez","lemma":"attrister","pos":"VER"} ,
		{"word":"attristât","word_nosc":"attristat","lemma":"attrister","pos":"VER"} ,
		{"word":"attristèrent","word_nosc":"attristerent","lemma":"attrister","pos":"VER"} ,
		{"word":"attristé","word_nosc":"attriste","lemma":"attrister","pos":"VER"} ,
		{"word":"attristée","word_nosc":"attristee","lemma":"attrister","pos":"VER"} ,
		{"word":"attristés","word_nosc":"attristes","lemma":"attrister","pos":"VER"} ,
		{"word":"attroupa","word_nosc":"attroupa","lemma":"attrouper","pos":"VER"} ,
		{"word":"attroupaient","word_nosc":"attroupaient","lemma":"attrouper","pos":"VER"} ,
		{"word":"attroupait","word_nosc":"attroupait","lemma":"attrouper","pos":"VER"} ,
		{"word":"attroupent","word_nosc":"attroupent","lemma":"attrouper","pos":"VER"} ,
		{"word":"attrouper","word_nosc":"attrouper","lemma":"attrouper","pos":"VER"} ,
		{"word":"attrouperaient","word_nosc":"attrouperaient","lemma":"attrouper","pos":"VER"} ,
		{"word":"attroupèrent","word_nosc":"attrouperent","lemma":"attrouper","pos":"VER"} ,
		{"word":"attroupé","word_nosc":"attroupe","lemma":"attrouper","pos":"VER"} ,
		{"word":"attroupée","word_nosc":"attroupee","lemma":"attrouper","pos":"VER"} ,
		{"word":"attroupées","word_nosc":"attroupees","lemma":"attrouper","pos":"VER"} ,
		{"word":"attroupés","word_nosc":"attroupes","lemma":"attrouper","pos":"VER"} ,
		{"word":"atténua","word_nosc":"attenua","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténuaient","word_nosc":"attenuaient","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténuait","word_nosc":"attenuait","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténuant","word_nosc":"attenuant","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténue","word_nosc":"attenue","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténuent","word_nosc":"attenuent","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténuer","word_nosc":"attenuer","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténuera","word_nosc":"attenuera","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténueraient","word_nosc":"attenueraient","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténuez","word_nosc":"attenuez","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténuons","word_nosc":"attenuons","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténuât","word_nosc":"attenuat","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténuèrent","word_nosc":"attenuerent","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténué","word_nosc":"attenue","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténuée","word_nosc":"attenuee","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténuées","word_nosc":"attenuees","lemma":"atténuer","pos":"VER"} ,
		{"word":"atténués","word_nosc":"attenues","lemma":"atténuer","pos":"VER"} ,
		{"word":"aubadent","word_nosc":"aubadent","lemma":"aubader","pos":"VER"} ,
		{"word":"auditer","word_nosc":"auditer","lemma":"auditer","pos":"VER"} ,
		{"word":"auditionnais","word_nosc":"auditionnais","lemma":"auditionner","pos":"VER"} ,
		{"word":"auditionne","word_nosc":"auditionne","lemma":"auditionner","pos":"VER"} ,
		{"word":"auditionnent","word_nosc":"auditionnent","lemma":"auditionner","pos":"VER"} ,
		{"word":"auditionner","word_nosc":"auditionner","lemma":"auditionner","pos":"VER"} ,
		{"word":"auditionnera","word_nosc":"auditionnera","lemma":"auditionner","pos":"VER"} ,
		{"word":"auditionnerais","word_nosc":"auditionnerais","lemma":"auditionner","pos":"VER"} ,
		{"word":"auditionnes","word_nosc":"auditionnes","lemma":"auditionner","pos":"VER"} ,
		{"word":"auditionnez","word_nosc":"auditionnez","lemma":"auditionner","pos":"VER"} ,
		{"word":"auditionné","word_nosc":"auditionne","lemma":"auditionner","pos":"VER"} ,
		{"word":"auditionnée","word_nosc":"auditionnee","lemma":"auditionner","pos":"VER"} ,
		{"word":"augmenta","word_nosc":"augmenta","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmentaient","word_nosc":"augmentaient","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmentais","word_nosc":"augmentais","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmentait","word_nosc":"augmentait","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmentant","word_nosc":"augmentant","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmente","word_nosc":"augmente","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmentent","word_nosc":"augmentent","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmenter","word_nosc":"augmenter","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmentera","word_nosc":"augmentera","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmenterai","word_nosc":"augmenterai","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmenteraient","word_nosc":"augmenteraient","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmenterais","word_nosc":"augmenterais","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmenterait","word_nosc":"augmenterait","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmenteras","word_nosc":"augmenteras","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmenterez","word_nosc":"augmenterez","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmenteront","word_nosc":"augmenteront","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmentez","word_nosc":"augmentez","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmentions","word_nosc":"augmentions","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmentons","word_nosc":"augmentons","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmentât","word_nosc":"augmentat","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmentèrent","word_nosc":"augmenterent","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmenté","word_nosc":"augmente","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmentée","word_nosc":"augmentee","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmentées","word_nosc":"augmentees","lemma":"augmenter","pos":"VER"} ,
		{"word":"augmentés","word_nosc":"augmentes","lemma":"augmenter","pos":"VER"} ,
		{"word":"augurai","word_nosc":"augurai","lemma":"augurer","pos":"VER"} ,
		{"word":"augurais","word_nosc":"augurais","lemma":"augurer","pos":"VER"} ,
		{"word":"augurait","word_nosc":"augurait","lemma":"augurer","pos":"VER"} ,
		{"word":"augurant","word_nosc":"augurant","lemma":"augurer","pos":"VER"} ,
		{"word":"augure","word_nosc":"augure","lemma":"augurer","pos":"VER"} ,
		{"word":"augurent","word_nosc":"augurent","lemma":"augurer","pos":"VER"} ,
		{"word":"augurer","word_nosc":"augurer","lemma":"augurer","pos":"VER"} ,
		{"word":"augurerais","word_nosc":"augurerais","lemma":"augurer","pos":"VER"} ,
		{"word":"auner","word_nosc":"auner","lemma":"auner","pos":"VER"} ,
		{"word":"aura","word_nosc":"aura","lemma":"avoir","pos":"VER"} ,
		{"word":"aurai","word_nosc":"aurai","lemma":"avoir","pos":"VER"} ,
		{"word":"auraient","word_nosc":"auraient","lemma":"avoir","pos":"VER"} ,
		{"word":"aurais","word_nosc":"aurais","lemma":"avoir","pos":"VER"} ,
		{"word":"aurait","word_nosc":"aurait","lemma":"avoir","pos":"VER"} ,
		{"word":"auras","word_nosc":"auras","lemma":"avoir","pos":"VER"} ,
		{"word":"aurez","word_nosc":"aurez","lemma":"avoir","pos":"VER"} ,
		{"word":"auriez","word_nosc":"auriez","lemma":"avoir","pos":"VER"} ,
		{"word":"aurifiées","word_nosc":"aurifiees","lemma":"aurifier","pos":"VER"} ,
		{"word":"aurions","word_nosc":"aurions","lemma":"avoir","pos":"VER"} ,
		{"word":"aurons","word_nosc":"aurons","lemma":"avoir","pos":"VER"} ,
		{"word":"auront","word_nosc":"auront","lemma":"avoir","pos":"VER"} ,
		{"word":"auréola","word_nosc":"aureola","lemma":"auréoler","pos":"VER"} ,
		{"word":"auréolaient","word_nosc":"aureolaient","lemma":"auréoler","pos":"VER"} ,
		{"word":"auréolait","word_nosc":"aureolait","lemma":"auréoler","pos":"VER"} ,
		{"word":"auréolant","word_nosc":"aureolant","lemma":"auréoler","pos":"VER"} ,
		{"word":"auréole","word_nosc":"aureole","lemma":"auréoler","pos":"VER"} ,
		{"word":"auréoler","word_nosc":"aureoler","lemma":"auréoler","pos":"VER"} ,
		{"word":"auréolé","word_nosc":"aureole","lemma":"auréoler","pos":"VER"} ,
		{"word":"auréolée","word_nosc":"aureolee","lemma":"auréoler","pos":"VER"} ,
		{"word":"auréolées","word_nosc":"aureolees","lemma":"auréoler","pos":"VER"} ,
		{"word":"auréolés","word_nosc":"aureoles","lemma":"auréoler","pos":"VER"} ,
		{"word":"ausculta","word_nosc":"ausculta","lemma":"ausculter","pos":"VER"} ,
		{"word":"auscultais","word_nosc":"auscultais","lemma":"ausculter","pos":"VER"} ,
		{"word":"auscultait","word_nosc":"auscultait","lemma":"ausculter","pos":"VER"} ,
		{"word":"auscultant","word_nosc":"auscultant","lemma":"ausculter","pos":"VER"} ,
		{"word":"ausculte","word_nosc":"ausculte","lemma":"ausculter","pos":"VER"} ,
		{"word":"auscultent","word_nosc":"auscultent","lemma":"ausculter","pos":"VER"} ,
		{"word":"ausculter","word_nosc":"ausculter","lemma":"ausculter","pos":"VER"} ,
		{"word":"auscultez","word_nosc":"auscultez","lemma":"ausculter","pos":"VER"} ,
		{"word":"ausculté","word_nosc":"ausculte","lemma":"ausculter","pos":"VER"} ,
		{"word":"auscultées","word_nosc":"auscultees","lemma":"ausculter","pos":"VER"} ,
		{"word":"auscultés","word_nosc":"auscultes","lemma":"ausculter","pos":"VER"} ,
		{"word":"authentifiait","word_nosc":"authentifiait","lemma":"authentifier","pos":"VER"} ,
		{"word":"authentifie","word_nosc":"authentifie","lemma":"authentifier","pos":"VER"} ,
		{"word":"authentifient","word_nosc":"authentifient","lemma":"authentifier","pos":"VER"} ,
		{"word":"authentifier","word_nosc":"authentifier","lemma":"authentifier","pos":"VER"} ,
		{"word":"authentifierait","word_nosc":"authentifierait","lemma":"authentifier","pos":"VER"} ,
		{"word":"authentifiez","word_nosc":"authentifiez","lemma":"authentifier","pos":"VER"} ,
		{"word":"authentifié","word_nosc":"authentifie","lemma":"authentifier","pos":"VER"} ,
		{"word":"authentifiée","word_nosc":"authentifiee","lemma":"authentifier","pos":"VER"} ,
		{"word":"authentifiées","word_nosc":"authentifiees","lemma":"authentifier","pos":"VER"} ,
		{"word":"authentifiés","word_nosc":"authentifies","lemma":"authentifier","pos":"VER"} ,
		{"word":"authentiquant","word_nosc":"authentiquant","lemma":"authentiquer","pos":"VER"} ,
		{"word":"authentique","word_nosc":"authentique","lemma":"authentiquer","pos":"VER"} ,
		{"word":"autocensure","word_nosc":"autocensure","lemma":"autocensurer","pos":"VER"} ,
		{"word":"autocensurent","word_nosc":"autocensurent","lemma":"autocensurer","pos":"VER"} ,
		{"word":"autocensurer","word_nosc":"autocensurer","lemma":"autocensurer","pos":"VER"} ,
		{"word":"autocritiquer","word_nosc":"autocritiquer","lemma":"autocritiquer","pos":"VER"} ,
		{"word":"autodétruira","word_nosc":"autodetruira","lemma":"autodétruire","pos":"VER"} ,
		{"word":"autodétruire","word_nosc":"autodetruire","lemma":"autodétruire","pos":"VER"} ,
		{"word":"autodétruiront","word_nosc":"autodetruiront","lemma":"autodétruire","pos":"VER"} ,
		{"word":"autodétruisant","word_nosc":"autodetruisant","lemma":"autodétruire","pos":"VER"} ,
		{"word":"autodétruisent","word_nosc":"autodetruisent","lemma":"autodétruire","pos":"VER"} ,
		{"word":"autodétruit","word_nosc":"autodetruit","lemma":"autodétruire","pos":"VER"} ,
		{"word":"autodétruite","word_nosc":"autodetruite","lemma":"autodétruire","pos":"VER"} ,
		{"word":"autographier","word_nosc":"autographier","lemma":"autographier","pos":"VER"} ,
		{"word":"autographié","word_nosc":"autographie","lemma":"autographier","pos":"VER"} ,
		{"word":"autogérer","word_nosc":"autogerer","lemma":"autogérer","pos":"VER"} ,
		{"word":"autolimite","word_nosc":"autolimite","lemma":"autolimiter","pos":"VER"} ,
		{"word":"automatisé","word_nosc":"automatise","lemma":"automatiser","pos":"VER"} ,
		{"word":"automatisée","word_nosc":"automatisee","lemma":"automatiser","pos":"VER"} ,
		{"word":"automatisés","word_nosc":"automatises","lemma":"automatiser","pos":"VER"} ,
		{"word":"automutiler","word_nosc":"automutiler","lemma":"automutiler","pos":"VER"} ,
		{"word":"autoproclamé","word_nosc":"autoproclame","lemma":"autoproclamer","pos":"VER"} ,
		{"word":"autoproclamée","word_nosc":"autoproclamee","lemma":"autoproclamer","pos":"VER"} ,
		{"word":"autopsia","word_nosc":"autopsia","lemma":"autopsier","pos":"VER"} ,
		{"word":"autopsiais","word_nosc":"autopsiais","lemma":"autopsier","pos":"VER"} ,
		{"word":"autopsier","word_nosc":"autopsier","lemma":"autopsier","pos":"VER"} ,
		{"word":"autopsié","word_nosc":"autopsie","lemma":"autopsier","pos":"VER"} ,
		{"word":"autorisa","word_nosc":"autorisa","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisai","word_nosc":"autorisai","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisaient","word_nosc":"autorisaient","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisais","word_nosc":"autorisais","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisait","word_nosc":"autorisait","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisant","word_nosc":"autorisant","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisassent","word_nosc":"autorisassent","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorise","word_nosc":"autorise","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisent","word_nosc":"autorisent","lemma":"autoriser","pos":"VER"} ,
		{"word":"autoriser","word_nosc":"autoriser","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisera","word_nosc":"autorisera","lemma":"autoriser","pos":"VER"} ,
		{"word":"autoriserai","word_nosc":"autoriserai","lemma":"autoriser","pos":"VER"} ,
		{"word":"autoriserais","word_nosc":"autoriserais","lemma":"autoriser","pos":"VER"} ,
		{"word":"autoriserait","word_nosc":"autoriserait","lemma":"autoriser","pos":"VER"} ,
		{"word":"autoriserez","word_nosc":"autoriserez","lemma":"autoriser","pos":"VER"} ,
		{"word":"autoriserons","word_nosc":"autoriserons","lemma":"autoriser","pos":"VER"} ,
		{"word":"autoriseront","word_nosc":"autoriseront","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorises","word_nosc":"autorises","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisez","word_nosc":"autorisez","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisiez","word_nosc":"autorisiez","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisions","word_nosc":"autorisions","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisons","word_nosc":"autorisons","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisât","word_nosc":"autorisat","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisèrent","word_nosc":"autoriserent","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisé","word_nosc":"autorise","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisée","word_nosc":"autorisee","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisées","word_nosc":"autorisees","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorisés","word_nosc":"autorises","lemma":"autoriser","pos":"VER"} ,
		{"word":"autorégule","word_nosc":"autoregule","lemma":"autoréguler","pos":"VER"} ,
		{"word":"autosuggestionna","word_nosc":"autosuggestionna","lemma":"autosuggestionner","pos":"VER"} ,
		{"word":"avachi","word_nosc":"avachi","lemma":"avachir","pos":"VER"} ,
		{"word":"avachie","word_nosc":"avachie","lemma":"avachir","pos":"VER"} ,
		{"word":"avachir","word_nosc":"avachir","lemma":"avachir","pos":"VER"} ,
		{"word":"avachirait","word_nosc":"avachirait","lemma":"avachir","pos":"VER"} ,
		{"word":"avachirons","word_nosc":"avachirons","lemma":"avachir","pos":"VER"} ,
		{"word":"avachis","word_nosc":"avachis","lemma":"avachir","pos":"VER"} ,
		{"word":"avachissait","word_nosc":"avachissait","lemma":"avachir","pos":"VER"} ,
		{"word":"avachissent","word_nosc":"avachissent","lemma":"avachir","pos":"VER"} ,
		{"word":"avachit","word_nosc":"avachit","lemma":"avachir","pos":"VER"} ,
		{"word":"avaient","word_nosc":"avaient","lemma":"avoir","pos":"VER"} ,
		{"word":"avais","word_nosc":"avais","lemma":"avoir","pos":"VER"} ,
		{"word":"avait","word_nosc":"avait","lemma":"avoir","pos":"VER"} ,
		{"word":"avala","word_nosc":"avala","lemma":"avaler","pos":"VER"} ,
		{"word":"avalai","word_nosc":"avalai","lemma":"avaler","pos":"VER"} ,
		{"word":"avalaient","word_nosc":"avalaient","lemma":"avaler","pos":"VER"} ,
		{"word":"avalais","word_nosc":"avalais","lemma":"avaler","pos":"VER"} ,
		{"word":"avalait","word_nosc":"avalait","lemma":"avaler","pos":"VER"} ,
		{"word":"avalant","word_nosc":"avalant","lemma":"avaler","pos":"VER"} ,
		{"word":"avale","word_nosc":"avale","lemma":"avaler","pos":"VER"} ,
		{"word":"avalent","word_nosc":"avalent","lemma":"avaler","pos":"VER"} ,
		{"word":"avaler","word_nosc":"avaler","lemma":"avaler","pos":"VER"} ,
		{"word":"avalera","word_nosc":"avalera","lemma":"avaler","pos":"VER"} ,
		{"word":"avalerai","word_nosc":"avalerai","lemma":"avaler","pos":"VER"} ,
		{"word":"avaleraient","word_nosc":"avaleraient","lemma":"avaler","pos":"VER"} ,
		{"word":"avalerais","word_nosc":"avalerais","lemma":"avaler","pos":"VER"} ,
		{"word":"avalerait","word_nosc":"avalerait","lemma":"avaler","pos":"VER"} ,
		{"word":"avaleras","word_nosc":"avaleras","lemma":"avaler","pos":"VER"} ,
		{"word":"avaleriez","word_nosc":"avaleriez","lemma":"avaler","pos":"VER"} ,
		{"word":"avaleront","word_nosc":"avaleront","lemma":"avaler","pos":"VER"} ,
		{"word":"avales","word_nosc":"avales","lemma":"avaler","pos":"VER"} ,
		{"word":"avalez","word_nosc":"avalez","lemma":"avaler","pos":"VER"} ,
		{"word":"avaliez","word_nosc":"avaliez","lemma":"avaler","pos":"VER"} ,
		{"word":"avaliser","word_nosc":"avaliser","lemma":"avaliser","pos":"VER"} ,
		{"word":"avalisés","word_nosc":"avalises","lemma":"avaliser","pos":"VER"} ,
		{"word":"avalons","word_nosc":"avalons","lemma":"avaler","pos":"VER"} ,
		{"word":"avalâmes","word_nosc":"avalames","lemma":"avaler","pos":"VER"} ,
		{"word":"avalât","word_nosc":"avalat","lemma":"avaler","pos":"VER"} ,
		{"word":"avalèrent","word_nosc":"avalerent","lemma":"avaler","pos":"VER"} ,
		{"word":"avalé","word_nosc":"avale","lemma":"avaler","pos":"VER"} ,
		{"word":"avalée","word_nosc":"avalee","lemma":"avaler","pos":"VER"} ,
		{"word":"avalées","word_nosc":"avalees","lemma":"avaler","pos":"VER"} ,
		{"word":"avalés","word_nosc":"avales","lemma":"avaler","pos":"VER"} ,
		{"word":"avance","word_nosc":"avance","lemma":"avancer","pos":"VER"} ,
		{"word":"avancent","word_nosc":"avancent","lemma":"avancer","pos":"VER"} ,
		{"word":"avancer","word_nosc":"avancer","lemma":"avancer","pos":"VER"} ,
		{"word":"avancera","word_nosc":"avancera","lemma":"avancer","pos":"VER"} ,
		{"word":"avancerai","word_nosc":"avancerai","lemma":"avancer","pos":"VER"} ,
		{"word":"avancerais","word_nosc":"avancerais","lemma":"avancer","pos":"VER"} ,
		{"word":"avancerait","word_nosc":"avancerait","lemma":"avancer","pos":"VER"} ,
		{"word":"avancerez","word_nosc":"avancerez","lemma":"avancer","pos":"VER"} ,
		{"word":"avancerons","word_nosc":"avancerons","lemma":"avancer","pos":"VER"} ,
		{"word":"avanceront","word_nosc":"avanceront","lemma":"avancer","pos":"VER"} ,
		{"word":"avances","word_nosc":"avances","lemma":"avancer","pos":"VER"} ,
		{"word":"avancez","word_nosc":"avancez","lemma":"avancer","pos":"VER"} ,
		{"word":"avanciez","word_nosc":"avanciez","lemma":"avancer","pos":"VER"} ,
		{"word":"avancions","word_nosc":"avancions","lemma":"avancer","pos":"VER"} ,
		{"word":"avancèrent","word_nosc":"avancerent","lemma":"avancer","pos":"VER"} ,
		{"word":"avancé","word_nosc":"avance","lemma":"avancer","pos":"VER"} ,
		{"word":"avancée","word_nosc":"avancee","lemma":"avancer","pos":"VER"} ,
		{"word":"avancées","word_nosc":"avancees","lemma":"avancer","pos":"VER"} ,
		{"word":"avancés","word_nosc":"avances","lemma":"avancer","pos":"VER"} ,
		{"word":"avantage","word_nosc":"avantage","lemma":"avantager","pos":"VER"} ,
		{"word":"avantageaient","word_nosc":"avantageaient","lemma":"avantager","pos":"VER"} ,
		{"word":"avantageais","word_nosc":"avantageais","lemma":"avantager","pos":"VER"} ,
		{"word":"avantageait","word_nosc":"avantageait","lemma":"avantager","pos":"VER"} ,
		{"word":"avantageant","word_nosc":"avantageant","lemma":"avantager","pos":"VER"} ,
		{"word":"avantagent","word_nosc":"avantagent","lemma":"avantager","pos":"VER"} ,
		{"word":"avantager","word_nosc":"avantager","lemma":"avantager","pos":"VER"} ,
		{"word":"avantagera","word_nosc":"avantagera","lemma":"avantager","pos":"VER"} ,
		{"word":"avantages","word_nosc":"avantages","lemma":"avantager","pos":"VER"} ,
		{"word":"avantagé","word_nosc":"avantage","lemma":"avantager","pos":"VER"} ,
		{"word":"avantagée","word_nosc":"avantagee","lemma":"avantager","pos":"VER"} ,
		{"word":"avantagés","word_nosc":"avantages","lemma":"avantager","pos":"VER"} ,
		{"word":"avança","word_nosc":"avanca","lemma":"avancer","pos":"VER"} ,
		{"word":"avançai","word_nosc":"avancai","lemma":"avancer","pos":"VER"} ,
		{"word":"avançaient","word_nosc":"avancaient","lemma":"avancer","pos":"VER"} ,
		{"word":"avançais","word_nosc":"avancais","lemma":"avancer","pos":"VER"} ,
		{"word":"avançait","word_nosc":"avancait","lemma":"avancer","pos":"VER"} ,
		{"word":"avançant","word_nosc":"avancant","lemma":"avancer","pos":"VER"} ,
		{"word":"avançons","word_nosc":"avancons","lemma":"avancer","pos":"VER"} ,
		{"word":"avançâmes","word_nosc":"avancames","lemma":"avancer","pos":"VER"} ,
		{"word":"avançât","word_nosc":"avancat","lemma":"avancer","pos":"VER"} ,
		{"word":"avarient","word_nosc":"avarient","lemma":"avarier","pos":"VER"} ,
		{"word":"avarié","word_nosc":"avarie","lemma":"avarier","pos":"VER"} ,
		{"word":"avariée","word_nosc":"avariee","lemma":"avarier","pos":"VER"} ,
		{"word":"avariées","word_nosc":"avariees","lemma":"avarier","pos":"VER"} ,
		{"word":"avariés","word_nosc":"avaries","lemma":"avarier","pos":"VER"} ,
		{"word":"avenir","word_nosc":"avenir","lemma":"avenir","pos":"VER"} ,
		{"word":"aventura","word_nosc":"aventura","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurai","word_nosc":"aventurai","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventuraient","word_nosc":"aventuraient","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurais","word_nosc":"aventurais","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurait","word_nosc":"aventurait","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurant","word_nosc":"aventurant","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventure","word_nosc":"aventure","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurent","word_nosc":"aventurent","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurer","word_nosc":"aventurer","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurerai","word_nosc":"aventurerai","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurerais","word_nosc":"aventurerais","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurerait","word_nosc":"aventurerait","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventures","word_nosc":"aventures","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurez","word_nosc":"aventurez","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurions","word_nosc":"aventurions","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurons","word_nosc":"aventurons","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurât","word_nosc":"aventurat","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurèrent","word_nosc":"aventurerent","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventuré","word_nosc":"aventure","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurée","word_nosc":"aventuree","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurées","word_nosc":"aventurees","lemma":"aventurer","pos":"VER"} ,
		{"word":"aventurés","word_nosc":"aventures","lemma":"aventurer","pos":"VER"} ,
		{"word":"averti","word_nosc":"averti","lemma":"avertir","pos":"VER"} ,
		{"word":"avertie","word_nosc":"avertie","lemma":"avertir","pos":"VER"} ,
		{"word":"averties","word_nosc":"averties","lemma":"avertir","pos":"VER"} ,
		{"word":"avertir","word_nosc":"avertir","lemma":"avertir","pos":"VER"} ,
		{"word":"avertira","word_nosc":"avertira","lemma":"avertir","pos":"VER"} ,
		{"word":"avertirai","word_nosc":"avertirai","lemma":"avertir","pos":"VER"} ,
		{"word":"avertirait","word_nosc":"avertirait","lemma":"avertir","pos":"VER"} ,
		{"word":"avertiras","word_nosc":"avertiras","lemma":"avertir","pos":"VER"} ,
		{"word":"avertirent","word_nosc":"avertirent","lemma":"avertir","pos":"VER"} ,
		{"word":"avertirez","word_nosc":"avertirez","lemma":"avertir","pos":"VER"} ,
		{"word":"avertis","word_nosc":"avertis","lemma":"avertir","pos":"VER"} ,
		{"word":"avertissaient","word_nosc":"avertissaient","lemma":"avertir","pos":"VER"} ,
		{"word":"avertissais","word_nosc":"avertissais","lemma":"avertir","pos":"VER"} ,
		{"word":"avertissait","word_nosc":"avertissait","lemma":"avertir","pos":"VER"} ,
		{"word":"avertissant","word_nosc":"avertissant","lemma":"avertir","pos":"VER"} ,
		{"word":"avertisse","word_nosc":"avertisse","lemma":"avertir","pos":"VER"} ,
		{"word":"avertissent","word_nosc":"avertissent","lemma":"avertir","pos":"VER"} ,
		{"word":"avertissez","word_nosc":"avertissez","lemma":"avertir","pos":"VER"} ,
		{"word":"avertissiez","word_nosc":"avertissiez","lemma":"avertir","pos":"VER"} ,
		{"word":"avertissons","word_nosc":"avertissons","lemma":"avertir","pos":"VER"} ,
		{"word":"avertit","word_nosc":"avertit","lemma":"avertir","pos":"VER"} ,
		{"word":"avertît","word_nosc":"avertit","lemma":"avertir","pos":"VER"} ,
		{"word":"aveugla","word_nosc":"aveugla","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuglaient","word_nosc":"aveuglaient","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuglais","word_nosc":"aveuglais","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuglait","word_nosc":"aveuglait","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuglant","word_nosc":"aveuglant","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveugle","word_nosc":"aveugle","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuglent","word_nosc":"aveuglent","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveugler","word_nosc":"aveugler","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuglera","word_nosc":"aveuglera","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuglerait","word_nosc":"aveuglerait","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveugles","word_nosc":"aveugles","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuglez","word_nosc":"aveuglez","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuglons","word_nosc":"aveuglons","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuglât","word_nosc":"aveuglat","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuglèrent","word_nosc":"aveuglerent","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuglé","word_nosc":"aveugle","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuglée","word_nosc":"aveuglee","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuglées","word_nosc":"aveuglees","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuglés","word_nosc":"aveugles","lemma":"aveugler","pos":"VER"} ,
		{"word":"aveuli","word_nosc":"aveuli","lemma":"aveulir","pos":"VER"} ,
		{"word":"avez","word_nosc":"avez","lemma":"avoir","pos":"VER"} ,
		{"word":"aviez","word_nosc":"aviez","lemma":"avoir","pos":"VER"} ,
		{"word":"avili","word_nosc":"avili","lemma":"avilir","pos":"VER"} ,
		{"word":"avilie","word_nosc":"avilie","lemma":"avilir","pos":"VER"} ,
		{"word":"avilies","word_nosc":"avilies","lemma":"avilir","pos":"VER"} ,
		{"word":"avilir","word_nosc":"avilir","lemma":"avilir","pos":"VER"} ,
		{"word":"avilis","word_nosc":"avilis","lemma":"avilir","pos":"VER"} ,
		{"word":"avilissait","word_nosc":"avilissait","lemma":"avilir","pos":"VER"} ,
		{"word":"avilissant","word_nosc":"avilissant","lemma":"avilir","pos":"VER"} ,
		{"word":"avilissent","word_nosc":"avilissent","lemma":"avilir","pos":"VER"} ,
		{"word":"avilit","word_nosc":"avilit","lemma":"avilir","pos":"VER"} ,
		{"word":"avinées","word_nosc":"avinees","lemma":"aviner","pos":"VER"} ,
		{"word":"avinés","word_nosc":"avines","lemma":"aviner","pos":"VER"} ,
		{"word":"avions","word_nosc":"avions","lemma":"avoir","pos":"VER"} ,
		{"word":"avisa","word_nosc":"avisa","lemma":"aviser","pos":"VER"} ,
		{"word":"avisai","word_nosc":"avisai","lemma":"aviser","pos":"VER"} ,
		{"word":"avisaient","word_nosc":"avisaient","lemma":"aviser","pos":"VER"} ,
		{"word":"avisais","word_nosc":"avisais","lemma":"aviser","pos":"VER"} ,
		{"word":"avisait","word_nosc":"avisait","lemma":"aviser","pos":"VER"} ,
		{"word":"avisant","word_nosc":"avisant","lemma":"aviser","pos":"VER"} ,
		{"word":"avise","word_nosc":"avise","lemma":"aviser","pos":"VER"} ,
		{"word":"avisent","word_nosc":"avisent","lemma":"aviser","pos":"VER"} ,
		{"word":"aviser","word_nosc":"aviser","lemma":"aviser","pos":"VER"} ,
		{"word":"avisera","word_nosc":"avisera","lemma":"aviser","pos":"VER"} ,
		{"word":"aviserai","word_nosc":"aviserai","lemma":"aviser","pos":"VER"} ,
		{"word":"aviseraient","word_nosc":"aviseraient","lemma":"aviser","pos":"VER"} ,
		{"word":"aviserais","word_nosc":"aviserais","lemma":"aviser","pos":"VER"} ,
		{"word":"aviserait","word_nosc":"aviserait","lemma":"aviser","pos":"VER"} ,
		{"word":"aviseras","word_nosc":"aviseras","lemma":"aviser","pos":"VER"} ,
		{"word":"aviserons","word_nosc":"aviserons","lemma":"aviser","pos":"VER"} ,
		{"word":"aviseront","word_nosc":"aviseront","lemma":"aviser","pos":"VER"} ,
		{"word":"avises","word_nosc":"avises","lemma":"aviser","pos":"VER"} ,
		{"word":"avisez","word_nosc":"avisez","lemma":"aviser","pos":"VER"} ,
		{"word":"avisions","word_nosc":"avisions","lemma":"aviser","pos":"VER"} ,
		{"word":"avisât","word_nosc":"avisat","lemma":"aviser","pos":"VER"} ,
		{"word":"avisèrent","word_nosc":"aviserent","lemma":"aviser","pos":"VER"} ,
		{"word":"avisé","word_nosc":"avise","lemma":"aviser","pos":"VER"} ,
		{"word":"avisée","word_nosc":"avisee","lemma":"aviser","pos":"VER"} ,
		{"word":"avisés","word_nosc":"avises","lemma":"aviser","pos":"VER"} ,
		{"word":"avitailler","word_nosc":"avitailler","lemma":"avitailler","pos":"VER"} ,
		{"word":"aviva","word_nosc":"aviva","lemma":"aviver","pos":"VER"} ,
		{"word":"avivaient","word_nosc":"avivaient","lemma":"aviver","pos":"VER"} ,
		{"word":"avivait","word_nosc":"avivait","lemma":"aviver","pos":"VER"} ,
		{"word":"avivant","word_nosc":"avivant","lemma":"aviver","pos":"VER"} ,
		{"word":"avive","word_nosc":"avive","lemma":"aviver","pos":"VER"} ,
		{"word":"avivent","word_nosc":"avivent","lemma":"aviver","pos":"VER"} ,
		{"word":"aviver","word_nosc":"aviver","lemma":"aviver","pos":"VER"} ,
		{"word":"avivons","word_nosc":"avivons","lemma":"aviver","pos":"VER"} ,
		{"word":"avivât","word_nosc":"avivat","lemma":"aviver","pos":"VER"} ,
		{"word":"avivèrent","word_nosc":"aviverent","lemma":"aviver","pos":"VER"} ,
		{"word":"avivé","word_nosc":"avive","lemma":"aviver","pos":"VER"} ,
		{"word":"avivée","word_nosc":"avivee","lemma":"aviver","pos":"VER"} ,
		{"word":"avivées","word_nosc":"avivees","lemma":"aviver","pos":"VER"} ,
		{"word":"avivés","word_nosc":"avives","lemma":"aviver","pos":"VER"} ,
		{"word":"avoient","word_nosc":"avoient","lemma":"avoyer","pos":"VER"} ,
		{"word":"avoinaient","word_nosc":"avoinaient","lemma":"avoiner","pos":"VER"} ,
		{"word":"avoiner","word_nosc":"avoiner","lemma":"avoiner","pos":"VER"} ,
		{"word":"avoir","word_nosc":"avoir","lemma":"avoir","pos":"VER"} ,
		{"word":"avoisinaient","word_nosc":"avoisinaient","lemma":"avoisiner","pos":"VER"} ,
		{"word":"avoisinait","word_nosc":"avoisinait","lemma":"avoisiner","pos":"VER"} ,
		{"word":"avoisinant","word_nosc":"avoisinant","lemma":"avoisiner","pos":"VER"} ,
		{"word":"avoisine","word_nosc":"avoisine","lemma":"avoisiner","pos":"VER"} ,
		{"word":"avoisinent","word_nosc":"avoisinent","lemma":"avoisiner","pos":"VER"} ,
		{"word":"avoisiner","word_nosc":"avoisiner","lemma":"avoisiner","pos":"VER"} ,
		{"word":"avoisinerait","word_nosc":"avoisinerait","lemma":"avoisiner","pos":"VER"} ,
		{"word":"avons","word_nosc":"avons","lemma":"avoir","pos":"VER"} ,
		{"word":"avorta","word_nosc":"avorta","lemma":"avorter","pos":"VER"} ,
		{"word":"avortait","word_nosc":"avortait","lemma":"avorter","pos":"VER"} ,
		{"word":"avortant","word_nosc":"avortant","lemma":"avorter","pos":"VER"} ,
		{"word":"avorte","word_nosc":"avorte","lemma":"avorter","pos":"VER"} ,
		{"word":"avortent","word_nosc":"avortent","lemma":"avorter","pos":"VER"} ,
		{"word":"avorter","word_nosc":"avorter","lemma":"avorter","pos":"VER"} ,
		{"word":"avorterai","word_nosc":"avorterai","lemma":"avorter","pos":"VER"} ,
		{"word":"avorterait","word_nosc":"avorterait","lemma":"avorter","pos":"VER"} ,
		{"word":"avorteriez","word_nosc":"avorteriez","lemma":"avorter","pos":"VER"} ,
		{"word":"avortez","word_nosc":"avortez","lemma":"avorter","pos":"VER"} ,
		{"word":"avortons","word_nosc":"avortons","lemma":"avorter","pos":"VER"} ,
		{"word":"avorté","word_nosc":"avorte","lemma":"avorter","pos":"VER"} ,
		{"word":"avortée","word_nosc":"avortee","lemma":"avorter","pos":"VER"} ,
		{"word":"avortées","word_nosc":"avortees","lemma":"avorter","pos":"VER"} ,
		{"word":"avortés","word_nosc":"avortes","lemma":"avorter","pos":"VER"} ,
		{"word":"avoua","word_nosc":"avoua","lemma":"avouer","pos":"VER"} ,
		{"word":"avouai","word_nosc":"avouai","lemma":"avouer","pos":"VER"} ,
		{"word":"avouaient","word_nosc":"avouaient","lemma":"avouer","pos":"VER"} ,
		{"word":"avouais","word_nosc":"avouais","lemma":"avouer","pos":"VER"} ,
		{"word":"avouait","word_nosc":"avouait","lemma":"avouer","pos":"VER"} ,
		{"word":"avouant","word_nosc":"avouant","lemma":"avouer","pos":"VER"} ,
		{"word":"avouas","word_nosc":"avouas","lemma":"avouer","pos":"VER"} ,
		{"word":"avoue","word_nosc":"avoue","lemma":"avouer","pos":"VER"} ,
		{"word":"avouent","word_nosc":"avouent","lemma":"avouer","pos":"VER"} ,
		{"word":"avouer","word_nosc":"avouer","lemma":"avouer","pos":"VER"} ,
		{"word":"avouera","word_nosc":"avouera","lemma":"avouer","pos":"VER"} ,
		{"word":"avouerai","word_nosc":"avouerai","lemma":"avouer","pos":"VER"} ,
		{"word":"avoueraient","word_nosc":"avoueraient","lemma":"avouer","pos":"VER"} ,
		{"word":"avouerais","word_nosc":"avouerais","lemma":"avouer","pos":"VER"} ,
		{"word":"avouerait","word_nosc":"avouerait","lemma":"avouer","pos":"VER"} ,
		{"word":"avoueras","word_nosc":"avoueras","lemma":"avouer","pos":"VER"} ,
		{"word":"avouerez","word_nosc":"avouerez","lemma":"avouer","pos":"VER"} ,
		{"word":"avoueriez","word_nosc":"avoueriez","lemma":"avouer","pos":"VER"} ,
		{"word":"avouerions","word_nosc":"avouerions","lemma":"avouer","pos":"VER"} ,
		{"word":"avoueront","word_nosc":"avoueront","lemma":"avouer","pos":"VER"} ,
		{"word":"avoues","word_nosc":"avoues","lemma":"avouer","pos":"VER"} ,
		{"word":"avouez","word_nosc":"avouez","lemma":"avouer","pos":"VER"} ,
		{"word":"avouiez","word_nosc":"avouiez","lemma":"avouer","pos":"VER"} ,
		{"word":"avouions","word_nosc":"avouions","lemma":"avouer","pos":"VER"} ,
		{"word":"avouons","word_nosc":"avouons","lemma":"avouer","pos":"VER"} ,
		{"word":"avouâmes","word_nosc":"avouames","lemma":"avouer","pos":"VER"} ,
		{"word":"avouât","word_nosc":"avouat","lemma":"avouer","pos":"VER"} ,
		{"word":"avouèrent","word_nosc":"avouerent","lemma":"avouer","pos":"VER"} ,
		{"word":"avoué","word_nosc":"avoue","lemma":"avouer","pos":"VER"} ,
		{"word":"avouée","word_nosc":"avouee","lemma":"avouer","pos":"VER"} ,
		{"word":"avouées","word_nosc":"avouees","lemma":"avouer","pos":"VER"} ,
		{"word":"avoués","word_nosc":"avoues","lemma":"avouer","pos":"VER"} ,
		{"word":"avoyer","word_nosc":"avoyer","lemma":"avoyer","pos":"VER"} ,
		{"word":"avère","word_nosc":"avere","lemma":"avérer","pos":"VER"} ,
		{"word":"avèrent","word_nosc":"averent","lemma":"avérer","pos":"VER"} ,
		{"word":"avéra","word_nosc":"avera","lemma":"avérer","pos":"VER"} ,
		{"word":"avérai","word_nosc":"averai","lemma":"avérer","pos":"VER"} ,
		{"word":"avéraient","word_nosc":"averaient","lemma":"avérer","pos":"VER"} ,
		{"word":"avérait","word_nosc":"averait","lemma":"avérer","pos":"VER"} ,
		{"word":"avérant","word_nosc":"averant","lemma":"avérer","pos":"VER"} ,
		{"word":"avérer","word_nosc":"averer","lemma":"avérer","pos":"VER"} ,
		{"word":"avérera","word_nosc":"averera","lemma":"avérer","pos":"VER"} ,
		{"word":"avéreraient","word_nosc":"avereraient","lemma":"avérer","pos":"VER"} ,
		{"word":"avérerait","word_nosc":"avererait","lemma":"avérer","pos":"VER"} ,
		{"word":"avérât","word_nosc":"averat","lemma":"avérer","pos":"VER"} ,
		{"word":"avérèrent","word_nosc":"avererent","lemma":"avérer","pos":"VER"} ,
		{"word":"avéré","word_nosc":"avere","lemma":"avérer","pos":"VER"} ,
		{"word":"avérée","word_nosc":"averee","lemma":"avérer","pos":"VER"} ,
		{"word":"avérées","word_nosc":"averees","lemma":"avérer","pos":"VER"} ,
		{"word":"avérés","word_nosc":"averes","lemma":"avérer","pos":"VER"} ,
		{"word":"axa","word_nosc":"axa","lemma":"axer","pos":"VER"} ,
		{"word":"axe","word_nosc":"axe","lemma":"axer","pos":"VER"} ,
		{"word":"axent","word_nosc":"axent","lemma":"axer","pos":"VER"} ,
		{"word":"axer","word_nosc":"axer","lemma":"axer","pos":"VER"} ,
		{"word":"axé","word_nosc":"axe","lemma":"axer","pos":"VER"} ,
		{"word":"axée","word_nosc":"axee","lemma":"axer","pos":"VER"} ,
		{"word":"axées","word_nosc":"axees","lemma":"axer","pos":"VER"} ,
		{"word":"axés","word_nosc":"axes","lemma":"axer","pos":"VER"} ,
		{"word":"ayant","word_nosc":"ayant","lemma":"avoir","pos":"VER"} ,
		{"word":"ayez","word_nosc":"ayez","lemma":"avoir","pos":"VER"} ,
		{"word":"ayons","word_nosc":"ayons","lemma":"avoir","pos":"VER"} ,
		{"word":"azimuté","word_nosc":"azimute","lemma":"azimuter","pos":"VER"} ,
		{"word":"azimutée","word_nosc":"azimutee","lemma":"azimuter","pos":"VER"} ,
		{"word":"azuré","word_nosc":"azure","lemma":"azurer","pos":"VER"} ,
		{"word":"azurée","word_nosc":"azuree","lemma":"azurer","pos":"VER"} ,
		{"word":"aère","word_nosc":"aere","lemma":"aérer","pos":"VER"} ,
		{"word":"aéraient","word_nosc":"aeraient","lemma":"aérer","pos":"VER"} ,
		{"word":"aérait","word_nosc":"aerait","lemma":"aérer","pos":"VER"} ,
		{"word":"aérer","word_nosc":"aerer","lemma":"aérer","pos":"VER"} ,
		{"word":"aérez","word_nosc":"aerez","lemma":"aérer","pos":"VER"} ,
		{"word":"aérons","word_nosc":"aerons","lemma":"aérer","pos":"VER"} ,
		{"word":"aérèrent","word_nosc":"aererent","lemma":"aérer","pos":"VER"} ,
		{"word":"aéré","word_nosc":"aere","lemma":"aérer","pos":"VER"} ,
		{"word":"aérée","word_nosc":"aeree","lemma":"aérer","pos":"VER"} ,
		{"word":"aérés","word_nosc":"aeres","lemma":"aérer","pos":"VER"} ,
		{"word":"babillaient","word_nosc":"babillaient","lemma":"babiller","pos":"VER"} ,
		{"word":"babillait","word_nosc":"babillait","lemma":"babiller","pos":"VER"} ,
		{"word":"babillant","word_nosc":"babillant","lemma":"babiller","pos":"VER"} ,
		{"word":"babille","word_nosc":"babille","lemma":"babiller","pos":"VER"} ,
		{"word":"babillent","word_nosc":"babillent","lemma":"babiller","pos":"VER"} ,
		{"word":"babiller","word_nosc":"babiller","lemma":"babiller","pos":"VER"} ,
		{"word":"babilles","word_nosc":"babilles","lemma":"babiller","pos":"VER"} ,
		{"word":"babillé","word_nosc":"babille","lemma":"babiller","pos":"VER"} ,
		{"word":"bachotaient","word_nosc":"bachotaient","lemma":"bachoter","pos":"VER"} ,
		{"word":"bachotait","word_nosc":"bachotait","lemma":"bachoter","pos":"VER"} ,
		{"word":"bachotant","word_nosc":"bachotant","lemma":"bachoter","pos":"VER"} ,
		{"word":"bachoter","word_nosc":"bachoter","lemma":"bachoter","pos":"VER"} ,
		{"word":"bada","word_nosc":"bada","lemma":"bader","pos":"VER"} ,
		{"word":"badant","word_nosc":"badant","lemma":"bader","pos":"VER"} ,
		{"word":"badauda","word_nosc":"badauda","lemma":"badauder","pos":"VER"} ,
		{"word":"badaudaient","word_nosc":"badaudaient","lemma":"badauder","pos":"VER"} ,
		{"word":"badaudant","word_nosc":"badaudant","lemma":"badauder","pos":"VER"} ,
		{"word":"badaude","word_nosc":"badaude","lemma":"badauder","pos":"VER"} ,
		{"word":"badauder","word_nosc":"badauder","lemma":"badauder","pos":"VER"} ,
		{"word":"bader","word_nosc":"bader","lemma":"bader","pos":"VER"} ,
		{"word":"bades","word_nosc":"bades","lemma":"bader","pos":"VER"} ,
		{"word":"badigeonna","word_nosc":"badigeonna","lemma":"badigeonner","pos":"VER"} ,
		{"word":"badigeonnaient","word_nosc":"badigeonnaient","lemma":"badigeonner","pos":"VER"} ,
		{"word":"badigeonnait","word_nosc":"badigeonnait","lemma":"badigeonner","pos":"VER"} ,
		{"word":"badigeonnant","word_nosc":"badigeonnant","lemma":"badigeonner","pos":"VER"} ,
		{"word":"badigeonne","word_nosc":"badigeonne","lemma":"badigeonner","pos":"VER"} ,
		{"word":"badigeonner","word_nosc":"badigeonner","lemma":"badigeonner","pos":"VER"} ,
		{"word":"badigeonnerai","word_nosc":"badigeonnerai","lemma":"badigeonner","pos":"VER"} ,
		{"word":"badigeonnerez","word_nosc":"badigeonnerez","lemma":"badigeonner","pos":"VER"} ,
		{"word":"badigeonnions","word_nosc":"badigeonnions","lemma":"badigeonner","pos":"VER"} ,
		{"word":"badigeonnèrent","word_nosc":"badigeonnerent","lemma":"badigeonner","pos":"VER"} ,
		{"word":"badigeonné","word_nosc":"badigeonne","lemma":"badigeonner","pos":"VER"} ,
		{"word":"badigeonnée","word_nosc":"badigeonnee","lemma":"badigeonner","pos":"VER"} ,
		{"word":"badigeonnées","word_nosc":"badigeonnees","lemma":"badigeonner","pos":"VER"} ,
		{"word":"badigeonnés","word_nosc":"badigeonnes","lemma":"badigeonner","pos":"VER"} ,
		{"word":"badina","word_nosc":"badina","lemma":"badiner","pos":"VER"} ,
		{"word":"badinaient","word_nosc":"badinaient","lemma":"badiner","pos":"VER"} ,
		{"word":"badinais","word_nosc":"badinais","lemma":"badiner","pos":"VER"} ,
		{"word":"badinait","word_nosc":"badinait","lemma":"badiner","pos":"VER"} ,
		{"word":"badine","word_nosc":"badine","lemma":"badiner","pos":"VER"} ,
		{"word":"badiner","word_nosc":"badiner","lemma":"badiner","pos":"VER"} ,
		{"word":"badines","word_nosc":"badines","lemma":"badiner","pos":"VER"} ,
		{"word":"badinez","word_nosc":"badinez","lemma":"badiner","pos":"VER"} ,
		{"word":"badiné","word_nosc":"badine","lemma":"badiner","pos":"VER"} ,
		{"word":"baffe","word_nosc":"baffe","lemma":"baffer","pos":"VER"} ,
		{"word":"baffer","word_nosc":"baffer","lemma":"baffer","pos":"VER"} ,
		{"word":"baffés","word_nosc":"baffes","lemma":"baffer","pos":"VER"} ,
		{"word":"bafouais","word_nosc":"bafouais","lemma":"bafouer","pos":"VER"} ,
		{"word":"bafouait","word_nosc":"bafouait","lemma":"bafouer","pos":"VER"} ,
		{"word":"bafouant","word_nosc":"bafouant","lemma":"bafouer","pos":"VER"} ,
		{"word":"bafoue","word_nosc":"bafoue","lemma":"bafouer","pos":"VER"} ,
		{"word":"bafouent","word_nosc":"bafouent","lemma":"bafouer","pos":"VER"} ,
		{"word":"bafouer","word_nosc":"bafouer","lemma":"bafouer","pos":"VER"} ,
		{"word":"bafoues","word_nosc":"bafoues","lemma":"bafouer","pos":"VER"} ,
		{"word":"bafouez","word_nosc":"bafouez","lemma":"bafouer","pos":"VER"} ,
		{"word":"bafouilla","word_nosc":"bafouilla","lemma":"bafouiller","pos":"VER"} ,
		{"word":"bafouillai","word_nosc":"bafouillai","lemma":"bafouiller","pos":"VER"} ,
		{"word":"bafouillaient","word_nosc":"bafouillaient","lemma":"bafouiller","pos":"VER"} ,
		{"word":"bafouillais","word_nosc":"bafouillais","lemma":"bafouiller","pos":"VER"} ,
		{"word":"bafouillait","word_nosc":"bafouillait","lemma":"bafouiller","pos":"VER"} ,
		{"word":"bafouillant","word_nosc":"bafouillant","lemma":"bafouiller","pos":"VER"} ,
		{"word":"bafouille","word_nosc":"bafouille","lemma":"bafouiller","pos":"VER"} ,
		{"word":"bafouillent","word_nosc":"bafouillent","lemma":"bafouiller","pos":"VER"} ,
		{"word":"bafouiller","word_nosc":"bafouiller","lemma":"bafouiller","pos":"VER"} ,
		{"word":"bafouilles","word_nosc":"bafouilles","lemma":"bafouiller","pos":"VER"} ,
		{"word":"bafouillez","word_nosc":"bafouillez","lemma":"bafouiller","pos":"VER"} ,
		{"word":"bafouillèrent","word_nosc":"bafouillerent","lemma":"bafouiller","pos":"VER"} ,
		{"word":"bafouillé","word_nosc":"bafouille","lemma":"bafouiller","pos":"VER"} ,
		{"word":"bafoué","word_nosc":"bafoue","lemma":"bafouer","pos":"VER"} ,
		{"word":"bafouée","word_nosc":"bafouee","lemma":"bafouer","pos":"VER"} ,
		{"word":"bafouées","word_nosc":"bafouees","lemma":"bafouer","pos":"VER"} ,
		{"word":"bafoués","word_nosc":"bafoues","lemma":"bafouer","pos":"VER"} ,
		{"word":"bagarraient","word_nosc":"bagarraient","lemma":"bagarrer","pos":"VER"} ,
		{"word":"bagarrais","word_nosc":"bagarrais","lemma":"bagarrer","pos":"VER"} ,
		{"word":"bagarrait","word_nosc":"bagarrait","lemma":"bagarrer","pos":"VER"} ,
		{"word":"bagarrant","word_nosc":"bagarrant","lemma":"bagarrer","pos":"VER"} ,
		{"word":"bagarre","word_nosc":"bagarre","lemma":"bagarrer","pos":"VER"} ,
		{"word":"bagarrent","word_nosc":"bagarrent","lemma":"bagarrer","pos":"VER"} ,
		{"word":"bagarrer","word_nosc":"bagarrer","lemma":"bagarrer","pos":"VER"} ,
		{"word":"bagarrerais","word_nosc":"bagarrerais","lemma":"bagarrer","pos":"VER"} ,
		{"word":"bagarrerait","word_nosc":"bagarrerait","lemma":"bagarrer","pos":"VER"} ,
		{"word":"bagarres","word_nosc":"bagarres","lemma":"bagarrer","pos":"VER"} ,
		{"word":"bagarrez","word_nosc":"bagarrez","lemma":"bagarrer","pos":"VER"} ,
		{"word":"bagarrons","word_nosc":"bagarrons","lemma":"bagarrer","pos":"VER"} ,
		{"word":"bagarré","word_nosc":"bagarre","lemma":"bagarrer","pos":"VER"} ,
		{"word":"bagarrée","word_nosc":"bagarree","lemma":"bagarrer","pos":"VER"} ,
		{"word":"bagarrés","word_nosc":"bagarres","lemma":"bagarrer","pos":"VER"} ,
		{"word":"bagote","word_nosc":"bagote","lemma":"bagoter","pos":"VER"} ,
		{"word":"bagoter","word_nosc":"bagoter","lemma":"bagoter","pos":"VER"} ,
		{"word":"bagottaient","word_nosc":"bagottaient","lemma":"bagotter","pos":"VER"} ,
		{"word":"bagottais","word_nosc":"bagottais","lemma":"bagotter","pos":"VER"} ,
		{"word":"bagotte","word_nosc":"bagotte","lemma":"bagotter","pos":"VER"} ,
		{"word":"bagotter","word_nosc":"bagotter","lemma":"bagotter","pos":"VER"} ,
		{"word":"bagoulait","word_nosc":"bagoulait","lemma":"bagouler","pos":"VER"} ,
		{"word":"bagoule","word_nosc":"bagoule","lemma":"bagouler","pos":"VER"} ,
		{"word":"bague","word_nosc":"bague","lemma":"baguer","pos":"VER"} ,
		{"word":"baguenaudaient","word_nosc":"baguenaudaient","lemma":"baguenauder","pos":"VER"} ,
		{"word":"baguenaudais","word_nosc":"baguenaudais","lemma":"baguenauder","pos":"VER"} ,
		{"word":"baguenaudait","word_nosc":"baguenaudait","lemma":"baguenauder","pos":"VER"} ,
		{"word":"baguenaudant","word_nosc":"baguenaudant","lemma":"baguenauder","pos":"VER"} ,
		{"word":"baguenaude","word_nosc":"baguenaude","lemma":"baguenauder","pos":"VER"} ,
		{"word":"baguenaudent","word_nosc":"baguenaudent","lemma":"baguenauder","pos":"VER"} ,
		{"word":"baguenauder","word_nosc":"baguenauder","lemma":"baguenauder","pos":"VER"} ,
		{"word":"baguenauderait","word_nosc":"baguenauderait","lemma":"baguenauder","pos":"VER"} ,
		{"word":"baguenaudé","word_nosc":"baguenaude","lemma":"baguenauder","pos":"VER"} ,
		{"word":"baguer","word_nosc":"baguer","lemma":"baguer","pos":"VER"} ,
		{"word":"bagué","word_nosc":"bague","lemma":"baguer","pos":"VER"} ,
		{"word":"baguée","word_nosc":"baguee","lemma":"baguer","pos":"VER"} ,
		{"word":"baguées","word_nosc":"baguees","lemma":"baguer","pos":"VER"} ,
		{"word":"bagués","word_nosc":"bagues","lemma":"baguer","pos":"VER"} ,
		{"word":"bahute","word_nosc":"bahute","lemma":"bahuter","pos":"VER"} ,
		{"word":"bahutent","word_nosc":"bahutent","lemma":"bahuter","pos":"VER"} ,
		{"word":"baie","word_nosc":"baie","lemma":"bayer","pos":"VER"} ,
		{"word":"baigna","word_nosc":"baigna","lemma":"baigner","pos":"VER"} ,
		{"word":"baignai","word_nosc":"baignai","lemma":"baigner","pos":"VER"} ,
		{"word":"baignaient","word_nosc":"baignaient","lemma":"baigner","pos":"VER"} ,
		{"word":"baignais","word_nosc":"baignais","lemma":"baigner","pos":"VER"} ,
		{"word":"baignait","word_nosc":"baignait","lemma":"baigner","pos":"VER"} ,
		{"word":"baignant","word_nosc":"baignant","lemma":"baigner","pos":"VER"} ,
		{"word":"baigne","word_nosc":"baigne","lemma":"baigner","pos":"VER"} ,
		{"word":"baignent","word_nosc":"baignent","lemma":"baigner","pos":"VER"} ,
		{"word":"baigner","word_nosc":"baigner","lemma":"baigner","pos":"VER"} ,
		{"word":"baignera","word_nosc":"baignera","lemma":"baigner","pos":"VER"} ,
		{"word":"baigneraient","word_nosc":"baigneraient","lemma":"baigner","pos":"VER"} ,
		{"word":"baignerait","word_nosc":"baignerait","lemma":"baigner","pos":"VER"} ,
		{"word":"baignerons","word_nosc":"baignerons","lemma":"baigner","pos":"VER"} ,
		{"word":"baigneront","word_nosc":"baigneront","lemma":"baigner","pos":"VER"} ,
		{"word":"baignes","word_nosc":"baignes","lemma":"baigner","pos":"VER"} ,
		{"word":"baignez","word_nosc":"baignez","lemma":"baigner","pos":"VER"} ,
		{"word":"baignions","word_nosc":"baignions","lemma":"baigner","pos":"VER"} ,
		{"word":"baignons","word_nosc":"baignons","lemma":"baigner","pos":"VER"} ,
		{"word":"baignèrent","word_nosc":"baignerent","lemma":"baigner","pos":"VER"} ,
		{"word":"baigné","word_nosc":"baigne","lemma":"baigner","pos":"VER"} ,
		{"word":"baignée","word_nosc":"baignee","lemma":"baigner","pos":"VER"} ,
		{"word":"baignées","word_nosc":"baignees","lemma":"baigner","pos":"VER"} ,
		{"word":"baignés","word_nosc":"baignes","lemma":"baigner","pos":"VER"} ,
		{"word":"baillait","word_nosc":"baillait","lemma":"bailler","pos":"VER"} ,
		{"word":"baillant","word_nosc":"baillant","lemma":"bailler","pos":"VER"} ,
		{"word":"baille","word_nosc":"baille","lemma":"bailler","pos":"VER"} ,
		{"word":"bailler","word_nosc":"bailler","lemma":"bailler","pos":"VER"} ,
		{"word":"bailles","word_nosc":"bailles","lemma":"bailler","pos":"VER"} ,
		{"word":"baillez","word_nosc":"baillez","lemma":"bailler","pos":"VER"} ,
		{"word":"baillé","word_nosc":"baille","lemma":"bailler","pos":"VER"} ,
		{"word":"baisa","word_nosc":"baisa","lemma":"baiser","pos":"VER"} ,
		{"word":"baisai","word_nosc":"baisai","lemma":"baiser","pos":"VER"} ,
		{"word":"baisaient","word_nosc":"baisaient","lemma":"baiser","pos":"VER"} ,
		{"word":"baisais","word_nosc":"baisais","lemma":"baiser","pos":"VER"} ,
		{"word":"baisait","word_nosc":"baisait","lemma":"baiser","pos":"VER"} ,
		{"word":"baisant","word_nosc":"baisant","lemma":"baiser","pos":"VER"} ,
		{"word":"baise","word_nosc":"baise","lemma":"baiser","pos":"VER"} ,
		{"word":"baisent","word_nosc":"baisent","lemma":"baiser","pos":"VER"} ,
		{"word":"baiser","word_nosc":"baiser","lemma":"baiser","pos":"VER"} ,
		{"word":"baisera","word_nosc":"baisera","lemma":"baiser","pos":"VER"} ,
		{"word":"baiserai","word_nosc":"baiserai","lemma":"baiser","pos":"VER"} ,
		{"word":"baiseraient","word_nosc":"baiseraient","lemma":"baiser","pos":"VER"} ,
		{"word":"baiserais","word_nosc":"baiserais","lemma":"baiser","pos":"VER"} ,
		{"word":"baiserait","word_nosc":"baiserait","lemma":"baiser","pos":"VER"} ,
		{"word":"baiseras","word_nosc":"baiseras","lemma":"baiser","pos":"VER"} ,
		{"word":"baiserez","word_nosc":"baiserez","lemma":"baiser","pos":"VER"} ,
		{"word":"baiseriez","word_nosc":"baiseriez","lemma":"baiser","pos":"VER"} ,
		{"word":"baiserons","word_nosc":"baiserons","lemma":"baiser","pos":"VER"} ,
		{"word":"baiseront","word_nosc":"baiseront","lemma":"baiser","pos":"VER"} ,
		{"word":"baises","word_nosc":"baises","lemma":"baiser","pos":"VER"} ,
		{"word":"baisez","word_nosc":"baisez","lemma":"baiser","pos":"VER"} ,
		{"word":"baisiez","word_nosc":"baisiez","lemma":"baiser","pos":"VER"} ,
		{"word":"baisions","word_nosc":"baisions","lemma":"baiser","pos":"VER"} ,
		{"word":"baisons","word_nosc":"baisons","lemma":"baiser","pos":"VER"} ,
		{"word":"baisotant","word_nosc":"baisotant","lemma":"baisoter","pos":"VER"} ,
		{"word":"baisouiller","word_nosc":"baisouiller","lemma":"baisouiller","pos":"VER"} ,
		{"word":"baisouillé","word_nosc":"baisouille","lemma":"baisouiller","pos":"VER"} ,
		{"word":"baissa","word_nosc":"baissa","lemma":"baisser","pos":"VER"} ,
		{"word":"baissai","word_nosc":"baissai","lemma":"baisser","pos":"VER"} ,
		{"word":"baissaient","word_nosc":"baissaient","lemma":"baisser","pos":"VER"} ,
		{"word":"baissais","word_nosc":"baissais","lemma":"baisser","pos":"VER"} ,
		{"word":"baissait","word_nosc":"baissait","lemma":"baisser","pos":"VER"} ,
		{"word":"baissant","word_nosc":"baissant","lemma":"baisser","pos":"VER"} ,
		{"word":"baisse","word_nosc":"baisse","lemma":"baisser","pos":"VER"} ,
		{"word":"baissent","word_nosc":"baissent","lemma":"baisser","pos":"VER"} ,
		{"word":"baisser","word_nosc":"baisser","lemma":"baisser","pos":"VER"} ,
		{"word":"baissera","word_nosc":"baissera","lemma":"baisser","pos":"VER"} ,
		{"word":"baisserai","word_nosc":"baisserai","lemma":"baisser","pos":"VER"} ,
		{"word":"baisseraient","word_nosc":"baisseraient","lemma":"baisser","pos":"VER"} ,
		{"word":"baisserais","word_nosc":"baisserais","lemma":"baisser","pos":"VER"} ,
		{"word":"baisserait","word_nosc":"baisserait","lemma":"baisser","pos":"VER"} ,
		{"word":"baisseras","word_nosc":"baisseras","lemma":"baisser","pos":"VER"} ,
		{"word":"baisserez","word_nosc":"baisserez","lemma":"baisser","pos":"VER"} ,
		{"word":"baisserons","word_nosc":"baisserons","lemma":"baisser","pos":"VER"} ,
		{"word":"baisseront","word_nosc":"baisseront","lemma":"baisser","pos":"VER"} ,
		{"word":"baisses","word_nosc":"baisses","lemma":"baisser","pos":"VER"} ,
		{"word":"baissez","word_nosc":"baissez","lemma":"baisser","pos":"VER"} ,
		{"word":"baissiez","word_nosc":"baissiez","lemma":"baisser","pos":"VER"} ,
		{"word":"baissions","word_nosc":"baissions","lemma":"baisser","pos":"VER"} ,
		{"word":"baissons","word_nosc":"baissons","lemma":"baisser","pos":"VER"} ,
		{"word":"baissâmes","word_nosc":"baissames","lemma":"baisser","pos":"VER"} ,
		{"word":"baissât","word_nosc":"baissat","lemma":"baisser","pos":"VER"} ,
		{"word":"baissèrent","word_nosc":"baisserent","lemma":"baisser","pos":"VER"} ,
		{"word":"baissé","word_nosc":"baisse","lemma":"baisser","pos":"VER"} ,
		{"word":"baissée","word_nosc":"baissee","lemma":"baisser","pos":"VER"} ,
		{"word":"baissées","word_nosc":"baissees","lemma":"baisser","pos":"VER"} ,
		{"word":"baissés","word_nosc":"baisses","lemma":"baisser","pos":"VER"} ,
		{"word":"baisèrent","word_nosc":"baiserent","lemma":"baiser","pos":"VER"} ,
		{"word":"baisé","word_nosc":"baise","lemma":"baiser","pos":"VER"} ,
		{"word":"baisée","word_nosc":"baisee","lemma":"baiser","pos":"VER"} ,
		{"word":"baisées","word_nosc":"baisees","lemma":"baiser","pos":"VER"} ,
		{"word":"baisés","word_nosc":"baises","lemma":"baiser","pos":"VER"} ,
		{"word":"balada","word_nosc":"balada","lemma":"balader","pos":"VER"} ,
		{"word":"baladai","word_nosc":"baladai","lemma":"balader","pos":"VER"} ,
		{"word":"baladaient","word_nosc":"baladaient","lemma":"balader","pos":"VER"} ,
		{"word":"baladais","word_nosc":"baladais","lemma":"balader","pos":"VER"} ,
		{"word":"baladait","word_nosc":"baladait","lemma":"balader","pos":"VER"} ,
		{"word":"baladant","word_nosc":"baladant","lemma":"balader","pos":"VER"} ,
		{"word":"balade","word_nosc":"balade","lemma":"balader","pos":"VER"} ,
		{"word":"baladent","word_nosc":"baladent","lemma":"balader","pos":"VER"} ,
		{"word":"balader","word_nosc":"balader","lemma":"balader","pos":"VER"} ,
		{"word":"baladera","word_nosc":"baladera","lemma":"balader","pos":"VER"} ,
		{"word":"baladerai","word_nosc":"baladerai","lemma":"balader","pos":"VER"} ,
		{"word":"baladerais","word_nosc":"baladerais","lemma":"balader","pos":"VER"} ,
		{"word":"baladerait","word_nosc":"baladerait","lemma":"balader","pos":"VER"} ,
		{"word":"baladerez","word_nosc":"baladerez","lemma":"balader","pos":"VER"} ,
		{"word":"balades","word_nosc":"balades","lemma":"balader","pos":"VER"} ,
		{"word":"baladez","word_nosc":"baladez","lemma":"balader","pos":"VER"} ,
		{"word":"baladiez","word_nosc":"baladiez","lemma":"balader","pos":"VER"} ,
		{"word":"baladèrent","word_nosc":"baladerent","lemma":"balader","pos":"VER"} ,
		{"word":"baladé","word_nosc":"balade","lemma":"balader","pos":"VER"} ,
		{"word":"baladée","word_nosc":"baladee","lemma":"balader","pos":"VER"} ,
		{"word":"baladées","word_nosc":"baladees","lemma":"balader","pos":"VER"} ,
		{"word":"baladés","word_nosc":"balades","lemma":"balader","pos":"VER"} ,
		{"word":"balafra","word_nosc":"balafra","lemma":"balafrer","pos":"VER"} ,
		{"word":"balafraient","word_nosc":"balafraient","lemma":"balafrer","pos":"VER"} ,
		{"word":"balafrait","word_nosc":"balafrait","lemma":"balafrer","pos":"VER"} ,
		{"word":"balafre","word_nosc":"balafre","lemma":"balafrer","pos":"VER"} ,
		{"word":"balafrent","word_nosc":"balafrent","lemma":"balafrer","pos":"VER"} ,
		{"word":"balafrer","word_nosc":"balafrer","lemma":"balafrer","pos":"VER"} ,
		{"word":"balafrons","word_nosc":"balafrons","lemma":"balafrer","pos":"VER"} ,
		{"word":"balafrât","word_nosc":"balafrat","lemma":"balafrer","pos":"VER"} ,
		{"word":"balafré","word_nosc":"balafre","lemma":"balafrer","pos":"VER"} ,
		{"word":"balafrée","word_nosc":"balafree","lemma":"balafrer","pos":"VER"} ,
		{"word":"balafrées","word_nosc":"balafrees","lemma":"balafrer","pos":"VER"} ,
		{"word":"balafrés","word_nosc":"balafres","lemma":"balafrer","pos":"VER"} ,
		{"word":"balaie","word_nosc":"balaie","lemma":"balayer","pos":"VER"} ,
		{"word":"balaient","word_nosc":"balaient","lemma":"balayer","pos":"VER"} ,
		{"word":"balaiera","word_nosc":"balaiera","lemma":"balayer","pos":"VER"} ,
		{"word":"balaierai","word_nosc":"balaierai","lemma":"balayer","pos":"VER"} ,
		{"word":"balaieraient","word_nosc":"balaieraient","lemma":"balayer","pos":"VER"} ,
		{"word":"balaierait","word_nosc":"balaierait","lemma":"balayer","pos":"VER"} ,
		{"word":"balaieras","word_nosc":"balaieras","lemma":"balayer","pos":"VER"} ,
		{"word":"balaierez","word_nosc":"balaierez","lemma":"balayer","pos":"VER"} ,
		{"word":"balaies","word_nosc":"balaies","lemma":"balayer","pos":"VER"} ,
		{"word":"balance","word_nosc":"balance","lemma":"balancer","pos":"VER"} ,
		{"word":"balancent","word_nosc":"balancent","lemma":"balancer","pos":"VER"} ,
		{"word":"balancer","word_nosc":"balancer","lemma":"balancer","pos":"VER"} ,
		{"word":"balancera","word_nosc":"balancera","lemma":"balancer","pos":"VER"} ,
		{"word":"balancerai","word_nosc":"balancerai","lemma":"balancer","pos":"VER"} ,
		{"word":"balanceraient","word_nosc":"balanceraient","lemma":"balancer","pos":"VER"} ,
		{"word":"balancerais","word_nosc":"balancerais","lemma":"balancer","pos":"VER"} ,
		{"word":"balancerait","word_nosc":"balancerait","lemma":"balancer","pos":"VER"} ,
		{"word":"balanceras","word_nosc":"balanceras","lemma":"balancer","pos":"VER"} ,
		{"word":"balancerez","word_nosc":"balancerez","lemma":"balancer","pos":"VER"} ,
		{"word":"balanceriez","word_nosc":"balanceriez","lemma":"balancer","pos":"VER"} ,
		{"word":"balanceront","word_nosc":"balanceront","lemma":"balancer","pos":"VER"} ,
		{"word":"balances","word_nosc":"balances","lemma":"balancer","pos":"VER"} ,
		{"word":"balancez","word_nosc":"balancez","lemma":"balancer","pos":"VER"} ,
		{"word":"balanciez","word_nosc":"balanciez","lemma":"balancer","pos":"VER"} ,
		{"word":"balancions","word_nosc":"balancions","lemma":"balancer","pos":"VER"} ,
		{"word":"balancèrent","word_nosc":"balancerent","lemma":"balancer","pos":"VER"} ,
		{"word":"balancé","word_nosc":"balance","lemma":"balancer","pos":"VER"} ,
		{"word":"balancée","word_nosc":"balancee","lemma":"balancer","pos":"VER"} ,
		{"word":"balancées","word_nosc":"balancees","lemma":"balancer","pos":"VER"} ,
		{"word":"balancés","word_nosc":"balances","lemma":"balancer","pos":"VER"} ,
		{"word":"balanstiquant","word_nosc":"balanstiquant","lemma":"balanstiquer","pos":"VER"} ,
		{"word":"balanstique","word_nosc":"balanstique","lemma":"balanstiquer","pos":"VER"} ,
		{"word":"balanstiquer","word_nosc":"balanstiquer","lemma":"balanstiquer","pos":"VER"} ,
		{"word":"balança","word_nosc":"balanca","lemma":"balancer","pos":"VER"} ,
		{"word":"balançai","word_nosc":"balancai","lemma":"balancer","pos":"VER"} ,
		{"word":"balançaient","word_nosc":"balancaient","lemma":"balancer","pos":"VER"} ,
		{"word":"balançais","word_nosc":"balancais","lemma":"balancer","pos":"VER"} ,
		{"word":"balançait","word_nosc":"balancait","lemma":"balancer","pos":"VER"} ,
		{"word":"balançant","word_nosc":"balancant","lemma":"balancer","pos":"VER"} ,
		{"word":"balançons","word_nosc":"balancons","lemma":"balancer","pos":"VER"} ,
		{"word":"balaya","word_nosc":"balaya","lemma":"balayer","pos":"VER"} ,
		{"word":"balayai","word_nosc":"balayai","lemma":"balayer","pos":"VER"} ,
		{"word":"balayaient","word_nosc":"balayaient","lemma":"balayer","pos":"VER"} ,
		{"word":"balayais","word_nosc":"balayais","lemma":"balayer","pos":"VER"} ,
		{"word":"balayait","word_nosc":"balayait","lemma":"balayer","pos":"VER"} ,
		{"word":"balayant","word_nosc":"balayant","lemma":"balayer","pos":"VER"} ,
		{"word":"balaye","word_nosc":"balaye","lemma":"balayer","pos":"VER"} ,
		{"word":"balayent","word_nosc":"balayent","lemma":"balayer","pos":"VER"} ,
		{"word":"balayer","word_nosc":"balayer","lemma":"balayer","pos":"VER"} ,
		{"word":"balayera","word_nosc":"balayera","lemma":"balayer","pos":"VER"} ,
		{"word":"balayerai","word_nosc":"balayerai","lemma":"balayer","pos":"VER"} ,
		{"word":"balayeraient","word_nosc":"balayeraient","lemma":"balayer","pos":"VER"} ,
		{"word":"balayerait","word_nosc":"balayerait","lemma":"balayer","pos":"VER"} ,
		{"word":"balayerez","word_nosc":"balayerez","lemma":"balayer","pos":"VER"} ,
		{"word":"balayerons","word_nosc":"balayerons","lemma":"balayer","pos":"VER"} ,
		{"word":"balayeront","word_nosc":"balayeront","lemma":"balayer","pos":"VER"} ,
		{"word":"balayez","word_nosc":"balayez","lemma":"balayer","pos":"VER"} ,
		{"word":"balayons","word_nosc":"balayons","lemma":"balayer","pos":"VER"} ,
		{"word":"balayât","word_nosc":"balayat","lemma":"balayer","pos":"VER"} ,
		{"word":"balayèrent","word_nosc":"balayerent","lemma":"balayer","pos":"VER"} ,
		{"word":"balayé","word_nosc":"balaye","lemma":"balayer","pos":"VER"} ,
		{"word":"balayée","word_nosc":"balayee","lemma":"balayer","pos":"VER"} ,
		{"word":"balayées","word_nosc":"balayees","lemma":"balayer","pos":"VER"} ,
		{"word":"balayés","word_nosc":"balayes","lemma":"balayer","pos":"VER"} ,
		{"word":"balbutia","word_nosc":"balbutia","lemma":"balbutier","pos":"VER"} ,
		{"word":"balbutiai","word_nosc":"balbutiai","lemma":"balbutier","pos":"VER"} ,
		{"word":"balbutiaient","word_nosc":"balbutiaient","lemma":"balbutier","pos":"VER"} ,
		{"word":"balbutiais","word_nosc":"balbutiais","lemma":"balbutier","pos":"VER"} ,
		{"word":"balbutiait","word_nosc":"balbutiait","lemma":"balbutier","pos":"VER"} ,
		{"word":"balbutiant","word_nosc":"balbutiant","lemma":"balbutier","pos":"VER"} ,
		{"word":"balbutie","word_nosc":"balbutie","lemma":"balbutier","pos":"VER"} ,
		{"word":"balbutier","word_nosc":"balbutier","lemma":"balbutier","pos":"VER"} ,
		{"word":"balbutions","word_nosc":"balbutions","lemma":"balbutier","pos":"VER"} ,
		{"word":"balbutièrent","word_nosc":"balbutierent","lemma":"balbutier","pos":"VER"} ,
		{"word":"balbutié","word_nosc":"balbutie","lemma":"balbutier","pos":"VER"} ,
		{"word":"balbutiées","word_nosc":"balbutiees","lemma":"balbutier","pos":"VER"} ,
		{"word":"balbutiés","word_nosc":"balbuties","lemma":"balbutier","pos":"VER"} ,
		{"word":"balisaient","word_nosc":"balisaient","lemma":"baliser","pos":"VER"} ,
		{"word":"balisait","word_nosc":"balisait","lemma":"baliser","pos":"VER"} ,
		{"word":"balisant","word_nosc":"balisant","lemma":"baliser","pos":"VER"} ,
		{"word":"balise","word_nosc":"balise","lemma":"baliser","pos":"VER"} ,
		{"word":"balisent","word_nosc":"balisent","lemma":"baliser","pos":"VER"} ,
		{"word":"baliser","word_nosc":"baliser","lemma":"baliser","pos":"VER"} ,
		{"word":"baliseraient","word_nosc":"baliseraient","lemma":"baliser","pos":"VER"} ,
		{"word":"balises","word_nosc":"balises","lemma":"baliser","pos":"VER"} ,
		{"word":"balisé","word_nosc":"balise","lemma":"baliser","pos":"VER"} ,
		{"word":"balisée","word_nosc":"balisee","lemma":"baliser","pos":"VER"} ,
		{"word":"balisées","word_nosc":"balisees","lemma":"baliser","pos":"VER"} ,
		{"word":"balisés","word_nosc":"balises","lemma":"baliser","pos":"VER"} ,
		{"word":"balkanisés","word_nosc":"balkanises","lemma":"balkaniser","pos":"VER"} ,
		{"word":"ballaient","word_nosc":"ballaient","lemma":"baller","pos":"VER"} ,
		{"word":"ballant","word_nosc":"ballant","lemma":"baller","pos":"VER"} ,
		{"word":"baller","word_nosc":"baller","lemma":"baller","pos":"VER"} ,
		{"word":"ballonnaient","word_nosc":"ballonnaient","lemma":"ballonner","pos":"VER"} ,
		{"word":"ballonne","word_nosc":"ballonne","lemma":"ballonner","pos":"VER"} ,
		{"word":"ballonnent","word_nosc":"ballonnent","lemma":"ballonner","pos":"VER"} ,
		{"word":"ballonner","word_nosc":"ballonner","lemma":"ballonner","pos":"VER"} ,
		{"word":"ballonné","word_nosc":"ballonne","lemma":"ballonner","pos":"VER"} ,
		{"word":"ballonnée","word_nosc":"ballonnee","lemma":"ballonner","pos":"VER"} ,
		{"word":"ballonnées","word_nosc":"ballonnees","lemma":"ballonner","pos":"VER"} ,
		{"word":"ballonnés","word_nosc":"ballonnes","lemma":"ballonner","pos":"VER"} ,
		{"word":"ballons","word_nosc":"ballons","lemma":"baller","pos":"VER"} ,
		{"word":"ballottaient","word_nosc":"ballottaient","lemma":"ballotter","pos":"VER"} ,
		{"word":"ballottait","word_nosc":"ballottait","lemma":"ballotter","pos":"VER"} ,
		{"word":"ballottant","word_nosc":"ballottant","lemma":"ballotter","pos":"VER"} ,
		{"word":"ballotte","word_nosc":"ballotte","lemma":"ballotter","pos":"VER"} ,
		{"word":"ballottent","word_nosc":"ballottent","lemma":"ballotter","pos":"VER"} ,
		{"word":"ballotter","word_nosc":"ballotter","lemma":"ballotter","pos":"VER"} ,
		{"word":"ballottèrent","word_nosc":"ballotterent","lemma":"ballotter","pos":"VER"} ,
		{"word":"ballotté","word_nosc":"ballotte","lemma":"ballotter","pos":"VER"} ,
		{"word":"ballottée","word_nosc":"ballottee","lemma":"ballotter","pos":"VER"} ,
		{"word":"ballottées","word_nosc":"ballottees","lemma":"ballotter","pos":"VER"} ,
		{"word":"ballottés","word_nosc":"ballottes","lemma":"ballotter","pos":"VER"} ,
		{"word":"balluchonné","word_nosc":"balluchonne","lemma":"balluchonner","pos":"VER"} ,
		{"word":"baluchonne","word_nosc":"baluchonne","lemma":"baluchonner","pos":"VER"} ,
		{"word":"baluchonnent","word_nosc":"baluchonnent","lemma":"baluchonner","pos":"VER"} ,
		{"word":"baluchonner","word_nosc":"baluchonner","lemma":"baluchonner","pos":"VER"} ,
		{"word":"baluchonné","word_nosc":"baluchonne","lemma":"baluchonner","pos":"VER"} ,
		{"word":"bambochait","word_nosc":"bambochait","lemma":"bambocher","pos":"VER"} ,
		{"word":"bamboche","word_nosc":"bamboche","lemma":"bambocher","pos":"VER"} ,
		{"word":"bambocher","word_nosc":"bambocher","lemma":"bambocher","pos":"VER"} ,
		{"word":"banalisait","word_nosc":"banalisait","lemma":"banaliser","pos":"VER"} ,
		{"word":"banalise","word_nosc":"banalise","lemma":"banaliser","pos":"VER"} ,
		{"word":"banalisent","word_nosc":"banalisent","lemma":"banaliser","pos":"VER"} ,
		{"word":"banaliser","word_nosc":"banaliser","lemma":"banaliser","pos":"VER"} ,
		{"word":"banalisé","word_nosc":"banalise","lemma":"banaliser","pos":"VER"} ,
		{"word":"banalisée","word_nosc":"banalisee","lemma":"banaliser","pos":"VER"} ,
		{"word":"banalisées","word_nosc":"banalisees","lemma":"banaliser","pos":"VER"} ,
		{"word":"banalisés","word_nosc":"banalises","lemma":"banaliser","pos":"VER"} ,
		{"word":"banana","word_nosc":"banana","lemma":"bananer","pos":"VER"} ,
		{"word":"bananas","word_nosc":"bananas","lemma":"bananer","pos":"VER"} ,
		{"word":"bananer","word_nosc":"bananer","lemma":"bananer","pos":"VER"} ,
		{"word":"banche","word_nosc":"banche","lemma":"bancher","pos":"VER"} ,
		{"word":"banda","word_nosc":"banda","lemma":"bander","pos":"VER"} ,
		{"word":"bandai","word_nosc":"bandai","lemma":"bander","pos":"VER"} ,
		{"word":"bandaient","word_nosc":"bandaient","lemma":"bander","pos":"VER"} ,
		{"word":"bandais","word_nosc":"bandais","lemma":"bander","pos":"VER"} ,
		{"word":"bandait","word_nosc":"bandait","lemma":"bander","pos":"VER"} ,
		{"word":"bandant","word_nosc":"bandant","lemma":"bander","pos":"VER"} ,
		{"word":"bandasse","word_nosc":"bandasse","lemma":"bander","pos":"VER"} ,
		{"word":"bande","word_nosc":"bande","lemma":"bander","pos":"VER"} ,
		{"word":"bandent","word_nosc":"bandent","lemma":"bander","pos":"VER"} ,
		{"word":"bander","word_nosc":"bander","lemma":"bander","pos":"VER"} ,
		{"word":"bandera","word_nosc":"bandera","lemma":"bander","pos":"VER"} ,
		{"word":"banderai","word_nosc":"banderai","lemma":"bander","pos":"VER"} ,
		{"word":"banderaient","word_nosc":"banderaient","lemma":"bander","pos":"VER"} ,
		{"word":"banderait","word_nosc":"banderait","lemma":"bander","pos":"VER"} ,
		{"word":"banderas","word_nosc":"banderas","lemma":"bander","pos":"VER"} ,
		{"word":"bandes","word_nosc":"bandes","lemma":"bander","pos":"VER"} ,
		{"word":"bandez","word_nosc":"bandez","lemma":"bander","pos":"VER"} ,
		{"word":"bandiez","word_nosc":"bandiez","lemma":"bander","pos":"VER"} ,
		{"word":"bandé","word_nosc":"bande","lemma":"bander","pos":"VER"} ,
		{"word":"bandée","word_nosc":"bandee","lemma":"bander","pos":"VER"} ,
		{"word":"bandées","word_nosc":"bandees","lemma":"bander","pos":"VER"} ,
		{"word":"bandés","word_nosc":"bandes","lemma":"bander","pos":"VER"} ,
		{"word":"banne","word_nosc":"banne","lemma":"banner","pos":"VER"} ,
		{"word":"banner","word_nosc":"banner","lemma":"banner","pos":"VER"} ,
		{"word":"banni","word_nosc":"banni","lemma":"bannir","pos":"VER"} ,
		{"word":"bannie","word_nosc":"bannie","lemma":"bannir","pos":"VER"} ,
		{"word":"bannies","word_nosc":"bannies","lemma":"bannir","pos":"VER"} ,
		{"word":"bannir","word_nosc":"bannir","lemma":"bannir","pos":"VER"} ,
		{"word":"bannirait","word_nosc":"bannirait","lemma":"bannir","pos":"VER"} ,
		{"word":"bannirent","word_nosc":"bannirent","lemma":"bannir","pos":"VER"} ,
		{"word":"bannis","word_nosc":"bannis","lemma":"bannir","pos":"VER"} ,
		{"word":"bannissaient","word_nosc":"bannissaient","lemma":"bannir","pos":"VER"} ,
		{"word":"bannissait","word_nosc":"bannissait","lemma":"bannir","pos":"VER"} ,
		{"word":"bannissant","word_nosc":"bannissant","lemma":"bannir","pos":"VER"} ,
		{"word":"bannisse","word_nosc":"bannisse","lemma":"bannir","pos":"VER"} ,
		{"word":"bannissez","word_nosc":"bannissez","lemma":"bannir","pos":"VER"} ,
		{"word":"bannissons","word_nosc":"bannissons","lemma":"bannir","pos":"VER"} ,
		{"word":"bannit","word_nosc":"bannit","lemma":"bannir","pos":"VER"} ,
		{"word":"banqua","word_nosc":"banqua","lemma":"banquer","pos":"VER"} ,
		{"word":"banquer","word_nosc":"banquer","lemma":"banquer","pos":"VER"} ,
		{"word":"banquerai","word_nosc":"banquerai","lemma":"banquer","pos":"VER"} ,
		{"word":"banqueté","word_nosc":"banquete","lemma":"banqueter","pos":"VER"} ,
		{"word":"banqué","word_nosc":"banque","lemma":"banquer","pos":"VER"} ,
		{"word":"baptisa","word_nosc":"baptisa","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptisai","word_nosc":"baptisai","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptisaient","word_nosc":"baptisaient","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptisais","word_nosc":"baptisais","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptisait","word_nosc":"baptisait","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptisant","word_nosc":"baptisant","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptise","word_nosc":"baptise","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptisent","word_nosc":"baptisent","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptiser","word_nosc":"baptiser","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptisera","word_nosc":"baptisera","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptiserai","word_nosc":"baptiserai","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptiseraient","word_nosc":"baptiseraient","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptiserait","word_nosc":"baptiserait","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptiseront","word_nosc":"baptiseront","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptises","word_nosc":"baptises","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptisez","word_nosc":"baptisez","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptisions","word_nosc":"baptisions","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptisons","word_nosc":"baptisons","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptisèrent","word_nosc":"baptiserent","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptisé","word_nosc":"baptise","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptisée","word_nosc":"baptisee","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptisées","word_nosc":"baptisees","lemma":"baptiser","pos":"VER"} ,
		{"word":"baptisés","word_nosc":"baptises","lemma":"baptiser","pos":"VER"} ,
		{"word":"baragouinaient","word_nosc":"baragouinaient","lemma":"baragouiner","pos":"VER"} ,
		{"word":"baragouinait","word_nosc":"baragouinait","lemma":"baragouiner","pos":"VER"} ,
		{"word":"baragouinant","word_nosc":"baragouinant","lemma":"baragouiner","pos":"VER"} ,
		{"word":"baragouine","word_nosc":"baragouine","lemma":"baragouiner","pos":"VER"} ,
		{"word":"baragouinent","word_nosc":"baragouinent","lemma":"baragouiner","pos":"VER"} ,
		{"word":"baragouiner","word_nosc":"baragouiner","lemma":"baragouiner","pos":"VER"} ,
		{"word":"baragouines","word_nosc":"baragouines","lemma":"baragouiner","pos":"VER"} ,
		{"word":"baragouinez","word_nosc":"baragouinez","lemma":"baragouiner","pos":"VER"} ,
		{"word":"baragouiné","word_nosc":"baragouine","lemma":"baragouiner","pos":"VER"} ,
		{"word":"baraqué","word_nosc":"baraque","lemma":"baraquer","pos":"VER"} ,
		{"word":"baraquée","word_nosc":"baraquee","lemma":"baraquer","pos":"VER"} ,
		{"word":"baraqués","word_nosc":"baraques","lemma":"baraquer","pos":"VER"} ,
		{"word":"baratinais","word_nosc":"baratinais","lemma":"baratiner","pos":"VER"} ,
		{"word":"baratinait","word_nosc":"baratinait","lemma":"baratiner","pos":"VER"} ,
		{"word":"baratine","word_nosc":"baratine","lemma":"baratiner","pos":"VER"} ,
		{"word":"baratinent","word_nosc":"baratinent","lemma":"baratiner","pos":"VER"} ,
		{"word":"baratiner","word_nosc":"baratiner","lemma":"baratiner","pos":"VER"} ,
		{"word":"baratines","word_nosc":"baratines","lemma":"baratiner","pos":"VER"} ,
		{"word":"baratinez","word_nosc":"baratinez","lemma":"baratiner","pos":"VER"} ,
		{"word":"baratiné","word_nosc":"baratine","lemma":"baratiner","pos":"VER"} ,
		{"word":"baratinée","word_nosc":"baratinee","lemma":"baratiner","pos":"VER"} ,
		{"word":"baratinés","word_nosc":"baratines","lemma":"baratiner","pos":"VER"} ,
		{"word":"barattait","word_nosc":"barattait","lemma":"baratter","pos":"VER"} ,
		{"word":"barattant","word_nosc":"barattant","lemma":"baratter","pos":"VER"} ,
		{"word":"baratte","word_nosc":"baratte","lemma":"baratter","pos":"VER"} ,
		{"word":"barattent","word_nosc":"barattent","lemma":"baratter","pos":"VER"} ,
		{"word":"baratter","word_nosc":"baratter","lemma":"baratter","pos":"VER"} ,
		{"word":"baratté","word_nosc":"baratte","lemma":"baratter","pos":"VER"} ,
		{"word":"barattés","word_nosc":"barattes","lemma":"baratter","pos":"VER"} ,
		{"word":"barba","word_nosc":"barba","lemma":"barber","pos":"VER"} ,
		{"word":"barbaient","word_nosc":"barbaient","lemma":"barber","pos":"VER"} ,
		{"word":"barbait","word_nosc":"barbait","lemma":"barber","pos":"VER"} ,
		{"word":"barbant","word_nosc":"barbant","lemma":"barber","pos":"VER"} ,
		{"word":"barbe","word_nosc":"barbe","lemma":"barber","pos":"VER"} ,
		{"word":"barbent","word_nosc":"barbent","lemma":"barber","pos":"VER"} ,
		{"word":"barber","word_nosc":"barber","lemma":"barber","pos":"VER"} ,
		{"word":"barbera","word_nosc":"barbera","lemma":"barber","pos":"VER"} ,
		{"word":"barberait","word_nosc":"barberait","lemma":"barber","pos":"VER"} ,
		{"word":"barbes","word_nosc":"barbes","lemma":"barber","pos":"VER"} ,
		{"word":"barbota","word_nosc":"barbota","lemma":"barboter","pos":"VER"} ,
		{"word":"barbotaient","word_nosc":"barbotaient","lemma":"barboter","pos":"VER"} ,
		{"word":"barbotait","word_nosc":"barbotait","lemma":"barboter","pos":"VER"} ,
		{"word":"barbotant","word_nosc":"barbotant","lemma":"barboter","pos":"VER"} ,
		{"word":"barbote","word_nosc":"barbote","lemma":"barboter","pos":"VER"} ,
		{"word":"barbotent","word_nosc":"barbotent","lemma":"barboter","pos":"VER"} ,
		{"word":"barboter","word_nosc":"barboter","lemma":"barboter","pos":"VER"} ,
		{"word":"barboterais","word_nosc":"barboterais","lemma":"barboter","pos":"VER"} ,
		{"word":"barboterait","word_nosc":"barboterait","lemma":"barboter","pos":"VER"} ,
		{"word":"barbotons","word_nosc":"barbotons","lemma":"barboter","pos":"VER"} ,
		{"word":"barboté","word_nosc":"barbote","lemma":"barboter","pos":"VER"} ,
		{"word":"barbouilla","word_nosc":"barbouilla","lemma":"barbouiller","pos":"VER"} ,
		{"word":"barbouillai","word_nosc":"barbouillai","lemma":"barbouiller","pos":"VER"} ,
		{"word":"barbouillaient","word_nosc":"barbouillaient","lemma":"barbouiller","pos":"VER"} ,
		{"word":"barbouillais","word_nosc":"barbouillais","lemma":"barbouiller","pos":"VER"} ,
		{"word":"barbouillait","word_nosc":"barbouillait","lemma":"barbouiller","pos":"VER"} ,
		{"word":"barbouillant","word_nosc":"barbouillant","lemma":"barbouiller","pos":"VER"} ,
		{"word":"barbouille","word_nosc":"barbouille","lemma":"barbouiller","pos":"VER"} ,
		{"word":"barbouillent","word_nosc":"barbouillent","lemma":"barbouiller","pos":"VER"} ,
		{"word":"barbouiller","word_nosc":"barbouiller","lemma":"barbouiller","pos":"VER"} ,
		{"word":"barbouilles","word_nosc":"barbouilles","lemma":"barbouiller","pos":"VER"} ,
		{"word":"barbouillèrent","word_nosc":"barbouillerent","lemma":"barbouiller","pos":"VER"} ,
		{"word":"barbouillé","word_nosc":"barbouille","lemma":"barbouiller","pos":"VER"} ,
		{"word":"barbouillée","word_nosc":"barbouillee","lemma":"barbouiller","pos":"VER"} ,
		{"word":"barbouillées","word_nosc":"barbouillees","lemma":"barbouiller","pos":"VER"} ,
		{"word":"barbouillés","word_nosc":"barbouilles","lemma":"barbouiller","pos":"VER"} ,
		{"word":"barbé","word_nosc":"barbe","lemma":"barber","pos":"VER"} ,
		{"word":"barbés","word_nosc":"barbes","lemma":"barber","pos":"VER"} ,
		{"word":"bardaient","word_nosc":"bardaient","lemma":"barder","pos":"VER"} ,
		{"word":"bardait","word_nosc":"bardait","lemma":"barder","pos":"VER"} ,
		{"word":"barde","word_nosc":"barde","lemma":"barder","pos":"VER"} ,
		{"word":"barder","word_nosc":"barder","lemma":"barder","pos":"VER"} ,
		{"word":"bardera","word_nosc":"bardera","lemma":"barder","pos":"VER"} ,
		{"word":"barderait","word_nosc":"barderait","lemma":"barder","pos":"VER"} ,
		{"word":"bardé","word_nosc":"barde","lemma":"barder","pos":"VER"} ,
		{"word":"bardée","word_nosc":"bardee","lemma":"barder","pos":"VER"} ,
		{"word":"bardées","word_nosc":"bardees","lemma":"barder","pos":"VER"} ,
		{"word":"bardés","word_nosc":"bardes","lemma":"barder","pos":"VER"} ,
		{"word":"barguigna","word_nosc":"barguigna","lemma":"barguigner","pos":"VER"} ,
		{"word":"barguigner","word_nosc":"barguigner","lemma":"barguigner","pos":"VER"} ,
		{"word":"barguigné","word_nosc":"barguigne","lemma":"barguigner","pos":"VER"} ,
		{"word":"bariolaient","word_nosc":"bariolaient","lemma":"barioler","pos":"VER"} ,
		{"word":"bariolant","word_nosc":"bariolant","lemma":"barioler","pos":"VER"} ,
		{"word":"bariole","word_nosc":"bariole","lemma":"barioler","pos":"VER"} ,
		{"word":"bariolé","word_nosc":"bariole","lemma":"barioler","pos":"VER"} ,
		{"word":"bariolée","word_nosc":"bariolee","lemma":"barioler","pos":"VER"} ,
		{"word":"bariolées","word_nosc":"bariolees","lemma":"barioler","pos":"VER"} ,
		{"word":"bariolés","word_nosc":"barioles","lemma":"barioler","pos":"VER"} ,
		{"word":"baronnait","word_nosc":"baronnait","lemma":"baronner","pos":"VER"} ,
		{"word":"baronner","word_nosc":"baronner","lemma":"baronner","pos":"VER"} ,
		{"word":"baronnez","word_nosc":"baronnez","lemma":"baronner","pos":"VER"} ,
		{"word":"baroudé","word_nosc":"baroude","lemma":"barouder","pos":"VER"} ,
		{"word":"barra","word_nosc":"barra","lemma":"barrer","pos":"VER"} ,
		{"word":"barrai","word_nosc":"barrai","lemma":"barrer","pos":"VER"} ,
		{"word":"barraient","word_nosc":"barraient","lemma":"barrer","pos":"VER"} ,
		{"word":"barrais","word_nosc":"barrais","lemma":"barrer","pos":"VER"} ,
		{"word":"barrait","word_nosc":"barrait","lemma":"barrer","pos":"VER"} ,
		{"word":"barrant","word_nosc":"barrant","lemma":"barrer","pos":"VER"} ,
		{"word":"barre","word_nosc":"barre","lemma":"barrer","pos":"VER"} ,
		{"word":"barrent","word_nosc":"barrent","lemma":"barrer","pos":"VER"} ,
		{"word":"barrer","word_nosc":"barrer","lemma":"barrer","pos":"VER"} ,
		{"word":"barrera","word_nosc":"barrera","lemma":"barrer","pos":"VER"} ,
		{"word":"barrerai","word_nosc":"barrerai","lemma":"barrer","pos":"VER"} ,
		{"word":"barrerais","word_nosc":"barrerais","lemma":"barrer","pos":"VER"} ,
		{"word":"barreront","word_nosc":"barreront","lemma":"barrer","pos":"VER"} ,
		{"word":"barres","word_nosc":"barres","lemma":"barrer","pos":"VER"} ,
		{"word":"barrez","word_nosc":"barrez","lemma":"barrer","pos":"VER"} ,
		{"word":"barri","word_nosc":"barri","lemma":"barrir","pos":"VER"} ,
		{"word":"barricada","word_nosc":"barricada","lemma":"barricader","pos":"VER"} ,
		{"word":"barricadai","word_nosc":"barricadai","lemma":"barricader","pos":"VER"} ,
		{"word":"barricadaient","word_nosc":"barricadaient","lemma":"barricader","pos":"VER"} ,
		{"word":"barricadait","word_nosc":"barricadait","lemma":"barricader","pos":"VER"} ,
		{"word":"barricade","word_nosc":"barricade","lemma":"barricader","pos":"VER"} ,
		{"word":"barricadent","word_nosc":"barricadent","lemma":"barricader","pos":"VER"} ,
		{"word":"barricader","word_nosc":"barricader","lemma":"barricader","pos":"VER"} ,
		{"word":"barricaderait","word_nosc":"barricaderait","lemma":"barricader","pos":"VER"} ,
		{"word":"barricades","word_nosc":"barricades","lemma":"barricader","pos":"VER"} ,
		{"word":"barricadez","word_nosc":"barricadez","lemma":"barricader","pos":"VER"} ,
		{"word":"barricadons","word_nosc":"barricadons","lemma":"barricader","pos":"VER"} ,
		{"word":"barricadèrent","word_nosc":"barricaderent","lemma":"barricader","pos":"VER"} ,
		{"word":"barricadé","word_nosc":"barricade","lemma":"barricader","pos":"VER"} ,
		{"word":"barricadée","word_nosc":"barricadee","lemma":"barricader","pos":"VER"} ,
		{"word":"barricadées","word_nosc":"barricadees","lemma":"barricader","pos":"VER"} ,
		{"word":"barricadés","word_nosc":"barricades","lemma":"barricader","pos":"VER"} ,
		{"word":"barris","word_nosc":"barris","lemma":"barrir","pos":"VER"} ,
		{"word":"barrissait","word_nosc":"barrissait","lemma":"barrir","pos":"VER"} ,
		{"word":"barrissant","word_nosc":"barrissant","lemma":"barrir","pos":"VER"} ,
		{"word":"barrissent","word_nosc":"barrissent","lemma":"barrir","pos":"VER"} ,
		{"word":"barrit","word_nosc":"barrit","lemma":"barrir","pos":"VER"} ,
		{"word":"barrons","word_nosc":"barrons","lemma":"barrer","pos":"VER"} ,
		{"word":"barrèrent","word_nosc":"barrerent","lemma":"barrer","pos":"VER"} ,
		{"word":"barré","word_nosc":"barre","lemma":"barrer","pos":"VER"} ,
		{"word":"barrée","word_nosc":"barree","lemma":"barrer","pos":"VER"} ,
		{"word":"barrées","word_nosc":"barrees","lemma":"barrer","pos":"VER"} ,
		{"word":"barrés","word_nosc":"barres","lemma":"barrer","pos":"VER"} ,
		{"word":"barytonnant","word_nosc":"barytonnant","lemma":"barytonner","pos":"VER"} ,
		{"word":"barytonner","word_nosc":"barytonner","lemma":"barytonner","pos":"VER"} ,
		{"word":"basa","word_nosc":"basa","lemma":"baser","pos":"VER"} ,
		{"word":"basaient","word_nosc":"basaient","lemma":"baser","pos":"VER"} ,
		{"word":"basait","word_nosc":"basait","lemma":"baser","pos":"VER"} ,
		{"word":"basant","word_nosc":"basant","lemma":"baser","pos":"VER"} ,
		{"word":"basané","word_nosc":"basane","lemma":"basaner","pos":"VER"} ,
		{"word":"basanées","word_nosc":"basanees","lemma":"basaner","pos":"VER"} ,
		{"word":"basanés","word_nosc":"basanes","lemma":"basaner","pos":"VER"} ,
		{"word":"bascula","word_nosc":"bascula","lemma":"basculer","pos":"VER"} ,
		{"word":"basculai","word_nosc":"basculai","lemma":"basculer","pos":"VER"} ,
		{"word":"basculaient","word_nosc":"basculaient","lemma":"basculer","pos":"VER"} ,
		{"word":"basculais","word_nosc":"basculais","lemma":"basculer","pos":"VER"} ,
		{"word":"basculait","word_nosc":"basculait","lemma":"basculer","pos":"VER"} ,
		{"word":"basculant","word_nosc":"basculant","lemma":"basculer","pos":"VER"} ,
		{"word":"bascule","word_nosc":"bascule","lemma":"basculer","pos":"VER"} ,
		{"word":"basculent","word_nosc":"basculent","lemma":"basculer","pos":"VER"} ,
		{"word":"basculer","word_nosc":"basculer","lemma":"basculer","pos":"VER"} ,
		{"word":"basculera","word_nosc":"basculera","lemma":"basculer","pos":"VER"} ,
		{"word":"basculerai","word_nosc":"basculerai","lemma":"basculer","pos":"VER"} ,
		{"word":"basculerais","word_nosc":"basculerais","lemma":"basculer","pos":"VER"} ,
		{"word":"basculerait","word_nosc":"basculerait","lemma":"basculer","pos":"VER"} ,
		{"word":"basculeront","word_nosc":"basculeront","lemma":"basculer","pos":"VER"} ,
		{"word":"bascules","word_nosc":"bascules","lemma":"basculer","pos":"VER"} ,
		{"word":"basculez","word_nosc":"basculez","lemma":"basculer","pos":"VER"} ,
		{"word":"basculions","word_nosc":"basculions","lemma":"basculer","pos":"VER"} ,
		{"word":"basculâmes","word_nosc":"basculames","lemma":"basculer","pos":"VER"} ,
		{"word":"basculât","word_nosc":"basculat","lemma":"basculer","pos":"VER"} ,
		{"word":"basculèrent","word_nosc":"basculerent","lemma":"basculer","pos":"VER"} ,
		{"word":"basculé","word_nosc":"bascule","lemma":"basculer","pos":"VER"} ,
		{"word":"basculée","word_nosc":"basculee","lemma":"basculer","pos":"VER"} ,
		{"word":"basculées","word_nosc":"basculees","lemma":"basculer","pos":"VER"} ,
		{"word":"basculés","word_nosc":"bascules","lemma":"basculer","pos":"VER"} ,
		{"word":"base","word_nosc":"base","lemma":"baser","pos":"VER"} ,
		{"word":"basent","word_nosc":"basent","lemma":"baser","pos":"VER"} ,
		{"word":"baser","word_nosc":"baser","lemma":"baser","pos":"VER"} ,
		{"word":"basera","word_nosc":"basera","lemma":"baser","pos":"VER"} ,
		{"word":"baseront","word_nosc":"baseront","lemma":"baser","pos":"VER"} ,
		{"word":"bases","word_nosc":"bases","lemma":"baser","pos":"VER"} ,
		{"word":"basez","word_nosc":"basez","lemma":"baser","pos":"VER"} ,
		{"word":"basons","word_nosc":"basons","lemma":"baser","pos":"VER"} ,
		{"word":"bassinaient","word_nosc":"bassinaient","lemma":"bassiner","pos":"VER"} ,
		{"word":"bassinais","word_nosc":"bassinais","lemma":"bassiner","pos":"VER"} ,
		{"word":"bassinait","word_nosc":"bassinait","lemma":"bassiner","pos":"VER"} ,
		{"word":"bassinant","word_nosc":"bassinant","lemma":"bassiner","pos":"VER"} ,
		{"word":"bassine","word_nosc":"bassine","lemma":"bassiner","pos":"VER"} ,
		{"word":"bassiner","word_nosc":"bassiner","lemma":"bassiner","pos":"VER"} ,
		{"word":"bassines","word_nosc":"bassines","lemma":"bassiner","pos":"VER"} ,
		{"word":"bassinez","word_nosc":"bassinez","lemma":"bassiner","pos":"VER"} ,
		{"word":"bassiné","word_nosc":"bassine","lemma":"bassiner","pos":"VER"} ,
		{"word":"basta","word_nosc":"basta","lemma":"baster","pos":"VER"} ,
		{"word":"baster","word_nosc":"baster","lemma":"baster","pos":"VER"} ,
		{"word":"bastionnée","word_nosc":"bastionnee","lemma":"bastionner","pos":"VER"} ,
		{"word":"bastonne","word_nosc":"bastonne","lemma":"bastonner","pos":"VER"} ,
		{"word":"bastonnent","word_nosc":"bastonnent","lemma":"bastonner","pos":"VER"} ,
		{"word":"bastonner","word_nosc":"bastonner","lemma":"bastonner","pos":"VER"} ,
		{"word":"bastonné","word_nosc":"bastonne","lemma":"bastonner","pos":"VER"} ,
		{"word":"bastonnée","word_nosc":"bastonnee","lemma":"bastonner","pos":"VER"} ,
		{"word":"bastonnés","word_nosc":"bastonnes","lemma":"bastonner","pos":"VER"} ,
		{"word":"bastons","word_nosc":"bastons","lemma":"baster","pos":"VER"} ,
		{"word":"basé","word_nosc":"base","lemma":"baser","pos":"VER"} ,
		{"word":"basée","word_nosc":"basee","lemma":"baser","pos":"VER"} ,
		{"word":"basées","word_nosc":"basees","lemma":"baser","pos":"VER"} ,
		{"word":"basés","word_nosc":"bases","lemma":"baser","pos":"VER"} ,
		{"word":"bat","word_nosc":"bat","lemma":"battre","pos":"VER"} ,
		{"word":"batailla","word_nosc":"batailla","lemma":"batailler","pos":"VER"} ,
		{"word":"bataillait","word_nosc":"bataillait","lemma":"batailler","pos":"VER"} ,
		{"word":"bataillant","word_nosc":"bataillant","lemma":"batailler","pos":"VER"} ,
		{"word":"bataille","word_nosc":"bataille","lemma":"batailler","pos":"VER"} ,
		{"word":"bataillent","word_nosc":"bataillent","lemma":"batailler","pos":"VER"} ,
		{"word":"batailler","word_nosc":"batailler","lemma":"batailler","pos":"VER"} ,
		{"word":"bataillons","word_nosc":"bataillons","lemma":"batailler","pos":"VER"} ,
		{"word":"bataillèrent","word_nosc":"bataillerent","lemma":"batailler","pos":"VER"} ,
		{"word":"bataillé","word_nosc":"bataille","lemma":"batailler","pos":"VER"} ,
		{"word":"bataillés","word_nosc":"batailles","lemma":"batailler","pos":"VER"} ,
		{"word":"batifola","word_nosc":"batifola","lemma":"batifoler","pos":"VER"} ,
		{"word":"batifolaient","word_nosc":"batifolaient","lemma":"batifoler","pos":"VER"} ,
		{"word":"batifolais","word_nosc":"batifolais","lemma":"batifoler","pos":"VER"} ,
		{"word":"batifolait","word_nosc":"batifolait","lemma":"batifoler","pos":"VER"} ,
		{"word":"batifolant","word_nosc":"batifolant","lemma":"batifoler","pos":"VER"} ,
		{"word":"batifole","word_nosc":"batifole","lemma":"batifoler","pos":"VER"} ,
		{"word":"batifolent","word_nosc":"batifolent","lemma":"batifoler","pos":"VER"} ,
		{"word":"batifoler","word_nosc":"batifoler","lemma":"batifoler","pos":"VER"} ,
		{"word":"batifoles","word_nosc":"batifoles","lemma":"batifoler","pos":"VER"} ,
		{"word":"batifolez","word_nosc":"batifolez","lemma":"batifoler","pos":"VER"} ,
		{"word":"batifolé","word_nosc":"batifole","lemma":"batifoler","pos":"VER"} ,
		{"word":"bats","word_nosc":"bats","lemma":"battre","pos":"VER"} ,
		{"word":"battaient","word_nosc":"battaient","lemma":"battre","pos":"VER"} ,
		{"word":"battais","word_nosc":"battais","lemma":"battre","pos":"VER"} ,
		{"word":"battait","word_nosc":"battait","lemma":"battre","pos":"VER"} ,
		{"word":"battant","word_nosc":"battant","lemma":"battre","pos":"VER"} ,
		{"word":"batte","word_nosc":"batte","lemma":"battre","pos":"VER"} ,
		{"word":"battent","word_nosc":"battent","lemma":"battre","pos":"VER"} ,
		{"word":"battes","word_nosc":"battes","lemma":"battre","pos":"VER"} ,
		{"word":"battez","word_nosc":"battez","lemma":"battre","pos":"VER"} ,
		{"word":"battiez","word_nosc":"battiez","lemma":"battre","pos":"VER"} ,
		{"word":"battions","word_nosc":"battions","lemma":"battre","pos":"VER"} ,
		{"word":"battirent","word_nosc":"battirent","lemma":"battre","pos":"VER"} ,
		{"word":"battis","word_nosc":"battis","lemma":"battre","pos":"VER"} ,
		{"word":"battit","word_nosc":"battit","lemma":"battre","pos":"VER"} ,
		{"word":"battons","word_nosc":"battons","lemma":"battre","pos":"VER"} ,
		{"word":"battra","word_nosc":"battra","lemma":"battre","pos":"VER"} ,
		{"word":"battrai","word_nosc":"battrai","lemma":"battre","pos":"VER"} ,
		{"word":"battraient","word_nosc":"battraient","lemma":"battre","pos":"VER"} ,
		{"word":"battrais","word_nosc":"battrais","lemma":"battre","pos":"VER"} ,
		{"word":"battrait","word_nosc":"battrait","lemma":"battre","pos":"VER"} ,
		{"word":"battras","word_nosc":"battras","lemma":"battre","pos":"VER"} ,
		{"word":"battre","word_nosc":"battre","lemma":"battre","pos":"VER"} ,
		{"word":"battrez","word_nosc":"battrez","lemma":"battre","pos":"VER"} ,
		{"word":"battriez","word_nosc":"battriez","lemma":"battre","pos":"VER"} ,
		{"word":"battrions","word_nosc":"battrions","lemma":"battre","pos":"VER"} ,
		{"word":"battrons","word_nosc":"battrons","lemma":"battre","pos":"VER"} ,
		{"word":"battront","word_nosc":"battront","lemma":"battre","pos":"VER"} ,
		{"word":"battu","word_nosc":"battu","lemma":"battre","pos":"VER"} ,
		{"word":"battue","word_nosc":"battue","lemma":"battre","pos":"VER"} ,
		{"word":"battues","word_nosc":"battues","lemma":"battre","pos":"VER"} ,
		{"word":"battus","word_nosc":"battus","lemma":"battre","pos":"VER"} ,
		{"word":"battîmes","word_nosc":"battimes","lemma":"battre","pos":"VER"} ,
		{"word":"battît","word_nosc":"battit","lemma":"battre","pos":"VER"} ,
		{"word":"baugé","word_nosc":"bauge","lemma":"bauger","pos":"VER"} ,
		{"word":"bava","word_nosc":"bava","lemma":"baver","pos":"VER"} ,
		{"word":"bavaient","word_nosc":"bavaient","lemma":"baver","pos":"VER"} ,
		{"word":"bavais","word_nosc":"bavais","lemma":"baver","pos":"VER"} ,
		{"word":"bavait","word_nosc":"bavait","lemma":"baver","pos":"VER"} ,
		{"word":"bavant","word_nosc":"bavant","lemma":"baver","pos":"VER"} ,
		{"word":"bavarda","word_nosc":"bavarda","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavardai","word_nosc":"bavardai","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavardaient","word_nosc":"bavardaient","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavardais","word_nosc":"bavardais","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavardait","word_nosc":"bavardait","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavardant","word_nosc":"bavardant","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavarde","word_nosc":"bavarde","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavardent","word_nosc":"bavardent","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavarder","word_nosc":"bavarder","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavardera","word_nosc":"bavardera","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavarderai","word_nosc":"bavarderai","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavarderait","word_nosc":"bavarderait","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavarderas","word_nosc":"bavarderas","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavarderons","word_nosc":"bavarderons","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavardes","word_nosc":"bavardes","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavardez","word_nosc":"bavardez","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavardiez","word_nosc":"bavardiez","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavardions","word_nosc":"bavardions","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavardons","word_nosc":"bavardons","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavardèrent","word_nosc":"bavarderent","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavardé","word_nosc":"bavarde","lemma":"bavarder","pos":"VER"} ,
		{"word":"bavassaient","word_nosc":"bavassaient","lemma":"bavasser","pos":"VER"} ,
		{"word":"bavassait","word_nosc":"bavassait","lemma":"bavasser","pos":"VER"} ,
		{"word":"bavassant","word_nosc":"bavassant","lemma":"bavasser","pos":"VER"} ,
		{"word":"bavasse","word_nosc":"bavasse","lemma":"bavasser","pos":"VER"} ,
		{"word":"bavassent","word_nosc":"bavassent","lemma":"bavasser","pos":"VER"} ,
		{"word":"bavasser","word_nosc":"bavasser","lemma":"bavasser","pos":"VER"} ,
		{"word":"bavasses","word_nosc":"bavasses","lemma":"bavasser","pos":"VER"} ,
		{"word":"bavassiez","word_nosc":"bavassiez","lemma":"bavasser","pos":"VER"} ,
		{"word":"bavassé","word_nosc":"bavasse","lemma":"bavasser","pos":"VER"} ,
		{"word":"bave","word_nosc":"bave","lemma":"baver","pos":"VER"} ,
		{"word":"bavent","word_nosc":"bavent","lemma":"baver","pos":"VER"} ,
		{"word":"baver","word_nosc":"baver","lemma":"baver","pos":"VER"} ,
		{"word":"baverais","word_nosc":"baverais","lemma":"baver","pos":"VER"} ,
		{"word":"baveras","word_nosc":"baveras","lemma":"baver","pos":"VER"} ,
		{"word":"baverez","word_nosc":"baverez","lemma":"baver","pos":"VER"} ,
		{"word":"baveront","word_nosc":"baveront","lemma":"baver","pos":"VER"} ,
		{"word":"baves","word_nosc":"baves","lemma":"baver","pos":"VER"} ,
		{"word":"bavez","word_nosc":"bavez","lemma":"baver","pos":"VER"} ,
		{"word":"bavocher","word_nosc":"bavocher","lemma":"bavocher","pos":"VER"} ,
		{"word":"bavons","word_nosc":"bavons","lemma":"baver","pos":"VER"} ,
		{"word":"bavotait","word_nosc":"bavotait","lemma":"bavoter","pos":"VER"} ,
		{"word":"bavotant","word_nosc":"bavotant","lemma":"bavoter","pos":"VER"} ,
		{"word":"bavé","word_nosc":"bave","lemma":"baver","pos":"VER"} ,
		{"word":"bayaient","word_nosc":"bayaient","lemma":"bayer","pos":"VER"} ,
		{"word":"bayant","word_nosc":"bayant","lemma":"bayer","pos":"VER"} ,
		{"word":"baye","word_nosc":"baye","lemma":"bayer","pos":"VER"} ,
		{"word":"bayer","word_nosc":"bayer","lemma":"bayer","pos":"VER"} ,
		{"word":"bayons","word_nosc":"bayons","lemma":"bayer","pos":"VER"} ,
		{"word":"bayé","word_nosc":"baye","lemma":"bayer","pos":"VER"} ,
		{"word":"bazardait","word_nosc":"bazardait","lemma":"bazarder","pos":"VER"} ,
		{"word":"bazardant","word_nosc":"bazardant","lemma":"bazarder","pos":"VER"} ,
		{"word":"bazarde","word_nosc":"bazarde","lemma":"bazarder","pos":"VER"} ,
		{"word":"bazarder","word_nosc":"bazarder","lemma":"bazarder","pos":"VER"} ,
		{"word":"bazarderez","word_nosc":"bazarderez","lemma":"bazarder","pos":"VER"} ,
		{"word":"bazardes","word_nosc":"bazardes","lemma":"bazarder","pos":"VER"} ,
		{"word":"bazardé","word_nosc":"bazarde","lemma":"bazarder","pos":"VER"} ,
		{"word":"bazardée","word_nosc":"bazardee","lemma":"bazarder","pos":"VER"} ,
		{"word":"bazardées","word_nosc":"bazardees","lemma":"bazarder","pos":"VER"} ,
		{"word":"becquant","word_nosc":"becquant","lemma":"becquer","pos":"VER"} ,
		{"word":"becquer","word_nosc":"becquer","lemma":"becquer","pos":"VER"} ,
		{"word":"becquetaient","word_nosc":"becquetaient","lemma":"becqueter","pos":"VER"} ,
		{"word":"becquetait","word_nosc":"becquetait","lemma":"becqueter","pos":"VER"} ,
		{"word":"becquetant","word_nosc":"becquetant","lemma":"becqueter","pos":"VER"} ,
		{"word":"becqueter","word_nosc":"becqueter","lemma":"becqueter","pos":"VER"} ,
		{"word":"becquette","word_nosc":"becquette","lemma":"becqueter","pos":"VER"} ,
		{"word":"becqueté","word_nosc":"becquete","lemma":"becqueter","pos":"VER"} ,
		{"word":"becquetés","word_nosc":"becquetes","lemma":"becqueter","pos":"VER"} ,
		{"word":"becqué","word_nosc":"becque","lemma":"becquer","pos":"VER"} ,
		{"word":"becta","word_nosc":"becta","lemma":"becter","pos":"VER"} ,
		{"word":"bectais","word_nosc":"bectais","lemma":"becter","pos":"VER"} ,
		{"word":"bectait","word_nosc":"bectait","lemma":"becter","pos":"VER"} ,
		{"word":"bectant","word_nosc":"bectant","lemma":"becter","pos":"VER"} ,
		{"word":"becte","word_nosc":"becte","lemma":"becter","pos":"VER"} ,
		{"word":"bectent","word_nosc":"bectent","lemma":"becter","pos":"VER"} ,
		{"word":"becter","word_nosc":"becter","lemma":"becter","pos":"VER"} ,
		{"word":"becterais","word_nosc":"becterais","lemma":"becter","pos":"VER"} ,
		{"word":"becteras","word_nosc":"becteras","lemma":"becter","pos":"VER"} ,
		{"word":"becté","word_nosc":"becte","lemma":"becter","pos":"VER"} ,
		{"word":"bectées","word_nosc":"bectees","lemma":"becter","pos":"VER"} ,
		{"word":"bectés","word_nosc":"bectes","lemma":"becter","pos":"VER"} ,
		{"word":"bedonnant","word_nosc":"bedonnant","lemma":"bedonner","pos":"VER"} ,
		{"word":"bedonne","word_nosc":"bedonne","lemma":"bedonner","pos":"VER"} ,
		{"word":"beloter","word_nosc":"beloter","lemma":"beloter","pos":"VER"} ,
		{"word":"belotte","word_nosc":"belotte","lemma":"belotter","pos":"VER"} ,
		{"word":"berce","word_nosc":"berce","lemma":"bercer","pos":"VER"} ,
		{"word":"bercent","word_nosc":"bercent","lemma":"bercer","pos":"VER"} ,
		{"word":"bercer","word_nosc":"bercer","lemma":"bercer","pos":"VER"} ,
		{"word":"bercera","word_nosc":"bercera","lemma":"bercer","pos":"VER"} ,
		{"word":"bercerai","word_nosc":"bercerai","lemma":"bercer","pos":"VER"} ,
		{"word":"bercerait","word_nosc":"bercerait","lemma":"bercer","pos":"VER"} ,
		{"word":"bercerez","word_nosc":"bercerez","lemma":"bercer","pos":"VER"} ,
		{"word":"berces","word_nosc":"berces","lemma":"bercer","pos":"VER"} ,
		{"word":"bercez","word_nosc":"bercez","lemma":"bercer","pos":"VER"} ,
		{"word":"bercèrent","word_nosc":"bercerent","lemma":"bercer","pos":"VER"} ,
		{"word":"bercé","word_nosc":"berce","lemma":"bercer","pos":"VER"} ,
		{"word":"bercée","word_nosc":"bercee","lemma":"bercer","pos":"VER"} ,
		{"word":"bercés","word_nosc":"berces","lemma":"bercer","pos":"VER"} ,
		{"word":"berlurais","word_nosc":"berlurais","lemma":"berlurer","pos":"VER"} ,
		{"word":"berlurait","word_nosc":"berlurait","lemma":"berlurer","pos":"VER"} ,
		{"word":"berlurent","word_nosc":"berlurent","lemma":"berlurer","pos":"VER"} ,
		{"word":"berlurer","word_nosc":"berlurer","lemma":"berlurer","pos":"VER"} ,
		{"word":"berluré","word_nosc":"berlure","lemma":"berlurer","pos":"VER"} ,
		{"word":"berlurée","word_nosc":"berluree","lemma":"berlurer","pos":"VER"} ,
		{"word":"bernait","word_nosc":"bernait","lemma":"berner","pos":"VER"} ,
		{"word":"bernant","word_nosc":"bernant","lemma":"berner","pos":"VER"} ,
		{"word":"berne","word_nosc":"berne","lemma":"berner","pos":"VER"} ,
		{"word":"berner","word_nosc":"berner","lemma":"berner","pos":"VER"} ,
		{"word":"berneras","word_nosc":"berneras","lemma":"berner","pos":"VER"} ,
		{"word":"bernez","word_nosc":"bernez","lemma":"berner","pos":"VER"} ,
		{"word":"berné","word_nosc":"berne","lemma":"berner","pos":"VER"} ,
		{"word":"bernée","word_nosc":"bernee","lemma":"berner","pos":"VER"} ,
		{"word":"bernées","word_nosc":"bernees","lemma":"berner","pos":"VER"} ,
		{"word":"bernés","word_nosc":"bernes","lemma":"berner","pos":"VER"} ,
		{"word":"berça","word_nosc":"berca","lemma":"bercer","pos":"VER"} ,
		{"word":"berçaient","word_nosc":"bercaient","lemma":"bercer","pos":"VER"} ,
		{"word":"berçais","word_nosc":"bercais","lemma":"bercer","pos":"VER"} ,
		{"word":"berçait","word_nosc":"bercait","lemma":"bercer","pos":"VER"} ,
		{"word":"berçant","word_nosc":"bercant","lemma":"bercer","pos":"VER"} ,
		{"word":"besogna","word_nosc":"besogna","lemma":"besogner","pos":"VER"} ,
		{"word":"besognaient","word_nosc":"besognaient","lemma":"besogner","pos":"VER"} ,
		{"word":"besognais","word_nosc":"besognais","lemma":"besogner","pos":"VER"} ,
		{"word":"besognait","word_nosc":"besognait","lemma":"besogner","pos":"VER"} ,
		{"word":"besognant","word_nosc":"besognant","lemma":"besogner","pos":"VER"} ,
		{"word":"besogne","word_nosc":"besogne","lemma":"besogner","pos":"VER"} ,
		{"word":"besognent","word_nosc":"besognent","lemma":"besogner","pos":"VER"} ,
		{"word":"besogner","word_nosc":"besogner","lemma":"besogner","pos":"VER"} ,
		{"word":"besogneras","word_nosc":"besogneras","lemma":"besogner","pos":"VER"} ,
		{"word":"besognèrent","word_nosc":"besognerent","lemma":"besogner","pos":"VER"} ,
		{"word":"besogné","word_nosc":"besogne","lemma":"besogner","pos":"VER"} ,
		{"word":"beugla","word_nosc":"beugla","lemma":"beugler","pos":"VER"} ,
		{"word":"beuglaient","word_nosc":"beuglaient","lemma":"beugler","pos":"VER"} ,
		{"word":"beuglais","word_nosc":"beuglais","lemma":"beugler","pos":"VER"} ,
		{"word":"beuglait","word_nosc":"beuglait","lemma":"beugler","pos":"VER"} ,
		{"word":"beuglant","word_nosc":"beuglant","lemma":"beugler","pos":"VER"} ,
		{"word":"beugle","word_nosc":"beugle","lemma":"beugler","pos":"VER"} ,
		{"word":"beuglent","word_nosc":"beuglent","lemma":"beugler","pos":"VER"} ,
		{"word":"beugler","word_nosc":"beugler","lemma":"beugler","pos":"VER"} ,
		{"word":"beuglions","word_nosc":"beuglions","lemma":"beugler","pos":"VER"} ,
		{"word":"beuglé","word_nosc":"beugle","lemma":"beugler","pos":"VER"} ,
		{"word":"beuglée","word_nosc":"beuglee","lemma":"beugler","pos":"VER"} ,
		{"word":"beurra","word_nosc":"beurra","lemma":"beurrer","pos":"VER"} ,
		{"word":"beurraient","word_nosc":"beurraient","lemma":"beurrer","pos":"VER"} ,
		{"word":"beurrais","word_nosc":"beurrais","lemma":"beurrer","pos":"VER"} ,
		{"word":"beurrait","word_nosc":"beurrait","lemma":"beurrer","pos":"VER"} ,
		{"word":"beurrant","word_nosc":"beurrant","lemma":"beurrer","pos":"VER"} ,
		{"word":"beurre","word_nosc":"beurre","lemma":"beurrer","pos":"VER"} ,
		{"word":"beurrer","word_nosc":"beurrer","lemma":"beurrer","pos":"VER"} ,
		{"word":"beurres","word_nosc":"beurres","lemma":"beurrer","pos":"VER"} ,
		{"word":"beurré","word_nosc":"beurre","lemma":"beurrer","pos":"VER"} ,
		{"word":"beurrée","word_nosc":"beurree","lemma":"beurrer","pos":"VER"} ,
		{"word":"beurrées","word_nosc":"beurrees","lemma":"beurrer","pos":"VER"} ,
		{"word":"beurrés","word_nosc":"beurres","lemma":"beurrer","pos":"VER"} ,
		{"word":"biaisai","word_nosc":"biaisai","lemma":"biaiser","pos":"VER"} ,
		{"word":"biaise","word_nosc":"biaise","lemma":"biaiser","pos":"VER"} ,
		{"word":"biaiser","word_nosc":"biaiser","lemma":"biaiser","pos":"VER"} ,
		{"word":"biaisé","word_nosc":"biaise","lemma":"biaiser","pos":"VER"} ,
		{"word":"biaisée","word_nosc":"biaisee","lemma":"biaiser","pos":"VER"} ,
		{"word":"biberonnait","word_nosc":"biberonnait","lemma":"biberonner","pos":"VER"} ,
		{"word":"biberonne","word_nosc":"biberonne","lemma":"biberonner","pos":"VER"} ,
		{"word":"biberonner","word_nosc":"biberonner","lemma":"biberonner","pos":"VER"} ,
		{"word":"biberonné","word_nosc":"biberonne","lemma":"biberonner","pos":"VER"} ,
		{"word":"bichais","word_nosc":"bichais","lemma":"bicher","pos":"VER"} ,
		{"word":"bichait","word_nosc":"bichait","lemma":"bicher","pos":"VER"} ,
		{"word":"bichant","word_nosc":"bichant","lemma":"bicher","pos":"VER"} ,
		{"word":"biche","word_nosc":"biche","lemma":"bicher","pos":"VER"} ,
		{"word":"bicher","word_nosc":"bicher","lemma":"bicher","pos":"VER"} ,
		{"word":"biches","word_nosc":"biches","lemma":"bicher","pos":"VER"} ,
		{"word":"bichez","word_nosc":"bichez","lemma":"bicher","pos":"VER"} ,
		{"word":"bichonnait","word_nosc":"bichonnait","lemma":"bichonner","pos":"VER"} ,
		{"word":"bichonnant","word_nosc":"bichonnant","lemma":"bichonner","pos":"VER"} ,
		{"word":"bichonne","word_nosc":"bichonne","lemma":"bichonner","pos":"VER"} ,
		{"word":"bichonner","word_nosc":"bichonner","lemma":"bichonner","pos":"VER"} ,
		{"word":"bichonnerai","word_nosc":"bichonnerai","lemma":"bichonner","pos":"VER"} ,
		{"word":"bichonnez","word_nosc":"bichonnez","lemma":"bichonner","pos":"VER"} ,
		{"word":"bichonné","word_nosc":"bichonne","lemma":"bichonner","pos":"VER"} ,
		{"word":"bichonnée","word_nosc":"bichonnee","lemma":"bichonner","pos":"VER"} ,
		{"word":"biché","word_nosc":"biche","lemma":"bicher","pos":"VER"} ,
		{"word":"bidonnaient","word_nosc":"bidonnaient","lemma":"bidonner","pos":"VER"} ,
		{"word":"bidonnais","word_nosc":"bidonnais","lemma":"bidonner","pos":"VER"} ,
		{"word":"bidonnait","word_nosc":"bidonnait","lemma":"bidonner","pos":"VER"} ,
		{"word":"bidonnant","word_nosc":"bidonnant","lemma":"bidonner","pos":"VER"} ,
		{"word":"bidonne","word_nosc":"bidonne","lemma":"bidonner","pos":"VER"} ,
		{"word":"bidonnent","word_nosc":"bidonnent","lemma":"bidonner","pos":"VER"} ,
		{"word":"bidonner","word_nosc":"bidonner","lemma":"bidonner","pos":"VER"} ,
		{"word":"bidonnera","word_nosc":"bidonnera","lemma":"bidonner","pos":"VER"} ,
		{"word":"bidonnez","word_nosc":"bidonnez","lemma":"bidonner","pos":"VER"} ,
		{"word":"bidonné","word_nosc":"bidonne","lemma":"bidonner","pos":"VER"} ,
		{"word":"bidonnée","word_nosc":"bidonnee","lemma":"bidonner","pos":"VER"} ,
		{"word":"bidonnées","word_nosc":"bidonnees","lemma":"bidonner","pos":"VER"} ,
		{"word":"bidonnés","word_nosc":"bidonnes","lemma":"bidonner","pos":"VER"} ,
		{"word":"bidouilla","word_nosc":"bidouilla","lemma":"bidouiller","pos":"VER"} ,
		{"word":"bidouiller","word_nosc":"bidouiller","lemma":"bidouiller","pos":"VER"} ,
		{"word":"bidouillerai","word_nosc":"bidouillerai","lemma":"bidouiller","pos":"VER"} ,
		{"word":"bidouillé","word_nosc":"bidouille","lemma":"bidouiller","pos":"VER"} ,
		{"word":"bidouillée","word_nosc":"bidouillee","lemma":"bidouiller","pos":"VER"} ,
		{"word":"bidouillés","word_nosc":"bidouilles","lemma":"bidouiller","pos":"VER"} ,
		{"word":"biffa","word_nosc":"biffa","lemma":"biffer","pos":"VER"} ,
		{"word":"biffaient","word_nosc":"biffaient","lemma":"biffer","pos":"VER"} ,
		{"word":"biffais","word_nosc":"biffais","lemma":"biffer","pos":"VER"} ,
		{"word":"biffant","word_nosc":"biffant","lemma":"biffer","pos":"VER"} ,
		{"word":"biffe","word_nosc":"biffe","lemma":"biffer","pos":"VER"} ,
		{"word":"biffer","word_nosc":"biffer","lemma":"biffer","pos":"VER"} ,
		{"word":"biffé","word_nosc":"biffe","lemma":"biffer","pos":"VER"} ,
		{"word":"bifurqua","word_nosc":"bifurqua","lemma":"bifurquer","pos":"VER"} ,
		{"word":"bifurquait","word_nosc":"bifurquait","lemma":"bifurquer","pos":"VER"} ,
		{"word":"bifurquant","word_nosc":"bifurquant","lemma":"bifurquer","pos":"VER"} ,
		{"word":"bifurque","word_nosc":"bifurque","lemma":"bifurquer","pos":"VER"} ,
		{"word":"bifurquent","word_nosc":"bifurquent","lemma":"bifurquer","pos":"VER"} ,
		{"word":"bifurquer","word_nosc":"bifurquer","lemma":"bifurquer","pos":"VER"} ,
		{"word":"bifurqueront","word_nosc":"bifurqueront","lemma":"bifurquer","pos":"VER"} ,
		{"word":"bifurqué","word_nosc":"bifurque","lemma":"bifurquer","pos":"VER"} ,
		{"word":"bifurquée","word_nosc":"bifurquee","lemma":"bifurquer","pos":"VER"} ,
		{"word":"bigarrer","word_nosc":"bigarrer","lemma":"bigarrer","pos":"VER"} ,
		{"word":"bigarré","word_nosc":"bigarre","lemma":"bigarrer","pos":"VER"} ,
		{"word":"bigarrée","word_nosc":"bigarree","lemma":"bigarrer","pos":"VER"} ,
		{"word":"bigarrés","word_nosc":"bigarres","lemma":"bigarrer","pos":"VER"} ,
		{"word":"biglaient","word_nosc":"biglaient","lemma":"bigler","pos":"VER"} ,
		{"word":"biglais","word_nosc":"biglais","lemma":"bigler","pos":"VER"} ,
		{"word":"biglait","word_nosc":"biglait","lemma":"bigler","pos":"VER"} ,
		{"word":"biglant","word_nosc":"biglant","lemma":"bigler","pos":"VER"} ,
		{"word":"bigle","word_nosc":"bigle","lemma":"bigler","pos":"VER"} ,
		{"word":"biglent","word_nosc":"biglent","lemma":"bigler","pos":"VER"} ,
		{"word":"bigler","word_nosc":"bigler","lemma":"bigler","pos":"VER"} ,
		{"word":"biglez","word_nosc":"biglez","lemma":"bigler","pos":"VER"} ,
		{"word":"biglé","word_nosc":"bigle","lemma":"bigler","pos":"VER"} ,
		{"word":"biglée","word_nosc":"biglee","lemma":"bigler","pos":"VER"} ,
		{"word":"biglés","word_nosc":"bigles","lemma":"bigler","pos":"VER"} ,
		{"word":"bignolait","word_nosc":"bignolait","lemma":"bignoler","pos":"VER"} ,
		{"word":"bigophone","word_nosc":"bigophone","lemma":"bigophoner","pos":"VER"} ,
		{"word":"bigophoner","word_nosc":"bigophoner","lemma":"bigophoner","pos":"VER"} ,
		{"word":"bigophones","word_nosc":"bigophones","lemma":"bigophoner","pos":"VER"} ,
		{"word":"bigophoné","word_nosc":"bigophone","lemma":"bigophoner","pos":"VER"} ,
		{"word":"bigorne","word_nosc":"bigorne","lemma":"bigorner","pos":"VER"} ,
		{"word":"bigorner","word_nosc":"bigorner","lemma":"bigorner","pos":"VER"} ,
		{"word":"bigorné","word_nosc":"bigorne","lemma":"bigorner","pos":"VER"} ,
		{"word":"bigornés","word_nosc":"bigornes","lemma":"bigorner","pos":"VER"} ,
		{"word":"bilais","word_nosc":"bilais","lemma":"biler","pos":"VER"} ,
		{"word":"bilait","word_nosc":"bilait","lemma":"biler","pos":"VER"} ,
		{"word":"bile","word_nosc":"bile","lemma":"biler","pos":"VER"} ,
		{"word":"biler","word_nosc":"biler","lemma":"biler","pos":"VER"} ,
		{"word":"biles","word_nosc":"biles","lemma":"biler","pos":"VER"} ,
		{"word":"bilez","word_nosc":"bilez","lemma":"biler","pos":"VER"} ,
		{"word":"biller","word_nosc":"biller","lemma":"biller","pos":"VER"} ,
		{"word":"billions","word_nosc":"billions","lemma":"biller","pos":"VER"} ,
		{"word":"binait","word_nosc":"binait","lemma":"biner","pos":"VER"} ,
		{"word":"binant","word_nosc":"binant","lemma":"biner","pos":"VER"} ,
		{"word":"biner","word_nosc":"biner","lemma":"biner","pos":"VER"} ,
		{"word":"biné","word_nosc":"bine","lemma":"biner","pos":"VER"} ,
		{"word":"biologisé","word_nosc":"biologise","lemma":"biologiser","pos":"VER"} ,
		{"word":"bipais","word_nosc":"bipais","lemma":"biper","pos":"VER"} ,
		{"word":"bipe","word_nosc":"bipe","lemma":"biper","pos":"VER"} ,
		{"word":"biper","word_nosc":"biper","lemma":"biper","pos":"VER"} ,
		{"word":"biperai","word_nosc":"biperai","lemma":"biper","pos":"VER"} ,
		{"word":"biperait","word_nosc":"biperait","lemma":"biper","pos":"VER"} ,
		{"word":"bipes","word_nosc":"bipes","lemma":"biper","pos":"VER"} ,
		{"word":"bipez","word_nosc":"bipez","lemma":"biper","pos":"VER"} ,
		{"word":"bipé","word_nosc":"bipe","lemma":"biper","pos":"VER"} ,
		{"word":"bipée","word_nosc":"bipee","lemma":"biper","pos":"VER"} ,
		{"word":"bipés","word_nosc":"bipes","lemma":"biper","pos":"VER"} ,
		{"word":"bise","word_nosc":"bise","lemma":"biser","pos":"VER"} ,
		{"word":"biseauté","word_nosc":"biseaute","lemma":"biseauter","pos":"VER"} ,
		{"word":"biseautée","word_nosc":"biseautee","lemma":"biseauter","pos":"VER"} ,
		{"word":"biseautées","word_nosc":"biseautees","lemma":"biseauter","pos":"VER"} ,
		{"word":"biseautés","word_nosc":"biseautes","lemma":"biseauter","pos":"VER"} ,
		{"word":"biser","word_nosc":"biser","lemma":"biser","pos":"VER"} ,
		{"word":"bises","word_nosc":"bises","lemma":"biser","pos":"VER"} ,
		{"word":"bisque","word_nosc":"bisque","lemma":"bisquer","pos":"VER"} ,
		{"word":"bisquent","word_nosc":"bisquent","lemma":"bisquer","pos":"VER"} ,
		{"word":"bisquer","word_nosc":"bisquer","lemma":"bisquer","pos":"VER"} ,
		{"word":"bissant","word_nosc":"bissant","lemma":"bisser","pos":"VER"} ,
		{"word":"bisse","word_nosc":"bisse","lemma":"bisser","pos":"VER"} ,
		{"word":"bisser","word_nosc":"bisser","lemma":"bisser","pos":"VER"} ,
		{"word":"bissé","word_nosc":"bisse","lemma":"bisser","pos":"VER"} ,
		{"word":"bistouriser","word_nosc":"bistouriser","lemma":"bistouriser","pos":"VER"} ,
		{"word":"bistré","word_nosc":"bistre","lemma":"bistrer","pos":"VER"} ,
		{"word":"bistrée","word_nosc":"bistree","lemma":"bistrer","pos":"VER"} ,
		{"word":"bistrées","word_nosc":"bistrees","lemma":"bistrer","pos":"VER"} ,
		{"word":"bistrés","word_nosc":"bistres","lemma":"bistrer","pos":"VER"} ,
		{"word":"bitent","word_nosc":"bitent","lemma":"biter","pos":"VER"} ,
		{"word":"bitter","word_nosc":"bitter","lemma":"bitter","pos":"VER"} ,
		{"word":"bitumé","word_nosc":"bitume","lemma":"bitumer","pos":"VER"} ,
		{"word":"bitumée","word_nosc":"bitumee","lemma":"bitumer","pos":"VER"} ,
		{"word":"bitumées","word_nosc":"bitumees","lemma":"bitumer","pos":"VER"} ,
		{"word":"biturer","word_nosc":"biturer","lemma":"biturer","pos":"VER"} ,
		{"word":"bituré","word_nosc":"biture","lemma":"biturer","pos":"VER"} ,
		{"word":"biturés","word_nosc":"bitures","lemma":"biturer","pos":"VER"} ,
		{"word":"bité","word_nosc":"bite","lemma":"biter","pos":"VER"} ,
		{"word":"bivouaquaient","word_nosc":"bivouaquaient","lemma":"bivouaquer","pos":"VER"} ,
		{"word":"bivouaquait","word_nosc":"bivouaquait","lemma":"bivouaquer","pos":"VER"} ,
		{"word":"bivouaque","word_nosc":"bivouaque","lemma":"bivouaquer","pos":"VER"} ,
		{"word":"bivouaquent","word_nosc":"bivouaquent","lemma":"bivouaquer","pos":"VER"} ,
		{"word":"bivouaquer","word_nosc":"bivouaquer","lemma":"bivouaquer","pos":"VER"} ,
		{"word":"bivouaquerons","word_nosc":"bivouaquerons","lemma":"bivouaquer","pos":"VER"} ,
		{"word":"bivouaquons","word_nosc":"bivouaquons","lemma":"bivouaquer","pos":"VER"} ,
		{"word":"bivouaqué","word_nosc":"bivouaque","lemma":"bivouaquer","pos":"VER"} ,
		{"word":"bivouaquée","word_nosc":"bivouaquee","lemma":"bivouaquer","pos":"VER"} ,
		{"word":"bizuter","word_nosc":"bizuter","lemma":"bizuter","pos":"VER"} ,
		{"word":"bizuté","word_nosc":"bizute","lemma":"bizuter","pos":"VER"} ,
		{"word":"blablatait","word_nosc":"blablatait","lemma":"blablater","pos":"VER"} ,
		{"word":"blablater","word_nosc":"blablater","lemma":"blablater","pos":"VER"} ,
		{"word":"blablatez","word_nosc":"blablatez","lemma":"blablater","pos":"VER"} ,
		{"word":"blablaté","word_nosc":"blablate","lemma":"blablater","pos":"VER"} ,
		{"word":"blackboule","word_nosc":"blackboule","lemma":"blackbouler","pos":"VER"} ,
		{"word":"blackbouler","word_nosc":"blackbouler","lemma":"blackbouler","pos":"VER"} ,
		{"word":"blackboulé","word_nosc":"blackboule","lemma":"blackbouler","pos":"VER"} ,
		{"word":"blagua","word_nosc":"blagua","lemma":"blaguer","pos":"VER"} ,
		{"word":"blaguaient","word_nosc":"blaguaient","lemma":"blaguer","pos":"VER"} ,
		{"word":"blaguais","word_nosc":"blaguais","lemma":"blaguer","pos":"VER"} ,
		{"word":"blaguait","word_nosc":"blaguait","lemma":"blaguer","pos":"VER"} ,
		{"word":"blaguant","word_nosc":"blaguant","lemma":"blaguer","pos":"VER"} ,
		{"word":"blague","word_nosc":"blague","lemma":"blaguer","pos":"VER"} ,
		{"word":"blaguent","word_nosc":"blaguent","lemma":"blaguer","pos":"VER"} ,
		{"word":"blaguer","word_nosc":"blaguer","lemma":"blaguer","pos":"VER"} ,
		{"word":"blaguerais","word_nosc":"blaguerais","lemma":"blaguer","pos":"VER"} ,
		{"word":"blagues","word_nosc":"blagues","lemma":"blaguer","pos":"VER"} ,
		{"word":"blaguez","word_nosc":"blaguez","lemma":"blaguer","pos":"VER"} ,
		{"word":"blaguons","word_nosc":"blaguons","lemma":"blaguer","pos":"VER"} ,
		{"word":"blagué","word_nosc":"blague","lemma":"blaguer","pos":"VER"} ,
		{"word":"blairaient","word_nosc":"blairaient","lemma":"blairer","pos":"VER"} ,
		{"word":"blairait","word_nosc":"blairait","lemma":"blairer","pos":"VER"} ,
		{"word":"blaire","word_nosc":"blaire","lemma":"blairer","pos":"VER"} ,
		{"word":"blairer","word_nosc":"blairer","lemma":"blairer","pos":"VER"} ,
		{"word":"blairé","word_nosc":"blaire","lemma":"blairer","pos":"VER"} ,
		{"word":"blaise","word_nosc":"blaise","lemma":"blaiser","pos":"VER"} ,
		{"word":"blanchi","word_nosc":"blanchi","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchie","word_nosc":"blanchie","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchies","word_nosc":"blanchies","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchir","word_nosc":"blanchir","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchira","word_nosc":"blanchira","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchiraient","word_nosc":"blanchiraient","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchirait","word_nosc":"blanchirait","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchirent","word_nosc":"blanchirent","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchirez","word_nosc":"blanchirez","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchiront","word_nosc":"blanchiront","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchis","word_nosc":"blanchis","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchissaient","word_nosc":"blanchissaient","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchissait","word_nosc":"blanchissait","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchissant","word_nosc":"blanchissant","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchisse","word_nosc":"blanchisse","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchissent","word_nosc":"blanchissent","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchisses","word_nosc":"blanchisses","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchissez","word_nosc":"blanchissez","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchit","word_nosc":"blanchit","lemma":"blanchir","pos":"VER"} ,
		{"word":"blanchoie","word_nosc":"blanchoie","lemma":"blanchoyer","pos":"VER"} ,
		{"word":"blanchoyait","word_nosc":"blanchoyait","lemma":"blanchoyer","pos":"VER"} ,
		{"word":"blanchoyant","word_nosc":"blanchoyant","lemma":"blanchoyer","pos":"VER"} ,
		{"word":"blasait","word_nosc":"blasait","lemma":"blaser","pos":"VER"} ,
		{"word":"blase","word_nosc":"blase","lemma":"blaser","pos":"VER"} ,
		{"word":"blaser","word_nosc":"blaser","lemma":"blaser","pos":"VER"} ,
		{"word":"blasonnait","word_nosc":"blasonnait","lemma":"blasonner","pos":"VER"} ,
		{"word":"blasonné","word_nosc":"blasonne","lemma":"blasonner","pos":"VER"} ,
		{"word":"blasonnée","word_nosc":"blasonnee","lemma":"blasonner","pos":"VER"} ,
		{"word":"blasonnées","word_nosc":"blasonnees","lemma":"blasonner","pos":"VER"} ,
		{"word":"blasonnés","word_nosc":"blasonnes","lemma":"blasonner","pos":"VER"} ,
		{"word":"blasphème","word_nosc":"blaspheme","lemma":"blasphémer","pos":"VER"} ,
		{"word":"blasphèment","word_nosc":"blasphement","lemma":"blasphémer","pos":"VER"} ,
		{"word":"blasphèmes","word_nosc":"blasphemes","lemma":"blasphémer","pos":"VER"} ,
		{"word":"blasphéma","word_nosc":"blasphema","lemma":"blasphémer","pos":"VER"} ,
		{"word":"blasphémaient","word_nosc":"blasphemaient","lemma":"blasphémer","pos":"VER"} ,
		{"word":"blasphémais","word_nosc":"blasphemais","lemma":"blasphémer","pos":"VER"} ,
		{"word":"blasphémait","word_nosc":"blasphemait","lemma":"blasphémer","pos":"VER"} ,
		{"word":"blasphémant","word_nosc":"blasphemant","lemma":"blasphémer","pos":"VER"} ,
		{"word":"blasphémer","word_nosc":"blasphemer","lemma":"blasphémer","pos":"VER"} ,
		{"word":"blasphémez","word_nosc":"blasphemez","lemma":"blasphémer","pos":"VER"} ,
		{"word":"blasphémé","word_nosc":"blaspheme","lemma":"blasphémer","pos":"VER"} ,
		{"word":"blasé","word_nosc":"blase","lemma":"blaser","pos":"VER"} ,
		{"word":"blasée","word_nosc":"blasee","lemma":"blaser","pos":"VER"} ,
		{"word":"blasées","word_nosc":"blasees","lemma":"blaser","pos":"VER"} ,
		{"word":"blasés","word_nosc":"blases","lemma":"blaser","pos":"VER"} ,
		{"word":"blatérant","word_nosc":"blaterant","lemma":"blatérer","pos":"VER"} ,
		{"word":"blessa","word_nosc":"blessa","lemma":"blesser","pos":"VER"} ,
		{"word":"blessaient","word_nosc":"blessaient","lemma":"blesser","pos":"VER"} ,
		{"word":"blessais","word_nosc":"blessais","lemma":"blesser","pos":"VER"} ,
		{"word":"blessait","word_nosc":"blessait","lemma":"blesser","pos":"VER"} ,
		{"word":"blessant","word_nosc":"blessant","lemma":"blesser","pos":"VER"} ,
		{"word":"blesse","word_nosc":"blesse","lemma":"blesser","pos":"VER"} ,
		{"word":"blessent","word_nosc":"blessent","lemma":"blesser","pos":"VER"} ,
		{"word":"blesser","word_nosc":"blesser","lemma":"blesser","pos":"VER"} ,
		{"word":"blessera","word_nosc":"blessera","lemma":"blesser","pos":"VER"} ,
		{"word":"blesserai","word_nosc":"blesserai","lemma":"blesser","pos":"VER"} ,
		{"word":"blesseraient","word_nosc":"blesseraient","lemma":"blesser","pos":"VER"} ,
		{"word":"blesserais","word_nosc":"blesserais","lemma":"blesser","pos":"VER"} ,
		{"word":"blesserait","word_nosc":"blesserait","lemma":"blesser","pos":"VER"} ,
		{"word":"blesseras","word_nosc":"blesseras","lemma":"blesser","pos":"VER"} ,
		{"word":"blesserez","word_nosc":"blesserez","lemma":"blesser","pos":"VER"} ,
		{"word":"blesseriez","word_nosc":"blesseriez","lemma":"blesser","pos":"VER"} ,
		{"word":"blesserons","word_nosc":"blesserons","lemma":"blesser","pos":"VER"} ,
		{"word":"blesseront","word_nosc":"blesseront","lemma":"blesser","pos":"VER"} ,
		{"word":"blesses","word_nosc":"blesses","lemma":"blesser","pos":"VER"} ,
		{"word":"blessez","word_nosc":"blessez","lemma":"blesser","pos":"VER"} ,
		{"word":"blessiez","word_nosc":"blessiez","lemma":"blesser","pos":"VER"} ,
		{"word":"blessât","word_nosc":"blessat","lemma":"blesser","pos":"VER"} ,
		{"word":"blessèrent","word_nosc":"blesserent","lemma":"blesser","pos":"VER"} ,
		{"word":"blessé","word_nosc":"blesse","lemma":"blesser","pos":"VER"} ,
		{"word":"blessée","word_nosc":"blessee","lemma":"blesser","pos":"VER"} ,
		{"word":"blessées","word_nosc":"blessees","lemma":"blesser","pos":"VER"} ,
		{"word":"blessés","word_nosc":"blesses","lemma":"blesser","pos":"VER"} ,
		{"word":"blettir","word_nosc":"blettir","lemma":"blettir","pos":"VER"} ,
		{"word":"blettissait","word_nosc":"blettissait","lemma":"blettir","pos":"VER"} ,
		{"word":"bleui","word_nosc":"bleui","lemma":"bleuir","pos":"VER"} ,
		{"word":"bleuie","word_nosc":"bleuie","lemma":"bleuir","pos":"VER"} ,
		{"word":"bleuies","word_nosc":"bleuies","lemma":"bleuir","pos":"VER"} ,
		{"word":"bleuir","word_nosc":"bleuir","lemma":"bleuir","pos":"VER"} ,
		{"word":"bleuira","word_nosc":"bleuira","lemma":"bleuir","pos":"VER"} ,
		{"word":"bleuis","word_nosc":"bleuis","lemma":"bleuir","pos":"VER"} ,
		{"word":"bleuissaient","word_nosc":"bleuissaient","lemma":"bleuir","pos":"VER"} ,
		{"word":"bleuissait","word_nosc":"bleuissait","lemma":"bleuir","pos":"VER"} ,
		{"word":"bleuissent","word_nosc":"bleuissent","lemma":"bleuir","pos":"VER"} ,
		{"word":"bleuit","word_nosc":"bleuit","lemma":"bleuir","pos":"VER"} ,
		{"word":"bleuté","word_nosc":"bleute","lemma":"bleuter","pos":"VER"} ,
		{"word":"bleutée","word_nosc":"bleutee","lemma":"bleuter","pos":"VER"} ,
		{"word":"bleutées","word_nosc":"bleutees","lemma":"bleuter","pos":"VER"} ,
		{"word":"bleutés","word_nosc":"bleutes","lemma":"bleuter","pos":"VER"} ,
		{"word":"bleuît","word_nosc":"bleuit","lemma":"bleuir","pos":"VER"} ,
		{"word":"blindant","word_nosc":"blindant","lemma":"blinder","pos":"VER"} ,
		{"word":"blinde","word_nosc":"blinde","lemma":"blinder","pos":"VER"} ,
		{"word":"blindent","word_nosc":"blindent","lemma":"blinder","pos":"VER"} ,
		{"word":"blinder","word_nosc":"blinder","lemma":"blinder","pos":"VER"} ,
		{"word":"blindera","word_nosc":"blindera","lemma":"blinder","pos":"VER"} ,
		{"word":"blindez","word_nosc":"blindez","lemma":"blinder","pos":"VER"} ,
		{"word":"blindé","word_nosc":"blinde","lemma":"blinder","pos":"VER"} ,
		{"word":"blindée","word_nosc":"blindee","lemma":"blinder","pos":"VER"} ,
		{"word":"blindées","word_nosc":"blindees","lemma":"blinder","pos":"VER"} ,
		{"word":"blindés","word_nosc":"blindes","lemma":"blinder","pos":"VER"} ,
		{"word":"blondi","word_nosc":"blondi","lemma":"blondir","pos":"VER"} ,
		{"word":"blondie","word_nosc":"blondie","lemma":"blondir","pos":"VER"} ,
		{"word":"blondir","word_nosc":"blondir","lemma":"blondir","pos":"VER"} ,
		{"word":"blondis","word_nosc":"blondis","lemma":"blondir","pos":"VER"} ,
		{"word":"blondissait","word_nosc":"blondissait","lemma":"blondir","pos":"VER"} ,
		{"word":"blondit","word_nosc":"blondit","lemma":"blondir","pos":"VER"} ,
		{"word":"bloqua","word_nosc":"bloqua","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquai","word_nosc":"bloquai","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquaient","word_nosc":"bloquaient","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquais","word_nosc":"bloquais","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquait","word_nosc":"bloquait","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquant","word_nosc":"bloquant","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloque","word_nosc":"bloque","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquent","word_nosc":"bloquent","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquer","word_nosc":"bloquer","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquera","word_nosc":"bloquera","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquerai","word_nosc":"bloquerai","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquerais","word_nosc":"bloquerais","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquerait","word_nosc":"bloquerait","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloqueront","word_nosc":"bloqueront","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloques","word_nosc":"bloques","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquez","word_nosc":"bloquez","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquiez","word_nosc":"bloquiez","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquons","word_nosc":"bloquons","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquât","word_nosc":"bloquat","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquèrent","word_nosc":"bloquerent","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloqué","word_nosc":"bloque","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquée","word_nosc":"bloquee","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloquées","word_nosc":"bloquees","lemma":"bloquer","pos":"VER"} ,
		{"word":"bloqués","word_nosc":"bloques","lemma":"bloquer","pos":"VER"} ,
		{"word":"blotti","word_nosc":"blotti","lemma":"blottir","pos":"VER"} ,
		{"word":"blottie","word_nosc":"blottie","lemma":"blottir","pos":"VER"} ,
		{"word":"blotties","word_nosc":"blotties","lemma":"blottir","pos":"VER"} ,
		{"word":"blottir","word_nosc":"blottir","lemma":"blottir","pos":"VER"} ,
		{"word":"blottirai","word_nosc":"blottirai","lemma":"blottir","pos":"VER"} ,
		{"word":"blottirait","word_nosc":"blottirait","lemma":"blottir","pos":"VER"} ,
		{"word":"blottirent","word_nosc":"blottirent","lemma":"blottir","pos":"VER"} ,
		{"word":"blottis","word_nosc":"blottis","lemma":"blottir","pos":"VER"} ,
		{"word":"blottissaient","word_nosc":"blottissaient","lemma":"blottir","pos":"VER"} ,
		{"word":"blottissais","word_nosc":"blottissais","lemma":"blottir","pos":"VER"} ,
		{"word":"blottissait","word_nosc":"blottissait","lemma":"blottir","pos":"VER"} ,
		{"word":"blottissant","word_nosc":"blottissant","lemma":"blottir","pos":"VER"} ,
		{"word":"blottisse","word_nosc":"blottisse","lemma":"blottir","pos":"VER"} ,
		{"word":"blottissent","word_nosc":"blottissent","lemma":"blottir","pos":"VER"} ,
		{"word":"blottissions","word_nosc":"blottissions","lemma":"blottir","pos":"VER"} ,
		{"word":"blottit","word_nosc":"blottit","lemma":"blottir","pos":"VER"} ,
		{"word":"blouse","word_nosc":"blouse","lemma":"blouser","pos":"VER"} ,
		{"word":"blousent","word_nosc":"blousent","lemma":"blouser","pos":"VER"} ,
		{"word":"blouser","word_nosc":"blouser","lemma":"blouser","pos":"VER"} ,
		{"word":"blouses","word_nosc":"blouses","lemma":"blouser","pos":"VER"} ,
		{"word":"blousé","word_nosc":"blouse","lemma":"blouser","pos":"VER"} ,
		{"word":"blousée","word_nosc":"blousee","lemma":"blouser","pos":"VER"} ,
		{"word":"blousés","word_nosc":"blouses","lemma":"blouser","pos":"VER"} ,
		{"word":"bluffa","word_nosc":"bluffa","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluffaient","word_nosc":"bluffaient","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluffais","word_nosc":"bluffais","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluffait","word_nosc":"bluffait","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluffant","word_nosc":"bluffant","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluffe","word_nosc":"bluffe","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluffent","word_nosc":"bluffent","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluffer","word_nosc":"bluffer","lemma":"bluffer","pos":"VER"} ,
		{"word":"blufferais","word_nosc":"blufferais","lemma":"bluffer","pos":"VER"} ,
		{"word":"blufferez","word_nosc":"blufferez","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluffes","word_nosc":"bluffes","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluffez","word_nosc":"bluffez","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluffiez","word_nosc":"bluffiez","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluffons","word_nosc":"bluffons","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluffé","word_nosc":"bluffe","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluffée","word_nosc":"bluffee","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluffées","word_nosc":"bluffees","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluffés","word_nosc":"bluffes","lemma":"bluffer","pos":"VER"} ,
		{"word":"bluter","word_nosc":"bluter","lemma":"bluter","pos":"VER"} ,
		{"word":"blâma","word_nosc":"blama","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmaient","word_nosc":"blamaient","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmais","word_nosc":"blamais","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmait","word_nosc":"blamait","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmant","word_nosc":"blamant","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâme","word_nosc":"blame","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâment","word_nosc":"blament","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmer","word_nosc":"blamer","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmera","word_nosc":"blamera","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmerais","word_nosc":"blamerais","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmerait","word_nosc":"blamerait","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmeras","word_nosc":"blameras","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmerions","word_nosc":"blamerions","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmeront","word_nosc":"blameront","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmes","word_nosc":"blames","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmez","word_nosc":"blamez","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmons","word_nosc":"blamons","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmé","word_nosc":"blame","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmée","word_nosc":"blamee","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmées","word_nosc":"blamees","lemma":"blâmer","pos":"VER"} ,
		{"word":"blâmés","word_nosc":"blames","lemma":"blâmer","pos":"VER"} ,
		{"word":"blêmi","word_nosc":"blemi","lemma":"blêmir","pos":"VER"} ,
		{"word":"blêmies","word_nosc":"blemies","lemma":"blêmir","pos":"VER"} ,
		{"word":"blêmir","word_nosc":"blemir","lemma":"blêmir","pos":"VER"} ,
		{"word":"blêmis","word_nosc":"blemis","lemma":"blêmir","pos":"VER"} ,
		{"word":"blêmissais","word_nosc":"blemissais","lemma":"blêmir","pos":"VER"} ,
		{"word":"blêmissait","word_nosc":"blemissait","lemma":"blêmir","pos":"VER"} ,
		{"word":"blêmissant","word_nosc":"blemissant","lemma":"blêmir","pos":"VER"} ,
		{"word":"blêmit","word_nosc":"blemit","lemma":"blêmir","pos":"VER"} ,
		{"word":"bobinait","word_nosc":"bobinait","lemma":"bobiner","pos":"VER"} ,
		{"word":"bobine","word_nosc":"bobine","lemma":"bobiner","pos":"VER"} ,
		{"word":"bobines","word_nosc":"bobines","lemma":"bobiner","pos":"VER"} ,
		{"word":"bogue","word_nosc":"bogue","lemma":"boguer","pos":"VER"} ,
		{"word":"boguer","word_nosc":"boguer","lemma":"boguer","pos":"VER"} ,
		{"word":"boira","word_nosc":"boira","lemma":"boire","pos":"VER"} ,
		{"word":"boirai","word_nosc":"boirai","lemma":"boire","pos":"VER"} ,
		{"word":"boiraient","word_nosc":"boiraient","lemma":"boire","pos":"VER"} ,
		{"word":"boirais","word_nosc":"boirais","lemma":"boire","pos":"VER"} ,
		{"word":"boirait","word_nosc":"boirait","lemma":"boire","pos":"VER"} ,
		{"word":"boiras","word_nosc":"boiras","lemma":"boire","pos":"VER"} ,
		{"word":"boire","word_nosc":"boire","lemma":"boire","pos":"VER"} ,
		{"word":"boirez","word_nosc":"boirez","lemma":"boire","pos":"VER"} ,
		{"word":"boiriez","word_nosc":"boiriez","lemma":"boire","pos":"VER"} ,
		{"word":"boirions","word_nosc":"boirions","lemma":"boire","pos":"VER"} ,
		{"word":"boirons","word_nosc":"boirons","lemma":"boire","pos":"VER"} ,
		{"word":"boiront","word_nosc":"boiront","lemma":"boire","pos":"VER"} ,
		{"word":"bois","word_nosc":"bois","lemma":"boire","pos":"VER"} ,
		{"word":"boisaient","word_nosc":"boisaient","lemma":"boiser","pos":"VER"} ,
		{"word":"boisait","word_nosc":"boisait","lemma":"boiser","pos":"VER"} ,
		{"word":"boisant","word_nosc":"boisant","lemma":"boiser","pos":"VER"} ,
		{"word":"boise","word_nosc":"boise","lemma":"boiser","pos":"VER"} ,
		{"word":"boissonnée","word_nosc":"boissonnee","lemma":"boissonner","pos":"VER"} ,
		{"word":"boisé","word_nosc":"boise","lemma":"boiser","pos":"VER"} ,
		{"word":"boisée","word_nosc":"boisee","lemma":"boiser","pos":"VER"} ,
		{"word":"boisées","word_nosc":"boisees","lemma":"boiser","pos":"VER"} ,
		{"word":"boisés","word_nosc":"boises","lemma":"boiser","pos":"VER"} ,
		{"word":"boit","word_nosc":"boit","lemma":"boire","pos":"VER"} ,
		{"word":"boitaient","word_nosc":"boitaient","lemma":"boiter","pos":"VER"} ,
		{"word":"boitais","word_nosc":"boitais","lemma":"boiter","pos":"VER"} ,
		{"word":"boitait","word_nosc":"boitait","lemma":"boiter","pos":"VER"} ,
		{"word":"boitant","word_nosc":"boitant","lemma":"boiter","pos":"VER"} ,
		{"word":"boite","word_nosc":"boite","lemma":"boiter","pos":"VER"} ,
		{"word":"boitent","word_nosc":"boitent","lemma":"boiter","pos":"VER"} ,
		{"word":"boiter","word_nosc":"boiter","lemma":"boiter","pos":"VER"} ,
		{"word":"boitera","word_nosc":"boitera","lemma":"boiter","pos":"VER"} ,
		{"word":"boiterai","word_nosc":"boiterai","lemma":"boiter","pos":"VER"} ,
		{"word":"boiteraient","word_nosc":"boiteraient","lemma":"boiter","pos":"VER"} ,
		{"word":"boiterait","word_nosc":"boiterait","lemma":"boiter","pos":"VER"} ,
		{"word":"boiteras","word_nosc":"boiteras","lemma":"boiter","pos":"VER"} ,
		{"word":"boites","word_nosc":"boites","lemma":"boiter","pos":"VER"} ,
		{"word":"boitez","word_nosc":"boitez","lemma":"boiter","pos":"VER"} ,
		{"word":"boitiez","word_nosc":"boitiez","lemma":"boiter","pos":"VER"} ,
		{"word":"boitillait","word_nosc":"boitillait","lemma":"boitiller","pos":"VER"} ,
		{"word":"boitillant","word_nosc":"boitillant","lemma":"boitiller","pos":"VER"} ,
		{"word":"boitille","word_nosc":"boitille","lemma":"boitiller","pos":"VER"} ,
		{"word":"boitiller","word_nosc":"boitiller","lemma":"boitiller","pos":"VER"} ,
		{"word":"boité","word_nosc":"boite","lemma":"boiter","pos":"VER"} ,
		{"word":"boive","word_nosc":"boive","lemma":"boire","pos":"VER"} ,
		{"word":"boivent","word_nosc":"boivent","lemma":"boire","pos":"VER"} ,
		{"word":"boives","word_nosc":"boives","lemma":"boire","pos":"VER"} ,
		{"word":"bomba","word_nosc":"bomba","lemma":"bomber","pos":"VER"} ,
		{"word":"bombaient","word_nosc":"bombaient","lemma":"bomber","pos":"VER"} ,
		{"word":"bombais","word_nosc":"bombais","lemma":"bomber","pos":"VER"} ,
		{"word":"bombait","word_nosc":"bombait","lemma":"bomber","pos":"VER"} ,
		{"word":"bombant","word_nosc":"bombant","lemma":"bomber","pos":"VER"} ,
		{"word":"bombarda","word_nosc":"bombarda","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardaient","word_nosc":"bombardaient","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardais","word_nosc":"bombardais","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardait","word_nosc":"bombardait","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardant","word_nosc":"bombardant","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombarde","word_nosc":"bombarde","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardent","word_nosc":"bombardent","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombarder","word_nosc":"bombarder","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardera","word_nosc":"bombardera","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombarderai","word_nosc":"bombarderai","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombarderaient","word_nosc":"bombarderaient","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombarderais","word_nosc":"bombarderais","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombarderait","word_nosc":"bombarderait","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombarderons","word_nosc":"bombarderons","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombarderont","word_nosc":"bombarderont","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardes","word_nosc":"bombardes","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardez","word_nosc":"bombardez","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardions","word_nosc":"bombardions","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardons","word_nosc":"bombardons","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardâmes","word_nosc":"bombardames","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardèrent","word_nosc":"bombarderent","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardé","word_nosc":"bombarde","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardée","word_nosc":"bombardee","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardées","word_nosc":"bombardees","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombardés","word_nosc":"bombardes","lemma":"bombarder","pos":"VER"} ,
		{"word":"bombas","word_nosc":"bombas","lemma":"bomber","pos":"VER"} ,
		{"word":"bombasses","word_nosc":"bombasses","lemma":"bomber","pos":"VER"} ,
		{"word":"bombe","word_nosc":"bombe","lemma":"bomber","pos":"VER"} ,
		{"word":"bombent","word_nosc":"bombent","lemma":"bomber","pos":"VER"} ,
		{"word":"bomber","word_nosc":"bomber","lemma":"bomber","pos":"VER"} ,
		{"word":"bombes","word_nosc":"bombes","lemma":"bomber","pos":"VER"} ,
		{"word":"bombez","word_nosc":"bombez","lemma":"bomber","pos":"VER"} ,
		{"word":"bombèrent","word_nosc":"bomberent","lemma":"bomber","pos":"VER"} ,
		{"word":"bombé","word_nosc":"bombe","lemma":"bomber","pos":"VER"} ,
		{"word":"bombée","word_nosc":"bombee","lemma":"bomber","pos":"VER"} ,
		{"word":"bombées","word_nosc":"bombees","lemma":"bomber","pos":"VER"} ,
		{"word":"bombés","word_nosc":"bombes","lemma":"bomber","pos":"VER"} ,
		{"word":"bondait","word_nosc":"bondait","lemma":"bonder","pos":"VER"} ,
		{"word":"bonder","word_nosc":"bonder","lemma":"bonder","pos":"VER"} ,
		{"word":"bondi","word_nosc":"bondi","lemma":"bondir","pos":"VER"} ,
		{"word":"bondieuses","word_nosc":"bondieuses","lemma":"bondieuser","pos":"VER"} ,
		{"word":"bondir","word_nosc":"bondir","lemma":"bondir","pos":"VER"} ,
		{"word":"bondira","word_nosc":"bondira","lemma":"bondir","pos":"VER"} ,
		{"word":"bondirais","word_nosc":"bondirais","lemma":"bondir","pos":"VER"} ,
		{"word":"bondirait","word_nosc":"bondirait","lemma":"bondir","pos":"VER"} ,
		{"word":"bondirent","word_nosc":"bondirent","lemma":"bondir","pos":"VER"} ,
		{"word":"bondis","word_nosc":"bondis","lemma":"bondir","pos":"VER"} ,
		{"word":"bondissaient","word_nosc":"bondissaient","lemma":"bondir","pos":"VER"} ,
		{"word":"bondissais","word_nosc":"bondissais","lemma":"bondir","pos":"VER"} ,
		{"word":"bondissait","word_nosc":"bondissait","lemma":"bondir","pos":"VER"} ,
		{"word":"bondissant","word_nosc":"bondissant","lemma":"bondir","pos":"VER"} ,
		{"word":"bondisse","word_nosc":"bondisse","lemma":"bondir","pos":"VER"} ,
		{"word":"bondissent","word_nosc":"bondissent","lemma":"bondir","pos":"VER"} ,
		{"word":"bondissez","word_nosc":"bondissez","lemma":"bondir","pos":"VER"} ,
		{"word":"bondissons","word_nosc":"bondissons","lemma":"bondir","pos":"VER"} ,
		{"word":"bondit","word_nosc":"bondit","lemma":"bondir","pos":"VER"} ,
		{"word":"bondé","word_nosc":"bonde","lemma":"bonder","pos":"VER"} ,
		{"word":"bondée","word_nosc":"bondee","lemma":"bonder","pos":"VER"} ,
		{"word":"bondées","word_nosc":"bondees","lemma":"bonder","pos":"VER"} ,
		{"word":"bondés","word_nosc":"bondes","lemma":"bonder","pos":"VER"} ,
		{"word":"bondît","word_nosc":"bondit","lemma":"bondir","pos":"VER"} ,
		{"word":"bonifiaient","word_nosc":"bonifiaient","lemma":"bonifier","pos":"VER"} ,
		{"word":"bonifier","word_nosc":"bonifier","lemma":"bonifier","pos":"VER"} ,
		{"word":"bonifierait","word_nosc":"bonifierait","lemma":"bonifier","pos":"VER"} ,
		{"word":"bonifiez","word_nosc":"bonifiez","lemma":"bonifier","pos":"VER"} ,
		{"word":"bonifié","word_nosc":"bonifie","lemma":"bonifier","pos":"VER"} ,
		{"word":"bonifiée","word_nosc":"bonifiee","lemma":"bonifier","pos":"VER"} ,
		{"word":"bonimentait","word_nosc":"bonimentait","lemma":"bonimenter","pos":"VER"} ,
		{"word":"bonimente","word_nosc":"bonimente","lemma":"bonimenter","pos":"VER"} ,
		{"word":"bonimenter","word_nosc":"bonimenter","lemma":"bonimenter","pos":"VER"} ,
		{"word":"bonir","word_nosc":"bonir","lemma":"bonir","pos":"VER"} ,
		{"word":"bonissait","word_nosc":"bonissait","lemma":"bonir","pos":"VER"} ,
		{"word":"bonisse","word_nosc":"bonisse","lemma":"bonir","pos":"VER"} ,
		{"word":"bonit","word_nosc":"bonit","lemma":"bonir","pos":"VER"} ,
		{"word":"bonneter","word_nosc":"bonneter","lemma":"bonneter","pos":"VER"} ,
		{"word":"bonni","word_nosc":"bonni","lemma":"bonnir","pos":"VER"} ,
		{"word":"bonnie","word_nosc":"bonnie","lemma":"bonnir","pos":"VER"} ,
		{"word":"bonnir","word_nosc":"bonnir","lemma":"bonnir","pos":"VER"} ,
		{"word":"bonnis","word_nosc":"bonnis","lemma":"bonnir","pos":"VER"} ,
		{"word":"bonnisse","word_nosc":"bonnisse","lemma":"bonnir","pos":"VER"} ,
		{"word":"bonnissent","word_nosc":"bonnissent","lemma":"bonnir","pos":"VER"} ,
		{"word":"bonnit","word_nosc":"bonnit","lemma":"bonnir","pos":"VER"} ,
		{"word":"booster","word_nosc":"booster","lemma":"booster","pos":"VER"} ,
		{"word":"borda","word_nosc":"borda","lemma":"border","pos":"VER"} ,
		{"word":"bordai","word_nosc":"bordai","lemma":"border","pos":"VER"} ,
		{"word":"bordaient","word_nosc":"bordaient","lemma":"border","pos":"VER"} ,
		{"word":"bordait","word_nosc":"bordait","lemma":"border","pos":"VER"} ,
		{"word":"bordant","word_nosc":"bordant","lemma":"border","pos":"VER"} ,
		{"word":"borde","word_nosc":"borde","lemma":"border","pos":"VER"} ,
		{"word":"bordent","word_nosc":"bordent","lemma":"border","pos":"VER"} ,
		{"word":"border","word_nosc":"border","lemma":"border","pos":"VER"} ,
		{"word":"bordera","word_nosc":"bordera","lemma":"border","pos":"VER"} ,
		{"word":"borderait","word_nosc":"borderait","lemma":"border","pos":"VER"} ,
		{"word":"borderiez","word_nosc":"borderiez","lemma":"border","pos":"VER"} ,
		{"word":"bordes","word_nosc":"bordes","lemma":"border","pos":"VER"} ,
		{"word":"bordez","word_nosc":"bordez","lemma":"border","pos":"VER"} ,
		{"word":"bordurant","word_nosc":"bordurant","lemma":"bordurer","pos":"VER"} ,
		{"word":"bordurer","word_nosc":"bordurer","lemma":"bordurer","pos":"VER"} ,
		{"word":"borduré","word_nosc":"bordure","lemma":"bordurer","pos":"VER"} ,
		{"word":"bordurée","word_nosc":"borduree","lemma":"bordurer","pos":"VER"} ,
		{"word":"bordèrent","word_nosc":"borderent","lemma":"border","pos":"VER"} ,
		{"word":"bordé","word_nosc":"borde","lemma":"border","pos":"VER"} ,
		{"word":"bordée","word_nosc":"bordee","lemma":"border","pos":"VER"} ,
		{"word":"bordées","word_nosc":"bordees","lemma":"border","pos":"VER"} ,
		{"word":"bordéliser","word_nosc":"bordeliser","lemma":"bordéliser","pos":"VER"} ,
		{"word":"bordés","word_nosc":"bordes","lemma":"border","pos":"VER"} ,
		{"word":"borgnotaient","word_nosc":"borgnotaient","lemma":"borgnoter","pos":"VER"} ,
		{"word":"borgnotant","word_nosc":"borgnotant","lemma":"borgnoter","pos":"VER"} ,
		{"word":"borgnote","word_nosc":"borgnote","lemma":"borgnoter","pos":"VER"} ,
		{"word":"borgnoté","word_nosc":"borgnote","lemma":"borgnoter","pos":"VER"} ,
		{"word":"borna","word_nosc":"borna","lemma":"borner","pos":"VER"} ,
		{"word":"bornai","word_nosc":"bornai","lemma":"borner","pos":"VER"} ,
		{"word":"bornaient","word_nosc":"bornaient","lemma":"borner","pos":"VER"} ,
		{"word":"bornais","word_nosc":"bornais","lemma":"borner","pos":"VER"} ,
		{"word":"bornait","word_nosc":"bornait","lemma":"borner","pos":"VER"} ,
		{"word":"bornant","word_nosc":"bornant","lemma":"borner","pos":"VER"} ,
		{"word":"borne","word_nosc":"borne","lemma":"borner","pos":"VER"} ,
		{"word":"bornent","word_nosc":"bornent","lemma":"borner","pos":"VER"} ,
		{"word":"borner","word_nosc":"borner","lemma":"borner","pos":"VER"} ,
		{"word":"bornera","word_nosc":"bornera","lemma":"borner","pos":"VER"} ,
		{"word":"bornerai","word_nosc":"bornerai","lemma":"borner","pos":"VER"} ,
		{"word":"borneraient","word_nosc":"borneraient","lemma":"borner","pos":"VER"} ,
		{"word":"bornerait","word_nosc":"bornerait","lemma":"borner","pos":"VER"} ,
		{"word":"bornerons","word_nosc":"bornerons","lemma":"borner","pos":"VER"} ,
		{"word":"bornes","word_nosc":"bornes","lemma":"borner","pos":"VER"} ,
		{"word":"bornez","word_nosc":"bornez","lemma":"borner","pos":"VER"} ,
		{"word":"bornions","word_nosc":"bornions","lemma":"borner","pos":"VER"} ,
		{"word":"bornât","word_nosc":"bornat","lemma":"borner","pos":"VER"} ,
		{"word":"bornèrent","word_nosc":"bornerent","lemma":"borner","pos":"VER"} ,
		{"word":"borné","word_nosc":"borne","lemma":"borner","pos":"VER"} ,
		{"word":"bornée","word_nosc":"bornee","lemma":"borner","pos":"VER"} ,
		{"word":"bornées","word_nosc":"bornees","lemma":"borner","pos":"VER"} ,
		{"word":"bornés","word_nosc":"bornes","lemma":"borner","pos":"VER"} ,
		{"word":"bossa","word_nosc":"bossa","lemma":"bosser","pos":"VER"} ,
		{"word":"bossaient","word_nosc":"bossaient","lemma":"bosser","pos":"VER"} ,
		{"word":"bossais","word_nosc":"bossais","lemma":"bosser","pos":"VER"} ,
		{"word":"bossait","word_nosc":"bossait","lemma":"bosser","pos":"VER"} ,
		{"word":"bossant","word_nosc":"bossant","lemma":"bosser","pos":"VER"} ,
		{"word":"bosse","word_nosc":"bosse","lemma":"bosser","pos":"VER"} ,
		{"word":"bossela","word_nosc":"bossela","lemma":"bosseler","pos":"VER"} ,
		{"word":"bosselaient","word_nosc":"bosselaient","lemma":"bosseler","pos":"VER"} ,
		{"word":"bosselait","word_nosc":"bosselait","lemma":"bosseler","pos":"VER"} ,
		{"word":"bosseler","word_nosc":"bosseler","lemma":"bosseler","pos":"VER"} ,
		{"word":"bosselé","word_nosc":"bossele","lemma":"bosseler","pos":"VER"} ,
		{"word":"bosselée","word_nosc":"bosselee","lemma":"bosseler","pos":"VER"} ,
		{"word":"bosselées","word_nosc":"bosselees","lemma":"bosseler","pos":"VER"} ,
		{"word":"bosselés","word_nosc":"bosseles","lemma":"bosseler","pos":"VER"} ,
		{"word":"bossent","word_nosc":"bossent","lemma":"bosser","pos":"VER"} ,
		{"word":"bosser","word_nosc":"bosser","lemma":"bosser","pos":"VER"} ,
		{"word":"bossera","word_nosc":"bossera","lemma":"bosser","pos":"VER"} ,
		{"word":"bosserai","word_nosc":"bosserai","lemma":"bosser","pos":"VER"} ,
		{"word":"bosserais","word_nosc":"bosserais","lemma":"bosser","pos":"VER"} ,
		{"word":"bosserait","word_nosc":"bosserait","lemma":"bosser","pos":"VER"} ,
		{"word":"bosseras","word_nosc":"bosseras","lemma":"bosser","pos":"VER"} ,
		{"word":"bosserez","word_nosc":"bosserez","lemma":"bosser","pos":"VER"} ,
		{"word":"bosses","word_nosc":"bosses","lemma":"bosser","pos":"VER"} ,
		{"word":"bossez","word_nosc":"bossez","lemma":"bosser","pos":"VER"} ,
		{"word":"bossiez","word_nosc":"bossiez","lemma":"bosser","pos":"VER"} ,
		{"word":"bossions","word_nosc":"bossions","lemma":"bosser","pos":"VER"} ,
		{"word":"bossons","word_nosc":"bossons","lemma":"bosser","pos":"VER"} ,
		{"word":"bossuaient","word_nosc":"bossuaient","lemma":"bossuer","pos":"VER"} ,
		{"word":"bossue","word_nosc":"bossue","lemma":"bossuer","pos":"VER"} ,
		{"word":"bossué","word_nosc":"bossue","lemma":"bossuer","pos":"VER"} ,
		{"word":"bossuées","word_nosc":"bossuees","lemma":"bossuer","pos":"VER"} ,
		{"word":"bossé","word_nosc":"bosse","lemma":"bosser","pos":"VER"} ,
		{"word":"bostonner","word_nosc":"bostonner","lemma":"bostonner","pos":"VER"} ,
		{"word":"botta","word_nosc":"botta","lemma":"botter","pos":"VER"} ,
		{"word":"bottai","word_nosc":"bottai","lemma":"botter","pos":"VER"} ,
		{"word":"bottaient","word_nosc":"bottaient","lemma":"botter","pos":"VER"} ,
		{"word":"bottait","word_nosc":"bottait","lemma":"botter","pos":"VER"} ,
		{"word":"bottant","word_nosc":"bottant","lemma":"botter","pos":"VER"} ,
		{"word":"botte","word_nosc":"botte","lemma":"botter","pos":"VER"} ,
		{"word":"botteler","word_nosc":"botteler","lemma":"botteler","pos":"VER"} ,
		{"word":"bottellent","word_nosc":"bottellent","lemma":"botteler","pos":"VER"} ,
		{"word":"bottelé","word_nosc":"bottele","lemma":"botteler","pos":"VER"} ,
		{"word":"bottelées","word_nosc":"bottelees","lemma":"botteler","pos":"VER"} ,
		{"word":"bottent","word_nosc":"bottent","lemma":"botter","pos":"VER"} ,
		{"word":"botter","word_nosc":"botter","lemma":"botter","pos":"VER"} ,
		{"word":"bottera","word_nosc":"bottera","lemma":"botter","pos":"VER"} ,
		{"word":"botterai","word_nosc":"botterai","lemma":"botter","pos":"VER"} ,
		{"word":"botterais","word_nosc":"botterais","lemma":"botter","pos":"VER"} ,
		{"word":"botterait","word_nosc":"botterait","lemma":"botter","pos":"VER"} ,
		{"word":"botteras","word_nosc":"botteras","lemma":"botter","pos":"VER"} ,
		{"word":"botterons","word_nosc":"botterons","lemma":"botter","pos":"VER"} ,
		{"word":"botteront","word_nosc":"botteront","lemma":"botter","pos":"VER"} ,
		{"word":"bottes","word_nosc":"bottes","lemma":"botter","pos":"VER"} ,
		{"word":"bottez","word_nosc":"bottez","lemma":"botter","pos":"VER"} ,
		{"word":"bottiez","word_nosc":"bottiez","lemma":"botter","pos":"VER"} ,
		{"word":"bottiné","word_nosc":"bottine","lemma":"bottiner","pos":"VER"} ,
		{"word":"bottons","word_nosc":"bottons","lemma":"botter","pos":"VER"} ,
		{"word":"botté","word_nosc":"botte","lemma":"botter","pos":"VER"} ,
		{"word":"bottée","word_nosc":"bottee","lemma":"botter","pos":"VER"} ,
		{"word":"bottées","word_nosc":"bottees","lemma":"botter","pos":"VER"} ,
		{"word":"bottés","word_nosc":"bottes","lemma":"botter","pos":"VER"} ,
		{"word":"bouboule","word_nosc":"bouboule","lemma":"boubouler","pos":"VER"} ,
		{"word":"bouboules","word_nosc":"bouboules","lemma":"boubouler","pos":"VER"} ,
		{"word":"boucanait","word_nosc":"boucanait","lemma":"boucaner","pos":"VER"} ,
		{"word":"boucaner","word_nosc":"boucaner","lemma":"boucaner","pos":"VER"} ,
		{"word":"boucané","word_nosc":"boucane","lemma":"boucaner","pos":"VER"} ,
		{"word":"boucanée","word_nosc":"boucanee","lemma":"boucaner","pos":"VER"} ,
		{"word":"boucanées","word_nosc":"boucanees","lemma":"boucaner","pos":"VER"} ,
		{"word":"boucanés","word_nosc":"boucanes","lemma":"boucaner","pos":"VER"} ,
		{"word":"boucha","word_nosc":"boucha","lemma":"boucher","pos":"VER"} ,
		{"word":"bouchai","word_nosc":"bouchai","lemma":"boucher","pos":"VER"} ,
		{"word":"bouchaient","word_nosc":"bouchaient","lemma":"boucher","pos":"VER"} ,
		{"word":"bouchais","word_nosc":"bouchais","lemma":"boucher","pos":"VER"} ,
		{"word":"bouchait","word_nosc":"bouchait","lemma":"boucher","pos":"VER"} ,
		{"word":"bouchant","word_nosc":"bouchant","lemma":"boucher","pos":"VER"} ,
		{"word":"bouchardant","word_nosc":"bouchardant","lemma":"boucharder","pos":"VER"} ,
		{"word":"boucharder","word_nosc":"boucharder","lemma":"boucharder","pos":"VER"} ,
		{"word":"bouche","word_nosc":"bouche","lemma":"boucher","pos":"VER"} ,
		{"word":"bouchent","word_nosc":"bouchent","lemma":"boucher","pos":"VER"} ,
		{"word":"boucher","word_nosc":"boucher","lemma":"boucher","pos":"VER"} ,
		{"word":"bouchera","word_nosc":"bouchera","lemma":"boucher","pos":"VER"} ,
		{"word":"boucheraient","word_nosc":"boucheraient","lemma":"boucher","pos":"VER"} ,
		{"word":"boucherais","word_nosc":"boucherais","lemma":"boucher","pos":"VER"} ,
		{"word":"boucherait","word_nosc":"boucherait","lemma":"boucher","pos":"VER"} ,
		{"word":"boucheras","word_nosc":"boucheras","lemma":"boucher","pos":"VER"} ,
		{"word":"boucherons","word_nosc":"boucherons","lemma":"boucher","pos":"VER"} ,
		{"word":"bouches","word_nosc":"bouches","lemma":"boucher","pos":"VER"} ,
		{"word":"bouchez","word_nosc":"bouchez","lemma":"boucher","pos":"VER"} ,
		{"word":"bouchions","word_nosc":"bouchions","lemma":"boucher","pos":"VER"} ,
		{"word":"bouchonna","word_nosc":"bouchonna","lemma":"bouchonner","pos":"VER"} ,
		{"word":"bouchonnaient","word_nosc":"bouchonnaient","lemma":"bouchonner","pos":"VER"} ,
		{"word":"bouchonnait","word_nosc":"bouchonnait","lemma":"bouchonner","pos":"VER"} ,
		{"word":"bouchonnant","word_nosc":"bouchonnant","lemma":"bouchonner","pos":"VER"} ,
		{"word":"bouchonne","word_nosc":"bouchonne","lemma":"bouchonner","pos":"VER"} ,
		{"word":"bouchonner","word_nosc":"bouchonner","lemma":"bouchonner","pos":"VER"} ,
		{"word":"bouchonneras","word_nosc":"bouchonneras","lemma":"bouchonner","pos":"VER"} ,
		{"word":"bouchonné","word_nosc":"bouchonne","lemma":"bouchonner","pos":"VER"} ,
		{"word":"bouchonnée","word_nosc":"bouchonnee","lemma":"bouchonner","pos":"VER"} ,
		{"word":"bouchons","word_nosc":"bouchons","lemma":"boucher","pos":"VER"} ,
		{"word":"bouchèrent","word_nosc":"boucherent","lemma":"boucher","pos":"VER"} ,
		{"word":"bouché","word_nosc":"bouche","lemma":"boucher","pos":"VER"} ,
		{"word":"bouchée","word_nosc":"bouchee","lemma":"boucher","pos":"VER"} ,
		{"word":"bouchées","word_nosc":"bouchees","lemma":"boucher","pos":"VER"} ,
		{"word":"bouchés","word_nosc":"bouches","lemma":"boucher","pos":"VER"} ,
		{"word":"boucla","word_nosc":"boucla","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclaient","word_nosc":"bouclaient","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclais","word_nosc":"bouclais","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclait","word_nosc":"bouclait","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclant","word_nosc":"bouclant","lemma":"boucler","pos":"VER"} ,
		{"word":"boucle","word_nosc":"boucle","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclent","word_nosc":"bouclent","lemma":"boucler","pos":"VER"} ,
		{"word":"boucler","word_nosc":"boucler","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclera","word_nosc":"bouclera","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclerai","word_nosc":"bouclerai","lemma":"boucler","pos":"VER"} ,
		{"word":"boucleraient","word_nosc":"boucleraient","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclerais","word_nosc":"bouclerais","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclerait","word_nosc":"bouclerait","lemma":"boucler","pos":"VER"} ,
		{"word":"boucleras","word_nosc":"boucleras","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclerons","word_nosc":"bouclerons","lemma":"boucler","pos":"VER"} ,
		{"word":"boucleront","word_nosc":"boucleront","lemma":"boucler","pos":"VER"} ,
		{"word":"boucles","word_nosc":"boucles","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclez","word_nosc":"bouclez","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclons","word_nosc":"bouclons","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclâmes","word_nosc":"bouclames","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclât","word_nosc":"bouclat","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclèrent","word_nosc":"bouclerent","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclé","word_nosc":"boucle","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclée","word_nosc":"bouclee","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclées","word_nosc":"bouclees","lemma":"boucler","pos":"VER"} ,
		{"word":"bouclés","word_nosc":"boucles","lemma":"boucler","pos":"VER"} ,
		{"word":"bouda","word_nosc":"bouda","lemma":"bouder","pos":"VER"} ,
		{"word":"boudaient","word_nosc":"boudaient","lemma":"bouder","pos":"VER"} ,
		{"word":"boudais","word_nosc":"boudais","lemma":"bouder","pos":"VER"} ,
		{"word":"boudait","word_nosc":"boudait","lemma":"bouder","pos":"VER"} ,
		{"word":"boudant","word_nosc":"boudant","lemma":"bouder","pos":"VER"} ,
		{"word":"boude","word_nosc":"boude","lemma":"bouder","pos":"VER"} ,
		{"word":"boudent","word_nosc":"boudent","lemma":"bouder","pos":"VER"} ,
		{"word":"bouder","word_nosc":"bouder","lemma":"bouder","pos":"VER"} ,
		{"word":"bouderai","word_nosc":"bouderai","lemma":"bouder","pos":"VER"} ,
		{"word":"bouderait","word_nosc":"bouderait","lemma":"bouder","pos":"VER"} ,
		{"word":"boudes","word_nosc":"boudes","lemma":"bouder","pos":"VER"} ,
		{"word":"boudez","word_nosc":"boudez","lemma":"bouder","pos":"VER"} ,
		{"word":"boudiez","word_nosc":"boudiez","lemma":"bouder","pos":"VER"} ,
		{"word":"boudinaient","word_nosc":"boudinaient","lemma":"boudiner","pos":"VER"} ,
		{"word":"boudinait","word_nosc":"boudinait","lemma":"boudiner","pos":"VER"} ,
		{"word":"boudinant","word_nosc":"boudinant","lemma":"boudiner","pos":"VER"} ,
		{"word":"boudine","word_nosc":"boudine","lemma":"boudiner","pos":"VER"} ,
		{"word":"boudinent","word_nosc":"boudinent","lemma":"boudiner","pos":"VER"} ,
		{"word":"boudiné","word_nosc":"boudine","lemma":"boudiner","pos":"VER"} ,
		{"word":"boudinée","word_nosc":"boudinee","lemma":"boudiner","pos":"VER"} ,
		{"word":"boudinées","word_nosc":"boudinees","lemma":"boudiner","pos":"VER"} ,
		{"word":"boudinés","word_nosc":"boudines","lemma":"boudiner","pos":"VER"} ,
		{"word":"boudons","word_nosc":"boudons","lemma":"bouder","pos":"VER"} ,
		{"word":"boudèrent","word_nosc":"bouderent","lemma":"bouder","pos":"VER"} ,
		{"word":"boudé","word_nosc":"boude","lemma":"bouder","pos":"VER"} ,
		{"word":"boudée","word_nosc":"boudee","lemma":"bouder","pos":"VER"} ,
		{"word":"boudés","word_nosc":"boudes","lemma":"bouder","pos":"VER"} ,
		{"word":"bouffaient","word_nosc":"bouffaient","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouffais","word_nosc":"bouffais","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouffait","word_nosc":"bouffait","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouffant","word_nosc":"bouffant","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouffe","word_nosc":"bouffe","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouffent","word_nosc":"bouffent","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouffer","word_nosc":"bouffer","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouffera","word_nosc":"bouffera","lemma":"bouffer","pos":"VER"} ,
		{"word":"boufferai","word_nosc":"boufferai","lemma":"bouffer","pos":"VER"} ,
		{"word":"boufferaient","word_nosc":"boufferaient","lemma":"bouffer","pos":"VER"} ,
		{"word":"boufferais","word_nosc":"boufferais","lemma":"bouffer","pos":"VER"} ,
		{"word":"boufferait","word_nosc":"boufferait","lemma":"bouffer","pos":"VER"} ,
		{"word":"boufferas","word_nosc":"boufferas","lemma":"bouffer","pos":"VER"} ,
		{"word":"boufferez","word_nosc":"boufferez","lemma":"bouffer","pos":"VER"} ,
		{"word":"boufferont","word_nosc":"boufferont","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouffes","word_nosc":"bouffes","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouffez","word_nosc":"bouffez","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouffi","word_nosc":"bouffi","lemma":"bouffir","pos":"VER"} ,
		{"word":"bouffie","word_nosc":"bouffie","lemma":"bouffir","pos":"VER"} ,
		{"word":"bouffies","word_nosc":"bouffies","lemma":"bouffir","pos":"VER"} ,
		{"word":"bouffiez","word_nosc":"bouffiez","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouffis","word_nosc":"bouffis","lemma":"bouffi","pos":"VER"} ,
		{"word":"bouffis","word_nosc":"bouffis","lemma":"bouffir","pos":"VER"} ,
		{"word":"bouffonna","word_nosc":"bouffonna","lemma":"bouffonner","pos":"VER"} ,
		{"word":"bouffonnait","word_nosc":"bouffonnait","lemma":"bouffonner","pos":"VER"} ,
		{"word":"bouffonnant","word_nosc":"bouffonnant","lemma":"bouffonner","pos":"VER"} ,
		{"word":"bouffonner","word_nosc":"bouffonner","lemma":"bouffonner","pos":"VER"} ,
		{"word":"bouffonnes","word_nosc":"bouffonnes","lemma":"bouffonner","pos":"VER"} ,
		{"word":"bouffons","word_nosc":"bouffons","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouffé","word_nosc":"bouffe","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouffée","word_nosc":"bouffee","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouffées","word_nosc":"bouffees","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouffés","word_nosc":"bouffes","lemma":"bouffer","pos":"VER"} ,
		{"word":"bouge","word_nosc":"bouge","lemma":"bouger","pos":"VER"} ,
		{"word":"bougea","word_nosc":"bougea","lemma":"bouger","pos":"VER"} ,
		{"word":"bougeai","word_nosc":"bougeai","lemma":"bouger","pos":"VER"} ,
		{"word":"bougeaient","word_nosc":"bougeaient","lemma":"bouger","pos":"VER"} ,
		{"word":"bougeais","word_nosc":"bougeais","lemma":"bouger","pos":"VER"} ,
		{"word":"bougeait","word_nosc":"bougeait","lemma":"bouger","pos":"VER"} ,
		{"word":"bougeant","word_nosc":"bougeant","lemma":"bouger","pos":"VER"} ,
		{"word":"bougeassent","word_nosc":"bougeassent","lemma":"bouger","pos":"VER"} ,
		{"word":"bougent","word_nosc":"bougent","lemma":"bouger","pos":"VER"} ,
		{"word":"bougeons","word_nosc":"bougeons","lemma":"bouger","pos":"VER"} ,
		{"word":"bouger","word_nosc":"bouger","lemma":"bouger","pos":"VER"} ,
		{"word":"bougera","word_nosc":"bougera","lemma":"bouger","pos":"VER"} ,
		{"word":"bougerai","word_nosc":"bougerai","lemma":"bouger","pos":"VER"} ,
		{"word":"bougeraient","word_nosc":"bougeraient","lemma":"bouger","pos":"VER"} ,
		{"word":"bougerais","word_nosc":"bougerais","lemma":"bouger","pos":"VER"} ,
		{"word":"bougerait","word_nosc":"bougerait","lemma":"bouger","pos":"VER"} ,
		{"word":"bougeras","word_nosc":"bougeras","lemma":"bouger","pos":"VER"} ,
		{"word":"bougerez","word_nosc":"bougerez","lemma":"bouger","pos":"VER"} ,
		{"word":"bougeriez","word_nosc":"bougeriez","lemma":"bouger","pos":"VER"} ,
		{"word":"bougerons","word_nosc":"bougerons","lemma":"bouger","pos":"VER"} ,
		{"word":"bougeront","word_nosc":"bougeront","lemma":"bouger","pos":"VER"} ,
		{"word":"bouges","word_nosc":"bouges","lemma":"bouger","pos":"VER"} ,
		{"word":"bougez","word_nosc":"bougez","lemma":"bouger","pos":"VER"} ,
		{"word":"bougeât","word_nosc":"bougeat","lemma":"bouger","pos":"VER"} ,
		{"word":"bougiez","word_nosc":"bougiez","lemma":"bougier","pos":"VER"} ,
		{"word":"bougions","word_nosc":"bougions","lemma":"bouger","pos":"VER"} ,
		{"word":"bougne","word_nosc":"bougne","lemma":"bougner","pos":"VER"} ,
		{"word":"bougnes","word_nosc":"bougnes","lemma":"bougner","pos":"VER"} ,
		{"word":"bougonna","word_nosc":"bougonna","lemma":"bougonner","pos":"VER"} ,
		{"word":"bougonnaient","word_nosc":"bougonnaient","lemma":"bougonner","pos":"VER"} ,
		{"word":"bougonnais","word_nosc":"bougonnais","lemma":"bougonner","pos":"VER"} ,
		{"word":"bougonnait","word_nosc":"bougonnait","lemma":"bougonner","pos":"VER"} ,
		{"word":"bougonnant","word_nosc":"bougonnant","lemma":"bougonner","pos":"VER"} ,
		{"word":"bougonne","word_nosc":"bougonne","lemma":"bougonner","pos":"VER"} ,
		{"word":"bougonnent","word_nosc":"bougonnent","lemma":"bougonner","pos":"VER"} ,
		{"word":"bougonner","word_nosc":"bougonner","lemma":"bougonner","pos":"VER"} ,
		{"word":"bougonné","word_nosc":"bougonne","lemma":"bougonner","pos":"VER"} ,
		{"word":"bougèrent","word_nosc":"bougerent","lemma":"bouger","pos":"VER"} ,
		{"word":"bougé","word_nosc":"bouge","lemma":"bouger","pos":"VER"} ,
		{"word":"bougée","word_nosc":"bougee","lemma":"bouger","pos":"VER"} ,
		{"word":"bougées","word_nosc":"bougees","lemma":"bouger","pos":"VER"} ,
		{"word":"bouillaient","word_nosc":"bouillaient","lemma":"bouillir","pos":"VER"} ,
		{"word":"bouillais","word_nosc":"bouillais","lemma":"bouillir","pos":"VER"} ,
		{"word":"bouillait","word_nosc":"bouillait","lemma":"bouillir","pos":"VER"} ,
		{"word":"bouillant","word_nosc":"bouillant","lemma":"bouiller","pos":"VER"} ,
		{"word":"bouille","word_nosc":"bouille","lemma":"bouillir","pos":"VER"} ,
		{"word":"bouillent","word_nosc":"bouillent","lemma":"bouillir","pos":"VER"} ,
		{"word":"bouilli","word_nosc":"bouilli","lemma":"bouillir","pos":"VER"} ,
		{"word":"bouillie","word_nosc":"bouillie","lemma":"bouillir","pos":"VER"} ,
		{"word":"bouillir","word_nosc":"bouillir","lemma":"bouillir","pos":"VER"} ,
		{"word":"bouillira","word_nosc":"bouillira","lemma":"bouillir","pos":"VER"} ,
		{"word":"bouillirons","word_nosc":"bouillirons","lemma":"bouillir","pos":"VER"} ,
		{"word":"bouillis","word_nosc":"bouillis","lemma":"bouillir","pos":"VER"} ,
		{"word":"bouillonnaient","word_nosc":"bouillonnaient","lemma":"bouillonner","pos":"VER"} ,
		{"word":"bouillonnais","word_nosc":"bouillonnais","lemma":"bouillonner","pos":"VER"} ,
		{"word":"bouillonnait","word_nosc":"bouillonnait","lemma":"bouillonner","pos":"VER"} ,
		{"word":"bouillonnant","word_nosc":"bouillonnant","lemma":"bouillonner","pos":"VER"} ,
		{"word":"bouillonne","word_nosc":"bouillonne","lemma":"bouillonner","pos":"VER"} ,
		{"word":"bouillonnent","word_nosc":"bouillonnent","lemma":"bouillonner","pos":"VER"} ,
		{"word":"bouillonner","word_nosc":"bouillonner","lemma":"bouillonner","pos":"VER"} ,
		{"word":"bouillonnèrent","word_nosc":"bouillonnerent","lemma":"bouillonner","pos":"VER"} ,
		{"word":"bouillonné","word_nosc":"bouillonne","lemma":"bouillonner","pos":"VER"} ,
		{"word":"bouillonnée","word_nosc":"bouillonnee","lemma":"bouillonner","pos":"VER"} ,
		{"word":"bouillonnées","word_nosc":"bouillonnees","lemma":"bouillonner","pos":"VER"} ,
		{"word":"bouillonnés","word_nosc":"bouillonnes","lemma":"bouillonner","pos":"VER"} ,
		{"word":"bouillons","word_nosc":"bouillons","lemma":"bouillir","pos":"VER"} ,
		{"word":"bouillottait","word_nosc":"bouillottait","lemma":"bouillotter","pos":"VER"} ,
		{"word":"boula","word_nosc":"boula","lemma":"bouler","pos":"VER"} ,
		{"word":"boulait","word_nosc":"boulait","lemma":"bouler","pos":"VER"} ,
		{"word":"boulanger","word_nosc":"boulanger","lemma":"boulanger","pos":"VER"} ,
		{"word":"boule","word_nosc":"boule","lemma":"bouler","pos":"VER"} ,
		{"word":"boulent","word_nosc":"boulent","lemma":"bouler","pos":"VER"} ,
		{"word":"bouler","word_nosc":"bouler","lemma":"bouler","pos":"VER"} ,
		{"word":"boules","word_nosc":"boules","lemma":"bouler","pos":"VER"} ,
		{"word":"bouleversa","word_nosc":"bouleversa","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleversaient","word_nosc":"bouleversaient","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleversait","word_nosc":"bouleversait","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleversant","word_nosc":"bouleversant","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleverse","word_nosc":"bouleverse","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleversent","word_nosc":"bouleversent","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleverser","word_nosc":"bouleverser","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleversera","word_nosc":"bouleversera","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleverseraient","word_nosc":"bouleverseraient","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleverserais","word_nosc":"bouleverserais","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleverserait","word_nosc":"bouleverserait","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleverseras","word_nosc":"bouleverseras","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleverserons","word_nosc":"bouleverserons","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleverseront","word_nosc":"bouleverseront","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleversions","word_nosc":"bouleversions","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleversons","word_nosc":"bouleversons","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleversèrent","word_nosc":"bouleverserent","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleversé","word_nosc":"bouleverse","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleversée","word_nosc":"bouleversee","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleversées","word_nosc":"bouleversees","lemma":"bouleverser","pos":"VER"} ,
		{"word":"bouleversés","word_nosc":"bouleverses","lemma":"bouleverser","pos":"VER"} ,
		{"word":"boulochait","word_nosc":"boulochait","lemma":"boulocher","pos":"VER"} ,
		{"word":"boulonnait","word_nosc":"boulonnait","lemma":"boulonner","pos":"VER"} ,
		{"word":"boulonnant","word_nosc":"boulonnant","lemma":"boulonner","pos":"VER"} ,
		{"word":"boulonne","word_nosc":"boulonne","lemma":"boulonner","pos":"VER"} ,
		{"word":"boulonner","word_nosc":"boulonner","lemma":"boulonner","pos":"VER"} ,
		{"word":"boulonné","word_nosc":"boulonne","lemma":"boulonner","pos":"VER"} ,
		{"word":"boulonnée","word_nosc":"boulonnee","lemma":"boulonner","pos":"VER"} ,
		{"word":"boulonnés","word_nosc":"boulonnes","lemma":"boulonner","pos":"VER"} ,
		{"word":"boulottait","word_nosc":"boulottait","lemma":"boulotter","pos":"VER"} ,
		{"word":"boulotte","word_nosc":"boulotte","lemma":"boulotter","pos":"VER"} ,
		{"word":"boulotter","word_nosc":"boulotter","lemma":"boulotter","pos":"VER"} ,
		{"word":"boulottes","word_nosc":"boulottes","lemma":"boulotter","pos":"VER"} ,
		{"word":"boulottées","word_nosc":"boulottees","lemma":"boulotter","pos":"VER"} ,
		{"word":"boulé","word_nosc":"boule","lemma":"bouler","pos":"VER"} ,
		{"word":"boulés","word_nosc":"boules","lemma":"bouler","pos":"VER"} ,
		{"word":"boumaient","word_nosc":"boumaient","lemma":"boumer","pos":"VER"} ,
		{"word":"boumait","word_nosc":"boumait","lemma":"boumer","pos":"VER"} ,
		{"word":"boume","word_nosc":"boume","lemma":"boumer","pos":"VER"} ,
		{"word":"boumé","word_nosc":"boume","lemma":"boumer","pos":"VER"} ,
		{"word":"bouquinais","word_nosc":"bouquinais","lemma":"bouquiner","pos":"VER"} ,
		{"word":"bouquinait","word_nosc":"bouquinait","lemma":"bouquiner","pos":"VER"} ,
		{"word":"bouquinant","word_nosc":"bouquinant","lemma":"bouquiner","pos":"VER"} ,
		{"word":"bouquine","word_nosc":"bouquine","lemma":"bouquiner","pos":"VER"} ,
		{"word":"bouquiner","word_nosc":"bouquiner","lemma":"bouquiner","pos":"VER"} ,
		{"word":"bouquinerait","word_nosc":"bouquinerait","lemma":"bouquiner","pos":"VER"} ,
		{"word":"bouquiné","word_nosc":"bouquine","lemma":"bouquiner","pos":"VER"} ,
		{"word":"bourdonna","word_nosc":"bourdonna","lemma":"bourdonner","pos":"VER"} ,
		{"word":"bourdonnaient","word_nosc":"bourdonnaient","lemma":"bourdonner","pos":"VER"} ,
		{"word":"bourdonnais","word_nosc":"bourdonnais","lemma":"bourdonner","pos":"VER"} ,
		{"word":"bourdonnait","word_nosc":"bourdonnait","lemma":"bourdonner","pos":"VER"} ,
		{"word":"bourdonnant","word_nosc":"bourdonnant","lemma":"bourdonner","pos":"VER"} ,
		{"word":"bourdonne","word_nosc":"bourdonne","lemma":"bourdonner","pos":"VER"} ,
		{"word":"bourdonnent","word_nosc":"bourdonnent","lemma":"bourdonner","pos":"VER"} ,
		{"word":"bourdonner","word_nosc":"bourdonner","lemma":"bourdonner","pos":"VER"} ,
		{"word":"bourdonné","word_nosc":"bourdonne","lemma":"bourdonner","pos":"VER"} ,
		{"word":"bourgeonnaient","word_nosc":"bourgeonnaient","lemma":"bourgeonner","pos":"VER"} ,
		{"word":"bourgeonnait","word_nosc":"bourgeonnait","lemma":"bourgeonner","pos":"VER"} ,
		{"word":"bourgeonnant","word_nosc":"bourgeonnant","lemma":"bourgeonner","pos":"VER"} ,
		{"word":"bourgeonne","word_nosc":"bourgeonne","lemma":"bourgeonner","pos":"VER"} ,
		{"word":"bourgeonnent","word_nosc":"bourgeonnent","lemma":"bourgeonner","pos":"VER"} ,
		{"word":"bourgeonner","word_nosc":"bourgeonner","lemma":"bourgeonner","pos":"VER"} ,
		{"word":"bourgeonneraient","word_nosc":"bourgeonneraient","lemma":"bourgeonner","pos":"VER"} ,
		{"word":"bourgeonné","word_nosc":"bourgeonne","lemma":"bourgeonner","pos":"VER"} ,
		{"word":"bourlinguant","word_nosc":"bourlinguant","lemma":"bourlinguer","pos":"VER"} ,
		{"word":"bourlinguer","word_nosc":"bourlinguer","lemma":"bourlinguer","pos":"VER"} ,
		{"word":"bourlinguera","word_nosc":"bourlinguera","lemma":"bourlinguer","pos":"VER"} ,
		{"word":"bourlinguons","word_nosc":"bourlinguons","lemma":"bourlinguer","pos":"VER"} ,
		{"word":"bourlingué","word_nosc":"bourlingue","lemma":"bourlinguer","pos":"VER"} ,
		{"word":"bourra","word_nosc":"bourra","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourrai","word_nosc":"bourrai","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourraient","word_nosc":"bourraient","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourrais","word_nosc":"bourrais","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourrait","word_nosc":"bourrait","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourrant","word_nosc":"bourrant","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourre","word_nosc":"bourre","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourrelé","word_nosc":"bourrele","lemma":"bourreler","pos":"VER"} ,
		{"word":"bourrelée","word_nosc":"bourrelee","lemma":"bourreler","pos":"VER"} ,
		{"word":"bourrelés","word_nosc":"bourreles","lemma":"bourreler","pos":"VER"} ,
		{"word":"bourrent","word_nosc":"bourrent","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourrer","word_nosc":"bourrer","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourrera","word_nosc":"bourrera","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourrerai","word_nosc":"bourrerai","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourres","word_nosc":"bourres","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourrez","word_nosc":"bourrez","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourriquait","word_nosc":"bourriquait","lemma":"bourriquer","pos":"VER"} ,
		{"word":"bourrons","word_nosc":"bourrons","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourrèrent","word_nosc":"bourrerent","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourré","word_nosc":"bourre","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourrée","word_nosc":"bourree","lemma":"bourrer","pos":"VER"} ,
		{"word":"bourrées","word_nosc":"bourrees","lemma":"bourrée","pos":"VER"} ,
		{"word":"bourrés","word_nosc":"bourres","lemma":"bourrer","pos":"VER"} ,
		{"word":"boursouflaient","word_nosc":"boursouflaient","lemma":"boursoufler","pos":"VER"} ,
		{"word":"boursouflait","word_nosc":"boursouflait","lemma":"boursoufler","pos":"VER"} ,
		{"word":"boursoufle","word_nosc":"boursoufle","lemma":"boursoufler","pos":"VER"} ,
		{"word":"boursoufler","word_nosc":"boursoufler","lemma":"boursoufler","pos":"VER"} ,
		{"word":"boursouflé","word_nosc":"boursoufle","lemma":"boursoufler","pos":"VER"} ,
		{"word":"boursouflée","word_nosc":"boursouflee","lemma":"boursoufler","pos":"VER"} ,
		{"word":"boursouflées","word_nosc":"boursouflees","lemma":"boursoufler","pos":"VER"} ,
		{"word":"boursouflés","word_nosc":"boursoufles","lemma":"boursoufler","pos":"VER"} ,
		{"word":"bous","word_nosc":"bous","lemma":"bouillir","pos":"VER"} ,
		{"word":"bouscula","word_nosc":"bouscula","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculai","word_nosc":"bousculai","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculaient","word_nosc":"bousculaient","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculait","word_nosc":"bousculait","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculant","word_nosc":"bousculant","lemma":"bousculer","pos":"VER"} ,
		{"word":"bouscule","word_nosc":"bouscule","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculent","word_nosc":"bousculent","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculer","word_nosc":"bousculer","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculera","word_nosc":"bousculera","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculerait","word_nosc":"bousculerait","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculerons","word_nosc":"bousculerons","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculeront","word_nosc":"bousculeront","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculez","word_nosc":"bousculez","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculons","word_nosc":"bousculons","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculât","word_nosc":"bousculat","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculèrent","word_nosc":"bousculerent","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculé","word_nosc":"bouscule","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculée","word_nosc":"bousculee","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculées","word_nosc":"bousculees","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousculés","word_nosc":"bouscules","lemma":"bousculer","pos":"VER"} ,
		{"word":"bousillaient","word_nosc":"bousillaient","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousillais","word_nosc":"bousillais","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousillait","word_nosc":"bousillait","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousillant","word_nosc":"bousillant","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousille","word_nosc":"bousille","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousillent","word_nosc":"bousillent","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousiller","word_nosc":"bousiller","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousillera","word_nosc":"bousillera","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousillerai","word_nosc":"bousillerai","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousillerait","word_nosc":"bousillerait","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousilleras","word_nosc":"bousilleras","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousilleront","word_nosc":"bousilleront","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousilles","word_nosc":"bousilles","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousillez","word_nosc":"bousillez","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousilliez","word_nosc":"bousilliez","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousillons","word_nosc":"bousillons","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousillé","word_nosc":"bousille","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousillée","word_nosc":"bousillee","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousillées","word_nosc":"bousillees","lemma":"bousiller","pos":"VER"} ,
		{"word":"bousillés","word_nosc":"bousilles","lemma":"bousiller","pos":"VER"} ,
		{"word":"bout","word_nosc":"bout","lemma":"bouillir","pos":"VER"} ,
		{"word":"bouta","word_nosc":"bouta","lemma":"bouter","pos":"VER"} ,
		{"word":"boutasse","word_nosc":"boutasse","lemma":"bouter","pos":"VER"} ,
		{"word":"boute","word_nosc":"boute","lemma":"bouter","pos":"VER"} ,
		{"word":"bouter","word_nosc":"bouter","lemma":"bouter","pos":"VER"} ,
		{"word":"bouterais","word_nosc":"bouterais","lemma":"bouter","pos":"VER"} ,
		{"word":"boutiquer","word_nosc":"boutiquer","lemma":"boutiquer","pos":"VER"} ,
		{"word":"boutiqué","word_nosc":"boutique","lemma":"boutiquer","pos":"VER"} ,
		{"word":"boutonna","word_nosc":"boutonna","lemma":"boutonner","pos":"VER"} ,
		{"word":"boutonnaient","word_nosc":"boutonnaient","lemma":"boutonner","pos":"VER"} ,
		{"word":"boutonnais","word_nosc":"boutonnais","lemma":"boutonner","pos":"VER"} ,
		{"word":"boutonnait","word_nosc":"boutonnait","lemma":"boutonner","pos":"VER"} ,
		{"word":"boutonnant","word_nosc":"boutonnant","lemma":"boutonner","pos":"VER"} ,
		{"word":"boutonne","word_nosc":"boutonne","lemma":"boutonner","pos":"VER"} ,
		{"word":"boutonnent","word_nosc":"boutonnent","lemma":"boutonner","pos":"VER"} ,
		{"word":"boutonner","word_nosc":"boutonner","lemma":"boutonner","pos":"VER"} ,
		{"word":"boutonnerait","word_nosc":"boutonnerait","lemma":"boutonner","pos":"VER"} ,
		{"word":"boutonnes","word_nosc":"boutonnes","lemma":"boutonner","pos":"VER"} ,
		{"word":"boutonnez","word_nosc":"boutonnez","lemma":"boutonner","pos":"VER"} ,
		{"word":"boutonné","word_nosc":"boutonne","lemma":"boutonner","pos":"VER"} ,
		{"word":"boutonnée","word_nosc":"boutonnee","lemma":"boutonner","pos":"VER"} ,
		{"word":"boutonnées","word_nosc":"boutonnees","lemma":"boutonner","pos":"VER"} ,
		{"word":"boutonnés","word_nosc":"boutonnes","lemma":"boutonner","pos":"VER"} ,
		{"word":"boutons","word_nosc":"boutons","lemma":"bouter","pos":"VER"} ,
		{"word":"bouté","word_nosc":"boute","lemma":"bouter","pos":"VER"} ,
		{"word":"boutée","word_nosc":"boutee","lemma":"bouter","pos":"VER"} ,
		{"word":"boutées","word_nosc":"boutees","lemma":"bouter","pos":"VER"} ,
		{"word":"boutés","word_nosc":"boutes","lemma":"bouter","pos":"VER"} ,
		{"word":"boxa","word_nosc":"boxa","lemma":"boxer","pos":"VER"} ,
		{"word":"boxaient","word_nosc":"boxaient","lemma":"boxer","pos":"VER"} ,
		{"word":"boxais","word_nosc":"boxais","lemma":"boxer","pos":"VER"} ,
		{"word":"boxait","word_nosc":"boxait","lemma":"boxer","pos":"VER"} ,
		{"word":"boxant","word_nosc":"boxant","lemma":"boxer","pos":"VER"} ,
		{"word":"boxe","word_nosc":"boxe","lemma":"boxer","pos":"VER"} ,
		{"word":"boxent","word_nosc":"boxent","lemma":"boxer","pos":"VER"} ,
		{"word":"boxer","word_nosc":"boxer","lemma":"boxer","pos":"VER"} ,
		{"word":"boxerai","word_nosc":"boxerai","lemma":"boxer","pos":"VER"} ,
		{"word":"boxerais","word_nosc":"boxerais","lemma":"boxer","pos":"VER"} ,
		{"word":"boxerez","word_nosc":"boxerez","lemma":"boxer","pos":"VER"} ,
		{"word":"boxes","word_nosc":"boxes","lemma":"boxer","pos":"VER"} ,
		{"word":"boxez","word_nosc":"boxez","lemma":"boxer","pos":"VER"} ,
		{"word":"boxons","word_nosc":"boxons","lemma":"boxer","pos":"VER"} ,
		{"word":"boxé","word_nosc":"boxe","lemma":"boxer","pos":"VER"} ,
		{"word":"boxés","word_nosc":"boxes","lemma":"boxer","pos":"VER"} ,
		{"word":"boyauter","word_nosc":"boyauter","lemma":"boyauter","pos":"VER"} ,
		{"word":"boycotte","word_nosc":"boycotte","lemma":"boycotter","pos":"VER"} ,
		{"word":"boycottent","word_nosc":"boycottent","lemma":"boycotter","pos":"VER"} ,
		{"word":"boycotter","word_nosc":"boycotter","lemma":"boycotter","pos":"VER"} ,
		{"word":"boycotterai","word_nosc":"boycotterai","lemma":"boycotter","pos":"VER"} ,
		{"word":"boycotteront","word_nosc":"boycotteront","lemma":"boycotter","pos":"VER"} ,
		{"word":"boycottes","word_nosc":"boycottes","lemma":"boycotter","pos":"VER"} ,
		{"word":"boycottez","word_nosc":"boycottez","lemma":"boycotter","pos":"VER"} ,
		{"word":"boycotté","word_nosc":"boycotte","lemma":"boycotter","pos":"VER"} ,
		{"word":"braconnais","word_nosc":"braconnais","lemma":"braconner","pos":"VER"} ,
		{"word":"braconnait","word_nosc":"braconnait","lemma":"braconner","pos":"VER"} ,
		{"word":"braconnant","word_nosc":"braconnant","lemma":"braconner","pos":"VER"} ,
		{"word":"braconne","word_nosc":"braconne","lemma":"braconner","pos":"VER"} ,
		{"word":"braconner","word_nosc":"braconner","lemma":"braconner","pos":"VER"} ,
		{"word":"braconnez","word_nosc":"braconnez","lemma":"braconner","pos":"VER"} ,
		{"word":"braconné","word_nosc":"braconne","lemma":"braconner","pos":"VER"} ,
		{"word":"bradaient","word_nosc":"bradaient","lemma":"brader","pos":"VER"} ,
		{"word":"bradait","word_nosc":"bradait","lemma":"brader","pos":"VER"} ,
		{"word":"brade","word_nosc":"brade","lemma":"brader","pos":"VER"} ,
		{"word":"bradent","word_nosc":"bradent","lemma":"brader","pos":"VER"} ,
		{"word":"brader","word_nosc":"brader","lemma":"brader","pos":"VER"} ,
		{"word":"braderai","word_nosc":"braderai","lemma":"brader","pos":"VER"} ,
		{"word":"braderais","word_nosc":"braderais","lemma":"brader","pos":"VER"} ,
		{"word":"braderait","word_nosc":"braderait","lemma":"brader","pos":"VER"} ,
		{"word":"bradons","word_nosc":"bradons","lemma":"brader","pos":"VER"} ,
		{"word":"bradé","word_nosc":"brade","lemma":"brader","pos":"VER"} ,
		{"word":"bradée","word_nosc":"bradee","lemma":"brader","pos":"VER"} ,
		{"word":"braie","word_nosc":"braie","lemma":"brayer","pos":"VER"} ,
		{"word":"brailla","word_nosc":"brailla","lemma":"brailler","pos":"VER"} ,
		{"word":"braillaient","word_nosc":"braillaient","lemma":"brailler","pos":"VER"} ,
		{"word":"braillait","word_nosc":"braillait","lemma":"brailler","pos":"VER"} ,
		{"word":"braillant","word_nosc":"braillant","lemma":"brailler","pos":"VER"} ,
		{"word":"braille","word_nosc":"braille","lemma":"brailler","pos":"VER"} ,
		{"word":"braillent","word_nosc":"braillent","lemma":"brailler","pos":"VER"} ,
		{"word":"brailler","word_nosc":"brailler","lemma":"brailler","pos":"VER"} ,
		{"word":"braillera","word_nosc":"braillera","lemma":"brailler","pos":"VER"} ,
		{"word":"braillerai","word_nosc":"braillerai","lemma":"brailler","pos":"VER"} ,
		{"word":"brailles","word_nosc":"brailles","lemma":"brailler","pos":"VER"} ,
		{"word":"braillez","word_nosc":"braillez","lemma":"brailler","pos":"VER"} ,
		{"word":"braillèrent","word_nosc":"braillerent","lemma":"brailler","pos":"VER"} ,
		{"word":"braillé","word_nosc":"braille","lemma":"brailler","pos":"VER"} ,
		{"word":"braillée","word_nosc":"braillee","lemma":"brailler","pos":"VER"} ,
		{"word":"braillés","word_nosc":"brailles","lemma":"brailler","pos":"VER"} ,
		{"word":"braire","word_nosc":"braire","lemma":"braire","pos":"VER"} ,
		{"word":"braise","word_nosc":"braise","lemma":"braiser","pos":"VER"} ,
		{"word":"braiser","word_nosc":"braiser","lemma":"braiser","pos":"VER"} ,
		{"word":"braises","word_nosc":"braises","lemma":"braiser","pos":"VER"} ,
		{"word":"braisé","word_nosc":"braise","lemma":"braiser","pos":"VER"} ,
		{"word":"braisée","word_nosc":"braisee","lemma":"braiser","pos":"VER"} ,
		{"word":"brait","word_nosc":"brait","lemma":"braire","pos":"VER"} ,
		{"word":"brama","word_nosc":"brama","lemma":"bramer","pos":"VER"} ,
		{"word":"bramaient","word_nosc":"bramaient","lemma":"bramer","pos":"VER"} ,
		{"word":"bramait","word_nosc":"bramait","lemma":"bramer","pos":"VER"} ,
		{"word":"bramant","word_nosc":"bramant","lemma":"bramer","pos":"VER"} ,
		{"word":"brame","word_nosc":"brame","lemma":"bramer","pos":"VER"} ,
		{"word":"brament","word_nosc":"brament","lemma":"bramer","pos":"VER"} ,
		{"word":"bramer","word_nosc":"bramer","lemma":"bramer","pos":"VER"} ,
		{"word":"brames","word_nosc":"brames","lemma":"bramer","pos":"VER"} ,
		{"word":"bramé","word_nosc":"brame","lemma":"bramer","pos":"VER"} ,
		{"word":"bramées","word_nosc":"bramees","lemma":"bramer","pos":"VER"} ,
		{"word":"brancarder","word_nosc":"brancarder","lemma":"brancarder","pos":"VER"} ,
		{"word":"brancha","word_nosc":"brancha","lemma":"brancher","pos":"VER"} ,
		{"word":"branchai","word_nosc":"branchai","lemma":"brancher","pos":"VER"} ,
		{"word":"branchaient","word_nosc":"branchaient","lemma":"brancher","pos":"VER"} ,
		{"word":"branchais","word_nosc":"branchais","lemma":"brancher","pos":"VER"} ,
		{"word":"branchait","word_nosc":"branchait","lemma":"brancher","pos":"VER"} ,
		{"word":"branchant","word_nosc":"branchant","lemma":"brancher","pos":"VER"} ,
		{"word":"branche","word_nosc":"branche","lemma":"brancher","pos":"VER"} ,
		{"word":"branchent","word_nosc":"branchent","lemma":"brancher","pos":"VER"} ,
		{"word":"brancher","word_nosc":"brancher","lemma":"brancher","pos":"VER"} ,
		{"word":"branchera","word_nosc":"branchera","lemma":"brancher","pos":"VER"} ,
		{"word":"brancheraient","word_nosc":"brancheraient","lemma":"brancher","pos":"VER"} ,
		{"word":"brancherait","word_nosc":"brancherait","lemma":"brancher","pos":"VER"} ,
		{"word":"brancheras","word_nosc":"brancheras","lemma":"brancher","pos":"VER"} ,
		{"word":"brancherez","word_nosc":"brancherez","lemma":"brancher","pos":"VER"} ,
		{"word":"branches","word_nosc":"branches","lemma":"brancher","pos":"VER"} ,
		{"word":"branchez","word_nosc":"branchez","lemma":"brancher","pos":"VER"} ,
		{"word":"branchons","word_nosc":"branchons","lemma":"brancher","pos":"VER"} ,
		{"word":"branché","word_nosc":"branche","lemma":"brancher","pos":"VER"} ,
		{"word":"branchée","word_nosc":"branchee","lemma":"brancher","pos":"VER"} ,
		{"word":"branchées","word_nosc":"branchees","lemma":"brancher","pos":"VER"} ,
		{"word":"branchés","word_nosc":"branches","lemma":"brancher","pos":"VER"} ,
		{"word":"brandi","word_nosc":"brandi","lemma":"brandir","pos":"VER"} ,
		{"word":"brandie","word_nosc":"brandie","lemma":"brandir","pos":"VER"} ,
		{"word":"brandies","word_nosc":"brandies","lemma":"brandir","pos":"VER"} ,
		{"word":"brandir","word_nosc":"brandir","lemma":"brandir","pos":"VER"} ,
		{"word":"brandira","word_nosc":"brandira","lemma":"brandir","pos":"VER"} ,
		{"word":"brandirait","word_nosc":"brandirait","lemma":"brandir","pos":"VER"} ,
		{"word":"brandirent","word_nosc":"brandirent","lemma":"brandir","pos":"VER"} ,
		{"word":"brandiront","word_nosc":"brandiront","lemma":"brandir","pos":"VER"} ,
		{"word":"brandis","word_nosc":"brandis","lemma":"brandir","pos":"VER"} ,
		{"word":"brandissaient","word_nosc":"brandissaient","lemma":"brandir","pos":"VER"} ,
		{"word":"brandissais","word_nosc":"brandissais","lemma":"brandir","pos":"VER"} ,
		{"word":"brandissait","word_nosc":"brandissait","lemma":"brandir","pos":"VER"} ,
		{"word":"brandissant","word_nosc":"brandissant","lemma":"brandir","pos":"VER"} ,
		{"word":"brandisse","word_nosc":"brandisse","lemma":"brandir","pos":"VER"} ,
		{"word":"brandissent","word_nosc":"brandissent","lemma":"brandir","pos":"VER"} ,
		{"word":"brandissez","word_nosc":"brandissez","lemma":"brandir","pos":"VER"} ,
		{"word":"brandissiez","word_nosc":"brandissiez","lemma":"brandir","pos":"VER"} ,
		{"word":"brandissions","word_nosc":"brandissions","lemma":"brandir","pos":"VER"} ,
		{"word":"brandissons","word_nosc":"brandissons","lemma":"brandir","pos":"VER"} ,
		{"word":"brandit","word_nosc":"brandit","lemma":"brandir","pos":"VER"} ,
		{"word":"brandouille","word_nosc":"brandouille","lemma":"brandouiller","pos":"VER"} ,
		{"word":"brandît","word_nosc":"brandit","lemma":"brandir","pos":"VER"} ,
		{"word":"branla","word_nosc":"branla","lemma":"branler","pos":"VER"} ,
		{"word":"branlaient","word_nosc":"branlaient","lemma":"branler","pos":"VER"} ,
		{"word":"branlais","word_nosc":"branlais","lemma":"branler","pos":"VER"} ,
		{"word":"branlait","word_nosc":"branlait","lemma":"branler","pos":"VER"} ,
		{"word":"branlant","word_nosc":"branlant","lemma":"branler","pos":"VER"} ,
		{"word":"branle","word_nosc":"branle","lemma":"branler","pos":"VER"} ,
		{"word":"branlent","word_nosc":"branlent","lemma":"branler","pos":"VER"} ,
		{"word":"branler","word_nosc":"branler","lemma":"branler","pos":"VER"} ,
		{"word":"branlera","word_nosc":"branlera","lemma":"branler","pos":"VER"} ,
		{"word":"branlerais","word_nosc":"branlerais","lemma":"branler","pos":"VER"} ,
		{"word":"branlerait","word_nosc":"branlerait","lemma":"branler","pos":"VER"} ,
		{"word":"branles","word_nosc":"branles","lemma":"branler","pos":"VER"} ,
		{"word":"branlez","word_nosc":"branlez","lemma":"branler","pos":"VER"} ,
		{"word":"branlochais","word_nosc":"branlochais","lemma":"branlocher","pos":"VER"} ,
		{"word":"branlochant","word_nosc":"branlochant","lemma":"branlocher","pos":"VER"} ,
		{"word":"branlochent","word_nosc":"branlochent","lemma":"branlocher","pos":"VER"} ,
		{"word":"branlé","word_nosc":"branle","lemma":"branler","pos":"VER"} ,
		{"word":"branlée","word_nosc":"branlee","lemma":"branler","pos":"VER"} ,
		{"word":"branlés","word_nosc":"branles","lemma":"branler","pos":"VER"} ,
		{"word":"braqua","word_nosc":"braqua","lemma":"braquer","pos":"VER"} ,
		{"word":"braquai","word_nosc":"braquai","lemma":"braquer","pos":"VER"} ,
		{"word":"braquaient","word_nosc":"braquaient","lemma":"braquer","pos":"VER"} ,
		{"word":"braquais","word_nosc":"braquais","lemma":"braquer","pos":"VER"} ,
		{"word":"braquait","word_nosc":"braquait","lemma":"braquer","pos":"VER"} ,
		{"word":"braquant","word_nosc":"braquant","lemma":"braquer","pos":"VER"} ,
		{"word":"braque","word_nosc":"braque","lemma":"braquer","pos":"VER"} ,
		{"word":"braquent","word_nosc":"braquent","lemma":"braquer","pos":"VER"} ,
		{"word":"braquer","word_nosc":"braquer","lemma":"braquer","pos":"VER"} ,
		{"word":"braquera","word_nosc":"braquera","lemma":"braquer","pos":"VER"} ,
		{"word":"braquerais","word_nosc":"braquerais","lemma":"braquer","pos":"VER"} ,
		{"word":"braquerait","word_nosc":"braquerait","lemma":"braquer","pos":"VER"} ,
		{"word":"braques","word_nosc":"braques","lemma":"braquer","pos":"VER"} ,
		{"word":"braquez","word_nosc":"braquez","lemma":"braquer","pos":"VER"} ,
		{"word":"braquèrent","word_nosc":"braquerent","lemma":"braquer","pos":"VER"} ,
		{"word":"braqué","word_nosc":"braque","lemma":"braquer","pos":"VER"} ,
		{"word":"braquée","word_nosc":"braquee","lemma":"braquer","pos":"VER"} ,
		{"word":"braquées","word_nosc":"braquees","lemma":"braquer","pos":"VER"} ,
		{"word":"braqués","word_nosc":"braques","lemma":"braquer","pos":"VER"} ,
		{"word":"braser","word_nosc":"braser","lemma":"braser","pos":"VER"} ,
		{"word":"brasillaient","word_nosc":"brasillaient","lemma":"brasiller","pos":"VER"} ,
		{"word":"brasillait","word_nosc":"brasillait","lemma":"brasiller","pos":"VER"} ,
		{"word":"brasillant","word_nosc":"brasillant","lemma":"brasiller","pos":"VER"} ,
		{"word":"brasille","word_nosc":"brasille","lemma":"brasiller","pos":"VER"} ,
		{"word":"brasiller","word_nosc":"brasiller","lemma":"brasiller","pos":"VER"} ,
		{"word":"brassai","word_nosc":"brassai","lemma":"brasser","pos":"VER"} ,
		{"word":"brassaient","word_nosc":"brassaient","lemma":"brasser","pos":"VER"} ,
		{"word":"brassait","word_nosc":"brassait","lemma":"brasser","pos":"VER"} ,
		{"word":"brassant","word_nosc":"brassant","lemma":"brasser","pos":"VER"} ,
		{"word":"brasse","word_nosc":"brasse","lemma":"brasser","pos":"VER"} ,
		{"word":"brassent","word_nosc":"brassent","lemma":"brasser","pos":"VER"} ,
		{"word":"brasser","word_nosc":"brasser","lemma":"brasser","pos":"VER"} ,
		{"word":"brasseront","word_nosc":"brasseront","lemma":"brasser","pos":"VER"} ,
		{"word":"brasses","word_nosc":"brasses","lemma":"brasser","pos":"VER"} ,
		{"word":"brassez","word_nosc":"brassez","lemma":"brasser","pos":"VER"} ,
		{"word":"brassions","word_nosc":"brassions","lemma":"brasser","pos":"VER"} ,
		{"word":"brassé","word_nosc":"brasse","lemma":"brasser","pos":"VER"} ,
		{"word":"brassée","word_nosc":"brassee","lemma":"brasser","pos":"VER"} ,
		{"word":"brassées","word_nosc":"brassees","lemma":"brasser","pos":"VER"} ,
		{"word":"brassés","word_nosc":"brasses","lemma":"brasser","pos":"VER"} ,
		{"word":"brava","word_nosc":"brava","lemma":"braver","pos":"VER"} ,
		{"word":"bravaient","word_nosc":"bravaient","lemma":"braver","pos":"VER"} ,
		{"word":"bravais","word_nosc":"bravais","lemma":"braver","pos":"VER"} ,
		{"word":"bravait","word_nosc":"bravait","lemma":"braver","pos":"VER"} ,
		{"word":"bravant","word_nosc":"bravant","lemma":"braver","pos":"VER"} ,
		{"word":"brave","word_nosc":"brave","lemma":"braver","pos":"VER"} ,
		{"word":"bravent","word_nosc":"bravent","lemma":"braver","pos":"VER"} ,
		{"word":"braver","word_nosc":"braver","lemma":"braver","pos":"VER"} ,
		{"word":"braverai","word_nosc":"braverai","lemma":"braver","pos":"VER"} ,
		{"word":"braveraient","word_nosc":"braveraient","lemma":"braver","pos":"VER"} ,
		{"word":"braverait","word_nosc":"braverait","lemma":"braver","pos":"VER"} ,
		{"word":"braveriez","word_nosc":"braveriez","lemma":"braver","pos":"VER"} ,
		{"word":"braves","word_nosc":"braves","lemma":"braver","pos":"VER"} ,
		{"word":"bravez","word_nosc":"bravez","lemma":"braver","pos":"VER"} ,
		{"word":"bravons","word_nosc":"bravons","lemma":"braver","pos":"VER"} ,
		{"word":"bravèrent","word_nosc":"braverent","lemma":"braver","pos":"VER"} ,
		{"word":"bravé","word_nosc":"brave","lemma":"braver","pos":"VER"} ,
		{"word":"bravée","word_nosc":"bravee","lemma":"braver","pos":"VER"} ,
		{"word":"brayaient","word_nosc":"brayaient","lemma":"brayer","pos":"VER"} ,
		{"word":"brayait","word_nosc":"brayait","lemma":"brayer","pos":"VER"} ,
		{"word":"brayes","word_nosc":"brayes","lemma":"brayer","pos":"VER"} ,
		{"word":"bredouilla","word_nosc":"bredouilla","lemma":"bredouiller","pos":"VER"} ,
		{"word":"bredouillai","word_nosc":"bredouillai","lemma":"bredouiller","pos":"VER"} ,
		{"word":"bredouillaient","word_nosc":"bredouillaient","lemma":"bredouiller","pos":"VER"} ,
		{"word":"bredouillais","word_nosc":"bredouillais","lemma":"bredouiller","pos":"VER"} ,
		{"word":"bredouillait","word_nosc":"bredouillait","lemma":"bredouiller","pos":"VER"} ,
		{"word":"bredouillant","word_nosc":"bredouillant","lemma":"bredouiller","pos":"VER"} ,
		{"word":"bredouille","word_nosc":"bredouille","lemma":"bredouiller","pos":"VER"} ,
		{"word":"bredouillent","word_nosc":"bredouillent","lemma":"bredouiller","pos":"VER"} ,
		{"word":"bredouiller","word_nosc":"bredouiller","lemma":"bredouiller","pos":"VER"} ,
		{"word":"bredouilles","word_nosc":"bredouilles","lemma":"bredouiller","pos":"VER"} ,
		{"word":"bredouillez","word_nosc":"bredouillez","lemma":"bredouiller","pos":"VER"} ,
		{"word":"bredouillé","word_nosc":"bredouille","lemma":"bredouiller","pos":"VER"} ,
		{"word":"bredouillées","word_nosc":"bredouillees","lemma":"bredouiller","pos":"VER"} ,
		{"word":"breveter","word_nosc":"breveter","lemma":"breveter","pos":"VER"} ,
		{"word":"breveté","word_nosc":"brevete","lemma":"breveter","pos":"VER"} ,
		{"word":"brevetée","word_nosc":"brevetee","lemma":"breveter","pos":"VER"} ,
		{"word":"brevetés","word_nosc":"brevetes","lemma":"breveter","pos":"VER"} ,
		{"word":"bricola","word_nosc":"bricola","lemma":"bricoler","pos":"VER"} ,
		{"word":"bricolaient","word_nosc":"bricolaient","lemma":"bricoler","pos":"VER"} ,
		{"word":"bricolais","word_nosc":"bricolais","lemma":"bricoler","pos":"VER"} ,
		{"word":"bricolait","word_nosc":"bricolait","lemma":"bricoler","pos":"VER"} ,
		{"word":"bricolant","word_nosc":"bricolant","lemma":"bricoler","pos":"VER"} ,
		{"word":"bricole","word_nosc":"bricole","lemma":"bricoler","pos":"VER"} ,
		{"word":"bricolent","word_nosc":"bricolent","lemma":"bricoler","pos":"VER"} ,
		{"word":"bricoler","word_nosc":"bricoler","lemma":"bricoler","pos":"VER"} ,
		{"word":"bricoles","word_nosc":"bricoles","lemma":"bricoler","pos":"VER"} ,
		{"word":"bricolez","word_nosc":"bricolez","lemma":"bricoler","pos":"VER"} ,
		{"word":"bricolons","word_nosc":"bricolons","lemma":"bricoler","pos":"VER"} ,
		{"word":"bricolé","word_nosc":"bricole","lemma":"bricoler","pos":"VER"} ,
		{"word":"bricolée","word_nosc":"bricolee","lemma":"bricoler","pos":"VER"} ,
		{"word":"bricolées","word_nosc":"bricolees","lemma":"bricoler","pos":"VER"} ,
		{"word":"bricolés","word_nosc":"bricoles","lemma":"bricoler","pos":"VER"} ,
		{"word":"bridais","word_nosc":"bridais","lemma":"brider","pos":"VER"} ,
		{"word":"bridait","word_nosc":"bridait","lemma":"brider","pos":"VER"} ,
		{"word":"bridant","word_nosc":"bridant","lemma":"brider","pos":"VER"} ,
		{"word":"bride","word_nosc":"bride","lemma":"brider","pos":"VER"} ,
		{"word":"brident","word_nosc":"brident","lemma":"brider","pos":"VER"} ,
		{"word":"brider","word_nosc":"brider","lemma":"brider","pos":"VER"} ,
		{"word":"brides","word_nosc":"brides","lemma":"brider","pos":"VER"} ,
		{"word":"bridez","word_nosc":"bridez","lemma":"brider","pos":"VER"} ,
		{"word":"bridgeait","word_nosc":"bridgeait","lemma":"bridger","pos":"VER"} ,
		{"word":"bridgeant","word_nosc":"bridgeant","lemma":"bridger","pos":"VER"} ,
		{"word":"bridgent","word_nosc":"bridgent","lemma":"bridger","pos":"VER"} ,
		{"word":"bridgeons","word_nosc":"bridgeons","lemma":"bridger","pos":"VER"} ,
		{"word":"bridger","word_nosc":"bridger","lemma":"bridger","pos":"VER"} ,
		{"word":"bridgez","word_nosc":"bridgez","lemma":"bridger","pos":"VER"} ,
		{"word":"bridgées","word_nosc":"bridgees","lemma":"bridger","pos":"VER"} ,
		{"word":"bridé","word_nosc":"bride","lemma":"brider","pos":"VER"} ,
		{"word":"bridée","word_nosc":"bridee","lemma":"brider","pos":"VER"} ,
		{"word":"bridées","word_nosc":"bridees","lemma":"brider","pos":"VER"} ,
		{"word":"bridés","word_nosc":"brides","lemma":"brider","pos":"VER"} ,
		{"word":"briefe","word_nosc":"briefe","lemma":"briefer","pos":"VER"} ,
		{"word":"briefer","word_nosc":"briefer","lemma":"briefer","pos":"VER"} ,
		{"word":"briefera","word_nosc":"briefera","lemma":"briefer","pos":"VER"} ,
		{"word":"brieferai","word_nosc":"brieferai","lemma":"briefer","pos":"VER"} ,
		{"word":"brieferas","word_nosc":"brieferas","lemma":"briefer","pos":"VER"} ,
		{"word":"briefes","word_nosc":"briefes","lemma":"briefer","pos":"VER"} ,
		{"word":"briefez","word_nosc":"briefez","lemma":"briefer","pos":"VER"} ,
		{"word":"briefé","word_nosc":"briefe","lemma":"briefer","pos":"VER"} ,
		{"word":"briefée","word_nosc":"briefee","lemma":"briefer","pos":"VER"} ,
		{"word":"briefés","word_nosc":"briefes","lemma":"briefer","pos":"VER"} ,
		{"word":"brifez","word_nosc":"brifez","lemma":"brifer","pos":"VER"} ,
		{"word":"briffe","word_nosc":"briffe","lemma":"briffer","pos":"VER"} ,
		{"word":"briffer","word_nosc":"briffer","lemma":"briffer","pos":"VER"} ,
		{"word":"brigandaient","word_nosc":"brigandaient","lemma":"brigander","pos":"VER"} ,
		{"word":"brigandasse","word_nosc":"brigandasse","lemma":"brigander","pos":"VER"} ,
		{"word":"brigande","word_nosc":"brigande","lemma":"brigander","pos":"VER"} ,
		{"word":"briguaient","word_nosc":"briguaient","lemma":"briguer","pos":"VER"} ,
		{"word":"briguait","word_nosc":"briguait","lemma":"briguer","pos":"VER"} ,
		{"word":"briguant","word_nosc":"briguant","lemma":"briguer","pos":"VER"} ,
		{"word":"brigue","word_nosc":"brigue","lemma":"briguer","pos":"VER"} ,
		{"word":"briguer","word_nosc":"briguer","lemma":"briguer","pos":"VER"} ,
		{"word":"briguerait","word_nosc":"briguerait","lemma":"briguer","pos":"VER"} ,
		{"word":"briguèrent","word_nosc":"briguerent","lemma":"briguer","pos":"VER"} ,
		{"word":"brigué","word_nosc":"brigue","lemma":"briguer","pos":"VER"} ,
		{"word":"brilla","word_nosc":"brilla","lemma":"briller","pos":"VER"} ,
		{"word":"brillaient","word_nosc":"brillaient","lemma":"briller","pos":"VER"} ,
		{"word":"brillais","word_nosc":"brillais","lemma":"briller","pos":"VER"} ,
		{"word":"brillait","word_nosc":"brillait","lemma":"briller","pos":"VER"} ,
		{"word":"brillant","word_nosc":"brillant","lemma":"briller","pos":"VER"} ,
		{"word":"brillante","word_nosc":"brillante","lemma":"brillanter","pos":"VER"} ,
		{"word":"brillantine","word_nosc":"brillantine","lemma":"brillantiner","pos":"VER"} ,
		{"word":"brillantiner","word_nosc":"brillantiner","lemma":"brillantiner","pos":"VER"} ,
		{"word":"brillantinée","word_nosc":"brillantinee","lemma":"brillantiner","pos":"VER"} ,
		{"word":"brillantinées","word_nosc":"brillantinees","lemma":"brillantiner","pos":"VER"} ,
		{"word":"brillantinés","word_nosc":"brillantines","lemma":"brillantiner","pos":"VER"} ,
		{"word":"brillantés","word_nosc":"brillantes","lemma":"brillanter","pos":"VER"} ,
		{"word":"brille","word_nosc":"brille","lemma":"briller","pos":"VER"} ,
		{"word":"brillent","word_nosc":"brillent","lemma":"briller","pos":"VER"} ,
		{"word":"briller","word_nosc":"briller","lemma":"briller","pos":"VER"} ,
		{"word":"brillera","word_nosc":"brillera","lemma":"briller","pos":"VER"} ,
		{"word":"brilleraient","word_nosc":"brilleraient","lemma":"briller","pos":"VER"} ,
		{"word":"brillerais","word_nosc":"brillerais","lemma":"briller","pos":"VER"} ,
		{"word":"brillerait","word_nosc":"brillerait","lemma":"briller","pos":"VER"} ,
		{"word":"brillerons","word_nosc":"brillerons","lemma":"briller","pos":"VER"} ,
		{"word":"brilleront","word_nosc":"brilleront","lemma":"briller","pos":"VER"} ,
		{"word":"brilles","word_nosc":"brilles","lemma":"briller","pos":"VER"} ,
		{"word":"brillez","word_nosc":"brillez","lemma":"briller","pos":"VER"} ,
		{"word":"brillions","word_nosc":"brillions","lemma":"briller","pos":"VER"} ,
		{"word":"brillons","word_nosc":"brillons","lemma":"briller","pos":"VER"} ,
		{"word":"brillât","word_nosc":"brillat","lemma":"briller","pos":"VER"} ,
		{"word":"brillèrent","word_nosc":"brillerent","lemma":"briller","pos":"VER"} ,
		{"word":"brillé","word_nosc":"brille","lemma":"briller","pos":"VER"} ,
		{"word":"brima","word_nosc":"brima","lemma":"brimer","pos":"VER"} ,
		{"word":"brimaient","word_nosc":"brimaient","lemma":"brimer","pos":"VER"} ,
		{"word":"brimais","word_nosc":"brimais","lemma":"brimer","pos":"VER"} ,
		{"word":"brimait","word_nosc":"brimait","lemma":"brimer","pos":"VER"} ,
		{"word":"brimbala","word_nosc":"brimbala","lemma":"brimbaler","pos":"VER"} ,
		{"word":"brimbalant","word_nosc":"brimbalant","lemma":"brimbaler","pos":"VER"} ,
		{"word":"brimbaler","word_nosc":"brimbaler","lemma":"brimbaler","pos":"VER"} ,
		{"word":"brimbalés","word_nosc":"brimbales","lemma":"brimbaler","pos":"VER"} ,
		{"word":"brime","word_nosc":"brime","lemma":"brimer","pos":"VER"} ,
		{"word":"briment","word_nosc":"briment","lemma":"brimer","pos":"VER"} ,
		{"word":"brimer","word_nosc":"brimer","lemma":"brimer","pos":"VER"} ,
		{"word":"brimé","word_nosc":"brime","lemma":"brimer","pos":"VER"} ,
		{"word":"brimée","word_nosc":"brimee","lemma":"brimer","pos":"VER"} ,
		{"word":"brimées","word_nosc":"brimees","lemma":"brimer","pos":"VER"} ,
		{"word":"brimés","word_nosc":"brimes","lemma":"brimer","pos":"VER"} ,
		{"word":"bringuebalaient","word_nosc":"bringuebalaient","lemma":"bringuebaler","pos":"VER"} ,
		{"word":"bringuebalait","word_nosc":"bringuebalait","lemma":"bringuebaler","pos":"VER"} ,
		{"word":"bringuebalant","word_nosc":"bringuebalant","lemma":"bringuebaler","pos":"VER"} ,
		{"word":"bringuebale","word_nosc":"bringuebale","lemma":"bringuebaler","pos":"VER"} ,
		{"word":"bringuebalent","word_nosc":"bringuebalent","lemma":"bringuebaler","pos":"VER"} ,
		{"word":"bringuebaler","word_nosc":"bringuebaler","lemma":"bringuebaler","pos":"VER"} ,
		{"word":"brinquebalait","word_nosc":"brinquebalait","lemma":"brinquebaler","pos":"VER"} ,
		{"word":"brinquebale","word_nosc":"brinquebale","lemma":"brinquebaler","pos":"VER"} ,
		{"word":"brinquebaler","word_nosc":"brinquebaler","lemma":"brinquebaler","pos":"VER"} ,
		{"word":"brinqueballant","word_nosc":"brinqueballant","lemma":"brinqueballer","pos":"VER"} ,
		{"word":"brinqueballé","word_nosc":"brinqueballe","lemma":"brinqueballer","pos":"VER"} ,
		{"word":"brinqueballés","word_nosc":"brinqueballes","lemma":"brinqueballer","pos":"VER"} ,
		{"word":"brinquebalé","word_nosc":"brinquebale","lemma":"brinquebaler","pos":"VER"} ,
		{"word":"briquaient","word_nosc":"briquaient","lemma":"briquer","pos":"VER"} ,
		{"word":"briquais","word_nosc":"briquais","lemma":"briquer","pos":"VER"} ,
		{"word":"briquait","word_nosc":"briquait","lemma":"briquer","pos":"VER"} ,
		{"word":"briquant","word_nosc":"briquant","lemma":"briquer","pos":"VER"} ,
		{"word":"brique","word_nosc":"brique","lemma":"briquer","pos":"VER"} ,
		{"word":"briquent","word_nosc":"briquent","lemma":"briquer","pos":"VER"} ,
		{"word":"briquer","word_nosc":"briquer","lemma":"briquer","pos":"VER"} ,
		{"word":"briquerait","word_nosc":"briquerait","lemma":"briquer","pos":"VER"} ,
		{"word":"briquez","word_nosc":"briquez","lemma":"briquer","pos":"VER"} ,
		{"word":"briqué","word_nosc":"brique","lemma":"briquer","pos":"VER"} ,
		{"word":"briquée","word_nosc":"briquee","lemma":"briquer","pos":"VER"} ,
		{"word":"briquées","word_nosc":"briquees","lemma":"briquer","pos":"VER"} ,
		{"word":"briqués","word_nosc":"briques","lemma":"briquer","pos":"VER"} ,
		{"word":"brisa","word_nosc":"brisa","lemma":"briser","pos":"VER"} ,
		{"word":"brisai","word_nosc":"brisai","lemma":"briser","pos":"VER"} ,
		{"word":"brisaient","word_nosc":"brisaient","lemma":"briser","pos":"VER"} ,
		{"word":"brisais","word_nosc":"brisais","lemma":"briser","pos":"VER"} ,
		{"word":"brisait","word_nosc":"brisait","lemma":"briser","pos":"VER"} ,
		{"word":"brisant","word_nosc":"brisant","lemma":"briser","pos":"VER"} ,
		{"word":"brise","word_nosc":"brise","lemma":"briser","pos":"VER"} ,
		{"word":"brisent","word_nosc":"brisent","lemma":"briser","pos":"VER"} ,
		{"word":"briser","word_nosc":"briser","lemma":"briser","pos":"VER"} ,
		{"word":"brisera","word_nosc":"brisera","lemma":"briser","pos":"VER"} ,
		{"word":"briserai","word_nosc":"briserai","lemma":"briser","pos":"VER"} ,
		{"word":"briseraient","word_nosc":"briseraient","lemma":"briser","pos":"VER"} ,
		{"word":"briserais","word_nosc":"briserais","lemma":"briser","pos":"VER"} ,
		{"word":"briserait","word_nosc":"briserait","lemma":"briser","pos":"VER"} ,
		{"word":"briseras","word_nosc":"briseras","lemma":"briser","pos":"VER"} ,
		{"word":"briserez","word_nosc":"briserez","lemma":"briser","pos":"VER"} ,
		{"word":"briseriez","word_nosc":"briseriez","lemma":"briser","pos":"VER"} ,
		{"word":"briserons","word_nosc":"briserons","lemma":"briser","pos":"VER"} ,
		{"word":"briseront","word_nosc":"briseront","lemma":"briser","pos":"VER"} ,
		{"word":"brises","word_nosc":"brises","lemma":"briser","pos":"VER"} ,
		{"word":"brisez","word_nosc":"brisez","lemma":"briser","pos":"VER"} ,
		{"word":"brisions","word_nosc":"brisions","lemma":"briser","pos":"VER"} ,
		{"word":"brisons","word_nosc":"brisons","lemma":"briser","pos":"VER"} ,
		{"word":"brisât","word_nosc":"brisat","lemma":"briser","pos":"VER"} ,
		{"word":"brisèrent","word_nosc":"briserent","lemma":"briser","pos":"VER"} ,
		{"word":"brisé","word_nosc":"brise","lemma":"briser","pos":"VER"} ,
		{"word":"brisée","word_nosc":"brisee","lemma":"briser","pos":"VER"} ,
		{"word":"brisées","word_nosc":"brisees","lemma":"briser","pos":"VER"} ,
		{"word":"brisés","word_nosc":"brises","lemma":"briser","pos":"VER"} ,
		{"word":"brocantées","word_nosc":"brocantees","lemma":"brocanter","pos":"VER"} ,
		{"word":"brocardaient","word_nosc":"brocardaient","lemma":"brocarder","pos":"VER"} ,
		{"word":"brocardait","word_nosc":"brocardait","lemma":"brocarder","pos":"VER"} ,
		{"word":"brocarde","word_nosc":"brocarde","lemma":"brocarder","pos":"VER"} ,
		{"word":"brocarder","word_nosc":"brocarder","lemma":"brocarder","pos":"VER"} ,
		{"word":"brocardions","word_nosc":"brocardions","lemma":"brocarder","pos":"VER"} ,
		{"word":"brochaient","word_nosc":"brochaient","lemma":"brocher","pos":"VER"} ,
		{"word":"brochant","word_nosc":"brochant","lemma":"brocher","pos":"VER"} ,
		{"word":"broche","word_nosc":"broche","lemma":"brocher","pos":"VER"} ,
		{"word":"brocher","word_nosc":"brocher","lemma":"brocher","pos":"VER"} ,
		{"word":"broché","word_nosc":"broche","lemma":"brocher","pos":"VER"} ,
		{"word":"brochée","word_nosc":"brochee","lemma":"brocher","pos":"VER"} ,
		{"word":"brochés","word_nosc":"broches","lemma":"brocher","pos":"VER"} ,
		{"word":"broda","word_nosc":"broda","lemma":"broder","pos":"VER"} ,
		{"word":"brodaient","word_nosc":"brodaient","lemma":"broder","pos":"VER"} ,
		{"word":"brodais","word_nosc":"brodais","lemma":"broder","pos":"VER"} ,
		{"word":"brodait","word_nosc":"brodait","lemma":"broder","pos":"VER"} ,
		{"word":"brodant","word_nosc":"brodant","lemma":"broder","pos":"VER"} ,
		{"word":"brode","word_nosc":"brode","lemma":"broder","pos":"VER"} ,
		{"word":"brodent","word_nosc":"brodent","lemma":"broder","pos":"VER"} ,
		{"word":"broder","word_nosc":"broder","lemma":"broder","pos":"VER"} ,
		{"word":"brodera","word_nosc":"brodera","lemma":"broder","pos":"VER"} ,
		{"word":"broderai","word_nosc":"broderai","lemma":"broder","pos":"VER"} ,
		{"word":"brodes","word_nosc":"brodes","lemma":"broder","pos":"VER"} ,
		{"word":"brodez","word_nosc":"brodez","lemma":"broder","pos":"VER"} ,
		{"word":"brodé","word_nosc":"brode","lemma":"broder","pos":"VER"} ,
		{"word":"brodée","word_nosc":"brodee","lemma":"broder","pos":"VER"} ,
		{"word":"brodées","word_nosc":"brodees","lemma":"broder","pos":"VER"} ,
		{"word":"brodés","word_nosc":"brodes","lemma":"broder","pos":"VER"} ,
		{"word":"broie","word_nosc":"broie","lemma":"broyer","pos":"VER"} ,
		{"word":"broient","word_nosc":"broient","lemma":"broyer","pos":"VER"} ,
		{"word":"broiera","word_nosc":"broiera","lemma":"broyer","pos":"VER"} ,
		{"word":"broierai","word_nosc":"broierai","lemma":"broyer","pos":"VER"} ,
		{"word":"broieras","word_nosc":"broieras","lemma":"broyer","pos":"VER"} ,
		{"word":"broies","word_nosc":"broies","lemma":"broyer","pos":"VER"} ,
		{"word":"broncha","word_nosc":"broncha","lemma":"broncher","pos":"VER"} ,
		{"word":"bronchai","word_nosc":"bronchai","lemma":"broncher","pos":"VER"} ,
		{"word":"bronchaient","word_nosc":"bronchaient","lemma":"broncher","pos":"VER"} ,
		{"word":"bronchais","word_nosc":"bronchais","lemma":"broncher","pos":"VER"} ,
		{"word":"bronchait","word_nosc":"bronchait","lemma":"broncher","pos":"VER"} ,
		{"word":"bronchant","word_nosc":"bronchant","lemma":"broncher","pos":"VER"} ,
		{"word":"bronche","word_nosc":"bronche","lemma":"broncher","pos":"VER"} ,
		{"word":"bronchent","word_nosc":"bronchent","lemma":"broncher","pos":"VER"} ,
		{"word":"broncher","word_nosc":"broncher","lemma":"broncher","pos":"VER"} ,
		{"word":"bronchera","word_nosc":"bronchera","lemma":"broncher","pos":"VER"} ,
		{"word":"broncherai","word_nosc":"broncherai","lemma":"broncher","pos":"VER"} ,
		{"word":"broncherais","word_nosc":"broncherais","lemma":"broncher","pos":"VER"} ,
		{"word":"broncherait","word_nosc":"broncherait","lemma":"broncher","pos":"VER"} ,
		{"word":"broncherons","word_nosc":"broncherons","lemma":"broncher","pos":"VER"} ,
		{"word":"broncheront","word_nosc":"broncheront","lemma":"broncher","pos":"VER"} ,
		{"word":"bronches","word_nosc":"bronches","lemma":"broncher","pos":"VER"} ,
		{"word":"bronchez","word_nosc":"bronchez","lemma":"broncher","pos":"VER"} ,
		{"word":"bronchons","word_nosc":"bronchons","lemma":"broncher","pos":"VER"} ,
		{"word":"bronchât","word_nosc":"bronchat","lemma":"broncher","pos":"VER"} ,
		{"word":"bronchèrent","word_nosc":"broncherent","lemma":"broncher","pos":"VER"} ,
		{"word":"bronché","word_nosc":"bronche","lemma":"broncher","pos":"VER"} ,
		{"word":"bronzaient","word_nosc":"bronzaient","lemma":"bronzer","pos":"VER"} ,
		{"word":"bronzait","word_nosc":"bronzait","lemma":"bronzer","pos":"VER"} ,
		{"word":"bronzant","word_nosc":"bronzant","lemma":"bronzer","pos":"VER"} ,
		{"word":"bronze","word_nosc":"bronze","lemma":"bronzer","pos":"VER"} ,
		{"word":"bronzent","word_nosc":"bronzent","lemma":"bronzer","pos":"VER"} ,
		{"word":"bronzer","word_nosc":"bronzer","lemma":"bronzer","pos":"VER"} ,
		{"word":"bronzes","word_nosc":"bronzes","lemma":"bronzer","pos":"VER"} ,
		{"word":"bronzions","word_nosc":"bronzions","lemma":"bronzer","pos":"VER"} ,
		{"word":"bronzé","word_nosc":"bronze","lemma":"bronzer","pos":"VER"} ,
		{"word":"bronzée","word_nosc":"bronzee","lemma":"bronzer","pos":"VER"} ,
		{"word":"bronzées","word_nosc":"bronzees","lemma":"bronzer","pos":"VER"} ,
		{"word":"bronzés","word_nosc":"bronzes","lemma":"bronzer","pos":"VER"} ,
		{"word":"brossa","word_nosc":"brossa","lemma":"brosser","pos":"VER"} ,
		{"word":"brossaient","word_nosc":"brossaient","lemma":"brosser","pos":"VER"} ,
		{"word":"brossais","word_nosc":"brossais","lemma":"brosser","pos":"VER"} ,
		{"word":"brossait","word_nosc":"brossait","lemma":"brosser","pos":"VER"} ,
		{"word":"brossant","word_nosc":"brossant","lemma":"brosser","pos":"VER"} ,
		{"word":"brosse","word_nosc":"brosse","lemma":"brosser","pos":"VER"} ,
		{"word":"brossent","word_nosc":"brossent","lemma":"brosser","pos":"VER"} ,
		{"word":"brosser","word_nosc":"brosser","lemma":"brosser","pos":"VER"} ,
		{"word":"brossera","word_nosc":"brossera","lemma":"brosser","pos":"VER"} ,
		{"word":"brosserai","word_nosc":"brosserai","lemma":"brosser","pos":"VER"} ,
		{"word":"brosseras","word_nosc":"brosseras","lemma":"brosser","pos":"VER"} ,
		{"word":"brosserez","word_nosc":"brosserez","lemma":"brosser","pos":"VER"} ,
		{"word":"brosses","word_nosc":"brosses","lemma":"brosser","pos":"VER"} ,
		{"word":"brossez","word_nosc":"brossez","lemma":"brosser","pos":"VER"} ,
		{"word":"brossé","word_nosc":"brosse","lemma":"brosser","pos":"VER"} ,
		{"word":"brossée","word_nosc":"brossee","lemma":"brosser","pos":"VER"} ,
		{"word":"brossées","word_nosc":"brossees","lemma":"brosser","pos":"VER"} ,
		{"word":"brossés","word_nosc":"brosses","lemma":"brosser","pos":"VER"} ,
		{"word":"brouetta","word_nosc":"brouetta","lemma":"brouetter","pos":"VER"} ,
		{"word":"brouettait","word_nosc":"brouettait","lemma":"brouetter","pos":"VER"} ,
		{"word":"brouetter","word_nosc":"brouetter","lemma":"brouetter","pos":"VER"} ,
		{"word":"brouilla","word_nosc":"brouilla","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouillaient","word_nosc":"brouillaient","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouillait","word_nosc":"brouillait","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouillant","word_nosc":"brouillant","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouillassait","word_nosc":"brouillassait","lemma":"brouillasser","pos":"VER"} ,
		{"word":"brouille","word_nosc":"brouille","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouillent","word_nosc":"brouillent","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouiller","word_nosc":"brouiller","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouilleraient","word_nosc":"brouilleraient","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouillerait","word_nosc":"brouillerait","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouillerons","word_nosc":"brouillerons","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouilles","word_nosc":"brouilles","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouillez","word_nosc":"brouillez","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouillonné","word_nosc":"brouillonne","lemma":"brouillonner","pos":"VER"} ,
		{"word":"brouillons","word_nosc":"brouillons","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouillât","word_nosc":"brouillat","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouillèrent","word_nosc":"brouillerent","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouillé","word_nosc":"brouille","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouillée","word_nosc":"brouillee","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouillées","word_nosc":"brouillees","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouillés","word_nosc":"brouilles","lemma":"brouiller","pos":"VER"} ,
		{"word":"brouis","word_nosc":"brouis","lemma":"brouir","pos":"VER"} ,
		{"word":"brouit","word_nosc":"brouit","lemma":"brouir","pos":"VER"} ,
		{"word":"broussait","word_nosc":"broussait","lemma":"brousser","pos":"VER"} ,
		{"word":"brouta","word_nosc":"brouta","lemma":"brouter","pos":"VER"} ,
		{"word":"broutaient","word_nosc":"broutaient","lemma":"brouter","pos":"VER"} ,
		{"word":"broutait","word_nosc":"broutait","lemma":"brouter","pos":"VER"} ,
		{"word":"broutant","word_nosc":"broutant","lemma":"brouter","pos":"VER"} ,
		{"word":"broute","word_nosc":"broute","lemma":"brouter","pos":"VER"} ,
		{"word":"broutent","word_nosc":"broutent","lemma":"brouter","pos":"VER"} ,
		{"word":"brouter","word_nosc":"brouter","lemma":"brouter","pos":"VER"} ,
		{"word":"brouterai","word_nosc":"brouterai","lemma":"brouter","pos":"VER"} ,
		{"word":"brouteraient","word_nosc":"brouteraient","lemma":"brouter","pos":"VER"} ,
		{"word":"broutes","word_nosc":"broutes","lemma":"brouter","pos":"VER"} ,
		{"word":"broutez","word_nosc":"broutez","lemma":"brouter","pos":"VER"} ,
		{"word":"broutèrent","word_nosc":"brouterent","lemma":"brouter","pos":"VER"} ,
		{"word":"brouté","word_nosc":"broute","lemma":"brouter","pos":"VER"} ,
		{"word":"broutée","word_nosc":"broutee","lemma":"brouter","pos":"VER"} ,
		{"word":"broutés","word_nosc":"broutes","lemma":"brouter","pos":"VER"} ,
		{"word":"broya","word_nosc":"broya","lemma":"broyer","pos":"VER"} ,
		{"word":"broyaient","word_nosc":"broyaient","lemma":"broyer","pos":"VER"} ,
		{"word":"broyais","word_nosc":"broyais","lemma":"broyer","pos":"VER"} ,
		{"word":"broyait","word_nosc":"broyait","lemma":"broyer","pos":"VER"} ,
		{"word":"broyant","word_nosc":"broyant","lemma":"broyer","pos":"VER"} ,
		{"word":"broyer","word_nosc":"broyer","lemma":"broyer","pos":"VER"} ,
		{"word":"broyez","word_nosc":"broyez","lemma":"broyer","pos":"VER"} ,
		{"word":"broyèrent","word_nosc":"broyerent","lemma":"broyer","pos":"VER"} ,
		{"word":"broyé","word_nosc":"broye","lemma":"broyer","pos":"VER"} ,
		{"word":"broyée","word_nosc":"broyee","lemma":"broyer","pos":"VER"} ,
		{"word":"broyées","word_nosc":"broyees","lemma":"broyer","pos":"VER"} ,
		{"word":"broyés","word_nosc":"broyes","lemma":"broyer","pos":"VER"} ,
		{"word":"bruinait","word_nosc":"bruinait","lemma":"bruiner","pos":"VER"} ,
		{"word":"bruinasse","word_nosc":"bruinasse","lemma":"bruiner","pos":"VER"} ,
		{"word":"bruine","word_nosc":"bruine","lemma":"bruiner","pos":"VER"} ,
		{"word":"bruiner","word_nosc":"bruiner","lemma":"bruiner","pos":"VER"} ,
		{"word":"bruire","word_nosc":"bruire","lemma":"bruire","pos":"VER"} ,
		{"word":"bruissa","word_nosc":"bruissa","lemma":"bruisser","pos":"VER"} ,
		{"word":"bruissaient","word_nosc":"bruissaient","lemma":"bruisser","pos":"VER"} ,
		{"word":"bruissait","word_nosc":"bruissait","lemma":"bruisser","pos":"VER"} ,
		{"word":"bruissant","word_nosc":"bruissant","lemma":"bruisser","pos":"VER"} ,
		{"word":"bruisse","word_nosc":"bruisse","lemma":"bruisser","pos":"VER"} ,
		{"word":"bruissent","word_nosc":"bruissent","lemma":"bruisser","pos":"VER"} ,
		{"word":"bruissé","word_nosc":"bruisse","lemma":"bruisser","pos":"VER"} ,
		{"word":"bruit","word_nosc":"bruit","lemma":"bruire","pos":"VER"} ,
		{"word":"bruita","word_nosc":"bruita","lemma":"bruiter","pos":"VER"} ,
		{"word":"bruiter","word_nosc":"bruiter","lemma":"bruiter","pos":"VER"} ,
		{"word":"brumassait","word_nosc":"brumassait","lemma":"brumasser","pos":"VER"} ,
		{"word":"brument","word_nosc":"brument","lemma":"brumer","pos":"VER"} ,
		{"word":"bruni","word_nosc":"bruni","lemma":"brunir","pos":"VER"} ,
		{"word":"brunie","word_nosc":"brunie","lemma":"brunir","pos":"VER"} ,
		{"word":"brunies","word_nosc":"brunies","lemma":"brunir","pos":"VER"} ,
		{"word":"brunir","word_nosc":"brunir","lemma":"brunir","pos":"VER"} ,
		{"word":"brunirent","word_nosc":"brunirent","lemma":"brunir","pos":"VER"} ,
		{"word":"brunis","word_nosc":"brunis","lemma":"brunir","pos":"VER"} ,
		{"word":"brunissaient","word_nosc":"brunissaient","lemma":"brunir","pos":"VER"} ,
		{"word":"brunissent","word_nosc":"brunissent","lemma":"brunir","pos":"VER"} ,
		{"word":"brunit","word_nosc":"brunit","lemma":"brunir","pos":"VER"} ,
		{"word":"brusqua","word_nosc":"brusqua","lemma":"brusquer","pos":"VER"} ,
		{"word":"brusquai","word_nosc":"brusquai","lemma":"brusquer","pos":"VER"} ,
		{"word":"brusquait","word_nosc":"brusquait","lemma":"brusquer","pos":"VER"} ,
		{"word":"brusquant","word_nosc":"brusquant","lemma":"brusquer","pos":"VER"} ,
		{"word":"brusque","word_nosc":"brusque","lemma":"brusquer","pos":"VER"} ,
		{"word":"brusquent","word_nosc":"brusquent","lemma":"brusquer","pos":"VER"} ,
		{"word":"brusquer","word_nosc":"brusquer","lemma":"brusquer","pos":"VER"} ,
		{"word":"brusquerai","word_nosc":"brusquerai","lemma":"brusquer","pos":"VER"} ,
		{"word":"brusqueraient","word_nosc":"brusqueraient","lemma":"brusquer","pos":"VER"} ,
		{"word":"brusquez","word_nosc":"brusquez","lemma":"brusquer","pos":"VER"} ,
		{"word":"brusquons","word_nosc":"brusquons","lemma":"brusquer","pos":"VER"} ,
		{"word":"brusquât","word_nosc":"brusquat","lemma":"brusquer","pos":"VER"} ,
		{"word":"brusqué","word_nosc":"brusque","lemma":"brusquer","pos":"VER"} ,
		{"word":"brusquée","word_nosc":"brusquee","lemma":"brusquer","pos":"VER"} ,
		{"word":"brutalisaient","word_nosc":"brutalisaient","lemma":"brutaliser","pos":"VER"} ,
		{"word":"brutalisait","word_nosc":"brutalisait","lemma":"brutaliser","pos":"VER"} ,
		{"word":"brutalisant","word_nosc":"brutalisant","lemma":"brutaliser","pos":"VER"} ,
		{"word":"brutalise","word_nosc":"brutalise","lemma":"brutaliser","pos":"VER"} ,
		{"word":"brutaliser","word_nosc":"brutaliser","lemma":"brutaliser","pos":"VER"} ,
		{"word":"brutaliserai","word_nosc":"brutaliserai","lemma":"brutaliser","pos":"VER"} ,
		{"word":"brutaliserait","word_nosc":"brutaliserait","lemma":"brutaliser","pos":"VER"} ,
		{"word":"brutalisez","word_nosc":"brutalisez","lemma":"brutaliser","pos":"VER"} ,
		{"word":"brutalisé","word_nosc":"brutalise","lemma":"brutaliser","pos":"VER"} ,
		{"word":"brutalisée","word_nosc":"brutalisee","lemma":"brutaliser","pos":"VER"} ,
		{"word":"brutalisées","word_nosc":"brutalisees","lemma":"brutaliser","pos":"VER"} ,
		{"word":"brutalisés","word_nosc":"brutalises","lemma":"brutaliser","pos":"VER"} ,
		{"word":"brêles","word_nosc":"breles","lemma":"brêler","pos":"VER"} ,
		{"word":"brûla","word_nosc":"brula","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlai","word_nosc":"brulai","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlaient","word_nosc":"brulaient","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlais","word_nosc":"brulais","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlait","word_nosc":"brulait","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlant","word_nosc":"brulant","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlasse","word_nosc":"brulasse","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlassent","word_nosc":"brulassent","lemma":"brûler","pos":"VER"} ,
		{"word":"brûle","word_nosc":"brule","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlent","word_nosc":"brulent","lemma":"brûler","pos":"VER"} ,
		{"word":"brûler","word_nosc":"bruler","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlera","word_nosc":"brulera","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlerai","word_nosc":"brulerai","lemma":"brûler","pos":"VER"} ,
		{"word":"brûleraient","word_nosc":"bruleraient","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlerais","word_nosc":"brulerais","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlerait","word_nosc":"brulerait","lemma":"brûler","pos":"VER"} ,
		{"word":"brûleras","word_nosc":"bruleras","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlerez","word_nosc":"brulerez","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlerons","word_nosc":"brulerons","lemma":"brûler","pos":"VER"} ,
		{"word":"brûleront","word_nosc":"bruleront","lemma":"brûler","pos":"VER"} ,
		{"word":"brûles","word_nosc":"brules","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlez","word_nosc":"brulez","lemma":"brûler","pos":"VER"} ,
		{"word":"brûliez","word_nosc":"bruliez","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlions","word_nosc":"brulions","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlons","word_nosc":"brulons","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlât","word_nosc":"brulat","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlèrent","word_nosc":"brulerent","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlé","word_nosc":"brule","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlée","word_nosc":"brulee","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlées","word_nosc":"brulees","lemma":"brûler","pos":"VER"} ,
		{"word":"brûlés","word_nosc":"brules","lemma":"brûler","pos":"VER"} ,
		{"word":"bu","word_nosc":"bu","lemma":"boire","pos":"VER"} ,
		{"word":"bua","word_nosc":"bua","lemma":"buer","pos":"VER"} ,
		{"word":"bugler","word_nosc":"bugler","lemma":"bugler","pos":"VER"} ,
		{"word":"bugné","word_nosc":"bugne","lemma":"bugner","pos":"VER"} ,
		{"word":"buissonnant","word_nosc":"buissonnant","lemma":"buissonner","pos":"VER"} ,
		{"word":"buissonne","word_nosc":"buissonne","lemma":"buissonner","pos":"VER"} ,
		{"word":"buissonner","word_nosc":"buissonner","lemma":"buissonner","pos":"VER"} ,
		{"word":"bulla","word_nosc":"bulla","lemma":"buller","pos":"VER"} ,
		{"word":"bulle","word_nosc":"bulle","lemma":"buller","pos":"VER"} ,
		{"word":"buller","word_nosc":"buller","lemma":"buller","pos":"VER"} ,
		{"word":"bulles","word_nosc":"bulles","lemma":"buller","pos":"VER"} ,
		{"word":"bullez","word_nosc":"bullez","lemma":"buller","pos":"VER"} ,
		{"word":"bureaucratisées","word_nosc":"bureaucratisees","lemma":"bureaucratiser","pos":"VER"} ,
		{"word":"burent","word_nosc":"burent","lemma":"boire","pos":"VER"} ,
		{"word":"burina","word_nosc":"burina","lemma":"buriner","pos":"VER"} ,
		{"word":"buriner","word_nosc":"buriner","lemma":"buriner","pos":"VER"} ,
		{"word":"buriné","word_nosc":"burine","lemma":"buriner","pos":"VER"} ,
		{"word":"burinée","word_nosc":"burinee","lemma":"buriner","pos":"VER"} ,
		{"word":"burinés","word_nosc":"burines","lemma":"buriner","pos":"VER"} ,
		{"word":"bus","word_nosc":"bus","lemma":"boire","pos":"VER"} ,
		{"word":"busqué","word_nosc":"busque","lemma":"busquer","pos":"VER"} ,
		{"word":"busse","word_nosc":"busse","lemma":"boire","pos":"VER"} ,
		{"word":"but","word_nosc":"but","lemma":"boire","pos":"VER"} ,
		{"word":"buta","word_nosc":"buta","lemma":"buter","pos":"VER"} ,
		{"word":"butai","word_nosc":"butai","lemma":"buter","pos":"VER"} ,
		{"word":"butaient","word_nosc":"butaient","lemma":"buter","pos":"VER"} ,
		{"word":"butais","word_nosc":"butais","lemma":"buter","pos":"VER"} ,
		{"word":"butait","word_nosc":"butait","lemma":"buter","pos":"VER"} ,
		{"word":"butant","word_nosc":"butant","lemma":"buter","pos":"VER"} ,
		{"word":"bute","word_nosc":"bute","lemma":"buter","pos":"VER"} ,
		{"word":"butent","word_nosc":"butent","lemma":"buter","pos":"VER"} ,
		{"word":"buter","word_nosc":"buter","lemma":"buter","pos":"VER"} ,
		{"word":"butera","word_nosc":"butera","lemma":"buter","pos":"VER"} ,
		{"word":"buterai","word_nosc":"buterai","lemma":"buter","pos":"VER"} ,
		{"word":"buterais","word_nosc":"buterais","lemma":"buter","pos":"VER"} ,
		{"word":"buterait","word_nosc":"buterait","lemma":"buter","pos":"VER"} ,
		{"word":"buteras","word_nosc":"buteras","lemma":"buter","pos":"VER"} ,
		{"word":"buterez","word_nosc":"buterez","lemma":"buter","pos":"VER"} ,
		{"word":"buterions","word_nosc":"buterions","lemma":"buter","pos":"VER"} ,
		{"word":"buteront","word_nosc":"buteront","lemma":"buter","pos":"VER"} ,
		{"word":"butes","word_nosc":"butes","lemma":"buter","pos":"VER"} ,
		{"word":"butez","word_nosc":"butez","lemma":"buter","pos":"VER"} ,
		{"word":"butinait","word_nosc":"butinait","lemma":"butiner","pos":"VER"} ,
		{"word":"butinant","word_nosc":"butinant","lemma":"butiner","pos":"VER"} ,
		{"word":"butine","word_nosc":"butine","lemma":"butiner","pos":"VER"} ,
		{"word":"butinent","word_nosc":"butinent","lemma":"butiner","pos":"VER"} ,
		{"word":"butiner","word_nosc":"butiner","lemma":"butiner","pos":"VER"} ,
		{"word":"butines","word_nosc":"butines","lemma":"butiner","pos":"VER"} ,
		{"word":"butiné","word_nosc":"butine","lemma":"butiner","pos":"VER"} ,
		{"word":"butinées","word_nosc":"butinees","lemma":"butiner","pos":"VER"} ,
		{"word":"butinés","word_nosc":"butines","lemma":"butiner","pos":"VER"} ,
		{"word":"butons","word_nosc":"butons","lemma":"buter","pos":"VER"} ,
		{"word":"butte","word_nosc":"butte","lemma":"butter","pos":"VER"} ,
		{"word":"butter","word_nosc":"butter","lemma":"butter","pos":"VER"} ,
		{"word":"buttes","word_nosc":"buttes","lemma":"butter","pos":"VER"} ,
		{"word":"buttèrent","word_nosc":"butterent","lemma":"butter","pos":"VER"} ,
		{"word":"butté","word_nosc":"butte","lemma":"butter","pos":"VER"} ,
		{"word":"buttés","word_nosc":"buttes","lemma":"butter","pos":"VER"} ,
		{"word":"butèrent","word_nosc":"buterent","lemma":"buter","pos":"VER"} ,
		{"word":"buté","word_nosc":"bute","lemma":"buter","pos":"VER"} ,
		{"word":"butée","word_nosc":"butee","lemma":"buter","pos":"VER"} ,
		{"word":"butées","word_nosc":"butees","lemma":"buter","pos":"VER"} ,
		{"word":"butés","word_nosc":"butes","lemma":"buter","pos":"VER"} ,
		{"word":"buvaient","word_nosc":"buvaient","lemma":"boire","pos":"VER"} ,
		{"word":"buvais","word_nosc":"buvais","lemma":"boire","pos":"VER"} ,
		{"word":"buvait","word_nosc":"buvait","lemma":"boire","pos":"VER"} ,
		{"word":"buvant","word_nosc":"buvant","lemma":"boire","pos":"VER"} ,
		{"word":"buvarde","word_nosc":"buvarde","lemma":"buvarder","pos":"VER"} ,
		{"word":"buvardé","word_nosc":"buvarde","lemma":"buvarder","pos":"VER"} ,
		{"word":"buvardés","word_nosc":"buvardes","lemma":"buvarder","pos":"VER"} ,
		{"word":"buvez","word_nosc":"buvez","lemma":"boire","pos":"VER"} ,
		{"word":"buviez","word_nosc":"buviez","lemma":"boire","pos":"VER"} ,
		{"word":"buvions","word_nosc":"buvions","lemma":"boire","pos":"VER"} ,
		{"word":"buvons","word_nosc":"buvons","lemma":"boire","pos":"VER"} ,
		{"word":"bâche","word_nosc":"bache","lemma":"bâcher","pos":"VER"} ,
		{"word":"bâcher","word_nosc":"bacher","lemma":"bâcher","pos":"VER"} ,
		{"word":"bâché","word_nosc":"bache","lemma":"bâcher","pos":"VER"} ,
		{"word":"bâchée","word_nosc":"bachee","lemma":"bâcher","pos":"VER"} ,
		{"word":"bâchées","word_nosc":"bachees","lemma":"bâcher","pos":"VER"} ,
		{"word":"bâcla","word_nosc":"bacla","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâclai","word_nosc":"baclai","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâclaient","word_nosc":"baclaient","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâclais","word_nosc":"baclais","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâclait","word_nosc":"baclait","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâclant","word_nosc":"baclant","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâcle","word_nosc":"bacle","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâcler","word_nosc":"bacler","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâclera","word_nosc":"baclera","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâclerait","word_nosc":"baclerait","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâcles","word_nosc":"bacles","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâclez","word_nosc":"baclez","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâclons","word_nosc":"baclons","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâclé","word_nosc":"bacle","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâclée","word_nosc":"baclee","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâclées","word_nosc":"baclees","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâclés","word_nosc":"bacles","lemma":"bâcler","pos":"VER"} ,
		{"word":"bâfra","word_nosc":"bafra","lemma":"bâfrer","pos":"VER"} ,
		{"word":"bâfraient","word_nosc":"bafraient","lemma":"bâfrer","pos":"VER"} ,
		{"word":"bâfrais","word_nosc":"bafrais","lemma":"bâfrer","pos":"VER"} ,
		{"word":"bâfrait","word_nosc":"bafrait","lemma":"bâfrer","pos":"VER"} ,
		{"word":"bâfrant","word_nosc":"bafrant","lemma":"bâfrer","pos":"VER"} ,
		{"word":"bâfre","word_nosc":"bafre","lemma":"bâfrer","pos":"VER"} ,
		{"word":"bâfrer","word_nosc":"bafrer","lemma":"bâfrer","pos":"VER"} ,
		{"word":"bâfres","word_nosc":"bafres","lemma":"bâfrer","pos":"VER"} ,
		{"word":"bâfrez","word_nosc":"bafrez","lemma":"bâfrer","pos":"VER"} ,
		{"word":"bâfrèrent","word_nosc":"bafrerent","lemma":"bâfrer","pos":"VER"} ,
		{"word":"bâfré","word_nosc":"bafre","lemma":"bâfrer","pos":"VER"} ,
		{"word":"bâilla","word_nosc":"bailla","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâillai","word_nosc":"baillai","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâillaient","word_nosc":"baillaient","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâillais","word_nosc":"baillais","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâillait","word_nosc":"baillait","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâillant","word_nosc":"baillant","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâille","word_nosc":"baille","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâillent","word_nosc":"baillent","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâiller","word_nosc":"bailler","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâillera","word_nosc":"baillera","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâillerez","word_nosc":"baillerez","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâilles","word_nosc":"bailles","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâillez","word_nosc":"baillez","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâillonna","word_nosc":"baillonna","lemma":"bâillonner","pos":"VER"} ,
		{"word":"bâillonnait","word_nosc":"baillonnait","lemma":"bâillonner","pos":"VER"} ,
		{"word":"bâillonnant","word_nosc":"baillonnant","lemma":"bâillonner","pos":"VER"} ,
		{"word":"bâillonne","word_nosc":"baillonne","lemma":"bâillonner","pos":"VER"} ,
		{"word":"bâillonnent","word_nosc":"baillonnent","lemma":"bâillonner","pos":"VER"} ,
		{"word":"bâillonner","word_nosc":"baillonner","lemma":"bâillonner","pos":"VER"} ,
		{"word":"bâillonnera","word_nosc":"baillonnera","lemma":"bâillonner","pos":"VER"} ,
		{"word":"bâillonnerait","word_nosc":"baillonnerait","lemma":"bâillonner","pos":"VER"} ,
		{"word":"bâillonnez","word_nosc":"baillonnez","lemma":"bâillonner","pos":"VER"} ,
		{"word":"bâillonnons","word_nosc":"baillonnons","lemma":"bâillonner","pos":"VER"} ,
		{"word":"bâillonné","word_nosc":"baillonne","lemma":"bâillonner","pos":"VER"} ,
		{"word":"bâillonnée","word_nosc":"baillonnee","lemma":"bâillonner","pos":"VER"} ,
		{"word":"bâillonnés","word_nosc":"baillonnes","lemma":"bâillonner","pos":"VER"} ,
		{"word":"bâillâmes","word_nosc":"baillames","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâillèrent","word_nosc":"baillerent","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâillé","word_nosc":"baille","lemma":"bâiller","pos":"VER"} ,
		{"word":"bâter","word_nosc":"bater","lemma":"bâter","pos":"VER"} ,
		{"word":"bâti","word_nosc":"bati","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtie","word_nosc":"batie","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâties","word_nosc":"baties","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtir","word_nosc":"batir","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtira","word_nosc":"batira","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtirai","word_nosc":"batirai","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtirais","word_nosc":"batirais","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtirait","word_nosc":"batirait","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtirent","word_nosc":"batirent","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtirions","word_nosc":"batirions","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtirons","word_nosc":"batirons","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtiront","word_nosc":"batiront","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtis","word_nosc":"batis","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtissaient","word_nosc":"batissaient","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtissais","word_nosc":"batissais","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtissait","word_nosc":"batissait","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtissant","word_nosc":"batissant","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtisse","word_nosc":"batisse","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtissent","word_nosc":"batissent","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtissez","word_nosc":"batissez","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtissions","word_nosc":"batissions","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtissons","word_nosc":"batissons","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtit","word_nosc":"batit","lemma":"bâtir","pos":"VER"} ,
		{"word":"bâtonnant","word_nosc":"batonnant","lemma":"bâtonner","pos":"VER"} ,
		{"word":"bâtonner","word_nosc":"batonner","lemma":"bâtonner","pos":"VER"} ,
		{"word":"bâtonnée","word_nosc":"batonnee","lemma":"bâtonner","pos":"VER"} ,
		{"word":"bâtons","word_nosc":"batons","lemma":"bâter","pos":"VER"} ,
		{"word":"bâté","word_nosc":"bate","lemma":"bâter","pos":"VER"} ,
		{"word":"bâtés","word_nosc":"bates","lemma":"bâter","pos":"VER"} ,
		{"word":"béa","word_nosc":"bea","lemma":"béer","pos":"VER"} ,
		{"word":"béaient","word_nosc":"beaient","lemma":"béer","pos":"VER"} ,
		{"word":"béait","word_nosc":"beait","lemma":"béer","pos":"VER"} ,
		{"word":"béant","word_nosc":"beant","lemma":"béer","pos":"VER"} ,
		{"word":"béatifier","word_nosc":"beatifier","lemma":"béatifier","pos":"VER"} ,
		{"word":"béatifié","word_nosc":"beatifie","lemma":"béatifier","pos":"VER"} ,
		{"word":"béatifiée","word_nosc":"beatifiee","lemma":"béatifier","pos":"VER"} ,
		{"word":"béatifiés","word_nosc":"beatifies","lemma":"béatifier","pos":"VER"} ,
		{"word":"bécotait","word_nosc":"becotait","lemma":"bécoter","pos":"VER"} ,
		{"word":"bécotant","word_nosc":"becotant","lemma":"bécoter","pos":"VER"} ,
		{"word":"bécote","word_nosc":"becote","lemma":"bécoter","pos":"VER"} ,
		{"word":"bécotent","word_nosc":"becotent","lemma":"bécoter","pos":"VER"} ,
		{"word":"bécoter","word_nosc":"becoter","lemma":"bécoter","pos":"VER"} ,
		{"word":"bécoté","word_nosc":"becote","lemma":"bécoter","pos":"VER"} ,
		{"word":"bécotée","word_nosc":"becotee","lemma":"bécoter","pos":"VER"} ,
		{"word":"bécotés","word_nosc":"becotes","lemma":"bécoter","pos":"VER"} ,
		{"word":"bée","word_nosc":"bee","lemma":"béer","pos":"VER"} ,
		{"word":"béent","word_nosc":"beent","lemma":"béer","pos":"VER"} ,
		{"word":"béer","word_nosc":"beer","lemma":"béer","pos":"VER"} ,
		{"word":"bégaie","word_nosc":"begaie","lemma":"bégayer","pos":"VER"} ,
		{"word":"bégaient","word_nosc":"begaient","lemma":"bégayer","pos":"VER"} ,
		{"word":"bégaiera","word_nosc":"begaiera","lemma":"bégayer","pos":"VER"} ,
		{"word":"bégaies","word_nosc":"begaies","lemma":"bégayer","pos":"VER"} ,
		{"word":"bégaya","word_nosc":"begaya","lemma":"bégayer","pos":"VER"} ,
		{"word":"bégayai","word_nosc":"begayai","lemma":"bégayer","pos":"VER"} ,
		{"word":"bégayaient","word_nosc":"begayaient","lemma":"bégayer","pos":"VER"} ,
		{"word":"bégayais","word_nosc":"begayais","lemma":"bégayer","pos":"VER"} ,
		{"word":"bégayait","word_nosc":"begayait","lemma":"bégayer","pos":"VER"} ,
		{"word":"bégayant","word_nosc":"begayant","lemma":"bégayer","pos":"VER"} ,
		{"word":"bégaye","word_nosc":"begaye","lemma":"bégayer","pos":"VER"} ,
		{"word":"bégayer","word_nosc":"begayer","lemma":"bégayer","pos":"VER"} ,
		{"word":"bégayes","word_nosc":"begayes","lemma":"bégayer","pos":"VER"} ,
		{"word":"bégayez","word_nosc":"begayez","lemma":"bégayer","pos":"VER"} ,
		{"word":"bégayé","word_nosc":"begaye","lemma":"bégayer","pos":"VER"} ,
		{"word":"bégayées","word_nosc":"begayees","lemma":"bégayer","pos":"VER"} ,
		{"word":"bémolisé","word_nosc":"bemolise","lemma":"bémoliser","pos":"VER"} ,
		{"word":"béni","word_nosc":"beni","lemma":"bénir","pos":"VER"} ,
		{"word":"bénie","word_nosc":"benie","lemma":"bénir","pos":"VER"} ,
		{"word":"bénies","word_nosc":"benies","lemma":"bénir","pos":"VER"} ,
		{"word":"bénir","word_nosc":"benir","lemma":"bénir","pos":"VER"} ,
		{"word":"bénira","word_nosc":"benira","lemma":"bénir","pos":"VER"} ,
		{"word":"bénirai","word_nosc":"benirai","lemma":"bénir","pos":"VER"} ,
		{"word":"bénirais","word_nosc":"benirais","lemma":"bénir","pos":"VER"} ,
		{"word":"bénirait","word_nosc":"benirait","lemma":"bénir","pos":"VER"} ,
		{"word":"béniras","word_nosc":"beniras","lemma":"bénir","pos":"VER"} ,
		{"word":"bénirez","word_nosc":"benirez","lemma":"bénir","pos":"VER"} ,
		{"word":"bénirons","word_nosc":"benirons","lemma":"bénir","pos":"VER"} ,
		{"word":"béniront","word_nosc":"beniront","lemma":"bénir","pos":"VER"} ,
		{"word":"bénis","word_nosc":"benis","lemma":"bénir","pos":"VER"} ,
		{"word":"bénissaient","word_nosc":"benissaient","lemma":"bénir","pos":"VER"} ,
		{"word":"bénissais","word_nosc":"benissais","lemma":"bénir","pos":"VER"} ,
		{"word":"bénissait","word_nosc":"benissait","lemma":"bénir","pos":"VER"} ,
		{"word":"bénissant","word_nosc":"benissant","lemma":"bénir","pos":"VER"} ,
		{"word":"bénisse","word_nosc":"benisse","lemma":"bénir","pos":"VER"} ,
		{"word":"bénissent","word_nosc":"benissent","lemma":"bénir","pos":"VER"} ,
		{"word":"bénissez","word_nosc":"benissez","lemma":"bénir","pos":"VER"} ,
		{"word":"bénissions","word_nosc":"benissions","lemma":"bénir","pos":"VER"} ,
		{"word":"bénissons","word_nosc":"benissons","lemma":"bénir","pos":"VER"} ,
		{"word":"bénit","word_nosc":"benit","lemma":"bénir","pos":"VER"} ,
		{"word":"bénéficia","word_nosc":"beneficia","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficiaient","word_nosc":"beneficiaient","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficiais","word_nosc":"beneficiais","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficiait","word_nosc":"beneficiait","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficiant","word_nosc":"beneficiant","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficie","word_nosc":"beneficie","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficient","word_nosc":"beneficient","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficier","word_nosc":"beneficier","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficiera","word_nosc":"beneficiera","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficierai","word_nosc":"beneficierai","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficierais","word_nosc":"beneficierais","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficierait","word_nosc":"beneficierait","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficierez","word_nosc":"beneficierez","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficierons","word_nosc":"beneficierons","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficies","word_nosc":"beneficies","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficiez","word_nosc":"beneficiez","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficions","word_nosc":"beneficions","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficiât","word_nosc":"beneficiat","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficièrent","word_nosc":"beneficierent","lemma":"bénéficier","pos":"VER"} ,
		{"word":"bénéficié","word_nosc":"beneficie","lemma":"bénéficier","pos":"VER"} ,
		{"word":"béquant","word_nosc":"bequant","lemma":"béquer","pos":"VER"} ,
		{"word":"béquillant","word_nosc":"bequillant","lemma":"béquiller","pos":"VER"} ,
		{"word":"béquillent","word_nosc":"bequillent","lemma":"béquiller","pos":"VER"} ,
		{"word":"bétonnaient","word_nosc":"betonnaient","lemma":"bétonner","pos":"VER"} ,
		{"word":"bétonner","word_nosc":"betonner","lemma":"bétonner","pos":"VER"} ,
		{"word":"bétonné","word_nosc":"betonne","lemma":"bétonner","pos":"VER"} ,
		{"word":"bétonnée","word_nosc":"betonnee","lemma":"bétonner","pos":"VER"} ,
		{"word":"bétonnés","word_nosc":"betonnes","lemma":"bétonner","pos":"VER"} ,
		{"word":"béèrent","word_nosc":"beerent","lemma":"béer","pos":"VER"} ,
		{"word":"béé","word_nosc":"bee","lemma":"béer","pos":"VER"} ,
		{"word":"béée","word_nosc":"beee","lemma":"béer","pos":"VER"} ,
		{"word":"bêchaient","word_nosc":"bechaient","lemma":"bêcher","pos":"VER"} ,
		{"word":"bêchait","word_nosc":"bechait","lemma":"bêcher","pos":"VER"} ,
		{"word":"bêchant","word_nosc":"bechant","lemma":"bêcher","pos":"VER"} ,
		{"word":"bêche","word_nosc":"beche","lemma":"bêcher","pos":"VER"} ,
		{"word":"bêchent","word_nosc":"bechent","lemma":"bêcher","pos":"VER"} ,
		{"word":"bêcher","word_nosc":"becher","lemma":"bêcher","pos":"VER"} ,
		{"word":"bêché","word_nosc":"beche","lemma":"bêcher","pos":"VER"} ,
		{"word":"bêlaient","word_nosc":"belaient","lemma":"bêler","pos":"VER"} ,
		{"word":"bêlait","word_nosc":"belait","lemma":"bêler","pos":"VER"} ,
		{"word":"bêlant","word_nosc":"belant","lemma":"bêler","pos":"VER"} ,
		{"word":"bêle","word_nosc":"bele","lemma":"bêler","pos":"VER"} ,
		{"word":"bêlent","word_nosc":"belent","lemma":"bêler","pos":"VER"} ,
		{"word":"bêler","word_nosc":"beler","lemma":"bêler","pos":"VER"} ,
		{"word":"bêlé","word_nosc":"bele","lemma":"bêler","pos":"VER"} ,
		{"word":"bêtifia","word_nosc":"betifia","lemma":"bêtifier","pos":"VER"} ,
		{"word":"bêtifiaient","word_nosc":"betifiaient","lemma":"bêtifier","pos":"VER"} ,
		{"word":"bêtifiais","word_nosc":"betifiais","lemma":"bêtifier","pos":"VER"} ,
		{"word":"bêtifiait","word_nosc":"betifiait","lemma":"bêtifier","pos":"VER"} ,
		{"word":"bêtifie","word_nosc":"betifie","lemma":"bêtifier","pos":"VER"} ,
		{"word":"bêtifier","word_nosc":"betifier","lemma":"bêtifier","pos":"VER"} ,
		{"word":"bêtifié","word_nosc":"betifie","lemma":"bêtifier","pos":"VER"} ,
		{"word":"bûchais","word_nosc":"buchais","lemma":"bûcher","pos":"VER"} ,
		{"word":"bûchant","word_nosc":"buchant","lemma":"bûcher","pos":"VER"} ,
		{"word":"bûche","word_nosc":"buche","lemma":"bûcher","pos":"VER"} ,
		{"word":"bûchent","word_nosc":"buchent","lemma":"bûcher","pos":"VER"} ,
		{"word":"bûcher","word_nosc":"bucher","lemma":"bûcher","pos":"VER"} ,
		{"word":"bûcheronne","word_nosc":"bucheronne","lemma":"bûcheronner","pos":"VER"} ,
		{"word":"bûcheronner","word_nosc":"bucheronner","lemma":"bûcheronner","pos":"VER"} ,
		{"word":"bûcherons","word_nosc":"bucherons","lemma":"bûcher","pos":"VER"} ,
		{"word":"bûches","word_nosc":"buches","lemma":"bûcher","pos":"VER"} ,
		{"word":"bûchez","word_nosc":"buchez","lemma":"bûcher","pos":"VER"} ,
		{"word":"bûché","word_nosc":"buche","lemma":"bûcher","pos":"VER"} ,
		{"word":"bûmes","word_nosc":"bumes","lemma":"boire","pos":"VER"} ,
		{"word":"bût","word_nosc":"but","lemma":"boire","pos":"VER"} ,
		{"word":"cabana","word_nosc":"cabana","lemma":"cabaner","pos":"VER"} ,
		{"word":"cabiner","word_nosc":"cabiner","lemma":"cabiner","pos":"VER"} ,
		{"word":"cabossa","word_nosc":"cabossa","lemma":"cabosser","pos":"VER"} ,
		{"word":"cabossait","word_nosc":"cabossait","lemma":"cabosser","pos":"VER"} ,
		{"word":"cabosse","word_nosc":"cabosse","lemma":"cabosser","pos":"VER"} ,
		{"word":"cabosser","word_nosc":"cabosser","lemma":"cabosser","pos":"VER"} ,
		{"word":"cabossé","word_nosc":"cabosse","lemma":"cabosser","pos":"VER"} ,
		{"word":"cabossée","word_nosc":"cabossee","lemma":"cabosser","pos":"VER"} ,
		{"word":"cabossées","word_nosc":"cabossees","lemma":"cabosser","pos":"VER"} ,
		{"word":"cabossés","word_nosc":"cabosses","lemma":"cabosser","pos":"VER"} ,
		{"word":"cabotaient","word_nosc":"cabotaient","lemma":"caboter","pos":"VER"} ,
		{"word":"cabotait","word_nosc":"cabotait","lemma":"caboter","pos":"VER"} ,
		{"word":"cabote","word_nosc":"cabote","lemma":"caboter","pos":"VER"} ,
		{"word":"caboter","word_nosc":"caboter","lemma":"caboter","pos":"VER"} ,
		{"word":"cabotines","word_nosc":"cabotines","lemma":"cabotiner","pos":"VER"} ,
		{"word":"caboté","word_nosc":"cabote","lemma":"caboter","pos":"VER"} ,
		{"word":"cabra","word_nosc":"cabra","lemma":"cabrer","pos":"VER"} ,
		{"word":"cabraient","word_nosc":"cabraient","lemma":"cabrer","pos":"VER"} ,
		{"word":"cabrais","word_nosc":"cabrais","lemma":"cabrer","pos":"VER"} ,
		{"word":"cabrait","word_nosc":"cabrait","lemma":"cabrer","pos":"VER"} ,
		{"word":"cabrant","word_nosc":"cabrant","lemma":"cabrer","pos":"VER"} ,
		{"word":"cabre","word_nosc":"cabre","lemma":"cabrer","pos":"VER"} ,
		{"word":"cabrent","word_nosc":"cabrent","lemma":"cabrer","pos":"VER"} ,
		{"word":"cabrer","word_nosc":"cabrer","lemma":"cabrer","pos":"VER"} ,
		{"word":"cabrera","word_nosc":"cabrera","lemma":"cabrer","pos":"VER"} ,
		{"word":"cabrerait","word_nosc":"cabrerait","lemma":"cabrer","pos":"VER"} ,
		{"word":"cabriolaient","word_nosc":"cabriolaient","lemma":"cabrioler","pos":"VER"} ,
		{"word":"cabriolait","word_nosc":"cabriolait","lemma":"cabrioler","pos":"VER"} ,
		{"word":"cabriolant","word_nosc":"cabriolant","lemma":"cabrioler","pos":"VER"} ,
		{"word":"cabriole","word_nosc":"cabriole","lemma":"cabrioler","pos":"VER"} ,
		{"word":"cabrioler","word_nosc":"cabrioler","lemma":"cabrioler","pos":"VER"} ,
		{"word":"cabrèrent","word_nosc":"cabrerent","lemma":"cabrer","pos":"VER"} ,
		{"word":"cabré","word_nosc":"cabre","lemma":"cabrer","pos":"VER"} ,
		{"word":"cabrée","word_nosc":"cabree","lemma":"cabrer","pos":"VER"} ,
		{"word":"cabrées","word_nosc":"cabrees","lemma":"cabrer","pos":"VER"} ,
		{"word":"cabrés","word_nosc":"cabres","lemma":"cabrer","pos":"VER"} ,
		{"word":"cacarda","word_nosc":"cacarda","lemma":"cacarder","pos":"VER"} ,
		{"word":"cacardait","word_nosc":"cacardait","lemma":"cacarder","pos":"VER"} ,
		{"word":"cacarde","word_nosc":"cacarde","lemma":"cacarder","pos":"VER"} ,
		{"word":"cacha","word_nosc":"cacha","lemma":"cacher","pos":"VER"} ,
		{"word":"cachai","word_nosc":"cachai","lemma":"cacher","pos":"VER"} ,
		{"word":"cachaient","word_nosc":"cachaient","lemma":"cacher","pos":"VER"} ,
		{"word":"cachais","word_nosc":"cachais","lemma":"cacher","pos":"VER"} ,
		{"word":"cachait","word_nosc":"cachait","lemma":"cacher","pos":"VER"} ,
		{"word":"cachant","word_nosc":"cachant","lemma":"cacher","pos":"VER"} ,
		{"word":"cache","word_nosc":"cache","lemma":"cacher","pos":"VER"} ,
		{"word":"cachent","word_nosc":"cachent","lemma":"cacher","pos":"VER"} ,
		{"word":"cacher","word_nosc":"cacher","lemma":"cacher","pos":"VER"} ,
		{"word":"cachera","word_nosc":"cachera","lemma":"cacher","pos":"VER"} ,
		{"word":"cacherai","word_nosc":"cacherai","lemma":"cacher","pos":"VER"} ,
		{"word":"cacheraient","word_nosc":"cacheraient","lemma":"cacher","pos":"VER"} ,
		{"word":"cacherais","word_nosc":"cacherais","lemma":"cacher","pos":"VER"} ,
		{"word":"cacherait","word_nosc":"cacherait","lemma":"cacher","pos":"VER"} ,
		{"word":"cacheras","word_nosc":"cacheras","lemma":"cacher","pos":"VER"} ,
		{"word":"cacherez","word_nosc":"cacherez","lemma":"cacher","pos":"VER"} ,
		{"word":"cacheriez","word_nosc":"cacheriez","lemma":"cacher","pos":"VER"} ,
		{"word":"cacherons","word_nosc":"cacherons","lemma":"cacher","pos":"VER"} ,
		{"word":"cacheront","word_nosc":"cacheront","lemma":"cacher","pos":"VER"} ,
		{"word":"caches","word_nosc":"caches","lemma":"cacher","pos":"VER"} ,
		{"word":"cacheta","word_nosc":"cacheta","lemma":"cacheter","pos":"VER"} ,
		{"word":"cachetais","word_nosc":"cachetais","lemma":"cacheter","pos":"VER"} ,
		{"word":"cachetait","word_nosc":"cachetait","lemma":"cacheter","pos":"VER"} ,
		{"word":"cacheter","word_nosc":"cacheter","lemma":"cacheter","pos":"VER"} ,
		{"word":"cachetonnais","word_nosc":"cachetonnais","lemma":"cachetonner","pos":"VER"} ,
		{"word":"cachetonnait","word_nosc":"cachetonnait","lemma":"cachetonner","pos":"VER"} ,
		{"word":"cachetonne","word_nosc":"cachetonne","lemma":"cachetonner","pos":"VER"} ,
		{"word":"cachette","word_nosc":"cachette","lemma":"cacheter","pos":"VER"} ,
		{"word":"cachettes","word_nosc":"cachettes","lemma":"cacheter","pos":"VER"} ,
		{"word":"cacheté","word_nosc":"cachete","lemma":"cacheter","pos":"VER"} ,
		{"word":"cachetée","word_nosc":"cachetee","lemma":"cacheter","pos":"VER"} ,
		{"word":"cachetées","word_nosc":"cachetees","lemma":"cacheter","pos":"VER"} ,
		{"word":"cachetés","word_nosc":"cachetes","lemma":"cacheter","pos":"VER"} ,
		{"word":"cachez","word_nosc":"cachez","lemma":"cacher","pos":"VER"} ,
		{"word":"cachiez","word_nosc":"cachiez","lemma":"cacher","pos":"VER"} ,
		{"word":"cachions","word_nosc":"cachions","lemma":"cacher","pos":"VER"} ,
		{"word":"cachons","word_nosc":"cachons","lemma":"cacher","pos":"VER"} ,
		{"word":"cachât","word_nosc":"cachat","lemma":"cacher","pos":"VER"} ,
		{"word":"cachèrent","word_nosc":"cacherent","lemma":"cacher","pos":"VER"} ,
		{"word":"caché","word_nosc":"cache","lemma":"cacher","pos":"VER"} ,
		{"word":"cachée","word_nosc":"cachee","lemma":"cacher","pos":"VER"} ,
		{"word":"cachées","word_nosc":"cachees","lemma":"cacher","pos":"VER"} ,
		{"word":"cachés","word_nosc":"caches","lemma":"cacher","pos":"VER"} ,
		{"word":"cadastré","word_nosc":"cadastre","lemma":"cadastrer","pos":"VER"} ,
		{"word":"cadastrée","word_nosc":"cadastree","lemma":"cadastrer","pos":"VER"} ,
		{"word":"cadavérise","word_nosc":"cadaverise","lemma":"cadavériser","pos":"VER"} ,
		{"word":"cadavérisés","word_nosc":"cadaverises","lemma":"cadavériser","pos":"VER"} ,
		{"word":"cadenassaient","word_nosc":"cadenassaient","lemma":"cadenasser","pos":"VER"} ,
		{"word":"cadenassait","word_nosc":"cadenassait","lemma":"cadenasser","pos":"VER"} ,
		{"word":"cadenassant","word_nosc":"cadenassant","lemma":"cadenasser","pos":"VER"} ,
		{"word":"cadenasse","word_nosc":"cadenasse","lemma":"cadenasser","pos":"VER"} ,
		{"word":"cadenasser","word_nosc":"cadenasser","lemma":"cadenasser","pos":"VER"} ,
		{"word":"cadenassez","word_nosc":"cadenassez","lemma":"cadenasser","pos":"VER"} ,
		{"word":"cadenassé","word_nosc":"cadenasse","lemma":"cadenasser","pos":"VER"} ,
		{"word":"cadenassée","word_nosc":"cadenassee","lemma":"cadenasser","pos":"VER"} ,
		{"word":"cadenassées","word_nosc":"cadenassees","lemma":"cadenasser","pos":"VER"} ,
		{"word":"cadence","word_nosc":"cadence","lemma":"cadencer","pos":"VER"} ,
		{"word":"cadencer","word_nosc":"cadencer","lemma":"cadencer","pos":"VER"} ,
		{"word":"cadencé","word_nosc":"cadence","lemma":"cadencer","pos":"VER"} ,
		{"word":"cadencée","word_nosc":"cadencee","lemma":"cadencer","pos":"VER"} ,
		{"word":"cadencés","word_nosc":"cadences","lemma":"cadencer","pos":"VER"} ,
		{"word":"cadmié","word_nosc":"cadmie","lemma":"cadmier","pos":"VER"} ,
		{"word":"cadrais","word_nosc":"cadrais","lemma":"cadrer","pos":"VER"} ,
		{"word":"cadrait","word_nosc":"cadrait","lemma":"cadrer","pos":"VER"} ,
		{"word":"cadrant","word_nosc":"cadrant","lemma":"cadrer","pos":"VER"} ,
		{"word":"cadre","word_nosc":"cadre","lemma":"cadrer","pos":"VER"} ,
		{"word":"cadrent","word_nosc":"cadrent","lemma":"cadrer","pos":"VER"} ,
		{"word":"cadrer","word_nosc":"cadrer","lemma":"cadrer","pos":"VER"} ,
		{"word":"cadres","word_nosc":"cadres","lemma":"cadrer","pos":"VER"} ,
		{"word":"cadrez","word_nosc":"cadrez","lemma":"cadrer","pos":"VER"} ,
		{"word":"cadré","word_nosc":"cadre","lemma":"cadrer","pos":"VER"} ,
		{"word":"cadrée","word_nosc":"cadree","lemma":"cadrer","pos":"VER"} ,
		{"word":"cafarde","word_nosc":"cafarde","lemma":"cafarder","pos":"VER"} ,
		{"word":"cafarder","word_nosc":"cafarder","lemma":"cafarder","pos":"VER"} ,
		{"word":"cafarderait","word_nosc":"cafarderait","lemma":"cafarder","pos":"VER"} ,
		{"word":"cafetais","word_nosc":"cafetais","lemma":"cafeter","pos":"VER"} ,
		{"word":"cafeter","word_nosc":"cafeter","lemma":"cafeter","pos":"VER"} ,
		{"word":"cafeté","word_nosc":"cafete","lemma":"cafeter","pos":"VER"} ,
		{"word":"cafouilla","word_nosc":"cafouilla","lemma":"cafouiller","pos":"VER"} ,
		{"word":"cafouillait","word_nosc":"cafouillait","lemma":"cafouiller","pos":"VER"} ,
		{"word":"cafouillant","word_nosc":"cafouillant","lemma":"cafouiller","pos":"VER"} ,
		{"word":"cafouille","word_nosc":"cafouille","lemma":"cafouiller","pos":"VER"} ,
		{"word":"cafouillent","word_nosc":"cafouillent","lemma":"cafouiller","pos":"VER"} ,
		{"word":"cafouiller","word_nosc":"cafouiller","lemma":"cafouiller","pos":"VER"} ,
		{"word":"cafouilles","word_nosc":"cafouilles","lemma":"cafouiller","pos":"VER"} ,
		{"word":"cafouillé","word_nosc":"cafouille","lemma":"cafouiller","pos":"VER"} ,
		{"word":"caftaient","word_nosc":"caftaient","lemma":"cafter","pos":"VER"} ,
		{"word":"caftait","word_nosc":"caftait","lemma":"cafter","pos":"VER"} ,
		{"word":"cafte","word_nosc":"cafte","lemma":"cafter","pos":"VER"} ,
		{"word":"cafter","word_nosc":"cafter","lemma":"cafter","pos":"VER"} ,
		{"word":"cafterai","word_nosc":"cafterai","lemma":"cafter","pos":"VER"} ,
		{"word":"cafterait","word_nosc":"cafterait","lemma":"cafter","pos":"VER"} ,
		{"word":"cafteras","word_nosc":"cafteras","lemma":"cafter","pos":"VER"} ,
		{"word":"caftes","word_nosc":"caftes","lemma":"cafter","pos":"VER"} ,
		{"word":"cafté","word_nosc":"cafte","lemma":"cafter","pos":"VER"} ,
		{"word":"caftés","word_nosc":"caftes","lemma":"cafter","pos":"VER"} ,
		{"word":"caguait","word_nosc":"caguait","lemma":"caguer","pos":"VER"} ,
		{"word":"cague","word_nosc":"cague","lemma":"caguer","pos":"VER"} ,
		{"word":"caguer","word_nosc":"caguer","lemma":"caguer","pos":"VER"} ,
		{"word":"cahota","word_nosc":"cahota","lemma":"cahoter","pos":"VER"} ,
		{"word":"cahotait","word_nosc":"cahotait","lemma":"cahoter","pos":"VER"} ,
		{"word":"cahotant","word_nosc":"cahotant","lemma":"cahoter","pos":"VER"} ,
		{"word":"cahote","word_nosc":"cahote","lemma":"cahoter","pos":"VER"} ,
		{"word":"cahotent","word_nosc":"cahotent","lemma":"cahoter","pos":"VER"} ,
		{"word":"cahoter","word_nosc":"cahoter","lemma":"cahoter","pos":"VER"} ,
		{"word":"cahotons","word_nosc":"cahotons","lemma":"cahoter","pos":"VER"} ,
		{"word":"cahotèrent","word_nosc":"cahoterent","lemma":"cahoter","pos":"VER"} ,
		{"word":"cahoté","word_nosc":"cahote","lemma":"cahoter","pos":"VER"} ,
		{"word":"cahotée","word_nosc":"cahotee","lemma":"cahoter","pos":"VER"} ,
		{"word":"cahotées","word_nosc":"cahotees","lemma":"cahoter","pos":"VER"} ,
		{"word":"cahotés","word_nosc":"cahotes","lemma":"cahoter","pos":"VER"} ,
		{"word":"cailla","word_nosc":"cailla","lemma":"cailler","pos":"VER"} ,
		{"word":"caillaient","word_nosc":"caillaient","lemma":"cailler","pos":"VER"} ,
		{"word":"caillais","word_nosc":"caillais","lemma":"cailler","pos":"VER"} ,
		{"word":"caillait","word_nosc":"caillait","lemma":"cailler","pos":"VER"} ,
		{"word":"caillant","word_nosc":"caillant","lemma":"cailler","pos":"VER"} ,
		{"word":"caillasser","word_nosc":"caillasser","lemma":"caillasser","pos":"VER"} ,
		{"word":"caille","word_nosc":"caille","lemma":"cailler","pos":"VER"} ,
		{"word":"caillent","word_nosc":"caillent","lemma":"cailler","pos":"VER"} ,
		{"word":"cailler","word_nosc":"cailler","lemma":"cailler","pos":"VER"} ,
		{"word":"caillerait","word_nosc":"caillerait","lemma":"cailler","pos":"VER"} ,
		{"word":"caillerez","word_nosc":"caillerez","lemma":"cailler","pos":"VER"} ,
		{"word":"cailles","word_nosc":"cailles","lemma":"cailler","pos":"VER"} ,
		{"word":"caillouté","word_nosc":"cailloute","lemma":"caillouter","pos":"VER"} ,
		{"word":"caillé","word_nosc":"caille","lemma":"cailler","pos":"VER"} ,
		{"word":"caillée","word_nosc":"caillee","lemma":"cailler","pos":"VER"} ,
		{"word":"caillées","word_nosc":"caillees","lemma":"cailler","pos":"VER"} ,
		{"word":"cajola","word_nosc":"cajola","lemma":"cajoler","pos":"VER"} ,
		{"word":"cajolaient","word_nosc":"cajolaient","lemma":"cajoler","pos":"VER"} ,
		{"word":"cajolait","word_nosc":"cajolait","lemma":"cajoler","pos":"VER"} ,
		{"word":"cajolant","word_nosc":"cajolant","lemma":"cajoler","pos":"VER"} ,
		{"word":"cajole","word_nosc":"cajole","lemma":"cajoler","pos":"VER"} ,
		{"word":"cajolent","word_nosc":"cajolent","lemma":"cajoler","pos":"VER"} ,
		{"word":"cajoler","word_nosc":"cajoler","lemma":"cajoler","pos":"VER"} ,
		{"word":"cajolera","word_nosc":"cajolera","lemma":"cajoler","pos":"VER"} ,
		{"word":"cajolerai","word_nosc":"cajolerai","lemma":"cajoler","pos":"VER"} ,
		{"word":"cajolerait","word_nosc":"cajolerait","lemma":"cajoler","pos":"VER"} ,
		{"word":"cajolèrent","word_nosc":"cajolerent","lemma":"cajoler","pos":"VER"} ,
		{"word":"cajolé","word_nosc":"cajole","lemma":"cajoler","pos":"VER"} ,
		{"word":"cajolée","word_nosc":"cajolee","lemma":"cajoler","pos":"VER"} ,
		{"word":"cajolés","word_nosc":"cajoles","lemma":"cajoler","pos":"VER"} ,
		{"word":"cala","word_nosc":"cala","lemma":"caler","pos":"VER"} ,
		{"word":"calai","word_nosc":"calai","lemma":"caler","pos":"VER"} ,
		{"word":"calaient","word_nosc":"calaient","lemma":"caler","pos":"VER"} ,
		{"word":"calais","word_nosc":"calais","lemma":"caler","pos":"VER"} ,
		{"word":"calait","word_nosc":"calait","lemma":"caler","pos":"VER"} ,
		{"word":"calamistre","word_nosc":"calamistre","lemma":"calamistrer","pos":"VER"} ,
		{"word":"calamita","word_nosc":"calamita","lemma":"calamiter","pos":"VER"} ,
		{"word":"calamitait","word_nosc":"calamitait","lemma":"calamiter","pos":"VER"} ,
		{"word":"calamité","word_nosc":"calamite","lemma":"calamiter","pos":"VER"} ,
		{"word":"calanchaient","word_nosc":"calanchaient","lemma":"calancher","pos":"VER"} ,
		{"word":"calanchais","word_nosc":"calanchais","lemma":"calancher","pos":"VER"} ,
		{"word":"calanche","word_nosc":"calanche","lemma":"calancher","pos":"VER"} ,
		{"word":"calancher","word_nosc":"calancher","lemma":"calancher","pos":"VER"} ,
		{"word":"calanché","word_nosc":"calanche","lemma":"calancher","pos":"VER"} ,
		{"word":"calandre","word_nosc":"calandre","lemma":"calandrer","pos":"VER"} ,
		{"word":"calant","word_nosc":"calant","lemma":"caler","pos":"VER"} ,
		{"word":"calce","word_nosc":"calce","lemma":"calcer","pos":"VER"} ,
		{"word":"calcer","word_nosc":"calcer","lemma":"calcer","pos":"VER"} ,
		{"word":"calcerais","word_nosc":"calcerais","lemma":"calcer","pos":"VER"} ,
		{"word":"calcifié","word_nosc":"calcifie","lemma":"calcifier","pos":"VER"} ,
		{"word":"calcifiée","word_nosc":"calcifiee","lemma":"calcifier","pos":"VER"} ,
		{"word":"calcinaient","word_nosc":"calcinaient","lemma":"calciner","pos":"VER"} ,
		{"word":"calcinait","word_nosc":"calcinait","lemma":"calciner","pos":"VER"} ,
		{"word":"calcinant","word_nosc":"calcinant","lemma":"calciner","pos":"VER"} ,
		{"word":"calcine","word_nosc":"calcine","lemma":"calciner","pos":"VER"} ,
		{"word":"calciner","word_nosc":"calciner","lemma":"calciner","pos":"VER"} ,
		{"word":"calciné","word_nosc":"calcine","lemma":"calciner","pos":"VER"} ,
		{"word":"calcinée","word_nosc":"calcinee","lemma":"calciner","pos":"VER"} ,
		{"word":"calcinées","word_nosc":"calcinees","lemma":"calciner","pos":"VER"} ,
		{"word":"calcinés","word_nosc":"calcines","lemma":"calciner","pos":"VER"} ,
		{"word":"calcula","word_nosc":"calcula","lemma":"calculer","pos":"VER"} ,
		{"word":"calculai","word_nosc":"calculai","lemma":"calculer","pos":"VER"} ,
		{"word":"calculaient","word_nosc":"calculaient","lemma":"calculer","pos":"VER"} ,
		{"word":"calculais","word_nosc":"calculais","lemma":"calculer","pos":"VER"} ,
		{"word":"calculait","word_nosc":"calculait","lemma":"calculer","pos":"VER"} ,
		{"word":"calculant","word_nosc":"calculant","lemma":"calculer","pos":"VER"} ,
		{"word":"calcule","word_nosc":"calcule","lemma":"calculer","pos":"VER"} ,
		{"word":"calculent","word_nosc":"calculent","lemma":"calculer","pos":"VER"} ,
		{"word":"calculer","word_nosc":"calculer","lemma":"calculer","pos":"VER"} ,
		{"word":"calculera","word_nosc":"calculera","lemma":"calculer","pos":"VER"} ,
		{"word":"calculerai","word_nosc":"calculerai","lemma":"calculer","pos":"VER"} ,
		{"word":"calculerait","word_nosc":"calculerait","lemma":"calculer","pos":"VER"} ,
		{"word":"calcules","word_nosc":"calcules","lemma":"calculer","pos":"VER"} ,
		{"word":"calculez","word_nosc":"calculez","lemma":"calculer","pos":"VER"} ,
		{"word":"calculiez","word_nosc":"calculiez","lemma":"calculer","pos":"VER"} ,
		{"word":"calculons","word_nosc":"calculons","lemma":"calculer","pos":"VER"} ,
		{"word":"calculèrent","word_nosc":"calculerent","lemma":"calculer","pos":"VER"} ,
		{"word":"calculé","word_nosc":"calcule","lemma":"calculer","pos":"VER"} ,
		{"word":"calculée","word_nosc":"calculee","lemma":"calculer","pos":"VER"} ,
		{"word":"calculées","word_nosc":"calculees","lemma":"calculer","pos":"VER"} ,
		{"word":"calculés","word_nosc":"calcules","lemma":"calculer","pos":"VER"} ,
		{"word":"cale","word_nosc":"cale","lemma":"caler","pos":"VER"} ,
		{"word":"calenché","word_nosc":"calenche","lemma":"calencher","pos":"VER"} ,
		{"word":"calent","word_nosc":"calent","lemma":"caler","pos":"VER"} ,
		{"word":"caler","word_nosc":"caler","lemma":"caler","pos":"VER"} ,
		{"word":"calerai","word_nosc":"calerai","lemma":"caler","pos":"VER"} ,
		{"word":"calerait","word_nosc":"calerait","lemma":"caler","pos":"VER"} ,
		{"word":"caleras","word_nosc":"caleras","lemma":"caler","pos":"VER"} ,
		{"word":"cales","word_nosc":"cales","lemma":"caler","pos":"VER"} ,
		{"word":"calez","word_nosc":"calez","lemma":"caler","pos":"VER"} ,
		{"word":"calfatait","word_nosc":"calfatait","lemma":"calfater","pos":"VER"} ,
		{"word":"calfatant","word_nosc":"calfatant","lemma":"calfater","pos":"VER"} ,
		{"word":"calfate","word_nosc":"calfate","lemma":"calfater","pos":"VER"} ,
		{"word":"calfater","word_nosc":"calfater","lemma":"calfater","pos":"VER"} ,
		{"word":"calfaté","word_nosc":"calfate","lemma":"calfater","pos":"VER"} ,
		{"word":"calfatée","word_nosc":"calfatee","lemma":"calfater","pos":"VER"} ,
		{"word":"calfatés","word_nosc":"calfates","lemma":"calfater","pos":"VER"} ,
		{"word":"calfeutra","word_nosc":"calfeutra","lemma":"calfeutrer","pos":"VER"} ,
		{"word":"calfeutrai","word_nosc":"calfeutrai","lemma":"calfeutrer","pos":"VER"} ,
		{"word":"calfeutraient","word_nosc":"calfeutraient","lemma":"calfeutrer","pos":"VER"} ,
		{"word":"calfeutrais","word_nosc":"calfeutrais","lemma":"calfeutrer","pos":"VER"} ,
		{"word":"calfeutrait","word_nosc":"calfeutrait","lemma":"calfeutrer","pos":"VER"} ,
		{"word":"calfeutrant","word_nosc":"calfeutrant","lemma":"calfeutrer","pos":"VER"} ,
		{"word":"calfeutre","word_nosc":"calfeutre","lemma":"calfeutrer","pos":"VER"} ,
		{"word":"calfeutrer","word_nosc":"calfeutrer","lemma":"calfeutrer","pos":"VER"} ,
		{"word":"calfeutres","word_nosc":"calfeutres","lemma":"calfeutrer","pos":"VER"} ,
		{"word":"calfeutré","word_nosc":"calfeutre","lemma":"calfeutrer","pos":"VER"} ,
		{"word":"calfeutrée","word_nosc":"calfeutree","lemma":"calfeutrer","pos":"VER"} ,
		{"word":"calfeutrées","word_nosc":"calfeutrees","lemma":"calfeutrer","pos":"VER"} ,
		{"word":"calfeutrés","word_nosc":"calfeutres","lemma":"calfeutrer","pos":"VER"} ,
		{"word":"calibre","word_nosc":"calibre","lemma":"calibrer","pos":"VER"} ,
		{"word":"calibrer","word_nosc":"calibrer","lemma":"calibrer","pos":"VER"} ,
		{"word":"calibrez","word_nosc":"calibrez","lemma":"calibrer","pos":"VER"} ,
		{"word":"calibré","word_nosc":"calibre","lemma":"calibrer","pos":"VER"} ,
		{"word":"calibrée","word_nosc":"calibree","lemma":"calibrer","pos":"VER"} ,
		{"word":"calibrées","word_nosc":"calibrees","lemma":"calibrer","pos":"VER"} ,
		{"word":"calibrés","word_nosc":"calibres","lemma":"calibrer","pos":"VER"} ,
		{"word":"calla","word_nosc":"calla","lemma":"caller","pos":"VER"} ,
		{"word":"calle","word_nosc":"calle","lemma":"caller","pos":"VER"} ,
		{"word":"caller","word_nosc":"caller","lemma":"caller","pos":"VER"} ,
		{"word":"calles","word_nosc":"calles","lemma":"caller","pos":"VER"} ,
		{"word":"calligraphiais","word_nosc":"calligraphiais","lemma":"calligraphier","pos":"VER"} ,
		{"word":"calligraphiait","word_nosc":"calligraphiait","lemma":"calligraphier","pos":"VER"} ,
		{"word":"calligraphiant","word_nosc":"calligraphiant","lemma":"calligraphier","pos":"VER"} ,
		{"word":"calligraphie","word_nosc":"calligraphie","lemma":"calligraphier","pos":"VER"} ,
		{"word":"calligraphient","word_nosc":"calligraphient","lemma":"calligraphier","pos":"VER"} ,
		{"word":"calligraphier","word_nosc":"calligraphier","lemma":"calligraphier","pos":"VER"} ,
		{"word":"calligraphiez","word_nosc":"calligraphiez","lemma":"calligraphier","pos":"VER"} ,
		{"word":"calligraphié","word_nosc":"calligraphie","lemma":"calligraphier","pos":"VER"} ,
		{"word":"calligraphiée","word_nosc":"calligraphiee","lemma":"calligraphier","pos":"VER"} ,
		{"word":"calligraphiées","word_nosc":"calligraphiees","lemma":"calligraphier","pos":"VER"} ,
		{"word":"calligraphiés","word_nosc":"calligraphies","lemma":"calligraphier","pos":"VER"} ,
		{"word":"calma","word_nosc":"calma","lemma":"calmer","pos":"VER"} ,
		{"word":"calmai","word_nosc":"calmai","lemma":"calmer","pos":"VER"} ,
		{"word":"calmaient","word_nosc":"calmaient","lemma":"calmer","pos":"VER"} ,
		{"word":"calmais","word_nosc":"calmais","lemma":"calmer","pos":"VER"} ,
		{"word":"calmait","word_nosc":"calmait","lemma":"calmer","pos":"VER"} ,
		{"word":"calmant","word_nosc":"calmant","lemma":"calmer","pos":"VER"} ,
		{"word":"calmas","word_nosc":"calmas","lemma":"calmer","pos":"VER"} ,
		{"word":"calme","word_nosc":"calme","lemma":"calmer","pos":"VER"} ,
		{"word":"calment","word_nosc":"calment","lemma":"calmer","pos":"VER"} ,
		{"word":"calmer","word_nosc":"calmer","lemma":"calmer","pos":"VER"} ,
		{"word":"calmera","word_nosc":"calmera","lemma":"calmer","pos":"VER"} ,
		{"word":"calmerai","word_nosc":"calmerai","lemma":"calmer","pos":"VER"} ,
		{"word":"calmeraient","word_nosc":"calmeraient","lemma":"calmer","pos":"VER"} ,
		{"word":"calmerais","word_nosc":"calmerais","lemma":"calmer","pos":"VER"} ,
		{"word":"calmerait","word_nosc":"calmerait","lemma":"calmer","pos":"VER"} ,
		{"word":"calmeras","word_nosc":"calmeras","lemma":"calmer","pos":"VER"} ,
		{"word":"calmerez","word_nosc":"calmerez","lemma":"calmer","pos":"VER"} ,
		{"word":"calmerons","word_nosc":"calmerons","lemma":"calmer","pos":"VER"} ,
		{"word":"calmeront","word_nosc":"calmeront","lemma":"calmer","pos":"VER"} ,
		{"word":"calmes","word_nosc":"calmes","lemma":"calmer","pos":"VER"} ,
		{"word":"calmez","word_nosc":"calmez","lemma":"calmer","pos":"VER"} ,
		{"word":"calmi","word_nosc":"calmi","lemma":"calmir","pos":"VER"} ,
		{"word":"calmiez","word_nosc":"calmiez","lemma":"calmer","pos":"VER"} ,
		{"word":"calmir","word_nosc":"calmir","lemma":"calmir","pos":"VER"} ,
		{"word":"calmons","word_nosc":"calmons","lemma":"calmer","pos":"VER"} ,
		{"word":"calmât","word_nosc":"calmat","lemma":"calmer","pos":"VER"} ,
		{"word":"calmèrent","word_nosc":"calmerent","lemma":"calmer","pos":"VER"} ,
		{"word":"calmé","word_nosc":"calme","lemma":"calmer","pos":"VER"} ,
		{"word":"calmée","word_nosc":"calmee","lemma":"calmer","pos":"VER"} ,
		{"word":"calmées","word_nosc":"calmees","lemma":"calmer","pos":"VER"} ,
		{"word":"calmés","word_nosc":"calmes","lemma":"calmer","pos":"VER"} ,
		{"word":"calomniant","word_nosc":"calomniant","lemma":"calomnier","pos":"VER"} ,
		{"word":"calomnie","word_nosc":"calomnie","lemma":"calomnier","pos":"VER"} ,
		{"word":"calomnient","word_nosc":"calomnient","lemma":"calomnier","pos":"VER"} ,
		{"word":"calomnier","word_nosc":"calomnier","lemma":"calomnier","pos":"VER"} ,
		{"word":"calomnies","word_nosc":"calomnies","lemma":"calomnier","pos":"VER"} ,
		{"word":"calomniez","word_nosc":"calomniez","lemma":"calomnier","pos":"VER"} ,
		{"word":"calomnié","word_nosc":"calomnie","lemma":"calomnier","pos":"VER"} ,
		{"word":"calomniée","word_nosc":"calomniee","lemma":"calomnier","pos":"VER"} ,
		{"word":"calomniés","word_nosc":"calomnies","lemma":"calomnier","pos":"VER"} ,
		{"word":"calottais","word_nosc":"calottais","lemma":"calotter","pos":"VER"} ,
		{"word":"calotte","word_nosc":"calotte","lemma":"calotter","pos":"VER"} ,
		{"word":"calottes","word_nosc":"calottes","lemma":"calotter","pos":"VER"} ,
		{"word":"calotté","word_nosc":"calotte","lemma":"calotter","pos":"VER"} ,
		{"word":"calottées","word_nosc":"calottees","lemma":"calotter","pos":"VER"} ,
		{"word":"calottés","word_nosc":"calottes","lemma":"calotter","pos":"VER"} ,
		{"word":"calquaient","word_nosc":"calquaient","lemma":"calquer","pos":"VER"} ,
		{"word":"calquait","word_nosc":"calquait","lemma":"calquer","pos":"VER"} ,
		{"word":"calquant","word_nosc":"calquant","lemma":"calquer","pos":"VER"} ,
		{"word":"calque","word_nosc":"calque","lemma":"calquer","pos":"VER"} ,
		{"word":"calquer","word_nosc":"calquer","lemma":"calquer","pos":"VER"} ,
		{"word":"calquera","word_nosc":"calquera","lemma":"calquer","pos":"VER"} ,
		{"word":"calqué","word_nosc":"calque","lemma":"calquer","pos":"VER"} ,
		{"word":"calquée","word_nosc":"calquee","lemma":"calquer","pos":"VER"} ,
		{"word":"calquées","word_nosc":"calquees","lemma":"calquer","pos":"VER"} ,
		{"word":"calte","word_nosc":"calte","lemma":"calter","pos":"VER"} ,
		{"word":"caltent","word_nosc":"caltent","lemma":"calter","pos":"VER"} ,
		{"word":"calter","word_nosc":"calter","lemma":"calter","pos":"VER"} ,
		{"word":"caltez","word_nosc":"caltez","lemma":"calter","pos":"VER"} ,
		{"word":"caltons","word_nosc":"caltons","lemma":"calter","pos":"VER"} ,
		{"word":"caltés","word_nosc":"caltes","lemma":"calter","pos":"VER"} ,
		{"word":"calèrent","word_nosc":"calerent","lemma":"caler","pos":"VER"} ,
		{"word":"calé","word_nosc":"cale","lemma":"caler","pos":"VER"} ,
		{"word":"calée","word_nosc":"calee","lemma":"caler","pos":"VER"} ,
		{"word":"calées","word_nosc":"calees","lemma":"caler","pos":"VER"} ,
		{"word":"calés","word_nosc":"cales","lemma":"caler","pos":"VER"} ,
		{"word":"cama","word_nosc":"cama","lemma":"camer","pos":"VER"} ,
		{"word":"camber","word_nosc":"camber","lemma":"camber","pos":"VER"} ,
		{"word":"cambra","word_nosc":"cambra","lemma":"cambrer","pos":"VER"} ,
		{"word":"cambrait","word_nosc":"cambrait","lemma":"cambrer","pos":"VER"} ,
		{"word":"cambrant","word_nosc":"cambrant","lemma":"cambrer","pos":"VER"} ,
		{"word":"cambre","word_nosc":"cambre","lemma":"cambrer","pos":"VER"} ,
		{"word":"cambrent","word_nosc":"cambrent","lemma":"cambrer","pos":"VER"} ,
		{"word":"cambrer","word_nosc":"cambrer","lemma":"cambrer","pos":"VER"} ,
		{"word":"cambrera","word_nosc":"cambrera","lemma":"cambrer","pos":"VER"} ,
		{"word":"cambres","word_nosc":"cambres","lemma":"cambrer","pos":"VER"} ,
		{"word":"cambrez","word_nosc":"cambrez","lemma":"cambrer","pos":"VER"} ,
		{"word":"cambriolaient","word_nosc":"cambriolaient","lemma":"cambrioler","pos":"VER"} ,
		{"word":"cambriolais","word_nosc":"cambriolais","lemma":"cambrioler","pos":"VER"} ,
		{"word":"cambriolant","word_nosc":"cambriolant","lemma":"cambrioler","pos":"VER"} ,
		{"word":"cambriole","word_nosc":"cambriole","lemma":"cambrioler","pos":"VER"} ,
		{"word":"cambriolent","word_nosc":"cambriolent","lemma":"cambrioler","pos":"VER"} ,
		{"word":"cambrioler","word_nosc":"cambrioler","lemma":"cambrioler","pos":"VER"} ,
		{"word":"cambrioles","word_nosc":"cambrioles","lemma":"cambrioler","pos":"VER"} ,
		{"word":"cambriolez","word_nosc":"cambriolez","lemma":"cambrioler","pos":"VER"} ,
		{"word":"cambrioliez","word_nosc":"cambrioliez","lemma":"cambrioler","pos":"VER"} ,
		{"word":"cambriolons","word_nosc":"cambriolons","lemma":"cambrioler","pos":"VER"} ,
		{"word":"cambriolé","word_nosc":"cambriole","lemma":"cambrioler","pos":"VER"} ,
		{"word":"cambriolée","word_nosc":"cambriolee","lemma":"cambrioler","pos":"VER"} ,
		{"word":"cambriolées","word_nosc":"cambriolees","lemma":"cambrioler","pos":"VER"} ,
		{"word":"cambriolés","word_nosc":"cambrioles","lemma":"cambrioler","pos":"VER"} ,
		{"word":"cambré","word_nosc":"cambre","lemma":"cambrer","pos":"VER"} ,
		{"word":"cambrée","word_nosc":"cambree","lemma":"cambrer","pos":"VER"} ,
		{"word":"cambrées","word_nosc":"cambrees","lemma":"cambrer","pos":"VER"} ,
		{"word":"cambrés","word_nosc":"cambres","lemma":"cambrer","pos":"VER"} ,
		{"word":"cambute","word_nosc":"cambute","lemma":"cambuter","pos":"VER"} ,
		{"word":"cambuté","word_nosc":"cambute","lemma":"cambuter","pos":"VER"} ,
		{"word":"cament","word_nosc":"cament","lemma":"camer","pos":"VER"} ,
		{"word":"camer","word_nosc":"camer","lemma":"camer","pos":"VER"} ,
		{"word":"camera","word_nosc":"camera","lemma":"camer","pos":"VER"} ,
		{"word":"cameras","word_nosc":"cameras","lemma":"camer","pos":"VER"} ,
		{"word":"camez","word_nosc":"camez","lemma":"camer","pos":"VER"} ,
		{"word":"camionnée","word_nosc":"camionnee","lemma":"camionner","pos":"VER"} ,
		{"word":"camoufla","word_nosc":"camoufla","lemma":"camoufler","pos":"VER"} ,
		{"word":"camouflaient","word_nosc":"camouflaient","lemma":"camoufler","pos":"VER"} ,
		{"word":"camouflais","word_nosc":"camouflais","lemma":"camoufler","pos":"VER"} ,
		{"word":"camouflait","word_nosc":"camouflait","lemma":"camoufler","pos":"VER"} ,
		{"word":"camouflant","word_nosc":"camouflant","lemma":"camoufler","pos":"VER"} ,
		{"word":"camoufle","word_nosc":"camoufle","lemma":"camoufler","pos":"VER"} ,
		{"word":"camouflent","word_nosc":"camouflent","lemma":"camoufler","pos":"VER"} ,
		{"word":"camoufler","word_nosc":"camoufler","lemma":"camoufler","pos":"VER"} ,
		{"word":"camouflerait","word_nosc":"camouflerait","lemma":"camoufler","pos":"VER"} ,
		{"word":"camouflez","word_nosc":"camouflez","lemma":"camoufler","pos":"VER"} ,
		{"word":"camouflé","word_nosc":"camoufle","lemma":"camoufler","pos":"VER"} ,
		{"word":"camouflée","word_nosc":"camouflee","lemma":"camoufler","pos":"VER"} ,
		{"word":"camouflées","word_nosc":"camouflees","lemma":"camoufler","pos":"VER"} ,
		{"word":"camouflés","word_nosc":"camoufles","lemma":"camoufler","pos":"VER"} ,
		{"word":"campa","word_nosc":"campa","lemma":"camper","pos":"VER"} ,
		{"word":"campaient","word_nosc":"campaient","lemma":"camper","pos":"VER"} ,
		{"word":"campais","word_nosc":"campais","lemma":"camper","pos":"VER"} ,
		{"word":"campait","word_nosc":"campait","lemma":"camper","pos":"VER"} ,
		{"word":"campant","word_nosc":"campant","lemma":"camper","pos":"VER"} ,
		{"word":"campe","word_nosc":"campe","lemma":"camper","pos":"VER"} ,
		{"word":"campent","word_nosc":"campent","lemma":"camper","pos":"VER"} ,
		{"word":"camper","word_nosc":"camper","lemma":"camper","pos":"VER"} ,
		{"word":"campera","word_nosc":"campera","lemma":"camper","pos":"VER"} ,
		{"word":"camperai","word_nosc":"camperai","lemma":"camper","pos":"VER"} ,
		{"word":"camperait","word_nosc":"camperait","lemma":"camper","pos":"VER"} ,
		{"word":"camperons","word_nosc":"camperons","lemma":"camper","pos":"VER"} ,
		{"word":"campes","word_nosc":"campes","lemma":"camper","pos":"VER"} ,
		{"word":"campez","word_nosc":"campez","lemma":"camper","pos":"VER"} ,
		{"word":"camphrée","word_nosc":"camphree","lemma":"camphrer","pos":"VER"} ,
		{"word":"campions","word_nosc":"campions","lemma":"camper","pos":"VER"} ,
		{"word":"campons","word_nosc":"campons","lemma":"camper","pos":"VER"} ,
		{"word":"campâmes","word_nosc":"campames","lemma":"camper","pos":"VER"} ,
		{"word":"campèrent","word_nosc":"camperent","lemma":"camper","pos":"VER"} ,
		{"word":"campé","word_nosc":"campe","lemma":"camper","pos":"VER"} ,
		{"word":"campée","word_nosc":"campee","lemma":"camper","pos":"VER"} ,
		{"word":"campées","word_nosc":"campees","lemma":"camper","pos":"VER"} ,
		{"word":"campés","word_nosc":"campes","lemma":"camper","pos":"VER"} ,
		{"word":"camé","word_nosc":"came","lemma":"camer","pos":"VER"} ,
		{"word":"camée","word_nosc":"camee","lemma":"camer","pos":"VER"} ,
		{"word":"camés","word_nosc":"cames","lemma":"camer","pos":"VER"} ,
		{"word":"cana","word_nosc":"cana","lemma":"caner","pos":"VER"} ,
		{"word":"canalisa","word_nosc":"canalisa","lemma":"canaliser","pos":"VER"} ,
		{"word":"canalisaient","word_nosc":"canalisaient","lemma":"canaliser","pos":"VER"} ,
		{"word":"canalisait","word_nosc":"canalisait","lemma":"canaliser","pos":"VER"} ,
		{"word":"canalise","word_nosc":"canalise","lemma":"canaliser","pos":"VER"} ,
		{"word":"canalisent","word_nosc":"canalisent","lemma":"canaliser","pos":"VER"} ,
		{"word":"canaliser","word_nosc":"canaliser","lemma":"canaliser","pos":"VER"} ,
		{"word":"canalisez","word_nosc":"canalisez","lemma":"canaliser","pos":"VER"} ,
		{"word":"canalisons","word_nosc":"canalisons","lemma":"canaliser","pos":"VER"} ,
		{"word":"canalisé","word_nosc":"canalise","lemma":"canaliser","pos":"VER"} ,
		{"word":"canalisée","word_nosc":"canalisee","lemma":"canaliser","pos":"VER"} ,
		{"word":"canalisées","word_nosc":"canalisees","lemma":"canaliser","pos":"VER"} ,
		{"word":"canalisés","word_nosc":"canalises","lemma":"canaliser","pos":"VER"} ,
		{"word":"canardaient","word_nosc":"canardaient","lemma":"canarder","pos":"VER"} ,
		{"word":"canardait","word_nosc":"canardait","lemma":"canarder","pos":"VER"} ,
		{"word":"canarde","word_nosc":"canarde","lemma":"canarder","pos":"VER"} ,
		{"word":"canardent","word_nosc":"canardent","lemma":"canarder","pos":"VER"} ,
		{"word":"canarder","word_nosc":"canarder","lemma":"canarder","pos":"VER"} ,
		{"word":"canardera","word_nosc":"canardera","lemma":"canarder","pos":"VER"} ,
		{"word":"canarderais","word_nosc":"canarderais","lemma":"canarder","pos":"VER"} ,
		{"word":"canarderont","word_nosc":"canarderont","lemma":"canarder","pos":"VER"} ,
		{"word":"canardez","word_nosc":"canardez","lemma":"canarder","pos":"VER"} ,
		{"word":"canardé","word_nosc":"canarde","lemma":"canarder","pos":"VER"} ,
		{"word":"canardée","word_nosc":"canardee","lemma":"canarder","pos":"VER"} ,
		{"word":"canardés","word_nosc":"canardes","lemma":"canarder","pos":"VER"} ,
		{"word":"canas","word_nosc":"canas","lemma":"caner","pos":"VER"} ,
		{"word":"cancanaient","word_nosc":"cancanaient","lemma":"cancaner","pos":"VER"} ,
		{"word":"cancanait","word_nosc":"cancanait","lemma":"cancaner","pos":"VER"} ,
		{"word":"cancanant","word_nosc":"cancanant","lemma":"cancaner","pos":"VER"} ,
		{"word":"cancane","word_nosc":"cancane","lemma":"cancaner","pos":"VER"} ,
		{"word":"cancaner","word_nosc":"cancaner","lemma":"cancaner","pos":"VER"} ,
		{"word":"cancanes","word_nosc":"cancanes","lemma":"cancaner","pos":"VER"} ,
		{"word":"cancériser","word_nosc":"canceriser","lemma":"cancériser","pos":"VER"} ,
		{"word":"candi","word_nosc":"candi","lemma":"candir","pos":"VER"} ,
		{"word":"candie","word_nosc":"candie","lemma":"candir","pos":"VER"} ,
		{"word":"caner","word_nosc":"caner","lemma":"caner","pos":"VER"} ,
		{"word":"caneras","word_nosc":"caneras","lemma":"caner","pos":"VER"} ,
		{"word":"cannait","word_nosc":"cannait","lemma":"canner","pos":"VER"} ,
		{"word":"cannant","word_nosc":"cannant","lemma":"canner","pos":"VER"} ,
		{"word":"canne","word_nosc":"canne","lemma":"canner","pos":"VER"} ,
		{"word":"cannelée","word_nosc":"cannelee","lemma":"canneler","pos":"VER"} ,
		{"word":"cannelées","word_nosc":"cannelees","lemma":"canneler","pos":"VER"} ,
		{"word":"canner","word_nosc":"canner","lemma":"canner","pos":"VER"} ,
		{"word":"canneraient","word_nosc":"canneraient","lemma":"canner","pos":"VER"} ,
		{"word":"canné","word_nosc":"canne","lemma":"canner","pos":"VER"} ,
		{"word":"cannée","word_nosc":"cannee","lemma":"canner","pos":"VER"} ,
		{"word":"cannées","word_nosc":"cannees","lemma":"canner","pos":"VER"} ,
		{"word":"canoniser","word_nosc":"canoniser","lemma":"canoniser","pos":"VER"} ,
		{"word":"canonisez","word_nosc":"canonisez","lemma":"canoniser","pos":"VER"} ,
		{"word":"canonisé","word_nosc":"canonise","lemma":"canoniser","pos":"VER"} ,
		{"word":"canonisée","word_nosc":"canonisee","lemma":"canoniser","pos":"VER"} ,
		{"word":"canonisés","word_nosc":"canonises","lemma":"canoniser","pos":"VER"} ,
		{"word":"canonnait","word_nosc":"canonnait","lemma":"canonner","pos":"VER"} ,
		{"word":"canonner","word_nosc":"canonner","lemma":"canonner","pos":"VER"} ,
		{"word":"canonné","word_nosc":"canonne","lemma":"canonner","pos":"VER"} ,
		{"word":"canonnées","word_nosc":"canonnees","lemma":"canonner","pos":"VER"} ,
		{"word":"canonnés","word_nosc":"canonnes","lemma":"canonner","pos":"VER"} ,
		{"word":"canons","word_nosc":"canons","lemma":"caner","pos":"VER"} ,
		{"word":"canotaient","word_nosc":"canotaient","lemma":"canoter","pos":"VER"} ,
		{"word":"canote","word_nosc":"canote","lemma":"canoter","pos":"VER"} ,
		{"word":"canotent","word_nosc":"canotent","lemma":"canoter","pos":"VER"} ,
		{"word":"canoter","word_nosc":"canoter","lemma":"canoter","pos":"VER"} ,
		{"word":"canotâmes","word_nosc":"canotames","lemma":"canoter","pos":"VER"} ,
		{"word":"canoté","word_nosc":"canote","lemma":"canoter","pos":"VER"} ,
		{"word":"cantina","word_nosc":"cantina","lemma":"cantiner","pos":"VER"} ,
		{"word":"cantinaient","word_nosc":"cantinaient","lemma":"cantiner","pos":"VER"} ,
		{"word":"cantiner","word_nosc":"cantiner","lemma":"cantiner","pos":"VER"} ,
		{"word":"cantonna","word_nosc":"cantonna","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonnai","word_nosc":"cantonnai","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonnaient","word_nosc":"cantonnaient","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonnais","word_nosc":"cantonnais","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonnait","word_nosc":"cantonnait","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonnant","word_nosc":"cantonnant","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonne","word_nosc":"cantonne","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonnent","word_nosc":"cantonnent","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonner","word_nosc":"cantonner","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonneraient","word_nosc":"cantonneraient","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonnez","word_nosc":"cantonnez","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonnons","word_nosc":"cantonnons","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonnèrent","word_nosc":"cantonnerent","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonné","word_nosc":"cantonne","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonnée","word_nosc":"cantonnee","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonnées","word_nosc":"cantonnees","lemma":"cantonner","pos":"VER"} ,
		{"word":"cantonnés","word_nosc":"cantonnes","lemma":"cantonner","pos":"VER"} ,
		{"word":"canulant","word_nosc":"canulant","lemma":"canuler","pos":"VER"} ,
		{"word":"cané","word_nosc":"cane","lemma":"caner","pos":"VER"} ,
		{"word":"canés","word_nosc":"canes","lemma":"caner","pos":"VER"} ,
		{"word":"caoutchouté","word_nosc":"caoutchoute","lemma":"caoutchouter","pos":"VER"} ,
		{"word":"caoutchoutée","word_nosc":"caoutchoutee","lemma":"caoutchouter","pos":"VER"} ,
		{"word":"caoutchoutés","word_nosc":"caoutchoutes","lemma":"caoutchouter","pos":"VER"} ,
		{"word":"caparaçonnait","word_nosc":"caparaconnait","lemma":"caparaçonner","pos":"VER"} ,
		{"word":"caparaçonne","word_nosc":"caparaconne","lemma":"caparaçonner","pos":"VER"} ,
		{"word":"caparaçonné","word_nosc":"caparaconne","lemma":"caparaçonner","pos":"VER"} ,
		{"word":"caparaçonnée","word_nosc":"caparaconnee","lemma":"caparaçonner","pos":"VER"} ,
		{"word":"caparaçonnées","word_nosc":"caparaconnees","lemma":"caparaçonner","pos":"VER"} ,
		{"word":"caparaçonnés","word_nosc":"caparaconnes","lemma":"caparaçonner","pos":"VER"} ,
		{"word":"capelé","word_nosc":"capele","lemma":"capeler","pos":"VER"} ,
		{"word":"caper","word_nosc":"caper","lemma":"caper","pos":"VER"} ,
		{"word":"capitalise","word_nosc":"capitalise","lemma":"capitaliser","pos":"VER"} ,
		{"word":"capitaliser","word_nosc":"capitaliser","lemma":"capitaliser","pos":"VER"} ,
		{"word":"capitalisons","word_nosc":"capitalisons","lemma":"capitaliser","pos":"VER"} ,
		{"word":"capitalisé","word_nosc":"capitalise","lemma":"capitaliser","pos":"VER"} ,
		{"word":"capitalisés","word_nosc":"capitalises","lemma":"capitaliser","pos":"VER"} ,
		{"word":"capitonnaient","word_nosc":"capitonnaient","lemma":"capitonner","pos":"VER"} ,
		{"word":"capitonnait","word_nosc":"capitonnait","lemma":"capitonner","pos":"VER"} ,
		{"word":"capitonner","word_nosc":"capitonner","lemma":"capitonner","pos":"VER"} ,
		{"word":"capitonné","word_nosc":"capitonne","lemma":"capitonner","pos":"VER"} ,
		{"word":"capitonnée","word_nosc":"capitonnee","lemma":"capitonner","pos":"VER"} ,
		{"word":"capitonnées","word_nosc":"capitonnees","lemma":"capitonner","pos":"VER"} ,
		{"word":"capitonnés","word_nosc":"capitonnes","lemma":"capitonner","pos":"VER"} ,
		{"word":"capitula","word_nosc":"capitula","lemma":"capituler","pos":"VER"} ,
		{"word":"capitulaient","word_nosc":"capitulaient","lemma":"capituler","pos":"VER"} ,
		{"word":"capitulais","word_nosc":"capitulais","lemma":"capituler","pos":"VER"} ,
		{"word":"capitulait","word_nosc":"capitulait","lemma":"capituler","pos":"VER"} ,
		{"word":"capitulant","word_nosc":"capitulant","lemma":"capituler","pos":"VER"} ,
		{"word":"capitule","word_nosc":"capitule","lemma":"capituler","pos":"VER"} ,
		{"word":"capitulent","word_nosc":"capitulent","lemma":"capituler","pos":"VER"} ,
		{"word":"capituler","word_nosc":"capituler","lemma":"capituler","pos":"VER"} ,
		{"word":"capitulera","word_nosc":"capitulera","lemma":"capituler","pos":"VER"} ,
		{"word":"capitulerait","word_nosc":"capitulerait","lemma":"capituler","pos":"VER"} ,
		{"word":"capitulerez","word_nosc":"capitulerez","lemma":"capituler","pos":"VER"} ,
		{"word":"capitulerons","word_nosc":"capitulerons","lemma":"capituler","pos":"VER"} ,
		{"word":"capituleront","word_nosc":"capituleront","lemma":"capituler","pos":"VER"} ,
		{"word":"capitules","word_nosc":"capitules","lemma":"capituler","pos":"VER"} ,
		{"word":"capituliez","word_nosc":"capituliez","lemma":"capituler","pos":"VER"} ,
		{"word":"capitulions","word_nosc":"capitulions","lemma":"capituler","pos":"VER"} ,
		{"word":"capitulons","word_nosc":"capitulons","lemma":"capituler","pos":"VER"} ,
		{"word":"capitulèrent","word_nosc":"capitulerent","lemma":"capituler","pos":"VER"} ,
		{"word":"capitulé","word_nosc":"capitule","lemma":"capituler","pos":"VER"} ,
		{"word":"capota","word_nosc":"capota","lemma":"capoter","pos":"VER"} ,
		{"word":"capotaient","word_nosc":"capotaient","lemma":"capoter","pos":"VER"} ,
		{"word":"capote","word_nosc":"capote","lemma":"capoter","pos":"VER"} ,
		{"word":"capoter","word_nosc":"capoter","lemma":"capoter","pos":"VER"} ,
		{"word":"capotes","word_nosc":"capotes","lemma":"capoter","pos":"VER"} ,
		{"word":"capoté","word_nosc":"capote","lemma":"capoter","pos":"VER"} ,
		{"word":"capotée","word_nosc":"capotee","lemma":"capoter","pos":"VER"} ,
		{"word":"capsule","word_nosc":"capsule","lemma":"capsuler","pos":"VER"} ,
		{"word":"capsulé","word_nosc":"capsule","lemma":"capsuler","pos":"VER"} ,
		{"word":"capta","word_nosc":"capta","lemma":"capter","pos":"VER"} ,
		{"word":"captaient","word_nosc":"captaient","lemma":"capter","pos":"VER"} ,
		{"word":"captais","word_nosc":"captais","lemma":"capter","pos":"VER"} ,
		{"word":"captait","word_nosc":"captait","lemma":"capter","pos":"VER"} ,
		{"word":"captant","word_nosc":"captant","lemma":"capter","pos":"VER"} ,
		{"word":"capte","word_nosc":"capte","lemma":"capter","pos":"VER"} ,
		{"word":"captent","word_nosc":"captent","lemma":"capter","pos":"VER"} ,
		{"word":"capter","word_nosc":"capter","lemma":"capter","pos":"VER"} ,
		{"word":"capterai","word_nosc":"capterai","lemma":"capter","pos":"VER"} ,
		{"word":"capterais","word_nosc":"capterais","lemma":"capter","pos":"VER"} ,
		{"word":"capterait","word_nosc":"capterait","lemma":"capter","pos":"VER"} ,
		{"word":"capteront","word_nosc":"capteront","lemma":"capter","pos":"VER"} ,
		{"word":"captez","word_nosc":"captez","lemma":"capter","pos":"VER"} ,
		{"word":"captions","word_nosc":"captions","lemma":"capter","pos":"VER"} ,
		{"word":"captiva","word_nosc":"captiva","lemma":"captiver","pos":"VER"} ,
		{"word":"captivaient","word_nosc":"captivaient","lemma":"captiver","pos":"VER"} ,
		{"word":"captivait","word_nosc":"captivait","lemma":"captiver","pos":"VER"} ,
		{"word":"captivant","word_nosc":"captivant","lemma":"captiver","pos":"VER"} ,
		{"word":"captivent","word_nosc":"captivent","lemma":"captiver","pos":"VER"} ,
		{"word":"captiver","word_nosc":"captiver","lemma":"captiver","pos":"VER"} ,
		{"word":"captivèrent","word_nosc":"captiverent","lemma":"captiver","pos":"VER"} ,
		{"word":"captivé","word_nosc":"captive","lemma":"captiver","pos":"VER"} ,
		{"word":"captivée","word_nosc":"captivee","lemma":"captiver","pos":"VER"} ,
		{"word":"captivés","word_nosc":"captives","lemma":"captiver","pos":"VER"} ,
		{"word":"captons","word_nosc":"captons","lemma":"capter","pos":"VER"} ,
		{"word":"captura","word_nosc":"captura","lemma":"capturer","pos":"VER"} ,
		{"word":"capturait","word_nosc":"capturait","lemma":"capturer","pos":"VER"} ,
		{"word":"capturant","word_nosc":"capturant","lemma":"capturer","pos":"VER"} ,
		{"word":"capture","word_nosc":"capture","lemma":"capturer","pos":"VER"} ,
		{"word":"capturent","word_nosc":"capturent","lemma":"capturer","pos":"VER"} ,
		{"word":"capturer","word_nosc":"capturer","lemma":"capturer","pos":"VER"} ,
		{"word":"capturera","word_nosc":"capturera","lemma":"capturer","pos":"VER"} ,
		{"word":"capturerait","word_nosc":"capturerait","lemma":"capturer","pos":"VER"} ,
		{"word":"captureras","word_nosc":"captureras","lemma":"capturer","pos":"VER"} ,
		{"word":"capturerez","word_nosc":"capturerez","lemma":"capturer","pos":"VER"} ,
		{"word":"capturerons","word_nosc":"capturerons","lemma":"capturer","pos":"VER"} ,
		{"word":"captureront","word_nosc":"captureront","lemma":"capturer","pos":"VER"} ,
		{"word":"captures","word_nosc":"captures","lemma":"capturer","pos":"VER"} ,
		{"word":"capturez","word_nosc":"capturez","lemma":"capturer","pos":"VER"} ,
		{"word":"capturons","word_nosc":"capturons","lemma":"capturer","pos":"VER"} ,
		{"word":"capturé","word_nosc":"capture","lemma":"capturer","pos":"VER"} ,
		{"word":"capturée","word_nosc":"capturee","lemma":"capturer","pos":"VER"} ,
		{"word":"capturées","word_nosc":"capturees","lemma":"capturer","pos":"VER"} ,
		{"word":"capturés","word_nosc":"captures","lemma":"capturer","pos":"VER"} ,
		{"word":"capté","word_nosc":"capte","lemma":"capter","pos":"VER"} ,
		{"word":"captée","word_nosc":"captee","lemma":"capter","pos":"VER"} ,
		{"word":"captées","word_nosc":"captees","lemma":"capter","pos":"VER"} ,
		{"word":"captés","word_nosc":"captes","lemma":"capter","pos":"VER"} ,
		{"word":"capuchonnés","word_nosc":"capuchonnes","lemma":"capuchonner","pos":"VER"} ,
		{"word":"caquetaient","word_nosc":"caquetaient","lemma":"caqueter","pos":"VER"} ,
		{"word":"caquetait","word_nosc":"caquetait","lemma":"caqueter","pos":"VER"} ,
		{"word":"caquetant","word_nosc":"caquetant","lemma":"caqueter","pos":"VER"} ,
		{"word":"caqueter","word_nosc":"caqueter","lemma":"caqueter","pos":"VER"} ,
		{"word":"caquette","word_nosc":"caquette","lemma":"caqueter","pos":"VER"} ,
		{"word":"caquettent","word_nosc":"caquettent","lemma":"caqueter","pos":"VER"} ,
		{"word":"caracola","word_nosc":"caracola","lemma":"caracoler","pos":"VER"} ,
		{"word":"caracolait","word_nosc":"caracolait","lemma":"caracoler","pos":"VER"} ,
		{"word":"caracolant","word_nosc":"caracolant","lemma":"caracoler","pos":"VER"} ,
		{"word":"caracole","word_nosc":"caracole","lemma":"caracoler","pos":"VER"} ,
		{"word":"caracolent","word_nosc":"caracolent","lemma":"caracoler","pos":"VER"} ,
		{"word":"caracoler","word_nosc":"caracoler","lemma":"caracoler","pos":"VER"} ,
		{"word":"caracolerez","word_nosc":"caracolerez","lemma":"caracoler","pos":"VER"} ,
		{"word":"caractérisait","word_nosc":"caracterisait","lemma":"caractériser","pos":"VER"} ,
		{"word":"caractérisant","word_nosc":"caracterisant","lemma":"caractériser","pos":"VER"} ,
		{"word":"caractérise","word_nosc":"caracterise","lemma":"caractériser","pos":"VER"} ,
		{"word":"caractérisent","word_nosc":"caracterisent","lemma":"caractériser","pos":"VER"} ,
		{"word":"caractériser","word_nosc":"caracteriser","lemma":"caractériser","pos":"VER"} ,
		{"word":"caractériserais","word_nosc":"caracteriserais","lemma":"caractériser","pos":"VER"} ,
		{"word":"caractériserait","word_nosc":"caracteriserait","lemma":"caractériser","pos":"VER"} ,
		{"word":"caractérisé","word_nosc":"caracterise","lemma":"caractériser","pos":"VER"} ,
		{"word":"caractérisée","word_nosc":"caracterisee","lemma":"caractériser","pos":"VER"} ,
		{"word":"caractérisés","word_nosc":"caracterises","lemma":"caractériser","pos":"VER"} ,
		{"word":"carambolaient","word_nosc":"carambolaient","lemma":"caramboler","pos":"VER"} ,
		{"word":"carambolait","word_nosc":"carambolait","lemma":"caramboler","pos":"VER"} ,
		{"word":"carambole","word_nosc":"carambole","lemma":"caramboler","pos":"VER"} ,
		{"word":"carambolent","word_nosc":"carambolent","lemma":"caramboler","pos":"VER"} ,
		{"word":"caramboler","word_nosc":"caramboler","lemma":"caramboler","pos":"VER"} ,
		{"word":"carambolé","word_nosc":"carambole","lemma":"caramboler","pos":"VER"} ,
		{"word":"carambouillé","word_nosc":"carambouille","lemma":"carambouiller","pos":"VER"} ,
		{"word":"caramélisait","word_nosc":"caramelisait","lemma":"caraméliser","pos":"VER"} ,
		{"word":"caramélise","word_nosc":"caramelise","lemma":"caraméliser","pos":"VER"} ,
		{"word":"caraméliser","word_nosc":"carameliser","lemma":"caraméliser","pos":"VER"} ,
		{"word":"caramélisé","word_nosc":"caramelise","lemma":"caraméliser","pos":"VER"} ,
		{"word":"carapatait","word_nosc":"carapatait","lemma":"carapater","pos":"VER"} ,
		{"word":"carapate","word_nosc":"carapate","lemma":"carapater","pos":"VER"} ,
		{"word":"carapatent","word_nosc":"carapatent","lemma":"carapater","pos":"VER"} ,
		{"word":"carapater","word_nosc":"carapater","lemma":"carapater","pos":"VER"} ,
		{"word":"carapaté","word_nosc":"carapate","lemma":"carapater","pos":"VER"} ,
		{"word":"carapatés","word_nosc":"carapates","lemma":"carapater","pos":"VER"} ,
		{"word":"carbonisa","word_nosc":"carbonisa","lemma":"carboniser","pos":"VER"} ,
		{"word":"carbonisant","word_nosc":"carbonisant","lemma":"carboniser","pos":"VER"} ,
		{"word":"carboniser","word_nosc":"carboniser","lemma":"carboniser","pos":"VER"} ,
		{"word":"carbonisé","word_nosc":"carbonise","lemma":"carboniser","pos":"VER"} ,
		{"word":"carbonisée","word_nosc":"carbonisee","lemma":"carboniser","pos":"VER"} ,
		{"word":"carbonisées","word_nosc":"carbonisees","lemma":"carboniser","pos":"VER"} ,
		{"word":"carbonisés","word_nosc":"carbonises","lemma":"carboniser","pos":"VER"} ,
		{"word":"carburaient","word_nosc":"carburaient","lemma":"carburer","pos":"VER"} ,
		{"word":"carburais","word_nosc":"carburais","lemma":"carburer","pos":"VER"} ,
		{"word":"carburait","word_nosc":"carburait","lemma":"carburer","pos":"VER"} ,
		{"word":"carburant","word_nosc":"carburant","lemma":"carburer","pos":"VER"} ,
		{"word":"carbure","word_nosc":"carbure","lemma":"carburer","pos":"VER"} ,
		{"word":"carburent","word_nosc":"carburent","lemma":"carburer","pos":"VER"} ,
		{"word":"carburer","word_nosc":"carburer","lemma":"carburer","pos":"VER"} ,
		{"word":"carburez","word_nosc":"carburez","lemma":"carburer","pos":"VER"} ,
		{"word":"carburé","word_nosc":"carbure","lemma":"carburer","pos":"VER"} ,
		{"word":"cardait","word_nosc":"cardait","lemma":"carder","pos":"VER"} ,
		{"word":"cardant","word_nosc":"cardant","lemma":"carder","pos":"VER"} ,
		{"word":"carde","word_nosc":"carde","lemma":"carder","pos":"VER"} ,
		{"word":"carder","word_nosc":"carder","lemma":"carder","pos":"VER"} ,
		{"word":"carderai","word_nosc":"carderai","lemma":"carder","pos":"VER"} ,
		{"word":"cardé","word_nosc":"carde","lemma":"carder","pos":"VER"} ,
		{"word":"cardée","word_nosc":"cardee","lemma":"carder","pos":"VER"} ,
		{"word":"cardés","word_nosc":"cardes","lemma":"carder","pos":"VER"} ,
		{"word":"caressa","word_nosc":"caressa","lemma":"caresser","pos":"VER"} ,
		{"word":"caressai","word_nosc":"caressai","lemma":"caresser","pos":"VER"} ,
		{"word":"caressaient","word_nosc":"caressaient","lemma":"caresser","pos":"VER"} ,
		{"word":"caressais","word_nosc":"caressais","lemma":"caresser","pos":"VER"} ,
		{"word":"caressait","word_nosc":"caressait","lemma":"caresser","pos":"VER"} ,
		{"word":"caressant","word_nosc":"caressant","lemma":"caresser","pos":"VER"} ,
		{"word":"caresse","word_nosc":"caresse","lemma":"caresser","pos":"VER"} ,
		{"word":"caressent","word_nosc":"caressent","lemma":"caresser","pos":"VER"} ,
		{"word":"caresser","word_nosc":"caresser","lemma":"caresser","pos":"VER"} ,
		{"word":"caressera","word_nosc":"caressera","lemma":"caresser","pos":"VER"} ,
		{"word":"caresserai","word_nosc":"caresserai","lemma":"caresser","pos":"VER"} ,
		{"word":"caresseraient","word_nosc":"caresseraient","lemma":"caresser","pos":"VER"} ,
		{"word":"caresserait","word_nosc":"caresserait","lemma":"caresser","pos":"VER"} ,
		{"word":"caresserez","word_nosc":"caresserez","lemma":"caresser","pos":"VER"} ,
		{"word":"caresseront","word_nosc":"caresseront","lemma":"caresser","pos":"VER"} ,
		{"word":"caresses","word_nosc":"caresses","lemma":"caresser","pos":"VER"} ,
		{"word":"caressez","word_nosc":"caressez","lemma":"caresser","pos":"VER"} ,
		{"word":"caressions","word_nosc":"caressions","lemma":"caresser","pos":"VER"} ,
		{"word":"caressât","word_nosc":"caressat","lemma":"caresser","pos":"VER"} ,
		{"word":"caressèrent","word_nosc":"caresserent","lemma":"caresser","pos":"VER"} ,
		{"word":"caressé","word_nosc":"caresse","lemma":"caresser","pos":"VER"} ,
		{"word":"caressée","word_nosc":"caressee","lemma":"caresser","pos":"VER"} ,
		{"word":"caressées","word_nosc":"caressees","lemma":"caresser","pos":"VER"} ,
		{"word":"caressés","word_nosc":"caresses","lemma":"caresser","pos":"VER"} ,
		{"word":"cargua","word_nosc":"cargua","lemma":"carguer","pos":"VER"} ,
		{"word":"carguent","word_nosc":"carguent","lemma":"carguer","pos":"VER"} ,
		{"word":"carguer","word_nosc":"carguer","lemma":"carguer","pos":"VER"} ,
		{"word":"carguez","word_nosc":"carguez","lemma":"carguer","pos":"VER"} ,
		{"word":"carguée","word_nosc":"carguee","lemma":"carguer","pos":"VER"} ,
		{"word":"carguées","word_nosc":"carguees","lemma":"carguer","pos":"VER"} ,
		{"word":"caricature","word_nosc":"caricature","lemma":"caricaturer","pos":"VER"} ,
		{"word":"caricaturer","word_nosc":"caricaturer","lemma":"caricaturer","pos":"VER"} ,
		{"word":"caricatures","word_nosc":"caricatures","lemma":"caricaturer","pos":"VER"} ,
		{"word":"caricaturé","word_nosc":"caricature","lemma":"caricaturer","pos":"VER"} ,
		{"word":"caricaturées","word_nosc":"caricaturees","lemma":"caricaturer","pos":"VER"} ,
		{"word":"caricaturés","word_nosc":"caricatures","lemma":"caricaturer","pos":"VER"} ,
		{"word":"carier","word_nosc":"carier","lemma":"carier","pos":"VER"} ,
		{"word":"carillonnaient","word_nosc":"carillonnaient","lemma":"carillonner","pos":"VER"} ,
		{"word":"carillonnait","word_nosc":"carillonnait","lemma":"carillonner","pos":"VER"} ,
		{"word":"carillonnant","word_nosc":"carillonnant","lemma":"carillonner","pos":"VER"} ,
		{"word":"carillonne","word_nosc":"carillonne","lemma":"carillonner","pos":"VER"} ,
		{"word":"carillonnent","word_nosc":"carillonnent","lemma":"carillonner","pos":"VER"} ,
		{"word":"carillonner","word_nosc":"carillonner","lemma":"carillonner","pos":"VER"} ,
		{"word":"carillonnes","word_nosc":"carillonnes","lemma":"carillonner","pos":"VER"} ,
		{"word":"carillonné","word_nosc":"carillonne","lemma":"carillonner","pos":"VER"} ,
		{"word":"cariée","word_nosc":"cariee","lemma":"carier","pos":"VER"} ,
		{"word":"cariées","word_nosc":"cariees","lemma":"carier","pos":"VER"} ,
		{"word":"carma","word_nosc":"carma","lemma":"carmer","pos":"VER"} ,
		{"word":"carmant","word_nosc":"carmant","lemma":"carmer","pos":"VER"} ,
		{"word":"carmer","word_nosc":"carmer","lemma":"carmer","pos":"VER"} ,
		{"word":"carmina","word_nosc":"carmina","lemma":"carminer","pos":"VER"} ,
		{"word":"carmine","word_nosc":"carmine","lemma":"carminer","pos":"VER"} ,
		{"word":"carminée","word_nosc":"carminee","lemma":"carminer","pos":"VER"} ,
		{"word":"carmé","word_nosc":"carme","lemma":"carmer","pos":"VER"} ,
		{"word":"carmée","word_nosc":"carmee","lemma":"carmer","pos":"VER"} ,
		{"word":"carottait","word_nosc":"carottait","lemma":"carotter","pos":"VER"} ,
		{"word":"carotte","word_nosc":"carotte","lemma":"carotter","pos":"VER"} ,
		{"word":"carottent","word_nosc":"carottent","lemma":"carotter","pos":"VER"} ,
		{"word":"carotter","word_nosc":"carotter","lemma":"carotter","pos":"VER"} ,
		{"word":"carotterait","word_nosc":"carotterait","lemma":"carotter","pos":"VER"} ,
		{"word":"carotté","word_nosc":"carotte","lemma":"carotter","pos":"VER"} ,
		{"word":"carottés","word_nosc":"carottes","lemma":"carotter","pos":"VER"} ,
		{"word":"caroublant","word_nosc":"caroublant","lemma":"caroubler","pos":"VER"} ,
		{"word":"caroubler","word_nosc":"caroubler","lemma":"caroubler","pos":"VER"} ,
		{"word":"carra","word_nosc":"carra","lemma":"carrer","pos":"VER"} ,
		{"word":"carraient","word_nosc":"carraient","lemma":"carrer","pos":"VER"} ,
		{"word":"carrais","word_nosc":"carrais","lemma":"carrer","pos":"VER"} ,
		{"word":"carrait","word_nosc":"carrait","lemma":"carrer","pos":"VER"} ,
		{"word":"carrant","word_nosc":"carrant","lemma":"carrer","pos":"VER"} ,
		{"word":"carre","word_nosc":"carre","lemma":"carrer","pos":"VER"} ,
		{"word":"carrelait","word_nosc":"carrelait","lemma":"carreler","pos":"VER"} ,
		{"word":"carrelant","word_nosc":"carrelant","lemma":"carreler","pos":"VER"} ,
		{"word":"carreler","word_nosc":"carreler","lemma":"carreler","pos":"VER"} ,
		{"word":"carrelé","word_nosc":"carrele","lemma":"carreler","pos":"VER"} ,
		{"word":"carrelée","word_nosc":"carrelee","lemma":"carreler","pos":"VER"} ,
		{"word":"carrelées","word_nosc":"carrelees","lemma":"carreler","pos":"VER"} ,
		{"word":"carrelés","word_nosc":"carreles","lemma":"carreler","pos":"VER"} ,
		{"word":"carrent","word_nosc":"carrent","lemma":"carrer","pos":"VER"} ,
		{"word":"carrer","word_nosc":"carrer","lemma":"carrer","pos":"VER"} ,
		{"word":"carrerais","word_nosc":"carrerais","lemma":"carrer","pos":"VER"} ,
		{"word":"carres","word_nosc":"carres","lemma":"carrer","pos":"VER"} ,
		{"word":"carrosse","word_nosc":"carrosse","lemma":"carrosser","pos":"VER"} ,
		{"word":"carrosser","word_nosc":"carrosser","lemma":"carrosser","pos":"VER"} ,
		{"word":"carrossée","word_nosc":"carrossee","lemma":"carrosser","pos":"VER"} ,
		{"word":"carrossées","word_nosc":"carrossees","lemma":"carrosser","pos":"VER"} ,
		{"word":"carré","word_nosc":"carre","lemma":"carrer","pos":"VER"} ,
		{"word":"carrée","word_nosc":"carree","lemma":"carrer","pos":"VER"} ,
		{"word":"carrées","word_nosc":"carrees","lemma":"carrer","pos":"VER"} ,
		{"word":"carrés","word_nosc":"carres","lemma":"carrer","pos":"VER"} ,
		{"word":"carta","word_nosc":"carta","lemma":"carter","pos":"VER"} ,
		{"word":"carter","word_nosc":"carter","lemma":"carter","pos":"VER"} ,
		{"word":"cartographient","word_nosc":"cartographient","lemma":"cartographier","pos":"VER"} ,
		{"word":"cartographier","word_nosc":"cartographier","lemma":"cartographier","pos":"VER"} ,
		{"word":"cartographié","word_nosc":"cartographie","lemma":"cartographier","pos":"VER"} ,
		{"word":"cartographiés","word_nosc":"cartographies","lemma":"cartographier","pos":"VER"} ,
		{"word":"cartonnant","word_nosc":"cartonnant","lemma":"cartonner","pos":"VER"} ,
		{"word":"cartonne","word_nosc":"cartonne","lemma":"cartonner","pos":"VER"} ,
		{"word":"cartonnent","word_nosc":"cartonnent","lemma":"cartonner","pos":"VER"} ,
		{"word":"cartonner","word_nosc":"cartonner","lemma":"cartonner","pos":"VER"} ,
		{"word":"cartonné","word_nosc":"cartonne","lemma":"cartonner","pos":"VER"} ,
		{"word":"cartonnée","word_nosc":"cartonnee","lemma":"cartonner","pos":"VER"} ,
		{"word":"cartonnées","word_nosc":"cartonnees","lemma":"cartonner","pos":"VER"} ,
		{"word":"cartonnés","word_nosc":"cartonnes","lemma":"cartonner","pos":"VER"} ,
		{"word":"carté","word_nosc":"carte","lemma":"carter","pos":"VER"} ,
		{"word":"carène","word_nosc":"carene","lemma":"caréner","pos":"VER"} ,
		{"word":"caréner","word_nosc":"carener","lemma":"caréner","pos":"VER"} ,
		{"word":"carénées","word_nosc":"carenees","lemma":"caréner","pos":"VER"} ,
		{"word":"casa","word_nosc":"casa","lemma":"caser","pos":"VER"} ,
		{"word":"casaient","word_nosc":"casaient","lemma":"caser","pos":"VER"} ,
		{"word":"casait","word_nosc":"casait","lemma":"caser","pos":"VER"} ,
		{"word":"casant","word_nosc":"casant","lemma":"caser","pos":"VER"} ,
		{"word":"casas","word_nosc":"casas","lemma":"caser","pos":"VER"} ,
		{"word":"cascadaient","word_nosc":"cascadaient","lemma":"cascader","pos":"VER"} ,
		{"word":"cascadait","word_nosc":"cascadait","lemma":"cascader","pos":"VER"} ,
		{"word":"cascadant","word_nosc":"cascadant","lemma":"cascader","pos":"VER"} ,
		{"word":"cascadas","word_nosc":"cascadas","lemma":"cascader","pos":"VER"} ,
		{"word":"cascade","word_nosc":"cascade","lemma":"cascader","pos":"VER"} ,
		{"word":"cascadent","word_nosc":"cascadent","lemma":"cascader","pos":"VER"} ,
		{"word":"cascader","word_nosc":"cascader","lemma":"cascader","pos":"VER"} ,
		{"word":"cascades","word_nosc":"cascades","lemma":"cascader","pos":"VER"} ,
		{"word":"cascadèrent","word_nosc":"cascaderent","lemma":"cascader","pos":"VER"} ,
		{"word":"cascadé","word_nosc":"cascade","lemma":"cascader","pos":"VER"} ,
		{"word":"case","word_nosc":"case","lemma":"caser","pos":"VER"} ,
		{"word":"casent","word_nosc":"casent","lemma":"caser","pos":"VER"} ,
		{"word":"caser","word_nosc":"caser","lemma":"caser","pos":"VER"} ,
		{"word":"caserai","word_nosc":"caserai","lemma":"caser","pos":"VER"} ,
		{"word":"caserais","word_nosc":"caserais","lemma":"caser","pos":"VER"} ,
		{"word":"caserait","word_nosc":"caserait","lemma":"caser","pos":"VER"} ,
		{"word":"caserner","word_nosc":"caserner","lemma":"caserner","pos":"VER"} ,
		{"word":"caserné","word_nosc":"caserne","lemma":"caserner","pos":"VER"} ,
		{"word":"casernés","word_nosc":"casernes","lemma":"caserner","pos":"VER"} ,
		{"word":"cases","word_nosc":"cases","lemma":"caser","pos":"VER"} ,
		{"word":"casquaient","word_nosc":"casquaient","lemma":"casquer","pos":"VER"} ,
		{"word":"casquais","word_nosc":"casquais","lemma":"casquer","pos":"VER"} ,
		{"word":"casquait","word_nosc":"casquait","lemma":"casquer","pos":"VER"} ,
		{"word":"casquant","word_nosc":"casquant","lemma":"casquer","pos":"VER"} ,
		{"word":"casque","word_nosc":"casque","lemma":"casquer","pos":"VER"} ,
		{"word":"casquent","word_nosc":"casquent","lemma":"casquer","pos":"VER"} ,
		{"word":"casquer","word_nosc":"casquer","lemma":"casquer","pos":"VER"} ,
		{"word":"casquera","word_nosc":"casquera","lemma":"casquer","pos":"VER"} ,
		{"word":"casqueras","word_nosc":"casqueras","lemma":"casquer","pos":"VER"} ,
		{"word":"casqueront","word_nosc":"casqueront","lemma":"casquer","pos":"VER"} ,
		{"word":"casques","word_nosc":"casques","lemma":"casquer","pos":"VER"} ,
		{"word":"casquez","word_nosc":"casquez","lemma":"casquer","pos":"VER"} ,
		{"word":"casqué","word_nosc":"casque","lemma":"casquer","pos":"VER"} ,
		{"word":"casquée","word_nosc":"casquee","lemma":"casquer","pos":"VER"} ,
		{"word":"casquées","word_nosc":"casquees","lemma":"casquer","pos":"VER"} ,
		{"word":"casqués","word_nosc":"casques","lemma":"casquer","pos":"VER"} ,
		{"word":"cassa","word_nosc":"cassa","lemma":"casser","pos":"VER"} ,
		{"word":"cassai","word_nosc":"cassai","lemma":"casser","pos":"VER"} ,
		{"word":"cassaient","word_nosc":"cassaient","lemma":"casser","pos":"VER"} ,
		{"word":"cassais","word_nosc":"cassais","lemma":"casser","pos":"VER"} ,
		{"word":"cassait","word_nosc":"cassait","lemma":"casser","pos":"VER"} ,
		{"word":"cassant","word_nosc":"cassant","lemma":"casser","pos":"VER"} ,
		{"word":"casse","word_nosc":"casse","lemma":"casser","pos":"VER"} ,
		{"word":"cassent","word_nosc":"cassent","lemma":"casser","pos":"VER"} ,
		{"word":"casser","word_nosc":"casser","lemma":"casser","pos":"VER"} ,
		{"word":"cassera","word_nosc":"cassera","lemma":"casser","pos":"VER"} ,
		{"word":"casserai","word_nosc":"casserai","lemma":"casser","pos":"VER"} ,
		{"word":"casseraient","word_nosc":"casseraient","lemma":"casser","pos":"VER"} ,
		{"word":"casserais","word_nosc":"casserais","lemma":"casser","pos":"VER"} ,
		{"word":"casserait","word_nosc":"casserait","lemma":"casser","pos":"VER"} ,
		{"word":"casseras","word_nosc":"casseras","lemma":"casser","pos":"VER"} ,
		{"word":"casserez","word_nosc":"casserez","lemma":"casser","pos":"VER"} ,
		{"word":"casseriez","word_nosc":"casseriez","lemma":"casser","pos":"VER"} ,
		{"word":"casserons","word_nosc":"casserons","lemma":"casser","pos":"VER"} ,
		{"word":"casseront","word_nosc":"casseront","lemma":"casser","pos":"VER"} ,
		{"word":"casses","word_nosc":"casses","lemma":"casser","pos":"VER"} ,
		{"word":"cassez","word_nosc":"cassez","lemma":"casser","pos":"VER"} ,
		{"word":"cassiez","word_nosc":"cassiez","lemma":"casser","pos":"VER"} ,
		{"word":"cassons","word_nosc":"cassons","lemma":"casser","pos":"VER"} ,
		{"word":"cassèrent","word_nosc":"casserent","lemma":"casser","pos":"VER"} ,
		{"word":"cassé","word_nosc":"casse","lemma":"casser","pos":"VER"} ,
		{"word":"cassée","word_nosc":"cassee","lemma":"casser","pos":"VER"} ,
		{"word":"cassées","word_nosc":"cassees","lemma":"casser","pos":"VER"} ,
		{"word":"cassés","word_nosc":"casses","lemma":"casser","pos":"VER"} ,
		{"word":"castagnait","word_nosc":"castagnait","lemma":"castagner","pos":"VER"} ,
		{"word":"castagnent","word_nosc":"castagnent","lemma":"castagner","pos":"VER"} ,
		{"word":"castagner","word_nosc":"castagner","lemma":"castagner","pos":"VER"} ,
		{"word":"castagnes","word_nosc":"castagnes","lemma":"castagner","pos":"VER"} ,
		{"word":"castagné","word_nosc":"castagne","lemma":"castagner","pos":"VER"} ,
		{"word":"castrer","word_nosc":"castrer","lemma":"castrer","pos":"VER"} ,
		{"word":"castrez","word_nosc":"castrez","lemma":"castrer","pos":"VER"} ,
		{"word":"castrons","word_nosc":"castrons","lemma":"castrer","pos":"VER"} ,
		{"word":"castré","word_nosc":"castre","lemma":"castrer","pos":"VER"} ,
		{"word":"castrée","word_nosc":"castree","lemma":"castrer","pos":"VER"} ,
		{"word":"castrés","word_nosc":"castres","lemma":"castrer","pos":"VER"} ,
		{"word":"casé","word_nosc":"case","lemma":"caser","pos":"VER"} ,
		{"word":"casée","word_nosc":"casee","lemma":"caser","pos":"VER"} ,
		{"word":"casées","word_nosc":"casees","lemma":"caser","pos":"VER"} ,
		{"word":"casés","word_nosc":"cases","lemma":"caser","pos":"VER"} ,
		{"word":"cataloguais","word_nosc":"cataloguais","lemma":"cataloguer","pos":"VER"} ,
		{"word":"cataloguant","word_nosc":"cataloguant","lemma":"cataloguer","pos":"VER"} ,
		{"word":"catalogue","word_nosc":"catalogue","lemma":"cataloguer","pos":"VER"} ,
		{"word":"cataloguent","word_nosc":"cataloguent","lemma":"cataloguer","pos":"VER"} ,
		{"word":"cataloguer","word_nosc":"cataloguer","lemma":"cataloguer","pos":"VER"} ,
		{"word":"cataloguerai","word_nosc":"cataloguerai","lemma":"cataloguer","pos":"VER"} ,
		{"word":"catalogues","word_nosc":"catalogues","lemma":"cataloguer","pos":"VER"} ,
		{"word":"cataloguiez","word_nosc":"cataloguiez","lemma":"cataloguer","pos":"VER"} ,
		{"word":"catalogué","word_nosc":"catalogue","lemma":"cataloguer","pos":"VER"} ,
		{"word":"cataloguée","word_nosc":"cataloguee","lemma":"cataloguer","pos":"VER"} ,
		{"word":"cataloguées","word_nosc":"cataloguees","lemma":"cataloguer","pos":"VER"} ,
		{"word":"catalogués","word_nosc":"catalogues","lemma":"cataloguer","pos":"VER"} ,
		{"word":"catalyser","word_nosc":"catalyser","lemma":"catalyser","pos":"VER"} ,
		{"word":"catalysé","word_nosc":"catalyse","lemma":"catalyser","pos":"VER"} ,
		{"word":"catapulta","word_nosc":"catapulta","lemma":"catapulter","pos":"VER"} ,
		{"word":"catapultait","word_nosc":"catapultait","lemma":"catapulter","pos":"VER"} ,
		{"word":"catapulte","word_nosc":"catapulte","lemma":"catapulter","pos":"VER"} ,
		{"word":"catapulter","word_nosc":"catapulter","lemma":"catapulter","pos":"VER"} ,
		{"word":"catapultera","word_nosc":"catapultera","lemma":"catapulter","pos":"VER"} ,
		{"word":"catapulté","word_nosc":"catapulte","lemma":"catapulter","pos":"VER"} ,
		{"word":"catapultée","word_nosc":"catapultee","lemma":"catapulter","pos":"VER"} ,
		{"word":"catapultées","word_nosc":"catapultees","lemma":"catapulter","pos":"VER"} ,
		{"word":"catapultés","word_nosc":"catapultes","lemma":"catapulter","pos":"VER"} ,
		{"word":"catastropha","word_nosc":"catastropha","lemma":"catastropher","pos":"VER"} ,
		{"word":"catastrophait","word_nosc":"catastrophait","lemma":"catastropher","pos":"VER"} ,
		{"word":"catastrophas","word_nosc":"catastrophas","lemma":"catastropher","pos":"VER"} ,
		{"word":"catastropher","word_nosc":"catastropher","lemma":"catastropher","pos":"VER"} ,
		{"word":"catastrophé","word_nosc":"catastrophe","lemma":"catastropher","pos":"VER"} ,
		{"word":"catastrophée","word_nosc":"catastrophee","lemma":"catastropher","pos":"VER"} ,
		{"word":"catcher","word_nosc":"catcher","lemma":"catcher","pos":"VER"} ,
		{"word":"catché","word_nosc":"catche","lemma":"catcher","pos":"VER"} ,
		{"word":"catéchisée","word_nosc":"catechisee","lemma":"catéchiser","pos":"VER"} ,
		{"word":"catégorise","word_nosc":"categorise","lemma":"catégoriser","pos":"VER"} ,
		{"word":"catégoriser","word_nosc":"categoriser","lemma":"catégoriser","pos":"VER"} ,
		{"word":"catégorisé","word_nosc":"categorise","lemma":"catégoriser","pos":"VER"} ,
		{"word":"cauchemardais","word_nosc":"cauchemardais","lemma":"cauchemarder","pos":"VER"} ,
		{"word":"cauchemarde","word_nosc":"cauchemarde","lemma":"cauchemarder","pos":"VER"} ,
		{"word":"cauchemarder","word_nosc":"cauchemarder","lemma":"cauchemarder","pos":"VER"} ,
		{"word":"cauchemardes","word_nosc":"cauchemardes","lemma":"cauchemarder","pos":"VER"} ,
		{"word":"cauchemardé","word_nosc":"cauchemarde","lemma":"cauchemarder","pos":"VER"} ,
		{"word":"causa","word_nosc":"causa","lemma":"causer","pos":"VER"} ,
		{"word":"causai","word_nosc":"causai","lemma":"causer","pos":"VER"} ,
		{"word":"causaient","word_nosc":"causaient","lemma":"causer","pos":"VER"} ,
		{"word":"causais","word_nosc":"causais","lemma":"causer","pos":"VER"} ,
		{"word":"causait","word_nosc":"causait","lemma":"causer","pos":"VER"} ,
		{"word":"causant","word_nosc":"causant","lemma":"causer","pos":"VER"} ,
		{"word":"cause","word_nosc":"cause","lemma":"causer","pos":"VER"} ,
		{"word":"causent","word_nosc":"causent","lemma":"causer","pos":"VER"} ,
		{"word":"causer","word_nosc":"causer","lemma":"causer","pos":"VER"} ,
		{"word":"causera","word_nosc":"causera","lemma":"causer","pos":"VER"} ,
		{"word":"causerai","word_nosc":"causerai","lemma":"causer","pos":"VER"} ,
		{"word":"causeraient","word_nosc":"causeraient","lemma":"causer","pos":"VER"} ,
		{"word":"causerais","word_nosc":"causerais","lemma":"causer","pos":"VER"} ,
		{"word":"causerait","word_nosc":"causerait","lemma":"causer","pos":"VER"} ,
		{"word":"causeras","word_nosc":"causeras","lemma":"causer","pos":"VER"} ,
		{"word":"causerez","word_nosc":"causerez","lemma":"causer","pos":"VER"} ,
		{"word":"causerions","word_nosc":"causerions","lemma":"causer","pos":"VER"} ,
		{"word":"causerons","word_nosc":"causerons","lemma":"causer","pos":"VER"} ,
		{"word":"causeront","word_nosc":"causeront","lemma":"causer","pos":"VER"} ,
		{"word":"causes","word_nosc":"causes","lemma":"causer","pos":"VER"} ,
		{"word":"causez","word_nosc":"causez","lemma":"causer","pos":"VER"} ,
		{"word":"causiez","word_nosc":"causiez","lemma":"causer","pos":"VER"} ,
		{"word":"causions","word_nosc":"causions","lemma":"causer","pos":"VER"} ,
		{"word":"causons","word_nosc":"causons","lemma":"causer","pos":"VER"} ,
		{"word":"causâmes","word_nosc":"causames","lemma":"causer","pos":"VER"} ,
		{"word":"causèrent","word_nosc":"causerent","lemma":"causer","pos":"VER"} ,
		{"word":"causé","word_nosc":"cause","lemma":"causer","pos":"VER"} ,
		{"word":"causée","word_nosc":"causee","lemma":"causer","pos":"VER"} ,
		{"word":"causées","word_nosc":"causees","lemma":"causer","pos":"VER"} ,
		{"word":"causés","word_nosc":"causes","lemma":"causer","pos":"VER"} ,
		{"word":"cautionnait","word_nosc":"cautionnait","lemma":"cautionner","pos":"VER"} ,
		{"word":"cautionne","word_nosc":"cautionne","lemma":"cautionner","pos":"VER"} ,
		{"word":"cautionnent","word_nosc":"cautionnent","lemma":"cautionner","pos":"VER"} ,
		{"word":"cautionner","word_nosc":"cautionner","lemma":"cautionner","pos":"VER"} ,
		{"word":"cautionnerait","word_nosc":"cautionnerait","lemma":"cautionner","pos":"VER"} ,
		{"word":"cautionnes","word_nosc":"cautionnes","lemma":"cautionner","pos":"VER"} ,
		{"word":"cautionnez","word_nosc":"cautionnez","lemma":"cautionner","pos":"VER"} ,
		{"word":"cautionné","word_nosc":"cautionne","lemma":"cautionner","pos":"VER"} ,
		{"word":"cautionnée","word_nosc":"cautionnee","lemma":"cautionner","pos":"VER"} ,
		{"word":"cautérisant","word_nosc":"cauterisant","lemma":"cautériser","pos":"VER"} ,
		{"word":"cautérise","word_nosc":"cauterise","lemma":"cautériser","pos":"VER"} ,
		{"word":"cautériser","word_nosc":"cauteriser","lemma":"cautériser","pos":"VER"} ,
		{"word":"cautérisée","word_nosc":"cauterisee","lemma":"cautériser","pos":"VER"} ,
		{"word":"cautérisées","word_nosc":"cauterisees","lemma":"cautériser","pos":"VER"} ,
		{"word":"cava","word_nosc":"cava","lemma":"caver","pos":"VER"} ,
		{"word":"cavalaient","word_nosc":"cavalaient","lemma":"cavaler","pos":"VER"} ,
		{"word":"cavalais","word_nosc":"cavalais","lemma":"cavaler","pos":"VER"} ,
		{"word":"cavalait","word_nosc":"cavalait","lemma":"cavaler","pos":"VER"} ,
		{"word":"cavalant","word_nosc":"cavalant","lemma":"cavaler","pos":"VER"} ,
		{"word":"cavalcadaient","word_nosc":"cavalcadaient","lemma":"cavalcader","pos":"VER"} ,
		{"word":"cavalcadait","word_nosc":"cavalcadait","lemma":"cavalcader","pos":"VER"} ,
		{"word":"cavalcadant","word_nosc":"cavalcadant","lemma":"cavalcader","pos":"VER"} ,
		{"word":"cavalcadent","word_nosc":"cavalcadent","lemma":"cavalcader","pos":"VER"} ,
		{"word":"cavalcader","word_nosc":"cavalcader","lemma":"cavalcader","pos":"VER"} ,
		{"word":"cavale","word_nosc":"cavale","lemma":"cavaler","pos":"VER"} ,
		{"word":"cavalent","word_nosc":"cavalent","lemma":"cavaler","pos":"VER"} ,
		{"word":"cavaler","word_nosc":"cavaler","lemma":"cavaler","pos":"VER"} ,
		{"word":"cavalerais","word_nosc":"cavalerais","lemma":"cavaler","pos":"VER"} ,
		{"word":"cavaleras","word_nosc":"cavaleras","lemma":"cavaler","pos":"VER"} ,
		{"word":"cavaleront","word_nosc":"cavaleront","lemma":"cavaler","pos":"VER"} ,
		{"word":"cavales","word_nosc":"cavales","lemma":"cavaler","pos":"VER"} ,
		{"word":"cavalez","word_nosc":"cavalez","lemma":"cavaler","pos":"VER"} ,
		{"word":"cavalé","word_nosc":"cavale","lemma":"cavaler","pos":"VER"} ,
		{"word":"caver","word_nosc":"caver","lemma":"caver","pos":"VER"} ,
		{"word":"caviardé","word_nosc":"caviarde","lemma":"caviarder","pos":"VER"} ,
		{"word":"cavé","word_nosc":"cave","lemma":"caver","pos":"VER"} ,
		{"word":"ceignait","word_nosc":"ceignait","lemma":"ceindre","pos":"VER"} ,
		{"word":"ceignant","word_nosc":"ceignant","lemma":"ceindre","pos":"VER"} ,
		{"word":"ceignent","word_nosc":"ceignent","lemma":"ceindre","pos":"VER"} ,
		{"word":"ceignit","word_nosc":"ceignit","lemma":"ceindre","pos":"VER"} ,
		{"word":"ceindra","word_nosc":"ceindra","lemma":"ceindre","pos":"VER"} ,
		{"word":"ceindrait","word_nosc":"ceindrait","lemma":"ceindre","pos":"VER"} ,
		{"word":"ceindre","word_nosc":"ceindre","lemma":"ceindre","pos":"VER"} ,
		{"word":"ceins","word_nosc":"ceins","lemma":"ceindre","pos":"VER"} ,
		{"word":"ceint","word_nosc":"ceint","lemma":"ceindre","pos":"VER"} ,
		{"word":"ceinte","word_nosc":"ceinte","lemma":"ceindre","pos":"VER"} ,
		{"word":"ceintes","word_nosc":"ceintes","lemma":"ceindre","pos":"VER"} ,
		{"word":"ceints","word_nosc":"ceints","lemma":"ceindre","pos":"VER"} ,
		{"word":"ceintura","word_nosc":"ceintura","lemma":"ceinturer","pos":"VER"} ,
		{"word":"ceinturaient","word_nosc":"ceinturaient","lemma":"ceinturer","pos":"VER"} ,
		{"word":"ceinturait","word_nosc":"ceinturait","lemma":"ceinturer","pos":"VER"} ,
		{"word":"ceinturant","word_nosc":"ceinturant","lemma":"ceinturer","pos":"VER"} ,
		{"word":"ceinture","word_nosc":"ceinture","lemma":"ceinturer","pos":"VER"} ,
		{"word":"ceinturent","word_nosc":"ceinturent","lemma":"ceinturer","pos":"VER"} ,
		{"word":"ceinturer","word_nosc":"ceinturer","lemma":"ceinturer","pos":"VER"} ,
		{"word":"ceintures","word_nosc":"ceintures","lemma":"ceinturer","pos":"VER"} ,
		{"word":"ceinturez","word_nosc":"ceinturez","lemma":"ceinturer","pos":"VER"} ,
		{"word":"ceinturât","word_nosc":"ceinturat","lemma":"ceinturer","pos":"VER"} ,
		{"word":"ceinturèrent","word_nosc":"ceinturerent","lemma":"ceinturer","pos":"VER"} ,
		{"word":"ceinturé","word_nosc":"ceinture","lemma":"ceinturer","pos":"VER"} ,
		{"word":"ceinturée","word_nosc":"ceinturee","lemma":"ceinturer","pos":"VER"} ,
		{"word":"ceinturées","word_nosc":"ceinturees","lemma":"ceinturer","pos":"VER"} ,
		{"word":"ceinturés","word_nosc":"ceintures","lemma":"ceinturer","pos":"VER"} ,
		{"word":"celait","word_nosc":"celait","lemma":"celer","pos":"VER"} ,
		{"word":"celer","word_nosc":"celer","lemma":"celer","pos":"VER"} ,
		{"word":"celée","word_nosc":"celee","lemma":"celer","pos":"VER"} ,
		{"word":"cendraient","word_nosc":"cendraient","lemma":"cendrer","pos":"VER"} ,
		{"word":"cendrait","word_nosc":"cendrait","lemma":"cendrer","pos":"VER"} ,
		{"word":"cendré","word_nosc":"cendre","lemma":"cendrer","pos":"VER"} ,
		{"word":"cendrées","word_nosc":"cendrees","lemma":"cendrer","pos":"VER"} ,
		{"word":"cendrés","word_nosc":"cendres","lemma":"cendrer","pos":"VER"} ,
		{"word":"censuraient","word_nosc":"censuraient","lemma":"censurer","pos":"VER"} ,
		{"word":"censurait","word_nosc":"censurait","lemma":"censurer","pos":"VER"} ,
		{"word":"censurant","word_nosc":"censurant","lemma":"censurer","pos":"VER"} ,
		{"word":"censure","word_nosc":"censure","lemma":"censurer","pos":"VER"} ,
		{"word":"censurer","word_nosc":"censurer","lemma":"censurer","pos":"VER"} ,
		{"word":"censures","word_nosc":"censures","lemma":"censurer","pos":"VER"} ,
		{"word":"censurez","word_nosc":"censurez","lemma":"censurer","pos":"VER"} ,
		{"word":"censuriez","word_nosc":"censuriez","lemma":"censurer","pos":"VER"} ,
		{"word":"censuré","word_nosc":"censure","lemma":"censurer","pos":"VER"} ,
		{"word":"censurée","word_nosc":"censuree","lemma":"censurer","pos":"VER"} ,
		{"word":"censurées","word_nosc":"censurees","lemma":"censurer","pos":"VER"} ,
		{"word":"censurés","word_nosc":"censures","lemma":"censurer","pos":"VER"} ,
		{"word":"centrait","word_nosc":"centrait","lemma":"centrer","pos":"VER"} ,
		{"word":"centralisait","word_nosc":"centralisait","lemma":"centraliser","pos":"VER"} ,
		{"word":"centralise","word_nosc":"centralise","lemma":"centraliser","pos":"VER"} ,
		{"word":"centraliser","word_nosc":"centraliser","lemma":"centraliser","pos":"VER"} ,
		{"word":"centralisiez","word_nosc":"centralisiez","lemma":"centraliser","pos":"VER"} ,
		{"word":"centralisons","word_nosc":"centralisons","lemma":"centraliser","pos":"VER"} ,
		{"word":"centralisé","word_nosc":"centralise","lemma":"centraliser","pos":"VER"} ,
		{"word":"centralisée","word_nosc":"centralisee","lemma":"centraliser","pos":"VER"} ,
		{"word":"centralisés","word_nosc":"centralises","lemma":"centraliser","pos":"VER"} ,
		{"word":"centrant","word_nosc":"centrant","lemma":"centrer","pos":"VER"} ,
		{"word":"centre","word_nosc":"centre","lemma":"centrer","pos":"VER"} ,
		{"word":"centrer","word_nosc":"centrer","lemma":"centrer","pos":"VER"} ,
		{"word":"centres","word_nosc":"centres","lemma":"centrer","pos":"VER"} ,
		{"word":"centré","word_nosc":"centre","lemma":"centrer","pos":"VER"} ,
		{"word":"centrée","word_nosc":"centree","lemma":"centrer","pos":"VER"} ,
		{"word":"centrées","word_nosc":"centrees","lemma":"centrer","pos":"VER"} ,
		{"word":"centrés","word_nosc":"centres","lemma":"centrer","pos":"VER"} ,
		{"word":"centuplé","word_nosc":"centuple","lemma":"centupler","pos":"VER"} ,
		{"word":"centuplée","word_nosc":"centuplee","lemma":"centupler","pos":"VER"} ,
		{"word":"cerclait","word_nosc":"cerclait","lemma":"cercler","pos":"VER"} ,
		{"word":"cerclant","word_nosc":"cerclant","lemma":"cercler","pos":"VER"} ,
		{"word":"cercle","word_nosc":"cercle","lemma":"cercler","pos":"VER"} ,
		{"word":"cercler","word_nosc":"cercler","lemma":"cercler","pos":"VER"} ,
		{"word":"cercles","word_nosc":"cercles","lemma":"cercler","pos":"VER"} ,
		{"word":"cerclé","word_nosc":"cercle","lemma":"cercler","pos":"VER"} ,
		{"word":"cerclée","word_nosc":"cerclee","lemma":"cercler","pos":"VER"} ,
		{"word":"cerclées","word_nosc":"cerclees","lemma":"cercler","pos":"VER"} ,
		{"word":"cerclés","word_nosc":"cercles","lemma":"cercler","pos":"VER"} ,
		{"word":"cerna","word_nosc":"cerna","lemma":"cerner","pos":"VER"} ,
		{"word":"cernaient","word_nosc":"cernaient","lemma":"cerner","pos":"VER"} ,
		{"word":"cernais","word_nosc":"cernais","lemma":"cerner","pos":"VER"} ,
		{"word":"cernait","word_nosc":"cernait","lemma":"cerner","pos":"VER"} ,
		{"word":"cernant","word_nosc":"cernant","lemma":"cerner","pos":"VER"} ,
		{"word":"cerne","word_nosc":"cerne","lemma":"cerner","pos":"VER"} ,
		{"word":"cernent","word_nosc":"cernent","lemma":"cerner","pos":"VER"} ,
		{"word":"cerner","word_nosc":"cerner","lemma":"cerner","pos":"VER"} ,
		{"word":"cernez","word_nosc":"cernez","lemma":"cerner","pos":"VER"} ,
		{"word":"cerniez","word_nosc":"cerniez","lemma":"cerner","pos":"VER"} ,
		{"word":"cernons","word_nosc":"cernons","lemma":"cerner","pos":"VER"} ,
		{"word":"cerné","word_nosc":"cerne","lemma":"cerner","pos":"VER"} ,
		{"word":"cernée","word_nosc":"cernee","lemma":"cerner","pos":"VER"} ,
		{"word":"cernées","word_nosc":"cernees","lemma":"cerner","pos":"VER"} ,
		{"word":"cernés","word_nosc":"cernes","lemma":"cerner","pos":"VER"} ,
		{"word":"certifia","word_nosc":"certifia","lemma":"certifier","pos":"VER"} ,
		{"word":"certifiaient","word_nosc":"certifiaient","lemma":"certifier","pos":"VER"} ,
		{"word":"certifiait","word_nosc":"certifiait","lemma":"certifier","pos":"VER"} ,
		{"word":"certifiant","word_nosc":"certifiant","lemma":"certifier","pos":"VER"} ,
		{"word":"certifie","word_nosc":"certifie","lemma":"certifier","pos":"VER"} ,
		{"word":"certifier","word_nosc":"certifier","lemma":"certifier","pos":"VER"} ,
		{"word":"certifierait","word_nosc":"certifierait","lemma":"certifier","pos":"VER"} ,
		{"word":"certifieront","word_nosc":"certifieront","lemma":"certifier","pos":"VER"} ,
		{"word":"certifiez","word_nosc":"certifiez","lemma":"certifier","pos":"VER"} ,
		{"word":"certifions","word_nosc":"certifions","lemma":"certifier","pos":"VER"} ,
		{"word":"certifié","word_nosc":"certifie","lemma":"certifier","pos":"VER"} ,
		{"word":"certifiée","word_nosc":"certifiee","lemma":"certifier","pos":"VER"} ,
		{"word":"certifiées","word_nosc":"certifiees","lemma":"certifier","pos":"VER"} ,
		{"word":"certifiés","word_nosc":"certifies","lemma":"certifier","pos":"VER"} ,
		{"word":"cessa","word_nosc":"cessa","lemma":"cesser","pos":"VER"} ,
		{"word":"cessai","word_nosc":"cessai","lemma":"cesser","pos":"VER"} ,
		{"word":"cessaient","word_nosc":"cessaient","lemma":"cesser","pos":"VER"} ,
		{"word":"cessais","word_nosc":"cessais","lemma":"cesser","pos":"VER"} ,
		{"word":"cessait","word_nosc":"cessait","lemma":"cesser","pos":"VER"} ,
		{"word":"cessant","word_nosc":"cessant","lemma":"cesser","pos":"VER"} ,
		{"word":"cessasse","word_nosc":"cessasse","lemma":"cesser","pos":"VER"} ,
		{"word":"cesse","word_nosc":"cesse","lemma":"cesser","pos":"VER"} ,
		{"word":"cessent","word_nosc":"cessent","lemma":"cesser","pos":"VER"} ,
		{"word":"cesser","word_nosc":"cesser","lemma":"cesser","pos":"VER"} ,
		{"word":"cessera","word_nosc":"cessera","lemma":"cesser","pos":"VER"} ,
		{"word":"cesserai","word_nosc":"cesserai","lemma":"cesser","pos":"VER"} ,
		{"word":"cesseraient","word_nosc":"cesseraient","lemma":"cesser","pos":"VER"} ,
		{"word":"cesserais","word_nosc":"cesserais","lemma":"cesser","pos":"VER"} ,
		{"word":"cesserait","word_nosc":"cesserait","lemma":"cesser","pos":"VER"} ,
		{"word":"cesseras","word_nosc":"cesseras","lemma":"cesser","pos":"VER"} ,
		{"word":"cesserez","word_nosc":"cesserez","lemma":"cesser","pos":"VER"} ,
		{"word":"cesseriez","word_nosc":"cesseriez","lemma":"cesser","pos":"VER"} ,
		{"word":"cesserons","word_nosc":"cesserons","lemma":"cesser","pos":"VER"} ,
		{"word":"cesseront","word_nosc":"cesseront","lemma":"cesser","pos":"VER"} ,
		{"word":"cesses","word_nosc":"cesses","lemma":"cesser","pos":"VER"} ,
		{"word":"cessez","word_nosc":"cessez","lemma":"cesser","pos":"VER"} ,
		{"word":"cessiez","word_nosc":"cessiez","lemma":"cesser","pos":"VER"} ,
		{"word":"cessions","word_nosc":"cessions","lemma":"cesser","pos":"VER"} ,
		{"word":"cessons","word_nosc":"cessons","lemma":"cesser","pos":"VER"} ,
		{"word":"cessâmes","word_nosc":"cessames","lemma":"cesser","pos":"VER"} ,
		{"word":"cessât","word_nosc":"cessat","lemma":"cesser","pos":"VER"} ,
		{"word":"cessèrent","word_nosc":"cesserent","lemma":"cesser","pos":"VER"} ,
		{"word":"cessé","word_nosc":"cesse","lemma":"cesser","pos":"VER"} ,
		{"word":"cessée","word_nosc":"cessee","lemma":"cesser","pos":"VER"} ,
		{"word":"chabler","word_nosc":"chabler","lemma":"chabler","pos":"VER"} ,
		{"word":"chagrina","word_nosc":"chagrina","lemma":"chagriner","pos":"VER"} ,
		{"word":"chagrinaient","word_nosc":"chagrinaient","lemma":"chagriner","pos":"VER"} ,
		{"word":"chagrinait","word_nosc":"chagrinait","lemma":"chagriner","pos":"VER"} ,
		{"word":"chagrine","word_nosc":"chagrine","lemma":"chagriner","pos":"VER"} ,
		{"word":"chagrinent","word_nosc":"chagrinent","lemma":"chagriner","pos":"VER"} ,
		{"word":"chagriner","word_nosc":"chagriner","lemma":"chagriner","pos":"VER"} ,
		{"word":"chagrinerait","word_nosc":"chagrinerait","lemma":"chagriner","pos":"VER"} ,
		{"word":"chagrines","word_nosc":"chagrines","lemma":"chagriner","pos":"VER"} ,
		{"word":"chagriné","word_nosc":"chagrine","lemma":"chagriner","pos":"VER"} ,
		{"word":"chagrinée","word_nosc":"chagrinee","lemma":"chagriner","pos":"VER"} ,
		{"word":"chagrinés","word_nosc":"chagrines","lemma":"chagriner","pos":"VER"} ,
		{"word":"chahutaient","word_nosc":"chahutaient","lemma":"chahuter","pos":"VER"} ,
		{"word":"chahutais","word_nosc":"chahutais","lemma":"chahuter","pos":"VER"} ,
		{"word":"chahutait","word_nosc":"chahutait","lemma":"chahuter","pos":"VER"} ,
		{"word":"chahutant","word_nosc":"chahutant","lemma":"chahuter","pos":"VER"} ,
		{"word":"chahute","word_nosc":"chahute","lemma":"chahuter","pos":"VER"} ,
		{"word":"chahutent","word_nosc":"chahutent","lemma":"chahuter","pos":"VER"} ,
		{"word":"chahuter","word_nosc":"chahuter","lemma":"chahuter","pos":"VER"} ,
		{"word":"chahuteraient","word_nosc":"chahuteraient","lemma":"chahuter","pos":"VER"} ,
		{"word":"chahutez","word_nosc":"chahutez","lemma":"chahuter","pos":"VER"} ,
		{"word":"chahutèrent","word_nosc":"chahuterent","lemma":"chahuter","pos":"VER"} ,
		{"word":"chahuté","word_nosc":"chahute","lemma":"chahuter","pos":"VER"} ,
		{"word":"chahutée","word_nosc":"chahutee","lemma":"chahuter","pos":"VER"} ,
		{"word":"chahutées","word_nosc":"chahutees","lemma":"chahuter","pos":"VER"} ,
		{"word":"chahutés","word_nosc":"chahutes","lemma":"chahuter","pos":"VER"} ,
		{"word":"challenger","word_nosc":"challenger","lemma":"challenger","pos":"VER"} ,
		{"word":"chaloupait","word_nosc":"chaloupait","lemma":"chalouper","pos":"VER"} ,
		{"word":"chaloupant","word_nosc":"chaloupant","lemma":"chalouper","pos":"VER"} ,
		{"word":"chalouper","word_nosc":"chalouper","lemma":"chalouper","pos":"VER"} ,
		{"word":"chaloupé","word_nosc":"chaloupe","lemma":"chalouper","pos":"VER"} ,
		{"word":"chaloupée","word_nosc":"chaloupee","lemma":"chalouper","pos":"VER"} ,
		{"word":"chaloupées","word_nosc":"chaloupees","lemma":"chalouper","pos":"VER"} ,
		{"word":"chaloupés","word_nosc":"chaloupes","lemma":"chalouper","pos":"VER"} ,
		{"word":"chamaillaient","word_nosc":"chamaillaient","lemma":"chamailler","pos":"VER"} ,
		{"word":"chamaillait","word_nosc":"chamaillait","lemma":"chamailler","pos":"VER"} ,
		{"word":"chamaillant","word_nosc":"chamaillant","lemma":"chamailler","pos":"VER"} ,
		{"word":"chamaille","word_nosc":"chamaille","lemma":"chamailler","pos":"VER"} ,
		{"word":"chamaillent","word_nosc":"chamaillent","lemma":"chamailler","pos":"VER"} ,
		{"word":"chamailler","word_nosc":"chamailler","lemma":"chamailler","pos":"VER"} ,
		{"word":"chamaillez","word_nosc":"chamaillez","lemma":"chamailler","pos":"VER"} ,
		{"word":"chamaillions","word_nosc":"chamaillions","lemma":"chamailler","pos":"VER"} ,
		{"word":"chamaillons","word_nosc":"chamaillons","lemma":"chamailler","pos":"VER"} ,
		{"word":"chamaillèrent","word_nosc":"chamaillerent","lemma":"chamailler","pos":"VER"} ,
		{"word":"chamaillé","word_nosc":"chamaille","lemma":"chamailler","pos":"VER"} ,
		{"word":"chamaillés","word_nosc":"chamailles","lemma":"chamailler","pos":"VER"} ,
		{"word":"chamarré","word_nosc":"chamarre","lemma":"chamarrer","pos":"VER"} ,
		{"word":"chamarrée","word_nosc":"chamarree","lemma":"chamarrer","pos":"VER"} ,
		{"word":"chamarrées","word_nosc":"chamarrees","lemma":"chamarrer","pos":"VER"} ,
		{"word":"chamarrés","word_nosc":"chamarres","lemma":"chamarrer","pos":"VER"} ,
		{"word":"chambarda","word_nosc":"chambarda","lemma":"chambarder","pos":"VER"} ,
		{"word":"chambardait","word_nosc":"chambardait","lemma":"chambarder","pos":"VER"} ,
		{"word":"chambarder","word_nosc":"chambarder","lemma":"chambarder","pos":"VER"} ,
		{"word":"chambardé","word_nosc":"chambarde","lemma":"chambarder","pos":"VER"} ,
		{"word":"chambardée","word_nosc":"chambardee","lemma":"chambarder","pos":"VER"} ,
		{"word":"chamboulaient","word_nosc":"chamboulaient","lemma":"chambouler","pos":"VER"} ,
		{"word":"chamboulait","word_nosc":"chamboulait","lemma":"chambouler","pos":"VER"} ,
		{"word":"chamboulant","word_nosc":"chamboulant","lemma":"chambouler","pos":"VER"} ,
		{"word":"chamboule","word_nosc":"chamboule","lemma":"chambouler","pos":"VER"} ,
		{"word":"chamboulent","word_nosc":"chamboulent","lemma":"chambouler","pos":"VER"} ,
		{"word":"chambouler","word_nosc":"chambouler","lemma":"chambouler","pos":"VER"} ,
		{"word":"chambouleras","word_nosc":"chambouleras","lemma":"chambouler","pos":"VER"} ,
		{"word":"chamboulez","word_nosc":"chamboulez","lemma":"chambouler","pos":"VER"} ,
		{"word":"chamboulât","word_nosc":"chamboulat","lemma":"chambouler","pos":"VER"} ,
		{"word":"chamboulé","word_nosc":"chamboule","lemma":"chambouler","pos":"VER"} ,
		{"word":"chamboulée","word_nosc":"chamboulee","lemma":"chambouler","pos":"VER"} ,
		{"word":"chamboulées","word_nosc":"chamboulees","lemma":"chambouler","pos":"VER"} ,
		{"word":"chamboulés","word_nosc":"chamboules","lemma":"chambouler","pos":"VER"} ,
		{"word":"chambra","word_nosc":"chambra","lemma":"chambrer","pos":"VER"} ,
		{"word":"chambrais","word_nosc":"chambrais","lemma":"chambrer","pos":"VER"} ,
		{"word":"chambrait","word_nosc":"chambrait","lemma":"chambrer","pos":"VER"} ,
		{"word":"chambrant","word_nosc":"chambrant","lemma":"chambrer","pos":"VER"} ,
		{"word":"chambre","word_nosc":"chambre","lemma":"chambrer","pos":"VER"} ,
		{"word":"chambrent","word_nosc":"chambrent","lemma":"chambrer","pos":"VER"} ,
		{"word":"chambrer","word_nosc":"chambrer","lemma":"chambrer","pos":"VER"} ,
		{"word":"chambres","word_nosc":"chambres","lemma":"chambrer","pos":"VER"} ,
		{"word":"chambrez","word_nosc":"chambrez","lemma":"chambrer","pos":"VER"} ,
		{"word":"chambré","word_nosc":"chambre","lemma":"chambrer","pos":"VER"} ,
		{"word":"chambrée","word_nosc":"chambree","lemma":"chambrer","pos":"VER"} ,
		{"word":"champagnisés","word_nosc":"champagnises","lemma":"champagniser","pos":"VER"} ,
		{"word":"champignonnaient","word_nosc":"champignonnaient","lemma":"champignonner","pos":"VER"} ,
		{"word":"champignonnait","word_nosc":"champignonnait","lemma":"champignonner","pos":"VER"} ,
		{"word":"champignonne","word_nosc":"champignonne","lemma":"champignonner","pos":"VER"} ,
		{"word":"champignonnent","word_nosc":"champignonnent","lemma":"champignonner","pos":"VER"} ,
		{"word":"chancela","word_nosc":"chancela","lemma":"chanceler","pos":"VER"} ,
		{"word":"chancelai","word_nosc":"chancelai","lemma":"chanceler","pos":"VER"} ,
		{"word":"chancelaient","word_nosc":"chancelaient","lemma":"chanceler","pos":"VER"} ,
		{"word":"chancelais","word_nosc":"chancelais","lemma":"chanceler","pos":"VER"} ,
		{"word":"chancelait","word_nosc":"chancelait","lemma":"chanceler","pos":"VER"} ,
		{"word":"chancelant","word_nosc":"chancelant","lemma":"chanceler","pos":"VER"} ,
		{"word":"chanceler","word_nosc":"chanceler","lemma":"chanceler","pos":"VER"} ,
		{"word":"chancelions","word_nosc":"chancelions","lemma":"chanceler","pos":"VER"} ,
		{"word":"chancelle","word_nosc":"chancelle","lemma":"chanceler","pos":"VER"} ,
		{"word":"chancellent","word_nosc":"chancellent","lemma":"chanceler","pos":"VER"} ,
		{"word":"chancelèrent","word_nosc":"chancelerent","lemma":"chanceler","pos":"VER"} ,
		{"word":"chancelé","word_nosc":"chancele","lemma":"chanceler","pos":"VER"} ,
		{"word":"chanci","word_nosc":"chanci","lemma":"chancir","pos":"VER"} ,
		{"word":"chanfreinée","word_nosc":"chanfreinee","lemma":"chanfreiner","pos":"VER"} ,
		{"word":"change","word_nosc":"change","lemma":"changer","pos":"VER"} ,
		{"word":"changea","word_nosc":"changea","lemma":"changer","pos":"VER"} ,
		{"word":"changeai","word_nosc":"changeai","lemma":"changer","pos":"VER"} ,
		{"word":"changeaient","word_nosc":"changeaient","lemma":"changer","pos":"VER"} ,
		{"word":"changeais","word_nosc":"changeais","lemma":"changer","pos":"VER"} ,
		{"word":"changeait","word_nosc":"changeait","lemma":"changer","pos":"VER"} ,
		{"word":"changeant","word_nosc":"changeant","lemma":"changer","pos":"VER"} ,
		{"word":"changeas","word_nosc":"changeas","lemma":"changer","pos":"VER"} ,
		{"word":"changent","word_nosc":"changent","lemma":"changer","pos":"VER"} ,
		{"word":"changeons","word_nosc":"changeons","lemma":"changer","pos":"VER"} ,
		{"word":"changer","word_nosc":"changer","lemma":"changer","pos":"VER"} ,
		{"word":"changera","word_nosc":"changera","lemma":"changer","pos":"VER"} ,
		{"word":"changerai","word_nosc":"changerai","lemma":"changer","pos":"VER"} ,
		{"word":"changeraient","word_nosc":"changeraient","lemma":"changer","pos":"VER"} ,
		{"word":"changerais","word_nosc":"changerais","lemma":"changer","pos":"VER"} ,
		{"word":"changerait","word_nosc":"changerait","lemma":"changer","pos":"VER"} ,
		{"word":"changeras","word_nosc":"changeras","lemma":"changer","pos":"VER"} ,
		{"word":"changerez","word_nosc":"changerez","lemma":"changer","pos":"VER"} ,
		{"word":"changeriez","word_nosc":"changeriez","lemma":"changer","pos":"VER"} ,
		{"word":"changerons","word_nosc":"changerons","lemma":"changer","pos":"VER"} ,
		{"word":"changeront","word_nosc":"changeront","lemma":"changer","pos":"VER"} ,
		{"word":"changes","word_nosc":"changes","lemma":"changer","pos":"VER"} ,
		{"word":"changez","word_nosc":"changez","lemma":"changer","pos":"VER"} ,
		{"word":"changeâmes","word_nosc":"changeames","lemma":"changer","pos":"VER"} ,
		{"word":"changeât","word_nosc":"changeat","lemma":"changer","pos":"VER"} ,
		{"word":"changiez","word_nosc":"changiez","lemma":"changer","pos":"VER"} ,
		{"word":"changions","word_nosc":"changions","lemma":"changer","pos":"VER"} ,
		{"word":"changèrent","word_nosc":"changerent","lemma":"changer","pos":"VER"} ,
		{"word":"changé","word_nosc":"change","lemma":"changer","pos":"VER"} ,
		{"word":"changée","word_nosc":"changee","lemma":"changer","pos":"VER"} ,
		{"word":"changées","word_nosc":"changees","lemma":"changer","pos":"VER"} ,
		{"word":"changés","word_nosc":"changes","lemma":"changer","pos":"VER"} ,
		{"word":"chansonner","word_nosc":"chansonner","lemma":"chansonner","pos":"VER"} ,
		{"word":"chanstiquait","word_nosc":"chanstiquait","lemma":"chanstiquer","pos":"VER"} ,
		{"word":"chanstique","word_nosc":"chanstique","lemma":"chanstiquer","pos":"VER"} ,
		{"word":"chanstiquent","word_nosc":"chanstiquent","lemma":"chanstiquer","pos":"VER"} ,
		{"word":"chanstiquer","word_nosc":"chanstiquer","lemma":"chanstiquer","pos":"VER"} ,
		{"word":"chanstiquèrent","word_nosc":"chanstiquerent","lemma":"chanstiquer","pos":"VER"} ,
		{"word":"chanstiqué","word_nosc":"chanstique","lemma":"chanstiquer","pos":"VER"} ,
		{"word":"chanstiquée","word_nosc":"chanstiquee","lemma":"chanstiquer","pos":"VER"} ,
		{"word":"chanta","word_nosc":"chanta","lemma":"chanter","pos":"VER"} ,
		{"word":"chantai","word_nosc":"chantai","lemma":"chanter","pos":"VER"} ,
		{"word":"chantaient","word_nosc":"chantaient","lemma":"chanter","pos":"VER"} ,
		{"word":"chantais","word_nosc":"chantais","lemma":"chanter","pos":"VER"} ,
		{"word":"chantait","word_nosc":"chantait","lemma":"chanter","pos":"VER"} ,
		{"word":"chantant","word_nosc":"chantant","lemma":"chanter","pos":"VER"} ,
		{"word":"chante","word_nosc":"chante","lemma":"chanter","pos":"VER"} ,
		{"word":"chantent","word_nosc":"chantent","lemma":"chanter","pos":"VER"} ,
		{"word":"chanter","word_nosc":"chanter","lemma":"chanter","pos":"VER"} ,
		{"word":"chantera","word_nosc":"chantera","lemma":"chanter","pos":"VER"} ,
		{"word":"chanterai","word_nosc":"chanterai","lemma":"chanter","pos":"VER"} ,
		{"word":"chanteraient","word_nosc":"chanteraient","lemma":"chanter","pos":"VER"} ,
		{"word":"chanterais","word_nosc":"chanterais","lemma":"chanter","pos":"VER"} ,
		{"word":"chanterait","word_nosc":"chanterait","lemma":"chanter","pos":"VER"} ,
		{"word":"chanteras","word_nosc":"chanteras","lemma":"chanter","pos":"VER"} ,
		{"word":"chanterez","word_nosc":"chanterez","lemma":"chanter","pos":"VER"} ,
		{"word":"chanteriez","word_nosc":"chanteriez","lemma":"chanter","pos":"VER"} ,
		{"word":"chanterons","word_nosc":"chanterons","lemma":"chanter","pos":"VER"} ,
		{"word":"chanteront","word_nosc":"chanteront","lemma":"chanter","pos":"VER"} ,
		{"word":"chantes","word_nosc":"chantes","lemma":"chanter","pos":"VER"} ,
		{"word":"chantez","word_nosc":"chantez","lemma":"chanter","pos":"VER"} ,
		{"word":"chantiez","word_nosc":"chantiez","lemma":"chanter","pos":"VER"} ,
		{"word":"chantions","word_nosc":"chantions","lemma":"chanter","pos":"VER"} ,
		{"word":"chantonna","word_nosc":"chantonna","lemma":"chantonner","pos":"VER"} ,
		{"word":"chantonnai","word_nosc":"chantonnai","lemma":"chantonner","pos":"VER"} ,
		{"word":"chantonnaient","word_nosc":"chantonnaient","lemma":"chantonner","pos":"VER"} ,
		{"word":"chantonnais","word_nosc":"chantonnais","lemma":"chantonner","pos":"VER"} ,
		{"word":"chantonnait","word_nosc":"chantonnait","lemma":"chantonner","pos":"VER"} ,
		{"word":"chantonnant","word_nosc":"chantonnant","lemma":"chantonner","pos":"VER"} ,
		{"word":"chantonne","word_nosc":"chantonne","lemma":"chantonner","pos":"VER"} ,
		{"word":"chantonnent","word_nosc":"chantonnent","lemma":"chantonner","pos":"VER"} ,
		{"word":"chantonner","word_nosc":"chantonner","lemma":"chantonner","pos":"VER"} ,
		{"word":"chantonnèrent","word_nosc":"chantonnerent","lemma":"chantonner","pos":"VER"} ,
		{"word":"chantonné","word_nosc":"chantonne","lemma":"chantonner","pos":"VER"} ,
		{"word":"chantonnée","word_nosc":"chantonnee","lemma":"chantonner","pos":"VER"} ,
		{"word":"chantonnés","word_nosc":"chantonnes","lemma":"chantonner","pos":"VER"} ,
		{"word":"chantons","word_nosc":"chantons","lemma":"chanter","pos":"VER"} ,
		{"word":"chantourner","word_nosc":"chantourner","lemma":"chantourner","pos":"VER"} ,
		{"word":"chantourné","word_nosc":"chantourne","lemma":"chantourner","pos":"VER"} ,
		{"word":"chantournées","word_nosc":"chantournees","lemma":"chantourner","pos":"VER"} ,
		{"word":"chantournés","word_nosc":"chantournes","lemma":"chantourner","pos":"VER"} ,
		{"word":"chantâmes","word_nosc":"chantames","lemma":"chanter","pos":"VER"} ,
		{"word":"chantât","word_nosc":"chantat","lemma":"chanter","pos":"VER"} ,
		{"word":"chantèrent","word_nosc":"chanterent","lemma":"chanter","pos":"VER"} ,
		{"word":"chanté","word_nosc":"chante","lemma":"chanter","pos":"VER"} ,
		{"word":"chantée","word_nosc":"chantee","lemma":"chanter","pos":"VER"} ,
		{"word":"chantées","word_nosc":"chantees","lemma":"chanter","pos":"VER"} ,
		{"word":"chantés","word_nosc":"chantes","lemma":"chanter","pos":"VER"} ,
		{"word":"chaparda","word_nosc":"chaparda","lemma":"chaparder","pos":"VER"} ,
		{"word":"chapardait","word_nosc":"chapardait","lemma":"chaparder","pos":"VER"} ,
		{"word":"chapardant","word_nosc":"chapardant","lemma":"chaparder","pos":"VER"} ,
		{"word":"chapardent","word_nosc":"chapardent","lemma":"chaparder","pos":"VER"} ,
		{"word":"chaparder","word_nosc":"chaparder","lemma":"chaparder","pos":"VER"} ,
		{"word":"chapardé","word_nosc":"chaparde","lemma":"chaparder","pos":"VER"} ,
		{"word":"chapardée","word_nosc":"chapardee","lemma":"chaparder","pos":"VER"} ,
		{"word":"chapardées","word_nosc":"chapardees","lemma":"chaparder","pos":"VER"} ,
		{"word":"chapeautait","word_nosc":"chapeautait","lemma":"chapeauter","pos":"VER"} ,
		{"word":"chapeaute","word_nosc":"chapeaute","lemma":"chapeauter","pos":"VER"} ,
		{"word":"chapeauter","word_nosc":"chapeauter","lemma":"chapeauter","pos":"VER"} ,
		{"word":"chapeauté","word_nosc":"chapeaute","lemma":"chapeauter","pos":"VER"} ,
		{"word":"chapeautée","word_nosc":"chapeautee","lemma":"chapeauter","pos":"VER"} ,
		{"word":"chapeautées","word_nosc":"chapeautees","lemma":"chapeauter","pos":"VER"} ,
		{"word":"chapeautés","word_nosc":"chapeautes","lemma":"chapeauter","pos":"VER"} ,
		{"word":"chaperonnais","word_nosc":"chaperonnais","lemma":"chaperonner","pos":"VER"} ,
		{"word":"chaperonnait","word_nosc":"chaperonnait","lemma":"chaperonner","pos":"VER"} ,
		{"word":"chaperonner","word_nosc":"chaperonner","lemma":"chaperonner","pos":"VER"} ,
		{"word":"chaperonné","word_nosc":"chaperonne","lemma":"chaperonner","pos":"VER"} ,
		{"word":"chaperonnée","word_nosc":"chaperonnee","lemma":"chaperonner","pos":"VER"} ,
		{"word":"chaperonnés","word_nosc":"chaperonnes","lemma":"chaperonner","pos":"VER"} ,
		{"word":"chapitre","word_nosc":"chapitre","lemma":"chapitrer","pos":"VER"} ,
		{"word":"chapitrer","word_nosc":"chapitrer","lemma":"chapitrer","pos":"VER"} ,
		{"word":"chapitres","word_nosc":"chapitres","lemma":"chapitrer","pos":"VER"} ,
		{"word":"chapitré","word_nosc":"chapitre","lemma":"chapitrer","pos":"VER"} ,
		{"word":"chapitrée","word_nosc":"chapitree","lemma":"chapitrer","pos":"VER"} ,
		{"word":"chapitrées","word_nosc":"chapitrees","lemma":"chapitrer","pos":"VER"} ,
		{"word":"charbonnaient","word_nosc":"charbonnaient","lemma":"charbonner","pos":"VER"} ,
		{"word":"charbonnait","word_nosc":"charbonnait","lemma":"charbonner","pos":"VER"} ,
		{"word":"charbonne","word_nosc":"charbonne","lemma":"charbonner","pos":"VER"} ,
		{"word":"charbonnent","word_nosc":"charbonnent","lemma":"charbonner","pos":"VER"} ,
		{"word":"charbonner","word_nosc":"charbonner","lemma":"charbonner","pos":"VER"} ,
		{"word":"charbonné","word_nosc":"charbonne","lemma":"charbonner","pos":"VER"} ,
		{"word":"charbonnées","word_nosc":"charbonnees","lemma":"charbonner","pos":"VER"} ,
		{"word":"charbonnés","word_nosc":"charbonnes","lemma":"charbonner","pos":"VER"} ,
		{"word":"charcutaient","word_nosc":"charcutaient","lemma":"charcuter","pos":"VER"} ,
		{"word":"charcutait","word_nosc":"charcutait","lemma":"charcuter","pos":"VER"} ,
		{"word":"charcute","word_nosc":"charcute","lemma":"charcuter","pos":"VER"} ,
		{"word":"charcuter","word_nosc":"charcuter","lemma":"charcuter","pos":"VER"} ,
		{"word":"charcutez","word_nosc":"charcutez","lemma":"charcuter","pos":"VER"} ,
		{"word":"charcuté","word_nosc":"charcute","lemma":"charcuter","pos":"VER"} ,
		{"word":"charcutée","word_nosc":"charcutee","lemma":"charcuter","pos":"VER"} ,
		{"word":"charcutées","word_nosc":"charcutees","lemma":"charcuter","pos":"VER"} ,
		{"word":"charcutés","word_nosc":"charcutes","lemma":"charcuter","pos":"VER"} ,
		{"word":"charge","word_nosc":"charge","lemma":"charger","pos":"VER"} ,
		{"word":"chargea","word_nosc":"chargea","lemma":"charger","pos":"VER"} ,
		{"word":"chargeai","word_nosc":"chargeai","lemma":"charger","pos":"VER"} ,
		{"word":"chargeaient","word_nosc":"chargeaient","lemma":"charger","pos":"VER"} ,
		{"word":"chargeais","word_nosc":"chargeais","lemma":"charger","pos":"VER"} ,
		{"word":"chargeait","word_nosc":"chargeait","lemma":"charger","pos":"VER"} ,
		{"word":"chargeant","word_nosc":"chargeant","lemma":"charger","pos":"VER"} ,
		{"word":"chargent","word_nosc":"chargent","lemma":"charger","pos":"VER"} ,
		{"word":"chargeons","word_nosc":"chargeons","lemma":"charger","pos":"VER"} ,
		{"word":"charger","word_nosc":"charger","lemma":"charger","pos":"VER"} ,
		{"word":"chargera","word_nosc":"chargera","lemma":"charger","pos":"VER"} ,
		{"word":"chargerai","word_nosc":"chargerai","lemma":"charger","pos":"VER"} ,
		{"word":"chargeraient","word_nosc":"chargeraient","lemma":"charger","pos":"VER"} ,
		{"word":"chargerais","word_nosc":"chargerais","lemma":"charger","pos":"VER"} ,
		{"word":"chargerait","word_nosc":"chargerait","lemma":"charger","pos":"VER"} ,
		{"word":"chargeras","word_nosc":"chargeras","lemma":"charger","pos":"VER"} ,
		{"word":"chargerez","word_nosc":"chargerez","lemma":"charger","pos":"VER"} ,
		{"word":"chargerons","word_nosc":"chargerons","lemma":"charger","pos":"VER"} ,
		{"word":"chargeront","word_nosc":"chargeront","lemma":"charger","pos":"VER"} ,
		{"word":"charges","word_nosc":"charges","lemma":"charger","pos":"VER"} ,
		{"word":"chargez","word_nosc":"chargez","lemma":"charger","pos":"VER"} ,
		{"word":"chargeât","word_nosc":"chargeat","lemma":"charger","pos":"VER"} ,
		{"word":"chargiez","word_nosc":"chargiez","lemma":"charger","pos":"VER"} ,
		{"word":"chargions","word_nosc":"chargions","lemma":"charger","pos":"VER"} ,
		{"word":"chargèrent","word_nosc":"chargerent","lemma":"charger","pos":"VER"} ,
		{"word":"chargé","word_nosc":"charge","lemma":"charger","pos":"VER"} ,
		{"word":"chargée","word_nosc":"chargee","lemma":"charger","pos":"VER"} ,
		{"word":"chargées","word_nosc":"chargees","lemma":"charger","pos":"VER"} ,
		{"word":"chargés","word_nosc":"charges","lemma":"charger","pos":"VER"} ,
		{"word":"charlataner","word_nosc":"charlataner","lemma":"charlataner","pos":"VER"} ,
		{"word":"charma","word_nosc":"charma","lemma":"charmer","pos":"VER"} ,
		{"word":"charmaient","word_nosc":"charmaient","lemma":"charmer","pos":"VER"} ,
		{"word":"charmait","word_nosc":"charmait","lemma":"charmer","pos":"VER"} ,
		{"word":"charmant","word_nosc":"charmant","lemma":"charmer","pos":"VER"} ,
		{"word":"charme","word_nosc":"charme","lemma":"charmer","pos":"VER"} ,
		{"word":"charment","word_nosc":"charment","lemma":"charmer","pos":"VER"} ,
		{"word":"charmer","word_nosc":"charmer","lemma":"charmer","pos":"VER"} ,
		{"word":"charmera","word_nosc":"charmera","lemma":"charmer","pos":"VER"} ,
		{"word":"charmes","word_nosc":"charmes","lemma":"charmer","pos":"VER"} ,
		{"word":"charmé","word_nosc":"charme","lemma":"charmer","pos":"VER"} ,
		{"word":"charmée","word_nosc":"charmee","lemma":"charmer","pos":"VER"} ,
		{"word":"charmés","word_nosc":"charmes","lemma":"charmer","pos":"VER"} ,
		{"word":"charpente","word_nosc":"charpente","lemma":"charpenter","pos":"VER"} ,
		{"word":"charpenter","word_nosc":"charpenter","lemma":"charpenter","pos":"VER"} ,
		{"word":"charpenté","word_nosc":"charpente","lemma":"charpenter","pos":"VER"} ,
		{"word":"charpentée","word_nosc":"charpentee","lemma":"charpenter","pos":"VER"} ,
		{"word":"charria","word_nosc":"charria","lemma":"charrier","pos":"VER"} ,
		{"word":"charriaient","word_nosc":"charriaient","lemma":"charrier","pos":"VER"} ,
		{"word":"charriais","word_nosc":"charriais","lemma":"charrier","pos":"VER"} ,
		{"word":"charriait","word_nosc":"charriait","lemma":"charrier","pos":"VER"} ,
		{"word":"charriant","word_nosc":"charriant","lemma":"charrier","pos":"VER"} ,
		{"word":"charrie","word_nosc":"charrie","lemma":"charrier","pos":"VER"} ,
		{"word":"charrient","word_nosc":"charrient","lemma":"charrier","pos":"VER"} ,
		{"word":"charrier","word_nosc":"charrier","lemma":"charrier","pos":"VER"} ,
		{"word":"charrieras","word_nosc":"charrieras","lemma":"charrier","pos":"VER"} ,
		{"word":"charrierons","word_nosc":"charrierons","lemma":"charrier","pos":"VER"} ,
		{"word":"charrieront","word_nosc":"charrieront","lemma":"charrier","pos":"VER"} ,
		{"word":"charries","word_nosc":"charries","lemma":"charrier","pos":"VER"} ,
		{"word":"charriez","word_nosc":"charriez","lemma":"charrier","pos":"VER"} ,
		{"word":"charrions","word_nosc":"charrions","lemma":"charrier","pos":"VER"} ,
		{"word":"charrièrent","word_nosc":"charrierent","lemma":"charrier","pos":"VER"} ,
		{"word":"charrié","word_nosc":"charrie","lemma":"charrier","pos":"VER"} ,
		{"word":"charriée","word_nosc":"charriee","lemma":"charrier","pos":"VER"} ,
		{"word":"charriées","word_nosc":"charriees","lemma":"charrier","pos":"VER"} ,
		{"word":"charriés","word_nosc":"charries","lemma":"charrier","pos":"VER"} ,
		{"word":"charronnées","word_nosc":"charronnees","lemma":"charronner","pos":"VER"} ,
		{"word":"charruée","word_nosc":"charruee","lemma":"charruer","pos":"VER"} ,
		{"word":"chassa","word_nosc":"chassa","lemma":"chasser","pos":"VER"} ,
		{"word":"chassai","word_nosc":"chassai","lemma":"chasser","pos":"VER"} ,
		{"word":"chassaient","word_nosc":"chassaient","lemma":"chasser","pos":"VER"} ,
		{"word":"chassais","word_nosc":"chassais","lemma":"chasser","pos":"VER"} ,
		{"word":"chassait","word_nosc":"chassait","lemma":"chasser","pos":"VER"} ,
		{"word":"chassant","word_nosc":"chassant","lemma":"chasser","pos":"VER"} ,
		{"word":"chasse","word_nosc":"chasse","lemma":"chasser","pos":"VER"} ,
		{"word":"chassent","word_nosc":"chassent","lemma":"chasser","pos":"VER"} ,
		{"word":"chasser","word_nosc":"chasser","lemma":"chasser","pos":"VER"} ,
		{"word":"chassera","word_nosc":"chassera","lemma":"chasser","pos":"VER"} ,
		{"word":"chasserai","word_nosc":"chasserai","lemma":"chasser","pos":"VER"} ,
		{"word":"chasseraient","word_nosc":"chasseraient","lemma":"chasser","pos":"VER"} ,
		{"word":"chasserais","word_nosc":"chasserais","lemma":"chasser","pos":"VER"} ,
		{"word":"chasserait","word_nosc":"chasserait","lemma":"chasser","pos":"VER"} ,
		{"word":"chasseras","word_nosc":"chasseras","lemma":"chasser","pos":"VER"} ,
		{"word":"chasserez","word_nosc":"chasserez","lemma":"chasser","pos":"VER"} ,
		{"word":"chasserions","word_nosc":"chasserions","lemma":"chasser","pos":"VER"} ,
		{"word":"chasserons","word_nosc":"chasserons","lemma":"chasser","pos":"VER"} ,
		{"word":"chasseront","word_nosc":"chasseront","lemma":"chasser","pos":"VER"} ,
		{"word":"chasses","word_nosc":"chasses","lemma":"chasser","pos":"VER"} ,
		{"word":"chassez","word_nosc":"chassez","lemma":"chasser","pos":"VER"} ,
		{"word":"chassiez","word_nosc":"chassiez","lemma":"chasser","pos":"VER"} ,
		{"word":"chassions","word_nosc":"chassions","lemma":"chasser","pos":"VER"} ,
		{"word":"chassons","word_nosc":"chassons","lemma":"chasser","pos":"VER"} ,
		{"word":"chassât","word_nosc":"chassat","lemma":"chasser","pos":"VER"} ,
		{"word":"chassèrent","word_nosc":"chasserent","lemma":"chasser","pos":"VER"} ,
		{"word":"chassé","word_nosc":"chasse","lemma":"chasser","pos":"VER"} ,
		{"word":"chassée","word_nosc":"chassee","lemma":"chasser","pos":"VER"} ,
		{"word":"chassées","word_nosc":"chassees","lemma":"chasser","pos":"VER"} ,
		{"word":"chassés","word_nosc":"chasses","lemma":"chasser","pos":"VER"} ,
		{"word":"chatoient","word_nosc":"chatoient","lemma":"chatoyer","pos":"VER"} ,
		{"word":"chatonne","word_nosc":"chatonne","lemma":"chatonner","pos":"VER"} ,
		{"word":"chatouilla","word_nosc":"chatouilla","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouillaient","word_nosc":"chatouillaient","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouillait","word_nosc":"chatouillait","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouillant","word_nosc":"chatouillant","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouille","word_nosc":"chatouille","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouillent","word_nosc":"chatouillent","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouiller","word_nosc":"chatouiller","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouillera","word_nosc":"chatouillera","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouilles","word_nosc":"chatouilles","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouillez","word_nosc":"chatouillez","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouillons","word_nosc":"chatouillons","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouillât","word_nosc":"chatouillat","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouillèrent","word_nosc":"chatouillerent","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouillé","word_nosc":"chatouille","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouillée","word_nosc":"chatouillee","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouillées","word_nosc":"chatouillees","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatouillés","word_nosc":"chatouilles","lemma":"chatouiller","pos":"VER"} ,
		{"word":"chatoyaient","word_nosc":"chatoyaient","lemma":"chatoyer","pos":"VER"} ,
		{"word":"chatoyait","word_nosc":"chatoyait","lemma":"chatoyer","pos":"VER"} ,
		{"word":"chatoyant","word_nosc":"chatoyant","lemma":"chatoyer","pos":"VER"} ,
		{"word":"chatoyer","word_nosc":"chatoyer","lemma":"chatoyer","pos":"VER"} ,
		{"word":"chattant","word_nosc":"chattant","lemma":"chatter","pos":"VER"} ,
		{"word":"chatter","word_nosc":"chatter","lemma":"chatter","pos":"VER"} ,
		{"word":"chattons","word_nosc":"chattons","lemma":"chatter","pos":"VER"} ,
		{"word":"chaudronné","word_nosc":"chaudronne","lemma":"chaudronner","pos":"VER"} ,
		{"word":"chauffa","word_nosc":"chauffa","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffaient","word_nosc":"chauffaient","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffais","word_nosc":"chauffais","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffait","word_nosc":"chauffait","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffant","word_nosc":"chauffant","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffe","word_nosc":"chauffe","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffent","word_nosc":"chauffent","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffer","word_nosc":"chauffer","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffera","word_nosc":"chauffera","lemma":"chauffer","pos":"VER"} ,
		{"word":"chaufferai","word_nosc":"chaufferai","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffes","word_nosc":"chauffes","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffez","word_nosc":"chauffez","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffiez","word_nosc":"chauffiez","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffions","word_nosc":"chauffions","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffons","word_nosc":"chauffons","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffâmes","word_nosc":"chauffames","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffèrent","word_nosc":"chaufferent","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffé","word_nosc":"chauffe","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffée","word_nosc":"chauffee","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffées","word_nosc":"chauffees","lemma":"chauffer","pos":"VER"} ,
		{"word":"chauffés","word_nosc":"chauffes","lemma":"chauffer","pos":"VER"} ,
		{"word":"chaule","word_nosc":"chaule","lemma":"chauler","pos":"VER"} ,
		{"word":"chauler","word_nosc":"chauler","lemma":"chauler","pos":"VER"} ,
		{"word":"chaulé","word_nosc":"chaule","lemma":"chauler","pos":"VER"} ,
		{"word":"chaulée","word_nosc":"chaulee","lemma":"chauler","pos":"VER"} ,
		{"word":"chaulées","word_nosc":"chaulees","lemma":"chauler","pos":"VER"} ,
		{"word":"chaulés","word_nosc":"chaules","lemma":"chauler","pos":"VER"} ,
		{"word":"chaumé","word_nosc":"chaume","lemma":"chaumer","pos":"VER"} ,
		{"word":"chaussa","word_nosc":"chaussa","lemma":"chausser","pos":"VER"} ,
		{"word":"chaussai","word_nosc":"chaussai","lemma":"chausser","pos":"VER"} ,
		{"word":"chaussaient","word_nosc":"chaussaient","lemma":"chausser","pos":"VER"} ,
		{"word":"chaussait","word_nosc":"chaussait","lemma":"chausser","pos":"VER"} ,
		{"word":"chaussant","word_nosc":"chaussant","lemma":"chausser","pos":"VER"} ,
		{"word":"chausse","word_nosc":"chausse","lemma":"chausser","pos":"VER"} ,
		{"word":"chaussent","word_nosc":"chaussent","lemma":"chausser","pos":"VER"} ,
		{"word":"chausser","word_nosc":"chausser","lemma":"chausser","pos":"VER"} ,
		{"word":"chausserai","word_nosc":"chausserai","lemma":"chausser","pos":"VER"} ,
		{"word":"chausses","word_nosc":"chausses","lemma":"chausser","pos":"VER"} ,
		{"word":"chaussez","word_nosc":"chaussez","lemma":"chausser","pos":"VER"} ,
		{"word":"chaussèrent","word_nosc":"chausserent","lemma":"chausser","pos":"VER"} ,
		{"word":"chaussé","word_nosc":"chausse","lemma":"chausser","pos":"VER"} ,
		{"word":"chaussée","word_nosc":"chaussee","lemma":"chausser","pos":"VER"} ,
		{"word":"chaussées","word_nosc":"chaussees","lemma":"chausser","pos":"VER"} ,
		{"word":"chaussés","word_nosc":"chausses","lemma":"chausser","pos":"VER"} ,
		{"word":"chaut","word_nosc":"chaut","lemma":"chaut","pos":"VER"} ,
		{"word":"chauvit","word_nosc":"chauvit","lemma":"chauvir","pos":"VER"} ,
		{"word":"chavira","word_nosc":"chavira","lemma":"chavirer","pos":"VER"} ,
		{"word":"chavirai","word_nosc":"chavirai","lemma":"chavirer","pos":"VER"} ,
		{"word":"chaviraient","word_nosc":"chaviraient","lemma":"chavirer","pos":"VER"} ,
		{"word":"chavirait","word_nosc":"chavirait","lemma":"chavirer","pos":"VER"} ,
		{"word":"chavirant","word_nosc":"chavirant","lemma":"chavirer","pos":"VER"} ,
		{"word":"chavire","word_nosc":"chavire","lemma":"chavirer","pos":"VER"} ,
		{"word":"chavirent","word_nosc":"chavirent","lemma":"chavirer","pos":"VER"} ,
		{"word":"chavirer","word_nosc":"chavirer","lemma":"chavirer","pos":"VER"} ,
		{"word":"chavireraient","word_nosc":"chavireraient","lemma":"chavirer","pos":"VER"} ,
		{"word":"chavirerait","word_nosc":"chavirerait","lemma":"chavirer","pos":"VER"} ,
		{"word":"chavireront","word_nosc":"chavireront","lemma":"chavirer","pos":"VER"} ,
		{"word":"chavirions","word_nosc":"chavirions","lemma":"chavirer","pos":"VER"} ,
		{"word":"chavirons","word_nosc":"chavirons","lemma":"chavirer","pos":"VER"} ,
		{"word":"chavirèrent","word_nosc":"chavirerent","lemma":"chavirer","pos":"VER"} ,
		{"word":"chaviré","word_nosc":"chavire","lemma":"chavirer","pos":"VER"} ,
		{"word":"chavirée","word_nosc":"chaviree","lemma":"chavirer","pos":"VER"} ,
		{"word":"chavirés","word_nosc":"chavires","lemma":"chavirer","pos":"VER"} ,
		{"word":"chaîné","word_nosc":"chaine","lemma":"chaîner","pos":"VER"} ,
		{"word":"chemina","word_nosc":"chemina","lemma":"cheminer","pos":"VER"} ,
		{"word":"cheminaient","word_nosc":"cheminaient","lemma":"cheminer","pos":"VER"} ,
		{"word":"cheminait","word_nosc":"cheminait","lemma":"cheminer","pos":"VER"} ,
		{"word":"cheminant","word_nosc":"cheminant","lemma":"cheminer","pos":"VER"} ,
		{"word":"chemine","word_nosc":"chemine","lemma":"cheminer","pos":"VER"} ,
		{"word":"cheminent","word_nosc":"cheminent","lemma":"cheminer","pos":"VER"} ,
		{"word":"cheminer","word_nosc":"cheminer","lemma":"cheminer","pos":"VER"} ,
		{"word":"chemineraient","word_nosc":"chemineraient","lemma":"cheminer","pos":"VER"} ,
		{"word":"cheminerait","word_nosc":"cheminerait","lemma":"cheminer","pos":"VER"} ,
		{"word":"cheminerons","word_nosc":"cheminerons","lemma":"cheminer","pos":"VER"} ,
		{"word":"chemineront","word_nosc":"chemineront","lemma":"cheminer","pos":"VER"} ,
		{"word":"cheminions","word_nosc":"cheminions","lemma":"cheminer","pos":"VER"} ,
		{"word":"cheminons","word_nosc":"cheminons","lemma":"cheminer","pos":"VER"} ,
		{"word":"cheminâmes","word_nosc":"cheminames","lemma":"cheminer","pos":"VER"} ,
		{"word":"cheminèrent","word_nosc":"cheminerent","lemma":"cheminer","pos":"VER"} ,
		{"word":"cheminé","word_nosc":"chemine","lemma":"cheminer","pos":"VER"} ,
		{"word":"cheminée","word_nosc":"cheminee","lemma":"cheminer","pos":"VER"} ,
		{"word":"cheminées","word_nosc":"cheminees","lemma":"cheminer","pos":"VER"} ,
		{"word":"chemiser","word_nosc":"chemiser","lemma":"chemiser","pos":"VER"} ,
		{"word":"chemisée","word_nosc":"chemisee","lemma":"chemiser","pos":"VER"} ,
		{"word":"chemisées","word_nosc":"chemisees","lemma":"chemiser","pos":"VER"} ,
		{"word":"chercha","word_nosc":"chercha","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchai","word_nosc":"cherchai","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchaient","word_nosc":"cherchaient","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchais","word_nosc":"cherchais","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchait","word_nosc":"cherchait","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchant","word_nosc":"cherchant","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchas","word_nosc":"cherchas","lemma":"chercher","pos":"VER"} ,
		{"word":"cherche","word_nosc":"cherche","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchent","word_nosc":"cherchent","lemma":"chercher","pos":"VER"} ,
		{"word":"chercher","word_nosc":"chercher","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchera","word_nosc":"cherchera","lemma":"chercher","pos":"VER"} ,
		{"word":"chercherai","word_nosc":"chercherai","lemma":"chercher","pos":"VER"} ,
		{"word":"chercheraient","word_nosc":"chercheraient","lemma":"chercher","pos":"VER"} ,
		{"word":"chercherais","word_nosc":"chercherais","lemma":"chercher","pos":"VER"} ,
		{"word":"chercherait","word_nosc":"chercherait","lemma":"chercher","pos":"VER"} ,
		{"word":"chercheras","word_nosc":"chercheras","lemma":"chercher","pos":"VER"} ,
		{"word":"chercherez","word_nosc":"chercherez","lemma":"chercher","pos":"VER"} ,
		{"word":"chercheriez","word_nosc":"chercheriez","lemma":"chercher","pos":"VER"} ,
		{"word":"chercherions","word_nosc":"chercherions","lemma":"chercher","pos":"VER"} ,
		{"word":"chercherons","word_nosc":"chercherons","lemma":"chercher","pos":"VER"} ,
		{"word":"chercheront","word_nosc":"chercheront","lemma":"chercher","pos":"VER"} ,
		{"word":"cherches","word_nosc":"cherches","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchez","word_nosc":"cherchez","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchiez","word_nosc":"cherchiez","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchions","word_nosc":"cherchions","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchons","word_nosc":"cherchons","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchâmes","word_nosc":"cherchames","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchât","word_nosc":"cherchat","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchèrent","word_nosc":"chercherent","lemma":"chercher","pos":"VER"} ,
		{"word":"cherché","word_nosc":"cherche","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchée","word_nosc":"cherchee","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchées","word_nosc":"cherchees","lemma":"chercher","pos":"VER"} ,
		{"word":"cherchés","word_nosc":"cherches","lemma":"chercher","pos":"VER"} ,
		{"word":"cherres","word_nosc":"cherres","lemma":"cherrer","pos":"VER"} ,
		{"word":"chevai","word_nosc":"chevai","lemma":"chever","pos":"VER"} ,
		{"word":"chevaler","word_nosc":"chevaler","lemma":"chevaler","pos":"VER"} ,
		{"word":"chevalée","word_nosc":"chevalee","lemma":"chevaler","pos":"VER"} ,
		{"word":"chevaucha","word_nosc":"chevaucha","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevauchaient","word_nosc":"chevauchaient","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevauchais","word_nosc":"chevauchais","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevauchait","word_nosc":"chevauchait","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevauchant","word_nosc":"chevauchant","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevauche","word_nosc":"chevauche","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevauchent","word_nosc":"chevauchent","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevaucher","word_nosc":"chevaucher","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevauchera","word_nosc":"chevauchera","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevaucherai","word_nosc":"chevaucherai","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevaucheraient","word_nosc":"chevaucheraient","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevauchez","word_nosc":"chevauchez","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevauchons","word_nosc":"chevauchons","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevauchèrent","word_nosc":"chevaucherent","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevauché","word_nosc":"chevauche","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevauchée","word_nosc":"chevauchee","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevauchés","word_nosc":"chevauches","lemma":"chevaucher","pos":"VER"} ,
		{"word":"chevillé","word_nosc":"cheville","lemma":"cheviller","pos":"VER"} ,
		{"word":"chevillée","word_nosc":"chevillee","lemma":"cheviller","pos":"VER"} ,
		{"word":"chevillées","word_nosc":"chevillees","lemma":"cheviller","pos":"VER"} ,
		{"word":"chevir","word_nosc":"chevir","lemma":"chevir","pos":"VER"} ,
		{"word":"chevronné","word_nosc":"chevronne","lemma":"chevronner","pos":"VER"} ,
		{"word":"chevronnée","word_nosc":"chevronnee","lemma":"chevronner","pos":"VER"} ,
		{"word":"chevronnés","word_nosc":"chevronnes","lemma":"chevronner","pos":"VER"} ,
		{"word":"chevrota","word_nosc":"chevrota","lemma":"chevroter","pos":"VER"} ,
		{"word":"chevrotais","word_nosc":"chevrotais","lemma":"chevroter","pos":"VER"} ,
		{"word":"chevrotait","word_nosc":"chevrotait","lemma":"chevroter","pos":"VER"} ,
		{"word":"chevrotant","word_nosc":"chevrotant","lemma":"chevroter","pos":"VER"} ,
		{"word":"chevrote","word_nosc":"chevrote","lemma":"chevroter","pos":"VER"} ,
		{"word":"chevroter","word_nosc":"chevroter","lemma":"chevroter","pos":"VER"} ,
		{"word":"chevroté","word_nosc":"chevrote","lemma":"chevroter","pos":"VER"} ,
		{"word":"chevrotée","word_nosc":"chevrotee","lemma":"chevroter","pos":"VER"} ,
		{"word":"chia","word_nosc":"chia","lemma":"chier","pos":"VER"} ,
		{"word":"chiader","word_nosc":"chiader","lemma":"chiader","pos":"VER"} ,
		{"word":"chiadé","word_nosc":"chiade","lemma":"chiader","pos":"VER"} ,
		{"word":"chiadée","word_nosc":"chiadee","lemma":"chiader","pos":"VER"} ,
		{"word":"chiadées","word_nosc":"chiadees","lemma":"chiader","pos":"VER"} ,
		{"word":"chiaient","word_nosc":"chiaient","lemma":"chier","pos":"VER"} ,
		{"word":"chiais","word_nosc":"chiais","lemma":"chier","pos":"VER"} ,
		{"word":"chiait","word_nosc":"chiait","lemma":"chier","pos":"VER"} ,
		{"word":"chiala","word_nosc":"chiala","lemma":"chialer","pos":"VER"} ,
		{"word":"chialaient","word_nosc":"chialaient","lemma":"chialer","pos":"VER"} ,
		{"word":"chialais","word_nosc":"chialais","lemma":"chialer","pos":"VER"} ,
		{"word":"chialait","word_nosc":"chialait","lemma":"chialer","pos":"VER"} ,
		{"word":"chialant","word_nosc":"chialant","lemma":"chialer","pos":"VER"} ,
		{"word":"chiale","word_nosc":"chiale","lemma":"chialer","pos":"VER"} ,
		{"word":"chialent","word_nosc":"chialent","lemma":"chialer","pos":"VER"} ,
		{"word":"chialer","word_nosc":"chialer","lemma":"chialer","pos":"VER"} ,
		{"word":"chialera","word_nosc":"chialera","lemma":"chialer","pos":"VER"} ,
		{"word":"chialeraient","word_nosc":"chialeraient","lemma":"chialer","pos":"VER"} ,
		{"word":"chialerais","word_nosc":"chialerais","lemma":"chialer","pos":"VER"} ,
		{"word":"chialerait","word_nosc":"chialerait","lemma":"chialer","pos":"VER"} ,
		{"word":"chialeras","word_nosc":"chialeras","lemma":"chialer","pos":"VER"} ,
		{"word":"chiales","word_nosc":"chiales","lemma":"chialer","pos":"VER"} ,
		{"word":"chialez","word_nosc":"chialez","lemma":"chialer","pos":"VER"} ,
		{"word":"chialèrent","word_nosc":"chialerent","lemma":"chialer","pos":"VER"} ,
		{"word":"chialé","word_nosc":"chiale","lemma":"chialer","pos":"VER"} ,
		{"word":"chiant","word_nosc":"chiant","lemma":"chier","pos":"VER"} ,
		{"word":"chicanait","word_nosc":"chicanait","lemma":"chicaner","pos":"VER"} ,
		{"word":"chicanant","word_nosc":"chicanant","lemma":"chicaner","pos":"VER"} ,
		{"word":"chicane","word_nosc":"chicane","lemma":"chicaner","pos":"VER"} ,
		{"word":"chicanent","word_nosc":"chicanent","lemma":"chicaner","pos":"VER"} ,
		{"word":"chicaner","word_nosc":"chicaner","lemma":"chicaner","pos":"VER"} ,
		{"word":"chicanerons","word_nosc":"chicanerons","lemma":"chicaner","pos":"VER"} ,
		{"word":"chicanes","word_nosc":"chicanes","lemma":"chicaner","pos":"VER"} ,
		{"word":"chicanez","word_nosc":"chicanez","lemma":"chicaner","pos":"VER"} ,
		{"word":"chicanons","word_nosc":"chicanons","lemma":"chicaner","pos":"VER"} ,
		{"word":"chicané","word_nosc":"chicane","lemma":"chicaner","pos":"VER"} ,
		{"word":"chicanées","word_nosc":"chicanees","lemma":"chicaner","pos":"VER"} ,
		{"word":"chicore","word_nosc":"chicore","lemma":"chicorer","pos":"VER"} ,
		{"word":"chicorent","word_nosc":"chicorent","lemma":"chicorer","pos":"VER"} ,
		{"word":"chicorer","word_nosc":"chicorer","lemma":"chicorer","pos":"VER"} ,
		{"word":"chicores","word_nosc":"chicores","lemma":"chicorer","pos":"VER"} ,
		{"word":"chicoré","word_nosc":"chicore","lemma":"chicorer","pos":"VER"} ,
		{"word":"chicoter","word_nosc":"chicoter","lemma":"chicoter","pos":"VER"} ,
		{"word":"chicoté","word_nosc":"chicote","lemma":"chicoter","pos":"VER"} ,
		{"word":"chie","word_nosc":"chie","lemma":"chier","pos":"VER"} ,
		{"word":"chiennasse","word_nosc":"chiennasse","lemma":"chienner","pos":"VER"} ,
		{"word":"chient","word_nosc":"chient","lemma":"chier","pos":"VER"} ,
		{"word":"chier","word_nosc":"chier","lemma":"chier","pos":"VER"} ,
		{"word":"chiera","word_nosc":"chiera","lemma":"chier","pos":"VER"} ,
		{"word":"chierai","word_nosc":"chierai","lemma":"chier","pos":"VER"} ,
		{"word":"chierais","word_nosc":"chierais","lemma":"chier","pos":"VER"} ,
		{"word":"chieras","word_nosc":"chieras","lemma":"chier","pos":"VER"} ,
		{"word":"chierez","word_nosc":"chierez","lemma":"chier","pos":"VER"} ,
		{"word":"chieront","word_nosc":"chieront","lemma":"chier","pos":"VER"} ,
		{"word":"chies","word_nosc":"chies","lemma":"chier","pos":"VER"} ,
		{"word":"chiez","word_nosc":"chiez","lemma":"chier","pos":"VER"} ,
		{"word":"chiffonna","word_nosc":"chiffonna","lemma":"chiffonner","pos":"VER"} ,
		{"word":"chiffonnaient","word_nosc":"chiffonnaient","lemma":"chiffonner","pos":"VER"} ,
		{"word":"chiffonnait","word_nosc":"chiffonnait","lemma":"chiffonner","pos":"VER"} ,
		{"word":"chiffonnant","word_nosc":"chiffonnant","lemma":"chiffonner","pos":"VER"} ,
		{"word":"chiffonne","word_nosc":"chiffonne","lemma":"chiffonner","pos":"VER"} ,
		{"word":"chiffonner","word_nosc":"chiffonner","lemma":"chiffonner","pos":"VER"} ,
		{"word":"chiffonné","word_nosc":"chiffonne","lemma":"chiffonner","pos":"VER"} ,
		{"word":"chiffonnée","word_nosc":"chiffonnee","lemma":"chiffonner","pos":"VER"} ,
		{"word":"chiffonnées","word_nosc":"chiffonnees","lemma":"chiffonner","pos":"VER"} ,
		{"word":"chiffonnés","word_nosc":"chiffonnes","lemma":"chiffonner","pos":"VER"} ,
		{"word":"chiffrait","word_nosc":"chiffrait","lemma":"chiffrer","pos":"VER"} ,
		{"word":"chiffrant","word_nosc":"chiffrant","lemma":"chiffrer","pos":"VER"} ,
		{"word":"chiffre","word_nosc":"chiffre","lemma":"chiffrer","pos":"VER"} ,
		{"word":"chiffrent","word_nosc":"chiffrent","lemma":"chiffrer","pos":"VER"} ,
		{"word":"chiffrer","word_nosc":"chiffrer","lemma":"chiffrer","pos":"VER"} ,
		{"word":"chiffres","word_nosc":"chiffres","lemma":"chiffrer","pos":"VER"} ,
		{"word":"chiffré","word_nosc":"chiffre","lemma":"chiffrer","pos":"VER"} ,
		{"word":"chiffrée","word_nosc":"chiffree","lemma":"chiffrer","pos":"VER"} ,
		{"word":"chiffrées","word_nosc":"chiffrees","lemma":"chiffrer","pos":"VER"} ,
		{"word":"chiffrés","word_nosc":"chiffres","lemma":"chiffrer","pos":"VER"} ,
		{"word":"china","word_nosc":"china","lemma":"chiner","pos":"VER"} ,
		{"word":"chinaient","word_nosc":"chinaient","lemma":"chiner","pos":"VER"} ,
		{"word":"chinait","word_nosc":"chinait","lemma":"chiner","pos":"VER"} ,
		{"word":"chinant","word_nosc":"chinant","lemma":"chiner","pos":"VER"} ,
		{"word":"chine","word_nosc":"chine","lemma":"chiner","pos":"VER"} ,
		{"word":"chiner","word_nosc":"chiner","lemma":"chiner","pos":"VER"} ,
		{"word":"chinera","word_nosc":"chinera","lemma":"chiner","pos":"VER"} ,
		{"word":"chinoiser","word_nosc":"chinoiser","lemma":"chinoiser","pos":"VER"} ,
		{"word":"chiné","word_nosc":"chine","lemma":"chiner","pos":"VER"} ,
		{"word":"chinée","word_nosc":"chinee","lemma":"chiner","pos":"VER"} ,
		{"word":"chinées","word_nosc":"chinees","lemma":"chiner","pos":"VER"} ,
		{"word":"chipa","word_nosc":"chipa","lemma":"chiper","pos":"VER"} ,
		{"word":"chipaient","word_nosc":"chipaient","lemma":"chiper","pos":"VER"} ,
		{"word":"chipais","word_nosc":"chipais","lemma":"chiper","pos":"VER"} ,
		{"word":"chipait","word_nosc":"chipait","lemma":"chiper","pos":"VER"} ,
		{"word":"chipant","word_nosc":"chipant","lemma":"chiper","pos":"VER"} ,
		{"word":"chipe","word_nosc":"chipe","lemma":"chiper","pos":"VER"} ,
		{"word":"chiper","word_nosc":"chiper","lemma":"chiper","pos":"VER"} ,
		{"word":"chiperaient","word_nosc":"chiperaient","lemma":"chiper","pos":"VER"} ,
		{"word":"chiperiez","word_nosc":"chiperiez","lemma":"chiper","pos":"VER"} ,
		{"word":"chipez","word_nosc":"chipez","lemma":"chiper","pos":"VER"} ,
		{"word":"chipota","word_nosc":"chipota","lemma":"chipoter","pos":"VER"} ,
		{"word":"chipotais","word_nosc":"chipotais","lemma":"chipoter","pos":"VER"} ,
		{"word":"chipotait","word_nosc":"chipotait","lemma":"chipoter","pos":"VER"} ,
		{"word":"chipotant","word_nosc":"chipotant","lemma":"chipoter","pos":"VER"} ,
		{"word":"chipote","word_nosc":"chipote","lemma":"chipoter","pos":"VER"} ,
		{"word":"chipotent","word_nosc":"chipotent","lemma":"chipoter","pos":"VER"} ,
		{"word":"chipoter","word_nosc":"chipoter","lemma":"chipoter","pos":"VER"} ,
		{"word":"chipotes","word_nosc":"chipotes","lemma":"chipoter","pos":"VER"} ,
		{"word":"chipotez","word_nosc":"chipotez","lemma":"chipoter","pos":"VER"} ,
		{"word":"chipotons","word_nosc":"chipotons","lemma":"chipoter","pos":"VER"} ,
		{"word":"chipoté","word_nosc":"chipote","lemma":"chipoter","pos":"VER"} ,
		{"word":"chipèrent","word_nosc":"chiperent","lemma":"chiper","pos":"VER"} ,
		{"word":"chipé","word_nosc":"chipe","lemma":"chiper","pos":"VER"} ,
		{"word":"chipée","word_nosc":"chipee","lemma":"chiper","pos":"VER"} ,
		{"word":"chipées","word_nosc":"chipees","lemma":"chiper","pos":"VER"} ,
		{"word":"chipés","word_nosc":"chipes","lemma":"chiper","pos":"VER"} ,
		{"word":"chiquaient","word_nosc":"chiquaient","lemma":"chiquer","pos":"VER"} ,
		{"word":"chiquait","word_nosc":"chiquait","lemma":"chiquer","pos":"VER"} ,
		{"word":"chiquant","word_nosc":"chiquant","lemma":"chiquer","pos":"VER"} ,
		{"word":"chique","word_nosc":"chique","lemma":"chiquer","pos":"VER"} ,
		{"word":"chiquent","word_nosc":"chiquent","lemma":"chiquer","pos":"VER"} ,
		{"word":"chiquer","word_nosc":"chiquer","lemma":"chiquer","pos":"VER"} ,
		{"word":"chiquerai","word_nosc":"chiquerai","lemma":"chiquer","pos":"VER"} ,
		{"word":"chiqueront","word_nosc":"chiqueront","lemma":"chiquer","pos":"VER"} ,
		{"word":"chiques","word_nosc":"chiques","lemma":"chiquer","pos":"VER"} ,
		{"word":"chiqué","word_nosc":"chique","lemma":"chiquer","pos":"VER"} ,
		{"word":"chié","word_nosc":"chie","lemma":"chier","pos":"VER"} ,
		{"word":"chiée","word_nosc":"chiee","lemma":"chier","pos":"VER"} ,
		{"word":"chiées","word_nosc":"chiees","lemma":"chier","pos":"VER"} ,
		{"word":"chiés","word_nosc":"chies","lemma":"chier","pos":"VER"} ,
		{"word":"chlingue","word_nosc":"chlingue","lemma":"chlinguer","pos":"VER"} ,
		{"word":"chlinguer","word_nosc":"chlinguer","lemma":"chlinguer","pos":"VER"} ,
		{"word":"chlingues","word_nosc":"chlingues","lemma":"chlinguer","pos":"VER"} ,
		{"word":"chlorer","word_nosc":"chlorer","lemma":"chlorer","pos":"VER"} ,
		{"word":"chloroformant","word_nosc":"chloroformant","lemma":"chloroformer","pos":"VER"} ,
		{"word":"chloroforme","word_nosc":"chloroforme","lemma":"chloroformer","pos":"VER"} ,
		{"word":"chloroformer","word_nosc":"chloroformer","lemma":"chloroformer","pos":"VER"} ,
		{"word":"chloroformes","word_nosc":"chloroformes","lemma":"chloroformer","pos":"VER"} ,
		{"word":"chloroformez","word_nosc":"chloroformez","lemma":"chloroformer","pos":"VER"} ,
		{"word":"chloroformé","word_nosc":"chloroforme","lemma":"chloroformer","pos":"VER"} ,
		{"word":"chloré","word_nosc":"chlore","lemma":"chlorer","pos":"VER"} ,
		{"word":"choie","word_nosc":"choie","lemma":"choyer","pos":"VER"} ,
		{"word":"choient","word_nosc":"choient","lemma":"choyer","pos":"VER"} ,
		{"word":"choieront","word_nosc":"choieront","lemma":"choyer","pos":"VER"} ,
		{"word":"choir","word_nosc":"choir","lemma":"choir","pos":"VER"} ,
		{"word":"choiras","word_nosc":"choiras","lemma":"choir","pos":"VER"} ,
		{"word":"chois","word_nosc":"chois","lemma":"choir","pos":"VER"} ,
		{"word":"choisi","word_nosc":"choisi","lemma":"choisir","pos":"VER"} ,
		{"word":"choisie","word_nosc":"choisie","lemma":"choisir","pos":"VER"} ,
		{"word":"choisies","word_nosc":"choisies","lemma":"choisir","pos":"VER"} ,
		{"word":"choisir","word_nosc":"choisir","lemma":"choisir","pos":"VER"} ,
		{"word":"choisira","word_nosc":"choisira","lemma":"choisir","pos":"VER"} ,
		{"word":"choisirai","word_nosc":"choisirai","lemma":"choisir","pos":"VER"} ,
		{"word":"choisiraient","word_nosc":"choisiraient","lemma":"choisir","pos":"VER"} ,
		{"word":"choisirais","word_nosc":"choisirais","lemma":"choisir","pos":"VER"} ,
		{"word":"choisirait","word_nosc":"choisirait","lemma":"choisir","pos":"VER"} ,
		{"word":"choisiras","word_nosc":"choisiras","lemma":"choisir","pos":"VER"} ,
		{"word":"choisirent","word_nosc":"choisirent","lemma":"choisir","pos":"VER"} ,
		{"word":"choisirez","word_nosc":"choisirez","lemma":"choisir","pos":"VER"} ,
		{"word":"choisiriez","word_nosc":"choisiriez","lemma":"choisir","pos":"VER"} ,
		{"word":"choisirions","word_nosc":"choisirions","lemma":"choisir","pos":"VER"} ,
		{"word":"choisirons","word_nosc":"choisirons","lemma":"choisir","pos":"VER"} ,
		{"word":"choisiront","word_nosc":"choisiront","lemma":"choisir","pos":"VER"} ,
		{"word":"choisis","word_nosc":"choisis","lemma":"choisir","pos":"VER"} ,
		{"word":"choisissaient","word_nosc":"choisissaient","lemma":"choisir","pos":"VER"} ,
		{"word":"choisissais","word_nosc":"choisissais","lemma":"choisir","pos":"VER"} ,
		{"word":"choisissait","word_nosc":"choisissait","lemma":"choisir","pos":"VER"} ,
		{"word":"choisissant","word_nosc":"choisissant","lemma":"choisir","pos":"VER"} ,
		{"word":"choisisse","word_nosc":"choisisse","lemma":"choisir","pos":"VER"} ,
		{"word":"choisissent","word_nosc":"choisissent","lemma":"choisir","pos":"VER"} ,
		{"word":"choisisses","word_nosc":"choisisses","lemma":"choisir","pos":"VER"} ,
		{"word":"choisissez","word_nosc":"choisissez","lemma":"choisir","pos":"VER"} ,
		{"word":"choisissiez","word_nosc":"choisissiez","lemma":"choisir","pos":"VER"} ,
		{"word":"choisissions","word_nosc":"choisissions","lemma":"choisir","pos":"VER"} ,
		{"word":"choisissons","word_nosc":"choisissons","lemma":"choisir","pos":"VER"} ,
		{"word":"choisit","word_nosc":"choisit","lemma":"choisir","pos":"VER"} ,
		{"word":"choisîmes","word_nosc":"choisimes","lemma":"choisir","pos":"VER"} ,
		{"word":"choisît","word_nosc":"choisit","lemma":"choisir","pos":"VER"} ,
		{"word":"choit","word_nosc":"choit","lemma":"choir","pos":"VER"} ,
		{"word":"cholera","word_nosc":"cholera","lemma":"choler","pos":"VER"} ,
		{"word":"chopaient","word_nosc":"chopaient","lemma":"choper","pos":"VER"} ,
		{"word":"chopais","word_nosc":"chopais","lemma":"choper","pos":"VER"} ,
		{"word":"chopait","word_nosc":"chopait","lemma":"choper","pos":"VER"} ,
		{"word":"chopant","word_nosc":"chopant","lemma":"choper","pos":"VER"} ,
		{"word":"chope","word_nosc":"chope","lemma":"choper","pos":"VER"} ,
		{"word":"chopent","word_nosc":"chopent","lemma":"choper","pos":"VER"} ,
		{"word":"choper","word_nosc":"choper","lemma":"choper","pos":"VER"} ,
		{"word":"chopera","word_nosc":"chopera","lemma":"choper","pos":"VER"} ,
		{"word":"choperai","word_nosc":"choperai","lemma":"choper","pos":"VER"} ,
		{"word":"choperais","word_nosc":"choperais","lemma":"choper","pos":"VER"} ,
		{"word":"choperait","word_nosc":"choperait","lemma":"choper","pos":"VER"} ,
		{"word":"choperas","word_nosc":"choperas","lemma":"choper","pos":"VER"} ,
		{"word":"choperez","word_nosc":"choperez","lemma":"choper","pos":"VER"} ,
		{"word":"choperons","word_nosc":"choperons","lemma":"choper","pos":"VER"} ,
		{"word":"choperont","word_nosc":"choperont","lemma":"choper","pos":"VER"} ,
		{"word":"chopes","word_nosc":"chopes","lemma":"choper","pos":"VER"} ,
		{"word":"chopez","word_nosc":"chopez","lemma":"choper","pos":"VER"} ,
		{"word":"chopons","word_nosc":"chopons","lemma":"choper","pos":"VER"} ,
		{"word":"choppe","word_nosc":"choppe","lemma":"chopper","pos":"VER"} ,
		{"word":"choppent","word_nosc":"choppent","lemma":"chopper","pos":"VER"} ,
		{"word":"chopper","word_nosc":"chopper","lemma":"chopper","pos":"VER"} ,
		{"word":"choppes","word_nosc":"choppes","lemma":"chopper","pos":"VER"} ,
		{"word":"choppez","word_nosc":"choppez","lemma":"chopper","pos":"VER"} ,
		{"word":"choppons","word_nosc":"choppons","lemma":"chopper","pos":"VER"} ,
		{"word":"choppé","word_nosc":"choppe","lemma":"chopper","pos":"VER"} ,
		{"word":"choppée","word_nosc":"choppee","lemma":"chopper","pos":"VER"} ,
		{"word":"chopé","word_nosc":"chope","lemma":"choper","pos":"VER"} ,
		{"word":"chopée","word_nosc":"chopee","lemma":"choper","pos":"VER"} ,
		{"word":"chopées","word_nosc":"chopees","lemma":"choper","pos":"VER"} ,
		{"word":"chopés","word_nosc":"chopes","lemma":"choper","pos":"VER"} ,
		{"word":"choqua","word_nosc":"choqua","lemma":"choquer","pos":"VER"} ,
		{"word":"choquaient","word_nosc":"choquaient","lemma":"choquer","pos":"VER"} ,
		{"word":"choquait","word_nosc":"choquait","lemma":"choquer","pos":"VER"} ,
		{"word":"choquant","word_nosc":"choquant","lemma":"choquer","pos":"VER"} ,
		{"word":"choque","word_nosc":"choque","lemma":"choquer","pos":"VER"} ,
		{"word":"choquent","word_nosc":"choquent","lemma":"choquer","pos":"VER"} ,
		{"word":"choquer","word_nosc":"choquer","lemma":"choquer","pos":"VER"} ,
		{"word":"choquera","word_nosc":"choquera","lemma":"choquer","pos":"VER"} ,
		{"word":"choqueraient","word_nosc":"choqueraient","lemma":"choquer","pos":"VER"} ,
		{"word":"choquerais","word_nosc":"choquerais","lemma":"choquer","pos":"VER"} ,
		{"word":"choquerait","word_nosc":"choquerait","lemma":"choquer","pos":"VER"} ,
		{"word":"choqueras","word_nosc":"choqueras","lemma":"choquer","pos":"VER"} ,
		{"word":"choquez","word_nosc":"choquez","lemma":"choquer","pos":"VER"} ,
		{"word":"choquons","word_nosc":"choquons","lemma":"choquer","pos":"VER"} ,
		{"word":"choquèrent","word_nosc":"choquerent","lemma":"choquer","pos":"VER"} ,
		{"word":"choqué","word_nosc":"choque","lemma":"choquer","pos":"VER"} ,
		{"word":"choquée","word_nosc":"choquee","lemma":"choquer","pos":"VER"} ,
		{"word":"choquées","word_nosc":"choquees","lemma":"choquer","pos":"VER"} ,
		{"word":"choqués","word_nosc":"choques","lemma":"choquer","pos":"VER"} ,
		{"word":"chorégraphier","word_nosc":"choregraphier","lemma":"chorégraphier","pos":"VER"} ,
		{"word":"chorégraphiées","word_nosc":"choregraphiees","lemma":"chorégraphier","pos":"VER"} ,
		{"word":"choser","word_nosc":"choser","lemma":"choser","pos":"VER"} ,
		{"word":"choses","word_nosc":"choses","lemma":"choses","pos":"VER"} ,
		{"word":"chosifient","word_nosc":"chosifient","lemma":"chosifier","pos":"VER"} ,
		{"word":"chosifier","word_nosc":"chosifier","lemma":"chosifier","pos":"VER"} ,
		{"word":"chouchoutaient","word_nosc":"chouchoutaient","lemma":"chouchouter","pos":"VER"} ,
		{"word":"chouchoutait","word_nosc":"chouchoutait","lemma":"chouchouter","pos":"VER"} ,
		{"word":"chouchoute","word_nosc":"chouchoute","lemma":"chouchouter","pos":"VER"} ,
		{"word":"chouchouter","word_nosc":"chouchouter","lemma":"chouchouter","pos":"VER"} ,
		{"word":"chouchouteras","word_nosc":"chouchouteras","lemma":"chouchouter","pos":"VER"} ,
		{"word":"chouchoutez","word_nosc":"chouchoutez","lemma":"chouchouter","pos":"VER"} ,
		{"word":"chouchouté","word_nosc":"chouchoute","lemma":"chouchouter","pos":"VER"} ,
		{"word":"chouchoutée","word_nosc":"chouchoutee","lemma":"chouchouter","pos":"VER"} ,
		{"word":"chougnait","word_nosc":"chougnait","lemma":"chougner","pos":"VER"} ,
		{"word":"chougne","word_nosc":"chougne","lemma":"chougner","pos":"VER"} ,
		{"word":"chouiner","word_nosc":"chouiner","lemma":"chouiner","pos":"VER"} ,
		{"word":"choura","word_nosc":"choura","lemma":"chourer","pos":"VER"} ,
		{"word":"chouravait","word_nosc":"chouravait","lemma":"chouraver","pos":"VER"} ,
		{"word":"chourave","word_nosc":"chourave","lemma":"chouraver","pos":"VER"} ,
		{"word":"chouravent","word_nosc":"chouravent","lemma":"chouraver","pos":"VER"} ,
		{"word":"chouraver","word_nosc":"chouraver","lemma":"chouraver","pos":"VER"} ,
		{"word":"chouravé","word_nosc":"chourave","lemma":"chouraver","pos":"VER"} ,
		{"word":"chouravée","word_nosc":"chouravee","lemma":"chouraver","pos":"VER"} ,
		{"word":"chouravés","word_nosc":"chouraves","lemma":"chouraver","pos":"VER"} ,
		{"word":"choure","word_nosc":"choure","lemma":"chourer","pos":"VER"} ,
		{"word":"chourent","word_nosc":"chourent","lemma":"chourer","pos":"VER"} ,
		{"word":"chourer","word_nosc":"chourer","lemma":"chourer","pos":"VER"} ,
		{"word":"chouriez","word_nosc":"chouriez","lemma":"chourer","pos":"VER"} ,
		{"word":"chouré","word_nosc":"choure","lemma":"chourer","pos":"VER"} ,
		{"word":"chourée","word_nosc":"chouree","lemma":"chourer","pos":"VER"} ,
		{"word":"chourées","word_nosc":"chourees","lemma":"chourer","pos":"VER"} ,
		{"word":"choya","word_nosc":"choya","lemma":"choyer","pos":"VER"} ,
		{"word":"choyais","word_nosc":"choyais","lemma":"choyer","pos":"VER"} ,
		{"word":"choyait","word_nosc":"choyait","lemma":"choyer","pos":"VER"} ,
		{"word":"choyant","word_nosc":"choyant","lemma":"choyer","pos":"VER"} ,
		{"word":"choyer","word_nosc":"choyer","lemma":"choyer","pos":"VER"} ,
		{"word":"choyez","word_nosc":"choyez","lemma":"choyer","pos":"VER"} ,
		{"word":"choyé","word_nosc":"choye","lemma":"choyer","pos":"VER"} ,
		{"word":"choyée","word_nosc":"choyee","lemma":"choyer","pos":"VER"} ,
		{"word":"choyées","word_nosc":"choyees","lemma":"choyer","pos":"VER"} ,
		{"word":"choyés","word_nosc":"choyes","lemma":"choyer","pos":"VER"} ,
		{"word":"christianisés","word_nosc":"christianises","lemma":"christianiser","pos":"VER"} ,
		{"word":"chrome","word_nosc":"chrome","lemma":"chromer","pos":"VER"} ,
		{"word":"chromé","word_nosc":"chrome","lemma":"chromer","pos":"VER"} ,
		{"word":"chromée","word_nosc":"chromee","lemma":"chromer","pos":"VER"} ,
		{"word":"chromées","word_nosc":"chromees","lemma":"chromer","pos":"VER"} ,
		{"word":"chromés","word_nosc":"chromes","lemma":"chromer","pos":"VER"} ,
		{"word":"chroniquais","word_nosc":"chroniquais","lemma":"chroniquer","pos":"VER"} ,
		{"word":"chronique","word_nosc":"chronique","lemma":"chroniquer","pos":"VER"} ,
		{"word":"chroniquer","word_nosc":"chroniquer","lemma":"chroniquer","pos":"VER"} ,
		{"word":"chronomètre","word_nosc":"chronometre","lemma":"chronométrer","pos":"VER"} ,
		{"word":"chronomètres","word_nosc":"chronometres","lemma":"chronométrer","pos":"VER"} ,
		{"word":"chronométrait","word_nosc":"chronometrait","lemma":"chronométrer","pos":"VER"} ,
		{"word":"chronométrant","word_nosc":"chronometrant","lemma":"chronométrer","pos":"VER"} ,
		{"word":"chronométrer","word_nosc":"chronometrer","lemma":"chronométrer","pos":"VER"} ,
		{"word":"chronométrez","word_nosc":"chronometrez","lemma":"chronométrer","pos":"VER"} ,
		{"word":"chronométrions","word_nosc":"chronometrions","lemma":"chronométrer","pos":"VER"} ,
		{"word":"chronométré","word_nosc":"chronometre","lemma":"chronométrer","pos":"VER"} ,
		{"word":"chronométrée","word_nosc":"chronometree","lemma":"chronométrer","pos":"VER"} ,
		{"word":"chronométrées","word_nosc":"chronometrees","lemma":"chronométrer","pos":"VER"} ,
		{"word":"chronométrés","word_nosc":"chronometres","lemma":"chronométrer","pos":"VER"} ,
		{"word":"chu","word_nosc":"chu","lemma":"choir","pos":"VER"} ,
		{"word":"chuchota","word_nosc":"chuchota","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchotai","word_nosc":"chuchotai","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchotaient","word_nosc":"chuchotaient","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchotais","word_nosc":"chuchotais","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchotait","word_nosc":"chuchotait","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchotant","word_nosc":"chuchotant","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchote","word_nosc":"chuchote","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchotent","word_nosc":"chuchotent","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchoter","word_nosc":"chuchoter","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchoteraient","word_nosc":"chuchoteraient","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchoterais","word_nosc":"chuchoterais","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchoterait","word_nosc":"chuchoterait","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchoteras","word_nosc":"chuchoteras","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchoterez","word_nosc":"chuchoterez","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchotez","word_nosc":"chuchotez","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchotions","word_nosc":"chuchotions","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchotât","word_nosc":"chuchotat","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchotèrent","word_nosc":"chuchoterent","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchoté","word_nosc":"chuchote","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchotée","word_nosc":"chuchotee","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchotées","word_nosc":"chuchotees","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chuchotés","word_nosc":"chuchotes","lemma":"chuchoter","pos":"VER"} ,
		{"word":"chue","word_nosc":"chue","lemma":"choir","pos":"VER"} ,
		{"word":"chues","word_nosc":"chues","lemma":"choir","pos":"VER"} ,
		{"word":"chuinta","word_nosc":"chuinta","lemma":"chuinter","pos":"VER"} ,
		{"word":"chuintaient","word_nosc":"chuintaient","lemma":"chuinter","pos":"VER"} ,
		{"word":"chuintait","word_nosc":"chuintait","lemma":"chuinter","pos":"VER"} ,
		{"word":"chuintant","word_nosc":"chuintant","lemma":"chuinter","pos":"VER"} ,
		{"word":"chuinte","word_nosc":"chuinte","lemma":"chuinter","pos":"VER"} ,
		{"word":"chuinter","word_nosc":"chuinter","lemma":"chuinter","pos":"VER"} ,
		{"word":"chuintèrent","word_nosc":"chuinterent","lemma":"chuinter","pos":"VER"} ,
		{"word":"chuinté","word_nosc":"chuinte","lemma":"chuinter","pos":"VER"} ,
		{"word":"chus","word_nosc":"chus","lemma":"choir","pos":"VER"} ,
		{"word":"chut","word_nosc":"chut","lemma":"choir","pos":"VER"} ,
		{"word":"chuta","word_nosc":"chuta","lemma":"chuter","pos":"VER"} ,
		{"word":"chutait","word_nosc":"chutait","lemma":"chuter","pos":"VER"} ,
		{"word":"chutant","word_nosc":"chutant","lemma":"chuter","pos":"VER"} ,
		{"word":"chute","word_nosc":"chute","lemma":"chuter","pos":"VER"} ,
		{"word":"chutent","word_nosc":"chutent","lemma":"chuter","pos":"VER"} ,
		{"word":"chuter","word_nosc":"chuter","lemma":"chuter","pos":"VER"} ,
		{"word":"chutera","word_nosc":"chutera","lemma":"chuter","pos":"VER"} ,
		{"word":"chuteraient","word_nosc":"chuteraient","lemma":"chuter","pos":"VER"} ,
		{"word":"chuterait","word_nosc":"chuterait","lemma":"chuter","pos":"VER"} ,
		{"word":"chuterez","word_nosc":"chuterez","lemma":"chuter","pos":"VER"} ,
		{"word":"chutes","word_nosc":"chutes","lemma":"chuter","pos":"VER"} ,
		{"word":"chutèrent","word_nosc":"chuterent","lemma":"chuter","pos":"VER"} ,
		{"word":"chuté","word_nosc":"chute","lemma":"chuter","pos":"VER"} ,
		{"word":"chutée","word_nosc":"chutee","lemma":"chuter","pos":"VER"} ,
		{"word":"châtaignent","word_nosc":"chataignent","lemma":"châtaigner","pos":"VER"} ,
		{"word":"châtaigner","word_nosc":"chataigner","lemma":"châtaigner","pos":"VER"} ,
		{"word":"châtaigné","word_nosc":"chataigne","lemma":"châtaigner","pos":"VER"} ,
		{"word":"châtiaient","word_nosc":"chatiaient","lemma":"châtier","pos":"VER"} ,
		{"word":"châtiait","word_nosc":"chatiait","lemma":"châtier","pos":"VER"} ,
		{"word":"châtiant","word_nosc":"chatiant","lemma":"châtier","pos":"VER"} ,
		{"word":"châtie","word_nosc":"chatie","lemma":"châtier","pos":"VER"} ,
		{"word":"châtier","word_nosc":"chatier","lemma":"châtier","pos":"VER"} ,
		{"word":"châtiera","word_nosc":"chatiera","lemma":"châtier","pos":"VER"} ,
		{"word":"châtierai","word_nosc":"chatierai","lemma":"châtier","pos":"VER"} ,
		{"word":"châtierait","word_nosc":"chatierait","lemma":"châtier","pos":"VER"} ,
		{"word":"châtiez","word_nosc":"chatiez","lemma":"châtier","pos":"VER"} ,
		{"word":"châtié","word_nosc":"chatie","lemma":"châtier","pos":"VER"} ,
		{"word":"châtiée","word_nosc":"chatiee","lemma":"châtier","pos":"VER"} ,
		{"word":"châtiés","word_nosc":"chaties","lemma":"châtier","pos":"VER"} ,
		{"word":"châtraient","word_nosc":"chatraient","lemma":"châtrer","pos":"VER"} ,
		{"word":"châtre","word_nosc":"chatre","lemma":"châtrer","pos":"VER"} ,
		{"word":"châtrent","word_nosc":"chatrent","lemma":"châtrer","pos":"VER"} ,
		{"word":"châtrer","word_nosc":"chatrer","lemma":"châtrer","pos":"VER"} ,
		{"word":"châtreront","word_nosc":"chatreront","lemma":"châtrer","pos":"VER"} ,
		{"word":"châtré","word_nosc":"chatre","lemma":"châtrer","pos":"VER"} ,
		{"word":"châtrés","word_nosc":"chatres","lemma":"châtrer","pos":"VER"} ,
		{"word":"chéri","word_nosc":"cheri","lemma":"chérir","pos":"VER"} ,
		{"word":"chérie","word_nosc":"cherie","lemma":"chérir","pos":"VER"} ,
		{"word":"chéries","word_nosc":"cheries","lemma":"chérir","pos":"VER"} ,
		{"word":"chérir","word_nosc":"cherir","lemma":"chérir","pos":"VER"} ,
		{"word":"chérirai","word_nosc":"cherirai","lemma":"chérir","pos":"VER"} ,
		{"word":"chérirais","word_nosc":"cherirais","lemma":"chérir","pos":"VER"} ,
		{"word":"chérirez","word_nosc":"cherirez","lemma":"chérir","pos":"VER"} ,
		{"word":"chérirons","word_nosc":"cherirons","lemma":"chérir","pos":"VER"} ,
		{"word":"chéris","word_nosc":"cheris","lemma":"chérir","pos":"VER"} ,
		{"word":"chérissaient","word_nosc":"cherissaient","lemma":"chérir","pos":"VER"} ,
		{"word":"chérissais","word_nosc":"cherissais","lemma":"chérir","pos":"VER"} ,
		{"word":"chérissait","word_nosc":"cherissait","lemma":"chérir","pos":"VER"} ,
		{"word":"chérissant","word_nosc":"cherissant","lemma":"chérir","pos":"VER"} ,
		{"word":"chérisse","word_nosc":"cherisse","lemma":"chérir","pos":"VER"} ,
		{"word":"chérissent","word_nosc":"cherissent","lemma":"chérir","pos":"VER"} ,
		{"word":"chérissez","word_nosc":"cherissez","lemma":"chérir","pos":"VER"} ,
		{"word":"chérissons","word_nosc":"cherissons","lemma":"chérir","pos":"VER"} ,
		{"word":"chérit","word_nosc":"cherit","lemma":"chérir","pos":"VER"} ,
		{"word":"chômaient","word_nosc":"chomaient","lemma":"chômer","pos":"VER"} ,
		{"word":"chômait","word_nosc":"chomait","lemma":"chômer","pos":"VER"} ,
		{"word":"chôme","word_nosc":"chome","lemma":"chômer","pos":"VER"} ,
		{"word":"chôment","word_nosc":"choment","lemma":"chômer","pos":"VER"} ,
		{"word":"chômer","word_nosc":"chomer","lemma":"chômer","pos":"VER"} ,
		{"word":"chômerez","word_nosc":"chomerez","lemma":"chômer","pos":"VER"} ,
		{"word":"chômez","word_nosc":"chomez","lemma":"chômer","pos":"VER"} ,
		{"word":"chômé","word_nosc":"chome","lemma":"chômer","pos":"VER"} ,
		{"word":"ciblais","word_nosc":"ciblais","lemma":"cibler","pos":"VER"} ,
		{"word":"ciblait","word_nosc":"ciblait","lemma":"cibler","pos":"VER"} ,
		{"word":"cibler","word_nosc":"cibler","lemma":"cibler","pos":"VER"} ,
		{"word":"ciblera","word_nosc":"ciblera","lemma":"cibler","pos":"VER"} ,
		{"word":"cibleront","word_nosc":"cibleront","lemma":"cibler","pos":"VER"} ,
		{"word":"ciblez","word_nosc":"ciblez","lemma":"cibler","pos":"VER"} ,
		{"word":"ciblons","word_nosc":"ciblons","lemma":"cibler","pos":"VER"} ,
		{"word":"ciblé","word_nosc":"cible","lemma":"cibler","pos":"VER"} ,
		{"word":"ciblée","word_nosc":"ciblee","lemma":"cibler","pos":"VER"} ,
		{"word":"ciblées","word_nosc":"ciblees","lemma":"cibler","pos":"VER"} ,
		{"word":"ciblés","word_nosc":"cibles","lemma":"cibler","pos":"VER"} ,
		{"word":"cicatrisa","word_nosc":"cicatrisa","lemma":"cicatriser","pos":"VER"} ,
		{"word":"cicatrisait","word_nosc":"cicatrisait","lemma":"cicatriser","pos":"VER"} ,
		{"word":"cicatrise","word_nosc":"cicatrise","lemma":"cicatriser","pos":"VER"} ,
		{"word":"cicatrisent","word_nosc":"cicatrisent","lemma":"cicatriser","pos":"VER"} ,
		{"word":"cicatriser","word_nosc":"cicatriser","lemma":"cicatriser","pos":"VER"} ,
		{"word":"cicatrisera","word_nosc":"cicatrisera","lemma":"cicatriser","pos":"VER"} ,
		{"word":"cicatriserait","word_nosc":"cicatriserait","lemma":"cicatriser","pos":"VER"} ,
		{"word":"cicatriseront","word_nosc":"cicatriseront","lemma":"cicatriser","pos":"VER"} ,
		{"word":"cicatrisez","word_nosc":"cicatrisez","lemma":"cicatriser","pos":"VER"} ,
		{"word":"cicatrisé","word_nosc":"cicatrise","lemma":"cicatriser","pos":"VER"} ,
		{"word":"cicatrisée","word_nosc":"cicatrisee","lemma":"cicatriser","pos":"VER"} ,
		{"word":"cicatrisées","word_nosc":"cicatrisees","lemma":"cicatriser","pos":"VER"} ,
		{"word":"cicatrisés","word_nosc":"cicatrises","lemma":"cicatriser","pos":"VER"} ,
		{"word":"cigle","word_nosc":"cigle","lemma":"cigler","pos":"VER"} ,
		{"word":"ciglent","word_nosc":"ciglent","lemma":"cigler","pos":"VER"} ,
		{"word":"cigler","word_nosc":"cigler","lemma":"cigler","pos":"VER"} ,
		{"word":"ciglé","word_nosc":"cigle","lemma":"cigler","pos":"VER"} ,
		{"word":"ciglées","word_nosc":"ciglees","lemma":"cigler","pos":"VER"} ,
		{"word":"ciglés","word_nosc":"cigles","lemma":"cigler","pos":"VER"} ,
		{"word":"cilla","word_nosc":"cilla","lemma":"ciller","pos":"VER"} ,
		{"word":"cillaient","word_nosc":"cillaient","lemma":"ciller","pos":"VER"} ,
		{"word":"cillait","word_nosc":"cillait","lemma":"ciller","pos":"VER"} ,
		{"word":"cillant","word_nosc":"cillant","lemma":"ciller","pos":"VER"} ,
		{"word":"cille","word_nosc":"cille","lemma":"ciller","pos":"VER"} ,
		{"word":"cillent","word_nosc":"cillent","lemma":"ciller","pos":"VER"} ,
		{"word":"ciller","word_nosc":"ciller","lemma":"ciller","pos":"VER"} ,
		{"word":"cillerait","word_nosc":"cillerait","lemma":"ciller","pos":"VER"} ,
		{"word":"cillèrent","word_nosc":"cillerent","lemma":"ciller","pos":"VER"} ,
		{"word":"cillé","word_nosc":"cille","lemma":"ciller","pos":"VER"} ,
		{"word":"cimentaient","word_nosc":"cimentaient","lemma":"cimenter","pos":"VER"} ,
		{"word":"cimentait","word_nosc":"cimentait","lemma":"cimenter","pos":"VER"} ,
		{"word":"cimentant","word_nosc":"cimentant","lemma":"cimenter","pos":"VER"} ,
		{"word":"cimente","word_nosc":"cimente","lemma":"cimenter","pos":"VER"} ,
		{"word":"cimentent","word_nosc":"cimentent","lemma":"cimenter","pos":"VER"} ,
		{"word":"cimenter","word_nosc":"cimenter","lemma":"cimenter","pos":"VER"} ,
		{"word":"cimentera","word_nosc":"cimentera","lemma":"cimenter","pos":"VER"} ,
		{"word":"cimenté","word_nosc":"cimente","lemma":"cimenter","pos":"VER"} ,
		{"word":"cimentée","word_nosc":"cimentee","lemma":"cimenter","pos":"VER"} ,
		{"word":"cimentées","word_nosc":"cimentees","lemma":"cimenter","pos":"VER"} ,
		{"word":"cimentés","word_nosc":"cimentes","lemma":"cimenter","pos":"VER"} ,
		{"word":"cingla","word_nosc":"cingla","lemma":"cingler","pos":"VER"} ,
		{"word":"cinglaient","word_nosc":"cinglaient","lemma":"cingler","pos":"VER"} ,
		{"word":"cinglait","word_nosc":"cinglait","lemma":"cingler","pos":"VER"} ,
		{"word":"cinglant","word_nosc":"cinglant","lemma":"cingler","pos":"VER"} ,
		{"word":"cingle","word_nosc":"cingle","lemma":"cingler","pos":"VER"} ,
		{"word":"cinglent","word_nosc":"cinglent","lemma":"cingler","pos":"VER"} ,
		{"word":"cingler","word_nosc":"cingler","lemma":"cingler","pos":"VER"} ,
		{"word":"cinglions","word_nosc":"cinglions","lemma":"cingler","pos":"VER"} ,
		{"word":"cinglons","word_nosc":"cinglons","lemma":"cingler","pos":"VER"} ,
		{"word":"cinglèrent","word_nosc":"cinglerent","lemma":"cingler","pos":"VER"} ,
		{"word":"cinglé","word_nosc":"cingle","lemma":"cingler","pos":"VER"} ,
		{"word":"cinglée","word_nosc":"cinglee","lemma":"cingler","pos":"VER"} ,
		{"word":"cinglées","word_nosc":"cinglees","lemma":"cingler","pos":"VER"} ,
		{"word":"cinglés","word_nosc":"cingles","lemma":"cingler","pos":"VER"} ,
		{"word":"cintra","word_nosc":"cintra","lemma":"cintrer","pos":"VER"} ,
		{"word":"cintrait","word_nosc":"cintrait","lemma":"cintrer","pos":"VER"} ,
		{"word":"cintrant","word_nosc":"cintrant","lemma":"cintrer","pos":"VER"} ,
		{"word":"cintras","word_nosc":"cintras","lemma":"cintrer","pos":"VER"} ,
		{"word":"cintrer","word_nosc":"cintrer","lemma":"cintrer","pos":"VER"} ,
		{"word":"cintré","word_nosc":"cintre","lemma":"cintrer","pos":"VER"} ,
		{"word":"cintrée","word_nosc":"cintree","lemma":"cintrer","pos":"VER"} ,
		{"word":"cintrées","word_nosc":"cintrees","lemma":"cintrer","pos":"VER"} ,
		{"word":"cintrés","word_nosc":"cintres","lemma":"cintrer","pos":"VER"} ,
		{"word":"cira","word_nosc":"cira","lemma":"cirer","pos":"VER"} ,
		{"word":"ciraient","word_nosc":"ciraient","lemma":"cirer","pos":"VER"} ,
		{"word":"cirais","word_nosc":"cirais","lemma":"cirer","pos":"VER"} ,
		{"word":"cirait","word_nosc":"cirait","lemma":"cirer","pos":"VER"} ,
		{"word":"cirant","word_nosc":"cirant","lemma":"cirer","pos":"VER"} ,
		{"word":"circoncire","word_nosc":"circoncire","lemma":"circoncire","pos":"VER"} ,
		{"word":"circoncis","word_nosc":"circoncis","lemma":"circoncire","pos":"VER"} ,
		{"word":"circoncise","word_nosc":"circoncise","lemma":"circoncire","pos":"VER"} ,
		{"word":"circoncisent","word_nosc":"circoncisent","lemma":"circoncire","pos":"VER"} ,
		{"word":"circoncit","word_nosc":"circoncit","lemma":"circoncire","pos":"VER"} ,
		{"word":"circonscrire","word_nosc":"circonscrire","lemma":"circonscrire","pos":"VER"} ,
		{"word":"circonscris","word_nosc":"circonscris","lemma":"circonscrire","pos":"VER"} ,
		{"word":"circonscrit","word_nosc":"circonscrit","lemma":"circonscrire","pos":"VER"} ,
		{"word":"circonscrite","word_nosc":"circonscrite","lemma":"circonscrire","pos":"VER"} ,
		{"word":"circonscrites","word_nosc":"circonscrites","lemma":"circonscrire","pos":"VER"} ,
		{"word":"circonscrits","word_nosc":"circonscrits","lemma":"circonscrire","pos":"VER"} ,
		{"word":"circonscrivait","word_nosc":"circonscrivait","lemma":"circonscrire","pos":"VER"} ,
		{"word":"circonscrivent","word_nosc":"circonscrivent","lemma":"circonscrire","pos":"VER"} ,
		{"word":"circonscrivit","word_nosc":"circonscrivit","lemma":"circonscrire","pos":"VER"} ,
		{"word":"circonstancia","word_nosc":"circonstancia","lemma":"circonstancier","pos":"VER"} ,
		{"word":"circonstancié","word_nosc":"circonstancie","lemma":"circonstancier","pos":"VER"} ,
		{"word":"circonstanciée","word_nosc":"circonstanciee","lemma":"circonstancier","pos":"VER"} ,
		{"word":"circonvenant","word_nosc":"circonvenant","lemma":"circonvenir","pos":"VER"} ,
		{"word":"circonvenir","word_nosc":"circonvenir","lemma":"circonvenir","pos":"VER"} ,
		{"word":"circonvenu","word_nosc":"circonvenu","lemma":"circonvenir","pos":"VER"} ,
		{"word":"circonvenue","word_nosc":"circonvenue","lemma":"circonvenir","pos":"VER"} ,
		{"word":"circonvient","word_nosc":"circonvient","lemma":"circonvenir","pos":"VER"} ,
		{"word":"circula","word_nosc":"circula","lemma":"circuler","pos":"VER"} ,
		{"word":"circulaient","word_nosc":"circulaient","lemma":"circuler","pos":"VER"} ,
		{"word":"circulais","word_nosc":"circulais","lemma":"circuler","pos":"VER"} ,
		{"word":"circulait","word_nosc":"circulait","lemma":"circuler","pos":"VER"} ,
		{"word":"circulant","word_nosc":"circulant","lemma":"circuler","pos":"VER"} ,
		{"word":"circule","word_nosc":"circule","lemma":"circuler","pos":"VER"} ,
		{"word":"circulent","word_nosc":"circulent","lemma":"circuler","pos":"VER"} ,
		{"word":"circuler","word_nosc":"circuler","lemma":"circuler","pos":"VER"} ,
		{"word":"circulera","word_nosc":"circulera","lemma":"circuler","pos":"VER"} ,
		{"word":"circuleraient","word_nosc":"circuleraient","lemma":"circuler","pos":"VER"} ,
		{"word":"circulerait","word_nosc":"circulerait","lemma":"circuler","pos":"VER"} ,
		{"word":"circuleront","word_nosc":"circuleront","lemma":"circuler","pos":"VER"} ,
		{"word":"circules","word_nosc":"circules","lemma":"circuler","pos":"VER"} ,
		{"word":"circulez","word_nosc":"circulez","lemma":"circuler","pos":"VER"} ,
		{"word":"circulions","word_nosc":"circulions","lemma":"circuler","pos":"VER"} ,
		{"word":"circulons","word_nosc":"circulons","lemma":"circuler","pos":"VER"} ,
		{"word":"circulèrent","word_nosc":"circulerent","lemma":"circuler","pos":"VER"} ,
		{"word":"circulé","word_nosc":"circule","lemma":"circuler","pos":"VER"} ,
		{"word":"cire","word_nosc":"cire","lemma":"cirer","pos":"VER"} ,
		{"word":"cirent","word_nosc":"cirent","lemma":"cirer","pos":"VER"} ,
		{"word":"cirer","word_nosc":"cirer","lemma":"cirer","pos":"VER"} ,
		{"word":"cirerais","word_nosc":"cirerais","lemma":"cirer","pos":"VER"} ,
		{"word":"cirerait","word_nosc":"cirerait","lemma":"cirer","pos":"VER"} ,
		{"word":"cires","word_nosc":"cires","lemma":"cirer","pos":"VER"} ,
		{"word":"cirez","word_nosc":"cirez","lemma":"cirer","pos":"VER"} ,
		{"word":"cirions","word_nosc":"cirions","lemma":"cirer","pos":"VER"} ,
		{"word":"cirons","word_nosc":"cirons","lemma":"cirer","pos":"VER"} ,
		{"word":"ciré","word_nosc":"cire","lemma":"cirer","pos":"VER"} ,
		{"word":"cirée","word_nosc":"ciree","lemma":"cirer","pos":"VER"} ,
		{"word":"cirées","word_nosc":"cirees","lemma":"cirer","pos":"VER"} ,
		{"word":"cirés","word_nosc":"cires","lemma":"cirer","pos":"VER"} ,
		{"word":"cisailla","word_nosc":"cisailla","lemma":"cisailler","pos":"VER"} ,
		{"word":"cisaillaient","word_nosc":"cisaillaient","lemma":"cisailler","pos":"VER"} ,
		{"word":"cisaillait","word_nosc":"cisaillait","lemma":"cisailler","pos":"VER"} ,
		{"word":"cisaillant","word_nosc":"cisaillant","lemma":"cisailler","pos":"VER"} ,
		{"word":"cisaille","word_nosc":"cisaille","lemma":"cisailler","pos":"VER"} ,
		{"word":"cisaillent","word_nosc":"cisaillent","lemma":"cisailler","pos":"VER"} ,
		{"word":"cisailler","word_nosc":"cisailler","lemma":"cisailler","pos":"VER"} ,
		{"word":"cisaillèrent","word_nosc":"cisaillerent","lemma":"cisailler","pos":"VER"} ,
		{"word":"cisaillé","word_nosc":"cisaille","lemma":"cisailler","pos":"VER"} ,
		{"word":"cisaillée","word_nosc":"cisaillee","lemma":"cisailler","pos":"VER"} ,
		{"word":"cisaillées","word_nosc":"cisaillees","lemma":"cisailler","pos":"VER"} ,
		{"word":"cisaillés","word_nosc":"cisailles","lemma":"cisailler","pos":"VER"} ,
		{"word":"ciselaient","word_nosc":"ciselaient","lemma":"ciseler","pos":"VER"} ,
		{"word":"ciselait","word_nosc":"ciselait","lemma":"ciseler","pos":"VER"} ,
		{"word":"ciseler","word_nosc":"ciseler","lemma":"ciseler","pos":"VER"} ,
		{"word":"ciselions","word_nosc":"ciselions","lemma":"ciseler","pos":"VER"} ,
		{"word":"ciselé","word_nosc":"cisele","lemma":"ciseler","pos":"VER"} ,
		{"word":"ciselée","word_nosc":"ciselee","lemma":"ciseler","pos":"VER"} ,
		{"word":"ciselées","word_nosc":"ciselees","lemma":"ciseler","pos":"VER"} ,
		{"word":"ciselés","word_nosc":"ciseles","lemma":"ciseler","pos":"VER"} ,
		{"word":"cisèle","word_nosc":"cisele","lemma":"ciseler","pos":"VER"} ,
		{"word":"cita","word_nosc":"cita","lemma":"citer","pos":"VER"} ,
		{"word":"citai","word_nosc":"citai","lemma":"citer","pos":"VER"} ,
		{"word":"citaient","word_nosc":"citaient","lemma":"citer","pos":"VER"} ,
		{"word":"citais","word_nosc":"citais","lemma":"citer","pos":"VER"} ,
		{"word":"citait","word_nosc":"citait","lemma":"citer","pos":"VER"} ,
		{"word":"citant","word_nosc":"citant","lemma":"citer","pos":"VER"} ,
		{"word":"cite","word_nosc":"cite","lemma":"citer","pos":"VER"} ,
		{"word":"citent","word_nosc":"citent","lemma":"citer","pos":"VER"} ,
		{"word":"citer","word_nosc":"citer","lemma":"citer","pos":"VER"} ,
		{"word":"citera","word_nosc":"citera","lemma":"citer","pos":"VER"} ,
		{"word":"citerai","word_nosc":"citerai","lemma":"citer","pos":"VER"} ,
		{"word":"citerais","word_nosc":"citerais","lemma":"citer","pos":"VER"} ,
		{"word":"citerait","word_nosc":"citerait","lemma":"citer","pos":"VER"} ,
		{"word":"citeras","word_nosc":"citeras","lemma":"citer","pos":"VER"} ,
		{"word":"cites","word_nosc":"cites","lemma":"citer","pos":"VER"} ,
		{"word":"citez","word_nosc":"citez","lemma":"citer","pos":"VER"} ,
		{"word":"citiez","word_nosc":"citiez","lemma":"citer","pos":"VER"} ,
		{"word":"citions","word_nosc":"citions","lemma":"citer","pos":"VER"} ,
		{"word":"citons","word_nosc":"citons","lemma":"citer","pos":"VER"} ,
		{"word":"citronnez","word_nosc":"citronnez","lemma":"citronner","pos":"VER"} ,
		{"word":"citronnée","word_nosc":"citronnee","lemma":"citronner","pos":"VER"} ,
		{"word":"citât","word_nosc":"citat","lemma":"citer","pos":"VER"} ,
		{"word":"citèrent","word_nosc":"citerent","lemma":"citer","pos":"VER"} ,
		{"word":"cité","word_nosc":"cite","lemma":"citer","pos":"VER"} ,
		{"word":"citée","word_nosc":"citee","lemma":"citer","pos":"VER"} ,
		{"word":"citées","word_nosc":"citees","lemma":"citer","pos":"VER"} ,
		{"word":"cités","word_nosc":"cites","lemma":"citer","pos":"VER"} ,
		{"word":"civilise","word_nosc":"civilise","lemma":"civiliser","pos":"VER"} ,
		{"word":"civiliser","word_nosc":"civiliser","lemma":"civiliser","pos":"VER"} ,
		{"word":"civilises","word_nosc":"civilises","lemma":"civiliser","pos":"VER"} ,
		{"word":"civilisé","word_nosc":"civilise","lemma":"civiliser","pos":"VER"} ,
		{"word":"civilisée","word_nosc":"civilisee","lemma":"civiliser","pos":"VER"} ,
		{"word":"civilisées","word_nosc":"civilisees","lemma":"civiliser","pos":"VER"} ,
		{"word":"civilisés","word_nosc":"civilises","lemma":"civiliser","pos":"VER"} ,
		{"word":"clabaudaient","word_nosc":"clabaudaient","lemma":"clabauder","pos":"VER"} ,
		{"word":"clabaudent","word_nosc":"clabaudent","lemma":"clabauder","pos":"VER"} ,
		{"word":"clabauder","word_nosc":"clabauder","lemma":"clabauder","pos":"VER"} ,
		{"word":"clabaudé","word_nosc":"clabaude","lemma":"clabauder","pos":"VER"} ,
		{"word":"clabote","word_nosc":"clabote","lemma":"claboter","pos":"VER"} ,
		{"word":"clabotent","word_nosc":"clabotent","lemma":"claboter","pos":"VER"} ,
		{"word":"claboter","word_nosc":"claboter","lemma":"claboter","pos":"VER"} ,
		{"word":"claboté","word_nosc":"clabote","lemma":"claboter","pos":"VER"} ,
		{"word":"claironna","word_nosc":"claironna","lemma":"claironner","pos":"VER"} ,
		{"word":"claironnait","word_nosc":"claironnait","lemma":"claironner","pos":"VER"} ,
		{"word":"claironnant","word_nosc":"claironnant","lemma":"claironner","pos":"VER"} ,
		{"word":"claironne","word_nosc":"claironne","lemma":"claironner","pos":"VER"} ,
		{"word":"claironnent","word_nosc":"claironnent","lemma":"claironner","pos":"VER"} ,
		{"word":"claironner","word_nosc":"claironner","lemma":"claironner","pos":"VER"} ,
		{"word":"claironnions","word_nosc":"claironnions","lemma":"claironner","pos":"VER"} ,
		{"word":"claironné","word_nosc":"claironne","lemma":"claironner","pos":"VER"} ,
		{"word":"clairsemaient","word_nosc":"clairsemaient","lemma":"clairsemer","pos":"VER"} ,
		{"word":"clairsemait","word_nosc":"clairsemait","lemma":"clairsemer","pos":"VER"} ,
		{"word":"clairsemant","word_nosc":"clairsemant","lemma":"clairsemer","pos":"VER"} ,
		{"word":"clairsemé","word_nosc":"clairseme","lemma":"clairsemer","pos":"VER"} ,
		{"word":"clairsemée","word_nosc":"clairsemee","lemma":"clairsemer","pos":"VER"} ,
		{"word":"clairsemées","word_nosc":"clairsemees","lemma":"clairsemer","pos":"VER"} ,
		{"word":"clairsemés","word_nosc":"clairsemes","lemma":"clairsemer","pos":"VER"} ,
		{"word":"clama","word_nosc":"clama","lemma":"clamer","pos":"VER"} ,
		{"word":"clamai","word_nosc":"clamai","lemma":"clamer","pos":"VER"} ,
		{"word":"clamaient","word_nosc":"clamaient","lemma":"clamer","pos":"VER"} ,
		{"word":"clamait","word_nosc":"clamait","lemma":"clamer","pos":"VER"} ,
		{"word":"clamant","word_nosc":"clamant","lemma":"clamer","pos":"VER"} ,
		{"word":"clame","word_nosc":"clame","lemma":"clamer","pos":"VER"} ,
		{"word":"clamecer","word_nosc":"clamecer","lemma":"clamecer","pos":"VER"} ,
		{"word":"clamecé","word_nosc":"clamece","lemma":"clamecer","pos":"VER"} ,
		{"word":"clamecée","word_nosc":"clamecee","lemma":"clamecer","pos":"VER"} ,
		{"word":"clament","word_nosc":"clament","lemma":"clamer","pos":"VER"} ,
		{"word":"clamer","word_nosc":"clamer","lemma":"clamer","pos":"VER"} ,
		{"word":"clameraient","word_nosc":"clameraient","lemma":"clamer","pos":"VER"} ,
		{"word":"clamez","word_nosc":"clamez","lemma":"clamer","pos":"VER"} ,
		{"word":"clamions","word_nosc":"clamions","lemma":"clamer","pos":"VER"} ,
		{"word":"clampe","word_nosc":"clampe","lemma":"clamper","pos":"VER"} ,
		{"word":"clamper","word_nosc":"clamper","lemma":"clamper","pos":"VER"} ,
		{"word":"clampé","word_nosc":"clampe","lemma":"clamper","pos":"VER"} ,
		{"word":"clamse","word_nosc":"clamse","lemma":"clamser","pos":"VER"} ,
		{"word":"clamser","word_nosc":"clamser","lemma":"clamser","pos":"VER"} ,
		{"word":"clamserai","word_nosc":"clamserai","lemma":"clamser","pos":"VER"} ,
		{"word":"clamserait","word_nosc":"clamserait","lemma":"clamser","pos":"VER"} ,
		{"word":"clamsé","word_nosc":"clamse","lemma":"clamser","pos":"VER"} ,
		{"word":"clamsée","word_nosc":"clamsee","lemma":"clamser","pos":"VER"} ,
		{"word":"clamsés","word_nosc":"clamses","lemma":"clamser","pos":"VER"} ,
		{"word":"clamèrent","word_nosc":"clamerent","lemma":"clamer","pos":"VER"} ,
		{"word":"clamé","word_nosc":"clame","lemma":"clamer","pos":"VER"} ,
		{"word":"clamée","word_nosc":"clamee","lemma":"clamer","pos":"VER"} ,
		{"word":"clamés","word_nosc":"clames","lemma":"clamer","pos":"VER"} ,
		{"word":"clapant","word_nosc":"clapant","lemma":"claper","pos":"VER"} ,
		{"word":"clape","word_nosc":"clape","lemma":"claper","pos":"VER"} ,
		{"word":"clapent","word_nosc":"clapent","lemma":"claper","pos":"VER"} ,
		{"word":"claper","word_nosc":"claper","lemma":"claper","pos":"VER"} ,
		{"word":"claperai","word_nosc":"claperai","lemma":"claper","pos":"VER"} ,
		{"word":"clapes","word_nosc":"clapes","lemma":"claper","pos":"VER"} ,
		{"word":"clapir","word_nosc":"clapir","lemma":"clapir","pos":"VER"} ,
		{"word":"clapit","word_nosc":"clapit","lemma":"clapir","pos":"VER"} ,
		{"word":"clapota","word_nosc":"clapota","lemma":"clapoter","pos":"VER"} ,
		{"word":"clapotaient","word_nosc":"clapotaient","lemma":"clapoter","pos":"VER"} ,
		{"word":"clapotait","word_nosc":"clapotait","lemma":"clapoter","pos":"VER"} ,
		{"word":"clapotant","word_nosc":"clapotant","lemma":"clapoter","pos":"VER"} ,
		{"word":"clapote","word_nosc":"clapote","lemma":"clapoter","pos":"VER"} ,
		{"word":"clapotent","word_nosc":"clapotent","lemma":"clapoter","pos":"VER"} ,
		{"word":"clapoter","word_nosc":"clapoter","lemma":"clapoter","pos":"VER"} ,
		{"word":"clapoterait","word_nosc":"clapoterait","lemma":"clapoter","pos":"VER"} ,
		{"word":"clappait","word_nosc":"clappait","lemma":"clapper","pos":"VER"} ,
		{"word":"clappant","word_nosc":"clappant","lemma":"clapper","pos":"VER"} ,
		{"word":"clappe","word_nosc":"clappe","lemma":"clapper","pos":"VER"} ,
		{"word":"clapper","word_nosc":"clapper","lemma":"clapper","pos":"VER"} ,
		{"word":"clapé","word_nosc":"clape","lemma":"claper","pos":"VER"} ,
		{"word":"claqua","word_nosc":"claqua","lemma":"claquer","pos":"VER"} ,
		{"word":"claquai","word_nosc":"claquai","lemma":"claquer","pos":"VER"} ,
		{"word":"claquaient","word_nosc":"claquaient","lemma":"claquer","pos":"VER"} ,
		{"word":"claquais","word_nosc":"claquais","lemma":"claquer","pos":"VER"} ,
		{"word":"claquait","word_nosc":"claquait","lemma":"claquer","pos":"VER"} ,
		{"word":"claquant","word_nosc":"claquant","lemma":"claquer","pos":"VER"} ,
		{"word":"claque","word_nosc":"claque","lemma":"claquer","pos":"VER"} ,
		{"word":"claquemurait","word_nosc":"claquemurait","lemma":"claquemurer","pos":"VER"} ,
		{"word":"claquemurant","word_nosc":"claquemurant","lemma":"claquemurer","pos":"VER"} ,
		{"word":"claquemurer","word_nosc":"claquemurer","lemma":"claquemurer","pos":"VER"} ,
		{"word":"claquemures","word_nosc":"claquemures","lemma":"claquemurer","pos":"VER"} ,
		{"word":"claquemuré","word_nosc":"claquemure","lemma":"claquemurer","pos":"VER"} ,
		{"word":"claquemurée","word_nosc":"claquemuree","lemma":"claquemurer","pos":"VER"} ,
		{"word":"claquemurées","word_nosc":"claquemurees","lemma":"claquemurer","pos":"VER"} ,
		{"word":"claquemurés","word_nosc":"claquemures","lemma":"claquemurer","pos":"VER"} ,
		{"word":"claquent","word_nosc":"claquent","lemma":"claquer","pos":"VER"} ,
		{"word":"claquer","word_nosc":"claquer","lemma":"claquer","pos":"VER"} ,
		{"word":"claquera","word_nosc":"claquera","lemma":"claquer","pos":"VER"} ,
		{"word":"claquerai","word_nosc":"claquerai","lemma":"claquer","pos":"VER"} ,
		{"word":"claqueraient","word_nosc":"claqueraient","lemma":"claquer","pos":"VER"} ,
		{"word":"claquerais","word_nosc":"claquerais","lemma":"claquer","pos":"VER"} ,
		{"word":"claquerait","word_nosc":"claquerait","lemma":"claquer","pos":"VER"} ,
		{"word":"claqueriez","word_nosc":"claqueriez","lemma":"claquer","pos":"VER"} ,
		{"word":"claqueront","word_nosc":"claqueront","lemma":"claquer","pos":"VER"} ,
		{"word":"claques","word_nosc":"claques","lemma":"claquer","pos":"VER"} ,
		{"word":"claquetèrent","word_nosc":"claqueterent","lemma":"claqueter","pos":"VER"} ,
		{"word":"claquez","word_nosc":"claquez","lemma":"claquer","pos":"VER"} ,
		{"word":"claquions","word_nosc":"claquions","lemma":"claquer","pos":"VER"} ,
		{"word":"claquons","word_nosc":"claquons","lemma":"claquer","pos":"VER"} ,
		{"word":"claquât","word_nosc":"claquat","lemma":"claquer","pos":"VER"} ,
		{"word":"claquèrent","word_nosc":"claquerent","lemma":"claquer","pos":"VER"} ,
		{"word":"claqué","word_nosc":"claque","lemma":"claquer","pos":"VER"} ,
		{"word":"claquée","word_nosc":"claquee","lemma":"claquer","pos":"VER"} ,
		{"word":"claquées","word_nosc":"claquees","lemma":"claquer","pos":"VER"} ,
		{"word":"claqués","word_nosc":"claques","lemma":"claquer","pos":"VER"} ,
		{"word":"clarifia","word_nosc":"clarifia","lemma":"clarifier","pos":"VER"} ,
		{"word":"clarifiant","word_nosc":"clarifiant","lemma":"clarifier","pos":"VER"} ,
		{"word":"clarifie","word_nosc":"clarifie","lemma":"clarifier","pos":"VER"} ,
		{"word":"clarifier","word_nosc":"clarifier","lemma":"clarifier","pos":"VER"} ,
		{"word":"clarifions","word_nosc":"clarifions","lemma":"clarifier","pos":"VER"} ,
		{"word":"clarifié","word_nosc":"clarifie","lemma":"clarifier","pos":"VER"} ,
		{"word":"clarifiée","word_nosc":"clarifiee","lemma":"clarifier","pos":"VER"} ,
		{"word":"classa","word_nosc":"classa","lemma":"classer","pos":"VER"} ,
		{"word":"classaient","word_nosc":"classaient","lemma":"classer","pos":"VER"} ,
		{"word":"classais","word_nosc":"classais","lemma":"classer","pos":"VER"} ,
		{"word":"classait","word_nosc":"classait","lemma":"classer","pos":"VER"} ,
		{"word":"classant","word_nosc":"classant","lemma":"classer","pos":"VER"} ,
		{"word":"classe","word_nosc":"classe","lemma":"classer","pos":"VER"} ,
		{"word":"classent","word_nosc":"classent","lemma":"classer","pos":"VER"} ,
		{"word":"classer","word_nosc":"classer","lemma":"classer","pos":"VER"} ,
		{"word":"classera","word_nosc":"classera","lemma":"classer","pos":"VER"} ,
		{"word":"classerai","word_nosc":"classerai","lemma":"classer","pos":"VER"} ,
		{"word":"classeraient","word_nosc":"classeraient","lemma":"classer","pos":"VER"} ,
		{"word":"classerais","word_nosc":"classerais","lemma":"classer","pos":"VER"} ,
		{"word":"classerait","word_nosc":"classerait","lemma":"classer","pos":"VER"} ,
		{"word":"classeras","word_nosc":"classeras","lemma":"classer","pos":"VER"} ,
		{"word":"classes","word_nosc":"classes","lemma":"classer","pos":"VER"} ,
		{"word":"classez","word_nosc":"classez","lemma":"classer","pos":"VER"} ,
		{"word":"classiez","word_nosc":"classiez","lemma":"classer","pos":"VER"} ,
		{"word":"classifient","word_nosc":"classifient","lemma":"classifier","pos":"VER"} ,
		{"word":"classifier","word_nosc":"classifier","lemma":"classifier","pos":"VER"} ,
		{"word":"classifierais","word_nosc":"classifierais","lemma":"classifier","pos":"VER"} ,
		{"word":"classifié","word_nosc":"classifie","lemma":"classifier","pos":"VER"} ,
		{"word":"classifiées","word_nosc":"classifiees","lemma":"classifier","pos":"VER"} ,
		{"word":"classons","word_nosc":"classons","lemma":"classer","pos":"VER"} ,
		{"word":"classèrent","word_nosc":"classerent","lemma":"classer","pos":"VER"} ,
		{"word":"classé","word_nosc":"classe","lemma":"classer","pos":"VER"} ,
		{"word":"classée","word_nosc":"classee","lemma":"classer","pos":"VER"} ,
		{"word":"classées","word_nosc":"classees","lemma":"classer","pos":"VER"} ,
		{"word":"classés","word_nosc":"classes","lemma":"classer","pos":"VER"} ,
		{"word":"claudiquait","word_nosc":"claudiquait","lemma":"claudiquer","pos":"VER"} ,
		{"word":"claudiquant","word_nosc":"claudiquant","lemma":"claudiquer","pos":"VER"} ,
		{"word":"claudique","word_nosc":"claudique","lemma":"claudiquer","pos":"VER"} ,
		{"word":"claudiquer","word_nosc":"claudiquer","lemma":"claudiquer","pos":"VER"} ,
		{"word":"claudiquerait","word_nosc":"claudiquerait","lemma":"claudiquer","pos":"VER"} ,
		{"word":"claudiquerez","word_nosc":"claudiquerez","lemma":"claudiquer","pos":"VER"} ,
		{"word":"claustrait","word_nosc":"claustrait","lemma":"claustrer","pos":"VER"} ,
		{"word":"claustrée","word_nosc":"claustree","lemma":"claustrer","pos":"VER"} ,
		{"word":"clayonnée","word_nosc":"clayonnee","lemma":"clayonner","pos":"VER"} ,
		{"word":"clicher","word_nosc":"clicher","lemma":"clicher","pos":"VER"} ,
		{"word":"cliché","word_nosc":"cliche","lemma":"clicher","pos":"VER"} ,
		{"word":"clichés","word_nosc":"cliches","lemma":"clicher","pos":"VER"} ,
		{"word":"cligna","word_nosc":"cligna","lemma":"cligner","pos":"VER"} ,
		{"word":"clignaient","word_nosc":"clignaient","lemma":"cligner","pos":"VER"} ,
		{"word":"clignais","word_nosc":"clignais","lemma":"cligner","pos":"VER"} ,
		{"word":"clignait","word_nosc":"clignait","lemma":"cligner","pos":"VER"} ,
		{"word":"clignant","word_nosc":"clignant","lemma":"cligner","pos":"VER"} ,
		{"word":"cligne","word_nosc":"cligne","lemma":"cligner","pos":"VER"} ,
		{"word":"clignent","word_nosc":"clignent","lemma":"cligner","pos":"VER"} ,
		{"word":"cligner","word_nosc":"cligner","lemma":"cligner","pos":"VER"} ,
		{"word":"clignez","word_nosc":"clignez","lemma":"cligner","pos":"VER"} ,
		{"word":"clignons","word_nosc":"clignons","lemma":"cligner","pos":"VER"} ,
		{"word":"clignota","word_nosc":"clignota","lemma":"clignoter","pos":"VER"} ,
		{"word":"clignotaient","word_nosc":"clignotaient","lemma":"clignoter","pos":"VER"} ,
		{"word":"clignotait","word_nosc":"clignotait","lemma":"clignoter","pos":"VER"} ,
		{"word":"clignotant","word_nosc":"clignotant","lemma":"clignoter","pos":"VER"} ,
		{"word":"clignote","word_nosc":"clignote","lemma":"clignoter","pos":"VER"} ,
		{"word":"clignotent","word_nosc":"clignotent","lemma":"clignoter","pos":"VER"} ,
		{"word":"clignoter","word_nosc":"clignoter","lemma":"clignoter","pos":"VER"} ,
		{"word":"clignotera","word_nosc":"clignotera","lemma":"clignoter","pos":"VER"} ,
		{"word":"clignoterait","word_nosc":"clignoterait","lemma":"clignoter","pos":"VER"} ,
		{"word":"clignotèrent","word_nosc":"clignoterent","lemma":"clignoter","pos":"VER"} ,
		{"word":"clignoté","word_nosc":"clignote","lemma":"clignoter","pos":"VER"} ,
		{"word":"clignèrent","word_nosc":"clignerent","lemma":"cligner","pos":"VER"} ,
		{"word":"cligné","word_nosc":"cligne","lemma":"cligner","pos":"VER"} ,
		{"word":"clignées","word_nosc":"clignees","lemma":"cligner","pos":"VER"} ,
		{"word":"clignés","word_nosc":"clignes","lemma":"cligner","pos":"VER"} ,
		{"word":"climatisant","word_nosc":"climatisant","lemma":"climatiser","pos":"VER"} ,
		{"word":"climatiser","word_nosc":"climatiser","lemma":"climatiser","pos":"VER"} ,
		{"word":"climatisé","word_nosc":"climatise","lemma":"climatiser","pos":"VER"} ,
		{"word":"climatisée","word_nosc":"climatisee","lemma":"climatiser","pos":"VER"} ,
		{"word":"climatisées","word_nosc":"climatisees","lemma":"climatiser","pos":"VER"} ,
		{"word":"cliquant","word_nosc":"cliquant","lemma":"cliquer","pos":"VER"} ,
		{"word":"cliquer","word_nosc":"cliquer","lemma":"cliquer","pos":"VER"} ,
		{"word":"cliqueta","word_nosc":"cliqueta","lemma":"cliqueter","pos":"VER"} ,
		{"word":"cliquetaient","word_nosc":"cliquetaient","lemma":"cliqueter","pos":"VER"} ,
		{"word":"cliquetait","word_nosc":"cliquetait","lemma":"cliqueter","pos":"VER"} ,
		{"word":"cliquetant","word_nosc":"cliquetant","lemma":"cliqueter","pos":"VER"} ,
		{"word":"cliqueter","word_nosc":"cliqueter","lemma":"cliqueter","pos":"VER"} ,
		{"word":"cliquette","word_nosc":"cliquette","lemma":"cliqueter","pos":"VER"} ,
		{"word":"cliquettent","word_nosc":"cliquettent","lemma":"cliqueter","pos":"VER"} ,
		{"word":"cliquez","word_nosc":"cliquez","lemma":"cliquer","pos":"VER"} ,
		{"word":"cliqué","word_nosc":"clique","lemma":"cliquer","pos":"VER"} ,
		{"word":"clissées","word_nosc":"clissees","lemma":"clisser","pos":"VER"} ,
		{"word":"cliver","word_nosc":"cliver","lemma":"cliver","pos":"VER"} ,
		{"word":"clochaient","word_nosc":"clochaient","lemma":"clocher","pos":"VER"} ,
		{"word":"clochait","word_nosc":"clochait","lemma":"clocher","pos":"VER"} ,
		{"word":"clochant","word_nosc":"clochant","lemma":"clocher","pos":"VER"} ,
		{"word":"clochardisait","word_nosc":"clochardisait","lemma":"clochardiser","pos":"VER"} ,
		{"word":"clochardiser","word_nosc":"clochardiser","lemma":"clochardiser","pos":"VER"} ,
		{"word":"clochardisé","word_nosc":"clochardise","lemma":"clochardiser","pos":"VER"} ,
		{"word":"cloche","word_nosc":"cloche","lemma":"clocher","pos":"VER"} ,
		{"word":"clochent","word_nosc":"clochent","lemma":"clocher","pos":"VER"} ,
		{"word":"clocher","word_nosc":"clocher","lemma":"clocher","pos":"VER"} ,
		{"word":"cloches","word_nosc":"cloches","lemma":"clocher","pos":"VER"} ,
		{"word":"clochetons","word_nosc":"clochetons","lemma":"clocheter","pos":"VER"} ,
		{"word":"clochât","word_nosc":"clochat","lemma":"clocher","pos":"VER"} ,
		{"word":"cloché","word_nosc":"cloche","lemma":"clocher","pos":"VER"} ,
		{"word":"cloisonnaient","word_nosc":"cloisonnaient","lemma":"cloisonner","pos":"VER"} ,
		{"word":"cloisonnais","word_nosc":"cloisonnais","lemma":"cloisonner","pos":"VER"} ,
		{"word":"cloisonnant","word_nosc":"cloisonnant","lemma":"cloisonner","pos":"VER"} ,
		{"word":"cloisonne","word_nosc":"cloisonne","lemma":"cloisonner","pos":"VER"} ,
		{"word":"cloisonner","word_nosc":"cloisonner","lemma":"cloisonner","pos":"VER"} ,
		{"word":"cloisonné","word_nosc":"cloisonne","lemma":"cloisonner","pos":"VER"} ,
		{"word":"cloisonnée","word_nosc":"cloisonnee","lemma":"cloisonner","pos":"VER"} ,
		{"word":"cloisonnés","word_nosc":"cloisonnes","lemma":"cloisonner","pos":"VER"} ,
		{"word":"clonant","word_nosc":"clonant","lemma":"cloner","pos":"VER"} ,
		{"word":"clone","word_nosc":"clone","lemma":"cloner","pos":"VER"} ,
		{"word":"clonent","word_nosc":"clonent","lemma":"cloner","pos":"VER"} ,
		{"word":"cloner","word_nosc":"cloner","lemma":"cloner","pos":"VER"} ,
		{"word":"cloné","word_nosc":"clone","lemma":"cloner","pos":"VER"} ,
		{"word":"clonés","word_nosc":"clones","lemma":"cloner","pos":"VER"} ,
		{"word":"clopais","word_nosc":"clopais","lemma":"cloper","pos":"VER"} ,
		{"word":"clopant","word_nosc":"clopant","lemma":"cloper","pos":"VER"} ,
		{"word":"clope","word_nosc":"clope","lemma":"cloper","pos":"VER"} ,
		{"word":"cloper","word_nosc":"cloper","lemma":"cloper","pos":"VER"} ,
		{"word":"clopes","word_nosc":"clopes","lemma":"cloper","pos":"VER"} ,
		{"word":"clopina","word_nosc":"clopina","lemma":"clopiner","pos":"VER"} ,
		{"word":"clopinait","word_nosc":"clopinait","lemma":"clopiner","pos":"VER"} ,
		{"word":"clopinant","word_nosc":"clopinant","lemma":"clopiner","pos":"VER"} ,
		{"word":"clopine","word_nosc":"clopine","lemma":"clopiner","pos":"VER"} ,
		{"word":"clopiner","word_nosc":"clopiner","lemma":"clopiner","pos":"VER"} ,
		{"word":"clopinez","word_nosc":"clopinez","lemma":"clopiner","pos":"VER"} ,
		{"word":"clopinâmes","word_nosc":"clopinames","lemma":"clopiner","pos":"VER"} ,
		{"word":"cloquait","word_nosc":"cloquait","lemma":"cloquer","pos":"VER"} ,
		{"word":"cloquant","word_nosc":"cloquant","lemma":"cloquer","pos":"VER"} ,
		{"word":"cloque","word_nosc":"cloque","lemma":"cloquer","pos":"VER"} ,
		{"word":"cloquent","word_nosc":"cloquent","lemma":"cloquer","pos":"VER"} ,
		{"word":"cloquer","word_nosc":"cloquer","lemma":"cloquer","pos":"VER"} ,
		{"word":"cloqué","word_nosc":"cloque","lemma":"cloquer","pos":"VER"} ,
		{"word":"cloquée","word_nosc":"cloquee","lemma":"cloquer","pos":"VER"} ,
		{"word":"cloquées","word_nosc":"cloquees","lemma":"cloquer","pos":"VER"} ,
		{"word":"cloqués","word_nosc":"cloques","lemma":"cloquer","pos":"VER"} ,
		{"word":"clora","word_nosc":"clora","lemma":"clore","pos":"VER"} ,
		{"word":"clore","word_nosc":"clore","lemma":"clore","pos":"VER"} ,
		{"word":"clos","word_nosc":"clos","lemma":"clore","pos":"VER"} ,
		{"word":"close","word_nosc":"close","lemma":"clore","pos":"VER"} ,
		{"word":"closent","word_nosc":"closent","lemma":"clore","pos":"VER"} ,
		{"word":"closes","word_nosc":"closes","lemma":"clore","pos":"VER"} ,
		{"word":"cloua","word_nosc":"cloua","lemma":"clouer","pos":"VER"} ,
		{"word":"clouai","word_nosc":"clouai","lemma":"clouer","pos":"VER"} ,
		{"word":"clouaient","word_nosc":"clouaient","lemma":"clouer","pos":"VER"} ,
		{"word":"clouait","word_nosc":"clouait","lemma":"clouer","pos":"VER"} ,
		{"word":"clouant","word_nosc":"clouant","lemma":"clouer","pos":"VER"} ,
		{"word":"cloue","word_nosc":"cloue","lemma":"clouer","pos":"VER"} ,
		{"word":"clouent","word_nosc":"clouent","lemma":"clouer","pos":"VER"} ,
		{"word":"clouer","word_nosc":"clouer","lemma":"clouer","pos":"VER"} ,
		{"word":"clouera","word_nosc":"clouera","lemma":"clouer","pos":"VER"} ,
		{"word":"clouerai","word_nosc":"clouerai","lemma":"clouer","pos":"VER"} ,
		{"word":"cloueraient","word_nosc":"cloueraient","lemma":"clouer","pos":"VER"} ,
		{"word":"clouerait","word_nosc":"clouerait","lemma":"clouer","pos":"VER"} ,
		{"word":"cloueras","word_nosc":"cloueras","lemma":"clouer","pos":"VER"} ,
		{"word":"clouerez","word_nosc":"clouerez","lemma":"clouer","pos":"VER"} ,
		{"word":"clouez","word_nosc":"clouez","lemma":"clouer","pos":"VER"} ,
		{"word":"clouions","word_nosc":"clouions","lemma":"clouer","pos":"VER"} ,
		{"word":"clouons","word_nosc":"clouons","lemma":"clouer","pos":"VER"} ,
		{"word":"clouté","word_nosc":"cloute","lemma":"clouter","pos":"VER"} ,
		{"word":"cloutée","word_nosc":"cloutee","lemma":"clouter","pos":"VER"} ,
		{"word":"cloutées","word_nosc":"cloutees","lemma":"clouter","pos":"VER"} ,
		{"word":"cloutés","word_nosc":"cloutes","lemma":"clouter","pos":"VER"} ,
		{"word":"clouèrent","word_nosc":"clouerent","lemma":"clouer","pos":"VER"} ,
		{"word":"cloué","word_nosc":"cloue","lemma":"clouer","pos":"VER"} ,
		{"word":"clouée","word_nosc":"clouee","lemma":"clouer","pos":"VER"} ,
		{"word":"clouées","word_nosc":"clouees","lemma":"clouer","pos":"VER"} ,
		{"word":"cloués","word_nosc":"cloues","lemma":"clouer","pos":"VER"} ,
		{"word":"cloîtra","word_nosc":"cloitra","lemma":"cloîtrer","pos":"VER"} ,
		{"word":"cloîtrai","word_nosc":"cloitrai","lemma":"cloîtrer","pos":"VER"} ,
		{"word":"cloîtraient","word_nosc":"cloitraient","lemma":"cloîtrer","pos":"VER"} ,
		{"word":"cloîtrait","word_nosc":"cloitrait","lemma":"cloîtrer","pos":"VER"} ,
		{"word":"cloîtrant","word_nosc":"cloitrant","lemma":"cloîtrer","pos":"VER"} ,
		{"word":"cloître","word_nosc":"cloitre","lemma":"cloîtrer","pos":"VER"} ,
		{"word":"cloîtrer","word_nosc":"cloitrer","lemma":"cloîtrer","pos":"VER"} ,
		{"word":"cloîtres","word_nosc":"cloitres","lemma":"cloîtrer","pos":"VER"} ,
		{"word":"cloîtrâmes","word_nosc":"cloitrames","lemma":"cloîtrer","pos":"VER"} ,
		{"word":"cloîtré","word_nosc":"cloitre","lemma":"cloîtrer","pos":"VER"} ,
		{"word":"cloîtrée","word_nosc":"cloitree","lemma":"cloîtrer","pos":"VER"} ,
		{"word":"cloîtrées","word_nosc":"cloitrees","lemma":"cloîtrer","pos":"VER"} ,
		{"word":"cloîtrés","word_nosc":"cloitres","lemma":"cloîtrer","pos":"VER"} ,
		{"word":"clôt","word_nosc":"clot","lemma":"clore","pos":"VER"} ,
		{"word":"clôtura","word_nosc":"clotura","lemma":"clôturer","pos":"VER"} ,
		{"word":"clôturai","word_nosc":"cloturai","lemma":"clôturer","pos":"VER"} ,
		{"word":"clôturaient","word_nosc":"cloturaient","lemma":"clôturer","pos":"VER"} ,
		{"word":"clôturait","word_nosc":"cloturait","lemma":"clôturer","pos":"VER"} ,
		{"word":"clôture","word_nosc":"cloture","lemma":"clôturer","pos":"VER"} ,
		{"word":"clôturent","word_nosc":"cloturent","lemma":"clôturer","pos":"VER"} ,
		{"word":"clôturer","word_nosc":"cloturer","lemma":"clôturer","pos":"VER"} ,
		{"word":"clôturera","word_nosc":"cloturera","lemma":"clôturer","pos":"VER"} ,
		{"word":"clôturé","word_nosc":"cloture","lemma":"clôturer","pos":"VER"} ,
		{"word":"clôturée","word_nosc":"cloturee","lemma":"clôturer","pos":"VER"} ,
		{"word":"co-exister","word_nosc":"co-exister","lemma":"co-exister","pos":"VER"} ,
		{"word":"co-habiter","word_nosc":"co-habiter","lemma":"co-habiter","pos":"VER"} ,
		{"word":"co-signer","word_nosc":"co-signer","lemma":"co-signer","pos":"VER"} ,
		{"word":"co-signons","word_nosc":"co-signons","lemma":"cosigner","pos":"VER"} ,
		{"word":"coache","word_nosc":"coache","lemma":"coacher","pos":"VER"} ,
		{"word":"coacher","word_nosc":"coacher","lemma":"coacher","pos":"VER"} ,
		{"word":"coaché","word_nosc":"coache","lemma":"coacher","pos":"VER"} ,
		{"word":"coagula","word_nosc":"coagula","lemma":"coaguler","pos":"VER"} ,
		{"word":"coagulaient","word_nosc":"coagulaient","lemma":"coaguler","pos":"VER"} ,
		{"word":"coagulait","word_nosc":"coagulait","lemma":"coaguler","pos":"VER"} ,
		{"word":"coagulant","word_nosc":"coagulant","lemma":"coaguler","pos":"VER"} ,
		{"word":"coagule","word_nosc":"coagule","lemma":"coaguler","pos":"VER"} ,
		{"word":"coagulent","word_nosc":"coagulent","lemma":"coaguler","pos":"VER"} ,
		{"word":"coaguler","word_nosc":"coaguler","lemma":"coaguler","pos":"VER"} ,
		{"word":"coagulera","word_nosc":"coagulera","lemma":"coaguler","pos":"VER"} ,
		{"word":"coagulât","word_nosc":"coagulat","lemma":"coaguler","pos":"VER"} ,
		{"word":"coagulé","word_nosc":"coagule","lemma":"coaguler","pos":"VER"} ,
		{"word":"coagulée","word_nosc":"coagulee","lemma":"coaguler","pos":"VER"} ,
		{"word":"coagulées","word_nosc":"coagulees","lemma":"coaguler","pos":"VER"} ,
		{"word":"coagulés","word_nosc":"coagules","lemma":"coaguler","pos":"VER"} ,
		{"word":"coalisaient","word_nosc":"coalisaient","lemma":"coaliser","pos":"VER"} ,
		{"word":"coalisent","word_nosc":"coalisent","lemma":"coaliser","pos":"VER"} ,
		{"word":"coaliser","word_nosc":"coaliser","lemma":"coaliser","pos":"VER"} ,
		{"word":"coalisée","word_nosc":"coalisee","lemma":"coaliser","pos":"VER"} ,
		{"word":"coalisés","word_nosc":"coalises","lemma":"coaliser","pos":"VER"} ,
		{"word":"coassaient","word_nosc":"coassaient","lemma":"coasser","pos":"VER"} ,
		{"word":"coassant","word_nosc":"coassant","lemma":"coasser","pos":"VER"} ,
		{"word":"coasse","word_nosc":"coasse","lemma":"coasser","pos":"VER"} ,
		{"word":"coassent","word_nosc":"coassent","lemma":"coasser","pos":"VER"} ,
		{"word":"coasser","word_nosc":"coasser","lemma":"coasser","pos":"VER"} ,
		{"word":"cocardasse","word_nosc":"cocardasse","lemma":"cocarder","pos":"VER"} ,
		{"word":"cocarder","word_nosc":"cocarder","lemma":"cocarder","pos":"VER"} ,
		{"word":"cocardés","word_nosc":"cocardes","lemma":"cocarder","pos":"VER"} ,
		{"word":"cocha","word_nosc":"cocha","lemma":"cocher","pos":"VER"} ,
		{"word":"cochait","word_nosc":"cochait","lemma":"cocher","pos":"VER"} ,
		{"word":"cochant","word_nosc":"cochant","lemma":"cocher","pos":"VER"} ,
		{"word":"coche","word_nosc":"coche","lemma":"cocher","pos":"VER"} ,
		{"word":"cocher","word_nosc":"cocher","lemma":"cocher","pos":"VER"} ,
		{"word":"cocheras","word_nosc":"cocheras","lemma":"cocher","pos":"VER"} ,
		{"word":"coches","word_nosc":"coches","lemma":"cocher","pos":"VER"} ,
		{"word":"cochez","word_nosc":"cochez","lemma":"cocher","pos":"VER"} ,
		{"word":"cochonner","word_nosc":"cochonner","lemma":"cochonner","pos":"VER"} ,
		{"word":"cochonnez","word_nosc":"cochonnez","lemma":"cochonner","pos":"VER"} ,
		{"word":"cochonné","word_nosc":"cochonne","lemma":"cochonner","pos":"VER"} ,
		{"word":"cochonnées","word_nosc":"cochonnees","lemma":"cochonner","pos":"VER"} ,
		{"word":"cochât","word_nosc":"cochat","lemma":"cocher","pos":"VER"} ,
		{"word":"coché","word_nosc":"coche","lemma":"cocher","pos":"VER"} ,
		{"word":"cochée","word_nosc":"cochee","lemma":"cocher","pos":"VER"} ,
		{"word":"cochées","word_nosc":"cochees","lemma":"cocher","pos":"VER"} ,
		{"word":"cochés","word_nosc":"coches","lemma":"cocher","pos":"VER"} ,
		{"word":"cocolait","word_nosc":"cocolait","lemma":"cocoler","pos":"VER"} ,
		{"word":"cocole","word_nosc":"cocole","lemma":"cocoler","pos":"VER"} ,
		{"word":"cocotent","word_nosc":"cocotent","lemma":"cocoter","pos":"VER"} ,
		{"word":"cocoter","word_nosc":"cocoter","lemma":"cocoter","pos":"VER"} ,
		{"word":"cocoterait","word_nosc":"cocoterait","lemma":"cocoter","pos":"VER"} ,
		{"word":"cocotte","word_nosc":"cocotte","lemma":"cocotter","pos":"VER"} ,
		{"word":"cocufiant","word_nosc":"cocufiant","lemma":"cocufier","pos":"VER"} ,
		{"word":"cocufie","word_nosc":"cocufie","lemma":"cocufier","pos":"VER"} ,
		{"word":"cocufier","word_nosc":"cocufier","lemma":"cocufier","pos":"VER"} ,
		{"word":"cocufiera","word_nosc":"cocufiera","lemma":"cocufier","pos":"VER"} ,
		{"word":"cocufié","word_nosc":"cocufie","lemma":"cocufier","pos":"VER"} ,
		{"word":"codait","word_nosc":"codait","lemma":"coder","pos":"VER"} ,
		{"word":"code","word_nosc":"code","lemma":"coder","pos":"VER"} ,
		{"word":"coder","word_nosc":"coder","lemma":"coder","pos":"VER"} ,
		{"word":"codes","word_nosc":"codes","lemma":"coder","pos":"VER"} ,
		{"word":"codifia","word_nosc":"codifia","lemma":"codifier","pos":"VER"} ,
		{"word":"codifiait","word_nosc":"codifiait","lemma":"codifier","pos":"VER"} ,
		{"word":"codifiant","word_nosc":"codifiant","lemma":"codifier","pos":"VER"} ,
		{"word":"codifier","word_nosc":"codifier","lemma":"codifier","pos":"VER"} ,
		{"word":"codifièrent","word_nosc":"codifierent","lemma":"codifier","pos":"VER"} ,
		{"word":"codifié","word_nosc":"codifie","lemma":"codifier","pos":"VER"} ,
		{"word":"codifiée","word_nosc":"codifiee","lemma":"codifier","pos":"VER"} ,
		{"word":"codifiées","word_nosc":"codifiees","lemma":"codifier","pos":"VER"} ,
		{"word":"codé","word_nosc":"code","lemma":"coder","pos":"VER"} ,
		{"word":"codée","word_nosc":"codee","lemma":"coder","pos":"VER"} ,
		{"word":"codées","word_nosc":"codees","lemma":"coder","pos":"VER"} ,
		{"word":"codés","word_nosc":"codes","lemma":"coder","pos":"VER"} ,
		{"word":"coexistaient","word_nosc":"coexistaient","lemma":"coexister","pos":"VER"} ,
		{"word":"coexistait","word_nosc":"coexistait","lemma":"coexister","pos":"VER"} ,
		{"word":"coexistant","word_nosc":"coexistant","lemma":"coexister","pos":"VER"} ,
		{"word":"coexiste","word_nosc":"coexiste","lemma":"coexister","pos":"VER"} ,
		{"word":"coexistent","word_nosc":"coexistent","lemma":"coexister","pos":"VER"} ,
		{"word":"coexister","word_nosc":"coexister","lemma":"coexister","pos":"VER"} ,
		{"word":"coexisteront","word_nosc":"coexisteront","lemma":"coexister","pos":"VER"} ,
		{"word":"coexistez","word_nosc":"coexistez","lemma":"coexister","pos":"VER"} ,
		{"word":"coexistons","word_nosc":"coexistons","lemma":"coexister","pos":"VER"} ,
		{"word":"coexisté","word_nosc":"coexiste","lemma":"coexister","pos":"VER"} ,
		{"word":"coffraient","word_nosc":"coffraient","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffrait","word_nosc":"coffrait","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffre","word_nosc":"coffre","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffrent","word_nosc":"coffrent","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffrer","word_nosc":"coffrer","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffrera","word_nosc":"coffrera","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffrerai","word_nosc":"coffrerai","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffrerais","word_nosc":"coffrerais","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffrerons","word_nosc":"coffrerons","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffreront","word_nosc":"coffreront","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffres","word_nosc":"coffres","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffrez","word_nosc":"coffrez","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffrons","word_nosc":"coffrons","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffré","word_nosc":"coffre","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffrée","word_nosc":"coffree","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffrées","word_nosc":"coffrees","lemma":"coffrer","pos":"VER"} ,
		{"word":"coffrés","word_nosc":"coffres","lemma":"coffrer","pos":"VER"} ,
		{"word":"cogitais","word_nosc":"cogitais","lemma":"cogiter","pos":"VER"} ,
		{"word":"cogitait","word_nosc":"cogitait","lemma":"cogiter","pos":"VER"} ,
		{"word":"cogitant","word_nosc":"cogitant","lemma":"cogiter","pos":"VER"} ,
		{"word":"cogite","word_nosc":"cogite","lemma":"cogiter","pos":"VER"} ,
		{"word":"cogitent","word_nosc":"cogitent","lemma":"cogiter","pos":"VER"} ,
		{"word":"cogiter","word_nosc":"cogiter","lemma":"cogiter","pos":"VER"} ,
		{"word":"cogité","word_nosc":"cogite","lemma":"cogiter","pos":"VER"} ,
		{"word":"cogna","word_nosc":"cogna","lemma":"cogner","pos":"VER"} ,
		{"word":"cognai","word_nosc":"cognai","lemma":"cogner","pos":"VER"} ,
		{"word":"cognaient","word_nosc":"cognaient","lemma":"cogner","pos":"VER"} ,
		{"word":"cognais","word_nosc":"cognais","lemma":"cogner","pos":"VER"} ,
		{"word":"cognait","word_nosc":"cognait","lemma":"cogner","pos":"VER"} ,
		{"word":"cognant","word_nosc":"cognant","lemma":"cogner","pos":"VER"} ,
		{"word":"cogne","word_nosc":"cogne","lemma":"cogner","pos":"VER"} ,
		{"word":"cognent","word_nosc":"cognent","lemma":"cogner","pos":"VER"} ,
		{"word":"cogner","word_nosc":"cogner","lemma":"cogner","pos":"VER"} ,
		{"word":"cognera","word_nosc":"cognera","lemma":"cogner","pos":"VER"} ,
		{"word":"cognerai","word_nosc":"cognerai","lemma":"cogner","pos":"VER"} ,
		{"word":"cogneraient","word_nosc":"cogneraient","lemma":"cogner","pos":"VER"} ,
		{"word":"cognerais","word_nosc":"cognerais","lemma":"cogner","pos":"VER"} ,
		{"word":"cognerait","word_nosc":"cognerait","lemma":"cogner","pos":"VER"} ,
		{"word":"cognerez","word_nosc":"cognerez","lemma":"cogner","pos":"VER"} ,
		{"word":"cogneront","word_nosc":"cogneront","lemma":"cogner","pos":"VER"} ,
		{"word":"cognes","word_nosc":"cognes","lemma":"cogner","pos":"VER"} ,
		{"word":"cognez","word_nosc":"cognez","lemma":"cogner","pos":"VER"} ,
		{"word":"cognons","word_nosc":"cognons","lemma":"cogner","pos":"VER"} ,
		{"word":"cognèrent","word_nosc":"cognerent","lemma":"cogner","pos":"VER"} ,
		{"word":"cogné","word_nosc":"cogne","lemma":"cogner","pos":"VER"} ,
		{"word":"cognée","word_nosc":"cognee","lemma":"cogner","pos":"VER"} ,
		{"word":"cognées","word_nosc":"cognees","lemma":"cogner","pos":"VER"} ,
		{"word":"cognés","word_nosc":"cognes","lemma":"cogner","pos":"VER"} ,
		{"word":"cohabita","word_nosc":"cohabita","lemma":"cohabiter","pos":"VER"} ,
		{"word":"cohabitaient","word_nosc":"cohabitaient","lemma":"cohabiter","pos":"VER"} ,
		{"word":"cohabitait","word_nosc":"cohabitait","lemma":"cohabiter","pos":"VER"} ,
		{"word":"cohabite","word_nosc":"cohabite","lemma":"cohabiter","pos":"VER"} ,
		{"word":"cohabitent","word_nosc":"cohabitent","lemma":"cohabiter","pos":"VER"} ,
		{"word":"cohabiter","word_nosc":"cohabiter","lemma":"cohabiter","pos":"VER"} ,
		{"word":"cohabiteraient","word_nosc":"cohabiteraient","lemma":"cohabiter","pos":"VER"} ,
		{"word":"cohabitiez","word_nosc":"cohabitiez","lemma":"cohabiter","pos":"VER"} ,
		{"word":"cohabité","word_nosc":"cohabite","lemma":"cohabiter","pos":"VER"} ,
		{"word":"coiffa","word_nosc":"coiffa","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffai","word_nosc":"coiffai","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffaient","word_nosc":"coiffaient","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffais","word_nosc":"coiffais","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffait","word_nosc":"coiffait","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffant","word_nosc":"coiffant","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffe","word_nosc":"coiffe","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffent","word_nosc":"coiffent","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffer","word_nosc":"coiffer","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffera","word_nosc":"coiffera","lemma":"coiffer","pos":"VER"} ,
		{"word":"coifferais","word_nosc":"coifferais","lemma":"coiffer","pos":"VER"} ,
		{"word":"coifferait","word_nosc":"coifferait","lemma":"coiffer","pos":"VER"} ,
		{"word":"coifferas","word_nosc":"coifferas","lemma":"coiffer","pos":"VER"} ,
		{"word":"coifferont","word_nosc":"coifferont","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffes","word_nosc":"coiffes","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffez","word_nosc":"coiffez","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffiez","word_nosc":"coiffiez","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffât","word_nosc":"coiffat","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffèrent","word_nosc":"coifferent","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffé","word_nosc":"coiffe","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffée","word_nosc":"coiffee","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffées","word_nosc":"coiffees","lemma":"coiffer","pos":"VER"} ,
		{"word":"coiffés","word_nosc":"coiffes","lemma":"coiffer","pos":"VER"} ,
		{"word":"coince","word_nosc":"coince","lemma":"coincer","pos":"VER"} ,
		{"word":"coincent","word_nosc":"coincent","lemma":"coincer","pos":"VER"} ,
		{"word":"coincer","word_nosc":"coincer","lemma":"coincer","pos":"VER"} ,
		{"word":"coincera","word_nosc":"coincera","lemma":"coincer","pos":"VER"} ,
		{"word":"coincerai","word_nosc":"coincerai","lemma":"coincer","pos":"VER"} ,
		{"word":"coincerais","word_nosc":"coincerais","lemma":"coincer","pos":"VER"} ,
		{"word":"coincerait","word_nosc":"coincerait","lemma":"coincer","pos":"VER"} ,
		{"word":"coinceriez","word_nosc":"coinceriez","lemma":"coincer","pos":"VER"} ,
		{"word":"coincerons","word_nosc":"coincerons","lemma":"coincer","pos":"VER"} ,
		{"word":"coinceront","word_nosc":"coinceront","lemma":"coincer","pos":"VER"} ,
		{"word":"coinces","word_nosc":"coinces","lemma":"coincer","pos":"VER"} ,
		{"word":"coincez","word_nosc":"coincez","lemma":"coincer","pos":"VER"} ,
		{"word":"coincher","word_nosc":"coincher","lemma":"coincher","pos":"VER"} ,
		{"word":"coinciez","word_nosc":"coinciez","lemma":"coincer","pos":"VER"} ,
		{"word":"coincèrent","word_nosc":"coincerent","lemma":"coincer","pos":"VER"} ,
		{"word":"coincé","word_nosc":"coince","lemma":"coincer","pos":"VER"} ,
		{"word":"coincée","word_nosc":"coincee","lemma":"coincer","pos":"VER"} ,
		{"word":"coincées","word_nosc":"coincees","lemma":"coincer","pos":"VER"} ,
		{"word":"coincés","word_nosc":"coinces","lemma":"coincer","pos":"VER"} ,
		{"word":"coinça","word_nosc":"coinca","lemma":"coincer","pos":"VER"} ,
		{"word":"coinçaient","word_nosc":"coincaient","lemma":"coincer","pos":"VER"} ,
		{"word":"coinçais","word_nosc":"coincais","lemma":"coincer","pos":"VER"} ,
		{"word":"coinçait","word_nosc":"coincait","lemma":"coincer","pos":"VER"} ,
		{"word":"coinçant","word_nosc":"coincant","lemma":"coincer","pos":"VER"} ,
		{"word":"coinçons","word_nosc":"coincons","lemma":"coincer","pos":"VER"} ,
		{"word":"colla","word_nosc":"colla","lemma":"coller","pos":"VER"} ,
		{"word":"collabora","word_nosc":"collabora","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaborai","word_nosc":"collaborai","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaboraient","word_nosc":"collaboraient","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaborais","word_nosc":"collaborais","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaborait","word_nosc":"collaborait","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaborant","word_nosc":"collaborant","lemma":"collaborer","pos":"VER"} ,
		{"word":"collabore","word_nosc":"collabore","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaborent","word_nosc":"collaborent","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaborer","word_nosc":"collaborer","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaborera","word_nosc":"collaborera","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaborerai","word_nosc":"collaborerai","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaboreraient","word_nosc":"collaboreraient","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaborerez","word_nosc":"collaborerez","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaborerons","word_nosc":"collaborerons","lemma":"collaborer","pos":"VER"} ,
		{"word":"collabores","word_nosc":"collabores","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaborez","word_nosc":"collaborez","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaborions","word_nosc":"collaborions","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaborons","word_nosc":"collaborons","lemma":"collaborer","pos":"VER"} ,
		{"word":"collaboré","word_nosc":"collabore","lemma":"collaborer","pos":"VER"} ,
		{"word":"collai","word_nosc":"collai","lemma":"coller","pos":"VER"} ,
		{"word":"collaient","word_nosc":"collaient","lemma":"coller","pos":"VER"} ,
		{"word":"collais","word_nosc":"collais","lemma":"coller","pos":"VER"} ,
		{"word":"collait","word_nosc":"collait","lemma":"coller","pos":"VER"} ,
		{"word":"collant","word_nosc":"collant","lemma":"coller","pos":"VER"} ,
		{"word":"collapse","word_nosc":"collapse","lemma":"collapser","pos":"VER"} ,
		{"word":"collapserait","word_nosc":"collapserait","lemma":"collapser","pos":"VER"} ,
		{"word":"collas","word_nosc":"collas","lemma":"coller","pos":"VER"} ,
		{"word":"collationne","word_nosc":"collationne","lemma":"collationner","pos":"VER"} ,
		{"word":"collationner","word_nosc":"collationner","lemma":"collationner","pos":"VER"} ,
		{"word":"collationnés","word_nosc":"collationnes","lemma":"collationner","pos":"VER"} ,
		{"word":"colle","word_nosc":"colle","lemma":"coller","pos":"VER"} ,
		{"word":"collectait","word_nosc":"collectait","lemma":"collecter","pos":"VER"} ,
		{"word":"collectant","word_nosc":"collectant","lemma":"collecter","pos":"VER"} ,
		{"word":"collecte","word_nosc":"collecte","lemma":"collecter","pos":"VER"} ,
		{"word":"collecter","word_nosc":"collecter","lemma":"collecter","pos":"VER"} ,
		{"word":"collectez","word_nosc":"collectez","lemma":"collecter","pos":"VER"} ,
		{"word":"collectionna","word_nosc":"collectionna","lemma":"collectionner","pos":"VER"} ,
		{"word":"collectionnaient","word_nosc":"collectionnaient","lemma":"collectionner","pos":"VER"} ,
		{"word":"collectionnais","word_nosc":"collectionnais","lemma":"collectionner","pos":"VER"} ,
		{"word":"collectionnait","word_nosc":"collectionnait","lemma":"collectionner","pos":"VER"} ,
		{"word":"collectionnant","word_nosc":"collectionnant","lemma":"collectionner","pos":"VER"} ,
		{"word":"collectionne","word_nosc":"collectionne","lemma":"collectionner","pos":"VER"} ,
		{"word":"collectionnent","word_nosc":"collectionnent","lemma":"collectionner","pos":"VER"} ,
		{"word":"collectionner","word_nosc":"collectionner","lemma":"collectionner","pos":"VER"} ,
		{"word":"collectionnes","word_nosc":"collectionnes","lemma":"collectionner","pos":"VER"} ,
		{"word":"collectionnez","word_nosc":"collectionnez","lemma":"collectionner","pos":"VER"} ,
		{"word":"collectionnions","word_nosc":"collectionnions","lemma":"collectionner","pos":"VER"} ,
		{"word":"collectionnons","word_nosc":"collectionnons","lemma":"collectionner","pos":"VER"} ,
		{"word":"collectionné","word_nosc":"collectionne","lemma":"collectionner","pos":"VER"} ,
		{"word":"collectionnées","word_nosc":"collectionnees","lemma":"collectionner","pos":"VER"} ,
		{"word":"collectionnés","word_nosc":"collectionnes","lemma":"collectionner","pos":"VER"} ,
		{"word":"collectiviser","word_nosc":"collectiviser","lemma":"collectiviser","pos":"VER"} ,
		{"word":"collectivisé","word_nosc":"collectivise","lemma":"collectiviser","pos":"VER"} ,
		{"word":"collectivisée","word_nosc":"collectivisee","lemma":"collectiviser","pos":"VER"} ,
		{"word":"collectivisés","word_nosc":"collectivises","lemma":"collectiviser","pos":"VER"} ,
		{"word":"collectons","word_nosc":"collectons","lemma":"collecter","pos":"VER"} ,
		{"word":"collecté","word_nosc":"collecte","lemma":"collecter","pos":"VER"} ,
		{"word":"collectée","word_nosc":"collectee","lemma":"collecter","pos":"VER"} ,
		{"word":"collectées","word_nosc":"collectees","lemma":"collecter","pos":"VER"} ,
		{"word":"collectés","word_nosc":"collectes","lemma":"collecter","pos":"VER"} ,
		{"word":"collent","word_nosc":"collent","lemma":"coller","pos":"VER"} ,
		{"word":"coller","word_nosc":"coller","lemma":"coller","pos":"VER"} ,
		{"word":"collera","word_nosc":"collera","lemma":"coller","pos":"VER"} ,
		{"word":"collerai","word_nosc":"collerai","lemma":"coller","pos":"VER"} ,
		{"word":"collerais","word_nosc":"collerais","lemma":"coller","pos":"VER"} ,
		{"word":"collerait","word_nosc":"collerait","lemma":"coller","pos":"VER"} ,
		{"word":"colleras","word_nosc":"colleras","lemma":"coller","pos":"VER"} ,
		{"word":"collerez","word_nosc":"collerez","lemma":"coller","pos":"VER"} ,
		{"word":"colleront","word_nosc":"colleront","lemma":"coller","pos":"VER"} ,
		{"word":"colles","word_nosc":"colles","lemma":"coller","pos":"VER"} ,
		{"word":"colleter","word_nosc":"colleter","lemma":"colleter","pos":"VER"} ,
		{"word":"collette","word_nosc":"collette","lemma":"colleter","pos":"VER"} ,
		{"word":"colleté","word_nosc":"collete","lemma":"colleter","pos":"VER"} ,
		{"word":"colletés","word_nosc":"colletes","lemma":"colleter","pos":"VER"} ,
		{"word":"collez","word_nosc":"collez","lemma":"coller","pos":"VER"} ,
		{"word":"colliez","word_nosc":"colliez","lemma":"coller","pos":"VER"} ,
		{"word":"collions","word_nosc":"collions","lemma":"coller","pos":"VER"} ,
		{"word":"collons","word_nosc":"collons","lemma":"coller","pos":"VER"} ,
		{"word":"colloquant","word_nosc":"colloquant","lemma":"colloquer","pos":"VER"} ,
		{"word":"colloquer","word_nosc":"colloquer","lemma":"colloquer","pos":"VER"} ,
		{"word":"colloqué","word_nosc":"colloque","lemma":"colloquer","pos":"VER"} ,
		{"word":"colloquée","word_nosc":"colloquee","lemma":"colloquer","pos":"VER"} ,
		{"word":"collèrent","word_nosc":"collerent","lemma":"coller","pos":"VER"} ,
		{"word":"collé","word_nosc":"colle","lemma":"coller","pos":"VER"} ,
		{"word":"collée","word_nosc":"collee","lemma":"coller","pos":"VER"} ,
		{"word":"collées","word_nosc":"collees","lemma":"coller","pos":"VER"} ,
		{"word":"collés","word_nosc":"colles","lemma":"coller","pos":"VER"} ,
		{"word":"colmataient","word_nosc":"colmataient","lemma":"colmater","pos":"VER"} ,
		{"word":"colmatais","word_nosc":"colmatais","lemma":"colmater","pos":"VER"} ,
		{"word":"colmatait","word_nosc":"colmatait","lemma":"colmater","pos":"VER"} ,
		{"word":"colmatant","word_nosc":"colmatant","lemma":"colmater","pos":"VER"} ,
		{"word":"colmate","word_nosc":"colmate","lemma":"colmater","pos":"VER"} ,
		{"word":"colmater","word_nosc":"colmater","lemma":"colmater","pos":"VER"} ,
		{"word":"colmatez","word_nosc":"colmatez","lemma":"colmater","pos":"VER"} ,
		{"word":"colmatons","word_nosc":"colmatons","lemma":"colmater","pos":"VER"} ,
		{"word":"colmaté","word_nosc":"colmate","lemma":"colmater","pos":"VER"} ,
		{"word":"colmatée","word_nosc":"colmatee","lemma":"colmater","pos":"VER"} ,
		{"word":"colmatées","word_nosc":"colmatees","lemma":"colmater","pos":"VER"} ,
		{"word":"colmatés","word_nosc":"colmates","lemma":"colmater","pos":"VER"} ,
		{"word":"colonisait","word_nosc":"colonisait","lemma":"coloniser","pos":"VER"} ,
		{"word":"colonise","word_nosc":"colonise","lemma":"coloniser","pos":"VER"} ,
		{"word":"colonisent","word_nosc":"colonisent","lemma":"coloniser","pos":"VER"} ,
		{"word":"coloniser","word_nosc":"coloniser","lemma":"coloniser","pos":"VER"} ,
		{"word":"coloniserait","word_nosc":"coloniserait","lemma":"coloniser","pos":"VER"} ,
		{"word":"colonisez","word_nosc":"colonisez","lemma":"coloniser","pos":"VER"} ,
		{"word":"colonisons","word_nosc":"colonisons","lemma":"coloniser","pos":"VER"} ,
		{"word":"colonisèrent","word_nosc":"coloniserent","lemma":"coloniser","pos":"VER"} ,
		{"word":"colonisé","word_nosc":"colonise","lemma":"coloniser","pos":"VER"} ,
		{"word":"colonisée","word_nosc":"colonisee","lemma":"coloniser","pos":"VER"} ,
		{"word":"colonisées","word_nosc":"colonisees","lemma":"coloniser","pos":"VER"} ,
		{"word":"colonisés","word_nosc":"colonises","lemma":"coloniser","pos":"VER"} ,
		{"word":"colophané","word_nosc":"colophane","lemma":"colophaner","pos":"VER"} ,
		{"word":"colora","word_nosc":"colora","lemma":"colorer","pos":"VER"} ,
		{"word":"colorai","word_nosc":"colorai","lemma":"colorer","pos":"VER"} ,
		{"word":"coloraient","word_nosc":"coloraient","lemma":"colorer","pos":"VER"} ,
		{"word":"colorait","word_nosc":"colorait","lemma":"colorer","pos":"VER"} ,
		{"word":"colorant","word_nosc":"colorant","lemma":"colorer","pos":"VER"} ,
		{"word":"colore","word_nosc":"colore","lemma":"colorer","pos":"VER"} ,
		{"word":"colorent","word_nosc":"colorent","lemma":"colorer","pos":"VER"} ,
		{"word":"colorer","word_nosc":"colorer","lemma":"colorer","pos":"VER"} ,
		{"word":"colorez","word_nosc":"colorez","lemma":"colorer","pos":"VER"} ,
		{"word":"coloriaient","word_nosc":"coloriaient","lemma":"colorier","pos":"VER"} ,
		{"word":"coloriait","word_nosc":"coloriait","lemma":"colorier","pos":"VER"} ,
		{"word":"colorie","word_nosc":"colorie","lemma":"colorier","pos":"VER"} ,
		{"word":"colorient","word_nosc":"colorient","lemma":"colorier","pos":"VER"} ,
		{"word":"colorier","word_nosc":"colorier","lemma":"colorier","pos":"VER"} ,
		{"word":"coloriez","word_nosc":"coloriez","lemma":"colorier","pos":"VER"} ,
		{"word":"colorisé","word_nosc":"colorise","lemma":"coloriser","pos":"VER"} ,
		{"word":"colorié","word_nosc":"colorie","lemma":"colorier","pos":"VER"} ,
		{"word":"coloriée","word_nosc":"coloriee","lemma":"colorier","pos":"VER"} ,
		{"word":"coloriées","word_nosc":"coloriees","lemma":"colorier","pos":"VER"} ,
		{"word":"coloriés","word_nosc":"colories","lemma":"colorier","pos":"VER"} ,
		{"word":"colorèrent","word_nosc":"colorerent","lemma":"colorer","pos":"VER"} ,
		{"word":"coloré","word_nosc":"colore","lemma":"colorer","pos":"VER"} ,
		{"word":"colorée","word_nosc":"coloree","lemma":"colorer","pos":"VER"} ,
		{"word":"colorées","word_nosc":"colorees","lemma":"colorer","pos":"VER"} ,
		{"word":"colorés","word_nosc":"colores","lemma":"colorer","pos":"VER"} ,
		{"word":"colporta","word_nosc":"colporta","lemma":"colporter","pos":"VER"} ,
		{"word":"colportaient","word_nosc":"colportaient","lemma":"colporter","pos":"VER"} ,
		{"word":"colportait","word_nosc":"colportait","lemma":"colporter","pos":"VER"} ,
		{"word":"colportant","word_nosc":"colportant","lemma":"colporter","pos":"VER"} ,
		{"word":"colporte","word_nosc":"colporte","lemma":"colporter","pos":"VER"} ,
		{"word":"colportent","word_nosc":"colportent","lemma":"colporter","pos":"VER"} ,
		{"word":"colporter","word_nosc":"colporter","lemma":"colporter","pos":"VER"} ,
		{"word":"colportera","word_nosc":"colportera","lemma":"colporter","pos":"VER"} ,
		{"word":"colportes","word_nosc":"colportes","lemma":"colporter","pos":"VER"} ,
		{"word":"colportons","word_nosc":"colportons","lemma":"colporter","pos":"VER"} ,
		{"word":"colporté","word_nosc":"colporte","lemma":"colporter","pos":"VER"} ,
		{"word":"colportée","word_nosc":"colportee","lemma":"colporter","pos":"VER"} ,
		{"word":"colportées","word_nosc":"colportees","lemma":"colporter","pos":"VER"} ,
		{"word":"coltina","word_nosc":"coltina","lemma":"coltiner","pos":"VER"} ,
		{"word":"coltinais","word_nosc":"coltinais","lemma":"coltiner","pos":"VER"} ,
		{"word":"coltinait","word_nosc":"coltinait","lemma":"coltiner","pos":"VER"} ,
		{"word":"coltinant","word_nosc":"coltinant","lemma":"coltiner","pos":"VER"} ,
		{"word":"coltine","word_nosc":"coltine","lemma":"coltiner","pos":"VER"} ,
		{"word":"coltinent","word_nosc":"coltinent","lemma":"coltiner","pos":"VER"} ,
		{"word":"coltiner","word_nosc":"coltiner","lemma":"coltiner","pos":"VER"} ,
		{"word":"coltines","word_nosc":"coltines","lemma":"coltiner","pos":"VER"} ,
		{"word":"coltinez","word_nosc":"coltinez","lemma":"coltiner","pos":"VER"} ,
		{"word":"coltiné","word_nosc":"coltine","lemma":"coltiner","pos":"VER"} ,
		{"word":"coltinée","word_nosc":"coltinee","lemma":"coltiner","pos":"VER"} ,
		{"word":"coltinés","word_nosc":"coltines","lemma":"coltiner","pos":"VER"} ,
		{"word":"colérer","word_nosc":"colerer","lemma":"colérer","pos":"VER"} ,
		{"word":"combat","word_nosc":"combat","lemma":"combattre","pos":"VER"} ,
		{"word":"combats","word_nosc":"combats","lemma":"combattre","pos":"VER"} ,
		{"word":"combattaient","word_nosc":"combattaient","lemma":"combattre","pos":"VER"} ,
		{"word":"combattais","word_nosc":"combattais","lemma":"combattre","pos":"VER"} ,
		{"word":"combattait","word_nosc":"combattait","lemma":"combattre","pos":"VER"} ,
		{"word":"combattant","word_nosc":"combattant","lemma":"combattre","pos":"VER"} ,
		{"word":"combatte","word_nosc":"combatte","lemma":"combattre","pos":"VER"} ,
		{"word":"combattent","word_nosc":"combattent","lemma":"combattre","pos":"VER"} ,
		{"word":"combattes","word_nosc":"combattes","lemma":"combattre","pos":"VER"} ,
		{"word":"combattez","word_nosc":"combattez","lemma":"combattre","pos":"VER"} ,
		{"word":"combattiez","word_nosc":"combattiez","lemma":"combattre","pos":"VER"} ,
		{"word":"combattions","word_nosc":"combattions","lemma":"combattre","pos":"VER"} ,
		{"word":"combattirent","word_nosc":"combattirent","lemma":"combattre","pos":"VER"} ,
		{"word":"combattit","word_nosc":"combattit","lemma":"combattre","pos":"VER"} ,
		{"word":"combattons","word_nosc":"combattons","lemma":"combattre","pos":"VER"} ,
		{"word":"combattra","word_nosc":"combattra","lemma":"combattre","pos":"VER"} ,
		{"word":"combattrai","word_nosc":"combattrai","lemma":"combattre","pos":"VER"} ,
		{"word":"combattraient","word_nosc":"combattraient","lemma":"combattre","pos":"VER"} ,
		{"word":"combattrais","word_nosc":"combattrais","lemma":"combattre","pos":"VER"} ,
		{"word":"combattrait","word_nosc":"combattrait","lemma":"combattre","pos":"VER"} ,
		{"word":"combattras","word_nosc":"combattras","lemma":"combattre","pos":"VER"} ,
		{"word":"combattre","word_nosc":"combattre","lemma":"combattre","pos":"VER"} ,
		{"word":"combattrez","word_nosc":"combattrez","lemma":"combattre","pos":"VER"} ,
		{"word":"combattriez","word_nosc":"combattriez","lemma":"combattre","pos":"VER"} ,
		{"word":"combattrons","word_nosc":"combattrons","lemma":"combattre","pos":"VER"} ,
		{"word":"combattront","word_nosc":"combattront","lemma":"combattre","pos":"VER"} ,
		{"word":"combattu","word_nosc":"combattu","lemma":"combattre","pos":"VER"} ,
		{"word":"combattue","word_nosc":"combattue","lemma":"combattre","pos":"VER"} ,
		{"word":"combattues","word_nosc":"combattues","lemma":"combattre","pos":"VER"} ,
		{"word":"combattus","word_nosc":"combattus","lemma":"combattre","pos":"VER"} ,
		{"word":"combattît","word_nosc":"combattit","lemma":"combattre","pos":"VER"} ,
		{"word":"combina","word_nosc":"combina","lemma":"combiner","pos":"VER"} ,
		{"word":"combinaient","word_nosc":"combinaient","lemma":"combiner","pos":"VER"} ,
		{"word":"combinait","word_nosc":"combinait","lemma":"combiner","pos":"VER"} ,
		{"word":"combinant","word_nosc":"combinant","lemma":"combiner","pos":"VER"} ,
		{"word":"combine","word_nosc":"combine","lemma":"combiner","pos":"VER"} ,
		{"word":"combinent","word_nosc":"combinent","lemma":"combiner","pos":"VER"} ,
		{"word":"combiner","word_nosc":"combiner","lemma":"combiner","pos":"VER"} ,
		{"word":"combineraient","word_nosc":"combineraient","lemma":"combiner","pos":"VER"} ,
		{"word":"combinerait","word_nosc":"combinerait","lemma":"combiner","pos":"VER"} ,
		{"word":"combinerons","word_nosc":"combinerons","lemma":"combiner","pos":"VER"} ,
		{"word":"combines","word_nosc":"combines","lemma":"combiner","pos":"VER"} ,
		{"word":"combinez","word_nosc":"combinez","lemma":"combiner","pos":"VER"} ,
		{"word":"combinons","word_nosc":"combinons","lemma":"combiner","pos":"VER"} ,
		{"word":"combiné","word_nosc":"combine","lemma":"combiner","pos":"VER"} ,
		{"word":"combinée","word_nosc":"combinee","lemma":"combiner","pos":"VER"} ,
		{"word":"combinées","word_nosc":"combinees","lemma":"combiner","pos":"VER"} ,
		{"word":"combinés","word_nosc":"combines","lemma":"combiner","pos":"VER"} ,
		{"word":"combla","word_nosc":"combla","lemma":"combler","pos":"VER"} ,
		{"word":"comblaient","word_nosc":"comblaient","lemma":"combler","pos":"VER"} ,
		{"word":"comblais","word_nosc":"comblais","lemma":"combler","pos":"VER"} ,
		{"word":"comblait","word_nosc":"comblait","lemma":"combler","pos":"VER"} ,
		{"word":"comblant","word_nosc":"comblant","lemma":"combler","pos":"VER"} ,
		{"word":"comble","word_nosc":"comble","lemma":"combler","pos":"VER"} ,
		{"word":"comblent","word_nosc":"comblent","lemma":"combler","pos":"VER"} ,
		{"word":"combler","word_nosc":"combler","lemma":"combler","pos":"VER"} ,
		{"word":"comblera","word_nosc":"comblera","lemma":"combler","pos":"VER"} ,
		{"word":"comblerai","word_nosc":"comblerai","lemma":"combler","pos":"VER"} ,
		{"word":"combleraient","word_nosc":"combleraient","lemma":"combler","pos":"VER"} ,
		{"word":"comblerais","word_nosc":"comblerais","lemma":"combler","pos":"VER"} ,
		{"word":"comblerait","word_nosc":"comblerait","lemma":"combler","pos":"VER"} ,
		{"word":"comblerez","word_nosc":"comblerez","lemma":"combler","pos":"VER"} ,
		{"word":"combleront","word_nosc":"combleront","lemma":"combler","pos":"VER"} ,
		{"word":"combles","word_nosc":"combles","lemma":"combler","pos":"VER"} ,
		{"word":"comblez","word_nosc":"comblez","lemma":"combler","pos":"VER"} ,
		{"word":"comblons","word_nosc":"comblons","lemma":"combler","pos":"VER"} ,
		{"word":"comblât","word_nosc":"comblat","lemma":"combler","pos":"VER"} ,
		{"word":"comblèrent","word_nosc":"comblerent","lemma":"combler","pos":"VER"} ,
		{"word":"comblé","word_nosc":"comble","lemma":"combler","pos":"VER"} ,
		{"word":"comblée","word_nosc":"comblee","lemma":"combler","pos":"VER"} ,
		{"word":"comblées","word_nosc":"comblees","lemma":"combler","pos":"VER"} ,
		{"word":"comblés","word_nosc":"combles","lemma":"combler","pos":"VER"} ,
		{"word":"commanda","word_nosc":"commanda","lemma":"commander","pos":"VER"} ,
		{"word":"commandai","word_nosc":"commandai","lemma":"commander","pos":"VER"} ,
		{"word":"commandaient","word_nosc":"commandaient","lemma":"commander","pos":"VER"} ,
		{"word":"commandais","word_nosc":"commandais","lemma":"commander","pos":"VER"} ,
		{"word":"commandait","word_nosc":"commandait","lemma":"commander","pos":"VER"} ,
		{"word":"commandant","word_nosc":"commandant","lemma":"commander","pos":"VER"} ,
		{"word":"commande","word_nosc":"commande","lemma":"commander","pos":"VER"} ,
		{"word":"commandent","word_nosc":"commandent","lemma":"commander","pos":"VER"} ,
		{"word":"commander","word_nosc":"commander","lemma":"commander","pos":"VER"} ,
		{"word":"commandera","word_nosc":"commandera","lemma":"commander","pos":"VER"} ,
		{"word":"commanderai","word_nosc":"commanderai","lemma":"commander","pos":"VER"} ,
		{"word":"commanderait","word_nosc":"commanderait","lemma":"commander","pos":"VER"} ,
		{"word":"commanderas","word_nosc":"commanderas","lemma":"commander","pos":"VER"} ,
		{"word":"commanderez","word_nosc":"commanderez","lemma":"commander","pos":"VER"} ,
		{"word":"commanderons","word_nosc":"commanderons","lemma":"commander","pos":"VER"} ,
		{"word":"commanderont","word_nosc":"commanderont","lemma":"commander","pos":"VER"} ,
		{"word":"commandes","word_nosc":"commandes","lemma":"commander","pos":"VER"} ,
		{"word":"commandez","word_nosc":"commandez","lemma":"commander","pos":"VER"} ,
		{"word":"commandiez","word_nosc":"commandiez","lemma":"commander","pos":"VER"} ,
		{"word":"commandions","word_nosc":"commandions","lemma":"commander","pos":"VER"} ,
		{"word":"commandite","word_nosc":"commandite","lemma":"commanditer","pos":"VER"} ,
		{"word":"commanditer","word_nosc":"commanditer","lemma":"commanditer","pos":"VER"} ,
		{"word":"commandites","word_nosc":"commandites","lemma":"commanditer","pos":"VER"} ,
		{"word":"commandité","word_nosc":"commandite","lemma":"commanditer","pos":"VER"} ,
		{"word":"commanditée","word_nosc":"commanditee","lemma":"commanditer","pos":"VER"} ,
		{"word":"commanditées","word_nosc":"commanditees","lemma":"commanditer","pos":"VER"} ,
		{"word":"commandons","word_nosc":"commandons","lemma":"commander","pos":"VER"} ,
		{"word":"commandât","word_nosc":"commandat","lemma":"commander","pos":"VER"} ,
		{"word":"commandèrent","word_nosc":"commanderent","lemma":"commander","pos":"VER"} ,
		{"word":"commandé","word_nosc":"commande","lemma":"commander","pos":"VER"} ,
		{"word":"commandée","word_nosc":"commandee","lemma":"commander","pos":"VER"} ,
		{"word":"commandées","word_nosc":"commandees","lemma":"commander","pos":"VER"} ,
		{"word":"commandés","word_nosc":"commandes","lemma":"commander","pos":"VER"} ,
		{"word":"commence","word_nosc":"commence","lemma":"commencer","pos":"VER"} ,
		{"word":"commencent","word_nosc":"commencent","lemma":"commencer","pos":"VER"} ,
		{"word":"commencer","word_nosc":"commencer","lemma":"commencer","pos":"VER"} ,
		{"word":"commencera","word_nosc":"commencera","lemma":"commencer","pos":"VER"} ,
		{"word":"commencerai","word_nosc":"commencerai","lemma":"commencer","pos":"VER"} ,
		{"word":"commenceraient","word_nosc":"commenceraient","lemma":"commencer","pos":"VER"} ,
		{"word":"commencerais","word_nosc":"commencerais","lemma":"commencer","pos":"VER"} ,
		{"word":"commencerait","word_nosc":"commencerait","lemma":"commencer","pos":"VER"} ,
		{"word":"commenceras","word_nosc":"commenceras","lemma":"commencer","pos":"VER"} ,
		{"word":"commencerez","word_nosc":"commencerez","lemma":"commencer","pos":"VER"} ,
		{"word":"commenceriez","word_nosc":"commenceriez","lemma":"commencer","pos":"VER"} ,
		{"word":"commencerions","word_nosc":"commencerions","lemma":"commencer","pos":"VER"} ,
		{"word":"commencerons","word_nosc":"commencerons","lemma":"commencer","pos":"VER"} ,
		{"word":"commenceront","word_nosc":"commenceront","lemma":"commencer","pos":"VER"} ,
		{"word":"commences","word_nosc":"commences","lemma":"commencer","pos":"VER"} ,
		{"word":"commencez","word_nosc":"commencez","lemma":"commencer","pos":"VER"} ,
		{"word":"commenciez","word_nosc":"commenciez","lemma":"commencer","pos":"VER"} ,
		{"word":"commencions","word_nosc":"commencions","lemma":"commencer","pos":"VER"} ,
		{"word":"commencèrent","word_nosc":"commencerent","lemma":"commencer","pos":"VER"} ,
		{"word":"commencé","word_nosc":"commence","lemma":"commencer","pos":"VER"} ,
		{"word":"commencée","word_nosc":"commencee","lemma":"commencer","pos":"VER"} ,
		{"word":"commencées","word_nosc":"commencees","lemma":"commencer","pos":"VER"} ,
		{"word":"commencés","word_nosc":"commences","lemma":"commencer","pos":"VER"} ,
		{"word":"commenta","word_nosc":"commenta","lemma":"commenter","pos":"VER"} ,
		{"word":"commentai","word_nosc":"commentai","lemma":"commenter","pos":"VER"} ,
		{"word":"commentaient","word_nosc":"commentaient","lemma":"commenter","pos":"VER"} ,
		{"word":"commentais","word_nosc":"commentais","lemma":"commenter","pos":"VER"} ,
		{"word":"commentait","word_nosc":"commentait","lemma":"commenter","pos":"VER"} ,
		{"word":"commentant","word_nosc":"commentant","lemma":"commenter","pos":"VER"} ,
		{"word":"commente","word_nosc":"commente","lemma":"commenter","pos":"VER"} ,
		{"word":"commentent","word_nosc":"commentent","lemma":"commenter","pos":"VER"} ,
		{"word":"commenter","word_nosc":"commenter","lemma":"commenter","pos":"VER"} ,
		{"word":"commentera","word_nosc":"commentera","lemma":"commenter","pos":"VER"} ,
		{"word":"commenterai","word_nosc":"commenterai","lemma":"commenter","pos":"VER"} ,
		{"word":"commenterait","word_nosc":"commenterait","lemma":"commenter","pos":"VER"} ,
		{"word":"commentez","word_nosc":"commentez","lemma":"commenter","pos":"VER"} ,
		{"word":"commentions","word_nosc":"commentions","lemma":"commenter","pos":"VER"} ,
		{"word":"commenté","word_nosc":"commente","lemma":"commenter","pos":"VER"} ,
		{"word":"commentée","word_nosc":"commentee","lemma":"commenter","pos":"VER"} ,
		{"word":"commentées","word_nosc":"commentees","lemma":"commenter","pos":"VER"} ,
		{"word":"commentés","word_nosc":"commentes","lemma":"commenter","pos":"VER"} ,
		{"word":"commença","word_nosc":"commenca","lemma":"commencer","pos":"VER"} ,
		{"word":"commençai","word_nosc":"commencai","lemma":"commencer","pos":"VER"} ,
		{"word":"commençaient","word_nosc":"commencaient","lemma":"commencer","pos":"VER"} ,
		{"word":"commençais","word_nosc":"commencais","lemma":"commencer","pos":"VER"} ,
		{"word":"commençait","word_nosc":"commencait","lemma":"commencer","pos":"VER"} ,
		{"word":"commençant","word_nosc":"commencant","lemma":"commencer","pos":"VER"} ,
		{"word":"commenças","word_nosc":"commencas","lemma":"commencer","pos":"VER"} ,
		{"word":"commençons","word_nosc":"commencons","lemma":"commencer","pos":"VER"} ,
		{"word":"commençâmes","word_nosc":"commencames","lemma":"commencer","pos":"VER"} ,
		{"word":"commençât","word_nosc":"commencat","lemma":"commencer","pos":"VER"} ,
		{"word":"commerce","word_nosc":"commerce","lemma":"commercer","pos":"VER"} ,
		{"word":"commercent","word_nosc":"commercent","lemma":"commercer","pos":"VER"} ,
		{"word":"commercer","word_nosc":"commercer","lemma":"commercer","pos":"VER"} ,
		{"word":"commercialiser","word_nosc":"commercialiser","lemma":"commercialiser","pos":"VER"} ,
		{"word":"commercialisera","word_nosc":"commercialisera","lemma":"commercialiser","pos":"VER"} ,
		{"word":"commercialisé","word_nosc":"commercialise","lemma":"commercialiser","pos":"VER"} ,
		{"word":"commerciez","word_nosc":"commerciez","lemma":"commercer","pos":"VER"} ,
		{"word":"commercé","word_nosc":"commerce","lemma":"commercer","pos":"VER"} ,
		{"word":"commerçait","word_nosc":"commercait","lemma":"commercer","pos":"VER"} ,
		{"word":"commerçant","word_nosc":"commercant","lemma":"commercer","pos":"VER"} ,
		{"word":"commerçons","word_nosc":"commercons","lemma":"commercer","pos":"VER"} ,
		{"word":"commet","word_nosc":"commet","lemma":"commettre","pos":"VER"} ,
		{"word":"commets","word_nosc":"commets","lemma":"commettre","pos":"VER"} ,
		{"word":"commettaient","word_nosc":"commettaient","lemma":"commettre","pos":"VER"} ,
		{"word":"commettais","word_nosc":"commettais","lemma":"commettre","pos":"VER"} ,
		{"word":"commettait","word_nosc":"commettait","lemma":"commettre","pos":"VER"} ,
		{"word":"commettant","word_nosc":"commettant","lemma":"commettre","pos":"VER"} ,
		{"word":"commette","word_nosc":"commette","lemma":"commettre","pos":"VER"} ,
		{"word":"commettent","word_nosc":"commettent","lemma":"commettre","pos":"VER"} ,
		{"word":"commettes","word_nosc":"commettes","lemma":"commettre","pos":"VER"} ,
		{"word":"commettez","word_nosc":"commettez","lemma":"commettre","pos":"VER"} ,
		{"word":"commettiez","word_nosc":"commettiez","lemma":"commettre","pos":"VER"} ,
		{"word":"commettions","word_nosc":"commettions","lemma":"commettre","pos":"VER"} ,
		{"word":"commettons","word_nosc":"commettons","lemma":"commettre","pos":"VER"} ,
		{"word":"commettra","word_nosc":"commettra","lemma":"commettre","pos":"VER"} ,
		{"word":"commettrai","word_nosc":"commettrai","lemma":"commettre","pos":"VER"} ,
		{"word":"commettraient","word_nosc":"commettraient","lemma":"commettre","pos":"VER"} ,
		{"word":"commettrais","word_nosc":"commettrais","lemma":"commettre","pos":"VER"} ,
		{"word":"commettrait","word_nosc":"commettrait","lemma":"commettre","pos":"VER"} ,
		{"word":"commettras","word_nosc":"commettras","lemma":"commettre","pos":"VER"} ,
		{"word":"commettre","word_nosc":"commettre","lemma":"commettre","pos":"VER"} ,
		{"word":"commettrez","word_nosc":"commettrez","lemma":"commettre","pos":"VER"} ,
		{"word":"commirent","word_nosc":"commirent","lemma":"commettre","pos":"VER"} ,
		{"word":"commis","word_nosc":"commis","lemma":"commettre","pos":"VER"} ,
		{"word":"commise","word_nosc":"commise","lemma":"commettre","pos":"VER"} ,
		{"word":"commises","word_nosc":"commises","lemma":"commettre","pos":"VER"} ,
		{"word":"commissionné","word_nosc":"commissionne","lemma":"commissionner","pos":"VER"} ,
		{"word":"commit","word_nosc":"commit","lemma":"commettre","pos":"VER"} ,
		{"word":"commotionnerait","word_nosc":"commotionnerait","lemma":"commotionner","pos":"VER"} ,
		{"word":"commotionné","word_nosc":"commotionne","lemma":"commotionner","pos":"VER"} ,
		{"word":"commotionnée","word_nosc":"commotionnee","lemma":"commotionner","pos":"VER"} ,
		{"word":"commotionnés","word_nosc":"commotionnes","lemma":"commotionner","pos":"VER"} ,
		{"word":"commuais","word_nosc":"commuais","lemma":"commuer","pos":"VER"} ,
		{"word":"commuant","word_nosc":"commuant","lemma":"commuer","pos":"VER"} ,
		{"word":"commuent","word_nosc":"commuent","lemma":"commuer","pos":"VER"} ,
		{"word":"commuer","word_nosc":"commuer","lemma":"commuer","pos":"VER"} ,
		{"word":"communia","word_nosc":"communia","lemma":"communier","pos":"VER"} ,
		{"word":"communiaient","word_nosc":"communiaient","lemma":"communier","pos":"VER"} ,
		{"word":"communiais","word_nosc":"communiais","lemma":"communier","pos":"VER"} ,
		{"word":"communiait","word_nosc":"communiait","lemma":"communier","pos":"VER"} ,
		{"word":"communiant","word_nosc":"communiant","lemma":"communier","pos":"VER"} ,
		{"word":"communiasse","word_nosc":"communiasse","lemma":"communier","pos":"VER"} ,
		{"word":"communie","word_nosc":"communie","lemma":"communier","pos":"VER"} ,
		{"word":"communient","word_nosc":"communient","lemma":"communier","pos":"VER"} ,
		{"word":"communier","word_nosc":"communier","lemma":"communier","pos":"VER"} ,
		{"word":"communierons","word_nosc":"communierons","lemma":"communier","pos":"VER"} ,
		{"word":"communieront","word_nosc":"communieront","lemma":"communier","pos":"VER"} ,
		{"word":"communiez","word_nosc":"communiez","lemma":"communier","pos":"VER"} ,
		{"word":"communions","word_nosc":"communions","lemma":"communier","pos":"VER"} ,
		{"word":"communiqua","word_nosc":"communiqua","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquai","word_nosc":"communiquai","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquaient","word_nosc":"communiquaient","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquais","word_nosc":"communiquais","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquait","word_nosc":"communiquait","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquant","word_nosc":"communiquant","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquassent","word_nosc":"communiquassent","lemma":"communiquer","pos":"VER"} ,
		{"word":"communique","word_nosc":"communique","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquent","word_nosc":"communiquent","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquer","word_nosc":"communiquer","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquera","word_nosc":"communiquera","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquerai","word_nosc":"communiquerai","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiqueraient","word_nosc":"communiqueraient","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquerait","word_nosc":"communiquerait","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquerez","word_nosc":"communiquerez","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquerons","word_nosc":"communiquerons","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiqueront","word_nosc":"communiqueront","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiques","word_nosc":"communiques","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquez","word_nosc":"communiquez","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquiez","word_nosc":"communiquiez","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquions","word_nosc":"communiquions","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquons","word_nosc":"communiquons","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquât","word_nosc":"communiquat","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquèrent","word_nosc":"communiquerent","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiqué","word_nosc":"communique","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquée","word_nosc":"communiquee","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiquées","word_nosc":"communiquees","lemma":"communiquer","pos":"VER"} ,
		{"word":"communiqués","word_nosc":"communiques","lemma":"communiquer","pos":"VER"} ,
		{"word":"communisée","word_nosc":"communisee","lemma":"communiser","pos":"VER"} ,
		{"word":"communièrent","word_nosc":"communierent","lemma":"communier","pos":"VER"} ,
		{"word":"communié","word_nosc":"communie","lemma":"communier","pos":"VER"} ,
		{"word":"commute","word_nosc":"commute","lemma":"commuter","pos":"VER"} ,
		{"word":"commuter","word_nosc":"commuter","lemma":"commuter","pos":"VER"} ,
		{"word":"commué","word_nosc":"commue","lemma":"commuer","pos":"VER"} ,
		{"word":"commuée","word_nosc":"commuee","lemma":"commuer","pos":"VER"} ,
		{"word":"commémorait","word_nosc":"commemorait","lemma":"commémorer","pos":"VER"} ,
		{"word":"commémorant","word_nosc":"commemorant","lemma":"commémorer","pos":"VER"} ,
		{"word":"commémore","word_nosc":"commemore","lemma":"commémorer","pos":"VER"} ,
		{"word":"commémorent","word_nosc":"commemorent","lemma":"commémorer","pos":"VER"} ,
		{"word":"commémorer","word_nosc":"commemorer","lemma":"commémorer","pos":"VER"} ,
		{"word":"commémorions","word_nosc":"commemorions","lemma":"commémorer","pos":"VER"} ,
		{"word":"commémorons","word_nosc":"commemorons","lemma":"commémorer","pos":"VER"} ,
		{"word":"commémoré","word_nosc":"commemore","lemma":"commémorer","pos":"VER"} ,
		{"word":"commérait","word_nosc":"commerait","lemma":"commérer","pos":"VER"} ,
		{"word":"commérer","word_nosc":"commerer","lemma":"commérer","pos":"VER"} ,
		{"word":"commît","word_nosc":"commit","lemma":"commettre","pos":"VER"} ,
		{"word":"compacte","word_nosc":"compacte","lemma":"compacter","pos":"VER"} ,
		{"word":"compacté","word_nosc":"compacte","lemma":"compacter","pos":"VER"} ,
		{"word":"compara","word_nosc":"compara","lemma":"comparer","pos":"VER"} ,
		{"word":"comparai","word_nosc":"comparai","lemma":"comparer","pos":"VER"} ,
		{"word":"comparaient","word_nosc":"comparaient","lemma":"comparer","pos":"VER"} ,
		{"word":"comparais","word_nosc":"comparais","lemma":"comparaître","pos":"VER"} ,
		{"word":"comparais","word_nosc":"comparais","lemma":"comparer","pos":"VER"} ,
		{"word":"comparaissaient","word_nosc":"comparaissaient","lemma":"comparaître","pos":"VER"} ,
		{"word":"comparaissait","word_nosc":"comparaissait","lemma":"comparaître","pos":"VER"} ,
		{"word":"comparaissant","word_nosc":"comparaissant","lemma":"comparaître","pos":"VER"} ,
		{"word":"comparaissent","word_nosc":"comparaissent","lemma":"comparaître","pos":"VER"} ,
		{"word":"comparaisses","word_nosc":"comparaisses","lemma":"comparaître","pos":"VER"} ,
		{"word":"comparaissons","word_nosc":"comparaissons","lemma":"comparaître","pos":"VER"} ,
		{"word":"comparait","word_nosc":"comparait","lemma":"comparer","pos":"VER"} ,
		{"word":"comparant","word_nosc":"comparant","lemma":"comparer","pos":"VER"} ,
		{"word":"comparaît","word_nosc":"comparait","lemma":"comparaître","pos":"VER"} ,
		{"word":"comparaîtra","word_nosc":"comparaitra","lemma":"comparaître","pos":"VER"} ,
		{"word":"comparaîtrait","word_nosc":"comparaitrait","lemma":"comparaître","pos":"VER"} ,
		{"word":"comparaître","word_nosc":"comparaitre","lemma":"comparaître","pos":"VER"} ,
		{"word":"comparaîtrez","word_nosc":"comparaitrez","lemma":"comparaître","pos":"VER"} ,
		{"word":"comparaîtrons","word_nosc":"comparaitrons","lemma":"comparaître","pos":"VER"} ,
		{"word":"compare","word_nosc":"compare","lemma":"comparer","pos":"VER"} ,
		{"word":"comparent","word_nosc":"comparent","lemma":"comparer","pos":"VER"} ,
		{"word":"comparer","word_nosc":"comparer","lemma":"comparer","pos":"VER"} ,
		{"word":"comparera","word_nosc":"comparera","lemma":"comparer","pos":"VER"} ,
		{"word":"comparerai","word_nosc":"comparerai","lemma":"comparer","pos":"VER"} ,
		{"word":"comparerais","word_nosc":"comparerais","lemma":"comparer","pos":"VER"} ,
		{"word":"comparerons","word_nosc":"comparerons","lemma":"comparer","pos":"VER"} ,
		{"word":"compareront","word_nosc":"compareront","lemma":"comparer","pos":"VER"} ,
		{"word":"compares","word_nosc":"compares","lemma":"comparer","pos":"VER"} ,
		{"word":"comparez","word_nosc":"comparez","lemma":"comparer","pos":"VER"} ,
		{"word":"compariez","word_nosc":"compariez","lemma":"comparer","pos":"VER"} ,
		{"word":"comparions","word_nosc":"comparions","lemma":"comparer","pos":"VER"} ,
		{"word":"comparoir","word_nosc":"comparoir","lemma":"comparoir","pos":"VER"} ,
		{"word":"comparons","word_nosc":"comparons","lemma":"comparer","pos":"VER"} ,
		{"word":"compartimentaient","word_nosc":"compartimentaient","lemma":"compartimenter","pos":"VER"} ,
		{"word":"compartimentant","word_nosc":"compartimentant","lemma":"compartimenter","pos":"VER"} ,
		{"word":"compartimente","word_nosc":"compartimente","lemma":"compartimenter","pos":"VER"} ,
		{"word":"compartimenter","word_nosc":"compartimenter","lemma":"compartimenter","pos":"VER"} ,
		{"word":"compartimenté","word_nosc":"compartimente","lemma":"compartimenter","pos":"VER"} ,
		{"word":"compartimentée","word_nosc":"compartimentee","lemma":"compartimenter","pos":"VER"} ,
		{"word":"comparu","word_nosc":"comparu","lemma":"comparaître","pos":"VER"} ,
		{"word":"comparut","word_nosc":"comparut","lemma":"comparaître","pos":"VER"} ,
		{"word":"comparé","word_nosc":"compare","lemma":"comparer","pos":"VER"} ,
		{"word":"comparée","word_nosc":"comparee","lemma":"comparer","pos":"VER"} ,
		{"word":"comparées","word_nosc":"comparees","lemma":"comparer","pos":"VER"} ,
		{"word":"comparés","word_nosc":"compares","lemma":"comparer","pos":"VER"} ,
		{"word":"compassé","word_nosc":"compasse","lemma":"compasser","pos":"VER"} ,
		{"word":"compassée","word_nosc":"compassee","lemma":"compasser","pos":"VER"} ,
		{"word":"compassés","word_nosc":"compasses","lemma":"compasser","pos":"VER"} ,
		{"word":"compati","word_nosc":"compati","lemma":"compatir","pos":"VER"} ,
		{"word":"compatie","word_nosc":"compatie","lemma":"compatir","pos":"VER"} ,
		{"word":"compatir","word_nosc":"compatir","lemma":"compatir","pos":"VER"} ,
		{"word":"compatira","word_nosc":"compatira","lemma":"compatir","pos":"VER"} ,
		{"word":"compatirait","word_nosc":"compatirait","lemma":"compatir","pos":"VER"} ,
		{"word":"compatirent","word_nosc":"compatirent","lemma":"compatir","pos":"VER"} ,
		{"word":"compatis","word_nosc":"compatis","lemma":"compatir","pos":"VER"} ,
		{"word":"compatissaient","word_nosc":"compatissaient","lemma":"compatir","pos":"VER"} ,
		{"word":"compatissais","word_nosc":"compatissais","lemma":"compatir","pos":"VER"} ,
		{"word":"compatissait","word_nosc":"compatissait","lemma":"compatir","pos":"VER"} ,
		{"word":"compatissant","word_nosc":"compatissant","lemma":"compatir","pos":"VER"} ,
		{"word":"compatisse","word_nosc":"compatisse","lemma":"compatir","pos":"VER"} ,
		{"word":"compatissent","word_nosc":"compatissent","lemma":"compatir","pos":"VER"} ,
		{"word":"compatissez","word_nosc":"compatissez","lemma":"compatir","pos":"VER"} ,
		{"word":"compatissons","word_nosc":"compatissons","lemma":"compatir","pos":"VER"} ,
		{"word":"compatit","word_nosc":"compatit","lemma":"compatir","pos":"VER"} ,
		{"word":"compensa","word_nosc":"compensa","lemma":"compenser","pos":"VER"} ,
		{"word":"compensaient","word_nosc":"compensaient","lemma":"compenser","pos":"VER"} ,
		{"word":"compensais","word_nosc":"compensais","lemma":"compenser","pos":"VER"} ,
		{"word":"compensait","word_nosc":"compensait","lemma":"compenser","pos":"VER"} ,
		{"word":"compensant","word_nosc":"compensant","lemma":"compenser","pos":"VER"} ,
		{"word":"compense","word_nosc":"compense","lemma":"compenser","pos":"VER"} ,
		{"word":"compensent","word_nosc":"compensent","lemma":"compenser","pos":"VER"} ,
		{"word":"compenser","word_nosc":"compenser","lemma":"compenser","pos":"VER"} ,
		{"word":"compensera","word_nosc":"compensera","lemma":"compenser","pos":"VER"} ,
		{"word":"compenserais","word_nosc":"compenserais","lemma":"compenser","pos":"VER"} ,
		{"word":"compenserait","word_nosc":"compenserait","lemma":"compenser","pos":"VER"} ,
		{"word":"compensez","word_nosc":"compensez","lemma":"compenser","pos":"VER"} ,
		{"word":"compensât","word_nosc":"compensat","lemma":"compenser","pos":"VER"} ,
		{"word":"compensèrent","word_nosc":"compenserent","lemma":"compenser","pos":"VER"} ,
		{"word":"compensé","word_nosc":"compense","lemma":"compenser","pos":"VER"} ,
		{"word":"compensée","word_nosc":"compensee","lemma":"compenser","pos":"VER"} ,
		{"word":"compensées","word_nosc":"compensees","lemma":"compenser","pos":"VER"} ,
		{"word":"compensés","word_nosc":"compenses","lemma":"compenser","pos":"VER"} ,
		{"word":"compilant","word_nosc":"compilant","lemma":"compiler","pos":"VER"} ,
		{"word":"compile","word_nosc":"compile","lemma":"compiler","pos":"VER"} ,
		{"word":"compiler","word_nosc":"compiler","lemma":"compiler","pos":"VER"} ,
		{"word":"compilons","word_nosc":"compilons","lemma":"compiler","pos":"VER"} ,
		{"word":"compilé","word_nosc":"compile","lemma":"compiler","pos":"VER"} ,
		{"word":"compilées","word_nosc":"compilees","lemma":"compiler","pos":"VER"} ,
		{"word":"compissait","word_nosc":"compissait","lemma":"compisser","pos":"VER"} ,
		{"word":"compisse","word_nosc":"compisse","lemma":"compisser","pos":"VER"} ,
		{"word":"compisser","word_nosc":"compisser","lemma":"compisser","pos":"VER"} ,
		{"word":"compissé","word_nosc":"compisse","lemma":"compisser","pos":"VER"} ,
		{"word":"complairait","word_nosc":"complairait","lemma":"complaire","pos":"VER"} ,
		{"word":"complaire","word_nosc":"complaire","lemma":"complaire","pos":"VER"} ,
		{"word":"complais","word_nosc":"complais","lemma":"complaire","pos":"VER"} ,
		{"word":"complaisaient","word_nosc":"complaisaient","lemma":"complaire","pos":"VER"} ,
		{"word":"complaisais","word_nosc":"complaisais","lemma":"complaire","pos":"VER"} ,
		{"word":"complaisait","word_nosc":"complaisait","lemma":"complaire","pos":"VER"} ,
		{"word":"complaisant","word_nosc":"complaisant","lemma":"complaire","pos":"VER"} ,
		{"word":"complaise","word_nosc":"complaise","lemma":"complaire","pos":"VER"} ,
		{"word":"complaisent","word_nosc":"complaisent","lemma":"complaire","pos":"VER"} ,
		{"word":"complaisez","word_nosc":"complaisez","lemma":"complaire","pos":"VER"} ,
		{"word":"complaisiez","word_nosc":"complaisiez","lemma":"complaire","pos":"VER"} ,
		{"word":"complaît","word_nosc":"complait","lemma":"complaire","pos":"VER"} ,
		{"word":"complexait","word_nosc":"complexait","lemma":"complexer","pos":"VER"} ,
		{"word":"complexe","word_nosc":"complexe","lemma":"complexer","pos":"VER"} ,
		{"word":"complexer","word_nosc":"complexer","lemma":"complexer","pos":"VER"} ,
		{"word":"complexes","word_nosc":"complexes","lemma":"complexer","pos":"VER"} ,
		{"word":"complexé","word_nosc":"complexe","lemma":"complexer","pos":"VER"} ,
		{"word":"complexée","word_nosc":"complexee","lemma":"complexer","pos":"VER"} ,
		{"word":"complexés","word_nosc":"complexes","lemma":"complexer","pos":"VER"} ,
		{"word":"complimenta","word_nosc":"complimenta","lemma":"complimenter","pos":"VER"} ,
		{"word":"complimentaient","word_nosc":"complimentaient","lemma":"complimenter","pos":"VER"} ,
		{"word":"complimentais","word_nosc":"complimentais","lemma":"complimenter","pos":"VER"} ,
		{"word":"complimentait","word_nosc":"complimentait","lemma":"complimenter","pos":"VER"} ,
		{"word":"complimentant","word_nosc":"complimentant","lemma":"complimenter","pos":"VER"} ,
		{"word":"complimente","word_nosc":"complimente","lemma":"complimenter","pos":"VER"} ,
		{"word":"complimentent","word_nosc":"complimentent","lemma":"complimenter","pos":"VER"} ,
		{"word":"complimenter","word_nosc":"complimenter","lemma":"complimenter","pos":"VER"} ,
		{"word":"complimenteraient","word_nosc":"complimenteraient","lemma":"complimenter","pos":"VER"} ,
		{"word":"complimentez","word_nosc":"complimentez","lemma":"complimenter","pos":"VER"} ,
		{"word":"complimentât","word_nosc":"complimentat","lemma":"complimenter","pos":"VER"} ,
		{"word":"complimenté","word_nosc":"complimente","lemma":"complimenter","pos":"VER"} ,
		{"word":"complimentée","word_nosc":"complimentee","lemma":"complimenter","pos":"VER"} ,
		{"word":"compliqua","word_nosc":"compliqua","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliquaient","word_nosc":"compliquaient","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliquais","word_nosc":"compliquais","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliquait","word_nosc":"compliquait","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliquant","word_nosc":"compliquant","lemma":"compliquer","pos":"VER"} ,
		{"word":"complique","word_nosc":"complique","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliquent","word_nosc":"compliquent","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliquer","word_nosc":"compliquer","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliquera","word_nosc":"compliquera","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliquerai","word_nosc":"compliquerai","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliquerait","word_nosc":"compliquerait","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliques","word_nosc":"compliques","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliquez","word_nosc":"compliquez","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliquions","word_nosc":"compliquions","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliquons","word_nosc":"compliquons","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliquèrent","word_nosc":"compliquerent","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliqué","word_nosc":"complique","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliquée","word_nosc":"compliquee","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliquées","word_nosc":"compliquees","lemma":"compliquer","pos":"VER"} ,
		{"word":"compliqués","word_nosc":"compliques","lemma":"compliquer","pos":"VER"} ,
		{"word":"complotai","word_nosc":"complotai","lemma":"comploter","pos":"VER"} ,
		{"word":"complotaient","word_nosc":"complotaient","lemma":"comploter","pos":"VER"} ,
		{"word":"complotais","word_nosc":"complotais","lemma":"comploter","pos":"VER"} ,
		{"word":"complotait","word_nosc":"complotait","lemma":"comploter","pos":"VER"} ,
		{"word":"complotant","word_nosc":"complotant","lemma":"comploter","pos":"VER"} ,
		{"word":"complote","word_nosc":"complote","lemma":"comploter","pos":"VER"} ,
		{"word":"complotent","word_nosc":"complotent","lemma":"comploter","pos":"VER"} ,
		{"word":"comploter","word_nosc":"comploter","lemma":"comploter","pos":"VER"} ,
		{"word":"comploteraient","word_nosc":"comploteraient","lemma":"comploter","pos":"VER"} ,
		{"word":"comploterait","word_nosc":"comploterait","lemma":"comploter","pos":"VER"} ,
		{"word":"complotes","word_nosc":"complotes","lemma":"comploter","pos":"VER"} ,
		{"word":"complotez","word_nosc":"complotez","lemma":"comploter","pos":"VER"} ,
		{"word":"complotiez","word_nosc":"complotiez","lemma":"comploter","pos":"VER"} ,
		{"word":"complotons","word_nosc":"complotons","lemma":"comploter","pos":"VER"} ,
		{"word":"complotèrent","word_nosc":"comploterent","lemma":"comploter","pos":"VER"} ,
		{"word":"comploté","word_nosc":"complote","lemma":"comploter","pos":"VER"} ,
		{"word":"complus","word_nosc":"complus","lemma":"complaire","pos":"VER"} ,
		{"word":"complut","word_nosc":"complut","lemma":"complaire","pos":"VER"} ,
		{"word":"complète","word_nosc":"complete","lemma":"compléter","pos":"VER"} ,
		{"word":"complètent","word_nosc":"completent","lemma":"compléter","pos":"VER"} ,
		{"word":"complètes","word_nosc":"completes","lemma":"compléter","pos":"VER"} ,
		{"word":"compléta","word_nosc":"completa","lemma":"compléter","pos":"VER"} ,
		{"word":"complétai","word_nosc":"completai","lemma":"compléter","pos":"VER"} ,
		{"word":"complétaient","word_nosc":"completaient","lemma":"compléter","pos":"VER"} ,
		{"word":"complétais","word_nosc":"completais","lemma":"compléter","pos":"VER"} ,
		{"word":"complétait","word_nosc":"completait","lemma":"compléter","pos":"VER"} ,
		{"word":"complétant","word_nosc":"completant","lemma":"compléter","pos":"VER"} ,
		{"word":"compléter","word_nosc":"completer","lemma":"compléter","pos":"VER"} ,
		{"word":"complétera","word_nosc":"completera","lemma":"compléter","pos":"VER"} ,
		{"word":"compléterai","word_nosc":"completerai","lemma":"compléter","pos":"VER"} ,
		{"word":"compléteraient","word_nosc":"completeraient","lemma":"compléter","pos":"VER"} ,
		{"word":"compléterait","word_nosc":"completerait","lemma":"compléter","pos":"VER"} ,
		{"word":"compléteras","word_nosc":"completeras","lemma":"compléter","pos":"VER"} ,
		{"word":"compléterez","word_nosc":"completerez","lemma":"compléter","pos":"VER"} ,
		{"word":"compléteront","word_nosc":"completeront","lemma":"compléter","pos":"VER"} ,
		{"word":"complétez","word_nosc":"completez","lemma":"compléter","pos":"VER"} ,
		{"word":"complétiez","word_nosc":"completiez","lemma":"compléter","pos":"VER"} ,
		{"word":"complétons","word_nosc":"completons","lemma":"compléter","pos":"VER"} ,
		{"word":"complétèrent","word_nosc":"completerent","lemma":"compléter","pos":"VER"} ,
		{"word":"complété","word_nosc":"complete","lemma":"compléter","pos":"VER"} ,
		{"word":"complétée","word_nosc":"completee","lemma":"compléter","pos":"VER"} ,
		{"word":"complétées","word_nosc":"completees","lemma":"compléter","pos":"VER"} ,
		{"word":"complétés","word_nosc":"completes","lemma":"compléter","pos":"VER"} ,
		{"word":"comporta","word_nosc":"comporta","lemma":"comporter","pos":"VER"} ,
		{"word":"comportai","word_nosc":"comportai","lemma":"comporter","pos":"VER"} ,
		{"word":"comportaient","word_nosc":"comportaient","lemma":"comporter","pos":"VER"} ,
		{"word":"comportais","word_nosc":"comportais","lemma":"comporter","pos":"VER"} ,
		{"word":"comportait","word_nosc":"comportait","lemma":"comporter","pos":"VER"} ,
		{"word":"comportant","word_nosc":"comportant","lemma":"comporter","pos":"VER"} ,
		{"word":"comportassent","word_nosc":"comportassent","lemma":"comporter","pos":"VER"} ,
		{"word":"comporte","word_nosc":"comporte","lemma":"comporter","pos":"VER"} ,
		{"word":"comportent","word_nosc":"comportent","lemma":"comporter","pos":"VER"} ,
		{"word":"comporter","word_nosc":"comporter","lemma":"comporter","pos":"VER"} ,
		{"word":"comportera","word_nosc":"comportera","lemma":"comporter","pos":"VER"} ,
		{"word":"comporterai","word_nosc":"comporterai","lemma":"comporter","pos":"VER"} ,
		{"word":"comporteraient","word_nosc":"comporteraient","lemma":"comporter","pos":"VER"} ,
		{"word":"comporterais","word_nosc":"comporterais","lemma":"comporter","pos":"VER"} ,
		{"word":"comporterait","word_nosc":"comporterait","lemma":"comporter","pos":"VER"} ,
		{"word":"comporterez","word_nosc":"comporterez","lemma":"comporter","pos":"VER"} ,
		{"word":"comporteriez","word_nosc":"comporteriez","lemma":"comporter","pos":"VER"} ,
		{"word":"comporterions","word_nosc":"comporterions","lemma":"comporter","pos":"VER"} ,
		{"word":"comporteront","word_nosc":"comporteront","lemma":"comporter","pos":"VER"} ,
		{"word":"comportes","word_nosc":"comportes","lemma":"comporter","pos":"VER"} ,
		{"word":"comportez","word_nosc":"comportez","lemma":"comporter","pos":"VER"} ,
		{"word":"comportiez","word_nosc":"comportiez","lemma":"comporter","pos":"VER"} ,
		{"word":"comportions","word_nosc":"comportions","lemma":"comporter","pos":"VER"} ,
		{"word":"comportons","word_nosc":"comportons","lemma":"comporter","pos":"VER"} ,
		{"word":"comportât","word_nosc":"comportat","lemma":"comporter","pos":"VER"} ,
		{"word":"comporté","word_nosc":"comporte","lemma":"comporter","pos":"VER"} ,
		{"word":"comportée","word_nosc":"comportee","lemma":"comporter","pos":"VER"} ,
		{"word":"comportés","word_nosc":"comportes","lemma":"comporter","pos":"VER"} ,
		{"word":"composa","word_nosc":"composa","lemma":"composer","pos":"VER"} ,
		{"word":"composai","word_nosc":"composai","lemma":"composer","pos":"VER"} ,
		{"word":"composaient","word_nosc":"composaient","lemma":"composer","pos":"VER"} ,
		{"word":"composais","word_nosc":"composais","lemma":"composer","pos":"VER"} ,
		{"word":"composait","word_nosc":"composait","lemma":"composer","pos":"VER"} ,
		{"word":"composant","word_nosc":"composant","lemma":"composer","pos":"VER"} ,
		{"word":"compose","word_nosc":"compose","lemma":"composer","pos":"VER"} ,
		{"word":"composent","word_nosc":"composent","lemma":"composer","pos":"VER"} ,
		{"word":"composer","word_nosc":"composer","lemma":"composer","pos":"VER"} ,
		{"word":"composera","word_nosc":"composera","lemma":"composer","pos":"VER"} ,
		{"word":"composerai","word_nosc":"composerai","lemma":"composer","pos":"VER"} ,
		{"word":"composerait","word_nosc":"composerait","lemma":"composer","pos":"VER"} ,
		{"word":"composeront","word_nosc":"composeront","lemma":"composer","pos":"VER"} ,
		{"word":"composes","word_nosc":"composes","lemma":"composer","pos":"VER"} ,
		{"word":"composez","word_nosc":"composez","lemma":"composer","pos":"VER"} ,
		{"word":"composiez","word_nosc":"composiez","lemma":"composer","pos":"VER"} ,
		{"word":"composions","word_nosc":"composions","lemma":"composer","pos":"VER"} ,
		{"word":"composons","word_nosc":"composons","lemma":"composer","pos":"VER"} ,
		{"word":"composter","word_nosc":"composter","lemma":"composter","pos":"VER"} ,
		{"word":"compostez","word_nosc":"compostez","lemma":"composter","pos":"VER"} ,
		{"word":"composté","word_nosc":"composte","lemma":"composter","pos":"VER"} ,
		{"word":"composèrent","word_nosc":"composerent","lemma":"composer","pos":"VER"} ,
		{"word":"composé","word_nosc":"compose","lemma":"composer","pos":"VER"} ,
		{"word":"composée","word_nosc":"composee","lemma":"composer","pos":"VER"} ,
		{"word":"composées","word_nosc":"composees","lemma":"composer","pos":"VER"} ,
		{"word":"composés","word_nosc":"composes","lemma":"composer","pos":"VER"} ,
		{"word":"comprenaient","word_nosc":"comprenaient","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprenais","word_nosc":"comprenais","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprenait","word_nosc":"comprenait","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprenant","word_nosc":"comprenant","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprend","word_nosc":"comprend","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprendra","word_nosc":"comprendra","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprendrai","word_nosc":"comprendrai","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprendraient","word_nosc":"comprendraient","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprendrais","word_nosc":"comprendrais","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprendrait","word_nosc":"comprendrait","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprendras","word_nosc":"comprendras","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprendre","word_nosc":"comprendre","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprendrez","word_nosc":"comprendrez","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprendriez","word_nosc":"comprendriez","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprendrions","word_nosc":"comprendrions","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprendrons","word_nosc":"comprendrons","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprendront","word_nosc":"comprendront","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprends","word_nosc":"comprends","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprenez","word_nosc":"comprenez","lemma":"comprendre","pos":"VER"} ,
		{"word":"compreniez","word_nosc":"compreniez","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprenions","word_nosc":"comprenions","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprenne","word_nosc":"comprenne","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprennent","word_nosc":"comprennent","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprennes","word_nosc":"comprennes","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprenons","word_nosc":"comprenons","lemma":"comprendre","pos":"VER"} ,
		{"word":"compresse","word_nosc":"compresse","lemma":"compresser","pos":"VER"} ,
		{"word":"compresser","word_nosc":"compresser","lemma":"compresser","pos":"VER"} ,
		{"word":"compressions","word_nosc":"compressions","lemma":"compresser","pos":"VER"} ,
		{"word":"compressé","word_nosc":"compresse","lemma":"compresser","pos":"VER"} ,
		{"word":"compressée","word_nosc":"compressee","lemma":"compresser","pos":"VER"} ,
		{"word":"compressés","word_nosc":"compresses","lemma":"compresser","pos":"VER"} ,
		{"word":"comprima","word_nosc":"comprima","lemma":"comprimer","pos":"VER"} ,
		{"word":"comprimait","word_nosc":"comprimait","lemma":"comprimer","pos":"VER"} ,
		{"word":"comprimant","word_nosc":"comprimant","lemma":"comprimer","pos":"VER"} ,
		{"word":"comprime","word_nosc":"comprime","lemma":"comprimer","pos":"VER"} ,
		{"word":"compriment","word_nosc":"compriment","lemma":"comprimer","pos":"VER"} ,
		{"word":"comprimer","word_nosc":"comprimer","lemma":"comprimer","pos":"VER"} ,
		{"word":"comprimez","word_nosc":"comprimez","lemma":"comprimer","pos":"VER"} ,
		{"word":"comprimèrent","word_nosc":"comprimerent","lemma":"comprimer","pos":"VER"} ,
		{"word":"comprimé","word_nosc":"comprime","lemma":"comprimer","pos":"VER"} ,
		{"word":"comprimée","word_nosc":"comprimee","lemma":"comprimer","pos":"VER"} ,
		{"word":"comprimées","word_nosc":"comprimees","lemma":"comprimer","pos":"VER"} ,
		{"word":"comprimés","word_nosc":"comprimes","lemma":"comprimer","pos":"VER"} ,
		{"word":"comprirent","word_nosc":"comprirent","lemma":"comprendre","pos":"VER"} ,
		{"word":"compris","word_nosc":"compris","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprise","word_nosc":"comprise","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprises","word_nosc":"comprises","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprisse","word_nosc":"comprisse","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprit","word_nosc":"comprit","lemma":"comprendre","pos":"VER"} ,
		{"word":"compromet","word_nosc":"compromet","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromets","word_nosc":"compromets","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromettaient","word_nosc":"compromettaient","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromettait","word_nosc":"compromettait","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromettant","word_nosc":"compromettant","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromette","word_nosc":"compromette","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromettent","word_nosc":"compromettent","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromettes","word_nosc":"compromettes","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromettez","word_nosc":"compromettez","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromettiez","word_nosc":"compromettiez","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromettrai","word_nosc":"compromettrai","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromettrait","word_nosc":"compromettrait","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromettre","word_nosc":"compromettre","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromettrons","word_nosc":"compromettrons","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromirent","word_nosc":"compromirent","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromis","word_nosc":"compromis","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromise","word_nosc":"compromise","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromises","word_nosc":"compromises","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromit","word_nosc":"compromit","lemma":"compromettre","pos":"VER"} ,
		{"word":"compromît","word_nosc":"compromit","lemma":"compromettre","pos":"VER"} ,
		{"word":"comprîmes","word_nosc":"comprimes","lemma":"comprendre","pos":"VER"} ,
		{"word":"comprît","word_nosc":"comprit","lemma":"comprendre","pos":"VER"} ,
		{"word":"compta","word_nosc":"compta","lemma":"compter","pos":"VER"} ,
		{"word":"comptabilisait","word_nosc":"comptabilisait","lemma":"comptabiliser","pos":"VER"} ,
		{"word":"comptabilisant","word_nosc":"comptabilisant","lemma":"comptabiliser","pos":"VER"} ,
		{"word":"comptabilise","word_nosc":"comptabilise","lemma":"comptabiliser","pos":"VER"} ,
		{"word":"comptabiliser","word_nosc":"comptabiliser","lemma":"comptabiliser","pos":"VER"} ,
		{"word":"comptabilisé","word_nosc":"comptabilise","lemma":"comptabiliser","pos":"VER"} ,
		{"word":"comptabilisées","word_nosc":"comptabilisees","lemma":"comptabiliser","pos":"VER"} ,
		{"word":"comptabilisés","word_nosc":"comptabilises","lemma":"comptabiliser","pos":"VER"} ,
		{"word":"comptai","word_nosc":"comptai","lemma":"compter","pos":"VER"} ,
		{"word":"comptaient","word_nosc":"comptaient","lemma":"compter","pos":"VER"} ,
		{"word":"comptais","word_nosc":"comptais","lemma":"compter","pos":"VER"} ,
		{"word":"comptait","word_nosc":"comptait","lemma":"compter","pos":"VER"} ,
		{"word":"comptant","word_nosc":"comptant","lemma":"compter","pos":"VER"} ,
		{"word":"compte","word_nosc":"compte","lemma":"compter","pos":"VER"} ,
		{"word":"comptent","word_nosc":"comptent","lemma":"compter","pos":"VER"} ,
		{"word":"compter","word_nosc":"compter","lemma":"compter","pos":"VER"} ,
		{"word":"comptera","word_nosc":"comptera","lemma":"compter","pos":"VER"} ,
		{"word":"compterai","word_nosc":"compterai","lemma":"compter","pos":"VER"} ,
		{"word":"compteraient","word_nosc":"compteraient","lemma":"compter","pos":"VER"} ,
		{"word":"compterais","word_nosc":"compterais","lemma":"compter","pos":"VER"} ,
		{"word":"compterait","word_nosc":"compterait","lemma":"compter","pos":"VER"} ,
		{"word":"compteras","word_nosc":"compteras","lemma":"compter","pos":"VER"} ,
		{"word":"compterez","word_nosc":"compterez","lemma":"compter","pos":"VER"} ,
		{"word":"compterons","word_nosc":"compterons","lemma":"compter","pos":"VER"} ,
		{"word":"compteront","word_nosc":"compteront","lemma":"compter","pos":"VER"} ,
		{"word":"comptes","word_nosc":"comptes","lemma":"compter","pos":"VER"} ,
		{"word":"comptez","word_nosc":"comptez","lemma":"compter","pos":"VER"} ,
		{"word":"comptiez","word_nosc":"comptiez","lemma":"compter","pos":"VER"} ,
		{"word":"comptions","word_nosc":"comptions","lemma":"compter","pos":"VER"} ,
		{"word":"comptons","word_nosc":"comptons","lemma":"compter","pos":"VER"} ,
		{"word":"comptât","word_nosc":"comptat","lemma":"compter","pos":"VER"} ,
		{"word":"comptèrent","word_nosc":"compterent","lemma":"compter","pos":"VER"} ,
		{"word":"compté","word_nosc":"compte","lemma":"compter","pos":"VER"} ,
		{"word":"comptée","word_nosc":"comptee","lemma":"compter","pos":"VER"} ,
		{"word":"comptées","word_nosc":"comptees","lemma":"compter","pos":"VER"} ,
		{"word":"comptés","word_nosc":"comptes","lemma":"compter","pos":"VER"} ,
		{"word":"compulsait","word_nosc":"compulsait","lemma":"compulser","pos":"VER"} ,
		{"word":"compulsant","word_nosc":"compulsant","lemma":"compulser","pos":"VER"} ,
		{"word":"compulse","word_nosc":"compulse","lemma":"compulser","pos":"VER"} ,
		{"word":"compulser","word_nosc":"compulser","lemma":"compulser","pos":"VER"} ,
		{"word":"compulsez","word_nosc":"compulsez","lemma":"compulser","pos":"VER"} ,
		{"word":"compulsons","word_nosc":"compulsons","lemma":"compulser","pos":"VER"} ,
		{"word":"compulsé","word_nosc":"compulse","lemma":"compulser","pos":"VER"} ,
		{"word":"compulsés","word_nosc":"compulses","lemma":"compulser","pos":"VER"} ,
		{"word":"compète","word_nosc":"compete","lemma":"compéter","pos":"VER"} ,
		{"word":"compètes","word_nosc":"competes","lemma":"compéter","pos":"VER"} ,
		{"word":"compétant","word_nosc":"competant","lemma":"compéter","pos":"VER"} ,
		{"word":"concassait","word_nosc":"concassait","lemma":"concasser","pos":"VER"} ,
		{"word":"concassant","word_nosc":"concassant","lemma":"concasser","pos":"VER"} ,
		{"word":"concasse","word_nosc":"concasse","lemma":"concasser","pos":"VER"} ,
		{"word":"concassent","word_nosc":"concassent","lemma":"concasser","pos":"VER"} ,
		{"word":"concasser","word_nosc":"concasser","lemma":"concasser","pos":"VER"} ,
		{"word":"concassé","word_nosc":"concasse","lemma":"concasser","pos":"VER"} ,
		{"word":"concassée","word_nosc":"concassee","lemma":"concasser","pos":"VER"} ,
		{"word":"concassées","word_nosc":"concassees","lemma":"concasser","pos":"VER"} ,
		{"word":"concassés","word_nosc":"concasses","lemma":"concasser","pos":"VER"} ,
		{"word":"concentra","word_nosc":"concentra","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrai","word_nosc":"concentrai","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentraient","word_nosc":"concentraient","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrais","word_nosc":"concentrais","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrait","word_nosc":"concentrait","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrant","word_nosc":"concentrant","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentre","word_nosc":"concentre","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrent","word_nosc":"concentrent","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrer","word_nosc":"concentrer","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrera","word_nosc":"concentrera","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrerai","word_nosc":"concentrerai","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrerais","word_nosc":"concentrerais","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrerait","word_nosc":"concentrerait","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrerons","word_nosc":"concentrerons","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentreront","word_nosc":"concentreront","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentres","word_nosc":"concentres","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrez","word_nosc":"concentrez","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentriez","word_nosc":"concentriez","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrions","word_nosc":"concentrions","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrons","word_nosc":"concentrons","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentré","word_nosc":"concentre","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrée","word_nosc":"concentree","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrées","word_nosc":"concentrees","lemma":"concentrer","pos":"VER"} ,
		{"word":"concentrés","word_nosc":"concentres","lemma":"concentrer","pos":"VER"} ,
		{"word":"conceptualise","word_nosc":"conceptualise","lemma":"conceptualiser","pos":"VER"} ,
		{"word":"conceptualiser","word_nosc":"conceptualiser","lemma":"conceptualiser","pos":"VER"} ,
		{"word":"conceptualisé","word_nosc":"conceptualise","lemma":"conceptualiser","pos":"VER"} ,
		{"word":"concernaient","word_nosc":"concernaient","lemma":"concerner","pos":"VER"} ,
		{"word":"concernait","word_nosc":"concernait","lemma":"concerner","pos":"VER"} ,
		{"word":"concernant","word_nosc":"concernant","lemma":"concerner","pos":"VER"} ,
		{"word":"concerne","word_nosc":"concerne","lemma":"concerner","pos":"VER"} ,
		{"word":"concernent","word_nosc":"concernent","lemma":"concerner","pos":"VER"} ,
		{"word":"concerner","word_nosc":"concerner","lemma":"concerner","pos":"VER"} ,
		{"word":"concernera","word_nosc":"concernera","lemma":"concerner","pos":"VER"} ,
		{"word":"concerneraient","word_nosc":"concerneraient","lemma":"concerner","pos":"VER"} ,
		{"word":"concernerait","word_nosc":"concernerait","lemma":"concerner","pos":"VER"} ,
		{"word":"concernât","word_nosc":"concernat","lemma":"concerner","pos":"VER"} ,
		{"word":"concernèrent","word_nosc":"concernerent","lemma":"concerner","pos":"VER"} ,
		{"word":"concerné","word_nosc":"concerne","lemma":"concerner","pos":"VER"} ,
		{"word":"concernée","word_nosc":"concernee","lemma":"concerner","pos":"VER"} ,
		{"word":"concernées","word_nosc":"concernees","lemma":"concerner","pos":"VER"} ,
		{"word":"concernés","word_nosc":"concernes","lemma":"concerner","pos":"VER"} ,
		{"word":"concertaient","word_nosc":"concertaient","lemma":"concerter","pos":"VER"} ,
		{"word":"concertait","word_nosc":"concertait","lemma":"concerter","pos":"VER"} ,
		{"word":"concertant","word_nosc":"concertant","lemma":"concerter","pos":"VER"} ,
		{"word":"concerte","word_nosc":"concerte","lemma":"concerter","pos":"VER"} ,
		{"word":"concertent","word_nosc":"concertent","lemma":"concerter","pos":"VER"} ,
		{"word":"concerter","word_nosc":"concerter","lemma":"concerter","pos":"VER"} ,
		{"word":"concertez","word_nosc":"concertez","lemma":"concerter","pos":"VER"} ,
		{"word":"concertons","word_nosc":"concertons","lemma":"concerter","pos":"VER"} ,
		{"word":"concertèrent","word_nosc":"concerterent","lemma":"concerter","pos":"VER"} ,
		{"word":"concerté","word_nosc":"concerte","lemma":"concerter","pos":"VER"} ,
		{"word":"concertée","word_nosc":"concertee","lemma":"concerter","pos":"VER"} ,
		{"word":"concertées","word_nosc":"concertees","lemma":"concerter","pos":"VER"} ,
		{"word":"concertés","word_nosc":"concertes","lemma":"concerter","pos":"VER"} ,
		{"word":"concevaient","word_nosc":"concevaient","lemma":"concevoir","pos":"VER"} ,
		{"word":"concevais","word_nosc":"concevais","lemma":"concevoir","pos":"VER"} ,
		{"word":"concevait","word_nosc":"concevait","lemma":"concevoir","pos":"VER"} ,
		{"word":"concevant","word_nosc":"concevant","lemma":"concevoir","pos":"VER"} ,
		{"word":"concevez","word_nosc":"concevez","lemma":"concevoir","pos":"VER"} ,
		{"word":"conceviez","word_nosc":"conceviez","lemma":"concevoir","pos":"VER"} ,
		{"word":"concevions","word_nosc":"concevions","lemma":"concevoir","pos":"VER"} ,
		{"word":"concevoir","word_nosc":"concevoir","lemma":"concevoir","pos":"VER"} ,
		{"word":"concevons","word_nosc":"concevons","lemma":"concevoir","pos":"VER"} ,
		{"word":"concevrais","word_nosc":"concevrais","lemma":"concevoir","pos":"VER"} ,
		{"word":"concevrait","word_nosc":"concevrait","lemma":"concevoir","pos":"VER"} ,
		{"word":"conchiaient","word_nosc":"conchiaient","lemma":"conchier","pos":"VER"} ,
		{"word":"conchie","word_nosc":"conchie","lemma":"conchier","pos":"VER"} ,
		{"word":"conchier","word_nosc":"conchier","lemma":"conchier","pos":"VER"} ,
		{"word":"conchié","word_nosc":"conchie","lemma":"conchier","pos":"VER"} ,
		{"word":"conciliaient","word_nosc":"conciliaient","lemma":"concilier","pos":"VER"} ,
		{"word":"conciliait","word_nosc":"conciliait","lemma":"concilier","pos":"VER"} ,
		{"word":"conciliant","word_nosc":"conciliant","lemma":"concilier","pos":"VER"} ,
		{"word":"concilie","word_nosc":"concilie","lemma":"concilier","pos":"VER"} ,
		{"word":"concilier","word_nosc":"concilier","lemma":"concilier","pos":"VER"} ,
		{"word":"concilierait","word_nosc":"concilierait","lemma":"concilier","pos":"VER"} ,
		{"word":"concilies","word_nosc":"concilies","lemma":"concilier","pos":"VER"} ,
		{"word":"concilié","word_nosc":"concilie","lemma":"concilier","pos":"VER"} ,
		{"word":"conciliées","word_nosc":"conciliees","lemma":"concilier","pos":"VER"} ,
		{"word":"conciliés","word_nosc":"concilies","lemma":"concilier","pos":"VER"} ,
		{"word":"conclu","word_nosc":"conclu","lemma":"conclure","pos":"VER"} ,
		{"word":"concluaient","word_nosc":"concluaient","lemma":"conclure","pos":"VER"} ,
		{"word":"concluais","word_nosc":"concluais","lemma":"conclure","pos":"VER"} ,
		{"word":"concluait","word_nosc":"concluait","lemma":"conclure","pos":"VER"} ,
		{"word":"concluant","word_nosc":"concluant","lemma":"conclure","pos":"VER"} ,
		{"word":"conclue","word_nosc":"conclue","lemma":"conclure","pos":"VER"} ,
		{"word":"concluent","word_nosc":"concluent","lemma":"conclure","pos":"VER"} ,
		{"word":"conclues","word_nosc":"conclues","lemma":"conclure","pos":"VER"} ,
		{"word":"concluez","word_nosc":"concluez","lemma":"conclure","pos":"VER"} ,
		{"word":"concluions","word_nosc":"concluions","lemma":"conclure","pos":"VER"} ,
		{"word":"concluons","word_nosc":"concluons","lemma":"conclure","pos":"VER"} ,
		{"word":"conclura","word_nosc":"conclura","lemma":"conclure","pos":"VER"} ,
		{"word":"conclurai","word_nosc":"conclurai","lemma":"conclure","pos":"VER"} ,
		{"word":"conclurais","word_nosc":"conclurais","lemma":"conclure","pos":"VER"} ,
		{"word":"conclurait","word_nosc":"conclurait","lemma":"conclure","pos":"VER"} ,
		{"word":"conclure","word_nosc":"conclure","lemma":"conclure","pos":"VER"} ,
		{"word":"conclurent","word_nosc":"conclurent","lemma":"conclure","pos":"VER"} ,
		{"word":"conclurez","word_nosc":"conclurez","lemma":"conclure","pos":"VER"} ,
		{"word":"conclurions","word_nosc":"conclurions","lemma":"conclure","pos":"VER"} ,
		{"word":"conclurons","word_nosc":"conclurons","lemma":"conclure","pos":"VER"} ,
		{"word":"concluront","word_nosc":"concluront","lemma":"conclure","pos":"VER"} ,
		{"word":"conclus","word_nosc":"conclus","lemma":"conclure","pos":"VER"} ,
		{"word":"conclut","word_nosc":"conclut","lemma":"conclure","pos":"VER"} ,
		{"word":"conclûmes","word_nosc":"conclumes","lemma":"conclure","pos":"VER"} ,
		{"word":"conclût","word_nosc":"conclut","lemma":"conclure","pos":"VER"} ,
		{"word":"concocta","word_nosc":"concocta","lemma":"concocter","pos":"VER"} ,
		{"word":"concoctait","word_nosc":"concoctait","lemma":"concocter","pos":"VER"} ,
		{"word":"concoctent","word_nosc":"concoctent","lemma":"concocter","pos":"VER"} ,
		{"word":"concocter","word_nosc":"concocter","lemma":"concocter","pos":"VER"} ,
		{"word":"concocteras","word_nosc":"concocteras","lemma":"concocter","pos":"VER"} ,
		{"word":"concocté","word_nosc":"concocte","lemma":"concocter","pos":"VER"} ,
		{"word":"concoctée","word_nosc":"concoctee","lemma":"concocter","pos":"VER"} ,
		{"word":"concoctés","word_nosc":"concoctes","lemma":"concocter","pos":"VER"} ,
		{"word":"concordaient","word_nosc":"concordaient","lemma":"concorder","pos":"VER"} ,
		{"word":"concordait","word_nosc":"concordait","lemma":"concorder","pos":"VER"} ,
		{"word":"concordant","word_nosc":"concordant","lemma":"concorder","pos":"VER"} ,
		{"word":"concorde","word_nosc":"concorde","lemma":"concorder","pos":"VER"} ,
		{"word":"concordent","word_nosc":"concordent","lemma":"concorder","pos":"VER"} ,
		{"word":"concorder","word_nosc":"concorder","lemma":"concorder","pos":"VER"} ,
		{"word":"concordez","word_nosc":"concordez","lemma":"concorder","pos":"VER"} ,
		{"word":"concouraient","word_nosc":"concouraient","lemma":"concourir","pos":"VER"} ,
		{"word":"concourait","word_nosc":"concourait","lemma":"concourir","pos":"VER"} ,
		{"word":"concourant","word_nosc":"concourant","lemma":"concourir","pos":"VER"} ,
		{"word":"concoure","word_nosc":"concoure","lemma":"concourir","pos":"VER"} ,
		{"word":"concourent","word_nosc":"concourent","lemma":"concourir","pos":"VER"} ,
		{"word":"concourez","word_nosc":"concourez","lemma":"concourir","pos":"VER"} ,
		{"word":"concourir","word_nosc":"concourir","lemma":"concourir","pos":"VER"} ,
		{"word":"concourrai","word_nosc":"concourrai","lemma":"concourir","pos":"VER"} ,
		{"word":"concourrait","word_nosc":"concourrait","lemma":"concourir","pos":"VER"} ,
		{"word":"concourront","word_nosc":"concourront","lemma":"concourir","pos":"VER"} ,
		{"word":"concours","word_nosc":"concours","lemma":"concourir","pos":"VER"} ,
		{"word":"concourt","word_nosc":"concourt","lemma":"concourir","pos":"VER"} ,
		{"word":"concouru","word_nosc":"concouru","lemma":"concourir","pos":"VER"} ,
		{"word":"concourût","word_nosc":"concourut","lemma":"concourir","pos":"VER"} ,
		{"word":"concrète","word_nosc":"concrete","lemma":"concréter","pos":"VER"} ,
		{"word":"concrétisant","word_nosc":"concretisant","lemma":"concrétiser","pos":"VER"} ,
		{"word":"concrétise","word_nosc":"concretise","lemma":"concrétiser","pos":"VER"} ,
		{"word":"concrétisent","word_nosc":"concretisent","lemma":"concrétiser","pos":"VER"} ,
		{"word":"concrétiser","word_nosc":"concretiser","lemma":"concrétiser","pos":"VER"} ,
		{"word":"concrétisèrent","word_nosc":"concretiserent","lemma":"concrétiser","pos":"VER"} ,
		{"word":"concrétisé","word_nosc":"concretise","lemma":"concrétiser","pos":"VER"} ,
		{"word":"concrétisée","word_nosc":"concretisee","lemma":"concrétiser","pos":"VER"} ,
		{"word":"concrétisées","word_nosc":"concretisees","lemma":"concrétiser","pos":"VER"} ,
		{"word":"concrétisés","word_nosc":"concretises","lemma":"concrétiser","pos":"VER"} ,
		{"word":"concubinait","word_nosc":"concubinait","lemma":"concubiner","pos":"VER"} ,
		{"word":"concurrence","word_nosc":"concurrence","lemma":"concurrencer","pos":"VER"} ,
		{"word":"concurrencer","word_nosc":"concurrencer","lemma":"concurrencer","pos":"VER"} ,
		{"word":"concurrencé","word_nosc":"concurrence","lemma":"concurrencer","pos":"VER"} ,
		{"word":"concurrencée","word_nosc":"concurrencee","lemma":"concurrencer","pos":"VER"} ,
		{"word":"concurrençait","word_nosc":"concurrencait","lemma":"concurrencer","pos":"VER"} ,
		{"word":"concède","word_nosc":"concede","lemma":"concéder","pos":"VER"} ,
		{"word":"concèdes","word_nosc":"concedes","lemma":"concéder","pos":"VER"} ,
		{"word":"concéda","word_nosc":"conceda","lemma":"concéder","pos":"VER"} ,
		{"word":"concédai","word_nosc":"concedai","lemma":"concéder","pos":"VER"} ,
		{"word":"concédaient","word_nosc":"concedaient","lemma":"concéder","pos":"VER"} ,
		{"word":"concédais","word_nosc":"concedais","lemma":"concéder","pos":"VER"} ,
		{"word":"concédait","word_nosc":"concedait","lemma":"concéder","pos":"VER"} ,
		{"word":"concéder","word_nosc":"conceder","lemma":"concéder","pos":"VER"} ,
		{"word":"concéderait","word_nosc":"concederait","lemma":"concéder","pos":"VER"} ,
		{"word":"concédez","word_nosc":"concedez","lemma":"concéder","pos":"VER"} ,
		{"word":"concédons","word_nosc":"concedons","lemma":"concéder","pos":"VER"} ,
		{"word":"concédât","word_nosc":"concedat","lemma":"concéder","pos":"VER"} ,
		{"word":"concédé","word_nosc":"concede","lemma":"concéder","pos":"VER"} ,
		{"word":"concédée","word_nosc":"concedee","lemma":"concéder","pos":"VER"} ,
		{"word":"concédés","word_nosc":"concedes","lemma":"concéder","pos":"VER"} ,
		{"word":"concélèbrent","word_nosc":"concelebrent","lemma":"concélébrer","pos":"VER"} ,
		{"word":"condamna","word_nosc":"condamna","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnai","word_nosc":"condamnai","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnaient","word_nosc":"condamnaient","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnais","word_nosc":"condamnais","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnait","word_nosc":"condamnait","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnant","word_nosc":"condamnant","lemma":"condamner","pos":"VER"} ,
		{"word":"condamne","word_nosc":"condamne","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnent","word_nosc":"condamnent","lemma":"condamner","pos":"VER"} ,
		{"word":"condamner","word_nosc":"condamner","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnera","word_nosc":"condamnera","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnerai","word_nosc":"condamnerai","lemma":"condamner","pos":"VER"} ,
		{"word":"condamneraient","word_nosc":"condamneraient","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnerais","word_nosc":"condamnerais","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnerait","word_nosc":"condamnerait","lemma":"condamner","pos":"VER"} ,
		{"word":"condamneras","word_nosc":"condamneras","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnerons","word_nosc":"condamnerons","lemma":"condamner","pos":"VER"} ,
		{"word":"condamneront","word_nosc":"condamneront","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnes","word_nosc":"condamnes","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnez","word_nosc":"condamnez","lemma":"condamner","pos":"VER"} ,
		{"word":"condamniez","word_nosc":"condamniez","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnions","word_nosc":"condamnions","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnons","word_nosc":"condamnons","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnât","word_nosc":"condamnat","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnèrent","word_nosc":"condamnerent","lemma":"condamner","pos":"VER"} ,
		{"word":"condamné","word_nosc":"condamne","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnée","word_nosc":"condamnee","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnées","word_nosc":"condamnees","lemma":"condamner","pos":"VER"} ,
		{"word":"condamnés","word_nosc":"condamnes","lemma":"condamner","pos":"VER"} ,
		{"word":"condensa","word_nosc":"condensa","lemma":"condenser","pos":"VER"} ,
		{"word":"condensaient","word_nosc":"condensaient","lemma":"condenser","pos":"VER"} ,
		{"word":"condensait","word_nosc":"condensait","lemma":"condenser","pos":"VER"} ,
		{"word":"condensant","word_nosc":"condensant","lemma":"condenser","pos":"VER"} ,
		{"word":"condense","word_nosc":"condense","lemma":"condenser","pos":"VER"} ,
		{"word":"condensent","word_nosc":"condensent","lemma":"condenser","pos":"VER"} ,
		{"word":"condenser","word_nosc":"condenser","lemma":"condenser","pos":"VER"} ,
		{"word":"condensera","word_nosc":"condensera","lemma":"condenser","pos":"VER"} ,
		{"word":"condensèrent","word_nosc":"condenserent","lemma":"condenser","pos":"VER"} ,
		{"word":"condensé","word_nosc":"condense","lemma":"condenser","pos":"VER"} ,
		{"word":"condensées","word_nosc":"condensees","lemma":"condenser","pos":"VER"} ,
		{"word":"condensés","word_nosc":"condenses","lemma":"condenser","pos":"VER"} ,
		{"word":"condescend","word_nosc":"condescend","lemma":"condescendre","pos":"VER"} ,
		{"word":"condescendait","word_nosc":"condescendait","lemma":"condescendre","pos":"VER"} ,
		{"word":"condescendant","word_nosc":"condescendant","lemma":"condescendre","pos":"VER"} ,
		{"word":"condescendit","word_nosc":"condescendit","lemma":"condescendre","pos":"VER"} ,
		{"word":"condescendrais","word_nosc":"condescendrais","lemma":"condescendre","pos":"VER"} ,
		{"word":"condescendre","word_nosc":"condescendre","lemma":"condescendre","pos":"VER"} ,
		{"word":"condescendu","word_nosc":"condescendu","lemma":"condescendre","pos":"VER"} ,
		{"word":"conditionnait","word_nosc":"conditionnait","lemma":"conditionner","pos":"VER"} ,
		{"word":"conditionnant","word_nosc":"conditionnant","lemma":"conditionner","pos":"VER"} ,
		{"word":"conditionne","word_nosc":"conditionne","lemma":"conditionner","pos":"VER"} ,
		{"word":"conditionnent","word_nosc":"conditionnent","lemma":"conditionner","pos":"VER"} ,
		{"word":"conditionner","word_nosc":"conditionner","lemma":"conditionner","pos":"VER"} ,
		{"word":"conditionnerons","word_nosc":"conditionnerons","lemma":"conditionner","pos":"VER"} ,
		{"word":"conditionnons","word_nosc":"conditionnons","lemma":"conditionner","pos":"VER"} ,
		{"word":"conditionné","word_nosc":"conditionne","lemma":"conditionner","pos":"VER"} ,
		{"word":"conditionnée","word_nosc":"conditionnee","lemma":"conditionner","pos":"VER"} ,
		{"word":"conditionnées","word_nosc":"conditionnees","lemma":"conditionner","pos":"VER"} ,
		{"word":"conditionnés","word_nosc":"conditionnes","lemma":"conditionner","pos":"VER"} ,
		{"word":"conduira","word_nosc":"conduira","lemma":"conduire","pos":"VER"} ,
		{"word":"conduirai","word_nosc":"conduirai","lemma":"conduire","pos":"VER"} ,
		{"word":"conduiraient","word_nosc":"conduiraient","lemma":"conduire","pos":"VER"} ,
		{"word":"conduirais","word_nosc":"conduirais","lemma":"conduire","pos":"VER"} ,
		{"word":"conduirait","word_nosc":"conduirait","lemma":"conduire","pos":"VER"} ,
		{"word":"conduiras","word_nosc":"conduiras","lemma":"conduire","pos":"VER"} ,
		{"word":"conduire","word_nosc":"conduire","lemma":"conduire","pos":"VER"} ,
		{"word":"conduirez","word_nosc":"conduirez","lemma":"conduire","pos":"VER"} ,
		{"word":"conduiriez","word_nosc":"conduiriez","lemma":"conduire","pos":"VER"} ,
		{"word":"conduirons","word_nosc":"conduirons","lemma":"conduire","pos":"VER"} ,
		{"word":"conduiront","word_nosc":"conduiront","lemma":"conduire","pos":"VER"} ,
		{"word":"conduis","word_nosc":"conduis","lemma":"conduire","pos":"VER"} ,
		{"word":"conduisaient","word_nosc":"conduisaient","lemma":"conduire","pos":"VER"} ,
		{"word":"conduisais","word_nosc":"conduisais","lemma":"conduire","pos":"VER"} ,
		{"word":"conduisait","word_nosc":"conduisait","lemma":"conduire","pos":"VER"} ,
		{"word":"conduisant","word_nosc":"conduisant","lemma":"conduire","pos":"VER"} ,
		{"word":"conduise","word_nosc":"conduise","lemma":"conduire","pos":"VER"} ,
		{"word":"conduisent","word_nosc":"conduisent","lemma":"conduire","pos":"VER"} ,
		{"word":"conduises","word_nosc":"conduises","lemma":"conduire","pos":"VER"} ,
		{"word":"conduisez","word_nosc":"conduisez","lemma":"conduire","pos":"VER"} ,
		{"word":"conduisiez","word_nosc":"conduisiez","lemma":"conduire","pos":"VER"} ,
		{"word":"conduisions","word_nosc":"conduisions","lemma":"conduire","pos":"VER"} ,
		{"word":"conduisirent","word_nosc":"conduisirent","lemma":"conduire","pos":"VER"} ,
		{"word":"conduisis","word_nosc":"conduisis","lemma":"conduire","pos":"VER"} ,
		{"word":"conduisit","word_nosc":"conduisit","lemma":"conduire","pos":"VER"} ,
		{"word":"conduisons","word_nosc":"conduisons","lemma":"conduire","pos":"VER"} ,
		{"word":"conduisît","word_nosc":"conduisit","lemma":"conduire","pos":"VER"} ,
		{"word":"conduit","word_nosc":"conduit","lemma":"conduire","pos":"VER"} ,
		{"word":"conduite","word_nosc":"conduite","lemma":"conduire","pos":"VER"} ,
		{"word":"conduites","word_nosc":"conduites","lemma":"conduire","pos":"VER"} ,
		{"word":"conduits","word_nosc":"conduits","lemma":"conduire","pos":"VER"} ,
		{"word":"confabulaient","word_nosc":"confabulaient","lemma":"confabuler","pos":"VER"} ,
		{"word":"confectionna","word_nosc":"confectionna","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionnai","word_nosc":"confectionnai","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionnaient","word_nosc":"confectionnaient","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionnais","word_nosc":"confectionnais","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionnait","word_nosc":"confectionnait","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionne","word_nosc":"confectionne","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionner","word_nosc":"confectionner","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionnerai","word_nosc":"confectionnerai","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionnerais","word_nosc":"confectionnerais","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionnerait","word_nosc":"confectionnerait","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionnez","word_nosc":"confectionnez","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionnions","word_nosc":"confectionnions","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionnâmes","word_nosc":"confectionnames","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionnât","word_nosc":"confectionnat","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionnèrent","word_nosc":"confectionnerent","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionné","word_nosc":"confectionne","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionnée","word_nosc":"confectionnee","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionnées","word_nosc":"confectionnees","lemma":"confectionner","pos":"VER"} ,
		{"word":"confectionnés","word_nosc":"confectionnes","lemma":"confectionner","pos":"VER"} ,
		{"word":"confessa","word_nosc":"confessa","lemma":"confesser","pos":"VER"} ,
		{"word":"confessai","word_nosc":"confessai","lemma":"confesser","pos":"VER"} ,
		{"word":"confessaient","word_nosc":"confessaient","lemma":"confesser","pos":"VER"} ,
		{"word":"confessais","word_nosc":"confessais","lemma":"confesser","pos":"VER"} ,
		{"word":"confessait","word_nosc":"confessait","lemma":"confesser","pos":"VER"} ,
		{"word":"confessant","word_nosc":"confessant","lemma":"confesser","pos":"VER"} ,
		{"word":"confesse","word_nosc":"confesse","lemma":"confesser","pos":"VER"} ,
		{"word":"confessent","word_nosc":"confessent","lemma":"confesser","pos":"VER"} ,
		{"word":"confesser","word_nosc":"confesser","lemma":"confesser","pos":"VER"} ,
		{"word":"confesserai","word_nosc":"confesserai","lemma":"confesser","pos":"VER"} ,
		{"word":"confesserait","word_nosc":"confesserait","lemma":"confesser","pos":"VER"} ,
		{"word":"confesseras","word_nosc":"confesseras","lemma":"confesser","pos":"VER"} ,
		{"word":"confesserez","word_nosc":"confesserez","lemma":"confesser","pos":"VER"} ,
		{"word":"confessez","word_nosc":"confessez","lemma":"confesser","pos":"VER"} ,
		{"word":"confessiez","word_nosc":"confessiez","lemma":"confesser","pos":"VER"} ,
		{"word":"confessons","word_nosc":"confessons","lemma":"confesser","pos":"VER"} ,
		{"word":"confessé","word_nosc":"confesse","lemma":"confesser","pos":"VER"} ,
		{"word":"confessée","word_nosc":"confessee","lemma":"confesser","pos":"VER"} ,
		{"word":"confessés","word_nosc":"confesses","lemma":"confesser","pos":"VER"} ,
		{"word":"confia","word_nosc":"confia","lemma":"confier","pos":"VER"} ,
		{"word":"confiai","word_nosc":"confiai","lemma":"confier","pos":"VER"} ,
		{"word":"confiaient","word_nosc":"confiaient","lemma":"confier","pos":"VER"} ,
		{"word":"confiais","word_nosc":"confiais","lemma":"confier","pos":"VER"} ,
		{"word":"confiait","word_nosc":"confiait","lemma":"confier","pos":"VER"} ,
		{"word":"confiant","word_nosc":"confiant","lemma":"confier","pos":"VER"} ,
		{"word":"confie","word_nosc":"confie","lemma":"confier","pos":"VER"} ,
		{"word":"confient","word_nosc":"confient","lemma":"confier","pos":"VER"} ,
		{"word":"confier","word_nosc":"confier","lemma":"confier","pos":"VER"} ,
		{"word":"confiera","word_nosc":"confiera","lemma":"confier","pos":"VER"} ,
		{"word":"confierai","word_nosc":"confierai","lemma":"confier","pos":"VER"} ,
		{"word":"confieraient","word_nosc":"confieraient","lemma":"confier","pos":"VER"} ,
		{"word":"confierais","word_nosc":"confierais","lemma":"confier","pos":"VER"} ,
		{"word":"confierait","word_nosc":"confierait","lemma":"confier","pos":"VER"} ,
		{"word":"confieras","word_nosc":"confieras","lemma":"confier","pos":"VER"} ,
		{"word":"confierez","word_nosc":"confierez","lemma":"confier","pos":"VER"} ,
		{"word":"confieriez","word_nosc":"confieriez","lemma":"confier","pos":"VER"} ,
		{"word":"confiez","word_nosc":"confiez","lemma":"confier","pos":"VER"} ,
		{"word":"configure","word_nosc":"configure","lemma":"configurer","pos":"VER"} ,
		{"word":"configurer","word_nosc":"configurer","lemma":"configurer","pos":"VER"} ,
		{"word":"configuré","word_nosc":"configure","lemma":"configurer","pos":"VER"} ,
		{"word":"confiions","word_nosc":"confiions","lemma":"confier","pos":"VER"} ,
		{"word":"confina","word_nosc":"confina","lemma":"confiner","pos":"VER"} ,
		{"word":"confinaient","word_nosc":"confinaient","lemma":"confiner","pos":"VER"} ,
		{"word":"confinait","word_nosc":"confinait","lemma":"confiner","pos":"VER"} ,
		{"word":"confinant","word_nosc":"confinant","lemma":"confiner","pos":"VER"} ,
		{"word":"confine","word_nosc":"confine","lemma":"confiner","pos":"VER"} ,
		{"word":"confinent","word_nosc":"confinent","lemma":"confiner","pos":"VER"} ,
		{"word":"confiner","word_nosc":"confiner","lemma":"confiner","pos":"VER"} ,
		{"word":"confinerez","word_nosc":"confinerez","lemma":"confiner","pos":"VER"} ,
		{"word":"confiné","word_nosc":"confine","lemma":"confiner","pos":"VER"} ,
		{"word":"confinée","word_nosc":"confinee","lemma":"confiner","pos":"VER"} ,
		{"word":"confinées","word_nosc":"confinees","lemma":"confiner","pos":"VER"} ,
		{"word":"confinés","word_nosc":"confines","lemma":"confiner","pos":"VER"} ,
		{"word":"confions","word_nosc":"confions","lemma":"confier","pos":"VER"} ,
		{"word":"confire","word_nosc":"confire","lemma":"confire","pos":"VER"} ,
		{"word":"confirma","word_nosc":"confirma","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmai","word_nosc":"confirmai","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmaient","word_nosc":"confirmaient","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmais","word_nosc":"confirmais","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmait","word_nosc":"confirmait","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmant","word_nosc":"confirmant","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirme","word_nosc":"confirme","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirment","word_nosc":"confirment","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmer","word_nosc":"confirmer","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmera","word_nosc":"confirmera","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmerai","word_nosc":"confirmerai","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmeraient","word_nosc":"confirmeraient","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmerait","word_nosc":"confirmerait","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmerez","word_nosc":"confirmerez","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmeront","word_nosc":"confirmeront","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmes","word_nosc":"confirmes","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmez","word_nosc":"confirmez","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmions","word_nosc":"confirmions","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmons","word_nosc":"confirmons","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmâmes","word_nosc":"confirmames","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmât","word_nosc":"confirmat","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmèrent","word_nosc":"confirmerent","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmé","word_nosc":"confirme","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmée","word_nosc":"confirmee","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmées","word_nosc":"confirmees","lemma":"confirmer","pos":"VER"} ,
		{"word":"confirmés","word_nosc":"confirmes","lemma":"confirmer","pos":"VER"} ,
		{"word":"confisqua","word_nosc":"confisqua","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisquaient","word_nosc":"confisquaient","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisquait","word_nosc":"confisquait","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisquant","word_nosc":"confisquant","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisque","word_nosc":"confisque","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisquent","word_nosc":"confisquent","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisquer","word_nosc":"confisquer","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisquera","word_nosc":"confisquera","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisquerai","word_nosc":"confisquerai","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisqueraient","word_nosc":"confisqueraient","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisquerait","word_nosc":"confisquerait","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisquerons","word_nosc":"confisquerons","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisqueront","word_nosc":"confisqueront","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisquez","word_nosc":"confisquez","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisquons","word_nosc":"confisquons","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisquât","word_nosc":"confisquat","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisquèrent","word_nosc":"confisquerent","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisqué","word_nosc":"confisque","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisquée","word_nosc":"confisquee","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisquées","word_nosc":"confisquees","lemma":"confisquer","pos":"VER"} ,
		{"word":"confisqués","word_nosc":"confisques","lemma":"confisquer","pos":"VER"} ,
		{"word":"confit","word_nosc":"confit","lemma":"confire","pos":"VER"} ,
		{"word":"confite","word_nosc":"confite","lemma":"confire","pos":"VER"} ,
		{"word":"confites","word_nosc":"confites","lemma":"confire","pos":"VER"} ,
		{"word":"confits","word_nosc":"confits","lemma":"confire","pos":"VER"} ,
		{"word":"confiâmes","word_nosc":"confiames","lemma":"confier","pos":"VER"} ,
		{"word":"confiât","word_nosc":"confiat","lemma":"confier","pos":"VER"} ,
		{"word":"confièrent","word_nosc":"confierent","lemma":"confier","pos":"VER"} ,
		{"word":"confié","word_nosc":"confie","lemma":"confier","pos":"VER"} ,
		{"word":"confiée","word_nosc":"confiee","lemma":"confier","pos":"VER"} ,
		{"word":"confiées","word_nosc":"confiees","lemma":"confier","pos":"VER"} ,
		{"word":"confiés","word_nosc":"confies","lemma":"confier","pos":"VER"} ,
		{"word":"confluaient","word_nosc":"confluaient","lemma":"confluer","pos":"VER"} ,
		{"word":"confluait","word_nosc":"confluait","lemma":"confluer","pos":"VER"} ,
		{"word":"confluant","word_nosc":"confluant","lemma":"confluer","pos":"VER"} ,
		{"word":"conflue","word_nosc":"conflue","lemma":"confluer","pos":"VER"} ,
		{"word":"confluent","word_nosc":"confluent","lemma":"confluer","pos":"VER"} ,
		{"word":"confluer","word_nosc":"confluer","lemma":"confluer","pos":"VER"} ,
		{"word":"conflué","word_nosc":"conflue","lemma":"confluer","pos":"VER"} ,
		{"word":"confond","word_nosc":"confond","lemma":"confondre","pos":"VER"} ,
		{"word":"confondaient","word_nosc":"confondaient","lemma":"confondre","pos":"VER"} ,
		{"word":"confondais","word_nosc":"confondais","lemma":"confondre","pos":"VER"} ,
		{"word":"confondait","word_nosc":"confondait","lemma":"confondre","pos":"VER"} ,
		{"word":"confondant","word_nosc":"confondant","lemma":"confondre","pos":"VER"} ,
		{"word":"confonde","word_nosc":"confonde","lemma":"confondre","pos":"VER"} ,
		{"word":"confondent","word_nosc":"confondent","lemma":"confondre","pos":"VER"} ,
		{"word":"confondez","word_nosc":"confondez","lemma":"confondre","pos":"VER"} ,
		{"word":"confondions","word_nosc":"confondions","lemma":"confondre","pos":"VER"} ,
		{"word":"confondirent","word_nosc":"confondirent","lemma":"confondre","pos":"VER"} ,
		{"word":"confondis","word_nosc":"confondis","lemma":"confondre","pos":"VER"} ,
		{"word":"confondit","word_nosc":"confondit","lemma":"confondre","pos":"VER"} ,
		{"word":"confondons","word_nosc":"confondons","lemma":"confondre","pos":"VER"} ,
		{"word":"confondra","word_nosc":"confondra","lemma":"confondre","pos":"VER"} ,
		{"word":"confondrai","word_nosc":"confondrai","lemma":"confondre","pos":"VER"} ,
		{"word":"confondrais","word_nosc":"confondrais","lemma":"confondre","pos":"VER"} ,
		{"word":"confondrait","word_nosc":"confondrait","lemma":"confondre","pos":"VER"} ,
		{"word":"confondre","word_nosc":"confondre","lemma":"confondre","pos":"VER"} ,
		{"word":"confondrez","word_nosc":"confondrez","lemma":"confondre","pos":"VER"} ,
		{"word":"confondront","word_nosc":"confondront","lemma":"confondre","pos":"VER"} ,
		{"word":"confonds","word_nosc":"confonds","lemma":"confondre","pos":"VER"} ,
		{"word":"confondu","word_nosc":"confondu","lemma":"confondre","pos":"VER"} ,
		{"word":"confondue","word_nosc":"confondue","lemma":"confondre","pos":"VER"} ,
		{"word":"confondues","word_nosc":"confondues","lemma":"confondre","pos":"VER"} ,
		{"word":"confondus","word_nosc":"confondus","lemma":"confondre","pos":"VER"} ,
		{"word":"confondît","word_nosc":"confondit","lemma":"confondre","pos":"VER"} ,
		{"word":"conformai","word_nosc":"conformai","lemma":"conformer","pos":"VER"} ,
		{"word":"conformaient","word_nosc":"conformaient","lemma":"conformer","pos":"VER"} ,
		{"word":"conformais","word_nosc":"conformais","lemma":"conformer","pos":"VER"} ,
		{"word":"conformait","word_nosc":"conformait","lemma":"conformer","pos":"VER"} ,
		{"word":"conformant","word_nosc":"conformant","lemma":"conformer","pos":"VER"} ,
		{"word":"conforme","word_nosc":"conforme","lemma":"conformer","pos":"VER"} ,
		{"word":"conforment","word_nosc":"conforment","lemma":"conformer","pos":"VER"} ,
		{"word":"conformer","word_nosc":"conformer","lemma":"conformer","pos":"VER"} ,
		{"word":"conformera","word_nosc":"conformera","lemma":"conformer","pos":"VER"} ,
		{"word":"conformerai","word_nosc":"conformerai","lemma":"conformer","pos":"VER"} ,
		{"word":"conformeraient","word_nosc":"conformeraient","lemma":"conformer","pos":"VER"} ,
		{"word":"conformerez","word_nosc":"conformerez","lemma":"conformer","pos":"VER"} ,
		{"word":"conformât","word_nosc":"conformat","lemma":"conformer","pos":"VER"} ,
		{"word":"conformé","word_nosc":"conforme","lemma":"conformer","pos":"VER"} ,
		{"word":"conformée","word_nosc":"conformee","lemma":"conformer","pos":"VER"} ,
		{"word":"conforta","word_nosc":"conforta","lemma":"conforter","pos":"VER"} ,
		{"word":"confortaient","word_nosc":"confortaient","lemma":"conforter","pos":"VER"} ,
		{"word":"confortait","word_nosc":"confortait","lemma":"conforter","pos":"VER"} ,
		{"word":"confortant","word_nosc":"confortant","lemma":"conforter","pos":"VER"} ,
		{"word":"conforte","word_nosc":"conforte","lemma":"conforter","pos":"VER"} ,
		{"word":"conforter","word_nosc":"conforter","lemma":"conforter","pos":"VER"} ,
		{"word":"conforterait","word_nosc":"conforterait","lemma":"conforter","pos":"VER"} ,
		{"word":"conforté","word_nosc":"conforte","lemma":"conforter","pos":"VER"} ,
		{"word":"confortée","word_nosc":"confortee","lemma":"conforter","pos":"VER"} ,
		{"word":"confrontai","word_nosc":"confrontai","lemma":"confronter","pos":"VER"} ,
		{"word":"confrontaient","word_nosc":"confrontaient","lemma":"confronter","pos":"VER"} ,
		{"word":"confrontais","word_nosc":"confrontais","lemma":"confronter","pos":"VER"} ,
		{"word":"confrontait","word_nosc":"confrontait","lemma":"confronter","pos":"VER"} ,
		{"word":"confrontant","word_nosc":"confrontant","lemma":"confronter","pos":"VER"} ,
		{"word":"confronte","word_nosc":"confronte","lemma":"confronter","pos":"VER"} ,
		{"word":"confrontent","word_nosc":"confrontent","lemma":"confronter","pos":"VER"} ,
		{"word":"confronter","word_nosc":"confronter","lemma":"confronter","pos":"VER"} ,
		{"word":"confrontez","word_nosc":"confrontez","lemma":"confronter","pos":"VER"} ,
		{"word":"confrontons","word_nosc":"confrontons","lemma":"confronter","pos":"VER"} ,
		{"word":"confrontèrent","word_nosc":"confronterent","lemma":"confronter","pos":"VER"} ,
		{"word":"confronté","word_nosc":"confronte","lemma":"confronter","pos":"VER"} ,
		{"word":"confrontée","word_nosc":"confrontee","lemma":"confronter","pos":"VER"} ,
		{"word":"confrontées","word_nosc":"confrontees","lemma":"confronter","pos":"VER"} ,
		{"word":"confrontés","word_nosc":"confrontes","lemma":"confronter","pos":"VER"} ,
		{"word":"confère","word_nosc":"confere","lemma":"conférer","pos":"VER"} ,
		{"word":"confèrent","word_nosc":"conferent","lemma":"conférer","pos":"VER"} ,
		{"word":"confédérer","word_nosc":"confederer","lemma":"confédérer","pos":"VER"} ,
		{"word":"confédéré","word_nosc":"confedere","lemma":"confédérer","pos":"VER"} ,
		{"word":"confédérés","word_nosc":"confederes","lemma":"confédérer","pos":"VER"} ,
		{"word":"conféra","word_nosc":"confera","lemma":"conférer","pos":"VER"} ,
		{"word":"conféraient","word_nosc":"conferaient","lemma":"conférer","pos":"VER"} ,
		{"word":"conférais","word_nosc":"conferais","lemma":"conférer","pos":"VER"} ,
		{"word":"conférait","word_nosc":"conferait","lemma":"conférer","pos":"VER"} ,
		{"word":"conférant","word_nosc":"conferant","lemma":"conférer","pos":"VER"} ,
		{"word":"conférer","word_nosc":"conferer","lemma":"conférer","pos":"VER"} ,
		{"word":"conférera","word_nosc":"conferera","lemma":"conférer","pos":"VER"} ,
		{"word":"conféreraient","word_nosc":"confereraient","lemma":"conférer","pos":"VER"} ,
		{"word":"conférerait","word_nosc":"confererait","lemma":"conférer","pos":"VER"} ,
		{"word":"conférerez","word_nosc":"confererez","lemma":"conférer","pos":"VER"} ,
		{"word":"conféreront","word_nosc":"confereront","lemma":"conférer","pos":"VER"} ,
		{"word":"conférez","word_nosc":"conferez","lemma":"conférer","pos":"VER"} ,
		{"word":"conférât","word_nosc":"conferat","lemma":"conférer","pos":"VER"} ,
		{"word":"conférèrent","word_nosc":"confererent","lemma":"conférer","pos":"VER"} ,
		{"word":"conféré","word_nosc":"confere","lemma":"conférer","pos":"VER"} ,
		{"word":"conférée","word_nosc":"conferee","lemma":"conférer","pos":"VER"} ,
		{"word":"conférées","word_nosc":"conferees","lemma":"conférer","pos":"VER"} ,
		{"word":"conférés","word_nosc":"conferes","lemma":"conférer","pos":"VER"} ,
		{"word":"congela","word_nosc":"congela","lemma":"congeler","pos":"VER"} ,
		{"word":"congelait","word_nosc":"congelait","lemma":"congeler","pos":"VER"} ,
		{"word":"congelant","word_nosc":"congelant","lemma":"congeler","pos":"VER"} ,
		{"word":"congeler","word_nosc":"congeler","lemma":"congeler","pos":"VER"} ,
		{"word":"congelez","word_nosc":"congelez","lemma":"congeler","pos":"VER"} ,
		{"word":"congelons","word_nosc":"congelons","lemma":"congeler","pos":"VER"} ,
		{"word":"congelé","word_nosc":"congele","lemma":"congeler","pos":"VER"} ,
		{"word":"congelée","word_nosc":"congelee","lemma":"congeler","pos":"VER"} ,
		{"word":"congelées","word_nosc":"congelees","lemma":"congeler","pos":"VER"} ,
		{"word":"congelés","word_nosc":"congeles","lemma":"congeler","pos":"VER"} ,
		{"word":"congestionnaient","word_nosc":"congestionnaient","lemma":"congestionner","pos":"VER"} ,
		{"word":"congestionnait","word_nosc":"congestionnait","lemma":"congestionner","pos":"VER"} ,
		{"word":"congestionne","word_nosc":"congestionne","lemma":"congestionner","pos":"VER"} ,
		{"word":"congestionnent","word_nosc":"congestionnent","lemma":"congestionner","pos":"VER"} ,
		{"word":"congestionné","word_nosc":"congestionne","lemma":"congestionner","pos":"VER"} ,
		{"word":"congestionnée","word_nosc":"congestionnee","lemma":"congestionner","pos":"VER"} ,
		{"word":"congestionnés","word_nosc":"congestionnes","lemma":"congestionner","pos":"VER"} ,
		{"word":"conglomérée","word_nosc":"conglomeree","lemma":"conglomérer","pos":"VER"} ,
		{"word":"congratula","word_nosc":"congratula","lemma":"congratuler","pos":"VER"} ,
		{"word":"congratulaient","word_nosc":"congratulaient","lemma":"congratuler","pos":"VER"} ,
		{"word":"congratulait","word_nosc":"congratulait","lemma":"congratuler","pos":"VER"} ,
		{"word":"congratulant","word_nosc":"congratulant","lemma":"congratuler","pos":"VER"} ,
		{"word":"congratule","word_nosc":"congratule","lemma":"congratuler","pos":"VER"} ,
		{"word":"congratulent","word_nosc":"congratulent","lemma":"congratuler","pos":"VER"} ,
		{"word":"congratuler","word_nosc":"congratuler","lemma":"congratuler","pos":"VER"} ,
		{"word":"congratulions","word_nosc":"congratulions","lemma":"congratuler","pos":"VER"} ,
		{"word":"congratulâmes","word_nosc":"congratulames","lemma":"congratuler","pos":"VER"} ,
		{"word":"congratulèrent","word_nosc":"congratulerent","lemma":"congratuler","pos":"VER"} ,
		{"word":"congratulé","word_nosc":"congratule","lemma":"congratuler","pos":"VER"} ,
		{"word":"congratulés","word_nosc":"congratules","lemma":"congratuler","pos":"VER"} ,
		{"word":"congèle","word_nosc":"congele","lemma":"congeler","pos":"VER"} ,
		{"word":"congèlera","word_nosc":"congelera","lemma":"congeler","pos":"VER"} ,
		{"word":"congèlerais","word_nosc":"congelerais","lemma":"congeler","pos":"VER"} ,
		{"word":"congédia","word_nosc":"congedia","lemma":"congédier","pos":"VER"} ,
		{"word":"congédiai","word_nosc":"congediai","lemma":"congédier","pos":"VER"} ,
		{"word":"congédiait","word_nosc":"congediait","lemma":"congédier","pos":"VER"} ,
		{"word":"congédiant","word_nosc":"congediant","lemma":"congédier","pos":"VER"} ,
		{"word":"congédie","word_nosc":"congedie","lemma":"congédier","pos":"VER"} ,
		{"word":"congédient","word_nosc":"congedient","lemma":"congédier","pos":"VER"} ,
		{"word":"congédier","word_nosc":"congedier","lemma":"congédier","pos":"VER"} ,
		{"word":"congédiez","word_nosc":"congediez","lemma":"congédier","pos":"VER"} ,
		{"word":"congédié","word_nosc":"congedie","lemma":"congédier","pos":"VER"} ,
		{"word":"congédiée","word_nosc":"congediee","lemma":"congédier","pos":"VER"} ,
		{"word":"congédiées","word_nosc":"congediees","lemma":"congédier","pos":"VER"} ,
		{"word":"congédiés","word_nosc":"congedies","lemma":"congédier","pos":"VER"} ,
		{"word":"conjecturai","word_nosc":"conjecturai","lemma":"conjecturer","pos":"VER"} ,
		{"word":"conjecture","word_nosc":"conjecture","lemma":"conjecturer","pos":"VER"} ,
		{"word":"conjecturent","word_nosc":"conjecturent","lemma":"conjecturer","pos":"VER"} ,
		{"word":"conjecturer","word_nosc":"conjecturer","lemma":"conjecturer","pos":"VER"} ,
		{"word":"conjectures","word_nosc":"conjectures","lemma":"conjecturer","pos":"VER"} ,
		{"word":"conjoindre","word_nosc":"conjoindre","lemma":"conjoindre","pos":"VER"} ,
		{"word":"conjoint","word_nosc":"conjoint","lemma":"conjoindre","pos":"VER"} ,
		{"word":"conjoints","word_nosc":"conjoints","lemma":"conjoindre","pos":"VER"} ,
		{"word":"conjuguaient","word_nosc":"conjuguaient","lemma":"conjuguer","pos":"VER"} ,
		{"word":"conjuguait","word_nosc":"conjuguait","lemma":"conjuguer","pos":"VER"} ,
		{"word":"conjuguant","word_nosc":"conjuguant","lemma":"conjuguer","pos":"VER"} ,
		{"word":"conjugue","word_nosc":"conjugue","lemma":"conjuguer","pos":"VER"} ,
		{"word":"conjuguent","word_nosc":"conjuguent","lemma":"conjuguer","pos":"VER"} ,
		{"word":"conjuguer","word_nosc":"conjuguer","lemma":"conjuguer","pos":"VER"} ,
		{"word":"conjuguerions","word_nosc":"conjuguerions","lemma":"conjuguer","pos":"VER"} ,
		{"word":"conjugues","word_nosc":"conjugues","lemma":"conjuguer","pos":"VER"} ,
		{"word":"conjugué","word_nosc":"conjugue","lemma":"conjuguer","pos":"VER"} ,
		{"word":"conjuguée","word_nosc":"conjuguee","lemma":"conjuguer","pos":"VER"} ,
		{"word":"conjuguées","word_nosc":"conjuguees","lemma":"conjuguer","pos":"VER"} ,
		{"word":"conjugués","word_nosc":"conjugues","lemma":"conjuguer","pos":"VER"} ,
		{"word":"conjura","word_nosc":"conjura","lemma":"conjurer","pos":"VER"} ,
		{"word":"conjurait","word_nosc":"conjurait","lemma":"conjurer","pos":"VER"} ,
		{"word":"conjurant","word_nosc":"conjurant","lemma":"conjurer","pos":"VER"} ,
		{"word":"conjure","word_nosc":"conjure","lemma":"conjurer","pos":"VER"} ,
		{"word":"conjurer","word_nosc":"conjurer","lemma":"conjurer","pos":"VER"} ,
		{"word":"conjurera","word_nosc":"conjurera","lemma":"conjurer","pos":"VER"} ,
		{"word":"conjuré","word_nosc":"conjure","lemma":"conjurer","pos":"VER"} ,
		{"word":"conjurée","word_nosc":"conjuree","lemma":"conjurer","pos":"VER"} ,
		{"word":"conjurées","word_nosc":"conjurees","lemma":"conjurer","pos":"VER"} ,
		{"word":"conjurés","word_nosc":"conjures","lemma":"conjurer","pos":"VER"} ,
		{"word":"connais","word_nosc":"connais","lemma":"connais","pos":"VER"} ,
		{"word":"connais","word_nosc":"connais","lemma":"connaître","pos":"VER"} ,
		{"word":"connaissaient","word_nosc":"connaissaient","lemma":"connaître","pos":"VER"} ,
		{"word":"connaissais","word_nosc":"connaissais","lemma":"connaître","pos":"VER"} ,
		{"word":"connaissait","word_nosc":"connaissait","lemma":"connaître","pos":"VER"} ,
		{"word":"connaissant","word_nosc":"connaissant","lemma":"connaître","pos":"VER"} ,
		{"word":"connaisse","word_nosc":"connaisse","lemma":"connaître","pos":"VER"} ,
		{"word":"connaissent","word_nosc":"connaissent","lemma":"connaître","pos":"VER"} ,
		{"word":"connaisses","word_nosc":"connaisses","lemma":"connaître","pos":"VER"} ,
		{"word":"connaissez","word_nosc":"connaissez","lemma":"connaître","pos":"VER"} ,
		{"word":"connaissiez","word_nosc":"connaissiez","lemma":"connaître","pos":"VER"} ,
		{"word":"connaissions","word_nosc":"connaissions","lemma":"connaître","pos":"VER"} ,
		{"word":"connaissons","word_nosc":"connaissons","lemma":"connaître","pos":"VER"} ,
		{"word":"connaît","word_nosc":"connait","lemma":"connaître","pos":"VER"} ,
		{"word":"connaîtra","word_nosc":"connaitra","lemma":"connaître","pos":"VER"} ,
		{"word":"connaîtrai","word_nosc":"connaitrai","lemma":"connaître","pos":"VER"} ,
		{"word":"connaîtraient","word_nosc":"connaitraient","lemma":"connaître","pos":"VER"} ,
		{"word":"connaîtrais","word_nosc":"connaitrais","lemma":"connaître","pos":"VER"} ,
		{"word":"connaîtrait","word_nosc":"connaitrait","lemma":"connaître","pos":"VER"} ,
		{"word":"connaîtras","word_nosc":"connaitras","lemma":"connaître","pos":"VER"} ,
		{"word":"connaître","word_nosc":"connaitre","lemma":"connaître","pos":"VER"} ,
		{"word":"connaîtrez","word_nosc":"connaitrez","lemma":"connaître","pos":"VER"} ,
		{"word":"connaîtriez","word_nosc":"connaitriez","lemma":"connaître","pos":"VER"} ,
		{"word":"connaîtrions","word_nosc":"connaitrions","lemma":"connaître","pos":"VER"} ,
		{"word":"connaîtrons","word_nosc":"connaitrons","lemma":"connaître","pos":"VER"} ,
		{"word":"connaîtront","word_nosc":"connaitront","lemma":"connaître","pos":"VER"} ,
		{"word":"connectant","word_nosc":"connectant","lemma":"connecter","pos":"VER"} ,
		{"word":"connecte","word_nosc":"connecte","lemma":"connecter","pos":"VER"} ,
		{"word":"connectent","word_nosc":"connectent","lemma":"connecter","pos":"VER"} ,
		{"word":"connecter","word_nosc":"connecter","lemma":"connecter","pos":"VER"} ,
		{"word":"connectera","word_nosc":"connectera","lemma":"connecter","pos":"VER"} ,
		{"word":"connectez","word_nosc":"connectez","lemma":"connecter","pos":"VER"} ,
		{"word":"connectiez","word_nosc":"connectiez","lemma":"connecter","pos":"VER"} ,
		{"word":"connections","word_nosc":"connections","lemma":"connecter","pos":"VER"} ,
		{"word":"connectons","word_nosc":"connectons","lemma":"connecter","pos":"VER"} ,
		{"word":"connecté","word_nosc":"connecte","lemma":"connecter","pos":"VER"} ,
		{"word":"connectée","word_nosc":"connectee","lemma":"connecter","pos":"VER"} ,
		{"word":"connectées","word_nosc":"connectees","lemma":"connecter","pos":"VER"} ,
		{"word":"connectés","word_nosc":"connectes","lemma":"connecter","pos":"VER"} ,
		{"word":"connu","word_nosc":"connu","lemma":"connaître","pos":"VER"} ,
		{"word":"connue","word_nosc":"connue","lemma":"connaître","pos":"VER"} ,
		{"word":"connues","word_nosc":"connues","lemma":"connaître","pos":"VER"} ,
		{"word":"connurent","word_nosc":"connurent","lemma":"connaître","pos":"VER"} ,
		{"word":"connus","word_nosc":"connus","lemma":"connaître","pos":"VER"} ,
		{"word":"connusse","word_nosc":"connusse","lemma":"connaître","pos":"VER"} ,
		{"word":"connussent","word_nosc":"connussent","lemma":"connaître","pos":"VER"} ,
		{"word":"connusses","word_nosc":"connusses","lemma":"connaître","pos":"VER"} ,
		{"word":"connut","word_nosc":"connut","lemma":"connaître","pos":"VER"} ,
		{"word":"connûmes","word_nosc":"connumes","lemma":"connaître","pos":"VER"} ,
		{"word":"connût","word_nosc":"connut","lemma":"connaître","pos":"VER"} ,
		{"word":"conquerra","word_nosc":"conquerra","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquerraient","word_nosc":"conquerraient","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquerrons","word_nosc":"conquerrons","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquerront","word_nosc":"conquerront","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquiers","word_nosc":"conquiers","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquiert","word_nosc":"conquiert","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquirent","word_nosc":"conquirent","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquis","word_nosc":"conquis","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquise","word_nosc":"conquise","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquises","word_nosc":"conquises","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquit","word_nosc":"conquit","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquière","word_nosc":"conquiere","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquièrent","word_nosc":"conquierent","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquérait","word_nosc":"conquerait","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquérant","word_nosc":"conquerant","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquérez","word_nosc":"conquerez","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquérir","word_nosc":"conquerir","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquérons","word_nosc":"conquerons","lemma":"conquérir","pos":"VER"} ,
		{"word":"conquît","word_nosc":"conquit","lemma":"conquérir","pos":"VER"} ,
		{"word":"consacra","word_nosc":"consacra","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrai","word_nosc":"consacrai","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacraient","word_nosc":"consacraient","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrais","word_nosc":"consacrais","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrait","word_nosc":"consacrait","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrant","word_nosc":"consacrant","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacre","word_nosc":"consacre","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrent","word_nosc":"consacrent","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrer","word_nosc":"consacrer","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrera","word_nosc":"consacrera","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrerai","word_nosc":"consacrerai","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacreraient","word_nosc":"consacreraient","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrerais","word_nosc":"consacrerais","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrerait","word_nosc":"consacrerait","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacreras","word_nosc":"consacreras","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrerez","word_nosc":"consacrerez","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacreriez","word_nosc":"consacreriez","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrerions","word_nosc":"consacrerions","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacreront","word_nosc":"consacreront","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacres","word_nosc":"consacres","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrez","word_nosc":"consacrez","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacriez","word_nosc":"consacriez","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrons","word_nosc":"consacrons","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrèrent","word_nosc":"consacrerent","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacré","word_nosc":"consacre","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrée","word_nosc":"consacree","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrées","word_nosc":"consacrees","lemma":"consacrer","pos":"VER"} ,
		{"word":"consacrés","word_nosc":"consacres","lemma":"consacrer","pos":"VER"} ,
		{"word":"conseilla","word_nosc":"conseilla","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillai","word_nosc":"conseillai","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillaient","word_nosc":"conseillaient","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillais","word_nosc":"conseillais","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillait","word_nosc":"conseillait","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillant","word_nosc":"conseillant","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseille","word_nosc":"conseille","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillent","word_nosc":"conseillent","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseiller","word_nosc":"conseiller","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillera","word_nosc":"conseillera","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillerai","word_nosc":"conseillerai","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillerais","word_nosc":"conseillerais","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillerait","word_nosc":"conseillerait","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillerez","word_nosc":"conseillerez","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseilleriez","word_nosc":"conseilleriez","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseilles","word_nosc":"conseilles","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillez","word_nosc":"conseillez","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillons","word_nosc":"conseillons","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillèrent","word_nosc":"conseillerent","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillé","word_nosc":"conseille","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillée","word_nosc":"conseillee","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillées","word_nosc":"conseillees","lemma":"conseiller","pos":"VER"} ,
		{"word":"conseillés","word_nosc":"conseilles","lemma":"conseiller","pos":"VER"} ,
		{"word":"consens","word_nosc":"consens","lemma":"consentir","pos":"VER"} ,
		{"word":"consent","word_nosc":"consent","lemma":"consentir","pos":"VER"} ,
		{"word":"consentaient","word_nosc":"consentaient","lemma":"consentir","pos":"VER"} ,
		{"word":"consentais","word_nosc":"consentais","lemma":"consentir","pos":"VER"} ,
		{"word":"consentait","word_nosc":"consentait","lemma":"consentir","pos":"VER"} ,
		{"word":"consentant","word_nosc":"consentant","lemma":"consentir","pos":"VER"} ,
		{"word":"consente","word_nosc":"consente","lemma":"consentir","pos":"VER"} ,
		{"word":"consentent","word_nosc":"consentent","lemma":"consentir","pos":"VER"} ,
		{"word":"consentes","word_nosc":"consentes","lemma":"consentir","pos":"VER"} ,
		{"word":"consentez","word_nosc":"consentez","lemma":"consentir","pos":"VER"} ,
		{"word":"consenti","word_nosc":"consenti","lemma":"consentir","pos":"VER"} ,
		{"word":"consentie","word_nosc":"consentie","lemma":"consentir","pos":"VER"} ,
		{"word":"consenties","word_nosc":"consenties","lemma":"consentir","pos":"VER"} ,
		{"word":"consentiez","word_nosc":"consentiez","lemma":"consentir","pos":"VER"} ,
		{"word":"consentions","word_nosc":"consentions","lemma":"consentir","pos":"VER"} ,
		{"word":"consentir","word_nosc":"consentir","lemma":"consentir","pos":"VER"} ,
		{"word":"consentira","word_nosc":"consentira","lemma":"consentir","pos":"VER"} ,
		{"word":"consentirai","word_nosc":"consentirai","lemma":"consentir","pos":"VER"} ,
		{"word":"consentiraient","word_nosc":"consentiraient","lemma":"consentir","pos":"VER"} ,
		{"word":"consentirais","word_nosc":"consentirais","lemma":"consentir","pos":"VER"} ,
		{"word":"consentirait","word_nosc":"consentirait","lemma":"consentir","pos":"VER"} ,
		{"word":"consentiras","word_nosc":"consentiras","lemma":"consentir","pos":"VER"} ,
		{"word":"consentirent","word_nosc":"consentirent","lemma":"consentir","pos":"VER"} ,
		{"word":"consentirez","word_nosc":"consentirez","lemma":"consentir","pos":"VER"} ,
		{"word":"consentiriez","word_nosc":"consentiriez","lemma":"consentir","pos":"VER"} ,
		{"word":"consentirions","word_nosc":"consentirions","lemma":"consentir","pos":"VER"} ,
		{"word":"consentiront","word_nosc":"consentiront","lemma":"consentir","pos":"VER"} ,
		{"word":"consentis","word_nosc":"consentis","lemma":"consentir","pos":"VER"} ,
		{"word":"consentisse","word_nosc":"consentisse","lemma":"consentir","pos":"VER"} ,
		{"word":"consentissent","word_nosc":"consentissent","lemma":"consentir","pos":"VER"} ,
		{"word":"consentit","word_nosc":"consentit","lemma":"consentir","pos":"VER"} ,
		{"word":"consentons","word_nosc":"consentons","lemma":"consentir","pos":"VER"} ,
		{"word":"consentît","word_nosc":"consentit","lemma":"consentir","pos":"VER"} ,
		{"word":"conserva","word_nosc":"conserva","lemma":"conserver","pos":"VER"} ,
		{"word":"conservai","word_nosc":"conservai","lemma":"conserver","pos":"VER"} ,
		{"word":"conservaient","word_nosc":"conservaient","lemma":"conserver","pos":"VER"} ,
		{"word":"conservais","word_nosc":"conservais","lemma":"conserver","pos":"VER"} ,
		{"word":"conservait","word_nosc":"conservait","lemma":"conserver","pos":"VER"} ,
		{"word":"conservant","word_nosc":"conservant","lemma":"conserver","pos":"VER"} ,
		{"word":"conserve","word_nosc":"conserve","lemma":"conserver","pos":"VER"} ,
		{"word":"conservent","word_nosc":"conservent","lemma":"conserver","pos":"VER"} ,
		{"word":"conserver","word_nosc":"conserver","lemma":"conserver","pos":"VER"} ,
		{"word":"conservera","word_nosc":"conservera","lemma":"conserver","pos":"VER"} ,
		{"word":"conserverai","word_nosc":"conserverai","lemma":"conserver","pos":"VER"} ,
		{"word":"conserveraient","word_nosc":"conserveraient","lemma":"conserver","pos":"VER"} ,
		{"word":"conserverais","word_nosc":"conserverais","lemma":"conserver","pos":"VER"} ,
		{"word":"conserverait","word_nosc":"conserverait","lemma":"conserver","pos":"VER"} ,
		{"word":"conserveras","word_nosc":"conserveras","lemma":"conserver","pos":"VER"} ,
		{"word":"conserverez","word_nosc":"conserverez","lemma":"conserver","pos":"VER"} ,
		{"word":"conserverons","word_nosc":"conserverons","lemma":"conserver","pos":"VER"} ,
		{"word":"conserveront","word_nosc":"conserveront","lemma":"conserver","pos":"VER"} ,
		{"word":"conserves","word_nosc":"conserves","lemma":"conserver","pos":"VER"} ,
		{"word":"conservez","word_nosc":"conservez","lemma":"conserver","pos":"VER"} ,
		{"word":"conserviez","word_nosc":"conserviez","lemma":"conserver","pos":"VER"} ,
		{"word":"conservions","word_nosc":"conservions","lemma":"conserver","pos":"VER"} ,
		{"word":"conservons","word_nosc":"conservons","lemma":"conserver","pos":"VER"} ,
		{"word":"conservât","word_nosc":"conservat","lemma":"conserver","pos":"VER"} ,
		{"word":"conservèrent","word_nosc":"conserverent","lemma":"conserver","pos":"VER"} ,
		{"word":"conservé","word_nosc":"conserve","lemma":"conserver","pos":"VER"} ,
		{"word":"conservée","word_nosc":"conservee","lemma":"conserver","pos":"VER"} ,
		{"word":"conservées","word_nosc":"conservees","lemma":"conserver","pos":"VER"} ,
		{"word":"conservés","word_nosc":"conserves","lemma":"conserver","pos":"VER"} ,
		{"word":"considère","word_nosc":"considere","lemma":"considérer","pos":"VER"} ,
		{"word":"considèrent","word_nosc":"considerent","lemma":"considérer","pos":"VER"} ,
		{"word":"considères","word_nosc":"consideres","lemma":"considérer","pos":"VER"} ,
		{"word":"considéra","word_nosc":"considera","lemma":"considérer","pos":"VER"} ,
		{"word":"considérai","word_nosc":"considerai","lemma":"considérer","pos":"VER"} ,
		{"word":"considéraient","word_nosc":"consideraient","lemma":"considérer","pos":"VER"} ,
		{"word":"considérais","word_nosc":"considerais","lemma":"considérer","pos":"VER"} ,
		{"word":"considérait","word_nosc":"considerait","lemma":"considérer","pos":"VER"} ,
		{"word":"considérant","word_nosc":"considerant","lemma":"considérer","pos":"VER"} ,
		{"word":"considérassent","word_nosc":"considerassent","lemma":"considérer","pos":"VER"} ,
		{"word":"considérer","word_nosc":"considerer","lemma":"considérer","pos":"VER"} ,
		{"word":"considérera","word_nosc":"considerera","lemma":"considérer","pos":"VER"} ,
		{"word":"considérerai","word_nosc":"considererai","lemma":"considérer","pos":"VER"} ,
		{"word":"considéreraient","word_nosc":"considereraient","lemma":"considérer","pos":"VER"} ,
		{"word":"considérerais","word_nosc":"considererais","lemma":"considérer","pos":"VER"} ,
		{"word":"considérerait","word_nosc":"considererait","lemma":"considérer","pos":"VER"} ,
		{"word":"considéreras","word_nosc":"considereras","lemma":"considérer","pos":"VER"} ,
		{"word":"considéreriez","word_nosc":"considereriez","lemma":"considérer","pos":"VER"} ,
		{"word":"considérerions","word_nosc":"considererions","lemma":"considérer","pos":"VER"} ,
		{"word":"considérerons","word_nosc":"considererons","lemma":"considérer","pos":"VER"} ,
		{"word":"considérez","word_nosc":"considerez","lemma":"considérer","pos":"VER"} ,
		{"word":"considériez","word_nosc":"consideriez","lemma":"considérer","pos":"VER"} ,
		{"word":"considérions","word_nosc":"considerions","lemma":"considérer","pos":"VER"} ,
		{"word":"considérons","word_nosc":"considerons","lemma":"considérer","pos":"VER"} ,
		{"word":"considérât","word_nosc":"considerat","lemma":"considérer","pos":"VER"} ,
		{"word":"considérèrent","word_nosc":"considererent","lemma":"considérer","pos":"VER"} ,
		{"word":"considéré","word_nosc":"considere","lemma":"considérer","pos":"VER"} ,
		{"word":"considérée","word_nosc":"consideree","lemma":"considérer","pos":"VER"} ,
		{"word":"considérées","word_nosc":"considerees","lemma":"considérer","pos":"VER"} ,
		{"word":"considérés","word_nosc":"consideres","lemma":"considérer","pos":"VER"} ,
		{"word":"consigna","word_nosc":"consigna","lemma":"consigner","pos":"VER"} ,
		{"word":"consignais","word_nosc":"consignais","lemma":"consigner","pos":"VER"} ,
		{"word":"consignait","word_nosc":"consignait","lemma":"consigner","pos":"VER"} ,
		{"word":"consignant","word_nosc":"consignant","lemma":"consigner","pos":"VER"} ,
		{"word":"consigne","word_nosc":"consigne","lemma":"consigner","pos":"VER"} ,
		{"word":"consignent","word_nosc":"consignent","lemma":"consigner","pos":"VER"} ,
		{"word":"consigner","word_nosc":"consigner","lemma":"consigner","pos":"VER"} ,
		{"word":"consignera","word_nosc":"consignera","lemma":"consigner","pos":"VER"} ,
		{"word":"consignerais","word_nosc":"consignerais","lemma":"consigner","pos":"VER"} ,
		{"word":"consignes","word_nosc":"consignes","lemma":"consigner","pos":"VER"} ,
		{"word":"consignez","word_nosc":"consignez","lemma":"consigner","pos":"VER"} ,
		{"word":"consigné","word_nosc":"consigne","lemma":"consigner","pos":"VER"} ,
		{"word":"consignée","word_nosc":"consignee","lemma":"consigner","pos":"VER"} ,
		{"word":"consignées","word_nosc":"consignees","lemma":"consigner","pos":"VER"} ,
		{"word":"consignés","word_nosc":"consignes","lemma":"consigner","pos":"VER"} ,
		{"word":"consista","word_nosc":"consista","lemma":"consister","pos":"VER"} ,
		{"word":"consistaient","word_nosc":"consistaient","lemma":"consister","pos":"VER"} ,
		{"word":"consistait","word_nosc":"consistait","lemma":"consister","pos":"VER"} ,
		{"word":"consistant","word_nosc":"consistant","lemma":"consister","pos":"VER"} ,
		{"word":"consiste","word_nosc":"consiste","lemma":"consister","pos":"VER"} ,
		{"word":"consistent","word_nosc":"consistent","lemma":"consister","pos":"VER"} ,
		{"word":"consister","word_nosc":"consister","lemma":"consister","pos":"VER"} ,
		{"word":"consistera","word_nosc":"consistera","lemma":"consister","pos":"VER"} ,
		{"word":"consisteraient","word_nosc":"consisteraient","lemma":"consister","pos":"VER"} ,
		{"word":"consisterait","word_nosc":"consisterait","lemma":"consister","pos":"VER"} ,
		{"word":"consistèrent","word_nosc":"consisterent","lemma":"consister","pos":"VER"} ,
		{"word":"consisté","word_nosc":"consiste","lemma":"consister","pos":"VER"} ,
		{"word":"consola","word_nosc":"consola","lemma":"consoler","pos":"VER"} ,
		{"word":"consolai","word_nosc":"consolai","lemma":"consoler","pos":"VER"} ,
		{"word":"consolaient","word_nosc":"consolaient","lemma":"consoler","pos":"VER"} ,
		{"word":"consolais","word_nosc":"consolais","lemma":"consoler","pos":"VER"} ,
		{"word":"consolait","word_nosc":"consolait","lemma":"consoler","pos":"VER"} ,
		{"word":"consolant","word_nosc":"consolant","lemma":"consoler","pos":"VER"} ,
		{"word":"consolasse","word_nosc":"consolasse","lemma":"consoler","pos":"VER"} ,
		{"word":"console","word_nosc":"console","lemma":"consoler","pos":"VER"} ,
		{"word":"consolent","word_nosc":"consolent","lemma":"consoler","pos":"VER"} ,
		{"word":"consoler","word_nosc":"consoler","lemma":"consoler","pos":"VER"} ,
		{"word":"consolera","word_nosc":"consolera","lemma":"consoler","pos":"VER"} ,
		{"word":"consolerai","word_nosc":"consolerai","lemma":"consoler","pos":"VER"} ,
		{"word":"consoleraient","word_nosc":"consoleraient","lemma":"consoler","pos":"VER"} ,
		{"word":"consolerais","word_nosc":"consolerais","lemma":"consoler","pos":"VER"} ,
		{"word":"consolerait","word_nosc":"consolerait","lemma":"consoler","pos":"VER"} ,
		{"word":"consoleras","word_nosc":"consoleras","lemma":"consoler","pos":"VER"} ,
		{"word":"consolerez","word_nosc":"consolerez","lemma":"consoler","pos":"VER"} ,
		{"word":"consolerons","word_nosc":"consolerons","lemma":"consoler","pos":"VER"} ,
		{"word":"consoleront","word_nosc":"consoleront","lemma":"consoler","pos":"VER"} ,
		{"word":"consoles","word_nosc":"consoles","lemma":"consoler","pos":"VER"} ,
		{"word":"consolez","word_nosc":"consolez","lemma":"consoler","pos":"VER"} ,
		{"word":"consolidaient","word_nosc":"consolidaient","lemma":"consolider","pos":"VER"} ,
		{"word":"consolidais","word_nosc":"consolidais","lemma":"consolider","pos":"VER"} ,
		{"word":"consolidait","word_nosc":"consolidait","lemma":"consolider","pos":"VER"} ,
		{"word":"consolidant","word_nosc":"consolidant","lemma":"consolider","pos":"VER"} ,
		{"word":"consolide","word_nosc":"consolide","lemma":"consolider","pos":"VER"} ,
		{"word":"consolident","word_nosc":"consolident","lemma":"consolider","pos":"VER"} ,
		{"word":"consolider","word_nosc":"consolider","lemma":"consolider","pos":"VER"} ,
		{"word":"consolideraient","word_nosc":"consolideraient","lemma":"consolider","pos":"VER"} ,
		{"word":"consolidons","word_nosc":"consolidons","lemma":"consolider","pos":"VER"} ,
		{"word":"consolidé","word_nosc":"consolide","lemma":"consolider","pos":"VER"} ,
		{"word":"consolidée","word_nosc":"consolidee","lemma":"consolider","pos":"VER"} ,
		{"word":"consolidées","word_nosc":"consolidees","lemma":"consolider","pos":"VER"} ,
		{"word":"consolidés","word_nosc":"consolides","lemma":"consolider","pos":"VER"} ,
		{"word":"consoliez","word_nosc":"consoliez","lemma":"consoler","pos":"VER"} ,
		{"word":"consolions","word_nosc":"consolions","lemma":"consoler","pos":"VER"} ,
		{"word":"consolât","word_nosc":"consolat","lemma":"consoler","pos":"VER"} ,
		{"word":"consolèrent","word_nosc":"consolerent","lemma":"consoler","pos":"VER"} ,
		{"word":"consolé","word_nosc":"console","lemma":"consoler","pos":"VER"} ,
		{"word":"consolée","word_nosc":"consolee","lemma":"consoler","pos":"VER"} ,
		{"word":"consolées","word_nosc":"consolees","lemma":"consoler","pos":"VER"} ,
		{"word":"consolés","word_nosc":"consoles","lemma":"consoler","pos":"VER"} ,
		{"word":"consomma","word_nosc":"consomma","lemma":"consommer","pos":"VER"} ,
		{"word":"consommai","word_nosc":"consommai","lemma":"consommer","pos":"VER"} ,
		{"word":"consommaient","word_nosc":"consommaient","lemma":"consommer","pos":"VER"} ,
		{"word":"consommait","word_nosc":"consommait","lemma":"consommer","pos":"VER"} ,
		{"word":"consommant","word_nosc":"consommant","lemma":"consommer","pos":"VER"} ,
		{"word":"consomme","word_nosc":"consomme","lemma":"consommer","pos":"VER"} ,
		{"word":"consomment","word_nosc":"consomment","lemma":"consommer","pos":"VER"} ,
		{"word":"consommer","word_nosc":"consommer","lemma":"consommer","pos":"VER"} ,
		{"word":"consommerait","word_nosc":"consommerait","lemma":"consommer","pos":"VER"} ,
		{"word":"consommeront","word_nosc":"consommeront","lemma":"consommer","pos":"VER"} ,
		{"word":"consommez","word_nosc":"consommez","lemma":"consommer","pos":"VER"} ,
		{"word":"consommions","word_nosc":"consommions","lemma":"consommer","pos":"VER"} ,
		{"word":"consommons","word_nosc":"consommons","lemma":"consommer","pos":"VER"} ,
		{"word":"consommé","word_nosc":"consomme","lemma":"consommer","pos":"VER"} ,
		{"word":"consommée","word_nosc":"consommee","lemma":"consommer","pos":"VER"} ,
		{"word":"consommées","word_nosc":"consommees","lemma":"consommer","pos":"VER"} ,
		{"word":"consommés","word_nosc":"consommes","lemma":"consommer","pos":"VER"} ,
		{"word":"conspiraient","word_nosc":"conspiraient","lemma":"conspirer","pos":"VER"} ,
		{"word":"conspirait","word_nosc":"conspirait","lemma":"conspirer","pos":"VER"} ,
		{"word":"conspirant","word_nosc":"conspirant","lemma":"conspirer","pos":"VER"} ,
		{"word":"conspire","word_nosc":"conspire","lemma":"conspirer","pos":"VER"} ,
		{"word":"conspirent","word_nosc":"conspirent","lemma":"conspirer","pos":"VER"} ,
		{"word":"conspirer","word_nosc":"conspirer","lemma":"conspirer","pos":"VER"} ,
		{"word":"conspirez","word_nosc":"conspirez","lemma":"conspirer","pos":"VER"} ,
		{"word":"conspiré","word_nosc":"conspire","lemma":"conspirer","pos":"VER"} ,
		{"word":"conspuaient","word_nosc":"conspuaient","lemma":"conspuer","pos":"VER"} ,
		{"word":"conspuant","word_nosc":"conspuant","lemma":"conspuer","pos":"VER"} ,
		{"word":"conspue","word_nosc":"conspue","lemma":"conspuer","pos":"VER"} ,
		{"word":"conspuer","word_nosc":"conspuer","lemma":"conspuer","pos":"VER"} ,
		{"word":"conspuèrent","word_nosc":"conspuerent","lemma":"conspuer","pos":"VER"} ,
		{"word":"conspué","word_nosc":"conspue","lemma":"conspuer","pos":"VER"} ,
		{"word":"conspués","word_nosc":"conspues","lemma":"conspuer","pos":"VER"} ,
		{"word":"constata","word_nosc":"constata","lemma":"constater","pos":"VER"} ,
		{"word":"constatai","word_nosc":"constatai","lemma":"constater","pos":"VER"} ,
		{"word":"constataient","word_nosc":"constataient","lemma":"constater","pos":"VER"} ,
		{"word":"constatais","word_nosc":"constatais","lemma":"constater","pos":"VER"} ,
		{"word":"constatait","word_nosc":"constatait","lemma":"constater","pos":"VER"} ,
		{"word":"constatant","word_nosc":"constatant","lemma":"constater","pos":"VER"} ,
		{"word":"constate","word_nosc":"constate","lemma":"constater","pos":"VER"} ,
		{"word":"constatent","word_nosc":"constatent","lemma":"constater","pos":"VER"} ,
		{"word":"constater","word_nosc":"constater","lemma":"constater","pos":"VER"} ,
		{"word":"constatera","word_nosc":"constatera","lemma":"constater","pos":"VER"} ,
		{"word":"constaterai","word_nosc":"constaterai","lemma":"constater","pos":"VER"} ,
		{"word":"constateraient","word_nosc":"constateraient","lemma":"constater","pos":"VER"} ,
		{"word":"constaterais","word_nosc":"constaterais","lemma":"constater","pos":"VER"} ,
		{"word":"constaterait","word_nosc":"constaterait","lemma":"constater","pos":"VER"} ,
		{"word":"constateras","word_nosc":"constateras","lemma":"constater","pos":"VER"} ,
		{"word":"constaterez","word_nosc":"constaterez","lemma":"constater","pos":"VER"} ,
		{"word":"constateriez","word_nosc":"constateriez","lemma":"constater","pos":"VER"} ,
		{"word":"constatez","word_nosc":"constatez","lemma":"constater","pos":"VER"} ,
		{"word":"constatiez","word_nosc":"constatiez","lemma":"constater","pos":"VER"} ,
		{"word":"constations","word_nosc":"constations","lemma":"constater","pos":"VER"} ,
		{"word":"constatons","word_nosc":"constatons","lemma":"constater","pos":"VER"} ,
		{"word":"constatâmes","word_nosc":"constatames","lemma":"constater","pos":"VER"} ,
		{"word":"constatèrent","word_nosc":"constaterent","lemma":"constater","pos":"VER"} ,
		{"word":"constaté","word_nosc":"constate","lemma":"constater","pos":"VER"} ,
		{"word":"constatée","word_nosc":"constatee","lemma":"constater","pos":"VER"} ,
		{"word":"constatées","word_nosc":"constatees","lemma":"constater","pos":"VER"} ,
		{"word":"constatés","word_nosc":"constates","lemma":"constater","pos":"VER"} ,
		{"word":"constellaient","word_nosc":"constellaient","lemma":"consteller","pos":"VER"} ,
		{"word":"constellait","word_nosc":"constellait","lemma":"consteller","pos":"VER"} ,
		{"word":"constellant","word_nosc":"constellant","lemma":"consteller","pos":"VER"} ,
		{"word":"constelle","word_nosc":"constelle","lemma":"consteller","pos":"VER"} ,
		{"word":"constellent","word_nosc":"constellent","lemma":"consteller","pos":"VER"} ,
		{"word":"constellé","word_nosc":"constelle","lemma":"consteller","pos":"VER"} ,
		{"word":"constellée","word_nosc":"constellee","lemma":"consteller","pos":"VER"} ,
		{"word":"constellées","word_nosc":"constellees","lemma":"consteller","pos":"VER"} ,
		{"word":"constellés","word_nosc":"constelles","lemma":"consteller","pos":"VER"} ,
		{"word":"consterna","word_nosc":"consterna","lemma":"consterner","pos":"VER"} ,
		{"word":"consternait","word_nosc":"consternait","lemma":"consterner","pos":"VER"} ,
		{"word":"consternant","word_nosc":"consternant","lemma":"consterner","pos":"VER"} ,
		{"word":"consterne","word_nosc":"consterne","lemma":"consterner","pos":"VER"} ,
		{"word":"consternent","word_nosc":"consternent","lemma":"consterner","pos":"VER"} ,
		{"word":"consterné","word_nosc":"consterne","lemma":"consterner","pos":"VER"} ,
		{"word":"consternée","word_nosc":"consternee","lemma":"consterner","pos":"VER"} ,
		{"word":"consternées","word_nosc":"consternees","lemma":"consterner","pos":"VER"} ,
		{"word":"consternés","word_nosc":"consternes","lemma":"consterner","pos":"VER"} ,
		{"word":"constipe","word_nosc":"constipe","lemma":"constiper","pos":"VER"} ,
		{"word":"constipé","word_nosc":"constipe","lemma":"constiper","pos":"VER"} ,
		{"word":"constipée","word_nosc":"constipee","lemma":"constiper","pos":"VER"} ,
		{"word":"constipés","word_nosc":"constipes","lemma":"constiper","pos":"VER"} ,
		{"word":"constitua","word_nosc":"constitua","lemma":"constituer","pos":"VER"} ,
		{"word":"constituaient","word_nosc":"constituaient","lemma":"constituer","pos":"VER"} ,
		{"word":"constituais","word_nosc":"constituais","lemma":"constituer","pos":"VER"} ,
		{"word":"constituait","word_nosc":"constituait","lemma":"constituer","pos":"VER"} ,
		{"word":"constituant","word_nosc":"constituant","lemma":"constituer","pos":"VER"} ,
		{"word":"constitue","word_nosc":"constitue","lemma":"constituer","pos":"VER"} ,
		{"word":"constituent","word_nosc":"constituent","lemma":"constituer","pos":"VER"} ,
		{"word":"constituer","word_nosc":"constituer","lemma":"constituer","pos":"VER"} ,
		{"word":"constituera","word_nosc":"constituera","lemma":"constituer","pos":"VER"} ,
		{"word":"constituerai","word_nosc":"constituerai","lemma":"constituer","pos":"VER"} ,
		{"word":"constitueraient","word_nosc":"constitueraient","lemma":"constituer","pos":"VER"} ,
		{"word":"constituerais","word_nosc":"constituerais","lemma":"constituer","pos":"VER"} ,
		{"word":"constituerait","word_nosc":"constituerait","lemma":"constituer","pos":"VER"} ,
		{"word":"constitueras","word_nosc":"constitueras","lemma":"constituer","pos":"VER"} ,
		{"word":"constitueront","word_nosc":"constitueront","lemma":"constituer","pos":"VER"} ,
		{"word":"constituez","word_nosc":"constituez","lemma":"constituer","pos":"VER"} ,
		{"word":"constituions","word_nosc":"constituions","lemma":"constituer","pos":"VER"} ,
		{"word":"constituons","word_nosc":"constituons","lemma":"constituer","pos":"VER"} ,
		{"word":"constituât","word_nosc":"constituat","lemma":"constituer","pos":"VER"} ,
		{"word":"constituèrent","word_nosc":"constituerent","lemma":"constituer","pos":"VER"} ,
		{"word":"constitué","word_nosc":"constitue","lemma":"constituer","pos":"VER"} ,
		{"word":"constituée","word_nosc":"constituee","lemma":"constituer","pos":"VER"} ,
		{"word":"constituées","word_nosc":"constituees","lemma":"constituer","pos":"VER"} ,
		{"word":"constitués","word_nosc":"constitues","lemma":"constituer","pos":"VER"} ,
		{"word":"construira","word_nosc":"construira","lemma":"construire","pos":"VER"} ,
		{"word":"construirai","word_nosc":"construirai","lemma":"construire","pos":"VER"} ,
		{"word":"construiraient","word_nosc":"construiraient","lemma":"construire","pos":"VER"} ,
		{"word":"construirais","word_nosc":"construirais","lemma":"construire","pos":"VER"} ,
		{"word":"construirait","word_nosc":"construirait","lemma":"construire","pos":"VER"} ,
		{"word":"construiras","word_nosc":"construiras","lemma":"construire","pos":"VER"} ,
		{"word":"construire","word_nosc":"construire","lemma":"construire","pos":"VER"} ,
		{"word":"construirez","word_nosc":"construirez","lemma":"construire","pos":"VER"} ,
		{"word":"construiriez","word_nosc":"construiriez","lemma":"construire","pos":"VER"} ,
		{"word":"construirons","word_nosc":"construirons","lemma":"construire","pos":"VER"} ,
		{"word":"construiront","word_nosc":"construiront","lemma":"construire","pos":"VER"} ,
		{"word":"construis","word_nosc":"construis","lemma":"construire","pos":"VER"} ,
		{"word":"construisaient","word_nosc":"construisaient","lemma":"construire","pos":"VER"} ,
		{"word":"construisais","word_nosc":"construisais","lemma":"construire","pos":"VER"} ,
		{"word":"construisait","word_nosc":"construisait","lemma":"construire","pos":"VER"} ,
		{"word":"construisant","word_nosc":"construisant","lemma":"construire","pos":"VER"} ,
		{"word":"construise","word_nosc":"construise","lemma":"construire","pos":"VER"} ,
		{"word":"construisent","word_nosc":"construisent","lemma":"construire","pos":"VER"} ,
		{"word":"construisez","word_nosc":"construisez","lemma":"construire","pos":"VER"} ,
		{"word":"construisiez","word_nosc":"construisiez","lemma":"construire","pos":"VER"} ,
		{"word":"construisions","word_nosc":"construisions","lemma":"construire","pos":"VER"} ,
		{"word":"construisirent","word_nosc":"construisirent","lemma":"construire","pos":"VER"} ,
		{"word":"construisis","word_nosc":"construisis","lemma":"construire","pos":"VER"} ,
		{"word":"construisit","word_nosc":"construisit","lemma":"construire","pos":"VER"} ,
		{"word":"construisons","word_nosc":"construisons","lemma":"construire","pos":"VER"} ,
		{"word":"construisît","word_nosc":"construisit","lemma":"construire","pos":"VER"} ,
		{"word":"construit","word_nosc":"construit","lemma":"construire","pos":"VER"} ,
		{"word":"construite","word_nosc":"construite","lemma":"construire","pos":"VER"} ,
		{"word":"construites","word_nosc":"construites","lemma":"construire","pos":"VER"} ,
		{"word":"construits","word_nosc":"construits","lemma":"construire","pos":"VER"} ,
		{"word":"consulta","word_nosc":"consulta","lemma":"consulter","pos":"VER"} ,
		{"word":"consultai","word_nosc":"consultai","lemma":"consulter","pos":"VER"} ,
		{"word":"consultaient","word_nosc":"consultaient","lemma":"consulter","pos":"VER"} ,
		{"word":"consultais","word_nosc":"consultais","lemma":"consulter","pos":"VER"} ,
		{"word":"consultait","word_nosc":"consultait","lemma":"consulter","pos":"VER"} ,
		{"word":"consultant","word_nosc":"consultant","lemma":"consulter","pos":"VER"} ,
		{"word":"consulte","word_nosc":"consulte","lemma":"consulter","pos":"VER"} ,
		{"word":"consultent","word_nosc":"consultent","lemma":"consulter","pos":"VER"} ,
		{"word":"consulter","word_nosc":"consulter","lemma":"consulter","pos":"VER"} ,
		{"word":"consultera","word_nosc":"consultera","lemma":"consulter","pos":"VER"} ,
		{"word":"consulterai","word_nosc":"consulterai","lemma":"consulter","pos":"VER"} ,
		{"word":"consulteraient","word_nosc":"consulteraient","lemma":"consulter","pos":"VER"} ,
		{"word":"consulterais","word_nosc":"consulterais","lemma":"consulter","pos":"VER"} ,
		{"word":"consulteras","word_nosc":"consulteras","lemma":"consulter","pos":"VER"} ,
		{"word":"consulterons","word_nosc":"consulterons","lemma":"consulter","pos":"VER"} ,
		{"word":"consulteront","word_nosc":"consulteront","lemma":"consulter","pos":"VER"} ,
		{"word":"consultes","word_nosc":"consultes","lemma":"consulter","pos":"VER"} ,
		{"word":"consultez","word_nosc":"consultez","lemma":"consulter","pos":"VER"} ,
		{"word":"consultions","word_nosc":"consultions","lemma":"consulter","pos":"VER"} ,
		{"word":"consultons","word_nosc":"consultons","lemma":"consulter","pos":"VER"} ,
		{"word":"consultâmes","word_nosc":"consultames","lemma":"consulter","pos":"VER"} ,
		{"word":"consultèrent","word_nosc":"consulterent","lemma":"consulter","pos":"VER"} ,
		{"word":"consulté","word_nosc":"consulte","lemma":"consulter","pos":"VER"} ,
		{"word":"consultée","word_nosc":"consultee","lemma":"consulter","pos":"VER"} ,
		{"word":"consultées","word_nosc":"consultees","lemma":"consulter","pos":"VER"} ,
		{"word":"consultés","word_nosc":"consultes","lemma":"consulter","pos":"VER"} ,
		{"word":"consuma","word_nosc":"consuma","lemma":"consumer","pos":"VER"} ,
		{"word":"consumai","word_nosc":"consumai","lemma":"consumer","pos":"VER"} ,
		{"word":"consumaient","word_nosc":"consumaient","lemma":"consumer","pos":"VER"} ,
		{"word":"consumais","word_nosc":"consumais","lemma":"consumer","pos":"VER"} ,
		{"word":"consumait","word_nosc":"consumait","lemma":"consumer","pos":"VER"} ,
		{"word":"consumant","word_nosc":"consumant","lemma":"consumer","pos":"VER"} ,
		{"word":"consume","word_nosc":"consume","lemma":"consumer","pos":"VER"} ,
		{"word":"consument","word_nosc":"consument","lemma":"consumer","pos":"VER"} ,
		{"word":"consumer","word_nosc":"consumer","lemma":"consumer","pos":"VER"} ,
		{"word":"consumera","word_nosc":"consumera","lemma":"consumer","pos":"VER"} ,
		{"word":"consumerait","word_nosc":"consumerait","lemma":"consumer","pos":"VER"} ,
		{"word":"consumeront","word_nosc":"consumeront","lemma":"consumer","pos":"VER"} ,
		{"word":"consumes","word_nosc":"consumes","lemma":"consumer","pos":"VER"} ,
		{"word":"consumez","word_nosc":"consumez","lemma":"consumer","pos":"VER"} ,
		{"word":"consumât","word_nosc":"consumat","lemma":"consumer","pos":"VER"} ,
		{"word":"consumèrent","word_nosc":"consumerent","lemma":"consumer","pos":"VER"} ,
		{"word":"consumé","word_nosc":"consume","lemma":"consumer","pos":"VER"} ,
		{"word":"consumée","word_nosc":"consumee","lemma":"consumer","pos":"VER"} ,
		{"word":"consumées","word_nosc":"consumees","lemma":"consumer","pos":"VER"} ,
		{"word":"consumés","word_nosc":"consumes","lemma":"consumer","pos":"VER"} ,
		{"word":"conta","word_nosc":"conta","lemma":"conter","pos":"VER"} ,
		{"word":"contacta","word_nosc":"contacta","lemma":"contacter","pos":"VER"} ,
		{"word":"contactais","word_nosc":"contactais","lemma":"contacter","pos":"VER"} ,
		{"word":"contactait","word_nosc":"contactait","lemma":"contacter","pos":"VER"} ,
		{"word":"contactant","word_nosc":"contactant","lemma":"contacter","pos":"VER"} ,
		{"word":"contacte","word_nosc":"contacte","lemma":"contacter","pos":"VER"} ,
		{"word":"contactent","word_nosc":"contactent","lemma":"contacter","pos":"VER"} ,
		{"word":"contacter","word_nosc":"contacter","lemma":"contacter","pos":"VER"} ,
		{"word":"contactera","word_nosc":"contactera","lemma":"contacter","pos":"VER"} ,
		{"word":"contacterai","word_nosc":"contacterai","lemma":"contacter","pos":"VER"} ,
		{"word":"contacteraient","word_nosc":"contacteraient","lemma":"contacter","pos":"VER"} ,
		{"word":"contacterais","word_nosc":"contacterais","lemma":"contacter","pos":"VER"} ,
		{"word":"contacterait","word_nosc":"contacterait","lemma":"contacter","pos":"VER"} ,
		{"word":"contacteras","word_nosc":"contacteras","lemma":"contacter","pos":"VER"} ,
		{"word":"contacterez","word_nosc":"contacterez","lemma":"contacter","pos":"VER"} ,
		{"word":"contacterons","word_nosc":"contacterons","lemma":"contacter","pos":"VER"} ,
		{"word":"contacteront","word_nosc":"contacteront","lemma":"contacter","pos":"VER"} ,
		{"word":"contactez","word_nosc":"contactez","lemma":"contacter","pos":"VER"} ,
		{"word":"contactiez","word_nosc":"contactiez","lemma":"contacter","pos":"VER"} ,
		{"word":"contactions","word_nosc":"contactions","lemma":"contacter","pos":"VER"} ,
		{"word":"contactons","word_nosc":"contactons","lemma":"contacter","pos":"VER"} ,
		{"word":"contacté","word_nosc":"contacte","lemma":"contacter","pos":"VER"} ,
		{"word":"contactée","word_nosc":"contactee","lemma":"contacter","pos":"VER"} ,
		{"word":"contactées","word_nosc":"contactees","lemma":"contacter","pos":"VER"} ,
		{"word":"contactés","word_nosc":"contactes","lemma":"contacter","pos":"VER"} ,
		{"word":"contai","word_nosc":"contai","lemma":"conter","pos":"VER"} ,
		{"word":"contaient","word_nosc":"contaient","lemma":"conter","pos":"VER"} ,
		{"word":"contais","word_nosc":"contais","lemma":"conter","pos":"VER"} ,
		{"word":"contait","word_nosc":"contait","lemma":"conter","pos":"VER"} ,
		{"word":"contaminaient","word_nosc":"contaminaient","lemma":"contaminer","pos":"VER"} ,
		{"word":"contaminait","word_nosc":"contaminait","lemma":"contaminer","pos":"VER"} ,
		{"word":"contaminant","word_nosc":"contaminant","lemma":"contaminer","pos":"VER"} ,
		{"word":"contamine","word_nosc":"contamine","lemma":"contaminer","pos":"VER"} ,
		{"word":"contaminer","word_nosc":"contaminer","lemma":"contaminer","pos":"VER"} ,
		{"word":"contaminera","word_nosc":"contaminera","lemma":"contaminer","pos":"VER"} ,
		{"word":"contamineras","word_nosc":"contamineras","lemma":"contaminer","pos":"VER"} ,
		{"word":"contamines","word_nosc":"contamines","lemma":"contaminer","pos":"VER"} ,
		{"word":"contaminons","word_nosc":"contaminons","lemma":"contaminer","pos":"VER"} ,
		{"word":"contaminât","word_nosc":"contaminat","lemma":"contaminer","pos":"VER"} ,
		{"word":"contaminé","word_nosc":"contamine","lemma":"contaminer","pos":"VER"} ,
		{"word":"contaminée","word_nosc":"contaminee","lemma":"contaminer","pos":"VER"} ,
		{"word":"contaminées","word_nosc":"contaminees","lemma":"contaminer","pos":"VER"} ,
		{"word":"contaminés","word_nosc":"contamines","lemma":"contaminer","pos":"VER"} ,
		{"word":"contant","word_nosc":"contant","lemma":"conter","pos":"VER"} ,
		{"word":"conte","word_nosc":"conte","lemma":"conter","pos":"VER"} ,
		{"word":"contempla","word_nosc":"contempla","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplai","word_nosc":"contemplai","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplaient","word_nosc":"contemplaient","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplais","word_nosc":"contemplais","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplait","word_nosc":"contemplait","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplant","word_nosc":"contemplant","lemma":"contempler","pos":"VER"} ,
		{"word":"contemple","word_nosc":"contemple","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplent","word_nosc":"contemplent","lemma":"contempler","pos":"VER"} ,
		{"word":"contempler","word_nosc":"contempler","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplera","word_nosc":"contemplera","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplerai","word_nosc":"contemplerai","lemma":"contempler","pos":"VER"} ,
		{"word":"contempleraient","word_nosc":"contempleraient","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplerait","word_nosc":"contemplerait","lemma":"contempler","pos":"VER"} ,
		{"word":"contempleront","word_nosc":"contempleront","lemma":"contempler","pos":"VER"} ,
		{"word":"contemples","word_nosc":"contemples","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplez","word_nosc":"contemplez","lemma":"contempler","pos":"VER"} ,
		{"word":"contempliez","word_nosc":"contempliez","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplions","word_nosc":"contemplions","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplons","word_nosc":"contemplons","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplâmes","word_nosc":"contemplames","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplèrent","word_nosc":"contemplerent","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplé","word_nosc":"contemple","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplée","word_nosc":"contemplee","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplées","word_nosc":"contemplees","lemma":"contempler","pos":"VER"} ,
		{"word":"contemplés","word_nosc":"contemples","lemma":"contempler","pos":"VER"} ,
		{"word":"contenaient","word_nosc":"contenaient","lemma":"contenir","pos":"VER"} ,
		{"word":"contenais","word_nosc":"contenais","lemma":"contenir","pos":"VER"} ,
		{"word":"contenait","word_nosc":"contenait","lemma":"contenir","pos":"VER"} ,
		{"word":"contenant","word_nosc":"contenant","lemma":"contenir","pos":"VER"} ,
		{"word":"contenez","word_nosc":"contenez","lemma":"contenir","pos":"VER"} ,
		{"word":"contenir","word_nosc":"contenir","lemma":"contenir","pos":"VER"} ,
		{"word":"content","word_nosc":"content","lemma":"conter","pos":"VER"} ,
		{"word":"contenta","word_nosc":"contenta","lemma":"contenter","pos":"VER"} ,
		{"word":"contentai","word_nosc":"contentai","lemma":"contenter","pos":"VER"} ,
		{"word":"contentaient","word_nosc":"contentaient","lemma":"contenter","pos":"VER"} ,
		{"word":"contentais","word_nosc":"contentais","lemma":"contenter","pos":"VER"} ,
		{"word":"contentait","word_nosc":"contentait","lemma":"contenter","pos":"VER"} ,
		{"word":"contentant","word_nosc":"contentant","lemma":"contenter","pos":"VER"} ,
		{"word":"contentassent","word_nosc":"contentassent","lemma":"contenter","pos":"VER"} ,
		{"word":"contente","word_nosc":"contente","lemma":"contenter","pos":"VER"} ,
		{"word":"contentent","word_nosc":"contentent","lemma":"contenter","pos":"VER"} ,
		{"word":"contenter","word_nosc":"contenter","lemma":"contenter","pos":"VER"} ,
		{"word":"contentera","word_nosc":"contentera","lemma":"contenter","pos":"VER"} ,
		{"word":"contenterai","word_nosc":"contenterai","lemma":"contenter","pos":"VER"} ,
		{"word":"contenteraient","word_nosc":"contenteraient","lemma":"contenter","pos":"VER"} ,
		{"word":"contenterais","word_nosc":"contenterais","lemma":"contenter","pos":"VER"} ,
		{"word":"contenterait","word_nosc":"contenterait","lemma":"contenter","pos":"VER"} ,
		{"word":"contenteras","word_nosc":"contenteras","lemma":"contenter","pos":"VER"} ,
		{"word":"contenterez","word_nosc":"contenterez","lemma":"contenter","pos":"VER"} ,
		{"word":"contenteriez","word_nosc":"contenteriez","lemma":"contenter","pos":"VER"} ,
		{"word":"contenterions","word_nosc":"contenterions","lemma":"contenter","pos":"VER"} ,
		{"word":"contenterons","word_nosc":"contenterons","lemma":"contenter","pos":"VER"} ,
		{"word":"contenteront","word_nosc":"contenteront","lemma":"contenter","pos":"VER"} ,
		{"word":"contentes","word_nosc":"contentes","lemma":"contenter","pos":"VER"} ,
		{"word":"contentez","word_nosc":"contentez","lemma":"contenter","pos":"VER"} ,
		{"word":"contentiez","word_nosc":"contentiez","lemma":"contenter","pos":"VER"} ,
		{"word":"contentions","word_nosc":"contentions","lemma":"contenter","pos":"VER"} ,
		{"word":"contentons","word_nosc":"contentons","lemma":"contenter","pos":"VER"} ,
		{"word":"contentèrent","word_nosc":"contenterent","lemma":"contenter","pos":"VER"} ,
		{"word":"contenté","word_nosc":"contente","lemma":"contenter","pos":"VER"} ,
		{"word":"contentée","word_nosc":"contentee","lemma":"contenter","pos":"VER"} ,
		{"word":"contentées","word_nosc":"contentees","lemma":"contenter","pos":"VER"} ,
		{"word":"contentés","word_nosc":"contentes","lemma":"contenter","pos":"VER"} ,
		{"word":"contenu","word_nosc":"contenu","lemma":"contenir","pos":"VER"} ,
		{"word":"contenue","word_nosc":"contenue","lemma":"contenir","pos":"VER"} ,
		{"word":"contenues","word_nosc":"contenues","lemma":"contenir","pos":"VER"} ,
		{"word":"contenus","word_nosc":"contenus","lemma":"contenir","pos":"VER"} ,
		{"word":"conter","word_nosc":"conter","lemma":"conter","pos":"VER"} ,
		{"word":"contera","word_nosc":"contera","lemma":"conter","pos":"VER"} ,
		{"word":"conterai","word_nosc":"conterai","lemma":"conter","pos":"VER"} ,
		{"word":"conterait","word_nosc":"conterait","lemma":"conter","pos":"VER"} ,
		{"word":"conteras","word_nosc":"conteras","lemma":"conter","pos":"VER"} ,
		{"word":"conterez","word_nosc":"conterez","lemma":"conter","pos":"VER"} ,
		{"word":"conterons","word_nosc":"conterons","lemma":"conter","pos":"VER"} ,
		{"word":"contes","word_nosc":"contes","lemma":"conter","pos":"VER"} ,
		{"word":"contesta","word_nosc":"contesta","lemma":"contester","pos":"VER"} ,
		{"word":"contestaient","word_nosc":"contestaient","lemma":"contester","pos":"VER"} ,
		{"word":"contestais","word_nosc":"contestais","lemma":"contester","pos":"VER"} ,
		{"word":"contestait","word_nosc":"contestait","lemma":"contester","pos":"VER"} ,
		{"word":"contestant","word_nosc":"contestant","lemma":"contester","pos":"VER"} ,
		{"word":"conteste","word_nosc":"conteste","lemma":"contester","pos":"VER"} ,
		{"word":"contestent","word_nosc":"contestent","lemma":"contester","pos":"VER"} ,
		{"word":"contester","word_nosc":"contester","lemma":"contester","pos":"VER"} ,
		{"word":"contestera","word_nosc":"contestera","lemma":"contester","pos":"VER"} ,
		{"word":"contesterai","word_nosc":"contesterai","lemma":"contester","pos":"VER"} ,
		{"word":"contesterait","word_nosc":"contesterait","lemma":"contester","pos":"VER"} ,
		{"word":"contesteriez","word_nosc":"contesteriez","lemma":"contester","pos":"VER"} ,
		{"word":"contesterons","word_nosc":"contesterons","lemma":"contester","pos":"VER"} ,
		{"word":"contestes","word_nosc":"contestes","lemma":"contester","pos":"VER"} ,
		{"word":"contestez","word_nosc":"contestez","lemma":"contester","pos":"VER"} ,
		{"word":"contestiez","word_nosc":"contestiez","lemma":"contester","pos":"VER"} ,
		{"word":"contestons","word_nosc":"contestons","lemma":"contester","pos":"VER"} ,
		{"word":"contesté","word_nosc":"conteste","lemma":"contester","pos":"VER"} ,
		{"word":"contestée","word_nosc":"contestee","lemma":"contester","pos":"VER"} ,
		{"word":"contestées","word_nosc":"contestees","lemma":"contester","pos":"VER"} ,
		{"word":"contestés","word_nosc":"contestes","lemma":"contester","pos":"VER"} ,
		{"word":"contextualiser","word_nosc":"contextualiser","lemma":"contextualiser","pos":"VER"} ,
		{"word":"contez","word_nosc":"contez","lemma":"conter","pos":"VER"} ,
		{"word":"contiendra","word_nosc":"contiendra","lemma":"contenir","pos":"VER"} ,
		{"word":"contiendrai","word_nosc":"contiendrai","lemma":"contenir","pos":"VER"} ,
		{"word":"contiendraient","word_nosc":"contiendraient","lemma":"contenir","pos":"VER"} ,
		{"word":"contiendrait","word_nosc":"contiendrait","lemma":"contenir","pos":"VER"} ,
		{"word":"contienne","word_nosc":"contienne","lemma":"contenir","pos":"VER"} ,
		{"word":"contiennent","word_nosc":"contiennent","lemma":"contenir","pos":"VER"} ,
		{"word":"contiens","word_nosc":"contiens","lemma":"contenir","pos":"VER"} ,
		{"word":"contient","word_nosc":"contient","lemma":"contenir","pos":"VER"} ,
		{"word":"contingenté","word_nosc":"contingente","lemma":"contingenter","pos":"VER"} ,
		{"word":"continrent","word_nosc":"continrent","lemma":"contenir","pos":"VER"} ,
		{"word":"contins","word_nosc":"contins","lemma":"contenir","pos":"VER"} ,
		{"word":"contint","word_nosc":"contint","lemma":"contenir","pos":"VER"} ,
		{"word":"continua","word_nosc":"continua","lemma":"continuer","pos":"VER"} ,
		{"word":"continuai","word_nosc":"continuai","lemma":"continuer","pos":"VER"} ,
		{"word":"continuaient","word_nosc":"continuaient","lemma":"continuer","pos":"VER"} ,
		{"word":"continuais","word_nosc":"continuais","lemma":"continuer","pos":"VER"} ,
		{"word":"continuait","word_nosc":"continuait","lemma":"continuer","pos":"VER"} ,
		{"word":"continuant","word_nosc":"continuant","lemma":"continuer","pos":"VER"} ,
		{"word":"continuassent","word_nosc":"continuassent","lemma":"continuer","pos":"VER"} ,
		{"word":"continue","word_nosc":"continue","lemma":"continuer","pos":"VER"} ,
		{"word":"continuent","word_nosc":"continuent","lemma":"continuer","pos":"VER"} ,
		{"word":"continuer","word_nosc":"continuer","lemma":"continuer","pos":"VER"} ,
		{"word":"continuera","word_nosc":"continuera","lemma":"continuer","pos":"VER"} ,
		{"word":"continuerai","word_nosc":"continuerai","lemma":"continuer","pos":"VER"} ,
		{"word":"continueraient","word_nosc":"continueraient","lemma":"continuer","pos":"VER"} ,
		{"word":"continuerais","word_nosc":"continuerais","lemma":"continuer","pos":"VER"} ,
		{"word":"continuerait","word_nosc":"continuerait","lemma":"continuer","pos":"VER"} ,
		{"word":"continueras","word_nosc":"continueras","lemma":"continuer","pos":"VER"} ,
		{"word":"continuerez","word_nosc":"continuerez","lemma":"continuer","pos":"VER"} ,
		{"word":"continueriez","word_nosc":"continueriez","lemma":"continuer","pos":"VER"} ,
		{"word":"continuerons","word_nosc":"continuerons","lemma":"continuer","pos":"VER"} ,
		{"word":"continueront","word_nosc":"continueront","lemma":"continuer","pos":"VER"} ,
		{"word":"continues","word_nosc":"continues","lemma":"continuer","pos":"VER"} ,
		{"word":"continuez","word_nosc":"continuez","lemma":"continuer","pos":"VER"} ,
		{"word":"continuiez","word_nosc":"continuiez","lemma":"continuer","pos":"VER"} ,
		{"word":"continuions","word_nosc":"continuions","lemma":"continuer","pos":"VER"} ,
		{"word":"continuons","word_nosc":"continuons","lemma":"continuer","pos":"VER"} ,
		{"word":"continuâmes","word_nosc":"continuames","lemma":"continuer","pos":"VER"} ,
		{"word":"continuât","word_nosc":"continuat","lemma":"continuer","pos":"VER"} ,
		{"word":"continuèrent","word_nosc":"continuerent","lemma":"continuer","pos":"VER"} ,
		{"word":"continué","word_nosc":"continue","lemma":"continuer","pos":"VER"} ,
		{"word":"continuée","word_nosc":"continuee","lemma":"continuer","pos":"VER"} ,
		{"word":"continuées","word_nosc":"continuees","lemma":"continuer","pos":"VER"} ,
		{"word":"continués","word_nosc":"continues","lemma":"continuer","pos":"VER"} ,
		{"word":"contions","word_nosc":"contions","lemma":"conter","pos":"VER"} ,
		{"word":"contorsionna","word_nosc":"contorsionna","lemma":"contorsionner","pos":"VER"} ,
		{"word":"contorsionnaient","word_nosc":"contorsionnaient","lemma":"contorsionner","pos":"VER"} ,
		{"word":"contorsionnait","word_nosc":"contorsionnait","lemma":"contorsionner","pos":"VER"} ,
		{"word":"contorsionnant","word_nosc":"contorsionnant","lemma":"contorsionner","pos":"VER"} ,
		{"word":"contorsionne","word_nosc":"contorsionne","lemma":"contorsionner","pos":"VER"} ,
		{"word":"contorsionnent","word_nosc":"contorsionnent","lemma":"contorsionner","pos":"VER"} ,
		{"word":"contorsionner","word_nosc":"contorsionner","lemma":"contorsionner","pos":"VER"} ,
		{"word":"contorsionné","word_nosc":"contorsionne","lemma":"contorsionner","pos":"VER"} ,
		{"word":"contorsionnée","word_nosc":"contorsionnee","lemma":"contorsionner","pos":"VER"} ,
		{"word":"contorsionnées","word_nosc":"contorsionnees","lemma":"contorsionner","pos":"VER"} ,
		{"word":"contorsionnés","word_nosc":"contorsionnes","lemma":"contorsionner","pos":"VER"} ,
		{"word":"contourna","word_nosc":"contourna","lemma":"contourner","pos":"VER"} ,
		{"word":"contournai","word_nosc":"contournai","lemma":"contourner","pos":"VER"} ,
		{"word":"contournaient","word_nosc":"contournaient","lemma":"contourner","pos":"VER"} ,
		{"word":"contournais","word_nosc":"contournais","lemma":"contourner","pos":"VER"} ,
		{"word":"contournait","word_nosc":"contournait","lemma":"contourner","pos":"VER"} ,
		{"word":"contournant","word_nosc":"contournant","lemma":"contourner","pos":"VER"} ,
		{"word":"contourne","word_nosc":"contourne","lemma":"contourner","pos":"VER"} ,
		{"word":"contournent","word_nosc":"contournent","lemma":"contourner","pos":"VER"} ,
		{"word":"contourner","word_nosc":"contourner","lemma":"contourner","pos":"VER"} ,
		{"word":"contournera","word_nosc":"contournera","lemma":"contourner","pos":"VER"} ,
		{"word":"contournerai","word_nosc":"contournerai","lemma":"contourner","pos":"VER"} ,
		{"word":"contournerais","word_nosc":"contournerais","lemma":"contourner","pos":"VER"} ,
		{"word":"contournerez","word_nosc":"contournerez","lemma":"contourner","pos":"VER"} ,
		{"word":"contournerons","word_nosc":"contournerons","lemma":"contourner","pos":"VER"} ,
		{"word":"contournes","word_nosc":"contournes","lemma":"contourner","pos":"VER"} ,
		{"word":"contournez","word_nosc":"contournez","lemma":"contourner","pos":"VER"} ,
		{"word":"contournions","word_nosc":"contournions","lemma":"contourner","pos":"VER"} ,
		{"word":"contournons","word_nosc":"contournons","lemma":"contourner","pos":"VER"} ,
		{"word":"contournèrent","word_nosc":"contournerent","lemma":"contourner","pos":"VER"} ,
		{"word":"contourné","word_nosc":"contourne","lemma":"contourner","pos":"VER"} ,
		{"word":"contournée","word_nosc":"contournee","lemma":"contourner","pos":"VER"} ,
		{"word":"contournées","word_nosc":"contournees","lemma":"contourner","pos":"VER"} ,
		{"word":"contournés","word_nosc":"contournes","lemma":"contourner","pos":"VER"} ,
		{"word":"contra","word_nosc":"contra","lemma":"contrer","pos":"VER"} ,
		{"word":"contracta","word_nosc":"contracta","lemma":"contracter","pos":"VER"} ,
		{"word":"contractai","word_nosc":"contractai","lemma":"contracter","pos":"VER"} ,
		{"word":"contractaient","word_nosc":"contractaient","lemma":"contracter","pos":"VER"} ,
		{"word":"contractais","word_nosc":"contractais","lemma":"contracter","pos":"VER"} ,
		{"word":"contractait","word_nosc":"contractait","lemma":"contracter","pos":"VER"} ,
		{"word":"contractant","word_nosc":"contractant","lemma":"contracter","pos":"VER"} ,
		{"word":"contracte","word_nosc":"contracte","lemma":"contracter","pos":"VER"} ,
		{"word":"contractent","word_nosc":"contractent","lemma":"contracter","pos":"VER"} ,
		{"word":"contracter","word_nosc":"contracter","lemma":"contracter","pos":"VER"} ,
		{"word":"contracterait","word_nosc":"contracterait","lemma":"contracter","pos":"VER"} ,
		{"word":"contractez","word_nosc":"contractez","lemma":"contracter","pos":"VER"} ,
		{"word":"contractions","word_nosc":"contractions","lemma":"contracter","pos":"VER"} ,
		{"word":"contractons","word_nosc":"contractons","lemma":"contracter","pos":"VER"} ,
		{"word":"contractèrent","word_nosc":"contracterent","lemma":"contracter","pos":"VER"} ,
		{"word":"contracté","word_nosc":"contracte","lemma":"contracter","pos":"VER"} ,
		{"word":"contractée","word_nosc":"contractee","lemma":"contracter","pos":"VER"} ,
		{"word":"contractées","word_nosc":"contractees","lemma":"contracter","pos":"VER"} ,
		{"word":"contractés","word_nosc":"contractes","lemma":"contracter","pos":"VER"} ,
		{"word":"contraignaient","word_nosc":"contraignaient","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraignais","word_nosc":"contraignais","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraignait","word_nosc":"contraignait","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraignant","word_nosc":"contraignant","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraigne","word_nosc":"contraigne","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraignent","word_nosc":"contraignent","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraignit","word_nosc":"contraignit","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraignît","word_nosc":"contraignit","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraindra","word_nosc":"contraindra","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraindrai","word_nosc":"contraindrai","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraindraient","word_nosc":"contraindraient","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraindrais","word_nosc":"contraindrais","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraindrait","word_nosc":"contraindrait","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraindre","word_nosc":"contraindre","lemma":"contraindre","pos":"VER"} ,
		{"word":"contrains","word_nosc":"contrains","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraint","word_nosc":"contraint","lemma":"contraindre","pos":"VER"} ,
		{"word":"contrainte","word_nosc":"contrainte","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraintes","word_nosc":"contraintes","lemma":"contraindre","pos":"VER"} ,
		{"word":"contraints","word_nosc":"contraints","lemma":"contraindre","pos":"VER"} ,
		{"word":"contrait","word_nosc":"contrait","lemma":"contrer","pos":"VER"} ,
		{"word":"contrant","word_nosc":"contrant","lemma":"contrer","pos":"VER"} ,
		{"word":"contraria","word_nosc":"contraria","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrariaient","word_nosc":"contrariaient","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrariais","word_nosc":"contrariais","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrariait","word_nosc":"contrariait","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrariant","word_nosc":"contrariant","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrarie","word_nosc":"contrarie","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrarient","word_nosc":"contrarient","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrarier","word_nosc":"contrarier","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrarierai","word_nosc":"contrarierai","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrarierait","word_nosc":"contrarierait","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrariez","word_nosc":"contrariez","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrarions","word_nosc":"contrarions","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrariât","word_nosc":"contrariat","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrarié","word_nosc":"contrarie","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrariée","word_nosc":"contrariee","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrariées","word_nosc":"contrariees","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrariés","word_nosc":"contraries","lemma":"contrarier","pos":"VER"} ,
		{"word":"contrasta","word_nosc":"contrasta","lemma":"contraster","pos":"VER"} ,
		{"word":"contrastaient","word_nosc":"contrastaient","lemma":"contraster","pos":"VER"} ,
		{"word":"contrastait","word_nosc":"contrastait","lemma":"contraster","pos":"VER"} ,
		{"word":"contrastant","word_nosc":"contrastant","lemma":"contraster","pos":"VER"} ,
		{"word":"contraste","word_nosc":"contraste","lemma":"contraster","pos":"VER"} ,
		{"word":"contrastent","word_nosc":"contrastent","lemma":"contraster","pos":"VER"} ,
		{"word":"contraster","word_nosc":"contraster","lemma":"contraster","pos":"VER"} ,
		{"word":"contrasteraient","word_nosc":"contrasteraient","lemma":"contraster","pos":"VER"} ,
		{"word":"contrasterait","word_nosc":"contrasterait","lemma":"contraster","pos":"VER"} ,
		{"word":"contrastez","word_nosc":"contrastez","lemma":"contraster","pos":"VER"} ,
		{"word":"contrastât","word_nosc":"contrastat","lemma":"contraster","pos":"VER"} ,
		{"word":"contrasté","word_nosc":"contraste","lemma":"contraster","pos":"VER"} ,
		{"word":"contrastée","word_nosc":"contrastee","lemma":"contraster","pos":"VER"} ,
		{"word":"contrastées","word_nosc":"contrastees","lemma":"contraster","pos":"VER"} ,
		{"word":"contrastés","word_nosc":"contrastes","lemma":"contraster","pos":"VER"} ,
		{"word":"contre","word_nosc":"contre","lemma":"contrer","pos":"VER"} ,
		{"word":"contre-attaqua","word_nosc":"contre-attaqua","lemma":"contre-attaquer","pos":"VER"} ,
		{"word":"contre-attaquaient","word_nosc":"contre-attaquaient","lemma":"contre-attaquer","pos":"VER"} ,
		{"word":"contre-attaquait","word_nosc":"contre-attaquait","lemma":"contre-attaquer","pos":"VER"} ,
		{"word":"contre-attaquant","word_nosc":"contre-attaquant","lemma":"contre-attaquer","pos":"VER"} ,
		{"word":"contre-attaque","word_nosc":"contre-attaque","lemma":"contre-attaquer","pos":"VER"} ,
		{"word":"contre-attaquent","word_nosc":"contre-attaquent","lemma":"contre-attaquer","pos":"VER"} ,
		{"word":"contre-attaquer","word_nosc":"contre-attaquer","lemma":"contre-attaquer","pos":"VER"} ,
		{"word":"contre-attaquera","word_nosc":"contre-attaquera","lemma":"contre-attaquer","pos":"VER"} ,
		{"word":"contre-attaquerons","word_nosc":"contre-attaquerons","lemma":"contre-attaquer","pos":"VER"} ,
		{"word":"contre-attaques","word_nosc":"contre-attaques","lemma":"contre-attaquer","pos":"VER"} ,
		{"word":"contre-attaquons","word_nosc":"contre-attaquons","lemma":"contre-attaquer","pos":"VER"} ,
		{"word":"contre-attaquèrent","word_nosc":"contre-attaquerent","lemma":"contre-attaquer","pos":"VER"} ,
		{"word":"contre-attaqué","word_nosc":"contre-attaque","lemma":"contre-attaquer","pos":"VER"} ,
		{"word":"contre-indiqué","word_nosc":"contre-indique","lemma":"contre-indiquer","pos":"VER"} ,
		{"word":"contre-manifestent","word_nosc":"contre-manifestent","lemma":"contre-manifester","pos":"VER"} ,
		{"word":"contre-miné","word_nosc":"contre-mine","lemma":"contre-miner","pos":"VER"} ,
		{"word":"contrebalance","word_nosc":"contrebalance","lemma":"contrebalancer","pos":"VER"} ,
		{"word":"contrebalancer","word_nosc":"contrebalancer","lemma":"contrebalancer","pos":"VER"} ,
		{"word":"contrebalancé","word_nosc":"contrebalance","lemma":"contrebalancer","pos":"VER"} ,
		{"word":"contrebalancée","word_nosc":"contrebalancee","lemma":"contrebalancer","pos":"VER"} ,
		{"word":"contrebalancés","word_nosc":"contrebalances","lemma":"contrebalancer","pos":"VER"} ,
		{"word":"contrebalançais","word_nosc":"contrebalancais","lemma":"contrebalancer","pos":"VER"} ,
		{"word":"contrebalançait","word_nosc":"contrebalancait","lemma":"contrebalancer","pos":"VER"} ,
		{"word":"contrebraque","word_nosc":"contrebraque","lemma":"contrebraquer","pos":"VER"} ,
		{"word":"contrecarraient","word_nosc":"contrecarraient","lemma":"contrecarrer","pos":"VER"} ,
		{"word":"contrecarrais","word_nosc":"contrecarrais","lemma":"contrecarrer","pos":"VER"} ,
		{"word":"contrecarre","word_nosc":"contrecarre","lemma":"contrecarrer","pos":"VER"} ,
		{"word":"contrecarrer","word_nosc":"contrecarrer","lemma":"contrecarrer","pos":"VER"} ,
		{"word":"contrecarres","word_nosc":"contrecarres","lemma":"contrecarrer","pos":"VER"} ,
		{"word":"contrecarré","word_nosc":"contrecarre","lemma":"contrecarrer","pos":"VER"} ,
		{"word":"contrecarrée","word_nosc":"contrecarree","lemma":"contrecarrer","pos":"VER"} ,
		{"word":"contredira","word_nosc":"contredira","lemma":"contredire","pos":"VER"} ,
		{"word":"contredirai","word_nosc":"contredirai","lemma":"contredire","pos":"VER"} ,
		{"word":"contrediraient","word_nosc":"contrediraient","lemma":"contredire","pos":"VER"} ,
		{"word":"contredirait","word_nosc":"contredirait","lemma":"contredire","pos":"VER"} ,
		{"word":"contredire","word_nosc":"contredire","lemma":"contredire","pos":"VER"} ,
		{"word":"contredirez","word_nosc":"contredirez","lemma":"contredire","pos":"VER"} ,
		{"word":"contredis","word_nosc":"contredis","lemma":"contredire","pos":"VER"} ,
		{"word":"contredisaient","word_nosc":"contredisaient","lemma":"contredire","pos":"VER"} ,
		{"word":"contredisait","word_nosc":"contredisait","lemma":"contredire","pos":"VER"} ,
		{"word":"contredisant","word_nosc":"contredisant","lemma":"contredire","pos":"VER"} ,
		{"word":"contredise","word_nosc":"contredise","lemma":"contredire","pos":"VER"} ,
		{"word":"contredisent","word_nosc":"contredisent","lemma":"contredire","pos":"VER"} ,
		{"word":"contredises","word_nosc":"contredises","lemma":"contredire","pos":"VER"} ,
		{"word":"contredisez","word_nosc":"contredisez","lemma":"contredire","pos":"VER"} ,
		{"word":"contredit","word_nosc":"contredit","lemma":"contredire","pos":"VER"} ,
		{"word":"contredite","word_nosc":"contredite","lemma":"contredire","pos":"VER"} ,
		{"word":"contredites","word_nosc":"contredites","lemma":"contredire","pos":"VER"} ,
		{"word":"contredits","word_nosc":"contredits","lemma":"contredire","pos":"VER"} ,
		{"word":"contredît","word_nosc":"contredit","lemma":"contredire","pos":"VER"} ,
		{"word":"contrefaire","word_nosc":"contrefaire","lemma":"contrefaire","pos":"VER"} ,
		{"word":"contrefaisait","word_nosc":"contrefaisait","lemma":"contrefaire","pos":"VER"} ,
		{"word":"contrefaisant","word_nosc":"contrefaisant","lemma":"contrefaire","pos":"VER"} ,
		{"word":"contrefait","word_nosc":"contrefait","lemma":"contrefaire","pos":"VER"} ,
		{"word":"contrefaite","word_nosc":"contrefaite","lemma":"contrefaire","pos":"VER"} ,
		{"word":"contrefaits","word_nosc":"contrefaits","lemma":"contrefaire","pos":"VER"} ,
		{"word":"contreferait","word_nosc":"contreferait","lemma":"contrefaire","pos":"VER"} ,
		{"word":"contrefichais","word_nosc":"contrefichais","lemma":"contreficher","pos":"VER"} ,
		{"word":"contrefichait","word_nosc":"contrefichait","lemma":"contreficher","pos":"VER"} ,
		{"word":"contrefiche","word_nosc":"contrefiche","lemma":"contreficher","pos":"VER"} ,
		{"word":"contrefous","word_nosc":"contrefous","lemma":"contrefoutre","pos":"VER"} ,
		{"word":"contrefout","word_nosc":"contrefout","lemma":"contrefoutre","pos":"VER"} ,
		{"word":"contrefoutait","word_nosc":"contrefoutait","lemma":"contrefoutre","pos":"VER"} ,
		{"word":"contrefoutre","word_nosc":"contrefoutre","lemma":"contrefoutre","pos":"VER"} ,
		{"word":"contremander","word_nosc":"contremander","lemma":"contremander","pos":"VER"} ,
		{"word":"contrent","word_nosc":"contrent","lemma":"contrer","pos":"VER"} ,
		{"word":"contrer","word_nosc":"contrer","lemma":"contrer","pos":"VER"} ,
		{"word":"contrerai","word_nosc":"contrerai","lemma":"contrer","pos":"VER"} ,
		{"word":"contrerait","word_nosc":"contrerait","lemma":"contrer","pos":"VER"} ,
		{"word":"contreras","word_nosc":"contreras","lemma":"contrer","pos":"VER"} ,
		{"word":"contreront","word_nosc":"contreront","lemma":"contrer","pos":"VER"} ,
		{"word":"contres","word_nosc":"contres","lemma":"contrer","pos":"VER"} ,
		{"word":"contresignait","word_nosc":"contresignait","lemma":"contresigner","pos":"VER"} ,
		{"word":"contresigne","word_nosc":"contresigne","lemma":"contresigner","pos":"VER"} ,
		{"word":"contresigner","word_nosc":"contresigner","lemma":"contresigner","pos":"VER"} ,
		{"word":"contresigné","word_nosc":"contresigne","lemma":"contresigner","pos":"VER"} ,
		{"word":"contresignée","word_nosc":"contresignee","lemma":"contresigner","pos":"VER"} ,
		{"word":"contresignées","word_nosc":"contresignees","lemma":"contresigner","pos":"VER"} ,
		{"word":"contresignés","word_nosc":"contresignes","lemma":"contresigner","pos":"VER"} ,
		{"word":"contretypés","word_nosc":"contretypes","lemma":"contretyper","pos":"VER"} ,
		{"word":"contrevenaient","word_nosc":"contrevenaient","lemma":"contrevenir","pos":"VER"} ,
		{"word":"contrevenait","word_nosc":"contrevenait","lemma":"contrevenir","pos":"VER"} ,
		{"word":"contrevenant","word_nosc":"contrevenant","lemma":"contrevenir","pos":"VER"} ,
		{"word":"contrevenez","word_nosc":"contrevenez","lemma":"contrevenir","pos":"VER"} ,
		{"word":"contrevenir","word_nosc":"contrevenir","lemma":"contrevenir","pos":"VER"} ,
		{"word":"contrevenu","word_nosc":"contrevenu","lemma":"contrevenir","pos":"VER"} ,
		{"word":"contreviendrait","word_nosc":"contreviendrait","lemma":"contrevenir","pos":"VER"} ,
		{"word":"contreviens","word_nosc":"contreviens","lemma":"contrevenir","pos":"VER"} ,
		{"word":"contrevient","word_nosc":"contrevient","lemma":"contrevenir","pos":"VER"} ,
		{"word":"contribua","word_nosc":"contribua","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribuaient","word_nosc":"contribuaient","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribuait","word_nosc":"contribuait","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribuant","word_nosc":"contribuant","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribue","word_nosc":"contribue","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribuent","word_nosc":"contribuent","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribuer","word_nosc":"contribuer","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribuera","word_nosc":"contribuera","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribueraient","word_nosc":"contribueraient","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribuerais","word_nosc":"contribuerais","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribuerait","word_nosc":"contribuerait","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribueras","word_nosc":"contribueras","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribuerez","word_nosc":"contribuerez","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribueront","word_nosc":"contribueront","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribuez","word_nosc":"contribuez","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribuons","word_nosc":"contribuons","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribuât","word_nosc":"contribuat","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribuèrent","word_nosc":"contribuerent","lemma":"contribuer","pos":"VER"} ,
		{"word":"contribué","word_nosc":"contribue","lemma":"contribuer","pos":"VER"} ,
		{"word":"contrista","word_nosc":"contrista","lemma":"contrister","pos":"VER"} ,
		{"word":"contristant","word_nosc":"contristant","lemma":"contrister","pos":"VER"} ,
		{"word":"contristées","word_nosc":"contristees","lemma":"contrister","pos":"VER"} ,
		{"word":"controversé","word_nosc":"controverse","lemma":"controverser","pos":"VER"} ,
		{"word":"controversée","word_nosc":"controversee","lemma":"controverser","pos":"VER"} ,
		{"word":"controversées","word_nosc":"controversees","lemma":"controverser","pos":"VER"} ,
		{"word":"controversés","word_nosc":"controverses","lemma":"controverser","pos":"VER"} ,
		{"word":"contré","word_nosc":"contre","lemma":"contrer","pos":"VER"} ,
		{"word":"contrée","word_nosc":"contree","lemma":"contrer","pos":"VER"} ,
		{"word":"contrées","word_nosc":"contrees","lemma":"contrer","pos":"VER"} ,
		{"word":"contrés","word_nosc":"contres","lemma":"contrer","pos":"VER"} ,
		{"word":"contrôla","word_nosc":"controla","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlai","word_nosc":"controlai","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlaient","word_nosc":"controlaient","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlais","word_nosc":"controlais","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlait","word_nosc":"controlait","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlant","word_nosc":"controlant","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôle","word_nosc":"controle","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlent","word_nosc":"controlent","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôler","word_nosc":"controler","lemma":"chuter","pos":"VER"} ,
		{"word":"contrôler","word_nosc":"controler","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlera","word_nosc":"controlera","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlerai","word_nosc":"controlerai","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlerais","word_nosc":"controlerais","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlerait","word_nosc":"controlerait","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôleras","word_nosc":"controleras","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlerez","word_nosc":"controlerez","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôleriez","word_nosc":"controleriez","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlerons","word_nosc":"controlerons","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôleront","word_nosc":"controleront","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôles","word_nosc":"controles","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlez","word_nosc":"controlez","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôliez","word_nosc":"controliez","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlions","word_nosc":"controlions","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlons","word_nosc":"controlons","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlâmes","word_nosc":"controlames","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlèrent","word_nosc":"controlerent","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlé","word_nosc":"controle","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlée","word_nosc":"controlee","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlées","word_nosc":"controlees","lemma":"contrôler","pos":"VER"} ,
		{"word":"contrôlés","word_nosc":"controles","lemma":"contrôler","pos":"VER"} ,
		{"word":"contusionné","word_nosc":"contusionne","lemma":"contusionner","pos":"VER"} ,
		{"word":"contusionnée","word_nosc":"contusionnee","lemma":"contusionner","pos":"VER"} ,
		{"word":"contusionnés","word_nosc":"contusionnes","lemma":"contusionner","pos":"VER"} ,
		{"word":"contât","word_nosc":"contat","lemma":"conter","pos":"VER"} ,
		{"word":"conté","word_nosc":"conte","lemma":"conter","pos":"VER"} ,
		{"word":"contée","word_nosc":"contee","lemma":"conter","pos":"VER"} ,
		{"word":"contées","word_nosc":"contees","lemma":"conter","pos":"VER"} ,
		{"word":"contés","word_nosc":"contes","lemma":"conter","pos":"VER"} ,
		{"word":"contînt","word_nosc":"contint","lemma":"contenir","pos":"VER"} ,
		{"word":"convainc","word_nosc":"convainc","lemma":"convaincre","pos":"VER"} ,
		{"word":"convaincra","word_nosc":"convaincra","lemma":"convaincre","pos":"VER"} ,
		{"word":"convaincrai","word_nosc":"convaincrai","lemma":"convaincre","pos":"VER"} ,
		{"word":"convaincrait","word_nosc":"convaincrait","lemma":"convaincre","pos":"VER"} ,
		{"word":"convaincras","word_nosc":"convaincras","lemma":"convaincre","pos":"VER"} ,
		{"word":"convaincre","word_nosc":"convaincre","lemma":"convaincre","pos":"VER"} ,
		{"word":"convaincrez","word_nosc":"convaincrez","lemma":"convaincre","pos":"VER"} ,
		{"word":"convaincrons","word_nosc":"convaincrons","lemma":"convaincre","pos":"VER"} ,
		{"word":"convaincront","word_nosc":"convaincront","lemma":"convaincre","pos":"VER"} ,
		{"word":"convaincs","word_nosc":"convaincs","lemma":"convaincre","pos":"VER"} ,
		{"word":"convaincu","word_nosc":"convaincu","lemma":"convaincre","pos":"VER"} ,
		{"word":"convaincue","word_nosc":"convaincue","lemma":"convaincre","pos":"VER"} ,
		{"word":"convaincues","word_nosc":"convaincues","lemma":"convaincre","pos":"VER"} ,
		{"word":"convaincus","word_nosc":"convaincus","lemma":"convaincre","pos":"VER"} ,
		{"word":"convainquaient","word_nosc":"convainquaient","lemma":"convaincre","pos":"VER"} ,
		{"word":"convainquait","word_nosc":"convainquait","lemma":"convaincre","pos":"VER"} ,
		{"word":"convainquant","word_nosc":"convainquant","lemma":"convaincre","pos":"VER"} ,
		{"word":"convainque","word_nosc":"convainque","lemma":"convaincre","pos":"VER"} ,
		{"word":"convainquent","word_nosc":"convainquent","lemma":"convaincre","pos":"VER"} ,
		{"word":"convainques","word_nosc":"convainques","lemma":"convaincre","pos":"VER"} ,
		{"word":"convainquez","word_nosc":"convainquez","lemma":"convaincre","pos":"VER"} ,
		{"word":"convainquiez","word_nosc":"convainquiez","lemma":"convaincre","pos":"VER"} ,
		{"word":"convainquirent","word_nosc":"convainquirent","lemma":"convaincre","pos":"VER"} ,
		{"word":"convainquis","word_nosc":"convainquis","lemma":"convaincre","pos":"VER"} ,
		{"word":"convainquit","word_nosc":"convainquit","lemma":"convaincre","pos":"VER"} ,
		{"word":"convainquons","word_nosc":"convainquons","lemma":"convaincre","pos":"VER"} ,
		{"word":"convenaient","word_nosc":"convenaient","lemma":"convenir","pos":"VER"} ,
		{"word":"convenais","word_nosc":"convenais","lemma":"convenir","pos":"VER"} ,
		{"word":"convenait","word_nosc":"convenait","lemma":"convenir","pos":"VER"} ,
		{"word":"convenant","word_nosc":"convenant","lemma":"convenir","pos":"VER"} ,
		{"word":"convenez","word_nosc":"convenez","lemma":"convenir","pos":"VER"} ,
		{"word":"conveniez","word_nosc":"conveniez","lemma":"convenir","pos":"VER"} ,
		{"word":"convenir","word_nosc":"convenir","lemma":"convenir","pos":"VER"} ,
		{"word":"convenons","word_nosc":"convenons","lemma":"convenir","pos":"VER"} ,
		{"word":"convenu","word_nosc":"convenu","lemma":"convenir","pos":"VER"} ,
		{"word":"convenue","word_nosc":"convenue","lemma":"convenir","pos":"VER"} ,
		{"word":"convenues","word_nosc":"convenues","lemma":"convenir","pos":"VER"} ,
		{"word":"convenus","word_nosc":"convenus","lemma":"convenir","pos":"VER"} ,
		{"word":"converge","word_nosc":"converge","lemma":"converger","pos":"VER"} ,
		{"word":"convergeaient","word_nosc":"convergeaient","lemma":"converger","pos":"VER"} ,
		{"word":"convergeait","word_nosc":"convergeait","lemma":"converger","pos":"VER"} ,
		{"word":"convergeant","word_nosc":"convergeant","lemma":"converger","pos":"VER"} ,
		{"word":"convergent","word_nosc":"convergent","lemma":"converger","pos":"VER"} ,
		{"word":"convergeons","word_nosc":"convergeons","lemma":"converger","pos":"VER"} ,
		{"word":"converger","word_nosc":"converger","lemma":"converger","pos":"VER"} ,
		{"word":"convergeraient","word_nosc":"convergeraient","lemma":"converger","pos":"VER"} ,
		{"word":"convergez","word_nosc":"convergez","lemma":"converger","pos":"VER"} ,
		{"word":"convergèrent","word_nosc":"convergerent","lemma":"converger","pos":"VER"} ,
		{"word":"convergé","word_nosc":"converge","lemma":"converger","pos":"VER"} ,
		{"word":"conversaient","word_nosc":"conversaient","lemma":"converser","pos":"VER"} ,
		{"word":"conversais","word_nosc":"conversais","lemma":"converser","pos":"VER"} ,
		{"word":"conversait","word_nosc":"conversait","lemma":"converser","pos":"VER"} ,
		{"word":"conversant","word_nosc":"conversant","lemma":"converser","pos":"VER"} ,
		{"word":"converse","word_nosc":"converse","lemma":"converser","pos":"VER"} ,
		{"word":"conversent","word_nosc":"conversent","lemma":"converser","pos":"VER"} ,
		{"word":"converser","word_nosc":"converser","lemma":"converser","pos":"VER"} ,
		{"word":"conversez","word_nosc":"conversez","lemma":"converser","pos":"VER"} ,
		{"word":"conversiez","word_nosc":"conversiez","lemma":"converser","pos":"VER"} ,
		{"word":"conversions","word_nosc":"conversions","lemma":"converser","pos":"VER"} ,
		{"word":"conversons","word_nosc":"conversons","lemma":"converser","pos":"VER"} ,
		{"word":"conversâmes","word_nosc":"conversames","lemma":"converser","pos":"VER"} ,
		{"word":"conversèrent","word_nosc":"converserent","lemma":"converser","pos":"VER"} ,
		{"word":"conversé","word_nosc":"converse","lemma":"converser","pos":"VER"} ,
		{"word":"converti","word_nosc":"converti","lemma":"convertir","pos":"VER"} ,
		{"word":"convertie","word_nosc":"convertie","lemma":"convertir","pos":"VER"} ,
		{"word":"converties","word_nosc":"converties","lemma":"convertir","pos":"VER"} ,
		{"word":"convertir","word_nosc":"convertir","lemma":"convertir","pos":"VER"} ,
		{"word":"convertira","word_nosc":"convertira","lemma":"convertir","pos":"VER"} ,
		{"word":"convertirai","word_nosc":"convertirai","lemma":"convertir","pos":"VER"} ,
		{"word":"convertirais","word_nosc":"convertirais","lemma":"convertir","pos":"VER"} ,
		{"word":"convertirait","word_nosc":"convertirait","lemma":"convertir","pos":"VER"} ,
		{"word":"convertirons","word_nosc":"convertirons","lemma":"convertir","pos":"VER"} ,
		{"word":"convertis","word_nosc":"convertis","lemma":"convertir","pos":"VER"} ,
		{"word":"convertissaient","word_nosc":"convertissaient","lemma":"convertir","pos":"VER"} ,
		{"word":"convertissais","word_nosc":"convertissais","lemma":"convertir","pos":"VER"} ,
		{"word":"convertissait","word_nosc":"convertissait","lemma":"convertir","pos":"VER"} ,
		{"word":"convertissant","word_nosc":"convertissant","lemma":"convertir","pos":"VER"} ,
		{"word":"convertisse","word_nosc":"convertisse","lemma":"convertir","pos":"VER"} ,
		{"word":"convertissent","word_nosc":"convertissent","lemma":"convertir","pos":"VER"} ,
		{"word":"convertissez","word_nosc":"convertissez","lemma":"convertir","pos":"VER"} ,
		{"word":"convertissions","word_nosc":"convertissions","lemma":"convertir","pos":"VER"} ,
		{"word":"convertit","word_nosc":"convertit","lemma":"convertir","pos":"VER"} ,
		{"word":"convertît","word_nosc":"convertit","lemma":"convertir","pos":"VER"} ,
		{"word":"convia","word_nosc":"convia","lemma":"convier","pos":"VER"} ,
		{"word":"conviaient","word_nosc":"conviaient","lemma":"convier","pos":"VER"} ,
		{"word":"conviais","word_nosc":"conviais","lemma":"convier","pos":"VER"} ,
		{"word":"conviait","word_nosc":"conviait","lemma":"convier","pos":"VER"} ,
		{"word":"conviant","word_nosc":"conviant","lemma":"convier","pos":"VER"} ,
		{"word":"convie","word_nosc":"convie","lemma":"convier","pos":"VER"} ,
		{"word":"conviendra","word_nosc":"conviendra","lemma":"convenir","pos":"VER"} ,
		{"word":"conviendrai","word_nosc":"conviendrai","lemma":"convenir","pos":"VER"} ,
		{"word":"conviendraient","word_nosc":"conviendraient","lemma":"convenir","pos":"VER"} ,
		{"word":"conviendrais","word_nosc":"conviendrais","lemma":"convenir","pos":"VER"} ,
		{"word":"conviendrait","word_nosc":"conviendrait","lemma":"convenir","pos":"VER"} ,
		{"word":"conviendras","word_nosc":"conviendras","lemma":"convenir","pos":"VER"} ,
		{"word":"conviendrez","word_nosc":"conviendrez","lemma":"convenir","pos":"VER"} ,
		{"word":"conviendrions","word_nosc":"conviendrions","lemma":"convenir","pos":"VER"} ,
		{"word":"conviendrons","word_nosc":"conviendrons","lemma":"convenir","pos":"VER"} ,
		{"word":"conviendront","word_nosc":"conviendront","lemma":"convenir","pos":"VER"} ,
		{"word":"convienne","word_nosc":"convienne","lemma":"convenir","pos":"VER"} ,
		{"word":"conviennent","word_nosc":"conviennent","lemma":"convenir","pos":"VER"} ,
		{"word":"conviens","word_nosc":"conviens","lemma":"convenir","pos":"VER"} ,
		{"word":"convient","word_nosc":"convient","lemma":"convier","pos":"VER"} ,
		{"word":"convient","word_nosc":"convient","lemma":"convenir","pos":"VER"} ,
		{"word":"convier","word_nosc":"convier","lemma":"convier","pos":"VER"} ,
		{"word":"conviera","word_nosc":"conviera","lemma":"convier","pos":"VER"} ,
		{"word":"convierai","word_nosc":"convierai","lemma":"convier","pos":"VER"} ,
		{"word":"convierait","word_nosc":"convierait","lemma":"convier","pos":"VER"} ,
		{"word":"conviez","word_nosc":"conviez","lemma":"convier","pos":"VER"} ,
		{"word":"convinrent","word_nosc":"convinrent","lemma":"convenir","pos":"VER"} ,
		{"word":"convins","word_nosc":"convins","lemma":"convenir","pos":"VER"} ,
		{"word":"convint","word_nosc":"convint","lemma":"convenir","pos":"VER"} ,
		{"word":"convions","word_nosc":"convions","lemma":"convier","pos":"VER"} ,
		{"word":"convivialisez","word_nosc":"convivialisez","lemma":"convivialiser","pos":"VER"} ,
		{"word":"convièrent","word_nosc":"convierent","lemma":"convier","pos":"VER"} ,
		{"word":"convié","word_nosc":"convie","lemma":"convier","pos":"VER"} ,
		{"word":"conviée","word_nosc":"conviee","lemma":"convier","pos":"VER"} ,
		{"word":"conviées","word_nosc":"conviees","lemma":"convier","pos":"VER"} ,
		{"word":"conviés","word_nosc":"convies","lemma":"convier","pos":"VER"} ,
		{"word":"convoie","word_nosc":"convoie","lemma":"convoyer","pos":"VER"} ,
		{"word":"convoita","word_nosc":"convoita","lemma":"convoiter","pos":"VER"} ,
		{"word":"convoitaient","word_nosc":"convoitaient","lemma":"convoiter","pos":"VER"} ,
		{"word":"convoitais","word_nosc":"convoitais","lemma":"convoiter","pos":"VER"} ,
		{"word":"convoitait","word_nosc":"convoitait","lemma":"convoiter","pos":"VER"} ,
		{"word":"convoitant","word_nosc":"convoitant","lemma":"convoiter","pos":"VER"} ,
		{"word":"convoite","word_nosc":"convoite","lemma":"convoiter","pos":"VER"} ,
		{"word":"convoitent","word_nosc":"convoitent","lemma":"convoiter","pos":"VER"} ,
		{"word":"convoiter","word_nosc":"convoiter","lemma":"convoiter","pos":"VER"} ,
		{"word":"convoiteras","word_nosc":"convoiteras","lemma":"convoiter","pos":"VER"} ,
		{"word":"convoiteront","word_nosc":"convoiteront","lemma":"convoiter","pos":"VER"} ,
		{"word":"convoitez","word_nosc":"convoitez","lemma":"convoiter","pos":"VER"} ,
		{"word":"convoité","word_nosc":"convoite","lemma":"convoiter","pos":"VER"} ,
		{"word":"convoitée","word_nosc":"convoitee","lemma":"convoiter","pos":"VER"} ,
		{"word":"convoitées","word_nosc":"convoitees","lemma":"convoiter","pos":"VER"} ,
		{"word":"convoités","word_nosc":"convoites","lemma":"convoiter","pos":"VER"} ,
		{"word":"convola","word_nosc":"convola","lemma":"convoler","pos":"VER"} ,
		{"word":"convolait","word_nosc":"convolait","lemma":"convoler","pos":"VER"} ,
		{"word":"convolant","word_nosc":"convolant","lemma":"convoler","pos":"VER"} ,
		{"word":"convolent","word_nosc":"convolent","lemma":"convoler","pos":"VER"} ,
		{"word":"convoler","word_nosc":"convoler","lemma":"convoler","pos":"VER"} ,
		{"word":"convolèrent","word_nosc":"convolerent","lemma":"convoler","pos":"VER"} ,
		{"word":"convolé","word_nosc":"convole","lemma":"convoler","pos":"VER"} ,
		{"word":"convoqua","word_nosc":"convoqua","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquai","word_nosc":"convoquai","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquaient","word_nosc":"convoquaient","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquait","word_nosc":"convoquait","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquant","word_nosc":"convoquant","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoque","word_nosc":"convoque","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquent","word_nosc":"convoquent","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquer","word_nosc":"convoquer","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquera","word_nosc":"convoquera","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquerai","word_nosc":"convoquerai","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoqueraient","word_nosc":"convoqueraient","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquerait","word_nosc":"convoquerait","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquerez","word_nosc":"convoquerez","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoqueront","word_nosc":"convoqueront","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquez","word_nosc":"convoquez","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquions","word_nosc":"convoquions","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquons","word_nosc":"convoquons","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquât","word_nosc":"convoquat","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquèrent","word_nosc":"convoquerent","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoqué","word_nosc":"convoque","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquée","word_nosc":"convoquee","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoquées","word_nosc":"convoquees","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoqués","word_nosc":"convoques","lemma":"convoquer","pos":"VER"} ,
		{"word":"convoya","word_nosc":"convoya","lemma":"convoyer","pos":"VER"} ,
		{"word":"convoyaient","word_nosc":"convoyaient","lemma":"convoyer","pos":"VER"} ,
		{"word":"convoyait","word_nosc":"convoyait","lemma":"convoyer","pos":"VER"} ,
		{"word":"convoyant","word_nosc":"convoyant","lemma":"convoyer","pos":"VER"} ,
		{"word":"convoyer","word_nosc":"convoyer","lemma":"convoyer","pos":"VER"} ,
		{"word":"convoyé","word_nosc":"convoye","lemma":"convoyer","pos":"VER"} ,
		{"word":"convoyée","word_nosc":"convoyee","lemma":"convoyer","pos":"VER"} ,
		{"word":"convoyés","word_nosc":"convoyes","lemma":"convoyer","pos":"VER"} ,
		{"word":"convulsa","word_nosc":"convulsa","lemma":"convulser","pos":"VER"} ,
		{"word":"convulsai","word_nosc":"convulsai","lemma":"convulser","pos":"VER"} ,
		{"word":"convulsais","word_nosc":"convulsais","lemma":"convulser","pos":"VER"} ,
		{"word":"convulsait","word_nosc":"convulsait","lemma":"convulser","pos":"VER"} ,
		{"word":"convulsant","word_nosc":"convulsant","lemma":"convulser","pos":"VER"} ,
		{"word":"convulse","word_nosc":"convulse","lemma":"convulser","pos":"VER"} ,
		{"word":"convulser","word_nosc":"convulser","lemma":"convulser","pos":"VER"} ,
		{"word":"convulsionne","word_nosc":"convulsionne","lemma":"convulsionner","pos":"VER"} ,
		{"word":"convulsionnée","word_nosc":"convulsionnee","lemma":"convulsionner","pos":"VER"} ,
		{"word":"convulsions","word_nosc":"convulsions","lemma":"convulser","pos":"VER"} ,
		{"word":"convulsé","word_nosc":"convulse","lemma":"convulser","pos":"VER"} ,
		{"word":"convulsée","word_nosc":"convulsee","lemma":"convulser","pos":"VER"} ,
		{"word":"convulsés","word_nosc":"convulses","lemma":"convulser","pos":"VER"} ,
		{"word":"convînmes","word_nosc":"convinmes","lemma":"convenir","pos":"VER"} ,
		{"word":"convînt","word_nosc":"convint","lemma":"convenir","pos":"VER"} ,
		{"word":"conçois","word_nosc":"concois","lemma":"concevoir","pos":"VER"} ,
		{"word":"conçoit","word_nosc":"concoit","lemma":"concevoir","pos":"VER"} ,
		{"word":"conçoivent","word_nosc":"concoivent","lemma":"concevoir","pos":"VER"} ,
		{"word":"conçu","word_nosc":"concu","lemma":"concevoir","pos":"VER"} ,
		{"word":"conçue","word_nosc":"concue","lemma":"concevoir","pos":"VER"} ,
		{"word":"conçues","word_nosc":"concues","lemma":"concevoir","pos":"VER"} ,
		{"word":"conçurent","word_nosc":"concurent","lemma":"concevoir","pos":"VER"} ,
		{"word":"conçus","word_nosc":"concus","lemma":"concevoir","pos":"VER"} ,
		{"word":"conçut","word_nosc":"concut","lemma":"concevoir","pos":"VER"} ,
		{"word":"cooptant","word_nosc":"cooptant","lemma":"coopter","pos":"VER"} ,
		{"word":"coopter","word_nosc":"coopter","lemma":"coopter","pos":"VER"} ,
		{"word":"coopté","word_nosc":"coopte","lemma":"coopter","pos":"VER"} ,
		{"word":"coopère","word_nosc":"coopere","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopèrent","word_nosc":"cooperent","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopères","word_nosc":"cooperes","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopérais","word_nosc":"cooperais","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopérait","word_nosc":"cooperait","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopérant","word_nosc":"cooperant","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopérer","word_nosc":"cooperer","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopérera","word_nosc":"cooperera","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopérerai","word_nosc":"coopererai","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopéreraient","word_nosc":"coopereraient","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopérerais","word_nosc":"coopererais","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopérerez","word_nosc":"coopererez","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopéreriez","word_nosc":"coopereriez","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopérerons","word_nosc":"coopererons","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopéreront","word_nosc":"coopereront","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopérez","word_nosc":"cooperez","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopériez","word_nosc":"cooperiez","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopérions","word_nosc":"cooperions","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopérons","word_nosc":"cooperons","lemma":"coopérer","pos":"VER"} ,
		{"word":"coopéré","word_nosc":"coopere","lemma":"coopérer","pos":"VER"} ,
		{"word":"coordonna","word_nosc":"coordonna","lemma":"coordonner","pos":"VER"} ,
		{"word":"coordonnaient","word_nosc":"coordonnaient","lemma":"coordonner","pos":"VER"} ,
		{"word":"coordonnait","word_nosc":"coordonnait","lemma":"coordonner","pos":"VER"} ,
		{"word":"coordonnant","word_nosc":"coordonnant","lemma":"coordonner","pos":"VER"} ,
		{"word":"coordonne","word_nosc":"coordonne","lemma":"coordonner","pos":"VER"} ,
		{"word":"coordonner","word_nosc":"coordonner","lemma":"coordonner","pos":"VER"} ,
		{"word":"coordonnera","word_nosc":"coordonnera","lemma":"coordonner","pos":"VER"} ,
		{"word":"coordonnerait","word_nosc":"coordonnerait","lemma":"coordonner","pos":"VER"} ,
		{"word":"coordonnez","word_nosc":"coordonnez","lemma":"coordonner","pos":"VER"} ,
		{"word":"coordonnons","word_nosc":"coordonnons","lemma":"coordonner","pos":"VER"} ,
		{"word":"coordonné","word_nosc":"coordonne","lemma":"coordonner","pos":"VER"} ,
		{"word":"coordonnée","word_nosc":"coordonnee","lemma":"coordonner","pos":"VER"} ,
		{"word":"coordonnées","word_nosc":"coordonnees","lemma":"coordonner","pos":"VER"} ,
		{"word":"copia","word_nosc":"copia","lemma":"copier","pos":"VER"} ,
		{"word":"copiai","word_nosc":"copiai","lemma":"copier","pos":"VER"} ,
		{"word":"copiaient","word_nosc":"copiaient","lemma":"copier","pos":"VER"} ,
		{"word":"copiais","word_nosc":"copiais","lemma":"copier","pos":"VER"} ,
		{"word":"copiait","word_nosc":"copiait","lemma":"copier","pos":"VER"} ,
		{"word":"copiant","word_nosc":"copiant","lemma":"copier","pos":"VER"} ,
		{"word":"copie","word_nosc":"copie","lemma":"copier","pos":"VER"} ,
		{"word":"copient","word_nosc":"copient","lemma":"copier","pos":"VER"} ,
		{"word":"copier","word_nosc":"copier","lemma":"copier","pos":"VER"} ,
		{"word":"copiera","word_nosc":"copiera","lemma":"copier","pos":"VER"} ,
		{"word":"copierai","word_nosc":"copierai","lemma":"copier","pos":"VER"} ,
		{"word":"copierait","word_nosc":"copierait","lemma":"copier","pos":"VER"} ,
		{"word":"copieras","word_nosc":"copieras","lemma":"copier","pos":"VER"} ,
		{"word":"copierez","word_nosc":"copierez","lemma":"copier","pos":"VER"} ,
		{"word":"copieront","word_nosc":"copieront","lemma":"copier","pos":"VER"} ,
		{"word":"copies","word_nosc":"copies","lemma":"copier","pos":"VER"} ,
		{"word":"copiez","word_nosc":"copiez","lemma":"copier","pos":"VER"} ,
		{"word":"copinait","word_nosc":"copinait","lemma":"copiner","pos":"VER"} ,
		{"word":"copiner","word_nosc":"copiner","lemma":"copiner","pos":"VER"} ,
		{"word":"copiniez","word_nosc":"copiniez","lemma":"copiner","pos":"VER"} ,
		{"word":"copiné","word_nosc":"copine","lemma":"copiner","pos":"VER"} ,
		{"word":"copié","word_nosc":"copie","lemma":"copier","pos":"VER"} ,
		{"word":"copiée","word_nosc":"copiee","lemma":"copier","pos":"VER"} ,
		{"word":"copiées","word_nosc":"copiees","lemma":"copier","pos":"VER"} ,
		{"word":"copiés","word_nosc":"copies","lemma":"copier","pos":"VER"} ,
		{"word":"coproduire","word_nosc":"coproduire","lemma":"coproduire","pos":"VER"} ,
		{"word":"coproduit","word_nosc":"coproduit","lemma":"coproduire","pos":"VER"} ,
		{"word":"coprésident","word_nosc":"copresident","lemma":"coprésider","pos":"VER"} ,
		{"word":"copula","word_nosc":"copula","lemma":"copuler","pos":"VER"} ,
		{"word":"copulait","word_nosc":"copulait","lemma":"copuler","pos":"VER"} ,
		{"word":"copulant","word_nosc":"copulant","lemma":"copuler","pos":"VER"} ,
		{"word":"copulent","word_nosc":"copulent","lemma":"copuler","pos":"VER"} ,
		{"word":"copuler","word_nosc":"copuler","lemma":"copuler","pos":"VER"} ,
		{"word":"copulez","word_nosc":"copulez","lemma":"copuler","pos":"VER"} ,
		{"word":"copulé","word_nosc":"copule","lemma":"copuler","pos":"VER"} ,
		{"word":"coqueter","word_nosc":"coqueter","lemma":"coqueter","pos":"VER"} ,
		{"word":"corde","word_nosc":"corde","lemma":"corder","pos":"VER"} ,
		{"word":"corder","word_nosc":"corder","lemma":"corder","pos":"VER"} ,
		{"word":"cordes","word_nosc":"cordes","lemma":"corder","pos":"VER"} ,
		{"word":"cordonner","word_nosc":"cordonner","lemma":"cordonner","pos":"VER"} ,
		{"word":"cordonnées","word_nosc":"cordonnees","lemma":"cordonner","pos":"VER"} ,
		{"word":"cordons","word_nosc":"cordons","lemma":"corder","pos":"VER"} ,
		{"word":"cordé","word_nosc":"corde","lemma":"corder","pos":"VER"} ,
		{"word":"cordées","word_nosc":"cordees","lemma":"corder","pos":"VER"} ,
		{"word":"corna","word_nosc":"corna","lemma":"corner","pos":"VER"} ,
		{"word":"cornaient","word_nosc":"cornaient","lemma":"corner","pos":"VER"} ,
		{"word":"cornais","word_nosc":"cornais","lemma":"corner","pos":"VER"} ,
		{"word":"cornait","word_nosc":"cornait","lemma":"corner","pos":"VER"} ,
		{"word":"cornant","word_nosc":"cornant","lemma":"corner","pos":"VER"} ,
		{"word":"cornaquaient","word_nosc":"cornaquaient","lemma":"cornaquer","pos":"VER"} ,
		{"word":"cornaquée","word_nosc":"cornaquee","lemma":"cornaquer","pos":"VER"} ,
		{"word":"cornas","word_nosc":"cornas","lemma":"corner","pos":"VER"} ,
		{"word":"corne","word_nosc":"corne","lemma":"corner","pos":"VER"} ,
		{"word":"cornent","word_nosc":"cornent","lemma":"corner","pos":"VER"} ,
		{"word":"corner","word_nosc":"corner","lemma":"corner","pos":"VER"} ,
		{"word":"cornes","word_nosc":"cornes","lemma":"corner","pos":"VER"} ,
		{"word":"cornèrent","word_nosc":"cornerent","lemma":"corner","pos":"VER"} ,
		{"word":"corné","word_nosc":"corne","lemma":"corner","pos":"VER"} ,
		{"word":"cornée","word_nosc":"cornee","lemma":"corner","pos":"VER"} ,
		{"word":"cornés","word_nosc":"cornes","lemma":"corner","pos":"VER"} ,
		{"word":"corporalisés","word_nosc":"corporalises","lemma":"corporaliser","pos":"VER"} ,
		{"word":"correctionnaliser","word_nosc":"correctionnaliser","lemma":"correctionnaliser","pos":"VER"} ,
		{"word":"correspond","word_nosc":"correspond","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondaient","word_nosc":"correspondaient","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondais","word_nosc":"correspondais","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondait","word_nosc":"correspondait","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondant","word_nosc":"correspondant","lemma":"correspondre","pos":"VER"} ,
		{"word":"corresponde","word_nosc":"corresponde","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondent","word_nosc":"correspondent","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondez","word_nosc":"correspondez","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondiez","word_nosc":"correspondiez","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondions","word_nosc":"correspondions","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondra","word_nosc":"correspondra","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondrai","word_nosc":"correspondrai","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondraient","word_nosc":"correspondraient","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondrait","word_nosc":"correspondrait","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondre","word_nosc":"correspondre","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondrons","word_nosc":"correspondrons","lemma":"correspondre","pos":"VER"} ,
		{"word":"corresponds","word_nosc":"corresponds","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondu","word_nosc":"correspondu","lemma":"correspondre","pos":"VER"} ,
		{"word":"correspondît","word_nosc":"correspondit","lemma":"correspondre","pos":"VER"} ,
		{"word":"corrige","word_nosc":"corrige","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigea","word_nosc":"corrigea","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigeai","word_nosc":"corrigeai","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigeaient","word_nosc":"corrigeaient","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigeais","word_nosc":"corrigeais","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigeait","word_nosc":"corrigeait","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigeant","word_nosc":"corrigeant","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigent","word_nosc":"corrigent","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigeons","word_nosc":"corrigeons","lemma":"corriger","pos":"VER"} ,
		{"word":"corriger","word_nosc":"corriger","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigera","word_nosc":"corrigera","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigerai","word_nosc":"corrigerai","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigerais","word_nosc":"corrigerais","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigerait","word_nosc":"corrigerait","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigeras","word_nosc":"corrigeras","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigerez","word_nosc":"corrigerez","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigerons","word_nosc":"corrigerons","lemma":"corriger","pos":"VER"} ,
		{"word":"corriges","word_nosc":"corriges","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigez","word_nosc":"corrigez","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigiez","word_nosc":"corrigiez","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigions","word_nosc":"corrigions","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigé","word_nosc":"corrige","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigée","word_nosc":"corrigee","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigées","word_nosc":"corrigees","lemma":"corriger","pos":"VER"} ,
		{"word":"corrigés","word_nosc":"corriges","lemma":"corriger","pos":"VER"} ,
		{"word":"corrobora","word_nosc":"corrobora","lemma":"corroborer","pos":"VER"} ,
		{"word":"corroboraient","word_nosc":"corroboraient","lemma":"corroborer","pos":"VER"} ,
		{"word":"corroborait","word_nosc":"corroborait","lemma":"corroborer","pos":"VER"} ,
		{"word":"corroborant","word_nosc":"corroborant","lemma":"corroborer","pos":"VER"} ,
		{"word":"corrobore","word_nosc":"corrobore","lemma":"corroborer","pos":"VER"} ,
		{"word":"corroborent","word_nosc":"corroborent","lemma":"corroborer","pos":"VER"} ,
		{"word":"corroborer","word_nosc":"corroborer","lemma":"corroborer","pos":"VER"} ,
		{"word":"corroborera","word_nosc":"corroborera","lemma":"corroborer","pos":"VER"} ,
		{"word":"corroborèrent","word_nosc":"corroborerent","lemma":"corroborer","pos":"VER"} ,
		{"word":"corroboré","word_nosc":"corrobore","lemma":"corroborer","pos":"VER"} ,
		{"word":"corroborées","word_nosc":"corroborees","lemma":"corroborer","pos":"VER"} ,
		{"word":"corrodait","word_nosc":"corrodait","lemma":"corroder","pos":"VER"} ,
		{"word":"corrode","word_nosc":"corrode","lemma":"corroder","pos":"VER"} ,
		{"word":"corrodent","word_nosc":"corrodent","lemma":"corroder","pos":"VER"} ,
		{"word":"corroder","word_nosc":"corroder","lemma":"corroder","pos":"VER"} ,
		{"word":"corrodé","word_nosc":"corrode","lemma":"corroder","pos":"VER"} ,
		{"word":"corrodée","word_nosc":"corrodee","lemma":"corroder","pos":"VER"} ,
		{"word":"corrodées","word_nosc":"corrodees","lemma":"corroder","pos":"VER"} ,
		{"word":"corrodés","word_nosc":"corrodes","lemma":"corroder","pos":"VER"} ,
		{"word":"corrompais","word_nosc":"corrompais","lemma":"corrompre","pos":"VER"} ,
		{"word":"corrompant","word_nosc":"corrompant","lemma":"corrompre","pos":"VER"} ,
		{"word":"corrompe","word_nosc":"corrompe","lemma":"corrompre","pos":"VER"} ,
		{"word":"corrompent","word_nosc":"corrompent","lemma":"corrompre","pos":"VER"} ,
		{"word":"corrompez","word_nosc":"corrompez","lemma":"corrompre","pos":"VER"} ,
		{"word":"corrompis","word_nosc":"corrompis","lemma":"corrompre","pos":"VER"} ,
		{"word":"corrompra","word_nosc":"corrompra","lemma":"corrompre","pos":"VER"} ,
		{"word":"corrompraient","word_nosc":"corrompraient","lemma":"corrompre","pos":"VER"} ,
		{"word":"corrompre","word_nosc":"corrompre","lemma":"corrompre","pos":"VER"} ,
		{"word":"corromps","word_nosc":"corromps","lemma":"corrompre","pos":"VER"} ,
		{"word":"corrompt","word_nosc":"corrompt","lemma":"corrompre","pos":"VER"} ,
		{"word":"corrompu","word_nosc":"corrompu","lemma":"corrompre","pos":"VER"} ,
		{"word":"corrompue","word_nosc":"corrompue","lemma":"corrompre","pos":"VER"} ,
		{"word":"corrompues","word_nosc":"corrompues","lemma":"corrompre","pos":"VER"} ,
		{"word":"corrompus","word_nosc":"corrompus","lemma":"corrompre","pos":"VER"} ,
		{"word":"corréler","word_nosc":"correler","lemma":"corréler","pos":"VER"} ,
		{"word":"corsa","word_nosc":"corsa","lemma":"corser","pos":"VER"} ,
		{"word":"corsait","word_nosc":"corsait","lemma":"corser","pos":"VER"} ,
		{"word":"corsant","word_nosc":"corsant","lemma":"corser","pos":"VER"} ,
		{"word":"corse","word_nosc":"corse","lemma":"corser","pos":"VER"} ,
		{"word":"corsent","word_nosc":"corsent","lemma":"corser","pos":"VER"} ,
		{"word":"corser","word_nosc":"corser","lemma":"corser","pos":"VER"} ,
		{"word":"corserait","word_nosc":"corserait","lemma":"corser","pos":"VER"} ,
		{"word":"corseter","word_nosc":"corseter","lemma":"corseter","pos":"VER"} ,
		{"word":"corseté","word_nosc":"corsete","lemma":"corseter","pos":"VER"} ,
		{"word":"corsetée","word_nosc":"corsetee","lemma":"corseter","pos":"VER"} ,
		{"word":"corsetées","word_nosc":"corsetees","lemma":"corseter","pos":"VER"} ,
		{"word":"corsetés","word_nosc":"corsetes","lemma":"corseter","pos":"VER"} ,
		{"word":"corsé","word_nosc":"corse","lemma":"corser","pos":"VER"} ,
		{"word":"corsée","word_nosc":"corsee","lemma":"corser","pos":"VER"} ,
		{"word":"corsés","word_nosc":"corses","lemma":"corser","pos":"VER"} ,
		{"word":"cortégeant","word_nosc":"cortegeant","lemma":"cortéger","pos":"VER"} ,
		{"word":"cosigner","word_nosc":"cosigner","lemma":"cosigner","pos":"VER"} ,
		{"word":"cosigné","word_nosc":"cosigne","lemma":"cosigner","pos":"VER"} ,
		{"word":"cosmétiqué","word_nosc":"cosmetique","lemma":"cosmétiquer","pos":"VER"} ,
		{"word":"cosmétiquée","word_nosc":"cosmetiquee","lemma":"cosmétiquer","pos":"VER"} ,
		{"word":"cosmétiquées","word_nosc":"cosmetiquees","lemma":"cosmétiquer","pos":"VER"} ,
		{"word":"cosmétiqués","word_nosc":"cosmetiques","lemma":"cosmétiquer","pos":"VER"} ,
		{"word":"cossent","word_nosc":"cossent","lemma":"cosser","pos":"VER"} ,
		{"word":"costume","word_nosc":"costume","lemma":"costumer","pos":"VER"} ,
		{"word":"costumer","word_nosc":"costumer","lemma":"costumer","pos":"VER"} ,
		{"word":"costumes","word_nosc":"costumes","lemma":"costumer","pos":"VER"} ,
		{"word":"costumé","word_nosc":"costume","lemma":"costumer","pos":"VER"} ,
		{"word":"costumée","word_nosc":"costumee","lemma":"costumer","pos":"VER"} ,
		{"word":"costumées","word_nosc":"costumees","lemma":"costumer","pos":"VER"} ,
		{"word":"costumés","word_nosc":"costumes","lemma":"costumer","pos":"VER"} ,
		{"word":"cota","word_nosc":"cota","lemma":"coter","pos":"VER"} ,
		{"word":"cotait","word_nosc":"cotait","lemma":"coter","pos":"VER"} ,
		{"word":"cote","word_nosc":"cote","lemma":"coter","pos":"VER"} ,
		{"word":"cotent","word_nosc":"cotent","lemma":"coter","pos":"VER"} ,
		{"word":"coter","word_nosc":"coter","lemma":"coter","pos":"VER"} ,
		{"word":"cotes","word_nosc":"cotes","lemma":"coter","pos":"VER"} ,
		{"word":"coties","word_nosc":"coties","lemma":"cotir","pos":"VER"} ,
		{"word":"cotisa","word_nosc":"cotisa","lemma":"cotiser","pos":"VER"} ,
		{"word":"cotisaient","word_nosc":"cotisaient","lemma":"cotiser","pos":"VER"} ,
		{"word":"cotisais","word_nosc":"cotisais","lemma":"cotiser","pos":"VER"} ,
		{"word":"cotisant","word_nosc":"cotisant","lemma":"cotiser","pos":"VER"} ,
		{"word":"cotise","word_nosc":"cotise","lemma":"cotiser","pos":"VER"} ,
		{"word":"cotisent","word_nosc":"cotisent","lemma":"cotiser","pos":"VER"} ,
		{"word":"cotiser","word_nosc":"cotiser","lemma":"cotiser","pos":"VER"} ,
		{"word":"cotises","word_nosc":"cotises","lemma":"cotiser","pos":"VER"} ,
		{"word":"cotisèrent","word_nosc":"cotiserent","lemma":"cotiser","pos":"VER"} ,
		{"word":"cotisé","word_nosc":"cotise","lemma":"cotiser","pos":"VER"} ,
		{"word":"cotisées","word_nosc":"cotisees","lemma":"cotiser","pos":"VER"} ,
		{"word":"cotisés","word_nosc":"cotises","lemma":"cotiser","pos":"VER"} ,
		{"word":"cotonnait","word_nosc":"cotonnait","lemma":"cotonner","pos":"VER"} ,
		{"word":"cotonnant","word_nosc":"cotonnant","lemma":"cotonner","pos":"VER"} ,
		{"word":"cotât","word_nosc":"cotat","lemma":"coter","pos":"VER"} ,
		{"word":"coté","word_nosc":"cote","lemma":"coter","pos":"VER"} ,
		{"word":"cotée","word_nosc":"cotee","lemma":"coter","pos":"VER"} ,
		{"word":"cotées","word_nosc":"cotees","lemma":"coter","pos":"VER"} ,
		{"word":"cotés","word_nosc":"cotes","lemma":"coter","pos":"VER"} ,
		{"word":"couaquait","word_nosc":"couaquait","lemma":"couaquer","pos":"VER"} ,
		{"word":"couaquer","word_nosc":"couaquer","lemma":"couaquer","pos":"VER"} ,
		{"word":"coucha","word_nosc":"coucha","lemma":"coucher","pos":"VER"} ,
		{"word":"couchai","word_nosc":"couchai","lemma":"coucher","pos":"VER"} ,
		{"word":"couchaient","word_nosc":"couchaient","lemma":"coucher","pos":"VER"} ,
		{"word":"couchailler","word_nosc":"couchailler","lemma":"couchailler","pos":"VER"} ,
		{"word":"couchais","word_nosc":"couchais","lemma":"coucher","pos":"VER"} ,
		{"word":"couchait","word_nosc":"couchait","lemma":"coucher","pos":"VER"} ,
		{"word":"couchant","word_nosc":"couchant","lemma":"coucher","pos":"VER"} ,
		{"word":"couche","word_nosc":"couche","lemma":"coucher","pos":"VER"} ,
		{"word":"couchent","word_nosc":"couchent","lemma":"coucher","pos":"VER"} ,
		{"word":"coucher","word_nosc":"coucher","lemma":"coucher","pos":"VER"} ,
		{"word":"couchera","word_nosc":"couchera","lemma":"coucher","pos":"VER"} ,
		{"word":"coucherai","word_nosc":"coucherai","lemma":"coucher","pos":"VER"} ,
		{"word":"coucheraient","word_nosc":"coucheraient","lemma":"coucher","pos":"VER"} ,
		{"word":"coucherais","word_nosc":"coucherais","lemma":"coucher","pos":"VER"} ,
		{"word":"coucherait","word_nosc":"coucherait","lemma":"coucher","pos":"VER"} ,
		{"word":"coucheras","word_nosc":"coucheras","lemma":"coucher","pos":"VER"} ,
		{"word":"coucherez","word_nosc":"coucherez","lemma":"coucher","pos":"VER"} ,
		{"word":"coucheriez","word_nosc":"coucheriez","lemma":"coucher","pos":"VER"} ,
		{"word":"coucherions","word_nosc":"coucherions","lemma":"coucher","pos":"VER"} ,
		{"word":"coucherons","word_nosc":"coucherons","lemma":"coucher","pos":"VER"} ,
		{"word":"coucheront","word_nosc":"coucheront","lemma":"coucher","pos":"VER"} ,
		{"word":"couches","word_nosc":"couches","lemma":"coucher","pos":"VER"} ,
		{"word":"couchez","word_nosc":"couchez","lemma":"coucher","pos":"VER"} ,
		{"word":"couchiez","word_nosc":"couchiez","lemma":"coucher","pos":"VER"} ,
		{"word":"couchions","word_nosc":"couchions","lemma":"coucher","pos":"VER"} ,
		{"word":"couchons","word_nosc":"couchons","lemma":"coucher","pos":"VER"} ,
		{"word":"couchâmes","word_nosc":"couchames","lemma":"coucher","pos":"VER"} ,
		{"word":"couchât","word_nosc":"couchat","lemma":"coucher","pos":"VER"} ,
		{"word":"couchèrent","word_nosc":"coucherent","lemma":"coucher","pos":"VER"} ,
		{"word":"couché","word_nosc":"couche","lemma":"coucher","pos":"VER"} ,
		{"word":"couchée","word_nosc":"couchee","lemma":"coucher","pos":"VER"} ,
		{"word":"couchées","word_nosc":"couchees","lemma":"coucher","pos":"VER"} ,
		{"word":"couchés","word_nosc":"couches","lemma":"coucher","pos":"VER"} ,
		{"word":"coud","word_nosc":"coud","lemma":"coudre","pos":"VER"} ,
		{"word":"coudent","word_nosc":"coudent","lemma":"couder","pos":"VER"} ,
		{"word":"coudoie","word_nosc":"coudoie","lemma":"coudoyer","pos":"VER"} ,
		{"word":"coudoient","word_nosc":"coudoient","lemma":"coudoyer","pos":"VER"} ,
		{"word":"coudoyai","word_nosc":"coudoyai","lemma":"coudoyer","pos":"VER"} ,
		{"word":"coudoyaient","word_nosc":"coudoyaient","lemma":"coudoyer","pos":"VER"} ,
		{"word":"coudoyais","word_nosc":"coudoyais","lemma":"coudoyer","pos":"VER"} ,
		{"word":"coudoyant","word_nosc":"coudoyant","lemma":"coudoyer","pos":"VER"} ,
		{"word":"coudoyer","word_nosc":"coudoyer","lemma":"coudoyer","pos":"VER"} ,
		{"word":"coudoyons","word_nosc":"coudoyons","lemma":"coudoyer","pos":"VER"} ,
		{"word":"coudoyé","word_nosc":"coudoye","lemma":"coudoyer","pos":"VER"} ,
		{"word":"coudra","word_nosc":"coudra","lemma":"coudre","pos":"VER"} ,
		{"word":"coudrai","word_nosc":"coudrai","lemma":"coudre","pos":"VER"} ,
		{"word":"coudrais","word_nosc":"coudrais","lemma":"coudre","pos":"VER"} ,
		{"word":"coudrait","word_nosc":"coudrait","lemma":"coudre","pos":"VER"} ,
		{"word":"coudras","word_nosc":"coudras","lemma":"coudre","pos":"VER"} ,
		{"word":"coudre","word_nosc":"coudre","lemma":"coudre","pos":"VER"} ,
		{"word":"couds","word_nosc":"couds","lemma":"coudre","pos":"VER"} ,
		{"word":"coudé","word_nosc":"coude","lemma":"couder","pos":"VER"} ,
		{"word":"coudée","word_nosc":"coudee","lemma":"couder","pos":"VER"} ,
		{"word":"coudées","word_nosc":"coudees","lemma":"couder","pos":"VER"} ,
		{"word":"couillonne","word_nosc":"couillonne","lemma":"couillonner","pos":"VER"} ,
		{"word":"couillonnent","word_nosc":"couillonnent","lemma":"couillonner","pos":"VER"} ,
		{"word":"couillonner","word_nosc":"couillonner","lemma":"couillonner","pos":"VER"} ,
		{"word":"couillonné","word_nosc":"couillonne","lemma":"couillonner","pos":"VER"} ,
		{"word":"couillonnée","word_nosc":"couillonnee","lemma":"couillonner","pos":"VER"} ,
		{"word":"couillonnés","word_nosc":"couillonnes","lemma":"couillonner","pos":"VER"} ,
		{"word":"couina","word_nosc":"couina","lemma":"couiner","pos":"VER"} ,
		{"word":"couinaient","word_nosc":"couinaient","lemma":"couiner","pos":"VER"} ,
		{"word":"couinais","word_nosc":"couinais","lemma":"couiner","pos":"VER"} ,
		{"word":"couinait","word_nosc":"couinait","lemma":"couiner","pos":"VER"} ,
		{"word":"couinant","word_nosc":"couinant","lemma":"couiner","pos":"VER"} ,
		{"word":"couine","word_nosc":"couine","lemma":"couiner","pos":"VER"} ,
		{"word":"couinent","word_nosc":"couinent","lemma":"couiner","pos":"VER"} ,
		{"word":"couiner","word_nosc":"couiner","lemma":"couiner","pos":"VER"} ,
		{"word":"couinerais","word_nosc":"couinerais","lemma":"couiner","pos":"VER"} ,
		{"word":"couineras","word_nosc":"couineras","lemma":"couiner","pos":"VER"} ,
		{"word":"couineront","word_nosc":"couineront","lemma":"couiner","pos":"VER"} ,
		{"word":"couines","word_nosc":"couines","lemma":"couiner","pos":"VER"} ,
		{"word":"couinez","word_nosc":"couinez","lemma":"couiner","pos":"VER"} ,
		{"word":"couiné","word_nosc":"couine","lemma":"couiner","pos":"VER"} ,
		{"word":"coula","word_nosc":"coula","lemma":"couler","pos":"VER"} ,
		{"word":"coulai","word_nosc":"coulai","lemma":"couler","pos":"VER"} ,
		{"word":"coulaient","word_nosc":"coulaient","lemma":"couler","pos":"VER"} ,
		{"word":"coulais","word_nosc":"coulais","lemma":"couler","pos":"VER"} ,
		{"word":"coulait","word_nosc":"coulait","lemma":"couler","pos":"VER"} ,
		{"word":"coulant","word_nosc":"coulant","lemma":"couler","pos":"VER"} ,
		{"word":"coule","word_nosc":"coule","lemma":"couler","pos":"VER"} ,
		{"word":"coulent","word_nosc":"coulent","lemma":"couler","pos":"VER"} ,
		{"word":"couler","word_nosc":"couler","lemma":"couler","pos":"VER"} ,
		{"word":"coulera","word_nosc":"coulera","lemma":"couler","pos":"VER"} ,
		{"word":"coulerai","word_nosc":"coulerai","lemma":"couler","pos":"VER"} ,
		{"word":"couleraient","word_nosc":"couleraient","lemma":"couler","pos":"VER"} ,
		{"word":"coulerais","word_nosc":"coulerais","lemma":"couler","pos":"VER"} ,
		{"word":"coulerait","word_nosc":"coulerait","lemma":"couler","pos":"VER"} ,
		{"word":"couleras","word_nosc":"couleras","lemma":"couler","pos":"VER"} ,
		{"word":"coulerez","word_nosc":"coulerez","lemma":"couler","pos":"VER"} ,
		{"word":"couleront","word_nosc":"couleront","lemma":"couler","pos":"VER"} ,
		{"word":"coules","word_nosc":"coules","lemma":"couler","pos":"VER"} ,
		{"word":"coulez","word_nosc":"coulez","lemma":"couler","pos":"VER"} ,
		{"word":"couliez","word_nosc":"couliez","lemma":"couler","pos":"VER"} ,
		{"word":"coulions","word_nosc":"coulions","lemma":"couler","pos":"VER"} ,
		{"word":"coulissa","word_nosc":"coulissa","lemma":"coulisser","pos":"VER"} ,
		{"word":"coulissaient","word_nosc":"coulissaient","lemma":"coulisser","pos":"VER"} ,
		{"word":"coulissait","word_nosc":"coulissait","lemma":"coulisser","pos":"VER"} ,
		{"word":"coulissant","word_nosc":"coulissant","lemma":"coulisser","pos":"VER"} ,
		{"word":"coulisse","word_nosc":"coulisse","lemma":"coulisser","pos":"VER"} ,
		{"word":"coulissent","word_nosc":"coulissent","lemma":"coulisser","pos":"VER"} ,
		{"word":"coulisser","word_nosc":"coulisser","lemma":"coulisser","pos":"VER"} ,
		{"word":"coulisses","word_nosc":"coulisses","lemma":"coulisser","pos":"VER"} ,
		{"word":"coulissèrent","word_nosc":"coulisserent","lemma":"coulisser","pos":"VER"} ,
		{"word":"coulissé","word_nosc":"coulisse","lemma":"coulisser","pos":"VER"} ,
		{"word":"coulissée","word_nosc":"coulissee","lemma":"coulisser","pos":"VER"} ,
		{"word":"coulons","word_nosc":"coulons","lemma":"couler","pos":"VER"} ,
		{"word":"coulât","word_nosc":"coulat","lemma":"couler","pos":"VER"} ,
		{"word":"coulèrent","word_nosc":"coulerent","lemma":"couler","pos":"VER"} ,
		{"word":"coulé","word_nosc":"coule","lemma":"couler","pos":"VER"} ,
		{"word":"coulée","word_nosc":"coulee","lemma":"couler","pos":"VER"} ,
		{"word":"coulées","word_nosc":"coulees","lemma":"couler","pos":"VER"} ,
		{"word":"coulés","word_nosc":"coules","lemma":"couler","pos":"VER"} ,
		{"word":"coupa","word_nosc":"coupa","lemma":"couper","pos":"VER"} ,
		{"word":"coupai","word_nosc":"coupai","lemma":"couper","pos":"VER"} ,
		{"word":"coupaient","word_nosc":"coupaient","lemma":"couper","pos":"VER"} ,
		{"word":"coupailler","word_nosc":"coupailler","lemma":"coupailler","pos":"VER"} ,
		{"word":"coupais","word_nosc":"coupais","lemma":"couper","pos":"VER"} ,
		{"word":"coupait","word_nosc":"coupait","lemma":"couper","pos":"VER"} ,
		{"word":"coupant","word_nosc":"coupant","lemma":"couper","pos":"VER"} ,
		{"word":"coupe","word_nosc":"coupe","lemma":"couper","pos":"VER"} ,
		{"word":"coupent","word_nosc":"coupent","lemma":"couper","pos":"VER"} ,
		{"word":"couper","word_nosc":"couper","lemma":"couper","pos":"VER"} ,
		{"word":"coupera","word_nosc":"coupera","lemma":"couper","pos":"VER"} ,
		{"word":"couperai","word_nosc":"couperai","lemma":"couper","pos":"VER"} ,
		{"word":"couperaient","word_nosc":"couperaient","lemma":"couper","pos":"VER"} ,
		{"word":"couperais","word_nosc":"couperais","lemma":"couper","pos":"VER"} ,
		{"word":"couperait","word_nosc":"couperait","lemma":"couper","pos":"VER"} ,
		{"word":"couperas","word_nosc":"couperas","lemma":"couper","pos":"VER"} ,
		{"word":"couperez","word_nosc":"couperez","lemma":"couper","pos":"VER"} ,
		{"word":"couperons","word_nosc":"couperons","lemma":"couper","pos":"VER"} ,
		{"word":"couperont","word_nosc":"couperont","lemma":"couper","pos":"VER"} ,
		{"word":"couperosé","word_nosc":"couperose","lemma":"couperoser","pos":"VER"} ,
		{"word":"coupes","word_nosc":"coupes","lemma":"couper","pos":"VER"} ,
		{"word":"coupez","word_nosc":"coupez","lemma":"couper","pos":"VER"} ,
		{"word":"coupiez","word_nosc":"coupiez","lemma":"couper","pos":"VER"} ,
		{"word":"coupions","word_nosc":"coupions","lemma":"couper","pos":"VER"} ,
		{"word":"couplant","word_nosc":"couplant","lemma":"coupler","pos":"VER"} ,
		{"word":"couple","word_nosc":"couple","lemma":"coupler","pos":"VER"} ,
		{"word":"coupler","word_nosc":"coupler","lemma":"coupler","pos":"VER"} ,
		{"word":"couples","word_nosc":"couples","lemma":"coupler","pos":"VER"} ,
		{"word":"couplé","word_nosc":"couple","lemma":"coupler","pos":"VER"} ,
		{"word":"couplée","word_nosc":"couplee","lemma":"coupler","pos":"VER"} ,
		{"word":"couplées","word_nosc":"couplees","lemma":"coupler","pos":"VER"} ,
		{"word":"couplés","word_nosc":"couples","lemma":"coupler","pos":"VER"} ,
		{"word":"coupons","word_nosc":"coupons","lemma":"couper","pos":"VER"} ,
		{"word":"coupèrent","word_nosc":"couperent","lemma":"couper","pos":"VER"} ,
		{"word":"coupé","word_nosc":"coupe","lemma":"couper","pos":"VER"} ,
		{"word":"coupée","word_nosc":"coupee","lemma":"couper","pos":"VER"} ,
		{"word":"coupées","word_nosc":"coupees","lemma":"couper","pos":"VER"} ,
		{"word":"coupés","word_nosc":"coupes","lemma":"couper","pos":"VER"} ,
		{"word":"couraient","word_nosc":"couraient","lemma":"courir","pos":"VER"} ,
		{"word":"courailler","word_nosc":"courailler","lemma":"courailler","pos":"VER"} ,
		{"word":"courais","word_nosc":"courais","lemma":"courir","pos":"VER"} ,
		{"word":"courait","word_nosc":"courait","lemma":"courir","pos":"VER"} ,
		{"word":"courant","word_nosc":"courant","lemma":"courir","pos":"VER"} ,
		{"word":"courba","word_nosc":"courba","lemma":"courber","pos":"VER"} ,
		{"word":"courbaient","word_nosc":"courbaient","lemma":"courber","pos":"VER"} ,
		{"word":"courbait","word_nosc":"courbait","lemma":"courber","pos":"VER"} ,
		{"word":"courbant","word_nosc":"courbant","lemma":"courber","pos":"VER"} ,
		{"word":"courbe","word_nosc":"courbe","lemma":"courber","pos":"VER"} ,
		{"word":"courbent","word_nosc":"courbent","lemma":"courber","pos":"VER"} ,
		{"word":"courber","word_nosc":"courber","lemma":"courber","pos":"VER"} ,
		{"word":"courberait","word_nosc":"courberait","lemma":"courber","pos":"VER"} ,
		{"word":"courbez","word_nosc":"courbez","lemma":"courber","pos":"VER"} ,
		{"word":"courbons","word_nosc":"courbons","lemma":"courber","pos":"VER"} ,
		{"word":"courbé","word_nosc":"courbe","lemma":"courber","pos":"VER"} ,
		{"word":"courbée","word_nosc":"courbee","lemma":"courber","pos":"VER"} ,
		{"word":"courbées","word_nosc":"courbees","lemma":"courber","pos":"VER"} ,
		{"word":"courbés","word_nosc":"courbes","lemma":"courber","pos":"VER"} ,
		{"word":"coure","word_nosc":"coure","lemma":"courir","pos":"VER"} ,
		{"word":"courent","word_nosc":"courent","lemma":"courir","pos":"VER"} ,
		{"word":"coures","word_nosc":"coures","lemma":"courir","pos":"VER"} ,
		{"word":"courez","word_nosc":"courez","lemma":"courir","pos":"VER"} ,
		{"word":"couriez","word_nosc":"couriez","lemma":"courir","pos":"VER"} ,
		{"word":"courions","word_nosc":"courions","lemma":"courir","pos":"VER"} ,
		{"word":"courir","word_nosc":"courir","lemma":"courir","pos":"VER"} ,
		{"word":"couronna","word_nosc":"couronna","lemma":"couronner","pos":"VER"} ,
		{"word":"couronnaient","word_nosc":"couronnaient","lemma":"couronner","pos":"VER"} ,
		{"word":"couronnait","word_nosc":"couronnait","lemma":"couronner","pos":"VER"} ,
		{"word":"couronnant","word_nosc":"couronnant","lemma":"couronner","pos":"VER"} ,
		{"word":"couronne","word_nosc":"couronne","lemma":"couronner","pos":"VER"} ,
		{"word":"couronnent","word_nosc":"couronnent","lemma":"couronner","pos":"VER"} ,
		{"word":"couronner","word_nosc":"couronner","lemma":"couronner","pos":"VER"} ,
		{"word":"couronnera","word_nosc":"couronnera","lemma":"couronner","pos":"VER"} ,
		{"word":"couronnerai","word_nosc":"couronnerai","lemma":"couronner","pos":"VER"} ,
		{"word":"couronnerons","word_nosc":"couronnerons","lemma":"couronner","pos":"VER"} ,
		{"word":"couronnes","word_nosc":"couronnes","lemma":"couronner","pos":"VER"} ,
		{"word":"couronné","word_nosc":"couronne","lemma":"couronner","pos":"VER"} ,
		{"word":"couronnée","word_nosc":"couronnee","lemma":"couronner","pos":"VER"} ,
		{"word":"couronnées","word_nosc":"couronnees","lemma":"couronner","pos":"VER"} ,
		{"word":"couronnés","word_nosc":"couronnes","lemma":"couronner","pos":"VER"} ,
		{"word":"courons","word_nosc":"courons","lemma":"courir","pos":"VER"} ,
		{"word":"courra","word_nosc":"courra","lemma":"courir","pos":"VER"} ,
		{"word":"courrai","word_nosc":"courrai","lemma":"courir","pos":"VER"} ,
		{"word":"courraient","word_nosc":"courraient","lemma":"courir","pos":"VER"} ,
		{"word":"courrais","word_nosc":"courrais","lemma":"courir","pos":"VER"} ,
		{"word":"courrait","word_nosc":"courrait","lemma":"courir","pos":"VER"} ,
		{"word":"courras","word_nosc":"courras","lemma":"courir","pos":"VER"} ,
		{"word":"courre","word_nosc":"courre","lemma":"courre","pos":"VER"} ,
		{"word":"courrez","word_nosc":"courrez","lemma":"courir","pos":"VER"} ,
		{"word":"courriez","word_nosc":"courriez","lemma":"courir","pos":"VER"} ,
		{"word":"courrions","word_nosc":"courrions","lemma":"courir","pos":"VER"} ,
		{"word":"courrons","word_nosc":"courrons","lemma":"courir","pos":"VER"} ,
		{"word":"courront","word_nosc":"courront","lemma":"courir","pos":"VER"} ,
		{"word":"courrouce","word_nosc":"courrouce","lemma":"courroucer","pos":"VER"} ,
		{"word":"courroucer","word_nosc":"courroucer","lemma":"courroucer","pos":"VER"} ,
		{"word":"courroucé","word_nosc":"courrouce","lemma":"courroucer","pos":"VER"} ,
		{"word":"courroucée","word_nosc":"courroucee","lemma":"courroucer","pos":"VER"} ,
		{"word":"courroucées","word_nosc":"courroucees","lemma":"courroucer","pos":"VER"} ,
		{"word":"courroucés","word_nosc":"courrouces","lemma":"courroucer","pos":"VER"} ,
		{"word":"courrouçaient","word_nosc":"courroucaient","lemma":"courroucer","pos":"VER"} ,
		{"word":"courrouçait","word_nosc":"courroucait","lemma":"courroucer","pos":"VER"} ,
		{"word":"cours","word_nosc":"cours","lemma":"courir","pos":"VER"} ,
		{"word":"coursaient","word_nosc":"coursaient","lemma":"courser","pos":"VER"} ,
		{"word":"coursais","word_nosc":"coursais","lemma":"courser","pos":"VER"} ,
		{"word":"coursait","word_nosc":"coursait","lemma":"courser","pos":"VER"} ,
		{"word":"coursant","word_nosc":"coursant","lemma":"courser","pos":"VER"} ,
		{"word":"course","word_nosc":"course","lemma":"courser","pos":"VER"} ,
		{"word":"coursent","word_nosc":"coursent","lemma":"courser","pos":"VER"} ,
		{"word":"courser","word_nosc":"courser","lemma":"courser","pos":"VER"} ,
		{"word":"courserais","word_nosc":"courserais","lemma":"courser","pos":"VER"} ,
		{"word":"coursé","word_nosc":"course","lemma":"courser","pos":"VER"} ,
		{"word":"coursée","word_nosc":"coursee","lemma":"courser","pos":"VER"} ,
		{"word":"coursés","word_nosc":"courses","lemma":"courser","pos":"VER"} ,
		{"word":"court","word_nosc":"court","lemma":"courir","pos":"VER"} ,
		{"word":"court-circuitait","word_nosc":"court-circuitait","lemma":"court-circuiter","pos":"VER"} ,
		{"word":"court-circuitant","word_nosc":"court-circuitant","lemma":"court-circuiter","pos":"VER"} ,
		{"word":"court-circuite","word_nosc":"court-circuite","lemma":"court-circuiter","pos":"VER"} ,
		{"word":"court-circuiter","word_nosc":"court-circuiter","lemma":"court-circuiter","pos":"VER"} ,
		{"word":"court-circuits","word_nosc":"court-circuits","lemma":"court-circuit","pos":"VER"} ,
		{"word":"court-circuité","word_nosc":"court-circuite","lemma":"court-circuiter","pos":"VER"} ,
		{"word":"court-circuitée","word_nosc":"court-circuitee","lemma":"court-circuiter","pos":"VER"} ,
		{"word":"court-circuitées","word_nosc":"court-circuitees","lemma":"court-circuiter","pos":"VER"} ,
		{"word":"courte","word_nosc":"courte","lemma":"courter","pos":"VER"} ,
		{"word":"courtisa","word_nosc":"courtisa","lemma":"courtiser","pos":"VER"} ,
		{"word":"courtisaient","word_nosc":"courtisaient","lemma":"courtiser","pos":"VER"} ,
		{"word":"courtisais","word_nosc":"courtisais","lemma":"courtiser","pos":"VER"} ,
		{"word":"courtisait","word_nosc":"courtisait","lemma":"courtiser","pos":"VER"} ,
		{"word":"courtisant","word_nosc":"courtisant","lemma":"courtiser","pos":"VER"} ,
		{"word":"courtise","word_nosc":"courtise","lemma":"courtiser","pos":"VER"} ,
		{"word":"courtisent","word_nosc":"courtisent","lemma":"courtiser","pos":"VER"} ,
		{"word":"courtiser","word_nosc":"courtiser","lemma":"courtiser","pos":"VER"} ,
		{"word":"courtiserai","word_nosc":"courtiserai","lemma":"courtiser","pos":"VER"} ,
		{"word":"courtiseras","word_nosc":"courtiseras","lemma":"courtiser","pos":"VER"} ,
		{"word":"courtisez","word_nosc":"courtisez","lemma":"courtiser","pos":"VER"} ,
		{"word":"courtisiez","word_nosc":"courtisiez","lemma":"courtiser","pos":"VER"} ,
		{"word":"courtisé","word_nosc":"courtise","lemma":"courtiser","pos":"VER"} ,
		{"word":"courtisée","word_nosc":"courtisee","lemma":"courtiser","pos":"VER"} ,
		{"word":"courtisées","word_nosc":"courtisees","lemma":"courtiser","pos":"VER"} ,
		{"word":"couru","word_nosc":"couru","lemma":"courir","pos":"VER"} ,
		{"word":"courue","word_nosc":"courue","lemma":"courir","pos":"VER"} ,
		{"word":"courues","word_nosc":"courues","lemma":"courir","pos":"VER"} ,
		{"word":"coururent","word_nosc":"coururent","lemma":"courir","pos":"VER"} ,
		{"word":"courus","word_nosc":"courus","lemma":"courir","pos":"VER"} ,
		{"word":"courussent","word_nosc":"courussent","lemma":"courir","pos":"VER"} ,
		{"word":"courut","word_nosc":"courut","lemma":"courir","pos":"VER"} ,
		{"word":"courûmes","word_nosc":"courumes","lemma":"courir","pos":"VER"} ,
		{"word":"courût","word_nosc":"courut","lemma":"courir","pos":"VER"} ,
		{"word":"courûtes","word_nosc":"courutes","lemma":"courir","pos":"VER"} ,
		{"word":"cousaient","word_nosc":"cousaient","lemma":"coudre","pos":"VER"} ,
		{"word":"cousait","word_nosc":"cousait","lemma":"coudre","pos":"VER"} ,
		{"word":"cousant","word_nosc":"cousant","lemma":"coudre","pos":"VER"} ,
		{"word":"couse","word_nosc":"couse","lemma":"coudre","pos":"VER"} ,
		{"word":"cousent","word_nosc":"cousent","lemma":"coudre","pos":"VER"} ,
		{"word":"cousez","word_nosc":"cousez","lemma":"coudre","pos":"VER"} ,
		{"word":"cousiez","word_nosc":"cousiez","lemma":"coudre","pos":"VER"} ,
		{"word":"cousinant","word_nosc":"cousinant","lemma":"cousiner","pos":"VER"} ,
		{"word":"cousirent","word_nosc":"cousirent","lemma":"coudre","pos":"VER"} ,
		{"word":"cousissent","word_nosc":"cousissent","lemma":"coudre","pos":"VER"} ,
		{"word":"cousit","word_nosc":"cousit","lemma":"coudre","pos":"VER"} ,
		{"word":"cousu","word_nosc":"cousu","lemma":"coudre","pos":"VER"} ,
		{"word":"cousue","word_nosc":"cousue","lemma":"coudre","pos":"VER"} ,
		{"word":"cousues","word_nosc":"cousues","lemma":"coudre","pos":"VER"} ,
		{"word":"cousus","word_nosc":"cousus","lemma":"coudre","pos":"VER"} ,
		{"word":"couturaient","word_nosc":"couturaient","lemma":"couturer","pos":"VER"} ,
		{"word":"couturé","word_nosc":"couture","lemma":"couturer","pos":"VER"} ,
		{"word":"couturée","word_nosc":"couturee","lemma":"couturer","pos":"VER"} ,
		{"word":"couturées","word_nosc":"couturees","lemma":"couturer","pos":"VER"} ,
		{"word":"couturés","word_nosc":"coutures","lemma":"couturer","pos":"VER"} ,
		{"word":"couvaient","word_nosc":"couvaient","lemma":"couver","pos":"VER"} ,
		{"word":"couvais","word_nosc":"couvais","lemma":"couver","pos":"VER"} ,
		{"word":"couvait","word_nosc":"couvait","lemma":"couver","pos":"VER"} ,
		{"word":"couvant","word_nosc":"couvant","lemma":"couver","pos":"VER"} ,
		{"word":"couve","word_nosc":"couve","lemma":"couver","pos":"VER"} ,
		{"word":"couvent","word_nosc":"couvent","lemma":"couver","pos":"VER"} ,
		{"word":"couver","word_nosc":"couver","lemma":"couver","pos":"VER"} ,
		{"word":"couveront","word_nosc":"couveront","lemma":"couver","pos":"VER"} ,
		{"word":"couvert","word_nosc":"couvert","lemma":"couvrir","pos":"VER"} ,
		{"word":"couverte","word_nosc":"couverte","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvertes","word_nosc":"couvertes","lemma":"couvrir","pos":"VER"} ,
		{"word":"couverts","word_nosc":"couverts","lemma":"couvrir","pos":"VER"} ,
		{"word":"couves","word_nosc":"couves","lemma":"couver","pos":"VER"} ,
		{"word":"couvez","word_nosc":"couvez","lemma":"couver","pos":"VER"} ,
		{"word":"couvraient","word_nosc":"couvraient","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrais","word_nosc":"couvrais","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrait","word_nosc":"couvrait","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrant","word_nosc":"couvrant","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvre","word_nosc":"couvre","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrent","word_nosc":"couvrent","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvres","word_nosc":"couvres","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrez","word_nosc":"couvrez","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvriez","word_nosc":"couvriez","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrir","word_nosc":"couvrir","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrira","word_nosc":"couvrira","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrirai","word_nosc":"couvrirai","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvriraient","word_nosc":"couvriraient","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrirais","word_nosc":"couvrirais","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrirait","word_nosc":"couvrirait","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvriras","word_nosc":"couvriras","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrirent","word_nosc":"couvrirent","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrirez","word_nosc":"couvrirez","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrirons","word_nosc":"couvrirons","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvriront","word_nosc":"couvriront","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvris","word_nosc":"couvris","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrit","word_nosc":"couvrit","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrons","word_nosc":"couvrons","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvrît","word_nosc":"couvrit","lemma":"couvrir","pos":"VER"} ,
		{"word":"couvé","word_nosc":"couve","lemma":"couver","pos":"VER"} ,
		{"word":"couvée","word_nosc":"couvee","lemma":"couver","pos":"VER"} ,
		{"word":"couvées","word_nosc":"couvees","lemma":"couver","pos":"VER"} ,
		{"word":"couvés","word_nosc":"couves","lemma":"couver","pos":"VER"} ,
		{"word":"coïncida","word_nosc":"coincida","lemma":"coïncider","pos":"VER"} ,
		{"word":"coïncidaient","word_nosc":"coincidaient","lemma":"coïncider","pos":"VER"} ,
		{"word":"coïncidais","word_nosc":"coincidais","lemma":"coïncider","pos":"VER"} ,
		{"word":"coïncidait","word_nosc":"coincidait","lemma":"coïncider","pos":"VER"} ,
		{"word":"coïncidant","word_nosc":"coincidant","lemma":"coïncider","pos":"VER"} ,
		{"word":"coïncidassent","word_nosc":"coincidassent","lemma":"coïncider","pos":"VER"} ,
		{"word":"coïncide","word_nosc":"coincide","lemma":"coïncider","pos":"VER"} ,
		{"word":"coïncident","word_nosc":"coincident","lemma":"coïncider","pos":"VER"} ,
		{"word":"coïncider","word_nosc":"coincider","lemma":"coïncider","pos":"VER"} ,
		{"word":"coïnciderait","word_nosc":"coinciderait","lemma":"coïncider","pos":"VER"} ,
		{"word":"coïncidions","word_nosc":"coincidions","lemma":"coïncider","pos":"VER"} ,
		{"word":"coïncidât","word_nosc":"coincidat","lemma":"coïncider","pos":"VER"} ,
		{"word":"coïncidèrent","word_nosc":"coinciderent","lemma":"coïncider","pos":"VER"} ,
		{"word":"coïncidé","word_nosc":"coincide","lemma":"coïncider","pos":"VER"} ,
		{"word":"coïté","word_nosc":"coite","lemma":"coïter","pos":"VER"} ,
		{"word":"coûta","word_nosc":"couta","lemma":"coûter","pos":"VER"} ,
		{"word":"coûtaient","word_nosc":"coutaient","lemma":"coûter","pos":"VER"} ,
		{"word":"coûtais","word_nosc":"coutais","lemma":"coûter","pos":"VER"} ,
		{"word":"coûtait","word_nosc":"coutait","lemma":"coûter","pos":"VER"} ,
		{"word":"coûtant","word_nosc":"coutant","lemma":"coûter","pos":"VER"} ,
		{"word":"coûte","word_nosc":"coute","lemma":"coûter","pos":"VER"} ,
		{"word":"coûtent","word_nosc":"coutent","lemma":"coûter","pos":"VER"} ,
		{"word":"coûter","word_nosc":"couter","lemma":"coûter","pos":"VER"} ,
		{"word":"coûtera","word_nosc":"coutera","lemma":"coûter","pos":"VER"} ,
		{"word":"coûterai","word_nosc":"couterai","lemma":"coûter","pos":"VER"} ,
		{"word":"coûteraient","word_nosc":"couteraient","lemma":"coûter","pos":"VER"} ,
		{"word":"coûterait","word_nosc":"couterait","lemma":"coûter","pos":"VER"} ,
		{"word":"coûteras","word_nosc":"couteras","lemma":"coûter","pos":"VER"} ,
		{"word":"coûteront","word_nosc":"couteront","lemma":"coûter","pos":"VER"} ,
		{"word":"coûtes","word_nosc":"coutes","lemma":"coûter","pos":"VER"} ,
		{"word":"coûtez","word_nosc":"coutez","lemma":"coûter","pos":"VER"} ,
		{"word":"coûtions","word_nosc":"coutions","lemma":"coûter","pos":"VER"} ,
		{"word":"coûtât","word_nosc":"coutat","lemma":"coûter","pos":"VER"} ,
		{"word":"coûté","word_nosc":"coute","lemma":"coûter","pos":"VER"} ,
		{"word":"coûtée","word_nosc":"coutee","lemma":"coûter","pos":"VER"} ,
		{"word":"coûtées","word_nosc":"coutees","lemma":"coûter","pos":"VER"} ,
		{"word":"coûtés","word_nosc":"coutes","lemma":"coûter","pos":"VER"} ,
		{"word":"cracha","word_nosc":"cracha","lemma":"cracher","pos":"VER"} ,
		{"word":"crachai","word_nosc":"crachai","lemma":"cracher","pos":"VER"} ,
		{"word":"crachaient","word_nosc":"crachaient","lemma":"cracher","pos":"VER"} ,
		{"word":"crachais","word_nosc":"crachais","lemma":"cracher","pos":"VER"} ,
		{"word":"crachait","word_nosc":"crachait","lemma":"cracher","pos":"VER"} ,
		{"word":"crachant","word_nosc":"crachant","lemma":"cracher","pos":"VER"} ,
		{"word":"crache","word_nosc":"crache","lemma":"cracher","pos":"VER"} ,
		{"word":"crachent","word_nosc":"crachent","lemma":"cracher","pos":"VER"} ,
		{"word":"cracher","word_nosc":"cracher","lemma":"cracher","pos":"VER"} ,
		{"word":"crachera","word_nosc":"crachera","lemma":"cracher","pos":"VER"} ,
		{"word":"cracherai","word_nosc":"cracherai","lemma":"cracher","pos":"VER"} ,
		{"word":"cracherais","word_nosc":"cracherais","lemma":"cracher","pos":"VER"} ,
		{"word":"cracherait","word_nosc":"cracherait","lemma":"cracher","pos":"VER"} ,
		{"word":"cracheriez","word_nosc":"cracheriez","lemma":"cracher","pos":"VER"} ,
		{"word":"cracheront","word_nosc":"cracheront","lemma":"cracher","pos":"VER"} ,
		{"word":"craches","word_nosc":"craches","lemma":"cracher","pos":"VER"} ,
		{"word":"crachez","word_nosc":"crachez","lemma":"cracher","pos":"VER"} ,
		{"word":"crachine","word_nosc":"crachine","lemma":"crachiner","pos":"VER"} ,
		{"word":"crachiner","word_nosc":"crachiner","lemma":"crachiner","pos":"VER"} ,
		{"word":"crachons","word_nosc":"crachons","lemma":"cracher","pos":"VER"} ,
		{"word":"crachota","word_nosc":"crachota","lemma":"crachoter","pos":"VER"} ,
		{"word":"crachotaient","word_nosc":"crachotaient","lemma":"crachoter","pos":"VER"} ,
		{"word":"crachotait","word_nosc":"crachotait","lemma":"crachoter","pos":"VER"} ,
		{"word":"crachotant","word_nosc":"crachotant","lemma":"crachoter","pos":"VER"} ,
		{"word":"crachote","word_nosc":"crachote","lemma":"crachoter","pos":"VER"} ,
		{"word":"crachotent","word_nosc":"crachotent","lemma":"crachoter","pos":"VER"} ,
		{"word":"crachoter","word_nosc":"crachoter","lemma":"crachoter","pos":"VER"} ,
		{"word":"crachoté","word_nosc":"crachote","lemma":"crachoter","pos":"VER"} ,
		{"word":"crachouilla","word_nosc":"crachouilla","lemma":"crachouiller","pos":"VER"} ,
		{"word":"crachouille","word_nosc":"crachouille","lemma":"crachouiller","pos":"VER"} ,
		{"word":"crachèrent","word_nosc":"cracherent","lemma":"cracher","pos":"VER"} ,
		{"word":"craché","word_nosc":"crache","lemma":"cracher","pos":"VER"} ,
		{"word":"crachée","word_nosc":"crachee","lemma":"cracher","pos":"VER"} ,
		{"word":"crachées","word_nosc":"crachees","lemma":"cracher","pos":"VER"} ,
		{"word":"crachés","word_nosc":"craches","lemma":"cracher","pos":"VER"} ,
		{"word":"craignaient","word_nosc":"craignaient","lemma":"craindre","pos":"VER"} ,
		{"word":"craignais","word_nosc":"craignais","lemma":"craindre","pos":"VER"} ,
		{"word":"craignait","word_nosc":"craignait","lemma":"craindre","pos":"VER"} ,
		{"word":"craignant","word_nosc":"craignant","lemma":"craindre","pos":"VER"} ,
		{"word":"craigne","word_nosc":"craigne","lemma":"craindre","pos":"VER"} ,
		{"word":"craignent","word_nosc":"craignent","lemma":"craindre","pos":"VER"} ,
		{"word":"craignes","word_nosc":"craignes","lemma":"craindre","pos":"VER"} ,
		{"word":"craignez","word_nosc":"craignez","lemma":"craindre","pos":"VER"} ,
		{"word":"craigniez","word_nosc":"craigniez","lemma":"craindre","pos":"VER"} ,
		{"word":"craignions","word_nosc":"craignions","lemma":"craindre","pos":"VER"} ,
		{"word":"craignirent","word_nosc":"craignirent","lemma":"craindre","pos":"VER"} ,
		{"word":"craignis","word_nosc":"craignis","lemma":"craindre","pos":"VER"} ,
		{"word":"craignissent","word_nosc":"craignissent","lemma":"craindre","pos":"VER"} ,
		{"word":"craignit","word_nosc":"craignit","lemma":"craindre","pos":"VER"} ,
		{"word":"craignons","word_nosc":"craignons","lemma":"craindre","pos":"VER"} ,
		{"word":"craignît","word_nosc":"craignit","lemma":"craindre","pos":"VER"} ,
		{"word":"craillaient","word_nosc":"craillaient","lemma":"crailler","pos":"VER"} ,
		{"word":"craillent","word_nosc":"craillent","lemma":"crailler","pos":"VER"} ,
		{"word":"craindra","word_nosc":"craindra","lemma":"craindre","pos":"VER"} ,
		{"word":"craindrai","word_nosc":"craindrai","lemma":"craindre","pos":"VER"} ,
		{"word":"craindraient","word_nosc":"craindraient","lemma":"craindre","pos":"VER"} ,
		{"word":"craindrais","word_nosc":"craindrais","lemma":"craindre","pos":"VER"} ,
		{"word":"craindrait","word_nosc":"craindrait","lemma":"craindre","pos":"VER"} ,
		{"word":"craindras","word_nosc":"craindras","lemma":"craindre","pos":"VER"} ,
		{"word":"craindre","word_nosc":"craindre","lemma":"craindre","pos":"VER"} ,
		{"word":"craindriez","word_nosc":"craindriez","lemma":"craindre","pos":"VER"} ,
		{"word":"craindrons","word_nosc":"craindrons","lemma":"craindre","pos":"VER"} ,
		{"word":"craindront","word_nosc":"craindront","lemma":"craindre","pos":"VER"} ,
		{"word":"crains","word_nosc":"crains","lemma":"craindre","pos":"VER"} ,
		{"word":"craint","word_nosc":"craint","lemma":"craindre","pos":"VER"} ,
		{"word":"crainte","word_nosc":"crainte","lemma":"craindre","pos":"VER"} ,
		{"word":"craintes","word_nosc":"craintes","lemma":"craindre","pos":"VER"} ,
		{"word":"craints","word_nosc":"craints","lemma":"craindre","pos":"VER"} ,
		{"word":"crama","word_nosc":"crama","lemma":"cramer","pos":"VER"} ,
		{"word":"cramaient","word_nosc":"cramaient","lemma":"cramer","pos":"VER"} ,
		{"word":"cramait","word_nosc":"cramait","lemma":"cramer","pos":"VER"} ,
		{"word":"cramant","word_nosc":"cramant","lemma":"cramer","pos":"VER"} ,
		{"word":"crame","word_nosc":"crame","lemma":"cramer","pos":"VER"} ,
		{"word":"crament","word_nosc":"crament","lemma":"cramer","pos":"VER"} ,
		{"word":"cramer","word_nosc":"cramer","lemma":"cramer","pos":"VER"} ,
		{"word":"crameraient","word_nosc":"crameraient","lemma":"cramer","pos":"VER"} ,
		{"word":"cramerais","word_nosc":"cramerais","lemma":"cramer","pos":"VER"} ,
		{"word":"crameras","word_nosc":"crameras","lemma":"cramer","pos":"VER"} ,
		{"word":"cramez","word_nosc":"cramez","lemma":"cramer","pos":"VER"} ,
		{"word":"cramoisi","word_nosc":"cramoisi","lemma":"cramoisir","pos":"VER"} ,
		{"word":"cramoisie","word_nosc":"cramoisie","lemma":"cramoisir","pos":"VER"} ,
		{"word":"cramoisis","word_nosc":"cramoisis","lemma":"cramoisir","pos":"VER"} ,
		{"word":"cramponna","word_nosc":"cramponna","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramponnaient","word_nosc":"cramponnaient","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramponnais","word_nosc":"cramponnais","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramponnait","word_nosc":"cramponnait","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramponnant","word_nosc":"cramponnant","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramponne","word_nosc":"cramponne","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramponnent","word_nosc":"cramponnent","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramponner","word_nosc":"cramponner","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramponneront","word_nosc":"cramponneront","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramponnez","word_nosc":"cramponnez","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramponniez","word_nosc":"cramponniez","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramponnons","word_nosc":"cramponnons","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramponné","word_nosc":"cramponne","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramponnée","word_nosc":"cramponnee","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramponnées","word_nosc":"cramponnees","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramponnés","word_nosc":"cramponnes","lemma":"cramponner","pos":"VER"} ,
		{"word":"cramé","word_nosc":"crame","lemma":"cramer","pos":"VER"} ,
		{"word":"cramée","word_nosc":"cramee","lemma":"cramer","pos":"VER"} ,
		{"word":"cramées","word_nosc":"cramees","lemma":"cramer","pos":"VER"} ,
		{"word":"cramés","word_nosc":"crames","lemma":"cramer","pos":"VER"} ,
		{"word":"cranter","word_nosc":"cranter","lemma":"cranter","pos":"VER"} ,
		{"word":"cranté","word_nosc":"crante","lemma":"cranter","pos":"VER"} ,
		{"word":"crantées","word_nosc":"crantees","lemma":"cranter","pos":"VER"} ,
		{"word":"crantés","word_nosc":"crantes","lemma":"cranter","pos":"VER"} ,
		{"word":"crapahutait","word_nosc":"crapahutait","lemma":"crapahuter","pos":"VER"} ,
		{"word":"crapahutant","word_nosc":"crapahutant","lemma":"crapahuter","pos":"VER"} ,
		{"word":"crapahute","word_nosc":"crapahute","lemma":"crapahuter","pos":"VER"} ,
		{"word":"crapahuter","word_nosc":"crapahuter","lemma":"crapahuter","pos":"VER"} ,
		{"word":"crapahutons","word_nosc":"crapahutons","lemma":"crapahuter","pos":"VER"} ,
		{"word":"crapahuté","word_nosc":"crapahute","lemma":"crapahuter","pos":"VER"} ,
		{"word":"crapoter","word_nosc":"crapoter","lemma":"crapoter","pos":"VER"} ,
		{"word":"craqua","word_nosc":"craqua","lemma":"craquer","pos":"VER"} ,
		{"word":"craquaient","word_nosc":"craquaient","lemma":"craquer","pos":"VER"} ,
		{"word":"craquais","word_nosc":"craquais","lemma":"craquer","pos":"VER"} ,
		{"word":"craquait","word_nosc":"craquait","lemma":"craquer","pos":"VER"} ,
		{"word":"craquant","word_nosc":"craquant","lemma":"craquer","pos":"VER"} ,
		{"word":"craque","word_nosc":"craque","lemma":"craquer","pos":"VER"} ,
		{"word":"craquela","word_nosc":"craquela","lemma":"craqueler","pos":"VER"} ,
		{"word":"craquelaient","word_nosc":"craquelaient","lemma":"craqueler","pos":"VER"} ,
		{"word":"craquelait","word_nosc":"craquelait","lemma":"craqueler","pos":"VER"} ,
		{"word":"craquelant","word_nosc":"craquelant","lemma":"craqueler","pos":"VER"} ,
		{"word":"craqueler","word_nosc":"craqueler","lemma":"craqueler","pos":"VER"} ,
		{"word":"craquelle","word_nosc":"craquelle","lemma":"craqueler","pos":"VER"} ,
		{"word":"craquellent","word_nosc":"craquellent","lemma":"craqueler","pos":"VER"} ,
		{"word":"craquelèrent","word_nosc":"craquelerent","lemma":"craqueler","pos":"VER"} ,
		{"word":"craquelé","word_nosc":"craquele","lemma":"craqueler","pos":"VER"} ,
		{"word":"craquelée","word_nosc":"craquelee","lemma":"craqueler","pos":"VER"} ,
		{"word":"craquelées","word_nosc":"craquelees","lemma":"craqueler","pos":"VER"} ,
		{"word":"craquent","word_nosc":"craquent","lemma":"craquer","pos":"VER"} ,
		{"word":"craquer","word_nosc":"craquer","lemma":"craquer","pos":"VER"} ,
		{"word":"craquera","word_nosc":"craquera","lemma":"craquer","pos":"VER"} ,
		{"word":"craqueraient","word_nosc":"craqueraient","lemma":"craquer","pos":"VER"} ,
		{"word":"craquerait","word_nosc":"craquerait","lemma":"craquer","pos":"VER"} ,
		{"word":"craqueras","word_nosc":"craqueras","lemma":"craquer","pos":"VER"} ,
		{"word":"craqueront","word_nosc":"craqueront","lemma":"craquer","pos":"VER"} ,
		{"word":"craques","word_nosc":"craques","lemma":"craquer","pos":"VER"} ,
		{"word":"craquette","word_nosc":"craquette","lemma":"craqueter","pos":"VER"} ,
		{"word":"craquez","word_nosc":"craquez","lemma":"craquer","pos":"VER"} ,
		{"word":"craquiez","word_nosc":"craquiez","lemma":"craquer","pos":"VER"} ,
		{"word":"craquèrent","word_nosc":"craquerent","lemma":"craquer","pos":"VER"} ,
		{"word":"craqué","word_nosc":"craque","lemma":"craquer","pos":"VER"} ,
		{"word":"craquée","word_nosc":"craquee","lemma":"craquer","pos":"VER"} ,
		{"word":"craquées","word_nosc":"craquees","lemma":"craquer","pos":"VER"} ,
		{"word":"crashe","word_nosc":"crashe","lemma":"crasher","pos":"VER"} ,
		{"word":"crasher","word_nosc":"crasher","lemma":"crasher","pos":"VER"} ,
		{"word":"crashé","word_nosc":"crashe","lemma":"crasher","pos":"VER"} ,
		{"word":"crashée","word_nosc":"crashee","lemma":"crasher","pos":"VER"} ,
		{"word":"cravachais","word_nosc":"cravachais","lemma":"cravacher","pos":"VER"} ,
		{"word":"cravachait","word_nosc":"cravachait","lemma":"cravacher","pos":"VER"} ,
		{"word":"cravachant","word_nosc":"cravachant","lemma":"cravacher","pos":"VER"} ,
		{"word":"cravache","word_nosc":"cravache","lemma":"cravacher","pos":"VER"} ,
		{"word":"cravacher","word_nosc":"cravacher","lemma":"cravacher","pos":"VER"} ,
		{"word":"cravaché","word_nosc":"cravache","lemma":"cravacher","pos":"VER"} ,
		{"word":"cravachées","word_nosc":"cravachees","lemma":"cravacher","pos":"VER"} ,
		{"word":"cravatait","word_nosc":"cravatait","lemma":"cravater","pos":"VER"} ,
		{"word":"cravate","word_nosc":"cravate","lemma":"cravater","pos":"VER"} ,
		{"word":"cravater","word_nosc":"cravater","lemma":"cravater","pos":"VER"} ,
		{"word":"cravates","word_nosc":"cravates","lemma":"cravater","pos":"VER"} ,
		{"word":"cravaté","word_nosc":"cravate","lemma":"cravater","pos":"VER"} ,
		{"word":"cravatée","word_nosc":"cravatee","lemma":"cravater","pos":"VER"} ,
		{"word":"cravatées","word_nosc":"cravatees","lemma":"cravater","pos":"VER"} ,
		{"word":"cravatés","word_nosc":"cravates","lemma":"cravater","pos":"VER"} ,
		{"word":"crawlait","word_nosc":"crawlait","lemma":"crawler","pos":"VER"} ,
		{"word":"crawlant","word_nosc":"crawlant","lemma":"crawler","pos":"VER"} ,
		{"word":"crayonna","word_nosc":"crayonna","lemma":"crayonner","pos":"VER"} ,
		{"word":"crayonnait","word_nosc":"crayonnait","lemma":"crayonner","pos":"VER"} ,
		{"word":"crayonnant","word_nosc":"crayonnant","lemma":"crayonner","pos":"VER"} ,
		{"word":"crayonne","word_nosc":"crayonne","lemma":"crayonner","pos":"VER"} ,
		{"word":"crayonner","word_nosc":"crayonner","lemma":"crayonner","pos":"VER"} ,
		{"word":"crayonnez","word_nosc":"crayonnez","lemma":"crayonner","pos":"VER"} ,
		{"word":"crayonné","word_nosc":"crayonne","lemma":"crayonner","pos":"VER"} ,
		{"word":"crayonnées","word_nosc":"crayonnees","lemma":"crayonner","pos":"VER"} ,
		{"word":"crayonnés","word_nosc":"crayonnes","lemma":"crayonner","pos":"VER"} ,
		{"word":"creusa","word_nosc":"creusa","lemma":"creuser","pos":"VER"} ,
		{"word":"creusai","word_nosc":"creusai","lemma":"creuser","pos":"VER"} ,
		{"word":"creusaient","word_nosc":"creusaient","lemma":"creuser","pos":"VER"} ,
		{"word":"creusais","word_nosc":"creusais","lemma":"creuser","pos":"VER"} ,
		{"word":"creusait","word_nosc":"creusait","lemma":"creuser","pos":"VER"} ,
		{"word":"creusant","word_nosc":"creusant","lemma":"creuser","pos":"VER"} ,
		{"word":"creusas","word_nosc":"creusas","lemma":"creuser","pos":"VER"} ,
		{"word":"creusassent","word_nosc":"creusassent","lemma":"creuser","pos":"VER"} ,
		{"word":"creuse","word_nosc":"creuse","lemma":"creuser","pos":"VER"} ,
		{"word":"creusent","word_nosc":"creusent","lemma":"creuser","pos":"VER"} ,
		{"word":"creuser","word_nosc":"creuser","lemma":"creuser","pos":"VER"} ,
		{"word":"creusera","word_nosc":"creusera","lemma":"creuser","pos":"VER"} ,
		{"word":"creuserai","word_nosc":"creuserai","lemma":"creuser","pos":"VER"} ,
		{"word":"creuseraient","word_nosc":"creuseraient","lemma":"creuser","pos":"VER"} ,
		{"word":"creuserais","word_nosc":"creuserais","lemma":"creuser","pos":"VER"} ,
		{"word":"creuserait","word_nosc":"creuserait","lemma":"creuser","pos":"VER"} ,
		{"word":"creuseras","word_nosc":"creuseras","lemma":"creuser","pos":"VER"} ,
		{"word":"creuserez","word_nosc":"creuserez","lemma":"creuser","pos":"VER"} ,
		{"word":"creuserons","word_nosc":"creuserons","lemma":"creuser","pos":"VER"} ,
		{"word":"creuseront","word_nosc":"creuseront","lemma":"creuser","pos":"VER"} ,
		{"word":"creuses","word_nosc":"creuses","lemma":"creuser","pos":"VER"} ,
		{"word":"creusez","word_nosc":"creusez","lemma":"creuser","pos":"VER"} ,
		{"word":"creusiez","word_nosc":"creusiez","lemma":"creuser","pos":"VER"} ,
		{"word":"creusions","word_nosc":"creusions","lemma":"creuser","pos":"VER"} ,
		{"word":"creusons","word_nosc":"creusons","lemma":"creuser","pos":"VER"} ,
		{"word":"creusèrent","word_nosc":"creuserent","lemma":"creuser","pos":"VER"} ,
		{"word":"creusé","word_nosc":"creuse","lemma":"creuser","pos":"VER"} ,
		{"word":"creusée","word_nosc":"creusee","lemma":"creuser","pos":"VER"} ,
		{"word":"creusées","word_nosc":"creusees","lemma":"creuser","pos":"VER"} ,
		{"word":"creusés","word_nosc":"creuses","lemma":"creuser","pos":"VER"} ,
		{"word":"creva","word_nosc":"creva","lemma":"crever","pos":"VER"} ,
		{"word":"crevaient","word_nosc":"crevaient","lemma":"crever","pos":"VER"} ,
		{"word":"crevais","word_nosc":"crevais","lemma":"crever","pos":"VER"} ,
		{"word":"crevait","word_nosc":"crevait","lemma":"crever","pos":"VER"} ,
		{"word":"crevant","word_nosc":"crevant","lemma":"crever","pos":"VER"} ,
		{"word":"crevasse","word_nosc":"crevasse","lemma":"crevasser","pos":"VER"} ,
		{"word":"crevassent","word_nosc":"crevassent","lemma":"crevasser","pos":"VER"} ,
		{"word":"crevasser","word_nosc":"crevasser","lemma":"crevasser","pos":"VER"} ,
		{"word":"crevassé","word_nosc":"crevasse","lemma":"crevasser","pos":"VER"} ,
		{"word":"crevassée","word_nosc":"crevassee","lemma":"crevasser","pos":"VER"} ,
		{"word":"crevassées","word_nosc":"crevassees","lemma":"crevasser","pos":"VER"} ,
		{"word":"crevassés","word_nosc":"crevasses","lemma":"crevasser","pos":"VER"} ,
		{"word":"crever","word_nosc":"crever","lemma":"crever","pos":"VER"} ,
		{"word":"crevez","word_nosc":"crevez","lemma":"crever","pos":"VER"} ,
		{"word":"creviez","word_nosc":"creviez","lemma":"crever","pos":"VER"} ,
		{"word":"crevions","word_nosc":"crevions","lemma":"crever","pos":"VER"} ,
		{"word":"crevons","word_nosc":"crevons","lemma":"crever","pos":"VER"} ,
		{"word":"crevotant","word_nosc":"crevotant","lemma":"crevoter","pos":"VER"} ,
		{"word":"crevât","word_nosc":"crevat","lemma":"crever","pos":"VER"} ,
		{"word":"crevèrent","word_nosc":"creverent","lemma":"crever","pos":"VER"} ,
		{"word":"crevé","word_nosc":"creve","lemma":"crever","pos":"VER"} ,
		{"word":"crevée","word_nosc":"crevee","lemma":"crever","pos":"VER"} ,
		{"word":"crevées","word_nosc":"crevees","lemma":"crever","pos":"VER"} ,
		{"word":"crevés","word_nosc":"creves","lemma":"crever","pos":"VER"} ,
		{"word":"cria","word_nosc":"cria","lemma":"crier","pos":"VER"} ,
		{"word":"criai","word_nosc":"criai","lemma":"crier","pos":"VER"} ,
		{"word":"criaient","word_nosc":"criaient","lemma":"crier","pos":"VER"} ,
		{"word":"criaillaient","word_nosc":"criaillaient","lemma":"criailler","pos":"VER"} ,
		{"word":"criaillait","word_nosc":"criaillait","lemma":"criailler","pos":"VER"} ,
		{"word":"criaillant","word_nosc":"criaillant","lemma":"criailler","pos":"VER"} ,
		{"word":"criaillent","word_nosc":"criaillent","lemma":"criailler","pos":"VER"} ,
		{"word":"criailler","word_nosc":"criailler","lemma":"criailler","pos":"VER"} ,
		{"word":"criais","word_nosc":"criais","lemma":"crier","pos":"VER"} ,
		{"word":"criait","word_nosc":"criait","lemma":"crier","pos":"VER"} ,
		{"word":"criant","word_nosc":"criant","lemma":"crier","pos":"VER"} ,
		{"word":"cribla","word_nosc":"cribla","lemma":"cribler","pos":"VER"} ,
		{"word":"criblaient","word_nosc":"criblaient","lemma":"cribler","pos":"VER"} ,
		{"word":"criblait","word_nosc":"criblait","lemma":"cribler","pos":"VER"} ,
		{"word":"criblant","word_nosc":"criblant","lemma":"cribler","pos":"VER"} ,
		{"word":"crible","word_nosc":"crible","lemma":"cribler","pos":"VER"} ,
		{"word":"criblent","word_nosc":"criblent","lemma":"cribler","pos":"VER"} ,
		{"word":"cribler","word_nosc":"cribler","lemma":"cribler","pos":"VER"} ,
		{"word":"criblé","word_nosc":"crible","lemma":"cribler","pos":"VER"} ,
		{"word":"criblée","word_nosc":"criblee","lemma":"cribler","pos":"VER"} ,
		{"word":"criblées","word_nosc":"criblees","lemma":"cribler","pos":"VER"} ,
		{"word":"criblés","word_nosc":"cribles","lemma":"cribler","pos":"VER"} ,
		{"word":"crie","word_nosc":"crie","lemma":"crier","pos":"VER"} ,
		{"word":"crient","word_nosc":"crient","lemma":"crier","pos":"VER"} ,
		{"word":"crier","word_nosc":"crier","lemma":"crier","pos":"VER"} ,
		{"word":"criera","word_nosc":"criera","lemma":"crier","pos":"VER"} ,
		{"word":"crierai","word_nosc":"crierai","lemma":"crier","pos":"VER"} ,
		{"word":"crieraient","word_nosc":"crieraient","lemma":"crier","pos":"VER"} ,
		{"word":"crierais","word_nosc":"crierais","lemma":"crier","pos":"VER"} ,
		{"word":"crierait","word_nosc":"crierait","lemma":"crier","pos":"VER"} ,
		{"word":"crieras","word_nosc":"crieras","lemma":"crier","pos":"VER"} ,
		{"word":"crieront","word_nosc":"crieront","lemma":"crier","pos":"VER"} ,
		{"word":"cries","word_nosc":"cries","lemma":"crier","pos":"VER"} ,
		{"word":"criez","word_nosc":"criez","lemma":"crier","pos":"VER"} ,
		{"word":"criions","word_nosc":"criions","lemma":"crier","pos":"VER"} ,
		{"word":"criminaliser","word_nosc":"criminaliser","lemma":"criminaliser","pos":"VER"} ,
		{"word":"crions","word_nosc":"crions","lemma":"crier","pos":"VER"} ,
		{"word":"crispa","word_nosc":"crispa","lemma":"crisper","pos":"VER"} ,
		{"word":"crispai","word_nosc":"crispai","lemma":"crisper","pos":"VER"} ,
		{"word":"crispaient","word_nosc":"crispaient","lemma":"crisper","pos":"VER"} ,
		{"word":"crispait","word_nosc":"crispait","lemma":"crisper","pos":"VER"} ,
		{"word":"crispant","word_nosc":"crispant","lemma":"crisper","pos":"VER"} ,
		{"word":"crispe","word_nosc":"crispe","lemma":"crisper","pos":"VER"} ,
		{"word":"crispent","word_nosc":"crispent","lemma":"crisper","pos":"VER"} ,
		{"word":"crisper","word_nosc":"crisper","lemma":"crisper","pos":"VER"} ,
		{"word":"crispes","word_nosc":"crispes","lemma":"crisper","pos":"VER"} ,
		{"word":"crispèrent","word_nosc":"crisperent","lemma":"crisper","pos":"VER"} ,
		{"word":"crispé","word_nosc":"crispe","lemma":"crisper","pos":"VER"} ,
		{"word":"crispée","word_nosc":"crispee","lemma":"crisper","pos":"VER"} ,
		{"word":"crispées","word_nosc":"crispees","lemma":"crisper","pos":"VER"} ,
		{"word":"crispés","word_nosc":"crispes","lemma":"crisper","pos":"VER"} ,
		{"word":"crissa","word_nosc":"crissa","lemma":"crisser","pos":"VER"} ,
		{"word":"crissaient","word_nosc":"crissaient","lemma":"crisser","pos":"VER"} ,
		{"word":"crissait","word_nosc":"crissait","lemma":"crisser","pos":"VER"} ,
		{"word":"crissant","word_nosc":"crissant","lemma":"crisser","pos":"VER"} ,
		{"word":"crisse","word_nosc":"crisse","lemma":"crisser","pos":"VER"} ,
		{"word":"crissent","word_nosc":"crissent","lemma":"crisser","pos":"VER"} ,
		{"word":"crisser","word_nosc":"crisser","lemma":"crisser","pos":"VER"} ,
		{"word":"crissèrent","word_nosc":"crisserent","lemma":"crisser","pos":"VER"} ,
		{"word":"crissé","word_nosc":"crisse","lemma":"crisser","pos":"VER"} ,
		{"word":"cristallisaient","word_nosc":"cristallisaient","lemma":"cristalliser","pos":"VER"} ,
		{"word":"cristallisait","word_nosc":"cristallisait","lemma":"cristalliser","pos":"VER"} ,
		{"word":"cristallise","word_nosc":"cristallise","lemma":"cristalliser","pos":"VER"} ,
		{"word":"cristallisent","word_nosc":"cristallisent","lemma":"cristalliser","pos":"VER"} ,
		{"word":"cristalliser","word_nosc":"cristalliser","lemma":"cristalliser","pos":"VER"} ,
		{"word":"cristallisera","word_nosc":"cristallisera","lemma":"cristalliser","pos":"VER"} ,
		{"word":"cristallisèrent","word_nosc":"cristalliserent","lemma":"cristalliser","pos":"VER"} ,
		{"word":"cristallisé","word_nosc":"cristallise","lemma":"cristalliser","pos":"VER"} ,
		{"word":"cristallisée","word_nosc":"cristallisee","lemma":"cristalliser","pos":"VER"} ,
		{"word":"cristallisés","word_nosc":"cristallises","lemma":"cristalliser","pos":"VER"} ,
		{"word":"criticaillent","word_nosc":"criticaillent","lemma":"criticailler","pos":"VER"} ,
		{"word":"critiqua","word_nosc":"critiqua","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiquaient","word_nosc":"critiquaient","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiquais","word_nosc":"critiquais","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiquait","word_nosc":"critiquait","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiquant","word_nosc":"critiquant","lemma":"critiquer","pos":"VER"} ,
		{"word":"critique","word_nosc":"critique","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiquent","word_nosc":"critiquent","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiquer","word_nosc":"critiquer","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiquera","word_nosc":"critiquera","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiquerais","word_nosc":"critiquerais","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiquerait","word_nosc":"critiquerait","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiqueras","word_nosc":"critiqueras","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiques","word_nosc":"critiques","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiquez","word_nosc":"critiquez","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiquiez","word_nosc":"critiquiez","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiquons","word_nosc":"critiquons","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiquât","word_nosc":"critiquat","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiqué","word_nosc":"critique","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiquée","word_nosc":"critiquee","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiquées","word_nosc":"critiquees","lemma":"critiquer","pos":"VER"} ,
		{"word":"critiqués","word_nosc":"critiques","lemma":"critiquer","pos":"VER"} ,
		{"word":"criât","word_nosc":"criat","lemma":"crier","pos":"VER"} ,
		{"word":"crièrent","word_nosc":"crierent","lemma":"crier","pos":"VER"} ,
		{"word":"crié","word_nosc":"crie","lemma":"crier","pos":"VER"} ,
		{"word":"criée","word_nosc":"criee","lemma":"crier","pos":"VER"} ,
		{"word":"criées","word_nosc":"criees","lemma":"crier","pos":"VER"} ,
		{"word":"criés","word_nosc":"cries","lemma":"crier","pos":"VER"} ,
		{"word":"croassa","word_nosc":"croassa","lemma":"croasser","pos":"VER"} ,
		{"word":"croassaient","word_nosc":"croassaient","lemma":"croasser","pos":"VER"} ,
		{"word":"croassait","word_nosc":"croassait","lemma":"croasser","pos":"VER"} ,
		{"word":"croasse","word_nosc":"croasse","lemma":"croasser","pos":"VER"} ,
		{"word":"croassent","word_nosc":"croassent","lemma":"croasser","pos":"VER"} ,
		{"word":"croasser","word_nosc":"croasser","lemma":"croasser","pos":"VER"} ,
		{"word":"crocha","word_nosc":"crocha","lemma":"crocher","pos":"VER"} ,
		{"word":"crochaient","word_nosc":"crochaient","lemma":"crocher","pos":"VER"} ,
		{"word":"crochait","word_nosc":"crochait","lemma":"crocher","pos":"VER"} ,
		{"word":"crochent","word_nosc":"crochent","lemma":"crocher","pos":"VER"} ,
		{"word":"crocher","word_nosc":"crocher","lemma":"crocher","pos":"VER"} ,
		{"word":"crocheta","word_nosc":"crocheta","lemma":"crocheter","pos":"VER"} ,
		{"word":"crochetaient","word_nosc":"crochetaient","lemma":"crocheter","pos":"VER"} ,
		{"word":"crochetait","word_nosc":"crochetait","lemma":"crocheter","pos":"VER"} ,
		{"word":"crochetant","word_nosc":"crochetant","lemma":"crocheter","pos":"VER"} ,
		{"word":"crocheter","word_nosc":"crocheter","lemma":"crocheter","pos":"VER"} ,
		{"word":"crocheté","word_nosc":"crochete","lemma":"crocheter","pos":"VER"} ,
		{"word":"crochetées","word_nosc":"crochetees","lemma":"crocheter","pos":"VER"} ,
		{"word":"crochetés","word_nosc":"crochetes","lemma":"crocheter","pos":"VER"} ,
		{"word":"crochètent","word_nosc":"crochetent","lemma":"crocheter","pos":"VER"} ,
		{"word":"croché","word_nosc":"croche","lemma":"crocher","pos":"VER"} ,
		{"word":"crochées","word_nosc":"crochees","lemma":"crocher","pos":"VER"} ,
		{"word":"croie","word_nosc":"croie","lemma":"croire","pos":"VER"} ,
		{"word":"croient","word_nosc":"croient","lemma":"croire","pos":"VER"} ,
		{"word":"croies","word_nosc":"croies","lemma":"croire","pos":"VER"} ,
		{"word":"croira","word_nosc":"croira","lemma":"croire","pos":"VER"} ,
		{"word":"croirai","word_nosc":"croirai","lemma":"croire","pos":"VER"} ,
		{"word":"croiraient","word_nosc":"croiraient","lemma":"croire","pos":"VER"} ,
		{"word":"croirais","word_nosc":"croirais","lemma":"croire","pos":"VER"} ,
		{"word":"croirait","word_nosc":"croirait","lemma":"croire","pos":"VER"} ,
		{"word":"croiras","word_nosc":"croiras","lemma":"croire","pos":"VER"} ,
		{"word":"croire","word_nosc":"croire","lemma":"croire","pos":"VER"} ,
		{"word":"croirez","word_nosc":"croirez","lemma":"croire","pos":"VER"} ,
		{"word":"croiriez","word_nosc":"croiriez","lemma":"croire","pos":"VER"} ,
		{"word":"croirions","word_nosc":"croirions","lemma":"croire","pos":"VER"} ,
		{"word":"croirons","word_nosc":"croirons","lemma":"croire","pos":"VER"} ,
		{"word":"croiront","word_nosc":"croiront","lemma":"croire","pos":"VER"} ,
		{"word":"crois","word_nosc":"crois","lemma":"croire","pos":"VER"} ,
		{"word":"croisa","word_nosc":"croisa","lemma":"croiser","pos":"VER"} ,
		{"word":"croisai","word_nosc":"croisai","lemma":"croiser","pos":"VER"} ,
		{"word":"croisaient","word_nosc":"croisaient","lemma":"croiser","pos":"VER"} ,
		{"word":"croisais","word_nosc":"croisais","lemma":"croiser","pos":"VER"} ,
		{"word":"croisait","word_nosc":"croisait","lemma":"croiser","pos":"VER"} ,
		{"word":"croisant","word_nosc":"croisant","lemma":"croiser","pos":"VER"} ,
		{"word":"croise","word_nosc":"croise","lemma":"croiser","pos":"VER"} ,
		{"word":"croisent","word_nosc":"croisent","lemma":"croiser","pos":"VER"} ,
		{"word":"croiser","word_nosc":"croiser","lemma":"croiser","pos":"VER"} ,
		{"word":"croisera","word_nosc":"croisera","lemma":"croiser","pos":"VER"} ,
		{"word":"croiserai","word_nosc":"croiserai","lemma":"croiser","pos":"VER"} ,
		{"word":"croiseraient","word_nosc":"croiseraient","lemma":"croiser","pos":"VER"} ,
		{"word":"croiserais","word_nosc":"croiserais","lemma":"croiser","pos":"VER"} ,
		{"word":"croiserait","word_nosc":"croiserait","lemma":"croiser","pos":"VER"} ,
		{"word":"croiserez","word_nosc":"croiserez","lemma":"croiser","pos":"VER"} ,
		{"word":"croiserons","word_nosc":"croiserons","lemma":"croiser","pos":"VER"} ,
		{"word":"croiseront","word_nosc":"croiseront","lemma":"croiser","pos":"VER"} ,
		{"word":"croises","word_nosc":"croises","lemma":"croiser","pos":"VER"} ,
		{"word":"croisez","word_nosc":"croisez","lemma":"croiser","pos":"VER"} ,
		{"word":"croisions","word_nosc":"croisions","lemma":"croiser","pos":"VER"} ,
		{"word":"croisons","word_nosc":"croisons","lemma":"croiser","pos":"VER"} ,
		{"word":"croissaient","word_nosc":"croissaient","lemma":"croître","pos":"VER"} ,
		{"word":"croissais","word_nosc":"croissais","lemma":"croître","pos":"VER"} ,
		{"word":"croissait","word_nosc":"croissait","lemma":"croître","pos":"VER"} ,
		{"word":"croissant","word_nosc":"croissant","lemma":"croître","pos":"VER"} ,
		{"word":"croisse","word_nosc":"croisse","lemma":"croître","pos":"VER"} ,
		{"word":"croissent","word_nosc":"croissent","lemma":"croître","pos":"VER"} ,
		{"word":"croissez","word_nosc":"croissez","lemma":"croître","pos":"VER"} ,
		{"word":"croisâmes","word_nosc":"croisames","lemma":"croiser","pos":"VER"} ,
		{"word":"croisât","word_nosc":"croisat","lemma":"croiser","pos":"VER"} ,
		{"word":"croisèrent","word_nosc":"croiserent","lemma":"croiser","pos":"VER"} ,
		{"word":"croisé","word_nosc":"croise","lemma":"croiser","pos":"VER"} ,
		{"word":"croisée","word_nosc":"croisee","lemma":"croiser","pos":"VER"} ,
		{"word":"croisées","word_nosc":"croisees","lemma":"croiser","pos":"VER"} ,
		{"word":"croisés","word_nosc":"croises","lemma":"croiser","pos":"VER"} ,
		{"word":"croit","word_nosc":"croit","lemma":"croire","pos":"VER"} ,
		{"word":"croqua","word_nosc":"croqua","lemma":"croquer","pos":"VER"} ,
		{"word":"croquai","word_nosc":"croquai","lemma":"croquer","pos":"VER"} ,
		{"word":"croquaient","word_nosc":"croquaient","lemma":"croquer","pos":"VER"} ,
		{"word":"croquais","word_nosc":"croquais","lemma":"croquer","pos":"VER"} ,
		{"word":"croquait","word_nosc":"croquait","lemma":"croquer","pos":"VER"} ,
		{"word":"croquant","word_nosc":"croquant","lemma":"croquer","pos":"VER"} ,
		{"word":"croque","word_nosc":"croque","lemma":"croquer","pos":"VER"} ,
		{"word":"croquent","word_nosc":"croquent","lemma":"croquer","pos":"VER"} ,
		{"word":"croquer","word_nosc":"croquer","lemma":"croquer","pos":"VER"} ,
		{"word":"croquera","word_nosc":"croquera","lemma":"croquer","pos":"VER"} ,
		{"word":"croquerai","word_nosc":"croquerai","lemma":"croquer","pos":"VER"} ,
		{"word":"croqueraient","word_nosc":"croqueraient","lemma":"croquer","pos":"VER"} ,
		{"word":"croquerais","word_nosc":"croquerais","lemma":"croquer","pos":"VER"} ,
		{"word":"croquerait","word_nosc":"croquerait","lemma":"croquer","pos":"VER"} ,
		{"word":"croquerons","word_nosc":"croquerons","lemma":"croquer","pos":"VER"} ,
		{"word":"croques","word_nosc":"croques","lemma":"croquer","pos":"VER"} ,
		{"word":"croquez","word_nosc":"croquez","lemma":"croquer","pos":"VER"} ,
		{"word":"croquons","word_nosc":"croquons","lemma":"croquer","pos":"VER"} ,
		{"word":"croquèrent","word_nosc":"croquerent","lemma":"croquer","pos":"VER"} ,
		{"word":"croqué","word_nosc":"croque","lemma":"croquer","pos":"VER"} ,
		{"word":"croquée","word_nosc":"croquee","lemma":"croquer","pos":"VER"} ,
		{"word":"croquées","word_nosc":"croquees","lemma":"croquer","pos":"VER"} ,
		{"word":"croqués","word_nosc":"croques","lemma":"croquer","pos":"VER"} ,
		{"word":"crosser","word_nosc":"crosser","lemma":"crosser","pos":"VER"} ,
		{"word":"crottait","word_nosc":"crottait","lemma":"crotter","pos":"VER"} ,
		{"word":"crotter","word_nosc":"crotter","lemma":"crotter","pos":"VER"} ,
		{"word":"crotté","word_nosc":"crotte","lemma":"crotter","pos":"VER"} ,
		{"word":"crottée","word_nosc":"crottee","lemma":"crotter","pos":"VER"} ,
		{"word":"crottées","word_nosc":"crottees","lemma":"crotter","pos":"VER"} ,
		{"word":"crottés","word_nosc":"crottes","lemma":"crotter","pos":"VER"} ,
		{"word":"croula","word_nosc":"croula","lemma":"crouler","pos":"VER"} ,
		{"word":"croulaient","word_nosc":"croulaient","lemma":"crouler","pos":"VER"} ,
		{"word":"croulait","word_nosc":"croulait","lemma":"crouler","pos":"VER"} ,
		{"word":"croulant","word_nosc":"croulant","lemma":"crouler","pos":"VER"} ,
		{"word":"croule","word_nosc":"croule","lemma":"crouler","pos":"VER"} ,
		{"word":"croulent","word_nosc":"croulent","lemma":"crouler","pos":"VER"} ,
		{"word":"crouler","word_nosc":"crouler","lemma":"crouler","pos":"VER"} ,
		{"word":"croulera","word_nosc":"croulera","lemma":"crouler","pos":"VER"} ,
		{"word":"croulerai","word_nosc":"croulerai","lemma":"crouler","pos":"VER"} ,
		{"word":"crouleraient","word_nosc":"crouleraient","lemma":"crouler","pos":"VER"} ,
		{"word":"croulerait","word_nosc":"croulerait","lemma":"crouler","pos":"VER"} ,
		{"word":"crouleras","word_nosc":"crouleras","lemma":"crouler","pos":"VER"} ,
		{"word":"croulerons","word_nosc":"croulerons","lemma":"crouler","pos":"VER"} ,
		{"word":"crouleront","word_nosc":"crouleront","lemma":"crouler","pos":"VER"} ,
		{"word":"croulons","word_nosc":"croulons","lemma":"crouler","pos":"VER"} ,
		{"word":"croulât","word_nosc":"croulat","lemma":"crouler","pos":"VER"} ,
		{"word":"croulèrent","word_nosc":"croulerent","lemma":"crouler","pos":"VER"} ,
		{"word":"croulé","word_nosc":"croule","lemma":"crouler","pos":"VER"} ,
		{"word":"croulée","word_nosc":"croulee","lemma":"crouler","pos":"VER"} ,
		{"word":"croulés","word_nosc":"croules","lemma":"crouler","pos":"VER"} ,
		{"word":"croupi","word_nosc":"croupi","lemma":"croupir","pos":"VER"} ,
		{"word":"croupie","word_nosc":"croupie","lemma":"croupir","pos":"VER"} ,
		{"word":"croupies","word_nosc":"croupies","lemma":"croupir","pos":"VER"} ,
		{"word":"croupionne","word_nosc":"croupionne","lemma":"croupionner","pos":"VER"} ,
		{"word":"croupir","word_nosc":"croupir","lemma":"croupir","pos":"VER"} ,
		{"word":"croupira","word_nosc":"croupira","lemma":"croupir","pos":"VER"} ,
		{"word":"croupis","word_nosc":"croupis","lemma":"croupir","pos":"VER"} ,
		{"word":"croupissaient","word_nosc":"croupissaient","lemma":"croupir","pos":"VER"} ,
		{"word":"croupissais","word_nosc":"croupissais","lemma":"croupir","pos":"VER"} ,
		{"word":"croupissait","word_nosc":"croupissait","lemma":"croupir","pos":"VER"} ,
		{"word":"croupissant","word_nosc":"croupissant","lemma":"croupir","pos":"VER"} ,
		{"word":"croupissent","word_nosc":"croupissent","lemma":"croupir","pos":"VER"} ,
		{"word":"croupissez","word_nosc":"croupissez","lemma":"croupir","pos":"VER"} ,
		{"word":"croupit","word_nosc":"croupit","lemma":"croupir","pos":"VER"} ,
		{"word":"croustillaient","word_nosc":"croustillaient","lemma":"croustiller","pos":"VER"} ,
		{"word":"croustillait","word_nosc":"croustillait","lemma":"croustiller","pos":"VER"} ,
		{"word":"croustillant","word_nosc":"croustillant","lemma":"croustiller","pos":"VER"} ,
		{"word":"croustille","word_nosc":"croustille","lemma":"croustiller","pos":"VER"} ,
		{"word":"croustillent","word_nosc":"croustillent","lemma":"croustiller","pos":"VER"} ,
		{"word":"croyaient","word_nosc":"croyaient","lemma":"croire","pos":"VER"} ,
		{"word":"croyais","word_nosc":"croyais","lemma":"croire","pos":"VER"} ,
		{"word":"croyait","word_nosc":"croyait","lemma":"croire","pos":"VER"} ,
		{"word":"croyant","word_nosc":"croyant","lemma":"croire","pos":"VER"} ,
		{"word":"croyez","word_nosc":"croyez","lemma":"croire","pos":"VER"} ,
		{"word":"croyiez","word_nosc":"croyiez","lemma":"croire","pos":"VER"} ,
		{"word":"croyions","word_nosc":"croyions","lemma":"croire","pos":"VER"} ,
		{"word":"croyons","word_nosc":"croyons","lemma":"croire","pos":"VER"} ,
		{"word":"croîs","word_nosc":"crois","lemma":"croître","pos":"VER"} ,
		{"word":"croît","word_nosc":"croit","lemma":"croître","pos":"VER"} ,
		{"word":"croîtraient","word_nosc":"croitraient","lemma":"croître","pos":"VER"} ,
		{"word":"croîtrait","word_nosc":"croitrait","lemma":"croître","pos":"VER"} ,
		{"word":"croître","word_nosc":"croitre","lemma":"croître","pos":"VER"} ,
		{"word":"croûte","word_nosc":"croute","lemma":"croûter","pos":"VER"} ,
		{"word":"croûter","word_nosc":"crouter","lemma":"croûter","pos":"VER"} ,
		{"word":"croûtes","word_nosc":"croutes","lemma":"croûter","pos":"VER"} ,
		{"word":"croûtonnais","word_nosc":"croutonnais","lemma":"croûtonner","pos":"VER"} ,
		{"word":"croûté","word_nosc":"croute","lemma":"croûter","pos":"VER"} ,
		{"word":"croûtée","word_nosc":"croutee","lemma":"croûter","pos":"VER"} ,
		{"word":"croûtées","word_nosc":"croutees","lemma":"croûter","pos":"VER"} ,
		{"word":"cru","word_nosc":"cru","lemma":"croire","pos":"VER"} ,
		{"word":"crucifia","word_nosc":"crucifia","lemma":"crucifier","pos":"VER"} ,
		{"word":"crucifiaient","word_nosc":"crucifiaient","lemma":"crucifier","pos":"VER"} ,
		{"word":"crucifiait","word_nosc":"crucifiait","lemma":"crucifier","pos":"VER"} ,
		{"word":"crucifie","word_nosc":"crucifie","lemma":"crucifier","pos":"VER"} ,
		{"word":"crucifient","word_nosc":"crucifient","lemma":"crucifier","pos":"VER"} ,
		{"word":"crucifier","word_nosc":"crucifier","lemma":"crucifier","pos":"VER"} ,
		{"word":"crucifierez","word_nosc":"crucifierez","lemma":"crucifier","pos":"VER"} ,
		{"word":"crucifieront","word_nosc":"crucifieront","lemma":"crucifier","pos":"VER"} ,
		{"word":"crucifiez","word_nosc":"crucifiez","lemma":"crucifier","pos":"VER"} ,
		{"word":"crucifié","word_nosc":"crucifie","lemma":"crucifier","pos":"VER"} ,
		{"word":"crucifiée","word_nosc":"crucifiee","lemma":"crucifier","pos":"VER"} ,
		{"word":"crucifiées","word_nosc":"crucifiees","lemma":"crucifier","pos":"VER"} ,
		{"word":"crucifiés","word_nosc":"crucifies","lemma":"crucifier","pos":"VER"} ,
		{"word":"crue","word_nosc":"crue","lemma":"croire","pos":"VER"} ,
		{"word":"crue","word_nosc":"crue","lemma":"croître","pos":"VER"} ,
		{"word":"crues","word_nosc":"crues","lemma":"croire","pos":"VER"} ,
		{"word":"crues","word_nosc":"crues","lemma":"croître","pos":"VER"} ,
		{"word":"crurent","word_nosc":"crurent","lemma":"croire","pos":"VER"} ,
		{"word":"crus","word_nosc":"crus","lemma":"croire","pos":"VER"} ,
		{"word":"crusse","word_nosc":"crusse","lemma":"croire","pos":"VER"} ,
		{"word":"crussent","word_nosc":"crussent","lemma":"croire","pos":"VER"} ,
		{"word":"crusses","word_nosc":"crusses","lemma":"croire","pos":"VER"} ,
		{"word":"crut","word_nosc":"crut","lemma":"croire","pos":"VER"} ,
		{"word":"crypte","word_nosc":"crypte","lemma":"crypter","pos":"VER"} ,
		{"word":"crypter","word_nosc":"crypter","lemma":"crypter","pos":"VER"} ,
		{"word":"crypté","word_nosc":"crypte","lemma":"crypter","pos":"VER"} ,
		{"word":"cryptée","word_nosc":"cryptee","lemma":"crypter","pos":"VER"} ,
		{"word":"cryptées","word_nosc":"cryptees","lemma":"crypter","pos":"VER"} ,
		{"word":"cryptés","word_nosc":"cryptes","lemma":"crypter","pos":"VER"} ,
		{"word":"crâna","word_nosc":"crana","lemma":"crâner","pos":"VER"} ,
		{"word":"crânais","word_nosc":"cranais","lemma":"crâner","pos":"VER"} ,
		{"word":"crânait","word_nosc":"cranait","lemma":"crâner","pos":"VER"} ,
		{"word":"crânant","word_nosc":"cranant","lemma":"crâner","pos":"VER"} ,
		{"word":"crâne","word_nosc":"crane","lemma":"crâner","pos":"VER"} ,
		{"word":"crânent","word_nosc":"cranent","lemma":"crâner","pos":"VER"} ,
		{"word":"crâner","word_nosc":"craner","lemma":"crâner","pos":"VER"} ,
		{"word":"crânes","word_nosc":"cranes","lemma":"crâner","pos":"VER"} ,
		{"word":"crânez","word_nosc":"cranez","lemma":"crâner","pos":"VER"} ,
		{"word":"crâné","word_nosc":"crane","lemma":"crâner","pos":"VER"} ,
		{"word":"crèche","word_nosc":"creche","lemma":"crécher","pos":"VER"} ,
		{"word":"crèchent","word_nosc":"crechent","lemma":"crécher","pos":"VER"} ,
		{"word":"crèches","word_nosc":"creches","lemma":"crécher","pos":"VER"} ,
		{"word":"crève","word_nosc":"creve","lemma":"crever","pos":"VER"} ,
		{"word":"crèvent","word_nosc":"crevent","lemma":"crever","pos":"VER"} ,
		{"word":"crèvera","word_nosc":"crevera","lemma":"crever","pos":"VER"} ,
		{"word":"crèverai","word_nosc":"creverai","lemma":"crever","pos":"VER"} ,
		{"word":"crèveraient","word_nosc":"creveraient","lemma":"crever","pos":"VER"} ,
		{"word":"crèverais","word_nosc":"creverais","lemma":"crever","pos":"VER"} ,
		{"word":"crèverait","word_nosc":"creverait","lemma":"crever","pos":"VER"} ,
		{"word":"crèveras","word_nosc":"creveras","lemma":"crever","pos":"VER"} ,
		{"word":"crèverez","word_nosc":"creverez","lemma":"crever","pos":"VER"} ,
		{"word":"crèveriez","word_nosc":"creveriez","lemma":"crever","pos":"VER"} ,
		{"word":"crèverons","word_nosc":"creverons","lemma":"crever","pos":"VER"} ,
		{"word":"crèveront","word_nosc":"creveront","lemma":"crever","pos":"VER"} ,
		{"word":"crèves","word_nosc":"creves","lemma":"crever","pos":"VER"} ,
		{"word":"créa","word_nosc":"crea","lemma":"créer","pos":"VER"} ,
		{"word":"créai","word_nosc":"creai","lemma":"créer","pos":"VER"} ,
		{"word":"créaient","word_nosc":"creaient","lemma":"créer","pos":"VER"} ,
		{"word":"créais","word_nosc":"creais","lemma":"créer","pos":"VER"} ,
		{"word":"créait","word_nosc":"creait","lemma":"créer","pos":"VER"} ,
		{"word":"créant","word_nosc":"creant","lemma":"créer","pos":"VER"} ,
		{"word":"créchaient","word_nosc":"crechaient","lemma":"crécher","pos":"VER"} ,
		{"word":"créchait","word_nosc":"crechait","lemma":"crécher","pos":"VER"} ,
		{"word":"créchant","word_nosc":"crechant","lemma":"crécher","pos":"VER"} ,
		{"word":"crécher","word_nosc":"crecher","lemma":"crécher","pos":"VER"} ,
		{"word":"créditait","word_nosc":"creditait","lemma":"créditer","pos":"VER"} ,
		{"word":"crédite","word_nosc":"credite","lemma":"créditer","pos":"VER"} ,
		{"word":"créditent","word_nosc":"creditent","lemma":"créditer","pos":"VER"} ,
		{"word":"créditer","word_nosc":"crediter","lemma":"créditer","pos":"VER"} ,
		{"word":"créditeraient","word_nosc":"crediteraient","lemma":"créditer","pos":"VER"} ,
		{"word":"créditez","word_nosc":"creditez","lemma":"créditer","pos":"VER"} ,
		{"word":"crédité","word_nosc":"credite","lemma":"créditer","pos":"VER"} ,
		{"word":"créditée","word_nosc":"creditee","lemma":"créditer","pos":"VER"} ,
		{"word":"crédités","word_nosc":"credites","lemma":"créditer","pos":"VER"} ,
		{"word":"crée","word_nosc":"cree","lemma":"créer","pos":"VER"} ,
		{"word":"créent","word_nosc":"creent","lemma":"créer","pos":"VER"} ,
		{"word":"créer","word_nosc":"creer","lemma":"créer","pos":"VER"} ,
		{"word":"créera","word_nosc":"creera","lemma":"créer","pos":"VER"} ,
		{"word":"créerai","word_nosc":"creerai","lemma":"créer","pos":"VER"} ,
		{"word":"créeraient","word_nosc":"creeraient","lemma":"créer","pos":"VER"} ,
		{"word":"créerais","word_nosc":"creerais","lemma":"créer","pos":"VER"} ,
		{"word":"créerait","word_nosc":"creerait","lemma":"créer","pos":"VER"} ,
		{"word":"créerez","word_nosc":"creerez","lemma":"créer","pos":"VER"} ,
		{"word":"créerons","word_nosc":"creerons","lemma":"créer","pos":"VER"} ,
		{"word":"créeront","word_nosc":"creeront","lemma":"créer","pos":"VER"} ,
		{"word":"créez","word_nosc":"creez","lemma":"créer","pos":"VER"} ,
		{"word":"créiez","word_nosc":"creiez","lemma":"créer","pos":"VER"} ,
		{"word":"créions","word_nosc":"creions","lemma":"créer","pos":"VER"} ,
		{"word":"crémer","word_nosc":"cremer","lemma":"crémer","pos":"VER"} ,
		{"word":"crémés","word_nosc":"cremes","lemma":"crémer","pos":"VER"} ,
		{"word":"crénelait","word_nosc":"crenelait","lemma":"créneler","pos":"VER"} ,
		{"word":"crénelé","word_nosc":"crenele","lemma":"créneler","pos":"VER"} ,
		{"word":"crénelée","word_nosc":"crenelee","lemma":"créneler","pos":"VER"} ,
		{"word":"crénelées","word_nosc":"crenelees","lemma":"créneler","pos":"VER"} ,
		{"word":"créons","word_nosc":"creons","lemma":"créer","pos":"VER"} ,
		{"word":"créosotée","word_nosc":"creosotee","lemma":"créosoter","pos":"VER"} ,
		{"word":"crépi","word_nosc":"crepi","lemma":"crépir","pos":"VER"} ,
		{"word":"crépie","word_nosc":"crepie","lemma":"crépir","pos":"VER"} ,
		{"word":"crépies","word_nosc":"crepies","lemma":"crépir","pos":"VER"} ,
		{"word":"crépir","word_nosc":"crepir","lemma":"crépir","pos":"VER"} ,
		{"word":"crépis","word_nosc":"crepis","lemma":"crépir","pos":"VER"} ,
		{"word":"crépissez","word_nosc":"crepissez","lemma":"crépir","pos":"VER"} ,
		{"word":"crépita","word_nosc":"crepita","lemma":"crépiter","pos":"VER"} ,
		{"word":"crépitaient","word_nosc":"crepitaient","lemma":"crépiter","pos":"VER"} ,
		{"word":"crépitait","word_nosc":"crepitait","lemma":"crépiter","pos":"VER"} ,
		{"word":"crépitant","word_nosc":"crepitant","lemma":"crépiter","pos":"VER"} ,
		{"word":"crépite","word_nosc":"crepite","lemma":"crépiter","pos":"VER"} ,
		{"word":"crépitent","word_nosc":"crepitent","lemma":"crépiter","pos":"VER"} ,
		{"word":"crépiter","word_nosc":"crepiter","lemma":"crépiter","pos":"VER"} ,
		{"word":"crépiteront","word_nosc":"crepiteront","lemma":"crépiter","pos":"VER"} ,
		{"word":"crépitèrent","word_nosc":"crepiterent","lemma":"crépiter","pos":"VER"} ,
		{"word":"crépité","word_nosc":"crepite","lemma":"crépiter","pos":"VER"} ,
		{"word":"crétiniser","word_nosc":"cretiniser","lemma":"crétiniser","pos":"VER"} ,
		{"word":"crétinisé","word_nosc":"cretinise","lemma":"crétiniser","pos":"VER"} ,
		{"word":"créât","word_nosc":"creat","lemma":"créer","pos":"VER"} ,
		{"word":"créèrent","word_nosc":"creerent","lemma":"créer","pos":"VER"} ,
		{"word":"créé","word_nosc":"cree","lemma":"créer","pos":"VER"} ,
		{"word":"créée","word_nosc":"creee","lemma":"créer","pos":"VER"} ,
		{"word":"créées","word_nosc":"creees","lemma":"créer","pos":"VER"} ,
		{"word":"créés","word_nosc":"crees","lemma":"créer","pos":"VER"} ,
		{"word":"crêpaient","word_nosc":"crepaient","lemma":"crêper","pos":"VER"} ,
		{"word":"crêpait","word_nosc":"crepait","lemma":"crêper","pos":"VER"} ,
		{"word":"crêpe","word_nosc":"crepe","lemma":"crêper","pos":"VER"} ,
		{"word":"crêpelait","word_nosc":"crepelait","lemma":"crêpeler","pos":"VER"} ,
		{"word":"crêper","word_nosc":"creper","lemma":"crêper","pos":"VER"} ,
		{"word":"crêpes","word_nosc":"crepes","lemma":"crêper","pos":"VER"} ,
		{"word":"crêpé","word_nosc":"crepe","lemma":"crêper","pos":"VER"} ,
		{"word":"crêpée","word_nosc":"crepee","lemma":"crêper","pos":"VER"} ,
		{"word":"crêpés","word_nosc":"crepes","lemma":"crêper","pos":"VER"} ,
		{"word":"crêtaient","word_nosc":"cretaient","lemma":"crêter","pos":"VER"} ,
		{"word":"crêtions","word_nosc":"cretions","lemma":"crêter","pos":"VER"} ,
		{"word":"crêté","word_nosc":"crete","lemma":"crêter","pos":"VER"} ,
		{"word":"crêtée","word_nosc":"cretee","lemma":"crêter","pos":"VER"} ,
		{"word":"crêtées","word_nosc":"cretees","lemma":"crêter","pos":"VER"} ,
		{"word":"crêtés","word_nosc":"cretes","lemma":"crêter","pos":"VER"} ,
		{"word":"crû","word_nosc":"cru","lemma":"croître","pos":"VER"} ,
		{"word":"crûmes","word_nosc":"crumes","lemma":"croire","pos":"VER"} ,
		{"word":"crût","word_nosc":"crut","lemma":"croire","pos":"VER"} ,
		{"word":"crût","word_nosc":"crut","lemma":"croître","pos":"VER"} ,
		{"word":"cubait","word_nosc":"cubait","lemma":"cuber","pos":"VER"} ,
		{"word":"cubasse","word_nosc":"cubasse","lemma":"cuber","pos":"VER"} ,
		{"word":"cube","word_nosc":"cube","lemma":"cuber","pos":"VER"} ,
		{"word":"cubes","word_nosc":"cubes","lemma":"cuber","pos":"VER"} ,
		{"word":"cueillaient","word_nosc":"cueillaient","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillais","word_nosc":"cueillais","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillait","word_nosc":"cueillait","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillant","word_nosc":"cueillant","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueille","word_nosc":"cueille","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillent","word_nosc":"cueillent","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillera","word_nosc":"cueillera","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillerai","word_nosc":"cueillerai","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillerais","word_nosc":"cueillerais","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillerait","word_nosc":"cueillerait","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueilleras","word_nosc":"cueilleras","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueilleront","word_nosc":"cueilleront","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueilles","word_nosc":"cueilles","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillez","word_nosc":"cueillez","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueilli","word_nosc":"cueilli","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillie","word_nosc":"cueillie","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillies","word_nosc":"cueillies","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillir","word_nosc":"cueillir","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillirent","word_nosc":"cueillirent","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillis","word_nosc":"cueillis","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillissent","word_nosc":"cueillissent","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillit","word_nosc":"cueillit","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillons","word_nosc":"cueillons","lemma":"cueillir","pos":"VER"} ,
		{"word":"cueillîmes","word_nosc":"cueillimes","lemma":"cueillir","pos":"VER"} ,
		{"word":"cuira","word_nosc":"cuira","lemma":"cuire","pos":"VER"} ,
		{"word":"cuirai","word_nosc":"cuirai","lemma":"cuire","pos":"VER"} ,
		{"word":"cuirait","word_nosc":"cuirait","lemma":"cuire","pos":"VER"} ,
		{"word":"cuiras","word_nosc":"cuiras","lemma":"cuire","pos":"VER"} ,
		{"word":"cuirassaient","word_nosc":"cuirassaient","lemma":"cuirasser","pos":"VER"} ,
		{"word":"cuirassait","word_nosc":"cuirassait","lemma":"cuirasser","pos":"VER"} ,
		{"word":"cuirasse","word_nosc":"cuirasse","lemma":"cuirasser","pos":"VER"} ,
		{"word":"cuirassent","word_nosc":"cuirassent","lemma":"cuirasser","pos":"VER"} ,
		{"word":"cuirasser","word_nosc":"cuirasser","lemma":"cuirasser","pos":"VER"} ,
		{"word":"cuirassé","word_nosc":"cuirasse","lemma":"cuirasser","pos":"VER"} ,
		{"word":"cuirassée","word_nosc":"cuirassee","lemma":"cuirasser","pos":"VER"} ,
		{"word":"cuirassées","word_nosc":"cuirassees","lemma":"cuirasser","pos":"VER"} ,
		{"word":"cuirassés","word_nosc":"cuirasses","lemma":"cuirasser","pos":"VER"} ,
		{"word":"cuire","word_nosc":"cuire","lemma":"cuire","pos":"VER"} ,
		{"word":"cuirez","word_nosc":"cuirez","lemma":"cuire","pos":"VER"} ,
		{"word":"cuiront","word_nosc":"cuiront","lemma":"cuire","pos":"VER"} ,
		{"word":"cuis","word_nosc":"cuis","lemma":"cuire","pos":"VER"} ,
		{"word":"cuisaient","word_nosc":"cuisaient","lemma":"cuire","pos":"VER"} ,
		{"word":"cuisait","word_nosc":"cuisait","lemma":"cuire","pos":"VER"} ,
		{"word":"cuisant","word_nosc":"cuisant","lemma":"cuire","pos":"VER"} ,
		{"word":"cuise","word_nosc":"cuise","lemma":"cuire","pos":"VER"} ,
		{"word":"cuisent","word_nosc":"cuisent","lemma":"cuire","pos":"VER"} ,
		{"word":"cuises","word_nosc":"cuises","lemma":"cuire","pos":"VER"} ,
		{"word":"cuisez","word_nosc":"cuisez","lemma":"cuire","pos":"VER"} ,
		{"word":"cuisiez","word_nosc":"cuisiez","lemma":"cuire","pos":"VER"} ,
		{"word":"cuisina","word_nosc":"cuisina","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisinaient","word_nosc":"cuisinaient","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisinais","word_nosc":"cuisinais","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisinait","word_nosc":"cuisinait","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisinant","word_nosc":"cuisinant","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisine","word_nosc":"cuisine","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisinent","word_nosc":"cuisinent","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisiner","word_nosc":"cuisiner","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisinera","word_nosc":"cuisinera","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisinerai","word_nosc":"cuisinerai","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisinerais","word_nosc":"cuisinerais","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisinerait","word_nosc":"cuisinerait","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisineras","word_nosc":"cuisineras","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisinerons","word_nosc":"cuisinerons","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisines","word_nosc":"cuisines","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisinez","word_nosc":"cuisinez","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisiniez","word_nosc":"cuisiniez","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisinons","word_nosc":"cuisinons","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisiné","word_nosc":"cuisine","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisinée","word_nosc":"cuisinee","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisinées","word_nosc":"cuisinees","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisinés","word_nosc":"cuisines","lemma":"cuisiner","pos":"VER"} ,
		{"word":"cuisit","word_nosc":"cuisit","lemma":"cuire","pos":"VER"} ,
		{"word":"cuisons","word_nosc":"cuisons","lemma":"cuire","pos":"VER"} ,
		{"word":"cuit","word_nosc":"cuit","lemma":"cuire","pos":"VER"} ,
		{"word":"cuitant","word_nosc":"cuitant","lemma":"cuiter","pos":"VER"} ,
		{"word":"cuiter","word_nosc":"cuiter","lemma":"cuiter","pos":"VER"} ,
		{"word":"cuits","word_nosc":"cuits","lemma":"cuire","pos":"VER"} ,
		{"word":"cuité","word_nosc":"cuite","lemma":"cuiter","pos":"VER"} ,
		{"word":"cuivra","word_nosc":"cuivra","lemma":"cuivrer","pos":"VER"} ,
		{"word":"cuivrait","word_nosc":"cuivrait","lemma":"cuivrer","pos":"VER"} ,
		{"word":"cuivré","word_nosc":"cuivre","lemma":"cuivrer","pos":"VER"} ,
		{"word":"cuivrée","word_nosc":"cuivree","lemma":"cuivrer","pos":"VER"} ,
		{"word":"cuivrées","word_nosc":"cuivrees","lemma":"cuivrer","pos":"VER"} ,
		{"word":"cuivrés","word_nosc":"cuivres","lemma":"cuivrer","pos":"VER"} ,
		{"word":"culbuta","word_nosc":"culbuta","lemma":"culbuter","pos":"VER"} ,
		{"word":"culbutaient","word_nosc":"culbutaient","lemma":"culbuter","pos":"VER"} ,
		{"word":"culbutait","word_nosc":"culbutait","lemma":"culbuter","pos":"VER"} ,
		{"word":"culbutant","word_nosc":"culbutant","lemma":"culbuter","pos":"VER"} ,
		{"word":"culbute","word_nosc":"culbute","lemma":"culbuter","pos":"VER"} ,
		{"word":"culbutent","word_nosc":"culbutent","lemma":"culbuter","pos":"VER"} ,
		{"word":"culbuter","word_nosc":"culbuter","lemma":"culbuter","pos":"VER"} ,
		{"word":"culbuterais","word_nosc":"culbuterais","lemma":"culbuter","pos":"VER"} ,
		{"word":"culbutes","word_nosc":"culbutes","lemma":"culbuter","pos":"VER"} ,
		{"word":"culbutèrent","word_nosc":"culbuterent","lemma":"culbuter","pos":"VER"} ,
		{"word":"culbuté","word_nosc":"culbute","lemma":"culbuter","pos":"VER"} ,
		{"word":"culbutée","word_nosc":"culbutee","lemma":"culbuter","pos":"VER"} ,
		{"word":"culbutées","word_nosc":"culbutees","lemma":"culbuter","pos":"VER"} ,
		{"word":"culbutés","word_nosc":"culbutes","lemma":"culbuter","pos":"VER"} ,
		{"word":"cule","word_nosc":"cule","lemma":"culer","pos":"VER"} ,
		{"word":"culer","word_nosc":"culer","lemma":"culer","pos":"VER"} ,
		{"word":"culmina","word_nosc":"culmina","lemma":"culminer","pos":"VER"} ,
		{"word":"culminait","word_nosc":"culminait","lemma":"culminer","pos":"VER"} ,
		{"word":"culminant","word_nosc":"culminant","lemma":"culminer","pos":"VER"} ,
		{"word":"culmine","word_nosc":"culmine","lemma":"culminer","pos":"VER"} ,
		{"word":"culminent","word_nosc":"culminent","lemma":"culminer","pos":"VER"} ,
		{"word":"culminer","word_nosc":"culminer","lemma":"culminer","pos":"VER"} ,
		{"word":"culminé","word_nosc":"culmine","lemma":"culminer","pos":"VER"} ,
		{"word":"culons","word_nosc":"culons","lemma":"culer","pos":"VER"} ,
		{"word":"culotte","word_nosc":"culotte","lemma":"culotter","pos":"VER"} ,
		{"word":"culotter","word_nosc":"culotter","lemma":"culotter","pos":"VER"} ,
		{"word":"culottes","word_nosc":"culottes","lemma":"culotter","pos":"VER"} ,
		{"word":"culotté","word_nosc":"culotte","lemma":"culotter","pos":"VER"} ,
		{"word":"culottée","word_nosc":"culottee","lemma":"culotter","pos":"VER"} ,
		{"word":"culottées","word_nosc":"culottees","lemma":"culotter","pos":"VER"} ,
		{"word":"culottés","word_nosc":"culottes","lemma":"culotter","pos":"VER"} ,
		{"word":"culpabilisais","word_nosc":"culpabilisais","lemma":"culpabiliser","pos":"VER"} ,
		{"word":"culpabilisait","word_nosc":"culpabilisait","lemma":"culpabiliser","pos":"VER"} ,
		{"word":"culpabilisant","word_nosc":"culpabilisant","lemma":"culpabiliser","pos":"VER"} ,
		{"word":"culpabilise","word_nosc":"culpabilise","lemma":"culpabiliser","pos":"VER"} ,
		{"word":"culpabilisent","word_nosc":"culpabilisent","lemma":"culpabiliser","pos":"VER"} ,
		{"word":"culpabiliser","word_nosc":"culpabiliser","lemma":"culpabiliser","pos":"VER"} ,
		{"word":"culpabiliserai","word_nosc":"culpabiliserai","lemma":"culpabiliser","pos":"VER"} ,
		{"word":"culpabilises","word_nosc":"culpabilises","lemma":"culpabiliser","pos":"VER"} ,
		{"word":"culpabilisez","word_nosc":"culpabilisez","lemma":"culpabiliser","pos":"VER"} ,
		{"word":"culpabilisiez","word_nosc":"culpabilisiez","lemma":"culpabiliser","pos":"VER"} ,
		{"word":"culpabilisons","word_nosc":"culpabilisons","lemma":"culpabiliser","pos":"VER"} ,
		{"word":"culpabilisé","word_nosc":"culpabilise","lemma":"culpabiliser","pos":"VER"} ,
		{"word":"culpabilisée","word_nosc":"culpabilisee","lemma":"culpabiliser","pos":"VER"} ,
		{"word":"culpabilisés","word_nosc":"culpabilises","lemma":"culpabiliser","pos":"VER"} ,
		{"word":"cultiva","word_nosc":"cultiva","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultivai","word_nosc":"cultivai","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultivaient","word_nosc":"cultivaient","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultivais","word_nosc":"cultivais","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultivait","word_nosc":"cultivait","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultivant","word_nosc":"cultivant","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultive","word_nosc":"cultive","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultivent","word_nosc":"cultivent","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultiver","word_nosc":"cultiver","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultivera","word_nosc":"cultivera","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultiverai","word_nosc":"cultiverai","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultiveraient","word_nosc":"cultiveraient","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultiverait","word_nosc":"cultiverait","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultiveras","word_nosc":"cultiveras","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultives","word_nosc":"cultives","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultivez","word_nosc":"cultivez","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultivions","word_nosc":"cultivions","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultivons","word_nosc":"cultivons","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultivèrent","word_nosc":"cultiverent","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultivé","word_nosc":"cultive","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultivée","word_nosc":"cultivee","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultivées","word_nosc":"cultivees","lemma":"cultiver","pos":"VER"} ,
		{"word":"cultivés","word_nosc":"cultives","lemma":"cultiver","pos":"VER"} ,
		{"word":"culé","word_nosc":"cule","lemma":"culer","pos":"VER"} ,
		{"word":"cumulaient","word_nosc":"cumulaient","lemma":"cumuler","pos":"VER"} ,
		{"word":"cumulait","word_nosc":"cumulait","lemma":"cumuler","pos":"VER"} ,
		{"word":"cumulant","word_nosc":"cumulant","lemma":"cumuler","pos":"VER"} ,
		{"word":"cumule","word_nosc":"cumule","lemma":"cumuler","pos":"VER"} ,
		{"word":"cumuler","word_nosc":"cumuler","lemma":"cumuler","pos":"VER"} ,
		{"word":"cumulera","word_nosc":"cumulera","lemma":"cumuler","pos":"VER"} ,
		{"word":"cumulez","word_nosc":"cumulez","lemma":"cumuler","pos":"VER"} ,
		{"word":"cumuliez","word_nosc":"cumuliez","lemma":"cumuler","pos":"VER"} ,
		{"word":"cumulé","word_nosc":"cumule","lemma":"cumuler","pos":"VER"} ,
		{"word":"cumulés","word_nosc":"cumules","lemma":"cumuler","pos":"VER"} ,
		{"word":"cura","word_nosc":"cura","lemma":"curer","pos":"VER"} ,
		{"word":"curait","word_nosc":"curait","lemma":"curer","pos":"VER"} ,
		{"word":"curant","word_nosc":"curant","lemma":"curer","pos":"VER"} ,
		{"word":"curariser","word_nosc":"curariser","lemma":"curariser","pos":"VER"} ,
		{"word":"cure","word_nosc":"cure","lemma":"curer","pos":"VER"} ,
		{"word":"curer","word_nosc":"curer","lemma":"curer","pos":"VER"} ,
		{"word":"cureras","word_nosc":"cureras","lemma":"curer","pos":"VER"} ,
		{"word":"cures","word_nosc":"cures","lemma":"curer","pos":"VER"} ,
		{"word":"cureter","word_nosc":"cureter","lemma":"cureter","pos":"VER"} ,
		{"word":"curé","word_nosc":"cure","lemma":"curer","pos":"VER"} ,
		{"word":"curée","word_nosc":"curee","lemma":"curer","pos":"VER"} ,
		{"word":"curés","word_nosc":"cures","lemma":"curer","pos":"VER"} ,
		{"word":"customisé","word_nosc":"customise","lemma":"customiser","pos":"VER"} ,
		{"word":"cuvaient","word_nosc":"cuvaient","lemma":"cuver","pos":"VER"} ,
		{"word":"cuvait","word_nosc":"cuvait","lemma":"cuver","pos":"VER"} ,
		{"word":"cuvant","word_nosc":"cuvant","lemma":"cuver","pos":"VER"} ,
		{"word":"cuve","word_nosc":"cuve","lemma":"cuver","pos":"VER"} ,
		{"word":"cuvent","word_nosc":"cuvent","lemma":"cuver","pos":"VER"} ,
		{"word":"cuver","word_nosc":"cuver","lemma":"cuver","pos":"VER"} ,
		{"word":"cuvera","word_nosc":"cuvera","lemma":"cuver","pos":"VER"} ,
		{"word":"cuverez","word_nosc":"cuverez","lemma":"cuver","pos":"VER"} ,
		{"word":"cuvé","word_nosc":"cuve","lemma":"cuver","pos":"VER"} ,
		{"word":"cuvée","word_nosc":"cuvee","lemma":"cuver","pos":"VER"} ,
		{"word":"cyanosé","word_nosc":"cyanose","lemma":"cyanoser","pos":"VER"} ,
		{"word":"cyanosée","word_nosc":"cyanosee","lemma":"cyanoser","pos":"VER"} ,
		{"word":"cybernétiser","word_nosc":"cybernetiser","lemma":"cybernétiser","pos":"VER"} ,
		{"word":"cyclées","word_nosc":"cyclees","lemma":"cycler","pos":"VER"} ,
		{"word":"cylindre","word_nosc":"cylindre","lemma":"cylindrer","pos":"VER"} ,
		{"word":"cylindres","word_nosc":"cylindres","lemma":"cylindrer","pos":"VER"} ,
		{"word":"cylindré","word_nosc":"cylindre","lemma":"cylindrer","pos":"VER"} ,
		{"word":"cylindrés","word_nosc":"cylindres","lemma":"cylindrer","pos":"VER"} ,
		{"word":"câbla","word_nosc":"cabla","lemma":"câbler","pos":"VER"} ,
		{"word":"câblait","word_nosc":"cablait","lemma":"câbler","pos":"VER"} ,
		{"word":"câble","word_nosc":"cable","lemma":"câbler","pos":"VER"} ,
		{"word":"câbler","word_nosc":"cabler","lemma":"câbler","pos":"VER"} ,
		{"word":"câblerais","word_nosc":"cablerais","lemma":"câbler","pos":"VER"} ,
		{"word":"câbleront","word_nosc":"cableront","lemma":"câbler","pos":"VER"} ,
		{"word":"câblez","word_nosc":"cablez","lemma":"câbler","pos":"VER"} ,
		{"word":"câblé","word_nosc":"cable","lemma":"câbler","pos":"VER"} ,
		{"word":"câblée","word_nosc":"cablee","lemma":"câbler","pos":"VER"} ,
		{"word":"câblées","word_nosc":"cablees","lemma":"câbler","pos":"VER"} ,
		{"word":"câblés","word_nosc":"cables","lemma":"câbler","pos":"VER"} ,
		{"word":"câlina","word_nosc":"calina","lemma":"câliner","pos":"VER"} ,
		{"word":"câlinait","word_nosc":"calinait","lemma":"câliner","pos":"VER"} ,
		{"word":"câlinant","word_nosc":"calinant","lemma":"câliner","pos":"VER"} ,
		{"word":"câline","word_nosc":"caline","lemma":"câliner","pos":"VER"} ,
		{"word":"câlinent","word_nosc":"calinent","lemma":"câliner","pos":"VER"} ,
		{"word":"câliner","word_nosc":"caliner","lemma":"câliner","pos":"VER"} ,
		{"word":"câlinerai","word_nosc":"calinerai","lemma":"câliner","pos":"VER"} ,
		{"word":"câliné","word_nosc":"caline","lemma":"câliner","pos":"VER"} ,
		{"word":"câlinée","word_nosc":"calinee","lemma":"câliner","pos":"VER"} ,
		{"word":"cède","word_nosc":"cede","lemma":"céder","pos":"VER"} ,
		{"word":"cèdent","word_nosc":"cedent","lemma":"céder","pos":"VER"} ,
		{"word":"cèle","word_nosc":"cele","lemma":"celer","pos":"VER"} ,
		{"word":"céda","word_nosc":"ceda","lemma":"céder","pos":"VER"} ,
		{"word":"cédai","word_nosc":"cedai","lemma":"céder","pos":"VER"} ,
		{"word":"cédaient","word_nosc":"cedaient","lemma":"céder","pos":"VER"} ,
		{"word":"cédais","word_nosc":"cedais","lemma":"céder","pos":"VER"} ,
		{"word":"cédait","word_nosc":"cedait","lemma":"céder","pos":"VER"} ,
		{"word":"cédant","word_nosc":"cedant","lemma":"céder","pos":"VER"} ,
		{"word":"céder","word_nosc":"ceder","lemma":"céder","pos":"VER"} ,
		{"word":"cédera","word_nosc":"cedera","lemma":"céder","pos":"VER"} ,
		{"word":"céderai","word_nosc":"cederai","lemma":"céder","pos":"VER"} ,
		{"word":"céderaient","word_nosc":"cederaient","lemma":"céder","pos":"VER"} ,
		{"word":"céderais","word_nosc":"cederais","lemma":"céder","pos":"VER"} ,
		{"word":"céderait","word_nosc":"cederait","lemma":"céder","pos":"VER"} ,
		{"word":"céderas","word_nosc":"cederas","lemma":"céder","pos":"VER"} ,
		{"word":"céderez","word_nosc":"cederez","lemma":"céder","pos":"VER"} ,
		{"word":"céderiez","word_nosc":"cederiez","lemma":"céder","pos":"VER"} ,
		{"word":"céderons","word_nosc":"cederons","lemma":"céder","pos":"VER"} ,
		{"word":"céderont","word_nosc":"cederont","lemma":"céder","pos":"VER"} ,
		{"word":"cédez","word_nosc":"cedez","lemma":"céder","pos":"VER"} ,
		{"word":"cédiez","word_nosc":"cediez","lemma":"céder","pos":"VER"} ,
		{"word":"cédions","word_nosc":"cedions","lemma":"céder","pos":"VER"} ,
		{"word":"cédons","word_nosc":"cedons","lemma":"céder","pos":"VER"} ,
		{"word":"cédât","word_nosc":"cedat","lemma":"céder","pos":"VER"} ,
		{"word":"cédèrent","word_nosc":"cederent","lemma":"céder","pos":"VER"} ,
		{"word":"cédé","word_nosc":"cede","lemma":"céder","pos":"VER"} ,
		{"word":"cédée","word_nosc":"cedee","lemma":"céder","pos":"VER"} ,
		{"word":"cédées","word_nosc":"cedees","lemma":"céder","pos":"VER"} ,
		{"word":"cédés","word_nosc":"cedes","lemma":"céder","pos":"VER"} ,
		{"word":"célèbre","word_nosc":"celebre","lemma":"célébrer","pos":"VER"} ,
		{"word":"célèbrent","word_nosc":"celebrent","lemma":"célébrer","pos":"VER"} ,
		{"word":"célèbres","word_nosc":"celebres","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébra","word_nosc":"celebra","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébraient","word_nosc":"celebraient","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrais","word_nosc":"celebrais","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrait","word_nosc":"celebrait","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrant","word_nosc":"celebrant","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrer","word_nosc":"celebrer","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrera","word_nosc":"celebrera","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrerais","word_nosc":"celebrerais","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrerait","word_nosc":"celebrerait","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrerons","word_nosc":"celebrerons","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébreront","word_nosc":"celebreront","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébriez","word_nosc":"celebriez","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrions","word_nosc":"celebrions","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrons","word_nosc":"celebrons","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrâmes","word_nosc":"celebrames","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrât","word_nosc":"celebrat","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrèrent","word_nosc":"celebrerent","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébré","word_nosc":"celebre","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrée","word_nosc":"celebree","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrées","word_nosc":"celebrees","lemma":"célébrer","pos":"VER"} ,
		{"word":"célébrés","word_nosc":"celebres","lemma":"célébrer","pos":"VER"} ,
		{"word":"côtoie","word_nosc":"cotoie","lemma":"côtoyer","pos":"VER"} ,
		{"word":"côtoient","word_nosc":"cotoient","lemma":"côtoyer","pos":"VER"} ,
		{"word":"côtoies","word_nosc":"cotoies","lemma":"côtoyer","pos":"VER"} ,
		{"word":"côtoya","word_nosc":"cotoya","lemma":"côtoyer","pos":"VER"} ,
		{"word":"côtoyaient","word_nosc":"cotoyaient","lemma":"côtoyer","pos":"VER"} ,
		{"word":"côtoyais","word_nosc":"cotoyais","lemma":"côtoyer","pos":"VER"} ,
		{"word":"côtoyait","word_nosc":"cotoyait","lemma":"côtoyer","pos":"VER"} ,
		{"word":"côtoyant","word_nosc":"cotoyant","lemma":"côtoyer","pos":"VER"} ,
		{"word":"côtoyer","word_nosc":"cotoyer","lemma":"côtoyer","pos":"VER"} ,
		{"word":"côtoyons","word_nosc":"cotoyons","lemma":"côtoyer","pos":"VER"} ,
		{"word":"côtoyé","word_nosc":"cotoye","lemma":"côtoyer","pos":"VER"} ,
		{"word":"côtoyée","word_nosc":"cotoyee","lemma":"côtoyer","pos":"VER"} ,
		{"word":"côtoyées","word_nosc":"cotoyees","lemma":"côtoyer","pos":"VER"} ,
		{"word":"côtoyés","word_nosc":"cotoyes","lemma":"côtoyer","pos":"VER"} ,
		{"word":"dactylographier","word_nosc":"dactylographier","lemma":"dactylographier","pos":"VER"} ,
		{"word":"dactylographié","word_nosc":"dactylographie","lemma":"dactylographier","pos":"VER"} ,
		{"word":"dactylographiée","word_nosc":"dactylographiee","lemma":"dactylographier","pos":"VER"} ,
		{"word":"dactylographiées","word_nosc":"dactylographiees","lemma":"dactylographier","pos":"VER"} ,
		{"word":"dagué","word_nosc":"dague","lemma":"daguer","pos":"VER"} ,
		{"word":"daigna","word_nosc":"daigna","lemma":"daigner","pos":"VER"} ,
		{"word":"daignaient","word_nosc":"daignaient","lemma":"daigner","pos":"VER"} ,
		{"word":"daignait","word_nosc":"daignait","lemma":"daigner","pos":"VER"} ,
		{"word":"daignant","word_nosc":"daignant","lemma":"daigner","pos":"VER"} ,
		{"word":"daigne","word_nosc":"daigne","lemma":"daigner","pos":"VER"} ,
		{"word":"daignent","word_nosc":"daignent","lemma":"daigner","pos":"VER"} ,
		{"word":"daigner","word_nosc":"daigner","lemma":"daigner","pos":"VER"} ,
		{"word":"daignera","word_nosc":"daignera","lemma":"daigner","pos":"VER"} ,
		{"word":"daigneraient","word_nosc":"daigneraient","lemma":"daigner","pos":"VER"} ,
		{"word":"daignerais","word_nosc":"daignerais","lemma":"daigner","pos":"VER"} ,
		{"word":"daignerait","word_nosc":"daignerait","lemma":"daigner","pos":"VER"} ,
		{"word":"daigneras","word_nosc":"daigneras","lemma":"daigner","pos":"VER"} ,
		{"word":"daignes","word_nosc":"daignes","lemma":"daigner","pos":"VER"} ,
		{"word":"daignez","word_nosc":"daignez","lemma":"daigner","pos":"VER"} ,
		{"word":"daignons","word_nosc":"daignons","lemma":"daigner","pos":"VER"} ,
		{"word":"daignât","word_nosc":"daignat","lemma":"daigner","pos":"VER"} ,
		{"word":"daignèrent","word_nosc":"daignerent","lemma":"daigner","pos":"VER"} ,
		{"word":"daigné","word_nosc":"daigne","lemma":"daigner","pos":"VER"} ,
		{"word":"dalle","word_nosc":"dalle","lemma":"daller","pos":"VER"} ,
		{"word":"daller","word_nosc":"daller","lemma":"daller","pos":"VER"} ,
		{"word":"dallé","word_nosc":"dalle","lemma":"daller","pos":"VER"} ,
		{"word":"dallée","word_nosc":"dallee","lemma":"daller","pos":"VER"} ,
		{"word":"dallées","word_nosc":"dallees","lemma":"daller","pos":"VER"} ,
		{"word":"dallés","word_nosc":"dalles","lemma":"daller","pos":"VER"} ,
		{"word":"damassée","word_nosc":"damassee","lemma":"damasser","pos":"VER"} ,
		{"word":"damassées","word_nosc":"damassees","lemma":"damasser","pos":"VER"} ,
		{"word":"damassés","word_nosc":"damasses","lemma":"damasser","pos":"VER"} ,
		{"word":"dame","word_nosc":"dame","lemma":"damer","pos":"VER"} ,
		{"word":"dament","word_nosc":"dament","lemma":"damer","pos":"VER"} ,
		{"word":"damer","word_nosc":"damer","lemma":"damer","pos":"VER"} ,
		{"word":"dameriez","word_nosc":"dameriez","lemma":"damer","pos":"VER"} ,
		{"word":"dames","word_nosc":"dames","lemma":"damer","pos":"VER"} ,
		{"word":"damez","word_nosc":"damez","lemma":"damer","pos":"VER"} ,
		{"word":"damnait","word_nosc":"damnait","lemma":"damner","pos":"VER"} ,
		{"word":"damnant","word_nosc":"damnant","lemma":"damner","pos":"VER"} ,
		{"word":"damne","word_nosc":"damne","lemma":"damner","pos":"VER"} ,
		{"word":"damnent","word_nosc":"damnent","lemma":"damner","pos":"VER"} ,
		{"word":"damner","word_nosc":"damner","lemma":"damner","pos":"VER"} ,
		{"word":"damnera","word_nosc":"damnera","lemma":"damner","pos":"VER"} ,
		{"word":"damneraient","word_nosc":"damneraient","lemma":"damner","pos":"VER"} ,
		{"word":"damnerais","word_nosc":"damnerais","lemma":"damner","pos":"VER"} ,
		{"word":"damnez","word_nosc":"damnez","lemma":"damner","pos":"VER"} ,
		{"word":"damné","word_nosc":"damne","lemma":"damner","pos":"VER"} ,
		{"word":"damnée","word_nosc":"damnee","lemma":"damner","pos":"VER"} ,
		{"word":"damnées","word_nosc":"damnees","lemma":"damner","pos":"VER"} ,
		{"word":"damnés","word_nosc":"damnes","lemma":"damner","pos":"VER"} ,
		{"word":"damé","word_nosc":"dame","lemma":"damer","pos":"VER"} ,
		{"word":"damée","word_nosc":"damee","lemma":"damer","pos":"VER"} ,
		{"word":"damées","word_nosc":"damees","lemma":"damer","pos":"VER"} ,
		{"word":"dandina","word_nosc":"dandina","lemma":"dandiner","pos":"VER"} ,
		{"word":"dandinaient","word_nosc":"dandinaient","lemma":"dandiner","pos":"VER"} ,
		{"word":"dandinais","word_nosc":"dandinais","lemma":"dandiner","pos":"VER"} ,
		{"word":"dandinait","word_nosc":"dandinait","lemma":"dandiner","pos":"VER"} ,
		{"word":"dandinant","word_nosc":"dandinant","lemma":"dandiner","pos":"VER"} ,
		{"word":"dandine","word_nosc":"dandine","lemma":"dandiner","pos":"VER"} ,
		{"word":"dandinent","word_nosc":"dandinent","lemma":"dandiner","pos":"VER"} ,
		{"word":"dandiner","word_nosc":"dandiner","lemma":"dandiner","pos":"VER"} ,
		{"word":"dandinez","word_nosc":"dandinez","lemma":"dandiner","pos":"VER"} ,
		{"word":"dandinée","word_nosc":"dandinee","lemma":"dandiner","pos":"VER"} ,
		{"word":"dandinés","word_nosc":"dandines","lemma":"dandiner","pos":"VER"} ,
		{"word":"dansa","word_nosc":"dansa","lemma":"danser","pos":"VER"} ,
		{"word":"dansai","word_nosc":"dansai","lemma":"danser","pos":"VER"} ,
		{"word":"dansaient","word_nosc":"dansaient","lemma":"danser","pos":"VER"} ,
		{"word":"dansais","word_nosc":"dansais","lemma":"danser","pos":"VER"} ,
		{"word":"dansait","word_nosc":"dansait","lemma":"danser","pos":"VER"} ,
		{"word":"dansant","word_nosc":"dansant","lemma":"danser","pos":"VER"} ,
		{"word":"danse","word_nosc":"danse","lemma":"danser","pos":"VER"} ,
		{"word":"dansent","word_nosc":"dansent","lemma":"danser","pos":"VER"} ,
		{"word":"danser","word_nosc":"danser","lemma":"danser","pos":"VER"} ,
		{"word":"dansera","word_nosc":"dansera","lemma":"danser","pos":"VER"} ,
		{"word":"danserai","word_nosc":"danserai","lemma":"danser","pos":"VER"} ,
		{"word":"danseraient","word_nosc":"danseraient","lemma":"danser","pos":"VER"} ,
		{"word":"danserais","word_nosc":"danserais","lemma":"danser","pos":"VER"} ,
		{"word":"danserait","word_nosc":"danserait","lemma":"danser","pos":"VER"} ,
		{"word":"danseras","word_nosc":"danseras","lemma":"danser","pos":"VER"} ,
		{"word":"danserez","word_nosc":"danserez","lemma":"danser","pos":"VER"} ,
		{"word":"danseriez","word_nosc":"danseriez","lemma":"danser","pos":"VER"} ,
		{"word":"danserions","word_nosc":"danserions","lemma":"danser","pos":"VER"} ,
		{"word":"danserons","word_nosc":"danserons","lemma":"danser","pos":"VER"} ,
		{"word":"danseront","word_nosc":"danseront","lemma":"danser","pos":"VER"} ,
		{"word":"danses","word_nosc":"danses","lemma":"danser","pos":"VER"} ,
		{"word":"dansez","word_nosc":"dansez","lemma":"danser","pos":"VER"} ,
		{"word":"dansiez","word_nosc":"dansiez","lemma":"danser","pos":"VER"} ,
		{"word":"dansions","word_nosc":"dansions","lemma":"danser","pos":"VER"} ,
		{"word":"dansons","word_nosc":"dansons","lemma":"danser","pos":"VER"} ,
		{"word":"dansota","word_nosc":"dansota","lemma":"dansoter","pos":"VER"} ,
		{"word":"dansotait","word_nosc":"dansotait","lemma":"dansoter","pos":"VER"} ,
		{"word":"dansotter","word_nosc":"dansotter","lemma":"dansotter","pos":"VER"} ,
		{"word":"dansâmes","word_nosc":"dansames","lemma":"danser","pos":"VER"} ,
		{"word":"dansèrent","word_nosc":"danserent","lemma":"danser","pos":"VER"} ,
		{"word":"dansé","word_nosc":"danse","lemma":"danser","pos":"VER"} ,
		{"word":"dansée","word_nosc":"dansee","lemma":"danser","pos":"VER"} ,
		{"word":"darda","word_nosc":"darda","lemma":"darder","pos":"VER"} ,
		{"word":"dardaient","word_nosc":"dardaient","lemma":"darder","pos":"VER"} ,
		{"word":"dardait","word_nosc":"dardait","lemma":"darder","pos":"VER"} ,
		{"word":"dardant","word_nosc":"dardant","lemma":"darder","pos":"VER"} ,
		{"word":"darde","word_nosc":"darde","lemma":"darder","pos":"VER"} ,
		{"word":"dardent","word_nosc":"dardent","lemma":"darder","pos":"VER"} ,
		{"word":"darder","word_nosc":"darder","lemma":"darder","pos":"VER"} ,
		{"word":"dardillonnaient","word_nosc":"dardillonnaient","lemma":"dardillonner","pos":"VER"} ,
		{"word":"dardillonne","word_nosc":"dardillonne","lemma":"dardillonner","pos":"VER"} ,
		{"word":"dardé","word_nosc":"darde","lemma":"darder","pos":"VER"} ,
		{"word":"dardée","word_nosc":"dardee","lemma":"darder","pos":"VER"} ,
		{"word":"dardées","word_nosc":"dardees","lemma":"darder","pos":"VER"} ,
		{"word":"dardés","word_nosc":"dardes","lemma":"darder","pos":"VER"} ,
		{"word":"data","word_nosc":"data","lemma":"dater","pos":"VER"} ,
		{"word":"dataient","word_nosc":"dataient","lemma":"dater","pos":"VER"} ,
		{"word":"datait","word_nosc":"datait","lemma":"dater","pos":"VER"} ,
		{"word":"datant","word_nosc":"datant","lemma":"dater","pos":"VER"} ,
		{"word":"date","word_nosc":"date","lemma":"dater","pos":"VER"} ,
		{"word":"datent","word_nosc":"datent","lemma":"dater","pos":"VER"} ,
		{"word":"dater","word_nosc":"dater","lemma":"dater","pos":"VER"} ,
		{"word":"daterai","word_nosc":"daterai","lemma":"dater","pos":"VER"} ,
		{"word":"daterais","word_nosc":"daterais","lemma":"dater","pos":"VER"} ,
		{"word":"daterait","word_nosc":"daterait","lemma":"dater","pos":"VER"} ,
		{"word":"dates","word_nosc":"dates","lemma":"dater","pos":"VER"} ,
		{"word":"datez","word_nosc":"datez","lemma":"dater","pos":"VER"} ,
		{"word":"daté","word_nosc":"date","lemma":"dater","pos":"VER"} ,
		{"word":"datée","word_nosc":"datee","lemma":"dater","pos":"VER"} ,
		{"word":"datées","word_nosc":"datees","lemma":"dater","pos":"VER"} ,
		{"word":"datés","word_nosc":"dates","lemma":"dater","pos":"VER"} ,
		{"word":"daubait","word_nosc":"daubait","lemma":"dauber","pos":"VER"} ,
		{"word":"dauber","word_nosc":"dauber","lemma":"dauber","pos":"VER"} ,
		{"word":"daubé","word_nosc":"daube","lemma":"dauber","pos":"VER"} ,
		{"word":"dealaient","word_nosc":"dealaient","lemma":"dealer","pos":"VER"} ,
		{"word":"dealais","word_nosc":"dealais","lemma":"dealer","pos":"VER"} ,
		{"word":"dealait","word_nosc":"dealait","lemma":"dealer","pos":"VER"} ,
		{"word":"dealant","word_nosc":"dealant","lemma":"dealer","pos":"VER"} ,
		{"word":"deale","word_nosc":"deale","lemma":"dealer","pos":"VER"} ,
		{"word":"dealent","word_nosc":"dealent","lemma":"dealer","pos":"VER"} ,
		{"word":"dealer","word_nosc":"dealer","lemma":"dealer","pos":"VER"} ,
		{"word":"dealera","word_nosc":"dealera","lemma":"dealer","pos":"VER"} ,
		{"word":"dealeront","word_nosc":"dealeront","lemma":"dealer","pos":"VER"} ,
		{"word":"deales","word_nosc":"deales","lemma":"dealer","pos":"VER"} ,
		{"word":"dealez","word_nosc":"dealez","lemma":"dealer","pos":"VER"} ,
		{"word":"dealé","word_nosc":"deale","lemma":"dealer","pos":"VER"} ,
		{"word":"demanda","word_nosc":"demanda","lemma":"demander","pos":"VER"} ,
		{"word":"demandai","word_nosc":"demandai","lemma":"demander","pos":"VER"} ,
		{"word":"demandaient","word_nosc":"demandaient","lemma":"demander","pos":"VER"} ,
		{"word":"demandais","word_nosc":"demandais","lemma":"demander","pos":"VER"} ,
		{"word":"demandait","word_nosc":"demandait","lemma":"demander","pos":"VER"} ,
		{"word":"demandant","word_nosc":"demandant","lemma":"demander","pos":"VER"} ,
		{"word":"demande","word_nosc":"demande","lemma":"demander","pos":"VER"} ,
		{"word":"demandent","word_nosc":"demandent","lemma":"demander","pos":"VER"} ,
		{"word":"demander","word_nosc":"demander","lemma":"demander","pos":"VER"} ,
		{"word":"demandera","word_nosc":"demandera","lemma":"demander","pos":"VER"} ,
		{"word":"demanderai","word_nosc":"demanderai","lemma":"demander","pos":"VER"} ,
		{"word":"demanderaient","word_nosc":"demanderaient","lemma":"demander","pos":"VER"} ,
		{"word":"demanderais","word_nosc":"demanderais","lemma":"demander","pos":"VER"} ,
		{"word":"demanderait","word_nosc":"demanderait","lemma":"demander","pos":"VER"} ,
		{"word":"demanderas","word_nosc":"demanderas","lemma":"demander","pos":"VER"} ,
		{"word":"demanderez","word_nosc":"demanderez","lemma":"demander","pos":"VER"} ,
		{"word":"demanderiez","word_nosc":"demanderiez","lemma":"demander","pos":"VER"} ,
		{"word":"demanderons","word_nosc":"demanderons","lemma":"demander","pos":"VER"} ,
		{"word":"demanderont","word_nosc":"demanderont","lemma":"demander","pos":"VER"} ,
		{"word":"demandes","word_nosc":"demandes","lemma":"demander","pos":"VER"} ,
		{"word":"demandez","word_nosc":"demandez","lemma":"demander","pos":"VER"} ,
		{"word":"demandiez","word_nosc":"demandiez","lemma":"demander","pos":"VER"} ,
		{"word":"demandions","word_nosc":"demandions","lemma":"demander","pos":"VER"} ,
		{"word":"demandons","word_nosc":"demandons","lemma":"demander","pos":"VER"} ,
		{"word":"demandâmes","word_nosc":"demandames","lemma":"demander","pos":"VER"} ,
		{"word":"demandât","word_nosc":"demandat","lemma":"demander","pos":"VER"} ,
		{"word":"demandèrent","word_nosc":"demanderent","lemma":"demander","pos":"VER"} ,
		{"word":"demandé","word_nosc":"demande","lemma":"demander","pos":"VER"} ,
		{"word":"demandée","word_nosc":"demandee","lemma":"demander","pos":"VER"} ,
		{"word":"demandées","word_nosc":"demandees","lemma":"demander","pos":"VER"} ,
		{"word":"demandés","word_nosc":"demandes","lemma":"demander","pos":"VER"} ,
		{"word":"demeura","word_nosc":"demeura","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurai","word_nosc":"demeurai","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeuraient","word_nosc":"demeuraient","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurais","word_nosc":"demeurais","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurait","word_nosc":"demeurait","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurant","word_nosc":"demeurant","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurassent","word_nosc":"demeurassent","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeure","word_nosc":"demeure","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurent","word_nosc":"demeurent","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurer","word_nosc":"demeurer","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurera","word_nosc":"demeurera","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurerai","word_nosc":"demeurerai","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeureraient","word_nosc":"demeureraient","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurerais","word_nosc":"demeurerais","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurerait","word_nosc":"demeurerait","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeureras","word_nosc":"demeureras","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurerez","word_nosc":"demeurerez","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurerions","word_nosc":"demeurerions","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurerons","word_nosc":"demeurerons","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeureront","word_nosc":"demeureront","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeures","word_nosc":"demeures","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurez","word_nosc":"demeurez","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeuriez","word_nosc":"demeuriez","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurions","word_nosc":"demeurions","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurons","word_nosc":"demeurons","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurâmes","word_nosc":"demeurames","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurât","word_nosc":"demeurat","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurèrent","word_nosc":"demeurerent","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeuré","word_nosc":"demeure","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurée","word_nosc":"demeuree","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurées","word_nosc":"demeurees","lemma":"demeurer","pos":"VER"} ,
		{"word":"demeurés","word_nosc":"demeures","lemma":"demeurer","pos":"VER"} ,
		{"word":"densifia","word_nosc":"densifia","lemma":"densifier","pos":"VER"} ,
		{"word":"densifie","word_nosc":"densifie","lemma":"densifier","pos":"VER"} ,
		{"word":"densifié","word_nosc":"densifie","lemma":"densifier","pos":"VER"} ,
		{"word":"dentelé","word_nosc":"dentele","lemma":"denteler","pos":"VER"} ,
		{"word":"dentelée","word_nosc":"dentelee","lemma":"denteler","pos":"VER"} ,
		{"word":"dentelées","word_nosc":"dentelees","lemma":"denteler","pos":"VER"} ,
		{"word":"dentelés","word_nosc":"denteles","lemma":"denteler","pos":"VER"} ,
		{"word":"dentée","word_nosc":"dentee","lemma":"denter","pos":"VER"} ,
		{"word":"dentées","word_nosc":"dentees","lemma":"denter","pos":"VER"} ,
		{"word":"descella","word_nosc":"descella","lemma":"desceller","pos":"VER"} ,
		{"word":"descellant","word_nosc":"descellant","lemma":"desceller","pos":"VER"} ,
		{"word":"desceller","word_nosc":"desceller","lemma":"desceller","pos":"VER"} ,
		{"word":"descellera","word_nosc":"descellera","lemma":"desceller","pos":"VER"} ,
		{"word":"descellez","word_nosc":"descellez","lemma":"desceller","pos":"VER"} ,
		{"word":"descellé","word_nosc":"descelle","lemma":"desceller","pos":"VER"} ,
		{"word":"descellée","word_nosc":"descellee","lemma":"desceller","pos":"VER"} ,
		{"word":"descellées","word_nosc":"descellees","lemma":"desceller","pos":"VER"} ,
		{"word":"descellés","word_nosc":"descelles","lemma":"desceller","pos":"VER"} ,
		{"word":"descend","word_nosc":"descend","lemma":"descendre","pos":"VER"} ,
		{"word":"descendaient","word_nosc":"descendaient","lemma":"descendre","pos":"VER"} ,
		{"word":"descendais","word_nosc":"descendais","lemma":"descendre","pos":"VER"} ,
		{"word":"descendait","word_nosc":"descendait","lemma":"descendre","pos":"VER"} ,
		{"word":"descendant","word_nosc":"descendant","lemma":"descendre","pos":"VER"} ,
		{"word":"descende","word_nosc":"descende","lemma":"descendre","pos":"VER"} ,
		{"word":"descendent","word_nosc":"descendent","lemma":"descendre","pos":"VER"} ,
		{"word":"descendes","word_nosc":"descendes","lemma":"descendre","pos":"VER"} ,
		{"word":"descendez","word_nosc":"descendez","lemma":"descendre","pos":"VER"} ,
		{"word":"descendiez","word_nosc":"descendiez","lemma":"descendre","pos":"VER"} ,
		{"word":"descendions","word_nosc":"descendions","lemma":"descendre","pos":"VER"} ,
		{"word":"descendirent","word_nosc":"descendirent","lemma":"descendre","pos":"VER"} ,
		{"word":"descendis","word_nosc":"descendis","lemma":"descendre","pos":"VER"} ,
		{"word":"descendit","word_nosc":"descendit","lemma":"descendre","pos":"VER"} ,
		{"word":"descendons","word_nosc":"descendons","lemma":"descendre","pos":"VER"} ,
		{"word":"descendra","word_nosc":"descendra","lemma":"descendre","pos":"VER"} ,
		{"word":"descendrai","word_nosc":"descendrai","lemma":"descendre","pos":"VER"} ,
		{"word":"descendraient","word_nosc":"descendraient","lemma":"descendre","pos":"VER"} ,
		{"word":"descendrais","word_nosc":"descendrais","lemma":"descendre","pos":"VER"} ,
		{"word":"descendrait","word_nosc":"descendrait","lemma":"descendre","pos":"VER"} ,
		{"word":"descendras","word_nosc":"descendras","lemma":"descendre","pos":"VER"} ,
		{"word":"descendre","word_nosc":"descendre","lemma":"descendre","pos":"VER"} ,
		{"word":"descendrez","word_nosc":"descendrez","lemma":"descendre","pos":"VER"} ,
		{"word":"descendriez","word_nosc":"descendriez","lemma":"descendre","pos":"VER"} ,
		{"word":"descendrions","word_nosc":"descendrions","lemma":"descendre","pos":"VER"} ,
		{"word":"descendrons","word_nosc":"descendrons","lemma":"descendre","pos":"VER"} ,
		{"word":"descendront","word_nosc":"descendront","lemma":"descendre","pos":"VER"} ,
		{"word":"descends","word_nosc":"descends","lemma":"descendre","pos":"VER"} ,
		{"word":"descendu","word_nosc":"descendu","lemma":"descendre","pos":"VER"} ,
		{"word":"descendue","word_nosc":"descendue","lemma":"descendre","pos":"VER"} ,
		{"word":"descendues","word_nosc":"descendues","lemma":"descendre","pos":"VER"} ,
		{"word":"descendus","word_nosc":"descendus","lemma":"descendre","pos":"VER"} ,
		{"word":"descendîmes","word_nosc":"descendimes","lemma":"descendre","pos":"VER"} ,
		{"word":"descendît","word_nosc":"descendit","lemma":"descendre","pos":"VER"} ,
		{"word":"desquame","word_nosc":"desquame","lemma":"desquamer","pos":"VER"} ,
		{"word":"dessaisi","word_nosc":"dessaisi","lemma":"dessaisir","pos":"VER"} ,
		{"word":"dessaisir","word_nosc":"dessaisir","lemma":"dessaisir","pos":"VER"} ,
		{"word":"dessaisis","word_nosc":"dessaisis","lemma":"dessaisir","pos":"VER"} ,
		{"word":"dessaisissais","word_nosc":"dessaisissais","lemma":"dessaisir","pos":"VER"} ,
		{"word":"dessaisissent","word_nosc":"dessaisissent","lemma":"dessaisir","pos":"VER"} ,
		{"word":"dessaisit","word_nosc":"dessaisit","lemma":"dessaisir","pos":"VER"} ,
		{"word":"dessalaient","word_nosc":"dessalaient","lemma":"dessaler","pos":"VER"} ,
		{"word":"dessalait","word_nosc":"dessalait","lemma":"dessaler","pos":"VER"} ,
		{"word":"dessalant","word_nosc":"dessalant","lemma":"dessaler","pos":"VER"} ,
		{"word":"dessale","word_nosc":"dessale","lemma":"dessaler","pos":"VER"} ,
		{"word":"dessaler","word_nosc":"dessaler","lemma":"dessaler","pos":"VER"} ,
		{"word":"dessalé","word_nosc":"dessale","lemma":"dessaler","pos":"VER"} ,
		{"word":"dessaoulais","word_nosc":"dessaoulais","lemma":"dessaouler","pos":"VER"} ,
		{"word":"dessaoule","word_nosc":"dessaoule","lemma":"dessaouler","pos":"VER"} ,
		{"word":"dessaoulent","word_nosc":"dessaoulent","lemma":"dessaouler","pos":"VER"} ,
		{"word":"dessaouler","word_nosc":"dessaouler","lemma":"dessaouler","pos":"VER"} ,
		{"word":"dessaoulé","word_nosc":"dessaoule","lemma":"dessaouler","pos":"VER"} ,
		{"word":"dessaoulée","word_nosc":"dessaoulee","lemma":"dessaouler","pos":"VER"} ,
		{"word":"dessaoulés","word_nosc":"dessaoules","lemma":"dessaouler","pos":"VER"} ,
		{"word":"dessape","word_nosc":"dessape","lemma":"dessaper","pos":"VER"} ,
		{"word":"dessaper","word_nosc":"dessaper","lemma":"dessaper","pos":"VER"} ,
		{"word":"desselle","word_nosc":"desselle","lemma":"desseller","pos":"VER"} ,
		{"word":"desseller","word_nosc":"desseller","lemma":"desseller","pos":"VER"} ,
		{"word":"dessellez","word_nosc":"dessellez","lemma":"desseller","pos":"VER"} ,
		{"word":"dessellé","word_nosc":"desselle","lemma":"desseller","pos":"VER"} ,
		{"word":"dessellées","word_nosc":"dessellees","lemma":"desseller","pos":"VER"} ,
		{"word":"dessellés","word_nosc":"desselles","lemma":"desseller","pos":"VER"} ,
		{"word":"desserra","word_nosc":"desserra","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserrai","word_nosc":"desserrai","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserraient","word_nosc":"desserraient","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserrais","word_nosc":"desserrais","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserrait","word_nosc":"desserrait","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserrant","word_nosc":"desserrant","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserre","word_nosc":"desserre","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserrent","word_nosc":"desserrent","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserrer","word_nosc":"desserrer","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserrera","word_nosc":"desserrera","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserreraient","word_nosc":"desserreraient","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserrerait","word_nosc":"desserrerait","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserrez","word_nosc":"desserrez","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserrons","word_nosc":"desserrons","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserrât","word_nosc":"desserrat","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserrèrent","word_nosc":"desserrerent","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserré","word_nosc":"desserre","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserrée","word_nosc":"desserree","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserrées","word_nosc":"desserrees","lemma":"desserrer","pos":"VER"} ,
		{"word":"desserrés","word_nosc":"desserres","lemma":"desserrer","pos":"VER"} ,
		{"word":"dessers","word_nosc":"dessers","lemma":"desservir","pos":"VER"} ,
		{"word":"dessert","word_nosc":"dessert","lemma":"desservir","pos":"VER"} ,
		{"word":"dessertie","word_nosc":"dessertie","lemma":"dessertir","pos":"VER"} ,
		{"word":"dessertir","word_nosc":"dessertir","lemma":"dessertir","pos":"VER"} ,
		{"word":"desservaient","word_nosc":"desservaient","lemma":"desservir","pos":"VER"} ,
		{"word":"desservait","word_nosc":"desservait","lemma":"desservir","pos":"VER"} ,
		{"word":"desservant","word_nosc":"desservant","lemma":"desservir","pos":"VER"} ,
		{"word":"desserve","word_nosc":"desserve","lemma":"desservir","pos":"VER"} ,
		{"word":"desservent","word_nosc":"desservent","lemma":"desservir","pos":"VER"} ,
		{"word":"desservi","word_nosc":"desservi","lemma":"desservir","pos":"VER"} ,
		{"word":"desservie","word_nosc":"desservie","lemma":"desservir","pos":"VER"} ,
		{"word":"desservies","word_nosc":"desservies","lemma":"desservir","pos":"VER"} ,
		{"word":"desservir","word_nosc":"desservir","lemma":"desservir","pos":"VER"} ,
		{"word":"desservira","word_nosc":"desservira","lemma":"desservir","pos":"VER"} ,
		{"word":"desservirait","word_nosc":"desservirait","lemma":"desservir","pos":"VER"} ,
		{"word":"desserviront","word_nosc":"desserviront","lemma":"desservir","pos":"VER"} ,
		{"word":"desservis","word_nosc":"desservis","lemma":"desservir","pos":"VER"} ,
		{"word":"desservit","word_nosc":"desservit","lemma":"desservir","pos":"VER"} ,
		{"word":"dessille","word_nosc":"dessille","lemma":"dessiller","pos":"VER"} ,
		{"word":"dessiller","word_nosc":"dessiller","lemma":"dessiller","pos":"VER"} ,
		{"word":"dessilleront","word_nosc":"dessilleront","lemma":"dessiller","pos":"VER"} ,
		{"word":"dessillé","word_nosc":"dessille","lemma":"dessiller","pos":"VER"} ,
		{"word":"dessillés","word_nosc":"dessilles","lemma":"dessiller","pos":"VER"} ,
		{"word":"dessina","word_nosc":"dessina","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinai","word_nosc":"dessinai","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinaient","word_nosc":"dessinaient","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinais","word_nosc":"dessinais","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinait","word_nosc":"dessinait","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinant","word_nosc":"dessinant","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessine","word_nosc":"dessine","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinent","word_nosc":"dessinent","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessiner","word_nosc":"dessiner","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinera","word_nosc":"dessinera","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinerai","word_nosc":"dessinerai","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessineraient","word_nosc":"dessineraient","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinerais","word_nosc":"dessinerais","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinerait","word_nosc":"dessinerait","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinerez","word_nosc":"dessinerez","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessineront","word_nosc":"dessineront","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessines","word_nosc":"dessines","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinez","word_nosc":"dessinez","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessiniez","word_nosc":"dessiniez","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinions","word_nosc":"dessinions","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinât","word_nosc":"dessinat","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinèrent","word_nosc":"dessinerent","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessiné","word_nosc":"dessine","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinée","word_nosc":"dessinee","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinées","word_nosc":"dessinees","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessinés","word_nosc":"dessines","lemma":"dessiner","pos":"VER"} ,
		{"word":"dessoucha","word_nosc":"dessoucha","lemma":"dessoucher","pos":"VER"} ,
		{"word":"dessouchée","word_nosc":"dessouchee","lemma":"dessoucher","pos":"VER"} ,
		{"word":"dessouda","word_nosc":"dessouda","lemma":"dessouder","pos":"VER"} ,
		{"word":"dessouder","word_nosc":"dessouder","lemma":"dessouder","pos":"VER"} ,
		{"word":"dessoudé","word_nosc":"dessoude","lemma":"dessouder","pos":"VER"} ,
		{"word":"dessoudée","word_nosc":"dessoudee","lemma":"dessouder","pos":"VER"} ,
		{"word":"dessoudées","word_nosc":"dessoudees","lemma":"dessouder","pos":"VER"} ,
		{"word":"dessoulait","word_nosc":"dessoulait","lemma":"dessouler","pos":"VER"} ,
		{"word":"dessouler","word_nosc":"dessouler","lemma":"dessouler","pos":"VER"} ,
		{"word":"dessoulé","word_nosc":"dessoule","lemma":"dessouler","pos":"VER"} ,
		{"word":"dessoûla","word_nosc":"dessoula","lemma":"dessoûler","pos":"VER"} ,
		{"word":"dessoûlait","word_nosc":"dessoulait","lemma":"dessoûler","pos":"VER"} ,
		{"word":"dessoûle","word_nosc":"dessoule","lemma":"dessoûler","pos":"VER"} ,
		{"word":"dessoûlent","word_nosc":"dessoulent","lemma":"dessoûler","pos":"VER"} ,
		{"word":"dessoûler","word_nosc":"dessouler","lemma":"dessoûler","pos":"VER"} ,
		{"word":"dessoûles","word_nosc":"dessoules","lemma":"dessoûler","pos":"VER"} ,
		{"word":"dessoûlez","word_nosc":"dessoulez","lemma":"dessoûler","pos":"VER"} ,
		{"word":"dessoûlé","word_nosc":"dessoule","lemma":"dessoûler","pos":"VER"} ,
		{"word":"dessoûlée","word_nosc":"dessoulee","lemma":"dessoûler","pos":"VER"} ,
		{"word":"dessuintée","word_nosc":"dessuintee","lemma":"dessuinter","pos":"VER"} ,
		{"word":"dessèche","word_nosc":"desseche","lemma":"dessécher","pos":"VER"} ,
		{"word":"dessèchent","word_nosc":"dessechent","lemma":"dessécher","pos":"VER"} ,
		{"word":"dessécha","word_nosc":"dessecha","lemma":"dessécher","pos":"VER"} ,
		{"word":"desséchaient","word_nosc":"dessechaient","lemma":"dessécher","pos":"VER"} ,
		{"word":"desséchais","word_nosc":"dessechais","lemma":"dessécher","pos":"VER"} ,
		{"word":"desséchait","word_nosc":"dessechait","lemma":"dessécher","pos":"VER"} ,
		{"word":"desséchant","word_nosc":"dessechant","lemma":"dessécher","pos":"VER"} ,
		{"word":"dessécher","word_nosc":"dessecher","lemma":"dessécher","pos":"VER"} ,
		{"word":"dessécherait","word_nosc":"dessecherait","lemma":"dessécher","pos":"VER"} ,
		{"word":"dessécheront","word_nosc":"dessecheront","lemma":"dessécher","pos":"VER"} ,
		{"word":"desséchez","word_nosc":"dessechez","lemma":"dessécher","pos":"VER"} ,
		{"word":"desséchèrent","word_nosc":"dessecherent","lemma":"dessécher","pos":"VER"} ,
		{"word":"desséché","word_nosc":"desseche","lemma":"dessécher","pos":"VER"} ,
		{"word":"desséchée","word_nosc":"dessechee","lemma":"dessécher","pos":"VER"} ,
		{"word":"desséchées","word_nosc":"dessechees","lemma":"dessécher","pos":"VER"} ,
		{"word":"desséchés","word_nosc":"desseches","lemma":"dessécher","pos":"VER"} ,
		{"word":"destina","word_nosc":"destina","lemma":"destiner","pos":"VER"} ,
		{"word":"destinaient","word_nosc":"destinaient","lemma":"destiner","pos":"VER"} ,
		{"word":"destinais","word_nosc":"destinais","lemma":"destiner","pos":"VER"} ,
		{"word":"destinait","word_nosc":"destinait","lemma":"destiner","pos":"VER"} ,
		{"word":"destinant","word_nosc":"destinant","lemma":"destiner","pos":"VER"} ,
		{"word":"destine","word_nosc":"destine","lemma":"destiner","pos":"VER"} ,
		{"word":"destinent","word_nosc":"destinent","lemma":"destiner","pos":"VER"} ,
		{"word":"destiner","word_nosc":"destiner","lemma":"destiner","pos":"VER"} ,
		{"word":"destines","word_nosc":"destines","lemma":"destiner","pos":"VER"} ,
		{"word":"destinez","word_nosc":"destinez","lemma":"destiner","pos":"VER"} ,
		{"word":"destiniez","word_nosc":"destiniez","lemma":"destiner","pos":"VER"} ,
		{"word":"destiné","word_nosc":"destine","lemma":"destiner","pos":"VER"} ,
		{"word":"destinée","word_nosc":"destinee","lemma":"destiner","pos":"VER"} ,
		{"word":"destinées","word_nosc":"destinees","lemma":"destiner","pos":"VER"} ,
		{"word":"destinés","word_nosc":"destines","lemma":"destiner","pos":"VER"} ,
		{"word":"destitue","word_nosc":"destitue","lemma":"destituer","pos":"VER"} ,
		{"word":"destituer","word_nosc":"destituer","lemma":"destituer","pos":"VER"} ,
		{"word":"destitueront","word_nosc":"destitueront","lemma":"destituer","pos":"VER"} ,
		{"word":"destituez","word_nosc":"destituez","lemma":"destituer","pos":"VER"} ,
		{"word":"destitué","word_nosc":"destitue","lemma":"destituer","pos":"VER"} ,
		{"word":"destituée","word_nosc":"destituee","lemma":"destituer","pos":"VER"} ,
		{"word":"devaient","word_nosc":"devaient","lemma":"devoir","pos":"VER"} ,
		{"word":"devais","word_nosc":"devais","lemma":"devoir","pos":"VER"} ,
		{"word":"devait","word_nosc":"devait","lemma":"devoir","pos":"VER"} ,
		{"word":"devance","word_nosc":"devance","lemma":"devancer","pos":"VER"} ,
		{"word":"devancent","word_nosc":"devancent","lemma":"devancer","pos":"VER"} ,
		{"word":"devancer","word_nosc":"devancer","lemma":"devancer","pos":"VER"} ,
		{"word":"devancerait","word_nosc":"devancerait","lemma":"devancer","pos":"VER"} ,
		{"word":"devancerez","word_nosc":"devancerez","lemma":"devancer","pos":"VER"} ,
		{"word":"devancez","word_nosc":"devancez","lemma":"devancer","pos":"VER"} ,
		{"word":"devancèrent","word_nosc":"devancerent","lemma":"devancer","pos":"VER"} ,
		{"word":"devancé","word_nosc":"devance","lemma":"devancer","pos":"VER"} ,
		{"word":"devancée","word_nosc":"devancee","lemma":"devancer","pos":"VER"} ,
		{"word":"devancés","word_nosc":"devances","lemma":"devancer","pos":"VER"} ,
		{"word":"devant","word_nosc":"devant","lemma":"devoir","pos":"VER"} ,
		{"word":"devança","word_nosc":"devanca","lemma":"devancer","pos":"VER"} ,
		{"word":"devançaient","word_nosc":"devancaient","lemma":"devancer","pos":"VER"} ,
		{"word":"devançais","word_nosc":"devancais","lemma":"devancer","pos":"VER"} ,
		{"word":"devançait","word_nosc":"devancait","lemma":"devancer","pos":"VER"} ,
		{"word":"devançant","word_nosc":"devancant","lemma":"devancer","pos":"VER"} ,
		{"word":"devançons","word_nosc":"devancons","lemma":"devancer","pos":"VER"} ,
		{"word":"devenaient","word_nosc":"devenaient","lemma":"devenir","pos":"VER"} ,
		{"word":"devenais","word_nosc":"devenais","lemma":"devenir","pos":"VER"} ,
		{"word":"devenait","word_nosc":"devenait","lemma":"devenir","pos":"VER"} ,
		{"word":"devenant","word_nosc":"devenant","lemma":"devenir","pos":"VER"} ,
		{"word":"devenez","word_nosc":"devenez","lemma":"devenir","pos":"VER"} ,
		{"word":"deveniez","word_nosc":"deveniez","lemma":"devenir","pos":"VER"} ,
		{"word":"devenions","word_nosc":"devenions","lemma":"devenir","pos":"VER"} ,
		{"word":"devenir","word_nosc":"devenir","lemma":"devenir","pos":"VER"} ,
		{"word":"devenons","word_nosc":"devenons","lemma":"devenir","pos":"VER"} ,
		{"word":"devenu","word_nosc":"devenu","lemma":"devenir","pos":"VER"} ,
		{"word":"devenue","word_nosc":"devenue","lemma":"devenir","pos":"VER"} ,
		{"word":"devenues","word_nosc":"devenues","lemma":"devenir","pos":"VER"} ,
		{"word":"devenus","word_nosc":"devenus","lemma":"devenir","pos":"VER"} ,
		{"word":"devez","word_nosc":"devez","lemma":"devoir","pos":"VER"} ,
		{"word":"deviendra","word_nosc":"deviendra","lemma":"devenir","pos":"VER"} ,
		{"word":"deviendrai","word_nosc":"deviendrai","lemma":"devenir","pos":"VER"} ,
		{"word":"deviendraient","word_nosc":"deviendraient","lemma":"devenir","pos":"VER"} ,
		{"word":"deviendrais","word_nosc":"deviendrais","lemma":"devenir","pos":"VER"} ,
		{"word":"deviendrait","word_nosc":"deviendrait","lemma":"devenir","pos":"VER"} ,
		{"word":"deviendras","word_nosc":"deviendras","lemma":"devenir","pos":"VER"} ,
		{"word":"deviendrez","word_nosc":"deviendrez","lemma":"devenir","pos":"VER"} ,
		{"word":"deviendriez","word_nosc":"deviendriez","lemma":"devenir","pos":"VER"} ,
		{"word":"deviendrions","word_nosc":"deviendrions","lemma":"devenir","pos":"VER"} ,
		{"word":"deviendrons","word_nosc":"deviendrons","lemma":"devenir","pos":"VER"} ,
		{"word":"deviendront","word_nosc":"deviendront","lemma":"devenir","pos":"VER"} ,
		{"word":"devienne","word_nosc":"devienne","lemma":"devenir","pos":"VER"} ,
		{"word":"deviennent","word_nosc":"deviennent","lemma":"devenir","pos":"VER"} ,
		{"word":"deviennes","word_nosc":"deviennes","lemma":"devenir","pos":"VER"} ,
		{"word":"deviens","word_nosc":"deviens","lemma":"devenir","pos":"VER"} ,
		{"word":"devient","word_nosc":"devient","lemma":"devenir","pos":"VER"} ,
		{"word":"deviez","word_nosc":"deviez","lemma":"devoir","pos":"VER"} ,
		{"word":"devina","word_nosc":"devina","lemma":"deviner","pos":"VER"} ,
		{"word":"devinai","word_nosc":"devinai","lemma":"deviner","pos":"VER"} ,
		{"word":"devinaient","word_nosc":"devinaient","lemma":"deviner","pos":"VER"} ,
		{"word":"devinais","word_nosc":"devinais","lemma":"deviner","pos":"VER"} ,
		{"word":"devinait","word_nosc":"devinait","lemma":"deviner","pos":"VER"} ,
		{"word":"devinant","word_nosc":"devinant","lemma":"deviner","pos":"VER"} ,
		{"word":"devine","word_nosc":"devine","lemma":"deviner","pos":"VER"} ,
		{"word":"devinent","word_nosc":"devinent","lemma":"deviner","pos":"VER"} ,
		{"word":"deviner","word_nosc":"deviner","lemma":"deviner","pos":"VER"} ,
		{"word":"devinera","word_nosc":"devinera","lemma":"deviner","pos":"VER"} ,
		{"word":"devinerai","word_nosc":"devinerai","lemma":"deviner","pos":"VER"} ,
		{"word":"devineraient","word_nosc":"devineraient","lemma":"deviner","pos":"VER"} ,
		{"word":"devinerais","word_nosc":"devinerais","lemma":"deviner","pos":"VER"} ,
		{"word":"devinerait","word_nosc":"devinerait","lemma":"deviner","pos":"VER"} ,
		{"word":"devineras","word_nosc":"devineras","lemma":"deviner","pos":"VER"} ,
		{"word":"devinerez","word_nosc":"devinerez","lemma":"deviner","pos":"VER"} ,
		{"word":"devineriez","word_nosc":"devineriez","lemma":"deviner","pos":"VER"} ,
		{"word":"devineront","word_nosc":"devineront","lemma":"deviner","pos":"VER"} ,
		{"word":"devines","word_nosc":"devines","lemma":"deviner","pos":"VER"} ,
		{"word":"devinez","word_nosc":"devinez","lemma":"deviner","pos":"VER"} ,
		{"word":"devinions","word_nosc":"devinions","lemma":"deviner","pos":"VER"} ,
		{"word":"devinons","word_nosc":"devinons","lemma":"deviner","pos":"VER"} ,
		{"word":"devinrent","word_nosc":"devinrent","lemma":"devenir","pos":"VER"} ,
		{"word":"devins","word_nosc":"devins","lemma":"devenir","pos":"VER"} ,
		{"word":"devinsse","word_nosc":"devinsse","lemma":"devenir","pos":"VER"} ,
		{"word":"devinssent","word_nosc":"devinssent","lemma":"devenir","pos":"VER"} ,
		{"word":"devinssiez","word_nosc":"devinssiez","lemma":"devenir","pos":"VER"} ,
		{"word":"devint","word_nosc":"devint","lemma":"devenir","pos":"VER"} ,
		{"word":"devinâmes","word_nosc":"devinames","lemma":"deviner","pos":"VER"} ,
		{"word":"devinât","word_nosc":"devinat","lemma":"deviner","pos":"VER"} ,
		{"word":"devinèrent","word_nosc":"devinerent","lemma":"deviner","pos":"VER"} ,
		{"word":"deviné","word_nosc":"devine","lemma":"deviner","pos":"VER"} ,
		{"word":"devinée","word_nosc":"devinee","lemma":"deviner","pos":"VER"} ,
		{"word":"devinées","word_nosc":"devinees","lemma":"deviner","pos":"VER"} ,
		{"word":"devinés","word_nosc":"devines","lemma":"deviner","pos":"VER"} ,
		{"word":"devions","word_nosc":"devions","lemma":"devoir","pos":"VER"} ,
		{"word":"devisaient","word_nosc":"devisaient","lemma":"deviser","pos":"VER"} ,
		{"word":"devisais","word_nosc":"devisais","lemma":"deviser","pos":"VER"} ,
		{"word":"devisait","word_nosc":"devisait","lemma":"deviser","pos":"VER"} ,
		{"word":"devisant","word_nosc":"devisant","lemma":"deviser","pos":"VER"} ,
		{"word":"devise","word_nosc":"devise","lemma":"deviser","pos":"VER"} ,
		{"word":"devisent","word_nosc":"devisent","lemma":"deviser","pos":"VER"} ,
		{"word":"deviser","word_nosc":"deviser","lemma":"deviser","pos":"VER"} ,
		{"word":"devisions","word_nosc":"devisions","lemma":"deviser","pos":"VER"} ,
		{"word":"devisèrent","word_nosc":"deviserent","lemma":"deviser","pos":"VER"} ,
		{"word":"devisé","word_nosc":"devise","lemma":"deviser","pos":"VER"} ,
		{"word":"devoir","word_nosc":"devoir","lemma":"devoir","pos":"VER"} ,
		{"word":"devons","word_nosc":"devons","lemma":"devoir","pos":"VER"} ,
		{"word":"devra","word_nosc":"devra","lemma":"devoir","pos":"VER"} ,
		{"word":"devrai","word_nosc":"devrai","lemma":"devoir","pos":"VER"} ,
		{"word":"devraient","word_nosc":"devraient","lemma":"devoir","pos":"VER"} ,
		{"word":"devrais","word_nosc":"devrais","lemma":"devoir","pos":"VER"} ,
		{"word":"devrait","word_nosc":"devrait","lemma":"devoir","pos":"VER"} ,
		{"word":"devras","word_nosc":"devras","lemma":"devoir","pos":"VER"} ,
		{"word":"devrez","word_nosc":"devrez","lemma":"devoir","pos":"VER"} ,
		{"word":"devriez","word_nosc":"devriez","lemma":"devoir","pos":"VER"} ,
		{"word":"devrions","word_nosc":"devrions","lemma":"devoir","pos":"VER"} ,
		{"word":"devrons","word_nosc":"devrons","lemma":"devoir","pos":"VER"} ,
		{"word":"devront","word_nosc":"devront","lemma":"devoir","pos":"VER"} ,
		{"word":"devînmes","word_nosc":"devinmes","lemma":"devenir","pos":"VER"} ,
		{"word":"devînt","word_nosc":"devint","lemma":"devenir","pos":"VER"} ,
		{"word":"diabolisent","word_nosc":"diabolisent","lemma":"diaboliser","pos":"VER"} ,
		{"word":"diaboliser","word_nosc":"diaboliser","lemma":"diaboliser","pos":"VER"} ,
		{"word":"diabolisé","word_nosc":"diabolise","lemma":"diaboliser","pos":"VER"} ,
		{"word":"diabolisée","word_nosc":"diabolisee","lemma":"diaboliser","pos":"VER"} ,
		{"word":"diagnostiqua","word_nosc":"diagnostiqua","lemma":"diagnostiquer","pos":"VER"} ,
		{"word":"diagnostiquait","word_nosc":"diagnostiquait","lemma":"diagnostiquer","pos":"VER"} ,
		{"word":"diagnostiquant","word_nosc":"diagnostiquant","lemma":"diagnostiquer","pos":"VER"} ,
		{"word":"diagnostique","word_nosc":"diagnostique","lemma":"diagnostiquer","pos":"VER"} ,
		{"word":"diagnostiquer","word_nosc":"diagnostiquer","lemma":"diagnostiquer","pos":"VER"} ,
		{"word":"diagnostiquez","word_nosc":"diagnostiquez","lemma":"diagnostiquer","pos":"VER"} ,
		{"word":"diagnostiquèrent","word_nosc":"diagnostiquerent","lemma":"diagnostiquer","pos":"VER"} ,
		{"word":"diagnostiqué","word_nosc":"diagnostique","lemma":"diagnostiquer","pos":"VER"} ,
		{"word":"diagnostiqués","word_nosc":"diagnostiques","lemma":"diagnostiquer","pos":"VER"} ,
		{"word":"dialoguai","word_nosc":"dialoguai","lemma":"dialoguer","pos":"VER"} ,
		{"word":"dialoguaient","word_nosc":"dialoguaient","lemma":"dialoguer","pos":"VER"} ,
		{"word":"dialoguait","word_nosc":"dialoguait","lemma":"dialoguer","pos":"VER"} ,
		{"word":"dialoguant","word_nosc":"dialoguant","lemma":"dialoguer","pos":"VER"} ,
		{"word":"dialogue","word_nosc":"dialogue","lemma":"dialoguer","pos":"VER"} ,
		{"word":"dialoguent","word_nosc":"dialoguent","lemma":"dialoguer","pos":"VER"} ,
		{"word":"dialoguer","word_nosc":"dialoguer","lemma":"dialoguer","pos":"VER"} ,
		{"word":"dialoguera","word_nosc":"dialoguera","lemma":"dialoguer","pos":"VER"} ,
		{"word":"dialoguez","word_nosc":"dialoguez","lemma":"dialoguer","pos":"VER"} ,
		{"word":"dialoguons","word_nosc":"dialoguons","lemma":"dialoguer","pos":"VER"} ,
		{"word":"dialoguât","word_nosc":"dialoguat","lemma":"dialoguer","pos":"VER"} ,
		{"word":"dialogué","word_nosc":"dialogue","lemma":"dialoguer","pos":"VER"} ,
		{"word":"dialoguée","word_nosc":"dialoguee","lemma":"dialoguer","pos":"VER"} ,
		{"word":"dialoguées","word_nosc":"dialoguees","lemma":"dialoguer","pos":"VER"} ,
		{"word":"dialyser","word_nosc":"dialyser","lemma":"dialyser","pos":"VER"} ,
		{"word":"dialysé","word_nosc":"dialyse","lemma":"dialyser","pos":"VER"} ,
		{"word":"dialysée","word_nosc":"dialysee","lemma":"dialyser","pos":"VER"} ,
		{"word":"diamante","word_nosc":"diamante","lemma":"diamanter","pos":"VER"} ,
		{"word":"diamanté","word_nosc":"diamante","lemma":"diamanter","pos":"VER"} ,
		{"word":"diamantée","word_nosc":"diamantee","lemma":"diamanter","pos":"VER"} ,
		{"word":"diaphragma","word_nosc":"diaphragma","lemma":"diaphragmer","pos":"VER"} ,
		{"word":"diaprée","word_nosc":"diapree","lemma":"diaprer","pos":"VER"} ,
		{"word":"diaprées","word_nosc":"diaprees","lemma":"diaprer","pos":"VER"} ,
		{"word":"diaprés","word_nosc":"diapres","lemma":"diaprer","pos":"VER"} ,
		{"word":"dicta","word_nosc":"dicta","lemma":"dicter","pos":"VER"} ,
		{"word":"dictai","word_nosc":"dictai","lemma":"dicter","pos":"VER"} ,
		{"word":"dictaient","word_nosc":"dictaient","lemma":"dicter","pos":"VER"} ,
		{"word":"dictais","word_nosc":"dictais","lemma":"dicter","pos":"VER"} ,
		{"word":"dictait","word_nosc":"dictait","lemma":"dicter","pos":"VER"} ,
		{"word":"dictant","word_nosc":"dictant","lemma":"dicter","pos":"VER"} ,
		{"word":"dicte","word_nosc":"dicte","lemma":"dicter","pos":"VER"} ,
		{"word":"dictent","word_nosc":"dictent","lemma":"dicter","pos":"VER"} ,
		{"word":"dicter","word_nosc":"dicter","lemma":"dicter","pos":"VER"} ,
		{"word":"dictera","word_nosc":"dictera","lemma":"dicter","pos":"VER"} ,
		{"word":"dicterai","word_nosc":"dicterai","lemma":"dicter","pos":"VER"} ,
		{"word":"dicterez","word_nosc":"dicterez","lemma":"dicter","pos":"VER"} ,
		{"word":"dictes","word_nosc":"dictes","lemma":"dicter","pos":"VER"} ,
		{"word":"dictez","word_nosc":"dictez","lemma":"dicter","pos":"VER"} ,
		{"word":"dictiez","word_nosc":"dictiez","lemma":"dicter","pos":"VER"} ,
		{"word":"dictons","word_nosc":"dictons","lemma":"dicter","pos":"VER"} ,
		{"word":"dictât","word_nosc":"dictat","lemma":"dicter","pos":"VER"} ,
		{"word":"dictèrent","word_nosc":"dicterent","lemma":"dicter","pos":"VER"} ,
		{"word":"dicté","word_nosc":"dicte","lemma":"dicter","pos":"VER"} ,
		{"word":"dictée","word_nosc":"dictee","lemma":"dicter","pos":"VER"} ,
		{"word":"dictées","word_nosc":"dictees","lemma":"dicter","pos":"VER"} ,
		{"word":"dictés","word_nosc":"dictes","lemma":"dicter","pos":"VER"} ,
		{"word":"diffamait","word_nosc":"diffamait","lemma":"diffamer","pos":"VER"} ,
		{"word":"diffamant","word_nosc":"diffamant","lemma":"diffamer","pos":"VER"} ,
		{"word":"diffame","word_nosc":"diffame","lemma":"diffamer","pos":"VER"} ,
		{"word":"diffament","word_nosc":"diffament","lemma":"diffamer","pos":"VER"} ,
		{"word":"diffamer","word_nosc":"diffamer","lemma":"diffamer","pos":"VER"} ,
		{"word":"diffamé","word_nosc":"diffame","lemma":"diffamer","pos":"VER"} ,
		{"word":"diffamée","word_nosc":"diffamee","lemma":"diffamer","pos":"VER"} ,
		{"word":"diffamées","word_nosc":"diffamees","lemma":"diffamer","pos":"VER"} ,
		{"word":"diffamés","word_nosc":"diffames","lemma":"diffamer","pos":"VER"} ,
		{"word":"difforme","word_nosc":"difforme","lemma":"difformer","pos":"VER"} ,
		{"word":"diffractent","word_nosc":"diffractent","lemma":"diffracter","pos":"VER"} ,
		{"word":"diffractée","word_nosc":"diffractee","lemma":"diffracter","pos":"VER"} ,
		{"word":"diffusa","word_nosc":"diffusa","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffusaient","word_nosc":"diffusaient","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffusais","word_nosc":"diffusais","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffusait","word_nosc":"diffusait","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffusant","word_nosc":"diffusant","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffuse","word_nosc":"diffuse","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffusent","word_nosc":"diffusent","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffuser","word_nosc":"diffuser","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffusera","word_nosc":"diffusera","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffuserai","word_nosc":"diffuserai","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffuseraient","word_nosc":"diffuseraient","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffuserais","word_nosc":"diffuserais","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffuserait","word_nosc":"diffuserait","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffuserions","word_nosc":"diffuserions","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffuserons","word_nosc":"diffuserons","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffuseront","word_nosc":"diffuseront","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffuses","word_nosc":"diffuses","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffusez","word_nosc":"diffusez","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffusions","word_nosc":"diffusions","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffusons","word_nosc":"diffusons","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffusèrent","word_nosc":"diffuserent","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffusé","word_nosc":"diffuse","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffusée","word_nosc":"diffusee","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffusées","word_nosc":"diffusees","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffusés","word_nosc":"diffuses","lemma":"diffuser","pos":"VER"} ,
		{"word":"diffère","word_nosc":"differe","lemma":"différer","pos":"VER"} ,
		{"word":"diffèrent","word_nosc":"different","lemma":"différer","pos":"VER"} ,
		{"word":"différa","word_nosc":"differa","lemma":"différer","pos":"VER"} ,
		{"word":"différaient","word_nosc":"differaient","lemma":"différer","pos":"VER"} ,
		{"word":"différais","word_nosc":"differais","lemma":"différer","pos":"VER"} ,
		{"word":"différait","word_nosc":"differait","lemma":"différer","pos":"VER"} ,
		{"word":"différant","word_nosc":"differant","lemma":"différer","pos":"VER"} ,
		{"word":"différenciaient","word_nosc":"differenciaient","lemma":"différencier","pos":"VER"} ,
		{"word":"différenciais","word_nosc":"differenciais","lemma":"différencier","pos":"VER"} ,
		{"word":"différenciait","word_nosc":"differenciait","lemma":"différencier","pos":"VER"} ,
		{"word":"différenciant","word_nosc":"differenciant","lemma":"différencier","pos":"VER"} ,
		{"word":"différencie","word_nosc":"differencie","lemma":"différencier","pos":"VER"} ,
		{"word":"différencient","word_nosc":"differencient","lemma":"différencier","pos":"VER"} ,
		{"word":"différencier","word_nosc":"differencier","lemma":"différencier","pos":"VER"} ,
		{"word":"différenciera","word_nosc":"differenciera","lemma":"différencier","pos":"VER"} ,
		{"word":"différencierez","word_nosc":"differencierez","lemma":"différencier","pos":"VER"} ,
		{"word":"différencieront","word_nosc":"differencieront","lemma":"différencier","pos":"VER"} ,
		{"word":"différenciez","word_nosc":"differenciez","lemma":"différencier","pos":"VER"} ,
		{"word":"différencié","word_nosc":"differencie","lemma":"différencier","pos":"VER"} ,
		{"word":"différenciées","word_nosc":"differenciees","lemma":"différencier","pos":"VER"} ,
		{"word":"différenciés","word_nosc":"differencies","lemma":"différencier","pos":"VER"} ,
		{"word":"différentie","word_nosc":"differentie","lemma":"différentier","pos":"VER"} ,
		{"word":"différer","word_nosc":"differer","lemma":"différer","pos":"VER"} ,
		{"word":"différera","word_nosc":"differera","lemma":"différer","pos":"VER"} ,
		{"word":"différerais","word_nosc":"differerais","lemma":"différer","pos":"VER"} ,
		{"word":"différerait","word_nosc":"differerait","lemma":"différer","pos":"VER"} ,
		{"word":"différeront","word_nosc":"differeront","lemma":"différer","pos":"VER"} ,
		{"word":"différez","word_nosc":"differez","lemma":"différer","pos":"VER"} ,
		{"word":"différions","word_nosc":"differions","lemma":"différer","pos":"VER"} ,
		{"word":"différons","word_nosc":"differons","lemma":"différer","pos":"VER"} ,
		{"word":"différât","word_nosc":"differat","lemma":"différer","pos":"VER"} ,
		{"word":"différèrent","word_nosc":"differerent","lemma":"différer","pos":"VER"} ,
		{"word":"différé","word_nosc":"differe","lemma":"différer","pos":"VER"} ,
		{"word":"différée","word_nosc":"differee","lemma":"différer","pos":"VER"} ,
		{"word":"différées","word_nosc":"differees","lemma":"différer","pos":"VER"} ,
		{"word":"difracter","word_nosc":"difracter","lemma":"difracter","pos":"VER"} ,
		{"word":"digitalisée","word_nosc":"digitalisee","lemma":"digitaliser","pos":"VER"} ,
		{"word":"digitalisées","word_nosc":"digitalisees","lemma":"digitaliser","pos":"VER"} ,
		{"word":"digresse","word_nosc":"digresse","lemma":"digresser","pos":"VER"} ,
		{"word":"digère","word_nosc":"digere","lemma":"digérer","pos":"VER"} ,
		{"word":"digèrent","word_nosc":"digerent","lemma":"digérer","pos":"VER"} ,
		{"word":"digéra","word_nosc":"digera","lemma":"digérer","pos":"VER"} ,
		{"word":"digérai","word_nosc":"digerai","lemma":"digérer","pos":"VER"} ,
		{"word":"digéraient","word_nosc":"digeraient","lemma":"digérer","pos":"VER"} ,
		{"word":"digérait","word_nosc":"digerait","lemma":"digérer","pos":"VER"} ,
		{"word":"digérant","word_nosc":"digerant","lemma":"digérer","pos":"VER"} ,
		{"word":"digérer","word_nosc":"digerer","lemma":"digérer","pos":"VER"} ,
		{"word":"digérera","word_nosc":"digerera","lemma":"digérer","pos":"VER"} ,
		{"word":"digérerait","word_nosc":"digererait","lemma":"digérer","pos":"VER"} ,
		{"word":"digérons","word_nosc":"digerons","lemma":"digérer","pos":"VER"} ,
		{"word":"digérât","word_nosc":"digerat","lemma":"digérer","pos":"VER"} ,
		{"word":"digéré","word_nosc":"digere","lemma":"digérer","pos":"VER"} ,
		{"word":"digérée","word_nosc":"digeree","lemma":"digérer","pos":"VER"} ,
		{"word":"digérées","word_nosc":"digerees","lemma":"digérer","pos":"VER"} ,
		{"word":"digérés","word_nosc":"digeres","lemma":"digérer","pos":"VER"} ,
		{"word":"dilapida","word_nosc":"dilapida","lemma":"dilapider","pos":"VER"} ,
		{"word":"dilapidait","word_nosc":"dilapidait","lemma":"dilapider","pos":"VER"} ,
		{"word":"dilapidant","word_nosc":"dilapidant","lemma":"dilapider","pos":"VER"} ,
		{"word":"dilapide","word_nosc":"dilapide","lemma":"dilapider","pos":"VER"} ,
		{"word":"dilapider","word_nosc":"dilapider","lemma":"dilapider","pos":"VER"} ,
		{"word":"dilapides","word_nosc":"dilapides","lemma":"dilapider","pos":"VER"} ,
		{"word":"dilapidez","word_nosc":"dilapidez","lemma":"dilapider","pos":"VER"} ,
		{"word":"dilapidé","word_nosc":"dilapide","lemma":"dilapider","pos":"VER"} ,
		{"word":"dilapidée","word_nosc":"dilapidee","lemma":"dilapider","pos":"VER"} ,
		{"word":"dilapidées","word_nosc":"dilapidees","lemma":"dilapider","pos":"VER"} ,
		{"word":"dilata","word_nosc":"dilata","lemma":"dilater","pos":"VER"} ,
		{"word":"dilataient","word_nosc":"dilataient","lemma":"dilater","pos":"VER"} ,
		{"word":"dilatait","word_nosc":"dilatait","lemma":"dilater","pos":"VER"} ,
		{"word":"dilatant","word_nosc":"dilatant","lemma":"dilater","pos":"VER"} ,
		{"word":"dilate","word_nosc":"dilate","lemma":"dilater","pos":"VER"} ,
		{"word":"dilatent","word_nosc":"dilatent","lemma":"dilater","pos":"VER"} ,
		{"word":"dilater","word_nosc":"dilater","lemma":"dilater","pos":"VER"} ,
		{"word":"dilatât","word_nosc":"dilatat","lemma":"dilater","pos":"VER"} ,
		{"word":"dilatèrent","word_nosc":"dilaterent","lemma":"dilater","pos":"VER"} ,
		{"word":"dilaté","word_nosc":"dilate","lemma":"dilater","pos":"VER"} ,
		{"word":"dilatée","word_nosc":"dilatee","lemma":"dilater","pos":"VER"} ,
		{"word":"dilatées","word_nosc":"dilatees","lemma":"dilater","pos":"VER"} ,
		{"word":"dilatés","word_nosc":"dilates","lemma":"dilater","pos":"VER"} ,
		{"word":"diligenter","word_nosc":"diligenter","lemma":"diligenter","pos":"VER"} ,
		{"word":"dilua","word_nosc":"dilua","lemma":"diluer","pos":"VER"} ,
		{"word":"diluaient","word_nosc":"diluaient","lemma":"diluer","pos":"VER"} ,
		{"word":"diluait","word_nosc":"diluait","lemma":"diluer","pos":"VER"} ,
		{"word":"diluant","word_nosc":"diluant","lemma":"diluer","pos":"VER"} ,
		{"word":"dilue","word_nosc":"dilue","lemma":"diluer","pos":"VER"} ,
		{"word":"diluent","word_nosc":"diluent","lemma":"diluer","pos":"VER"} ,
		{"word":"diluer","word_nosc":"diluer","lemma":"diluer","pos":"VER"} ,
		{"word":"diluerait","word_nosc":"diluerait","lemma":"diluer","pos":"VER"} ,
		{"word":"diluons","word_nosc":"diluons","lemma":"diluer","pos":"VER"} ,
		{"word":"dilué","word_nosc":"dilue","lemma":"diluer","pos":"VER"} ,
		{"word":"diluée","word_nosc":"diluee","lemma":"diluer","pos":"VER"} ,
		{"word":"diluées","word_nosc":"diluees","lemma":"diluer","pos":"VER"} ,
		{"word":"dilués","word_nosc":"dilues","lemma":"diluer","pos":"VER"} ,
		{"word":"diminua","word_nosc":"diminua","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuaient","word_nosc":"diminuaient","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuais","word_nosc":"diminuais","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuait","word_nosc":"diminuait","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuant","word_nosc":"diminuant","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminue","word_nosc":"diminue","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuent","word_nosc":"diminuent","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuer","word_nosc":"diminuer","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuera","word_nosc":"diminuera","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuerai","word_nosc":"diminuerai","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuerais","word_nosc":"diminuerais","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuerait","word_nosc":"diminuerait","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminueront","word_nosc":"diminueront","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuez","word_nosc":"diminuez","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuiez","word_nosc":"diminuiez","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuât","word_nosc":"diminuat","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuèrent","word_nosc":"diminuerent","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminué","word_nosc":"diminue","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuée","word_nosc":"diminuee","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminuées","word_nosc":"diminuees","lemma":"diminuer","pos":"VER"} ,
		{"word":"diminués","word_nosc":"diminues","lemma":"diminuer","pos":"VER"} ,
		{"word":"dindonne","word_nosc":"dindonne","lemma":"dindonner","pos":"VER"} ,
		{"word":"dindonné","word_nosc":"dindonne","lemma":"dindonner","pos":"VER"} ,
		{"word":"dingue","word_nosc":"dingue","lemma":"dinguer","pos":"VER"} ,
		{"word":"dinguent","word_nosc":"dinguent","lemma":"dinguer","pos":"VER"} ,
		{"word":"dinguer","word_nosc":"dinguer","lemma":"dinguer","pos":"VER"} ,
		{"word":"dingué","word_nosc":"dingue","lemma":"dinguer","pos":"VER"} ,
		{"word":"diplômer","word_nosc":"diplomer","lemma":"diplômer","pos":"VER"} ,
		{"word":"diplômé","word_nosc":"diplome","lemma":"diplômer","pos":"VER"} ,
		{"word":"diplômée","word_nosc":"diplomee","lemma":"diplômer","pos":"VER"} ,
		{"word":"diplômées","word_nosc":"diplomees","lemma":"diplômer","pos":"VER"} ,
		{"word":"diplômés","word_nosc":"diplomes","lemma":"diplômer","pos":"VER"} ,
		{"word":"dira","word_nosc":"dira","lemma":"dire","pos":"VER"} ,
		{"word":"dirai","word_nosc":"dirai","lemma":"dire","pos":"VER"} ,
		{"word":"diraient","word_nosc":"diraient","lemma":"dire","pos":"VER"} ,
		{"word":"dirais","word_nosc":"dirais","lemma":"dire","pos":"VER"} ,
		{"word":"dirait","word_nosc":"dirait","lemma":"dire","pos":"VER"} ,
		{"word":"diras","word_nosc":"diras","lemma":"dire","pos":"VER"} ,
		{"word":"dire","word_nosc":"dire","lemma":"dire","pos":"VER"} ,
		{"word":"dirent","word_nosc":"dirent","lemma":"dire","pos":"VER"} ,
		{"word":"direz","word_nosc":"direz","lemma":"dire","pos":"VER"} ,
		{"word":"diriez","word_nosc":"diriez","lemma":"dire","pos":"VER"} ,
		{"word":"dirige","word_nosc":"dirige","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigea","word_nosc":"dirigea","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigeai","word_nosc":"dirigeai","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigeaient","word_nosc":"dirigeaient","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigeais","word_nosc":"dirigeais","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigeait","word_nosc":"dirigeait","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigeant","word_nosc":"dirigeant","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigent","word_nosc":"dirigent","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigeons","word_nosc":"dirigeons","lemma":"diriger","pos":"VER"} ,
		{"word":"diriger","word_nosc":"diriger","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigera","word_nosc":"dirigera","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigerai","word_nosc":"dirigerai","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigeraient","word_nosc":"dirigeraient","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigerais","word_nosc":"dirigerais","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigerait","word_nosc":"dirigerait","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigeras","word_nosc":"dirigeras","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigerez","word_nosc":"dirigerez","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigerons","word_nosc":"dirigerons","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigeront","word_nosc":"dirigeront","lemma":"diriger","pos":"VER"} ,
		{"word":"diriges","word_nosc":"diriges","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigez","word_nosc":"dirigez","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigeâmes","word_nosc":"dirigeames","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigeât","word_nosc":"dirigeat","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigiez","word_nosc":"dirigiez","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigions","word_nosc":"dirigions","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigèrent","word_nosc":"dirigerent","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigé","word_nosc":"dirige","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigée","word_nosc":"dirigee","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigées","word_nosc":"dirigees","lemma":"diriger","pos":"VER"} ,
		{"word":"dirigés","word_nosc":"diriges","lemma":"diriger","pos":"VER"} ,
		{"word":"dirions","word_nosc":"dirions","lemma":"dire","pos":"VER"} ,
		{"word":"dirons","word_nosc":"dirons","lemma":"dire","pos":"VER"} ,
		{"word":"diront","word_nosc":"diront","lemma":"dire","pos":"VER"} ,
		{"word":"dis","word_nosc":"dis","lemma":"dire","pos":"VER"} ,
		{"word":"disaient","word_nosc":"disaient","lemma":"dire","pos":"VER"} ,
		{"word":"disais","word_nosc":"disais","lemma":"dire","pos":"VER"} ,
		{"word":"disait","word_nosc":"disait","lemma":"dire","pos":"VER"} ,
		{"word":"disant","word_nosc":"disant","lemma":"dire","pos":"VER"} ,
		{"word":"discerna","word_nosc":"discerna","lemma":"discerner","pos":"VER"} ,
		{"word":"discernai","word_nosc":"discernai","lemma":"discerner","pos":"VER"} ,
		{"word":"discernaient","word_nosc":"discernaient","lemma":"discerner","pos":"VER"} ,
		{"word":"discernais","word_nosc":"discernais","lemma":"discerner","pos":"VER"} ,
		{"word":"discernait","word_nosc":"discernait","lemma":"discerner","pos":"VER"} ,
		{"word":"discernant","word_nosc":"discernant","lemma":"discerner","pos":"VER"} ,
		{"word":"discerne","word_nosc":"discerne","lemma":"discerner","pos":"VER"} ,
		{"word":"discernent","word_nosc":"discernent","lemma":"discerner","pos":"VER"} ,
		{"word":"discerner","word_nosc":"discerner","lemma":"discerner","pos":"VER"} ,
		{"word":"discernerait","word_nosc":"discernerait","lemma":"discerner","pos":"VER"} ,
		{"word":"discernes","word_nosc":"discernes","lemma":"discerner","pos":"VER"} ,
		{"word":"discernions","word_nosc":"discernions","lemma":"discerner","pos":"VER"} ,
		{"word":"discernâmes","word_nosc":"discernames","lemma":"discerner","pos":"VER"} ,
		{"word":"discernât","word_nosc":"discernat","lemma":"discerner","pos":"VER"} ,
		{"word":"discerné","word_nosc":"discerne","lemma":"discerner","pos":"VER"} ,
		{"word":"discernées","word_nosc":"discernees","lemma":"discerner","pos":"VER"} ,
		{"word":"disciplina","word_nosc":"disciplina","lemma":"discipliner","pos":"VER"} ,
		{"word":"disciplinaient","word_nosc":"disciplinaient","lemma":"discipliner","pos":"VER"} ,
		{"word":"disciplinais","word_nosc":"disciplinais","lemma":"discipliner","pos":"VER"} ,
		{"word":"disciplinait","word_nosc":"disciplinait","lemma":"discipliner","pos":"VER"} ,
		{"word":"discipliner","word_nosc":"discipliner","lemma":"discipliner","pos":"VER"} ,
		{"word":"disciplinera","word_nosc":"disciplinera","lemma":"discipliner","pos":"VER"} ,
		{"word":"disciplinerait","word_nosc":"disciplinerait","lemma":"discipliner","pos":"VER"} ,
		{"word":"discipliné","word_nosc":"discipline","lemma":"discipliner","pos":"VER"} ,
		{"word":"disciplinée","word_nosc":"disciplinee","lemma":"discipliner","pos":"VER"} ,
		{"word":"disciplinés","word_nosc":"disciplines","lemma":"discipliner","pos":"VER"} ,
		{"word":"discontinuaient","word_nosc":"discontinuaient","lemma":"discontinuer","pos":"VER"} ,
		{"word":"discontinuer","word_nosc":"discontinuer","lemma":"discontinuer","pos":"VER"} ,
		{"word":"disconviens","word_nosc":"disconviens","lemma":"disconvenir","pos":"VER"} ,
		{"word":"disconvint","word_nosc":"disconvint","lemma":"disconvenir","pos":"VER"} ,
		{"word":"discordant","word_nosc":"discordant","lemma":"discorder","pos":"VER"} ,
		{"word":"discouraient","word_nosc":"discouraient","lemma":"discourir","pos":"VER"} ,
		{"word":"discourait","word_nosc":"discourait","lemma":"discourir","pos":"VER"} ,
		{"word":"discourant","word_nosc":"discourant","lemma":"discourir","pos":"VER"} ,
		{"word":"discoure","word_nosc":"discoure","lemma":"discourir","pos":"VER"} ,
		{"word":"discourent","word_nosc":"discourent","lemma":"discourir","pos":"VER"} ,
		{"word":"discourir","word_nosc":"discourir","lemma":"discourir","pos":"VER"} ,
		{"word":"discours","word_nosc":"discours","lemma":"discourir","pos":"VER"} ,
		{"word":"discourt","word_nosc":"discourt","lemma":"discourir","pos":"VER"} ,
		{"word":"discouru","word_nosc":"discouru","lemma":"discourir","pos":"VER"} ,
		{"word":"discourut","word_nosc":"discourut","lemma":"discourir","pos":"VER"} ,
		{"word":"discriminer","word_nosc":"discriminer","lemma":"discriminer","pos":"VER"} ,
		{"word":"discriminé","word_nosc":"discrimine","lemma":"discriminer","pos":"VER"} ,
		{"word":"discréditait","word_nosc":"discreditait","lemma":"discréditer","pos":"VER"} ,
		{"word":"discréditant","word_nosc":"discreditant","lemma":"discréditer","pos":"VER"} ,
		{"word":"discrédite","word_nosc":"discredite","lemma":"discréditer","pos":"VER"} ,
		{"word":"discréditer","word_nosc":"discrediter","lemma":"discréditer","pos":"VER"} ,
		{"word":"discréditerait","word_nosc":"discrediterait","lemma":"discréditer","pos":"VER"} ,
		{"word":"discréditez","word_nosc":"discreditez","lemma":"discréditer","pos":"VER"} ,
		{"word":"discrédité","word_nosc":"discredite","lemma":"discréditer","pos":"VER"} ,
		{"word":"discréditée","word_nosc":"discreditee","lemma":"discréditer","pos":"VER"} ,
		{"word":"discréditées","word_nosc":"discreditees","lemma":"discréditer","pos":"VER"} ,
		{"word":"discrédités","word_nosc":"discredites","lemma":"discréditer","pos":"VER"} ,
		{"word":"disculpait","word_nosc":"disculpait","lemma":"disculper","pos":"VER"} ,
		{"word":"disculpant","word_nosc":"disculpant","lemma":"disculper","pos":"VER"} ,
		{"word":"disculpe","word_nosc":"disculpe","lemma":"disculper","pos":"VER"} ,
		{"word":"disculpent","word_nosc":"disculpent","lemma":"disculper","pos":"VER"} ,
		{"word":"disculper","word_nosc":"disculper","lemma":"disculper","pos":"VER"} ,
		{"word":"disculperait","word_nosc":"disculperait","lemma":"disculper","pos":"VER"} ,
		{"word":"disculpez","word_nosc":"disculpez","lemma":"disculper","pos":"VER"} ,
		{"word":"disculpé","word_nosc":"disculpe","lemma":"disculper","pos":"VER"} ,
		{"word":"disculpés","word_nosc":"disculpes","lemma":"disculper","pos":"VER"} ,
		{"word":"discuta","word_nosc":"discuta","lemma":"discuter","pos":"VER"} ,
		{"word":"discutai","word_nosc":"discutai","lemma":"discuter","pos":"VER"} ,
		{"word":"discutaient","word_nosc":"discutaient","lemma":"discuter","pos":"VER"} ,
		{"word":"discutaillaient","word_nosc":"discutaillaient","lemma":"discutailler","pos":"VER"} ,
		{"word":"discutaillait","word_nosc":"discutaillait","lemma":"discutailler","pos":"VER"} ,
		{"word":"discutaille","word_nosc":"discutaille","lemma":"discutailler","pos":"VER"} ,
		{"word":"discutaillent","word_nosc":"discutaillent","lemma":"discutailler","pos":"VER"} ,
		{"word":"discutailler","word_nosc":"discutailler","lemma":"discutailler","pos":"VER"} ,
		{"word":"discutais","word_nosc":"discutais","lemma":"discuter","pos":"VER"} ,
		{"word":"discutait","word_nosc":"discutait","lemma":"discuter","pos":"VER"} ,
		{"word":"discutant","word_nosc":"discutant","lemma":"discuter","pos":"VER"} ,
		{"word":"discute","word_nosc":"discute","lemma":"discuter","pos":"VER"} ,
		{"word":"discutent","word_nosc":"discutent","lemma":"discuter","pos":"VER"} ,
		{"word":"discuter","word_nosc":"discuter","lemma":"discuter","pos":"VER"} ,
		{"word":"discutera","word_nosc":"discutera","lemma":"discuter","pos":"VER"} ,
		{"word":"discuterai","word_nosc":"discuterai","lemma":"discuter","pos":"VER"} ,
		{"word":"discuteraient","word_nosc":"discuteraient","lemma":"discuter","pos":"VER"} ,
		{"word":"discuterais","word_nosc":"discuterais","lemma":"discuter","pos":"VER"} ,
		{"word":"discuterait","word_nosc":"discuterait","lemma":"discuter","pos":"VER"} ,
		{"word":"discuterez","word_nosc":"discuterez","lemma":"discuter","pos":"VER"} ,
		{"word":"discuteriez","word_nosc":"discuteriez","lemma":"discuter","pos":"VER"} ,
		{"word":"discuterions","word_nosc":"discuterions","lemma":"discuter","pos":"VER"} ,
		{"word":"discuterons","word_nosc":"discuterons","lemma":"discuter","pos":"VER"} ,
		{"word":"discuteront","word_nosc":"discuteront","lemma":"discuter","pos":"VER"} ,
		{"word":"discutes","word_nosc":"discutes","lemma":"discuter","pos":"VER"} ,
		{"word":"discutez","word_nosc":"discutez","lemma":"discuter","pos":"VER"} ,
		{"word":"discutiez","word_nosc":"discutiez","lemma":"discuter","pos":"VER"} ,
		{"word":"discutions","word_nosc":"discutions","lemma":"discuter","pos":"VER"} ,
		{"word":"discutons","word_nosc":"discutons","lemma":"discuter","pos":"VER"} ,
		{"word":"discutâmes","word_nosc":"discutames","lemma":"discuter","pos":"VER"} ,
		{"word":"discutât","word_nosc":"discutat","lemma":"discuter","pos":"VER"} ,
		{"word":"discutèrent","word_nosc":"discuterent","lemma":"discuter","pos":"VER"} ,
		{"word":"discuté","word_nosc":"discute","lemma":"discuter","pos":"VER"} ,
		{"word":"discutée","word_nosc":"discutee","lemma":"discuter","pos":"VER"} ,
		{"word":"discutées","word_nosc":"discutees","lemma":"discuter","pos":"VER"} ,
		{"word":"discutés","word_nosc":"discutes","lemma":"discuter","pos":"VER"} ,
		{"word":"dise","word_nosc":"dise","lemma":"dire","pos":"VER"} ,
		{"word":"disent","word_nosc":"disent","lemma":"dire","pos":"VER"} ,
		{"word":"dises","word_nosc":"dises","lemma":"dire","pos":"VER"} ,
		{"word":"disgracia","word_nosc":"disgracia","lemma":"disgracier","pos":"VER"} ,
		{"word":"disgraciaient","word_nosc":"disgraciaient","lemma":"disgracier","pos":"VER"} ,
		{"word":"disgraciait","word_nosc":"disgraciait","lemma":"disgracier","pos":"VER"} ,
		{"word":"disgracier","word_nosc":"disgracier","lemma":"disgracier","pos":"VER"} ,
		{"word":"disgraciera","word_nosc":"disgraciera","lemma":"disgracier","pos":"VER"} ,
		{"word":"disgracié","word_nosc":"disgracie","lemma":"disgracier","pos":"VER"} ,
		{"word":"disgraciée","word_nosc":"disgraciee","lemma":"disgracier","pos":"VER"} ,
		{"word":"disgraciés","word_nosc":"disgracies","lemma":"disgracier","pos":"VER"} ,
		{"word":"disiez","word_nosc":"disiez","lemma":"dire","pos":"VER"} ,
		{"word":"disions","word_nosc":"disions","lemma":"dire","pos":"VER"} ,
		{"word":"disjoignait","word_nosc":"disjoignait","lemma":"disjoindre","pos":"VER"} ,
		{"word":"disjoignant","word_nosc":"disjoignant","lemma":"disjoindre","pos":"VER"} ,
		{"word":"disjoignent","word_nosc":"disjoignent","lemma":"disjoindre","pos":"VER"} ,
		{"word":"disjoindre","word_nosc":"disjoindre","lemma":"disjoindre","pos":"VER"} ,
		{"word":"disjoint","word_nosc":"disjoint","lemma":"disjoindre","pos":"VER"} ,
		{"word":"disjointe","word_nosc":"disjointe","lemma":"disjoindre","pos":"VER"} ,
		{"word":"disjointes","word_nosc":"disjointes","lemma":"disjoindre","pos":"VER"} ,
		{"word":"disjoints","word_nosc":"disjoints","lemma":"disjoindre","pos":"VER"} ,
		{"word":"disjoncta","word_nosc":"disjoncta","lemma":"disjoncter","pos":"VER"} ,
		{"word":"disjonctais","word_nosc":"disjonctais","lemma":"disjoncter","pos":"VER"} ,
		{"word":"disjonctait","word_nosc":"disjonctait","lemma":"disjoncter","pos":"VER"} ,
		{"word":"disjoncte","word_nosc":"disjoncte","lemma":"disjoncter","pos":"VER"} ,
		{"word":"disjonctent","word_nosc":"disjonctent","lemma":"disjoncter","pos":"VER"} ,
		{"word":"disjoncter","word_nosc":"disjoncter","lemma":"disjoncter","pos":"VER"} ,
		{"word":"disjoncterais","word_nosc":"disjoncterais","lemma":"disjoncter","pos":"VER"} ,
		{"word":"disjoncterait","word_nosc":"disjoncterait","lemma":"disjoncter","pos":"VER"} ,
		{"word":"disjonctes","word_nosc":"disjonctes","lemma":"disjoncter","pos":"VER"} ,
		{"word":"disjonctez","word_nosc":"disjonctez","lemma":"disjoncter","pos":"VER"} ,
		{"word":"disjoncté","word_nosc":"disjoncte","lemma":"disjoncter","pos":"VER"} ,
		{"word":"disjonctée","word_nosc":"disjonctee","lemma":"disjoncter","pos":"VER"} ,
		{"word":"disjonctés","word_nosc":"disjonctes","lemma":"disjoncter","pos":"VER"} ,
		{"word":"disloqua","word_nosc":"disloqua","lemma":"disloquer","pos":"VER"} ,
		{"word":"disloquai","word_nosc":"disloquai","lemma":"disloquer","pos":"VER"} ,
		{"word":"disloquaient","word_nosc":"disloquaient","lemma":"disloquer","pos":"VER"} ,
		{"word":"disloquait","word_nosc":"disloquait","lemma":"disloquer","pos":"VER"} ,
		{"word":"disloquant","word_nosc":"disloquant","lemma":"disloquer","pos":"VER"} ,
		{"word":"disloque","word_nosc":"disloque","lemma":"disloquer","pos":"VER"} ,
		{"word":"disloquent","word_nosc":"disloquent","lemma":"disloquer","pos":"VER"} ,
		{"word":"disloquer","word_nosc":"disloquer","lemma":"disloquer","pos":"VER"} ,
		{"word":"disloquerai","word_nosc":"disloquerai","lemma":"disloquer","pos":"VER"} ,
		{"word":"disloqueront","word_nosc":"disloqueront","lemma":"disloquer","pos":"VER"} ,
		{"word":"disloquât","word_nosc":"disloquat","lemma":"disloquer","pos":"VER"} ,
		{"word":"disloquèrent","word_nosc":"disloquerent","lemma":"disloquer","pos":"VER"} ,
		{"word":"disloqué","word_nosc":"disloque","lemma":"disloquer","pos":"VER"} ,
		{"word":"disloquée","word_nosc":"disloquee","lemma":"disloquer","pos":"VER"} ,
		{"word":"disloquées","word_nosc":"disloquees","lemma":"disloquer","pos":"VER"} ,
		{"word":"disloqués","word_nosc":"disloques","lemma":"disloquer","pos":"VER"} ,
		{"word":"disons","word_nosc":"disons","lemma":"dire","pos":"VER"} ,
		{"word":"disparais","word_nosc":"disparais","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaissaient","word_nosc":"disparaissaient","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaissais","word_nosc":"disparaissais","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaissait","word_nosc":"disparaissait","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaissant","word_nosc":"disparaissant","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaisse","word_nosc":"disparaisse","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaissent","word_nosc":"disparaissent","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaisses","word_nosc":"disparaisses","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaissez","word_nosc":"disparaissez","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaissiez","word_nosc":"disparaissiez","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaissions","word_nosc":"disparaissions","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaissons","word_nosc":"disparaissons","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaît","word_nosc":"disparait","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaîtra","word_nosc":"disparaitra","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaîtrai","word_nosc":"disparaitrai","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaîtraient","word_nosc":"disparaitraient","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaîtrais","word_nosc":"disparaitrais","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaîtrait","word_nosc":"disparaitrait","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaîtras","word_nosc":"disparaitras","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaître","word_nosc":"disparaitre","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaîtrez","word_nosc":"disparaitrez","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaîtrions","word_nosc":"disparaitrions","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaîtrons","word_nosc":"disparaitrons","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparaîtront","word_nosc":"disparaitront","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparu","word_nosc":"disparu","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparue","word_nosc":"disparue","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparues","word_nosc":"disparues","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparurent","word_nosc":"disparurent","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparus","word_nosc":"disparus","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparut","word_nosc":"disparut","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparûmes","word_nosc":"disparumes","lemma":"disparaître","pos":"VER"} ,
		{"word":"disparût","word_nosc":"disparut","lemma":"disparaître","pos":"VER"} ,
		{"word":"dispatchais","word_nosc":"dispatchais","lemma":"dispatcher","pos":"VER"} ,
		{"word":"dispatchait","word_nosc":"dispatchait","lemma":"dispatcher","pos":"VER"} ,
		{"word":"dispatche","word_nosc":"dispatche","lemma":"dispatcher","pos":"VER"} ,
		{"word":"dispatcher","word_nosc":"dispatcher","lemma":"dispatcher","pos":"VER"} ,
		{"word":"dispensa","word_nosc":"dispensa","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispensaient","word_nosc":"dispensaient","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispensais","word_nosc":"dispensais","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispensait","word_nosc":"dispensait","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispensant","word_nosc":"dispensant","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispense","word_nosc":"dispense","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispensent","word_nosc":"dispensent","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispenser","word_nosc":"dispenser","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispenserait","word_nosc":"dispenserait","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispenseras","word_nosc":"dispenseras","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispenses","word_nosc":"dispenses","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispensez","word_nosc":"dispensez","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispensât","word_nosc":"dispensat","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispensèrent","word_nosc":"dispenserent","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispensé","word_nosc":"dispense","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispensée","word_nosc":"dispensee","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispensées","word_nosc":"dispensees","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispensés","word_nosc":"dispenses","lemma":"dispenser","pos":"VER"} ,
		{"word":"dispersa","word_nosc":"dispersa","lemma":"disperser","pos":"VER"} ,
		{"word":"dispersaient","word_nosc":"dispersaient","lemma":"disperser","pos":"VER"} ,
		{"word":"dispersait","word_nosc":"dispersait","lemma":"disperser","pos":"VER"} ,
		{"word":"dispersant","word_nosc":"dispersant","lemma":"disperser","pos":"VER"} ,
		{"word":"disperse","word_nosc":"disperse","lemma":"disperser","pos":"VER"} ,
		{"word":"dispersent","word_nosc":"dispersent","lemma":"disperser","pos":"VER"} ,
		{"word":"disperser","word_nosc":"disperser","lemma":"disperser","pos":"VER"} ,
		{"word":"dispersera","word_nosc":"dispersera","lemma":"disperser","pos":"VER"} ,
		{"word":"disperserai","word_nosc":"disperserai","lemma":"disperser","pos":"VER"} ,
		{"word":"disperseraient","word_nosc":"disperseraient","lemma":"disperser","pos":"VER"} ,
		{"word":"disperserait","word_nosc":"disperserait","lemma":"disperser","pos":"VER"} ,
		{"word":"disperserons","word_nosc":"disperserons","lemma":"disperser","pos":"VER"} ,
		{"word":"disperseront","word_nosc":"disperseront","lemma":"disperser","pos":"VER"} ,
		{"word":"disperses","word_nosc":"disperses","lemma":"disperser","pos":"VER"} ,
		{"word":"dispersez","word_nosc":"dispersez","lemma":"disperser","pos":"VER"} ,
		{"word":"dispersions","word_nosc":"dispersions","lemma":"disperser","pos":"VER"} ,
		{"word":"dispersons","word_nosc":"dispersons","lemma":"disperser","pos":"VER"} ,
		{"word":"dispersât","word_nosc":"dispersat","lemma":"disperser","pos":"VER"} ,
		{"word":"dispersèrent","word_nosc":"disperserent","lemma":"disperser","pos":"VER"} ,
		{"word":"dispersé","word_nosc":"disperse","lemma":"disperser","pos":"VER"} ,
		{"word":"dispersée","word_nosc":"dispersee","lemma":"disperser","pos":"VER"} ,
		{"word":"dispersées","word_nosc":"dispersees","lemma":"disperser","pos":"VER"} ,
		{"word":"dispersés","word_nosc":"disperses","lemma":"disperser","pos":"VER"} ,
		{"word":"disposa","word_nosc":"disposa","lemma":"disposer","pos":"VER"} ,
		{"word":"disposai","word_nosc":"disposai","lemma":"disposer","pos":"VER"} ,
		{"word":"disposaient","word_nosc":"disposaient","lemma":"disposer","pos":"VER"} ,
		{"word":"disposais","word_nosc":"disposais","lemma":"disposer","pos":"VER"} ,
		{"word":"disposait","word_nosc":"disposait","lemma":"disposer","pos":"VER"} ,
		{"word":"disposant","word_nosc":"disposant","lemma":"disposer","pos":"VER"} ,
		{"word":"disposas","word_nosc":"disposas","lemma":"disposer","pos":"VER"} ,
		{"word":"dispose","word_nosc":"dispose","lemma":"disposer","pos":"VER"} ,
		{"word":"disposent","word_nosc":"disposent","lemma":"disposer","pos":"VER"} ,
		{"word":"disposer","word_nosc":"disposer","lemma":"disposer","pos":"VER"} ,
		{"word":"disposera","word_nosc":"disposera","lemma":"disposer","pos":"VER"} ,
		{"word":"disposerai","word_nosc":"disposerai","lemma":"disposer","pos":"VER"} ,
		{"word":"disposeraient","word_nosc":"disposeraient","lemma":"disposer","pos":"VER"} ,
		{"word":"disposerais","word_nosc":"disposerais","lemma":"disposer","pos":"VER"} ,
		{"word":"disposerait","word_nosc":"disposerait","lemma":"disposer","pos":"VER"} ,
		{"word":"disposeras","word_nosc":"disposeras","lemma":"disposer","pos":"VER"} ,
		{"word":"disposerez","word_nosc":"disposerez","lemma":"disposer","pos":"VER"} ,
		{"word":"disposerons","word_nosc":"disposerons","lemma":"disposer","pos":"VER"} ,
		{"word":"disposeront","word_nosc":"disposeront","lemma":"disposer","pos":"VER"} ,
		{"word":"disposes","word_nosc":"disposes","lemma":"disposer","pos":"VER"} ,
		{"word":"disposez","word_nosc":"disposez","lemma":"disposer","pos":"VER"} ,
		{"word":"disposiez","word_nosc":"disposiez","lemma":"disposer","pos":"VER"} ,
		{"word":"disposions","word_nosc":"disposions","lemma":"disposer","pos":"VER"} ,
		{"word":"disposons","word_nosc":"disposons","lemma":"disposer","pos":"VER"} ,
		{"word":"disposâmes","word_nosc":"disposames","lemma":"disposer","pos":"VER"} ,
		{"word":"disposât","word_nosc":"disposat","lemma":"disposer","pos":"VER"} ,
		{"word":"disposèrent","word_nosc":"disposerent","lemma":"disposer","pos":"VER"} ,
		{"word":"disposé","word_nosc":"dispose","lemma":"disposer","pos":"VER"} ,
		{"word":"disposée","word_nosc":"disposee","lemma":"disposer","pos":"VER"} ,
		{"word":"disposées","word_nosc":"disposees","lemma":"disposer","pos":"VER"} ,
		{"word":"disposés","word_nosc":"disposes","lemma":"disposer","pos":"VER"} ,
		{"word":"disproportionné","word_nosc":"disproportionne","lemma":"disproportionner","pos":"VER"} ,
		{"word":"disproportionnée","word_nosc":"disproportionnee","lemma":"disproportionner","pos":"VER"} ,
		{"word":"disproportionnées","word_nosc":"disproportionnees","lemma":"disproportionner","pos":"VER"} ,
		{"word":"disputa","word_nosc":"disputa","lemma":"disputer","pos":"VER"} ,
		{"word":"disputaient","word_nosc":"disputaient","lemma":"disputer","pos":"VER"} ,
		{"word":"disputais","word_nosc":"disputais","lemma":"disputer","pos":"VER"} ,
		{"word":"disputait","word_nosc":"disputait","lemma":"disputer","pos":"VER"} ,
		{"word":"disputant","word_nosc":"disputant","lemma":"disputer","pos":"VER"} ,
		{"word":"dispute","word_nosc":"dispute","lemma":"disputer","pos":"VER"} ,
		{"word":"disputent","word_nosc":"disputent","lemma":"disputer","pos":"VER"} ,
		{"word":"disputer","word_nosc":"disputer","lemma":"disputer","pos":"VER"} ,
		{"word":"disputera","word_nosc":"disputera","lemma":"disputer","pos":"VER"} ,
		{"word":"disputerai","word_nosc":"disputerai","lemma":"disputer","pos":"VER"} ,
		{"word":"disputeraient","word_nosc":"disputeraient","lemma":"disputer","pos":"VER"} ,
		{"word":"disputerait","word_nosc":"disputerait","lemma":"disputer","pos":"VER"} ,
		{"word":"disputerez","word_nosc":"disputerez","lemma":"disputer","pos":"VER"} ,
		{"word":"disputerons","word_nosc":"disputerons","lemma":"disputer","pos":"VER"} ,
		{"word":"disputeront","word_nosc":"disputeront","lemma":"disputer","pos":"VER"} ,
		{"word":"disputes","word_nosc":"disputes","lemma":"disputer","pos":"VER"} ,
		{"word":"disputez","word_nosc":"disputez","lemma":"disputer","pos":"VER"} ,
		{"word":"disputiez","word_nosc":"disputiez","lemma":"disputer","pos":"VER"} ,
		{"word":"disputions","word_nosc":"disputions","lemma":"disputer","pos":"VER"} ,
		{"word":"disputons","word_nosc":"disputons","lemma":"disputer","pos":"VER"} ,
		{"word":"disputâmes","word_nosc":"disputames","lemma":"disputer","pos":"VER"} ,
		{"word":"disputât","word_nosc":"disputat","lemma":"disputer","pos":"VER"} ,
		{"word":"disputèrent","word_nosc":"disputerent","lemma":"disputer","pos":"VER"} ,
		{"word":"disputé","word_nosc":"dispute","lemma":"disputer","pos":"VER"} ,
		{"word":"disputée","word_nosc":"disputee","lemma":"disputer","pos":"VER"} ,
		{"word":"disputées","word_nosc":"disputees","lemma":"disputer","pos":"VER"} ,
		{"word":"disputés","word_nosc":"disputes","lemma":"disputer","pos":"VER"} ,
		{"word":"disqualifiait","word_nosc":"disqualifiait","lemma":"disqualifier","pos":"VER"} ,
		{"word":"disqualifiant","word_nosc":"disqualifiant","lemma":"disqualifier","pos":"VER"} ,
		{"word":"disqualifie","word_nosc":"disqualifie","lemma":"disqualifier","pos":"VER"} ,
		{"word":"disqualifient","word_nosc":"disqualifient","lemma":"disqualifier","pos":"VER"} ,
		{"word":"disqualifier","word_nosc":"disqualifier","lemma":"disqualifier","pos":"VER"} ,
		{"word":"disqualifié","word_nosc":"disqualifie","lemma":"disqualifier","pos":"VER"} ,
		{"word":"disqualifiée","word_nosc":"disqualifiee","lemma":"disqualifier","pos":"VER"} ,
		{"word":"disqualifiées","word_nosc":"disqualifiees","lemma":"disqualifier","pos":"VER"} ,
		{"word":"disqualifiés","word_nosc":"disqualifies","lemma":"disqualifier","pos":"VER"} ,
		{"word":"dissent","word_nosc":"dissent","lemma":"dire","pos":"VER"} ,
		{"word":"dissertait","word_nosc":"dissertait","lemma":"disserter","pos":"VER"} ,
		{"word":"dissertant","word_nosc":"dissertant","lemma":"disserter","pos":"VER"} ,
		{"word":"disserte","word_nosc":"disserte","lemma":"disserter","pos":"VER"} ,
		{"word":"dissertent","word_nosc":"dissertent","lemma":"disserter","pos":"VER"} ,
		{"word":"disserter","word_nosc":"disserter","lemma":"disserter","pos":"VER"} ,
		{"word":"disserterait","word_nosc":"disserterait","lemma":"disserter","pos":"VER"} ,
		{"word":"dissertes","word_nosc":"dissertes","lemma":"disserter","pos":"VER"} ,
		{"word":"disserté","word_nosc":"disserte","lemma":"disserter","pos":"VER"} ,
		{"word":"dissimiler","word_nosc":"dissimiler","lemma":"dissimiler","pos":"VER"} ,
		{"word":"dissimula","word_nosc":"dissimula","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulai","word_nosc":"dissimulai","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulaient","word_nosc":"dissimulaient","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulais","word_nosc":"dissimulais","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulait","word_nosc":"dissimulait","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulant","word_nosc":"dissimulant","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimule","word_nosc":"dissimule","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulent","word_nosc":"dissimulent","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimuler","word_nosc":"dissimuler","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulera","word_nosc":"dissimulera","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimuleraient","word_nosc":"dissimuleraient","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulerais","word_nosc":"dissimulerais","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulerait","word_nosc":"dissimulerait","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulerons","word_nosc":"dissimulerons","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimules","word_nosc":"dissimules","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulez","word_nosc":"dissimulez","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimuliez","word_nosc":"dissimuliez","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulions","word_nosc":"dissimulions","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulons","word_nosc":"dissimulons","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulât","word_nosc":"dissimulat","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulèrent","word_nosc":"dissimulerent","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulé","word_nosc":"dissimule","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulée","word_nosc":"dissimulee","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulées","word_nosc":"dissimulees","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissimulés","word_nosc":"dissimules","lemma":"dissimuler","pos":"VER"} ,
		{"word":"dissipa","word_nosc":"dissipa","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissipaient","word_nosc":"dissipaient","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissipait","word_nosc":"dissipait","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissipant","word_nosc":"dissipant","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissipe","word_nosc":"dissipe","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissipent","word_nosc":"dissipent","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissiper","word_nosc":"dissiper","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissipera","word_nosc":"dissipera","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissiperais","word_nosc":"dissiperais","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissiperait","word_nosc":"dissiperait","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissiperont","word_nosc":"dissiperont","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissipez","word_nosc":"dissipez","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissipèrent","word_nosc":"dissiperent","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissipé","word_nosc":"dissipe","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissipée","word_nosc":"dissipee","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissipées","word_nosc":"dissipees","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissipés","word_nosc":"dissipes","lemma":"dissiper","pos":"VER"} ,
		{"word":"dissocia","word_nosc":"dissocia","lemma":"dissocier","pos":"VER"} ,
		{"word":"dissociaient","word_nosc":"dissociaient","lemma":"dissocier","pos":"VER"} ,
		{"word":"dissociait","word_nosc":"dissociait","lemma":"dissocier","pos":"VER"} ,
		{"word":"dissociant","word_nosc":"dissociant","lemma":"dissocier","pos":"VER"} ,
		{"word":"dissocie","word_nosc":"dissocie","lemma":"dissocier","pos":"VER"} ,
		{"word":"dissocient","word_nosc":"dissocient","lemma":"dissocier","pos":"VER"} ,
		{"word":"dissocier","word_nosc":"dissocier","lemma":"dissocier","pos":"VER"} ,
		{"word":"dissocierons","word_nosc":"dissocierons","lemma":"dissocier","pos":"VER"} ,
		{"word":"dissocié","word_nosc":"dissocie","lemma":"dissocier","pos":"VER"} ,
		{"word":"dissociée","word_nosc":"dissociee","lemma":"dissocier","pos":"VER"} ,
		{"word":"dissociées","word_nosc":"dissociees","lemma":"dissocier","pos":"VER"} ,
		{"word":"dissociés","word_nosc":"dissocies","lemma":"dissocier","pos":"VER"} ,
		{"word":"dissolvaient","word_nosc":"dissolvaient","lemma":"dissoudre","pos":"VER"} ,
		{"word":"dissolvait","word_nosc":"dissolvait","lemma":"dissoudre","pos":"VER"} ,
		{"word":"dissolvant","word_nosc":"dissolvant","lemma":"dissoudre","pos":"VER"} ,
		{"word":"dissolve","word_nosc":"dissolve","lemma":"dissoudre","pos":"VER"} ,
		{"word":"dissolvent","word_nosc":"dissolvent","lemma":"dissoudre","pos":"VER"} ,
		{"word":"dissolves","word_nosc":"dissolves","lemma":"dissoudre","pos":"VER"} ,
		{"word":"dissonant","word_nosc":"dissonant","lemma":"dissoner","pos":"VER"} ,
		{"word":"dissoudra","word_nosc":"dissoudra","lemma":"dissoudre","pos":"VER"} ,
		{"word":"dissoudrai","word_nosc":"dissoudrai","lemma":"dissoudre","pos":"VER"} ,
		{"word":"dissoudrait","word_nosc":"dissoudrait","lemma":"dissoudre","pos":"VER"} ,
		{"word":"dissoudre","word_nosc":"dissoudre","lemma":"dissoudre","pos":"VER"} ,
		{"word":"dissoudront","word_nosc":"dissoudront","lemma":"dissoudre","pos":"VER"} ,
		{"word":"dissous","word_nosc":"dissous","lemma":"dissoudre","pos":"VER"} ,
		{"word":"dissout","word_nosc":"dissout","lemma":"dissoudre","pos":"VER"} ,
		{"word":"dissuada","word_nosc":"dissuada","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuadai","word_nosc":"dissuadai","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuadait","word_nosc":"dissuadait","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuadant","word_nosc":"dissuadant","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuade","word_nosc":"dissuade","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuadent","word_nosc":"dissuadent","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuader","word_nosc":"dissuader","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuadera","word_nosc":"dissuadera","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuaderas","word_nosc":"dissuaderas","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuaderez","word_nosc":"dissuaderez","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuaderont","word_nosc":"dissuaderont","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuadez","word_nosc":"dissuadez","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuadiez","word_nosc":"dissuadiez","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuadèrent","word_nosc":"dissuaderent","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuadé","word_nosc":"dissuade","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuadée","word_nosc":"dissuadee","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissuadés","word_nosc":"dissuades","lemma":"dissuader","pos":"VER"} ,
		{"word":"dissèque","word_nosc":"disseque","lemma":"disséquer","pos":"VER"} ,
		{"word":"dissèquent","word_nosc":"dissequent","lemma":"disséquer","pos":"VER"} ,
		{"word":"dissémina","word_nosc":"dissemina","lemma":"disséminer","pos":"VER"} ,
		{"word":"disséminait","word_nosc":"disseminait","lemma":"disséminer","pos":"VER"} ,
		{"word":"disséminant","word_nosc":"disseminant","lemma":"disséminer","pos":"VER"} ,
		{"word":"disséminent","word_nosc":"disseminent","lemma":"disséminer","pos":"VER"} ,
		{"word":"disséminer","word_nosc":"disseminer","lemma":"disséminer","pos":"VER"} ,
		{"word":"disséminèrent","word_nosc":"disseminerent","lemma":"disséminer","pos":"VER"} ,
		{"word":"disséminé","word_nosc":"dissemine","lemma":"disséminer","pos":"VER"} ,
		{"word":"disséminée","word_nosc":"disseminee","lemma":"disséminer","pos":"VER"} ,
		{"word":"disséminées","word_nosc":"disseminees","lemma":"disséminer","pos":"VER"} ,
		{"word":"disséminés","word_nosc":"dissemines","lemma":"disséminer","pos":"VER"} ,
		{"word":"disséquai","word_nosc":"dissequai","lemma":"disséquer","pos":"VER"} ,
		{"word":"disséquait","word_nosc":"dissequait","lemma":"disséquer","pos":"VER"} ,
		{"word":"disséquant","word_nosc":"dissequant","lemma":"disséquer","pos":"VER"} ,
		{"word":"disséquer","word_nosc":"dissequer","lemma":"disséquer","pos":"VER"} ,
		{"word":"disséqueraient","word_nosc":"dissequeraient","lemma":"disséquer","pos":"VER"} ,
		{"word":"disséquerais","word_nosc":"dissequerais","lemma":"disséquer","pos":"VER"} ,
		{"word":"disséquez","word_nosc":"dissequez","lemma":"disséquer","pos":"VER"} ,
		{"word":"disséquiez","word_nosc":"dissequiez","lemma":"disséquer","pos":"VER"} ,
		{"word":"disséquions","word_nosc":"dissequions","lemma":"disséquer","pos":"VER"} ,
		{"word":"disséquons","word_nosc":"dissequons","lemma":"disséquer","pos":"VER"} ,
		{"word":"disséqué","word_nosc":"disseque","lemma":"disséquer","pos":"VER"} ,
		{"word":"disséquée","word_nosc":"dissequee","lemma":"disséquer","pos":"VER"} ,
		{"word":"disséqués","word_nosc":"disseques","lemma":"disséquer","pos":"VER"} ,
		{"word":"distance","word_nosc":"distance","lemma":"distancer","pos":"VER"} ,
		{"word":"distancent","word_nosc":"distancent","lemma":"distancer","pos":"VER"} ,
		{"word":"distancer","word_nosc":"distancer","lemma":"distancer","pos":"VER"} ,
		{"word":"distancerez","word_nosc":"distancerez","lemma":"distancer","pos":"VER"} ,
		{"word":"distances","word_nosc":"distances","lemma":"distancer","pos":"VER"} ,
		{"word":"distancier","word_nosc":"distancier","lemma":"distancier","pos":"VER"} ,
		{"word":"distancié","word_nosc":"distancie","lemma":"distancier","pos":"VER"} ,
		{"word":"distancé","word_nosc":"distance","lemma":"distancer","pos":"VER"} ,
		{"word":"distancée","word_nosc":"distancee","lemma":"distancer","pos":"VER"} ,
		{"word":"distancés","word_nosc":"distances","lemma":"distancer","pos":"VER"} ,
		{"word":"distança","word_nosc":"distanca","lemma":"distancer","pos":"VER"} ,
		{"word":"distançait","word_nosc":"distancait","lemma":"distancer","pos":"VER"} ,
		{"word":"distançant","word_nosc":"distancant","lemma":"distancer","pos":"VER"} ,
		{"word":"distançons","word_nosc":"distancons","lemma":"distancer","pos":"VER"} ,
		{"word":"distend","word_nosc":"distend","lemma":"distendre","pos":"VER"} ,
		{"word":"distendaient","word_nosc":"distendaient","lemma":"distendre","pos":"VER"} ,
		{"word":"distendait","word_nosc":"distendait","lemma":"distendre","pos":"VER"} ,
		{"word":"distendant","word_nosc":"distendant","lemma":"distendre","pos":"VER"} ,
		{"word":"distende","word_nosc":"distende","lemma":"distendre","pos":"VER"} ,
		{"word":"distendent","word_nosc":"distendent","lemma":"distendre","pos":"VER"} ,
		{"word":"distendit","word_nosc":"distendit","lemma":"distendre","pos":"VER"} ,
		{"word":"distendrait","word_nosc":"distendrait","lemma":"distendre","pos":"VER"} ,
		{"word":"distendre","word_nosc":"distendre","lemma":"distendre","pos":"VER"} ,
		{"word":"distendu","word_nosc":"distendu","lemma":"distendre","pos":"VER"} ,
		{"word":"distendue","word_nosc":"distendue","lemma":"distendre","pos":"VER"} ,
		{"word":"distendues","word_nosc":"distendues","lemma":"distendre","pos":"VER"} ,
		{"word":"distendus","word_nosc":"distendus","lemma":"distendre","pos":"VER"} ,
		{"word":"distillaient","word_nosc":"distillaient","lemma":"distiller","pos":"VER"} ,
		{"word":"distillait","word_nosc":"distillait","lemma":"distiller","pos":"VER"} ,
		{"word":"distillant","word_nosc":"distillant","lemma":"distiller","pos":"VER"} ,
		{"word":"distille","word_nosc":"distille","lemma":"distiller","pos":"VER"} ,
		{"word":"distillent","word_nosc":"distillent","lemma":"distiller","pos":"VER"} ,
		{"word":"distiller","word_nosc":"distiller","lemma":"distiller","pos":"VER"} ,
		{"word":"distillera","word_nosc":"distillera","lemma":"distiller","pos":"VER"} ,
		{"word":"distilleront","word_nosc":"distilleront","lemma":"distiller","pos":"VER"} ,
		{"word":"distillez","word_nosc":"distillez","lemma":"distiller","pos":"VER"} ,
		{"word":"distillé","word_nosc":"distille","lemma":"distiller","pos":"VER"} ,
		{"word":"distillée","word_nosc":"distillee","lemma":"distiller","pos":"VER"} ,
		{"word":"distillées","word_nosc":"distillees","lemma":"distiller","pos":"VER"} ,
		{"word":"distillés","word_nosc":"distilles","lemma":"distiller","pos":"VER"} ,
		{"word":"distingua","word_nosc":"distingua","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguai","word_nosc":"distinguai","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguaient","word_nosc":"distinguaient","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguais","word_nosc":"distinguais","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguait","word_nosc":"distinguait","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguant","word_nosc":"distinguant","lemma":"distinguer","pos":"VER"} ,
		{"word":"distingue","word_nosc":"distingue","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguent","word_nosc":"distinguent","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguer","word_nosc":"distinguer","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguera","word_nosc":"distinguera","lemma":"distinguer","pos":"VER"} ,
		{"word":"distingueraient","word_nosc":"distingueraient","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguerait","word_nosc":"distinguerait","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguerez","word_nosc":"distinguerez","lemma":"distinguer","pos":"VER"} ,
		{"word":"distingues","word_nosc":"distingues","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguez","word_nosc":"distinguez","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguions","word_nosc":"distinguions","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguons","word_nosc":"distinguons","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguât","word_nosc":"distinguat","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguèrent","word_nosc":"distinguerent","lemma":"distinguer","pos":"VER"} ,
		{"word":"distingué","word_nosc":"distingue","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguée","word_nosc":"distinguee","lemma":"distinguer","pos":"VER"} ,
		{"word":"distinguées","word_nosc":"distinguees","lemma":"distinguer","pos":"VER"} ,
		{"word":"distingués","word_nosc":"distingues","lemma":"distinguer","pos":"VER"} ,
		{"word":"distord","word_nosc":"distord","lemma":"distordre","pos":"VER"} ,
		{"word":"distordant","word_nosc":"distordant","lemma":"distordre","pos":"VER"} ,
		{"word":"distordre","word_nosc":"distordre","lemma":"distordre","pos":"VER"} ,
		{"word":"distordu","word_nosc":"distordu","lemma":"distordre","pos":"VER"} ,
		{"word":"distordue","word_nosc":"distordue","lemma":"distordre","pos":"VER"} ,
		{"word":"distordues","word_nosc":"distordues","lemma":"distordre","pos":"VER"} ,
		{"word":"distraie","word_nosc":"distraie","lemma":"distraire","pos":"VER"} ,
		{"word":"distraient","word_nosc":"distraient","lemma":"distraire","pos":"VER"} ,
		{"word":"distraies","word_nosc":"distraies","lemma":"distraire","pos":"VER"} ,
		{"word":"distraira","word_nosc":"distraira","lemma":"distraire","pos":"VER"} ,
		{"word":"distrairaient","word_nosc":"distrairaient","lemma":"distraire","pos":"VER"} ,
		{"word":"distrairait","word_nosc":"distrairait","lemma":"distraire","pos":"VER"} ,
		{"word":"distraire","word_nosc":"distraire","lemma":"distraire","pos":"VER"} ,
		{"word":"distrairont","word_nosc":"distrairont","lemma":"distraire","pos":"VER"} ,
		{"word":"distrais","word_nosc":"distrais","lemma":"distraire","pos":"VER"} ,
		{"word":"distrait","word_nosc":"distrait","lemma":"distraire","pos":"VER"} ,
		{"word":"distraite","word_nosc":"distraite","lemma":"distraire","pos":"VER"} ,
		{"word":"distraites","word_nosc":"distraites","lemma":"distraire","pos":"VER"} ,
		{"word":"distraits","word_nosc":"distraits","lemma":"distraire","pos":"VER"} ,
		{"word":"distrayaient","word_nosc":"distrayaient","lemma":"distraire","pos":"VER"} ,
		{"word":"distrayais","word_nosc":"distrayais","lemma":"distraire","pos":"VER"} ,
		{"word":"distrayait","word_nosc":"distrayait","lemma":"distraire","pos":"VER"} ,
		{"word":"distrayant","word_nosc":"distrayant","lemma":"distraire","pos":"VER"} ,
		{"word":"distrayez","word_nosc":"distrayez","lemma":"distraire","pos":"VER"} ,
		{"word":"distrayons","word_nosc":"distrayons","lemma":"distraire","pos":"VER"} ,
		{"word":"distribua","word_nosc":"distribua","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuai","word_nosc":"distribuai","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuaient","word_nosc":"distribuaient","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuais","word_nosc":"distribuais","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuait","word_nosc":"distribuait","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuant","word_nosc":"distribuant","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribue","word_nosc":"distribue","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuent","word_nosc":"distribuent","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuer","word_nosc":"distribuer","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuera","word_nosc":"distribuera","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuerai","word_nosc":"distribuerai","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuerais","word_nosc":"distribuerais","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuerait","word_nosc":"distribuerait","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuerez","word_nosc":"distribuerez","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuerions","word_nosc":"distribuerions","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuerons","word_nosc":"distribuerons","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribueront","word_nosc":"distribueront","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribues","word_nosc":"distribues","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuez","word_nosc":"distribuez","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuiez","word_nosc":"distribuiez","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuions","word_nosc":"distribuions","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuons","word_nosc":"distribuons","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuât","word_nosc":"distribuat","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuèrent","word_nosc":"distribuerent","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribué","word_nosc":"distribue","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuée","word_nosc":"distribuee","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribuées","word_nosc":"distribuees","lemma":"distribuer","pos":"VER"} ,
		{"word":"distribués","word_nosc":"distribues","lemma":"distribuer","pos":"VER"} ,
		{"word":"dit","word_nosc":"dit","lemma":"dire","pos":"VER"} ,
		{"word":"dite","word_nosc":"dite","lemma":"dire","pos":"VER"} ,
		{"word":"dites","word_nosc":"dites","lemma":"dire","pos":"VER"} ,
		{"word":"dits","word_nosc":"dits","lemma":"dire","pos":"VER"} ,
		{"word":"divaguaient","word_nosc":"divaguaient","lemma":"divaguer","pos":"VER"} ,
		{"word":"divaguait","word_nosc":"divaguait","lemma":"divaguer","pos":"VER"} ,
		{"word":"divaguant","word_nosc":"divaguant","lemma":"divaguer","pos":"VER"} ,
		{"word":"divague","word_nosc":"divague","lemma":"divaguer","pos":"VER"} ,
		{"word":"divaguent","word_nosc":"divaguent","lemma":"divaguer","pos":"VER"} ,
		{"word":"divaguer","word_nosc":"divaguer","lemma":"divaguer","pos":"VER"} ,
		{"word":"divagues","word_nosc":"divagues","lemma":"divaguer","pos":"VER"} ,
		{"word":"divaguez","word_nosc":"divaguez","lemma":"divaguer","pos":"VER"} ,
		{"word":"divagué","word_nosc":"divague","lemma":"divaguer","pos":"VER"} ,
		{"word":"diverge","word_nosc":"diverge","lemma":"diverger","pos":"VER"} ,
		{"word":"divergeaient","word_nosc":"divergeaient","lemma":"diverger","pos":"VER"} ,
		{"word":"divergeait","word_nosc":"divergeait","lemma":"diverger","pos":"VER"} ,
		{"word":"divergeant","word_nosc":"divergeant","lemma":"diverger","pos":"VER"} ,
		{"word":"divergent","word_nosc":"divergent","lemma":"diverger","pos":"VER"} ,
		{"word":"divergeons","word_nosc":"divergeons","lemma":"diverger","pos":"VER"} ,
		{"word":"diverger","word_nosc":"diverger","lemma":"diverger","pos":"VER"} ,
		{"word":"divergez","word_nosc":"divergez","lemma":"diverger","pos":"VER"} ,
		{"word":"divergions","word_nosc":"divergions","lemma":"diverger","pos":"VER"} ,
		{"word":"divergé","word_nosc":"diverge","lemma":"diverger","pos":"VER"} ,
		{"word":"diversifiant","word_nosc":"diversifiant","lemma":"diversifier","pos":"VER"} ,
		{"word":"diversifie","word_nosc":"diversifie","lemma":"diversifier","pos":"VER"} ,
		{"word":"diversifient","word_nosc":"diversifient","lemma":"diversifier","pos":"VER"} ,
		{"word":"diversifier","word_nosc":"diversifier","lemma":"diversifier","pos":"VER"} ,
		{"word":"diversifié","word_nosc":"diversifie","lemma":"diversifier","pos":"VER"} ,
		{"word":"diversifiée","word_nosc":"diversifiee","lemma":"diversifier","pos":"VER"} ,
		{"word":"diversifiées","word_nosc":"diversifiees","lemma":"diversifier","pos":"VER"} ,
		{"word":"diversifiés","word_nosc":"diversifies","lemma":"diversifier","pos":"VER"} ,
		{"word":"diverti","word_nosc":"diverti","lemma":"divertir","pos":"VER"} ,
		{"word":"divertie","word_nosc":"divertie","lemma":"divertir","pos":"VER"} ,
		{"word":"divertir","word_nosc":"divertir","lemma":"divertir","pos":"VER"} ,
		{"word":"divertira","word_nosc":"divertira","lemma":"divertir","pos":"VER"} ,
		{"word":"divertirait","word_nosc":"divertirait","lemma":"divertir","pos":"VER"} ,
		{"word":"divertirent","word_nosc":"divertirent","lemma":"divertir","pos":"VER"} ,
		{"word":"divertirons","word_nosc":"divertirons","lemma":"divertir","pos":"VER"} ,
		{"word":"divertiront","word_nosc":"divertiront","lemma":"divertir","pos":"VER"} ,
		{"word":"divertis","word_nosc":"divertis","lemma":"divertir","pos":"VER"} ,
		{"word":"divertissaient","word_nosc":"divertissaient","lemma":"divertir","pos":"VER"} ,
		{"word":"divertissais","word_nosc":"divertissais","lemma":"divertir","pos":"VER"} ,
		{"word":"divertissait","word_nosc":"divertissait","lemma":"divertir","pos":"VER"} ,
		{"word":"divertissant","word_nosc":"divertissant","lemma":"divertir","pos":"VER"} ,
		{"word":"divertissent","word_nosc":"divertissent","lemma":"divertir","pos":"VER"} ,
		{"word":"divertissez","word_nosc":"divertissez","lemma":"divertir","pos":"VER"} ,
		{"word":"divertissons","word_nosc":"divertissons","lemma":"divertir","pos":"VER"} ,
		{"word":"divertit","word_nosc":"divertit","lemma":"divertir","pos":"VER"} ,
		{"word":"divinisaient","word_nosc":"divinisaient","lemma":"diviniser","pos":"VER"} ,
		{"word":"divinise","word_nosc":"divinise","lemma":"diviniser","pos":"VER"} ,
		{"word":"divinisez","word_nosc":"divinisez","lemma":"diviniser","pos":"VER"} ,
		{"word":"divinisé","word_nosc":"divinise","lemma":"diviniser","pos":"VER"} ,
		{"word":"divinisée","word_nosc":"divinisee","lemma":"diviniser","pos":"VER"} ,
		{"word":"divisa","word_nosc":"divisa","lemma":"diviser","pos":"VER"} ,
		{"word":"divisaient","word_nosc":"divisaient","lemma":"diviser","pos":"VER"} ,
		{"word":"divisais","word_nosc":"divisais","lemma":"diviser","pos":"VER"} ,
		{"word":"divisait","word_nosc":"divisait","lemma":"diviser","pos":"VER"} ,
		{"word":"divisant","word_nosc":"divisant","lemma":"diviser","pos":"VER"} ,
		{"word":"divise","word_nosc":"divise","lemma":"diviser","pos":"VER"} ,
		{"word":"divisent","word_nosc":"divisent","lemma":"diviser","pos":"VER"} ,
		{"word":"diviser","word_nosc":"diviser","lemma":"diviser","pos":"VER"} ,
		{"word":"divisera","word_nosc":"divisera","lemma":"diviser","pos":"VER"} ,
		{"word":"diviserais","word_nosc":"diviserais","lemma":"diviser","pos":"VER"} ,
		{"word":"diviserait","word_nosc":"diviserait","lemma":"diviser","pos":"VER"} ,
		{"word":"divises","word_nosc":"divises","lemma":"diviser","pos":"VER"} ,
		{"word":"divisez","word_nosc":"divisez","lemma":"diviser","pos":"VER"} ,
		{"word":"divisions","word_nosc":"divisions","lemma":"diviser","pos":"VER"} ,
		{"word":"divisons","word_nosc":"divisons","lemma":"diviser","pos":"VER"} ,
		{"word":"divisèrent","word_nosc":"diviserent","lemma":"diviser","pos":"VER"} ,
		{"word":"divisé","word_nosc":"divise","lemma":"diviser","pos":"VER"} ,
		{"word":"divisée","word_nosc":"divisee","lemma":"diviser","pos":"VER"} ,
		{"word":"divisées","word_nosc":"divisees","lemma":"diviser","pos":"VER"} ,
		{"word":"divisés","word_nosc":"divises","lemma":"diviser","pos":"VER"} ,
		{"word":"divorce","word_nosc":"divorce","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorcent","word_nosc":"divorcent","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorcer","word_nosc":"divorcer","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorcera","word_nosc":"divorcera","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorcerai","word_nosc":"divorcerai","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorcerais","word_nosc":"divorcerais","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorcerait","word_nosc":"divorcerait","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorceras","word_nosc":"divorceras","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorcerez","word_nosc":"divorcerez","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorceriez","word_nosc":"divorceriez","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorcerons","word_nosc":"divorcerons","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorceront","word_nosc":"divorceront","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorces","word_nosc":"divorces","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorcez","word_nosc":"divorcez","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorciez","word_nosc":"divorciez","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorcions","word_nosc":"divorcions","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorcèrent","word_nosc":"divorcerent","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorcé","word_nosc":"divorce","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorcée","word_nosc":"divorcee","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorcées","word_nosc":"divorcees","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorcés","word_nosc":"divorces","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorça","word_nosc":"divorca","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorçaient","word_nosc":"divorcaient","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorçait","word_nosc":"divorcait","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorçant","word_nosc":"divorcant","lemma":"divorcer","pos":"VER"} ,
		{"word":"divorçons","word_nosc":"divorcons","lemma":"divorcer","pos":"VER"} ,
		{"word":"divulguait","word_nosc":"divulguait","lemma":"divulguer","pos":"VER"} ,
		{"word":"divulguant","word_nosc":"divulguant","lemma":"divulguer","pos":"VER"} ,
		{"word":"divulgue","word_nosc":"divulgue","lemma":"divulguer","pos":"VER"} ,
		{"word":"divulguer","word_nosc":"divulguer","lemma":"divulguer","pos":"VER"} ,
		{"word":"divulguera","word_nosc":"divulguera","lemma":"divulguer","pos":"VER"} ,
		{"word":"divulguez","word_nosc":"divulguez","lemma":"divulguer","pos":"VER"} ,
		{"word":"divulguons","word_nosc":"divulguons","lemma":"divulguer","pos":"VER"} ,
		{"word":"divulgué","word_nosc":"divulgue","lemma":"divulguer","pos":"VER"} ,
		{"word":"divulguée","word_nosc":"divulguee","lemma":"divulguer","pos":"VER"} ,
		{"word":"divulguées","word_nosc":"divulguees","lemma":"divulguer","pos":"VER"} ,
		{"word":"divulgués","word_nosc":"divulgues","lemma":"divulguer","pos":"VER"} ,
		{"word":"dièse","word_nosc":"diese","lemma":"diéser","pos":"VER"} ,
		{"word":"dièses","word_nosc":"dieses","lemma":"diéser","pos":"VER"} ,
		{"word":"doctrina","word_nosc":"doctrina","lemma":"doctriner","pos":"VER"} ,
		{"word":"documenta","word_nosc":"documenta","lemma":"documenter","pos":"VER"} ,
		{"word":"documentais","word_nosc":"documentais","lemma":"documenter","pos":"VER"} ,
		{"word":"documentait","word_nosc":"documentait","lemma":"documenter","pos":"VER"} ,
		{"word":"documentant","word_nosc":"documentant","lemma":"documenter","pos":"VER"} ,
		{"word":"documente","word_nosc":"documente","lemma":"documenter","pos":"VER"} ,
		{"word":"documentent","word_nosc":"documentent","lemma":"documenter","pos":"VER"} ,
		{"word":"documenter","word_nosc":"documenter","lemma":"documenter","pos":"VER"} ,
		{"word":"documenterai","word_nosc":"documenterai","lemma":"documenter","pos":"VER"} ,
		{"word":"documentes","word_nosc":"documentes","lemma":"documenter","pos":"VER"} ,
		{"word":"documenté","word_nosc":"documente","lemma":"documenter","pos":"VER"} ,
		{"word":"documentée","word_nosc":"documentee","lemma":"documenter","pos":"VER"} ,
		{"word":"documentés","word_nosc":"documentes","lemma":"documenter","pos":"VER"} ,
		{"word":"dodelinaient","word_nosc":"dodelinaient","lemma":"dodeliner","pos":"VER"} ,
		{"word":"dodelinais","word_nosc":"dodelinais","lemma":"dodeliner","pos":"VER"} ,
		{"word":"dodelinait","word_nosc":"dodelinait","lemma":"dodeliner","pos":"VER"} ,
		{"word":"dodelinant","word_nosc":"dodelinant","lemma":"dodeliner","pos":"VER"} ,
		{"word":"dodeline","word_nosc":"dodeline","lemma":"dodeliner","pos":"VER"} ,
		{"word":"dodelinent","word_nosc":"dodelinent","lemma":"dodeliner","pos":"VER"} ,
		{"word":"dodeliner","word_nosc":"dodeliner","lemma":"dodeliner","pos":"VER"} ,
		{"word":"dodelinèrent","word_nosc":"dodelinerent","lemma":"dodeliner","pos":"VER"} ,
		{"word":"dodeliné","word_nosc":"dodeline","lemma":"dodeliner","pos":"VER"} ,
		{"word":"dodine","word_nosc":"dodine","lemma":"dodiner","pos":"VER"} ,
		{"word":"doigta","word_nosc":"doigta","lemma":"doigter","pos":"VER"} ,
		{"word":"doigtait","word_nosc":"doigtait","lemma":"doigter","pos":"VER"} ,
		{"word":"doigter","word_nosc":"doigter","lemma":"doigter","pos":"VER"} ,
		{"word":"doigté","word_nosc":"doigte","lemma":"doigter","pos":"VER"} ,
		{"word":"dois","word_nosc":"dois","lemma":"devoir","pos":"VER"} ,
		{"word":"doit","word_nosc":"doit","lemma":"devoir","pos":"VER"} ,
		{"word":"doive","word_nosc":"doive","lemma":"devoir","pos":"VER"} ,
		{"word":"doivent","word_nosc":"doivent","lemma":"devoir","pos":"VER"} ,
		{"word":"doives","word_nosc":"doives","lemma":"devoir","pos":"VER"} ,
		{"word":"domestiqua","word_nosc":"domestiqua","lemma":"domestiquer","pos":"VER"} ,
		{"word":"domestiquait","word_nosc":"domestiquait","lemma":"domestiquer","pos":"VER"} ,
		{"word":"domestique","word_nosc":"domestique","lemma":"domestiquer","pos":"VER"} ,
		{"word":"domestiquer","word_nosc":"domestiquer","lemma":"domestiquer","pos":"VER"} ,
		{"word":"domestiquez","word_nosc":"domestiquez","lemma":"domestiquer","pos":"VER"} ,
		{"word":"domestiqué","word_nosc":"domestique","lemma":"domestiquer","pos":"VER"} ,
		{"word":"domestiquée","word_nosc":"domestiquee","lemma":"domestiquer","pos":"VER"} ,
		{"word":"domestiquées","word_nosc":"domestiquees","lemma":"domestiquer","pos":"VER"} ,
		{"word":"domestiqués","word_nosc":"domestiques","lemma":"domestiquer","pos":"VER"} ,
		{"word":"domicilier","word_nosc":"domicilier","lemma":"domicilier","pos":"VER"} ,
		{"word":"domicilié","word_nosc":"domicilie","lemma":"domicilier","pos":"VER"} ,
		{"word":"domiciliée","word_nosc":"domiciliee","lemma":"domicilier","pos":"VER"} ,
		{"word":"domiciliés","word_nosc":"domicilies","lemma":"domicilier","pos":"VER"} ,
		{"word":"domina","word_nosc":"domina","lemma":"dominer","pos":"VER"} ,
		{"word":"dominaient","word_nosc":"dominaient","lemma":"dominer","pos":"VER"} ,
		{"word":"dominais","word_nosc":"dominais","lemma":"dominer","pos":"VER"} ,
		{"word":"dominait","word_nosc":"dominait","lemma":"dominer","pos":"VER"} ,
		{"word":"dominant","word_nosc":"dominant","lemma":"dominer","pos":"VER"} ,
		{"word":"domine","word_nosc":"domine","lemma":"dominer","pos":"VER"} ,
		{"word":"dominent","word_nosc":"dominent","lemma":"dominer","pos":"VER"} ,
		{"word":"dominer","word_nosc":"dominer","lemma":"dominer","pos":"VER"} ,
		{"word":"dominera","word_nosc":"dominera","lemma":"dominer","pos":"VER"} ,
		{"word":"dominerai","word_nosc":"dominerai","lemma":"dominer","pos":"VER"} ,
		{"word":"domineraient","word_nosc":"domineraient","lemma":"dominer","pos":"VER"} ,
		{"word":"dominerais","word_nosc":"dominerais","lemma":"dominer","pos":"VER"} ,
		{"word":"dominerait","word_nosc":"dominerait","lemma":"dominer","pos":"VER"} ,
		{"word":"domineras","word_nosc":"domineras","lemma":"dominer","pos":"VER"} ,
		{"word":"domineriez","word_nosc":"domineriez","lemma":"dominer","pos":"VER"} ,
		{"word":"dominerions","word_nosc":"dominerions","lemma":"dominer","pos":"VER"} ,
		{"word":"dominerons","word_nosc":"dominerons","lemma":"dominer","pos":"VER"} ,
		{"word":"domineront","word_nosc":"domineront","lemma":"dominer","pos":"VER"} ,
		{"word":"domines","word_nosc":"domines","lemma":"dominer","pos":"VER"} ,
		{"word":"dominez","word_nosc":"dominez","lemma":"dominer","pos":"VER"} ,
		{"word":"dominiez","word_nosc":"dominiez","lemma":"dominer","pos":"VER"} ,
		{"word":"dominions","word_nosc":"dominions","lemma":"dominer","pos":"VER"} ,
		{"word":"dominons","word_nosc":"dominons","lemma":"dominer","pos":"VER"} ,
		{"word":"dominât","word_nosc":"dominat","lemma":"dominer","pos":"VER"} ,
		{"word":"dominèrent","word_nosc":"dominerent","lemma":"dominer","pos":"VER"} ,
		{"word":"dominé","word_nosc":"domine","lemma":"dominer","pos":"VER"} ,
		{"word":"dominée","word_nosc":"dominee","lemma":"dominer","pos":"VER"} ,
		{"word":"dominées","word_nosc":"dominees","lemma":"dominer","pos":"VER"} ,
		{"word":"dominés","word_nosc":"domines","lemma":"dominer","pos":"VER"} ,
		{"word":"dompta","word_nosc":"dompta","lemma":"dompter","pos":"VER"} ,
		{"word":"domptai","word_nosc":"domptai","lemma":"dompter","pos":"VER"} ,
		{"word":"domptait","word_nosc":"domptait","lemma":"dompter","pos":"VER"} ,
		{"word":"dompte","word_nosc":"dompte","lemma":"dompter","pos":"VER"} ,
		{"word":"domptent","word_nosc":"domptent","lemma":"dompter","pos":"VER"} ,
		{"word":"dompter","word_nosc":"dompter","lemma":"dompter","pos":"VER"} ,
		{"word":"dompterai","word_nosc":"dompterai","lemma":"dompter","pos":"VER"} ,
		{"word":"dompterait","word_nosc":"dompterait","lemma":"dompter","pos":"VER"} ,
		{"word":"domptez","word_nosc":"domptez","lemma":"dompter","pos":"VER"} ,
		{"word":"dompté","word_nosc":"dompte","lemma":"dompter","pos":"VER"} ,
		{"word":"domptée","word_nosc":"domptee","lemma":"dompter","pos":"VER"} ,
		{"word":"domptés","word_nosc":"domptes","lemma":"dompter","pos":"VER"} ,
		{"word":"donna","word_nosc":"donna","lemma":"donner","pos":"VER"} ,
		{"word":"donnai","word_nosc":"donnai","lemma":"donner","pos":"VER"} ,
		{"word":"donnaient","word_nosc":"donnaient","lemma":"donner","pos":"VER"} ,
		{"word":"donnais","word_nosc":"donnais","lemma":"donner","pos":"VER"} ,
		{"word":"donnait","word_nosc":"donnait","lemma":"donner","pos":"VER"} ,
		{"word":"donnant","word_nosc":"donnant","lemma":"donner","pos":"VER"} ,
		{"word":"donnas","word_nosc":"donnas","lemma":"donner","pos":"VER"} ,
		{"word":"donnassent","word_nosc":"donnassent","lemma":"donner","pos":"VER"} ,
		{"word":"donne","word_nosc":"donne","lemma":"donner","pos":"VER"} ,
		{"word":"donnent","word_nosc":"donnent","lemma":"donner","pos":"VER"} ,
		{"word":"donner","word_nosc":"donner","lemma":"donner","pos":"VER"} ,
		{"word":"donnera","word_nosc":"donnera","lemma":"donner","pos":"VER"} ,
		{"word":"donnerai","word_nosc":"donnerai","lemma":"donner","pos":"VER"} ,
		{"word":"donneraient","word_nosc":"donneraient","lemma":"donner","pos":"VER"} ,
		{"word":"donnerais","word_nosc":"donnerais","lemma":"donner","pos":"VER"} ,
		{"word":"donnerait","word_nosc":"donnerait","lemma":"donner","pos":"VER"} ,
		{"word":"donneras","word_nosc":"donneras","lemma":"donner","pos":"VER"} ,
		{"word":"donnerez","word_nosc":"donnerez","lemma":"donner","pos":"VER"} ,
		{"word":"donneriez","word_nosc":"donneriez","lemma":"donner","pos":"VER"} ,
		{"word":"donnerions","word_nosc":"donnerions","lemma":"donner","pos":"VER"} ,
		{"word":"donnerons","word_nosc":"donnerons","lemma":"donner","pos":"VER"} ,
		{"word":"donneront","word_nosc":"donneront","lemma":"donner","pos":"VER"} ,
		{"word":"donnes","word_nosc":"donnes","lemma":"donner","pos":"VER"} ,
		{"word":"donnez","word_nosc":"donnez","lemma":"donner","pos":"VER"} ,
		{"word":"donniez","word_nosc":"donniez","lemma":"donner","pos":"VER"} ,
		{"word":"donnions","word_nosc":"donnions","lemma":"donner","pos":"VER"} ,
		{"word":"donnons","word_nosc":"donnons","lemma":"donner","pos":"VER"} ,
		{"word":"donnâmes","word_nosc":"donnames","lemma":"donner","pos":"VER"} ,
		{"word":"donnât","word_nosc":"donnat","lemma":"donner","pos":"VER"} ,
		{"word":"donnèrent","word_nosc":"donnerent","lemma":"donner","pos":"VER"} ,
		{"word":"donné","word_nosc":"donne","lemma":"donner","pos":"VER"} ,
		{"word":"donnée","word_nosc":"donnee","lemma":"donner","pos":"VER"} ,
		{"word":"données","word_nosc":"donnees","lemma":"donner","pos":"VER"} ,
		{"word":"donnés","word_nosc":"donnes","lemma":"donner","pos":"VER"} ,
		{"word":"dopais","word_nosc":"dopais","lemma":"doper","pos":"VER"} ,
		{"word":"dopait","word_nosc":"dopait","lemma":"doper","pos":"VER"} ,
		{"word":"dopant","word_nosc":"dopant","lemma":"doper","pos":"VER"} ,
		{"word":"dope","word_nosc":"dope","lemma":"doper","pos":"VER"} ,
		{"word":"doper","word_nosc":"doper","lemma":"doper","pos":"VER"} ,
		{"word":"dopes","word_nosc":"dopes","lemma":"doper","pos":"VER"} ,
		{"word":"dopez","word_nosc":"dopez","lemma":"doper","pos":"VER"} ,
		{"word":"dopé","word_nosc":"dope","lemma":"doper","pos":"VER"} ,
		{"word":"dopée","word_nosc":"dopee","lemma":"doper","pos":"VER"} ,
		{"word":"dopés","word_nosc":"dopes","lemma":"doper","pos":"VER"} ,
		{"word":"dora","word_nosc":"dora","lemma":"dorer","pos":"VER"} ,
		{"word":"doraient","word_nosc":"doraient","lemma":"dorer","pos":"VER"} ,
		{"word":"dorais","word_nosc":"dorais","lemma":"dorer","pos":"VER"} ,
		{"word":"dorait","word_nosc":"dorait","lemma":"dorer","pos":"VER"} ,
		{"word":"dorant","word_nosc":"dorant","lemma":"dorer","pos":"VER"} ,
		{"word":"dore","word_nosc":"dore","lemma":"dorer","pos":"VER"} ,
		{"word":"dorent","word_nosc":"dorent","lemma":"dorer","pos":"VER"} ,
		{"word":"dorer","word_nosc":"dorer","lemma":"dorer","pos":"VER"} ,
		{"word":"dorera","word_nosc":"dorera","lemma":"dorer","pos":"VER"} ,
		{"word":"doreront","word_nosc":"doreront","lemma":"dorer","pos":"VER"} ,
		{"word":"dorlotait","word_nosc":"dorlotait","lemma":"dorloter","pos":"VER"} ,
		{"word":"dorlotant","word_nosc":"dorlotant","lemma":"dorloter","pos":"VER"} ,
		{"word":"dorlote","word_nosc":"dorlote","lemma":"dorloter","pos":"VER"} ,
		{"word":"dorlotent","word_nosc":"dorlotent","lemma":"dorloter","pos":"VER"} ,
		{"word":"dorloter","word_nosc":"dorloter","lemma":"dorloter","pos":"VER"} ,
		{"word":"dorloterai","word_nosc":"dorloterai","lemma":"dorloter","pos":"VER"} ,
		{"word":"dorloterons","word_nosc":"dorloterons","lemma":"dorloter","pos":"VER"} ,
		{"word":"dorlotez","word_nosc":"dorlotez","lemma":"dorloter","pos":"VER"} ,
		{"word":"dorloté","word_nosc":"dorlote","lemma":"dorloter","pos":"VER"} ,
		{"word":"dorlotée","word_nosc":"dorlotee","lemma":"dorloter","pos":"VER"} ,
		{"word":"dorlotées","word_nosc":"dorlotees","lemma":"dorloter","pos":"VER"} ,
		{"word":"dorlotés","word_nosc":"dorlotes","lemma":"dorloter","pos":"VER"} ,
		{"word":"dormaient","word_nosc":"dormaient","lemma":"dormir","pos":"VER"} ,
		{"word":"dormais","word_nosc":"dormais","lemma":"dormir","pos":"VER"} ,
		{"word":"dormait","word_nosc":"dormait","lemma":"dormir","pos":"VER"} ,
		{"word":"dormant","word_nosc":"dormant","lemma":"dormir","pos":"VER"} ,
		{"word":"dorme","word_nosc":"dorme","lemma":"dormir","pos":"VER"} ,
		{"word":"dorment","word_nosc":"dorment","lemma":"dormir","pos":"VER"} ,
		{"word":"dormes","word_nosc":"dormes","lemma":"dormir","pos":"VER"} ,
		{"word":"dormez","word_nosc":"dormez","lemma":"dormir","pos":"VER"} ,
		{"word":"dormi","word_nosc":"dormi","lemma":"dormir","pos":"VER"} ,
		{"word":"dormiez","word_nosc":"dormiez","lemma":"dormir","pos":"VER"} ,
		{"word":"dormions","word_nosc":"dormions","lemma":"dormir","pos":"VER"} ,
		{"word":"dormir","word_nosc":"dormir","lemma":"dormir","pos":"VER"} ,
		{"word":"dormira","word_nosc":"dormira","lemma":"dormir","pos":"VER"} ,
		{"word":"dormirai","word_nosc":"dormirai","lemma":"dormir","pos":"VER"} ,
		{"word":"dormiraient","word_nosc":"dormiraient","lemma":"dormir","pos":"VER"} ,
		{"word":"dormirais","word_nosc":"dormirais","lemma":"dormir","pos":"VER"} ,
		{"word":"dormirait","word_nosc":"dormirait","lemma":"dormir","pos":"VER"} ,
		{"word":"dormiras","word_nosc":"dormiras","lemma":"dormir","pos":"VER"} ,
		{"word":"dormirent","word_nosc":"dormirent","lemma":"dormir","pos":"VER"} ,
		{"word":"dormirez","word_nosc":"dormirez","lemma":"dormir","pos":"VER"} ,
		{"word":"dormiriez","word_nosc":"dormiriez","lemma":"dormir","pos":"VER"} ,
		{"word":"dormirions","word_nosc":"dormirions","lemma":"dormir","pos":"VER"} ,
		{"word":"dormirons","word_nosc":"dormirons","lemma":"dormir","pos":"VER"} ,
		{"word":"dormiront","word_nosc":"dormiront","lemma":"dormir","pos":"VER"} ,
		{"word":"dormis","word_nosc":"dormis","lemma":"dormir","pos":"VER"} ,
		{"word":"dormit","word_nosc":"dormit","lemma":"dormir","pos":"VER"} ,
		{"word":"dormons","word_nosc":"dormons","lemma":"dormir","pos":"VER"} ,
		{"word":"dormîmes","word_nosc":"dormimes","lemma":"dormir","pos":"VER"} ,
		{"word":"dormît","word_nosc":"dormit","lemma":"dormir","pos":"VER"} ,
		{"word":"dors","word_nosc":"dors","lemma":"dormir","pos":"VER"} ,
		{"word":"dort","word_nosc":"dort","lemma":"dormir","pos":"VER"} ,
		{"word":"doré","word_nosc":"dore","lemma":"dorer","pos":"VER"} ,
		{"word":"dorée","word_nosc":"doree","lemma":"dorer","pos":"VER"} ,
		{"word":"dorées","word_nosc":"dorees","lemma":"dorer","pos":"VER"} ,
		{"word":"dorés","word_nosc":"dores","lemma":"dorer","pos":"VER"} ,
		{"word":"dosaient","word_nosc":"dosaient","lemma":"doser","pos":"VER"} ,
		{"word":"dosais","word_nosc":"dosais","lemma":"doser","pos":"VER"} ,
		{"word":"dosait","word_nosc":"dosait","lemma":"doser","pos":"VER"} ,
		{"word":"dosant","word_nosc":"dosant","lemma":"doser","pos":"VER"} ,
		{"word":"dose","word_nosc":"dose","lemma":"doser","pos":"VER"} ,
		{"word":"dosent","word_nosc":"dosent","lemma":"doser","pos":"VER"} ,
		{"word":"doser","word_nosc":"doser","lemma":"doser","pos":"VER"} ,
		{"word":"doses","word_nosc":"doses","lemma":"doser","pos":"VER"} ,
		{"word":"dosé","word_nosc":"dose","lemma":"doser","pos":"VER"} ,
		{"word":"dosée","word_nosc":"dosee","lemma":"doser","pos":"VER"} ,
		{"word":"dosées","word_nosc":"dosees","lemma":"doser","pos":"VER"} ,
		{"word":"dosés","word_nosc":"doses","lemma":"doser","pos":"VER"} ,
		{"word":"dota","word_nosc":"dota","lemma":"doter","pos":"VER"} ,
		{"word":"dotaient","word_nosc":"dotaient","lemma":"doter","pos":"VER"} ,
		{"word":"dotait","word_nosc":"dotait","lemma":"doter","pos":"VER"} ,
		{"word":"dotant","word_nosc":"dotant","lemma":"doter","pos":"VER"} ,
		{"word":"dote","word_nosc":"dote","lemma":"doter","pos":"VER"} ,
		{"word":"doter","word_nosc":"doter","lemma":"doter","pos":"VER"} ,
		{"word":"doté","word_nosc":"dote","lemma":"doter","pos":"VER"} ,
		{"word":"dotée","word_nosc":"dotee","lemma":"doter","pos":"VER"} ,
		{"word":"dotées","word_nosc":"dotees","lemma":"doter","pos":"VER"} ,
		{"word":"dotés","word_nosc":"dotes","lemma":"doter","pos":"VER"} ,
		{"word":"douait","word_nosc":"douait","lemma":"douer","pos":"VER"} ,
		{"word":"doubla","word_nosc":"doubla","lemma":"doubler","pos":"VER"} ,
		{"word":"doublai","word_nosc":"doublai","lemma":"doubler","pos":"VER"} ,
		{"word":"doublaient","word_nosc":"doublaient","lemma":"doubler","pos":"VER"} ,
		{"word":"doublais","word_nosc":"doublais","lemma":"doubler","pos":"VER"} ,
		{"word":"doublait","word_nosc":"doublait","lemma":"doubler","pos":"VER"} ,
		{"word":"doublant","word_nosc":"doublant","lemma":"doubler","pos":"VER"} ,
		{"word":"double","word_nosc":"double","lemma":"doubler","pos":"VER"} ,
		{"word":"double-cliquer","word_nosc":"double-cliquer","lemma":"double-cliquer","pos":"VER"} ,
		{"word":"double-cliqué","word_nosc":"double-clique","lemma":"double-cliquer","pos":"VER"} ,
		{"word":"doublent","word_nosc":"doublent","lemma":"doubler","pos":"VER"} ,
		{"word":"doubler","word_nosc":"doubler","lemma":"doubler","pos":"VER"} ,
		{"word":"doublera","word_nosc":"doublera","lemma":"doubler","pos":"VER"} ,
		{"word":"doublerai","word_nosc":"doublerai","lemma":"doubler","pos":"VER"} ,
		{"word":"doubleraient","word_nosc":"doubleraient","lemma":"doubler","pos":"VER"} ,
		{"word":"doublerait","word_nosc":"doublerait","lemma":"doubler","pos":"VER"} ,
		{"word":"doublerez","word_nosc":"doublerez","lemma":"doubler","pos":"VER"} ,
		{"word":"doublerons","word_nosc":"doublerons","lemma":"doubler","pos":"VER"} ,
		{"word":"doubles","word_nosc":"doubles","lemma":"doubler","pos":"VER"} ,
		{"word":"doublez","word_nosc":"doublez","lemma":"doubler","pos":"VER"} ,
		{"word":"doubliez","word_nosc":"doubliez","lemma":"doubler","pos":"VER"} ,
		{"word":"doublions","word_nosc":"doublions","lemma":"doubler","pos":"VER"} ,
		{"word":"doublonner","word_nosc":"doublonner","lemma":"doublonner","pos":"VER"} ,
		{"word":"doublons","word_nosc":"doublons","lemma":"doubler","pos":"VER"} ,
		{"word":"doublât","word_nosc":"doublat","lemma":"doubler","pos":"VER"} ,
		{"word":"doublèrent","word_nosc":"doublerent","lemma":"doubler","pos":"VER"} ,
		{"word":"doublé","word_nosc":"double","lemma":"doubler","pos":"VER"} ,
		{"word":"doublée","word_nosc":"doublee","lemma":"doubler","pos":"VER"} ,
		{"word":"doublées","word_nosc":"doublees","lemma":"doubler","pos":"VER"} ,
		{"word":"doublés","word_nosc":"doubles","lemma":"doubler","pos":"VER"} ,
		{"word":"doucha","word_nosc":"doucha","lemma":"doucher","pos":"VER"} ,
		{"word":"douchais","word_nosc":"douchais","lemma":"doucher","pos":"VER"} ,
		{"word":"douchait","word_nosc":"douchait","lemma":"doucher","pos":"VER"} ,
		{"word":"douchant","word_nosc":"douchant","lemma":"doucher","pos":"VER"} ,
		{"word":"douche","word_nosc":"douche","lemma":"doucher","pos":"VER"} ,
		{"word":"douchent","word_nosc":"douchent","lemma":"doucher","pos":"VER"} ,
		{"word":"doucher","word_nosc":"doucher","lemma":"doucher","pos":"VER"} ,
		{"word":"douchera","word_nosc":"douchera","lemma":"doucher","pos":"VER"} ,
		{"word":"doucherai","word_nosc":"doucherai","lemma":"doucher","pos":"VER"} ,
		{"word":"doucheras","word_nosc":"doucheras","lemma":"doucher","pos":"VER"} ,
		{"word":"douches","word_nosc":"douches","lemma":"doucher","pos":"VER"} ,
		{"word":"douchez","word_nosc":"douchez","lemma":"doucher","pos":"VER"} ,
		{"word":"douchiez","word_nosc":"douchiez","lemma":"doucher","pos":"VER"} ,
		{"word":"douchons","word_nosc":"douchons","lemma":"doucher","pos":"VER"} ,
		{"word":"douché","word_nosc":"douche","lemma":"doucher","pos":"VER"} ,
		{"word":"douchée","word_nosc":"douchee","lemma":"doucher","pos":"VER"} ,
		{"word":"douchés","word_nosc":"douches","lemma":"doucher","pos":"VER"} ,
		{"word":"doucie","word_nosc":"doucie","lemma":"doucir","pos":"VER"} ,
		{"word":"douer","word_nosc":"douer","lemma":"douer","pos":"VER"} ,
		{"word":"douillait","word_nosc":"douillait","lemma":"douiller","pos":"VER"} ,
		{"word":"douille","word_nosc":"douille","lemma":"douiller","pos":"VER"} ,
		{"word":"douillent","word_nosc":"douillent","lemma":"douiller","pos":"VER"} ,
		{"word":"douiller","word_nosc":"douiller","lemma":"douiller","pos":"VER"} ,
		{"word":"douillera","word_nosc":"douillera","lemma":"douiller","pos":"VER"} ,
		{"word":"douilles","word_nosc":"douilles","lemma":"douiller","pos":"VER"} ,
		{"word":"douillé","word_nosc":"douille","lemma":"douiller","pos":"VER"} ,
		{"word":"douillée","word_nosc":"douillee","lemma":"douiller","pos":"VER"} ,
		{"word":"douta","word_nosc":"douta","lemma":"douter","pos":"VER"} ,
		{"word":"doutai","word_nosc":"doutai","lemma":"douter","pos":"VER"} ,
		{"word":"doutaient","word_nosc":"doutaient","lemma":"douter","pos":"VER"} ,
		{"word":"doutais","word_nosc":"doutais","lemma":"douter","pos":"VER"} ,
		{"word":"doutait","word_nosc":"doutait","lemma":"douter","pos":"VER"} ,
		{"word":"doutant","word_nosc":"doutant","lemma":"douter","pos":"VER"} ,
		{"word":"doute","word_nosc":"doute","lemma":"douter","pos":"VER"} ,
		{"word":"doutent","word_nosc":"doutent","lemma":"douter","pos":"VER"} ,
		{"word":"douter","word_nosc":"douter","lemma":"douter","pos":"VER"} ,
		{"word":"doutera","word_nosc":"doutera","lemma":"douter","pos":"VER"} ,
		{"word":"douterai","word_nosc":"douterai","lemma":"douter","pos":"VER"} ,
		{"word":"douterais","word_nosc":"douterais","lemma":"douter","pos":"VER"} ,
		{"word":"douterait","word_nosc":"douterait","lemma":"douter","pos":"VER"} ,
		{"word":"douteras","word_nosc":"douteras","lemma":"douter","pos":"VER"} ,
		{"word":"douterez","word_nosc":"douterez","lemma":"douter","pos":"VER"} ,
		{"word":"douteriez","word_nosc":"douteriez","lemma":"douter","pos":"VER"} ,
		{"word":"douteront","word_nosc":"douteront","lemma":"douter","pos":"VER"} ,
		{"word":"doutes","word_nosc":"doutes","lemma":"douter","pos":"VER"} ,
		{"word":"doutez","word_nosc":"doutez","lemma":"douter","pos":"VER"} ,
		{"word":"doutiez","word_nosc":"doutiez","lemma":"douter","pos":"VER"} ,
		{"word":"doutions","word_nosc":"doutions","lemma":"douter","pos":"VER"} ,
		{"word":"doutons","word_nosc":"doutons","lemma":"douter","pos":"VER"} ,
		{"word":"doutât","word_nosc":"doutat","lemma":"douter","pos":"VER"} ,
		{"word":"doutèrent","word_nosc":"douterent","lemma":"douter","pos":"VER"} ,
		{"word":"douté","word_nosc":"doute","lemma":"douter","pos":"VER"} ,
		{"word":"doutée","word_nosc":"doutee","lemma":"douter","pos":"VER"} ,
		{"word":"doutés","word_nosc":"doutes","lemma":"douter","pos":"VER"} ,
		{"word":"doué","word_nosc":"doue","lemma":"douer","pos":"VER"} ,
		{"word":"douée","word_nosc":"douee","lemma":"douer","pos":"VER"} ,
		{"word":"douées","word_nosc":"douees","lemma":"douer","pos":"VER"} ,
		{"word":"doués","word_nosc":"doues","lemma":"douer","pos":"VER"} ,
		{"word":"drache","word_nosc":"drache","lemma":"dracher","pos":"VER"} ,
		{"word":"dragua","word_nosc":"dragua","lemma":"draguer","pos":"VER"} ,
		{"word":"draguaient","word_nosc":"draguaient","lemma":"draguer","pos":"VER"} ,
		{"word":"draguais","word_nosc":"draguais","lemma":"draguer","pos":"VER"} ,
		{"word":"draguait","word_nosc":"draguait","lemma":"draguer","pos":"VER"} ,
		{"word":"draguant","word_nosc":"draguant","lemma":"draguer","pos":"VER"} ,
		{"word":"drague","word_nosc":"drague","lemma":"draguer","pos":"VER"} ,
		{"word":"draguent","word_nosc":"draguent","lemma":"draguer","pos":"VER"} ,
		{"word":"draguer","word_nosc":"draguer","lemma":"draguer","pos":"VER"} ,
		{"word":"draguera","word_nosc":"draguera","lemma":"draguer","pos":"VER"} ,
		{"word":"draguerai","word_nosc":"draguerai","lemma":"draguer","pos":"VER"} ,
		{"word":"draguerais","word_nosc":"draguerais","lemma":"draguer","pos":"VER"} ,
		{"word":"draguerait","word_nosc":"draguerait","lemma":"draguer","pos":"VER"} ,
		{"word":"dragues","word_nosc":"dragues","lemma":"draguer","pos":"VER"} ,
		{"word":"draguez","word_nosc":"draguez","lemma":"draguer","pos":"VER"} ,
		{"word":"draguiez","word_nosc":"draguiez","lemma":"draguer","pos":"VER"} ,
		{"word":"draguons","word_nosc":"draguons","lemma":"draguer","pos":"VER"} ,
		{"word":"dragué","word_nosc":"drague","lemma":"draguer","pos":"VER"} ,
		{"word":"draguée","word_nosc":"draguee","lemma":"draguer","pos":"VER"} ,
		{"word":"draguées","word_nosc":"draguees","lemma":"draguer","pos":"VER"} ,
		{"word":"dragués","word_nosc":"dragues","lemma":"draguer","pos":"VER"} ,
		{"word":"draina","word_nosc":"draina","lemma":"drainer","pos":"VER"} ,
		{"word":"drainaient","word_nosc":"drainaient","lemma":"drainer","pos":"VER"} ,
		{"word":"drainait","word_nosc":"drainait","lemma":"drainer","pos":"VER"} ,
		{"word":"drainant","word_nosc":"drainant","lemma":"drainer","pos":"VER"} ,
		{"word":"draine","word_nosc":"draine","lemma":"drainer","pos":"VER"} ,
		{"word":"drainent","word_nosc":"drainent","lemma":"drainer","pos":"VER"} ,
		{"word":"drainer","word_nosc":"drainer","lemma":"drainer","pos":"VER"} ,
		{"word":"drainera","word_nosc":"drainera","lemma":"drainer","pos":"VER"} ,
		{"word":"drainons","word_nosc":"drainons","lemma":"drainer","pos":"VER"} ,
		{"word":"drainé","word_nosc":"draine","lemma":"drainer","pos":"VER"} ,
		{"word":"drainées","word_nosc":"drainees","lemma":"drainer","pos":"VER"} ,
		{"word":"drainés","word_nosc":"draines","lemma":"drainer","pos":"VER"} ,
		{"word":"dramatisait","word_nosc":"dramatisait","lemma":"dramatiser","pos":"VER"} ,
		{"word":"dramatisant","word_nosc":"dramatisant","lemma":"dramatiser","pos":"VER"} ,
		{"word":"dramatise","word_nosc":"dramatise","lemma":"dramatiser","pos":"VER"} ,
		{"word":"dramatisent","word_nosc":"dramatisent","lemma":"dramatiser","pos":"VER"} ,
		{"word":"dramatiser","word_nosc":"dramatiser","lemma":"dramatiser","pos":"VER"} ,
		{"word":"dramatises","word_nosc":"dramatises","lemma":"dramatiser","pos":"VER"} ,
		{"word":"dramatisez","word_nosc":"dramatisez","lemma":"dramatiser","pos":"VER"} ,
		{"word":"dramatisons","word_nosc":"dramatisons","lemma":"dramatiser","pos":"VER"} ,
		{"word":"dramatisé","word_nosc":"dramatise","lemma":"dramatiser","pos":"VER"} ,
		{"word":"dramatisée","word_nosc":"dramatisee","lemma":"dramatiser","pos":"VER"} ,
		{"word":"dramatisés","word_nosc":"dramatises","lemma":"dramatiser","pos":"VER"} ,
		{"word":"drapa","word_nosc":"drapa","lemma":"draper","pos":"VER"} ,
		{"word":"drapai","word_nosc":"drapai","lemma":"draper","pos":"VER"} ,
		{"word":"drapaient","word_nosc":"drapaient","lemma":"draper","pos":"VER"} ,
		{"word":"drapait","word_nosc":"drapait","lemma":"draper","pos":"VER"} ,
		{"word":"drapant","word_nosc":"drapant","lemma":"draper","pos":"VER"} ,
		{"word":"drape","word_nosc":"drape","lemma":"draper","pos":"VER"} ,
		{"word":"drapent","word_nosc":"drapent","lemma":"draper","pos":"VER"} ,
		{"word":"draper","word_nosc":"draper","lemma":"draper","pos":"VER"} ,
		{"word":"draperaient","word_nosc":"draperaient","lemma":"draper","pos":"VER"} ,
		{"word":"draperez","word_nosc":"draperez","lemma":"draper","pos":"VER"} ,
		{"word":"drapèrent","word_nosc":"draperent","lemma":"draper","pos":"VER"} ,
		{"word":"drapé","word_nosc":"drape","lemma":"draper","pos":"VER"} ,
		{"word":"drapée","word_nosc":"drapee","lemma":"draper","pos":"VER"} ,
		{"word":"drapées","word_nosc":"drapees","lemma":"draper","pos":"VER"} ,
		{"word":"drapés","word_nosc":"drapes","lemma":"draper","pos":"VER"} ,
		{"word":"dressa","word_nosc":"dressa","lemma":"dresser","pos":"VER"} ,
		{"word":"dressai","word_nosc":"dressai","lemma":"dresser","pos":"VER"} ,
		{"word":"dressaient","word_nosc":"dressaient","lemma":"dresser","pos":"VER"} ,
		{"word":"dressais","word_nosc":"dressais","lemma":"dresser","pos":"VER"} ,
		{"word":"dressait","word_nosc":"dressait","lemma":"dresser","pos":"VER"} ,
		{"word":"dressant","word_nosc":"dressant","lemma":"dresser","pos":"VER"} ,
		{"word":"dresse","word_nosc":"dresse","lemma":"dresser","pos":"VER"} ,
		{"word":"dressent","word_nosc":"dressent","lemma":"dresser","pos":"VER"} ,
		{"word":"dresser","word_nosc":"dresser","lemma":"dresser","pos":"VER"} ,
		{"word":"dressera","word_nosc":"dressera","lemma":"dresser","pos":"VER"} ,
		{"word":"dresserai","word_nosc":"dresserai","lemma":"dresser","pos":"VER"} ,
		{"word":"dresseraient","word_nosc":"dresseraient","lemma":"dresser","pos":"VER"} ,
		{"word":"dresserait","word_nosc":"dresserait","lemma":"dresser","pos":"VER"} ,
		{"word":"dresseras","word_nosc":"dresseras","lemma":"dresser","pos":"VER"} ,
		{"word":"dresserons","word_nosc":"dresserons","lemma":"dresser","pos":"VER"} ,
		{"word":"dresseront","word_nosc":"dresseront","lemma":"dresser","pos":"VER"} ,
		{"word":"dresses","word_nosc":"dresses","lemma":"dresser","pos":"VER"} ,
		{"word":"dressez","word_nosc":"dressez","lemma":"dresser","pos":"VER"} ,
		{"word":"dressions","word_nosc":"dressions","lemma":"dresser","pos":"VER"} ,
		{"word":"dressons","word_nosc":"dressons","lemma":"dresser","pos":"VER"} ,
		{"word":"dressât","word_nosc":"dressat","lemma":"dresser","pos":"VER"} ,
		{"word":"dressèrent","word_nosc":"dresserent","lemma":"dresser","pos":"VER"} ,
		{"word":"dressé","word_nosc":"dresse","lemma":"dresser","pos":"VER"} ,
		{"word":"dressée","word_nosc":"dressee","lemma":"dresser","pos":"VER"} ,
		{"word":"dressées","word_nosc":"dressees","lemma":"dresser","pos":"VER"} ,
		{"word":"dressés","word_nosc":"dresses","lemma":"dresser","pos":"VER"} ,
		{"word":"dribbla","word_nosc":"dribbla","lemma":"dribbler","pos":"VER"} ,
		{"word":"dribblais","word_nosc":"dribblais","lemma":"dribbler","pos":"VER"} ,
		{"word":"dribblait","word_nosc":"dribblait","lemma":"dribbler","pos":"VER"} ,
		{"word":"dribble","word_nosc":"dribble","lemma":"dribbler","pos":"VER"} ,
		{"word":"dribbler","word_nosc":"dribbler","lemma":"dribbler","pos":"VER"} ,
		{"word":"dribbles","word_nosc":"dribbles","lemma":"dribbler","pos":"VER"} ,
		{"word":"dribblez","word_nosc":"dribblez","lemma":"dribbler","pos":"VER"} ,
		{"word":"dribblé","word_nosc":"dribble","lemma":"dribbler","pos":"VER"} ,
		{"word":"drible","word_nosc":"drible","lemma":"dribler","pos":"VER"} ,
		{"word":"dribler","word_nosc":"dribler","lemma":"dribler","pos":"VER"} ,
		{"word":"drivait","word_nosc":"drivait","lemma":"driver","pos":"VER"} ,
		{"word":"drivant","word_nosc":"drivant","lemma":"driver","pos":"VER"} ,
		{"word":"driver","word_nosc":"driver","lemma":"driver","pos":"VER"} ,
		{"word":"drivé","word_nosc":"drive","lemma":"driver","pos":"VER"} ,
		{"word":"drivée","word_nosc":"drivee","lemma":"driver","pos":"VER"} ,
		{"word":"droguaient","word_nosc":"droguaient","lemma":"droguer","pos":"VER"} ,
		{"word":"droguais","word_nosc":"droguais","lemma":"droguer","pos":"VER"} ,
		{"word":"droguait","word_nosc":"droguait","lemma":"droguer","pos":"VER"} ,
		{"word":"droguant","word_nosc":"droguant","lemma":"droguer","pos":"VER"} ,
		{"word":"drogue","word_nosc":"drogue","lemma":"droguer","pos":"VER"} ,
		{"word":"droguent","word_nosc":"droguent","lemma":"droguer","pos":"VER"} ,
		{"word":"droguer","word_nosc":"droguer","lemma":"droguer","pos":"VER"} ,
		{"word":"drogues","word_nosc":"drogues","lemma":"droguer","pos":"VER"} ,
		{"word":"droguez","word_nosc":"droguez","lemma":"droguer","pos":"VER"} ,
		{"word":"droguiez","word_nosc":"droguiez","lemma":"droguer","pos":"VER"} ,
		{"word":"drogué","word_nosc":"drogue","lemma":"droguer","pos":"VER"} ,
		{"word":"droguée","word_nosc":"droguee","lemma":"droguer","pos":"VER"} ,
		{"word":"droguées","word_nosc":"droguees","lemma":"droguer","pos":"VER"} ,
		{"word":"drogués","word_nosc":"drogues","lemma":"droguer","pos":"VER"} ,
		{"word":"drope","word_nosc":"drope","lemma":"droper","pos":"VER"} ,
		{"word":"dropent","word_nosc":"dropent","lemma":"droper","pos":"VER"} ,
		{"word":"droppant","word_nosc":"droppant","lemma":"dropper","pos":"VER"} ,
		{"word":"droppe","word_nosc":"droppe","lemma":"dropper","pos":"VER"} ,
		{"word":"dropé","word_nosc":"drope","lemma":"droper","pos":"VER"} ,
		{"word":"drossait","word_nosc":"drossait","lemma":"drosser","pos":"VER"} ,
		{"word":"drossant","word_nosc":"drossant","lemma":"drosser","pos":"VER"} ,
		{"word":"drosser","word_nosc":"drosser","lemma":"drosser","pos":"VER"} ,
		{"word":"drossé","word_nosc":"drosse","lemma":"drosser","pos":"VER"} ,
		{"word":"drossée","word_nosc":"drossee","lemma":"drosser","pos":"VER"} ,
		{"word":"drossés","word_nosc":"drosses","lemma":"drosser","pos":"VER"} ,
		{"word":"due","word_nosc":"due","lemma":"devoir","pos":"VER"} ,
		{"word":"dues","word_nosc":"dues","lemma":"devoir","pos":"VER"} ,
		{"word":"dulcifiant","word_nosc":"dulcifiant","lemma":"dulcifier","pos":"VER"} ,
		{"word":"dupait","word_nosc":"dupait","lemma":"duper","pos":"VER"} ,
		{"word":"dupant","word_nosc":"dupant","lemma":"duper","pos":"VER"} ,
		{"word":"dupe","word_nosc":"dupe","lemma":"duper","pos":"VER"} ,
		{"word":"dupent","word_nosc":"dupent","lemma":"duper","pos":"VER"} ,
		{"word":"duper","word_nosc":"duper","lemma":"duper","pos":"VER"} ,
		{"word":"dupera","word_nosc":"dupera","lemma":"duper","pos":"VER"} ,
		{"word":"duperas","word_nosc":"duperas","lemma":"duper","pos":"VER"} ,
		{"word":"dupes","word_nosc":"dupes","lemma":"duper","pos":"VER"} ,
		{"word":"dupez","word_nosc":"dupez","lemma":"duper","pos":"VER"} ,
		{"word":"dupliquer","word_nosc":"dupliquer","lemma":"dupliquer","pos":"VER"} ,
		{"word":"dupliqué","word_nosc":"duplique","lemma":"dupliquer","pos":"VER"} ,
		{"word":"dupliqués","word_nosc":"dupliques","lemma":"dupliquer","pos":"VER"} ,
		{"word":"dupons","word_nosc":"dupons","lemma":"duper","pos":"VER"} ,
		{"word":"dupé","word_nosc":"dupe","lemma":"duper","pos":"VER"} ,
		{"word":"dupée","word_nosc":"dupee","lemma":"duper","pos":"VER"} ,
		{"word":"dupés","word_nosc":"dupes","lemma":"duper","pos":"VER"} ,
		{"word":"dura","word_nosc":"dura","lemma":"durer","pos":"VER"} ,
		{"word":"duraient","word_nosc":"duraient","lemma":"durer","pos":"VER"} ,
		{"word":"durait","word_nosc":"durait","lemma":"durer","pos":"VER"} ,
		{"word":"durant","word_nosc":"durant","lemma":"durer","pos":"VER"} ,
		{"word":"durassent","word_nosc":"durassent","lemma":"durer","pos":"VER"} ,
		{"word":"durci","word_nosc":"durci","lemma":"durcir","pos":"VER"} ,
		{"word":"durcie","word_nosc":"durcie","lemma":"durcir","pos":"VER"} ,
		{"word":"durcies","word_nosc":"durcies","lemma":"durcir","pos":"VER"} ,
		{"word":"durcir","word_nosc":"durcir","lemma":"durcir","pos":"VER"} ,
		{"word":"durciraient","word_nosc":"durciraient","lemma":"durcir","pos":"VER"} ,
		{"word":"durcirent","word_nosc":"durcirent","lemma":"durcir","pos":"VER"} ,
		{"word":"durcis","word_nosc":"durcis","lemma":"durcir","pos":"VER"} ,
		{"word":"durcissaient","word_nosc":"durcissaient","lemma":"durcir","pos":"VER"} ,
		{"word":"durcissais","word_nosc":"durcissais","lemma":"durcir","pos":"VER"} ,
		{"word":"durcissait","word_nosc":"durcissait","lemma":"durcir","pos":"VER"} ,
		{"word":"durcissant","word_nosc":"durcissant","lemma":"durcir","pos":"VER"} ,
		{"word":"durcisse","word_nosc":"durcisse","lemma":"durcir","pos":"VER"} ,
		{"word":"durcissent","word_nosc":"durcissent","lemma":"durcir","pos":"VER"} ,
		{"word":"durcit","word_nosc":"durcit","lemma":"durcir","pos":"VER"} ,
		{"word":"dure","word_nosc":"dure","lemma":"durer","pos":"VER"} ,
		{"word":"durent","word_nosc":"durent","lemma":"devoir","pos":"VER"} ,
		{"word":"durent","word_nosc":"durent","lemma":"durer","pos":"VER"} ,
		{"word":"durer","word_nosc":"durer","lemma":"durer","pos":"VER"} ,
		{"word":"durera","word_nosc":"durera","lemma":"durer","pos":"VER"} ,
		{"word":"durerai","word_nosc":"durerai","lemma":"durer","pos":"VER"} ,
		{"word":"dureraient","word_nosc":"dureraient","lemma":"durer","pos":"VER"} ,
		{"word":"durerais","word_nosc":"durerais","lemma":"durer","pos":"VER"} ,
		{"word":"durerait","word_nosc":"durerait","lemma":"durer","pos":"VER"} ,
		{"word":"dureras","word_nosc":"dureras","lemma":"durer","pos":"VER"} ,
		{"word":"durerez","word_nosc":"durerez","lemma":"durer","pos":"VER"} ,
		{"word":"dureront","word_nosc":"dureront","lemma":"durer","pos":"VER"} ,
		{"word":"dures","word_nosc":"dures","lemma":"durer","pos":"VER"} ,
		{"word":"durât","word_nosc":"durat","lemma":"durer","pos":"VER"} ,
		{"word":"durèrent","word_nosc":"durerent","lemma":"durer","pos":"VER"} ,
		{"word":"duré","word_nosc":"dure","lemma":"durer","pos":"VER"} ,
		{"word":"durée","word_nosc":"duree","lemma":"durer","pos":"VER"} ,
		{"word":"durées","word_nosc":"durees","lemma":"durer","pos":"VER"} ,
		{"word":"dus","word_nosc":"dus","lemma":"devoir","pos":"VER"} ,
		{"word":"dusse","word_nosc":"dusse","lemma":"devoir","pos":"VER"} ,
		{"word":"dussent","word_nosc":"dussent","lemma":"devoir","pos":"VER"} ,
		{"word":"dusses","word_nosc":"dusses","lemma":"devoir","pos":"VER"} ,
		{"word":"dussiez","word_nosc":"dussiez","lemma":"devoir","pos":"VER"} ,
		{"word":"dussions","word_nosc":"dussions","lemma":"devoir","pos":"VER"} ,
		{"word":"dut","word_nosc":"dut","lemma":"devoir","pos":"VER"} ,
		{"word":"duveté","word_nosc":"duvete","lemma":"duveter","pos":"VER"} ,
		{"word":"duvetées","word_nosc":"duvetees","lemma":"duveter","pos":"VER"} ,
		{"word":"duvetés","word_nosc":"duvetes","lemma":"duveter","pos":"VER"} ,
		{"word":"dynamiser","word_nosc":"dynamiser","lemma":"dynamiser","pos":"VER"} ,
		{"word":"dynamisera","word_nosc":"dynamisera","lemma":"dynamiser","pos":"VER"} ,
		{"word":"dynamiserait","word_nosc":"dynamiserait","lemma":"dynamiser","pos":"VER"} ,
		{"word":"dynamisez","word_nosc":"dynamisez","lemma":"dynamiser","pos":"VER"} ,
		{"word":"dynamisé","word_nosc":"dynamise","lemma":"dynamiser","pos":"VER"} ,
		{"word":"dynamisée","word_nosc":"dynamisee","lemma":"dynamiser","pos":"VER"} ,
		{"word":"dynamisées","word_nosc":"dynamisees","lemma":"dynamiser","pos":"VER"} ,
		{"word":"dynamitait","word_nosc":"dynamitait","lemma":"dynamiter","pos":"VER"} ,
		{"word":"dynamitant","word_nosc":"dynamitant","lemma":"dynamiter","pos":"VER"} ,
		{"word":"dynamite","word_nosc":"dynamite","lemma":"dynamiter","pos":"VER"} ,
		{"word":"dynamiter","word_nosc":"dynamiter","lemma":"dynamiter","pos":"VER"} ,
		{"word":"dynamitez","word_nosc":"dynamitez","lemma":"dynamiter","pos":"VER"} ,
		{"word":"dynamitons","word_nosc":"dynamitons","lemma":"dynamiter","pos":"VER"} ,
		{"word":"dynamité","word_nosc":"dynamite","lemma":"dynamiter","pos":"VER"} ,
		{"word":"dynamitée","word_nosc":"dynamitee","lemma":"dynamiter","pos":"VER"} ,
		{"word":"déambula","word_nosc":"deambula","lemma":"déambuler","pos":"VER"} ,
		{"word":"déambulai","word_nosc":"deambulai","lemma":"déambuler","pos":"VER"} ,
		{"word":"déambulaient","word_nosc":"deambulaient","lemma":"déambuler","pos":"VER"} ,
		{"word":"déambulais","word_nosc":"deambulais","lemma":"déambuler","pos":"VER"} ,
		{"word":"déambulait","word_nosc":"deambulait","lemma":"déambuler","pos":"VER"} ,
		{"word":"déambulant","word_nosc":"deambulant","lemma":"déambuler","pos":"VER"} ,
		{"word":"déambule","word_nosc":"deambule","lemma":"déambuler","pos":"VER"} ,
		{"word":"déambulent","word_nosc":"deambulent","lemma":"déambuler","pos":"VER"} ,
		{"word":"déambuler","word_nosc":"deambuler","lemma":"déambuler","pos":"VER"} ,
		{"word":"déambulerai","word_nosc":"deambulerai","lemma":"déambuler","pos":"VER"} ,
		{"word":"déambulez","word_nosc":"deambulez","lemma":"déambuler","pos":"VER"} ,
		{"word":"déambulions","word_nosc":"deambulions","lemma":"déambuler","pos":"VER"} ,
		{"word":"déambulons","word_nosc":"deambulons","lemma":"déambuler","pos":"VER"} ,
		{"word":"déambulèrent","word_nosc":"deambulerent","lemma":"déambuler","pos":"VER"} ,
		{"word":"déambulé","word_nosc":"deambule","lemma":"déambuler","pos":"VER"} ,
		{"word":"débagoule","word_nosc":"debagoule","lemma":"débagouler","pos":"VER"} ,
		{"word":"débagouler","word_nosc":"debagouler","lemma":"débagouler","pos":"VER"} ,
		{"word":"débagoulerait","word_nosc":"debagoulerait","lemma":"débagouler","pos":"VER"} ,
		{"word":"débagoules","word_nosc":"debagoules","lemma":"débagouler","pos":"VER"} ,
		{"word":"déballa","word_nosc":"deballa","lemma":"déballer","pos":"VER"} ,
		{"word":"déballai","word_nosc":"deballai","lemma":"déballer","pos":"VER"} ,
		{"word":"déballaient","word_nosc":"deballaient","lemma":"déballer","pos":"VER"} ,
		{"word":"déballais","word_nosc":"deballais","lemma":"déballer","pos":"VER"} ,
		{"word":"déballait","word_nosc":"deballait","lemma":"déballer","pos":"VER"} ,
		{"word":"déballant","word_nosc":"deballant","lemma":"déballer","pos":"VER"} ,
		{"word":"déballe","word_nosc":"deballe","lemma":"déballer","pos":"VER"} ,
		{"word":"déballent","word_nosc":"deballent","lemma":"déballer","pos":"VER"} ,
		{"word":"déballer","word_nosc":"deballer","lemma":"déballer","pos":"VER"} ,
		{"word":"déballera","word_nosc":"deballera","lemma":"déballer","pos":"VER"} ,
		{"word":"déballez","word_nosc":"deballez","lemma":"déballer","pos":"VER"} ,
		{"word":"déballonner","word_nosc":"deballonner","lemma":"déballonner","pos":"VER"} ,
		{"word":"déballonnerais","word_nosc":"deballonnerais","lemma":"déballonner","pos":"VER"} ,
		{"word":"déballonneront","word_nosc":"deballonneront","lemma":"déballonner","pos":"VER"} ,
		{"word":"déballonné","word_nosc":"deballonne","lemma":"déballonner","pos":"VER"} ,
		{"word":"déballons","word_nosc":"deballons","lemma":"déballer","pos":"VER"} ,
		{"word":"déballèrent","word_nosc":"deballerent","lemma":"déballer","pos":"VER"} ,
		{"word":"déballé","word_nosc":"deballe","lemma":"déballer","pos":"VER"} ,
		{"word":"déballée","word_nosc":"deballee","lemma":"déballer","pos":"VER"} ,
		{"word":"déballées","word_nosc":"deballees","lemma":"déballer","pos":"VER"} ,
		{"word":"déballés","word_nosc":"deballes","lemma":"déballer","pos":"VER"} ,
		{"word":"débanda","word_nosc":"debanda","lemma":"débander","pos":"VER"} ,
		{"word":"débandaient","word_nosc":"debandaient","lemma":"débander","pos":"VER"} ,
		{"word":"débandais","word_nosc":"debandais","lemma":"débander","pos":"VER"} ,
		{"word":"débandait","word_nosc":"debandait","lemma":"débander","pos":"VER"} ,
		{"word":"débande","word_nosc":"debande","lemma":"débander","pos":"VER"} ,
		{"word":"débandent","word_nosc":"debandent","lemma":"débander","pos":"VER"} ,
		{"word":"débander","word_nosc":"debander","lemma":"débander","pos":"VER"} ,
		{"word":"débandera","word_nosc":"debandera","lemma":"débander","pos":"VER"} ,
		{"word":"débanderaient","word_nosc":"debanderaient","lemma":"débander","pos":"VER"} ,
		{"word":"débandez","word_nosc":"debandez","lemma":"débander","pos":"VER"} ,
		{"word":"débandèrent","word_nosc":"debanderent","lemma":"débander","pos":"VER"} ,
		{"word":"débandé","word_nosc":"debande","lemma":"débander","pos":"VER"} ,
		{"word":"débandées","word_nosc":"debandees","lemma":"débander","pos":"VER"} ,
		{"word":"débandés","word_nosc":"debandes","lemma":"débander","pos":"VER"} ,
		{"word":"débaptiser","word_nosc":"debaptiser","lemma":"débaptiser","pos":"VER"} ,
		{"word":"débaptiserais","word_nosc":"debaptiserais","lemma":"débaptiser","pos":"VER"} ,
		{"word":"débaptisé","word_nosc":"debaptise","lemma":"débaptiser","pos":"VER"} ,
		{"word":"débaptisée","word_nosc":"debaptisee","lemma":"débaptiser","pos":"VER"} ,
		{"word":"débarbouilla","word_nosc":"debarbouilla","lemma":"débarbouiller","pos":"VER"} ,
		{"word":"débarbouillait","word_nosc":"debarbouillait","lemma":"débarbouiller","pos":"VER"} ,
		{"word":"débarbouillant","word_nosc":"debarbouillant","lemma":"débarbouiller","pos":"VER"} ,
		{"word":"débarbouille","word_nosc":"debarbouille","lemma":"débarbouiller","pos":"VER"} ,
		{"word":"débarbouiller","word_nosc":"debarbouiller","lemma":"débarbouiller","pos":"VER"} ,
		{"word":"débarbouillé","word_nosc":"debarbouille","lemma":"débarbouiller","pos":"VER"} ,
		{"word":"débarbouillée","word_nosc":"debarbouillee","lemma":"débarbouiller","pos":"VER"} ,
		{"word":"débarbouillés","word_nosc":"debarbouilles","lemma":"débarbouiller","pos":"VER"} ,
		{"word":"débardaient","word_nosc":"debardaient","lemma":"débarder","pos":"VER"} ,
		{"word":"débarder","word_nosc":"debarder","lemma":"débarder","pos":"VER"} ,
		{"word":"débardée","word_nosc":"debardee","lemma":"débarder","pos":"VER"} ,
		{"word":"débaroule","word_nosc":"debaroule","lemma":"débarouler","pos":"VER"} ,
		{"word":"débaroulent","word_nosc":"debaroulent","lemma":"débarouler","pos":"VER"} ,
		{"word":"débarqua","word_nosc":"debarqua","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquai","word_nosc":"debarquai","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquaient","word_nosc":"debarquaient","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquais","word_nosc":"debarquais","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquait","word_nosc":"debarquait","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquant","word_nosc":"debarquant","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarque","word_nosc":"debarque","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquent","word_nosc":"debarquent","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquer","word_nosc":"debarquer","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquera","word_nosc":"debarquera","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquerai","word_nosc":"debarquerai","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarqueraient","word_nosc":"debarqueraient","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquerait","word_nosc":"debarquerait","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarqueras","word_nosc":"debarqueras","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquerez","word_nosc":"debarquerez","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquerons","word_nosc":"debarquerons","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarqueront","word_nosc":"debarqueront","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarques","word_nosc":"debarques","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquez","word_nosc":"debarquez","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquiez","word_nosc":"debarquiez","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquions","word_nosc":"debarquions","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquons","word_nosc":"debarquons","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquâmes","word_nosc":"debarquames","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquèrent","word_nosc":"debarquerent","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarqué","word_nosc":"debarque","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquée","word_nosc":"debarquee","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarquées","word_nosc":"debarquees","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarqués","word_nosc":"debarques","lemma":"débarquer","pos":"VER"} ,
		{"word":"débarra","word_nosc":"debarra","lemma":"débarrer","pos":"VER"} ,
		{"word":"débarras","word_nosc":"debarras","lemma":"débarrer","pos":"VER"} ,
		{"word":"débarrassa","word_nosc":"debarrassa","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassai","word_nosc":"debarrassai","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassaient","word_nosc":"debarrassaient","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassais","word_nosc":"debarrassais","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassait","word_nosc":"debarrassait","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassant","word_nosc":"debarrassant","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrasse","word_nosc":"debarrasse","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassent","word_nosc":"debarrassent","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrasser","word_nosc":"debarrasser","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassera","word_nosc":"debarrassera","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrasserai","word_nosc":"debarrasserai","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrasserais","word_nosc":"debarrasserais","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrasserait","word_nosc":"debarrasserait","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrasseras","word_nosc":"debarrasseras","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrasserez","word_nosc":"debarrasserez","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrasserons","word_nosc":"debarrasserons","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrasseront","word_nosc":"debarrasseront","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrasses","word_nosc":"debarrasses","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassez","word_nosc":"debarrassez","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassiez","word_nosc":"debarrassiez","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassons","word_nosc":"debarrassons","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassât","word_nosc":"debarrassat","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassèrent","word_nosc":"debarrasserent","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassé","word_nosc":"debarrasse","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassée","word_nosc":"debarrassee","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassées","word_nosc":"debarrassees","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrassés","word_nosc":"debarrasses","lemma":"débarrasser","pos":"VER"} ,
		{"word":"débarrer","word_nosc":"debarrer","lemma":"débarrer","pos":"VER"} ,
		{"word":"débarrons","word_nosc":"debarrons","lemma":"débarrer","pos":"VER"} ,
		{"word":"débat","word_nosc":"debat","lemma":"débattre","pos":"VER"} ,
		{"word":"débats","word_nosc":"debats","lemma":"débattre","pos":"VER"} ,
		{"word":"débattaient","word_nosc":"debattaient","lemma":"débattre","pos":"VER"} ,
		{"word":"débattais","word_nosc":"debattais","lemma":"débattre","pos":"VER"} ,
		{"word":"débattait","word_nosc":"debattait","lemma":"débattre","pos":"VER"} ,
		{"word":"débattant","word_nosc":"debattant","lemma":"débattre","pos":"VER"} ,
		{"word":"débatte","word_nosc":"debatte","lemma":"débattre","pos":"VER"} ,
		{"word":"débattent","word_nosc":"debattent","lemma":"débattre","pos":"VER"} ,
		{"word":"débattez","word_nosc":"debattez","lemma":"débattre","pos":"VER"} ,
		{"word":"débattiez","word_nosc":"debattiez","lemma":"débattre","pos":"VER"} ,
		{"word":"débattions","word_nosc":"debattions","lemma":"débattre","pos":"VER"} ,
		{"word":"débattirent","word_nosc":"debattirent","lemma":"débattre","pos":"VER"} ,
		{"word":"débattis","word_nosc":"debattis","lemma":"débattre","pos":"VER"} ,
		{"word":"débattit","word_nosc":"debattit","lemma":"débattre","pos":"VER"} ,
		{"word":"débattons","word_nosc":"debattons","lemma":"débattre","pos":"VER"} ,
		{"word":"débattra","word_nosc":"debattra","lemma":"débattre","pos":"VER"} ,
		{"word":"débattraient","word_nosc":"debattraient","lemma":"débattre","pos":"VER"} ,
		{"word":"débattrais","word_nosc":"debattrais","lemma":"débattre","pos":"VER"} ,
		{"word":"débattrait","word_nosc":"debattrait","lemma":"débattre","pos":"VER"} ,
		{"word":"débattre","word_nosc":"debattre","lemma":"débattre","pos":"VER"} ,
		{"word":"débattrons","word_nosc":"debattrons","lemma":"débattre","pos":"VER"} ,
		{"word":"débattu","word_nosc":"debattu","lemma":"débattre","pos":"VER"} ,
		{"word":"débattue","word_nosc":"debattue","lemma":"débattre","pos":"VER"} ,
		{"word":"débattues","word_nosc":"debattues","lemma":"débattre","pos":"VER"} ,
		{"word":"débattus","word_nosc":"debattus","lemma":"débattre","pos":"VER"} ,
		{"word":"débattît","word_nosc":"debattit","lemma":"débattre","pos":"VER"} ,
		{"word":"débauchais","word_nosc":"debauchais","lemma":"débaucher","pos":"VER"} ,
		{"word":"débauchait","word_nosc":"debauchait","lemma":"débaucher","pos":"VER"} ,
		{"word":"débauchant","word_nosc":"debauchant","lemma":"débaucher","pos":"VER"} ,
		{"word":"débauche","word_nosc":"debauche","lemma":"débaucher","pos":"VER"} ,
		{"word":"débaucher","word_nosc":"debaucher","lemma":"débaucher","pos":"VER"} ,
		{"word":"débauches","word_nosc":"debauches","lemma":"débaucher","pos":"VER"} ,
		{"word":"débauché","word_nosc":"debauche","lemma":"débaucher","pos":"VER"} ,
		{"word":"débauchée","word_nosc":"debauchee","lemma":"débaucher","pos":"VER"} ,
		{"word":"débauchés","word_nosc":"debauches","lemma":"débaucher","pos":"VER"} ,
		{"word":"débecquetait","word_nosc":"debecquetait","lemma":"débecqueter","pos":"VER"} ,
		{"word":"débectait","word_nosc":"debectait","lemma":"débecter","pos":"VER"} ,
		{"word":"débectant","word_nosc":"debectant","lemma":"débecter","pos":"VER"} ,
		{"word":"débecte","word_nosc":"debecte","lemma":"débecter","pos":"VER"} ,
		{"word":"débectent","word_nosc":"debectent","lemma":"débecter","pos":"VER"} ,
		{"word":"débecter","word_nosc":"debecter","lemma":"débecter","pos":"VER"} ,
		{"word":"débecterais","word_nosc":"debecterais","lemma":"débecter","pos":"VER"} ,
		{"word":"débectez","word_nosc":"debectez","lemma":"débecter","pos":"VER"} ,
		{"word":"débecté","word_nosc":"debecte","lemma":"débecter","pos":"VER"} ,
		{"word":"débectée","word_nosc":"debectee","lemma":"débecter","pos":"VER"} ,
		{"word":"débectés","word_nosc":"debectes","lemma":"débecter","pos":"VER"} ,
		{"word":"débiffé","word_nosc":"debiffe","lemma":"débiffer","pos":"VER"} ,
		{"word":"débilitaient","word_nosc":"debilitaient","lemma":"débiliter","pos":"VER"} ,
		{"word":"débilitant","word_nosc":"debilitant","lemma":"débiliter","pos":"VER"} ,
		{"word":"débilite","word_nosc":"debilite","lemma":"débiliter","pos":"VER"} ,
		{"word":"débilité","word_nosc":"debilite","lemma":"débiliter","pos":"VER"} ,
		{"word":"débilitée","word_nosc":"debilitee","lemma":"débiliter","pos":"VER"} ,
		{"word":"débilités","word_nosc":"debilites","lemma":"débiliter","pos":"VER"} ,
		{"word":"débiller","word_nosc":"debiller","lemma":"débiller","pos":"VER"} ,
		{"word":"débinait","word_nosc":"debinait","lemma":"débiner","pos":"VER"} ,
		{"word":"débine","word_nosc":"debine","lemma":"débiner","pos":"VER"} ,
		{"word":"débinent","word_nosc":"debinent","lemma":"débiner","pos":"VER"} ,
		{"word":"débiner","word_nosc":"debiner","lemma":"débiner","pos":"VER"} ,
		{"word":"débinera","word_nosc":"debinera","lemma":"débiner","pos":"VER"} ,
		{"word":"débinerai","word_nosc":"debinerai","lemma":"débiner","pos":"VER"} ,
		{"word":"débinerait","word_nosc":"debinerait","lemma":"débiner","pos":"VER"} ,
		{"word":"débineras","word_nosc":"debineras","lemma":"débiner","pos":"VER"} ,
		{"word":"débines","word_nosc":"debines","lemma":"débiner","pos":"VER"} ,
		{"word":"débinez","word_nosc":"debinez","lemma":"débiner","pos":"VER"} ,
		{"word":"débiné","word_nosc":"debine","lemma":"débiner","pos":"VER"} ,
		{"word":"débinées","word_nosc":"debinees","lemma":"débiner","pos":"VER"} ,
		{"word":"débinés","word_nosc":"debines","lemma":"débiner","pos":"VER"} ,
		{"word":"débita","word_nosc":"debita","lemma":"débiter","pos":"VER"} ,
		{"word":"débitai","word_nosc":"debitai","lemma":"débiter","pos":"VER"} ,
		{"word":"débitaient","word_nosc":"debitaient","lemma":"débiter","pos":"VER"} ,
		{"word":"débitais","word_nosc":"debitais","lemma":"débiter","pos":"VER"} ,
		{"word":"débitait","word_nosc":"debitait","lemma":"débiter","pos":"VER"} ,
		{"word":"débitant","word_nosc":"debitant","lemma":"débiter","pos":"VER"} ,
		{"word":"débite","word_nosc":"debite","lemma":"débiter","pos":"VER"} ,
		{"word":"débitent","word_nosc":"debitent","lemma":"débiter","pos":"VER"} ,
		{"word":"débiter","word_nosc":"debiter","lemma":"débiter","pos":"VER"} ,
		{"word":"débitera","word_nosc":"debitera","lemma":"débiter","pos":"VER"} ,
		{"word":"débiterai","word_nosc":"debiterai","lemma":"débiter","pos":"VER"} ,
		{"word":"débiterait","word_nosc":"debiterait","lemma":"débiter","pos":"VER"} ,
		{"word":"débites","word_nosc":"debites","lemma":"débiter","pos":"VER"} ,
		{"word":"débitez","word_nosc":"debitez","lemma":"débiter","pos":"VER"} ,
		{"word":"débité","word_nosc":"debite","lemma":"débiter","pos":"VER"} ,
		{"word":"débitée","word_nosc":"debitee","lemma":"débiter","pos":"VER"} ,
		{"word":"débitées","word_nosc":"debitees","lemma":"débiter","pos":"VER"} ,
		{"word":"débités","word_nosc":"debites","lemma":"débiter","pos":"VER"} ,
		{"word":"déblaie","word_nosc":"deblaie","lemma":"déblayer","pos":"VER"} ,
		{"word":"déblaient","word_nosc":"deblaient","lemma":"déblayer","pos":"VER"} ,
		{"word":"déblaierez","word_nosc":"deblaierez","lemma":"déblayer","pos":"VER"} ,
		{"word":"déblatère","word_nosc":"deblatere","lemma":"déblatérer","pos":"VER"} ,
		{"word":"déblatérait","word_nosc":"deblaterait","lemma":"déblatérer","pos":"VER"} ,
		{"word":"déblatérant","word_nosc":"deblaterant","lemma":"déblatérer","pos":"VER"} ,
		{"word":"déblatérer","word_nosc":"deblaterer","lemma":"déblatérer","pos":"VER"} ,
		{"word":"déblaya","word_nosc":"deblaya","lemma":"déblayer","pos":"VER"} ,
		{"word":"déblayaient","word_nosc":"deblayaient","lemma":"déblayer","pos":"VER"} ,
		{"word":"déblayait","word_nosc":"deblayait","lemma":"déblayer","pos":"VER"} ,
		{"word":"déblayant","word_nosc":"deblayant","lemma":"déblayer","pos":"VER"} ,
		{"word":"déblayer","word_nosc":"deblayer","lemma":"déblayer","pos":"VER"} ,
		{"word":"déblayera","word_nosc":"deblayera","lemma":"déblayer","pos":"VER"} ,
		{"word":"déblayez","word_nosc":"deblayez","lemma":"déblayer","pos":"VER"} ,
		{"word":"déblayé","word_nosc":"deblaye","lemma":"déblayer","pos":"VER"} ,
		{"word":"déblayée","word_nosc":"deblayee","lemma":"déblayer","pos":"VER"} ,
		{"word":"déblayées","word_nosc":"deblayees","lemma":"déblayer","pos":"VER"} ,
		{"word":"déblayés","word_nosc":"deblayes","lemma":"déblayer","pos":"VER"} ,
		{"word":"débloqua","word_nosc":"debloqua","lemma":"débloquer","pos":"VER"} ,
		{"word":"débloquait","word_nosc":"debloquait","lemma":"débloquer","pos":"VER"} ,
		{"word":"débloquant","word_nosc":"debloquant","lemma":"débloquer","pos":"VER"} ,
		{"word":"débloque","word_nosc":"debloque","lemma":"débloquer","pos":"VER"} ,
		{"word":"débloquent","word_nosc":"debloquent","lemma":"débloquer","pos":"VER"} ,
		{"word":"débloquer","word_nosc":"debloquer","lemma":"débloquer","pos":"VER"} ,
		{"word":"débloques","word_nosc":"debloques","lemma":"débloquer","pos":"VER"} ,
		{"word":"débloquez","word_nosc":"debloquez","lemma":"débloquer","pos":"VER"} ,
		{"word":"débloquât","word_nosc":"debloquat","lemma":"débloquer","pos":"VER"} ,
		{"word":"débloqué","word_nosc":"debloque","lemma":"débloquer","pos":"VER"} ,
		{"word":"débloquée","word_nosc":"debloquee","lemma":"débloquer","pos":"VER"} ,
		{"word":"débloqués","word_nosc":"debloques","lemma":"débloquer","pos":"VER"} ,
		{"word":"débobiner","word_nosc":"debobiner","lemma":"débobiner","pos":"VER"} ,
		{"word":"déboguer","word_nosc":"deboguer","lemma":"déboguer","pos":"VER"} ,
		{"word":"déboiser","word_nosc":"deboiser","lemma":"déboiser","pos":"VER"} ,
		{"word":"déboisé","word_nosc":"deboise","lemma":"déboiser","pos":"VER"} ,
		{"word":"déboisée","word_nosc":"deboisee","lemma":"déboiser","pos":"VER"} ,
		{"word":"déboisées","word_nosc":"deboisees","lemma":"déboiser","pos":"VER"} ,
		{"word":"débonda","word_nosc":"debonda","lemma":"débonder","pos":"VER"} ,
		{"word":"débondait","word_nosc":"debondait","lemma":"débonder","pos":"VER"} ,
		{"word":"débondant","word_nosc":"debondant","lemma":"débonder","pos":"VER"} ,
		{"word":"débonder","word_nosc":"debonder","lemma":"débonder","pos":"VER"} ,
		{"word":"débondé","word_nosc":"debonde","lemma":"débonder","pos":"VER"} ,
		{"word":"déborda","word_nosc":"deborda","lemma":"déborder","pos":"VER"} ,
		{"word":"débordaient","word_nosc":"debordaient","lemma":"déborder","pos":"VER"} ,
		{"word":"débordais","word_nosc":"debordais","lemma":"déborder","pos":"VER"} ,
		{"word":"débordait","word_nosc":"debordait","lemma":"déborder","pos":"VER"} ,
		{"word":"débordant","word_nosc":"debordant","lemma":"déborder","pos":"VER"} ,
		{"word":"déborde","word_nosc":"deborde","lemma":"déborder","pos":"VER"} ,
		{"word":"débordent","word_nosc":"debordent","lemma":"déborder","pos":"VER"} ,
		{"word":"déborder","word_nosc":"deborder","lemma":"déborder","pos":"VER"} ,
		{"word":"débordera","word_nosc":"debordera","lemma":"déborder","pos":"VER"} ,
		{"word":"déborderaient","word_nosc":"deborderaient","lemma":"déborder","pos":"VER"} ,
		{"word":"déborderait","word_nosc":"deborderait","lemma":"déborder","pos":"VER"} ,
		{"word":"débordez","word_nosc":"debordez","lemma":"déborder","pos":"VER"} ,
		{"word":"débordât","word_nosc":"debordat","lemma":"déborder","pos":"VER"} ,
		{"word":"débordèrent","word_nosc":"deborderent","lemma":"déborder","pos":"VER"} ,
		{"word":"débordé","word_nosc":"deborde","lemma":"déborder","pos":"VER"} ,
		{"word":"débordée","word_nosc":"debordee","lemma":"déborder","pos":"VER"} ,
		{"word":"débordées","word_nosc":"debordees","lemma":"déborder","pos":"VER"} ,
		{"word":"débordés","word_nosc":"debordes","lemma":"déborder","pos":"VER"} ,
		{"word":"débottelons","word_nosc":"debottelons","lemma":"débotteler","pos":"VER"} ,
		{"word":"déboucha","word_nosc":"deboucha","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouchai","word_nosc":"debouchai","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouchaient","word_nosc":"debouchaient","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouchais","word_nosc":"debouchais","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouchait","word_nosc":"debouchait","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouchant","word_nosc":"debouchant","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouche","word_nosc":"debouche","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouchent","word_nosc":"debouchent","lemma":"déboucher","pos":"VER"} ,
		{"word":"déboucher","word_nosc":"deboucher","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouchera","word_nosc":"debouchera","lemma":"déboucher","pos":"VER"} ,
		{"word":"déboucherai","word_nosc":"deboucherai","lemma":"déboucher","pos":"VER"} ,
		{"word":"déboucheraient","word_nosc":"deboucheraient","lemma":"déboucher","pos":"VER"} ,
		{"word":"déboucherait","word_nosc":"deboucherait","lemma":"déboucher","pos":"VER"} ,
		{"word":"déboucherez","word_nosc":"deboucherez","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouchez","word_nosc":"debouchez","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouchions","word_nosc":"debouchions","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouchons","word_nosc":"debouchons","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouchâmes","word_nosc":"debouchames","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouchèrent","word_nosc":"deboucherent","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouché","word_nosc":"debouche","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouchée","word_nosc":"debouchee","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouchées","word_nosc":"debouchees","lemma":"déboucher","pos":"VER"} ,
		{"word":"débouchés","word_nosc":"debouches","lemma":"déboucher","pos":"VER"} ,
		{"word":"déboucla","word_nosc":"deboucla","lemma":"déboucler","pos":"VER"} ,
		{"word":"débouclaient","word_nosc":"debouclaient","lemma":"déboucler","pos":"VER"} ,
		{"word":"débouclait","word_nosc":"debouclait","lemma":"déboucler","pos":"VER"} ,
		{"word":"débouclant","word_nosc":"debouclant","lemma":"déboucler","pos":"VER"} ,
		{"word":"déboucle","word_nosc":"deboucle","lemma":"déboucler","pos":"VER"} ,
		{"word":"débouclent","word_nosc":"debouclent","lemma":"déboucler","pos":"VER"} ,
		{"word":"déboucler","word_nosc":"deboucler","lemma":"déboucler","pos":"VER"} ,
		{"word":"débouclez","word_nosc":"debouclez","lemma":"déboucler","pos":"VER"} ,
		{"word":"débouclions","word_nosc":"debouclions","lemma":"déboucler","pos":"VER"} ,
		{"word":"débouclèrent","word_nosc":"debouclerent","lemma":"déboucler","pos":"VER"} ,
		{"word":"débouclé","word_nosc":"deboucle","lemma":"déboucler","pos":"VER"} ,
		{"word":"débouclées","word_nosc":"debouclees","lemma":"déboucler","pos":"VER"} ,
		{"word":"débouclés","word_nosc":"deboucles","lemma":"déboucler","pos":"VER"} ,
		{"word":"déboula","word_nosc":"deboula","lemma":"débouler","pos":"VER"} ,
		{"word":"déboulai","word_nosc":"deboulai","lemma":"débouler","pos":"VER"} ,
		{"word":"déboulaient","word_nosc":"deboulaient","lemma":"débouler","pos":"VER"} ,
		{"word":"déboulais","word_nosc":"deboulais","lemma":"débouler","pos":"VER"} ,
		{"word":"déboulait","word_nosc":"deboulait","lemma":"débouler","pos":"VER"} ,
		{"word":"déboulant","word_nosc":"deboulant","lemma":"débouler","pos":"VER"} ,
		{"word":"déboule","word_nosc":"deboule","lemma":"débouler","pos":"VER"} ,
		{"word":"déboulent","word_nosc":"deboulent","lemma":"débouler","pos":"VER"} ,
		{"word":"débouler","word_nosc":"debouler","lemma":"débouler","pos":"VER"} ,
		{"word":"déboulerais","word_nosc":"deboulerais","lemma":"débouler","pos":"VER"} ,
		{"word":"déboulerait","word_nosc":"deboulerait","lemma":"débouler","pos":"VER"} ,
		{"word":"débouleront","word_nosc":"debouleront","lemma":"débouler","pos":"VER"} ,
		{"word":"déboulez","word_nosc":"deboulez","lemma":"débouler","pos":"VER"} ,
		{"word":"débouliez","word_nosc":"debouliez","lemma":"débouler","pos":"VER"} ,
		{"word":"déboulions","word_nosc":"deboulions","lemma":"débouler","pos":"VER"} ,
		{"word":"déboulonnant","word_nosc":"deboulonnant","lemma":"déboulonner","pos":"VER"} ,
		{"word":"déboulonner","word_nosc":"deboulonner","lemma":"déboulonner","pos":"VER"} ,
		{"word":"déboulonnez","word_nosc":"deboulonnez","lemma":"déboulonner","pos":"VER"} ,
		{"word":"déboulonné","word_nosc":"deboulonne","lemma":"déboulonner","pos":"VER"} ,
		{"word":"déboulonnée","word_nosc":"deboulonnee","lemma":"déboulonner","pos":"VER"} ,
		{"word":"déboulonnées","word_nosc":"deboulonnees","lemma":"déboulonner","pos":"VER"} ,
		{"word":"déboulèrent","word_nosc":"deboulerent","lemma":"débouler","pos":"VER"} ,
		{"word":"déboulé","word_nosc":"deboule","lemma":"débouler","pos":"VER"} ,
		{"word":"déboulés","word_nosc":"deboules","lemma":"débouler","pos":"VER"} ,
		{"word":"débouquaient","word_nosc":"debouquaient","lemma":"débouquer","pos":"VER"} ,
		{"word":"débouqué","word_nosc":"debouque","lemma":"débouquer","pos":"VER"} ,
		{"word":"débourre","word_nosc":"debourre","lemma":"débourrer","pos":"VER"} ,
		{"word":"débourrent","word_nosc":"debourrent","lemma":"débourrer","pos":"VER"} ,
		{"word":"débourrer","word_nosc":"debourrer","lemma":"débourrer","pos":"VER"} ,
		{"word":"débourré","word_nosc":"debourre","lemma":"débourrer","pos":"VER"} ,
		{"word":"débourrée","word_nosc":"debourree","lemma":"débourrer","pos":"VER"} ,
		{"word":"débourse","word_nosc":"debourse","lemma":"débourser","pos":"VER"} ,
		{"word":"débourser","word_nosc":"debourser","lemma":"débourser","pos":"VER"} ,
		{"word":"déboursera","word_nosc":"deboursera","lemma":"débourser","pos":"VER"} ,
		{"word":"débourseront","word_nosc":"debourseront","lemma":"débourser","pos":"VER"} ,
		{"word":"déboursé","word_nosc":"debourse","lemma":"débourser","pos":"VER"} ,
		{"word":"déboursées","word_nosc":"deboursees","lemma":"débourser","pos":"VER"} ,
		{"word":"déboussolait","word_nosc":"deboussolait","lemma":"déboussoler","pos":"VER"} ,
		{"word":"déboussoler","word_nosc":"deboussoler","lemma":"déboussoler","pos":"VER"} ,
		{"word":"déboussolé","word_nosc":"deboussole","lemma":"déboussoler","pos":"VER"} ,
		{"word":"déboussolée","word_nosc":"deboussolee","lemma":"déboussoler","pos":"VER"} ,
		{"word":"déboussolées","word_nosc":"deboussolees","lemma":"déboussoler","pos":"VER"} ,
		{"word":"déboussolés","word_nosc":"deboussoles","lemma":"déboussoler","pos":"VER"} ,
		{"word":"déboutait","word_nosc":"deboutait","lemma":"débouter","pos":"VER"} ,
		{"word":"débouter","word_nosc":"debouter","lemma":"débouter","pos":"VER"} ,
		{"word":"déboutonna","word_nosc":"deboutonna","lemma":"déboutonner","pos":"VER"} ,
		{"word":"déboutonnait","word_nosc":"deboutonnait","lemma":"déboutonner","pos":"VER"} ,
		{"word":"déboutonnant","word_nosc":"deboutonnant","lemma":"déboutonner","pos":"VER"} ,
		{"word":"déboutonne","word_nosc":"deboutonne","lemma":"déboutonner","pos":"VER"} ,
		{"word":"déboutonnent","word_nosc":"deboutonnent","lemma":"déboutonner","pos":"VER"} ,
		{"word":"déboutonner","word_nosc":"deboutonner","lemma":"déboutonner","pos":"VER"} ,
		{"word":"déboutonnez","word_nosc":"deboutonnez","lemma":"déboutonner","pos":"VER"} ,
		{"word":"déboutonnât","word_nosc":"deboutonnat","lemma":"déboutonner","pos":"VER"} ,
		{"word":"déboutonné","word_nosc":"deboutonne","lemma":"déboutonner","pos":"VER"} ,
		{"word":"déboutonnée","word_nosc":"deboutonnee","lemma":"déboutonner","pos":"VER"} ,
		{"word":"déboutonnées","word_nosc":"deboutonnees","lemma":"déboutonner","pos":"VER"} ,
		{"word":"déboutonnés","word_nosc":"deboutonnes","lemma":"déboutonner","pos":"VER"} ,
		{"word":"débouté","word_nosc":"deboute","lemma":"débouter","pos":"VER"} ,
		{"word":"déboutée","word_nosc":"deboutee","lemma":"débouter","pos":"VER"} ,
		{"word":"déboîta","word_nosc":"deboita","lemma":"déboîter","pos":"VER"} ,
		{"word":"déboîtaient","word_nosc":"deboitaient","lemma":"déboîter","pos":"VER"} ,
		{"word":"déboîtais","word_nosc":"deboitais","lemma":"déboîter","pos":"VER"} ,
		{"word":"déboîtait","word_nosc":"deboitait","lemma":"déboîter","pos":"VER"} ,
		{"word":"déboîtant","word_nosc":"deboitant","lemma":"déboîter","pos":"VER"} ,
		{"word":"déboîte","word_nosc":"deboite","lemma":"déboîter","pos":"VER"} ,
		{"word":"déboîter","word_nosc":"deboiter","lemma":"déboîter","pos":"VER"} ,
		{"word":"déboîtez","word_nosc":"deboitez","lemma":"déboîter","pos":"VER"} ,
		{"word":"déboîté","word_nosc":"deboite","lemma":"déboîter","pos":"VER"} ,
		{"word":"déboîtée","word_nosc":"deboitee","lemma":"déboîter","pos":"VER"} ,
		{"word":"déboîtées","word_nosc":"deboitees","lemma":"déboîter","pos":"VER"} ,
		{"word":"débraguette","word_nosc":"debraguette","lemma":"débraguetter","pos":"VER"} ,
		{"word":"débraguettent","word_nosc":"debraguettent","lemma":"débraguetter","pos":"VER"} ,
		{"word":"débraguetter","word_nosc":"debraguetter","lemma":"débraguetter","pos":"VER"} ,
		{"word":"débraguetté","word_nosc":"debraguette","lemma":"débraguetter","pos":"VER"} ,
		{"word":"débraguettés","word_nosc":"debraguettes","lemma":"débraguetter","pos":"VER"} ,
		{"word":"débraillé","word_nosc":"debraille","lemma":"débrailler","pos":"VER"} ,
		{"word":"débraillée","word_nosc":"debraillee","lemma":"débrailler","pos":"VER"} ,
		{"word":"débraillées","word_nosc":"debraillees","lemma":"débrailler","pos":"VER"} ,
		{"word":"débraillés","word_nosc":"debrailles","lemma":"débrailler","pos":"VER"} ,
		{"word":"débraillés","word_nosc":"debrailles","lemma":"débraillé","pos":"VER"} ,
		{"word":"débrancha","word_nosc":"debrancha","lemma":"débrancher","pos":"VER"} ,
		{"word":"débranchant","word_nosc":"debranchant","lemma":"débrancher","pos":"VER"} ,
		{"word":"débranche","word_nosc":"debranche","lemma":"débrancher","pos":"VER"} ,
		{"word":"débranchent","word_nosc":"debranchent","lemma":"débrancher","pos":"VER"} ,
		{"word":"débrancher","word_nosc":"debrancher","lemma":"débrancher","pos":"VER"} ,
		{"word":"débrancherai","word_nosc":"debrancherai","lemma":"débrancher","pos":"VER"} ,
		{"word":"débrancherais","word_nosc":"debrancherais","lemma":"débrancher","pos":"VER"} ,
		{"word":"débrancherait","word_nosc":"debrancherait","lemma":"débrancher","pos":"VER"} ,
		{"word":"débranchez","word_nosc":"debranchez","lemma":"débrancher","pos":"VER"} ,
		{"word":"débranchons","word_nosc":"debranchons","lemma":"débrancher","pos":"VER"} ,
		{"word":"débranché","word_nosc":"debranche","lemma":"débrancher","pos":"VER"} ,
		{"word":"débranchée","word_nosc":"debranchee","lemma":"débrancher","pos":"VER"} ,
		{"word":"débranchées","word_nosc":"debranchees","lemma":"débrancher","pos":"VER"} ,
		{"word":"débranchés","word_nosc":"debranches","lemma":"débrancher","pos":"VER"} ,
		{"word":"débraya","word_nosc":"debraya","lemma":"débrayer","pos":"VER"} ,
		{"word":"débrayait","word_nosc":"debrayait","lemma":"débrayer","pos":"VER"} ,
		{"word":"débraye","word_nosc":"debraye","lemma":"débrayer","pos":"VER"} ,
		{"word":"débrayent","word_nosc":"debrayent","lemma":"débrayer","pos":"VER"} ,
		{"word":"débrayer","word_nosc":"debrayer","lemma":"débrayer","pos":"VER"} ,
		{"word":"débrayes","word_nosc":"debrayes","lemma":"débrayer","pos":"VER"} ,
		{"word":"débrayez","word_nosc":"debrayez","lemma":"débrayer","pos":"VER"} ,
		{"word":"débrayé","word_nosc":"debraye","lemma":"débrayer","pos":"VER"} ,
		{"word":"débrayée","word_nosc":"debrayee","lemma":"débrayer","pos":"VER"} ,
		{"word":"débridait","word_nosc":"debridait","lemma":"débrider","pos":"VER"} ,
		{"word":"débridant","word_nosc":"debridant","lemma":"débrider","pos":"VER"} ,
		{"word":"débride","word_nosc":"debride","lemma":"débrider","pos":"VER"} ,
		{"word":"débrider","word_nosc":"debrider","lemma":"débrider","pos":"VER"} ,
		{"word":"débridera","word_nosc":"debridera","lemma":"débrider","pos":"VER"} ,
		{"word":"débriderai","word_nosc":"debriderai","lemma":"débrider","pos":"VER"} ,
		{"word":"débriderait","word_nosc":"debriderait","lemma":"débrider","pos":"VER"} ,
		{"word":"débridez","word_nosc":"debridez","lemma":"débrider","pos":"VER"} ,
		{"word":"débridé","word_nosc":"debride","lemma":"débrider","pos":"VER"} ,
		{"word":"débridée","word_nosc":"debridee","lemma":"débrider","pos":"VER"} ,
		{"word":"débridées","word_nosc":"debridees","lemma":"débrider","pos":"VER"} ,
		{"word":"débridés","word_nosc":"debrides","lemma":"débrider","pos":"VER"} ,
		{"word":"débriefer","word_nosc":"debriefer","lemma":"débriefer","pos":"VER"} ,
		{"word":"débriefé","word_nosc":"debriefe","lemma":"débriefer","pos":"VER"} ,
		{"word":"débriefée","word_nosc":"debriefee","lemma":"débriefer","pos":"VER"} ,
		{"word":"débronzer","word_nosc":"debronzer","lemma":"débronzer","pos":"VER"} ,
		{"word":"débrouilla","word_nosc":"debrouilla","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillai","word_nosc":"debrouillai","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillaient","word_nosc":"debrouillaient","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillais","word_nosc":"debrouillais","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillait","word_nosc":"debrouillait","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillant","word_nosc":"debrouillant","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouille","word_nosc":"debrouille","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillent","word_nosc":"debrouillent","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouiller","word_nosc":"debrouiller","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillera","word_nosc":"debrouillera","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillerai","word_nosc":"debrouillerai","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouilleraient","word_nosc":"debrouilleraient","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillerais","word_nosc":"debrouillerais","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillerait","word_nosc":"debrouillerait","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouilleras","word_nosc":"debrouilleras","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillerez","word_nosc":"debrouillerez","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillerions","word_nosc":"debrouillerions","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillerons","word_nosc":"debrouillerons","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouilleront","word_nosc":"debrouilleront","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouilles","word_nosc":"debrouilles","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillez","word_nosc":"debrouillez","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouilliez","word_nosc":"debrouilliez","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillions","word_nosc":"debrouillions","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillons","word_nosc":"debrouillons","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillât","word_nosc":"debrouillat","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillé","word_nosc":"debrouille","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillée","word_nosc":"debrouillee","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillées","word_nosc":"debrouillees","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débrouillés","word_nosc":"debrouilles","lemma":"débrouiller","pos":"VER"} ,
		{"word":"débroussaille","word_nosc":"debroussaille","lemma":"débroussailler","pos":"VER"} ,
		{"word":"débroussailler","word_nosc":"debroussailler","lemma":"débroussailler","pos":"VER"} ,
		{"word":"débroussaillé","word_nosc":"debroussaille","lemma":"débroussailler","pos":"VER"} ,
		{"word":"débusquaient","word_nosc":"debusquaient","lemma":"débusquer","pos":"VER"} ,
		{"word":"débusquait","word_nosc":"debusquait","lemma":"débusquer","pos":"VER"} ,
		{"word":"débusquant","word_nosc":"debusquant","lemma":"débusquer","pos":"VER"} ,
		{"word":"débusque","word_nosc":"debusque","lemma":"débusquer","pos":"VER"} ,
		{"word":"débusquer","word_nosc":"debusquer","lemma":"débusquer","pos":"VER"} ,
		{"word":"débusquerait","word_nosc":"debusquerait","lemma":"débusquer","pos":"VER"} ,
		{"word":"débusquerons","word_nosc":"debusquerons","lemma":"débusquer","pos":"VER"} ,
		{"word":"débusquez","word_nosc":"debusquez","lemma":"débusquer","pos":"VER"} ,
		{"word":"débusquèrent","word_nosc":"debusquerent","lemma":"débusquer","pos":"VER"} ,
		{"word":"débusqué","word_nosc":"debusque","lemma":"débusquer","pos":"VER"} ,
		{"word":"débusquée","word_nosc":"debusquee","lemma":"débusquer","pos":"VER"} ,
		{"word":"débusqués","word_nosc":"debusques","lemma":"débusquer","pos":"VER"} ,
		{"word":"débuta","word_nosc":"debuta","lemma":"débuter","pos":"VER"} ,
		{"word":"débutai","word_nosc":"debutai","lemma":"débuter","pos":"VER"} ,
		{"word":"débutaient","word_nosc":"debutaient","lemma":"débuter","pos":"VER"} ,
		{"word":"débutais","word_nosc":"debutais","lemma":"débuter","pos":"VER"} ,
		{"word":"débutait","word_nosc":"debutait","lemma":"débuter","pos":"VER"} ,
		{"word":"débutant","word_nosc":"debutant","lemma":"débuter","pos":"VER"} ,
		{"word":"débute","word_nosc":"debute","lemma":"débuter","pos":"VER"} ,
		{"word":"débutent","word_nosc":"debutent","lemma":"débuter","pos":"VER"} ,
		{"word":"débuter","word_nosc":"debuter","lemma":"débuter","pos":"VER"} ,
		{"word":"débutera","word_nosc":"debutera","lemma":"débuter","pos":"VER"} ,
		{"word":"débuteraient","word_nosc":"debuteraient","lemma":"débuter","pos":"VER"} ,
		{"word":"débutes","word_nosc":"debutes","lemma":"débuter","pos":"VER"} ,
		{"word":"débutez","word_nosc":"debutez","lemma":"débuter","pos":"VER"} ,
		{"word":"débutiez","word_nosc":"debutiez","lemma":"débuter","pos":"VER"} ,
		{"word":"débutons","word_nosc":"debutons","lemma":"débuter","pos":"VER"} ,
		{"word":"débutèrent","word_nosc":"debuterent","lemma":"débuter","pos":"VER"} ,
		{"word":"débuté","word_nosc":"debute","lemma":"débuter","pos":"VER"} ,
		{"word":"débutée","word_nosc":"debutee","lemma":"débuter","pos":"VER"} ,
		{"word":"débâillonné","word_nosc":"debaillonne","lemma":"débâillonner","pos":"VER"} ,
		{"word":"débâté","word_nosc":"debate","lemma":"débâter","pos":"VER"} ,
		{"word":"débâtées","word_nosc":"debatees","lemma":"débâter","pos":"VER"} ,
		{"word":"décabosser","word_nosc":"decabosser","lemma":"décabosser","pos":"VER"} ,
		{"word":"décacheta","word_nosc":"decacheta","lemma":"décacheter","pos":"VER"} ,
		{"word":"décachetai","word_nosc":"decachetai","lemma":"décacheter","pos":"VER"} ,
		{"word":"décachetaient","word_nosc":"decachetaient","lemma":"décacheter","pos":"VER"} ,
		{"word":"décachetais","word_nosc":"decachetais","lemma":"décacheter","pos":"VER"} ,
		{"word":"décachetant","word_nosc":"decachetant","lemma":"décacheter","pos":"VER"} ,
		{"word":"décacheter","word_nosc":"decacheter","lemma":"décacheter","pos":"VER"} ,
		{"word":"décachetons","word_nosc":"decachetons","lemma":"décacheter","pos":"VER"} ,
		{"word":"décachette","word_nosc":"decachette","lemma":"décacheter","pos":"VER"} ,
		{"word":"décachetteraient","word_nosc":"decachetteraient","lemma":"décacheter","pos":"VER"} ,
		{"word":"décacheté","word_nosc":"decachete","lemma":"décacheter","pos":"VER"} ,
		{"word":"décachetée","word_nosc":"decachetee","lemma":"décacheter","pos":"VER"} ,
		{"word":"décachetées","word_nosc":"decachetees","lemma":"décacheter","pos":"VER"} ,
		{"word":"décaféiné","word_nosc":"decafeine","lemma":"décaféiner","pos":"VER"} ,
		{"word":"décaféinée","word_nosc":"decafeinee","lemma":"décaféiner","pos":"VER"} ,
		{"word":"décaisser","word_nosc":"decaisser","lemma":"décaisser","pos":"VER"} ,
		{"word":"décalaminé","word_nosc":"decalamine","lemma":"décalaminer","pos":"VER"} ,
		{"word":"décalant","word_nosc":"decalant","lemma":"décaler","pos":"VER"} ,
		{"word":"décalcifié","word_nosc":"decalcifie","lemma":"décalcifier","pos":"VER"} ,
		{"word":"décalcifiée","word_nosc":"decalcifiee","lemma":"décalcifier","pos":"VER"} ,
		{"word":"décale","word_nosc":"decale","lemma":"décaler","pos":"VER"} ,
		{"word":"décalent","word_nosc":"decalent","lemma":"décaler","pos":"VER"} ,
		{"word":"décaler","word_nosc":"decaler","lemma":"décaler","pos":"VER"} ,
		{"word":"décalez","word_nosc":"decalez","lemma":"décaler","pos":"VER"} ,
		{"word":"décalotta","word_nosc":"decalotta","lemma":"décalotter","pos":"VER"} ,
		{"word":"décalotter","word_nosc":"decalotter","lemma":"décalotter","pos":"VER"} ,
		{"word":"décalotté","word_nosc":"decalotte","lemma":"décalotter","pos":"VER"} ,
		{"word":"décalottée","word_nosc":"decalottee","lemma":"décalotter","pos":"VER"} ,
		{"word":"décalottés","word_nosc":"decalottes","lemma":"décalotter","pos":"VER"} ,
		{"word":"décalquai","word_nosc":"decalquai","lemma":"décalquer","pos":"VER"} ,
		{"word":"décalquait","word_nosc":"decalquait","lemma":"décalquer","pos":"VER"} ,
		{"word":"décalque","word_nosc":"decalque","lemma":"décalquer","pos":"VER"} ,
		{"word":"décalquer","word_nosc":"decalquer","lemma":"décalquer","pos":"VER"} ,
		{"word":"décalques","word_nosc":"decalques","lemma":"décalquer","pos":"VER"} ,
		{"word":"décalqué","word_nosc":"decalque","lemma":"décalquer","pos":"VER"} ,
		{"word":"décalquée","word_nosc":"decalquee","lemma":"décalquer","pos":"VER"} ,
		{"word":"décalquées","word_nosc":"decalquees","lemma":"décalquer","pos":"VER"} ,
		{"word":"décalqués","word_nosc":"decalques","lemma":"décalquer","pos":"VER"} ,
		{"word":"décalé","word_nosc":"decale","lemma":"décaler","pos":"VER"} ,
		{"word":"décalée","word_nosc":"decalee","lemma":"décaler","pos":"VER"} ,
		{"word":"décalées","word_nosc":"decalees","lemma":"décaler","pos":"VER"} ,
		{"word":"décalés","word_nosc":"decales","lemma":"décaler","pos":"VER"} ,
		{"word":"décambuter","word_nosc":"decambuter","lemma":"décambuter","pos":"VER"} ,
		{"word":"décampa","word_nosc":"decampa","lemma":"décamper","pos":"VER"} ,
		{"word":"décampait","word_nosc":"decampait","lemma":"décamper","pos":"VER"} ,
		{"word":"décampe","word_nosc":"decampe","lemma":"décamper","pos":"VER"} ,
		{"word":"décampent","word_nosc":"decampent","lemma":"décamper","pos":"VER"} ,
		{"word":"décamper","word_nosc":"decamper","lemma":"décamper","pos":"VER"} ,
		{"word":"décampera","word_nosc":"decampera","lemma":"décamper","pos":"VER"} ,
		{"word":"décamperais","word_nosc":"decamperais","lemma":"décamper","pos":"VER"} ,
		{"word":"décamperont","word_nosc":"decamperont","lemma":"décamper","pos":"VER"} ,
		{"word":"décampes","word_nosc":"decampes","lemma":"décamper","pos":"VER"} ,
		{"word":"décampez","word_nosc":"decampez","lemma":"décamper","pos":"VER"} ,
		{"word":"décampons","word_nosc":"decampons","lemma":"décamper","pos":"VER"} ,
		{"word":"décampé","word_nosc":"decampe","lemma":"décamper","pos":"VER"} ,
		{"word":"décanillant","word_nosc":"decanillant","lemma":"décaniller","pos":"VER"} ,
		{"word":"décanille","word_nosc":"decanille","lemma":"décaniller","pos":"VER"} ,
		{"word":"décaniller","word_nosc":"decaniller","lemma":"décaniller","pos":"VER"} ,
		{"word":"décanillerait","word_nosc":"decanillerait","lemma":"décaniller","pos":"VER"} ,
		{"word":"décanillez","word_nosc":"decanillez","lemma":"décaniller","pos":"VER"} ,
		{"word":"décantai","word_nosc":"decantai","lemma":"décanter","pos":"VER"} ,
		{"word":"décantaient","word_nosc":"decantaient","lemma":"décanter","pos":"VER"} ,
		{"word":"décantait","word_nosc":"decantait","lemma":"décanter","pos":"VER"} ,
		{"word":"décantant","word_nosc":"decantant","lemma":"décanter","pos":"VER"} ,
		{"word":"décante","word_nosc":"decante","lemma":"décanter","pos":"VER"} ,
		{"word":"décantent","word_nosc":"decantent","lemma":"décanter","pos":"VER"} ,
		{"word":"décanter","word_nosc":"decanter","lemma":"décanter","pos":"VER"} ,
		{"word":"décanté","word_nosc":"decante","lemma":"décanter","pos":"VER"} ,
		{"word":"décantée","word_nosc":"decantee","lemma":"décanter","pos":"VER"} ,
		{"word":"décantées","word_nosc":"decantees","lemma":"décanter","pos":"VER"} ,
		{"word":"décapais","word_nosc":"decapais","lemma":"décaper","pos":"VER"} ,
		{"word":"décapait","word_nosc":"decapait","lemma":"décaper","pos":"VER"} ,
		{"word":"décapant","word_nosc":"decapant","lemma":"décaper","pos":"VER"} ,
		{"word":"décape","word_nosc":"decape","lemma":"décaper","pos":"VER"} ,
		{"word":"décaper","word_nosc":"decaper","lemma":"décaper","pos":"VER"} ,
		{"word":"décapera","word_nosc":"decapera","lemma":"décaper","pos":"VER"} ,
		{"word":"décapita","word_nosc":"decapita","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapitaient","word_nosc":"decapitaient","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapitais","word_nosc":"decapitais","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapitait","word_nosc":"decapitait","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapitant","word_nosc":"decapitant","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapite","word_nosc":"decapite","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapitent","word_nosc":"decapitent","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapiter","word_nosc":"decapiter","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapitera","word_nosc":"decapitera","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapiterai","word_nosc":"decapiterai","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapiterait","word_nosc":"decapiterait","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapiteront","word_nosc":"decapiteront","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapitez","word_nosc":"decapitez","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapitons","word_nosc":"decapitons","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapitât","word_nosc":"decapitat","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapitèrent","word_nosc":"decapiterent","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapité","word_nosc":"decapite","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapitée","word_nosc":"decapitee","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapitées","word_nosc":"decapitees","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapités","word_nosc":"decapites","lemma":"décapiter","pos":"VER"} ,
		{"word":"décapotait","word_nosc":"decapotait","lemma":"décapoter","pos":"VER"} ,
		{"word":"décapote","word_nosc":"decapote","lemma":"décapoter","pos":"VER"} ,
		{"word":"décapoter","word_nosc":"decapoter","lemma":"décapoter","pos":"VER"} ,
		{"word":"décapoté","word_nosc":"decapote","lemma":"décapoter","pos":"VER"} ,
		{"word":"décapotée","word_nosc":"decapotee","lemma":"décapoter","pos":"VER"} ,
		{"word":"décapotés","word_nosc":"decapotes","lemma":"décapoter","pos":"VER"} ,
		{"word":"décapsula","word_nosc":"decapsula","lemma":"décapsuler","pos":"VER"} ,
		{"word":"décapsulant","word_nosc":"decapsulant","lemma":"décapsuler","pos":"VER"} ,
		{"word":"décapsule","word_nosc":"decapsule","lemma":"décapsuler","pos":"VER"} ,
		{"word":"décapsuler","word_nosc":"decapsuler","lemma":"décapsuler","pos":"VER"} ,
		{"word":"décapsulerait","word_nosc":"decapsulerait","lemma":"décapsuler","pos":"VER"} ,
		{"word":"décapsulez","word_nosc":"decapsulez","lemma":"décapsuler","pos":"VER"} ,
		{"word":"décapsulé","word_nosc":"decapsule","lemma":"décapsuler","pos":"VER"} ,
		{"word":"décapsulée","word_nosc":"decapsulee","lemma":"décapsuler","pos":"VER"} ,
		{"word":"décapuchonna","word_nosc":"decapuchonna","lemma":"décapuchonner","pos":"VER"} ,
		{"word":"décapuchonne","word_nosc":"decapuchonne","lemma":"décapuchonner","pos":"VER"} ,
		{"word":"décapuchonner","word_nosc":"decapuchonner","lemma":"décapuchonner","pos":"VER"} ,
		{"word":"décapuchonnèrent","word_nosc":"decapuchonnerent","lemma":"décapuchonner","pos":"VER"} ,
		{"word":"décapé","word_nosc":"decape","lemma":"décaper","pos":"VER"} ,
		{"word":"décapée","word_nosc":"decapee","lemma":"décaper","pos":"VER"} ,
		{"word":"décapées","word_nosc":"decapees","lemma":"décaper","pos":"VER"} ,
		{"word":"décapés","word_nosc":"decapes","lemma":"décaper","pos":"VER"} ,
		{"word":"décarcassais","word_nosc":"decarcassais","lemma":"décarcasser","pos":"VER"} ,
		{"word":"décarcassait","word_nosc":"decarcassait","lemma":"décarcasser","pos":"VER"} ,
		{"word":"décarcassent","word_nosc":"decarcassent","lemma":"décarcasser","pos":"VER"} ,
		{"word":"décarcasser","word_nosc":"decarcasser","lemma":"décarcasser","pos":"VER"} ,
		{"word":"décarcassé","word_nosc":"decarcasse","lemma":"décarcasser","pos":"VER"} ,
		{"word":"décarcassée","word_nosc":"decarcassee","lemma":"décarcasser","pos":"VER"} ,
		{"word":"décarcassées","word_nosc":"decarcassees","lemma":"décarcasser","pos":"VER"} ,
		{"word":"décarpiller","word_nosc":"decarpiller","lemma":"décarpiller","pos":"VER"} ,
		{"word":"décarpillé","word_nosc":"decarpille","lemma":"décarpiller","pos":"VER"} ,
		{"word":"décarraient","word_nosc":"decarraient","lemma":"décarrer","pos":"VER"} ,
		{"word":"décarrais","word_nosc":"decarrais","lemma":"décarrer","pos":"VER"} ,
		{"word":"décarrait","word_nosc":"decarrait","lemma":"décarrer","pos":"VER"} ,
		{"word":"décarrant","word_nosc":"decarrant","lemma":"décarrer","pos":"VER"} ,
		{"word":"décarre","word_nosc":"decarre","lemma":"décarrer","pos":"VER"} ,
		{"word":"décarrent","word_nosc":"decarrent","lemma":"décarrer","pos":"VER"} ,
		{"word":"décarrer","word_nosc":"decarrer","lemma":"décarrer","pos":"VER"} ,
		{"word":"décarrerait","word_nosc":"decarrerait","lemma":"décarrer","pos":"VER"} ,
		{"word":"décarres","word_nosc":"decarres","lemma":"décarrer","pos":"VER"} ,
		{"word":"décarré","word_nosc":"decarre","lemma":"décarrer","pos":"VER"} ,
		{"word":"décarrés","word_nosc":"decarres","lemma":"décarrer","pos":"VER"} ,
		{"word":"décati","word_nosc":"decati","lemma":"décatir","pos":"VER"} ,
		{"word":"décatie","word_nosc":"decatie","lemma":"décatir","pos":"VER"} ,
		{"word":"décatir","word_nosc":"decatir","lemma":"décatir","pos":"VER"} ,
		{"word":"déceint","word_nosc":"deceint","lemma":"déceindre","pos":"VER"} ,
		{"word":"décela","word_nosc":"decela","lemma":"déceler","pos":"VER"} ,
		{"word":"décelai","word_nosc":"decelai","lemma":"déceler","pos":"VER"} ,
		{"word":"décelaient","word_nosc":"decelaient","lemma":"déceler","pos":"VER"} ,
		{"word":"décelais","word_nosc":"decelais","lemma":"déceler","pos":"VER"} ,
		{"word":"décelait","word_nosc":"decelait","lemma":"déceler","pos":"VER"} ,
		{"word":"décelant","word_nosc":"decelant","lemma":"déceler","pos":"VER"} ,
		{"word":"déceler","word_nosc":"deceler","lemma":"déceler","pos":"VER"} ,
		{"word":"décelèrent","word_nosc":"decelerent","lemma":"déceler","pos":"VER"} ,
		{"word":"décelé","word_nosc":"decele","lemma":"déceler","pos":"VER"} ,
		{"word":"décelée","word_nosc":"decelee","lemma":"déceler","pos":"VER"} ,
		{"word":"décelées","word_nosc":"decelees","lemma":"déceler","pos":"VER"} ,
		{"word":"décelés","word_nosc":"deceles","lemma":"déceler","pos":"VER"} ,
		{"word":"décentralisé","word_nosc":"decentralise","lemma":"décentraliser","pos":"VER"} ,
		{"word":"décentralisée","word_nosc":"decentralisee","lemma":"décentraliser","pos":"VER"} ,
		{"word":"décentralisées","word_nosc":"decentralisees","lemma":"décentraliser","pos":"VER"} ,
		{"word":"décentrant","word_nosc":"decentrant","lemma":"décentrer","pos":"VER"} ,
		{"word":"décentre","word_nosc":"decentre","lemma":"décentrer","pos":"VER"} ,
		{"word":"décentrée","word_nosc":"decentree","lemma":"décentrer","pos":"VER"} ,
		{"word":"décentrées","word_nosc":"decentrees","lemma":"décentrer","pos":"VER"} ,
		{"word":"décerna","word_nosc":"decerna","lemma":"décerner","pos":"VER"} ,
		{"word":"décernai","word_nosc":"decernai","lemma":"décerner","pos":"VER"} ,
		{"word":"décernais","word_nosc":"decernais","lemma":"décerner","pos":"VER"} ,
		{"word":"décernait","word_nosc":"decernait","lemma":"décerner","pos":"VER"} ,
		{"word":"décernant","word_nosc":"decernant","lemma":"décerner","pos":"VER"} ,
		{"word":"décerne","word_nosc":"decerne","lemma":"décerner","pos":"VER"} ,
		{"word":"décernent","word_nosc":"decernent","lemma":"décerner","pos":"VER"} ,
		{"word":"décerner","word_nosc":"decerner","lemma":"décerner","pos":"VER"} ,
		{"word":"décernera","word_nosc":"decernera","lemma":"décerner","pos":"VER"} ,
		{"word":"décerniez","word_nosc":"decerniez","lemma":"décerner","pos":"VER"} ,
		{"word":"décernons","word_nosc":"decernons","lemma":"décerner","pos":"VER"} ,
		{"word":"décerné","word_nosc":"decerne","lemma":"décerner","pos":"VER"} ,
		{"word":"décernée","word_nosc":"decernee","lemma":"décerner","pos":"VER"} ,
		{"word":"décernées","word_nosc":"decernees","lemma":"décerner","pos":"VER"} ,
		{"word":"décernés","word_nosc":"decernes","lemma":"décerner","pos":"VER"} ,
		{"word":"décerveler","word_nosc":"decerveler","lemma":"décerveler","pos":"VER"} ,
		{"word":"décervelle","word_nosc":"decervelle","lemma":"décerveler","pos":"VER"} ,
		{"word":"décervelé","word_nosc":"decervele","lemma":"décerveler","pos":"VER"} ,
		{"word":"décesse","word_nosc":"decesse","lemma":"décesser","pos":"VER"} ,
		{"word":"décessé","word_nosc":"decesse","lemma":"décesser","pos":"VER"} ,
		{"word":"décevaient","word_nosc":"decevaient","lemma":"décevoir","pos":"VER"} ,
		{"word":"décevais","word_nosc":"decevais","lemma":"décevoir","pos":"VER"} ,
		{"word":"décevait","word_nosc":"decevait","lemma":"décevoir","pos":"VER"} ,
		{"word":"décevant","word_nosc":"decevant","lemma":"décevoir","pos":"VER"} ,
		{"word":"décevez","word_nosc":"decevez","lemma":"décevoir","pos":"VER"} ,
		{"word":"déceviez","word_nosc":"deceviez","lemma":"décevoir","pos":"VER"} ,
		{"word":"décevoir","word_nosc":"decevoir","lemma":"décevoir","pos":"VER"} ,
		{"word":"décevons","word_nosc":"decevons","lemma":"décevoir","pos":"VER"} ,
		{"word":"décevra","word_nosc":"decevra","lemma":"décevoir","pos":"VER"} ,
		{"word":"décevrai","word_nosc":"decevrai","lemma":"décevoir","pos":"VER"} ,
		{"word":"décevraient","word_nosc":"decevraient","lemma":"décevoir","pos":"VER"} ,
		{"word":"décevrais","word_nosc":"decevrais","lemma":"décevoir","pos":"VER"} ,
		{"word":"décevrait","word_nosc":"decevrait","lemma":"décevoir","pos":"VER"} ,
		{"word":"décevras","word_nosc":"decevras","lemma":"décevoir","pos":"VER"} ,
		{"word":"décevrez","word_nosc":"decevrez","lemma":"décevoir","pos":"VER"} ,
		{"word":"déchait","word_nosc":"dechait","lemma":"décher","pos":"VER"} ,
		{"word":"déchanta","word_nosc":"dechanta","lemma":"déchanter","pos":"VER"} ,
		{"word":"déchantai","word_nosc":"dechantai","lemma":"déchanter","pos":"VER"} ,
		{"word":"déchantait","word_nosc":"dechantait","lemma":"déchanter","pos":"VER"} ,
		{"word":"déchantant","word_nosc":"dechantant","lemma":"déchanter","pos":"VER"} ,
		{"word":"déchante","word_nosc":"dechante","lemma":"déchanter","pos":"VER"} ,
		{"word":"déchantent","word_nosc":"dechantent","lemma":"déchanter","pos":"VER"} ,
		{"word":"déchanter","word_nosc":"dechanter","lemma":"déchanter","pos":"VER"} ,
		{"word":"déchanterais","word_nosc":"dechanterais","lemma":"déchanter","pos":"VER"} ,
		{"word":"déchantèrent","word_nosc":"dechanterent","lemma":"déchanter","pos":"VER"} ,
		{"word":"déchanté","word_nosc":"dechante","lemma":"déchanter","pos":"VER"} ,
		{"word":"décharge","word_nosc":"decharge","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargea","word_nosc":"dechargea","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargeaient","word_nosc":"dechargeaient","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargeait","word_nosc":"dechargeait","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargeant","word_nosc":"dechargeant","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargent","word_nosc":"dechargent","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargeons","word_nosc":"dechargeons","lemma":"décharger","pos":"VER"} ,
		{"word":"décharger","word_nosc":"decharger","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargera","word_nosc":"dechargera","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargerais","word_nosc":"dechargerais","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargerait","word_nosc":"dechargerait","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargerez","word_nosc":"dechargerez","lemma":"décharger","pos":"VER"} ,
		{"word":"décharges","word_nosc":"decharges","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargez","word_nosc":"dechargez","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargeât","word_nosc":"dechargeat","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargions","word_nosc":"dechargions","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargèrent","word_nosc":"dechargerent","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargé","word_nosc":"decharge","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargée","word_nosc":"dechargee","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargées","word_nosc":"dechargees","lemma":"décharger","pos":"VER"} ,
		{"word":"déchargés","word_nosc":"decharges","lemma":"décharger","pos":"VER"} ,
		{"word":"décharne","word_nosc":"decharne","lemma":"décharner","pos":"VER"} ,
		{"word":"décharnent","word_nosc":"decharnent","lemma":"décharner","pos":"VER"} ,
		{"word":"décharner","word_nosc":"decharner","lemma":"décharner","pos":"VER"} ,
		{"word":"décharnerait","word_nosc":"decharnerait","lemma":"décharner","pos":"VER"} ,
		{"word":"décharné","word_nosc":"decharne","lemma":"décharner","pos":"VER"} ,
		{"word":"décharnée","word_nosc":"decharnee","lemma":"décharner","pos":"VER"} ,
		{"word":"décharnées","word_nosc":"decharnees","lemma":"décharner","pos":"VER"} ,
		{"word":"décharnés","word_nosc":"decharnes","lemma":"décharner","pos":"VER"} ,
		{"word":"déchassé","word_nosc":"dechasse","lemma":"déchasser","pos":"VER"} ,
		{"word":"déchaumaient","word_nosc":"dechaumaient","lemma":"déchaumer","pos":"VER"} ,
		{"word":"déchaumée","word_nosc":"dechaumee","lemma":"déchaumer","pos":"VER"} ,
		{"word":"déchaussa","word_nosc":"dechaussa","lemma":"déchausser","pos":"VER"} ,
		{"word":"déchaussaient","word_nosc":"dechaussaient","lemma":"déchausser","pos":"VER"} ,
		{"word":"déchaussait","word_nosc":"dechaussait","lemma":"déchausser","pos":"VER"} ,
		{"word":"déchaussant","word_nosc":"dechaussant","lemma":"déchausser","pos":"VER"} ,
		{"word":"déchausse","word_nosc":"dechausse","lemma":"déchausser","pos":"VER"} ,
		{"word":"déchaussent","word_nosc":"dechaussent","lemma":"déchausser","pos":"VER"} ,
		{"word":"déchausser","word_nosc":"dechausser","lemma":"déchausser","pos":"VER"} ,
		{"word":"déchausserais","word_nosc":"dechausserais","lemma":"déchausser","pos":"VER"} ,
		{"word":"déchaussez","word_nosc":"dechaussez","lemma":"déchausser","pos":"VER"} ,
		{"word":"déchaussé","word_nosc":"dechausse","lemma":"déchausser","pos":"VER"} ,
		{"word":"déchaussée","word_nosc":"dechaussee","lemma":"déchausser","pos":"VER"} ,
		{"word":"déchaussées","word_nosc":"dechaussees","lemma":"déchausser","pos":"VER"} ,
		{"word":"déchaussés","word_nosc":"dechausses","lemma":"déchausser","pos":"VER"} ,
		{"word":"déchaîna","word_nosc":"dechaina","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaînaient","word_nosc":"dechainaient","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaînais","word_nosc":"dechainais","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaînait","word_nosc":"dechainait","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaînant","word_nosc":"dechainant","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaîne","word_nosc":"dechaine","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaînent","word_nosc":"dechainent","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaîner","word_nosc":"dechainer","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaînera","word_nosc":"dechainera","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaînerait","word_nosc":"dechainerait","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaîneras","word_nosc":"dechaineras","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaînez","word_nosc":"dechainez","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaînât","word_nosc":"dechainat","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaînèrent","word_nosc":"dechainerent","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaîné","word_nosc":"dechaine","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaînée","word_nosc":"dechainee","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaînées","word_nosc":"dechainees","lemma":"déchaîner","pos":"VER"} ,
		{"word":"déchaînés","word_nosc":"dechaines","lemma":"déchaîner","pos":"VER"} ,
		{"word":"décher","word_nosc":"decher","lemma":"décher","pos":"VER"} ,
		{"word":"déchiffra","word_nosc":"dechiffra","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffrai","word_nosc":"dechiffrai","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffraient","word_nosc":"dechiffraient","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffrais","word_nosc":"dechiffrais","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffrait","word_nosc":"dechiffrait","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffrant","word_nosc":"dechiffrant","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffre","word_nosc":"dechiffre","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffrent","word_nosc":"dechiffrent","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffrer","word_nosc":"dechiffrer","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffrera","word_nosc":"dechiffrera","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffrez","word_nosc":"dechiffrez","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffrions","word_nosc":"dechiffrions","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffrâmes","word_nosc":"dechifframes","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffrât","word_nosc":"dechiffrat","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffrèrent","word_nosc":"dechiffrerent","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffré","word_nosc":"dechiffre","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffrée","word_nosc":"dechiffree","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffrées","word_nosc":"dechiffrees","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiffrés","word_nosc":"dechiffres","lemma":"déchiffrer","pos":"VER"} ,
		{"word":"déchiqueta","word_nosc":"dechiqueta","lemma":"déchiqueter","pos":"VER"} ,
		{"word":"déchiquetaient","word_nosc":"dechiquetaient","lemma":"déchiqueter","pos":"VER"} ,
		{"word":"déchiquetait","word_nosc":"dechiquetait","lemma":"déchiqueter","pos":"VER"} ,
		{"word":"déchiquetant","word_nosc":"dechiquetant","lemma":"déchiqueter","pos":"VER"} ,
		{"word":"déchiqueter","word_nosc":"dechiqueter","lemma":"déchiqueter","pos":"VER"} ,
		{"word":"déchiquetons","word_nosc":"dechiquetons","lemma":"déchiqueter","pos":"VER"} ,
		{"word":"déchiquette","word_nosc":"dechiquette","lemma":"déchiqueter","pos":"VER"} ,
		{"word":"déchiquettent","word_nosc":"dechiquettent","lemma":"déchiqueter","pos":"VER"} ,
		{"word":"déchiquettes","word_nosc":"dechiquettes","lemma":"déchiqueter","pos":"VER"} ,
		{"word":"déchiqueté","word_nosc":"dechiquete","lemma":"déchiqueter","pos":"VER"} ,
		{"word":"déchiquetée","word_nosc":"dechiquetee","lemma":"déchiqueter","pos":"VER"} ,
		{"word":"déchiquetées","word_nosc":"dechiquetees","lemma":"déchiqueter","pos":"VER"} ,
		{"word":"déchiquetés","word_nosc":"dechiquetes","lemma":"déchiqueter","pos":"VER"} ,
		{"word":"déchira","word_nosc":"dechira","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirai","word_nosc":"dechirai","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchiraient","word_nosc":"dechiraient","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirais","word_nosc":"dechirais","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirait","word_nosc":"dechirait","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirant","word_nosc":"dechirant","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchire","word_nosc":"dechire","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirent","word_nosc":"dechirent","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirer","word_nosc":"dechirer","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirera","word_nosc":"dechirera","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirerai","word_nosc":"dechirerai","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchireraient","word_nosc":"dechireraient","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirerais","word_nosc":"dechirerais","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirerait","word_nosc":"dechirerait","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchireras","word_nosc":"dechireras","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirerons","word_nosc":"dechirerons","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchireront","word_nosc":"dechireront","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchires","word_nosc":"dechires","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirez","word_nosc":"dechirez","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchiriez","word_nosc":"dechiriez","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirions","word_nosc":"dechirions","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirons","word_nosc":"dechirons","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirât","word_nosc":"dechirat","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirèrent","word_nosc":"dechirerent","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchiré","word_nosc":"dechire","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirée","word_nosc":"dechiree","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirées","word_nosc":"dechirees","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchirés","word_nosc":"dechires","lemma":"déchirer","pos":"VER"} ,
		{"word":"déchoient","word_nosc":"dechoient","lemma":"déchoir","pos":"VER"} ,
		{"word":"déchoir","word_nosc":"dechoir","lemma":"déchoir","pos":"VER"} ,
		{"word":"déchoirons","word_nosc":"dechoirons","lemma":"déchoir","pos":"VER"} ,
		{"word":"déchouer","word_nosc":"dechouer","lemma":"déchouer","pos":"VER"} ,
		{"word":"déchristianise","word_nosc":"dechristianise","lemma":"déchristianiser","pos":"VER"} ,
		{"word":"déchu","word_nosc":"dechu","lemma":"déchoir","pos":"VER"} ,
		{"word":"déchue","word_nosc":"dechue","lemma":"déchoir","pos":"VER"} ,
		{"word":"déchues","word_nosc":"dechues","lemma":"déchoir","pos":"VER"} ,
		{"word":"déchus","word_nosc":"dechus","lemma":"déchoir","pos":"VER"} ,
		{"word":"déché","word_nosc":"deche","lemma":"décher","pos":"VER"} ,
		{"word":"décida","word_nosc":"decida","lemma":"décider","pos":"VER"} ,
		{"word":"décidai","word_nosc":"decidai","lemma":"décider","pos":"VER"} ,
		{"word":"décidaient","word_nosc":"decidaient","lemma":"décider","pos":"VER"} ,
		{"word":"décidais","word_nosc":"decidais","lemma":"décider","pos":"VER"} ,
		{"word":"décidait","word_nosc":"decidait","lemma":"décider","pos":"VER"} ,
		{"word":"décidant","word_nosc":"decidant","lemma":"décider","pos":"VER"} ,
		{"word":"décide","word_nosc":"decide","lemma":"décider","pos":"VER"} ,
		{"word":"décident","word_nosc":"decident","lemma":"décider","pos":"VER"} ,
		{"word":"décider","word_nosc":"decider","lemma":"décider","pos":"VER"} ,
		{"word":"décidera","word_nosc":"decidera","lemma":"décider","pos":"VER"} ,
		{"word":"déciderai","word_nosc":"deciderai","lemma":"décider","pos":"VER"} ,
		{"word":"décideraient","word_nosc":"decideraient","lemma":"décider","pos":"VER"} ,
		{"word":"déciderais","word_nosc":"deciderais","lemma":"décider","pos":"VER"} ,
		{"word":"déciderait","word_nosc":"deciderait","lemma":"décider","pos":"VER"} ,
		{"word":"décideras","word_nosc":"decideras","lemma":"décider","pos":"VER"} ,
		{"word":"déciderez","word_nosc":"deciderez","lemma":"décider","pos":"VER"} ,
		{"word":"décideriez","word_nosc":"decideriez","lemma":"décider","pos":"VER"} ,
		{"word":"déciderions","word_nosc":"deciderions","lemma":"décider","pos":"VER"} ,
		{"word":"déciderons","word_nosc":"deciderons","lemma":"décider","pos":"VER"} ,
		{"word":"décideront","word_nosc":"decideront","lemma":"décider","pos":"VER"} ,
		{"word":"décides","word_nosc":"decides","lemma":"décider","pos":"VER"} ,
		{"word":"décidez","word_nosc":"decidez","lemma":"décider","pos":"VER"} ,
		{"word":"décidiez","word_nosc":"decidiez","lemma":"décider","pos":"VER"} ,
		{"word":"décidions","word_nosc":"decidions","lemma":"décider","pos":"VER"} ,
		{"word":"décidons","word_nosc":"decidons","lemma":"décider","pos":"VER"} ,
		{"word":"décidâmes","word_nosc":"decidames","lemma":"décider","pos":"VER"} ,
		{"word":"décidât","word_nosc":"decidat","lemma":"décider","pos":"VER"} ,
		{"word":"décidèrent","word_nosc":"deciderent","lemma":"décider","pos":"VER"} ,
		{"word":"décidé","word_nosc":"decide","lemma":"décider","pos":"VER"} ,
		{"word":"décidée","word_nosc":"decidee","lemma":"décider","pos":"VER"} ,
		{"word":"décidées","word_nosc":"decidees","lemma":"décider","pos":"VER"} ,
		{"word":"décidés","word_nosc":"decides","lemma":"décider","pos":"VER"} ,
		{"word":"décima","word_nosc":"decima","lemma":"décimer","pos":"VER"} ,
		{"word":"décimaient","word_nosc":"decimaient","lemma":"décimer","pos":"VER"} ,
		{"word":"décimait","word_nosc":"decimait","lemma":"décimer","pos":"VER"} ,
		{"word":"décimant","word_nosc":"decimant","lemma":"décimer","pos":"VER"} ,
		{"word":"décime","word_nosc":"decime","lemma":"décimer","pos":"VER"} ,
		{"word":"déciment","word_nosc":"deciment","lemma":"décimer","pos":"VER"} ,
		{"word":"décimer","word_nosc":"decimer","lemma":"décimer","pos":"VER"} ,
		{"word":"décimera","word_nosc":"decimera","lemma":"décimer","pos":"VER"} ,
		{"word":"décimerai","word_nosc":"decimerai","lemma":"décimer","pos":"VER"} ,
		{"word":"décimerait","word_nosc":"decimerait","lemma":"décimer","pos":"VER"} ,
		{"word":"décimeront","word_nosc":"decimeront","lemma":"décimer","pos":"VER"} ,
		{"word":"décimèrent","word_nosc":"decimerent","lemma":"décimer","pos":"VER"} ,
		{"word":"décimé","word_nosc":"decime","lemma":"décimer","pos":"VER"} ,
		{"word":"décimée","word_nosc":"decimee","lemma":"décimer","pos":"VER"} ,
		{"word":"décimées","word_nosc":"decimees","lemma":"décimer","pos":"VER"} ,
		{"word":"décimés","word_nosc":"decimes","lemma":"décimer","pos":"VER"} ,
		{"word":"décivilisés","word_nosc":"decivilises","lemma":"déciviliser","pos":"VER"} ,
		{"word":"déclama","word_nosc":"declama","lemma":"déclamer","pos":"VER"} ,
		{"word":"déclamai","word_nosc":"declamai","lemma":"déclamer","pos":"VER"} ,
		{"word":"déclamaient","word_nosc":"declamaient","lemma":"déclamer","pos":"VER"} ,
		{"word":"déclamais","word_nosc":"declamais","lemma":"déclamer","pos":"VER"} ,
		{"word":"déclamait","word_nosc":"declamait","lemma":"déclamer","pos":"VER"} ,
		{"word":"déclamant","word_nosc":"declamant","lemma":"déclamer","pos":"VER"} ,
		{"word":"déclame","word_nosc":"declame","lemma":"déclamer","pos":"VER"} ,
		{"word":"déclamer","word_nosc":"declamer","lemma":"déclamer","pos":"VER"} ,
		{"word":"déclamera","word_nosc":"declamera","lemma":"déclamer","pos":"VER"} ,
		{"word":"déclamerait","word_nosc":"declamerait","lemma":"déclamer","pos":"VER"} ,
		{"word":"déclamât","word_nosc":"declamat","lemma":"déclamer","pos":"VER"} ,
		{"word":"déclamé","word_nosc":"declame","lemma":"déclamer","pos":"VER"} ,
		{"word":"déclamée","word_nosc":"declamee","lemma":"déclamer","pos":"VER"} ,
		{"word":"déclanche","word_nosc":"declanche","lemma":"déclancher","pos":"VER"} ,
		{"word":"déclancher","word_nosc":"declancher","lemma":"déclancher","pos":"VER"} ,
		{"word":"déclanchera","word_nosc":"declanchera","lemma":"déclancher","pos":"VER"} ,
		{"word":"déclanché","word_nosc":"declanche","lemma":"déclancher","pos":"VER"} ,
		{"word":"déclara","word_nosc":"declara","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarai","word_nosc":"declarai","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclaraient","word_nosc":"declaraient","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarais","word_nosc":"declarais","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarait","word_nosc":"declarait","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarant","word_nosc":"declarant","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclare","word_nosc":"declare","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarent","word_nosc":"declarent","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarer","word_nosc":"declarer","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarera","word_nosc":"declarera","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarerai","word_nosc":"declarerai","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarerais","word_nosc":"declarerais","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarerait","word_nosc":"declarerait","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclareriez","word_nosc":"declareriez","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarerions","word_nosc":"declarerions","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarerons","word_nosc":"declarerons","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclareront","word_nosc":"declareront","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclares","word_nosc":"declares","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarez","word_nosc":"declarez","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclariez","word_nosc":"declariez","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarions","word_nosc":"declarions","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarons","word_nosc":"declarons","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarât","word_nosc":"declarat","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarèrent","word_nosc":"declarerent","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclaré","word_nosc":"declare","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarée","word_nosc":"declaree","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarées","word_nosc":"declarees","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclarés","word_nosc":"declares","lemma":"déclarer","pos":"VER"} ,
		{"word":"déclasse","word_nosc":"declasse","lemma":"déclasser","pos":"VER"} ,
		{"word":"déclasser","word_nosc":"declasser","lemma":"déclasser","pos":"VER"} ,
		{"word":"déclassé","word_nosc":"declasse","lemma":"déclasser","pos":"VER"} ,
		{"word":"déclassée","word_nosc":"declassee","lemma":"déclasser","pos":"VER"} ,
		{"word":"déclassées","word_nosc":"declassees","lemma":"déclasser","pos":"VER"} ,
		{"word":"déclavetées","word_nosc":"declavetees","lemma":"déclaveter","pos":"VER"} ,
		{"word":"déclencha","word_nosc":"declencha","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenchai","word_nosc":"declenchai","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenchaient","word_nosc":"declenchaient","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenchait","word_nosc":"declenchait","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenchant","word_nosc":"declenchant","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenche","word_nosc":"declenche","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenchent","word_nosc":"declenchent","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclencher","word_nosc":"declencher","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenchera","word_nosc":"declenchera","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclencherai","word_nosc":"declencherai","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclencheraient","word_nosc":"declencheraient","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclencherais","word_nosc":"declencherais","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclencherait","word_nosc":"declencherait","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclencherez","word_nosc":"declencherez","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclencherons","word_nosc":"declencherons","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclencheront","word_nosc":"declencheront","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenchez","word_nosc":"declenchez","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenchions","word_nosc":"declenchions","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenchons","word_nosc":"declenchons","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenchât","word_nosc":"declenchat","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenchèrent","word_nosc":"declencherent","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenché","word_nosc":"declenche","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenchée","word_nosc":"declenchee","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenchées","word_nosc":"declenchees","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclenchés","word_nosc":"declenches","lemma":"déclencher","pos":"VER"} ,
		{"word":"déclina","word_nosc":"declina","lemma":"décliner","pos":"VER"} ,
		{"word":"déclinai","word_nosc":"declinai","lemma":"décliner","pos":"VER"} ,
		{"word":"déclinaient","word_nosc":"declinaient","lemma":"décliner","pos":"VER"} ,
		{"word":"déclinais","word_nosc":"declinais","lemma":"décliner","pos":"VER"} ,
		{"word":"déclinait","word_nosc":"declinait","lemma":"décliner","pos":"VER"} ,
		{"word":"déclinant","word_nosc":"declinant","lemma":"décliner","pos":"VER"} ,
		{"word":"décline","word_nosc":"decline","lemma":"décliner","pos":"VER"} ,
		{"word":"déclinent","word_nosc":"declinent","lemma":"décliner","pos":"VER"} ,
		{"word":"décliner","word_nosc":"decliner","lemma":"décliner","pos":"VER"} ,
		{"word":"déclinera","word_nosc":"declinera","lemma":"décliner","pos":"VER"} ,
		{"word":"déclines","word_nosc":"declines","lemma":"décliner","pos":"VER"} ,
		{"word":"déclinez","word_nosc":"declinez","lemma":"décliner","pos":"VER"} ,
		{"word":"déclinions","word_nosc":"declinions","lemma":"décliner","pos":"VER"} ,
		{"word":"déclinèrent","word_nosc":"declinerent","lemma":"décliner","pos":"VER"} ,
		{"word":"décliné","word_nosc":"decline","lemma":"décliner","pos":"VER"} ,
		{"word":"déclinée","word_nosc":"declinee","lemma":"décliner","pos":"VER"} ,
		{"word":"déclique","word_nosc":"declique","lemma":"décliquer","pos":"VER"} ,
		{"word":"décliqueté","word_nosc":"decliquete","lemma":"décliqueter","pos":"VER"} ,
		{"word":"décloisonnée","word_nosc":"decloisonnee","lemma":"décloisonner","pos":"VER"} ,
		{"word":"déclose","word_nosc":"declose","lemma":"déclore","pos":"VER"} ,
		{"word":"déclouer","word_nosc":"declouer","lemma":"déclouer","pos":"VER"} ,
		{"word":"déclouèrent","word_nosc":"declouerent","lemma":"déclouer","pos":"VER"} ,
		{"word":"décloué","word_nosc":"decloue","lemma":"déclouer","pos":"VER"} ,
		{"word":"déclouée","word_nosc":"declouee","lemma":"déclouer","pos":"VER"} ,
		{"word":"déclouées","word_nosc":"declouees","lemma":"déclouer","pos":"VER"} ,
		{"word":"décocha","word_nosc":"decocha","lemma":"décocher","pos":"VER"} ,
		{"word":"décochai","word_nosc":"decochai","lemma":"décocher","pos":"VER"} ,
		{"word":"décochaient","word_nosc":"decochaient","lemma":"décocher","pos":"VER"} ,
		{"word":"décochais","word_nosc":"decochais","lemma":"décocher","pos":"VER"} ,
		{"word":"décochait","word_nosc":"decochait","lemma":"décocher","pos":"VER"} ,
		{"word":"décochant","word_nosc":"decochant","lemma":"décocher","pos":"VER"} ,
		{"word":"décoche","word_nosc":"decoche","lemma":"décocher","pos":"VER"} ,
		{"word":"décochent","word_nosc":"decochent","lemma":"décocher","pos":"VER"} ,
		{"word":"décocher","word_nosc":"decocher","lemma":"décocher","pos":"VER"} ,
		{"word":"décochera","word_nosc":"decochera","lemma":"décocher","pos":"VER"} ,
		{"word":"décochez","word_nosc":"decochez","lemma":"décocher","pos":"VER"} ,
		{"word":"décoché","word_nosc":"decoche","lemma":"décocher","pos":"VER"} ,
		{"word":"décochée","word_nosc":"decochee","lemma":"décocher","pos":"VER"} ,
		{"word":"décochées","word_nosc":"decochees","lemma":"décocher","pos":"VER"} ,
		{"word":"décoconne","word_nosc":"decoconne","lemma":"décoconner","pos":"VER"} ,
		{"word":"décoconnerai","word_nosc":"decoconnerai","lemma":"décoconner","pos":"VER"} ,
		{"word":"décodait","word_nosc":"decodait","lemma":"décoder","pos":"VER"} ,
		{"word":"décodant","word_nosc":"decodant","lemma":"décoder","pos":"VER"} ,
		{"word":"décode","word_nosc":"decode","lemma":"décoder","pos":"VER"} ,
		{"word":"décodent","word_nosc":"decodent","lemma":"décoder","pos":"VER"} ,
		{"word":"décoder","word_nosc":"decoder","lemma":"décoder","pos":"VER"} ,
		{"word":"décoderai","word_nosc":"decoderai","lemma":"décoder","pos":"VER"} ,
		{"word":"décodez","word_nosc":"decodez","lemma":"décoder","pos":"VER"} ,
		{"word":"décodons","word_nosc":"decodons","lemma":"décoder","pos":"VER"} ,
		{"word":"décodé","word_nosc":"decode","lemma":"décoder","pos":"VER"} ,
		{"word":"décodée","word_nosc":"decodee","lemma":"décoder","pos":"VER"} ,
		{"word":"décodées","word_nosc":"decodees","lemma":"décoder","pos":"VER"} ,
		{"word":"décodés","word_nosc":"decodes","lemma":"décoder","pos":"VER"} ,
		{"word":"décoffrés","word_nosc":"decoffres","lemma":"décoffrer","pos":"VER"} ,
		{"word":"décoiffa","word_nosc":"decoiffa","lemma":"décoiffer","pos":"VER"} ,
		{"word":"décoiffait","word_nosc":"decoiffait","lemma":"décoiffer","pos":"VER"} ,
		{"word":"décoiffe","word_nosc":"decoiffe","lemma":"décoiffer","pos":"VER"} ,
		{"word":"décoiffer","word_nosc":"decoiffer","lemma":"décoiffer","pos":"VER"} ,
		{"word":"décoiffera","word_nosc":"decoiffera","lemma":"décoiffer","pos":"VER"} ,
		{"word":"décoifferais","word_nosc":"decoifferais","lemma":"décoiffer","pos":"VER"} ,
		{"word":"décoifferait","word_nosc":"decoifferait","lemma":"décoiffer","pos":"VER"} ,
		{"word":"décoiffes","word_nosc":"decoiffes","lemma":"décoiffer","pos":"VER"} ,
		{"word":"décoiffez","word_nosc":"decoiffez","lemma":"décoiffer","pos":"VER"} ,
		{"word":"décoiffé","word_nosc":"decoiffe","lemma":"décoiffer","pos":"VER"} ,
		{"word":"décoiffée","word_nosc":"decoiffee","lemma":"décoiffer","pos":"VER"} ,
		{"word":"décoiffées","word_nosc":"decoiffees","lemma":"décoiffer","pos":"VER"} ,
		{"word":"décoiffés","word_nosc":"decoiffes","lemma":"décoiffer","pos":"VER"} ,
		{"word":"décoince","word_nosc":"decoince","lemma":"décoincer","pos":"VER"} ,
		{"word":"décoincer","word_nosc":"decoincer","lemma":"décoincer","pos":"VER"} ,
		{"word":"décoincera","word_nosc":"decoincera","lemma":"décoincer","pos":"VER"} ,
		{"word":"décoincerait","word_nosc":"decoincerait","lemma":"décoincer","pos":"VER"} ,
		{"word":"décoinces","word_nosc":"decoinces","lemma":"décoincer","pos":"VER"} ,
		{"word":"décoincez","word_nosc":"decoincez","lemma":"décoincer","pos":"VER"} ,
		{"word":"décoincé","word_nosc":"decoince","lemma":"décoincer","pos":"VER"} ,
		{"word":"décoinça","word_nosc":"decoinca","lemma":"décoincer","pos":"VER"} ,
		{"word":"décoinçant","word_nosc":"decoincant","lemma":"décoincer","pos":"VER"} ,
		{"word":"décolla","word_nosc":"decolla","lemma":"décoller","pos":"VER"} ,
		{"word":"décollai","word_nosc":"decollai","lemma":"décoller","pos":"VER"} ,
		{"word":"décollaient","word_nosc":"decollaient","lemma":"décoller","pos":"VER"} ,
		{"word":"décollais","word_nosc":"decollais","lemma":"décoller","pos":"VER"} ,
		{"word":"décollait","word_nosc":"decollait","lemma":"décoller","pos":"VER"} ,
		{"word":"décollant","word_nosc":"decollant","lemma":"décoller","pos":"VER"} ,
		{"word":"décollassions","word_nosc":"decollassions","lemma":"décoller","pos":"VER"} ,
		{"word":"décolle","word_nosc":"decolle","lemma":"décoller","pos":"VER"} ,
		{"word":"décollent","word_nosc":"decollent","lemma":"décoller","pos":"VER"} ,
		{"word":"décoller","word_nosc":"decoller","lemma":"décoller","pos":"VER"} ,
		{"word":"décollera","word_nosc":"decollera","lemma":"décoller","pos":"VER"} ,
		{"word":"décollerai","word_nosc":"decollerai","lemma":"décoller","pos":"VER"} ,
		{"word":"décollerait","word_nosc":"decollerait","lemma":"décoller","pos":"VER"} ,
		{"word":"décolleras","word_nosc":"decolleras","lemma":"décoller","pos":"VER"} ,
		{"word":"décollerons","word_nosc":"decollerons","lemma":"décoller","pos":"VER"} ,
		{"word":"décolleront","word_nosc":"decolleront","lemma":"décoller","pos":"VER"} ,
		{"word":"décolles","word_nosc":"decolles","lemma":"décoller","pos":"VER"} ,
		{"word":"décolleter","word_nosc":"decolleter","lemma":"décolleter","pos":"VER"} ,
		{"word":"décolleté","word_nosc":"decollete","lemma":"décolleter","pos":"VER"} ,
		{"word":"décolletée","word_nosc":"decolletee","lemma":"décolleter","pos":"VER"} ,
		{"word":"décolletées","word_nosc":"decolletees","lemma":"décolleter","pos":"VER"} ,
		{"word":"décollez","word_nosc":"decollez","lemma":"décoller","pos":"VER"} ,
		{"word":"décollons","word_nosc":"decollons","lemma":"décoller","pos":"VER"} ,
		{"word":"décollèrent","word_nosc":"decollerent","lemma":"décoller","pos":"VER"} ,
		{"word":"décollé","word_nosc":"decolle","lemma":"décoller","pos":"VER"} ,
		{"word":"décollée","word_nosc":"decollee","lemma":"décoller","pos":"VER"} ,
		{"word":"décollées","word_nosc":"decollees","lemma":"décoller","pos":"VER"} ,
		{"word":"décollés","word_nosc":"decolles","lemma":"décoller","pos":"VER"} ,
		{"word":"décolonise","word_nosc":"decolonise","lemma":"décoloniser","pos":"VER"} ,
		{"word":"décolora","word_nosc":"decolora","lemma":"décolorer","pos":"VER"} ,
		{"word":"décoloraient","word_nosc":"decoloraient","lemma":"décolorer","pos":"VER"} ,
		{"word":"décolorait","word_nosc":"decolorait","lemma":"décolorer","pos":"VER"} ,
		{"word":"décolorant","word_nosc":"decolorant","lemma":"décolorer","pos":"VER"} ,
		{"word":"décolore","word_nosc":"decolore","lemma":"décolorer","pos":"VER"} ,
		{"word":"décolorent","word_nosc":"decolorent","lemma":"décolorer","pos":"VER"} ,
		{"word":"décolorer","word_nosc":"decolorer","lemma":"décolorer","pos":"VER"} ,
		{"word":"décoloré","word_nosc":"decolore","lemma":"décolorer","pos":"VER"} ,
		{"word":"décolorée","word_nosc":"decoloree","lemma":"décolorer","pos":"VER"} ,
		{"word":"décolorées","word_nosc":"decolorees","lemma":"décolorer","pos":"VER"} ,
		{"word":"décolorés","word_nosc":"decolores","lemma":"décolorer","pos":"VER"} ,
		{"word":"décolère","word_nosc":"decolere","lemma":"décolérer","pos":"VER"} ,
		{"word":"décoléraient","word_nosc":"decoleraient","lemma":"décolérer","pos":"VER"} ,
		{"word":"décolérais","word_nosc":"decolerais","lemma":"décolérer","pos":"VER"} ,
		{"word":"décolérait","word_nosc":"decolerait","lemma":"décolérer","pos":"VER"} ,
		{"word":"décolérer","word_nosc":"decolerer","lemma":"décolérer","pos":"VER"} ,
		{"word":"décoléré","word_nosc":"decolere","lemma":"décolérer","pos":"VER"} ,
		{"word":"décombre","word_nosc":"decombre","lemma":"décombrer","pos":"VER"} ,
		{"word":"décommanda","word_nosc":"decommanda","lemma":"décommander","pos":"VER"} ,
		{"word":"décommandai","word_nosc":"decommandai","lemma":"décommander","pos":"VER"} ,
		{"word":"décommandais","word_nosc":"decommandais","lemma":"décommander","pos":"VER"} ,
		{"word":"décommandait","word_nosc":"decommandait","lemma":"décommander","pos":"VER"} ,
		{"word":"décommande","word_nosc":"decommande","lemma":"décommander","pos":"VER"} ,
		{"word":"décommander","word_nosc":"decommander","lemma":"décommander","pos":"VER"} ,
		{"word":"décommanderait","word_nosc":"decommanderait","lemma":"décommander","pos":"VER"} ,
		{"word":"décommandes","word_nosc":"decommandes","lemma":"décommander","pos":"VER"} ,
		{"word":"décommandez","word_nosc":"decommandez","lemma":"décommander","pos":"VER"} ,
		{"word":"décommandé","word_nosc":"decommande","lemma":"décommander","pos":"VER"} ,
		{"word":"décommandée","word_nosc":"decommandee","lemma":"décommander","pos":"VER"} ,
		{"word":"décommandées","word_nosc":"decommandees","lemma":"décommander","pos":"VER"} ,
		{"word":"décommandés","word_nosc":"decommandes","lemma":"décommander","pos":"VER"} ,
		{"word":"décompenser","word_nosc":"decompenser","lemma":"décompenser","pos":"VER"} ,
		{"word":"décompensé","word_nosc":"decompense","lemma":"décompenser","pos":"VER"} ,
		{"word":"décompliquer","word_nosc":"decompliquer","lemma":"décompliquer","pos":"VER"} ,
		{"word":"décomposa","word_nosc":"decomposa","lemma":"décomposer","pos":"VER"} ,
		{"word":"décomposaient","word_nosc":"decomposaient","lemma":"décomposer","pos":"VER"} ,
		{"word":"décomposais","word_nosc":"decomposais","lemma":"décomposer","pos":"VER"} ,
		{"word":"décomposait","word_nosc":"decomposait","lemma":"décomposer","pos":"VER"} ,
		{"word":"décomposant","word_nosc":"decomposant","lemma":"décomposer","pos":"VER"} ,
		{"word":"décompose","word_nosc":"decompose","lemma":"décomposer","pos":"VER"} ,
		{"word":"décomposent","word_nosc":"decomposent","lemma":"décomposer","pos":"VER"} ,
		{"word":"décomposer","word_nosc":"decomposer","lemma":"décomposer","pos":"VER"} ,
		{"word":"décomposera","word_nosc":"decomposera","lemma":"décomposer","pos":"VER"} ,
		{"word":"décomposeraient","word_nosc":"decomposeraient","lemma":"décomposer","pos":"VER"} ,
		{"word":"décomposerait","word_nosc":"decomposerait","lemma":"décomposer","pos":"VER"} ,
		{"word":"décomposé","word_nosc":"decompose","lemma":"décomposer","pos":"VER"} ,
		{"word":"décomposée","word_nosc":"decomposee","lemma":"décomposer","pos":"VER"} ,
		{"word":"décomposées","word_nosc":"decomposees","lemma":"décomposer","pos":"VER"} ,
		{"word":"décomposés","word_nosc":"decomposes","lemma":"décomposer","pos":"VER"} ,
		{"word":"décompressa","word_nosc":"decompressa","lemma":"décompresser","pos":"VER"} ,
		{"word":"décompresse","word_nosc":"decompresse","lemma":"décompresser","pos":"VER"} ,
		{"word":"décompresser","word_nosc":"decompresser","lemma":"décompresser","pos":"VER"} ,
		{"word":"décompressez","word_nosc":"decompressez","lemma":"décompresser","pos":"VER"} ,
		{"word":"décompressé","word_nosc":"decompresse","lemma":"décompresser","pos":"VER"} ,
		{"word":"décomprimer","word_nosc":"decomprimer","lemma":"décomprimer","pos":"VER"} ,
		{"word":"décompte","word_nosc":"decompte","lemma":"décompter","pos":"VER"} ,
		{"word":"décompter","word_nosc":"decompter","lemma":"décompter","pos":"VER"} ,
		{"word":"décompté","word_nosc":"decompte","lemma":"décompter","pos":"VER"} ,
		{"word":"décomptés","word_nosc":"decomptes","lemma":"décompter","pos":"VER"} ,
		{"word":"déconcentrant","word_nosc":"deconcentrant","lemma":"déconcentrer","pos":"VER"} ,
		{"word":"déconcentrent","word_nosc":"deconcentrent","lemma":"déconcentrer","pos":"VER"} ,
		{"word":"déconcentrer","word_nosc":"deconcentrer","lemma":"déconcentrer","pos":"VER"} ,
		{"word":"déconcentrez","word_nosc":"deconcentrez","lemma":"déconcentrer","pos":"VER"} ,
		{"word":"déconcentré","word_nosc":"deconcentre","lemma":"déconcentrer","pos":"VER"} ,
		{"word":"déconcentrée","word_nosc":"deconcentree","lemma":"déconcentrer","pos":"VER"} ,
		{"word":"déconcerta","word_nosc":"deconcerta","lemma":"déconcerter","pos":"VER"} ,
		{"word":"déconcertait","word_nosc":"deconcertait","lemma":"déconcerter","pos":"VER"} ,
		{"word":"déconcertant","word_nosc":"deconcertant","lemma":"déconcerter","pos":"VER"} ,
		{"word":"déconcerte","word_nosc":"deconcerte","lemma":"déconcerter","pos":"VER"} ,
		{"word":"déconcertent","word_nosc":"deconcertent","lemma":"déconcerter","pos":"VER"} ,
		{"word":"déconcerter","word_nosc":"deconcerter","lemma":"déconcerter","pos":"VER"} ,
		{"word":"déconcertera","word_nosc":"deconcertera","lemma":"déconcerter","pos":"VER"} ,
		{"word":"déconcertez","word_nosc":"deconcertez","lemma":"déconcerter","pos":"VER"} ,
		{"word":"déconcertèrent","word_nosc":"deconcerterent","lemma":"déconcerter","pos":"VER"} ,
		{"word":"déconcerté","word_nosc":"deconcerte","lemma":"déconcerter","pos":"VER"} ,
		{"word":"déconcertée","word_nosc":"deconcertee","lemma":"déconcerter","pos":"VER"} ,
		{"word":"déconcertés","word_nosc":"deconcertes","lemma":"déconcerter","pos":"VER"} ,
		{"word":"déconditionner","word_nosc":"deconditionner","lemma":"déconditionner","pos":"VER"} ,
		{"word":"déconfire","word_nosc":"deconfire","lemma":"déconfire","pos":"VER"} ,
		{"word":"déconfit","word_nosc":"deconfit","lemma":"déconfire","pos":"VER"} ,
		{"word":"déconfite","word_nosc":"deconfite","lemma":"déconfire","pos":"VER"} ,
		{"word":"déconfits","word_nosc":"deconfits","lemma":"déconfire","pos":"VER"} ,
		{"word":"décongela","word_nosc":"decongela","lemma":"décongeler","pos":"VER"} ,
		{"word":"décongeler","word_nosc":"decongeler","lemma":"décongeler","pos":"VER"} ,
		{"word":"décongelé","word_nosc":"decongele","lemma":"décongeler","pos":"VER"} ,
		{"word":"décongelée","word_nosc":"decongelee","lemma":"décongeler","pos":"VER"} ,
		{"word":"décongestionnant","word_nosc":"decongestionnant","lemma":"décongestionner","pos":"VER"} ,
		{"word":"décongestionne","word_nosc":"decongestionne","lemma":"décongestionner","pos":"VER"} ,
		{"word":"décongestionner","word_nosc":"decongestionner","lemma":"décongestionner","pos":"VER"} ,
		{"word":"décongestionné","word_nosc":"decongestionne","lemma":"décongestionner","pos":"VER"} ,
		{"word":"décongèlera","word_nosc":"decongelera","lemma":"décongeler","pos":"VER"} ,
		{"word":"déconnaient","word_nosc":"deconnaient","lemma":"déconner","pos":"VER"} ,
		{"word":"déconnais","word_nosc":"deconnais","lemma":"déconner","pos":"VER"} ,
		{"word":"déconnait","word_nosc":"deconnait","lemma":"déconner","pos":"VER"} ,
		{"word":"déconnant","word_nosc":"deconnant","lemma":"déconner","pos":"VER"} ,
		{"word":"déconne","word_nosc":"deconne","lemma":"déconner","pos":"VER"} ,
		{"word":"déconnectaient","word_nosc":"deconnectaient","lemma":"déconnecter","pos":"VER"} ,
		{"word":"déconnectant","word_nosc":"deconnectant","lemma":"déconnecter","pos":"VER"} ,
		{"word":"déconnecte","word_nosc":"deconnecte","lemma":"déconnecter","pos":"VER"} ,
		{"word":"déconnectent","word_nosc":"deconnectent","lemma":"déconnecter","pos":"VER"} ,
		{"word":"déconnecter","word_nosc":"deconnecter","lemma":"déconnecter","pos":"VER"} ,
		{"word":"déconnectez","word_nosc":"deconnectez","lemma":"déconnecter","pos":"VER"} ,
		{"word":"déconnectiez","word_nosc":"deconnectiez","lemma":"déconnecter","pos":"VER"} ,
		{"word":"déconnectons","word_nosc":"deconnectons","lemma":"déconnecter","pos":"VER"} ,
		{"word":"déconnecté","word_nosc":"deconnecte","lemma":"déconnecter","pos":"VER"} ,
		{"word":"déconnectée","word_nosc":"deconnectee","lemma":"déconnecter","pos":"VER"} ,
		{"word":"déconnectés","word_nosc":"deconnectes","lemma":"déconnecter","pos":"VER"} ,
		{"word":"déconnent","word_nosc":"deconnent","lemma":"déconner","pos":"VER"} ,
		{"word":"déconner","word_nosc":"deconner","lemma":"déconner","pos":"VER"} ,
		{"word":"déconnerai","word_nosc":"deconnerai","lemma":"déconner","pos":"VER"} ,
		{"word":"déconnerais","word_nosc":"deconnerais","lemma":"déconner","pos":"VER"} ,
		{"word":"déconnes","word_nosc":"deconnes","lemma":"déconner","pos":"VER"} ,
		{"word":"déconnez","word_nosc":"deconnez","lemma":"déconner","pos":"VER"} ,
		{"word":"déconné","word_nosc":"deconne","lemma":"déconner","pos":"VER"} ,
		{"word":"déconseilla","word_nosc":"deconseilla","lemma":"déconseiller","pos":"VER"} ,
		{"word":"déconseillai","word_nosc":"deconseillai","lemma":"déconseiller","pos":"VER"} ,
		{"word":"déconseillait","word_nosc":"deconseillait","lemma":"déconseiller","pos":"VER"} ,
		{"word":"déconseillant","word_nosc":"deconseillant","lemma":"déconseiller","pos":"VER"} ,
		{"word":"déconseille","word_nosc":"deconseille","lemma":"déconseiller","pos":"VER"} ,
		{"word":"déconseiller","word_nosc":"deconseiller","lemma":"déconseiller","pos":"VER"} ,
		{"word":"déconseillerais","word_nosc":"deconseillerais","lemma":"déconseiller","pos":"VER"} ,
		{"word":"déconseilles","word_nosc":"deconseilles","lemma":"déconseiller","pos":"VER"} ,
		{"word":"déconseillèrent","word_nosc":"deconseillerent","lemma":"déconseiller","pos":"VER"} ,
		{"word":"déconseillé","word_nosc":"deconseille","lemma":"déconseiller","pos":"VER"} ,
		{"word":"déconseillée","word_nosc":"deconseillee","lemma":"déconseiller","pos":"VER"} ,
		{"word":"déconseillées","word_nosc":"deconseillees","lemma":"déconseiller","pos":"VER"} ,
		{"word":"déconsidèrent","word_nosc":"deconsiderent","lemma":"déconsidérer","pos":"VER"} ,
		{"word":"déconsidéraient","word_nosc":"deconsideraient","lemma":"déconsidérer","pos":"VER"} ,
		{"word":"déconsidérait","word_nosc":"deconsiderait","lemma":"déconsidérer","pos":"VER"} ,
		{"word":"déconsidérant","word_nosc":"deconsiderant","lemma":"déconsidérer","pos":"VER"} ,
		{"word":"déconsidérer","word_nosc":"deconsiderer","lemma":"déconsidérer","pos":"VER"} ,
		{"word":"déconsidéré","word_nosc":"deconsidere","lemma":"déconsidérer","pos":"VER"} ,
		{"word":"déconsidérée","word_nosc":"deconsideree","lemma":"déconsidérer","pos":"VER"} ,
		{"word":"déconsidérés","word_nosc":"deconsideres","lemma":"déconsidérer","pos":"VER"} ,
		{"word":"déconsigner","word_nosc":"deconsigner","lemma":"déconsigner","pos":"VER"} ,
		{"word":"déconstipe","word_nosc":"deconstipe","lemma":"déconstiper","pos":"VER"} ,
		{"word":"déconstiper","word_nosc":"deconstiper","lemma":"déconstiper","pos":"VER"} ,
		{"word":"déconstruit","word_nosc":"deconstruit","lemma":"déconstruire","pos":"VER"} ,
		{"word":"décontaminant","word_nosc":"decontaminant","lemma":"décontaminer","pos":"VER"} ,
		{"word":"décontaminer","word_nosc":"decontaminer","lemma":"décontaminer","pos":"VER"} ,
		{"word":"décontaminé","word_nosc":"decontamine","lemma":"décontaminer","pos":"VER"} ,
		{"word":"décontenancer","word_nosc":"decontenancer","lemma":"décontenancer","pos":"VER"} ,
		{"word":"décontenancé","word_nosc":"decontenance","lemma":"décontenancer","pos":"VER"} ,
		{"word":"décontenancée","word_nosc":"decontenancee","lemma":"décontenancer","pos":"VER"} ,
		{"word":"décontenancés","word_nosc":"decontenances","lemma":"décontenancer","pos":"VER"} ,
		{"word":"décontenança","word_nosc":"decontenanca","lemma":"décontenancer","pos":"VER"} ,
		{"word":"décontenançaient","word_nosc":"decontenancaient","lemma":"décontenancer","pos":"VER"} ,
		{"word":"décontenançait","word_nosc":"decontenancait","lemma":"décontenancer","pos":"VER"} ,
		{"word":"décontracta","word_nosc":"decontracta","lemma":"décontracter","pos":"VER"} ,
		{"word":"décontractaient","word_nosc":"decontractaient","lemma":"décontracter","pos":"VER"} ,
		{"word":"décontractait","word_nosc":"decontractait","lemma":"décontracter","pos":"VER"} ,
		{"word":"décontractant","word_nosc":"decontractant","lemma":"décontracter","pos":"VER"} ,
		{"word":"décontracte","word_nosc":"decontracte","lemma":"décontracter","pos":"VER"} ,
		{"word":"décontracter","word_nosc":"decontracter","lemma":"décontracter","pos":"VER"} ,
		{"word":"décontractez","word_nosc":"decontractez","lemma":"décontracter","pos":"VER"} ,
		{"word":"décontracté","word_nosc":"decontracte","lemma":"décontracter","pos":"VER"} ,
		{"word":"décontractée","word_nosc":"decontractee","lemma":"décontracter","pos":"VER"} ,
		{"word":"décontractés","word_nosc":"decontractes","lemma":"décontracter","pos":"VER"} ,
		{"word":"décora","word_nosc":"decora","lemma":"décorer","pos":"VER"} ,
		{"word":"décorai","word_nosc":"decorai","lemma":"décorer","pos":"VER"} ,
		{"word":"décoraient","word_nosc":"decoraient","lemma":"décorer","pos":"VER"} ,
		{"word":"décorais","word_nosc":"decorais","lemma":"décorer","pos":"VER"} ,
		{"word":"décorait","word_nosc":"decorait","lemma":"décorer","pos":"VER"} ,
		{"word":"décorant","word_nosc":"decorant","lemma":"décorer","pos":"VER"} ,
		{"word":"décore","word_nosc":"decore","lemma":"décorer","pos":"VER"} ,
		{"word":"décorent","word_nosc":"decorent","lemma":"décorer","pos":"VER"} ,
		{"word":"décorer","word_nosc":"decorer","lemma":"décorer","pos":"VER"} ,
		{"word":"décorera","word_nosc":"decorera","lemma":"décorer","pos":"VER"} ,
		{"word":"décorerait","word_nosc":"decorerait","lemma":"décorer","pos":"VER"} ,
		{"word":"décoreront","word_nosc":"decoreront","lemma":"décorer","pos":"VER"} ,
		{"word":"décorez","word_nosc":"decorez","lemma":"décorer","pos":"VER"} ,
		{"word":"décorne","word_nosc":"decorne","lemma":"décorner","pos":"VER"} ,
		{"word":"décorner","word_nosc":"decorner","lemma":"décorner","pos":"VER"} ,
		{"word":"décortiqua","word_nosc":"decortiqua","lemma":"décortiquer","pos":"VER"} ,
		{"word":"décortiquaient","word_nosc":"decortiquaient","lemma":"décortiquer","pos":"VER"} ,
		{"word":"décortiquait","word_nosc":"decortiquait","lemma":"décortiquer","pos":"VER"} ,
		{"word":"décortiquant","word_nosc":"decortiquant","lemma":"décortiquer","pos":"VER"} ,
		{"word":"décortique","word_nosc":"decortique","lemma":"décortiquer","pos":"VER"} ,
		{"word":"décortiquent","word_nosc":"decortiquent","lemma":"décortiquer","pos":"VER"} ,
		{"word":"décortiquer","word_nosc":"decortiquer","lemma":"décortiquer","pos":"VER"} ,
		{"word":"décortiquez","word_nosc":"decortiquez","lemma":"décortiquer","pos":"VER"} ,
		{"word":"décortiqué","word_nosc":"decortique","lemma":"décortiquer","pos":"VER"} ,
		{"word":"décortiquée","word_nosc":"decortiquee","lemma":"décortiquer","pos":"VER"} ,
		{"word":"décortiquées","word_nosc":"decortiquees","lemma":"décortiquer","pos":"VER"} ,
		{"word":"décortiqués","word_nosc":"decortiques","lemma":"décortiquer","pos":"VER"} ,
		{"word":"décorât","word_nosc":"decorat","lemma":"décorer","pos":"VER"} ,
		{"word":"décorèrent","word_nosc":"decorerent","lemma":"décorer","pos":"VER"} ,
		{"word":"décoré","word_nosc":"decore","lemma":"décorer","pos":"VER"} ,
		{"word":"décorée","word_nosc":"decoree","lemma":"décorer","pos":"VER"} ,
		{"word":"décorées","word_nosc":"decorees","lemma":"décorer","pos":"VER"} ,
		{"word":"décorés","word_nosc":"decores","lemma":"décorer","pos":"VER"} ,
		{"word":"découcha","word_nosc":"decoucha","lemma":"découcher","pos":"VER"} ,
		{"word":"découchaient","word_nosc":"decouchaient","lemma":"découcher","pos":"VER"} ,
		{"word":"découche","word_nosc":"decouche","lemma":"découcher","pos":"VER"} ,
		{"word":"découcher","word_nosc":"decoucher","lemma":"découcher","pos":"VER"} ,
		{"word":"découches","word_nosc":"decouches","lemma":"découcher","pos":"VER"} ,
		{"word":"découché","word_nosc":"decouche","lemma":"découcher","pos":"VER"} ,
		{"word":"découd","word_nosc":"decoud","lemma":"découdre","pos":"VER"} ,
		{"word":"découdre","word_nosc":"decoudre","lemma":"découdre","pos":"VER"} ,
		{"word":"découlaient","word_nosc":"decoulaient","lemma":"découler","pos":"VER"} ,
		{"word":"découlait","word_nosc":"decoulait","lemma":"découler","pos":"VER"} ,
		{"word":"découlant","word_nosc":"decoulant","lemma":"découler","pos":"VER"} ,
		{"word":"découle","word_nosc":"decoule","lemma":"découler","pos":"VER"} ,
		{"word":"découlent","word_nosc":"decoulent","lemma":"découler","pos":"VER"} ,
		{"word":"découler","word_nosc":"decouler","lemma":"découler","pos":"VER"} ,
		{"word":"découlera","word_nosc":"decoulera","lemma":"découler","pos":"VER"} ,
		{"word":"découlerait","word_nosc":"decoulerait","lemma":"découler","pos":"VER"} ,
		{"word":"découlèrent","word_nosc":"decoulerent","lemma":"découler","pos":"VER"} ,
		{"word":"découlé","word_nosc":"decoule","lemma":"découler","pos":"VER"} ,
		{"word":"découpa","word_nosc":"decoupa","lemma":"découper","pos":"VER"} ,
		{"word":"découpai","word_nosc":"decoupai","lemma":"découper","pos":"VER"} ,
		{"word":"découpaient","word_nosc":"decoupaient","lemma":"découper","pos":"VER"} ,
		{"word":"découpais","word_nosc":"decoupais","lemma":"découper","pos":"VER"} ,
		{"word":"découpait","word_nosc":"decoupait","lemma":"découper","pos":"VER"} ,
		{"word":"découpant","word_nosc":"decoupant","lemma":"découper","pos":"VER"} ,
		{"word":"découpe","word_nosc":"decoupe","lemma":"découper","pos":"VER"} ,
		{"word":"découpent","word_nosc":"decoupent","lemma":"découper","pos":"VER"} ,
		{"word":"découper","word_nosc":"decouper","lemma":"découper","pos":"VER"} ,
		{"word":"découpera","word_nosc":"decoupera","lemma":"découper","pos":"VER"} ,
		{"word":"découperai","word_nosc":"decouperai","lemma":"découper","pos":"VER"} ,
		{"word":"découperaient","word_nosc":"decouperaient","lemma":"découper","pos":"VER"} ,
		{"word":"découperais","word_nosc":"decouperais","lemma":"découper","pos":"VER"} ,
		{"word":"découperait","word_nosc":"decouperait","lemma":"découper","pos":"VER"} ,
		{"word":"découperont","word_nosc":"decouperont","lemma":"découper","pos":"VER"} ,
		{"word":"découpes","word_nosc":"decoupes","lemma":"découper","pos":"VER"} ,
		{"word":"découpez","word_nosc":"decoupez","lemma":"découper","pos":"VER"} ,
		{"word":"découpions","word_nosc":"decoupions","lemma":"découper","pos":"VER"} ,
		{"word":"découpler","word_nosc":"decoupler","lemma":"découpler","pos":"VER"} ,
		{"word":"découplons","word_nosc":"decouplons","lemma":"découpler","pos":"VER"} ,
		{"word":"découplé","word_nosc":"decouple","lemma":"découpler","pos":"VER"} ,
		{"word":"découplée","word_nosc":"decouplee","lemma":"découpler","pos":"VER"} ,
		{"word":"découpons","word_nosc":"decoupons","lemma":"découper","pos":"VER"} ,
		{"word":"découpèrent","word_nosc":"decouperent","lemma":"découper","pos":"VER"} ,
		{"word":"découpé","word_nosc":"decoupe","lemma":"découper","pos":"VER"} ,
		{"word":"découpée","word_nosc":"decoupee","lemma":"découper","pos":"VER"} ,
		{"word":"découpées","word_nosc":"decoupees","lemma":"découper","pos":"VER"} ,
		{"word":"découpés","word_nosc":"decoupes","lemma":"découper","pos":"VER"} ,
		{"word":"décourage","word_nosc":"decourage","lemma":"décourager","pos":"VER"} ,
		{"word":"découragea","word_nosc":"decouragea","lemma":"décourager","pos":"VER"} ,
		{"word":"décourageai","word_nosc":"decourageai","lemma":"décourager","pos":"VER"} ,
		{"word":"décourageaient","word_nosc":"decourageaient","lemma":"décourager","pos":"VER"} ,
		{"word":"décourageais","word_nosc":"decourageais","lemma":"décourager","pos":"VER"} ,
		{"word":"décourageait","word_nosc":"decourageait","lemma":"décourager","pos":"VER"} ,
		{"word":"décourageant","word_nosc":"decourageant","lemma":"décourager","pos":"VER"} ,
		{"word":"découragent","word_nosc":"decouragent","lemma":"décourager","pos":"VER"} ,
		{"word":"décourageons","word_nosc":"decourageons","lemma":"décourager","pos":"VER"} ,
		{"word":"décourager","word_nosc":"decourager","lemma":"décourager","pos":"VER"} ,
		{"word":"découragera","word_nosc":"decouragera","lemma":"décourager","pos":"VER"} ,
		{"word":"découragerais","word_nosc":"decouragerais","lemma":"décourager","pos":"VER"} ,
		{"word":"découragerait","word_nosc":"decouragerait","lemma":"décourager","pos":"VER"} ,
		{"word":"décourages","word_nosc":"decourages","lemma":"décourager","pos":"VER"} ,
		{"word":"découragez","word_nosc":"decouragez","lemma":"décourager","pos":"VER"} ,
		{"word":"décourageât","word_nosc":"decourageat","lemma":"décourager","pos":"VER"} ,
		{"word":"découragèrent","word_nosc":"decouragerent","lemma":"décourager","pos":"VER"} ,
		{"word":"découragé","word_nosc":"decourage","lemma":"décourager","pos":"VER"} ,
		{"word":"découragée","word_nosc":"decouragee","lemma":"décourager","pos":"VER"} ,
		{"word":"découragées","word_nosc":"decouragees","lemma":"décourager","pos":"VER"} ,
		{"word":"découragés","word_nosc":"decourages","lemma":"décourager","pos":"VER"} ,
		{"word":"découronné","word_nosc":"decouronne","lemma":"découronner","pos":"VER"} ,
		{"word":"découronnés","word_nosc":"decouronnes","lemma":"découronner","pos":"VER"} ,
		{"word":"décousit","word_nosc":"decousit","lemma":"découdre","pos":"VER"} ,
		{"word":"décousu","word_nosc":"decousu","lemma":"découdre","pos":"VER"} ,
		{"word":"décousue","word_nosc":"decousue","lemma":"découdre","pos":"VER"} ,
		{"word":"décousues","word_nosc":"decousues","lemma":"découdre","pos":"VER"} ,
		{"word":"décousus","word_nosc":"decousus","lemma":"découdre","pos":"VER"} ,
		{"word":"découvert","word_nosc":"decouvert","lemma":"découvrir","pos":"VER"} ,
		{"word":"découverte","word_nosc":"decouverte","lemma":"découverte","pos":"VER"} ,
		{"word":"découvertes","word_nosc":"decouvertes","lemma":"découverte","pos":"VER"} ,
		{"word":"découverts","word_nosc":"decouverts","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvraient","word_nosc":"decouvraient","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrais","word_nosc":"decouvrais","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrait","word_nosc":"decouvrait","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrant","word_nosc":"decouvrant","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvre","word_nosc":"decouvre","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrent","word_nosc":"decouvrent","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvres","word_nosc":"decouvres","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrez","word_nosc":"decouvrez","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvriez","word_nosc":"decouvriez","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrions","word_nosc":"decouvrions","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrir","word_nosc":"decouvrir","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrira","word_nosc":"decouvrira","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrirai","word_nosc":"decouvrirai","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvriraient","word_nosc":"decouvriraient","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrirais","word_nosc":"decouvrirais","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrirait","word_nosc":"decouvrirait","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvriras","word_nosc":"decouvriras","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrirent","word_nosc":"decouvrirent","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrirez","word_nosc":"decouvrirez","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvririez","word_nosc":"decouvririez","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrirons","word_nosc":"decouvrirons","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvriront","word_nosc":"decouvriront","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvris","word_nosc":"decouvris","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrisse","word_nosc":"decouvrisse","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrissent","word_nosc":"decouvrissent","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrit","word_nosc":"decouvrit","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrons","word_nosc":"decouvrons","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrîmes","word_nosc":"decouvrimes","lemma":"découvrir","pos":"VER"} ,
		{"word":"découvrît","word_nosc":"decouvrit","lemma":"découvrir","pos":"VER"} ,
		{"word":"décramponner","word_nosc":"decramponner","lemma":"décramponner","pos":"VER"} ,
		{"word":"décrapouille","word_nosc":"decrapouille","lemma":"décrapouiller","pos":"VER"} ,
		{"word":"décrassait","word_nosc":"decrassait","lemma":"décrasser","pos":"VER"} ,
		{"word":"décrasse","word_nosc":"decrasse","lemma":"décrasser","pos":"VER"} ,
		{"word":"décrasser","word_nosc":"decrasser","lemma":"décrasser","pos":"VER"} ,
		{"word":"décrassé","word_nosc":"decrasse","lemma":"décrasser","pos":"VER"} ,
		{"word":"décrassés","word_nosc":"decrasses","lemma":"décrasser","pos":"VER"} ,
		{"word":"décrie","word_nosc":"decrie","lemma":"décrier","pos":"VER"} ,
		{"word":"décrier","word_nosc":"decrier","lemma":"décrier","pos":"VER"} ,
		{"word":"décries","word_nosc":"decries","lemma":"décrier","pos":"VER"} ,
		{"word":"décriez","word_nosc":"decriez","lemma":"décrier","pos":"VER"} ,
		{"word":"décriminaliser","word_nosc":"decriminaliser","lemma":"décriminaliser","pos":"VER"} ,
		{"word":"décrira","word_nosc":"decrira","lemma":"décrire","pos":"VER"} ,
		{"word":"décrirai","word_nosc":"decrirai","lemma":"décrire","pos":"VER"} ,
		{"word":"décriraient","word_nosc":"decriraient","lemma":"décrire","pos":"VER"} ,
		{"word":"décrirais","word_nosc":"decrirais","lemma":"décrire","pos":"VER"} ,
		{"word":"décrirait","word_nosc":"decrirait","lemma":"décrire","pos":"VER"} ,
		{"word":"décriras","word_nosc":"decriras","lemma":"décrire","pos":"VER"} ,
		{"word":"décrire","word_nosc":"decrire","lemma":"décrire","pos":"VER"} ,
		{"word":"décrirez","word_nosc":"decrirez","lemma":"décrire","pos":"VER"} ,
		{"word":"décririez","word_nosc":"decririez","lemma":"décrire","pos":"VER"} ,
		{"word":"décriront","word_nosc":"decriront","lemma":"décrire","pos":"VER"} ,
		{"word":"décris","word_nosc":"decris","lemma":"décrire","pos":"VER"} ,
		{"word":"décrispe","word_nosc":"decrispe","lemma":"décrisper","pos":"VER"} ,
		{"word":"décrisper","word_nosc":"decrisper","lemma":"décrisper","pos":"VER"} ,
		{"word":"décrispât","word_nosc":"decrispat","lemma":"décrisper","pos":"VER"} ,
		{"word":"décrispé","word_nosc":"decrispe","lemma":"décrisper","pos":"VER"} ,
		{"word":"décrit","word_nosc":"decrit","lemma":"décrire","pos":"VER"} ,
		{"word":"décrite","word_nosc":"decrite","lemma":"décrire","pos":"VER"} ,
		{"word":"décrites","word_nosc":"decrites","lemma":"décrire","pos":"VER"} ,
		{"word":"décrits","word_nosc":"decrits","lemma":"décrire","pos":"VER"} ,
		{"word":"décrivaient","word_nosc":"decrivaient","lemma":"décrire","pos":"VER"} ,
		{"word":"décrivais","word_nosc":"decrivais","lemma":"décrire","pos":"VER"} ,
		{"word":"décrivait","word_nosc":"decrivait","lemma":"décrire","pos":"VER"} ,
		{"word":"décrivant","word_nosc":"decrivant","lemma":"décrire","pos":"VER"} ,
		{"word":"décrive","word_nosc":"decrive","lemma":"décrire","pos":"VER"} ,
		{"word":"décrivent","word_nosc":"decrivent","lemma":"décrire","pos":"VER"} ,
		{"word":"décrives","word_nosc":"decrives","lemma":"décrire","pos":"VER"} ,
		{"word":"décrivez","word_nosc":"decrivez","lemma":"décrire","pos":"VER"} ,
		{"word":"décriviez","word_nosc":"decriviez","lemma":"décrire","pos":"VER"} ,
		{"word":"décrivirent","word_nosc":"decrivirent","lemma":"décrire","pos":"VER"} ,
		{"word":"décrivis","word_nosc":"decrivis","lemma":"décrire","pos":"VER"} ,
		{"word":"décrivit","word_nosc":"decrivit","lemma":"décrire","pos":"VER"} ,
		{"word":"décrié","word_nosc":"decrie","lemma":"décrier","pos":"VER"} ,
		{"word":"décriée","word_nosc":"decriee","lemma":"décrier","pos":"VER"} ,
		{"word":"décrocha","word_nosc":"decrocha","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrochai","word_nosc":"decrochai","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrochaient","word_nosc":"decrochaient","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrochais","word_nosc":"decrochais","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrochait","word_nosc":"decrochait","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrochant","word_nosc":"decrochant","lemma":"décrocher","pos":"VER"} ,
		{"word":"décroche","word_nosc":"decroche","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrochent","word_nosc":"decrochent","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrocher","word_nosc":"decrocher","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrochera","word_nosc":"decrochera","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrocherai","word_nosc":"decrocherai","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrocheraient","word_nosc":"decrocheraient","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrocherais","word_nosc":"decrocherais","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrocherait","word_nosc":"decrocherait","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrocheras","word_nosc":"decrocheras","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrocheront","word_nosc":"decrocheront","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrochez","word_nosc":"decrochez","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrochiez","word_nosc":"decrochiez","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrochons","word_nosc":"decrochons","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrochèrent","word_nosc":"decrocherent","lemma":"décrocher","pos":"VER"} ,
		{"word":"décroché","word_nosc":"decroche","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrochée","word_nosc":"decrochee","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrochées","word_nosc":"decrochees","lemma":"décrocher","pos":"VER"} ,
		{"word":"décrochés","word_nosc":"decroches","lemma":"décrocher","pos":"VER"} ,
		{"word":"décroisa","word_nosc":"decroisa","lemma":"décroiser","pos":"VER"} ,
		{"word":"décroisait","word_nosc":"decroisait","lemma":"décroiser","pos":"VER"} ,
		{"word":"décroisant","word_nosc":"decroisant","lemma":"décroiser","pos":"VER"} ,
		{"word":"décroise","word_nosc":"decroise","lemma":"décroiser","pos":"VER"} ,
		{"word":"décroiser","word_nosc":"decroiser","lemma":"décroiser","pos":"VER"} ,
		{"word":"décroissaient","word_nosc":"decroissaient","lemma":"décroître","pos":"VER"} ,
		{"word":"décroissait","word_nosc":"decroissait","lemma":"décroître","pos":"VER"} ,
		{"word":"décroissant","word_nosc":"decroissant","lemma":"décroître","pos":"VER"} ,
		{"word":"décroisse","word_nosc":"decroisse","lemma":"décroître","pos":"VER"} ,
		{"word":"décroissent","word_nosc":"decroissent","lemma":"décroître","pos":"VER"} ,
		{"word":"décroisé","word_nosc":"decroise","lemma":"décroiser","pos":"VER"} ,
		{"word":"décroisées","word_nosc":"decroisees","lemma":"décroiser","pos":"VER"} ,
		{"word":"décrottait","word_nosc":"decrottait","lemma":"décrotter","pos":"VER"} ,
		{"word":"décrottant","word_nosc":"decrottant","lemma":"décrotter","pos":"VER"} ,
		{"word":"décrotte","word_nosc":"decrotte","lemma":"décrotter","pos":"VER"} ,
		{"word":"décrottent","word_nosc":"decrottent","lemma":"décrotter","pos":"VER"} ,
		{"word":"décrotter","word_nosc":"decrotter","lemma":"décrotter","pos":"VER"} ,
		{"word":"décrottions","word_nosc":"decrottions","lemma":"décrotter","pos":"VER"} ,
		{"word":"décrottée","word_nosc":"decrottee","lemma":"décrotter","pos":"VER"} ,
		{"word":"décrottés","word_nosc":"decrottes","lemma":"décrotter","pos":"VER"} ,
		{"word":"décroît","word_nosc":"decroit","lemma":"décroître","pos":"VER"} ,
		{"word":"décroître","word_nosc":"decroitre","lemma":"décroître","pos":"VER"} ,
		{"word":"décru","word_nosc":"decru","lemma":"décroître","pos":"VER"} ,
		{"word":"décrut","word_nosc":"decrut","lemma":"décroître","pos":"VER"} ,
		{"word":"décryptai","word_nosc":"decryptai","lemma":"décrypter","pos":"VER"} ,
		{"word":"décrypte","word_nosc":"decrypte","lemma":"décrypter","pos":"VER"} ,
		{"word":"décrypter","word_nosc":"decrypter","lemma":"décrypter","pos":"VER"} ,
		{"word":"décrypté","word_nosc":"decrypte","lemma":"décrypter","pos":"VER"} ,
		{"word":"décryptés","word_nosc":"decryptes","lemma":"décrypter","pos":"VER"} ,
		{"word":"décrète","word_nosc":"decrete","lemma":"décréter","pos":"VER"} ,
		{"word":"décrètent","word_nosc":"decretent","lemma":"décréter","pos":"VER"} ,
		{"word":"décrètes","word_nosc":"decretes","lemma":"décréter","pos":"VER"} ,
		{"word":"décrédibiliser","word_nosc":"decredibiliser","lemma":"décrédibiliser","pos":"VER"} ,
		{"word":"décrédibilisez","word_nosc":"decredibilisez","lemma":"décrédibiliser","pos":"VER"} ,
		{"word":"décrépi","word_nosc":"decrepi","lemma":"décrépir","pos":"VER"} ,
		{"word":"décrépie","word_nosc":"decrepie","lemma":"décrépir","pos":"VER"} ,
		{"word":"décrépies","word_nosc":"decrepies","lemma":"décrépir","pos":"VER"} ,
		{"word":"décrépis","word_nosc":"decrepis","lemma":"décrépir","pos":"VER"} ,
		{"word":"décrépit","word_nosc":"decrepit","lemma":"décrépir","pos":"VER"} ,
		{"word":"décréta","word_nosc":"decreta","lemma":"décréter","pos":"VER"} ,
		{"word":"décrétai","word_nosc":"decretai","lemma":"décréter","pos":"VER"} ,
		{"word":"décrétaient","word_nosc":"decretaient","lemma":"décréter","pos":"VER"} ,
		{"word":"décrétais","word_nosc":"decretais","lemma":"décréter","pos":"VER"} ,
		{"word":"décrétait","word_nosc":"decretait","lemma":"décréter","pos":"VER"} ,
		{"word":"décrétant","word_nosc":"decretant","lemma":"décréter","pos":"VER"} ,
		{"word":"décréter","word_nosc":"decreter","lemma":"décréter","pos":"VER"} ,
		{"word":"décrétera","word_nosc":"decretera","lemma":"décréter","pos":"VER"} ,
		{"word":"décréterai","word_nosc":"decreterai","lemma":"décréter","pos":"VER"} ,
		{"word":"décréterais","word_nosc":"decreterais","lemma":"décréter","pos":"VER"} ,
		{"word":"décréterait","word_nosc":"decreterait","lemma":"décréter","pos":"VER"} ,
		{"word":"décréteront","word_nosc":"decreteront","lemma":"décréter","pos":"VER"} ,
		{"word":"décrétez","word_nosc":"decretez","lemma":"décréter","pos":"VER"} ,
		{"word":"décrétons","word_nosc":"decretons","lemma":"décréter","pos":"VER"} ,
		{"word":"décrétèrent","word_nosc":"decreterent","lemma":"décréter","pos":"VER"} ,
		{"word":"décrété","word_nosc":"decrete","lemma":"décréter","pos":"VER"} ,
		{"word":"décrétée","word_nosc":"decretee","lemma":"décréter","pos":"VER"} ,
		{"word":"décrétés","word_nosc":"decretes","lemma":"décréter","pos":"VER"} ,
		{"word":"décrêper","word_nosc":"decreper","lemma":"décrêper","pos":"VER"} ,
		{"word":"décrût","word_nosc":"decrut","lemma":"décroître","pos":"VER"} ,
		{"word":"décuité","word_nosc":"decuite","lemma":"décuiter","pos":"VER"} ,
		{"word":"déculotta","word_nosc":"deculotta","lemma":"déculotter","pos":"VER"} ,
		{"word":"déculottait","word_nosc":"deculottait","lemma":"déculotter","pos":"VER"} ,
		{"word":"déculottant","word_nosc":"deculottant","lemma":"déculotter","pos":"VER"} ,
		{"word":"déculotte","word_nosc":"deculotte","lemma":"déculotter","pos":"VER"} ,
		{"word":"déculotter","word_nosc":"deculotter","lemma":"déculotter","pos":"VER"} ,
		{"word":"déculottera","word_nosc":"deculottera","lemma":"déculotter","pos":"VER"} ,
		{"word":"déculotté","word_nosc":"deculotte","lemma":"déculotter","pos":"VER"} ,
		{"word":"déculpabilisait","word_nosc":"deculpabilisait","lemma":"déculpabiliser","pos":"VER"} ,
		{"word":"déculpabiliser","word_nosc":"deculpabiliser","lemma":"déculpabiliser","pos":"VER"} ,
		{"word":"déculpabilisé","word_nosc":"deculpabilise","lemma":"déculpabiliser","pos":"VER"} ,
		{"word":"décupla","word_nosc":"decupla","lemma":"décupler","pos":"VER"} ,
		{"word":"décuplaient","word_nosc":"decuplaient","lemma":"décupler","pos":"VER"} ,
		{"word":"décuplait","word_nosc":"decuplait","lemma":"décupler","pos":"VER"} ,
		{"word":"décuplant","word_nosc":"decuplant","lemma":"décupler","pos":"VER"} ,
		{"word":"décuple","word_nosc":"decuple","lemma":"décupler","pos":"VER"} ,
		{"word":"décuplent","word_nosc":"decuplent","lemma":"décupler","pos":"VER"} ,
		{"word":"décupler","word_nosc":"decupler","lemma":"décupler","pos":"VER"} ,
		{"word":"décuplera","word_nosc":"decuplera","lemma":"décupler","pos":"VER"} ,
		{"word":"décuplerait","word_nosc":"decuplerait","lemma":"décupler","pos":"VER"} ,
		{"word":"décuplèrent","word_nosc":"decuplerent","lemma":"décupler","pos":"VER"} ,
		{"word":"décuplé","word_nosc":"decuple","lemma":"décupler","pos":"VER"} ,
		{"word":"décuplée","word_nosc":"decuplee","lemma":"décupler","pos":"VER"} ,
		{"word":"décuplées","word_nosc":"decuplees","lemma":"décupler","pos":"VER"} ,
		{"word":"décuplés","word_nosc":"decuples","lemma":"décupler","pos":"VER"} ,
		{"word":"décuver","word_nosc":"decuver","lemma":"décuver","pos":"VER"} ,
		{"word":"décède","word_nosc":"decede","lemma":"décéder","pos":"VER"} ,
		{"word":"décèdent","word_nosc":"decedent","lemma":"décéder","pos":"VER"} ,
		{"word":"décèle","word_nosc":"decele","lemma":"déceler","pos":"VER"} ,
		{"word":"décèlera","word_nosc":"decelera","lemma":"déceler","pos":"VER"} ,
		{"word":"décèlerait","word_nosc":"decelerait","lemma":"déceler","pos":"VER"} ,
		{"word":"décèleront","word_nosc":"deceleront","lemma":"déceler","pos":"VER"} ,
		{"word":"décèles","word_nosc":"deceles","lemma":"déceler","pos":"VER"} ,
		{"word":"décéda","word_nosc":"deceda","lemma":"décéder","pos":"VER"} ,
		{"word":"décédait","word_nosc":"decedait","lemma":"décéder","pos":"VER"} ,
		{"word":"décéder","word_nosc":"deceder","lemma":"décéder","pos":"VER"} ,
		{"word":"décédé","word_nosc":"decede","lemma":"décéder","pos":"VER"} ,
		{"word":"décédée","word_nosc":"decedee","lemma":"décéder","pos":"VER"} ,
		{"word":"décédées","word_nosc":"decedees","lemma":"décéder","pos":"VER"} ,
		{"word":"décédés","word_nosc":"decedes","lemma":"décéder","pos":"VER"} ,
		{"word":"décélérer","word_nosc":"decelerer","lemma":"décélérer","pos":"VER"} ,
		{"word":"décélérez","word_nosc":"decelerez","lemma":"décélérer","pos":"VER"} ,
		{"word":"décérébrer","word_nosc":"decerebrer","lemma":"décérébrer","pos":"VER"} ,
		{"word":"décérébré","word_nosc":"decerebre","lemma":"décérébrer","pos":"VER"} ,
		{"word":"décérébrée","word_nosc":"decerebree","lemma":"décérébrer","pos":"VER"} ,
		{"word":"décérébrés","word_nosc":"decerebres","lemma":"décérébrer","pos":"VER"} ,
		{"word":"dédaigna","word_nosc":"dedaigna","lemma":"dédaigner","pos":"VER"} ,
		{"word":"dédaignaient","word_nosc":"dedaignaient","lemma":"dédaigner","pos":"VER"} ,
		{"word":"dédaignais","word_nosc":"dedaignais","lemma":"dédaigner","pos":"VER"} ,
		{"word":"dédaignait","word_nosc":"dedaignait","lemma":"dédaigner","pos":"VER"} ,
		{"word":"dédaignant","word_nosc":"dedaignant","lemma":"dédaigner","pos":"VER"} ,
		{"word":"dédaigne","word_nosc":"dedaigne","lemma":"dédaigner","pos":"VER"} ,
		{"word":"dédaignent","word_nosc":"dedaignent","lemma":"dédaigner","pos":"VER"} ,
		{"word":"dédaigner","word_nosc":"dedaigner","lemma":"dédaigner","pos":"VER"} ,
		{"word":"dédaignes","word_nosc":"dedaignes","lemma":"dédaigner","pos":"VER"} ,
		{"word":"dédaignez","word_nosc":"dedaignez","lemma":"dédaigner","pos":"VER"} ,
		{"word":"dédaignât","word_nosc":"dedaignat","lemma":"dédaigner","pos":"VER"} ,
		{"word":"dédaigné","word_nosc":"dedaigne","lemma":"dédaigner","pos":"VER"} ,
		{"word":"dédaignée","word_nosc":"dedaignee","lemma":"dédaigner","pos":"VER"} ,
		{"word":"dédaignés","word_nosc":"dedaignes","lemma":"dédaigner","pos":"VER"} ,
		{"word":"dédia","word_nosc":"dedia","lemma":"dédier","pos":"VER"} ,
		{"word":"dédiaient","word_nosc":"dediaient","lemma":"dédier","pos":"VER"} ,
		{"word":"dédiait","word_nosc":"dediait","lemma":"dédier","pos":"VER"} ,
		{"word":"dédiant","word_nosc":"dediant","lemma":"dédier","pos":"VER"} ,
		{"word":"dédicace","word_nosc":"dedicace","lemma":"dédicacer","pos":"VER"} ,
		{"word":"dédicacer","word_nosc":"dedicacer","lemma":"dédicacer","pos":"VER"} ,
		{"word":"dédicacerai","word_nosc":"dedicacerai","lemma":"dédicacer","pos":"VER"} ,
		{"word":"dédicaces","word_nosc":"dedicaces","lemma":"dédicacer","pos":"VER"} ,
		{"word":"dédicacez","word_nosc":"dedicacez","lemma":"dédicacer","pos":"VER"} ,
		{"word":"dédicaciez","word_nosc":"dedicaciez","lemma":"dédicacer","pos":"VER"} ,
		{"word":"dédicacé","word_nosc":"dedicace","lemma":"dédicacer","pos":"VER"} ,
		{"word":"dédicacée","word_nosc":"dedicacee","lemma":"dédicacer","pos":"VER"} ,
		{"word":"dédicacées","word_nosc":"dedicacees","lemma":"dédicacer","pos":"VER"} ,
		{"word":"dédicacés","word_nosc":"dedicaces","lemma":"dédicacer","pos":"VER"} ,
		{"word":"dédicaça","word_nosc":"dedicaca","lemma":"dédicacer","pos":"VER"} ,
		{"word":"dédicaçait","word_nosc":"dedicacait","lemma":"dédicacer","pos":"VER"} ,
		{"word":"dédie","word_nosc":"dedie","lemma":"dédier","pos":"VER"} ,
		{"word":"dédient","word_nosc":"dedient","lemma":"dédier","pos":"VER"} ,
		{"word":"dédier","word_nosc":"dedier","lemma":"dédier","pos":"VER"} ,
		{"word":"dédiera","word_nosc":"dediera","lemma":"dédier","pos":"VER"} ,
		{"word":"dédierai","word_nosc":"dedierai","lemma":"dédier","pos":"VER"} ,
		{"word":"dédierait","word_nosc":"dedierait","lemma":"dédier","pos":"VER"} ,
		{"word":"dédiez","word_nosc":"dediez","lemma":"dédier","pos":"VER"} ,
		{"word":"dédions","word_nosc":"dedions","lemma":"dédier","pos":"VER"} ,
		{"word":"dédire","word_nosc":"dedire","lemma":"dédire","pos":"VER"} ,
		{"word":"dédit","word_nosc":"dedit","lemma":"dédire","pos":"VER"} ,
		{"word":"dédite","word_nosc":"dedite","lemma":"dédire","pos":"VER"} ,
		{"word":"dédié","word_nosc":"dedie","lemma":"dédier","pos":"VER"} ,
		{"word":"dédiée","word_nosc":"dediee","lemma":"dédier","pos":"VER"} ,
		{"word":"dédiées","word_nosc":"dediees","lemma":"dédier","pos":"VER"} ,
		{"word":"dédiés","word_nosc":"dedies","lemma":"dédier","pos":"VER"} ,
		{"word":"dédommage","word_nosc":"dedommage","lemma":"dédommager","pos":"VER"} ,
		{"word":"dédommagea","word_nosc":"dedommagea","lemma":"dédommager","pos":"VER"} ,
		{"word":"dédommageait","word_nosc":"dedommageait","lemma":"dédommager","pos":"VER"} ,
		{"word":"dédommagent","word_nosc":"dedommagent","lemma":"dédommager","pos":"VER"} ,
		{"word":"dédommager","word_nosc":"dedommager","lemma":"dédommager","pos":"VER"} ,
		{"word":"dédommagera","word_nosc":"dedommagera","lemma":"dédommager","pos":"VER"} ,
		{"word":"dédommagerai","word_nosc":"dedommagerai","lemma":"dédommager","pos":"VER"} ,
		{"word":"dédommagerait","word_nosc":"dedommagerait","lemma":"dédommager","pos":"VER"} ,
		{"word":"dédommagerez","word_nosc":"dedommagerez","lemma":"dédommager","pos":"VER"} ,
		{"word":"dédommagerons","word_nosc":"dedommagerons","lemma":"dédommager","pos":"VER"} ,
		{"word":"dédommagé","word_nosc":"dedommage","lemma":"dédommager","pos":"VER"} ,
		{"word":"dédommagée","word_nosc":"dedommagee","lemma":"dédommager","pos":"VER"} ,
		{"word":"dédommagés","word_nosc":"dedommages","lemma":"dédommager","pos":"VER"} ,
		{"word":"dédorent","word_nosc":"dedorent","lemma":"dédorer","pos":"VER"} ,
		{"word":"dédoré","word_nosc":"dedore","lemma":"dédorer","pos":"VER"} ,
		{"word":"dédorée","word_nosc":"dedoree","lemma":"dédorer","pos":"VER"} ,
		{"word":"dédorées","word_nosc":"dedorees","lemma":"dédorer","pos":"VER"} ,
		{"word":"dédorés","word_nosc":"dedores","lemma":"dédorer","pos":"VER"} ,
		{"word":"dédouanait","word_nosc":"dedouanait","lemma":"dédouaner","pos":"VER"} ,
		{"word":"dédouane","word_nosc":"dedouane","lemma":"dédouaner","pos":"VER"} ,
		{"word":"dédouaner","word_nosc":"dedouaner","lemma":"dédouaner","pos":"VER"} ,
		{"word":"dédouanez","word_nosc":"dedouanez","lemma":"dédouaner","pos":"VER"} ,
		{"word":"dédouané","word_nosc":"dedouane","lemma":"dédouaner","pos":"VER"} ,
		{"word":"dédouanée","word_nosc":"dedouanee","lemma":"dédouaner","pos":"VER"} ,
		{"word":"dédouanés","word_nosc":"dedouanes","lemma":"dédouaner","pos":"VER"} ,
		{"word":"dédoubla","word_nosc":"dedoubla","lemma":"dédoubler","pos":"VER"} ,
		{"word":"dédoublaient","word_nosc":"dedoublaient","lemma":"dédoubler","pos":"VER"} ,
		{"word":"dédoublais","word_nosc":"dedoublais","lemma":"dédoubler","pos":"VER"} ,
		{"word":"dédoublait","word_nosc":"dedoublait","lemma":"dédoubler","pos":"VER"} ,
		{"word":"dédouble","word_nosc":"dedouble","lemma":"dédoubler","pos":"VER"} ,
		{"word":"dédoubler","word_nosc":"dedoubler","lemma":"dédoubler","pos":"VER"} ,
		{"word":"dédoublerait","word_nosc":"dedoublerait","lemma":"dédoubler","pos":"VER"} ,
		{"word":"dédoublez","word_nosc":"dedoublez","lemma":"dédoubler","pos":"VER"} ,
		{"word":"dédoublons","word_nosc":"dedoublons","lemma":"dédoubler","pos":"VER"} ,
		{"word":"dédoublé","word_nosc":"dedouble","lemma":"dédoubler","pos":"VER"} ,
		{"word":"dédoublée","word_nosc":"dedoublee","lemma":"dédoubler","pos":"VER"} ,
		{"word":"dédoublées","word_nosc":"dedoublees","lemma":"dédoubler","pos":"VER"} ,
		{"word":"dédoublés","word_nosc":"dedoubles","lemma":"dédoubler","pos":"VER"} ,
		{"word":"dédramatisant","word_nosc":"dedramatisant","lemma":"dédramatiser","pos":"VER"} ,
		{"word":"dédramatise","word_nosc":"dedramatise","lemma":"dédramatiser","pos":"VER"} ,
		{"word":"dédramatiser","word_nosc":"dedramatiser","lemma":"dédramatiser","pos":"VER"} ,
		{"word":"déduira","word_nosc":"deduira","lemma":"déduire","pos":"VER"} ,
		{"word":"déduirai","word_nosc":"deduirai","lemma":"déduire","pos":"VER"} ,
		{"word":"déduiraient","word_nosc":"deduiraient","lemma":"déduire","pos":"VER"} ,
		{"word":"déduirait","word_nosc":"deduirait","lemma":"déduire","pos":"VER"} ,
		{"word":"déduire","word_nosc":"deduire","lemma":"déduire","pos":"VER"} ,
		{"word":"déduirez","word_nosc":"deduirez","lemma":"déduire","pos":"VER"} ,
		{"word":"déduis","word_nosc":"deduis","lemma":"déduire","pos":"VER"} ,
		{"word":"déduisaient","word_nosc":"deduisaient","lemma":"déduire","pos":"VER"} ,
		{"word":"déduisait","word_nosc":"deduisait","lemma":"déduire","pos":"VER"} ,
		{"word":"déduisant","word_nosc":"deduisant","lemma":"déduire","pos":"VER"} ,
		{"word":"déduise","word_nosc":"deduise","lemma":"déduire","pos":"VER"} ,
		{"word":"déduisent","word_nosc":"deduisent","lemma":"déduire","pos":"VER"} ,
		{"word":"déduisez","word_nosc":"deduisez","lemma":"déduire","pos":"VER"} ,
		{"word":"déduisiez","word_nosc":"deduisiez","lemma":"déduire","pos":"VER"} ,
		{"word":"déduisit","word_nosc":"deduisit","lemma":"déduire","pos":"VER"} ,
		{"word":"déduisons","word_nosc":"deduisons","lemma":"déduire","pos":"VER"} ,
		{"word":"déduit","word_nosc":"deduit","lemma":"déduire","pos":"VER"} ,
		{"word":"déduite","word_nosc":"deduite","lemma":"déduire","pos":"VER"} ,
		{"word":"déduites","word_nosc":"deduites","lemma":"déduire","pos":"VER"} ,
		{"word":"déduits","word_nosc":"deduits","lemma":"déduire","pos":"VER"} ,
		{"word":"défaillais","word_nosc":"defaillais","lemma":"défaillir","pos":"VER"} ,
		{"word":"défaillait","word_nosc":"defaillait","lemma":"défaillir","pos":"VER"} ,
		{"word":"défaillant","word_nosc":"defaillant","lemma":"défaillir","pos":"VER"} ,
		{"word":"défaille","word_nosc":"defaille","lemma":"défaillir","pos":"VER"} ,
		{"word":"défaillent","word_nosc":"defaillent","lemma":"défaillir","pos":"VER"} ,
		{"word":"défailli","word_nosc":"defailli","lemma":"défaillir","pos":"VER"} ,
		{"word":"défaillir","word_nosc":"defaillir","lemma":"défaillir","pos":"VER"} ,
		{"word":"défaillirait","word_nosc":"defaillirait","lemma":"défaillir","pos":"VER"} ,
		{"word":"défaillis","word_nosc":"defaillis","lemma":"défaillir","pos":"VER"} ,
		{"word":"défaillit","word_nosc":"defaillit","lemma":"défaillir","pos":"VER"} ,
		{"word":"défaire","word_nosc":"defaire","lemma":"défaire","pos":"VER"} ,
		{"word":"défais","word_nosc":"defais","lemma":"défaire","pos":"VER"} ,
		{"word":"défaisaient","word_nosc":"defaisaient","lemma":"défaire","pos":"VER"} ,
		{"word":"défaisais","word_nosc":"defaisais","lemma":"défaire","pos":"VER"} ,
		{"word":"défaisait","word_nosc":"defaisait","lemma":"défaire","pos":"VER"} ,
		{"word":"défaisant","word_nosc":"defaisant","lemma":"défaire","pos":"VER"} ,
		{"word":"défaisons","word_nosc":"defaisons","lemma":"défaire","pos":"VER"} ,
		{"word":"défait","word_nosc":"defait","lemma":"défaire","pos":"VER"} ,
		{"word":"défaite","word_nosc":"defaite","lemma":"défaire","pos":"VER"} ,
		{"word":"défaites","word_nosc":"defaites","lemma":"défaire","pos":"VER"} ,
		{"word":"défaits","word_nosc":"defaits","lemma":"défaire","pos":"VER"} ,
		{"word":"défalque","word_nosc":"defalque","lemma":"défalquer","pos":"VER"} ,
		{"word":"défalquer","word_nosc":"defalquer","lemma":"défalquer","pos":"VER"} ,
		{"word":"défalqué","word_nosc":"defalque","lemma":"défalquer","pos":"VER"} ,
		{"word":"défalquées","word_nosc":"defalquees","lemma":"défalquer","pos":"VER"} ,
		{"word":"défarguaient","word_nosc":"defarguaient","lemma":"défarguer","pos":"VER"} ,
		{"word":"défargue","word_nosc":"defargue","lemma":"défarguer","pos":"VER"} ,
		{"word":"défarguer","word_nosc":"defarguer","lemma":"défarguer","pos":"VER"} ,
		{"word":"défargué","word_nosc":"defargue","lemma":"défarguer","pos":"VER"} ,
		{"word":"défasse","word_nosc":"defasse","lemma":"défaire","pos":"VER"} ,
		{"word":"défausse","word_nosc":"defausse","lemma":"défausser","pos":"VER"} ,
		{"word":"défausser","word_nosc":"defausser","lemma":"défausser","pos":"VER"} ,
		{"word":"défavorisait","word_nosc":"defavorisait","lemma":"défavoriser","pos":"VER"} ,
		{"word":"défavorisent","word_nosc":"defavorisent","lemma":"défavoriser","pos":"VER"} ,
		{"word":"défavorisé","word_nosc":"defavorise","lemma":"défavoriser","pos":"VER"} ,
		{"word":"défavorisée","word_nosc":"defavorisee","lemma":"défavoriser","pos":"VER"} ,
		{"word":"défavorisées","word_nosc":"defavorisees","lemma":"défavoriser","pos":"VER"} ,
		{"word":"défavorisés","word_nosc":"defavorises","lemma":"défavoriser","pos":"VER"} ,
		{"word":"défend","word_nosc":"defend","lemma":"défendre","pos":"VER"} ,
		{"word":"défendaient","word_nosc":"defendaient","lemma":"défendre","pos":"VER"} ,
		{"word":"défendais","word_nosc":"defendais","lemma":"défendre","pos":"VER"} ,
		{"word":"défendait","word_nosc":"defendait","lemma":"défendre","pos":"VER"} ,
		{"word":"défendant","word_nosc":"defendant","lemma":"défendre","pos":"VER"} ,
		{"word":"défende","word_nosc":"defende","lemma":"défendre","pos":"VER"} ,
		{"word":"défendent","word_nosc":"defendent","lemma":"défendre","pos":"VER"} ,
		{"word":"défendes","word_nosc":"defendes","lemma":"défendre","pos":"VER"} ,
		{"word":"défendez","word_nosc":"defendez","lemma":"défendre","pos":"VER"} ,
		{"word":"défendiez","word_nosc":"defendiez","lemma":"défendre","pos":"VER"} ,
		{"word":"défendions","word_nosc":"defendions","lemma":"défendre","pos":"VER"} ,
		{"word":"défendirent","word_nosc":"defendirent","lemma":"défendre","pos":"VER"} ,
		{"word":"défendis","word_nosc":"defendis","lemma":"défendre","pos":"VER"} ,
		{"word":"défendit","word_nosc":"defendit","lemma":"défendre","pos":"VER"} ,
		{"word":"défendons","word_nosc":"defendons","lemma":"défendre","pos":"VER"} ,
		{"word":"défendra","word_nosc":"defendra","lemma":"défendre","pos":"VER"} ,
		{"word":"défendrai","word_nosc":"defendrai","lemma":"défendre","pos":"VER"} ,
		{"word":"défendraient","word_nosc":"defendraient","lemma":"défendre","pos":"VER"} ,
		{"word":"défendrais","word_nosc":"defendrais","lemma":"défendre","pos":"VER"} ,
		{"word":"défendrait","word_nosc":"defendrait","lemma":"défendre","pos":"VER"} ,
		{"word":"défendras","word_nosc":"defendras","lemma":"défendre","pos":"VER"} ,
		{"word":"défendre","word_nosc":"defendre","lemma":"défendre","pos":"VER"} ,
		{"word":"défendrez","word_nosc":"defendrez","lemma":"défendre","pos":"VER"} ,
		{"word":"défendriez","word_nosc":"defendriez","lemma":"défendre","pos":"VER"} ,
		{"word":"défendrons","word_nosc":"defendrons","lemma":"défendre","pos":"VER"} ,
		{"word":"défendront","word_nosc":"defendront","lemma":"défendre","pos":"VER"} ,
		{"word":"défends","word_nosc":"defends","lemma":"défendre","pos":"VER"} ,
		{"word":"défendu","word_nosc":"defendu","lemma":"défendre","pos":"VER"} ,
		{"word":"défendue","word_nosc":"defendue","lemma":"défendre","pos":"VER"} ,
		{"word":"défendues","word_nosc":"defendues","lemma":"défendre","pos":"VER"} ,
		{"word":"défendus","word_nosc":"defendus","lemma":"défendre","pos":"VER"} ,
		{"word":"défendît","word_nosc":"defendit","lemma":"défendre","pos":"VER"} ,
		{"word":"défenestraient","word_nosc":"defenestraient","lemma":"défenestrer","pos":"VER"} ,
		{"word":"défenestrant","word_nosc":"defenestrant","lemma":"défenestrer","pos":"VER"} ,
		{"word":"défenestrer","word_nosc":"defenestrer","lemma":"défenestrer","pos":"VER"} ,
		{"word":"défenestrerai","word_nosc":"defenestrerai","lemma":"défenestrer","pos":"VER"} ,
		{"word":"défenestré","word_nosc":"defenestre","lemma":"défenestrer","pos":"VER"} ,
		{"word":"défenestrée","word_nosc":"defenestree","lemma":"défenestrer","pos":"VER"} ,
		{"word":"défera","word_nosc":"defera","lemma":"défaire","pos":"VER"} ,
		{"word":"déferai","word_nosc":"deferai","lemma":"défaire","pos":"VER"} ,
		{"word":"déferaient","word_nosc":"deferaient","lemma":"défaire","pos":"VER"} ,
		{"word":"déferais","word_nosc":"deferais","lemma":"défaire","pos":"VER"} ,
		{"word":"déferait","word_nosc":"deferait","lemma":"défaire","pos":"VER"} ,
		{"word":"déferas","word_nosc":"deferas","lemma":"défaire","pos":"VER"} ,
		{"word":"déferez","word_nosc":"deferez","lemma":"défaire","pos":"VER"} ,
		{"word":"déferla","word_nosc":"deferla","lemma":"déferler","pos":"VER"} ,
		{"word":"déferlaient","word_nosc":"deferlaient","lemma":"déferler","pos":"VER"} ,
		{"word":"déferlait","word_nosc":"deferlait","lemma":"déferler","pos":"VER"} ,
		{"word":"déferlant","word_nosc":"deferlant","lemma":"déferler","pos":"VER"} ,
		{"word":"déferle","word_nosc":"deferle","lemma":"déferler","pos":"VER"} ,
		{"word":"déferlent","word_nosc":"deferlent","lemma":"déferler","pos":"VER"} ,
		{"word":"déferler","word_nosc":"deferler","lemma":"déferler","pos":"VER"} ,
		{"word":"déferleraient","word_nosc":"deferleraient","lemma":"déferler","pos":"VER"} ,
		{"word":"déferlerait","word_nosc":"deferlerait","lemma":"déferler","pos":"VER"} ,
		{"word":"déferleront","word_nosc":"deferleront","lemma":"déferler","pos":"VER"} ,
		{"word":"déferlez","word_nosc":"deferlez","lemma":"déferler","pos":"VER"} ,
		{"word":"déferlât","word_nosc":"deferlat","lemma":"déferler","pos":"VER"} ,
		{"word":"déferlèrent","word_nosc":"deferlerent","lemma":"déferler","pos":"VER"} ,
		{"word":"déferlé","word_nosc":"deferle","lemma":"déferler","pos":"VER"} ,
		{"word":"déferlées","word_nosc":"deferlees","lemma":"déferler","pos":"VER"} ,
		{"word":"déferons","word_nosc":"deferons","lemma":"défaire","pos":"VER"} ,
		{"word":"déferont","word_nosc":"deferont","lemma":"défaire","pos":"VER"} ,
		{"word":"déferrer","word_nosc":"deferrer","lemma":"déferrer","pos":"VER"} ,
		{"word":"déferré","word_nosc":"deferre","lemma":"déferrer","pos":"VER"} ,
		{"word":"défeuillées","word_nosc":"defeuillees","lemma":"défeuiller","pos":"VER"} ,
		{"word":"défia","word_nosc":"defia","lemma":"défier","pos":"VER"} ,
		{"word":"défiaient","word_nosc":"defiaient","lemma":"défier","pos":"VER"} ,
		{"word":"défiais","word_nosc":"defiais","lemma":"défier","pos":"VER"} ,
		{"word":"défiait","word_nosc":"defiait","lemma":"défier","pos":"VER"} ,
		{"word":"défiant","word_nosc":"defiant","lemma":"défier","pos":"VER"} ,
		{"word":"déficeler","word_nosc":"deficeler","lemma":"déficeler","pos":"VER"} ,
		{"word":"déficelle","word_nosc":"deficelle","lemma":"déficeler","pos":"VER"} ,
		{"word":"déficelèrent","word_nosc":"deficelerent","lemma":"déficeler","pos":"VER"} ,
		{"word":"défie","word_nosc":"defie","lemma":"défier","pos":"VER"} ,
		{"word":"défient","word_nosc":"defient","lemma":"défier","pos":"VER"} ,
		{"word":"défier","word_nosc":"defier","lemma":"défier","pos":"VER"} ,
		{"word":"défiera","word_nosc":"defiera","lemma":"défier","pos":"VER"} ,
		{"word":"défierai","word_nosc":"defierai","lemma":"défier","pos":"VER"} ,
		{"word":"défierais","word_nosc":"defierais","lemma":"défier","pos":"VER"} ,
		{"word":"défierait","word_nosc":"defierait","lemma":"défier","pos":"VER"} ,
		{"word":"défieriez","word_nosc":"defieriez","lemma":"défier","pos":"VER"} ,
		{"word":"défierons","word_nosc":"defierons","lemma":"défier","pos":"VER"} ,
		{"word":"défiez","word_nosc":"defiez","lemma":"défier","pos":"VER"} ,
		{"word":"défigeait","word_nosc":"defigeait","lemma":"défiger","pos":"VER"} ,
		{"word":"défiger","word_nosc":"defiger","lemma":"défiger","pos":"VER"} ,
		{"word":"défigura","word_nosc":"defigura","lemma":"défigurer","pos":"VER"} ,
		{"word":"défiguraient","word_nosc":"defiguraient","lemma":"défigurer","pos":"VER"} ,
		{"word":"défigurais","word_nosc":"defigurais","lemma":"défigurer","pos":"VER"} ,
		{"word":"défigurait","word_nosc":"defigurait","lemma":"défigurer","pos":"VER"} ,
		{"word":"défigurant","word_nosc":"defigurant","lemma":"défigurer","pos":"VER"} ,
		{"word":"défigure","word_nosc":"defigure","lemma":"défigurer","pos":"VER"} ,
		{"word":"défigurent","word_nosc":"defigurent","lemma":"défigurer","pos":"VER"} ,
		{"word":"défigurer","word_nosc":"defigurer","lemma":"défigurer","pos":"VER"} ,
		{"word":"défigurerait","word_nosc":"defigurerait","lemma":"défigurer","pos":"VER"} ,
		{"word":"défiguré","word_nosc":"defigure","lemma":"défigurer","pos":"VER"} ,
		{"word":"défigurée","word_nosc":"defiguree","lemma":"défigurer","pos":"VER"} ,
		{"word":"défigurées","word_nosc":"defigurees","lemma":"défigurer","pos":"VER"} ,
		{"word":"défigurés","word_nosc":"defigures","lemma":"défigurer","pos":"VER"} ,
		{"word":"défila","word_nosc":"defila","lemma":"défiler","pos":"VER"} ,
		{"word":"défilaient","word_nosc":"defilaient","lemma":"défiler","pos":"VER"} ,
		{"word":"défilais","word_nosc":"defilais","lemma":"défiler","pos":"VER"} ,
		{"word":"défilait","word_nosc":"defilait","lemma":"défiler","pos":"VER"} ,
		{"word":"défilant","word_nosc":"defilant","lemma":"défiler","pos":"VER"} ,
		{"word":"défile","word_nosc":"defile","lemma":"défiler","pos":"VER"} ,
		{"word":"défilent","word_nosc":"defilent","lemma":"défiler","pos":"VER"} ,
		{"word":"défiler","word_nosc":"defiler","lemma":"défiler","pos":"VER"} ,
		{"word":"défilera","word_nosc":"defilera","lemma":"défiler","pos":"VER"} ,
		{"word":"défileraient","word_nosc":"defileraient","lemma":"défiler","pos":"VER"} ,
		{"word":"défilerait","word_nosc":"defilerait","lemma":"défiler","pos":"VER"} ,
		{"word":"défileras","word_nosc":"defileras","lemma":"défiler","pos":"VER"} ,
		{"word":"défilerez","word_nosc":"defilerez","lemma":"défiler","pos":"VER"} ,
		{"word":"défilerons","word_nosc":"defilerons","lemma":"défiler","pos":"VER"} ,
		{"word":"défileront","word_nosc":"defileront","lemma":"défiler","pos":"VER"} ,
		{"word":"défilez","word_nosc":"defilez","lemma":"défiler","pos":"VER"} ,
		{"word":"défiliez","word_nosc":"defiliez","lemma":"défiler","pos":"VER"} ,
		{"word":"défilions","word_nosc":"defilions","lemma":"défiler","pos":"VER"} ,
		{"word":"défilons","word_nosc":"defilons","lemma":"défiler","pos":"VER"} ,
		{"word":"défilèrent","word_nosc":"defilerent","lemma":"défiler","pos":"VER"} ,
		{"word":"défilé","word_nosc":"defile","lemma":"défiler","pos":"VER"} ,
		{"word":"défilée","word_nosc":"defilee","lemma":"défiler","pos":"VER"} ,
		{"word":"défilés","word_nosc":"defiles","lemma":"défiler","pos":"VER"} ,
		{"word":"défini","word_nosc":"defini","lemma":"définir","pos":"VER"} ,
		{"word":"définie","word_nosc":"definie","lemma":"définir","pos":"VER"} ,
		{"word":"définies","word_nosc":"definies","lemma":"définir","pos":"VER"} ,
		{"word":"définir","word_nosc":"definir","lemma":"définir","pos":"VER"} ,
		{"word":"définira","word_nosc":"definira","lemma":"définir","pos":"VER"} ,
		{"word":"définirais","word_nosc":"definirais","lemma":"définir","pos":"VER"} ,
		{"word":"définirait","word_nosc":"definirait","lemma":"définir","pos":"VER"} ,
		{"word":"définiriez","word_nosc":"definiriez","lemma":"définir","pos":"VER"} ,
		{"word":"définis","word_nosc":"definis","lemma":"définir","pos":"VER"} ,
		{"word":"définissaient","word_nosc":"definissaient","lemma":"définir","pos":"VER"} ,
		{"word":"définissait","word_nosc":"definissait","lemma":"définir","pos":"VER"} ,
		{"word":"définissant","word_nosc":"definissant","lemma":"définir","pos":"VER"} ,
		{"word":"définisse","word_nosc":"definisse","lemma":"définir","pos":"VER"} ,
		{"word":"définissent","word_nosc":"definissent","lemma":"définir","pos":"VER"} ,
		{"word":"définissez","word_nosc":"definissez","lemma":"définir","pos":"VER"} ,
		{"word":"définissons","word_nosc":"definissons","lemma":"définir","pos":"VER"} ,
		{"word":"définit","word_nosc":"definit","lemma":"définir","pos":"VER"} ,
		{"word":"défions","word_nosc":"defions","lemma":"défier","pos":"VER"} ,
		{"word":"défirent","word_nosc":"defirent","lemma":"défaire","pos":"VER"} ,
		{"word":"défis","word_nosc":"defis","lemma":"défaire","pos":"VER"} ,
		{"word":"défiscalisé","word_nosc":"defiscalise","lemma":"défiscaliser","pos":"VER"} ,
		{"word":"défit","word_nosc":"defit","lemma":"défaire","pos":"VER"} ,
		{"word":"défièrent","word_nosc":"defierent","lemma":"défier","pos":"VER"} ,
		{"word":"défié","word_nosc":"defie","lemma":"défier","pos":"VER"} ,
		{"word":"défiée","word_nosc":"defiee","lemma":"défier","pos":"VER"} ,
		{"word":"défiés","word_nosc":"defies","lemma":"défier","pos":"VER"} ,
		{"word":"défleuri","word_nosc":"defleuri","lemma":"défleurir","pos":"VER"} ,
		{"word":"défleuries","word_nosc":"defleuries","lemma":"défleurir","pos":"VER"} ,
		{"word":"défleuris","word_nosc":"defleuris","lemma":"défleurir","pos":"VER"} ,
		{"word":"déflorait","word_nosc":"deflorait","lemma":"déflorer","pos":"VER"} ,
		{"word":"déflorer","word_nosc":"deflorer","lemma":"déflorer","pos":"VER"} ,
		{"word":"déflorerons","word_nosc":"deflorerons","lemma":"déflorer","pos":"VER"} ,
		{"word":"défloré","word_nosc":"deflore","lemma":"déflorer","pos":"VER"} ,
		{"word":"déflorée","word_nosc":"defloree","lemma":"déflorer","pos":"VER"} ,
		{"word":"déflorés","word_nosc":"deflores","lemma":"déflorer","pos":"VER"} ,
		{"word":"défonce","word_nosc":"defonce","lemma":"défoncer","pos":"VER"} ,
		{"word":"défoncent","word_nosc":"defoncent","lemma":"défoncer","pos":"VER"} ,
		{"word":"défoncer","word_nosc":"defoncer","lemma":"défoncer","pos":"VER"} ,
		{"word":"défoncera","word_nosc":"defoncera","lemma":"défoncer","pos":"VER"} ,
		{"word":"défoncerai","word_nosc":"defoncerai","lemma":"défoncer","pos":"VER"} ,
		{"word":"défonceraient","word_nosc":"defonceraient","lemma":"défoncer","pos":"VER"} ,
		{"word":"défoncerait","word_nosc":"defoncerait","lemma":"défoncer","pos":"VER"} ,
		{"word":"défonceront","word_nosc":"defonceront","lemma":"défoncer","pos":"VER"} ,
		{"word":"défoncez","word_nosc":"defoncez","lemma":"défoncer","pos":"VER"} ,
		{"word":"défoncé","word_nosc":"defonce","lemma":"défoncer","pos":"VER"} ,
		{"word":"défoncée","word_nosc":"defoncee","lemma":"défoncer","pos":"VER"} ,
		{"word":"défoncées","word_nosc":"defoncees","lemma":"défoncer","pos":"VER"} ,
		{"word":"défoncés","word_nosc":"defonces","lemma":"défoncer","pos":"VER"} ,
		{"word":"défont","word_nosc":"defont","lemma":"défaire","pos":"VER"} ,
		{"word":"défonça","word_nosc":"defonca","lemma":"défoncer","pos":"VER"} ,
		{"word":"défonçai","word_nosc":"defoncai","lemma":"défoncer","pos":"VER"} ,
		{"word":"défonçaient","word_nosc":"defoncaient","lemma":"défoncer","pos":"VER"} ,
		{"word":"défonçais","word_nosc":"defoncais","lemma":"défoncer","pos":"VER"} ,
		{"word":"défonçait","word_nosc":"defoncait","lemma":"défoncer","pos":"VER"} ,
		{"word":"défonçant","word_nosc":"defoncant","lemma":"défoncer","pos":"VER"} ,
		{"word":"défonçons","word_nosc":"defoncons","lemma":"défoncer","pos":"VER"} ,
		{"word":"déforma","word_nosc":"deforma","lemma":"déformer","pos":"VER"} ,
		{"word":"déformaient","word_nosc":"deformaient","lemma":"déformer","pos":"VER"} ,
		{"word":"déformait","word_nosc":"deformait","lemma":"déformer","pos":"VER"} ,
		{"word":"déformant","word_nosc":"deformant","lemma":"déformer","pos":"VER"} ,
		{"word":"déforme","word_nosc":"deforme","lemma":"déformer","pos":"VER"} ,
		{"word":"déforment","word_nosc":"deforment","lemma":"déformer","pos":"VER"} ,
		{"word":"déformer","word_nosc":"deformer","lemma":"déformer","pos":"VER"} ,
		{"word":"déformera","word_nosc":"deformera","lemma":"déformer","pos":"VER"} ,
		{"word":"déformerai","word_nosc":"deformerai","lemma":"déformer","pos":"VER"} ,
		{"word":"déformerait","word_nosc":"deformerait","lemma":"déformer","pos":"VER"} ,
		{"word":"déformeront","word_nosc":"deformeront","lemma":"déformer","pos":"VER"} ,
		{"word":"déformes","word_nosc":"deformes","lemma":"déformer","pos":"VER"} ,
		{"word":"déformez","word_nosc":"deformez","lemma":"déformer","pos":"VER"} ,
		{"word":"déformé","word_nosc":"deforme","lemma":"déformer","pos":"VER"} ,
		{"word":"déformée","word_nosc":"deformee","lemma":"déformer","pos":"VER"} ,
		{"word":"déformées","word_nosc":"deformees","lemma":"déformer","pos":"VER"} ,
		{"word":"déformés","word_nosc":"deformes","lemma":"déformer","pos":"VER"} ,
		{"word":"défoulaient","word_nosc":"defoulaient","lemma":"défouler","pos":"VER"} ,
		{"word":"défoulais","word_nosc":"defoulais","lemma":"défouler","pos":"VER"} ,
		{"word":"défoulait","word_nosc":"defoulait","lemma":"défouler","pos":"VER"} ,
		{"word":"défoule","word_nosc":"defoule","lemma":"défouler","pos":"VER"} ,
		{"word":"défoulent","word_nosc":"defoulent","lemma":"défouler","pos":"VER"} ,
		{"word":"défouler","word_nosc":"defouler","lemma":"défouler","pos":"VER"} ,
		{"word":"défoulera","word_nosc":"defoulera","lemma":"défouler","pos":"VER"} ,
		{"word":"défoulerait","word_nosc":"defoulerait","lemma":"défouler","pos":"VER"} ,
		{"word":"défoules","word_nosc":"defoules","lemma":"défouler","pos":"VER"} ,
		{"word":"défouliez","word_nosc":"defouliez","lemma":"défouler","pos":"VER"} ,
		{"word":"défoulons","word_nosc":"defoulons","lemma":"défouler","pos":"VER"} ,
		{"word":"défoulés","word_nosc":"defoules","lemma":"défouler","pos":"VER"} ,
		{"word":"défouraillaient","word_nosc":"defouraillaient","lemma":"défourailler","pos":"VER"} ,
		{"word":"défouraille","word_nosc":"defouraille","lemma":"défourailler","pos":"VER"} ,
		{"word":"défouraillent","word_nosc":"defouraillent","lemma":"défourailler","pos":"VER"} ,
		{"word":"défourailler","word_nosc":"defourailler","lemma":"défourailler","pos":"VER"} ,
		{"word":"défouraillerait","word_nosc":"defouraillerait","lemma":"défourailler","pos":"VER"} ,
		{"word":"défouraillé","word_nosc":"defouraille","lemma":"défourailler","pos":"VER"} ,
		{"word":"défourna","word_nosc":"defourna","lemma":"défourner","pos":"VER"} ,
		{"word":"défournait","word_nosc":"defournait","lemma":"défourner","pos":"VER"} ,
		{"word":"défraya","word_nosc":"defraya","lemma":"défrayer","pos":"VER"} ,
		{"word":"défrayaient","word_nosc":"defrayaient","lemma":"défrayer","pos":"VER"} ,
		{"word":"défrayait","word_nosc":"defrayait","lemma":"défrayer","pos":"VER"} ,
		{"word":"défrayant","word_nosc":"defrayant","lemma":"défrayer","pos":"VER"} ,
		{"word":"défrayent","word_nosc":"defrayent","lemma":"défrayer","pos":"VER"} ,
		{"word":"défrayer","word_nosc":"defrayer","lemma":"défrayer","pos":"VER"} ,
		{"word":"défrayèrent","word_nosc":"defrayerent","lemma":"défrayer","pos":"VER"} ,
		{"word":"défrayé","word_nosc":"defraye","lemma":"défrayer","pos":"VER"} ,
		{"word":"défraîchi","word_nosc":"defraichi","lemma":"défraîchir","pos":"VER"} ,
		{"word":"défraîchie","word_nosc":"defraichie","lemma":"défraîchir","pos":"VER"} ,
		{"word":"défraîchies","word_nosc":"defraichies","lemma":"défraîchir","pos":"VER"} ,
		{"word":"défraîchir","word_nosc":"defraichir","lemma":"défraîchir","pos":"VER"} ,
		{"word":"défraîchis","word_nosc":"defraichis","lemma":"défraîchir","pos":"VER"} ,
		{"word":"défraîchissait","word_nosc":"defraichissait","lemma":"défraîchir","pos":"VER"} ,
		{"word":"défricha","word_nosc":"defricha","lemma":"défricher","pos":"VER"} ,
		{"word":"défrichait","word_nosc":"defrichait","lemma":"défricher","pos":"VER"} ,
		{"word":"défriche","word_nosc":"defriche","lemma":"défricher","pos":"VER"} ,
		{"word":"défricher","word_nosc":"defricher","lemma":"défricher","pos":"VER"} ,
		{"word":"défriché","word_nosc":"defriche","lemma":"défricher","pos":"VER"} ,
		{"word":"défrichée","word_nosc":"defrichee","lemma":"défricher","pos":"VER"} ,
		{"word":"défrichées","word_nosc":"defrichees","lemma":"défricher","pos":"VER"} ,
		{"word":"défrichés","word_nosc":"defriches","lemma":"défricher","pos":"VER"} ,
		{"word":"défringue","word_nosc":"defringue","lemma":"défringuer","pos":"VER"} ,
		{"word":"défringues","word_nosc":"defringues","lemma":"défringuer","pos":"VER"} ,
		{"word":"défripe","word_nosc":"defripe","lemma":"défriper","pos":"VER"} ,
		{"word":"défripera","word_nosc":"defripera","lemma":"défriper","pos":"VER"} ,
		{"word":"défripé","word_nosc":"defripe","lemma":"défriper","pos":"VER"} ,
		{"word":"défripées","word_nosc":"defripees","lemma":"défriper","pos":"VER"} ,
		{"word":"défrisaient","word_nosc":"defrisaient","lemma":"défriser","pos":"VER"} ,
		{"word":"défrisait","word_nosc":"defrisait","lemma":"défriser","pos":"VER"} ,
		{"word":"défrise","word_nosc":"defrise","lemma":"défriser","pos":"VER"} ,
		{"word":"défriser","word_nosc":"defriser","lemma":"défriser","pos":"VER"} ,
		{"word":"défriserait","word_nosc":"defriserait","lemma":"défriser","pos":"VER"} ,
		{"word":"défrises","word_nosc":"defrises","lemma":"défriser","pos":"VER"} ,
		{"word":"défrisé","word_nosc":"defrise","lemma":"défriser","pos":"VER"} ,
		{"word":"défroissa","word_nosc":"defroissa","lemma":"défroisser","pos":"VER"} ,
		{"word":"défroissai","word_nosc":"defroissai","lemma":"défroisser","pos":"VER"} ,
		{"word":"défroissaient","word_nosc":"defroissaient","lemma":"défroisser","pos":"VER"} ,
		{"word":"défroissait","word_nosc":"defroissait","lemma":"défroisser","pos":"VER"} ,
		{"word":"défroissant","word_nosc":"defroissant","lemma":"défroisser","pos":"VER"} ,
		{"word":"défroisse","word_nosc":"defroisse","lemma":"défroisser","pos":"VER"} ,
		{"word":"défroissent","word_nosc":"defroissent","lemma":"défroisser","pos":"VER"} ,
		{"word":"défroisser","word_nosc":"defroisser","lemma":"défroisser","pos":"VER"} ,
		{"word":"défroissé","word_nosc":"defroisse","lemma":"défroisser","pos":"VER"} ,
		{"word":"défroissées","word_nosc":"defroissees","lemma":"défroisser","pos":"VER"} ,
		{"word":"défroissés","word_nosc":"defroisses","lemma":"défroisser","pos":"VER"} ,
		{"word":"défroqua","word_nosc":"defroqua","lemma":"défroquer","pos":"VER"} ,
		{"word":"défroquer","word_nosc":"defroquer","lemma":"défroquer","pos":"VER"} ,
		{"word":"défroqué","word_nosc":"defroque","lemma":"défroquer","pos":"VER"} ,
		{"word":"défroquée","word_nosc":"defroquee","lemma":"défroquer","pos":"VER"} ,
		{"word":"défroqués","word_nosc":"defroques","lemma":"défroquer","pos":"VER"} ,
		{"word":"défunter","word_nosc":"defunter","lemma":"défunter","pos":"VER"} ,
		{"word":"défunté","word_nosc":"defunte","lemma":"défunter","pos":"VER"} ,
		{"word":"défèque","word_nosc":"defeque","lemma":"déféquer","pos":"VER"} ,
		{"word":"déféminiser","word_nosc":"defeminiser","lemma":"déféminiser","pos":"VER"} ,
		{"word":"déféquaient","word_nosc":"defequaient","lemma":"déféquer","pos":"VER"} ,
		{"word":"déféquant","word_nosc":"defequant","lemma":"déféquer","pos":"VER"} ,
		{"word":"déféquassent","word_nosc":"defequassent","lemma":"déféquer","pos":"VER"} ,
		{"word":"déféquer","word_nosc":"defequer","lemma":"déféquer","pos":"VER"} ,
		{"word":"déféqué","word_nosc":"defeque","lemma":"déféquer","pos":"VER"} ,
		{"word":"déféra","word_nosc":"defera","lemma":"déférer","pos":"VER"} ,
		{"word":"déférait","word_nosc":"deferait","lemma":"déférer","pos":"VER"} ,
		{"word":"déférant","word_nosc":"deferant","lemma":"déférer","pos":"VER"} ,
		{"word":"déférer","word_nosc":"deferer","lemma":"déférer","pos":"VER"} ,
		{"word":"déférerait","word_nosc":"defererait","lemma":"déférer","pos":"VER"} ,
		{"word":"déféré","word_nosc":"defere","lemma":"déférer","pos":"VER"} ,
		{"word":"déférés","word_nosc":"deferes","lemma":"déférer","pos":"VER"} ,
		{"word":"dégage","word_nosc":"degage","lemma":"dégager","pos":"VER"} ,
		{"word":"dégagea","word_nosc":"degagea","lemma":"dégager","pos":"VER"} ,
		{"word":"dégageai","word_nosc":"degageai","lemma":"dégager","pos":"VER"} ,
		{"word":"dégageaient","word_nosc":"degageaient","lemma":"dégager","pos":"VER"} ,
		{"word":"dégageait","word_nosc":"degageait","lemma":"dégager","pos":"VER"} ,
		{"word":"dégageant","word_nosc":"degageant","lemma":"dégager","pos":"VER"} ,
		{"word":"dégagent","word_nosc":"degagent","lemma":"dégager","pos":"VER"} ,
		{"word":"dégageons","word_nosc":"degageons","lemma":"dégager","pos":"VER"} ,
		{"word":"dégager","word_nosc":"degager","lemma":"dégager","pos":"VER"} ,
		{"word":"dégagera","word_nosc":"degagera","lemma":"dégager","pos":"VER"} ,
		{"word":"dégageraient","word_nosc":"degageraient","lemma":"dégager","pos":"VER"} ,
		{"word":"dégagerais","word_nosc":"degagerais","lemma":"dégager","pos":"VER"} ,
		{"word":"dégagerait","word_nosc":"degagerait","lemma":"dégager","pos":"VER"} ,
		{"word":"dégageront","word_nosc":"degageront","lemma":"dégager","pos":"VER"} ,
		{"word":"dégages","word_nosc":"degages","lemma":"dégager","pos":"VER"} ,
		{"word":"dégagez","word_nosc":"degagez","lemma":"dégager","pos":"VER"} ,
		{"word":"dégageât","word_nosc":"degageat","lemma":"dégager","pos":"VER"} ,
		{"word":"dégagèrent","word_nosc":"degagerent","lemma":"dégager","pos":"VER"} ,
		{"word":"dégagé","word_nosc":"degage","lemma":"dégager","pos":"VER"} ,
		{"word":"dégagée","word_nosc":"degagee","lemma":"dégager","pos":"VER"} ,
		{"word":"dégagées","word_nosc":"degagees","lemma":"dégager","pos":"VER"} ,
		{"word":"dégagés","word_nosc":"degages","lemma":"dégager","pos":"VER"} ,
		{"word":"dégaina","word_nosc":"degaina","lemma":"dégainer","pos":"VER"} ,
		{"word":"dégainais","word_nosc":"degainais","lemma":"dégainer","pos":"VER"} ,
		{"word":"dégainait","word_nosc":"degainait","lemma":"dégainer","pos":"VER"} ,
		{"word":"dégainant","word_nosc":"degainant","lemma":"dégainer","pos":"VER"} ,
		{"word":"dégaine","word_nosc":"degaine","lemma":"dégainer","pos":"VER"} ,
		{"word":"dégainent","word_nosc":"degainent","lemma":"dégainer","pos":"VER"} ,
		{"word":"dégainer","word_nosc":"degainer","lemma":"dégainer","pos":"VER"} ,
		{"word":"dégainerai","word_nosc":"degainerai","lemma":"dégainer","pos":"VER"} ,
		{"word":"dégainerez","word_nosc":"degainerez","lemma":"dégainer","pos":"VER"} ,
		{"word":"dégaines","word_nosc":"degaines","lemma":"dégainer","pos":"VER"} ,
		{"word":"dégainez","word_nosc":"degainez","lemma":"dégainer","pos":"VER"} ,
		{"word":"dégainèrent","word_nosc":"degainerent","lemma":"dégainer","pos":"VER"} ,
		{"word":"dégainé","word_nosc":"degaine","lemma":"dégainer","pos":"VER"} ,
		{"word":"dégainés","word_nosc":"degaines","lemma":"dégainer","pos":"VER"} ,
		{"word":"déganta","word_nosc":"deganta","lemma":"déganter","pos":"VER"} ,
		{"word":"dégantant","word_nosc":"degantant","lemma":"déganter","pos":"VER"} ,
		{"word":"déganté","word_nosc":"degante","lemma":"déganter","pos":"VER"} ,
		{"word":"dégantée","word_nosc":"degantee","lemma":"déganter","pos":"VER"} ,
		{"word":"dégantées","word_nosc":"degantees","lemma":"déganter","pos":"VER"} ,
		{"word":"dégarni","word_nosc":"degarni","lemma":"dégarnir","pos":"VER"} ,
		{"word":"dégarnie","word_nosc":"degarnie","lemma":"dégarnir","pos":"VER"} ,
		{"word":"dégarnies","word_nosc":"degarnies","lemma":"dégarnir","pos":"VER"} ,
		{"word":"dégarnir","word_nosc":"degarnir","lemma":"dégarnir","pos":"VER"} ,
		{"word":"dégarnis","word_nosc":"degarnis","lemma":"dégarnir","pos":"VER"} ,
		{"word":"dégarnissait","word_nosc":"degarnissait","lemma":"dégarnir","pos":"VER"} ,
		{"word":"dégarnissant","word_nosc":"degarnissant","lemma":"dégarnir","pos":"VER"} ,
		{"word":"dégauchi","word_nosc":"degauchi","lemma":"dégauchir","pos":"VER"} ,
		{"word":"dégauchie","word_nosc":"degauchie","lemma":"dégauchir","pos":"VER"} ,
		{"word":"dégauchir","word_nosc":"degauchir","lemma":"dégauchir","pos":"VER"} ,
		{"word":"dégauchira","word_nosc":"degauchira","lemma":"dégauchir","pos":"VER"} ,
		{"word":"dégauchis","word_nosc":"degauchis","lemma":"dégauchir","pos":"VER"} ,
		{"word":"dégauchisse","word_nosc":"degauchisse","lemma":"dégauchir","pos":"VER"} ,
		{"word":"dégazent","word_nosc":"degazent","lemma":"dégazer","pos":"VER"} ,
		{"word":"dégazer","word_nosc":"degazer","lemma":"dégazer","pos":"VER"} ,
		{"word":"dégela","word_nosc":"degela","lemma":"dégeler","pos":"VER"} ,
		{"word":"dégelai","word_nosc":"degelai","lemma":"dégeler","pos":"VER"} ,
		{"word":"dégelait","word_nosc":"degelait","lemma":"dégeler","pos":"VER"} ,
		{"word":"dégelant","word_nosc":"degelant","lemma":"dégeler","pos":"VER"} ,
		{"word":"dégeler","word_nosc":"degeler","lemma":"dégeler","pos":"VER"} ,
		{"word":"dégelez","word_nosc":"degelez","lemma":"dégeler","pos":"VER"} ,
		{"word":"dégelât","word_nosc":"degelat","lemma":"dégeler","pos":"VER"} ,
		{"word":"dégelé","word_nosc":"degele","lemma":"dégeler","pos":"VER"} ,
		{"word":"dégelée","word_nosc":"degelee","lemma":"dégeler","pos":"VER"} ,
		{"word":"dégelées","word_nosc":"degelees","lemma":"dégeler","pos":"VER"} ,
		{"word":"dégelés","word_nosc":"degeles","lemma":"dégeler","pos":"VER"} ,
		{"word":"dégermait","word_nosc":"degermait","lemma":"dégermer","pos":"VER"} ,
		{"word":"dégingandé","word_nosc":"degingande","lemma":"dégingander","pos":"VER"} ,
		{"word":"dégingandée","word_nosc":"degingandee","lemma":"dégingander","pos":"VER"} ,
		{"word":"dégivrer","word_nosc":"degivrer","lemma":"dégivrer","pos":"VER"} ,
		{"word":"dégivré","word_nosc":"degivre","lemma":"dégivrer","pos":"VER"} ,
		{"word":"déglacer","word_nosc":"deglacer","lemma":"déglacer","pos":"VER"} ,
		{"word":"déglacera","word_nosc":"deglacera","lemma":"déglacer","pos":"VER"} ,
		{"word":"déglacez","word_nosc":"deglacez","lemma":"déglacer","pos":"VER"} ,
		{"word":"déglacé","word_nosc":"deglace","lemma":"déglacer","pos":"VER"} ,
		{"word":"déglingua","word_nosc":"deglingua","lemma":"déglinguer","pos":"VER"} ,
		{"word":"déglinguaient","word_nosc":"deglinguaient","lemma":"déglinguer","pos":"VER"} ,
		{"word":"déglinguait","word_nosc":"deglinguait","lemma":"déglinguer","pos":"VER"} ,
		{"word":"déglinguant","word_nosc":"deglinguant","lemma":"déglinguer","pos":"VER"} ,
		{"word":"déglingue","word_nosc":"deglingue","lemma":"déglinguer","pos":"VER"} ,
		{"word":"déglinguent","word_nosc":"deglinguent","lemma":"déglinguer","pos":"VER"} ,
		{"word":"déglinguer","word_nosc":"deglinguer","lemma":"déglinguer","pos":"VER"} ,
		{"word":"déglinguions","word_nosc":"deglinguions","lemma":"déglinguer","pos":"VER"} ,
		{"word":"déglingué","word_nosc":"deglingue","lemma":"déglinguer","pos":"VER"} ,
		{"word":"déglinguée","word_nosc":"deglinguee","lemma":"déglinguer","pos":"VER"} ,
		{"word":"déglinguées","word_nosc":"deglinguees","lemma":"déglinguer","pos":"VER"} ,
		{"word":"déglingués","word_nosc":"deglingues","lemma":"déglinguer","pos":"VER"} ,
		{"word":"dégluti","word_nosc":"degluti","lemma":"déglutir","pos":"VER"} ,
		{"word":"déglutie","word_nosc":"deglutie","lemma":"déglutir","pos":"VER"} ,
		{"word":"dégluties","word_nosc":"degluties","lemma":"déglutir","pos":"VER"} ,
		{"word":"déglutir","word_nosc":"deglutir","lemma":"déglutir","pos":"VER"} ,
		{"word":"déglutis","word_nosc":"deglutis","lemma":"déglutir","pos":"VER"} ,
		{"word":"déglutissait","word_nosc":"deglutissait","lemma":"déglutir","pos":"VER"} ,
		{"word":"déglutissant","word_nosc":"deglutissant","lemma":"déglutir","pos":"VER"} ,
		{"word":"déglutit","word_nosc":"deglutit","lemma":"déglutir","pos":"VER"} ,
		{"word":"dégobilla","word_nosc":"degobilla","lemma":"dégobiller","pos":"VER"} ,
		{"word":"dégobillaient","word_nosc":"degobillaient","lemma":"dégobiller","pos":"VER"} ,
		{"word":"dégobillait","word_nosc":"degobillait","lemma":"dégobiller","pos":"VER"} ,
		{"word":"dégobillant","word_nosc":"degobillant","lemma":"dégobiller","pos":"VER"} ,
		{"word":"dégobille","word_nosc":"degobille","lemma":"dégobiller","pos":"VER"} ,
		{"word":"dégobiller","word_nosc":"degobiller","lemma":"dégobiller","pos":"VER"} ,
		{"word":"dégobillé","word_nosc":"degobille","lemma":"dégobiller","pos":"VER"} ,
		{"word":"dégoisais","word_nosc":"degoisais","lemma":"dégoiser","pos":"VER"} ,
		{"word":"dégoisait","word_nosc":"degoisait","lemma":"dégoiser","pos":"VER"} ,
		{"word":"dégoisant","word_nosc":"degoisant","lemma":"dégoiser","pos":"VER"} ,
		{"word":"dégoise","word_nosc":"degoise","lemma":"dégoiser","pos":"VER"} ,
		{"word":"dégoisent","word_nosc":"degoisent","lemma":"dégoiser","pos":"VER"} ,
		{"word":"dégoiser","word_nosc":"degoiser","lemma":"dégoiser","pos":"VER"} ,
		{"word":"dégoiserai","word_nosc":"degoiserai","lemma":"dégoiser","pos":"VER"} ,
		{"word":"dégoises","word_nosc":"degoises","lemma":"dégoiser","pos":"VER"} ,
		{"word":"dégommait","word_nosc":"degommait","lemma":"dégommer","pos":"VER"} ,
		{"word":"dégommant","word_nosc":"degommant","lemma":"dégommer","pos":"VER"} ,
		{"word":"dégomme","word_nosc":"degomme","lemma":"dégommer","pos":"VER"} ,
		{"word":"dégomment","word_nosc":"degomment","lemma":"dégommer","pos":"VER"} ,
		{"word":"dégommer","word_nosc":"degommer","lemma":"dégommer","pos":"VER"} ,
		{"word":"dégommera","word_nosc":"degommera","lemma":"dégommer","pos":"VER"} ,
		{"word":"dégommerai","word_nosc":"degommerai","lemma":"dégommer","pos":"VER"} ,
		{"word":"dégommez","word_nosc":"degommez","lemma":"dégommer","pos":"VER"} ,
		{"word":"dégommons","word_nosc":"degommons","lemma":"dégommer","pos":"VER"} ,
		{"word":"dégommé","word_nosc":"degomme","lemma":"dégommer","pos":"VER"} ,
		{"word":"dégommée","word_nosc":"degommee","lemma":"dégommer","pos":"VER"} ,
		{"word":"dégommées","word_nosc":"degommees","lemma":"dégommer","pos":"VER"} ,
		{"word":"dégommés","word_nosc":"degommes","lemma":"dégommer","pos":"VER"} ,
		{"word":"dégonfla","word_nosc":"degonfla","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonflaient","word_nosc":"degonflaient","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonflais","word_nosc":"degonflais","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonflait","word_nosc":"degonflait","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonflant","word_nosc":"degonflant","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonfle","word_nosc":"degonfle","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonflent","word_nosc":"degonflent","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonfler","word_nosc":"degonfler","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonflera","word_nosc":"degonflera","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonfleraient","word_nosc":"degonfleraient","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonflerais","word_nosc":"degonflerais","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonflerait","word_nosc":"degonflerait","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonfleriez","word_nosc":"degonfleriez","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonfles","word_nosc":"degonfles","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonflez","word_nosc":"degonflez","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonflé","word_nosc":"degonfle","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonflée","word_nosc":"degonflee","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonflées","word_nosc":"degonflees","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégonflés","word_nosc":"degonfles","lemma":"dégonfler","pos":"VER"} ,
		{"word":"dégorge","word_nosc":"degorge","lemma":"dégorger","pos":"VER"} ,
		{"word":"dégorgeaient","word_nosc":"degorgeaient","lemma":"dégorger","pos":"VER"} ,
		{"word":"dégorgeais","word_nosc":"degorgeais","lemma":"dégorger","pos":"VER"} ,
		{"word":"dégorgeait","word_nosc":"degorgeait","lemma":"dégorger","pos":"VER"} ,
		{"word":"dégorgeant","word_nosc":"degorgeant","lemma":"dégorger","pos":"VER"} ,
		{"word":"dégorgent","word_nosc":"degorgent","lemma":"dégorger","pos":"VER"} ,
		{"word":"dégorger","word_nosc":"degorger","lemma":"dégorger","pos":"VER"} ,
		{"word":"dégorgeront","word_nosc":"degorgeront","lemma":"dégorger","pos":"VER"} ,
		{"word":"dégorgé","word_nosc":"degorge","lemma":"dégorger","pos":"VER"} ,
		{"word":"dégorgée","word_nosc":"degorgee","lemma":"dégorger","pos":"VER"} ,
		{"word":"dégorgées","word_nosc":"degorgees","lemma":"dégorger","pos":"VER"} ,
		{"word":"dégorgés","word_nosc":"degorges","lemma":"dégorger","pos":"VER"} ,
		{"word":"dégota","word_nosc":"degota","lemma":"dégoter","pos":"VER"} ,
		{"word":"dégotait","word_nosc":"degotait","lemma":"dégoter","pos":"VER"} ,
		{"word":"dégote","word_nosc":"degote","lemma":"dégoter","pos":"VER"} ,
		{"word":"dégoter","word_nosc":"degoter","lemma":"dégoter","pos":"VER"} ,
		{"word":"dégoterais","word_nosc":"degoterais","lemma":"dégoter","pos":"VER"} ,
		{"word":"dégoteras","word_nosc":"degoteras","lemma":"dégoter","pos":"VER"} ,
		{"word":"dégotez","word_nosc":"degotez","lemma":"dégoter","pos":"VER"} ,
		{"word":"dégotte","word_nosc":"degotte","lemma":"dégotter","pos":"VER"} ,
		{"word":"dégotter","word_nosc":"degotter","lemma":"dégotter","pos":"VER"} ,
		{"word":"dégottera","word_nosc":"degottera","lemma":"dégotter","pos":"VER"} ,
		{"word":"dégotterai","word_nosc":"degotterai","lemma":"dégotter","pos":"VER"} ,
		{"word":"dégotterait","word_nosc":"degotterait","lemma":"dégotter","pos":"VER"} ,
		{"word":"dégottes","word_nosc":"degottes","lemma":"dégotter","pos":"VER"} ,
		{"word":"dégotté","word_nosc":"degotte","lemma":"dégotter","pos":"VER"} ,
		{"word":"dégoté","word_nosc":"degote","lemma":"dégoter","pos":"VER"} ,
		{"word":"dégotée","word_nosc":"degotee","lemma":"dégoter","pos":"VER"} ,
		{"word":"dégotées","word_nosc":"degotees","lemma":"dégoter","pos":"VER"} ,
		{"word":"dégotés","word_nosc":"degotes","lemma":"dégoter","pos":"VER"} ,
		{"word":"dégoulina","word_nosc":"degoulina","lemma":"dégouliner","pos":"VER"} ,
		{"word":"dégoulinaient","word_nosc":"degoulinaient","lemma":"dégouliner","pos":"VER"} ,
		{"word":"dégoulinais","word_nosc":"degoulinais","lemma":"dégouliner","pos":"VER"} ,
		{"word":"dégoulinait","word_nosc":"degoulinait","lemma":"dégouliner","pos":"VER"} ,
		{"word":"dégoulinant","word_nosc":"degoulinant","lemma":"dégouliner","pos":"VER"} ,
		{"word":"dégouline","word_nosc":"degouline","lemma":"dégouliner","pos":"VER"} ,
		{"word":"dégoulinent","word_nosc":"degoulinent","lemma":"dégouliner","pos":"VER"} ,
		{"word":"dégouliner","word_nosc":"degouliner","lemma":"dégouliner","pos":"VER"} ,
		{"word":"dégoulines","word_nosc":"degoulines","lemma":"dégouliner","pos":"VER"} ,
		{"word":"dégoulinez","word_nosc":"degoulinez","lemma":"dégouliner","pos":"VER"} ,
		{"word":"dégoulinèrent","word_nosc":"degoulinerent","lemma":"dégouliner","pos":"VER"} ,
		{"word":"dégouliné","word_nosc":"degouline","lemma":"dégouliner","pos":"VER"} ,
		{"word":"dégoupille","word_nosc":"degoupille","lemma":"dégoupiller","pos":"VER"} ,
		{"word":"dégoupiller","word_nosc":"degoupiller","lemma":"dégoupiller","pos":"VER"} ,
		{"word":"dégoupillâmes","word_nosc":"degoupillames","lemma":"dégoupiller","pos":"VER"} ,
		{"word":"dégoupillé","word_nosc":"degoupille","lemma":"dégoupiller","pos":"VER"} ,
		{"word":"dégoupillée","word_nosc":"degoupillee","lemma":"dégoupiller","pos":"VER"} ,
		{"word":"dégoupillées","word_nosc":"degoupillees","lemma":"dégoupiller","pos":"VER"} ,
		{"word":"dégourdi","word_nosc":"degourdi","lemma":"dégourdir","pos":"VER"} ,
		{"word":"dégourdie","word_nosc":"degourdie","lemma":"dégourdir","pos":"VER"} ,
		{"word":"dégourdir","word_nosc":"degourdir","lemma":"dégourdir","pos":"VER"} ,
		{"word":"dégourdira","word_nosc":"degourdira","lemma":"dégourdir","pos":"VER"} ,
		{"word":"dégourdiraient","word_nosc":"degourdiraient","lemma":"dégourdir","pos":"VER"} ,
		{"word":"dégourdis","word_nosc":"degourdis","lemma":"dégourdir","pos":"VER"} ,
		{"word":"dégourdissais","word_nosc":"degourdissais","lemma":"dégourdir","pos":"VER"} ,
		{"word":"dégourdissait","word_nosc":"degourdissait","lemma":"dégourdir","pos":"VER"} ,
		{"word":"dégourdissant","word_nosc":"degourdissant","lemma":"dégourdir","pos":"VER"} ,
		{"word":"dégourdisse","word_nosc":"degourdisse","lemma":"dégourdir","pos":"VER"} ,
		{"word":"dégourdissions","word_nosc":"degourdissions","lemma":"dégourdir","pos":"VER"} ,
		{"word":"dégourdit","word_nosc":"degourdit","lemma":"dégourdir","pos":"VER"} ,
		{"word":"dégouttaient","word_nosc":"degouttaient","lemma":"dégoutter","pos":"VER"} ,
		{"word":"dégouttait","word_nosc":"degouttait","lemma":"dégoutter","pos":"VER"} ,
		{"word":"dégouttant","word_nosc":"degouttant","lemma":"dégoutter","pos":"VER"} ,
		{"word":"dégoutte","word_nosc":"degoutte","lemma":"dégoutter","pos":"VER"} ,
		{"word":"dégoutter","word_nosc":"degoutter","lemma":"dégoutter","pos":"VER"} ,
		{"word":"dégoûta","word_nosc":"degouta","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûtai","word_nosc":"degoutai","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûtaient","word_nosc":"degoutaient","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûtais","word_nosc":"degoutais","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûtait","word_nosc":"degoutait","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûtant","word_nosc":"degoutant","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûte","word_nosc":"degoute","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûtent","word_nosc":"degoutent","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûter","word_nosc":"degouter","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûtera","word_nosc":"degoutera","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûteraient","word_nosc":"degouteraient","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûterais","word_nosc":"degouterais","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûterait","word_nosc":"degouterait","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûtes","word_nosc":"degoutes","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûtez","word_nosc":"degoutez","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûtât","word_nosc":"degoutat","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûtèrent","word_nosc":"degouterent","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûté","word_nosc":"degoute","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûtée","word_nosc":"degoutee","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégoûtés","word_nosc":"degoutes","lemma":"dégoûter","pos":"VER"} ,
		{"word":"dégrada","word_nosc":"degrada","lemma":"dégrader","pos":"VER"} ,
		{"word":"dégradaient","word_nosc":"degradaient","lemma":"dégrader","pos":"VER"} ,
		{"word":"dégradait","word_nosc":"degradait","lemma":"dégrader","pos":"VER"} ,
		{"word":"dégradant","word_nosc":"degradant","lemma":"dégrader","pos":"VER"} ,
		{"word":"dégrade","word_nosc":"degrade","lemma":"dégrader","pos":"VER"} ,
		{"word":"dégradent","word_nosc":"degradent","lemma":"dégrader","pos":"VER"} ,
		{"word":"dégrader","word_nosc":"degrader","lemma":"dégrader","pos":"VER"} ,
		{"word":"dégrades","word_nosc":"degrades","lemma":"dégrader","pos":"VER"} ,
		{"word":"dégradez","word_nosc":"degradez","lemma":"dégrader","pos":"VER"} ,
		{"word":"dégradé","word_nosc":"degrade","lemma":"dégrader","pos":"VER"} ,
		{"word":"dégradée","word_nosc":"degradee","lemma":"dégrader","pos":"VER"} ,
		{"word":"dégradées","word_nosc":"degradees","lemma":"dégrader","pos":"VER"} ,
		{"word":"dégradés","word_nosc":"degrades","lemma":"dégrader","pos":"VER"} ,
		{"word":"dégrafa","word_nosc":"degrafa","lemma":"dégrafer","pos":"VER"} ,
		{"word":"dégrafaient","word_nosc":"degrafaient","lemma":"dégrafer","pos":"VER"} ,
		{"word":"dégrafait","word_nosc":"degrafait","lemma":"dégrafer","pos":"VER"} ,
		{"word":"dégrafant","word_nosc":"degrafant","lemma":"dégrafer","pos":"VER"} ,
		{"word":"dégrafe","word_nosc":"degrafe","lemma":"dégrafer","pos":"VER"} ,
		{"word":"dégrafer","word_nosc":"degrafer","lemma":"dégrafer","pos":"VER"} ,
		{"word":"dégrafera","word_nosc":"degrafera","lemma":"dégrafer","pos":"VER"} ,
		{"word":"dégraferait","word_nosc":"degraferait","lemma":"dégrafer","pos":"VER"} ,
		{"word":"dégrafez","word_nosc":"degrafez","lemma":"dégrafer","pos":"VER"} ,
		{"word":"dégrafât","word_nosc":"degrafat","lemma":"dégrafer","pos":"VER"} ,
		{"word":"dégrafé","word_nosc":"degrafe","lemma":"dégrafer","pos":"VER"} ,
		{"word":"dégrafée","word_nosc":"degrafee","lemma":"dégrafer","pos":"VER"} ,
		{"word":"dégrafées","word_nosc":"degrafees","lemma":"dégrafer","pos":"VER"} ,
		{"word":"dégrafés","word_nosc":"degrafes","lemma":"dégrafer","pos":"VER"} ,
		{"word":"dégraissant","word_nosc":"degraissant","lemma":"dégraisser","pos":"VER"} ,
		{"word":"dégraisse","word_nosc":"degraisse","lemma":"dégraisser","pos":"VER"} ,
		{"word":"dégraissent","word_nosc":"degraissent","lemma":"dégraisser","pos":"VER"} ,
		{"word":"dégraisser","word_nosc":"degraisser","lemma":"dégraisser","pos":"VER"} ,
		{"word":"dégraissez","word_nosc":"degraissez","lemma":"dégraisser","pos":"VER"} ,
		{"word":"dégraissé","word_nosc":"degraisse","lemma":"dégraisser","pos":"VER"} ,
		{"word":"dégraissés","word_nosc":"degraisses","lemma":"dégraisser","pos":"VER"} ,
		{"word":"dégriffe","word_nosc":"degriffe","lemma":"dégriffer","pos":"VER"} ,
		{"word":"dégriffer","word_nosc":"degriffer","lemma":"dégriffer","pos":"VER"} ,
		{"word":"dégriffé","word_nosc":"degriffe","lemma":"dégriffer","pos":"VER"} ,
		{"word":"dégringola","word_nosc":"degringola","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringolai","word_nosc":"degringolai","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringolaient","word_nosc":"degringolaient","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringolait","word_nosc":"degringolait","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringolant","word_nosc":"degringolant","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringole","word_nosc":"degringole","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringolent","word_nosc":"degringolent","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringoler","word_nosc":"degringoler","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringoleraient","word_nosc":"degringoleraient","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringolerais","word_nosc":"degringolerais","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringoleront","word_nosc":"degringoleront","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringolions","word_nosc":"degringolions","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringolâmes","word_nosc":"degringolames","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringolèrent","word_nosc":"degringolerent","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringolé","word_nosc":"degringole","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringolée","word_nosc":"degringolee","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégringolés","word_nosc":"degringoles","lemma":"dégringoler","pos":"VER"} ,
		{"word":"dégripper","word_nosc":"degripper","lemma":"dégripper","pos":"VER"} ,
		{"word":"dégrisa","word_nosc":"degrisa","lemma":"dégriser","pos":"VER"} ,
		{"word":"dégrisait","word_nosc":"degrisait","lemma":"dégriser","pos":"VER"} ,
		{"word":"dégrise","word_nosc":"degrise","lemma":"dégriser","pos":"VER"} ,
		{"word":"dégriser","word_nosc":"degriser","lemma":"dégriser","pos":"VER"} ,
		{"word":"dégrisât","word_nosc":"degrisat","lemma":"dégriser","pos":"VER"} ,
		{"word":"dégrisé","word_nosc":"degrise","lemma":"dégriser","pos":"VER"} ,
		{"word":"dégrisée","word_nosc":"degrisee","lemma":"dégriser","pos":"VER"} ,
		{"word":"dégrisés","word_nosc":"degrises","lemma":"dégriser","pos":"VER"} ,
		{"word":"dégrossir","word_nosc":"degrossir","lemma":"dégrossir","pos":"VER"} ,
		{"word":"dégrossissant","word_nosc":"degrossissant","lemma":"dégrossir","pos":"VER"} ,
		{"word":"dégrouille","word_nosc":"degrouille","lemma":"dégrouiller","pos":"VER"} ,
		{"word":"dégrouiller","word_nosc":"degrouiller","lemma":"dégrouiller","pos":"VER"} ,
		{"word":"dégrène","word_nosc":"degrene","lemma":"dégrèner","pos":"VER"} ,
		{"word":"dégrée","word_nosc":"degree","lemma":"dégréer","pos":"VER"} ,
		{"word":"dégréner","word_nosc":"degrener","lemma":"dégréner","pos":"VER"} ,
		{"word":"déguerpi","word_nosc":"deguerpi","lemma":"déguerpir","pos":"VER"} ,
		{"word":"déguerpir","word_nosc":"deguerpir","lemma":"déguerpir","pos":"VER"} ,
		{"word":"déguerpirent","word_nosc":"deguerpirent","lemma":"déguerpir","pos":"VER"} ,
		{"word":"déguerpis","word_nosc":"deguerpis","lemma":"déguerpir","pos":"VER"} ,
		{"word":"déguerpisse","word_nosc":"deguerpisse","lemma":"déguerpir","pos":"VER"} ,
		{"word":"déguerpissent","word_nosc":"deguerpissent","lemma":"déguerpir","pos":"VER"} ,
		{"word":"déguerpissez","word_nosc":"deguerpissez","lemma":"déguerpir","pos":"VER"} ,
		{"word":"déguerpissiez","word_nosc":"deguerpissiez","lemma":"déguerpir","pos":"VER"} ,
		{"word":"déguerpissons","word_nosc":"deguerpissons","lemma":"déguerpir","pos":"VER"} ,
		{"word":"déguerpit","word_nosc":"deguerpit","lemma":"déguerpir","pos":"VER"} ,
		{"word":"dégueula","word_nosc":"degueula","lemma":"dégueuler","pos":"VER"} ,
		{"word":"dégueulais","word_nosc":"degueulais","lemma":"dégueuler","pos":"VER"} ,
		{"word":"dégueulait","word_nosc":"degueulait","lemma":"dégueuler","pos":"VER"} ,
		{"word":"dégueulant","word_nosc":"degueulant","lemma":"dégueuler","pos":"VER"} ,
		{"word":"dégueulasse","word_nosc":"degueulasse","lemma":"dégueulasser","pos":"VER"} ,
		{"word":"dégueulasser","word_nosc":"degueulasser","lemma":"dégueulasser","pos":"VER"} ,
		{"word":"dégueulasses","word_nosc":"degueulasses","lemma":"dégueulasser","pos":"VER"} ,
		{"word":"dégueulassé","word_nosc":"degueulasse","lemma":"dégueulasser","pos":"VER"} ,
		{"word":"dégueulassés","word_nosc":"degueulasses","lemma":"dégueulasser","pos":"VER"} ,
		{"word":"dégueule","word_nosc":"degueule","lemma":"dégueuler","pos":"VER"} ,
		{"word":"dégueulent","word_nosc":"degueulent","lemma":"dégueuler","pos":"VER"} ,
		{"word":"dégueuler","word_nosc":"degueuler","lemma":"dégueuler","pos":"VER"} ,
		{"word":"dégueulera","word_nosc":"degueulera","lemma":"dégueuler","pos":"VER"} ,
		{"word":"dégueules","word_nosc":"degueules","lemma":"dégueuler","pos":"VER"} ,
		{"word":"dégueulez","word_nosc":"degueulez","lemma":"dégueuler","pos":"VER"} ,
		{"word":"dégueulé","word_nosc":"degueule","lemma":"dégueuler","pos":"VER"} ,
		{"word":"dégueulées","word_nosc":"degueulees","lemma":"dégueuler","pos":"VER"} ,
		{"word":"déguisa","word_nosc":"deguisa","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguisaient","word_nosc":"deguisaient","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguisais","word_nosc":"deguisais","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguisait","word_nosc":"deguisait","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguisant","word_nosc":"deguisant","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguise","word_nosc":"deguise","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguisent","word_nosc":"deguisent","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguiser","word_nosc":"deguiser","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguisera","word_nosc":"deguisera","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguiserai","word_nosc":"deguiserai","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguiserais","word_nosc":"deguiserais","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguiserait","word_nosc":"deguiserait","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguises","word_nosc":"deguises","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguisez","word_nosc":"deguisez","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguisiez","word_nosc":"deguisiez","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguisions","word_nosc":"deguisions","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguisé","word_nosc":"deguise","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguisée","word_nosc":"deguisee","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguisées","word_nosc":"deguisees","lemma":"déguiser","pos":"VER"} ,
		{"word":"déguisés","word_nosc":"deguises","lemma":"déguiser","pos":"VER"} ,
		{"word":"dégurgitant","word_nosc":"degurgitant","lemma":"dégurgiter","pos":"VER"} ,
		{"word":"dégusta","word_nosc":"degusta","lemma":"déguster","pos":"VER"} ,
		{"word":"dégustai","word_nosc":"degustai","lemma":"déguster","pos":"VER"} ,
		{"word":"dégustaient","word_nosc":"degustaient","lemma":"déguster","pos":"VER"} ,
		{"word":"dégustais","word_nosc":"degustais","lemma":"déguster","pos":"VER"} ,
		{"word":"dégustait","word_nosc":"degustait","lemma":"déguster","pos":"VER"} ,
		{"word":"dégustant","word_nosc":"degustant","lemma":"déguster","pos":"VER"} ,
		{"word":"déguste","word_nosc":"deguste","lemma":"déguster","pos":"VER"} ,
		{"word":"dégustent","word_nosc":"degustent","lemma":"déguster","pos":"VER"} ,
		{"word":"déguster","word_nosc":"deguster","lemma":"déguster","pos":"VER"} ,
		{"word":"dégustera","word_nosc":"degustera","lemma":"déguster","pos":"VER"} ,
		{"word":"dégusteras","word_nosc":"degusteras","lemma":"déguster","pos":"VER"} ,
		{"word":"dégusterons","word_nosc":"degusterons","lemma":"déguster","pos":"VER"} ,
		{"word":"dégustez","word_nosc":"degustez","lemma":"déguster","pos":"VER"} ,
		{"word":"dégustons","word_nosc":"degustons","lemma":"déguster","pos":"VER"} ,
		{"word":"dégustâmes","word_nosc":"degustames","lemma":"déguster","pos":"VER"} ,
		{"word":"dégustèrent","word_nosc":"degusterent","lemma":"déguster","pos":"VER"} ,
		{"word":"dégusté","word_nosc":"deguste","lemma":"déguster","pos":"VER"} ,
		{"word":"dégustée","word_nosc":"degustee","lemma":"déguster","pos":"VER"} ,
		{"word":"dégustées","word_nosc":"degustees","lemma":"déguster","pos":"VER"} ,
		{"word":"dégustés","word_nosc":"degustes","lemma":"déguster","pos":"VER"} ,
		{"word":"dégèle","word_nosc":"degele","lemma":"dégeler","pos":"VER"} ,
		{"word":"dégèlent","word_nosc":"degelent","lemma":"dégeler","pos":"VER"} ,
		{"word":"dégèlera","word_nosc":"degelera","lemma":"dégeler","pos":"VER"} ,
		{"word":"dégèlerait","word_nosc":"degelerait","lemma":"dégeler","pos":"VER"} ,
		{"word":"dégénère","word_nosc":"degenere","lemma":"dégénérer","pos":"VER"} ,
		{"word":"dégénèrent","word_nosc":"degenerent","lemma":"dégénérer","pos":"VER"} ,
		{"word":"dégénéra","word_nosc":"degenera","lemma":"dégénérer","pos":"VER"} ,
		{"word":"dégénéraient","word_nosc":"degeneraient","lemma":"dégénérer","pos":"VER"} ,
		{"word":"dégénérait","word_nosc":"degenerait","lemma":"dégénérer","pos":"VER"} ,
		{"word":"dégénérant","word_nosc":"degenerant","lemma":"dégénérer","pos":"VER"} ,
		{"word":"dégénérer","word_nosc":"degenerer","lemma":"dégénérer","pos":"VER"} ,
		{"word":"dégénérât","word_nosc":"degenerat","lemma":"dégénérer","pos":"VER"} ,
		{"word":"dégénéré","word_nosc":"degenere","lemma":"dégénérer","pos":"VER"} ,
		{"word":"dégénérée","word_nosc":"degeneree","lemma":"dégénérer","pos":"VER"} ,
		{"word":"dégénérées","word_nosc":"degenerees","lemma":"dégénérer","pos":"VER"} ,
		{"word":"dégénérés","word_nosc":"degeneres","lemma":"dégénérer","pos":"VER"} ,
		{"word":"déhale","word_nosc":"dehale","lemma":"déhaler","pos":"VER"} ,
		{"word":"déhaler","word_nosc":"dehaler","lemma":"déhaler","pos":"VER"} ,
		{"word":"déhalées","word_nosc":"dehalees","lemma":"déhaler","pos":"VER"} ,
		{"word":"déhancha","word_nosc":"dehancha","lemma":"déhancher","pos":"VER"} ,
		{"word":"déhanchaient","word_nosc":"dehanchaient","lemma":"déhancher","pos":"VER"} ,
		{"word":"déhanchait","word_nosc":"dehanchait","lemma":"déhancher","pos":"VER"} ,
		{"word":"déhanchant","word_nosc":"dehanchant","lemma":"déhancher","pos":"VER"} ,
		{"word":"déhanche","word_nosc":"dehanche","lemma":"déhancher","pos":"VER"} ,
		{"word":"déhanchent","word_nosc":"dehanchent","lemma":"déhancher","pos":"VER"} ,
		{"word":"déhancher","word_nosc":"dehancher","lemma":"déhancher","pos":"VER"} ,
		{"word":"déhanchez","word_nosc":"dehanchez","lemma":"déhancher","pos":"VER"} ,
		{"word":"déhanché","word_nosc":"dehanche","lemma":"déhancher","pos":"VER"} ,
		{"word":"déhanchée","word_nosc":"dehanchee","lemma":"déhancher","pos":"VER"} ,
		{"word":"déhanchées","word_nosc":"dehanchees","lemma":"déhancher","pos":"VER"} ,
		{"word":"déharnacha","word_nosc":"deharnacha","lemma":"déharnacher","pos":"VER"} ,
		{"word":"déharnachées","word_nosc":"deharnachees","lemma":"déharnacher","pos":"VER"} ,
		{"word":"déhotte","word_nosc":"dehotte","lemma":"déhotter","pos":"VER"} ,
		{"word":"déhotter","word_nosc":"dehotter","lemma":"déhotter","pos":"VER"} ,
		{"word":"déhotté","word_nosc":"dehotte","lemma":"déhotter","pos":"VER"} ,
		{"word":"déhottée","word_nosc":"dehottee","lemma":"déhotter","pos":"VER"} ,
		{"word":"déifia","word_nosc":"deifia","lemma":"déifier","pos":"VER"} ,
		{"word":"déifier","word_nosc":"deifier","lemma":"déifier","pos":"VER"} ,
		{"word":"déifié","word_nosc":"deifie","lemma":"déifier","pos":"VER"} ,
		{"word":"déifiés","word_nosc":"deifies","lemma":"déifier","pos":"VER"} ,
		{"word":"déjanta","word_nosc":"dejanta","lemma":"déjanter","pos":"VER"} ,
		{"word":"déjantait","word_nosc":"dejantait","lemma":"déjanter","pos":"VER"} ,
		{"word":"déjante","word_nosc":"dejante","lemma":"déjanter","pos":"VER"} ,
		{"word":"déjantent","word_nosc":"dejantent","lemma":"déjanter","pos":"VER"} ,
		{"word":"déjanter","word_nosc":"dejanter","lemma":"déjanter","pos":"VER"} ,
		{"word":"déjanterais","word_nosc":"dejanterais","lemma":"déjanter","pos":"VER"} ,
		{"word":"déjantes","word_nosc":"dejantes","lemma":"déjanter","pos":"VER"} ,
		{"word":"déjanté","word_nosc":"dejante","lemma":"déjanter","pos":"VER"} ,
		{"word":"déjantée","word_nosc":"dejantee","lemma":"déjanter","pos":"VER"} ,
		{"word":"déjantés","word_nosc":"dejantes","lemma":"déjanter","pos":"VER"} ,
		{"word":"déjaugeant","word_nosc":"dejaugeant","lemma":"déjauger","pos":"VER"} ,
		{"word":"déjetait","word_nosc":"dejetait","lemma":"déjeter","pos":"VER"} ,
		{"word":"déjeté","word_nosc":"dejete","lemma":"déjeter","pos":"VER"} ,
		{"word":"déjetée","word_nosc":"dejetee","lemma":"déjeter","pos":"VER"} ,
		{"word":"déjeuna","word_nosc":"dejeuna","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunai","word_nosc":"dejeunai","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunaient","word_nosc":"dejeunaient","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunais","word_nosc":"dejeunais","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunait","word_nosc":"dejeunait","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunant","word_nosc":"dejeunant","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeune","word_nosc":"dejeune","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunent","word_nosc":"dejeunent","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeuner","word_nosc":"dejeuner","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunera","word_nosc":"dejeunera","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunerai","word_nosc":"dejeunerai","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeuneraient","word_nosc":"dejeuneraient","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunerait","word_nosc":"dejeunerait","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeuneras","word_nosc":"dejeuneras","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunerez","word_nosc":"dejeunerez","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunerions","word_nosc":"dejeunerions","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunerons","word_nosc":"dejeunerons","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunes","word_nosc":"dejeunes","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunez","word_nosc":"dejeunez","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeuniez","word_nosc":"dejeuniez","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunions","word_nosc":"dejeunions","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunons","word_nosc":"dejeunons","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunâmes","word_nosc":"dejeunames","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunât","word_nosc":"dejeunat","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeunèrent","word_nosc":"dejeunerent","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjeuné","word_nosc":"dejeune","lemma":"déjeuner","pos":"VER"} ,
		{"word":"déjoua","word_nosc":"dejoua","lemma":"déjouer","pos":"VER"} ,
		{"word":"déjouait","word_nosc":"dejouait","lemma":"déjouer","pos":"VER"} ,
		{"word":"déjouant","word_nosc":"dejouant","lemma":"déjouer","pos":"VER"} ,
		{"word":"déjoue","word_nosc":"dejoue","lemma":"déjouer","pos":"VER"} ,
		{"word":"déjouent","word_nosc":"dejouent","lemma":"déjouer","pos":"VER"} ,
		{"word":"déjouer","word_nosc":"dejouer","lemma":"déjouer","pos":"VER"} ,
		{"word":"déjouerai","word_nosc":"dejouerai","lemma":"déjouer","pos":"VER"} ,
		{"word":"déjouerez","word_nosc":"dejouerez","lemma":"déjouer","pos":"VER"} ,
		{"word":"déjouerions","word_nosc":"dejouerions","lemma":"déjouer","pos":"VER"} ,
		{"word":"déjouez","word_nosc":"dejouez","lemma":"déjouer","pos":"VER"} ,
		{"word":"déjouons","word_nosc":"dejouons","lemma":"déjouer","pos":"VER"} ,
		{"word":"déjoué","word_nosc":"dejoue","lemma":"déjouer","pos":"VER"} ,
		{"word":"déjouée","word_nosc":"dejouee","lemma":"déjouer","pos":"VER"} ,
		{"word":"déjouées","word_nosc":"dejouees","lemma":"déjouer","pos":"VER"} ,
		{"word":"déjoués","word_nosc":"dejoues","lemma":"déjouer","pos":"VER"} ,
		{"word":"déjuchaient","word_nosc":"dejuchaient","lemma":"déjucher","pos":"VER"} ,
		{"word":"déjuger","word_nosc":"dejuger","lemma":"déjuger","pos":"VER"} ,
		{"word":"déjugé","word_nosc":"dejuge","lemma":"déjuger","pos":"VER"} ,
		{"word":"délabre","word_nosc":"delabre","lemma":"délabrer","pos":"VER"} ,
		{"word":"délabrer","word_nosc":"delabrer","lemma":"délabrer","pos":"VER"} ,
		{"word":"délabré","word_nosc":"delabre","lemma":"délabrer","pos":"VER"} ,
		{"word":"délabrée","word_nosc":"delabree","lemma":"délabrer","pos":"VER"} ,
		{"word":"délabrées","word_nosc":"delabrees","lemma":"délabrer","pos":"VER"} ,
		{"word":"délabrés","word_nosc":"delabres","lemma":"délabrer","pos":"VER"} ,
		{"word":"délabyrinthez","word_nosc":"delabyrinthez","lemma":"délabyrinther","pos":"VER"} ,
		{"word":"délace","word_nosc":"delace","lemma":"délacer","pos":"VER"} ,
		{"word":"délacer","word_nosc":"delacer","lemma":"délacer","pos":"VER"} ,
		{"word":"délacèrent","word_nosc":"delacerent","lemma":"délacer","pos":"VER"} ,
		{"word":"délacé","word_nosc":"delace","lemma":"délacer","pos":"VER"} ,
		{"word":"délacées","word_nosc":"delacees","lemma":"délacer","pos":"VER"} ,
		{"word":"délaie","word_nosc":"delaie","lemma":"délayer","pos":"VER"} ,
		{"word":"délaissa","word_nosc":"delaissa","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaissaient","word_nosc":"delaissaient","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaissais","word_nosc":"delaissais","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaissait","word_nosc":"delaissait","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaissant","word_nosc":"delaissant","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaisse","word_nosc":"delaisse","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaissent","word_nosc":"delaissent","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaisser","word_nosc":"delaisser","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaisserait","word_nosc":"delaisserait","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaisses","word_nosc":"delaisses","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaissions","word_nosc":"delaissions","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaissèrent","word_nosc":"delaisserent","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaissé","word_nosc":"delaisse","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaissée","word_nosc":"delaissee","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaissées","word_nosc":"delaissees","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaissés","word_nosc":"delaisses","lemma":"délaisser","pos":"VER"} ,
		{"word":"délaita","word_nosc":"delaita","lemma":"délaiter","pos":"VER"} ,
		{"word":"délaite","word_nosc":"delaite","lemma":"délaiter","pos":"VER"} ,
		{"word":"délardant","word_nosc":"delardant","lemma":"délarder","pos":"VER"} ,
		{"word":"délassa","word_nosc":"delassa","lemma":"délasser","pos":"VER"} ,
		{"word":"délassait","word_nosc":"delassait","lemma":"délasser","pos":"VER"} ,
		{"word":"délasse","word_nosc":"delasse","lemma":"délasser","pos":"VER"} ,
		{"word":"délasser","word_nosc":"delasser","lemma":"délasser","pos":"VER"} ,
		{"word":"délassera","word_nosc":"delassera","lemma":"délasser","pos":"VER"} ,
		{"word":"délasseront","word_nosc":"delasseront","lemma":"délasser","pos":"VER"} ,
		{"word":"délassés","word_nosc":"delasses","lemma":"délasser","pos":"VER"} ,
		{"word":"délavait","word_nosc":"delavait","lemma":"délaver","pos":"VER"} ,
		{"word":"délavant","word_nosc":"delavant","lemma":"délaver","pos":"VER"} ,
		{"word":"délave","word_nosc":"delave","lemma":"délaver","pos":"VER"} ,
		{"word":"délavent","word_nosc":"delavent","lemma":"délaver","pos":"VER"} ,
		{"word":"délaver","word_nosc":"delaver","lemma":"délaver","pos":"VER"} ,
		{"word":"délavé","word_nosc":"delave","lemma":"délaver","pos":"VER"} ,
		{"word":"délavée","word_nosc":"delavee","lemma":"délaver","pos":"VER"} ,
		{"word":"délavées","word_nosc":"delavees","lemma":"délaver","pos":"VER"} ,
		{"word":"délavés","word_nosc":"delaves","lemma":"délaver","pos":"VER"} ,
		{"word":"délaya","word_nosc":"delaya","lemma":"délayer","pos":"VER"} ,
		{"word":"délayait","word_nosc":"delayait","lemma":"délayer","pos":"VER"} ,
		{"word":"délayant","word_nosc":"delayant","lemma":"délayer","pos":"VER"} ,
		{"word":"délaye","word_nosc":"delaye","lemma":"délayer","pos":"VER"} ,
		{"word":"délayent","word_nosc":"delayent","lemma":"délayer","pos":"VER"} ,
		{"word":"délayer","word_nosc":"delayer","lemma":"délayer","pos":"VER"} ,
		{"word":"délayé","word_nosc":"delaye","lemma":"délayer","pos":"VER"} ,
		{"word":"délayée","word_nosc":"delayee","lemma":"délayer","pos":"VER"} ,
		{"word":"délayés","word_nosc":"delayes","lemma":"délayer","pos":"VER"} ,
		{"word":"délaça","word_nosc":"delaca","lemma":"délacer","pos":"VER"} ,
		{"word":"délaçais","word_nosc":"delacais","lemma":"délacer","pos":"VER"} ,
		{"word":"délaçait","word_nosc":"delacait","lemma":"délacer","pos":"VER"} ,
		{"word":"délaçant","word_nosc":"delacant","lemma":"délacer","pos":"VER"} ,
		{"word":"délectai","word_nosc":"delectai","lemma":"délecter","pos":"VER"} ,
		{"word":"délectaient","word_nosc":"delectaient","lemma":"délecter","pos":"VER"} ,
		{"word":"délectais","word_nosc":"delectais","lemma":"délecter","pos":"VER"} ,
		{"word":"délectait","word_nosc":"delectait","lemma":"délecter","pos":"VER"} ,
		{"word":"délectant","word_nosc":"delectant","lemma":"délecter","pos":"VER"} ,
		{"word":"délecte","word_nosc":"delecte","lemma":"délecter","pos":"VER"} ,
		{"word":"délectent","word_nosc":"delectent","lemma":"délecter","pos":"VER"} ,
		{"word":"délecter","word_nosc":"delecter","lemma":"délecter","pos":"VER"} ,
		{"word":"délectes","word_nosc":"delectes","lemma":"délecter","pos":"VER"} ,
		{"word":"délectez","word_nosc":"delectez","lemma":"délecter","pos":"VER"} ,
		{"word":"délections","word_nosc":"delections","lemma":"délecter","pos":"VER"} ,
		{"word":"délectât","word_nosc":"delectat","lemma":"délecter","pos":"VER"} ,
		{"word":"délecté","word_nosc":"delecte","lemma":"délecter","pos":"VER"} ,
		{"word":"délestant","word_nosc":"delestant","lemma":"délester","pos":"VER"} ,
		{"word":"déleste","word_nosc":"deleste","lemma":"délester","pos":"VER"} ,
		{"word":"délester","word_nosc":"delester","lemma":"délester","pos":"VER"} ,
		{"word":"délestons","word_nosc":"delestons","lemma":"délester","pos":"VER"} ,
		{"word":"délestèrent","word_nosc":"delesterent","lemma":"délester","pos":"VER"} ,
		{"word":"délesté","word_nosc":"deleste","lemma":"délester","pos":"VER"} ,
		{"word":"délestée","word_nosc":"delestee","lemma":"délester","pos":"VER"} ,
		{"word":"délestées","word_nosc":"delestees","lemma":"délester","pos":"VER"} ,
		{"word":"délestés","word_nosc":"delestes","lemma":"délester","pos":"VER"} ,
		{"word":"délia","word_nosc":"delia","lemma":"délier","pos":"VER"} ,
		{"word":"déliaient","word_nosc":"deliaient","lemma":"délier","pos":"VER"} ,
		{"word":"déliait","word_nosc":"deliait","lemma":"délier","pos":"VER"} ,
		{"word":"déliant","word_nosc":"deliant","lemma":"délier","pos":"VER"} ,
		{"word":"délibère","word_nosc":"delibere","lemma":"délibérer","pos":"VER"} ,
		{"word":"délibèrent","word_nosc":"deliberent","lemma":"délibérer","pos":"VER"} ,
		{"word":"délibéra","word_nosc":"delibera","lemma":"délibérer","pos":"VER"} ,
		{"word":"délibéraient","word_nosc":"deliberaient","lemma":"délibérer","pos":"VER"} ,
		{"word":"délibérait","word_nosc":"deliberait","lemma":"délibérer","pos":"VER"} ,
		{"word":"délibérant","word_nosc":"deliberant","lemma":"délibérer","pos":"VER"} ,
		{"word":"délibérer","word_nosc":"deliberer","lemma":"délibérer","pos":"VER"} ,
		{"word":"délibérera","word_nosc":"deliberera","lemma":"délibérer","pos":"VER"} ,
		{"word":"délibéreraient","word_nosc":"delibereraient","lemma":"délibérer","pos":"VER"} ,
		{"word":"délibérions","word_nosc":"deliberions","lemma":"délibérer","pos":"VER"} ,
		{"word":"délibérèrent","word_nosc":"delibererent","lemma":"délibérer","pos":"VER"} ,
		{"word":"délibéré","word_nosc":"delibere","lemma":"délibérer","pos":"VER"} ,
		{"word":"délibérée","word_nosc":"deliberee","lemma":"délibérer","pos":"VER"} ,
		{"word":"délibérées","word_nosc":"deliberees","lemma":"délibérer","pos":"VER"} ,
		{"word":"délibérés","word_nosc":"deliberes","lemma":"délibérer","pos":"VER"} ,
		{"word":"délie","word_nosc":"delie","lemma":"délier","pos":"VER"} ,
		{"word":"délient","word_nosc":"delient","lemma":"délier","pos":"VER"} ,
		{"word":"délier","word_nosc":"delier","lemma":"délier","pos":"VER"} ,
		{"word":"délieraient","word_nosc":"delieraient","lemma":"délier","pos":"VER"} ,
		{"word":"délieras","word_nosc":"delieras","lemma":"délier","pos":"VER"} ,
		{"word":"déliez","word_nosc":"deliez","lemma":"délier","pos":"VER"} ,
		{"word":"délimitaient","word_nosc":"delimitaient","lemma":"délimiter","pos":"VER"} ,
		{"word":"délimitais","word_nosc":"delimitais","lemma":"délimiter","pos":"VER"} ,
		{"word":"délimitait","word_nosc":"delimitait","lemma":"délimiter","pos":"VER"} ,
		{"word":"délimitant","word_nosc":"delimitant","lemma":"délimiter","pos":"VER"} ,
		{"word":"délimite","word_nosc":"delimite","lemma":"délimiter","pos":"VER"} ,
		{"word":"délimitent","word_nosc":"delimitent","lemma":"délimiter","pos":"VER"} ,
		{"word":"délimiter","word_nosc":"delimiter","lemma":"délimiter","pos":"VER"} ,
		{"word":"délimitez","word_nosc":"delimitez","lemma":"délimiter","pos":"VER"} ,
		{"word":"délimitions","word_nosc":"delimitions","lemma":"délimiter","pos":"VER"} ,
		{"word":"délimitons","word_nosc":"delimitons","lemma":"délimiter","pos":"VER"} ,
		{"word":"délimité","word_nosc":"delimite","lemma":"délimiter","pos":"VER"} ,
		{"word":"délimitée","word_nosc":"delimitee","lemma":"délimiter","pos":"VER"} ,
		{"word":"délimitées","word_nosc":"delimitees","lemma":"délimiter","pos":"VER"} ,
		{"word":"délimités","word_nosc":"delimites","lemma":"délimiter","pos":"VER"} ,
		{"word":"délira","word_nosc":"delira","lemma":"délirer","pos":"VER"} ,
		{"word":"délirai","word_nosc":"delirai","lemma":"délirer","pos":"VER"} ,
		{"word":"déliraient","word_nosc":"deliraient","lemma":"délirer","pos":"VER"} ,
		{"word":"délirais","word_nosc":"delirais","lemma":"délirer","pos":"VER"} ,
		{"word":"délirait","word_nosc":"delirait","lemma":"délirer","pos":"VER"} ,
		{"word":"délirant","word_nosc":"delirant","lemma":"délirer","pos":"VER"} ,
		{"word":"délirassent","word_nosc":"delirassent","lemma":"délirer","pos":"VER"} ,
		{"word":"délire","word_nosc":"delire","lemma":"délirer","pos":"VER"} ,
		{"word":"délirent","word_nosc":"delirent","lemma":"délirer","pos":"VER"} ,
		{"word":"délirer","word_nosc":"delirer","lemma":"délirer","pos":"VER"} ,
		{"word":"délireras","word_nosc":"delireras","lemma":"délirer","pos":"VER"} ,
		{"word":"délires","word_nosc":"delires","lemma":"délirer","pos":"VER"} ,
		{"word":"délirez","word_nosc":"delirez","lemma":"délirer","pos":"VER"} ,
		{"word":"déliriez","word_nosc":"deliriez","lemma":"délirer","pos":"VER"} ,
		{"word":"déliré","word_nosc":"delire","lemma":"délirer","pos":"VER"} ,
		{"word":"délitaient","word_nosc":"delitaient","lemma":"déliter","pos":"VER"} ,
		{"word":"délite","word_nosc":"delite","lemma":"déliter","pos":"VER"} ,
		{"word":"délitent","word_nosc":"delitent","lemma":"déliter","pos":"VER"} ,
		{"word":"déliteront","word_nosc":"deliteront","lemma":"déliter","pos":"VER"} ,
		{"word":"délité","word_nosc":"delite","lemma":"déliter","pos":"VER"} ,
		{"word":"délitée","word_nosc":"delitee","lemma":"déliter","pos":"VER"} ,
		{"word":"délitées","word_nosc":"delitees","lemma":"déliter","pos":"VER"} ,
		{"word":"délivra","word_nosc":"delivra","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivraient","word_nosc":"delivraient","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrais","word_nosc":"delivrais","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrait","word_nosc":"delivrait","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrant","word_nosc":"delivrant","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivre","word_nosc":"delivre","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrent","word_nosc":"delivrent","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrer","word_nosc":"delivrer","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrera","word_nosc":"delivrera","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrerai","word_nosc":"delivrerai","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivreraient","word_nosc":"delivreraient","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrerais","word_nosc":"delivrerais","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrerait","word_nosc":"delivrerait","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivreras","word_nosc":"delivreras","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrerez","word_nosc":"delivrerez","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrerons","word_nosc":"delivrerons","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivreront","word_nosc":"delivreront","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrez","word_nosc":"delivrez","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivriez","word_nosc":"delivriez","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrions","word_nosc":"delivrions","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrons","word_nosc":"delivrons","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrât","word_nosc":"delivrat","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrèrent","word_nosc":"delivrerent","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivré","word_nosc":"delivre","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrée","word_nosc":"delivree","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrées","word_nosc":"delivrees","lemma":"délivrer","pos":"VER"} ,
		{"word":"délivrés","word_nosc":"delivres","lemma":"délivrer","pos":"VER"} ,
		{"word":"délièrent","word_nosc":"delierent","lemma":"délier","pos":"VER"} ,
		{"word":"délié","word_nosc":"delie","lemma":"délier","pos":"VER"} ,
		{"word":"déliée","word_nosc":"deliee","lemma":"délier","pos":"VER"} ,
		{"word":"déliées","word_nosc":"deliees","lemma":"délier","pos":"VER"} ,
		{"word":"déliés","word_nosc":"delies","lemma":"délier","pos":"VER"} ,
		{"word":"délocaliser","word_nosc":"delocaliser","lemma":"délocaliser","pos":"VER"} ,
		{"word":"délocalisez","word_nosc":"delocalisez","lemma":"délocaliser","pos":"VER"} ,
		{"word":"délocalisé","word_nosc":"delocalise","lemma":"délocaliser","pos":"VER"} ,
		{"word":"déloge","word_nosc":"deloge","lemma":"déloger","pos":"VER"} ,
		{"word":"délogea","word_nosc":"delogea","lemma":"déloger","pos":"VER"} ,
		{"word":"délogeait","word_nosc":"delogeait","lemma":"déloger","pos":"VER"} ,
		{"word":"déloger","word_nosc":"deloger","lemma":"déloger","pos":"VER"} ,
		{"word":"délogera","word_nosc":"delogera","lemma":"déloger","pos":"VER"} ,
		{"word":"délogerait","word_nosc":"delogerait","lemma":"déloger","pos":"VER"} ,
		{"word":"délogerons","word_nosc":"delogerons","lemma":"déloger","pos":"VER"} ,
		{"word":"délogez","word_nosc":"delogez","lemma":"déloger","pos":"VER"} ,
		{"word":"délogeât","word_nosc":"delogeat","lemma":"déloger","pos":"VER"} ,
		{"word":"délogé","word_nosc":"deloge","lemma":"déloger","pos":"VER"} ,
		{"word":"délogée","word_nosc":"delogee","lemma":"déloger","pos":"VER"} ,
		{"word":"délogés","word_nosc":"deloges","lemma":"déloger","pos":"VER"} ,
		{"word":"déloquaient","word_nosc":"deloquaient","lemma":"déloquer","pos":"VER"} ,
		{"word":"déloque","word_nosc":"deloque","lemma":"déloquer","pos":"VER"} ,
		{"word":"déloquer","word_nosc":"deloquer","lemma":"déloquer","pos":"VER"} ,
		{"word":"déloques","word_nosc":"deloques","lemma":"déloquer","pos":"VER"} ,
		{"word":"déloqué","word_nosc":"deloque","lemma":"déloquer","pos":"VER"} ,
		{"word":"déloquées","word_nosc":"deloquees","lemma":"déloquer","pos":"VER"} ,
		{"word":"déloqués","word_nosc":"deloques","lemma":"déloquer","pos":"VER"} ,
		{"word":"délourdant","word_nosc":"delourdant","lemma":"délourder","pos":"VER"} ,
		{"word":"délourder","word_nosc":"delourder","lemma":"délourder","pos":"VER"} ,
		{"word":"déluré","word_nosc":"delure","lemma":"délurer","pos":"VER"} ,
		{"word":"délurée","word_nosc":"deluree","lemma":"délurer","pos":"VER"} ,
		{"word":"délurées","word_nosc":"delurees","lemma":"délurer","pos":"VER"} ,
		{"word":"délustrer","word_nosc":"delustrer","lemma":"délustrer","pos":"VER"} ,
		{"word":"délègue","word_nosc":"delegue","lemma":"déléguer","pos":"VER"} ,
		{"word":"délégua","word_nosc":"delegua","lemma":"déléguer","pos":"VER"} ,
		{"word":"déléguaient","word_nosc":"deleguaient","lemma":"déléguer","pos":"VER"} ,
		{"word":"déléguait","word_nosc":"deleguait","lemma":"déléguer","pos":"VER"} ,
		{"word":"déléguant","word_nosc":"deleguant","lemma":"déléguer","pos":"VER"} ,
		{"word":"déléguer","word_nosc":"deleguer","lemma":"déléguer","pos":"VER"} ,
		{"word":"déléguerai","word_nosc":"deleguerai","lemma":"déléguer","pos":"VER"} ,
		{"word":"délégueraient","word_nosc":"delegueraient","lemma":"déléguer","pos":"VER"} ,
		{"word":"déléguez","word_nosc":"deleguez","lemma":"déléguer","pos":"VER"} ,
		{"word":"déléguiez","word_nosc":"deleguiez","lemma":"déléguer","pos":"VER"} ,
		{"word":"déléguons","word_nosc":"deleguons","lemma":"déléguer","pos":"VER"} ,
		{"word":"déléguât","word_nosc":"deleguat","lemma":"déléguer","pos":"VER"} ,
		{"word":"délégué","word_nosc":"delegue","lemma":"déléguer","pos":"VER"} ,
		{"word":"déléguée","word_nosc":"deleguee","lemma":"déléguer","pos":"VER"} ,
		{"word":"délégués","word_nosc":"delegues","lemma":"déléguer","pos":"VER"} ,
		{"word":"démagnétiser","word_nosc":"demagnetiser","lemma":"démagnétiser","pos":"VER"} ,
		{"word":"démagnétisé","word_nosc":"demagnetise","lemma":"démagnétiser","pos":"VER"} ,
		{"word":"démagnétisée","word_nosc":"demagnetisee","lemma":"démagnétiser","pos":"VER"} ,
		{"word":"démaille","word_nosc":"demaille","lemma":"démailler","pos":"VER"} ,
		{"word":"démailler","word_nosc":"demailler","lemma":"démailler","pos":"VER"} ,
		{"word":"démailloter","word_nosc":"demailloter","lemma":"démailloter","pos":"VER"} ,
		{"word":"démailloté","word_nosc":"demaillote","lemma":"démailloter","pos":"VER"} ,
		{"word":"démaillée","word_nosc":"demaillee","lemma":"démailler","pos":"VER"} ,
		{"word":"démanche","word_nosc":"demanche","lemma":"démancher","pos":"VER"} ,
		{"word":"démancher","word_nosc":"demancher","lemma":"démancher","pos":"VER"} ,
		{"word":"démanché","word_nosc":"demanche","lemma":"démancher","pos":"VER"} ,
		{"word":"démange","word_nosc":"demange","lemma":"démanger","pos":"VER"} ,
		{"word":"démangeaient","word_nosc":"demangeaient","lemma":"démanger","pos":"VER"} ,
		{"word":"démangeais","word_nosc":"demangeais","lemma":"démanger","pos":"VER"} ,
		{"word":"démangeait","word_nosc":"demangeait","lemma":"démanger","pos":"VER"} ,
		{"word":"démangent","word_nosc":"demangent","lemma":"démanger","pos":"VER"} ,
		{"word":"démanger","word_nosc":"demanger","lemma":"démanger","pos":"VER"} ,
		{"word":"démangé","word_nosc":"demange","lemma":"démanger","pos":"VER"} ,
		{"word":"démangés","word_nosc":"demanges","lemma":"démanger","pos":"VER"} ,
		{"word":"démanteler","word_nosc":"demanteler","lemma":"démanteler","pos":"VER"} ,
		{"word":"démantelez","word_nosc":"demantelez","lemma":"démanteler","pos":"VER"} ,
		{"word":"démantelons","word_nosc":"demantelons","lemma":"démanteler","pos":"VER"} ,
		{"word":"démantelé","word_nosc":"demantele","lemma":"démanteler","pos":"VER"} ,
		{"word":"démantelée","word_nosc":"demantelee","lemma":"démanteler","pos":"VER"} ,
		{"word":"démantelées","word_nosc":"demantelees","lemma":"démanteler","pos":"VER"} ,
		{"word":"démantelés","word_nosc":"demanteles","lemma":"démanteler","pos":"VER"} ,
		{"word":"démantibulant","word_nosc":"demantibulant","lemma":"démantibuler","pos":"VER"} ,
		{"word":"démantibule","word_nosc":"demantibule","lemma":"démantibuler","pos":"VER"} ,
		{"word":"démantibulent","word_nosc":"demantibulent","lemma":"démantibuler","pos":"VER"} ,
		{"word":"démantibuler","word_nosc":"demantibuler","lemma":"démantibuler","pos":"VER"} ,
		{"word":"démantibulé","word_nosc":"demantibule","lemma":"démantibuler","pos":"VER"} ,
		{"word":"démantibulée","word_nosc":"demantibulee","lemma":"démantibuler","pos":"VER"} ,
		{"word":"démantibulées","word_nosc":"demantibulees","lemma":"démantibuler","pos":"VER"} ,
		{"word":"démantibulés","word_nosc":"demantibules","lemma":"démantibuler","pos":"VER"} ,
		{"word":"démantèle","word_nosc":"demantele","lemma":"démanteler","pos":"VER"} ,
		{"word":"démantèlent","word_nosc":"demantelent","lemma":"démanteler","pos":"VER"} ,
		{"word":"démaquillaient","word_nosc":"demaquillaient","lemma":"démaquiller","pos":"VER"} ,
		{"word":"démaquillais","word_nosc":"demaquillais","lemma":"démaquiller","pos":"VER"} ,
		{"word":"démaquillait","word_nosc":"demaquillait","lemma":"démaquiller","pos":"VER"} ,
		{"word":"démaquillant","word_nosc":"demaquillant","lemma":"démaquiller","pos":"VER"} ,
		{"word":"démaquille","word_nosc":"demaquille","lemma":"démaquiller","pos":"VER"} ,
		{"word":"démaquiller","word_nosc":"demaquiller","lemma":"démaquiller","pos":"VER"} ,
		{"word":"démaquillerai","word_nosc":"demaquillerai","lemma":"démaquiller","pos":"VER"} ,
		{"word":"démaquillé","word_nosc":"demaquille","lemma":"démaquiller","pos":"VER"} ,
		{"word":"démaquillée","word_nosc":"demaquillee","lemma":"démaquiller","pos":"VER"} ,
		{"word":"démarcha","word_nosc":"demarcha","lemma":"démarcher","pos":"VER"} ,
		{"word":"démarchait","word_nosc":"demarchait","lemma":"démarcher","pos":"VER"} ,
		{"word":"démarche","word_nosc":"demarche","lemma":"démarcher","pos":"VER"} ,
		{"word":"démarcher","word_nosc":"demarcher","lemma":"démarcher","pos":"VER"} ,
		{"word":"démarient","word_nosc":"demarient","lemma":"démarier","pos":"VER"} ,
		{"word":"démarier","word_nosc":"demarier","lemma":"démarier","pos":"VER"} ,
		{"word":"démariée","word_nosc":"demariee","lemma":"démarier","pos":"VER"} ,
		{"word":"démarquai","word_nosc":"demarquai","lemma":"démarquer","pos":"VER"} ,
		{"word":"démarquaient","word_nosc":"demarquaient","lemma":"démarquer","pos":"VER"} ,
		{"word":"démarquait","word_nosc":"demarquait","lemma":"démarquer","pos":"VER"} ,
		{"word":"démarque","word_nosc":"demarque","lemma":"démarquer","pos":"VER"} ,
		{"word":"démarquer","word_nosc":"demarquer","lemma":"démarquer","pos":"VER"} ,
		{"word":"démarqué","word_nosc":"demarque","lemma":"démarquer","pos":"VER"} ,
		{"word":"démarquée","word_nosc":"demarquee","lemma":"démarquer","pos":"VER"} ,
		{"word":"démarquées","word_nosc":"demarquees","lemma":"démarquer","pos":"VER"} ,
		{"word":"démarqués","word_nosc":"demarques","lemma":"démarquer","pos":"VER"} ,
		{"word":"démarra","word_nosc":"demarra","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrai","word_nosc":"demarrai","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarraient","word_nosc":"demarraient","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrais","word_nosc":"demarrais","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrait","word_nosc":"demarrait","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrant","word_nosc":"demarrant","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarre","word_nosc":"demarre","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrent","word_nosc":"demarrent","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrer","word_nosc":"demarrer","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrera","word_nosc":"demarrera","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrerai","word_nosc":"demarrerai","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarreront","word_nosc":"demarreront","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarres","word_nosc":"demarres","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrez","word_nosc":"demarrez","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrions","word_nosc":"demarrions","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrons","word_nosc":"demarrons","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrât","word_nosc":"demarrat","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrèrent","word_nosc":"demarrerent","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarré","word_nosc":"demarre","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrée","word_nosc":"demarree","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrées","word_nosc":"demarrees","lemma":"démarrer","pos":"VER"} ,
		{"word":"démarrés","word_nosc":"demarres","lemma":"démarrer","pos":"VER"} ,
		{"word":"démasqua","word_nosc":"demasqua","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasquaient","word_nosc":"demasquaient","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasquais","word_nosc":"demasquais","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasquait","word_nosc":"demasquait","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasquant","word_nosc":"demasquant","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasque","word_nosc":"demasque","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasquent","word_nosc":"demasquent","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasquer","word_nosc":"demasquer","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasquera","word_nosc":"demasquera","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasquerai","word_nosc":"demasquerai","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasquerait","word_nosc":"demasquerait","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasqueriez","word_nosc":"demasqueriez","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasquerons","word_nosc":"demasquerons","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasqueront","word_nosc":"demasqueront","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasquons","word_nosc":"demasquons","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasqué","word_nosc":"demasque","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasquée","word_nosc":"demasquee","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasquées","word_nosc":"demasquees","lemma":"démasquer","pos":"VER"} ,
		{"word":"démasqués","word_nosc":"demasques","lemma":"démasquer","pos":"VER"} ,
		{"word":"dématérialise","word_nosc":"dematerialise","lemma":"dématérialiser","pos":"VER"} ,
		{"word":"dématérialisent","word_nosc":"dematerialisent","lemma":"dématérialiser","pos":"VER"} ,
		{"word":"dématérialiser","word_nosc":"dematerialiser","lemma":"dématérialiser","pos":"VER"} ,
		{"word":"dématérialisé","word_nosc":"dematerialise","lemma":"dématérialiser","pos":"VER"} ,
		{"word":"démembrant","word_nosc":"demembrant","lemma":"démembrer","pos":"VER"} ,
		{"word":"démembre","word_nosc":"demembre","lemma":"démembrer","pos":"VER"} ,
		{"word":"démembrer","word_nosc":"demembrer","lemma":"démembrer","pos":"VER"} ,
		{"word":"démembrez","word_nosc":"demembrez","lemma":"démembrer","pos":"VER"} ,
		{"word":"démembré","word_nosc":"demembre","lemma":"démembrer","pos":"VER"} ,
		{"word":"démembrée","word_nosc":"demembree","lemma":"démembrer","pos":"VER"} ,
		{"word":"démembrées","word_nosc":"demembrees","lemma":"démembrer","pos":"VER"} ,
		{"word":"démembrés","word_nosc":"demembres","lemma":"démembrer","pos":"VER"} ,
		{"word":"démena","word_nosc":"demena","lemma":"démener","pos":"VER"} ,
		{"word":"démenaient","word_nosc":"demenaient","lemma":"démener","pos":"VER"} ,
		{"word":"démenais","word_nosc":"demenais","lemma":"démener","pos":"VER"} ,
		{"word":"démenait","word_nosc":"demenait","lemma":"démener","pos":"VER"} ,
		{"word":"démenant","word_nosc":"demenant","lemma":"démener","pos":"VER"} ,
		{"word":"démener","word_nosc":"demener","lemma":"démener","pos":"VER"} ,
		{"word":"démenons","word_nosc":"demenons","lemma":"démener","pos":"VER"} ,
		{"word":"démenotte","word_nosc":"demenotte","lemma":"démenotter","pos":"VER"} ,
		{"word":"démens","word_nosc":"demens","lemma":"démentir","pos":"VER"} ,
		{"word":"dément","word_nosc":"dement","lemma":"démentir","pos":"VER"} ,
		{"word":"démentaient","word_nosc":"dementaient","lemma":"démentir","pos":"VER"} ,
		{"word":"démentait","word_nosc":"dementait","lemma":"démentir","pos":"VER"} ,
		{"word":"démentant","word_nosc":"dementant","lemma":"démentir","pos":"VER"} ,
		{"word":"démente","word_nosc":"demente","lemma":"démentir","pos":"VER"} ,
		{"word":"démentent","word_nosc":"dementent","lemma":"démentir","pos":"VER"} ,
		{"word":"démentez","word_nosc":"dementez","lemma":"démentir","pos":"VER"} ,
		{"word":"démenti","word_nosc":"dementi","lemma":"démentir","pos":"VER"} ,
		{"word":"démentie","word_nosc":"dementie","lemma":"démentir","pos":"VER"} ,
		{"word":"démenties","word_nosc":"dementies","lemma":"démentir","pos":"VER"} ,
		{"word":"démentir","word_nosc":"dementir","lemma":"démentir","pos":"VER"} ,
		{"word":"démentirai","word_nosc":"dementirai","lemma":"démentir","pos":"VER"} ,
		{"word":"démentirait","word_nosc":"dementirait","lemma":"démentir","pos":"VER"} ,
		{"word":"démentiront","word_nosc":"dementiront","lemma":"démentir","pos":"VER"} ,
		{"word":"démentit","word_nosc":"dementit","lemma":"démentir","pos":"VER"} ,
		{"word":"démené","word_nosc":"demene","lemma":"démener","pos":"VER"} ,
		{"word":"démenée","word_nosc":"demenee","lemma":"démener","pos":"VER"} ,
		{"word":"démerdaient","word_nosc":"demerdaient","lemma":"démerder","pos":"VER"} ,
		{"word":"démerdais","word_nosc":"demerdais","lemma":"démerder","pos":"VER"} ,
		{"word":"démerdait","word_nosc":"demerdait","lemma":"démerder","pos":"VER"} ,
		{"word":"démerde","word_nosc":"demerde","lemma":"démerder","pos":"VER"} ,
		{"word":"démerdent","word_nosc":"demerdent","lemma":"démerder","pos":"VER"} ,
		{"word":"démerder","word_nosc":"demerder","lemma":"démerder","pos":"VER"} ,
		{"word":"démerdera","word_nosc":"demerdera","lemma":"démerder","pos":"VER"} ,
		{"word":"démerderai","word_nosc":"demerderai","lemma":"démerder","pos":"VER"} ,
		{"word":"démerderas","word_nosc":"demerderas","lemma":"démerder","pos":"VER"} ,
		{"word":"démerdes","word_nosc":"demerdes","lemma":"démerder","pos":"VER"} ,
		{"word":"démerdez","word_nosc":"demerdez","lemma":"démerder","pos":"VER"} ,
		{"word":"démerdé","word_nosc":"demerde","lemma":"démerder","pos":"VER"} ,
		{"word":"démerdée","word_nosc":"demerdee","lemma":"démerder","pos":"VER"} ,
		{"word":"démerdés","word_nosc":"demerdes","lemma":"démerder","pos":"VER"} ,
		{"word":"démesurent","word_nosc":"demesurent","lemma":"démesurer","pos":"VER"} ,
		{"word":"démesuré","word_nosc":"demesure","lemma":"démesurer","pos":"VER"} ,
		{"word":"démesurée","word_nosc":"demesuree","lemma":"démesurer","pos":"VER"} ,
		{"word":"démesurées","word_nosc":"demesurees","lemma":"démesurer","pos":"VER"} ,
		{"word":"démesurés","word_nosc":"demesures","lemma":"démesurer","pos":"VER"} ,
		{"word":"démet","word_nosc":"demet","lemma":"démettre","pos":"VER"} ,
		{"word":"démets","word_nosc":"demets","lemma":"démettre","pos":"VER"} ,
		{"word":"démette","word_nosc":"demette","lemma":"démettre","pos":"VER"} ,
		{"word":"démettez","word_nosc":"demettez","lemma":"démettre","pos":"VER"} ,
		{"word":"démettrait","word_nosc":"demettrait","lemma":"démettre","pos":"VER"} ,
		{"word":"démettre","word_nosc":"demettre","lemma":"démettre","pos":"VER"} ,
		{"word":"démeublé","word_nosc":"demeuble","lemma":"démeubler","pos":"VER"} ,
		{"word":"démeublée","word_nosc":"demeublee","lemma":"démeubler","pos":"VER"} ,
		{"word":"démilitarisée","word_nosc":"demilitarisee","lemma":"démilitariser","pos":"VER"} ,
		{"word":"démilitarisées","word_nosc":"demilitarisees","lemma":"démilitariser","pos":"VER"} ,
		{"word":"déminant","word_nosc":"deminant","lemma":"déminer","pos":"VER"} ,
		{"word":"déminer","word_nosc":"deminer","lemma":"déminer","pos":"VER"} ,
		{"word":"déminé","word_nosc":"demine","lemma":"déminer","pos":"VER"} ,
		{"word":"déminée","word_nosc":"deminee","lemma":"déminer","pos":"VER"} ,
		{"word":"démis","word_nosc":"demis","lemma":"démettre","pos":"VER"} ,
		{"word":"démise","word_nosc":"demise","lemma":"démettre","pos":"VER"} ,
		{"word":"démissionna","word_nosc":"demissionna","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionnaient","word_nosc":"demissionnaient","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionnais","word_nosc":"demissionnais","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionnait","word_nosc":"demissionnait","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionnant","word_nosc":"demissionnant","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionne","word_nosc":"demissionne","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionnent","word_nosc":"demissionnent","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionner","word_nosc":"demissionner","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionnera","word_nosc":"demissionnera","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionnerai","word_nosc":"demissionnerai","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionneraient","word_nosc":"demissionneraient","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionnerais","word_nosc":"demissionnerais","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionnerait","word_nosc":"demissionnerait","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionneras","word_nosc":"demissionneras","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionnerez","word_nosc":"demissionnerez","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionneront","word_nosc":"demissionneront","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionnes","word_nosc":"demissionnes","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionnez","word_nosc":"demissionnez","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionniez","word_nosc":"demissionniez","lemma":"démissionner","pos":"VER"} ,
		{"word":"démissionné","word_nosc":"demissionne","lemma":"démissionner","pos":"VER"} ,
		{"word":"démit","word_nosc":"demit","lemma":"démettre","pos":"VER"} ,
		{"word":"démobilisant","word_nosc":"demobilisant","lemma":"démobiliser","pos":"VER"} ,
		{"word":"démobilise","word_nosc":"demobilise","lemma":"démobiliser","pos":"VER"} ,
		{"word":"démobiliser","word_nosc":"demobiliser","lemma":"démobiliser","pos":"VER"} ,
		{"word":"démobiliseriez","word_nosc":"demobiliseriez","lemma":"démobiliser","pos":"VER"} ,
		{"word":"démobilisé","word_nosc":"demobilise","lemma":"démobiliser","pos":"VER"} ,
		{"word":"démobilisés","word_nosc":"demobilises","lemma":"démobiliser","pos":"VER"} ,
		{"word":"démocratise","word_nosc":"democratise","lemma":"démocratiser","pos":"VER"} ,
		{"word":"démocratiser","word_nosc":"democratiser","lemma":"démocratiser","pos":"VER"} ,
		{"word":"démocratiseront","word_nosc":"democratiseront","lemma":"démocratiser","pos":"VER"} ,
		{"word":"démocratisé","word_nosc":"democratise","lemma":"démocratiser","pos":"VER"} ,
		{"word":"démodaient","word_nosc":"demodaient","lemma":"démoder","pos":"VER"} ,
		{"word":"démodant","word_nosc":"demodant","lemma":"démoder","pos":"VER"} ,
		{"word":"démode","word_nosc":"demode","lemma":"démoder","pos":"VER"} ,
		{"word":"démoder","word_nosc":"demoder","lemma":"démoder","pos":"VER"} ,
		{"word":"démodé","word_nosc":"demode","lemma":"démoder","pos":"VER"} ,
		{"word":"démodée","word_nosc":"demodee","lemma":"démoder","pos":"VER"} ,
		{"word":"démodées","word_nosc":"demodees","lemma":"démoder","pos":"VER"} ,
		{"word":"démodés","word_nosc":"demodes","lemma":"démoder","pos":"VER"} ,
		{"word":"démoli","word_nosc":"demoli","lemma":"démolir","pos":"VER"} ,
		{"word":"démolie","word_nosc":"demolie","lemma":"démolir","pos":"VER"} ,
		{"word":"démolies","word_nosc":"demolies","lemma":"démolir","pos":"VER"} ,
		{"word":"démolir","word_nosc":"demolir","lemma":"démolir","pos":"VER"} ,
		{"word":"démolira","word_nosc":"demolira","lemma":"démolir","pos":"VER"} ,
		{"word":"démolirai","word_nosc":"demolirai","lemma":"démolir","pos":"VER"} ,
		{"word":"démoliraient","word_nosc":"demoliraient","lemma":"démolir","pos":"VER"} ,
		{"word":"démolirais","word_nosc":"demolirais","lemma":"démolir","pos":"VER"} ,
		{"word":"démolirait","word_nosc":"demolirait","lemma":"démolir","pos":"VER"} ,
		{"word":"démolirent","word_nosc":"demolirent","lemma":"démolir","pos":"VER"} ,
		{"word":"démolirions","word_nosc":"demolirions","lemma":"démolir","pos":"VER"} ,
		{"word":"démoliront","word_nosc":"demoliront","lemma":"démolir","pos":"VER"} ,
		{"word":"démolis","word_nosc":"demolis","lemma":"démolir","pos":"VER"} ,
		{"word":"démolissaient","word_nosc":"demolissaient","lemma":"démolir","pos":"VER"} ,
		{"word":"démolissais","word_nosc":"demolissais","lemma":"démolir","pos":"VER"} ,
		{"word":"démolissait","word_nosc":"demolissait","lemma":"démolir","pos":"VER"} ,
		{"word":"démolissant","word_nosc":"demolissant","lemma":"démolir","pos":"VER"} ,
		{"word":"démolisse","word_nosc":"demolisse","lemma":"démolir","pos":"VER"} ,
		{"word":"démolissent","word_nosc":"demolissent","lemma":"démolir","pos":"VER"} ,
		{"word":"démolisses","word_nosc":"demolisses","lemma":"démolir","pos":"VER"} ,
		{"word":"démolissez","word_nosc":"demolissez","lemma":"démolir","pos":"VER"} ,
		{"word":"démolissions","word_nosc":"demolissions","lemma":"démolir","pos":"VER"} ,
		{"word":"démolit","word_nosc":"demolit","lemma":"démolir","pos":"VER"} ,
		{"word":"démonta","word_nosc":"demonta","lemma":"démonter","pos":"VER"} ,
		{"word":"démontaient","word_nosc":"demontaient","lemma":"démonter","pos":"VER"} ,
		{"word":"démontait","word_nosc":"demontait","lemma":"démonter","pos":"VER"} ,
		{"word":"démontant","word_nosc":"demontant","lemma":"démonter","pos":"VER"} ,
		{"word":"démonte","word_nosc":"demonte","lemma":"démonter","pos":"VER"} ,
		{"word":"démontent","word_nosc":"demontent","lemma":"démonter","pos":"VER"} ,
		{"word":"démonter","word_nosc":"demonter","lemma":"démonter","pos":"VER"} ,
		{"word":"démontez","word_nosc":"demontez","lemma":"démonter","pos":"VER"} ,
		{"word":"démontra","word_nosc":"demontra","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrai","word_nosc":"demontrai","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontraient","word_nosc":"demontraient","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrait","word_nosc":"demontrait","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrant","word_nosc":"demontrant","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontre","word_nosc":"demontre","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrent","word_nosc":"demontrent","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrer","word_nosc":"demontrer","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrera","word_nosc":"demontrera","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrerai","word_nosc":"demontrerai","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrerait","word_nosc":"demontrerait","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrerons","word_nosc":"demontrerons","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontreront","word_nosc":"demontreront","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrez","word_nosc":"demontrez","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrions","word_nosc":"demontrions","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrât","word_nosc":"demontrat","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrèrent","word_nosc":"demontrerent","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontré","word_nosc":"demontre","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrée","word_nosc":"demontree","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrées","word_nosc":"demontrees","lemma":"démontrer","pos":"VER"} ,
		{"word":"démontrés","word_nosc":"demontres","lemma":"démontrer","pos":"VER"} ,
		{"word":"démonté","word_nosc":"demonte","lemma":"démonter","pos":"VER"} ,
		{"word":"démontée","word_nosc":"demontee","lemma":"démonter","pos":"VER"} ,
		{"word":"démontées","word_nosc":"demontees","lemma":"démonter","pos":"VER"} ,
		{"word":"démontés","word_nosc":"demontes","lemma":"démonter","pos":"VER"} ,
		{"word":"démonétisé","word_nosc":"demonetise","lemma":"démonétiser","pos":"VER"} ,
		{"word":"démonétisés","word_nosc":"demonetises","lemma":"démonétiser","pos":"VER"} ,
		{"word":"démoralisa","word_nosc":"demoralisa","lemma":"démoraliser","pos":"VER"} ,
		{"word":"démoralisait","word_nosc":"demoralisait","lemma":"démoraliser","pos":"VER"} ,
		{"word":"démoralisant","word_nosc":"demoralisant","lemma":"démoraliser","pos":"VER"} ,
		{"word":"démoralise","word_nosc":"demoralise","lemma":"démoraliser","pos":"VER"} ,
		{"word":"démoralisent","word_nosc":"demoralisent","lemma":"démoraliser","pos":"VER"} ,
		{"word":"démoraliser","word_nosc":"demoraliser","lemma":"démoraliser","pos":"VER"} ,
		{"word":"démoraliseraient","word_nosc":"demoraliseraient","lemma":"démoraliser","pos":"VER"} ,
		{"word":"démoraliserais","word_nosc":"demoraliserais","lemma":"démoraliser","pos":"VER"} ,
		{"word":"démoralisé","word_nosc":"demoralise","lemma":"démoraliser","pos":"VER"} ,
		{"word":"démoralisée","word_nosc":"demoralisee","lemma":"démoraliser","pos":"VER"} ,
		{"word":"démoralisés","word_nosc":"demoralises","lemma":"démoraliser","pos":"VER"} ,
		{"word":"démord","word_nosc":"demord","lemma":"démordre","pos":"VER"} ,
		{"word":"démordaient","word_nosc":"demordaient","lemma":"démordre","pos":"VER"} ,
		{"word":"démordais","word_nosc":"demordais","lemma":"démordre","pos":"VER"} ,
		{"word":"démordait","word_nosc":"demordait","lemma":"démordre","pos":"VER"} ,
		{"word":"démordent","word_nosc":"demordent","lemma":"démordre","pos":"VER"} ,
		{"word":"démordez","word_nosc":"demordez","lemma":"démordre","pos":"VER"} ,
		{"word":"démordit","word_nosc":"demordit","lemma":"démordre","pos":"VER"} ,
		{"word":"démordra","word_nosc":"demordra","lemma":"démordre","pos":"VER"} ,
		{"word":"démordrai","word_nosc":"demordrai","lemma":"démordre","pos":"VER"} ,
		{"word":"démordrait","word_nosc":"demordrait","lemma":"démordre","pos":"VER"} ,
		{"word":"démordre","word_nosc":"demordre","lemma":"démordre","pos":"VER"} ,
		{"word":"démordront","word_nosc":"demordront","lemma":"démordre","pos":"VER"} ,
		{"word":"démords","word_nosc":"demords","lemma":"démordre","pos":"VER"} ,
		{"word":"démordu","word_nosc":"demordu","lemma":"démordre","pos":"VER"} ,
		{"word":"démotive","word_nosc":"demotive","lemma":"démotiver","pos":"VER"} ,
		{"word":"démotiver","word_nosc":"demotiver","lemma":"démotiver","pos":"VER"} ,
		{"word":"démotivée","word_nosc":"demotivee","lemma":"démotiver","pos":"VER"} ,
		{"word":"démoucheté","word_nosc":"demouchete","lemma":"démoucheter","pos":"VER"} ,
		{"word":"démoule","word_nosc":"demoule","lemma":"démouler","pos":"VER"} ,
		{"word":"démouler","word_nosc":"demouler","lemma":"démouler","pos":"VER"} ,
		{"word":"démoules","word_nosc":"demoules","lemma":"démouler","pos":"VER"} ,
		{"word":"démoulé","word_nosc":"demoule","lemma":"démouler","pos":"VER"} ,
		{"word":"démoulées","word_nosc":"demoulees","lemma":"démouler","pos":"VER"} ,
		{"word":"démultipliaient","word_nosc":"demultipliaient","lemma":"démultiplier","pos":"VER"} ,
		{"word":"démultipliait","word_nosc":"demultipliait","lemma":"démultiplier","pos":"VER"} ,
		{"word":"démultipliant","word_nosc":"demultipliant","lemma":"démultiplier","pos":"VER"} ,
		{"word":"démultiplie","word_nosc":"demultiplie","lemma":"démultiplier","pos":"VER"} ,
		{"word":"démultiplient","word_nosc":"demultiplient","lemma":"démultiplier","pos":"VER"} ,
		{"word":"démultiplier","word_nosc":"demultiplier","lemma":"démultiplier","pos":"VER"} ,
		{"word":"démultiplié","word_nosc":"demultiplie","lemma":"démultiplier","pos":"VER"} ,
		{"word":"démultipliée","word_nosc":"demultipliee","lemma":"démultiplier","pos":"VER"} ,
		{"word":"démultipliées","word_nosc":"demultipliees","lemma":"démultiplier","pos":"VER"} ,
		{"word":"démultipliés","word_nosc":"demultiplies","lemma":"démultiplier","pos":"VER"} ,
		{"word":"démuni","word_nosc":"demuni","lemma":"démunir","pos":"VER"} ,
		{"word":"démunie","word_nosc":"demunie","lemma":"démunir","pos":"VER"} ,
		{"word":"démunies","word_nosc":"demunies","lemma":"démunir","pos":"VER"} ,
		{"word":"démunir","word_nosc":"demunir","lemma":"démunir","pos":"VER"} ,
		{"word":"démunis","word_nosc":"demunis","lemma":"démunir","pos":"VER"} ,
		{"word":"démunissant","word_nosc":"demunissant","lemma":"démunir","pos":"VER"} ,
		{"word":"démurge","word_nosc":"demurge","lemma":"démurger","pos":"VER"} ,
		{"word":"démurger","word_nosc":"demurger","lemma":"démurger","pos":"VER"} ,
		{"word":"démuseler","word_nosc":"demuseler","lemma":"démuseler","pos":"VER"} ,
		{"word":"démystifier","word_nosc":"demystifier","lemma":"démystifier","pos":"VER"} ,
		{"word":"démystifié","word_nosc":"demystifie","lemma":"démystifier","pos":"VER"} ,
		{"word":"démythifie","word_nosc":"demythifie","lemma":"démythifier","pos":"VER"} ,
		{"word":"démythifié","word_nosc":"demythifie","lemma":"démythifier","pos":"VER"} ,
		{"word":"démâtait","word_nosc":"dematait","lemma":"démâter","pos":"VER"} ,
		{"word":"démâter","word_nosc":"demater","lemma":"démâter","pos":"VER"} ,
		{"word":"démâté","word_nosc":"demate","lemma":"démâter","pos":"VER"} ,
		{"word":"démâtée","word_nosc":"dematee","lemma":"démâter","pos":"VER"} ,
		{"word":"démâtés","word_nosc":"demates","lemma":"démâter","pos":"VER"} ,
		{"word":"démène","word_nosc":"demene","lemma":"démener","pos":"VER"} ,
		{"word":"démènent","word_nosc":"demenent","lemma":"démener","pos":"VER"} ,
		{"word":"démèneras","word_nosc":"demeneras","lemma":"démener","pos":"VER"} ,
		{"word":"démènes","word_nosc":"demenes","lemma":"démener","pos":"VER"} ,
		{"word":"déménage","word_nosc":"demenage","lemma":"déménager","pos":"VER"} ,
		{"word":"déménagea","word_nosc":"demenagea","lemma":"déménager","pos":"VER"} ,
		{"word":"déménageaient","word_nosc":"demenageaient","lemma":"déménager","pos":"VER"} ,
		{"word":"déménageais","word_nosc":"demenageais","lemma":"déménager","pos":"VER"} ,
		{"word":"déménageait","word_nosc":"demenageait","lemma":"déménager","pos":"VER"} ,
		{"word":"déménageant","word_nosc":"demenageant","lemma":"déménager","pos":"VER"} ,
		{"word":"déménagent","word_nosc":"demenagent","lemma":"déménager","pos":"VER"} ,
		{"word":"déménageons","word_nosc":"demenageons","lemma":"déménager","pos":"VER"} ,
		{"word":"déménager","word_nosc":"demenager","lemma":"déménager","pos":"VER"} ,
		{"word":"déménagera","word_nosc":"demenagera","lemma":"déménager","pos":"VER"} ,
		{"word":"déménagerai","word_nosc":"demenagerai","lemma":"déménager","pos":"VER"} ,
		{"word":"déménageraient","word_nosc":"demenageraient","lemma":"déménager","pos":"VER"} ,
		{"word":"déménagerais","word_nosc":"demenagerais","lemma":"déménager","pos":"VER"} ,
		{"word":"déménagerait","word_nosc":"demenagerait","lemma":"déménager","pos":"VER"} ,
		{"word":"déménageras","word_nosc":"demenageras","lemma":"déménager","pos":"VER"} ,
		{"word":"déménagerez","word_nosc":"demenagerez","lemma":"déménager","pos":"VER"} ,
		{"word":"déménages","word_nosc":"demenages","lemma":"déménager","pos":"VER"} ,
		{"word":"déménagez","word_nosc":"demenagez","lemma":"déménager","pos":"VER"} ,
		{"word":"déménagiez","word_nosc":"demenagiez","lemma":"déménager","pos":"VER"} ,
		{"word":"déménagions","word_nosc":"demenagions","lemma":"déménager","pos":"VER"} ,
		{"word":"déménagèrent","word_nosc":"demenagerent","lemma":"déménager","pos":"VER"} ,
		{"word":"déménagé","word_nosc":"demenage","lemma":"déménager","pos":"VER"} ,
		{"word":"déménagée","word_nosc":"demenagee","lemma":"déménager","pos":"VER"} ,
		{"word":"déménagés","word_nosc":"demenages","lemma":"déménager","pos":"VER"} ,
		{"word":"déméritait","word_nosc":"demeritait","lemma":"démériter","pos":"VER"} ,
		{"word":"démériter","word_nosc":"demeriter","lemma":"démériter","pos":"VER"} ,
		{"word":"démérité","word_nosc":"demerite","lemma":"démériter","pos":"VER"} ,
		{"word":"démêla","word_nosc":"demela","lemma":"démêler","pos":"VER"} ,
		{"word":"démêlai","word_nosc":"demelai","lemma":"démêler","pos":"VER"} ,
		{"word":"démêlais","word_nosc":"demelais","lemma":"démêler","pos":"VER"} ,
		{"word":"démêlait","word_nosc":"demelait","lemma":"démêler","pos":"VER"} ,
		{"word":"démêlant","word_nosc":"demelant","lemma":"démêler","pos":"VER"} ,
		{"word":"démêle","word_nosc":"demele","lemma":"démêler","pos":"VER"} ,
		{"word":"démêlent","word_nosc":"demelent","lemma":"démêler","pos":"VER"} ,
		{"word":"démêler","word_nosc":"demeler","lemma":"démêler","pos":"VER"} ,
		{"word":"démêlerait","word_nosc":"demelerait","lemma":"démêler","pos":"VER"} ,
		{"word":"démêlions","word_nosc":"demelions","lemma":"démêler","pos":"VER"} ,
		{"word":"démêlé","word_nosc":"demele","lemma":"démêler","pos":"VER"} ,
		{"word":"démêlées","word_nosc":"demelees","lemma":"démêler","pos":"VER"} ,
		{"word":"démêlés","word_nosc":"demeles","lemma":"démêler","pos":"VER"} ,
		{"word":"dénanti","word_nosc":"denanti","lemma":"dénantir","pos":"VER"} ,
		{"word":"dénaturaient","word_nosc":"denaturaient","lemma":"dénaturer","pos":"VER"} ,
		{"word":"dénaturait","word_nosc":"denaturait","lemma":"dénaturer","pos":"VER"} ,
		{"word":"dénature","word_nosc":"denature","lemma":"dénaturer","pos":"VER"} ,
		{"word":"dénaturent","word_nosc":"denaturent","lemma":"dénaturer","pos":"VER"} ,
		{"word":"dénaturer","word_nosc":"denaturer","lemma":"dénaturer","pos":"VER"} ,
		{"word":"dénaturé","word_nosc":"denature","lemma":"dénaturer","pos":"VER"} ,
		{"word":"dénaturée","word_nosc":"denaturee","lemma":"dénaturer","pos":"VER"} ,
		{"word":"dénaturées","word_nosc":"denaturees","lemma":"dénaturer","pos":"VER"} ,
		{"word":"dénaturés","word_nosc":"denatures","lemma":"dénaturer","pos":"VER"} ,
		{"word":"dénazifier","word_nosc":"denazifier","lemma":"dénazifier","pos":"VER"} ,
		{"word":"déneiger","word_nosc":"deneiger","lemma":"déneiger","pos":"VER"} ,
		{"word":"déneigé","word_nosc":"deneige","lemma":"déneiger","pos":"VER"} ,
		{"word":"dénervé","word_nosc":"denerve","lemma":"dénerver","pos":"VER"} ,
		{"word":"dénia","word_nosc":"denia","lemma":"dénier","pos":"VER"} ,
		{"word":"déniaient","word_nosc":"deniaient","lemma":"dénier","pos":"VER"} ,
		{"word":"déniaisa","word_nosc":"deniaisa","lemma":"déniaiser","pos":"VER"} ,
		{"word":"déniaisas","word_nosc":"deniaisas","lemma":"déniaiser","pos":"VER"} ,
		{"word":"déniaiser","word_nosc":"deniaiser","lemma":"déniaiser","pos":"VER"} ,
		{"word":"déniaises","word_nosc":"deniaises","lemma":"déniaiser","pos":"VER"} ,
		{"word":"déniaisé","word_nosc":"deniaise","lemma":"déniaiser","pos":"VER"} ,
		{"word":"déniait","word_nosc":"deniait","lemma":"dénier","pos":"VER"} ,
		{"word":"déniant","word_nosc":"deniant","lemma":"dénier","pos":"VER"} ,
		{"word":"dénicha","word_nosc":"denicha","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénichai","word_nosc":"denichai","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénichaient","word_nosc":"denichaient","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénichais","word_nosc":"denichais","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénichait","word_nosc":"denichait","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénichant","word_nosc":"denichant","lemma":"dénicher","pos":"VER"} ,
		{"word":"déniche","word_nosc":"deniche","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénichent","word_nosc":"denichent","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénicher","word_nosc":"denicher","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénichera","word_nosc":"denichera","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénicherait","word_nosc":"denicherait","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénicheras","word_nosc":"denicheras","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénichez","word_nosc":"denichez","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénichions","word_nosc":"denichions","lemma":"dénicher","pos":"VER"} ,
		{"word":"déniché","word_nosc":"deniche","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénichée","word_nosc":"denichee","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénichées","word_nosc":"denichees","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénichés","word_nosc":"deniches","lemma":"dénicher","pos":"VER"} ,
		{"word":"dénie","word_nosc":"denie","lemma":"dénier","pos":"VER"} ,
		{"word":"dénier","word_nosc":"denier","lemma":"dénier","pos":"VER"} ,
		{"word":"dénierai","word_nosc":"denierai","lemma":"dénier","pos":"VER"} ,
		{"word":"dénies","word_nosc":"denies","lemma":"dénier","pos":"VER"} ,
		{"word":"dénigrais","word_nosc":"denigrais","lemma":"dénigrer","pos":"VER"} ,
		{"word":"dénigrait","word_nosc":"denigrait","lemma":"dénigrer","pos":"VER"} ,
		{"word":"dénigrant","word_nosc":"denigrant","lemma":"dénigrer","pos":"VER"} ,
		{"word":"dénigre","word_nosc":"denigre","lemma":"dénigrer","pos":"VER"} ,
		{"word":"dénigrent","word_nosc":"denigrent","lemma":"dénigrer","pos":"VER"} ,
		{"word":"dénigrer","word_nosc":"denigrer","lemma":"dénigrer","pos":"VER"} ,
		{"word":"dénigres","word_nosc":"denigres","lemma":"dénigrer","pos":"VER"} ,
		{"word":"dénigrez","word_nosc":"denigrez","lemma":"dénigrer","pos":"VER"} ,
		{"word":"dénigré","word_nosc":"denigre","lemma":"dénigrer","pos":"VER"} ,
		{"word":"dénigrées","word_nosc":"denigrees","lemma":"dénigrer","pos":"VER"} ,
		{"word":"dénigrés","word_nosc":"denigres","lemma":"dénigrer","pos":"VER"} ,
		{"word":"dénions","word_nosc":"denions","lemma":"dénier","pos":"VER"} ,
		{"word":"dénivelé","word_nosc":"denivele","lemma":"déniveler","pos":"VER"} ,
		{"word":"dénié","word_nosc":"denie","lemma":"dénier","pos":"VER"} ,
		{"word":"dénombra","word_nosc":"denombra","lemma":"dénombrer","pos":"VER"} ,
		{"word":"dénombrait","word_nosc":"denombrait","lemma":"dénombrer","pos":"VER"} ,
		{"word":"dénombrant","word_nosc":"denombrant","lemma":"dénombrer","pos":"VER"} ,
		{"word":"dénombre","word_nosc":"denombre","lemma":"dénombrer","pos":"VER"} ,
		{"word":"dénombrent","word_nosc":"denombrent","lemma":"dénombrer","pos":"VER"} ,
		{"word":"dénombrer","word_nosc":"denombrer","lemma":"dénombrer","pos":"VER"} ,
		{"word":"dénombrez","word_nosc":"denombrez","lemma":"dénombrer","pos":"VER"} ,
		{"word":"dénombré","word_nosc":"denombre","lemma":"dénombrer","pos":"VER"} ,
		{"word":"dénombrés","word_nosc":"denombres","lemma":"dénombrer","pos":"VER"} ,
		{"word":"dénominer","word_nosc":"denominer","lemma":"dénominer","pos":"VER"} ,
		{"word":"dénommaient","word_nosc":"denommaient","lemma":"dénommer","pos":"VER"} ,
		{"word":"dénommait","word_nosc":"denommait","lemma":"dénommer","pos":"VER"} ,
		{"word":"dénomment","word_nosc":"denomment","lemma":"dénommer","pos":"VER"} ,
		{"word":"dénommer","word_nosc":"denommer","lemma":"dénommer","pos":"VER"} ,
		{"word":"dénommez","word_nosc":"denommez","lemma":"dénommer","pos":"VER"} ,
		{"word":"dénommé","word_nosc":"denomme","lemma":"dénommer","pos":"VER"} ,
		{"word":"dénommée","word_nosc":"denommee","lemma":"dénommer","pos":"VER"} ,
		{"word":"dénommés","word_nosc":"denommes","lemma":"dénommer","pos":"VER"} ,
		{"word":"dénonce","word_nosc":"denonce","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénoncent","word_nosc":"denoncent","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénoncer","word_nosc":"denoncer","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénoncera","word_nosc":"denoncera","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénoncerai","word_nosc":"denoncerai","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénonceraient","word_nosc":"denonceraient","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénoncerais","word_nosc":"denoncerais","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénoncerait","word_nosc":"denoncerait","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénonceras","word_nosc":"denonceras","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénoncerez","word_nosc":"denoncerez","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénonceriez","word_nosc":"denonceriez","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénonceront","word_nosc":"denonceront","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénonces","word_nosc":"denonces","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénoncez","word_nosc":"denoncez","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénoncé","word_nosc":"denonce","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénoncée","word_nosc":"denoncee","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénoncées","word_nosc":"denoncees","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénoncés","word_nosc":"denonces","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénonça","word_nosc":"denonca","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénonçaient","word_nosc":"denoncaient","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénonçais","word_nosc":"denoncais","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénonçait","word_nosc":"denoncait","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénonçant","word_nosc":"denoncant","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénonçons","word_nosc":"denoncons","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénonçât","word_nosc":"denoncat","lemma":"dénoncer","pos":"VER"} ,
		{"word":"dénotaient","word_nosc":"denotaient","lemma":"dénoter","pos":"VER"} ,
		{"word":"dénotait","word_nosc":"denotait","lemma":"dénoter","pos":"VER"} ,
		{"word":"dénotant","word_nosc":"denotant","lemma":"dénoter","pos":"VER"} ,
		{"word":"dénote","word_nosc":"denote","lemma":"dénoter","pos":"VER"} ,
		{"word":"dénotent","word_nosc":"denotent","lemma":"dénoter","pos":"VER"} ,
		{"word":"dénoter","word_nosc":"denoter","lemma":"dénoter","pos":"VER"} ,
		{"word":"dénoterait","word_nosc":"denoterait","lemma":"dénoter","pos":"VER"} ,
		{"word":"dénoté","word_nosc":"denote","lemma":"dénoter","pos":"VER"} ,
		{"word":"dénoua","word_nosc":"denoua","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénouaient","word_nosc":"denouaient","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénouait","word_nosc":"denouait","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénouant","word_nosc":"denouant","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénoue","word_nosc":"denoue","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénouent","word_nosc":"denouent","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénouer","word_nosc":"denouer","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénouera","word_nosc":"denouera","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénouerais","word_nosc":"denouerais","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénouions","word_nosc":"denouions","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénouât","word_nosc":"denouat","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénouèrent","word_nosc":"denouerent","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénoué","word_nosc":"denoue","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénouée","word_nosc":"denouee","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénouées","word_nosc":"denouees","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénoués","word_nosc":"denoues","lemma":"dénouer","pos":"VER"} ,
		{"word":"dénoyautait","word_nosc":"denoyautait","lemma":"dénoyauter","pos":"VER"} ,
		{"word":"dénoyauter","word_nosc":"denoyauter","lemma":"dénoyauter","pos":"VER"} ,
		{"word":"dénuda","word_nosc":"denuda","lemma":"dénuder","pos":"VER"} ,
		{"word":"dénudaient","word_nosc":"denudaient","lemma":"dénuder","pos":"VER"} ,
		{"word":"dénudait","word_nosc":"denudait","lemma":"dénuder","pos":"VER"} ,
		{"word":"dénudant","word_nosc":"denudant","lemma":"dénuder","pos":"VER"} ,
		{"word":"dénude","word_nosc":"denude","lemma":"dénuder","pos":"VER"} ,
		{"word":"dénudent","word_nosc":"denudent","lemma":"dénuder","pos":"VER"} ,
		{"word":"dénuder","word_nosc":"denuder","lemma":"dénuder","pos":"VER"} ,
		{"word":"dénudera","word_nosc":"denudera","lemma":"dénuder","pos":"VER"} ,
		{"word":"dénuderait","word_nosc":"denuderait","lemma":"dénuder","pos":"VER"} ,
		{"word":"dénudèrent","word_nosc":"denuderent","lemma":"dénuder","pos":"VER"} ,
		{"word":"dénudé","word_nosc":"denude","lemma":"dénuder","pos":"VER"} ,
		{"word":"dénudée","word_nosc":"denudee","lemma":"dénuder","pos":"VER"} ,
		{"word":"dénudées","word_nosc":"denudees","lemma":"dénuder","pos":"VER"} ,
		{"word":"dénudés","word_nosc":"denudes","lemma":"dénuder","pos":"VER"} ,
		{"word":"dénué","word_nosc":"denue","lemma":"dénuer","pos":"VER"} ,
		{"word":"dénuée","word_nosc":"denuee","lemma":"dénuer","pos":"VER"} ,
		{"word":"dénuées","word_nosc":"denuees","lemma":"dénuer","pos":"VER"} ,
		{"word":"dénués","word_nosc":"denues","lemma":"dénuer","pos":"VER"} ,
		{"word":"dépaillée","word_nosc":"depaillee","lemma":"dépailler","pos":"VER"} ,
		{"word":"dépaillées","word_nosc":"depaillees","lemma":"dépailler","pos":"VER"} ,
		{"word":"dépannaient","word_nosc":"depannaient","lemma":"dépanner","pos":"VER"} ,
		{"word":"dépannait","word_nosc":"depannait","lemma":"dépanner","pos":"VER"} ,
		{"word":"dépanne","word_nosc":"depanne","lemma":"dépanner","pos":"VER"} ,
		{"word":"dépannent","word_nosc":"depannent","lemma":"dépanner","pos":"VER"} ,
		{"word":"dépanner","word_nosc":"depanner","lemma":"dépanner","pos":"VER"} ,
		{"word":"dépannera","word_nosc":"depannera","lemma":"dépanner","pos":"VER"} ,
		{"word":"dépannerais","word_nosc":"depannerais","lemma":"dépanner","pos":"VER"} ,
		{"word":"dépannerait","word_nosc":"depannerait","lemma":"dépanner","pos":"VER"} ,
		{"word":"dépanneriez","word_nosc":"depanneriez","lemma":"dépanner","pos":"VER"} ,
		{"word":"dépanneront","word_nosc":"depanneront","lemma":"dépanner","pos":"VER"} ,
		{"word":"dépannons","word_nosc":"depannons","lemma":"dépanner","pos":"VER"} ,
		{"word":"dépanné","word_nosc":"depanne","lemma":"dépanner","pos":"VER"} ,
		{"word":"dépannée","word_nosc":"depannee","lemma":"dépanner","pos":"VER"} ,
		{"word":"dépannés","word_nosc":"depannes","lemma":"dépanner","pos":"VER"} ,
		{"word":"dépaquetait","word_nosc":"depaquetait","lemma":"dépaqueter","pos":"VER"} ,
		{"word":"déparaient","word_nosc":"deparaient","lemma":"déparer","pos":"VER"} ,
		{"word":"déparait","word_nosc":"deparait","lemma":"déparer","pos":"VER"} ,
		{"word":"dépare","word_nosc":"depare","lemma":"déparer","pos":"VER"} ,
		{"word":"dépareillaient","word_nosc":"depareillaient","lemma":"dépareiller","pos":"VER"} ,
		{"word":"dépareillait","word_nosc":"depareillait","lemma":"dépareiller","pos":"VER"} ,
		{"word":"dépareiller","word_nosc":"depareiller","lemma":"dépareiller","pos":"VER"} ,
		{"word":"dépareilleraient","word_nosc":"depareilleraient","lemma":"dépareiller","pos":"VER"} ,
		{"word":"dépareillé","word_nosc":"depareille","lemma":"dépareiller","pos":"VER"} ,
		{"word":"dépareillée","word_nosc":"depareillee","lemma":"dépareiller","pos":"VER"} ,
		{"word":"dépareillées","word_nosc":"depareillees","lemma":"dépareiller","pos":"VER"} ,
		{"word":"dépareillés","word_nosc":"depareilles","lemma":"dépareiller","pos":"VER"} ,
		{"word":"déparer","word_nosc":"deparer","lemma":"déparer","pos":"VER"} ,
		{"word":"déparerait","word_nosc":"deparerait","lemma":"déparer","pos":"VER"} ,
		{"word":"déparié","word_nosc":"deparie","lemma":"déparier","pos":"VER"} ,
		{"word":"dépariée","word_nosc":"depariee","lemma":"déparier","pos":"VER"} ,
		{"word":"dépariées","word_nosc":"depariees","lemma":"déparier","pos":"VER"} ,
		{"word":"dépariés","word_nosc":"deparies","lemma":"déparier","pos":"VER"} ,
		{"word":"déparlait","word_nosc":"deparlait","lemma":"déparler","pos":"VER"} ,
		{"word":"déparler","word_nosc":"deparler","lemma":"déparler","pos":"VER"} ,
		{"word":"départ","word_nosc":"depart","lemma":"départir","pos":"VER"} ,
		{"word":"départage","word_nosc":"departage","lemma":"départager","pos":"VER"} ,
		{"word":"départageant","word_nosc":"departageant","lemma":"départager","pos":"VER"} ,
		{"word":"départager","word_nosc":"departager","lemma":"départager","pos":"VER"} ,
		{"word":"départagera","word_nosc":"departagera","lemma":"départager","pos":"VER"} ,
		{"word":"départagions","word_nosc":"departagions","lemma":"départager","pos":"VER"} ,
		{"word":"départagé","word_nosc":"departage","lemma":"départager","pos":"VER"} ,
		{"word":"départait","word_nosc":"departait","lemma":"départir","pos":"VER"} ,
		{"word":"départent","word_nosc":"departent","lemma":"départir","pos":"VER"} ,
		{"word":"départi","word_nosc":"departi","lemma":"départir","pos":"VER"} ,
		{"word":"départie","word_nosc":"departie","lemma":"départir","pos":"VER"} ,
		{"word":"départir","word_nosc":"departir","lemma":"départir","pos":"VER"} ,
		{"word":"départira","word_nosc":"departira","lemma":"départir","pos":"VER"} ,
		{"word":"départirent","word_nosc":"departirent","lemma":"départir","pos":"VER"} ,
		{"word":"départis","word_nosc":"departis","lemma":"départir","pos":"VER"} ,
		{"word":"départit","word_nosc":"departit","lemma":"départir","pos":"VER"} ,
		{"word":"déparât","word_nosc":"deparat","lemma":"déparer","pos":"VER"} ,
		{"word":"déparé","word_nosc":"depare","lemma":"déparer","pos":"VER"} ,
		{"word":"dépassa","word_nosc":"depassa","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassai","word_nosc":"depassai","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassaient","word_nosc":"depassaient","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassais","word_nosc":"depassais","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassait","word_nosc":"depassait","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassant","word_nosc":"depassant","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépasse","word_nosc":"depasse","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassent","word_nosc":"depassent","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépasser","word_nosc":"depasser","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassera","word_nosc":"depassera","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépasserai","word_nosc":"depasserai","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépasseraient","word_nosc":"depasseraient","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépasserais","word_nosc":"depasserais","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépasserait","word_nosc":"depasserait","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépasseras","word_nosc":"depasseras","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépasserions","word_nosc":"depasserions","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépasseront","word_nosc":"depasseront","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépasses","word_nosc":"depasses","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassez","word_nosc":"depassez","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassiez","word_nosc":"depassiez","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassionnons","word_nosc":"depassionnons","lemma":"dépassionner","pos":"VER"} ,
		{"word":"dépassions","word_nosc":"depassions","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassons","word_nosc":"depassons","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassâmes","word_nosc":"depassames","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassât","word_nosc":"depassat","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassèrent","word_nosc":"depasserent","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassé","word_nosc":"depasse","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassée","word_nosc":"depassee","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassées","word_nosc":"depassees","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépassés","word_nosc":"depasses","lemma":"dépasser","pos":"VER"} ,
		{"word":"dépatouillait","word_nosc":"depatouillait","lemma":"dépatouiller","pos":"VER"} ,
		{"word":"dépatouille","word_nosc":"depatouille","lemma":"dépatouiller","pos":"VER"} ,
		{"word":"dépatouiller","word_nosc":"depatouiller","lemma":"dépatouiller","pos":"VER"} ,
		{"word":"dépaver","word_nosc":"depaver","lemma":"dépaver","pos":"VER"} ,
		{"word":"dépaysaient","word_nosc":"depaysaient","lemma":"dépayser","pos":"VER"} ,
		{"word":"dépaysait","word_nosc":"depaysait","lemma":"dépayser","pos":"VER"} ,
		{"word":"dépaysant","word_nosc":"depaysant","lemma":"dépayser","pos":"VER"} ,
		{"word":"dépayse","word_nosc":"depayse","lemma":"dépayser","pos":"VER"} ,
		{"word":"dépayser","word_nosc":"depayser","lemma":"dépayser","pos":"VER"} ,
		{"word":"dépaysera","word_nosc":"depaysera","lemma":"dépayser","pos":"VER"} ,
		{"word":"dépaysé","word_nosc":"depayse","lemma":"dépayser","pos":"VER"} ,
		{"word":"dépaysée","word_nosc":"depaysee","lemma":"dépayser","pos":"VER"} ,
		{"word":"dépaysés","word_nosc":"depayses","lemma":"dépayser","pos":"VER"} ,
		{"word":"dépecer","word_nosc":"depecer","lemma":"dépecer","pos":"VER"} ,
		{"word":"dépecez","word_nosc":"depecez","lemma":"dépecer","pos":"VER"} ,
		{"word":"dépecèrent","word_nosc":"depecerent","lemma":"dépecer","pos":"VER"} ,
		{"word":"dépecé","word_nosc":"depece","lemma":"dépecer","pos":"VER"} ,
		{"word":"dépecée","word_nosc":"depecee","lemma":"dépecer","pos":"VER"} ,
		{"word":"dépecés","word_nosc":"depeces","lemma":"dépecer","pos":"VER"} ,
		{"word":"dépeignaient","word_nosc":"depeignaient","lemma":"dépeindre","pos":"VER"} ,
		{"word":"dépeignais","word_nosc":"depeignais","lemma":"dépeindre","pos":"VER"} ,
		{"word":"dépeignait","word_nosc":"depeignait","lemma":"dépeindre","pos":"VER"} ,
		{"word":"dépeignant","word_nosc":"depeignant","lemma":"dépeigner","pos":"VER"} ,
		{"word":"dépeignant","word_nosc":"depeignant","lemma":"dépeindre","pos":"VER"} ,
		{"word":"dépeigne","word_nosc":"depeigne","lemma":"dépeigner","pos":"VER"} ,
		{"word":"dépeignent","word_nosc":"depeignent","lemma":"dépeindre","pos":"VER"} ,
		{"word":"dépeignirent","word_nosc":"depeignirent","lemma":"dépeindre","pos":"VER"} ,
		{"word":"dépeignis","word_nosc":"depeignis","lemma":"dépeindre","pos":"VER"} ,
		{"word":"dépeignit","word_nosc":"depeignit","lemma":"dépeindre","pos":"VER"} ,
		{"word":"dépeigné","word_nosc":"depeigne","lemma":"dépeigner","pos":"VER"} ,
		{"word":"dépeignée","word_nosc":"depeignee","lemma":"dépeigner","pos":"VER"} ,
		{"word":"dépeignés","word_nosc":"depeignes","lemma":"dépeigner","pos":"VER"} ,
		{"word":"dépeindre","word_nosc":"depeindre","lemma":"dépeindre","pos":"VER"} ,
		{"word":"dépeins","word_nosc":"depeins","lemma":"dépeindre","pos":"VER"} ,
		{"word":"dépeint","word_nosc":"depeint","lemma":"dépeindre","pos":"VER"} ,
		{"word":"dépeinte","word_nosc":"depeinte","lemma":"dépeindre","pos":"VER"} ,
		{"word":"dépeints","word_nosc":"depeints","lemma":"dépeindre","pos":"VER"} ,
		{"word":"dépend","word_nosc":"depend","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendaient","word_nosc":"dependaient","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendais","word_nosc":"dependais","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendait","word_nosc":"dependait","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendant","word_nosc":"dependant","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépende","word_nosc":"depende","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendent","word_nosc":"dependent","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendez","word_nosc":"dependez","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendions","word_nosc":"dependions","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendit","word_nosc":"dependit","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendons","word_nosc":"dependons","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendra","word_nosc":"dependra","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendraient","word_nosc":"dependraient","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendrait","word_nosc":"dependrait","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendre","word_nosc":"dependre","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendrez","word_nosc":"dependrez","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendront","word_nosc":"dependront","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépends","word_nosc":"depends","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendu","word_nosc":"dependu","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépendît","word_nosc":"dependit","lemma":"dépendre","pos":"VER"} ,
		{"word":"dépensa","word_nosc":"depensa","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépensai","word_nosc":"depensai","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépensaient","word_nosc":"depensaient","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépensais","word_nosc":"depensais","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépensait","word_nosc":"depensait","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépensant","word_nosc":"depensant","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépense","word_nosc":"depense","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépensent","word_nosc":"depensent","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépenser","word_nosc":"depenser","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépensera","word_nosc":"depensera","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépenserai","word_nosc":"depenserai","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépenseraient","word_nosc":"depenseraient","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépenserais","word_nosc":"depenserais","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépenserait","word_nosc":"depenserait","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépenseras","word_nosc":"depenseras","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépenserez","word_nosc":"depenserez","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépenserions","word_nosc":"depenserions","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépenseront","word_nosc":"depenseront","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépenses","word_nosc":"depenses","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépensez","word_nosc":"depensez","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépensiez","word_nosc":"depensiez","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépensons","word_nosc":"depensons","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépensé","word_nosc":"depense","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépensée","word_nosc":"depensee","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépensées","word_nosc":"depensees","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépensés","word_nosc":"depenses","lemma":"dépenser","pos":"VER"} ,
		{"word":"dépersonnaliser","word_nosc":"depersonnaliser","lemma":"dépersonnaliser","pos":"VER"} ,
		{"word":"dépersonnalisé","word_nosc":"depersonnalise","lemma":"dépersonnaliser","pos":"VER"} ,
		{"word":"dépeuplait","word_nosc":"depeuplait","lemma":"dépeupler","pos":"VER"} ,
		{"word":"dépeuple","word_nosc":"depeuple","lemma":"dépeupler","pos":"VER"} ,
		{"word":"dépeuplent","word_nosc":"depeuplent","lemma":"dépeupler","pos":"VER"} ,
		{"word":"dépeupler","word_nosc":"depeupler","lemma":"dépeupler","pos":"VER"} ,
		{"word":"dépeuplera","word_nosc":"depeuplera","lemma":"dépeupler","pos":"VER"} ,
		{"word":"dépeuplé","word_nosc":"depeuple","lemma":"dépeupler","pos":"VER"} ,
		{"word":"dépeuplée","word_nosc":"depeuplee","lemma":"dépeupler","pos":"VER"} ,
		{"word":"dépeuplés","word_nosc":"depeuples","lemma":"dépeupler","pos":"VER"} ,
		{"word":"dépeçaient","word_nosc":"depecaient","lemma":"dépecer","pos":"VER"} ,
		{"word":"dépeçait","word_nosc":"depecait","lemma":"dépecer","pos":"VER"} ,
		{"word":"dépeçant","word_nosc":"depecant","lemma":"dépecer","pos":"VER"} ,
		{"word":"déphase","word_nosc":"dephase","lemma":"déphaser","pos":"VER"} ,
		{"word":"déphasé","word_nosc":"dephase","lemma":"déphaser","pos":"VER"} ,
		{"word":"déphasée","word_nosc":"dephasee","lemma":"déphaser","pos":"VER"} ,
		{"word":"déphasés","word_nosc":"dephases","lemma":"déphaser","pos":"VER"} ,
		{"word":"dépiautaient","word_nosc":"depiautaient","lemma":"dépiauter","pos":"VER"} ,
		{"word":"dépiautait","word_nosc":"depiautait","lemma":"dépiauter","pos":"VER"} ,
		{"word":"dépiautant","word_nosc":"depiautant","lemma":"dépiauter","pos":"VER"} ,
		{"word":"dépiaute","word_nosc":"depiaute","lemma":"dépiauter","pos":"VER"} ,
		{"word":"dépiautent","word_nosc":"depiautent","lemma":"dépiauter","pos":"VER"} ,
		{"word":"dépiauter","word_nosc":"depiauter","lemma":"dépiauter","pos":"VER"} ,
		{"word":"dépiautera","word_nosc":"depiautera","lemma":"dépiauter","pos":"VER"} ,
		{"word":"dépiautèrent","word_nosc":"depiauterent","lemma":"dépiauter","pos":"VER"} ,
		{"word":"dépiauté","word_nosc":"depiaute","lemma":"dépiauter","pos":"VER"} ,
		{"word":"dépiautés","word_nosc":"depiautes","lemma":"dépiauter","pos":"VER"} ,
		{"word":"dépieute","word_nosc":"depieute","lemma":"dépieuter","pos":"VER"} ,
		{"word":"dépigmenté","word_nosc":"depigmente","lemma":"dépigmenter","pos":"VER"} ,
		{"word":"dépigmentés","word_nosc":"depigmentes","lemma":"dépigmenter","pos":"VER"} ,
		{"word":"dépiler","word_nosc":"depiler","lemma":"dépiler","pos":"VER"} ,
		{"word":"dépique","word_nosc":"depique","lemma":"dépiquer","pos":"VER"} ,
		{"word":"dépiquer","word_nosc":"depiquer","lemma":"dépiquer","pos":"VER"} ,
		{"word":"dépiqués","word_nosc":"depiques","lemma":"dépiquer","pos":"VER"} ,
		{"word":"dépistait","word_nosc":"depistait","lemma":"dépister","pos":"VER"} ,
		{"word":"dépistant","word_nosc":"depistant","lemma":"dépister","pos":"VER"} ,
		{"word":"dépiste","word_nosc":"depiste","lemma":"dépister","pos":"VER"} ,
		{"word":"dépister","word_nosc":"depister","lemma":"dépister","pos":"VER"} ,
		{"word":"dépisté","word_nosc":"depiste","lemma":"dépister","pos":"VER"} ,
		{"word":"dépita","word_nosc":"depita","lemma":"dépiter","pos":"VER"} ,
		{"word":"dépitait","word_nosc":"depitait","lemma":"dépiter","pos":"VER"} ,
		{"word":"dépiter","word_nosc":"depiter","lemma":"dépiter","pos":"VER"} ,
		{"word":"dépité","word_nosc":"depite","lemma":"dépiter","pos":"VER"} ,
		{"word":"dépitée","word_nosc":"depitee","lemma":"dépiter","pos":"VER"} ,
		{"word":"dépités","word_nosc":"depites","lemma":"dépiter","pos":"VER"} ,
		{"word":"déplace","word_nosc":"deplace","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplacent","word_nosc":"deplacent","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplacer","word_nosc":"deplacer","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplacera","word_nosc":"deplacera","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplacerai","word_nosc":"deplacerai","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplaceraient","word_nosc":"deplaceraient","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplacerait","word_nosc":"deplacerait","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplaceras","word_nosc":"deplaceras","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplacerez","word_nosc":"deplacerez","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplacerons","word_nosc":"deplacerons","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplaceront","word_nosc":"deplaceront","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplaces","word_nosc":"deplaces","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplacez","word_nosc":"deplacez","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplaciez","word_nosc":"deplaciez","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplacions","word_nosc":"deplacions","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplacèrent","word_nosc":"deplacerent","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplacé","word_nosc":"deplace","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplacée","word_nosc":"deplacee","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplacées","word_nosc":"deplacees","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplacés","word_nosc":"deplaces","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplaira","word_nosc":"deplaira","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplairaient","word_nosc":"deplairaient","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplairait","word_nosc":"deplairait","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplaire","word_nosc":"deplaire","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplairont","word_nosc":"deplairont","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplais","word_nosc":"deplais","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplaisaient","word_nosc":"deplaisaient","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplaisais","word_nosc":"deplaisais","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplaisait","word_nosc":"deplaisait","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplaisant","word_nosc":"deplaisant","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplaise","word_nosc":"deplaise","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplaisent","word_nosc":"deplaisent","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplaisez","word_nosc":"deplaisez","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplaisiez","word_nosc":"deplaisiez","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplanque","word_nosc":"deplanque","lemma":"déplanquer","pos":"VER"} ,
		{"word":"déplantèrent","word_nosc":"deplanterent","lemma":"déplanter","pos":"VER"} ,
		{"word":"déplaça","word_nosc":"deplaca","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplaçai","word_nosc":"deplacai","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplaçaient","word_nosc":"deplacaient","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplaçais","word_nosc":"deplacais","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplaçait","word_nosc":"deplacait","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplaçant","word_nosc":"deplacant","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplaçons","word_nosc":"deplacons","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplaçât","word_nosc":"deplacat","lemma":"déplacer","pos":"VER"} ,
		{"word":"déplaît","word_nosc":"deplait","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplia","word_nosc":"deplia","lemma":"déplier","pos":"VER"} ,
		{"word":"dépliai","word_nosc":"depliai","lemma":"déplier","pos":"VER"} ,
		{"word":"dépliaient","word_nosc":"depliaient","lemma":"déplier","pos":"VER"} ,
		{"word":"dépliais","word_nosc":"depliais","lemma":"déplier","pos":"VER"} ,
		{"word":"dépliait","word_nosc":"depliait","lemma":"déplier","pos":"VER"} ,
		{"word":"dépliant","word_nosc":"depliant","lemma":"déplier","pos":"VER"} ,
		{"word":"déplie","word_nosc":"deplie","lemma":"déplier","pos":"VER"} ,
		{"word":"déplient","word_nosc":"deplient","lemma":"déplier","pos":"VER"} ,
		{"word":"déplier","word_nosc":"deplier","lemma":"déplier","pos":"VER"} ,
		{"word":"déplies","word_nosc":"deplies","lemma":"déplier","pos":"VER"} ,
		{"word":"déplions","word_nosc":"deplions","lemma":"déplier","pos":"VER"} ,
		{"word":"déplissa","word_nosc":"deplissa","lemma":"déplisser","pos":"VER"} ,
		{"word":"déplissaient","word_nosc":"deplissaient","lemma":"déplisser","pos":"VER"} ,
		{"word":"déplissait","word_nosc":"deplissait","lemma":"déplisser","pos":"VER"} ,
		{"word":"déplisse","word_nosc":"deplisse","lemma":"déplisser","pos":"VER"} ,
		{"word":"déplissent","word_nosc":"deplissent","lemma":"déplisser","pos":"VER"} ,
		{"word":"déplisser","word_nosc":"deplisser","lemma":"déplisser","pos":"VER"} ,
		{"word":"dépliâmes","word_nosc":"depliames","lemma":"déplier","pos":"VER"} ,
		{"word":"déplièrent","word_nosc":"deplierent","lemma":"déplier","pos":"VER"} ,
		{"word":"déplié","word_nosc":"deplie","lemma":"déplier","pos":"VER"} ,
		{"word":"dépliée","word_nosc":"depliee","lemma":"déplier","pos":"VER"} ,
		{"word":"dépliées","word_nosc":"depliees","lemma":"déplier","pos":"VER"} ,
		{"word":"dépliés","word_nosc":"deplies","lemma":"déplier","pos":"VER"} ,
		{"word":"déploie","word_nosc":"deploie","lemma":"déployer","pos":"VER"} ,
		{"word":"déploient","word_nosc":"deploient","lemma":"déployer","pos":"VER"} ,
		{"word":"déploiera","word_nosc":"deploiera","lemma":"déployer","pos":"VER"} ,
		{"word":"déploierai","word_nosc":"deploierai","lemma":"déployer","pos":"VER"} ,
		{"word":"déploieraient","word_nosc":"deploieraient","lemma":"déployer","pos":"VER"} ,
		{"word":"déploierait","word_nosc":"deploierait","lemma":"déployer","pos":"VER"} ,
		{"word":"déploieras","word_nosc":"deploieras","lemma":"déployer","pos":"VER"} ,
		{"word":"déploierez","word_nosc":"deploierez","lemma":"déployer","pos":"VER"} ,
		{"word":"déploierons","word_nosc":"deploierons","lemma":"déployer","pos":"VER"} ,
		{"word":"déploieront","word_nosc":"deploieront","lemma":"déployer","pos":"VER"} ,
		{"word":"déploies","word_nosc":"deploies","lemma":"déployer","pos":"VER"} ,
		{"word":"déplombé","word_nosc":"deplombe","lemma":"déplomber","pos":"VER"} ,
		{"word":"déplora","word_nosc":"deplora","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplorai","word_nosc":"deplorai","lemma":"déplorer","pos":"VER"} ,
		{"word":"déploraient","word_nosc":"deploraient","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplorais","word_nosc":"deplorais","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplorait","word_nosc":"deplorait","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplorant","word_nosc":"deplorant","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplore","word_nosc":"deplore","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplorent","word_nosc":"deplorent","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplorer","word_nosc":"deplorer","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplorerais","word_nosc":"deplorerais","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplorerait","word_nosc":"deplorerait","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplorerons","word_nosc":"deplorerons","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplorez","word_nosc":"deplorez","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplorions","word_nosc":"deplorions","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplorons","word_nosc":"deplorons","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplorèrent","word_nosc":"deplorerent","lemma":"déplorer","pos":"VER"} ,
		{"word":"déploré","word_nosc":"deplore","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplorée","word_nosc":"deploree","lemma":"déplorer","pos":"VER"} ,
		{"word":"déplorés","word_nosc":"deplores","lemma":"déplorer","pos":"VER"} ,
		{"word":"déploya","word_nosc":"deploya","lemma":"déployer","pos":"VER"} ,
		{"word":"déployaient","word_nosc":"deployaient","lemma":"déployer","pos":"VER"} ,
		{"word":"déployais","word_nosc":"deployais","lemma":"déployer","pos":"VER"} ,
		{"word":"déployait","word_nosc":"deployait","lemma":"déployer","pos":"VER"} ,
		{"word":"déployant","word_nosc":"deployant","lemma":"déployer","pos":"VER"} ,
		{"word":"déployasse","word_nosc":"deployasse","lemma":"déployer","pos":"VER"} ,
		{"word":"déployer","word_nosc":"deployer","lemma":"déployer","pos":"VER"} ,
		{"word":"déployez","word_nosc":"deployez","lemma":"déployer","pos":"VER"} ,
		{"word":"déployions","word_nosc":"deployions","lemma":"déployer","pos":"VER"} ,
		{"word":"déployons","word_nosc":"deployons","lemma":"déployer","pos":"VER"} ,
		{"word":"déployât","word_nosc":"deployat","lemma":"déployer","pos":"VER"} ,
		{"word":"déployèrent","word_nosc":"deployerent","lemma":"déployer","pos":"VER"} ,
		{"word":"déployé","word_nosc":"deploye","lemma":"déployer","pos":"VER"} ,
		{"word":"déployée","word_nosc":"deployee","lemma":"déployer","pos":"VER"} ,
		{"word":"déployées","word_nosc":"deployees","lemma":"déployer","pos":"VER"} ,
		{"word":"déployés","word_nosc":"deployes","lemma":"déployer","pos":"VER"} ,
		{"word":"déplu","word_nosc":"deplu","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplumait","word_nosc":"deplumait","lemma":"déplumer","pos":"VER"} ,
		{"word":"déplume","word_nosc":"deplume","lemma":"déplumer","pos":"VER"} ,
		{"word":"déplumer","word_nosc":"deplumer","lemma":"déplumer","pos":"VER"} ,
		{"word":"déplumé","word_nosc":"deplume","lemma":"déplumer","pos":"VER"} ,
		{"word":"déplumée","word_nosc":"deplumee","lemma":"déplumer","pos":"VER"} ,
		{"word":"déplumés","word_nosc":"deplumes","lemma":"déplumer","pos":"VER"} ,
		{"word":"déplurent","word_nosc":"deplurent","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplut","word_nosc":"deplut","lemma":"déplaire","pos":"VER"} ,
		{"word":"déplâtré","word_nosc":"deplatre","lemma":"déplâtrer","pos":"VER"} ,
		{"word":"déplût","word_nosc":"deplut","lemma":"déplaire","pos":"VER"} ,
		{"word":"dépoile","word_nosc":"depoile","lemma":"dépoiler","pos":"VER"} ,
		{"word":"dépoiler","word_nosc":"depoiler","lemma":"dépoiler","pos":"VER"} ,
		{"word":"dépoilé","word_nosc":"depoile","lemma":"dépoiler","pos":"VER"} ,
		{"word":"dépoilée","word_nosc":"depoilee","lemma":"dépoiler","pos":"VER"} ,
		{"word":"dépoilées","word_nosc":"depoilees","lemma":"dépoiler","pos":"VER"} ,
		{"word":"dépointées","word_nosc":"depointees","lemma":"dépointer","pos":"VER"} ,
		{"word":"dépoitraillé","word_nosc":"depoitraille","lemma":"dépoitrailler","pos":"VER"} ,
		{"word":"dépoli","word_nosc":"depoli","lemma":"dépolir","pos":"VER"} ,
		{"word":"dépolies","word_nosc":"depolies","lemma":"dépolir","pos":"VER"} ,
		{"word":"dépolis","word_nosc":"depolis","lemma":"dépolir","pos":"VER"} ,
		{"word":"dépolissait","word_nosc":"depolissait","lemma":"dépolir","pos":"VER"} ,
		{"word":"dépolit","word_nosc":"depolit","lemma":"dépolir","pos":"VER"} ,
		{"word":"dépolitisant","word_nosc":"depolitisant","lemma":"dépolitiser","pos":"VER"} ,
		{"word":"dépolitisé","word_nosc":"depolitise","lemma":"dépolitiser","pos":"VER"} ,
		{"word":"dépolluer","word_nosc":"depolluer","lemma":"dépolluer","pos":"VER"} ,
		{"word":"déporta","word_nosc":"deporta","lemma":"déporter","pos":"VER"} ,
		{"word":"déportaient","word_nosc":"deportaient","lemma":"déporter","pos":"VER"} ,
		{"word":"déportais","word_nosc":"deportais","lemma":"déporter","pos":"VER"} ,
		{"word":"déportait","word_nosc":"deportait","lemma":"déporter","pos":"VER"} ,
		{"word":"déportant","word_nosc":"deportant","lemma":"déporter","pos":"VER"} ,
		{"word":"déporte","word_nosc":"deporte","lemma":"déporter","pos":"VER"} ,
		{"word":"déporter","word_nosc":"deporter","lemma":"déporter","pos":"VER"} ,
		{"word":"déporteront","word_nosc":"deporteront","lemma":"déporter","pos":"VER"} ,
		{"word":"déportez","word_nosc":"deportez","lemma":"déporter","pos":"VER"} ,
		{"word":"déporté","word_nosc":"deporte","lemma":"déporter","pos":"VER"} ,
		{"word":"déportée","word_nosc":"deportee","lemma":"déporter","pos":"VER"} ,
		{"word":"déportées","word_nosc":"deportees","lemma":"déporter","pos":"VER"} ,
		{"word":"déportés","word_nosc":"deportes","lemma":"déporter","pos":"VER"} ,
		{"word":"déposa","word_nosc":"deposa","lemma":"déposer","pos":"VER"} ,
		{"word":"déposai","word_nosc":"deposai","lemma":"déposer","pos":"VER"} ,
		{"word":"déposaient","word_nosc":"deposaient","lemma":"déposer","pos":"VER"} ,
		{"word":"déposais","word_nosc":"deposais","lemma":"déposer","pos":"VER"} ,
		{"word":"déposait","word_nosc":"deposait","lemma":"déposer","pos":"VER"} ,
		{"word":"déposant","word_nosc":"deposant","lemma":"déposer","pos":"VER"} ,
		{"word":"dépose","word_nosc":"depose","lemma":"déposer","pos":"VER"} ,
		{"word":"déposent","word_nosc":"deposent","lemma":"déposer","pos":"VER"} ,
		{"word":"déposer","word_nosc":"deposer","lemma":"déposer","pos":"VER"} ,
		{"word":"déposera","word_nosc":"deposera","lemma":"déposer","pos":"VER"} ,
		{"word":"déposerai","word_nosc":"deposerai","lemma":"déposer","pos":"VER"} ,
		{"word":"déposeraient","word_nosc":"deposeraient","lemma":"déposer","pos":"VER"} ,
		{"word":"déposerais","word_nosc":"deposerais","lemma":"déposer","pos":"VER"} ,
		{"word":"déposerait","word_nosc":"deposerait","lemma":"déposer","pos":"VER"} ,
		{"word":"déposeras","word_nosc":"deposeras","lemma":"déposer","pos":"VER"} ,
		{"word":"déposerez","word_nosc":"deposerez","lemma":"déposer","pos":"VER"} ,
		{"word":"déposerions","word_nosc":"deposerions","lemma":"déposer","pos":"VER"} ,
		{"word":"déposerons","word_nosc":"deposerons","lemma":"déposer","pos":"VER"} ,
		{"word":"déposeront","word_nosc":"deposeront","lemma":"déposer","pos":"VER"} ,
		{"word":"déposes","word_nosc":"deposes","lemma":"déposer","pos":"VER"} ,
		{"word":"déposez","word_nosc":"deposez","lemma":"déposer","pos":"VER"} ,
		{"word":"déposiez","word_nosc":"deposiez","lemma":"déposer","pos":"VER"} ,
		{"word":"déposions","word_nosc":"deposions","lemma":"déposer","pos":"VER"} ,
		{"word":"déposons","word_nosc":"deposons","lemma":"déposer","pos":"VER"} ,
		{"word":"dépossède","word_nosc":"depossede","lemma":"déposséder","pos":"VER"} ,
		{"word":"dépossédais","word_nosc":"depossedais","lemma":"déposséder","pos":"VER"} ,
		{"word":"dépossédait","word_nosc":"depossedait","lemma":"déposséder","pos":"VER"} ,
		{"word":"déposséder","word_nosc":"deposseder","lemma":"déposséder","pos":"VER"} ,
		{"word":"dépossédé","word_nosc":"depossede","lemma":"déposséder","pos":"VER"} ,
		{"word":"dépossédée","word_nosc":"depossedee","lemma":"déposséder","pos":"VER"} ,
		{"word":"dépossédées","word_nosc":"depossedees","lemma":"déposséder","pos":"VER"} ,
		{"word":"dépossédés","word_nosc":"depossedes","lemma":"déposséder","pos":"VER"} ,
		{"word":"déposâmes","word_nosc":"deposames","lemma":"déposer","pos":"VER"} ,
		{"word":"déposât","word_nosc":"deposat","lemma":"déposer","pos":"VER"} ,
		{"word":"déposèrent","word_nosc":"deposerent","lemma":"déposer","pos":"VER"} ,
		{"word":"déposé","word_nosc":"depose","lemma":"déposer","pos":"VER"} ,
		{"word":"déposée","word_nosc":"deposee","lemma":"déposer","pos":"VER"} ,
		{"word":"déposées","word_nosc":"deposees","lemma":"déposer","pos":"VER"} ,
		{"word":"déposés","word_nosc":"deposes","lemma":"déposer","pos":"VER"} ,
		{"word":"dépote","word_nosc":"depote","lemma":"dépoter","pos":"VER"} ,
		{"word":"dépotent","word_nosc":"depotent","lemma":"dépoter","pos":"VER"} ,
		{"word":"dépoter","word_nosc":"depoter","lemma":"dépoter","pos":"VER"} ,
		{"word":"dépoté","word_nosc":"depote","lemma":"dépoter","pos":"VER"} ,
		{"word":"dépoudrer","word_nosc":"depoudrer","lemma":"dépoudrer","pos":"VER"} ,
		{"word":"dépouilla","word_nosc":"depouilla","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillaient","word_nosc":"depouillaient","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillais","word_nosc":"depouillais","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillait","word_nosc":"depouillait","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillant","word_nosc":"depouillant","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouille","word_nosc":"depouille","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillent","word_nosc":"depouillent","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouiller","word_nosc":"depouiller","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillera","word_nosc":"depouillera","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillerais","word_nosc":"depouillerais","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillerait","word_nosc":"depouillerait","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillerez","word_nosc":"depouillerez","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouilles","word_nosc":"depouilles","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillez","word_nosc":"depouillez","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillons","word_nosc":"depouillons","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillât","word_nosc":"depouillat","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillèrent","word_nosc":"depouillerent","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillé","word_nosc":"depouille","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillée","word_nosc":"depouillee","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillées","word_nosc":"depouillees","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépouillés","word_nosc":"depouilles","lemma":"dépouiller","pos":"VER"} ,
		{"word":"dépourvu","word_nosc":"depourvu","lemma":"dépourvoir","pos":"VER"} ,
		{"word":"dépourvue","word_nosc":"depourvue","lemma":"dépourvoir","pos":"VER"} ,
		{"word":"dépourvues","word_nosc":"depourvues","lemma":"dépourvoir","pos":"VER"} ,
		{"word":"dépourvus","word_nosc":"depourvus","lemma":"dépourvoir","pos":"VER"} ,
		{"word":"dépoussière","word_nosc":"depoussiere","lemma":"dépoussiérer","pos":"VER"} ,
		{"word":"dépoussiéraient","word_nosc":"depoussieraient","lemma":"dépoussiérer","pos":"VER"} ,
		{"word":"dépoussiérant","word_nosc":"depoussierant","lemma":"dépoussiérer","pos":"VER"} ,
		{"word":"dépoussiérer","word_nosc":"depoussierer","lemma":"dépoussiérer","pos":"VER"} ,
		{"word":"dépoussiérez","word_nosc":"depoussierez","lemma":"dépoussiérer","pos":"VER"} ,
		{"word":"dépoussiéré","word_nosc":"depoussiere","lemma":"dépoussiérer","pos":"VER"} ,
		{"word":"dépoétisent","word_nosc":"depoetisent","lemma":"dépoétiser","pos":"VER"} ,
		{"word":"dépraver","word_nosc":"depraver","lemma":"dépraver","pos":"VER"} ,
		{"word":"dépravons","word_nosc":"depravons","lemma":"dépraver","pos":"VER"} ,
		{"word":"dépravé","word_nosc":"deprave","lemma":"dépraver","pos":"VER"} ,
		{"word":"dépravée","word_nosc":"depravee","lemma":"dépraver","pos":"VER"} ,
		{"word":"dépravés","word_nosc":"depraves","lemma":"dépraver","pos":"VER"} ,
		{"word":"déprenais","word_nosc":"deprenais","lemma":"déprendre","pos":"VER"} ,
		{"word":"déprenait","word_nosc":"deprenait","lemma":"déprendre","pos":"VER"} ,
		{"word":"déprend","word_nosc":"deprend","lemma":"déprendre","pos":"VER"} ,
		{"word":"déprendre","word_nosc":"deprendre","lemma":"déprendre","pos":"VER"} ,
		{"word":"déprendront","word_nosc":"deprendront","lemma":"déprendre","pos":"VER"} ,
		{"word":"dépressurisait","word_nosc":"depressurisait","lemma":"dépressuriser","pos":"VER"} ,
		{"word":"dépressurise","word_nosc":"depressurise","lemma":"dépressuriser","pos":"VER"} ,
		{"word":"dépressuriser","word_nosc":"depressuriser","lemma":"dépressuriser","pos":"VER"} ,
		{"word":"dépressurisé","word_nosc":"depressurise","lemma":"dépressuriser","pos":"VER"} ,
		{"word":"dépressurisés","word_nosc":"depressurises","lemma":"dépressuriser","pos":"VER"} ,
		{"word":"déprima","word_nosc":"deprima","lemma":"déprimer","pos":"VER"} ,
		{"word":"déprimais","word_nosc":"deprimais","lemma":"déprimer","pos":"VER"} ,
		{"word":"déprimait","word_nosc":"deprimait","lemma":"déprimer","pos":"VER"} ,
		{"word":"déprimant","word_nosc":"deprimant","lemma":"déprimer","pos":"VER"} ,
		{"word":"déprime","word_nosc":"deprime","lemma":"déprimer","pos":"VER"} ,
		{"word":"dépriment","word_nosc":"depriment","lemma":"déprimer","pos":"VER"} ,
		{"word":"déprimer","word_nosc":"deprimer","lemma":"déprimer","pos":"VER"} ,
		{"word":"déprimerais","word_nosc":"deprimerais","lemma":"déprimer","pos":"VER"} ,
		{"word":"déprimerait","word_nosc":"deprimerait","lemma":"déprimer","pos":"VER"} ,
		{"word":"déprimes","word_nosc":"deprimes","lemma":"déprimer","pos":"VER"} ,
		{"word":"déprimez","word_nosc":"deprimez","lemma":"déprimer","pos":"VER"} ,
		{"word":"déprimé","word_nosc":"deprime","lemma":"déprimer","pos":"VER"} ,
		{"word":"déprimée","word_nosc":"deprimee","lemma":"déprimer","pos":"VER"} ,
		{"word":"déprimées","word_nosc":"deprimees","lemma":"déprimer","pos":"VER"} ,
		{"word":"déprimés","word_nosc":"deprimes","lemma":"déprimer","pos":"VER"} ,
		{"word":"déprirent","word_nosc":"deprirent","lemma":"déprendre","pos":"VER"} ,
		{"word":"dépris","word_nosc":"depris","lemma":"déprendre","pos":"VER"} ,
		{"word":"déprit","word_nosc":"deprit","lemma":"déprendre","pos":"VER"} ,
		{"word":"déprogrammer","word_nosc":"deprogrammer","lemma":"déprogrammer","pos":"VER"} ,
		{"word":"déprogrammez","word_nosc":"deprogrammez","lemma":"déprogrammer","pos":"VER"} ,
		{"word":"déprogrammé","word_nosc":"deprogramme","lemma":"déprogrammer","pos":"VER"} ,
		{"word":"déprogrammée","word_nosc":"deprogrammee","lemma":"déprogrammer","pos":"VER"} ,
		{"word":"dépréciait","word_nosc":"depreciait","lemma":"déprécier","pos":"VER"} ,
		{"word":"dépréciant","word_nosc":"depreciant","lemma":"déprécier","pos":"VER"} ,
		{"word":"déprécie","word_nosc":"deprecie","lemma":"déprécier","pos":"VER"} ,
		{"word":"déprécier","word_nosc":"deprecier","lemma":"déprécier","pos":"VER"} ,
		{"word":"déprécies","word_nosc":"deprecies","lemma":"déprécier","pos":"VER"} ,
		{"word":"dépréciez","word_nosc":"depreciez","lemma":"déprécier","pos":"VER"} ,
		{"word":"dépréciiez","word_nosc":"depreciiez","lemma":"déprécier","pos":"VER"} ,
		{"word":"déprécié","word_nosc":"deprecie","lemma":"déprécier","pos":"VER"} ,
		{"word":"dépréciée","word_nosc":"depreciee","lemma":"déprécier","pos":"VER"} ,
		{"word":"dépucela","word_nosc":"depucela","lemma":"dépuceler","pos":"VER"} ,
		{"word":"dépuceler","word_nosc":"depuceler","lemma":"dépuceler","pos":"VER"} ,
		{"word":"dépucelle","word_nosc":"depucelle","lemma":"dépuceler","pos":"VER"} ,
		{"word":"dépucellera","word_nosc":"depucellera","lemma":"dépuceler","pos":"VER"} ,
		{"word":"dépucelons","word_nosc":"depucelons","lemma":"dépuceler","pos":"VER"} ,
		{"word":"dépucelé","word_nosc":"depucele","lemma":"dépuceler","pos":"VER"} ,
		{"word":"dépucelée","word_nosc":"depucelee","lemma":"dépuceler","pos":"VER"} ,
		{"word":"dépulpé","word_nosc":"depulpe","lemma":"dépulper","pos":"VER"} ,
		{"word":"dépurés","word_nosc":"depures","lemma":"dépurer","pos":"VER"} ,
		{"word":"députer","word_nosc":"deputer","lemma":"députer","pos":"VER"} ,
		{"word":"députèrent","word_nosc":"deputerent","lemma":"députer","pos":"VER"} ,
		{"word":"députée","word_nosc":"deputee","lemma":"députer","pos":"VER"} ,
		{"word":"dépèce","word_nosc":"depece","lemma":"dépecer","pos":"VER"} ,
		{"word":"dépècent","word_nosc":"depecent","lemma":"dépecer","pos":"VER"} ,
		{"word":"dépècera","word_nosc":"depecera","lemma":"dépecer","pos":"VER"} ,
		{"word":"dépècerai","word_nosc":"depecerai","lemma":"dépecer","pos":"VER"} ,
		{"word":"dépèceraient","word_nosc":"depeceraient","lemma":"dépecer","pos":"VER"} ,
		{"word":"dépénaliser","word_nosc":"depenaliser","lemma":"dépénaliser","pos":"VER"} ,
		{"word":"dépéri","word_nosc":"deperi","lemma":"dépérir","pos":"VER"} ,
		{"word":"dépérir","word_nosc":"deperir","lemma":"dépérir","pos":"VER"} ,
		{"word":"dépérira","word_nosc":"deperira","lemma":"dépérir","pos":"VER"} ,
		{"word":"dépériraient","word_nosc":"deperiraient","lemma":"dépérir","pos":"VER"} ,
		{"word":"dépérirait","word_nosc":"deperirait","lemma":"dépérir","pos":"VER"} ,
		{"word":"dépériront","word_nosc":"deperiront","lemma":"dépérir","pos":"VER"} ,
		{"word":"dépéris","word_nosc":"deperis","lemma":"dépérir","pos":"VER"} ,
		{"word":"dépérissaient","word_nosc":"deperissaient","lemma":"dépérir","pos":"VER"} ,
		{"word":"dépérissais","word_nosc":"deperissais","lemma":"dépérir","pos":"VER"} ,
		{"word":"dépérissait","word_nosc":"deperissait","lemma":"dépérir","pos":"VER"} ,
		{"word":"dépérisse","word_nosc":"deperisse","lemma":"dépérir","pos":"VER"} ,
		{"word":"dépérissent","word_nosc":"deperissent","lemma":"dépérir","pos":"VER"} ,
		{"word":"dépérit","word_nosc":"deperit","lemma":"dépérir","pos":"VER"} ,
		{"word":"dépêcha","word_nosc":"depecha","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêchai","word_nosc":"depechai","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêchaient","word_nosc":"depechaient","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêchais","word_nosc":"depechais","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêchait","word_nosc":"depechait","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêchant","word_nosc":"depechant","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêche","word_nosc":"depeche","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêchent","word_nosc":"depechent","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêcher","word_nosc":"depecher","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêcherais","word_nosc":"depecherais","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêcherons","word_nosc":"depecherons","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêches","word_nosc":"depeches","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêchez","word_nosc":"depechez","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêchions","word_nosc":"depechions","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêchons","word_nosc":"depechons","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêchât","word_nosc":"depechat","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêchèrent","word_nosc":"depecherent","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêché","word_nosc":"depeche","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêchée","word_nosc":"depechee","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêchées","word_nosc":"depechees","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêchés","word_nosc":"depeches","lemma":"dépêcher","pos":"VER"} ,
		{"word":"dépêtrer","word_nosc":"depetrer","lemma":"dépêtrer","pos":"VER"} ,
		{"word":"dépêtré","word_nosc":"depetre","lemma":"dépêtrer","pos":"VER"} ,
		{"word":"dépêtrée","word_nosc":"depetree","lemma":"dépêtrer","pos":"VER"} ,
		{"word":"dépêtrés","word_nosc":"depetres","lemma":"dépêtrer","pos":"VER"} ,
		{"word":"déqualifier","word_nosc":"dequalifier","lemma":"déqualifier","pos":"VER"} ,
		{"word":"déquiller","word_nosc":"dequiller","lemma":"déquiller","pos":"VER"} ,
		{"word":"déquillé","word_nosc":"dequille","lemma":"déquiller","pos":"VER"} ,
		{"word":"déquillés","word_nosc":"dequilles","lemma":"déquiller","pos":"VER"} ,
		{"word":"déracina","word_nosc":"deracina","lemma":"déraciner","pos":"VER"} ,
		{"word":"déracinaient","word_nosc":"deracinaient","lemma":"déraciner","pos":"VER"} ,
		{"word":"déracinais","word_nosc":"deracinais","lemma":"déraciner","pos":"VER"} ,
		{"word":"déracinait","word_nosc":"deracinait","lemma":"déraciner","pos":"VER"} ,
		{"word":"déracine","word_nosc":"deracine","lemma":"déraciner","pos":"VER"} ,
		{"word":"déracinent","word_nosc":"deracinent","lemma":"déraciner","pos":"VER"} ,
		{"word":"déraciner","word_nosc":"deraciner","lemma":"déraciner","pos":"VER"} ,
		{"word":"déracinerait","word_nosc":"deracinerait","lemma":"déraciner","pos":"VER"} ,
		{"word":"déraciné","word_nosc":"deracine","lemma":"déraciner","pos":"VER"} ,
		{"word":"déracinée","word_nosc":"deracinee","lemma":"déraciner","pos":"VER"} ,
		{"word":"déracinées","word_nosc":"deracinees","lemma":"déraciner","pos":"VER"} ,
		{"word":"déracinés","word_nosc":"deracines","lemma":"déraciner","pos":"VER"} ,
		{"word":"dérailla","word_nosc":"derailla","lemma":"dérailler","pos":"VER"} ,
		{"word":"déraillaient","word_nosc":"deraillaient","lemma":"dérailler","pos":"VER"} ,
		{"word":"déraillais","word_nosc":"deraillais","lemma":"dérailler","pos":"VER"} ,
		{"word":"déraillait","word_nosc":"deraillait","lemma":"dérailler","pos":"VER"} ,
		{"word":"déraillant","word_nosc":"deraillant","lemma":"dérailler","pos":"VER"} ,
		{"word":"déraille","word_nosc":"deraille","lemma":"dérailler","pos":"VER"} ,
		{"word":"déraillent","word_nosc":"deraillent","lemma":"dérailler","pos":"VER"} ,
		{"word":"dérailler","word_nosc":"derailler","lemma":"dérailler","pos":"VER"} ,
		{"word":"déraillera","word_nosc":"deraillera","lemma":"dérailler","pos":"VER"} ,
		{"word":"dérailles","word_nosc":"derailles","lemma":"dérailler","pos":"VER"} ,
		{"word":"déraillez","word_nosc":"deraillez","lemma":"dérailler","pos":"VER"} ,
		{"word":"déraillé","word_nosc":"deraille","lemma":"dérailler","pos":"VER"} ,
		{"word":"déraisonnait","word_nosc":"deraisonnait","lemma":"déraisonner","pos":"VER"} ,
		{"word":"déraisonne","word_nosc":"deraisonne","lemma":"déraisonner","pos":"VER"} ,
		{"word":"déraisonner","word_nosc":"deraisonner","lemma":"déraisonner","pos":"VER"} ,
		{"word":"déraisonnes","word_nosc":"deraisonnes","lemma":"déraisonner","pos":"VER"} ,
		{"word":"déraisonnez","word_nosc":"deraisonnez","lemma":"déraisonner","pos":"VER"} ,
		{"word":"déraisonné","word_nosc":"deraisonne","lemma":"déraisonner","pos":"VER"} ,
		{"word":"dérange","word_nosc":"derange","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangea","word_nosc":"derangea","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangeaient","word_nosc":"derangeaient","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangeais","word_nosc":"derangeais","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangeait","word_nosc":"derangeait","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangeant","word_nosc":"derangeant","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangeassent","word_nosc":"derangeassent","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangent","word_nosc":"derangent","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangeons","word_nosc":"derangeons","lemma":"déranger","pos":"VER"} ,
		{"word":"déranger","word_nosc":"deranger","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangera","word_nosc":"derangera","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangerai","word_nosc":"derangerai","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangeraient","word_nosc":"derangeraient","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangerais","word_nosc":"derangerais","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangerait","word_nosc":"derangerait","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangeras","word_nosc":"derangeras","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangerez","word_nosc":"derangerez","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangerons","word_nosc":"derangerons","lemma":"déranger","pos":"VER"} ,
		{"word":"déranges","word_nosc":"deranges","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangez","word_nosc":"derangez","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangeât","word_nosc":"derangeat","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangiez","word_nosc":"derangiez","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangions","word_nosc":"derangions","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangé","word_nosc":"derange","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangée","word_nosc":"derangee","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangées","word_nosc":"derangees","lemma":"déranger","pos":"VER"} ,
		{"word":"dérangés","word_nosc":"deranges","lemma":"déranger","pos":"VER"} ,
		{"word":"dérapa","word_nosc":"derapa","lemma":"déraper","pos":"VER"} ,
		{"word":"dérapai","word_nosc":"derapai","lemma":"déraper","pos":"VER"} ,
		{"word":"dérapaient","word_nosc":"derapaient","lemma":"déraper","pos":"VER"} ,
		{"word":"dérapait","word_nosc":"derapait","lemma":"déraper","pos":"VER"} ,
		{"word":"dérapant","word_nosc":"derapant","lemma":"déraper","pos":"VER"} ,
		{"word":"dérape","word_nosc":"derape","lemma":"déraper","pos":"VER"} ,
		{"word":"dérapent","word_nosc":"derapent","lemma":"déraper","pos":"VER"} ,
		{"word":"déraper","word_nosc":"deraper","lemma":"déraper","pos":"VER"} ,
		{"word":"déraperont","word_nosc":"deraperont","lemma":"déraper","pos":"VER"} ,
		{"word":"dérapes","word_nosc":"derapes","lemma":"déraper","pos":"VER"} ,
		{"word":"dérapez","word_nosc":"derapez","lemma":"déraper","pos":"VER"} ,
		{"word":"dérapé","word_nosc":"derape","lemma":"déraper","pos":"VER"} ,
		{"word":"dératiser","word_nosc":"deratiser","lemma":"dératiser","pos":"VER"} ,
		{"word":"dératisé","word_nosc":"deratise","lemma":"dératiser","pos":"VER"} ,
		{"word":"dératisée","word_nosc":"deratisee","lemma":"dératiser","pos":"VER"} ,
		{"word":"dératisés","word_nosc":"deratises","lemma":"dératiser","pos":"VER"} ,
		{"word":"dérayer","word_nosc":"derayer","lemma":"dérayer","pos":"VER"} ,
		{"word":"dérida","word_nosc":"derida","lemma":"dérider","pos":"VER"} ,
		{"word":"déridait","word_nosc":"deridait","lemma":"dérider","pos":"VER"} ,
		{"word":"déride","word_nosc":"deride","lemma":"dérider","pos":"VER"} ,
		{"word":"dérider","word_nosc":"derider","lemma":"dérider","pos":"VER"} ,
		{"word":"déridera","word_nosc":"deridera","lemma":"dérider","pos":"VER"} ,
		{"word":"dériderait","word_nosc":"deriderait","lemma":"dérider","pos":"VER"} ,
		{"word":"déridez","word_nosc":"deridez","lemma":"dérider","pos":"VER"} ,
		{"word":"déridé","word_nosc":"deride","lemma":"dérider","pos":"VER"} ,
		{"word":"dériva","word_nosc":"deriva","lemma":"dériver","pos":"VER"} ,
		{"word":"dérivaient","word_nosc":"derivaient","lemma":"dériver","pos":"VER"} ,
		{"word":"dérivais","word_nosc":"derivais","lemma":"dériver","pos":"VER"} ,
		{"word":"dérivait","word_nosc":"derivait","lemma":"dériver","pos":"VER"} ,
		{"word":"dérivant","word_nosc":"derivant","lemma":"dériver","pos":"VER"} ,
		{"word":"dérive","word_nosc":"derive","lemma":"dériver","pos":"VER"} ,
		{"word":"dérivent","word_nosc":"derivent","lemma":"dériver","pos":"VER"} ,
		{"word":"dériver","word_nosc":"deriver","lemma":"dériver","pos":"VER"} ,
		{"word":"dériverai","word_nosc":"deriverai","lemma":"dériver","pos":"VER"} ,
		{"word":"dériveras","word_nosc":"deriveras","lemma":"dériver","pos":"VER"} ,
		{"word":"dérives","word_nosc":"derives","lemma":"dériver","pos":"VER"} ,
		{"word":"dérivez","word_nosc":"derivez","lemma":"dériver","pos":"VER"} ,
		{"word":"dérivions","word_nosc":"derivions","lemma":"dériver","pos":"VER"} ,
		{"word":"dérivons","word_nosc":"derivons","lemma":"dériver","pos":"VER"} ,
		{"word":"dérivé","word_nosc":"derive","lemma":"dériver","pos":"VER"} ,
		{"word":"dérivée","word_nosc":"derivee","lemma":"dériver","pos":"VER"} ,
		{"word":"dérivées","word_nosc":"derivees","lemma":"dériver","pos":"VER"} ,
		{"word":"dérivés","word_nosc":"derives","lemma":"dériver","pos":"VER"} ,
		{"word":"déroba","word_nosc":"deroba","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobai","word_nosc":"derobai","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobaient","word_nosc":"derobaient","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobais","word_nosc":"derobais","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobait","word_nosc":"derobait","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobant","word_nosc":"derobant","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobe","word_nosc":"derobe","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobent","word_nosc":"derobent","lemma":"dérober","pos":"VER"} ,
		{"word":"dérober","word_nosc":"derober","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobera","word_nosc":"derobera","lemma":"dérober","pos":"VER"} ,
		{"word":"déroberaient","word_nosc":"deroberaient","lemma":"dérober","pos":"VER"} ,
		{"word":"déroberait","word_nosc":"deroberait","lemma":"dérober","pos":"VER"} ,
		{"word":"déroberons","word_nosc":"deroberons","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobes","word_nosc":"derobes","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobez","word_nosc":"derobez","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobiez","word_nosc":"derobiez","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobions","word_nosc":"derobions","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobons","word_nosc":"derobons","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobât","word_nosc":"derobat","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobèrent","word_nosc":"deroberent","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobé","word_nosc":"derobe","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobée","word_nosc":"derobee","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobées","word_nosc":"derobees","lemma":"dérober","pos":"VER"} ,
		{"word":"dérobés","word_nosc":"derobes","lemma":"dérober","pos":"VER"} ,
		{"word":"déroge","word_nosc":"deroge","lemma":"déroger","pos":"VER"} ,
		{"word":"dérogea","word_nosc":"derogea","lemma":"déroger","pos":"VER"} ,
		{"word":"dérogeait","word_nosc":"derogeait","lemma":"déroger","pos":"VER"} ,
		{"word":"dérogent","word_nosc":"derogent","lemma":"déroger","pos":"VER"} ,
		{"word":"déroger","word_nosc":"deroger","lemma":"déroger","pos":"VER"} ,
		{"word":"dérogera","word_nosc":"derogera","lemma":"déroger","pos":"VER"} ,
		{"word":"dérogerait","word_nosc":"derogerait","lemma":"déroger","pos":"VER"} ,
		{"word":"dérogé","word_nosc":"deroge","lemma":"déroger","pos":"VER"} ,
		{"word":"dérouillaient","word_nosc":"derouillaient","lemma":"dérouiller","pos":"VER"} ,
		{"word":"dérouillais","word_nosc":"derouillais","lemma":"dérouiller","pos":"VER"} ,
		{"word":"dérouillait","word_nosc":"derouillait","lemma":"dérouiller","pos":"VER"} ,
		{"word":"dérouillant","word_nosc":"derouillant","lemma":"dérouiller","pos":"VER"} ,
		{"word":"dérouille","word_nosc":"derouille","lemma":"dérouiller","pos":"VER"} ,
		{"word":"dérouillent","word_nosc":"derouillent","lemma":"dérouiller","pos":"VER"} ,
		{"word":"dérouiller","word_nosc":"derouiller","lemma":"dérouiller","pos":"VER"} ,
		{"word":"dérouillera","word_nosc":"derouillera","lemma":"dérouiller","pos":"VER"} ,
		{"word":"dérouillerai","word_nosc":"derouillerai","lemma":"dérouiller","pos":"VER"} ,
		{"word":"dérouillerais","word_nosc":"derouillerais","lemma":"dérouiller","pos":"VER"} ,
		{"word":"dérouillerait","word_nosc":"derouillerait","lemma":"dérouiller","pos":"VER"} ,
		{"word":"dérouilles","word_nosc":"derouilles","lemma":"dérouiller","pos":"VER"} ,
		{"word":"dérouillez","word_nosc":"derouillez","lemma":"dérouiller","pos":"VER"} ,
		{"word":"dérouillé","word_nosc":"derouille","lemma":"dérouiller","pos":"VER"} ,
		{"word":"dérouillée","word_nosc":"derouillee","lemma":"dérouiller","pos":"VER"} ,
		{"word":"déroula","word_nosc":"deroula","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulai","word_nosc":"deroulai","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulaient","word_nosc":"deroulaient","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulais","word_nosc":"deroulais","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulait","word_nosc":"deroulait","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulant","word_nosc":"deroulant","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroule","word_nosc":"deroule","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulent","word_nosc":"deroulent","lemma":"dérouler","pos":"VER"} ,
		{"word":"dérouler","word_nosc":"derouler","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulera","word_nosc":"deroulera","lemma":"dérouler","pos":"VER"} ,
		{"word":"dérouleraient","word_nosc":"derouleraient","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulerait","word_nosc":"deroulerait","lemma":"dérouler","pos":"VER"} ,
		{"word":"dérouleront","word_nosc":"derouleront","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulez","word_nosc":"deroulez","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulions","word_nosc":"deroulions","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulât","word_nosc":"deroulat","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulèrent","word_nosc":"deroulerent","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulé","word_nosc":"deroule","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulée","word_nosc":"deroulee","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulées","word_nosc":"deroulees","lemma":"dérouler","pos":"VER"} ,
		{"word":"déroulés","word_nosc":"deroules","lemma":"dérouler","pos":"VER"} ,
		{"word":"dérouta","word_nosc":"derouta","lemma":"dérouter","pos":"VER"} ,
		{"word":"déroutaient","word_nosc":"deroutaient","lemma":"dérouter","pos":"VER"} ,
		{"word":"déroutait","word_nosc":"deroutait","lemma":"dérouter","pos":"VER"} ,
		{"word":"déroutant","word_nosc":"deroutant","lemma":"dérouter","pos":"VER"} ,
		{"word":"déroute","word_nosc":"deroute","lemma":"dérouter","pos":"VER"} ,
		{"word":"déroutent","word_nosc":"deroutent","lemma":"dérouter","pos":"VER"} ,
		{"word":"dérouter","word_nosc":"derouter","lemma":"dérouter","pos":"VER"} ,
		{"word":"déroutera","word_nosc":"deroutera","lemma":"dérouter","pos":"VER"} ,
		{"word":"déroutes","word_nosc":"deroutes","lemma":"dérouter","pos":"VER"} ,
		{"word":"déroutez","word_nosc":"deroutez","lemma":"dérouter","pos":"VER"} ,
		{"word":"déroutèrent","word_nosc":"derouterent","lemma":"dérouter","pos":"VER"} ,
		{"word":"dérouté","word_nosc":"deroute","lemma":"dérouter","pos":"VER"} ,
		{"word":"déroutée","word_nosc":"deroutee","lemma":"dérouter","pos":"VER"} ,
		{"word":"déroutées","word_nosc":"deroutees","lemma":"dérouter","pos":"VER"} ,
		{"word":"déroutés","word_nosc":"deroutes","lemma":"dérouter","pos":"VER"} ,
		{"word":"dérègle","word_nosc":"deregle","lemma":"dérégler","pos":"VER"} ,
		{"word":"dérèglent","word_nosc":"dereglent","lemma":"dérégler","pos":"VER"} ,
		{"word":"déréalisaient","word_nosc":"derealisaient","lemma":"déréaliser","pos":"VER"} ,
		{"word":"déréglait","word_nosc":"dereglait","lemma":"dérégler","pos":"VER"} ,
		{"word":"déréglant","word_nosc":"dereglant","lemma":"dérégler","pos":"VER"} ,
		{"word":"dérégler","word_nosc":"deregler","lemma":"dérégler","pos":"VER"} ,
		{"word":"déréglé","word_nosc":"deregle","lemma":"dérégler","pos":"VER"} ,
		{"word":"déréglée","word_nosc":"dereglee","lemma":"dérégler","pos":"VER"} ,
		{"word":"déréglées","word_nosc":"dereglees","lemma":"dérégler","pos":"VER"} ,
		{"word":"déréglés","word_nosc":"deregles","lemma":"dérégler","pos":"VER"} ,
		{"word":"déréguler","word_nosc":"dereguler","lemma":"déréguler","pos":"VER"} ,
		{"word":"désabonna","word_nosc":"desabonna","lemma":"désabonner","pos":"VER"} ,
		{"word":"désabuser","word_nosc":"desabuser","lemma":"désabuser","pos":"VER"} ,
		{"word":"désabusé","word_nosc":"desabuse","lemma":"désabuser","pos":"VER"} ,
		{"word":"désabusée","word_nosc":"desabusee","lemma":"désabuser","pos":"VER"} ,
		{"word":"désabusées","word_nosc":"desabusees","lemma":"désabuser","pos":"VER"} ,
		{"word":"désabusés","word_nosc":"desabuses","lemma":"désabuser","pos":"VER"} ,
		{"word":"désaccorda","word_nosc":"desaccorda","lemma":"désaccorder","pos":"VER"} ,
		{"word":"désaccordaient","word_nosc":"desaccordaient","lemma":"désaccorder","pos":"VER"} ,
		{"word":"désaccordent","word_nosc":"desaccordent","lemma":"désaccorder","pos":"VER"} ,
		{"word":"désaccorder","word_nosc":"desaccorder","lemma":"désaccorder","pos":"VER"} ,
		{"word":"désaccordé","word_nosc":"desaccorde","lemma":"désaccorder","pos":"VER"} ,
		{"word":"désaccordée","word_nosc":"desaccordee","lemma":"désaccorder","pos":"VER"} ,
		{"word":"désaccordées","word_nosc":"desaccordees","lemma":"désaccorder","pos":"VER"} ,
		{"word":"désaccordés","word_nosc":"desaccordes","lemma":"désaccorder","pos":"VER"} ,
		{"word":"désaccoupler","word_nosc":"desaccoupler","lemma":"désaccoupler","pos":"VER"} ,
		{"word":"désaccoutuma","word_nosc":"desaccoutuma","lemma":"désaccoutumer","pos":"VER"} ,
		{"word":"désaccoutumer","word_nosc":"desaccoutumer","lemma":"désaccoutumer","pos":"VER"} ,
		{"word":"désacraliser","word_nosc":"desacraliser","lemma":"désacraliser","pos":"VER"} ,
		{"word":"désacralisé","word_nosc":"desacralise","lemma":"désacraliser","pos":"VER"} ,
		{"word":"désacralisée","word_nosc":"desacralisee","lemma":"désacraliser","pos":"VER"} ,
		{"word":"désactive","word_nosc":"desactive","lemma":"désactiver","pos":"VER"} ,
		{"word":"désactiver","word_nosc":"desactiver","lemma":"désactiver","pos":"VER"} ,
		{"word":"désactiverai","word_nosc":"desactiverai","lemma":"désactiver","pos":"VER"} ,
		{"word":"désactiveront","word_nosc":"desactiveront","lemma":"désactiver","pos":"VER"} ,
		{"word":"désactives","word_nosc":"desactives","lemma":"désactiver","pos":"VER"} ,
		{"word":"désactivez","word_nosc":"desactivez","lemma":"désactiver","pos":"VER"} ,
		{"word":"désactivons","word_nosc":"desactivons","lemma":"désactiver","pos":"VER"} ,
		{"word":"désactivé","word_nosc":"desactive","lemma":"désactiver","pos":"VER"} ,
		{"word":"désactivée","word_nosc":"desactivee","lemma":"désactiver","pos":"VER"} ,
		{"word":"désactivées","word_nosc":"desactivees","lemma":"désactiver","pos":"VER"} ,
		{"word":"désactivés","word_nosc":"desactives","lemma":"désactiver","pos":"VER"} ,
		{"word":"désadaptée","word_nosc":"desadaptee","lemma":"désadapter","pos":"VER"} ,
		{"word":"désadopter","word_nosc":"desadopter","lemma":"désadopter","pos":"VER"} ,
		{"word":"désaffecter","word_nosc":"desaffecter","lemma":"désaffecter","pos":"VER"} ,
		{"word":"désaffecté","word_nosc":"desaffecte","lemma":"désaffecter","pos":"VER"} ,
		{"word":"désaffectée","word_nosc":"desaffectee","lemma":"désaffecter","pos":"VER"} ,
		{"word":"désaffectées","word_nosc":"desaffectees","lemma":"désaffecter","pos":"VER"} ,
		{"word":"désaffectés","word_nosc":"desaffectes","lemma":"désaffecter","pos":"VER"} ,
		{"word":"désagrège","word_nosc":"desagrege","lemma":"désagréger","pos":"VER"} ,
		{"word":"désagrègent","word_nosc":"desagregent","lemma":"désagréger","pos":"VER"} ,
		{"word":"désagrégeait","word_nosc":"desagregeait","lemma":"désagréger","pos":"VER"} ,
		{"word":"désagrégeant","word_nosc":"desagregeant","lemma":"désagréger","pos":"VER"} ,
		{"word":"désagréger","word_nosc":"desagreger","lemma":"désagréger","pos":"VER"} ,
		{"word":"désagrégèrent","word_nosc":"desagregerent","lemma":"désagréger","pos":"VER"} ,
		{"word":"désagrégé","word_nosc":"desagrege","lemma":"désagréger","pos":"VER"} ,
		{"word":"désagrégée","word_nosc":"desagregee","lemma":"désagréger","pos":"VER"} ,
		{"word":"désagrégés","word_nosc":"desagreges","lemma":"désagréger","pos":"VER"} ,
		{"word":"désaimée","word_nosc":"desaimee","lemma":"désaimer","pos":"VER"} ,
		{"word":"désajustés","word_nosc":"desajustes","lemma":"désajuster","pos":"VER"} ,
		{"word":"désaltère","word_nosc":"desaltere","lemma":"désaltérer","pos":"VER"} ,
		{"word":"désaltéra","word_nosc":"desaltera","lemma":"désaltérer","pos":"VER"} ,
		{"word":"désaltéraient","word_nosc":"desalteraient","lemma":"désaltérer","pos":"VER"} ,
		{"word":"désaltérait","word_nosc":"desalterait","lemma":"désaltérer","pos":"VER"} ,
		{"word":"désaltérant","word_nosc":"desalterant","lemma":"désaltérer","pos":"VER"} ,
		{"word":"désaltérer","word_nosc":"desalterer","lemma":"désaltérer","pos":"VER"} ,
		{"word":"désaltérât","word_nosc":"desalterat","lemma":"désaltérer","pos":"VER"} ,
		{"word":"désaltéré","word_nosc":"desaltere","lemma":"désaltérer","pos":"VER"} ,
		{"word":"désaltérés","word_nosc":"desalteres","lemma":"désaltérer","pos":"VER"} ,
		{"word":"désamorce","word_nosc":"desamorce","lemma":"désamorcer","pos":"VER"} ,
		{"word":"désamorcent","word_nosc":"desamorcent","lemma":"désamorcer","pos":"VER"} ,
		{"word":"désamorcer","word_nosc":"desamorcer","lemma":"désamorcer","pos":"VER"} ,
		{"word":"désamorcera","word_nosc":"desamorcera","lemma":"désamorcer","pos":"VER"} ,
		{"word":"désamorcerez","word_nosc":"desamorcerez","lemma":"désamorcer","pos":"VER"} ,
		{"word":"désamorcerons","word_nosc":"desamorcerons","lemma":"désamorcer","pos":"VER"} ,
		{"word":"désamorcez","word_nosc":"desamorcez","lemma":"désamorcer","pos":"VER"} ,
		{"word":"désamorcé","word_nosc":"desamorce","lemma":"désamorcer","pos":"VER"} ,
		{"word":"désamorcée","word_nosc":"desamorcee","lemma":"désamorcer","pos":"VER"} ,
		{"word":"désamorcées","word_nosc":"desamorcees","lemma":"désamorcer","pos":"VER"} ,
		{"word":"désamorcés","word_nosc":"desamorces","lemma":"désamorcer","pos":"VER"} ,
		{"word":"désamorçait","word_nosc":"desamorcait","lemma":"désamorcer","pos":"VER"} ,
		{"word":"désamorçant","word_nosc":"desamorcant","lemma":"désamorcer","pos":"VER"} ,
		{"word":"désangler","word_nosc":"desangler","lemma":"désangler","pos":"VER"} ,
		{"word":"désape","word_nosc":"desape","lemma":"désaper","pos":"VER"} ,
		{"word":"désapent","word_nosc":"desapent","lemma":"désaper","pos":"VER"} ,
		{"word":"désaper","word_nosc":"desaper","lemma":"désaper","pos":"VER"} ,
		{"word":"désapeurer","word_nosc":"desapeurer","lemma":"désapeurer","pos":"VER"} ,
		{"word":"désapez","word_nosc":"desapez","lemma":"désaper","pos":"VER"} ,
		{"word":"désappointait","word_nosc":"desappointait","lemma":"désappointer","pos":"VER"} ,
		{"word":"désappointer","word_nosc":"desappointer","lemma":"désappointer","pos":"VER"} ,
		{"word":"désappointé","word_nosc":"desappointe","lemma":"désappointer","pos":"VER"} ,
		{"word":"désappointée","word_nosc":"desappointee","lemma":"désappointer","pos":"VER"} ,
		{"word":"désappointés","word_nosc":"desappointes","lemma":"désappointer","pos":"VER"} ,
		{"word":"désapprenais","word_nosc":"desapprenais","lemma":"désapprendre","pos":"VER"} ,
		{"word":"désapprenant","word_nosc":"desapprenant","lemma":"désapprendre","pos":"VER"} ,
		{"word":"désapprendre","word_nosc":"desapprendre","lemma":"désapprendre","pos":"VER"} ,
		{"word":"désapprenne","word_nosc":"desapprenne","lemma":"désapprendre","pos":"VER"} ,
		{"word":"désapprirent","word_nosc":"desapprirent","lemma":"désapprendre","pos":"VER"} ,
		{"word":"désappris","word_nosc":"desappris","lemma":"désapprendre","pos":"VER"} ,
		{"word":"désapprit","word_nosc":"desapprit","lemma":"désapprendre","pos":"VER"} ,
		{"word":"désapprouva","word_nosc":"desapprouva","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouvaient","word_nosc":"desapprouvaient","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouvais","word_nosc":"desapprouvais","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouvait","word_nosc":"desapprouvait","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouvant","word_nosc":"desapprouvant","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouve","word_nosc":"desapprouve","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouvent","word_nosc":"desapprouvent","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouver","word_nosc":"desapprouver","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouvera","word_nosc":"desapprouvera","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouverait","word_nosc":"desapprouverait","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouverez","word_nosc":"desapprouverez","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouves","word_nosc":"desapprouves","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouvez","word_nosc":"desapprouvez","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouviez","word_nosc":"desapprouviez","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouvât","word_nosc":"desapprouvat","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouvé","word_nosc":"desapprouve","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désapprouvée","word_nosc":"desapprouvee","lemma":"désapprouver","pos":"VER"} ,
		{"word":"désargenté","word_nosc":"desargente","lemma":"désargenter","pos":"VER"} ,
		{"word":"désargentés","word_nosc":"desargentes","lemma":"désargenter","pos":"VER"} ,
		{"word":"désarma","word_nosc":"desarma","lemma":"désarmer","pos":"VER"} ,
		{"word":"désarmai","word_nosc":"desarmai","lemma":"désarmer","pos":"VER"} ,
		{"word":"désarmaient","word_nosc":"desarmaient","lemma":"désarmer","pos":"VER"} ,
		{"word":"désarmait","word_nosc":"desarmait","lemma":"désarmer","pos":"VER"} ,
		{"word":"désarmant","word_nosc":"desarmant","lemma":"désarmer","pos":"VER"} ,
		{"word":"désarme","word_nosc":"desarme","lemma":"désarmer","pos":"VER"} ,
		{"word":"désarment","word_nosc":"desarment","lemma":"désarmer","pos":"VER"} ,
		{"word":"désarmer","word_nosc":"desarmer","lemma":"désarmer","pos":"VER"} ,
		{"word":"désarmera","word_nosc":"desarmera","lemma":"désarmer","pos":"VER"} ,
		{"word":"désarmez","word_nosc":"desarmez","lemma":"désarmer","pos":"VER"} ,
		{"word":"désarmèrent","word_nosc":"desarmerent","lemma":"désarmer","pos":"VER"} ,
		{"word":"désarmé","word_nosc":"desarme","lemma":"désarmer","pos":"VER"} ,
		{"word":"désarmée","word_nosc":"desarmee","lemma":"désarmer","pos":"VER"} ,
		{"word":"désarmées","word_nosc":"desarmees","lemma":"désarmer","pos":"VER"} ,
		{"word":"désarmés","word_nosc":"desarmes","lemma":"désarmer","pos":"VER"} ,
		{"word":"désarrimez","word_nosc":"desarrimez","lemma":"désarrimer","pos":"VER"} ,
		{"word":"désarrimé","word_nosc":"desarrime","lemma":"désarrimer","pos":"VER"} ,
		{"word":"désarticula","word_nosc":"desarticula","lemma":"désarticuler","pos":"VER"} ,
		{"word":"désarticulait","word_nosc":"desarticulait","lemma":"désarticuler","pos":"VER"} ,
		{"word":"désarticulant","word_nosc":"desarticulant","lemma":"désarticuler","pos":"VER"} ,
		{"word":"désarticulent","word_nosc":"desarticulent","lemma":"désarticuler","pos":"VER"} ,
		{"word":"désarticuler","word_nosc":"desarticuler","lemma":"désarticuler","pos":"VER"} ,
		{"word":"désarticulera","word_nosc":"desarticulera","lemma":"désarticuler","pos":"VER"} ,
		{"word":"désarticulé","word_nosc":"desarticule","lemma":"désarticuler","pos":"VER"} ,
		{"word":"désarticulée","word_nosc":"desarticulee","lemma":"désarticuler","pos":"VER"} ,
		{"word":"désarticulées","word_nosc":"desarticulees","lemma":"désarticuler","pos":"VER"} ,
		{"word":"désarticulés","word_nosc":"desarticules","lemma":"désarticuler","pos":"VER"} ,
		{"word":"désarçonna","word_nosc":"desarconna","lemma":"désarçonner","pos":"VER"} ,
		{"word":"désarçonnaient","word_nosc":"desarconnaient","lemma":"désarçonner","pos":"VER"} ,
		{"word":"désarçonnait","word_nosc":"desarconnait","lemma":"désarçonner","pos":"VER"} ,
		{"word":"désarçonne","word_nosc":"desarconne","lemma":"désarçonner","pos":"VER"} ,
		{"word":"désarçonner","word_nosc":"desarconner","lemma":"désarçonner","pos":"VER"} ,
		{"word":"désarçonné","word_nosc":"desarconne","lemma":"désarçonner","pos":"VER"} ,
		{"word":"désarçonnée","word_nosc":"desarconnee","lemma":"désarçonner","pos":"VER"} ,
		{"word":"désarçonnés","word_nosc":"desarconnes","lemma":"désarçonner","pos":"VER"} ,
		{"word":"désassemble","word_nosc":"desassemble","lemma":"désassembler","pos":"VER"} ,
		{"word":"désassembler","word_nosc":"desassembler","lemma":"désassembler","pos":"VER"} ,
		{"word":"désassemblé","word_nosc":"desassemble","lemma":"désassembler","pos":"VER"} ,
		{"word":"désassortis","word_nosc":"desassortis","lemma":"désassortir","pos":"VER"} ,
		{"word":"désavantage","word_nosc":"desavantage","lemma":"désavantager","pos":"VER"} ,
		{"word":"désavantageaient","word_nosc":"desavantageaient","lemma":"désavantager","pos":"VER"} ,
		{"word":"désavantageait","word_nosc":"desavantageait","lemma":"désavantager","pos":"VER"} ,
		{"word":"désavantager","word_nosc":"desavantager","lemma":"désavantager","pos":"VER"} ,
		{"word":"désavantagé","word_nosc":"desavantage","lemma":"désavantager","pos":"VER"} ,
		{"word":"désavantagés","word_nosc":"desavantages","lemma":"désavantager","pos":"VER"} ,
		{"word":"désavoua","word_nosc":"desavoua","lemma":"désavouer","pos":"VER"} ,
		{"word":"désavouait","word_nosc":"desavouait","lemma":"désavouer","pos":"VER"} ,
		{"word":"désavouant","word_nosc":"desavouant","lemma":"désavouer","pos":"VER"} ,
		{"word":"désavoue","word_nosc":"desavoue","lemma":"désavouer","pos":"VER"} ,
		{"word":"désavouent","word_nosc":"desavouent","lemma":"désavouer","pos":"VER"} ,
		{"word":"désavouer","word_nosc":"desavouer","lemma":"désavouer","pos":"VER"} ,
		{"word":"désavoueraient","word_nosc":"desavoueraient","lemma":"désavouer","pos":"VER"} ,
		{"word":"désavouerons","word_nosc":"desavouerons","lemma":"désavouer","pos":"VER"} ,
		{"word":"désavouez","word_nosc":"desavouez","lemma":"désavouer","pos":"VER"} ,
		{"word":"désavouât","word_nosc":"desavouat","lemma":"désavouer","pos":"VER"} ,
		{"word":"désavoué","word_nosc":"desavoue","lemma":"désavouer","pos":"VER"} ,
		{"word":"désavouée","word_nosc":"desavouee","lemma":"désavouer","pos":"VER"} ,
		{"word":"désavoués","word_nosc":"desavoues","lemma":"désavouer","pos":"VER"} ,
		{"word":"désaxait","word_nosc":"desaxait","lemma":"désaxer","pos":"VER"} ,
		{"word":"désaxant","word_nosc":"desaxant","lemma":"désaxer","pos":"VER"} ,
		{"word":"désaxe","word_nosc":"desaxe","lemma":"désaxer","pos":"VER"} ,
		{"word":"désaxé","word_nosc":"desaxe","lemma":"désaxer","pos":"VER"} ,
		{"word":"désaxée","word_nosc":"desaxee","lemma":"désaxer","pos":"VER"} ,
		{"word":"désaxés","word_nosc":"desaxes","lemma":"désaxer","pos":"VER"} ,
		{"word":"déscolarisé","word_nosc":"descolarise","lemma":"déscolariser","pos":"VER"} ,
		{"word":"désembourber","word_nosc":"desembourber","lemma":"désembourber","pos":"VER"} ,
		{"word":"désembourbée","word_nosc":"desembourbee","lemma":"désembourber","pos":"VER"} ,
		{"word":"désembrouiller","word_nosc":"desembrouiller","lemma":"désembrouiller","pos":"VER"} ,
		{"word":"désembuer","word_nosc":"desembuer","lemma":"désembuer","pos":"VER"} ,
		{"word":"désemparait","word_nosc":"desemparait","lemma":"désemparer","pos":"VER"} ,
		{"word":"désemparer","word_nosc":"desemparer","lemma":"désemparer","pos":"VER"} ,
		{"word":"désemparé","word_nosc":"desempare","lemma":"désemparer","pos":"VER"} ,
		{"word":"désemparée","word_nosc":"desemparee","lemma":"désemparer","pos":"VER"} ,
		{"word":"désemparés","word_nosc":"desempares","lemma":"désemparer","pos":"VER"} ,
		{"word":"désempenné","word_nosc":"desempenne","lemma":"désempenner","pos":"VER"} ,
		{"word":"désempierraient","word_nosc":"desempierraient","lemma":"désempierrer","pos":"VER"} ,
		{"word":"désemplissaient","word_nosc":"desemplissaient","lemma":"désemplir","pos":"VER"} ,
		{"word":"désemplissait","word_nosc":"desemplissait","lemma":"désemplir","pos":"VER"} ,
		{"word":"désemplit","word_nosc":"desemplit","lemma":"désemplir","pos":"VER"} ,
		{"word":"désenchanta","word_nosc":"desenchanta","lemma":"désenchanter","pos":"VER"} ,
		{"word":"désenchantant","word_nosc":"desenchantant","lemma":"désenchanter","pos":"VER"} ,
		{"word":"désenchante","word_nosc":"desenchante","lemma":"désenchanter","pos":"VER"} ,
		{"word":"désenchantent","word_nosc":"desenchantent","lemma":"désenchanter","pos":"VER"} ,
		{"word":"désenchanter","word_nosc":"desenchanter","lemma":"désenchanter","pos":"VER"} ,
		{"word":"désenchantée","word_nosc":"desenchantee","lemma":"désenchanter","pos":"VER"} ,
		{"word":"désenchantées","word_nosc":"desenchantees","lemma":"désenchanter","pos":"VER"} ,
		{"word":"désenchantés","word_nosc":"desenchantes","lemma":"désenchanter","pos":"VER"} ,
		{"word":"désenchaîner","word_nosc":"desenchainer","lemma":"désenchaîner","pos":"VER"} ,
		{"word":"désenchevêtra","word_nosc":"desenchevetra","lemma":"désenchevêtrer","pos":"VER"} ,
		{"word":"désencombre","word_nosc":"desencombre","lemma":"désencombrer","pos":"VER"} ,
		{"word":"désencrasser","word_nosc":"desencrasser","lemma":"désencrasser","pos":"VER"} ,
		{"word":"désenflaient","word_nosc":"desenflaient","lemma":"désenfler","pos":"VER"} ,
		{"word":"désenfle","word_nosc":"desenfle","lemma":"désenfler","pos":"VER"} ,
		{"word":"désenfler","word_nosc":"desenfler","lemma":"désenfler","pos":"VER"} ,
		{"word":"désenflé","word_nosc":"desenfle","lemma":"désenfler","pos":"VER"} ,
		{"word":"désengager","word_nosc":"desengager","lemma":"désengager","pos":"VER"} ,
		{"word":"désengagez","word_nosc":"desengagez","lemma":"désengager","pos":"VER"} ,
		{"word":"désengagé","word_nosc":"desengage","lemma":"désengager","pos":"VER"} ,
		{"word":"désengluer","word_nosc":"desengluer","lemma":"désengluer","pos":"VER"} ,
		{"word":"désengourdir","word_nosc":"desengourdir","lemma":"désengourdir","pos":"VER"} ,
		{"word":"désennuie","word_nosc":"desennuie","lemma":"désennuyer","pos":"VER"} ,
		{"word":"désennuiera","word_nosc":"desennuiera","lemma":"désennuyer","pos":"VER"} ,
		{"word":"désennuyant","word_nosc":"desennuyant","lemma":"désennuyer","pos":"VER"} ,
		{"word":"désennuyer","word_nosc":"desennuyer","lemma":"désennuyer","pos":"VER"} ,
		{"word":"désenrouler","word_nosc":"desenrouler","lemma":"désenrouler","pos":"VER"} ,
		{"word":"désensabler","word_nosc":"desensabler","lemma":"désensabler","pos":"VER"} ,
		{"word":"désensibilisent","word_nosc":"desensibilisent","lemma":"désensibiliser","pos":"VER"} ,
		{"word":"désensibilisée","word_nosc":"desensibilisee","lemma":"désensibiliser","pos":"VER"} ,
		{"word":"désensorceler","word_nosc":"desensorceler","lemma":"désensorceler","pos":"VER"} ,
		{"word":"désensorcelé","word_nosc":"desensorcele","lemma":"désensorceler","pos":"VER"} ,
		{"word":"désentortille","word_nosc":"desentortille","lemma":"désentortiller","pos":"VER"} ,
		{"word":"désentravé","word_nosc":"desentrave","lemma":"désentraver","pos":"VER"} ,
		{"word":"désentravée","word_nosc":"desentravee","lemma":"désentraver","pos":"VER"} ,
		{"word":"désenvoûter","word_nosc":"desenvouter","lemma":"désenvoûter","pos":"VER"} ,
		{"word":"désenvoûté","word_nosc":"desenvoute","lemma":"désenvoûter","pos":"VER"} ,
		{"word":"déserta","word_nosc":"deserta","lemma":"déserter","pos":"VER"} ,
		{"word":"désertaient","word_nosc":"desertaient","lemma":"déserter","pos":"VER"} ,
		{"word":"désertais","word_nosc":"desertais","lemma":"déserter","pos":"VER"} ,
		{"word":"désertait","word_nosc":"desertait","lemma":"déserter","pos":"VER"} ,
		{"word":"désertant","word_nosc":"desertant","lemma":"déserter","pos":"VER"} ,
		{"word":"déserte","word_nosc":"deserte","lemma":"déserter","pos":"VER"} ,
		{"word":"désertent","word_nosc":"desertent","lemma":"déserter","pos":"VER"} ,
		{"word":"déserter","word_nosc":"deserter","lemma":"déserter","pos":"VER"} ,
		{"word":"déserterait","word_nosc":"deserterait","lemma":"déserter","pos":"VER"} ,
		{"word":"désertes","word_nosc":"desertes","lemma":"déserter","pos":"VER"} ,
		{"word":"désertifie","word_nosc":"desertifie","lemma":"désertifier","pos":"VER"} ,
		{"word":"désertons","word_nosc":"desertons","lemma":"déserter","pos":"VER"} ,
		{"word":"désertâmes","word_nosc":"desertames","lemma":"déserter","pos":"VER"} ,
		{"word":"désertèrent","word_nosc":"deserterent","lemma":"déserter","pos":"VER"} ,
		{"word":"déserté","word_nosc":"deserte","lemma":"déserter","pos":"VER"} ,
		{"word":"désertée","word_nosc":"desertee","lemma":"déserter","pos":"VER"} ,
		{"word":"désertées","word_nosc":"desertees","lemma":"déserter","pos":"VER"} ,
		{"word":"désertés","word_nosc":"desertes","lemma":"déserter","pos":"VER"} ,
		{"word":"désespère","word_nosc":"desespere","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespèrent","word_nosc":"desesperent","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespères","word_nosc":"desesperes","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespéra","word_nosc":"desespera","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespérai","word_nosc":"desesperai","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespéraient","word_nosc":"desesperaient","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespérais","word_nosc":"desesperais","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespérait","word_nosc":"desesperait","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespérant","word_nosc":"desesperant","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespérer","word_nosc":"desesperer","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespérez","word_nosc":"desesperez","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespérions","word_nosc":"desesperions","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespérons","word_nosc":"desesperons","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespérât","word_nosc":"desesperat","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespérèrent","word_nosc":"desespererent","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespéré","word_nosc":"desespere","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespérée","word_nosc":"desesperee","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespérées","word_nosc":"desesperees","lemma":"désespérer","pos":"VER"} ,
		{"word":"désespérés","word_nosc":"desesperes","lemma":"désespérer","pos":"VER"} ,
		{"word":"déshabilla","word_nosc":"deshabilla","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillai","word_nosc":"deshabillai","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillaient","word_nosc":"deshabillaient","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillais","word_nosc":"deshabillais","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillait","word_nosc":"deshabillait","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillant","word_nosc":"deshabillant","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabille","word_nosc":"deshabille","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillent","word_nosc":"deshabillent","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabiller","word_nosc":"deshabiller","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillera","word_nosc":"deshabillera","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillerai","word_nosc":"deshabillerai","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillerait","word_nosc":"deshabillerait","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabilleras","word_nosc":"deshabilleras","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabilleriez","word_nosc":"deshabilleriez","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillerons","word_nosc":"deshabillerons","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabilles","word_nosc":"deshabilles","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillez","word_nosc":"deshabillez","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillons","word_nosc":"deshabillons","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillèrent","word_nosc":"deshabillerent","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillé","word_nosc":"deshabille","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillée","word_nosc":"deshabillee","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillées","word_nosc":"deshabillees","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabillés","word_nosc":"deshabilles","lemma":"déshabiller","pos":"VER"} ,
		{"word":"déshabitué","word_nosc":"deshabitue","lemma":"déshabituer","pos":"VER"} ,
		{"word":"déshabituées","word_nosc":"deshabituees","lemma":"déshabituer","pos":"VER"} ,
		{"word":"déshabitués","word_nosc":"deshabitues","lemma":"déshabituer","pos":"VER"} ,
		{"word":"déshabité","word_nosc":"deshabite","lemma":"déshabiter","pos":"VER"} ,
		{"word":"déshabités","word_nosc":"deshabites","lemma":"déshabiter","pos":"VER"} ,
		{"word":"désherbait","word_nosc":"desherbait","lemma":"désherber","pos":"VER"} ,
		{"word":"désherbe","word_nosc":"desherbe","lemma":"désherber","pos":"VER"} ,
		{"word":"désherber","word_nosc":"desherber","lemma":"désherber","pos":"VER"} ,
		{"word":"désherbé","word_nosc":"desherbe","lemma":"désherber","pos":"VER"} ,
		{"word":"désherbée","word_nosc":"desherbee","lemma":"désherber","pos":"VER"} ,
		{"word":"désheuraient","word_nosc":"desheuraient","lemma":"désheurer","pos":"VER"} ,
		{"word":"déshonoraient","word_nosc":"deshonoraient","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshonorait","word_nosc":"deshonorait","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshonorant","word_nosc":"deshonorant","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshonore","word_nosc":"deshonore","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshonorent","word_nosc":"deshonorent","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshonorer","word_nosc":"deshonorer","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshonorera","word_nosc":"deshonorera","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshonorerai","word_nosc":"deshonorerai","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshonorerais","word_nosc":"deshonorerais","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshonorerait","word_nosc":"deshonorerait","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshonores","word_nosc":"deshonores","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshonorez","word_nosc":"deshonorez","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshonoré","word_nosc":"deshonore","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshonorée","word_nosc":"deshonoree","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshonorées","word_nosc":"deshonorees","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshonorés","word_nosc":"deshonores","lemma":"déshonorer","pos":"VER"} ,
		{"word":"déshumanisant","word_nosc":"deshumanisant","lemma":"déshumaniser","pos":"VER"} ,
		{"word":"déshumanise","word_nosc":"deshumanise","lemma":"déshumaniser","pos":"VER"} ,
		{"word":"déshumanisent","word_nosc":"deshumanisent","lemma":"déshumaniser","pos":"VER"} ,
		{"word":"déshumaniser","word_nosc":"deshumaniser","lemma":"déshumaniser","pos":"VER"} ,
		{"word":"déshumanisé","word_nosc":"deshumanise","lemma":"déshumaniser","pos":"VER"} ,
		{"word":"déshumanisée","word_nosc":"deshumanisee","lemma":"déshumaniser","pos":"VER"} ,
		{"word":"déshumanisées","word_nosc":"deshumanisees","lemma":"déshumaniser","pos":"VER"} ,
		{"word":"déshydratais","word_nosc":"deshydratais","lemma":"déshydrater","pos":"VER"} ,
		{"word":"déshydratant","word_nosc":"deshydratant","lemma":"déshydrater","pos":"VER"} ,
		{"word":"déshydrate","word_nosc":"deshydrate","lemma":"déshydrater","pos":"VER"} ,
		{"word":"déshydrater","word_nosc":"deshydrater","lemma":"déshydrater","pos":"VER"} ,
		{"word":"déshydraté","word_nosc":"deshydrate","lemma":"déshydrater","pos":"VER"} ,
		{"word":"déshydratée","word_nosc":"deshydratee","lemma":"déshydrater","pos":"VER"} ,
		{"word":"déshydratées","word_nosc":"deshydratees","lemma":"déshydrater","pos":"VER"} ,
		{"word":"déshydratés","word_nosc":"deshydrates","lemma":"déshydrater","pos":"VER"} ,
		{"word":"déshérita","word_nosc":"desherita","lemma":"déshériter","pos":"VER"} ,
		{"word":"déshéritais","word_nosc":"desheritais","lemma":"déshériter","pos":"VER"} ,
		{"word":"déshérite","word_nosc":"desherite","lemma":"déshériter","pos":"VER"} ,
		{"word":"déshériter","word_nosc":"desheriter","lemma":"déshériter","pos":"VER"} ,
		{"word":"déshéritera","word_nosc":"desheritera","lemma":"déshériter","pos":"VER"} ,
		{"word":"déshériterai","word_nosc":"desheriterai","lemma":"déshériter","pos":"VER"} ,
		{"word":"déshériterais","word_nosc":"desheriterais","lemma":"déshériter","pos":"VER"} ,
		{"word":"déshériterait","word_nosc":"desheriterait","lemma":"déshériter","pos":"VER"} ,
		{"word":"déshérité","word_nosc":"desherite","lemma":"déshériter","pos":"VER"} ,
		{"word":"déshéritée","word_nosc":"desheritee","lemma":"déshériter","pos":"VER"} ,
		{"word":"déshérités","word_nosc":"desherites","lemma":"déshériter","pos":"VER"} ,
		{"word":"désigna","word_nosc":"designa","lemma":"désigner","pos":"VER"} ,
		{"word":"désignai","word_nosc":"designai","lemma":"désigner","pos":"VER"} ,
		{"word":"désignaient","word_nosc":"designaient","lemma":"désigner","pos":"VER"} ,
		{"word":"désignais","word_nosc":"designais","lemma":"désigner","pos":"VER"} ,
		{"word":"désignait","word_nosc":"designait","lemma":"désigner","pos":"VER"} ,
		{"word":"désignant","word_nosc":"designant","lemma":"désigner","pos":"VER"} ,
		{"word":"désignassent","word_nosc":"designassent","lemma":"désigner","pos":"VER"} ,
		{"word":"désigne","word_nosc":"designe","lemma":"désigner","pos":"VER"} ,
		{"word":"désignent","word_nosc":"designent","lemma":"désigner","pos":"VER"} ,
		{"word":"désigner","word_nosc":"designer","lemma":"désigner","pos":"VER"} ,
		{"word":"désignera","word_nosc":"designera","lemma":"désigner","pos":"VER"} ,
		{"word":"désignerai","word_nosc":"designerai","lemma":"désigner","pos":"VER"} ,
		{"word":"désignerait","word_nosc":"designerait","lemma":"désigner","pos":"VER"} ,
		{"word":"désigneras","word_nosc":"designeras","lemma":"désigner","pos":"VER"} ,
		{"word":"désignerez","word_nosc":"designerez","lemma":"désigner","pos":"VER"} ,
		{"word":"désigneriez","word_nosc":"designeriez","lemma":"désigner","pos":"VER"} ,
		{"word":"désignerons","word_nosc":"designerons","lemma":"désigner","pos":"VER"} ,
		{"word":"désigneront","word_nosc":"designeront","lemma":"désigner","pos":"VER"} ,
		{"word":"désignez","word_nosc":"designez","lemma":"désigner","pos":"VER"} ,
		{"word":"désignions","word_nosc":"designions","lemma":"désigner","pos":"VER"} ,
		{"word":"désignât","word_nosc":"designat","lemma":"désigner","pos":"VER"} ,
		{"word":"désignèrent","word_nosc":"designerent","lemma":"désigner","pos":"VER"} ,
		{"word":"désigné","word_nosc":"designe","lemma":"désigner","pos":"VER"} ,
		{"word":"désignée","word_nosc":"designee","lemma":"désigner","pos":"VER"} ,
		{"word":"désignées","word_nosc":"designees","lemma":"désigner","pos":"VER"} ,
		{"word":"désignés","word_nosc":"designes","lemma":"désigner","pos":"VER"} ,
		{"word":"désillusionner","word_nosc":"desillusionner","lemma":"désillusionner","pos":"VER"} ,
		{"word":"désillusionné","word_nosc":"desillusionne","lemma":"désillusionner","pos":"VER"} ,
		{"word":"désincarcéré","word_nosc":"desincarcere","lemma":"désincarcérer","pos":"VER"} ,
		{"word":"désincarnant","word_nosc":"desincarnant","lemma":"désincarner","pos":"VER"} ,
		{"word":"désincarne","word_nosc":"desincarne","lemma":"désincarner","pos":"VER"} ,
		{"word":"désincarner","word_nosc":"desincarner","lemma":"désincarner","pos":"VER"} ,
		{"word":"désincarné","word_nosc":"desincarne","lemma":"désincarner","pos":"VER"} ,
		{"word":"désincarnés","word_nosc":"desincarnes","lemma":"désincarner","pos":"VER"} ,
		{"word":"désincruster","word_nosc":"desincruster","lemma":"désincruster","pos":"VER"} ,
		{"word":"désinfecta","word_nosc":"desinfecta","lemma":"désinfecter","pos":"VER"} ,
		{"word":"désinfectant","word_nosc":"desinfectant","lemma":"désinfecter","pos":"VER"} ,
		{"word":"désinfecte","word_nosc":"desinfecte","lemma":"désinfecter","pos":"VER"} ,
		{"word":"désinfectent","word_nosc":"desinfectent","lemma":"désinfecter","pos":"VER"} ,
		{"word":"désinfecter","word_nosc":"desinfecter","lemma":"désinfecter","pos":"VER"} ,
		{"word":"désinfecterai","word_nosc":"desinfecterai","lemma":"désinfecter","pos":"VER"} ,
		{"word":"désinfectez","word_nosc":"desinfectez","lemma":"désinfecter","pos":"VER"} ,
		{"word":"désinfecté","word_nosc":"desinfecte","lemma":"désinfecter","pos":"VER"} ,
		{"word":"désinfectée","word_nosc":"desinfectee","lemma":"désinfecter","pos":"VER"} ,
		{"word":"désinfectées","word_nosc":"desinfectees","lemma":"désinfecter","pos":"VER"} ,
		{"word":"désinfectés","word_nosc":"desinfectes","lemma":"désinfecter","pos":"VER"} ,
		{"word":"désinformer","word_nosc":"desinformer","lemma":"désinformer","pos":"VER"} ,
		{"word":"désinformée","word_nosc":"desinformee","lemma":"désinformer","pos":"VER"} ,
		{"word":"désinhibe","word_nosc":"desinhibe","lemma":"désinhiber","pos":"VER"} ,
		{"word":"désinsectiser","word_nosc":"desinsectiser","lemma":"désinsectiser","pos":"VER"} ,
		{"word":"désinstaller","word_nosc":"desinstaller","lemma":"désinstaller","pos":"VER"} ,
		{"word":"désinstallé","word_nosc":"desinstalle","lemma":"désinstaller","pos":"VER"} ,
		{"word":"désintellectualiser","word_nosc":"desintellectualiser","lemma":"désintellectualiser","pos":"VER"} ,
		{"word":"désintoxique","word_nosc":"desintoxique","lemma":"désintoxiquer","pos":"VER"} ,
		{"word":"désintoxiquer","word_nosc":"desintoxiquer","lemma":"désintoxiquer","pos":"VER"} ,
		{"word":"désintoxiquera","word_nosc":"desintoxiquera","lemma":"désintoxiquer","pos":"VER"} ,
		{"word":"désintoxiquez","word_nosc":"desintoxiquez","lemma":"désintoxiquer","pos":"VER"} ,
		{"word":"désintoxiqué","word_nosc":"desintoxique","lemma":"désintoxiquer","pos":"VER"} ,
		{"word":"désintoxiquée","word_nosc":"desintoxiquee","lemma":"désintoxiquer","pos":"VER"} ,
		{"word":"désintoxiqués","word_nosc":"desintoxiques","lemma":"désintoxiquer","pos":"VER"} ,
		{"word":"désintègre","word_nosc":"desintegre","lemma":"désintégrer","pos":"VER"} ,
		{"word":"désintègrent","word_nosc":"desintegrent","lemma":"désintégrer","pos":"VER"} ,
		{"word":"désintégra","word_nosc":"desintegra","lemma":"désintégrer","pos":"VER"} ,
		{"word":"désintégraient","word_nosc":"desintegraient","lemma":"désintégrer","pos":"VER"} ,
		{"word":"désintégrait","word_nosc":"desintegrait","lemma":"désintégrer","pos":"VER"} ,
		{"word":"désintégrant","word_nosc":"desintegrant","lemma":"désintégrer","pos":"VER"} ,
		{"word":"désintégrer","word_nosc":"desintegrer","lemma":"désintégrer","pos":"VER"} ,
		{"word":"désintégrera","word_nosc":"desintegrera","lemma":"désintégrer","pos":"VER"} ,
		{"word":"désintégreront","word_nosc":"desintegreront","lemma":"désintégrer","pos":"VER"} ,
		{"word":"désintégré","word_nosc":"desintegre","lemma":"désintégrer","pos":"VER"} ,
		{"word":"désintégrée","word_nosc":"desintegree","lemma":"désintégrer","pos":"VER"} ,
		{"word":"désintégrés","word_nosc":"desintegres","lemma":"désintégrer","pos":"VER"} ,
		{"word":"désintéressa","word_nosc":"desinteressa","lemma":"désintéresser","pos":"VER"} ,
		{"word":"désintéressaient","word_nosc":"desinteressaient","lemma":"désintéresser","pos":"VER"} ,
		{"word":"désintéressait","word_nosc":"desinteressait","lemma":"désintéresser","pos":"VER"} ,
		{"word":"désintéressant","word_nosc":"desinteressant","lemma":"désintéresser","pos":"VER"} ,
		{"word":"désintéresse","word_nosc":"desinteresse","lemma":"désintéresser","pos":"VER"} ,
		{"word":"désintéressent","word_nosc":"desinteressent","lemma":"désintéresser","pos":"VER"} ,
		{"word":"désintéresser","word_nosc":"desinteresser","lemma":"désintéresser","pos":"VER"} ,
		{"word":"désintéresserait","word_nosc":"desinteresserait","lemma":"désintéresser","pos":"VER"} ,
		{"word":"désintéresseront","word_nosc":"desinteresseront","lemma":"désintéresser","pos":"VER"} ,
		{"word":"désintéressiez","word_nosc":"desinteressiez","lemma":"désintéresser","pos":"VER"} ,
		{"word":"désintéressé","word_nosc":"desinteresse","lemma":"désintéresser","pos":"VER"} ,
		{"word":"désintéressée","word_nosc":"desinteressee","lemma":"désintéresser","pos":"VER"} ,
		{"word":"désintéressées","word_nosc":"desinteressees","lemma":"désintéresser","pos":"VER"} ,
		{"word":"désintéressés","word_nosc":"desinteresses","lemma":"désintéresser","pos":"VER"} ,
		{"word":"désinvite","word_nosc":"desinvite","lemma":"désinviter","pos":"VER"} ,
		{"word":"désinviter","word_nosc":"desinviter","lemma":"désinviter","pos":"VER"} ,
		{"word":"désira","word_nosc":"desira","lemma":"désirer","pos":"VER"} ,
		{"word":"désiraient","word_nosc":"desiraient","lemma":"désirer","pos":"VER"} ,
		{"word":"désirais","word_nosc":"desirais","lemma":"désirer","pos":"VER"} ,
		{"word":"désirait","word_nosc":"desirait","lemma":"désirer","pos":"VER"} ,
		{"word":"désirant","word_nosc":"desirant","lemma":"désirer","pos":"VER"} ,
		{"word":"désire","word_nosc":"desire","lemma":"désirer","pos":"VER"} ,
		{"word":"désirent","word_nosc":"desirent","lemma":"désirer","pos":"VER"} ,
		{"word":"désirer","word_nosc":"desirer","lemma":"désirer","pos":"VER"} ,
		{"word":"désirera","word_nosc":"desirera","lemma":"désirer","pos":"VER"} ,
		{"word":"désireraient","word_nosc":"desireraient","lemma":"désirer","pos":"VER"} ,
		{"word":"désirerais","word_nosc":"desirerais","lemma":"désirer","pos":"VER"} ,
		{"word":"désirerait","word_nosc":"desirerait","lemma":"désirer","pos":"VER"} ,
		{"word":"désireras","word_nosc":"desireras","lemma":"désirer","pos":"VER"} ,
		{"word":"désireriez","word_nosc":"desireriez","lemma":"désirer","pos":"VER"} ,
		{"word":"désirerions","word_nosc":"desirerions","lemma":"désirer","pos":"VER"} ,
		{"word":"désireront","word_nosc":"desireront","lemma":"désirer","pos":"VER"} ,
		{"word":"désires","word_nosc":"desires","lemma":"désirer","pos":"VER"} ,
		{"word":"désirez","word_nosc":"desirez","lemma":"désirer","pos":"VER"} ,
		{"word":"désiriez","word_nosc":"desiriez","lemma":"désirer","pos":"VER"} ,
		{"word":"désirions","word_nosc":"desirions","lemma":"désirer","pos":"VER"} ,
		{"word":"désirons","word_nosc":"desirons","lemma":"désirer","pos":"VER"} ,
		{"word":"désirât","word_nosc":"desirat","lemma":"désirer","pos":"VER"} ,
		{"word":"désiré","word_nosc":"desire","lemma":"désirer","pos":"VER"} ,
		{"word":"désirée","word_nosc":"desiree","lemma":"désirer","pos":"VER"} ,
		{"word":"désirées","word_nosc":"desirees","lemma":"désirer","pos":"VER"} ,
		{"word":"désirés","word_nosc":"desires","lemma":"désirer","pos":"VER"} ,
		{"word":"désiste","word_nosc":"desiste","lemma":"désister","pos":"VER"} ,
		{"word":"désister","word_nosc":"desister","lemma":"désister","pos":"VER"} ,
		{"word":"désistez","word_nosc":"desistez","lemma":"désister","pos":"VER"} ,
		{"word":"désistèrent","word_nosc":"desisterent","lemma":"désister","pos":"VER"} ,
		{"word":"désisté","word_nosc":"desiste","lemma":"désister","pos":"VER"} ,
		{"word":"désistée","word_nosc":"desistee","lemma":"désister","pos":"VER"} ,
		{"word":"désistés","word_nosc":"desistes","lemma":"désister","pos":"VER"} ,
		{"word":"déslipe","word_nosc":"deslipe","lemma":"désliper","pos":"VER"} ,
		{"word":"désoblige","word_nosc":"desoblige","lemma":"désobliger","pos":"VER"} ,
		{"word":"désobligea","word_nosc":"desobligea","lemma":"désobliger","pos":"VER"} ,
		{"word":"désobligeait","word_nosc":"desobligeait","lemma":"désobliger","pos":"VER"} ,
		{"word":"désobligeant","word_nosc":"desobligeant","lemma":"désobliger","pos":"VER"} ,
		{"word":"désobligent","word_nosc":"desobligent","lemma":"désobliger","pos":"VER"} ,
		{"word":"désobliger","word_nosc":"desobliger","lemma":"désobliger","pos":"VER"} ,
		{"word":"désobligerait","word_nosc":"desobligerait","lemma":"désobliger","pos":"VER"} ,
		{"word":"désobligés","word_nosc":"desobliges","lemma":"désobliger","pos":"VER"} ,
		{"word":"désobéi","word_nosc":"desobei","lemma":"désobéir","pos":"VER"} ,
		{"word":"désobéir","word_nosc":"desobeir","lemma":"désobéir","pos":"VER"} ,
		{"word":"désobéira","word_nosc":"desobeira","lemma":"désobéir","pos":"VER"} ,
		{"word":"désobéirai","word_nosc":"desobeirai","lemma":"désobéir","pos":"VER"} ,
		{"word":"désobéirais","word_nosc":"desobeirais","lemma":"désobéir","pos":"VER"} ,
		{"word":"désobéiront","word_nosc":"desobeiront","lemma":"désobéir","pos":"VER"} ,
		{"word":"désobéis","word_nosc":"desobeis","lemma":"désobéir","pos":"VER"} ,
		{"word":"désobéissais","word_nosc":"desobeissais","lemma":"désobéir","pos":"VER"} ,
		{"word":"désobéissait","word_nosc":"desobeissait","lemma":"désobéir","pos":"VER"} ,
		{"word":"désobéissant","word_nosc":"desobeissant","lemma":"désobéir","pos":"VER"} ,
		{"word":"désobéisse","word_nosc":"desobeisse","lemma":"désobéir","pos":"VER"} ,
		{"word":"désobéissent","word_nosc":"desobeissent","lemma":"désobéir","pos":"VER"} ,
		{"word":"désobéissez","word_nosc":"desobeissez","lemma":"désobéir","pos":"VER"} ,
		{"word":"désobéissions","word_nosc":"desobeissions","lemma":"désobéir","pos":"VER"} ,
		{"word":"désobéit","word_nosc":"desobeit","lemma":"désobéir","pos":"VER"} ,
		{"word":"désoccupés","word_nosc":"desoccupes","lemma":"désoccuper","pos":"VER"} ,
		{"word":"désodoriser","word_nosc":"desodoriser","lemma":"désodoriser","pos":"VER"} ,
		{"word":"désodorisés","word_nosc":"desodorises","lemma":"désodoriser","pos":"VER"} ,
		{"word":"désoeuvré","word_nosc":"desoeuvre","lemma":"désoeuvrer","pos":"VER"} ,
		{"word":"désoeuvrée","word_nosc":"desoeuvree","lemma":"désoeuvrer","pos":"VER"} ,
		{"word":"désoeuvrées","word_nosc":"desoeuvrees","lemma":"désoeuvrer","pos":"VER"} ,
		{"word":"désoeuvrés","word_nosc":"desoeuvres","lemma":"désoeuvrer","pos":"VER"} ,
		{"word":"désola","word_nosc":"desola","lemma":"désoler","pos":"VER"} ,
		{"word":"désolai","word_nosc":"desolai","lemma":"désoler","pos":"VER"} ,
		{"word":"désolaient","word_nosc":"desolaient","lemma":"désoler","pos":"VER"} ,
		{"word":"désolais","word_nosc":"desolais","lemma":"désoler","pos":"VER"} ,
		{"word":"désolait","word_nosc":"desolait","lemma":"désoler","pos":"VER"} ,
		{"word":"désolant","word_nosc":"desolant","lemma":"désoler","pos":"VER"} ,
		{"word":"désole","word_nosc":"desole","lemma":"désoler","pos":"VER"} ,
		{"word":"désolent","word_nosc":"desolent","lemma":"désoler","pos":"VER"} ,
		{"word":"désoler","word_nosc":"desoler","lemma":"désoler","pos":"VER"} ,
		{"word":"désolera","word_nosc":"desolera","lemma":"désoler","pos":"VER"} ,
		{"word":"désolerait","word_nosc":"desolerait","lemma":"désoler","pos":"VER"} ,
		{"word":"désoleront","word_nosc":"desoleront","lemma":"désoler","pos":"VER"} ,
		{"word":"désoles","word_nosc":"desoles","lemma":"désoler","pos":"VER"} ,
		{"word":"désolez","word_nosc":"desolez","lemma":"désoler","pos":"VER"} ,
		{"word":"désolidarisa","word_nosc":"desolidarisa","lemma":"désolidariser","pos":"VER"} ,
		{"word":"désolidarisaient","word_nosc":"desolidarisaient","lemma":"désolidariser","pos":"VER"} ,
		{"word":"désolidarisait","word_nosc":"desolidarisait","lemma":"désolidariser","pos":"VER"} ,
		{"word":"désolidariser","word_nosc":"desolidariser","lemma":"désolidariser","pos":"VER"} ,
		{"word":"désolidariserai","word_nosc":"desolidariserai","lemma":"désolidariser","pos":"VER"} ,
		{"word":"désolidarisé","word_nosc":"desolidarise","lemma":"désolidariser","pos":"VER"} ,
		{"word":"désolidarisés","word_nosc":"desolidarises","lemma":"désolidariser","pos":"VER"} ,
		{"word":"désolât","word_nosc":"desolat","lemma":"désoler","pos":"VER"} ,
		{"word":"désolé","word_nosc":"desole","lemma":"désoler","pos":"VER"} ,
		{"word":"désolée","word_nosc":"desolee","lemma":"désoler","pos":"VER"} ,
		{"word":"désolées","word_nosc":"desolees","lemma":"désoler","pos":"VER"} ,
		{"word":"désolés","word_nosc":"desoles","lemma":"désoler","pos":"VER"} ,
		{"word":"désopilant","word_nosc":"desopilant","lemma":"désopiler","pos":"VER"} ,
		{"word":"désorbitent","word_nosc":"desorbitent","lemma":"désorbiter","pos":"VER"} ,
		{"word":"désorbiter","word_nosc":"desorbiter","lemma":"désorbiter","pos":"VER"} ,
		{"word":"désordonne","word_nosc":"desordonne","lemma":"désordonner","pos":"VER"} ,
		{"word":"désordonné","word_nosc":"desordonne","lemma":"désordonner","pos":"VER"} ,
		{"word":"désordonnée","word_nosc":"desordonnee","lemma":"désordonner","pos":"VER"} ,
		{"word":"désordonnés","word_nosc":"desordonnes","lemma":"désordonner","pos":"VER"} ,
		{"word":"désorganisait","word_nosc":"desorganisait","lemma":"désorganiser","pos":"VER"} ,
		{"word":"désorganise","word_nosc":"desorganise","lemma":"désorganiser","pos":"VER"} ,
		{"word":"désorganiser","word_nosc":"desorganiser","lemma":"désorganiser","pos":"VER"} ,
		{"word":"désorganisé","word_nosc":"desorganise","lemma":"désorganiser","pos":"VER"} ,
		{"word":"désorganisée","word_nosc":"desorganisee","lemma":"désorganiser","pos":"VER"} ,
		{"word":"désorganisées","word_nosc":"desorganisees","lemma":"désorganiser","pos":"VER"} ,
		{"word":"désorganisés","word_nosc":"desorganises","lemma":"désorganiser","pos":"VER"} ,
		{"word":"désorienta","word_nosc":"desorienta","lemma":"désorienter","pos":"VER"} ,
		{"word":"désorientaient","word_nosc":"desorientaient","lemma":"désorienter","pos":"VER"} ,
		{"word":"désorientait","word_nosc":"desorientait","lemma":"désorienter","pos":"VER"} ,
		{"word":"désorientant","word_nosc":"desorientant","lemma":"désorienter","pos":"VER"} ,
		{"word":"désoriente","word_nosc":"desoriente","lemma":"désorienter","pos":"VER"} ,
		{"word":"désorientent","word_nosc":"desorientent","lemma":"désorienter","pos":"VER"} ,
		{"word":"désorienter","word_nosc":"desorienter","lemma":"désorienter","pos":"VER"} ,
		{"word":"désorienterait","word_nosc":"desorienterait","lemma":"désorienter","pos":"VER"} ,
		{"word":"désorienté","word_nosc":"desoriente","lemma":"désorienter","pos":"VER"} ,
		{"word":"désorientée","word_nosc":"desorientee","lemma":"désorienter","pos":"VER"} ,
		{"word":"désorientées","word_nosc":"desorientees","lemma":"désorienter","pos":"VER"} ,
		{"word":"désorientés","word_nosc":"desorientes","lemma":"désorienter","pos":"VER"} ,
		{"word":"désossait","word_nosc":"desossait","lemma":"désosser","pos":"VER"} ,
		{"word":"désosse","word_nosc":"desosse","lemma":"désosser","pos":"VER"} ,
		{"word":"désosser","word_nosc":"desosser","lemma":"désosser","pos":"VER"} ,
		{"word":"désossé","word_nosc":"desosse","lemma":"désosser","pos":"VER"} ,
		{"word":"désossée","word_nosc":"desossee","lemma":"désosser","pos":"VER"} ,
		{"word":"désossées","word_nosc":"desossees","lemma":"désosser","pos":"VER"} ,
		{"word":"désossés","word_nosc":"desosses","lemma":"désosser","pos":"VER"} ,
		{"word":"déspiritualiser","word_nosc":"despiritualiser","lemma":"déspiritualiser","pos":"VER"} ,
		{"word":"déstabilisaient","word_nosc":"destabilisaient","lemma":"déstabiliser","pos":"VER"} ,
		{"word":"déstabilisant","word_nosc":"destabilisant","lemma":"déstabiliser","pos":"VER"} ,
		{"word":"déstabilise","word_nosc":"destabilise","lemma":"déstabiliser","pos":"VER"} ,
		{"word":"déstabilisent","word_nosc":"destabilisent","lemma":"déstabiliser","pos":"VER"} ,
		{"word":"déstabiliser","word_nosc":"destabiliser","lemma":"déstabiliser","pos":"VER"} ,
		{"word":"déstabilisera","word_nosc":"destabilisera","lemma":"déstabiliser","pos":"VER"} ,
		{"word":"déstabiliserait","word_nosc":"destabiliserait","lemma":"déstabiliser","pos":"VER"} ,
		{"word":"déstabilisez","word_nosc":"destabilisez","lemma":"déstabiliser","pos":"VER"} ,
		{"word":"déstabilisé","word_nosc":"destabilise","lemma":"déstabiliser","pos":"VER"} ,
		{"word":"déstabilisée","word_nosc":"destabilisee","lemma":"déstabiliser","pos":"VER"} ,
		{"word":"déstabilisés","word_nosc":"destabilises","lemma":"déstabiliser","pos":"VER"} ,
		{"word":"déstocké","word_nosc":"destocke","lemma":"déstocker","pos":"VER"} ,
		{"word":"déstructurer","word_nosc":"destructurer","lemma":"déstructurer","pos":"VER"} ,
		{"word":"déstructuré","word_nosc":"destructure","lemma":"déstructurer","pos":"VER"} ,
		{"word":"désubjectiviser","word_nosc":"desubjectiviser","lemma":"désubjectiviser","pos":"VER"} ,
		{"word":"désuni","word_nosc":"desuni","lemma":"désunir","pos":"VER"} ,
		{"word":"désunir","word_nosc":"desunir","lemma":"désunir","pos":"VER"} ,
		{"word":"désunirait","word_nosc":"desunirait","lemma":"désunir","pos":"VER"} ,
		{"word":"désunirent","word_nosc":"desunirent","lemma":"désunir","pos":"VER"} ,
		{"word":"désunis","word_nosc":"desunis","lemma":"désunir","pos":"VER"} ,
		{"word":"désunissaient","word_nosc":"desunissaient","lemma":"désunir","pos":"VER"} ,
		{"word":"désunissait","word_nosc":"desunissait","lemma":"désunir","pos":"VER"} ,
		{"word":"désunissant","word_nosc":"desunissant","lemma":"désunir","pos":"VER"} ,
		{"word":"désunisse","word_nosc":"desunisse","lemma":"désunir","pos":"VER"} ,
		{"word":"désunissent","word_nosc":"desunissent","lemma":"désunir","pos":"VER"} ,
		{"word":"désunit","word_nosc":"desunit","lemma":"désunir","pos":"VER"} ,
		{"word":"désynchronisé","word_nosc":"desynchronise","lemma":"désynchroniser","pos":"VER"} ,
		{"word":"déséchouée","word_nosc":"desechouee","lemma":"déséchouer","pos":"VER"} ,
		{"word":"désépaissir","word_nosc":"desepaissir","lemma":"désépaissir","pos":"VER"} ,
		{"word":"désépaissis","word_nosc":"desepaissis","lemma":"désépaissir","pos":"VER"} ,
		{"word":"déséquilibra","word_nosc":"desequilibra","lemma":"déséquilibrer","pos":"VER"} ,
		{"word":"déséquilibraient","word_nosc":"desequilibraient","lemma":"déséquilibrer","pos":"VER"} ,
		{"word":"déséquilibrais","word_nosc":"desequilibrais","lemma":"déséquilibrer","pos":"VER"} ,
		{"word":"déséquilibrait","word_nosc":"desequilibrait","lemma":"déséquilibrer","pos":"VER"} ,
		{"word":"déséquilibrant","word_nosc":"desequilibrant","lemma":"déséquilibrer","pos":"VER"} ,
		{"word":"déséquilibre","word_nosc":"desequilibre","lemma":"déséquilibrer","pos":"VER"} ,
		{"word":"déséquilibrer","word_nosc":"desequilibrer","lemma":"déséquilibrer","pos":"VER"} ,
		{"word":"déséquilibrez","word_nosc":"desequilibrez","lemma":"déséquilibrer","pos":"VER"} ,
		{"word":"déséquilibrât","word_nosc":"desequilibrat","lemma":"déséquilibrer","pos":"VER"} ,
		{"word":"déséquilibré","word_nosc":"desequilibre","lemma":"déséquilibrer","pos":"VER"} ,
		{"word":"déséquilibrée","word_nosc":"desequilibree","lemma":"déséquilibrer","pos":"VER"} ,
		{"word":"déséquilibrés","word_nosc":"desequilibres","lemma":"déséquilibrer","pos":"VER"} ,
		{"word":"déséquipent","word_nosc":"desequipent","lemma":"déséquiper","pos":"VER"} ,
		{"word":"déséquiper","word_nosc":"desequiper","lemma":"déséquiper","pos":"VER"} ,
		{"word":"déséquipé","word_nosc":"desequipe","lemma":"déséquiper","pos":"VER"} ,
		{"word":"déséquipés","word_nosc":"desequipes","lemma":"déséquiper","pos":"VER"} ,
		{"word":"détacha","word_nosc":"detacha","lemma":"détacher","pos":"VER"} ,
		{"word":"détachai","word_nosc":"detachai","lemma":"détacher","pos":"VER"} ,
		{"word":"détachaient","word_nosc":"detachaient","lemma":"détacher","pos":"VER"} ,
		{"word":"détachais","word_nosc":"detachais","lemma":"détacher","pos":"VER"} ,
		{"word":"détachait","word_nosc":"detachait","lemma":"détacher","pos":"VER"} ,
		{"word":"détachant","word_nosc":"detachant","lemma":"détacher","pos":"VER"} ,
		{"word":"détache","word_nosc":"detache","lemma":"détacher","pos":"VER"} ,
		{"word":"détachent","word_nosc":"detachent","lemma":"détacher","pos":"VER"} ,
		{"word":"détacher","word_nosc":"detacher","lemma":"détacher","pos":"VER"} ,
		{"word":"détacherai","word_nosc":"detacherai","lemma":"détacher","pos":"VER"} ,
		{"word":"détacheraient","word_nosc":"detacheraient","lemma":"détacher","pos":"VER"} ,
		{"word":"détacherais","word_nosc":"detacherais","lemma":"détacher","pos":"VER"} ,
		{"word":"détacherait","word_nosc":"detacherait","lemma":"détacher","pos":"VER"} ,
		{"word":"détacherons","word_nosc":"detacherons","lemma":"détacher","pos":"VER"} ,
		{"word":"détacheront","word_nosc":"detacheront","lemma":"détacher","pos":"VER"} ,
		{"word":"détaches","word_nosc":"detaches","lemma":"détacher","pos":"VER"} ,
		{"word":"détachez","word_nosc":"detachez","lemma":"détacher","pos":"VER"} ,
		{"word":"détachions","word_nosc":"detachions","lemma":"détacher","pos":"VER"} ,
		{"word":"détachons","word_nosc":"detachons","lemma":"détacher","pos":"VER"} ,
		{"word":"détachât","word_nosc":"detachat","lemma":"détacher","pos":"VER"} ,
		{"word":"détachèrent","word_nosc":"detacherent","lemma":"détacher","pos":"VER"} ,
		{"word":"détaché","word_nosc":"detache","lemma":"détacher","pos":"VER"} ,
		{"word":"détachée","word_nosc":"detachee","lemma":"détacher","pos":"VER"} ,
		{"word":"détachées","word_nosc":"detachees","lemma":"détacher","pos":"VER"} ,
		{"word":"détachés","word_nosc":"detaches","lemma":"détacher","pos":"VER"} ,
		{"word":"détailla","word_nosc":"detailla","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillai","word_nosc":"detaillai","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillaient","word_nosc":"detaillaient","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillais","word_nosc":"detaillais","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillait","word_nosc":"detaillait","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillant","word_nosc":"detaillant","lemma":"détailler","pos":"VER"} ,
		{"word":"détaille","word_nosc":"detaille","lemma":"détailler","pos":"VER"} ,
		{"word":"détailler","word_nosc":"detailler","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillera","word_nosc":"detaillera","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillerai","word_nosc":"detaillerai","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillez","word_nosc":"detaillez","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillions","word_nosc":"detaillions","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillons","word_nosc":"detaillons","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillât","word_nosc":"detaillat","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillèrent","word_nosc":"detaillerent","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillé","word_nosc":"detaille","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillée","word_nosc":"detaillee","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillées","word_nosc":"detaillees","lemma":"détailler","pos":"VER"} ,
		{"word":"détaillés","word_nosc":"detailles","lemma":"détailler","pos":"VER"} ,
		{"word":"détala","word_nosc":"detala","lemma":"détaler","pos":"VER"} ,
		{"word":"détalai","word_nosc":"detalai","lemma":"détaler","pos":"VER"} ,
		{"word":"détalaient","word_nosc":"detalaient","lemma":"détaler","pos":"VER"} ,
		{"word":"détalait","word_nosc":"detalait","lemma":"détaler","pos":"VER"} ,
		{"word":"détalant","word_nosc":"detalant","lemma":"détaler","pos":"VER"} ,
		{"word":"détale","word_nosc":"detale","lemma":"détaler","pos":"VER"} ,
		{"word":"détalent","word_nosc":"detalent","lemma":"détaler","pos":"VER"} ,
		{"word":"détaler","word_nosc":"detaler","lemma":"détaler","pos":"VER"} ,
		{"word":"détaleraient","word_nosc":"detaleraient","lemma":"détaler","pos":"VER"} ,
		{"word":"détaleront","word_nosc":"detaleront","lemma":"détaler","pos":"VER"} ,
		{"word":"détales","word_nosc":"detales","lemma":"détaler","pos":"VER"} ,
		{"word":"détalions","word_nosc":"detalions","lemma":"détaler","pos":"VER"} ,
		{"word":"détalonner","word_nosc":"detalonner","lemma":"détalonner","pos":"VER"} ,
		{"word":"détalât","word_nosc":"detalat","lemma":"détaler","pos":"VER"} ,
		{"word":"détalèrent","word_nosc":"detalerent","lemma":"détaler","pos":"VER"} ,
		{"word":"détalé","word_nosc":"detale","lemma":"détaler","pos":"VER"} ,
		{"word":"détaxée","word_nosc":"detaxee","lemma":"détaxer","pos":"VER"} ,
		{"word":"détaxées","word_nosc":"detaxees","lemma":"détaxer","pos":"VER"} ,
		{"word":"détecta","word_nosc":"detecta","lemma":"détecter","pos":"VER"} ,
		{"word":"détectai","word_nosc":"detectai","lemma":"détecter","pos":"VER"} ,
		{"word":"détectaient","word_nosc":"detectaient","lemma":"détecter","pos":"VER"} ,
		{"word":"détectait","word_nosc":"detectait","lemma":"détecter","pos":"VER"} ,
		{"word":"détecte","word_nosc":"detecte","lemma":"détecter","pos":"VER"} ,
		{"word":"détectent","word_nosc":"detectent","lemma":"détecter","pos":"VER"} ,
		{"word":"détecter","word_nosc":"detecter","lemma":"détecter","pos":"VER"} ,
		{"word":"détectera","word_nosc":"detectera","lemma":"détecter","pos":"VER"} ,
		{"word":"détecteront","word_nosc":"detecteront","lemma":"détecter","pos":"VER"} ,
		{"word":"détectez","word_nosc":"detectez","lemma":"détecter","pos":"VER"} ,
		{"word":"détectons","word_nosc":"detectons","lemma":"détecter","pos":"VER"} ,
		{"word":"détecté","word_nosc":"detecte","lemma":"détecter","pos":"VER"} ,
		{"word":"détectée","word_nosc":"detectee","lemma":"détecter","pos":"VER"} ,
		{"word":"détectées","word_nosc":"detectees","lemma":"détecter","pos":"VER"} ,
		{"word":"détectés","word_nosc":"detectes","lemma":"détecter","pos":"VER"} ,
		{"word":"déteignaient","word_nosc":"deteignaient","lemma":"déteindre","pos":"VER"} ,
		{"word":"déteignait","word_nosc":"deteignait","lemma":"déteindre","pos":"VER"} ,
		{"word":"déteignant","word_nosc":"deteignant","lemma":"déteindre","pos":"VER"} ,
		{"word":"déteigne","word_nosc":"deteigne","lemma":"déteindre","pos":"VER"} ,
		{"word":"déteignent","word_nosc":"deteignent","lemma":"déteindre","pos":"VER"} ,
		{"word":"déteignes","word_nosc":"deteignes","lemma":"déteindre","pos":"VER"} ,
		{"word":"déteindra","word_nosc":"deteindra","lemma":"déteindre","pos":"VER"} ,
		{"word":"déteindre","word_nosc":"deteindre","lemma":"déteindre","pos":"VER"} ,
		{"word":"déteint","word_nosc":"deteint","lemma":"déteindre","pos":"VER"} ,
		{"word":"déteinte","word_nosc":"deteinte","lemma":"déteindre","pos":"VER"} ,
		{"word":"déteintes","word_nosc":"deteintes","lemma":"déteindre","pos":"VER"} ,
		{"word":"déteints","word_nosc":"deteints","lemma":"déteindre","pos":"VER"} ,
		{"word":"détela","word_nosc":"detela","lemma":"dételer","pos":"VER"} ,
		{"word":"dételaient","word_nosc":"detelaient","lemma":"dételer","pos":"VER"} ,
		{"word":"dételait","word_nosc":"detelait","lemma":"dételer","pos":"VER"} ,
		{"word":"dételer","word_nosc":"deteler","lemma":"dételer","pos":"VER"} ,
		{"word":"dételez","word_nosc":"detelez","lemma":"dételer","pos":"VER"} ,
		{"word":"dételle","word_nosc":"detelle","lemma":"dételer","pos":"VER"} ,
		{"word":"dételé","word_nosc":"detele","lemma":"dételer","pos":"VER"} ,
		{"word":"dételée","word_nosc":"detelee","lemma":"dételer","pos":"VER"} ,
		{"word":"dételées","word_nosc":"detelees","lemma":"dételer","pos":"VER"} ,
		{"word":"dételés","word_nosc":"deteles","lemma":"dételer","pos":"VER"} ,
		{"word":"détenaient","word_nosc":"detenaient","lemma":"détenir","pos":"VER"} ,
		{"word":"détenais","word_nosc":"detenais","lemma":"détenir","pos":"VER"} ,
		{"word":"détenait","word_nosc":"detenait","lemma":"détenir","pos":"VER"} ,
		{"word":"détenant","word_nosc":"detenant","lemma":"détenir","pos":"VER"} ,
		{"word":"détend","word_nosc":"detend","lemma":"détendre","pos":"VER"} ,
		{"word":"détendaient","word_nosc":"detendaient","lemma":"détendre","pos":"VER"} ,
		{"word":"détendais","word_nosc":"detendais","lemma":"détendre","pos":"VER"} ,
		{"word":"détendait","word_nosc":"detendait","lemma":"détendre","pos":"VER"} ,
		{"word":"détendant","word_nosc":"detendant","lemma":"détendre","pos":"VER"} ,
		{"word":"détende","word_nosc":"detende","lemma":"détendre","pos":"VER"} ,
		{"word":"détendent","word_nosc":"detendent","lemma":"détendre","pos":"VER"} ,
		{"word":"détendes","word_nosc":"detendes","lemma":"détendre","pos":"VER"} ,
		{"word":"détendez","word_nosc":"detendez","lemma":"détendre","pos":"VER"} ,
		{"word":"détendiez","word_nosc":"detendiez","lemma":"détendre","pos":"VER"} ,
		{"word":"détendions","word_nosc":"detendions","lemma":"détendre","pos":"VER"} ,
		{"word":"détendirent","word_nosc":"detendirent","lemma":"détendre","pos":"VER"} ,
		{"word":"détendis","word_nosc":"detendis","lemma":"détendre","pos":"VER"} ,
		{"word":"détendit","word_nosc":"detendit","lemma":"détendre","pos":"VER"} ,
		{"word":"détendons","word_nosc":"detendons","lemma":"détendre","pos":"VER"} ,
		{"word":"détendra","word_nosc":"detendra","lemma":"détendre","pos":"VER"} ,
		{"word":"détendrai","word_nosc":"detendrai","lemma":"détendre","pos":"VER"} ,
		{"word":"détendraient","word_nosc":"detendraient","lemma":"détendre","pos":"VER"} ,
		{"word":"détendrait","word_nosc":"detendrait","lemma":"détendre","pos":"VER"} ,
		{"word":"détendre","word_nosc":"detendre","lemma":"détendre","pos":"VER"} ,
		{"word":"détendrez","word_nosc":"detendrez","lemma":"détendre","pos":"VER"} ,
		{"word":"détendrons","word_nosc":"detendrons","lemma":"détendre","pos":"VER"} ,
		{"word":"détends","word_nosc":"detends","lemma":"détendre","pos":"VER"} ,
		{"word":"détendu","word_nosc":"detendu","lemma":"détendre","pos":"VER"} ,
		{"word":"détendue","word_nosc":"detendue","lemma":"détendre","pos":"VER"} ,
		{"word":"détendues","word_nosc":"detendues","lemma":"détendre","pos":"VER"} ,
		{"word":"détendus","word_nosc":"detendus","lemma":"détendre","pos":"VER"} ,
		{"word":"détendît","word_nosc":"detendit","lemma":"détendre","pos":"VER"} ,
		{"word":"détenez","word_nosc":"detenez","lemma":"détenir","pos":"VER"} ,
		{"word":"déteniez","word_nosc":"deteniez","lemma":"détenir","pos":"VER"} ,
		{"word":"détenions","word_nosc":"detenions","lemma":"détenir","pos":"VER"} ,
		{"word":"détenir","word_nosc":"detenir","lemma":"détenir","pos":"VER"} ,
		{"word":"détenons","word_nosc":"detenons","lemma":"détenir","pos":"VER"} ,
		{"word":"détenu","word_nosc":"detenu","lemma":"détenir","pos":"VER"} ,
		{"word":"détenue","word_nosc":"detenue","lemma":"détenir","pos":"VER"} ,
		{"word":"détenues","word_nosc":"detenues","lemma":"détenir","pos":"VER"} ,
		{"word":"détenus","word_nosc":"detenus","lemma":"détenir","pos":"VER"} ,
		{"word":"détergeant","word_nosc":"detergeant","lemma":"déterger","pos":"VER"} ,
		{"word":"détermina","word_nosc":"determina","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminai","word_nosc":"determinai","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminaient","word_nosc":"determinaient","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminais","word_nosc":"determinais","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminait","word_nosc":"determinait","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminant","word_nosc":"determinant","lemma":"déterminer","pos":"VER"} ,
		{"word":"détermine","word_nosc":"determine","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminent","word_nosc":"determinent","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminer","word_nosc":"determiner","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminera","word_nosc":"determinera","lemma":"déterminer","pos":"VER"} ,
		{"word":"détermineraient","word_nosc":"determineraient","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminerait","word_nosc":"determinerait","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminerons","word_nosc":"determinerons","lemma":"déterminer","pos":"VER"} ,
		{"word":"détermineront","word_nosc":"determineront","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminez","word_nosc":"determinez","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminiez","word_nosc":"determiniez","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminions","word_nosc":"determinions","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminons","word_nosc":"determinons","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminèrent","word_nosc":"determinerent","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminé","word_nosc":"determine","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminée","word_nosc":"determinee","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminées","word_nosc":"determinees","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterminés","word_nosc":"determines","lemma":"déterminer","pos":"VER"} ,
		{"word":"déterra","word_nosc":"deterra","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterraient","word_nosc":"deterraient","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterrais","word_nosc":"deterrais","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterrait","word_nosc":"deterrait","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterrant","word_nosc":"deterrant","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterre","word_nosc":"deterre","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterrent","word_nosc":"deterrent","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterrer","word_nosc":"deterrer","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterrera","word_nosc":"deterrera","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterrerai","word_nosc":"deterrerai","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterrerait","word_nosc":"deterrerait","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterrerez","word_nosc":"deterrerez","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterreront","word_nosc":"deterreront","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterrez","word_nosc":"deterrez","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterrions","word_nosc":"deterrions","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterrons","word_nosc":"deterrons","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterré","word_nosc":"deterre","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterrée","word_nosc":"deterree","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterrées","word_nosc":"deterrees","lemma":"déterrer","pos":"VER"} ,
		{"word":"déterrés","word_nosc":"deterres","lemma":"déterrer","pos":"VER"} ,
		{"word":"détesta","word_nosc":"detesta","lemma":"détester","pos":"VER"} ,
		{"word":"détestai","word_nosc":"detestai","lemma":"détester","pos":"VER"} ,
		{"word":"détestaient","word_nosc":"detestaient","lemma":"détester","pos":"VER"} ,
		{"word":"détestais","word_nosc":"detestais","lemma":"détester","pos":"VER"} ,
		{"word":"détestait","word_nosc":"detestait","lemma":"détester","pos":"VER"} ,
		{"word":"détestant","word_nosc":"detestant","lemma":"détester","pos":"VER"} ,
		{"word":"déteste","word_nosc":"deteste","lemma":"détester","pos":"VER"} ,
		{"word":"détestent","word_nosc":"detestent","lemma":"détester","pos":"VER"} ,
		{"word":"détester","word_nosc":"detester","lemma":"détester","pos":"VER"} ,
		{"word":"détestera","word_nosc":"detestera","lemma":"détester","pos":"VER"} ,
		{"word":"détesterai","word_nosc":"detesterai","lemma":"détester","pos":"VER"} ,
		{"word":"détesteraient","word_nosc":"detesteraient","lemma":"détester","pos":"VER"} ,
		{"word":"détesterais","word_nosc":"detesterais","lemma":"détester","pos":"VER"} ,
		{"word":"détesterait","word_nosc":"detesterait","lemma":"détester","pos":"VER"} ,
		{"word":"détesteras","word_nosc":"detesteras","lemma":"détester","pos":"VER"} ,
		{"word":"détesteriez","word_nosc":"detesteriez","lemma":"détester","pos":"VER"} ,
		{"word":"détesteront","word_nosc":"detesteront","lemma":"détester","pos":"VER"} ,
		{"word":"détestes","word_nosc":"detestes","lemma":"détester","pos":"VER"} ,
		{"word":"détestez","word_nosc":"detestez","lemma":"détester","pos":"VER"} ,
		{"word":"détestiez","word_nosc":"detestiez","lemma":"détester","pos":"VER"} ,
		{"word":"détestions","word_nosc":"detestions","lemma":"détester","pos":"VER"} ,
		{"word":"détestons","word_nosc":"detestons","lemma":"détester","pos":"VER"} ,
		{"word":"détestât","word_nosc":"detestat","lemma":"détester","pos":"VER"} ,
		{"word":"détesté","word_nosc":"deteste","lemma":"détester","pos":"VER"} ,
		{"word":"détestée","word_nosc":"detestee","lemma":"détester","pos":"VER"} ,
		{"word":"détestés","word_nosc":"detestes","lemma":"détester","pos":"VER"} ,
		{"word":"détiendra","word_nosc":"detiendra","lemma":"détenir","pos":"VER"} ,
		{"word":"détiendrai","word_nosc":"detiendrai","lemma":"détenir","pos":"VER"} ,
		{"word":"détiendraient","word_nosc":"detiendraient","lemma":"détenir","pos":"VER"} ,
		{"word":"détiendrait","word_nosc":"detiendrait","lemma":"détenir","pos":"VER"} ,
		{"word":"détiendras","word_nosc":"detiendras","lemma":"détenir","pos":"VER"} ,
		{"word":"détiendrez","word_nosc":"detiendrez","lemma":"détenir","pos":"VER"} ,
		{"word":"détiendront","word_nosc":"detiendront","lemma":"détenir","pos":"VER"} ,
		{"word":"détienne","word_nosc":"detienne","lemma":"détenir","pos":"VER"} ,
		{"word":"détiennent","word_nosc":"detiennent","lemma":"détenir","pos":"VER"} ,
		{"word":"détiens","word_nosc":"detiens","lemma":"détenir","pos":"VER"} ,
		{"word":"détient","word_nosc":"detient","lemma":"détenir","pos":"VER"} ,
		{"word":"détint","word_nosc":"detint","lemma":"détenir","pos":"VER"} ,
		{"word":"détisse","word_nosc":"detisse","lemma":"détisser","pos":"VER"} ,
		{"word":"détonait","word_nosc":"detonait","lemma":"détoner","pos":"VER"} ,
		{"word":"détoner","word_nosc":"detoner","lemma":"détoner","pos":"VER"} ,
		{"word":"détonera","word_nosc":"detonera","lemma":"détoner","pos":"VER"} ,
		{"word":"détonna","word_nosc":"detonna","lemma":"détonner","pos":"VER"} ,
		{"word":"détonnaient","word_nosc":"detonnaient","lemma":"détonner","pos":"VER"} ,
		{"word":"détonnait","word_nosc":"detonnait","lemma":"détonner","pos":"VER"} ,
		{"word":"détonnant","word_nosc":"detonnant","lemma":"détonner","pos":"VER"} ,
		{"word":"détonne","word_nosc":"detonne","lemma":"détonner","pos":"VER"} ,
		{"word":"détonnent","word_nosc":"detonnent","lemma":"détonner","pos":"VER"} ,
		{"word":"détonner","word_nosc":"detonner","lemma":"détonner","pos":"VER"} ,
		{"word":"détonée","word_nosc":"detonee","lemma":"détoner","pos":"VER"} ,
		{"word":"détord","word_nosc":"detord","lemma":"détordre","pos":"VER"} ,
		{"word":"détordit","word_nosc":"detordit","lemma":"détordre","pos":"VER"} ,
		{"word":"détortillai","word_nosc":"detortillai","lemma":"détortiller","pos":"VER"} ,
		{"word":"détortille","word_nosc":"detortille","lemma":"détortiller","pos":"VER"} ,
		{"word":"détourna","word_nosc":"detourna","lemma":"détourner","pos":"VER"} ,
		{"word":"détournai","word_nosc":"detournai","lemma":"détourner","pos":"VER"} ,
		{"word":"détournaient","word_nosc":"detournaient","lemma":"détourner","pos":"VER"} ,
		{"word":"détournais","word_nosc":"detournais","lemma":"détourner","pos":"VER"} ,
		{"word":"détournait","word_nosc":"detournait","lemma":"détourner","pos":"VER"} ,
		{"word":"détournant","word_nosc":"detournant","lemma":"détourner","pos":"VER"} ,
		{"word":"détourne","word_nosc":"detourne","lemma":"détourner","pos":"VER"} ,
		{"word":"détournent","word_nosc":"detournent","lemma":"détourner","pos":"VER"} ,
		{"word":"détourner","word_nosc":"detourner","lemma":"détourner","pos":"VER"} ,
		{"word":"détournera","word_nosc":"detournera","lemma":"détourner","pos":"VER"} ,
		{"word":"détournerai","word_nosc":"detournerai","lemma":"détourner","pos":"VER"} ,
		{"word":"détourneraient","word_nosc":"detourneraient","lemma":"détourner","pos":"VER"} ,
		{"word":"détournerais","word_nosc":"detournerais","lemma":"détourner","pos":"VER"} ,
		{"word":"détournerait","word_nosc":"detournerait","lemma":"détourner","pos":"VER"} ,
		{"word":"détourneront","word_nosc":"detourneront","lemma":"détourner","pos":"VER"} ,
		{"word":"détournes","word_nosc":"detournes","lemma":"détourner","pos":"VER"} ,
		{"word":"détournez","word_nosc":"detournez","lemma":"détourner","pos":"VER"} ,
		{"word":"détourniez","word_nosc":"detourniez","lemma":"détourner","pos":"VER"} ,
		{"word":"détournions","word_nosc":"detournions","lemma":"détourner","pos":"VER"} ,
		{"word":"détournons","word_nosc":"detournons","lemma":"détourner","pos":"VER"} ,
		{"word":"détournât","word_nosc":"detournat","lemma":"détourner","pos":"VER"} ,
		{"word":"détournèrent","word_nosc":"detournerent","lemma":"détourner","pos":"VER"} ,
		{"word":"détourné","word_nosc":"detourne","lemma":"détourner","pos":"VER"} ,
		{"word":"détournée","word_nosc":"detournee","lemma":"détourner","pos":"VER"} ,
		{"word":"détournées","word_nosc":"detournees","lemma":"détourner","pos":"VER"} ,
		{"word":"détournés","word_nosc":"detournes","lemma":"détourner","pos":"VER"} ,
		{"word":"détraquaient","word_nosc":"detraquaient","lemma":"détraquer","pos":"VER"} ,
		{"word":"détraquais","word_nosc":"detraquais","lemma":"détraquer","pos":"VER"} ,
		{"word":"détraquant","word_nosc":"detraquant","lemma":"détraquer","pos":"VER"} ,
		{"word":"détraque","word_nosc":"detraque","lemma":"détraquer","pos":"VER"} ,
		{"word":"détraquent","word_nosc":"detraquent","lemma":"détraquer","pos":"VER"} ,
		{"word":"détraquer","word_nosc":"detraquer","lemma":"détraquer","pos":"VER"} ,
		{"word":"détraquera","word_nosc":"detraquera","lemma":"détraquer","pos":"VER"} ,
		{"word":"détraquerais","word_nosc":"detraquerais","lemma":"détraquer","pos":"VER"} ,
		{"word":"détraqué","word_nosc":"detraque","lemma":"détraquer","pos":"VER"} ,
		{"word":"détraquée","word_nosc":"detraquee","lemma":"détraquer","pos":"VER"} ,
		{"word":"détraquées","word_nosc":"detraquees","lemma":"détraquer","pos":"VER"} ,
		{"word":"détraqués","word_nosc":"detraques","lemma":"détraquer","pos":"VER"} ,
		{"word":"détrempaient","word_nosc":"detrempaient","lemma":"détremper","pos":"VER"} ,
		{"word":"détrempait","word_nosc":"detrempait","lemma":"détremper","pos":"VER"} ,
		{"word":"détrempant","word_nosc":"detrempant","lemma":"détremper","pos":"VER"} ,
		{"word":"détrempe","word_nosc":"detrempe","lemma":"détremper","pos":"VER"} ,
		{"word":"détremper","word_nosc":"detremper","lemma":"détremper","pos":"VER"} ,
		{"word":"détrempé","word_nosc":"detrempe","lemma":"détremper","pos":"VER"} ,
		{"word":"détrempée","word_nosc":"detrempee","lemma":"détremper","pos":"VER"} ,
		{"word":"détrempées","word_nosc":"detrempees","lemma":"détremper","pos":"VER"} ,
		{"word":"détrempés","word_nosc":"detrempes","lemma":"détremper","pos":"VER"} ,
		{"word":"détribalisée","word_nosc":"detribalisee","lemma":"détribaliser","pos":"VER"} ,
		{"word":"détricote","word_nosc":"detricote","lemma":"détricoter","pos":"VER"} ,
		{"word":"détricoter","word_nosc":"detricoter","lemma":"détricoter","pos":"VER"} ,
		{"word":"détrompa","word_nosc":"detrompa","lemma":"détromper","pos":"VER"} ,
		{"word":"détrompai","word_nosc":"detrompai","lemma":"détromper","pos":"VER"} ,
		{"word":"détrompais","word_nosc":"detrompais","lemma":"détromper","pos":"VER"} ,
		{"word":"détrompait","word_nosc":"detrompait","lemma":"détromper","pos":"VER"} ,
		{"word":"détrompe","word_nosc":"detrompe","lemma":"détromper","pos":"VER"} ,
		{"word":"détromper","word_nosc":"detromper","lemma":"détromper","pos":"VER"} ,
		{"word":"détromperai","word_nosc":"detromperai","lemma":"détromper","pos":"VER"} ,
		{"word":"détrompez","word_nosc":"detrompez","lemma":"détromper","pos":"VER"} ,
		{"word":"détrompé","word_nosc":"detrompe","lemma":"détromper","pos":"VER"} ,
		{"word":"détrompée","word_nosc":"detrompee","lemma":"détromper","pos":"VER"} ,
		{"word":"détrompés","word_nosc":"detrompes","lemma":"détromper","pos":"VER"} ,
		{"word":"détroncha","word_nosc":"detroncha","lemma":"détroncher","pos":"VER"} ,
		{"word":"détronchant","word_nosc":"detronchant","lemma":"détroncher","pos":"VER"} ,
		{"word":"détronche","word_nosc":"detronche","lemma":"détroncher","pos":"VER"} ,
		{"word":"détronchent","word_nosc":"detronchent","lemma":"détroncher","pos":"VER"} ,
		{"word":"détroncher","word_nosc":"detroncher","lemma":"détroncher","pos":"VER"} ,
		{"word":"détroncherais","word_nosc":"detroncherais","lemma":"détroncher","pos":"VER"} ,
		{"word":"détronchèrent","word_nosc":"detroncherent","lemma":"détroncher","pos":"VER"} ,
		{"word":"détronché","word_nosc":"detronche","lemma":"détroncher","pos":"VER"} ,
		{"word":"détroussaient","word_nosc":"detroussaient","lemma":"détrousser","pos":"VER"} ,
		{"word":"détroussais","word_nosc":"detroussais","lemma":"détrousser","pos":"VER"} ,
		{"word":"détroussant","word_nosc":"detroussant","lemma":"détrousser","pos":"VER"} ,
		{"word":"détroussent","word_nosc":"detroussent","lemma":"détrousser","pos":"VER"} ,
		{"word":"détrousser","word_nosc":"detrousser","lemma":"détrousser","pos":"VER"} ,
		{"word":"détrousses","word_nosc":"detrousses","lemma":"détrousser","pos":"VER"} ,
		{"word":"détroussez","word_nosc":"detroussez","lemma":"détrousser","pos":"VER"} ,
		{"word":"détroussé","word_nosc":"detrousse","lemma":"détrousser","pos":"VER"} ,
		{"word":"détroussés","word_nosc":"detrousses","lemma":"détrousser","pos":"VER"} ,
		{"word":"détruira","word_nosc":"detruira","lemma":"détruire","pos":"VER"} ,
		{"word":"détruirai","word_nosc":"detruirai","lemma":"détruire","pos":"VER"} ,
		{"word":"détruiraient","word_nosc":"detruiraient","lemma":"détruire","pos":"VER"} ,
		{"word":"détruirais","word_nosc":"detruirais","lemma":"détruire","pos":"VER"} ,
		{"word":"détruirait","word_nosc":"detruirait","lemma":"détruire","pos":"VER"} ,
		{"word":"détruiras","word_nosc":"detruiras","lemma":"détruire","pos":"VER"} ,
		{"word":"détruire","word_nosc":"detruire","lemma":"détruire","pos":"VER"} ,
		{"word":"détruirez","word_nosc":"detruirez","lemma":"détruire","pos":"VER"} ,
		{"word":"détruiriez","word_nosc":"detruiriez","lemma":"détruire","pos":"VER"} ,
		{"word":"détruirions","word_nosc":"detruirions","lemma":"détruire","pos":"VER"} ,
		{"word":"détruirons","word_nosc":"detruirons","lemma":"détruire","pos":"VER"} ,
		{"word":"détruiront","word_nosc":"detruiront","lemma":"détruire","pos":"VER"} ,
		{"word":"détruis","word_nosc":"detruis","lemma":"détruire","pos":"VER"} ,
		{"word":"détruisaient","word_nosc":"detruisaient","lemma":"détruire","pos":"VER"} ,
		{"word":"détruisais","word_nosc":"detruisais","lemma":"détruire","pos":"VER"} ,
		{"word":"détruisait","word_nosc":"detruisait","lemma":"détruire","pos":"VER"} ,
		{"word":"détruisant","word_nosc":"detruisant","lemma":"détruire","pos":"VER"} ,
		{"word":"détruise","word_nosc":"detruise","lemma":"détruire","pos":"VER"} ,
		{"word":"détruisent","word_nosc":"detruisent","lemma":"détruire","pos":"VER"} ,
		{"word":"détruises","word_nosc":"detruises","lemma":"détruire","pos":"VER"} ,
		{"word":"détruisez","word_nosc":"detruisez","lemma":"détruire","pos":"VER"} ,
		{"word":"détruisiez","word_nosc":"detruisiez","lemma":"détruire","pos":"VER"} ,
		{"word":"détruisions","word_nosc":"detruisions","lemma":"détruire","pos":"VER"} ,
		{"word":"détruisirent","word_nosc":"detruisirent","lemma":"détruire","pos":"VER"} ,
		{"word":"détruisis","word_nosc":"detruisis","lemma":"détruire","pos":"VER"} ,
		{"word":"détruisit","word_nosc":"detruisit","lemma":"détruire","pos":"VER"} ,
		{"word":"détruisons","word_nosc":"detruisons","lemma":"détruire","pos":"VER"} ,
		{"word":"détruit","word_nosc":"detruit","lemma":"détruire","pos":"VER"} ,
		{"word":"détruite","word_nosc":"detruite","lemma":"détruire","pos":"VER"} ,
		{"word":"détruites","word_nosc":"detruites","lemma":"détruire","pos":"VER"} ,
		{"word":"détruits","word_nosc":"detruits","lemma":"détruire","pos":"VER"} ,
		{"word":"détrônaient","word_nosc":"detronaient","lemma":"détrôner","pos":"VER"} ,
		{"word":"détrône","word_nosc":"detrone","lemma":"détrôner","pos":"VER"} ,
		{"word":"détrôner","word_nosc":"detroner","lemma":"détrôner","pos":"VER"} ,
		{"word":"détrônât","word_nosc":"detronat","lemma":"détrôner","pos":"VER"} ,
		{"word":"détrôné","word_nosc":"detrone","lemma":"détrôner","pos":"VER"} ,
		{"word":"détrônée","word_nosc":"detronee","lemma":"détrôner","pos":"VER"} ,
		{"word":"détérioraient","word_nosc":"deterioraient","lemma":"détériorer","pos":"VER"} ,
		{"word":"détériorait","word_nosc":"deteriorait","lemma":"détériorer","pos":"VER"} ,
		{"word":"détériorant","word_nosc":"deteriorant","lemma":"détériorer","pos":"VER"} ,
		{"word":"détériore","word_nosc":"deteriore","lemma":"détériorer","pos":"VER"} ,
		{"word":"détériorent","word_nosc":"deteriorent","lemma":"détériorer","pos":"VER"} ,
		{"word":"détériorer","word_nosc":"deteriorer","lemma":"détériorer","pos":"VER"} ,
		{"word":"détériorât","word_nosc":"deteriorat","lemma":"détériorer","pos":"VER"} ,
		{"word":"détérioré","word_nosc":"deteriore","lemma":"détériorer","pos":"VER"} ,
		{"word":"détériorée","word_nosc":"deterioree","lemma":"détériorer","pos":"VER"} ,
		{"word":"détériorées","word_nosc":"deteriorees","lemma":"détériorer","pos":"VER"} ,
		{"word":"détériorés","word_nosc":"deteriores","lemma":"détériorer","pos":"VER"} ,
		{"word":"détînt","word_nosc":"detint","lemma":"détenir","pos":"VER"} ,
		{"word":"dévala","word_nosc":"devala","lemma":"dévaler","pos":"VER"} ,
		{"word":"dévalai","word_nosc":"devalai","lemma":"dévaler","pos":"VER"} ,
		{"word":"dévalaient","word_nosc":"devalaient","lemma":"dévaler","pos":"VER"} ,
		{"word":"dévalais","word_nosc":"devalais","lemma":"dévaler","pos":"VER"} ,
		{"word":"dévalait","word_nosc":"devalait","lemma":"dévaler","pos":"VER"} ,
		{"word":"dévalant","word_nosc":"devalant","lemma":"dévaler","pos":"VER"} ,
		{"word":"dévale","word_nosc":"devale","lemma":"dévaler","pos":"VER"} ,
		{"word":"dévalent","word_nosc":"devalent","lemma":"dévaler","pos":"VER"} ,
		{"word":"dévaler","word_nosc":"devaler","lemma":"dévaler","pos":"VER"} ,
		{"word":"dévales","word_nosc":"devales","lemma":"dévaler","pos":"VER"} ,
		{"word":"dévalions","word_nosc":"devalions","lemma":"dévaler","pos":"VER"} ,
		{"word":"dévalisai","word_nosc":"devalisai","lemma":"dévaliser","pos":"VER"} ,
		{"word":"dévalisaient","word_nosc":"devalisaient","lemma":"dévaliser","pos":"VER"} ,
		{"word":"dévalisait","word_nosc":"devalisait","lemma":"dévaliser","pos":"VER"} ,
		{"word":"dévalisant","word_nosc":"devalisant","lemma":"dévaliser","pos":"VER"} ,
		{"word":"dévalise","word_nosc":"devalise","lemma":"dévaliser","pos":"VER"} ,
		{"word":"dévalisent","word_nosc":"devalisent","lemma":"dévaliser","pos":"VER"} ,
		{"word":"dévaliser","word_nosc":"devaliser","lemma":"dévaliser","pos":"VER"} ,
		{"word":"dévalisera","word_nosc":"devalisera","lemma":"dévaliser","pos":"VER"} ,
		{"word":"dévaliserait","word_nosc":"devaliserait","lemma":"dévaliser","pos":"VER"} ,
		{"word":"dévalises","word_nosc":"devalises","lemma":"dévaliser","pos":"VER"} ,
		{"word":"dévalisez","word_nosc":"devalisez","lemma":"dévaliser","pos":"VER"} ,
		{"word":"dévalisé","word_nosc":"devalise","lemma":"dévaliser","pos":"VER"} ,
		{"word":"dévalisée","word_nosc":"devalisee","lemma":"dévaliser","pos":"VER"} ,
		{"word":"dévalisés","word_nosc":"devalises","lemma":"dévaliser","pos":"VER"} ,
		{"word":"dévalons","word_nosc":"devalons","lemma":"dévaler","pos":"VER"} ,
		{"word":"dévalorisaient","word_nosc":"devalorisaient","lemma":"dévaloriser","pos":"VER"} ,
		{"word":"dévalorisait","word_nosc":"devalorisait","lemma":"dévaloriser","pos":"VER"} ,
		{"word":"dévalorise","word_nosc":"devalorise","lemma":"dévaloriser","pos":"VER"} ,
		{"word":"dévalorisent","word_nosc":"devalorisent","lemma":"dévaloriser","pos":"VER"} ,
		{"word":"dévaloriser","word_nosc":"devaloriser","lemma":"dévaloriser","pos":"VER"} ,
		{"word":"dévalorisez","word_nosc":"devalorisez","lemma":"dévaloriser","pos":"VER"} ,
		{"word":"dévalorisé","word_nosc":"devalorise","lemma":"dévaloriser","pos":"VER"} ,
		{"word":"dévalorisée","word_nosc":"devalorisee","lemma":"dévaloriser","pos":"VER"} ,
		{"word":"dévaluait","word_nosc":"devaluait","lemma":"dévaluer","pos":"VER"} ,
		{"word":"dévalue","word_nosc":"devalue","lemma":"dévaluer","pos":"VER"} ,
		{"word":"dévaluer","word_nosc":"devaluer","lemma":"dévaluer","pos":"VER"} ,
		{"word":"dévaluera","word_nosc":"devaluera","lemma":"dévaluer","pos":"VER"} ,
		{"word":"dévalué","word_nosc":"devalue","lemma":"dévaluer","pos":"VER"} ,
		{"word":"dévaluée","word_nosc":"devaluee","lemma":"dévaluer","pos":"VER"} ,
		{"word":"dévalués","word_nosc":"devalues","lemma":"dévaluer","pos":"VER"} ,
		{"word":"dévalèrent","word_nosc":"devalerent","lemma":"dévaler","pos":"VER"} ,
		{"word":"dévalé","word_nosc":"devale","lemma":"dévaler","pos":"VER"} ,
		{"word":"dévalée","word_nosc":"devalee","lemma":"dévaler","pos":"VER"} ,
		{"word":"dévasta","word_nosc":"devasta","lemma":"dévaster","pos":"VER"} ,
		{"word":"dévastaient","word_nosc":"devastaient","lemma":"dévaster","pos":"VER"} ,
		{"word":"dévastait","word_nosc":"devastait","lemma":"dévaster","pos":"VER"} ,
		{"word":"dévastant","word_nosc":"devastant","lemma":"dévaster","pos":"VER"} ,
		{"word":"dévaste","word_nosc":"devaste","lemma":"dévaster","pos":"VER"} ,
		{"word":"dévastent","word_nosc":"devastent","lemma":"dévaster","pos":"VER"} ,
		{"word":"dévaster","word_nosc":"devaster","lemma":"dévaster","pos":"VER"} ,
		{"word":"dévastera","word_nosc":"devastera","lemma":"dévaster","pos":"VER"} ,
		{"word":"dévasterait","word_nosc":"devasterait","lemma":"dévaster","pos":"VER"} ,
		{"word":"dévasteront","word_nosc":"devasteront","lemma":"dévaster","pos":"VER"} ,
		{"word":"dévastèrent","word_nosc":"devasterent","lemma":"dévaster","pos":"VER"} ,
		{"word":"dévasté","word_nosc":"devaste","lemma":"dévaster","pos":"VER"} ,
		{"word":"dévastée","word_nosc":"devastee","lemma":"dévaster","pos":"VER"} ,
		{"word":"dévastées","word_nosc":"devastees","lemma":"dévaster","pos":"VER"} ,
		{"word":"dévastés","word_nosc":"devastes","lemma":"dévaster","pos":"VER"} ,
		{"word":"développa","word_nosc":"developpa","lemma":"développer","pos":"VER"} ,
		{"word":"développai","word_nosc":"developpai","lemma":"développer","pos":"VER"} ,
		{"word":"développaient","word_nosc":"developpaient","lemma":"développer","pos":"VER"} ,
		{"word":"développais","word_nosc":"developpais","lemma":"développer","pos":"VER"} ,
		{"word":"développait","word_nosc":"developpait","lemma":"développer","pos":"VER"} ,
		{"word":"développant","word_nosc":"developpant","lemma":"développer","pos":"VER"} ,
		{"word":"développe","word_nosc":"developpe","lemma":"développer","pos":"VER"} ,
		{"word":"développent","word_nosc":"developpent","lemma":"développer","pos":"VER"} ,
		{"word":"développer","word_nosc":"developper","lemma":"développer","pos":"VER"} ,
		{"word":"développera","word_nosc":"developpera","lemma":"développer","pos":"VER"} ,
		{"word":"développerai","word_nosc":"developperai","lemma":"développer","pos":"VER"} ,
		{"word":"développeraient","word_nosc":"developperaient","lemma":"développer","pos":"VER"} ,
		{"word":"développerais","word_nosc":"developperais","lemma":"développer","pos":"VER"} ,
		{"word":"développerait","word_nosc":"developperait","lemma":"développer","pos":"VER"} ,
		{"word":"développerez","word_nosc":"developperez","lemma":"développer","pos":"VER"} ,
		{"word":"développeront","word_nosc":"developperont","lemma":"développer","pos":"VER"} ,
		{"word":"développez","word_nosc":"developpez","lemma":"développer","pos":"VER"} ,
		{"word":"développons","word_nosc":"developpons","lemma":"développer","pos":"VER"} ,
		{"word":"développât","word_nosc":"developpat","lemma":"développer","pos":"VER"} ,
		{"word":"développèrent","word_nosc":"developperent","lemma":"développer","pos":"VER"} ,
		{"word":"développé","word_nosc":"developpe","lemma":"développer","pos":"VER"} ,
		{"word":"développée","word_nosc":"developpee","lemma":"développer","pos":"VER"} ,
		{"word":"développées","word_nosc":"developpees","lemma":"développer","pos":"VER"} ,
		{"word":"développés","word_nosc":"developpes","lemma":"développer","pos":"VER"} ,
		{"word":"déventer","word_nosc":"deventer","lemma":"déventer","pos":"VER"} ,
		{"word":"dévergonde","word_nosc":"devergonde","lemma":"dévergonder","pos":"VER"} ,
		{"word":"dévergondent","word_nosc":"devergondent","lemma":"dévergonder","pos":"VER"} ,
		{"word":"dévergonder","word_nosc":"devergonder","lemma":"dévergonder","pos":"VER"} ,
		{"word":"dévergondé","word_nosc":"devergonde","lemma":"dévergonder","pos":"VER"} ,
		{"word":"dévergondée","word_nosc":"devergondee","lemma":"dévergonder","pos":"VER"} ,
		{"word":"dévergondées","word_nosc":"devergondees","lemma":"dévergonder","pos":"VER"} ,
		{"word":"dévergondés","word_nosc":"devergondes","lemma":"dévergonder","pos":"VER"} ,
		{"word":"dévernie","word_nosc":"devernie","lemma":"dévernir","pos":"VER"} ,
		{"word":"dévernies","word_nosc":"devernies","lemma":"dévernir","pos":"VER"} ,
		{"word":"dévernir","word_nosc":"devernir","lemma":"dévernir","pos":"VER"} ,
		{"word":"déverrouilla","word_nosc":"deverrouilla","lemma":"déverrouiller","pos":"VER"} ,
		{"word":"déverrouillait","word_nosc":"deverrouillait","lemma":"déverrouiller","pos":"VER"} ,
		{"word":"déverrouille","word_nosc":"deverrouille","lemma":"déverrouiller","pos":"VER"} ,
		{"word":"déverrouillent","word_nosc":"deverrouillent","lemma":"déverrouiller","pos":"VER"} ,
		{"word":"déverrouiller","word_nosc":"deverrouiller","lemma":"déverrouiller","pos":"VER"} ,
		{"word":"déverrouillez","word_nosc":"deverrouillez","lemma":"déverrouiller","pos":"VER"} ,
		{"word":"déverrouillé","word_nosc":"deverrouille","lemma":"déverrouiller","pos":"VER"} ,
		{"word":"déverrouillée","word_nosc":"deverrouillee","lemma":"déverrouiller","pos":"VER"} ,
		{"word":"déverrouillées","word_nosc":"deverrouillees","lemma":"déverrouiller","pos":"VER"} ,
		{"word":"déversa","word_nosc":"deversa","lemma":"déverser","pos":"VER"} ,
		{"word":"déversaient","word_nosc":"deversaient","lemma":"déverser","pos":"VER"} ,
		{"word":"déversais","word_nosc":"deversais","lemma":"déverser","pos":"VER"} ,
		{"word":"déversait","word_nosc":"deversait","lemma":"déverser","pos":"VER"} ,
		{"word":"déversant","word_nosc":"deversant","lemma":"déverser","pos":"VER"} ,
		{"word":"déverse","word_nosc":"deverse","lemma":"déverser","pos":"VER"} ,
		{"word":"déversent","word_nosc":"deversent","lemma":"déverser","pos":"VER"} ,
		{"word":"déverser","word_nosc":"deverser","lemma":"déverser","pos":"VER"} ,
		{"word":"déversera","word_nosc":"deversera","lemma":"déverser","pos":"VER"} ,
		{"word":"déverserai","word_nosc":"deverserai","lemma":"déverser","pos":"VER"} ,
		{"word":"déverseraient","word_nosc":"deverseraient","lemma":"déverser","pos":"VER"} ,
		{"word":"déverserait","word_nosc":"deverserait","lemma":"déverser","pos":"VER"} ,
		{"word":"déversez","word_nosc":"deversez","lemma":"déverser","pos":"VER"} ,
		{"word":"déversèrent","word_nosc":"deverserent","lemma":"déverser","pos":"VER"} ,
		{"word":"déversé","word_nosc":"deverse","lemma":"déverser","pos":"VER"} ,
		{"word":"déversée","word_nosc":"deversee","lemma":"déverser","pos":"VER"} ,
		{"word":"déversées","word_nosc":"deversees","lemma":"déverser","pos":"VER"} ,
		{"word":"déversés","word_nosc":"deverses","lemma":"déverser","pos":"VER"} ,
		{"word":"dévia","word_nosc":"devia","lemma":"dévier","pos":"VER"} ,
		{"word":"déviai","word_nosc":"deviai","lemma":"dévier","pos":"VER"} ,
		{"word":"déviaient","word_nosc":"deviaient","lemma":"dévier","pos":"VER"} ,
		{"word":"déviait","word_nosc":"deviait","lemma":"dévier","pos":"VER"} ,
		{"word":"déviant","word_nosc":"deviant","lemma":"dévier","pos":"VER"} ,
		{"word":"dévida","word_nosc":"devida","lemma":"dévider","pos":"VER"} ,
		{"word":"dévidais","word_nosc":"devidais","lemma":"dévider","pos":"VER"} ,
		{"word":"dévidait","word_nosc":"devidait","lemma":"dévider","pos":"VER"} ,
		{"word":"dévidant","word_nosc":"devidant","lemma":"dévider","pos":"VER"} ,
		{"word":"dévide","word_nosc":"devide","lemma":"dévider","pos":"VER"} ,
		{"word":"dévident","word_nosc":"devident","lemma":"dévider","pos":"VER"} ,
		{"word":"dévider","word_nosc":"devider","lemma":"dévider","pos":"VER"} ,
		{"word":"déviderait","word_nosc":"deviderait","lemma":"dévider","pos":"VER"} ,
		{"word":"dévidions","word_nosc":"devidions","lemma":"dévider","pos":"VER"} ,
		{"word":"dévidé","word_nosc":"devide","lemma":"dévider","pos":"VER"} ,
		{"word":"dévidée","word_nosc":"devidee","lemma":"dévider","pos":"VER"} ,
		{"word":"dévie","word_nosc":"devie","lemma":"dévier","pos":"VER"} ,
		{"word":"dévient","word_nosc":"devient","lemma":"dévier","pos":"VER"} ,
		{"word":"dévier","word_nosc":"devier","lemma":"dévier","pos":"VER"} ,
		{"word":"déviera","word_nosc":"deviera","lemma":"dévier","pos":"VER"} ,
		{"word":"dévierait","word_nosc":"devierait","lemma":"dévier","pos":"VER"} ,
		{"word":"déviez","word_nosc":"deviez","lemma":"dévier","pos":"VER"} ,
		{"word":"dévions","word_nosc":"devions","lemma":"dévier","pos":"VER"} ,
		{"word":"dévirginiser","word_nosc":"devirginiser","lemma":"dévirginiser","pos":"VER"} ,
		{"word":"dévirginisée","word_nosc":"devirginisee","lemma":"dévirginiser","pos":"VER"} ,
		{"word":"dévirilisant","word_nosc":"devirilisant","lemma":"déviriliser","pos":"VER"} ,
		{"word":"dévirilisé","word_nosc":"devirilise","lemma":"déviriliser","pos":"VER"} ,
		{"word":"dévisage","word_nosc":"devisage","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisagea","word_nosc":"devisagea","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisageai","word_nosc":"devisageai","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisageaient","word_nosc":"devisageaient","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisageais","word_nosc":"devisageais","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisageait","word_nosc":"devisageait","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisageant","word_nosc":"devisageant","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisagent","word_nosc":"devisagent","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisageons","word_nosc":"devisageons","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisager","word_nosc":"devisager","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisageraient","word_nosc":"devisageraient","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisagez","word_nosc":"devisagez","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisageâmes","word_nosc":"devisageames","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisagions","word_nosc":"devisagions","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisagèrent","word_nosc":"devisagerent","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisagé","word_nosc":"devisage","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisagée","word_nosc":"devisagee","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévisagés","word_nosc":"devisages","lemma":"dévisager","pos":"VER"} ,
		{"word":"dévissa","word_nosc":"devissa","lemma":"dévisser","pos":"VER"} ,
		{"word":"dévissaient","word_nosc":"devissaient","lemma":"dévisser","pos":"VER"} ,
		{"word":"dévissait","word_nosc":"devissait","lemma":"dévisser","pos":"VER"} ,
		{"word":"dévissant","word_nosc":"devissant","lemma":"dévisser","pos":"VER"} ,
		{"word":"dévisse","word_nosc":"devisse","lemma":"dévisser","pos":"VER"} ,
		{"word":"dévisser","word_nosc":"devisser","lemma":"dévisser","pos":"VER"} ,
		{"word":"dévissera","word_nosc":"devissera","lemma":"dévisser","pos":"VER"} ,
		{"word":"dévissez","word_nosc":"devissez","lemma":"dévisser","pos":"VER"} ,
		{"word":"dévissé","word_nosc":"devisse","lemma":"dévisser","pos":"VER"} ,
		{"word":"dévissée","word_nosc":"devissee","lemma":"dévisser","pos":"VER"} ,
		{"word":"dévissées","word_nosc":"devissees","lemma":"dévisser","pos":"VER"} ,
		{"word":"dévissés","word_nosc":"devisses","lemma":"dévisser","pos":"VER"} ,
		{"word":"dévitaliser","word_nosc":"devitaliser","lemma":"dévitaliser","pos":"VER"} ,
		{"word":"dévitalisé","word_nosc":"devitalise","lemma":"dévitaliser","pos":"VER"} ,
		{"word":"dévié","word_nosc":"devie","lemma":"dévier","pos":"VER"} ,
		{"word":"déviée","word_nosc":"deviee","lemma":"dévier","pos":"VER"} ,
		{"word":"déviés","word_nosc":"devies","lemma":"dévier","pos":"VER"} ,
		{"word":"dévoie","word_nosc":"devoie","lemma":"dévoyer","pos":"VER"} ,
		{"word":"dévoila","word_nosc":"devoila","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilai","word_nosc":"devoilai","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilaient","word_nosc":"devoilaient","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilais","word_nosc":"devoilais","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilait","word_nosc":"devoilait","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilant","word_nosc":"devoilant","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoile","word_nosc":"devoile","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilent","word_nosc":"devoilent","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoiler","word_nosc":"devoiler","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilera","word_nosc":"devoilera","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilerai","word_nosc":"devoilerai","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilerais","word_nosc":"devoilerais","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilerait","word_nosc":"devoilerait","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilerez","word_nosc":"devoilerez","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoileront","word_nosc":"devoileront","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoiles","word_nosc":"devoiles","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilez","word_nosc":"devoilez","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilons","word_nosc":"devoilons","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilé","word_nosc":"devoile","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilée","word_nosc":"devoilee","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilées","word_nosc":"devoilees","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévoilés","word_nosc":"devoiles","lemma":"dévoiler","pos":"VER"} ,
		{"word":"dévora","word_nosc":"devora","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorai","word_nosc":"devorai","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévoraient","word_nosc":"devoraient","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorais","word_nosc":"devorais","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorait","word_nosc":"devorait","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorant","word_nosc":"devorant","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévore","word_nosc":"devore","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorent","word_nosc":"devorent","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorer","word_nosc":"devorer","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorera","word_nosc":"devorera","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorerai","word_nosc":"devorerai","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévoreraient","word_nosc":"devoreraient","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorerais","word_nosc":"devorerais","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorerait","word_nosc":"devorerait","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorerez","word_nosc":"devorerez","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévoreront","word_nosc":"devoreront","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévores","word_nosc":"devores","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorez","word_nosc":"devorez","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorions","word_nosc":"devorions","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorons","word_nosc":"devorons","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorèrent","word_nosc":"devorerent","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévoré","word_nosc":"devore","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorée","word_nosc":"devoree","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorées","word_nosc":"devorees","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévorés","word_nosc":"devores","lemma":"dévorer","pos":"VER"} ,
		{"word":"dévoua","word_nosc":"devoua","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévouaient","word_nosc":"devouaient","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévouait","word_nosc":"devouait","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévouant","word_nosc":"devouant","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévoue","word_nosc":"devoue","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévouent","word_nosc":"devouent","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévouer","word_nosc":"devouer","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévouera","word_nosc":"devouera","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévouerais","word_nosc":"devouerais","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévoues","word_nosc":"devoues","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévouez","word_nosc":"devouez","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévouât","word_nosc":"devouat","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévoué","word_nosc":"devoue","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévouée","word_nosc":"devouee","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévouées","word_nosc":"devouees","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévoués","word_nosc":"devoues","lemma":"dévouer","pos":"VER"} ,
		{"word":"dévoyait","word_nosc":"devoyait","lemma":"dévoyer","pos":"VER"} ,
		{"word":"dévoyer","word_nosc":"devoyer","lemma":"dévoyer","pos":"VER"} ,
		{"word":"dévoyez","word_nosc":"devoyez","lemma":"dévoyer","pos":"VER"} ,
		{"word":"dévoyé","word_nosc":"devoye","lemma":"dévoyer","pos":"VER"} ,
		{"word":"dévoyée","word_nosc":"devoyee","lemma":"dévoyer","pos":"VER"} ,
		{"word":"dévoyés","word_nosc":"devoyes","lemma":"dévoyer","pos":"VER"} ,
		{"word":"dévêt","word_nosc":"devet","lemma":"dévêtir","pos":"VER"} ,
		{"word":"dévêtaient","word_nosc":"devetaient","lemma":"dévêtir","pos":"VER"} ,
		{"word":"dévêtait","word_nosc":"devetait","lemma":"dévêtir","pos":"VER"} ,
		{"word":"dévêtant","word_nosc":"devetant","lemma":"dévêtir","pos":"VER"} ,
		{"word":"dévêtez","word_nosc":"devetez","lemma":"dévêtir","pos":"VER"} ,
		{"word":"dévêtir","word_nosc":"devetir","lemma":"dévêtir","pos":"VER"} ,
		{"word":"dévêtit","word_nosc":"devetit","lemma":"dévêtir","pos":"VER"} ,
		{"word":"dévêtu","word_nosc":"devetu","lemma":"dévêtir","pos":"VER"} ,
		{"word":"dévêtue","word_nosc":"devetue","lemma":"dévêtir","pos":"VER"} ,
		{"word":"dévêtues","word_nosc":"devetues","lemma":"dévêtir","pos":"VER"} ,
		{"word":"dévêtus","word_nosc":"devetus","lemma":"dévêtir","pos":"VER"} ,
		{"word":"dézingue","word_nosc":"dezingue","lemma":"dézinguer","pos":"VER"} ,
		{"word":"dézinguer","word_nosc":"dezinguer","lemma":"dézinguer","pos":"VER"} ,
		{"word":"dézingués","word_nosc":"dezingues","lemma":"dézinguer","pos":"VER"} ,
		{"word":"déçois","word_nosc":"decois","lemma":"décevoir","pos":"VER"} ,
		{"word":"déçoit","word_nosc":"decoit","lemma":"décevoir","pos":"VER"} ,
		{"word":"déçoive","word_nosc":"decoive","lemma":"décevoir","pos":"VER"} ,
		{"word":"déçoivent","word_nosc":"decoivent","lemma":"décevoir","pos":"VER"} ,
		{"word":"déçoives","word_nosc":"decoives","lemma":"décevoir","pos":"VER"} ,
		{"word":"déçu","word_nosc":"decu","lemma":"décevoir","pos":"VER"} ,
		{"word":"déçue","word_nosc":"decue","lemma":"décevoir","pos":"VER"} ,
		{"word":"déçues","word_nosc":"decues","lemma":"décevoir","pos":"VER"} ,
		{"word":"déçurent","word_nosc":"decurent","lemma":"décevoir","pos":"VER"} ,
		{"word":"déçus","word_nosc":"decus","lemma":"décevoir","pos":"VER"} ,
		{"word":"déçut","word_nosc":"decut","lemma":"décevoir","pos":"VER"} ,
		{"word":"déçûmes","word_nosc":"decumes","lemma":"décevoir","pos":"VER"} ,
		{"word":"dîmes","word_nosc":"dimes","lemma":"dire","pos":"VER"} ,
		{"word":"dîna","word_nosc":"dina","lemma":"dîner","pos":"VER"} ,
		{"word":"dînai","word_nosc":"dinai","lemma":"dîner","pos":"VER"} ,
		{"word":"dînaient","word_nosc":"dinaient","lemma":"dîner","pos":"VER"} ,
		{"word":"dînais","word_nosc":"dinais","lemma":"dîner","pos":"VER"} ,
		{"word":"dînait","word_nosc":"dinait","lemma":"dîner","pos":"VER"} ,
		{"word":"dînant","word_nosc":"dinant","lemma":"dîner","pos":"VER"} ,
		{"word":"dîne","word_nosc":"dine","lemma":"dîner","pos":"VER"} ,
		{"word":"dînent","word_nosc":"dinent","lemma":"dîner","pos":"VER"} ,
		{"word":"dîner","word_nosc":"diner","lemma":"dîner","pos":"VER"} ,
		{"word":"dînera","word_nosc":"dinera","lemma":"dîner","pos":"VER"} ,
		{"word":"dînerai","word_nosc":"dinerai","lemma":"dîner","pos":"VER"} ,
		{"word":"dîneraient","word_nosc":"dineraient","lemma":"dîner","pos":"VER"} ,
		{"word":"dînerais","word_nosc":"dinerais","lemma":"dîner","pos":"VER"} ,
		{"word":"dînerait","word_nosc":"dinerait","lemma":"dîner","pos":"VER"} ,
		{"word":"dîneras","word_nosc":"dineras","lemma":"dîner","pos":"VER"} ,
		{"word":"dînerez","word_nosc":"dinerez","lemma":"dîner","pos":"VER"} ,
		{"word":"dîneriez","word_nosc":"dineriez","lemma":"dîner","pos":"VER"} ,
		{"word":"dînerions","word_nosc":"dinerions","lemma":"dîner","pos":"VER"} ,
		{"word":"dînerons","word_nosc":"dinerons","lemma":"dîner","pos":"VER"} ,
		{"word":"dîneront","word_nosc":"dineront","lemma":"dîner","pos":"VER"} ,
		{"word":"dînes","word_nosc":"dines","lemma":"dîner","pos":"VER"} ,
		{"word":"dînez","word_nosc":"dinez","lemma":"dîner","pos":"VER"} ,
		{"word":"dîniez","word_nosc":"diniez","lemma":"dîner","pos":"VER"} ,
		{"word":"dînions","word_nosc":"dinions","lemma":"dîner","pos":"VER"} ,
		{"word":"dînons","word_nosc":"dinons","lemma":"dîner","pos":"VER"} ,
		{"word":"dînâmes","word_nosc":"dinames","lemma":"dîner","pos":"VER"} ,
		{"word":"dînât","word_nosc":"dinat","lemma":"dîner","pos":"VER"} ,
		{"word":"dînèrent","word_nosc":"dinerent","lemma":"dîner","pos":"VER"} ,
		{"word":"dîné","word_nosc":"dine","lemma":"dîner","pos":"VER"} ,
		{"word":"dît","word_nosc":"dit","lemma":"dire","pos":"VER"} ,
		{"word":"dîtes","word_nosc":"dites","lemma":"dire","pos":"VER"} ,
		{"word":"dû","word_nosc":"du","lemma":"devoir","pos":"VER"} ,
		{"word":"dûmes","word_nosc":"dumes","lemma":"devoir","pos":"VER"} ,
		{"word":"dût","word_nosc":"dut","lemma":"devoir","pos":"VER"} ,
		{"word":"ecchymosé","word_nosc":"ecchymose","lemma":"ecchymoser","pos":"VER"} ,
		{"word":"efface","word_nosc":"efface","lemma":"effacer","pos":"VER"} ,
		{"word":"effacent","word_nosc":"effacent","lemma":"effacer","pos":"VER"} ,
		{"word":"effacer","word_nosc":"effacer","lemma":"effacer","pos":"VER"} ,
		{"word":"effacera","word_nosc":"effacera","lemma":"effacer","pos":"VER"} ,
		{"word":"effacerai","word_nosc":"effacerai","lemma":"effacer","pos":"VER"} ,
		{"word":"effaceraient","word_nosc":"effaceraient","lemma":"effacer","pos":"VER"} ,
		{"word":"effacerais","word_nosc":"effacerais","lemma":"effacer","pos":"VER"} ,
		{"word":"effacerait","word_nosc":"effacerait","lemma":"effacer","pos":"VER"} ,
		{"word":"effaceras","word_nosc":"effaceras","lemma":"effacer","pos":"VER"} ,
		{"word":"effacerez","word_nosc":"effacerez","lemma":"effacer","pos":"VER"} ,
		{"word":"effacerions","word_nosc":"effacerions","lemma":"effacer","pos":"VER"} ,
		{"word":"effacerons","word_nosc":"effacerons","lemma":"effacer","pos":"VER"} ,
		{"word":"effaceront","word_nosc":"effaceront","lemma":"effacer","pos":"VER"} ,
		{"word":"effaces","word_nosc":"effaces","lemma":"effacer","pos":"VER"} ,
		{"word":"effacez","word_nosc":"effacez","lemma":"effacer","pos":"VER"} ,
		{"word":"effaciez","word_nosc":"effaciez","lemma":"effacer","pos":"VER"} ,
		{"word":"effacions","word_nosc":"effacions","lemma":"effacer","pos":"VER"} ,
		{"word":"effacèrent","word_nosc":"effacerent","lemma":"effacer","pos":"VER"} ,
		{"word":"effacé","word_nosc":"efface","lemma":"effacer","pos":"VER"} ,
		{"word":"effacée","word_nosc":"effacee","lemma":"effacer","pos":"VER"} ,
		{"word":"effacées","word_nosc":"effacees","lemma":"effacer","pos":"VER"} ,
		{"word":"effacés","word_nosc":"effaces","lemma":"effacer","pos":"VER"} ,
		{"word":"effara","word_nosc":"effara","lemma":"effarer","pos":"VER"} ,
		{"word":"effaraient","word_nosc":"effaraient","lemma":"effarer","pos":"VER"} ,
		{"word":"effarait","word_nosc":"effarait","lemma":"effarer","pos":"VER"} ,
		{"word":"effarant","word_nosc":"effarant","lemma":"effarer","pos":"VER"} ,
		{"word":"effare","word_nosc":"effare","lemma":"effarer","pos":"VER"} ,
		{"word":"effarer","word_nosc":"effarer","lemma":"effarer","pos":"VER"} ,
		{"word":"effaroucha","word_nosc":"effaroucha","lemma":"effaroucher","pos":"VER"} ,
		{"word":"effarouchaient","word_nosc":"effarouchaient","lemma":"effaroucher","pos":"VER"} ,
		{"word":"effarouchait","word_nosc":"effarouchait","lemma":"effaroucher","pos":"VER"} ,
		{"word":"effarouchant","word_nosc":"effarouchant","lemma":"effaroucher","pos":"VER"} ,
		{"word":"effarouche","word_nosc":"effarouche","lemma":"effaroucher","pos":"VER"} ,
		{"word":"effarouchent","word_nosc":"effarouchent","lemma":"effaroucher","pos":"VER"} ,
		{"word":"effaroucher","word_nosc":"effaroucher","lemma":"effaroucher","pos":"VER"} ,
		{"word":"effarouches","word_nosc":"effarouches","lemma":"effaroucher","pos":"VER"} ,
		{"word":"effarouchâmes","word_nosc":"effarouchames","lemma":"effaroucher","pos":"VER"} ,
		{"word":"effarouchât","word_nosc":"effarouchat","lemma":"effaroucher","pos":"VER"} ,
		{"word":"effarouché","word_nosc":"effarouche","lemma":"effaroucher","pos":"VER"} ,
		{"word":"effarouchée","word_nosc":"effarouchee","lemma":"effaroucher","pos":"VER"} ,
		{"word":"effarouchés","word_nosc":"effarouches","lemma":"effaroucher","pos":"VER"} ,
		{"word":"effarèrent","word_nosc":"effarerent","lemma":"effarer","pos":"VER"} ,
		{"word":"effaré","word_nosc":"effare","lemma":"effarer","pos":"VER"} ,
		{"word":"effarée","word_nosc":"effaree","lemma":"effarer","pos":"VER"} ,
		{"word":"effarés","word_nosc":"effares","lemma":"effarer","pos":"VER"} ,
		{"word":"effaça","word_nosc":"effaca","lemma":"effacer","pos":"VER"} ,
		{"word":"effaçai","word_nosc":"effacai","lemma":"effacer","pos":"VER"} ,
		{"word":"effaçaient","word_nosc":"effacaient","lemma":"effacer","pos":"VER"} ,
		{"word":"effaçais","word_nosc":"effacais","lemma":"effacer","pos":"VER"} ,
		{"word":"effaçait","word_nosc":"effacait","lemma":"effacer","pos":"VER"} ,
		{"word":"effaçant","word_nosc":"effacant","lemma":"effacer","pos":"VER"} ,
		{"word":"effaçassent","word_nosc":"effacassent","lemma":"effacer","pos":"VER"} ,
		{"word":"effaçons","word_nosc":"effacons","lemma":"effacer","pos":"VER"} ,
		{"word":"effaçât","word_nosc":"effacat","lemma":"effacer","pos":"VER"} ,
		{"word":"effectua","word_nosc":"effectua","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuai","word_nosc":"effectuai","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuaient","word_nosc":"effectuaient","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuais","word_nosc":"effectuais","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuait","word_nosc":"effectuait","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuant","word_nosc":"effectuant","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectue","word_nosc":"effectue","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuent","word_nosc":"effectuent","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuer","word_nosc":"effectuer","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuera","word_nosc":"effectuera","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuerai","word_nosc":"effectuerai","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuerait","word_nosc":"effectuerait","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuerons","word_nosc":"effectuerons","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectues","word_nosc":"effectues","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuez","word_nosc":"effectuez","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuions","word_nosc":"effectuions","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuons","word_nosc":"effectuons","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectué","word_nosc":"effectue","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuée","word_nosc":"effectuee","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectuées","word_nosc":"effectuees","lemma":"effectuer","pos":"VER"} ,
		{"word":"effectués","word_nosc":"effectues","lemma":"effectuer","pos":"VER"} ,
		{"word":"effeuilla","word_nosc":"effeuilla","lemma":"effeuiller","pos":"VER"} ,
		{"word":"effeuillaient","word_nosc":"effeuillaient","lemma":"effeuiller","pos":"VER"} ,
		{"word":"effeuillait","word_nosc":"effeuillait","lemma":"effeuiller","pos":"VER"} ,
		{"word":"effeuillant","word_nosc":"effeuillant","lemma":"effeuiller","pos":"VER"} ,
		{"word":"effeuille","word_nosc":"effeuille","lemma":"effeuiller","pos":"VER"} ,
		{"word":"effeuillent","word_nosc":"effeuillent","lemma":"effeuiller","pos":"VER"} ,
		{"word":"effeuiller","word_nosc":"effeuiller","lemma":"effeuiller","pos":"VER"} ,
		{"word":"effeuilleras","word_nosc":"effeuilleras","lemma":"effeuiller","pos":"VER"} ,
		{"word":"effeuillez","word_nosc":"effeuillez","lemma":"effeuiller","pos":"VER"} ,
		{"word":"effeuillèrent","word_nosc":"effeuillerent","lemma":"effeuiller","pos":"VER"} ,
		{"word":"effeuillé","word_nosc":"effeuille","lemma":"effeuiller","pos":"VER"} ,
		{"word":"effeuillée","word_nosc":"effeuillee","lemma":"effeuiller","pos":"VER"} ,
		{"word":"effeuillés","word_nosc":"effeuilles","lemma":"effeuiller","pos":"VER"} ,
		{"word":"effila","word_nosc":"effila","lemma":"effiler","pos":"VER"} ,
		{"word":"effilaient","word_nosc":"effilaient","lemma":"effiler","pos":"VER"} ,
		{"word":"effilait","word_nosc":"effilait","lemma":"effiler","pos":"VER"} ,
		{"word":"effilant","word_nosc":"effilant","lemma":"effiler","pos":"VER"} ,
		{"word":"effile","word_nosc":"effile","lemma":"effiler","pos":"VER"} ,
		{"word":"effilent","word_nosc":"effilent","lemma":"effiler","pos":"VER"} ,
		{"word":"effiler","word_nosc":"effiler","lemma":"effiler","pos":"VER"} ,
		{"word":"effilochaient","word_nosc":"effilochaient","lemma":"effilocher","pos":"VER"} ,
		{"word":"effilochait","word_nosc":"effilochait","lemma":"effilocher","pos":"VER"} ,
		{"word":"effilochant","word_nosc":"effilochant","lemma":"effilocher","pos":"VER"} ,
		{"word":"effiloche","word_nosc":"effiloche","lemma":"effilocher","pos":"VER"} ,
		{"word":"effilochent","word_nosc":"effilochent","lemma":"effilocher","pos":"VER"} ,
		{"word":"effilocher","word_nosc":"effilocher","lemma":"effilocher","pos":"VER"} ,
		{"word":"effilochera","word_nosc":"effilochera","lemma":"effilocher","pos":"VER"} ,
		{"word":"effilochèrent","word_nosc":"effilocherent","lemma":"effilocher","pos":"VER"} ,
		{"word":"effiloché","word_nosc":"effiloche","lemma":"effilocher","pos":"VER"} ,
		{"word":"effilochée","word_nosc":"effilochee","lemma":"effilocher","pos":"VER"} ,
		{"word":"effilochées","word_nosc":"effilochees","lemma":"effilocher","pos":"VER"} ,
		{"word":"effilochés","word_nosc":"effiloches","lemma":"effilocher","pos":"VER"} ,
		{"word":"effilé","word_nosc":"effile","lemma":"effiler","pos":"VER"} ,
		{"word":"effilée","word_nosc":"effilee","lemma":"effiler","pos":"VER"} ,
		{"word":"effilées","word_nosc":"effilees","lemma":"effiler","pos":"VER"} ,
		{"word":"effilés","word_nosc":"effiles","lemma":"effiler","pos":"VER"} ,
		{"word":"efflanqué","word_nosc":"efflanque","lemma":"efflanquer","pos":"VER"} ,
		{"word":"efflanquée","word_nosc":"efflanquee","lemma":"efflanquer","pos":"VER"} ,
		{"word":"efflanquées","word_nosc":"efflanquees","lemma":"efflanquer","pos":"VER"} ,
		{"word":"efflanqués","word_nosc":"efflanques","lemma":"efflanquer","pos":"VER"} ,
		{"word":"effleura","word_nosc":"effleura","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleurai","word_nosc":"effleurai","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleuraient","word_nosc":"effleuraient","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleurais","word_nosc":"effleurais","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleurait","word_nosc":"effleurait","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleurant","word_nosc":"effleurant","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleure","word_nosc":"effleure","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleurent","word_nosc":"effleurent","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleurer","word_nosc":"effleurer","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleurera","word_nosc":"effleurera","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleurerait","word_nosc":"effleurerait","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleureront","word_nosc":"effleureront","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleures","word_nosc":"effleures","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleurez","word_nosc":"effleurez","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleurâmes","word_nosc":"effleurames","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleurât","word_nosc":"effleurat","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleurèrent","word_nosc":"effleurerent","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleuré","word_nosc":"effleure","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleurée","word_nosc":"effleuree","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleurées","word_nosc":"effleurees","lemma":"effleurer","pos":"VER"} ,
		{"word":"effleurés","word_nosc":"effleures","lemma":"effleurer","pos":"VER"} ,
		{"word":"effondra","word_nosc":"effondra","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondrai","word_nosc":"effondrai","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondraient","word_nosc":"effondraient","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondrais","word_nosc":"effondrais","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondrait","word_nosc":"effondrait","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondrant","word_nosc":"effondrant","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondre","word_nosc":"effondre","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondrent","word_nosc":"effondrent","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondrer","word_nosc":"effondrer","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondrera","word_nosc":"effondrera","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondreraient","word_nosc":"effondreraient","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondrerais","word_nosc":"effondrerais","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondrerait","word_nosc":"effondrerait","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondreront","word_nosc":"effondreront","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondrez","word_nosc":"effondrez","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondrât","word_nosc":"effondrat","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondrèrent","word_nosc":"effondrerent","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondré","word_nosc":"effondre","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondrée","word_nosc":"effondree","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondrées","word_nosc":"effondrees","lemma":"effondrer","pos":"VER"} ,
		{"word":"effondrés","word_nosc":"effondres","lemma":"effondrer","pos":"VER"} ,
		{"word":"efforce","word_nosc":"efforce","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforcent","word_nosc":"efforcent","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforcer","word_nosc":"efforcer","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforcera","word_nosc":"efforcera","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforcerai","word_nosc":"efforcerai","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforceraient","word_nosc":"efforceraient","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforcerais","word_nosc":"efforcerais","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforcerait","word_nosc":"efforcerait","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforcerons","word_nosc":"efforcerons","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforces","word_nosc":"efforces","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforcez","word_nosc":"efforcez","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforcions","word_nosc":"efforcions","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforcèrent","word_nosc":"efforcerent","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforcé","word_nosc":"efforce","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforcée","word_nosc":"efforcee","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforcés","word_nosc":"efforces","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforça","word_nosc":"efforca","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforçai","word_nosc":"efforcai","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforçaient","word_nosc":"efforcaient","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforçais","word_nosc":"efforcais","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforçait","word_nosc":"efforcait","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforçant","word_nosc":"efforcant","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforçons","word_nosc":"efforcons","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforçâmes","word_nosc":"efforcames","lemma":"efforcer","pos":"VER"} ,
		{"word":"efforçât","word_nosc":"efforcat","lemma":"efforcer","pos":"VER"} ,
		{"word":"effraie","word_nosc":"effraie","lemma":"effrayer","pos":"VER"} ,
		{"word":"effraient","word_nosc":"effraient","lemma":"effrayer","pos":"VER"} ,
		{"word":"effraiera","word_nosc":"effraiera","lemma":"effrayer","pos":"VER"} ,
		{"word":"effraieraient","word_nosc":"effraieraient","lemma":"effrayer","pos":"VER"} ,
		{"word":"effraierait","word_nosc":"effraierait","lemma":"effrayer","pos":"VER"} ,
		{"word":"effraieras","word_nosc":"effraieras","lemma":"effrayer","pos":"VER"} ,
		{"word":"effraierez","word_nosc":"effraierez","lemma":"effrayer","pos":"VER"} ,
		{"word":"effraies","word_nosc":"effraies","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrange","word_nosc":"effrange","lemma":"effranger","pos":"VER"} ,
		{"word":"effrangeait","word_nosc":"effrangeait","lemma":"effranger","pos":"VER"} ,
		{"word":"effrangent","word_nosc":"effrangent","lemma":"effranger","pos":"VER"} ,
		{"word":"effrangé","word_nosc":"effrange","lemma":"effranger","pos":"VER"} ,
		{"word":"effrangée","word_nosc":"effrangee","lemma":"effranger","pos":"VER"} ,
		{"word":"effrangées","word_nosc":"effrangees","lemma":"effranger","pos":"VER"} ,
		{"word":"effrangés","word_nosc":"effranges","lemma":"effranger","pos":"VER"} ,
		{"word":"effraya","word_nosc":"effraya","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayaient","word_nosc":"effrayaient","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayais","word_nosc":"effrayais","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayait","word_nosc":"effrayait","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayant","word_nosc":"effrayant","lemma":"effrayer","pos":"VER"} ,
		{"word":"effraye","word_nosc":"effraye","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayent","word_nosc":"effrayent","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayer","word_nosc":"effrayer","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayerais","word_nosc":"effrayerais","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayerait","word_nosc":"effrayerait","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayeras","word_nosc":"effrayeras","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayez","word_nosc":"effrayez","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayons","word_nosc":"effrayons","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayèrent","word_nosc":"effrayerent","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayé","word_nosc":"effraye","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayée","word_nosc":"effrayee","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayées","word_nosc":"effrayees","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrayés","word_nosc":"effrayes","lemma":"effrayer","pos":"VER"} ,
		{"word":"effrita","word_nosc":"effrita","lemma":"effriter","pos":"VER"} ,
		{"word":"effritaient","word_nosc":"effritaient","lemma":"effriter","pos":"VER"} ,
		{"word":"effritait","word_nosc":"effritait","lemma":"effriter","pos":"VER"} ,
		{"word":"effritant","word_nosc":"effritant","lemma":"effriter","pos":"VER"} ,
		{"word":"effrite","word_nosc":"effrite","lemma":"effriter","pos":"VER"} ,
		{"word":"effritent","word_nosc":"effritent","lemma":"effriter","pos":"VER"} ,
		{"word":"effriter","word_nosc":"effriter","lemma":"effriter","pos":"VER"} ,
		{"word":"effriterait","word_nosc":"effriterait","lemma":"effriter","pos":"VER"} ,
		{"word":"effrité","word_nosc":"effrite","lemma":"effriter","pos":"VER"} ,
		{"word":"effritée","word_nosc":"effritee","lemma":"effriter","pos":"VER"} ,
		{"word":"effritées","word_nosc":"effritees","lemma":"effriter","pos":"VER"} ,
		{"word":"effrités","word_nosc":"effrites","lemma":"effriter","pos":"VER"} ,
		{"word":"efféminait","word_nosc":"effeminait","lemma":"efféminer","pos":"VER"} ,
		{"word":"efféminé","word_nosc":"effemine","lemma":"efféminer","pos":"VER"} ,
		{"word":"efféminée","word_nosc":"effeminee","lemma":"efféminer","pos":"VER"} ,
		{"word":"efféminés","word_nosc":"effemines","lemma":"efféminer","pos":"VER"} ,
		{"word":"embabouiner","word_nosc":"embabouiner","lemma":"embabouiner","pos":"VER"} ,
		{"word":"emballa","word_nosc":"emballa","lemma":"emballer","pos":"VER"} ,
		{"word":"emballai","word_nosc":"emballai","lemma":"emballer","pos":"VER"} ,
		{"word":"emballaient","word_nosc":"emballaient","lemma":"emballer","pos":"VER"} ,
		{"word":"emballais","word_nosc":"emballais","lemma":"emballer","pos":"VER"} ,
		{"word":"emballait","word_nosc":"emballait","lemma":"emballer","pos":"VER"} ,
		{"word":"emballant","word_nosc":"emballant","lemma":"emballer","pos":"VER"} ,
		{"word":"emballe","word_nosc":"emballe","lemma":"emballer","pos":"VER"} ,
		{"word":"emballent","word_nosc":"emballent","lemma":"emballer","pos":"VER"} ,
		{"word":"emballer","word_nosc":"emballer","lemma":"emballer","pos":"VER"} ,
		{"word":"emballera","word_nosc":"emballera","lemma":"emballer","pos":"VER"} ,
		{"word":"emballeraient","word_nosc":"emballeraient","lemma":"emballer","pos":"VER"} ,
		{"word":"emballerait","word_nosc":"emballerait","lemma":"emballer","pos":"VER"} ,
		{"word":"emballeras","word_nosc":"emballeras","lemma":"emballer","pos":"VER"} ,
		{"word":"emballerons","word_nosc":"emballerons","lemma":"emballer","pos":"VER"} ,
		{"word":"emballes","word_nosc":"emballes","lemma":"emballer","pos":"VER"} ,
		{"word":"emballez","word_nosc":"emballez","lemma":"emballer","pos":"VER"} ,
		{"word":"emballons","word_nosc":"emballons","lemma":"emballer","pos":"VER"} ,
		{"word":"emballât","word_nosc":"emballat","lemma":"emballer","pos":"VER"} ,
		{"word":"emballèrent","word_nosc":"emballerent","lemma":"emballer","pos":"VER"} ,
		{"word":"emballé","word_nosc":"emballe","lemma":"emballer","pos":"VER"} ,
		{"word":"emballée","word_nosc":"emballee","lemma":"emballer","pos":"VER"} ,
		{"word":"emballées","word_nosc":"emballees","lemma":"emballer","pos":"VER"} ,
		{"word":"emballés","word_nosc":"emballes","lemma":"emballer","pos":"VER"} ,
		{"word":"embaluchonnés","word_nosc":"embaluchonnes","lemma":"embaluchonner","pos":"VER"} ,
		{"word":"embarbouilla","word_nosc":"embarbouilla","lemma":"embarbouiller","pos":"VER"} ,
		{"word":"embarbouillé","word_nosc":"embarbouille","lemma":"embarbouiller","pos":"VER"} ,
		{"word":"embarqua","word_nosc":"embarqua","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquai","word_nosc":"embarquai","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquaient","word_nosc":"embarquaient","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquais","word_nosc":"embarquais","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquait","word_nosc":"embarquait","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquant","word_nosc":"embarquant","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarque","word_nosc":"embarque","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquent","word_nosc":"embarquent","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquer","word_nosc":"embarquer","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquera","word_nosc":"embarquera","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquerai","word_nosc":"embarquerai","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarqueraient","word_nosc":"embarqueraient","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquerais","word_nosc":"embarquerais","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquerait","word_nosc":"embarquerait","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarqueras","word_nosc":"embarqueras","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquerez","word_nosc":"embarquerez","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquerions","word_nosc":"embarquerions","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarqueront","word_nosc":"embarqueront","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquez","word_nosc":"embarquez","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquiez","word_nosc":"embarquiez","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquions","word_nosc":"embarquions","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquons","word_nosc":"embarquons","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquâmes","word_nosc":"embarquames","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquèrent","word_nosc":"embarquerent","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarqué","word_nosc":"embarque","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquée","word_nosc":"embarquee","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarquées","word_nosc":"embarquees","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarqués","word_nosc":"embarques","lemma":"embarquer","pos":"VER"} ,
		{"word":"embarras","word_nosc":"embarras","lemma":"embarrer","pos":"VER"} ,
		{"word":"embarrassa","word_nosc":"embarrassa","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrassai","word_nosc":"embarrassai","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrassaient","word_nosc":"embarrassaient","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrassais","word_nosc":"embarrassais","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrassait","word_nosc":"embarrassait","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrassant","word_nosc":"embarrassant","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrasse","word_nosc":"embarrasse","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrassent","word_nosc":"embarrassent","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrasser","word_nosc":"embarrasser","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrasserai","word_nosc":"embarrasserai","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrasseraient","word_nosc":"embarrasseraient","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrasses","word_nosc":"embarrasses","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrassez","word_nosc":"embarrassez","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrassions","word_nosc":"embarrassions","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrassons","word_nosc":"embarrassons","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrassèrent","word_nosc":"embarrasserent","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrassé","word_nosc":"embarrasse","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrassée","word_nosc":"embarrassee","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrassées","word_nosc":"embarrassees","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embarrassés","word_nosc":"embarrasses","lemma":"embarrasser","pos":"VER"} ,
		{"word":"embastiller","word_nosc":"embastiller","lemma":"embastiller","pos":"VER"} ,
		{"word":"embastillé","word_nosc":"embastille","lemma":"embastiller","pos":"VER"} ,
		{"word":"embastillée","word_nosc":"embastillee","lemma":"embastiller","pos":"VER"} ,
		{"word":"embastillés","word_nosc":"embastilles","lemma":"embastiller","pos":"VER"} ,
		{"word":"embats","word_nosc":"embats","lemma":"embattre","pos":"VER"} ,
		{"word":"embaucha","word_nosc":"embaucha","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauchaient","word_nosc":"embauchaient","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauchais","word_nosc":"embauchais","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauchait","word_nosc":"embauchait","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauchant","word_nosc":"embauchant","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauche","word_nosc":"embauche","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauchent","word_nosc":"embauchent","lemma":"embaucher","pos":"VER"} ,
		{"word":"embaucher","word_nosc":"embaucher","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauchera","word_nosc":"embauchera","lemma":"embaucher","pos":"VER"} ,
		{"word":"embaucherai","word_nosc":"embaucherai","lemma":"embaucher","pos":"VER"} ,
		{"word":"embaucherais","word_nosc":"embaucherais","lemma":"embaucher","pos":"VER"} ,
		{"word":"embaucherait","word_nosc":"embaucherait","lemma":"embaucher","pos":"VER"} ,
		{"word":"embaucheront","word_nosc":"embaucheront","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauches","word_nosc":"embauches","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauchez","word_nosc":"embauchez","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauchons","word_nosc":"embauchons","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauchât","word_nosc":"embauchat","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauché","word_nosc":"embauche","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauchée","word_nosc":"embauchee","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauchées","word_nosc":"embauchees","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauchés","word_nosc":"embauches","lemma":"embaucher","pos":"VER"} ,
		{"word":"embauma","word_nosc":"embauma","lemma":"embaumer","pos":"VER"} ,
		{"word":"embaumaient","word_nosc":"embaumaient","lemma":"embaumer","pos":"VER"} ,
		{"word":"embaumait","word_nosc":"embaumait","lemma":"embaumer","pos":"VER"} ,
		{"word":"embaume","word_nosc":"embaume","lemma":"embaumer","pos":"VER"} ,
		{"word":"embaument","word_nosc":"embaument","lemma":"embaumer","pos":"VER"} ,
		{"word":"embaumer","word_nosc":"embaumer","lemma":"embaumer","pos":"VER"} ,
		{"word":"embaumerait","word_nosc":"embaumerait","lemma":"embaumer","pos":"VER"} ,
		{"word":"embaumez","word_nosc":"embaumez","lemma":"embaumer","pos":"VER"} ,
		{"word":"embaumions","word_nosc":"embaumions","lemma":"embaumer","pos":"VER"} ,
		{"word":"embaumé","word_nosc":"embaume","lemma":"embaumer","pos":"VER"} ,
		{"word":"embaumée","word_nosc":"embaumee","lemma":"embaumer","pos":"VER"} ,
		{"word":"embaumées","word_nosc":"embaumees","lemma":"embaumer","pos":"VER"} ,
		{"word":"embaumés","word_nosc":"embaumes","lemma":"embaumer","pos":"VER"} ,
		{"word":"embelli","word_nosc":"embelli","lemma":"embellir","pos":"VER"} ,
		{"word":"embellie","word_nosc":"embellie","lemma":"embellir","pos":"VER"} ,
		{"word":"embellies","word_nosc":"embellies","lemma":"embellir","pos":"VER"} ,
		{"word":"embellir","word_nosc":"embellir","lemma":"embellir","pos":"VER"} ,
		{"word":"embellira","word_nosc":"embellira","lemma":"embellir","pos":"VER"} ,
		{"word":"embellirai","word_nosc":"embellirai","lemma":"embellir","pos":"VER"} ,
		{"word":"embellirait","word_nosc":"embellirait","lemma":"embellir","pos":"VER"} ,
		{"word":"embellis","word_nosc":"embellis","lemma":"embellir","pos":"VER"} ,
		{"word":"embellissaient","word_nosc":"embellissaient","lemma":"embellir","pos":"VER"} ,
		{"word":"embellissais","word_nosc":"embellissais","lemma":"embellir","pos":"VER"} ,
		{"word":"embellissait","word_nosc":"embellissait","lemma":"embellir","pos":"VER"} ,
		{"word":"embellissant","word_nosc":"embellissant","lemma":"embellir","pos":"VER"} ,
		{"word":"embellissent","word_nosc":"embellissent","lemma":"embellir","pos":"VER"} ,
		{"word":"embellissez","word_nosc":"embellissez","lemma":"embellir","pos":"VER"} ,
		{"word":"embellissons","word_nosc":"embellissons","lemma":"embellir","pos":"VER"} ,
		{"word":"embellit","word_nosc":"embellit","lemma":"embellir","pos":"VER"} ,
		{"word":"emberlificotaient","word_nosc":"emberlificotaient","lemma":"emberlificoter","pos":"VER"} ,
		{"word":"emberlificotais","word_nosc":"emberlificotais","lemma":"emberlificoter","pos":"VER"} ,
		{"word":"emberlificote","word_nosc":"emberlificote","lemma":"emberlificoter","pos":"VER"} ,
		{"word":"emberlificoter","word_nosc":"emberlificoter","lemma":"emberlificoter","pos":"VER"} ,
		{"word":"emberlificoté","word_nosc":"emberlificote","lemma":"emberlificoter","pos":"VER"} ,
		{"word":"emberlificotée","word_nosc":"emberlificotee","lemma":"emberlificoter","pos":"VER"} ,
		{"word":"emberlificotées","word_nosc":"emberlificotees","lemma":"emberlificoter","pos":"VER"} ,
		{"word":"emberlucoquer","word_nosc":"emberlucoquer","lemma":"emberlucoquer","pos":"VER"} ,
		{"word":"emblaver","word_nosc":"emblaver","lemma":"emblaver","pos":"VER"} ,
		{"word":"emblavé","word_nosc":"emblave","lemma":"emblaver","pos":"VER"} ,
		{"word":"emblavée","word_nosc":"emblavee","lemma":"emblaver","pos":"VER"} ,
		{"word":"embobeliner","word_nosc":"embobeliner","lemma":"embobeliner","pos":"VER"} ,
		{"word":"embobinant","word_nosc":"embobinant","lemma":"embobiner","pos":"VER"} ,
		{"word":"embobine","word_nosc":"embobine","lemma":"embobiner","pos":"VER"} ,
		{"word":"embobiner","word_nosc":"embobiner","lemma":"embobiner","pos":"VER"} ,
		{"word":"embobinez","word_nosc":"embobinez","lemma":"embobiner","pos":"VER"} ,
		{"word":"embobiné","word_nosc":"embobine","lemma":"embobiner","pos":"VER"} ,
		{"word":"embobinée","word_nosc":"embobinee","lemma":"embobiner","pos":"VER"} ,
		{"word":"embobinées","word_nosc":"embobinees","lemma":"embobiner","pos":"VER"} ,
		{"word":"embobinés","word_nosc":"embobines","lemma":"embobiner","pos":"VER"} ,
		{"word":"embosser","word_nosc":"embosser","lemma":"embosser","pos":"VER"} ,
		{"word":"embossé","word_nosc":"embosse","lemma":"embosser","pos":"VER"} ,
		{"word":"embossée","word_nosc":"embossee","lemma":"embosser","pos":"VER"} ,
		{"word":"embossés","word_nosc":"embosses","lemma":"embosser","pos":"VER"} ,
		{"word":"emboucanent","word_nosc":"emboucanent","lemma":"emboucaner","pos":"VER"} ,
		{"word":"emboucha","word_nosc":"emboucha","lemma":"emboucher","pos":"VER"} ,
		{"word":"embouchant","word_nosc":"embouchant","lemma":"emboucher","pos":"VER"} ,
		{"word":"embouche","word_nosc":"embouche","lemma":"emboucher","pos":"VER"} ,
		{"word":"emboucher","word_nosc":"emboucher","lemma":"emboucher","pos":"VER"} ,
		{"word":"emboucherait","word_nosc":"emboucherait","lemma":"emboucher","pos":"VER"} ,
		{"word":"embouché","word_nosc":"embouche","lemma":"emboucher","pos":"VER"} ,
		{"word":"embouquer","word_nosc":"embouquer","lemma":"embouquer","pos":"VER"} ,
		{"word":"embouqué","word_nosc":"embouque","lemma":"embouquer","pos":"VER"} ,
		{"word":"embourbaient","word_nosc":"embourbaient","lemma":"embourber","pos":"VER"} ,
		{"word":"embourbait","word_nosc":"embourbait","lemma":"embourber","pos":"VER"} ,
		{"word":"embourbe","word_nosc":"embourbe","lemma":"embourber","pos":"VER"} ,
		{"word":"embourbent","word_nosc":"embourbent","lemma":"embourber","pos":"VER"} ,
		{"word":"embourber","word_nosc":"embourber","lemma":"embourber","pos":"VER"} ,
		{"word":"embourbé","word_nosc":"embourbe","lemma":"embourber","pos":"VER"} ,
		{"word":"embourbée","word_nosc":"embourbee","lemma":"embourber","pos":"VER"} ,
		{"word":"embourbées","word_nosc":"embourbees","lemma":"embourber","pos":"VER"} ,
		{"word":"embourbés","word_nosc":"embourbes","lemma":"embourber","pos":"VER"} ,
		{"word":"embourgeoise","word_nosc":"embourgeoise","lemma":"embourgeoiser","pos":"VER"} ,
		{"word":"embourgeoiser","word_nosc":"embourgeoiser","lemma":"embourgeoiser","pos":"VER"} ,
		{"word":"embourgeoises","word_nosc":"embourgeoises","lemma":"embourgeoiser","pos":"VER"} ,
		{"word":"embourgeoisés","word_nosc":"embourgeoises","lemma":"embourgeoiser","pos":"VER"} ,
		{"word":"embouteillait","word_nosc":"embouteillait","lemma":"embouteiller","pos":"VER"} ,
		{"word":"embouteille","word_nosc":"embouteille","lemma":"embouteiller","pos":"VER"} ,
		{"word":"embouteiller","word_nosc":"embouteiller","lemma":"embouteiller","pos":"VER"} ,
		{"word":"embouteillé","word_nosc":"embouteille","lemma":"embouteiller","pos":"VER"} ,
		{"word":"embouteillée","word_nosc":"embouteillee","lemma":"embouteiller","pos":"VER"} ,
		{"word":"embouteillées","word_nosc":"embouteillees","lemma":"embouteiller","pos":"VER"} ,
		{"word":"embouti","word_nosc":"embouti","lemma":"emboutir","pos":"VER"} ,
		{"word":"emboutie","word_nosc":"emboutie","lemma":"emboutir","pos":"VER"} ,
		{"word":"embouties","word_nosc":"embouties","lemma":"emboutir","pos":"VER"} ,
		{"word":"emboutir","word_nosc":"emboutir","lemma":"emboutir","pos":"VER"} ,
		{"word":"emboutis","word_nosc":"emboutis","lemma":"emboutir","pos":"VER"} ,
		{"word":"emboutissant","word_nosc":"emboutissant","lemma":"emboutir","pos":"VER"} ,
		{"word":"emboutit","word_nosc":"emboutit","lemma":"emboutir","pos":"VER"} ,
		{"word":"emboîta","word_nosc":"emboita","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîtai","word_nosc":"emboitai","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîtaient","word_nosc":"emboitaient","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîtais","word_nosc":"emboitais","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîtait","word_nosc":"emboitait","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîtant","word_nosc":"emboitant","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîtas","word_nosc":"emboitas","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîte","word_nosc":"emboite","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîtent","word_nosc":"emboitent","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîter","word_nosc":"emboiter","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîterai","word_nosc":"emboiterai","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîtions","word_nosc":"emboitions","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîtons","word_nosc":"emboitons","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîtâmes","word_nosc":"emboitames","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîtèrent","word_nosc":"emboiterent","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîté","word_nosc":"emboite","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîtée","word_nosc":"emboitee","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîtées","word_nosc":"emboitees","lemma":"emboîter","pos":"VER"} ,
		{"word":"emboîtés","word_nosc":"emboites","lemma":"emboîter","pos":"VER"} ,
		{"word":"embranchant","word_nosc":"embranchant","lemma":"embrancher","pos":"VER"} ,
		{"word":"embraquer","word_nosc":"embraquer","lemma":"embraquer","pos":"VER"} ,
		{"word":"embrasa","word_nosc":"embrasa","lemma":"embraser","pos":"VER"} ,
		{"word":"embrasaient","word_nosc":"embrasaient","lemma":"embraser","pos":"VER"} ,
		{"word":"embrasait","word_nosc":"embrasait","lemma":"embraser","pos":"VER"} ,
		{"word":"embrasant","word_nosc":"embrasant","lemma":"embraser","pos":"VER"} ,
		{"word":"embrase","word_nosc":"embrase","lemma":"embraser","pos":"VER"} ,
		{"word":"embrasent","word_nosc":"embrasent","lemma":"embraser","pos":"VER"} ,
		{"word":"embraser","word_nosc":"embraser","lemma":"embraser","pos":"VER"} ,
		{"word":"embrasera","word_nosc":"embrasera","lemma":"embraser","pos":"VER"} ,
		{"word":"embraserait","word_nosc":"embraserait","lemma":"embraser","pos":"VER"} ,
		{"word":"embrasez","word_nosc":"embrasez","lemma":"embraser","pos":"VER"} ,
		{"word":"embrassa","word_nosc":"embrassa","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassai","word_nosc":"embrassai","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassaient","word_nosc":"embrassaient","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassais","word_nosc":"embrassais","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassait","word_nosc":"embrassait","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassant","word_nosc":"embrassant","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrasse","word_nosc":"embrasse","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassent","word_nosc":"embrassent","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrasser","word_nosc":"embrasser","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassera","word_nosc":"embrassera","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrasserai","word_nosc":"embrasserai","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrasseraient","word_nosc":"embrasseraient","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrasserais","word_nosc":"embrasserais","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrasserait","word_nosc":"embrasserait","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrasseras","word_nosc":"embrasseras","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrasserez","word_nosc":"embrasserez","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrasseriez","word_nosc":"embrasseriez","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrasseront","word_nosc":"embrasseront","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrasses","word_nosc":"embrasses","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassez","word_nosc":"embrassez","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassiez","word_nosc":"embrassiez","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassions","word_nosc":"embrassions","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassons","word_nosc":"embrassons","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassâmes","word_nosc":"embrassames","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassât","word_nosc":"embrassat","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassâtes","word_nosc":"embrassates","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassèrent","word_nosc":"embrasserent","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassé","word_nosc":"embrasse","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassée","word_nosc":"embrassee","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassées","word_nosc":"embrassees","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrassés","word_nosc":"embrasses","lemma":"embrasser","pos":"VER"} ,
		{"word":"embrasèrent","word_nosc":"embraserent","lemma":"embraser","pos":"VER"} ,
		{"word":"embrasé","word_nosc":"embrase","lemma":"embraser","pos":"VER"} ,
		{"word":"embrasée","word_nosc":"embrasee","lemma":"embraser","pos":"VER"} ,
		{"word":"embrasées","word_nosc":"embrasees","lemma":"embraser","pos":"VER"} ,
		{"word":"embrasés","word_nosc":"embrases","lemma":"embraser","pos":"VER"} ,
		{"word":"embraya","word_nosc":"embraya","lemma":"embrayer","pos":"VER"} ,
		{"word":"embrayais","word_nosc":"embrayais","lemma":"embrayer","pos":"VER"} ,
		{"word":"embrayait","word_nosc":"embrayait","lemma":"embrayer","pos":"VER"} ,
		{"word":"embrayant","word_nosc":"embrayant","lemma":"embrayer","pos":"VER"} ,
		{"word":"embraye","word_nosc":"embraye","lemma":"embrayer","pos":"VER"} ,
		{"word":"embrayer","word_nosc":"embrayer","lemma":"embrayer","pos":"VER"} ,
		{"word":"embrayes","word_nosc":"embrayes","lemma":"embrayer","pos":"VER"} ,
		{"word":"embrayé","word_nosc":"embraye","lemma":"embrayer","pos":"VER"} ,
		{"word":"embrayée","word_nosc":"embrayee","lemma":"embrayer","pos":"VER"} ,
		{"word":"embrigadé","word_nosc":"embrigade","lemma":"embrigader","pos":"VER"} ,
		{"word":"embrigadée","word_nosc":"embrigadee","lemma":"embrigader","pos":"VER"} ,
		{"word":"embringua","word_nosc":"embringua","lemma":"embringuer","pos":"VER"} ,
		{"word":"embringuaient","word_nosc":"embringuaient","lemma":"embringuer","pos":"VER"} ,
		{"word":"embringuait","word_nosc":"embringuait","lemma":"embringuer","pos":"VER"} ,
		{"word":"embringuer","word_nosc":"embringuer","lemma":"embringuer","pos":"VER"} ,
		{"word":"embringues","word_nosc":"embringues","lemma":"embringuer","pos":"VER"} ,
		{"word":"embringué","word_nosc":"embringue","lemma":"embringuer","pos":"VER"} ,
		{"word":"embringuée","word_nosc":"embringuee","lemma":"embringuer","pos":"VER"} ,
		{"word":"embrocha","word_nosc":"embrocha","lemma":"embrocher","pos":"VER"} ,
		{"word":"embrochais","word_nosc":"embrochais","lemma":"embrocher","pos":"VER"} ,
		{"word":"embrochant","word_nosc":"embrochant","lemma":"embrocher","pos":"VER"} ,
		{"word":"embroche","word_nosc":"embroche","lemma":"embrocher","pos":"VER"} ,
		{"word":"embrochent","word_nosc":"embrochent","lemma":"embrocher","pos":"VER"} ,
		{"word":"embrocher","word_nosc":"embrocher","lemma":"embrocher","pos":"VER"} ,
		{"word":"embrochera","word_nosc":"embrochera","lemma":"embrocher","pos":"VER"} ,
		{"word":"embrochez","word_nosc":"embrochez","lemma":"embrocher","pos":"VER"} ,
		{"word":"embrochèrent","word_nosc":"embrocherent","lemma":"embrocher","pos":"VER"} ,
		{"word":"embroché","word_nosc":"embroche","lemma":"embrocher","pos":"VER"} ,
		{"word":"embrochée","word_nosc":"embrochee","lemma":"embrocher","pos":"VER"} ,
		{"word":"embrochées","word_nosc":"embrochees","lemma":"embrocher","pos":"VER"} ,
		{"word":"embrochés","word_nosc":"embroches","lemma":"embrocher","pos":"VER"} ,
		{"word":"embrouilla","word_nosc":"embrouilla","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillai","word_nosc":"embrouillai","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillaient","word_nosc":"embrouillaient","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillais","word_nosc":"embrouillais","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillait","word_nosc":"embrouillait","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillant","word_nosc":"embrouillant","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillasse","word_nosc":"embrouillasse","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouille","word_nosc":"embrouille","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillent","word_nosc":"embrouillent","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouiller","word_nosc":"embrouiller","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillerai","word_nosc":"embrouillerai","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillerais","word_nosc":"embrouillerais","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillerait","word_nosc":"embrouillerait","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouilles","word_nosc":"embrouilles","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillez","word_nosc":"embrouillez","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillât","word_nosc":"embrouillat","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillèrent","word_nosc":"embrouillerent","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillé","word_nosc":"embrouille","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillée","word_nosc":"embrouillee","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillées","word_nosc":"embrouillees","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embrouillés","word_nosc":"embrouilles","lemma":"embrouiller","pos":"VER"} ,
		{"word":"embroussaille","word_nosc":"embroussaille","lemma":"embroussailler","pos":"VER"} ,
		{"word":"embroussailler","word_nosc":"embroussailler","lemma":"embroussailler","pos":"VER"} ,
		{"word":"embroussaillée","word_nosc":"embroussaillee","lemma":"embroussailler","pos":"VER"} ,
		{"word":"embroussaillées","word_nosc":"embroussaillees","lemma":"embroussailler","pos":"VER"} ,
		{"word":"embroussaillés","word_nosc":"embroussailles","lemma":"embroussailler","pos":"VER"} ,
		{"word":"embrumait","word_nosc":"embrumait","lemma":"embrumer","pos":"VER"} ,
		{"word":"embrume","word_nosc":"embrume","lemma":"embrumer","pos":"VER"} ,
		{"word":"embrument","word_nosc":"embrument","lemma":"embrumer","pos":"VER"} ,
		{"word":"embrumer","word_nosc":"embrumer","lemma":"embrumer","pos":"VER"} ,
		{"word":"embrumé","word_nosc":"embrume","lemma":"embrumer","pos":"VER"} ,
		{"word":"embrumée","word_nosc":"embrumee","lemma":"embrumer","pos":"VER"} ,
		{"word":"embrumées","word_nosc":"embrumees","lemma":"embrumer","pos":"VER"} ,
		{"word":"embrumés","word_nosc":"embrumes","lemma":"embrumer","pos":"VER"} ,
		{"word":"embua","word_nosc":"embua","lemma":"embuer","pos":"VER"} ,
		{"word":"embuaient","word_nosc":"embuaient","lemma":"embuer","pos":"VER"} ,
		{"word":"embuait","word_nosc":"embuait","lemma":"embuer","pos":"VER"} ,
		{"word":"embuant","word_nosc":"embuant","lemma":"embuer","pos":"VER"} ,
		{"word":"embue","word_nosc":"embue","lemma":"embuer","pos":"VER"} ,
		{"word":"embuent","word_nosc":"embuent","lemma":"embuer","pos":"VER"} ,
		{"word":"embuer","word_nosc":"embuer","lemma":"embuer","pos":"VER"} ,
		{"word":"embusqua","word_nosc":"embusqua","lemma":"embusquer","pos":"VER"} ,
		{"word":"embusquaient","word_nosc":"embusquaient","lemma":"embusquer","pos":"VER"} ,
		{"word":"embusquait","word_nosc":"embusquait","lemma":"embusquer","pos":"VER"} ,
		{"word":"embusque","word_nosc":"embusque","lemma":"embusquer","pos":"VER"} ,
		{"word":"embusquent","word_nosc":"embusquent","lemma":"embusquer","pos":"VER"} ,
		{"word":"embusquer","word_nosc":"embusquer","lemma":"embusquer","pos":"VER"} ,
		{"word":"embusqué","word_nosc":"embusque","lemma":"embusquer","pos":"VER"} ,
		{"word":"embusquée","word_nosc":"embusquee","lemma":"embusquer","pos":"VER"} ,
		{"word":"embusquées","word_nosc":"embusquees","lemma":"embusquer","pos":"VER"} ,
		{"word":"embusqués","word_nosc":"embusques","lemma":"embusquer","pos":"VER"} ,
		{"word":"embuèrent","word_nosc":"embuerent","lemma":"embuer","pos":"VER"} ,
		{"word":"embué","word_nosc":"embue","lemma":"embuer","pos":"VER"} ,
		{"word":"embuée","word_nosc":"embuee","lemma":"embuer","pos":"VER"} ,
		{"word":"embuées","word_nosc":"embuees","lemma":"embuer","pos":"VER"} ,
		{"word":"embués","word_nosc":"embues","lemma":"embuer","pos":"VER"} ,
		{"word":"embéguinées","word_nosc":"embeguinees","lemma":"embéguiner","pos":"VER"} ,
		{"word":"embêtaient","word_nosc":"embetaient","lemma":"embêter","pos":"VER"} ,
		{"word":"embêtais","word_nosc":"embetais","lemma":"embêter","pos":"VER"} ,
		{"word":"embêtait","word_nosc":"embetait","lemma":"embêter","pos":"VER"} ,
		{"word":"embête","word_nosc":"embete","lemma":"embêter","pos":"VER"} ,
		{"word":"embêtent","word_nosc":"embetent","lemma":"embêter","pos":"VER"} ,
		{"word":"embêter","word_nosc":"embeter","lemma":"embêter","pos":"VER"} ,
		{"word":"embêtera","word_nosc":"embetera","lemma":"embêter","pos":"VER"} ,
		{"word":"embêterai","word_nosc":"embeterai","lemma":"embêter","pos":"VER"} ,
		{"word":"embêteraient","word_nosc":"embeteraient","lemma":"embêter","pos":"VER"} ,
		{"word":"embêterais","word_nosc":"embeterais","lemma":"embêter","pos":"VER"} ,
		{"word":"embêterait","word_nosc":"embeterait","lemma":"embêter","pos":"VER"} ,
		{"word":"embêtes","word_nosc":"embetes","lemma":"embêter","pos":"VER"} ,
		{"word":"embêtez","word_nosc":"embetez","lemma":"embêter","pos":"VER"} ,
		{"word":"embêté","word_nosc":"embete","lemma":"embêter","pos":"VER"} ,
		{"word":"embêtée","word_nosc":"embetee","lemma":"embêter","pos":"VER"} ,
		{"word":"embêtées","word_nosc":"embetees","lemma":"embêter","pos":"VER"} ,
		{"word":"embêtés","word_nosc":"embetes","lemma":"embêter","pos":"VER"} ,
		{"word":"emmagasinaient","word_nosc":"emmagasinaient","lemma":"emmagasiner","pos":"VER"} ,
		{"word":"emmagasinais","word_nosc":"emmagasinais","lemma":"emmagasiner","pos":"VER"} ,
		{"word":"emmagasinait","word_nosc":"emmagasinait","lemma":"emmagasiner","pos":"VER"} ,
		{"word":"emmagasine","word_nosc":"emmagasine","lemma":"emmagasiner","pos":"VER"} ,
		{"word":"emmagasinent","word_nosc":"emmagasinent","lemma":"emmagasiner","pos":"VER"} ,
		{"word":"emmagasiner","word_nosc":"emmagasiner","lemma":"emmagasiner","pos":"VER"} ,
		{"word":"emmagasiné","word_nosc":"emmagasine","lemma":"emmagasiner","pos":"VER"} ,
		{"word":"emmagasinée","word_nosc":"emmagasinee","lemma":"emmagasiner","pos":"VER"} ,
		{"word":"emmagasinées","word_nosc":"emmagasinees","lemma":"emmagasiner","pos":"VER"} ,
		{"word":"emmagasinés","word_nosc":"emmagasines","lemma":"emmagasiner","pos":"VER"} ,
		{"word":"emmaillota","word_nosc":"emmaillota","lemma":"emmailloter","pos":"VER"} ,
		{"word":"emmaillotaient","word_nosc":"emmaillotaient","lemma":"emmailloter","pos":"VER"} ,
		{"word":"emmailloter","word_nosc":"emmailloter","lemma":"emmailloter","pos":"VER"} ,
		{"word":"emmailloté","word_nosc":"emmaillote","lemma":"emmailloter","pos":"VER"} ,
		{"word":"emmaillotée","word_nosc":"emmaillotee","lemma":"emmailloter","pos":"VER"} ,
		{"word":"emmaillotées","word_nosc":"emmaillotees","lemma":"emmailloter","pos":"VER"} ,
		{"word":"emmaillotés","word_nosc":"emmaillotes","lemma":"emmailloter","pos":"VER"} ,
		{"word":"emmanchant","word_nosc":"emmanchant","lemma":"emmancher","pos":"VER"} ,
		{"word":"emmanche","word_nosc":"emmanche","lemma":"emmancher","pos":"VER"} ,
		{"word":"emmanchent","word_nosc":"emmanchent","lemma":"emmancher","pos":"VER"} ,
		{"word":"emmancher","word_nosc":"emmancher","lemma":"emmancher","pos":"VER"} ,
		{"word":"emmanché","word_nosc":"emmanche","lemma":"emmancher","pos":"VER"} ,
		{"word":"emmanchée","word_nosc":"emmanchee","lemma":"emmancher","pos":"VER"} ,
		{"word":"emmanchées","word_nosc":"emmanchees","lemma":"emmancher","pos":"VER"} ,
		{"word":"emmanchés","word_nosc":"emmanches","lemma":"emmancher","pos":"VER"} ,
		{"word":"emmena","word_nosc":"emmena","lemma":"emmener","pos":"VER"} ,
		{"word":"emmenai","word_nosc":"emmenai","lemma":"emmener","pos":"VER"} ,
		{"word":"emmenaient","word_nosc":"emmenaient","lemma":"emmener","pos":"VER"} ,
		{"word":"emmenais","word_nosc":"emmenais","lemma":"emmener","pos":"VER"} ,
		{"word":"emmenait","word_nosc":"emmenait","lemma":"emmener","pos":"VER"} ,
		{"word":"emmenant","word_nosc":"emmenant","lemma":"emmener","pos":"VER"} ,
		{"word":"emmener","word_nosc":"emmener","lemma":"emmener","pos":"VER"} ,
		{"word":"emmener","word_nosc":"emmener","lemma":"expliquer","pos":"VER"} ,
		{"word":"emmenez","word_nosc":"emmenez","lemma":"emmener","pos":"VER"} ,
		{"word":"emmeniez","word_nosc":"emmeniez","lemma":"emmener","pos":"VER"} ,
		{"word":"emmenions","word_nosc":"emmenions","lemma":"emmener","pos":"VER"} ,
		{"word":"emmenons","word_nosc":"emmenons","lemma":"emmener","pos":"VER"} ,
		{"word":"emmenotté","word_nosc":"emmenotte","lemma":"emmenotter","pos":"VER"} ,
		{"word":"emmenât","word_nosc":"emmenat","lemma":"emmener","pos":"VER"} ,
		{"word":"emmenèrent","word_nosc":"emmenerent","lemma":"emmener","pos":"VER"} ,
		{"word":"emmené","word_nosc":"emmene","lemma":"emmener","pos":"VER"} ,
		{"word":"emmenée","word_nosc":"emmenee","lemma":"emmener","pos":"VER"} ,
		{"word":"emmenées","word_nosc":"emmenees","lemma":"emmener","pos":"VER"} ,
		{"word":"emmenés","word_nosc":"emmenes","lemma":"emmener","pos":"VER"} ,
		{"word":"emmerda","word_nosc":"emmerda","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerdaient","word_nosc":"emmerdaient","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerdais","word_nosc":"emmerdais","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerdait","word_nosc":"emmerdait","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerdant","word_nosc":"emmerdant","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerde","word_nosc":"emmerde","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerdent","word_nosc":"emmerdent","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerder","word_nosc":"emmerder","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerdera","word_nosc":"emmerdera","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerderai","word_nosc":"emmerderai","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerderaient","word_nosc":"emmerderaient","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerderais","word_nosc":"emmerderais","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerderait","word_nosc":"emmerderait","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerderez","word_nosc":"emmerderez","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerderont","word_nosc":"emmerderont","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerdes","word_nosc":"emmerdes","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerdez","word_nosc":"emmerdez","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerdons","word_nosc":"emmerdons","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerdé","word_nosc":"emmerde","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerdée","word_nosc":"emmerdee","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmerdés","word_nosc":"emmerdes","lemma":"emmerder","pos":"VER"} ,
		{"word":"emmitoufla","word_nosc":"emmitoufla","lemma":"emmitoufler","pos":"VER"} ,
		{"word":"emmitouflai","word_nosc":"emmitouflai","lemma":"emmitoufler","pos":"VER"} ,
		{"word":"emmitouflait","word_nosc":"emmitouflait","lemma":"emmitoufler","pos":"VER"} ,
		{"word":"emmitouflant","word_nosc":"emmitouflant","lemma":"emmitoufler","pos":"VER"} ,
		{"word":"emmitoufle","word_nosc":"emmitoufle","lemma":"emmitoufler","pos":"VER"} ,
		{"word":"emmitoufler","word_nosc":"emmitoufler","lemma":"emmitoufler","pos":"VER"} ,
		{"word":"emmitouflez","word_nosc":"emmitouflez","lemma":"emmitoufler","pos":"VER"} ,
		{"word":"emmitouflé","word_nosc":"emmitoufle","lemma":"emmitoufler","pos":"VER"} ,
		{"word":"emmitouflée","word_nosc":"emmitouflee","lemma":"emmitoufler","pos":"VER"} ,
		{"word":"emmitouflées","word_nosc":"emmitouflees","lemma":"emmitoufler","pos":"VER"} ,
		{"word":"emmitouflés","word_nosc":"emmitoufles","lemma":"emmitoufler","pos":"VER"} ,
		{"word":"emmouflé","word_nosc":"emmoufle","lemma":"emmoufler","pos":"VER"} ,
		{"word":"emmura","word_nosc":"emmura","lemma":"emmurer","pos":"VER"} ,
		{"word":"emmurait","word_nosc":"emmurait","lemma":"emmurer","pos":"VER"} ,
		{"word":"emmure","word_nosc":"emmure","lemma":"emmurer","pos":"VER"} ,
		{"word":"emmurer","word_nosc":"emmurer","lemma":"emmurer","pos":"VER"} ,
		{"word":"emmuré","word_nosc":"emmure","lemma":"emmurer","pos":"VER"} ,
		{"word":"emmurée","word_nosc":"emmuree","lemma":"emmurer","pos":"VER"} ,
		{"word":"emmurées","word_nosc":"emmurees","lemma":"emmurer","pos":"VER"} ,
		{"word":"emmurés","word_nosc":"emmures","lemma":"emmurer","pos":"VER"} ,
		{"word":"emmène","word_nosc":"emmene","lemma":"emmener","pos":"VER"} ,
		{"word":"emmènent","word_nosc":"emmenent","lemma":"emmener","pos":"VER"} ,
		{"word":"emmènera","word_nosc":"emmenera","lemma":"emmener","pos":"VER"} ,
		{"word":"emmènerai","word_nosc":"emmenerai","lemma":"emmener","pos":"VER"} ,
		{"word":"emmèneraient","word_nosc":"emmeneraient","lemma":"emmener","pos":"VER"} ,
		{"word":"emmènerais","word_nosc":"emmenerais","lemma":"emmener","pos":"VER"} ,
		{"word":"emmènerait","word_nosc":"emmenerait","lemma":"emmener","pos":"VER"} ,
		{"word":"emmèneras","word_nosc":"emmeneras","lemma":"emmener","pos":"VER"} ,
		{"word":"emmènerez","word_nosc":"emmenerez","lemma":"emmener","pos":"VER"} ,
		{"word":"emmèneriez","word_nosc":"emmeneriez","lemma":"emmener","pos":"VER"} ,
		{"word":"emmènerions","word_nosc":"emmenerions","lemma":"emmener","pos":"VER"} ,
		{"word":"emmènerons","word_nosc":"emmenerons","lemma":"emmener","pos":"VER"} ,
		{"word":"emmèneront","word_nosc":"emmeneront","lemma":"emmener","pos":"VER"} ,
		{"word":"emmènes","word_nosc":"emmenes","lemma":"emmener","pos":"VER"} ,
		{"word":"emménage","word_nosc":"emmenage","lemma":"emménager","pos":"VER"} ,
		{"word":"emménagea","word_nosc":"emmenagea","lemma":"emménager","pos":"VER"} ,
		{"word":"emménageaient","word_nosc":"emmenageaient","lemma":"emménager","pos":"VER"} ,
		{"word":"emménageais","word_nosc":"emmenageais","lemma":"emménager","pos":"VER"} ,
		{"word":"emménageait","word_nosc":"emmenageait","lemma":"emménager","pos":"VER"} ,
		{"word":"emménageant","word_nosc":"emmenageant","lemma":"emménager","pos":"VER"} ,
		{"word":"emménagent","word_nosc":"emmenagent","lemma":"emménager","pos":"VER"} ,
		{"word":"emménageons","word_nosc":"emmenageons","lemma":"emménager","pos":"VER"} ,
		{"word":"emménager","word_nosc":"emmenager","lemma":"emménager","pos":"VER"} ,
		{"word":"emménagera","word_nosc":"emmenagera","lemma":"emménager","pos":"VER"} ,
		{"word":"emménagerai","word_nosc":"emmenagerai","lemma":"emménager","pos":"VER"} ,
		{"word":"emménagerais","word_nosc":"emmenagerais","lemma":"emménager","pos":"VER"} ,
		{"word":"emménagerait","word_nosc":"emmenagerait","lemma":"emménager","pos":"VER"} ,
		{"word":"emménagerez","word_nosc":"emmenagerez","lemma":"emménager","pos":"VER"} ,
		{"word":"emménagerons","word_nosc":"emmenagerons","lemma":"emménager","pos":"VER"} ,
		{"word":"emménages","word_nosc":"emmenages","lemma":"emménager","pos":"VER"} ,
		{"word":"emménagez","word_nosc":"emmenagez","lemma":"emménager","pos":"VER"} ,
		{"word":"emménagiez","word_nosc":"emmenagiez","lemma":"emménager","pos":"VER"} ,
		{"word":"emménagions","word_nosc":"emmenagions","lemma":"emménager","pos":"VER"} ,
		{"word":"emménagèrent","word_nosc":"emmenagerent","lemma":"emménager","pos":"VER"} ,
		{"word":"emménagé","word_nosc":"emmenage","lemma":"emménager","pos":"VER"} ,
		{"word":"emmêla","word_nosc":"emmela","lemma":"emmêler","pos":"VER"} ,
		{"word":"emmêlai","word_nosc":"emmelai","lemma":"emmêler","pos":"VER"} ,
		{"word":"emmêlaient","word_nosc":"emmelaient","lemma":"emmêler","pos":"VER"} ,
		{"word":"emmêlait","word_nosc":"emmelait","lemma":"emmêler","pos":"VER"} ,
		{"word":"emmêlant","word_nosc":"emmelant","lemma":"emmêler","pos":"VER"} ,
		{"word":"emmêle","word_nosc":"emmele","lemma":"emmêler","pos":"VER"} ,
		{"word":"emmêlent","word_nosc":"emmelent","lemma":"emmêler","pos":"VER"} ,
		{"word":"emmêler","word_nosc":"emmeler","lemma":"emmêler","pos":"VER"} ,
		{"word":"emmêlera","word_nosc":"emmelera","lemma":"emmêler","pos":"VER"} ,
		{"word":"emmêleront","word_nosc":"emmeleront","lemma":"emmêler","pos":"VER"} ,
		{"word":"emmêles","word_nosc":"emmeles","lemma":"emmêler","pos":"VER"} ,
		{"word":"emmêlèrent","word_nosc":"emmelerent","lemma":"emmêler","pos":"VER"} ,
		{"word":"emmêlé","word_nosc":"emmele","lemma":"emmêler","pos":"VER"} ,
		{"word":"emmêlée","word_nosc":"emmelee","lemma":"emmêler","pos":"VER"} ,
		{"word":"emmêlées","word_nosc":"emmelees","lemma":"emmêler","pos":"VER"} ,
		{"word":"emmêlés","word_nosc":"emmeles","lemma":"emmêler","pos":"VER"} ,
		{"word":"empaffe","word_nosc":"empaffe","lemma":"empaffer","pos":"VER"} ,
		{"word":"empaffer","word_nosc":"empaffer","lemma":"empaffer","pos":"VER"} ,
		{"word":"empaillant","word_nosc":"empaillant","lemma":"empailler","pos":"VER"} ,
		{"word":"empailler","word_nosc":"empailler","lemma":"empailler","pos":"VER"} ,
		{"word":"empaillez","word_nosc":"empaillez","lemma":"empailler","pos":"VER"} ,
		{"word":"empaillé","word_nosc":"empaille","lemma":"empailler","pos":"VER"} ,
		{"word":"empaillée","word_nosc":"empaillee","lemma":"empailler","pos":"VER"} ,
		{"word":"empaillées","word_nosc":"empaillees","lemma":"empailler","pos":"VER"} ,
		{"word":"empaillés","word_nosc":"empailles","lemma":"empailler","pos":"VER"} ,
		{"word":"empalait","word_nosc":"empalait","lemma":"empaler","pos":"VER"} ,
		{"word":"empalant","word_nosc":"empalant","lemma":"empaler","pos":"VER"} ,
		{"word":"empale","word_nosc":"empale","lemma":"empaler","pos":"VER"} ,
		{"word":"empaler","word_nosc":"empaler","lemma":"empaler","pos":"VER"} ,
		{"word":"empalerais","word_nosc":"empalerais","lemma":"empaler","pos":"VER"} ,
		{"word":"empaleront","word_nosc":"empaleront","lemma":"empaler","pos":"VER"} ,
		{"word":"empalmait","word_nosc":"empalmait","lemma":"empalmer","pos":"VER"} ,
		{"word":"empalme","word_nosc":"empalme","lemma":"empalmer","pos":"VER"} ,
		{"word":"empalmé","word_nosc":"empalme","lemma":"empalmer","pos":"VER"} ,
		{"word":"empalé","word_nosc":"empale","lemma":"empaler","pos":"VER"} ,
		{"word":"empalée","word_nosc":"empalee","lemma":"empaler","pos":"VER"} ,
		{"word":"empalées","word_nosc":"empalees","lemma":"empaler","pos":"VER"} ,
		{"word":"empalés","word_nosc":"empales","lemma":"empaler","pos":"VER"} ,
		{"word":"empanaché","word_nosc":"empanache","lemma":"empanacher","pos":"VER"} ,
		{"word":"empanachée","word_nosc":"empanachee","lemma":"empanacher","pos":"VER"} ,
		{"word":"empanachées","word_nosc":"empanachees","lemma":"empanacher","pos":"VER"} ,
		{"word":"empanachés","word_nosc":"empanaches","lemma":"empanacher","pos":"VER"} ,
		{"word":"empanner","word_nosc":"empanner","lemma":"empanner","pos":"VER"} ,
		{"word":"empapaoutent","word_nosc":"empapaoutent","lemma":"empapaouter","pos":"VER"} ,
		{"word":"empapaouter","word_nosc":"empapaouter","lemma":"empapaouter","pos":"VER"} ,
		{"word":"empapaouté","word_nosc":"empapaoute","lemma":"empapaouter","pos":"VER"} ,
		{"word":"empapaoutés","word_nosc":"empapaoutes","lemma":"empapaouter","pos":"VER"} ,
		{"word":"empaqueta","word_nosc":"empaqueta","lemma":"empaqueter","pos":"VER"} ,
		{"word":"empaqueter","word_nosc":"empaqueter","lemma":"empaqueter","pos":"VER"} ,
		{"word":"empaquette","word_nosc":"empaquette","lemma":"empaqueter","pos":"VER"} ,
		{"word":"empaquettent","word_nosc":"empaquettent","lemma":"empaqueter","pos":"VER"} ,
		{"word":"empaquettes","word_nosc":"empaquettes","lemma":"empaqueter","pos":"VER"} ,
		{"word":"empaquetèrent","word_nosc":"empaqueterent","lemma":"empaqueter","pos":"VER"} ,
		{"word":"empaqueté","word_nosc":"empaquete","lemma":"empaqueter","pos":"VER"} ,
		{"word":"empaquetée","word_nosc":"empaquetee","lemma":"empaqueter","pos":"VER"} ,
		{"word":"empaquetées","word_nosc":"empaquetees","lemma":"empaqueter","pos":"VER"} ,
		{"word":"empaquetés","word_nosc":"empaquetes","lemma":"empaqueter","pos":"VER"} ,
		{"word":"empara","word_nosc":"empara","lemma":"emparer","pos":"VER"} ,
		{"word":"emparadisé","word_nosc":"emparadise","lemma":"emparadiser","pos":"VER"} ,
		{"word":"emparai","word_nosc":"emparai","lemma":"emparer","pos":"VER"} ,
		{"word":"emparaient","word_nosc":"emparaient","lemma":"emparer","pos":"VER"} ,
		{"word":"emparais","word_nosc":"emparais","lemma":"emparer","pos":"VER"} ,
		{"word":"emparait","word_nosc":"emparait","lemma":"emparer","pos":"VER"} ,
		{"word":"emparant","word_nosc":"emparant","lemma":"emparer","pos":"VER"} ,
		{"word":"emparassent","word_nosc":"emparassent","lemma":"emparer","pos":"VER"} ,
		{"word":"empare","word_nosc":"empare","lemma":"emparer","pos":"VER"} ,
		{"word":"emparent","word_nosc":"emparent","lemma":"emparer","pos":"VER"} ,
		{"word":"emparer","word_nosc":"emparer","lemma":"emparer","pos":"VER"} ,
		{"word":"emparera","word_nosc":"emparera","lemma":"emparer","pos":"VER"} ,
		{"word":"emparerai","word_nosc":"emparerai","lemma":"emparer","pos":"VER"} ,
		{"word":"empareraient","word_nosc":"empareraient","lemma":"emparer","pos":"VER"} ,
		{"word":"emparerais","word_nosc":"emparerais","lemma":"emparer","pos":"VER"} ,
		{"word":"emparerait","word_nosc":"emparerait","lemma":"emparer","pos":"VER"} ,
		{"word":"empareront","word_nosc":"empareront","lemma":"emparer","pos":"VER"} ,
		{"word":"emparez","word_nosc":"emparez","lemma":"emparer","pos":"VER"} ,
		{"word":"emparons","word_nosc":"emparons","lemma":"emparer","pos":"VER"} ,
		{"word":"emparât","word_nosc":"emparat","lemma":"emparer","pos":"VER"} ,
		{"word":"emparèrent","word_nosc":"emparerent","lemma":"emparer","pos":"VER"} ,
		{"word":"emparé","word_nosc":"empare","lemma":"emparer","pos":"VER"} ,
		{"word":"emparée","word_nosc":"emparee","lemma":"emparer","pos":"VER"} ,
		{"word":"emparées","word_nosc":"emparees","lemma":"emparer","pos":"VER"} ,
		{"word":"emparés","word_nosc":"empares","lemma":"emparer","pos":"VER"} ,
		{"word":"empaume","word_nosc":"empaume","lemma":"empaumer","pos":"VER"} ,
		{"word":"empaumer","word_nosc":"empaumer","lemma":"empaumer","pos":"VER"} ,
		{"word":"empeignés","word_nosc":"empeignes","lemma":"empeigner","pos":"VER"} ,
		{"word":"empennait","word_nosc":"empennait","lemma":"empenner","pos":"VER"} ,
		{"word":"empennée","word_nosc":"empennee","lemma":"empenner","pos":"VER"} ,
		{"word":"empennées","word_nosc":"empennees","lemma":"empenner","pos":"VER"} ,
		{"word":"empennés","word_nosc":"empennes","lemma":"empenner","pos":"VER"} ,
		{"word":"emperlait","word_nosc":"emperlait","lemma":"emperler","pos":"VER"} ,
		{"word":"emperlant","word_nosc":"emperlant","lemma":"emperler","pos":"VER"} ,
		{"word":"emperler","word_nosc":"emperler","lemma":"emperler","pos":"VER"} ,
		{"word":"emperlé","word_nosc":"emperle","lemma":"emperler","pos":"VER"} ,
		{"word":"emperlés","word_nosc":"emperles","lemma":"emperler","pos":"VER"} ,
		{"word":"empesant","word_nosc":"empesant","lemma":"empeser","pos":"VER"} ,
		{"word":"empesta","word_nosc":"empesta","lemma":"empester","pos":"VER"} ,
		{"word":"empestaient","word_nosc":"empestaient","lemma":"empester","pos":"VER"} ,
		{"word":"empestais","word_nosc":"empestais","lemma":"empester","pos":"VER"} ,
		{"word":"empestait","word_nosc":"empestait","lemma":"empester","pos":"VER"} ,
		{"word":"empestant","word_nosc":"empestant","lemma":"empester","pos":"VER"} ,
		{"word":"empeste","word_nosc":"empeste","lemma":"empester","pos":"VER"} ,
		{"word":"empestent","word_nosc":"empestent","lemma":"empester","pos":"VER"} ,
		{"word":"empester","word_nosc":"empester","lemma":"empester","pos":"VER"} ,
		{"word":"empestes","word_nosc":"empestes","lemma":"empester","pos":"VER"} ,
		{"word":"empestez","word_nosc":"empestez","lemma":"empester","pos":"VER"} ,
		{"word":"empesté","word_nosc":"empeste","lemma":"empester","pos":"VER"} ,
		{"word":"empestée","word_nosc":"empestee","lemma":"empester","pos":"VER"} ,
		{"word":"empestées","word_nosc":"empestees","lemma":"empester","pos":"VER"} ,
		{"word":"empesé","word_nosc":"empese","lemma":"empeser","pos":"VER"} ,
		{"word":"empesée","word_nosc":"empesee","lemma":"empeser","pos":"VER"} ,
		{"word":"empesées","word_nosc":"empesees","lemma":"empeser","pos":"VER"} ,
		{"word":"empesés","word_nosc":"empeses","lemma":"empeser","pos":"VER"} ,
		{"word":"emphatise","word_nosc":"emphatise","lemma":"emphatiser","pos":"VER"} ,
		{"word":"empierrait","word_nosc":"empierrait","lemma":"empierrer","pos":"VER"} ,
		{"word":"empierrer","word_nosc":"empierrer","lemma":"empierrer","pos":"VER"} ,
		{"word":"empierré","word_nosc":"empierre","lemma":"empierrer","pos":"VER"} ,
		{"word":"empierrée","word_nosc":"empierree","lemma":"empierrer","pos":"VER"} ,
		{"word":"empiffra","word_nosc":"empiffra","lemma":"empiffrer","pos":"VER"} ,
		{"word":"empiffraient","word_nosc":"empiffraient","lemma":"empiffrer","pos":"VER"} ,
		{"word":"empiffrais","word_nosc":"empiffrais","lemma":"empiffrer","pos":"VER"} ,
		{"word":"empiffrait","word_nosc":"empiffrait","lemma":"empiffrer","pos":"VER"} ,
		{"word":"empiffrant","word_nosc":"empiffrant","lemma":"empiffrer","pos":"VER"} ,
		{"word":"empiffre","word_nosc":"empiffre","lemma":"empiffrer","pos":"VER"} ,
		{"word":"empiffrent","word_nosc":"empiffrent","lemma":"empiffrer","pos":"VER"} ,
		{"word":"empiffrer","word_nosc":"empiffrer","lemma":"empiffrer","pos":"VER"} ,
		{"word":"empiffres","word_nosc":"empiffres","lemma":"empiffrer","pos":"VER"} ,
		{"word":"empiffrez","word_nosc":"empiffrez","lemma":"empiffrer","pos":"VER"} ,
		{"word":"empiffrée","word_nosc":"empiffree","lemma":"empiffrer","pos":"VER"} ,
		{"word":"empiffrées","word_nosc":"empiffrees","lemma":"empiffrer","pos":"VER"} ,
		{"word":"empila","word_nosc":"empila","lemma":"empiler","pos":"VER"} ,
		{"word":"empilaient","word_nosc":"empilaient","lemma":"empiler","pos":"VER"} ,
		{"word":"empilais","word_nosc":"empilais","lemma":"empiler","pos":"VER"} ,
		{"word":"empilait","word_nosc":"empilait","lemma":"empiler","pos":"VER"} ,
		{"word":"empilant","word_nosc":"empilant","lemma":"empiler","pos":"VER"} ,
		{"word":"empile","word_nosc":"empile","lemma":"empiler","pos":"VER"} ,
		{"word":"empilent","word_nosc":"empilent","lemma":"empiler","pos":"VER"} ,
		{"word":"empiler","word_nosc":"empiler","lemma":"empiler","pos":"VER"} ,
		{"word":"empilerai","word_nosc":"empilerai","lemma":"empiler","pos":"VER"} ,
		{"word":"empilerions","word_nosc":"empilerions","lemma":"empiler","pos":"VER"} ,
		{"word":"empilez","word_nosc":"empilez","lemma":"empiler","pos":"VER"} ,
		{"word":"empilons","word_nosc":"empilons","lemma":"empiler","pos":"VER"} ,
		{"word":"empilèrent","word_nosc":"empilerent","lemma":"empiler","pos":"VER"} ,
		{"word":"empilé","word_nosc":"empile","lemma":"empiler","pos":"VER"} ,
		{"word":"empilée","word_nosc":"empilee","lemma":"empiler","pos":"VER"} ,
		{"word":"empilées","word_nosc":"empilees","lemma":"empiler","pos":"VER"} ,
		{"word":"empilés","word_nosc":"empiles","lemma":"empiler","pos":"VER"} ,
		{"word":"empira","word_nosc":"empira","lemma":"empirer","pos":"VER"} ,
		{"word":"empirait","word_nosc":"empirait","lemma":"empirer","pos":"VER"} ,
		{"word":"empirant","word_nosc":"empirant","lemma":"empirer","pos":"VER"} ,
		{"word":"empire","word_nosc":"empire","lemma":"empirer","pos":"VER"} ,
		{"word":"empirent","word_nosc":"empirent","lemma":"empirer","pos":"VER"} ,
		{"word":"empirer","word_nosc":"empirer","lemma":"empirer","pos":"VER"} ,
		{"word":"empirera","word_nosc":"empirera","lemma":"empirer","pos":"VER"} ,
		{"word":"empirerait","word_nosc":"empirerait","lemma":"empirer","pos":"VER"} ,
		{"word":"empireront","word_nosc":"empireront","lemma":"empirer","pos":"VER"} ,
		{"word":"empires","word_nosc":"empires","lemma":"empirer","pos":"VER"} ,
		{"word":"empirez","word_nosc":"empirez","lemma":"empirer","pos":"VER"} ,
		{"word":"empirèrent","word_nosc":"empirerent","lemma":"empirer","pos":"VER"} ,
		{"word":"empiré","word_nosc":"empire","lemma":"empirer","pos":"VER"} ,
		{"word":"empirée","word_nosc":"empiree","lemma":"empirer","pos":"VER"} ,
		{"word":"empiète","word_nosc":"empiete","lemma":"empiéter","pos":"VER"} ,
		{"word":"empiètent","word_nosc":"empietent","lemma":"empiéter","pos":"VER"} ,
		{"word":"empiètes","word_nosc":"empietes","lemma":"empiéter","pos":"VER"} ,
		{"word":"empiétaient","word_nosc":"empietaient","lemma":"empiéter","pos":"VER"} ,
		{"word":"empiétais","word_nosc":"empietais","lemma":"empiéter","pos":"VER"} ,
		{"word":"empiétait","word_nosc":"empietait","lemma":"empiéter","pos":"VER"} ,
		{"word":"empiétant","word_nosc":"empietant","lemma":"empiéter","pos":"VER"} ,
		{"word":"empiéter","word_nosc":"empieter","lemma":"empiéter","pos":"VER"} ,
		{"word":"empiétez","word_nosc":"empietez","lemma":"empiéter","pos":"VER"} ,
		{"word":"empiété","word_nosc":"empiete","lemma":"empiéter","pos":"VER"} ,
		{"word":"emplafonne","word_nosc":"emplafonne","lemma":"emplafonner","pos":"VER"} ,
		{"word":"emplafonnent","word_nosc":"emplafonnent","lemma":"emplafonner","pos":"VER"} ,
		{"word":"emplafonner","word_nosc":"emplafonner","lemma":"emplafonner","pos":"VER"} ,
		{"word":"emplafonné","word_nosc":"emplafonne","lemma":"emplafonner","pos":"VER"} ,
		{"word":"emplafonnée","word_nosc":"emplafonnee","lemma":"emplafonner","pos":"VER"} ,
		{"word":"empli","word_nosc":"empli","lemma":"emplir","pos":"VER"} ,
		{"word":"emplie","word_nosc":"emplie","lemma":"emplir","pos":"VER"} ,
		{"word":"emplies","word_nosc":"emplies","lemma":"emplir","pos":"VER"} ,
		{"word":"emplir","word_nosc":"emplir","lemma":"emplir","pos":"VER"} ,
		{"word":"emplira","word_nosc":"emplira","lemma":"emplir","pos":"VER"} ,
		{"word":"empliraient","word_nosc":"empliraient","lemma":"emplir","pos":"VER"} ,
		{"word":"emplirait","word_nosc":"emplirait","lemma":"emplir","pos":"VER"} ,
		{"word":"emplirent","word_nosc":"emplirent","lemma":"emplir","pos":"VER"} ,
		{"word":"emplis","word_nosc":"emplis","lemma":"emplir","pos":"VER"} ,
		{"word":"emplissaient","word_nosc":"emplissaient","lemma":"emplir","pos":"VER"} ,
		{"word":"emplissais","word_nosc":"emplissais","lemma":"emplir","pos":"VER"} ,
		{"word":"emplissait","word_nosc":"emplissait","lemma":"emplir","pos":"VER"} ,
		{"word":"emplissant","word_nosc":"emplissant","lemma":"emplir","pos":"VER"} ,
		{"word":"emplissent","word_nosc":"emplissent","lemma":"emplir","pos":"VER"} ,
		{"word":"emplissez","word_nosc":"emplissez","lemma":"emplir","pos":"VER"} ,
		{"word":"emplissons","word_nosc":"emplissons","lemma":"emplir","pos":"VER"} ,
		{"word":"emplit","word_nosc":"emplit","lemma":"emplir","pos":"VER"} ,
		{"word":"emploie","word_nosc":"emploie","lemma":"employer","pos":"VER"} ,
		{"word":"emploient","word_nosc":"emploient","lemma":"employer","pos":"VER"} ,
		{"word":"emploiera","word_nosc":"emploiera","lemma":"employer","pos":"VER"} ,
		{"word":"emploierai","word_nosc":"emploierai","lemma":"employer","pos":"VER"} ,
		{"word":"emploieraient","word_nosc":"emploieraient","lemma":"employer","pos":"VER"} ,
		{"word":"emploierais","word_nosc":"emploierais","lemma":"employer","pos":"VER"} ,
		{"word":"emploierait","word_nosc":"emploierait","lemma":"employer","pos":"VER"} ,
		{"word":"emploierez","word_nosc":"emploierez","lemma":"employer","pos":"VER"} ,
		{"word":"emploieriez","word_nosc":"emploieriez","lemma":"employer","pos":"VER"} ,
		{"word":"emploierons","word_nosc":"emploierons","lemma":"employer","pos":"VER"} ,
		{"word":"emploieront","word_nosc":"emploieront","lemma":"employer","pos":"VER"} ,
		{"word":"emploies","word_nosc":"emploies","lemma":"employer","pos":"VER"} ,
		{"word":"employa","word_nosc":"employa","lemma":"employer","pos":"VER"} ,
		{"word":"employai","word_nosc":"employai","lemma":"employer","pos":"VER"} ,
		{"word":"employaient","word_nosc":"employaient","lemma":"employer","pos":"VER"} ,
		{"word":"employais","word_nosc":"employais","lemma":"employer","pos":"VER"} ,
		{"word":"employait","word_nosc":"employait","lemma":"employer","pos":"VER"} ,
		{"word":"employant","word_nosc":"employant","lemma":"employer","pos":"VER"} ,
		{"word":"employer","word_nosc":"employer","lemma":"employer","pos":"VER"} ,
		{"word":"employez","word_nosc":"employez","lemma":"employer","pos":"VER"} ,
		{"word":"employiez","word_nosc":"employiez","lemma":"employer","pos":"VER"} ,
		{"word":"employions","word_nosc":"employions","lemma":"employer","pos":"VER"} ,
		{"word":"employons","word_nosc":"employons","lemma":"employer","pos":"VER"} ,
		{"word":"employâmes","word_nosc":"employames","lemma":"employer","pos":"VER"} ,
		{"word":"employât","word_nosc":"employat","lemma":"employer","pos":"VER"} ,
		{"word":"employèrent","word_nosc":"employerent","lemma":"employer","pos":"VER"} ,
		{"word":"employé","word_nosc":"employe","lemma":"employer","pos":"VER"} ,
		{"word":"employée","word_nosc":"employee","lemma":"employer","pos":"VER"} ,
		{"word":"employées","word_nosc":"employees","lemma":"employer","pos":"VER"} ,
		{"word":"employés","word_nosc":"employes","lemma":"employer","pos":"VER"} ,
		{"word":"empluma","word_nosc":"empluma","lemma":"emplumer","pos":"VER"} ,
		{"word":"emplume","word_nosc":"emplume","lemma":"emplumer","pos":"VER"} ,
		{"word":"emplumé","word_nosc":"emplume","lemma":"emplumer","pos":"VER"} ,
		{"word":"emplumée","word_nosc":"emplumee","lemma":"emplumer","pos":"VER"} ,
		{"word":"emplumées","word_nosc":"emplumees","lemma":"emplumer","pos":"VER"} ,
		{"word":"emplâtrais","word_nosc":"emplatrais","lemma":"emplâtrer","pos":"VER"} ,
		{"word":"emplâtrer","word_nosc":"emplatrer","lemma":"emplâtrer","pos":"VER"} ,
		{"word":"emplâtré","word_nosc":"emplatre","lemma":"emplâtrer","pos":"VER"} ,
		{"word":"emplît","word_nosc":"emplit","lemma":"emplir","pos":"VER"} ,
		{"word":"empocha","word_nosc":"empocha","lemma":"empocher","pos":"VER"} ,
		{"word":"empochai","word_nosc":"empochai","lemma":"empocher","pos":"VER"} ,
		{"word":"empochaient","word_nosc":"empochaient","lemma":"empocher","pos":"VER"} ,
		{"word":"empochais","word_nosc":"empochais","lemma":"empocher","pos":"VER"} ,
		{"word":"empochait","word_nosc":"empochait","lemma":"empocher","pos":"VER"} ,
		{"word":"empochant","word_nosc":"empochant","lemma":"empocher","pos":"VER"} ,
		{"word":"empoche","word_nosc":"empoche","lemma":"empocher","pos":"VER"} ,
		{"word":"empochent","word_nosc":"empochent","lemma":"empocher","pos":"VER"} ,
		{"word":"empocher","word_nosc":"empocher","lemma":"empocher","pos":"VER"} ,
		{"word":"empochera","word_nosc":"empochera","lemma":"empocher","pos":"VER"} ,
		{"word":"empocherais","word_nosc":"empocherais","lemma":"empocher","pos":"VER"} ,
		{"word":"empocheras","word_nosc":"empocheras","lemma":"empocher","pos":"VER"} ,
		{"word":"empoches","word_nosc":"empoches","lemma":"empocher","pos":"VER"} ,
		{"word":"empochez","word_nosc":"empochez","lemma":"empocher","pos":"VER"} ,
		{"word":"empochiez","word_nosc":"empochiez","lemma":"empocher","pos":"VER"} ,
		{"word":"empoché","word_nosc":"empoche","lemma":"empocher","pos":"VER"} ,
		{"word":"empochée","word_nosc":"empochee","lemma":"empocher","pos":"VER"} ,
		{"word":"empochées","word_nosc":"empochees","lemma":"empocher","pos":"VER"} ,
		{"word":"empochés","word_nosc":"empoches","lemma":"empocher","pos":"VER"} ,
		{"word":"empoigna","word_nosc":"empoigna","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoignai","word_nosc":"empoignai","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoignaient","word_nosc":"empoignaient","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoignais","word_nosc":"empoignais","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoignait","word_nosc":"empoignait","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoignant","word_nosc":"empoignant","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoigne","word_nosc":"empoigne","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoignent","word_nosc":"empoignent","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoigner","word_nosc":"empoigner","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoignera","word_nosc":"empoignera","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoigneront","word_nosc":"empoigneront","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoignes","word_nosc":"empoignes","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoignez","word_nosc":"empoignez","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoignons","word_nosc":"empoignons","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoignèrent","word_nosc":"empoignerent","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoigné","word_nosc":"empoigne","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoignée","word_nosc":"empoignee","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoignées","word_nosc":"empoignees","lemma":"empoigner","pos":"VER"} ,
		{"word":"empoiler","word_nosc":"empoiler","lemma":"empoiler","pos":"VER"} ,
		{"word":"empoilés","word_nosc":"empoiles","lemma":"empoiler","pos":"VER"} ,
		{"word":"empoisonna","word_nosc":"empoisonna","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonnaient","word_nosc":"empoisonnaient","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonnais","word_nosc":"empoisonnais","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonnait","word_nosc":"empoisonnait","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonnant","word_nosc":"empoisonnant","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonne","word_nosc":"empoisonne","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonnent","word_nosc":"empoisonnent","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonner","word_nosc":"empoisonner","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonnera","word_nosc":"empoisonnera","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonnerai","word_nosc":"empoisonnerai","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonneraient","word_nosc":"empoisonneraient","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonnerais","word_nosc":"empoisonnerais","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonnerez","word_nosc":"empoisonnerez","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonneront","word_nosc":"empoisonneront","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonnes","word_nosc":"empoisonnes","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonnez","word_nosc":"empoisonnez","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonné","word_nosc":"empoisonne","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonnée","word_nosc":"empoisonnee","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonnées","word_nosc":"empoisonnees","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoisonnés","word_nosc":"empoisonnes","lemma":"empoisonner","pos":"VER"} ,
		{"word":"empoissait","word_nosc":"empoissait","lemma":"empoisser","pos":"VER"} ,
		{"word":"empoisse","word_nosc":"empoisse","lemma":"empoisser","pos":"VER"} ,
		{"word":"empoisser","word_nosc":"empoisser","lemma":"empoisser","pos":"VER"} ,
		{"word":"empoissonner","word_nosc":"empoissonner","lemma":"empoissonner","pos":"VER"} ,
		{"word":"empoissonné","word_nosc":"empoissonne","lemma":"empoissonner","pos":"VER"} ,
		{"word":"empoissonnées","word_nosc":"empoissonnees","lemma":"empoissonner","pos":"VER"} ,
		{"word":"empoissé","word_nosc":"empoisse","lemma":"empoisser","pos":"VER"} ,
		{"word":"empoissés","word_nosc":"empoisses","lemma":"empoisser","pos":"VER"} ,
		{"word":"emporta","word_nosc":"emporta","lemma":"emporter","pos":"VER"} ,
		{"word":"emportai","word_nosc":"emportai","lemma":"emporter","pos":"VER"} ,
		{"word":"emportaient","word_nosc":"emportaient","lemma":"emporter","pos":"VER"} ,
		{"word":"emportais","word_nosc":"emportais","lemma":"emporter","pos":"VER"} ,
		{"word":"emportait","word_nosc":"emportait","lemma":"emporter","pos":"VER"} ,
		{"word":"emportant","word_nosc":"emportant","lemma":"emporter","pos":"VER"} ,
		{"word":"emporte","word_nosc":"emporte","lemma":"emporter","pos":"VER"} ,
		{"word":"emportent","word_nosc":"emportent","lemma":"emporter","pos":"VER"} ,
		{"word":"emporter","word_nosc":"emporter","lemma":"emporter","pos":"VER"} ,
		{"word":"emportera","word_nosc":"emportera","lemma":"emporter","pos":"VER"} ,
		{"word":"emporterai","word_nosc":"emporterai","lemma":"emporter","pos":"VER"} ,
		{"word":"emporteraient","word_nosc":"emporteraient","lemma":"emporter","pos":"VER"} ,
		{"word":"emporterais","word_nosc":"emporterais","lemma":"emporter","pos":"VER"} ,
		{"word":"emporterait","word_nosc":"emporterait","lemma":"emporter","pos":"VER"} ,
		{"word":"emporteras","word_nosc":"emporteras","lemma":"emporter","pos":"VER"} ,
		{"word":"emporterez","word_nosc":"emporterez","lemma":"emporter","pos":"VER"} ,
		{"word":"emporteriez","word_nosc":"emporteriez","lemma":"emporter","pos":"VER"} ,
		{"word":"emporterions","word_nosc":"emporterions","lemma":"emporter","pos":"VER"} ,
		{"word":"emporterons","word_nosc":"emporterons","lemma":"emporter","pos":"VER"} ,
		{"word":"emporteront","word_nosc":"emporteront","lemma":"emporter","pos":"VER"} ,
		{"word":"emportes","word_nosc":"emportes","lemma":"emporter","pos":"VER"} ,
		{"word":"emportez","word_nosc":"emportez","lemma":"emporter","pos":"VER"} ,
		{"word":"emportiez","word_nosc":"emportiez","lemma":"emporter","pos":"VER"} ,
		{"word":"emportions","word_nosc":"emportions","lemma":"emporter","pos":"VER"} ,
		{"word":"emportons","word_nosc":"emportons","lemma":"emporter","pos":"VER"} ,
		{"word":"emportât","word_nosc":"emportat","lemma":"emporter","pos":"VER"} ,
		{"word":"emportèrent","word_nosc":"emporterent","lemma":"emporter","pos":"VER"} ,
		{"word":"emporté","word_nosc":"emporte","lemma":"emporter","pos":"VER"} ,
		{"word":"emportée","word_nosc":"emportee","lemma":"emporter","pos":"VER"} ,
		{"word":"emportées","word_nosc":"emportees","lemma":"emporter","pos":"VER"} ,
		{"word":"emportés","word_nosc":"emportes","lemma":"emporter","pos":"VER"} ,
		{"word":"empoté","word_nosc":"empote","lemma":"empoter","pos":"VER"} ,
		{"word":"empotée","word_nosc":"empotee","lemma":"empoter","pos":"VER"} ,
		{"word":"empotés","word_nosc":"empotes","lemma":"empoter","pos":"VER"} ,
		{"word":"empourpra","word_nosc":"empourpra","lemma":"empourprer","pos":"VER"} ,
		{"word":"empourprait","word_nosc":"empourprait","lemma":"empourprer","pos":"VER"} ,
		{"word":"empourprant","word_nosc":"empourprant","lemma":"empourprer","pos":"VER"} ,
		{"word":"empourpre","word_nosc":"empourpre","lemma":"empourprer","pos":"VER"} ,
		{"word":"empourprent","word_nosc":"empourprent","lemma":"empourprer","pos":"VER"} ,
		{"word":"empourprer","word_nosc":"empourprer","lemma":"empourprer","pos":"VER"} ,
		{"word":"empourpré","word_nosc":"empourpre","lemma":"empourprer","pos":"VER"} ,
		{"word":"empourprée","word_nosc":"empourpree","lemma":"empourprer","pos":"VER"} ,
		{"word":"empourprées","word_nosc":"empourprees","lemma":"empourprer","pos":"VER"} ,
		{"word":"empourprés","word_nosc":"empourpres","lemma":"empourprer","pos":"VER"} ,
		{"word":"empoussière","word_nosc":"empoussiere","lemma":"empoussiérer","pos":"VER"} ,
		{"word":"empoussièrent","word_nosc":"empoussierent","lemma":"empoussiérer","pos":"VER"} ,
		{"word":"empoussiérait","word_nosc":"empoussierait","lemma":"empoussiérer","pos":"VER"} ,
		{"word":"empoussiéré","word_nosc":"empoussiere","lemma":"empoussiérer","pos":"VER"} ,
		{"word":"empoussiérée","word_nosc":"empoussieree","lemma":"empoussiérer","pos":"VER"} ,
		{"word":"empoussiérés","word_nosc":"empoussieres","lemma":"empoussiérer","pos":"VER"} ,
		{"word":"empreignit","word_nosc":"empreignit","lemma":"empreindre","pos":"VER"} ,
		{"word":"empreint","word_nosc":"empreint","lemma":"empreindre","pos":"VER"} ,
		{"word":"empreints","word_nosc":"empreints","lemma":"empreindre","pos":"VER"} ,
		{"word":"empressa","word_nosc":"empressa","lemma":"empresser","pos":"VER"} ,
		{"word":"empressai","word_nosc":"empressai","lemma":"empresser","pos":"VER"} ,
		{"word":"empressaient","word_nosc":"empressaient","lemma":"empresser","pos":"VER"} ,
		{"word":"empressais","word_nosc":"empressais","lemma":"empresser","pos":"VER"} ,
		{"word":"empressait","word_nosc":"empressait","lemma":"empresser","pos":"VER"} ,
		{"word":"empressant","word_nosc":"empressant","lemma":"empresser","pos":"VER"} ,
		{"word":"empresse","word_nosc":"empresse","lemma":"empresser","pos":"VER"} ,
		{"word":"empressent","word_nosc":"empressent","lemma":"empresser","pos":"VER"} ,
		{"word":"empresser","word_nosc":"empresser","lemma":"empresser","pos":"VER"} ,
		{"word":"empressera","word_nosc":"empressera","lemma":"empresser","pos":"VER"} ,
		{"word":"empresserait","word_nosc":"empresserait","lemma":"empresser","pos":"VER"} ,
		{"word":"empresseras","word_nosc":"empresseras","lemma":"empresser","pos":"VER"} ,
		{"word":"empresserez","word_nosc":"empresserez","lemma":"empresser","pos":"VER"} ,
		{"word":"empresses","word_nosc":"empresses","lemma":"empresser","pos":"VER"} ,
		{"word":"empressâmes","word_nosc":"empressames","lemma":"empresser","pos":"VER"} ,
		{"word":"empressèrent","word_nosc":"empresserent","lemma":"empresser","pos":"VER"} ,
		{"word":"empressé","word_nosc":"empresse","lemma":"empresser","pos":"VER"} ,
		{"word":"empressée","word_nosc":"empressee","lemma":"empresser","pos":"VER"} ,
		{"word":"empressées","word_nosc":"empressees","lemma":"empresser","pos":"VER"} ,
		{"word":"empressés","word_nosc":"empresses","lemma":"empresser","pos":"VER"} ,
		{"word":"emprisonna","word_nosc":"emprisonna","lemma":"emprisonner","pos":"VER"} ,
		{"word":"emprisonnaient","word_nosc":"emprisonnaient","lemma":"emprisonner","pos":"VER"} ,
		{"word":"emprisonnait","word_nosc":"emprisonnait","lemma":"emprisonner","pos":"VER"} ,
		{"word":"emprisonnant","word_nosc":"emprisonnant","lemma":"emprisonner","pos":"VER"} ,
		{"word":"emprisonne","word_nosc":"emprisonne","lemma":"emprisonner","pos":"VER"} ,
		{"word":"emprisonnent","word_nosc":"emprisonnent","lemma":"emprisonner","pos":"VER"} ,
		{"word":"emprisonner","word_nosc":"emprisonner","lemma":"emprisonner","pos":"VER"} ,
		{"word":"emprisonnera","word_nosc":"emprisonnera","lemma":"emprisonner","pos":"VER"} ,
		{"word":"emprisonnez","word_nosc":"emprisonnez","lemma":"emprisonner","pos":"VER"} ,
		{"word":"emprisonnèrent","word_nosc":"emprisonnerent","lemma":"emprisonner","pos":"VER"} ,
		{"word":"emprisonné","word_nosc":"emprisonne","lemma":"emprisonner","pos":"VER"} ,
		{"word":"emprisonnée","word_nosc":"emprisonnee","lemma":"emprisonner","pos":"VER"} ,
		{"word":"emprisonnées","word_nosc":"emprisonnees","lemma":"emprisonner","pos":"VER"} ,
		{"word":"emprisonnés","word_nosc":"emprisonnes","lemma":"emprisonner","pos":"VER"} ,
		{"word":"emprunta","word_nosc":"emprunta","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntai","word_nosc":"empruntai","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntaient","word_nosc":"empruntaient","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntais","word_nosc":"empruntais","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntait","word_nosc":"empruntait","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntant","word_nosc":"empruntant","lemma":"emprunter","pos":"VER"} ,
		{"word":"emprunte","word_nosc":"emprunte","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntent","word_nosc":"empruntent","lemma":"emprunter","pos":"VER"} ,
		{"word":"emprunter","word_nosc":"emprunter","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntera","word_nosc":"empruntera","lemma":"emprunter","pos":"VER"} ,
		{"word":"emprunterai","word_nosc":"emprunterai","lemma":"emprunter","pos":"VER"} ,
		{"word":"emprunteraient","word_nosc":"emprunteraient","lemma":"emprunter","pos":"VER"} ,
		{"word":"emprunterais","word_nosc":"emprunterais","lemma":"emprunter","pos":"VER"} ,
		{"word":"emprunterait","word_nosc":"emprunterait","lemma":"emprunter","pos":"VER"} ,
		{"word":"emprunteras","word_nosc":"emprunteras","lemma":"emprunter","pos":"VER"} ,
		{"word":"emprunterons","word_nosc":"emprunterons","lemma":"emprunter","pos":"VER"} ,
		{"word":"emprunteront","word_nosc":"emprunteront","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntes","word_nosc":"empruntes","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntez","word_nosc":"empruntez","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntions","word_nosc":"empruntions","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntons","word_nosc":"empruntons","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntâmes","word_nosc":"empruntames","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntât","word_nosc":"empruntat","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntèrent","word_nosc":"emprunterent","lemma":"emprunter","pos":"VER"} ,
		{"word":"emprunté","word_nosc":"emprunte","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntée","word_nosc":"empruntee","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntées","word_nosc":"empruntees","lemma":"emprunter","pos":"VER"} ,
		{"word":"empruntés","word_nosc":"empruntes","lemma":"emprunter","pos":"VER"} ,
		{"word":"empuanti","word_nosc":"empuanti","lemma":"empuantir","pos":"VER"} ,
		{"word":"empuantie","word_nosc":"empuantie","lemma":"empuantir","pos":"VER"} ,
		{"word":"empuanties","word_nosc":"empuanties","lemma":"empuantir","pos":"VER"} ,
		{"word":"empuantir","word_nosc":"empuantir","lemma":"empuantir","pos":"VER"} ,
		{"word":"empuantis","word_nosc":"empuantis","lemma":"empuantir","pos":"VER"} ,
		{"word":"empuantissaient","word_nosc":"empuantissaient","lemma":"empuantir","pos":"VER"} ,
		{"word":"empuantissait","word_nosc":"empuantissait","lemma":"empuantir","pos":"VER"} ,
		{"word":"empuantisse","word_nosc":"empuantisse","lemma":"empuantir","pos":"VER"} ,
		{"word":"empuantissent","word_nosc":"empuantissent","lemma":"empuantir","pos":"VER"} ,
		{"word":"empâta","word_nosc":"empata","lemma":"empâter","pos":"VER"} ,
		{"word":"empâtait","word_nosc":"empatait","lemma":"empâter","pos":"VER"} ,
		{"word":"empâte","word_nosc":"empate","lemma":"empâter","pos":"VER"} ,
		{"word":"empâtent","word_nosc":"empatent","lemma":"empâter","pos":"VER"} ,
		{"word":"empâter","word_nosc":"empater","lemma":"empâter","pos":"VER"} ,
		{"word":"empâté","word_nosc":"empate","lemma":"empâter","pos":"VER"} ,
		{"word":"empâtée","word_nosc":"empatee","lemma":"empâter","pos":"VER"} ,
		{"word":"empâtés","word_nosc":"empates","lemma":"empâter","pos":"VER"} ,
		{"word":"empêcha","word_nosc":"empecha","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêchai","word_nosc":"empechai","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêchaient","word_nosc":"empechaient","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêchais","word_nosc":"empechais","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêchait","word_nosc":"empechait","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêchant","word_nosc":"empechant","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêche","word_nosc":"empeche","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêchent","word_nosc":"empechent","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêcher","word_nosc":"empecher","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêchera","word_nosc":"empechera","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêcherai","word_nosc":"empecherai","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêcheraient","word_nosc":"empecheraient","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêcherais","word_nosc":"empecherais","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêcherait","word_nosc":"empecherait","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêcheras","word_nosc":"empecheras","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêcherez","word_nosc":"empecherez","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêcherons","word_nosc":"empecherons","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêcheront","word_nosc":"empecheront","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêches","word_nosc":"empeches","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêchez","word_nosc":"empechez","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêchions","word_nosc":"empechions","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêchons","word_nosc":"empechons","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêchât","word_nosc":"empechat","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêchèrent","word_nosc":"empecherent","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêché","word_nosc":"empeche","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêchée","word_nosc":"empechee","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêchées","word_nosc":"empechees","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêchés","word_nosc":"empeches","lemma":"empêcher","pos":"VER"} ,
		{"word":"empêtra","word_nosc":"empetra","lemma":"empêtrer","pos":"VER"} ,
		{"word":"empêtraient","word_nosc":"empetraient","lemma":"empêtrer","pos":"VER"} ,
		{"word":"empêtrais","word_nosc":"empetrais","lemma":"empêtrer","pos":"VER"} ,
		{"word":"empêtrait","word_nosc":"empetrait","lemma":"empêtrer","pos":"VER"} ,
		{"word":"empêtrant","word_nosc":"empetrant","lemma":"empêtrer","pos":"VER"} ,
		{"word":"empêtre","word_nosc":"empetre","lemma":"empêtrer","pos":"VER"} ,
		{"word":"empêtrent","word_nosc":"empetrent","lemma":"empêtrer","pos":"VER"} ,
		{"word":"empêtrer","word_nosc":"empetrer","lemma":"empêtrer","pos":"VER"} ,
		{"word":"empêtrons","word_nosc":"empetrons","lemma":"empêtrer","pos":"VER"} ,
		{"word":"empêtré","word_nosc":"empetre","lemma":"empêtrer","pos":"VER"} ,
		{"word":"empêtrée","word_nosc":"empetree","lemma":"empêtrer","pos":"VER"} ,
		{"word":"empêtrées","word_nosc":"empetrees","lemma":"empêtrer","pos":"VER"} ,
		{"word":"empêtrés","word_nosc":"empetres","lemma":"empêtrer","pos":"VER"} ,
		{"word":"enamouré","word_nosc":"enamoure","lemma":"enamourer","pos":"VER"} ,
		{"word":"enamourée","word_nosc":"enamouree","lemma":"enamourer","pos":"VER"} ,
		{"word":"enamourés","word_nosc":"enamoures","lemma":"enamourer","pos":"VER"} ,
		{"word":"encabanée","word_nosc":"encabanee","lemma":"encabaner","pos":"VER"} ,
		{"word":"encadra","word_nosc":"encadra","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadraient","word_nosc":"encadraient","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadrait","word_nosc":"encadrait","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadre","word_nosc":"encadre","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadrent","word_nosc":"encadrent","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadrer","word_nosc":"encadrer","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadrera","word_nosc":"encadrera","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadrerai","word_nosc":"encadrerai","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadreraient","word_nosc":"encadreraient","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadrerait","word_nosc":"encadrerait","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadreront","word_nosc":"encadreront","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadrez","word_nosc":"encadrez","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadrions","word_nosc":"encadrions","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadrèrent","word_nosc":"encadrerent","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadré","word_nosc":"encadre","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadrée","word_nosc":"encadree","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadrées","word_nosc":"encadrees","lemma":"encadrer","pos":"VER"} ,
		{"word":"encadrés","word_nosc":"encadres","lemma":"encadrer","pos":"VER"} ,
		{"word":"encagent","word_nosc":"encagent","lemma":"encager","pos":"VER"} ,
		{"word":"encager","word_nosc":"encager","lemma":"encager","pos":"VER"} ,
		{"word":"encagez","word_nosc":"encagez","lemma":"encager","pos":"VER"} ,
		{"word":"encagé","word_nosc":"encage","lemma":"encager","pos":"VER"} ,
		{"word":"encagée","word_nosc":"encagee","lemma":"encager","pos":"VER"} ,
		{"word":"encagés","word_nosc":"encages","lemma":"encager","pos":"VER"} ,
		{"word":"encaissa","word_nosc":"encaissa","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaissai","word_nosc":"encaissai","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaissaient","word_nosc":"encaissaient","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaissais","word_nosc":"encaissais","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaissait","word_nosc":"encaissait","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaissant","word_nosc":"encaissant","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaisse","word_nosc":"encaisse","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaissent","word_nosc":"encaissent","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaisser","word_nosc":"encaisser","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaissera","word_nosc":"encaissera","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaisserai","word_nosc":"encaisserai","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaisserais","word_nosc":"encaisserais","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaisserait","word_nosc":"encaisserait","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaisserez","word_nosc":"encaisserez","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaisseront","word_nosc":"encaisseront","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaisses","word_nosc":"encaisses","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaissez","word_nosc":"encaissez","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaissons","word_nosc":"encaissons","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaissât","word_nosc":"encaissat","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaissé","word_nosc":"encaisse","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaissée","word_nosc":"encaissee","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaissées","word_nosc":"encaissees","lemma":"encaisser","pos":"VER"} ,
		{"word":"encaissés","word_nosc":"encaisses","lemma":"encaisser","pos":"VER"} ,
		{"word":"encanaillais","word_nosc":"encanaillais","lemma":"encanailler","pos":"VER"} ,
		{"word":"encanaillait","word_nosc":"encanaillait","lemma":"encanailler","pos":"VER"} ,
		{"word":"encanaille","word_nosc":"encanaille","lemma":"encanailler","pos":"VER"} ,
		{"word":"encanaillent","word_nosc":"encanaillent","lemma":"encanailler","pos":"VER"} ,
		{"word":"encanailler","word_nosc":"encanailler","lemma":"encanailler","pos":"VER"} ,
		{"word":"encanaillé","word_nosc":"encanaille","lemma":"encanailler","pos":"VER"} ,
		{"word":"encapsuler","word_nosc":"encapsuler","lemma":"encapsuler","pos":"VER"} ,
		{"word":"encapsulé","word_nosc":"encapsule","lemma":"encapsuler","pos":"VER"} ,
		{"word":"encapuchonna","word_nosc":"encapuchonna","lemma":"encapuchonner","pos":"VER"} ,
		{"word":"encapuchonnait","word_nosc":"encapuchonnait","lemma":"encapuchonner","pos":"VER"} ,
		{"word":"encapuchonnant","word_nosc":"encapuchonnant","lemma":"encapuchonner","pos":"VER"} ,
		{"word":"encapuchonne","word_nosc":"encapuchonne","lemma":"encapuchonner","pos":"VER"} ,
		{"word":"encapuchonné","word_nosc":"encapuchonne","lemma":"encapuchonner","pos":"VER"} ,
		{"word":"encapuchonnée","word_nosc":"encapuchonnee","lemma":"encapuchonner","pos":"VER"} ,
		{"word":"encapuchonnées","word_nosc":"encapuchonnees","lemma":"encapuchonner","pos":"VER"} ,
		{"word":"encapuchonnés","word_nosc":"encapuchonnes","lemma":"encapuchonner","pos":"VER"} ,
		{"word":"encaqués","word_nosc":"encaques","lemma":"encaquer","pos":"VER"} ,
		{"word":"encartait","word_nosc":"encartait","lemma":"encarter","pos":"VER"} ,
		{"word":"encarter","word_nosc":"encarter","lemma":"encarter","pos":"VER"} ,
		{"word":"encarté","word_nosc":"encarte","lemma":"encarter","pos":"VER"} ,
		{"word":"encartées","word_nosc":"encartees","lemma":"encarter","pos":"VER"} ,
		{"word":"encartés","word_nosc":"encartes","lemma":"encarter","pos":"VER"} ,
		{"word":"encaserner","word_nosc":"encaserner","lemma":"encaserner","pos":"VER"} ,
		{"word":"encastelé","word_nosc":"encastele","lemma":"encasteler","pos":"VER"} ,
		{"word":"encastra","word_nosc":"encastra","lemma":"encastrer","pos":"VER"} ,
		{"word":"encastraient","word_nosc":"encastraient","lemma":"encastrer","pos":"VER"} ,
		{"word":"encastrait","word_nosc":"encastrait","lemma":"encastrer","pos":"VER"} ,
		{"word":"encastrant","word_nosc":"encastrant","lemma":"encastrer","pos":"VER"} ,
		{"word":"encastre","word_nosc":"encastre","lemma":"encastrer","pos":"VER"} ,
		{"word":"encastrent","word_nosc":"encastrent","lemma":"encastrer","pos":"VER"} ,
		{"word":"encastrer","word_nosc":"encastrer","lemma":"encastrer","pos":"VER"} ,
		{"word":"encastré","word_nosc":"encastre","lemma":"encastrer","pos":"VER"} ,
		{"word":"encastrée","word_nosc":"encastree","lemma":"encastrer","pos":"VER"} ,
		{"word":"encastrées","word_nosc":"encastrees","lemma":"encastrer","pos":"VER"} ,
		{"word":"encastrés","word_nosc":"encastres","lemma":"encastrer","pos":"VER"} ,
		{"word":"encaustiquait","word_nosc":"encaustiquait","lemma":"encaustiquer","pos":"VER"} ,
		{"word":"encaustique","word_nosc":"encaustique","lemma":"encaustiquer","pos":"VER"} ,
		{"word":"encaustiquer","word_nosc":"encaustiquer","lemma":"encaustiquer","pos":"VER"} ,
		{"word":"encaver","word_nosc":"encaver","lemma":"encaver","pos":"VER"} ,
		{"word":"encavée","word_nosc":"encavee","lemma":"encaver","pos":"VER"} ,
		{"word":"enceint","word_nosc":"enceint","lemma":"enceindre","pos":"VER"} ,
		{"word":"enceinter","word_nosc":"enceinter","lemma":"enceinter","pos":"VER"} ,
		{"word":"enceints","word_nosc":"enceints","lemma":"enceindre","pos":"VER"} ,
		{"word":"encellulée","word_nosc":"encellulee","lemma":"encelluler","pos":"VER"} ,
		{"word":"encellulées","word_nosc":"encellulees","lemma":"encelluler","pos":"VER"} ,
		{"word":"encensaient","word_nosc":"encensaient","lemma":"encenser","pos":"VER"} ,
		{"word":"encensait","word_nosc":"encensait","lemma":"encenser","pos":"VER"} ,
		{"word":"encensant","word_nosc":"encensant","lemma":"encenser","pos":"VER"} ,
		{"word":"encense","word_nosc":"encense","lemma":"encenser","pos":"VER"} ,
		{"word":"encenser","word_nosc":"encenser","lemma":"encenser","pos":"VER"} ,
		{"word":"encensé","word_nosc":"encense","lemma":"encenser","pos":"VER"} ,
		{"word":"encensés","word_nosc":"encenses","lemma":"encenser","pos":"VER"} ,
		{"word":"encercla","word_nosc":"encercla","lemma":"encercler","pos":"VER"} ,
		{"word":"encerclaient","word_nosc":"encerclaient","lemma":"encercler","pos":"VER"} ,
		{"word":"encerclais","word_nosc":"encerclais","lemma":"encercler","pos":"VER"} ,
		{"word":"encerclait","word_nosc":"encerclait","lemma":"encercler","pos":"VER"} ,
		{"word":"encerclant","word_nosc":"encerclant","lemma":"encercler","pos":"VER"} ,
		{"word":"encercle","word_nosc":"encercle","lemma":"encercler","pos":"VER"} ,
		{"word":"encerclent","word_nosc":"encerclent","lemma":"encercler","pos":"VER"} ,
		{"word":"encercler","word_nosc":"encercler","lemma":"encercler","pos":"VER"} ,
		{"word":"encerclera","word_nosc":"encerclera","lemma":"encercler","pos":"VER"} ,
		{"word":"encercleraient","word_nosc":"encercleraient","lemma":"encercler","pos":"VER"} ,
		{"word":"encerclez","word_nosc":"encerclez","lemma":"encercler","pos":"VER"} ,
		{"word":"encerclons","word_nosc":"encerclons","lemma":"encercler","pos":"VER"} ,
		{"word":"encerclèrent","word_nosc":"encerclerent","lemma":"encercler","pos":"VER"} ,
		{"word":"encerclé","word_nosc":"encercle","lemma":"encercler","pos":"VER"} ,
		{"word":"encerclée","word_nosc":"encerclee","lemma":"encercler","pos":"VER"} ,
		{"word":"encerclées","word_nosc":"encerclees","lemma":"encercler","pos":"VER"} ,
		{"word":"encerclés","word_nosc":"encercles","lemma":"encercler","pos":"VER"} ,
		{"word":"enchanta","word_nosc":"enchanta","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchantaient","word_nosc":"enchantaient","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchantais","word_nosc":"enchantais","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchantait","word_nosc":"enchantait","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchantant","word_nosc":"enchantant","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchante","word_nosc":"enchante","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchantent","word_nosc":"enchantent","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchanter","word_nosc":"enchanter","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchantera","word_nosc":"enchantera","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchanteraient","word_nosc":"enchanteraient","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchanterait","word_nosc":"enchanterait","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchanteront","word_nosc":"enchanteront","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchantes","word_nosc":"enchantes","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchantez","word_nosc":"enchantez","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchantions","word_nosc":"enchantions","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchantèrent","word_nosc":"enchanterent","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchanté","word_nosc":"enchante","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchantée","word_nosc":"enchantee","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchantées","word_nosc":"enchantees","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchantés","word_nosc":"enchantes","lemma":"enchanter","pos":"VER"} ,
		{"word":"enchaîna","word_nosc":"enchaina","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînai","word_nosc":"enchainai","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînaient","word_nosc":"enchainaient","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînais","word_nosc":"enchainais","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînait","word_nosc":"enchainait","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînant","word_nosc":"enchainant","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaîne","word_nosc":"enchaine","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînent","word_nosc":"enchainent","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaîner","word_nosc":"enchainer","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînera","word_nosc":"enchainera","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaîneraient","word_nosc":"enchaineraient","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînerait","word_nosc":"enchainerait","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaîneras","word_nosc":"enchaineras","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînerez","word_nosc":"enchainerez","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînerons","word_nosc":"enchainerons","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînez","word_nosc":"enchainez","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînons","word_nosc":"enchainons","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînèrent","word_nosc":"enchainerent","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaîné","word_nosc":"enchaine","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînée","word_nosc":"enchainee","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînées","word_nosc":"enchainees","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchaînés","word_nosc":"enchaines","lemma":"enchaîner","pos":"VER"} ,
		{"word":"enchemisé","word_nosc":"enchemise","lemma":"enchemiser","pos":"VER"} ,
		{"word":"enchevêtraient","word_nosc":"enchevetraient","lemma":"enchevêtrer","pos":"VER"} ,
		{"word":"enchevêtrais","word_nosc":"enchevetrais","lemma":"enchevêtrer","pos":"VER"} ,
		{"word":"enchevêtrant","word_nosc":"enchevetrant","lemma":"enchevêtrer","pos":"VER"} ,
		{"word":"enchevêtre","word_nosc":"enchevetre","lemma":"enchevêtrer","pos":"VER"} ,
		{"word":"enchevêtrent","word_nosc":"enchevetrent","lemma":"enchevêtrer","pos":"VER"} ,
		{"word":"enchevêtré","word_nosc":"enchevetre","lemma":"enchevêtrer","pos":"VER"} ,
		{"word":"enchevêtrée","word_nosc":"enchevetree","lemma":"enchevêtrer","pos":"VER"} ,
		{"word":"enchevêtrées","word_nosc":"enchevetrees","lemma":"enchevêtrer","pos":"VER"} ,
		{"word":"enchevêtrés","word_nosc":"enchevetres","lemma":"enchevêtrer","pos":"VER"} ,
		{"word":"enchifrené","word_nosc":"enchifrene","lemma":"enchifrener","pos":"VER"} ,
		{"word":"enchriste","word_nosc":"enchriste","lemma":"enchrister","pos":"VER"} ,
		{"word":"enchrister","word_nosc":"enchrister","lemma":"enchrister","pos":"VER"} ,
		{"word":"enchristé","word_nosc":"enchriste","lemma":"enchrister","pos":"VER"} ,
		{"word":"enchristée","word_nosc":"enchristee","lemma":"enchrister","pos":"VER"} ,
		{"word":"enchtiber","word_nosc":"enchtiber","lemma":"enchtiber","pos":"VER"} ,
		{"word":"enchtibé","word_nosc":"enchtibe","lemma":"enchtiber","pos":"VER"} ,
		{"word":"enchtibés","word_nosc":"enchtibes","lemma":"enchtiber","pos":"VER"} ,
		{"word":"enchâssaient","word_nosc":"enchassaient","lemma":"enchâsser","pos":"VER"} ,
		{"word":"enchâssait","word_nosc":"enchassait","lemma":"enchâsser","pos":"VER"} ,
		{"word":"enchâssant","word_nosc":"enchassant","lemma":"enchâsser","pos":"VER"} ,
		{"word":"enchâsse","word_nosc":"enchasse","lemma":"enchâsser","pos":"VER"} ,
		{"word":"enchâsser","word_nosc":"enchasser","lemma":"enchâsser","pos":"VER"} ,
		{"word":"enchâssé","word_nosc":"enchasse","lemma":"enchâsser","pos":"VER"} ,
		{"word":"enchâssée","word_nosc":"enchassee","lemma":"enchâsser","pos":"VER"} ,
		{"word":"enchâssés","word_nosc":"enchasses","lemma":"enchâsser","pos":"VER"} ,
		{"word":"enchéri","word_nosc":"encheri","lemma":"enchérir","pos":"VER"} ,
		{"word":"enchérir","word_nosc":"encherir","lemma":"enchérir","pos":"VER"} ,
		{"word":"enchérissant","word_nosc":"encherissant","lemma":"enchérir","pos":"VER"} ,
		{"word":"enchérisse","word_nosc":"encherisse","lemma":"enchérir","pos":"VER"} ,
		{"word":"enchérit","word_nosc":"encherit","lemma":"enchérir","pos":"VER"} ,
		{"word":"enclavé","word_nosc":"enclave","lemma":"enclaver","pos":"VER"} ,
		{"word":"enclavée","word_nosc":"enclavee","lemma":"enclaver","pos":"VER"} ,
		{"word":"enclencha","word_nosc":"enclencha","lemma":"enclencher","pos":"VER"} ,
		{"word":"enclenchai","word_nosc":"enclenchai","lemma":"enclencher","pos":"VER"} ,
		{"word":"enclenchait","word_nosc":"enclenchait","lemma":"enclencher","pos":"VER"} ,
		{"word":"enclenchant","word_nosc":"enclenchant","lemma":"enclencher","pos":"VER"} ,
		{"word":"enclenche","word_nosc":"enclenche","lemma":"enclencher","pos":"VER"} ,
		{"word":"enclenchent","word_nosc":"enclenchent","lemma":"enclencher","pos":"VER"} ,
		{"word":"enclencher","word_nosc":"enclencher","lemma":"enclencher","pos":"VER"} ,
		{"word":"enclenchera","word_nosc":"enclenchera","lemma":"enclencher","pos":"VER"} ,
		{"word":"enclenches","word_nosc":"enclenches","lemma":"enclencher","pos":"VER"} ,
		{"word":"enclenchez","word_nosc":"enclenchez","lemma":"enclencher","pos":"VER"} ,
		{"word":"enclenché","word_nosc":"enclenche","lemma":"enclencher","pos":"VER"} ,
		{"word":"enclenchée","word_nosc":"enclenchee","lemma":"enclencher","pos":"VER"} ,
		{"word":"enclenchées","word_nosc":"enclenchees","lemma":"enclencher","pos":"VER"} ,
		{"word":"enclenchés","word_nosc":"enclenches","lemma":"enclencher","pos":"VER"} ,
		{"word":"encliqueter","word_nosc":"encliqueter","lemma":"encliqueter","pos":"VER"} ,
		{"word":"encloqué","word_nosc":"encloque","lemma":"encloquer","pos":"VER"} ,
		{"word":"enclore","word_nosc":"enclore","lemma":"enclore","pos":"VER"} ,
		{"word":"enclos","word_nosc":"enclos","lemma":"enclore","pos":"VER"} ,
		{"word":"enclose","word_nosc":"enclose","lemma":"enclore","pos":"VER"} ,
		{"word":"encloses","word_nosc":"encloses","lemma":"enclore","pos":"VER"} ,
		{"word":"enclouer","word_nosc":"enclouer","lemma":"enclouer","pos":"VER"} ,
		{"word":"encloîtrer","word_nosc":"encloitrer","lemma":"encloîtrer","pos":"VER"} ,
		{"word":"encoché","word_nosc":"encoche","lemma":"encocher","pos":"VER"} ,
		{"word":"encoconner","word_nosc":"encoconner","lemma":"encoconner","pos":"VER"} ,
		{"word":"encoconnée","word_nosc":"encoconnee","lemma":"encoconner","pos":"VER"} ,
		{"word":"encode","word_nosc":"encode","lemma":"encoder","pos":"VER"} ,
		{"word":"encoder","word_nosc":"encoder","lemma":"encoder","pos":"VER"} ,
		{"word":"encodé","word_nosc":"encode","lemma":"encoder","pos":"VER"} ,
		{"word":"encodée","word_nosc":"encodee","lemma":"encoder","pos":"VER"} ,
		{"word":"encodés","word_nosc":"encodes","lemma":"encoder","pos":"VER"} ,
		{"word":"encollait","word_nosc":"encollait","lemma":"encoller","pos":"VER"} ,
		{"word":"encoller","word_nosc":"encoller","lemma":"encoller","pos":"VER"} ,
		{"word":"encollé","word_nosc":"encolle","lemma":"encoller","pos":"VER"} ,
		{"word":"encollée","word_nosc":"encollee","lemma":"encoller","pos":"VER"} ,
		{"word":"encollées","word_nosc":"encollees","lemma":"encoller","pos":"VER"} ,
		{"word":"encombra","word_nosc":"encombra","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombraient","word_nosc":"encombraient","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombrais","word_nosc":"encombrais","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombrait","word_nosc":"encombrait","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombrant","word_nosc":"encombrant","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombre","word_nosc":"encombre","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombrent","word_nosc":"encombrent","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombrer","word_nosc":"encombrer","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombrera","word_nosc":"encombrera","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombrerai","word_nosc":"encombrerai","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombrerait","word_nosc":"encombrerait","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombres","word_nosc":"encombres","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombrez","word_nosc":"encombrez","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombrèrent","word_nosc":"encombrerent","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombré","word_nosc":"encombre","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombrée","word_nosc":"encombree","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombrées","word_nosc":"encombrees","lemma":"encombrer","pos":"VER"} ,
		{"word":"encombrés","word_nosc":"encombres","lemma":"encombrer","pos":"VER"} ,
		{"word":"encorder","word_nosc":"encorder","lemma":"encorder","pos":"VER"} ,
		{"word":"encordé","word_nosc":"encorde","lemma":"encorder","pos":"VER"} ,
		{"word":"encordée","word_nosc":"encordee","lemma":"encorder","pos":"VER"} ,
		{"word":"encordés","word_nosc":"encordes","lemma":"encorder","pos":"VER"} ,
		{"word":"encornaient","word_nosc":"encornaient","lemma":"encorner","pos":"VER"} ,
		{"word":"encorner","word_nosc":"encorner","lemma":"encorner","pos":"VER"} ,
		{"word":"encotonne","word_nosc":"encotonne","lemma":"encotonner","pos":"VER"} ,
		{"word":"encotonner","word_nosc":"encotonner","lemma":"encotonner","pos":"VER"} ,
		{"word":"encourage","word_nosc":"encourage","lemma":"encourager","pos":"VER"} ,
		{"word":"encouragea","word_nosc":"encouragea","lemma":"encourager","pos":"VER"} ,
		{"word":"encourageai","word_nosc":"encourageai","lemma":"encourager","pos":"VER"} ,
		{"word":"encourageaient","word_nosc":"encourageaient","lemma":"encourager","pos":"VER"} ,
		{"word":"encourageais","word_nosc":"encourageais","lemma":"encourager","pos":"VER"} ,
		{"word":"encourageait","word_nosc":"encourageait","lemma":"encourager","pos":"VER"} ,
		{"word":"encourageant","word_nosc":"encourageant","lemma":"encourager","pos":"VER"} ,
		{"word":"encourageas","word_nosc":"encourageas","lemma":"encourager","pos":"VER"} ,
		{"word":"encouragent","word_nosc":"encouragent","lemma":"encourager","pos":"VER"} ,
		{"word":"encourageons","word_nosc":"encourageons","lemma":"encourager","pos":"VER"} ,
		{"word":"encourager","word_nosc":"encourager","lemma":"encourager","pos":"VER"} ,
		{"word":"encouragera","word_nosc":"encouragera","lemma":"encourager","pos":"VER"} ,
		{"word":"encouragerai","word_nosc":"encouragerai","lemma":"encourager","pos":"VER"} ,
		{"word":"encouragerais","word_nosc":"encouragerais","lemma":"encourager","pos":"VER"} ,
		{"word":"encouragerait","word_nosc":"encouragerait","lemma":"encourager","pos":"VER"} ,
		{"word":"encourageras","word_nosc":"encourageras","lemma":"encourager","pos":"VER"} ,
		{"word":"encourages","word_nosc":"encourages","lemma":"encourager","pos":"VER"} ,
		{"word":"encouragez","word_nosc":"encouragez","lemma":"encourager","pos":"VER"} ,
		{"word":"encourageât","word_nosc":"encourageat","lemma":"encourager","pos":"VER"} ,
		{"word":"encouragiez","word_nosc":"encouragiez","lemma":"encourager","pos":"VER"} ,
		{"word":"encouragèrent","word_nosc":"encouragerent","lemma":"encourager","pos":"VER"} ,
		{"word":"encouragé","word_nosc":"encourage","lemma":"encourager","pos":"VER"} ,
		{"word":"encouragée","word_nosc":"encouragee","lemma":"encourager","pos":"VER"} ,
		{"word":"encouragées","word_nosc":"encouragees","lemma":"encourager","pos":"VER"} ,
		{"word":"encouragés","word_nosc":"encourages","lemma":"encourager","pos":"VER"} ,
		{"word":"encouraient","word_nosc":"encouraient","lemma":"encourir","pos":"VER"} ,
		{"word":"encourais","word_nosc":"encourais","lemma":"encourir","pos":"VER"} ,
		{"word":"encourait","word_nosc":"encourait","lemma":"encourir","pos":"VER"} ,
		{"word":"encourant","word_nosc":"encourant","lemma":"encourir","pos":"VER"} ,
		{"word":"encourent","word_nosc":"encourent","lemma":"encourir","pos":"VER"} ,
		{"word":"encourir","word_nosc":"encourir","lemma":"encourir","pos":"VER"} ,
		{"word":"encourrait","word_nosc":"encourrait","lemma":"encourir","pos":"VER"} ,
		{"word":"encours","word_nosc":"encours","lemma":"encourir","pos":"VER"} ,
		{"word":"encourt","word_nosc":"encourt","lemma":"encourir","pos":"VER"} ,
		{"word":"encouru","word_nosc":"encouru","lemma":"encourir","pos":"VER"} ,
		{"word":"encourue","word_nosc":"encourue","lemma":"encourir","pos":"VER"} ,
		{"word":"encourues","word_nosc":"encourues","lemma":"encourir","pos":"VER"} ,
		{"word":"encourus","word_nosc":"encourus","lemma":"encourir","pos":"VER"} ,
		{"word":"encrait","word_nosc":"encrait","lemma":"encrer","pos":"VER"} ,
		{"word":"encrassait","word_nosc":"encrassait","lemma":"encrasser","pos":"VER"} ,
		{"word":"encrasse","word_nosc":"encrasse","lemma":"encrasser","pos":"VER"} ,
		{"word":"encrassent","word_nosc":"encrassent","lemma":"encrasser","pos":"VER"} ,
		{"word":"encrasser","word_nosc":"encrasser","lemma":"encrasser","pos":"VER"} ,
		{"word":"encrassé","word_nosc":"encrasse","lemma":"encrasser","pos":"VER"} ,
		{"word":"encrassée","word_nosc":"encrassee","lemma":"encrasser","pos":"VER"} ,
		{"word":"encrassées","word_nosc":"encrassees","lemma":"encrasser","pos":"VER"} ,
		{"word":"encrassés","word_nosc":"encrasses","lemma":"encrasser","pos":"VER"} ,
		{"word":"encre","word_nosc":"encre","lemma":"encrer","pos":"VER"} ,
		{"word":"encrer","word_nosc":"encrer","lemma":"encrer","pos":"VER"} ,
		{"word":"encrotté","word_nosc":"encrotte","lemma":"encrotter","pos":"VER"} ,
		{"word":"encroûtaient","word_nosc":"encroutaient","lemma":"encroûter","pos":"VER"} ,
		{"word":"encroûte","word_nosc":"encroute","lemma":"encroûter","pos":"VER"} ,
		{"word":"encroûter","word_nosc":"encrouter","lemma":"encroûter","pos":"VER"} ,
		{"word":"encroûtèrent","word_nosc":"encrouterent","lemma":"encroûter","pos":"VER"} ,
		{"word":"encroûté","word_nosc":"encroute","lemma":"encroûter","pos":"VER"} ,
		{"word":"encroûtée","word_nosc":"encroutee","lemma":"encroûter","pos":"VER"} ,
		{"word":"encroûtées","word_nosc":"encroutees","lemma":"encroûter","pos":"VER"} ,
		{"word":"encroûtés","word_nosc":"encroutes","lemma":"encroûter","pos":"VER"} ,
		{"word":"encrypté","word_nosc":"encrypte","lemma":"encrypter","pos":"VER"} ,
		{"word":"encré","word_nosc":"encre","lemma":"encrer","pos":"VER"} ,
		{"word":"encrée","word_nosc":"encree","lemma":"encrer","pos":"VER"} ,
		{"word":"encrés","word_nosc":"encres","lemma":"encrer","pos":"VER"} ,
		{"word":"enculant","word_nosc":"enculant","lemma":"enculer","pos":"VER"} ,
		{"word":"encule","word_nosc":"encule","lemma":"enculer","pos":"VER"} ,
		{"word":"enculent","word_nosc":"enculent","lemma":"enculer","pos":"VER"} ,
		{"word":"enculer","word_nosc":"enculer","lemma":"enculer","pos":"VER"} ,
		{"word":"enculera","word_nosc":"enculera","lemma":"enculer","pos":"VER"} ,
		{"word":"enculerais","word_nosc":"enculerais","lemma":"enculer","pos":"VER"} ,
		{"word":"enculerait","word_nosc":"enculerait","lemma":"enculer","pos":"VER"} ,
		{"word":"encules","word_nosc":"encules","lemma":"enculer","pos":"VER"} ,
		{"word":"enculé","word_nosc":"encule","lemma":"enculer","pos":"VER"} ,
		{"word":"enculée","word_nosc":"enculee","lemma":"enculer","pos":"VER"} ,
		{"word":"enculés","word_nosc":"encules","lemma":"enculer","pos":"VER"} ,
		{"word":"endettait","word_nosc":"endettait","lemma":"endetter","pos":"VER"} ,
		{"word":"endettant","word_nosc":"endettant","lemma":"endetter","pos":"VER"} ,
		{"word":"endette","word_nosc":"endette","lemma":"endetter","pos":"VER"} ,
		{"word":"endetter","word_nosc":"endetter","lemma":"endetter","pos":"VER"} ,
		{"word":"endettez","word_nosc":"endettez","lemma":"endetter","pos":"VER"} ,
		{"word":"endetté","word_nosc":"endette","lemma":"endetter","pos":"VER"} ,
		{"word":"endettée","word_nosc":"endettee","lemma":"endetter","pos":"VER"} ,
		{"word":"endettées","word_nosc":"endettees","lemma":"endetter","pos":"VER"} ,
		{"word":"endettés","word_nosc":"endettes","lemma":"endetter","pos":"VER"} ,
		{"word":"endeuille","word_nosc":"endeuille","lemma":"endeuiller","pos":"VER"} ,
		{"word":"endeuillent","word_nosc":"endeuillent","lemma":"endeuiller","pos":"VER"} ,
		{"word":"endeuiller","word_nosc":"endeuiller","lemma":"endeuiller","pos":"VER"} ,
		{"word":"endeuillerai","word_nosc":"endeuillerai","lemma":"endeuiller","pos":"VER"} ,
		{"word":"endeuillé","word_nosc":"endeuille","lemma":"endeuiller","pos":"VER"} ,
		{"word":"endeuillée","word_nosc":"endeuillee","lemma":"endeuiller","pos":"VER"} ,
		{"word":"endeuillées","word_nosc":"endeuillees","lemma":"endeuiller","pos":"VER"} ,
		{"word":"endeuillés","word_nosc":"endeuilles","lemma":"endeuiller","pos":"VER"} ,
		{"word":"endiable","word_nosc":"endiable","lemma":"endiabler","pos":"VER"} ,
		{"word":"endiablent","word_nosc":"endiablent","lemma":"endiabler","pos":"VER"} ,
		{"word":"endiablerez","word_nosc":"endiablerez","lemma":"endiabler","pos":"VER"} ,
		{"word":"endiablée","word_nosc":"endiablee","lemma":"endiabler","pos":"VER"} ,
		{"word":"endiablées","word_nosc":"endiablees","lemma":"endiabler","pos":"VER"} ,
		{"word":"endigua","word_nosc":"endigua","lemma":"endiguer","pos":"VER"} ,
		{"word":"endiguait","word_nosc":"endiguait","lemma":"endiguer","pos":"VER"} ,
		{"word":"endigue","word_nosc":"endigue","lemma":"endiguer","pos":"VER"} ,
		{"word":"endiguer","word_nosc":"endiguer","lemma":"endiguer","pos":"VER"} ,
		{"word":"endiguera","word_nosc":"endiguera","lemma":"endiguer","pos":"VER"} ,
		{"word":"endigué","word_nosc":"endigue","lemma":"endiguer","pos":"VER"} ,
		{"word":"endiguée","word_nosc":"endiguee","lemma":"endiguer","pos":"VER"} ,
		{"word":"endigués","word_nosc":"endigues","lemma":"endiguer","pos":"VER"} ,
		{"word":"endimanchait","word_nosc":"endimanchait","lemma":"endimancher","pos":"VER"} ,
		{"word":"endimancher","word_nosc":"endimancher","lemma":"endimancher","pos":"VER"} ,
		{"word":"endimanché","word_nosc":"endimanche","lemma":"endimancher","pos":"VER"} ,
		{"word":"endimanchée","word_nosc":"endimanchee","lemma":"endimancher","pos":"VER"} ,
		{"word":"endimanchées","word_nosc":"endimanchees","lemma":"endimancher","pos":"VER"} ,
		{"word":"endimanchés","word_nosc":"endimanches","lemma":"endimancher","pos":"VER"} ,
		{"word":"endivisionnés","word_nosc":"endivisionnes","lemma":"endivisionner","pos":"VER"} ,
		{"word":"endoctrina","word_nosc":"endoctrina","lemma":"endoctriner","pos":"VER"} ,
		{"word":"endoctriner","word_nosc":"endoctriner","lemma":"endoctriner","pos":"VER"} ,
		{"word":"endoctriné","word_nosc":"endoctrine","lemma":"endoctriner","pos":"VER"} ,
		{"word":"endoctrinée","word_nosc":"endoctrinee","lemma":"endoctriner","pos":"VER"} ,
		{"word":"endoctrinés","word_nosc":"endoctrines","lemma":"endoctriner","pos":"VER"} ,
		{"word":"endolori","word_nosc":"endolori","lemma":"endolorir","pos":"VER"} ,
		{"word":"endolorie","word_nosc":"endolorie","lemma":"endolorir","pos":"VER"} ,
		{"word":"endolories","word_nosc":"endolories","lemma":"endolorir","pos":"VER"} ,
		{"word":"endolorir","word_nosc":"endolorir","lemma":"endolorir","pos":"VER"} ,
		{"word":"endoloris","word_nosc":"endoloris","lemma":"endolorir","pos":"VER"} ,
		{"word":"endommagea","word_nosc":"endommagea","lemma":"endommager","pos":"VER"} ,
		{"word":"endommageant","word_nosc":"endommageant","lemma":"endommager","pos":"VER"} ,
		{"word":"endommager","word_nosc":"endommager","lemma":"endommager","pos":"VER"} ,
		{"word":"endommagera","word_nosc":"endommagera","lemma":"endommager","pos":"VER"} ,
		{"word":"endommagerait","word_nosc":"endommagerait","lemma":"endommager","pos":"VER"} ,
		{"word":"endommagerez","word_nosc":"endommagerez","lemma":"endommager","pos":"VER"} ,
		{"word":"endommagez","word_nosc":"endommagez","lemma":"endommager","pos":"VER"} ,
		{"word":"endommagé","word_nosc":"endommage","lemma":"endommager","pos":"VER"} ,
		{"word":"endommagée","word_nosc":"endommagee","lemma":"endommager","pos":"VER"} ,
		{"word":"endommagées","word_nosc":"endommagees","lemma":"endommager","pos":"VER"} ,
		{"word":"endommagés","word_nosc":"endommages","lemma":"endommager","pos":"VER"} ,
		{"word":"endormaient","word_nosc":"endormaient","lemma":"endormir","pos":"VER"} ,
		{"word":"endormais","word_nosc":"endormais","lemma":"endormir","pos":"VER"} ,
		{"word":"endormait","word_nosc":"endormait","lemma":"endormir","pos":"VER"} ,
		{"word":"endormant","word_nosc":"endormant","lemma":"endormir","pos":"VER"} ,
		{"word":"endorme","word_nosc":"endorme","lemma":"endormir","pos":"VER"} ,
		{"word":"endorment","word_nosc":"endorment","lemma":"endormir","pos":"VER"} ,
		{"word":"endormes","word_nosc":"endormes","lemma":"endormir","pos":"VER"} ,
		{"word":"endormez","word_nosc":"endormez","lemma":"endormir","pos":"VER"} ,
		{"word":"endormi","word_nosc":"endormi","lemma":"endormir","pos":"VER"} ,
		{"word":"endormions","word_nosc":"endormions","lemma":"endormir","pos":"VER"} ,
		{"word":"endormir","word_nosc":"endormir","lemma":"endormir","pos":"VER"} ,
		{"word":"endormira","word_nosc":"endormira","lemma":"endormir","pos":"VER"} ,
		{"word":"endormirai","word_nosc":"endormirai","lemma":"endormir","pos":"VER"} ,
		{"word":"endormiraient","word_nosc":"endormiraient","lemma":"endormir","pos":"VER"} ,
		{"word":"endormirais","word_nosc":"endormirais","lemma":"endormir","pos":"VER"} ,
		{"word":"endormirait","word_nosc":"endormirait","lemma":"endormir","pos":"VER"} ,
		{"word":"endormirent","word_nosc":"endormirent","lemma":"endormir","pos":"VER"} ,
		{"word":"endormirez","word_nosc":"endormirez","lemma":"endormir","pos":"VER"} ,
		{"word":"endormirions","word_nosc":"endormirions","lemma":"endormir","pos":"VER"} ,
		{"word":"endormiront","word_nosc":"endormiront","lemma":"endormir","pos":"VER"} ,
		{"word":"endormis","word_nosc":"endormis","lemma":"endormir","pos":"VER"} ,
		{"word":"endormisse","word_nosc":"endormisse","lemma":"endormir","pos":"VER"} ,
		{"word":"endormit","word_nosc":"endormit","lemma":"endormir","pos":"VER"} ,
		{"word":"endormons","word_nosc":"endormons","lemma":"endormir","pos":"VER"} ,
		{"word":"endormîmes","word_nosc":"endormimes","lemma":"endormir","pos":"VER"} ,
		{"word":"endormît","word_nosc":"endormit","lemma":"endormir","pos":"VER"} ,
		{"word":"endors","word_nosc":"endors","lemma":"endormir","pos":"VER"} ,
		{"word":"endort","word_nosc":"endort","lemma":"endormir","pos":"VER"} ,
		{"word":"endossa","word_nosc":"endossa","lemma":"endosser","pos":"VER"} ,
		{"word":"endossai","word_nosc":"endossai","lemma":"endosser","pos":"VER"} ,
		{"word":"endossaient","word_nosc":"endossaient","lemma":"endosser","pos":"VER"} ,
		{"word":"endossais","word_nosc":"endossais","lemma":"endosser","pos":"VER"} ,
		{"word":"endossait","word_nosc":"endossait","lemma":"endosser","pos":"VER"} ,
		{"word":"endossant","word_nosc":"endossant","lemma":"endosser","pos":"VER"} ,
		{"word":"endosse","word_nosc":"endosse","lemma":"endosser","pos":"VER"} ,
		{"word":"endossent","word_nosc":"endossent","lemma":"endosser","pos":"VER"} ,
		{"word":"endosser","word_nosc":"endosser","lemma":"endosser","pos":"VER"} ,
		{"word":"endosserai","word_nosc":"endosserai","lemma":"endosser","pos":"VER"} ,
		{"word":"endosserais","word_nosc":"endosserais","lemma":"endosser","pos":"VER"} ,
		{"word":"endosserait","word_nosc":"endosserait","lemma":"endosser","pos":"VER"} ,
		{"word":"endosseras","word_nosc":"endosseras","lemma":"endosser","pos":"VER"} ,
		{"word":"endosses","word_nosc":"endosses","lemma":"endosser","pos":"VER"} ,
		{"word":"endossez","word_nosc":"endossez","lemma":"endosser","pos":"VER"} ,
		{"word":"endossiez","word_nosc":"endossiez","lemma":"endosser","pos":"VER"} ,
		{"word":"endossât","word_nosc":"endossat","lemma":"endosser","pos":"VER"} ,
		{"word":"endossèrent","word_nosc":"endosserent","lemma":"endosser","pos":"VER"} ,
		{"word":"endossé","word_nosc":"endosse","lemma":"endosser","pos":"VER"} ,
		{"word":"endossée","word_nosc":"endossee","lemma":"endosser","pos":"VER"} ,
		{"word":"endossées","word_nosc":"endossees","lemma":"endosser","pos":"VER"} ,
		{"word":"endossés","word_nosc":"endosses","lemma":"endosser","pos":"VER"} ,
		{"word":"enduira","word_nosc":"enduira","lemma":"enduire","pos":"VER"} ,
		{"word":"enduirais","word_nosc":"enduirais","lemma":"enduire","pos":"VER"} ,
		{"word":"enduirait","word_nosc":"enduirait","lemma":"enduire","pos":"VER"} ,
		{"word":"enduire","word_nosc":"enduire","lemma":"enduire","pos":"VER"} ,
		{"word":"enduis","word_nosc":"enduis","lemma":"enduire","pos":"VER"} ,
		{"word":"enduisaient","word_nosc":"enduisaient","lemma":"enduire","pos":"VER"} ,
		{"word":"enduisait","word_nosc":"enduisait","lemma":"enduire","pos":"VER"} ,
		{"word":"enduisant","word_nosc":"enduisant","lemma":"enduire","pos":"VER"} ,
		{"word":"enduisent","word_nosc":"enduisent","lemma":"enduire","pos":"VER"} ,
		{"word":"enduisit","word_nosc":"enduisit","lemma":"enduire","pos":"VER"} ,
		{"word":"enduisons","word_nosc":"enduisons","lemma":"enduire","pos":"VER"} ,
		{"word":"enduit","word_nosc":"enduit","lemma":"enduire","pos":"VER"} ,
		{"word":"enduite","word_nosc":"enduite","lemma":"enduire","pos":"VER"} ,
		{"word":"enduites","word_nosc":"enduites","lemma":"enduire","pos":"VER"} ,
		{"word":"enduits","word_nosc":"enduits","lemma":"enduire","pos":"VER"} ,
		{"word":"endura","word_nosc":"endura","lemma":"endurer","pos":"VER"} ,
		{"word":"enduraient","word_nosc":"enduraient","lemma":"endurer","pos":"VER"} ,
		{"word":"endurais","word_nosc":"endurais","lemma":"endurer","pos":"VER"} ,
		{"word":"endurait","word_nosc":"endurait","lemma":"endurer","pos":"VER"} ,
		{"word":"endurant","word_nosc":"endurant","lemma":"endurer","pos":"VER"} ,
		{"word":"endurci","word_nosc":"endurci","lemma":"endurcir","pos":"VER"} ,
		{"word":"endurcie","word_nosc":"endurcie","lemma":"endurcir","pos":"VER"} ,
		{"word":"endurcies","word_nosc":"endurcies","lemma":"endurcir","pos":"VER"} ,
		{"word":"endurcir","word_nosc":"endurcir","lemma":"endurcir","pos":"VER"} ,
		{"word":"endurcirait","word_nosc":"endurcirait","lemma":"endurcir","pos":"VER"} ,
		{"word":"endurcis","word_nosc":"endurcis","lemma":"endurcir","pos":"VER"} ,
		{"word":"endurcissait","word_nosc":"endurcissait","lemma":"endurcir","pos":"VER"} ,
		{"word":"endurcit","word_nosc":"endurcit","lemma":"endurcir","pos":"VER"} ,
		{"word":"endure","word_nosc":"endure","lemma":"endurer","pos":"VER"} ,
		{"word":"endurent","word_nosc":"endurent","lemma":"endurer","pos":"VER"} ,
		{"word":"endurer","word_nosc":"endurer","lemma":"endurer","pos":"VER"} ,
		{"word":"endurera","word_nosc":"endurera","lemma":"endurer","pos":"VER"} ,
		{"word":"endurerai","word_nosc":"endurerai","lemma":"endurer","pos":"VER"} ,
		{"word":"endureras","word_nosc":"endureras","lemma":"endurer","pos":"VER"} ,
		{"word":"endurez","word_nosc":"endurez","lemma":"endurer","pos":"VER"} ,
		{"word":"enduriez","word_nosc":"enduriez","lemma":"endurer","pos":"VER"} ,
		{"word":"endurons","word_nosc":"endurons","lemma":"endurer","pos":"VER"} ,
		{"word":"enduré","word_nosc":"endure","lemma":"endurer","pos":"VER"} ,
		{"word":"endurée","word_nosc":"enduree","lemma":"endurer","pos":"VER"} ,
		{"word":"endurées","word_nosc":"endurees","lemma":"endurer","pos":"VER"} ,
		{"word":"endurés","word_nosc":"endures","lemma":"endurer","pos":"VER"} ,
		{"word":"endêver","word_nosc":"endever","lemma":"endêver","pos":"VER"} ,
		{"word":"enfanta","word_nosc":"enfanta","lemma":"enfanter","pos":"VER"} ,
		{"word":"enfantaient","word_nosc":"enfantaient","lemma":"enfanter","pos":"VER"} ,
		{"word":"enfantait","word_nosc":"enfantait","lemma":"enfanter","pos":"VER"} ,
		{"word":"enfante","word_nosc":"enfante","lemma":"enfanter","pos":"VER"} ,
		{"word":"enfantent","word_nosc":"enfantent","lemma":"enfanter","pos":"VER"} ,
		{"word":"enfanter","word_nosc":"enfanter","lemma":"enfanter","pos":"VER"} ,
		{"word":"enfantera","word_nosc":"enfantera","lemma":"enfanter","pos":"VER"} ,
		{"word":"enfanterai","word_nosc":"enfanterai","lemma":"enfanter","pos":"VER"} ,
		{"word":"enfantons","word_nosc":"enfantons","lemma":"enfanter","pos":"VER"} ,
		{"word":"enfanté","word_nosc":"enfante","lemma":"enfanter","pos":"VER"} ,
		{"word":"enfantée","word_nosc":"enfantee","lemma":"enfanter","pos":"VER"} ,
		{"word":"enfantées","word_nosc":"enfantees","lemma":"enfanter","pos":"VER"} ,
		{"word":"enfantés","word_nosc":"enfantes","lemma":"enfanter","pos":"VER"} ,
		{"word":"enfarinait","word_nosc":"enfarinait","lemma":"enfariner","pos":"VER"} ,
		{"word":"enfarine","word_nosc":"enfarine","lemma":"enfariner","pos":"VER"} ,
		{"word":"enfariner","word_nosc":"enfariner","lemma":"enfariner","pos":"VER"} ,
		{"word":"enfariné","word_nosc":"enfarine","lemma":"enfariner","pos":"VER"} ,
		{"word":"enfarinée","word_nosc":"enfarinee","lemma":"enfariner","pos":"VER"} ,
		{"word":"enfarinés","word_nosc":"enfarines","lemma":"enfariner","pos":"VER"} ,
		{"word":"enferma","word_nosc":"enferma","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermai","word_nosc":"enfermai","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermaient","word_nosc":"enfermaient","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermais","word_nosc":"enfermais","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermait","word_nosc":"enfermait","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermant","word_nosc":"enfermant","lemma":"enfermer","pos":"VER"} ,
		{"word":"enferme","word_nosc":"enferme","lemma":"enfermer","pos":"VER"} ,
		{"word":"enferment","word_nosc":"enferment","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermer","word_nosc":"enfermer","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermera","word_nosc":"enfermera","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermerai","word_nosc":"enfermerai","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermeraient","word_nosc":"enfermeraient","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermerais","word_nosc":"enfermerais","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermerait","word_nosc":"enfermerait","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermerez","word_nosc":"enfermerez","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermerons","word_nosc":"enfermerons","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermeront","word_nosc":"enfermeront","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermes","word_nosc":"enfermes","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermez","word_nosc":"enfermez","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermiez","word_nosc":"enfermiez","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermons","word_nosc":"enfermons","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermèrent","word_nosc":"enfermerent","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermé","word_nosc":"enferme","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermée","word_nosc":"enfermee","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermées","word_nosc":"enfermees","lemma":"enfermer","pos":"VER"} ,
		{"word":"enfermés","word_nosc":"enfermes","lemma":"enfermer","pos":"VER"} ,
		{"word":"enferra","word_nosc":"enferra","lemma":"enferrer","pos":"VER"} ,
		{"word":"enferrait","word_nosc":"enferrait","lemma":"enferrer","pos":"VER"} ,
		{"word":"enferrant","word_nosc":"enferrant","lemma":"enferrer","pos":"VER"} ,
		{"word":"enferre","word_nosc":"enferre","lemma":"enferrer","pos":"VER"} ,
		{"word":"enferrent","word_nosc":"enferrent","lemma":"enferrer","pos":"VER"} ,
		{"word":"enferrer","word_nosc":"enferrer","lemma":"enferrer","pos":"VER"} ,
		{"word":"enferres","word_nosc":"enferres","lemma":"enferrer","pos":"VER"} ,
		{"word":"enferrez","word_nosc":"enferrez","lemma":"enferrer","pos":"VER"} ,
		{"word":"enferrons","word_nosc":"enferrons","lemma":"enferrer","pos":"VER"} ,
		{"word":"enferré","word_nosc":"enferre","lemma":"enferrer","pos":"VER"} ,
		{"word":"enferrée","word_nosc":"enferree","lemma":"enferrer","pos":"VER"} ,
		{"word":"enfiche","word_nosc":"enfiche","lemma":"enficher","pos":"VER"} ,
		{"word":"enfila","word_nosc":"enfila","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilai","word_nosc":"enfilai","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilaient","word_nosc":"enfilaient","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilais","word_nosc":"enfilais","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilait","word_nosc":"enfilait","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilant","word_nosc":"enfilant","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfile","word_nosc":"enfile","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilent","word_nosc":"enfilent","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfiler","word_nosc":"enfiler","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilera","word_nosc":"enfilera","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilerai","word_nosc":"enfilerai","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfileraient","word_nosc":"enfileraient","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilerais","word_nosc":"enfilerais","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilerait","word_nosc":"enfilerait","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilerez","word_nosc":"enfilerez","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfileront","word_nosc":"enfileront","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfiles","word_nosc":"enfiles","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilez","word_nosc":"enfilez","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilions","word_nosc":"enfilions","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilons","word_nosc":"enfilons","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilèrent","word_nosc":"enfilerent","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilé","word_nosc":"enfile","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilée","word_nosc":"enfilee","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilées","word_nosc":"enfilees","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfilés","word_nosc":"enfiles","lemma":"enfiler","pos":"VER"} ,
		{"word":"enfièvre","word_nosc":"enfievre","lemma":"enfiévrer","pos":"VER"} ,
		{"word":"enfièvrent","word_nosc":"enfievrent","lemma":"enfiévrer","pos":"VER"} ,
		{"word":"enfiévra","word_nosc":"enfievra","lemma":"enfiévrer","pos":"VER"} ,
		{"word":"enfiévraient","word_nosc":"enfievraient","lemma":"enfiévrer","pos":"VER"} ,
		{"word":"enfiévrait","word_nosc":"enfievrait","lemma":"enfiévrer","pos":"VER"} ,
		{"word":"enfiévré","word_nosc":"enfievre","lemma":"enfiévrer","pos":"VER"} ,
		{"word":"enfiévrée","word_nosc":"enfievree","lemma":"enfiévrer","pos":"VER"} ,
		{"word":"enfiévrées","word_nosc":"enfievrees","lemma":"enfiévrer","pos":"VER"} ,
		{"word":"enfiévrés","word_nosc":"enfievres","lemma":"enfiévrer","pos":"VER"} ,
		{"word":"enfla","word_nosc":"enfla","lemma":"enfler","pos":"VER"} ,
		{"word":"enflaient","word_nosc":"enflaient","lemma":"enfler","pos":"VER"} ,
		{"word":"enflait","word_nosc":"enflait","lemma":"enfler","pos":"VER"} ,
		{"word":"enflamma","word_nosc":"enflamma","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflammai","word_nosc":"enflammai","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflammaient","word_nosc":"enflammaient","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflammait","word_nosc":"enflammait","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflammant","word_nosc":"enflammant","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflamme","word_nosc":"enflamme","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflamment","word_nosc":"enflamment","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflammer","word_nosc":"enflammer","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflammera","word_nosc":"enflammera","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflammerait","word_nosc":"enflammerait","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflammez","word_nosc":"enflammez","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflammât","word_nosc":"enflammat","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflammèrent","word_nosc":"enflammerent","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflammé","word_nosc":"enflamme","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflammée","word_nosc":"enflammee","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflammées","word_nosc":"enflammees","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflammés","word_nosc":"enflammes","lemma":"enflammer","pos":"VER"} ,
		{"word":"enflant","word_nosc":"enflant","lemma":"enfler","pos":"VER"} ,
		{"word":"enfle","word_nosc":"enfle","lemma":"enfler","pos":"VER"} ,
		{"word":"enflent","word_nosc":"enflent","lemma":"enfler","pos":"VER"} ,
		{"word":"enfler","word_nosc":"enfler","lemma":"enfler","pos":"VER"} ,
		{"word":"enflerait","word_nosc":"enflerait","lemma":"enfler","pos":"VER"} ,
		{"word":"enfles","word_nosc":"enfles","lemma":"enfler","pos":"VER"} ,
		{"word":"enflèrent","word_nosc":"enflerent","lemma":"enfler","pos":"VER"} ,
		{"word":"enflé","word_nosc":"enfle","lemma":"enfler","pos":"VER"} ,
		{"word":"enflée","word_nosc":"enflee","lemma":"enfler","pos":"VER"} ,
		{"word":"enflées","word_nosc":"enflees","lemma":"enfler","pos":"VER"} ,
		{"word":"enflés","word_nosc":"enfles","lemma":"enfler","pos":"VER"} ,
		{"word":"enfonce","word_nosc":"enfonce","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncent","word_nosc":"enfoncent","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncer","word_nosc":"enfoncer","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncera","word_nosc":"enfoncera","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncerai","word_nosc":"enfoncerai","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfonceraient","word_nosc":"enfonceraient","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncerais","word_nosc":"enfoncerais","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncerait","word_nosc":"enfoncerait","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfonceras","word_nosc":"enfonceras","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncerez","word_nosc":"enfoncerez","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncerions","word_nosc":"enfoncerions","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncerons","word_nosc":"enfoncerons","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfonceront","word_nosc":"enfonceront","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfonces","word_nosc":"enfonces","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncez","word_nosc":"enfoncez","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfonciez","word_nosc":"enfonciez","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncions","word_nosc":"enfoncions","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncèrent","word_nosc":"enfoncerent","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncé","word_nosc":"enfonce","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncée","word_nosc":"enfoncee","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncées","word_nosc":"enfoncees","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoncés","word_nosc":"enfonces","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfonça","word_nosc":"enfonca","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfonçai","word_nosc":"enfoncai","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfonçaient","word_nosc":"enfoncaient","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfonçais","word_nosc":"enfoncais","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfonçait","word_nosc":"enfoncait","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfonçant","word_nosc":"enfoncant","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfonçons","word_nosc":"enfoncons","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfonçâmes","word_nosc":"enfoncames","lemma":"enfoncer","pos":"VER"} ,
		{"word":"enfoui","word_nosc":"enfoui","lemma":"enfouir","pos":"VER"} ,
		{"word":"enfouie","word_nosc":"enfouie","lemma":"enfouir","pos":"VER"} ,
		{"word":"enfouies","word_nosc":"enfouies","lemma":"enfouir","pos":"VER"} ,
		{"word":"enfouillais","word_nosc":"enfouillais","lemma":"enfouiller","pos":"VER"} ,
		{"word":"enfouillant","word_nosc":"enfouillant","lemma":"enfouiller","pos":"VER"} ,
		{"word":"enfouille","word_nosc":"enfouille","lemma":"enfouiller","pos":"VER"} ,
		{"word":"enfouiller","word_nosc":"enfouiller","lemma":"enfouiller","pos":"VER"} ,
		{"word":"enfouillé","word_nosc":"enfouille","lemma":"enfouiller","pos":"VER"} ,
		{"word":"enfouillée","word_nosc":"enfouillee","lemma":"enfouiller","pos":"VER"} ,
		{"word":"enfouir","word_nosc":"enfouir","lemma":"enfouir","pos":"VER"} ,
		{"word":"enfouiraient","word_nosc":"enfouiraient","lemma":"enfouir","pos":"VER"} ,
		{"word":"enfouis","word_nosc":"enfouis","lemma":"enfouir","pos":"VER"} ,
		{"word":"enfouissais","word_nosc":"enfouissais","lemma":"enfouir","pos":"VER"} ,
		{"word":"enfouissait","word_nosc":"enfouissait","lemma":"enfouir","pos":"VER"} ,
		{"word":"enfouissant","word_nosc":"enfouissant","lemma":"enfouir","pos":"VER"} ,
		{"word":"enfouissent","word_nosc":"enfouissent","lemma":"enfouir","pos":"VER"} ,
		{"word":"enfouissez","word_nosc":"enfouissez","lemma":"enfouir","pos":"VER"} ,
		{"word":"enfouissons","word_nosc":"enfouissons","lemma":"enfouir","pos":"VER"} ,
		{"word":"enfouit","word_nosc":"enfouit","lemma":"enfouir","pos":"VER"} ,
		{"word":"enfouraille","word_nosc":"enfouraille","lemma":"enfourailler","pos":"VER"} ,
		{"word":"enfourcha","word_nosc":"enfourcha","lemma":"enfourcher","pos":"VER"} ,
		{"word":"enfourchai","word_nosc":"enfourchai","lemma":"enfourcher","pos":"VER"} ,
		{"word":"enfourchaient","word_nosc":"enfourchaient","lemma":"enfourcher","pos":"VER"} ,
		{"word":"enfourchais","word_nosc":"enfourchais","lemma":"enfourcher","pos":"VER"} ,
		{"word":"enfourchait","word_nosc":"enfourchait","lemma":"enfourcher","pos":"VER"} ,
		{"word":"enfourchant","word_nosc":"enfourchant","lemma":"enfourcher","pos":"VER"} ,
		{"word":"enfourche","word_nosc":"enfourche","lemma":"enfourcher","pos":"VER"} ,
		{"word":"enfourcher","word_nosc":"enfourcher","lemma":"enfourcher","pos":"VER"} ,
		{"word":"enfourcherait","word_nosc":"enfourcherait","lemma":"enfourcher","pos":"VER"} ,
		{"word":"enfourchez","word_nosc":"enfourchez","lemma":"enfourcher","pos":"VER"} ,
		{"word":"enfourchiez","word_nosc":"enfourchiez","lemma":"enfourcher","pos":"VER"} ,
		{"word":"enfourchèrent","word_nosc":"enfourcherent","lemma":"enfourcher","pos":"VER"} ,
		{"word":"enfourché","word_nosc":"enfourche","lemma":"enfourcher","pos":"VER"} ,
		{"word":"enfourna","word_nosc":"enfourna","lemma":"enfourner","pos":"VER"} ,
		{"word":"enfournaient","word_nosc":"enfournaient","lemma":"enfourner","pos":"VER"} ,
		{"word":"enfournait","word_nosc":"enfournait","lemma":"enfourner","pos":"VER"} ,
		{"word":"enfournant","word_nosc":"enfournant","lemma":"enfourner","pos":"VER"} ,
		{"word":"enfourne","word_nosc":"enfourne","lemma":"enfourner","pos":"VER"} ,
		{"word":"enfournent","word_nosc":"enfournent","lemma":"enfourner","pos":"VER"} ,
		{"word":"enfourner","word_nosc":"enfourner","lemma":"enfourner","pos":"VER"} ,
		{"word":"enfourniez","word_nosc":"enfourniez","lemma":"enfourner","pos":"VER"} ,
		{"word":"enfournèrent","word_nosc":"enfournerent","lemma":"enfourner","pos":"VER"} ,
		{"word":"enfourné","word_nosc":"enfourne","lemma":"enfourner","pos":"VER"} ,
		{"word":"enfournée","word_nosc":"enfournee","lemma":"enfourner","pos":"VER"} ,
		{"word":"enfournées","word_nosc":"enfournees","lemma":"enfourner","pos":"VER"} ,
		{"word":"enfournés","word_nosc":"enfournes","lemma":"enfourner","pos":"VER"} ,
		{"word":"enfreignaient","word_nosc":"enfreignaient","lemma":"enfreindre","pos":"VER"} ,
		{"word":"enfreignais","word_nosc":"enfreignais","lemma":"enfreindre","pos":"VER"} ,
		{"word":"enfreignait","word_nosc":"enfreignait","lemma":"enfreindre","pos":"VER"} ,
		{"word":"enfreignant","word_nosc":"enfreignant","lemma":"enfreindre","pos":"VER"} ,
		{"word":"enfreignent","word_nosc":"enfreignent","lemma":"enfreindre","pos":"VER"} ,
		{"word":"enfreignez","word_nosc":"enfreignez","lemma":"enfreindre","pos":"VER"} ,
		{"word":"enfreindra","word_nosc":"enfreindra","lemma":"enfreindre","pos":"VER"} ,
		{"word":"enfreindrai","word_nosc":"enfreindrai","lemma":"enfreindre","pos":"VER"} ,
		{"word":"enfreindrais","word_nosc":"enfreindrais","lemma":"enfreindre","pos":"VER"} ,
		{"word":"enfreindre","word_nosc":"enfreindre","lemma":"enfreindre","pos":"VER"} ,
		{"word":"enfreins","word_nosc":"enfreins","lemma":"enfreindre","pos":"VER"} ,
		{"word":"enfreint","word_nosc":"enfreint","lemma":"enfreindre","pos":"VER"} ,
		{"word":"enfreinte","word_nosc":"enfreinte","lemma":"enfreindre","pos":"VER"} ,
		{"word":"enfreintes","word_nosc":"enfreintes","lemma":"enfreindre","pos":"VER"} ,
		{"word":"enfui","word_nosc":"enfui","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuie","word_nosc":"enfuie","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuient","word_nosc":"enfuient","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuies","word_nosc":"enfuies","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuir","word_nosc":"enfuir","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuira","word_nosc":"enfuira","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuirai","word_nosc":"enfuirai","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuiraient","word_nosc":"enfuiraient","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuirais","word_nosc":"enfuirais","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuirait","word_nosc":"enfuirait","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuiras","word_nosc":"enfuiras","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuirent","word_nosc":"enfuirent","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuiront","word_nosc":"enfuiront","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuis","word_nosc":"enfuis","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuit","word_nosc":"enfuit","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuma","word_nosc":"enfuma","lemma":"enfumer","pos":"VER"} ,
		{"word":"enfumaient","word_nosc":"enfumaient","lemma":"enfumer","pos":"VER"} ,
		{"word":"enfumait","word_nosc":"enfumait","lemma":"enfumer","pos":"VER"} ,
		{"word":"enfumer","word_nosc":"enfumer","lemma":"enfumer","pos":"VER"} ,
		{"word":"enfumez","word_nosc":"enfumez","lemma":"enfumer","pos":"VER"} ,
		{"word":"enfumé","word_nosc":"enfume","lemma":"enfumer","pos":"VER"} ,
		{"word":"enfumée","word_nosc":"enfumee","lemma":"enfumer","pos":"VER"} ,
		{"word":"enfumées","word_nosc":"enfumees","lemma":"enfumer","pos":"VER"} ,
		{"word":"enfumés","word_nosc":"enfumes","lemma":"enfumer","pos":"VER"} ,
		{"word":"enfuyaient","word_nosc":"enfuyaient","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuyais","word_nosc":"enfuyais","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuyait","word_nosc":"enfuyait","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuyant","word_nosc":"enfuyant","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuyez","word_nosc":"enfuyez","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuyiez","word_nosc":"enfuyiez","lemma":"enfuir","pos":"VER"} ,
		{"word":"enfuyons","word_nosc":"enfuyons","lemma":"enfuir","pos":"VER"} ,
		{"word":"engage","word_nosc":"engage","lemma":"engager","pos":"VER"} ,
		{"word":"engagea","word_nosc":"engagea","lemma":"engager","pos":"VER"} ,
		{"word":"engageai","word_nosc":"engageai","lemma":"engager","pos":"VER"} ,
		{"word":"engageaient","word_nosc":"engageaient","lemma":"engager","pos":"VER"} ,
		{"word":"engageais","word_nosc":"engageais","lemma":"engager","pos":"VER"} ,
		{"word":"engageait","word_nosc":"engageait","lemma":"engager","pos":"VER"} ,
		{"word":"engageant","word_nosc":"engageant","lemma":"engager","pos":"VER"} ,
		{"word":"engagent","word_nosc":"engagent","lemma":"engager","pos":"VER"} ,
		{"word":"engageons","word_nosc":"engageons","lemma":"engager","pos":"VER"} ,
		{"word":"engager","word_nosc":"engager","lemma":"engager","pos":"VER"} ,
		{"word":"engagera","word_nosc":"engagera","lemma":"engager","pos":"VER"} ,
		{"word":"engagerai","word_nosc":"engagerai","lemma":"engager","pos":"VER"} ,
		{"word":"engageraient","word_nosc":"engageraient","lemma":"engager","pos":"VER"} ,
		{"word":"engagerais","word_nosc":"engagerais","lemma":"engager","pos":"VER"} ,
		{"word":"engagerait","word_nosc":"engagerait","lemma":"engager","pos":"VER"} ,
		{"word":"engageriez","word_nosc":"engageriez","lemma":"engager","pos":"VER"} ,
		{"word":"engagerions","word_nosc":"engagerions","lemma":"engager","pos":"VER"} ,
		{"word":"engagerons","word_nosc":"engagerons","lemma":"engager","pos":"VER"} ,
		{"word":"engageront","word_nosc":"engageront","lemma":"engager","pos":"VER"} ,
		{"word":"engages","word_nosc":"engages","lemma":"engager","pos":"VER"} ,
		{"word":"engagez","word_nosc":"engagez","lemma":"engager","pos":"VER"} ,
		{"word":"engageâmes","word_nosc":"engageames","lemma":"engager","pos":"VER"} ,
		{"word":"engageât","word_nosc":"engageat","lemma":"engager","pos":"VER"} ,
		{"word":"engagiez","word_nosc":"engagiez","lemma":"engager","pos":"VER"} ,
		{"word":"engagions","word_nosc":"engagions","lemma":"engager","pos":"VER"} ,
		{"word":"engagèrent","word_nosc":"engagerent","lemma":"engager","pos":"VER"} ,
		{"word":"engagé","word_nosc":"engage","lemma":"engager","pos":"VER"} ,
		{"word":"engagée","word_nosc":"engagee","lemma":"engager","pos":"VER"} ,
		{"word":"engagées","word_nosc":"engagees","lemma":"engager","pos":"VER"} ,
		{"word":"engagés","word_nosc":"engages","lemma":"engager","pos":"VER"} ,
		{"word":"engazonnée","word_nosc":"engazonnee","lemma":"engazonner","pos":"VER"} ,
		{"word":"engendra","word_nosc":"engendra","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendraient","word_nosc":"engendraient","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendrait","word_nosc":"engendrait","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendrant","word_nosc":"engendrant","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendrassent","word_nosc":"engendrassent","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendre","word_nosc":"engendre","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendrent","word_nosc":"engendrent","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendrer","word_nosc":"engendrer","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendrera","word_nosc":"engendrera","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendreraient","word_nosc":"engendreraient","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendrerait","word_nosc":"engendrerait","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendrez","word_nosc":"engendrez","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendrons","word_nosc":"engendrons","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendrèrent","word_nosc":"engendrerent","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendré","word_nosc":"engendre","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendrée","word_nosc":"engendree","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendrées","word_nosc":"engendrees","lemma":"engendrer","pos":"VER"} ,
		{"word":"engendrés","word_nosc":"engendres","lemma":"engendrer","pos":"VER"} ,
		{"word":"englacé","word_nosc":"englace","lemma":"englacer","pos":"VER"} ,
		{"word":"englande","word_nosc":"englande","lemma":"englander","pos":"VER"} ,
		{"word":"englander","word_nosc":"englander","lemma":"englander","pos":"VER"} ,
		{"word":"englandés","word_nosc":"englandes","lemma":"englander","pos":"VER"} ,
		{"word":"engloba","word_nosc":"engloba","lemma":"englober","pos":"VER"} ,
		{"word":"englobaient","word_nosc":"englobaient","lemma":"englober","pos":"VER"} ,
		{"word":"englobais","word_nosc":"englobais","lemma":"englober","pos":"VER"} ,
		{"word":"englobait","word_nosc":"englobait","lemma":"englober","pos":"VER"} ,
		{"word":"englobant","word_nosc":"englobant","lemma":"englober","pos":"VER"} ,
		{"word":"englobe","word_nosc":"englobe","lemma":"englober","pos":"VER"} ,
		{"word":"englobent","word_nosc":"englobent","lemma":"englober","pos":"VER"} ,
		{"word":"englober","word_nosc":"englober","lemma":"englober","pos":"VER"} ,
		{"word":"englobera","word_nosc":"englobera","lemma":"englober","pos":"VER"} ,
		{"word":"engloberaient","word_nosc":"engloberaient","lemma":"englober","pos":"VER"} ,
		{"word":"engloberait","word_nosc":"engloberait","lemma":"englober","pos":"VER"} ,
		{"word":"englobé","word_nosc":"englobe","lemma":"englober","pos":"VER"} ,
		{"word":"englobée","word_nosc":"englobee","lemma":"englober","pos":"VER"} ,
		{"word":"englobées","word_nosc":"englobees","lemma":"englober","pos":"VER"} ,
		{"word":"englobés","word_nosc":"englobes","lemma":"englober","pos":"VER"} ,
		{"word":"englouti","word_nosc":"englouti","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutie","word_nosc":"engloutie","lemma":"engloutir","pos":"VER"} ,
		{"word":"englouties","word_nosc":"englouties","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutir","word_nosc":"engloutir","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutira","word_nosc":"engloutira","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutiraient","word_nosc":"engloutiraient","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutirait","word_nosc":"engloutirait","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutirent","word_nosc":"engloutirent","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutirons","word_nosc":"engloutirons","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutis","word_nosc":"engloutis","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutissaient","word_nosc":"engloutissaient","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutissais","word_nosc":"engloutissais","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutissait","word_nosc":"engloutissait","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutissant","word_nosc":"engloutissant","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutisse","word_nosc":"engloutisse","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutissent","word_nosc":"engloutissent","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutissions","word_nosc":"engloutissions","lemma":"engloutir","pos":"VER"} ,
		{"word":"engloutit","word_nosc":"engloutit","lemma":"engloutir","pos":"VER"} ,
		{"word":"engluaient","word_nosc":"engluaient","lemma":"engluer","pos":"VER"} ,
		{"word":"engluait","word_nosc":"engluait","lemma":"engluer","pos":"VER"} ,
		{"word":"engluant","word_nosc":"engluant","lemma":"engluer","pos":"VER"} ,
		{"word":"englue","word_nosc":"englue","lemma":"engluer","pos":"VER"} ,
		{"word":"engluent","word_nosc":"engluent","lemma":"engluer","pos":"VER"} ,
		{"word":"engluer","word_nosc":"engluer","lemma":"engluer","pos":"VER"} ,
		{"word":"englué","word_nosc":"englue","lemma":"engluer","pos":"VER"} ,
		{"word":"engluée","word_nosc":"engluee","lemma":"engluer","pos":"VER"} ,
		{"word":"engluées","word_nosc":"engluees","lemma":"engluer","pos":"VER"} ,
		{"word":"englués","word_nosc":"englues","lemma":"engluer","pos":"VER"} ,
		{"word":"engonce","word_nosc":"engonce","lemma":"engoncer","pos":"VER"} ,
		{"word":"engoncé","word_nosc":"engonce","lemma":"engoncer","pos":"VER"} ,
		{"word":"engoncée","word_nosc":"engoncee","lemma":"engoncer","pos":"VER"} ,
		{"word":"engoncées","word_nosc":"engoncees","lemma":"engoncer","pos":"VER"} ,
		{"word":"engoncés","word_nosc":"engonces","lemma":"engoncer","pos":"VER"} ,
		{"word":"engonçaient","word_nosc":"engoncaient","lemma":"engoncer","pos":"VER"} ,
		{"word":"engonçait","word_nosc":"engoncait","lemma":"engoncer","pos":"VER"} ,
		{"word":"engonçant","word_nosc":"engoncant","lemma":"engoncer","pos":"VER"} ,
		{"word":"engorge","word_nosc":"engorge","lemma":"engorger","pos":"VER"} ,
		{"word":"engorgeaient","word_nosc":"engorgeaient","lemma":"engorger","pos":"VER"} ,
		{"word":"engorgeait","word_nosc":"engorgeait","lemma":"engorger","pos":"VER"} ,
		{"word":"engorgent","word_nosc":"engorgent","lemma":"engorger","pos":"VER"} ,
		{"word":"engorger","word_nosc":"engorger","lemma":"engorger","pos":"VER"} ,
		{"word":"engorgeraient","word_nosc":"engorgeraient","lemma":"engorger","pos":"VER"} ,
		{"word":"engorgèrent","word_nosc":"engorgerent","lemma":"engorger","pos":"VER"} ,
		{"word":"engorgé","word_nosc":"engorge","lemma":"engorger","pos":"VER"} ,
		{"word":"engorgée","word_nosc":"engorgee","lemma":"engorger","pos":"VER"} ,
		{"word":"engorgées","word_nosc":"engorgees","lemma":"engorger","pos":"VER"} ,
		{"word":"engouai","word_nosc":"engouai","lemma":"engouer","pos":"VER"} ,
		{"word":"engouait","word_nosc":"engouait","lemma":"engouer","pos":"VER"} ,
		{"word":"engouffra","word_nosc":"engouffra","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffrai","word_nosc":"engouffrai","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffraient","word_nosc":"engouffraient","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffrait","word_nosc":"engouffrait","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffrant","word_nosc":"engouffrant","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffre","word_nosc":"engouffre","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffrent","word_nosc":"engouffrent","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffrer","word_nosc":"engouffrer","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffrera","word_nosc":"engouffrera","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffreraient","word_nosc":"engouffreraient","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffrerait","word_nosc":"engouffrerait","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffrons","word_nosc":"engouffrons","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffrât","word_nosc":"engouffrat","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffrèrent","word_nosc":"engouffrerent","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffré","word_nosc":"engouffre","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffrée","word_nosc":"engouffree","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffrées","word_nosc":"engouffrees","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engouffrés","word_nosc":"engouffres","lemma":"engouffrer","pos":"VER"} ,
		{"word":"engourdi","word_nosc":"engourdi","lemma":"engourdir","pos":"VER"} ,
		{"word":"engourdie","word_nosc":"engourdie","lemma":"engourdir","pos":"VER"} ,
		{"word":"engourdies","word_nosc":"engourdies","lemma":"engourdir","pos":"VER"} ,
		{"word":"engourdir","word_nosc":"engourdir","lemma":"engourdir","pos":"VER"} ,
		{"word":"engourdira","word_nosc":"engourdira","lemma":"engourdir","pos":"VER"} ,
		{"word":"engourdirai","word_nosc":"engourdirai","lemma":"engourdir","pos":"VER"} ,
		{"word":"engourdirais","word_nosc":"engourdirais","lemma":"engourdir","pos":"VER"} ,
		{"word":"engourdis","word_nosc":"engourdis","lemma":"engourdir","pos":"VER"} ,
		{"word":"engourdissaient","word_nosc":"engourdissaient","lemma":"engourdir","pos":"VER"} ,
		{"word":"engourdissait","word_nosc":"engourdissait","lemma":"engourdir","pos":"VER"} ,
		{"word":"engourdissant","word_nosc":"engourdissant","lemma":"engourdir","pos":"VER"} ,
		{"word":"engourdissent","word_nosc":"engourdissent","lemma":"engourdir","pos":"VER"} ,
		{"word":"engourdit","word_nosc":"engourdit","lemma":"engourdir","pos":"VER"} ,
		{"word":"engoué","word_nosc":"engoue","lemma":"engouer","pos":"VER"} ,
		{"word":"engouée","word_nosc":"engouee","lemma":"engouer","pos":"VER"} ,
		{"word":"engoués","word_nosc":"engoues","lemma":"engouer","pos":"VER"} ,
		{"word":"engrainait","word_nosc":"engrainait","lemma":"engrainer","pos":"VER"} ,
		{"word":"engraissaient","word_nosc":"engraissaient","lemma":"engraisser","pos":"VER"} ,
		{"word":"engraissait","word_nosc":"engraissait","lemma":"engraisser","pos":"VER"} ,
		{"word":"engraissant","word_nosc":"engraissant","lemma":"engraisser","pos":"VER"} ,
		{"word":"engraisse","word_nosc":"engraisse","lemma":"engraisser","pos":"VER"} ,
		{"word":"engraissent","word_nosc":"engraissent","lemma":"engraisser","pos":"VER"} ,
		{"word":"engraisser","word_nosc":"engraisser","lemma":"engraisser","pos":"VER"} ,
		{"word":"engraisser","word_nosc":"engraisser","lemma":"entrer","pos":"VER"} ,
		{"word":"engraisseraient","word_nosc":"engraisseraient","lemma":"engraisser","pos":"VER"} ,
		{"word":"engraisserait","word_nosc":"engraisserait","lemma":"engraisser","pos":"VER"} ,
		{"word":"engraisserons","word_nosc":"engraisserons","lemma":"engraisser","pos":"VER"} ,
		{"word":"engraisses","word_nosc":"engraisses","lemma":"engraisser","pos":"VER"} ,
		{"word":"engraissez","word_nosc":"engraissez","lemma":"engraisser","pos":"VER"} ,
		{"word":"engraissèrent","word_nosc":"engraisserent","lemma":"engraisser","pos":"VER"} ,
		{"word":"engraissé","word_nosc":"engraisse","lemma":"engraisser","pos":"VER"} ,
		{"word":"engraissée","word_nosc":"engraissee","lemma":"engraisser","pos":"VER"} ,
		{"word":"engraissées","word_nosc":"engraissees","lemma":"engraisser","pos":"VER"} ,
		{"word":"engraissés","word_nosc":"engraisses","lemma":"engraisser","pos":"VER"} ,
		{"word":"engrange","word_nosc":"engrange","lemma":"engranger","pos":"VER"} ,
		{"word":"engrangea","word_nosc":"engrangea","lemma":"engranger","pos":"VER"} ,
		{"word":"engrangeais","word_nosc":"engrangeais","lemma":"engranger","pos":"VER"} ,
		{"word":"engrangeait","word_nosc":"engrangeait","lemma":"engranger","pos":"VER"} ,
		{"word":"engrangeant","word_nosc":"engrangeant","lemma":"engranger","pos":"VER"} ,
		{"word":"engrangent","word_nosc":"engrangent","lemma":"engranger","pos":"VER"} ,
		{"word":"engrangeons","word_nosc":"engrangeons","lemma":"engranger","pos":"VER"} ,
		{"word":"engranger","word_nosc":"engranger","lemma":"engranger","pos":"VER"} ,
		{"word":"engrangé","word_nosc":"engrange","lemma":"engranger","pos":"VER"} ,
		{"word":"engrangée","word_nosc":"engrangee","lemma":"engranger","pos":"VER"} ,
		{"word":"engrangées","word_nosc":"engrangees","lemma":"engranger","pos":"VER"} ,
		{"word":"engrangés","word_nosc":"engranges","lemma":"engranger","pos":"VER"} ,
		{"word":"engraver","word_nosc":"engraver","lemma":"engraver","pos":"VER"} ,
		{"word":"engrenait","word_nosc":"engrenait","lemma":"engrener","pos":"VER"} ,
		{"word":"engrenant","word_nosc":"engrenant","lemma":"engrener","pos":"VER"} ,
		{"word":"engrener","word_nosc":"engrener","lemma":"engrener","pos":"VER"} ,
		{"word":"engrenez","word_nosc":"engrenez","lemma":"engrener","pos":"VER"} ,
		{"word":"engrossait","word_nosc":"engrossait","lemma":"engrosser","pos":"VER"} ,
		{"word":"engrossant","word_nosc":"engrossant","lemma":"engrosser","pos":"VER"} ,
		{"word":"engrosse","word_nosc":"engrosse","lemma":"engrosser","pos":"VER"} ,
		{"word":"engrossent","word_nosc":"engrossent","lemma":"engrosser","pos":"VER"} ,
		{"word":"engrosser","word_nosc":"engrosser","lemma":"engrosser","pos":"VER"} ,
		{"word":"engrossera","word_nosc":"engrossera","lemma":"engrosser","pos":"VER"} ,
		{"word":"engrosses","word_nosc":"engrosses","lemma":"engrosser","pos":"VER"} ,
		{"word":"engrossé","word_nosc":"engrosse","lemma":"engrosser","pos":"VER"} ,
		{"word":"engrossée","word_nosc":"engrossee","lemma":"engrosser","pos":"VER"} ,
		{"word":"engrossées","word_nosc":"engrossees","lemma":"engrosser","pos":"VER"} ,
		{"word":"engueula","word_nosc":"engueula","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueulaient","word_nosc":"engueulaient","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueulais","word_nosc":"engueulais","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueulait","word_nosc":"engueulait","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueulant","word_nosc":"engueulant","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueule","word_nosc":"engueule","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueulent","word_nosc":"engueulent","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueuler","word_nosc":"engueuler","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueulera","word_nosc":"engueulera","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueuleraient","word_nosc":"engueuleraient","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueulerait","word_nosc":"engueulerait","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueuleras","word_nosc":"engueuleras","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueulerez","word_nosc":"engueulerez","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueuleront","word_nosc":"engueuleront","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueules","word_nosc":"engueules","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueulez","word_nosc":"engueulez","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueuliez","word_nosc":"engueuliez","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueulions","word_nosc":"engueulions","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueulons","word_nosc":"engueulons","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueulèrent","word_nosc":"engueulerent","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueulé","word_nosc":"engueule","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueulée","word_nosc":"engueulee","lemma":"engueuler","pos":"VER"} ,
		{"word":"engueulés","word_nosc":"engueules","lemma":"engueuler","pos":"VER"} ,
		{"word":"enguirlandaient","word_nosc":"enguirlandaient","lemma":"enguirlander","pos":"VER"} ,
		{"word":"enguirlandait","word_nosc":"enguirlandait","lemma":"enguirlander","pos":"VER"} ,
		{"word":"enguirlande","word_nosc":"enguirlande","lemma":"enguirlander","pos":"VER"} ,
		{"word":"enguirlandent","word_nosc":"enguirlandent","lemma":"enguirlander","pos":"VER"} ,
		{"word":"enguirlander","word_nosc":"enguirlander","lemma":"enguirlander","pos":"VER"} ,
		{"word":"enguirlandé","word_nosc":"enguirlande","lemma":"enguirlander","pos":"VER"} ,
		{"word":"enguirlandée","word_nosc":"enguirlandee","lemma":"enguirlander","pos":"VER"} ,
		{"word":"enguirlandées","word_nosc":"enguirlandees","lemma":"enguirlander","pos":"VER"} ,
		{"word":"enguirlandés","word_nosc":"enguirlandes","lemma":"enguirlander","pos":"VER"} ,
		{"word":"enhardi","word_nosc":"enhardi","lemma":"enhardir","pos":"VER"} ,
		{"word":"enhardie","word_nosc":"enhardie","lemma":"enhardir","pos":"VER"} ,
		{"word":"enhardies","word_nosc":"enhardies","lemma":"enhardir","pos":"VER"} ,
		{"word":"enhardir","word_nosc":"enhardir","lemma":"enhardir","pos":"VER"} ,
		{"word":"enhardirent","word_nosc":"enhardirent","lemma":"enhardir","pos":"VER"} ,
		{"word":"enhardis","word_nosc":"enhardis","lemma":"enhardir","pos":"VER"} ,
		{"word":"enhardissaient","word_nosc":"enhardissaient","lemma":"enhardir","pos":"VER"} ,
		{"word":"enhardissait","word_nosc":"enhardissait","lemma":"enhardir","pos":"VER"} ,
		{"word":"enhardissant","word_nosc":"enhardissant","lemma":"enhardir","pos":"VER"} ,
		{"word":"enhardissent","word_nosc":"enhardissent","lemma":"enhardir","pos":"VER"} ,
		{"word":"enhardit","word_nosc":"enhardit","lemma":"enhardir","pos":"VER"} ,
		{"word":"enivra","word_nosc":"enivra","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivrai","word_nosc":"enivrai","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivraient","word_nosc":"enivraient","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivrais","word_nosc":"enivrais","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivrait","word_nosc":"enivrait","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivrant","word_nosc":"enivrant","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivre","word_nosc":"enivre","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivrent","word_nosc":"enivrent","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivrer","word_nosc":"enivrer","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivreras","word_nosc":"enivreras","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivrerez","word_nosc":"enivrerez","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivres","word_nosc":"enivres","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivrez","word_nosc":"enivrez","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivrons","word_nosc":"enivrons","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivrèrent","word_nosc":"enivrerent","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivré","word_nosc":"enivre","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivrée","word_nosc":"enivree","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivrées","word_nosc":"enivrees","lemma":"enivrer","pos":"VER"} ,
		{"word":"enivrés","word_nosc":"enivres","lemma":"enivrer","pos":"VER"} ,
		{"word":"enjamba","word_nosc":"enjamba","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambai","word_nosc":"enjambai","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambaient","word_nosc":"enjambaient","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambais","word_nosc":"enjambais","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambait","word_nosc":"enjambait","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambant","word_nosc":"enjambant","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambe","word_nosc":"enjambe","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambent","word_nosc":"enjambent","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjamber","word_nosc":"enjamber","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjamberais","word_nosc":"enjamberais","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjamberait","word_nosc":"enjamberait","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambes","word_nosc":"enjambes","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambez","word_nosc":"enjambez","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambions","word_nosc":"enjambions","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambons","word_nosc":"enjambons","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambèrent","word_nosc":"enjamberent","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambé","word_nosc":"enjambe","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambée","word_nosc":"enjambee","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambées","word_nosc":"enjambees","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjambés","word_nosc":"enjambes","lemma":"enjamber","pos":"VER"} ,
		{"word":"enjoignaient","word_nosc":"enjoignaient","lemma":"enjoindre","pos":"VER"} ,
		{"word":"enjoignait","word_nosc":"enjoignait","lemma":"enjoindre","pos":"VER"} ,
		{"word":"enjoignant","word_nosc":"enjoignant","lemma":"enjoindre","pos":"VER"} ,
		{"word":"enjoignis","word_nosc":"enjoignis","lemma":"enjoindre","pos":"VER"} ,
		{"word":"enjoignit","word_nosc":"enjoignit","lemma":"enjoindre","pos":"VER"} ,
		{"word":"enjoignons","word_nosc":"enjoignons","lemma":"enjoindre","pos":"VER"} ,
		{"word":"enjoindre","word_nosc":"enjoindre","lemma":"enjoindre","pos":"VER"} ,
		{"word":"enjoint","word_nosc":"enjoint","lemma":"enjoindre","pos":"VER"} ,
		{"word":"enjolivaient","word_nosc":"enjolivaient","lemma":"enjoliver","pos":"VER"} ,
		{"word":"enjolivait","word_nosc":"enjolivait","lemma":"enjoliver","pos":"VER"} ,
		{"word":"enjolivant","word_nosc":"enjolivant","lemma":"enjoliver","pos":"VER"} ,
		{"word":"enjolive","word_nosc":"enjolive","lemma":"enjoliver","pos":"VER"} ,
		{"word":"enjoliver","word_nosc":"enjoliver","lemma":"enjoliver","pos":"VER"} ,
		{"word":"enjolivé","word_nosc":"enjolive","lemma":"enjoliver","pos":"VER"} ,
		{"word":"enjolivée","word_nosc":"enjolivee","lemma":"enjoliver","pos":"VER"} ,
		{"word":"enjolivés","word_nosc":"enjolives","lemma":"enjoliver","pos":"VER"} ,
		{"word":"enjoué","word_nosc":"enjoue","lemma":"enjouer","pos":"VER"} ,
		{"word":"enjouée","word_nosc":"enjouee","lemma":"enjouer","pos":"VER"} ,
		{"word":"enjoués","word_nosc":"enjoues","lemma":"enjouer","pos":"VER"} ,
		{"word":"enjuiver","word_nosc":"enjuiver","lemma":"enjuiver","pos":"VER"} ,
		{"word":"enjuponne","word_nosc":"enjuponne","lemma":"enjuponner","pos":"VER"} ,
		{"word":"enjuponnée","word_nosc":"enjuponnee","lemma":"enjuponner","pos":"VER"} ,
		{"word":"enjôlait","word_nosc":"enjolait","lemma":"enjôler","pos":"VER"} ,
		{"word":"enjôlant","word_nosc":"enjolant","lemma":"enjôler","pos":"VER"} ,
		{"word":"enjôle","word_nosc":"enjole","lemma":"enjôler","pos":"VER"} ,
		{"word":"enjôler","word_nosc":"enjoler","lemma":"enjôler","pos":"VER"} ,
		{"word":"enjôlé","word_nosc":"enjole","lemma":"enjôler","pos":"VER"} ,
		{"word":"enjôlée","word_nosc":"enjolee","lemma":"enjôler","pos":"VER"} ,
		{"word":"enkyster","word_nosc":"enkyster","lemma":"enkyster","pos":"VER"} ,
		{"word":"enlace","word_nosc":"enlace","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlacent","word_nosc":"enlacent","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlacer","word_nosc":"enlacer","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlacerai","word_nosc":"enlacerai","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlacerons","word_nosc":"enlacerons","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlacez","word_nosc":"enlacez","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlacions","word_nosc":"enlacions","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlacèrent","word_nosc":"enlacerent","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlacé","word_nosc":"enlace","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlacée","word_nosc":"enlacee","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlacées","word_nosc":"enlacees","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlacés","word_nosc":"enlaces","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlaidi","word_nosc":"enlaidi","lemma":"enlaidir","pos":"VER"} ,
		{"word":"enlaidie","word_nosc":"enlaidie","lemma":"enlaidir","pos":"VER"} ,
		{"word":"enlaidir","word_nosc":"enlaidir","lemma":"enlaidir","pos":"VER"} ,
		{"word":"enlaidis","word_nosc":"enlaidis","lemma":"enlaidir","pos":"VER"} ,
		{"word":"enlaidissait","word_nosc":"enlaidissait","lemma":"enlaidir","pos":"VER"} ,
		{"word":"enlaidissant","word_nosc":"enlaidissant","lemma":"enlaidir","pos":"VER"} ,
		{"word":"enlaidisse","word_nosc":"enlaidisse","lemma":"enlaidir","pos":"VER"} ,
		{"word":"enlaidissent","word_nosc":"enlaidissent","lemma":"enlaidir","pos":"VER"} ,
		{"word":"enlaidisses","word_nosc":"enlaidisses","lemma":"enlaidir","pos":"VER"} ,
		{"word":"enlaidit","word_nosc":"enlaidit","lemma":"enlaidir","pos":"VER"} ,
		{"word":"enlaça","word_nosc":"enlaca","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlaçaient","word_nosc":"enlacaient","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlaçais","word_nosc":"enlacais","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlaçait","word_nosc":"enlacait","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlaçant","word_nosc":"enlacant","lemma":"enlacer","pos":"VER"} ,
		{"word":"enlaçons","word_nosc":"enlacons","lemma":"enlacer","pos":"VER"} ,
		{"word":"enleva","word_nosc":"enleva","lemma":"enlever","pos":"VER"} ,
		{"word":"enlevai","word_nosc":"enlevai","lemma":"enlever","pos":"VER"} ,
		{"word":"enlevaient","word_nosc":"enlevaient","lemma":"enlever","pos":"VER"} ,
		{"word":"enlevais","word_nosc":"enlevais","lemma":"enlever","pos":"VER"} ,
		{"word":"enlevait","word_nosc":"enlevait","lemma":"enlever","pos":"VER"} ,
		{"word":"enlevant","word_nosc":"enlevant","lemma":"enlever","pos":"VER"} ,
		{"word":"enlever","word_nosc":"enlever","lemma":"enlever","pos":"VER"} ,
		{"word":"enlevez","word_nosc":"enlevez","lemma":"enlever","pos":"VER"} ,
		{"word":"enleviez","word_nosc":"enleviez","lemma":"enlever","pos":"VER"} ,
		{"word":"enlevions","word_nosc":"enlevions","lemma":"enlever","pos":"VER"} ,
		{"word":"enlevons","word_nosc":"enlevons","lemma":"enlever","pos":"VER"} ,
		{"word":"enlevât","word_nosc":"enlevat","lemma":"enlever","pos":"VER"} ,
		{"word":"enlevèrent","word_nosc":"enleverent","lemma":"enlever","pos":"VER"} ,
		{"word":"enlevé","word_nosc":"enleve","lemma":"enlever","pos":"VER"} ,
		{"word":"enlevée","word_nosc":"enlevee","lemma":"enlever","pos":"VER"} ,
		{"word":"enlevées","word_nosc":"enlevees","lemma":"enlever","pos":"VER"} ,
		{"word":"enlevés","word_nosc":"enleves","lemma":"enlever","pos":"VER"} ,
		{"word":"enliasser","word_nosc":"enliasser","lemma":"enliasser","pos":"VER"} ,
		{"word":"enlisa","word_nosc":"enlisa","lemma":"enliser","pos":"VER"} ,
		{"word":"enlisaient","word_nosc":"enlisaient","lemma":"enliser","pos":"VER"} ,
		{"word":"enlisais","word_nosc":"enlisais","lemma":"enliser","pos":"VER"} ,
		{"word":"enlisait","word_nosc":"enlisait","lemma":"enliser","pos":"VER"} ,
		{"word":"enlisant","word_nosc":"enlisant","lemma":"enliser","pos":"VER"} ,
		{"word":"enlise","word_nosc":"enlise","lemma":"enliser","pos":"VER"} ,
		{"word":"enlisent","word_nosc":"enlisent","lemma":"enliser","pos":"VER"} ,
		{"word":"enliser","word_nosc":"enliser","lemma":"enliser","pos":"VER"} ,
		{"word":"enlisera","word_nosc":"enlisera","lemma":"enliser","pos":"VER"} ,
		{"word":"enliserais","word_nosc":"enliserais","lemma":"enliser","pos":"VER"} ,
		{"word":"enlisé","word_nosc":"enlise","lemma":"enliser","pos":"VER"} ,
		{"word":"enlisée","word_nosc":"enlisee","lemma":"enliser","pos":"VER"} ,
		{"word":"enlisées","word_nosc":"enlisees","lemma":"enliser","pos":"VER"} ,
		{"word":"enlisés","word_nosc":"enlises","lemma":"enliser","pos":"VER"} ,
		{"word":"enlumine","word_nosc":"enlumine","lemma":"enluminer","pos":"VER"} ,
		{"word":"enluminent","word_nosc":"enluminent","lemma":"enluminer","pos":"VER"} ,
		{"word":"enluminer","word_nosc":"enluminer","lemma":"enluminer","pos":"VER"} ,
		{"word":"enluminé","word_nosc":"enlumine","lemma":"enluminer","pos":"VER"} ,
		{"word":"enluminée","word_nosc":"enluminee","lemma":"enluminer","pos":"VER"} ,
		{"word":"enluminées","word_nosc":"enluminees","lemma":"enluminer","pos":"VER"} ,
		{"word":"enluminés","word_nosc":"enlumines","lemma":"enluminer","pos":"VER"} ,
		{"word":"enlève","word_nosc":"enleve","lemma":"enlever","pos":"VER"} ,
		{"word":"enlèvent","word_nosc":"enlevent","lemma":"enlever","pos":"VER"} ,
		{"word":"enlèvera","word_nosc":"enlevera","lemma":"enlever","pos":"VER"} ,
		{"word":"enlèverai","word_nosc":"enleverai","lemma":"enlever","pos":"VER"} ,
		{"word":"enlèveraient","word_nosc":"enleveraient","lemma":"enlever","pos":"VER"} ,
		{"word":"enlèverais","word_nosc":"enleverais","lemma":"enlever","pos":"VER"} ,
		{"word":"enlèverait","word_nosc":"enleverait","lemma":"enlever","pos":"VER"} ,
		{"word":"enlèveras","word_nosc":"enleveras","lemma":"enlever","pos":"VER"} ,
		{"word":"enlèverez","word_nosc":"enleverez","lemma":"enlever","pos":"VER"} ,
		{"word":"enlèveriez","word_nosc":"enleveriez","lemma":"enlever","pos":"VER"} ,
		{"word":"enlèverons","word_nosc":"enleverons","lemma":"enlever","pos":"VER"} ,
		{"word":"enlèveront","word_nosc":"enleveront","lemma":"enlever","pos":"VER"} ,
		{"word":"enlèves","word_nosc":"enleves","lemma":"enlever","pos":"VER"} ,
		{"word":"enneige","word_nosc":"enneige","lemma":"enneiger","pos":"VER"} ,
		{"word":"enneigeait","word_nosc":"enneigeait","lemma":"enneiger","pos":"VER"} ,
		{"word":"enneiger","word_nosc":"enneiger","lemma":"enneiger","pos":"VER"} ,
		{"word":"enneigé","word_nosc":"enneige","lemma":"enneiger","pos":"VER"} ,
		{"word":"enneigée","word_nosc":"enneigee","lemma":"enneiger","pos":"VER"} ,
		{"word":"enneigées","word_nosc":"enneigees","lemma":"enneiger","pos":"VER"} ,
		{"word":"enneigés","word_nosc":"enneiges","lemma":"enneiger","pos":"VER"} ,
		{"word":"ennobli","word_nosc":"ennobli","lemma":"ennoblir","pos":"VER"} ,
		{"word":"ennoblie","word_nosc":"ennoblie","lemma":"ennoblir","pos":"VER"} ,
		{"word":"ennoblir","word_nosc":"ennoblir","lemma":"ennoblir","pos":"VER"} ,
		{"word":"ennoblis","word_nosc":"ennoblis","lemma":"ennoblir","pos":"VER"} ,
		{"word":"ennoblit","word_nosc":"ennoblit","lemma":"ennoblir","pos":"VER"} ,
		{"word":"ennuagent","word_nosc":"ennuagent","lemma":"ennuager","pos":"VER"} ,
		{"word":"ennuager","word_nosc":"ennuager","lemma":"ennuager","pos":"VER"} ,
		{"word":"ennuagé","word_nosc":"ennuage","lemma":"ennuager","pos":"VER"} ,
		{"word":"ennuie","word_nosc":"ennuie","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuient","word_nosc":"ennuient","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuiera","word_nosc":"ennuiera","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuierai","word_nosc":"ennuierai","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuieraient","word_nosc":"ennuieraient","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuierais","word_nosc":"ennuierais","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuierait","word_nosc":"ennuierait","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuieras","word_nosc":"ennuieras","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuierez","word_nosc":"ennuierez","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuieriez","word_nosc":"ennuieriez","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuierions","word_nosc":"ennuierions","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuierons","word_nosc":"ennuierons","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuieront","word_nosc":"ennuieront","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuies","word_nosc":"ennuies","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuya","word_nosc":"ennuya","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyai","word_nosc":"ennuyai","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyaient","word_nosc":"ennuyaient","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyais","word_nosc":"ennuyais","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyait","word_nosc":"ennuyait","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyant","word_nosc":"ennuyant","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyer","word_nosc":"ennuyer","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyez","word_nosc":"ennuyez","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyiez","word_nosc":"ennuyiez","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyions","word_nosc":"ennuyions","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyons","word_nosc":"ennuyons","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyât","word_nosc":"ennuyat","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyèrent","word_nosc":"ennuyerent","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyé","word_nosc":"ennuye","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyée","word_nosc":"ennuyee","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyées","word_nosc":"ennuyees","lemma":"ennuyer","pos":"VER"} ,
		{"word":"ennuyés","word_nosc":"ennuyes","lemma":"ennuyer","pos":"VER"} ,
		{"word":"enorgueilli","word_nosc":"enorgueilli","lemma":"enorgueillir","pos":"VER"} ,
		{"word":"enorgueillir","word_nosc":"enorgueillir","lemma":"enorgueillir","pos":"VER"} ,
		{"word":"enorgueillirent","word_nosc":"enorgueillirent","lemma":"enorgueillir","pos":"VER"} ,
		{"word":"enorgueillis","word_nosc":"enorgueillis","lemma":"enorgueillir","pos":"VER"} ,
		{"word":"enorgueillissais","word_nosc":"enorgueillissais","lemma":"enorgueillir","pos":"VER"} ,
		{"word":"enorgueillissait","word_nosc":"enorgueillissait","lemma":"enorgueillir","pos":"VER"} ,
		{"word":"enorgueillissant","word_nosc":"enorgueillissant","lemma":"enorgueillir","pos":"VER"} ,
		{"word":"enorgueillisse","word_nosc":"enorgueillisse","lemma":"enorgueillir","pos":"VER"} ,
		{"word":"enorgueillissent","word_nosc":"enorgueillissent","lemma":"enorgueillir","pos":"VER"} ,
		{"word":"enorgueillit","word_nosc":"enorgueillit","lemma":"enorgueillir","pos":"VER"} ,
		{"word":"enorgueillît","word_nosc":"enorgueillit","lemma":"enorgueillir","pos":"VER"} ,
		{"word":"enquerraient","word_nosc":"enquerraient","lemma":"enquérir","pos":"VER"} ,
		{"word":"enquiers","word_nosc":"enquiers","lemma":"enquérir","pos":"VER"} ,
		{"word":"enquiert","word_nosc":"enquiert","lemma":"enquérir","pos":"VER"} ,
		{"word":"enquillais","word_nosc":"enquillais","lemma":"enquiller","pos":"VER"} ,
		{"word":"enquillait","word_nosc":"enquillait","lemma":"enquiller","pos":"VER"} ,
		{"word":"enquillant","word_nosc":"enquillant","lemma":"enquiller","pos":"VER"} ,
		{"word":"enquille","word_nosc":"enquille","lemma":"enquiller","pos":"VER"} ,
		{"word":"enquillent","word_nosc":"enquillent","lemma":"enquiller","pos":"VER"} ,
		{"word":"enquiller","word_nosc":"enquiller","lemma":"enquiller","pos":"VER"} ,
		{"word":"enquillons","word_nosc":"enquillons","lemma":"enquiller","pos":"VER"} ,
		{"word":"enquillé","word_nosc":"enquille","lemma":"enquiller","pos":"VER"} ,
		{"word":"enquillés","word_nosc":"enquilles","lemma":"enquiller","pos":"VER"} ,
		{"word":"enquiquinaient","word_nosc":"enquiquinaient","lemma":"enquiquiner","pos":"VER"} ,
		{"word":"enquiquinait","word_nosc":"enquiquinait","lemma":"enquiquiner","pos":"VER"} ,
		{"word":"enquiquinant","word_nosc":"enquiquinant","lemma":"enquiquiner","pos":"VER"} ,
		{"word":"enquiquine","word_nosc":"enquiquine","lemma":"enquiquiner","pos":"VER"} ,
		{"word":"enquiquinent","word_nosc":"enquiquinent","lemma":"enquiquiner","pos":"VER"} ,
		{"word":"enquiquiner","word_nosc":"enquiquiner","lemma":"enquiquiner","pos":"VER"} ,
		{"word":"enquiquineraient","word_nosc":"enquiquineraient","lemma":"enquiquiner","pos":"VER"} ,
		{"word":"enquiquines","word_nosc":"enquiquines","lemma":"enquiquiner","pos":"VER"} ,
		{"word":"enquiquiné","word_nosc":"enquiquine","lemma":"enquiquiner","pos":"VER"} ,
		{"word":"enquis","word_nosc":"enquis","lemma":"enquérir","pos":"VER"} ,
		{"word":"enquise","word_nosc":"enquise","lemma":"enquérir","pos":"VER"} ,
		{"word":"enquit","word_nosc":"enquit","lemma":"enquérir","pos":"VER"} ,
		{"word":"enquière","word_nosc":"enquiere","lemma":"enquérir","pos":"VER"} ,
		{"word":"enquièrent","word_nosc":"enquierent","lemma":"enquérir","pos":"VER"} ,
		{"word":"enquéraient","word_nosc":"enqueraient","lemma":"enquérir","pos":"VER"} ,
		{"word":"enquérais","word_nosc":"enquerais","lemma":"enquérir","pos":"VER"} ,
		{"word":"enquérait","word_nosc":"enquerait","lemma":"enquérir","pos":"VER"} ,
		{"word":"enquérant","word_nosc":"enquerant","lemma":"enquérir","pos":"VER"} ,
		{"word":"enquérez","word_nosc":"enquerez","lemma":"enquérir","pos":"VER"} ,
		{"word":"enquérir","word_nosc":"enquerir","lemma":"enquérir","pos":"VER"} ,
		{"word":"enquêta","word_nosc":"enqueta","lemma":"enquêter","pos":"VER"} ,
		{"word":"enquêtais","word_nosc":"enquetais","lemma":"enquêter","pos":"VER"} ,
		{"word":"enquêtait","word_nosc":"enquetait","lemma":"enquêter","pos":"VER"} ,
		{"word":"enquêtant","word_nosc":"enquetant","lemma":"enquêter","pos":"VER"} ,
		{"word":"enquête","word_nosc":"enquete","lemma":"enquêter","pos":"VER"} ,
		{"word":"enquêtent","word_nosc":"enquetent","lemma":"enquêter","pos":"VER"} ,
		{"word":"enquêter","word_nosc":"enqueter","lemma":"enquêter","pos":"VER"} ,
		{"word":"enquêtes","word_nosc":"enquetes","lemma":"enquêter","pos":"VER"} ,
		{"word":"enquêtez","word_nosc":"enquetez","lemma":"enquêter","pos":"VER"} ,
		{"word":"enquêté","word_nosc":"enquete","lemma":"enquêter","pos":"VER"} ,
		{"word":"enracinais","word_nosc":"enracinais","lemma":"enraciner","pos":"VER"} ,
		{"word":"enracinait","word_nosc":"enracinait","lemma":"enraciner","pos":"VER"} ,
		{"word":"enracinant","word_nosc":"enracinant","lemma":"enraciner","pos":"VER"} ,
		{"word":"enracine","word_nosc":"enracine","lemma":"enraciner","pos":"VER"} ,
		{"word":"enracinent","word_nosc":"enracinent","lemma":"enraciner","pos":"VER"} ,
		{"word":"enraciner","word_nosc":"enraciner","lemma":"enraciner","pos":"VER"} ,
		{"word":"enracinerait","word_nosc":"enracinerait","lemma":"enraciner","pos":"VER"} ,
		{"word":"enracineras","word_nosc":"enracineras","lemma":"enraciner","pos":"VER"} ,
		{"word":"enracinerez","word_nosc":"enracinerez","lemma":"enraciner","pos":"VER"} ,
		{"word":"enraciné","word_nosc":"enracine","lemma":"enraciner","pos":"VER"} ,
		{"word":"enracinée","word_nosc":"enracinee","lemma":"enraciner","pos":"VER"} ,
		{"word":"enracinées","word_nosc":"enracinees","lemma":"enraciner","pos":"VER"} ,
		{"word":"enracinés","word_nosc":"enracines","lemma":"enraciner","pos":"VER"} ,
		{"word":"enrage","word_nosc":"enrage","lemma":"enrager","pos":"VER"} ,
		{"word":"enragea","word_nosc":"enragea","lemma":"enrager","pos":"VER"} ,
		{"word":"enrageai","word_nosc":"enrageai","lemma":"enrager","pos":"VER"} ,
		{"word":"enrageais","word_nosc":"enrageais","lemma":"enrager","pos":"VER"} ,
		{"word":"enrageait","word_nosc":"enrageait","lemma":"enrager","pos":"VER"} ,
		{"word":"enrageant","word_nosc":"enrageant","lemma":"enrager","pos":"VER"} ,
		{"word":"enragent","word_nosc":"enragent","lemma":"enrager","pos":"VER"} ,
		{"word":"enrager","word_nosc":"enrager","lemma":"enrager","pos":"VER"} ,
		{"word":"enragerais","word_nosc":"enragerais","lemma":"enrager","pos":"VER"} ,
		{"word":"enragé","word_nosc":"enrage","lemma":"enrager","pos":"VER"} ,
		{"word":"enragée","word_nosc":"enragee","lemma":"enrager","pos":"VER"} ,
		{"word":"enragées","word_nosc":"enragees","lemma":"enrager","pos":"VER"} ,
		{"word":"enragés","word_nosc":"enrages","lemma":"enrager","pos":"VER"} ,
		{"word":"enraie","word_nosc":"enraie","lemma":"enrayer","pos":"VER"} ,
		{"word":"enraillée","word_nosc":"enraillee","lemma":"enrailler","pos":"VER"} ,
		{"word":"enraya","word_nosc":"enraya","lemma":"enrayer","pos":"VER"} ,
		{"word":"enrayait","word_nosc":"enrayait","lemma":"enrayer","pos":"VER"} ,
		{"word":"enrayant","word_nosc":"enrayant","lemma":"enrayer","pos":"VER"} ,
		{"word":"enraye","word_nosc":"enraye","lemma":"enrayer","pos":"VER"} ,
		{"word":"enrayent","word_nosc":"enrayent","lemma":"enrayer","pos":"VER"} ,
		{"word":"enrayer","word_nosc":"enrayer","lemma":"enrayer","pos":"VER"} ,
		{"word":"enrayera","word_nosc":"enrayera","lemma":"enrayer","pos":"VER"} ,
		{"word":"enrayé","word_nosc":"enraye","lemma":"enrayer","pos":"VER"} ,
		{"word":"enrayée","word_nosc":"enrayee","lemma":"enrayer","pos":"VER"} ,
		{"word":"enregistra","word_nosc":"enregistra","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrai","word_nosc":"enregistrai","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistraient","word_nosc":"enregistraient","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrais","word_nosc":"enregistrais","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrait","word_nosc":"enregistrait","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrant","word_nosc":"enregistrant","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistre","word_nosc":"enregistre","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrent","word_nosc":"enregistrent","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrer","word_nosc":"enregistrer","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrera","word_nosc":"enregistrera","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrerai","word_nosc":"enregistrerai","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrerait","word_nosc":"enregistrerait","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistreras","word_nosc":"enregistreras","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrerez","word_nosc":"enregistrerez","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrerons","word_nosc":"enregistrerons","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistreront","word_nosc":"enregistreront","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistres","word_nosc":"enregistres","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrez","word_nosc":"enregistrez","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistriez","word_nosc":"enregistriez","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrions","word_nosc":"enregistrions","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrons","word_nosc":"enregistrons","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistré","word_nosc":"enregistre","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrée","word_nosc":"enregistree","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrées","word_nosc":"enregistrees","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enregistrés","word_nosc":"enregistres","lemma":"enregistrer","pos":"VER"} ,
		{"word":"enrhumait","word_nosc":"enrhumait","lemma":"enrhumer","pos":"VER"} ,
		{"word":"enrhume","word_nosc":"enrhume","lemma":"enrhumer","pos":"VER"} ,
		{"word":"enrhument","word_nosc":"enrhument","lemma":"enrhumer","pos":"VER"} ,
		{"word":"enrhumer","word_nosc":"enrhumer","lemma":"enrhumer","pos":"VER"} ,
		{"word":"enrhumes","word_nosc":"enrhumes","lemma":"enrhumer","pos":"VER"} ,
		{"word":"enrhumez","word_nosc":"enrhumez","lemma":"enrhumer","pos":"VER"} ,
		{"word":"enrhumé","word_nosc":"enrhume","lemma":"enrhumer","pos":"VER"} ,
		{"word":"enrhumée","word_nosc":"enrhumee","lemma":"enrhumer","pos":"VER"} ,
		{"word":"enrhumés","word_nosc":"enrhumes","lemma":"enrhumer","pos":"VER"} ,
		{"word":"enrichi","word_nosc":"enrichi","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichie","word_nosc":"enrichie","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichies","word_nosc":"enrichies","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichir","word_nosc":"enrichir","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichira","word_nosc":"enrichira","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichirais","word_nosc":"enrichirais","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichirait","word_nosc":"enrichirait","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichiront","word_nosc":"enrichiront","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichis","word_nosc":"enrichis","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichissaient","word_nosc":"enrichissaient","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichissais","word_nosc":"enrichissais","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichissait","word_nosc":"enrichissait","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichissant","word_nosc":"enrichissant","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichisse","word_nosc":"enrichisse","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichissent","word_nosc":"enrichissent","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichissez","word_nosc":"enrichissez","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrichit","word_nosc":"enrichit","lemma":"enrichir","pos":"VER"} ,
		{"word":"enrobaient","word_nosc":"enrobaient","lemma":"enrober","pos":"VER"} ,
		{"word":"enrobait","word_nosc":"enrobait","lemma":"enrober","pos":"VER"} ,
		{"word":"enrobant","word_nosc":"enrobant","lemma":"enrober","pos":"VER"} ,
		{"word":"enrobe","word_nosc":"enrobe","lemma":"enrober","pos":"VER"} ,
		{"word":"enrober","word_nosc":"enrober","lemma":"enrober","pos":"VER"} ,
		{"word":"enrobèrent","word_nosc":"enroberent","lemma":"enrober","pos":"VER"} ,
		{"word":"enrobé","word_nosc":"enrobe","lemma":"enrober","pos":"VER"} ,
		{"word":"enrobée","word_nosc":"enrobee","lemma":"enrober","pos":"VER"} ,
		{"word":"enrobées","word_nosc":"enrobees","lemma":"enrober","pos":"VER"} ,
		{"word":"enrobés","word_nosc":"enrobes","lemma":"enrober","pos":"VER"} ,
		{"word":"enroua","word_nosc":"enroua","lemma":"enrouer","pos":"VER"} ,
		{"word":"enrouaient","word_nosc":"enrouaient","lemma":"enrouer","pos":"VER"} ,
		{"word":"enrouait","word_nosc":"enrouait","lemma":"enrouer","pos":"VER"} ,
		{"word":"enroue","word_nosc":"enroue","lemma":"enrouer","pos":"VER"} ,
		{"word":"enrouer","word_nosc":"enrouer","lemma":"enrouer","pos":"VER"} ,
		{"word":"enroula","word_nosc":"enroula","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroulai","word_nosc":"enroulai","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroulaient","word_nosc":"enroulaient","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroulais","word_nosc":"enroulais","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroulait","word_nosc":"enroulait","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroulant","word_nosc":"enroulant","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroule","word_nosc":"enroule","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroulent","word_nosc":"enroulent","lemma":"enrouler","pos":"VER"} ,
		{"word":"enrouler","word_nosc":"enrouler","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroulera","word_nosc":"enroulera","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroulerais","word_nosc":"enroulerais","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroulez","word_nosc":"enroulez","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroulèrent","word_nosc":"enroulerent","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroulé","word_nosc":"enroule","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroulée","word_nosc":"enroulee","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroulées","word_nosc":"enroulees","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroulés","word_nosc":"enroules","lemma":"enrouler","pos":"VER"} ,
		{"word":"enroué","word_nosc":"enroue","lemma":"enrouer","pos":"VER"} ,
		{"word":"enrouée","word_nosc":"enrouee","lemma":"enrouer","pos":"VER"} ,
		{"word":"enrouées","word_nosc":"enrouees","lemma":"enrouer","pos":"VER"} ,
		{"word":"enroués","word_nosc":"enroues","lemma":"enrouer","pos":"VER"} ,
		{"word":"enrubannaient","word_nosc":"enrubannaient","lemma":"enrubanner","pos":"VER"} ,
		{"word":"enrubannait","word_nosc":"enrubannait","lemma":"enrubanner","pos":"VER"} ,
		{"word":"enrubanner","word_nosc":"enrubanner","lemma":"enrubanner","pos":"VER"} ,
		{"word":"enrubanné","word_nosc":"enrubanne","lemma":"enrubanner","pos":"VER"} ,
		{"word":"enrubannée","word_nosc":"enrubannee","lemma":"enrubanner","pos":"VER"} ,
		{"word":"enrubannées","word_nosc":"enrubannees","lemma":"enrubanner","pos":"VER"} ,
		{"word":"enrubannés","word_nosc":"enrubannes","lemma":"enrubanner","pos":"VER"} ,
		{"word":"enrégimentait","word_nosc":"enregimentait","lemma":"enrégimenter","pos":"VER"} ,
		{"word":"enrégimenter","word_nosc":"enregimenter","lemma":"enrégimenter","pos":"VER"} ,
		{"word":"enrégimenterait","word_nosc":"enregimenterait","lemma":"enrégimenter","pos":"VER"} ,
		{"word":"enrôla","word_nosc":"enrola","lemma":"enrôler","pos":"VER"} ,
		{"word":"enrôlaient","word_nosc":"enrolaient","lemma":"enrôler","pos":"VER"} ,
		{"word":"enrôlais","word_nosc":"enrolais","lemma":"enrôler","pos":"VER"} ,
		{"word":"enrôlait","word_nosc":"enrolait","lemma":"enrôler","pos":"VER"} ,
		{"word":"enrôlant","word_nosc":"enrolant","lemma":"enrôler","pos":"VER"} ,
		{"word":"enrôle","word_nosc":"enrole","lemma":"enrôler","pos":"VER"} ,
		{"word":"enrôler","word_nosc":"enroler","lemma":"enrôler","pos":"VER"} ,
		{"word":"enrôlerait","word_nosc":"enrolerait","lemma":"enrôler","pos":"VER"} ,
		{"word":"enrôleriez","word_nosc":"enroleriez","lemma":"enrôler","pos":"VER"} ,
		{"word":"enrôlez","word_nosc":"enrolez","lemma":"enrôler","pos":"VER"} ,
		{"word":"enrôlons","word_nosc":"enrolons","lemma":"enrôler","pos":"VER"} ,
		{"word":"enrôlé","word_nosc":"enrole","lemma":"enrôler","pos":"VER"} ,
		{"word":"enrôlée","word_nosc":"enrolee","lemma":"enrôler","pos":"VER"} ,
		{"word":"enrôlées","word_nosc":"enrolees","lemma":"enrôler","pos":"VER"} ,
		{"word":"enrôlés","word_nosc":"enroles","lemma":"enrôler","pos":"VER"} ,
		{"word":"ensablait","word_nosc":"ensablait","lemma":"ensabler","pos":"VER"} ,
		{"word":"ensable","word_nosc":"ensable","lemma":"ensabler","pos":"VER"} ,
		{"word":"ensablent","word_nosc":"ensablent","lemma":"ensabler","pos":"VER"} ,
		{"word":"ensabler","word_nosc":"ensabler","lemma":"ensabler","pos":"VER"} ,
		{"word":"ensablèrent","word_nosc":"ensablerent","lemma":"ensabler","pos":"VER"} ,
		{"word":"ensablé","word_nosc":"ensable","lemma":"ensabler","pos":"VER"} ,
		{"word":"ensablée","word_nosc":"ensablee","lemma":"ensabler","pos":"VER"} ,
		{"word":"ensablées","word_nosc":"ensablees","lemma":"ensabler","pos":"VER"} ,
		{"word":"ensablés","word_nosc":"ensables","lemma":"ensabler","pos":"VER"} ,
		{"word":"ensacher","word_nosc":"ensacher","lemma":"ensacher","pos":"VER"} ,
		{"word":"ensaché","word_nosc":"ensache","lemma":"ensacher","pos":"VER"} ,
		{"word":"ensanglanta","word_nosc":"ensanglanta","lemma":"ensanglanter","pos":"VER"} ,
		{"word":"ensanglantaient","word_nosc":"ensanglantaient","lemma":"ensanglanter","pos":"VER"} ,
		{"word":"ensanglantant","word_nosc":"ensanglantant","lemma":"ensanglanter","pos":"VER"} ,
		{"word":"ensanglante","word_nosc":"ensanglante","lemma":"ensanglanter","pos":"VER"} ,
		{"word":"ensanglanter","word_nosc":"ensanglanter","lemma":"ensanglanter","pos":"VER"} ,
		{"word":"ensanglantèrent","word_nosc":"ensanglanterent","lemma":"ensanglanter","pos":"VER"} ,
		{"word":"ensanglanté","word_nosc":"ensanglante","lemma":"ensanglanter","pos":"VER"} ,
		{"word":"ensanglantée","word_nosc":"ensanglantee","lemma":"ensanglanter","pos":"VER"} ,
		{"word":"ensanglantées","word_nosc":"ensanglantees","lemma":"ensanglanter","pos":"VER"} ,
		{"word":"ensanglantés","word_nosc":"ensanglantes","lemma":"ensanglanter","pos":"VER"} ,
		{"word":"ensauvageait","word_nosc":"ensauvageait","lemma":"ensauvager","pos":"VER"} ,
		{"word":"ensauvagé","word_nosc":"ensauvage","lemma":"ensauvager","pos":"VER"} ,
		{"word":"ensauvagée","word_nosc":"ensauvagee","lemma":"ensauvager","pos":"VER"} ,
		{"word":"ensauvagées","word_nosc":"ensauvagees","lemma":"ensauvager","pos":"VER"} ,
		{"word":"ensauvagés","word_nosc":"ensauvages","lemma":"ensauvager","pos":"VER"} ,
		{"word":"ensauve","word_nosc":"ensauve","lemma":"ensauver","pos":"VER"} ,
		{"word":"ensauvés","word_nosc":"ensauves","lemma":"ensauver","pos":"VER"} ,
		{"word":"enseigna","word_nosc":"enseigna","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignaient","word_nosc":"enseignaient","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignais","word_nosc":"enseignais","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignait","word_nosc":"enseignait","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignant","word_nosc":"enseignant","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseigne","word_nosc":"enseigne","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignent","word_nosc":"enseignent","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseigner","word_nosc":"enseigner","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignera","word_nosc":"enseignera","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignerai","word_nosc":"enseignerai","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseigneraient","word_nosc":"enseigneraient","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignerais","word_nosc":"enseignerais","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignerait","word_nosc":"enseignerait","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseigneras","word_nosc":"enseigneras","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignerez","word_nosc":"enseignerez","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignerons","word_nosc":"enseignerons","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseigneront","word_nosc":"enseigneront","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignes","word_nosc":"enseignes","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignez","word_nosc":"enseignez","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseigniez","word_nosc":"enseigniez","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignons","word_nosc":"enseignons","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignât","word_nosc":"enseignat","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignèrent","word_nosc":"enseignerent","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseigné","word_nosc":"enseigne","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignée","word_nosc":"enseignee","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignées","word_nosc":"enseignees","lemma":"enseigner","pos":"VER"} ,
		{"word":"enseignés","word_nosc":"enseignes","lemma":"enseigner","pos":"VER"} ,
		{"word":"ensemencer","word_nosc":"ensemencer","lemma":"ensemencer","pos":"VER"} ,
		{"word":"ensemencé","word_nosc":"ensemence","lemma":"ensemencer","pos":"VER"} ,
		{"word":"ensemencée","word_nosc":"ensemencee","lemma":"ensemencer","pos":"VER"} ,
		{"word":"ensemencés","word_nosc":"ensemences","lemma":"ensemencer","pos":"VER"} ,
		{"word":"ensemença","word_nosc":"ensemenca","lemma":"ensemencer","pos":"VER"} ,
		{"word":"ensemençaient","word_nosc":"ensemencaient","lemma":"ensemencer","pos":"VER"} ,
		{"word":"ensemençait","word_nosc":"ensemencait","lemma":"ensemencer","pos":"VER"} ,
		{"word":"ensemençant","word_nosc":"ensemencant","lemma":"ensemencer","pos":"VER"} ,
		{"word":"enserra","word_nosc":"enserra","lemma":"enserrer","pos":"VER"} ,
		{"word":"enserrai","word_nosc":"enserrai","lemma":"enserrer","pos":"VER"} ,
		{"word":"enserraient","word_nosc":"enserraient","lemma":"enserrer","pos":"VER"} ,
		{"word":"enserrait","word_nosc":"enserrait","lemma":"enserrer","pos":"VER"} ,
		{"word":"enserrant","word_nosc":"enserrant","lemma":"enserrer","pos":"VER"} ,
		{"word":"enserre","word_nosc":"enserre","lemma":"enserrer","pos":"VER"} ,
		{"word":"enserrent","word_nosc":"enserrent","lemma":"enserrer","pos":"VER"} ,
		{"word":"enserrer","word_nosc":"enserrer","lemma":"enserrer","pos":"VER"} ,
		{"word":"enserrera","word_nosc":"enserrera","lemma":"enserrer","pos":"VER"} ,
		{"word":"enserrerait","word_nosc":"enserrerait","lemma":"enserrer","pos":"VER"} ,
		{"word":"enserres","word_nosc":"enserres","lemma":"enserrer","pos":"VER"} ,
		{"word":"enserrèrent","word_nosc":"enserrerent","lemma":"enserrer","pos":"VER"} ,
		{"word":"enserré","word_nosc":"enserre","lemma":"enserrer","pos":"VER"} ,
		{"word":"enserrée","word_nosc":"enserree","lemma":"enserrer","pos":"VER"} ,
		{"word":"enserrées","word_nosc":"enserrees","lemma":"enserrer","pos":"VER"} ,
		{"word":"enserrés","word_nosc":"enserres","lemma":"enserrer","pos":"VER"} ,
		{"word":"enseveli","word_nosc":"enseveli","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensevelie","word_nosc":"ensevelie","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensevelies","word_nosc":"ensevelies","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensevelir","word_nosc":"ensevelir","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensevelira","word_nosc":"ensevelira","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensevelirait","word_nosc":"ensevelirait","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensevelis","word_nosc":"ensevelis","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensevelissaient","word_nosc":"ensevelissaient","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensevelissais","word_nosc":"ensevelissais","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensevelissait","word_nosc":"ensevelissait","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensevelissant","word_nosc":"ensevelissant","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensevelisse","word_nosc":"ensevelisse","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensevelissent","word_nosc":"ensevelissent","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensevelissez","word_nosc":"ensevelissez","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensevelissons","word_nosc":"ensevelissons","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensevelit","word_nosc":"ensevelit","lemma":"ensevelir","pos":"VER"} ,
		{"word":"ensoleilla","word_nosc":"ensoleilla","lemma":"ensoleiller","pos":"VER"} ,
		{"word":"ensoleiller","word_nosc":"ensoleiller","lemma":"ensoleiller","pos":"VER"} ,
		{"word":"ensoleillèrent","word_nosc":"ensoleillerent","lemma":"ensoleiller","pos":"VER"} ,
		{"word":"ensoleillé","word_nosc":"ensoleille","lemma":"ensoleiller","pos":"VER"} ,
		{"word":"ensoleillée","word_nosc":"ensoleillee","lemma":"ensoleiller","pos":"VER"} ,
		{"word":"ensoleillées","word_nosc":"ensoleillees","lemma":"ensoleiller","pos":"VER"} ,
		{"word":"ensoleillés","word_nosc":"ensoleilles","lemma":"ensoleiller","pos":"VER"} ,
		{"word":"ensommeilla","word_nosc":"ensommeilla","lemma":"ensommeiller","pos":"VER"} ,
		{"word":"ensommeillaient","word_nosc":"ensommeillaient","lemma":"ensommeiller","pos":"VER"} ,
		{"word":"ensommeillait","word_nosc":"ensommeillait","lemma":"ensommeiller","pos":"VER"} ,
		{"word":"ensommeiller","word_nosc":"ensommeiller","lemma":"ensommeiller","pos":"VER"} ,
		{"word":"ensommeillé","word_nosc":"ensommeille","lemma":"ensommeiller","pos":"VER"} ,
		{"word":"ensommeillée","word_nosc":"ensommeillee","lemma":"ensommeiller","pos":"VER"} ,
		{"word":"ensommeillées","word_nosc":"ensommeillees","lemma":"ensommeiller","pos":"VER"} ,
		{"word":"ensorcelaient","word_nosc":"ensorcelaient","lemma":"ensorceler","pos":"VER"} ,
		{"word":"ensorcelant","word_nosc":"ensorcelant","lemma":"ensorceler","pos":"VER"} ,
		{"word":"ensorceler","word_nosc":"ensorceler","lemma":"ensorceler","pos":"VER"} ,
		{"word":"ensorcelez","word_nosc":"ensorcelez","lemma":"ensorceler","pos":"VER"} ,
		{"word":"ensorcelle","word_nosc":"ensorcelle","lemma":"ensorceler","pos":"VER"} ,
		{"word":"ensorcellent","word_nosc":"ensorcellent","lemma":"ensorceler","pos":"VER"} ,
		{"word":"ensorcelles","word_nosc":"ensorcelles","lemma":"ensorceler","pos":"VER"} ,
		{"word":"ensorcelé","word_nosc":"ensorcele","lemma":"ensorceler","pos":"VER"} ,
		{"word":"ensorcelée","word_nosc":"ensorcelee","lemma":"ensorceler","pos":"VER"} ,
		{"word":"ensorcelées","word_nosc":"ensorcelees","lemma":"ensorceler","pos":"VER"} ,
		{"word":"ensorcelés","word_nosc":"ensorceles","lemma":"ensorceler","pos":"VER"} ,
		{"word":"ensoutanées","word_nosc":"ensoutanees","lemma":"ensoutaner","pos":"VER"} ,
		{"word":"ensuit","word_nosc":"ensuit","lemma":"ensuivre","pos":"VER"} ,
		{"word":"ensuivaient","word_nosc":"ensuivaient","lemma":"ensuivre","pos":"VER"} ,
		{"word":"ensuivait","word_nosc":"ensuivait","lemma":"ensuivre","pos":"VER"} ,
		{"word":"ensuive","word_nosc":"ensuive","lemma":"ensuivre","pos":"VER"} ,
		{"word":"ensuivent","word_nosc":"ensuivent","lemma":"ensuivre","pos":"VER"} ,
		{"word":"ensuivi","word_nosc":"ensuivi","lemma":"ensuivre","pos":"VER"} ,
		{"word":"ensuivie","word_nosc":"ensuivie","lemma":"ensuivre","pos":"VER"} ,
		{"word":"ensuivies","word_nosc":"ensuivies","lemma":"ensuivre","pos":"VER"} ,
		{"word":"ensuivirent","word_nosc":"ensuivirent","lemma":"ensuivre","pos":"VER"} ,
		{"word":"ensuivit","word_nosc":"ensuivit","lemma":"ensuivre","pos":"VER"} ,
		{"word":"ensuivra","word_nosc":"ensuivra","lemma":"ensuivre","pos":"VER"} ,
		{"word":"ensuivraient","word_nosc":"ensuivraient","lemma":"ensuivre","pos":"VER"} ,
		{"word":"ensuivrait","word_nosc":"ensuivrait","lemma":"ensuivre","pos":"VER"} ,
		{"word":"ensuivre","word_nosc":"ensuivre","lemma":"ensuivre","pos":"VER"} ,
		{"word":"ensuivront","word_nosc":"ensuivront","lemma":"ensuivre","pos":"VER"} ,
		{"word":"ensuquent","word_nosc":"ensuquent","lemma":"ensuquer","pos":"VER"} ,
		{"word":"ensuqué","word_nosc":"ensuque","lemma":"ensuquer","pos":"VER"} ,
		{"word":"ensuquée","word_nosc":"ensuquee","lemma":"ensuquer","pos":"VER"} ,
		{"word":"entachait","word_nosc":"entachait","lemma":"entacher","pos":"VER"} ,
		{"word":"entache","word_nosc":"entache","lemma":"entacher","pos":"VER"} ,
		{"word":"entachent","word_nosc":"entachent","lemma":"entacher","pos":"VER"} ,
		{"word":"entacher","word_nosc":"entacher","lemma":"entacher","pos":"VER"} ,
		{"word":"entacherait","word_nosc":"entacherait","lemma":"entacher","pos":"VER"} ,
		{"word":"entaché","word_nosc":"entache","lemma":"entacher","pos":"VER"} ,
		{"word":"entachée","word_nosc":"entachee","lemma":"entacher","pos":"VER"} ,
		{"word":"entachées","word_nosc":"entachees","lemma":"entacher","pos":"VER"} ,
		{"word":"entachés","word_nosc":"entaches","lemma":"entacher","pos":"VER"} ,
		{"word":"entailla","word_nosc":"entailla","lemma":"entailler","pos":"VER"} ,
		{"word":"entaillait","word_nosc":"entaillait","lemma":"entailler","pos":"VER"} ,
		{"word":"entaille","word_nosc":"entaille","lemma":"entailler","pos":"VER"} ,
		{"word":"entaillent","word_nosc":"entaillent","lemma":"entailler","pos":"VER"} ,
		{"word":"entailler","word_nosc":"entailler","lemma":"entailler","pos":"VER"} ,
		{"word":"entaillerai","word_nosc":"entaillerai","lemma":"entailler","pos":"VER"} ,
		{"word":"entailles","word_nosc":"entailles","lemma":"entailler","pos":"VER"} ,
		{"word":"entaillé","word_nosc":"entaille","lemma":"entailler","pos":"VER"} ,
		{"word":"entaillée","word_nosc":"entaillee","lemma":"entailler","pos":"VER"} ,
		{"word":"entaillées","word_nosc":"entaillees","lemma":"entailler","pos":"VER"} ,
		{"word":"entaillés","word_nosc":"entailles","lemma":"entailler","pos":"VER"} ,
		{"word":"entama","word_nosc":"entama","lemma":"entamer","pos":"VER"} ,
		{"word":"entamai","word_nosc":"entamai","lemma":"entamer","pos":"VER"} ,
		{"word":"entamaient","word_nosc":"entamaient","lemma":"entamer","pos":"VER"} ,
		{"word":"entamais","word_nosc":"entamais","lemma":"entamer","pos":"VER"} ,
		{"word":"entamait","word_nosc":"entamait","lemma":"entamer","pos":"VER"} ,
		{"word":"entamant","word_nosc":"entamant","lemma":"entamer","pos":"VER"} ,
		{"word":"entame","word_nosc":"entame","lemma":"entamer","pos":"VER"} ,
		{"word":"entament","word_nosc":"entament","lemma":"entamer","pos":"VER"} ,
		{"word":"entamer","word_nosc":"entamer","lemma":"entamer","pos":"VER"} ,
		{"word":"entamera","word_nosc":"entamera","lemma":"entamer","pos":"VER"} ,
		{"word":"entamerai","word_nosc":"entamerai","lemma":"entamer","pos":"VER"} ,
		{"word":"entameraient","word_nosc":"entameraient","lemma":"entamer","pos":"VER"} ,
		{"word":"entamerais","word_nosc":"entamerais","lemma":"entamer","pos":"VER"} ,
		{"word":"entamerait","word_nosc":"entamerait","lemma":"entamer","pos":"VER"} ,
		{"word":"entamerez","word_nosc":"entamerez","lemma":"entamer","pos":"VER"} ,
		{"word":"entamerons","word_nosc":"entamerons","lemma":"entamer","pos":"VER"} ,
		{"word":"entames","word_nosc":"entames","lemma":"entamer","pos":"VER"} ,
		{"word":"entamez","word_nosc":"entamez","lemma":"entamer","pos":"VER"} ,
		{"word":"entamons","word_nosc":"entamons","lemma":"entamer","pos":"VER"} ,
		{"word":"entamât","word_nosc":"entamat","lemma":"entamer","pos":"VER"} ,
		{"word":"entamèrent","word_nosc":"entamerent","lemma":"entamer","pos":"VER"} ,
		{"word":"entamé","word_nosc":"entame","lemma":"entamer","pos":"VER"} ,
		{"word":"entamée","word_nosc":"entamee","lemma":"entamer","pos":"VER"} ,
		{"word":"entamées","word_nosc":"entamees","lemma":"entamer","pos":"VER"} ,
		{"word":"entamés","word_nosc":"entames","lemma":"entamer","pos":"VER"} ,
		{"word":"entant","word_nosc":"entant","lemma":"enter","pos":"VER"} ,
		{"word":"entassa","word_nosc":"entassa","lemma":"entasser","pos":"VER"} ,
		{"word":"entassaient","word_nosc":"entassaient","lemma":"entasser","pos":"VER"} ,
		{"word":"entassait","word_nosc":"entassait","lemma":"entasser","pos":"VER"} ,
		{"word":"entassant","word_nosc":"entassant","lemma":"entasser","pos":"VER"} ,
		{"word":"entasse","word_nosc":"entasse","lemma":"entasser","pos":"VER"} ,
		{"word":"entassent","word_nosc":"entassent","lemma":"entasser","pos":"VER"} ,
		{"word":"entasser","word_nosc":"entasser","lemma":"entasser","pos":"VER"} ,
		{"word":"entassera","word_nosc":"entassera","lemma":"entasser","pos":"VER"} ,
		{"word":"entasseraient","word_nosc":"entasseraient","lemma":"entasser","pos":"VER"} ,
		{"word":"entasserait","word_nosc":"entasserait","lemma":"entasser","pos":"VER"} ,
		{"word":"entasseront","word_nosc":"entasseront","lemma":"entasser","pos":"VER"} ,
		{"word":"entassez","word_nosc":"entassez","lemma":"entasser","pos":"VER"} ,
		{"word":"entassions","word_nosc":"entassions","lemma":"entasser","pos":"VER"} ,
		{"word":"entassons","word_nosc":"entassons","lemma":"entasser","pos":"VER"} ,
		{"word":"entassâmes","word_nosc":"entassames","lemma":"entasser","pos":"VER"} ,
		{"word":"entassèrent","word_nosc":"entasserent","lemma":"entasser","pos":"VER"} ,
		{"word":"entassé","word_nosc":"entasse","lemma":"entasser","pos":"VER"} ,
		{"word":"entassée","word_nosc":"entassee","lemma":"entasser","pos":"VER"} ,
		{"word":"entassées","word_nosc":"entassees","lemma":"entasser","pos":"VER"} ,
		{"word":"entassés","word_nosc":"entasses","lemma":"entasser","pos":"VER"} ,
		{"word":"ente","word_nosc":"ente","lemma":"enter","pos":"VER"} ,
		{"word":"entend","word_nosc":"entend","lemma":"entendre","pos":"VER"} ,
		{"word":"entendaient","word_nosc":"entendaient","lemma":"entendre","pos":"VER"} ,
		{"word":"entendais","word_nosc":"entendais","lemma":"entendre","pos":"VER"} ,
		{"word":"entendait","word_nosc":"entendait","lemma":"entendre","pos":"VER"} ,
		{"word":"entendant","word_nosc":"entendant","lemma":"entendre","pos":"VER"} ,
		{"word":"entende","word_nosc":"entende","lemma":"entendre","pos":"VER"} ,
		{"word":"entendent","word_nosc":"entendent","lemma":"entendre","pos":"VER"} ,
		{"word":"entendes","word_nosc":"entendes","lemma":"entendre","pos":"VER"} ,
		{"word":"entendez","word_nosc":"entendez","lemma":"entendre","pos":"VER"} ,
		{"word":"entendiez","word_nosc":"entendiez","lemma":"entendre","pos":"VER"} ,
		{"word":"entendions","word_nosc":"entendions","lemma":"entendre","pos":"VER"} ,
		{"word":"entendirent","word_nosc":"entendirent","lemma":"entendre","pos":"VER"} ,
		{"word":"entendis","word_nosc":"entendis","lemma":"entendre","pos":"VER"} ,
		{"word":"entendisse","word_nosc":"entendisse","lemma":"entendre","pos":"VER"} ,
		{"word":"entendissent","word_nosc":"entendissent","lemma":"entendre","pos":"VER"} ,
		{"word":"entendit","word_nosc":"entendit","lemma":"entendre","pos":"VER"} ,
		{"word":"entendons","word_nosc":"entendons","lemma":"entendre","pos":"VER"} ,
		{"word":"entendra","word_nosc":"entendra","lemma":"entendre","pos":"VER"} ,
		{"word":"entendrai","word_nosc":"entendrai","lemma":"entendre","pos":"VER"} ,
		{"word":"entendraient","word_nosc":"entendraient","lemma":"entendre","pos":"VER"} ,
		{"word":"entendrais","word_nosc":"entendrais","lemma":"entendre","pos":"VER"} ,
		{"word":"entendrait","word_nosc":"entendrait","lemma":"entendre","pos":"VER"} ,
		{"word":"entendras","word_nosc":"entendras","lemma":"entendre","pos":"VER"} ,
		{"word":"entendre","word_nosc":"entendre","lemma":"entendre","pos":"VER"} ,
		{"word":"entendrez","word_nosc":"entendrez","lemma":"entendre","pos":"VER"} ,
		{"word":"entendriez","word_nosc":"entendriez","lemma":"entendre","pos":"VER"} ,
		{"word":"entendrions","word_nosc":"entendrions","lemma":"entendre","pos":"VER"} ,
		{"word":"entendrons","word_nosc":"entendrons","lemma":"entendre","pos":"VER"} ,
		{"word":"entendront","word_nosc":"entendront","lemma":"entendre","pos":"VER"} ,
		{"word":"entends","word_nosc":"entends","lemma":"entendre","pos":"VER"} ,
		{"word":"entendu","word_nosc":"entendu","lemma":"entendre","pos":"VER"} ,
		{"word":"entendue","word_nosc":"entendue","lemma":"entendre","pos":"VER"} ,
		{"word":"entendues","word_nosc":"entendues","lemma":"entendre","pos":"VER"} ,
		{"word":"entendus","word_nosc":"entendus","lemma":"entendre","pos":"VER"} ,
		{"word":"entendîmes","word_nosc":"entendimes","lemma":"entendre","pos":"VER"} ,
		{"word":"entendît","word_nosc":"entendit","lemma":"entendre","pos":"VER"} ,
		{"word":"enter","word_nosc":"enter","lemma":"enter","pos":"VER"} ,
		{"word":"enterra","word_nosc":"enterra","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrai","word_nosc":"enterrai","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterraient","word_nosc":"enterraient","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrais","word_nosc":"enterrais","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrait","word_nosc":"enterrait","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrant","word_nosc":"enterrant","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterre","word_nosc":"enterre","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrent","word_nosc":"enterrent","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrer","word_nosc":"enterrer","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrera","word_nosc":"enterrera","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrerai","word_nosc":"enterrerai","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrerais","word_nosc":"enterrerais","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrerait","word_nosc":"enterrerait","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterreras","word_nosc":"enterreras","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrerez","word_nosc":"enterrerez","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrerons","word_nosc":"enterrerons","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterreront","word_nosc":"enterreront","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterres","word_nosc":"enterres","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrez","word_nosc":"enterrez","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterriez","word_nosc":"enterriez","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrions","word_nosc":"enterrions","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrons","word_nosc":"enterrons","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrâmes","word_nosc":"enterrames","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrèrent","word_nosc":"enterrerent","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterré","word_nosc":"enterre","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrée","word_nosc":"enterree","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrées","word_nosc":"enterrees","lemma":"enterrer","pos":"VER"} ,
		{"word":"enterrés","word_nosc":"enterres","lemma":"enterrer","pos":"VER"} ,
		{"word":"enthousiasma","word_nosc":"enthousiasma","lemma":"enthousiasmer","pos":"VER"} ,
		{"word":"enthousiasmai","word_nosc":"enthousiasmai","lemma":"enthousiasmer","pos":"VER"} ,
		{"word":"enthousiasmaient","word_nosc":"enthousiasmaient","lemma":"enthousiasmer","pos":"VER"} ,
		{"word":"enthousiasmais","word_nosc":"enthousiasmais","lemma":"enthousiasmer","pos":"VER"} ,
		{"word":"enthousiasmait","word_nosc":"enthousiasmait","lemma":"enthousiasmer","pos":"VER"} ,
		{"word":"enthousiasmant","word_nosc":"enthousiasmant","lemma":"enthousiasmer","pos":"VER"} ,
		{"word":"enthousiasme","word_nosc":"enthousiasme","lemma":"enthousiasmer","pos":"VER"} ,
		{"word":"enthousiasmer","word_nosc":"enthousiasmer","lemma":"enthousiasmer","pos":"VER"} ,
		{"word":"enthousiasmez","word_nosc":"enthousiasmez","lemma":"enthousiasmer","pos":"VER"} ,
		{"word":"enthousiasmèrent","word_nosc":"enthousiasmerent","lemma":"enthousiasmer","pos":"VER"} ,
		{"word":"enthousiasmé","word_nosc":"enthousiasme","lemma":"enthousiasmer","pos":"VER"} ,
		{"word":"enthousiasmée","word_nosc":"enthousiasmee","lemma":"enthousiasmer","pos":"VER"} ,
		{"word":"enthousiasmés","word_nosc":"enthousiasmes","lemma":"enthousiasmer","pos":"VER"} ,
		{"word":"enticha","word_nosc":"enticha","lemma":"enticher","pos":"VER"} ,
		{"word":"entichait","word_nosc":"entichait","lemma":"enticher","pos":"VER"} ,
		{"word":"entiche","word_nosc":"entiche","lemma":"enticher","pos":"VER"} ,
		{"word":"entichent","word_nosc":"entichent","lemma":"enticher","pos":"VER"} ,
		{"word":"enticher","word_nosc":"enticher","lemma":"enticher","pos":"VER"} ,
		{"word":"entichera","word_nosc":"entichera","lemma":"enticher","pos":"VER"} ,
		{"word":"entichèrent","word_nosc":"enticherent","lemma":"enticher","pos":"VER"} ,
		{"word":"entiché","word_nosc":"entiche","lemma":"enticher","pos":"VER"} ,
		{"word":"entichée","word_nosc":"entichee","lemma":"enticher","pos":"VER"} ,
		{"word":"entichés","word_nosc":"entiches","lemma":"enticher","pos":"VER"} ,
		{"word":"entifler","word_nosc":"entifler","lemma":"entifler","pos":"VER"} ,
		{"word":"entiflé","word_nosc":"entifle","lemma":"entifler","pos":"VER"} ,
		{"word":"entoiler","word_nosc":"entoiler","lemma":"entoiler","pos":"VER"} ,
		{"word":"entoilées","word_nosc":"entoilees","lemma":"entoiler","pos":"VER"} ,
		{"word":"entoilés","word_nosc":"entoiles","lemma":"entoiler","pos":"VER"} ,
		{"word":"entonna","word_nosc":"entonna","lemma":"entonner","pos":"VER"} ,
		{"word":"entonnaient","word_nosc":"entonnaient","lemma":"entonner","pos":"VER"} ,
		{"word":"entonnais","word_nosc":"entonnais","lemma":"entonner","pos":"VER"} ,
		{"word":"entonnait","word_nosc":"entonnait","lemma":"entonner","pos":"VER"} ,
		{"word":"entonnant","word_nosc":"entonnant","lemma":"entonner","pos":"VER"} ,
		{"word":"entonne","word_nosc":"entonne","lemma":"entonner","pos":"VER"} ,
		{"word":"entonnent","word_nosc":"entonnent","lemma":"entonner","pos":"VER"} ,
		{"word":"entonner","word_nosc":"entonner","lemma":"entonner","pos":"VER"} ,
		{"word":"entonnera","word_nosc":"entonnera","lemma":"entonner","pos":"VER"} ,
		{"word":"entonneraient","word_nosc":"entonneraient","lemma":"entonner","pos":"VER"} ,
		{"word":"entonnions","word_nosc":"entonnions","lemma":"entonner","pos":"VER"} ,
		{"word":"entonnons","word_nosc":"entonnons","lemma":"entonner","pos":"VER"} ,
		{"word":"entonnâmes","word_nosc":"entonnames","lemma":"entonner","pos":"VER"} ,
		{"word":"entonnèrent","word_nosc":"entonnerent","lemma":"entonner","pos":"VER"} ,
		{"word":"entonné","word_nosc":"entonne","lemma":"entonner","pos":"VER"} ,
		{"word":"entortilla","word_nosc":"entortilla","lemma":"entortiller","pos":"VER"} ,
		{"word":"entortillai","word_nosc":"entortillai","lemma":"entortiller","pos":"VER"} ,
		{"word":"entortillaient","word_nosc":"entortillaient","lemma":"entortiller","pos":"VER"} ,
		{"word":"entortillait","word_nosc":"entortillait","lemma":"entortiller","pos":"VER"} ,
		{"word":"entortillant","word_nosc":"entortillant","lemma":"entortiller","pos":"VER"} ,
		{"word":"entortille","word_nosc":"entortille","lemma":"entortiller","pos":"VER"} ,
		{"word":"entortillent","word_nosc":"entortillent","lemma":"entortiller","pos":"VER"} ,
		{"word":"entortiller","word_nosc":"entortiller","lemma":"entortiller","pos":"VER"} ,
		{"word":"entortilleraient","word_nosc":"entortilleraient","lemma":"entortiller","pos":"VER"} ,
		{"word":"entortillé","word_nosc":"entortille","lemma":"entortiller","pos":"VER"} ,
		{"word":"entortillée","word_nosc":"entortillee","lemma":"entortiller","pos":"VER"} ,
		{"word":"entortillées","word_nosc":"entortillees","lemma":"entortiller","pos":"VER"} ,
		{"word":"entortillés","word_nosc":"entortilles","lemma":"entortiller","pos":"VER"} ,
		{"word":"entoura","word_nosc":"entoura","lemma":"entourer","pos":"VER"} ,
		{"word":"entourai","word_nosc":"entourai","lemma":"entourer","pos":"VER"} ,
		{"word":"entouraient","word_nosc":"entouraient","lemma":"entourer","pos":"VER"} ,
		{"word":"entourais","word_nosc":"entourais","lemma":"entourer","pos":"VER"} ,
		{"word":"entourait","word_nosc":"entourait","lemma":"entourer","pos":"VER"} ,
		{"word":"entourant","word_nosc":"entourant","lemma":"entourer","pos":"VER"} ,
		{"word":"entoure","word_nosc":"entoure","lemma":"entourer","pos":"VER"} ,
		{"word":"entourent","word_nosc":"entourent","lemma":"entourer","pos":"VER"} ,
		{"word":"entourer","word_nosc":"entourer","lemma":"entourer","pos":"VER"} ,
		{"word":"entourera","word_nosc":"entourera","lemma":"entourer","pos":"VER"} ,
		{"word":"entoureraient","word_nosc":"entoureraient","lemma":"entourer","pos":"VER"} ,
		{"word":"entourerait","word_nosc":"entourerait","lemma":"entourer","pos":"VER"} ,
		{"word":"entoureront","word_nosc":"entoureront","lemma":"entourer","pos":"VER"} ,
		{"word":"entoures","word_nosc":"entoures","lemma":"entourer","pos":"VER"} ,
		{"word":"entourez","word_nosc":"entourez","lemma":"entourer","pos":"VER"} ,
		{"word":"entourions","word_nosc":"entourions","lemma":"entourer","pos":"VER"} ,
		{"word":"entourloupent","word_nosc":"entourloupent","lemma":"entourlouper","pos":"VER"} ,
		{"word":"entourlouper","word_nosc":"entourlouper","lemma":"entourlouper","pos":"VER"} ,
		{"word":"entourons","word_nosc":"entourons","lemma":"entourer","pos":"VER"} ,
		{"word":"entourât","word_nosc":"entourat","lemma":"entourer","pos":"VER"} ,
		{"word":"entourèrent","word_nosc":"entourerent","lemma":"entourer","pos":"VER"} ,
		{"word":"entouré","word_nosc":"entoure","lemma":"entourer","pos":"VER"} ,
		{"word":"entourée","word_nosc":"entouree","lemma":"entourer","pos":"VER"} ,
		{"word":"entourées","word_nosc":"entourees","lemma":"entourer","pos":"VER"} ,
		{"word":"entourés","word_nosc":"entoures","lemma":"entourer","pos":"VER"} ,
		{"word":"entr'aimer","word_nosc":"entr'aimer","lemma":"entr'aimer","pos":"VER"} ,
		{"word":"entr'apercevoir","word_nosc":"entr'apercevoir","lemma":"entr'apercevoir","pos":"VER"} ,
		{"word":"entr'aperçu","word_nosc":"entr'apercu","lemma":"entr'apercevoir","pos":"VER"} ,
		{"word":"entr'aperçue","word_nosc":"entr'apercue","lemma":"entr'apercevoir","pos":"VER"} ,
		{"word":"entr'aperçues","word_nosc":"entr'apercues","lemma":"entr'apercevoir","pos":"VER"} ,
		{"word":"entr'aperçus","word_nosc":"entr'apercus","lemma":"entr'apercevoir","pos":"VER"} ,
		{"word":"entr'appellent","word_nosc":"entr'appellent","lemma":"entr'appeler","pos":"VER"} ,
		{"word":"entr'égorgent","word_nosc":"entr'egorgent","lemma":"entr'égorger","pos":"VER"} ,
		{"word":"entr'égorger","word_nosc":"entr'egorger","lemma":"entr'égorger","pos":"VER"} ,
		{"word":"entr'égorgèrent","word_nosc":"entr'egorgerent","lemma":"entr'égorger","pos":"VER"} ,
		{"word":"entra","word_nosc":"entra","lemma":"entrer","pos":"VER"} ,
		{"word":"entrai","word_nosc":"entrai","lemma":"entrer","pos":"VER"} ,
		{"word":"entraidait","word_nosc":"entraidait","lemma":"entraider","pos":"VER"} ,
		{"word":"entraidant","word_nosc":"entraidant","lemma":"entraider","pos":"VER"} ,
		{"word":"entraide","word_nosc":"entraide","lemma":"entraider","pos":"VER"} ,
		{"word":"entraident","word_nosc":"entraident","lemma":"entraider","pos":"VER"} ,
		{"word":"entraider","word_nosc":"entraider","lemma":"entraider","pos":"VER"} ,
		{"word":"entraidez","word_nosc":"entraidez","lemma":"entraider","pos":"VER"} ,
		{"word":"entraidiez","word_nosc":"entraidiez","lemma":"entraider","pos":"VER"} ,
		{"word":"entraidons","word_nosc":"entraidons","lemma":"entraider","pos":"VER"} ,
		{"word":"entraidés","word_nosc":"entraides","lemma":"entraider","pos":"VER"} ,
		{"word":"entraient","word_nosc":"entraient","lemma":"entrer","pos":"VER"} ,
		{"word":"entrais","word_nosc":"entrais","lemma":"entrer","pos":"VER"} ,
		{"word":"entrait","word_nosc":"entrait","lemma":"entrer","pos":"VER"} ,
		{"word":"entrant","word_nosc":"entrant","lemma":"entrer","pos":"VER"} ,
		{"word":"entrapercevoir","word_nosc":"entrapercevoir","lemma":"entrapercevoir","pos":"VER"} ,
		{"word":"entraperçu","word_nosc":"entrapercu","lemma":"entrapercevoir","pos":"VER"} ,
		{"word":"entraperçues","word_nosc":"entrapercues","lemma":"entrapercevoir","pos":"VER"} ,
		{"word":"entraperçus","word_nosc":"entrapercus","lemma":"entrapercevoir","pos":"VER"} ,
		{"word":"entrassent","word_nosc":"entrassent","lemma":"entrer","pos":"VER"} ,
		{"word":"entrava","word_nosc":"entrava","lemma":"entraver","pos":"VER"} ,
		{"word":"entravai","word_nosc":"entravai","lemma":"entraver","pos":"VER"} ,
		{"word":"entravaient","word_nosc":"entravaient","lemma":"entraver","pos":"VER"} ,
		{"word":"entravais","word_nosc":"entravais","lemma":"entraver","pos":"VER"} ,
		{"word":"entravait","word_nosc":"entravait","lemma":"entraver","pos":"VER"} ,
		{"word":"entravant","word_nosc":"entravant","lemma":"entraver","pos":"VER"} ,
		{"word":"entrave","word_nosc":"entrave","lemma":"entraver","pos":"VER"} ,
		{"word":"entravent","word_nosc":"entravent","lemma":"entraver","pos":"VER"} ,
		{"word":"entraver","word_nosc":"entraver","lemma":"entraver","pos":"VER"} ,
		{"word":"entravera","word_nosc":"entravera","lemma":"entraver","pos":"VER"} ,
		{"word":"entraverais","word_nosc":"entraverais","lemma":"entraver","pos":"VER"} ,
		{"word":"entraverait","word_nosc":"entraverait","lemma":"entraver","pos":"VER"} ,
		{"word":"entraveront","word_nosc":"entraveront","lemma":"entraver","pos":"VER"} ,
		{"word":"entraves","word_nosc":"entraves","lemma":"entraver","pos":"VER"} ,
		{"word":"entravez","word_nosc":"entravez","lemma":"entraver","pos":"VER"} ,
		{"word":"entravèrent","word_nosc":"entraverent","lemma":"entraver","pos":"VER"} ,
		{"word":"entravé","word_nosc":"entrave","lemma":"entraver","pos":"VER"} ,
		{"word":"entravée","word_nosc":"entravee","lemma":"entraver","pos":"VER"} ,
		{"word":"entravées","word_nosc":"entravees","lemma":"entraver","pos":"VER"} ,
		{"word":"entravés","word_nosc":"entraves","lemma":"entraver","pos":"VER"} ,
		{"word":"entraîna","word_nosc":"entraina","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînai","word_nosc":"entrainai","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînaient","word_nosc":"entrainaient","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînais","word_nosc":"entrainais","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînait","word_nosc":"entrainait","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînant","word_nosc":"entrainant","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraîne","word_nosc":"entraine","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînent","word_nosc":"entrainent","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraîner","word_nosc":"entrainer","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînera","word_nosc":"entrainera","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînerai","word_nosc":"entrainerai","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraîneraient","word_nosc":"entraineraient","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînerais","word_nosc":"entrainerais","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînerait","word_nosc":"entrainerait","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraîneras","word_nosc":"entraineras","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînerez","word_nosc":"entrainerez","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraîneriez","word_nosc":"entraineriez","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraîneront","word_nosc":"entraineront","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînes","word_nosc":"entraines","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînez","word_nosc":"entrainez","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraîniez","word_nosc":"entrainiez","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînons","word_nosc":"entrainons","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînât","word_nosc":"entrainat","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînèrent","word_nosc":"entrainerent","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraîné","word_nosc":"entraine","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînée","word_nosc":"entrainee","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînées","word_nosc":"entrainees","lemma":"entraîner","pos":"VER"} ,
		{"word":"entraînés","word_nosc":"entraines","lemma":"entraîner","pos":"VER"} ,
		{"word":"entre","word_nosc":"entre","lemma":"entrer","pos":"VER"} ,
		{"word":"entre-déchiraient","word_nosc":"entre-dechiraient","lemma":"entre-déchirer","pos":"VER"} ,
		{"word":"entre-déchirait","word_nosc":"entre-dechirait","lemma":"entre-déchirer","pos":"VER"} ,
		{"word":"entre-déchire","word_nosc":"entre-dechire","lemma":"entre-déchirer","pos":"VER"} ,
		{"word":"entre-déchirent","word_nosc":"entre-dechirent","lemma":"entre-déchirer","pos":"VER"} ,
		{"word":"entre-déchirer","word_nosc":"entre-dechirer","lemma":"entre-déchirer","pos":"VER"} ,
		{"word":"entre-déchirèrent","word_nosc":"entre-dechirerent","lemma":"entre-déchirer","pos":"VER"} ,
		{"word":"entre-dévorant","word_nosc":"entre-devorant","lemma":"entre-dévorer","pos":"VER"} ,
		{"word":"entre-dévorent","word_nosc":"entre-devorent","lemma":"entre-dévorer","pos":"VER"} ,
		{"word":"entre-dévorer","word_nosc":"entre-devorer","lemma":"entre-dévorer","pos":"VER"} ,
		{"word":"entre-dévorèrent","word_nosc":"entre-devorerent","lemma":"entre-dévorer","pos":"VER"} ,
		{"word":"entre-regardait","word_nosc":"entre-regardait","lemma":"entre-regarder","pos":"VER"} ,
		{"word":"entre-regardent","word_nosc":"entre-regardent","lemma":"entre-regarder","pos":"VER"} ,
		{"word":"entre-regardèrent","word_nosc":"entre-regarderent","lemma":"entre-regarder","pos":"VER"} ,
		{"word":"entre-regardés","word_nosc":"entre-regardes","lemma":"entre-regarder","pos":"VER"} ,
		{"word":"entre-tuaient","word_nosc":"entre-tuaient","lemma":"entre-tuer","pos":"VER"} ,
		{"word":"entre-tuait","word_nosc":"entre-tuait","lemma":"entre-tuer","pos":"VER"} ,
		{"word":"entre-tue","word_nosc":"entre-tue","lemma":"entre-tuer","pos":"VER"} ,
		{"word":"entre-tuent","word_nosc":"entre-tuent","lemma":"entre-tuer","pos":"VER"} ,
		{"word":"entre-tuer","word_nosc":"entre-tuer","lemma":"entre-tuer","pos":"VER"} ,
		{"word":"entre-tueront","word_nosc":"entre-tueront","lemma":"entre-tuer","pos":"VER"} ,
		{"word":"entre-tuez","word_nosc":"entre-tuez","lemma":"entre-tuer","pos":"VER"} ,
		{"word":"entre-tuions","word_nosc":"entre-tuions","lemma":"entre-tuer","pos":"VER"} ,
		{"word":"entre-tués","word_nosc":"entre-tues","lemma":"entre-tuer","pos":"VER"} ,
		{"word":"entrebattre","word_nosc":"entrebattre","lemma":"entrebattre","pos":"VER"} ,
		{"word":"entrebâilla","word_nosc":"entrebailla","lemma":"entrebâiller","pos":"VER"} ,
		{"word":"entrebâillaient","word_nosc":"entrebaillaient","lemma":"entrebâiller","pos":"VER"} ,
		{"word":"entrebâillais","word_nosc":"entrebaillais","lemma":"entrebâiller","pos":"VER"} ,
		{"word":"entrebâillait","word_nosc":"entrebaillait","lemma":"entrebâiller","pos":"VER"} ,
		{"word":"entrebâillant","word_nosc":"entrebaillant","lemma":"entrebâiller","pos":"VER"} ,
		{"word":"entrebâille","word_nosc":"entrebaille","lemma":"entrebâiller","pos":"VER"} ,
		{"word":"entrebâillent","word_nosc":"entrebaillent","lemma":"entrebâiller","pos":"VER"} ,
		{"word":"entrebâiller","word_nosc":"entrebailler","lemma":"entrebâiller","pos":"VER"} ,
		{"word":"entrebâillerait","word_nosc":"entrebaillerait","lemma":"entrebâiller","pos":"VER"} ,
		{"word":"entrebâillèrent","word_nosc":"entrebaillerent","lemma":"entrebâiller","pos":"VER"} ,
		{"word":"entrebâillé","word_nosc":"entrebaille","lemma":"entrebâiller","pos":"VER"} ,
		{"word":"entrebâillée","word_nosc":"entrebaillee","lemma":"entrebâiller","pos":"VER"} ,
		{"word":"entrebâillées","word_nosc":"entrebaillees","lemma":"entrebâiller","pos":"VER"} ,
		{"word":"entrebâillés","word_nosc":"entrebailles","lemma":"entrebâiller","pos":"VER"} ,
		{"word":"entrechoquaient","word_nosc":"entrechoquaient","lemma":"entrechoquer","pos":"VER"} ,
		{"word":"entrechoquait","word_nosc":"entrechoquait","lemma":"entrechoquer","pos":"VER"} ,
		{"word":"entrechoquant","word_nosc":"entrechoquant","lemma":"entrechoquer","pos":"VER"} ,
		{"word":"entrechoque","word_nosc":"entrechoque","lemma":"entrechoquer","pos":"VER"} ,
		{"word":"entrechoquent","word_nosc":"entrechoquent","lemma":"entrechoquer","pos":"VER"} ,
		{"word":"entrechoquer","word_nosc":"entrechoquer","lemma":"entrechoquer","pos":"VER"} ,
		{"word":"entrechoqueront","word_nosc":"entrechoqueront","lemma":"entrechoquer","pos":"VER"} ,
		{"word":"entrechoquions","word_nosc":"entrechoquions","lemma":"entrechoquer","pos":"VER"} ,
		{"word":"entrechoquèrent","word_nosc":"entrechoquerent","lemma":"entrechoquer","pos":"VER"} ,
		{"word":"entrechoqué","word_nosc":"entrechoque","lemma":"entrechoquer","pos":"VER"} ,
		{"word":"entrechoquées","word_nosc":"entrechoquees","lemma":"entrechoquer","pos":"VER"} ,
		{"word":"entrechoqués","word_nosc":"entrechoques","lemma":"entrechoquer","pos":"VER"} ,
		{"word":"entreclos","word_nosc":"entreclos","lemma":"entreclore","pos":"VER"} ,
		{"word":"entrecoupaient","word_nosc":"entrecoupaient","lemma":"entrecouper","pos":"VER"} ,
		{"word":"entrecoupais","word_nosc":"entrecoupais","lemma":"entrecouper","pos":"VER"} ,
		{"word":"entrecoupait","word_nosc":"entrecoupait","lemma":"entrecouper","pos":"VER"} ,
		{"word":"entrecoupant","word_nosc":"entrecoupant","lemma":"entrecouper","pos":"VER"} ,
		{"word":"entrecoupe","word_nosc":"entrecoupe","lemma":"entrecouper","pos":"VER"} ,
		{"word":"entrecoupent","word_nosc":"entrecoupent","lemma":"entrecouper","pos":"VER"} ,
		{"word":"entrecouper","word_nosc":"entrecouper","lemma":"entrecouper","pos":"VER"} ,
		{"word":"entrecoupé","word_nosc":"entrecoupe","lemma":"entrecouper","pos":"VER"} ,
		{"word":"entrecoupée","word_nosc":"entrecoupee","lemma":"entrecouper","pos":"VER"} ,
		{"word":"entrecoupées","word_nosc":"entrecoupees","lemma":"entrecouper","pos":"VER"} ,
		{"word":"entrecoupés","word_nosc":"entrecoupes","lemma":"entrecouper","pos":"VER"} ,
		{"word":"entrecroisaient","word_nosc":"entrecroisaient","lemma":"entrecroiser","pos":"VER"} ,
		{"word":"entrecroisait","word_nosc":"entrecroisait","lemma":"entrecroiser","pos":"VER"} ,
		{"word":"entrecroisant","word_nosc":"entrecroisant","lemma":"entrecroiser","pos":"VER"} ,
		{"word":"entrecroise","word_nosc":"entrecroise","lemma":"entrecroiser","pos":"VER"} ,
		{"word":"entrecroisent","word_nosc":"entrecroisent","lemma":"entrecroiser","pos":"VER"} ,
		{"word":"entrecroiser","word_nosc":"entrecroiser","lemma":"entrecroiser","pos":"VER"} ,
		{"word":"entrecroisèrent","word_nosc":"entrecroiserent","lemma":"entrecroiser","pos":"VER"} ,
		{"word":"entrecroisé","word_nosc":"entrecroise","lemma":"entrecroiser","pos":"VER"} ,
		{"word":"entrecroisées","word_nosc":"entrecroisees","lemma":"entrecroiser","pos":"VER"} ,
		{"word":"entrecroisés","word_nosc":"entrecroises","lemma":"entrecroiser","pos":"VER"} ,
		{"word":"entredéchire","word_nosc":"entredechire","lemma":"entredéchirer","pos":"VER"} ,
		{"word":"entredévorait","word_nosc":"entredevorait","lemma":"entredévorer","pos":"VER"} ,
		{"word":"entredévorer","word_nosc":"entredevorer","lemma":"entredévorer","pos":"VER"} ,
		{"word":"entreferma","word_nosc":"entreferma","lemma":"entrefermer","pos":"VER"} ,
		{"word":"entrefermée","word_nosc":"entrefermee","lemma":"entrefermer","pos":"VER"} ,
		{"word":"entrefermées","word_nosc":"entrefermees","lemma":"entrefermer","pos":"VER"} ,
		{"word":"entrelace","word_nosc":"entrelace","lemma":"entrelacer","pos":"VER"} ,
		{"word":"entrelacent","word_nosc":"entrelacent","lemma":"entrelacer","pos":"VER"} ,
		{"word":"entrelacer","word_nosc":"entrelacer","lemma":"entrelacer","pos":"VER"} ,
		{"word":"entrelacèrent","word_nosc":"entrelacerent","lemma":"entrelacer","pos":"VER"} ,
		{"word":"entrelacé","word_nosc":"entrelace","lemma":"entrelacer","pos":"VER"} ,
		{"word":"entrelacée","word_nosc":"entrelacee","lemma":"entrelacer","pos":"VER"} ,
		{"word":"entrelacées","word_nosc":"entrelacees","lemma":"entrelacer","pos":"VER"} ,
		{"word":"entrelacés","word_nosc":"entrelaces","lemma":"entrelacer","pos":"VER"} ,
		{"word":"entrelarde","word_nosc":"entrelarde","lemma":"entrelarder","pos":"VER"} ,
		{"word":"entrelarder","word_nosc":"entrelarder","lemma":"entrelarder","pos":"VER"} ,
		{"word":"entrelardée","word_nosc":"entrelardee","lemma":"entrelarder","pos":"VER"} ,
		{"word":"entrelardées","word_nosc":"entrelardees","lemma":"entrelarder","pos":"VER"} ,
		{"word":"entrelardés","word_nosc":"entrelardes","lemma":"entrelarder","pos":"VER"} ,
		{"word":"entrelaçaient","word_nosc":"entrelacaient","lemma":"entrelacer","pos":"VER"} ,
		{"word":"entrelaçait","word_nosc":"entrelacait","lemma":"entrelacer","pos":"VER"} ,
		{"word":"entremet","word_nosc":"entremet","lemma":"entremettre","pos":"VER"} ,
		{"word":"entremettait","word_nosc":"entremettait","lemma":"entremettre","pos":"VER"} ,
		{"word":"entremettre","word_nosc":"entremettre","lemma":"entremettre","pos":"VER"} ,
		{"word":"entremis","word_nosc":"entremis","lemma":"entremettre","pos":"VER"} ,
		{"word":"entremise","word_nosc":"entremise","lemma":"entremettre","pos":"VER"} ,
		{"word":"entremit","word_nosc":"entremit","lemma":"entremettre","pos":"VER"} ,
		{"word":"entremêlaient","word_nosc":"entremelaient","lemma":"entremêler","pos":"VER"} ,
		{"word":"entremêlait","word_nosc":"entremelait","lemma":"entremêler","pos":"VER"} ,
		{"word":"entremêlant","word_nosc":"entremelant","lemma":"entremêler","pos":"VER"} ,
		{"word":"entremêle","word_nosc":"entremele","lemma":"entremêler","pos":"VER"} ,
		{"word":"entremêlent","word_nosc":"entremelent","lemma":"entremêler","pos":"VER"} ,
		{"word":"entremêlé","word_nosc":"entremele","lemma":"entremêler","pos":"VER"} ,
		{"word":"entremêlées","word_nosc":"entremelees","lemma":"entremêler","pos":"VER"} ,
		{"word":"entremêlés","word_nosc":"entremeles","lemma":"entremêler","pos":"VER"} ,
		{"word":"entrent","word_nosc":"entrent","lemma":"entrer","pos":"VER"} ,
		{"word":"entreposais","word_nosc":"entreposais","lemma":"entreposer","pos":"VER"} ,
		{"word":"entreposait","word_nosc":"entreposait","lemma":"entreposer","pos":"VER"} ,
		{"word":"entrepose","word_nosc":"entrepose","lemma":"entreposer","pos":"VER"} ,
		{"word":"entreposent","word_nosc":"entreposent","lemma":"entreposer","pos":"VER"} ,
		{"word":"entreposer","word_nosc":"entreposer","lemma":"entreposer","pos":"VER"} ,
		{"word":"entreposerait","word_nosc":"entreposerait","lemma":"entreposer","pos":"VER"} ,
		{"word":"entreposez","word_nosc":"entreposez","lemma":"entreposer","pos":"VER"} ,
		{"word":"entreposions","word_nosc":"entreposions","lemma":"entreposer","pos":"VER"} ,
		{"word":"entreposé","word_nosc":"entrepose","lemma":"entreposer","pos":"VER"} ,
		{"word":"entreposée","word_nosc":"entreposee","lemma":"entreposer","pos":"VER"} ,
		{"word":"entreposées","word_nosc":"entreposees","lemma":"entreposer","pos":"VER"} ,
		{"word":"entreposés","word_nosc":"entreposes","lemma":"entreposer","pos":"VER"} ,
		{"word":"entreprenaient","word_nosc":"entreprenaient","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprenais","word_nosc":"entreprenais","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprenait","word_nosc":"entreprenait","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprenant","word_nosc":"entreprenant","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprend","word_nosc":"entreprend","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprendrai","word_nosc":"entreprendrai","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprendraient","word_nosc":"entreprendraient","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprendrais","word_nosc":"entreprendrais","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprendrait","word_nosc":"entreprendrait","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprendras","word_nosc":"entreprendras","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprendre","word_nosc":"entreprendre","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprendrez","word_nosc":"entreprendrez","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprendrons","word_nosc":"entreprendrons","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprends","word_nosc":"entreprends","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprenez","word_nosc":"entreprenez","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entrepreniez","word_nosc":"entrepreniez","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprenions","word_nosc":"entreprenions","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprenne","word_nosc":"entreprenne","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprennent","word_nosc":"entreprennent","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprenons","word_nosc":"entreprenons","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprirent","word_nosc":"entreprirent","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entrepris","word_nosc":"entrepris","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprise","word_nosc":"entreprise","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprises","word_nosc":"entreprises","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprit","word_nosc":"entreprit","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprîmes","word_nosc":"entreprimes","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entreprît","word_nosc":"entreprit","lemma":"entreprendre","pos":"VER"} ,
		{"word":"entrer","word_nosc":"entrer","lemma":"entrer","pos":"VER"} ,
		{"word":"entrera","word_nosc":"entrera","lemma":"entrer","pos":"VER"} ,
		{"word":"entrerai","word_nosc":"entrerai","lemma":"entrer","pos":"VER"} ,
		{"word":"entreraient","word_nosc":"entreraient","lemma":"entrer","pos":"VER"} ,
		{"word":"entrerais","word_nosc":"entrerais","lemma":"entrer","pos":"VER"} ,
		{"word":"entrerait","word_nosc":"entrerait","lemma":"entrer","pos":"VER"} ,
		{"word":"entreras","word_nosc":"entreras","lemma":"entrer","pos":"VER"} ,
		{"word":"entreregardèrent","word_nosc":"entreregarderent","lemma":"entreregarder","pos":"VER"} ,
		{"word":"entrerez","word_nosc":"entrerez","lemma":"entrer","pos":"VER"} ,
		{"word":"entrerions","word_nosc":"entrerions","lemma":"entrer","pos":"VER"} ,
		{"word":"entrerons","word_nosc":"entrerons","lemma":"entrer","pos":"VER"} ,
		{"word":"entreront","word_nosc":"entreront","lemma":"entrer","pos":"VER"} ,
		{"word":"entres","word_nosc":"entres","lemma":"entrer","pos":"VER"} ,
		{"word":"entretenaient","word_nosc":"entretenaient","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretenais","word_nosc":"entretenais","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretenait","word_nosc":"entretenait","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretenant","word_nosc":"entretenant","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretenez","word_nosc":"entretenez","lemma":"entretenir","pos":"VER"} ,
		{"word":"entreteniez","word_nosc":"entreteniez","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretenions","word_nosc":"entretenions","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretenir","word_nosc":"entretenir","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretenons","word_nosc":"entretenons","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretenu","word_nosc":"entretenu","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretenue","word_nosc":"entretenue","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretenues","word_nosc":"entretenues","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretenus","word_nosc":"entretenus","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretiendra","word_nosc":"entretiendra","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretiendrai","word_nosc":"entretiendrai","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretiendraient","word_nosc":"entretiendraient","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretiendrait","word_nosc":"entretiendrait","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretiendras","word_nosc":"entretiendras","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretiendrez","word_nosc":"entretiendrez","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretiendrons","word_nosc":"entretiendrons","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretienne","word_nosc":"entretienne","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretiennent","word_nosc":"entretiennent","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretiennes","word_nosc":"entretiennes","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretiens","word_nosc":"entretiens","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretient","word_nosc":"entretient","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretinrent","word_nosc":"entretinrent","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretins","word_nosc":"entretins","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretint","word_nosc":"entretint","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretissait","word_nosc":"entretissait","lemma":"entretisser","pos":"VER"} ,
		{"word":"entretissé","word_nosc":"entretisse","lemma":"entretisser","pos":"VER"} ,
		{"word":"entretissées","word_nosc":"entretissees","lemma":"entretisser","pos":"VER"} ,
		{"word":"entretoisaient","word_nosc":"entretoisaient","lemma":"entretoiser","pos":"VER"} ,
		{"word":"entretuaient","word_nosc":"entretuaient","lemma":"entretuer","pos":"VER"} ,
		{"word":"entretuant","word_nosc":"entretuant","lemma":"entretuer","pos":"VER"} ,
		{"word":"entretue","word_nosc":"entretue","lemma":"entretuer","pos":"VER"} ,
		{"word":"entretuent","word_nosc":"entretuent","lemma":"entretuer","pos":"VER"} ,
		{"word":"entretuer","word_nosc":"entretuer","lemma":"entretuer","pos":"VER"} ,
		{"word":"entretueraient","word_nosc":"entretueraient","lemma":"entretuer","pos":"VER"} ,
		{"word":"entretuerait","word_nosc":"entretuerait","lemma":"entretuer","pos":"VER"} ,
		{"word":"entretueront","word_nosc":"entretueront","lemma":"entretuer","pos":"VER"} ,
		{"word":"entretuez","word_nosc":"entretuez","lemma":"entretuer","pos":"VER"} ,
		{"word":"entretuées","word_nosc":"entretuees","lemma":"entretuer","pos":"VER"} ,
		{"word":"entretués","word_nosc":"entretues","lemma":"entretuer","pos":"VER"} ,
		{"word":"entretînmes","word_nosc":"entretinmes","lemma":"entretenir","pos":"VER"} ,
		{"word":"entretînt","word_nosc":"entretint","lemma":"entretenir","pos":"VER"} ,
		{"word":"entreverrai","word_nosc":"entreverrai","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevirent","word_nosc":"entrevirent","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevis","word_nosc":"entrevis","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevit","word_nosc":"entrevit","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevoie","word_nosc":"entrevoie","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevoient","word_nosc":"entrevoient","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevoir","word_nosc":"entrevoir","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevois","word_nosc":"entrevois","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevoit","word_nosc":"entrevoit","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevoyaient","word_nosc":"entrevoyaient","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevoyais","word_nosc":"entrevoyais","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevoyait","word_nosc":"entrevoyait","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevoyant","word_nosc":"entrevoyant","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevoyons","word_nosc":"entrevoyons","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevu","word_nosc":"entrevu","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevue","word_nosc":"entrevue","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevues","word_nosc":"entrevues","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevus","word_nosc":"entrevus","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrevît","word_nosc":"entrevit","lemma":"entrevoir","pos":"VER"} ,
		{"word":"entrez","word_nosc":"entrez","lemma":"entrer","pos":"VER"} ,
		{"word":"entriez","word_nosc":"entriez","lemma":"entrer","pos":"VER"} ,
		{"word":"entrions","word_nosc":"entrions","lemma":"entrer","pos":"VER"} ,
		{"word":"entrons","word_nosc":"entrons","lemma":"entrer","pos":"VER"} ,
		{"word":"entrouvert","word_nosc":"entrouvert","lemma":"entrouvrir","pos":"VER"} ,
		{"word":"entrouverte","word_nosc":"entrouverte","lemma":"entrouvrir","pos":"VER"} ,
		{"word":"entrouvertes","word_nosc":"entrouvertes","lemma":"entrouvrir","pos":"VER"} ,
		{"word":"entrouverts","word_nosc":"entrouverts","lemma":"entrouvrir","pos":"VER"} ,
		{"word":"entrouvraient","word_nosc":"entrouvraient","lemma":"entrouvrir","pos":"VER"} ,
		{"word":"entrouvrait","word_nosc":"entrouvrait","lemma":"entrouvrir","pos":"VER"} ,
		{"word":"entrouvrant","word_nosc":"entrouvrant","lemma":"entrouvrir","pos":"VER"} ,
		{"word":"entrouvre","word_nosc":"entrouvre","lemma":"entrouvrir","pos":"VER"} ,
		{"word":"entrouvrent","word_nosc":"entrouvrent","lemma":"entrouvrir","pos":"VER"} ,
		{"word":"entrouvrir","word_nosc":"entrouvrir","lemma":"entrouvrir","pos":"VER"} ,
		{"word":"entrouvrirait","word_nosc":"entrouvrirait","lemma":"entrouvrir","pos":"VER"} ,
		{"word":"entrouvrirent","word_nosc":"entrouvrirent","lemma":"entrouvrir","pos":"VER"} ,
		{"word":"entrouvris","word_nosc":"entrouvris","lemma":"entrouvrir","pos":"VER"} ,
		{"word":"entrouvrit","word_nosc":"entrouvrit","lemma":"entrouvrir","pos":"VER"} ,
		{"word":"entrouvrît","word_nosc":"entrouvrit","lemma":"entrouvrir","pos":"VER"} ,
		{"word":"entrâmes","word_nosc":"entrames","lemma":"entrer","pos":"VER"} ,
		{"word":"entrât","word_nosc":"entrat","lemma":"entrer","pos":"VER"} ,
		{"word":"entrèrent","word_nosc":"entrerent","lemma":"entrer","pos":"VER"} ,
		{"word":"entré","word_nosc":"entre","lemma":"entrer","pos":"VER"} ,
		{"word":"entrée","word_nosc":"entree","lemma":"entrer","pos":"VER"} ,
		{"word":"entrées","word_nosc":"entrees","lemma":"entrer","pos":"VER"} ,
		{"word":"entrés","word_nosc":"entres","lemma":"entrer","pos":"VER"} ,
		{"word":"entubait","word_nosc":"entubait","lemma":"entuber","pos":"VER"} ,
		{"word":"entube","word_nosc":"entube","lemma":"entuber","pos":"VER"} ,
		{"word":"entubent","word_nosc":"entubent","lemma":"entuber","pos":"VER"} ,
		{"word":"entuber","word_nosc":"entuber","lemma":"entuber","pos":"VER"} ,
		{"word":"entuberais","word_nosc":"entuberais","lemma":"entuber","pos":"VER"} ,
		{"word":"entubes","word_nosc":"entubes","lemma":"entuber","pos":"VER"} ,
		{"word":"entubez","word_nosc":"entubez","lemma":"entuber","pos":"VER"} ,
		{"word":"entubé","word_nosc":"entube","lemma":"entuber","pos":"VER"} ,
		{"word":"entubée","word_nosc":"entubee","lemma":"entuber","pos":"VER"} ,
		{"word":"entubés","word_nosc":"entubes","lemma":"entuber","pos":"VER"} ,
		{"word":"enturbanner","word_nosc":"enturbanner","lemma":"enturbanner","pos":"VER"} ,
		{"word":"enturbanné","word_nosc":"enturbanne","lemma":"enturbanner","pos":"VER"} ,
		{"word":"enturbannée","word_nosc":"enturbannee","lemma":"enturbanner","pos":"VER"} ,
		{"word":"enturbannés","word_nosc":"enturbannes","lemma":"enturbanner","pos":"VER"} ,
		{"word":"enténèbre","word_nosc":"entenebre","lemma":"enténébrer","pos":"VER"} ,
		{"word":"enténèbrent","word_nosc":"entenebrent","lemma":"enténébrer","pos":"VER"} ,
		{"word":"enténébra","word_nosc":"entenebra","lemma":"enténébrer","pos":"VER"} ,
		{"word":"enténébrait","word_nosc":"entenebrait","lemma":"enténébrer","pos":"VER"} ,
		{"word":"enténébrant","word_nosc":"entenebrant","lemma":"enténébrer","pos":"VER"} ,
		{"word":"enténébrer","word_nosc":"entenebrer","lemma":"enténébrer","pos":"VER"} ,
		{"word":"enténébré","word_nosc":"entenebre","lemma":"enténébrer","pos":"VER"} ,
		{"word":"enténébrée","word_nosc":"entenebree","lemma":"enténébrer","pos":"VER"} ,
		{"word":"enténébrées","word_nosc":"entenebrees","lemma":"enténébrer","pos":"VER"} ,
		{"word":"enténébrés","word_nosc":"entenebres","lemma":"enténébrer","pos":"VER"} ,
		{"word":"entérinais","word_nosc":"enterinais","lemma":"entériner","pos":"VER"} ,
		{"word":"entérinait","word_nosc":"enterinait","lemma":"entériner","pos":"VER"} ,
		{"word":"entérinant","word_nosc":"enterinant","lemma":"entériner","pos":"VER"} ,
		{"word":"entérine","word_nosc":"enterine","lemma":"entériner","pos":"VER"} ,
		{"word":"entériner","word_nosc":"enteriner","lemma":"entériner","pos":"VER"} ,
		{"word":"entériné","word_nosc":"enterine","lemma":"entériner","pos":"VER"} ,
		{"word":"entérinée","word_nosc":"enterinee","lemma":"entériner","pos":"VER"} ,
		{"word":"entérinées","word_nosc":"enterinees","lemma":"entériner","pos":"VER"} ,
		{"word":"entêta","word_nosc":"enteta","lemma":"entêter","pos":"VER"} ,
		{"word":"entêtai","word_nosc":"entetai","lemma":"entêter","pos":"VER"} ,
		{"word":"entêtaient","word_nosc":"entetaient","lemma":"entêter","pos":"VER"} ,
		{"word":"entêtais","word_nosc":"entetais","lemma":"entêter","pos":"VER"} ,
		{"word":"entêtait","word_nosc":"entetait","lemma":"entêter","pos":"VER"} ,
		{"word":"entêtant","word_nosc":"entetant","lemma":"entêter","pos":"VER"} ,
		{"word":"entête","word_nosc":"entete","lemma":"entêter","pos":"VER"} ,
		{"word":"entêtent","word_nosc":"entetent","lemma":"entêter","pos":"VER"} ,
		{"word":"entêter","word_nosc":"enteter","lemma":"entêter","pos":"VER"} ,
		{"word":"entêteraient","word_nosc":"enteteraient","lemma":"entêter","pos":"VER"} ,
		{"word":"entêterais","word_nosc":"enteterais","lemma":"entêter","pos":"VER"} ,
		{"word":"entêtes","word_nosc":"entetes","lemma":"entêter","pos":"VER"} ,
		{"word":"entêtez","word_nosc":"entetez","lemma":"entêter","pos":"VER"} ,
		{"word":"entêtiez","word_nosc":"entetiez","lemma":"entêter","pos":"VER"} ,
		{"word":"entêtons","word_nosc":"entetons","lemma":"entêter","pos":"VER"} ,
		{"word":"entêtât","word_nosc":"entetat","lemma":"entêter","pos":"VER"} ,
		{"word":"entêté","word_nosc":"entete","lemma":"entêter","pos":"VER"} ,
		{"word":"entêtée","word_nosc":"entetee","lemma":"entêter","pos":"VER"} ,
		{"word":"entêtés","word_nosc":"entetes","lemma":"entêter","pos":"VER"} ,
		{"word":"entôler","word_nosc":"entoler","lemma":"entôler","pos":"VER"} ,
		{"word":"entôlé","word_nosc":"entole","lemma":"entôler","pos":"VER"} ,
		{"word":"entôlée","word_nosc":"entolee","lemma":"entôler","pos":"VER"} ,
		{"word":"envahi","word_nosc":"envahi","lemma":"envahir","pos":"VER"} ,
		{"word":"envahie","word_nosc":"envahie","lemma":"envahir","pos":"VER"} ,
		{"word":"envahies","word_nosc":"envahies","lemma":"envahir","pos":"VER"} ,
		{"word":"envahir","word_nosc":"envahir","lemma":"envahir","pos":"VER"} ,
		{"word":"envahira","word_nosc":"envahira","lemma":"envahir","pos":"VER"} ,
		{"word":"envahirai","word_nosc":"envahirai","lemma":"envahir","pos":"VER"} ,
		{"word":"envahiraient","word_nosc":"envahiraient","lemma":"envahir","pos":"VER"} ,
		{"word":"envahirait","word_nosc":"envahirait","lemma":"envahir","pos":"VER"} ,
		{"word":"envahirent","word_nosc":"envahirent","lemma":"envahir","pos":"VER"} ,
		{"word":"envahirez","word_nosc":"envahirez","lemma":"envahir","pos":"VER"} ,
		{"word":"envahirons","word_nosc":"envahirons","lemma":"envahir","pos":"VER"} ,
		{"word":"envahiront","word_nosc":"envahiront","lemma":"envahir","pos":"VER"} ,
		{"word":"envahis","word_nosc":"envahis","lemma":"envahir","pos":"VER"} ,
		{"word":"envahissaient","word_nosc":"envahissaient","lemma":"envahir","pos":"VER"} ,
		{"word":"envahissait","word_nosc":"envahissait","lemma":"envahir","pos":"VER"} ,
		{"word":"envahissant","word_nosc":"envahissant","lemma":"envahir","pos":"VER"} ,
		{"word":"envahisse","word_nosc":"envahisse","lemma":"envahir","pos":"VER"} ,
		{"word":"envahissent","word_nosc":"envahissent","lemma":"envahir","pos":"VER"} ,
		{"word":"envahissez","word_nosc":"envahissez","lemma":"envahir","pos":"VER"} ,
		{"word":"envahissiez","word_nosc":"envahissiez","lemma":"envahir","pos":"VER"} ,
		{"word":"envahit","word_nosc":"envahit","lemma":"envahir","pos":"VER"} ,
		{"word":"envahît","word_nosc":"envahit","lemma":"envahir","pos":"VER"} ,
		{"word":"envasait","word_nosc":"envasait","lemma":"envaser","pos":"VER"} ,
		{"word":"envaser","word_nosc":"envaser","lemma":"envaser","pos":"VER"} ,
		{"word":"envasé","word_nosc":"envase","lemma":"envaser","pos":"VER"} ,
		{"word":"envasée","word_nosc":"envasee","lemma":"envaser","pos":"VER"} ,
		{"word":"envasées","word_nosc":"envasees","lemma":"envaser","pos":"VER"} ,
		{"word":"enveloppa","word_nosc":"enveloppa","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppai","word_nosc":"enveloppai","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppaient","word_nosc":"enveloppaient","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppais","word_nosc":"enveloppais","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppait","word_nosc":"enveloppait","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppant","word_nosc":"enveloppant","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppe","word_nosc":"enveloppe","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppent","word_nosc":"enveloppent","lemma":"envelopper","pos":"VER"} ,
		{"word":"envelopper","word_nosc":"envelopper","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppera","word_nosc":"enveloppera","lemma":"envelopper","pos":"VER"} ,
		{"word":"envelopperaient","word_nosc":"envelopperaient","lemma":"envelopper","pos":"VER"} ,
		{"word":"envelopperait","word_nosc":"envelopperait","lemma":"envelopper","pos":"VER"} ,
		{"word":"envelopperez","word_nosc":"envelopperez","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppes","word_nosc":"enveloppes","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppez","word_nosc":"enveloppez","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppons","word_nosc":"enveloppons","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppât","word_nosc":"enveloppat","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppèrent","word_nosc":"envelopperent","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppé","word_nosc":"enveloppe","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppée","word_nosc":"enveloppee","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppées","word_nosc":"enveloppees","lemma":"envelopper","pos":"VER"} ,
		{"word":"enveloppés","word_nosc":"enveloppes","lemma":"envelopper","pos":"VER"} ,
		{"word":"envenima","word_nosc":"envenima","lemma":"envenimer","pos":"VER"} ,
		{"word":"envenimait","word_nosc":"envenimait","lemma":"envenimer","pos":"VER"} ,
		{"word":"envenimant","word_nosc":"envenimant","lemma":"envenimer","pos":"VER"} ,
		{"word":"envenime","word_nosc":"envenime","lemma":"envenimer","pos":"VER"} ,
		{"word":"enveniment","word_nosc":"enveniment","lemma":"envenimer","pos":"VER"} ,
		{"word":"envenimer","word_nosc":"envenimer","lemma":"envenimer","pos":"VER"} ,
		{"word":"envenimons","word_nosc":"envenimons","lemma":"envenimer","pos":"VER"} ,
		{"word":"envenimèrent","word_nosc":"envenimerent","lemma":"envenimer","pos":"VER"} ,
		{"word":"envenimé","word_nosc":"envenime","lemma":"envenimer","pos":"VER"} ,
		{"word":"envenimée","word_nosc":"envenimee","lemma":"envenimer","pos":"VER"} ,
		{"word":"envenimées","word_nosc":"envenimees","lemma":"envenimer","pos":"VER"} ,
		{"word":"envenimés","word_nosc":"envenimes","lemma":"envenimer","pos":"VER"} ,
		{"word":"enverguée","word_nosc":"enverguee","lemma":"enverguer","pos":"VER"} ,
		{"word":"enverra","word_nosc":"enverra","lemma":"envoyer","pos":"VER"} ,
		{"word":"enverrai","word_nosc":"enverrai","lemma":"envoyer","pos":"VER"} ,
		{"word":"enverraient","word_nosc":"enverraient","lemma":"envoyer","pos":"VER"} ,
		{"word":"enverrais","word_nosc":"enverrais","lemma":"envoyer","pos":"VER"} ,
		{"word":"enverrait","word_nosc":"enverrait","lemma":"envoyer","pos":"VER"} ,
		{"word":"enverras","word_nosc":"enverras","lemma":"envoyer","pos":"VER"} ,
		{"word":"enverrez","word_nosc":"enverrez","lemma":"envoyer","pos":"VER"} ,
		{"word":"enverriez","word_nosc":"enverriez","lemma":"envoyer","pos":"VER"} ,
		{"word":"enverrions","word_nosc":"enverrions","lemma":"envoyer","pos":"VER"} ,
		{"word":"enverrons","word_nosc":"enverrons","lemma":"envoyer","pos":"VER"} ,
		{"word":"enverront","word_nosc":"enverront","lemma":"envoyer","pos":"VER"} ,
		{"word":"envia","word_nosc":"envia","lemma":"envier","pos":"VER"} ,
		{"word":"enviai","word_nosc":"enviai","lemma":"envier","pos":"VER"} ,
		{"word":"enviaient","word_nosc":"enviaient","lemma":"envier","pos":"VER"} ,
		{"word":"enviais","word_nosc":"enviais","lemma":"envier","pos":"VER"} ,
		{"word":"enviait","word_nosc":"enviait","lemma":"envier","pos":"VER"} ,
		{"word":"enviandait","word_nosc":"enviandait","lemma":"enviander","pos":"VER"} ,
		{"word":"enviant","word_nosc":"enviant","lemma":"envier","pos":"VER"} ,
		{"word":"envie","word_nosc":"envie","lemma":"envier","pos":"VER"} ,
		{"word":"envient","word_nosc":"envient","lemma":"envier","pos":"VER"} ,
		{"word":"envier","word_nosc":"envier","lemma":"envier","pos":"VER"} ,
		{"word":"enviera","word_nosc":"enviera","lemma":"envier","pos":"VER"} ,
		{"word":"envieraient","word_nosc":"envieraient","lemma":"envier","pos":"VER"} ,
		{"word":"envierais","word_nosc":"envierais","lemma":"envier","pos":"VER"} ,
		{"word":"envierait","word_nosc":"envierait","lemma":"envier","pos":"VER"} ,
		{"word":"envieront","word_nosc":"envieront","lemma":"envier","pos":"VER"} ,
		{"word":"envies","word_nosc":"envies","lemma":"envier","pos":"VER"} ,
		{"word":"enviez","word_nosc":"enviez","lemma":"envier","pos":"VER"} ,
		{"word":"enviions","word_nosc":"enviions","lemma":"envier","pos":"VER"} ,
		{"word":"envions","word_nosc":"envions","lemma":"envier","pos":"VER"} ,
		{"word":"environnaient","word_nosc":"environnaient","lemma":"environner","pos":"VER"} ,
		{"word":"environnait","word_nosc":"environnait","lemma":"environner","pos":"VER"} ,
		{"word":"environnant","word_nosc":"environnant","lemma":"environner","pos":"VER"} ,
		{"word":"environne","word_nosc":"environne","lemma":"environner","pos":"VER"} ,
		{"word":"environnent","word_nosc":"environnent","lemma":"environner","pos":"VER"} ,
		{"word":"environner","word_nosc":"environner","lemma":"environner","pos":"VER"} ,
		{"word":"environné","word_nosc":"environne","lemma":"environner","pos":"VER"} ,
		{"word":"environnée","word_nosc":"environnee","lemma":"environner","pos":"VER"} ,
		{"word":"environnées","word_nosc":"environnees","lemma":"environner","pos":"VER"} ,
		{"word":"environnés","word_nosc":"environnes","lemma":"environner","pos":"VER"} ,
		{"word":"envisage","word_nosc":"envisage","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagea","word_nosc":"envisagea","lemma":"envisager","pos":"VER"} ,
		{"word":"envisageai","word_nosc":"envisageai","lemma":"envisager","pos":"VER"} ,
		{"word":"envisageaient","word_nosc":"envisageaient","lemma":"envisager","pos":"VER"} ,
		{"word":"envisageais","word_nosc":"envisageais","lemma":"envisager","pos":"VER"} ,
		{"word":"envisageait","word_nosc":"envisageait","lemma":"envisager","pos":"VER"} ,
		{"word":"envisageant","word_nosc":"envisageant","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagent","word_nosc":"envisagent","lemma":"envisager","pos":"VER"} ,
		{"word":"envisageons","word_nosc":"envisageons","lemma":"envisager","pos":"VER"} ,
		{"word":"envisager","word_nosc":"envisager","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagera","word_nosc":"envisagera","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagerai","word_nosc":"envisagerai","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagerais","word_nosc":"envisagerais","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagerait","word_nosc":"envisagerait","lemma":"envisager","pos":"VER"} ,
		{"word":"envisageras","word_nosc":"envisageras","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagerez","word_nosc":"envisagerez","lemma":"envisager","pos":"VER"} ,
		{"word":"envisageriez","word_nosc":"envisageriez","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagerions","word_nosc":"envisagerions","lemma":"envisager","pos":"VER"} ,
		{"word":"envisages","word_nosc":"envisages","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagez","word_nosc":"envisagez","lemma":"envisager","pos":"VER"} ,
		{"word":"envisageât","word_nosc":"envisageat","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagiez","word_nosc":"envisagiez","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagions","word_nosc":"envisagions","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagèrent","word_nosc":"envisagerent","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagé","word_nosc":"envisage","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagée","word_nosc":"envisagee","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagées","word_nosc":"envisagees","lemma":"envisager","pos":"VER"} ,
		{"word":"envisagés","word_nosc":"envisages","lemma":"envisager","pos":"VER"} ,
		{"word":"enviât","word_nosc":"enviat","lemma":"envier","pos":"VER"} ,
		{"word":"envié","word_nosc":"envie","lemma":"envier","pos":"VER"} ,
		{"word":"enviée","word_nosc":"enviee","lemma":"envier","pos":"VER"} ,
		{"word":"enviées","word_nosc":"enviees","lemma":"envier","pos":"VER"} ,
		{"word":"enviés","word_nosc":"envies","lemma":"envier","pos":"VER"} ,
		{"word":"envoie","word_nosc":"envoie","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoient","word_nosc":"envoient","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoies","word_nosc":"envoies","lemma":"envoyer","pos":"VER"} ,
		{"word":"envola","word_nosc":"envola","lemma":"envoler","pos":"VER"} ,
		{"word":"envolai","word_nosc":"envolai","lemma":"envoler","pos":"VER"} ,
		{"word":"envolaient","word_nosc":"envolaient","lemma":"envoler","pos":"VER"} ,
		{"word":"envolais","word_nosc":"envolais","lemma":"envoler","pos":"VER"} ,
		{"word":"envolait","word_nosc":"envolait","lemma":"envoler","pos":"VER"} ,
		{"word":"envolant","word_nosc":"envolant","lemma":"envoler","pos":"VER"} ,
		{"word":"envole","word_nosc":"envole","lemma":"envoler","pos":"VER"} ,
		{"word":"envolent","word_nosc":"envolent","lemma":"envoler","pos":"VER"} ,
		{"word":"envoler","word_nosc":"envoler","lemma":"envoler","pos":"VER"} ,
		{"word":"envolera","word_nosc":"envolera","lemma":"envoler","pos":"VER"} ,
		{"word":"envolerai","word_nosc":"envolerai","lemma":"envoler","pos":"VER"} ,
		{"word":"envoleraient","word_nosc":"envoleraient","lemma":"envoler","pos":"VER"} ,
		{"word":"envolerais","word_nosc":"envolerais","lemma":"envoler","pos":"VER"} ,
		{"word":"envolerait","word_nosc":"envolerait","lemma":"envoler","pos":"VER"} ,
		{"word":"envolerez","word_nosc":"envolerez","lemma":"envoler","pos":"VER"} ,
		{"word":"envolerions","word_nosc":"envolerions","lemma":"envoler","pos":"VER"} ,
		{"word":"envolerons","word_nosc":"envolerons","lemma":"envoler","pos":"VER"} ,
		{"word":"envoleront","word_nosc":"envoleront","lemma":"envoler","pos":"VER"} ,
		{"word":"envoles","word_nosc":"envoles","lemma":"envoler","pos":"VER"} ,
		{"word":"envolez","word_nosc":"envolez","lemma":"envoler","pos":"VER"} ,
		{"word":"envolons","word_nosc":"envolons","lemma":"envoler","pos":"VER"} ,
		{"word":"envolâmes","word_nosc":"envolames","lemma":"envoler","pos":"VER"} ,
		{"word":"envolât","word_nosc":"envolat","lemma":"envoler","pos":"VER"} ,
		{"word":"envolèrent","word_nosc":"envolerent","lemma":"envoler","pos":"VER"} ,
		{"word":"envolé","word_nosc":"envole","lemma":"envoler","pos":"VER"} ,
		{"word":"envolée","word_nosc":"envolee","lemma":"envoler","pos":"VER"} ,
		{"word":"envolées","word_nosc":"envolees","lemma":"envoler","pos":"VER"} ,
		{"word":"envolés","word_nosc":"envoles","lemma":"envoler","pos":"VER"} ,
		{"word":"envoya","word_nosc":"envoya","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyai","word_nosc":"envoyai","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyaient","word_nosc":"envoyaient","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyais","word_nosc":"envoyais","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyait","word_nosc":"envoyait","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyant","word_nosc":"envoyant","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyer","word_nosc":"envoyer","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyez","word_nosc":"envoyez","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyiez","word_nosc":"envoyiez","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyions","word_nosc":"envoyions","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyons","word_nosc":"envoyons","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyâmes","word_nosc":"envoyames","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyât","word_nosc":"envoyat","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyèrent","word_nosc":"envoyerent","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyé","word_nosc":"envoye","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyée","word_nosc":"envoyee","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyées","word_nosc":"envoyees","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoyés","word_nosc":"envoyes","lemma":"envoyer","pos":"VER"} ,
		{"word":"envoûtaient","word_nosc":"envoutaient","lemma":"envoûter","pos":"VER"} ,
		{"word":"envoûtait","word_nosc":"envoutait","lemma":"envoûter","pos":"VER"} ,
		{"word":"envoûtant","word_nosc":"envoutant","lemma":"envoûter","pos":"VER"} ,
		{"word":"envoûte","word_nosc":"envoute","lemma":"envoûter","pos":"VER"} ,
		{"word":"envoûter","word_nosc":"envouter","lemma":"envoûter","pos":"VER"} ,
		{"word":"envoûteras","word_nosc":"envouteras","lemma":"envoûter","pos":"VER"} ,
		{"word":"envoûté","word_nosc":"envoute","lemma":"envoûter","pos":"VER"} ,
		{"word":"envoûtée","word_nosc":"envoutee","lemma":"envoûter","pos":"VER"} ,
		{"word":"envoûtées","word_nosc":"envoutees","lemma":"envoûter","pos":"VER"} ,
		{"word":"envoûtés","word_nosc":"envoutes","lemma":"envoûter","pos":"VER"} ,
		{"word":"ergotait","word_nosc":"ergotait","lemma":"ergoter","pos":"VER"} ,
		{"word":"ergote","word_nosc":"ergote","lemma":"ergoter","pos":"VER"} ,
		{"word":"ergotent","word_nosc":"ergotent","lemma":"ergoter","pos":"VER"} ,
		{"word":"ergoter","word_nosc":"ergoter","lemma":"ergoter","pos":"VER"} ,
		{"word":"ergotons","word_nosc":"ergotons","lemma":"ergoter","pos":"VER"} ,
		{"word":"erra","word_nosc":"erra","lemma":"errer","pos":"VER"} ,
		{"word":"errai","word_nosc":"errai","lemma":"errer","pos":"VER"} ,
		{"word":"erraient","word_nosc":"erraient","lemma":"errer","pos":"VER"} ,
		{"word":"errais","word_nosc":"errais","lemma":"errer","pos":"VER"} ,
		{"word":"errait","word_nosc":"errait","lemma":"errer","pos":"VER"} ,
		{"word":"errant","word_nosc":"errant","lemma":"errer","pos":"VER"} ,
		{"word":"erre","word_nosc":"erre","lemma":"errer","pos":"VER"} ,
		{"word":"errent","word_nosc":"errent","lemma":"errer","pos":"VER"} ,
		{"word":"errer","word_nosc":"errer","lemma":"errer","pos":"VER"} ,
		{"word":"errera","word_nosc":"errera","lemma":"errer","pos":"VER"} ,
		{"word":"errerai","word_nosc":"errerai","lemma":"errer","pos":"VER"} ,
		{"word":"erreraient","word_nosc":"erreraient","lemma":"errer","pos":"VER"} ,
		{"word":"errerait","word_nosc":"errerait","lemma":"errer","pos":"VER"} ,
		{"word":"erreras","word_nosc":"erreras","lemma":"errer","pos":"VER"} ,
		{"word":"errerons","word_nosc":"errerons","lemma":"errer","pos":"VER"} ,
		{"word":"erreront","word_nosc":"erreront","lemma":"errer","pos":"VER"} ,
		{"word":"erres","word_nosc":"erres","lemma":"errer","pos":"VER"} ,
		{"word":"errez","word_nosc":"errez","lemma":"errer","pos":"VER"} ,
		{"word":"erriez","word_nosc":"erriez","lemma":"errer","pos":"VER"} ,
		{"word":"errions","word_nosc":"errions","lemma":"errer","pos":"VER"} ,
		{"word":"errons","word_nosc":"errons","lemma":"errer","pos":"VER"} ,
		{"word":"errâmes","word_nosc":"errames","lemma":"errer","pos":"VER"} ,
		{"word":"errèrent","word_nosc":"errerent","lemma":"errer","pos":"VER"} ,
		{"word":"erré","word_nosc":"erre","lemma":"errer","pos":"VER"} ,
		{"word":"errés","word_nosc":"erres","lemma":"errer","pos":"VER"} ,
		{"word":"es","word_nosc":"es","lemma":"être","pos":"VER"} ,
		{"word":"esbaudir","word_nosc":"esbaudir","lemma":"esbaudir","pos":"VER"} ,
		{"word":"esbignais","word_nosc":"esbignais","lemma":"esbigner","pos":"VER"} ,
		{"word":"esbignait","word_nosc":"esbignait","lemma":"esbigner","pos":"VER"} ,
		{"word":"esbigne","word_nosc":"esbigne","lemma":"esbigner","pos":"VER"} ,
		{"word":"esbignent","word_nosc":"esbignent","lemma":"esbigner","pos":"VER"} ,
		{"word":"esbigner","word_nosc":"esbigner","lemma":"esbigner","pos":"VER"} ,
		{"word":"esbigné","word_nosc":"esbigne","lemma":"esbigner","pos":"VER"} ,
		{"word":"esbroufe","word_nosc":"esbroufe","lemma":"esbroufer","pos":"VER"} ,
		{"word":"esbroufer","word_nosc":"esbroufer","lemma":"esbroufer","pos":"VER"} ,
		{"word":"esbroufée","word_nosc":"esbroufee","lemma":"esbroufer","pos":"VER"} ,
		{"word":"escagasse","word_nosc":"escagasse","lemma":"escagasser","pos":"VER"} ,
		{"word":"escagasser","word_nosc":"escagasser","lemma":"escagasser","pos":"VER"} ,
		{"word":"escalada","word_nosc":"escalada","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladaient","word_nosc":"escaladaient","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladais","word_nosc":"escaladais","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladait","word_nosc":"escaladait","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladant","word_nosc":"escaladant","lemma":"escalader","pos":"VER"} ,
		{"word":"escalade","word_nosc":"escalade","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladent","word_nosc":"escaladent","lemma":"escalader","pos":"VER"} ,
		{"word":"escalader","word_nosc":"escalader","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladera","word_nosc":"escaladera","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladerais","word_nosc":"escaladerais","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladerait","word_nosc":"escaladerait","lemma":"escalader","pos":"VER"} ,
		{"word":"escalades","word_nosc":"escalades","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladez","word_nosc":"escaladez","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladions","word_nosc":"escaladions","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladons","word_nosc":"escaladons","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladâmes","word_nosc":"escaladames","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladèrent","word_nosc":"escaladerent","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladé","word_nosc":"escalade","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladée","word_nosc":"escaladee","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladées","word_nosc":"escaladees","lemma":"escalader","pos":"VER"} ,
		{"word":"escaladés","word_nosc":"escalades","lemma":"escalader","pos":"VER"} ,
		{"word":"escalais","word_nosc":"escalais","lemma":"escaler","pos":"VER"} ,
		{"word":"escalera","word_nosc":"escalera","lemma":"escaler","pos":"VER"} ,
		{"word":"escamota","word_nosc":"escamota","lemma":"escamoter","pos":"VER"} ,
		{"word":"escamotaient","word_nosc":"escamotaient","lemma":"escamoter","pos":"VER"} ,
		{"word":"escamotait","word_nosc":"escamotait","lemma":"escamoter","pos":"VER"} ,
		{"word":"escamotant","word_nosc":"escamotant","lemma":"escamoter","pos":"VER"} ,
		{"word":"escamote","word_nosc":"escamote","lemma":"escamoter","pos":"VER"} ,
		{"word":"escamoter","word_nosc":"escamoter","lemma":"escamoter","pos":"VER"} ,
		{"word":"escamoterai","word_nosc":"escamoterai","lemma":"escamoter","pos":"VER"} ,
		{"word":"escamoté","word_nosc":"escamote","lemma":"escamoter","pos":"VER"} ,
		{"word":"escamotée","word_nosc":"escamotee","lemma":"escamoter","pos":"VER"} ,
		{"word":"escamotées","word_nosc":"escamotees","lemma":"escamoter","pos":"VER"} ,
		{"word":"escamotés","word_nosc":"escamotes","lemma":"escamoter","pos":"VER"} ,
		{"word":"escarmouchaient","word_nosc":"escarmouchaient","lemma":"escarmoucher","pos":"VER"} ,
		{"word":"escarmouchent","word_nosc":"escarmouchent","lemma":"escarmoucher","pos":"VER"} ,
		{"word":"escarpé","word_nosc":"escarpe","lemma":"escarper","pos":"VER"} ,
		{"word":"escarpée","word_nosc":"escarpee","lemma":"escarper","pos":"VER"} ,
		{"word":"escarpées","word_nosc":"escarpees","lemma":"escarper","pos":"VER"} ,
		{"word":"escher","word_nosc":"escher","lemma":"escher","pos":"VER"} ,
		{"word":"esclaffa","word_nosc":"esclaffa","lemma":"esclaffer","pos":"VER"} ,
		{"word":"esclaffai","word_nosc":"esclaffai","lemma":"esclaffer","pos":"VER"} ,
		{"word":"esclaffaient","word_nosc":"esclaffaient","lemma":"esclaffer","pos":"VER"} ,
		{"word":"esclaffait","word_nosc":"esclaffait","lemma":"esclaffer","pos":"VER"} ,
		{"word":"esclaffant","word_nosc":"esclaffant","lemma":"esclaffer","pos":"VER"} ,
		{"word":"esclaffe","word_nosc":"esclaffe","lemma":"esclaffer","pos":"VER"} ,
		{"word":"esclaffent","word_nosc":"esclaffent","lemma":"esclaffer","pos":"VER"} ,
		{"word":"esclaffer","word_nosc":"esclaffer","lemma":"esclaffer","pos":"VER"} ,
		{"word":"esclafferaient","word_nosc":"esclafferaient","lemma":"esclaffer","pos":"VER"} ,
		{"word":"esclaffèrent","word_nosc":"esclafferent","lemma":"esclaffer","pos":"VER"} ,
		{"word":"esclaffé","word_nosc":"esclaffe","lemma":"esclaffer","pos":"VER"} ,
		{"word":"esclaffée","word_nosc":"esclaffee","lemma":"esclaffer","pos":"VER"} ,
		{"word":"esclaffés","word_nosc":"esclaffes","lemma":"esclaffer","pos":"VER"} ,
		{"word":"esclavagea","word_nosc":"esclavagea","lemma":"esclavager","pos":"VER"} ,
		{"word":"esclavager","word_nosc":"esclavager","lemma":"esclavager","pos":"VER"} ,
		{"word":"esclavagé","word_nosc":"esclavage","lemma":"esclavager","pos":"VER"} ,
		{"word":"escomptai","word_nosc":"escomptai","lemma":"escompter","pos":"VER"} ,
		{"word":"escomptaient","word_nosc":"escomptaient","lemma":"escompter","pos":"VER"} ,
		{"word":"escomptais","word_nosc":"escomptais","lemma":"escompter","pos":"VER"} ,
		{"word":"escomptait","word_nosc":"escomptait","lemma":"escompter","pos":"VER"} ,
		{"word":"escomptant","word_nosc":"escomptant","lemma":"escompter","pos":"VER"} ,
		{"word":"escompte","word_nosc":"escompte","lemma":"escompter","pos":"VER"} ,
		{"word":"escomptent","word_nosc":"escomptent","lemma":"escompter","pos":"VER"} ,
		{"word":"escompter","word_nosc":"escompter","lemma":"escompter","pos":"VER"} ,
		{"word":"escomptes","word_nosc":"escomptes","lemma":"escompter","pos":"VER"} ,
		{"word":"escomptez","word_nosc":"escomptez","lemma":"escompter","pos":"VER"} ,
		{"word":"escomptions","word_nosc":"escomptions","lemma":"escompter","pos":"VER"} ,
		{"word":"escompté","word_nosc":"escompte","lemma":"escompter","pos":"VER"} ,
		{"word":"escomptée","word_nosc":"escomptee","lemma":"escompter","pos":"VER"} ,
		{"word":"escomptées","word_nosc":"escomptees","lemma":"escompter","pos":"VER"} ,
		{"word":"escomptés","word_nosc":"escomptes","lemma":"escompter","pos":"VER"} ,
		{"word":"escorta","word_nosc":"escorta","lemma":"escorter","pos":"VER"} ,
		{"word":"escortaient","word_nosc":"escortaient","lemma":"escorter","pos":"VER"} ,
		{"word":"escortais","word_nosc":"escortais","lemma":"escorter","pos":"VER"} ,
		{"word":"escortait","word_nosc":"escortait","lemma":"escorter","pos":"VER"} ,
		{"word":"escortant","word_nosc":"escortant","lemma":"escorter","pos":"VER"} ,
		{"word":"escorte","word_nosc":"escorte","lemma":"escorter","pos":"VER"} ,
		{"word":"escortent","word_nosc":"escortent","lemma":"escorter","pos":"VER"} ,
		{"word":"escorter","word_nosc":"escorter","lemma":"escorter","pos":"VER"} ,
		{"word":"escortera","word_nosc":"escortera","lemma":"escorter","pos":"VER"} ,
		{"word":"escorterai","word_nosc":"escorterai","lemma":"escorter","pos":"VER"} ,
		{"word":"escorteraient","word_nosc":"escorteraient","lemma":"escorter","pos":"VER"} ,
		{"word":"escorterez","word_nosc":"escorterez","lemma":"escorter","pos":"VER"} ,
		{"word":"escorteriez","word_nosc":"escorteriez","lemma":"escorter","pos":"VER"} ,
		{"word":"escorterons","word_nosc":"escorterons","lemma":"escorter","pos":"VER"} ,
		{"word":"escorteront","word_nosc":"escorteront","lemma":"escorter","pos":"VER"} ,
		{"word":"escortes","word_nosc":"escortes","lemma":"escorter","pos":"VER"} ,
		{"word":"escortez","word_nosc":"escortez","lemma":"escorter","pos":"VER"} ,
		{"word":"escortiez","word_nosc":"escortiez","lemma":"escorter","pos":"VER"} ,
		{"word":"escortons","word_nosc":"escortons","lemma":"escorter","pos":"VER"} ,
		{"word":"escortèrent","word_nosc":"escorterent","lemma":"escorter","pos":"VER"} ,
		{"word":"escorté","word_nosc":"escorte","lemma":"escorter","pos":"VER"} ,
		{"word":"escortée","word_nosc":"escortee","lemma":"escorter","pos":"VER"} ,
		{"word":"escortées","word_nosc":"escortees","lemma":"escorter","pos":"VER"} ,
		{"word":"escortés","word_nosc":"escortes","lemma":"escorter","pos":"VER"} ,
		{"word":"escrimai","word_nosc":"escrimai","lemma":"escrimer","pos":"VER"} ,
		{"word":"escrimaient","word_nosc":"escrimaient","lemma":"escrimer","pos":"VER"} ,
		{"word":"escrimais","word_nosc":"escrimais","lemma":"escrimer","pos":"VER"} ,
		{"word":"escrimait","word_nosc":"escrimait","lemma":"escrimer","pos":"VER"} ,
		{"word":"escrimant","word_nosc":"escrimant","lemma":"escrimer","pos":"VER"} ,
		{"word":"escrime","word_nosc":"escrime","lemma":"escrimer","pos":"VER"} ,
		{"word":"escriment","word_nosc":"escriment","lemma":"escrimer","pos":"VER"} ,
		{"word":"escrimer","word_nosc":"escrimer","lemma":"escrimer","pos":"VER"} ,
		{"word":"escrimera","word_nosc":"escrimera","lemma":"escrimer","pos":"VER"} ,
		{"word":"escrimeraient","word_nosc":"escrimeraient","lemma":"escrimer","pos":"VER"} ,
		{"word":"escrimes","word_nosc":"escrimes","lemma":"escrimer","pos":"VER"} ,
		{"word":"escroquant","word_nosc":"escroquant","lemma":"escroquer","pos":"VER"} ,
		{"word":"escroque","word_nosc":"escroque","lemma":"escroquer","pos":"VER"} ,
		{"word":"escroquent","word_nosc":"escroquent","lemma":"escroquer","pos":"VER"} ,
		{"word":"escroquer","word_nosc":"escroquer","lemma":"escroquer","pos":"VER"} ,
		{"word":"escroquera","word_nosc":"escroquera","lemma":"escroquer","pos":"VER"} ,
		{"word":"escroques","word_nosc":"escroques","lemma":"escroquer","pos":"VER"} ,
		{"word":"escroquez","word_nosc":"escroquez","lemma":"escroquer","pos":"VER"} ,
		{"word":"escroqué","word_nosc":"escroque","lemma":"escroquer","pos":"VER"} ,
		{"word":"escroquées","word_nosc":"escroquees","lemma":"escroquer","pos":"VER"} ,
		{"word":"escroqués","word_nosc":"escroques","lemma":"escroquer","pos":"VER"} ,
		{"word":"esgourdai","word_nosc":"esgourdai","lemma":"esgourder","pos":"VER"} ,
		{"word":"esgourdant","word_nosc":"esgourdant","lemma":"esgourder","pos":"VER"} ,
		{"word":"esgourder","word_nosc":"esgourder","lemma":"esgourder","pos":"VER"} ,
		{"word":"esgourdé","word_nosc":"esgourde","lemma":"esgourder","pos":"VER"} ,
		{"word":"esgourdée","word_nosc":"esgourdee","lemma":"esgourder","pos":"VER"} ,
		{"word":"espace","word_nosc":"espace","lemma":"espacer","pos":"VER"} ,
		{"word":"espacent","word_nosc":"espacent","lemma":"espacer","pos":"VER"} ,
		{"word":"espacer","word_nosc":"espacer","lemma":"espacer","pos":"VER"} ,
		{"word":"espacerait","word_nosc":"espacerait","lemma":"espacer","pos":"VER"} ,
		{"word":"espaceront","word_nosc":"espaceront","lemma":"espacer","pos":"VER"} ,
		{"word":"espacez","word_nosc":"espacez","lemma":"espacer","pos":"VER"} ,
		{"word":"espacions","word_nosc":"espacions","lemma":"espacer","pos":"VER"} ,
		{"word":"espacèrent","word_nosc":"espacerent","lemma":"espacer","pos":"VER"} ,
		{"word":"espacé","word_nosc":"espace","lemma":"espacer","pos":"VER"} ,
		{"word":"espacée","word_nosc":"espacee","lemma":"espacer","pos":"VER"} ,
		{"word":"espacées","word_nosc":"espacees","lemma":"espacer","pos":"VER"} ,
		{"word":"espacés","word_nosc":"espaces","lemma":"espacer","pos":"VER"} ,
		{"word":"espaça","word_nosc":"espaca","lemma":"espacer","pos":"VER"} ,
		{"word":"espaçaient","word_nosc":"espacaient","lemma":"espacer","pos":"VER"} ,
		{"word":"espaçait","word_nosc":"espacait","lemma":"espacer","pos":"VER"} ,
		{"word":"espaçant","word_nosc":"espacant","lemma":"espacer","pos":"VER"} ,
		{"word":"espionnaient","word_nosc":"espionnaient","lemma":"espionner","pos":"VER"} ,
		{"word":"espionnais","word_nosc":"espionnais","lemma":"espionner","pos":"VER"} ,
		{"word":"espionnait","word_nosc":"espionnait","lemma":"espionner","pos":"VER"} ,
		{"word":"espionnant","word_nosc":"espionnant","lemma":"espionner","pos":"VER"} ,
		{"word":"espionnent","word_nosc":"espionnent","lemma":"espionner","pos":"VER"} ,
		{"word":"espionner","word_nosc":"espionner","lemma":"espionner","pos":"VER"} ,
		{"word":"espionnera","word_nosc":"espionnera","lemma":"espionner","pos":"VER"} ,
		{"word":"espionnerai","word_nosc":"espionnerai","lemma":"espionner","pos":"VER"} ,
		{"word":"espionnerions","word_nosc":"espionnerions","lemma":"espionner","pos":"VER"} ,
		{"word":"espionnez","word_nosc":"espionnez","lemma":"espionner","pos":"VER"} ,
		{"word":"espionniez","word_nosc":"espionniez","lemma":"espionner","pos":"VER"} ,
		{"word":"espionnons","word_nosc":"espionnons","lemma":"espionner","pos":"VER"} ,
		{"word":"espionnât","word_nosc":"espionnat","lemma":"espionner","pos":"VER"} ,
		{"word":"espionné","word_nosc":"espionne","lemma":"espionner","pos":"VER"} ,
		{"word":"espionnée","word_nosc":"espionnee","lemma":"espionner","pos":"VER"} ,
		{"word":"espionnés","word_nosc":"espionnes","lemma":"espionner","pos":"VER"} ,
		{"word":"espère","word_nosc":"espere","lemma":"espérer","pos":"VER"} ,
		{"word":"espèrent","word_nosc":"esperent","lemma":"espérer","pos":"VER"} ,
		{"word":"espères","word_nosc":"esperes","lemma":"espérer","pos":"VER"} ,
		{"word":"espéra","word_nosc":"espera","lemma":"espérer","pos":"VER"} ,
		{"word":"espérai","word_nosc":"esperai","lemma":"espérer","pos":"VER"} ,
		{"word":"espéraient","word_nosc":"esperaient","lemma":"espérer","pos":"VER"} ,
		{"word":"espérais","word_nosc":"esperais","lemma":"espérer","pos":"VER"} ,
		{"word":"espérait","word_nosc":"esperait","lemma":"espérer","pos":"VER"} ,
		{"word":"espérant","word_nosc":"esperant","lemma":"espérer","pos":"VER"} ,
		{"word":"espérer","word_nosc":"esperer","lemma":"espérer","pos":"VER"} ,
		{"word":"espérerai","word_nosc":"espererai","lemma":"espérer","pos":"VER"} ,
		{"word":"espérerais","word_nosc":"espererais","lemma":"espérer","pos":"VER"} ,
		{"word":"espérerait","word_nosc":"espererait","lemma":"espérer","pos":"VER"} ,
		{"word":"espérez","word_nosc":"esperez","lemma":"espérer","pos":"VER"} ,
		{"word":"espériez","word_nosc":"esperiez","lemma":"espérer","pos":"VER"} ,
		{"word":"espérions","word_nosc":"esperions","lemma":"espérer","pos":"VER"} ,
		{"word":"espérons","word_nosc":"esperons","lemma":"espérer","pos":"VER"} ,
		{"word":"espérâmes","word_nosc":"esperames","lemma":"espérer","pos":"VER"} ,
		{"word":"espérât","word_nosc":"esperat","lemma":"espérer","pos":"VER"} ,
		{"word":"espérèrent","word_nosc":"espererent","lemma":"espérer","pos":"VER"} ,
		{"word":"espéré","word_nosc":"espere","lemma":"espérer","pos":"VER"} ,
		{"word":"espérée","word_nosc":"esperee","lemma":"espérer","pos":"VER"} ,
		{"word":"espérées","word_nosc":"esperees","lemma":"espérer","pos":"VER"} ,
		{"word":"espérés","word_nosc":"esperes","lemma":"espérer","pos":"VER"} ,
		{"word":"esquintait","word_nosc":"esquintait","lemma":"esquinter","pos":"VER"} ,
		{"word":"esquinte","word_nosc":"esquinte","lemma":"esquinter","pos":"VER"} ,
		{"word":"esquintent","word_nosc":"esquintent","lemma":"esquinter","pos":"VER"} ,
		{"word":"esquinter","word_nosc":"esquinter","lemma":"esquinter","pos":"VER"} ,
		{"word":"esquinterais","word_nosc":"esquinterais","lemma":"esquinter","pos":"VER"} ,
		{"word":"esquintes","word_nosc":"esquintes","lemma":"esquinter","pos":"VER"} ,
		{"word":"esquintez","word_nosc":"esquintez","lemma":"esquinter","pos":"VER"} ,
		{"word":"esquinté","word_nosc":"esquinte","lemma":"esquinter","pos":"VER"} ,
		{"word":"esquintée","word_nosc":"esquintee","lemma":"esquinter","pos":"VER"} ,
		{"word":"esquintées","word_nosc":"esquintees","lemma":"esquinter","pos":"VER"} ,
		{"word":"esquintés","word_nosc":"esquintes","lemma":"esquinter","pos":"VER"} ,
		{"word":"esquissa","word_nosc":"esquissa","lemma":"esquisser","pos":"VER"} ,
		{"word":"esquissai","word_nosc":"esquissai","lemma":"esquisser","pos":"VER"} ,
		{"word":"esquissaient","word_nosc":"esquissaient","lemma":"esquisser","pos":"VER"} ,
		{"word":"esquissais","word_nosc":"esquissais","lemma":"esquisser","pos":"VER"} ,
		{"word":"esquissait","word_nosc":"esquissait","lemma":"esquisser","pos":"VER"} ,
		{"word":"esquissant","word_nosc":"esquissant","lemma":"esquisser","pos":"VER"} ,
		{"word":"esquisse","word_nosc":"esquisse","lemma":"esquisser","pos":"VER"} ,
		{"word":"esquissent","word_nosc":"esquissent","lemma":"esquisser","pos":"VER"} ,
		{"word":"esquisser","word_nosc":"esquisser","lemma":"esquisser","pos":"VER"} ,
		{"word":"esquisses","word_nosc":"esquisses","lemma":"esquisser","pos":"VER"} ,
		{"word":"esquissèrent","word_nosc":"esquisserent","lemma":"esquisser","pos":"VER"} ,
		{"word":"esquissé","word_nosc":"esquisse","lemma":"esquisser","pos":"VER"} ,
		{"word":"esquissée","word_nosc":"esquissee","lemma":"esquisser","pos":"VER"} ,
		{"word":"esquissées","word_nosc":"esquissees","lemma":"esquisser","pos":"VER"} ,
		{"word":"esquissés","word_nosc":"esquisses","lemma":"esquisser","pos":"VER"} ,
		{"word":"esquiva","word_nosc":"esquiva","lemma":"esquiver","pos":"VER"} ,
		{"word":"esquivai","word_nosc":"esquivai","lemma":"esquiver","pos":"VER"} ,
		{"word":"esquivaient","word_nosc":"esquivaient","lemma":"esquiver","pos":"VER"} ,
		{"word":"esquivait","word_nosc":"esquivait","lemma":"esquiver","pos":"VER"} ,
		{"word":"esquivant","word_nosc":"esquivant","lemma":"esquiver","pos":"VER"} ,
		{"word":"esquive","word_nosc":"esquive","lemma":"esquiver","pos":"VER"} ,
		{"word":"esquivent","word_nosc":"esquivent","lemma":"esquiver","pos":"VER"} ,
		{"word":"esquiver","word_nosc":"esquiver","lemma":"esquiver","pos":"VER"} ,
		{"word":"esquivera","word_nosc":"esquivera","lemma":"esquiver","pos":"VER"} ,
		{"word":"esquiveront","word_nosc":"esquiveront","lemma":"esquiver","pos":"VER"} ,
		{"word":"esquives","word_nosc":"esquives","lemma":"esquiver","pos":"VER"} ,
		{"word":"esquivèrent","word_nosc":"esquiverent","lemma":"esquiver","pos":"VER"} ,
		{"word":"esquivé","word_nosc":"esquive","lemma":"esquiver","pos":"VER"} ,
		{"word":"esquivée","word_nosc":"esquivee","lemma":"esquiver","pos":"VER"} ,
		{"word":"essaie","word_nosc":"essaie","lemma":"essayer","pos":"VER"} ,
		{"word":"essaient","word_nosc":"essaient","lemma":"essayer","pos":"VER"} ,
		{"word":"essaiera","word_nosc":"essaiera","lemma":"essayer","pos":"VER"} ,
		{"word":"essaierai","word_nosc":"essaierai","lemma":"essayer","pos":"VER"} ,
		{"word":"essaieraient","word_nosc":"essaieraient","lemma":"essayer","pos":"VER"} ,
		{"word":"essaierais","word_nosc":"essaierais","lemma":"essayer","pos":"VER"} ,
		{"word":"essaierait","word_nosc":"essaierait","lemma":"essayer","pos":"VER"} ,
		{"word":"essaieras","word_nosc":"essaieras","lemma":"essayer","pos":"VER"} ,
		{"word":"essaierez","word_nosc":"essaierez","lemma":"essayer","pos":"VER"} ,
		{"word":"essaieriez","word_nosc":"essaieriez","lemma":"essayer","pos":"VER"} ,
		{"word":"essaierions","word_nosc":"essaierions","lemma":"essayer","pos":"VER"} ,
		{"word":"essaierons","word_nosc":"essaierons","lemma":"essayer","pos":"VER"} ,
		{"word":"essaieront","word_nosc":"essaieront","lemma":"essayer","pos":"VER"} ,
		{"word":"essaies","word_nosc":"essaies","lemma":"essayer","pos":"VER"} ,
		{"word":"essaimaient","word_nosc":"essaimaient","lemma":"essaimer","pos":"VER"} ,
		{"word":"essaimait","word_nosc":"essaimait","lemma":"essaimer","pos":"VER"} ,
		{"word":"essaiment","word_nosc":"essaiment","lemma":"essaimer","pos":"VER"} ,
		{"word":"essaimé","word_nosc":"essaime","lemma":"essaimer","pos":"VER"} ,
		{"word":"essaimées","word_nosc":"essaimees","lemma":"essaimer","pos":"VER"} ,
		{"word":"essaimés","word_nosc":"essaimes","lemma":"essaimer","pos":"VER"} ,
		{"word":"essangent","word_nosc":"essangent","lemma":"essanger","pos":"VER"} ,
		{"word":"essarte","word_nosc":"essarte","lemma":"essarter","pos":"VER"} ,
		{"word":"essartée","word_nosc":"essartee","lemma":"essarter","pos":"VER"} ,
		{"word":"essaya","word_nosc":"essaya","lemma":"essayer","pos":"VER"} ,
		{"word":"essayai","word_nosc":"essayai","lemma":"essayer","pos":"VER"} ,
		{"word":"essayaient","word_nosc":"essayaient","lemma":"essayer","pos":"VER"} ,
		{"word":"essayais","word_nosc":"essayais","lemma":"essayer","pos":"VER"} ,
		{"word":"essayait","word_nosc":"essayait","lemma":"essayer","pos":"VER"} ,
		{"word":"essayant","word_nosc":"essayant","lemma":"essayer","pos":"VER"} ,
		{"word":"essaye","word_nosc":"essaye","lemma":"essayer","pos":"VER"} ,
		{"word":"essayent","word_nosc":"essayent","lemma":"essayer","pos":"VER"} ,
		{"word":"essayer","word_nosc":"essayer","lemma":"essayer","pos":"VER"} ,
		{"word":"essayera","word_nosc":"essayera","lemma":"essayer","pos":"VER"} ,
		{"word":"essayerai","word_nosc":"essayerai","lemma":"essayer","pos":"VER"} ,
		{"word":"essayeraient","word_nosc":"essayeraient","lemma":"essayer","pos":"VER"} ,
		{"word":"essayerais","word_nosc":"essayerais","lemma":"essayer","pos":"VER"} ,
		{"word":"essayerait","word_nosc":"essayerait","lemma":"essayer","pos":"VER"} ,
		{"word":"essayeras","word_nosc":"essayeras","lemma":"essayer","pos":"VER"} ,
		{"word":"essayerez","word_nosc":"essayerez","lemma":"essayer","pos":"VER"} ,
		{"word":"essayeriez","word_nosc":"essayeriez","lemma":"essayer","pos":"VER"} ,
		{"word":"essayerons","word_nosc":"essayerons","lemma":"essayer","pos":"VER"} ,
		{"word":"essayeront","word_nosc":"essayeront","lemma":"essayer","pos":"VER"} ,
		{"word":"essayes","word_nosc":"essayes","lemma":"essayer","pos":"VER"} ,
		{"word":"essayez","word_nosc":"essayez","lemma":"essayer","pos":"VER"} ,
		{"word":"essayiez","word_nosc":"essayiez","lemma":"essayer","pos":"VER"} ,
		{"word":"essayions","word_nosc":"essayions","lemma":"essayer","pos":"VER"} ,
		{"word":"essayons","word_nosc":"essayons","lemma":"essayer","pos":"VER"} ,
		{"word":"essayâmes","word_nosc":"essayames","lemma":"essayer","pos":"VER"} ,
		{"word":"essayât","word_nosc":"essayat","lemma":"essayer","pos":"VER"} ,
		{"word":"essayèrent","word_nosc":"essayerent","lemma":"essayer","pos":"VER"} ,
		{"word":"essayé","word_nosc":"essaye","lemma":"essayer","pos":"VER"} ,
		{"word":"essayée","word_nosc":"essayee","lemma":"essayer","pos":"VER"} ,
		{"word":"essayées","word_nosc":"essayees","lemma":"essayer","pos":"VER"} ,
		{"word":"essayés","word_nosc":"essayes","lemma":"essayer","pos":"VER"} ,
		{"word":"esseulé","word_nosc":"esseule","lemma":"esseuler","pos":"VER"} ,
		{"word":"esseulée","word_nosc":"esseulee","lemma":"esseuler","pos":"VER"} ,
		{"word":"esseulées","word_nosc":"esseulees","lemma":"esseuler","pos":"VER"} ,
		{"word":"esseulés","word_nosc":"esseules","lemma":"esseuler","pos":"VER"} ,
		{"word":"essora","word_nosc":"essora","lemma":"essorer","pos":"VER"} ,
		{"word":"essoraient","word_nosc":"essoraient","lemma":"essorer","pos":"VER"} ,
		{"word":"essorait","word_nosc":"essorait","lemma":"essorer","pos":"VER"} ,
		{"word":"essorant","word_nosc":"essorant","lemma":"essorer","pos":"VER"} ,
		{"word":"essore","word_nosc":"essore","lemma":"essorer","pos":"VER"} ,
		{"word":"essorent","word_nosc":"essorent","lemma":"essorer","pos":"VER"} ,
		{"word":"essorer","word_nosc":"essorer","lemma":"essorer","pos":"VER"} ,
		{"word":"essorerait","word_nosc":"essorerait","lemma":"essorer","pos":"VER"} ,
		{"word":"essorillé","word_nosc":"essorille","lemma":"essoriller","pos":"VER"} ,
		{"word":"essoré","word_nosc":"essore","lemma":"essorer","pos":"VER"} ,
		{"word":"essorée","word_nosc":"essoree","lemma":"essorer","pos":"VER"} ,
		{"word":"essorés","word_nosc":"essores","lemma":"essorer","pos":"VER"} ,
		{"word":"essouffla","word_nosc":"essouffla","lemma":"essouffler","pos":"VER"} ,
		{"word":"essoufflaient","word_nosc":"essoufflaient","lemma":"essouffler","pos":"VER"} ,
		{"word":"essoufflais","word_nosc":"essoufflais","lemma":"essouffler","pos":"VER"} ,
		{"word":"essoufflait","word_nosc":"essoufflait","lemma":"essouffler","pos":"VER"} ,
		{"word":"essoufflant","word_nosc":"essoufflant","lemma":"essouffler","pos":"VER"} ,
		{"word":"essouffle","word_nosc":"essouffle","lemma":"essouffler","pos":"VER"} ,
		{"word":"essoufflent","word_nosc":"essoufflent","lemma":"essouffler","pos":"VER"} ,
		{"word":"essouffler","word_nosc":"essouffler","lemma":"essouffler","pos":"VER"} ,
		{"word":"essouffleront","word_nosc":"essouffleront","lemma":"essouffler","pos":"VER"} ,
		{"word":"essouffles","word_nosc":"essouffles","lemma":"essouffler","pos":"VER"} ,
		{"word":"essoufflez","word_nosc":"essoufflez","lemma":"essouffler","pos":"VER"} ,
		{"word":"essoufflèrent","word_nosc":"essoufflerent","lemma":"essouffler","pos":"VER"} ,
		{"word":"essoufflé","word_nosc":"essouffle","lemma":"essouffler","pos":"VER"} ,
		{"word":"essoufflée","word_nosc":"essoufflee","lemma":"essouffler","pos":"VER"} ,
		{"word":"essoufflés","word_nosc":"essouffles","lemma":"essouffler","pos":"VER"} ,
		{"word":"essuie","word_nosc":"essuie","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuient","word_nosc":"essuient","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuiera","word_nosc":"essuiera","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuierais","word_nosc":"essuierais","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuieras","word_nosc":"essuieras","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuierez","word_nosc":"essuierez","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuierions","word_nosc":"essuierions","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuies","word_nosc":"essuies","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuya","word_nosc":"essuya","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuyai","word_nosc":"essuyai","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuyaient","word_nosc":"essuyaient","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuyais","word_nosc":"essuyais","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuyait","word_nosc":"essuyait","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuyant","word_nosc":"essuyant","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuyer","word_nosc":"essuyer","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuyez","word_nosc":"essuyez","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuyions","word_nosc":"essuyions","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuyons","word_nosc":"essuyons","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuyèrent","word_nosc":"essuyerent","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuyé","word_nosc":"essuye","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuyée","word_nosc":"essuyee","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuyées","word_nosc":"essuyees","lemma":"essuyer","pos":"VER"} ,
		{"word":"essuyés","word_nosc":"essuyes","lemma":"essuyer","pos":"VER"} ,
		{"word":"est","word_nosc":"est","lemma":"être","pos":"VER"} ,
		{"word":"estamper","word_nosc":"estamper","lemma":"estamper","pos":"VER"} ,
		{"word":"estampilla","word_nosc":"estampilla","lemma":"estampiller","pos":"VER"} ,
		{"word":"estampillaient","word_nosc":"estampillaient","lemma":"estampiller","pos":"VER"} ,
		{"word":"estampiller","word_nosc":"estampiller","lemma":"estampiller","pos":"VER"} ,
		{"word":"estampillé","word_nosc":"estampille","lemma":"estampiller","pos":"VER"} ,
		{"word":"estampillés","word_nosc":"estampilles","lemma":"estampiller","pos":"VER"} ,
		{"word":"estampé","word_nosc":"estampe","lemma":"estamper","pos":"VER"} ,
		{"word":"estampée","word_nosc":"estampee","lemma":"estamper","pos":"VER"} ,
		{"word":"ester","word_nosc":"ester","lemma":"ester","pos":"VER"} ,
		{"word":"esthétisait","word_nosc":"esthetisait","lemma":"esthétiser","pos":"VER"} ,
		{"word":"estima","word_nosc":"estima","lemma":"estimer","pos":"VER"} ,
		{"word":"estimai","word_nosc":"estimai","lemma":"estimer","pos":"VER"} ,
		{"word":"estimaient","word_nosc":"estimaient","lemma":"estimer","pos":"VER"} ,
		{"word":"estimais","word_nosc":"estimais","lemma":"estimer","pos":"VER"} ,
		{"word":"estimait","word_nosc":"estimait","lemma":"estimer","pos":"VER"} ,
		{"word":"estimant","word_nosc":"estimant","lemma":"estimer","pos":"VER"} ,
		{"word":"estime","word_nosc":"estime","lemma":"estimer","pos":"VER"} ,
		{"word":"estiment","word_nosc":"estiment","lemma":"estimer","pos":"VER"} ,
		{"word":"estimer","word_nosc":"estimer","lemma":"estimer","pos":"VER"} ,
		{"word":"estimera","word_nosc":"estimera","lemma":"estimer","pos":"VER"} ,
		{"word":"estimerai","word_nosc":"estimerai","lemma":"estimer","pos":"VER"} ,
		{"word":"estimerais","word_nosc":"estimerais","lemma":"estimer","pos":"VER"} ,
		{"word":"estimerait","word_nosc":"estimerait","lemma":"estimer","pos":"VER"} ,
		{"word":"estimeras","word_nosc":"estimeras","lemma":"estimer","pos":"VER"} ,
		{"word":"estimerez","word_nosc":"estimerez","lemma":"estimer","pos":"VER"} ,
		{"word":"estimes","word_nosc":"estimes","lemma":"estimer","pos":"VER"} ,
		{"word":"estimez","word_nosc":"estimez","lemma":"estimer","pos":"VER"} ,
		{"word":"estimiez","word_nosc":"estimiez","lemma":"estimer","pos":"VER"} ,
		{"word":"estimions","word_nosc":"estimions","lemma":"estimer","pos":"VER"} ,
		{"word":"estimons","word_nosc":"estimons","lemma":"estimer","pos":"VER"} ,
		{"word":"estimât","word_nosc":"estimat","lemma":"estimer","pos":"VER"} ,
		{"word":"estimèrent","word_nosc":"estimerent","lemma":"estimer","pos":"VER"} ,
		{"word":"estimé","word_nosc":"estime","lemma":"estimer","pos":"VER"} ,
		{"word":"estimée","word_nosc":"estimee","lemma":"estimer","pos":"VER"} ,
		{"word":"estimées","word_nosc":"estimees","lemma":"estimer","pos":"VER"} ,
		{"word":"estimés","word_nosc":"estimes","lemma":"estimer","pos":"VER"} ,
		{"word":"estomaquait","word_nosc":"estomaquait","lemma":"estomaquer","pos":"VER"} ,
		{"word":"estomaquant","word_nosc":"estomaquant","lemma":"estomaquer","pos":"VER"} ,
		{"word":"estomaque","word_nosc":"estomaque","lemma":"estomaquer","pos":"VER"} ,
		{"word":"estomaquer","word_nosc":"estomaquer","lemma":"estomaquer","pos":"VER"} ,
		{"word":"estomaqué","word_nosc":"estomaque","lemma":"estomaquer","pos":"VER"} ,
		{"word":"estomaquée","word_nosc":"estomaquee","lemma":"estomaquer","pos":"VER"} ,
		{"word":"estomaqués","word_nosc":"estomaques","lemma":"estomaquer","pos":"VER"} ,
		{"word":"estompa","word_nosc":"estompa","lemma":"estomper","pos":"VER"} ,
		{"word":"estompaient","word_nosc":"estompaient","lemma":"estomper","pos":"VER"} ,
		{"word":"estompait","word_nosc":"estompait","lemma":"estomper","pos":"VER"} ,
		{"word":"estompant","word_nosc":"estompant","lemma":"estomper","pos":"VER"} ,
		{"word":"estompe","word_nosc":"estompe","lemma":"estomper","pos":"VER"} ,
		{"word":"estompent","word_nosc":"estompent","lemma":"estomper","pos":"VER"} ,
		{"word":"estomper","word_nosc":"estomper","lemma":"estomper","pos":"VER"} ,
		{"word":"estompera","word_nosc":"estompera","lemma":"estomper","pos":"VER"} ,
		{"word":"estomperont","word_nosc":"estomperont","lemma":"estomper","pos":"VER"} ,
		{"word":"estompes","word_nosc":"estompes","lemma":"estomper","pos":"VER"} ,
		{"word":"estompât","word_nosc":"estompat","lemma":"estomper","pos":"VER"} ,
		{"word":"estompèrent","word_nosc":"estomperent","lemma":"estomper","pos":"VER"} ,
		{"word":"estompé","word_nosc":"estompe","lemma":"estomper","pos":"VER"} ,
		{"word":"estompée","word_nosc":"estompee","lemma":"estomper","pos":"VER"} ,
		{"word":"estompées","word_nosc":"estompees","lemma":"estomper","pos":"VER"} ,
		{"word":"estompés","word_nosc":"estompes","lemma":"estomper","pos":"VER"} ,
		{"word":"estoqua","word_nosc":"estoqua","lemma":"estoquer","pos":"VER"} ,
		{"word":"estoque","word_nosc":"estoque","lemma":"estoquer","pos":"VER"} ,
		{"word":"estoquer","word_nosc":"estoquer","lemma":"estoquer","pos":"VER"} ,
		{"word":"estoqué","word_nosc":"estoque","lemma":"estoquer","pos":"VER"} ,
		{"word":"estourbi","word_nosc":"estourbi","lemma":"estourbir","pos":"VER"} ,
		{"word":"estourbie","word_nosc":"estourbie","lemma":"estourbir","pos":"VER"} ,
		{"word":"estourbies","word_nosc":"estourbies","lemma":"estourbir","pos":"VER"} ,
		{"word":"estourbir","word_nosc":"estourbir","lemma":"estourbir","pos":"VER"} ,
		{"word":"estourbirait","word_nosc":"estourbirait","lemma":"estourbir","pos":"VER"} ,
		{"word":"estourbis","word_nosc":"estourbis","lemma":"estourbir","pos":"VER"} ,
		{"word":"estourbissais","word_nosc":"estourbissais","lemma":"estourbir","pos":"VER"} ,
		{"word":"estourbissant","word_nosc":"estourbissant","lemma":"estourbir","pos":"VER"} ,
		{"word":"estourbisse","word_nosc":"estourbisse","lemma":"estourbir","pos":"VER"} ,
		{"word":"estourbit","word_nosc":"estourbit","lemma":"estourbir","pos":"VER"} ,
		{"word":"estrapassée","word_nosc":"estrapassee","lemma":"estrapasser","pos":"VER"} ,
		{"word":"estropiait","word_nosc":"estropiait","lemma":"estropier","pos":"VER"} ,
		{"word":"estropie","word_nosc":"estropie","lemma":"estropier","pos":"VER"} ,
		{"word":"estropier","word_nosc":"estropier","lemma":"estropier","pos":"VER"} ,
		{"word":"estropierai","word_nosc":"estropierai","lemma":"estropier","pos":"VER"} ,
		{"word":"estropierait","word_nosc":"estropierait","lemma":"estropier","pos":"VER"} ,
		{"word":"estropiez","word_nosc":"estropiez","lemma":"estropier","pos":"VER"} ,
		{"word":"estropié","word_nosc":"estropie","lemma":"estropier","pos":"VER"} ,
		{"word":"estropiée","word_nosc":"estropiee","lemma":"estropier","pos":"VER"} ,
		{"word":"estropiés","word_nosc":"estropies","lemma":"estropier","pos":"VER"} ,
		{"word":"eu","word_nosc":"eu","lemma":"avoir","pos":"VER"} ,
		{"word":"eue","word_nosc":"eue","lemma":"avoir","pos":"VER"} ,
		{"word":"eues","word_nosc":"eues","lemma":"avoir","pos":"VER"} ,
		{"word":"euphorisent","word_nosc":"euphorisent","lemma":"euphoriser","pos":"VER"} ,
		{"word":"euphorisé","word_nosc":"euphorise","lemma":"euphoriser","pos":"VER"} ,
		{"word":"euphorisée","word_nosc":"euphorisee","lemma":"euphoriser","pos":"VER"} ,
		{"word":"eurent","word_nosc":"eurent","lemma":"avoir","pos":"VER"} ,
		{"word":"eus","word_nosc":"eus","lemma":"avoir","pos":"VER"} ,
		{"word":"eusse","word_nosc":"eusse","lemma":"avoir","pos":"VER"} ,
		{"word":"eussent","word_nosc":"eussent","lemma":"avoir","pos":"VER"} ,
		{"word":"eusses","word_nosc":"eusses","lemma":"avoir","pos":"VER"} ,
		{"word":"eussiez","word_nosc":"eussiez","lemma":"avoir","pos":"VER"} ,
		{"word":"eussions","word_nosc":"eussions","lemma":"avoir","pos":"VER"} ,
		{"word":"eussé","word_nosc":"eusse","lemma":"avoir","pos":"VER"} ,
		{"word":"eut","word_nosc":"eut","lemma":"avoir","pos":"VER"} ,
		{"word":"ex-officier","word_nosc":"ex-officier","lemma":"ex-officier","pos":"VER"} ,
		{"word":"exacerba","word_nosc":"exacerba","lemma":"exacerber","pos":"VER"} ,
		{"word":"exacerbant","word_nosc":"exacerbant","lemma":"exacerber","pos":"VER"} ,
		{"word":"exacerbe","word_nosc":"exacerbe","lemma":"exacerber","pos":"VER"} ,
		{"word":"exacerbent","word_nosc":"exacerbent","lemma":"exacerber","pos":"VER"} ,
		{"word":"exacerber","word_nosc":"exacerber","lemma":"exacerber","pos":"VER"} ,
		{"word":"exacerbé","word_nosc":"exacerbe","lemma":"exacerber","pos":"VER"} ,
		{"word":"exacerbée","word_nosc":"exacerbee","lemma":"exacerber","pos":"VER"} ,
		{"word":"exacerbées","word_nosc":"exacerbees","lemma":"exacerber","pos":"VER"} ,
		{"word":"exacerbés","word_nosc":"exacerbes","lemma":"exacerber","pos":"VER"} ,
		{"word":"exagère","word_nosc":"exagere","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagèrent","word_nosc":"exagerent","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagères","word_nosc":"exageres","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagéra","word_nosc":"exagera","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagérai","word_nosc":"exagerai","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagéraient","word_nosc":"exageraient","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagérais","word_nosc":"exagerais","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagérait","word_nosc":"exagerait","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagérant","word_nosc":"exagerant","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagérer","word_nosc":"exagerer","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagérerais","word_nosc":"exagererais","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagérerait","word_nosc":"exagererait","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagérerons","word_nosc":"exagererons","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagérez","word_nosc":"exagerez","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagérions","word_nosc":"exagerions","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagérons","word_nosc":"exagerons","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagérèrent","word_nosc":"exagererent","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagéré","word_nosc":"exagere","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagérée","word_nosc":"exageree","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagérées","word_nosc":"exagerees","lemma":"exagérer","pos":"VER"} ,
		{"word":"exagérés","word_nosc":"exageres","lemma":"exagérer","pos":"VER"} ,
		{"word":"exalta","word_nosc":"exalta","lemma":"exalter","pos":"VER"} ,
		{"word":"exaltai","word_nosc":"exaltai","lemma":"exalter","pos":"VER"} ,
		{"word":"exaltaient","word_nosc":"exaltaient","lemma":"exalter","pos":"VER"} ,
		{"word":"exaltais","word_nosc":"exaltais","lemma":"exalter","pos":"VER"} ,
		{"word":"exaltait","word_nosc":"exaltait","lemma":"exalter","pos":"VER"} ,
		{"word":"exaltant","word_nosc":"exaltant","lemma":"exalter","pos":"VER"} ,
		{"word":"exalte","word_nosc":"exalte","lemma":"exalter","pos":"VER"} ,
		{"word":"exaltent","word_nosc":"exaltent","lemma":"exalter","pos":"VER"} ,
		{"word":"exalter","word_nosc":"exalter","lemma":"exalter","pos":"VER"} ,
		{"word":"exalterait","word_nosc":"exalterait","lemma":"exalter","pos":"VER"} ,
		{"word":"exaltes","word_nosc":"exaltes","lemma":"exalter","pos":"VER"} ,
		{"word":"exaltons","word_nosc":"exaltons","lemma":"exalter","pos":"VER"} ,
		{"word":"exaltèrent","word_nosc":"exalterent","lemma":"exalter","pos":"VER"} ,
		{"word":"exalté","word_nosc":"exalte","lemma":"exalter","pos":"VER"} ,
		{"word":"exaltée","word_nosc":"exaltee","lemma":"exalter","pos":"VER"} ,
		{"word":"exaltées","word_nosc":"exaltees","lemma":"exalter","pos":"VER"} ,
		{"word":"exaltés","word_nosc":"exaltes","lemma":"exalter","pos":"VER"} ,
		{"word":"examina","word_nosc":"examina","lemma":"examiner","pos":"VER"} ,
		{"word":"examinai","word_nosc":"examinai","lemma":"examiner","pos":"VER"} ,
		{"word":"examinaient","word_nosc":"examinaient","lemma":"examiner","pos":"VER"} ,
		{"word":"examinais","word_nosc":"examinais","lemma":"examiner","pos":"VER"} ,
		{"word":"examinait","word_nosc":"examinait","lemma":"examiner","pos":"VER"} ,
		{"word":"examinant","word_nosc":"examinant","lemma":"examiner","pos":"VER"} ,
		{"word":"examine","word_nosc":"examine","lemma":"examiner","pos":"VER"} ,
		{"word":"examinent","word_nosc":"examinent","lemma":"examiner","pos":"VER"} ,
		{"word":"examiner","word_nosc":"examiner","lemma":"examiner","pos":"VER"} ,
		{"word":"examinera","word_nosc":"examinera","lemma":"examiner","pos":"VER"} ,
		{"word":"examinerai","word_nosc":"examinerai","lemma":"examiner","pos":"VER"} ,
		{"word":"examineraient","word_nosc":"examineraient","lemma":"examiner","pos":"VER"} ,
		{"word":"examinerais","word_nosc":"examinerais","lemma":"examiner","pos":"VER"} ,
		{"word":"examinerait","word_nosc":"examinerait","lemma":"examiner","pos":"VER"} ,
		{"word":"examinerez","word_nosc":"examinerez","lemma":"examiner","pos":"VER"} ,
		{"word":"examineriez","word_nosc":"examineriez","lemma":"examiner","pos":"VER"} ,
		{"word":"examinerons","word_nosc":"examinerons","lemma":"examiner","pos":"VER"} ,
		{"word":"examineront","word_nosc":"examineront","lemma":"examiner","pos":"VER"} ,
		{"word":"examines","word_nosc":"examines","lemma":"examiner","pos":"VER"} ,
		{"word":"examinez","word_nosc":"examinez","lemma":"examiner","pos":"VER"} ,
		{"word":"examiniez","word_nosc":"examiniez","lemma":"examiner","pos":"VER"} ,
		{"word":"examinions","word_nosc":"examinions","lemma":"examiner","pos":"VER"} ,
		{"word":"examinons","word_nosc":"examinons","lemma":"examiner","pos":"VER"} ,
		{"word":"examinèrent","word_nosc":"examinerent","lemma":"examiner","pos":"VER"} ,
		{"word":"examiné","word_nosc":"examine","lemma":"examiner","pos":"VER"} ,
		{"word":"examinée","word_nosc":"examinee","lemma":"examiner","pos":"VER"} ,
		{"word":"examinées","word_nosc":"examinees","lemma":"examiner","pos":"VER"} ,
		{"word":"examinés","word_nosc":"examines","lemma":"examiner","pos":"VER"} ,
		{"word":"exaspère","word_nosc":"exaspere","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspèrent","word_nosc":"exasperent","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspéra","word_nosc":"exaspera","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspéraient","word_nosc":"exasperaient","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspérais","word_nosc":"exasperais","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspérait","word_nosc":"exasperait","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspérant","word_nosc":"exasperant","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspérer","word_nosc":"exasperer","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspérera","word_nosc":"exasperera","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspéreraient","word_nosc":"exaspereraient","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspérerait","word_nosc":"exaspererait","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspérez","word_nosc":"exasperez","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspérât","word_nosc":"exasperat","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspérèrent","word_nosc":"exaspererent","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspéré","word_nosc":"exaspere","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspérée","word_nosc":"exasperee","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspérées","word_nosc":"exasperees","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exaspérés","word_nosc":"exasperes","lemma":"exaspérer","pos":"VER"} ,
		{"word":"exauce","word_nosc":"exauce","lemma":"exaucer","pos":"VER"} ,
		{"word":"exaucent","word_nosc":"exaucent","lemma":"exaucer","pos":"VER"} ,
		{"word":"exaucer","word_nosc":"exaucer","lemma":"exaucer","pos":"VER"} ,
		{"word":"exaucera","word_nosc":"exaucera","lemma":"exaucer","pos":"VER"} ,
		{"word":"exaucerai","word_nosc":"exaucerai","lemma":"exaucer","pos":"VER"} ,
		{"word":"exaucez","word_nosc":"exaucez","lemma":"exaucer","pos":"VER"} ,
		{"word":"exaucé","word_nosc":"exauce","lemma":"exaucer","pos":"VER"} ,
		{"word":"exaucée","word_nosc":"exaucee","lemma":"exaucer","pos":"VER"} ,
		{"word":"exaucées","word_nosc":"exaucees","lemma":"exaucer","pos":"VER"} ,
		{"word":"exaucés","word_nosc":"exauces","lemma":"exaucer","pos":"VER"} ,
		{"word":"exauça","word_nosc":"exauca","lemma":"exaucer","pos":"VER"} ,
		{"word":"exauçai","word_nosc":"exaucai","lemma":"exaucer","pos":"VER"} ,
		{"word":"exauçaient","word_nosc":"exaucaient","lemma":"exaucer","pos":"VER"} ,
		{"word":"exauçant","word_nosc":"exaucant","lemma":"exaucer","pos":"VER"} ,
		{"word":"exauçons","word_nosc":"exaucons","lemma":"exaucer","pos":"VER"} ,
		{"word":"exauçât","word_nosc":"exaucat","lemma":"exaucer","pos":"VER"} ,
		{"word":"excaver","word_nosc":"excaver","lemma":"excaver","pos":"VER"} ,
		{"word":"excavée","word_nosc":"excavee","lemma":"excaver","pos":"VER"} ,
		{"word":"excavées","word_nosc":"excavees","lemma":"excaver","pos":"VER"} ,
		{"word":"excella","word_nosc":"excella","lemma":"exceller","pos":"VER"} ,
		{"word":"excellaient","word_nosc":"excellaient","lemma":"exceller","pos":"VER"} ,
		{"word":"excellais","word_nosc":"excellais","lemma":"exceller","pos":"VER"} ,
		{"word":"excellait","word_nosc":"excellait","lemma":"exceller","pos":"VER"} ,
		{"word":"excellant","word_nosc":"excellant","lemma":"exceller","pos":"VER"} ,
		{"word":"excelle","word_nosc":"excelle","lemma":"exceller","pos":"VER"} ,
		{"word":"excellent","word_nosc":"excellent","lemma":"exceller","pos":"VER"} ,
		{"word":"exceller","word_nosc":"exceller","lemma":"exceller","pos":"VER"} ,
		{"word":"excelleraient","word_nosc":"excelleraient","lemma":"exceller","pos":"VER"} ,
		{"word":"excelles","word_nosc":"excelles","lemma":"exceller","pos":"VER"} ,
		{"word":"excellez","word_nosc":"excellez","lemma":"exceller","pos":"VER"} ,
		{"word":"excellé","word_nosc":"excelle","lemma":"exceller","pos":"VER"} ,
		{"word":"excentrer","word_nosc":"excentrer","lemma":"excentrer","pos":"VER"} ,
		{"word":"excentré","word_nosc":"excentre","lemma":"excentrer","pos":"VER"} ,
		{"word":"exceptais","word_nosc":"exceptais","lemma":"excepter","pos":"VER"} ,
		{"word":"exceptait","word_nosc":"exceptait","lemma":"excepter","pos":"VER"} ,
		{"word":"exceptant","word_nosc":"exceptant","lemma":"excepter","pos":"VER"} ,
		{"word":"excepte","word_nosc":"excepte","lemma":"excepter","pos":"VER"} ,
		{"word":"excepter","word_nosc":"excepter","lemma":"excepter","pos":"VER"} ,
		{"word":"exceptes","word_nosc":"exceptes","lemma":"excepter","pos":"VER"} ,
		{"word":"exceptions","word_nosc":"exceptions","lemma":"excepter","pos":"VER"} ,
		{"word":"excepté","word_nosc":"excepte","lemma":"excepter","pos":"VER"} ,
		{"word":"exceptée","word_nosc":"exceptee","lemma":"excepter","pos":"VER"} ,
		{"word":"exceptées","word_nosc":"exceptees","lemma":"excepter","pos":"VER"} ,
		{"word":"exceptés","word_nosc":"exceptes","lemma":"excepter","pos":"VER"} ,
		{"word":"excipent","word_nosc":"excipent","lemma":"exciper","pos":"VER"} ,
		{"word":"exciper","word_nosc":"exciper","lemma":"exciper","pos":"VER"} ,
		{"word":"excisant","word_nosc":"excisant","lemma":"exciser","pos":"VER"} ,
		{"word":"excise","word_nosc":"excise","lemma":"exciser","pos":"VER"} ,
		{"word":"exciser","word_nosc":"exciser","lemma":"exciser","pos":"VER"} ,
		{"word":"excisé","word_nosc":"excise","lemma":"exciser","pos":"VER"} ,
		{"word":"excisée","word_nosc":"excisee","lemma":"exciser","pos":"VER"} ,
		{"word":"excisées","word_nosc":"excisees","lemma":"exciser","pos":"VER"} ,
		{"word":"excita","word_nosc":"excita","lemma":"exciter","pos":"VER"} ,
		{"word":"excitaient","word_nosc":"excitaient","lemma":"exciter","pos":"VER"} ,
		{"word":"excitais","word_nosc":"excitais","lemma":"exciter","pos":"VER"} ,
		{"word":"excitait","word_nosc":"excitait","lemma":"exciter","pos":"VER"} ,
		{"word":"excitant","word_nosc":"excitant","lemma":"exciter","pos":"VER"} ,
		{"word":"excite","word_nosc":"excite","lemma":"exciter","pos":"VER"} ,
		{"word":"excitent","word_nosc":"excitent","lemma":"exciter","pos":"VER"} ,
		{"word":"exciter","word_nosc":"exciter","lemma":"exciter","pos":"VER"} ,
		{"word":"excitera","word_nosc":"excitera","lemma":"exciter","pos":"VER"} ,
		{"word":"exciterait","word_nosc":"exciterait","lemma":"exciter","pos":"VER"} ,
		{"word":"exciterez","word_nosc":"exciterez","lemma":"exciter","pos":"VER"} ,
		{"word":"exciteriez","word_nosc":"exciteriez","lemma":"exciter","pos":"VER"} ,
		{"word":"exciteront","word_nosc":"exciteront","lemma":"exciter","pos":"VER"} ,
		{"word":"excites","word_nosc":"excites","lemma":"exciter","pos":"VER"} ,
		{"word":"excitez","word_nosc":"excitez","lemma":"exciter","pos":"VER"} ,
		{"word":"excitions","word_nosc":"excitions","lemma":"exciter","pos":"VER"} ,
		{"word":"excitons","word_nosc":"excitons","lemma":"exciter","pos":"VER"} ,
		{"word":"excitèrent","word_nosc":"exciterent","lemma":"exciter","pos":"VER"} ,
		{"word":"excité","word_nosc":"excite","lemma":"exciter","pos":"VER"} ,
		{"word":"excitée","word_nosc":"excitee","lemma":"exciter","pos":"VER"} ,
		{"word":"excitées","word_nosc":"excitees","lemma":"exciter","pos":"VER"} ,
		{"word":"excités","word_nosc":"excites","lemma":"exciter","pos":"VER"} ,
		{"word":"exclama","word_nosc":"exclama","lemma":"exclamer","pos":"VER"} ,
		{"word":"exclamai","word_nosc":"exclamai","lemma":"exclamer","pos":"VER"} ,
		{"word":"exclamaient","word_nosc":"exclamaient","lemma":"exclamer","pos":"VER"} ,
		{"word":"exclamais","word_nosc":"exclamais","lemma":"exclamer","pos":"VER"} ,
		{"word":"exclamait","word_nosc":"exclamait","lemma":"exclamer","pos":"VER"} ,
		{"word":"exclamant","word_nosc":"exclamant","lemma":"exclamer","pos":"VER"} ,
		{"word":"exclame","word_nosc":"exclame","lemma":"exclamer","pos":"VER"} ,
		{"word":"exclament","word_nosc":"exclament","lemma":"exclamer","pos":"VER"} ,
		{"word":"exclamer","word_nosc":"exclamer","lemma":"exclamer","pos":"VER"} ,
		{"word":"exclameraient","word_nosc":"exclameraient","lemma":"exclamer","pos":"VER"} ,
		{"word":"exclamerait","word_nosc":"exclamerait","lemma":"exclamer","pos":"VER"} ,
		{"word":"exclamâmes","word_nosc":"exclamames","lemma":"exclamer","pos":"VER"} ,
		{"word":"exclamèrent","word_nosc":"exclamerent","lemma":"exclamer","pos":"VER"} ,
		{"word":"exclamé","word_nosc":"exclame","lemma":"exclamer","pos":"VER"} ,
		{"word":"exclamée","word_nosc":"exclamee","lemma":"exclamer","pos":"VER"} ,
		{"word":"exclu","word_nosc":"exclu","lemma":"exclure","pos":"VER"} ,
		{"word":"excluaient","word_nosc":"excluaient","lemma":"exclure","pos":"VER"} ,
		{"word":"excluais","word_nosc":"excluais","lemma":"exclure","pos":"VER"} ,
		{"word":"excluait","word_nosc":"excluait","lemma":"exclure","pos":"VER"} ,
		{"word":"excluant","word_nosc":"excluant","lemma":"exclure","pos":"VER"} ,
		{"word":"exclue","word_nosc":"exclue","lemma":"exclure","pos":"VER"} ,
		{"word":"excluent","word_nosc":"excluent","lemma":"exclure","pos":"VER"} ,
		{"word":"exclues","word_nosc":"exclues","lemma":"exclure","pos":"VER"} ,
		{"word":"excluez","word_nosc":"excluez","lemma":"exclure","pos":"VER"} ,
		{"word":"excluions","word_nosc":"excluions","lemma":"exclure","pos":"VER"} ,
		{"word":"excluons","word_nosc":"excluons","lemma":"exclure","pos":"VER"} ,
		{"word":"exclura","word_nosc":"exclura","lemma":"exclure","pos":"VER"} ,
		{"word":"exclurais","word_nosc":"exclurais","lemma":"exclure","pos":"VER"} ,
		{"word":"exclure","word_nosc":"exclure","lemma":"exclure","pos":"VER"} ,
		{"word":"exclus","word_nosc":"exclus","lemma":"exclure","pos":"VER"} ,
		{"word":"exclut","word_nosc":"exclut","lemma":"exclure","pos":"VER"} ,
		{"word":"exclût","word_nosc":"exclut","lemma":"exclure","pos":"VER"} ,
		{"word":"excommuniaient","word_nosc":"excommuniaient","lemma":"excommunier","pos":"VER"} ,
		{"word":"excommuniait","word_nosc":"excommuniait","lemma":"excommunier","pos":"VER"} ,
		{"word":"excommuniant","word_nosc":"excommuniant","lemma":"excommunier","pos":"VER"} ,
		{"word":"excommunie","word_nosc":"excommunie","lemma":"excommunier","pos":"VER"} ,
		{"word":"excommunient","word_nosc":"excommunient","lemma":"excommunier","pos":"VER"} ,
		{"word":"excommunier","word_nosc":"excommunier","lemma":"excommunier","pos":"VER"} ,
		{"word":"excommuniez","word_nosc":"excommuniez","lemma":"excommunier","pos":"VER"} ,
		{"word":"excommunié","word_nosc":"excommunie","lemma":"excommunier","pos":"VER"} ,
		{"word":"excommuniée","word_nosc":"excommuniee","lemma":"excommunier","pos":"VER"} ,
		{"word":"excommuniés","word_nosc":"excommunies","lemma":"excommunier","pos":"VER"} ,
		{"word":"excoriée","word_nosc":"excoriee","lemma":"excorier","pos":"VER"} ,
		{"word":"excoriées","word_nosc":"excoriees","lemma":"excorier","pos":"VER"} ,
		{"word":"excréter","word_nosc":"excreter","lemma":"excréter","pos":"VER"} ,
		{"word":"excrétera","word_nosc":"excretera","lemma":"excréter","pos":"VER"} ,
		{"word":"excrété","word_nosc":"excrete","lemma":"excréter","pos":"VER"} ,
		{"word":"excursionnaient","word_nosc":"excursionnaient","lemma":"excursionner","pos":"VER"} ,
		{"word":"excursionnant","word_nosc":"excursionnant","lemma":"excursionner","pos":"VER"} ,
		{"word":"excursionner","word_nosc":"excursionner","lemma":"excursionner","pos":"VER"} ,
		{"word":"excusa","word_nosc":"excusa","lemma":"excuser","pos":"VER"} ,
		{"word":"excusai","word_nosc":"excusai","lemma":"excuser","pos":"VER"} ,
		{"word":"excusaient","word_nosc":"excusaient","lemma":"excuser","pos":"VER"} ,
		{"word":"excusais","word_nosc":"excusais","lemma":"excuser","pos":"VER"} ,
		{"word":"excusait","word_nosc":"excusait","lemma":"excuser","pos":"VER"} ,
		{"word":"excusant","word_nosc":"excusant","lemma":"excuser","pos":"VER"} ,
		{"word":"excuse","word_nosc":"excuse","lemma":"excuser","pos":"VER"} ,
		{"word":"excusent","word_nosc":"excusent","lemma":"excuser","pos":"VER"} ,
		{"word":"excuser","word_nosc":"excuser","lemma":"excuser","pos":"VER"} ,
		{"word":"excusera","word_nosc":"excusera","lemma":"excuser","pos":"VER"} ,
		{"word":"excuserai","word_nosc":"excuserai","lemma":"excuser","pos":"VER"} ,
		{"word":"excuserais","word_nosc":"excuserais","lemma":"excuser","pos":"VER"} ,
		{"word":"excuserait","word_nosc":"excuserait","lemma":"excuser","pos":"VER"} ,
		{"word":"excuseras","word_nosc":"excuseras","lemma":"excuser","pos":"VER"} ,
		{"word":"excuserez","word_nosc":"excuserez","lemma":"excuser","pos":"VER"} ,
		{"word":"excuseriez","word_nosc":"excuseriez","lemma":"excuser","pos":"VER"} ,
		{"word":"excuserons","word_nosc":"excuserons","lemma":"excuser","pos":"VER"} ,
		{"word":"excuseront","word_nosc":"excuseront","lemma":"excuser","pos":"VER"} ,
		{"word":"excuses","word_nosc":"excuses","lemma":"excuser","pos":"VER"} ,
		{"word":"excusez","word_nosc":"excusez","lemma":"excuser","pos":"VER"} ,
		{"word":"excusiez","word_nosc":"excusiez","lemma":"excuser","pos":"VER"} ,
		{"word":"excusions","word_nosc":"excusions","lemma":"excuser","pos":"VER"} ,
		{"word":"excusons","word_nosc":"excusons","lemma":"excuser","pos":"VER"} ,
		{"word":"excusâmes","word_nosc":"excusames","lemma":"excuser","pos":"VER"} ,
		{"word":"excusât","word_nosc":"excusat","lemma":"excuser","pos":"VER"} ,
		{"word":"excusèrent","word_nosc":"excuserent","lemma":"excuser","pos":"VER"} ,
		{"word":"excusé","word_nosc":"excuse","lemma":"excuser","pos":"VER"} ,
		{"word":"excusée","word_nosc":"excusee","lemma":"excuser","pos":"VER"} ,
		{"word":"excusées","word_nosc":"excusees","lemma":"excuser","pos":"VER"} ,
		{"word":"excusés","word_nosc":"excuses","lemma":"excuser","pos":"VER"} ,
		{"word":"excède","word_nosc":"excede","lemma":"excéder","pos":"VER"} ,
		{"word":"excèdent","word_nosc":"excedent","lemma":"excéder","pos":"VER"} ,
		{"word":"excédaient","word_nosc":"excedaient","lemma":"excéder","pos":"VER"} ,
		{"word":"excédais","word_nosc":"excedais","lemma":"excéder","pos":"VER"} ,
		{"word":"excédait","word_nosc":"excedait","lemma":"excéder","pos":"VER"} ,
		{"word":"excédant","word_nosc":"excedant","lemma":"excéder","pos":"VER"} ,
		{"word":"excéder","word_nosc":"exceder","lemma":"excéder","pos":"VER"} ,
		{"word":"excédera","word_nosc":"excedera","lemma":"excéder","pos":"VER"} ,
		{"word":"excédât","word_nosc":"excedat","lemma":"excéder","pos":"VER"} ,
		{"word":"excédé","word_nosc":"excede","lemma":"excéder","pos":"VER"} ,
		{"word":"excédée","word_nosc":"excedee","lemma":"excéder","pos":"VER"} ,
		{"word":"excédées","word_nosc":"excedees","lemma":"excéder","pos":"VER"} ,
		{"word":"excédés","word_nosc":"excedes","lemma":"excéder","pos":"VER"} ,
		{"word":"exemplifier","word_nosc":"exemplifier","lemma":"exemplifier","pos":"VER"} ,
		{"word":"exempte","word_nosc":"exempte","lemma":"exempter","pos":"VER"} ,
		{"word":"exempter","word_nosc":"exempter","lemma":"exempter","pos":"VER"} ,
		{"word":"exemptiez","word_nosc":"exemptiez","lemma":"exempter","pos":"VER"} ,
		{"word":"exempté","word_nosc":"exempte","lemma":"exempter","pos":"VER"} ,
		{"word":"exemptée","word_nosc":"exemptee","lemma":"exempter","pos":"VER"} ,
		{"word":"exemptés","word_nosc":"exemptes","lemma":"exempter","pos":"VER"} ,
		{"word":"exerce","word_nosc":"exerce","lemma":"exercer","pos":"VER"} ,
		{"word":"exercent","word_nosc":"exercent","lemma":"exercer","pos":"VER"} ,
		{"word":"exercer","word_nosc":"exercer","lemma":"exercer","pos":"VER"} ,
		{"word":"exercera","word_nosc":"exercera","lemma":"exercer","pos":"VER"} ,
		{"word":"exercerai","word_nosc":"exercerai","lemma":"exercer","pos":"VER"} ,
		{"word":"exerceraient","word_nosc":"exerceraient","lemma":"exercer","pos":"VER"} ,
		{"word":"exercerais","word_nosc":"exercerais","lemma":"exercer","pos":"VER"} ,
		{"word":"exercerait","word_nosc":"exercerait","lemma":"exercer","pos":"VER"} ,
		{"word":"exercerez","word_nosc":"exercerez","lemma":"exercer","pos":"VER"} ,
		{"word":"exercerons","word_nosc":"exercerons","lemma":"exercer","pos":"VER"} ,
		{"word":"exerceront","word_nosc":"exerceront","lemma":"exercer","pos":"VER"} ,
		{"word":"exerces","word_nosc":"exerces","lemma":"exercer","pos":"VER"} ,
		{"word":"exercez","word_nosc":"exercez","lemma":"exercer","pos":"VER"} ,
		{"word":"exerciez","word_nosc":"exerciez","lemma":"exercer","pos":"VER"} ,
		{"word":"exercèrent","word_nosc":"exercerent","lemma":"exercer","pos":"VER"} ,
		{"word":"exercé","word_nosc":"exerce","lemma":"exercer","pos":"VER"} ,
		{"word":"exercée","word_nosc":"exercee","lemma":"exercer","pos":"VER"} ,
		{"word":"exercées","word_nosc":"exercees","lemma":"exercer","pos":"VER"} ,
		{"word":"exercés","word_nosc":"exerces","lemma":"exercer","pos":"VER"} ,
		{"word":"exerça","word_nosc":"exerca","lemma":"exercer","pos":"VER"} ,
		{"word":"exerçai","word_nosc":"exercai","lemma":"exercer","pos":"VER"} ,
		{"word":"exerçaient","word_nosc":"exercaient","lemma":"exercer","pos":"VER"} ,
		{"word":"exerçais","word_nosc":"exercais","lemma":"exercer","pos":"VER"} ,
		{"word":"exerçait","word_nosc":"exercait","lemma":"exercer","pos":"VER"} ,
		{"word":"exerçant","word_nosc":"exercant","lemma":"exercer","pos":"VER"} ,
		{"word":"exerçassent","word_nosc":"exercassent","lemma":"exercer","pos":"VER"} ,
		{"word":"exerçons","word_nosc":"exercons","lemma":"exercer","pos":"VER"} ,
		{"word":"exerçât","word_nosc":"exercat","lemma":"exercer","pos":"VER"} ,
		{"word":"exfiltrer","word_nosc":"exfiltrer","lemma":"exfiltrer","pos":"VER"} ,
		{"word":"exfiltré","word_nosc":"exfiltre","lemma":"exfiltrer","pos":"VER"} ,
		{"word":"exfolier","word_nosc":"exfolier","lemma":"exfolier","pos":"VER"} ,
		{"word":"exfolions","word_nosc":"exfolions","lemma":"exfolier","pos":"VER"} ,
		{"word":"exfoliée","word_nosc":"exfoliee","lemma":"exfolier","pos":"VER"} ,
		{"word":"exhala","word_nosc":"exhala","lemma":"exhaler","pos":"VER"} ,
		{"word":"exhalaient","word_nosc":"exhalaient","lemma":"exhaler","pos":"VER"} ,
		{"word":"exhalait","word_nosc":"exhalait","lemma":"exhaler","pos":"VER"} ,
		{"word":"exhalant","word_nosc":"exhalant","lemma":"exhaler","pos":"VER"} ,
		{"word":"exhale","word_nosc":"exhale","lemma":"exhaler","pos":"VER"} ,
		{"word":"exhalent","word_nosc":"exhalent","lemma":"exhaler","pos":"VER"} ,
		{"word":"exhaler","word_nosc":"exhaler","lemma":"exhaler","pos":"VER"} ,
		{"word":"exhaleraient","word_nosc":"exhaleraient","lemma":"exhaler","pos":"VER"} ,
		{"word":"exhalâmes","word_nosc":"exhalames","lemma":"exhaler","pos":"VER"} ,
		{"word":"exhalât","word_nosc":"exhalat","lemma":"exhaler","pos":"VER"} ,
		{"word":"exhalé","word_nosc":"exhale","lemma":"exhaler","pos":"VER"} ,
		{"word":"exhalée","word_nosc":"exhalee","lemma":"exhaler","pos":"VER"} ,
		{"word":"exhaussait","word_nosc":"exhaussait","lemma":"exhausser","pos":"VER"} ,
		{"word":"exhausse","word_nosc":"exhausse","lemma":"exhausser","pos":"VER"} ,
		{"word":"exhausser","word_nosc":"exhausser","lemma":"exhausser","pos":"VER"} ,
		{"word":"exhaussé","word_nosc":"exhausse","lemma":"exhausser","pos":"VER"} ,
		{"word":"exhaussée","word_nosc":"exhaussee","lemma":"exhausser","pos":"VER"} ,
		{"word":"exhiba","word_nosc":"exhiba","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhibai","word_nosc":"exhibai","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhibaient","word_nosc":"exhibaient","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhibais","word_nosc":"exhibais","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhibait","word_nosc":"exhibait","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhibant","word_nosc":"exhibant","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhibe","word_nosc":"exhibe","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhibent","word_nosc":"exhibent","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhiber","word_nosc":"exhiber","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhibera","word_nosc":"exhibera","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhiberait","word_nosc":"exhiberait","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhiberas","word_nosc":"exhiberas","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhibez","word_nosc":"exhibez","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhibions","word_nosc":"exhibions","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhibé","word_nosc":"exhibe","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhibée","word_nosc":"exhibee","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhibées","word_nosc":"exhibees","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhibés","word_nosc":"exhibes","lemma":"exhiber","pos":"VER"} ,
		{"word":"exhorta","word_nosc":"exhorta","lemma":"exhorter","pos":"VER"} ,
		{"word":"exhortai","word_nosc":"exhortai","lemma":"exhorter","pos":"VER"} ,
		{"word":"exhortaient","word_nosc":"exhortaient","lemma":"exhorter","pos":"VER"} ,
		{"word":"exhortais","word_nosc":"exhortais","lemma":"exhorter","pos":"VER"} ,
		{"word":"exhortait","word_nosc":"exhortait","lemma":"exhorter","pos":"VER"} ,
		{"word":"exhortant","word_nosc":"exhortant","lemma":"exhorter","pos":"VER"} ,
		{"word":"exhorte","word_nosc":"exhorte","lemma":"exhorter","pos":"VER"} ,
		{"word":"exhortent","word_nosc":"exhortent","lemma":"exhorter","pos":"VER"} ,
		{"word":"exhorter","word_nosc":"exhorter","lemma":"exhorter","pos":"VER"} ,
		{"word":"exhortions","word_nosc":"exhortions","lemma":"exhorter","pos":"VER"} ,
		{"word":"exhortons","word_nosc":"exhortons","lemma":"exhorter","pos":"VER"} ,
		{"word":"exhortâmes","word_nosc":"exhortames","lemma":"exhorter","pos":"VER"} ,
		{"word":"exhorté","word_nosc":"exhorte","lemma":"exhorter","pos":"VER"} ,
		{"word":"exhuma","word_nosc":"exhuma","lemma":"exhumer","pos":"VER"} ,
		{"word":"exhumaient","word_nosc":"exhumaient","lemma":"exhumer","pos":"VER"} ,
		{"word":"exhumais","word_nosc":"exhumais","lemma":"exhumer","pos":"VER"} ,
		{"word":"exhumait","word_nosc":"exhumait","lemma":"exhumer","pos":"VER"} ,
		{"word":"exhumant","word_nosc":"exhumant","lemma":"exhumer","pos":"VER"} ,
		{"word":"exhume","word_nosc":"exhume","lemma":"exhumer","pos":"VER"} ,
		{"word":"exhumer","word_nosc":"exhumer","lemma":"exhumer","pos":"VER"} ,
		{"word":"exhumera","word_nosc":"exhumera","lemma":"exhumer","pos":"VER"} ,
		{"word":"exhumeront","word_nosc":"exhumeront","lemma":"exhumer","pos":"VER"} ,
		{"word":"exhumé","word_nosc":"exhume","lemma":"exhumer","pos":"VER"} ,
		{"word":"exhumée","word_nosc":"exhumee","lemma":"exhumer","pos":"VER"} ,
		{"word":"exhumées","word_nosc":"exhumees","lemma":"exhumer","pos":"VER"} ,
		{"word":"exhumés","word_nosc":"exhumes","lemma":"exhumer","pos":"VER"} ,
		{"word":"exige","word_nosc":"exige","lemma":"exiger","pos":"VER"} ,
		{"word":"exigea","word_nosc":"exigea","lemma":"exiger","pos":"VER"} ,
		{"word":"exigeai","word_nosc":"exigeai","lemma":"exiger","pos":"VER"} ,
		{"word":"exigeaient","word_nosc":"exigeaient","lemma":"exiger","pos":"VER"} ,
		{"word":"exigeais","word_nosc":"exigeais","lemma":"exiger","pos":"VER"} ,
		{"word":"exigeait","word_nosc":"exigeait","lemma":"exiger","pos":"VER"} ,
		{"word":"exigeant","word_nosc":"exigeant","lemma":"exiger","pos":"VER"} ,
		{"word":"exigeassent","word_nosc":"exigeassent","lemma":"exiger","pos":"VER"} ,
		{"word":"exigent","word_nosc":"exigent","lemma":"exiger","pos":"VER"} ,
		{"word":"exigeons","word_nosc":"exigeons","lemma":"exiger","pos":"VER"} ,
		{"word":"exiger","word_nosc":"exiger","lemma":"exiger","pos":"VER"} ,
		{"word":"exigera","word_nosc":"exigera","lemma":"exiger","pos":"VER"} ,
		{"word":"exigerai","word_nosc":"exigerai","lemma":"exiger","pos":"VER"} ,
		{"word":"exigeraient","word_nosc":"exigeraient","lemma":"exiger","pos":"VER"} ,
		{"word":"exigerais","word_nosc":"exigerais","lemma":"exiger","pos":"VER"} ,
		{"word":"exigerait","word_nosc":"exigerait","lemma":"exiger","pos":"VER"} ,
		{"word":"exigerez","word_nosc":"exigerez","lemma":"exiger","pos":"VER"} ,
		{"word":"exigeriez","word_nosc":"exigeriez","lemma":"exiger","pos":"VER"} ,
		{"word":"exigerions","word_nosc":"exigerions","lemma":"exiger","pos":"VER"} ,
		{"word":"exigerons","word_nosc":"exigerons","lemma":"exiger","pos":"VER"} ,
		{"word":"exigeront","word_nosc":"exigeront","lemma":"exiger","pos":"VER"} ,
		{"word":"exiges","word_nosc":"exiges","lemma":"exiger","pos":"VER"} ,
		{"word":"exigez","word_nosc":"exigez","lemma":"exiger","pos":"VER"} ,
		{"word":"exigiez","word_nosc":"exigiez","lemma":"exiger","pos":"VER"} ,
		{"word":"exigèrent","word_nosc":"exigerent","lemma":"exiger","pos":"VER"} ,
		{"word":"exigé","word_nosc":"exige","lemma":"exiger","pos":"VER"} ,
		{"word":"exigée","word_nosc":"exigee","lemma":"exiger","pos":"VER"} ,
		{"word":"exigées","word_nosc":"exigees","lemma":"exiger","pos":"VER"} ,
		{"word":"exigés","word_nosc":"exiges","lemma":"exiger","pos":"VER"} ,
		{"word":"exila","word_nosc":"exila","lemma":"exiler","pos":"VER"} ,
		{"word":"exilaient","word_nosc":"exilaient","lemma":"exiler","pos":"VER"} ,
		{"word":"exilait","word_nosc":"exilait","lemma":"exiler","pos":"VER"} ,
		{"word":"exilant","word_nosc":"exilant","lemma":"exiler","pos":"VER"} ,
		{"word":"exile","word_nosc":"exile","lemma":"exiler","pos":"VER"} ,
		{"word":"exilent","word_nosc":"exilent","lemma":"exiler","pos":"VER"} ,
		{"word":"exiler","word_nosc":"exiler","lemma":"exiler","pos":"VER"} ,
		{"word":"exiles","word_nosc":"exiles","lemma":"exiler","pos":"VER"} ,
		{"word":"exilez","word_nosc":"exilez","lemma":"exiler","pos":"VER"} ,
		{"word":"exilât","word_nosc":"exilat","lemma":"exiler","pos":"VER"} ,
		{"word":"exilé","word_nosc":"exile","lemma":"exiler","pos":"VER"} ,
		{"word":"exilée","word_nosc":"exilee","lemma":"exiler","pos":"VER"} ,
		{"word":"exilées","word_nosc":"exilees","lemma":"exiler","pos":"VER"} ,
		{"word":"exilés","word_nosc":"exiles","lemma":"exiler","pos":"VER"} ,
		{"word":"exista","word_nosc":"exista","lemma":"exister","pos":"VER"} ,
		{"word":"existai","word_nosc":"existai","lemma":"exister","pos":"VER"} ,
		{"word":"existaient","word_nosc":"existaient","lemma":"exister","pos":"VER"} ,
		{"word":"existais","word_nosc":"existais","lemma":"exister","pos":"VER"} ,
		{"word":"existait","word_nosc":"existait","lemma":"exister","pos":"VER"} ,
		{"word":"existant","word_nosc":"existant","lemma":"exister","pos":"VER"} ,
		{"word":"existe","word_nosc":"existe","lemma":"exister","pos":"VER"} ,
		{"word":"existent","word_nosc":"existent","lemma":"exister","pos":"VER"} ,
		{"word":"exister","word_nosc":"exister","lemma":"exister","pos":"VER"} ,
		{"word":"existera","word_nosc":"existera","lemma":"exister","pos":"VER"} ,
		{"word":"existerai","word_nosc":"existerai","lemma":"exister","pos":"VER"} ,
		{"word":"existeraient","word_nosc":"existeraient","lemma":"exister","pos":"VER"} ,
		{"word":"existerais","word_nosc":"existerais","lemma":"exister","pos":"VER"} ,
		{"word":"existerait","word_nosc":"existerait","lemma":"exister","pos":"VER"} ,
		{"word":"existeras","word_nosc":"existeras","lemma":"exister","pos":"VER"} ,
		{"word":"existeriez","word_nosc":"existeriez","lemma":"exister","pos":"VER"} ,
		{"word":"existerions","word_nosc":"existerions","lemma":"exister","pos":"VER"} ,
		{"word":"existerons","word_nosc":"existerons","lemma":"exister","pos":"VER"} ,
		{"word":"existeront","word_nosc":"existeront","lemma":"exister","pos":"VER"} ,
		{"word":"existes","word_nosc":"existes","lemma":"exister","pos":"VER"} ,
		{"word":"existez","word_nosc":"existez","lemma":"exister","pos":"VER"} ,
		{"word":"existiez","word_nosc":"existiez","lemma":"exister","pos":"VER"} ,
		{"word":"existions","word_nosc":"existions","lemma":"exister","pos":"VER"} ,
		{"word":"existons","word_nosc":"existons","lemma":"exister","pos":"VER"} ,
		{"word":"existât","word_nosc":"existat","lemma":"exister","pos":"VER"} ,
		{"word":"existèrent","word_nosc":"existerent","lemma":"exister","pos":"VER"} ,
		{"word":"existé","word_nosc":"existe","lemma":"exister","pos":"VER"} ,
		{"word":"existée","word_nosc":"existee","lemma":"exister","pos":"VER"} ,
		{"word":"existés","word_nosc":"existes","lemma":"exister","pos":"VER"} ,
		{"word":"exit","word_nosc":"exit","lemma":"exit","pos":"VER"} ,
		{"word":"exonérait","word_nosc":"exonerait","lemma":"exonérer","pos":"VER"} ,
		{"word":"exonérer","word_nosc":"exonerer","lemma":"exonérer","pos":"VER"} ,
		{"word":"exonérera","word_nosc":"exonerera","lemma":"exonérer","pos":"VER"} ,
		{"word":"exonérées","word_nosc":"exonerees","lemma":"exonérer","pos":"VER"} ,
		{"word":"exorbitaient","word_nosc":"exorbitaient","lemma":"exorbiter","pos":"VER"} ,
		{"word":"exorbitait","word_nosc":"exorbitait","lemma":"exorbiter","pos":"VER"} ,
		{"word":"exorbiter","word_nosc":"exorbiter","lemma":"exorbiter","pos":"VER"} ,
		{"word":"exorbitées","word_nosc":"exorbitees","lemma":"exorbiter","pos":"VER"} ,
		{"word":"exorbités","word_nosc":"exorbites","lemma":"exorbiter","pos":"VER"} ,
		{"word":"exorcisa","word_nosc":"exorcisa","lemma":"exorciser","pos":"VER"} ,
		{"word":"exorcisait","word_nosc":"exorcisait","lemma":"exorciser","pos":"VER"} ,
		{"word":"exorcisant","word_nosc":"exorcisant","lemma":"exorciser","pos":"VER"} ,
		{"word":"exorcise","word_nosc":"exorcise","lemma":"exorciser","pos":"VER"} ,
		{"word":"exorcisent","word_nosc":"exorcisent","lemma":"exorciser","pos":"VER"} ,
		{"word":"exorciser","word_nosc":"exorciser","lemma":"exorciser","pos":"VER"} ,
		{"word":"exorciserait","word_nosc":"exorciserait","lemma":"exorciser","pos":"VER"} ,
		{"word":"exorcisez","word_nosc":"exorcisez","lemma":"exorciser","pos":"VER"} ,
		{"word":"exorcisions","word_nosc":"exorcisions","lemma":"exorciser","pos":"VER"} ,
		{"word":"exorcisé","word_nosc":"exorcise","lemma":"exorciser","pos":"VER"} ,
		{"word":"exorcisée","word_nosc":"exorcisee","lemma":"exorciser","pos":"VER"} ,
		{"word":"exorcisées","word_nosc":"exorcisees","lemma":"exorciser","pos":"VER"} ,
		{"word":"exorcisés","word_nosc":"exorcises","lemma":"exorciser","pos":"VER"} ,
		{"word":"expatriait","word_nosc":"expatriait","lemma":"expatrier","pos":"VER"} ,
		{"word":"expatrier","word_nosc":"expatrier","lemma":"expatrier","pos":"VER"} ,
		{"word":"expatrièrent","word_nosc":"expatrierent","lemma":"expatrier","pos":"VER"} ,
		{"word":"expatrié","word_nosc":"expatrie","lemma":"expatrier","pos":"VER"} ,
		{"word":"expatriée","word_nosc":"expatriee","lemma":"expatrier","pos":"VER"} ,
		{"word":"expectora","word_nosc":"expectora","lemma":"expectorer","pos":"VER"} ,
		{"word":"expectorant","word_nosc":"expectorant","lemma":"expectorer","pos":"VER"} ,
		{"word":"expectorer","word_nosc":"expectorer","lemma":"expectorer","pos":"VER"} ,
		{"word":"expectoré","word_nosc":"expectore","lemma":"expectorer","pos":"VER"} ,
		{"word":"expectorées","word_nosc":"expectorees","lemma":"expectorer","pos":"VER"} ,
		{"word":"expertisaient","word_nosc":"expertisaient","lemma":"expertiser","pos":"VER"} ,
		{"word":"expertisait","word_nosc":"expertisait","lemma":"expertiser","pos":"VER"} ,
		{"word":"expertisant","word_nosc":"expertisant","lemma":"expertiser","pos":"VER"} ,
		{"word":"expertise","word_nosc":"expertise","lemma":"expertiser","pos":"VER"} ,
		{"word":"expertiser","word_nosc":"expertiser","lemma":"expertiser","pos":"VER"} ,
		{"word":"expiaient","word_nosc":"expiaient","lemma":"expier","pos":"VER"} ,
		{"word":"expiait","word_nosc":"expiait","lemma":"expier","pos":"VER"} ,
		{"word":"expiant","word_nosc":"expiant","lemma":"expier","pos":"VER"} ,
		{"word":"expie","word_nosc":"expie","lemma":"expier","pos":"VER"} ,
		{"word":"expier","word_nosc":"expier","lemma":"expier","pos":"VER"} ,
		{"word":"expieras","word_nosc":"expieras","lemma":"expier","pos":"VER"} ,
		{"word":"expira","word_nosc":"expira","lemma":"expirer","pos":"VER"} ,
		{"word":"expirais","word_nosc":"expirais","lemma":"expirer","pos":"VER"} ,
		{"word":"expirait","word_nosc":"expirait","lemma":"expirer","pos":"VER"} ,
		{"word":"expirant","word_nosc":"expirant","lemma":"expirer","pos":"VER"} ,
		{"word":"expire","word_nosc":"expire","lemma":"expirer","pos":"VER"} ,
		{"word":"expirent","word_nosc":"expirent","lemma":"expirer","pos":"VER"} ,
		{"word":"expirer","word_nosc":"expirer","lemma":"expirer","pos":"VER"} ,
		{"word":"expirera","word_nosc":"expirera","lemma":"expirer","pos":"VER"} ,
		{"word":"expirerait","word_nosc":"expirerait","lemma":"expirer","pos":"VER"} ,
		{"word":"expirez","word_nosc":"expirez","lemma":"expirer","pos":"VER"} ,
		{"word":"expirons","word_nosc":"expirons","lemma":"expirer","pos":"VER"} ,
		{"word":"expiré","word_nosc":"expire","lemma":"expirer","pos":"VER"} ,
		{"word":"expirée","word_nosc":"expiree","lemma":"expirer","pos":"VER"} ,
		{"word":"expirés","word_nosc":"expires","lemma":"expirer","pos":"VER"} ,
		{"word":"expié","word_nosc":"expie","lemma":"expier","pos":"VER"} ,
		{"word":"expiée","word_nosc":"expiee","lemma":"expier","pos":"VER"} ,
		{"word":"expiés","word_nosc":"expies","lemma":"expier","pos":"VER"} ,
		{"word":"explicitant","word_nosc":"explicitant","lemma":"expliciter","pos":"VER"} ,
		{"word":"explicite","word_nosc":"explicite","lemma":"expliciter","pos":"VER"} ,
		{"word":"expliciter","word_nosc":"expliciter","lemma":"expliciter","pos":"VER"} ,
		{"word":"expliqua","word_nosc":"expliqua","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquai","word_nosc":"expliquai","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquaient","word_nosc":"expliquaient","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquais","word_nosc":"expliquais","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquait","word_nosc":"expliquait","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquant","word_nosc":"expliquant","lemma":"expliquer","pos":"VER"} ,
		{"word":"explique","word_nosc":"explique","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquent","word_nosc":"expliquent","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquer","word_nosc":"expliquer","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquera","word_nosc":"expliquera","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquerai","word_nosc":"expliquerai","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliqueraient","word_nosc":"expliqueraient","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquerais","word_nosc":"expliquerais","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquerait","word_nosc":"expliquerait","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliqueras","word_nosc":"expliqueras","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquerez","word_nosc":"expliquerez","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliqueriez","word_nosc":"expliqueriez","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquerons","word_nosc":"expliquerons","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliqueront","word_nosc":"expliqueront","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliques","word_nosc":"expliques","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquez","word_nosc":"expliquez","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquiez","word_nosc":"expliquiez","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquions","word_nosc":"expliquions","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquons","word_nosc":"expliquons","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquâmes","word_nosc":"expliquames","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquât","word_nosc":"expliquat","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquèrent","word_nosc":"expliquerent","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliqué","word_nosc":"explique","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquée","word_nosc":"expliquee","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliquées","word_nosc":"expliquees","lemma":"expliquer","pos":"VER"} ,
		{"word":"expliqués","word_nosc":"expliques","lemma":"expliquer","pos":"VER"} ,
		{"word":"exploita","word_nosc":"exploita","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploitai","word_nosc":"exploitai","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploitaient","word_nosc":"exploitaient","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploitais","word_nosc":"exploitais","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploitait","word_nosc":"exploitait","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploitant","word_nosc":"exploitant","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploite","word_nosc":"exploite","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploitent","word_nosc":"exploitent","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploiter","word_nosc":"exploiter","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploitera","word_nosc":"exploitera","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploiterais","word_nosc":"exploiterais","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploiterait","word_nosc":"exploiterait","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploiteront","word_nosc":"exploiteront","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploites","word_nosc":"exploites","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploitez","word_nosc":"exploitez","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploitions","word_nosc":"exploitions","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploitons","word_nosc":"exploitons","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploitât","word_nosc":"exploitat","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploitèrent","word_nosc":"exploiterent","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploité","word_nosc":"exploite","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploitée","word_nosc":"exploitee","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploitées","word_nosc":"exploitees","lemma":"exploiter","pos":"VER"} ,
		{"word":"exploités","word_nosc":"exploites","lemma":"exploiter","pos":"VER"} ,
		{"word":"explora","word_nosc":"explora","lemma":"explorer","pos":"VER"} ,
		{"word":"explorai","word_nosc":"explorai","lemma":"explorer","pos":"VER"} ,
		{"word":"exploraient","word_nosc":"exploraient","lemma":"explorer","pos":"VER"} ,
		{"word":"explorais","word_nosc":"explorais","lemma":"explorer","pos":"VER"} ,
		{"word":"explorait","word_nosc":"explorait","lemma":"explorer","pos":"VER"} ,
		{"word":"explorant","word_nosc":"explorant","lemma":"explorer","pos":"VER"} ,
		{"word":"explore","word_nosc":"explore","lemma":"explorer","pos":"VER"} ,
		{"word":"explorent","word_nosc":"explorent","lemma":"explorer","pos":"VER"} ,
		{"word":"explorer","word_nosc":"explorer","lemma":"explorer","pos":"VER"} ,
		{"word":"explorera","word_nosc":"explorera","lemma":"explorer","pos":"VER"} ,
		{"word":"explorerai","word_nosc":"explorerai","lemma":"explorer","pos":"VER"} ,
		{"word":"explorerez","word_nosc":"explorerez","lemma":"explorer","pos":"VER"} ,
		{"word":"explorerons","word_nosc":"explorerons","lemma":"explorer","pos":"VER"} ,
		{"word":"explorez","word_nosc":"explorez","lemma":"explorer","pos":"VER"} ,
		{"word":"explorions","word_nosc":"explorions","lemma":"explorer","pos":"VER"} ,
		{"word":"explorons","word_nosc":"explorons","lemma":"explorer","pos":"VER"} ,
		{"word":"explorèrent","word_nosc":"explorerent","lemma":"explorer","pos":"VER"} ,
		{"word":"exploré","word_nosc":"explore","lemma":"explorer","pos":"VER"} ,
		{"word":"explorée","word_nosc":"exploree","lemma":"explorer","pos":"VER"} ,
		{"word":"explorées","word_nosc":"explorees","lemma":"explorer","pos":"VER"} ,
		{"word":"explorés","word_nosc":"explores","lemma":"explorer","pos":"VER"} ,
		{"word":"explosa","word_nosc":"explosa","lemma":"exploser","pos":"VER"} ,
		{"word":"explosai","word_nosc":"explosai","lemma":"exploser","pos":"VER"} ,
		{"word":"explosaient","word_nosc":"explosaient","lemma":"exploser","pos":"VER"} ,
		{"word":"explosais","word_nosc":"explosais","lemma":"exploser","pos":"VER"} ,
		{"word":"explosait","word_nosc":"explosait","lemma":"exploser","pos":"VER"} ,
		{"word":"explosant","word_nosc":"explosant","lemma":"exploser","pos":"VER"} ,
		{"word":"explose","word_nosc":"explose","lemma":"exploser","pos":"VER"} ,
		{"word":"explosent","word_nosc":"explosent","lemma":"exploser","pos":"VER"} ,
		{"word":"exploser","word_nosc":"exploser","lemma":"exploser","pos":"VER"} ,
		{"word":"explosera","word_nosc":"explosera","lemma":"exploser","pos":"VER"} ,
		{"word":"exploserai","word_nosc":"exploserai","lemma":"exploser","pos":"VER"} ,
		{"word":"exploseraient","word_nosc":"exploseraient","lemma":"exploser","pos":"VER"} ,
		{"word":"exploserais","word_nosc":"exploserais","lemma":"exploser","pos":"VER"} ,
		{"word":"exploserait","word_nosc":"exploserait","lemma":"exploser","pos":"VER"} ,
		{"word":"exploseras","word_nosc":"exploseras","lemma":"exploser","pos":"VER"} ,
		{"word":"exploserez","word_nosc":"exploserez","lemma":"exploser","pos":"VER"} ,
		{"word":"exploseront","word_nosc":"exploseront","lemma":"exploser","pos":"VER"} ,
		{"word":"exploses","word_nosc":"exploses","lemma":"exploser","pos":"VER"} ,
		{"word":"explosez","word_nosc":"explosez","lemma":"exploser","pos":"VER"} ,
		{"word":"explosions","word_nosc":"explosions","lemma":"exploser","pos":"VER"} ,
		{"word":"explosons","word_nosc":"explosons","lemma":"exploser","pos":"VER"} ,
		{"word":"explosât","word_nosc":"explosat","lemma":"exploser","pos":"VER"} ,
		{"word":"explosèrent","word_nosc":"exploserent","lemma":"exploser","pos":"VER"} ,
		{"word":"explosé","word_nosc":"explose","lemma":"exploser","pos":"VER"} ,
		{"word":"explosée","word_nosc":"explosee","lemma":"exploser","pos":"VER"} ,
		{"word":"explosées","word_nosc":"explosees","lemma":"exploser","pos":"VER"} ,
		{"word":"explosés","word_nosc":"exploses","lemma":"exploser","pos":"VER"} ,
		{"word":"exporta","word_nosc":"exporta","lemma":"exporter","pos":"VER"} ,
		{"word":"exportaient","word_nosc":"exportaient","lemma":"exporter","pos":"VER"} ,
		{"word":"exportant","word_nosc":"exportant","lemma":"exporter","pos":"VER"} ,
		{"word":"exporte","word_nosc":"exporte","lemma":"exporter","pos":"VER"} ,
		{"word":"exporter","word_nosc":"exporter","lemma":"exporter","pos":"VER"} ,
		{"word":"exportera","word_nosc":"exportera","lemma":"exporter","pos":"VER"} ,
		{"word":"exporterons","word_nosc":"exporterons","lemma":"exporter","pos":"VER"} ,
		{"word":"exportez","word_nosc":"exportez","lemma":"exporter","pos":"VER"} ,
		{"word":"exporté","word_nosc":"exporte","lemma":"exporter","pos":"VER"} ,
		{"word":"exportée","word_nosc":"exportee","lemma":"exporter","pos":"VER"} ,
		{"word":"exportées","word_nosc":"exportees","lemma":"exporter","pos":"VER"} ,
		{"word":"exportés","word_nosc":"exportes","lemma":"exporter","pos":"VER"} ,
		{"word":"exposa","word_nosc":"exposa","lemma":"exposer","pos":"VER"} ,
		{"word":"exposai","word_nosc":"exposai","lemma":"exposer","pos":"VER"} ,
		{"word":"exposaient","word_nosc":"exposaient","lemma":"exposer","pos":"VER"} ,
		{"word":"exposais","word_nosc":"exposais","lemma":"exposer","pos":"VER"} ,
		{"word":"exposait","word_nosc":"exposait","lemma":"exposer","pos":"VER"} ,
		{"word":"exposant","word_nosc":"exposant","lemma":"exposer","pos":"VER"} ,
		{"word":"expose","word_nosc":"expose","lemma":"exposer","pos":"VER"} ,
		{"word":"exposent","word_nosc":"exposent","lemma":"exposer","pos":"VER"} ,
		{"word":"exposer","word_nosc":"exposer","lemma":"exposer","pos":"VER"} ,
		{"word":"exposera","word_nosc":"exposera","lemma":"exposer","pos":"VER"} ,
		{"word":"exposerai","word_nosc":"exposerai","lemma":"exposer","pos":"VER"} ,
		{"word":"exposerais","word_nosc":"exposerais","lemma":"exposer","pos":"VER"} ,
		{"word":"exposerait","word_nosc":"exposerait","lemma":"exposer","pos":"VER"} ,
		{"word":"exposerez","word_nosc":"exposerez","lemma":"exposer","pos":"VER"} ,
		{"word":"exposerions","word_nosc":"exposerions","lemma":"exposer","pos":"VER"} ,
		{"word":"exposeront","word_nosc":"exposeront","lemma":"exposer","pos":"VER"} ,
		{"word":"exposes","word_nosc":"exposes","lemma":"exposer","pos":"VER"} ,
		{"word":"exposez","word_nosc":"exposez","lemma":"exposer","pos":"VER"} ,
		{"word":"exposiez","word_nosc":"exposiez","lemma":"exposer","pos":"VER"} ,
		{"word":"exposons","word_nosc":"exposons","lemma":"exposer","pos":"VER"} ,
		{"word":"exposâmes","word_nosc":"exposames","lemma":"exposer","pos":"VER"} ,
		{"word":"exposèrent","word_nosc":"exposerent","lemma":"exposer","pos":"VER"} ,
		{"word":"exposé","word_nosc":"expose","lemma":"exposer","pos":"VER"} ,
		{"word":"exposée","word_nosc":"exposee","lemma":"exposer","pos":"VER"} ,
		{"word":"exposées","word_nosc":"exposees","lemma":"exposer","pos":"VER"} ,
		{"word":"exposés","word_nosc":"exposes","lemma":"exposer","pos":"VER"} ,
		{"word":"exprima","word_nosc":"exprima","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimai","word_nosc":"exprimai","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimaient","word_nosc":"exprimaient","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimais","word_nosc":"exprimais","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimait","word_nosc":"exprimait","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimant","word_nosc":"exprimant","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprime","word_nosc":"exprime","lemma":"exprimer","pos":"VER"} ,
		{"word":"expriment","word_nosc":"expriment","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimer","word_nosc":"exprimer","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimera","word_nosc":"exprimera","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimerai","word_nosc":"exprimerai","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimerais","word_nosc":"exprimerais","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimerait","word_nosc":"exprimerait","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimeras","word_nosc":"exprimeras","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimerez","word_nosc":"exprimerez","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimeront","word_nosc":"exprimeront","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimes","word_nosc":"exprimes","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimez","word_nosc":"exprimez","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimiez","word_nosc":"exprimiez","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimions","word_nosc":"exprimions","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimons","word_nosc":"exprimons","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimât","word_nosc":"exprimat","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimèrent","word_nosc":"exprimerent","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimé","word_nosc":"exprime","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimée","word_nosc":"exprimee","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimées","word_nosc":"exprimees","lemma":"exprimer","pos":"VER"} ,
		{"word":"exprimés","word_nosc":"exprimes","lemma":"exprimer","pos":"VER"} ,
		{"word":"expropria","word_nosc":"expropria","lemma":"exproprier","pos":"VER"} ,
		{"word":"exproprie","word_nosc":"exproprie","lemma":"exproprier","pos":"VER"} ,
		{"word":"exproprier","word_nosc":"exproprier","lemma":"exproprier","pos":"VER"} ,
		{"word":"exproprierait","word_nosc":"exproprierait","lemma":"exproprier","pos":"VER"} ,
		{"word":"exproprié","word_nosc":"exproprie","lemma":"exproprier","pos":"VER"} ,
		{"word":"expropriée","word_nosc":"expropriee","lemma":"exproprier","pos":"VER"} ,
		{"word":"expropriées","word_nosc":"expropriees","lemma":"exproprier","pos":"VER"} ,
		{"word":"expropriés","word_nosc":"expropries","lemma":"exproprier","pos":"VER"} ,
		{"word":"expulsa","word_nosc":"expulsa","lemma":"expulser","pos":"VER"} ,
		{"word":"expulsai","word_nosc":"expulsai","lemma":"expulser","pos":"VER"} ,
		{"word":"expulsaient","word_nosc":"expulsaient","lemma":"expulser","pos":"VER"} ,
		{"word":"expulsais","word_nosc":"expulsais","lemma":"expulser","pos":"VER"} ,
		{"word":"expulsait","word_nosc":"expulsait","lemma":"expulser","pos":"VER"} ,
		{"word":"expulsant","word_nosc":"expulsant","lemma":"expulser","pos":"VER"} ,
		{"word":"expulse","word_nosc":"expulse","lemma":"expulser","pos":"VER"} ,
		{"word":"expulsent","word_nosc":"expulsent","lemma":"expulser","pos":"VER"} ,
		{"word":"expulser","word_nosc":"expulser","lemma":"expulser","pos":"VER"} ,
		{"word":"expulsera","word_nosc":"expulsera","lemma":"expulser","pos":"VER"} ,
		{"word":"expulserai","word_nosc":"expulserai","lemma":"expulser","pos":"VER"} ,
		{"word":"expulserait","word_nosc":"expulserait","lemma":"expulser","pos":"VER"} ,
		{"word":"expulseront","word_nosc":"expulseront","lemma":"expulser","pos":"VER"} ,
		{"word":"expulsez","word_nosc":"expulsez","lemma":"expulser","pos":"VER"} ,
		{"word":"expulsons","word_nosc":"expulsons","lemma":"expulser","pos":"VER"} ,
		{"word":"expulsât","word_nosc":"expulsat","lemma":"expulser","pos":"VER"} ,
		{"word":"expulsé","word_nosc":"expulse","lemma":"expulser","pos":"VER"} ,
		{"word":"expulsée","word_nosc":"expulsee","lemma":"expulser","pos":"VER"} ,
		{"word":"expulsées","word_nosc":"expulsees","lemma":"expulser","pos":"VER"} ,
		{"word":"expulsés","word_nosc":"expulses","lemma":"expulser","pos":"VER"} ,
		{"word":"expurgeait","word_nosc":"expurgeait","lemma":"expurger","pos":"VER"} ,
		{"word":"expurger","word_nosc":"expurger","lemma":"expurger","pos":"VER"} ,
		{"word":"expurgera","word_nosc":"expurgera","lemma":"expurger","pos":"VER"} ,
		{"word":"expurgé","word_nosc":"expurge","lemma":"expurger","pos":"VER"} ,
		{"word":"expurgée","word_nosc":"expurgee","lemma":"expurger","pos":"VER"} ,
		{"word":"expurgés","word_nosc":"expurges","lemma":"expurger","pos":"VER"} ,
		{"word":"expédia","word_nosc":"expedia","lemma":"expédier","pos":"VER"} ,
		{"word":"expédiai","word_nosc":"expediai","lemma":"expédier","pos":"VER"} ,
		{"word":"expédiaient","word_nosc":"expediaient","lemma":"expédier","pos":"VER"} ,
		{"word":"expédiais","word_nosc":"expediais","lemma":"expédier","pos":"VER"} ,
		{"word":"expédiait","word_nosc":"expediait","lemma":"expédier","pos":"VER"} ,
		{"word":"expédiant","word_nosc":"expediant","lemma":"expédier","pos":"VER"} ,
		{"word":"expédie","word_nosc":"expedie","lemma":"expédier","pos":"VER"} ,
		{"word":"expédient","word_nosc":"expedient","lemma":"expédier","pos":"VER"} ,
		{"word":"expédier","word_nosc":"expedier","lemma":"expédier","pos":"VER"} ,
		{"word":"expédiera","word_nosc":"expediera","lemma":"expédier","pos":"VER"} ,
		{"word":"expédierai","word_nosc":"expedierai","lemma":"expédier","pos":"VER"} ,
		{"word":"expédierait","word_nosc":"expedierait","lemma":"expédier","pos":"VER"} ,
		{"word":"expédieras","word_nosc":"expedieras","lemma":"expédier","pos":"VER"} ,
		{"word":"expédieriez","word_nosc":"expedieriez","lemma":"expédier","pos":"VER"} ,
		{"word":"expédierons","word_nosc":"expedierons","lemma":"expédier","pos":"VER"} ,
		{"word":"expédieront","word_nosc":"expedieront","lemma":"expédier","pos":"VER"} ,
		{"word":"expédies","word_nosc":"expedies","lemma":"expédier","pos":"VER"} ,
		{"word":"expédiez","word_nosc":"expediez","lemma":"expédier","pos":"VER"} ,
		{"word":"expédions","word_nosc":"expedions","lemma":"expédier","pos":"VER"} ,
		{"word":"expédié","word_nosc":"expedie","lemma":"expédier","pos":"VER"} ,
		{"word":"expédiée","word_nosc":"expediee","lemma":"expédier","pos":"VER"} ,
		{"word":"expédiées","word_nosc":"expediees","lemma":"expédier","pos":"VER"} ,
		{"word":"expédiés","word_nosc":"expedies","lemma":"expédier","pos":"VER"} ,
		{"word":"expérimenta","word_nosc":"experimenta","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimentai","word_nosc":"experimentai","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimentaient","word_nosc":"experimentaient","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimentais","word_nosc":"experimentais","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimentait","word_nosc":"experimentait","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimentant","word_nosc":"experimentant","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimente","word_nosc":"experimente","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimentent","word_nosc":"experimentent","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimenter","word_nosc":"experimenter","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimenteras","word_nosc":"experimenteras","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimentez","word_nosc":"experimentez","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimentiez","word_nosc":"experimentiez","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimentons","word_nosc":"experimentons","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimenté","word_nosc":"experimente","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimentée","word_nosc":"experimentee","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimentées","word_nosc":"experimentees","lemma":"expérimenter","pos":"VER"} ,
		{"word":"expérimentés","word_nosc":"experimentes","lemma":"expérimenter","pos":"VER"} ,
		{"word":"exsudaient","word_nosc":"exsudaient","lemma":"exsuder","pos":"VER"} ,
		{"word":"exsudait","word_nosc":"exsudait","lemma":"exsuder","pos":"VER"} ,
		{"word":"exsudant","word_nosc":"exsudant","lemma":"exsuder","pos":"VER"} ,
		{"word":"exsude","word_nosc":"exsude","lemma":"exsuder","pos":"VER"} ,
		{"word":"exsuder","word_nosc":"exsuder","lemma":"exsuder","pos":"VER"} ,
		{"word":"exsudé","word_nosc":"exsude","lemma":"exsuder","pos":"VER"} ,
		{"word":"extasia","word_nosc":"extasia","lemma":"extasier","pos":"VER"} ,
		{"word":"extasiaient","word_nosc":"extasiaient","lemma":"extasier","pos":"VER"} ,
		{"word":"extasiais","word_nosc":"extasiais","lemma":"extasier","pos":"VER"} ,
		{"word":"extasiait","word_nosc":"extasiait","lemma":"extasier","pos":"VER"} ,
		{"word":"extasiant","word_nosc":"extasiant","lemma":"extasier","pos":"VER"} ,
		{"word":"extasie","word_nosc":"extasie","lemma":"extasier","pos":"VER"} ,
		{"word":"extasient","word_nosc":"extasient","lemma":"extasier","pos":"VER"} ,
		{"word":"extasier","word_nosc":"extasier","lemma":"extasier","pos":"VER"} ,
		{"word":"extasieront","word_nosc":"extasieront","lemma":"extasier","pos":"VER"} ,
		{"word":"extasiez","word_nosc":"extasiez","lemma":"extasier","pos":"VER"} ,
		{"word":"extasions","word_nosc":"extasions","lemma":"extasier","pos":"VER"} ,
		{"word":"extasiât","word_nosc":"extasiat","lemma":"extasier","pos":"VER"} ,
		{"word":"extasièrent","word_nosc":"extasierent","lemma":"extasier","pos":"VER"} ,
		{"word":"extasié","word_nosc":"extasie","lemma":"extasier","pos":"VER"} ,
		{"word":"extasiée","word_nosc":"extasiee","lemma":"extasier","pos":"VER"} ,
		{"word":"extasiées","word_nosc":"extasiees","lemma":"extasier","pos":"VER"} ,
		{"word":"extasiés","word_nosc":"extasies","lemma":"extasier","pos":"VER"} ,
		{"word":"extermina","word_nosc":"extermina","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminaient","word_nosc":"exterminaient","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminais","word_nosc":"exterminais","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminait","word_nosc":"exterminait","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminant","word_nosc":"exterminant","lemma":"exterminer","pos":"VER"} ,
		{"word":"extermine","word_nosc":"extermine","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminent","word_nosc":"exterminent","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminer","word_nosc":"exterminer","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminera","word_nosc":"exterminera","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminerait","word_nosc":"exterminerait","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminerons","word_nosc":"exterminerons","lemma":"exterminer","pos":"VER"} ,
		{"word":"extermineront","word_nosc":"extermineront","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminez","word_nosc":"exterminez","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminions","word_nosc":"exterminions","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminons","word_nosc":"exterminons","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminé","word_nosc":"extermine","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminée","word_nosc":"exterminee","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminées","word_nosc":"exterminees","lemma":"exterminer","pos":"VER"} ,
		{"word":"exterminés","word_nosc":"extermines","lemma":"exterminer","pos":"VER"} ,
		{"word":"extirpa","word_nosc":"extirpa","lemma":"extirper","pos":"VER"} ,
		{"word":"extirpaient","word_nosc":"extirpaient","lemma":"extirper","pos":"VER"} ,
		{"word":"extirpais","word_nosc":"extirpais","lemma":"extirper","pos":"VER"} ,
		{"word":"extirpait","word_nosc":"extirpait","lemma":"extirper","pos":"VER"} ,
		{"word":"extirpant","word_nosc":"extirpant","lemma":"extirper","pos":"VER"} ,
		{"word":"extirpe","word_nosc":"extirpe","lemma":"extirper","pos":"VER"} ,
		{"word":"extirpent","word_nosc":"extirpent","lemma":"extirper","pos":"VER"} ,
		{"word":"extirper","word_nosc":"extirper","lemma":"extirper","pos":"VER"} ,
		{"word":"extirpera","word_nosc":"extirpera","lemma":"extirper","pos":"VER"} ,
		{"word":"extirperai","word_nosc":"extirperai","lemma":"extirper","pos":"VER"} ,
		{"word":"extirperais","word_nosc":"extirperais","lemma":"extirper","pos":"VER"} ,
		{"word":"extirperait","word_nosc":"extirperait","lemma":"extirper","pos":"VER"} ,
		{"word":"extirpé","word_nosc":"extirpe","lemma":"extirper","pos":"VER"} ,
		{"word":"extirpée","word_nosc":"extirpee","lemma":"extirper","pos":"VER"} ,
		{"word":"extirpées","word_nosc":"extirpees","lemma":"extirper","pos":"VER"} ,
		{"word":"extirpés","word_nosc":"extirpes","lemma":"extirper","pos":"VER"} ,
		{"word":"extorquai","word_nosc":"extorquai","lemma":"extorquer","pos":"VER"} ,
		{"word":"extorquais","word_nosc":"extorquais","lemma":"extorquer","pos":"VER"} ,
		{"word":"extorquait","word_nosc":"extorquait","lemma":"extorquer","pos":"VER"} ,
		{"word":"extorque","word_nosc":"extorque","lemma":"extorquer","pos":"VER"} ,
		{"word":"extorquent","word_nosc":"extorquent","lemma":"extorquer","pos":"VER"} ,
		{"word":"extorquer","word_nosc":"extorquer","lemma":"extorquer","pos":"VER"} ,
		{"word":"extorquez","word_nosc":"extorquez","lemma":"extorquer","pos":"VER"} ,
		{"word":"extorquèrent","word_nosc":"extorquerent","lemma":"extorquer","pos":"VER"} ,
		{"word":"extorqué","word_nosc":"extorque","lemma":"extorquer","pos":"VER"} ,
		{"word":"extorquée","word_nosc":"extorquee","lemma":"extorquer","pos":"VER"} ,
		{"word":"extorquées","word_nosc":"extorquees","lemma":"extorquer","pos":"VER"} ,
		{"word":"extorqués","word_nosc":"extorques","lemma":"extorquer","pos":"VER"} ,
		{"word":"extradaient","word_nosc":"extradaient","lemma":"extrader","pos":"VER"} ,
		{"word":"extrade","word_nosc":"extrade","lemma":"extrader","pos":"VER"} ,
		{"word":"extrader","word_nosc":"extrader","lemma":"extrader","pos":"VER"} ,
		{"word":"extradé","word_nosc":"extrade","lemma":"extrader","pos":"VER"} ,
		{"word":"extraient","word_nosc":"extraient","lemma":"extraire","pos":"VER"} ,
		{"word":"extraira","word_nosc":"extraira","lemma":"extraire","pos":"VER"} ,
		{"word":"extrairai","word_nosc":"extrairai","lemma":"extraire","pos":"VER"} ,
		{"word":"extraire","word_nosc":"extraire","lemma":"extraire","pos":"VER"} ,
		{"word":"extrairons","word_nosc":"extrairons","lemma":"extraire","pos":"VER"} ,
		{"word":"extrairont","word_nosc":"extrairont","lemma":"extraire","pos":"VER"} ,
		{"word":"extrais","word_nosc":"extrais","lemma":"extraire","pos":"VER"} ,
		{"word":"extrait","word_nosc":"extrait","lemma":"extraire","pos":"VER"} ,
		{"word":"extraite","word_nosc":"extraite","lemma":"extraire","pos":"VER"} ,
		{"word":"extraites","word_nosc":"extraites","lemma":"extraire","pos":"VER"} ,
		{"word":"extraits","word_nosc":"extraits","lemma":"extraire","pos":"VER"} ,
		{"word":"extrapolant","word_nosc":"extrapolant","lemma":"extrapoler","pos":"VER"} ,
		{"word":"extrapole","word_nosc":"extrapole","lemma":"extrapoler","pos":"VER"} ,
		{"word":"extrapolent","word_nosc":"extrapolent","lemma":"extrapoler","pos":"VER"} ,
		{"word":"extrapoler","word_nosc":"extrapoler","lemma":"extrapoler","pos":"VER"} ,
		{"word":"extrapolez","word_nosc":"extrapolez","lemma":"extrapoler","pos":"VER"} ,
		{"word":"extrapolons","word_nosc":"extrapolons","lemma":"extrapoler","pos":"VER"} ,
		{"word":"extrapolé","word_nosc":"extrapole","lemma":"extrapoler","pos":"VER"} ,
		{"word":"extrapolée","word_nosc":"extrapolee","lemma":"extrapoler","pos":"VER"} ,
		{"word":"extrapolées","word_nosc":"extrapolees","lemma":"extrapoler","pos":"VER"} ,
		{"word":"extravaguait","word_nosc":"extravaguait","lemma":"extravaguer","pos":"VER"} ,
		{"word":"extravague","word_nosc":"extravague","lemma":"extravaguer","pos":"VER"} ,
		{"word":"extravaguer","word_nosc":"extravaguer","lemma":"extravaguer","pos":"VER"} ,
		{"word":"extravagué","word_nosc":"extravague","lemma":"extravaguer","pos":"VER"} ,
		{"word":"extrayaient","word_nosc":"extrayaient","lemma":"extraire","pos":"VER"} ,
		{"word":"extrayait","word_nosc":"extrayait","lemma":"extraire","pos":"VER"} ,
		{"word":"extrayant","word_nosc":"extrayant","lemma":"extraire","pos":"VER"} ,
		{"word":"extrayez","word_nosc":"extrayez","lemma":"extraire","pos":"VER"} ,
		{"word":"extrayions","word_nosc":"extrayions","lemma":"extraire","pos":"VER"} ,
		{"word":"exténua","word_nosc":"extenua","lemma":"exténuer","pos":"VER"} ,
		{"word":"exténuait","word_nosc":"extenuait","lemma":"exténuer","pos":"VER"} ,
		{"word":"exténuant","word_nosc":"extenuant","lemma":"exténuer","pos":"VER"} ,
		{"word":"exténue","word_nosc":"extenue","lemma":"exténuer","pos":"VER"} ,
		{"word":"exténuent","word_nosc":"extenuent","lemma":"exténuer","pos":"VER"} ,
		{"word":"exténuer","word_nosc":"extenuer","lemma":"exténuer","pos":"VER"} ,
		{"word":"exténuerait","word_nosc":"extenuerait","lemma":"exténuer","pos":"VER"} ,
		{"word":"exténué","word_nosc":"extenue","lemma":"exténuer","pos":"VER"} ,
		{"word":"exténuée","word_nosc":"extenuee","lemma":"exténuer","pos":"VER"} ,
		{"word":"exténuées","word_nosc":"extenuees","lemma":"exténuer","pos":"VER"} ,
		{"word":"exténués","word_nosc":"extenues","lemma":"exténuer","pos":"VER"} ,
		{"word":"extériorisa","word_nosc":"exteriorisa","lemma":"extérioriser","pos":"VER"} ,
		{"word":"extériorisaient","word_nosc":"exteriorisaient","lemma":"extérioriser","pos":"VER"} ,
		{"word":"extériorisait","word_nosc":"exteriorisait","lemma":"extérioriser","pos":"VER"} ,
		{"word":"extériorise","word_nosc":"exteriorise","lemma":"extérioriser","pos":"VER"} ,
		{"word":"extérioriser","word_nosc":"exterioriser","lemma":"extérioriser","pos":"VER"} ,
		{"word":"extériorisez","word_nosc":"exteriorisez","lemma":"extérioriser","pos":"VER"} ,
		{"word":"extériorisé","word_nosc":"exteriorise","lemma":"extérioriser","pos":"VER"} ,
		{"word":"extériorisés","word_nosc":"exteriorises","lemma":"extérioriser","pos":"VER"} ,
		{"word":"exulta","word_nosc":"exulta","lemma":"exulter","pos":"VER"} ,
		{"word":"exultai","word_nosc":"exultai","lemma":"exulter","pos":"VER"} ,
		{"word":"exultaient","word_nosc":"exultaient","lemma":"exulter","pos":"VER"} ,
		{"word":"exultais","word_nosc":"exultais","lemma":"exulter","pos":"VER"} ,
		{"word":"exultait","word_nosc":"exultait","lemma":"exulter","pos":"VER"} ,
		{"word":"exultant","word_nosc":"exultant","lemma":"exulter","pos":"VER"} ,
		{"word":"exulte","word_nosc":"exulte","lemma":"exulter","pos":"VER"} ,
		{"word":"exultent","word_nosc":"exultent","lemma":"exulter","pos":"VER"} ,
		{"word":"exulter","word_nosc":"exulter","lemma":"exulter","pos":"VER"} ,
		{"word":"exultons","word_nosc":"exultons","lemma":"exulter","pos":"VER"} ,
		{"word":"exultèrent","word_nosc":"exulterent","lemma":"exulter","pos":"VER"} ,
		{"word":"exècre","word_nosc":"execre","lemma":"exécrer","pos":"VER"} ,
		{"word":"exècrent","word_nosc":"execrent","lemma":"exécrer","pos":"VER"} ,
		{"word":"exécra","word_nosc":"execra","lemma":"exécrer","pos":"VER"} ,
		{"word":"exécrais","word_nosc":"execrais","lemma":"exécrer","pos":"VER"} ,
		{"word":"exécrait","word_nosc":"execrait","lemma":"exécrer","pos":"VER"} ,
		{"word":"exécrer","word_nosc":"execrer","lemma":"exécrer","pos":"VER"} ,
		{"word":"exécré","word_nosc":"execre","lemma":"exécrer","pos":"VER"} ,
		{"word":"exécrée","word_nosc":"execree","lemma":"exécrer","pos":"VER"} ,
		{"word":"exécrées","word_nosc":"execrees","lemma":"exécrer","pos":"VER"} ,
		{"word":"exécrés","word_nosc":"execres","lemma":"exécrer","pos":"VER"} ,
		{"word":"exécuta","word_nosc":"executa","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutai","word_nosc":"executai","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutaient","word_nosc":"executaient","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutais","word_nosc":"executais","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutait","word_nosc":"executait","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutant","word_nosc":"executant","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécute","word_nosc":"execute","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutent","word_nosc":"executent","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécuter","word_nosc":"executer","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutera","word_nosc":"executera","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécuterai","word_nosc":"executerai","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécuteraient","word_nosc":"executeraient","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécuterais","word_nosc":"executerais","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécuterait","word_nosc":"executerait","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécuteras","word_nosc":"executeras","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécuterez","word_nosc":"executerez","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécuteriez","word_nosc":"executeriez","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécuterons","word_nosc":"executerons","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécuteront","word_nosc":"executeront","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutes","word_nosc":"executes","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutez","word_nosc":"executez","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutiez","word_nosc":"executiez","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutions","word_nosc":"executions","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutons","word_nosc":"executons","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutèrent","word_nosc":"executerent","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécuté","word_nosc":"execute","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutée","word_nosc":"executee","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutées","word_nosc":"executees","lemma":"exécuter","pos":"VER"} ,
		{"word":"exécutés","word_nosc":"executes","lemma":"exécuter","pos":"VER"} ,
		{"word":"eûmes","word_nosc":"eumes","lemma":"avoir","pos":"VER"} ,
		{"word":"eût","word_nosc":"eut","lemma":"avoir","pos":"VER"} ,
		{"word":"eûtes","word_nosc":"eutes","lemma":"avoir","pos":"VER"} ,
		{"word":"fabriqua","word_nosc":"fabriqua","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquai","word_nosc":"fabriquai","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquaient","word_nosc":"fabriquaient","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquais","word_nosc":"fabriquais","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquait","word_nosc":"fabriquait","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquant","word_nosc":"fabriquant","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabrique","word_nosc":"fabrique","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquent","word_nosc":"fabriquent","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquer","word_nosc":"fabriquer","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquera","word_nosc":"fabriquera","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquerai","word_nosc":"fabriquerai","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquerais","word_nosc":"fabriquerais","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquerait","word_nosc":"fabriquerait","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquerez","word_nosc":"fabriquerez","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquerons","word_nosc":"fabriquerons","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriqueront","word_nosc":"fabriqueront","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriques","word_nosc":"fabriques","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquez","word_nosc":"fabriquez","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquiez","word_nosc":"fabriquiez","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquions","word_nosc":"fabriquions","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquons","word_nosc":"fabriquons","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquèrent","word_nosc":"fabriquerent","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriqué","word_nosc":"fabrique","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquée","word_nosc":"fabriquee","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriquées","word_nosc":"fabriquees","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabriqués","word_nosc":"fabriques","lemma":"fabriquer","pos":"VER"} ,
		{"word":"fabule","word_nosc":"fabule","lemma":"fabuler","pos":"VER"} ,
		{"word":"fabuler","word_nosc":"fabuler","lemma":"fabuler","pos":"VER"} ,
		{"word":"fabulez","word_nosc":"fabulez","lemma":"fabuler","pos":"VER"} ,
		{"word":"fabulé","word_nosc":"fabule","lemma":"fabuler","pos":"VER"} ,
		{"word":"fac-similé","word_nosc":"fac-simile","lemma":"fac-similer","pos":"VER"} ,
		{"word":"facettés","word_nosc":"facettes","lemma":"facetter","pos":"VER"} ,
		{"word":"facilita","word_nosc":"facilita","lemma":"faciliter","pos":"VER"} ,
		{"word":"facilitaient","word_nosc":"facilitaient","lemma":"faciliter","pos":"VER"} ,
		{"word":"facilitait","word_nosc":"facilitait","lemma":"faciliter","pos":"VER"} ,
		{"word":"facilitant","word_nosc":"facilitant","lemma":"faciliter","pos":"VER"} ,
		{"word":"facilite","word_nosc":"facilite","lemma":"faciliter","pos":"VER"} ,
		{"word":"facilitent","word_nosc":"facilitent","lemma":"faciliter","pos":"VER"} ,
		{"word":"faciliter","word_nosc":"faciliter","lemma":"faciliter","pos":"VER"} ,
		{"word":"facilitera","word_nosc":"facilitera","lemma":"faciliter","pos":"VER"} ,
		{"word":"faciliterai","word_nosc":"faciliterai","lemma":"faciliter","pos":"VER"} ,
		{"word":"faciliteraient","word_nosc":"faciliteraient","lemma":"faciliter","pos":"VER"} ,
		{"word":"faciliterais","word_nosc":"faciliterais","lemma":"faciliter","pos":"VER"} ,
		{"word":"faciliterait","word_nosc":"faciliterait","lemma":"faciliter","pos":"VER"} ,
		{"word":"faciliteront","word_nosc":"faciliteront","lemma":"faciliter","pos":"VER"} ,
		{"word":"facilitez","word_nosc":"facilitez","lemma":"faciliter","pos":"VER"} ,
		{"word":"facilitons","word_nosc":"facilitons","lemma":"faciliter","pos":"VER"} ,
		{"word":"facilité","word_nosc":"facilite","lemma":"faciliter","pos":"VER"} ,
		{"word":"facilitée","word_nosc":"facilitee","lemma":"faciliter","pos":"VER"} ,
		{"word":"facilitées","word_nosc":"facilitees","lemma":"faciliter","pos":"VER"} ,
		{"word":"facilités","word_nosc":"facilites","lemma":"faciliter","pos":"VER"} ,
		{"word":"factoriser","word_nosc":"factoriser","lemma":"factoriser","pos":"VER"} ,
		{"word":"facture","word_nosc":"facture","lemma":"facturer","pos":"VER"} ,
		{"word":"facturent","word_nosc":"facturent","lemma":"facturer","pos":"VER"} ,
		{"word":"facturer","word_nosc":"facturer","lemma":"facturer","pos":"VER"} ,
		{"word":"facturera","word_nosc":"facturera","lemma":"facturer","pos":"VER"} ,
		{"word":"facturerait","word_nosc":"facturerait","lemma":"facturer","pos":"VER"} ,
		{"word":"factures","word_nosc":"factures","lemma":"facturer","pos":"VER"} ,
		{"word":"facturez","word_nosc":"facturez","lemma":"facturer","pos":"VER"} ,
		{"word":"facturons","word_nosc":"facturons","lemma":"facturer","pos":"VER"} ,
		{"word":"facturé","word_nosc":"facture","lemma":"facturer","pos":"VER"} ,
		{"word":"facturée","word_nosc":"facturee","lemma":"facturer","pos":"VER"} ,
		{"word":"facturées","word_nosc":"facturees","lemma":"facturer","pos":"VER"} ,
		{"word":"facturés","word_nosc":"factures","lemma":"facturer","pos":"VER"} ,
		{"word":"fade","word_nosc":"fade","lemma":"fader","pos":"VER"} ,
		{"word":"fadent","word_nosc":"fadent","lemma":"fader","pos":"VER"} ,
		{"word":"fader","word_nosc":"fader","lemma":"fader","pos":"VER"} ,
		{"word":"faderas","word_nosc":"faderas","lemma":"fader","pos":"VER"} ,
		{"word":"fadé","word_nosc":"fade","lemma":"fader","pos":"VER"} ,
		{"word":"fadés","word_nosc":"fades","lemma":"fader","pos":"VER"} ,
		{"word":"fagotait","word_nosc":"fagotait","lemma":"fagoter","pos":"VER"} ,
		{"word":"fagotant","word_nosc":"fagotant","lemma":"fagoter","pos":"VER"} ,
		{"word":"fagote","word_nosc":"fagote","lemma":"fagoter","pos":"VER"} ,
		{"word":"fagoter","word_nosc":"fagoter","lemma":"fagoter","pos":"VER"} ,
		{"word":"fagoté","word_nosc":"fagote","lemma":"fagoter","pos":"VER"} ,
		{"word":"fagotée","word_nosc":"fagotee","lemma":"fagoter","pos":"VER"} ,
		{"word":"fagotées","word_nosc":"fagotees","lemma":"fagoter","pos":"VER"} ,
		{"word":"fagotés","word_nosc":"fagotes","lemma":"fagoter","pos":"VER"} ,
		{"word":"faibli","word_nosc":"faibli","lemma":"faiblir","pos":"VER"} ,
		{"word":"faiblir","word_nosc":"faiblir","lemma":"faiblir","pos":"VER"} ,
		{"word":"faiblira","word_nosc":"faiblira","lemma":"faiblir","pos":"VER"} ,
		{"word":"faiblirent","word_nosc":"faiblirent","lemma":"faiblir","pos":"VER"} ,
		{"word":"faiblis","word_nosc":"faiblis","lemma":"faiblir","pos":"VER"} ,
		{"word":"faiblissaient","word_nosc":"faiblissaient","lemma":"faiblir","pos":"VER"} ,
		{"word":"faiblissais","word_nosc":"faiblissais","lemma":"faiblir","pos":"VER"} ,
		{"word":"faiblissait","word_nosc":"faiblissait","lemma":"faiblir","pos":"VER"} ,
		{"word":"faiblissant","word_nosc":"faiblissant","lemma":"faiblir","pos":"VER"} ,
		{"word":"faiblisse","word_nosc":"faiblisse","lemma":"faiblir","pos":"VER"} ,
		{"word":"faiblissent","word_nosc":"faiblissent","lemma":"faiblir","pos":"VER"} ,
		{"word":"faiblissez","word_nosc":"faiblissez","lemma":"faiblir","pos":"VER"} ,
		{"word":"faiblissions","word_nosc":"faiblissions","lemma":"faiblir","pos":"VER"} ,
		{"word":"faiblit","word_nosc":"faiblit","lemma":"faiblir","pos":"VER"} ,
		{"word":"faiblît","word_nosc":"faiblit","lemma":"faiblir","pos":"VER"} ,
		{"word":"faignante","word_nosc":"faignante","lemma":"faignanter","pos":"VER"} ,
		{"word":"faillait","word_nosc":"faillait","lemma":"failler","pos":"VER"} ,
		{"word":"faille","word_nosc":"faille","lemma":"failler","pos":"VER"} ,
		{"word":"failli","word_nosc":"failli","lemma":"faillir","pos":"VER"} ,
		{"word":"faillir","word_nosc":"faillir","lemma":"faillir","pos":"VER"} ,
		{"word":"faillirai","word_nosc":"faillirai","lemma":"faillir","pos":"VER"} ,
		{"word":"faillirent","word_nosc":"faillirent","lemma":"faillir","pos":"VER"} ,
		{"word":"faillis","word_nosc":"faillis","lemma":"faillir","pos":"VER"} ,
		{"word":"faillit","word_nosc":"faillit","lemma":"faillir","pos":"VER"} ,
		{"word":"faillîmes","word_nosc":"faillimes","lemma":"faillir","pos":"VER"} ,
		{"word":"fainéantais","word_nosc":"faineantais","lemma":"fainéanter","pos":"VER"} ,
		{"word":"fainéantant","word_nosc":"faineantant","lemma":"fainéanter","pos":"VER"} ,
		{"word":"fainéante","word_nosc":"faineante","lemma":"fainéanter","pos":"VER"} ,
		{"word":"fainéanter","word_nosc":"faineanter","lemma":"fainéanter","pos":"VER"} ,
		{"word":"faire","word_nosc":"faire","lemma":"faire","pos":"VER"} ,
		{"word":"fais","word_nosc":"fais","lemma":"faire","pos":"VER"} ,
		{"word":"faisaient","word_nosc":"faisaient","lemma":"faire","pos":"VER"} ,
		{"word":"faisais","word_nosc":"faisais","lemma":"faire","pos":"VER"} ,
		{"word":"faisait","word_nosc":"faisait","lemma":"faire","pos":"VER"} ,
		{"word":"faisander","word_nosc":"faisander","lemma":"faisander","pos":"VER"} ,
		{"word":"faisandé","word_nosc":"faisande","lemma":"faisander","pos":"VER"} ,
		{"word":"faisandée","word_nosc":"faisandee","lemma":"faisander","pos":"VER"} ,
		{"word":"faisandées","word_nosc":"faisandees","lemma":"faisander","pos":"VER"} ,
		{"word":"faisant","word_nosc":"faisant","lemma":"faire","pos":"VER"} ,
		{"word":"faisiez","word_nosc":"faisiez","lemma":"faire","pos":"VER"} ,
		{"word":"faisions","word_nosc":"faisions","lemma":"faire","pos":"VER"} ,
		{"word":"faisons","word_nosc":"faisons","lemma":"faire","pos":"VER"} ,
		{"word":"fait","word_nosc":"fait","lemma":"faire","pos":"VER"} ,
		{"word":"faite","word_nosc":"faite","lemma":"faire","pos":"VER"} ,
		{"word":"faites","word_nosc":"faites","lemma":"faire","pos":"VER"} ,
		{"word":"faits","word_nosc":"faits","lemma":"faire","pos":"VER"} ,
		{"word":"fallait","word_nosc":"fallait","lemma":"falloir","pos":"VER"} ,
		{"word":"falloir","word_nosc":"falloir","lemma":"falloir","pos":"VER"} ,
		{"word":"fallu","word_nosc":"fallu","lemma":"falloir","pos":"VER"} ,
		{"word":"fallut","word_nosc":"fallut","lemma":"falloir","pos":"VER"} ,
		{"word":"fallût","word_nosc":"fallut","lemma":"falloir","pos":"VER"} ,
		{"word":"falsifiaient","word_nosc":"falsifiaient","lemma":"falsifier","pos":"VER"} ,
		{"word":"falsifiais","word_nosc":"falsifiais","lemma":"falsifier","pos":"VER"} ,
		{"word":"falsifiait","word_nosc":"falsifiait","lemma":"falsifier","pos":"VER"} ,
		{"word":"falsifiant","word_nosc":"falsifiant","lemma":"falsifier","pos":"VER"} ,
		{"word":"falsifient","word_nosc":"falsifient","lemma":"falsifier","pos":"VER"} ,
		{"word":"falsifier","word_nosc":"falsifier","lemma":"falsifier","pos":"VER"} ,
		{"word":"falsifiez","word_nosc":"falsifiez","lemma":"falsifier","pos":"VER"} ,
		{"word":"falsifié","word_nosc":"falsifie","lemma":"falsifier","pos":"VER"} ,
		{"word":"falsifiée","word_nosc":"falsifiee","lemma":"falsifier","pos":"VER"} ,
		{"word":"falsifiées","word_nosc":"falsifiees","lemma":"falsifier","pos":"VER"} ,
		{"word":"falsifiés","word_nosc":"falsifies","lemma":"falsifier","pos":"VER"} ,
		{"word":"familiarisa","word_nosc":"familiarisa","lemma":"familiariser","pos":"VER"} ,
		{"word":"familiarisaient","word_nosc":"familiarisaient","lemma":"familiariser","pos":"VER"} ,
		{"word":"familiarisais","word_nosc":"familiarisais","lemma":"familiariser","pos":"VER"} ,
		{"word":"familiarisait","word_nosc":"familiarisait","lemma":"familiariser","pos":"VER"} ,
		{"word":"familiarisant","word_nosc":"familiarisant","lemma":"familiariser","pos":"VER"} ,
		{"word":"familiarise","word_nosc":"familiarise","lemma":"familiariser","pos":"VER"} ,
		{"word":"familiarisent","word_nosc":"familiarisent","lemma":"familiariser","pos":"VER"} ,
		{"word":"familiariser","word_nosc":"familiariser","lemma":"familiariser","pos":"VER"} ,
		{"word":"familiariserez","word_nosc":"familiariserez","lemma":"familiariser","pos":"VER"} ,
		{"word":"familiarisez","word_nosc":"familiarisez","lemma":"familiariser","pos":"VER"} ,
		{"word":"familiarisé","word_nosc":"familiarise","lemma":"familiariser","pos":"VER"} ,
		{"word":"familiarisée","word_nosc":"familiarisee","lemma":"familiariser","pos":"VER"} ,
		{"word":"familiarisés","word_nosc":"familiarises","lemma":"familiariser","pos":"VER"} ,
		{"word":"fanaient","word_nosc":"fanaient","lemma":"faner","pos":"VER"} ,
		{"word":"fanait","word_nosc":"fanait","lemma":"faner","pos":"VER"} ,
		{"word":"fanant","word_nosc":"fanant","lemma":"faner","pos":"VER"} ,
		{"word":"fanatisait","word_nosc":"fanatisait","lemma":"fanatiser","pos":"VER"} ,
		{"word":"fanatise","word_nosc":"fanatise","lemma":"fanatiser","pos":"VER"} ,
		{"word":"fanatiser","word_nosc":"fanatiser","lemma":"fanatiser","pos":"VER"} ,
		{"word":"fanatisé","word_nosc":"fanatise","lemma":"fanatiser","pos":"VER"} ,
		{"word":"fanatisés","word_nosc":"fanatises","lemma":"fanatiser","pos":"VER"} ,
		{"word":"fane","word_nosc":"fane","lemma":"faner","pos":"VER"} ,
		{"word":"fanent","word_nosc":"fanent","lemma":"faner","pos":"VER"} ,
		{"word":"faner","word_nosc":"faner","lemma":"faner","pos":"VER"} ,
		{"word":"fanera","word_nosc":"fanera","lemma":"faner","pos":"VER"} ,
		{"word":"faneraient","word_nosc":"faneraient","lemma":"faner","pos":"VER"} ,
		{"word":"fanerait","word_nosc":"fanerait","lemma":"faner","pos":"VER"} ,
		{"word":"faneras","word_nosc":"faneras","lemma":"faner","pos":"VER"} ,
		{"word":"fanerions","word_nosc":"fanerions","lemma":"faner","pos":"VER"} ,
		{"word":"faneront","word_nosc":"faneront","lemma":"faner","pos":"VER"} ,
		{"word":"fanes","word_nosc":"fanes","lemma":"faner","pos":"VER"} ,
		{"word":"fanez","word_nosc":"fanez","lemma":"faner","pos":"VER"} ,
		{"word":"fanfaronna","word_nosc":"fanfaronna","lemma":"fanfaronner","pos":"VER"} ,
		{"word":"fanfaronnaient","word_nosc":"fanfaronnaient","lemma":"fanfaronner","pos":"VER"} ,
		{"word":"fanfaronnait","word_nosc":"fanfaronnait","lemma":"fanfaronner","pos":"VER"} ,
		{"word":"fanfaronnant","word_nosc":"fanfaronnant","lemma":"fanfaronner","pos":"VER"} ,
		{"word":"fanfaronne","word_nosc":"fanfaronne","lemma":"fanfaronner","pos":"VER"} ,
		{"word":"fanfaronner","word_nosc":"fanfaronner","lemma":"fanfaronner","pos":"VER"} ,
		{"word":"fanfaronnes","word_nosc":"fanfaronnes","lemma":"fanfaronner","pos":"VER"} ,
		{"word":"fanfaronné","word_nosc":"fanfaronne","lemma":"fanfaronner","pos":"VER"} ,
		{"word":"fantasmaient","word_nosc":"fantasmaient","lemma":"fantasmer","pos":"VER"} ,
		{"word":"fantasmais","word_nosc":"fantasmais","lemma":"fantasmer","pos":"VER"} ,
		{"word":"fantasmait","word_nosc":"fantasmait","lemma":"fantasmer","pos":"VER"} ,
		{"word":"fantasme","word_nosc":"fantasme","lemma":"fantasmer","pos":"VER"} ,
		{"word":"fantasment","word_nosc":"fantasment","lemma":"fantasmer","pos":"VER"} ,
		{"word":"fantasmer","word_nosc":"fantasmer","lemma":"fantasmer","pos":"VER"} ,
		{"word":"fantasmes","word_nosc":"fantasmes","lemma":"fantasmer","pos":"VER"} ,
		{"word":"fantasmez","word_nosc":"fantasmez","lemma":"fantasmer","pos":"VER"} ,
		{"word":"fantasmé","word_nosc":"fantasme","lemma":"fantasmer","pos":"VER"} ,
		{"word":"fantasmés","word_nosc":"fantasmes","lemma":"fantasmer","pos":"VER"} ,
		{"word":"fané","word_nosc":"fane","lemma":"faner","pos":"VER"} ,
		{"word":"fanée","word_nosc":"fanee","lemma":"faner","pos":"VER"} ,
		{"word":"fanées","word_nosc":"fanees","lemma":"faner","pos":"VER"} ,
		{"word":"fanés","word_nosc":"fanes","lemma":"faner","pos":"VER"} ,
		{"word":"farandolaient","word_nosc":"farandolaient","lemma":"farandoler","pos":"VER"} ,
		{"word":"farauder","word_nosc":"farauder","lemma":"farauder","pos":"VER"} ,
		{"word":"farce","word_nosc":"farce","lemma":"farcer","pos":"VER"} ,
		{"word":"farces","word_nosc":"farces","lemma":"farcer","pos":"VER"} ,
		{"word":"farci","word_nosc":"farci","lemma":"farcir","pos":"VER"} ,
		{"word":"farcie","word_nosc":"farcie","lemma":"farcir","pos":"VER"} ,
		{"word":"farcies","word_nosc":"farcies","lemma":"farcir","pos":"VER"} ,
		{"word":"farcir","word_nosc":"farcir","lemma":"farcir","pos":"VER"} ,
		{"word":"farcirai","word_nosc":"farcirai","lemma":"farcir","pos":"VER"} ,
		{"word":"farcirait","word_nosc":"farcirait","lemma":"farcir","pos":"VER"} ,
		{"word":"farcis","word_nosc":"farcis","lemma":"farcir","pos":"VER"} ,
		{"word":"farcissaient","word_nosc":"farcissaient","lemma":"farcir","pos":"VER"} ,
		{"word":"farcissais","word_nosc":"farcissais","lemma":"farcir","pos":"VER"} ,
		{"word":"farcissait","word_nosc":"farcissait","lemma":"farcir","pos":"VER"} ,
		{"word":"farcissant","word_nosc":"farcissant","lemma":"farcir","pos":"VER"} ,
		{"word":"farcisse","word_nosc":"farcisse","lemma":"farcir","pos":"VER"} ,
		{"word":"farcissez","word_nosc":"farcissez","lemma":"farcir","pos":"VER"} ,
		{"word":"farcit","word_nosc":"farcit","lemma":"farcir","pos":"VER"} ,
		{"word":"fardaient","word_nosc":"fardaient","lemma":"farder","pos":"VER"} ,
		{"word":"fardait","word_nosc":"fardait","lemma":"farder","pos":"VER"} ,
		{"word":"fardant","word_nosc":"fardant","lemma":"farder","pos":"VER"} ,
		{"word":"farde","word_nosc":"farde","lemma":"farder","pos":"VER"} ,
		{"word":"fardent","word_nosc":"fardent","lemma":"farder","pos":"VER"} ,
		{"word":"farder","word_nosc":"farder","lemma":"farder","pos":"VER"} ,
		{"word":"fardé","word_nosc":"farde","lemma":"farder","pos":"VER"} ,
		{"word":"fardée","word_nosc":"fardee","lemma":"farder","pos":"VER"} ,
		{"word":"fardées","word_nosc":"fardees","lemma":"farder","pos":"VER"} ,
		{"word":"fardés","word_nosc":"fardes","lemma":"farder","pos":"VER"} ,
		{"word":"farfouilla","word_nosc":"farfouilla","lemma":"farfouiller","pos":"VER"} ,
		{"word":"farfouillais","word_nosc":"farfouillais","lemma":"farfouiller","pos":"VER"} ,
		{"word":"farfouillait","word_nosc":"farfouillait","lemma":"farfouiller","pos":"VER"} ,
		{"word":"farfouillant","word_nosc":"farfouillant","lemma":"farfouiller","pos":"VER"} ,
		{"word":"farfouille","word_nosc":"farfouille","lemma":"farfouiller","pos":"VER"} ,
		{"word":"farfouillent","word_nosc":"farfouillent","lemma":"farfouiller","pos":"VER"} ,
		{"word":"farfouiller","word_nosc":"farfouiller","lemma":"farfouiller","pos":"VER"} ,
		{"word":"farfouillerait","word_nosc":"farfouillerait","lemma":"farfouiller","pos":"VER"} ,
		{"word":"farfouilles","word_nosc":"farfouilles","lemma":"farfouiller","pos":"VER"} ,
		{"word":"farfouillez","word_nosc":"farfouillez","lemma":"farfouiller","pos":"VER"} ,
		{"word":"farfouillé","word_nosc":"farfouille","lemma":"farfouiller","pos":"VER"} ,
		{"word":"fariboler","word_nosc":"fariboler","lemma":"fariboler","pos":"VER"} ,
		{"word":"farinée","word_nosc":"farinee","lemma":"fariner","pos":"VER"} ,
		{"word":"farinés","word_nosc":"farines","lemma":"fariner","pos":"VER"} ,
		{"word":"farter","word_nosc":"farter","lemma":"farter","pos":"VER"} ,
		{"word":"fascina","word_nosc":"fascina","lemma":"fasciner","pos":"VER"} ,
		{"word":"fascinaient","word_nosc":"fascinaient","lemma":"fasciner","pos":"VER"} ,
		{"word":"fascinais","word_nosc":"fascinais","lemma":"fasciner","pos":"VER"} ,
		{"word":"fascinait","word_nosc":"fascinait","lemma":"fasciner","pos":"VER"} ,
		{"word":"fascinant","word_nosc":"fascinant","lemma":"fasciner","pos":"VER"} ,
		{"word":"fascine","word_nosc":"fascine","lemma":"fasciner","pos":"VER"} ,
		{"word":"fascinent","word_nosc":"fascinent","lemma":"fasciner","pos":"VER"} ,
		{"word":"fasciner","word_nosc":"fasciner","lemma":"fasciner","pos":"VER"} ,
		{"word":"fascinerait","word_nosc":"fascinerait","lemma":"fasciner","pos":"VER"} ,
		{"word":"fascines","word_nosc":"fascines","lemma":"fasciner","pos":"VER"} ,
		{"word":"fascinez","word_nosc":"fascinez","lemma":"fasciner","pos":"VER"} ,
		{"word":"fascinèrent","word_nosc":"fascinerent","lemma":"fasciner","pos":"VER"} ,
		{"word":"fasciné","word_nosc":"fascine","lemma":"fasciner","pos":"VER"} ,
		{"word":"fascinée","word_nosc":"fascinee","lemma":"fasciner","pos":"VER"} ,
		{"word":"fascinées","word_nosc":"fascinees","lemma":"fasciner","pos":"VER"} ,
		{"word":"fascinés","word_nosc":"fascines","lemma":"fasciner","pos":"VER"} ,
		{"word":"fascisée","word_nosc":"fascisee","lemma":"fasciser","pos":"VER"} ,
		{"word":"faseyaient","word_nosc":"faseyaient","lemma":"faseyer","pos":"VER"} ,
		{"word":"faseyait","word_nosc":"faseyait","lemma":"faseyer","pos":"VER"} ,
		{"word":"fasse","word_nosc":"fasse","lemma":"faire","pos":"VER"} ,
		{"word":"fassent","word_nosc":"fassent","lemma":"faire","pos":"VER"} ,
		{"word":"fasses","word_nosc":"fasses","lemma":"faire","pos":"VER"} ,
		{"word":"fassiez","word_nosc":"fassiez","lemma":"faire","pos":"VER"} ,
		{"word":"fassions","word_nosc":"fassions","lemma":"faire","pos":"VER"} ,
		{"word":"fatalise","word_nosc":"fatalise","lemma":"fataliser","pos":"VER"} ,
		{"word":"fataliser","word_nosc":"fataliser","lemma":"fataliser","pos":"VER"} ,
		{"word":"fatalisé","word_nosc":"fatalise","lemma":"fataliser","pos":"VER"} ,
		{"word":"fatigua","word_nosc":"fatigua","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguai","word_nosc":"fatiguai","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguaient","word_nosc":"fatiguaient","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguais","word_nosc":"fatiguais","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguait","word_nosc":"fatiguait","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguant","word_nosc":"fatiguant","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatigue","word_nosc":"fatigue","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguent","word_nosc":"fatiguent","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguer","word_nosc":"fatiguer","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguera","word_nosc":"fatiguera","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguerai","word_nosc":"fatiguerai","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatigueraient","word_nosc":"fatigueraient","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguerais","word_nosc":"fatiguerais","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguerait","word_nosc":"fatiguerait","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatigueras","word_nosc":"fatigueras","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatigues","word_nosc":"fatigues","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguez","word_nosc":"fatiguez","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguons","word_nosc":"fatiguons","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguât","word_nosc":"fatiguat","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguèrent","word_nosc":"fatiguerent","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatigué","word_nosc":"fatigue","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguée","word_nosc":"fatiguee","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatiguées","word_nosc":"fatiguees","lemma":"fatiguer","pos":"VER"} ,
		{"word":"fatigués","word_nosc":"fatigues","lemma":"fatiguer","pos":"VER"} ,
		{"word":"faucardé","word_nosc":"faucarde","lemma":"faucarder","pos":"VER"} ,
		{"word":"faucardée","word_nosc":"faucardee","lemma":"faucarder","pos":"VER"} ,
		{"word":"faucardées","word_nosc":"faucardees","lemma":"faucarder","pos":"VER"} ,
		{"word":"faucardés","word_nosc":"faucardes","lemma":"faucarder","pos":"VER"} ,
		{"word":"faucha","word_nosc":"faucha","lemma":"faucher","pos":"VER"} ,
		{"word":"fauchaient","word_nosc":"fauchaient","lemma":"faucher","pos":"VER"} ,
		{"word":"fauchais","word_nosc":"fauchais","lemma":"faucher","pos":"VER"} ,
		{"word":"fauchait","word_nosc":"fauchait","lemma":"faucher","pos":"VER"} ,
		{"word":"fauchant","word_nosc":"fauchant","lemma":"faucher","pos":"VER"} ,
		{"word":"fauche","word_nosc":"fauche","lemma":"faucher","pos":"VER"} ,
		{"word":"fauchent","word_nosc":"fauchent","lemma":"faucher","pos":"VER"} ,
		{"word":"faucher","word_nosc":"faucher","lemma":"faucher","pos":"VER"} ,
		{"word":"fauchera","word_nosc":"fauchera","lemma":"faucher","pos":"VER"} ,
		{"word":"faucherait","word_nosc":"faucherait","lemma":"faucher","pos":"VER"} ,
		{"word":"faucheras","word_nosc":"faucheras","lemma":"faucher","pos":"VER"} ,
		{"word":"faucheront","word_nosc":"faucheront","lemma":"faucher","pos":"VER"} ,
		{"word":"fauches","word_nosc":"fauches","lemma":"faucher","pos":"VER"} ,
		{"word":"fauchez","word_nosc":"fauchez","lemma":"faucher","pos":"VER"} ,
		{"word":"fauché","word_nosc":"fauche","lemma":"faucher","pos":"VER"} ,
		{"word":"fauchée","word_nosc":"fauchee","lemma":"faucher","pos":"VER"} ,
		{"word":"fauchées","word_nosc":"fauchees","lemma":"faucher","pos":"VER"} ,
		{"word":"fauchés","word_nosc":"fauches","lemma":"faucher","pos":"VER"} ,
		{"word":"faudra","word_nosc":"faudra","lemma":"falloir","pos":"VER"} ,
		{"word":"faudrait","word_nosc":"faudrait","lemma":"falloir","pos":"VER"} ,
		{"word":"faufila","word_nosc":"faufila","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilai","word_nosc":"faufilai","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilaient","word_nosc":"faufilaient","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilais","word_nosc":"faufilais","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilait","word_nosc":"faufilait","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilant","word_nosc":"faufilant","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufile","word_nosc":"faufile","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilent","word_nosc":"faufilent","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufiler","word_nosc":"faufiler","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilera","word_nosc":"faufilera","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilerai","word_nosc":"faufilerai","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilerait","word_nosc":"faufilerait","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufileras","word_nosc":"faufileras","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufileront","word_nosc":"faufileront","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilez","word_nosc":"faufilez","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilons","word_nosc":"faufilons","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilâmes","word_nosc":"faufilames","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilât","word_nosc":"faufilat","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilèrent","word_nosc":"faufilerent","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilé","word_nosc":"faufile","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilée","word_nosc":"faufilee","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilées","word_nosc":"faufilees","lemma":"faufiler","pos":"VER"} ,
		{"word":"faufilés","word_nosc":"faufiles","lemma":"faufiler","pos":"VER"} ,
		{"word":"faussa","word_nosc":"faussa","lemma":"fausser","pos":"VER"} ,
		{"word":"faussaient","word_nosc":"faussaient","lemma":"fausser","pos":"VER"} ,
		{"word":"faussait","word_nosc":"faussait","lemma":"fausser","pos":"VER"} ,
		{"word":"faussant","word_nosc":"faussant","lemma":"fausser","pos":"VER"} ,
		{"word":"fausse","word_nosc":"fausse","lemma":"fausser","pos":"VER"} ,
		{"word":"faussent","word_nosc":"faussent","lemma":"fausser","pos":"VER"} ,
		{"word":"fausser","word_nosc":"fausser","lemma":"fausser","pos":"VER"} ,
		{"word":"fausseront","word_nosc":"fausseront","lemma":"fausser","pos":"VER"} ,
		{"word":"faussez","word_nosc":"faussez","lemma":"fausser","pos":"VER"} ,
		{"word":"faussions","word_nosc":"faussions","lemma":"fausser","pos":"VER"} ,
		{"word":"faussât","word_nosc":"faussat","lemma":"fausser","pos":"VER"} ,
		{"word":"faussé","word_nosc":"fausse","lemma":"fausser","pos":"VER"} ,
		{"word":"faussée","word_nosc":"faussee","lemma":"fausser","pos":"VER"} ,
		{"word":"faussées","word_nosc":"faussees","lemma":"fausser","pos":"VER"} ,
		{"word":"faussés","word_nosc":"fausses","lemma":"fausser","pos":"VER"} ,
		{"word":"faut","word_nosc":"faut","lemma":"falloir","pos":"VER"} ,
		{"word":"faute","word_nosc":"faute","lemma":"fauter","pos":"VER"} ,
		{"word":"fauter","word_nosc":"fauter","lemma":"fauter","pos":"VER"} ,
		{"word":"fautes","word_nosc":"fautes","lemma":"fauter","pos":"VER"} ,
		{"word":"fauté","word_nosc":"faute","lemma":"fauter","pos":"VER"} ,
		{"word":"favorisa","word_nosc":"favorisa","lemma":"favoriser","pos":"VER"} ,
		{"word":"favorisaient","word_nosc":"favorisaient","lemma":"favoriser","pos":"VER"} ,
		{"word":"favorisait","word_nosc":"favorisait","lemma":"favoriser","pos":"VER"} ,
		{"word":"favorisant","word_nosc":"favorisant","lemma":"favoriser","pos":"VER"} ,
		{"word":"favorise","word_nosc":"favorise","lemma":"favoriser","pos":"VER"} ,
		{"word":"favorisent","word_nosc":"favorisent","lemma":"favoriser","pos":"VER"} ,
		{"word":"favoriser","word_nosc":"favoriser","lemma":"favoriser","pos":"VER"} ,
		{"word":"favorisera","word_nosc":"favorisera","lemma":"favoriser","pos":"VER"} ,
		{"word":"favoriserai","word_nosc":"favoriserai","lemma":"favoriser","pos":"VER"} ,
		{"word":"favoriseraient","word_nosc":"favoriseraient","lemma":"favoriser","pos":"VER"} ,
		{"word":"favoriserait","word_nosc":"favoriserait","lemma":"favoriser","pos":"VER"} ,
		{"word":"favorisez","word_nosc":"favorisez","lemma":"favoriser","pos":"VER"} ,
		{"word":"favorisât","word_nosc":"favorisat","lemma":"favoriser","pos":"VER"} ,
		{"word":"favorisèrent","word_nosc":"favoriserent","lemma":"favoriser","pos":"VER"} ,
		{"word":"favorisé","word_nosc":"favorise","lemma":"favoriser","pos":"VER"} ,
		{"word":"favorisée","word_nosc":"favorisee","lemma":"favoriser","pos":"VER"} ,
		{"word":"favorisées","word_nosc":"favorisees","lemma":"favoriser","pos":"VER"} ,
		{"word":"favorisés","word_nosc":"favorises","lemma":"favoriser","pos":"VER"} ,
		{"word":"faxe","word_nosc":"faxe","lemma":"faxer","pos":"VER"} ,
		{"word":"faxer","word_nosc":"faxer","lemma":"faxer","pos":"VER"} ,
		{"word":"faxera","word_nosc":"faxera","lemma":"faxer","pos":"VER"} ,
		{"word":"faxerai","word_nosc":"faxerai","lemma":"faxer","pos":"VER"} ,
		{"word":"faxes","word_nosc":"faxes","lemma":"faxer","pos":"VER"} ,
		{"word":"faxez","word_nosc":"faxez","lemma":"faxer","pos":"VER"} ,
		{"word":"faxons","word_nosc":"faxons","lemma":"faxer","pos":"VER"} ,
		{"word":"faxé","word_nosc":"faxe","lemma":"faxer","pos":"VER"} ,
		{"word":"faxés","word_nosc":"faxes","lemma":"faxer","pos":"VER"} ,
		{"word":"fayotaient","word_nosc":"fayotaient","lemma":"fayoter","pos":"VER"} ,
		{"word":"fayotais","word_nosc":"fayotais","lemma":"fayoter","pos":"VER"} ,
		{"word":"fayotait","word_nosc":"fayotait","lemma":"fayoter","pos":"VER"} ,
		{"word":"fayote","word_nosc":"fayote","lemma":"fayoter","pos":"VER"} ,
		{"word":"fayoter","word_nosc":"fayoter","lemma":"fayoter","pos":"VER"} ,
		{"word":"fayotte","word_nosc":"fayotte","lemma":"fayotter","pos":"VER"} ,
		{"word":"fayotée","word_nosc":"fayotee","lemma":"fayoter","pos":"VER"} ,
		{"word":"façonna","word_nosc":"faconna","lemma":"façonner","pos":"VER"} ,
		{"word":"façonnaient","word_nosc":"faconnaient","lemma":"façonner","pos":"VER"} ,
		{"word":"façonnait","word_nosc":"faconnait","lemma":"façonner","pos":"VER"} ,
		{"word":"façonnant","word_nosc":"faconnant","lemma":"façonner","pos":"VER"} ,
		{"word":"façonne","word_nosc":"faconne","lemma":"façonner","pos":"VER"} ,
		{"word":"façonnent","word_nosc":"faconnent","lemma":"façonner","pos":"VER"} ,
		{"word":"façonner","word_nosc":"faconner","lemma":"façonner","pos":"VER"} ,
		{"word":"façonnera","word_nosc":"faconnera","lemma":"façonner","pos":"VER"} ,
		{"word":"façonnerai","word_nosc":"faconnerai","lemma":"façonner","pos":"VER"} ,
		{"word":"façonnerais","word_nosc":"faconnerais","lemma":"façonner","pos":"VER"} ,
		{"word":"façonnerez","word_nosc":"faconnerez","lemma":"façonner","pos":"VER"} ,
		{"word":"façonneront","word_nosc":"faconneront","lemma":"façonner","pos":"VER"} ,
		{"word":"façonné","word_nosc":"faconne","lemma":"façonner","pos":"VER"} ,
		{"word":"façonnée","word_nosc":"faconnee","lemma":"façonner","pos":"VER"} ,
		{"word":"façonnées","word_nosc":"faconnees","lemma":"façonner","pos":"VER"} ,
		{"word":"façonnés","word_nosc":"faconnes","lemma":"façonner","pos":"VER"} ,
		{"word":"feignaient","word_nosc":"feignaient","lemma":"feindre","pos":"VER"} ,
		{"word":"feignais","word_nosc":"feignais","lemma":"feindre","pos":"VER"} ,
		{"word":"feignait","word_nosc":"feignait","lemma":"feindre","pos":"VER"} ,
		{"word":"feignant","word_nosc":"feignant","lemma":"feindre","pos":"VER"} ,
		{"word":"feigne","word_nosc":"feigne","lemma":"feindre","pos":"VER"} ,
		{"word":"feignent","word_nosc":"feignent","lemma":"feindre","pos":"VER"} ,
		{"word":"feignez","word_nosc":"feignez","lemma":"feindre","pos":"VER"} ,
		{"word":"feigniez","word_nosc":"feigniez","lemma":"feindre","pos":"VER"} ,
		{"word":"feignirent","word_nosc":"feignirent","lemma":"feindre","pos":"VER"} ,
		{"word":"feignis","word_nosc":"feignis","lemma":"feindre","pos":"VER"} ,
		{"word":"feignit","word_nosc":"feignit","lemma":"feindre","pos":"VER"} ,
		{"word":"feignons","word_nosc":"feignons","lemma":"feindre","pos":"VER"} ,
		{"word":"feignîmes","word_nosc":"feignimes","lemma":"feindre","pos":"VER"} ,
		{"word":"feindra","word_nosc":"feindra","lemma":"feindre","pos":"VER"} ,
		{"word":"feindrai","word_nosc":"feindrai","lemma":"feindre","pos":"VER"} ,
		{"word":"feindrait","word_nosc":"feindrait","lemma":"feindre","pos":"VER"} ,
		{"word":"feindre","word_nosc":"feindre","lemma":"feindre","pos":"VER"} ,
		{"word":"feins","word_nosc":"feins","lemma":"feindre","pos":"VER"} ,
		{"word":"feint","word_nosc":"feint","lemma":"feindre","pos":"VER"} ,
		{"word":"feintait","word_nosc":"feintait","lemma":"feinter","pos":"VER"} ,
		{"word":"feintant","word_nosc":"feintant","lemma":"feinter","pos":"VER"} ,
		{"word":"feinte","word_nosc":"feinte","lemma":"feinter","pos":"VER"} ,
		{"word":"feinter","word_nosc":"feinter","lemma":"feinter","pos":"VER"} ,
		{"word":"feinterez","word_nosc":"feinterez","lemma":"feinter","pos":"VER"} ,
		{"word":"feintes","word_nosc":"feintes","lemma":"feinter","pos":"VER"} ,
		{"word":"feints","word_nosc":"feints","lemma":"feindre","pos":"VER"} ,
		{"word":"feinté","word_nosc":"feinte","lemma":"feinter","pos":"VER"} ,
		{"word":"feintés","word_nosc":"feintes","lemma":"feinter","pos":"VER"} ,
		{"word":"fellationne","word_nosc":"fellationne","lemma":"fellationner","pos":"VER"} ,
		{"word":"fend","word_nosc":"fend","lemma":"fendre","pos":"VER"} ,
		{"word":"fendaient","word_nosc":"fendaient","lemma":"fendre","pos":"VER"} ,
		{"word":"fendais","word_nosc":"fendais","lemma":"fendre","pos":"VER"} ,
		{"word":"fendait","word_nosc":"fendait","lemma":"fendre","pos":"VER"} ,
		{"word":"fendant","word_nosc":"fendant","lemma":"fendre","pos":"VER"} ,
		{"word":"fende","word_nosc":"fende","lemma":"fendre","pos":"VER"} ,
		{"word":"fendent","word_nosc":"fendent","lemma":"fendre","pos":"VER"} ,
		{"word":"fendez","word_nosc":"fendez","lemma":"fendre","pos":"VER"} ,
		{"word":"fendillaient","word_nosc":"fendillaient","lemma":"fendiller","pos":"VER"} ,
		{"word":"fendillait","word_nosc":"fendillait","lemma":"fendiller","pos":"VER"} ,
		{"word":"fendille","word_nosc":"fendille","lemma":"fendiller","pos":"VER"} ,
		{"word":"fendiller","word_nosc":"fendiller","lemma":"fendiller","pos":"VER"} ,
		{"word":"fendillèrent","word_nosc":"fendillerent","lemma":"fendiller","pos":"VER"} ,
		{"word":"fendillé","word_nosc":"fendille","lemma":"fendiller","pos":"VER"} ,
		{"word":"fendillée","word_nosc":"fendillee","lemma":"fendiller","pos":"VER"} ,
		{"word":"fendillés","word_nosc":"fendilles","lemma":"fendiller","pos":"VER"} ,
		{"word":"fendis","word_nosc":"fendis","lemma":"fendre","pos":"VER"} ,
		{"word":"fendissent","word_nosc":"fendissent","lemma":"fendre","pos":"VER"} ,
		{"word":"fendit","word_nosc":"fendit","lemma":"fendre","pos":"VER"} ,
		{"word":"fendons","word_nosc":"fendons","lemma":"fendre","pos":"VER"} ,
		{"word":"fendra","word_nosc":"fendra","lemma":"fendre","pos":"VER"} ,
		{"word":"fendrai","word_nosc":"fendrai","lemma":"fendre","pos":"VER"} ,
		{"word":"fendraient","word_nosc":"fendraient","lemma":"fendre","pos":"VER"} ,
		{"word":"fendrait","word_nosc":"fendrait","lemma":"fendre","pos":"VER"} ,
		{"word":"fendre","word_nosc":"fendre","lemma":"fendre","pos":"VER"} ,
		{"word":"fends","word_nosc":"fends","lemma":"fendre","pos":"VER"} ,
		{"word":"fendu","word_nosc":"fendu","lemma":"fendre","pos":"VER"} ,
		{"word":"fendue","word_nosc":"fendue","lemma":"fendre","pos":"VER"} ,
		{"word":"fendues","word_nosc":"fendues","lemma":"fendre","pos":"VER"} ,
		{"word":"fendus","word_nosc":"fendus","lemma":"fendre","pos":"VER"} ,
		{"word":"fera","word_nosc":"fera","lemma":"faire","pos":"VER"} ,
		{"word":"ferai","word_nosc":"ferai","lemma":"faire","pos":"VER"} ,
		{"word":"feraient","word_nosc":"feraient","lemma":"faire","pos":"VER"} ,
		{"word":"ferais","word_nosc":"ferais","lemma":"faire","pos":"VER"} ,
		{"word":"ferait","word_nosc":"ferait","lemma":"faire","pos":"VER"} ,
		{"word":"feras","word_nosc":"feras","lemma":"faire","pos":"VER"} ,
		{"word":"ferez","word_nosc":"ferez","lemma":"faire","pos":"VER"} ,
		{"word":"feriez","word_nosc":"feriez","lemma":"faire","pos":"VER"} ,
		{"word":"ferions","word_nosc":"ferions","lemma":"faire","pos":"VER"} ,
		{"word":"ferlez","word_nosc":"ferlez","lemma":"ferler","pos":"VER"} ,
		{"word":"ferlée","word_nosc":"ferlee","lemma":"ferler","pos":"VER"} ,
		{"word":"ferma","word_nosc":"ferma","lemma":"fermer","pos":"VER"} ,
		{"word":"fermai","word_nosc":"fermai","lemma":"fermer","pos":"VER"} ,
		{"word":"fermaient","word_nosc":"fermaient","lemma":"fermer","pos":"VER"} ,
		{"word":"fermais","word_nosc":"fermais","lemma":"fermer","pos":"VER"} ,
		{"word":"fermait","word_nosc":"fermait","lemma":"fermer","pos":"VER"} ,
		{"word":"fermant","word_nosc":"fermant","lemma":"fermer","pos":"VER"} ,
		{"word":"ferme","word_nosc":"ferme","lemma":"fermer","pos":"VER"} ,
		{"word":"ferment","word_nosc":"ferment","lemma":"fermer","pos":"VER"} ,
		{"word":"fermentaient","word_nosc":"fermentaient","lemma":"fermenter","pos":"VER"} ,
		{"word":"fermentais","word_nosc":"fermentais","lemma":"fermenter","pos":"VER"} ,
		{"word":"fermentait","word_nosc":"fermentait","lemma":"fermenter","pos":"VER"} ,
		{"word":"fermente","word_nosc":"fermente","lemma":"fermenter","pos":"VER"} ,
		{"word":"fermenter","word_nosc":"fermenter","lemma":"fermenter","pos":"VER"} ,
		{"word":"fermentèrent","word_nosc":"fermenterent","lemma":"fermenter","pos":"VER"} ,
		{"word":"fermenté","word_nosc":"fermente","lemma":"fermenter","pos":"VER"} ,
		{"word":"fermentée","word_nosc":"fermentee","lemma":"fermenter","pos":"VER"} ,
		{"word":"fermer","word_nosc":"fermer","lemma":"fermer","pos":"VER"} ,
		{"word":"fermera","word_nosc":"fermera","lemma":"fermer","pos":"VER"} ,
		{"word":"fermerai","word_nosc":"fermerai","lemma":"fermer","pos":"VER"} ,
		{"word":"fermeraient","word_nosc":"fermeraient","lemma":"fermer","pos":"VER"} ,
		{"word":"fermerais","word_nosc":"fermerais","lemma":"fermer","pos":"VER"} ,
		{"word":"fermerait","word_nosc":"fermerait","lemma":"fermer","pos":"VER"} ,
		{"word":"fermeras","word_nosc":"fermeras","lemma":"fermer","pos":"VER"} ,
		{"word":"fermerez","word_nosc":"fermerez","lemma":"fermer","pos":"VER"} ,
		{"word":"fermeriez","word_nosc":"fermeriez","lemma":"fermer","pos":"VER"} ,
		{"word":"fermerons","word_nosc":"fermerons","lemma":"fermer","pos":"VER"} ,
		{"word":"fermeront","word_nosc":"fermeront","lemma":"fermer","pos":"VER"} ,
		{"word":"fermes","word_nosc":"fermes","lemma":"fermer","pos":"VER"} ,
		{"word":"fermez","word_nosc":"fermez","lemma":"fermer","pos":"VER"} ,
		{"word":"fermiez","word_nosc":"fermiez","lemma":"fermer","pos":"VER"} ,
		{"word":"fermions","word_nosc":"fermions","lemma":"fermer","pos":"VER"} ,
		{"word":"fermons","word_nosc":"fermons","lemma":"fermer","pos":"VER"} ,
		{"word":"fermât","word_nosc":"fermat","lemma":"fermer","pos":"VER"} ,
		{"word":"fermèrent","word_nosc":"fermerent","lemma":"fermer","pos":"VER"} ,
		{"word":"fermé","word_nosc":"ferme","lemma":"fermer","pos":"VER"} ,
		{"word":"fermée","word_nosc":"fermee","lemma":"fermer","pos":"VER"} ,
		{"word":"fermées","word_nosc":"fermees","lemma":"fermer","pos":"VER"} ,
		{"word":"fermés","word_nosc":"fermes","lemma":"fermer","pos":"VER"} ,
		{"word":"ferons","word_nosc":"ferons","lemma":"faire","pos":"VER"} ,
		{"word":"feront","word_nosc":"feront","lemma":"faire","pos":"VER"} ,
		{"word":"ferra","word_nosc":"ferra","lemma":"ferrer","pos":"VER"} ,
		{"word":"ferrai","word_nosc":"ferrai","lemma":"ferrer","pos":"VER"} ,
		{"word":"ferrailla","word_nosc":"ferrailla","lemma":"ferrailler","pos":"VER"} ,
		{"word":"ferraillaient","word_nosc":"ferraillaient","lemma":"ferrailler","pos":"VER"} ,
		{"word":"ferraillait","word_nosc":"ferraillait","lemma":"ferrailler","pos":"VER"} ,
		{"word":"ferraillant","word_nosc":"ferraillant","lemma":"ferrailler","pos":"VER"} ,
		{"word":"ferraille","word_nosc":"ferraille","lemma":"ferrailler","pos":"VER"} ,
		{"word":"ferrailler","word_nosc":"ferrailler","lemma":"ferrailler","pos":"VER"} ,
		{"word":"ferraillé","word_nosc":"ferraille","lemma":"ferrailler","pos":"VER"} ,
		{"word":"ferrais","word_nosc":"ferrais","lemma":"ferrer","pos":"VER"} ,
		{"word":"ferrait","word_nosc":"ferrait","lemma":"ferrer","pos":"VER"} ,
		{"word":"ferrant","word_nosc":"ferrant","lemma":"ferrer","pos":"VER"} ,
		{"word":"ferrasse","word_nosc":"ferrasse","lemma":"ferrer","pos":"VER"} ,
		{"word":"ferre","word_nosc":"ferre","lemma":"ferrer","pos":"VER"} ,
		{"word":"ferrer","word_nosc":"ferrer","lemma":"ferrer","pos":"VER"} ,
		{"word":"ferrera","word_nosc":"ferrera","lemma":"ferrer","pos":"VER"} ,
		{"word":"ferrerait","word_nosc":"ferrerait","lemma":"ferrer","pos":"VER"} ,
		{"word":"ferrez","word_nosc":"ferrez","lemma":"ferrer","pos":"VER"} ,
		{"word":"ferrons","word_nosc":"ferrons","lemma":"ferrer","pos":"VER"} ,
		{"word":"ferré","word_nosc":"ferre","lemma":"ferrer","pos":"VER"} ,
		{"word":"ferrée","word_nosc":"ferree","lemma":"ferrer","pos":"VER"} ,
		{"word":"ferrées","word_nosc":"ferrees","lemma":"ferrer","pos":"VER"} ,
		{"word":"ferrés","word_nosc":"ferres","lemma":"ferrer","pos":"VER"} ,
		{"word":"fertilisait","word_nosc":"fertilisait","lemma":"fertiliser","pos":"VER"} ,
		{"word":"fertilisant","word_nosc":"fertilisant","lemma":"fertiliser","pos":"VER"} ,
		{"word":"fertilise","word_nosc":"fertilise","lemma":"fertiliser","pos":"VER"} ,
		{"word":"fertilisent","word_nosc":"fertilisent","lemma":"fertiliser","pos":"VER"} ,
		{"word":"fertiliser","word_nosc":"fertiliser","lemma":"fertiliser","pos":"VER"} ,
		{"word":"fertilisera","word_nosc":"fertilisera","lemma":"fertiliser","pos":"VER"} ,
		{"word":"fertiliserons","word_nosc":"fertiliserons","lemma":"fertiliser","pos":"VER"} ,
		{"word":"fertilisez","word_nosc":"fertilisez","lemma":"fertiliser","pos":"VER"} ,
		{"word":"fertilisèrent","word_nosc":"fertiliserent","lemma":"fertiliser","pos":"VER"} ,
		{"word":"fertilisé","word_nosc":"fertilise","lemma":"fertiliser","pos":"VER"} ,
		{"word":"fertilisée","word_nosc":"fertilisee","lemma":"fertiliser","pos":"VER"} ,
		{"word":"fertilisés","word_nosc":"fertilises","lemma":"fertiliser","pos":"VER"} ,
		{"word":"fessait","word_nosc":"fessait","lemma":"fesser","pos":"VER"} ,
		{"word":"fesse","word_nosc":"fesse","lemma":"fesser","pos":"VER"} ,
		{"word":"fesser","word_nosc":"fesser","lemma":"fesser","pos":"VER"} ,
		{"word":"fesserai","word_nosc":"fesserai","lemma":"fesser","pos":"VER"} ,
		{"word":"fesserais","word_nosc":"fesserais","lemma":"fesser","pos":"VER"} ,
		{"word":"fesses","word_nosc":"fesses","lemma":"fesser","pos":"VER"} ,
		{"word":"fessiez","word_nosc":"fessiez","lemma":"fesser","pos":"VER"} ,
		{"word":"fessé","word_nosc":"fesse","lemma":"fesser","pos":"VER"} ,
		{"word":"fessée","word_nosc":"fessee","lemma":"fesser","pos":"VER"} ,
		{"word":"fessées","word_nosc":"fessees","lemma":"fesser","pos":"VER"} ,
		{"word":"fessés","word_nosc":"fesses","lemma":"fesser","pos":"VER"} ,
		{"word":"festoie","word_nosc":"festoie","lemma":"festoyer","pos":"VER"} ,
		{"word":"festoient","word_nosc":"festoient","lemma":"festoyer","pos":"VER"} ,
		{"word":"festoiera","word_nosc":"festoiera","lemma":"festoyer","pos":"VER"} ,
		{"word":"festoierons","word_nosc":"festoierons","lemma":"festoyer","pos":"VER"} ,
		{"word":"festonnaient","word_nosc":"festonnaient","lemma":"festonner","pos":"VER"} ,
		{"word":"festonnait","word_nosc":"festonnait","lemma":"festonner","pos":"VER"} ,
		{"word":"festonne","word_nosc":"festonne","lemma":"festonner","pos":"VER"} ,
		{"word":"festonner","word_nosc":"festonner","lemma":"festonner","pos":"VER"} ,
		{"word":"festonné","word_nosc":"festonne","lemma":"festonner","pos":"VER"} ,
		{"word":"festonnée","word_nosc":"festonnee","lemma":"festonner","pos":"VER"} ,
		{"word":"festonnées","word_nosc":"festonnees","lemma":"festonner","pos":"VER"} ,
		{"word":"festonnés","word_nosc":"festonnes","lemma":"festonner","pos":"VER"} ,
		{"word":"festoya","word_nosc":"festoya","lemma":"festoyer","pos":"VER"} ,
		{"word":"festoyaient","word_nosc":"festoyaient","lemma":"festoyer","pos":"VER"} ,
		{"word":"festoyant","word_nosc":"festoyant","lemma":"festoyer","pos":"VER"} ,
		{"word":"festoyer","word_nosc":"festoyer","lemma":"festoyer","pos":"VER"} ,
		{"word":"festoyez","word_nosc":"festoyez","lemma":"festoyer","pos":"VER"} ,
		{"word":"festoyé","word_nosc":"festoye","lemma":"festoyer","pos":"VER"} ,
		{"word":"feuilleta","word_nosc":"feuilleta","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuilletai","word_nosc":"feuilletai","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuilletaient","word_nosc":"feuilletaient","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuilletais","word_nosc":"feuilletais","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuilletait","word_nosc":"feuilletait","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuilletant","word_nosc":"feuilletant","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuilleter","word_nosc":"feuilleter","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuilletez","word_nosc":"feuilletez","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuilletiez","word_nosc":"feuilletiez","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuilletions","word_nosc":"feuilletions","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuillette","word_nosc":"feuillette","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuillettent","word_nosc":"feuillettent","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuillettes","word_nosc":"feuillettes","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuilletèrent","word_nosc":"feuilleterent","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuilleté","word_nosc":"feuillete","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuilletée","word_nosc":"feuilletee","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feuilletés","word_nosc":"feuilletes","lemma":"feuilleter","pos":"VER"} ,
		{"word":"feula","word_nosc":"feula","lemma":"feuler","pos":"VER"} ,
		{"word":"feulait","word_nosc":"feulait","lemma":"feuler","pos":"VER"} ,
		{"word":"feulant","word_nosc":"feulant","lemma":"feuler","pos":"VER"} ,
		{"word":"feule","word_nosc":"feule","lemma":"feuler","pos":"VER"} ,
		{"word":"feulent","word_nosc":"feulent","lemma":"feuler","pos":"VER"} ,
		{"word":"feuler","word_nosc":"feuler","lemma":"feuler","pos":"VER"} ,
		{"word":"feulée","word_nosc":"feulee","lemma":"feuler","pos":"VER"} ,
		{"word":"feutra","word_nosc":"feutra","lemma":"feutrer","pos":"VER"} ,
		{"word":"feutrait","word_nosc":"feutrait","lemma":"feutrer","pos":"VER"} ,
		{"word":"feutrant","word_nosc":"feutrant","lemma":"feutrer","pos":"VER"} ,
		{"word":"feutre","word_nosc":"feutre","lemma":"feutrer","pos":"VER"} ,
		{"word":"feutrent","word_nosc":"feutrent","lemma":"feutrer","pos":"VER"} ,
		{"word":"feutrer","word_nosc":"feutrer","lemma":"feutrer","pos":"VER"} ,
		{"word":"feutres","word_nosc":"feutres","lemma":"feutrer","pos":"VER"} ,
		{"word":"feutré","word_nosc":"feutre","lemma":"feutrer","pos":"VER"} ,
		{"word":"feutrée","word_nosc":"feutree","lemma":"feutrer","pos":"VER"} ,
		{"word":"feutrées","word_nosc":"feutrees","lemma":"feutrer","pos":"VER"} ,
		{"word":"feutrés","word_nosc":"feutres","lemma":"feutrer","pos":"VER"} ,
		{"word":"fia","word_nosc":"fia","lemma":"fier","pos":"VER"} ,
		{"word":"fiaient","word_nosc":"fiaient","lemma":"fier","pos":"VER"} ,
		{"word":"fiais","word_nosc":"fiais","lemma":"fier","pos":"VER"} ,
		{"word":"fiait","word_nosc":"fiait","lemma":"fier","pos":"VER"} ,
		{"word":"fiance","word_nosc":"fiance","lemma":"fiancer","pos":"VER"} ,
		{"word":"fiancent","word_nosc":"fiancent","lemma":"fiancer","pos":"VER"} ,
		{"word":"fiancer","word_nosc":"fiancer","lemma":"fiancer","pos":"VER"} ,
		{"word":"fiancerais","word_nosc":"fiancerais","lemma":"fiancer","pos":"VER"} ,
		{"word":"fiancerons","word_nosc":"fiancerons","lemma":"fiancer","pos":"VER"} ,
		{"word":"fiancez","word_nosc":"fiancez","lemma":"fiancer","pos":"VER"} ,
		{"word":"fiancèrent","word_nosc":"fiancerent","lemma":"fiancer","pos":"VER"} ,
		{"word":"fiancé","word_nosc":"fiance","lemma":"fiancer","pos":"VER"} ,
		{"word":"fiancée","word_nosc":"fiancee","lemma":"fiancer","pos":"VER"} ,
		{"word":"fiancées","word_nosc":"fiancees","lemma":"fiancer","pos":"VER"} ,
		{"word":"fiancés","word_nosc":"fiances","lemma":"fiancer","pos":"VER"} ,
		{"word":"fiant","word_nosc":"fiant","lemma":"fier","pos":"VER"} ,
		{"word":"fiança","word_nosc":"fianca","lemma":"fiancer","pos":"VER"} ,
		{"word":"fiançais","word_nosc":"fiancais","lemma":"fiancer","pos":"VER"} ,
		{"word":"fiançons","word_nosc":"fiancons","lemma":"fiancer","pos":"VER"} ,
		{"word":"fias","word_nosc":"fias","lemma":"fier","pos":"VER"} ,
		{"word":"fiassent","word_nosc":"fiassent","lemma":"fier","pos":"VER"} ,
		{"word":"ficela","word_nosc":"ficela","lemma":"ficeler","pos":"VER"} ,
		{"word":"ficelai","word_nosc":"ficelai","lemma":"ficeler","pos":"VER"} ,
		{"word":"ficelaient","word_nosc":"ficelaient","lemma":"ficeler","pos":"VER"} ,
		{"word":"ficelait","word_nosc":"ficelait","lemma":"ficeler","pos":"VER"} ,
		{"word":"ficelant","word_nosc":"ficelant","lemma":"ficeler","pos":"VER"} ,
		{"word":"ficeler","word_nosc":"ficeler","lemma":"ficeler","pos":"VER"} ,
		{"word":"ficelez","word_nosc":"ficelez","lemma":"ficeler","pos":"VER"} ,
		{"word":"ficelle","word_nosc":"ficelle","lemma":"ficeler","pos":"VER"} ,
		{"word":"ficellerait","word_nosc":"ficellerait","lemma":"ficeler","pos":"VER"} ,
		{"word":"ficelles","word_nosc":"ficelles","lemma":"ficeler","pos":"VER"} ,
		{"word":"ficelèrent","word_nosc":"ficelerent","lemma":"ficeler","pos":"VER"} ,
		{"word":"ficelé","word_nosc":"ficele","lemma":"ficeler","pos":"VER"} ,
		{"word":"ficelée","word_nosc":"ficelee","lemma":"ficeler","pos":"VER"} ,
		{"word":"ficelées","word_nosc":"ficelees","lemma":"ficeler","pos":"VER"} ,
		{"word":"ficelés","word_nosc":"ficeles","lemma":"ficeler","pos":"VER"} ,
		{"word":"ficha","word_nosc":"ficha","lemma":"ficher","pos":"VER"} ,
		{"word":"fichaient","word_nosc":"fichaient","lemma":"ficher","pos":"VER"} ,
		{"word":"fichais","word_nosc":"fichais","lemma":"ficher","pos":"VER"} ,
		{"word":"fichait","word_nosc":"fichait","lemma":"ficher","pos":"VER"} ,
		{"word":"fichant","word_nosc":"fichant","lemma":"ficher","pos":"VER"} ,
		{"word":"fiche","word_nosc":"fiche","lemma":"ficher","pos":"VER"} ,
		{"word":"fichent","word_nosc":"fichent","lemma":"ficher","pos":"VER"} ,
		{"word":"ficher","word_nosc":"ficher","lemma":"ficher","pos":"VER"} ,
		{"word":"fichera","word_nosc":"fichera","lemma":"ficher","pos":"VER"} ,
		{"word":"ficherai","word_nosc":"ficherai","lemma":"ficher","pos":"VER"} ,
		{"word":"ficheraient","word_nosc":"ficheraient","lemma":"ficher","pos":"VER"} ,
		{"word":"ficherais","word_nosc":"ficherais","lemma":"ficher","pos":"VER"} ,
		{"word":"ficherait","word_nosc":"ficherait","lemma":"ficher","pos":"VER"} ,
		{"word":"ficheras","word_nosc":"ficheras","lemma":"ficher","pos":"VER"} ,
		{"word":"ficherons","word_nosc":"ficherons","lemma":"ficher","pos":"VER"} ,
		{"word":"ficheront","word_nosc":"ficheront","lemma":"ficher","pos":"VER"} ,
		{"word":"fiches","word_nosc":"fiches","lemma":"ficher","pos":"VER"} ,
		{"word":"fichez","word_nosc":"fichez","lemma":"ficher","pos":"VER"} ,
		{"word":"fichiez","word_nosc":"fichiez","lemma":"ficher","pos":"VER"} ,
		{"word":"fichions","word_nosc":"fichions","lemma":"ficher","pos":"VER"} ,
		{"word":"fichons","word_nosc":"fichons","lemma":"ficher","pos":"VER"} ,
		{"word":"fichât","word_nosc":"fichat","lemma":"ficher","pos":"VER"} ,
		{"word":"fiché","word_nosc":"fiche","lemma":"ficher","pos":"VER"} ,
		{"word":"fichée","word_nosc":"fichee","lemma":"ficher","pos":"VER"} ,
		{"word":"fichées","word_nosc":"fichees","lemma":"ficher","pos":"VER"} ,
		{"word":"fichés","word_nosc":"fiches","lemma":"ficher","pos":"VER"} ,
		{"word":"fidéliser","word_nosc":"fideliser","lemma":"fidéliser","pos":"VER"} ,
		{"word":"fidéliseras","word_nosc":"fideliseras","lemma":"fidéliser","pos":"VER"} ,
		{"word":"fie","word_nosc":"fie","lemma":"fier","pos":"VER"} ,
		{"word":"fieffé","word_nosc":"fieffe","lemma":"fieffer","pos":"VER"} ,
		{"word":"fieffée","word_nosc":"fieffee","lemma":"fieffer","pos":"VER"} ,
		{"word":"fient","word_nosc":"fient","lemma":"fier","pos":"VER"} ,
		{"word":"fiente","word_nosc":"fiente","lemma":"fienter","pos":"VER"} ,
		{"word":"fienté","word_nosc":"fiente","lemma":"fienter","pos":"VER"} ,
		{"word":"fier","word_nosc":"fier","lemma":"fier","pos":"VER"} ,
		{"word":"fiera","word_nosc":"fiera","lemma":"fier","pos":"VER"} ,
		{"word":"fierai","word_nosc":"fierai","lemma":"fier","pos":"VER"} ,
		{"word":"fierais","word_nosc":"fierais","lemma":"fier","pos":"VER"} ,
		{"word":"fieras","word_nosc":"fieras","lemma":"fier","pos":"VER"} ,
		{"word":"fiez","word_nosc":"fiez","lemma":"fier","pos":"VER"} ,
		{"word":"fige","word_nosc":"fige","lemma":"figer","pos":"VER"} ,
		{"word":"figea","word_nosc":"figea","lemma":"figer","pos":"VER"} ,
		{"word":"figeaient","word_nosc":"figeaient","lemma":"figer","pos":"VER"} ,
		{"word":"figeais","word_nosc":"figeais","lemma":"figer","pos":"VER"} ,
		{"word":"figeait","word_nosc":"figeait","lemma":"figer","pos":"VER"} ,
		{"word":"figeant","word_nosc":"figeant","lemma":"figer","pos":"VER"} ,
		{"word":"figent","word_nosc":"figent","lemma":"figer","pos":"VER"} ,
		{"word":"figer","word_nosc":"figer","lemma":"figer","pos":"VER"} ,
		{"word":"figera","word_nosc":"figera","lemma":"figer","pos":"VER"} ,
		{"word":"figerai","word_nosc":"figerai","lemma":"figer","pos":"VER"} ,
		{"word":"figeraient","word_nosc":"figeraient","lemma":"figer","pos":"VER"} ,
		{"word":"figerait","word_nosc":"figerait","lemma":"figer","pos":"VER"} ,
		{"word":"figez","word_nosc":"figez","lemma":"figer","pos":"VER"} ,
		{"word":"fignola","word_nosc":"fignola","lemma":"fignoler","pos":"VER"} ,
		{"word":"fignolaient","word_nosc":"fignolaient","lemma":"fignoler","pos":"VER"} ,
		{"word":"fignolait","word_nosc":"fignolait","lemma":"fignoler","pos":"VER"} ,
		{"word":"fignolant","word_nosc":"fignolant","lemma":"fignoler","pos":"VER"} ,
		{"word":"fignole","word_nosc":"fignole","lemma":"fignoler","pos":"VER"} ,
		{"word":"fignolent","word_nosc":"fignolent","lemma":"fignoler","pos":"VER"} ,
		{"word":"fignoler","word_nosc":"fignoler","lemma":"fignoler","pos":"VER"} ,
		{"word":"fignolerai","word_nosc":"fignolerai","lemma":"fignoler","pos":"VER"} ,
		{"word":"fignolerais","word_nosc":"fignolerais","lemma":"fignoler","pos":"VER"} ,
		{"word":"fignolerait","word_nosc":"fignolerait","lemma":"fignoler","pos":"VER"} ,
		{"word":"fignolons","word_nosc":"fignolons","lemma":"fignoler","pos":"VER"} ,
		{"word":"fignolé","word_nosc":"fignole","lemma":"fignoler","pos":"VER"} ,
		{"word":"fignolée","word_nosc":"fignolee","lemma":"fignoler","pos":"VER"} ,
		{"word":"fignolés","word_nosc":"fignoles","lemma":"fignoler","pos":"VER"} ,
		{"word":"figura","word_nosc":"figura","lemma":"figurer","pos":"VER"} ,
		{"word":"figurai","word_nosc":"figurai","lemma":"figurer","pos":"VER"} ,
		{"word":"figuraient","word_nosc":"figuraient","lemma":"figurer","pos":"VER"} ,
		{"word":"figurais","word_nosc":"figurais","lemma":"figurer","pos":"VER"} ,
		{"word":"figurait","word_nosc":"figurait","lemma":"figurer","pos":"VER"} ,
		{"word":"figurant","word_nosc":"figurant","lemma":"figurer","pos":"VER"} ,
		{"word":"figure","word_nosc":"figure","lemma":"figurer","pos":"VER"} ,
		{"word":"figurent","word_nosc":"figurent","lemma":"figurer","pos":"VER"} ,
		{"word":"figurer","word_nosc":"figurer","lemma":"figurer","pos":"VER"} ,
		{"word":"figurera","word_nosc":"figurera","lemma":"figurer","pos":"VER"} ,
		{"word":"figureraient","word_nosc":"figureraient","lemma":"figurer","pos":"VER"} ,
		{"word":"figurerait","word_nosc":"figurerait","lemma":"figurer","pos":"VER"} ,
		{"word":"figures","word_nosc":"figures","lemma":"figurer","pos":"VER"} ,
		{"word":"figurez","word_nosc":"figurez","lemma":"figurer","pos":"VER"} ,
		{"word":"figuriez","word_nosc":"figuriez","lemma":"figurer","pos":"VER"} ,
		{"word":"figurions","word_nosc":"figurions","lemma":"figurer","pos":"VER"} ,
		{"word":"figurât","word_nosc":"figurat","lemma":"figurer","pos":"VER"} ,
		{"word":"figurèrent","word_nosc":"figurerent","lemma":"figurer","pos":"VER"} ,
		{"word":"figuré","word_nosc":"figure","lemma":"figurer","pos":"VER"} ,
		{"word":"figurée","word_nosc":"figuree","lemma":"figurer","pos":"VER"} ,
		{"word":"figurées","word_nosc":"figurees","lemma":"figurer","pos":"VER"} ,
		{"word":"figurés","word_nosc":"figures","lemma":"figurer","pos":"VER"} ,
		{"word":"figèrent","word_nosc":"figerent","lemma":"figer","pos":"VER"} ,
		{"word":"figé","word_nosc":"fige","lemma":"figer","pos":"VER"} ,
		{"word":"figée","word_nosc":"figee","lemma":"figer","pos":"VER"} ,
		{"word":"figées","word_nosc":"figees","lemma":"figer","pos":"VER"} ,
		{"word":"figés","word_nosc":"figes","lemma":"figer","pos":"VER"} ,
		{"word":"fila","word_nosc":"fila","lemma":"filer","pos":"VER"} ,
		{"word":"filai","word_nosc":"filai","lemma":"filer","pos":"VER"} ,
		{"word":"filaient","word_nosc":"filaient","lemma":"filer","pos":"VER"} ,
		{"word":"filais","word_nosc":"filais","lemma":"filer","pos":"VER"} ,
		{"word":"filait","word_nosc":"filait","lemma":"filer","pos":"VER"} ,
		{"word":"filant","word_nosc":"filant","lemma":"filer","pos":"VER"} ,
		{"word":"file","word_nosc":"file","lemma":"filer","pos":"VER"} ,
		{"word":"filent","word_nosc":"filent","lemma":"filer","pos":"VER"} ,
		{"word":"filer","word_nosc":"filer","lemma":"filer","pos":"VER"} ,
		{"word":"filera","word_nosc":"filera","lemma":"filer","pos":"VER"} ,
		{"word":"filerai","word_nosc":"filerai","lemma":"filer","pos":"VER"} ,
		{"word":"fileraient","word_nosc":"fileraient","lemma":"filer","pos":"VER"} ,
		{"word":"filerais","word_nosc":"filerais","lemma":"filer","pos":"VER"} ,
		{"word":"filerait","word_nosc":"filerait","lemma":"filer","pos":"VER"} ,
		{"word":"fileras","word_nosc":"fileras","lemma":"filer","pos":"VER"} ,
		{"word":"filerez","word_nosc":"filerez","lemma":"filer","pos":"VER"} ,
		{"word":"fileriez","word_nosc":"fileriez","lemma":"filer","pos":"VER"} ,
		{"word":"filerons","word_nosc":"filerons","lemma":"filer","pos":"VER"} ,
		{"word":"fileront","word_nosc":"fileront","lemma":"filer","pos":"VER"} ,
		{"word":"files","word_nosc":"files","lemma":"filer","pos":"VER"} ,
		{"word":"fileté","word_nosc":"filete","lemma":"fileter","pos":"VER"} ,
		{"word":"filetée","word_nosc":"filetee","lemma":"fileter","pos":"VER"} ,
		{"word":"filez","word_nosc":"filez","lemma":"filer","pos":"VER"} ,
		{"word":"filiez","word_nosc":"filiez","lemma":"filer","pos":"VER"} ,
		{"word":"filigranés","word_nosc":"filigranes","lemma":"filigraner","pos":"VER"} ,
		{"word":"filions","word_nosc":"filions","lemma":"filer","pos":"VER"} ,
		{"word":"filma","word_nosc":"filma","lemma":"filmer","pos":"VER"} ,
		{"word":"filmaient","word_nosc":"filmaient","lemma":"filmer","pos":"VER"} ,
		{"word":"filmais","word_nosc":"filmais","lemma":"filmer","pos":"VER"} ,
		{"word":"filmait","word_nosc":"filmait","lemma":"filmer","pos":"VER"} ,
		{"word":"filmant","word_nosc":"filmant","lemma":"filmer","pos":"VER"} ,
		{"word":"filme","word_nosc":"filme","lemma":"filmer","pos":"VER"} ,
		{"word":"filment","word_nosc":"filment","lemma":"filmer","pos":"VER"} ,
		{"word":"filmer","word_nosc":"filmer","lemma":"filmer","pos":"VER"} ,
		{"word":"filmera","word_nosc":"filmera","lemma":"filmer","pos":"VER"} ,
		{"word":"filmerai","word_nosc":"filmerai","lemma":"filmer","pos":"VER"} ,
		{"word":"filmerais","word_nosc":"filmerais","lemma":"filmer","pos":"VER"} ,
		{"word":"filmerait","word_nosc":"filmerait","lemma":"filmer","pos":"VER"} ,
		{"word":"filmerez","word_nosc":"filmerez","lemma":"filmer","pos":"VER"} ,
		{"word":"filmeront","word_nosc":"filmeront","lemma":"filmer","pos":"VER"} ,
		{"word":"filmes","word_nosc":"filmes","lemma":"filmer","pos":"VER"} ,
		{"word":"filmez","word_nosc":"filmez","lemma":"filmer","pos":"VER"} ,
		{"word":"filmiez","word_nosc":"filmiez","lemma":"filmer","pos":"VER"} ,
		{"word":"filmions","word_nosc":"filmions","lemma":"filmer","pos":"VER"} ,
		{"word":"filmons","word_nosc":"filmons","lemma":"filmer","pos":"VER"} ,
		{"word":"filmé","word_nosc":"filme","lemma":"filmer","pos":"VER"} ,
		{"word":"filmée","word_nosc":"filmee","lemma":"filmer","pos":"VER"} ,
		{"word":"filmées","word_nosc":"filmees","lemma":"filmer","pos":"VER"} ,
		{"word":"filmés","word_nosc":"filmes","lemma":"filmer","pos":"VER"} ,
		{"word":"filocher","word_nosc":"filocher","lemma":"filocher","pos":"VER"} ,
		{"word":"filochez","word_nosc":"filochez","lemma":"filocher","pos":"VER"} ,
		{"word":"filoché","word_nosc":"filoche","lemma":"filocher","pos":"VER"} ,
		{"word":"filons","word_nosc":"filons","lemma":"filer","pos":"VER"} ,
		{"word":"filouter","word_nosc":"filouter","lemma":"filouter","pos":"VER"} ,
		{"word":"filoutes","word_nosc":"filoutes","lemma":"filouter","pos":"VER"} ,
		{"word":"filouté","word_nosc":"filoute","lemma":"filouter","pos":"VER"} ,
		{"word":"filoutés","word_nosc":"filoutes","lemma":"filouter","pos":"VER"} ,
		{"word":"filtra","word_nosc":"filtra","lemma":"filtrer","pos":"VER"} ,
		{"word":"filtraient","word_nosc":"filtraient","lemma":"filtrer","pos":"VER"} ,
		{"word":"filtrais","word_nosc":"filtrais","lemma":"filtrer","pos":"VER"} ,
		{"word":"filtrait","word_nosc":"filtrait","lemma":"filtrer","pos":"VER"} ,
		{"word":"filtrant","word_nosc":"filtrant","lemma":"filtrer","pos":"VER"} ,
		{"word":"filtre","word_nosc":"filtre","lemma":"filtrer","pos":"VER"} ,
		{"word":"filtrent","word_nosc":"filtrent","lemma":"filtrer","pos":"VER"} ,
		{"word":"filtrer","word_nosc":"filtrer","lemma":"filtrer","pos":"VER"} ,
		{"word":"filtrerait","word_nosc":"filtrerait","lemma":"filtrer","pos":"VER"} ,
		{"word":"filtres","word_nosc":"filtres","lemma":"filtrer","pos":"VER"} ,
		{"word":"filtrez","word_nosc":"filtrez","lemma":"filtrer","pos":"VER"} ,
		{"word":"filtrèrent","word_nosc":"filtrerent","lemma":"filtrer","pos":"VER"} ,
		{"word":"filtré","word_nosc":"filtre","lemma":"filtrer","pos":"VER"} ,
		{"word":"filtrée","word_nosc":"filtree","lemma":"filtrer","pos":"VER"} ,
		{"word":"filtrées","word_nosc":"filtrees","lemma":"filtrer","pos":"VER"} ,
		{"word":"filtrés","word_nosc":"filtres","lemma":"filtrer","pos":"VER"} ,
		{"word":"filâmes","word_nosc":"filames","lemma":"filer","pos":"VER"} ,
		{"word":"filât","word_nosc":"filat","lemma":"filer","pos":"VER"} ,
		{"word":"filèrent","word_nosc":"filerent","lemma":"filer","pos":"VER"} ,
		{"word":"filé","word_nosc":"file","lemma":"filer","pos":"VER"} ,
		{"word":"filée","word_nosc":"filee","lemma":"filer","pos":"VER"} ,
		{"word":"filées","word_nosc":"filees","lemma":"filer","pos":"VER"} ,
		{"word":"filés","word_nosc":"files","lemma":"filer","pos":"VER"} ,
		{"word":"finaliser","word_nosc":"finaliser","lemma":"finaliser","pos":"VER"} ,
		{"word":"finalisé","word_nosc":"finalise","lemma":"finaliser","pos":"VER"} ,
		{"word":"finalisées","word_nosc":"finalisees","lemma":"finaliser","pos":"VER"} ,
		{"word":"finance","word_nosc":"finance","lemma":"financer","pos":"VER"} ,
		{"word":"financent","word_nosc":"financent","lemma":"financer","pos":"VER"} ,
		{"word":"financer","word_nosc":"financer","lemma":"financer","pos":"VER"} ,
		{"word":"financera","word_nosc":"financera","lemma":"financer","pos":"VER"} ,
		{"word":"financerai","word_nosc":"financerai","lemma":"financer","pos":"VER"} ,
		{"word":"financeraient","word_nosc":"financeraient","lemma":"financer","pos":"VER"} ,
		{"word":"financerait","word_nosc":"financerait","lemma":"financer","pos":"VER"} ,
		{"word":"financeras","word_nosc":"financeras","lemma":"financer","pos":"VER"} ,
		{"word":"financerons","word_nosc":"financerons","lemma":"financer","pos":"VER"} ,
		{"word":"financeront","word_nosc":"financeront","lemma":"financer","pos":"VER"} ,
		{"word":"finances","word_nosc":"finances","lemma":"financer","pos":"VER"} ,
		{"word":"financez","word_nosc":"financez","lemma":"financer","pos":"VER"} ,
		{"word":"financiez","word_nosc":"financiez","lemma":"financer","pos":"VER"} ,
		{"word":"financé","word_nosc":"finance","lemma":"financer","pos":"VER"} ,
		{"word":"financée","word_nosc":"financee","lemma":"financer","pos":"VER"} ,
		{"word":"financés","word_nosc":"finances","lemma":"financer","pos":"VER"} ,
		{"word":"finançai","word_nosc":"financai","lemma":"financer","pos":"VER"} ,
		{"word":"finançaient","word_nosc":"financaient","lemma":"financer","pos":"VER"} ,
		{"word":"finançait","word_nosc":"financait","lemma":"financer","pos":"VER"} ,
		{"word":"finançant","word_nosc":"financant","lemma":"financer","pos":"VER"} ,
		{"word":"finançons","word_nosc":"financons","lemma":"financer","pos":"VER"} ,
		{"word":"finassait","word_nosc":"finassait","lemma":"finasser","pos":"VER"} ,
		{"word":"finasse","word_nosc":"finasse","lemma":"finasser","pos":"VER"} ,
		{"word":"finasser","word_nosc":"finasser","lemma":"finasser","pos":"VER"} ,
		{"word":"finasses","word_nosc":"finasses","lemma":"finasser","pos":"VER"} ,
		{"word":"finassons","word_nosc":"finassons","lemma":"finasser","pos":"VER"} ,
		{"word":"finassé","word_nosc":"finasse","lemma":"finasser","pos":"VER"} ,
		{"word":"fini","word_nosc":"fini","lemma":"finir","pos":"VER"} ,
		{"word":"finie","word_nosc":"finie","lemma":"finir","pos":"VER"} ,
		{"word":"finies","word_nosc":"finies","lemma":"finir","pos":"VER"} ,
		{"word":"finir","word_nosc":"finir","lemma":"finir","pos":"VER"} ,
		{"word":"finira","word_nosc":"finira","lemma":"finir","pos":"VER"} ,
		{"word":"finirai","word_nosc":"finirai","lemma":"finir","pos":"VER"} ,
		{"word":"finiraient","word_nosc":"finiraient","lemma":"finir","pos":"VER"} ,
		{"word":"finirais","word_nosc":"finirais","lemma":"finir","pos":"VER"} ,
		{"word":"finirait","word_nosc":"finirait","lemma":"finir","pos":"VER"} ,
		{"word":"finiras","word_nosc":"finiras","lemma":"finir","pos":"VER"} ,
		{"word":"finirent","word_nosc":"finirent","lemma":"finir","pos":"VER"} ,
		{"word":"finirez","word_nosc":"finirez","lemma":"finir","pos":"VER"} ,
		{"word":"finiriez","word_nosc":"finiriez","lemma":"finir","pos":"VER"} ,
		{"word":"finirions","word_nosc":"finirions","lemma":"finir","pos":"VER"} ,
		{"word":"finirons","word_nosc":"finirons","lemma":"finir","pos":"VER"} ,
		{"word":"finiront","word_nosc":"finiront","lemma":"finir","pos":"VER"} ,
		{"word":"finis","word_nosc":"finis","lemma":"finir","pos":"VER"} ,
		{"word":"finissaient","word_nosc":"finissaient","lemma":"finir","pos":"VER"} ,
		{"word":"finissais","word_nosc":"finissais","lemma":"finir","pos":"VER"} ,
		{"word":"finissait","word_nosc":"finissait","lemma":"finir","pos":"VER"} ,
		{"word":"finissant","word_nosc":"finissant","lemma":"finir","pos":"VER"} ,
		{"word":"finisse","word_nosc":"finisse","lemma":"finir","pos":"VER"} ,
		{"word":"finissent","word_nosc":"finissent","lemma":"finir","pos":"VER"} ,
		{"word":"finisses","word_nosc":"finisses","lemma":"finir","pos":"VER"} ,
		{"word":"finissez","word_nosc":"finissez","lemma":"finir","pos":"VER"} ,
		{"word":"finissiez","word_nosc":"finissiez","lemma":"finir","pos":"VER"} ,
		{"word":"finissions","word_nosc":"finissions","lemma":"finir","pos":"VER"} ,
		{"word":"finissons","word_nosc":"finissons","lemma":"finir","pos":"VER"} ,
		{"word":"finit","word_nosc":"finit","lemma":"finir","pos":"VER"} ,
		{"word":"finîmes","word_nosc":"finimes","lemma":"finir","pos":"VER"} ,
		{"word":"finît","word_nosc":"finit","lemma":"finir","pos":"VER"} ,
		{"word":"fions","word_nosc":"fions","lemma":"fier","pos":"VER"} ,
		{"word":"firent","word_nosc":"firent","lemma":"faire","pos":"VER"} ,
		{"word":"fis","word_nosc":"fis","lemma":"faire","pos":"VER"} ,
		{"word":"fisse","word_nosc":"fisse","lemma":"faire","pos":"VER"} ,
		{"word":"fissent","word_nosc":"fissent","lemma":"faire","pos":"VER"} ,
		{"word":"fissiez","word_nosc":"fissiez","lemma":"faire","pos":"VER"} ,
		{"word":"fissionner","word_nosc":"fissionner","lemma":"fissionner","pos":"VER"} ,
		{"word":"fissionné","word_nosc":"fissionne","lemma":"fissionner","pos":"VER"} ,
		{"word":"fissions","word_nosc":"fissions","lemma":"faire","pos":"VER"} ,
		{"word":"fissurait","word_nosc":"fissurait","lemma":"fissurer","pos":"VER"} ,
		{"word":"fissurant","word_nosc":"fissurant","lemma":"fissurer","pos":"VER"} ,
		{"word":"fissure","word_nosc":"fissure","lemma":"fissurer","pos":"VER"} ,
		{"word":"fissurent","word_nosc":"fissurent","lemma":"fissurer","pos":"VER"} ,
		{"word":"fissurer","word_nosc":"fissurer","lemma":"fissurer","pos":"VER"} ,
		{"word":"fissures","word_nosc":"fissures","lemma":"fissurer","pos":"VER"} ,
		{"word":"fissuré","word_nosc":"fissure","lemma":"fissurer","pos":"VER"} ,
		{"word":"fissurée","word_nosc":"fissuree","lemma":"fissurer","pos":"VER"} ,
		{"word":"fissurées","word_nosc":"fissurees","lemma":"fissurer","pos":"VER"} ,
		{"word":"fissurés","word_nosc":"fissures","lemma":"fissurer","pos":"VER"} ,
		{"word":"fit","word_nosc":"fit","lemma":"faire","pos":"VER"} ,
		{"word":"fixa","word_nosc":"fixa","lemma":"fixer","pos":"VER"} ,
		{"word":"fixai","word_nosc":"fixai","lemma":"fixer","pos":"VER"} ,
		{"word":"fixaient","word_nosc":"fixaient","lemma":"fixer","pos":"VER"} ,
		{"word":"fixais","word_nosc":"fixais","lemma":"fixer","pos":"VER"} ,
		{"word":"fixait","word_nosc":"fixait","lemma":"fixer","pos":"VER"} ,
		{"word":"fixant","word_nosc":"fixant","lemma":"fixer","pos":"VER"} ,
		{"word":"fixe","word_nosc":"fixe","lemma":"fixer","pos":"VER"} ,
		{"word":"fixent","word_nosc":"fixent","lemma":"fixer","pos":"VER"} ,
		{"word":"fixer","word_nosc":"fixer","lemma":"fixer","pos":"VER"} ,
		{"word":"fixera","word_nosc":"fixera","lemma":"fixer","pos":"VER"} ,
		{"word":"fixerai","word_nosc":"fixerai","lemma":"fixer","pos":"VER"} ,
		{"word":"fixeraient","word_nosc":"fixeraient","lemma":"fixer","pos":"VER"} ,
		{"word":"fixerais","word_nosc":"fixerais","lemma":"fixer","pos":"VER"} ,
		{"word":"fixerait","word_nosc":"fixerait","lemma":"fixer","pos":"VER"} ,
		{"word":"fixeras","word_nosc":"fixeras","lemma":"fixer","pos":"VER"} ,
		{"word":"fixerez","word_nosc":"fixerez","lemma":"fixer","pos":"VER"} ,
		{"word":"fixeriez","word_nosc":"fixeriez","lemma":"fixer","pos":"VER"} ,
		{"word":"fixerons","word_nosc":"fixerons","lemma":"fixer","pos":"VER"} ,
		{"word":"fixeront","word_nosc":"fixeront","lemma":"fixer","pos":"VER"} ,
		{"word":"fixes","word_nosc":"fixes","lemma":"fixer","pos":"VER"} ,
		{"word":"fixez","word_nosc":"fixez","lemma":"fixer","pos":"VER"} ,
		{"word":"fixiez","word_nosc":"fixiez","lemma":"fixer","pos":"VER"} ,
		{"word":"fixions","word_nosc":"fixions","lemma":"fixer","pos":"VER"} ,
		{"word":"fixons","word_nosc":"fixons","lemma":"fixer","pos":"VER"} ,
		{"word":"fixâmes","word_nosc":"fixames","lemma":"fixer","pos":"VER"} ,
		{"word":"fixât","word_nosc":"fixat","lemma":"fixer","pos":"VER"} ,
		{"word":"fixèrent","word_nosc":"fixerent","lemma":"fixer","pos":"VER"} ,
		{"word":"fixé","word_nosc":"fixe","lemma":"fixer","pos":"VER"} ,
		{"word":"fixée","word_nosc":"fixee","lemma":"fixer","pos":"VER"} ,
		{"word":"fixées","word_nosc":"fixees","lemma":"fixer","pos":"VER"} ,
		{"word":"fixés","word_nosc":"fixes","lemma":"fixer","pos":"VER"} ,
		{"word":"fié","word_nosc":"fie","lemma":"fier","pos":"VER"} ,
		{"word":"fiée","word_nosc":"fiee","lemma":"fier","pos":"VER"} ,
		{"word":"fiés","word_nosc":"fies","lemma":"fier","pos":"VER"} ,
		{"word":"flagella","word_nosc":"flagella","lemma":"flageller","pos":"VER"} ,
		{"word":"flagellaient","word_nosc":"flagellaient","lemma":"flageller","pos":"VER"} ,
		{"word":"flagellait","word_nosc":"flagellait","lemma":"flageller","pos":"VER"} ,
		{"word":"flagellant","word_nosc":"flagellant","lemma":"flageller","pos":"VER"} ,
		{"word":"flagelle","word_nosc":"flagelle","lemma":"flageller","pos":"VER"} ,
		{"word":"flagellent","word_nosc":"flagellent","lemma":"flageller","pos":"VER"} ,
		{"word":"flageller","word_nosc":"flageller","lemma":"flageller","pos":"VER"} ,
		{"word":"flagellerez","word_nosc":"flagellerez","lemma":"flageller","pos":"VER"} ,
		{"word":"flagelleront","word_nosc":"flagelleront","lemma":"flageller","pos":"VER"} ,
		{"word":"flagellé","word_nosc":"flagelle","lemma":"flageller","pos":"VER"} ,
		{"word":"flagellée","word_nosc":"flagellee","lemma":"flageller","pos":"VER"} ,
		{"word":"flagellées","word_nosc":"flagellees","lemma":"flageller","pos":"VER"} ,
		{"word":"flagellés","word_nosc":"flagelles","lemma":"flageller","pos":"VER"} ,
		{"word":"flageola","word_nosc":"flageola","lemma":"flageoler","pos":"VER"} ,
		{"word":"flageolai","word_nosc":"flageolai","lemma":"flageoler","pos":"VER"} ,
		{"word":"flageolaient","word_nosc":"flageolaient","lemma":"flageoler","pos":"VER"} ,
		{"word":"flageolait","word_nosc":"flageolait","lemma":"flageoler","pos":"VER"} ,
		{"word":"flageolant","word_nosc":"flageolant","lemma":"flageoler","pos":"VER"} ,
		{"word":"flageole","word_nosc":"flageole","lemma":"flageoler","pos":"VER"} ,
		{"word":"flageolent","word_nosc":"flageolent","lemma":"flageoler","pos":"VER"} ,
		{"word":"flageoler","word_nosc":"flageoler","lemma":"flageoler","pos":"VER"} ,
		{"word":"flageoleront","word_nosc":"flageoleront","lemma":"flageoler","pos":"VER"} ,
		{"word":"flageolèrent","word_nosc":"flageolerent","lemma":"flageoler","pos":"VER"} ,
		{"word":"flagornait","word_nosc":"flagornait","lemma":"flagorner","pos":"VER"} ,
		{"word":"flagornent","word_nosc":"flagornent","lemma":"flagorner","pos":"VER"} ,
		{"word":"flagorner","word_nosc":"flagorner","lemma":"flagorner","pos":"VER"} ,
		{"word":"flaira","word_nosc":"flaira","lemma":"flairer","pos":"VER"} ,
		{"word":"flairaient","word_nosc":"flairaient","lemma":"flairer","pos":"VER"} ,
		{"word":"flairais","word_nosc":"flairais","lemma":"flairer","pos":"VER"} ,
		{"word":"flairait","word_nosc":"flairait","lemma":"flairer","pos":"VER"} ,
		{"word":"flairant","word_nosc":"flairant","lemma":"flairer","pos":"VER"} ,
		{"word":"flaire","word_nosc":"flaire","lemma":"flairer","pos":"VER"} ,
		{"word":"flairent","word_nosc":"flairent","lemma":"flairer","pos":"VER"} ,
		{"word":"flairer","word_nosc":"flairer","lemma":"flairer","pos":"VER"} ,
		{"word":"flairera","word_nosc":"flairera","lemma":"flairer","pos":"VER"} ,
		{"word":"flairerait","word_nosc":"flairerait","lemma":"flairer","pos":"VER"} ,
		{"word":"flaires","word_nosc":"flaires","lemma":"flairer","pos":"VER"} ,
		{"word":"flairez","word_nosc":"flairez","lemma":"flairer","pos":"VER"} ,
		{"word":"flairé","word_nosc":"flaire","lemma":"flairer","pos":"VER"} ,
		{"word":"flairée","word_nosc":"flairee","lemma":"flairer","pos":"VER"} ,
		{"word":"flairés","word_nosc":"flaires","lemma":"flairer","pos":"VER"} ,
		{"word":"flamba","word_nosc":"flamba","lemma":"flamber","pos":"VER"} ,
		{"word":"flambaient","word_nosc":"flambaient","lemma":"flamber","pos":"VER"} ,
		{"word":"flambait","word_nosc":"flambait","lemma":"flamber","pos":"VER"} ,
		{"word":"flambant","word_nosc":"flambant","lemma":"flamber","pos":"VER"} ,
		{"word":"flambe","word_nosc":"flambe","lemma":"flamber","pos":"VER"} ,
		{"word":"flambent","word_nosc":"flambent","lemma":"flamber","pos":"VER"} ,
		{"word":"flamber","word_nosc":"flamber","lemma":"flamber","pos":"VER"} ,
		{"word":"flambera","word_nosc":"flambera","lemma":"flamber","pos":"VER"} ,
		{"word":"flamberaient","word_nosc":"flamberaient","lemma":"flamber","pos":"VER"} ,
		{"word":"flamberait","word_nosc":"flamberait","lemma":"flamber","pos":"VER"} ,
		{"word":"flamberont","word_nosc":"flamberont","lemma":"flamber","pos":"VER"} ,
		{"word":"flambes","word_nosc":"flambes","lemma":"flamber","pos":"VER"} ,
		{"word":"flambez","word_nosc":"flambez","lemma":"flamber","pos":"VER"} ,
		{"word":"flamboie","word_nosc":"flamboie","lemma":"flamboyer","pos":"VER"} ,
		{"word":"flamboient","word_nosc":"flamboient","lemma":"flamboyer","pos":"VER"} ,
		{"word":"flamboierait","word_nosc":"flamboierait","lemma":"flamboyer","pos":"VER"} ,
		{"word":"flambons","word_nosc":"flambons","lemma":"flamber","pos":"VER"} ,
		{"word":"flamboya","word_nosc":"flamboya","lemma":"flamboyer","pos":"VER"} ,
		{"word":"flamboyaient","word_nosc":"flamboyaient","lemma":"flamboyer","pos":"VER"} ,
		{"word":"flamboyait","word_nosc":"flamboyait","lemma":"flamboyer","pos":"VER"} ,
		{"word":"flamboyant","word_nosc":"flamboyant","lemma":"flamboyer","pos":"VER"} ,
		{"word":"flamboyer","word_nosc":"flamboyer","lemma":"flamboyer","pos":"VER"} ,
		{"word":"flamboyèrent","word_nosc":"flamboyerent","lemma":"flamboyer","pos":"VER"} ,
		{"word":"flambèrent","word_nosc":"flamberent","lemma":"flamber","pos":"VER"} ,
		{"word":"flambé","word_nosc":"flambe","lemma":"flamber","pos":"VER"} ,
		{"word":"flambée","word_nosc":"flambee","lemma":"flamber","pos":"VER"} ,
		{"word":"flambées","word_nosc":"flambees","lemma":"flamber","pos":"VER"} ,
		{"word":"flambés","word_nosc":"flambes","lemma":"flamber","pos":"VER"} ,
		{"word":"flancha","word_nosc":"flancha","lemma":"flancher","pos":"VER"} ,
		{"word":"flanchais","word_nosc":"flanchais","lemma":"flancher","pos":"VER"} ,
		{"word":"flanchait","word_nosc":"flanchait","lemma":"flancher","pos":"VER"} ,
		{"word":"flanche","word_nosc":"flanche","lemma":"flancher","pos":"VER"} ,
		{"word":"flanchent","word_nosc":"flanchent","lemma":"flancher","pos":"VER"} ,
		{"word":"flancher","word_nosc":"flancher","lemma":"flancher","pos":"VER"} ,
		{"word":"flanchera","word_nosc":"flanchera","lemma":"flancher","pos":"VER"} ,
		{"word":"flancherai","word_nosc":"flancherai","lemma":"flancher","pos":"VER"} ,
		{"word":"flancheraient","word_nosc":"flancheraient","lemma":"flancher","pos":"VER"} ,
		{"word":"flancherait","word_nosc":"flancherait","lemma":"flancher","pos":"VER"} ,
		{"word":"flanché","word_nosc":"flanche","lemma":"flancher","pos":"VER"} ,
		{"word":"flanqua","word_nosc":"flanqua","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanquaient","word_nosc":"flanquaient","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanquait","word_nosc":"flanquait","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanquant","word_nosc":"flanquant","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanque","word_nosc":"flanque","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanquent","word_nosc":"flanquent","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanquer","word_nosc":"flanquer","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanquera","word_nosc":"flanquera","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanquerai","word_nosc":"flanquerai","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanqueraient","word_nosc":"flanqueraient","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanquerais","word_nosc":"flanquerais","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanquerait","word_nosc":"flanquerait","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanquerez","word_nosc":"flanquerez","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanques","word_nosc":"flanques","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanquez","word_nosc":"flanquez","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanquons","word_nosc":"flanquons","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanqué","word_nosc":"flanque","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanquée","word_nosc":"flanquee","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanquées","word_nosc":"flanquees","lemma":"flanquer","pos":"VER"} ,
		{"word":"flanqués","word_nosc":"flanques","lemma":"flanquer","pos":"VER"} ,
		{"word":"flashaient","word_nosc":"flashaient","lemma":"flasher","pos":"VER"} ,
		{"word":"flashant","word_nosc":"flashant","lemma":"flasher","pos":"VER"} ,
		{"word":"flashe","word_nosc":"flashe","lemma":"flasher","pos":"VER"} ,
		{"word":"flashent","word_nosc":"flashent","lemma":"flasher","pos":"VER"} ,
		{"word":"flasher","word_nosc":"flasher","lemma":"flasher","pos":"VER"} ,
		{"word":"flashé","word_nosc":"flashe","lemma":"flasher","pos":"VER"} ,
		{"word":"flatta","word_nosc":"flatta","lemma":"flatter","pos":"VER"} ,
		{"word":"flattai","word_nosc":"flattai","lemma":"flatter","pos":"VER"} ,
		{"word":"flattaient","word_nosc":"flattaient","lemma":"flatter","pos":"VER"} ,
		{"word":"flattais","word_nosc":"flattais","lemma":"flatter","pos":"VER"} ,
		{"word":"flattait","word_nosc":"flattait","lemma":"flatter","pos":"VER"} ,
		{"word":"flattant","word_nosc":"flattant","lemma":"flatter","pos":"VER"} ,
		{"word":"flattasse","word_nosc":"flattasse","lemma":"flatter","pos":"VER"} ,
		{"word":"flattassent","word_nosc":"flattassent","lemma":"flatter","pos":"VER"} ,
		{"word":"flatte","word_nosc":"flatte","lemma":"flatter","pos":"VER"} ,
		{"word":"flattent","word_nosc":"flattent","lemma":"flatter","pos":"VER"} ,
		{"word":"flatter","word_nosc":"flatter","lemma":"flatter","pos":"VER"} ,
		{"word":"flatteras","word_nosc":"flatteras","lemma":"flatter","pos":"VER"} ,
		{"word":"flatteront","word_nosc":"flatteront","lemma":"flatter","pos":"VER"} ,
		{"word":"flattes","word_nosc":"flattes","lemma":"flatter","pos":"VER"} ,
		{"word":"flattez","word_nosc":"flattez","lemma":"flatter","pos":"VER"} ,
		{"word":"flattions","word_nosc":"flattions","lemma":"flatter","pos":"VER"} ,
		{"word":"flattons","word_nosc":"flattons","lemma":"flatter","pos":"VER"} ,
		{"word":"flattèrent","word_nosc":"flatterent","lemma":"flatter","pos":"VER"} ,
		{"word":"flatté","word_nosc":"flatte","lemma":"flatter","pos":"VER"} ,
		{"word":"flattée","word_nosc":"flattee","lemma":"flatter","pos":"VER"} ,
		{"word":"flattées","word_nosc":"flattees","lemma":"flatter","pos":"VER"} ,
		{"word":"flattés","word_nosc":"flattes","lemma":"flatter","pos":"VER"} ,
		{"word":"flemmardais","word_nosc":"flemmardais","lemma":"flemmarder","pos":"VER"} ,
		{"word":"flemmardant","word_nosc":"flemmardant","lemma":"flemmarder","pos":"VER"} ,
		{"word":"flemmarde","word_nosc":"flemmarde","lemma":"flemmarder","pos":"VER"} ,
		{"word":"flemmarder","word_nosc":"flemmarder","lemma":"flemmarder","pos":"VER"} ,
		{"word":"flemmardes","word_nosc":"flemmardes","lemma":"flemmarder","pos":"VER"} ,
		{"word":"flemmer","word_nosc":"flemmer","lemma":"flemmer","pos":"VER"} ,
		{"word":"fleuraient","word_nosc":"fleuraient","lemma":"fleurer","pos":"VER"} ,
		{"word":"fleurais","word_nosc":"fleurais","lemma":"fleurer","pos":"VER"} ,
		{"word":"fleurait","word_nosc":"fleurait","lemma":"fleurer","pos":"VER"} ,
		{"word":"fleurant","word_nosc":"fleurant","lemma":"fleurer","pos":"VER"} ,
		{"word":"fleurdelisée","word_nosc":"fleurdelisee","lemma":"fleurdeliser","pos":"VER"} ,
		{"word":"fleure","word_nosc":"fleure","lemma":"fleurer","pos":"VER"} ,
		{"word":"fleurent","word_nosc":"fleurent","lemma":"fleurer","pos":"VER"} ,
		{"word":"fleurer","word_nosc":"fleurer","lemma":"fleurer","pos":"VER"} ,
		{"word":"fleuretais","word_nosc":"fleuretais","lemma":"fleureter","pos":"VER"} ,
		{"word":"fleuretait","word_nosc":"fleuretait","lemma":"fleureter","pos":"VER"} ,
		{"word":"fleuri","word_nosc":"fleuri","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleurie","word_nosc":"fleurie","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleuries","word_nosc":"fleuries","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleurir","word_nosc":"fleurir","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleurira","word_nosc":"fleurira","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleuriraient","word_nosc":"fleuriraient","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleurirent","word_nosc":"fleurirent","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleuriront","word_nosc":"fleuriront","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleuris","word_nosc":"fleuris","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleurissaient","word_nosc":"fleurissaient","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleurissait","word_nosc":"fleurissait","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleurissant","word_nosc":"fleurissant","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleurisse","word_nosc":"fleurisse","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleurissent","word_nosc":"fleurissent","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleurissiez","word_nosc":"fleurissiez","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleurissons","word_nosc":"fleurissons","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleurit","word_nosc":"fleurit","lemma":"fleurir","pos":"VER"} ,
		{"word":"fleuronner","word_nosc":"fleuronner","lemma":"fleuronner","pos":"VER"} ,
		{"word":"flingoté","word_nosc":"flingote","lemma":"flingoter","pos":"VER"} ,
		{"word":"flinguait","word_nosc":"flinguait","lemma":"flinguer","pos":"VER"} ,
		{"word":"flinguant","word_nosc":"flinguant","lemma":"flinguer","pos":"VER"} ,
		{"word":"flingue","word_nosc":"flingue","lemma":"flinguer","pos":"VER"} ,
		{"word":"flinguent","word_nosc":"flinguent","lemma":"flinguer","pos":"VER"} ,
		{"word":"flinguer","word_nosc":"flinguer","lemma":"flinguer","pos":"VER"} ,
		{"word":"flinguera","word_nosc":"flinguera","lemma":"flinguer","pos":"VER"} ,
		{"word":"flinguerai","word_nosc":"flinguerai","lemma":"flinguer","pos":"VER"} ,
		{"word":"flingueraient","word_nosc":"flingueraient","lemma":"flinguer","pos":"VER"} ,
		{"word":"flinguerais","word_nosc":"flinguerais","lemma":"flinguer","pos":"VER"} ,
		{"word":"flingueront","word_nosc":"flingueront","lemma":"flinguer","pos":"VER"} ,
		{"word":"flingues","word_nosc":"flingues","lemma":"flinguer","pos":"VER"} ,
		{"word":"flinguez","word_nosc":"flinguez","lemma":"flinguer","pos":"VER"} ,
		{"word":"flinguons","word_nosc":"flinguons","lemma":"flinguer","pos":"VER"} ,
		{"word":"flinguât","word_nosc":"flinguat","lemma":"flinguer","pos":"VER"} ,
		{"word":"flingué","word_nosc":"flingue","lemma":"flinguer","pos":"VER"} ,
		{"word":"flinguée","word_nosc":"flinguee","lemma":"flinguer","pos":"VER"} ,
		{"word":"flingués","word_nosc":"flingues","lemma":"flinguer","pos":"VER"} ,
		{"word":"flippais","word_nosc":"flippais","lemma":"flipper","pos":"VER"} ,
		{"word":"flippait","word_nosc":"flippait","lemma":"flipper","pos":"VER"} ,
		{"word":"flippant","word_nosc":"flippant","lemma":"flipper","pos":"VER"} ,
		{"word":"flippe","word_nosc":"flippe","lemma":"flipper","pos":"VER"} ,
		{"word":"flippent","word_nosc":"flippent","lemma":"flipper","pos":"VER"} ,
		{"word":"flipper","word_nosc":"flipper","lemma":"flipper","pos":"VER"} ,
		{"word":"flipperais","word_nosc":"flipperais","lemma":"flipper","pos":"VER"} ,
		{"word":"flipperait","word_nosc":"flipperait","lemma":"flipper","pos":"VER"} ,
		{"word":"flipperont","word_nosc":"flipperont","lemma":"flipper","pos":"VER"} ,
		{"word":"flippes","word_nosc":"flippes","lemma":"flipper","pos":"VER"} ,
		{"word":"flippé","word_nosc":"flippe","lemma":"flipper","pos":"VER"} ,
		{"word":"flippée","word_nosc":"flippee","lemma":"flipper","pos":"VER"} ,
		{"word":"flippés","word_nosc":"flippes","lemma":"flipper","pos":"VER"} ,
		{"word":"fliquer","word_nosc":"fliquer","lemma":"fliquer","pos":"VER"} ,
		{"word":"flirta","word_nosc":"flirta","lemma":"flirter","pos":"VER"} ,
		{"word":"flirtaient","word_nosc":"flirtaient","lemma":"flirter","pos":"VER"} ,
		{"word":"flirtais","word_nosc":"flirtais","lemma":"flirter","pos":"VER"} ,
		{"word":"flirtait","word_nosc":"flirtait","lemma":"flirter","pos":"VER"} ,
		{"word":"flirtant","word_nosc":"flirtant","lemma":"flirter","pos":"VER"} ,
		{"word":"flirte","word_nosc":"flirte","lemma":"flirter","pos":"VER"} ,
		{"word":"flirtent","word_nosc":"flirtent","lemma":"flirter","pos":"VER"} ,
		{"word":"flirter","word_nosc":"flirter","lemma":"flirter","pos":"VER"} ,
		{"word":"flirteront","word_nosc":"flirteront","lemma":"flirter","pos":"VER"} ,
		{"word":"flirtes","word_nosc":"flirtes","lemma":"flirter","pos":"VER"} ,
		{"word":"flirtez","word_nosc":"flirtez","lemma":"flirter","pos":"VER"} ,
		{"word":"flirtiez","word_nosc":"flirtiez","lemma":"flirter","pos":"VER"} ,
		{"word":"flirtons","word_nosc":"flirtons","lemma":"flirter","pos":"VER"} ,
		{"word":"flirtèrent","word_nosc":"flirterent","lemma":"flirter","pos":"VER"} ,
		{"word":"flirté","word_nosc":"flirte","lemma":"flirter","pos":"VER"} ,
		{"word":"floconne","word_nosc":"floconne","lemma":"floconner","pos":"VER"} ,
		{"word":"floconnent","word_nosc":"floconnent","lemma":"floconner","pos":"VER"} ,
		{"word":"floculent","word_nosc":"floculent","lemma":"floculer","pos":"VER"} ,
		{"word":"florissaient","word_nosc":"florissaient","lemma":"florissaient","pos":"VER"} ,
		{"word":"florissait","word_nosc":"florissait","lemma":"florissait","pos":"VER"} ,
		{"word":"florissant","word_nosc":"florissant","lemma":"florir","pos":"VER"} ,
		{"word":"flotta","word_nosc":"flotta","lemma":"flotter","pos":"VER"} ,
		{"word":"flottaient","word_nosc":"flottaient","lemma":"flotter","pos":"VER"} ,
		{"word":"flottais","word_nosc":"flottais","lemma":"flotter","pos":"VER"} ,
		{"word":"flottait","word_nosc":"flottait","lemma":"flotter","pos":"VER"} ,
		{"word":"flottant","word_nosc":"flottant","lemma":"flotter","pos":"VER"} ,
		{"word":"flotte","word_nosc":"flotte","lemma":"flotter","pos":"VER"} ,
		{"word":"flottent","word_nosc":"flottent","lemma":"flotter","pos":"VER"} ,
		{"word":"flotter","word_nosc":"flotter","lemma":"flotter","pos":"VER"} ,
		{"word":"flottera","word_nosc":"flottera","lemma":"flotter","pos":"VER"} ,
		{"word":"flotteraient","word_nosc":"flotteraient","lemma":"flotter","pos":"VER"} ,
		{"word":"flotterait","word_nosc":"flotterait","lemma":"flotter","pos":"VER"} ,
		{"word":"flotteras","word_nosc":"flotteras","lemma":"flotter","pos":"VER"} ,
		{"word":"flotterez","word_nosc":"flotterez","lemma":"flotter","pos":"VER"} ,
		{"word":"flotteront","word_nosc":"flotteront","lemma":"flotter","pos":"VER"} ,
		{"word":"flottes","word_nosc":"flottes","lemma":"flotter","pos":"VER"} ,
		{"word":"flottez","word_nosc":"flottez","lemma":"flotter","pos":"VER"} ,
		{"word":"flottiez","word_nosc":"flottiez","lemma":"flotter","pos":"VER"} ,
		{"word":"flottions","word_nosc":"flottions","lemma":"flotter","pos":"VER"} ,
		{"word":"flottât","word_nosc":"flottat","lemma":"flotter","pos":"VER"} ,
		{"word":"flottèrent","word_nosc":"flotterent","lemma":"flotter","pos":"VER"} ,
		{"word":"flotté","word_nosc":"flotte","lemma":"flotter","pos":"VER"} ,
		{"word":"flottée","word_nosc":"flottee","lemma":"flotter","pos":"VER"} ,
		{"word":"flottées","word_nosc":"flottees","lemma":"flotter","pos":"VER"} ,
		{"word":"flottés","word_nosc":"flottes","lemma":"flotter","pos":"VER"} ,
		{"word":"flouant","word_nosc":"flouant","lemma":"flouer","pos":"VER"} ,
		{"word":"floue","word_nosc":"floue","lemma":"flouer","pos":"VER"} ,
		{"word":"flouer","word_nosc":"flouer","lemma":"flouer","pos":"VER"} ,
		{"word":"floué","word_nosc":"floue","lemma":"flouer","pos":"VER"} ,
		{"word":"flouée","word_nosc":"flouee","lemma":"flouer","pos":"VER"} ,
		{"word":"floués","word_nosc":"floues","lemma":"flouer","pos":"VER"} ,
		{"word":"fluaient","word_nosc":"fluaient","lemma":"fluer","pos":"VER"} ,
		{"word":"fluait","word_nosc":"fluait","lemma":"fluer","pos":"VER"} ,
		{"word":"flubes","word_nosc":"flubes","lemma":"fluber","pos":"VER"} ,
		{"word":"fluctuait","word_nosc":"fluctuait","lemma":"fluctuer","pos":"VER"} ,
		{"word":"fluctue","word_nosc":"fluctue","lemma":"fluctuer","pos":"VER"} ,
		{"word":"fluctuent","word_nosc":"fluctuent","lemma":"fluctuer","pos":"VER"} ,
		{"word":"fluctuer","word_nosc":"fluctuer","lemma":"fluctuer","pos":"VER"} ,
		{"word":"fluctuerait","word_nosc":"fluctuerait","lemma":"fluctuer","pos":"VER"} ,
		{"word":"fluctué","word_nosc":"fluctue","lemma":"fluctuer","pos":"VER"} ,
		{"word":"fluidifier","word_nosc":"fluidifier","lemma":"fluidifier","pos":"VER"} ,
		{"word":"fluidifié","word_nosc":"fluidifie","lemma":"fluidifier","pos":"VER"} ,
		{"word":"fluxe","word_nosc":"fluxe","lemma":"fluxer","pos":"VER"} ,
		{"word":"flâna","word_nosc":"flana","lemma":"flâner","pos":"VER"} ,
		{"word":"flânai","word_nosc":"flanai","lemma":"flâner","pos":"VER"} ,
		{"word":"flânaient","word_nosc":"flanaient","lemma":"flâner","pos":"VER"} ,
		{"word":"flânais","word_nosc":"flanais","lemma":"flâner","pos":"VER"} ,
		{"word":"flânait","word_nosc":"flanait","lemma":"flâner","pos":"VER"} ,
		{"word":"flânant","word_nosc":"flanant","lemma":"flâner","pos":"VER"} ,
		{"word":"flâne","word_nosc":"flane","lemma":"flâner","pos":"VER"} ,
		{"word":"flânent","word_nosc":"flanent","lemma":"flâner","pos":"VER"} ,
		{"word":"flâner","word_nosc":"flaner","lemma":"flâner","pos":"VER"} ,
		{"word":"flânera","word_nosc":"flanera","lemma":"flâner","pos":"VER"} ,
		{"word":"flânerais","word_nosc":"flanerais","lemma":"flâner","pos":"VER"} ,
		{"word":"flânerait","word_nosc":"flanerait","lemma":"flâner","pos":"VER"} ,
		{"word":"flâneront","word_nosc":"flaneront","lemma":"flâner","pos":"VER"} ,
		{"word":"flânions","word_nosc":"flanions","lemma":"flâner","pos":"VER"} ,
		{"word":"flânochent","word_nosc":"flanochent","lemma":"flânocher","pos":"VER"} ,
		{"word":"flânocher","word_nosc":"flanocher","lemma":"flânocher","pos":"VER"} ,
		{"word":"flânons","word_nosc":"flanons","lemma":"flâner","pos":"VER"} ,
		{"word":"flâné","word_nosc":"flane","lemma":"flâner","pos":"VER"} ,
		{"word":"flécher","word_nosc":"flecher","lemma":"flécher","pos":"VER"} ,
		{"word":"fléchi","word_nosc":"flechi","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchie","word_nosc":"flechie","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchies","word_nosc":"flechies","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchir","word_nosc":"flechir","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchiraient","word_nosc":"flechiraient","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchirait","word_nosc":"flechirait","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchirent","word_nosc":"flechirent","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchis","word_nosc":"flechis","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchissaient","word_nosc":"flechissaient","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchissais","word_nosc":"flechissais","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchissait","word_nosc":"flechissait","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchissant","word_nosc":"flechissant","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchisse","word_nosc":"flechisse","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchissent","word_nosc":"flechissent","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchissez","word_nosc":"flechissez","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchissions","word_nosc":"flechissions","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchissons","word_nosc":"flechissons","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléchit","word_nosc":"flechit","lemma":"fléchir","pos":"VER"} ,
		{"word":"fléché","word_nosc":"fleche","lemma":"flécher","pos":"VER"} ,
		{"word":"fléchée","word_nosc":"flechee","lemma":"flécher","pos":"VER"} ,
		{"word":"fléchées","word_nosc":"flechees","lemma":"flécher","pos":"VER"} ,
		{"word":"fléchés","word_nosc":"fleches","lemma":"flécher","pos":"VER"} ,
		{"word":"flétri","word_nosc":"fletri","lemma":"flétrir","pos":"VER"} ,
		{"word":"flétrie","word_nosc":"fletrie","lemma":"flétrir","pos":"VER"} ,
		{"word":"flétries","word_nosc":"fletries","lemma":"flétrir","pos":"VER"} ,
		{"word":"flétrir","word_nosc":"fletrir","lemma":"flétrir","pos":"VER"} ,
		{"word":"flétrira","word_nosc":"fletrira","lemma":"flétrir","pos":"VER"} ,
		{"word":"flétrirai","word_nosc":"fletrirai","lemma":"flétrir","pos":"VER"} ,
		{"word":"flétrirent","word_nosc":"fletrirent","lemma":"flétrir","pos":"VER"} ,
		{"word":"flétriront","word_nosc":"fletriront","lemma":"flétrir","pos":"VER"} ,
		{"word":"flétris","word_nosc":"fletris","lemma":"flétrir","pos":"VER"} ,
		{"word":"flétrissaient","word_nosc":"fletrissaient","lemma":"flétrir","pos":"VER"} ,
		{"word":"flétrissait","word_nosc":"fletrissait","lemma":"flétrir","pos":"VER"} ,
		{"word":"flétrissent","word_nosc":"fletrissent","lemma":"flétrir","pos":"VER"} ,
		{"word":"flétrit","word_nosc":"fletrit","lemma":"flétrir","pos":"VER"} ,
		{"word":"flétrît","word_nosc":"fletrit","lemma":"flétrir","pos":"VER"} ,
		{"word":"flûta","word_nosc":"fluta","lemma":"flûter","pos":"VER"} ,
		{"word":"flûter","word_nosc":"fluter","lemma":"flûter","pos":"VER"} ,
		{"word":"flûtée","word_nosc":"flutee","lemma":"flûter","pos":"VER"} ,
		{"word":"focalise","word_nosc":"focalise","lemma":"focaliser","pos":"VER"} ,
		{"word":"focaliser","word_nosc":"focaliser","lemma":"focaliser","pos":"VER"} ,
		{"word":"focalisez","word_nosc":"focalisez","lemma":"focaliser","pos":"VER"} ,
		{"word":"focalisons","word_nosc":"focalisons","lemma":"focaliser","pos":"VER"} ,
		{"word":"focalisé","word_nosc":"focalise","lemma":"focaliser","pos":"VER"} ,
		{"word":"focalisés","word_nosc":"focalises","lemma":"focaliser","pos":"VER"} ,
		{"word":"foira","word_nosc":"foira","lemma":"foirer","pos":"VER"} ,
		{"word":"foiraient","word_nosc":"foiraient","lemma":"foirer","pos":"VER"} ,
		{"word":"foirais","word_nosc":"foirais","lemma":"foirer","pos":"VER"} ,
		{"word":"foirait","word_nosc":"foirait","lemma":"foirer","pos":"VER"} ,
		{"word":"foire","word_nosc":"foire","lemma":"foirer","pos":"VER"} ,
		{"word":"foirent","word_nosc":"foirent","lemma":"foirer","pos":"VER"} ,
		{"word":"foirer","word_nosc":"foirer","lemma":"foirer","pos":"VER"} ,
		{"word":"foirera","word_nosc":"foirera","lemma":"foirer","pos":"VER"} ,
		{"word":"foires","word_nosc":"foires","lemma":"foirer","pos":"VER"} ,
		{"word":"foirez","word_nosc":"foirez","lemma":"foirer","pos":"VER"} ,
		{"word":"foiré","word_nosc":"foire","lemma":"foirer","pos":"VER"} ,
		{"word":"foirée","word_nosc":"foiree","lemma":"foirer","pos":"VER"} ,
		{"word":"foirées","word_nosc":"foirees","lemma":"foirer","pos":"VER"} ,
		{"word":"foirés","word_nosc":"foires","lemma":"foirer","pos":"VER"} ,
		{"word":"foisonnaient","word_nosc":"foisonnaient","lemma":"foisonner","pos":"VER"} ,
		{"word":"foisonnait","word_nosc":"foisonnait","lemma":"foisonner","pos":"VER"} ,
		{"word":"foisonnant","word_nosc":"foisonnant","lemma":"foisonner","pos":"VER"} ,
		{"word":"foisonne","word_nosc":"foisonne","lemma":"foisonner","pos":"VER"} ,
		{"word":"foisonnent","word_nosc":"foisonnent","lemma":"foisonner","pos":"VER"} ,
		{"word":"foisonner","word_nosc":"foisonner","lemma":"foisonner","pos":"VER"} ,
		{"word":"foisonneraient","word_nosc":"foisonneraient","lemma":"foisonner","pos":"VER"} ,
		{"word":"foisonneront","word_nosc":"foisonneront","lemma":"foisonner","pos":"VER"} ,
		{"word":"folichonnait","word_nosc":"folichonnait","lemma":"folichonner","pos":"VER"} ,
		{"word":"folichonne","word_nosc":"folichonne","lemma":"folichonner","pos":"VER"} ,
		{"word":"folâtraient","word_nosc":"folatraient","lemma":"folâtrer","pos":"VER"} ,
		{"word":"folâtrait","word_nosc":"folatrait","lemma":"folâtrer","pos":"VER"} ,
		{"word":"folâtre","word_nosc":"folatre","lemma":"folâtrer","pos":"VER"} ,
		{"word":"folâtrent","word_nosc":"folatrent","lemma":"folâtrer","pos":"VER"} ,
		{"word":"folâtrer","word_nosc":"folatrer","lemma":"folâtrer","pos":"VER"} ,
		{"word":"fomentaient","word_nosc":"fomentaient","lemma":"fomenter","pos":"VER"} ,
		{"word":"fomentait","word_nosc":"fomentait","lemma":"fomenter","pos":"VER"} ,
		{"word":"fomente","word_nosc":"fomente","lemma":"fomenter","pos":"VER"} ,
		{"word":"fomentent","word_nosc":"fomentent","lemma":"fomenter","pos":"VER"} ,
		{"word":"fomenter","word_nosc":"fomenter","lemma":"fomenter","pos":"VER"} ,
		{"word":"fomenté","word_nosc":"fomente","lemma":"fomenter","pos":"VER"} ,
		{"word":"fomentée","word_nosc":"fomentee","lemma":"fomenter","pos":"VER"} ,
		{"word":"fonce","word_nosc":"fonce","lemma":"foncer","pos":"VER"} ,
		{"word":"foncent","word_nosc":"foncent","lemma":"foncer","pos":"VER"} ,
		{"word":"foncer","word_nosc":"foncer","lemma":"foncer","pos":"VER"} ,
		{"word":"foncera","word_nosc":"foncera","lemma":"foncer","pos":"VER"} ,
		{"word":"foncerai","word_nosc":"foncerai","lemma":"foncer","pos":"VER"} ,
		{"word":"fonceraient","word_nosc":"fonceraient","lemma":"foncer","pos":"VER"} ,
		{"word":"foncerais","word_nosc":"foncerais","lemma":"foncer","pos":"VER"} ,
		{"word":"foncerait","word_nosc":"foncerait","lemma":"foncer","pos":"VER"} ,
		{"word":"foncerons","word_nosc":"foncerons","lemma":"foncer","pos":"VER"} ,
		{"word":"fonceront","word_nosc":"fonceront","lemma":"foncer","pos":"VER"} ,
		{"word":"fonces","word_nosc":"fonces","lemma":"foncer","pos":"VER"} ,
		{"word":"foncez","word_nosc":"foncez","lemma":"foncer","pos":"VER"} ,
		{"word":"fonciez","word_nosc":"fonciez","lemma":"foncer","pos":"VER"} ,
		{"word":"foncions","word_nosc":"foncions","lemma":"foncer","pos":"VER"} ,
		{"word":"fonctionna","word_nosc":"fonctionna","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionnaient","word_nosc":"fonctionnaient","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionnais","word_nosc":"fonctionnais","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionnait","word_nosc":"fonctionnait","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionnant","word_nosc":"fonctionnant","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionnarisé","word_nosc":"fonctionnarise","lemma":"fonctionnariser","pos":"VER"} ,
		{"word":"fonctionnarisée","word_nosc":"fonctionnarisee","lemma":"fonctionnariser","pos":"VER"} ,
		{"word":"fonctionnassent","word_nosc":"fonctionnassent","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionne","word_nosc":"fonctionne","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionnent","word_nosc":"fonctionnent","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionner","word_nosc":"fonctionner","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionnera","word_nosc":"fonctionnera","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionneraient","word_nosc":"fonctionneraient","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionnerait","word_nosc":"fonctionnerait","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionneront","word_nosc":"fonctionneront","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionnes","word_nosc":"fonctionnes","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionnez","word_nosc":"fonctionnez","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionnons","word_nosc":"fonctionnons","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionnât","word_nosc":"fonctionnat","lemma":"fonctionner","pos":"VER"} ,
		{"word":"fonctionné","word_nosc":"fonctionne","lemma":"fonctionner","pos":"VER"} ,
		{"word":"foncèrent","word_nosc":"foncerent","lemma":"foncer","pos":"VER"} ,
		{"word":"foncé","word_nosc":"fonce","lemma":"foncer","pos":"VER"} ,
		{"word":"foncée","word_nosc":"foncee","lemma":"foncer","pos":"VER"} ,
		{"word":"foncées","word_nosc":"foncees","lemma":"foncer","pos":"VER"} ,
		{"word":"foncés","word_nosc":"fonces","lemma":"foncer","pos":"VER"} ,
		{"word":"fond","word_nosc":"fond","lemma":"fondre","pos":"VER"} ,
		{"word":"fonda","word_nosc":"fonda","lemma":"fonder","pos":"VER"} ,
		{"word":"fondaient","word_nosc":"fondaient","lemma":"fonder","pos":"VER"} ,
		{"word":"fondais","word_nosc":"fondais","lemma":"fonder","pos":"VER"} ,
		{"word":"fondait","word_nosc":"fondait","lemma":"fonder","pos":"VER"} ,
		{"word":"fondant","word_nosc":"fondant","lemma":"fonder","pos":"VER"} ,
		{"word":"fonde","word_nosc":"fonde","lemma":"fonder","pos":"VER"} ,
		{"word":"fondent","word_nosc":"fondent","lemma":"fonder","pos":"VER"} ,
		{"word":"fonder","word_nosc":"fonder","lemma":"fonder","pos":"VER"} ,
		{"word":"fondera","word_nosc":"fondera","lemma":"fonder","pos":"VER"} ,
		{"word":"fonderais","word_nosc":"fonderais","lemma":"fonder","pos":"VER"} ,
		{"word":"fonderait","word_nosc":"fonderait","lemma":"fonder","pos":"VER"} ,
		{"word":"fonderez","word_nosc":"fonderez","lemma":"fonder","pos":"VER"} ,
		{"word":"fonderons","word_nosc":"fonderons","lemma":"fonder","pos":"VER"} ,
		{"word":"fonderont","word_nosc":"fonderont","lemma":"fonder","pos":"VER"} ,
		{"word":"fondez","word_nosc":"fondez","lemma":"fonder","pos":"VER"} ,
		{"word":"fondions","word_nosc":"fondions","lemma":"fonder","pos":"VER"} ,
		{"word":"fondirent","word_nosc":"fondirent","lemma":"fondre","pos":"VER"} ,
		{"word":"fondis","word_nosc":"fondis","lemma":"fondre","pos":"VER"} ,
		{"word":"fondit","word_nosc":"fondit","lemma":"fondre","pos":"VER"} ,
		{"word":"fondons","word_nosc":"fondons","lemma":"fonder","pos":"VER"} ,
		{"word":"fondra","word_nosc":"fondra","lemma":"fondre","pos":"VER"} ,
		{"word":"fondrai","word_nosc":"fondrai","lemma":"fondre","pos":"VER"} ,
		{"word":"fondraient","word_nosc":"fondraient","lemma":"fondre","pos":"VER"} ,
		{"word":"fondrait","word_nosc":"fondrait","lemma":"fondre","pos":"VER"} ,
		{"word":"fondras","word_nosc":"fondras","lemma":"fondre","pos":"VER"} ,
		{"word":"fondre","word_nosc":"fondre","lemma":"fondre","pos":"VER"} ,
		{"word":"fondriez","word_nosc":"fondriez","lemma":"fondre","pos":"VER"} ,
		{"word":"fonds","word_nosc":"fonds","lemma":"fondre","pos":"VER"} ,
		{"word":"fondu","word_nosc":"fondu","lemma":"fondre","pos":"VER"} ,
		{"word":"fondue","word_nosc":"fondue","lemma":"fondre","pos":"VER"} ,
		{"word":"fondues","word_nosc":"fondues","lemma":"fondre","pos":"VER"} ,
		{"word":"fondus","word_nosc":"fondus","lemma":"fondre","pos":"VER"} ,
		{"word":"fondât","word_nosc":"fondat","lemma":"fonder","pos":"VER"} ,
		{"word":"fondèrent","word_nosc":"fonderent","lemma":"fonder","pos":"VER"} ,
		{"word":"fondé","word_nosc":"fonde","lemma":"fonder","pos":"VER"} ,
		{"word":"fondée","word_nosc":"fondee","lemma":"fonder","pos":"VER"} ,
		{"word":"fondées","word_nosc":"fondees","lemma":"fonder","pos":"VER"} ,
		{"word":"fondés","word_nosc":"fondes","lemma":"fonder","pos":"VER"} ,
		{"word":"fondît","word_nosc":"fondit","lemma":"fondre","pos":"VER"} ,
		{"word":"font","word_nosc":"font","lemma":"faire","pos":"VER"} ,
		{"word":"fonça","word_nosc":"fonca","lemma":"foncer","pos":"VER"} ,
		{"word":"fonçai","word_nosc":"foncai","lemma":"foncer","pos":"VER"} ,
		{"word":"fonçaient","word_nosc":"foncaient","lemma":"foncer","pos":"VER"} ,
		{"word":"fonçais","word_nosc":"foncais","lemma":"foncer","pos":"VER"} ,
		{"word":"fonçait","word_nosc":"foncait","lemma":"foncer","pos":"VER"} ,
		{"word":"fonçant","word_nosc":"foncant","lemma":"foncer","pos":"VER"} ,
		{"word":"fonçons","word_nosc":"foncons","lemma":"foncer","pos":"VER"} ,
		{"word":"foraient","word_nosc":"foraient","lemma":"forer","pos":"VER"} ,
		{"word":"forait","word_nosc":"forait","lemma":"forer","pos":"VER"} ,
		{"word":"forant","word_nosc":"forant","lemma":"forer","pos":"VER"} ,
		{"word":"force","word_nosc":"force","lemma":"forcer","pos":"VER"} ,
		{"word":"forcent","word_nosc":"forcent","lemma":"forcer","pos":"VER"} ,
		{"word":"forcer","word_nosc":"forcer","lemma":"forcer","pos":"VER"} ,
		{"word":"forcera","word_nosc":"forcera","lemma":"forcer","pos":"VER"} ,
		{"word":"forcerai","word_nosc":"forcerai","lemma":"forcer","pos":"VER"} ,
		{"word":"forcerais","word_nosc":"forcerais","lemma":"forcer","pos":"VER"} ,
		{"word":"forcerait","word_nosc":"forcerait","lemma":"forcer","pos":"VER"} ,
		{"word":"forceras","word_nosc":"forceras","lemma":"forcer","pos":"VER"} ,
		{"word":"forcerez","word_nosc":"forcerez","lemma":"forcer","pos":"VER"} ,
		{"word":"forceront","word_nosc":"forceront","lemma":"forcer","pos":"VER"} ,
		{"word":"forces","word_nosc":"forces","lemma":"forcer","pos":"VER"} ,
		{"word":"forcez","word_nosc":"forcez","lemma":"forcer","pos":"VER"} ,
		{"word":"forci","word_nosc":"forci","lemma":"forcir","pos":"VER"} ,
		{"word":"forciez","word_nosc":"forciez","lemma":"forcer","pos":"VER"} ,
		{"word":"forcions","word_nosc":"forcions","lemma":"forcer","pos":"VER"} ,
		{"word":"forcir","word_nosc":"forcir","lemma":"forcir","pos":"VER"} ,
		{"word":"forcis","word_nosc":"forcis","lemma":"forcir","pos":"VER"} ,
		{"word":"forcissait","word_nosc":"forcissait","lemma":"forcir","pos":"VER"} ,
		{"word":"forcit","word_nosc":"forcit","lemma":"forcir","pos":"VER"} ,
		{"word":"forcèrent","word_nosc":"forcerent","lemma":"forcer","pos":"VER"} ,
		{"word":"forcé","word_nosc":"force","lemma":"forcer","pos":"VER"} ,
		{"word":"forcée","word_nosc":"forcee","lemma":"forcer","pos":"VER"} ,
		{"word":"forcées","word_nosc":"forcees","lemma":"forcer","pos":"VER"} ,
		{"word":"forcés","word_nosc":"forces","lemma":"forcer","pos":"VER"} ,
		{"word":"fore","word_nosc":"fore","lemma":"forer","pos":"VER"} ,
		{"word":"forent","word_nosc":"forent","lemma":"forer","pos":"VER"} ,
		{"word":"forer","word_nosc":"forer","lemma":"forer","pos":"VER"} ,
		{"word":"forez","word_nosc":"forez","lemma":"forer","pos":"VER"} ,
		{"word":"forfait","word_nosc":"forfait","lemma":"forfait","pos":"VER"} ,
		{"word":"forge","word_nosc":"forge","lemma":"forger","pos":"VER"} ,
		{"word":"forgea","word_nosc":"forgea","lemma":"forger","pos":"VER"} ,
		{"word":"forgeaient","word_nosc":"forgeaient","lemma":"forger","pos":"VER"} ,
		{"word":"forgeais","word_nosc":"forgeais","lemma":"forger","pos":"VER"} ,
		{"word":"forgeait","word_nosc":"forgeait","lemma":"forger","pos":"VER"} ,
		{"word":"forgeant","word_nosc":"forgeant","lemma":"forger","pos":"VER"} ,
		{"word":"forgent","word_nosc":"forgent","lemma":"forger","pos":"VER"} ,
		{"word":"forgeons","word_nosc":"forgeons","lemma":"forger","pos":"VER"} ,
		{"word":"forger","word_nosc":"forger","lemma":"forger","pos":"VER"} ,
		{"word":"forgera","word_nosc":"forgera","lemma":"forger","pos":"VER"} ,
		{"word":"forgerai","word_nosc":"forgerai","lemma":"forger","pos":"VER"} ,
		{"word":"forges","word_nosc":"forges","lemma":"forger","pos":"VER"} ,
		{"word":"forgions","word_nosc":"forgions","lemma":"forger","pos":"VER"} ,
		{"word":"forgèrent","word_nosc":"forgerent","lemma":"forger","pos":"VER"} ,
		{"word":"forgé","word_nosc":"forge","lemma":"forger","pos":"VER"} ,
		{"word":"forgée","word_nosc":"forgee","lemma":"forger","pos":"VER"} ,
		{"word":"forgées","word_nosc":"forgees","lemma":"forger","pos":"VER"} ,
		{"word":"forgés","word_nosc":"forges","lemma":"forger","pos":"VER"} ,
		{"word":"forlonger","word_nosc":"forlonger","lemma":"forlonger","pos":"VER"} ,
		{"word":"forlongés","word_nosc":"forlonges","lemma":"forlonger","pos":"VER"} ,
		{"word":"forma","word_nosc":"forma","lemma":"former","pos":"VER"} ,
		{"word":"formai","word_nosc":"formai","lemma":"former","pos":"VER"} ,
		{"word":"formaient","word_nosc":"formaient","lemma":"former","pos":"VER"} ,
		{"word":"formais","word_nosc":"formais","lemma":"former","pos":"VER"} ,
		{"word":"formait","word_nosc":"formait","lemma":"former","pos":"VER"} ,
		{"word":"formalisa","word_nosc":"formalisa","lemma":"formaliser","pos":"VER"} ,
		{"word":"formalisaient","word_nosc":"formalisaient","lemma":"formaliser","pos":"VER"} ,
		{"word":"formalisais","word_nosc":"formalisais","lemma":"formaliser","pos":"VER"} ,
		{"word":"formalisait","word_nosc":"formalisait","lemma":"formaliser","pos":"VER"} ,
		{"word":"formalise","word_nosc":"formalise","lemma":"formaliser","pos":"VER"} ,
		{"word":"formaliser","word_nosc":"formaliser","lemma":"formaliser","pos":"VER"} ,
		{"word":"formalisera","word_nosc":"formalisera","lemma":"formaliser","pos":"VER"} ,
		{"word":"formaliserait","word_nosc":"formaliserait","lemma":"formaliser","pos":"VER"} ,
		{"word":"formalisez","word_nosc":"formalisez","lemma":"formaliser","pos":"VER"} ,
		{"word":"formalisât","word_nosc":"formalisat","lemma":"formaliser","pos":"VER"} ,
		{"word":"formalisé","word_nosc":"formalise","lemma":"formaliser","pos":"VER"} ,
		{"word":"formalisée","word_nosc":"formalisee","lemma":"formaliser","pos":"VER"} ,
		{"word":"formalisés","word_nosc":"formalises","lemma":"formaliser","pos":"VER"} ,
		{"word":"formant","word_nosc":"formant","lemma":"former","pos":"VER"} ,
		{"word":"formater","word_nosc":"formater","lemma":"formater","pos":"VER"} ,
		{"word":"formaté","word_nosc":"formate","lemma":"formater","pos":"VER"} ,
		{"word":"forme","word_nosc":"forme","lemma":"former","pos":"VER"} ,
		{"word":"forment","word_nosc":"forment","lemma":"former","pos":"VER"} ,
		{"word":"former","word_nosc":"former","lemma":"former","pos":"VER"} ,
		{"word":"formera","word_nosc":"formera","lemma":"former","pos":"VER"} ,
		{"word":"formerai","word_nosc":"formerai","lemma":"former","pos":"VER"} ,
		{"word":"formeraient","word_nosc":"formeraient","lemma":"former","pos":"VER"} ,
		{"word":"formerais","word_nosc":"formerais","lemma":"former","pos":"VER"} ,
		{"word":"formerait","word_nosc":"formerait","lemma":"former","pos":"VER"} ,
		{"word":"formerez","word_nosc":"formerez","lemma":"former","pos":"VER"} ,
		{"word":"formeriez","word_nosc":"formeriez","lemma":"former","pos":"VER"} ,
		{"word":"formerons","word_nosc":"formerons","lemma":"former","pos":"VER"} ,
		{"word":"formeront","word_nosc":"formeront","lemma":"former","pos":"VER"} ,
		{"word":"formes","word_nosc":"formes","lemma":"former","pos":"VER"} ,
		{"word":"formez","word_nosc":"formez","lemma":"former","pos":"VER"} ,
		{"word":"formiez","word_nosc":"formiez","lemma":"former","pos":"VER"} ,
		{"word":"formions","word_nosc":"formions","lemma":"former","pos":"VER"} ,
		{"word":"formons","word_nosc":"formons","lemma":"former","pos":"VER"} ,
		{"word":"formula","word_nosc":"formula","lemma":"formuler","pos":"VER"} ,
		{"word":"formulai","word_nosc":"formulai","lemma":"formuler","pos":"VER"} ,
		{"word":"formulaient","word_nosc":"formulaient","lemma":"formuler","pos":"VER"} ,
		{"word":"formulais","word_nosc":"formulais","lemma":"formuler","pos":"VER"} ,
		{"word":"formulait","word_nosc":"formulait","lemma":"formuler","pos":"VER"} ,
		{"word":"formulant","word_nosc":"formulant","lemma":"formuler","pos":"VER"} ,
		{"word":"formule","word_nosc":"formule","lemma":"formuler","pos":"VER"} ,
		{"word":"formulent","word_nosc":"formulent","lemma":"formuler","pos":"VER"} ,
		{"word":"formuler","word_nosc":"formuler","lemma":"formuler","pos":"VER"} ,
		{"word":"formulera","word_nosc":"formulera","lemma":"formuler","pos":"VER"} ,
		{"word":"formules","word_nosc":"formules","lemma":"formuler","pos":"VER"} ,
		{"word":"formulez","word_nosc":"formulez","lemma":"formuler","pos":"VER"} ,
		{"word":"formulons","word_nosc":"formulons","lemma":"formuler","pos":"VER"} ,
		{"word":"formulât","word_nosc":"formulat","lemma":"formuler","pos":"VER"} ,
		{"word":"formulé","word_nosc":"formule","lemma":"formuler","pos":"VER"} ,
		{"word":"formulée","word_nosc":"formulee","lemma":"formuler","pos":"VER"} ,
		{"word":"formulées","word_nosc":"formulees","lemma":"formuler","pos":"VER"} ,
		{"word":"formulés","word_nosc":"formules","lemma":"formuler","pos":"VER"} ,
		{"word":"formèrent","word_nosc":"formerent","lemma":"former","pos":"VER"} ,
		{"word":"formé","word_nosc":"forme","lemma":"former","pos":"VER"} ,
		{"word":"formée","word_nosc":"formee","lemma":"former","pos":"VER"} ,
		{"word":"formées","word_nosc":"formees","lemma":"former","pos":"VER"} ,
		{"word":"formés","word_nosc":"formes","lemma":"former","pos":"VER"} ,
		{"word":"forniquaient","word_nosc":"forniquaient","lemma":"forniquer","pos":"VER"} ,
		{"word":"forniquait","word_nosc":"forniquait","lemma":"forniquer","pos":"VER"} ,
		{"word":"forniquant","word_nosc":"forniquant","lemma":"forniquer","pos":"VER"} ,
		{"word":"fornique","word_nosc":"fornique","lemma":"forniquer","pos":"VER"} ,
		{"word":"forniquent","word_nosc":"forniquent","lemma":"forniquer","pos":"VER"} ,
		{"word":"forniquer","word_nosc":"forniquer","lemma":"forniquer","pos":"VER"} ,
		{"word":"forniqué","word_nosc":"fornique","lemma":"forniquer","pos":"VER"} ,
		{"word":"fortifia","word_nosc":"fortifia","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifiaient","word_nosc":"fortifiaient","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifiais","word_nosc":"fortifiais","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifiait","word_nosc":"fortifiait","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifiant","word_nosc":"fortifiant","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifie","word_nosc":"fortifie","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifient","word_nosc":"fortifient","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifier","word_nosc":"fortifier","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifiera","word_nosc":"fortifiera","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifierait","word_nosc":"fortifierait","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifiez","word_nosc":"fortifiez","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifions","word_nosc":"fortifions","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifiât","word_nosc":"fortifiat","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifièrent","word_nosc":"fortifierent","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifié","word_nosc":"fortifie","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifiée","word_nosc":"fortifiee","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifiées","word_nosc":"fortifiees","lemma":"fortifier","pos":"VER"} ,
		{"word":"fortifiés","word_nosc":"fortifies","lemma":"fortifier","pos":"VER"} ,
		{"word":"força","word_nosc":"forca","lemma":"forcer","pos":"VER"} ,
		{"word":"forçai","word_nosc":"forcai","lemma":"forcer","pos":"VER"} ,
		{"word":"forçaient","word_nosc":"forcaient","lemma":"forcer","pos":"VER"} ,
		{"word":"forçais","word_nosc":"forcais","lemma":"forcer","pos":"VER"} ,
		{"word":"forçait","word_nosc":"forcait","lemma":"forcer","pos":"VER"} ,
		{"word":"forçant","word_nosc":"forcant","lemma":"forcer","pos":"VER"} ,
		{"word":"forçons","word_nosc":"forcons","lemma":"forcer","pos":"VER"} ,
		{"word":"forçât","word_nosc":"forcat","lemma":"forcer","pos":"VER"} ,
		{"word":"foré","word_nosc":"fore","lemma":"forer","pos":"VER"} ,
		{"word":"forée","word_nosc":"foree","lemma":"forer","pos":"VER"} ,
		{"word":"forés","word_nosc":"fores","lemma":"forer","pos":"VER"} ,
		{"word":"fossilisé","word_nosc":"fossilise","lemma":"fossiliser","pos":"VER"} ,
		{"word":"fossilisés","word_nosc":"fossilises","lemma":"fossiliser","pos":"VER"} ,
		{"word":"fouailla","word_nosc":"fouailla","lemma":"fouailler","pos":"VER"} ,
		{"word":"fouaillai","word_nosc":"fouaillai","lemma":"fouailler","pos":"VER"} ,
		{"word":"fouaillaient","word_nosc":"fouaillaient","lemma":"fouailler","pos":"VER"} ,
		{"word":"fouaillait","word_nosc":"fouaillait","lemma":"fouailler","pos":"VER"} ,
		{"word":"fouaillant","word_nosc":"fouaillant","lemma":"fouailler","pos":"VER"} ,
		{"word":"fouaille","word_nosc":"fouaille","lemma":"fouailler","pos":"VER"} ,
		{"word":"fouailler","word_nosc":"fouailler","lemma":"fouailler","pos":"VER"} ,
		{"word":"fouaillé","word_nosc":"fouaille","lemma":"fouailler","pos":"VER"} ,
		{"word":"fouaillée","word_nosc":"fouaillee","lemma":"fouailler","pos":"VER"} ,
		{"word":"fouaillées","word_nosc":"fouaillees","lemma":"fouailler","pos":"VER"} ,
		{"word":"foudroie","word_nosc":"foudroie","lemma":"foudroyer","pos":"VER"} ,
		{"word":"foudroient","word_nosc":"foudroient","lemma":"foudroyer","pos":"VER"} ,
		{"word":"foudroiera","word_nosc":"foudroiera","lemma":"foudroyer","pos":"VER"} ,
		{"word":"foudroieraient","word_nosc":"foudroieraient","lemma":"foudroyer","pos":"VER"} ,
		{"word":"foudroierait","word_nosc":"foudroierait","lemma":"foudroyer","pos":"VER"} ,
		{"word":"foudroya","word_nosc":"foudroya","lemma":"foudroyer","pos":"VER"} ,
		{"word":"foudroyaient","word_nosc":"foudroyaient","lemma":"foudroyer","pos":"VER"} ,
		{"word":"foudroyait","word_nosc":"foudroyait","lemma":"foudroyer","pos":"VER"} ,
		{"word":"foudroyant","word_nosc":"foudroyant","lemma":"foudroyer","pos":"VER"} ,
		{"word":"foudroyer","word_nosc":"foudroyer","lemma":"foudroyer","pos":"VER"} ,
		{"word":"foudroyions","word_nosc":"foudroyions","lemma":"foudroyer","pos":"VER"} ,
		{"word":"foudroyèrent","word_nosc":"foudroyerent","lemma":"foudroyer","pos":"VER"} ,
		{"word":"foudroyé","word_nosc":"foudroye","lemma":"foudroyer","pos":"VER"} ,
		{"word":"foudroyée","word_nosc":"foudroyee","lemma":"foudroyer","pos":"VER"} ,
		{"word":"foudroyées","word_nosc":"foudroyees","lemma":"foudroyer","pos":"VER"} ,
		{"word":"foudroyés","word_nosc":"foudroyes","lemma":"foudroyer","pos":"VER"} ,
		{"word":"fouetta","word_nosc":"fouetta","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouettaient","word_nosc":"fouettaient","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouettais","word_nosc":"fouettais","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouettait","word_nosc":"fouettait","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouettant","word_nosc":"fouettant","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouette","word_nosc":"fouette","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouettent","word_nosc":"fouettent","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouetter","word_nosc":"fouetter","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouettera","word_nosc":"fouettera","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouetterai","word_nosc":"fouetterai","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouetterait","word_nosc":"fouetterait","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouetteront","word_nosc":"fouetteront","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouettes","word_nosc":"fouettes","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouettez","word_nosc":"fouettez","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouettons","word_nosc":"fouettons","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouettèrent","word_nosc":"fouetterent","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouetté","word_nosc":"fouette","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouettée","word_nosc":"fouettee","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouettées","word_nosc":"fouettees","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouettés","word_nosc":"fouettes","lemma":"fouetter","pos":"VER"} ,
		{"word":"fouge","word_nosc":"fouge","lemma":"fouger","pos":"VER"} ,
		{"word":"foui","word_nosc":"foui","lemma":"fouir","pos":"VER"} ,
		{"word":"fouie","word_nosc":"fouie","lemma":"fouir","pos":"VER"} ,
		{"word":"fouies","word_nosc":"fouies","lemma":"fouir","pos":"VER"} ,
		{"word":"fouilla","word_nosc":"fouilla","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillai","word_nosc":"fouillai","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillaient","word_nosc":"fouillaient","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillais","word_nosc":"fouillais","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillait","word_nosc":"fouillait","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillant","word_nosc":"fouillant","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillasse","word_nosc":"fouillasse","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouille","word_nosc":"fouille","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillent","word_nosc":"fouillent","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouiller","word_nosc":"fouiller","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillera","word_nosc":"fouillera","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillerai","word_nosc":"fouillerai","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillerais","word_nosc":"fouillerais","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillerait","word_nosc":"fouillerait","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouilleras","word_nosc":"fouilleras","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillerons","word_nosc":"fouillerons","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouilleront","word_nosc":"fouilleront","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouilles","word_nosc":"fouilles","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillez","word_nosc":"fouillez","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouilliez","word_nosc":"fouilliez","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillons","word_nosc":"fouillons","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillèrent","word_nosc":"fouillerent","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillé","word_nosc":"fouille","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillée","word_nosc":"fouillee","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillées","word_nosc":"fouillees","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouillés","word_nosc":"fouilles","lemma":"fouiller","pos":"VER"} ,
		{"word":"fouinaient","word_nosc":"fouinaient","lemma":"fouiner","pos":"VER"} ,
		{"word":"fouinais","word_nosc":"fouinais","lemma":"fouiner","pos":"VER"} ,
		{"word":"fouinait","word_nosc":"fouinait","lemma":"fouiner","pos":"VER"} ,
		{"word":"fouinant","word_nosc":"fouinant","lemma":"fouiner","pos":"VER"} ,
		{"word":"fouine","word_nosc":"fouine","lemma":"fouiner","pos":"VER"} ,
		{"word":"fouinent","word_nosc":"fouinent","lemma":"fouiner","pos":"VER"} ,
		{"word":"fouiner","word_nosc":"fouiner","lemma":"fouiner","pos":"VER"} ,
		{"word":"fouinera","word_nosc":"fouinera","lemma":"fouiner","pos":"VER"} ,
		{"word":"fouineriez","word_nosc":"fouineriez","lemma":"fouiner","pos":"VER"} ,
		{"word":"fouines","word_nosc":"fouines","lemma":"fouiner","pos":"VER"} ,
		{"word":"fouinez","word_nosc":"fouinez","lemma":"fouiner","pos":"VER"} ,
		{"word":"fouiniez","word_nosc":"fouiniez","lemma":"fouiner","pos":"VER"} ,
		{"word":"fouiné","word_nosc":"fouine","lemma":"fouiner","pos":"VER"} ,
		{"word":"fouir","word_nosc":"fouir","lemma":"fouir","pos":"VER"} ,
		{"word":"fouissait","word_nosc":"fouissait","lemma":"fouir","pos":"VER"} ,
		{"word":"fouissant","word_nosc":"fouissant","lemma":"fouir","pos":"VER"} ,
		{"word":"fouissent","word_nosc":"fouissent","lemma":"fouir","pos":"VER"} ,
		{"word":"fouit","word_nosc":"fouit","lemma":"fouir","pos":"VER"} ,
		{"word":"foula","word_nosc":"foula","lemma":"fouler","pos":"VER"} ,
		{"word":"foulaient","word_nosc":"foulaient","lemma":"fouler","pos":"VER"} ,
		{"word":"foulais","word_nosc":"foulais","lemma":"fouler","pos":"VER"} ,
		{"word":"foulait","word_nosc":"foulait","lemma":"fouler","pos":"VER"} ,
		{"word":"foulant","word_nosc":"foulant","lemma":"fouler","pos":"VER"} ,
		{"word":"foule","word_nosc":"foule","lemma":"fouler","pos":"VER"} ,
		{"word":"foulent","word_nosc":"foulent","lemma":"fouler","pos":"VER"} ,
		{"word":"fouler","word_nosc":"fouler","lemma":"fouler","pos":"VER"} ,
		{"word":"foulera","word_nosc":"foulera","lemma":"fouler","pos":"VER"} ,
		{"word":"fouleraient","word_nosc":"fouleraient","lemma":"fouler","pos":"VER"} ,
		{"word":"foulerait","word_nosc":"foulerait","lemma":"fouler","pos":"VER"} ,
		{"word":"foulerez","word_nosc":"foulerez","lemma":"fouler","pos":"VER"} ,
		{"word":"foules","word_nosc":"foules","lemma":"fouler","pos":"VER"} ,
		{"word":"foulions","word_nosc":"foulions","lemma":"fouler","pos":"VER"} ,
		{"word":"foulons","word_nosc":"foulons","lemma":"fouler","pos":"VER"} ,
		{"word":"foulèrent","word_nosc":"foulerent","lemma":"fouler","pos":"VER"} ,
		{"word":"foulé","word_nosc":"foule","lemma":"fouler","pos":"VER"} ,
		{"word":"foulée","word_nosc":"foulee","lemma":"fouler","pos":"VER"} ,
		{"word":"foulées","word_nosc":"foulees","lemma":"fouler","pos":"VER"} ,
		{"word":"foulés","word_nosc":"foules","lemma":"fouler","pos":"VER"} ,
		{"word":"fourbais","word_nosc":"fourbais","lemma":"fourber","pos":"VER"} ,
		{"word":"fourber","word_nosc":"fourber","lemma":"fourber","pos":"VER"} ,
		{"word":"fourbi","word_nosc":"fourbi","lemma":"fourbir","pos":"VER"} ,
		{"word":"fourbie","word_nosc":"fourbie","lemma":"fourbir","pos":"VER"} ,
		{"word":"fourbir","word_nosc":"fourbir","lemma":"fourbir","pos":"VER"} ,
		{"word":"fourbis","word_nosc":"fourbis","lemma":"fourbir","pos":"VER"} ,
		{"word":"fourbissaient","word_nosc":"fourbissaient","lemma":"fourbir","pos":"VER"} ,
		{"word":"fourbissais","word_nosc":"fourbissais","lemma":"fourbir","pos":"VER"} ,
		{"word":"fourbissait","word_nosc":"fourbissait","lemma":"fourbir","pos":"VER"} ,
		{"word":"fourbissant","word_nosc":"fourbissant","lemma":"fourbir","pos":"VER"} ,
		{"word":"fourbit","word_nosc":"fourbit","lemma":"fourbir","pos":"VER"} ,
		{"word":"fourcha","word_nosc":"fourcha","lemma":"fourcher","pos":"VER"} ,
		{"word":"fourche","word_nosc":"fourche","lemma":"fourcher","pos":"VER"} ,
		{"word":"fourchent","word_nosc":"fourchent","lemma":"fourcher","pos":"VER"} ,
		{"word":"fourcher","word_nosc":"fourcher","lemma":"fourcher","pos":"VER"} ,
		{"word":"fourchez","word_nosc":"fourchez","lemma":"fourcher","pos":"VER"} ,
		{"word":"fourché","word_nosc":"fourche","lemma":"fourcher","pos":"VER"} ,
		{"word":"fourchée","word_nosc":"fourchee","lemma":"fourcher","pos":"VER"} ,
		{"word":"fourgonnait","word_nosc":"fourgonnait","lemma":"fourgonner","pos":"VER"} ,
		{"word":"fourgonnas","word_nosc":"fourgonnas","lemma":"fourgonner","pos":"VER"} ,
		{"word":"fourgonne","word_nosc":"fourgonne","lemma":"fourgonner","pos":"VER"} ,
		{"word":"fourgonner","word_nosc":"fourgonner","lemma":"fourgonner","pos":"VER"} ,
		{"word":"fourguais","word_nosc":"fourguais","lemma":"fourguer","pos":"VER"} ,
		{"word":"fourguait","word_nosc":"fourguait","lemma":"fourguer","pos":"VER"} ,
		{"word":"fourguant","word_nosc":"fourguant","lemma":"fourguer","pos":"VER"} ,
		{"word":"fourgue","word_nosc":"fourgue","lemma":"fourguer","pos":"VER"} ,
		{"word":"fourguent","word_nosc":"fourguent","lemma":"fourguer","pos":"VER"} ,
		{"word":"fourguer","word_nosc":"fourguer","lemma":"fourguer","pos":"VER"} ,
		{"word":"fourgueraient","word_nosc":"fourgueraient","lemma":"fourguer","pos":"VER"} ,
		{"word":"fourguerais","word_nosc":"fourguerais","lemma":"fourguer","pos":"VER"} ,
		{"word":"fourguerait","word_nosc":"fourguerait","lemma":"fourguer","pos":"VER"} ,
		{"word":"fourgues","word_nosc":"fourgues","lemma":"fourguer","pos":"VER"} ,
		{"word":"fourguez","word_nosc":"fourguez","lemma":"fourguer","pos":"VER"} ,
		{"word":"fourgué","word_nosc":"fourgue","lemma":"fourguer","pos":"VER"} ,
		{"word":"fourguée","word_nosc":"fourguee","lemma":"fourguer","pos":"VER"} ,
		{"word":"fourguées","word_nosc":"fourguees","lemma":"fourguer","pos":"VER"} ,
		{"word":"fourgués","word_nosc":"fourgues","lemma":"fourguer","pos":"VER"} ,
		{"word":"fourmillaient","word_nosc":"fourmillaient","lemma":"fourmiller","pos":"VER"} ,
		{"word":"fourmillait","word_nosc":"fourmillait","lemma":"fourmiller","pos":"VER"} ,
		{"word":"fourmillant","word_nosc":"fourmillant","lemma":"fourmiller","pos":"VER"} ,
		{"word":"fourmille","word_nosc":"fourmille","lemma":"fourmiller","pos":"VER"} ,
		{"word":"fourmillent","word_nosc":"fourmillent","lemma":"fourmiller","pos":"VER"} ,
		{"word":"fourmiller","word_nosc":"fourmiller","lemma":"fourmiller","pos":"VER"} ,
		{"word":"fourmillé","word_nosc":"fourmille","lemma":"fourmiller","pos":"VER"} ,
		{"word":"fourni","word_nosc":"fourni","lemma":"fournir","pos":"VER"} ,
		{"word":"fournie","word_nosc":"fournie","lemma":"fournir","pos":"VER"} ,
		{"word":"fournies","word_nosc":"fournies","lemma":"fournir","pos":"VER"} ,
		{"word":"fournir","word_nosc":"fournir","lemma":"fournir","pos":"VER"} ,
		{"word":"fournira","word_nosc":"fournira","lemma":"fournir","pos":"VER"} ,
		{"word":"fournirai","word_nosc":"fournirai","lemma":"fournir","pos":"VER"} ,
		{"word":"fourniraient","word_nosc":"fourniraient","lemma":"fournir","pos":"VER"} ,
		{"word":"fournirait","word_nosc":"fournirait","lemma":"fournir","pos":"VER"} ,
		{"word":"fournirent","word_nosc":"fournirent","lemma":"fournir","pos":"VER"} ,
		{"word":"fournirez","word_nosc":"fournirez","lemma":"fournir","pos":"VER"} ,
		{"word":"fournirons","word_nosc":"fournirons","lemma":"fournir","pos":"VER"} ,
		{"word":"fourniront","word_nosc":"fourniront","lemma":"fournir","pos":"VER"} ,
		{"word":"fournis","word_nosc":"fournis","lemma":"fournir","pos":"VER"} ,
		{"word":"fournissaient","word_nosc":"fournissaient","lemma":"fournir","pos":"VER"} ,
		{"word":"fournissais","word_nosc":"fournissais","lemma":"fournir","pos":"VER"} ,
		{"word":"fournissait","word_nosc":"fournissait","lemma":"fournir","pos":"VER"} ,
		{"word":"fournissant","word_nosc":"fournissant","lemma":"fournir","pos":"VER"} ,
		{"word":"fournisse","word_nosc":"fournisse","lemma":"fournir","pos":"VER"} ,
		{"word":"fournissent","word_nosc":"fournissent","lemma":"fournir","pos":"VER"} ,
		{"word":"fournissez","word_nosc":"fournissez","lemma":"fournir","pos":"VER"} ,
		{"word":"fournissiez","word_nosc":"fournissiez","lemma":"fournir","pos":"VER"} ,
		{"word":"fournissions","word_nosc":"fournissions","lemma":"fournir","pos":"VER"} ,
		{"word":"fournissons","word_nosc":"fournissons","lemma":"fournir","pos":"VER"} ,
		{"word":"fournit","word_nosc":"fournit","lemma":"fournir","pos":"VER"} ,
		{"word":"fournîmes","word_nosc":"fournimes","lemma":"fournir","pos":"VER"} ,
		{"word":"fournît","word_nosc":"fournit","lemma":"fournir","pos":"VER"} ,
		{"word":"fourra","word_nosc":"fourra","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourrage","word_nosc":"fourrage","lemma":"fourrager","pos":"VER"} ,
		{"word":"fourragea","word_nosc":"fourragea","lemma":"fourrager","pos":"VER"} ,
		{"word":"fourrageaient","word_nosc":"fourrageaient","lemma":"fourrager","pos":"VER"} ,
		{"word":"fourrageais","word_nosc":"fourrageais","lemma":"fourrager","pos":"VER"} ,
		{"word":"fourrageait","word_nosc":"fourrageait","lemma":"fourrager","pos":"VER"} ,
		{"word":"fourrageant","word_nosc":"fourrageant","lemma":"fourrager","pos":"VER"} ,
		{"word":"fourrager","word_nosc":"fourrager","lemma":"fourrager","pos":"VER"} ,
		{"word":"fourragerait","word_nosc":"fourragerait","lemma":"fourrager","pos":"VER"} ,
		{"word":"fourragé","word_nosc":"fourrage","lemma":"fourrager","pos":"VER"} ,
		{"word":"fourrai","word_nosc":"fourrai","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourraient","word_nosc":"fourraient","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourrais","word_nosc":"fourrais","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourrait","word_nosc":"fourrait","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourrant","word_nosc":"fourrant","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourre","word_nosc":"fourre","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourrent","word_nosc":"fourrent","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourrer","word_nosc":"fourrer","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourrerai","word_nosc":"fourrerai","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourrez","word_nosc":"fourrez","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourriez","word_nosc":"fourriez","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourrions","word_nosc":"fourrions","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourrons","word_nosc":"fourrons","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourrât","word_nosc":"fourrat","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourrèrent","word_nosc":"fourrerent","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourré","word_nosc":"fourre","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourrée","word_nosc":"fourree","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourrées","word_nosc":"fourrees","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourrés","word_nosc":"fourres","lemma":"fourrer","pos":"VER"} ,
		{"word":"fourvoie","word_nosc":"fourvoie","lemma":"fourvoyer","pos":"VER"} ,
		{"word":"fourvoient","word_nosc":"fourvoient","lemma":"fourvoyer","pos":"VER"} ,
		{"word":"fourvoierais","word_nosc":"fourvoierais","lemma":"fourvoyer","pos":"VER"} ,
		{"word":"fourvoierait","word_nosc":"fourvoierait","lemma":"fourvoyer","pos":"VER"} ,
		{"word":"fourvoyaient","word_nosc":"fourvoyaient","lemma":"fourvoyer","pos":"VER"} ,
		{"word":"fourvoyait","word_nosc":"fourvoyait","lemma":"fourvoyer","pos":"VER"} ,
		{"word":"fourvoyant","word_nosc":"fourvoyant","lemma":"fourvoyer","pos":"VER"} ,
		{"word":"fourvoyer","word_nosc":"fourvoyer","lemma":"fourvoyer","pos":"VER"} ,
		{"word":"fourvoyâmes","word_nosc":"fourvoyames","lemma":"fourvoyer","pos":"VER"} ,
		{"word":"fourvoyé","word_nosc":"fourvoye","lemma":"fourvoyer","pos":"VER"} ,
		{"word":"fourvoyée","word_nosc":"fourvoyee","lemma":"fourvoyer","pos":"VER"} ,
		{"word":"fourvoyées","word_nosc":"fourvoyees","lemma":"fourvoyer","pos":"VER"} ,
		{"word":"fourvoyés","word_nosc":"fourvoyes","lemma":"fourvoyer","pos":"VER"} ,
		{"word":"fous","word_nosc":"fous","lemma":"foutre","pos":"VER"} ,
		{"word":"fout","word_nosc":"fout","lemma":"foutre","pos":"VER"} ,
		{"word":"foutaient","word_nosc":"foutaient","lemma":"foutre","pos":"VER"} ,
		{"word":"foutais","word_nosc":"foutais","lemma":"foutre","pos":"VER"} ,
		{"word":"foutait","word_nosc":"foutait","lemma":"foutre","pos":"VER"} ,
		{"word":"foutant","word_nosc":"foutant","lemma":"foutre","pos":"VER"} ,
		{"word":"foute","word_nosc":"foute","lemma":"foutre","pos":"VER"} ,
		{"word":"foutent","word_nosc":"foutent","lemma":"foutre","pos":"VER"} ,
		{"word":"foutes","word_nosc":"foutes","lemma":"foutre","pos":"VER"} ,
		{"word":"foutez","word_nosc":"foutez","lemma":"foutre","pos":"VER"} ,
		{"word":"foutiez","word_nosc":"foutiez","lemma":"foutre","pos":"VER"} ,
		{"word":"foutions","word_nosc":"foutions","lemma":"foutre","pos":"VER"} ,
		{"word":"foutit","word_nosc":"foutit","lemma":"foutre","pos":"VER"} ,
		{"word":"foutons","word_nosc":"foutons","lemma":"foutre","pos":"VER"} ,
		{"word":"foutra","word_nosc":"foutra","lemma":"foutre","pos":"VER"} ,
		{"word":"foutrai","word_nosc":"foutrai","lemma":"foutre","pos":"VER"} ,
		{"word":"foutraient","word_nosc":"foutraient","lemma":"foutre","pos":"VER"} ,
		{"word":"foutrais","word_nosc":"foutrais","lemma":"foutre","pos":"VER"} ,
		{"word":"foutrait","word_nosc":"foutrait","lemma":"foutre","pos":"VER"} ,
		{"word":"foutras","word_nosc":"foutras","lemma":"foutre","pos":"VER"} ,
		{"word":"foutre","word_nosc":"foutre","lemma":"foutre","pos":"VER"} ,
		{"word":"foutrez","word_nosc":"foutrez","lemma":"foutre","pos":"VER"} ,
		{"word":"foutrons","word_nosc":"foutrons","lemma":"foutre","pos":"VER"} ,
		{"word":"foutront","word_nosc":"foutront","lemma":"foutre","pos":"VER"} ,
		{"word":"foutu","word_nosc":"foutu","lemma":"foutre","pos":"VER"} ,
		{"word":"foutue","word_nosc":"foutue","lemma":"foutre","pos":"VER"} ,
		{"word":"foutues","word_nosc":"foutues","lemma":"foutre","pos":"VER"} ,
		{"word":"foutus","word_nosc":"foutus","lemma":"foutre","pos":"VER"} ,
		{"word":"foutît","word_nosc":"foutit","lemma":"foutre","pos":"VER"} ,
		{"word":"fracassa","word_nosc":"fracassa","lemma":"fracasser","pos":"VER"} ,
		{"word":"fracassaient","word_nosc":"fracassaient","lemma":"fracasser","pos":"VER"} ,
		{"word":"fracassait","word_nosc":"fracassait","lemma":"fracasser","pos":"VER"} ,
		{"word":"fracassant","word_nosc":"fracassant","lemma":"fracasser","pos":"VER"} ,
		{"word":"fracasse","word_nosc":"fracasse","lemma":"fracasser","pos":"VER"} ,
		{"word":"fracassent","word_nosc":"fracassent","lemma":"fracasser","pos":"VER"} ,
		{"word":"fracasser","word_nosc":"fracasser","lemma":"fracasser","pos":"VER"} ,
		{"word":"fracassera","word_nosc":"fracassera","lemma":"fracasser","pos":"VER"} ,
		{"word":"fracasserait","word_nosc":"fracasserait","lemma":"fracasser","pos":"VER"} ,
		{"word":"fracassez","word_nosc":"fracassez","lemma":"fracasser","pos":"VER"} ,
		{"word":"fracassât","word_nosc":"fracassat","lemma":"fracasser","pos":"VER"} ,
		{"word":"fracassé","word_nosc":"fracasse","lemma":"fracasser","pos":"VER"} ,
		{"word":"fracassée","word_nosc":"fracassee","lemma":"fracasser","pos":"VER"} ,
		{"word":"fracassées","word_nosc":"fracassees","lemma":"fracasser","pos":"VER"} ,
		{"word":"fracassés","word_nosc":"fracasses","lemma":"fracasser","pos":"VER"} ,
		{"word":"fractionnait","word_nosc":"fractionnait","lemma":"fractionner","pos":"VER"} ,
		{"word":"fractionnant","word_nosc":"fractionnant","lemma":"fractionner","pos":"VER"} ,
		{"word":"fractionne","word_nosc":"fractionne","lemma":"fractionner","pos":"VER"} ,
		{"word":"fractionner","word_nosc":"fractionner","lemma":"fractionner","pos":"VER"} ,
		{"word":"fractionné","word_nosc":"fractionne","lemma":"fractionner","pos":"VER"} ,
		{"word":"fractionnée","word_nosc":"fractionnee","lemma":"fractionner","pos":"VER"} ,
		{"word":"fractionnées","word_nosc":"fractionnees","lemma":"fractionner","pos":"VER"} ,
		{"word":"fractura","word_nosc":"fractura","lemma":"fracturer","pos":"VER"} ,
		{"word":"fracturant","word_nosc":"fracturant","lemma":"fracturer","pos":"VER"} ,
		{"word":"fracture","word_nosc":"fracture","lemma":"fracturer","pos":"VER"} ,
		{"word":"fracturent","word_nosc":"fracturent","lemma":"fracturer","pos":"VER"} ,
		{"word":"fracturer","word_nosc":"fracturer","lemma":"fracturer","pos":"VER"} ,
		{"word":"fractures","word_nosc":"fractures","lemma":"fracturer","pos":"VER"} ,
		{"word":"fracturé","word_nosc":"fracture","lemma":"fracturer","pos":"VER"} ,
		{"word":"fracturée","word_nosc":"fracturee","lemma":"fracturer","pos":"VER"} ,
		{"word":"fracturées","word_nosc":"fracturees","lemma":"fracturer","pos":"VER"} ,
		{"word":"fracturés","word_nosc":"fractures","lemma":"fracturer","pos":"VER"} ,
		{"word":"fragilise","word_nosc":"fragilise","lemma":"fragiliser","pos":"VER"} ,
		{"word":"fragiliser","word_nosc":"fragiliser","lemma":"fragiliser","pos":"VER"} ,
		{"word":"fragilisé","word_nosc":"fragilise","lemma":"fragiliser","pos":"VER"} ,
		{"word":"fragilisée","word_nosc":"fragilisee","lemma":"fragiliser","pos":"VER"} ,
		{"word":"fragilisés","word_nosc":"fragilises","lemma":"fragiliser","pos":"VER"} ,
		{"word":"fragmentaient","word_nosc":"fragmentaient","lemma":"fragmenter","pos":"VER"} ,
		{"word":"fragmentait","word_nosc":"fragmentait","lemma":"fragmenter","pos":"VER"} ,
		{"word":"fragmentant","word_nosc":"fragmentant","lemma":"fragmenter","pos":"VER"} ,
		{"word":"fragmente","word_nosc":"fragmente","lemma":"fragmenter","pos":"VER"} ,
		{"word":"fragmentent","word_nosc":"fragmentent","lemma":"fragmenter","pos":"VER"} ,
		{"word":"fragmenter","word_nosc":"fragmenter","lemma":"fragmenter","pos":"VER"} ,
		{"word":"fragmentèrent","word_nosc":"fragmenterent","lemma":"fragmenter","pos":"VER"} ,
		{"word":"fragmenté","word_nosc":"fragmente","lemma":"fragmenter","pos":"VER"} ,
		{"word":"fragmentée","word_nosc":"fragmentee","lemma":"fragmenter","pos":"VER"} ,
		{"word":"fragmentées","word_nosc":"fragmentees","lemma":"fragmenter","pos":"VER"} ,
		{"word":"fragmentés","word_nosc":"fragmentes","lemma":"fragmenter","pos":"VER"} ,
		{"word":"fraie","word_nosc":"fraie","lemma":"frayer","pos":"VER"} ,
		{"word":"fraient","word_nosc":"fraient","lemma":"frayer","pos":"VER"} ,
		{"word":"fraies","word_nosc":"fraies","lemma":"frayer","pos":"VER"} ,
		{"word":"fraise","word_nosc":"fraise","lemma":"fraiser","pos":"VER"} ,
		{"word":"fraiser","word_nosc":"fraiser","lemma":"fraiser","pos":"VER"} ,
		{"word":"fraisées","word_nosc":"fraisees","lemma":"fraiser","pos":"VER"} ,
		{"word":"franchi","word_nosc":"franchi","lemma":"franchir","pos":"VER"} ,
		{"word":"franchie","word_nosc":"franchie","lemma":"franchir","pos":"VER"} ,
		{"word":"franchies","word_nosc":"franchies","lemma":"franchir","pos":"VER"} ,
		{"word":"franchir","word_nosc":"franchir","lemma":"franchir","pos":"VER"} ,
		{"word":"franchira","word_nosc":"franchira","lemma":"franchir","pos":"VER"} ,
		{"word":"franchirai","word_nosc":"franchirai","lemma":"franchir","pos":"VER"} ,
		{"word":"franchiraient","word_nosc":"franchiraient","lemma":"franchir","pos":"VER"} ,
		{"word":"franchirais","word_nosc":"franchirais","lemma":"franchir","pos":"VER"} ,
		{"word":"franchirait","word_nosc":"franchirait","lemma":"franchir","pos":"VER"} ,
		{"word":"franchiras","word_nosc":"franchiras","lemma":"franchir","pos":"VER"} ,
		{"word":"franchirent","word_nosc":"franchirent","lemma":"franchir","pos":"VER"} ,
		{"word":"franchirez","word_nosc":"franchirez","lemma":"franchir","pos":"VER"} ,
		{"word":"franchirions","word_nosc":"franchirions","lemma":"franchir","pos":"VER"} ,
		{"word":"franchirons","word_nosc":"franchirons","lemma":"franchir","pos":"VER"} ,
		{"word":"franchiront","word_nosc":"franchiront","lemma":"franchir","pos":"VER"} ,
		{"word":"franchis","word_nosc":"franchis","lemma":"franchir","pos":"VER"} ,
		{"word":"franchisant","word_nosc":"franchisant","lemma":"franchiser","pos":"VER"} ,
		{"word":"franchiser","word_nosc":"franchiser","lemma":"franchiser","pos":"VER"} ,
		{"word":"franchissaient","word_nosc":"franchissaient","lemma":"franchir","pos":"VER"} ,
		{"word":"franchissais","word_nosc":"franchissais","lemma":"franchir","pos":"VER"} ,
		{"word":"franchissait","word_nosc":"franchissait","lemma":"franchir","pos":"VER"} ,
		{"word":"franchissant","word_nosc":"franchissant","lemma":"franchir","pos":"VER"} ,
		{"word":"franchisse","word_nosc":"franchisse","lemma":"franchir","pos":"VER"} ,
		{"word":"franchissent","word_nosc":"franchissent","lemma":"franchir","pos":"VER"} ,
		{"word":"franchissez","word_nosc":"franchissez","lemma":"franchir","pos":"VER"} ,
		{"word":"franchissions","word_nosc":"franchissions","lemma":"franchir","pos":"VER"} ,
		{"word":"franchissons","word_nosc":"franchissons","lemma":"franchir","pos":"VER"} ,
		{"word":"franchisé","word_nosc":"franchise","lemma":"franchiser","pos":"VER"} ,
		{"word":"franchisée","word_nosc":"franchisee","lemma":"franchiser","pos":"VER"} ,
		{"word":"franchit","word_nosc":"franchit","lemma":"franchir","pos":"VER"} ,
		{"word":"franchîmes","word_nosc":"franchimes","lemma":"franchir","pos":"VER"} ,
		{"word":"franchît","word_nosc":"franchit","lemma":"franchir","pos":"VER"} ,
		{"word":"francisant","word_nosc":"francisant","lemma":"franciser","pos":"VER"} ,
		{"word":"franciser","word_nosc":"franciser","lemma":"franciser","pos":"VER"} ,
		{"word":"francisé","word_nosc":"francise","lemma":"franciser","pos":"VER"} ,
		{"word":"francisés","word_nosc":"francises","lemma":"franciser","pos":"VER"} ,
		{"word":"frange","word_nosc":"frange","lemma":"franger","pos":"VER"} ,
		{"word":"frangeaient","word_nosc":"frangeaient","lemma":"franger","pos":"VER"} ,
		{"word":"frangeait","word_nosc":"frangeait","lemma":"franger","pos":"VER"} ,
		{"word":"frangeant","word_nosc":"frangeant","lemma":"franger","pos":"VER"} ,
		{"word":"frangent","word_nosc":"frangent","lemma":"franger","pos":"VER"} ,
		{"word":"frangé","word_nosc":"frange","lemma":"franger","pos":"VER"} ,
		{"word":"frangée","word_nosc":"frangee","lemma":"franger","pos":"VER"} ,
		{"word":"frangées","word_nosc":"frangees","lemma":"franger","pos":"VER"} ,
		{"word":"frangés","word_nosc":"franges","lemma":"franger","pos":"VER"} ,
		{"word":"frappa","word_nosc":"frappa","lemma":"frapper","pos":"VER"} ,
		{"word":"frappai","word_nosc":"frappai","lemma":"frapper","pos":"VER"} ,
		{"word":"frappaient","word_nosc":"frappaient","lemma":"frapper","pos":"VER"} ,
		{"word":"frappais","word_nosc":"frappais","lemma":"frapper","pos":"VER"} ,
		{"word":"frappait","word_nosc":"frappait","lemma":"frapper","pos":"VER"} ,
		{"word":"frappant","word_nosc":"frappant","lemma":"frapper","pos":"VER"} ,
		{"word":"frappe","word_nosc":"frappe","lemma":"frapper","pos":"VER"} ,
		{"word":"frappent","word_nosc":"frappent","lemma":"frapper","pos":"VER"} ,
		{"word":"frapper","word_nosc":"frapper","lemma":"frapper","pos":"VER"} ,
		{"word":"frappera","word_nosc":"frappera","lemma":"frapper","pos":"VER"} ,
		{"word":"frapperai","word_nosc":"frapperai","lemma":"frapper","pos":"VER"} ,
		{"word":"frapperaient","word_nosc":"frapperaient","lemma":"frapper","pos":"VER"} ,
		{"word":"frapperais","word_nosc":"frapperais","lemma":"frapper","pos":"VER"} ,
		{"word":"frapperait","word_nosc":"frapperait","lemma":"frapper","pos":"VER"} ,
		{"word":"frapperas","word_nosc":"frapperas","lemma":"frapper","pos":"VER"} ,
		{"word":"frapperez","word_nosc":"frapperez","lemma":"frapper","pos":"VER"} ,
		{"word":"frapperiez","word_nosc":"frapperiez","lemma":"frapper","pos":"VER"} ,
		{"word":"frapperons","word_nosc":"frapperons","lemma":"frapper","pos":"VER"} ,
		{"word":"frapperont","word_nosc":"frapperont","lemma":"frapper","pos":"VER"} ,
		{"word":"frappes","word_nosc":"frappes","lemma":"frapper","pos":"VER"} ,
		{"word":"frappez","word_nosc":"frappez","lemma":"frapper","pos":"VER"} ,
		{"word":"frappiez","word_nosc":"frappiez","lemma":"frapper","pos":"VER"} ,
		{"word":"frappions","word_nosc":"frappions","lemma":"frapper","pos":"VER"} ,
		{"word":"frappons","word_nosc":"frappons","lemma":"frapper","pos":"VER"} ,
		{"word":"frappât","word_nosc":"frappat","lemma":"frapper","pos":"VER"} ,
		{"word":"frappèrent","word_nosc":"frapperent","lemma":"frapper","pos":"VER"} ,
		{"word":"frappé","word_nosc":"frappe","lemma":"frapper","pos":"VER"} ,
		{"word":"frappée","word_nosc":"frappee","lemma":"frapper","pos":"VER"} ,
		{"word":"frappées","word_nosc":"frappees","lemma":"frapper","pos":"VER"} ,
		{"word":"frappés","word_nosc":"frappes","lemma":"frapper","pos":"VER"} ,
		{"word":"fraser","word_nosc":"fraser","lemma":"fraser","pos":"VER"} ,
		{"word":"fraternisa","word_nosc":"fraternisa","lemma":"fraterniser","pos":"VER"} ,
		{"word":"fraternisaient","word_nosc":"fraternisaient","lemma":"fraterniser","pos":"VER"} ,
		{"word":"fraternisant","word_nosc":"fraternisant","lemma":"fraterniser","pos":"VER"} ,
		{"word":"fraternise","word_nosc":"fraternise","lemma":"fraterniser","pos":"VER"} ,
		{"word":"fraternisent","word_nosc":"fraternisent","lemma":"fraterniser","pos":"VER"} ,
		{"word":"fraterniser","word_nosc":"fraterniser","lemma":"fraterniser","pos":"VER"} ,
		{"word":"fraterniserait","word_nosc":"fraterniserait","lemma":"fraterniser","pos":"VER"} ,
		{"word":"fraternisiez","word_nosc":"fraternisiez","lemma":"fraterniser","pos":"VER"} ,
		{"word":"fraternisons","word_nosc":"fraternisons","lemma":"fraterniser","pos":"VER"} ,
		{"word":"fraternisèrent","word_nosc":"fraterniserent","lemma":"fraterniser","pos":"VER"} ,
		{"word":"fraternisé","word_nosc":"fraternise","lemma":"fraterniser","pos":"VER"} ,
		{"word":"frauda","word_nosc":"frauda","lemma":"frauder","pos":"VER"} ,
		{"word":"fraudais","word_nosc":"fraudais","lemma":"frauder","pos":"VER"} ,
		{"word":"fraude","word_nosc":"fraude","lemma":"frauder","pos":"VER"} ,
		{"word":"frauder","word_nosc":"frauder","lemma":"frauder","pos":"VER"} ,
		{"word":"fraudes","word_nosc":"fraudes","lemma":"frauder","pos":"VER"} ,
		{"word":"fraudé","word_nosc":"fraude","lemma":"frauder","pos":"VER"} ,
		{"word":"fraya","word_nosc":"fraya","lemma":"frayer","pos":"VER"} ,
		{"word":"frayai","word_nosc":"frayai","lemma":"frayer","pos":"VER"} ,
		{"word":"frayaient","word_nosc":"frayaient","lemma":"frayer","pos":"VER"} ,
		{"word":"frayais","word_nosc":"frayais","lemma":"frayer","pos":"VER"} ,
		{"word":"frayait","word_nosc":"frayait","lemma":"frayer","pos":"VER"} ,
		{"word":"frayant","word_nosc":"frayant","lemma":"frayer","pos":"VER"} ,
		{"word":"fraye","word_nosc":"fraye","lemma":"frayer","pos":"VER"} ,
		{"word":"frayent","word_nosc":"frayent","lemma":"frayer","pos":"VER"} ,
		{"word":"frayer","word_nosc":"frayer","lemma":"frayer","pos":"VER"} ,
		{"word":"frayes","word_nosc":"frayes","lemma":"frayer","pos":"VER"} ,
		{"word":"frayez","word_nosc":"frayez","lemma":"frayer","pos":"VER"} ,
		{"word":"frayons","word_nosc":"frayons","lemma":"frayer","pos":"VER"} ,
		{"word":"frayèrent","word_nosc":"frayerent","lemma":"frayer","pos":"VER"} ,
		{"word":"frayé","word_nosc":"fraye","lemma":"frayer","pos":"VER"} ,
		{"word":"frayée","word_nosc":"frayee","lemma":"frayer","pos":"VER"} ,
		{"word":"fraîchi","word_nosc":"fraichi","lemma":"fraîchir","pos":"VER"} ,
		{"word":"fraîchir","word_nosc":"fraichir","lemma":"fraîchir","pos":"VER"} ,
		{"word":"fraîchira","word_nosc":"fraichira","lemma":"fraîchir","pos":"VER"} ,
		{"word":"fraîchissait","word_nosc":"fraichissait","lemma":"fraîchir","pos":"VER"} ,
		{"word":"fraîchissant","word_nosc":"fraichissant","lemma":"fraîchir","pos":"VER"} ,
		{"word":"fraîchissent","word_nosc":"fraichissent","lemma":"fraîchir","pos":"VER"} ,
		{"word":"fraîchit","word_nosc":"fraichit","lemma":"fraîchir","pos":"VER"} ,
		{"word":"fredonna","word_nosc":"fredonna","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonnaient","word_nosc":"fredonnaient","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonnais","word_nosc":"fredonnais","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonnait","word_nosc":"fredonnait","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonnant","word_nosc":"fredonnant","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonne","word_nosc":"fredonne","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonnent","word_nosc":"fredonnent","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonner","word_nosc":"fredonner","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonnera","word_nosc":"fredonnera","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonnerai","word_nosc":"fredonnerai","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonnerait","word_nosc":"fredonnerait","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonneras","word_nosc":"fredonneras","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonnez","word_nosc":"fredonnez","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonnons","word_nosc":"fredonnons","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonnâmes","word_nosc":"fredonnames","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonnèrent","word_nosc":"fredonnerent","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonné","word_nosc":"fredonne","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonnée","word_nosc":"fredonnee","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonnées","word_nosc":"fredonnees","lemma":"fredonner","pos":"VER"} ,
		{"word":"fredonnés","word_nosc":"fredonnes","lemma":"fredonner","pos":"VER"} ,
		{"word":"freina","word_nosc":"freina","lemma":"freiner","pos":"VER"} ,
		{"word":"freinai","word_nosc":"freinai","lemma":"freiner","pos":"VER"} ,
		{"word":"freinaient","word_nosc":"freinaient","lemma":"freiner","pos":"VER"} ,
		{"word":"freinais","word_nosc":"freinais","lemma":"freiner","pos":"VER"} ,
		{"word":"freinait","word_nosc":"freinait","lemma":"freiner","pos":"VER"} ,
		{"word":"freinant","word_nosc":"freinant","lemma":"freiner","pos":"VER"} ,
		{"word":"freine","word_nosc":"freine","lemma":"freiner","pos":"VER"} ,
		{"word":"freinent","word_nosc":"freinent","lemma":"freiner","pos":"VER"} ,
		{"word":"freiner","word_nosc":"freiner","lemma":"freiner","pos":"VER"} ,
		{"word":"freinera","word_nosc":"freinera","lemma":"freiner","pos":"VER"} ,
		{"word":"freinerais","word_nosc":"freinerais","lemma":"freiner","pos":"VER"} ,
		{"word":"freinerez","word_nosc":"freinerez","lemma":"freiner","pos":"VER"} ,
		{"word":"freines","word_nosc":"freines","lemma":"freiner","pos":"VER"} ,
		{"word":"freinez","word_nosc":"freinez","lemma":"freiner","pos":"VER"} ,
		{"word":"freinons","word_nosc":"freinons","lemma":"freiner","pos":"VER"} ,
		{"word":"freinèrent","word_nosc":"freinerent","lemma":"freiner","pos":"VER"} ,
		{"word":"freiné","word_nosc":"freine","lemma":"freiner","pos":"VER"} ,
		{"word":"freinée","word_nosc":"freinee","lemma":"freiner","pos":"VER"} ,
		{"word":"freinées","word_nosc":"freinees","lemma":"freiner","pos":"VER"} ,
		{"word":"freinés","word_nosc":"freines","lemma":"freiner","pos":"VER"} ,
		{"word":"frelater","word_nosc":"frelater","lemma":"frelater","pos":"VER"} ,
		{"word":"frelatée","word_nosc":"frelatee","lemma":"frelater","pos":"VER"} ,
		{"word":"frelatés","word_nosc":"frelates","lemma":"frelater","pos":"VER"} ,
		{"word":"fretta","word_nosc":"fretta","lemma":"fretter","pos":"VER"} ,
		{"word":"fricasse","word_nosc":"fricasse","lemma":"fricasser","pos":"VER"} ,
		{"word":"fricasserai","word_nosc":"fricasserai","lemma":"fricasser","pos":"VER"} ,
		{"word":"fricassé","word_nosc":"fricasse","lemma":"fricasser","pos":"VER"} ,
		{"word":"fricotaient","word_nosc":"fricotaient","lemma":"fricoter","pos":"VER"} ,
		{"word":"fricotais","word_nosc":"fricotais","lemma":"fricoter","pos":"VER"} ,
		{"word":"fricotait","word_nosc":"fricotait","lemma":"fricoter","pos":"VER"} ,
		{"word":"fricotant","word_nosc":"fricotant","lemma":"fricoter","pos":"VER"} ,
		{"word":"fricote","word_nosc":"fricote","lemma":"fricoter","pos":"VER"} ,
		{"word":"fricotent","word_nosc":"fricotent","lemma":"fricoter","pos":"VER"} ,
		{"word":"fricoter","word_nosc":"fricoter","lemma":"fricoter","pos":"VER"} ,
		{"word":"fricotes","word_nosc":"fricotes","lemma":"fricoter","pos":"VER"} ,
		{"word":"fricotez","word_nosc":"fricotez","lemma":"fricoter","pos":"VER"} ,
		{"word":"fricotiez","word_nosc":"fricotiez","lemma":"fricoter","pos":"VER"} ,
		{"word":"fricoté","word_nosc":"fricote","lemma":"fricoter","pos":"VER"} ,
		{"word":"frictionna","word_nosc":"frictionna","lemma":"frictionner","pos":"VER"} ,
		{"word":"frictionnais","word_nosc":"frictionnais","lemma":"frictionner","pos":"VER"} ,
		{"word":"frictionnait","word_nosc":"frictionnait","lemma":"frictionner","pos":"VER"} ,
		{"word":"frictionnant","word_nosc":"frictionnant","lemma":"frictionner","pos":"VER"} ,
		{"word":"frictionne","word_nosc":"frictionne","lemma":"frictionner","pos":"VER"} ,
		{"word":"frictionnent","word_nosc":"frictionnent","lemma":"frictionner","pos":"VER"} ,
		{"word":"frictionner","word_nosc":"frictionner","lemma":"frictionner","pos":"VER"} ,
		{"word":"frictionnerai","word_nosc":"frictionnerai","lemma":"frictionner","pos":"VER"} ,
		{"word":"frictionnerait","word_nosc":"frictionnerait","lemma":"frictionner","pos":"VER"} ,
		{"word":"frictionnez","word_nosc":"frictionnez","lemma":"frictionner","pos":"VER"} ,
		{"word":"frictionnèrent","word_nosc":"frictionnerent","lemma":"frictionner","pos":"VER"} ,
		{"word":"frictionné","word_nosc":"frictionne","lemma":"frictionner","pos":"VER"} ,
		{"word":"frigorifiant","word_nosc":"frigorifiant","lemma":"frigorifier","pos":"VER"} ,
		{"word":"frigorifier","word_nosc":"frigorifier","lemma":"frigorifier","pos":"VER"} ,
		{"word":"frigorifié","word_nosc":"frigorifie","lemma":"frigorifier","pos":"VER"} ,
		{"word":"frigorifiée","word_nosc":"frigorifiee","lemma":"frigorifier","pos":"VER"} ,
		{"word":"frigorifiés","word_nosc":"frigorifies","lemma":"frigorifier","pos":"VER"} ,
		{"word":"frima","word_nosc":"frima","lemma":"frimer","pos":"VER"} ,
		{"word":"frimaient","word_nosc":"frimaient","lemma":"frimer","pos":"VER"} ,
		{"word":"frimais","word_nosc":"frimais","lemma":"frimer","pos":"VER"} ,
		{"word":"frimait","word_nosc":"frimait","lemma":"frimer","pos":"VER"} ,
		{"word":"frimant","word_nosc":"frimant","lemma":"frimer","pos":"VER"} ,
		{"word":"frime","word_nosc":"frime","lemma":"frimer","pos":"VER"} ,
		{"word":"friment","word_nosc":"friment","lemma":"frimer","pos":"VER"} ,
		{"word":"frimer","word_nosc":"frimer","lemma":"frimer","pos":"VER"} ,
		{"word":"frimerais","word_nosc":"frimerais","lemma":"frimer","pos":"VER"} ,
		{"word":"frimes","word_nosc":"frimes","lemma":"frimer","pos":"VER"} ,
		{"word":"frimez","word_nosc":"frimez","lemma":"frimer","pos":"VER"} ,
		{"word":"frimiez","word_nosc":"frimiez","lemma":"frimer","pos":"VER"} ,
		{"word":"frimé","word_nosc":"frime","lemma":"frimer","pos":"VER"} ,
		{"word":"frimée","word_nosc":"frimee","lemma":"frimer","pos":"VER"} ,
		{"word":"frimées","word_nosc":"frimees","lemma":"frimer","pos":"VER"} ,
		{"word":"frimés","word_nosc":"frimes","lemma":"frimer","pos":"VER"} ,
		{"word":"fringuait","word_nosc":"fringuait","lemma":"fringuer","pos":"VER"} ,
		{"word":"fringue","word_nosc":"fringue","lemma":"fringuer","pos":"VER"} ,
		{"word":"fringuent","word_nosc":"fringuent","lemma":"fringuer","pos":"VER"} ,
		{"word":"fringuer","word_nosc":"fringuer","lemma":"fringuer","pos":"VER"} ,
		{"word":"fringues","word_nosc":"fringues","lemma":"fringuer","pos":"VER"} ,
		{"word":"fringuez","word_nosc":"fringuez","lemma":"fringuer","pos":"VER"} ,
		{"word":"fringué","word_nosc":"fringue","lemma":"fringuer","pos":"VER"} ,
		{"word":"fringuée","word_nosc":"fringuee","lemma":"fringuer","pos":"VER"} ,
		{"word":"fringuées","word_nosc":"fringuees","lemma":"fringuer","pos":"VER"} ,
		{"word":"fringués","word_nosc":"fringues","lemma":"fringuer","pos":"VER"} ,
		{"word":"fripa","word_nosc":"fripa","lemma":"friper","pos":"VER"} ,
		{"word":"fripaient","word_nosc":"fripaient","lemma":"friper","pos":"VER"} ,
		{"word":"fripant","word_nosc":"fripant","lemma":"friper","pos":"VER"} ,
		{"word":"fripe","word_nosc":"fripe","lemma":"friper","pos":"VER"} ,
		{"word":"fripent","word_nosc":"fripent","lemma":"friper","pos":"VER"} ,
		{"word":"friper","word_nosc":"friper","lemma":"friper","pos":"VER"} ,
		{"word":"fripé","word_nosc":"fripe","lemma":"friper","pos":"VER"} ,
		{"word":"fripée","word_nosc":"fripee","lemma":"friper","pos":"VER"} ,
		{"word":"fripées","word_nosc":"fripees","lemma":"friper","pos":"VER"} ,
		{"word":"fripés","word_nosc":"fripes","lemma":"friper","pos":"VER"} ,
		{"word":"frire","word_nosc":"frire","lemma":"frire","pos":"VER"} ,
		{"word":"fris","word_nosc":"fris","lemma":"frire","pos":"VER"} ,
		{"word":"frisa","word_nosc":"frisa","lemma":"friser","pos":"VER"} ,
		{"word":"frisaient","word_nosc":"frisaient","lemma":"friser","pos":"VER"} ,
		{"word":"frisais","word_nosc":"frisais","lemma":"friser","pos":"VER"} ,
		{"word":"frisait","word_nosc":"frisait","lemma":"friser","pos":"VER"} ,
		{"word":"frisant","word_nosc":"frisant","lemma":"friser","pos":"VER"} ,
		{"word":"frise","word_nosc":"frise","lemma":"friser","pos":"VER"} ,
		{"word":"friseler","word_nosc":"friseler","lemma":"friseler","pos":"VER"} ,
		{"word":"frisent","word_nosc":"frisent","lemma":"friser","pos":"VER"} ,
		{"word":"friser","word_nosc":"friser","lemma":"friser","pos":"VER"} ,
		{"word":"friserai","word_nosc":"friserai","lemma":"friser","pos":"VER"} ,
		{"word":"frises","word_nosc":"frises","lemma":"friser","pos":"VER"} ,
		{"word":"frisez","word_nosc":"frisez","lemma":"friser","pos":"VER"} ,
		{"word":"frisons","word_nosc":"frisons","lemma":"friser","pos":"VER"} ,
		{"word":"frisottaient","word_nosc":"frisottaient","lemma":"frisotter","pos":"VER"} ,
		{"word":"frisottent","word_nosc":"frisottent","lemma":"frisotter","pos":"VER"} ,
		{"word":"frisotter","word_nosc":"frisotter","lemma":"frisotter","pos":"VER"} ,
		{"word":"frisotté","word_nosc":"frisotte","lemma":"frisotter","pos":"VER"} ,
		{"word":"frisottée","word_nosc":"frisottee","lemma":"frisotter","pos":"VER"} ,
		{"word":"frisottées","word_nosc":"frisottees","lemma":"frisotter","pos":"VER"} ,
		{"word":"frisottés","word_nosc":"frisottes","lemma":"frisotter","pos":"VER"} ,
		{"word":"frissonna","word_nosc":"frissonna","lemma":"frissonner","pos":"VER"} ,
		{"word":"frissonnai","word_nosc":"frissonnai","lemma":"frissonner","pos":"VER"} ,
		{"word":"frissonnaient","word_nosc":"frissonnaient","lemma":"frissonner","pos":"VER"} ,
		{"word":"frissonnais","word_nosc":"frissonnais","lemma":"frissonner","pos":"VER"} ,
		{"word":"frissonnait","word_nosc":"frissonnait","lemma":"frissonner","pos":"VER"} ,
		{"word":"frissonnant","word_nosc":"frissonnant","lemma":"frissonner","pos":"VER"} ,
		{"word":"frissonne","word_nosc":"frissonne","lemma":"frissonner","pos":"VER"} ,
		{"word":"frissonnent","word_nosc":"frissonnent","lemma":"frissonner","pos":"VER"} ,
		{"word":"frissonner","word_nosc":"frissonner","lemma":"frissonner","pos":"VER"} ,
		{"word":"frissonnes","word_nosc":"frissonnes","lemma":"frissonner","pos":"VER"} ,
		{"word":"frissonnez","word_nosc":"frissonnez","lemma":"frissonner","pos":"VER"} ,
		{"word":"frissonnèrent","word_nosc":"frissonnerent","lemma":"frissonner","pos":"VER"} ,
		{"word":"frissonné","word_nosc":"frissonne","lemma":"frissonner","pos":"VER"} ,
		{"word":"frisâmes","word_nosc":"frisames","lemma":"friser","pos":"VER"} ,
		{"word":"frisé","word_nosc":"frise","lemma":"friser","pos":"VER"} ,
		{"word":"frisée","word_nosc":"frisee","lemma":"friser","pos":"VER"} ,
		{"word":"frisées","word_nosc":"frisees","lemma":"friser","pos":"VER"} ,
		{"word":"frisés","word_nosc":"frises","lemma":"friser","pos":"VER"} ,
		{"word":"frit","word_nosc":"frit","lemma":"frire","pos":"VER"} ,
		{"word":"frits","word_nosc":"frits","lemma":"frire","pos":"VER"} ,
		{"word":"fritter","word_nosc":"fritter","lemma":"fritter","pos":"VER"} ,
		{"word":"frittés","word_nosc":"frittes","lemma":"fritter","pos":"VER"} ,
		{"word":"froidi","word_nosc":"froidi","lemma":"froidir","pos":"VER"} ,
		{"word":"froidir","word_nosc":"froidir","lemma":"froidir","pos":"VER"} ,
		{"word":"froidissait","word_nosc":"froidissait","lemma":"froidir","pos":"VER"} ,
		{"word":"froissa","word_nosc":"froissa","lemma":"froisser","pos":"VER"} ,
		{"word":"froissaient","word_nosc":"froissaient","lemma":"froisser","pos":"VER"} ,
		{"word":"froissais","word_nosc":"froissais","lemma":"froisser","pos":"VER"} ,
		{"word":"froissait","word_nosc":"froissait","lemma":"froisser","pos":"VER"} ,
		{"word":"froissant","word_nosc":"froissant","lemma":"froisser","pos":"VER"} ,
		{"word":"froisse","word_nosc":"froisse","lemma":"froisser","pos":"VER"} ,
		{"word":"froissent","word_nosc":"froissent","lemma":"froisser","pos":"VER"} ,
		{"word":"froisser","word_nosc":"froisser","lemma":"froisser","pos":"VER"} ,
		{"word":"froissera","word_nosc":"froissera","lemma":"froisser","pos":"VER"} ,
		{"word":"froisserais","word_nosc":"froisserais","lemma":"froisser","pos":"VER"} ,
		{"word":"froisserait","word_nosc":"froisserait","lemma":"froisser","pos":"VER"} ,
		{"word":"froisses","word_nosc":"froisses","lemma":"froisser","pos":"VER"} ,
		{"word":"froissez","word_nosc":"froissez","lemma":"froisser","pos":"VER"} ,
		{"word":"froissions","word_nosc":"froissions","lemma":"froisser","pos":"VER"} ,
		{"word":"froissèrent","word_nosc":"froisserent","lemma":"froisser","pos":"VER"} ,
		{"word":"froissé","word_nosc":"froisse","lemma":"froisser","pos":"VER"} ,
		{"word":"froissée","word_nosc":"froissee","lemma":"froisser","pos":"VER"} ,
		{"word":"froissées","word_nosc":"froissees","lemma":"froisser","pos":"VER"} ,
		{"word":"froissés","word_nosc":"froisses","lemma":"froisser","pos":"VER"} ,
		{"word":"fronce","word_nosc":"fronce","lemma":"froncer","pos":"VER"} ,
		{"word":"froncer","word_nosc":"froncer","lemma":"froncer","pos":"VER"} ,
		{"word":"froncerait","word_nosc":"froncerait","lemma":"froncer","pos":"VER"} ,
		{"word":"fronces","word_nosc":"fronces","lemma":"froncer","pos":"VER"} ,
		{"word":"froncez","word_nosc":"froncez","lemma":"froncer","pos":"VER"} ,
		{"word":"froncèrent","word_nosc":"froncerent","lemma":"froncer","pos":"VER"} ,
		{"word":"froncé","word_nosc":"fronce","lemma":"froncer","pos":"VER"} ,
		{"word":"froncée","word_nosc":"froncee","lemma":"froncer","pos":"VER"} ,
		{"word":"froncés","word_nosc":"fronces","lemma":"froncer","pos":"VER"} ,
		{"word":"frondaient","word_nosc":"frondaient","lemma":"fronder","pos":"VER"} ,
		{"word":"fronde","word_nosc":"fronde","lemma":"fronder","pos":"VER"} ,
		{"word":"frondent","word_nosc":"frondent","lemma":"fronder","pos":"VER"} ,
		{"word":"frondé","word_nosc":"fronde","lemma":"fronder","pos":"VER"} ,
		{"word":"fronça","word_nosc":"fronca","lemma":"froncer","pos":"VER"} ,
		{"word":"fronçai","word_nosc":"froncai","lemma":"froncer","pos":"VER"} ,
		{"word":"fronçaient","word_nosc":"froncaient","lemma":"froncer","pos":"VER"} ,
		{"word":"fronçais","word_nosc":"froncais","lemma":"froncer","pos":"VER"} ,
		{"word":"fronçait","word_nosc":"froncait","lemma":"froncer","pos":"VER"} ,
		{"word":"fronçant","word_nosc":"froncant","lemma":"froncer","pos":"VER"} ,
		{"word":"frotta","word_nosc":"frotta","lemma":"frotter","pos":"VER"} ,
		{"word":"frottai","word_nosc":"frottai","lemma":"frotter","pos":"VER"} ,
		{"word":"frottaient","word_nosc":"frottaient","lemma":"frotter","pos":"VER"} ,
		{"word":"frottais","word_nosc":"frottais","lemma":"frotter","pos":"VER"} ,
		{"word":"frottait","word_nosc":"frottait","lemma":"frotter","pos":"VER"} ,
		{"word":"frottant","word_nosc":"frottant","lemma":"frotter","pos":"VER"} ,
		{"word":"frotte","word_nosc":"frotte","lemma":"frotter","pos":"VER"} ,
		{"word":"frottent","word_nosc":"frottent","lemma":"frotter","pos":"VER"} ,
		{"word":"frotter","word_nosc":"frotter","lemma":"frotter","pos":"VER"} ,
		{"word":"frottera","word_nosc":"frottera","lemma":"frotter","pos":"VER"} ,
		{"word":"frotterai","word_nosc":"frotterai","lemma":"frotter","pos":"VER"} ,
		{"word":"frotterais","word_nosc":"frotterais","lemma":"frotter","pos":"VER"} ,
		{"word":"frotterait","word_nosc":"frotterait","lemma":"frotter","pos":"VER"} ,
		{"word":"frotteras","word_nosc":"frotteras","lemma":"frotter","pos":"VER"} ,
		{"word":"frotterons","word_nosc":"frotterons","lemma":"frotter","pos":"VER"} ,
		{"word":"frottes","word_nosc":"frottes","lemma":"frotter","pos":"VER"} ,
		{"word":"frottez","word_nosc":"frottez","lemma":"frotter","pos":"VER"} ,
		{"word":"frottiez","word_nosc":"frottiez","lemma":"frotter","pos":"VER"} ,
		{"word":"frottions","word_nosc":"frottions","lemma":"frotter","pos":"VER"} ,
		{"word":"frottons","word_nosc":"frottons","lemma":"frotter","pos":"VER"} ,
		{"word":"frottèrent","word_nosc":"frotterent","lemma":"frotter","pos":"VER"} ,
		{"word":"frotté","word_nosc":"frotte","lemma":"frotter","pos":"VER"} ,
		{"word":"frottée","word_nosc":"frottee","lemma":"frotter","pos":"VER"} ,
		{"word":"frottées","word_nosc":"frottees","lemma":"frotter","pos":"VER"} ,
		{"word":"frottés","word_nosc":"frottes","lemma":"frotter","pos":"VER"} ,
		{"word":"froufroutait","word_nosc":"froufroutait","lemma":"froufrouter","pos":"VER"} ,
		{"word":"froufroutant","word_nosc":"froufroutant","lemma":"froufrouter","pos":"VER"} ,
		{"word":"froufrouter","word_nosc":"froufrouter","lemma":"froufrouter","pos":"VER"} ,
		{"word":"fructifiaient","word_nosc":"fructifiaient","lemma":"fructifier","pos":"VER"} ,
		{"word":"fructifiant","word_nosc":"fructifiant","lemma":"fructifier","pos":"VER"} ,
		{"word":"fructifier","word_nosc":"fructifier","lemma":"fructifier","pos":"VER"} ,
		{"word":"fructifié","word_nosc":"fructifie","lemma":"fructifier","pos":"VER"} ,
		{"word":"fruiter","word_nosc":"fruiter","lemma":"fruiter","pos":"VER"} ,
		{"word":"fruité","word_nosc":"fruite","lemma":"fruiter","pos":"VER"} ,
		{"word":"fruitée","word_nosc":"fruitee","lemma":"fruiter","pos":"VER"} ,
		{"word":"fruités","word_nosc":"fruites","lemma":"fruiter","pos":"VER"} ,
		{"word":"frusquer","word_nosc":"frusquer","lemma":"frusquer","pos":"VER"} ,
		{"word":"frusquée","word_nosc":"frusquee","lemma":"frusquer","pos":"VER"} ,
		{"word":"frustra","word_nosc":"frustra","lemma":"frustrer","pos":"VER"} ,
		{"word":"frustrait","word_nosc":"frustrait","lemma":"frustrer","pos":"VER"} ,
		{"word":"frustrant","word_nosc":"frustrant","lemma":"frustrer","pos":"VER"} ,
		{"word":"frustre","word_nosc":"frustre","lemma":"frustrer","pos":"VER"} ,
		{"word":"frustrer","word_nosc":"frustrer","lemma":"frustrer","pos":"VER"} ,
		{"word":"frustrât","word_nosc":"frustrat","lemma":"frustrer","pos":"VER"} ,
		{"word":"frustré","word_nosc":"frustre","lemma":"frustrer","pos":"VER"} ,
		{"word":"frustrée","word_nosc":"frustree","lemma":"frustrer","pos":"VER"} ,
		{"word":"frustrées","word_nosc":"frustrees","lemma":"frustrer","pos":"VER"} ,
		{"word":"frustrés","word_nosc":"frustres","lemma":"frustrer","pos":"VER"} ,
		{"word":"frète","word_nosc":"frete","lemma":"fréter","pos":"VER"} ,
		{"word":"frémi","word_nosc":"fremi","lemma":"frémir","pos":"VER"} ,
		{"word":"frémir","word_nosc":"fremir","lemma":"frémir","pos":"VER"} ,
		{"word":"frémirait","word_nosc":"fremirait","lemma":"frémir","pos":"VER"} ,
		{"word":"frémirent","word_nosc":"fremirent","lemma":"frémir","pos":"VER"} ,
		{"word":"frémiront","word_nosc":"fremiront","lemma":"frémir","pos":"VER"} ,
		{"word":"frémis","word_nosc":"fremis","lemma":"frémir","pos":"VER"} ,
		{"word":"frémissaient","word_nosc":"fremissaient","lemma":"frémir","pos":"VER"} ,
		{"word":"frémissais","word_nosc":"fremissais","lemma":"frémir","pos":"VER"} ,
		{"word":"frémissait","word_nosc":"fremissait","lemma":"frémir","pos":"VER"} ,
		{"word":"frémissant","word_nosc":"fremissant","lemma":"frémir","pos":"VER"} ,
		{"word":"frémisse","word_nosc":"fremisse","lemma":"frémir","pos":"VER"} ,
		{"word":"frémissent","word_nosc":"fremissent","lemma":"frémir","pos":"VER"} ,
		{"word":"frémissez","word_nosc":"fremissez","lemma":"frémir","pos":"VER"} ,
		{"word":"frémit","word_nosc":"fremit","lemma":"frémir","pos":"VER"} ,
		{"word":"fréquenta","word_nosc":"frequenta","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentai","word_nosc":"frequentai","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentaient","word_nosc":"frequentaient","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentais","word_nosc":"frequentais","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentait","word_nosc":"frequentait","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentant","word_nosc":"frequentant","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquente","word_nosc":"frequente","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentent","word_nosc":"frequentent","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquenter","word_nosc":"frequenter","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquenterais","word_nosc":"frequenterais","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquenterait","word_nosc":"frequenterait","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquenteras","word_nosc":"frequenteras","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquenterez","word_nosc":"frequenterez","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentes","word_nosc":"frequentes","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentez","word_nosc":"frequentez","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentiez","word_nosc":"frequentiez","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentions","word_nosc":"frequentions","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentons","word_nosc":"frequentons","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentât","word_nosc":"frequentat","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentèrent","word_nosc":"frequenterent","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquenté","word_nosc":"frequente","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentée","word_nosc":"frequentee","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentées","word_nosc":"frequentees","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréquentés","word_nosc":"frequentes","lemma":"fréquenter","pos":"VER"} ,
		{"word":"fréta","word_nosc":"freta","lemma":"fréter","pos":"VER"} ,
		{"word":"frétait","word_nosc":"fretait","lemma":"fréter","pos":"VER"} ,
		{"word":"frétant","word_nosc":"fretant","lemma":"fréter","pos":"VER"} ,
		{"word":"fréter","word_nosc":"freter","lemma":"fréter","pos":"VER"} ,
		{"word":"frétilla","word_nosc":"fretilla","lemma":"frétiller","pos":"VER"} ,
		{"word":"frétillaient","word_nosc":"fretillaient","lemma":"frétiller","pos":"VER"} ,
		{"word":"frétillait","word_nosc":"fretillait","lemma":"frétiller","pos":"VER"} ,
		{"word":"frétillant","word_nosc":"fretillant","lemma":"frétiller","pos":"VER"} ,
		{"word":"frétille","word_nosc":"fretille","lemma":"frétiller","pos":"VER"} ,
		{"word":"frétillent","word_nosc":"fretillent","lemma":"frétiller","pos":"VER"} ,
		{"word":"frétiller","word_nosc":"fretiller","lemma":"frétiller","pos":"VER"} ,
		{"word":"frétillé","word_nosc":"fretille","lemma":"frétiller","pos":"VER"} ,
		{"word":"frétèrent","word_nosc":"freterent","lemma":"fréter","pos":"VER"} ,
		{"word":"frété","word_nosc":"frete","lemma":"fréter","pos":"VER"} ,
		{"word":"frétée","word_nosc":"fretee","lemma":"fréter","pos":"VER"} ,
		{"word":"frôla","word_nosc":"frola","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlai","word_nosc":"frolai","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlaient","word_nosc":"frolaient","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlais","word_nosc":"frolais","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlait","word_nosc":"frolait","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlant","word_nosc":"frolant","lemma":"frôler","pos":"VER"} ,
		{"word":"frôle","word_nosc":"frole","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlent","word_nosc":"frolent","lemma":"frôler","pos":"VER"} ,
		{"word":"frôler","word_nosc":"froler","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlerais","word_nosc":"frolerais","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlerait","word_nosc":"frolerait","lemma":"frôler","pos":"VER"} ,
		{"word":"frôles","word_nosc":"froles","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlez","word_nosc":"frolez","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlions","word_nosc":"frolions","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlons","word_nosc":"frolons","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlât","word_nosc":"frolat","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlèrent","word_nosc":"frolerent","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlé","word_nosc":"frole","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlée","word_nosc":"frolee","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlées","word_nosc":"frolees","lemma":"frôler","pos":"VER"} ,
		{"word":"frôlés","word_nosc":"froles","lemma":"frôler","pos":"VER"} ,
		{"word":"fuguaient","word_nosc":"fuguaient","lemma":"fuguer","pos":"VER"} ,
		{"word":"fuguait","word_nosc":"fuguait","lemma":"fuguer","pos":"VER"} ,
		{"word":"fugue","word_nosc":"fugue","lemma":"fuguer","pos":"VER"} ,
		{"word":"fuguent","word_nosc":"fuguent","lemma":"fuguer","pos":"VER"} ,
		{"word":"fuguer","word_nosc":"fuguer","lemma":"fuguer","pos":"VER"} ,
		{"word":"fuguerais","word_nosc":"fuguerais","lemma":"fuguer","pos":"VER"} ,
		{"word":"fugues","word_nosc":"fugues","lemma":"fuguer","pos":"VER"} ,
		{"word":"fuguèrent","word_nosc":"fuguerent","lemma":"fuguer","pos":"VER"} ,
		{"word":"fugué","word_nosc":"fugue","lemma":"fuguer","pos":"VER"} ,
		{"word":"fui","word_nosc":"fui","lemma":"fuir","pos":"VER"} ,
		{"word":"fuie","word_nosc":"fuie","lemma":"fuir","pos":"VER"} ,
		{"word":"fuient","word_nosc":"fuient","lemma":"fuir","pos":"VER"} ,
		{"word":"fuies","word_nosc":"fuies","lemma":"fuir","pos":"VER"} ,
		{"word":"fuir","word_nosc":"fuir","lemma":"fuir","pos":"VER"} ,
		{"word":"fuira","word_nosc":"fuira","lemma":"fuir","pos":"VER"} ,
		{"word":"fuirai","word_nosc":"fuirai","lemma":"fuir","pos":"VER"} ,
		{"word":"fuiraient","word_nosc":"fuiraient","lemma":"fuir","pos":"VER"} ,
		{"word":"fuirais","word_nosc":"fuirais","lemma":"fuir","pos":"VER"} ,
		{"word":"fuirait","word_nosc":"fuirait","lemma":"fuir","pos":"VER"} ,
		{"word":"fuiras","word_nosc":"fuiras","lemma":"fuir","pos":"VER"} ,
		{"word":"fuirent","word_nosc":"fuirent","lemma":"fuir","pos":"VER"} ,
		{"word":"fuirez","word_nosc":"fuirez","lemma":"fuir","pos":"VER"} ,
		{"word":"fuiriez","word_nosc":"fuiriez","lemma":"fuir","pos":"VER"} ,
		{"word":"fuirions","word_nosc":"fuirions","lemma":"fuir","pos":"VER"} ,
		{"word":"fuirons","word_nosc":"fuirons","lemma":"fuir","pos":"VER"} ,
		{"word":"fuiront","word_nosc":"fuiront","lemma":"fuir","pos":"VER"} ,
		{"word":"fuis","word_nosc":"fuis","lemma":"fuir","pos":"VER"} ,
		{"word":"fuisse","word_nosc":"fuisse","lemma":"fuir","pos":"VER"} ,
		{"word":"fuit","word_nosc":"fuit","lemma":"fuir","pos":"VER"} ,
		{"word":"fuiter","word_nosc":"fuiter","lemma":"fuiter","pos":"VER"} ,
		{"word":"fulgura","word_nosc":"fulgura","lemma":"fulgurer","pos":"VER"} ,
		{"word":"fulguraient","word_nosc":"fulguraient","lemma":"fulgurer","pos":"VER"} ,
		{"word":"fulgurait","word_nosc":"fulgurait","lemma":"fulgurer","pos":"VER"} ,
		{"word":"fulgurant","word_nosc":"fulgurant","lemma":"fulgurer","pos":"VER"} ,
		{"word":"fulgure","word_nosc":"fulgure","lemma":"fulgurer","pos":"VER"} ,
		{"word":"fulgurent","word_nosc":"fulgurent","lemma":"fulgurer","pos":"VER"} ,
		{"word":"fulgurer","word_nosc":"fulgurer","lemma":"fulgurer","pos":"VER"} ,
		{"word":"fulgurèrent","word_nosc":"fulgurerent","lemma":"fulgurer","pos":"VER"} ,
		{"word":"fulguré","word_nosc":"fulgure","lemma":"fulgurer","pos":"VER"} ,
		{"word":"fulmina","word_nosc":"fulmina","lemma":"fulminer","pos":"VER"} ,
		{"word":"fulminaient","word_nosc":"fulminaient","lemma":"fulminer","pos":"VER"} ,
		{"word":"fulminais","word_nosc":"fulminais","lemma":"fulminer","pos":"VER"} ,
		{"word":"fulminait","word_nosc":"fulminait","lemma":"fulminer","pos":"VER"} ,
		{"word":"fulminant","word_nosc":"fulminant","lemma":"fulminer","pos":"VER"} ,
		{"word":"fulmine","word_nosc":"fulmine","lemma":"fulminer","pos":"VER"} ,
		{"word":"fulminent","word_nosc":"fulminent","lemma":"fulminer","pos":"VER"} ,
		{"word":"fulminer","word_nosc":"fulminer","lemma":"fulminer","pos":"VER"} ,
		{"word":"fulminera","word_nosc":"fulminera","lemma":"fulminer","pos":"VER"} ,
		{"word":"fuma","word_nosc":"fuma","lemma":"fumer","pos":"VER"} ,
		{"word":"fumai","word_nosc":"fumai","lemma":"fumer","pos":"VER"} ,
		{"word":"fumaient","word_nosc":"fumaient","lemma":"fumer","pos":"VER"} ,
		{"word":"fumailler","word_nosc":"fumailler","lemma":"fumailler","pos":"VER"} ,
		{"word":"fumaillé","word_nosc":"fumaille","lemma":"fumailler","pos":"VER"} ,
		{"word":"fumais","word_nosc":"fumais","lemma":"fumer","pos":"VER"} ,
		{"word":"fumait","word_nosc":"fumait","lemma":"fumer","pos":"VER"} ,
		{"word":"fumant","word_nosc":"fumant","lemma":"fumer","pos":"VER"} ,
		{"word":"fume","word_nosc":"fume","lemma":"fumer","pos":"VER"} ,
		{"word":"fument","word_nosc":"fument","lemma":"fumer","pos":"VER"} ,
		{"word":"fumer","word_nosc":"fumer","lemma":"fumer","pos":"VER"} ,
		{"word":"fumera","word_nosc":"fumera","lemma":"fumer","pos":"VER"} ,
		{"word":"fumerai","word_nosc":"fumerai","lemma":"fumer","pos":"VER"} ,
		{"word":"fumeraient","word_nosc":"fumeraient","lemma":"fumer","pos":"VER"} ,
		{"word":"fumerais","word_nosc":"fumerais","lemma":"fumer","pos":"VER"} ,
		{"word":"fumerait","word_nosc":"fumerait","lemma":"fumer","pos":"VER"} ,
		{"word":"fumeras","word_nosc":"fumeras","lemma":"fumer","pos":"VER"} ,
		{"word":"fumerez","word_nosc":"fumerez","lemma":"fumer","pos":"VER"} ,
		{"word":"fumeronne","word_nosc":"fumeronne","lemma":"fumeronner","pos":"VER"} ,
		{"word":"fumerons","word_nosc":"fumerons","lemma":"fumer","pos":"VER"} ,
		{"word":"fumes","word_nosc":"fumes","lemma":"fumer","pos":"VER"} ,
		{"word":"fumez","word_nosc":"fumez","lemma":"fumer","pos":"VER"} ,
		{"word":"fumiez","word_nosc":"fumiez","lemma":"fumer","pos":"VER"} ,
		{"word":"fumiger","word_nosc":"fumiger","lemma":"fumiger","pos":"VER"} ,
		{"word":"fumigé","word_nosc":"fumige","lemma":"fumiger","pos":"VER"} ,
		{"word":"fumions","word_nosc":"fumions","lemma":"fumer","pos":"VER"} ,
		{"word":"fumons","word_nosc":"fumons","lemma":"fumer","pos":"VER"} ,
		{"word":"fumâmes","word_nosc":"fumames","lemma":"fumer","pos":"VER"} ,
		{"word":"fumât","word_nosc":"fumat","lemma":"fumer","pos":"VER"} ,
		{"word":"fumèrent","word_nosc":"fumerent","lemma":"fumer","pos":"VER"} ,
		{"word":"fumé","word_nosc":"fume","lemma":"fumer","pos":"VER"} ,
		{"word":"fumée","word_nosc":"fumee","lemma":"fumer","pos":"VER"} ,
		{"word":"fumées","word_nosc":"fumees","lemma":"fumer","pos":"VER"} ,
		{"word":"fumés","word_nosc":"fumes","lemma":"fumer","pos":"VER"} ,
		{"word":"furent","word_nosc":"furent","lemma":"être","pos":"VER"} ,
		{"word":"fureta","word_nosc":"fureta","lemma":"fureter","pos":"VER"} ,
		{"word":"furetai","word_nosc":"furetai","lemma":"fureter","pos":"VER"} ,
		{"word":"furetaient","word_nosc":"furetaient","lemma":"fureter","pos":"VER"} ,
		{"word":"furetait","word_nosc":"furetait","lemma":"fureter","pos":"VER"} ,
		{"word":"furetant","word_nosc":"furetant","lemma":"fureter","pos":"VER"} ,
		{"word":"fureter","word_nosc":"fureter","lemma":"fureter","pos":"VER"} ,
		{"word":"furetez","word_nosc":"furetez","lemma":"fureter","pos":"VER"} ,
		{"word":"fureté","word_nosc":"furete","lemma":"fureter","pos":"VER"} ,
		{"word":"furète","word_nosc":"furete","lemma":"fureter","pos":"VER"} ,
		{"word":"furètent","word_nosc":"furetent","lemma":"fureter","pos":"VER"} ,
		{"word":"furèterais","word_nosc":"fureterais","lemma":"fureter","pos":"VER"} ,
		{"word":"fus","word_nosc":"fus","lemma":"être","pos":"VER"} ,
		{"word":"fusa","word_nosc":"fusa","lemma":"fuser","pos":"VER"} ,
		{"word":"fusaient","word_nosc":"fusaient","lemma":"fuser","pos":"VER"} ,
		{"word":"fusait","word_nosc":"fusait","lemma":"fuser","pos":"VER"} ,
		{"word":"fusant","word_nosc":"fusant","lemma":"fuser","pos":"VER"} ,
		{"word":"fuse","word_nosc":"fuse","lemma":"fuser","pos":"VER"} ,
		{"word":"fuselé","word_nosc":"fusele","lemma":"fuseler","pos":"VER"} ,
		{"word":"fuselée","word_nosc":"fuselee","lemma":"fuseler","pos":"VER"} ,
		{"word":"fuselées","word_nosc":"fuselees","lemma":"fuseler","pos":"VER"} ,
		{"word":"fusent","word_nosc":"fusent","lemma":"fuser","pos":"VER"} ,
		{"word":"fuser","word_nosc":"fuser","lemma":"fuser","pos":"VER"} ,
		{"word":"fusilla","word_nosc":"fusilla","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusillaient","word_nosc":"fusillaient","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusillait","word_nosc":"fusillait","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusillant","word_nosc":"fusillant","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusille","word_nosc":"fusille","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusillent","word_nosc":"fusillent","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusiller","word_nosc":"fusiller","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusillera","word_nosc":"fusillera","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusilleraient","word_nosc":"fusilleraient","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusillerait","word_nosc":"fusillerait","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusillerons","word_nosc":"fusillerons","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusilleront","word_nosc":"fusilleront","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusillez","word_nosc":"fusillez","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusillions","word_nosc":"fusillions","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusillons","word_nosc":"fusillons","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusillé","word_nosc":"fusille","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusillée","word_nosc":"fusillee","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusillées","word_nosc":"fusillees","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusillés","word_nosc":"fusilles","lemma":"fusiller","pos":"VER"} ,
		{"word":"fusionnaient","word_nosc":"fusionnaient","lemma":"fusionner","pos":"VER"} ,
		{"word":"fusionnant","word_nosc":"fusionnant","lemma":"fusionner","pos":"VER"} ,
		{"word":"fusionne","word_nosc":"fusionne","lemma":"fusionner","pos":"VER"} ,
		{"word":"fusionnent","word_nosc":"fusionnent","lemma":"fusionner","pos":"VER"} ,
		{"word":"fusionner","word_nosc":"fusionner","lemma":"fusionner","pos":"VER"} ,
		{"word":"fusionneraient","word_nosc":"fusionneraient","lemma":"fusionner","pos":"VER"} ,
		{"word":"fusionnerait","word_nosc":"fusionnerait","lemma":"fusionner","pos":"VER"} ,
		{"word":"fusionneront","word_nosc":"fusionneront","lemma":"fusionner","pos":"VER"} ,
		{"word":"fusionnons","word_nosc":"fusionnons","lemma":"fusionner","pos":"VER"} ,
		{"word":"fusionné","word_nosc":"fusionne","lemma":"fusionner","pos":"VER"} ,
		{"word":"fusionnés","word_nosc":"fusionnes","lemma":"fusionner","pos":"VER"} ,
		{"word":"fusse","word_nosc":"fusse","lemma":"être","pos":"VER"} ,
		{"word":"fussent","word_nosc":"fussent","lemma":"être","pos":"VER"} ,
		{"word":"fusses","word_nosc":"fusses","lemma":"être","pos":"VER"} ,
		{"word":"fussiez","word_nosc":"fussiez","lemma":"être","pos":"VER"} ,
		{"word":"fussions","word_nosc":"fussions","lemma":"être","pos":"VER"} ,
		{"word":"fustige","word_nosc":"fustige","lemma":"fustiger","pos":"VER"} ,
		{"word":"fustigea","word_nosc":"fustigea","lemma":"fustiger","pos":"VER"} ,
		{"word":"fustigeaient","word_nosc":"fustigeaient","lemma":"fustiger","pos":"VER"} ,
		{"word":"fustigeais","word_nosc":"fustigeais","lemma":"fustiger","pos":"VER"} ,
		{"word":"fustigeait","word_nosc":"fustigeait","lemma":"fustiger","pos":"VER"} ,
		{"word":"fustigeant","word_nosc":"fustigeant","lemma":"fustiger","pos":"VER"} ,
		{"word":"fustiger","word_nosc":"fustiger","lemma":"fustiger","pos":"VER"} ,
		{"word":"fustigez","word_nosc":"fustigez","lemma":"fustiger","pos":"VER"} ,
		{"word":"fustigé","word_nosc":"fustige","lemma":"fustiger","pos":"VER"} ,
		{"word":"fustigée","word_nosc":"fustigee","lemma":"fustiger","pos":"VER"} ,
		{"word":"fustigés","word_nosc":"fustiges","lemma":"fustiger","pos":"VER"} ,
		{"word":"fusèrent","word_nosc":"fuserent","lemma":"fuser","pos":"VER"} ,
		{"word":"fusé","word_nosc":"fuse","lemma":"fuser","pos":"VER"} ,
		{"word":"fusée","word_nosc":"fusee","lemma":"fuser","pos":"VER"} ,
		{"word":"fusées","word_nosc":"fusees","lemma":"fuser","pos":"VER"} ,
		{"word":"fut","word_nosc":"fut","lemma":"être","pos":"VER"} ,
		{"word":"fuyaient","word_nosc":"fuyaient","lemma":"fuir","pos":"VER"} ,
		{"word":"fuyais","word_nosc":"fuyais","lemma":"fuir","pos":"VER"} ,
		{"word":"fuyait","word_nosc":"fuyait","lemma":"fuir","pos":"VER"} ,
		{"word":"fuyant","word_nosc":"fuyant","lemma":"fuir","pos":"VER"} ,
		{"word":"fuyez","word_nosc":"fuyez","lemma":"fuir","pos":"VER"} ,
		{"word":"fuyiez","word_nosc":"fuyiez","lemma":"fuir","pos":"VER"} ,
		{"word":"fuyions","word_nosc":"fuyions","lemma":"fuir","pos":"VER"} ,
		{"word":"fuyons","word_nosc":"fuyons","lemma":"fuir","pos":"VER"} ,
		{"word":"fâcha","word_nosc":"facha","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâchai","word_nosc":"fachai","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâchaient","word_nosc":"fachaient","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâchais","word_nosc":"fachais","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâchait","word_nosc":"fachait","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâche","word_nosc":"fache","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâchent","word_nosc":"fachent","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâcher","word_nosc":"facher","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâchera","word_nosc":"fachera","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâcherai","word_nosc":"facherai","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâcherais","word_nosc":"facherais","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâcherait","word_nosc":"facherait","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâcheras","word_nosc":"facheras","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâcheront","word_nosc":"facheront","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâches","word_nosc":"faches","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâchez","word_nosc":"fachez","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâchiez","word_nosc":"fachiez","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâchions","word_nosc":"fachions","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâchons","word_nosc":"fachons","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâchèrent","word_nosc":"facherent","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâché","word_nosc":"fache","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâchée","word_nosc":"fachee","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâchées","word_nosc":"fachees","lemma":"fâcher","pos":"VER"} ,
		{"word":"fâchés","word_nosc":"faches","lemma":"fâcher","pos":"VER"} ,
		{"word":"fécondais","word_nosc":"fecondais","lemma":"féconder","pos":"VER"} ,
		{"word":"fécondait","word_nosc":"fecondait","lemma":"féconder","pos":"VER"} ,
		{"word":"fécondant","word_nosc":"fecondant","lemma":"féconder","pos":"VER"} ,
		{"word":"féconde","word_nosc":"feconde","lemma":"féconder","pos":"VER"} ,
		{"word":"fécondent","word_nosc":"fecondent","lemma":"féconder","pos":"VER"} ,
		{"word":"féconder","word_nosc":"feconder","lemma":"féconder","pos":"VER"} ,
		{"word":"féconderai","word_nosc":"feconderai","lemma":"féconder","pos":"VER"} ,
		{"word":"féconderait","word_nosc":"feconderait","lemma":"féconder","pos":"VER"} ,
		{"word":"fécondes","word_nosc":"fecondes","lemma":"féconder","pos":"VER"} ,
		{"word":"fécondez","word_nosc":"fecondez","lemma":"féconder","pos":"VER"} ,
		{"word":"fécondé","word_nosc":"feconde","lemma":"féconder","pos":"VER"} ,
		{"word":"fécondée","word_nosc":"fecondee","lemma":"féconder","pos":"VER"} ,
		{"word":"fécondées","word_nosc":"fecondees","lemma":"féconder","pos":"VER"} ,
		{"word":"fécondés","word_nosc":"fecondes","lemma":"féconder","pos":"VER"} ,
		{"word":"fédérer","word_nosc":"federer","lemma":"fédérer","pos":"VER"} ,
		{"word":"fédéré","word_nosc":"federe","lemma":"fédérer","pos":"VER"} ,
		{"word":"fédérée","word_nosc":"federee","lemma":"fédérer","pos":"VER"} ,
		{"word":"fédérés","word_nosc":"federes","lemma":"fédérer","pos":"VER"} ,
		{"word":"félicita","word_nosc":"felicita","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicitai","word_nosc":"felicitai","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicitaient","word_nosc":"felicitaient","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicitais","word_nosc":"felicitais","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicitait","word_nosc":"felicitait","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicitant","word_nosc":"felicitant","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicite","word_nosc":"felicite","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicitent","word_nosc":"felicitent","lemma":"féliciter","pos":"VER"} ,
		{"word":"féliciter","word_nosc":"feliciter","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicitera","word_nosc":"felicitera","lemma":"féliciter","pos":"VER"} ,
		{"word":"féliciterai","word_nosc":"feliciterai","lemma":"féliciter","pos":"VER"} ,
		{"word":"féliciteraient","word_nosc":"feliciteraient","lemma":"féliciter","pos":"VER"} ,
		{"word":"féliciterais","word_nosc":"feliciterais","lemma":"féliciter","pos":"VER"} ,
		{"word":"féliciterait","word_nosc":"feliciterait","lemma":"féliciter","pos":"VER"} ,
		{"word":"féliciteras","word_nosc":"feliciteras","lemma":"féliciter","pos":"VER"} ,
		{"word":"féliciteriez","word_nosc":"feliciteriez","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicitez","word_nosc":"felicitez","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicitiez","word_nosc":"felicitiez","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicitions","word_nosc":"felicitions","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicitons","word_nosc":"felicitons","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicitâmes","word_nosc":"felicitames","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicitèrent","word_nosc":"feliciterent","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicité","word_nosc":"felicite","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicitée","word_nosc":"felicitee","lemma":"féliciter","pos":"VER"} ,
		{"word":"félicités","word_nosc":"felicites","lemma":"féliciter","pos":"VER"} ,
		{"word":"féminisaient","word_nosc":"feminisaient","lemma":"féminiser","pos":"VER"} ,
		{"word":"féminiser","word_nosc":"feminiser","lemma":"féminiser","pos":"VER"} ,
		{"word":"féminisé","word_nosc":"feminise","lemma":"féminiser","pos":"VER"} ,
		{"word":"férir","word_nosc":"ferir","lemma":"férir","pos":"VER"} ,
		{"word":"fêla","word_nosc":"fela","lemma":"fêler","pos":"VER"} ,
		{"word":"fêlant","word_nosc":"felant","lemma":"fêler","pos":"VER"} ,
		{"word":"fêle","word_nosc":"fele","lemma":"fêler","pos":"VER"} ,
		{"word":"fêler","word_nosc":"feler","lemma":"fêler","pos":"VER"} ,
		{"word":"fêles","word_nosc":"feles","lemma":"fêler","pos":"VER"} ,
		{"word":"fêlé","word_nosc":"fele","lemma":"fêler","pos":"VER"} ,
		{"word":"fêlée","word_nosc":"felee","lemma":"fêler","pos":"VER"} ,
		{"word":"fêlées","word_nosc":"felees","lemma":"fêler","pos":"VER"} ,
		{"word":"fêlés","word_nosc":"feles","lemma":"fêler","pos":"VER"} ,
		{"word":"fêta","word_nosc":"feta","lemma":"fêter","pos":"VER"} ,
		{"word":"fêtaient","word_nosc":"fetaient","lemma":"fêter","pos":"VER"} ,
		{"word":"fêtait","word_nosc":"fetait","lemma":"fêter","pos":"VER"} ,
		{"word":"fêtant","word_nosc":"fetant","lemma":"fêter","pos":"VER"} ,
		{"word":"fête","word_nosc":"fete","lemma":"fêter","pos":"VER"} ,
		{"word":"fêtent","word_nosc":"fetent","lemma":"fêter","pos":"VER"} ,
		{"word":"fêter","word_nosc":"feter","lemma":"fêter","pos":"VER"} ,
		{"word":"fêtera","word_nosc":"fetera","lemma":"fêter","pos":"VER"} ,
		{"word":"fêterait","word_nosc":"feterait","lemma":"fêter","pos":"VER"} ,
		{"word":"fêterons","word_nosc":"feterons","lemma":"fêter","pos":"VER"} ,
		{"word":"fêtes","word_nosc":"fetes","lemma":"fêter","pos":"VER"} ,
		{"word":"fêtez","word_nosc":"fetez","lemma":"fêter","pos":"VER"} ,
		{"word":"fêtions","word_nosc":"fetions","lemma":"fêter","pos":"VER"} ,
		{"word":"fêtons","word_nosc":"fetons","lemma":"fêter","pos":"VER"} ,
		{"word":"fêtâmes","word_nosc":"fetames","lemma":"fêter","pos":"VER"} ,
		{"word":"fêtèrent","word_nosc":"feterent","lemma":"fêter","pos":"VER"} ,
		{"word":"fêté","word_nosc":"fete","lemma":"fêter","pos":"VER"} ,
		{"word":"fêtée","word_nosc":"fetee","lemma":"fêter","pos":"VER"} ,
		{"word":"fêtés","word_nosc":"fetes","lemma":"fêter","pos":"VER"} ,
		{"word":"fîmes","word_nosc":"fimes","lemma":"faire","pos":"VER"} ,
		{"word":"fît","word_nosc":"fit","lemma":"faire","pos":"VER"} ,
		{"word":"fîtes","word_nosc":"fites","lemma":"faire","pos":"VER"} ,
		{"word":"fûmes","word_nosc":"fumes","lemma":"être","pos":"VER"} ,
		{"word":"fût","word_nosc":"fut","lemma":"être","pos":"VER"} ,
		{"word":"fûtes","word_nosc":"futes","lemma":"être","pos":"VER"} ,
		{"word":"gabe","word_nosc":"gabe","lemma":"gaber","pos":"VER"} ,
		{"word":"gaffa","word_nosc":"gaffa","lemma":"gaffer","pos":"VER"} ,
		{"word":"gaffaient","word_nosc":"gaffaient","lemma":"gaffer","pos":"VER"} ,
		{"word":"gaffais","word_nosc":"gaffais","lemma":"gaffer","pos":"VER"} ,
		{"word":"gaffait","word_nosc":"gaffait","lemma":"gaffer","pos":"VER"} ,
		{"word":"gaffant","word_nosc":"gaffant","lemma":"gaffer","pos":"VER"} ,
		{"word":"gaffe","word_nosc":"gaffe","lemma":"gaffer","pos":"VER"} ,
		{"word":"gaffent","word_nosc":"gaffent","lemma":"gaffer","pos":"VER"} ,
		{"word":"gaffer","word_nosc":"gaffer","lemma":"gaffer","pos":"VER"} ,
		{"word":"gafferai","word_nosc":"gafferai","lemma":"gaffer","pos":"VER"} ,
		{"word":"gaffes","word_nosc":"gaffes","lemma":"gaffer","pos":"VER"} ,
		{"word":"gaffez","word_nosc":"gaffez","lemma":"gaffer","pos":"VER"} ,
		{"word":"gaffé","word_nosc":"gaffe","lemma":"gaffer","pos":"VER"} ,
		{"word":"gaffée","word_nosc":"gaffee","lemma":"gaffer","pos":"VER"} ,
		{"word":"gaffés","word_nosc":"gaffes","lemma":"gaffer","pos":"VER"} ,
		{"word":"gage","word_nosc":"gage","lemma":"gager","pos":"VER"} ,
		{"word":"gageaient","word_nosc":"gageaient","lemma":"gager","pos":"VER"} ,
		{"word":"gageons","word_nosc":"gageons","lemma":"gager","pos":"VER"} ,
		{"word":"gager","word_nosc":"gager","lemma":"gager","pos":"VER"} ,
		{"word":"gagerai","word_nosc":"gagerai","lemma":"gager","pos":"VER"} ,
		{"word":"gagerais","word_nosc":"gagerais","lemma":"gager","pos":"VER"} ,
		{"word":"gages","word_nosc":"gages","lemma":"gager","pos":"VER"} ,
		{"word":"gagez","word_nosc":"gagez","lemma":"gager","pos":"VER"} ,
		{"word":"gagna","word_nosc":"gagna","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnai","word_nosc":"gagnai","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnaient","word_nosc":"gagnaient","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnais","word_nosc":"gagnais","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnait","word_nosc":"gagnait","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnant","word_nosc":"gagnant","lemma":"gagner","pos":"VER"} ,
		{"word":"gagne","word_nosc":"gagne","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnent","word_nosc":"gagnent","lemma":"gagner","pos":"VER"} ,
		{"word":"gagner","word_nosc":"gagner","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnera","word_nosc":"gagnera","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnerai","word_nosc":"gagnerai","lemma":"gagner","pos":"VER"} ,
		{"word":"gagneraient","word_nosc":"gagneraient","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnerais","word_nosc":"gagnerais","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnerait","word_nosc":"gagnerait","lemma":"gagner","pos":"VER"} ,
		{"word":"gagneras","word_nosc":"gagneras","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnerez","word_nosc":"gagnerez","lemma":"gagner","pos":"VER"} ,
		{"word":"gagneriez","word_nosc":"gagneriez","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnerions","word_nosc":"gagnerions","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnerons","word_nosc":"gagnerons","lemma":"gagner","pos":"VER"} ,
		{"word":"gagneront","word_nosc":"gagneront","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnes","word_nosc":"gagnes","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnez","word_nosc":"gagnez","lemma":"gagner","pos":"VER"} ,
		{"word":"gagniez","word_nosc":"gagniez","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnions","word_nosc":"gagnions","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnons","word_nosc":"gagnons","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnâmes","word_nosc":"gagnames","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnât","word_nosc":"gagnat","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnèrent","word_nosc":"gagnerent","lemma":"gagner","pos":"VER"} ,
		{"word":"gagné","word_nosc":"gagne","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnée","word_nosc":"gagnee","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnées","word_nosc":"gagnees","lemma":"gagner","pos":"VER"} ,
		{"word":"gagnés","word_nosc":"gagnes","lemma":"gagner","pos":"VER"} ,
		{"word":"gagé","word_nosc":"gage","lemma":"gager","pos":"VER"} ,
		{"word":"gagée","word_nosc":"gagee","lemma":"gager","pos":"VER"} ,
		{"word":"gainaient","word_nosc":"gainaient","lemma":"gainer","pos":"VER"} ,
		{"word":"gainait","word_nosc":"gainait","lemma":"gainer","pos":"VER"} ,
		{"word":"gainent","word_nosc":"gainent","lemma":"gainer","pos":"VER"} ,
		{"word":"gainer","word_nosc":"gainer","lemma":"gainer","pos":"VER"} ,
		{"word":"gainé","word_nosc":"gaine","lemma":"gainer","pos":"VER"} ,
		{"word":"gainée","word_nosc":"gainee","lemma":"gainer","pos":"VER"} ,
		{"word":"gainées","word_nosc":"gainees","lemma":"gainer","pos":"VER"} ,
		{"word":"gainés","word_nosc":"gaines","lemma":"gainer","pos":"VER"} ,
		{"word":"galantisait","word_nosc":"galantisait","lemma":"galantiser","pos":"VER"} ,
		{"word":"galantisé","word_nosc":"galantise","lemma":"galantiser","pos":"VER"} ,
		{"word":"galbait","word_nosc":"galbait","lemma":"galber","pos":"VER"} ,
		{"word":"galber","word_nosc":"galber","lemma":"galber","pos":"VER"} ,
		{"word":"galbé","word_nosc":"galbe","lemma":"galber","pos":"VER"} ,
		{"word":"galbée","word_nosc":"galbee","lemma":"galber","pos":"VER"} ,
		{"word":"galbées","word_nosc":"galbees","lemma":"galber","pos":"VER"} ,
		{"word":"galbés","word_nosc":"galbes","lemma":"galber","pos":"VER"} ,
		{"word":"gallant","word_nosc":"gallant","lemma":"galler","pos":"VER"} ,
		{"word":"galoché","word_nosc":"galoche","lemma":"galocher","pos":"VER"} ,
		{"word":"galochés","word_nosc":"galoches","lemma":"galocher","pos":"VER"} ,
		{"word":"galonnait","word_nosc":"galonnait","lemma":"galonner","pos":"VER"} ,
		{"word":"galonne","word_nosc":"galonne","lemma":"galonner","pos":"VER"} ,
		{"word":"galonné","word_nosc":"galonne","lemma":"galonner","pos":"VER"} ,
		{"word":"galonnée","word_nosc":"galonnee","lemma":"galonner","pos":"VER"} ,
		{"word":"galonnées","word_nosc":"galonnees","lemma":"galonner","pos":"VER"} ,
		{"word":"galonnés","word_nosc":"galonnes","lemma":"galonner","pos":"VER"} ,
		{"word":"galopa","word_nosc":"galopa","lemma":"galoper","pos":"VER"} ,
		{"word":"galopaient","word_nosc":"galopaient","lemma":"galoper","pos":"VER"} ,
		{"word":"galopais","word_nosc":"galopais","lemma":"galoper","pos":"VER"} ,
		{"word":"galopait","word_nosc":"galopait","lemma":"galoper","pos":"VER"} ,
		{"word":"galopant","word_nosc":"galopant","lemma":"galoper","pos":"VER"} ,
		{"word":"galope","word_nosc":"galope","lemma":"galoper","pos":"VER"} ,
		{"word":"galopent","word_nosc":"galopent","lemma":"galoper","pos":"VER"} ,
		{"word":"galoper","word_nosc":"galoper","lemma":"galoper","pos":"VER"} ,
		{"word":"galopera","word_nosc":"galopera","lemma":"galoper","pos":"VER"} ,
		{"word":"galoperaient","word_nosc":"galoperaient","lemma":"galoper","pos":"VER"} ,
		{"word":"galoperez","word_nosc":"galoperez","lemma":"galoper","pos":"VER"} ,
		{"word":"galopes","word_nosc":"galopes","lemma":"galoper","pos":"VER"} ,
		{"word":"galopez","word_nosc":"galopez","lemma":"galoper","pos":"VER"} ,
		{"word":"galopiner","word_nosc":"galopiner","lemma":"galopiner","pos":"VER"} ,
		{"word":"galopons","word_nosc":"galopons","lemma":"galoper","pos":"VER"} ,
		{"word":"galopèrent","word_nosc":"galoperent","lemma":"galoper","pos":"VER"} ,
		{"word":"galopé","word_nosc":"galope","lemma":"galoper","pos":"VER"} ,
		{"word":"galvanisa","word_nosc":"galvanisa","lemma":"galvaniser","pos":"VER"} ,
		{"word":"galvanisait","word_nosc":"galvanisait","lemma":"galvaniser","pos":"VER"} ,
		{"word":"galvanisant","word_nosc":"galvanisant","lemma":"galvaniser","pos":"VER"} ,
		{"word":"galvanise","word_nosc":"galvanise","lemma":"galvaniser","pos":"VER"} ,
		{"word":"galvaniser","word_nosc":"galvaniser","lemma":"galvaniser","pos":"VER"} ,
		{"word":"galvanisé","word_nosc":"galvanise","lemma":"galvaniser","pos":"VER"} ,
		{"word":"galvanisée","word_nosc":"galvanisee","lemma":"galvaniser","pos":"VER"} ,
		{"word":"galvanisées","word_nosc":"galvanisees","lemma":"galvaniser","pos":"VER"} ,
		{"word":"galvanisés","word_nosc":"galvanises","lemma":"galvaniser","pos":"VER"} ,
		{"word":"galvaudait","word_nosc":"galvaudait","lemma":"galvauder","pos":"VER"} ,
		{"word":"galvaudant","word_nosc":"galvaudant","lemma":"galvauder","pos":"VER"} ,
		{"word":"galvaude","word_nosc":"galvaude","lemma":"galvauder","pos":"VER"} ,
		{"word":"galvaudent","word_nosc":"galvaudent","lemma":"galvauder","pos":"VER"} ,
		{"word":"galvauder","word_nosc":"galvauder","lemma":"galvauder","pos":"VER"} ,
		{"word":"galvaudez","word_nosc":"galvaudez","lemma":"galvauder","pos":"VER"} ,
		{"word":"galvaudiez","word_nosc":"galvaudiez","lemma":"galvauder","pos":"VER"} ,
		{"word":"galvaudé","word_nosc":"galvaude","lemma":"galvauder","pos":"VER"} ,
		{"word":"galvaudée","word_nosc":"galvaudee","lemma":"galvauder","pos":"VER"} ,
		{"word":"galère","word_nosc":"galere","lemma":"galérer","pos":"VER"} ,
		{"word":"galèrent","word_nosc":"galerent","lemma":"galérer","pos":"VER"} ,
		{"word":"galères","word_nosc":"galeres","lemma":"galérer","pos":"VER"} ,
		{"word":"galérais","word_nosc":"galerais","lemma":"galérer","pos":"VER"} ,
		{"word":"galérait","word_nosc":"galerait","lemma":"galérer","pos":"VER"} ,
		{"word":"galérer","word_nosc":"galerer","lemma":"galérer","pos":"VER"} ,
		{"word":"galéré","word_nosc":"galere","lemma":"galérer","pos":"VER"} ,
		{"word":"gamahuche","word_nosc":"gamahuche","lemma":"gamahucher","pos":"VER"} ,
		{"word":"gambada","word_nosc":"gambada","lemma":"gambader","pos":"VER"} ,
		{"word":"gambadaient","word_nosc":"gambadaient","lemma":"gambader","pos":"VER"} ,
		{"word":"gambadais","word_nosc":"gambadais","lemma":"gambader","pos":"VER"} ,
		{"word":"gambadait","word_nosc":"gambadait","lemma":"gambader","pos":"VER"} ,
		{"word":"gambadant","word_nosc":"gambadant","lemma":"gambader","pos":"VER"} ,
		{"word":"gambade","word_nosc":"gambade","lemma":"gambader","pos":"VER"} ,
		{"word":"gambadent","word_nosc":"gambadent","lemma":"gambader","pos":"VER"} ,
		{"word":"gambader","word_nosc":"gambader","lemma":"gambader","pos":"VER"} ,
		{"word":"gambades","word_nosc":"gambades","lemma":"gambader","pos":"VER"} ,
		{"word":"gambadez","word_nosc":"gambadez","lemma":"gambader","pos":"VER"} ,
		{"word":"gambadèrent","word_nosc":"gambaderent","lemma":"gambader","pos":"VER"} ,
		{"word":"gamberge","word_nosc":"gamberge","lemma":"gamberger","pos":"VER"} ,
		{"word":"gambergea","word_nosc":"gambergea","lemma":"gamberger","pos":"VER"} ,
		{"word":"gambergeaient","word_nosc":"gambergeaient","lemma":"gamberger","pos":"VER"} ,
		{"word":"gambergeailler","word_nosc":"gambergeailler","lemma":"gambergeailler","pos":"VER"} ,
		{"word":"gambergeais","word_nosc":"gambergeais","lemma":"gamberger","pos":"VER"} ,
		{"word":"gambergeait","word_nosc":"gambergeait","lemma":"gamberger","pos":"VER"} ,
		{"word":"gambergeant","word_nosc":"gambergeant","lemma":"gamberger","pos":"VER"} ,
		{"word":"gambergent","word_nosc":"gambergent","lemma":"gamberger","pos":"VER"} ,
		{"word":"gambergeons","word_nosc":"gambergeons","lemma":"gamberger","pos":"VER"} ,
		{"word":"gamberger","word_nosc":"gamberger","lemma":"gamberger","pos":"VER"} ,
		{"word":"gamberges","word_nosc":"gamberges","lemma":"gamberger","pos":"VER"} ,
		{"word":"gambergez","word_nosc":"gambergez","lemma":"gamberger","pos":"VER"} ,
		{"word":"gambergé","word_nosc":"gamberge","lemma":"gamberger","pos":"VER"} ,
		{"word":"gambillait","word_nosc":"gambillait","lemma":"gambiller","pos":"VER"} ,
		{"word":"gambillant","word_nosc":"gambillant","lemma":"gambiller","pos":"VER"} ,
		{"word":"gambille","word_nosc":"gambille","lemma":"gambiller","pos":"VER"} ,
		{"word":"gambiller","word_nosc":"gambiller","lemma":"gambiller","pos":"VER"} ,
		{"word":"gambilles","word_nosc":"gambilles","lemma":"gambiller","pos":"VER"} ,
		{"word":"gambillé","word_nosc":"gambille","lemma":"gambiller","pos":"VER"} ,
		{"word":"gaminas","word_nosc":"gaminas","lemma":"gaminer","pos":"VER"} ,
		{"word":"gangrena","word_nosc":"gangrena","lemma":"gangrener","pos":"VER"} ,
		{"word":"gangrener","word_nosc":"gangrener","lemma":"gangrener","pos":"VER"} ,
		{"word":"gangrené","word_nosc":"gangrene","lemma":"gangrener","pos":"VER"} ,
		{"word":"gangrenée","word_nosc":"gangrenee","lemma":"gangrener","pos":"VER"} ,
		{"word":"gangrenées","word_nosc":"gangrenees","lemma":"gangrener","pos":"VER"} ,
		{"word":"gangrenés","word_nosc":"gangrenes","lemma":"gangrener","pos":"VER"} ,
		{"word":"gangrène","word_nosc":"gangrene","lemma":"gangrener","pos":"VER"} ,
		{"word":"gansé","word_nosc":"ganse","lemma":"ganser","pos":"VER"} ,
		{"word":"gansée","word_nosc":"gansee","lemma":"ganser","pos":"VER"} ,
		{"word":"gantait","word_nosc":"gantait","lemma":"ganter","pos":"VER"} ,
		{"word":"gantant","word_nosc":"gantant","lemma":"ganter","pos":"VER"} ,
		{"word":"gante","word_nosc":"gante","lemma":"ganter","pos":"VER"} ,
		{"word":"ganté","word_nosc":"gante","lemma":"ganter","pos":"VER"} ,
		{"word":"gantée","word_nosc":"gantee","lemma":"ganter","pos":"VER"} ,
		{"word":"gantées","word_nosc":"gantees","lemma":"ganter","pos":"VER"} ,
		{"word":"gantés","word_nosc":"gantes","lemma":"ganter","pos":"VER"} ,
		{"word":"gara","word_nosc":"gara","lemma":"garer","pos":"VER"} ,
		{"word":"garaient","word_nosc":"garaient","lemma":"garer","pos":"VER"} ,
		{"word":"garais","word_nosc":"garais","lemma":"garer","pos":"VER"} ,
		{"word":"garait","word_nosc":"garait","lemma":"garer","pos":"VER"} ,
		{"word":"garant","word_nosc":"garant","lemma":"garer","pos":"VER"} ,
		{"word":"garanti","word_nosc":"garanti","lemma":"garantir","pos":"VER"} ,
		{"word":"garantie","word_nosc":"garantie","lemma":"garantir","pos":"VER"} ,
		{"word":"garanties","word_nosc":"garanties","lemma":"garantir","pos":"VER"} ,
		{"word":"garantir","word_nosc":"garantir","lemma":"garantir","pos":"VER"} ,
		{"word":"garantira","word_nosc":"garantira","lemma":"garantir","pos":"VER"} ,
		{"word":"garantirais","word_nosc":"garantirais","lemma":"garantir","pos":"VER"} ,
		{"word":"garantirait","word_nosc":"garantirait","lemma":"garantir","pos":"VER"} ,
		{"word":"garantiriez","word_nosc":"garantiriez","lemma":"garantir","pos":"VER"} ,
		{"word":"garantirons","word_nosc":"garantirons","lemma":"garantir","pos":"VER"} ,
		{"word":"garantiront","word_nosc":"garantiront","lemma":"garantir","pos":"VER"} ,
		{"word":"garantis","word_nosc":"garantis","lemma":"garantir","pos":"VER"} ,
		{"word":"garantissaient","word_nosc":"garantissaient","lemma":"garantir","pos":"VER"} ,
		{"word":"garantissais","word_nosc":"garantissais","lemma":"garantir","pos":"VER"} ,
		{"word":"garantissait","word_nosc":"garantissait","lemma":"garantir","pos":"VER"} ,
		{"word":"garantissant","word_nosc":"garantissant","lemma":"garantir","pos":"VER"} ,
		{"word":"garantisse","word_nosc":"garantisse","lemma":"garantir","pos":"VER"} ,
		{"word":"garantissent","word_nosc":"garantissent","lemma":"garantir","pos":"VER"} ,
		{"word":"garantissez","word_nosc":"garantissez","lemma":"garantir","pos":"VER"} ,
		{"word":"garantissons","word_nosc":"garantissons","lemma":"garantir","pos":"VER"} ,
		{"word":"garantit","word_nosc":"garantit","lemma":"garantir","pos":"VER"} ,
		{"word":"garantît","word_nosc":"garantit","lemma":"garantir","pos":"VER"} ,
		{"word":"garda","word_nosc":"garda","lemma":"garder","pos":"VER"} ,
		{"word":"gardai","word_nosc":"gardai","lemma":"garder","pos":"VER"} ,
		{"word":"gardaient","word_nosc":"gardaient","lemma":"garder","pos":"VER"} ,
		{"word":"gardais","word_nosc":"gardais","lemma":"garder","pos":"VER"} ,
		{"word":"gardait","word_nosc":"gardait","lemma":"garder","pos":"VER"} ,
		{"word":"gardant","word_nosc":"gardant","lemma":"garder","pos":"VER"} ,
		{"word":"garde","word_nosc":"garde","lemma":"garder","pos":"VER"} ,
		{"word":"gardent","word_nosc":"gardent","lemma":"garder","pos":"VER"} ,
		{"word":"garder","word_nosc":"garder","lemma":"garder","pos":"VER"} ,
		{"word":"gardera","word_nosc":"gardera","lemma":"garder","pos":"VER"} ,
		{"word":"garderai","word_nosc":"garderai","lemma":"garder","pos":"VER"} ,
		{"word":"garderaient","word_nosc":"garderaient","lemma":"garder","pos":"VER"} ,
		{"word":"garderais","word_nosc":"garderais","lemma":"garder","pos":"VER"} ,
		{"word":"garderait","word_nosc":"garderait","lemma":"garder","pos":"VER"} ,
		{"word":"garderas","word_nosc":"garderas","lemma":"garder","pos":"VER"} ,
		{"word":"garderez","word_nosc":"garderez","lemma":"garder","pos":"VER"} ,
		{"word":"garderiez","word_nosc":"garderiez","lemma":"garder","pos":"VER"} ,
		{"word":"garderions","word_nosc":"garderions","lemma":"garder","pos":"VER"} ,
		{"word":"garderons","word_nosc":"garderons","lemma":"garder","pos":"VER"} ,
		{"word":"garderont","word_nosc":"garderont","lemma":"garder","pos":"VER"} ,
		{"word":"gardes","word_nosc":"gardes","lemma":"garder","pos":"VER"} ,
		{"word":"gardez","word_nosc":"gardez","lemma":"garder","pos":"VER"} ,
		{"word":"gardiennait","word_nosc":"gardiennait","lemma":"gardienner","pos":"VER"} ,
		{"word":"gardiez","word_nosc":"gardiez","lemma":"garder","pos":"VER"} ,
		{"word":"gardions","word_nosc":"gardions","lemma":"garder","pos":"VER"} ,
		{"word":"gardons","word_nosc":"gardons","lemma":"garder","pos":"VER"} ,
		{"word":"gardâmes","word_nosc":"gardames","lemma":"garder","pos":"VER"} ,
		{"word":"gardât","word_nosc":"gardat","lemma":"garder","pos":"VER"} ,
		{"word":"gardèrent","word_nosc":"garderent","lemma":"garder","pos":"VER"} ,
		{"word":"gardé","word_nosc":"garde","lemma":"garder","pos":"VER"} ,
		{"word":"gardée","word_nosc":"gardee","lemma":"garder","pos":"VER"} ,
		{"word":"gardées","word_nosc":"gardees","lemma":"garder","pos":"VER"} ,
		{"word":"gardés","word_nosc":"gardes","lemma":"garder","pos":"VER"} ,
		{"word":"gare","word_nosc":"gare","lemma":"garer","pos":"VER"} ,
		{"word":"garent","word_nosc":"garent","lemma":"garer","pos":"VER"} ,
		{"word":"garer","word_nosc":"garer","lemma":"garer","pos":"VER"} ,
		{"word":"garerai","word_nosc":"garerai","lemma":"garer","pos":"VER"} ,
		{"word":"garerais","word_nosc":"garerais","lemma":"garer","pos":"VER"} ,
		{"word":"garerait","word_nosc":"garerait","lemma":"garer","pos":"VER"} ,
		{"word":"gareront","word_nosc":"gareront","lemma":"garer","pos":"VER"} ,
		{"word":"gares","word_nosc":"gares","lemma":"garer","pos":"VER"} ,
		{"word":"garez","word_nosc":"garez","lemma":"garer","pos":"VER"} ,
		{"word":"gargarisa","word_nosc":"gargarisa","lemma":"gargariser","pos":"VER"} ,
		{"word":"gargarisaient","word_nosc":"gargarisaient","lemma":"gargariser","pos":"VER"} ,
		{"word":"gargarisait","word_nosc":"gargarisait","lemma":"gargariser","pos":"VER"} ,
		{"word":"gargarisant","word_nosc":"gargarisant","lemma":"gargariser","pos":"VER"} ,
		{"word":"gargarise","word_nosc":"gargarise","lemma":"gargariser","pos":"VER"} ,
		{"word":"gargarisent","word_nosc":"gargarisent","lemma":"gargariser","pos":"VER"} ,
		{"word":"gargariser","word_nosc":"gargariser","lemma":"gargariser","pos":"VER"} ,
		{"word":"gargarisé","word_nosc":"gargarise","lemma":"gargariser","pos":"VER"} ,
		{"word":"gargouilla","word_nosc":"gargouilla","lemma":"gargouiller","pos":"VER"} ,
		{"word":"gargouillaient","word_nosc":"gargouillaient","lemma":"gargouiller","pos":"VER"} ,
		{"word":"gargouillait","word_nosc":"gargouillait","lemma":"gargouiller","pos":"VER"} ,
		{"word":"gargouillant","word_nosc":"gargouillant","lemma":"gargouiller","pos":"VER"} ,
		{"word":"gargouille","word_nosc":"gargouille","lemma":"gargouiller","pos":"VER"} ,
		{"word":"gargouillent","word_nosc":"gargouillent","lemma":"gargouiller","pos":"VER"} ,
		{"word":"gargouiller","word_nosc":"gargouiller","lemma":"gargouiller","pos":"VER"} ,
		{"word":"gargouillé","word_nosc":"gargouille","lemma":"gargouiller","pos":"VER"} ,
		{"word":"garions","word_nosc":"garions","lemma":"garer","pos":"VER"} ,
		{"word":"garni","word_nosc":"garni","lemma":"garnir","pos":"VER"} ,
		{"word":"garnie","word_nosc":"garnie","lemma":"garnir","pos":"VER"} ,
		{"word":"garnies","word_nosc":"garnies","lemma":"garnir","pos":"VER"} ,
		{"word":"garnir","word_nosc":"garnir","lemma":"garnir","pos":"VER"} ,
		{"word":"garnirent","word_nosc":"garnirent","lemma":"garnir","pos":"VER"} ,
		{"word":"garniront","word_nosc":"garniront","lemma":"garnir","pos":"VER"} ,
		{"word":"garnis","word_nosc":"garnis","lemma":"garnir","pos":"VER"} ,
		{"word":"garnissaient","word_nosc":"garnissaient","lemma":"garnir","pos":"VER"} ,
		{"word":"garnissais","word_nosc":"garnissais","lemma":"garnir","pos":"VER"} ,
		{"word":"garnissait","word_nosc":"garnissait","lemma":"garnir","pos":"VER"} ,
		{"word":"garnissant","word_nosc":"garnissant","lemma":"garnir","pos":"VER"} ,
		{"word":"garnissent","word_nosc":"garnissent","lemma":"garnir","pos":"VER"} ,
		{"word":"garnissez","word_nosc":"garnissez","lemma":"garnir","pos":"VER"} ,
		{"word":"garnit","word_nosc":"garnit","lemma":"garnir","pos":"VER"} ,
		{"word":"garons","word_nosc":"garons","lemma":"garer","pos":"VER"} ,
		{"word":"garrottaient","word_nosc":"garrottaient","lemma":"garrotter","pos":"VER"} ,
		{"word":"garrotte","word_nosc":"garrotte","lemma":"garrotter","pos":"VER"} ,
		{"word":"garrottent","word_nosc":"garrottent","lemma":"garrotter","pos":"VER"} ,
		{"word":"garrotter","word_nosc":"garrotter","lemma":"garrotter","pos":"VER"} ,
		{"word":"garrotté","word_nosc":"garrotte","lemma":"garrotter","pos":"VER"} ,
		{"word":"garrottée","word_nosc":"garrottee","lemma":"garrotter","pos":"VER"} ,
		{"word":"garrottés","word_nosc":"garrottes","lemma":"garrotter","pos":"VER"} ,
		{"word":"garèrent","word_nosc":"garerent","lemma":"garer","pos":"VER"} ,
		{"word":"garé","word_nosc":"gare","lemma":"garer","pos":"VER"} ,
		{"word":"garée","word_nosc":"garee","lemma":"garer","pos":"VER"} ,
		{"word":"garées","word_nosc":"garees","lemma":"garer","pos":"VER"} ,
		{"word":"garés","word_nosc":"gares","lemma":"garer","pos":"VER"} ,
		{"word":"gaspilla","word_nosc":"gaspilla","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspillaient","word_nosc":"gaspillaient","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspillait","word_nosc":"gaspillait","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspillant","word_nosc":"gaspillant","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspillasse","word_nosc":"gaspillasse","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspille","word_nosc":"gaspille","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspillent","word_nosc":"gaspillent","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspiller","word_nosc":"gaspiller","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspillerai","word_nosc":"gaspillerai","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspilleraient","word_nosc":"gaspilleraient","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspillerais","word_nosc":"gaspillerais","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspillerez","word_nosc":"gaspillerez","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspilles","word_nosc":"gaspilles","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspillez","word_nosc":"gaspillez","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspillons","word_nosc":"gaspillons","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspillât","word_nosc":"gaspillat","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspillé","word_nosc":"gaspille","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspillée","word_nosc":"gaspillee","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspillées","word_nosc":"gaspillees","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gaspillés","word_nosc":"gaspilles","lemma":"gaspiller","pos":"VER"} ,
		{"word":"gauchi","word_nosc":"gauchi","lemma":"gauchir","pos":"VER"} ,
		{"word":"gauchie","word_nosc":"gauchie","lemma":"gauchir","pos":"VER"} ,
		{"word":"gauchir","word_nosc":"gauchir","lemma":"gauchir","pos":"VER"} ,
		{"word":"gauchis","word_nosc":"gauchis","lemma":"gauchir","pos":"VER"} ,
		{"word":"gauchissaient","word_nosc":"gauchissaient","lemma":"gauchir","pos":"VER"} ,
		{"word":"gauchissait","word_nosc":"gauchissait","lemma":"gauchir","pos":"VER"} ,
		{"word":"gaufre","word_nosc":"gaufre","lemma":"gaufrer","pos":"VER"} ,
		{"word":"gaufré","word_nosc":"gaufre","lemma":"gaufrer","pos":"VER"} ,
		{"word":"gaufrée","word_nosc":"gaufree","lemma":"gaufrer","pos":"VER"} ,
		{"word":"gaufrées","word_nosc":"gaufrees","lemma":"gaufrer","pos":"VER"} ,
		{"word":"gaufrés","word_nosc":"gaufres","lemma":"gaufrer","pos":"VER"} ,
		{"word":"gaulant","word_nosc":"gaulant","lemma":"gauler","pos":"VER"} ,
		{"word":"gaule","word_nosc":"gaule","lemma":"gauler","pos":"VER"} ,
		{"word":"gauler","word_nosc":"gauler","lemma":"gauler","pos":"VER"} ,
		{"word":"gaulerais","word_nosc":"gaulerais","lemma":"gauler","pos":"VER"} ,
		{"word":"gaulerait","word_nosc":"gaulerait","lemma":"gauler","pos":"VER"} ,
		{"word":"gaulèrent","word_nosc":"gaulerent","lemma":"gauler","pos":"VER"} ,
		{"word":"gaulé","word_nosc":"gaule","lemma":"gauler","pos":"VER"} ,
		{"word":"gaulée","word_nosc":"gaulee","lemma":"gauler","pos":"VER"} ,
		{"word":"gaussaient","word_nosc":"gaussaient","lemma":"gausser","pos":"VER"} ,
		{"word":"gaussait","word_nosc":"gaussait","lemma":"gausser","pos":"VER"} ,
		{"word":"gaussant","word_nosc":"gaussant","lemma":"gausser","pos":"VER"} ,
		{"word":"gausse","word_nosc":"gausse","lemma":"gausser","pos":"VER"} ,
		{"word":"gaussent","word_nosc":"gaussent","lemma":"gausser","pos":"VER"} ,
		{"word":"gausser","word_nosc":"gausser","lemma":"gausser","pos":"VER"} ,
		{"word":"gausserai","word_nosc":"gausserai","lemma":"gausser","pos":"VER"} ,
		{"word":"gausserait","word_nosc":"gausserait","lemma":"gausser","pos":"VER"} ,
		{"word":"gaussez","word_nosc":"gaussez","lemma":"gausser","pos":"VER"} ,
		{"word":"gaussèrent","word_nosc":"gausserent","lemma":"gausser","pos":"VER"} ,
		{"word":"gaussé","word_nosc":"gausse","lemma":"gausser","pos":"VER"} ,
		{"word":"gaussée","word_nosc":"gaussee","lemma":"gausser","pos":"VER"} ,
		{"word":"gava","word_nosc":"gava","lemma":"gaver","pos":"VER"} ,
		{"word":"gavai","word_nosc":"gavai","lemma":"gaver","pos":"VER"} ,
		{"word":"gavaient","word_nosc":"gavaient","lemma":"gaver","pos":"VER"} ,
		{"word":"gavais","word_nosc":"gavais","lemma":"gaver","pos":"VER"} ,
		{"word":"gavait","word_nosc":"gavait","lemma":"gaver","pos":"VER"} ,
		{"word":"gavant","word_nosc":"gavant","lemma":"gaver","pos":"VER"} ,
		{"word":"gave","word_nosc":"gave","lemma":"gaver","pos":"VER"} ,
		{"word":"gavent","word_nosc":"gavent","lemma":"gaver","pos":"VER"} ,
		{"word":"gaver","word_nosc":"gaver","lemma":"gaver","pos":"VER"} ,
		{"word":"gavera","word_nosc":"gavera","lemma":"gaver","pos":"VER"} ,
		{"word":"gaveraient","word_nosc":"gaveraient","lemma":"gaver","pos":"VER"} ,
		{"word":"gaves","word_nosc":"gaves","lemma":"gaver","pos":"VER"} ,
		{"word":"gavez","word_nosc":"gavez","lemma":"gaver","pos":"VER"} ,
		{"word":"gavons","word_nosc":"gavons","lemma":"gaver","pos":"VER"} ,
		{"word":"gavé","word_nosc":"gave","lemma":"gaver","pos":"VER"} ,
		{"word":"gavée","word_nosc":"gavee","lemma":"gaver","pos":"VER"} ,
		{"word":"gavées","word_nosc":"gavees","lemma":"gaver","pos":"VER"} ,
		{"word":"gavés","word_nosc":"gaves","lemma":"gaver","pos":"VER"} ,
		{"word":"gazait","word_nosc":"gazait","lemma":"gazer","pos":"VER"} ,
		{"word":"gaze","word_nosc":"gaze","lemma":"gazer","pos":"VER"} ,
		{"word":"gazer","word_nosc":"gazer","lemma":"gazer","pos":"VER"} ,
		{"word":"gazera","word_nosc":"gazera","lemma":"gazer","pos":"VER"} ,
		{"word":"gazerait","word_nosc":"gazerait","lemma":"gazer","pos":"VER"} ,
		{"word":"gazez","word_nosc":"gazez","lemma":"gazer","pos":"VER"} ,
		{"word":"gazons","word_nosc":"gazons","lemma":"gazer","pos":"VER"} ,
		{"word":"gazouilla","word_nosc":"gazouilla","lemma":"gazouiller","pos":"VER"} ,
		{"word":"gazouillaient","word_nosc":"gazouillaient","lemma":"gazouiller","pos":"VER"} ,
		{"word":"gazouillait","word_nosc":"gazouillait","lemma":"gazouiller","pos":"VER"} ,
		{"word":"gazouillant","word_nosc":"gazouillant","lemma":"gazouiller","pos":"VER"} ,
		{"word":"gazouille","word_nosc":"gazouille","lemma":"gazouiller","pos":"VER"} ,
		{"word":"gazouillent","word_nosc":"gazouillent","lemma":"gazouiller","pos":"VER"} ,
		{"word":"gazouiller","word_nosc":"gazouiller","lemma":"gazouiller","pos":"VER"} ,
		{"word":"gazouilles","word_nosc":"gazouilles","lemma":"gazouiller","pos":"VER"} ,
		{"word":"gazé","word_nosc":"gaze","lemma":"gazer","pos":"VER"} ,
		{"word":"gazée","word_nosc":"gazee","lemma":"gazer","pos":"VER"} ,
		{"word":"gazées","word_nosc":"gazees","lemma":"gazer","pos":"VER"} ,
		{"word":"gazéifier","word_nosc":"gazeifier","lemma":"gazéifier","pos":"VER"} ,
		{"word":"gazéifiée","word_nosc":"gazeifiee","lemma":"gazéifier","pos":"VER"} ,
		{"word":"gazés","word_nosc":"gazes","lemma":"gazer","pos":"VER"} ,
		{"word":"geignaient","word_nosc":"geignaient","lemma":"geindre","pos":"VER"} ,
		{"word":"geignais","word_nosc":"geignais","lemma":"geindre","pos":"VER"} ,
		{"word":"geignait","word_nosc":"geignait","lemma":"geindre","pos":"VER"} ,
		{"word":"geignant","word_nosc":"geignant","lemma":"geindre","pos":"VER"} ,
		{"word":"geignent","word_nosc":"geignent","lemma":"geindre","pos":"VER"} ,
		{"word":"geignez","word_nosc":"geignez","lemma":"geindre","pos":"VER"} ,
		{"word":"geignit","word_nosc":"geignit","lemma":"geindre","pos":"VER"} ,
		{"word":"geindre","word_nosc":"geindre","lemma":"geindre","pos":"VER"} ,
		{"word":"geins","word_nosc":"geins","lemma":"geindre","pos":"VER"} ,
		{"word":"geint","word_nosc":"geint","lemma":"geindre","pos":"VER"} ,
		{"word":"gela","word_nosc":"gela","lemma":"geler","pos":"VER"} ,
		{"word":"gelaient","word_nosc":"gelaient","lemma":"geler","pos":"VER"} ,
		{"word":"gelais","word_nosc":"gelais","lemma":"geler","pos":"VER"} ,
		{"word":"gelait","word_nosc":"gelait","lemma":"geler","pos":"VER"} ,
		{"word":"gelant","word_nosc":"gelant","lemma":"geler","pos":"VER"} ,
		{"word":"geler","word_nosc":"geler","lemma":"geler","pos":"VER"} ,
		{"word":"gelez","word_nosc":"gelez","lemma":"geler","pos":"VER"} ,
		{"word":"gelons","word_nosc":"gelons","lemma":"geler","pos":"VER"} ,
		{"word":"gelât","word_nosc":"gelat","lemma":"geler","pos":"VER"} ,
		{"word":"gelé","word_nosc":"gele","lemma":"geler","pos":"VER"} ,
		{"word":"gelée","word_nosc":"gelee","lemma":"geler","pos":"VER"} ,
		{"word":"gelées","word_nosc":"gelees","lemma":"geler","pos":"VER"} ,
		{"word":"gelés","word_nosc":"geles","lemma":"geler","pos":"VER"} ,
		{"word":"gendarmer","word_nosc":"gendarmer","lemma":"gendarmer","pos":"VER"} ,
		{"word":"gerba","word_nosc":"gerba","lemma":"gerber","pos":"VER"} ,
		{"word":"gerbaient","word_nosc":"gerbaient","lemma":"gerber","pos":"VER"} ,
		{"word":"gerbais","word_nosc":"gerbais","lemma":"gerber","pos":"VER"} ,
		{"word":"gerbait","word_nosc":"gerbait","lemma":"gerber","pos":"VER"} ,
		{"word":"gerbant","word_nosc":"gerbant","lemma":"gerber","pos":"VER"} ,
		{"word":"gerbe","word_nosc":"gerbe","lemma":"gerber","pos":"VER"} ,
		{"word":"gerbent","word_nosc":"gerbent","lemma":"gerber","pos":"VER"} ,
		{"word":"gerber","word_nosc":"gerber","lemma":"gerber","pos":"VER"} ,
		{"word":"gerberais","word_nosc":"gerberais","lemma":"gerber","pos":"VER"} ,
		{"word":"gerbes","word_nosc":"gerbes","lemma":"gerber","pos":"VER"} ,
		{"word":"gerbèrent","word_nosc":"gerberent","lemma":"gerber","pos":"VER"} ,
		{"word":"gerbé","word_nosc":"gerbe","lemma":"gerber","pos":"VER"} ,
		{"word":"gerbée","word_nosc":"gerbee","lemma":"gerber","pos":"VER"} ,
		{"word":"gerbées","word_nosc":"gerbees","lemma":"gerber","pos":"VER"} ,
		{"word":"gerbés","word_nosc":"gerbes","lemma":"gerber","pos":"VER"} ,
		{"word":"gerce","word_nosc":"gerce","lemma":"gercer","pos":"VER"} ,
		{"word":"gercent","word_nosc":"gercent","lemma":"gercer","pos":"VER"} ,
		{"word":"gercer","word_nosc":"gercer","lemma":"gercer","pos":"VER"} ,
		{"word":"gercèrent","word_nosc":"gercerent","lemma":"gercer","pos":"VER"} ,
		{"word":"gercé","word_nosc":"gerce","lemma":"gercer","pos":"VER"} ,
		{"word":"gercée","word_nosc":"gercee","lemma":"gercer","pos":"VER"} ,
		{"word":"gercées","word_nosc":"gercees","lemma":"gercer","pos":"VER"} ,
		{"word":"gercés","word_nosc":"gerces","lemma":"gercer","pos":"VER"} ,
		{"word":"germa","word_nosc":"germa","lemma":"germer","pos":"VER"} ,
		{"word":"germaient","word_nosc":"germaient","lemma":"germer","pos":"VER"} ,
		{"word":"germait","word_nosc":"germait","lemma":"germer","pos":"VER"} ,
		{"word":"germe","word_nosc":"germe","lemma":"germer","pos":"VER"} ,
		{"word":"germent","word_nosc":"germent","lemma":"germer","pos":"VER"} ,
		{"word":"germer","word_nosc":"germer","lemma":"germer","pos":"VER"} ,
		{"word":"germera","word_nosc":"germera","lemma":"germer","pos":"VER"} ,
		{"word":"germerait","word_nosc":"germerait","lemma":"germer","pos":"VER"} ,
		{"word":"germé","word_nosc":"germe","lemma":"germer","pos":"VER"} ,
		{"word":"germée","word_nosc":"germee","lemma":"germer","pos":"VER"} ,
		{"word":"germées","word_nosc":"germees","lemma":"germer","pos":"VER"} ,
		{"word":"gerçait","word_nosc":"gercait","lemma":"gercer","pos":"VER"} ,
		{"word":"gesticula","word_nosc":"gesticula","lemma":"gesticuler","pos":"VER"} ,
		{"word":"gesticulai","word_nosc":"gesticulai","lemma":"gesticuler","pos":"VER"} ,
		{"word":"gesticulaient","word_nosc":"gesticulaient","lemma":"gesticuler","pos":"VER"} ,
		{"word":"gesticulais","word_nosc":"gesticulais","lemma":"gesticuler","pos":"VER"} ,
		{"word":"gesticulait","word_nosc":"gesticulait","lemma":"gesticuler","pos":"VER"} ,
		{"word":"gesticulant","word_nosc":"gesticulant","lemma":"gesticuler","pos":"VER"} ,
		{"word":"gesticule","word_nosc":"gesticule","lemma":"gesticuler","pos":"VER"} ,
		{"word":"gesticulent","word_nosc":"gesticulent","lemma":"gesticuler","pos":"VER"} ,
		{"word":"gesticuler","word_nosc":"gesticuler","lemma":"gesticuler","pos":"VER"} ,
		{"word":"gesticules","word_nosc":"gesticules","lemma":"gesticuler","pos":"VER"} ,
		{"word":"gesticulé","word_nosc":"gesticule","lemma":"gesticuler","pos":"VER"} ,
		{"word":"gicla","word_nosc":"gicla","lemma":"gicler","pos":"VER"} ,
		{"word":"giclaient","word_nosc":"giclaient","lemma":"gicler","pos":"VER"} ,
		{"word":"giclais","word_nosc":"giclais","lemma":"gicler","pos":"VER"} ,
		{"word":"giclait","word_nosc":"giclait","lemma":"gicler","pos":"VER"} ,
		{"word":"giclant","word_nosc":"giclant","lemma":"gicler","pos":"VER"} ,
		{"word":"gicle","word_nosc":"gicle","lemma":"gicler","pos":"VER"} ,
		{"word":"giclent","word_nosc":"giclent","lemma":"gicler","pos":"VER"} ,
		{"word":"gicler","word_nosc":"gicler","lemma":"gicler","pos":"VER"} ,
		{"word":"giclera","word_nosc":"giclera","lemma":"gicler","pos":"VER"} ,
		{"word":"gicleront","word_nosc":"gicleront","lemma":"gicler","pos":"VER"} ,
		{"word":"gicles","word_nosc":"gicles","lemma":"gicler","pos":"VER"} ,
		{"word":"giclez","word_nosc":"giclez","lemma":"gicler","pos":"VER"} ,
		{"word":"giclèrent","word_nosc":"giclerent","lemma":"gicler","pos":"VER"} ,
		{"word":"giclé","word_nosc":"gicle","lemma":"gicler","pos":"VER"} ,
		{"word":"giclée","word_nosc":"giclee","lemma":"gicler","pos":"VER"} ,
		{"word":"gifla","word_nosc":"gifla","lemma":"gifler","pos":"VER"} ,
		{"word":"giflai","word_nosc":"giflai","lemma":"gifler","pos":"VER"} ,
		{"word":"giflaient","word_nosc":"giflaient","lemma":"gifler","pos":"VER"} ,
		{"word":"giflais","word_nosc":"giflais","lemma":"gifler","pos":"VER"} ,
		{"word":"giflait","word_nosc":"giflait","lemma":"gifler","pos":"VER"} ,
		{"word":"giflant","word_nosc":"giflant","lemma":"gifler","pos":"VER"} ,
		{"word":"gifle","word_nosc":"gifle","lemma":"gifler","pos":"VER"} ,
		{"word":"giflent","word_nosc":"giflent","lemma":"gifler","pos":"VER"} ,
		{"word":"gifler","word_nosc":"gifler","lemma":"gifler","pos":"VER"} ,
		{"word":"giflera","word_nosc":"giflera","lemma":"gifler","pos":"VER"} ,
		{"word":"giflerai","word_nosc":"giflerai","lemma":"gifler","pos":"VER"} ,
		{"word":"giflerais","word_nosc":"giflerais","lemma":"gifler","pos":"VER"} ,
		{"word":"giflerait","word_nosc":"giflerait","lemma":"gifler","pos":"VER"} ,
		{"word":"gifles","word_nosc":"gifles","lemma":"gifler","pos":"VER"} ,
		{"word":"giflez","word_nosc":"giflez","lemma":"gifler","pos":"VER"} ,
		{"word":"giflât","word_nosc":"giflat","lemma":"gifler","pos":"VER"} ,
		{"word":"giflé","word_nosc":"gifle","lemma":"gifler","pos":"VER"} ,
		{"word":"giflée","word_nosc":"giflee","lemma":"gifler","pos":"VER"} ,
		{"word":"giflées","word_nosc":"giflees","lemma":"gifler","pos":"VER"} ,
		{"word":"giflés","word_nosc":"gifles","lemma":"gifler","pos":"VER"} ,
		{"word":"gigota","word_nosc":"gigota","lemma":"gigoter","pos":"VER"} ,
		{"word":"gigotaient","word_nosc":"gigotaient","lemma":"gigoter","pos":"VER"} ,
		{"word":"gigotait","word_nosc":"gigotait","lemma":"gigoter","pos":"VER"} ,
		{"word":"gigotant","word_nosc":"gigotant","lemma":"gigoter","pos":"VER"} ,
		{"word":"gigote","word_nosc":"gigote","lemma":"gigoter","pos":"VER"} ,
		{"word":"gigotent","word_nosc":"gigotent","lemma":"gigoter","pos":"VER"} ,
		{"word":"gigoter","word_nosc":"gigoter","lemma":"gigoter","pos":"VER"} ,
		{"word":"gigotes","word_nosc":"gigotes","lemma":"gigoter","pos":"VER"} ,
		{"word":"gigotez","word_nosc":"gigotez","lemma":"gigoter","pos":"VER"} ,
		{"word":"gigoté","word_nosc":"gigote","lemma":"gigoter","pos":"VER"} ,
		{"word":"girer","word_nosc":"girer","lemma":"girer","pos":"VER"} ,
		{"word":"girouettait","word_nosc":"girouettait","lemma":"girouetter","pos":"VER"} ,
		{"word":"girouettant","word_nosc":"girouettant","lemma":"girouetter","pos":"VER"} ,
		{"word":"gis","word_nosc":"gis","lemma":"gésir","pos":"VER"} ,
		{"word":"gisaient","word_nosc":"gisaient","lemma":"gésir","pos":"VER"} ,
		{"word":"gisais","word_nosc":"gisais","lemma":"gésir","pos":"VER"} ,
		{"word":"gisait","word_nosc":"gisait","lemma":"gésir","pos":"VER"} ,
		{"word":"gisant","word_nosc":"gisant","lemma":"gésir","pos":"VER"} ,
		{"word":"gisent","word_nosc":"gisent","lemma":"gésir","pos":"VER"} ,
		{"word":"gisions","word_nosc":"gisions","lemma":"gésir","pos":"VER"} ,
		{"word":"givrait","word_nosc":"givrait","lemma":"givrer","pos":"VER"} ,
		{"word":"givrer","word_nosc":"givrer","lemma":"givrer","pos":"VER"} ,
		{"word":"givré","word_nosc":"givre","lemma":"givrer","pos":"VER"} ,
		{"word":"givrée","word_nosc":"givree","lemma":"givrer","pos":"VER"} ,
		{"word":"givrées","word_nosc":"givrees","lemma":"givrer","pos":"VER"} ,
		{"word":"givrés","word_nosc":"givres","lemma":"givrer","pos":"VER"} ,
		{"word":"glace","word_nosc":"glace","lemma":"glacer","pos":"VER"} ,
		{"word":"glacent","word_nosc":"glacent","lemma":"glacer","pos":"VER"} ,
		{"word":"glacer","word_nosc":"glacer","lemma":"glacer","pos":"VER"} ,
		{"word":"glacera","word_nosc":"glacera","lemma":"glacer","pos":"VER"} ,
		{"word":"glaceront","word_nosc":"glaceront","lemma":"glacer","pos":"VER"} ,
		{"word":"glaces","word_nosc":"glaces","lemma":"glacer","pos":"VER"} ,
		{"word":"glacez","word_nosc":"glacez","lemma":"glacer","pos":"VER"} ,
		{"word":"glacèrent","word_nosc":"glacerent","lemma":"glacer","pos":"VER"} ,
		{"word":"glacé","word_nosc":"glace","lemma":"glacer","pos":"VER"} ,
		{"word":"glacée","word_nosc":"glacee","lemma":"glacer","pos":"VER"} ,
		{"word":"glacées","word_nosc":"glacees","lemma":"glacer","pos":"VER"} ,
		{"word":"glacés","word_nosc":"glaces","lemma":"glacer","pos":"VER"} ,
		{"word":"glaglate","word_nosc":"glaglate","lemma":"glaglater","pos":"VER"} ,
		{"word":"glaglater","word_nosc":"glaglater","lemma":"glaglater","pos":"VER"} ,
		{"word":"glana","word_nosc":"glana","lemma":"glaner","pos":"VER"} ,
		{"word":"glanaient","word_nosc":"glanaient","lemma":"glaner","pos":"VER"} ,
		{"word":"glanais","word_nosc":"glanais","lemma":"glaner","pos":"VER"} ,
		{"word":"glanait","word_nosc":"glanait","lemma":"glaner","pos":"VER"} ,
		{"word":"glanant","word_nosc":"glanant","lemma":"glaner","pos":"VER"} ,
		{"word":"glandais","word_nosc":"glandais","lemma":"glander","pos":"VER"} ,
		{"word":"glandait","word_nosc":"glandait","lemma":"glander","pos":"VER"} ,
		{"word":"glande","word_nosc":"glande","lemma":"glander","pos":"VER"} ,
		{"word":"glandent","word_nosc":"glandent","lemma":"glander","pos":"VER"} ,
		{"word":"glander","word_nosc":"glander","lemma":"glander","pos":"VER"} ,
		{"word":"glandes","word_nosc":"glandes","lemma":"glander","pos":"VER"} ,
		{"word":"glandez","word_nosc":"glandez","lemma":"glander","pos":"VER"} ,
		{"word":"glandiez","word_nosc":"glandiez","lemma":"glander","pos":"VER"} ,
		{"word":"glandons","word_nosc":"glandons","lemma":"glander","pos":"VER"} ,
		{"word":"glandouillant","word_nosc":"glandouillant","lemma":"glandouiller","pos":"VER"} ,
		{"word":"glandouille","word_nosc":"glandouille","lemma":"glandouiller","pos":"VER"} ,
		{"word":"glandouiller","word_nosc":"glandouiller","lemma":"glandouiller","pos":"VER"} ,
		{"word":"glandouillez","word_nosc":"glandouillez","lemma":"glandouiller","pos":"VER"} ,
		{"word":"glandouillé","word_nosc":"glandouille","lemma":"glandouiller","pos":"VER"} ,
		{"word":"glandé","word_nosc":"glande","lemma":"glander","pos":"VER"} ,
		{"word":"glane","word_nosc":"glane","lemma":"glaner","pos":"VER"} ,
		{"word":"glanent","word_nosc":"glanent","lemma":"glaner","pos":"VER"} ,
		{"word":"glaner","word_nosc":"glaner","lemma":"glaner","pos":"VER"} ,
		{"word":"glanez","word_nosc":"glanez","lemma":"glaner","pos":"VER"} ,
		{"word":"glané","word_nosc":"glane","lemma":"glaner","pos":"VER"} ,
		{"word":"glanée","word_nosc":"glanee","lemma":"glaner","pos":"VER"} ,
		{"word":"glanées","word_nosc":"glanees","lemma":"glaner","pos":"VER"} ,
		{"word":"glanés","word_nosc":"glanes","lemma":"glaner","pos":"VER"} ,
		{"word":"glapi","word_nosc":"glapi","lemma":"glapir","pos":"VER"} ,
		{"word":"glapie","word_nosc":"glapie","lemma":"glapir","pos":"VER"} ,
		{"word":"glapir","word_nosc":"glapir","lemma":"glapir","pos":"VER"} ,
		{"word":"glapirent","word_nosc":"glapirent","lemma":"glapir","pos":"VER"} ,
		{"word":"glapissaient","word_nosc":"glapissaient","lemma":"glapir","pos":"VER"} ,
		{"word":"glapissait","word_nosc":"glapissait","lemma":"glapir","pos":"VER"} ,
		{"word":"glapissant","word_nosc":"glapissant","lemma":"glapir","pos":"VER"} ,
		{"word":"glapissent","word_nosc":"glapissent","lemma":"glapir","pos":"VER"} ,
		{"word":"glapissions","word_nosc":"glapissions","lemma":"glapir","pos":"VER"} ,
		{"word":"glapit","word_nosc":"glapit","lemma":"glapir","pos":"VER"} ,
		{"word":"glaviotaient","word_nosc":"glaviotaient","lemma":"glavioter","pos":"VER"} ,
		{"word":"glaviotait","word_nosc":"glaviotait","lemma":"glavioter","pos":"VER"} ,
		{"word":"glaviotant","word_nosc":"glaviotant","lemma":"glavioter","pos":"VER"} ,
		{"word":"glaviote","word_nosc":"glaviote","lemma":"glavioter","pos":"VER"} ,
		{"word":"glavioter","word_nosc":"glavioter","lemma":"glavioter","pos":"VER"} ,
		{"word":"glaviotte","word_nosc":"glaviotte","lemma":"glaviotter","pos":"VER"} ,
		{"word":"glaça","word_nosc":"glaca","lemma":"glacer","pos":"VER"} ,
		{"word":"glaçaient","word_nosc":"glacaient","lemma":"glacer","pos":"VER"} ,
		{"word":"glaçais","word_nosc":"glacais","lemma":"glacer","pos":"VER"} ,
		{"word":"glaçait","word_nosc":"glacait","lemma":"glacer","pos":"VER"} ,
		{"word":"glaçant","word_nosc":"glacant","lemma":"glacer","pos":"VER"} ,
		{"word":"glaçons","word_nosc":"glacons","lemma":"glacer","pos":"VER"} ,
		{"word":"glissa","word_nosc":"glissa","lemma":"glisser","pos":"VER"} ,
		{"word":"glissai","word_nosc":"glissai","lemma":"glisser","pos":"VER"} ,
		{"word":"glissaient","word_nosc":"glissaient","lemma":"glisser","pos":"VER"} ,
		{"word":"glissais","word_nosc":"glissais","lemma":"glisser","pos":"VER"} ,
		{"word":"glissait","word_nosc":"glissait","lemma":"glisser","pos":"VER"} ,
		{"word":"glissant","word_nosc":"glissant","lemma":"glisser","pos":"VER"} ,
		{"word":"glisse","word_nosc":"glisse","lemma":"glisser","pos":"VER"} ,
		{"word":"glissent","word_nosc":"glissent","lemma":"glisser","pos":"VER"} ,
		{"word":"glisser","word_nosc":"glisser","lemma":"glisser","pos":"VER"} ,
		{"word":"glissera","word_nosc":"glissera","lemma":"glisser","pos":"VER"} ,
		{"word":"glisserai","word_nosc":"glisserai","lemma":"glisser","pos":"VER"} ,
		{"word":"glisseraient","word_nosc":"glisseraient","lemma":"glisser","pos":"VER"} ,
		{"word":"glisserais","word_nosc":"glisserais","lemma":"glisser","pos":"VER"} ,
		{"word":"glisserait","word_nosc":"glisserait","lemma":"glisser","pos":"VER"} ,
		{"word":"glisserez","word_nosc":"glisserez","lemma":"glisser","pos":"VER"} ,
		{"word":"glisseriez","word_nosc":"glisseriez","lemma":"glisser","pos":"VER"} ,
		{"word":"glisserons","word_nosc":"glisserons","lemma":"glisser","pos":"VER"} ,
		{"word":"glisseront","word_nosc":"glisseront","lemma":"glisser","pos":"VER"} ,
		{"word":"glisses","word_nosc":"glisses","lemma":"glisser","pos":"VER"} ,
		{"word":"glissez","word_nosc":"glissez","lemma":"glisser","pos":"VER"} ,
		{"word":"glissions","word_nosc":"glissions","lemma":"glisser","pos":"VER"} ,
		{"word":"glissons","word_nosc":"glissons","lemma":"glisser","pos":"VER"} ,
		{"word":"glissâmes","word_nosc":"glissames","lemma":"glisser","pos":"VER"} ,
		{"word":"glissât","word_nosc":"glissat","lemma":"glisser","pos":"VER"} ,
		{"word":"glissèrent","word_nosc":"glisserent","lemma":"glisser","pos":"VER"} ,
		{"word":"glissé","word_nosc":"glisse","lemma":"glisser","pos":"VER"} ,
		{"word":"glissée","word_nosc":"glissee","lemma":"glisser","pos":"VER"} ,
		{"word":"glissées","word_nosc":"glissees","lemma":"glisser","pos":"VER"} ,
		{"word":"glissés","word_nosc":"glisses","lemma":"glisser","pos":"VER"} ,
		{"word":"globalisé","word_nosc":"globalise","lemma":"globaliser","pos":"VER"} ,
		{"word":"glorifia","word_nosc":"glorifia","lemma":"glorifier","pos":"VER"} ,
		{"word":"glorifiaient","word_nosc":"glorifiaient","lemma":"glorifier","pos":"VER"} ,
		{"word":"glorifiait","word_nosc":"glorifiait","lemma":"glorifier","pos":"VER"} ,
		{"word":"glorifiant","word_nosc":"glorifiant","lemma":"glorifier","pos":"VER"} ,
		{"word":"glorifie","word_nosc":"glorifie","lemma":"glorifier","pos":"VER"} ,
		{"word":"glorifient","word_nosc":"glorifient","lemma":"glorifier","pos":"VER"} ,
		{"word":"glorifier","word_nosc":"glorifier","lemma":"glorifier","pos":"VER"} ,
		{"word":"glorifiez","word_nosc":"glorifiez","lemma":"glorifier","pos":"VER"} ,
		{"word":"glorifions","word_nosc":"glorifions","lemma":"glorifier","pos":"VER"} ,
		{"word":"glorifié","word_nosc":"glorifie","lemma":"glorifier","pos":"VER"} ,
		{"word":"glorifiées","word_nosc":"glorifiees","lemma":"glorifier","pos":"VER"} ,
		{"word":"glorifiés","word_nosc":"glorifies","lemma":"glorifier","pos":"VER"} ,
		{"word":"glosaient","word_nosc":"glosaient","lemma":"gloser","pos":"VER"} ,
		{"word":"glose","word_nosc":"glose","lemma":"gloser","pos":"VER"} ,
		{"word":"gloser","word_nosc":"gloser","lemma":"gloser","pos":"VER"} ,
		{"word":"glosé","word_nosc":"glose","lemma":"gloser","pos":"VER"} ,
		{"word":"glosées","word_nosc":"glosees","lemma":"gloser","pos":"VER"} ,
		{"word":"glougloutait","word_nosc":"glougloutait","lemma":"glouglouter","pos":"VER"} ,
		{"word":"glougloutant","word_nosc":"glougloutant","lemma":"glouglouter","pos":"VER"} ,
		{"word":"glougloute","word_nosc":"glougloute","lemma":"glouglouter","pos":"VER"} ,
		{"word":"glouglouter","word_nosc":"glouglouter","lemma":"glouglouter","pos":"VER"} ,
		{"word":"gloussa","word_nosc":"gloussa","lemma":"glousser","pos":"VER"} ,
		{"word":"gloussaient","word_nosc":"gloussaient","lemma":"glousser","pos":"VER"} ,
		{"word":"gloussait","word_nosc":"gloussait","lemma":"glousser","pos":"VER"} ,
		{"word":"gloussant","word_nosc":"gloussant","lemma":"glousser","pos":"VER"} ,
		{"word":"glousse","word_nosc":"glousse","lemma":"glousser","pos":"VER"} ,
		{"word":"gloussent","word_nosc":"gloussent","lemma":"glousser","pos":"VER"} ,
		{"word":"glousser","word_nosc":"glousser","lemma":"glousser","pos":"VER"} ,
		{"word":"glousserait","word_nosc":"glousserait","lemma":"glousser","pos":"VER"} ,
		{"word":"gloussez","word_nosc":"gloussez","lemma":"glousser","pos":"VER"} ,
		{"word":"gloussèrent","word_nosc":"glousserent","lemma":"glousser","pos":"VER"} ,
		{"word":"gloussé","word_nosc":"glousse","lemma":"glousser","pos":"VER"} ,
		{"word":"gloutonnant","word_nosc":"gloutonnant","lemma":"gloutonner","pos":"VER"} ,
		{"word":"gluant","word_nosc":"gluant","lemma":"gluer","pos":"VER"} ,
		{"word":"glue","word_nosc":"glue","lemma":"gluer","pos":"VER"} ,
		{"word":"gluent","word_nosc":"gluent","lemma":"gluer","pos":"VER"} ,
		{"word":"goba","word_nosc":"goba","lemma":"gober","pos":"VER"} ,
		{"word":"gobaient","word_nosc":"gobaient","lemma":"gober","pos":"VER"} ,
		{"word":"gobais","word_nosc":"gobais","lemma":"gober","pos":"VER"} ,
		{"word":"gobait","word_nosc":"gobait","lemma":"gober","pos":"VER"} ,
		{"word":"gobant","word_nosc":"gobant","lemma":"gober","pos":"VER"} ,
		{"word":"gobe","word_nosc":"gobe","lemma":"gober","pos":"VER"} ,
		{"word":"gobelottes","word_nosc":"gobelottes","lemma":"gobelotter","pos":"VER"} ,
		{"word":"gobent","word_nosc":"gobent","lemma":"gober","pos":"VER"} ,
		{"word":"gober","word_nosc":"gober","lemma":"gober","pos":"VER"} ,
		{"word":"gobera","word_nosc":"gobera","lemma":"gober","pos":"VER"} ,
		{"word":"goberaient","word_nosc":"goberaient","lemma":"gober","pos":"VER"} ,
		{"word":"goberait","word_nosc":"goberait","lemma":"gober","pos":"VER"} ,
		{"word":"goberge","word_nosc":"goberge","lemma":"goberger","pos":"VER"} ,
		{"word":"gobergeait","word_nosc":"gobergeait","lemma":"goberger","pos":"VER"} ,
		{"word":"gobergent","word_nosc":"gobergent","lemma":"goberger","pos":"VER"} ,
		{"word":"goberger","word_nosc":"goberger","lemma":"goberger","pos":"VER"} ,
		{"word":"gobergez","word_nosc":"gobergez","lemma":"goberger","pos":"VER"} ,
		{"word":"gobergé","word_nosc":"goberge","lemma":"goberger","pos":"VER"} ,
		{"word":"goberont","word_nosc":"goberont","lemma":"gober","pos":"VER"} ,
		{"word":"gobes","word_nosc":"gobes","lemma":"gober","pos":"VER"} ,
		{"word":"gobette","word_nosc":"gobette","lemma":"gobeter","pos":"VER"} ,
		{"word":"gobez","word_nosc":"gobez","lemma":"gober","pos":"VER"} ,
		{"word":"gobions","word_nosc":"gobions","lemma":"gober","pos":"VER"} ,
		{"word":"gobèrent","word_nosc":"goberent","lemma":"gober","pos":"VER"} ,
		{"word":"gobé","word_nosc":"gobe","lemma":"gober","pos":"VER"} ,
		{"word":"gobée","word_nosc":"gobee","lemma":"gober","pos":"VER"} ,
		{"word":"gobées","word_nosc":"gobees","lemma":"gober","pos":"VER"} ,
		{"word":"gobés","word_nosc":"gobes","lemma":"gober","pos":"VER"} ,
		{"word":"goda","word_nosc":"goda","lemma":"goder","pos":"VER"} ,
		{"word":"godaille","word_nosc":"godaille","lemma":"godailler","pos":"VER"} ,
		{"word":"godaillera","word_nosc":"godaillera","lemma":"godailler","pos":"VER"} ,
		{"word":"godailles","word_nosc":"godailles","lemma":"godailler","pos":"VER"} ,
		{"word":"godait","word_nosc":"godait","lemma":"goder","pos":"VER"} ,
		{"word":"godant","word_nosc":"godant","lemma":"goder","pos":"VER"} ,
		{"word":"godasses","word_nosc":"godasses","lemma":"goder","pos":"VER"} ,
		{"word":"gode","word_nosc":"gode","lemma":"goder","pos":"VER"} ,
		{"word":"godent","word_nosc":"godent","lemma":"goder","pos":"VER"} ,
		{"word":"goder","word_nosc":"goder","lemma":"goder","pos":"VER"} ,
		{"word":"goderas","word_nosc":"goderas","lemma":"goder","pos":"VER"} ,
		{"word":"godes","word_nosc":"godes","lemma":"goder","pos":"VER"} ,
		{"word":"godillais","word_nosc":"godillais","lemma":"godiller","pos":"VER"} ,
		{"word":"godillait","word_nosc":"godillait","lemma":"godiller","pos":"VER"} ,
		{"word":"godillant","word_nosc":"godillant","lemma":"godiller","pos":"VER"} ,
		{"word":"godille","word_nosc":"godille","lemma":"godiller","pos":"VER"} ,
		{"word":"godiller","word_nosc":"godiller","lemma":"godiller","pos":"VER"} ,
		{"word":"godillé","word_nosc":"godille","lemma":"godiller","pos":"VER"} ,
		{"word":"godronnés","word_nosc":"godronnes","lemma":"godronner","pos":"VER"} ,
		{"word":"goguenarda","word_nosc":"goguenarda","lemma":"goguenarder","pos":"VER"} ,
		{"word":"goguenarde","word_nosc":"goguenarde","lemma":"goguenarder","pos":"VER"} ,
		{"word":"goinfraient","word_nosc":"goinfraient","lemma":"goinfrer","pos":"VER"} ,
		{"word":"goinfrait","word_nosc":"goinfrait","lemma":"goinfrer","pos":"VER"} ,
		{"word":"goinfre","word_nosc":"goinfre","lemma":"goinfrer","pos":"VER"} ,
		{"word":"goinfrent","word_nosc":"goinfrent","lemma":"goinfrer","pos":"VER"} ,
		{"word":"goinfrer","word_nosc":"goinfrer","lemma":"goinfrer","pos":"VER"} ,
		{"word":"goinfreras","word_nosc":"goinfreras","lemma":"goinfrer","pos":"VER"} ,
		{"word":"goinfres","word_nosc":"goinfres","lemma":"goinfrer","pos":"VER"} ,
		{"word":"goinfrez","word_nosc":"goinfrez","lemma":"goinfrer","pos":"VER"} ,
		{"word":"goinfré","word_nosc":"goinfre","lemma":"goinfrer","pos":"VER"} ,
		{"word":"goinfrée","word_nosc":"goinfree","lemma":"goinfrer","pos":"VER"} ,
		{"word":"goinfrés","word_nosc":"goinfres","lemma":"goinfrer","pos":"VER"} ,
		{"word":"gominer","word_nosc":"gominer","lemma":"gominer","pos":"VER"} ,
		{"word":"gominé","word_nosc":"gomine","lemma":"gominer","pos":"VER"} ,
		{"word":"gominée","word_nosc":"gominee","lemma":"gominer","pos":"VER"} ,
		{"word":"gominées","word_nosc":"gominees","lemma":"gominer","pos":"VER"} ,
		{"word":"gominés","word_nosc":"gomines","lemma":"gominer","pos":"VER"} ,
		{"word":"gomma","word_nosc":"gomma","lemma":"gommer","pos":"VER"} ,
		{"word":"gommait","word_nosc":"gommait","lemma":"gommer","pos":"VER"} ,
		{"word":"gommant","word_nosc":"gommant","lemma":"gommer","pos":"VER"} ,
		{"word":"gomme","word_nosc":"gomme","lemma":"gommer","pos":"VER"} ,
		{"word":"gomment","word_nosc":"gomment","lemma":"gommer","pos":"VER"} ,
		{"word":"gommer","word_nosc":"gommer","lemma":"gommer","pos":"VER"} ,
		{"word":"gommerait","word_nosc":"gommerait","lemma":"gommer","pos":"VER"} ,
		{"word":"gommes","word_nosc":"gommes","lemma":"gommer","pos":"VER"} ,
		{"word":"gommé","word_nosc":"gomme","lemma":"gommer","pos":"VER"} ,
		{"word":"gommée","word_nosc":"gommee","lemma":"gommer","pos":"VER"} ,
		{"word":"gommées","word_nosc":"gommees","lemma":"gommer","pos":"VER"} ,
		{"word":"gommés","word_nosc":"gommes","lemma":"gommer","pos":"VER"} ,
		{"word":"gonde","word_nosc":"gonde","lemma":"gonder","pos":"VER"} ,
		{"word":"gonder","word_nosc":"gonder","lemma":"gonder","pos":"VER"} ,
		{"word":"gondolaient","word_nosc":"gondolaient","lemma":"gondoler","pos":"VER"} ,
		{"word":"gondolais","word_nosc":"gondolais","lemma":"gondoler","pos":"VER"} ,
		{"word":"gondolait","word_nosc":"gondolait","lemma":"gondoler","pos":"VER"} ,
		{"word":"gondolant","word_nosc":"gondolant","lemma":"gondoler","pos":"VER"} ,
		{"word":"gondole","word_nosc":"gondole","lemma":"gondoler","pos":"VER"} ,
		{"word":"gondolent","word_nosc":"gondolent","lemma":"gondoler","pos":"VER"} ,
		{"word":"gondoler","word_nosc":"gondoler","lemma":"gondoler","pos":"VER"} ,
		{"word":"gondolera","word_nosc":"gondolera","lemma":"gondoler","pos":"VER"} ,
		{"word":"gondolèrent","word_nosc":"gondolerent","lemma":"gondoler","pos":"VER"} ,
		{"word":"gondolé","word_nosc":"gondole","lemma":"gondoler","pos":"VER"} ,
		{"word":"gondolée","word_nosc":"gondolee","lemma":"gondoler","pos":"VER"} ,
		{"word":"gondolées","word_nosc":"gondolees","lemma":"gondoler","pos":"VER"} ,
		{"word":"gondolés","word_nosc":"gondoles","lemma":"gondoler","pos":"VER"} ,
		{"word":"gonfla","word_nosc":"gonfla","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonflaient","word_nosc":"gonflaient","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonflais","word_nosc":"gonflais","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonflait","word_nosc":"gonflait","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonflant","word_nosc":"gonflant","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonfle","word_nosc":"gonfle","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonflent","word_nosc":"gonflent","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonfler","word_nosc":"gonfler","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonflera","word_nosc":"gonflera","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonflerait","word_nosc":"gonflerait","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonfles","word_nosc":"gonfles","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonflez","word_nosc":"gonflez","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonflèrent","word_nosc":"gonflerent","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonflé","word_nosc":"gonfle","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonflée","word_nosc":"gonflee","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonflées","word_nosc":"gonflees","lemma":"gonfler","pos":"VER"} ,
		{"word":"gonflés","word_nosc":"gonfles","lemma":"gonfler","pos":"VER"} ,
		{"word":"gorge","word_nosc":"gorge","lemma":"gorger","pos":"VER"} ,
		{"word":"gorgea","word_nosc":"gorgea","lemma":"gorger","pos":"VER"} ,
		{"word":"gorgeai","word_nosc":"gorgeai","lemma":"gorger","pos":"VER"} ,
		{"word":"gorgeaient","word_nosc":"gorgeaient","lemma":"gorger","pos":"VER"} ,
		{"word":"gorgeais","word_nosc":"gorgeais","lemma":"gorger","pos":"VER"} ,
		{"word":"gorgeait","word_nosc":"gorgeait","lemma":"gorger","pos":"VER"} ,
		{"word":"gorgeant","word_nosc":"gorgeant","lemma":"gorger","pos":"VER"} ,
		{"word":"gorgent","word_nosc":"gorgent","lemma":"gorger","pos":"VER"} ,
		{"word":"gorgeons","word_nosc":"gorgeons","lemma":"gorger","pos":"VER"} ,
		{"word":"gorger","word_nosc":"gorger","lemma":"gorger","pos":"VER"} ,
		{"word":"gorges","word_nosc":"gorges","lemma":"gorger","pos":"VER"} ,
		{"word":"gorgez","word_nosc":"gorgez","lemma":"gorger","pos":"VER"} ,
		{"word":"gorgèrent","word_nosc":"gorgerent","lemma":"gorger","pos":"VER"} ,
		{"word":"gorgé","word_nosc":"gorge","lemma":"gorger","pos":"VER"} ,
		{"word":"gorgée","word_nosc":"gorgee","lemma":"gorger","pos":"VER"} ,
		{"word":"gorgées","word_nosc":"gorgees","lemma":"gorger","pos":"VER"} ,
		{"word":"gorgés","word_nosc":"gorges","lemma":"gorger","pos":"VER"} ,
		{"word":"gouachez","word_nosc":"gouachez","lemma":"gouacher","pos":"VER"} ,
		{"word":"gouailla","word_nosc":"gouailla","lemma":"gouailler","pos":"VER"} ,
		{"word":"gouaille","word_nosc":"gouaille","lemma":"gouailler","pos":"VER"} ,
		{"word":"gouaillé","word_nosc":"gouaille","lemma":"gouailler","pos":"VER"} ,
		{"word":"goualer","word_nosc":"goualer","lemma":"goualer","pos":"VER"} ,
		{"word":"goudronne","word_nosc":"goudronne","lemma":"goudronner","pos":"VER"} ,
		{"word":"goudronner","word_nosc":"goudronner","lemma":"goudronner","pos":"VER"} ,
		{"word":"goudronnez","word_nosc":"goudronnez","lemma":"goudronner","pos":"VER"} ,
		{"word":"goudronné","word_nosc":"goudronne","lemma":"goudronner","pos":"VER"} ,
		{"word":"goudronnée","word_nosc":"goudronnee","lemma":"goudronner","pos":"VER"} ,
		{"word":"gougnottes","word_nosc":"gougnottes","lemma":"gougnotter","pos":"VER"} ,
		{"word":"goupillait","word_nosc":"goupillait","lemma":"goupiller","pos":"VER"} ,
		{"word":"goupille","word_nosc":"goupille","lemma":"goupiller","pos":"VER"} ,
		{"word":"goupiller","word_nosc":"goupiller","lemma":"goupiller","pos":"VER"} ,
		{"word":"goupilles","word_nosc":"goupilles","lemma":"goupiller","pos":"VER"} ,
		{"word":"goupillé","word_nosc":"goupille","lemma":"goupiller","pos":"VER"} ,
		{"word":"goupillée","word_nosc":"goupillee","lemma":"goupiller","pos":"VER"} ,
		{"word":"gouraient","word_nosc":"gouraient","lemma":"gourer","pos":"VER"} ,
		{"word":"gourais","word_nosc":"gourais","lemma":"gourer","pos":"VER"} ,
		{"word":"gourait","word_nosc":"gourait","lemma":"gourer","pos":"VER"} ,
		{"word":"goure","word_nosc":"goure","lemma":"gourer","pos":"VER"} ,
		{"word":"gourent","word_nosc":"gourent","lemma":"gourer","pos":"VER"} ,
		{"word":"gourer","word_nosc":"gourer","lemma":"gourer","pos":"VER"} ,
		{"word":"gourerait","word_nosc":"gourerait","lemma":"gourer","pos":"VER"} ,
		{"word":"goures","word_nosc":"goures","lemma":"gourer","pos":"VER"} ,
		{"word":"gourez","word_nosc":"gourez","lemma":"gourer","pos":"VER"} ,
		{"word":"gourmandai","word_nosc":"gourmandai","lemma":"gourmander","pos":"VER"} ,
		{"word":"gourmandais","word_nosc":"gourmandais","lemma":"gourmander","pos":"VER"} ,
		{"word":"gourmandait","word_nosc":"gourmandait","lemma":"gourmander","pos":"VER"} ,
		{"word":"gourmandant","word_nosc":"gourmandant","lemma":"gourmander","pos":"VER"} ,
		{"word":"gourmande","word_nosc":"gourmande","lemma":"gourmander","pos":"VER"} ,
		{"word":"gourmandent","word_nosc":"gourmandent","lemma":"gourmander","pos":"VER"} ,
		{"word":"gourmander","word_nosc":"gourmander","lemma":"gourmander","pos":"VER"} ,
		{"word":"gourmandé","word_nosc":"gourmande","lemma":"gourmander","pos":"VER"} ,
		{"word":"gourmé","word_nosc":"gourme","lemma":"gourmer","pos":"VER"} ,
		{"word":"gouré","word_nosc":"goure","lemma":"gourer","pos":"VER"} ,
		{"word":"gourée","word_nosc":"gouree","lemma":"gourer","pos":"VER"} ,
		{"word":"gourées","word_nosc":"gourees","lemma":"gourer","pos":"VER"} ,
		{"word":"gourés","word_nosc":"goures","lemma":"gourer","pos":"VER"} ,
		{"word":"gouttait","word_nosc":"gouttait","lemma":"goutter","pos":"VER"} ,
		{"word":"gouttant","word_nosc":"gouttant","lemma":"goutter","pos":"VER"} ,
		{"word":"goutte","word_nosc":"goutte","lemma":"goutter","pos":"VER"} ,
		{"word":"gouttelaient","word_nosc":"gouttelaient","lemma":"goutteler","pos":"VER"} ,
		{"word":"gouttelait","word_nosc":"gouttelait","lemma":"goutteler","pos":"VER"} ,
		{"word":"goutter","word_nosc":"goutter","lemma":"goutter","pos":"VER"} ,
		{"word":"gouttera","word_nosc":"gouttera","lemma":"goutter","pos":"VER"} ,
		{"word":"goutterais","word_nosc":"goutterais","lemma":"goutter","pos":"VER"} ,
		{"word":"gouttes","word_nosc":"gouttes","lemma":"goutter","pos":"VER"} ,
		{"word":"gouvernaient","word_nosc":"gouvernaient","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouvernait","word_nosc":"gouvernait","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouvernant","word_nosc":"gouvernant","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouverne","word_nosc":"gouverne","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouvernent","word_nosc":"gouvernent","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouverner","word_nosc":"gouverner","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouvernera","word_nosc":"gouvernera","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouvernerai","word_nosc":"gouvernerai","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouvernerait","word_nosc":"gouvernerait","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouverneras","word_nosc":"gouverneras","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouvernerez","word_nosc":"gouvernerez","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouvernerons","word_nosc":"gouvernerons","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouverneront","word_nosc":"gouverneront","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouvernes","word_nosc":"gouvernes","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouvernez","word_nosc":"gouvernez","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouverniez","word_nosc":"gouverniez","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouvernât","word_nosc":"gouvernat","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouverné","word_nosc":"gouverne","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouvernée","word_nosc":"gouvernee","lemma":"gouverner","pos":"VER"} ,
		{"word":"gouvernés","word_nosc":"gouvernes","lemma":"gouverner","pos":"VER"} ,
		{"word":"goûta","word_nosc":"gouta","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtai","word_nosc":"goutai","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtaient","word_nosc":"goutaient","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtais","word_nosc":"goutais","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtait","word_nosc":"goutait","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtant","word_nosc":"goutant","lemma":"goûter","pos":"VER"} ,
		{"word":"goûte","word_nosc":"goute","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtent","word_nosc":"goutent","lemma":"goûter","pos":"VER"} ,
		{"word":"goûter","word_nosc":"gouter","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtera","word_nosc":"goutera","lemma":"goûter","pos":"VER"} ,
		{"word":"goûterai","word_nosc":"gouterai","lemma":"goûter","pos":"VER"} ,
		{"word":"goûterais","word_nosc":"gouterais","lemma":"goûter","pos":"VER"} ,
		{"word":"goûterait","word_nosc":"gouterait","lemma":"goûter","pos":"VER"} ,
		{"word":"goûteras","word_nosc":"gouteras","lemma":"goûter","pos":"VER"} ,
		{"word":"goûterez","word_nosc":"gouterez","lemma":"goûter","pos":"VER"} ,
		{"word":"goûterons","word_nosc":"gouterons","lemma":"goûter","pos":"VER"} ,
		{"word":"goûteront","word_nosc":"gouteront","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtes","word_nosc":"goutes","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtez","word_nosc":"goutez","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtiez","word_nosc":"goutiez","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtions","word_nosc":"goutions","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtons","word_nosc":"goutons","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtâmes","word_nosc":"goutames","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtèrent","word_nosc":"gouterent","lemma":"goûter","pos":"VER"} ,
		{"word":"goûté","word_nosc":"goute","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtée","word_nosc":"goutee","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtées","word_nosc":"goutees","lemma":"goûter","pos":"VER"} ,
		{"word":"goûtés","word_nosc":"goutes","lemma":"goûter","pos":"VER"} ,
		{"word":"gracia","word_nosc":"gracia","lemma":"gracier","pos":"VER"} ,
		{"word":"graciai","word_nosc":"graciai","lemma":"gracier","pos":"VER"} ,
		{"word":"gracias","word_nosc":"gracias","lemma":"gracier","pos":"VER"} ,
		{"word":"gracie","word_nosc":"gracie","lemma":"gracier","pos":"VER"} ,
		{"word":"gracier","word_nosc":"gracier","lemma":"gracier","pos":"VER"} ,
		{"word":"graciera","word_nosc":"graciera","lemma":"gracier","pos":"VER"} ,
		{"word":"gracierai","word_nosc":"gracierai","lemma":"gracier","pos":"VER"} ,
		{"word":"gracieras","word_nosc":"gracieras","lemma":"gracier","pos":"VER"} ,
		{"word":"graciez","word_nosc":"graciez","lemma":"gracier","pos":"VER"} ,
		{"word":"graciât","word_nosc":"graciat","lemma":"gracier","pos":"VER"} ,
		{"word":"gracié","word_nosc":"gracie","lemma":"gracier","pos":"VER"} ,
		{"word":"graciée","word_nosc":"graciee","lemma":"gracier","pos":"VER"} ,
		{"word":"graciés","word_nosc":"gracies","lemma":"gracier","pos":"VER"} ,
		{"word":"graduer","word_nosc":"graduer","lemma":"graduer","pos":"VER"} ,
		{"word":"gradué","word_nosc":"gradue","lemma":"graduer","pos":"VER"} ,
		{"word":"graduée","word_nosc":"graduee","lemma":"graduer","pos":"VER"} ,
		{"word":"graduées","word_nosc":"graduees","lemma":"graduer","pos":"VER"} ,
		{"word":"gradués","word_nosc":"gradues","lemma":"graduer","pos":"VER"} ,
		{"word":"graffita","word_nosc":"graffita","lemma":"graffiter","pos":"VER"} ,
		{"word":"graffiter","word_nosc":"graffiter","lemma":"graffiter","pos":"VER"} ,
		{"word":"graffité","word_nosc":"graffite","lemma":"graffiter","pos":"VER"} ,
		{"word":"grafigner","word_nosc":"grafigner","lemma":"grafigner","pos":"VER"} ,
		{"word":"graillant","word_nosc":"graillant","lemma":"grailler","pos":"VER"} ,
		{"word":"graille","word_nosc":"graille","lemma":"grailler","pos":"VER"} ,
		{"word":"grailler","word_nosc":"grailler","lemma":"grailler","pos":"VER"} ,
		{"word":"graillonna","word_nosc":"graillonna","lemma":"graillonner","pos":"VER"} ,
		{"word":"graillonnait","word_nosc":"graillonnait","lemma":"graillonner","pos":"VER"} ,
		{"word":"graillonne","word_nosc":"graillonne","lemma":"graillonner","pos":"VER"} ,
		{"word":"graillé","word_nosc":"graille","lemma":"grailler","pos":"VER"} ,
		{"word":"graillée","word_nosc":"graillee","lemma":"grailler","pos":"VER"} ,
		{"word":"grainer","word_nosc":"grainer","lemma":"grainer","pos":"VER"} ,
		{"word":"grainé","word_nosc":"graine","lemma":"grainer","pos":"VER"} ,
		{"word":"graissa","word_nosc":"graissa","lemma":"graisser","pos":"VER"} ,
		{"word":"graissait","word_nosc":"graissait","lemma":"graisser","pos":"VER"} ,
		{"word":"graissant","word_nosc":"graissant","lemma":"graisser","pos":"VER"} ,
		{"word":"graisse","word_nosc":"graisse","lemma":"graisser","pos":"VER"} ,
		{"word":"graissent","word_nosc":"graissent","lemma":"graisser","pos":"VER"} ,
		{"word":"graisser","word_nosc":"graisser","lemma":"graisser","pos":"VER"} ,
		{"word":"graissera","word_nosc":"graissera","lemma":"graisser","pos":"VER"} ,
		{"word":"graisserai","word_nosc":"graisserai","lemma":"graisser","pos":"VER"} ,
		{"word":"graisses","word_nosc":"graisses","lemma":"graisser","pos":"VER"} ,
		{"word":"graissez","word_nosc":"graissez","lemma":"graisser","pos":"VER"} ,
		{"word":"graissât","word_nosc":"graissat","lemma":"graisser","pos":"VER"} ,
		{"word":"graissé","word_nosc":"graisse","lemma":"graisser","pos":"VER"} ,
		{"word":"graissée","word_nosc":"graissee","lemma":"graisser","pos":"VER"} ,
		{"word":"graissées","word_nosc":"graissees","lemma":"graisser","pos":"VER"} ,
		{"word":"graissés","word_nosc":"graisses","lemma":"graisser","pos":"VER"} ,
		{"word":"grammer","word_nosc":"grammer","lemma":"grammer","pos":"VER"} ,
		{"word":"grandi","word_nosc":"grandi","lemma":"grandir","pos":"VER"} ,
		{"word":"grandie","word_nosc":"grandie","lemma":"grandir","pos":"VER"} ,
		{"word":"grandir","word_nosc":"grandir","lemma":"grandir","pos":"VER"} ,
		{"word":"grandira","word_nosc":"grandira","lemma":"grandir","pos":"VER"} ,
		{"word":"grandirai","word_nosc":"grandirai","lemma":"grandir","pos":"VER"} ,
		{"word":"grandiraient","word_nosc":"grandiraient","lemma":"grandir","pos":"VER"} ,
		{"word":"grandirais","word_nosc":"grandirais","lemma":"grandir","pos":"VER"} ,
		{"word":"grandirait","word_nosc":"grandirait","lemma":"grandir","pos":"VER"} ,
		{"word":"grandiras","word_nosc":"grandiras","lemma":"grandir","pos":"VER"} ,
		{"word":"grandirent","word_nosc":"grandirent","lemma":"grandir","pos":"VER"} ,
		{"word":"grandirez","word_nosc":"grandirez","lemma":"grandir","pos":"VER"} ,
		{"word":"grandiriez","word_nosc":"grandiriez","lemma":"grandir","pos":"VER"} ,
		{"word":"grandirons","word_nosc":"grandirons","lemma":"grandir","pos":"VER"} ,
		{"word":"grandiront","word_nosc":"grandiront","lemma":"grandir","pos":"VER"} ,
		{"word":"grandis","word_nosc":"grandis","lemma":"grandir","pos":"VER"} ,
		{"word":"grandissaient","word_nosc":"grandissaient","lemma":"grandir","pos":"VER"} ,
		{"word":"grandissais","word_nosc":"grandissais","lemma":"grandir","pos":"VER"} ,
		{"word":"grandissait","word_nosc":"grandissait","lemma":"grandir","pos":"VER"} ,
		{"word":"grandissant","word_nosc":"grandissant","lemma":"grandir","pos":"VER"} ,
		{"word":"grandisse","word_nosc":"grandisse","lemma":"grandir","pos":"VER"} ,
		{"word":"grandissent","word_nosc":"grandissent","lemma":"grandir","pos":"VER"} ,
		{"word":"grandisses","word_nosc":"grandisses","lemma":"grandir","pos":"VER"} ,
		{"word":"grandissez","word_nosc":"grandissez","lemma":"grandir","pos":"VER"} ,
		{"word":"grandissions","word_nosc":"grandissions","lemma":"grandir","pos":"VER"} ,
		{"word":"grandissons","word_nosc":"grandissons","lemma":"grandir","pos":"VER"} ,
		{"word":"grandit","word_nosc":"grandit","lemma":"grandir","pos":"VER"} ,
		{"word":"grandît","word_nosc":"grandit","lemma":"grandir","pos":"VER"} ,
		{"word":"granita","word_nosc":"granita","lemma":"graniter","pos":"VER"} ,
		{"word":"granitée","word_nosc":"granitee","lemma":"graniter","pos":"VER"} ,
		{"word":"granulait","word_nosc":"granulait","lemma":"granuler","pos":"VER"} ,
		{"word":"granule","word_nosc":"granule","lemma":"granuler","pos":"VER"} ,
		{"word":"granulée","word_nosc":"granulee","lemma":"granuler","pos":"VER"} ,
		{"word":"grappillais","word_nosc":"grappillais","lemma":"grappiller","pos":"VER"} ,
		{"word":"grappillait","word_nosc":"grappillait","lemma":"grappiller","pos":"VER"} ,
		{"word":"grappillent","word_nosc":"grappillent","lemma":"grappiller","pos":"VER"} ,
		{"word":"grappiller","word_nosc":"grappiller","lemma":"grappiller","pos":"VER"} ,
		{"word":"grappillé","word_nosc":"grappille","lemma":"grappiller","pos":"VER"} ,
		{"word":"grappillées","word_nosc":"grappillees","lemma":"grappiller","pos":"VER"} ,
		{"word":"grasseya","word_nosc":"grasseya","lemma":"grasseyer","pos":"VER"} ,
		{"word":"grasseyait","word_nosc":"grasseyait","lemma":"grasseyer","pos":"VER"} ,
		{"word":"grasseyant","word_nosc":"grasseyant","lemma":"grasseyer","pos":"VER"} ,
		{"word":"grasseyer","word_nosc":"grasseyer","lemma":"grasseyer","pos":"VER"} ,
		{"word":"grasseyé","word_nosc":"grasseye","lemma":"grasseyer","pos":"VER"} ,
		{"word":"gratifia","word_nosc":"gratifia","lemma":"gratifier","pos":"VER"} ,
		{"word":"gratifiaient","word_nosc":"gratifiaient","lemma":"gratifier","pos":"VER"} ,
		{"word":"gratifiait","word_nosc":"gratifiait","lemma":"gratifier","pos":"VER"} ,
		{"word":"gratifiant","word_nosc":"gratifiant","lemma":"gratifier","pos":"VER"} ,
		{"word":"gratifie","word_nosc":"gratifie","lemma":"gratifier","pos":"VER"} ,
		{"word":"gratifier","word_nosc":"gratifier","lemma":"gratifier","pos":"VER"} ,
		{"word":"gratifierait","word_nosc":"gratifierait","lemma":"gratifier","pos":"VER"} ,
		{"word":"gratifies","word_nosc":"gratifies","lemma":"gratifier","pos":"VER"} ,
		{"word":"gratifiez","word_nosc":"gratifiez","lemma":"gratifier","pos":"VER"} ,
		{"word":"gratifièrent","word_nosc":"gratifierent","lemma":"gratifier","pos":"VER"} ,
		{"word":"gratifié","word_nosc":"gratifie","lemma":"gratifier","pos":"VER"} ,
		{"word":"gratifiée","word_nosc":"gratifiee","lemma":"gratifier","pos":"VER"} ,
		{"word":"gratifiés","word_nosc":"gratifies","lemma":"gratifier","pos":"VER"} ,
		{"word":"gratiner","word_nosc":"gratiner","lemma":"gratiner","pos":"VER"} ,
		{"word":"gratiné","word_nosc":"gratine","lemma":"gratiner","pos":"VER"} ,
		{"word":"gratinée","word_nosc":"gratinee","lemma":"gratiner","pos":"VER"} ,
		{"word":"gratinées","word_nosc":"gratinees","lemma":"gratiner","pos":"VER"} ,
		{"word":"gratinés","word_nosc":"gratines","lemma":"gratiner","pos":"VER"} ,
		{"word":"gratouillait","word_nosc":"gratouillait","lemma":"gratouiller","pos":"VER"} ,
		{"word":"gratouillant","word_nosc":"gratouillant","lemma":"gratouiller","pos":"VER"} ,
		{"word":"gratouille","word_nosc":"gratouille","lemma":"gratouiller","pos":"VER"} ,
		{"word":"gratouillerait","word_nosc":"gratouillerait","lemma":"gratouiller","pos":"VER"} ,
		{"word":"gratta","word_nosc":"gratta","lemma":"gratter","pos":"VER"} ,
		{"word":"grattai","word_nosc":"grattai","lemma":"gratter","pos":"VER"} ,
		{"word":"grattaient","word_nosc":"grattaient","lemma":"gratter","pos":"VER"} ,
		{"word":"grattais","word_nosc":"grattais","lemma":"gratter","pos":"VER"} ,
		{"word":"grattait","word_nosc":"grattait","lemma":"gratter","pos":"VER"} ,
		{"word":"grattant","word_nosc":"grattant","lemma":"gratter","pos":"VER"} ,
		{"word":"gratte","word_nosc":"gratte","lemma":"gratter","pos":"VER"} ,
		{"word":"grattent","word_nosc":"grattent","lemma":"gratter","pos":"VER"} ,
		{"word":"gratter","word_nosc":"gratter","lemma":"gratter","pos":"VER"} ,
		{"word":"grattera","word_nosc":"grattera","lemma":"gratter","pos":"VER"} ,
		{"word":"gratterai","word_nosc":"gratterai","lemma":"gratter","pos":"VER"} ,
		{"word":"gratteraient","word_nosc":"gratteraient","lemma":"gratter","pos":"VER"} ,
		{"word":"gratterait","word_nosc":"gratterait","lemma":"gratter","pos":"VER"} ,
		{"word":"gratterez","word_nosc":"gratterez","lemma":"gratter","pos":"VER"} ,
		{"word":"grattes","word_nosc":"grattes","lemma":"gratter","pos":"VER"} ,
		{"word":"grattez","word_nosc":"grattez","lemma":"gratter","pos":"VER"} ,
		{"word":"grattons","word_nosc":"grattons","lemma":"gratter","pos":"VER"} ,
		{"word":"grattouillant","word_nosc":"grattouillant","lemma":"grattouiller","pos":"VER"} ,
		{"word":"grattouillent","word_nosc":"grattouillent","lemma":"grattouiller","pos":"VER"} ,
		{"word":"grattouiller","word_nosc":"grattouiller","lemma":"grattouiller","pos":"VER"} ,
		{"word":"grattouilles","word_nosc":"grattouilles","lemma":"grattouiller","pos":"VER"} ,
		{"word":"grattouillé","word_nosc":"grattouille","lemma":"grattouiller","pos":"VER"} ,
		{"word":"grattât","word_nosc":"grattat","lemma":"gratter","pos":"VER"} ,
		{"word":"gratté","word_nosc":"gratte","lemma":"gratter","pos":"VER"} ,
		{"word":"grattée","word_nosc":"grattee","lemma":"gratter","pos":"VER"} ,
		{"word":"grattées","word_nosc":"grattees","lemma":"gratter","pos":"VER"} ,
		{"word":"grattés","word_nosc":"grattes","lemma":"gratter","pos":"VER"} ,
		{"word":"grava","word_nosc":"grava","lemma":"graver","pos":"VER"} ,
		{"word":"gravai","word_nosc":"gravai","lemma":"graver","pos":"VER"} ,
		{"word":"gravaient","word_nosc":"gravaient","lemma":"graver","pos":"VER"} ,
		{"word":"gravais","word_nosc":"gravais","lemma":"graver","pos":"VER"} ,
		{"word":"gravait","word_nosc":"gravait","lemma":"graver","pos":"VER"} ,
		{"word":"gravant","word_nosc":"gravant","lemma":"graver","pos":"VER"} ,
		{"word":"grave","word_nosc":"grave","lemma":"graver","pos":"VER"} ,
		{"word":"gravelé","word_nosc":"gravele","lemma":"graveler","pos":"VER"} ,
		{"word":"gravelés","word_nosc":"graveles","lemma":"graveler","pos":"VER"} ,
		{"word":"gravent","word_nosc":"gravent","lemma":"graver","pos":"VER"} ,
		{"word":"graver","word_nosc":"graver","lemma":"graver","pos":"VER"} ,
		{"word":"graveraient","word_nosc":"graveraient","lemma":"graver","pos":"VER"} ,
		{"word":"graverais","word_nosc":"graverais","lemma":"graver","pos":"VER"} ,
		{"word":"graverait","word_nosc":"graverait","lemma":"graver","pos":"VER"} ,
		{"word":"graves","word_nosc":"graves","lemma":"graver","pos":"VER"} ,
		{"word":"gravez","word_nosc":"gravez","lemma":"graver","pos":"VER"} ,
		{"word":"gravi","word_nosc":"gravi","lemma":"gravir","pos":"VER"} ,
		{"word":"gravie","word_nosc":"gravie","lemma":"gravir","pos":"VER"} ,
		{"word":"gravies","word_nosc":"gravies","lemma":"gravir","pos":"VER"} ,
		{"word":"gravillonnée","word_nosc":"gravillonnee","lemma":"gravillonner","pos":"VER"} ,
		{"word":"gravillonnées","word_nosc":"gravillonnees","lemma":"gravillonner","pos":"VER"} ,
		{"word":"gravir","word_nosc":"gravir","lemma":"gravir","pos":"VER"} ,
		{"word":"gravirai","word_nosc":"gravirai","lemma":"gravir","pos":"VER"} ,
		{"word":"gravirait","word_nosc":"gravirait","lemma":"gravir","pos":"VER"} ,
		{"word":"gravirent","word_nosc":"gravirent","lemma":"gravir","pos":"VER"} ,
		{"word":"gravirons","word_nosc":"gravirons","lemma":"gravir","pos":"VER"} ,
		{"word":"gravis","word_nosc":"gravis","lemma":"gravir","pos":"VER"} ,
		{"word":"gravissaient","word_nosc":"gravissaient","lemma":"gravir","pos":"VER"} ,
		{"word":"gravissais","word_nosc":"gravissais","lemma":"gravir","pos":"VER"} ,
		{"word":"gravissait","word_nosc":"gravissait","lemma":"gravir","pos":"VER"} ,
		{"word":"gravissant","word_nosc":"gravissant","lemma":"gravir","pos":"VER"} ,
		{"word":"gravisse","word_nosc":"gravisse","lemma":"gravir","pos":"VER"} ,
		{"word":"gravissent","word_nosc":"gravissent","lemma":"gravir","pos":"VER"} ,
		{"word":"gravissions","word_nosc":"gravissions","lemma":"gravir","pos":"VER"} ,
		{"word":"gravissons","word_nosc":"gravissons","lemma":"gravir","pos":"VER"} ,
		{"word":"gravit","word_nosc":"gravit","lemma":"gravir","pos":"VER"} ,
		{"word":"gravitaient","word_nosc":"gravitaient","lemma":"graviter","pos":"VER"} ,
		{"word":"gravitait","word_nosc":"gravitait","lemma":"graviter","pos":"VER"} ,
		{"word":"gravitant","word_nosc":"gravitant","lemma":"graviter","pos":"VER"} ,
		{"word":"gravite","word_nosc":"gravite","lemma":"graviter","pos":"VER"} ,
		{"word":"graviter","word_nosc":"graviter","lemma":"graviter","pos":"VER"} ,
		{"word":"gravitons","word_nosc":"gravitons","lemma":"graviter","pos":"VER"} ,
		{"word":"gravité","word_nosc":"gravite","lemma":"graviter","pos":"VER"} ,
		{"word":"gravons","word_nosc":"gravons","lemma":"graver","pos":"VER"} ,
		{"word":"gravât","word_nosc":"gravat","lemma":"graver","pos":"VER"} ,
		{"word":"gravèrent","word_nosc":"graverent","lemma":"graver","pos":"VER"} ,
		{"word":"gravé","word_nosc":"grave","lemma":"graver","pos":"VER"} ,
		{"word":"gravée","word_nosc":"gravee","lemma":"graver","pos":"VER"} ,
		{"word":"gravées","word_nosc":"gravees","lemma":"graver","pos":"VER"} ,
		{"word":"gravés","word_nosc":"graves","lemma":"graver","pos":"VER"} ,
		{"word":"greffaient","word_nosc":"greffaient","lemma":"greffer","pos":"VER"} ,
		{"word":"greffais","word_nosc":"greffais","lemma":"greffer","pos":"VER"} ,
		{"word":"greffait","word_nosc":"greffait","lemma":"greffer","pos":"VER"} ,
		{"word":"greffant","word_nosc":"greffant","lemma":"greffer","pos":"VER"} ,
		{"word":"greffe","word_nosc":"greffe","lemma":"greffer","pos":"VER"} ,
		{"word":"greffent","word_nosc":"greffent","lemma":"greffer","pos":"VER"} ,
		{"word":"greffer","word_nosc":"greffer","lemma":"greffer","pos":"VER"} ,
		{"word":"greffera","word_nosc":"greffera","lemma":"greffer","pos":"VER"} ,
		{"word":"grefferais","word_nosc":"grefferais","lemma":"greffer","pos":"VER"} ,
		{"word":"grefferons","word_nosc":"grefferons","lemma":"greffer","pos":"VER"} ,
		{"word":"grefferont","word_nosc":"grefferont","lemma":"greffer","pos":"VER"} ,
		{"word":"greffé","word_nosc":"greffe","lemma":"greffer","pos":"VER"} ,
		{"word":"greffée","word_nosc":"greffee","lemma":"greffer","pos":"VER"} ,
		{"word":"greffées","word_nosc":"greffees","lemma":"greffer","pos":"VER"} ,
		{"word":"greffés","word_nosc":"greffes","lemma":"greffer","pos":"VER"} ,
		{"word":"grelotta","word_nosc":"grelotta","lemma":"grelotter","pos":"VER"} ,
		{"word":"grelottaient","word_nosc":"grelottaient","lemma":"grelotter","pos":"VER"} ,
		{"word":"grelottais","word_nosc":"grelottais","lemma":"grelotter","pos":"VER"} ,
		{"word":"grelottait","word_nosc":"grelottait","lemma":"grelotter","pos":"VER"} ,
		{"word":"grelottant","word_nosc":"grelottant","lemma":"grelotter","pos":"VER"} ,
		{"word":"grelotte","word_nosc":"grelotte","lemma":"grelotter","pos":"VER"} ,
		{"word":"grelottent","word_nosc":"grelottent","lemma":"grelotter","pos":"VER"} ,
		{"word":"grelotter","word_nosc":"grelotter","lemma":"grelotter","pos":"VER"} ,
		{"word":"grelottes","word_nosc":"grelottes","lemma":"grelotter","pos":"VER"} ,
		{"word":"grelottions","word_nosc":"grelottions","lemma":"grelotter","pos":"VER"} ,
		{"word":"grelottons","word_nosc":"grelottons","lemma":"grelotter","pos":"VER"} ,
		{"word":"grelottèrent","word_nosc":"grelotterent","lemma":"grelotter","pos":"VER"} ,
		{"word":"grelotté","word_nosc":"grelotte","lemma":"grelotter","pos":"VER"} ,
		{"word":"grelottée","word_nosc":"grelottee","lemma":"grelotter","pos":"VER"} ,
		{"word":"grenader","word_nosc":"grenader","lemma":"grenader","pos":"VER"} ,
		{"word":"grenadé","word_nosc":"grenade","lemma":"grenader","pos":"VER"} ,
		{"word":"grenelle","word_nosc":"grenelle","lemma":"greneler","pos":"VER"} ,
		{"word":"greneta","word_nosc":"greneta","lemma":"greneter","pos":"VER"} ,
		{"word":"grenouillait","word_nosc":"grenouillait","lemma":"grenouiller","pos":"VER"} ,
		{"word":"grené","word_nosc":"grene","lemma":"grener","pos":"VER"} ,
		{"word":"grenée","word_nosc":"grenee","lemma":"grener","pos":"VER"} ,
		{"word":"grenées","word_nosc":"grenees","lemma":"grener","pos":"VER"} ,
		{"word":"grevaient","word_nosc":"grevaient","lemma":"grever","pos":"VER"} ,
		{"word":"grever","word_nosc":"grever","lemma":"grever","pos":"VER"} ,
		{"word":"grevé","word_nosc":"greve","lemma":"grever","pos":"VER"} ,
		{"word":"grevée","word_nosc":"grevee","lemma":"grever","pos":"VER"} ,
		{"word":"grevés","word_nosc":"greves","lemma":"grever","pos":"VER"} ,
		{"word":"gribouilla","word_nosc":"gribouilla","lemma":"gribouiller","pos":"VER"} ,
		{"word":"gribouillais","word_nosc":"gribouillais","lemma":"gribouiller","pos":"VER"} ,
		{"word":"gribouillait","word_nosc":"gribouillait","lemma":"gribouiller","pos":"VER"} ,
		{"word":"gribouillant","word_nosc":"gribouillant","lemma":"gribouiller","pos":"VER"} ,
		{"word":"gribouille","word_nosc":"gribouille","lemma":"gribouiller","pos":"VER"} ,
		{"word":"gribouillent","word_nosc":"gribouillent","lemma":"gribouiller","pos":"VER"} ,
		{"word":"gribouiller","word_nosc":"gribouiller","lemma":"gribouiller","pos":"VER"} ,
		{"word":"gribouilles","word_nosc":"gribouilles","lemma":"gribouiller","pos":"VER"} ,
		{"word":"gribouillez","word_nosc":"gribouillez","lemma":"gribouiller","pos":"VER"} ,
		{"word":"gribouillé","word_nosc":"gribouille","lemma":"gribouiller","pos":"VER"} ,
		{"word":"gribouillée","word_nosc":"gribouillee","lemma":"gribouiller","pos":"VER"} ,
		{"word":"gribouillées","word_nosc":"gribouillees","lemma":"gribouiller","pos":"VER"} ,
		{"word":"gribouillés","word_nosc":"gribouilles","lemma":"gribouiller","pos":"VER"} ,
		{"word":"griffa","word_nosc":"griffa","lemma":"griffer","pos":"VER"} ,
		{"word":"griffai","word_nosc":"griffai","lemma":"griffer","pos":"VER"} ,
		{"word":"griffaient","word_nosc":"griffaient","lemma":"griffer","pos":"VER"} ,
		{"word":"griffais","word_nosc":"griffais","lemma":"griffer","pos":"VER"} ,
		{"word":"griffait","word_nosc":"griffait","lemma":"griffer","pos":"VER"} ,
		{"word":"griffant","word_nosc":"griffant","lemma":"griffer","pos":"VER"} ,
		{"word":"griffe","word_nosc":"griffe","lemma":"griffer","pos":"VER"} ,
		{"word":"griffent","word_nosc":"griffent","lemma":"griffer","pos":"VER"} ,
		{"word":"griffer","word_nosc":"griffer","lemma":"griffer","pos":"VER"} ,
		{"word":"griffera","word_nosc":"griffera","lemma":"griffer","pos":"VER"} ,
		{"word":"grifferait","word_nosc":"grifferait","lemma":"griffer","pos":"VER"} ,
		{"word":"griffes","word_nosc":"griffes","lemma":"griffer","pos":"VER"} ,
		{"word":"griffonna","word_nosc":"griffonna","lemma":"griffonner","pos":"VER"} ,
		{"word":"griffonnai","word_nosc":"griffonnai","lemma":"griffonner","pos":"VER"} ,
		{"word":"griffonnaient","word_nosc":"griffonnaient","lemma":"griffonner","pos":"VER"} ,
		{"word":"griffonnais","word_nosc":"griffonnais","lemma":"griffonner","pos":"VER"} ,
		{"word":"griffonnait","word_nosc":"griffonnait","lemma":"griffonner","pos":"VER"} ,
		{"word":"griffonnant","word_nosc":"griffonnant","lemma":"griffonner","pos":"VER"} ,
		{"word":"griffonne","word_nosc":"griffonne","lemma":"griffonner","pos":"VER"} ,
		{"word":"griffonnent","word_nosc":"griffonnent","lemma":"griffonner","pos":"VER"} ,
		{"word":"griffonner","word_nosc":"griffonner","lemma":"griffonner","pos":"VER"} ,
		{"word":"griffonnes","word_nosc":"griffonnes","lemma":"griffonner","pos":"VER"} ,
		{"word":"griffonnez","word_nosc":"griffonnez","lemma":"griffonner","pos":"VER"} ,
		{"word":"griffonné","word_nosc":"griffonne","lemma":"griffonner","pos":"VER"} ,
		{"word":"griffonnée","word_nosc":"griffonnee","lemma":"griffonner","pos":"VER"} ,
		{"word":"griffonnées","word_nosc":"griffonnees","lemma":"griffonner","pos":"VER"} ,
		{"word":"griffonnés","word_nosc":"griffonnes","lemma":"griffonner","pos":"VER"} ,
		{"word":"griffons","word_nosc":"griffons","lemma":"griffer","pos":"VER"} ,
		{"word":"griffé","word_nosc":"griffe","lemma":"griffer","pos":"VER"} ,
		{"word":"griffée","word_nosc":"griffee","lemma":"griffer","pos":"VER"} ,
		{"word":"griffées","word_nosc":"griffees","lemma":"griffer","pos":"VER"} ,
		{"word":"griffés","word_nosc":"griffes","lemma":"griffer","pos":"VER"} ,
		{"word":"grignota","word_nosc":"grignota","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignotaient","word_nosc":"grignotaient","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignotais","word_nosc":"grignotais","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignotait","word_nosc":"grignotait","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignotant","word_nosc":"grignotant","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignote","word_nosc":"grignote","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignotent","word_nosc":"grignotent","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignoter","word_nosc":"grignoter","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignotera","word_nosc":"grignotera","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignoterai","word_nosc":"grignoterai","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignoteraient","word_nosc":"grignoteraient","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignoterons","word_nosc":"grignoterons","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignoteront","word_nosc":"grignoteront","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignotions","word_nosc":"grignotions","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignotons","word_nosc":"grignotons","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignotèrent","word_nosc":"grignoterent","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignoté","word_nosc":"grignote","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignotée","word_nosc":"grignotee","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignotées","word_nosc":"grignotees","lemma":"grignoter","pos":"VER"} ,
		{"word":"grignotés","word_nosc":"grignotes","lemma":"grignoter","pos":"VER"} ,
		{"word":"grilla","word_nosc":"grilla","lemma":"griller","pos":"VER"} ,
		{"word":"grillageront","word_nosc":"grillageront","lemma":"grillager","pos":"VER"} ,
		{"word":"grillagez","word_nosc":"grillagez","lemma":"grillager","pos":"VER"} ,
		{"word":"grillagé","word_nosc":"grillage","lemma":"grillager","pos":"VER"} ,
		{"word":"grillagée","word_nosc":"grillagee","lemma":"grillager","pos":"VER"} ,
		{"word":"grillagées","word_nosc":"grillagees","lemma":"grillager","pos":"VER"} ,
		{"word":"grillagés","word_nosc":"grillages","lemma":"grillager","pos":"VER"} ,
		{"word":"grillaient","word_nosc":"grillaient","lemma":"griller","pos":"VER"} ,
		{"word":"grillais","word_nosc":"grillais","lemma":"griller","pos":"VER"} ,
		{"word":"grillait","word_nosc":"grillait","lemma":"griller","pos":"VER"} ,
		{"word":"grillant","word_nosc":"grillant","lemma":"griller","pos":"VER"} ,
		{"word":"grille","word_nosc":"grille","lemma":"griller","pos":"VER"} ,
		{"word":"grillent","word_nosc":"grillent","lemma":"griller","pos":"VER"} ,
		{"word":"griller","word_nosc":"griller","lemma":"griller","pos":"VER"} ,
		{"word":"grillera","word_nosc":"grillera","lemma":"griller","pos":"VER"} ,
		{"word":"grillerai","word_nosc":"grillerai","lemma":"griller","pos":"VER"} ,
		{"word":"grilleraient","word_nosc":"grilleraient","lemma":"griller","pos":"VER"} ,
		{"word":"grillerais","word_nosc":"grillerais","lemma":"griller","pos":"VER"} ,
		{"word":"grillerait","word_nosc":"grillerait","lemma":"griller","pos":"VER"} ,
		{"word":"grilleras","word_nosc":"grilleras","lemma":"griller","pos":"VER"} ,
		{"word":"grillerez","word_nosc":"grillerez","lemma":"griller","pos":"VER"} ,
		{"word":"grillerions","word_nosc":"grillerions","lemma":"griller","pos":"VER"} ,
		{"word":"grilleront","word_nosc":"grilleront","lemma":"griller","pos":"VER"} ,
		{"word":"grilles","word_nosc":"grilles","lemma":"griller","pos":"VER"} ,
		{"word":"grillez","word_nosc":"grillez","lemma":"griller","pos":"VER"} ,
		{"word":"grilliez","word_nosc":"grilliez","lemma":"griller","pos":"VER"} ,
		{"word":"grillons","word_nosc":"grillons","lemma":"griller","pos":"VER"} ,
		{"word":"grillé","word_nosc":"grille","lemma":"griller","pos":"VER"} ,
		{"word":"grillée","word_nosc":"grillee","lemma":"griller","pos":"VER"} ,
		{"word":"grillées","word_nosc":"grillees","lemma":"griller","pos":"VER"} ,
		{"word":"grillés","word_nosc":"grilles","lemma":"griller","pos":"VER"} ,
		{"word":"grima","word_nosc":"grima","lemma":"grimer","pos":"VER"} ,
		{"word":"grimace","word_nosc":"grimace","lemma":"grimacer","pos":"VER"} ,
		{"word":"grimacent","word_nosc":"grimacent","lemma":"grimacer","pos":"VER"} ,
		{"word":"grimacer","word_nosc":"grimacer","lemma":"grimacer","pos":"VER"} ,
		{"word":"grimaces","word_nosc":"grimaces","lemma":"grimacer","pos":"VER"} ,
		{"word":"grimacez","word_nosc":"grimacez","lemma":"grimacer","pos":"VER"} ,
		{"word":"grimaciez","word_nosc":"grimaciez","lemma":"grimacer","pos":"VER"} ,
		{"word":"grimacions","word_nosc":"grimacions","lemma":"grimacer","pos":"VER"} ,
		{"word":"grimacèrent","word_nosc":"grimacerent","lemma":"grimacer","pos":"VER"} ,
		{"word":"grimacé","word_nosc":"grimace","lemma":"grimacer","pos":"VER"} ,
		{"word":"grimait","word_nosc":"grimait","lemma":"grimer","pos":"VER"} ,
		{"word":"grimant","word_nosc":"grimant","lemma":"grimer","pos":"VER"} ,
		{"word":"grimasse","word_nosc":"grimasse","lemma":"grimer","pos":"VER"} ,
		{"word":"grimaça","word_nosc":"grimaca","lemma":"grimacer","pos":"VER"} ,
		{"word":"grimaçai","word_nosc":"grimacai","lemma":"grimacer","pos":"VER"} ,
		{"word":"grimaçaient","word_nosc":"grimacaient","lemma":"grimacer","pos":"VER"} ,
		{"word":"grimaçais","word_nosc":"grimacais","lemma":"grimacer","pos":"VER"} ,
		{"word":"grimaçait","word_nosc":"grimacait","lemma":"grimacer","pos":"VER"} ,
		{"word":"grimaçant","word_nosc":"grimacant","lemma":"grimacer","pos":"VER"} ,
		{"word":"grimaçons","word_nosc":"grimacons","lemma":"grimacer","pos":"VER"} ,
		{"word":"griment","word_nosc":"griment","lemma":"grimer","pos":"VER"} ,
		{"word":"grimer","word_nosc":"grimer","lemma":"grimer","pos":"VER"} ,
		{"word":"grimes","word_nosc":"grimes","lemma":"grimer","pos":"VER"} ,
		{"word":"grimpa","word_nosc":"grimpa","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpai","word_nosc":"grimpai","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpaient","word_nosc":"grimpaient","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpais","word_nosc":"grimpais","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpait","word_nosc":"grimpait","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpant","word_nosc":"grimpant","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpe","word_nosc":"grimpe","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpent","word_nosc":"grimpent","lemma":"grimper","pos":"VER"} ,
		{"word":"grimper","word_nosc":"grimper","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpera","word_nosc":"grimpera","lemma":"grimper","pos":"VER"} ,
		{"word":"grimperai","word_nosc":"grimperai","lemma":"grimper","pos":"VER"} ,
		{"word":"grimperaient","word_nosc":"grimperaient","lemma":"grimper","pos":"VER"} ,
		{"word":"grimperais","word_nosc":"grimperais","lemma":"grimper","pos":"VER"} ,
		{"word":"grimperait","word_nosc":"grimperait","lemma":"grimper","pos":"VER"} ,
		{"word":"grimperez","word_nosc":"grimperez","lemma":"grimper","pos":"VER"} ,
		{"word":"grimperons","word_nosc":"grimperons","lemma":"grimper","pos":"VER"} ,
		{"word":"grimperont","word_nosc":"grimperont","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpez","word_nosc":"grimpez","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpiez","word_nosc":"grimpiez","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpions","word_nosc":"grimpions","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpons","word_nosc":"grimpons","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpâmes","word_nosc":"grimpames","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpât","word_nosc":"grimpat","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpèrent","word_nosc":"grimperent","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpé","word_nosc":"grimpe","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpée","word_nosc":"grimpee","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpées","word_nosc":"grimpees","lemma":"grimper","pos":"VER"} ,
		{"word":"grimpés","word_nosc":"grimpes","lemma":"grimper","pos":"VER"} ,
		{"word":"grimé","word_nosc":"grime","lemma":"grimer","pos":"VER"} ,
		{"word":"grimée","word_nosc":"grimee","lemma":"grimer","pos":"VER"} ,
		{"word":"grimées","word_nosc":"grimees","lemma":"grimer","pos":"VER"} ,
		{"word":"grimés","word_nosc":"grimes","lemma":"grimer","pos":"VER"} ,
		{"word":"grince","word_nosc":"grince","lemma":"grincer","pos":"VER"} ,
		{"word":"grincent","word_nosc":"grincent","lemma":"grincer","pos":"VER"} ,
		{"word":"grincer","word_nosc":"grincer","lemma":"grincer","pos":"VER"} ,
		{"word":"grincerait","word_nosc":"grincerait","lemma":"grincer","pos":"VER"} ,
		{"word":"grinces","word_nosc":"grinces","lemma":"grincer","pos":"VER"} ,
		{"word":"grincez","word_nosc":"grincez","lemma":"grincer","pos":"VER"} ,
		{"word":"grinche","word_nosc":"grinche","lemma":"grincher","pos":"VER"} ,
		{"word":"grinchir","word_nosc":"grinchir","lemma":"grinchir","pos":"VER"} ,
		{"word":"grincèrent","word_nosc":"grincerent","lemma":"grincer","pos":"VER"} ,
		{"word":"grincé","word_nosc":"grince","lemma":"grincer","pos":"VER"} ,
		{"word":"gringuer","word_nosc":"gringuer","lemma":"gringuer","pos":"VER"} ,
		{"word":"grinça","word_nosc":"grinca","lemma":"grincer","pos":"VER"} ,
		{"word":"grinçaient","word_nosc":"grincaient","lemma":"grincer","pos":"VER"} ,
		{"word":"grinçais","word_nosc":"grincais","lemma":"grincer","pos":"VER"} ,
		{"word":"grinçait","word_nosc":"grincait","lemma":"grincer","pos":"VER"} ,
		{"word":"grinçant","word_nosc":"grincant","lemma":"grincer","pos":"VER"} ,
		{"word":"grinçât","word_nosc":"grincat","lemma":"grincer","pos":"VER"} ,
		{"word":"grippa","word_nosc":"grippa","lemma":"gripper","pos":"VER"} ,
		{"word":"grippaient","word_nosc":"grippaient","lemma":"gripper","pos":"VER"} ,
		{"word":"grippant","word_nosc":"grippant","lemma":"gripper","pos":"VER"} ,
		{"word":"grippe","word_nosc":"grippe","lemma":"gripper","pos":"VER"} ,
		{"word":"gripper","word_nosc":"gripper","lemma":"gripper","pos":"VER"} ,
		{"word":"gripperait","word_nosc":"gripperait","lemma":"gripper","pos":"VER"} ,
		{"word":"grippé","word_nosc":"grippe","lemma":"gripper","pos":"VER"} ,
		{"word":"grippée","word_nosc":"grippee","lemma":"gripper","pos":"VER"} ,
		{"word":"grippés","word_nosc":"grippes","lemma":"gripper","pos":"VER"} ,
		{"word":"grisa","word_nosc":"grisa","lemma":"griser","pos":"VER"} ,
		{"word":"grisai","word_nosc":"grisai","lemma":"griser","pos":"VER"} ,
		{"word":"grisaient","word_nosc":"grisaient","lemma":"griser","pos":"VER"} ,
		{"word":"grisaillèrent","word_nosc":"grisaillerent","lemma":"grisailler","pos":"VER"} ,
		{"word":"grisais","word_nosc":"grisais","lemma":"griser","pos":"VER"} ,
		{"word":"grisait","word_nosc":"grisait","lemma":"griser","pos":"VER"} ,
		{"word":"grisant","word_nosc":"grisant","lemma":"griser","pos":"VER"} ,
		{"word":"grisassent","word_nosc":"grisassent","lemma":"griser","pos":"VER"} ,
		{"word":"grise","word_nosc":"grise","lemma":"griser","pos":"VER"} ,
		{"word":"grisent","word_nosc":"grisent","lemma":"griser","pos":"VER"} ,
		{"word":"griser","word_nosc":"griser","lemma":"griser","pos":"VER"} ,
		{"word":"grisera","word_nosc":"grisera","lemma":"griser","pos":"VER"} ,
		{"word":"grises","word_nosc":"grises","lemma":"griser","pos":"VER"} ,
		{"word":"grisonnaient","word_nosc":"grisonnaient","lemma":"grisonner","pos":"VER"} ,
		{"word":"grisonnait","word_nosc":"grisonnait","lemma":"grisonner","pos":"VER"} ,
		{"word":"grisonnant","word_nosc":"grisonnant","lemma":"grisonner","pos":"VER"} ,
		{"word":"grisonne","word_nosc":"grisonne","lemma":"grisonner","pos":"VER"} ,
		{"word":"grisonnent","word_nosc":"grisonnent","lemma":"grisonner","pos":"VER"} ,
		{"word":"grisonner","word_nosc":"grisonner","lemma":"grisonner","pos":"VER"} ,
		{"word":"grisonné","word_nosc":"grisonne","lemma":"grisonner","pos":"VER"} ,
		{"word":"grisé","word_nosc":"grise","lemma":"griser","pos":"VER"} ,
		{"word":"grisée","word_nosc":"grisee","lemma":"griser","pos":"VER"} ,
		{"word":"grisées","word_nosc":"grisees","lemma":"griser","pos":"VER"} ,
		{"word":"grisés","word_nosc":"grises","lemma":"griser","pos":"VER"} ,
		{"word":"grivelle","word_nosc":"grivelle","lemma":"griveler","pos":"VER"} ,
		{"word":"grogna","word_nosc":"grogna","lemma":"grogner","pos":"VER"} ,
		{"word":"grognai","word_nosc":"grognai","lemma":"grogner","pos":"VER"} ,
		{"word":"grognaient","word_nosc":"grognaient","lemma":"grogner","pos":"VER"} ,
		{"word":"grognais","word_nosc":"grognais","lemma":"grogner","pos":"VER"} ,
		{"word":"grognait","word_nosc":"grognait","lemma":"grogner","pos":"VER"} ,
		{"word":"grognant","word_nosc":"grognant","lemma":"grogner","pos":"VER"} ,
		{"word":"grognassaient","word_nosc":"grognassaient","lemma":"grognasser","pos":"VER"} ,
		{"word":"grognasser","word_nosc":"grognasser","lemma":"grognasser","pos":"VER"} ,
		{"word":"grogne","word_nosc":"grogne","lemma":"grogner","pos":"VER"} ,
		{"word":"grognent","word_nosc":"grognent","lemma":"grogner","pos":"VER"} ,
		{"word":"grogner","word_nosc":"grogner","lemma":"grogner","pos":"VER"} ,
		{"word":"grognera","word_nosc":"grognera","lemma":"grogner","pos":"VER"} ,
		{"word":"grognes","word_nosc":"grognes","lemma":"grogner","pos":"VER"} ,
		{"word":"grognions","word_nosc":"grognions","lemma":"grogner","pos":"VER"} ,
		{"word":"grognonna","word_nosc":"grognonna","lemma":"grognonner","pos":"VER"} ,
		{"word":"grognonne","word_nosc":"grognonne","lemma":"grognonner","pos":"VER"} ,
		{"word":"grognonner","word_nosc":"grognonner","lemma":"grognonner","pos":"VER"} ,
		{"word":"grognèrent","word_nosc":"grognerent","lemma":"grogner","pos":"VER"} ,
		{"word":"grogné","word_nosc":"grogne","lemma":"grogner","pos":"VER"} ,
		{"word":"grognées","word_nosc":"grognees","lemma":"grogner","pos":"VER"} ,
		{"word":"grommela","word_nosc":"grommela","lemma":"grommeler","pos":"VER"} ,
		{"word":"grommelai","word_nosc":"grommelai","lemma":"grommeler","pos":"VER"} ,
		{"word":"grommelaient","word_nosc":"grommelaient","lemma":"grommeler","pos":"VER"} ,
		{"word":"grommelait","word_nosc":"grommelait","lemma":"grommeler","pos":"VER"} ,
		{"word":"grommelant","word_nosc":"grommelant","lemma":"grommeler","pos":"VER"} ,
		{"word":"grommeler","word_nosc":"grommeler","lemma":"grommeler","pos":"VER"} ,
		{"word":"grommelez","word_nosc":"grommelez","lemma":"grommeler","pos":"VER"} ,
		{"word":"grommelle","word_nosc":"grommelle","lemma":"grommeler","pos":"VER"} ,
		{"word":"grommellent","word_nosc":"grommellent","lemma":"grommeler","pos":"VER"} ,
		{"word":"grommellerait","word_nosc":"grommellerait","lemma":"grommeler","pos":"VER"} ,
		{"word":"grommelèrent","word_nosc":"grommelerent","lemma":"grommeler","pos":"VER"} ,
		{"word":"grommelé","word_nosc":"grommele","lemma":"grommeler","pos":"VER"} ,
		{"word":"grommelée","word_nosc":"grommelee","lemma":"grommeler","pos":"VER"} ,
		{"word":"gronda","word_nosc":"gronda","lemma":"gronder","pos":"VER"} ,
		{"word":"grondai","word_nosc":"grondai","lemma":"gronder","pos":"VER"} ,
		{"word":"grondaient","word_nosc":"grondaient","lemma":"gronder","pos":"VER"} ,
		{"word":"grondais","word_nosc":"grondais","lemma":"gronder","pos":"VER"} ,
		{"word":"grondait","word_nosc":"grondait","lemma":"gronder","pos":"VER"} ,
		{"word":"grondant","word_nosc":"grondant","lemma":"gronder","pos":"VER"} ,
		{"word":"gronde","word_nosc":"gronde","lemma":"gronder","pos":"VER"} ,
		{"word":"grondent","word_nosc":"grondent","lemma":"gronder","pos":"VER"} ,
		{"word":"gronder","word_nosc":"gronder","lemma":"gronder","pos":"VER"} ,
		{"word":"grondera","word_nosc":"grondera","lemma":"gronder","pos":"VER"} ,
		{"word":"gronderai","word_nosc":"gronderai","lemma":"gronder","pos":"VER"} ,
		{"word":"gronderait","word_nosc":"gronderait","lemma":"gronder","pos":"VER"} ,
		{"word":"grondes","word_nosc":"grondes","lemma":"gronder","pos":"VER"} ,
		{"word":"grondez","word_nosc":"grondez","lemma":"gronder","pos":"VER"} ,
		{"word":"grondèrent","word_nosc":"gronderent","lemma":"gronder","pos":"VER"} ,
		{"word":"grondé","word_nosc":"gronde","lemma":"gronder","pos":"VER"} ,
		{"word":"grondée","word_nosc":"grondee","lemma":"gronder","pos":"VER"} ,
		{"word":"grondés","word_nosc":"grondes","lemma":"gronder","pos":"VER"} ,
		{"word":"grossi","word_nosc":"grossi","lemma":"grossir","pos":"VER"} ,
		{"word":"grossie","word_nosc":"grossie","lemma":"grossir","pos":"VER"} ,
		{"word":"grossies","word_nosc":"grossies","lemma":"grossir","pos":"VER"} ,
		{"word":"grossir","word_nosc":"grossir","lemma":"grossir","pos":"VER"} ,
		{"word":"grossira","word_nosc":"grossira","lemma":"grossir","pos":"VER"} ,
		{"word":"grossirent","word_nosc":"grossirent","lemma":"grossir","pos":"VER"} ,
		{"word":"grossirez","word_nosc":"grossirez","lemma":"grossir","pos":"VER"} ,
		{"word":"grossiront","word_nosc":"grossiront","lemma":"grossir","pos":"VER"} ,
		{"word":"grossis","word_nosc":"grossis","lemma":"grossir","pos":"VER"} ,
		{"word":"grossissaient","word_nosc":"grossissaient","lemma":"grossir","pos":"VER"} ,
		{"word":"grossissais","word_nosc":"grossissais","lemma":"grossir","pos":"VER"} ,
		{"word":"grossissait","word_nosc":"grossissait","lemma":"grossir","pos":"VER"} ,
		{"word":"grossissant","word_nosc":"grossissant","lemma":"grossir","pos":"VER"} ,
		{"word":"grossisse","word_nosc":"grossisse","lemma":"grossir","pos":"VER"} ,
		{"word":"grossissent","word_nosc":"grossissent","lemma":"grossir","pos":"VER"} ,
		{"word":"grossisses","word_nosc":"grossisses","lemma":"grossir","pos":"VER"} ,
		{"word":"grossissez","word_nosc":"grossissez","lemma":"grossir","pos":"VER"} ,
		{"word":"grossit","word_nosc":"grossit","lemma":"grossir","pos":"VER"} ,
		{"word":"grouillaient","word_nosc":"grouillaient","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouillais","word_nosc":"grouillais","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouillait","word_nosc":"grouillait","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouillant","word_nosc":"grouillant","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouille","word_nosc":"grouille","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouillent","word_nosc":"grouillent","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouiller","word_nosc":"grouiller","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouillera","word_nosc":"grouillera","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouillerait","word_nosc":"grouillerait","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouilleront","word_nosc":"grouilleront","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouilles","word_nosc":"grouilles","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouillez","word_nosc":"grouillez","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouillons","word_nosc":"grouillons","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouillèrent","word_nosc":"grouillerent","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouillé","word_nosc":"grouille","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouillées","word_nosc":"grouillees","lemma":"grouiller","pos":"VER"} ,
		{"word":"grouiner","word_nosc":"grouiner","lemma":"grouiner","pos":"VER"} ,
		{"word":"groumais","word_nosc":"groumais","lemma":"groumer","pos":"VER"} ,
		{"word":"groumait","word_nosc":"groumait","lemma":"groumer","pos":"VER"} ,
		{"word":"groumant","word_nosc":"groumant","lemma":"groumer","pos":"VER"} ,
		{"word":"groume","word_nosc":"groume","lemma":"groumer","pos":"VER"} ,
		{"word":"groumer","word_nosc":"groumer","lemma":"groumer","pos":"VER"} ,
		{"word":"groupa","word_nosc":"groupa","lemma":"grouper","pos":"VER"} ,
		{"word":"groupaient","word_nosc":"groupaient","lemma":"grouper","pos":"VER"} ,
		{"word":"groupais","word_nosc":"groupais","lemma":"grouper","pos":"VER"} ,
		{"word":"groupait","word_nosc":"groupait","lemma":"grouper","pos":"VER"} ,
		{"word":"groupant","word_nosc":"groupant","lemma":"grouper","pos":"VER"} ,
		{"word":"groupe","word_nosc":"groupe","lemma":"grouper","pos":"VER"} ,
		{"word":"groupent","word_nosc":"groupent","lemma":"grouper","pos":"VER"} ,
		{"word":"grouper","word_nosc":"grouper","lemma":"grouper","pos":"VER"} ,
		{"word":"grouperaient","word_nosc":"grouperaient","lemma":"grouper","pos":"VER"} ,
		{"word":"grouperont","word_nosc":"grouperont","lemma":"grouper","pos":"VER"} ,
		{"word":"groupez","word_nosc":"groupez","lemma":"grouper","pos":"VER"} ,
		{"word":"groupons","word_nosc":"groupons","lemma":"grouper","pos":"VER"} ,
		{"word":"groupât","word_nosc":"groupat","lemma":"grouper","pos":"VER"} ,
		{"word":"groupèrent","word_nosc":"grouperent","lemma":"grouper","pos":"VER"} ,
		{"word":"groupé","word_nosc":"groupe","lemma":"grouper","pos":"VER"} ,
		{"word":"groupée","word_nosc":"groupee","lemma":"grouper","pos":"VER"} ,
		{"word":"groupées","word_nosc":"groupees","lemma":"grouper","pos":"VER"} ,
		{"word":"groupés","word_nosc":"groupes","lemma":"grouper","pos":"VER"} ,
		{"word":"gruge","word_nosc":"gruge","lemma":"gruger","pos":"VER"} ,
		{"word":"grugeaient","word_nosc":"grugeaient","lemma":"gruger","pos":"VER"} ,
		{"word":"grugeait","word_nosc":"grugeait","lemma":"gruger","pos":"VER"} ,
		{"word":"grugeant","word_nosc":"grugeant","lemma":"gruger","pos":"VER"} ,
		{"word":"gruger","word_nosc":"gruger","lemma":"gruger","pos":"VER"} ,
		{"word":"grugé","word_nosc":"gruge","lemma":"gruger","pos":"VER"} ,
		{"word":"grugée","word_nosc":"grugee","lemma":"gruger","pos":"VER"} ,
		{"word":"gruta","word_nosc":"gruta","lemma":"gruter","pos":"VER"} ,
		{"word":"grute","word_nosc":"grute","lemma":"gruter","pos":"VER"} ,
		{"word":"grène","word_nosc":"grene","lemma":"grener","pos":"VER"} ,
		{"word":"grènera","word_nosc":"grenera","lemma":"grener","pos":"VER"} ,
		{"word":"grève","word_nosc":"greve","lemma":"grever","pos":"VER"} ,
		{"word":"grèvent","word_nosc":"grevent","lemma":"grever","pos":"VER"} ,
		{"word":"grèverait","word_nosc":"greverait","lemma":"grever","pos":"VER"} ,
		{"word":"grèves","word_nosc":"greves","lemma":"grever","pos":"VER"} ,
		{"word":"gréer","word_nosc":"greer","lemma":"gréer","pos":"VER"} ,
		{"word":"gréez","word_nosc":"greez","lemma":"gréer","pos":"VER"} ,
		{"word":"grésilla","word_nosc":"gresilla","lemma":"grésiller","pos":"VER"} ,
		{"word":"grésillaient","word_nosc":"gresillaient","lemma":"grésiller","pos":"VER"} ,
		{"word":"grésillait","word_nosc":"gresillait","lemma":"grésiller","pos":"VER"} ,
		{"word":"grésillant","word_nosc":"gresillant","lemma":"grésiller","pos":"VER"} ,
		{"word":"grésille","word_nosc":"gresille","lemma":"grésiller","pos":"VER"} ,
		{"word":"grésillent","word_nosc":"gresillent","lemma":"grésiller","pos":"VER"} ,
		{"word":"grésiller","word_nosc":"gresiller","lemma":"grésiller","pos":"VER"} ,
		{"word":"grésillerait","word_nosc":"gresillerait","lemma":"grésiller","pos":"VER"} ,
		{"word":"grésillé","word_nosc":"gresille","lemma":"grésiller","pos":"VER"} ,
		{"word":"gréé","word_nosc":"gree","lemma":"gréer","pos":"VER"} ,
		{"word":"gréée","word_nosc":"greee","lemma":"gréer","pos":"VER"} ,
		{"word":"gréées","word_nosc":"greees","lemma":"gréer","pos":"VER"} ,
		{"word":"grêlaient","word_nosc":"grelaient","lemma":"grêler","pos":"VER"} ,
		{"word":"grêlait","word_nosc":"grelait","lemma":"grêler","pos":"VER"} ,
		{"word":"grêle","word_nosc":"grele","lemma":"grêler","pos":"VER"} ,
		{"word":"grêlent","word_nosc":"grelent","lemma":"grêler","pos":"VER"} ,
		{"word":"grêler","word_nosc":"greler","lemma":"grêler","pos":"VER"} ,
		{"word":"grêleraient","word_nosc":"greleraient","lemma":"grêler","pos":"VER"} ,
		{"word":"grêlé","word_nosc":"grele","lemma":"grêler","pos":"VER"} ,
		{"word":"grêlée","word_nosc":"grelee","lemma":"grêler","pos":"VER"} ,
		{"word":"grêlées","word_nosc":"grelees","lemma":"grêler","pos":"VER"} ,
		{"word":"grêlés","word_nosc":"greles","lemma":"grêler","pos":"VER"} ,
		{"word":"guerroient","word_nosc":"guerroient","lemma":"guerroyer","pos":"VER"} ,
		{"word":"guerroyai","word_nosc":"guerroyai","lemma":"guerroyer","pos":"VER"} ,
		{"word":"guerroyaient","word_nosc":"guerroyaient","lemma":"guerroyer","pos":"VER"} ,
		{"word":"guerroyait","word_nosc":"guerroyait","lemma":"guerroyer","pos":"VER"} ,
		{"word":"guerroyant","word_nosc":"guerroyant","lemma":"guerroyer","pos":"VER"} ,
		{"word":"guerroyer","word_nosc":"guerroyer","lemma":"guerroyer","pos":"VER"} ,
		{"word":"guerroyé","word_nosc":"guerroye","lemma":"guerroyer","pos":"VER"} ,
		{"word":"guetta","word_nosc":"guetta","lemma":"guetter","pos":"VER"} ,
		{"word":"guettai","word_nosc":"guettai","lemma":"guetter","pos":"VER"} ,
		{"word":"guettaient","word_nosc":"guettaient","lemma":"guetter","pos":"VER"} ,
		{"word":"guettais","word_nosc":"guettais","lemma":"guetter","pos":"VER"} ,
		{"word":"guettait","word_nosc":"guettait","lemma":"guetter","pos":"VER"} ,
		{"word":"guettant","word_nosc":"guettant","lemma":"guetter","pos":"VER"} ,
		{"word":"guette","word_nosc":"guette","lemma":"guetter","pos":"VER"} ,
		{"word":"guettent","word_nosc":"guettent","lemma":"guetter","pos":"VER"} ,
		{"word":"guetter","word_nosc":"guetter","lemma":"guetter","pos":"VER"} ,
		{"word":"guettera","word_nosc":"guettera","lemma":"guetter","pos":"VER"} ,
		{"word":"guetterai","word_nosc":"guetterai","lemma":"guetter","pos":"VER"} ,
		{"word":"guetterais","word_nosc":"guetterais","lemma":"guetter","pos":"VER"} ,
		{"word":"guetterait","word_nosc":"guetterait","lemma":"guetter","pos":"VER"} ,
		{"word":"guetterez","word_nosc":"guetterez","lemma":"guetter","pos":"VER"} ,
		{"word":"guetterons","word_nosc":"guetterons","lemma":"guetter","pos":"VER"} ,
		{"word":"guetteront","word_nosc":"guetteront","lemma":"guetter","pos":"VER"} ,
		{"word":"guettes","word_nosc":"guettes","lemma":"guetter","pos":"VER"} ,
		{"word":"guettez","word_nosc":"guettez","lemma":"guetter","pos":"VER"} ,
		{"word":"guettiez","word_nosc":"guettiez","lemma":"guetter","pos":"VER"} ,
		{"word":"guettions","word_nosc":"guettions","lemma":"guetter","pos":"VER"} ,
		{"word":"guettons","word_nosc":"guettons","lemma":"guetter","pos":"VER"} ,
		{"word":"guettât","word_nosc":"guettat","lemma":"guetter","pos":"VER"} ,
		{"word":"guettèrent","word_nosc":"guetterent","lemma":"guetter","pos":"VER"} ,
		{"word":"guetté","word_nosc":"guette","lemma":"guetter","pos":"VER"} ,
		{"word":"guettée","word_nosc":"guettee","lemma":"guetter","pos":"VER"} ,
		{"word":"guettées","word_nosc":"guettees","lemma":"guetter","pos":"VER"} ,
		{"word":"guettés","word_nosc":"guettes","lemma":"guetter","pos":"VER"} ,
		{"word":"gueula","word_nosc":"gueula","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueulai","word_nosc":"gueulai","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueulaient","word_nosc":"gueulaient","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueulais","word_nosc":"gueulais","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueulait","word_nosc":"gueulait","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueulant","word_nosc":"gueulant","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueule","word_nosc":"gueule","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueulent","word_nosc":"gueulent","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueuler","word_nosc":"gueuler","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueulera","word_nosc":"gueulera","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueulerai","word_nosc":"gueulerai","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueulerais","word_nosc":"gueulerais","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueulerait","word_nosc":"gueulerait","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueules","word_nosc":"gueules","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueuletonnait","word_nosc":"gueuletonnait","lemma":"gueuletonner","pos":"VER"} ,
		{"word":"gueuletonner","word_nosc":"gueuletonner","lemma":"gueuletonner","pos":"VER"} ,
		{"word":"gueulez","word_nosc":"gueulez","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueulé","word_nosc":"gueule","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueulée","word_nosc":"gueulee","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueulés","word_nosc":"gueules","lemma":"gueuler","pos":"VER"} ,
		{"word":"gueusant","word_nosc":"gueusant","lemma":"gueuser","pos":"VER"} ,
		{"word":"guida","word_nosc":"guida","lemma":"guider","pos":"VER"} ,
		{"word":"guidai","word_nosc":"guidai","lemma":"guider","pos":"VER"} ,
		{"word":"guidaient","word_nosc":"guidaient","lemma":"guider","pos":"VER"} ,
		{"word":"guidais","word_nosc":"guidais","lemma":"guider","pos":"VER"} ,
		{"word":"guidait","word_nosc":"guidait","lemma":"guider","pos":"VER"} ,
		{"word":"guidant","word_nosc":"guidant","lemma":"guider","pos":"VER"} ,
		{"word":"guide","word_nosc":"guide","lemma":"guider","pos":"VER"} ,
		{"word":"guident","word_nosc":"guident","lemma":"guider","pos":"VER"} ,
		{"word":"guider","word_nosc":"guider","lemma":"guider","pos":"VER"} ,
		{"word":"guidera","word_nosc":"guidera","lemma":"guider","pos":"VER"} ,
		{"word":"guiderai","word_nosc":"guiderai","lemma":"guider","pos":"VER"} ,
		{"word":"guiderait","word_nosc":"guiderait","lemma":"guider","pos":"VER"} ,
		{"word":"guideras","word_nosc":"guideras","lemma":"guider","pos":"VER"} ,
		{"word":"guiderez","word_nosc":"guiderez","lemma":"guider","pos":"VER"} ,
		{"word":"guideront","word_nosc":"guideront","lemma":"guider","pos":"VER"} ,
		{"word":"guides","word_nosc":"guides","lemma":"guider","pos":"VER"} ,
		{"word":"guidez","word_nosc":"guidez","lemma":"guider","pos":"VER"} ,
		{"word":"guidiez","word_nosc":"guidiez","lemma":"guider","pos":"VER"} ,
		{"word":"guidons","word_nosc":"guidons","lemma":"guider","pos":"VER"} ,
		{"word":"guidèrent","word_nosc":"guiderent","lemma":"guider","pos":"VER"} ,
		{"word":"guidé","word_nosc":"guide","lemma":"guider","pos":"VER"} ,
		{"word":"guidée","word_nosc":"guidee","lemma":"guider","pos":"VER"} ,
		{"word":"guidées","word_nosc":"guidees","lemma":"guider","pos":"VER"} ,
		{"word":"guidés","word_nosc":"guides","lemma":"guider","pos":"VER"} ,
		{"word":"guigna","word_nosc":"guigna","lemma":"guigner","pos":"VER"} ,
		{"word":"guignaient","word_nosc":"guignaient","lemma":"guigner","pos":"VER"} ,
		{"word":"guignais","word_nosc":"guignais","lemma":"guigner","pos":"VER"} ,
		{"word":"guignait","word_nosc":"guignait","lemma":"guigner","pos":"VER"} ,
		{"word":"guignant","word_nosc":"guignant","lemma":"guigner","pos":"VER"} ,
		{"word":"guigne","word_nosc":"guigne","lemma":"guigner","pos":"VER"} ,
		{"word":"guigner","word_nosc":"guigner","lemma":"guigner","pos":"VER"} ,
		{"word":"guilloche","word_nosc":"guilloche","lemma":"guillocher","pos":"VER"} ,
		{"word":"guilloché","word_nosc":"guilloche","lemma":"guillocher","pos":"VER"} ,
		{"word":"guillochée","word_nosc":"guillochee","lemma":"guillocher","pos":"VER"} ,
		{"word":"guillochées","word_nosc":"guillochees","lemma":"guillocher","pos":"VER"} ,
		{"word":"guillochés","word_nosc":"guilloches","lemma":"guillocher","pos":"VER"} ,
		{"word":"guillotina","word_nosc":"guillotina","lemma":"guillotiner","pos":"VER"} ,
		{"word":"guillotinaient","word_nosc":"guillotinaient","lemma":"guillotiner","pos":"VER"} ,
		{"word":"guillotinait","word_nosc":"guillotinait","lemma":"guillotiner","pos":"VER"} ,
		{"word":"guillotine","word_nosc":"guillotine","lemma":"guillotiner","pos":"VER"} ,
		{"word":"guillotinent","word_nosc":"guillotinent","lemma":"guillotiner","pos":"VER"} ,
		{"word":"guillotiner","word_nosc":"guillotiner","lemma":"guillotiner","pos":"VER"} ,
		{"word":"guillotiné","word_nosc":"guillotine","lemma":"guillotiner","pos":"VER"} ,
		{"word":"guillotinée","word_nosc":"guillotinee","lemma":"guillotiner","pos":"VER"} ,
		{"word":"guillotinés","word_nosc":"guillotines","lemma":"guillotiner","pos":"VER"} ,
		{"word":"guinchait","word_nosc":"guinchait","lemma":"guincher","pos":"VER"} ,
		{"word":"guincher","word_nosc":"guincher","lemma":"guincher","pos":"VER"} ,
		{"word":"guinches","word_nosc":"guinches","lemma":"guincher","pos":"VER"} ,
		{"word":"guindaient","word_nosc":"guindaient","lemma":"guinder","pos":"VER"} ,
		{"word":"guindait","word_nosc":"guindait","lemma":"guinder","pos":"VER"} ,
		{"word":"guindant","word_nosc":"guindant","lemma":"guinder","pos":"VER"} ,
		{"word":"guinde","word_nosc":"guinde","lemma":"guinder","pos":"VER"} ,
		{"word":"guinder","word_nosc":"guinder","lemma":"guinder","pos":"VER"} ,
		{"word":"guindé","word_nosc":"guinde","lemma":"guinder","pos":"VER"} ,
		{"word":"guindée","word_nosc":"guindee","lemma":"guinder","pos":"VER"} ,
		{"word":"guindés","word_nosc":"guindes","lemma":"guinder","pos":"VER"} ,
		{"word":"guéri","word_nosc":"gueri","lemma":"guérir","pos":"VER"} ,
		{"word":"guérie","word_nosc":"guerie","lemma":"guérir","pos":"VER"} ,
		{"word":"guéries","word_nosc":"gueries","lemma":"guérir","pos":"VER"} ,
		{"word":"guérir","word_nosc":"guerir","lemma":"guérir","pos":"VER"} ,
		{"word":"guérira","word_nosc":"guerira","lemma":"guérir","pos":"VER"} ,
		{"word":"guérirai","word_nosc":"guerirai","lemma":"guérir","pos":"VER"} ,
		{"word":"guérirais","word_nosc":"guerirais","lemma":"guérir","pos":"VER"} ,
		{"word":"guérirait","word_nosc":"guerirait","lemma":"guérir","pos":"VER"} ,
		{"word":"guériras","word_nosc":"gueriras","lemma":"guérir","pos":"VER"} ,
		{"word":"guérirez","word_nosc":"guerirez","lemma":"guérir","pos":"VER"} ,
		{"word":"guérirons","word_nosc":"guerirons","lemma":"guérir","pos":"VER"} ,
		{"word":"guériront","word_nosc":"gueriront","lemma":"guérir","pos":"VER"} ,
		{"word":"guéris","word_nosc":"gueris","lemma":"guérir","pos":"VER"} ,
		{"word":"guérissaient","word_nosc":"guerissaient","lemma":"guérir","pos":"VER"} ,
		{"word":"guérissais","word_nosc":"guerissais","lemma":"guérir","pos":"VER"} ,
		{"word":"guérissait","word_nosc":"guerissait","lemma":"guérir","pos":"VER"} ,
		{"word":"guérissant","word_nosc":"guerissant","lemma":"guérir","pos":"VER"} ,
		{"word":"guérisse","word_nosc":"guerisse","lemma":"guérir","pos":"VER"} ,
		{"word":"guérissent","word_nosc":"guerissent","lemma":"guérir","pos":"VER"} ,
		{"word":"guérisses","word_nosc":"guerisses","lemma":"guérir","pos":"VER"} ,
		{"word":"guérissez","word_nosc":"guerissez","lemma":"guérir","pos":"VER"} ,
		{"word":"guérissiez","word_nosc":"guerissiez","lemma":"guérir","pos":"VER"} ,
		{"word":"guérissons","word_nosc":"guerissons","lemma":"guérir","pos":"VER"} ,
		{"word":"guérit","word_nosc":"guerit","lemma":"guérir","pos":"VER"} ,
		{"word":"guérît","word_nosc":"guerit","lemma":"guérir","pos":"VER"} ,
		{"word":"guêtré","word_nosc":"guetre","lemma":"guêtrer","pos":"VER"} ,
		{"word":"guêtrés","word_nosc":"guetres","lemma":"guêtrer","pos":"VER"} ,
		{"word":"gâcha","word_nosc":"gacha","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâchaient","word_nosc":"gachaient","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâchais","word_nosc":"gachais","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâchait","word_nosc":"gachait","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâchant","word_nosc":"gachant","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâche","word_nosc":"gache","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâchent","word_nosc":"gachent","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâcher","word_nosc":"gacher","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâchera","word_nosc":"gachera","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâcherai","word_nosc":"gacherai","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâcheraient","word_nosc":"gacheraient","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâcherais","word_nosc":"gacherais","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâcherait","word_nosc":"gacherait","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâcheras","word_nosc":"gacheras","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâcherez","word_nosc":"gacherez","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâcheriez","word_nosc":"gacheriez","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâches","word_nosc":"gaches","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâchez","word_nosc":"gachez","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâchiez","word_nosc":"gachiez","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâchons","word_nosc":"gachons","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâchât","word_nosc":"gachat","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâché","word_nosc":"gache","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâchée","word_nosc":"gachee","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâchées","word_nosc":"gachees","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâchés","word_nosc":"gaches","lemma":"gâcher","pos":"VER"} ,
		{"word":"gâta","word_nosc":"gata","lemma":"gâter","pos":"VER"} ,
		{"word":"gâtaient","word_nosc":"gataient","lemma":"gâter","pos":"VER"} ,
		{"word":"gâtais","word_nosc":"gatais","lemma":"gâter","pos":"VER"} ,
		{"word":"gâtait","word_nosc":"gatait","lemma":"gâter","pos":"VER"} ,
		{"word":"gâtant","word_nosc":"gatant","lemma":"gâter","pos":"VER"} ,
		{"word":"gâte","word_nosc":"gate","lemma":"gâter","pos":"VER"} ,
		{"word":"gâtent","word_nosc":"gatent","lemma":"gâter","pos":"VER"} ,
		{"word":"gâter","word_nosc":"gater","lemma":"gâter","pos":"VER"} ,
		{"word":"gâtera","word_nosc":"gatera","lemma":"gâter","pos":"VER"} ,
		{"word":"gâterais","word_nosc":"gaterais","lemma":"gâter","pos":"VER"} ,
		{"word":"gâterait","word_nosc":"gaterait","lemma":"gâter","pos":"VER"} ,
		{"word":"gâteront","word_nosc":"gateront","lemma":"gâter","pos":"VER"} ,
		{"word":"gâtes","word_nosc":"gates","lemma":"gâter","pos":"VER"} ,
		{"word":"gâtez","word_nosc":"gatez","lemma":"gâter","pos":"VER"} ,
		{"word":"gâtifie","word_nosc":"gatifie","lemma":"gâtifier","pos":"VER"} ,
		{"word":"gâtât","word_nosc":"gatat","lemma":"gâter","pos":"VER"} ,
		{"word":"gâtèrent","word_nosc":"gaterent","lemma":"gâter","pos":"VER"} ,
		{"word":"gâté","word_nosc":"gate","lemma":"gâter","pos":"VER"} ,
		{"word":"gâtée","word_nosc":"gatee","lemma":"gâter","pos":"VER"} ,
		{"word":"gâtées","word_nosc":"gatees","lemma":"gâter","pos":"VER"} ,
		{"word":"gâtés","word_nosc":"gates","lemma":"gâter","pos":"VER"} ,
		{"word":"gèle","word_nosc":"gele","lemma":"geler","pos":"VER"} ,
		{"word":"gèlent","word_nosc":"gelent","lemma":"geler","pos":"VER"} ,
		{"word":"gèlera","word_nosc":"gelera","lemma":"geler","pos":"VER"} ,
		{"word":"gèlerai","word_nosc":"gelerai","lemma":"geler","pos":"VER"} ,
		{"word":"gèlerait","word_nosc":"gelerait","lemma":"geler","pos":"VER"} ,
		{"word":"gèles","word_nosc":"geles","lemma":"geler","pos":"VER"} ,
		{"word":"gère","word_nosc":"gere","lemma":"gérer","pos":"VER"} ,
		{"word":"gèrent","word_nosc":"gerent","lemma":"gérer","pos":"VER"} ,
		{"word":"gélifié","word_nosc":"gelifie","lemma":"gélifier","pos":"VER"} ,
		{"word":"gémi","word_nosc":"gemi","lemma":"gémir","pos":"VER"} ,
		{"word":"gémie","word_nosc":"gemie","lemma":"gémir","pos":"VER"} ,
		{"word":"gémir","word_nosc":"gemir","lemma":"gémir","pos":"VER"} ,
		{"word":"gémira","word_nosc":"gemira","lemma":"gémir","pos":"VER"} ,
		{"word":"gémirai","word_nosc":"gemirai","lemma":"gémir","pos":"VER"} ,
		{"word":"gémiraient","word_nosc":"gemiraient","lemma":"gémir","pos":"VER"} ,
		{"word":"gémirais","word_nosc":"gemirais","lemma":"gémir","pos":"VER"} ,
		{"word":"gémirait","word_nosc":"gemirait","lemma":"gémir","pos":"VER"} ,
		{"word":"gémirent","word_nosc":"gemirent","lemma":"gémir","pos":"VER"} ,
		{"word":"gémis","word_nosc":"gemis","lemma":"gémir","pos":"VER"} ,
		{"word":"gémissaient","word_nosc":"gemissaient","lemma":"gémir","pos":"VER"} ,
		{"word":"gémissais","word_nosc":"gemissais","lemma":"gémir","pos":"VER"} ,
		{"word":"gémissait","word_nosc":"gemissait","lemma":"gémir","pos":"VER"} ,
		{"word":"gémissant","word_nosc":"gemissant","lemma":"gémir","pos":"VER"} ,
		{"word":"gémisse","word_nosc":"gemisse","lemma":"gémir","pos":"VER"} ,
		{"word":"gémissent","word_nosc":"gemissent","lemma":"gémir","pos":"VER"} ,
		{"word":"gémissez","word_nosc":"gemissez","lemma":"gémir","pos":"VER"} ,
		{"word":"gémissiez","word_nosc":"gemissiez","lemma":"gémir","pos":"VER"} ,
		{"word":"gémit","word_nosc":"gemit","lemma":"gémir","pos":"VER"} ,
		{"word":"génère","word_nosc":"genere","lemma":"générer","pos":"VER"} ,
		{"word":"génères","word_nosc":"generes","lemma":"générer","pos":"VER"} ,
		{"word":"généralisait","word_nosc":"generalisait","lemma":"généraliser","pos":"VER"} ,
		{"word":"généralisant","word_nosc":"generalisant","lemma":"généraliser","pos":"VER"} ,
		{"word":"généralise","word_nosc":"generalise","lemma":"généraliser","pos":"VER"} ,
		{"word":"généraliser","word_nosc":"generaliser","lemma":"généraliser","pos":"VER"} ,
		{"word":"généralisez","word_nosc":"generalisez","lemma":"généraliser","pos":"VER"} ,
		{"word":"généralisions","word_nosc":"generalisions","lemma":"généraliser","pos":"VER"} ,
		{"word":"généralisé","word_nosc":"generalise","lemma":"généraliser","pos":"VER"} ,
		{"word":"généralisée","word_nosc":"generalisee","lemma":"généraliser","pos":"VER"} ,
		{"word":"générant","word_nosc":"generant","lemma":"générer","pos":"VER"} ,
		{"word":"générer","word_nosc":"generer","lemma":"générer","pos":"VER"} ,
		{"word":"générez","word_nosc":"generez","lemma":"générer","pos":"VER"} ,
		{"word":"généré","word_nosc":"genere","lemma":"générer","pos":"VER"} ,
		{"word":"générée","word_nosc":"generee","lemma":"générer","pos":"VER"} ,
		{"word":"générés","word_nosc":"generes","lemma":"générer","pos":"VER"} ,
		{"word":"géra","word_nosc":"gera","lemma":"gérer","pos":"VER"} ,
		{"word":"gérais","word_nosc":"gerais","lemma":"gérer","pos":"VER"} ,
		{"word":"gérait","word_nosc":"gerait","lemma":"gérer","pos":"VER"} ,
		{"word":"gérant","word_nosc":"gerant","lemma":"gérer","pos":"VER"} ,
		{"word":"gérer","word_nosc":"gerer","lemma":"gérer","pos":"VER"} ,
		{"word":"gérera","word_nosc":"gerera","lemma":"gérer","pos":"VER"} ,
		{"word":"gérerai","word_nosc":"gererai","lemma":"gérer","pos":"VER"} ,
		{"word":"gérerais","word_nosc":"gererais","lemma":"gérer","pos":"VER"} ,
		{"word":"gérerez","word_nosc":"gererez","lemma":"gérer","pos":"VER"} ,
		{"word":"géreriez","word_nosc":"gereriez","lemma":"gérer","pos":"VER"} ,
		{"word":"gérez","word_nosc":"gerez","lemma":"gérer","pos":"VER"} ,
		{"word":"gérons","word_nosc":"gerons","lemma":"gérer","pos":"VER"} ,
		{"word":"géré","word_nosc":"gere","lemma":"gérer","pos":"VER"} ,
		{"word":"gérée","word_nosc":"geree","lemma":"gérer","pos":"VER"} ,
		{"word":"gérées","word_nosc":"gerees","lemma":"gérer","pos":"VER"} ,
		{"word":"gérés","word_nosc":"geres","lemma":"gérer","pos":"VER"} ,
		{"word":"gésir","word_nosc":"gesir","lemma":"gésir","pos":"VER"} ,
		{"word":"gêna","word_nosc":"gena","lemma":"gêner","pos":"VER"} ,
		{"word":"gênaient","word_nosc":"genaient","lemma":"gêner","pos":"VER"} ,
		{"word":"gênais","word_nosc":"genais","lemma":"gêner","pos":"VER"} ,
		{"word":"gênait","word_nosc":"genait","lemma":"gêner","pos":"VER"} ,
		{"word":"gênant","word_nosc":"genant","lemma":"gêner","pos":"VER"} ,
		{"word":"gêne","word_nosc":"gene","lemma":"gêner","pos":"VER"} ,
		{"word":"gênent","word_nosc":"genent","lemma":"gêner","pos":"VER"} ,
		{"word":"gêner","word_nosc":"gener","lemma":"gêner","pos":"VER"} ,
		{"word":"gênera","word_nosc":"genera","lemma":"gêner","pos":"VER"} ,
		{"word":"gênerai","word_nosc":"generai","lemma":"gêner","pos":"VER"} ,
		{"word":"gêneraient","word_nosc":"generaient","lemma":"gêner","pos":"VER"} ,
		{"word":"gênerais","word_nosc":"generais","lemma":"gêner","pos":"VER"} ,
		{"word":"gênerait","word_nosc":"generait","lemma":"gêner","pos":"VER"} ,
		{"word":"gênerons","word_nosc":"generons","lemma":"gêner","pos":"VER"} ,
		{"word":"gêneront","word_nosc":"generont","lemma":"gêner","pos":"VER"} ,
		{"word":"gênes","word_nosc":"genes","lemma":"gêner","pos":"VER"} ,
		{"word":"gênez","word_nosc":"genez","lemma":"gêner","pos":"VER"} ,
		{"word":"gêniez","word_nosc":"geniez","lemma":"gêner","pos":"VER"} ,
		{"word":"gênons","word_nosc":"genons","lemma":"gêner","pos":"VER"} ,
		{"word":"gênât","word_nosc":"genat","lemma":"gêner","pos":"VER"} ,
		{"word":"gêné","word_nosc":"gene","lemma":"gêner","pos":"VER"} ,
		{"word":"gênée","word_nosc":"genee","lemma":"gêner","pos":"VER"} ,
		{"word":"gênées","word_nosc":"genees","lemma":"gêner","pos":"VER"} ,
		{"word":"gênés","word_nosc":"genes","lemma":"gêner","pos":"VER"} ,
		{"word":"gît","word_nosc":"git","lemma":"gésir","pos":"VER"} ,
		{"word":"gîta","word_nosc":"gita","lemma":"gîter","pos":"VER"} ,
		{"word":"gîtait","word_nosc":"gitait","lemma":"gîter","pos":"VER"} ,
		{"word":"gîtent","word_nosc":"gitent","lemma":"gîter","pos":"VER"} ,
		{"word":"gîter","word_nosc":"giter","lemma":"gîter","pos":"VER"} ,
		{"word":"gîtera","word_nosc":"gitera","lemma":"gîter","pos":"VER"} ,
		{"word":"gîté","word_nosc":"gite","lemma":"gîter","pos":"VER"} ,
		{"word":"habilite","word_nosc":"habilite","lemma":"habiliter","pos":"VER"} ,
		{"word":"habilité","word_nosc":"habilite","lemma":"habiliter","pos":"VER"} ,
		{"word":"habilitée","word_nosc":"habilitee","lemma":"habiliter","pos":"VER"} ,
		{"word":"habilitées","word_nosc":"habilitees","lemma":"habiliter","pos":"VER"} ,
		{"word":"habilités","word_nosc":"habilites","lemma":"habiliter","pos":"VER"} ,
		{"word":"habilla","word_nosc":"habilla","lemma":"habiller","pos":"VER"} ,
		{"word":"habillai","word_nosc":"habillai","lemma":"habiller","pos":"VER"} ,
		{"word":"habillaient","word_nosc":"habillaient","lemma":"habiller","pos":"VER"} ,
		{"word":"habillais","word_nosc":"habillais","lemma":"habiller","pos":"VER"} ,
		{"word":"habillait","word_nosc":"habillait","lemma":"habiller","pos":"VER"} ,
		{"word":"habillant","word_nosc":"habillant","lemma":"habiller","pos":"VER"} ,
		{"word":"habillas","word_nosc":"habillas","lemma":"habiller","pos":"VER"} ,
		{"word":"habille","word_nosc":"habille","lemma":"habiller","pos":"VER"} ,
		{"word":"habillent","word_nosc":"habillent","lemma":"habiller","pos":"VER"} ,
		{"word":"habiller","word_nosc":"habiller","lemma":"habiller","pos":"VER"} ,
		{"word":"habillera","word_nosc":"habillera","lemma":"habiller","pos":"VER"} ,
		{"word":"habillerai","word_nosc":"habillerai","lemma":"habiller","pos":"VER"} ,
		{"word":"habilleraient","word_nosc":"habilleraient","lemma":"habiller","pos":"VER"} ,
		{"word":"habillerais","word_nosc":"habillerais","lemma":"habiller","pos":"VER"} ,
		{"word":"habillerait","word_nosc":"habillerait","lemma":"habiller","pos":"VER"} ,
		{"word":"habilleras","word_nosc":"habilleras","lemma":"habiller","pos":"VER"} ,
		{"word":"habillerez","word_nosc":"habillerez","lemma":"habiller","pos":"VER"} ,
		{"word":"habillerons","word_nosc":"habillerons","lemma":"habiller","pos":"VER"} ,
		{"word":"habilles","word_nosc":"habilles","lemma":"habiller","pos":"VER"} ,
		{"word":"habillez","word_nosc":"habillez","lemma":"habiller","pos":"VER"} ,
		{"word":"habilliez","word_nosc":"habilliez","lemma":"habiller","pos":"VER"} ,
		{"word":"habillions","word_nosc":"habillions","lemma":"habiller","pos":"VER"} ,
		{"word":"habillons","word_nosc":"habillons","lemma":"habiller","pos":"VER"} ,
		{"word":"habillâmes","word_nosc":"habillames","lemma":"habiller","pos":"VER"} ,
		{"word":"habillât","word_nosc":"habillat","lemma":"habiller","pos":"VER"} ,
		{"word":"habillèrent","word_nosc":"habillerent","lemma":"habiller","pos":"VER"} ,
		{"word":"habillé","word_nosc":"habille","lemma":"habiller","pos":"VER"} ,
		{"word":"habillée","word_nosc":"habillee","lemma":"habiller","pos":"VER"} ,
		{"word":"habillées","word_nosc":"habillees","lemma":"habiller","pos":"VER"} ,
		{"word":"habillés","word_nosc":"habilles","lemma":"habiller","pos":"VER"} ,
		{"word":"habita","word_nosc":"habita","lemma":"habiter","pos":"VER"} ,
		{"word":"habitai","word_nosc":"habitai","lemma":"habiter","pos":"VER"} ,
		{"word":"habitaient","word_nosc":"habitaient","lemma":"habiter","pos":"VER"} ,
		{"word":"habitais","word_nosc":"habitais","lemma":"habiter","pos":"VER"} ,
		{"word":"habitait","word_nosc":"habitait","lemma":"habiter","pos":"VER"} ,
		{"word":"habitant","word_nosc":"habitant","lemma":"habiter","pos":"VER"} ,
		{"word":"habitassent","word_nosc":"habitassent","lemma":"habiter","pos":"VER"} ,
		{"word":"habite","word_nosc":"habite","lemma":"habiter","pos":"VER"} ,
		{"word":"habitent","word_nosc":"habitent","lemma":"habiter","pos":"VER"} ,
		{"word":"habiter","word_nosc":"habiter","lemma":"habiter","pos":"VER"} ,
		{"word":"habitera","word_nosc":"habitera","lemma":"habiter","pos":"VER"} ,
		{"word":"habiterai","word_nosc":"habiterai","lemma":"habiter","pos":"VER"} ,
		{"word":"habiteraient","word_nosc":"habiteraient","lemma":"habiter","pos":"VER"} ,
		{"word":"habiterais","word_nosc":"habiterais","lemma":"habiter","pos":"VER"} ,
		{"word":"habiterait","word_nosc":"habiterait","lemma":"habiter","pos":"VER"} ,
		{"word":"habiteras","word_nosc":"habiteras","lemma":"habiter","pos":"VER"} ,
		{"word":"habiterez","word_nosc":"habiterez","lemma":"habiter","pos":"VER"} ,
		{"word":"habiterions","word_nosc":"habiterions","lemma":"habiter","pos":"VER"} ,
		{"word":"habiterons","word_nosc":"habiterons","lemma":"habiter","pos":"VER"} ,
		{"word":"habiteront","word_nosc":"habiteront","lemma":"habiter","pos":"VER"} ,
		{"word":"habites","word_nosc":"habites","lemma":"habiter","pos":"VER"} ,
		{"word":"habitez","word_nosc":"habitez","lemma":"habiter","pos":"VER"} ,
		{"word":"habitiez","word_nosc":"habitiez","lemma":"habiter","pos":"VER"} ,
		{"word":"habitions","word_nosc":"habitions","lemma":"habiter","pos":"VER"} ,
		{"word":"habitons","word_nosc":"habitons","lemma":"habiter","pos":"VER"} ,
		{"word":"habitua","word_nosc":"habitua","lemma":"habituer","pos":"VER"} ,
		{"word":"habituai","word_nosc":"habituai","lemma":"habituer","pos":"VER"} ,
		{"word":"habituaient","word_nosc":"habituaient","lemma":"habituer","pos":"VER"} ,
		{"word":"habituais","word_nosc":"habituais","lemma":"habituer","pos":"VER"} ,
		{"word":"habituait","word_nosc":"habituait","lemma":"habituer","pos":"VER"} ,
		{"word":"habituant","word_nosc":"habituant","lemma":"habituer","pos":"VER"} ,
		{"word":"habitue","word_nosc":"habitue","lemma":"habituer","pos":"VER"} ,
		{"word":"habituent","word_nosc":"habituent","lemma":"habituer","pos":"VER"} ,
		{"word":"habituer","word_nosc":"habituer","lemma":"habituer","pos":"VER"} ,
		{"word":"habituera","word_nosc":"habituera","lemma":"habituer","pos":"VER"} ,
		{"word":"habituerai","word_nosc":"habituerai","lemma":"habituer","pos":"VER"} ,
		{"word":"habitueraient","word_nosc":"habitueraient","lemma":"habituer","pos":"VER"} ,
		{"word":"habituerais","word_nosc":"habituerais","lemma":"habituer","pos":"VER"} ,
		{"word":"habituerait","word_nosc":"habituerait","lemma":"habituer","pos":"VER"} ,
		{"word":"habitueras","word_nosc":"habitueras","lemma":"habituer","pos":"VER"} ,
		{"word":"habituerez","word_nosc":"habituerez","lemma":"habituer","pos":"VER"} ,
		{"word":"habituerons","word_nosc":"habituerons","lemma":"habituer","pos":"VER"} ,
		{"word":"habitueront","word_nosc":"habitueront","lemma":"habituer","pos":"VER"} ,
		{"word":"habitues","word_nosc":"habitues","lemma":"habituer","pos":"VER"} ,
		{"word":"habituez","word_nosc":"habituez","lemma":"habituer","pos":"VER"} ,
		{"word":"habituons","word_nosc":"habituons","lemma":"habituer","pos":"VER"} ,
		{"word":"habituât","word_nosc":"habituat","lemma":"habituer","pos":"VER"} ,
		{"word":"habituèrent","word_nosc":"habituerent","lemma":"habituer","pos":"VER"} ,
		{"word":"habitué","word_nosc":"habitue","lemma":"habituer","pos":"VER"} ,
		{"word":"habituée","word_nosc":"habituee","lemma":"habituer","pos":"VER"} ,
		{"word":"habituées","word_nosc":"habituees","lemma":"habituer","pos":"VER"} ,
		{"word":"habitués","word_nosc":"habitues","lemma":"habituer","pos":"VER"} ,
		{"word":"habitât","word_nosc":"habitat","lemma":"habiter","pos":"VER"} ,
		{"word":"habitèrent","word_nosc":"habiterent","lemma":"habiter","pos":"VER"} ,
		{"word":"habité","word_nosc":"habite","lemma":"habiter","pos":"VER"} ,
		{"word":"habitée","word_nosc":"habitee","lemma":"habiter","pos":"VER"} ,
		{"word":"habitées","word_nosc":"habitees","lemma":"habiter","pos":"VER"} ,
		{"word":"habités","word_nosc":"habites","lemma":"habiter","pos":"VER"} ,
		{"word":"hacha","word_nosc":"hacha","lemma":"hacher","pos":"VER"} ,
		{"word":"hachaient","word_nosc":"hachaient","lemma":"hacher","pos":"VER"} ,
		{"word":"hachait","word_nosc":"hachait","lemma":"hacher","pos":"VER"} ,
		{"word":"hachant","word_nosc":"hachant","lemma":"hacher","pos":"VER"} ,
		{"word":"hache","word_nosc":"hache","lemma":"hacher","pos":"VER"} ,
		{"word":"hachent","word_nosc":"hachent","lemma":"hacher","pos":"VER"} ,
		{"word":"hacher","word_nosc":"hacher","lemma":"hacher","pos":"VER"} ,
		{"word":"hacherai","word_nosc":"hacherai","lemma":"hacher","pos":"VER"} ,
		{"word":"hacherait","word_nosc":"hacherait","lemma":"hacher","pos":"VER"} ,
		{"word":"haches","word_nosc":"haches","lemma":"hacher","pos":"VER"} ,
		{"word":"hachez","word_nosc":"hachez","lemma":"hacher","pos":"VER"} ,
		{"word":"hachuraient","word_nosc":"hachuraient","lemma":"hachurer","pos":"VER"} ,
		{"word":"hachurait","word_nosc":"hachurait","lemma":"hachurer","pos":"VER"} ,
		{"word":"hachurant","word_nosc":"hachurant","lemma":"hachurer","pos":"VER"} ,
		{"word":"hachure","word_nosc":"hachure","lemma":"hachurer","pos":"VER"} ,
		{"word":"hachuré","word_nosc":"hachure","lemma":"hachurer","pos":"VER"} ,
		{"word":"hachurée","word_nosc":"hachuree","lemma":"hachurer","pos":"VER"} ,
		{"word":"hachurées","word_nosc":"hachurees","lemma":"hachurer","pos":"VER"} ,
		{"word":"hachurés","word_nosc":"hachures","lemma":"hachurer","pos":"VER"} ,
		{"word":"hachèrent","word_nosc":"hacherent","lemma":"hacher","pos":"VER"} ,
		{"word":"haché","word_nosc":"hache","lemma":"hacher","pos":"VER"} ,
		{"word":"hachée","word_nosc":"hachee","lemma":"hacher","pos":"VER"} ,
		{"word":"hachées","word_nosc":"hachees","lemma":"hacher","pos":"VER"} ,
		{"word":"hachés","word_nosc":"haches","lemma":"hacher","pos":"VER"} ,
		{"word":"hais","word_nosc":"hais","lemma":"haïr","pos":"VER"} ,
		{"word":"hait","word_nosc":"hait","lemma":"haïr","pos":"VER"} ,
		{"word":"hala","word_nosc":"hala","lemma":"haler","pos":"VER"} ,
		{"word":"halaient","word_nosc":"halaient","lemma":"haler","pos":"VER"} ,
		{"word":"halait","word_nosc":"halait","lemma":"haler","pos":"VER"} ,
		{"word":"halant","word_nosc":"halant","lemma":"haler","pos":"VER"} ,
		{"word":"halent","word_nosc":"halent","lemma":"haler","pos":"VER"} ,
		{"word":"haler","word_nosc":"haler","lemma":"haler","pos":"VER"} ,
		{"word":"haleta","word_nosc":"haleta","lemma":"haleter","pos":"VER"} ,
		{"word":"haletaient","word_nosc":"haletaient","lemma":"haleter","pos":"VER"} ,
		{"word":"haletais","word_nosc":"haletais","lemma":"haleter","pos":"VER"} ,
		{"word":"haletait","word_nosc":"haletait","lemma":"haleter","pos":"VER"} ,
		{"word":"haletant","word_nosc":"haletant","lemma":"haleter","pos":"VER"} ,
		{"word":"haleter","word_nosc":"haleter","lemma":"haleter","pos":"VER"} ,
		{"word":"haleté","word_nosc":"halete","lemma":"haleter","pos":"VER"} ,
		{"word":"halez","word_nosc":"halez","lemma":"haler","pos":"VER"} ,
		{"word":"hallucinais","word_nosc":"hallucinais","lemma":"halluciner","pos":"VER"} ,
		{"word":"hallucinant","word_nosc":"hallucinant","lemma":"halluciner","pos":"VER"} ,
		{"word":"hallucine","word_nosc":"hallucine","lemma":"halluciner","pos":"VER"} ,
		{"word":"halluciner","word_nosc":"halluciner","lemma":"halluciner","pos":"VER"} ,
		{"word":"hallucinez","word_nosc":"hallucinez","lemma":"halluciner","pos":"VER"} ,
		{"word":"hallucinons","word_nosc":"hallucinons","lemma":"halluciner","pos":"VER"} ,
		{"word":"halluciné","word_nosc":"hallucine","lemma":"halluciner","pos":"VER"} ,
		{"word":"hallucinés","word_nosc":"hallucines","lemma":"halluciner","pos":"VER"} ,
		{"word":"halte","word_nosc":"halte","lemma":"halter","pos":"VER"} ,
		{"word":"halter","word_nosc":"halter","lemma":"halter","pos":"VER"} ,
		{"word":"halèrent","word_nosc":"halerent","lemma":"haler","pos":"VER"} ,
		{"word":"halète","word_nosc":"halete","lemma":"haleter","pos":"VER"} ,
		{"word":"halètent","word_nosc":"haletent","lemma":"haleter","pos":"VER"} ,
		{"word":"halètes","word_nosc":"haletes","lemma":"haleter","pos":"VER"} ,
		{"word":"halé","word_nosc":"hale","lemma":"haler","pos":"VER"} ,
		{"word":"halée","word_nosc":"halee","lemma":"haler","pos":"VER"} ,
		{"word":"halés","word_nosc":"hales","lemma":"haler","pos":"VER"} ,
		{"word":"hameçonne","word_nosc":"hameconne","lemma":"hameçonner","pos":"VER"} ,
		{"word":"hanchée","word_nosc":"hanchee","lemma":"hancher","pos":"VER"} ,
		{"word":"handicapait","word_nosc":"handicapait","lemma":"handicaper","pos":"VER"} ,
		{"word":"handicape","word_nosc":"handicape","lemma":"handicaper","pos":"VER"} ,
		{"word":"handicaper","word_nosc":"handicaper","lemma":"handicaper","pos":"VER"} ,
		{"word":"handicapera","word_nosc":"handicapera","lemma":"handicaper","pos":"VER"} ,
		{"word":"handicapé","word_nosc":"handicape","lemma":"handicaper","pos":"VER"} ,
		{"word":"handicapée","word_nosc":"handicapee","lemma":"handicaper","pos":"VER"} ,
		{"word":"handicapées","word_nosc":"handicapees","lemma":"handicaper","pos":"VER"} ,
		{"word":"handicapés","word_nosc":"handicapes","lemma":"handicaper","pos":"VER"} ,
		{"word":"hanta","word_nosc":"hanta","lemma":"hanter","pos":"VER"} ,
		{"word":"hantaient","word_nosc":"hantaient","lemma":"hanter","pos":"VER"} ,
		{"word":"hantais","word_nosc":"hantais","lemma":"hanter","pos":"VER"} ,
		{"word":"hantait","word_nosc":"hantait","lemma":"hanter","pos":"VER"} ,
		{"word":"hantant","word_nosc":"hantant","lemma":"hanter","pos":"VER"} ,
		{"word":"hante","word_nosc":"hante","lemma":"hanter","pos":"VER"} ,
		{"word":"hantent","word_nosc":"hantent","lemma":"hanter","pos":"VER"} ,
		{"word":"hanter","word_nosc":"hanter","lemma":"hanter","pos":"VER"} ,
		{"word":"hantera","word_nosc":"hantera","lemma":"hanter","pos":"VER"} ,
		{"word":"hanterai","word_nosc":"hanterai","lemma":"hanter","pos":"VER"} ,
		{"word":"hanterait","word_nosc":"hanterait","lemma":"hanter","pos":"VER"} ,
		{"word":"hanteront","word_nosc":"hanteront","lemma":"hanter","pos":"VER"} ,
		{"word":"hantes","word_nosc":"hantes","lemma":"hanter","pos":"VER"} ,
		{"word":"hantez","word_nosc":"hantez","lemma":"hanter","pos":"VER"} ,
		{"word":"hanté","word_nosc":"hante","lemma":"hanter","pos":"VER"} ,
		{"word":"hantée","word_nosc":"hantee","lemma":"hanter","pos":"VER"} ,
		{"word":"hantées","word_nosc":"hantees","lemma":"hanter","pos":"VER"} ,
		{"word":"hantés","word_nosc":"hantes","lemma":"hanter","pos":"VER"} ,
		{"word":"happa","word_nosc":"happa","lemma":"happer","pos":"VER"} ,
		{"word":"happai","word_nosc":"happai","lemma":"happer","pos":"VER"} ,
		{"word":"happaient","word_nosc":"happaient","lemma":"happer","pos":"VER"} ,
		{"word":"happait","word_nosc":"happait","lemma":"happer","pos":"VER"} ,
		{"word":"happant","word_nosc":"happant","lemma":"happer","pos":"VER"} ,
		{"word":"happe","word_nosc":"happe","lemma":"happer","pos":"VER"} ,
		{"word":"happent","word_nosc":"happent","lemma":"happer","pos":"VER"} ,
		{"word":"happer","word_nosc":"happer","lemma":"happer","pos":"VER"} ,
		{"word":"happerait","word_nosc":"happerait","lemma":"happer","pos":"VER"} ,
		{"word":"happé","word_nosc":"happe","lemma":"happer","pos":"VER"} ,
		{"word":"happée","word_nosc":"happee","lemma":"happer","pos":"VER"} ,
		{"word":"happées","word_nosc":"happees","lemma":"happer","pos":"VER"} ,
		{"word":"happés","word_nosc":"happes","lemma":"happer","pos":"VER"} ,
		{"word":"harangua","word_nosc":"harangua","lemma":"haranguer","pos":"VER"} ,
		{"word":"haranguaient","word_nosc":"haranguaient","lemma":"haranguer","pos":"VER"} ,
		{"word":"haranguait","word_nosc":"haranguait","lemma":"haranguer","pos":"VER"} ,
		{"word":"haranguant","word_nosc":"haranguant","lemma":"haranguer","pos":"VER"} ,
		{"word":"harangue","word_nosc":"harangue","lemma":"haranguer","pos":"VER"} ,
		{"word":"haranguer","word_nosc":"haranguer","lemma":"haranguer","pos":"VER"} ,
		{"word":"haranguerais","word_nosc":"haranguerais","lemma":"haranguer","pos":"VER"} ,
		{"word":"haranguez","word_nosc":"haranguez","lemma":"haranguer","pos":"VER"} ,
		{"word":"harangué","word_nosc":"harangue","lemma":"haranguer","pos":"VER"} ,
		{"word":"haranguée","word_nosc":"haranguee","lemma":"haranguer","pos":"VER"} ,
		{"word":"harangués","word_nosc":"harangues","lemma":"haranguer","pos":"VER"} ,
		{"word":"harassaient","word_nosc":"harassaient","lemma":"harasser","pos":"VER"} ,
		{"word":"harassant","word_nosc":"harassant","lemma":"harasser","pos":"VER"} ,
		{"word":"harasse","word_nosc":"harasse","lemma":"harasser","pos":"VER"} ,
		{"word":"harassent","word_nosc":"harassent","lemma":"harasser","pos":"VER"} ,
		{"word":"harasser","word_nosc":"harasser","lemma":"harasser","pos":"VER"} ,
		{"word":"harassé","word_nosc":"harasse","lemma":"harasser","pos":"VER"} ,
		{"word":"harassée","word_nosc":"harassee","lemma":"harasser","pos":"VER"} ,
		{"word":"harassées","word_nosc":"harassees","lemma":"harasser","pos":"VER"} ,
		{"word":"harassés","word_nosc":"harasses","lemma":"harasser","pos":"VER"} ,
		{"word":"harcela","word_nosc":"harcela","lemma":"harceler","pos":"VER"} ,
		{"word":"harcelai","word_nosc":"harcelai","lemma":"harceler","pos":"VER"} ,
		{"word":"harcelaient","word_nosc":"harcelaient","lemma":"harceler","pos":"VER"} ,
		{"word":"harcelais","word_nosc":"harcelais","lemma":"harceler","pos":"VER"} ,
		{"word":"harcelait","word_nosc":"harcelait","lemma":"harceler","pos":"VER"} ,
		{"word":"harcelant","word_nosc":"harcelant","lemma":"harceler","pos":"VER"} ,
		{"word":"harceler","word_nosc":"harceler","lemma":"harceler","pos":"VER"} ,
		{"word":"harcelez","word_nosc":"harcelez","lemma":"harceler","pos":"VER"} ,
		{"word":"harceliez","word_nosc":"harceliez","lemma":"harceler","pos":"VER"} ,
		{"word":"harcelèrent","word_nosc":"harcelerent","lemma":"harceler","pos":"VER"} ,
		{"word":"harcelé","word_nosc":"harcele","lemma":"harceler","pos":"VER"} ,
		{"word":"harcelée","word_nosc":"harcelee","lemma":"harceler","pos":"VER"} ,
		{"word":"harcelées","word_nosc":"harcelees","lemma":"harceler","pos":"VER"} ,
		{"word":"harcelés","word_nosc":"harceles","lemma":"harceler","pos":"VER"} ,
		{"word":"harcèle","word_nosc":"harcele","lemma":"harceler","pos":"VER"} ,
		{"word":"harcèlent","word_nosc":"harcelent","lemma":"harceler","pos":"VER"} ,
		{"word":"harcèlera","word_nosc":"harcelera","lemma":"harceler","pos":"VER"} ,
		{"word":"harcèlerai","word_nosc":"harcelerai","lemma":"harceler","pos":"VER"} ,
		{"word":"harcèleraient","word_nosc":"harceleraient","lemma":"harceler","pos":"VER"} ,
		{"word":"harcèlerez","word_nosc":"harcelerez","lemma":"harceler","pos":"VER"} ,
		{"word":"harcèlerons","word_nosc":"harcelerons","lemma":"harceler","pos":"VER"} ,
		{"word":"harcèleront","word_nosc":"harceleront","lemma":"harceler","pos":"VER"} ,
		{"word":"hardent","word_nosc":"hardent","lemma":"harder","pos":"VER"} ,
		{"word":"harder","word_nosc":"harder","lemma":"harder","pos":"VER"} ,
		{"word":"harmonisaient","word_nosc":"harmonisaient","lemma":"harmoniser","pos":"VER"} ,
		{"word":"harmonisait","word_nosc":"harmonisait","lemma":"harmoniser","pos":"VER"} ,
		{"word":"harmonisant","word_nosc":"harmonisant","lemma":"harmoniser","pos":"VER"} ,
		{"word":"harmonise","word_nosc":"harmonise","lemma":"harmoniser","pos":"VER"} ,
		{"word":"harmonisent","word_nosc":"harmonisent","lemma":"harmoniser","pos":"VER"} ,
		{"word":"harmoniser","word_nosc":"harmoniser","lemma":"harmoniser","pos":"VER"} ,
		{"word":"harmonisera","word_nosc":"harmonisera","lemma":"harmoniser","pos":"VER"} ,
		{"word":"harmonisez","word_nosc":"harmonisez","lemma":"harmoniser","pos":"VER"} ,
		{"word":"harmonisèrent","word_nosc":"harmoniserent","lemma":"harmoniser","pos":"VER"} ,
		{"word":"harmonisé","word_nosc":"harmonise","lemma":"harmoniser","pos":"VER"} ,
		{"word":"harmonisés","word_nosc":"harmonises","lemma":"harmoniser","pos":"VER"} ,
		{"word":"harnacha","word_nosc":"harnacha","lemma":"harnacher","pos":"VER"} ,
		{"word":"harnachaient","word_nosc":"harnachaient","lemma":"harnacher","pos":"VER"} ,
		{"word":"harnachais","word_nosc":"harnachais","lemma":"harnacher","pos":"VER"} ,
		{"word":"harnachait","word_nosc":"harnachait","lemma":"harnacher","pos":"VER"} ,
		{"word":"harnacher","word_nosc":"harnacher","lemma":"harnacher","pos":"VER"} ,
		{"word":"harnachiez","word_nosc":"harnachiez","lemma":"harnacher","pos":"VER"} ,
		{"word":"harnachèrent","word_nosc":"harnacherent","lemma":"harnacher","pos":"VER"} ,
		{"word":"harnaché","word_nosc":"harnache","lemma":"harnacher","pos":"VER"} ,
		{"word":"harnachée","word_nosc":"harnachee","lemma":"harnacher","pos":"VER"} ,
		{"word":"harnachées","word_nosc":"harnachees","lemma":"harnacher","pos":"VER"} ,
		{"word":"harnachés","word_nosc":"harnaches","lemma":"harnacher","pos":"VER"} ,
		{"word":"harper","word_nosc":"harper","lemma":"harper","pos":"VER"} ,
		{"word":"harpions","word_nosc":"harpions","lemma":"harper","pos":"VER"} ,
		{"word":"harponna","word_nosc":"harponna","lemma":"harponner","pos":"VER"} ,
		{"word":"harponne","word_nosc":"harponne","lemma":"harponner","pos":"VER"} ,
		{"word":"harponnent","word_nosc":"harponnent","lemma":"harponner","pos":"VER"} ,
		{"word":"harponner","word_nosc":"harponner","lemma":"harponner","pos":"VER"} ,
		{"word":"harponnera","word_nosc":"harponnera","lemma":"harponner","pos":"VER"} ,
		{"word":"harponné","word_nosc":"harponne","lemma":"harponner","pos":"VER"} ,
		{"word":"harponnée","word_nosc":"harponnee","lemma":"harponner","pos":"VER"} ,
		{"word":"harponnés","word_nosc":"harponnes","lemma":"harponner","pos":"VER"} ,
		{"word":"hasarda","word_nosc":"hasarda","lemma":"hasarder","pos":"VER"} ,
		{"word":"hasardai","word_nosc":"hasardai","lemma":"hasarder","pos":"VER"} ,
		{"word":"hasardaient","word_nosc":"hasardaient","lemma":"hasarder","pos":"VER"} ,
		{"word":"hasardais","word_nosc":"hasardais","lemma":"hasarder","pos":"VER"} ,
		{"word":"hasardait","word_nosc":"hasardait","lemma":"hasarder","pos":"VER"} ,
		{"word":"hasardant","word_nosc":"hasardant","lemma":"hasarder","pos":"VER"} ,
		{"word":"hasarde","word_nosc":"hasarde","lemma":"hasarder","pos":"VER"} ,
		{"word":"hasardent","word_nosc":"hasardent","lemma":"hasarder","pos":"VER"} ,
		{"word":"hasarder","word_nosc":"hasarder","lemma":"hasarder","pos":"VER"} ,
		{"word":"hasardera","word_nosc":"hasardera","lemma":"hasarder","pos":"VER"} ,
		{"word":"hasarderais","word_nosc":"hasarderais","lemma":"hasarder","pos":"VER"} ,
		{"word":"hasardé","word_nosc":"hasarde","lemma":"hasarder","pos":"VER"} ,
		{"word":"hasardée","word_nosc":"hasardee","lemma":"hasarder","pos":"VER"} ,
		{"word":"haubanée","word_nosc":"haubanee","lemma":"haubaner","pos":"VER"} ,
		{"word":"haubanés","word_nosc":"haubanes","lemma":"haubaner","pos":"VER"} ,
		{"word":"haussa","word_nosc":"haussa","lemma":"hausser","pos":"VER"} ,
		{"word":"haussai","word_nosc":"haussai","lemma":"hausser","pos":"VER"} ,
		{"word":"haussaient","word_nosc":"haussaient","lemma":"hausser","pos":"VER"} ,
		{"word":"haussais","word_nosc":"haussais","lemma":"hausser","pos":"VER"} ,
		{"word":"haussait","word_nosc":"haussait","lemma":"hausser","pos":"VER"} ,
		{"word":"haussant","word_nosc":"haussant","lemma":"hausser","pos":"VER"} ,
		{"word":"hausse","word_nosc":"hausse","lemma":"hausser","pos":"VER"} ,
		{"word":"haussent","word_nosc":"haussent","lemma":"hausser","pos":"VER"} ,
		{"word":"hausser","word_nosc":"hausser","lemma":"hausser","pos":"VER"} ,
		{"word":"hausserait","word_nosc":"hausserait","lemma":"hausser","pos":"VER"} ,
		{"word":"hausses","word_nosc":"hausses","lemma":"hausser","pos":"VER"} ,
		{"word":"haussez","word_nosc":"haussez","lemma":"hausser","pos":"VER"} ,
		{"word":"haussons","word_nosc":"haussons","lemma":"hausser","pos":"VER"} ,
		{"word":"haussât","word_nosc":"haussat","lemma":"hausser","pos":"VER"} ,
		{"word":"haussèrent","word_nosc":"hausserent","lemma":"hausser","pos":"VER"} ,
		{"word":"haussé","word_nosc":"hausse","lemma":"hausser","pos":"VER"} ,
		{"word":"haussée","word_nosc":"haussee","lemma":"hausser","pos":"VER"} ,
		{"word":"haussées","word_nosc":"haussees","lemma":"hausser","pos":"VER"} ,
		{"word":"haussés","word_nosc":"hausses","lemma":"hausser","pos":"VER"} ,
		{"word":"have","word_nosc":"have","lemma":"haver","pos":"VER"} ,
		{"word":"haver","word_nosc":"haver","lemma":"haver","pos":"VER"} ,
		{"word":"haves","word_nosc":"haves","lemma":"haver","pos":"VER"} ,
		{"word":"haï","word_nosc":"hai","lemma":"haïr","pos":"VER"} ,
		{"word":"haïe","word_nosc":"haie","lemma":"haïr","pos":"VER"} ,
		{"word":"haïes","word_nosc":"haies","lemma":"haïr","pos":"VER"} ,
		{"word":"haïr","word_nosc":"hair","lemma":"haïr","pos":"VER"} ,
		{"word":"haïra","word_nosc":"haira","lemma":"haïr","pos":"VER"} ,
		{"word":"haïrai","word_nosc":"hairai","lemma":"haïr","pos":"VER"} ,
		{"word":"haïraient","word_nosc":"hairaient","lemma":"haïr","pos":"VER"} ,
		{"word":"haïrais","word_nosc":"hairais","lemma":"haïr","pos":"VER"} ,
		{"word":"haïrait","word_nosc":"hairait","lemma":"haïr","pos":"VER"} ,
		{"word":"haïras","word_nosc":"hairas","lemma":"haïr","pos":"VER"} ,
		{"word":"haïrez","word_nosc":"hairez","lemma":"haïr","pos":"VER"} ,
		{"word":"haïriez","word_nosc":"hairiez","lemma":"haïr","pos":"VER"} ,
		{"word":"haïrons","word_nosc":"hairons","lemma":"haïr","pos":"VER"} ,
		{"word":"haïront","word_nosc":"hairont","lemma":"haïr","pos":"VER"} ,
		{"word":"haïs","word_nosc":"hais","lemma":"haïr","pos":"VER"} ,
		{"word":"haïssaient","word_nosc":"haissaient","lemma":"haïr","pos":"VER"} ,
		{"word":"haïssais","word_nosc":"haissais","lemma":"haïr","pos":"VER"} ,
		{"word":"haïssait","word_nosc":"haissait","lemma":"haïr","pos":"VER"} ,
		{"word":"haïssant","word_nosc":"haissant","lemma":"haïr","pos":"VER"} ,
		{"word":"haïsse","word_nosc":"haisse","lemma":"haïr","pos":"VER"} ,
		{"word":"haïssent","word_nosc":"haissent","lemma":"haïr","pos":"VER"} ,
		{"word":"haïsses","word_nosc":"haisses","lemma":"haïr","pos":"VER"} ,
		{"word":"haïssez","word_nosc":"haissez","lemma":"haïr","pos":"VER"} ,
		{"word":"haïssiez","word_nosc":"haissiez","lemma":"haïr","pos":"VER"} ,
		{"word":"haïssions","word_nosc":"haissions","lemma":"haïr","pos":"VER"} ,
		{"word":"haïssons","word_nosc":"haissons","lemma":"haïr","pos":"VER"} ,
		{"word":"haït","word_nosc":"hait","lemma":"haïr","pos":"VER"} ,
		{"word":"helléniser","word_nosc":"helleniser","lemma":"helléniser","pos":"VER"} ,
		{"word":"hellénisé","word_nosc":"hellenise","lemma":"helléniser","pos":"VER"} ,
		{"word":"hennir","word_nosc":"hennir","lemma":"hennir","pos":"VER"} ,
		{"word":"hennirent","word_nosc":"hennirent","lemma":"hennir","pos":"VER"} ,
		{"word":"hennissaient","word_nosc":"hennissaient","lemma":"hennir","pos":"VER"} ,
		{"word":"hennissait","word_nosc":"hennissait","lemma":"hennir","pos":"VER"} ,
		{"word":"hennissant","word_nosc":"hennissant","lemma":"hennir","pos":"VER"} ,
		{"word":"hennissent","word_nosc":"hennissent","lemma":"hennir","pos":"VER"} ,
		{"word":"hennit","word_nosc":"hennit","lemma":"hennir","pos":"VER"} ,
		{"word":"herba","word_nosc":"herba","lemma":"herber","pos":"VER"} ,
		{"word":"herber","word_nosc":"herber","lemma":"herber","pos":"VER"} ,
		{"word":"herborisais","word_nosc":"herborisais","lemma":"herboriser","pos":"VER"} ,
		{"word":"herborisait","word_nosc":"herborisait","lemma":"herboriser","pos":"VER"} ,
		{"word":"herboriser","word_nosc":"herboriser","lemma":"herboriser","pos":"VER"} ,
		{"word":"hercher","word_nosc":"hercher","lemma":"hercher","pos":"VER"} ,
		{"word":"hersait","word_nosc":"hersait","lemma":"herser","pos":"VER"} ,
		{"word":"herser","word_nosc":"herser","lemma":"herser","pos":"VER"} ,
		{"word":"hersé","word_nosc":"herse","lemma":"herser","pos":"VER"} ,
		{"word":"hersés","word_nosc":"herses","lemma":"herser","pos":"VER"} ,
		{"word":"heurta","word_nosc":"heurta","lemma":"heurter","pos":"VER"} ,
		{"word":"heurtai","word_nosc":"heurtai","lemma":"heurter","pos":"VER"} ,
		{"word":"heurtaient","word_nosc":"heurtaient","lemma":"heurter","pos":"VER"} ,
		{"word":"heurtais","word_nosc":"heurtais","lemma":"heurter","pos":"VER"} ,
		{"word":"heurtait","word_nosc":"heurtait","lemma":"heurter","pos":"VER"} ,
		{"word":"heurtant","word_nosc":"heurtant","lemma":"heurter","pos":"VER"} ,
		{"word":"heurte","word_nosc":"heurte","lemma":"heurter","pos":"VER"} ,
		{"word":"heurtent","word_nosc":"heurtent","lemma":"heurter","pos":"VER"} ,
		{"word":"heurter","word_nosc":"heurter","lemma":"heurter","pos":"VER"} ,
		{"word":"heurtera","word_nosc":"heurtera","lemma":"heurter","pos":"VER"} ,
		{"word":"heurterai","word_nosc":"heurterai","lemma":"heurter","pos":"VER"} ,
		{"word":"heurteraient","word_nosc":"heurteraient","lemma":"heurter","pos":"VER"} ,
		{"word":"heurterait","word_nosc":"heurterait","lemma":"heurter","pos":"VER"} ,
		{"word":"heurterions","word_nosc":"heurterions","lemma":"heurter","pos":"VER"} ,
		{"word":"heurtez","word_nosc":"heurtez","lemma":"heurter","pos":"VER"} ,
		{"word":"heurtions","word_nosc":"heurtions","lemma":"heurter","pos":"VER"} ,
		{"word":"heurtons","word_nosc":"heurtons","lemma":"heurter","pos":"VER"} ,
		{"word":"heurtât","word_nosc":"heurtat","lemma":"heurter","pos":"VER"} ,
		{"word":"heurtèrent","word_nosc":"heurterent","lemma":"heurter","pos":"VER"} ,
		{"word":"heurté","word_nosc":"heurte","lemma":"heurter","pos":"VER"} ,
		{"word":"heurtée","word_nosc":"heurtee","lemma":"heurter","pos":"VER"} ,
		{"word":"heurtées","word_nosc":"heurtees","lemma":"heurter","pos":"VER"} ,
		{"word":"heurtés","word_nosc":"heurtes","lemma":"heurter","pos":"VER"} ,
		{"word":"hibernaient","word_nosc":"hibernaient","lemma":"hiberner","pos":"VER"} ,
		{"word":"hibernait","word_nosc":"hibernait","lemma":"hiberner","pos":"VER"} ,
		{"word":"hiberne","word_nosc":"hiberne","lemma":"hiberner","pos":"VER"} ,
		{"word":"hibernent","word_nosc":"hibernent","lemma":"hiberner","pos":"VER"} ,
		{"word":"hiberner","word_nosc":"hiberner","lemma":"hiberner","pos":"VER"} ,
		{"word":"hiberné","word_nosc":"hiberne","lemma":"hiberner","pos":"VER"} ,
		{"word":"hissa","word_nosc":"hissa","lemma":"hisser","pos":"VER"} ,
		{"word":"hissai","word_nosc":"hissai","lemma":"hisser","pos":"VER"} ,
		{"word":"hissaient","word_nosc":"hissaient","lemma":"hisser","pos":"VER"} ,
		{"word":"hissais","word_nosc":"hissais","lemma":"hisser","pos":"VER"} ,
		{"word":"hissait","word_nosc":"hissait","lemma":"hisser","pos":"VER"} ,
		{"word":"hissant","word_nosc":"hissant","lemma":"hisser","pos":"VER"} ,
		{"word":"hisse","word_nosc":"hisse","lemma":"hisser","pos":"VER"} ,
		{"word":"hissent","word_nosc":"hissent","lemma":"hisser","pos":"VER"} ,
		{"word":"hisser","word_nosc":"hisser","lemma":"hisser","pos":"VER"} ,
		{"word":"hissera","word_nosc":"hissera","lemma":"hisser","pos":"VER"} ,
		{"word":"hisserai","word_nosc":"hisserai","lemma":"hisser","pos":"VER"} ,
		{"word":"hissez","word_nosc":"hissez","lemma":"hisser","pos":"VER"} ,
		{"word":"hissons","word_nosc":"hissons","lemma":"hisser","pos":"VER"} ,
		{"word":"hissâmes","word_nosc":"hissames","lemma":"hisser","pos":"VER"} ,
		{"word":"hissèrent","word_nosc":"hisserent","lemma":"hisser","pos":"VER"} ,
		{"word":"hissé","word_nosc":"hisse","lemma":"hisser","pos":"VER"} ,
		{"word":"hissée","word_nosc":"hissee","lemma":"hisser","pos":"VER"} ,
		{"word":"hissées","word_nosc":"hissees","lemma":"hisser","pos":"VER"} ,
		{"word":"hissés","word_nosc":"hisses","lemma":"hisser","pos":"VER"} ,
		{"word":"historia","word_nosc":"historia","lemma":"historier","pos":"VER"} ,
		{"word":"historiée","word_nosc":"historiee","lemma":"historier","pos":"VER"} ,
		{"word":"historiés","word_nosc":"histories","lemma":"historier","pos":"VER"} ,
		{"word":"hivernaient","word_nosc":"hivernaient","lemma":"hiverner","pos":"VER"} ,
		{"word":"hivernait","word_nosc":"hivernait","lemma":"hiverner","pos":"VER"} ,
		{"word":"hiverne","word_nosc":"hiverne","lemma":"hiverner","pos":"VER"} ,
		{"word":"hiverner","word_nosc":"hiverner","lemma":"hiverner","pos":"VER"} ,
		{"word":"hivernons","word_nosc":"hivernons","lemma":"hiverner","pos":"VER"} ,
		{"word":"hiverné","word_nosc":"hiverne","lemma":"hiverner","pos":"VER"} ,
		{"word":"hiérarchisait","word_nosc":"hierarchisait","lemma":"hiérarchiser","pos":"VER"} ,
		{"word":"hiérarchise","word_nosc":"hierarchise","lemma":"hiérarchiser","pos":"VER"} ,
		{"word":"hiérarchisés","word_nosc":"hierarchises","lemma":"hiérarchiser","pos":"VER"} ,
		{"word":"hocha","word_nosc":"hocha","lemma":"hocher","pos":"VER"} ,
		{"word":"hochai","word_nosc":"hochai","lemma":"hocher","pos":"VER"} ,
		{"word":"hochaient","word_nosc":"hochaient","lemma":"hocher","pos":"VER"} ,
		{"word":"hochais","word_nosc":"hochais","lemma":"hocher","pos":"VER"} ,
		{"word":"hochait","word_nosc":"hochait","lemma":"hocher","pos":"VER"} ,
		{"word":"hochant","word_nosc":"hochant","lemma":"hocher","pos":"VER"} ,
		{"word":"hoche","word_nosc":"hoche","lemma":"hocher","pos":"VER"} ,
		{"word":"hochent","word_nosc":"hochent","lemma":"hocher","pos":"VER"} ,
		{"word":"hocher","word_nosc":"hocher","lemma":"hocher","pos":"VER"} ,
		{"word":"hocheraient","word_nosc":"hocheraient","lemma":"hocher","pos":"VER"} ,
		{"word":"hocheront","word_nosc":"hocheront","lemma":"hocher","pos":"VER"} ,
		{"word":"hoches","word_nosc":"hoches","lemma":"hocher","pos":"VER"} ,
		{"word":"hochez","word_nosc":"hochez","lemma":"hocher","pos":"VER"} ,
		{"word":"hochions","word_nosc":"hochions","lemma":"hocher","pos":"VER"} ,
		{"word":"hochèrent","word_nosc":"hocherent","lemma":"hocher","pos":"VER"} ,
		{"word":"hoché","word_nosc":"hoche","lemma":"hocher","pos":"VER"} ,
		{"word":"homogénéisé","word_nosc":"homogeneise","lemma":"homogénéiser","pos":"VER"} ,
		{"word":"homologuer","word_nosc":"homologuer","lemma":"homologuer","pos":"VER"} ,
		{"word":"homologué","word_nosc":"homologue","lemma":"homologuer","pos":"VER"} ,
		{"word":"homologuée","word_nosc":"homologuee","lemma":"homologuer","pos":"VER"} ,
		{"word":"homologués","word_nosc":"homologues","lemma":"homologuer","pos":"VER"} ,
		{"word":"honni","word_nosc":"honni","lemma":"honnir","pos":"VER"} ,
		{"word":"honnie","word_nosc":"honnie","lemma":"honnir","pos":"VER"} ,
		{"word":"honnir","word_nosc":"honnir","lemma":"honnir","pos":"VER"} ,
		{"word":"honnis","word_nosc":"honnis","lemma":"honnir","pos":"VER"} ,
		{"word":"honnissait","word_nosc":"honnissait","lemma":"honnir","pos":"VER"} ,
		{"word":"honnissant","word_nosc":"honnissant","lemma":"honnir","pos":"VER"} ,
		{"word":"honnissent","word_nosc":"honnissent","lemma":"honnir","pos":"VER"} ,
		{"word":"honnit","word_nosc":"honnit","lemma":"honnir","pos":"VER"} ,
		{"word":"honora","word_nosc":"honora","lemma":"honorer","pos":"VER"} ,
		{"word":"honorai","word_nosc":"honorai","lemma":"honorer","pos":"VER"} ,
		{"word":"honoraient","word_nosc":"honoraient","lemma":"honorer","pos":"VER"} ,
		{"word":"honorais","word_nosc":"honorais","lemma":"honorer","pos":"VER"} ,
		{"word":"honorait","word_nosc":"honorait","lemma":"honorer","pos":"VER"} ,
		{"word":"honorant","word_nosc":"honorant","lemma":"honorer","pos":"VER"} ,
		{"word":"honore","word_nosc":"honore","lemma":"honorer","pos":"VER"} ,
		{"word":"honorent","word_nosc":"honorent","lemma":"honorer","pos":"VER"} ,
		{"word":"honorer","word_nosc":"honorer","lemma":"honorer","pos":"VER"} ,
		{"word":"honorera","word_nosc":"honorera","lemma":"honorer","pos":"VER"} ,
		{"word":"honorerai","word_nosc":"honorerai","lemma":"honorer","pos":"VER"} ,
		{"word":"honorerait","word_nosc":"honorerait","lemma":"honorer","pos":"VER"} ,
		{"word":"honoreras","word_nosc":"honoreras","lemma":"honorer","pos":"VER"} ,
		{"word":"honorerez","word_nosc":"honorerez","lemma":"honorer","pos":"VER"} ,
		{"word":"honorerons","word_nosc":"honorerons","lemma":"honorer","pos":"VER"} ,
		{"word":"honoreront","word_nosc":"honoreront","lemma":"honorer","pos":"VER"} ,
		{"word":"honores","word_nosc":"honores","lemma":"honorer","pos":"VER"} ,
		{"word":"honorez","word_nosc":"honorez","lemma":"honorer","pos":"VER"} ,
		{"word":"honoriez","word_nosc":"honoriez","lemma":"honorer","pos":"VER"} ,
		{"word":"honorons","word_nosc":"honorons","lemma":"honorer","pos":"VER"} ,
		{"word":"honorât","word_nosc":"honorat","lemma":"honorer","pos":"VER"} ,
		{"word":"honorèrent","word_nosc":"honorerent","lemma":"honorer","pos":"VER"} ,
		{"word":"honoré","word_nosc":"honore","lemma":"honorer","pos":"VER"} ,
		{"word":"honorée","word_nosc":"honoree","lemma":"honorer","pos":"VER"} ,
		{"word":"honorées","word_nosc":"honorees","lemma":"honorer","pos":"VER"} ,
		{"word":"honorés","word_nosc":"honores","lemma":"honorer","pos":"VER"} ,
		{"word":"hoqueta","word_nosc":"hoqueta","lemma":"hoqueter","pos":"VER"} ,
		{"word":"hoquetai","word_nosc":"hoquetai","lemma":"hoqueter","pos":"VER"} ,
		{"word":"hoquetais","word_nosc":"hoquetais","lemma":"hoqueter","pos":"VER"} ,
		{"word":"hoquetait","word_nosc":"hoquetait","lemma":"hoqueter","pos":"VER"} ,
		{"word":"hoquetant","word_nosc":"hoquetant","lemma":"hoqueter","pos":"VER"} ,
		{"word":"hoqueter","word_nosc":"hoqueter","lemma":"hoqueter","pos":"VER"} ,
		{"word":"hoquetons","word_nosc":"hoquetons","lemma":"hoqueter","pos":"VER"} ,
		{"word":"hoquette","word_nosc":"hoquette","lemma":"hoqueter","pos":"VER"} ,
		{"word":"hoqueté","word_nosc":"hoquete","lemma":"hoqueter","pos":"VER"} ,
		{"word":"hormonés","word_nosc":"hormones","lemma":"hormoner","pos":"VER"} ,
		{"word":"horrifia","word_nosc":"horrifia","lemma":"horrifier","pos":"VER"} ,
		{"word":"horrifiaient","word_nosc":"horrifiaient","lemma":"horrifier","pos":"VER"} ,
		{"word":"horrifiait","word_nosc":"horrifiait","lemma":"horrifier","pos":"VER"} ,
		{"word":"horrifiant","word_nosc":"horrifiant","lemma":"horrifier","pos":"VER"} ,
		{"word":"horrifie","word_nosc":"horrifie","lemma":"horrifier","pos":"VER"} ,
		{"word":"horrifier","word_nosc":"horrifier","lemma":"horrifier","pos":"VER"} ,
		{"word":"horrifièrent","word_nosc":"horrifierent","lemma":"horrifier","pos":"VER"} ,
		{"word":"horrifié","word_nosc":"horrifie","lemma":"horrifier","pos":"VER"} ,
		{"word":"horrifiée","word_nosc":"horrifiee","lemma":"horrifier","pos":"VER"} ,
		{"word":"horrifiées","word_nosc":"horrifiees","lemma":"horrifier","pos":"VER"} ,
		{"word":"horrifiés","word_nosc":"horrifies","lemma":"horrifier","pos":"VER"} ,
		{"word":"horripilaient","word_nosc":"horripilaient","lemma":"horripiler","pos":"VER"} ,
		{"word":"horripilais","word_nosc":"horripilais","lemma":"horripiler","pos":"VER"} ,
		{"word":"horripilait","word_nosc":"horripilait","lemma":"horripiler","pos":"VER"} ,
		{"word":"horripilant","word_nosc":"horripilant","lemma":"horripiler","pos":"VER"} ,
		{"word":"horripile","word_nosc":"horripile","lemma":"horripiler","pos":"VER"} ,
		{"word":"horripilent","word_nosc":"horripilent","lemma":"horripiler","pos":"VER"} ,
		{"word":"horripiler","word_nosc":"horripiler","lemma":"horripiler","pos":"VER"} ,
		{"word":"horripilé","word_nosc":"horripile","lemma":"horripiler","pos":"VER"} ,
		{"word":"horripilée","word_nosc":"horripilee","lemma":"horripiler","pos":"VER"} ,
		{"word":"horripilés","word_nosc":"horripiles","lemma":"horripiler","pos":"VER"} ,
		{"word":"hospitaliser","word_nosc":"hospitaliser","lemma":"hospitaliser","pos":"VER"} ,
		{"word":"hospitaliserons","word_nosc":"hospitaliserons","lemma":"hospitaliser","pos":"VER"} ,
		{"word":"hospitalisé","word_nosc":"hospitalise","lemma":"hospitaliser","pos":"VER"} ,
		{"word":"hospitalisée","word_nosc":"hospitalisee","lemma":"hospitaliser","pos":"VER"} ,
		{"word":"hospitalisées","word_nosc":"hospitalisees","lemma":"hospitaliser","pos":"VER"} ,
		{"word":"hospitalisés","word_nosc":"hospitalises","lemma":"hospitaliser","pos":"VER"} ,
		{"word":"hotter","word_nosc":"hotter","lemma":"hotter","pos":"VER"} ,
		{"word":"houa","word_nosc":"houa","lemma":"houer","pos":"VER"} ,
		{"word":"houblonnés","word_nosc":"houblonnes","lemma":"houblonner","pos":"VER"} ,
		{"word":"houler","word_nosc":"houler","lemma":"houler","pos":"VER"} ,
		{"word":"houspilla","word_nosc":"houspilla","lemma":"houspiller","pos":"VER"} ,
		{"word":"houspillaient","word_nosc":"houspillaient","lemma":"houspiller","pos":"VER"} ,
		{"word":"houspillait","word_nosc":"houspillait","lemma":"houspiller","pos":"VER"} ,
		{"word":"houspillant","word_nosc":"houspillant","lemma":"houspiller","pos":"VER"} ,
		{"word":"houspille","word_nosc":"houspille","lemma":"houspiller","pos":"VER"} ,
		{"word":"houspillent","word_nosc":"houspillent","lemma":"houspiller","pos":"VER"} ,
		{"word":"houspiller","word_nosc":"houspiller","lemma":"houspiller","pos":"VER"} ,
		{"word":"houspillâmes","word_nosc":"houspillames","lemma":"houspiller","pos":"VER"} ,
		{"word":"houspillèrent","word_nosc":"houspillerent","lemma":"houspiller","pos":"VER"} ,
		{"word":"houspillé","word_nosc":"houspille","lemma":"houspiller","pos":"VER"} ,
		{"word":"houspillée","word_nosc":"houspillee","lemma":"houspiller","pos":"VER"} ,
		{"word":"houspillées","word_nosc":"houspillees","lemma":"houspiller","pos":"VER"} ,
		{"word":"houspillés","word_nosc":"houspilles","lemma":"houspiller","pos":"VER"} ,
		{"word":"houssés","word_nosc":"housses","lemma":"housser","pos":"VER"} ,
		{"word":"hua","word_nosc":"hua","lemma":"huer","pos":"VER"} ,
		{"word":"huaient","word_nosc":"huaient","lemma":"huer","pos":"VER"} ,
		{"word":"huait","word_nosc":"huait","lemma":"huer","pos":"VER"} ,
		{"word":"huant","word_nosc":"huant","lemma":"huer","pos":"VER"} ,
		{"word":"hue","word_nosc":"hue","lemma":"huer","pos":"VER"} ,
		{"word":"huer","word_nosc":"huer","lemma":"huer","pos":"VER"} ,
		{"word":"huez","word_nosc":"huez","lemma":"huer","pos":"VER"} ,
		{"word":"huilai","word_nosc":"huilai","lemma":"huiler","pos":"VER"} ,
		{"word":"huilait","word_nosc":"huilait","lemma":"huiler","pos":"VER"} ,
		{"word":"huile","word_nosc":"huile","lemma":"huiler","pos":"VER"} ,
		{"word":"huilent","word_nosc":"huilent","lemma":"huiler","pos":"VER"} ,
		{"word":"huiler","word_nosc":"huiler","lemma":"huiler","pos":"VER"} ,
		{"word":"huilerai","word_nosc":"huilerai","lemma":"huiler","pos":"VER"} ,
		{"word":"huilez","word_nosc":"huilez","lemma":"huiler","pos":"VER"} ,
		{"word":"huilé","word_nosc":"huile","lemma":"huiler","pos":"VER"} ,
		{"word":"huilée","word_nosc":"huilee","lemma":"huiler","pos":"VER"} ,
		{"word":"huilées","word_nosc":"huilees","lemma":"huiler","pos":"VER"} ,
		{"word":"huilés","word_nosc":"huiles","lemma":"huiler","pos":"VER"} ,
		{"word":"hulula","word_nosc":"hulula","lemma":"hululer","pos":"VER"} ,
		{"word":"hululaient","word_nosc":"hululaient","lemma":"hululer","pos":"VER"} ,
		{"word":"hululait","word_nosc":"hululait","lemma":"hululer","pos":"VER"} ,
		{"word":"hululant","word_nosc":"hululant","lemma":"hululer","pos":"VER"} ,
		{"word":"hulule","word_nosc":"hulule","lemma":"hululer","pos":"VER"} ,
		{"word":"hululent","word_nosc":"hululent","lemma":"hululer","pos":"VER"} ,
		{"word":"hululer","word_nosc":"hululer","lemma":"hululer","pos":"VER"} ,
		{"word":"hululée","word_nosc":"hululee","lemma":"hululer","pos":"VER"} ,
		{"word":"huma","word_nosc":"huma","lemma":"humer","pos":"VER"} ,
		{"word":"humai","word_nosc":"humai","lemma":"humer","pos":"VER"} ,
		{"word":"humaient","word_nosc":"humaient","lemma":"humer","pos":"VER"} ,
		{"word":"humais","word_nosc":"humais","lemma":"humer","pos":"VER"} ,
		{"word":"humait","word_nosc":"humait","lemma":"humer","pos":"VER"} ,
		{"word":"humanisait","word_nosc":"humanisait","lemma":"humaniser","pos":"VER"} ,
		{"word":"humanisant","word_nosc":"humanisant","lemma":"humaniser","pos":"VER"} ,
		{"word":"humanise","word_nosc":"humanise","lemma":"humaniser","pos":"VER"} ,
		{"word":"humanisent","word_nosc":"humanisent","lemma":"humaniser","pos":"VER"} ,
		{"word":"humaniser","word_nosc":"humaniser","lemma":"humaniser","pos":"VER"} ,
		{"word":"humanisé","word_nosc":"humanise","lemma":"humaniser","pos":"VER"} ,
		{"word":"humanisée","word_nosc":"humanisee","lemma":"humaniser","pos":"VER"} ,
		{"word":"humanisées","word_nosc":"humanisees","lemma":"humaniser","pos":"VER"} ,
		{"word":"humanisés","word_nosc":"humanises","lemma":"humaniser","pos":"VER"} ,
		{"word":"humant","word_nosc":"humant","lemma":"humer","pos":"VER"} ,
		{"word":"hume","word_nosc":"hume","lemma":"humer","pos":"VER"} ,
		{"word":"humecta","word_nosc":"humecta","lemma":"humecter","pos":"VER"} ,
		{"word":"humectai","word_nosc":"humectai","lemma":"humecter","pos":"VER"} ,
		{"word":"humectaient","word_nosc":"humectaient","lemma":"humecter","pos":"VER"} ,
		{"word":"humectais","word_nosc":"humectais","lemma":"humecter","pos":"VER"} ,
		{"word":"humectait","word_nosc":"humectait","lemma":"humecter","pos":"VER"} ,
		{"word":"humectant","word_nosc":"humectant","lemma":"humecter","pos":"VER"} ,
		{"word":"humecte","word_nosc":"humecte","lemma":"humecter","pos":"VER"} ,
		{"word":"humectent","word_nosc":"humectent","lemma":"humecter","pos":"VER"} ,
		{"word":"humecter","word_nosc":"humecter","lemma":"humecter","pos":"VER"} ,
		{"word":"humecté","word_nosc":"humecte","lemma":"humecter","pos":"VER"} ,
		{"word":"humectée","word_nosc":"humectee","lemma":"humecter","pos":"VER"} ,
		{"word":"humectées","word_nosc":"humectees","lemma":"humecter","pos":"VER"} ,
		{"word":"humectés","word_nosc":"humectes","lemma":"humecter","pos":"VER"} ,
		{"word":"hument","word_nosc":"hument","lemma":"humer","pos":"VER"} ,
		{"word":"humer","word_nosc":"humer","lemma":"humer","pos":"VER"} ,
		{"word":"humeras","word_nosc":"humeras","lemma":"humer","pos":"VER"} ,
		{"word":"humez","word_nosc":"humez","lemma":"humer","pos":"VER"} ,
		{"word":"humidifia","word_nosc":"humidifia","lemma":"humidifier","pos":"VER"} ,
		{"word":"humidifiant","word_nosc":"humidifiant","lemma":"humidifier","pos":"VER"} ,
		{"word":"humidifient","word_nosc":"humidifient","lemma":"humidifier","pos":"VER"} ,
		{"word":"humidifier","word_nosc":"humidifier","lemma":"humidifier","pos":"VER"} ,
		{"word":"humidifié","word_nosc":"humidifie","lemma":"humidifier","pos":"VER"} ,
		{"word":"humidifiés","word_nosc":"humidifies","lemma":"humidifier","pos":"VER"} ,
		{"word":"humilia","word_nosc":"humilia","lemma":"humilier","pos":"VER"} ,
		{"word":"humiliaient","word_nosc":"humiliaient","lemma":"humilier","pos":"VER"} ,
		{"word":"humiliais","word_nosc":"humiliais","lemma":"humilier","pos":"VER"} ,
		{"word":"humiliait","word_nosc":"humiliait","lemma":"humilier","pos":"VER"} ,
		{"word":"humiliant","word_nosc":"humiliant","lemma":"humilier","pos":"VER"} ,
		{"word":"humiliassent","word_nosc":"humiliassent","lemma":"humilier","pos":"VER"} ,
		{"word":"humilie","word_nosc":"humilie","lemma":"humilier","pos":"VER"} ,
		{"word":"humilient","word_nosc":"humilient","lemma":"humilier","pos":"VER"} ,
		{"word":"humilier","word_nosc":"humilier","lemma":"humilier","pos":"VER"} ,
		{"word":"humiliera","word_nosc":"humiliera","lemma":"humilier","pos":"VER"} ,
		{"word":"humilierai","word_nosc":"humilierai","lemma":"humilier","pos":"VER"} ,
		{"word":"humilieraient","word_nosc":"humilieraient","lemma":"humilier","pos":"VER"} ,
		{"word":"humilierais","word_nosc":"humilierais","lemma":"humilier","pos":"VER"} ,
		{"word":"humilierait","word_nosc":"humilierait","lemma":"humilier","pos":"VER"} ,
		{"word":"humilies","word_nosc":"humilies","lemma":"humilier","pos":"VER"} ,
		{"word":"humiliez","word_nosc":"humiliez","lemma":"humilier","pos":"VER"} ,
		{"word":"humilié","word_nosc":"humilie","lemma":"humilier","pos":"VER"} ,
		{"word":"humiliée","word_nosc":"humiliee","lemma":"humilier","pos":"VER"} ,
		{"word":"humiliées","word_nosc":"humiliees","lemma":"humilier","pos":"VER"} ,
		{"word":"humiliés","word_nosc":"humilies","lemma":"humilier","pos":"VER"} ,
		{"word":"humions","word_nosc":"humions","lemma":"humer","pos":"VER"} ,
		{"word":"humons","word_nosc":"humons","lemma":"humer","pos":"VER"} ,
		{"word":"humèrent","word_nosc":"humerent","lemma":"humer","pos":"VER"} ,
		{"word":"humé","word_nosc":"hume","lemma":"humer","pos":"VER"} ,
		{"word":"humée","word_nosc":"humee","lemma":"humer","pos":"VER"} ,
		{"word":"humés","word_nosc":"humes","lemma":"humer","pos":"VER"} ,
		{"word":"hurla","word_nosc":"hurla","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlai","word_nosc":"hurlai","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlaient","word_nosc":"hurlaient","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlais","word_nosc":"hurlais","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlait","word_nosc":"hurlait","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlant","word_nosc":"hurlant","lemma":"hurler","pos":"VER"} ,
		{"word":"hurle","word_nosc":"hurle","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlent","word_nosc":"hurlent","lemma":"hurler","pos":"VER"} ,
		{"word":"hurler","word_nosc":"hurler","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlera","word_nosc":"hurlera","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlerai","word_nosc":"hurlerai","lemma":"hurler","pos":"VER"} ,
		{"word":"hurleraient","word_nosc":"hurleraient","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlerais","word_nosc":"hurlerais","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlerait","word_nosc":"hurlerait","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlerez","word_nosc":"hurlerez","lemma":"hurler","pos":"VER"} ,
		{"word":"hurleront","word_nosc":"hurleront","lemma":"hurler","pos":"VER"} ,
		{"word":"hurles","word_nosc":"hurles","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlez","word_nosc":"hurlez","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlions","word_nosc":"hurlions","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlât","word_nosc":"hurlat","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlèrent","word_nosc":"hurlerent","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlé","word_nosc":"hurle","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlée","word_nosc":"hurlee","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlées","word_nosc":"hurlees","lemma":"hurler","pos":"VER"} ,
		{"word":"hurlés","word_nosc":"hurles","lemma":"hurler","pos":"VER"} ,
		{"word":"hutter","word_nosc":"hutter","lemma":"hutter","pos":"VER"} ,
		{"word":"huèrent","word_nosc":"huerent","lemma":"huer","pos":"VER"} ,
		{"word":"hué","word_nosc":"hue","lemma":"huer","pos":"VER"} ,
		{"word":"huée","word_nosc":"huee","lemma":"huer","pos":"VER"} ,
		{"word":"huées","word_nosc":"huees","lemma":"huer","pos":"VER"} ,
		{"word":"hués","word_nosc":"hues","lemma":"huer","pos":"VER"} ,
		{"word":"hybridé","word_nosc":"hybride","lemma":"hybrider","pos":"VER"} ,
		{"word":"hydratant","word_nosc":"hydratant","lemma":"hydrater","pos":"VER"} ,
		{"word":"hydrate","word_nosc":"hydrate","lemma":"hydrater","pos":"VER"} ,
		{"word":"hydrater","word_nosc":"hydrater","lemma":"hydrater","pos":"VER"} ,
		{"word":"hydratez","word_nosc":"hydratez","lemma":"hydrater","pos":"VER"} ,
		{"word":"hydraté","word_nosc":"hydrate","lemma":"hydrater","pos":"VER"} ,
		{"word":"hydratée","word_nosc":"hydratee","lemma":"hydrater","pos":"VER"} ,
		{"word":"hypertrophie","word_nosc":"hypertrophie","lemma":"hypertrophier","pos":"VER"} ,
		{"word":"hypertrophié","word_nosc":"hypertrophie","lemma":"hypertrophier","pos":"VER"} ,
		{"word":"hypertrophiée","word_nosc":"hypertrophiee","lemma":"hypertrophier","pos":"VER"} ,
		{"word":"hypertrophiés","word_nosc":"hypertrophies","lemma":"hypertrophier","pos":"VER"} ,
		{"word":"hypnotisa","word_nosc":"hypnotisa","lemma":"hypnotiser","pos":"VER"} ,
		{"word":"hypnotisaient","word_nosc":"hypnotisaient","lemma":"hypnotiser","pos":"VER"} ,
		{"word":"hypnotisais","word_nosc":"hypnotisais","lemma":"hypnotiser","pos":"VER"} ,
		{"word":"hypnotisait","word_nosc":"hypnotisait","lemma":"hypnotiser","pos":"VER"} ,
		{"word":"hypnotisant","word_nosc":"hypnotisant","lemma":"hypnotiser","pos":"VER"} ,
		{"word":"hypnotise","word_nosc":"hypnotise","lemma":"hypnotiser","pos":"VER"} ,
		{"word":"hypnotisent","word_nosc":"hypnotisent","lemma":"hypnotiser","pos":"VER"} ,
		{"word":"hypnotiser","word_nosc":"hypnotiser","lemma":"hypnotiser","pos":"VER"} ,
		{"word":"hypnotisé","word_nosc":"hypnotise","lemma":"hypnotiser","pos":"VER"} ,
		{"word":"hypnotisée","word_nosc":"hypnotisee","lemma":"hypnotiser","pos":"VER"} ,
		{"word":"hypnotisées","word_nosc":"hypnotisees","lemma":"hypnotiser","pos":"VER"} ,
		{"word":"hypnotisés","word_nosc":"hypnotises","lemma":"hypnotiser","pos":"VER"} ,
		{"word":"hypothèque","word_nosc":"hypotheque","lemma":"hypothéquer","pos":"VER"} ,
		{"word":"hypothèquent","word_nosc":"hypothequent","lemma":"hypothéquer","pos":"VER"} ,
		{"word":"hypothèques","word_nosc":"hypotheques","lemma":"hypothéquer","pos":"VER"} ,
		{"word":"hypothéquait","word_nosc":"hypothequait","lemma":"hypothéquer","pos":"VER"} ,
		{"word":"hypothéquant","word_nosc":"hypothequant","lemma":"hypothéquer","pos":"VER"} ,
		{"word":"hypothéquer","word_nosc":"hypothequer","lemma":"hypothéquer","pos":"VER"} ,
		{"word":"hypothéqueraient","word_nosc":"hypothequeraient","lemma":"hypothéquer","pos":"VER"} ,
		{"word":"hypothéquez","word_nosc":"hypothequez","lemma":"hypothéquer","pos":"VER"} ,
		{"word":"hypothéquions","word_nosc":"hypothequions","lemma":"hypothéquer","pos":"VER"} ,
		{"word":"hypothéqué","word_nosc":"hypotheque","lemma":"hypothéquer","pos":"VER"} ,
		{"word":"hypothéquée","word_nosc":"hypothequee","lemma":"hypothéquer","pos":"VER"} ,
		{"word":"hâlait","word_nosc":"halait","lemma":"hâler","pos":"VER"} ,
		{"word":"hâlant","word_nosc":"halant","lemma":"hâler","pos":"VER"} ,
		{"word":"hâlé","word_nosc":"hale","lemma":"hâler","pos":"VER"} ,
		{"word":"hâlée","word_nosc":"halee","lemma":"hâler","pos":"VER"} ,
		{"word":"hâlées","word_nosc":"halees","lemma":"hâler","pos":"VER"} ,
		{"word":"hâlés","word_nosc":"hales","lemma":"hâler","pos":"VER"} ,
		{"word":"hâta","word_nosc":"hata","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtai","word_nosc":"hatai","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtaient","word_nosc":"hataient","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtais","word_nosc":"hatais","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtait","word_nosc":"hatait","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtant","word_nosc":"hatant","lemma":"hâter","pos":"VER"} ,
		{"word":"hâte","word_nosc":"hate","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtent","word_nosc":"hatent","lemma":"hâter","pos":"VER"} ,
		{"word":"hâter","word_nosc":"hater","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtera","word_nosc":"hatera","lemma":"hâter","pos":"VER"} ,
		{"word":"hâteraient","word_nosc":"hateraient","lemma":"hâter","pos":"VER"} ,
		{"word":"hâterait","word_nosc":"haterait","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtes","word_nosc":"hates","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtez","word_nosc":"hatez","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtions","word_nosc":"hations","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtons","word_nosc":"hatons","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtâmes","word_nosc":"hatames","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtât","word_nosc":"hatat","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtèrent","word_nosc":"haterent","lemma":"hâter","pos":"VER"} ,
		{"word":"hâté","word_nosc":"hate","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtée","word_nosc":"hatee","lemma":"hâter","pos":"VER"} ,
		{"word":"hâtés","word_nosc":"hates","lemma":"hâter","pos":"VER"} ,
		{"word":"hèle","word_nosc":"hele","lemma":"héler","pos":"VER"} ,
		{"word":"héberge","word_nosc":"heberge","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergea","word_nosc":"hebergea","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergeai","word_nosc":"hebergeai","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergeaient","word_nosc":"hebergeaient","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergeais","word_nosc":"hebergeais","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergeait","word_nosc":"hebergeait","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergeant","word_nosc":"hebergeant","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergent","word_nosc":"hebergent","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergeons","word_nosc":"hebergeons","lemma":"héberger","pos":"VER"} ,
		{"word":"héberger","word_nosc":"heberger","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergera","word_nosc":"hebergera","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergerait","word_nosc":"hebergerait","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergeras","word_nosc":"hebergeras","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergez","word_nosc":"hebergez","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergiez","word_nosc":"hebergiez","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergions","word_nosc":"hebergions","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergé","word_nosc":"heberge","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergée","word_nosc":"hebergee","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergées","word_nosc":"hebergees","lemma":"héberger","pos":"VER"} ,
		{"word":"hébergés","word_nosc":"heberges","lemma":"héberger","pos":"VER"} ,
		{"word":"hébètent","word_nosc":"hebetent","lemma":"hébéter","pos":"VER"} ,
		{"word":"hébéta","word_nosc":"hebeta","lemma":"hébéter","pos":"VER"} ,
		{"word":"hébétait","word_nosc":"hebetait","lemma":"hébéter","pos":"VER"} ,
		{"word":"hébété","word_nosc":"hebete","lemma":"hébéter","pos":"VER"} ,
		{"word":"hébétée","word_nosc":"hebetee","lemma":"hébéter","pos":"VER"} ,
		{"word":"hébétées","word_nosc":"hebetees","lemma":"hébéter","pos":"VER"} ,
		{"word":"hébétés","word_nosc":"hebetes","lemma":"hébéter","pos":"VER"} ,
		{"word":"héla","word_nosc":"hela","lemma":"héler","pos":"VER"} ,
		{"word":"hélai","word_nosc":"helai","lemma":"héler","pos":"VER"} ,
		{"word":"hélaient","word_nosc":"helaient","lemma":"héler","pos":"VER"} ,
		{"word":"hélait","word_nosc":"helait","lemma":"héler","pos":"VER"} ,
		{"word":"hélant","word_nosc":"helant","lemma":"héler","pos":"VER"} ,
		{"word":"hélas","word_nosc":"helas","lemma":"héler","pos":"VER"} ,
		{"word":"héler","word_nosc":"heler","lemma":"héler","pos":"VER"} ,
		{"word":"hélez","word_nosc":"helez","lemma":"héler","pos":"VER"} ,
		{"word":"héliporter","word_nosc":"heliporter","lemma":"héliporter","pos":"VER"} ,
		{"word":"héliporté","word_nosc":"heliporte","lemma":"héliporter","pos":"VER"} ,
		{"word":"héliportée","word_nosc":"heliportee","lemma":"héliporter","pos":"VER"} ,
		{"word":"héliportés","word_nosc":"heliportes","lemma":"héliporter","pos":"VER"} ,
		{"word":"hélitreuiller","word_nosc":"helitreuiller","lemma":"hélitreuiller","pos":"VER"} ,
		{"word":"hélèrent","word_nosc":"helerent","lemma":"héler","pos":"VER"} ,
		{"word":"hélé","word_nosc":"hele","lemma":"héler","pos":"VER"} ,
		{"word":"hélés","word_nosc":"heles","lemma":"héler","pos":"VER"} ,
		{"word":"hérissa","word_nosc":"herissa","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérissaient","word_nosc":"herissaient","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérissait","word_nosc":"herissait","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérissant","word_nosc":"herissant","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérisse","word_nosc":"herisse","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérissent","word_nosc":"herissent","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérisser","word_nosc":"herisser","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérisseraient","word_nosc":"herisseraient","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérisses","word_nosc":"herisses","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérissons","word_nosc":"herissons","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérissât","word_nosc":"herissat","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérissèrent","word_nosc":"herisserent","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérissé","word_nosc":"herisse","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérissée","word_nosc":"herissee","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérissées","word_nosc":"herissees","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérissés","word_nosc":"herisses","lemma":"hérisser","pos":"VER"} ,
		{"word":"hérita","word_nosc":"herita","lemma":"hériter","pos":"VER"} ,
		{"word":"héritai","word_nosc":"heritai","lemma":"hériter","pos":"VER"} ,
		{"word":"héritaient","word_nosc":"heritaient","lemma":"hériter","pos":"VER"} ,
		{"word":"héritais","word_nosc":"heritais","lemma":"hériter","pos":"VER"} ,
		{"word":"héritait","word_nosc":"heritait","lemma":"hériter","pos":"VER"} ,
		{"word":"hérite","word_nosc":"herite","lemma":"hériter","pos":"VER"} ,
		{"word":"héritent","word_nosc":"heritent","lemma":"hériter","pos":"VER"} ,
		{"word":"hériter","word_nosc":"heriter","lemma":"hériter","pos":"VER"} ,
		{"word":"héritera","word_nosc":"heritera","lemma":"hériter","pos":"VER"} ,
		{"word":"hériterai","word_nosc":"heriterai","lemma":"hériter","pos":"VER"} ,
		{"word":"hériteraient","word_nosc":"heriteraient","lemma":"hériter","pos":"VER"} ,
		{"word":"hériterait","word_nosc":"heriterait","lemma":"hériter","pos":"VER"} ,
		{"word":"hériteras","word_nosc":"heriteras","lemma":"hériter","pos":"VER"} ,
		{"word":"hériterez","word_nosc":"heriterez","lemma":"hériter","pos":"VER"} ,
		{"word":"hériteront","word_nosc":"heriteront","lemma":"hériter","pos":"VER"} ,
		{"word":"hérites","word_nosc":"herites","lemma":"hériter","pos":"VER"} ,
		{"word":"héritez","word_nosc":"heritez","lemma":"hériter","pos":"VER"} ,
		{"word":"héritons","word_nosc":"heritons","lemma":"hériter","pos":"VER"} ,
		{"word":"hérité","word_nosc":"herite","lemma":"hériter","pos":"VER"} ,
		{"word":"héritée","word_nosc":"heritee","lemma":"hériter","pos":"VER"} ,
		{"word":"héritées","word_nosc":"heritees","lemma":"hériter","pos":"VER"} ,
		{"word":"hérités","word_nosc":"herites","lemma":"hériter","pos":"VER"} ,
		{"word":"hésita","word_nosc":"hesita","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésitai","word_nosc":"hesitai","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésitaient","word_nosc":"hesitaient","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésitais","word_nosc":"hesitais","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésitait","word_nosc":"hesitait","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésitant","word_nosc":"hesitant","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésite","word_nosc":"hesite","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésitent","word_nosc":"hesitent","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésiter","word_nosc":"hesiter","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésitera","word_nosc":"hesitera","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésiterai","word_nosc":"hesiterai","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésiteraient","word_nosc":"hesiteraient","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésiterais","word_nosc":"hesiterais","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésiterait","word_nosc":"hesiterait","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésiteras","word_nosc":"hesiteras","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésiterez","word_nosc":"hesiterez","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésiteriez","word_nosc":"hesiteriez","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésiterions","word_nosc":"hesiterions","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésiterons","word_nosc":"hesiterons","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésiteront","word_nosc":"hesiteront","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésites","word_nosc":"hesites","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésitez","word_nosc":"hesitez","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésitiez","word_nosc":"hesitiez","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésitions","word_nosc":"hesitions","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésitons","word_nosc":"hesitons","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésitât","word_nosc":"hesitat","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésitèrent","word_nosc":"hesiterent","lemma":"hésiter","pos":"VER"} ,
		{"word":"hésité","word_nosc":"hesite","lemma":"hésiter","pos":"VER"} ,
		{"word":"identifia","word_nosc":"identifia","lemma":"identifier","pos":"VER"} ,
		{"word":"identifiai","word_nosc":"identifiai","lemma":"identifier","pos":"VER"} ,
		{"word":"identifiaient","word_nosc":"identifiaient","lemma":"identifier","pos":"VER"} ,
		{"word":"identifiais","word_nosc":"identifiais","lemma":"identifier","pos":"VER"} ,
		{"word":"identifiait","word_nosc":"identifiait","lemma":"identifier","pos":"VER"} ,
		{"word":"identifiant","word_nosc":"identifiant","lemma":"identifier","pos":"VER"} ,
		{"word":"identifiassent","word_nosc":"identifiassent","lemma":"identifier","pos":"VER"} ,
		{"word":"identifie","word_nosc":"identifie","lemma":"identifier","pos":"VER"} ,
		{"word":"identifient","word_nosc":"identifient","lemma":"identifier","pos":"VER"} ,
		{"word":"identifier","word_nosc":"identifier","lemma":"identifier","pos":"VER"} ,
		{"word":"identifiera","word_nosc":"identifiera","lemma":"identifier","pos":"VER"} ,
		{"word":"identifierai","word_nosc":"identifierai","lemma":"identifier","pos":"VER"} ,
		{"word":"identifierais","word_nosc":"identifierais","lemma":"identifier","pos":"VER"} ,
		{"word":"identifierait","word_nosc":"identifierait","lemma":"identifier","pos":"VER"} ,
		{"word":"identifierez","word_nosc":"identifierez","lemma":"identifier","pos":"VER"} ,
		{"word":"identifieront","word_nosc":"identifieront","lemma":"identifier","pos":"VER"} ,
		{"word":"identifiez","word_nosc":"identifiez","lemma":"identifier","pos":"VER"} ,
		{"word":"identifions","word_nosc":"identifions","lemma":"identifier","pos":"VER"} ,
		{"word":"identifiât","word_nosc":"identifiat","lemma":"identifier","pos":"VER"} ,
		{"word":"identifièrent","word_nosc":"identifierent","lemma":"identifier","pos":"VER"} ,
		{"word":"identifié","word_nosc":"identifie","lemma":"identifier","pos":"VER"} ,
		{"word":"identifiée","word_nosc":"identifiee","lemma":"identifier","pos":"VER"} ,
		{"word":"identifiées","word_nosc":"identifiees","lemma":"identifier","pos":"VER"} ,
		{"word":"identifiés","word_nosc":"identifies","lemma":"identifier","pos":"VER"} ,
		{"word":"idolâtraient","word_nosc":"idolatraient","lemma":"idolâtrer","pos":"VER"} ,
		{"word":"idolâtrait","word_nosc":"idolatrait","lemma":"idolâtrer","pos":"VER"} ,
		{"word":"idolâtrant","word_nosc":"idolatrant","lemma":"idolâtrer","pos":"VER"} ,
		{"word":"idolâtre","word_nosc":"idolatre","lemma":"idolâtrer","pos":"VER"} ,
		{"word":"idolâtrent","word_nosc":"idolatrent","lemma":"idolâtrer","pos":"VER"} ,
		{"word":"idolâtrer","word_nosc":"idolatrer","lemma":"idolâtrer","pos":"VER"} ,
		{"word":"idolâtres","word_nosc":"idolatres","lemma":"idolâtrer","pos":"VER"} ,
		{"word":"idolâtré","word_nosc":"idolatre","lemma":"idolâtrer","pos":"VER"} ,
		{"word":"idolâtrée","word_nosc":"idolatree","lemma":"idolâtrer","pos":"VER"} ,
		{"word":"idéalisait","word_nosc":"idealisait","lemma":"idéaliser","pos":"VER"} ,
		{"word":"idéalisant","word_nosc":"idealisant","lemma":"idéaliser","pos":"VER"} ,
		{"word":"idéalise","word_nosc":"idealise","lemma":"idéaliser","pos":"VER"} ,
		{"word":"idéalisent","word_nosc":"idealisent","lemma":"idéaliser","pos":"VER"} ,
		{"word":"idéaliser","word_nosc":"idealiser","lemma":"idéaliser","pos":"VER"} ,
		{"word":"idéalisé","word_nosc":"idealise","lemma":"idéaliser","pos":"VER"} ,
		{"word":"idéalisée","word_nosc":"idealisee","lemma":"idéaliser","pos":"VER"} ,
		{"word":"idéalisées","word_nosc":"idealisees","lemma":"idéaliser","pos":"VER"} ,
		{"word":"idéalisés","word_nosc":"idealises","lemma":"idéaliser","pos":"VER"} ,
		{"word":"ignifugeant","word_nosc":"ignifugeant","lemma":"ignifuger","pos":"VER"} ,
		{"word":"ignifuger","word_nosc":"ignifuger","lemma":"ignifuger","pos":"VER"} ,
		{"word":"ignifugé","word_nosc":"ignifuge","lemma":"ignifuger","pos":"VER"} ,
		{"word":"ignifugées","word_nosc":"ignifugees","lemma":"ignifuger","pos":"VER"} ,
		{"word":"ignora","word_nosc":"ignora","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorai","word_nosc":"ignorai","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignoraient","word_nosc":"ignoraient","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorais","word_nosc":"ignorais","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorait","word_nosc":"ignorait","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorant","word_nosc":"ignorant","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorassent","word_nosc":"ignorassent","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignore","word_nosc":"ignore","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorent","word_nosc":"ignorent","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorer","word_nosc":"ignorer","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorera","word_nosc":"ignorera","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorerai","word_nosc":"ignorerai","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignoreraient","word_nosc":"ignoreraient","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorerais","word_nosc":"ignorerais","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorerait","word_nosc":"ignorerait","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignoreras","word_nosc":"ignoreras","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignoreriez","word_nosc":"ignoreriez","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorerons","word_nosc":"ignorerons","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignoreront","word_nosc":"ignoreront","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignores","word_nosc":"ignores","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorez","word_nosc":"ignorez","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignoriez","word_nosc":"ignoriez","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorions","word_nosc":"ignorions","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorons","word_nosc":"ignorons","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorât","word_nosc":"ignorat","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorèrent","word_nosc":"ignorerent","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignoré","word_nosc":"ignore","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorée","word_nosc":"ignoree","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorées","word_nosc":"ignorees","lemma":"ignorer","pos":"VER"} ,
		{"word":"ignorés","word_nosc":"ignores","lemma":"ignorer","pos":"VER"} ,
		{"word":"illumina","word_nosc":"illumina","lemma":"illuminer","pos":"VER"} ,
		{"word":"illuminaient","word_nosc":"illuminaient","lemma":"illuminer","pos":"VER"} ,
		{"word":"illuminait","word_nosc":"illuminait","lemma":"illuminer","pos":"VER"} ,
		{"word":"illuminant","word_nosc":"illuminant","lemma":"illuminer","pos":"VER"} ,
		{"word":"illumine","word_nosc":"illumine","lemma":"illuminer","pos":"VER"} ,
		{"word":"illuminent","word_nosc":"illuminent","lemma":"illuminer","pos":"VER"} ,
		{"word":"illuminer","word_nosc":"illuminer","lemma":"illuminer","pos":"VER"} ,
		{"word":"illuminera","word_nosc":"illuminera","lemma":"illuminer","pos":"VER"} ,
		{"word":"illuminerai","word_nosc":"illuminerai","lemma":"illuminer","pos":"VER"} ,
		{"word":"illuminerait","word_nosc":"illuminerait","lemma":"illuminer","pos":"VER"} ,
		{"word":"illumineront","word_nosc":"illumineront","lemma":"illuminer","pos":"VER"} ,
		{"word":"illuminez","word_nosc":"illuminez","lemma":"illuminer","pos":"VER"} ,
		{"word":"illuminèrent","word_nosc":"illuminerent","lemma":"illuminer","pos":"VER"} ,
		{"word":"illuminé","word_nosc":"illumine","lemma":"illuminer","pos":"VER"} ,
		{"word":"illuminée","word_nosc":"illuminee","lemma":"illuminer","pos":"VER"} ,
		{"word":"illuminées","word_nosc":"illuminees","lemma":"illuminer","pos":"VER"} ,
		{"word":"illuminés","word_nosc":"illumines","lemma":"illuminer","pos":"VER"} ,
		{"word":"illusionnait","word_nosc":"illusionnait","lemma":"illusionner","pos":"VER"} ,
		{"word":"illusionne","word_nosc":"illusionne","lemma":"illusionner","pos":"VER"} ,
		{"word":"illusionnent","word_nosc":"illusionnent","lemma":"illusionner","pos":"VER"} ,
		{"word":"illusionner","word_nosc":"illusionner","lemma":"illusionner","pos":"VER"} ,
		{"word":"illusionnez","word_nosc":"illusionnez","lemma":"illusionner","pos":"VER"} ,
		{"word":"illustra","word_nosc":"illustra","lemma":"illustrer","pos":"VER"} ,
		{"word":"illustraient","word_nosc":"illustraient","lemma":"illustrer","pos":"VER"} ,
		{"word":"illustrait","word_nosc":"illustrait","lemma":"illustrer","pos":"VER"} ,
		{"word":"illustrant","word_nosc":"illustrant","lemma":"illustrer","pos":"VER"} ,
		{"word":"illustre","word_nosc":"illustre","lemma":"illustrer","pos":"VER"} ,
		{"word":"illustrent","word_nosc":"illustrent","lemma":"illustrer","pos":"VER"} ,
		{"word":"illustrer","word_nosc":"illustrer","lemma":"illustrer","pos":"VER"} ,
		{"word":"illustrera","word_nosc":"illustrera","lemma":"illustrer","pos":"VER"} ,
		{"word":"illustrerai","word_nosc":"illustrerai","lemma":"illustrer","pos":"VER"} ,
		{"word":"illustrerait","word_nosc":"illustrerait","lemma":"illustrer","pos":"VER"} ,
		{"word":"illustrions","word_nosc":"illustrions","lemma":"illustrer","pos":"VER"} ,
		{"word":"illustré","word_nosc":"illustre","lemma":"illustrer","pos":"VER"} ,
		{"word":"illustrée","word_nosc":"illustree","lemma":"illustrer","pos":"VER"} ,
		{"word":"illustrées","word_nosc":"illustrees","lemma":"illustrer","pos":"VER"} ,
		{"word":"illustrés","word_nosc":"illustres","lemma":"illustrer","pos":"VER"} ,
		{"word":"image","word_nosc":"image","lemma":"imager","pos":"VER"} ,
		{"word":"imageant","word_nosc":"imageant","lemma":"imager","pos":"VER"} ,
		{"word":"imager","word_nosc":"imager","lemma":"imager","pos":"VER"} ,
		{"word":"imagina","word_nosc":"imagina","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginai","word_nosc":"imaginai","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginaient","word_nosc":"imaginaient","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginais","word_nosc":"imaginais","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginait","word_nosc":"imaginait","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginant","word_nosc":"imaginant","lemma":"imaginer","pos":"VER"} ,
		{"word":"imagine","word_nosc":"imagine","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginent","word_nosc":"imaginent","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginer","word_nosc":"imaginer","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginerai","word_nosc":"imaginerai","lemma":"imaginer","pos":"VER"} ,
		{"word":"imagineraient","word_nosc":"imagineraient","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginerais","word_nosc":"imaginerais","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginerait","word_nosc":"imaginerait","lemma":"imaginer","pos":"VER"} ,
		{"word":"imagineras","word_nosc":"imagineras","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginerez","word_nosc":"imaginerez","lemma":"imaginer","pos":"VER"} ,
		{"word":"imagineriez","word_nosc":"imagineriez","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginerons","word_nosc":"imaginerons","lemma":"imaginer","pos":"VER"} ,
		{"word":"imagineront","word_nosc":"imagineront","lemma":"imaginer","pos":"VER"} ,
		{"word":"imagines","word_nosc":"imagines","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginez","word_nosc":"imaginez","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginiez","word_nosc":"imaginiez","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginions","word_nosc":"imaginions","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginons","word_nosc":"imaginons","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginâmes","word_nosc":"imaginames","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginât","word_nosc":"imaginat","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginèrent","word_nosc":"imaginerent","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginé","word_nosc":"imagine","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginée","word_nosc":"imaginee","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginées","word_nosc":"imaginees","lemma":"imaginer","pos":"VER"} ,
		{"word":"imaginés","word_nosc":"imagines","lemma":"imaginer","pos":"VER"} ,
		{"word":"imagé","word_nosc":"image","lemma":"imager","pos":"VER"} ,
		{"word":"imagée","word_nosc":"imagee","lemma":"imager","pos":"VER"} ,
		{"word":"imagées","word_nosc":"imagees","lemma":"imager","pos":"VER"} ,
		{"word":"imagés","word_nosc":"images","lemma":"imager","pos":"VER"} ,
		{"word":"imbiba","word_nosc":"imbiba","lemma":"imbiber","pos":"VER"} ,
		{"word":"imbibaient","word_nosc":"imbibaient","lemma":"imbiber","pos":"VER"} ,
		{"word":"imbibais","word_nosc":"imbibais","lemma":"imbiber","pos":"VER"} ,
		{"word":"imbibait","word_nosc":"imbibait","lemma":"imbiber","pos":"VER"} ,
		{"word":"imbibant","word_nosc":"imbibant","lemma":"imbiber","pos":"VER"} ,
		{"word":"imbibe","word_nosc":"imbibe","lemma":"imbiber","pos":"VER"} ,
		{"word":"imbiber","word_nosc":"imbiber","lemma":"imbiber","pos":"VER"} ,
		{"word":"imbiberai","word_nosc":"imbiberai","lemma":"imbiber","pos":"VER"} ,
		{"word":"imbiberais","word_nosc":"imbiberais","lemma":"imbiber","pos":"VER"} ,
		{"word":"imbiberait","word_nosc":"imbiberait","lemma":"imbiber","pos":"VER"} ,
		{"word":"imbibé","word_nosc":"imbibe","lemma":"imbiber","pos":"VER"} ,
		{"word":"imbibée","word_nosc":"imbibee","lemma":"imbiber","pos":"VER"} ,
		{"word":"imbibées","word_nosc":"imbibees","lemma":"imbiber","pos":"VER"} ,
		{"word":"imbibés","word_nosc":"imbibes","lemma":"imbiber","pos":"VER"} ,
		{"word":"imbriquaient","word_nosc":"imbriquaient","lemma":"imbriquer","pos":"VER"} ,
		{"word":"imbriquait","word_nosc":"imbriquait","lemma":"imbriquer","pos":"VER"} ,
		{"word":"imbriquant","word_nosc":"imbriquant","lemma":"imbriquer","pos":"VER"} ,
		{"word":"imbrique","word_nosc":"imbrique","lemma":"imbriquer","pos":"VER"} ,
		{"word":"imbriquent","word_nosc":"imbriquent","lemma":"imbriquer","pos":"VER"} ,
		{"word":"imbriquer","word_nosc":"imbriquer","lemma":"imbriquer","pos":"VER"} ,
		{"word":"imbriquée","word_nosc":"imbriquee","lemma":"imbriquer","pos":"VER"} ,
		{"word":"imbriquées","word_nosc":"imbriquees","lemma":"imbriquer","pos":"VER"} ,
		{"word":"imbriqués","word_nosc":"imbriques","lemma":"imbriquer","pos":"VER"} ,
		{"word":"imita","word_nosc":"imita","lemma":"imiter","pos":"VER"} ,
		{"word":"imitai","word_nosc":"imitai","lemma":"imiter","pos":"VER"} ,
		{"word":"imitaient","word_nosc":"imitaient","lemma":"imiter","pos":"VER"} ,
		{"word":"imitais","word_nosc":"imitais","lemma":"imiter","pos":"VER"} ,
		{"word":"imitait","word_nosc":"imitait","lemma":"imiter","pos":"VER"} ,
		{"word":"imitant","word_nosc":"imitant","lemma":"imiter","pos":"VER"} ,
		{"word":"imite","word_nosc":"imite","lemma":"imiter","pos":"VER"} ,
		{"word":"imitent","word_nosc":"imitent","lemma":"imiter","pos":"VER"} ,
		{"word":"imiter","word_nosc":"imiter","lemma":"imiter","pos":"VER"} ,
		{"word":"imitera","word_nosc":"imitera","lemma":"imiter","pos":"VER"} ,
		{"word":"imiterai","word_nosc":"imiterai","lemma":"imiter","pos":"VER"} ,
		{"word":"imiteraient","word_nosc":"imiteraient","lemma":"imiter","pos":"VER"} ,
		{"word":"imiterais","word_nosc":"imiterais","lemma":"imiter","pos":"VER"} ,
		{"word":"imiterait","word_nosc":"imiterait","lemma":"imiter","pos":"VER"} ,
		{"word":"imiteront","word_nosc":"imiteront","lemma":"imiter","pos":"VER"} ,
		{"word":"imites","word_nosc":"imites","lemma":"imiter","pos":"VER"} ,
		{"word":"imitez","word_nosc":"imitez","lemma":"imiter","pos":"VER"} ,
		{"word":"imitiez","word_nosc":"imitiez","lemma":"imiter","pos":"VER"} ,
		{"word":"imitions","word_nosc":"imitions","lemma":"imiter","pos":"VER"} ,
		{"word":"imitons","word_nosc":"imitons","lemma":"imiter","pos":"VER"} ,
		{"word":"imitâmes","word_nosc":"imitames","lemma":"imiter","pos":"VER"} ,
		{"word":"imitât","word_nosc":"imitat","lemma":"imiter","pos":"VER"} ,
		{"word":"imitèrent","word_nosc":"imiterent","lemma":"imiter","pos":"VER"} ,
		{"word":"imité","word_nosc":"imite","lemma":"imiter","pos":"VER"} ,
		{"word":"imitée","word_nosc":"imitee","lemma":"imiter","pos":"VER"} ,
		{"word":"imitées","word_nosc":"imitees","lemma":"imiter","pos":"VER"} ,
		{"word":"imités","word_nosc":"imites","lemma":"imiter","pos":"VER"} ,
		{"word":"immatriculer","word_nosc":"immatriculer","lemma":"immatriculer","pos":"VER"} ,
		{"word":"immatriculé","word_nosc":"immatricule","lemma":"immatriculer","pos":"VER"} ,
		{"word":"immatriculée","word_nosc":"immatriculee","lemma":"immatriculer","pos":"VER"} ,
		{"word":"immatriculées","word_nosc":"immatriculees","lemma":"immatriculer","pos":"VER"} ,
		{"word":"immerge","word_nosc":"immerge","lemma":"immerger","pos":"VER"} ,
		{"word":"immergea","word_nosc":"immergea","lemma":"immerger","pos":"VER"} ,
		{"word":"immergeait","word_nosc":"immergeait","lemma":"immerger","pos":"VER"} ,
		{"word":"immergeant","word_nosc":"immergeant","lemma":"immerger","pos":"VER"} ,
		{"word":"immergent","word_nosc":"immergent","lemma":"immerger","pos":"VER"} ,
		{"word":"immergeons","word_nosc":"immergeons","lemma":"immerger","pos":"VER"} ,
		{"word":"immerger","word_nosc":"immerger","lemma":"immerger","pos":"VER"} ,
		{"word":"immergerons","word_nosc":"immergerons","lemma":"immerger","pos":"VER"} ,
		{"word":"immergez","word_nosc":"immergez","lemma":"immerger","pos":"VER"} ,
		{"word":"immergiez","word_nosc":"immergiez","lemma":"immerger","pos":"VER"} ,
		{"word":"immergèrent","word_nosc":"immergerent","lemma":"immerger","pos":"VER"} ,
		{"word":"immergé","word_nosc":"immerge","lemma":"immerger","pos":"VER"} ,
		{"word":"immergée","word_nosc":"immergee","lemma":"immerger","pos":"VER"} ,
		{"word":"immergées","word_nosc":"immergees","lemma":"immerger","pos":"VER"} ,
		{"word":"immergés","word_nosc":"immerges","lemma":"immerger","pos":"VER"} ,
		{"word":"immigrai","word_nosc":"immigrai","lemma":"immigrer","pos":"VER"} ,
		{"word":"immigrer","word_nosc":"immigrer","lemma":"immigrer","pos":"VER"} ,
		{"word":"immigré","word_nosc":"immigre","lemma":"immigrer","pos":"VER"} ,
		{"word":"immigrées","word_nosc":"immigrees","lemma":"immigrer","pos":"VER"} ,
		{"word":"immisce","word_nosc":"immisce","lemma":"immiscer","pos":"VER"} ,
		{"word":"immiscent","word_nosc":"immiscent","lemma":"immiscer","pos":"VER"} ,
		{"word":"immiscer","word_nosc":"immiscer","lemma":"immiscer","pos":"VER"} ,
		{"word":"immiscerait","word_nosc":"immiscerait","lemma":"immiscer","pos":"VER"} ,
		{"word":"immisces","word_nosc":"immisces","lemma":"immiscer","pos":"VER"} ,
		{"word":"immiscé","word_nosc":"immisce","lemma":"immiscer","pos":"VER"} ,
		{"word":"immiscée","word_nosc":"immiscee","lemma":"immiscer","pos":"VER"} ,
		{"word":"immisçaient","word_nosc":"immiscaient","lemma":"immiscer","pos":"VER"} ,
		{"word":"immisçait","word_nosc":"immiscait","lemma":"immiscer","pos":"VER"} ,
		{"word":"immisçant","word_nosc":"immiscant","lemma":"immiscer","pos":"VER"} ,
		{"word":"immobilisa","word_nosc":"immobilisa","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilisai","word_nosc":"immobilisai","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilisaient","word_nosc":"immobilisaient","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilisais","word_nosc":"immobilisais","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilisait","word_nosc":"immobilisait","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilisant","word_nosc":"immobilisant","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilise","word_nosc":"immobilise","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilisent","word_nosc":"immobilisent","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobiliser","word_nosc":"immobiliser","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilisera","word_nosc":"immobilisera","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobiliserait","word_nosc":"immobiliserait","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobiliseront","word_nosc":"immobiliseront","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilises","word_nosc":"immobilises","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilisez","word_nosc":"immobilisez","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilisons","word_nosc":"immobilisons","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilisèrent","word_nosc":"immobiliserent","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilisé","word_nosc":"immobilise","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilisée","word_nosc":"immobilisee","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilisées","word_nosc":"immobilisees","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immobilisés","word_nosc":"immobilises","lemma":"immobiliser","pos":"VER"} ,
		{"word":"immola","word_nosc":"immola","lemma":"immoler","pos":"VER"} ,
		{"word":"immole","word_nosc":"immole","lemma":"immoler","pos":"VER"} ,
		{"word":"immolent","word_nosc":"immolent","lemma":"immoler","pos":"VER"} ,
		{"word":"immoler","word_nosc":"immoler","lemma":"immoler","pos":"VER"} ,
		{"word":"immolé","word_nosc":"immole","lemma":"immoler","pos":"VER"} ,
		{"word":"immolée","word_nosc":"immolee","lemma":"immoler","pos":"VER"} ,
		{"word":"immolés","word_nosc":"immoles","lemma":"immoler","pos":"VER"} ,
		{"word":"immortalisa","word_nosc":"immortalisa","lemma":"immortaliser","pos":"VER"} ,
		{"word":"immortalisait","word_nosc":"immortalisait","lemma":"immortaliser","pos":"VER"} ,
		{"word":"immortalisant","word_nosc":"immortalisant","lemma":"immortaliser","pos":"VER"} ,
		{"word":"immortalise","word_nosc":"immortalise","lemma":"immortaliser","pos":"VER"} ,
		{"word":"immortaliser","word_nosc":"immortaliser","lemma":"immortaliser","pos":"VER"} ,
		{"word":"immortaliseraient","word_nosc":"immortaliseraient","lemma":"immortaliser","pos":"VER"} ,
		{"word":"immortaliseront","word_nosc":"immortaliseront","lemma":"immortaliser","pos":"VER"} ,
		{"word":"immortalisez","word_nosc":"immortalisez","lemma":"immortaliser","pos":"VER"} ,
		{"word":"immortalisé","word_nosc":"immortalise","lemma":"immortaliser","pos":"VER"} ,
		{"word":"immortalisée","word_nosc":"immortalisee","lemma":"immortaliser","pos":"VER"} ,
		{"word":"immortalisées","word_nosc":"immortalisees","lemma":"immortaliser","pos":"VER"} ,
		{"word":"immortalisés","word_nosc":"immortalises","lemma":"immortaliser","pos":"VER"} ,
		{"word":"immunisait","word_nosc":"immunisait","lemma":"immuniser","pos":"VER"} ,
		{"word":"immunise","word_nosc":"immunise","lemma":"immuniser","pos":"VER"} ,
		{"word":"immunisent","word_nosc":"immunisent","lemma":"immuniser","pos":"VER"} ,
		{"word":"immuniser","word_nosc":"immuniser","lemma":"immuniser","pos":"VER"} ,
		{"word":"immunisé","word_nosc":"immunise","lemma":"immuniser","pos":"VER"} ,
		{"word":"immunisée","word_nosc":"immunisee","lemma":"immuniser","pos":"VER"} ,
		{"word":"immunisés","word_nosc":"immunises","lemma":"immuniser","pos":"VER"} ,
		{"word":"imparti","word_nosc":"imparti","lemma":"impartir","pos":"VER"} ,
		{"word":"impartie","word_nosc":"impartie","lemma":"impartir","pos":"VER"} ,
		{"word":"impartir","word_nosc":"impartir","lemma":"impartir","pos":"VER"} ,
		{"word":"impartis","word_nosc":"impartis","lemma":"impartir","pos":"VER"} ,
		{"word":"impatienta","word_nosc":"impatienta","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatientai","word_nosc":"impatientai","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatientaient","word_nosc":"impatientaient","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatientais","word_nosc":"impatientais","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatientait","word_nosc":"impatientait","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatientant","word_nosc":"impatientant","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatiente","word_nosc":"impatiente","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatientent","word_nosc":"impatientent","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatienter","word_nosc":"impatienter","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatienterait","word_nosc":"impatienterait","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatientes","word_nosc":"impatientes","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatientez","word_nosc":"impatientez","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatientèrent","word_nosc":"impatienterent","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatienté","word_nosc":"impatiente","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatientée","word_nosc":"impatientee","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatientés","word_nosc":"impatientes","lemma":"impatienter","pos":"VER"} ,
		{"word":"impatronisait","word_nosc":"impatronisait","lemma":"impatroniser","pos":"VER"} ,
		{"word":"impatroniser","word_nosc":"impatroniser","lemma":"impatroniser","pos":"VER"} ,
		{"word":"impatronisé","word_nosc":"impatronise","lemma":"impatroniser","pos":"VER"} ,
		{"word":"imperméabiliser","word_nosc":"impermeabiliser","lemma":"imperméabiliser","pos":"VER"} ,
		{"word":"imperméabilisé","word_nosc":"impermeabilise","lemma":"imperméabiliser","pos":"VER"} ,
		{"word":"impersonnaliser","word_nosc":"impersonnaliser","lemma":"impersonnaliser","pos":"VER"} ,
		{"word":"implanta","word_nosc":"implanta","lemma":"implanter","pos":"VER"} ,
		{"word":"implantaient","word_nosc":"implantaient","lemma":"implanter","pos":"VER"} ,
		{"word":"implantais","word_nosc":"implantais","lemma":"implanter","pos":"VER"} ,
		{"word":"implantait","word_nosc":"implantait","lemma":"implanter","pos":"VER"} ,
		{"word":"implantant","word_nosc":"implantant","lemma":"implanter","pos":"VER"} ,
		{"word":"implante","word_nosc":"implante","lemma":"implanter","pos":"VER"} ,
		{"word":"implantent","word_nosc":"implantent","lemma":"implanter","pos":"VER"} ,
		{"word":"implanter","word_nosc":"implanter","lemma":"implanter","pos":"VER"} ,
		{"word":"implantez","word_nosc":"implantez","lemma":"implanter","pos":"VER"} ,
		{"word":"implantiez","word_nosc":"implantiez","lemma":"implanter","pos":"VER"} ,
		{"word":"implantons","word_nosc":"implantons","lemma":"implanter","pos":"VER"} ,
		{"word":"implanté","word_nosc":"implante","lemma":"implanter","pos":"VER"} ,
		{"word":"implantée","word_nosc":"implantee","lemma":"implanter","pos":"VER"} ,
		{"word":"implantées","word_nosc":"implantees","lemma":"implanter","pos":"VER"} ,
		{"word":"implantés","word_nosc":"implantes","lemma":"implanter","pos":"VER"} ,
		{"word":"impliqua","word_nosc":"impliqua","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquaient","word_nosc":"impliquaient","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquait","word_nosc":"impliquait","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquant","word_nosc":"impliquant","lemma":"impliquer","pos":"VER"} ,
		{"word":"implique","word_nosc":"implique","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquent","word_nosc":"impliquent","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquer","word_nosc":"impliquer","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquera","word_nosc":"impliquera","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquerai","word_nosc":"impliquerai","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquerais","word_nosc":"impliquerais","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquerait","word_nosc":"impliquerait","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquerons","word_nosc":"impliquerons","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliqueront","word_nosc":"impliqueront","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquez","word_nosc":"impliquez","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquions","word_nosc":"impliquions","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquons","word_nosc":"impliquons","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquât","word_nosc":"impliquat","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliqué","word_nosc":"implique","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquée","word_nosc":"impliquee","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliquées","word_nosc":"impliquees","lemma":"impliquer","pos":"VER"} ,
		{"word":"impliqués","word_nosc":"impliques","lemma":"impliquer","pos":"VER"} ,
		{"word":"implora","word_nosc":"implora","lemma":"implorer","pos":"VER"} ,
		{"word":"implorai","word_nosc":"implorai","lemma":"implorer","pos":"VER"} ,
		{"word":"imploraient","word_nosc":"imploraient","lemma":"implorer","pos":"VER"} ,
		{"word":"implorais","word_nosc":"implorais","lemma":"implorer","pos":"VER"} ,
		{"word":"implorait","word_nosc":"implorait","lemma":"implorer","pos":"VER"} ,
		{"word":"implorant","word_nosc":"implorant","lemma":"implorer","pos":"VER"} ,
		{"word":"implore","word_nosc":"implore","lemma":"implorer","pos":"VER"} ,
		{"word":"implorent","word_nosc":"implorent","lemma":"implorer","pos":"VER"} ,
		{"word":"implorer","word_nosc":"implorer","lemma":"implorer","pos":"VER"} ,
		{"word":"implorera","word_nosc":"implorera","lemma":"implorer","pos":"VER"} ,
		{"word":"implorerai","word_nosc":"implorerai","lemma":"implorer","pos":"VER"} ,
		{"word":"implorerais","word_nosc":"implorerais","lemma":"implorer","pos":"VER"} ,
		{"word":"implorerez","word_nosc":"implorerez","lemma":"implorer","pos":"VER"} ,
		{"word":"imploreront","word_nosc":"imploreront","lemma":"implorer","pos":"VER"} ,
		{"word":"implorez","word_nosc":"implorez","lemma":"implorer","pos":"VER"} ,
		{"word":"imploriez","word_nosc":"imploriez","lemma":"implorer","pos":"VER"} ,
		{"word":"implorons","word_nosc":"implorons","lemma":"implorer","pos":"VER"} ,
		{"word":"implorèrent","word_nosc":"implorerent","lemma":"implorer","pos":"VER"} ,
		{"word":"imploré","word_nosc":"implore","lemma":"implorer","pos":"VER"} ,
		{"word":"implorée","word_nosc":"imploree","lemma":"implorer","pos":"VER"} ,
		{"word":"implose","word_nosc":"implose","lemma":"imploser","pos":"VER"} ,
		{"word":"implosent","word_nosc":"implosent","lemma":"imploser","pos":"VER"} ,
		{"word":"imploser","word_nosc":"imploser","lemma":"imploser","pos":"VER"} ,
		{"word":"implosera","word_nosc":"implosera","lemma":"imploser","pos":"VER"} ,
		{"word":"implosez","word_nosc":"implosez","lemma":"imploser","pos":"VER"} ,
		{"word":"implémenter","word_nosc":"implementer","lemma":"implémenter","pos":"VER"} ,
		{"word":"importa","word_nosc":"importa","lemma":"importer","pos":"VER"} ,
		{"word":"importaient","word_nosc":"importaient","lemma":"importer","pos":"VER"} ,
		{"word":"importait","word_nosc":"importait","lemma":"importer","pos":"VER"} ,
		{"word":"important","word_nosc":"important","lemma":"importer","pos":"VER"} ,
		{"word":"importe","word_nosc":"importe","lemma":"importer","pos":"VER"} ,
		{"word":"importent","word_nosc":"importent","lemma":"importer","pos":"VER"} ,
		{"word":"importer","word_nosc":"importer","lemma":"importer","pos":"VER"} ,
		{"word":"importera","word_nosc":"importera","lemma":"importer","pos":"VER"} ,
		{"word":"importeraient","word_nosc":"importeraient","lemma":"importer","pos":"VER"} ,
		{"word":"importerait","word_nosc":"importerait","lemma":"importer","pos":"VER"} ,
		{"word":"importes","word_nosc":"importes","lemma":"importer","pos":"VER"} ,
		{"word":"importuna","word_nosc":"importuna","lemma":"importuner","pos":"VER"} ,
		{"word":"importunaient","word_nosc":"importunaient","lemma":"importuner","pos":"VER"} ,
		{"word":"importunais","word_nosc":"importunais","lemma":"importuner","pos":"VER"} ,
		{"word":"importunait","word_nosc":"importunait","lemma":"importuner","pos":"VER"} ,
		{"word":"importune","word_nosc":"importune","lemma":"importuner","pos":"VER"} ,
		{"word":"importunent","word_nosc":"importunent","lemma":"importuner","pos":"VER"} ,
		{"word":"importuner","word_nosc":"importuner","lemma":"importuner","pos":"VER"} ,
		{"word":"importunerai","word_nosc":"importunerai","lemma":"importuner","pos":"VER"} ,
		{"word":"importunerait","word_nosc":"importunerait","lemma":"importuner","pos":"VER"} ,
		{"word":"importunerez","word_nosc":"importunerez","lemma":"importuner","pos":"VER"} ,
		{"word":"importunerons","word_nosc":"importunerons","lemma":"importuner","pos":"VER"} ,
		{"word":"importunes","word_nosc":"importunes","lemma":"importuner","pos":"VER"} ,
		{"word":"importunez","word_nosc":"importunez","lemma":"importuner","pos":"VER"} ,
		{"word":"importunât","word_nosc":"importunat","lemma":"importuner","pos":"VER"} ,
		{"word":"importuné","word_nosc":"importune","lemma":"importuner","pos":"VER"} ,
		{"word":"importunée","word_nosc":"importunee","lemma":"importuner","pos":"VER"} ,
		{"word":"importunés","word_nosc":"importunes","lemma":"importuner","pos":"VER"} ,
		{"word":"importât","word_nosc":"importat","lemma":"importer","pos":"VER"} ,
		{"word":"importé","word_nosc":"importe","lemma":"importer","pos":"VER"} ,
		{"word":"importée","word_nosc":"importee","lemma":"importer","pos":"VER"} ,
		{"word":"importées","word_nosc":"importees","lemma":"importer","pos":"VER"} ,
		{"word":"importés","word_nosc":"importes","lemma":"importer","pos":"VER"} ,
		{"word":"imposa","word_nosc":"imposa","lemma":"imposer","pos":"VER"} ,
		{"word":"imposai","word_nosc":"imposai","lemma":"imposer","pos":"VER"} ,
		{"word":"imposaient","word_nosc":"imposaient","lemma":"imposer","pos":"VER"} ,
		{"word":"imposais","word_nosc":"imposais","lemma":"imposer","pos":"VER"} ,
		{"word":"imposait","word_nosc":"imposait","lemma":"imposer","pos":"VER"} ,
		{"word":"imposant","word_nosc":"imposant","lemma":"imposer","pos":"VER"} ,
		{"word":"imposassent","word_nosc":"imposassent","lemma":"imposer","pos":"VER"} ,
		{"word":"impose","word_nosc":"impose","lemma":"imposer","pos":"VER"} ,
		{"word":"imposent","word_nosc":"imposent","lemma":"imposer","pos":"VER"} ,
		{"word":"imposer","word_nosc":"imposer","lemma":"imposer","pos":"VER"} ,
		{"word":"imposera","word_nosc":"imposera","lemma":"imposer","pos":"VER"} ,
		{"word":"imposerai","word_nosc":"imposerai","lemma":"imposer","pos":"VER"} ,
		{"word":"imposeraient","word_nosc":"imposeraient","lemma":"imposer","pos":"VER"} ,
		{"word":"imposerais","word_nosc":"imposerais","lemma":"imposer","pos":"VER"} ,
		{"word":"imposerait","word_nosc":"imposerait","lemma":"imposer","pos":"VER"} ,
		{"word":"imposerez","word_nosc":"imposerez","lemma":"imposer","pos":"VER"} ,
		{"word":"imposerions","word_nosc":"imposerions","lemma":"imposer","pos":"VER"} ,
		{"word":"imposerons","word_nosc":"imposerons","lemma":"imposer","pos":"VER"} ,
		{"word":"imposeront","word_nosc":"imposeront","lemma":"imposer","pos":"VER"} ,
		{"word":"imposes","word_nosc":"imposes","lemma":"imposer","pos":"VER"} ,
		{"word":"imposez","word_nosc":"imposez","lemma":"imposer","pos":"VER"} ,
		{"word":"imposiez","word_nosc":"imposiez","lemma":"imposer","pos":"VER"} ,
		{"word":"imposons","word_nosc":"imposons","lemma":"imposer","pos":"VER"} ,
		{"word":"imposât","word_nosc":"imposat","lemma":"imposer","pos":"VER"} ,
		{"word":"imposèrent","word_nosc":"imposerent","lemma":"imposer","pos":"VER"} ,
		{"word":"imposé","word_nosc":"impose","lemma":"imposer","pos":"VER"} ,
		{"word":"imposée","word_nosc":"imposee","lemma":"imposer","pos":"VER"} ,
		{"word":"imposées","word_nosc":"imposees","lemma":"imposer","pos":"VER"} ,
		{"word":"imposés","word_nosc":"imposes","lemma":"imposer","pos":"VER"} ,
		{"word":"impressionna","word_nosc":"impressionna","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionnaient","word_nosc":"impressionnaient","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionnais","word_nosc":"impressionnais","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionnait","word_nosc":"impressionnait","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionnant","word_nosc":"impressionnant","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionne","word_nosc":"impressionne","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionnent","word_nosc":"impressionnent","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionner","word_nosc":"impressionner","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionnera","word_nosc":"impressionnera","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionnerait","word_nosc":"impressionnerait","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionneras","word_nosc":"impressionneras","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionneront","word_nosc":"impressionneront","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionnes","word_nosc":"impressionnes","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionnez","word_nosc":"impressionnez","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionnât","word_nosc":"impressionnat","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionnèrent","word_nosc":"impressionnerent","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionné","word_nosc":"impressionne","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionnée","word_nosc":"impressionnee","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionnées","word_nosc":"impressionnees","lemma":"impressionner","pos":"VER"} ,
		{"word":"impressionnés","word_nosc":"impressionnes","lemma":"impressionner","pos":"VER"} ,
		{"word":"imprima","word_nosc":"imprima","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimaient","word_nosc":"imprimaient","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimais","word_nosc":"imprimais","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimait","word_nosc":"imprimait","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimant","word_nosc":"imprimant","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprime","word_nosc":"imprime","lemma":"imprimer","pos":"VER"} ,
		{"word":"impriment","word_nosc":"impriment","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimer","word_nosc":"imprimer","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimera","word_nosc":"imprimera","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimerai","word_nosc":"imprimerai","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimerait","word_nosc":"imprimerait","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimeras","word_nosc":"imprimeras","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimerons","word_nosc":"imprimerons","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimez","word_nosc":"imprimez","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimèrent","word_nosc":"imprimerent","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimé","word_nosc":"imprime","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimée","word_nosc":"imprimee","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimées","word_nosc":"imprimees","lemma":"imprimer","pos":"VER"} ,
		{"word":"imprimés","word_nosc":"imprimes","lemma":"imprimer","pos":"VER"} ,
		{"word":"improuva","word_nosc":"improuva","lemma":"improuver","pos":"VER"} ,
		{"word":"improvisa","word_nosc":"improvisa","lemma":"improviser","pos":"VER"} ,
		{"word":"improvisai","word_nosc":"improvisai","lemma":"improviser","pos":"VER"} ,
		{"word":"improvisaient","word_nosc":"improvisaient","lemma":"improviser","pos":"VER"} ,
		{"word":"improvisais","word_nosc":"improvisais","lemma":"improviser","pos":"VER"} ,
		{"word":"improvisait","word_nosc":"improvisait","lemma":"improviser","pos":"VER"} ,
		{"word":"improvisant","word_nosc":"improvisant","lemma":"improviser","pos":"VER"} ,
		{"word":"improvise","word_nosc":"improvise","lemma":"improviser","pos":"VER"} ,
		{"word":"improvisent","word_nosc":"improvisent","lemma":"improviser","pos":"VER"} ,
		{"word":"improviser","word_nosc":"improviser","lemma":"improviser","pos":"VER"} ,
		{"word":"improvisera","word_nosc":"improvisera","lemma":"improviser","pos":"VER"} ,
		{"word":"improviserai","word_nosc":"improviserai","lemma":"improviser","pos":"VER"} ,
		{"word":"improviserons","word_nosc":"improviserons","lemma":"improviser","pos":"VER"} ,
		{"word":"improvisez","word_nosc":"improvisez","lemma":"improviser","pos":"VER"} ,
		{"word":"improvisions","word_nosc":"improvisions","lemma":"improviser","pos":"VER"} ,
		{"word":"improvisons","word_nosc":"improvisons","lemma":"improviser","pos":"VER"} ,
		{"word":"improvisèrent","word_nosc":"improviserent","lemma":"improviser","pos":"VER"} ,
		{"word":"improvisé","word_nosc":"improvise","lemma":"improviser","pos":"VER"} ,
		{"word":"improvisée","word_nosc":"improvisee","lemma":"improviser","pos":"VER"} ,
		{"word":"improvisées","word_nosc":"improvisees","lemma":"improviser","pos":"VER"} ,
		{"word":"improvisés","word_nosc":"improvises","lemma":"improviser","pos":"VER"} ,
		{"word":"imprègne","word_nosc":"impregne","lemma":"imprégner","pos":"VER"} ,
		{"word":"imprègnent","word_nosc":"impregnent","lemma":"imprégner","pos":"VER"} ,
		{"word":"imprégna","word_nosc":"impregna","lemma":"imprégner","pos":"VER"} ,
		{"word":"imprégnaient","word_nosc":"impregnaient","lemma":"imprégner","pos":"VER"} ,
		{"word":"imprégnais","word_nosc":"impregnais","lemma":"imprégner","pos":"VER"} ,
		{"word":"imprégnait","word_nosc":"impregnait","lemma":"imprégner","pos":"VER"} ,
		{"word":"imprégnant","word_nosc":"impregnant","lemma":"imprégner","pos":"VER"} ,
		{"word":"imprégner","word_nosc":"impregner","lemma":"imprégner","pos":"VER"} ,
		{"word":"imprégnez","word_nosc":"impregnez","lemma":"imprégner","pos":"VER"} ,
		{"word":"imprégnèrent","word_nosc":"impregnerent","lemma":"imprégner","pos":"VER"} ,
		{"word":"imprégné","word_nosc":"impregne","lemma":"imprégner","pos":"VER"} ,
		{"word":"imprégnée","word_nosc":"impregnee","lemma":"imprégner","pos":"VER"} ,
		{"word":"imprégnées","word_nosc":"impregnees","lemma":"imprégner","pos":"VER"} ,
		{"word":"imprégnés","word_nosc":"impregnes","lemma":"imprégner","pos":"VER"} ,
		{"word":"impulsions","word_nosc":"impulsions","lemma":"impulser","pos":"VER"} ,
		{"word":"imputai","word_nosc":"imputai","lemma":"imputer","pos":"VER"} ,
		{"word":"imputaient","word_nosc":"imputaient","lemma":"imputer","pos":"VER"} ,
		{"word":"imputais","word_nosc":"imputais","lemma":"imputer","pos":"VER"} ,
		{"word":"imputait","word_nosc":"imputait","lemma":"imputer","pos":"VER"} ,
		{"word":"imputant","word_nosc":"imputant","lemma":"imputer","pos":"VER"} ,
		{"word":"imputasse","word_nosc":"imputasse","lemma":"imputer","pos":"VER"} ,
		{"word":"impute","word_nosc":"impute","lemma":"imputer","pos":"VER"} ,
		{"word":"imputer","word_nosc":"imputer","lemma":"imputer","pos":"VER"} ,
		{"word":"imputera","word_nosc":"imputera","lemma":"imputer","pos":"VER"} ,
		{"word":"imputerai","word_nosc":"imputerai","lemma":"imputer","pos":"VER"} ,
		{"word":"imputeront","word_nosc":"imputeront","lemma":"imputer","pos":"VER"} ,
		{"word":"imputât","word_nosc":"imputat","lemma":"imputer","pos":"VER"} ,
		{"word":"imputèrent","word_nosc":"imputerent","lemma":"imputer","pos":"VER"} ,
		{"word":"imputé","word_nosc":"impute","lemma":"imputer","pos":"VER"} ,
		{"word":"imputée","word_nosc":"imputee","lemma":"imputer","pos":"VER"} ,
		{"word":"imputées","word_nosc":"imputees","lemma":"imputer","pos":"VER"} ,
		{"word":"imputés","word_nosc":"imputes","lemma":"imputer","pos":"VER"} ,
		{"word":"inaugura","word_nosc":"inaugura","lemma":"inaugurer","pos":"VER"} ,
		{"word":"inaugurai","word_nosc":"inaugurai","lemma":"inaugurer","pos":"VER"} ,
		{"word":"inaugurais","word_nosc":"inaugurais","lemma":"inaugurer","pos":"VER"} ,
		{"word":"inaugurait","word_nosc":"inaugurait","lemma":"inaugurer","pos":"VER"} ,
		{"word":"inaugurant","word_nosc":"inaugurant","lemma":"inaugurer","pos":"VER"} ,
		{"word":"inaugure","word_nosc":"inaugure","lemma":"inaugurer","pos":"VER"} ,
		{"word":"inaugurer","word_nosc":"inaugurer","lemma":"inaugurer","pos":"VER"} ,
		{"word":"inaugurera","word_nosc":"inaugurera","lemma":"inaugurer","pos":"VER"} ,
		{"word":"inaugurions","word_nosc":"inaugurions","lemma":"inaugurer","pos":"VER"} ,
		{"word":"inaugurons","word_nosc":"inaugurons","lemma":"inaugurer","pos":"VER"} ,
		{"word":"inaugurèrent","word_nosc":"inaugurerent","lemma":"inaugurer","pos":"VER"} ,
		{"word":"inauguré","word_nosc":"inaugure","lemma":"inaugurer","pos":"VER"} ,
		{"word":"inaugurée","word_nosc":"inauguree","lemma":"inaugurer","pos":"VER"} ,
		{"word":"inaugurés","word_nosc":"inaugures","lemma":"inaugurer","pos":"VER"} ,
		{"word":"incarcère","word_nosc":"incarcere","lemma":"incarcérer","pos":"VER"} ,
		{"word":"incarcérer","word_nosc":"incarcerer","lemma":"incarcérer","pos":"VER"} ,
		{"word":"incarcérez","word_nosc":"incarcerez","lemma":"incarcérer","pos":"VER"} ,
		{"word":"incarcéré","word_nosc":"incarcere","lemma":"incarcérer","pos":"VER"} ,
		{"word":"incarcérée","word_nosc":"incarceree","lemma":"incarcérer","pos":"VER"} ,
		{"word":"incarcérées","word_nosc":"incarcerees","lemma":"incarcérer","pos":"VER"} ,
		{"word":"incarcérés","word_nosc":"incarceres","lemma":"incarcérer","pos":"VER"} ,
		{"word":"incarna","word_nosc":"incarna","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnai","word_nosc":"incarnai","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnaient","word_nosc":"incarnaient","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnais","word_nosc":"incarnais","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnait","word_nosc":"incarnait","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnant","word_nosc":"incarnant","lemma":"incarner","pos":"VER"} ,
		{"word":"incarne","word_nosc":"incarne","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnent","word_nosc":"incarnent","lemma":"incarner","pos":"VER"} ,
		{"word":"incarner","word_nosc":"incarner","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnera","word_nosc":"incarnera","lemma":"incarner","pos":"VER"} ,
		{"word":"incarneraient","word_nosc":"incarneraient","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnerais","word_nosc":"incarnerais","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnerait","word_nosc":"incarnerait","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnerez","word_nosc":"incarnerez","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnes","word_nosc":"incarnes","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnez","word_nosc":"incarnez","lemma":"incarner","pos":"VER"} ,
		{"word":"incarniez","word_nosc":"incarniez","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnions","word_nosc":"incarnions","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnât","word_nosc":"incarnat","lemma":"incarner","pos":"VER"} ,
		{"word":"incarné","word_nosc":"incarne","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnée","word_nosc":"incarnee","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnées","word_nosc":"incarnees","lemma":"incarner","pos":"VER"} ,
		{"word":"incarnés","word_nosc":"incarnes","lemma":"incarner","pos":"VER"} ,
		{"word":"incendia","word_nosc":"incendia","lemma":"incendier","pos":"VER"} ,
		{"word":"incendiaient","word_nosc":"incendiaient","lemma":"incendier","pos":"VER"} ,
		{"word":"incendiait","word_nosc":"incendiait","lemma":"incendier","pos":"VER"} ,
		{"word":"incendiant","word_nosc":"incendiant","lemma":"incendier","pos":"VER"} ,
		{"word":"incendie","word_nosc":"incendie","lemma":"incendier","pos":"VER"} ,
		{"word":"incendient","word_nosc":"incendient","lemma":"incendier","pos":"VER"} ,
		{"word":"incendier","word_nosc":"incendier","lemma":"incendier","pos":"VER"} ,
		{"word":"incendieras","word_nosc":"incendieras","lemma":"incendier","pos":"VER"} ,
		{"word":"incendies","word_nosc":"incendies","lemma":"incendier","pos":"VER"} ,
		{"word":"incendiez","word_nosc":"incendiez","lemma":"incendier","pos":"VER"} ,
		{"word":"incendièrent","word_nosc":"incendierent","lemma":"incendier","pos":"VER"} ,
		{"word":"incendié","word_nosc":"incendie","lemma":"incendier","pos":"VER"} ,
		{"word":"incendiée","word_nosc":"incendiee","lemma":"incendier","pos":"VER"} ,
		{"word":"incendiées","word_nosc":"incendiees","lemma":"incendier","pos":"VER"} ,
		{"word":"incendiés","word_nosc":"incendies","lemma":"incendier","pos":"VER"} ,
		{"word":"incinère","word_nosc":"incinere","lemma":"incinérer","pos":"VER"} ,
		{"word":"incinérait","word_nosc":"incinerait","lemma":"incinérer","pos":"VER"} ,
		{"word":"incinérant","word_nosc":"incinerant","lemma":"incinérer","pos":"VER"} ,
		{"word":"incinérer","word_nosc":"incinerer","lemma":"incinérer","pos":"VER"} ,
		{"word":"incinérez","word_nosc":"incinerez","lemma":"incinérer","pos":"VER"} ,
		{"word":"incinéré","word_nosc":"incinere","lemma":"incinérer","pos":"VER"} ,
		{"word":"incinérée","word_nosc":"incineree","lemma":"incinérer","pos":"VER"} ,
		{"word":"incinérés","word_nosc":"incineres","lemma":"incinérer","pos":"VER"} ,
		{"word":"incisa","word_nosc":"incisa","lemma":"inciser","pos":"VER"} ,
		{"word":"incisaient","word_nosc":"incisaient","lemma":"inciser","pos":"VER"} ,
		{"word":"incisait","word_nosc":"incisait","lemma":"inciser","pos":"VER"} ,
		{"word":"incisant","word_nosc":"incisant","lemma":"inciser","pos":"VER"} ,
		{"word":"incise","word_nosc":"incise","lemma":"inciser","pos":"VER"} ,
		{"word":"inciser","word_nosc":"inciser","lemma":"inciser","pos":"VER"} ,
		{"word":"incisera","word_nosc":"incisera","lemma":"inciser","pos":"VER"} ,
		{"word":"incises","word_nosc":"incises","lemma":"inciser","pos":"VER"} ,
		{"word":"incisez","word_nosc":"incisez","lemma":"inciser","pos":"VER"} ,
		{"word":"incisé","word_nosc":"incise","lemma":"inciser","pos":"VER"} ,
		{"word":"incisée","word_nosc":"incisee","lemma":"inciser","pos":"VER"} ,
		{"word":"incisées","word_nosc":"incisees","lemma":"inciser","pos":"VER"} ,
		{"word":"incisés","word_nosc":"incises","lemma":"inciser","pos":"VER"} ,
		{"word":"incita","word_nosc":"incita","lemma":"inciter","pos":"VER"} ,
		{"word":"incitaient","word_nosc":"incitaient","lemma":"inciter","pos":"VER"} ,
		{"word":"incitait","word_nosc":"incitait","lemma":"inciter","pos":"VER"} ,
		{"word":"incitant","word_nosc":"incitant","lemma":"inciter","pos":"VER"} ,
		{"word":"incite","word_nosc":"incite","lemma":"inciter","pos":"VER"} ,
		{"word":"incitent","word_nosc":"incitent","lemma":"inciter","pos":"VER"} ,
		{"word":"inciter","word_nosc":"inciter","lemma":"inciter","pos":"VER"} ,
		{"word":"incitera","word_nosc":"incitera","lemma":"inciter","pos":"VER"} ,
		{"word":"inciterai","word_nosc":"inciterai","lemma":"inciter","pos":"VER"} ,
		{"word":"inciteraient","word_nosc":"inciteraient","lemma":"inciter","pos":"VER"} ,
		{"word":"inciterais","word_nosc":"inciterais","lemma":"inciter","pos":"VER"} ,
		{"word":"inciterait","word_nosc":"inciterait","lemma":"inciter","pos":"VER"} ,
		{"word":"inciteront","word_nosc":"inciteront","lemma":"inciter","pos":"VER"} ,
		{"word":"incitez","word_nosc":"incitez","lemma":"inciter","pos":"VER"} ,
		{"word":"incitiez","word_nosc":"incitiez","lemma":"inciter","pos":"VER"} ,
		{"word":"incitèrent","word_nosc":"inciterent","lemma":"inciter","pos":"VER"} ,
		{"word":"incité","word_nosc":"incite","lemma":"inciter","pos":"VER"} ,
		{"word":"incitée","word_nosc":"incitee","lemma":"inciter","pos":"VER"} ,
		{"word":"incités","word_nosc":"incites","lemma":"inciter","pos":"VER"} ,
		{"word":"inclina","word_nosc":"inclina","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinai","word_nosc":"inclinai","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinaient","word_nosc":"inclinaient","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinais","word_nosc":"inclinais","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinait","word_nosc":"inclinait","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinant","word_nosc":"inclinant","lemma":"incliner","pos":"VER"} ,
		{"word":"incline","word_nosc":"incline","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinent","word_nosc":"inclinent","lemma":"incliner","pos":"VER"} ,
		{"word":"incliner","word_nosc":"incliner","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinerai","word_nosc":"inclinerai","lemma":"incliner","pos":"VER"} ,
		{"word":"inclineraient","word_nosc":"inclineraient","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinerais","word_nosc":"inclinerais","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinerait","word_nosc":"inclinerait","lemma":"incliner","pos":"VER"} ,
		{"word":"inclineras","word_nosc":"inclineras","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinerez","word_nosc":"inclinerez","lemma":"incliner","pos":"VER"} ,
		{"word":"inclineront","word_nosc":"inclineront","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinez","word_nosc":"inclinez","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinions","word_nosc":"inclinions","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinons","word_nosc":"inclinons","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinât","word_nosc":"inclinat","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinèrent","word_nosc":"inclinerent","lemma":"incliner","pos":"VER"} ,
		{"word":"incliné","word_nosc":"incline","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinée","word_nosc":"inclinee","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinées","word_nosc":"inclinees","lemma":"incliner","pos":"VER"} ,
		{"word":"inclinés","word_nosc":"inclines","lemma":"incliner","pos":"VER"} ,
		{"word":"incluais","word_nosc":"incluais","lemma":"inclure","pos":"VER"} ,
		{"word":"incluait","word_nosc":"incluait","lemma":"inclure","pos":"VER"} ,
		{"word":"incluant","word_nosc":"incluant","lemma":"inclure","pos":"VER"} ,
		{"word":"inclue","word_nosc":"inclue","lemma":"inclure","pos":"VER"} ,
		{"word":"incluent","word_nosc":"incluent","lemma":"inclure","pos":"VER"} ,
		{"word":"incluez","word_nosc":"incluez","lemma":"inclure","pos":"VER"} ,
		{"word":"incluons","word_nosc":"incluons","lemma":"inclure","pos":"VER"} ,
		{"word":"inclura","word_nosc":"inclura","lemma":"inclure","pos":"VER"} ,
		{"word":"inclurai","word_nosc":"inclurai","lemma":"inclure","pos":"VER"} ,
		{"word":"inclurait","word_nosc":"inclurait","lemma":"inclure","pos":"VER"} ,
		{"word":"inclure","word_nosc":"inclure","lemma":"inclure","pos":"VER"} ,
		{"word":"inclus","word_nosc":"inclus","lemma":"inclure","pos":"VER"} ,
		{"word":"inclut","word_nosc":"inclut","lemma":"inclure","pos":"VER"} ,
		{"word":"incombaient","word_nosc":"incombaient","lemma":"incomber","pos":"VER"} ,
		{"word":"incombait","word_nosc":"incombait","lemma":"incomber","pos":"VER"} ,
		{"word":"incombant","word_nosc":"incombant","lemma":"incomber","pos":"VER"} ,
		{"word":"incombe","word_nosc":"incombe","lemma":"incomber","pos":"VER"} ,
		{"word":"incombent","word_nosc":"incombent","lemma":"incomber","pos":"VER"} ,
		{"word":"incomber","word_nosc":"incomber","lemma":"incomber","pos":"VER"} ,
		{"word":"incombera","word_nosc":"incombera","lemma":"incomber","pos":"VER"} ,
		{"word":"incomberait","word_nosc":"incomberait","lemma":"incomber","pos":"VER"} ,
		{"word":"incombèrent","word_nosc":"incomberent","lemma":"incomber","pos":"VER"} ,
		{"word":"incommodait","word_nosc":"incommodait","lemma":"incommoder","pos":"VER"} ,
		{"word":"incommode","word_nosc":"incommode","lemma":"incommoder","pos":"VER"} ,
		{"word":"incommoder","word_nosc":"incommoder","lemma":"incommoder","pos":"VER"} ,
		{"word":"incommodera","word_nosc":"incommodera","lemma":"incommoder","pos":"VER"} ,
		{"word":"incommoderai","word_nosc":"incommoderai","lemma":"incommoder","pos":"VER"} ,
		{"word":"incommodez","word_nosc":"incommodez","lemma":"incommoder","pos":"VER"} ,
		{"word":"incommodé","word_nosc":"incommode","lemma":"incommoder","pos":"VER"} ,
		{"word":"incommodée","word_nosc":"incommodee","lemma":"incommoder","pos":"VER"} ,
		{"word":"incommodées","word_nosc":"incommodees","lemma":"incommoder","pos":"VER"} ,
		{"word":"incommodés","word_nosc":"incommodes","lemma":"incommoder","pos":"VER"} ,
		{"word":"incorpora","word_nosc":"incorpora","lemma":"incorporer","pos":"VER"} ,
		{"word":"incorporaient","word_nosc":"incorporaient","lemma":"incorporer","pos":"VER"} ,
		{"word":"incorporait","word_nosc":"incorporait","lemma":"incorporer","pos":"VER"} ,
		{"word":"incorporant","word_nosc":"incorporant","lemma":"incorporer","pos":"VER"} ,
		{"word":"incorpore","word_nosc":"incorpore","lemma":"incorporer","pos":"VER"} ,
		{"word":"incorporent","word_nosc":"incorporent","lemma":"incorporer","pos":"VER"} ,
		{"word":"incorporer","word_nosc":"incorporer","lemma":"incorporer","pos":"VER"} ,
		{"word":"incorporez","word_nosc":"incorporez","lemma":"incorporer","pos":"VER"} ,
		{"word":"incorporé","word_nosc":"incorpore","lemma":"incorporer","pos":"VER"} ,
		{"word":"incorporée","word_nosc":"incorporee","lemma":"incorporer","pos":"VER"} ,
		{"word":"incorporées","word_nosc":"incorporees","lemma":"incorporer","pos":"VER"} ,
		{"word":"incorporés","word_nosc":"incorpores","lemma":"incorporer","pos":"VER"} ,
		{"word":"incrimina","word_nosc":"incrimina","lemma":"incriminer","pos":"VER"} ,
		{"word":"incriminant","word_nosc":"incriminant","lemma":"incriminer","pos":"VER"} ,
		{"word":"incrimine","word_nosc":"incrimine","lemma":"incriminer","pos":"VER"} ,
		{"word":"incriminent","word_nosc":"incriminent","lemma":"incriminer","pos":"VER"} ,
		{"word":"incriminer","word_nosc":"incriminer","lemma":"incriminer","pos":"VER"} ,
		{"word":"incriminé","word_nosc":"incrimine","lemma":"incriminer","pos":"VER"} ,
		{"word":"incriminée","word_nosc":"incriminee","lemma":"incriminer","pos":"VER"} ,
		{"word":"incriminées","word_nosc":"incriminees","lemma":"incriminer","pos":"VER"} ,
		{"word":"incrusta","word_nosc":"incrusta","lemma":"incruster","pos":"VER"} ,
		{"word":"incrustaient","word_nosc":"incrustaient","lemma":"incruster","pos":"VER"} ,
		{"word":"incrustais","word_nosc":"incrustais","lemma":"incruster","pos":"VER"} ,
		{"word":"incrustait","word_nosc":"incrustait","lemma":"incruster","pos":"VER"} ,
		{"word":"incrustant","word_nosc":"incrustant","lemma":"incruster","pos":"VER"} ,
		{"word":"incruste","word_nosc":"incruste","lemma":"incruster","pos":"VER"} ,
		{"word":"incrustent","word_nosc":"incrustent","lemma":"incruster","pos":"VER"} ,
		{"word":"incruster","word_nosc":"incruster","lemma":"incruster","pos":"VER"} ,
		{"word":"incrusterait","word_nosc":"incrusterait","lemma":"incruster","pos":"VER"} ,
		{"word":"incrustez","word_nosc":"incrustez","lemma":"incruster","pos":"VER"} ,
		{"word":"incrustèrent","word_nosc":"incrusterent","lemma":"incruster","pos":"VER"} ,
		{"word":"incrusté","word_nosc":"incruste","lemma":"incruster","pos":"VER"} ,
		{"word":"incrustée","word_nosc":"incrustee","lemma":"incruster","pos":"VER"} ,
		{"word":"incrustées","word_nosc":"incrustees","lemma":"incruster","pos":"VER"} ,
		{"word":"incrustés","word_nosc":"incrustes","lemma":"incruster","pos":"VER"} ,
		{"word":"incuber","word_nosc":"incuber","lemma":"incuber","pos":"VER"} ,
		{"word":"incubé","word_nosc":"incube","lemma":"incuber","pos":"VER"} ,
		{"word":"inculpa","word_nosc":"inculpa","lemma":"inculper","pos":"VER"} ,
		{"word":"inculpant","word_nosc":"inculpant","lemma":"inculper","pos":"VER"} ,
		{"word":"inculpe","word_nosc":"inculpe","lemma":"inculper","pos":"VER"} ,
		{"word":"inculpent","word_nosc":"inculpent","lemma":"inculper","pos":"VER"} ,
		{"word":"inculper","word_nosc":"inculper","lemma":"inculper","pos":"VER"} ,
		{"word":"inculpera","word_nosc":"inculpera","lemma":"inculper","pos":"VER"} ,
		{"word":"inculperai","word_nosc":"inculperai","lemma":"inculper","pos":"VER"} ,
		{"word":"inculperais","word_nosc":"inculperais","lemma":"inculper","pos":"VER"} ,
		{"word":"inculperont","word_nosc":"inculperont","lemma":"inculper","pos":"VER"} ,
		{"word":"inculpez","word_nosc":"inculpez","lemma":"inculper","pos":"VER"} ,
		{"word":"inculpons","word_nosc":"inculpons","lemma":"inculper","pos":"VER"} ,
		{"word":"inculpé","word_nosc":"inculpe","lemma":"inculper","pos":"VER"} ,
		{"word":"inculpée","word_nosc":"inculpee","lemma":"inculper","pos":"VER"} ,
		{"word":"inculpés","word_nosc":"inculpes","lemma":"inculper","pos":"VER"} ,
		{"word":"inculqua","word_nosc":"inculqua","lemma":"inculquer","pos":"VER"} ,
		{"word":"inculquait","word_nosc":"inculquait","lemma":"inculquer","pos":"VER"} ,
		{"word":"inculquant","word_nosc":"inculquant","lemma":"inculquer","pos":"VER"} ,
		{"word":"inculque","word_nosc":"inculque","lemma":"inculquer","pos":"VER"} ,
		{"word":"inculquent","word_nosc":"inculquent","lemma":"inculquer","pos":"VER"} ,
		{"word":"inculquer","word_nosc":"inculquer","lemma":"inculquer","pos":"VER"} ,
		{"word":"inculquons","word_nosc":"inculquons","lemma":"inculquer","pos":"VER"} ,
		{"word":"inculquât","word_nosc":"inculquat","lemma":"inculquer","pos":"VER"} ,
		{"word":"inculqué","word_nosc":"inculque","lemma":"inculquer","pos":"VER"} ,
		{"word":"inculquée","word_nosc":"inculquee","lemma":"inculquer","pos":"VER"} ,
		{"word":"inculquées","word_nosc":"inculquees","lemma":"inculquer","pos":"VER"} ,
		{"word":"inculqués","word_nosc":"inculques","lemma":"inculquer","pos":"VER"} ,
		{"word":"incurvaient","word_nosc":"incurvaient","lemma":"incurver","pos":"VER"} ,
		{"word":"incurvait","word_nosc":"incurvait","lemma":"incurver","pos":"VER"} ,
		{"word":"incurvant","word_nosc":"incurvant","lemma":"incurver","pos":"VER"} ,
		{"word":"incurve","word_nosc":"incurve","lemma":"incurver","pos":"VER"} ,
		{"word":"incurvent","word_nosc":"incurvent","lemma":"incurver","pos":"VER"} ,
		{"word":"incurver","word_nosc":"incurver","lemma":"incurver","pos":"VER"} ,
		{"word":"incurvèrent","word_nosc":"incurverent","lemma":"incurver","pos":"VER"} ,
		{"word":"incurvé","word_nosc":"incurve","lemma":"incurver","pos":"VER"} ,
		{"word":"incurvée","word_nosc":"incurvee","lemma":"incurver","pos":"VER"} ,
		{"word":"incurvées","word_nosc":"incurvees","lemma":"incurver","pos":"VER"} ,
		{"word":"incurvés","word_nosc":"incurves","lemma":"incurver","pos":"VER"} ,
		{"word":"indemnise","word_nosc":"indemnise","lemma":"indemniser","pos":"VER"} ,
		{"word":"indemniser","word_nosc":"indemniser","lemma":"indemniser","pos":"VER"} ,
		{"word":"indemniserai","word_nosc":"indemniserai","lemma":"indemniser","pos":"VER"} ,
		{"word":"indemniseraient","word_nosc":"indemniseraient","lemma":"indemniser","pos":"VER"} ,
		{"word":"indemnisez","word_nosc":"indemnisez","lemma":"indemniser","pos":"VER"} ,
		{"word":"indemnisé","word_nosc":"indemnise","lemma":"indemniser","pos":"VER"} ,
		{"word":"indemnisés","word_nosc":"indemnises","lemma":"indemniser","pos":"VER"} ,
		{"word":"indenter","word_nosc":"indenter","lemma":"indenter","pos":"VER"} ,
		{"word":"indenté","word_nosc":"indente","lemma":"indenter","pos":"VER"} ,
		{"word":"indexer","word_nosc":"indexer","lemma":"indexer","pos":"VER"} ,
		{"word":"indexé","word_nosc":"indexe","lemma":"indexer","pos":"VER"} ,
		{"word":"indexée","word_nosc":"indexee","lemma":"indexer","pos":"VER"} ,
		{"word":"indianisés","word_nosc":"indianises","lemma":"indianiser","pos":"VER"} ,
		{"word":"indiffère","word_nosc":"indiffere","lemma":"indifférer","pos":"VER"} ,
		{"word":"indiffèrent","word_nosc":"indifferent","lemma":"indifférer","pos":"VER"} ,
		{"word":"indiffères","word_nosc":"indifferes","lemma":"indifférer","pos":"VER"} ,
		{"word":"indifféraient","word_nosc":"indifferaient","lemma":"indifférer","pos":"VER"} ,
		{"word":"indifférait","word_nosc":"indifferait","lemma":"indifférer","pos":"VER"} ,
		{"word":"indifférenciée","word_nosc":"indifferenciee","lemma":"indifférencier","pos":"VER"} ,
		{"word":"indifféré","word_nosc":"indiffere","lemma":"indifférer","pos":"VER"} ,
		{"word":"indigestionner","word_nosc":"indigestionner","lemma":"indigestionner","pos":"VER"} ,
		{"word":"indigna","word_nosc":"indigna","lemma":"indigner","pos":"VER"} ,
		{"word":"indignai","word_nosc":"indignai","lemma":"indigner","pos":"VER"} ,
		{"word":"indignaient","word_nosc":"indignaient","lemma":"indigner","pos":"VER"} ,
		{"word":"indignais","word_nosc":"indignais","lemma":"indigner","pos":"VER"} ,
		{"word":"indignait","word_nosc":"indignait","lemma":"indigner","pos":"VER"} ,
		{"word":"indignant","word_nosc":"indignant","lemma":"indigner","pos":"VER"} ,
		{"word":"indignassent","word_nosc":"indignassent","lemma":"indigner","pos":"VER"} ,
		{"word":"indigne","word_nosc":"indigne","lemma":"indigner","pos":"VER"} ,
		{"word":"indignent","word_nosc":"indignent","lemma":"indigner","pos":"VER"} ,
		{"word":"indigner","word_nosc":"indigner","lemma":"indigner","pos":"VER"} ,
		{"word":"indignera","word_nosc":"indignera","lemma":"indigner","pos":"VER"} ,
		{"word":"indignes","word_nosc":"indignes","lemma":"indigner","pos":"VER"} ,
		{"word":"indignèrent","word_nosc":"indignerent","lemma":"indigner","pos":"VER"} ,
		{"word":"indigné","word_nosc":"indigne","lemma":"indigner","pos":"VER"} ,
		{"word":"indignée","word_nosc":"indignee","lemma":"indigner","pos":"VER"} ,
		{"word":"indignées","word_nosc":"indignees","lemma":"indigner","pos":"VER"} ,
		{"word":"indignés","word_nosc":"indignes","lemma":"indigner","pos":"VER"} ,
		{"word":"indiqua","word_nosc":"indiqua","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquai","word_nosc":"indiquai","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquaient","word_nosc":"indiquaient","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquais","word_nosc":"indiquais","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquait","word_nosc":"indiquait","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquant","word_nosc":"indiquant","lemma":"indiquer","pos":"VER"} ,
		{"word":"indique","word_nosc":"indique","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquent","word_nosc":"indiquent","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquer","word_nosc":"indiquer","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquera","word_nosc":"indiquera","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquerai","word_nosc":"indiquerai","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiqueraient","word_nosc":"indiqueraient","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquerais","word_nosc":"indiquerais","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquerait","word_nosc":"indiquerait","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiqueras","word_nosc":"indiqueras","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquerez","word_nosc":"indiquerez","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiqueriez","word_nosc":"indiqueriez","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquerons","word_nosc":"indiquerons","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquez","word_nosc":"indiquez","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquiez","word_nosc":"indiquiez","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquions","word_nosc":"indiquions","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquons","word_nosc":"indiquons","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquât","word_nosc":"indiquat","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquèrent","word_nosc":"indiquerent","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiqué","word_nosc":"indique","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquée","word_nosc":"indiquee","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiquées","word_nosc":"indiquees","lemma":"indiquer","pos":"VER"} ,
		{"word":"indiqués","word_nosc":"indiques","lemma":"indiquer","pos":"VER"} ,
		{"word":"indisposa","word_nosc":"indisposa","lemma":"indisposer","pos":"VER"} ,
		{"word":"indisposaient","word_nosc":"indisposaient","lemma":"indisposer","pos":"VER"} ,
		{"word":"indisposait","word_nosc":"indisposait","lemma":"indisposer","pos":"VER"} ,
		{"word":"indisposent","word_nosc":"indisposent","lemma":"indisposer","pos":"VER"} ,
		{"word":"indisposer","word_nosc":"indisposer","lemma":"indisposer","pos":"VER"} ,
		{"word":"indisposerait","word_nosc":"indisposerait","lemma":"indisposer","pos":"VER"} ,
		{"word":"indisposons","word_nosc":"indisposons","lemma":"indisposer","pos":"VER"} ,
		{"word":"indisposèrent","word_nosc":"indisposerent","lemma":"indisposer","pos":"VER"} ,
		{"word":"indisposé","word_nosc":"indispose","lemma":"indisposer","pos":"VER"} ,
		{"word":"indisposée","word_nosc":"indisposee","lemma":"indisposer","pos":"VER"} ,
		{"word":"indisposés","word_nosc":"indisposes","lemma":"indisposer","pos":"VER"} ,
		{"word":"individualiser","word_nosc":"individualiser","lemma":"individualiser","pos":"VER"} ,
		{"word":"individualisé","word_nosc":"individualise","lemma":"individualiser","pos":"VER"} ,
		{"word":"individualisés","word_nosc":"individualises","lemma":"individualiser","pos":"VER"} ,
		{"word":"induiraient","word_nosc":"induiraient","lemma":"induire","pos":"VER"} ,
		{"word":"induirait","word_nosc":"induirait","lemma":"induire","pos":"VER"} ,
		{"word":"induire","word_nosc":"induire","lemma":"induire","pos":"VER"} ,
		{"word":"induis","word_nosc":"induis","lemma":"induire","pos":"VER"} ,
		{"word":"induisaient","word_nosc":"induisaient","lemma":"induire","pos":"VER"} ,
		{"word":"induisait","word_nosc":"induisait","lemma":"induire","pos":"VER"} ,
		{"word":"induisant","word_nosc":"induisant","lemma":"induire","pos":"VER"} ,
		{"word":"induisez","word_nosc":"induisez","lemma":"induire","pos":"VER"} ,
		{"word":"induisit","word_nosc":"induisit","lemma":"induire","pos":"VER"} ,
		{"word":"induit","word_nosc":"induit","lemma":"induire","pos":"VER"} ,
		{"word":"induite","word_nosc":"induite","lemma":"induire","pos":"VER"} ,
		{"word":"induits","word_nosc":"induits","lemma":"induire","pos":"VER"} ,
		{"word":"induré","word_nosc":"indure","lemma":"indurer","pos":"VER"} ,
		{"word":"indurées","word_nosc":"indurees","lemma":"indurer","pos":"VER"} ,
		{"word":"industrialiser","word_nosc":"industrialiser","lemma":"industrialiser","pos":"VER"} ,
		{"word":"industrialisé","word_nosc":"industrialise","lemma":"industrialiser","pos":"VER"} ,
		{"word":"industrialisée","word_nosc":"industrialisee","lemma":"industrialiser","pos":"VER"} ,
		{"word":"infantiliser","word_nosc":"infantiliser","lemma":"infantiliser","pos":"VER"} ,
		{"word":"infantilisé","word_nosc":"infantilise","lemma":"infantiliser","pos":"VER"} ,
		{"word":"infatuer","word_nosc":"infatuer","lemma":"infatuer","pos":"VER"} ,
		{"word":"infatué","word_nosc":"infatue","lemma":"infatuer","pos":"VER"} ,
		{"word":"infatués","word_nosc":"infatues","lemma":"infatuer","pos":"VER"} ,
		{"word":"infectaient","word_nosc":"infectaient","lemma":"infecter","pos":"VER"} ,
		{"word":"infectait","word_nosc":"infectait","lemma":"infecter","pos":"VER"} ,
		{"word":"infectant","word_nosc":"infectant","lemma":"infecter","pos":"VER"} ,
		{"word":"infectassent","word_nosc":"infectassent","lemma":"infecter","pos":"VER"} ,
		{"word":"infecte","word_nosc":"infecte","lemma":"infecter","pos":"VER"} ,
		{"word":"infectent","word_nosc":"infectent","lemma":"infecter","pos":"VER"} ,
		{"word":"infecter","word_nosc":"infecter","lemma":"infecter","pos":"VER"} ,
		{"word":"infectera","word_nosc":"infectera","lemma":"infecter","pos":"VER"} ,
		{"word":"infectes","word_nosc":"infectes","lemma":"infecter","pos":"VER"} ,
		{"word":"infectez","word_nosc":"infectez","lemma":"infecter","pos":"VER"} ,
		{"word":"infecté","word_nosc":"infecte","lemma":"infecter","pos":"VER"} ,
		{"word":"infectée","word_nosc":"infectee","lemma":"infecter","pos":"VER"} ,
		{"word":"infectées","word_nosc":"infectees","lemma":"infecter","pos":"VER"} ,
		{"word":"infectés","word_nosc":"infectes","lemma":"infecter","pos":"VER"} ,
		{"word":"infestaient","word_nosc":"infestaient","lemma":"infester","pos":"VER"} ,
		{"word":"infestait","word_nosc":"infestait","lemma":"infester","pos":"VER"} ,
		{"word":"infestant","word_nosc":"infestant","lemma":"infester","pos":"VER"} ,
		{"word":"infeste","word_nosc":"infeste","lemma":"infester","pos":"VER"} ,
		{"word":"infestent","word_nosc":"infestent","lemma":"infester","pos":"VER"} ,
		{"word":"infester","word_nosc":"infester","lemma":"infester","pos":"VER"} ,
		{"word":"infesteront","word_nosc":"infesteront","lemma":"infester","pos":"VER"} ,
		{"word":"infestât","word_nosc":"infestat","lemma":"infester","pos":"VER"} ,
		{"word":"infestèrent","word_nosc":"infesterent","lemma":"infester","pos":"VER"} ,
		{"word":"infesté","word_nosc":"infeste","lemma":"infester","pos":"VER"} ,
		{"word":"infestée","word_nosc":"infestee","lemma":"infester","pos":"VER"} ,
		{"word":"infestées","word_nosc":"infestees","lemma":"infester","pos":"VER"} ,
		{"word":"infestés","word_nosc":"infestes","lemma":"infester","pos":"VER"} ,
		{"word":"infiltra","word_nosc":"infiltra","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltraient","word_nosc":"infiltraient","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltrait","word_nosc":"infiltrait","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltrant","word_nosc":"infiltrant","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltre","word_nosc":"infiltre","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltrent","word_nosc":"infiltrent","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltrer","word_nosc":"infiltrer","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltrera","word_nosc":"infiltrera","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltreront","word_nosc":"infiltreront","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltrez","word_nosc":"infiltrez","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltriez","word_nosc":"infiltriez","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltrions","word_nosc":"infiltrions","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltrons","word_nosc":"infiltrons","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltrèrent","word_nosc":"infiltrerent","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltré","word_nosc":"infiltre","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltrée","word_nosc":"infiltree","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltrées","word_nosc":"infiltrees","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infiltrés","word_nosc":"infiltres","lemma":"infiltrer","pos":"VER"} ,
		{"word":"infirma","word_nosc":"infirma","lemma":"infirmer","pos":"VER"} ,
		{"word":"infirmait","word_nosc":"infirmait","lemma":"infirmer","pos":"VER"} ,
		{"word":"infirmant","word_nosc":"infirmant","lemma":"infirmer","pos":"VER"} ,
		{"word":"infirme","word_nosc":"infirme","lemma":"infirmer","pos":"VER"} ,
		{"word":"infirment","word_nosc":"infirment","lemma":"infirmer","pos":"VER"} ,
		{"word":"infirmer","word_nosc":"infirmer","lemma":"infirmer","pos":"VER"} ,
		{"word":"infirmeront","word_nosc":"infirmeront","lemma":"infirmer","pos":"VER"} ,
		{"word":"infirmé","word_nosc":"infirme","lemma":"infirmer","pos":"VER"} ,
		{"word":"inflige","word_nosc":"inflige","lemma":"infliger","pos":"VER"} ,
		{"word":"infligea","word_nosc":"infligea","lemma":"infliger","pos":"VER"} ,
		{"word":"infligeaient","word_nosc":"infligeaient","lemma":"infliger","pos":"VER"} ,
		{"word":"infligeais","word_nosc":"infligeais","lemma":"infliger","pos":"VER"} ,
		{"word":"infligeait","word_nosc":"infligeait","lemma":"infliger","pos":"VER"} ,
		{"word":"infligeant","word_nosc":"infligeant","lemma":"infliger","pos":"VER"} ,
		{"word":"infligent","word_nosc":"infligent","lemma":"infliger","pos":"VER"} ,
		{"word":"infligeons","word_nosc":"infligeons","lemma":"infliger","pos":"VER"} ,
		{"word":"infliger","word_nosc":"infliger","lemma":"infliger","pos":"VER"} ,
		{"word":"infligera","word_nosc":"infligera","lemma":"infliger","pos":"VER"} ,
		{"word":"infligerai","word_nosc":"infligerai","lemma":"infliger","pos":"VER"} ,
		{"word":"infligeraient","word_nosc":"infligeraient","lemma":"infliger","pos":"VER"} ,
		{"word":"infligerais","word_nosc":"infligerais","lemma":"infliger","pos":"VER"} ,
		{"word":"infligerait","word_nosc":"infligerait","lemma":"infliger","pos":"VER"} ,
		{"word":"infligerez","word_nosc":"infligerez","lemma":"infliger","pos":"VER"} ,
		{"word":"infligerions","word_nosc":"infligerions","lemma":"infliger","pos":"VER"} ,
		{"word":"infliges","word_nosc":"infliges","lemma":"infliger","pos":"VER"} ,
		{"word":"infligez","word_nosc":"infligez","lemma":"infliger","pos":"VER"} ,
		{"word":"infligeâmes","word_nosc":"infligeames","lemma":"infliger","pos":"VER"} ,
		{"word":"infligèrent","word_nosc":"infligerent","lemma":"infliger","pos":"VER"} ,
		{"word":"infligé","word_nosc":"inflige","lemma":"infliger","pos":"VER"} ,
		{"word":"infligée","word_nosc":"infligee","lemma":"infliger","pos":"VER"} ,
		{"word":"infligées","word_nosc":"infligees","lemma":"infliger","pos":"VER"} ,
		{"word":"infligés","word_nosc":"infliges","lemma":"infliger","pos":"VER"} ,
		{"word":"influaient","word_nosc":"influaient","lemma":"influer","pos":"VER"} ,
		{"word":"influait","word_nosc":"influait","lemma":"influer","pos":"VER"} ,
		{"word":"influant","word_nosc":"influant","lemma":"influer","pos":"VER"} ,
		{"word":"influe","word_nosc":"influe","lemma":"influer","pos":"VER"} ,
		{"word":"influence","word_nosc":"influence","lemma":"influencer","pos":"VER"} ,
		{"word":"influencent","word_nosc":"influencent","lemma":"influencer","pos":"VER"} ,
		{"word":"influencer","word_nosc":"influencer","lemma":"influencer","pos":"VER"} ,
		{"word":"influencera","word_nosc":"influencera","lemma":"influencer","pos":"VER"} ,
		{"word":"influencerait","word_nosc":"influencerait","lemma":"influencer","pos":"VER"} ,
		{"word":"influencerez","word_nosc":"influencerez","lemma":"influencer","pos":"VER"} ,
		{"word":"influenceriez","word_nosc":"influenceriez","lemma":"influencer","pos":"VER"} ,
		{"word":"influenceront","word_nosc":"influenceront","lemma":"influencer","pos":"VER"} ,
		{"word":"influences","word_nosc":"influences","lemma":"influencer","pos":"VER"} ,
		{"word":"influencé","word_nosc":"influence","lemma":"influencer","pos":"VER"} ,
		{"word":"influencée","word_nosc":"influencee","lemma":"influencer","pos":"VER"} ,
		{"word":"influencés","word_nosc":"influences","lemma":"influencer","pos":"VER"} ,
		{"word":"influent","word_nosc":"influent","lemma":"influer","pos":"VER"} ,
		{"word":"influença","word_nosc":"influenca","lemma":"influencer","pos":"VER"} ,
		{"word":"influençaient","word_nosc":"influencaient","lemma":"influencer","pos":"VER"} ,
		{"word":"influençait","word_nosc":"influencait","lemma":"influencer","pos":"VER"} ,
		{"word":"influençant","word_nosc":"influencant","lemma":"influencer","pos":"VER"} ,
		{"word":"influer","word_nosc":"influer","lemma":"influer","pos":"VER"} ,
		{"word":"influera","word_nosc":"influera","lemma":"influer","pos":"VER"} ,
		{"word":"influeraient","word_nosc":"influeraient","lemma":"influer","pos":"VER"} ,
		{"word":"influeront","word_nosc":"influeront","lemma":"influer","pos":"VER"} ,
		{"word":"influé","word_nosc":"influe","lemma":"influer","pos":"VER"} ,
		{"word":"infléchi","word_nosc":"inflechi","lemma":"infléchir","pos":"VER"} ,
		{"word":"infléchie","word_nosc":"inflechie","lemma":"infléchir","pos":"VER"} ,
		{"word":"infléchir","word_nosc":"inflechir","lemma":"infléchir","pos":"VER"} ,
		{"word":"infléchirai","word_nosc":"inflechirai","lemma":"infléchir","pos":"VER"} ,
		{"word":"infléchissaient","word_nosc":"inflechissaient","lemma":"infléchir","pos":"VER"} ,
		{"word":"infléchissant","word_nosc":"inflechissant","lemma":"infléchir","pos":"VER"} ,
		{"word":"infléchissent","word_nosc":"inflechissent","lemma":"infléchir","pos":"VER"} ,
		{"word":"infléchissez","word_nosc":"inflechissez","lemma":"infléchir","pos":"VER"} ,
		{"word":"infléchit","word_nosc":"inflechit","lemma":"infléchir","pos":"VER"} ,
		{"word":"informa","word_nosc":"informa","lemma":"informer","pos":"VER"} ,
		{"word":"informai","word_nosc":"informai","lemma":"informer","pos":"VER"} ,
		{"word":"informaient","word_nosc":"informaient","lemma":"informer","pos":"VER"} ,
		{"word":"informais","word_nosc":"informais","lemma":"informer","pos":"VER"} ,
		{"word":"informait","word_nosc":"informait","lemma":"informer","pos":"VER"} ,
		{"word":"informant","word_nosc":"informant","lemma":"informer","pos":"VER"} ,
		{"word":"informatiser","word_nosc":"informatiser","lemma":"informatiser","pos":"VER"} ,
		{"word":"informatisé","word_nosc":"informatise","lemma":"informatiser","pos":"VER"} ,
		{"word":"informatisée","word_nosc":"informatisee","lemma":"informatiser","pos":"VER"} ,
		{"word":"informatisées","word_nosc":"informatisees","lemma":"informatiser","pos":"VER"} ,
		{"word":"informatisés","word_nosc":"informatises","lemma":"informatiser","pos":"VER"} ,
		{"word":"informe","word_nosc":"informe","lemma":"informer","pos":"VER"} ,
		{"word":"informent","word_nosc":"informent","lemma":"informer","pos":"VER"} ,
		{"word":"informer","word_nosc":"informer","lemma":"informer","pos":"VER"} ,
		{"word":"informera","word_nosc":"informera","lemma":"informer","pos":"VER"} ,
		{"word":"informerai","word_nosc":"informerai","lemma":"informer","pos":"VER"} ,
		{"word":"informerais","word_nosc":"informerais","lemma":"informer","pos":"VER"} ,
		{"word":"informeras","word_nosc":"informeras","lemma":"informer","pos":"VER"} ,
		{"word":"informerez","word_nosc":"informerez","lemma":"informer","pos":"VER"} ,
		{"word":"informerons","word_nosc":"informerons","lemma":"informer","pos":"VER"} ,
		{"word":"informeront","word_nosc":"informeront","lemma":"informer","pos":"VER"} ,
		{"word":"informes","word_nosc":"informes","lemma":"informer","pos":"VER"} ,
		{"word":"informez","word_nosc":"informez","lemma":"informer","pos":"VER"} ,
		{"word":"informiez","word_nosc":"informiez","lemma":"informer","pos":"VER"} ,
		{"word":"informions","word_nosc":"informions","lemma":"informer","pos":"VER"} ,
		{"word":"informons","word_nosc":"informons","lemma":"informer","pos":"VER"} ,
		{"word":"informèrent","word_nosc":"informerent","lemma":"informer","pos":"VER"} ,
		{"word":"informé","word_nosc":"informe","lemma":"informer","pos":"VER"} ,
		{"word":"informée","word_nosc":"informee","lemma":"informer","pos":"VER"} ,
		{"word":"informées","word_nosc":"informees","lemma":"informer","pos":"VER"} ,
		{"word":"informés","word_nosc":"informes","lemma":"informer","pos":"VER"} ,
		{"word":"infusaient","word_nosc":"infusaient","lemma":"infuser","pos":"VER"} ,
		{"word":"infusait","word_nosc":"infusait","lemma":"infuser","pos":"VER"} ,
		{"word":"infusant","word_nosc":"infusant","lemma":"infuser","pos":"VER"} ,
		{"word":"infuse","word_nosc":"infuse","lemma":"infuser","pos":"VER"} ,
		{"word":"infusent","word_nosc":"infusent","lemma":"infuser","pos":"VER"} ,
		{"word":"infuser","word_nosc":"infuser","lemma":"infuser","pos":"VER"} ,
		{"word":"infusé","word_nosc":"infuse","lemma":"infuser","pos":"VER"} ,
		{"word":"infusée","word_nosc":"infusee","lemma":"infuser","pos":"VER"} ,
		{"word":"infusées","word_nosc":"infusees","lemma":"infuser","pos":"VER"} ,
		{"word":"inféode","word_nosc":"infeode","lemma":"inféoder","pos":"VER"} ,
		{"word":"inféoder","word_nosc":"infeoder","lemma":"inféoder","pos":"VER"} ,
		{"word":"inféodée","word_nosc":"infeodee","lemma":"inféoder","pos":"VER"} ,
		{"word":"inférai","word_nosc":"inferai","lemma":"inférer","pos":"VER"} ,
		{"word":"inférer","word_nosc":"inferer","lemma":"inférer","pos":"VER"} ,
		{"word":"inférioriser","word_nosc":"inferioriser","lemma":"inférioriser","pos":"VER"} ,
		{"word":"inféré","word_nosc":"infere","lemma":"inférer","pos":"VER"} ,
		{"word":"ingurgita","word_nosc":"ingurgita","lemma":"ingurgiter","pos":"VER"} ,
		{"word":"ingurgitaient","word_nosc":"ingurgitaient","lemma":"ingurgiter","pos":"VER"} ,
		{"word":"ingurgitais","word_nosc":"ingurgitais","lemma":"ingurgiter","pos":"VER"} ,
		{"word":"ingurgitait","word_nosc":"ingurgitait","lemma":"ingurgiter","pos":"VER"} ,
		{"word":"ingurgitant","word_nosc":"ingurgitant","lemma":"ingurgiter","pos":"VER"} ,
		{"word":"ingurgite","word_nosc":"ingurgite","lemma":"ingurgiter","pos":"VER"} ,
		{"word":"ingurgitent","word_nosc":"ingurgitent","lemma":"ingurgiter","pos":"VER"} ,
		{"word":"ingurgiter","word_nosc":"ingurgiter","lemma":"ingurgiter","pos":"VER"} ,
		{"word":"ingurgiterait","word_nosc":"ingurgiterait","lemma":"ingurgiter","pos":"VER"} ,
		{"word":"ingurgité","word_nosc":"ingurgite","lemma":"ingurgiter","pos":"VER"} ,
		{"word":"ingurgitée","word_nosc":"ingurgitee","lemma":"ingurgiter","pos":"VER"} ,
		{"word":"ingurgitées","word_nosc":"ingurgitees","lemma":"ingurgiter","pos":"VER"} ,
		{"word":"ingurgités","word_nosc":"ingurgites","lemma":"ingurgiter","pos":"VER"} ,
		{"word":"ingère","word_nosc":"ingere","lemma":"ingérer","pos":"VER"} ,
		{"word":"ingèrent","word_nosc":"ingerent","lemma":"ingérer","pos":"VER"} ,
		{"word":"ingénia","word_nosc":"ingenia","lemma":"ingénier","pos":"VER"} ,
		{"word":"ingéniai","word_nosc":"ingeniai","lemma":"ingénier","pos":"VER"} ,
		{"word":"ingéniaient","word_nosc":"ingeniaient","lemma":"ingénier","pos":"VER"} ,
		{"word":"ingéniais","word_nosc":"ingeniais","lemma":"ingénier","pos":"VER"} ,
		{"word":"ingéniait","word_nosc":"ingeniait","lemma":"ingénier","pos":"VER"} ,
		{"word":"ingéniant","word_nosc":"ingeniant","lemma":"ingénier","pos":"VER"} ,
		{"word":"ingénie","word_nosc":"ingenie","lemma":"ingénier","pos":"VER"} ,
		{"word":"ingénient","word_nosc":"ingenient","lemma":"ingénier","pos":"VER"} ,
		{"word":"ingénier","word_nosc":"ingenier","lemma":"ingénier","pos":"VER"} ,
		{"word":"ingénié","word_nosc":"ingenie","lemma":"ingénier","pos":"VER"} ,
		{"word":"ingéniés","word_nosc":"ingenies","lemma":"ingénier","pos":"VER"} ,
		{"word":"ingérant","word_nosc":"ingerant","lemma":"ingérer","pos":"VER"} ,
		{"word":"ingérer","word_nosc":"ingerer","lemma":"ingérer","pos":"VER"} ,
		{"word":"ingérons","word_nosc":"ingerons","lemma":"ingérer","pos":"VER"} ,
		{"word":"ingéré","word_nosc":"ingere","lemma":"ingérer","pos":"VER"} ,
		{"word":"ingérée","word_nosc":"ingeree","lemma":"ingérer","pos":"VER"} ,
		{"word":"ingérées","word_nosc":"ingerees","lemma":"ingérer","pos":"VER"} ,
		{"word":"ingérés","word_nosc":"ingeres","lemma":"ingérer","pos":"VER"} ,
		{"word":"inhala","word_nosc":"inhala","lemma":"inhaler","pos":"VER"} ,
		{"word":"inhalais","word_nosc":"inhalais","lemma":"inhaler","pos":"VER"} ,
		{"word":"inhalait","word_nosc":"inhalait","lemma":"inhaler","pos":"VER"} ,
		{"word":"inhalant","word_nosc":"inhalant","lemma":"inhaler","pos":"VER"} ,
		{"word":"inhaler","word_nosc":"inhaler","lemma":"inhaler","pos":"VER"} ,
		{"word":"inhalera","word_nosc":"inhalera","lemma":"inhaler","pos":"VER"} ,
		{"word":"inhalez","word_nosc":"inhalez","lemma":"inhaler","pos":"VER"} ,
		{"word":"inhalé","word_nosc":"inhale","lemma":"inhaler","pos":"VER"} ,
		{"word":"inhibait","word_nosc":"inhibait","lemma":"inhiber","pos":"VER"} ,
		{"word":"inhibant","word_nosc":"inhibant","lemma":"inhiber","pos":"VER"} ,
		{"word":"inhibe","word_nosc":"inhibe","lemma":"inhiber","pos":"VER"} ,
		{"word":"inhibent","word_nosc":"inhibent","lemma":"inhiber","pos":"VER"} ,
		{"word":"inhiber","word_nosc":"inhiber","lemma":"inhiber","pos":"VER"} ,
		{"word":"inhibât","word_nosc":"inhibat","lemma":"inhiber","pos":"VER"} ,
		{"word":"inhibé","word_nosc":"inhibe","lemma":"inhiber","pos":"VER"} ,
		{"word":"inhibée","word_nosc":"inhibee","lemma":"inhiber","pos":"VER"} ,
		{"word":"inhibés","word_nosc":"inhibes","lemma":"inhiber","pos":"VER"} ,
		{"word":"inhuma","word_nosc":"inhuma","lemma":"inhumer","pos":"VER"} ,
		{"word":"inhumaient","word_nosc":"inhumaient","lemma":"inhumer","pos":"VER"} ,
		{"word":"inhumait","word_nosc":"inhumait","lemma":"inhumer","pos":"VER"} ,
		{"word":"inhume","word_nosc":"inhume","lemma":"inhumer","pos":"VER"} ,
		{"word":"inhumer","word_nosc":"inhumer","lemma":"inhumer","pos":"VER"} ,
		{"word":"inhumons","word_nosc":"inhumons","lemma":"inhumer","pos":"VER"} ,
		{"word":"inhumé","word_nosc":"inhume","lemma":"inhumer","pos":"VER"} ,
		{"word":"inhumée","word_nosc":"inhumee","lemma":"inhumer","pos":"VER"} ,
		{"word":"inhumés","word_nosc":"inhumes","lemma":"inhumer","pos":"VER"} ,
		{"word":"initia","word_nosc":"initia","lemma":"initier","pos":"VER"} ,
		{"word":"initiai","word_nosc":"initiai","lemma":"initier","pos":"VER"} ,
		{"word":"initiaient","word_nosc":"initiaient","lemma":"initier","pos":"VER"} ,
		{"word":"initiais","word_nosc":"initiais","lemma":"initier","pos":"VER"} ,
		{"word":"initiait","word_nosc":"initiait","lemma":"initier","pos":"VER"} ,
		{"word":"initialise","word_nosc":"initialise","lemma":"initialiser","pos":"VER"} ,
		{"word":"initialiser","word_nosc":"initialiser","lemma":"initialiser","pos":"VER"} ,
		{"word":"initialisez","word_nosc":"initialisez","lemma":"initialiser","pos":"VER"} ,
		{"word":"initialisé","word_nosc":"initialise","lemma":"initialiser","pos":"VER"} ,
		{"word":"initiant","word_nosc":"initiant","lemma":"initier","pos":"VER"} ,
		{"word":"initie","word_nosc":"initie","lemma":"initier","pos":"VER"} ,
		{"word":"initier","word_nosc":"initier","lemma":"initier","pos":"VER"} ,
		{"word":"initiera","word_nosc":"initiera","lemma":"initier","pos":"VER"} ,
		{"word":"initierai","word_nosc":"initierai","lemma":"initier","pos":"VER"} ,
		{"word":"initierait","word_nosc":"initierait","lemma":"initier","pos":"VER"} ,
		{"word":"initierons","word_nosc":"initierons","lemma":"initier","pos":"VER"} ,
		{"word":"initiez","word_nosc":"initiez","lemma":"initier","pos":"VER"} ,
		{"word":"initiâmes","word_nosc":"initiames","lemma":"initier","pos":"VER"} ,
		{"word":"initiât","word_nosc":"initiat","lemma":"initier","pos":"VER"} ,
		{"word":"initièrent","word_nosc":"initierent","lemma":"initier","pos":"VER"} ,
		{"word":"initié","word_nosc":"initie","lemma":"initier","pos":"VER"} ,
		{"word":"initiée","word_nosc":"initiee","lemma":"initier","pos":"VER"} ,
		{"word":"initiées","word_nosc":"initiees","lemma":"initier","pos":"VER"} ,
		{"word":"initiés","word_nosc":"inities","lemma":"initier","pos":"VER"} ,
		{"word":"injecta","word_nosc":"injecta","lemma":"injecter","pos":"VER"} ,
		{"word":"injectaient","word_nosc":"injectaient","lemma":"injecter","pos":"VER"} ,
		{"word":"injectait","word_nosc":"injectait","lemma":"injecter","pos":"VER"} ,
		{"word":"injectant","word_nosc":"injectant","lemma":"injecter","pos":"VER"} ,
		{"word":"injecte","word_nosc":"injecte","lemma":"injecter","pos":"VER"} ,
		{"word":"injectent","word_nosc":"injectent","lemma":"injecter","pos":"VER"} ,
		{"word":"injecter","word_nosc":"injecter","lemma":"injecter","pos":"VER"} ,
		{"word":"injectera","word_nosc":"injectera","lemma":"injecter","pos":"VER"} ,
		{"word":"injecteras","word_nosc":"injecteras","lemma":"injecter","pos":"VER"} ,
		{"word":"injectes","word_nosc":"injectes","lemma":"injecter","pos":"VER"} ,
		{"word":"injectez","word_nosc":"injectez","lemma":"injecter","pos":"VER"} ,
		{"word":"injecté","word_nosc":"injecte","lemma":"injecter","pos":"VER"} ,
		{"word":"injectée","word_nosc":"injectee","lemma":"injecter","pos":"VER"} ,
		{"word":"injectés","word_nosc":"injectes","lemma":"injecter","pos":"VER"} ,
		{"word":"injuria","word_nosc":"injuria","lemma":"injurier","pos":"VER"} ,
		{"word":"injuriai","word_nosc":"injuriai","lemma":"injurier","pos":"VER"} ,
		{"word":"injuriaient","word_nosc":"injuriaient","lemma":"injurier","pos":"VER"} ,
		{"word":"injuriais","word_nosc":"injuriais","lemma":"injurier","pos":"VER"} ,
		{"word":"injuriait","word_nosc":"injuriait","lemma":"injurier","pos":"VER"} ,
		{"word":"injuriant","word_nosc":"injuriant","lemma":"injurier","pos":"VER"} ,
		{"word":"injurie","word_nosc":"injurie","lemma":"injurier","pos":"VER"} ,
		{"word":"injurient","word_nosc":"injurient","lemma":"injurier","pos":"VER"} ,
		{"word":"injurier","word_nosc":"injurier","lemma":"injurier","pos":"VER"} ,
		{"word":"injuries","word_nosc":"injuries","lemma":"injurier","pos":"VER"} ,
		{"word":"injurié","word_nosc":"injurie","lemma":"injurier","pos":"VER"} ,
		{"word":"injuriée","word_nosc":"injuriee","lemma":"injurier","pos":"VER"} ,
		{"word":"injuriés","word_nosc":"injuries","lemma":"injurier","pos":"VER"} ,
		{"word":"innervant","word_nosc":"innervant","lemma":"innerver","pos":"VER"} ,
		{"word":"innerver","word_nosc":"innerver","lemma":"innerver","pos":"VER"} ,
		{"word":"innervé","word_nosc":"innerve","lemma":"innerver","pos":"VER"} ,
		{"word":"innocentait","word_nosc":"innocentait","lemma":"innocenter","pos":"VER"} ,
		{"word":"innocentant","word_nosc":"innocentant","lemma":"innocenter","pos":"VER"} ,
		{"word":"innocente","word_nosc":"innocente","lemma":"innocenter","pos":"VER"} ,
		{"word":"innocenter","word_nosc":"innocenter","lemma":"innocenter","pos":"VER"} ,
		{"word":"innocentera","word_nosc":"innocentera","lemma":"innocenter","pos":"VER"} ,
		{"word":"innocenterai","word_nosc":"innocenterai","lemma":"innocenter","pos":"VER"} ,
		{"word":"innocenterait","word_nosc":"innocenterait","lemma":"innocenter","pos":"VER"} ,
		{"word":"innocentes","word_nosc":"innocentes","lemma":"innocenter","pos":"VER"} ,
		{"word":"innocenté","word_nosc":"innocente","lemma":"innocenter","pos":"VER"} ,
		{"word":"innocentée","word_nosc":"innocentee","lemma":"innocenter","pos":"VER"} ,
		{"word":"innova","word_nosc":"innova","lemma":"innover","pos":"VER"} ,
		{"word":"innovais","word_nosc":"innovais","lemma":"innover","pos":"VER"} ,
		{"word":"innovait","word_nosc":"innovait","lemma":"innover","pos":"VER"} ,
		{"word":"innovant","word_nosc":"innovant","lemma":"innover","pos":"VER"} ,
		{"word":"innove","word_nosc":"innove","lemma":"innover","pos":"VER"} ,
		{"word":"innover","word_nosc":"innover","lemma":"innover","pos":"VER"} ,
		{"word":"innovez","word_nosc":"innovez","lemma":"innover","pos":"VER"} ,
		{"word":"innové","word_nosc":"innove","lemma":"innover","pos":"VER"} ,
		{"word":"inoculant","word_nosc":"inoculant","lemma":"inoculer","pos":"VER"} ,
		{"word":"inocule","word_nosc":"inocule","lemma":"inoculer","pos":"VER"} ,
		{"word":"inoculent","word_nosc":"inoculent","lemma":"inoculer","pos":"VER"} ,
		{"word":"inoculer","word_nosc":"inoculer","lemma":"inoculer","pos":"VER"} ,
		{"word":"inoculerait","word_nosc":"inoculerait","lemma":"inoculer","pos":"VER"} ,
		{"word":"inoculé","word_nosc":"inocule","lemma":"inoculer","pos":"VER"} ,
		{"word":"inoculée","word_nosc":"inoculee","lemma":"inoculer","pos":"VER"} ,
		{"word":"inoculées","word_nosc":"inoculees","lemma":"inoculer","pos":"VER"} ,
		{"word":"inonda","word_nosc":"inonda","lemma":"inonder","pos":"VER"} ,
		{"word":"inondaient","word_nosc":"inondaient","lemma":"inonder","pos":"VER"} ,
		{"word":"inondait","word_nosc":"inondait","lemma":"inonder","pos":"VER"} ,
		{"word":"inondant","word_nosc":"inondant","lemma":"inonder","pos":"VER"} ,
		{"word":"inonde","word_nosc":"inonde","lemma":"inonder","pos":"VER"} ,
		{"word":"inondent","word_nosc":"inondent","lemma":"inonder","pos":"VER"} ,
		{"word":"inonder","word_nosc":"inonder","lemma":"inonder","pos":"VER"} ,
		{"word":"inondera","word_nosc":"inondera","lemma":"inonder","pos":"VER"} ,
		{"word":"inonderai","word_nosc":"inonderai","lemma":"inonder","pos":"VER"} ,
		{"word":"inonderait","word_nosc":"inonderait","lemma":"inonder","pos":"VER"} ,
		{"word":"inonderons","word_nosc":"inonderons","lemma":"inonder","pos":"VER"} ,
		{"word":"inondez","word_nosc":"inondez","lemma":"inonder","pos":"VER"} ,
		{"word":"inondiez","word_nosc":"inondiez","lemma":"inonder","pos":"VER"} ,
		{"word":"inondât","word_nosc":"inondat","lemma":"inonder","pos":"VER"} ,
		{"word":"inondèrent","word_nosc":"inonderent","lemma":"inonder","pos":"VER"} ,
		{"word":"inondé","word_nosc":"inonde","lemma":"inonder","pos":"VER"} ,
		{"word":"inondée","word_nosc":"inondee","lemma":"inonder","pos":"VER"} ,
		{"word":"inondées","word_nosc":"inondees","lemma":"inonder","pos":"VER"} ,
		{"word":"inondés","word_nosc":"inondes","lemma":"inonder","pos":"VER"} ,
		{"word":"inquisitionner","word_nosc":"inquisitionner","lemma":"inquisitionner","pos":"VER"} ,
		{"word":"inquiète","word_nosc":"inquiete","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiètent","word_nosc":"inquietent","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiètes","word_nosc":"inquietes","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiéta","word_nosc":"inquieta","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétai","word_nosc":"inquietai","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétaient","word_nosc":"inquietaient","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétais","word_nosc":"inquietais","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétait","word_nosc":"inquietait","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétant","word_nosc":"inquietant","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétassent","word_nosc":"inquietassent","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiéter","word_nosc":"inquieter","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétera","word_nosc":"inquietera","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiéterai","word_nosc":"inquieterai","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiéteraient","word_nosc":"inquieteraient","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiéterais","word_nosc":"inquieterais","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiéterait","word_nosc":"inquieterait","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiéteras","word_nosc":"inquieteras","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiéteriez","word_nosc":"inquieteriez","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiéteront","word_nosc":"inquieteront","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétez","word_nosc":"inquietez","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétiez","word_nosc":"inquietiez","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétions","word_nosc":"inquietions","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétons","word_nosc":"inquietons","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétât","word_nosc":"inquietat","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétèrent","word_nosc":"inquieterent","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiété","word_nosc":"inquiete","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétée","word_nosc":"inquietee","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétées","word_nosc":"inquietees","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inquiétés","word_nosc":"inquietes","lemma":"inquiéter","pos":"VER"} ,
		{"word":"inscrira","word_nosc":"inscrira","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrirai","word_nosc":"inscrirai","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscriraient","word_nosc":"inscriraient","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrirais","word_nosc":"inscrirais","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrirait","word_nosc":"inscrirait","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscriras","word_nosc":"inscriras","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrire","word_nosc":"inscrire","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscris","word_nosc":"inscris","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrit","word_nosc":"inscrit","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrite","word_nosc":"inscrite","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrites","word_nosc":"inscrites","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrits","word_nosc":"inscrits","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrivaient","word_nosc":"inscrivaient","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrivais","word_nosc":"inscrivais","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrivait","word_nosc":"inscrivait","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrivant","word_nosc":"inscrivant","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrive","word_nosc":"inscrive","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrivent","word_nosc":"inscrivent","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrivez","word_nosc":"inscrivez","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrivirent","word_nosc":"inscrivirent","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrivis","word_nosc":"inscrivis","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrivit","word_nosc":"inscrivit","lemma":"inscrire","pos":"VER"} ,
		{"word":"inscrivons","word_nosc":"inscrivons","lemma":"inscrire","pos":"VER"} ,
		{"word":"insensibilisait","word_nosc":"insensibilisait","lemma":"insensibiliser","pos":"VER"} ,
		{"word":"insensibiliser","word_nosc":"insensibiliser","lemma":"insensibiliser","pos":"VER"} ,
		{"word":"insensibilisé","word_nosc":"insensibilise","lemma":"insensibiliser","pos":"VER"} ,
		{"word":"insensibilisée","word_nosc":"insensibilisee","lemma":"insensibiliser","pos":"VER"} ,
		{"word":"insinua","word_nosc":"insinua","lemma":"insinuer","pos":"VER"} ,
		{"word":"insinuai","word_nosc":"insinuai","lemma":"insinuer","pos":"VER"} ,
		{"word":"insinuaient","word_nosc":"insinuaient","lemma":"insinuer","pos":"VER"} ,
		{"word":"insinuais","word_nosc":"insinuais","lemma":"insinuer","pos":"VER"} ,
		{"word":"insinuait","word_nosc":"insinuait","lemma":"insinuer","pos":"VER"} ,
		{"word":"insinuant","word_nosc":"insinuant","lemma":"insinuer","pos":"VER"} ,
		{"word":"insinue","word_nosc":"insinue","lemma":"insinuer","pos":"VER"} ,
		{"word":"insinuent","word_nosc":"insinuent","lemma":"insinuer","pos":"VER"} ,
		{"word":"insinuer","word_nosc":"insinuer","lemma":"insinuer","pos":"VER"} ,
		{"word":"insinuerai","word_nosc":"insinuerai","lemma":"insinuer","pos":"VER"} ,
		{"word":"insinuerait","word_nosc":"insinuerait","lemma":"insinuer","pos":"VER"} ,
		{"word":"insinues","word_nosc":"insinues","lemma":"insinuer","pos":"VER"} ,
		{"word":"insinuez","word_nosc":"insinuez","lemma":"insinuer","pos":"VER"} ,
		{"word":"insinuiez","word_nosc":"insinuiez","lemma":"insinuer","pos":"VER"} ,
		{"word":"insinué","word_nosc":"insinue","lemma":"insinuer","pos":"VER"} ,
		{"word":"insinuée","word_nosc":"insinuee","lemma":"insinuer","pos":"VER"} ,
		{"word":"insista","word_nosc":"insista","lemma":"insister","pos":"VER"} ,
		{"word":"insistai","word_nosc":"insistai","lemma":"insister","pos":"VER"} ,
		{"word":"insistaient","word_nosc":"insistaient","lemma":"insister","pos":"VER"} ,
		{"word":"insistais","word_nosc":"insistais","lemma":"insister","pos":"VER"} ,
		{"word":"insistait","word_nosc":"insistait","lemma":"insister","pos":"VER"} ,
		{"word":"insistant","word_nosc":"insistant","lemma":"insister","pos":"VER"} ,
		{"word":"insiste","word_nosc":"insiste","lemma":"insister","pos":"VER"} ,
		{"word":"insistent","word_nosc":"insistent","lemma":"insister","pos":"VER"} ,
		{"word":"insister","word_nosc":"insister","lemma":"insister","pos":"VER"} ,
		{"word":"insistera","word_nosc":"insistera","lemma":"insister","pos":"VER"} ,
		{"word":"insisterai","word_nosc":"insisterai","lemma":"insister","pos":"VER"} ,
		{"word":"insisteraient","word_nosc":"insisteraient","lemma":"insister","pos":"VER"} ,
		{"word":"insisterais","word_nosc":"insisterais","lemma":"insister","pos":"VER"} ,
		{"word":"insisterait","word_nosc":"insisterait","lemma":"insister","pos":"VER"} ,
		{"word":"insisteras","word_nosc":"insisteras","lemma":"insister","pos":"VER"} ,
		{"word":"insisterons","word_nosc":"insisterons","lemma":"insister","pos":"VER"} ,
		{"word":"insistes","word_nosc":"insistes","lemma":"insister","pos":"VER"} ,
		{"word":"insistez","word_nosc":"insistez","lemma":"insister","pos":"VER"} ,
		{"word":"insistiez","word_nosc":"insistiez","lemma":"insister","pos":"VER"} ,
		{"word":"insistions","word_nosc":"insistions","lemma":"insister","pos":"VER"} ,
		{"word":"insistons","word_nosc":"insistons","lemma":"insister","pos":"VER"} ,
		{"word":"insistât","word_nosc":"insistat","lemma":"insister","pos":"VER"} ,
		{"word":"insistèrent","word_nosc":"insisterent","lemma":"insister","pos":"VER"} ,
		{"word":"insisté","word_nosc":"insiste","lemma":"insister","pos":"VER"} ,
		{"word":"insolant","word_nosc":"insolant","lemma":"insoler","pos":"VER"} ,
		{"word":"insolent","word_nosc":"insolent","lemma":"insoler","pos":"VER"} ,
		{"word":"insolé","word_nosc":"insole","lemma":"insoler","pos":"VER"} ,
		{"word":"insonoriser","word_nosc":"insonoriser","lemma":"insonoriser","pos":"VER"} ,
		{"word":"insonorisé","word_nosc":"insonorise","lemma":"insonoriser","pos":"VER"} ,
		{"word":"insonorisée","word_nosc":"insonorisee","lemma":"insonoriser","pos":"VER"} ,
		{"word":"insonorisées","word_nosc":"insonorisees","lemma":"insonoriser","pos":"VER"} ,
		{"word":"insonorisés","word_nosc":"insonorises","lemma":"insonoriser","pos":"VER"} ,
		{"word":"inspecta","word_nosc":"inspecta","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspectai","word_nosc":"inspectai","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspectaient","word_nosc":"inspectaient","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspectais","word_nosc":"inspectais","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspectait","word_nosc":"inspectait","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspectant","word_nosc":"inspectant","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspecte","word_nosc":"inspecte","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspectent","word_nosc":"inspectent","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspecter","word_nosc":"inspecter","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspectera","word_nosc":"inspectera","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspecterais","word_nosc":"inspecterais","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspecterez","word_nosc":"inspecterez","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspecteront","word_nosc":"inspecteront","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspectes","word_nosc":"inspectes","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspectez","word_nosc":"inspectez","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspectiez","word_nosc":"inspectiez","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspections","word_nosc":"inspections","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspectons","word_nosc":"inspectons","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspectèrent","word_nosc":"inspecterent","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspecté","word_nosc":"inspecte","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspectée","word_nosc":"inspectee","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspectées","word_nosc":"inspectees","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspectés","word_nosc":"inspectes","lemma":"inspecter","pos":"VER"} ,
		{"word":"inspira","word_nosc":"inspira","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspirai","word_nosc":"inspirai","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspiraient","word_nosc":"inspiraient","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspirais","word_nosc":"inspirais","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspirait","word_nosc":"inspirait","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspirant","word_nosc":"inspirant","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspire","word_nosc":"inspire","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspirent","word_nosc":"inspirent","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspirer","word_nosc":"inspirer","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspirera","word_nosc":"inspirera","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspireraient","word_nosc":"inspireraient","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspirerait","word_nosc":"inspirerait","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspireras","word_nosc":"inspireras","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspireront","word_nosc":"inspireront","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspires","word_nosc":"inspires","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspirez","word_nosc":"inspirez","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspiriez","word_nosc":"inspiriez","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspirions","word_nosc":"inspirions","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspirât","word_nosc":"inspirat","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspirèrent","word_nosc":"inspirerent","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspiré","word_nosc":"inspire","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspirée","word_nosc":"inspiree","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspirées","word_nosc":"inspirees","lemma":"inspirer","pos":"VER"} ,
		{"word":"inspirés","word_nosc":"inspires","lemma":"inspirer","pos":"VER"} ,
		{"word":"installa","word_nosc":"installa","lemma":"installer","pos":"VER"} ,
		{"word":"installai","word_nosc":"installai","lemma":"installer","pos":"VER"} ,
		{"word":"installaient","word_nosc":"installaient","lemma":"installer","pos":"VER"} ,
		{"word":"installais","word_nosc":"installais","lemma":"installer","pos":"VER"} ,
		{"word":"installait","word_nosc":"installait","lemma":"installer","pos":"VER"} ,
		{"word":"installant","word_nosc":"installant","lemma":"installer","pos":"VER"} ,
		{"word":"installasse","word_nosc":"installasse","lemma":"installer","pos":"VER"} ,
		{"word":"installe","word_nosc":"installe","lemma":"installer","pos":"VER"} ,
		{"word":"installent","word_nosc":"installent","lemma":"installer","pos":"VER"} ,
		{"word":"installer","word_nosc":"installer","lemma":"installer","pos":"VER"} ,
		{"word":"installera","word_nosc":"installera","lemma":"installer","pos":"VER"} ,
		{"word":"installerai","word_nosc":"installerai","lemma":"installer","pos":"VER"} ,
		{"word":"installeraient","word_nosc":"installeraient","lemma":"installer","pos":"VER"} ,
		{"word":"installerais","word_nosc":"installerais","lemma":"installer","pos":"VER"} ,
		{"word":"installerait","word_nosc":"installerait","lemma":"installer","pos":"VER"} ,
		{"word":"installeras","word_nosc":"installeras","lemma":"installer","pos":"VER"} ,
		{"word":"installerez","word_nosc":"installerez","lemma":"installer","pos":"VER"} ,
		{"word":"installerions","word_nosc":"installerions","lemma":"installer","pos":"VER"} ,
		{"word":"installerons","word_nosc":"installerons","lemma":"installer","pos":"VER"} ,
		{"word":"installeront","word_nosc":"installeront","lemma":"installer","pos":"VER"} ,
		{"word":"installes","word_nosc":"installes","lemma":"installer","pos":"VER"} ,
		{"word":"installez","word_nosc":"installez","lemma":"installer","pos":"VER"} ,
		{"word":"installiez","word_nosc":"installiez","lemma":"installer","pos":"VER"} ,
		{"word":"installions","word_nosc":"installions","lemma":"installer","pos":"VER"} ,
		{"word":"installons","word_nosc":"installons","lemma":"installer","pos":"VER"} ,
		{"word":"installâmes","word_nosc":"installames","lemma":"installer","pos":"VER"} ,
		{"word":"installât","word_nosc":"installat","lemma":"installer","pos":"VER"} ,
		{"word":"installèrent","word_nosc":"installerent","lemma":"installer","pos":"VER"} ,
		{"word":"installé","word_nosc":"installe","lemma":"installer","pos":"VER"} ,
		{"word":"installée","word_nosc":"installee","lemma":"installer","pos":"VER"} ,
		{"word":"installées","word_nosc":"installees","lemma":"installer","pos":"VER"} ,
		{"word":"installés","word_nosc":"installes","lemma":"installer","pos":"VER"} ,
		{"word":"instaura","word_nosc":"instaura","lemma":"instaurer","pos":"VER"} ,
		{"word":"instaurai","word_nosc":"instaurai","lemma":"instaurer","pos":"VER"} ,
		{"word":"instaurait","word_nosc":"instaurait","lemma":"instaurer","pos":"VER"} ,
		{"word":"instaurant","word_nosc":"instaurant","lemma":"instaurer","pos":"VER"} ,
		{"word":"instaure","word_nosc":"instaure","lemma":"instaurer","pos":"VER"} ,
		{"word":"instaurer","word_nosc":"instaurer","lemma":"instaurer","pos":"VER"} ,
		{"word":"instaurera","word_nosc":"instaurera","lemma":"instaurer","pos":"VER"} ,
		{"word":"instaurerions","word_nosc":"instaurerions","lemma":"instaurer","pos":"VER"} ,
		{"word":"instaurions","word_nosc":"instaurions","lemma":"instaurer","pos":"VER"} ,
		{"word":"instaurèrent","word_nosc":"instaurerent","lemma":"instaurer","pos":"VER"} ,
		{"word":"instauré","word_nosc":"instaure","lemma":"instaurer","pos":"VER"} ,
		{"word":"instaurée","word_nosc":"instauree","lemma":"instaurer","pos":"VER"} ,
		{"word":"instaurées","word_nosc":"instaurees","lemma":"instaurer","pos":"VER"} ,
		{"word":"instaurés","word_nosc":"instaures","lemma":"instaurer","pos":"VER"} ,
		{"word":"instilla","word_nosc":"instilla","lemma":"instiller","pos":"VER"} ,
		{"word":"instille","word_nosc":"instille","lemma":"instiller","pos":"VER"} ,
		{"word":"instiller","word_nosc":"instiller","lemma":"instiller","pos":"VER"} ,
		{"word":"instillera","word_nosc":"instillera","lemma":"instiller","pos":"VER"} ,
		{"word":"instillé","word_nosc":"instille","lemma":"instiller","pos":"VER"} ,
		{"word":"instillées","word_nosc":"instillees","lemma":"instiller","pos":"VER"} ,
		{"word":"institua","word_nosc":"institua","lemma":"instituer","pos":"VER"} ,
		{"word":"instituai","word_nosc":"instituai","lemma":"instituer","pos":"VER"} ,
		{"word":"instituaient","word_nosc":"instituaient","lemma":"instituer","pos":"VER"} ,
		{"word":"instituait","word_nosc":"instituait","lemma":"instituer","pos":"VER"} ,
		{"word":"instituant","word_nosc":"instituant","lemma":"instituer","pos":"VER"} ,
		{"word":"institue","word_nosc":"institue","lemma":"instituer","pos":"VER"} ,
		{"word":"instituent","word_nosc":"instituent","lemma":"instituer","pos":"VER"} ,
		{"word":"instituer","word_nosc":"instituer","lemma":"instituer","pos":"VER"} ,
		{"word":"instituons","word_nosc":"instituons","lemma":"instituer","pos":"VER"} ,
		{"word":"institutionnalisé","word_nosc":"institutionnalise","lemma":"institutionnaliser","pos":"VER"} ,
		{"word":"instituèrent","word_nosc":"instituerent","lemma":"instituer","pos":"VER"} ,
		{"word":"institué","word_nosc":"institue","lemma":"instituer","pos":"VER"} ,
		{"word":"instituée","word_nosc":"instituee","lemma":"instituer","pos":"VER"} ,
		{"word":"instituées","word_nosc":"instituees","lemma":"instituer","pos":"VER"} ,
		{"word":"institués","word_nosc":"institues","lemma":"instituer","pos":"VER"} ,
		{"word":"instruira","word_nosc":"instruira","lemma":"instruire","pos":"VER"} ,
		{"word":"instruirai","word_nosc":"instruirai","lemma":"instruire","pos":"VER"} ,
		{"word":"instruiraient","word_nosc":"instruiraient","lemma":"instruire","pos":"VER"} ,
		{"word":"instruirait","word_nosc":"instruirait","lemma":"instruire","pos":"VER"} ,
		{"word":"instruire","word_nosc":"instruire","lemma":"instruire","pos":"VER"} ,
		{"word":"instruis","word_nosc":"instruis","lemma":"instruire","pos":"VER"} ,
		{"word":"instruisaient","word_nosc":"instruisaient","lemma":"instruire","pos":"VER"} ,
		{"word":"instruisais","word_nosc":"instruisais","lemma":"instruire","pos":"VER"} ,
		{"word":"instruisait","word_nosc":"instruisait","lemma":"instruire","pos":"VER"} ,
		{"word":"instruisant","word_nosc":"instruisant","lemma":"instruire","pos":"VER"} ,
		{"word":"instruise","word_nosc":"instruise","lemma":"instruire","pos":"VER"} ,
		{"word":"instruisent","word_nosc":"instruisent","lemma":"instruire","pos":"VER"} ,
		{"word":"instruisez","word_nosc":"instruisez","lemma":"instruire","pos":"VER"} ,
		{"word":"instruisirent","word_nosc":"instruisirent","lemma":"instruire","pos":"VER"} ,
		{"word":"instruisis","word_nosc":"instruisis","lemma":"instruire","pos":"VER"} ,
		{"word":"instruisit","word_nosc":"instruisit","lemma":"instruire","pos":"VER"} ,
		{"word":"instruisîmes","word_nosc":"instruisimes","lemma":"instruire","pos":"VER"} ,
		{"word":"instruit","word_nosc":"instruit","lemma":"instruire","pos":"VER"} ,
		{"word":"instruite","word_nosc":"instruite","lemma":"instruire","pos":"VER"} ,
		{"word":"instruites","word_nosc":"instruites","lemma":"instruit","pos":"VER"} ,
		{"word":"instruits","word_nosc":"instruits","lemma":"instruire","pos":"VER"} ,
		{"word":"instrumentais","word_nosc":"instrumentais","lemma":"instrumenter","pos":"VER"} ,
		{"word":"instrumenter","word_nosc":"instrumenter","lemma":"instrumenter","pos":"VER"} ,
		{"word":"insufflaient","word_nosc":"insufflaient","lemma":"insuffler","pos":"VER"} ,
		{"word":"insufflait","word_nosc":"insufflait","lemma":"insuffler","pos":"VER"} ,
		{"word":"insufflant","word_nosc":"insufflant","lemma":"insuffler","pos":"VER"} ,
		{"word":"insuffle","word_nosc":"insuffle","lemma":"insuffler","pos":"VER"} ,
		{"word":"insufflent","word_nosc":"insufflent","lemma":"insuffler","pos":"VER"} ,
		{"word":"insuffler","word_nosc":"insuffler","lemma":"insuffler","pos":"VER"} ,
		{"word":"insufflera","word_nosc":"insufflera","lemma":"insuffler","pos":"VER"} ,
		{"word":"insufflé","word_nosc":"insuffle","lemma":"insuffler","pos":"VER"} ,
		{"word":"insufflée","word_nosc":"insufflee","lemma":"insuffler","pos":"VER"} ,
		{"word":"insufflées","word_nosc":"insufflees","lemma":"insuffler","pos":"VER"} ,
		{"word":"insulta","word_nosc":"insulta","lemma":"insulter","pos":"VER"} ,
		{"word":"insultaient","word_nosc":"insultaient","lemma":"insulter","pos":"VER"} ,
		{"word":"insultais","word_nosc":"insultais","lemma":"insulter","pos":"VER"} ,
		{"word":"insultait","word_nosc":"insultait","lemma":"insulter","pos":"VER"} ,
		{"word":"insultant","word_nosc":"insultant","lemma":"insulter","pos":"VER"} ,
		{"word":"insulte","word_nosc":"insulte","lemma":"insulter","pos":"VER"} ,
		{"word":"insultent","word_nosc":"insultent","lemma":"insulter","pos":"VER"} ,
		{"word":"insulter","word_nosc":"insulter","lemma":"insulter","pos":"VER"} ,
		{"word":"insulterai","word_nosc":"insulterai","lemma":"insulter","pos":"VER"} ,
		{"word":"insulterais","word_nosc":"insulterais","lemma":"insulter","pos":"VER"} ,
		{"word":"insulteras","word_nosc":"insulteras","lemma":"insulter","pos":"VER"} ,
		{"word":"insultes","word_nosc":"insultes","lemma":"insulter","pos":"VER"} ,
		{"word":"insultez","word_nosc":"insultez","lemma":"insulter","pos":"VER"} ,
		{"word":"insultions","word_nosc":"insultions","lemma":"insulter","pos":"VER"} ,
		{"word":"insultons","word_nosc":"insultons","lemma":"insulter","pos":"VER"} ,
		{"word":"insulté","word_nosc":"insulte","lemma":"insulter","pos":"VER"} ,
		{"word":"insultée","word_nosc":"insultee","lemma":"insulter","pos":"VER"} ,
		{"word":"insultées","word_nosc":"insultees","lemma":"insulter","pos":"VER"} ,
		{"word":"insultés","word_nosc":"insultes","lemma":"insulter","pos":"VER"} ,
		{"word":"insupportaient","word_nosc":"insupportaient","lemma":"insupporter","pos":"VER"} ,
		{"word":"insupporte","word_nosc":"insupporte","lemma":"insupporter","pos":"VER"} ,
		{"word":"insupportent","word_nosc":"insupportent","lemma":"insupporter","pos":"VER"} ,
		{"word":"insurge","word_nosc":"insurge","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgea","word_nosc":"insurgea","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgeai","word_nosc":"insurgeai","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgeaient","word_nosc":"insurgeaient","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgeais","word_nosc":"insurgeais","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgeait","word_nosc":"insurgeait","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgeant","word_nosc":"insurgeant","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgent","word_nosc":"insurgent","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgeons","word_nosc":"insurgeons","lemma":"insurger","pos":"VER"} ,
		{"word":"insurger","word_nosc":"insurger","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgera","word_nosc":"insurgera","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgerai","word_nosc":"insurgerai","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgerait","word_nosc":"insurgerait","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgeât","word_nosc":"insurgeat","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgèrent","word_nosc":"insurgerent","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgé","word_nosc":"insurge","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgée","word_nosc":"insurgee","lemma":"insurger","pos":"VER"} ,
		{"word":"insurgés","word_nosc":"insurges","lemma":"insurger","pos":"VER"} ,
		{"word":"insère","word_nosc":"insere","lemma":"insérer","pos":"VER"} ,
		{"word":"insèrent","word_nosc":"inserent","lemma":"insérer","pos":"VER"} ,
		{"word":"inséminer","word_nosc":"inseminer","lemma":"inséminer","pos":"VER"} ,
		{"word":"inséminé","word_nosc":"insemine","lemma":"inséminer","pos":"VER"} ,
		{"word":"inséminée","word_nosc":"inseminee","lemma":"inséminer","pos":"VER"} ,
		{"word":"inséminés","word_nosc":"insemines","lemma":"inséminer","pos":"VER"} ,
		{"word":"inséra","word_nosc":"insera","lemma":"insérer","pos":"VER"} ,
		{"word":"inséraient","word_nosc":"inseraient","lemma":"insérer","pos":"VER"} ,
		{"word":"insérait","word_nosc":"inserait","lemma":"insérer","pos":"VER"} ,
		{"word":"insérant","word_nosc":"inserant","lemma":"insérer","pos":"VER"} ,
		{"word":"insérer","word_nosc":"inserer","lemma":"insérer","pos":"VER"} ,
		{"word":"insérera","word_nosc":"inserera","lemma":"insérer","pos":"VER"} ,
		{"word":"insérerait","word_nosc":"insererait","lemma":"insérer","pos":"VER"} ,
		{"word":"insérerons","word_nosc":"insererons","lemma":"insérer","pos":"VER"} ,
		{"word":"insérez","word_nosc":"inserez","lemma":"insérer","pos":"VER"} ,
		{"word":"insérons","word_nosc":"inserons","lemma":"insérer","pos":"VER"} ,
		{"word":"insérèrent","word_nosc":"insererent","lemma":"insérer","pos":"VER"} ,
		{"word":"inséré","word_nosc":"insere","lemma":"insérer","pos":"VER"} ,
		{"word":"insérée","word_nosc":"inseree","lemma":"insérer","pos":"VER"} ,
		{"word":"insérées","word_nosc":"inserees","lemma":"insérer","pos":"VER"} ,
		{"word":"insérés","word_nosc":"inseres","lemma":"insérer","pos":"VER"} ,
		{"word":"intellectualise","word_nosc":"intellectualise","lemma":"intellectualiser","pos":"VER"} ,
		{"word":"intellectualiser","word_nosc":"intellectualiser","lemma":"intellectualiser","pos":"VER"} ,
		{"word":"intellectualisées","word_nosc":"intellectualisees","lemma":"intellectualiser","pos":"VER"} ,
		{"word":"intellige","word_nosc":"intellige","lemma":"intelliger","pos":"VER"} ,
		{"word":"intensifia","word_nosc":"intensifia","lemma":"intensifier","pos":"VER"} ,
		{"word":"intensifiaient","word_nosc":"intensifiaient","lemma":"intensifier","pos":"VER"} ,
		{"word":"intensifiait","word_nosc":"intensifiait","lemma":"intensifier","pos":"VER"} ,
		{"word":"intensifiant","word_nosc":"intensifiant","lemma":"intensifier","pos":"VER"} ,
		{"word":"intensifie","word_nosc":"intensifie","lemma":"intensifier","pos":"VER"} ,
		{"word":"intensifient","word_nosc":"intensifient","lemma":"intensifier","pos":"VER"} ,
		{"word":"intensifier","word_nosc":"intensifier","lemma":"intensifier","pos":"VER"} ,
		{"word":"intensifiez","word_nosc":"intensifiez","lemma":"intensifier","pos":"VER"} ,
		{"word":"intensifié","word_nosc":"intensifie","lemma":"intensifier","pos":"VER"} ,
		{"word":"intensifiée","word_nosc":"intensifiee","lemma":"intensifier","pos":"VER"} ,
		{"word":"intensifiées","word_nosc":"intensifiees","lemma":"intensifier","pos":"VER"} ,
		{"word":"intenta","word_nosc":"intenta","lemma":"intenter","pos":"VER"} ,
		{"word":"intentait","word_nosc":"intentait","lemma":"intenter","pos":"VER"} ,
		{"word":"intente","word_nosc":"intente","lemma":"intenter","pos":"VER"} ,
		{"word":"intenter","word_nosc":"intenter","lemma":"intenter","pos":"VER"} ,
		{"word":"intentera","word_nosc":"intentera","lemma":"intenter","pos":"VER"} ,
		{"word":"intenterai","word_nosc":"intenterai","lemma":"intenter","pos":"VER"} ,
		{"word":"intentions","word_nosc":"intentions","lemma":"intenter","pos":"VER"} ,
		{"word":"intenté","word_nosc":"intente","lemma":"intenter","pos":"VER"} ,
		{"word":"intentée","word_nosc":"intentee","lemma":"intenter","pos":"VER"} ,
		{"word":"intentées","word_nosc":"intentees","lemma":"intenter","pos":"VER"} ,
		{"word":"intentés","word_nosc":"intentes","lemma":"intenter","pos":"VER"} ,
		{"word":"interagir","word_nosc":"interagir","lemma":"interagir","pos":"VER"} ,
		{"word":"interagis","word_nosc":"interagis","lemma":"interagir","pos":"VER"} ,
		{"word":"interagissais","word_nosc":"interagissais","lemma":"interagir","pos":"VER"} ,
		{"word":"interagissant","word_nosc":"interagissant","lemma":"interagir","pos":"VER"} ,
		{"word":"interagit","word_nosc":"interagit","lemma":"interagir","pos":"VER"} ,
		{"word":"intercalaient","word_nosc":"intercalaient","lemma":"intercaler","pos":"VER"} ,
		{"word":"intercalait","word_nosc":"intercalait","lemma":"intercaler","pos":"VER"} ,
		{"word":"intercalant","word_nosc":"intercalant","lemma":"intercaler","pos":"VER"} ,
		{"word":"intercale","word_nosc":"intercale","lemma":"intercaler","pos":"VER"} ,
		{"word":"intercalent","word_nosc":"intercalent","lemma":"intercaler","pos":"VER"} ,
		{"word":"intercaler","word_nosc":"intercaler","lemma":"intercaler","pos":"VER"} ,
		{"word":"intercalera","word_nosc":"intercalera","lemma":"intercaler","pos":"VER"} ,
		{"word":"intercalerai","word_nosc":"intercalerai","lemma":"intercaler","pos":"VER"} ,
		{"word":"intercalerez","word_nosc":"intercalerez","lemma":"intercaler","pos":"VER"} ,
		{"word":"intercalé","word_nosc":"intercale","lemma":"intercaler","pos":"VER"} ,
		{"word":"intercalées","word_nosc":"intercalees","lemma":"intercaler","pos":"VER"} ,
		{"word":"intercalés","word_nosc":"intercales","lemma":"intercaler","pos":"VER"} ,
		{"word":"intercepta","word_nosc":"intercepta","lemma":"intercepter","pos":"VER"} ,
		{"word":"interceptai","word_nosc":"interceptai","lemma":"intercepter","pos":"VER"} ,
		{"word":"interceptais","word_nosc":"interceptais","lemma":"intercepter","pos":"VER"} ,
		{"word":"interceptait","word_nosc":"interceptait","lemma":"intercepter","pos":"VER"} ,
		{"word":"interceptant","word_nosc":"interceptant","lemma":"intercepter","pos":"VER"} ,
		{"word":"intercepte","word_nosc":"intercepte","lemma":"intercepter","pos":"VER"} ,
		{"word":"interceptent","word_nosc":"interceptent","lemma":"intercepter","pos":"VER"} ,
		{"word":"intercepter","word_nosc":"intercepter","lemma":"intercepter","pos":"VER"} ,
		{"word":"interceptera","word_nosc":"interceptera","lemma":"intercepter","pos":"VER"} ,
		{"word":"intercepterait","word_nosc":"intercepterait","lemma":"intercepter","pos":"VER"} ,
		{"word":"intercepterez","word_nosc":"intercepterez","lemma":"intercepter","pos":"VER"} ,
		{"word":"intercepterons","word_nosc":"intercepterons","lemma":"intercepter","pos":"VER"} ,
		{"word":"interceptez","word_nosc":"interceptez","lemma":"intercepter","pos":"VER"} ,
		{"word":"interceptions","word_nosc":"interceptions","lemma":"intercepter","pos":"VER"} ,
		{"word":"interceptèrent","word_nosc":"intercepterent","lemma":"intercepter","pos":"VER"} ,
		{"word":"intercepté","word_nosc":"intercepte","lemma":"intercepter","pos":"VER"} ,
		{"word":"interceptée","word_nosc":"interceptee","lemma":"intercepter","pos":"VER"} ,
		{"word":"interceptées","word_nosc":"interceptees","lemma":"intercepter","pos":"VER"} ,
		{"word":"interceptés","word_nosc":"interceptes","lemma":"intercepter","pos":"VER"} ,
		{"word":"interchanger","word_nosc":"interchanger","lemma":"interchanger","pos":"VER"} ,
		{"word":"interchangé","word_nosc":"interchange","lemma":"interchanger","pos":"VER"} ,
		{"word":"interconnecté","word_nosc":"interconnecte","lemma":"interconnecter","pos":"VER"} ,
		{"word":"interconnectée","word_nosc":"interconnectee","lemma":"interconnecter","pos":"VER"} ,
		{"word":"intercède","word_nosc":"intercede","lemma":"intercéder","pos":"VER"} ,
		{"word":"intercéda","word_nosc":"interceda","lemma":"intercéder","pos":"VER"} ,
		{"word":"intercédait","word_nosc":"intercedait","lemma":"intercéder","pos":"VER"} ,
		{"word":"intercédant","word_nosc":"intercedant","lemma":"intercéder","pos":"VER"} ,
		{"word":"intercéder","word_nosc":"interceder","lemma":"intercéder","pos":"VER"} ,
		{"word":"intercédera","word_nosc":"intercedera","lemma":"intercéder","pos":"VER"} ,
		{"word":"intercéderai","word_nosc":"intercederai","lemma":"intercéder","pos":"VER"} ,
		{"word":"intercédé","word_nosc":"intercede","lemma":"intercéder","pos":"VER"} ,
		{"word":"interdira","word_nosc":"interdira","lemma":"interdire","pos":"VER"} ,
		{"word":"interdirai","word_nosc":"interdirai","lemma":"interdire","pos":"VER"} ,
		{"word":"interdiraient","word_nosc":"interdiraient","lemma":"interdire","pos":"VER"} ,
		{"word":"interdirais","word_nosc":"interdirais","lemma":"interdire","pos":"VER"} ,
		{"word":"interdirait","word_nosc":"interdirait","lemma":"interdire","pos":"VER"} ,
		{"word":"interdire","word_nosc":"interdire","lemma":"interdire","pos":"VER"} ,
		{"word":"interdirent","word_nosc":"interdirent","lemma":"interdire","pos":"VER"} ,
		{"word":"interdis","word_nosc":"interdis","lemma":"interdire","pos":"VER"} ,
		{"word":"interdisaient","word_nosc":"interdisaient","lemma":"interdire","pos":"VER"} ,
		{"word":"interdisais","word_nosc":"interdisais","lemma":"interdire","pos":"VER"} ,
		{"word":"interdisait","word_nosc":"interdisait","lemma":"interdire","pos":"VER"} ,
		{"word":"interdisant","word_nosc":"interdisant","lemma":"interdire","pos":"VER"} ,
		{"word":"interdise","word_nosc":"interdise","lemma":"interdire","pos":"VER"} ,
		{"word":"interdisent","word_nosc":"interdisent","lemma":"interdire","pos":"VER"} ,
		{"word":"interdisez","word_nosc":"interdisez","lemma":"interdire","pos":"VER"} ,
		{"word":"interdisiez","word_nosc":"interdisiez","lemma":"interdire","pos":"VER"} ,
		{"word":"interdisons","word_nosc":"interdisons","lemma":"interdire","pos":"VER"} ,
		{"word":"interdissent","word_nosc":"interdissent","lemma":"interdire","pos":"VER"} ,
		{"word":"interdit","word_nosc":"interdit","lemma":"interdire","pos":"VER"} ,
		{"word":"interdite","word_nosc":"interdite","lemma":"interdire","pos":"VER"} ,
		{"word":"interdites","word_nosc":"interdites","lemma":"interdire","pos":"VER"} ,
		{"word":"interdits","word_nosc":"interdits","lemma":"interdire","pos":"VER"} ,
		{"word":"interdît","word_nosc":"interdit","lemma":"interdire","pos":"VER"} ,
		{"word":"interfacer","word_nosc":"interfacer","lemma":"interfacer","pos":"VER"} ,
		{"word":"interfère","word_nosc":"interfere","lemma":"interférer","pos":"VER"} ,
		{"word":"interfèrent","word_nosc":"interferent","lemma":"interférer","pos":"VER"} ,
		{"word":"interféraient","word_nosc":"interferaient","lemma":"interférer","pos":"VER"} ,
		{"word":"interférait","word_nosc":"interferait","lemma":"interférer","pos":"VER"} ,
		{"word":"interférant","word_nosc":"interferant","lemma":"interférer","pos":"VER"} ,
		{"word":"interférer","word_nosc":"interferer","lemma":"interférer","pos":"VER"} ,
		{"word":"interférez","word_nosc":"interferez","lemma":"interférer","pos":"VER"} ,
		{"word":"interférons","word_nosc":"interferons","lemma":"interférer","pos":"VER"} ,
		{"word":"interféré","word_nosc":"interfere","lemma":"interférer","pos":"VER"} ,
		{"word":"interjecter","word_nosc":"interjecter","lemma":"interjecter","pos":"VER"} ,
		{"word":"interjeta","word_nosc":"interjeta","lemma":"interjeter","pos":"VER"} ,
		{"word":"interjeter","word_nosc":"interjeter","lemma":"interjeter","pos":"VER"} ,
		{"word":"interlignés","word_nosc":"interlignes","lemma":"interligner","pos":"VER"} ,
		{"word":"interloque","word_nosc":"interloque","lemma":"interloquer","pos":"VER"} ,
		{"word":"interloqué","word_nosc":"interloque","lemma":"interloquer","pos":"VER"} ,
		{"word":"interloquée","word_nosc":"interloquee","lemma":"interloquer","pos":"VER"} ,
		{"word":"interloqués","word_nosc":"interloques","lemma":"interloquer","pos":"VER"} ,
		{"word":"internationalisée","word_nosc":"internationalisee","lemma":"internationaliser","pos":"VER"} ,
		{"word":"internationalisés","word_nosc":"internationalises","lemma":"internationaliser","pos":"VER"} ,
		{"word":"interne","word_nosc":"interne","lemma":"interner","pos":"VER"} ,
		{"word":"interner","word_nosc":"interner","lemma":"interner","pos":"VER"} ,
		{"word":"internez","word_nosc":"internez","lemma":"interner","pos":"VER"} ,
		{"word":"interné","word_nosc":"interne","lemma":"interner","pos":"VER"} ,
		{"word":"internée","word_nosc":"internee","lemma":"interner","pos":"VER"} ,
		{"word":"internées","word_nosc":"internees","lemma":"interner","pos":"VER"} ,
		{"word":"internés","word_nosc":"internes","lemma":"interner","pos":"VER"} ,
		{"word":"interpella","word_nosc":"interpella","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpellai","word_nosc":"interpellai","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpellaient","word_nosc":"interpellaient","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpellais","word_nosc":"interpellais","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpellait","word_nosc":"interpellait","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpellant","word_nosc":"interpellant","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpelle","word_nosc":"interpelle","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpellent","word_nosc":"interpellent","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpeller","word_nosc":"interpeller","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpellerait","word_nosc":"interpellerait","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpellez","word_nosc":"interpellez","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpellèrent","word_nosc":"interpellerent","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpellé","word_nosc":"interpelle","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpellée","word_nosc":"interpellee","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpellées","word_nosc":"interpellees","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpellés","word_nosc":"interpelles","lemma":"interpeller","pos":"VER"} ,
		{"word":"interpole","word_nosc":"interpole","lemma":"interpoler","pos":"VER"} ,
		{"word":"interpolez","word_nosc":"interpolez","lemma":"interpoler","pos":"VER"} ,
		{"word":"interposa","word_nosc":"interposa","lemma":"interposer","pos":"VER"} ,
		{"word":"interposai","word_nosc":"interposai","lemma":"interposer","pos":"VER"} ,
		{"word":"interposaient","word_nosc":"interposaient","lemma":"interposer","pos":"VER"} ,
		{"word":"interposais","word_nosc":"interposais","lemma":"interposer","pos":"VER"} ,
		{"word":"interposait","word_nosc":"interposait","lemma":"interposer","pos":"VER"} ,
		{"word":"interposant","word_nosc":"interposant","lemma":"interposer","pos":"VER"} ,
		{"word":"interpose","word_nosc":"interpose","lemma":"interposer","pos":"VER"} ,
		{"word":"interposent","word_nosc":"interposent","lemma":"interposer","pos":"VER"} ,
		{"word":"interposer","word_nosc":"interposer","lemma":"interposer","pos":"VER"} ,
		{"word":"interposera","word_nosc":"interposera","lemma":"interposer","pos":"VER"} ,
		{"word":"interposeront","word_nosc":"interposeront","lemma":"interposer","pos":"VER"} ,
		{"word":"interposez","word_nosc":"interposez","lemma":"interposer","pos":"VER"} ,
		{"word":"interposèrent","word_nosc":"interposerent","lemma":"interposer","pos":"VER"} ,
		{"word":"interposé","word_nosc":"interpose","lemma":"interposer","pos":"VER"} ,
		{"word":"interposée","word_nosc":"interposee","lemma":"interposer","pos":"VER"} ,
		{"word":"interposées","word_nosc":"interposees","lemma":"interposer","pos":"VER"} ,
		{"word":"interposés","word_nosc":"interposes","lemma":"interposer","pos":"VER"} ,
		{"word":"interprète","word_nosc":"interprete","lemma":"interpréter","pos":"VER"} ,
		{"word":"interprètent","word_nosc":"interpretent","lemma":"interpréter","pos":"VER"} ,
		{"word":"interprètes","word_nosc":"interpretes","lemma":"interpréter","pos":"VER"} ,
		{"word":"interpréta","word_nosc":"interpreta","lemma":"interpréter","pos":"VER"} ,
		{"word":"interprétai","word_nosc":"interpretai","lemma":"interpréter","pos":"VER"} ,
		{"word":"interprétaient","word_nosc":"interpretaient","lemma":"interpréter","pos":"VER"} ,
		{"word":"interprétais","word_nosc":"interpretais","lemma":"interpréter","pos":"VER"} ,
		{"word":"interprétait","word_nosc":"interpretait","lemma":"interpréter","pos":"VER"} ,
		{"word":"interprétant","word_nosc":"interpretant","lemma":"interpréter","pos":"VER"} ,
		{"word":"interpréter","word_nosc":"interpreter","lemma":"interpréter","pos":"VER"} ,
		{"word":"interprétera","word_nosc":"interpretera","lemma":"interpréter","pos":"VER"} ,
		{"word":"interpréteraient","word_nosc":"interpreteraient","lemma":"interpréter","pos":"VER"} ,
		{"word":"interpréterait","word_nosc":"interpreterait","lemma":"interpréter","pos":"VER"} ,
		{"word":"interpréteriez","word_nosc":"interpreteriez","lemma":"interpréter","pos":"VER"} ,
		{"word":"interprétez","word_nosc":"interpretez","lemma":"interpréter","pos":"VER"} ,
		{"word":"interprétât","word_nosc":"interpretat","lemma":"interpréter","pos":"VER"} ,
		{"word":"interprétèrent","word_nosc":"interpreterent","lemma":"interpréter","pos":"VER"} ,
		{"word":"interprété","word_nosc":"interprete","lemma":"interpréter","pos":"VER"} ,
		{"word":"interprétée","word_nosc":"interpretee","lemma":"interpréter","pos":"VER"} ,
		{"word":"interprétées","word_nosc":"interpretees","lemma":"interpréter","pos":"VER"} ,
		{"word":"interprétés","word_nosc":"interpretes","lemma":"interpréter","pos":"VER"} ,
		{"word":"interpénètre","word_nosc":"interpenetre","lemma":"interpénétrer","pos":"VER"} ,
		{"word":"interpénètrent","word_nosc":"interpenetrent","lemma":"interpénétrer","pos":"VER"} ,
		{"word":"interpénétrer","word_nosc":"interpenetrer","lemma":"interpénétrer","pos":"VER"} ,
		{"word":"interroge","word_nosc":"interroge","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogea","word_nosc":"interrogea","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogeai","word_nosc":"interrogeai","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogeaient","word_nosc":"interrogeaient","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogeais","word_nosc":"interrogeais","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogeait","word_nosc":"interrogeait","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogeant","word_nosc":"interrogeant","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogent","word_nosc":"interrogent","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogeons","word_nosc":"interrogeons","lemma":"interroger","pos":"VER"} ,
		{"word":"interroger","word_nosc":"interroger","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogera","word_nosc":"interrogera","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogerai","word_nosc":"interrogerai","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogeraient","word_nosc":"interrogeraient","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogerais","word_nosc":"interrogerais","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogerait","word_nosc":"interrogerait","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogeras","word_nosc":"interrogeras","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogerez","word_nosc":"interrogerez","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogerons","word_nosc":"interrogerons","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogeront","word_nosc":"interrogeront","lemma":"interroger","pos":"VER"} ,
		{"word":"interroges","word_nosc":"interroges","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogez","word_nosc":"interrogez","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogeâmes","word_nosc":"interrogeames","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogeât","word_nosc":"interrogeat","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogiez","word_nosc":"interrogiez","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogions","word_nosc":"interrogions","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogèrent","word_nosc":"interrogerent","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogé","word_nosc":"interroge","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogée","word_nosc":"interrogee","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogées","word_nosc":"interrogees","lemma":"interroger","pos":"VER"} ,
		{"word":"interrogés","word_nosc":"interroges","lemma":"interroger","pos":"VER"} ,
		{"word":"interrompaient","word_nosc":"interrompaient","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompais","word_nosc":"interrompais","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompait","word_nosc":"interrompait","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompant","word_nosc":"interrompant","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompe","word_nosc":"interrompe","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompent","word_nosc":"interrompent","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompes","word_nosc":"interrompes","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompez","word_nosc":"interrompez","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompiez","word_nosc":"interrompiez","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompirent","word_nosc":"interrompirent","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompis","word_nosc":"interrompis","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompisse","word_nosc":"interrompisse","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompit","word_nosc":"interrompit","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompons","word_nosc":"interrompons","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompra","word_nosc":"interrompra","lemma":"interrompre","pos":"VER"} ,
		{"word":"interromprai","word_nosc":"interromprai","lemma":"interrompre","pos":"VER"} ,
		{"word":"interromprais","word_nosc":"interromprais","lemma":"interrompre","pos":"VER"} ,
		{"word":"interromprait","word_nosc":"interromprait","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompras","word_nosc":"interrompras","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompre","word_nosc":"interrompre","lemma":"interrompre","pos":"VER"} ,
		{"word":"interromps","word_nosc":"interromps","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompt","word_nosc":"interrompt","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompu","word_nosc":"interrompu","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompue","word_nosc":"interrompue","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompues","word_nosc":"interrompues","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompus","word_nosc":"interrompus","lemma":"interrompre","pos":"VER"} ,
		{"word":"interrompît","word_nosc":"interrompit","lemma":"interrompre","pos":"VER"} ,
		{"word":"intersectant","word_nosc":"intersectant","lemma":"intersecter","pos":"VER"} ,
		{"word":"intervenaient","word_nosc":"intervenaient","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervenais","word_nosc":"intervenais","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervenait","word_nosc":"intervenait","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervenant","word_nosc":"intervenant","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervenez","word_nosc":"intervenez","lemma":"intervenir","pos":"VER"} ,
		{"word":"interveniez","word_nosc":"interveniez","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervenir","word_nosc":"intervenir","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervenons","word_nosc":"intervenons","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervenu","word_nosc":"intervenu","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervenue","word_nosc":"intervenue","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervenues","word_nosc":"intervenues","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervenus","word_nosc":"intervenus","lemma":"intervenir","pos":"VER"} ,
		{"word":"interverti","word_nosc":"interverti","lemma":"intervertir","pos":"VER"} ,
		{"word":"intervertir","word_nosc":"intervertir","lemma":"intervertir","pos":"VER"} ,
		{"word":"intervertirai","word_nosc":"intervertirai","lemma":"intervertir","pos":"VER"} ,
		{"word":"intervertis","word_nosc":"intervertis","lemma":"intervertir","pos":"VER"} ,
		{"word":"intervertissait","word_nosc":"intervertissait","lemma":"intervertir","pos":"VER"} ,
		{"word":"intervertissent","word_nosc":"intervertissent","lemma":"intervertir","pos":"VER"} ,
		{"word":"intervertissiez","word_nosc":"intervertissiez","lemma":"intervertir","pos":"VER"} ,
		{"word":"intervertissons","word_nosc":"intervertissons","lemma":"intervertir","pos":"VER"} ,
		{"word":"intervertit","word_nosc":"intervertit","lemma":"intervertir","pos":"VER"} ,
		{"word":"interviendra","word_nosc":"interviendra","lemma":"intervenir","pos":"VER"} ,
		{"word":"interviendrai","word_nosc":"interviendrai","lemma":"intervenir","pos":"VER"} ,
		{"word":"interviendraient","word_nosc":"interviendraient","lemma":"intervenir","pos":"VER"} ,
		{"word":"interviendrais","word_nosc":"interviendrais","lemma":"intervenir","pos":"VER"} ,
		{"word":"interviendrait","word_nosc":"interviendrait","lemma":"intervenir","pos":"VER"} ,
		{"word":"interviendrez","word_nosc":"interviendrez","lemma":"intervenir","pos":"VER"} ,
		{"word":"interviendrons","word_nosc":"interviendrons","lemma":"intervenir","pos":"VER"} ,
		{"word":"interviendront","word_nosc":"interviendront","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervienne","word_nosc":"intervienne","lemma":"intervenir","pos":"VER"} ,
		{"word":"interviennent","word_nosc":"interviennent","lemma":"intervenir","pos":"VER"} ,
		{"word":"interviens","word_nosc":"interviens","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervient","word_nosc":"intervient","lemma":"intervenir","pos":"VER"} ,
		{"word":"interviewaient","word_nosc":"interviewaient","lemma":"interviewer","pos":"VER"} ,
		{"word":"interviewais","word_nosc":"interviewais","lemma":"interviewer","pos":"VER"} ,
		{"word":"interviewait","word_nosc":"interviewait","lemma":"interviewer","pos":"VER"} ,
		{"word":"interviewe","word_nosc":"interviewe","lemma":"interviewer","pos":"VER"} ,
		{"word":"interviewer","word_nosc":"interviewer","lemma":"interviewer","pos":"VER"} ,
		{"word":"interviewèrent","word_nosc":"interviewerent","lemma":"interviewer","pos":"VER"} ,
		{"word":"interviewé","word_nosc":"interviewe","lemma":"interviewer","pos":"VER"} ,
		{"word":"interviewée","word_nosc":"interviewee","lemma":"interviewer","pos":"VER"} ,
		{"word":"interviewés","word_nosc":"interviewes","lemma":"interviewer","pos":"VER"} ,
		{"word":"intervinrent","word_nosc":"intervinrent","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervins","word_nosc":"intervins","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervinsse","word_nosc":"intervinsse","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervint","word_nosc":"intervint","lemma":"intervenir","pos":"VER"} ,
		{"word":"intervînt","word_nosc":"intervint","lemma":"intervenir","pos":"VER"} ,
		{"word":"intima","word_nosc":"intima","lemma":"intimer","pos":"VER"} ,
		{"word":"intimait","word_nosc":"intimait","lemma":"intimer","pos":"VER"} ,
		{"word":"intimant","word_nosc":"intimant","lemma":"intimer","pos":"VER"} ,
		{"word":"intime","word_nosc":"intime","lemma":"intimer","pos":"VER"} ,
		{"word":"intimer","word_nosc":"intimer","lemma":"intimer","pos":"VER"} ,
		{"word":"intimerait","word_nosc":"intimerait","lemma":"intimer","pos":"VER"} ,
		{"word":"intimida","word_nosc":"intimida","lemma":"intimider","pos":"VER"} ,
		{"word":"intimidaient","word_nosc":"intimidaient","lemma":"intimider","pos":"VER"} ,
		{"word":"intimidais","word_nosc":"intimidais","lemma":"intimider","pos":"VER"} ,
		{"word":"intimidait","word_nosc":"intimidait","lemma":"intimider","pos":"VER"} ,
		{"word":"intimidant","word_nosc":"intimidant","lemma":"intimider","pos":"VER"} ,
		{"word":"intimide","word_nosc":"intimide","lemma":"intimider","pos":"VER"} ,
		{"word":"intimident","word_nosc":"intimident","lemma":"intimider","pos":"VER"} ,
		{"word":"intimider","word_nosc":"intimider","lemma":"intimider","pos":"VER"} ,
		{"word":"intimiderait","word_nosc":"intimiderait","lemma":"intimider","pos":"VER"} ,
		{"word":"intimides","word_nosc":"intimides","lemma":"intimider","pos":"VER"} ,
		{"word":"intimidez","word_nosc":"intimidez","lemma":"intimider","pos":"VER"} ,
		{"word":"intimidiez","word_nosc":"intimidiez","lemma":"intimider","pos":"VER"} ,
		{"word":"intimidât","word_nosc":"intimidat","lemma":"intimider","pos":"VER"} ,
		{"word":"intimidé","word_nosc":"intimide","lemma":"intimider","pos":"VER"} ,
		{"word":"intimidée","word_nosc":"intimidee","lemma":"intimider","pos":"VER"} ,
		{"word":"intimidées","word_nosc":"intimidees","lemma":"intimider","pos":"VER"} ,
		{"word":"intimidés","word_nosc":"intimides","lemma":"intimider","pos":"VER"} ,
		{"word":"intimât","word_nosc":"intimat","lemma":"intimer","pos":"VER"} ,
		{"word":"intimèrent","word_nosc":"intimerent","lemma":"intimer","pos":"VER"} ,
		{"word":"intimé","word_nosc":"intime","lemma":"intimer","pos":"VER"} ,
		{"word":"intimés","word_nosc":"intimes","lemma":"intimer","pos":"VER"} ,
		{"word":"intitula","word_nosc":"intitula","lemma":"intituler","pos":"VER"} ,
		{"word":"intitulai","word_nosc":"intitulai","lemma":"intituler","pos":"VER"} ,
		{"word":"intitulaient","word_nosc":"intitulaient","lemma":"intituler","pos":"VER"} ,
		{"word":"intitulais","word_nosc":"intitulais","lemma":"intituler","pos":"VER"} ,
		{"word":"intitulait","word_nosc":"intitulait","lemma":"intituler","pos":"VER"} ,
		{"word":"intitulant","word_nosc":"intitulant","lemma":"intituler","pos":"VER"} ,
		{"word":"intitule","word_nosc":"intitule","lemma":"intituler","pos":"VER"} ,
		{"word":"intituler","word_nosc":"intituler","lemma":"intituler","pos":"VER"} ,
		{"word":"intitulerait","word_nosc":"intitulerait","lemma":"intituler","pos":"VER"} ,
		{"word":"intitulé","word_nosc":"intitule","lemma":"intituler","pos":"VER"} ,
		{"word":"intitulée","word_nosc":"intitulee","lemma":"intituler","pos":"VER"} ,
		{"word":"intitulées","word_nosc":"intitulees","lemma":"intituler","pos":"VER"} ,
		{"word":"intitulés","word_nosc":"intitules","lemma":"intituler","pos":"VER"} ,
		{"word":"intoxiquant","word_nosc":"intoxiquant","lemma":"intoxiquer","pos":"VER"} ,
		{"word":"intoxique","word_nosc":"intoxique","lemma":"intoxiquer","pos":"VER"} ,
		{"word":"intoxiquer","word_nosc":"intoxiquer","lemma":"intoxiquer","pos":"VER"} ,
		{"word":"intoxiqué","word_nosc":"intoxique","lemma":"intoxiquer","pos":"VER"} ,
		{"word":"intoxiquée","word_nosc":"intoxiquee","lemma":"intoxiquer","pos":"VER"} ,
		{"word":"intoxiqués","word_nosc":"intoxiques","lemma":"intoxiquer","pos":"VER"} ,
		{"word":"intrigua","word_nosc":"intrigua","lemma":"intriguer","pos":"VER"} ,
		{"word":"intriguaient","word_nosc":"intriguaient","lemma":"intriguer","pos":"VER"} ,
		{"word":"intriguait","word_nosc":"intriguait","lemma":"intriguer","pos":"VER"} ,
		{"word":"intriguant","word_nosc":"intriguant","lemma":"intriguer","pos":"VER"} ,
		{"word":"intriguassent","word_nosc":"intriguassent","lemma":"intriguer","pos":"VER"} ,
		{"word":"intrigue","word_nosc":"intrigue","lemma":"intriguer","pos":"VER"} ,
		{"word":"intriguent","word_nosc":"intriguent","lemma":"intriguer","pos":"VER"} ,
		{"word":"intriguer","word_nosc":"intriguer","lemma":"intriguer","pos":"VER"} ,
		{"word":"intriguera","word_nosc":"intriguera","lemma":"intriguer","pos":"VER"} ,
		{"word":"intriguerait","word_nosc":"intriguerait","lemma":"intriguer","pos":"VER"} ,
		{"word":"intrigues","word_nosc":"intrigues","lemma":"intriguer","pos":"VER"} ,
		{"word":"intriguez","word_nosc":"intriguez","lemma":"intriguer","pos":"VER"} ,
		{"word":"intriguât","word_nosc":"intriguat","lemma":"intriguer","pos":"VER"} ,
		{"word":"intriguèrent","word_nosc":"intriguerent","lemma":"intriguer","pos":"VER"} ,
		{"word":"intrigué","word_nosc":"intrigue","lemma":"intriguer","pos":"VER"} ,
		{"word":"intriguée","word_nosc":"intriguee","lemma":"intriguer","pos":"VER"} ,
		{"word":"intrigués","word_nosc":"intrigues","lemma":"intriguer","pos":"VER"} ,
		{"word":"introduira","word_nosc":"introduira","lemma":"introduire","pos":"VER"} ,
		{"word":"introduirai","word_nosc":"introduirai","lemma":"introduire","pos":"VER"} ,
		{"word":"introduiraient","word_nosc":"introduiraient","lemma":"introduire","pos":"VER"} ,
		{"word":"introduirais","word_nosc":"introduirais","lemma":"introduire","pos":"VER"} ,
		{"word":"introduirait","word_nosc":"introduirait","lemma":"introduire","pos":"VER"} ,
		{"word":"introduire","word_nosc":"introduire","lemma":"introduire","pos":"VER"} ,
		{"word":"introduirez","word_nosc":"introduirez","lemma":"introduire","pos":"VER"} ,
		{"word":"introduirions","word_nosc":"introduirions","lemma":"introduire","pos":"VER"} ,
		{"word":"introduis","word_nosc":"introduis","lemma":"introduire","pos":"VER"} ,
		{"word":"introduisaient","word_nosc":"introduisaient","lemma":"introduire","pos":"VER"} ,
		{"word":"introduisais","word_nosc":"introduisais","lemma":"introduire","pos":"VER"} ,
		{"word":"introduisait","word_nosc":"introduisait","lemma":"introduire","pos":"VER"} ,
		{"word":"introduisant","word_nosc":"introduisant","lemma":"introduire","pos":"VER"} ,
		{"word":"introduise","word_nosc":"introduise","lemma":"introduire","pos":"VER"} ,
		{"word":"introduisent","word_nosc":"introduisent","lemma":"introduire","pos":"VER"} ,
		{"word":"introduisez","word_nosc":"introduisez","lemma":"introduire","pos":"VER"} ,
		{"word":"introduisirent","word_nosc":"introduisirent","lemma":"introduire","pos":"VER"} ,
		{"word":"introduisis","word_nosc":"introduisis","lemma":"introduire","pos":"VER"} ,
		{"word":"introduisit","word_nosc":"introduisit","lemma":"introduire","pos":"VER"} ,
		{"word":"introduisons","word_nosc":"introduisons","lemma":"introduire","pos":"VER"} ,
		{"word":"introduisît","word_nosc":"introduisit","lemma":"introduire","pos":"VER"} ,
		{"word":"introduit","word_nosc":"introduit","lemma":"introduire","pos":"VER"} ,
		{"word":"introduite","word_nosc":"introduite","lemma":"introduire","pos":"VER"} ,
		{"word":"introduites","word_nosc":"introduites","lemma":"introduire","pos":"VER"} ,
		{"word":"introduits","word_nosc":"introduits","lemma":"introduire","pos":"VER"} ,
		{"word":"intronisa","word_nosc":"intronisa","lemma":"introniser","pos":"VER"} ,
		{"word":"intronisait","word_nosc":"intronisait","lemma":"introniser","pos":"VER"} ,
		{"word":"intronise","word_nosc":"intronise","lemma":"introniser","pos":"VER"} ,
		{"word":"introniser","word_nosc":"introniser","lemma":"introniser","pos":"VER"} ,
		{"word":"intronisé","word_nosc":"intronise","lemma":"introniser","pos":"VER"} ,
		{"word":"introspecter","word_nosc":"introspecter","lemma":"introspecter","pos":"VER"} ,
		{"word":"intube","word_nosc":"intube","lemma":"intuber","pos":"VER"} ,
		{"word":"intuber","word_nosc":"intuber","lemma":"intuber","pos":"VER"} ,
		{"word":"intubez","word_nosc":"intubez","lemma":"intuber","pos":"VER"} ,
		{"word":"intubé","word_nosc":"intube","lemma":"intuber","pos":"VER"} ,
		{"word":"intubée","word_nosc":"intubee","lemma":"intuber","pos":"VER"} ,
		{"word":"intègre","word_nosc":"integre","lemma":"intégrer","pos":"VER"} ,
		{"word":"intègrent","word_nosc":"integrent","lemma":"intégrer","pos":"VER"} ,
		{"word":"intègres","word_nosc":"integres","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégra","word_nosc":"integra","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégraient","word_nosc":"integraient","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégrait","word_nosc":"integrait","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégrant","word_nosc":"integrant","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégrer","word_nosc":"integrer","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégrera","word_nosc":"integrera","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégrerais","word_nosc":"integrerais","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégreras","word_nosc":"integreras","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégrerez","word_nosc":"integrerez","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégreront","word_nosc":"integreront","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégrez","word_nosc":"integrez","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégriez","word_nosc":"integriez","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégrèrent","word_nosc":"integrerent","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégré","word_nosc":"integre","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégrée","word_nosc":"integree","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégrées","word_nosc":"integrees","lemma":"intégrer","pos":"VER"} ,
		{"word":"intégrés","word_nosc":"integres","lemma":"intégrer","pos":"VER"} ,
		{"word":"intéressa","word_nosc":"interessa","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressai","word_nosc":"interessai","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressaient","word_nosc":"interessaient","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressais","word_nosc":"interessais","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressait","word_nosc":"interessait","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressant","word_nosc":"interessant","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéresse","word_nosc":"interesse","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressent","word_nosc":"interessent","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéresser","word_nosc":"interesser","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressera","word_nosc":"interessera","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéresserai","word_nosc":"interesserai","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéresseraient","word_nosc":"interesseraient","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéresserais","word_nosc":"interesserais","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéresserait","word_nosc":"interesserait","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéresseras","word_nosc":"interesseras","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéresseriez","word_nosc":"interesseriez","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéresserons","word_nosc":"interesserons","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéresseront","word_nosc":"interesseront","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéresses","word_nosc":"interesses","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressez","word_nosc":"interessez","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressiez","word_nosc":"interessiez","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressions","word_nosc":"interessions","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressons","word_nosc":"interessons","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressât","word_nosc":"interessat","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressèrent","word_nosc":"interesserent","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressé","word_nosc":"interesse","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressée","word_nosc":"interessee","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressées","word_nosc":"interessees","lemma":"intéresser","pos":"VER"} ,
		{"word":"intéressés","word_nosc":"interesses","lemma":"intéresser","pos":"VER"} ,
		{"word":"intériorisa","word_nosc":"interiorisa","lemma":"intérioriser","pos":"VER"} ,
		{"word":"intériorisait","word_nosc":"interiorisait","lemma":"intérioriser","pos":"VER"} ,
		{"word":"intériorise","word_nosc":"interiorise","lemma":"intérioriser","pos":"VER"} ,
		{"word":"intériorisent","word_nosc":"interiorisent","lemma":"intérioriser","pos":"VER"} ,
		{"word":"intérioriser","word_nosc":"interioriser","lemma":"intérioriser","pos":"VER"} ,
		{"word":"intériorisée","word_nosc":"interiorisee","lemma":"intérioriser","pos":"VER"} ,
		{"word":"intériorisées","word_nosc":"interiorisees","lemma":"intérioriser","pos":"VER"} ,
		{"word":"intériorisés","word_nosc":"interiorises","lemma":"intérioriser","pos":"VER"} ,
		{"word":"invalide","word_nosc":"invalide","lemma":"invalider","pos":"VER"} ,
		{"word":"invalider","word_nosc":"invalider","lemma":"invalider","pos":"VER"} ,
		{"word":"invalidé","word_nosc":"invalide","lemma":"invalider","pos":"VER"} ,
		{"word":"invalidés","word_nosc":"invalides","lemma":"invalider","pos":"VER"} ,
		{"word":"invectiva","word_nosc":"invectiva","lemma":"invectiver","pos":"VER"} ,
		{"word":"invectivai","word_nosc":"invectivai","lemma":"invectiver","pos":"VER"} ,
		{"word":"invectivaient","word_nosc":"invectivaient","lemma":"invectiver","pos":"VER"} ,
		{"word":"invectivait","word_nosc":"invectivait","lemma":"invectiver","pos":"VER"} ,
		{"word":"invectivant","word_nosc":"invectivant","lemma":"invectiver","pos":"VER"} ,
		{"word":"invective","word_nosc":"invective","lemma":"invectiver","pos":"VER"} ,
		{"word":"invectivent","word_nosc":"invectivent","lemma":"invectiver","pos":"VER"} ,
		{"word":"invectiver","word_nosc":"invectiver","lemma":"invectiver","pos":"VER"} ,
		{"word":"inventa","word_nosc":"inventa","lemma":"inventer","pos":"VER"} ,
		{"word":"inventai","word_nosc":"inventai","lemma":"inventer","pos":"VER"} ,
		{"word":"inventaient","word_nosc":"inventaient","lemma":"inventer","pos":"VER"} ,
		{"word":"inventais","word_nosc":"inventais","lemma":"inventer","pos":"VER"} ,
		{"word":"inventait","word_nosc":"inventait","lemma":"inventer","pos":"VER"} ,
		{"word":"inventant","word_nosc":"inventant","lemma":"inventer","pos":"VER"} ,
		{"word":"invente","word_nosc":"invente","lemma":"inventer","pos":"VER"} ,
		{"word":"inventent","word_nosc":"inventent","lemma":"inventer","pos":"VER"} ,
		{"word":"inventer","word_nosc":"inventer","lemma":"inventer","pos":"VER"} ,
		{"word":"inventera","word_nosc":"inventera","lemma":"inventer","pos":"VER"} ,
		{"word":"inventerai","word_nosc":"inventerai","lemma":"inventer","pos":"VER"} ,
		{"word":"inventeraient","word_nosc":"inventeraient","lemma":"inventer","pos":"VER"} ,
		{"word":"inventerais","word_nosc":"inventerais","lemma":"inventer","pos":"VER"} ,
		{"word":"inventerait","word_nosc":"inventerait","lemma":"inventer","pos":"VER"} ,
		{"word":"inventeras","word_nosc":"inventeras","lemma":"inventer","pos":"VER"} ,
		{"word":"inventerez","word_nosc":"inventerez","lemma":"inventer","pos":"VER"} ,
		{"word":"inventeriez","word_nosc":"inventeriez","lemma":"inventer","pos":"VER"} ,
		{"word":"inventerions","word_nosc":"inventerions","lemma":"inventer","pos":"VER"} ,
		{"word":"inventerons","word_nosc":"inventerons","lemma":"inventer","pos":"VER"} ,
		{"word":"inventeront","word_nosc":"inventeront","lemma":"inventer","pos":"VER"} ,
		{"word":"inventes","word_nosc":"inventes","lemma":"inventer","pos":"VER"} ,
		{"word":"inventez","word_nosc":"inventez","lemma":"inventer","pos":"VER"} ,
		{"word":"inventiez","word_nosc":"inventiez","lemma":"inventer","pos":"VER"} ,
		{"word":"inventions","word_nosc":"inventions","lemma":"inventer","pos":"VER"} ,
		{"word":"inventons","word_nosc":"inventons","lemma":"inventer","pos":"VER"} ,
		{"word":"inventoria","word_nosc":"inventoria","lemma":"inventorier","pos":"VER"} ,
		{"word":"inventoriai","word_nosc":"inventoriai","lemma":"inventorier","pos":"VER"} ,
		{"word":"inventoriaient","word_nosc":"inventoriaient","lemma":"inventorier","pos":"VER"} ,
		{"word":"inventoriait","word_nosc":"inventoriait","lemma":"inventorier","pos":"VER"} ,
		{"word":"inventorient","word_nosc":"inventorient","lemma":"inventorier","pos":"VER"} ,
		{"word":"inventorier","word_nosc":"inventorier","lemma":"inventorier","pos":"VER"} ,
		{"word":"inventorié","word_nosc":"inventorie","lemma":"inventorier","pos":"VER"} ,
		{"word":"inventoriée","word_nosc":"inventoriee","lemma":"inventorier","pos":"VER"} ,
		{"word":"inventoriées","word_nosc":"inventoriees","lemma":"inventorier","pos":"VER"} ,
		{"word":"inventât","word_nosc":"inventat","lemma":"inventer","pos":"VER"} ,
		{"word":"inventèrent","word_nosc":"inventerent","lemma":"inventer","pos":"VER"} ,
		{"word":"inventé","word_nosc":"invente","lemma":"inventer","pos":"VER"} ,
		{"word":"inventée","word_nosc":"inventee","lemma":"inventer","pos":"VER"} ,
		{"word":"inventées","word_nosc":"inventees","lemma":"inventer","pos":"VER"} ,
		{"word":"inventés","word_nosc":"inventes","lemma":"inventer","pos":"VER"} ,
		{"word":"inversa","word_nosc":"inversa","lemma":"inverser","pos":"VER"} ,
		{"word":"inversaient","word_nosc":"inversaient","lemma":"inverser","pos":"VER"} ,
		{"word":"inversait","word_nosc":"inversait","lemma":"inverser","pos":"VER"} ,
		{"word":"inversant","word_nosc":"inversant","lemma":"inverser","pos":"VER"} ,
		{"word":"inverse","word_nosc":"inverse","lemma":"inverser","pos":"VER"} ,
		{"word":"inversent","word_nosc":"inversent","lemma":"inverser","pos":"VER"} ,
		{"word":"inverser","word_nosc":"inverser","lemma":"inverser","pos":"VER"} ,
		{"word":"inversera","word_nosc":"inversera","lemma":"inverser","pos":"VER"} ,
		{"word":"inverserais","word_nosc":"inverserais","lemma":"inverser","pos":"VER"} ,
		{"word":"inverserait","word_nosc":"inverserait","lemma":"inverser","pos":"VER"} ,
		{"word":"inverses","word_nosc":"inverses","lemma":"inverser","pos":"VER"} ,
		{"word":"inversez","word_nosc":"inversez","lemma":"inverser","pos":"VER"} ,
		{"word":"inversons","word_nosc":"inversons","lemma":"inverser","pos":"VER"} ,
		{"word":"inversèrent","word_nosc":"inverserent","lemma":"inverser","pos":"VER"} ,
		{"word":"inversé","word_nosc":"inverse","lemma":"inverser","pos":"VER"} ,
		{"word":"inversée","word_nosc":"inversee","lemma":"inverser","pos":"VER"} ,
		{"word":"inversées","word_nosc":"inversees","lemma":"inverser","pos":"VER"} ,
		{"word":"inversés","word_nosc":"inverses","lemma":"inverser","pos":"VER"} ,
		{"word":"inverti","word_nosc":"inverti","lemma":"invertir","pos":"VER"} ,
		{"word":"investi","word_nosc":"investi","lemma":"investir","pos":"VER"} ,
		{"word":"investie","word_nosc":"investie","lemma":"investir","pos":"VER"} ,
		{"word":"investies","word_nosc":"investies","lemma":"investir","pos":"VER"} ,
		{"word":"investiguant","word_nosc":"investiguant","lemma":"investiguer","pos":"VER"} ,
		{"word":"investiguer","word_nosc":"investiguer","lemma":"investiguer","pos":"VER"} ,
		{"word":"investir","word_nosc":"investir","lemma":"investir","pos":"VER"} ,
		{"word":"investira","word_nosc":"investira","lemma":"investir","pos":"VER"} ,
		{"word":"investirais","word_nosc":"investirais","lemma":"investir","pos":"VER"} ,
		{"word":"investirait","word_nosc":"investirait","lemma":"investir","pos":"VER"} ,
		{"word":"investirent","word_nosc":"investirent","lemma":"investir","pos":"VER"} ,
		{"word":"investirons","word_nosc":"investirons","lemma":"investir","pos":"VER"} ,
		{"word":"investiront","word_nosc":"investiront","lemma":"investir","pos":"VER"} ,
		{"word":"investis","word_nosc":"investis","lemma":"investir","pos":"VER"} ,
		{"word":"investissaient","word_nosc":"investissaient","lemma":"investir","pos":"VER"} ,
		{"word":"investissais","word_nosc":"investissais","lemma":"investir","pos":"VER"} ,
		{"word":"investissait","word_nosc":"investissait","lemma":"investir","pos":"VER"} ,
		{"word":"investissant","word_nosc":"investissant","lemma":"investir","pos":"VER"} ,
		{"word":"investisse","word_nosc":"investisse","lemma":"investir","pos":"VER"} ,
		{"word":"investissent","word_nosc":"investissent","lemma":"investir","pos":"VER"} ,
		{"word":"investissez","word_nosc":"investissez","lemma":"investir","pos":"VER"} ,
		{"word":"investissons","word_nosc":"investissons","lemma":"investir","pos":"VER"} ,
		{"word":"investit","word_nosc":"investit","lemma":"investir","pos":"VER"} ,
		{"word":"investît","word_nosc":"investit","lemma":"investir","pos":"VER"} ,
		{"word":"invita","word_nosc":"invita","lemma":"inviter","pos":"VER"} ,
		{"word":"invitai","word_nosc":"invitai","lemma":"inviter","pos":"VER"} ,
		{"word":"invitaient","word_nosc":"invitaient","lemma":"inviter","pos":"VER"} ,
		{"word":"invitais","word_nosc":"invitais","lemma":"inviter","pos":"VER"} ,
		{"word":"invitait","word_nosc":"invitait","lemma":"inviter","pos":"VER"} ,
		{"word":"invitant","word_nosc":"invitant","lemma":"inviter","pos":"VER"} ,
		{"word":"invite","word_nosc":"invite","lemma":"inviter","pos":"VER"} ,
		{"word":"invitent","word_nosc":"invitent","lemma":"inviter","pos":"VER"} ,
		{"word":"inviter","word_nosc":"inviter","lemma":"inviter","pos":"VER"} ,
		{"word":"invitera","word_nosc":"invitera","lemma":"inviter","pos":"VER"} ,
		{"word":"inviterai","word_nosc":"inviterai","lemma":"inviter","pos":"VER"} ,
		{"word":"inviteraient","word_nosc":"inviteraient","lemma":"inviter","pos":"VER"} ,
		{"word":"inviterais","word_nosc":"inviterais","lemma":"inviter","pos":"VER"} ,
		{"word":"inviterait","word_nosc":"inviterait","lemma":"inviter","pos":"VER"} ,
		{"word":"inviteras","word_nosc":"inviteras","lemma":"inviter","pos":"VER"} ,
		{"word":"inviterez","word_nosc":"inviterez","lemma":"inviter","pos":"VER"} ,
		{"word":"inviteriez","word_nosc":"inviteriez","lemma":"inviter","pos":"VER"} ,
		{"word":"inviterions","word_nosc":"inviterions","lemma":"inviter","pos":"VER"} ,
		{"word":"inviterons","word_nosc":"inviterons","lemma":"inviter","pos":"VER"} ,
		{"word":"inviteront","word_nosc":"inviteront","lemma":"inviter","pos":"VER"} ,
		{"word":"invites","word_nosc":"invites","lemma":"inviter","pos":"VER"} ,
		{"word":"invitez","word_nosc":"invitez","lemma":"inviter","pos":"VER"} ,
		{"word":"invitiez","word_nosc":"invitiez","lemma":"inviter","pos":"VER"} ,
		{"word":"invitions","word_nosc":"invitions","lemma":"inviter","pos":"VER"} ,
		{"word":"invitons","word_nosc":"invitons","lemma":"inviter","pos":"VER"} ,
		{"word":"invitâmes","word_nosc":"invitames","lemma":"inviter","pos":"VER"} ,
		{"word":"invitât","word_nosc":"invitat","lemma":"inviter","pos":"VER"} ,
		{"word":"invitèrent","word_nosc":"inviterent","lemma":"inviter","pos":"VER"} ,
		{"word":"invité","word_nosc":"invite","lemma":"inviter","pos":"VER"} ,
		{"word":"invitée","word_nosc":"invitee","lemma":"inviter","pos":"VER"} ,
		{"word":"invitées","word_nosc":"invitees","lemma":"inviter","pos":"VER"} ,
		{"word":"invités","word_nosc":"invites","lemma":"inviter","pos":"VER"} ,
		{"word":"invoqua","word_nosc":"invoqua","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquai","word_nosc":"invoquai","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquaient","word_nosc":"invoquaient","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquais","word_nosc":"invoquais","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquait","word_nosc":"invoquait","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquant","word_nosc":"invoquant","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoque","word_nosc":"invoque","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquent","word_nosc":"invoquent","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquer","word_nosc":"invoquer","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquera","word_nosc":"invoquera","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquerai","word_nosc":"invoquerai","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquerais","word_nosc":"invoquerais","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquerait","word_nosc":"invoquerait","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquerez","word_nosc":"invoquerez","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquerons","word_nosc":"invoquerons","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoques","word_nosc":"invoques","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquez","word_nosc":"invoquez","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquons","word_nosc":"invoquons","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquât","word_nosc":"invoquat","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoqué","word_nosc":"invoque","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquée","word_nosc":"invoquee","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoquées","word_nosc":"invoquees","lemma":"invoquer","pos":"VER"} ,
		{"word":"invoqués","word_nosc":"invoques","lemma":"invoquer","pos":"VER"} ,
		{"word":"invétéré","word_nosc":"invetere","lemma":"invétérer","pos":"VER"} ,
		{"word":"invétérée","word_nosc":"inveteree","lemma":"invétérer","pos":"VER"} ,
		{"word":"inégale","word_nosc":"inegale","lemma":"inégaler","pos":"VER"} ,
		{"word":"inégalé","word_nosc":"inegale","lemma":"inégaler","pos":"VER"} ,
		{"word":"inégalée","word_nosc":"inegalee","lemma":"inégaler","pos":"VER"} ,
		{"word":"iodle","word_nosc":"iodle","lemma":"iodler","pos":"VER"} ,
		{"word":"iodler","word_nosc":"iodler","lemma":"iodler","pos":"VER"} ,
		{"word":"iodée","word_nosc":"iodee","lemma":"ioder","pos":"VER"} ,
		{"word":"ioniser","word_nosc":"ioniser","lemma":"ioniser","pos":"VER"} ,
		{"word":"ionisez","word_nosc":"ionisez","lemma":"ioniser","pos":"VER"} ,
		{"word":"ionisé","word_nosc":"ionise","lemma":"ioniser","pos":"VER"} ,
		{"word":"ira","word_nosc":"ira","lemma":"aller","pos":"VER"} ,
		{"word":"irai","word_nosc":"irai","lemma":"aller","pos":"VER"} ,
		{"word":"iraient","word_nosc":"iraient","lemma":"aller","pos":"VER"} ,
		{"word":"irais","word_nosc":"irais","lemma":"aller","pos":"VER"} ,
		{"word":"irait","word_nosc":"irait","lemma":"aller","pos":"VER"} ,
		{"word":"iras","word_nosc":"iras","lemma":"aller","pos":"VER"} ,
		{"word":"irez","word_nosc":"irez","lemma":"aller","pos":"VER"} ,
		{"word":"iriez","word_nosc":"iriez","lemma":"aller","pos":"VER"} ,
		{"word":"irions","word_nosc":"irions","lemma":"aller","pos":"VER"} ,
		{"word":"irisaient","word_nosc":"irisaient","lemma":"iriser","pos":"VER"} ,
		{"word":"irisait","word_nosc":"irisait","lemma":"iriser","pos":"VER"} ,
		{"word":"irisant","word_nosc":"irisant","lemma":"iriser","pos":"VER"} ,
		{"word":"irise","word_nosc":"irise","lemma":"iriser","pos":"VER"} ,
		{"word":"irisent","word_nosc":"irisent","lemma":"iriser","pos":"VER"} ,
		{"word":"iriser","word_nosc":"iriser","lemma":"iriser","pos":"VER"} ,
		{"word":"iriserait","word_nosc":"iriserait","lemma":"iriser","pos":"VER"} ,
		{"word":"irisé","word_nosc":"irise","lemma":"iriser","pos":"VER"} ,
		{"word":"irisée","word_nosc":"irisee","lemma":"iriser","pos":"VER"} ,
		{"word":"irisées","word_nosc":"irisees","lemma":"iriser","pos":"VER"} ,
		{"word":"irisés","word_nosc":"irises","lemma":"iriser","pos":"VER"} ,
		{"word":"ironisa","word_nosc":"ironisa","lemma":"ironiser","pos":"VER"} ,
		{"word":"ironisai","word_nosc":"ironisai","lemma":"ironiser","pos":"VER"} ,
		{"word":"ironisais","word_nosc":"ironisais","lemma":"ironiser","pos":"VER"} ,
		{"word":"ironisait","word_nosc":"ironisait","lemma":"ironiser","pos":"VER"} ,
		{"word":"ironise","word_nosc":"ironise","lemma":"ironiser","pos":"VER"} ,
		{"word":"ironisent","word_nosc":"ironisent","lemma":"ironiser","pos":"VER"} ,
		{"word":"ironiser","word_nosc":"ironiser","lemma":"ironiser","pos":"VER"} ,
		{"word":"ironisé","word_nosc":"ironise","lemma":"ironiser","pos":"VER"} ,
		{"word":"irons","word_nosc":"irons","lemma":"aller","pos":"VER"} ,
		{"word":"iront","word_nosc":"iront","lemma":"aller","pos":"VER"} ,
		{"word":"irradia","word_nosc":"irradia","lemma":"irradier","pos":"VER"} ,
		{"word":"irradiaient","word_nosc":"irradiaient","lemma":"irradier","pos":"VER"} ,
		{"word":"irradiait","word_nosc":"irradiait","lemma":"irradier","pos":"VER"} ,
		{"word":"irradiant","word_nosc":"irradiant","lemma":"irradier","pos":"VER"} ,
		{"word":"irradie","word_nosc":"irradie","lemma":"irradier","pos":"VER"} ,
		{"word":"irradier","word_nosc":"irradier","lemma":"irradier","pos":"VER"} ,
		{"word":"irradiera","word_nosc":"irradiera","lemma":"irradier","pos":"VER"} ,
		{"word":"irradiez","word_nosc":"irradiez","lemma":"irradier","pos":"VER"} ,
		{"word":"irradié","word_nosc":"irradie","lemma":"irradier","pos":"VER"} ,
		{"word":"irradiée","word_nosc":"irradiee","lemma":"irradier","pos":"VER"} ,
		{"word":"irradiées","word_nosc":"irradiees","lemma":"irradier","pos":"VER"} ,
		{"word":"irradiés","word_nosc":"irradies","lemma":"irradier","pos":"VER"} ,
		{"word":"irriguaient","word_nosc":"irriguaient","lemma":"irriguer","pos":"VER"} ,
		{"word":"irriguait","word_nosc":"irriguait","lemma":"irriguer","pos":"VER"} ,
		{"word":"irriguant","word_nosc":"irriguant","lemma":"irriguer","pos":"VER"} ,
		{"word":"irrigue","word_nosc":"irrigue","lemma":"irriguer","pos":"VER"} ,
		{"word":"irriguent","word_nosc":"irriguent","lemma":"irriguer","pos":"VER"} ,
		{"word":"irriguer","word_nosc":"irriguer","lemma":"irriguer","pos":"VER"} ,
		{"word":"irriguera","word_nosc":"irriguera","lemma":"irriguer","pos":"VER"} ,
		{"word":"irrigueraient","word_nosc":"irrigueraient","lemma":"irriguer","pos":"VER"} ,
		{"word":"irriguerait","word_nosc":"irriguerait","lemma":"irriguer","pos":"VER"} ,
		{"word":"irriguons","word_nosc":"irriguons","lemma":"irriguer","pos":"VER"} ,
		{"word":"irrigué","word_nosc":"irrigue","lemma":"irriguer","pos":"VER"} ,
		{"word":"irriguée","word_nosc":"irriguee","lemma":"irriguer","pos":"VER"} ,
		{"word":"irriguées","word_nosc":"irriguees","lemma":"irriguer","pos":"VER"} ,
		{"word":"irrigués","word_nosc":"irrigues","lemma":"irriguer","pos":"VER"} ,
		{"word":"irrita","word_nosc":"irrita","lemma":"irriter","pos":"VER"} ,
		{"word":"irritai","word_nosc":"irritai","lemma":"irriter","pos":"VER"} ,
		{"word":"irritaient","word_nosc":"irritaient","lemma":"irriter","pos":"VER"} ,
		{"word":"irritais","word_nosc":"irritais","lemma":"irriter","pos":"VER"} ,
		{"word":"irritait","word_nosc":"irritait","lemma":"irriter","pos":"VER"} ,
		{"word":"irritant","word_nosc":"irritant","lemma":"irriter","pos":"VER"} ,
		{"word":"irrite","word_nosc":"irrite","lemma":"irriter","pos":"VER"} ,
		{"word":"irritent","word_nosc":"irritent","lemma":"irriter","pos":"VER"} ,
		{"word":"irriter","word_nosc":"irriter","lemma":"irriter","pos":"VER"} ,
		{"word":"irritera","word_nosc":"irritera","lemma":"irriter","pos":"VER"} ,
		{"word":"irriterait","word_nosc":"irriterait","lemma":"irriter","pos":"VER"} ,
		{"word":"irriterons","word_nosc":"irriterons","lemma":"irriter","pos":"VER"} ,
		{"word":"irritez","word_nosc":"irritez","lemma":"irriter","pos":"VER"} ,
		{"word":"irritons","word_nosc":"irritons","lemma":"irriter","pos":"VER"} ,
		{"word":"irritât","word_nosc":"irritat","lemma":"irriter","pos":"VER"} ,
		{"word":"irritèrent","word_nosc":"irriterent","lemma":"irriter","pos":"VER"} ,
		{"word":"irrité","word_nosc":"irrite","lemma":"irriter","pos":"VER"} ,
		{"word":"irritée","word_nosc":"irritee","lemma":"irriter","pos":"VER"} ,
		{"word":"irritées","word_nosc":"irritees","lemma":"irriter","pos":"VER"} ,
		{"word":"irrités","word_nosc":"irrites","lemma":"irriter","pos":"VER"} ,
		{"word":"irréalisait","word_nosc":"irrealisait","lemma":"irréaliser","pos":"VER"} ,
		{"word":"irréalise","word_nosc":"irrealise","lemma":"irréaliser","pos":"VER"} ,
		{"word":"islamiser","word_nosc":"islamiser","lemma":"islamiser","pos":"VER"} ,
		{"word":"islamisées","word_nosc":"islamisees","lemma":"islamiser","pos":"VER"} ,
		{"word":"isola","word_nosc":"isola","lemma":"isoler","pos":"VER"} ,
		{"word":"isolai","word_nosc":"isolai","lemma":"isoler","pos":"VER"} ,
		{"word":"isolaient","word_nosc":"isolaient","lemma":"isoler","pos":"VER"} ,
		{"word":"isolais","word_nosc":"isolais","lemma":"isoler","pos":"VER"} ,
		{"word":"isolait","word_nosc":"isolait","lemma":"isoler","pos":"VER"} ,
		{"word":"isolant","word_nosc":"isolant","lemma":"isoler","pos":"VER"} ,
		{"word":"isole","word_nosc":"isole","lemma":"isoler","pos":"VER"} ,
		{"word":"isolent","word_nosc":"isolent","lemma":"isoler","pos":"VER"} ,
		{"word":"isoler","word_nosc":"isoler","lemma":"isoler","pos":"VER"} ,
		{"word":"isolera","word_nosc":"isolera","lemma":"isoler","pos":"VER"} ,
		{"word":"isoleraient","word_nosc":"isoleraient","lemma":"isoler","pos":"VER"} ,
		{"word":"isolerait","word_nosc":"isolerait","lemma":"isoler","pos":"VER"} ,
		{"word":"isoleront","word_nosc":"isoleront","lemma":"isoler","pos":"VER"} ,
		{"word":"isolez","word_nosc":"isolez","lemma":"isoler","pos":"VER"} ,
		{"word":"isoliez","word_nosc":"isoliez","lemma":"isoler","pos":"VER"} ,
		{"word":"isolions","word_nosc":"isolions","lemma":"isoler","pos":"VER"} ,
		{"word":"isolèrent","word_nosc":"isolerent","lemma":"isoler","pos":"VER"} ,
		{"word":"isolé","word_nosc":"isole","lemma":"isoler","pos":"VER"} ,
		{"word":"isolée","word_nosc":"isolee","lemma":"isoler","pos":"VER"} ,
		{"word":"isolées","word_nosc":"isolees","lemma":"isoler","pos":"VER"} ,
		{"word":"isolés","word_nosc":"isoles","lemma":"isoler","pos":"VER"} ,
		{"word":"ivrognait","word_nosc":"ivrognait","lemma":"ivrogner","pos":"VER"} ,
		{"word":"ivrognasse","word_nosc":"ivrognasse","lemma":"ivrogner","pos":"VER"} ,
		{"word":"ivrogner","word_nosc":"ivrogner","lemma":"ivrogner","pos":"VER"} ,
		{"word":"ixe","word_nosc":"ixe","lemma":"ixer","pos":"VER"} ,
		{"word":"jaboter","word_nosc":"jaboter","lemma":"jaboter","pos":"VER"} ,
		{"word":"jacassaient","word_nosc":"jacassaient","lemma":"jacasser","pos":"VER"} ,
		{"word":"jacassais","word_nosc":"jacassais","lemma":"jacasser","pos":"VER"} ,
		{"word":"jacassait","word_nosc":"jacassait","lemma":"jacasser","pos":"VER"} ,
		{"word":"jacassant","word_nosc":"jacassant","lemma":"jacasser","pos":"VER"} ,
		{"word":"jacasse","word_nosc":"jacasse","lemma":"jacasser","pos":"VER"} ,
		{"word":"jacassent","word_nosc":"jacassent","lemma":"jacasser","pos":"VER"} ,
		{"word":"jacasser","word_nosc":"jacasser","lemma":"jacasser","pos":"VER"} ,
		{"word":"jacasseraient","word_nosc":"jacasseraient","lemma":"jacasser","pos":"VER"} ,
		{"word":"jacasses","word_nosc":"jacasses","lemma":"jacasser","pos":"VER"} ,
		{"word":"jacassez","word_nosc":"jacassez","lemma":"jacasser","pos":"VER"} ,
		{"word":"jacassiez","word_nosc":"jacassiez","lemma":"jacasser","pos":"VER"} ,
		{"word":"jacassé","word_nosc":"jacasse","lemma":"jacasser","pos":"VER"} ,
		{"word":"jactais","word_nosc":"jactais","lemma":"jacter","pos":"VER"} ,
		{"word":"jactait","word_nosc":"jactait","lemma":"jacter","pos":"VER"} ,
		{"word":"jactant","word_nosc":"jactant","lemma":"jacter","pos":"VER"} ,
		{"word":"jacte","word_nosc":"jacte","lemma":"jacter","pos":"VER"} ,
		{"word":"jactent","word_nosc":"jactent","lemma":"jacter","pos":"VER"} ,
		{"word":"jacter","word_nosc":"jacter","lemma":"jacter","pos":"VER"} ,
		{"word":"jacteraient","word_nosc":"jacteraient","lemma":"jacter","pos":"VER"} ,
		{"word":"jacterais","word_nosc":"jacterais","lemma":"jacter","pos":"VER"} ,
		{"word":"jactes","word_nosc":"jactes","lemma":"jacter","pos":"VER"} ,
		{"word":"jactez","word_nosc":"jactez","lemma":"jacter","pos":"VER"} ,
		{"word":"jactions","word_nosc":"jactions","lemma":"jacter","pos":"VER"} ,
		{"word":"jacté","word_nosc":"jacte","lemma":"jacter","pos":"VER"} ,
		{"word":"jaffa","word_nosc":"jaffa","lemma":"jaffer","pos":"VER"} ,
		{"word":"jaffas","word_nosc":"jaffas","lemma":"jaffer","pos":"VER"} ,
		{"word":"jaffer","word_nosc":"jaffer","lemma":"jaffer","pos":"VER"} ,
		{"word":"jailli","word_nosc":"jailli","lemma":"jaillir","pos":"VER"} ,
		{"word":"jaillie","word_nosc":"jaillie","lemma":"jaillir","pos":"VER"} ,
		{"word":"jaillies","word_nosc":"jaillies","lemma":"jaillir","pos":"VER"} ,
		{"word":"jaillir","word_nosc":"jaillir","lemma":"jaillir","pos":"VER"} ,
		{"word":"jaillira","word_nosc":"jaillira","lemma":"jaillir","pos":"VER"} ,
		{"word":"jailliraient","word_nosc":"jailliraient","lemma":"jaillir","pos":"VER"} ,
		{"word":"jaillirait","word_nosc":"jaillirait","lemma":"jaillir","pos":"VER"} ,
		{"word":"jaillirent","word_nosc":"jaillirent","lemma":"jaillir","pos":"VER"} ,
		{"word":"jailliront","word_nosc":"jailliront","lemma":"jaillir","pos":"VER"} ,
		{"word":"jaillis","word_nosc":"jaillis","lemma":"jaillir","pos":"VER"} ,
		{"word":"jaillissaient","word_nosc":"jaillissaient","lemma":"jaillir","pos":"VER"} ,
		{"word":"jaillissait","word_nosc":"jaillissait","lemma":"jaillir","pos":"VER"} ,
		{"word":"jaillissant","word_nosc":"jaillissant","lemma":"jaillir","pos":"VER"} ,
		{"word":"jaillisse","word_nosc":"jaillisse","lemma":"jaillir","pos":"VER"} ,
		{"word":"jaillissent","word_nosc":"jaillissent","lemma":"jaillir","pos":"VER"} ,
		{"word":"jaillit","word_nosc":"jaillit","lemma":"jaillir","pos":"VER"} ,
		{"word":"jaillît","word_nosc":"jaillit","lemma":"jaillir","pos":"VER"} ,
		{"word":"jalonnaient","word_nosc":"jalonnaient","lemma":"jalonner","pos":"VER"} ,
		{"word":"jalonnais","word_nosc":"jalonnais","lemma":"jalonner","pos":"VER"} ,
		{"word":"jalonnait","word_nosc":"jalonnait","lemma":"jalonner","pos":"VER"} ,
		{"word":"jalonnant","word_nosc":"jalonnant","lemma":"jalonner","pos":"VER"} ,
		{"word":"jalonne","word_nosc":"jalonne","lemma":"jalonner","pos":"VER"} ,
		{"word":"jalonnent","word_nosc":"jalonnent","lemma":"jalonner","pos":"VER"} ,
		{"word":"jalonner","word_nosc":"jalonner","lemma":"jalonner","pos":"VER"} ,
		{"word":"jalonné","word_nosc":"jalonne","lemma":"jalonner","pos":"VER"} ,
		{"word":"jalonnée","word_nosc":"jalonnee","lemma":"jalonner","pos":"VER"} ,
		{"word":"jalonnés","word_nosc":"jalonnes","lemma":"jalonner","pos":"VER"} ,
		{"word":"jalousaient","word_nosc":"jalousaient","lemma":"jalouser","pos":"VER"} ,
		{"word":"jalousais","word_nosc":"jalousais","lemma":"jalouser","pos":"VER"} ,
		{"word":"jalousait","word_nosc":"jalousait","lemma":"jalouser","pos":"VER"} ,
		{"word":"jalousant","word_nosc":"jalousant","lemma":"jalouser","pos":"VER"} ,
		{"word":"jalousent","word_nosc":"jalousent","lemma":"jalouser","pos":"VER"} ,
		{"word":"jalouser","word_nosc":"jalouser","lemma":"jalouser","pos":"VER"} ,
		{"word":"jalouserais","word_nosc":"jalouserais","lemma":"jalouser","pos":"VER"} ,
		{"word":"jalousé","word_nosc":"jalouse","lemma":"jalouser","pos":"VER"} ,
		{"word":"jalousée","word_nosc":"jalousee","lemma":"jalouser","pos":"VER"} ,
		{"word":"japoniser","word_nosc":"japoniser","lemma":"japoniser","pos":"VER"} ,
		{"word":"jappa","word_nosc":"jappa","lemma":"japper","pos":"VER"} ,
		{"word":"jappaient","word_nosc":"jappaient","lemma":"japper","pos":"VER"} ,
		{"word":"jappait","word_nosc":"jappait","lemma":"japper","pos":"VER"} ,
		{"word":"jappant","word_nosc":"jappant","lemma":"japper","pos":"VER"} ,
		{"word":"jappe","word_nosc":"jappe","lemma":"japper","pos":"VER"} ,
		{"word":"jappent","word_nosc":"jappent","lemma":"japper","pos":"VER"} ,
		{"word":"japper","word_nosc":"japper","lemma":"japper","pos":"VER"} ,
		{"word":"jappèrent","word_nosc":"japperent","lemma":"japper","pos":"VER"} ,
		{"word":"jappé","word_nosc":"jappe","lemma":"japper","pos":"VER"} ,
		{"word":"jardinait","word_nosc":"jardinait","lemma":"jardiner","pos":"VER"} ,
		{"word":"jardinant","word_nosc":"jardinant","lemma":"jardiner","pos":"VER"} ,
		{"word":"jardine","word_nosc":"jardine","lemma":"jardiner","pos":"VER"} ,
		{"word":"jardinent","word_nosc":"jardinent","lemma":"jardiner","pos":"VER"} ,
		{"word":"jardiner","word_nosc":"jardiner","lemma":"jardiner","pos":"VER"} ,
		{"word":"jardinerai","word_nosc":"jardinerai","lemma":"jardiner","pos":"VER"} ,
		{"word":"jardineras","word_nosc":"jardineras","lemma":"jardiner","pos":"VER"} ,
		{"word":"jardiné","word_nosc":"jardine","lemma":"jardiner","pos":"VER"} ,
		{"word":"jargonnant","word_nosc":"jargonnant","lemma":"jargonner","pos":"VER"} ,
		{"word":"jargonne","word_nosc":"jargonne","lemma":"jargonner","pos":"VER"} ,
		{"word":"jargonnent","word_nosc":"jargonnent","lemma":"jargonner","pos":"VER"} ,
		{"word":"jargonner","word_nosc":"jargonner","lemma":"jargonner","pos":"VER"} ,
		{"word":"jargonné","word_nosc":"jargonne","lemma":"jargonner","pos":"VER"} ,
		{"word":"jasaient","word_nosc":"jasaient","lemma":"jaser","pos":"VER"} ,
		{"word":"jasait","word_nosc":"jasait","lemma":"jaser","pos":"VER"} ,
		{"word":"jase","word_nosc":"jase","lemma":"jaser","pos":"VER"} ,
		{"word":"jasent","word_nosc":"jasent","lemma":"jaser","pos":"VER"} ,
		{"word":"jaser","word_nosc":"jaser","lemma":"jaser","pos":"VER"} ,
		{"word":"jaserait","word_nosc":"jaserait","lemma":"jaser","pos":"VER"} ,
		{"word":"jases","word_nosc":"jases","lemma":"jaser","pos":"VER"} ,
		{"word":"jaspaient","word_nosc":"jaspaient","lemma":"jasper","pos":"VER"} ,
		{"word":"jasper","word_nosc":"jasper","lemma":"jasper","pos":"VER"} ,
		{"word":"jaspinaient","word_nosc":"jaspinaient","lemma":"jaspiner","pos":"VER"} ,
		{"word":"jaspinais","word_nosc":"jaspinais","lemma":"jaspiner","pos":"VER"} ,
		{"word":"jaspinait","word_nosc":"jaspinait","lemma":"jaspiner","pos":"VER"} ,
		{"word":"jaspine","word_nosc":"jaspine","lemma":"jaspiner","pos":"VER"} ,
		{"word":"jaspinent","word_nosc":"jaspinent","lemma":"jaspiner","pos":"VER"} ,
		{"word":"jaspiner","word_nosc":"jaspiner","lemma":"jaspiner","pos":"VER"} ,
		{"word":"jaspiné","word_nosc":"jaspine","lemma":"jaspiner","pos":"VER"} ,
		{"word":"jaspé","word_nosc":"jaspe","lemma":"jasper","pos":"VER"} ,
		{"word":"jaspées","word_nosc":"jaspees","lemma":"jasper","pos":"VER"} ,
		{"word":"jaspés","word_nosc":"jaspes","lemma":"jasper","pos":"VER"} ,
		{"word":"jasé","word_nosc":"jase","lemma":"jaser","pos":"VER"} ,
		{"word":"jauge","word_nosc":"jauge","lemma":"jauger","pos":"VER"} ,
		{"word":"jaugea","word_nosc":"jaugea","lemma":"jauger","pos":"VER"} ,
		{"word":"jaugeait","word_nosc":"jaugeait","lemma":"jauger","pos":"VER"} ,
		{"word":"jaugeant","word_nosc":"jaugeant","lemma":"jauger","pos":"VER"} ,
		{"word":"jaugent","word_nosc":"jaugent","lemma":"jauger","pos":"VER"} ,
		{"word":"jauger","word_nosc":"jauger","lemma":"jauger","pos":"VER"} ,
		{"word":"jauges","word_nosc":"jauges","lemma":"jauger","pos":"VER"} ,
		{"word":"jaugez","word_nosc":"jaugez","lemma":"jauger","pos":"VER"} ,
		{"word":"jaugé","word_nosc":"jauge","lemma":"jauger","pos":"VER"} ,
		{"word":"jaugée","word_nosc":"jaugee","lemma":"jauger","pos":"VER"} ,
		{"word":"jaugés","word_nosc":"jauges","lemma":"jauger","pos":"VER"} ,
		{"word":"jauni","word_nosc":"jauni","lemma":"jaunir","pos":"VER"} ,
		{"word":"jaunie","word_nosc":"jaunie","lemma":"jaunir","pos":"VER"} ,
		{"word":"jaunies","word_nosc":"jaunies","lemma":"jaunir","pos":"VER"} ,
		{"word":"jaunir","word_nosc":"jaunir","lemma":"jaunir","pos":"VER"} ,
		{"word":"jaunira","word_nosc":"jaunira","lemma":"jaunir","pos":"VER"} ,
		{"word":"jauniront","word_nosc":"jauniront","lemma":"jaunir","pos":"VER"} ,
		{"word":"jaunis","word_nosc":"jaunis","lemma":"jaunir","pos":"VER"} ,
		{"word":"jaunissaient","word_nosc":"jaunissaient","lemma":"jaunir","pos":"VER"} ,
		{"word":"jaunissait","word_nosc":"jaunissait","lemma":"jaunir","pos":"VER"} ,
		{"word":"jaunissant","word_nosc":"jaunissant","lemma":"jaunir","pos":"VER"} ,
		{"word":"jaunisse","word_nosc":"jaunisse","lemma":"jaunir","pos":"VER"} ,
		{"word":"jaunissent","word_nosc":"jaunissent","lemma":"jaunir","pos":"VER"} ,
		{"word":"jaunit","word_nosc":"jaunit","lemma":"jaunir","pos":"VER"} ,
		{"word":"javellise","word_nosc":"javellise","lemma":"javelliser","pos":"VER"} ,
		{"word":"javelliser","word_nosc":"javelliser","lemma":"javelliser","pos":"VER"} ,
		{"word":"javellisé","word_nosc":"javellise","lemma":"javelliser","pos":"VER"} ,
		{"word":"javellisée","word_nosc":"javellisee","lemma":"javelliser","pos":"VER"} ,
		{"word":"javellisées","word_nosc":"javellisees","lemma":"javelliser","pos":"VER"} ,
		{"word":"jeta","word_nosc":"jeta","lemma":"jeter","pos":"VER"} ,
		{"word":"jetai","word_nosc":"jetai","lemma":"jeter","pos":"VER"} ,
		{"word":"jetaient","word_nosc":"jetaient","lemma":"jeter","pos":"VER"} ,
		{"word":"jetais","word_nosc":"jetais","lemma":"jeter","pos":"VER"} ,
		{"word":"jetait","word_nosc":"jetait","lemma":"jeter","pos":"VER"} ,
		{"word":"jetant","word_nosc":"jetant","lemma":"jeter","pos":"VER"} ,
		{"word":"jetas","word_nosc":"jetas","lemma":"jeter","pos":"VER"} ,
		{"word":"jeter","word_nosc":"jeter","lemma":"jeter","pos":"VER"} ,
		{"word":"jetez","word_nosc":"jetez","lemma":"jeter","pos":"VER"} ,
		{"word":"jetiez","word_nosc":"jetiez","lemma":"jeter","pos":"VER"} ,
		{"word":"jetions","word_nosc":"jetions","lemma":"jeter","pos":"VER"} ,
		{"word":"jetons","word_nosc":"jetons","lemma":"jeter","pos":"VER"} ,
		{"word":"jette","word_nosc":"jette","lemma":"jeter","pos":"VER"} ,
		{"word":"jettent","word_nosc":"jettent","lemma":"jeter","pos":"VER"} ,
		{"word":"jettera","word_nosc":"jettera","lemma":"jeter","pos":"VER"} ,
		{"word":"jetterai","word_nosc":"jetterai","lemma":"jeter","pos":"VER"} ,
		{"word":"jetteraient","word_nosc":"jetteraient","lemma":"jeter","pos":"VER"} ,
		{"word":"jetterais","word_nosc":"jetterais","lemma":"jeter","pos":"VER"} ,
		{"word":"jetterait","word_nosc":"jetterait","lemma":"jeter","pos":"VER"} ,
		{"word":"jetteras","word_nosc":"jetteras","lemma":"jeter","pos":"VER"} ,
		{"word":"jetterez","word_nosc":"jetterez","lemma":"jeter","pos":"VER"} ,
		{"word":"jetteriez","word_nosc":"jetteriez","lemma":"jeter","pos":"VER"} ,
		{"word":"jetterions","word_nosc":"jetterions","lemma":"jeter","pos":"VER"} ,
		{"word":"jetterons","word_nosc":"jetterons","lemma":"jeter","pos":"VER"} ,
		{"word":"jetteront","word_nosc":"jetteront","lemma":"jeter","pos":"VER"} ,
		{"word":"jettes","word_nosc":"jettes","lemma":"jeter","pos":"VER"} ,
		{"word":"jetâmes","word_nosc":"jetames","lemma":"jeter","pos":"VER"} ,
		{"word":"jetât","word_nosc":"jetat","lemma":"jeter","pos":"VER"} ,
		{"word":"jetèrent","word_nosc":"jeterent","lemma":"jeter","pos":"VER"} ,
		{"word":"jeté","word_nosc":"jete","lemma":"jeter","pos":"VER"} ,
		{"word":"jetée","word_nosc":"jetee","lemma":"jeter","pos":"VER"} ,
		{"word":"jetées","word_nosc":"jetees","lemma":"jeter","pos":"VER"} ,
		{"word":"jetés","word_nosc":"jetes","lemma":"jeter","pos":"VER"} ,
		{"word":"jeûna","word_nosc":"jeuna","lemma":"jeûner","pos":"VER"} ,
		{"word":"jeûnaient","word_nosc":"jeunaient","lemma":"jeûner","pos":"VER"} ,
		{"word":"jeûnait","word_nosc":"jeunait","lemma":"jeûner","pos":"VER"} ,
		{"word":"jeûnant","word_nosc":"jeunant","lemma":"jeûner","pos":"VER"} ,
		{"word":"jeûne","word_nosc":"jeune","lemma":"jeûner","pos":"VER"} ,
		{"word":"jeûnent","word_nosc":"jeunent","lemma":"jeûner","pos":"VER"} ,
		{"word":"jeûner","word_nosc":"jeuner","lemma":"jeûner","pos":"VER"} ,
		{"word":"jeûnerai","word_nosc":"jeunerai","lemma":"jeûner","pos":"VER"} ,
		{"word":"jeûnerait","word_nosc":"jeunerait","lemma":"jeûner","pos":"VER"} ,
		{"word":"jeûneras","word_nosc":"jeuneras","lemma":"jeûner","pos":"VER"} ,
		{"word":"jeûnerons","word_nosc":"jeunerons","lemma":"jeûner","pos":"VER"} ,
		{"word":"jeûnes","word_nosc":"jeunes","lemma":"jeûner","pos":"VER"} ,
		{"word":"jeûnez","word_nosc":"jeunez","lemma":"jeûner","pos":"VER"} ,
		{"word":"jeûné","word_nosc":"jeune","lemma":"jeûner","pos":"VER"} ,
		{"word":"jodler","word_nosc":"jodler","lemma":"jodler","pos":"VER"} ,
		{"word":"jogger","word_nosc":"jogger","lemma":"jogger","pos":"VER"} ,
		{"word":"joignaient","word_nosc":"joignaient","lemma":"joindre","pos":"VER"} ,
		{"word":"joignais","word_nosc":"joignais","lemma":"joindre","pos":"VER"} ,
		{"word":"joignait","word_nosc":"joignait","lemma":"joindre","pos":"VER"} ,
		{"word":"joignant","word_nosc":"joignant","lemma":"joindre","pos":"VER"} ,
		{"word":"joigne","word_nosc":"joigne","lemma":"joindre","pos":"VER"} ,
		{"word":"joignent","word_nosc":"joignent","lemma":"joindre","pos":"VER"} ,
		{"word":"joignes","word_nosc":"joignes","lemma":"joindre","pos":"VER"} ,
		{"word":"joignez","word_nosc":"joignez","lemma":"joindre","pos":"VER"} ,
		{"word":"joigniez","word_nosc":"joigniez","lemma":"joindre","pos":"VER"} ,
		{"word":"joignions","word_nosc":"joignions","lemma":"joindre","pos":"VER"} ,
		{"word":"joignirent","word_nosc":"joignirent","lemma":"joindre","pos":"VER"} ,
		{"word":"joignis","word_nosc":"joignis","lemma":"joindre","pos":"VER"} ,
		{"word":"joignissent","word_nosc":"joignissent","lemma":"joindre","pos":"VER"} ,
		{"word":"joignit","word_nosc":"joignit","lemma":"joindre","pos":"VER"} ,
		{"word":"joignons","word_nosc":"joignons","lemma":"joindre","pos":"VER"} ,
		{"word":"joignîmes","word_nosc":"joignimes","lemma":"joindre","pos":"VER"} ,
		{"word":"joindra","word_nosc":"joindra","lemma":"joindre","pos":"VER"} ,
		{"word":"joindrai","word_nosc":"joindrai","lemma":"joindre","pos":"VER"} ,
		{"word":"joindraient","word_nosc":"joindraient","lemma":"joindre","pos":"VER"} ,
		{"word":"joindrais","word_nosc":"joindrais","lemma":"joindre","pos":"VER"} ,
		{"word":"joindrait","word_nosc":"joindrait","lemma":"joindre","pos":"VER"} ,
		{"word":"joindras","word_nosc":"joindras","lemma":"joindre","pos":"VER"} ,
		{"word":"joindre","word_nosc":"joindre","lemma":"joindre","pos":"VER"} ,
		{"word":"joindrez","word_nosc":"joindrez","lemma":"joindre","pos":"VER"} ,
		{"word":"joindriez","word_nosc":"joindriez","lemma":"joindre","pos":"VER"} ,
		{"word":"joindrons","word_nosc":"joindrons","lemma":"joindre","pos":"VER"} ,
		{"word":"joindront","word_nosc":"joindront","lemma":"joindre","pos":"VER"} ,
		{"word":"joins","word_nosc":"joins","lemma":"joindre","pos":"VER"} ,
		{"word":"joint","word_nosc":"joint","lemma":"joindre","pos":"VER"} ,
		{"word":"jointe","word_nosc":"jointe","lemma":"jointer","pos":"VER"} ,
		{"word":"jointoyaient","word_nosc":"jointoyaient","lemma":"jointoyer","pos":"VER"} ,
		{"word":"joints","word_nosc":"joints","lemma":"joindre","pos":"VER"} ,
		{"word":"jointés","word_nosc":"jointes","lemma":"jointer","pos":"VER"} ,
		{"word":"jonchaient","word_nosc":"jonchaient","lemma":"joncher","pos":"VER"} ,
		{"word":"jonchait","word_nosc":"jonchait","lemma":"joncher","pos":"VER"} ,
		{"word":"jonchant","word_nosc":"jonchant","lemma":"joncher","pos":"VER"} ,
		{"word":"jonchent","word_nosc":"jonchent","lemma":"joncher","pos":"VER"} ,
		{"word":"joncher","word_nosc":"joncher","lemma":"joncher","pos":"VER"} ,
		{"word":"jonché","word_nosc":"jonche","lemma":"joncher","pos":"VER"} ,
		{"word":"jonchée","word_nosc":"jonchee","lemma":"joncher","pos":"VER"} ,
		{"word":"jonchées","word_nosc":"jonchees","lemma":"joncher","pos":"VER"} ,
		{"word":"jonchés","word_nosc":"jonches","lemma":"joncher","pos":"VER"} ,
		{"word":"jonglaient","word_nosc":"jonglaient","lemma":"jongler","pos":"VER"} ,
		{"word":"jonglais","word_nosc":"jonglais","lemma":"jongler","pos":"VER"} ,
		{"word":"jonglait","word_nosc":"jonglait","lemma":"jongler","pos":"VER"} ,
		{"word":"jonglant","word_nosc":"jonglant","lemma":"jongler","pos":"VER"} ,
		{"word":"jongle","word_nosc":"jongle","lemma":"jongler","pos":"VER"} ,
		{"word":"jonglent","word_nosc":"jonglent","lemma":"jongler","pos":"VER"} ,
		{"word":"jongler","word_nosc":"jongler","lemma":"jongler","pos":"VER"} ,
		{"word":"jonglera","word_nosc":"jonglera","lemma":"jongler","pos":"VER"} ,
		{"word":"jongles","word_nosc":"jongles","lemma":"jongler","pos":"VER"} ,
		{"word":"jonglez","word_nosc":"jonglez","lemma":"jongler","pos":"VER"} ,
		{"word":"jonglé","word_nosc":"jongle","lemma":"jongler","pos":"VER"} ,
		{"word":"jordonner","word_nosc":"jordonner","lemma":"jordonner","pos":"VER"} ,
		{"word":"joua","word_nosc":"joua","lemma":"jouer","pos":"VER"} ,
		{"word":"jouai","word_nosc":"jouai","lemma":"jouer","pos":"VER"} ,
		{"word":"jouaient","word_nosc":"jouaient","lemma":"jouer","pos":"VER"} ,
		{"word":"jouais","word_nosc":"jouais","lemma":"jouer","pos":"VER"} ,
		{"word":"jouait","word_nosc":"jouait","lemma":"jouer","pos":"VER"} ,
		{"word":"jouant","word_nosc":"jouant","lemma":"jouer","pos":"VER"} ,
		{"word":"joue","word_nosc":"joue","lemma":"jouer","pos":"VER"} ,
		{"word":"jouent","word_nosc":"jouent","lemma":"jouer","pos":"VER"} ,
		{"word":"jouer","word_nosc":"jouer","lemma":"jouer","pos":"VER"} ,
		{"word":"jouera","word_nosc":"jouera","lemma":"jouer","pos":"VER"} ,
		{"word":"jouerai","word_nosc":"jouerai","lemma":"jouer","pos":"VER"} ,
		{"word":"joueraient","word_nosc":"joueraient","lemma":"jouer","pos":"VER"} ,
		{"word":"jouerais","word_nosc":"jouerais","lemma":"jouer","pos":"VER"} ,
		{"word":"jouerait","word_nosc":"jouerait","lemma":"jouer","pos":"VER"} ,
		{"word":"joueras","word_nosc":"joueras","lemma":"jouer","pos":"VER"} ,
		{"word":"jouerez","word_nosc":"jouerez","lemma":"jouer","pos":"VER"} ,
		{"word":"joueriez","word_nosc":"joueriez","lemma":"jouer","pos":"VER"} ,
		{"word":"jouerions","word_nosc":"jouerions","lemma":"jouer","pos":"VER"} ,
		{"word":"jouerons","word_nosc":"jouerons","lemma":"jouer","pos":"VER"} ,
		{"word":"joueront","word_nosc":"joueront","lemma":"jouer","pos":"VER"} ,
		{"word":"joues","word_nosc":"joues","lemma":"jouer","pos":"VER"} ,
		{"word":"jouez","word_nosc":"jouez","lemma":"jouer","pos":"VER"} ,
		{"word":"joui","word_nosc":"joui","lemma":"jouir","pos":"VER"} ,
		{"word":"jouiez","word_nosc":"jouiez","lemma":"jouer","pos":"VER"} ,
		{"word":"jouions","word_nosc":"jouions","lemma":"jouer","pos":"VER"} ,
		{"word":"jouir","word_nosc":"jouir","lemma":"jouir","pos":"VER"} ,
		{"word":"jouira","word_nosc":"jouira","lemma":"jouir","pos":"VER"} ,
		{"word":"jouirai","word_nosc":"jouirai","lemma":"jouir","pos":"VER"} ,
		{"word":"jouiraient","word_nosc":"jouiraient","lemma":"jouir","pos":"VER"} ,
		{"word":"jouirait","word_nosc":"jouirait","lemma":"jouir","pos":"VER"} ,
		{"word":"jouiras","word_nosc":"jouiras","lemma":"jouir","pos":"VER"} ,
		{"word":"jouirent","word_nosc":"jouirent","lemma":"jouir","pos":"VER"} ,
		{"word":"jouirions","word_nosc":"jouirions","lemma":"jouir","pos":"VER"} ,
		{"word":"jouirons","word_nosc":"jouirons","lemma":"jouir","pos":"VER"} ,
		{"word":"jouis","word_nosc":"jouis","lemma":"jouir","pos":"VER"} ,
		{"word":"jouissaient","word_nosc":"jouissaient","lemma":"jouir","pos":"VER"} ,
		{"word":"jouissais","word_nosc":"jouissais","lemma":"jouir","pos":"VER"} ,
		{"word":"jouissait","word_nosc":"jouissait","lemma":"jouir","pos":"VER"} ,
		{"word":"jouissant","word_nosc":"jouissant","lemma":"jouir","pos":"VER"} ,
		{"word":"jouisse","word_nosc":"jouisse","lemma":"jouir","pos":"VER"} ,
		{"word":"jouissent","word_nosc":"jouissent","lemma":"jouir","pos":"VER"} ,
		{"word":"jouisses","word_nosc":"jouisses","lemma":"jouir","pos":"VER"} ,
		{"word":"jouissez","word_nosc":"jouissez","lemma":"jouir","pos":"VER"} ,
		{"word":"jouissions","word_nosc":"jouissions","lemma":"jouir","pos":"VER"} ,
		{"word":"jouissons","word_nosc":"jouissons","lemma":"jouir","pos":"VER"} ,
		{"word":"jouit","word_nosc":"jouit","lemma":"jouir","pos":"VER"} ,
		{"word":"jouons","word_nosc":"jouons","lemma":"jouer","pos":"VER"} ,
		{"word":"jouter","word_nosc":"jouter","lemma":"jouter","pos":"VER"} ,
		{"word":"joutez","word_nosc":"joutez","lemma":"jouter","pos":"VER"} ,
		{"word":"jouté","word_nosc":"joute","lemma":"jouter","pos":"VER"} ,
		{"word":"jouxtaient","word_nosc":"jouxtaient","lemma":"jouxter","pos":"VER"} ,
		{"word":"jouxtait","word_nosc":"jouxtait","lemma":"jouxter","pos":"VER"} ,
		{"word":"jouxtant","word_nosc":"jouxtant","lemma":"jouxter","pos":"VER"} ,
		{"word":"jouxte","word_nosc":"jouxte","lemma":"jouxter","pos":"VER"} ,
		{"word":"jouâmes","word_nosc":"jouames","lemma":"jouer","pos":"VER"} ,
		{"word":"jouât","word_nosc":"jouat","lemma":"jouer","pos":"VER"} ,
		{"word":"jouèrent","word_nosc":"jouerent","lemma":"jouer","pos":"VER"} ,
		{"word":"joué","word_nosc":"joue","lemma":"jouer","pos":"VER"} ,
		{"word":"jouée","word_nosc":"jouee","lemma":"jouer","pos":"VER"} ,
		{"word":"jouées","word_nosc":"jouees","lemma":"jouer","pos":"VER"} ,
		{"word":"joués","word_nosc":"joues","lemma":"jouer","pos":"VER"} ,
		{"word":"jubila","word_nosc":"jubila","lemma":"jubiler","pos":"VER"} ,
		{"word":"jubilai","word_nosc":"jubilai","lemma":"jubiler","pos":"VER"} ,
		{"word":"jubilaient","word_nosc":"jubilaient","lemma":"jubiler","pos":"VER"} ,
		{"word":"jubilais","word_nosc":"jubilais","lemma":"jubiler","pos":"VER"} ,
		{"word":"jubilait","word_nosc":"jubilait","lemma":"jubiler","pos":"VER"} ,
		{"word":"jubilant","word_nosc":"jubilant","lemma":"jubiler","pos":"VER"} ,
		{"word":"jubile","word_nosc":"jubile","lemma":"jubiler","pos":"VER"} ,
		{"word":"jubilent","word_nosc":"jubilent","lemma":"jubiler","pos":"VER"} ,
		{"word":"jubiler","word_nosc":"jubiler","lemma":"jubiler","pos":"VER"} ,
		{"word":"jubilera","word_nosc":"jubilera","lemma":"jubiler","pos":"VER"} ,
		{"word":"jubileraient","word_nosc":"jubileraient","lemma":"jubiler","pos":"VER"} ,
		{"word":"jubiles","word_nosc":"jubiles","lemma":"jubiler","pos":"VER"} ,
		{"word":"jubilèrent","word_nosc":"jubilerent","lemma":"jubiler","pos":"VER"} ,
		{"word":"jubilé","word_nosc":"jubile","lemma":"jubiler","pos":"VER"} ,
		{"word":"jubilée","word_nosc":"jubilee","lemma":"jubiler","pos":"VER"} ,
		{"word":"jucha","word_nosc":"jucha","lemma":"jucher","pos":"VER"} ,
		{"word":"juchai","word_nosc":"juchai","lemma":"jucher","pos":"VER"} ,
		{"word":"juchaient","word_nosc":"juchaient","lemma":"jucher","pos":"VER"} ,
		{"word":"juchais","word_nosc":"juchais","lemma":"jucher","pos":"VER"} ,
		{"word":"juchait","word_nosc":"juchait","lemma":"jucher","pos":"VER"} ,
		{"word":"juchant","word_nosc":"juchant","lemma":"jucher","pos":"VER"} ,
		{"word":"juche","word_nosc":"juche","lemma":"jucher","pos":"VER"} ,
		{"word":"juchent","word_nosc":"juchent","lemma":"jucher","pos":"VER"} ,
		{"word":"jucher","word_nosc":"jucher","lemma":"jucher","pos":"VER"} ,
		{"word":"jucheront","word_nosc":"jucheront","lemma":"jucher","pos":"VER"} ,
		{"word":"juchions","word_nosc":"juchions","lemma":"jucher","pos":"VER"} ,
		{"word":"juché","word_nosc":"juche","lemma":"jucher","pos":"VER"} ,
		{"word":"juchée","word_nosc":"juchee","lemma":"jucher","pos":"VER"} ,
		{"word":"juchées","word_nosc":"juchees","lemma":"jucher","pos":"VER"} ,
		{"word":"juchés","word_nosc":"juches","lemma":"jucher","pos":"VER"} ,
		{"word":"judaïser","word_nosc":"judaiser","lemma":"judaïser","pos":"VER"} ,
		{"word":"judaïsées","word_nosc":"judaisees","lemma":"judaïser","pos":"VER"} ,
		{"word":"juge","word_nosc":"juge","lemma":"juger","pos":"VER"} ,
		{"word":"jugea","word_nosc":"jugea","lemma":"juger","pos":"VER"} ,
		{"word":"jugeai","word_nosc":"jugeai","lemma":"juger","pos":"VER"} ,
		{"word":"jugeaient","word_nosc":"jugeaient","lemma":"juger","pos":"VER"} ,
		{"word":"jugeais","word_nosc":"jugeais","lemma":"juger","pos":"VER"} ,
		{"word":"jugeait","word_nosc":"jugeait","lemma":"juger","pos":"VER"} ,
		{"word":"jugeant","word_nosc":"jugeant","lemma":"juger","pos":"VER"} ,
		{"word":"jugent","word_nosc":"jugent","lemma":"juger","pos":"VER"} ,
		{"word":"jugeons","word_nosc":"jugeons","lemma":"juger","pos":"VER"} ,
		{"word":"juger","word_nosc":"juger","lemma":"juger","pos":"VER"} ,
		{"word":"jugera","word_nosc":"jugera","lemma":"juger","pos":"VER"} ,
		{"word":"jugerai","word_nosc":"jugerai","lemma":"juger","pos":"VER"} ,
		{"word":"jugeraient","word_nosc":"jugeraient","lemma":"juger","pos":"VER"} ,
		{"word":"jugerais","word_nosc":"jugerais","lemma":"juger","pos":"VER"} ,
		{"word":"jugerait","word_nosc":"jugerait","lemma":"juger","pos":"VER"} ,
		{"word":"jugeras","word_nosc":"jugeras","lemma":"juger","pos":"VER"} ,
		{"word":"jugerez","word_nosc":"jugerez","lemma":"juger","pos":"VER"} ,
		{"word":"jugeriez","word_nosc":"jugeriez","lemma":"juger","pos":"VER"} ,
		{"word":"jugerons","word_nosc":"jugerons","lemma":"juger","pos":"VER"} ,
		{"word":"jugeront","word_nosc":"jugeront","lemma":"juger","pos":"VER"} ,
		{"word":"juges","word_nosc":"juges","lemma":"juger","pos":"VER"} ,
		{"word":"jugez","word_nosc":"jugez","lemma":"juger","pos":"VER"} ,
		{"word":"jugeâmes","word_nosc":"jugeames","lemma":"juger","pos":"VER"} ,
		{"word":"jugeât","word_nosc":"jugeat","lemma":"juger","pos":"VER"} ,
		{"word":"jugiez","word_nosc":"jugiez","lemma":"juger","pos":"VER"} ,
		{"word":"jugions","word_nosc":"jugions","lemma":"juger","pos":"VER"} ,
		{"word":"jugula","word_nosc":"jugula","lemma":"juguler","pos":"VER"} ,
		{"word":"jugulant","word_nosc":"jugulant","lemma":"juguler","pos":"VER"} ,
		{"word":"jugule","word_nosc":"jugule","lemma":"juguler","pos":"VER"} ,
		{"word":"juguler","word_nosc":"juguler","lemma":"juguler","pos":"VER"} ,
		{"word":"jugulé","word_nosc":"jugule","lemma":"juguler","pos":"VER"} ,
		{"word":"jugulée","word_nosc":"jugulee","lemma":"juguler","pos":"VER"} ,
		{"word":"jugèrent","word_nosc":"jugerent","lemma":"juger","pos":"VER"} ,
		{"word":"jugé","word_nosc":"juge","lemma":"juger","pos":"VER"} ,
		{"word":"jugée","word_nosc":"jugee","lemma":"juger","pos":"VER"} ,
		{"word":"jugées","word_nosc":"jugees","lemma":"juger","pos":"VER"} ,
		{"word":"jugés","word_nosc":"juges","lemma":"juger","pos":"VER"} ,
		{"word":"jumeler","word_nosc":"jumeler","lemma":"jumeler","pos":"VER"} ,
		{"word":"jumelle","word_nosc":"jumelle","lemma":"jumeler","pos":"VER"} ,
		{"word":"jumellera","word_nosc":"jumellera","lemma":"jumeler","pos":"VER"} ,
		{"word":"jumelé","word_nosc":"jumele","lemma":"jumeler","pos":"VER"} ,
		{"word":"jumelée","word_nosc":"jumelee","lemma":"jumeler","pos":"VER"} ,
		{"word":"jumelées","word_nosc":"jumelees","lemma":"jumeler","pos":"VER"} ,
		{"word":"jumelés","word_nosc":"jumeles","lemma":"jumeler","pos":"VER"} ,
		{"word":"juponnée","word_nosc":"juponnee","lemma":"juponner","pos":"VER"} ,
		{"word":"juponnés","word_nosc":"juponnes","lemma":"juponner","pos":"VER"} ,
		{"word":"jura","word_nosc":"jura","lemma":"jurer","pos":"VER"} ,
		{"word":"jurai","word_nosc":"jurai","lemma":"jurer","pos":"VER"} ,
		{"word":"juraient","word_nosc":"juraient","lemma":"jurer","pos":"VER"} ,
		{"word":"jurais","word_nosc":"jurais","lemma":"jurer","pos":"VER"} ,
		{"word":"jurait","word_nosc":"jurait","lemma":"jurer","pos":"VER"} ,
		{"word":"jurant","word_nosc":"jurant","lemma":"jurer","pos":"VER"} ,
		{"word":"jure","word_nosc":"jure","lemma":"jurer","pos":"VER"} ,
		{"word":"jurent","word_nosc":"jurent","lemma":"jurer","pos":"VER"} ,
		{"word":"jurer","word_nosc":"jurer","lemma":"jurer","pos":"VER"} ,
		{"word":"jurera","word_nosc":"jurera","lemma":"jurer","pos":"VER"} ,
		{"word":"jurerai","word_nosc":"jurerai","lemma":"jurer","pos":"VER"} ,
		{"word":"jurerais","word_nosc":"jurerais","lemma":"jurer","pos":"VER"} ,
		{"word":"jurerait","word_nosc":"jurerait","lemma":"jurer","pos":"VER"} ,
		{"word":"jureras","word_nosc":"jureras","lemma":"jurer","pos":"VER"} ,
		{"word":"jurerez","word_nosc":"jurerez","lemma":"jurer","pos":"VER"} ,
		{"word":"jureriez","word_nosc":"jureriez","lemma":"jurer","pos":"VER"} ,
		{"word":"jureront","word_nosc":"jureront","lemma":"jurer","pos":"VER"} ,
		{"word":"jures","word_nosc":"jures","lemma":"jurer","pos":"VER"} ,
		{"word":"jurez","word_nosc":"jurez","lemma":"jurer","pos":"VER"} ,
		{"word":"juriez","word_nosc":"juriez","lemma":"jurer","pos":"VER"} ,
		{"word":"jurons","word_nosc":"jurons","lemma":"jurer","pos":"VER"} ,
		{"word":"jurâmes","word_nosc":"jurames","lemma":"jurer","pos":"VER"} ,
		{"word":"jurèrent","word_nosc":"jurerent","lemma":"jurer","pos":"VER"} ,
		{"word":"juré","word_nosc":"jure","lemma":"jurer","pos":"VER"} ,
		{"word":"jurée","word_nosc":"juree","lemma":"jurer","pos":"VER"} ,
		{"word":"jurées","word_nosc":"jurees","lemma":"jurer","pos":"VER"} ,
		{"word":"jurés","word_nosc":"jures","lemma":"jurer","pos":"VER"} ,
		{"word":"justifia","word_nosc":"justifia","lemma":"justifier","pos":"VER"} ,
		{"word":"justifiaient","word_nosc":"justifiaient","lemma":"justifier","pos":"VER"} ,
		{"word":"justifiais","word_nosc":"justifiais","lemma":"justifier","pos":"VER"} ,
		{"word":"justifiait","word_nosc":"justifiait","lemma":"justifier","pos":"VER"} ,
		{"word":"justifiant","word_nosc":"justifiant","lemma":"justifier","pos":"VER"} ,
		{"word":"justifie","word_nosc":"justifie","lemma":"justifier","pos":"VER"} ,
		{"word":"justifient","word_nosc":"justifient","lemma":"justifier","pos":"VER"} ,
		{"word":"justifier","word_nosc":"justifier","lemma":"justifier","pos":"VER"} ,
		{"word":"justifiera","word_nosc":"justifiera","lemma":"justifier","pos":"VER"} ,
		{"word":"justifierai","word_nosc":"justifierai","lemma":"justifier","pos":"VER"} ,
		{"word":"justifieraient","word_nosc":"justifieraient","lemma":"justifier","pos":"VER"} ,
		{"word":"justifierais","word_nosc":"justifierais","lemma":"justifier","pos":"VER"} ,
		{"word":"justifierait","word_nosc":"justifierait","lemma":"justifier","pos":"VER"} ,
		{"word":"justifiez","word_nosc":"justifiez","lemma":"justifier","pos":"VER"} ,
		{"word":"justifiât","word_nosc":"justifiat","lemma":"justifier","pos":"VER"} ,
		{"word":"justifié","word_nosc":"justifie","lemma":"justifier","pos":"VER"} ,
		{"word":"justifiée","word_nosc":"justifiee","lemma":"justifier","pos":"VER"} ,
		{"word":"justifiées","word_nosc":"justifiees","lemma":"justifier","pos":"VER"} ,
		{"word":"justifiés","word_nosc":"justifies","lemma":"justifier","pos":"VER"} ,
		{"word":"juta","word_nosc":"juta","lemma":"juter","pos":"VER"} ,
		{"word":"jutait","word_nosc":"jutait","lemma":"juter","pos":"VER"} ,
		{"word":"juter","word_nosc":"juter","lemma":"juter","pos":"VER"} ,
		{"word":"juté","word_nosc":"jute","lemma":"juter","pos":"VER"} ,
		{"word":"juxtaposaient","word_nosc":"juxtaposaient","lemma":"juxtaposer","pos":"VER"} ,
		{"word":"juxtaposais","word_nosc":"juxtaposais","lemma":"juxtaposer","pos":"VER"} ,
		{"word":"juxtaposait","word_nosc":"juxtaposait","lemma":"juxtaposer","pos":"VER"} ,
		{"word":"juxtaposant","word_nosc":"juxtaposant","lemma":"juxtaposer","pos":"VER"} ,
		{"word":"juxtapose","word_nosc":"juxtapose","lemma":"juxtaposer","pos":"VER"} ,
		{"word":"juxtaposent","word_nosc":"juxtaposent","lemma":"juxtaposer","pos":"VER"} ,
		{"word":"juxtaposer","word_nosc":"juxtaposer","lemma":"juxtaposer","pos":"VER"} ,
		{"word":"juxtaposèrent","word_nosc":"juxtaposerent","lemma":"juxtaposer","pos":"VER"} ,
		{"word":"juxtaposées","word_nosc":"juxtaposees","lemma":"juxtaposer","pos":"VER"} ,
		{"word":"juxtaposés","word_nosc":"juxtaposes","lemma":"juxtaposer","pos":"VER"} ,
		{"word":"kidnappa","word_nosc":"kidnappa","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnappait","word_nosc":"kidnappait","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnappant","word_nosc":"kidnappant","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnappe","word_nosc":"kidnappe","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnappent","word_nosc":"kidnappent","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnapper","word_nosc":"kidnapper","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnapperai","word_nosc":"kidnapperai","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnapperaient","word_nosc":"kidnapperaient","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnapperas","word_nosc":"kidnapperas","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnapperez","word_nosc":"kidnapperez","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnappez","word_nosc":"kidnappez","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnappiez","word_nosc":"kidnappiez","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnappons","word_nosc":"kidnappons","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnappé","word_nosc":"kidnappe","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnappée","word_nosc":"kidnappee","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnappées","word_nosc":"kidnappees","lemma":"kidnapper","pos":"VER"} ,
		{"word":"kidnappés","word_nosc":"kidnappes","lemma":"kidnapper","pos":"VER"} ,
		{"word":"klaxonna","word_nosc":"klaxonna","lemma":"klaxonner","pos":"VER"} ,
		{"word":"klaxonnai","word_nosc":"klaxonnai","lemma":"klaxonner","pos":"VER"} ,
		{"word":"klaxonnaient","word_nosc":"klaxonnaient","lemma":"klaxonner","pos":"VER"} ,
		{"word":"klaxonnais","word_nosc":"klaxonnais","lemma":"klaxonner","pos":"VER"} ,
		{"word":"klaxonnait","word_nosc":"klaxonnait","lemma":"klaxonner","pos":"VER"} ,
		{"word":"klaxonnant","word_nosc":"klaxonnant","lemma":"klaxonner","pos":"VER"} ,
		{"word":"klaxonne","word_nosc":"klaxonne","lemma":"klaxonner","pos":"VER"} ,
		{"word":"klaxonnent","word_nosc":"klaxonnent","lemma":"klaxonner","pos":"VER"} ,
		{"word":"klaxonner","word_nosc":"klaxonner","lemma":"klaxonner","pos":"VER"} ,
		{"word":"klaxonnera","word_nosc":"klaxonnera","lemma":"klaxonner","pos":"VER"} ,
		{"word":"klaxonnerai","word_nosc":"klaxonnerai","lemma":"klaxonner","pos":"VER"} ,
		{"word":"klaxonnes","word_nosc":"klaxonnes","lemma":"klaxonner","pos":"VER"} ,
		{"word":"klaxonnez","word_nosc":"klaxonnez","lemma":"klaxonner","pos":"VER"} ,
		{"word":"klaxonné","word_nosc":"klaxonne","lemma":"klaxonner","pos":"VER"} ,
		{"word":"labialisant","word_nosc":"labialisant","lemma":"labialiser","pos":"VER"} ,
		{"word":"laboura","word_nosc":"laboura","lemma":"labourer","pos":"VER"} ,
		{"word":"labouraient","word_nosc":"labouraient","lemma":"labourer","pos":"VER"} ,
		{"word":"labourais","word_nosc":"labourais","lemma":"labourer","pos":"VER"} ,
		{"word":"labourait","word_nosc":"labourait","lemma":"labourer","pos":"VER"} ,
		{"word":"labourant","word_nosc":"labourant","lemma":"labourer","pos":"VER"} ,
		{"word":"laboure","word_nosc":"laboure","lemma":"labourer","pos":"VER"} ,
		{"word":"labourent","word_nosc":"labourent","lemma":"labourer","pos":"VER"} ,
		{"word":"labourer","word_nosc":"labourer","lemma":"labourer","pos":"VER"} ,
		{"word":"labourerait","word_nosc":"labourerait","lemma":"labourer","pos":"VER"} ,
		{"word":"labouré","word_nosc":"laboure","lemma":"labourer","pos":"VER"} ,
		{"word":"labourée","word_nosc":"labouree","lemma":"labourer","pos":"VER"} ,
		{"word":"labourées","word_nosc":"labourees","lemma":"labourer","pos":"VER"} ,
		{"word":"labourés","word_nosc":"laboures","lemma":"labourer","pos":"VER"} ,
		{"word":"lace","word_nosc":"lace","lemma":"lacer","pos":"VER"} ,
		{"word":"lacent","word_nosc":"lacent","lemma":"lacer","pos":"VER"} ,
		{"word":"lacer","word_nosc":"lacer","lemma":"lacer","pos":"VER"} ,
		{"word":"lacère","word_nosc":"lacere","lemma":"lacérer","pos":"VER"} ,
		{"word":"lacèrent","word_nosc":"lacerent","lemma":"lacérer","pos":"VER"} ,
		{"word":"lacé","word_nosc":"lace","lemma":"lacer","pos":"VER"} ,
		{"word":"lacée","word_nosc":"lacee","lemma":"lacer","pos":"VER"} ,
		{"word":"lacées","word_nosc":"lacees","lemma":"lacer","pos":"VER"} ,
		{"word":"lacéra","word_nosc":"lacera","lemma":"lacérer","pos":"VER"} ,
		{"word":"lacéraient","word_nosc":"laceraient","lemma":"lacérer","pos":"VER"} ,
		{"word":"lacérait","word_nosc":"lacerait","lemma":"lacérer","pos":"VER"} ,
		{"word":"lacérant","word_nosc":"lacerant","lemma":"lacérer","pos":"VER"} ,
		{"word":"lacérer","word_nosc":"lacerer","lemma":"lacérer","pos":"VER"} ,
		{"word":"lacérions","word_nosc":"lacerions","lemma":"lacérer","pos":"VER"} ,
		{"word":"lacéré","word_nosc":"lacere","lemma":"lacérer","pos":"VER"} ,
		{"word":"lacérée","word_nosc":"laceree","lemma":"lacérer","pos":"VER"} ,
		{"word":"lacérées","word_nosc":"lacerees","lemma":"lacérer","pos":"VER"} ,
		{"word":"lacérés","word_nosc":"laceres","lemma":"lacérer","pos":"VER"} ,
		{"word":"lacés","word_nosc":"laces","lemma":"lacer","pos":"VER"} ,
		{"word":"laissa","word_nosc":"laissa","lemma":"laisser","pos":"VER"} ,
		{"word":"laissai","word_nosc":"laissai","lemma":"laisser","pos":"VER"} ,
		{"word":"laissaient","word_nosc":"laissaient","lemma":"laisser","pos":"VER"} ,
		{"word":"laissais","word_nosc":"laissais","lemma":"laisser","pos":"VER"} ,
		{"word":"laissait","word_nosc":"laissait","lemma":"laisser","pos":"VER"} ,
		{"word":"laissant","word_nosc":"laissant","lemma":"laisser","pos":"VER"} ,
		{"word":"laissassent","word_nosc":"laissassent","lemma":"laisser","pos":"VER"} ,
		{"word":"laisse","word_nosc":"laisse","lemma":"laisser","pos":"VER"} ,
		{"word":"laissent","word_nosc":"laissent","lemma":"laisser","pos":"VER"} ,
		{"word":"laisser","word_nosc":"laisser","lemma":"laisser","pos":"VER"} ,
		{"word":"laissera","word_nosc":"laissera","lemma":"laisser","pos":"VER"} ,
		{"word":"laisserai","word_nosc":"laisserai","lemma":"laisser","pos":"VER"} ,
		{"word":"laisseraient","word_nosc":"laisseraient","lemma":"laisser","pos":"VER"} ,
		{"word":"laisserais","word_nosc":"laisserais","lemma":"laisser","pos":"VER"} ,
		{"word":"laisserait","word_nosc":"laisserait","lemma":"laisser","pos":"VER"} ,
		{"word":"laisseras","word_nosc":"laisseras","lemma":"laisser","pos":"VER"} ,
		{"word":"laisserez","word_nosc":"laisserez","lemma":"laisser","pos":"VER"} ,
		{"word":"laisseriez","word_nosc":"laisseriez","lemma":"laisser","pos":"VER"} ,
		{"word":"laisserions","word_nosc":"laisserions","lemma":"laisser","pos":"VER"} ,
		{"word":"laisserons","word_nosc":"laisserons","lemma":"laisser","pos":"VER"} ,
		{"word":"laisseront","word_nosc":"laisseront","lemma":"laisser","pos":"VER"} ,
		{"word":"laisses","word_nosc":"laisses","lemma":"laisser","pos":"VER"} ,
		{"word":"laissez","word_nosc":"laissez","lemma":"laisser","pos":"VER"} ,
		{"word":"laissiez","word_nosc":"laissiez","lemma":"laisser","pos":"VER"} ,
		{"word":"laissions","word_nosc":"laissions","lemma":"laisser","pos":"VER"} ,
		{"word":"laissons","word_nosc":"laissons","lemma":"laisser","pos":"VER"} ,
		{"word":"laissâmes","word_nosc":"laissames","lemma":"laisser","pos":"VER"} ,
		{"word":"laissât","word_nosc":"laissat","lemma":"laisser","pos":"VER"} ,
		{"word":"laissèrent","word_nosc":"laisserent","lemma":"laisser","pos":"VER"} ,
		{"word":"laissé","word_nosc":"laisse","lemma":"laisser","pos":"VER"} ,
		{"word":"laissée","word_nosc":"laissee","lemma":"laisser","pos":"VER"} ,
		{"word":"laissées","word_nosc":"laissees","lemma":"laisser","pos":"VER"} ,
		{"word":"laissés","word_nosc":"laisses","lemma":"laisser","pos":"VER"} ,
		{"word":"lamais","word_nosc":"lamais","lemma":"lamer","pos":"VER"} ,
		{"word":"lamas","word_nosc":"lamas","lemma":"lamer","pos":"VER"} ,
		{"word":"lambinaient","word_nosc":"lambinaient","lemma":"lambiner","pos":"VER"} ,
		{"word":"lambinais","word_nosc":"lambinais","lemma":"lambiner","pos":"VER"} ,
		{"word":"lambinait","word_nosc":"lambinait","lemma":"lambiner","pos":"VER"} ,
		{"word":"lambinant","word_nosc":"lambinant","lemma":"lambiner","pos":"VER"} ,
		{"word":"lambine","word_nosc":"lambine","lemma":"lambiner","pos":"VER"} ,
		{"word":"lambiner","word_nosc":"lambiner","lemma":"lambiner","pos":"VER"} ,
		{"word":"lambines","word_nosc":"lambines","lemma":"lambiner","pos":"VER"} ,
		{"word":"lambinez","word_nosc":"lambinez","lemma":"lambiner","pos":"VER"} ,
		{"word":"lambiniez","word_nosc":"lambiniez","lemma":"lambiner","pos":"VER"} ,
		{"word":"lambinions","word_nosc":"lambinions","lemma":"lambiner","pos":"VER"} ,
		{"word":"lambinons","word_nosc":"lambinons","lemma":"lambiner","pos":"VER"} ,
		{"word":"lambiné","word_nosc":"lambine","lemma":"lambiner","pos":"VER"} ,
		{"word":"lambrissé","word_nosc":"lambrisse","lemma":"lambrisser","pos":"VER"} ,
		{"word":"lambrissée","word_nosc":"lambrissee","lemma":"lambrisser","pos":"VER"} ,
		{"word":"lambrissées","word_nosc":"lambrissees","lemma":"lambrisser","pos":"VER"} ,
		{"word":"lamenta","word_nosc":"lamenta","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamentaient","word_nosc":"lamentaient","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamentais","word_nosc":"lamentais","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamentait","word_nosc":"lamentait","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamentant","word_nosc":"lamentant","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamente","word_nosc":"lamente","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamentent","word_nosc":"lamentent","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamenter","word_nosc":"lamenter","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamentera","word_nosc":"lamentera","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamenterait","word_nosc":"lamenterait","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamenteront","word_nosc":"lamenteront","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamentez","word_nosc":"lamentez","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamentions","word_nosc":"lamentions","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamentons","word_nosc":"lamentons","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamentèrent","word_nosc":"lamenterent","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamenté","word_nosc":"lamente","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamentés","word_nosc":"lamentes","lemma":"lamenter","pos":"VER"} ,
		{"word":"lamer","word_nosc":"lamer","lemma":"lamer","pos":"VER"} ,
		{"word":"laminait","word_nosc":"laminait","lemma":"laminer","pos":"VER"} ,
		{"word":"laminant","word_nosc":"laminant","lemma":"laminer","pos":"VER"} ,
		{"word":"lamine","word_nosc":"lamine","lemma":"laminer","pos":"VER"} ,
		{"word":"laminer","word_nosc":"laminer","lemma":"laminer","pos":"VER"} ,
		{"word":"lamineront","word_nosc":"lamineront","lemma":"laminer","pos":"VER"} ,
		{"word":"lamines","word_nosc":"lamines","lemma":"laminer","pos":"VER"} ,
		{"word":"laminé","word_nosc":"lamine","lemma":"laminer","pos":"VER"} ,
		{"word":"laminée","word_nosc":"laminee","lemma":"laminer","pos":"VER"} ,
		{"word":"laminées","word_nosc":"laminees","lemma":"laminer","pos":"VER"} ,
		{"word":"laminés","word_nosc":"lamines","lemma":"laminer","pos":"VER"} ,
		{"word":"lampa","word_nosc":"lampa","lemma":"lamper","pos":"VER"} ,
		{"word":"lampais","word_nosc":"lampais","lemma":"lamper","pos":"VER"} ,
		{"word":"lampait","word_nosc":"lampait","lemma":"lamper","pos":"VER"} ,
		{"word":"lampant","word_nosc":"lampant","lemma":"lamper","pos":"VER"} ,
		{"word":"lampe","word_nosc":"lampe","lemma":"lamper","pos":"VER"} ,
		{"word":"lampent","word_nosc":"lampent","lemma":"lamper","pos":"VER"} ,
		{"word":"lamper","word_nosc":"lamper","lemma":"lamper","pos":"VER"} ,
		{"word":"lampez","word_nosc":"lampez","lemma":"lamper","pos":"VER"} ,
		{"word":"lampé","word_nosc":"lampe","lemma":"lamper","pos":"VER"} ,
		{"word":"lampée","word_nosc":"lampee","lemma":"lamper","pos":"VER"} ,
		{"word":"lamées","word_nosc":"lamees","lemma":"lamer","pos":"VER"} ,
		{"word":"lamés","word_nosc":"lames","lemma":"lamer","pos":"VER"} ,
		{"word":"lance","word_nosc":"lance","lemma":"lancer","pos":"VER"} ,
		{"word":"lancent","word_nosc":"lancent","lemma":"lancer","pos":"VER"} ,
		{"word":"lancequine","word_nosc":"lancequine","lemma":"lancequiner","pos":"VER"} ,
		{"word":"lancequiner","word_nosc":"lancequiner","lemma":"lancequiner","pos":"VER"} ,
		{"word":"lancer","word_nosc":"lancer","lemma":"lancer","pos":"VER"} ,
		{"word":"lancera","word_nosc":"lancera","lemma":"lancer","pos":"VER"} ,
		{"word":"lancerai","word_nosc":"lancerai","lemma":"lancer","pos":"VER"} ,
		{"word":"lanceraient","word_nosc":"lanceraient","lemma":"lancer","pos":"VER"} ,
		{"word":"lancerais","word_nosc":"lancerais","lemma":"lancer","pos":"VER"} ,
		{"word":"lancerait","word_nosc":"lancerait","lemma":"lancer","pos":"VER"} ,
		{"word":"lanceras","word_nosc":"lanceras","lemma":"lancer","pos":"VER"} ,
		{"word":"lancerez","word_nosc":"lancerez","lemma":"lancer","pos":"VER"} ,
		{"word":"lancerons","word_nosc":"lancerons","lemma":"lancer","pos":"VER"} ,
		{"word":"lanceront","word_nosc":"lanceront","lemma":"lancer","pos":"VER"} ,
		{"word":"lances","word_nosc":"lances","lemma":"lancer","pos":"VER"} ,
		{"word":"lancez","word_nosc":"lancez","lemma":"lancer","pos":"VER"} ,
		{"word":"lanciez","word_nosc":"lanciez","lemma":"lancer","pos":"VER"} ,
		{"word":"lancinait","word_nosc":"lancinait","lemma":"lanciner","pos":"VER"} ,
		{"word":"lancinant","word_nosc":"lancinant","lemma":"lanciner","pos":"VER"} ,
		{"word":"lancine","word_nosc":"lancine","lemma":"lanciner","pos":"VER"} ,
		{"word":"lanciné","word_nosc":"lancine","lemma":"lanciner","pos":"VER"} ,
		{"word":"lancions","word_nosc":"lancions","lemma":"lancer","pos":"VER"} ,
		{"word":"lancèrent","word_nosc":"lancerent","lemma":"lancer","pos":"VER"} ,
		{"word":"lancé","word_nosc":"lance","lemma":"lancer","pos":"VER"} ,
		{"word":"lancée","word_nosc":"lancee","lemma":"lancer","pos":"VER"} ,
		{"word":"lancées","word_nosc":"lancees","lemma":"lancer","pos":"VER"} ,
		{"word":"lancés","word_nosc":"lances","lemma":"lancer","pos":"VER"} ,
		{"word":"lange","word_nosc":"lange","lemma":"langer","pos":"VER"} ,
		{"word":"langeait","word_nosc":"langeait","lemma":"langer","pos":"VER"} ,
		{"word":"langeant","word_nosc":"langeant","lemma":"langer","pos":"VER"} ,
		{"word":"langer","word_nosc":"langer","lemma":"langer","pos":"VER"} ,
		{"word":"langui","word_nosc":"langui","lemma":"languir","pos":"VER"} ,
		{"word":"languir","word_nosc":"languir","lemma":"languir","pos":"VER"} ,
		{"word":"languirait","word_nosc":"languirait","lemma":"languir","pos":"VER"} ,
		{"word":"languirent","word_nosc":"languirent","lemma":"languir","pos":"VER"} ,
		{"word":"languiront","word_nosc":"languiront","lemma":"languir","pos":"VER"} ,
		{"word":"languis","word_nosc":"languis","lemma":"languir","pos":"VER"} ,
		{"word":"languissaient","word_nosc":"languissaient","lemma":"languir","pos":"VER"} ,
		{"word":"languissais","word_nosc":"languissais","lemma":"languir","pos":"VER"} ,
		{"word":"languissait","word_nosc":"languissait","lemma":"languir","pos":"VER"} ,
		{"word":"languissant","word_nosc":"languissant","lemma":"languir","pos":"VER"} ,
		{"word":"languisse","word_nosc":"languisse","lemma":"languir","pos":"VER"} ,
		{"word":"languissent","word_nosc":"languissent","lemma":"languir","pos":"VER"} ,
		{"word":"languisses","word_nosc":"languisses","lemma":"languir","pos":"VER"} ,
		{"word":"languissons","word_nosc":"languissons","lemma":"languir","pos":"VER"} ,
		{"word":"languit","word_nosc":"languit","lemma":"languir","pos":"VER"} ,
		{"word":"langé","word_nosc":"lange","lemma":"langer","pos":"VER"} ,
		{"word":"langée","word_nosc":"langee","lemma":"langer","pos":"VER"} ,
		{"word":"lansquiner","word_nosc":"lansquiner","lemma":"lansquiner","pos":"VER"} ,
		{"word":"lanternant","word_nosc":"lanternant","lemma":"lanterner","pos":"VER"} ,
		{"word":"lanterne","word_nosc":"lanterne","lemma":"lanterner","pos":"VER"} ,
		{"word":"lanterner","word_nosc":"lanterner","lemma":"lanterner","pos":"VER"} ,
		{"word":"lanternez","word_nosc":"lanternez","lemma":"lanterner","pos":"VER"} ,
		{"word":"lanterné","word_nosc":"lanterne","lemma":"lanterner","pos":"VER"} ,
		{"word":"lança","word_nosc":"lanca","lemma":"lancer","pos":"VER"} ,
		{"word":"lançai","word_nosc":"lancai","lemma":"lancer","pos":"VER"} ,
		{"word":"lançaient","word_nosc":"lancaient","lemma":"lancer","pos":"VER"} ,
		{"word":"lançais","word_nosc":"lancais","lemma":"lancer","pos":"VER"} ,
		{"word":"lançait","word_nosc":"lancait","lemma":"lancer","pos":"VER"} ,
		{"word":"lançant","word_nosc":"lancant","lemma":"lancer","pos":"VER"} ,
		{"word":"lanças","word_nosc":"lancas","lemma":"lancer","pos":"VER"} ,
		{"word":"lançons","word_nosc":"lancons","lemma":"lancer","pos":"VER"} ,
		{"word":"lançâmes","word_nosc":"lancames","lemma":"lancer","pos":"VER"} ,
		{"word":"lançât","word_nosc":"lancat","lemma":"lancer","pos":"VER"} ,
		{"word":"lapais","word_nosc":"lapais","lemma":"laper","pos":"VER"} ,
		{"word":"lapait","word_nosc":"lapait","lemma":"laper","pos":"VER"} ,
		{"word":"lapant","word_nosc":"lapant","lemma":"laper","pos":"VER"} ,
		{"word":"lape","word_nosc":"lape","lemma":"laper","pos":"VER"} ,
		{"word":"lapent","word_nosc":"lapent","lemma":"laper","pos":"VER"} ,
		{"word":"laper","word_nosc":"laper","lemma":"laper","pos":"VER"} ,
		{"word":"lapida","word_nosc":"lapida","lemma":"lapider","pos":"VER"} ,
		{"word":"lapidait","word_nosc":"lapidait","lemma":"lapider","pos":"VER"} ,
		{"word":"lapidant","word_nosc":"lapidant","lemma":"lapider","pos":"VER"} ,
		{"word":"lapide","word_nosc":"lapide","lemma":"lapider","pos":"VER"} ,
		{"word":"lapident","word_nosc":"lapident","lemma":"lapider","pos":"VER"} ,
		{"word":"lapider","word_nosc":"lapider","lemma":"lapider","pos":"VER"} ,
		{"word":"lapiderait","word_nosc":"lapiderait","lemma":"lapider","pos":"VER"} ,
		{"word":"lapideras","word_nosc":"lapideras","lemma":"lapider","pos":"VER"} ,
		{"word":"lapidez","word_nosc":"lapidez","lemma":"lapider","pos":"VER"} ,
		{"word":"lapidifiés","word_nosc":"lapidifies","lemma":"lapidifier","pos":"VER"} ,
		{"word":"lapidèrent","word_nosc":"lapiderent","lemma":"lapider","pos":"VER"} ,
		{"word":"lapidé","word_nosc":"lapide","lemma":"lapider","pos":"VER"} ,
		{"word":"lapidée","word_nosc":"lapidee","lemma":"lapider","pos":"VER"} ,
		{"word":"lapidés","word_nosc":"lapides","lemma":"lapider","pos":"VER"} ,
		{"word":"lapiner","word_nosc":"lapiner","lemma":"lapiner","pos":"VER"} ,
		{"word":"lappant","word_nosc":"lappant","lemma":"lapper","pos":"VER"} ,
		{"word":"lapper","word_nosc":"lapper","lemma":"lapper","pos":"VER"} ,
		{"word":"lappé","word_nosc":"lappe","lemma":"lapper","pos":"VER"} ,
		{"word":"lapé","word_nosc":"lape","lemma":"laper","pos":"VER"} ,
		{"word":"lapées","word_nosc":"lapees","lemma":"laper","pos":"VER"} ,
		{"word":"laquaient","word_nosc":"laquaient","lemma":"laquer","pos":"VER"} ,
		{"word":"laquait","word_nosc":"laquait","lemma":"laquer","pos":"VER"} ,
		{"word":"laque","word_nosc":"laque","lemma":"laquer","pos":"VER"} ,
		{"word":"laquer","word_nosc":"laquer","lemma":"laquer","pos":"VER"} ,
		{"word":"laquerai","word_nosc":"laquerai","lemma":"laquer","pos":"VER"} ,
		{"word":"laqué","word_nosc":"laque","lemma":"laquer","pos":"VER"} ,
		{"word":"laquée","word_nosc":"laquee","lemma":"laquer","pos":"VER"} ,
		{"word":"laquées","word_nosc":"laquees","lemma":"laquer","pos":"VER"} ,
		{"word":"laqués","word_nosc":"laques","lemma":"laquer","pos":"VER"} ,
		{"word":"larda","word_nosc":"larda","lemma":"larder","pos":"VER"} ,
		{"word":"lardais","word_nosc":"lardais","lemma":"larder","pos":"VER"} ,
		{"word":"lardait","word_nosc":"lardait","lemma":"larder","pos":"VER"} ,
		{"word":"lardant","word_nosc":"lardant","lemma":"larder","pos":"VER"} ,
		{"word":"larde","word_nosc":"larde","lemma":"larder","pos":"VER"} ,
		{"word":"larder","word_nosc":"larder","lemma":"larder","pos":"VER"} ,
		{"word":"lardons","word_nosc":"lardons","lemma":"larder","pos":"VER"} ,
		{"word":"lardé","word_nosc":"larde","lemma":"larder","pos":"VER"} ,
		{"word":"lardée","word_nosc":"lardee","lemma":"larder","pos":"VER"} ,
		{"word":"lardées","word_nosc":"lardees","lemma":"larder","pos":"VER"} ,
		{"word":"largua","word_nosc":"largua","lemma":"larguer","pos":"VER"} ,
		{"word":"larguaient","word_nosc":"larguaient","lemma":"larguer","pos":"VER"} ,
		{"word":"larguais","word_nosc":"larguais","lemma":"larguer","pos":"VER"} ,
		{"word":"larguait","word_nosc":"larguait","lemma":"larguer","pos":"VER"} ,
		{"word":"larguant","word_nosc":"larguant","lemma":"larguer","pos":"VER"} ,
		{"word":"largue","word_nosc":"largue","lemma":"larguer","pos":"VER"} ,
		{"word":"larguent","word_nosc":"larguent","lemma":"larguer","pos":"VER"} ,
		{"word":"larguer","word_nosc":"larguer","lemma":"larguer","pos":"VER"} ,
		{"word":"larguera","word_nosc":"larguera","lemma":"larguer","pos":"VER"} ,
		{"word":"larguerai","word_nosc":"larguerai","lemma":"larguer","pos":"VER"} ,
		{"word":"larguerait","word_nosc":"larguerait","lemma":"larguer","pos":"VER"} ,
		{"word":"larguerez","word_nosc":"larguerez","lemma":"larguer","pos":"VER"} ,
		{"word":"largueront","word_nosc":"largueront","lemma":"larguer","pos":"VER"} ,
		{"word":"largues","word_nosc":"largues","lemma":"larguer","pos":"VER"} ,
		{"word":"larguez","word_nosc":"larguez","lemma":"larguer","pos":"VER"} ,
		{"word":"larguiez","word_nosc":"larguiez","lemma":"larguer","pos":"VER"} ,
		{"word":"larguons","word_nosc":"larguons","lemma":"larguer","pos":"VER"} ,
		{"word":"largué","word_nosc":"largue","lemma":"larguer","pos":"VER"} ,
		{"word":"larguée","word_nosc":"larguee","lemma":"larguer","pos":"VER"} ,
		{"word":"larguées","word_nosc":"larguees","lemma":"larguer","pos":"VER"} ,
		{"word":"largués","word_nosc":"largues","lemma":"larguer","pos":"VER"} ,
		{"word":"larmoie","word_nosc":"larmoie","lemma":"larmoyer","pos":"VER"} ,
		{"word":"larmoient","word_nosc":"larmoient","lemma":"larmoyer","pos":"VER"} ,
		{"word":"larmoyait","word_nosc":"larmoyait","lemma":"larmoyer","pos":"VER"} ,
		{"word":"larmoyant","word_nosc":"larmoyant","lemma":"larmoyer","pos":"VER"} ,
		{"word":"larmoyer","word_nosc":"larmoyer","lemma":"larmoyer","pos":"VER"} ,
		{"word":"larmoyons","word_nosc":"larmoyons","lemma":"larmoyer","pos":"VER"} ,
		{"word":"larmoyé","word_nosc":"larmoye","lemma":"larmoyer","pos":"VER"} ,
		{"word":"larronne","word_nosc":"larronne","lemma":"larronner","pos":"VER"} ,
		{"word":"lassa","word_nosc":"lassa","lemma":"lasser","pos":"VER"} ,
		{"word":"lassai","word_nosc":"lassai","lemma":"lasser","pos":"VER"} ,
		{"word":"lassaient","word_nosc":"lassaient","lemma":"lasser","pos":"VER"} ,
		{"word":"lassais","word_nosc":"lassais","lemma":"lasser","pos":"VER"} ,
		{"word":"lassait","word_nosc":"lassait","lemma":"lasser","pos":"VER"} ,
		{"word":"lassant","word_nosc":"lassant","lemma":"lasser","pos":"VER"} ,
		{"word":"lasse","word_nosc":"lasse","lemma":"lasser","pos":"VER"} ,
		{"word":"lassent","word_nosc":"lassent","lemma":"lasser","pos":"VER"} ,
		{"word":"lasser","word_nosc":"lasser","lemma":"lasser","pos":"VER"} ,
		{"word":"lassera","word_nosc":"lassera","lemma":"lasser","pos":"VER"} ,
		{"word":"lasserai","word_nosc":"lasserai","lemma":"lasser","pos":"VER"} ,
		{"word":"lasseraient","word_nosc":"lasseraient","lemma":"lasser","pos":"VER"} ,
		{"word":"lasserais","word_nosc":"lasserais","lemma":"lasser","pos":"VER"} ,
		{"word":"lasserait","word_nosc":"lasserait","lemma":"lasser","pos":"VER"} ,
		{"word":"lasserez","word_nosc":"lasserez","lemma":"lasser","pos":"VER"} ,
		{"word":"lasseront","word_nosc":"lasseront","lemma":"lasser","pos":"VER"} ,
		{"word":"lasses","word_nosc":"lasses","lemma":"lasser","pos":"VER"} ,
		{"word":"lassez","word_nosc":"lassez","lemma":"lasser","pos":"VER"} ,
		{"word":"lassons","word_nosc":"lassons","lemma":"lasser","pos":"VER"} ,
		{"word":"lassèrent","word_nosc":"lasserent","lemma":"lasser","pos":"VER"} ,
		{"word":"lassé","word_nosc":"lasse","lemma":"lasser","pos":"VER"} ,
		{"word":"lassée","word_nosc":"lassee","lemma":"lasser","pos":"VER"} ,
		{"word":"lassées","word_nosc":"lassees","lemma":"lasser","pos":"VER"} ,
		{"word":"lassés","word_nosc":"lasses","lemma":"lasser","pos":"VER"} ,
		{"word":"latinisaient","word_nosc":"latinisaient","lemma":"latiniser","pos":"VER"} ,
		{"word":"latinisé","word_nosc":"latinise","lemma":"latiniser","pos":"VER"} ,
		{"word":"latinisée","word_nosc":"latinisee","lemma":"latiniser","pos":"VER"} ,
		{"word":"latte","word_nosc":"latte","lemma":"latter","pos":"VER"} ,
		{"word":"latter","word_nosc":"latter","lemma":"latter","pos":"VER"} ,
		{"word":"lattes","word_nosc":"lattes","lemma":"latter","pos":"VER"} ,
		{"word":"latté","word_nosc":"latte","lemma":"latter","pos":"VER"} ,
		{"word":"lattés","word_nosc":"lattes","lemma":"latter","pos":"VER"} ,
		{"word":"lava","word_nosc":"lava","lemma":"laver","pos":"VER"} ,
		{"word":"lavai","word_nosc":"lavai","lemma":"laver","pos":"VER"} ,
		{"word":"lavaient","word_nosc":"lavaient","lemma":"laver","pos":"VER"} ,
		{"word":"lavais","word_nosc":"lavais","lemma":"laver","pos":"VER"} ,
		{"word":"lavait","word_nosc":"lavait","lemma":"laver","pos":"VER"} ,
		{"word":"lavant","word_nosc":"lavant","lemma":"laver","pos":"VER"} ,
		{"word":"lavasse","word_nosc":"lavasse","lemma":"laver","pos":"VER"} ,
		{"word":"lave","word_nosc":"lave","lemma":"laver","pos":"VER"} ,
		{"word":"lavent","word_nosc":"lavent","lemma":"laver","pos":"VER"} ,
		{"word":"laver","word_nosc":"laver","lemma":"laver","pos":"VER"} ,
		{"word":"lavera","word_nosc":"lavera","lemma":"laver","pos":"VER"} ,
		{"word":"laverai","word_nosc":"laverai","lemma":"laver","pos":"VER"} ,
		{"word":"laverais","word_nosc":"laverais","lemma":"laver","pos":"VER"} ,
		{"word":"laverait","word_nosc":"laverait","lemma":"laver","pos":"VER"} ,
		{"word":"laveras","word_nosc":"laveras","lemma":"laver","pos":"VER"} ,
		{"word":"laverez","word_nosc":"laverez","lemma":"laver","pos":"VER"} ,
		{"word":"laverions","word_nosc":"laverions","lemma":"laver","pos":"VER"} ,
		{"word":"laverons","word_nosc":"laverons","lemma":"laver","pos":"VER"} ,
		{"word":"laveront","word_nosc":"laveront","lemma":"laver","pos":"VER"} ,
		{"word":"laves","word_nosc":"laves","lemma":"laver","pos":"VER"} ,
		{"word":"lavez","word_nosc":"lavez","lemma":"laver","pos":"VER"} ,
		{"word":"lavions","word_nosc":"lavions","lemma":"laver","pos":"VER"} ,
		{"word":"lavons","word_nosc":"lavons","lemma":"laver","pos":"VER"} ,
		{"word":"lavèrent","word_nosc":"laverent","lemma":"laver","pos":"VER"} ,
		{"word":"lavé","word_nosc":"lave","lemma":"laver","pos":"VER"} ,
		{"word":"lavée","word_nosc":"lavee","lemma":"laver","pos":"VER"} ,
		{"word":"lavées","word_nosc":"lavees","lemma":"laver","pos":"VER"} ,
		{"word":"lavés","word_nosc":"laves","lemma":"laver","pos":"VER"} ,
		{"word":"laya","word_nosc":"laya","lemma":"layer","pos":"VER"} ,
		{"word":"layer","word_nosc":"layer","lemma":"layer","pos":"VER"} ,
		{"word":"laça","word_nosc":"laca","lemma":"lacer","pos":"VER"} ,
		{"word":"laçais","word_nosc":"lacais","lemma":"lacer","pos":"VER"} ,
		{"word":"laçait","word_nosc":"lacait","lemma":"lacer","pos":"VER"} ,
		{"word":"laçant","word_nosc":"lacant","lemma":"lacer","pos":"VER"} ,
		{"word":"laïciser","word_nosc":"laiciser","lemma":"laïciser","pos":"VER"} ,
		{"word":"laïusse","word_nosc":"laiusse","lemma":"laïusser","pos":"VER"} ,
		{"word":"lessiva","word_nosc":"lessiva","lemma":"lessiver","pos":"VER"} ,
		{"word":"lessivaient","word_nosc":"lessivaient","lemma":"lessiver","pos":"VER"} ,
		{"word":"lessivait","word_nosc":"lessivait","lemma":"lessiver","pos":"VER"} ,
		{"word":"lessivant","word_nosc":"lessivant","lemma":"lessiver","pos":"VER"} ,
		{"word":"lessive","word_nosc":"lessive","lemma":"lessiver","pos":"VER"} ,
		{"word":"lessiver","word_nosc":"lessiver","lemma":"lessiver","pos":"VER"} ,
		{"word":"lessiverait","word_nosc":"lessiverait","lemma":"lessiver","pos":"VER"} ,
		{"word":"lessivons","word_nosc":"lessivons","lemma":"lessiver","pos":"VER"} ,
		{"word":"lessivé","word_nosc":"lessive","lemma":"lessiver","pos":"VER"} ,
		{"word":"lessivée","word_nosc":"lessivee","lemma":"lessiver","pos":"VER"} ,
		{"word":"lessivés","word_nosc":"lessives","lemma":"lessiver","pos":"VER"} ,
		{"word":"leste","word_nosc":"leste","lemma":"lester","pos":"VER"} ,
		{"word":"lester","word_nosc":"lester","lemma":"lester","pos":"VER"} ,
		{"word":"lestera","word_nosc":"lestera","lemma":"lester","pos":"VER"} ,
		{"word":"lesterait","word_nosc":"lesterait","lemma":"lester","pos":"VER"} ,
		{"word":"lestes","word_nosc":"lestes","lemma":"lester","pos":"VER"} ,
		{"word":"lestez","word_nosc":"lestez","lemma":"lester","pos":"VER"} ,
		{"word":"lesté","word_nosc":"leste","lemma":"lester","pos":"VER"} ,
		{"word":"lestée","word_nosc":"lestee","lemma":"lester","pos":"VER"} ,
		{"word":"lestées","word_nosc":"lestees","lemma":"lester","pos":"VER"} ,
		{"word":"lestés","word_nosc":"lestes","lemma":"lester","pos":"VER"} ,
		{"word":"leurrais","word_nosc":"leurrais","lemma":"leurrer","pos":"VER"} ,
		{"word":"leurrait","word_nosc":"leurrait","lemma":"leurrer","pos":"VER"} ,
		{"word":"leurre","word_nosc":"leurre","lemma":"leurrer","pos":"VER"} ,
		{"word":"leurrent","word_nosc":"leurrent","lemma":"leurrer","pos":"VER"} ,
		{"word":"leurrer","word_nosc":"leurrer","lemma":"leurrer","pos":"VER"} ,
		{"word":"leurres","word_nosc":"leurres","lemma":"leurrer","pos":"VER"} ,
		{"word":"leurrez","word_nosc":"leurrez","lemma":"leurrer","pos":"VER"} ,
		{"word":"leurrons","word_nosc":"leurrons","lemma":"leurrer","pos":"VER"} ,
		{"word":"leurré","word_nosc":"leurre","lemma":"leurrer","pos":"VER"} ,
		{"word":"leurrés","word_nosc":"leurres","lemma":"leurrer","pos":"VER"} ,
		{"word":"leva","word_nosc":"leva","lemma":"lever","pos":"VER"} ,
		{"word":"levai","word_nosc":"levai","lemma":"lever","pos":"VER"} ,
		{"word":"levaient","word_nosc":"levaient","lemma":"lever","pos":"VER"} ,
		{"word":"levais","word_nosc":"levais","lemma":"lever","pos":"VER"} ,
		{"word":"levait","word_nosc":"levait","lemma":"lever","pos":"VER"} ,
		{"word":"levant","word_nosc":"levant","lemma":"lever","pos":"VER"} ,
		{"word":"lever","word_nosc":"lever","lemma":"lever","pos":"VER"} ,
		{"word":"levez","word_nosc":"levez","lemma":"lever","pos":"VER"} ,
		{"word":"leviez","word_nosc":"leviez","lemma":"lever","pos":"VER"} ,
		{"word":"levions","word_nosc":"levions","lemma":"lever","pos":"VER"} ,
		{"word":"levons","word_nosc":"levons","lemma":"lever","pos":"VER"} ,
		{"word":"levâmes","word_nosc":"levames","lemma":"lever","pos":"VER"} ,
		{"word":"levât","word_nosc":"levat","lemma":"lever","pos":"VER"} ,
		{"word":"levèrent","word_nosc":"leverent","lemma":"lever","pos":"VER"} ,
		{"word":"levé","word_nosc":"leve","lemma":"lever","pos":"VER"} ,
		{"word":"levée","word_nosc":"levee","lemma":"lever","pos":"VER"} ,
		{"word":"levées","word_nosc":"levees","lemma":"lever","pos":"VER"} ,
		{"word":"levés","word_nosc":"leves","lemma":"lever","pos":"VER"} ,
		{"word":"lia","word_nosc":"lia","lemma":"lier","pos":"VER"} ,
		{"word":"liai","word_nosc":"liai","lemma":"lier","pos":"VER"} ,
		{"word":"liaient","word_nosc":"liaient","lemma":"lier","pos":"VER"} ,
		{"word":"liais","word_nosc":"liais","lemma":"lier","pos":"VER"} ,
		{"word":"liait","word_nosc":"liait","lemma":"lier","pos":"VER"} ,
		{"word":"liant","word_nosc":"liant","lemma":"lier","pos":"VER"} ,
		{"word":"liardais","word_nosc":"liardais","lemma":"liarder","pos":"VER"} ,
		{"word":"liasse","word_nosc":"liasse","lemma":"lier","pos":"VER"} ,
		{"word":"libeller","word_nosc":"libeller","lemma":"libeller","pos":"VER"} ,
		{"word":"libellé","word_nosc":"libelle","lemma":"libeller","pos":"VER"} ,
		{"word":"libellée","word_nosc":"libellee","lemma":"libeller","pos":"VER"} ,
		{"word":"libellées","word_nosc":"libellees","lemma":"libeller","pos":"VER"} ,
		{"word":"libère","word_nosc":"libere","lemma":"libérer","pos":"VER"} ,
		{"word":"libèrent","word_nosc":"liberent","lemma":"libérer","pos":"VER"} ,
		{"word":"libères","word_nosc":"liberes","lemma":"libérer","pos":"VER"} ,
		{"word":"libéra","word_nosc":"libera","lemma":"libérer","pos":"VER"} ,
		{"word":"libérai","word_nosc":"liberai","lemma":"libérer","pos":"VER"} ,
		{"word":"libéraient","word_nosc":"liberaient","lemma":"libérer","pos":"VER"} ,
		{"word":"libérais","word_nosc":"liberais","lemma":"libérer","pos":"VER"} ,
		{"word":"libérait","word_nosc":"liberait","lemma":"libérer","pos":"VER"} ,
		{"word":"libérant","word_nosc":"liberant","lemma":"libérer","pos":"VER"} ,
		{"word":"libérer","word_nosc":"liberer","lemma":"libérer","pos":"VER"} ,
		{"word":"libérera","word_nosc":"liberera","lemma":"libérer","pos":"VER"} ,
		{"word":"libérerai","word_nosc":"libererai","lemma":"libérer","pos":"VER"} ,
		{"word":"libéreraient","word_nosc":"libereraient","lemma":"libérer","pos":"VER"} ,
		{"word":"libérerais","word_nosc":"libererais","lemma":"libérer","pos":"VER"} ,
		{"word":"libérerait","word_nosc":"libererait","lemma":"libérer","pos":"VER"} ,
		{"word":"libéreras","word_nosc":"libereras","lemma":"libérer","pos":"VER"} ,
		{"word":"libérerez","word_nosc":"libererez","lemma":"libérer","pos":"VER"} ,
		{"word":"libérerons","word_nosc":"libererons","lemma":"libérer","pos":"VER"} ,
		{"word":"libéreront","word_nosc":"libereront","lemma":"libérer","pos":"VER"} ,
		{"word":"libérez","word_nosc":"liberez","lemma":"libérer","pos":"VER"} ,
		{"word":"libériez","word_nosc":"liberiez","lemma":"libérer","pos":"VER"} ,
		{"word":"libérions","word_nosc":"liberions","lemma":"libérer","pos":"VER"} ,
		{"word":"libérons","word_nosc":"liberons","lemma":"libérer","pos":"VER"} ,
		{"word":"libérât","word_nosc":"liberat","lemma":"libérer","pos":"VER"} ,
		{"word":"libérèrent","word_nosc":"libererent","lemma":"libérer","pos":"VER"} ,
		{"word":"libéré","word_nosc":"libere","lemma":"libérer","pos":"VER"} ,
		{"word":"libérée","word_nosc":"liberee","lemma":"libérer","pos":"VER"} ,
		{"word":"libérées","word_nosc":"liberees","lemma":"libérer","pos":"VER"} ,
		{"word":"libérés","word_nosc":"liberes","lemma":"libérer","pos":"VER"} ,
		{"word":"licencia","word_nosc":"licencia","lemma":"licencier","pos":"VER"} ,
		{"word":"licenciai","word_nosc":"licenciai","lemma":"licencier","pos":"VER"} ,
		{"word":"licenciaient","word_nosc":"licenciaient","lemma":"licencier","pos":"VER"} ,
		{"word":"licenciait","word_nosc":"licenciait","lemma":"licencier","pos":"VER"} ,
		{"word":"licenciant","word_nosc":"licenciant","lemma":"licencier","pos":"VER"} ,
		{"word":"licencie","word_nosc":"licencie","lemma":"licencier","pos":"VER"} ,
		{"word":"licencient","word_nosc":"licencient","lemma":"licencier","pos":"VER"} ,
		{"word":"licencier","word_nosc":"licencier","lemma":"licencier","pos":"VER"} ,
		{"word":"licenciera","word_nosc":"licenciera","lemma":"licencier","pos":"VER"} ,
		{"word":"licenciez","word_nosc":"licenciez","lemma":"licencier","pos":"VER"} ,
		{"word":"licencié","word_nosc":"licencie","lemma":"licencier","pos":"VER"} ,
		{"word":"licenciée","word_nosc":"licenciee","lemma":"licencier","pos":"VER"} ,
		{"word":"licenciés","word_nosc":"licencies","lemma":"licencier","pos":"VER"} ,
		{"word":"lichant","word_nosc":"lichant","lemma":"licher","pos":"VER"} ,
		{"word":"lichas","word_nosc":"lichas","lemma":"licher","pos":"VER"} ,
		{"word":"licher","word_nosc":"licher","lemma":"licher","pos":"VER"} ,
		{"word":"lichotter","word_nosc":"lichotter","lemma":"lichotter","pos":"VER"} ,
		{"word":"liché","word_nosc":"liche","lemma":"licher","pos":"VER"} ,
		{"word":"lichée","word_nosc":"lichee","lemma":"licher","pos":"VER"} ,
		{"word":"lichées","word_nosc":"lichees","lemma":"licher","pos":"VER"} ,
		{"word":"lie","word_nosc":"lie","lemma":"lier","pos":"VER"} ,
		{"word":"lient","word_nosc":"lient","lemma":"lier","pos":"VER"} ,
		{"word":"lier","word_nosc":"lier","lemma":"lier","pos":"VER"} ,
		{"word":"liera","word_nosc":"liera","lemma":"lier","pos":"VER"} ,
		{"word":"lierais","word_nosc":"lierais","lemma":"lier","pos":"VER"} ,
		{"word":"lierait","word_nosc":"lierait","lemma":"lier","pos":"VER"} ,
		{"word":"lieras","word_nosc":"lieras","lemma":"lier","pos":"VER"} ,
		{"word":"lieront","word_nosc":"lieront","lemma":"lier","pos":"VER"} ,
		{"word":"lies","word_nosc":"lies","lemma":"lier","pos":"VER"} ,
		{"word":"liez","word_nosc":"liez","lemma":"lier","pos":"VER"} ,
		{"word":"lifter","word_nosc":"lifter","lemma":"lifter","pos":"VER"} ,
		{"word":"liftée","word_nosc":"liftee","lemma":"lifter","pos":"VER"} ,
		{"word":"liftées","word_nosc":"liftees","lemma":"lifter","pos":"VER"} ,
		{"word":"ligaturer","word_nosc":"ligaturer","lemma":"ligaturer","pos":"VER"} ,
		{"word":"ligaturez","word_nosc":"ligaturez","lemma":"ligaturer","pos":"VER"} ,
		{"word":"ligaturé","word_nosc":"ligature","lemma":"ligaturer","pos":"VER"} ,
		{"word":"ligaturée","word_nosc":"ligaturee","lemma":"ligaturer","pos":"VER"} ,
		{"word":"ligna","word_nosc":"ligna","lemma":"ligner","pos":"VER"} ,
		{"word":"ligner","word_nosc":"ligner","lemma":"ligner","pos":"VER"} ,
		{"word":"ligné","word_nosc":"ligne","lemma":"ligner","pos":"VER"} ,
		{"word":"lignée","word_nosc":"lignee","lemma":"ligner","pos":"VER"} ,
		{"word":"lignées","word_nosc":"lignees","lemma":"ligner","pos":"VER"} ,
		{"word":"ligota","word_nosc":"ligota","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligotaient","word_nosc":"ligotaient","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligotait","word_nosc":"ligotait","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligotant","word_nosc":"ligotant","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligote","word_nosc":"ligote","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligotent","word_nosc":"ligotent","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligoter","word_nosc":"ligoter","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligoterai","word_nosc":"ligoterai","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligoterait","word_nosc":"ligoterait","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligoterons","word_nosc":"ligoterons","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligotez","word_nosc":"ligotez","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligotons","word_nosc":"ligotons","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligotèrent","word_nosc":"ligoterent","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligoté","word_nosc":"ligote","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligotée","word_nosc":"ligotee","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligotées","word_nosc":"ligotees","lemma":"ligoter","pos":"VER"} ,
		{"word":"ligotés","word_nosc":"ligotes","lemma":"ligoter","pos":"VER"} ,
		{"word":"liguaient","word_nosc":"liguaient","lemma":"liguer","pos":"VER"} ,
		{"word":"liguait","word_nosc":"liguait","lemma":"liguer","pos":"VER"} ,
		{"word":"liguant","word_nosc":"liguant","lemma":"liguer","pos":"VER"} ,
		{"word":"ligue","word_nosc":"ligue","lemma":"liguer","pos":"VER"} ,
		{"word":"liguent","word_nosc":"liguent","lemma":"liguer","pos":"VER"} ,
		{"word":"liguer","word_nosc":"liguer","lemma":"liguer","pos":"VER"} ,
		{"word":"ligues","word_nosc":"ligues","lemma":"liguer","pos":"VER"} ,
		{"word":"liguez","word_nosc":"liguez","lemma":"liguer","pos":"VER"} ,
		{"word":"liguèrent","word_nosc":"liguerent","lemma":"liguer","pos":"VER"} ,
		{"word":"ligué","word_nosc":"ligue","lemma":"liguer","pos":"VER"} ,
		{"word":"liguées","word_nosc":"liguees","lemma":"liguer","pos":"VER"} ,
		{"word":"ligués","word_nosc":"ligues","lemma":"liguer","pos":"VER"} ,
		{"word":"lima","word_nosc":"lima","lemma":"limer","pos":"VER"} ,
		{"word":"limaient","word_nosc":"limaient","lemma":"limer","pos":"VER"} ,
		{"word":"limait","word_nosc":"limait","lemma":"limer","pos":"VER"} ,
		{"word":"limant","word_nosc":"limant","lemma":"limer","pos":"VER"} ,
		{"word":"lime","word_nosc":"lime","lemma":"limer","pos":"VER"} ,
		{"word":"liment","word_nosc":"liment","lemma":"limer","pos":"VER"} ,
		{"word":"limer","word_nosc":"limer","lemma":"limer","pos":"VER"} ,
		{"word":"limes","word_nosc":"limes","lemma":"limer","pos":"VER"} ,
		{"word":"limez","word_nosc":"limez","lemma":"limer","pos":"VER"} ,
		{"word":"limions","word_nosc":"limions","lemma":"limer","pos":"VER"} ,
		{"word":"limita","word_nosc":"limita","lemma":"limiter","pos":"VER"} ,
		{"word":"limitai","word_nosc":"limitai","lemma":"limiter","pos":"VER"} ,
		{"word":"limitaient","word_nosc":"limitaient","lemma":"limiter","pos":"VER"} ,
		{"word":"limitais","word_nosc":"limitais","lemma":"limiter","pos":"VER"} ,
		{"word":"limitait","word_nosc":"limitait","lemma":"limiter","pos":"VER"} ,
		{"word":"limitant","word_nosc":"limitant","lemma":"limiter","pos":"VER"} ,
		{"word":"limite","word_nosc":"limite","lemma":"limiter","pos":"VER"} ,
		{"word":"limitent","word_nosc":"limitent","lemma":"limiter","pos":"VER"} ,
		{"word":"limiter","word_nosc":"limiter","lemma":"limiter","pos":"VER"} ,
		{"word":"limitera","word_nosc":"limitera","lemma":"limiter","pos":"VER"} ,
		{"word":"limiterai","word_nosc":"limiterai","lemma":"limiter","pos":"VER"} ,
		{"word":"limiteraient","word_nosc":"limiteraient","lemma":"limiter","pos":"VER"} ,
		{"word":"limiterait","word_nosc":"limiterait","lemma":"limiter","pos":"VER"} ,
		{"word":"limiteront","word_nosc":"limiteront","lemma":"limiter","pos":"VER"} ,
		{"word":"limites","word_nosc":"limites","lemma":"limiter","pos":"VER"} ,
		{"word":"limitez","word_nosc":"limitez","lemma":"limiter","pos":"VER"} ,
		{"word":"limitions","word_nosc":"limitions","lemma":"limiter","pos":"VER"} ,
		{"word":"limitons","word_nosc":"limitons","lemma":"limiter","pos":"VER"} ,
		{"word":"limité","word_nosc":"limite","lemma":"limiter","pos":"VER"} ,
		{"word":"limitée","word_nosc":"limitee","lemma":"limiter","pos":"VER"} ,
		{"word":"limitées","word_nosc":"limitees","lemma":"limiter","pos":"VER"} ,
		{"word":"limités","word_nosc":"limites","lemma":"limiter","pos":"VER"} ,
		{"word":"limoge","word_nosc":"limoge","lemma":"limoger","pos":"VER"} ,
		{"word":"limoger","word_nosc":"limoger","lemma":"limoger","pos":"VER"} ,
		{"word":"limoges","word_nosc":"limoges","lemma":"limoger","pos":"VER"} ,
		{"word":"limogé","word_nosc":"limoge","lemma":"limoger","pos":"VER"} ,
		{"word":"limé","word_nosc":"lime","lemma":"limer","pos":"VER"} ,
		{"word":"limée","word_nosc":"limee","lemma":"limer","pos":"VER"} ,
		{"word":"lions","word_nosc":"lions","lemma":"lier","pos":"VER"} ,
		{"word":"liposucer","word_nosc":"liposucer","lemma":"liposucer","pos":"VER"} ,
		{"word":"liquida","word_nosc":"liquida","lemma":"liquider","pos":"VER"} ,
		{"word":"liquidai","word_nosc":"liquidai","lemma":"liquider","pos":"VER"} ,
		{"word":"liquidaient","word_nosc":"liquidaient","lemma":"liquider","pos":"VER"} ,
		{"word":"liquidait","word_nosc":"liquidait","lemma":"liquider","pos":"VER"} ,
		{"word":"liquidant","word_nosc":"liquidant","lemma":"liquider","pos":"VER"} ,
		{"word":"liquide","word_nosc":"liquide","lemma":"liquider","pos":"VER"} ,
		{"word":"liquident","word_nosc":"liquident","lemma":"liquider","pos":"VER"} ,
		{"word":"liquider","word_nosc":"liquider","lemma":"liquider","pos":"VER"} ,
		{"word":"liquidera","word_nosc":"liquidera","lemma":"liquider","pos":"VER"} ,
		{"word":"liquiderai","word_nosc":"liquiderai","lemma":"liquider","pos":"VER"} ,
		{"word":"liquiderais","word_nosc":"liquiderais","lemma":"liquider","pos":"VER"} ,
		{"word":"liquiderait","word_nosc":"liquiderait","lemma":"liquider","pos":"VER"} ,
		{"word":"liquiderons","word_nosc":"liquiderons","lemma":"liquider","pos":"VER"} ,
		{"word":"liquideront","word_nosc":"liquideront","lemma":"liquider","pos":"VER"} ,
		{"word":"liquides","word_nosc":"liquides","lemma":"liquider","pos":"VER"} ,
		{"word":"liquidez","word_nosc":"liquidez","lemma":"liquider","pos":"VER"} ,
		{"word":"liquidions","word_nosc":"liquidions","lemma":"liquider","pos":"VER"} ,
		{"word":"liquidons","word_nosc":"liquidons","lemma":"liquider","pos":"VER"} ,
		{"word":"liquidé","word_nosc":"liquide","lemma":"liquider","pos":"VER"} ,
		{"word":"liquidée","word_nosc":"liquidee","lemma":"liquider","pos":"VER"} ,
		{"word":"liquidées","word_nosc":"liquidees","lemma":"liquider","pos":"VER"} ,
		{"word":"liquidés","word_nosc":"liquides","lemma":"liquider","pos":"VER"} ,
		{"word":"liquéfiaient","word_nosc":"liquefiaient","lemma":"liquéfier","pos":"VER"} ,
		{"word":"liquéfiait","word_nosc":"liquefiait","lemma":"liquéfier","pos":"VER"} ,
		{"word":"liquéfie","word_nosc":"liquefie","lemma":"liquéfier","pos":"VER"} ,
		{"word":"liquéfient","word_nosc":"liquefient","lemma":"liquéfier","pos":"VER"} ,
		{"word":"liquéfier","word_nosc":"liquefier","lemma":"liquéfier","pos":"VER"} ,
		{"word":"liquéfièrent","word_nosc":"liquefierent","lemma":"liquéfier","pos":"VER"} ,
		{"word":"liquéfié","word_nosc":"liquefie","lemma":"liquéfier","pos":"VER"} ,
		{"word":"liquéfiée","word_nosc":"liquefiee","lemma":"liquéfier","pos":"VER"} ,
		{"word":"liquéfiées","word_nosc":"liquefiees","lemma":"liquéfier","pos":"VER"} ,
		{"word":"liquéfiés","word_nosc":"liquefies","lemma":"liquéfier","pos":"VER"} ,
		{"word":"lira","word_nosc":"lira","lemma":"lire","pos":"VER"} ,
		{"word":"lirai","word_nosc":"lirai","lemma":"lire","pos":"VER"} ,
		{"word":"liraient","word_nosc":"liraient","lemma":"lire","pos":"VER"} ,
		{"word":"lirais","word_nosc":"lirais","lemma":"lire","pos":"VER"} ,
		{"word":"lirait","word_nosc":"lirait","lemma":"lire","pos":"VER"} ,
		{"word":"liras","word_nosc":"liras","lemma":"lire","pos":"VER"} ,
		{"word":"lire","word_nosc":"lire","lemma":"lire","pos":"VER"} ,
		{"word":"lirez","word_nosc":"lirez","lemma":"lire","pos":"VER"} ,
		{"word":"liriez","word_nosc":"liriez","lemma":"lire","pos":"VER"} ,
		{"word":"lirons","word_nosc":"lirons","lemma":"lire","pos":"VER"} ,
		{"word":"liront","word_nosc":"liront","lemma":"lire","pos":"VER"} ,
		{"word":"lis","word_nosc":"lis","lemma":"lire","pos":"VER"} ,
		{"word":"lisaient","word_nosc":"lisaient","lemma":"lire","pos":"VER"} ,
		{"word":"lisais","word_nosc":"lisais","lemma":"lire","pos":"VER"} ,
		{"word":"lisait","word_nosc":"lisait","lemma":"lire","pos":"VER"} ,
		{"word":"lisant","word_nosc":"lisant","lemma":"lire","pos":"VER"} ,
		{"word":"lise","word_nosc":"lise","lemma":"lire","pos":"VER"} ,
		{"word":"lisent","word_nosc":"lisent","lemma":"lire","pos":"VER"} ,
		{"word":"liseré","word_nosc":"lisere","lemma":"liserer","pos":"VER"} ,
		{"word":"liserée","word_nosc":"liseree","lemma":"liserer","pos":"VER"} ,
		{"word":"lises","word_nosc":"lises","lemma":"lire","pos":"VER"} ,
		{"word":"lisez","word_nosc":"lisez","lemma":"lire","pos":"VER"} ,
		{"word":"lisiez","word_nosc":"lisiez","lemma":"lire","pos":"VER"} ,
		{"word":"lisions","word_nosc":"lisions","lemma":"lire","pos":"VER"} ,
		{"word":"lisons","word_nosc":"lisons","lemma":"lire","pos":"VER"} ,
		{"word":"lissa","word_nosc":"lissa","lemma":"lisser","pos":"VER"} ,
		{"word":"lissai","word_nosc":"lissai","lemma":"lisser","pos":"VER"} ,
		{"word":"lissaient","word_nosc":"lissaient","lemma":"lisser","pos":"VER"} ,
		{"word":"lissais","word_nosc":"lissais","lemma":"lisser","pos":"VER"} ,
		{"word":"lissait","word_nosc":"lissait","lemma":"lisser","pos":"VER"} ,
		{"word":"lissant","word_nosc":"lissant","lemma":"lisser","pos":"VER"} ,
		{"word":"lisse","word_nosc":"lisse","lemma":"lisser","pos":"VER"} ,
		{"word":"lissent","word_nosc":"lissent","lemma":"lisser","pos":"VER"} ,
		{"word":"lisser","word_nosc":"lisser","lemma":"lisser","pos":"VER"} ,
		{"word":"lissera","word_nosc":"lissera","lemma":"lisser","pos":"VER"} ,
		{"word":"lisserait","word_nosc":"lisserait","lemma":"lisser","pos":"VER"} ,
		{"word":"lisses","word_nosc":"lisses","lemma":"lisser","pos":"VER"} ,
		{"word":"lissez","word_nosc":"lissez","lemma":"lisser","pos":"VER"} ,
		{"word":"lissons","word_nosc":"lissons","lemma":"lisser","pos":"VER"} ,
		{"word":"lissèrent","word_nosc":"lisserent","lemma":"lisser","pos":"VER"} ,
		{"word":"lissé","word_nosc":"lisse","lemma":"lisser","pos":"VER"} ,
		{"word":"lissée","word_nosc":"lissee","lemma":"lisser","pos":"VER"} ,
		{"word":"lissées","word_nosc":"lissees","lemma":"lisser","pos":"VER"} ,
		{"word":"lissés","word_nosc":"lisses","lemma":"lisser","pos":"VER"} ,
		{"word":"lister","word_nosc":"lister","lemma":"lister","pos":"VER"} ,
		{"word":"listez","word_nosc":"listez","lemma":"lister","pos":"VER"} ,
		{"word":"listé","word_nosc":"liste","lemma":"lister","pos":"VER"} ,
		{"word":"listée","word_nosc":"listee","lemma":"lister","pos":"VER"} ,
		{"word":"listées","word_nosc":"listees","lemma":"lister","pos":"VER"} ,
		{"word":"listés","word_nosc":"listes","lemma":"lister","pos":"VER"} ,
		{"word":"lisérait","word_nosc":"liserait","lemma":"lisérer","pos":"VER"} ,
		{"word":"liséré","word_nosc":"lisere","lemma":"lisérer","pos":"VER"} ,
		{"word":"lisérés","word_nosc":"liseres","lemma":"lisérer","pos":"VER"} ,
		{"word":"lit","word_nosc":"lit","lemma":"lire","pos":"VER"} ,
		{"word":"lit-divan","word_nosc":"lit-divan","lemma":"lire","pos":"VER"} ,
		{"word":"lita","word_nosc":"lita","lemma":"liter","pos":"VER"} ,
		{"word":"lite","word_nosc":"lite","lemma":"liter","pos":"VER"} ,
		{"word":"liter","word_nosc":"liter","lemma":"liter","pos":"VER"} ,
		{"word":"lithographié","word_nosc":"lithographie","lemma":"lithographier","pos":"VER"} ,
		{"word":"litrer","word_nosc":"litrer","lemma":"litrer","pos":"VER"} ,
		{"word":"litronaient","word_nosc":"litronaient","lemma":"litroner","pos":"VER"} ,
		{"word":"litroné","word_nosc":"litrone","lemma":"litroner","pos":"VER"} ,
		{"word":"lité","word_nosc":"lite","lemma":"liter","pos":"VER"} ,
		{"word":"livra","word_nosc":"livra","lemma":"livrer","pos":"VER"} ,
		{"word":"livrai","word_nosc":"livrai","lemma":"livrer","pos":"VER"} ,
		{"word":"livraient","word_nosc":"livraient","lemma":"livrer","pos":"VER"} ,
		{"word":"livrais","word_nosc":"livrais","lemma":"livrer","pos":"VER"} ,
		{"word":"livrait","word_nosc":"livrait","lemma":"livrer","pos":"VER"} ,
		{"word":"livrant","word_nosc":"livrant","lemma":"livrer","pos":"VER"} ,
		{"word":"livre","word_nosc":"livre","lemma":"livrer","pos":"VER"} ,
		{"word":"livrent","word_nosc":"livrent","lemma":"livrer","pos":"VER"} ,
		{"word":"livrer","word_nosc":"livrer","lemma":"livrer","pos":"VER"} ,
		{"word":"livrera","word_nosc":"livrera","lemma":"livrer","pos":"VER"} ,
		{"word":"livrerai","word_nosc":"livrerai","lemma":"livrer","pos":"VER"} ,
		{"word":"livreraient","word_nosc":"livreraient","lemma":"livrer","pos":"VER"} ,
		{"word":"livrerais","word_nosc":"livrerais","lemma":"livrer","pos":"VER"} ,
		{"word":"livrerait","word_nosc":"livrerait","lemma":"livrer","pos":"VER"} ,
		{"word":"livreras","word_nosc":"livreras","lemma":"livrer","pos":"VER"} ,
		{"word":"livrerez","word_nosc":"livrerez","lemma":"livrer","pos":"VER"} ,
		{"word":"livrerons","word_nosc":"livrerons","lemma":"livrer","pos":"VER"} ,
		{"word":"livreront","word_nosc":"livreront","lemma":"livrer","pos":"VER"} ,
		{"word":"livres","word_nosc":"livres","lemma":"livrer","pos":"VER"} ,
		{"word":"livrez","word_nosc":"livrez","lemma":"livrer","pos":"VER"} ,
		{"word":"livrions","word_nosc":"livrions","lemma":"livrer","pos":"VER"} ,
		{"word":"livrons","word_nosc":"livrons","lemma":"livrer","pos":"VER"} ,
		{"word":"livrâmes","word_nosc":"livrames","lemma":"livrer","pos":"VER"} ,
		{"word":"livrât","word_nosc":"livrat","lemma":"livrer","pos":"VER"} ,
		{"word":"livrèrent","word_nosc":"livrerent","lemma":"livrer","pos":"VER"} ,
		{"word":"livré","word_nosc":"livre","lemma":"livrer","pos":"VER"} ,
		{"word":"livrée","word_nosc":"livree","lemma":"livrer","pos":"VER"} ,
		{"word":"livrées","word_nosc":"livrees","lemma":"livrer","pos":"VER"} ,
		{"word":"livrés","word_nosc":"livres","lemma":"livrer","pos":"VER"} ,
		{"word":"liâmes","word_nosc":"liames","lemma":"lier","pos":"VER"} ,
		{"word":"liât","word_nosc":"liat","lemma":"lier","pos":"VER"} ,
		{"word":"lièrent","word_nosc":"lierent","lemma":"lier","pos":"VER"} ,
		{"word":"lié","word_nosc":"lie","lemma":"lier","pos":"VER"} ,
		{"word":"liée","word_nosc":"liee","lemma":"lier","pos":"VER"} ,
		{"word":"liées","word_nosc":"liees","lemma":"lier","pos":"VER"} ,
		{"word":"liés","word_nosc":"lies","lemma":"lier","pos":"VER"} ,
		{"word":"lober","word_nosc":"lober","lemma":"lober","pos":"VER"} ,
		{"word":"lobotomiser","word_nosc":"lobotomiser","lemma":"lobotomiser","pos":"VER"} ,
		{"word":"lobotomisé","word_nosc":"lobotomise","lemma":"lobotomiser","pos":"VER"} ,
		{"word":"lobotomisée","word_nosc":"lobotomisee","lemma":"lobotomiser","pos":"VER"} ,
		{"word":"lobé","word_nosc":"lobe","lemma":"lober","pos":"VER"} ,
		{"word":"localisa","word_nosc":"localisa","lemma":"localiser","pos":"VER"} ,
		{"word":"localisaient","word_nosc":"localisaient","lemma":"localiser","pos":"VER"} ,
		{"word":"localisais","word_nosc":"localisais","lemma":"localiser","pos":"VER"} ,
		{"word":"localisait","word_nosc":"localisait","lemma":"localiser","pos":"VER"} ,
		{"word":"localisant","word_nosc":"localisant","lemma":"localiser","pos":"VER"} ,
		{"word":"localise","word_nosc":"localise","lemma":"localiser","pos":"VER"} ,
		{"word":"localisent","word_nosc":"localisent","lemma":"localiser","pos":"VER"} ,
		{"word":"localiser","word_nosc":"localiser","lemma":"localiser","pos":"VER"} ,
		{"word":"localisera","word_nosc":"localisera","lemma":"localiser","pos":"VER"} ,
		{"word":"localiseront","word_nosc":"localiseront","lemma":"localiser","pos":"VER"} ,
		{"word":"localisez","word_nosc":"localisez","lemma":"localiser","pos":"VER"} ,
		{"word":"localisions","word_nosc":"localisions","lemma":"localiser","pos":"VER"} ,
		{"word":"localisons","word_nosc":"localisons","lemma":"localiser","pos":"VER"} ,
		{"word":"localisé","word_nosc":"localise","lemma":"localiser","pos":"VER"} ,
		{"word":"localisée","word_nosc":"localisee","lemma":"localiser","pos":"VER"} ,
		{"word":"localisées","word_nosc":"localisees","lemma":"localiser","pos":"VER"} ,
		{"word":"localisés","word_nosc":"localises","lemma":"localiser","pos":"VER"} ,
		{"word":"lock-outés","word_nosc":"lock-outes","lemma":"lock-outer","pos":"VER"} ,
		{"word":"lofe","word_nosc":"lofe","lemma":"lofer","pos":"VER"} ,
		{"word":"lofez","word_nosc":"lofez","lemma":"lofer","pos":"VER"} ,
		{"word":"loge","word_nosc":"loge","lemma":"loger","pos":"VER"} ,
		{"word":"logea","word_nosc":"logea","lemma":"loger","pos":"VER"} ,
		{"word":"logeai","word_nosc":"logeai","lemma":"loger","pos":"VER"} ,
		{"word":"logeaient","word_nosc":"logeaient","lemma":"loger","pos":"VER"} ,
		{"word":"logeais","word_nosc":"logeais","lemma":"loger","pos":"VER"} ,
		{"word":"logeait","word_nosc":"logeait","lemma":"loger","pos":"VER"} ,
		{"word":"logeant","word_nosc":"logeant","lemma":"loger","pos":"VER"} ,
		{"word":"logeassent","word_nosc":"logeassent","lemma":"loger","pos":"VER"} ,
		{"word":"logent","word_nosc":"logent","lemma":"loger","pos":"VER"} ,
		{"word":"loger","word_nosc":"loger","lemma":"loger","pos":"VER"} ,
		{"word":"logera","word_nosc":"logera","lemma":"loger","pos":"VER"} ,
		{"word":"logerai","word_nosc":"logerai","lemma":"loger","pos":"VER"} ,
		{"word":"logeraient","word_nosc":"logeraient","lemma":"loger","pos":"VER"} ,
		{"word":"logerait","word_nosc":"logerait","lemma":"loger","pos":"VER"} ,
		{"word":"logeras","word_nosc":"logeras","lemma":"loger","pos":"VER"} ,
		{"word":"logerez","word_nosc":"logerez","lemma":"loger","pos":"VER"} ,
		{"word":"logerions","word_nosc":"logerions","lemma":"loger","pos":"VER"} ,
		{"word":"logerons","word_nosc":"logerons","lemma":"loger","pos":"VER"} ,
		{"word":"logeront","word_nosc":"logeront","lemma":"loger","pos":"VER"} ,
		{"word":"loges","word_nosc":"loges","lemma":"loger","pos":"VER"} ,
		{"word":"logez","word_nosc":"logez","lemma":"loger","pos":"VER"} ,
		{"word":"logiez","word_nosc":"logiez","lemma":"loger","pos":"VER"} ,
		{"word":"logions","word_nosc":"logions","lemma":"loger","pos":"VER"} ,
		{"word":"logue","word_nosc":"logue","lemma":"loguer","pos":"VER"} ,
		{"word":"logèrent","word_nosc":"logerent","lemma":"loger","pos":"VER"} ,
		{"word":"logé","word_nosc":"loge","lemma":"loger","pos":"VER"} ,
		{"word":"logée","word_nosc":"logee","lemma":"loger","pos":"VER"} ,
		{"word":"logées","word_nosc":"logees","lemma":"loger","pos":"VER"} ,
		{"word":"logés","word_nosc":"loges","lemma":"loger","pos":"VER"} ,
		{"word":"longe","word_nosc":"longe","lemma":"longer","pos":"VER"} ,
		{"word":"longea","word_nosc":"longea","lemma":"longer","pos":"VER"} ,
		{"word":"longeai","word_nosc":"longeai","lemma":"longer","pos":"VER"} ,
		{"word":"longeaient","word_nosc":"longeaient","lemma":"longer","pos":"VER"} ,
		{"word":"longeais","word_nosc":"longeais","lemma":"longer","pos":"VER"} ,
		{"word":"longeait","word_nosc":"longeait","lemma":"longer","pos":"VER"} ,
		{"word":"longeant","word_nosc":"longeant","lemma":"longer","pos":"VER"} ,
		{"word":"longent","word_nosc":"longent","lemma":"longer","pos":"VER"} ,
		{"word":"longeons","word_nosc":"longeons","lemma":"longer","pos":"VER"} ,
		{"word":"longer","word_nosc":"longer","lemma":"longer","pos":"VER"} ,
		{"word":"longera","word_nosc":"longera","lemma":"longer","pos":"VER"} ,
		{"word":"longeraient","word_nosc":"longeraient","lemma":"longer","pos":"VER"} ,
		{"word":"longerait","word_nosc":"longerait","lemma":"longer","pos":"VER"} ,
		{"word":"longerions","word_nosc":"longerions","lemma":"longer","pos":"VER"} ,
		{"word":"longerons","word_nosc":"longerons","lemma":"longer","pos":"VER"} ,
		{"word":"longeront","word_nosc":"longeront","lemma":"longer","pos":"VER"} ,
		{"word":"longes","word_nosc":"longes","lemma":"longer","pos":"VER"} ,
		{"word":"longez","word_nosc":"longez","lemma":"longer","pos":"VER"} ,
		{"word":"longeâmes","word_nosc":"longeames","lemma":"longer","pos":"VER"} ,
		{"word":"longiez","word_nosc":"longiez","lemma":"longer","pos":"VER"} ,
		{"word":"longions","word_nosc":"longions","lemma":"longer","pos":"VER"} ,
		{"word":"longèrent","word_nosc":"longerent","lemma":"longer","pos":"VER"} ,
		{"word":"longé","word_nosc":"longe","lemma":"longer","pos":"VER"} ,
		{"word":"longée","word_nosc":"longee","lemma":"longer","pos":"VER"} ,
		{"word":"longés","word_nosc":"longes","lemma":"longer","pos":"VER"} ,
		{"word":"loquait","word_nosc":"loquait","lemma":"loquer","pos":"VER"} ,
		{"word":"loquer","word_nosc":"loquer","lemma":"loquer","pos":"VER"} ,
		{"word":"loquette","word_nosc":"loquette","lemma":"loqueter","pos":"VER"} ,
		{"word":"loqué","word_nosc":"loque","lemma":"loquer","pos":"VER"} ,
		{"word":"loqués","word_nosc":"loques","lemma":"loquer","pos":"VER"} ,
		{"word":"lorgna","word_nosc":"lorgna","lemma":"lorgner","pos":"VER"} ,
		{"word":"lorgnaient","word_nosc":"lorgnaient","lemma":"lorgner","pos":"VER"} ,
		{"word":"lorgnais","word_nosc":"lorgnais","lemma":"lorgner","pos":"VER"} ,
		{"word":"lorgnait","word_nosc":"lorgnait","lemma":"lorgner","pos":"VER"} ,
		{"word":"lorgnant","word_nosc":"lorgnant","lemma":"lorgner","pos":"VER"} ,
		{"word":"lorgne","word_nosc":"lorgne","lemma":"lorgner","pos":"VER"} ,
		{"word":"lorgnent","word_nosc":"lorgnent","lemma":"lorgner","pos":"VER"} ,
		{"word":"lorgner","word_nosc":"lorgner","lemma":"lorgner","pos":"VER"} ,
		{"word":"lorgnerai","word_nosc":"lorgnerai","lemma":"lorgner","pos":"VER"} ,
		{"word":"lorgnerez","word_nosc":"lorgnerez","lemma":"lorgner","pos":"VER"} ,
		{"word":"lorgnez","word_nosc":"lorgnez","lemma":"lorgner","pos":"VER"} ,
		{"word":"lorgnèrent","word_nosc":"lorgnerent","lemma":"lorgner","pos":"VER"} ,
		{"word":"lorgné","word_nosc":"lorgne","lemma":"lorgner","pos":"VER"} ,
		{"word":"lorgnées","word_nosc":"lorgnees","lemma":"lorgner","pos":"VER"} ,
		{"word":"lorgnés","word_nosc":"lorgnes","lemma":"lorgner","pos":"VER"} ,
		{"word":"loti","word_nosc":"loti","lemma":"lotir","pos":"VER"} ,
		{"word":"lotie","word_nosc":"lotie","lemma":"lotir","pos":"VER"} ,
		{"word":"lotionnés","word_nosc":"lotionnes","lemma":"lotionner","pos":"VER"} ,
		{"word":"lotir","word_nosc":"lotir","lemma":"lotir","pos":"VER"} ,
		{"word":"lotis","word_nosc":"lotis","lemma":"lotir","pos":"VER"} ,
		{"word":"loua","word_nosc":"loua","lemma":"louer","pos":"VER"} ,
		{"word":"louai","word_nosc":"louai","lemma":"louer","pos":"VER"} ,
		{"word":"louaient","word_nosc":"louaient","lemma":"louer","pos":"VER"} ,
		{"word":"louais","word_nosc":"louais","lemma":"louer","pos":"VER"} ,
		{"word":"louait","word_nosc":"louait","lemma":"louer","pos":"VER"} ,
		{"word":"louange","word_nosc":"louange","lemma":"louanger","pos":"VER"} ,
		{"word":"louangea","word_nosc":"louangea","lemma":"louanger","pos":"VER"} ,
		{"word":"louangeaient","word_nosc":"louangeaient","lemma":"louanger","pos":"VER"} ,
		{"word":"louangeant","word_nosc":"louangeant","lemma":"louanger","pos":"VER"} ,
		{"word":"louanger","word_nosc":"louanger","lemma":"louanger","pos":"VER"} ,
		{"word":"louangé","word_nosc":"louange","lemma":"louanger","pos":"VER"} ,
		{"word":"louangée","word_nosc":"louangee","lemma":"louanger","pos":"VER"} ,
		{"word":"louangés","word_nosc":"louanges","lemma":"louanger","pos":"VER"} ,
		{"word":"louant","word_nosc":"louant","lemma":"louer","pos":"VER"} ,
		{"word":"loucha","word_nosc":"loucha","lemma":"loucher","pos":"VER"} ,
		{"word":"louchai","word_nosc":"louchai","lemma":"loucher","pos":"VER"} ,
		{"word":"louchaient","word_nosc":"louchaient","lemma":"loucher","pos":"VER"} ,
		{"word":"louchais","word_nosc":"louchais","lemma":"loucher","pos":"VER"} ,
		{"word":"louchait","word_nosc":"louchait","lemma":"loucher","pos":"VER"} ,
		{"word":"louchant","word_nosc":"louchant","lemma":"loucher","pos":"VER"} ,
		{"word":"louche","word_nosc":"louche","lemma":"loucher","pos":"VER"} ,
		{"word":"louchent","word_nosc":"louchent","lemma":"loucher","pos":"VER"} ,
		{"word":"loucher","word_nosc":"loucher","lemma":"loucher","pos":"VER"} ,
		{"word":"louches","word_nosc":"louches","lemma":"loucher","pos":"VER"} ,
		{"word":"louchez","word_nosc":"louchez","lemma":"loucher","pos":"VER"} ,
		{"word":"louchons","word_nosc":"louchons","lemma":"loucher","pos":"VER"} ,
		{"word":"louchât","word_nosc":"louchat","lemma":"loucher","pos":"VER"} ,
		{"word":"louché","word_nosc":"louche","lemma":"loucher","pos":"VER"} ,
		{"word":"louchée","word_nosc":"louchee","lemma":"loucher","pos":"VER"} ,
		{"word":"loue","word_nosc":"loue","lemma":"louer","pos":"VER"} ,
		{"word":"louent","word_nosc":"louent","lemma":"louer","pos":"VER"} ,
		{"word":"louer","word_nosc":"louer","lemma":"louer","pos":"VER"} ,
		{"word":"louera","word_nosc":"louera","lemma":"louer","pos":"VER"} ,
		{"word":"louerai","word_nosc":"louerai","lemma":"louer","pos":"VER"} ,
		{"word":"loueraient","word_nosc":"loueraient","lemma":"louer","pos":"VER"} ,
		{"word":"louerais","word_nosc":"louerais","lemma":"louer","pos":"VER"} ,
		{"word":"louerait","word_nosc":"louerait","lemma":"louer","pos":"VER"} ,
		{"word":"loueras","word_nosc":"loueras","lemma":"louer","pos":"VER"} ,
		{"word":"loueriez","word_nosc":"loueriez","lemma":"louer","pos":"VER"} ,
		{"word":"louerons","word_nosc":"louerons","lemma":"louer","pos":"VER"} ,
		{"word":"loues","word_nosc":"loues","lemma":"louer","pos":"VER"} ,
		{"word":"louez","word_nosc":"louez","lemma":"louer","pos":"VER"} ,
		{"word":"loufer","word_nosc":"loufer","lemma":"loufer","pos":"VER"} ,
		{"word":"loufes","word_nosc":"loufes","lemma":"loufer","pos":"VER"} ,
		{"word":"louions","word_nosc":"louions","lemma":"louer","pos":"VER"} ,
		{"word":"louons","word_nosc":"louons","lemma":"louer","pos":"VER"} ,
		{"word":"loupa","word_nosc":"loupa","lemma":"louper","pos":"VER"} ,
		{"word":"loupai","word_nosc":"loupai","lemma":"louper","pos":"VER"} ,
		{"word":"loupaient","word_nosc":"loupaient","lemma":"louper","pos":"VER"} ,
		{"word":"loupais","word_nosc":"loupais","lemma":"louper","pos":"VER"} ,
		{"word":"loupait","word_nosc":"loupait","lemma":"louper","pos":"VER"} ,
		{"word":"loupe","word_nosc":"loupe","lemma":"louper","pos":"VER"} ,
		{"word":"loupent","word_nosc":"loupent","lemma":"louper","pos":"VER"} ,
		{"word":"louper","word_nosc":"louper","lemma":"louper","pos":"VER"} ,
		{"word":"loupera","word_nosc":"loupera","lemma":"louper","pos":"VER"} ,
		{"word":"louperai","word_nosc":"louperai","lemma":"louper","pos":"VER"} ,
		{"word":"louperais","word_nosc":"louperais","lemma":"louper","pos":"VER"} ,
		{"word":"louperait","word_nosc":"louperait","lemma":"louper","pos":"VER"} ,
		{"word":"louperas","word_nosc":"louperas","lemma":"louper","pos":"VER"} ,
		{"word":"louperez","word_nosc":"louperez","lemma":"louper","pos":"VER"} ,
		{"word":"loupes","word_nosc":"loupes","lemma":"louper","pos":"VER"} ,
		{"word":"loupez","word_nosc":"loupez","lemma":"louper","pos":"VER"} ,
		{"word":"loupiez","word_nosc":"loupiez","lemma":"louper","pos":"VER"} ,
		{"word":"loupé","word_nosc":"loupe","lemma":"louper","pos":"VER"} ,
		{"word":"loupée","word_nosc":"loupee","lemma":"louper","pos":"VER"} ,
		{"word":"loupées","word_nosc":"loupees","lemma":"louper","pos":"VER"} ,
		{"word":"loupés","word_nosc":"loupes","lemma":"louper","pos":"VER"} ,
		{"word":"lourant","word_nosc":"lourant","lemma":"lourer","pos":"VER"} ,
		{"word":"lourdait","word_nosc":"lourdait","lemma":"lourder","pos":"VER"} ,
		{"word":"lourdant","word_nosc":"lourdant","lemma":"lourder","pos":"VER"} ,
		{"word":"lourder","word_nosc":"lourder","lemma":"lourder","pos":"VER"} ,
		{"word":"lourdé","word_nosc":"lourde","lemma":"lourder","pos":"VER"} ,
		{"word":"lourdée","word_nosc":"lourdee","lemma":"lourder","pos":"VER"} ,
		{"word":"lourdées","word_nosc":"lourdees","lemma":"lourder","pos":"VER"} ,
		{"word":"lourdés","word_nosc":"lourdes","lemma":"lourder","pos":"VER"} ,
		{"word":"louvoie","word_nosc":"louvoie","lemma":"louvoyer","pos":"VER"} ,
		{"word":"louvoient","word_nosc":"louvoient","lemma":"louvoyer","pos":"VER"} ,
		{"word":"louvoyaient","word_nosc":"louvoyaient","lemma":"louvoyer","pos":"VER"} ,
		{"word":"louvoyait","word_nosc":"louvoyait","lemma":"louvoyer","pos":"VER"} ,
		{"word":"louvoyant","word_nosc":"louvoyant","lemma":"louvoyer","pos":"VER"} ,
		{"word":"louvoyer","word_nosc":"louvoyer","lemma":"louvoyer","pos":"VER"} ,
		{"word":"louvoyons","word_nosc":"louvoyons","lemma":"louvoyer","pos":"VER"} ,
		{"word":"louvoyât","word_nosc":"louvoyat","lemma":"louvoyer","pos":"VER"} ,
		{"word":"louvoyé","word_nosc":"louvoye","lemma":"louvoyer","pos":"VER"} ,
		{"word":"louâmes","word_nosc":"louames","lemma":"louer","pos":"VER"} ,
		{"word":"louèrent","word_nosc":"louerent","lemma":"louer","pos":"VER"} ,
		{"word":"loué","word_nosc":"loue","lemma":"louer","pos":"VER"} ,
		{"word":"louée","word_nosc":"louee","lemma":"louer","pos":"VER"} ,
		{"word":"louées","word_nosc":"louees","lemma":"louer","pos":"VER"} ,
		{"word":"loués","word_nosc":"loues","lemma":"louer","pos":"VER"} ,
		{"word":"lova","word_nosc":"lova","lemma":"lover","pos":"VER"} ,
		{"word":"lovaient","word_nosc":"lovaient","lemma":"lover","pos":"VER"} ,
		{"word":"lovais","word_nosc":"lovais","lemma":"lover","pos":"VER"} ,
		{"word":"lovait","word_nosc":"lovait","lemma":"lover","pos":"VER"} ,
		{"word":"lovant","word_nosc":"lovant","lemma":"lover","pos":"VER"} ,
		{"word":"love","word_nosc":"love","lemma":"lover","pos":"VER"} ,
		{"word":"lovent","word_nosc":"lovent","lemma":"lover","pos":"VER"} ,
		{"word":"lover","word_nosc":"lover","lemma":"lover","pos":"VER"} ,
		{"word":"lové","word_nosc":"love","lemma":"lover","pos":"VER"} ,
		{"word":"lovée","word_nosc":"lovee","lemma":"lover","pos":"VER"} ,
		{"word":"lovées","word_nosc":"lovees","lemma":"lover","pos":"VER"} ,
		{"word":"lovés","word_nosc":"loves","lemma":"lover","pos":"VER"} ,
		{"word":"lu","word_nosc":"lu","lemma":"lire","pos":"VER"} ,
		{"word":"lubrifiant","word_nosc":"lubrifiant","lemma":"lubrifier","pos":"VER"} ,
		{"word":"lubrifie","word_nosc":"lubrifie","lemma":"lubrifier","pos":"VER"} ,
		{"word":"lubrifient","word_nosc":"lubrifient","lemma":"lubrifier","pos":"VER"} ,
		{"word":"lubrifier","word_nosc":"lubrifier","lemma":"lubrifier","pos":"VER"} ,
		{"word":"lubrifié","word_nosc":"lubrifie","lemma":"lubrifier","pos":"VER"} ,
		{"word":"lubrifiée","word_nosc":"lubrifiee","lemma":"lubrifier","pos":"VER"} ,
		{"word":"lubrifiées","word_nosc":"lubrifiees","lemma":"lubrifier","pos":"VER"} ,
		{"word":"lue","word_nosc":"lue","lemma":"lire","pos":"VER"} ,
		{"word":"lues","word_nosc":"lues","lemma":"lire","pos":"VER"} ,
		{"word":"lui","word_nosc":"lui","lemma":"luire","pos":"VER"} ,
		{"word":"luira","word_nosc":"luira","lemma":"luire","pos":"VER"} ,
		{"word":"luiraient","word_nosc":"luiraient","lemma":"luire","pos":"VER"} ,
		{"word":"luirait","word_nosc":"luirait","lemma":"luire","pos":"VER"} ,
		{"word":"luire","word_nosc":"luire","lemma":"luire","pos":"VER"} ,
		{"word":"luirent","word_nosc":"luirent","lemma":"luire","pos":"VER"} ,
		{"word":"luis","word_nosc":"luis","lemma":"luire","pos":"VER"} ,
		{"word":"luisaient","word_nosc":"luisaient","lemma":"luire","pos":"VER"} ,
		{"word":"luisait","word_nosc":"luisait","lemma":"luire","pos":"VER"} ,
		{"word":"luisant","word_nosc":"luisant","lemma":"luire","pos":"VER"} ,
		{"word":"luise","word_nosc":"luise","lemma":"luire","pos":"VER"} ,
		{"word":"luisent","word_nosc":"luisent","lemma":"luire","pos":"VER"} ,
		{"word":"luit","word_nosc":"luit","lemma":"luire","pos":"VER"} ,
		{"word":"luncher","word_nosc":"luncher","lemma":"luncher","pos":"VER"} ,
		{"word":"lurent","word_nosc":"lurent","lemma":"lire","pos":"VER"} ,
		{"word":"lus","word_nosc":"lus","lemma":"lire","pos":"VER"} ,
		{"word":"lustra","word_nosc":"lustra","lemma":"lustrer","pos":"VER"} ,
		{"word":"lustraient","word_nosc":"lustraient","lemma":"lustrer","pos":"VER"} ,
		{"word":"lustrait","word_nosc":"lustrait","lemma":"lustrer","pos":"VER"} ,
		{"word":"lustrant","word_nosc":"lustrant","lemma":"lustrer","pos":"VER"} ,
		{"word":"lustre","word_nosc":"lustre","lemma":"lustrer","pos":"VER"} ,
		{"word":"lustrer","word_nosc":"lustrer","lemma":"lustrer","pos":"VER"} ,
		{"word":"lustres","word_nosc":"lustres","lemma":"lustrer","pos":"VER"} ,
		{"word":"lustré","word_nosc":"lustre","lemma":"lustrer","pos":"VER"} ,
		{"word":"lustrée","word_nosc":"lustree","lemma":"lustrer","pos":"VER"} ,
		{"word":"lustrées","word_nosc":"lustrees","lemma":"lustrer","pos":"VER"} ,
		{"word":"lustrés","word_nosc":"lustres","lemma":"lustrer","pos":"VER"} ,
		{"word":"lut","word_nosc":"lut","lemma":"lire","pos":"VER"} ,
		{"word":"lutinaient","word_nosc":"lutinaient","lemma":"lutiner","pos":"VER"} ,
		{"word":"lutinait","word_nosc":"lutinait","lemma":"lutiner","pos":"VER"} ,
		{"word":"lutinant","word_nosc":"lutinant","lemma":"lutiner","pos":"VER"} ,
		{"word":"lutine","word_nosc":"lutine","lemma":"lutiner","pos":"VER"} ,
		{"word":"lutinent","word_nosc":"lutinent","lemma":"lutiner","pos":"VER"} ,
		{"word":"lutiner","word_nosc":"lutiner","lemma":"lutiner","pos":"VER"} ,
		{"word":"lutinerais","word_nosc":"lutinerais","lemma":"lutiner","pos":"VER"} ,
		{"word":"lutinèrent","word_nosc":"lutinerent","lemma":"lutiner","pos":"VER"} ,
		{"word":"lutiné","word_nosc":"lutine","lemma":"lutiner","pos":"VER"} ,
		{"word":"lutinée","word_nosc":"lutinee","lemma":"lutiner","pos":"VER"} ,
		{"word":"lutta","word_nosc":"lutta","lemma":"lutter","pos":"VER"} ,
		{"word":"luttai","word_nosc":"luttai","lemma":"lutter","pos":"VER"} ,
		{"word":"luttaient","word_nosc":"luttaient","lemma":"lutter","pos":"VER"} ,
		{"word":"luttais","word_nosc":"luttais","lemma":"lutter","pos":"VER"} ,
		{"word":"luttait","word_nosc":"luttait","lemma":"lutter","pos":"VER"} ,
		{"word":"luttant","word_nosc":"luttant","lemma":"lutter","pos":"VER"} ,
		{"word":"lutte","word_nosc":"lutte","lemma":"lutter","pos":"VER"} ,
		{"word":"luttent","word_nosc":"luttent","lemma":"lutter","pos":"VER"} ,
		{"word":"lutter","word_nosc":"lutter","lemma":"lutter","pos":"VER"} ,
		{"word":"luttera","word_nosc":"luttera","lemma":"lutter","pos":"VER"} ,
		{"word":"lutterai","word_nosc":"lutterai","lemma":"lutter","pos":"VER"} ,
		{"word":"lutterais","word_nosc":"lutterais","lemma":"lutter","pos":"VER"} ,
		{"word":"lutterait","word_nosc":"lutterait","lemma":"lutter","pos":"VER"} ,
		{"word":"lutterions","word_nosc":"lutterions","lemma":"lutter","pos":"VER"} ,
		{"word":"lutterons","word_nosc":"lutterons","lemma":"lutter","pos":"VER"} ,
		{"word":"lutteront","word_nosc":"lutteront","lemma":"lutter","pos":"VER"} ,
		{"word":"luttes","word_nosc":"luttes","lemma":"lutter","pos":"VER"} ,
		{"word":"luttez","word_nosc":"luttez","lemma":"lutter","pos":"VER"} ,
		{"word":"luttiez","word_nosc":"luttiez","lemma":"lutter","pos":"VER"} ,
		{"word":"luttions","word_nosc":"luttions","lemma":"lutter","pos":"VER"} ,
		{"word":"luttons","word_nosc":"luttons","lemma":"lutter","pos":"VER"} ,
		{"word":"luttât","word_nosc":"luttat","lemma":"lutter","pos":"VER"} ,
		{"word":"luttèrent","word_nosc":"lutterent","lemma":"lutter","pos":"VER"} ,
		{"word":"lutté","word_nosc":"lutte","lemma":"lutter","pos":"VER"} ,
		{"word":"luté","word_nosc":"lute","lemma":"luter","pos":"VER"} ,
		{"word":"luxa","word_nosc":"luxa","lemma":"luxer","pos":"VER"} ,
		{"word":"luxe","word_nosc":"luxe","lemma":"luxer","pos":"VER"} ,
		{"word":"luxer","word_nosc":"luxer","lemma":"luxer","pos":"VER"} ,
		{"word":"luxé","word_nosc":"luxe","lemma":"luxer","pos":"VER"} ,
		{"word":"lynchait","word_nosc":"lynchait","lemma":"lyncher","pos":"VER"} ,
		{"word":"lynchent","word_nosc":"lynchent","lemma":"lyncher","pos":"VER"} ,
		{"word":"lyncher","word_nosc":"lyncher","lemma":"lyncher","pos":"VER"} ,
		{"word":"lynchez","word_nosc":"lynchez","lemma":"lyncher","pos":"VER"} ,
		{"word":"lynchiez","word_nosc":"lynchiez","lemma":"lyncher","pos":"VER"} ,
		{"word":"lynchons","word_nosc":"lynchons","lemma":"lyncher","pos":"VER"} ,
		{"word":"lynché","word_nosc":"lynche","lemma":"lyncher","pos":"VER"} ,
		{"word":"lynchée","word_nosc":"lynchee","lemma":"lyncher","pos":"VER"} ,
		{"word":"lynchés","word_nosc":"lynches","lemma":"lyncher","pos":"VER"} ,
		{"word":"lyophilisant","word_nosc":"lyophilisant","lemma":"lyophiliser","pos":"VER"} ,
		{"word":"lyophiliser","word_nosc":"lyophiliser","lemma":"lyophiliser","pos":"VER"} ,
		{"word":"lyophilisé","word_nosc":"lyophilise","lemma":"lyophiliser","pos":"VER"} ,
		{"word":"lyriser","word_nosc":"lyriser","lemma":"lyriser","pos":"VER"} ,
		{"word":"lâcha","word_nosc":"lacha","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchai","word_nosc":"lachai","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchaient","word_nosc":"lachaient","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchais","word_nosc":"lachais","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchait","word_nosc":"lachait","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchant","word_nosc":"lachant","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâche","word_nosc":"lache","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchent","word_nosc":"lachent","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâcher","word_nosc":"lacher","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchera","word_nosc":"lachera","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâcherai","word_nosc":"lacherai","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâcheraient","word_nosc":"lacheraient","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâcherais","word_nosc":"lacherais","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâcherait","word_nosc":"lacherait","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâcheras","word_nosc":"lacheras","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâcherez","word_nosc":"lacherez","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâcheriez","word_nosc":"lacheriez","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâcherons","word_nosc":"lacherons","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâcheront","word_nosc":"lacheront","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâches","word_nosc":"laches","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchez","word_nosc":"lachez","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchiez","word_nosc":"lachiez","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchions","word_nosc":"lachions","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchons","word_nosc":"lachons","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchât","word_nosc":"lachat","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchèrent","word_nosc":"lacherent","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâché","word_nosc":"lache","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchée","word_nosc":"lachee","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchées","word_nosc":"lachees","lemma":"lâcher","pos":"VER"} ,
		{"word":"lâchés","word_nosc":"laches","lemma":"lâcher","pos":"VER"} ,
		{"word":"lèche","word_nosc":"leche","lemma":"lécher","pos":"VER"} ,
		{"word":"lèchent","word_nosc":"lechent","lemma":"lécher","pos":"VER"} ,
		{"word":"lèches","word_nosc":"leches","lemma":"lécher","pos":"VER"} ,
		{"word":"lègue","word_nosc":"legue","lemma":"léguer","pos":"VER"} ,
		{"word":"lèse","word_nosc":"lese","lemma":"léser","pos":"VER"} ,
		{"word":"lève","word_nosc":"leve","lemma":"lever","pos":"VER"} ,
		{"word":"lèvent","word_nosc":"levent","lemma":"lever","pos":"VER"} ,
		{"word":"lèvera","word_nosc":"levera","lemma":"lever","pos":"VER"} ,
		{"word":"lèverai","word_nosc":"leverai","lemma":"lever","pos":"VER"} ,
		{"word":"lèveraient","word_nosc":"leveraient","lemma":"lever","pos":"VER"} ,
		{"word":"lèverais","word_nosc":"leverais","lemma":"lever","pos":"VER"} ,
		{"word":"lèverait","word_nosc":"leverait","lemma":"lever","pos":"VER"} ,
		{"word":"lèveras","word_nosc":"leveras","lemma":"lever","pos":"VER"} ,
		{"word":"lèverez","word_nosc":"leverez","lemma":"lever","pos":"VER"} ,
		{"word":"lèverons","word_nosc":"leverons","lemma":"lever","pos":"VER"} ,
		{"word":"lèveront","word_nosc":"leveront","lemma":"lever","pos":"VER"} ,
		{"word":"lèves","word_nosc":"leves","lemma":"lever","pos":"VER"} ,
		{"word":"lécha","word_nosc":"lecha","lemma":"lécher","pos":"VER"} ,
		{"word":"léchaient","word_nosc":"lechaient","lemma":"lécher","pos":"VER"} ,
		{"word":"léchais","word_nosc":"lechais","lemma":"lécher","pos":"VER"} ,
		{"word":"léchait","word_nosc":"lechait","lemma":"lécher","pos":"VER"} ,
		{"word":"léchant","word_nosc":"lechant","lemma":"lécher","pos":"VER"} ,
		{"word":"léchassions","word_nosc":"lechassions","lemma":"lécher","pos":"VER"} ,
		{"word":"lécher","word_nosc":"lecher","lemma":"lécher","pos":"VER"} ,
		{"word":"léchera","word_nosc":"lechera","lemma":"lécher","pos":"VER"} ,
		{"word":"lécherai","word_nosc":"lecherai","lemma":"lécher","pos":"VER"} ,
		{"word":"lécherais","word_nosc":"lecherais","lemma":"lécher","pos":"VER"} ,
		{"word":"lécherait","word_nosc":"lecherait","lemma":"lécher","pos":"VER"} ,
		{"word":"lécheras","word_nosc":"lecheras","lemma":"lécher","pos":"VER"} ,
		{"word":"lécheront","word_nosc":"lecheront","lemma":"lécher","pos":"VER"} ,
		{"word":"léchez","word_nosc":"lechez","lemma":"lécher","pos":"VER"} ,
		{"word":"léchouillait","word_nosc":"lechouillait","lemma":"léchouiller","pos":"VER"} ,
		{"word":"léchouillent","word_nosc":"lechouillent","lemma":"léchouiller","pos":"VER"} ,
		{"word":"léchouiller","word_nosc":"lechouiller","lemma":"léchouiller","pos":"VER"} ,
		{"word":"léchouilles","word_nosc":"lechouilles","lemma":"léchouiller","pos":"VER"} ,
		{"word":"léchèrent","word_nosc":"lecherent","lemma":"lécher","pos":"VER"} ,
		{"word":"léché","word_nosc":"leche","lemma":"lécher","pos":"VER"} ,
		{"word":"léchée","word_nosc":"lechee","lemma":"lécher","pos":"VER"} ,
		{"word":"léchées","word_nosc":"lechees","lemma":"lécher","pos":"VER"} ,
		{"word":"léchés","word_nosc":"leches","lemma":"lécher","pos":"VER"} ,
		{"word":"légalisant","word_nosc":"legalisant","lemma":"légaliser","pos":"VER"} ,
		{"word":"légalise","word_nosc":"legalise","lemma":"légaliser","pos":"VER"} ,
		{"word":"légaliser","word_nosc":"legaliser","lemma":"légaliser","pos":"VER"} ,
		{"word":"légalisez","word_nosc":"legalisez","lemma":"légaliser","pos":"VER"} ,
		{"word":"légalisé","word_nosc":"legalise","lemma":"légaliser","pos":"VER"} ,
		{"word":"légalisée","word_nosc":"legalisee","lemma":"légaliser","pos":"VER"} ,
		{"word":"légender","word_nosc":"legender","lemma":"légender","pos":"VER"} ,
		{"word":"légendée","word_nosc":"legendee","lemma":"légender","pos":"VER"} ,
		{"word":"légifère","word_nosc":"legifere","lemma":"légiférer","pos":"VER"} ,
		{"word":"légiférait","word_nosc":"legiferait","lemma":"légiférer","pos":"VER"} ,
		{"word":"légiférer","word_nosc":"legiferer","lemma":"légiférer","pos":"VER"} ,
		{"word":"légiféré","word_nosc":"legifere","lemma":"légiférer","pos":"VER"} ,
		{"word":"légitimaient","word_nosc":"legitimaient","lemma":"légitimer","pos":"VER"} ,
		{"word":"légitimait","word_nosc":"legitimait","lemma":"légitimer","pos":"VER"} ,
		{"word":"légitimant","word_nosc":"legitimant","lemma":"légitimer","pos":"VER"} ,
		{"word":"légitime","word_nosc":"legitime","lemma":"légitimer","pos":"VER"} ,
		{"word":"légitiment","word_nosc":"legitiment","lemma":"légitimer","pos":"VER"} ,
		{"word":"légitimer","word_nosc":"legitimer","lemma":"légitimer","pos":"VER"} ,
		{"word":"légitimera","word_nosc":"legitimera","lemma":"légitimer","pos":"VER"} ,
		{"word":"légitimé","word_nosc":"legitime","lemma":"légitimer","pos":"VER"} ,
		{"word":"légitimée","word_nosc":"legitimee","lemma":"légitimer","pos":"VER"} ,
		{"word":"légua","word_nosc":"legua","lemma":"léguer","pos":"VER"} ,
		{"word":"léguaient","word_nosc":"leguaient","lemma":"léguer","pos":"VER"} ,
		{"word":"léguais","word_nosc":"leguais","lemma":"léguer","pos":"VER"} ,
		{"word":"léguait","word_nosc":"leguait","lemma":"léguer","pos":"VER"} ,
		{"word":"léguant","word_nosc":"leguant","lemma":"léguer","pos":"VER"} ,
		{"word":"léguer","word_nosc":"leguer","lemma":"léguer","pos":"VER"} ,
		{"word":"léguera","word_nosc":"leguera","lemma":"léguer","pos":"VER"} ,
		{"word":"léguerai","word_nosc":"leguerai","lemma":"léguer","pos":"VER"} ,
		{"word":"léguerais","word_nosc":"leguerais","lemma":"léguer","pos":"VER"} ,
		{"word":"léguerez","word_nosc":"leguerez","lemma":"léguer","pos":"VER"} ,
		{"word":"léguerons","word_nosc":"leguerons","lemma":"léguer","pos":"VER"} ,
		{"word":"légueront","word_nosc":"legueront","lemma":"léguer","pos":"VER"} ,
		{"word":"léguez","word_nosc":"leguez","lemma":"léguer","pos":"VER"} ,
		{"word":"légué","word_nosc":"legue","lemma":"léguer","pos":"VER"} ,
		{"word":"léguée","word_nosc":"leguee","lemma":"léguer","pos":"VER"} ,
		{"word":"léguées","word_nosc":"leguees","lemma":"léguer","pos":"VER"} ,
		{"word":"légués","word_nosc":"legues","lemma":"léguer","pos":"VER"} ,
		{"word":"lénifiait","word_nosc":"lenifiait","lemma":"lénifier","pos":"VER"} ,
		{"word":"lésaient","word_nosc":"lesaient","lemma":"léser","pos":"VER"} ,
		{"word":"lésais","word_nosc":"lesais","lemma":"léser","pos":"VER"} ,
		{"word":"léser","word_nosc":"leser","lemma":"léser","pos":"VER"} ,
		{"word":"lésina","word_nosc":"lesina","lemma":"lésiner","pos":"VER"} ,
		{"word":"lésinaient","word_nosc":"lesinaient","lemma":"lésiner","pos":"VER"} ,
		{"word":"lésinait","word_nosc":"lesinait","lemma":"lésiner","pos":"VER"} ,
		{"word":"lésine","word_nosc":"lesine","lemma":"lésiner","pos":"VER"} ,
		{"word":"lésinent","word_nosc":"lesinent","lemma":"lésiner","pos":"VER"} ,
		{"word":"lésiner","word_nosc":"lesiner","lemma":"lésiner","pos":"VER"} ,
		{"word":"lésinez","word_nosc":"lesinez","lemma":"lésiner","pos":"VER"} ,
		{"word":"lésiné","word_nosc":"lesine","lemma":"lésiner","pos":"VER"} ,
		{"word":"lésions","word_nosc":"lesions","lemma":"léser","pos":"VER"} ,
		{"word":"lésons","word_nosc":"lesons","lemma":"léser","pos":"VER"} ,
		{"word":"lésé","word_nosc":"lese","lemma":"léser","pos":"VER"} ,
		{"word":"lésée","word_nosc":"lesee","lemma":"léser","pos":"VER"} ,
		{"word":"lésées","word_nosc":"lesees","lemma":"léser","pos":"VER"} ,
		{"word":"lésés","word_nosc":"leses","lemma":"léser","pos":"VER"} ,
		{"word":"lévitent","word_nosc":"levitent","lemma":"léviter","pos":"VER"} ,
		{"word":"léviter","word_nosc":"leviter","lemma":"léviter","pos":"VER"} ,
		{"word":"lézardaient","word_nosc":"lezardaient","lemma":"lézarder","pos":"VER"} ,
		{"word":"lézardait","word_nosc":"lezardait","lemma":"lézarder","pos":"VER"} ,
		{"word":"lézarde","word_nosc":"lezarde","lemma":"lézarder","pos":"VER"} ,
		{"word":"lézardent","word_nosc":"lezardent","lemma":"lézarder","pos":"VER"} ,
		{"word":"lézarder","word_nosc":"lezarder","lemma":"lézarder","pos":"VER"} ,
		{"word":"lézardé","word_nosc":"lezarde","lemma":"lézarder","pos":"VER"} ,
		{"word":"lézardées","word_nosc":"lezardees","lemma":"lézarder","pos":"VER"} ,
		{"word":"lézardés","word_nosc":"lezardes","lemma":"lézarder","pos":"VER"} ,
		{"word":"lûmes","word_nosc":"lumes","lemma":"lire","pos":"VER"} ,
		{"word":"lût","word_nosc":"lut","lemma":"lire","pos":"VER"} ,
		{"word":"machina","word_nosc":"machina","lemma":"machiner","pos":"VER"} ,
		{"word":"machinaient","word_nosc":"machinaient","lemma":"machiner","pos":"VER"} ,
		{"word":"machiner","word_nosc":"machiner","lemma":"machiner","pos":"VER"} ,
		{"word":"machiné","word_nosc":"machine","lemma":"machiner","pos":"VER"} ,
		{"word":"machinée","word_nosc":"machinee","lemma":"machiner","pos":"VER"} ,
		{"word":"machinées","word_nosc":"machinees","lemma":"machiner","pos":"VER"} ,
		{"word":"machinés","word_nosc":"machines","lemma":"machiner","pos":"VER"} ,
		{"word":"macula","word_nosc":"macula","lemma":"maculer","pos":"VER"} ,
		{"word":"maculaient","word_nosc":"maculaient","lemma":"maculer","pos":"VER"} ,
		{"word":"maculait","word_nosc":"maculait","lemma":"maculer","pos":"VER"} ,
		{"word":"maculant","word_nosc":"maculant","lemma":"maculer","pos":"VER"} ,
		{"word":"macule","word_nosc":"macule","lemma":"maculer","pos":"VER"} ,
		{"word":"maculent","word_nosc":"maculent","lemma":"maculer","pos":"VER"} ,
		{"word":"maculer","word_nosc":"maculer","lemma":"maculer","pos":"VER"} ,
		{"word":"maculèrent","word_nosc":"maculerent","lemma":"maculer","pos":"VER"} ,
		{"word":"maculé","word_nosc":"macule","lemma":"maculer","pos":"VER"} ,
		{"word":"maculée","word_nosc":"maculee","lemma":"maculer","pos":"VER"} ,
		{"word":"maculées","word_nosc":"maculees","lemma":"maculer","pos":"VER"} ,
		{"word":"maculés","word_nosc":"macules","lemma":"maculer","pos":"VER"} ,
		{"word":"macère","word_nosc":"macere","lemma":"macérer","pos":"VER"} ,
		{"word":"macèrent","word_nosc":"macerent","lemma":"macérer","pos":"VER"} ,
		{"word":"macérai","word_nosc":"macerai","lemma":"macérer","pos":"VER"} ,
		{"word":"macéraient","word_nosc":"maceraient","lemma":"macérer","pos":"VER"} ,
		{"word":"macérait","word_nosc":"macerait","lemma":"macérer","pos":"VER"} ,
		{"word":"macérer","word_nosc":"macerer","lemma":"macérer","pos":"VER"} ,
		{"word":"macéré","word_nosc":"macere","lemma":"macérer","pos":"VER"} ,
		{"word":"macérée","word_nosc":"maceree","lemma":"macérer","pos":"VER"} ,
		{"word":"macérés","word_nosc":"maceres","lemma":"macérer","pos":"VER"} ,
		{"word":"maganer","word_nosc":"maganer","lemma":"maganer","pos":"VER"} ,
		{"word":"magasine","word_nosc":"magasine","lemma":"magasiner","pos":"VER"} ,
		{"word":"magasiner","word_nosc":"magasiner","lemma":"magasiner","pos":"VER"} ,
		{"word":"magasines","word_nosc":"magasines","lemma":"magasiner","pos":"VER"} ,
		{"word":"magna","word_nosc":"magna","lemma":"magner","pos":"VER"} ,
		{"word":"magnait","word_nosc":"magnait","lemma":"magner","pos":"VER"} ,
		{"word":"magne","word_nosc":"magne","lemma":"magner","pos":"VER"} ,
		{"word":"magnent","word_nosc":"magnent","lemma":"magner","pos":"VER"} ,
		{"word":"magner","word_nosc":"magner","lemma":"magner","pos":"VER"} ,
		{"word":"magnes","word_nosc":"magnes","lemma":"magner","pos":"VER"} ,
		{"word":"magnez","word_nosc":"magnez","lemma":"magner","pos":"VER"} ,
		{"word":"magnifiaient","word_nosc":"magnifiaient","lemma":"magnifier","pos":"VER"} ,
		{"word":"magnifiais","word_nosc":"magnifiais","lemma":"magnifier","pos":"VER"} ,
		{"word":"magnifiait","word_nosc":"magnifiait","lemma":"magnifier","pos":"VER"} ,
		{"word":"magnifiant","word_nosc":"magnifiant","lemma":"magnifier","pos":"VER"} ,
		{"word":"magnifie","word_nosc":"magnifie","lemma":"magnifier","pos":"VER"} ,
		{"word":"magnifient","word_nosc":"magnifient","lemma":"magnifier","pos":"VER"} ,
		{"word":"magnifier","word_nosc":"magnifier","lemma":"magnifier","pos":"VER"} ,
		{"word":"magnifiez","word_nosc":"magnifiez","lemma":"magnifier","pos":"VER"} ,
		{"word":"magnifié","word_nosc":"magnifie","lemma":"magnifier","pos":"VER"} ,
		{"word":"magnifiée","word_nosc":"magnifiee","lemma":"magnifier","pos":"VER"} ,
		{"word":"magnifiées","word_nosc":"magnifiees","lemma":"magnifier","pos":"VER"} ,
		{"word":"magnifiés","word_nosc":"magnifies","lemma":"magnifier","pos":"VER"} ,
		{"word":"magnons","word_nosc":"magnons","lemma":"magner","pos":"VER"} ,
		{"word":"magné","word_nosc":"magne","lemma":"magner","pos":"VER"} ,
		{"word":"magnétisait","word_nosc":"magnetisait","lemma":"magnétiser","pos":"VER"} ,
		{"word":"magnétise","word_nosc":"magnetise","lemma":"magnétiser","pos":"VER"} ,
		{"word":"magnétisé","word_nosc":"magnetise","lemma":"magnétiser","pos":"VER"} ,
		{"word":"magnétisée","word_nosc":"magnetisee","lemma":"magnétiser","pos":"VER"} ,
		{"word":"magnétisées","word_nosc":"magnetisees","lemma":"magnétiser","pos":"VER"} ,
		{"word":"magnétisés","word_nosc":"magnetises","lemma":"magnétiser","pos":"VER"} ,
		{"word":"magouillais","word_nosc":"magouillais","lemma":"magouiller","pos":"VER"} ,
		{"word":"magouillait","word_nosc":"magouillait","lemma":"magouiller","pos":"VER"} ,
		{"word":"magouillant","word_nosc":"magouillant","lemma":"magouiller","pos":"VER"} ,
		{"word":"magouiller","word_nosc":"magouiller","lemma":"magouiller","pos":"VER"} ,
		{"word":"magouillez","word_nosc":"magouillez","lemma":"magouiller","pos":"VER"} ,
		{"word":"magouillé","word_nosc":"magouille","lemma":"magouiller","pos":"VER"} ,
		{"word":"maigri","word_nosc":"maigri","lemma":"maigrir","pos":"VER"} ,
		{"word":"maigrie","word_nosc":"maigrie","lemma":"maigrir","pos":"VER"} ,
		{"word":"maigrir","word_nosc":"maigrir","lemma":"maigrir","pos":"VER"} ,
		{"word":"maigris","word_nosc":"maigris","lemma":"maigrir","pos":"VER"} ,
		{"word":"maigrissais","word_nosc":"maigrissais","lemma":"maigrir","pos":"VER"} ,
		{"word":"maigrissait","word_nosc":"maigrissait","lemma":"maigrir","pos":"VER"} ,
		{"word":"maigrissant","word_nosc":"maigrissant","lemma":"maigrir","pos":"VER"} ,
		{"word":"maigrisse","word_nosc":"maigrisse","lemma":"maigrir","pos":"VER"} ,
		{"word":"maigrissent","word_nosc":"maigrissent","lemma":"maigrir","pos":"VER"} ,
		{"word":"maigrisses","word_nosc":"maigrisses","lemma":"maigrir","pos":"VER"} ,
		{"word":"maigrissez","word_nosc":"maigrissez","lemma":"maigrir","pos":"VER"} ,
		{"word":"maigrit","word_nosc":"maigrit","lemma":"maigrir","pos":"VER"} ,
		{"word":"mailer","word_nosc":"mailer","lemma":"mailer","pos":"VER"} ,
		{"word":"maillait","word_nosc":"maillait","lemma":"mailler","pos":"VER"} ,
		{"word":"maille","word_nosc":"maille","lemma":"mailler","pos":"VER"} ,
		{"word":"mailler","word_nosc":"mailler","lemma":"mailler","pos":"VER"} ,
		{"word":"maillons","word_nosc":"maillons","lemma":"mailler","pos":"VER"} ,
		{"word":"mailé","word_nosc":"maile","lemma":"mailer","pos":"VER"} ,
		{"word":"maintenaient","word_nosc":"maintenaient","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintenais","word_nosc":"maintenais","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintenait","word_nosc":"maintenait","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintenant","word_nosc":"maintenant","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintenez","word_nosc":"maintenez","lemma":"maintenir","pos":"VER"} ,
		{"word":"mainteniez","word_nosc":"mainteniez","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintenions","word_nosc":"maintenions","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintenir","word_nosc":"maintenir","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintenons","word_nosc":"maintenons","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintenu","word_nosc":"maintenu","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintenue","word_nosc":"maintenue","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintenues","word_nosc":"maintenues","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintenus","word_nosc":"maintenus","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintiendra","word_nosc":"maintiendra","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintiendrai","word_nosc":"maintiendrai","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintiendraient","word_nosc":"maintiendraient","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintiendrait","word_nosc":"maintiendrait","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintiendras","word_nosc":"maintiendras","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintiendriez","word_nosc":"maintiendriez","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintiendrons","word_nosc":"maintiendrons","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintiendront","word_nosc":"maintiendront","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintienne","word_nosc":"maintienne","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintiennent","word_nosc":"maintiennent","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintiennes","word_nosc":"maintiennes","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintiens","word_nosc":"maintiens","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintient","word_nosc":"maintient","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintinrent","word_nosc":"maintinrent","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintins","word_nosc":"maintins","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintint","word_nosc":"maintint","lemma":"maintenir","pos":"VER"} ,
		{"word":"maintînt","word_nosc":"maintint","lemma":"maintenir","pos":"VER"} ,
		{"word":"majora","word_nosc":"majora","lemma":"majorer","pos":"VER"} ,
		{"word":"majore","word_nosc":"majore","lemma":"majorer","pos":"VER"} ,
		{"word":"majorer","word_nosc":"majorer","lemma":"majorer","pos":"VER"} ,
		{"word":"majorée","word_nosc":"majoree","lemma":"majorer","pos":"VER"} ,
		{"word":"malaxa","word_nosc":"malaxa","lemma":"malaxer","pos":"VER"} ,
		{"word":"malaxaient","word_nosc":"malaxaient","lemma":"malaxer","pos":"VER"} ,
		{"word":"malaxait","word_nosc":"malaxait","lemma":"malaxer","pos":"VER"} ,
		{"word":"malaxant","word_nosc":"malaxant","lemma":"malaxer","pos":"VER"} ,
		{"word":"malaxe","word_nosc":"malaxe","lemma":"malaxer","pos":"VER"} ,
		{"word":"malaxer","word_nosc":"malaxer","lemma":"malaxer","pos":"VER"} ,
		{"word":"malaxera","word_nosc":"malaxera","lemma":"malaxer","pos":"VER"} ,
		{"word":"malaxez","word_nosc":"malaxez","lemma":"malaxer","pos":"VER"} ,
		{"word":"malaxé","word_nosc":"malaxe","lemma":"malaxer","pos":"VER"} ,
		{"word":"malaxée","word_nosc":"malaxee","lemma":"malaxer","pos":"VER"} ,
		{"word":"malaxées","word_nosc":"malaxees","lemma":"malaxer","pos":"VER"} ,
		{"word":"malaxés","word_nosc":"malaxes","lemma":"malaxer","pos":"VER"} ,
		{"word":"malmenaient","word_nosc":"malmenaient","lemma":"malmener","pos":"VER"} ,
		{"word":"malmenais","word_nosc":"malmenais","lemma":"malmener","pos":"VER"} ,
		{"word":"malmenait","word_nosc":"malmenait","lemma":"malmener","pos":"VER"} ,
		{"word":"malmenant","word_nosc":"malmenant","lemma":"malmener","pos":"VER"} ,
		{"word":"malmener","word_nosc":"malmener","lemma":"malmener","pos":"VER"} ,
		{"word":"malmenez","word_nosc":"malmenez","lemma":"malmener","pos":"VER"} ,
		{"word":"malmené","word_nosc":"malmene","lemma":"malmener","pos":"VER"} ,
		{"word":"malmenée","word_nosc":"malmenee","lemma":"malmener","pos":"VER"} ,
		{"word":"malmenées","word_nosc":"malmenees","lemma":"malmener","pos":"VER"} ,
		{"word":"malmenés","word_nosc":"malmenes","lemma":"malmener","pos":"VER"} ,
		{"word":"malmène","word_nosc":"malmene","lemma":"malmener","pos":"VER"} ,
		{"word":"malmènent","word_nosc":"malmenent","lemma":"malmener","pos":"VER"} ,
		{"word":"malmèneront","word_nosc":"malmeneront","lemma":"malmener","pos":"VER"} ,
		{"word":"malta","word_nosc":"malta","lemma":"malter","pos":"VER"} ,
		{"word":"maltraitaient","word_nosc":"maltraitaient","lemma":"maltraiter","pos":"VER"} ,
		{"word":"maltraitais","word_nosc":"maltraitais","lemma":"maltraiter","pos":"VER"} ,
		{"word":"maltraitait","word_nosc":"maltraitait","lemma":"maltraiter","pos":"VER"} ,
		{"word":"maltraitant","word_nosc":"maltraitant","lemma":"maltraiter","pos":"VER"} ,
		{"word":"maltraite","word_nosc":"maltraite","lemma":"maltraiter","pos":"VER"} ,
		{"word":"maltraitent","word_nosc":"maltraitent","lemma":"maltraiter","pos":"VER"} ,
		{"word":"maltraiter","word_nosc":"maltraiter","lemma":"maltraiter","pos":"VER"} ,
		{"word":"maltraitera","word_nosc":"maltraitera","lemma":"maltraiter","pos":"VER"} ,
		{"word":"maltraiterez","word_nosc":"maltraiterez","lemma":"maltraiter","pos":"VER"} ,
		{"word":"maltraitez","word_nosc":"maltraitez","lemma":"maltraiter","pos":"VER"} ,
		{"word":"maltraitât","word_nosc":"maltraitat","lemma":"maltraiter","pos":"VER"} ,
		{"word":"maltraitèrent","word_nosc":"maltraiterent","lemma":"maltraiter","pos":"VER"} ,
		{"word":"maltraité","word_nosc":"maltraite","lemma":"maltraiter","pos":"VER"} ,
		{"word":"maltraitée","word_nosc":"maltraitee","lemma":"maltraiter","pos":"VER"} ,
		{"word":"maltraitées","word_nosc":"maltraitees","lemma":"maltraiter","pos":"VER"} ,
		{"word":"maltraités","word_nosc":"maltraites","lemma":"maltraiter","pos":"VER"} ,
		{"word":"malté","word_nosc":"malte","lemma":"malter","pos":"VER"} ,
		{"word":"maltée","word_nosc":"maltee","lemma":"malter","pos":"VER"} ,
		{"word":"maléficier","word_nosc":"maleficier","lemma":"maléficier","pos":"VER"} ,
		{"word":"manage","word_nosc":"manage","lemma":"manager","pos":"VER"} ,
		{"word":"manageait","word_nosc":"manageait","lemma":"manager","pos":"VER"} ,
		{"word":"manager","word_nosc":"manager","lemma":"manager","pos":"VER"} ,
		{"word":"managerais","word_nosc":"managerais","lemma":"manager","pos":"VER"} ,
		{"word":"mandai","word_nosc":"mandai","lemma":"mander","pos":"VER"} ,
		{"word":"mandaient","word_nosc":"mandaient","lemma":"mander","pos":"VER"} ,
		{"word":"mandait","word_nosc":"mandait","lemma":"mander","pos":"VER"} ,
		{"word":"mandas","word_nosc":"mandas","lemma":"mander","pos":"VER"} ,
		{"word":"mandatait","word_nosc":"mandatait","lemma":"mandater","pos":"VER"} ,
		{"word":"mandatant","word_nosc":"mandatant","lemma":"mandater","pos":"VER"} ,
		{"word":"mandater","word_nosc":"mandater","lemma":"mandater","pos":"VER"} ,
		{"word":"mandatez","word_nosc":"mandatez","lemma":"mandater","pos":"VER"} ,
		{"word":"mandaté","word_nosc":"mandate","lemma":"mandater","pos":"VER"} ,
		{"word":"mandatée","word_nosc":"mandatee","lemma":"mandater","pos":"VER"} ,
		{"word":"mandatés","word_nosc":"mandates","lemma":"mandater","pos":"VER"} ,
		{"word":"mande","word_nosc":"mande","lemma":"mander","pos":"VER"} ,
		{"word":"mander","word_nosc":"mander","lemma":"mander","pos":"VER"} ,
		{"word":"mandera","word_nosc":"mandera","lemma":"mander","pos":"VER"} ,
		{"word":"manderai","word_nosc":"manderai","lemma":"mander","pos":"VER"} ,
		{"word":"manderaient","word_nosc":"manderaient","lemma":"mander","pos":"VER"} ,
		{"word":"manderais","word_nosc":"manderais","lemma":"mander","pos":"VER"} ,
		{"word":"mandes","word_nosc":"mandes","lemma":"mander","pos":"VER"} ,
		{"word":"mandez","word_nosc":"mandez","lemma":"mander","pos":"VER"} ,
		{"word":"mandèrent","word_nosc":"manderent","lemma":"mander","pos":"VER"} ,
		{"word":"mandé","word_nosc":"mande","lemma":"mander","pos":"VER"} ,
		{"word":"mandée","word_nosc":"mandee","lemma":"mander","pos":"VER"} ,
		{"word":"mange","word_nosc":"mange","lemma":"manger","pos":"VER"} ,
		{"word":"mangea","word_nosc":"mangea","lemma":"manger","pos":"VER"} ,
		{"word":"mangeai","word_nosc":"mangeai","lemma":"manger","pos":"VER"} ,
		{"word":"mangeaient","word_nosc":"mangeaient","lemma":"manger","pos":"VER"} ,
		{"word":"mangeais","word_nosc":"mangeais","lemma":"manger","pos":"VER"} ,
		{"word":"mangeait","word_nosc":"mangeait","lemma":"manger","pos":"VER"} ,
		{"word":"mangeant","word_nosc":"mangeant","lemma":"manger","pos":"VER"} ,
		{"word":"mangeas","word_nosc":"mangeas","lemma":"manger","pos":"VER"} ,
		{"word":"mangeasse","word_nosc":"mangeasse","lemma":"manger","pos":"VER"} ,
		{"word":"mangent","word_nosc":"mangent","lemma":"manger","pos":"VER"} ,
		{"word":"mangeons","word_nosc":"mangeons","lemma":"manger","pos":"VER"} ,
		{"word":"manger","word_nosc":"manger","lemma":"manger","pos":"VER"} ,
		{"word":"mangera","word_nosc":"mangera","lemma":"manger","pos":"VER"} ,
		{"word":"mangerai","word_nosc":"mangerai","lemma":"manger","pos":"VER"} ,
		{"word":"mangeraient","word_nosc":"mangeraient","lemma":"manger","pos":"VER"} ,
		{"word":"mangerais","word_nosc":"mangerais","lemma":"manger","pos":"VER"} ,
		{"word":"mangerait","word_nosc":"mangerait","lemma":"manger","pos":"VER"} ,
		{"word":"mangeras","word_nosc":"mangeras","lemma":"manger","pos":"VER"} ,
		{"word":"mangerez","word_nosc":"mangerez","lemma":"manger","pos":"VER"} ,
		{"word":"mangeriez","word_nosc":"mangeriez","lemma":"manger","pos":"VER"} ,
		{"word":"mangerions","word_nosc":"mangerions","lemma":"manger","pos":"VER"} ,
		{"word":"mangerons","word_nosc":"mangerons","lemma":"manger","pos":"VER"} ,
		{"word":"mangeront","word_nosc":"mangeront","lemma":"manger","pos":"VER"} ,
		{"word":"manges","word_nosc":"manges","lemma":"manger","pos":"VER"} ,
		{"word":"mangez","word_nosc":"mangez","lemma":"manger","pos":"VER"} ,
		{"word":"mangeâmes","word_nosc":"mangeames","lemma":"manger","pos":"VER"} ,
		{"word":"mangeât","word_nosc":"mangeat","lemma":"manger","pos":"VER"} ,
		{"word":"mangiez","word_nosc":"mangiez","lemma":"manger","pos":"VER"} ,
		{"word":"mangions","word_nosc":"mangions","lemma":"manger","pos":"VER"} ,
		{"word":"mangèrent","word_nosc":"mangerent","lemma":"manger","pos":"VER"} ,
		{"word":"mangé","word_nosc":"mange","lemma":"manger","pos":"VER"} ,
		{"word":"mangée","word_nosc":"mangee","lemma":"manger","pos":"VER"} ,
		{"word":"mangées","word_nosc":"mangees","lemma":"manger","pos":"VER"} ,
		{"word":"mangés","word_nosc":"manges","lemma":"manger","pos":"VER"} ,
		{"word":"mania","word_nosc":"mania","lemma":"manier","pos":"VER"} ,
		{"word":"maniaient","word_nosc":"maniaient","lemma":"manier","pos":"VER"} ,
		{"word":"maniais","word_nosc":"maniais","lemma":"manier","pos":"VER"} ,
		{"word":"maniait","word_nosc":"maniait","lemma":"manier","pos":"VER"} ,
		{"word":"maniant","word_nosc":"maniant","lemma":"manier","pos":"VER"} ,
		{"word":"manie","word_nosc":"manie","lemma":"manier","pos":"VER"} ,
		{"word":"manient","word_nosc":"manient","lemma":"manier","pos":"VER"} ,
		{"word":"manier","word_nosc":"manier","lemma":"manier","pos":"VER"} ,
		{"word":"manierais","word_nosc":"manierais","lemma":"manier","pos":"VER"} ,
		{"word":"manieront","word_nosc":"manieront","lemma":"manier","pos":"VER"} ,
		{"word":"manies","word_nosc":"manies","lemma":"manier","pos":"VER"} ,
		{"word":"maniez","word_nosc":"maniez","lemma":"manier","pos":"VER"} ,
		{"word":"manifesta","word_nosc":"manifesta","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestai","word_nosc":"manifestai","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestaient","word_nosc":"manifestaient","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestais","word_nosc":"manifestais","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestait","word_nosc":"manifestait","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestant","word_nosc":"manifestant","lemma":"manifester","pos":"VER"} ,
		{"word":"manifeste","word_nosc":"manifeste","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestent","word_nosc":"manifestent","lemma":"manifester","pos":"VER"} ,
		{"word":"manifester","word_nosc":"manifester","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestera","word_nosc":"manifestera","lemma":"manifester","pos":"VER"} ,
		{"word":"manifesterai","word_nosc":"manifesterai","lemma":"manifester","pos":"VER"} ,
		{"word":"manifesteraient","word_nosc":"manifesteraient","lemma":"manifester","pos":"VER"} ,
		{"word":"manifesterais","word_nosc":"manifesterais","lemma":"manifester","pos":"VER"} ,
		{"word":"manifesterait","word_nosc":"manifesterait","lemma":"manifester","pos":"VER"} ,
		{"word":"manifesteront","word_nosc":"manifesteront","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestes","word_nosc":"manifestes","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestez","word_nosc":"manifestez","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestiez","word_nosc":"manifestiez","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestions","word_nosc":"manifestions","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestons","word_nosc":"manifestons","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestât","word_nosc":"manifestat","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestèrent","word_nosc":"manifesterent","lemma":"manifester","pos":"VER"} ,
		{"word":"manifesté","word_nosc":"manifeste","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestée","word_nosc":"manifestee","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestées","word_nosc":"manifestees","lemma":"manifester","pos":"VER"} ,
		{"word":"manifestés","word_nosc":"manifestes","lemma":"manifester","pos":"VER"} ,
		{"word":"manigance","word_nosc":"manigance","lemma":"manigancer","pos":"VER"} ,
		{"word":"manigancent","word_nosc":"manigancent","lemma":"manigancer","pos":"VER"} ,
		{"word":"manigancer","word_nosc":"manigancer","lemma":"manigancer","pos":"VER"} ,
		{"word":"manigancerait","word_nosc":"manigancerait","lemma":"manigancer","pos":"VER"} ,
		{"word":"manigances","word_nosc":"manigances","lemma":"manigancer","pos":"VER"} ,
		{"word":"manigancez","word_nosc":"manigancez","lemma":"manigancer","pos":"VER"} ,
		{"word":"maniganciez","word_nosc":"maniganciez","lemma":"manigancer","pos":"VER"} ,
		{"word":"manigancé","word_nosc":"manigance","lemma":"manigancer","pos":"VER"} ,
		{"word":"manigancée","word_nosc":"manigancee","lemma":"manigancer","pos":"VER"} ,
		{"word":"manigançaient","word_nosc":"manigancaient","lemma":"manigancer","pos":"VER"} ,
		{"word":"manigançais","word_nosc":"manigancais","lemma":"manigancer","pos":"VER"} ,
		{"word":"manigançait","word_nosc":"manigancait","lemma":"manigancer","pos":"VER"} ,
		{"word":"manilla","word_nosc":"manilla","lemma":"maniller","pos":"VER"} ,
		{"word":"manipula","word_nosc":"manipula","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipulaient","word_nosc":"manipulaient","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipulais","word_nosc":"manipulais","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipulait","word_nosc":"manipulait","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipulant","word_nosc":"manipulant","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipule","word_nosc":"manipule","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipulent","word_nosc":"manipulent","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipuler","word_nosc":"manipuler","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipulera","word_nosc":"manipulera","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipulerai","word_nosc":"manipulerai","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipulerait","word_nosc":"manipulerait","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipuleras","word_nosc":"manipuleras","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipules","word_nosc":"manipules","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipulez","word_nosc":"manipulez","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipulons","word_nosc":"manipulons","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipulé","word_nosc":"manipule","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipulée","word_nosc":"manipulee","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipulées","word_nosc":"manipulees","lemma":"manipuler","pos":"VER"} ,
		{"word":"manipulés","word_nosc":"manipules","lemma":"manipuler","pos":"VER"} ,
		{"word":"manièrent","word_nosc":"manierent","lemma":"manier","pos":"VER"} ,
		{"word":"manié","word_nosc":"manie","lemma":"manier","pos":"VER"} ,
		{"word":"maniée","word_nosc":"maniee","lemma":"manier","pos":"VER"} ,
		{"word":"maniées","word_nosc":"maniees","lemma":"manier","pos":"VER"} ,
		{"word":"maniéré","word_nosc":"maniere","lemma":"maniérer","pos":"VER"} ,
		{"word":"maniérées","word_nosc":"manierees","lemma":"maniérer","pos":"VER"} ,
		{"word":"maniérés","word_nosc":"manieres","lemma":"maniérer","pos":"VER"} ,
		{"word":"maniés","word_nosc":"manies","lemma":"manier","pos":"VER"} ,
		{"word":"mannequine","word_nosc":"mannequine","lemma":"mannequiner","pos":"VER"} ,
		{"word":"manoeuvra","word_nosc":"manoeuvra","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manoeuvraient","word_nosc":"manoeuvraient","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manoeuvrais","word_nosc":"manoeuvrais","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manoeuvrait","word_nosc":"manoeuvrait","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manoeuvrant","word_nosc":"manoeuvrant","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manoeuvre","word_nosc":"manoeuvre","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manoeuvrent","word_nosc":"manoeuvrent","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manoeuvrer","word_nosc":"manoeuvrer","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manoeuvres","word_nosc":"manoeuvres","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manoeuvrez","word_nosc":"manoeuvrez","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manoeuvrons","word_nosc":"manoeuvrons","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manoeuvrèrent","word_nosc":"manoeuvrerent","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manoeuvré","word_nosc":"manoeuvre","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manoeuvrée","word_nosc":"manoeuvree","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manoeuvrées","word_nosc":"manoeuvrees","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manoeuvrés","word_nosc":"manoeuvres","lemma":"manoeuvrer","pos":"VER"} ,
		{"word":"manqua","word_nosc":"manqua","lemma":"manquer","pos":"VER"} ,
		{"word":"manquai","word_nosc":"manquai","lemma":"manquer","pos":"VER"} ,
		{"word":"manquaient","word_nosc":"manquaient","lemma":"manquer","pos":"VER"} ,
		{"word":"manquais","word_nosc":"manquais","lemma":"manquer","pos":"VER"} ,
		{"word":"manquait","word_nosc":"manquait","lemma":"manquer","pos":"VER"} ,
		{"word":"manquant","word_nosc":"manquant","lemma":"manquer","pos":"VER"} ,
		{"word":"manquassent","word_nosc":"manquassent","lemma":"manquer","pos":"VER"} ,
		{"word":"manque","word_nosc":"manque","lemma":"manquer","pos":"VER"} ,
		{"word":"manquent","word_nosc":"manquent","lemma":"manquer","pos":"VER"} ,
		{"word":"manquer","word_nosc":"manquer","lemma":"manquer","pos":"VER"} ,
		{"word":"manquera","word_nosc":"manquera","lemma":"manquer","pos":"VER"} ,
		{"word":"manquerai","word_nosc":"manquerai","lemma":"manquer","pos":"VER"} ,
		{"word":"manqueraient","word_nosc":"manqueraient","lemma":"manquer","pos":"VER"} ,
		{"word":"manquerais","word_nosc":"manquerais","lemma":"manquer","pos":"VER"} ,
		{"word":"manquerait","word_nosc":"manquerait","lemma":"manquer","pos":"VER"} ,
		{"word":"manqueras","word_nosc":"manqueras","lemma":"manquer","pos":"VER"} ,
		{"word":"manquerez","word_nosc":"manquerez","lemma":"manquer","pos":"VER"} ,
		{"word":"manqueriez","word_nosc":"manqueriez","lemma":"manquer","pos":"VER"} ,
		{"word":"manquerions","word_nosc":"manquerions","lemma":"manquer","pos":"VER"} ,
		{"word":"manquerons","word_nosc":"manquerons","lemma":"manquer","pos":"VER"} ,
		{"word":"manqueront","word_nosc":"manqueront","lemma":"manquer","pos":"VER"} ,
		{"word":"manques","word_nosc":"manques","lemma":"manquer","pos":"VER"} ,
		{"word":"manquez","word_nosc":"manquez","lemma":"manquer","pos":"VER"} ,
		{"word":"manquiez","word_nosc":"manquiez","lemma":"manquer","pos":"VER"} ,
		{"word":"manquions","word_nosc":"manquions","lemma":"manquer","pos":"VER"} ,
		{"word":"manquons","word_nosc":"manquons","lemma":"manquer","pos":"VER"} ,
		{"word":"manquâmes","word_nosc":"manquames","lemma":"manquer","pos":"VER"} ,
		{"word":"manquât","word_nosc":"manquat","lemma":"manquer","pos":"VER"} ,
		{"word":"manquèrent","word_nosc":"manquerent","lemma":"manquer","pos":"VER"} ,
		{"word":"manqué","word_nosc":"manque","lemma":"manquer","pos":"VER"} ,
		{"word":"manquée","word_nosc":"manquee","lemma":"manquer","pos":"VER"} ,
		{"word":"manquées","word_nosc":"manquees","lemma":"manquer","pos":"VER"} ,
		{"word":"manqués","word_nosc":"manques","lemma":"manquer","pos":"VER"} ,
		{"word":"mansardé","word_nosc":"mansarde","lemma":"mansarder","pos":"VER"} ,
		{"word":"mansardée","word_nosc":"mansardee","lemma":"mansarder","pos":"VER"} ,
		{"word":"mansardées","word_nosc":"mansardees","lemma":"mansarder","pos":"VER"} ,
		{"word":"manucure","word_nosc":"manucure","lemma":"manucurer","pos":"VER"} ,
		{"word":"manucurer","word_nosc":"manucurer","lemma":"manucurer","pos":"VER"} ,
		{"word":"manucuré","word_nosc":"manucure","lemma":"manucurer","pos":"VER"} ,
		{"word":"manucurée","word_nosc":"manucuree","lemma":"manucurer","pos":"VER"} ,
		{"word":"manucurées","word_nosc":"manucurees","lemma":"manucurer","pos":"VER"} ,
		{"word":"manucurés","word_nosc":"manucures","lemma":"manucurer","pos":"VER"} ,
		{"word":"manufacturer","word_nosc":"manufacturer","lemma":"manufacturer","pos":"VER"} ,
		{"word":"manufactures","word_nosc":"manufactures","lemma":"manufacturer","pos":"VER"} ,
		{"word":"manufacturons","word_nosc":"manufacturons","lemma":"manufacturer","pos":"VER"} ,
		{"word":"manufacturé","word_nosc":"manufacture","lemma":"manufacturer","pos":"VER"} ,
		{"word":"manufacturée","word_nosc":"manufacturee","lemma":"manufacturer","pos":"VER"} ,
		{"word":"manufacturées","word_nosc":"manufacturees","lemma":"manufacturer","pos":"VER"} ,
		{"word":"manufacturés","word_nosc":"manufactures","lemma":"manufacturer","pos":"VER"} ,
		{"word":"manutentionner","word_nosc":"manutentionner","lemma":"manutentionner","pos":"VER"} ,
		{"word":"manégés","word_nosc":"maneges","lemma":"manéger","pos":"VER"} ,
		{"word":"maquaient","word_nosc":"maquaient","lemma":"maquer","pos":"VER"} ,
		{"word":"maque","word_nosc":"maque","lemma":"maquer","pos":"VER"} ,
		{"word":"maquent","word_nosc":"maquent","lemma":"maquer","pos":"VER"} ,
		{"word":"maquer","word_nosc":"maquer","lemma":"maquer","pos":"VER"} ,
		{"word":"maquereauter","word_nosc":"maquereauter","lemma":"maquereauter","pos":"VER"} ,
		{"word":"maques","word_nosc":"maques","lemma":"maquer","pos":"VER"} ,
		{"word":"maquignonne","word_nosc":"maquignonne","lemma":"maquignonner","pos":"VER"} ,
		{"word":"maquignonné","word_nosc":"maquignonne","lemma":"maquignonner","pos":"VER"} ,
		{"word":"maquignonnés","word_nosc":"maquignonnes","lemma":"maquignonner","pos":"VER"} ,
		{"word":"maquilla","word_nosc":"maquilla","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquillai","word_nosc":"maquillai","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquillais","word_nosc":"maquillais","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquillait","word_nosc":"maquillait","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquillant","word_nosc":"maquillant","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquille","word_nosc":"maquille","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquillent","word_nosc":"maquillent","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquiller","word_nosc":"maquiller","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquillerai","word_nosc":"maquillerai","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquillerais","word_nosc":"maquillerais","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquilleras","word_nosc":"maquilleras","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquilles","word_nosc":"maquilles","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquillez","word_nosc":"maquillez","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquilliez","word_nosc":"maquilliez","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquillons","word_nosc":"maquillons","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquillé","word_nosc":"maquille","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquillée","word_nosc":"maquillee","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquillées","word_nosc":"maquillees","lemma":"maquiller","pos":"VER"} ,
		{"word":"maquillés","word_nosc":"maquilles","lemma":"maquiller","pos":"VER"} ,
		{"word":"maqué","word_nosc":"maque","lemma":"maquer","pos":"VER"} ,
		{"word":"maquée","word_nosc":"maquee","lemma":"maquer","pos":"VER"} ,
		{"word":"maqués","word_nosc":"maques","lemma":"maquer","pos":"VER"} ,
		{"word":"maraudaient","word_nosc":"maraudaient","lemma":"marauder","pos":"VER"} ,
		{"word":"maraudait","word_nosc":"maraudait","lemma":"marauder","pos":"VER"} ,
		{"word":"maraude","word_nosc":"maraude","lemma":"marauder","pos":"VER"} ,
		{"word":"marauder","word_nosc":"marauder","lemma":"marauder","pos":"VER"} ,
		{"word":"maraudé","word_nosc":"maraude","lemma":"marauder","pos":"VER"} ,
		{"word":"maraudés","word_nosc":"maraudes","lemma":"marauder","pos":"VER"} ,
		{"word":"marbra","word_nosc":"marbra","lemma":"marbrer","pos":"VER"} ,
		{"word":"marbraient","word_nosc":"marbraient","lemma":"marbrer","pos":"VER"} ,
		{"word":"marbrait","word_nosc":"marbrait","lemma":"marbrer","pos":"VER"} ,
		{"word":"marbre","word_nosc":"marbre","lemma":"marbrer","pos":"VER"} ,
		{"word":"marbrent","word_nosc":"marbrent","lemma":"marbrer","pos":"VER"} ,
		{"word":"marbré","word_nosc":"marbre","lemma":"marbrer","pos":"VER"} ,
		{"word":"marbrée","word_nosc":"marbree","lemma":"marbrer","pos":"VER"} ,
		{"word":"marbrées","word_nosc":"marbrees","lemma":"marbrer","pos":"VER"} ,
		{"word":"marbrés","word_nosc":"marbres","lemma":"marbrer","pos":"VER"} ,
		{"word":"marcha","word_nosc":"marcha","lemma":"marcher","pos":"VER"} ,
		{"word":"marchai","word_nosc":"marchai","lemma":"marcher","pos":"VER"} ,
		{"word":"marchaient","word_nosc":"marchaient","lemma":"marcher","pos":"VER"} ,
		{"word":"marchais","word_nosc":"marchais","lemma":"marcher","pos":"VER"} ,
		{"word":"marchait","word_nosc":"marchait","lemma":"marcher","pos":"VER"} ,
		{"word":"marchandaient","word_nosc":"marchandaient","lemma":"marchander","pos":"VER"} ,
		{"word":"marchandais","word_nosc":"marchandais","lemma":"marchander","pos":"VER"} ,
		{"word":"marchandait","word_nosc":"marchandait","lemma":"marchander","pos":"VER"} ,
		{"word":"marchandant","word_nosc":"marchandant","lemma":"marchander","pos":"VER"} ,
		{"word":"marchande","word_nosc":"marchande","lemma":"marchander","pos":"VER"} ,
		{"word":"marchandent","word_nosc":"marchandent","lemma":"marchander","pos":"VER"} ,
		{"word":"marchander","word_nosc":"marchander","lemma":"marchander","pos":"VER"} ,
		{"word":"marchandera","word_nosc":"marchandera","lemma":"marchander","pos":"VER"} ,
		{"word":"marchanderais","word_nosc":"marchanderais","lemma":"marchander","pos":"VER"} ,
		{"word":"marchanderez","word_nosc":"marchanderez","lemma":"marchander","pos":"VER"} ,
		{"word":"marchandes","word_nosc":"marchandes","lemma":"marchander","pos":"VER"} ,
		{"word":"marchandez","word_nosc":"marchandez","lemma":"marchander","pos":"VER"} ,
		{"word":"marchandons","word_nosc":"marchandons","lemma":"marchander","pos":"VER"} ,
		{"word":"marchandé","word_nosc":"marchande","lemma":"marchander","pos":"VER"} ,
		{"word":"marchandée","word_nosc":"marchandee","lemma":"marchander","pos":"VER"} ,
		{"word":"marchant","word_nosc":"marchant","lemma":"marcher","pos":"VER"} ,
		{"word":"marche","word_nosc":"marche","lemma":"marcher","pos":"VER"} ,
		{"word":"marchent","word_nosc":"marchent","lemma":"marcher","pos":"VER"} ,
		{"word":"marcher","word_nosc":"marcher","lemma":"marcher","pos":"VER"} ,
		{"word":"marchera","word_nosc":"marchera","lemma":"marcher","pos":"VER"} ,
		{"word":"marcherai","word_nosc":"marcherai","lemma":"marcher","pos":"VER"} ,
		{"word":"marcheraient","word_nosc":"marcheraient","lemma":"marcher","pos":"VER"} ,
		{"word":"marcherais","word_nosc":"marcherais","lemma":"marcher","pos":"VER"} ,
		{"word":"marcherait","word_nosc":"marcherait","lemma":"marcher","pos":"VER"} ,
		{"word":"marcheras","word_nosc":"marcheras","lemma":"marcher","pos":"VER"} ,
		{"word":"marcherez","word_nosc":"marcherez","lemma":"marcher","pos":"VER"} ,
		{"word":"marcheriez","word_nosc":"marcheriez","lemma":"marcher","pos":"VER"} ,
		{"word":"marcherions","word_nosc":"marcherions","lemma":"marcher","pos":"VER"} ,
		{"word":"marcherons","word_nosc":"marcherons","lemma":"marcher","pos":"VER"} ,
		{"word":"marcheront","word_nosc":"marcheront","lemma":"marcher","pos":"VER"} ,
		{"word":"marches","word_nosc":"marches","lemma":"marcher","pos":"VER"} ,
		{"word":"marchez","word_nosc":"marchez","lemma":"marcher","pos":"VER"} ,
		{"word":"marchiez","word_nosc":"marchiez","lemma":"marcher","pos":"VER"} ,
		{"word":"marchions","word_nosc":"marchions","lemma":"marcher","pos":"VER"} ,
		{"word":"marchons","word_nosc":"marchons","lemma":"marcher","pos":"VER"} ,
		{"word":"marchâmes","word_nosc":"marchames","lemma":"marcher","pos":"VER"} ,
		{"word":"marchât","word_nosc":"marchat","lemma":"marcher","pos":"VER"} ,
		{"word":"marchèrent","word_nosc":"marcherent","lemma":"marcher","pos":"VER"} ,
		{"word":"marché","word_nosc":"marche","lemma":"marcher","pos":"VER"} ,
		{"word":"marchées","word_nosc":"marchees","lemma":"marcher","pos":"VER"} ,
		{"word":"marchés","word_nosc":"marches","lemma":"marcher","pos":"VER"} ,
		{"word":"margeait","word_nosc":"margeait","lemma":"marger","pos":"VER"} ,
		{"word":"marger","word_nosc":"marger","lemma":"marger","pos":"VER"} ,
		{"word":"margina","word_nosc":"margina","lemma":"marginer","pos":"VER"} ,
		{"word":"marginalisant","word_nosc":"marginalisant","lemma":"marginaliser","pos":"VER"} ,
		{"word":"marginaliser","word_nosc":"marginaliser","lemma":"marginaliser","pos":"VER"} ,
		{"word":"marginaliseraient","word_nosc":"marginaliseraient","lemma":"marginaliser","pos":"VER"} ,
		{"word":"marginalisé","word_nosc":"marginalise","lemma":"marginaliser","pos":"VER"} ,
		{"word":"marginalisée","word_nosc":"marginalisee","lemma":"marginaliser","pos":"VER"} ,
		{"word":"marginalisés","word_nosc":"marginalises","lemma":"marginaliser","pos":"VER"} ,
		{"word":"marginée","word_nosc":"marginee","lemma":"marginer","pos":"VER"} ,
		{"word":"margotait","word_nosc":"margotait","lemma":"margoter","pos":"VER"} ,
		{"word":"margotte","word_nosc":"margotte","lemma":"margotter","pos":"VER"} ,
		{"word":"margottons","word_nosc":"margottons","lemma":"margotter","pos":"VER"} ,
		{"word":"maria","word_nosc":"maria","lemma":"marier","pos":"VER"} ,
		{"word":"mariai","word_nosc":"mariai","lemma":"marier","pos":"VER"} ,
		{"word":"mariaient","word_nosc":"mariaient","lemma":"marier","pos":"VER"} ,
		{"word":"mariais","word_nosc":"mariais","lemma":"marier","pos":"VER"} ,
		{"word":"mariait","word_nosc":"mariait","lemma":"marier","pos":"VER"} ,
		{"word":"mariant","word_nosc":"mariant","lemma":"marier","pos":"VER"} ,
		{"word":"marias","word_nosc":"marias","lemma":"marier","pos":"VER"} ,
		{"word":"mariassent","word_nosc":"mariassent","lemma":"marier","pos":"VER"} ,
		{"word":"marie","word_nosc":"marie","lemma":"marier","pos":"VER"} ,
		{"word":"marient","word_nosc":"marient","lemma":"marier","pos":"VER"} ,
		{"word":"marier","word_nosc":"marier","lemma":"marier","pos":"VER"} ,
		{"word":"mariera","word_nosc":"mariera","lemma":"marier","pos":"VER"} ,
		{"word":"marierai","word_nosc":"marierai","lemma":"marier","pos":"VER"} ,
		{"word":"marieraient","word_nosc":"marieraient","lemma":"marier","pos":"VER"} ,
		{"word":"marierais","word_nosc":"marierais","lemma":"marier","pos":"VER"} ,
		{"word":"marierait","word_nosc":"marierait","lemma":"marier","pos":"VER"} ,
		{"word":"marieras","word_nosc":"marieras","lemma":"marier","pos":"VER"} ,
		{"word":"marierez","word_nosc":"marierez","lemma":"marier","pos":"VER"} ,
		{"word":"marieriez","word_nosc":"marieriez","lemma":"marier","pos":"VER"} ,
		{"word":"marierions","word_nosc":"marierions","lemma":"marier","pos":"VER"} ,
		{"word":"marierons","word_nosc":"marierons","lemma":"marier","pos":"VER"} ,
		{"word":"marieront","word_nosc":"marieront","lemma":"marier","pos":"VER"} ,
		{"word":"maries","word_nosc":"maries","lemma":"marier","pos":"VER"} ,
		{"word":"mariez","word_nosc":"mariez","lemma":"marier","pos":"VER"} ,
		{"word":"mariions","word_nosc":"mariions","lemma":"marier","pos":"VER"} ,
		{"word":"marina","word_nosc":"marina","lemma":"mariner","pos":"VER"} ,
		{"word":"marinaient","word_nosc":"marinaient","lemma":"mariner","pos":"VER"} ,
		{"word":"marinait","word_nosc":"marinait","lemma":"mariner","pos":"VER"} ,
		{"word":"marinant","word_nosc":"marinant","lemma":"mariner","pos":"VER"} ,
		{"word":"marine","word_nosc":"marine","lemma":"mariner","pos":"VER"} ,
		{"word":"mariner","word_nosc":"mariner","lemma":"mariner","pos":"VER"} ,
		{"word":"marinera","word_nosc":"marinera","lemma":"mariner","pos":"VER"} ,
		{"word":"mariné","word_nosc":"marine","lemma":"mariner","pos":"VER"} ,
		{"word":"marinée","word_nosc":"marinee","lemma":"mariner","pos":"VER"} ,
		{"word":"marinées","word_nosc":"marinees","lemma":"mariner","pos":"VER"} ,
		{"word":"marinés","word_nosc":"marines","lemma":"mariner","pos":"VER"} ,
		{"word":"marions","word_nosc":"marions","lemma":"marier","pos":"VER"} ,
		{"word":"marivaudant","word_nosc":"marivaudant","lemma":"marivauder","pos":"VER"} ,
		{"word":"marivauder","word_nosc":"marivauder","lemma":"marivauder","pos":"VER"} ,
		{"word":"mariât","word_nosc":"mariat","lemma":"marier","pos":"VER"} ,
		{"word":"marièrent","word_nosc":"marierent","lemma":"marier","pos":"VER"} ,
		{"word":"marié","word_nosc":"marie","lemma":"marier","pos":"VER"} ,
		{"word":"mariée","word_nosc":"mariee","lemma":"marier","pos":"VER"} ,
		{"word":"mariées","word_nosc":"mariees","lemma":"marier","pos":"VER"} ,
		{"word":"mariés","word_nosc":"maries","lemma":"marier","pos":"VER"} ,
		{"word":"marmiter","word_nosc":"marmiter","lemma":"marmiter","pos":"VER"} ,
		{"word":"marmités","word_nosc":"marmites","lemma":"marmiter","pos":"VER"} ,
		{"word":"marmonna","word_nosc":"marmonna","lemma":"marmonner","pos":"VER"} ,
		{"word":"marmonnaient","word_nosc":"marmonnaient","lemma":"marmonner","pos":"VER"} ,
		{"word":"marmonnait","word_nosc":"marmonnait","lemma":"marmonner","pos":"VER"} ,
		{"word":"marmonnant","word_nosc":"marmonnant","lemma":"marmonner","pos":"VER"} ,
		{"word":"marmonne","word_nosc":"marmonne","lemma":"marmonner","pos":"VER"} ,
		{"word":"marmonnent","word_nosc":"marmonnent","lemma":"marmonner","pos":"VER"} ,
		{"word":"marmonner","word_nosc":"marmonner","lemma":"marmonner","pos":"VER"} ,
		{"word":"marmonnes","word_nosc":"marmonnes","lemma":"marmonner","pos":"VER"} ,
		{"word":"marmonnez","word_nosc":"marmonnez","lemma":"marmonner","pos":"VER"} ,
		{"word":"marmonniez","word_nosc":"marmonniez","lemma":"marmonner","pos":"VER"} ,
		{"word":"marmonné","word_nosc":"marmonne","lemma":"marmonner","pos":"VER"} ,
		{"word":"marmonnée","word_nosc":"marmonnee","lemma":"marmonner","pos":"VER"} ,
		{"word":"marmonnés","word_nosc":"marmonnes","lemma":"marmonner","pos":"VER"} ,
		{"word":"marmotta","word_nosc":"marmotta","lemma":"marmotter","pos":"VER"} ,
		{"word":"marmottait","word_nosc":"marmottait","lemma":"marmotter","pos":"VER"} ,
		{"word":"marmottant","word_nosc":"marmottant","lemma":"marmotter","pos":"VER"} ,
		{"word":"marmotte","word_nosc":"marmotte","lemma":"marmotter","pos":"VER"} ,
		{"word":"marmotter","word_nosc":"marmotter","lemma":"marmotter","pos":"VER"} ,
		{"word":"marmottes","word_nosc":"marmottes","lemma":"marmotter","pos":"VER"} ,
		{"word":"marmotté","word_nosc":"marmotte","lemma":"marmotter","pos":"VER"} ,
		{"word":"marna","word_nosc":"marna","lemma":"marner","pos":"VER"} ,
		{"word":"marnait","word_nosc":"marnait","lemma":"marner","pos":"VER"} ,
		{"word":"marne","word_nosc":"marne","lemma":"marner","pos":"VER"} ,
		{"word":"marnent","word_nosc":"marnent","lemma":"marner","pos":"VER"} ,
		{"word":"marner","word_nosc":"marner","lemma":"marner","pos":"VER"} ,
		{"word":"marné","word_nosc":"marne","lemma":"marner","pos":"VER"} ,
		{"word":"maronner","word_nosc":"maronner","lemma":"maronner","pos":"VER"} ,
		{"word":"maronné","word_nosc":"maronne","lemma":"maronner","pos":"VER"} ,
		{"word":"maroufler","word_nosc":"maroufler","lemma":"maroufler","pos":"VER"} ,
		{"word":"marqua","word_nosc":"marqua","lemma":"marquer","pos":"VER"} ,
		{"word":"marquai","word_nosc":"marquai","lemma":"marquer","pos":"VER"} ,
		{"word":"marquaient","word_nosc":"marquaient","lemma":"marquer","pos":"VER"} ,
		{"word":"marquais","word_nosc":"marquais","lemma":"marquer","pos":"VER"} ,
		{"word":"marquait","word_nosc":"marquait","lemma":"marquer","pos":"VER"} ,
		{"word":"marquant","word_nosc":"marquant","lemma":"marquer","pos":"VER"} ,
		{"word":"marque","word_nosc":"marque","lemma":"marquer","pos":"VER"} ,
		{"word":"marquent","word_nosc":"marquent","lemma":"marquer","pos":"VER"} ,
		{"word":"marquer","word_nosc":"marquer","lemma":"marquer","pos":"VER"} ,
		{"word":"marquera","word_nosc":"marquera","lemma":"marquer","pos":"VER"} ,
		{"word":"marquerai","word_nosc":"marquerai","lemma":"marquer","pos":"VER"} ,
		{"word":"marqueraient","word_nosc":"marqueraient","lemma":"marquer","pos":"VER"} ,
		{"word":"marquerait","word_nosc":"marquerait","lemma":"marquer","pos":"VER"} ,
		{"word":"marqueras","word_nosc":"marqueras","lemma":"marquer","pos":"VER"} ,
		{"word":"marquerez","word_nosc":"marquerez","lemma":"marquer","pos":"VER"} ,
		{"word":"marquerons","word_nosc":"marquerons","lemma":"marquer","pos":"VER"} ,
		{"word":"marqueront","word_nosc":"marqueront","lemma":"marquer","pos":"VER"} ,
		{"word":"marques","word_nosc":"marques","lemma":"marquer","pos":"VER"} ,
		{"word":"marquette","word_nosc":"marquette","lemma":"marqueter","pos":"VER"} ,
		{"word":"marqueté","word_nosc":"marquete","lemma":"marqueter","pos":"VER"} ,
		{"word":"marquetées","word_nosc":"marquetees","lemma":"marqueter","pos":"VER"} ,
		{"word":"marquetés","word_nosc":"marquetes","lemma":"marqueter","pos":"VER"} ,
		{"word":"marquez","word_nosc":"marquez","lemma":"marquer","pos":"VER"} ,
		{"word":"marquiez","word_nosc":"marquiez","lemma":"marquer","pos":"VER"} ,
		{"word":"marquions","word_nosc":"marquions","lemma":"marquer","pos":"VER"} ,
		{"word":"marquons","word_nosc":"marquons","lemma":"marquer","pos":"VER"} ,
		{"word":"marquât","word_nosc":"marquat","lemma":"marquer","pos":"VER"} ,
		{"word":"marquèrent","word_nosc":"marquerent","lemma":"marquer","pos":"VER"} ,
		{"word":"marqué","word_nosc":"marque","lemma":"marquer","pos":"VER"} ,
		{"word":"marquée","word_nosc":"marquee","lemma":"marquer","pos":"VER"} ,
		{"word":"marquées","word_nosc":"marquees","lemma":"marquer","pos":"VER"} ,
		{"word":"marqués","word_nosc":"marques","lemma":"marquer","pos":"VER"} ,
		{"word":"marra","word_nosc":"marra","lemma":"marrer","pos":"VER"} ,
		{"word":"marraient","word_nosc":"marraient","lemma":"marrer","pos":"VER"} ,
		{"word":"marrais","word_nosc":"marrais","lemma":"marrer","pos":"VER"} ,
		{"word":"marrait","word_nosc":"marrait","lemma":"marrer","pos":"VER"} ,
		{"word":"marrant","word_nosc":"marrant","lemma":"marrer","pos":"VER"} ,
		{"word":"marre","word_nosc":"marre","lemma":"marrer","pos":"VER"} ,
		{"word":"marrent","word_nosc":"marrent","lemma":"marrer","pos":"VER"} ,
		{"word":"marrer","word_nosc":"marrer","lemma":"marrer","pos":"VER"} ,
		{"word":"marrera","word_nosc":"marrera","lemma":"marrer","pos":"VER"} ,
		{"word":"marrerais","word_nosc":"marrerais","lemma":"marrer","pos":"VER"} ,
		{"word":"marrerait","word_nosc":"marrerait","lemma":"marrer","pos":"VER"} ,
		{"word":"marres","word_nosc":"marres","lemma":"marrer","pos":"VER"} ,
		{"word":"marrez","word_nosc":"marrez","lemma":"marrer","pos":"VER"} ,
		{"word":"marrions","word_nosc":"marrions","lemma":"marrer","pos":"VER"} ,
		{"word":"marronnais","word_nosc":"marronnais","lemma":"marronner","pos":"VER"} ,
		{"word":"marronner","word_nosc":"marronner","lemma":"marronner","pos":"VER"} ,
		{"word":"marronnera","word_nosc":"marronnera","lemma":"marronner","pos":"VER"} ,
		{"word":"marronné","word_nosc":"marronne","lemma":"marronner","pos":"VER"} ,
		{"word":"marrons","word_nosc":"marrons","lemma":"marrer","pos":"VER"} ,
		{"word":"marré","word_nosc":"marre","lemma":"marrer","pos":"VER"} ,
		{"word":"marrée","word_nosc":"marree","lemma":"marrer","pos":"VER"} ,
		{"word":"marrés","word_nosc":"marres","lemma":"marrer","pos":"VER"} ,
		{"word":"martela","word_nosc":"martela","lemma":"marteler","pos":"VER"} ,
		{"word":"martelai","word_nosc":"martelai","lemma":"marteler","pos":"VER"} ,
		{"word":"martelaient","word_nosc":"martelaient","lemma":"marteler","pos":"VER"} ,
		{"word":"martelait","word_nosc":"martelait","lemma":"marteler","pos":"VER"} ,
		{"word":"martelant","word_nosc":"martelant","lemma":"marteler","pos":"VER"} ,
		{"word":"marteler","word_nosc":"marteler","lemma":"marteler","pos":"VER"} ,
		{"word":"martelez","word_nosc":"martelez","lemma":"marteler","pos":"VER"} ,
		{"word":"martelèrent","word_nosc":"martelerent","lemma":"marteler","pos":"VER"} ,
		{"word":"martelé","word_nosc":"martele","lemma":"marteler","pos":"VER"} ,
		{"word":"martelée","word_nosc":"martelee","lemma":"marteler","pos":"VER"} ,
		{"word":"martelées","word_nosc":"martelees","lemma":"marteler","pos":"VER"} ,
		{"word":"martelés","word_nosc":"marteles","lemma":"marteler","pos":"VER"} ,
		{"word":"martine","word_nosc":"martine","lemma":"martiner","pos":"VER"} ,
		{"word":"martinez","word_nosc":"martinez","lemma":"martiner","pos":"VER"} ,
		{"word":"martyrisaient","word_nosc":"martyrisaient","lemma":"martyriser","pos":"VER"} ,
		{"word":"martyrisait","word_nosc":"martyrisait","lemma":"martyriser","pos":"VER"} ,
		{"word":"martyrisant","word_nosc":"martyrisant","lemma":"martyriser","pos":"VER"} ,
		{"word":"martyrise","word_nosc":"martyrise","lemma":"martyriser","pos":"VER"} ,
		{"word":"martyrisent","word_nosc":"martyrisent","lemma":"martyriser","pos":"VER"} ,
		{"word":"martyriser","word_nosc":"martyriser","lemma":"martyriser","pos":"VER"} ,
		{"word":"martyriserait","word_nosc":"martyriserait","lemma":"martyriser","pos":"VER"} ,
		{"word":"martyrisé","word_nosc":"martyrise","lemma":"martyriser","pos":"VER"} ,
		{"word":"martyrisée","word_nosc":"martyrisee","lemma":"martyriser","pos":"VER"} ,
		{"word":"martyrisées","word_nosc":"martyrisees","lemma":"martyriser","pos":"VER"} ,
		{"word":"martyrisés","word_nosc":"martyrises","lemma":"martyriser","pos":"VER"} ,
		{"word":"martèle","word_nosc":"martele","lemma":"marteler","pos":"VER"} ,
		{"word":"martèlent","word_nosc":"martelent","lemma":"marteler","pos":"VER"} ,
		{"word":"martèlera","word_nosc":"martelera","lemma":"marteler","pos":"VER"} ,
		{"word":"masculiniser","word_nosc":"masculiniser","lemma":"masculiniser","pos":"VER"} ,
		{"word":"masculinisée","word_nosc":"masculinisee","lemma":"masculiniser","pos":"VER"} ,
		{"word":"masqua","word_nosc":"masqua","lemma":"masquer","pos":"VER"} ,
		{"word":"masquaient","word_nosc":"masquaient","lemma":"masquer","pos":"VER"} ,
		{"word":"masquait","word_nosc":"masquait","lemma":"masquer","pos":"VER"} ,
		{"word":"masquant","word_nosc":"masquant","lemma":"masquer","pos":"VER"} ,
		{"word":"masque","word_nosc":"masque","lemma":"masquer","pos":"VER"} ,
		{"word":"masquent","word_nosc":"masquent","lemma":"masquer","pos":"VER"} ,
		{"word":"masquer","word_nosc":"masquer","lemma":"masquer","pos":"VER"} ,
		{"word":"masqueraient","word_nosc":"masqueraient","lemma":"masquer","pos":"VER"} ,
		{"word":"masques","word_nosc":"masques","lemma":"masquer","pos":"VER"} ,
		{"word":"masquons","word_nosc":"masquons","lemma":"masquer","pos":"VER"} ,
		{"word":"masqué","word_nosc":"masque","lemma":"masquer","pos":"VER"} ,
		{"word":"masquée","word_nosc":"masquee","lemma":"masquer","pos":"VER"} ,
		{"word":"masquées","word_nosc":"masquees","lemma":"masquer","pos":"VER"} ,
		{"word":"masqués","word_nosc":"masques","lemma":"masquer","pos":"VER"} ,
		{"word":"massa","word_nosc":"massa","lemma":"masser","pos":"VER"} ,
		{"word":"massacra","word_nosc":"massacra","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacraient","word_nosc":"massacraient","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrais","word_nosc":"massacrais","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrait","word_nosc":"massacrait","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrant","word_nosc":"massacrant","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacre","word_nosc":"massacre","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrent","word_nosc":"massacrent","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrer","word_nosc":"massacrer","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrera","word_nosc":"massacrera","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrerai","word_nosc":"massacrerai","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacreraient","word_nosc":"massacreraient","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrerait","word_nosc":"massacrerait","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrerons","word_nosc":"massacrerons","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacreront","word_nosc":"massacreront","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacres","word_nosc":"massacres","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrez","word_nosc":"massacrez","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrons","word_nosc":"massacrons","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrât","word_nosc":"massacrat","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrèrent","word_nosc":"massacrerent","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacré","word_nosc":"massacre","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrée","word_nosc":"massacree","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrées","word_nosc":"massacrees","lemma":"massacrer","pos":"VER"} ,
		{"word":"massacrés","word_nosc":"massacres","lemma":"massacrer","pos":"VER"} ,
		{"word":"massaient","word_nosc":"massaient","lemma":"masser","pos":"VER"} ,
		{"word":"massais","word_nosc":"massais","lemma":"masser","pos":"VER"} ,
		{"word":"massait","word_nosc":"massait","lemma":"masser","pos":"VER"} ,
		{"word":"massant","word_nosc":"massant","lemma":"masser","pos":"VER"} ,
		{"word":"masse","word_nosc":"masse","lemma":"masser","pos":"VER"} ,
		{"word":"massent","word_nosc":"massent","lemma":"masser","pos":"VER"} ,
		{"word":"masser","word_nosc":"masser","lemma":"masser","pos":"VER"} ,
		{"word":"massera","word_nosc":"massera","lemma":"masser","pos":"VER"} ,
		{"word":"masserai","word_nosc":"masserai","lemma":"masser","pos":"VER"} ,
		{"word":"masseraient","word_nosc":"masseraient","lemma":"masser","pos":"VER"} ,
		{"word":"masserais","word_nosc":"masserais","lemma":"masser","pos":"VER"} ,
		{"word":"masseras","word_nosc":"masseras","lemma":"masser","pos":"VER"} ,
		{"word":"masserez","word_nosc":"masserez","lemma":"masser","pos":"VER"} ,
		{"word":"masses","word_nosc":"masses","lemma":"masser","pos":"VER"} ,
		{"word":"massez","word_nosc":"massez","lemma":"masser","pos":"VER"} ,
		{"word":"massicoter","word_nosc":"massicoter","lemma":"massicoter","pos":"VER"} ,
		{"word":"massicoté","word_nosc":"massicote","lemma":"massicoter","pos":"VER"} ,
		{"word":"massèrent","word_nosc":"masserent","lemma":"masser","pos":"VER"} ,
		{"word":"massé","word_nosc":"masse","lemma":"masser","pos":"VER"} ,
		{"word":"massée","word_nosc":"massee","lemma":"masser","pos":"VER"} ,
		{"word":"massées","word_nosc":"massees","lemma":"masser","pos":"VER"} ,
		{"word":"massés","word_nosc":"masses","lemma":"masser","pos":"VER"} ,
		{"word":"mastiqua","word_nosc":"mastiqua","lemma":"mastiquer","pos":"VER"} ,
		{"word":"mastiquaient","word_nosc":"mastiquaient","lemma":"mastiquer","pos":"VER"} ,
		{"word":"mastiquais","word_nosc":"mastiquais","lemma":"mastiquer","pos":"VER"} ,
		{"word":"mastiquait","word_nosc":"mastiquait","lemma":"mastiquer","pos":"VER"} ,
		{"word":"mastiquant","word_nosc":"mastiquant","lemma":"mastiquer","pos":"VER"} ,
		{"word":"mastique","word_nosc":"mastique","lemma":"mastiquer","pos":"VER"} ,
		{"word":"mastiquent","word_nosc":"mastiquent","lemma":"mastiquer","pos":"VER"} ,
		{"word":"mastiquer","word_nosc":"mastiquer","lemma":"mastiquer","pos":"VER"} ,
		{"word":"mastiquez","word_nosc":"mastiquez","lemma":"mastiquer","pos":"VER"} ,
		{"word":"mastiquons","word_nosc":"mastiquons","lemma":"mastiquer","pos":"VER"} ,
		{"word":"mastiqué","word_nosc":"mastique","lemma":"mastiquer","pos":"VER"} ,
		{"word":"mastiqués","word_nosc":"mastiques","lemma":"mastiquer","pos":"VER"} ,
		{"word":"masturbais","word_nosc":"masturbais","lemma":"masturber","pos":"VER"} ,
		{"word":"masturbait","word_nosc":"masturbait","lemma":"masturber","pos":"VER"} ,
		{"word":"masturbant","word_nosc":"masturbant","lemma":"masturber","pos":"VER"} ,
		{"word":"masturbe","word_nosc":"masturbe","lemma":"masturber","pos":"VER"} ,
		{"word":"masturbent","word_nosc":"masturbent","lemma":"masturber","pos":"VER"} ,
		{"word":"masturber","word_nosc":"masturber","lemma":"masturber","pos":"VER"} ,
		{"word":"masturberas","word_nosc":"masturberas","lemma":"masturber","pos":"VER"} ,
		{"word":"masturbez","word_nosc":"masturbez","lemma":"masturber","pos":"VER"} ,
		{"word":"masturbé","word_nosc":"masturbe","lemma":"masturber","pos":"VER"} ,
		{"word":"masturbée","word_nosc":"masturbee","lemma":"masturber","pos":"VER"} ,
		{"word":"masturbés","word_nosc":"masturbes","lemma":"masturber","pos":"VER"} ,
		{"word":"mata","word_nosc":"mata","lemma":"mater","pos":"VER"} ,
		{"word":"mataient","word_nosc":"mataient","lemma":"mater","pos":"VER"} ,
		{"word":"matais","word_nosc":"matais","lemma":"mater","pos":"VER"} ,
		{"word":"matait","word_nosc":"matait","lemma":"mater","pos":"VER"} ,
		{"word":"matant","word_nosc":"matant","lemma":"mater","pos":"VER"} ,
		{"word":"matcha","word_nosc":"matcha","lemma":"matcher","pos":"VER"} ,
		{"word":"matche","word_nosc":"matche","lemma":"matcher","pos":"VER"} ,
		{"word":"mate","word_nosc":"mate","lemma":"mater","pos":"VER"} ,
		{"word":"matelassent","word_nosc":"matelassent","lemma":"matelasser","pos":"VER"} ,
		{"word":"matelasser","word_nosc":"matelasser","lemma":"matelasser","pos":"VER"} ,
		{"word":"matelassé","word_nosc":"matelasse","lemma":"matelasser","pos":"VER"} ,
		{"word":"matelassée","word_nosc":"matelassee","lemma":"matelasser","pos":"VER"} ,
		{"word":"matelassées","word_nosc":"matelassees","lemma":"matelasser","pos":"VER"} ,
		{"word":"matelassés","word_nosc":"matelasses","lemma":"matelasser","pos":"VER"} ,
		{"word":"matent","word_nosc":"matent","lemma":"mater","pos":"VER"} ,
		{"word":"mater","word_nosc":"mater","lemma":"mater","pos":"VER"} ,
		{"word":"matera","word_nosc":"matera","lemma":"mater","pos":"VER"} ,
		{"word":"materai","word_nosc":"materai","lemma":"mater","pos":"VER"} ,
		{"word":"materais","word_nosc":"materais","lemma":"mater","pos":"VER"} ,
		{"word":"materait","word_nosc":"materait","lemma":"mater","pos":"VER"} ,
		{"word":"maternant","word_nosc":"maternant","lemma":"materner","pos":"VER"} ,
		{"word":"materner","word_nosc":"materner","lemma":"materner","pos":"VER"} ,
		{"word":"maternera","word_nosc":"maternera","lemma":"materner","pos":"VER"} ,
		{"word":"materniser","word_nosc":"materniser","lemma":"materniser","pos":"VER"} ,
		{"word":"maternisé","word_nosc":"maternise","lemma":"materniser","pos":"VER"} ,
		{"word":"materné","word_nosc":"materne","lemma":"materner","pos":"VER"} ,
		{"word":"materons","word_nosc":"materons","lemma":"mater","pos":"VER"} ,
		{"word":"materont","word_nosc":"materont","lemma":"mater","pos":"VER"} ,
		{"word":"mates","word_nosc":"mates","lemma":"mater","pos":"VER"} ,
		{"word":"matez","word_nosc":"matez","lemma":"mater","pos":"VER"} ,
		{"word":"mathématiser","word_nosc":"mathematiser","lemma":"mathématiser","pos":"VER"} ,
		{"word":"mati","word_nosc":"mati","lemma":"matir","pos":"VER"} ,
		{"word":"matiez","word_nosc":"matiez","lemma":"mater","pos":"VER"} ,
		{"word":"matis","word_nosc":"matis","lemma":"matir","pos":"VER"} ,
		{"word":"matisse","word_nosc":"matisse","lemma":"matir","pos":"VER"} ,
		{"word":"matons","word_nosc":"matons","lemma":"mater","pos":"VER"} ,
		{"word":"matouse","word_nosc":"matouse","lemma":"matouser","pos":"VER"} ,
		{"word":"matouser","word_nosc":"matouser","lemma":"matouser","pos":"VER"} ,
		{"word":"matraqua","word_nosc":"matraqua","lemma":"matraquer","pos":"VER"} ,
		{"word":"matraquaient","word_nosc":"matraquaient","lemma":"matraquer","pos":"VER"} ,
		{"word":"matraquais","word_nosc":"matraquais","lemma":"matraquer","pos":"VER"} ,
		{"word":"matraquait","word_nosc":"matraquait","lemma":"matraquer","pos":"VER"} ,
		{"word":"matraquant","word_nosc":"matraquant","lemma":"matraquer","pos":"VER"} ,
		{"word":"matraque","word_nosc":"matraque","lemma":"matraquer","pos":"VER"} ,
		{"word":"matraquer","word_nosc":"matraquer","lemma":"matraquer","pos":"VER"} ,
		{"word":"matraquez","word_nosc":"matraquez","lemma":"matraquer","pos":"VER"} ,
		{"word":"matraqué","word_nosc":"matraque","lemma":"matraquer","pos":"VER"} ,
		{"word":"matraquée","word_nosc":"matraquee","lemma":"matraquer","pos":"VER"} ,
		{"word":"matraqués","word_nosc":"matraques","lemma":"matraquer","pos":"VER"} ,
		{"word":"matter","word_nosc":"matter","lemma":"matter","pos":"VER"} ,
		{"word":"maturer","word_nosc":"maturer","lemma":"maturer","pos":"VER"} ,
		{"word":"matèrent","word_nosc":"materent","lemma":"mater","pos":"VER"} ,
		{"word":"maté","word_nosc":"mate","lemma":"mater","pos":"VER"} ,
		{"word":"matée","word_nosc":"matee","lemma":"mater","pos":"VER"} ,
		{"word":"matées","word_nosc":"matees","lemma":"mater","pos":"VER"} ,
		{"word":"matérialisa","word_nosc":"materialisa","lemma":"matérialiser","pos":"VER"} ,
		{"word":"matérialisaient","word_nosc":"materialisaient","lemma":"matérialiser","pos":"VER"} ,
		{"word":"matérialisait","word_nosc":"materialisait","lemma":"matérialiser","pos":"VER"} ,
		{"word":"matérialisant","word_nosc":"materialisant","lemma":"matérialiser","pos":"VER"} ,
		{"word":"matérialise","word_nosc":"materialise","lemma":"matérialiser","pos":"VER"} ,
		{"word":"matérialisent","word_nosc":"materialisent","lemma":"matérialiser","pos":"VER"} ,
		{"word":"matérialiser","word_nosc":"materialiser","lemma":"matérialiser","pos":"VER"} ,
		{"word":"matérialisera","word_nosc":"materialisera","lemma":"matérialiser","pos":"VER"} ,
		{"word":"matérialiseront","word_nosc":"materialiseront","lemma":"matérialiser","pos":"VER"} ,
		{"word":"matérialisions","word_nosc":"materialisions","lemma":"matérialiser","pos":"VER"} ,
		{"word":"matérialisèrent","word_nosc":"materialiserent","lemma":"matérialiser","pos":"VER"} ,
		{"word":"matérialisé","word_nosc":"materialise","lemma":"matérialiser","pos":"VER"} ,
		{"word":"matérialisée","word_nosc":"materialisee","lemma":"matérialiser","pos":"VER"} ,
		{"word":"matérialisées","word_nosc":"materialisees","lemma":"matérialiser","pos":"VER"} ,
		{"word":"matérialisés","word_nosc":"materialises","lemma":"matérialiser","pos":"VER"} ,
		{"word":"matés","word_nosc":"mates","lemma":"mater","pos":"VER"} ,
		{"word":"maudira","word_nosc":"maudira","lemma":"maudire","pos":"VER"} ,
		{"word":"maudirai","word_nosc":"maudirai","lemma":"maudire","pos":"VER"} ,
		{"word":"maudirais","word_nosc":"maudirais","lemma":"maudire","pos":"VER"} ,
		{"word":"maudirait","word_nosc":"maudirait","lemma":"maudire","pos":"VER"} ,
		{"word":"maudire","word_nosc":"maudire","lemma":"maudire","pos":"VER"} ,
		{"word":"maudirent","word_nosc":"maudirent","lemma":"maudire","pos":"VER"} ,
		{"word":"maudirons","word_nosc":"maudirons","lemma":"maudire","pos":"VER"} ,
		{"word":"maudiront","word_nosc":"maudiront","lemma":"maudire","pos":"VER"} ,
		{"word":"maudis","word_nosc":"maudis","lemma":"maudire","pos":"VER"} ,
		{"word":"maudissaient","word_nosc":"maudissaient","lemma":"maudire","pos":"VER"} ,
		{"word":"maudissais","word_nosc":"maudissais","lemma":"maudire","pos":"VER"} ,
		{"word":"maudissait","word_nosc":"maudissait","lemma":"maudire","pos":"VER"} ,
		{"word":"maudissant","word_nosc":"maudissant","lemma":"maudire","pos":"VER"} ,
		{"word":"maudisse","word_nosc":"maudisse","lemma":"maudire","pos":"VER"} ,
		{"word":"maudissent","word_nosc":"maudissent","lemma":"maudire","pos":"VER"} ,
		{"word":"maudissez","word_nosc":"maudissez","lemma":"maudire","pos":"VER"} ,
		{"word":"maudissions","word_nosc":"maudissions","lemma":"maudire","pos":"VER"} ,
		{"word":"maudit","word_nosc":"maudit","lemma":"maudire","pos":"VER"} ,
		{"word":"maugréa","word_nosc":"maugrea","lemma":"maugréer","pos":"VER"} ,
		{"word":"maugréai","word_nosc":"maugreai","lemma":"maugréer","pos":"VER"} ,
		{"word":"maugréaient","word_nosc":"maugreaient","lemma":"maugréer","pos":"VER"} ,
		{"word":"maugréait","word_nosc":"maugreait","lemma":"maugréer","pos":"VER"} ,
		{"word":"maugréant","word_nosc":"maugreant","lemma":"maugréer","pos":"VER"} ,
		{"word":"maugrée","word_nosc":"maugree","lemma":"maugréer","pos":"VER"} ,
		{"word":"maugréer","word_nosc":"maugreer","lemma":"maugréer","pos":"VER"} ,
		{"word":"maximalise","word_nosc":"maximalise","lemma":"maximaliser","pos":"VER"} ,
		{"word":"maximaliser","word_nosc":"maximaliser","lemma":"maximaliser","pos":"VER"} ,
		{"word":"maximiser","word_nosc":"maximiser","lemma":"maximiser","pos":"VER"} ,
		{"word":"mazas","word_nosc":"mazas","lemma":"mazer","pos":"VER"} ,
		{"word":"maze","word_nosc":"maze","lemma":"mazer","pos":"VER"} ,
		{"word":"mazouté","word_nosc":"mazoute","lemma":"mazouter","pos":"VER"} ,
		{"word":"mazé","word_nosc":"maze","lemma":"mazer","pos":"VER"} ,
		{"word":"maçonnant","word_nosc":"maconnant","lemma":"maçonner","pos":"VER"} ,
		{"word":"maçonnent","word_nosc":"maconnent","lemma":"maçonner","pos":"VER"} ,
		{"word":"maçonner","word_nosc":"maconner","lemma":"maçonner","pos":"VER"} ,
		{"word":"maçonné","word_nosc":"maconne","lemma":"maçonner","pos":"VER"} ,
		{"word":"maçonnée","word_nosc":"maconnee","lemma":"maçonner","pos":"VER"} ,
		{"word":"maçonnées","word_nosc":"maconnees","lemma":"maçonner","pos":"VER"} ,
		{"word":"maçonnés","word_nosc":"maconnes","lemma":"maçonner","pos":"VER"} ,
		{"word":"maîtrisa","word_nosc":"maitrisa","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrisaient","word_nosc":"maitrisaient","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrisais","word_nosc":"maitrisais","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrisait","word_nosc":"maitrisait","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrisant","word_nosc":"maitrisant","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrise","word_nosc":"maitrise","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrisent","word_nosc":"maitrisent","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtriser","word_nosc":"maitriser","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrisera","word_nosc":"maitrisera","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtriserai","word_nosc":"maitriserai","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtriseraient","word_nosc":"maitriseraient","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtriserait","word_nosc":"maitriserait","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtriseras","word_nosc":"maitriseras","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtriserez","word_nosc":"maitriserez","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtriseriez","word_nosc":"maitriseriez","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtriserons","word_nosc":"maitriserons","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtriseront","word_nosc":"maitriseront","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrises","word_nosc":"maitrises","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrisez","word_nosc":"maitrisez","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrisiez","word_nosc":"maitrisiez","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrisions","word_nosc":"maitrisions","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrisons","word_nosc":"maitrisons","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrisèrent","word_nosc":"maitriserent","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrisé","word_nosc":"maitrise","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrisée","word_nosc":"maitrisee","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrisées","word_nosc":"maitrisees","lemma":"maîtriser","pos":"VER"} ,
		{"word":"maîtrisés","word_nosc":"maitrises","lemma":"maîtriser","pos":"VER"} ,
		{"word":"membré","word_nosc":"membre","lemma":"membrer","pos":"VER"} ,
		{"word":"mena","word_nosc":"mena","lemma":"mener","pos":"VER"} ,
		{"word":"menace","word_nosc":"menace","lemma":"menacer","pos":"VER"} ,
		{"word":"menacent","word_nosc":"menacent","lemma":"menacer","pos":"VER"} ,
		{"word":"menacer","word_nosc":"menacer","lemma":"menacer","pos":"VER"} ,
		{"word":"menacera","word_nosc":"menacera","lemma":"menacer","pos":"VER"} ,
		{"word":"menaceraient","word_nosc":"menaceraient","lemma":"menacer","pos":"VER"} ,
		{"word":"menacerait","word_nosc":"menacerait","lemma":"menacer","pos":"VER"} ,
		{"word":"menaceras","word_nosc":"menaceras","lemma":"menacer","pos":"VER"} ,
		{"word":"menaceriez","word_nosc":"menaceriez","lemma":"menacer","pos":"VER"} ,
		{"word":"menaceront","word_nosc":"menaceront","lemma":"menacer","pos":"VER"} ,
		{"word":"menaces","word_nosc":"menaces","lemma":"menacer","pos":"VER"} ,
		{"word":"menacez","word_nosc":"menacez","lemma":"menacer","pos":"VER"} ,
		{"word":"menaciez","word_nosc":"menaciez","lemma":"menacer","pos":"VER"} ,
		{"word":"menacions","word_nosc":"menacions","lemma":"menacer","pos":"VER"} ,
		{"word":"menacèrent","word_nosc":"menacerent","lemma":"menacer","pos":"VER"} ,
		{"word":"menacé","word_nosc":"menace","lemma":"menacer","pos":"VER"} ,
		{"word":"menacée","word_nosc":"menacee","lemma":"menacer","pos":"VER"} ,
		{"word":"menacées","word_nosc":"menacees","lemma":"menacer","pos":"VER"} ,
		{"word":"menacés","word_nosc":"menaces","lemma":"menacer","pos":"VER"} ,
		{"word":"menai","word_nosc":"menai","lemma":"mener","pos":"VER"} ,
		{"word":"menaient","word_nosc":"menaient","lemma":"mener","pos":"VER"} ,
		{"word":"menais","word_nosc":"menais","lemma":"mener","pos":"VER"} ,
		{"word":"menait","word_nosc":"menait","lemma":"mener","pos":"VER"} ,
		{"word":"menant","word_nosc":"menant","lemma":"mener","pos":"VER"} ,
		{"word":"menassent","word_nosc":"menassent","lemma":"mener","pos":"VER"} ,
		{"word":"menaça","word_nosc":"menaca","lemma":"menacer","pos":"VER"} ,
		{"word":"menaçaient","word_nosc":"menacaient","lemma":"menacer","pos":"VER"} ,
		{"word":"menaçais","word_nosc":"menacais","lemma":"menacer","pos":"VER"} ,
		{"word":"menaçait","word_nosc":"menacait","lemma":"menacer","pos":"VER"} ,
		{"word":"menaçant","word_nosc":"menacant","lemma":"menacer","pos":"VER"} ,
		{"word":"menaçons","word_nosc":"menacons","lemma":"menacer","pos":"VER"} ,
		{"word":"menaçât","word_nosc":"menacat","lemma":"menacer","pos":"VER"} ,
		{"word":"mendia","word_nosc":"mendia","lemma":"mendier","pos":"VER"} ,
		{"word":"mendiaient","word_nosc":"mendiaient","lemma":"mendier","pos":"VER"} ,
		{"word":"mendiais","word_nosc":"mendiais","lemma":"mendier","pos":"VER"} ,
		{"word":"mendiait","word_nosc":"mendiait","lemma":"mendier","pos":"VER"} ,
		{"word":"mendiant","word_nosc":"mendiant","lemma":"mendier","pos":"VER"} ,
		{"word":"mendie","word_nosc":"mendie","lemma":"mendier","pos":"VER"} ,
		{"word":"mendient","word_nosc":"mendient","lemma":"mendier","pos":"VER"} ,
		{"word":"mendier","word_nosc":"mendier","lemma":"mendier","pos":"VER"} ,
		{"word":"mendierait","word_nosc":"mendierait","lemma":"mendier","pos":"VER"} ,
		{"word":"mendieras","word_nosc":"mendieras","lemma":"mendier","pos":"VER"} ,
		{"word":"mendies","word_nosc":"mendies","lemma":"mendier","pos":"VER"} ,
		{"word":"mendiez","word_nosc":"mendiez","lemma":"mendier","pos":"VER"} ,
		{"word":"mendigotant","word_nosc":"mendigotant","lemma":"mendigoter","pos":"VER"} ,
		{"word":"mendigoter","word_nosc":"mendigoter","lemma":"mendigoter","pos":"VER"} ,
		{"word":"mendions","word_nosc":"mendions","lemma":"mendier","pos":"VER"} ,
		{"word":"mendié","word_nosc":"mendie","lemma":"mendier","pos":"VER"} ,
		{"word":"mendiée","word_nosc":"mendiee","lemma":"mendier","pos":"VER"} ,
		{"word":"mendiées","word_nosc":"mendiees","lemma":"mendier","pos":"VER"} ,
		{"word":"mener","word_nosc":"mener","lemma":"mener","pos":"VER"} ,
		{"word":"menez","word_nosc":"menez","lemma":"mener","pos":"VER"} ,
		{"word":"meniez","word_nosc":"meniez","lemma":"mener","pos":"VER"} ,
		{"word":"menions","word_nosc":"menions","lemma":"mener","pos":"VER"} ,
		{"word":"menons","word_nosc":"menons","lemma":"mener","pos":"VER"} ,
		{"word":"menotter","word_nosc":"menotter","lemma":"menotter","pos":"VER"} ,
		{"word":"menottez","word_nosc":"menottez","lemma":"menotter","pos":"VER"} ,
		{"word":"menotté","word_nosc":"menotte","lemma":"menotter","pos":"VER"} ,
		{"word":"menottée","word_nosc":"menottee","lemma":"menotter","pos":"VER"} ,
		{"word":"menottés","word_nosc":"menottes","lemma":"menotter","pos":"VER"} ,
		{"word":"mens","word_nosc":"mens","lemma":"mentir","pos":"VER"} ,
		{"word":"ment","word_nosc":"ment","lemma":"mentir","pos":"VER"} ,
		{"word":"mentaient","word_nosc":"mentaient","lemma":"mentir","pos":"VER"} ,
		{"word":"mentais","word_nosc":"mentais","lemma":"mentir","pos":"VER"} ,
		{"word":"mentait","word_nosc":"mentait","lemma":"mentir","pos":"VER"} ,
		{"word":"mentaliser","word_nosc":"mentaliser","lemma":"mentaliser","pos":"VER"} ,
		{"word":"mentant","word_nosc":"mentant","lemma":"mentir","pos":"VER"} ,
		{"word":"mente","word_nosc":"mente","lemma":"mentir","pos":"VER"} ,
		{"word":"mentent","word_nosc":"mentent","lemma":"mentir","pos":"VER"} ,
		{"word":"mentes","word_nosc":"mentes","lemma":"mentir","pos":"VER"} ,
		{"word":"mentez","word_nosc":"mentez","lemma":"mentez","pos":"VER"} ,
		{"word":"mentez","word_nosc":"mentez","lemma":"mentir","pos":"VER"} ,
		{"word":"menti","word_nosc":"menti","lemma":"mentir","pos":"VER"} ,
		{"word":"mentiez","word_nosc":"mentiez","lemma":"mentir","pos":"VER"} ,
		{"word":"mentionna","word_nosc":"mentionna","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnai","word_nosc":"mentionnai","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnaient","word_nosc":"mentionnaient","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnais","word_nosc":"mentionnais","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnait","word_nosc":"mentionnait","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnant","word_nosc":"mentionnant","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionne","word_nosc":"mentionne","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnent","word_nosc":"mentionnent","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionner","word_nosc":"mentionner","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnerai","word_nosc":"mentionnerai","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnerais","word_nosc":"mentionnerais","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnerait","word_nosc":"mentionnerait","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionneras","word_nosc":"mentionneras","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnerez","word_nosc":"mentionnerez","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnes","word_nosc":"mentionnes","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnez","word_nosc":"mentionnez","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionniez","word_nosc":"mentionniez","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnons","word_nosc":"mentionnons","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnât","word_nosc":"mentionnat","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionné","word_nosc":"mentionne","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnée","word_nosc":"mentionnee","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnées","word_nosc":"mentionnees","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentionnés","word_nosc":"mentionnes","lemma":"mentionner","pos":"VER"} ,
		{"word":"mentions","word_nosc":"mentions","lemma":"mentir","pos":"VER"} ,
		{"word":"mentir","word_nosc":"mentir","lemma":"mentir","pos":"VER"} ,
		{"word":"mentira","word_nosc":"mentira","lemma":"mentir","pos":"VER"} ,
		{"word":"mentirai","word_nosc":"mentirai","lemma":"mentir","pos":"VER"} ,
		{"word":"mentiraient","word_nosc":"mentiraient","lemma":"mentir","pos":"VER"} ,
		{"word":"mentirais","word_nosc":"mentirais","lemma":"mentir","pos":"VER"} ,
		{"word":"mentirait","word_nosc":"mentirait","lemma":"mentir","pos":"VER"} ,
		{"word":"mentiras","word_nosc":"mentiras","lemma":"mentir","pos":"VER"} ,
		{"word":"mentirez","word_nosc":"mentirez","lemma":"mentir","pos":"VER"} ,
		{"word":"mentiriez","word_nosc":"mentiriez","lemma":"mentir","pos":"VER"} ,
		{"word":"mentirons","word_nosc":"mentirons","lemma":"mentir","pos":"VER"} ,
		{"word":"mentiront","word_nosc":"mentiront","lemma":"mentir","pos":"VER"} ,
		{"word":"mentis","word_nosc":"mentis","lemma":"mentir","pos":"VER"} ,
		{"word":"mentissent","word_nosc":"mentissent","lemma":"mentir","pos":"VER"} ,
		{"word":"mentit","word_nosc":"mentit","lemma":"mentir","pos":"VER"} ,
		{"word":"mentons","word_nosc":"mentons","lemma":"mentir","pos":"VER"} ,
		{"word":"mentît","word_nosc":"mentit","lemma":"mentir","pos":"VER"} ,
		{"word":"menuiser","word_nosc":"menuiser","lemma":"menuiser","pos":"VER"} ,
		{"word":"menuisera","word_nosc":"menuisera","lemma":"menuiser","pos":"VER"} ,
		{"word":"menât","word_nosc":"menat","lemma":"mener","pos":"VER"} ,
		{"word":"menèrent","word_nosc":"menerent","lemma":"mener","pos":"VER"} ,
		{"word":"mené","word_nosc":"mene","lemma":"mener","pos":"VER"} ,
		{"word":"menée","word_nosc":"menee","lemma":"mener","pos":"VER"} ,
		{"word":"menées","word_nosc":"menees","lemma":"mener","pos":"VER"} ,
		{"word":"menés","word_nosc":"menes","lemma":"mener","pos":"VER"} ,
		{"word":"mercerisé","word_nosc":"mercerise","lemma":"merceriser","pos":"VER"} ,
		{"word":"merda","word_nosc":"merda","lemma":"merder","pos":"VER"} ,
		{"word":"merdait","word_nosc":"merdait","lemma":"merder","pos":"VER"} ,
		{"word":"merde","word_nosc":"merde","lemma":"merder","pos":"VER"} ,
		{"word":"merdent","word_nosc":"merdent","lemma":"merder","pos":"VER"} ,
		{"word":"merder","word_nosc":"merder","lemma":"merder","pos":"VER"} ,
		{"word":"merderai","word_nosc":"merderai","lemma":"merder","pos":"VER"} ,
		{"word":"merdes","word_nosc":"merdes","lemma":"merder","pos":"VER"} ,
		{"word":"merdez","word_nosc":"merdez","lemma":"merder","pos":"VER"} ,
		{"word":"merdoie","word_nosc":"merdoie","lemma":"merdoyer","pos":"VER"} ,
		{"word":"merdouillé","word_nosc":"merdouille","lemma":"merdouiller","pos":"VER"} ,
		{"word":"merdoyait","word_nosc":"merdoyait","lemma":"merdoyer","pos":"VER"} ,
		{"word":"merdé","word_nosc":"merde","lemma":"merder","pos":"VER"} ,
		{"word":"merdée","word_nosc":"merdee","lemma":"merder","pos":"VER"} ,
		{"word":"meringué","word_nosc":"meringue","lemma":"meringuer","pos":"VER"} ,
		{"word":"meringuée","word_nosc":"meringuee","lemma":"meringuer","pos":"VER"} ,
		{"word":"meringuées","word_nosc":"meringuees","lemma":"meringuer","pos":"VER"} ,
		{"word":"mesura","word_nosc":"mesura","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurai","word_nosc":"mesurai","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesuraient","word_nosc":"mesuraient","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurais","word_nosc":"mesurais","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurait","word_nosc":"mesurait","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurant","word_nosc":"mesurant","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurassent","word_nosc":"mesurassent","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesure","word_nosc":"mesure","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurent","word_nosc":"mesurent","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurer","word_nosc":"mesurer","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurera","word_nosc":"mesurera","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurerait","word_nosc":"mesurerait","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurerez","word_nosc":"mesurerez","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurerons","word_nosc":"mesurerons","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesureront","word_nosc":"mesureront","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesures","word_nosc":"mesures","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurez","word_nosc":"mesurez","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesuriez","word_nosc":"mesuriez","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurions","word_nosc":"mesurions","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurons","word_nosc":"mesurons","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurât","word_nosc":"mesurat","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurèrent","word_nosc":"mesurerent","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesuré","word_nosc":"mesure","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurée","word_nosc":"mesuree","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurées","word_nosc":"mesurees","lemma":"mesurer","pos":"VER"} ,
		{"word":"mesurés","word_nosc":"mesures","lemma":"mesurer","pos":"VER"} ,
		{"word":"met","word_nosc":"met","lemma":"mettre","pos":"VER"} ,
		{"word":"mets","word_nosc":"mets","lemma":"mettre","pos":"VER"} ,
		{"word":"mettaient","word_nosc":"mettaient","lemma":"mettre","pos":"VER"} ,
		{"word":"mettais","word_nosc":"mettais","lemma":"mettre","pos":"VER"} ,
		{"word":"mettait","word_nosc":"mettait","lemma":"mettre","pos":"VER"} ,
		{"word":"mettant","word_nosc":"mettant","lemma":"mettre","pos":"VER"} ,
		{"word":"mette","word_nosc":"mette","lemma":"mettre","pos":"VER"} ,
		{"word":"mettent","word_nosc":"mettent","lemma":"mettre","pos":"VER"} ,
		{"word":"mettes","word_nosc":"mettes","lemma":"mettre","pos":"VER"} ,
		{"word":"mettez","word_nosc":"mettez","lemma":"mettre","pos":"VER"} ,
		{"word":"mettiez","word_nosc":"mettiez","lemma":"mettre","pos":"VER"} ,
		{"word":"mettions","word_nosc":"mettions","lemma":"mettre","pos":"VER"} ,
		{"word":"mettons","word_nosc":"mettons","lemma":"mettre","pos":"VER"} ,
		{"word":"mettra","word_nosc":"mettra","lemma":"mettre","pos":"VER"} ,
		{"word":"mettrai","word_nosc":"mettrai","lemma":"mettre","pos":"VER"} ,
		{"word":"mettraient","word_nosc":"mettraient","lemma":"mettre","pos":"VER"} ,
		{"word":"mettrais","word_nosc":"mettrais","lemma":"mettre","pos":"VER"} ,
		{"word":"mettrait","word_nosc":"mettrait","lemma":"mettre","pos":"VER"} ,
		{"word":"mettras","word_nosc":"mettras","lemma":"mettre","pos":"VER"} ,
		{"word":"mettre","word_nosc":"mettre","lemma":"mettre","pos":"VER"} ,
		{"word":"mettrez","word_nosc":"mettrez","lemma":"mettre","pos":"VER"} ,
		{"word":"mettriez","word_nosc":"mettriez","lemma":"mettre","pos":"VER"} ,
		{"word":"mettrions","word_nosc":"mettrions","lemma":"mettre","pos":"VER"} ,
		{"word":"mettrons","word_nosc":"mettrons","lemma":"mettre","pos":"VER"} ,
		{"word":"mettront","word_nosc":"mettront","lemma":"mettre","pos":"VER"} ,
		{"word":"meubla","word_nosc":"meubla","lemma":"meubler","pos":"VER"} ,
		{"word":"meublaient","word_nosc":"meublaient","lemma":"meubler","pos":"VER"} ,
		{"word":"meublais","word_nosc":"meublais","lemma":"meubler","pos":"VER"} ,
		{"word":"meublait","word_nosc":"meublait","lemma":"meubler","pos":"VER"} ,
		{"word":"meublant","word_nosc":"meublant","lemma":"meubler","pos":"VER"} ,
		{"word":"meuble","word_nosc":"meuble","lemma":"meubler","pos":"VER"} ,
		{"word":"meublent","word_nosc":"meublent","lemma":"meubler","pos":"VER"} ,
		{"word":"meubler","word_nosc":"meubler","lemma":"meubler","pos":"VER"} ,
		{"word":"meublèrent","word_nosc":"meublerent","lemma":"meubler","pos":"VER"} ,
		{"word":"meublé","word_nosc":"meuble","lemma":"meubler","pos":"VER"} ,
		{"word":"meublée","word_nosc":"meublee","lemma":"meubler","pos":"VER"} ,
		{"word":"meublées","word_nosc":"meublees","lemma":"meubler","pos":"VER"} ,
		{"word":"meublés","word_nosc":"meubles","lemma":"meubler","pos":"VER"} ,
		{"word":"meugla","word_nosc":"meugla","lemma":"meugler","pos":"VER"} ,
		{"word":"meuglaient","word_nosc":"meuglaient","lemma":"meugler","pos":"VER"} ,
		{"word":"meuglait","word_nosc":"meuglait","lemma":"meugler","pos":"VER"} ,
		{"word":"meuglant","word_nosc":"meuglant","lemma":"meugler","pos":"VER"} ,
		{"word":"meugle","word_nosc":"meugle","lemma":"meugler","pos":"VER"} ,
		{"word":"meugler","word_nosc":"meugler","lemma":"meugler","pos":"VER"} ,
		{"word":"meuglé","word_nosc":"meugle","lemma":"meugler","pos":"VER"} ,
		{"word":"meule","word_nosc":"meule","lemma":"meuler","pos":"VER"} ,
		{"word":"meuler","word_nosc":"meuler","lemma":"meuler","pos":"VER"} ,
		{"word":"meules","word_nosc":"meules","lemma":"meuler","pos":"VER"} ,
		{"word":"meulez","word_nosc":"meulez","lemma":"meuler","pos":"VER"} ,
		{"word":"meure","word_nosc":"meure","lemma":"mourir","pos":"VER"} ,
		{"word":"meurent","word_nosc":"meurent","lemma":"mourir","pos":"VER"} ,
		{"word":"meures","word_nosc":"meures","lemma":"mourir","pos":"VER"} ,
		{"word":"meurs","word_nosc":"meurs","lemma":"mourir","pos":"VER"} ,
		{"word":"meurt","word_nosc":"meurt","lemma":"mourir","pos":"VER"} ,
		{"word":"meurtri","word_nosc":"meurtri","lemma":"meurtrir","pos":"VER"} ,
		{"word":"meurtrie","word_nosc":"meurtrie","lemma":"meurtrir","pos":"VER"} ,
		{"word":"meurtries","word_nosc":"meurtries","lemma":"meurtrir","pos":"VER"} ,
		{"word":"meurtrir","word_nosc":"meurtrir","lemma":"meurtrir","pos":"VER"} ,
		{"word":"meurtrira","word_nosc":"meurtrira","lemma":"meurtrir","pos":"VER"} ,
		{"word":"meurtriront","word_nosc":"meurtriront","lemma":"meurtrir","pos":"VER"} ,
		{"word":"meurtris","word_nosc":"meurtris","lemma":"meurtrir","pos":"VER"} ,
		{"word":"meurtrissaient","word_nosc":"meurtrissaient","lemma":"meurtrir","pos":"VER"} ,
		{"word":"meurtrissais","word_nosc":"meurtrissais","lemma":"meurtrir","pos":"VER"} ,
		{"word":"meurtrissait","word_nosc":"meurtrissait","lemma":"meurtrir","pos":"VER"} ,
		{"word":"meurtrissant","word_nosc":"meurtrissant","lemma":"meurtrir","pos":"VER"} ,
		{"word":"meurtrissent","word_nosc":"meurtrissent","lemma":"meurtrir","pos":"VER"} ,
		{"word":"meurtrit","word_nosc":"meurtrit","lemma":"meurtrir","pos":"VER"} ,
		{"word":"meus","word_nosc":"meus","lemma":"mouvoir","pos":"VER"} ,
		{"word":"meut","word_nosc":"meut","lemma":"mouvoir","pos":"VER"} ,
		{"word":"meuvent","word_nosc":"meuvent","lemma":"mouvoir","pos":"VER"} ,
		{"word":"miaula","word_nosc":"miaula","lemma":"miauler","pos":"VER"} ,
		{"word":"miaulaient","word_nosc":"miaulaient","lemma":"miauler","pos":"VER"} ,
		{"word":"miaulais","word_nosc":"miaulais","lemma":"miauler","pos":"VER"} ,
		{"word":"miaulait","word_nosc":"miaulait","lemma":"miauler","pos":"VER"} ,
		{"word":"miaulant","word_nosc":"miaulant","lemma":"miauler","pos":"VER"} ,
		{"word":"miaule","word_nosc":"miaule","lemma":"miauler","pos":"VER"} ,
		{"word":"miaulent","word_nosc":"miaulent","lemma":"miauler","pos":"VER"} ,
		{"word":"miauler","word_nosc":"miauler","lemma":"miauler","pos":"VER"} ,
		{"word":"miaulez","word_nosc":"miaulez","lemma":"miauler","pos":"VER"} ,
		{"word":"miaulé","word_nosc":"miaule","lemma":"miauler","pos":"VER"} ,
		{"word":"michetonnais","word_nosc":"michetonnais","lemma":"michetonner","pos":"VER"} ,
		{"word":"michetonne","word_nosc":"michetonne","lemma":"michetonner","pos":"VER"} ,
		{"word":"michetonner","word_nosc":"michetonner","lemma":"michetonner","pos":"VER"} ,
		{"word":"michetonnes","word_nosc":"michetonnes","lemma":"michetonner","pos":"VER"} ,
		{"word":"mignardant","word_nosc":"mignardant","lemma":"mignarder","pos":"VER"} ,
		{"word":"mignarde","word_nosc":"mignarde","lemma":"mignarder","pos":"VER"} ,
		{"word":"mignardé","word_nosc":"mignarde","lemma":"mignarder","pos":"VER"} ,
		{"word":"mignoter","word_nosc":"mignoter","lemma":"mignoter","pos":"VER"} ,
		{"word":"mignoteront","word_nosc":"mignoteront","lemma":"mignoter","pos":"VER"} ,
		{"word":"mignoté","word_nosc":"mignote","lemma":"mignoter","pos":"VER"} ,
		{"word":"migrait","word_nosc":"migrait","lemma":"migrer","pos":"VER"} ,
		{"word":"migrant","word_nosc":"migrant","lemma":"migrer","pos":"VER"} ,
		{"word":"migre","word_nosc":"migre","lemma":"migrer","pos":"VER"} ,
		{"word":"migrent","word_nosc":"migrent","lemma":"migrer","pos":"VER"} ,
		{"word":"migrer","word_nosc":"migrer","lemma":"migrer","pos":"VER"} ,
		{"word":"migres","word_nosc":"migres","lemma":"migrer","pos":"VER"} ,
		{"word":"migré","word_nosc":"migre","lemma":"migrer","pos":"VER"} ,
		{"word":"mijota","word_nosc":"mijota","lemma":"mijoter","pos":"VER"} ,
		{"word":"mijotaient","word_nosc":"mijotaient","lemma":"mijoter","pos":"VER"} ,
		{"word":"mijotais","word_nosc":"mijotais","lemma":"mijoter","pos":"VER"} ,
		{"word":"mijotait","word_nosc":"mijotait","lemma":"mijoter","pos":"VER"} ,
		{"word":"mijotant","word_nosc":"mijotant","lemma":"mijoter","pos":"VER"} ,
		{"word":"mijote","word_nosc":"mijote","lemma":"mijoter","pos":"VER"} ,
		{"word":"mijotent","word_nosc":"mijotent","lemma":"mijoter","pos":"VER"} ,
		{"word":"mijoter","word_nosc":"mijoter","lemma":"mijoter","pos":"VER"} ,
		{"word":"mijotera","word_nosc":"mijotera","lemma":"mijoter","pos":"VER"} ,
		{"word":"mijotes","word_nosc":"mijotes","lemma":"mijoter","pos":"VER"} ,
		{"word":"mijotez","word_nosc":"mijotez","lemma":"mijoter","pos":"VER"} ,
		{"word":"mijotions","word_nosc":"mijotions","lemma":"mijoter","pos":"VER"} ,
		{"word":"mijotons","word_nosc":"mijotons","lemma":"mijoter","pos":"VER"} ,
		{"word":"mijoté","word_nosc":"mijote","lemma":"mijoter","pos":"VER"} ,
		{"word":"mijotées","word_nosc":"mijotees","lemma":"mijoter","pos":"VER"} ,
		{"word":"mijotés","word_nosc":"mijotes","lemma":"mijoter","pos":"VER"} ,
		{"word":"milita","word_nosc":"milita","lemma":"militer","pos":"VER"} ,
		{"word":"militaient","word_nosc":"militaient","lemma":"militer","pos":"VER"} ,
		{"word":"militait","word_nosc":"militait","lemma":"militer","pos":"VER"} ,
		{"word":"militant","word_nosc":"militant","lemma":"militer","pos":"VER"} ,
		{"word":"militariser","word_nosc":"militariser","lemma":"militariser","pos":"VER"} ,
		{"word":"militarisé","word_nosc":"militarise","lemma":"militariser","pos":"VER"} ,
		{"word":"milite","word_nosc":"milite","lemma":"militer","pos":"VER"} ,
		{"word":"militent","word_nosc":"militent","lemma":"militer","pos":"VER"} ,
		{"word":"militer","word_nosc":"militer","lemma":"militer","pos":"VER"} ,
		{"word":"militerai","word_nosc":"militerai","lemma":"militer","pos":"VER"} ,
		{"word":"milites","word_nosc":"milites","lemma":"militer","pos":"VER"} ,
		{"word":"militons","word_nosc":"militons","lemma":"militer","pos":"VER"} ,
		{"word":"militèrent","word_nosc":"militerent","lemma":"militer","pos":"VER"} ,
		{"word":"milité","word_nosc":"milite","lemma":"militer","pos":"VER"} ,
		{"word":"millésimé","word_nosc":"millesime","lemma":"millésimer","pos":"VER"} ,
		{"word":"millésimée","word_nosc":"millesimee","lemma":"millésimer","pos":"VER"} ,
		{"word":"millésimées","word_nosc":"millesimees","lemma":"millésimer","pos":"VER"} ,
		{"word":"millésimés","word_nosc":"millesimes","lemma":"millésimer","pos":"VER"} ,
		{"word":"mima","word_nosc":"mima","lemma":"mimer","pos":"VER"} ,
		{"word":"mimaient","word_nosc":"mimaient","lemma":"mimer","pos":"VER"} ,
		{"word":"mimais","word_nosc":"mimais","lemma":"mimer","pos":"VER"} ,
		{"word":"mimait","word_nosc":"mimait","lemma":"mimer","pos":"VER"} ,
		{"word":"mimant","word_nosc":"mimant","lemma":"mimer","pos":"VER"} ,
		{"word":"mimas","word_nosc":"mimas","lemma":"mimer","pos":"VER"} ,
		{"word":"mime","word_nosc":"mime","lemma":"mimer","pos":"VER"} ,
		{"word":"miment","word_nosc":"miment","lemma":"mimer","pos":"VER"} ,
		{"word":"mimer","word_nosc":"mimer","lemma":"mimer","pos":"VER"} ,
		{"word":"mimerai","word_nosc":"mimerai","lemma":"mimer","pos":"VER"} ,
		{"word":"mimerait","word_nosc":"mimerait","lemma":"mimer","pos":"VER"} ,
		{"word":"mimâmes","word_nosc":"mimames","lemma":"mimer","pos":"VER"} ,
		{"word":"mimèrent","word_nosc":"mimerent","lemma":"mimer","pos":"VER"} ,
		{"word":"mimé","word_nosc":"mime","lemma":"mimer","pos":"VER"} ,
		{"word":"mimée","word_nosc":"mimee","lemma":"mimer","pos":"VER"} ,
		{"word":"mina","word_nosc":"mina","lemma":"miner","pos":"VER"} ,
		{"word":"minait","word_nosc":"minait","lemma":"miner","pos":"VER"} ,
		{"word":"minant","word_nosc":"minant","lemma":"miner","pos":"VER"} ,
		{"word":"minas","word_nosc":"minas","lemma":"miner","pos":"VER"} ,
		{"word":"minauda","word_nosc":"minauda","lemma":"minauder","pos":"VER"} ,
		{"word":"minaudaient","word_nosc":"minaudaient","lemma":"minauder","pos":"VER"} ,
		{"word":"minaudait","word_nosc":"minaudait","lemma":"minauder","pos":"VER"} ,
		{"word":"minaudant","word_nosc":"minaudant","lemma":"minauder","pos":"VER"} ,
		{"word":"minaude","word_nosc":"minaude","lemma":"minauder","pos":"VER"} ,
		{"word":"minaudent","word_nosc":"minaudent","lemma":"minauder","pos":"VER"} ,
		{"word":"minauder","word_nosc":"minauder","lemma":"minauder","pos":"VER"} ,
		{"word":"minauderait","word_nosc":"minauderait","lemma":"minauder","pos":"VER"} ,
		{"word":"minaudez","word_nosc":"minaudez","lemma":"minauder","pos":"VER"} ,
		{"word":"minaudé","word_nosc":"minaude","lemma":"minauder","pos":"VER"} ,
		{"word":"minci","word_nosc":"minci","lemma":"mincir","pos":"VER"} ,
		{"word":"mincir","word_nosc":"mincir","lemma":"mincir","pos":"VER"} ,
		{"word":"mincirait","word_nosc":"mincirait","lemma":"mincir","pos":"VER"} ,
		{"word":"mincis","word_nosc":"mincis","lemma":"mincir","pos":"VER"} ,
		{"word":"mincissaient","word_nosc":"mincissaient","lemma":"mincir","pos":"VER"} ,
		{"word":"mine","word_nosc":"mine","lemma":"miner","pos":"VER"} ,
		{"word":"minent","word_nosc":"minent","lemma":"miner","pos":"VER"} ,
		{"word":"miner","word_nosc":"miner","lemma":"miner","pos":"VER"} ,
		{"word":"minera","word_nosc":"minera","lemma":"miner","pos":"VER"} ,
		{"word":"minerai","word_nosc":"minerai","lemma":"miner","pos":"VER"} ,
		{"word":"mines","word_nosc":"mines","lemma":"miner","pos":"VER"} ,
		{"word":"miniaturise","word_nosc":"miniaturise","lemma":"miniaturiser","pos":"VER"} ,
		{"word":"miniaturiser","word_nosc":"miniaturiser","lemma":"miniaturiser","pos":"VER"} ,
		{"word":"miniaturisé","word_nosc":"miniaturise","lemma":"miniaturiser","pos":"VER"} ,
		{"word":"miniaturisée","word_nosc":"miniaturisee","lemma":"miniaturiser","pos":"VER"} ,
		{"word":"miniaturisées","word_nosc":"miniaturisees","lemma":"miniaturiser","pos":"VER"} ,
		{"word":"miniaturisés","word_nosc":"miniaturises","lemma":"miniaturiser","pos":"VER"} ,
		{"word":"minimaliser","word_nosc":"minimaliser","lemma":"minimaliser","pos":"VER"} ,
		{"word":"minimisa","word_nosc":"minimisa","lemma":"minimiser","pos":"VER"} ,
		{"word":"minimisaient","word_nosc":"minimisaient","lemma":"minimiser","pos":"VER"} ,
		{"word":"minimisait","word_nosc":"minimisait","lemma":"minimiser","pos":"VER"} ,
		{"word":"minimisant","word_nosc":"minimisant","lemma":"minimiser","pos":"VER"} ,
		{"word":"minimise","word_nosc":"minimise","lemma":"minimiser","pos":"VER"} ,
		{"word":"minimisent","word_nosc":"minimisent","lemma":"minimiser","pos":"VER"} ,
		{"word":"minimiser","word_nosc":"minimiser","lemma":"minimiser","pos":"VER"} ,
		{"word":"minimiserons","word_nosc":"minimiserons","lemma":"minimiser","pos":"VER"} ,
		{"word":"minimises","word_nosc":"minimises","lemma":"minimiser","pos":"VER"} ,
		{"word":"minimisez","word_nosc":"minimisez","lemma":"minimiser","pos":"VER"} ,
		{"word":"minimisons","word_nosc":"minimisons","lemma":"minimiser","pos":"VER"} ,
		{"word":"minimisé","word_nosc":"minimise","lemma":"minimiser","pos":"VER"} ,
		{"word":"minimisée","word_nosc":"minimisee","lemma":"minimiser","pos":"VER"} ,
		{"word":"minimisées","word_nosc":"minimisees","lemma":"minimiser","pos":"VER"} ,
		{"word":"minute","word_nosc":"minute","lemma":"minuter","pos":"VER"} ,
		{"word":"minuter","word_nosc":"minuter","lemma":"minuter","pos":"VER"} ,
		{"word":"minutes","word_nosc":"minutes","lemma":"minuter","pos":"VER"} ,
		{"word":"minutions","word_nosc":"minutions","lemma":"minuter","pos":"VER"} ,
		{"word":"minutât","word_nosc":"minutat","lemma":"minuter","pos":"VER"} ,
		{"word":"minuté","word_nosc":"minute","lemma":"minuter","pos":"VER"} ,
		{"word":"minutée","word_nosc":"minutee","lemma":"minuter","pos":"VER"} ,
		{"word":"minutés","word_nosc":"minutes","lemma":"minuter","pos":"VER"} ,
		{"word":"miné","word_nosc":"mine","lemma":"miner","pos":"VER"} ,
		{"word":"minée","word_nosc":"minee","lemma":"miner","pos":"VER"} ,
		{"word":"minées","word_nosc":"minees","lemma":"miner","pos":"VER"} ,
		{"word":"minéralisés","word_nosc":"mineralises","lemma":"minéraliser","pos":"VER"} ,
		{"word":"minés","word_nosc":"mines","lemma":"miner","pos":"VER"} ,
		{"word":"mira","word_nosc":"mira","lemma":"mirer","pos":"VER"} ,
		{"word":"miraient","word_nosc":"miraient","lemma":"mirer","pos":"VER"} ,
		{"word":"mirais","word_nosc":"mirais","lemma":"mirer","pos":"VER"} ,
		{"word":"mirait","word_nosc":"mirait","lemma":"mirer","pos":"VER"} ,
		{"word":"mirant","word_nosc":"mirant","lemma":"mirer","pos":"VER"} ,
		{"word":"mire","word_nosc":"mire","lemma":"mirer","pos":"VER"} ,
		{"word":"mirent","word_nosc":"mirent","lemma":"mettre","pos":"VER"} ,
		{"word":"mirent","word_nosc":"mirent","lemma":"mirer","pos":"VER"} ,
		{"word":"mirer","word_nosc":"mirer","lemma":"mirer","pos":"VER"} ,
		{"word":"mirerais","word_nosc":"mirerais","lemma":"mirer","pos":"VER"} ,
		{"word":"mirez","word_nosc":"mirez","lemma":"mirer","pos":"VER"} ,
		{"word":"miroita","word_nosc":"miroita","lemma":"miroiter","pos":"VER"} ,
		{"word":"miroitaient","word_nosc":"miroitaient","lemma":"miroiter","pos":"VER"} ,
		{"word":"miroitait","word_nosc":"miroitait","lemma":"miroiter","pos":"VER"} ,
		{"word":"miroitant","word_nosc":"miroitant","lemma":"miroiter","pos":"VER"} ,
		{"word":"miroite","word_nosc":"miroite","lemma":"miroiter","pos":"VER"} ,
		{"word":"miroitent","word_nosc":"miroitent","lemma":"miroiter","pos":"VER"} ,
		{"word":"miroiter","word_nosc":"miroiter","lemma":"miroiter","pos":"VER"} ,
		{"word":"mirée","word_nosc":"miree","lemma":"mirer","pos":"VER"} ,
		{"word":"mis","word_nosc":"mis","lemma":"mettre","pos":"VER"} ,
		{"word":"misa","word_nosc":"misa","lemma":"miser","pos":"VER"} ,
		{"word":"misai","word_nosc":"misai","lemma":"miser","pos":"VER"} ,
		{"word":"misaient","word_nosc":"misaient","lemma":"miser","pos":"VER"} ,
		{"word":"misais","word_nosc":"misais","lemma":"miser","pos":"VER"} ,
		{"word":"misait","word_nosc":"misait","lemma":"miser","pos":"VER"} ,
		{"word":"misant","word_nosc":"misant","lemma":"miser","pos":"VER"} ,
		{"word":"mise","word_nosc":"mise","lemma":"mettre","pos":"VER"} ,
		{"word":"mise","word_nosc":"mise","lemma":"miser","pos":"VER"} ,
		{"word":"misent","word_nosc":"misent","lemma":"miser","pos":"VER"} ,
		{"word":"miser","word_nosc":"miser","lemma":"miser","pos":"VER"} ,
		{"word":"misera","word_nosc":"misera","lemma":"miser","pos":"VER"} ,
		{"word":"miserais","word_nosc":"miserais","lemma":"miser","pos":"VER"} ,
		{"word":"miseras","word_nosc":"miseras","lemma":"miser","pos":"VER"} ,
		{"word":"miseriez","word_nosc":"miseriez","lemma":"miser","pos":"VER"} ,
		{"word":"miseront","word_nosc":"miseront","lemma":"miser","pos":"VER"} ,
		{"word":"mises","word_nosc":"mises","lemma":"mettre","pos":"VER"} ,
		{"word":"mises","word_nosc":"mises","lemma":"miser","pos":"VER"} ,
		{"word":"misez","word_nosc":"misez","lemma":"miser","pos":"VER"} ,
		{"word":"misiez","word_nosc":"misiez","lemma":"miser","pos":"VER"} ,
		{"word":"misons","word_nosc":"misons","lemma":"miser","pos":"VER"} ,
		{"word":"missent","word_nosc":"missent","lemma":"mettre","pos":"VER"} ,
		{"word":"missionna","word_nosc":"missionna","lemma":"missionner","pos":"VER"} ,
		{"word":"missionné","word_nosc":"missionne","lemma":"missionner","pos":"VER"} ,
		{"word":"missionnés","word_nosc":"missionnes","lemma":"missionner","pos":"VER"} ,
		{"word":"missions","word_nosc":"missions","lemma":"mettre","pos":"VER"} ,
		{"word":"misât","word_nosc":"misat","lemma":"miser","pos":"VER"} ,
		{"word":"misé","word_nosc":"mise","lemma":"miser","pos":"VER"} ,
		{"word":"mit","word_nosc":"mit","lemma":"mettre","pos":"VER"} ,
		{"word":"mita","word_nosc":"mita","lemma":"miter","pos":"VER"} ,
		{"word":"mitarder","word_nosc":"mitarder","lemma":"mitarder","pos":"VER"} ,
		{"word":"mitardés","word_nosc":"mitardes","lemma":"mitarder","pos":"VER"} ,
		{"word":"mitent","word_nosc":"mitent","lemma":"miter","pos":"VER"} ,
		{"word":"miter","word_nosc":"miter","lemma":"miter","pos":"VER"} ,
		{"word":"mites","word_nosc":"mites","lemma":"miter","pos":"VER"} ,
		{"word":"mithridatisé","word_nosc":"mithridatise","lemma":"mithridatiser","pos":"VER"} ,
		{"word":"mithridatisés","word_nosc":"mithridatises","lemma":"mithridatiser","pos":"VER"} ,
		{"word":"mitigea","word_nosc":"mitigea","lemma":"mitiger","pos":"VER"} ,
		{"word":"mitiger","word_nosc":"mitiger","lemma":"mitiger","pos":"VER"} ,
		{"word":"mitigé","word_nosc":"mitige","lemma":"mitiger","pos":"VER"} ,
		{"word":"mitigée","word_nosc":"mitigee","lemma":"mitiger","pos":"VER"} ,
		{"word":"mitigées","word_nosc":"mitigees","lemma":"mitiger","pos":"VER"} ,
		{"word":"mitigés","word_nosc":"mitiges","lemma":"mitiger","pos":"VER"} ,
		{"word":"mitonnaient","word_nosc":"mitonnaient","lemma":"mitonner","pos":"VER"} ,
		{"word":"mitonnait","word_nosc":"mitonnait","lemma":"mitonner","pos":"VER"} ,
		{"word":"mitonnant","word_nosc":"mitonnant","lemma":"mitonner","pos":"VER"} ,
		{"word":"mitonne","word_nosc":"mitonne","lemma":"mitonner","pos":"VER"} ,
		{"word":"mitonnent","word_nosc":"mitonnent","lemma":"mitonner","pos":"VER"} ,
		{"word":"mitonner","word_nosc":"mitonner","lemma":"mitonner","pos":"VER"} ,
		{"word":"mitonnerait","word_nosc":"mitonnerait","lemma":"mitonner","pos":"VER"} ,
		{"word":"mitonné","word_nosc":"mitonne","lemma":"mitonner","pos":"VER"} ,
		{"word":"mitonnée","word_nosc":"mitonnee","lemma":"mitonner","pos":"VER"} ,
		{"word":"mitonnées","word_nosc":"mitonnees","lemma":"mitonner","pos":"VER"} ,
		{"word":"mitonnés","word_nosc":"mitonnes","lemma":"mitonner","pos":"VER"} ,
		{"word":"mitra","word_nosc":"mitra","lemma":"mitrer","pos":"VER"} ,
		{"word":"mitrailla","word_nosc":"mitrailla","lemma":"mitrailler","pos":"VER"} ,
		{"word":"mitraillaient","word_nosc":"mitraillaient","lemma":"mitrailler","pos":"VER"} ,
		{"word":"mitraillait","word_nosc":"mitraillait","lemma":"mitrailler","pos":"VER"} ,
		{"word":"mitraillant","word_nosc":"mitraillant","lemma":"mitrailler","pos":"VER"} ,
		{"word":"mitraille","word_nosc":"mitraille","lemma":"mitrailler","pos":"VER"} ,
		{"word":"mitraillent","word_nosc":"mitraillent","lemma":"mitrailler","pos":"VER"} ,
		{"word":"mitrailler","word_nosc":"mitrailler","lemma":"mitrailler","pos":"VER"} ,
		{"word":"mitrailleraient","word_nosc":"mitrailleraient","lemma":"mitrailler","pos":"VER"} ,
		{"word":"mitrailles","word_nosc":"mitrailles","lemma":"mitrailler","pos":"VER"} ,
		{"word":"mitrailliez","word_nosc":"mitrailliez","lemma":"mitrailler","pos":"VER"} ,
		{"word":"mitraillèrent","word_nosc":"mitraillerent","lemma":"mitrailler","pos":"VER"} ,
		{"word":"mitraillé","word_nosc":"mitraille","lemma":"mitrailler","pos":"VER"} ,
		{"word":"mitraillée","word_nosc":"mitraillee","lemma":"mitrailler","pos":"VER"} ,
		{"word":"mitraillées","word_nosc":"mitraillees","lemma":"mitrailler","pos":"VER"} ,
		{"word":"mitraillés","word_nosc":"mitrailles","lemma":"mitrailler","pos":"VER"} ,
		{"word":"mitré","word_nosc":"mitre","lemma":"mitrer","pos":"VER"} ,
		{"word":"mitrés","word_nosc":"mitres","lemma":"mitrer","pos":"VER"} ,
		{"word":"mité","word_nosc":"mite","lemma":"miter","pos":"VER"} ,
		{"word":"mitée","word_nosc":"mitee","lemma":"miter","pos":"VER"} ,
		{"word":"mixait","word_nosc":"mixait","lemma":"mixer","pos":"VER"} ,
		{"word":"mixe","word_nosc":"mixe","lemma":"mixer","pos":"VER"} ,
		{"word":"mixer","word_nosc":"mixer","lemma":"mixer","pos":"VER"} ,
		{"word":"mixé","word_nosc":"mixe","lemma":"mixer","pos":"VER"} ,
		{"word":"mixée","word_nosc":"mixee","lemma":"mixer","pos":"VER"} ,
		{"word":"mixés","word_nosc":"mixes","lemma":"mixer","pos":"VER"} ,
		{"word":"mobilisa","word_nosc":"mobilisa","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobilisaient","word_nosc":"mobilisaient","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobilisais","word_nosc":"mobilisais","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobilisait","word_nosc":"mobilisait","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobilisant","word_nosc":"mobilisant","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobilise","word_nosc":"mobilise","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobilisent","word_nosc":"mobilisent","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobiliser","word_nosc":"mobiliser","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobilisera","word_nosc":"mobilisera","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobiliserait","word_nosc":"mobiliserait","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobiliserons","word_nosc":"mobiliserons","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobilisez","word_nosc":"mobilisez","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobilisions","word_nosc":"mobilisions","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobilisons","word_nosc":"mobilisons","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobilisé","word_nosc":"mobilise","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobilisée","word_nosc":"mobilisee","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobilisées","word_nosc":"mobilisees","lemma":"mobiliser","pos":"VER"} ,
		{"word":"mobilisés","word_nosc":"mobilises","lemma":"mobiliser","pos":"VER"} ,
		{"word":"modela","word_nosc":"modela","lemma":"modeler","pos":"VER"} ,
		{"word":"modelaient","word_nosc":"modelaient","lemma":"modeler","pos":"VER"} ,
		{"word":"modelais","word_nosc":"modelais","lemma":"modeler","pos":"VER"} ,
		{"word":"modelait","word_nosc":"modelait","lemma":"modeler","pos":"VER"} ,
		{"word":"modelant","word_nosc":"modelant","lemma":"modeler","pos":"VER"} ,
		{"word":"modeler","word_nosc":"modeler","lemma":"modeler","pos":"VER"} ,
		{"word":"modelez","word_nosc":"modelez","lemma":"modeler","pos":"VER"} ,
		{"word":"modelé","word_nosc":"modele","lemma":"modeler","pos":"VER"} ,
		{"word":"modelée","word_nosc":"modelee","lemma":"modeler","pos":"VER"} ,
		{"word":"modelées","word_nosc":"modelees","lemma":"modeler","pos":"VER"} ,
		{"word":"modelés","word_nosc":"modeles","lemma":"modeler","pos":"VER"} ,
		{"word":"modernisaient","word_nosc":"modernisaient","lemma":"moderniser","pos":"VER"} ,
		{"word":"modernisait","word_nosc":"modernisait","lemma":"moderniser","pos":"VER"} ,
		{"word":"modernise","word_nosc":"modernise","lemma":"moderniser","pos":"VER"} ,
		{"word":"modernisent","word_nosc":"modernisent","lemma":"moderniser","pos":"VER"} ,
		{"word":"moderniser","word_nosc":"moderniser","lemma":"moderniser","pos":"VER"} ,
		{"word":"modernisons","word_nosc":"modernisons","lemma":"moderniser","pos":"VER"} ,
		{"word":"modernisé","word_nosc":"modernise","lemma":"moderniser","pos":"VER"} ,
		{"word":"modernisée","word_nosc":"modernisee","lemma":"moderniser","pos":"VER"} ,
		{"word":"modernisés","word_nosc":"modernises","lemma":"moderniser","pos":"VER"} ,
		{"word":"modifia","word_nosc":"modifia","lemma":"modifier","pos":"VER"} ,
		{"word":"modifiaient","word_nosc":"modifiaient","lemma":"modifier","pos":"VER"} ,
		{"word":"modifiais","word_nosc":"modifiais","lemma":"modifier","pos":"VER"} ,
		{"word":"modifiait","word_nosc":"modifiait","lemma":"modifier","pos":"VER"} ,
		{"word":"modifiant","word_nosc":"modifiant","lemma":"modifier","pos":"VER"} ,
		{"word":"modifie","word_nosc":"modifie","lemma":"modifier","pos":"VER"} ,
		{"word":"modifient","word_nosc":"modifient","lemma":"modifier","pos":"VER"} ,
		{"word":"modifier","word_nosc":"modifier","lemma":"modifier","pos":"VER"} ,
		{"word":"modifiera","word_nosc":"modifiera","lemma":"modifier","pos":"VER"} ,
		{"word":"modifierai","word_nosc":"modifierai","lemma":"modifier","pos":"VER"} ,
		{"word":"modifieraient","word_nosc":"modifieraient","lemma":"modifier","pos":"VER"} ,
		{"word":"modifierais","word_nosc":"modifierais","lemma":"modifier","pos":"VER"} ,
		{"word":"modifierait","word_nosc":"modifierait","lemma":"modifier","pos":"VER"} ,
		{"word":"modifiez","word_nosc":"modifiez","lemma":"modifier","pos":"VER"} ,
		{"word":"modifions","word_nosc":"modifions","lemma":"modifier","pos":"VER"} ,
		{"word":"modifiât","word_nosc":"modifiat","lemma":"modifier","pos":"VER"} ,
		{"word":"modifièrent","word_nosc":"modifierent","lemma":"modifier","pos":"VER"} ,
		{"word":"modifié","word_nosc":"modifie","lemma":"modifier","pos":"VER"} ,
		{"word":"modifiée","word_nosc":"modifiee","lemma":"modifier","pos":"VER"} ,
		{"word":"modifiées","word_nosc":"modifiees","lemma":"modifier","pos":"VER"} ,
		{"word":"modifiés","word_nosc":"modifies","lemma":"modifier","pos":"VER"} ,
		{"word":"modula","word_nosc":"modula","lemma":"moduler","pos":"VER"} ,
		{"word":"modulai","word_nosc":"modulai","lemma":"moduler","pos":"VER"} ,
		{"word":"modulais","word_nosc":"modulais","lemma":"moduler","pos":"VER"} ,
		{"word":"modulait","word_nosc":"modulait","lemma":"moduler","pos":"VER"} ,
		{"word":"modulant","word_nosc":"modulant","lemma":"moduler","pos":"VER"} ,
		{"word":"module","word_nosc":"module","lemma":"moduler","pos":"VER"} ,
		{"word":"modulent","word_nosc":"modulent","lemma":"moduler","pos":"VER"} ,
		{"word":"moduler","word_nosc":"moduler","lemma":"moduler","pos":"VER"} ,
		{"word":"modules","word_nosc":"modules","lemma":"moduler","pos":"VER"} ,
		{"word":"modulé","word_nosc":"module","lemma":"moduler","pos":"VER"} ,
		{"word":"modulée","word_nosc":"modulee","lemma":"moduler","pos":"VER"} ,
		{"word":"modulées","word_nosc":"modulees","lemma":"moduler","pos":"VER"} ,
		{"word":"modulés","word_nosc":"modules","lemma":"moduler","pos":"VER"} ,
		{"word":"modèle","word_nosc":"modele","lemma":"modeler","pos":"VER"} ,
		{"word":"modèlent","word_nosc":"modelent","lemma":"modeler","pos":"VER"} ,
		{"word":"modèles","word_nosc":"modeles","lemma":"modeler","pos":"VER"} ,
		{"word":"modère","word_nosc":"modere","lemma":"modérer","pos":"VER"} ,
		{"word":"modélisé","word_nosc":"modelise","lemma":"modéliser","pos":"VER"} ,
		{"word":"modélisée","word_nosc":"modelisee","lemma":"modéliser","pos":"VER"} ,
		{"word":"modéra","word_nosc":"modera","lemma":"modérer","pos":"VER"} ,
		{"word":"modérai","word_nosc":"moderai","lemma":"modérer","pos":"VER"} ,
		{"word":"modérait","word_nosc":"moderait","lemma":"modérer","pos":"VER"} ,
		{"word":"modérant","word_nosc":"moderant","lemma":"modérer","pos":"VER"} ,
		{"word":"modérer","word_nosc":"moderer","lemma":"modérer","pos":"VER"} ,
		{"word":"modéreras","word_nosc":"modereras","lemma":"modérer","pos":"VER"} ,
		{"word":"modérez","word_nosc":"moderez","lemma":"modérer","pos":"VER"} ,
		{"word":"modéré","word_nosc":"modere","lemma":"modérer","pos":"VER"} ,
		{"word":"modérée","word_nosc":"moderee","lemma":"modérer","pos":"VER"} ,
		{"word":"modérées","word_nosc":"moderees","lemma":"modérer","pos":"VER"} ,
		{"word":"modérés","word_nosc":"moderes","lemma":"modérer","pos":"VER"} ,
		{"word":"moiraient","word_nosc":"moiraient","lemma":"moirer","pos":"VER"} ,
		{"word":"moiré","word_nosc":"moire","lemma":"moirer","pos":"VER"} ,
		{"word":"moirée","word_nosc":"moiree","lemma":"moirer","pos":"VER"} ,
		{"word":"moirées","word_nosc":"moirees","lemma":"moirer","pos":"VER"} ,
		{"word":"moirés","word_nosc":"moires","lemma":"moirer","pos":"VER"} ,
		{"word":"moisi","word_nosc":"moisi","lemma":"moisir","pos":"VER"} ,
		{"word":"moisie","word_nosc":"moisie","lemma":"moisir","pos":"VER"} ,
		{"word":"moisies","word_nosc":"moisies","lemma":"moisir","pos":"VER"} ,
		{"word":"moisir","word_nosc":"moisir","lemma":"moisir","pos":"VER"} ,
		{"word":"moisira","word_nosc":"moisira","lemma":"moisir","pos":"VER"} ,
		{"word":"moisirai","word_nosc":"moisirai","lemma":"moisir","pos":"VER"} ,
		{"word":"moisiraient","word_nosc":"moisiraient","lemma":"moisir","pos":"VER"} ,
		{"word":"moisirais","word_nosc":"moisirais","lemma":"moisir","pos":"VER"} ,
		{"word":"moisirait","word_nosc":"moisirait","lemma":"moisir","pos":"VER"} ,
		{"word":"moisiras","word_nosc":"moisiras","lemma":"moisir","pos":"VER"} ,
		{"word":"moisirez","word_nosc":"moisirez","lemma":"moisir","pos":"VER"} ,
		{"word":"moisiront","word_nosc":"moisiront","lemma":"moisir","pos":"VER"} ,
		{"word":"moisis","word_nosc":"moisis","lemma":"moisir","pos":"VER"} ,
		{"word":"moisissaient","word_nosc":"moisissaient","lemma":"moisir","pos":"VER"} ,
		{"word":"moisissais","word_nosc":"moisissais","lemma":"moisir","pos":"VER"} ,
		{"word":"moisissait","word_nosc":"moisissait","lemma":"moisir","pos":"VER"} ,
		{"word":"moisissant","word_nosc":"moisissant","lemma":"moisir","pos":"VER"} ,
		{"word":"moisisse","word_nosc":"moisisse","lemma":"moisir","pos":"VER"} ,
		{"word":"moisissent","word_nosc":"moisissent","lemma":"moisir","pos":"VER"} ,
		{"word":"moisissez","word_nosc":"moisissez","lemma":"moisir","pos":"VER"} ,
		{"word":"moisit","word_nosc":"moisit","lemma":"moisir","pos":"VER"} ,
		{"word":"moissonnait","word_nosc":"moissonnait","lemma":"moissonner","pos":"VER"} ,
		{"word":"moissonnant","word_nosc":"moissonnant","lemma":"moissonner","pos":"VER"} ,
		{"word":"moissonne","word_nosc":"moissonne","lemma":"moissonner","pos":"VER"} ,
		{"word":"moissonnent","word_nosc":"moissonnent","lemma":"moissonner","pos":"VER"} ,
		{"word":"moissonner","word_nosc":"moissonner","lemma":"moissonner","pos":"VER"} ,
		{"word":"moissonnera","word_nosc":"moissonnera","lemma":"moissonner","pos":"VER"} ,
		{"word":"moissonnerai","word_nosc":"moissonnerai","lemma":"moissonner","pos":"VER"} ,
		{"word":"moissonnez","word_nosc":"moissonnez","lemma":"moissonner","pos":"VER"} ,
		{"word":"moissonné","word_nosc":"moissonne","lemma":"moissonner","pos":"VER"} ,
		{"word":"moissonnée","word_nosc":"moissonnee","lemma":"moissonner","pos":"VER"} ,
		{"word":"moissonnées","word_nosc":"moissonnees","lemma":"moissonner","pos":"VER"} ,
		{"word":"moissonnés","word_nosc":"moissonnes","lemma":"moissonner","pos":"VER"} ,
		{"word":"moiti","word_nosc":"moiti","lemma":"moitir","pos":"VER"} ,
		{"word":"molesta","word_nosc":"molesta","lemma":"molester","pos":"VER"} ,
		{"word":"molestaient","word_nosc":"molestaient","lemma":"molester","pos":"VER"} ,
		{"word":"molestant","word_nosc":"molestant","lemma":"molester","pos":"VER"} ,
		{"word":"moleste","word_nosc":"moleste","lemma":"molester","pos":"VER"} ,
		{"word":"molester","word_nosc":"molester","lemma":"molester","pos":"VER"} ,
		{"word":"molestez","word_nosc":"molestez","lemma":"molester","pos":"VER"} ,
		{"word":"molesté","word_nosc":"moleste","lemma":"molester","pos":"VER"} ,
		{"word":"molestée","word_nosc":"molestee","lemma":"molester","pos":"VER"} ,
		{"word":"molestés","word_nosc":"molestes","lemma":"molester","pos":"VER"} ,
		{"word":"moleta","word_nosc":"moleta","lemma":"moleter","pos":"VER"} ,
		{"word":"mollarder","word_nosc":"mollarder","lemma":"mollarder","pos":"VER"} ,
		{"word":"mollardé","word_nosc":"mollarde","lemma":"mollarder","pos":"VER"} ,
		{"word":"molletonné","word_nosc":"molletonne","lemma":"molletonner","pos":"VER"} ,
		{"word":"molletonnée","word_nosc":"molletonnee","lemma":"molletonner","pos":"VER"} ,
		{"word":"molli","word_nosc":"molli","lemma":"mollir","pos":"VER"} ,
		{"word":"mollie","word_nosc":"mollie","lemma":"mollir","pos":"VER"} ,
		{"word":"mollir","word_nosc":"mollir","lemma":"mollir","pos":"VER"} ,
		{"word":"mollis","word_nosc":"mollis","lemma":"mollir","pos":"VER"} ,
		{"word":"mollissaient","word_nosc":"mollissaient","lemma":"mollir","pos":"VER"} ,
		{"word":"mollissait","word_nosc":"mollissait","lemma":"mollir","pos":"VER"} ,
		{"word":"mollissant","word_nosc":"mollissant","lemma":"mollir","pos":"VER"} ,
		{"word":"mollisse","word_nosc":"mollisse","lemma":"mollir","pos":"VER"} ,
		{"word":"mollissent","word_nosc":"mollissent","lemma":"mollir","pos":"VER"} ,
		{"word":"mollissez","word_nosc":"mollissez","lemma":"mollir","pos":"VER"} ,
		{"word":"mollit","word_nosc":"mollit","lemma":"mollir","pos":"VER"} ,
		{"word":"momifiait","word_nosc":"momifiait","lemma":"momifier","pos":"VER"} ,
		{"word":"momifiant","word_nosc":"momifiant","lemma":"momifier","pos":"VER"} ,
		{"word":"momifie","word_nosc":"momifie","lemma":"momifier","pos":"VER"} ,
		{"word":"momifier","word_nosc":"momifier","lemma":"momifier","pos":"VER"} ,
		{"word":"momifié","word_nosc":"momifie","lemma":"momifier","pos":"VER"} ,
		{"word":"momifiée","word_nosc":"momifiee","lemma":"momifier","pos":"VER"} ,
		{"word":"momifiées","word_nosc":"momifiees","lemma":"momifier","pos":"VER"} ,
		{"word":"momifiés","word_nosc":"momifies","lemma":"momifier","pos":"VER"} ,
		{"word":"monda","word_nosc":"monda","lemma":"monder","pos":"VER"} ,
		{"word":"mondanisaient","word_nosc":"mondanisaient","lemma":"mondaniser","pos":"VER"} ,
		{"word":"mondanisé","word_nosc":"mondanise","lemma":"mondaniser","pos":"VER"} ,
		{"word":"monder","word_nosc":"monder","lemma":"monder","pos":"VER"} ,
		{"word":"mondialisent","word_nosc":"mondialisent","lemma":"mondialiser","pos":"VER"} ,
		{"word":"mondée","word_nosc":"mondee","lemma":"monder","pos":"VER"} ,
		{"word":"monnaie","word_nosc":"monnaie","lemma":"monnayer","pos":"VER"} ,
		{"word":"monnaiera","word_nosc":"monnaiera","lemma":"monnayer","pos":"VER"} ,
		{"word":"monnaierai","word_nosc":"monnaierai","lemma":"monnayer","pos":"VER"} ,
		{"word":"monnaya","word_nosc":"monnaya","lemma":"monnayer","pos":"VER"} ,
		{"word":"monnayait","word_nosc":"monnayait","lemma":"monnayer","pos":"VER"} ,
		{"word":"monnayant","word_nosc":"monnayant","lemma":"monnayer","pos":"VER"} ,
		{"word":"monnaye","word_nosc":"monnaye","lemma":"monnayer","pos":"VER"} ,
		{"word":"monnayer","word_nosc":"monnayer","lemma":"monnayer","pos":"VER"} ,
		{"word":"monnayé","word_nosc":"monnaye","lemma":"monnayer","pos":"VER"} ,
		{"word":"monnayée","word_nosc":"monnayee","lemma":"monnayer","pos":"VER"} ,
		{"word":"monologua","word_nosc":"monologua","lemma":"monologuer","pos":"VER"} ,
		{"word":"monologuaient","word_nosc":"monologuaient","lemma":"monologuer","pos":"VER"} ,
		{"word":"monologuais","word_nosc":"monologuais","lemma":"monologuer","pos":"VER"} ,
		{"word":"monologuait","word_nosc":"monologuait","lemma":"monologuer","pos":"VER"} ,
		{"word":"monologuant","word_nosc":"monologuant","lemma":"monologuer","pos":"VER"} ,
		{"word":"monologue","word_nosc":"monologue","lemma":"monologuer","pos":"VER"} ,
		{"word":"monologuer","word_nosc":"monologuer","lemma":"monologuer","pos":"VER"} ,
		{"word":"monopolisa","word_nosc":"monopolisa","lemma":"monopoliser","pos":"VER"} ,
		{"word":"monopolisaient","word_nosc":"monopolisaient","lemma":"monopoliser","pos":"VER"} ,
		{"word":"monopolisais","word_nosc":"monopolisais","lemma":"monopoliser","pos":"VER"} ,
		{"word":"monopolisait","word_nosc":"monopolisait","lemma":"monopoliser","pos":"VER"} ,
		{"word":"monopolise","word_nosc":"monopolise","lemma":"monopoliser","pos":"VER"} ,
		{"word":"monopolisent","word_nosc":"monopolisent","lemma":"monopoliser","pos":"VER"} ,
		{"word":"monopoliser","word_nosc":"monopoliser","lemma":"monopoliser","pos":"VER"} ,
		{"word":"monopolisera","word_nosc":"monopolisera","lemma":"monopoliser","pos":"VER"} ,
		{"word":"monopolisez","word_nosc":"monopolisez","lemma":"monopoliser","pos":"VER"} ,
		{"word":"monopolisèrent","word_nosc":"monopoliserent","lemma":"monopoliser","pos":"VER"} ,
		{"word":"monopolisé","word_nosc":"monopolise","lemma":"monopoliser","pos":"VER"} ,
		{"word":"monopolisés","word_nosc":"monopolises","lemma":"monopoliser","pos":"VER"} ,
		{"word":"monta","word_nosc":"monta","lemma":"monter","pos":"VER"} ,
		{"word":"montai","word_nosc":"montai","lemma":"monter","pos":"VER"} ,
		{"word":"montaient","word_nosc":"montaient","lemma":"monter","pos":"VER"} ,
		{"word":"montais","word_nosc":"montais","lemma":"monter","pos":"VER"} ,
		{"word":"montait","word_nosc":"montait","lemma":"monter","pos":"VER"} ,
		{"word":"montant","word_nosc":"montant","lemma":"monter","pos":"VER"} ,
		{"word":"monte","word_nosc":"monte","lemma":"monter","pos":"VER"} ,
		{"word":"montent","word_nosc":"montent","lemma":"monter","pos":"VER"} ,
		{"word":"monter","word_nosc":"monter","lemma":"monter","pos":"VER"} ,
		{"word":"montera","word_nosc":"montera","lemma":"monter","pos":"VER"} ,
		{"word":"monterai","word_nosc":"monterai","lemma":"monter","pos":"VER"} ,
		{"word":"monteraient","word_nosc":"monteraient","lemma":"monter","pos":"VER"} ,
		{"word":"monterais","word_nosc":"monterais","lemma":"monter","pos":"VER"} ,
		{"word":"monterait","word_nosc":"monterait","lemma":"monter","pos":"VER"} ,
		{"word":"monteras","word_nosc":"monteras","lemma":"monter","pos":"VER"} ,
		{"word":"monterez","word_nosc":"monterez","lemma":"monter","pos":"VER"} ,
		{"word":"monteriez","word_nosc":"monteriez","lemma":"monter","pos":"VER"} ,
		{"word":"monterons","word_nosc":"monterons","lemma":"monter","pos":"VER"} ,
		{"word":"monteront","word_nosc":"monteront","lemma":"monter","pos":"VER"} ,
		{"word":"montes","word_nosc":"montes","lemma":"monter","pos":"VER"} ,
		{"word":"montez","word_nosc":"montez","lemma":"monter","pos":"VER"} ,
		{"word":"montiez","word_nosc":"montiez","lemma":"monter","pos":"VER"} ,
		{"word":"montions","word_nosc":"montions","lemma":"monter","pos":"VER"} ,
		{"word":"montons","word_nosc":"montons","lemma":"monter","pos":"VER"} ,
		{"word":"montra","word_nosc":"montra","lemma":"montrer","pos":"VER"} ,
		{"word":"montrai","word_nosc":"montrai","lemma":"montrer","pos":"VER"} ,
		{"word":"montraient","word_nosc":"montraient","lemma":"montrer","pos":"VER"} ,
		{"word":"montrais","word_nosc":"montrais","lemma":"montrer","pos":"VER"} ,
		{"word":"montrait","word_nosc":"montrait","lemma":"montrer","pos":"VER"} ,
		{"word":"montrant","word_nosc":"montrant","lemma":"montrer","pos":"VER"} ,
		{"word":"montrassent","word_nosc":"montrassent","lemma":"montrer","pos":"VER"} ,
		{"word":"montre","word_nosc":"montre","lemma":"montrer","pos":"VER"} ,
		{"word":"montre-la-moi","word_nosc":"montre-la-moi","lemma":"montrer","pos":"VER"} ,
		{"word":"montrent","word_nosc":"montrent","lemma":"montrer","pos":"VER"} ,
		{"word":"montrer","word_nosc":"montrer","lemma":"montrer","pos":"VER"} ,
		{"word":"montrera","word_nosc":"montrera","lemma":"montrer","pos":"VER"} ,
		{"word":"montrerai","word_nosc":"montrerai","lemma":"montrer","pos":"VER"} ,
		{"word":"montreraient","word_nosc":"montreraient","lemma":"montrer","pos":"VER"} ,
		{"word":"montrerais","word_nosc":"montrerais","lemma":"montrer","pos":"VER"} ,
		{"word":"montrerait","word_nosc":"montrerait","lemma":"montrer","pos":"VER"} ,
		{"word":"montreras","word_nosc":"montreras","lemma":"montrer","pos":"VER"} ,
		{"word":"montrerez","word_nosc":"montrerez","lemma":"montrer","pos":"VER"} ,
		{"word":"montreriez","word_nosc":"montreriez","lemma":"montrer","pos":"VER"} ,
		{"word":"montrerons","word_nosc":"montrerons","lemma":"montrer","pos":"VER"} ,
		{"word":"montreront","word_nosc":"montreront","lemma":"montrer","pos":"VER"} ,
		{"word":"montres","word_nosc":"montres","lemma":"montrer","pos":"VER"} ,
		{"word":"montrez","word_nosc":"montrez","lemma":"montrer","pos":"VER"} ,
		{"word":"montriez","word_nosc":"montriez","lemma":"montrer","pos":"VER"} ,
		{"word":"montrions","word_nosc":"montrions","lemma":"montrer","pos":"VER"} ,
		{"word":"montrons","word_nosc":"montrons","lemma":"montrer","pos":"VER"} ,
		{"word":"montrâmes","word_nosc":"montrames","lemma":"montrer","pos":"VER"} ,
		{"word":"montrât","word_nosc":"montrat","lemma":"montrer","pos":"VER"} ,
		{"word":"montrèrent","word_nosc":"montrerent","lemma":"montrer","pos":"VER"} ,
		{"word":"montré","word_nosc":"montre","lemma":"montrer","pos":"VER"} ,
		{"word":"montrée","word_nosc":"montree","lemma":"montrer","pos":"VER"} ,
		{"word":"montrées","word_nosc":"montrees","lemma":"montrer","pos":"VER"} ,
		{"word":"montrés","word_nosc":"montres","lemma":"montrer","pos":"VER"} ,
		{"word":"montâmes","word_nosc":"montames","lemma":"monter","pos":"VER"} ,
		{"word":"montât","word_nosc":"montat","lemma":"monter","pos":"VER"} ,
		{"word":"montèrent","word_nosc":"monterent","lemma":"monter","pos":"VER"} ,
		{"word":"monté","word_nosc":"monte","lemma":"monter","pos":"VER"} ,
		{"word":"montée","word_nosc":"montee","lemma":"monter","pos":"VER"} ,
		{"word":"montées","word_nosc":"montees","lemma":"monter","pos":"VER"} ,
		{"word":"montés","word_nosc":"montes","lemma":"monter","pos":"VER"} ,
		{"word":"moqua","word_nosc":"moqua","lemma":"moquer","pos":"VER"} ,
		{"word":"moquai","word_nosc":"moquai","lemma":"moquer","pos":"VER"} ,
		{"word":"moquaient","word_nosc":"moquaient","lemma":"moquer","pos":"VER"} ,
		{"word":"moquais","word_nosc":"moquais","lemma":"moquer","pos":"VER"} ,
		{"word":"moquait","word_nosc":"moquait","lemma":"moquer","pos":"VER"} ,
		{"word":"moquant","word_nosc":"moquant","lemma":"moquer","pos":"VER"} ,
		{"word":"moque","word_nosc":"moque","lemma":"moquer","pos":"VER"} ,
		{"word":"moquent","word_nosc":"moquent","lemma":"moquer","pos":"VER"} ,
		{"word":"moquer","word_nosc":"moquer","lemma":"moquer","pos":"VER"} ,
		{"word":"moquera","word_nosc":"moquera","lemma":"moquer","pos":"VER"} ,
		{"word":"moquerai","word_nosc":"moquerai","lemma":"moquer","pos":"VER"} ,
		{"word":"moqueraient","word_nosc":"moqueraient","lemma":"moquer","pos":"VER"} ,
		{"word":"moquerais","word_nosc":"moquerais","lemma":"moquer","pos":"VER"} ,
		{"word":"moquerait","word_nosc":"moquerait","lemma":"moquer","pos":"VER"} ,
		{"word":"moqueras","word_nosc":"moqueras","lemma":"moquer","pos":"VER"} ,
		{"word":"moquerez","word_nosc":"moquerez","lemma":"moquer","pos":"VER"} ,
		{"word":"moqueriez","word_nosc":"moqueriez","lemma":"moquer","pos":"VER"} ,
		{"word":"moqueront","word_nosc":"moqueront","lemma":"moquer","pos":"VER"} ,
		{"word":"moques","word_nosc":"moques","lemma":"moquer","pos":"VER"} ,
		{"word":"moquetté","word_nosc":"moquette","lemma":"moquetter","pos":"VER"} ,
		{"word":"moquettées","word_nosc":"moquettees","lemma":"moquetter","pos":"VER"} ,
		{"word":"moquettés","word_nosc":"moquettes","lemma":"moquetter","pos":"VER"} ,
		{"word":"moquez","word_nosc":"moquez","lemma":"moquer","pos":"VER"} ,
		{"word":"moquiez","word_nosc":"moquiez","lemma":"moquer","pos":"VER"} ,
		{"word":"moquions","word_nosc":"moquions","lemma":"moquer","pos":"VER"} ,
		{"word":"moquons","word_nosc":"moquons","lemma":"moquer","pos":"VER"} ,
		{"word":"moquât","word_nosc":"moquat","lemma":"moquer","pos":"VER"} ,
		{"word":"moquèrent","word_nosc":"moquerent","lemma":"moquer","pos":"VER"} ,
		{"word":"moqué","word_nosc":"moque","lemma":"moquer","pos":"VER"} ,
		{"word":"moquée","word_nosc":"moquee","lemma":"moquer","pos":"VER"} ,
		{"word":"moquées","word_nosc":"moquees","lemma":"moquer","pos":"VER"} ,
		{"word":"moqués","word_nosc":"moques","lemma":"moquer","pos":"VER"} ,
		{"word":"moralise","word_nosc":"moralise","lemma":"moraliser","pos":"VER"} ,
		{"word":"moraliser","word_nosc":"moraliser","lemma":"moraliser","pos":"VER"} ,
		{"word":"moralisée","word_nosc":"moralisee","lemma":"moraliser","pos":"VER"} ,
		{"word":"morcela","word_nosc":"morcela","lemma":"morceler","pos":"VER"} ,
		{"word":"morcelait","word_nosc":"morcelait","lemma":"morceler","pos":"VER"} ,
		{"word":"morcelant","word_nosc":"morcelant","lemma":"morceler","pos":"VER"} ,
		{"word":"morceler","word_nosc":"morceler","lemma":"morceler","pos":"VER"} ,
		{"word":"morcellent","word_nosc":"morcellent","lemma":"morceler","pos":"VER"} ,
		{"word":"morcellera","word_nosc":"morcellera","lemma":"morceler","pos":"VER"} ,
		{"word":"morcelé","word_nosc":"morcele","lemma":"morceler","pos":"VER"} ,
		{"word":"morcelée","word_nosc":"morcelee","lemma":"morceler","pos":"VER"} ,
		{"word":"morcelées","word_nosc":"morcelees","lemma":"morceler","pos":"VER"} ,
		{"word":"morcelés","word_nosc":"morceles","lemma":"morceler","pos":"VER"} ,
		{"word":"mord","word_nosc":"mord","lemma":"mordre","pos":"VER"} ,
		{"word":"mordaient","word_nosc":"mordaient","lemma":"mordre","pos":"VER"} ,
		{"word":"mordais","word_nosc":"mordais","lemma":"mordre","pos":"VER"} ,
		{"word":"mordait","word_nosc":"mordait","lemma":"mordre","pos":"VER"} ,
		{"word":"mordant","word_nosc":"mordant","lemma":"mordre","pos":"VER"} ,
		{"word":"morde","word_nosc":"morde","lemma":"mordre","pos":"VER"} ,
		{"word":"mordent","word_nosc":"mordent","lemma":"mordre","pos":"VER"} ,
		{"word":"mordes","word_nosc":"mordes","lemma":"mordre","pos":"VER"} ,
		{"word":"mordez","word_nosc":"mordez","lemma":"mordre","pos":"VER"} ,
		{"word":"mordilla","word_nosc":"mordilla","lemma":"mordiller","pos":"VER"} ,
		{"word":"mordillaient","word_nosc":"mordillaient","lemma":"mordiller","pos":"VER"} ,
		{"word":"mordillais","word_nosc":"mordillais","lemma":"mordiller","pos":"VER"} ,
		{"word":"mordillait","word_nosc":"mordillait","lemma":"mordiller","pos":"VER"} ,
		{"word":"mordillant","word_nosc":"mordillant","lemma":"mordiller","pos":"VER"} ,
		{"word":"mordille","word_nosc":"mordille","lemma":"mordiller","pos":"VER"} ,
		{"word":"mordillent","word_nosc":"mordillent","lemma":"mordiller","pos":"VER"} ,
		{"word":"mordiller","word_nosc":"mordiller","lemma":"mordiller","pos":"VER"} ,
		{"word":"mordillera","word_nosc":"mordillera","lemma":"mordiller","pos":"VER"} ,
		{"word":"mordillez","word_nosc":"mordillez","lemma":"mordiller","pos":"VER"} ,
		{"word":"mordillé","word_nosc":"mordille","lemma":"mordiller","pos":"VER"} ,
		{"word":"mordillée","word_nosc":"mordillee","lemma":"mordiller","pos":"VER"} ,
		{"word":"mordillées","word_nosc":"mordillees","lemma":"mordiller","pos":"VER"} ,
		{"word":"mordillés","word_nosc":"mordilles","lemma":"mordiller","pos":"VER"} ,
		{"word":"mordions","word_nosc":"mordions","lemma":"mordre","pos":"VER"} ,
		{"word":"mordirent","word_nosc":"mordirent","lemma":"mordre","pos":"VER"} ,
		{"word":"mordis","word_nosc":"mordis","lemma":"mordre","pos":"VER"} ,
		{"word":"mordissent","word_nosc":"mordissent","lemma":"mordre","pos":"VER"} ,
		{"word":"mordit","word_nosc":"mordit","lemma":"mordre","pos":"VER"} ,
		{"word":"mordorait","word_nosc":"mordorait","lemma":"mordorer","pos":"VER"} ,
		{"word":"mordorée","word_nosc":"mordoree","lemma":"mordorer","pos":"VER"} ,
		{"word":"mordorées","word_nosc":"mordorees","lemma":"mordorer","pos":"VER"} ,
		{"word":"mordorés","word_nosc":"mordores","lemma":"mordorer","pos":"VER"} ,
		{"word":"mordra","word_nosc":"mordra","lemma":"mordre","pos":"VER"} ,
		{"word":"mordrai","word_nosc":"mordrai","lemma":"mordre","pos":"VER"} ,
		{"word":"mordraient","word_nosc":"mordraient","lemma":"mordre","pos":"VER"} ,
		{"word":"mordrais","word_nosc":"mordrais","lemma":"mordre","pos":"VER"} ,
		{"word":"mordrait","word_nosc":"mordrait","lemma":"mordre","pos":"VER"} ,
		{"word":"mordras","word_nosc":"mordras","lemma":"mordre","pos":"VER"} ,
		{"word":"mordre","word_nosc":"mordre","lemma":"mordre","pos":"VER"} ,
		{"word":"mordrez","word_nosc":"mordrez","lemma":"mordre","pos":"VER"} ,
		{"word":"mordront","word_nosc":"mordront","lemma":"mordre","pos":"VER"} ,
		{"word":"mords","word_nosc":"mords","lemma":"mordre","pos":"VER"} ,
		{"word":"mordu","word_nosc":"mordu","lemma":"mordre","pos":"VER"} ,
		{"word":"mordue","word_nosc":"mordue","lemma":"mordre","pos":"VER"} ,
		{"word":"mordues","word_nosc":"mordues","lemma":"mordre","pos":"VER"} ,
		{"word":"mordus","word_nosc":"mordus","lemma":"mordre","pos":"VER"} ,
		{"word":"mordît","word_nosc":"mordit","lemma":"mordre","pos":"VER"} ,
		{"word":"morfale","word_nosc":"morfale","lemma":"morfaler","pos":"VER"} ,
		{"word":"morfaler","word_nosc":"morfaler","lemma":"morfaler","pos":"VER"} ,
		{"word":"morfalé","word_nosc":"morfale","lemma":"morfaler","pos":"VER"} ,
		{"word":"morfalée","word_nosc":"morfalee","lemma":"morfaler","pos":"VER"} ,
		{"word":"morfalés","word_nosc":"morfales","lemma":"morfaler","pos":"VER"} ,
		{"word":"morflais","word_nosc":"morflais","lemma":"morfler","pos":"VER"} ,
		{"word":"morflait","word_nosc":"morflait","lemma":"morfler","pos":"VER"} ,
		{"word":"morfle","word_nosc":"morfle","lemma":"morfler","pos":"VER"} ,
		{"word":"morfler","word_nosc":"morfler","lemma":"morfler","pos":"VER"} ,
		{"word":"morflera","word_nosc":"morflera","lemma":"morfler","pos":"VER"} ,
		{"word":"morfleras","word_nosc":"morfleras","lemma":"morfler","pos":"VER"} ,
		{"word":"morflerez","word_nosc":"morflerez","lemma":"morfler","pos":"VER"} ,
		{"word":"morfles","word_nosc":"morfles","lemma":"morfler","pos":"VER"} ,
		{"word":"morflé","word_nosc":"morfle","lemma":"morfler","pos":"VER"} ,
		{"word":"morflée","word_nosc":"morflee","lemma":"morfler","pos":"VER"} ,
		{"word":"morfond","word_nosc":"morfond","lemma":"morfondre","pos":"VER"} ,
		{"word":"morfondaient","word_nosc":"morfondaient","lemma":"morfondre","pos":"VER"} ,
		{"word":"morfondais","word_nosc":"morfondais","lemma":"morfondre","pos":"VER"} ,
		{"word":"morfondait","word_nosc":"morfondait","lemma":"morfondre","pos":"VER"} ,
		{"word":"morfondant","word_nosc":"morfondant","lemma":"morfondre","pos":"VER"} ,
		{"word":"morfonde","word_nosc":"morfonde","lemma":"morfondre","pos":"VER"} ,
		{"word":"morfondis","word_nosc":"morfondis","lemma":"morfondre","pos":"VER"} ,
		{"word":"morfondra","word_nosc":"morfondra","lemma":"morfondre","pos":"VER"} ,
		{"word":"morfondre","word_nosc":"morfondre","lemma":"morfondre","pos":"VER"} ,
		{"word":"morfonds","word_nosc":"morfonds","lemma":"morfondre","pos":"VER"} ,
		{"word":"morfondu","word_nosc":"morfondu","lemma":"morfondre","pos":"VER"} ,
		{"word":"morgana","word_nosc":"morgana","lemma":"morganer","pos":"VER"} ,
		{"word":"morgane","word_nosc":"morgane","lemma":"morganer","pos":"VER"} ,
		{"word":"morganer","word_nosc":"morganer","lemma":"morganer","pos":"VER"} ,
		{"word":"morganerai","word_nosc":"morganerai","lemma":"morganer","pos":"VER"} ,
		{"word":"morigène","word_nosc":"morigene","lemma":"morigéner","pos":"VER"} ,
		{"word":"morigéna","word_nosc":"morigena","lemma":"morigéner","pos":"VER"} ,
		{"word":"morigénai","word_nosc":"morigenai","lemma":"morigéner","pos":"VER"} ,
		{"word":"morigénais","word_nosc":"morigenais","lemma":"morigéner","pos":"VER"} ,
		{"word":"morigénait","word_nosc":"morigenait","lemma":"morigéner","pos":"VER"} ,
		{"word":"morigénant","word_nosc":"morigenant","lemma":"morigéner","pos":"VER"} ,
		{"word":"morigéner","word_nosc":"morigener","lemma":"morigéner","pos":"VER"} ,
		{"word":"morigéné","word_nosc":"morigene","lemma":"morigéner","pos":"VER"} ,
		{"word":"morigénée","word_nosc":"morigenee","lemma":"morigéner","pos":"VER"} ,
		{"word":"mort","word_nosc":"mort","lemma":"mourir","pos":"VER"} ,
		{"word":"mortaiser","word_nosc":"mortaiser","lemma":"mortaiser","pos":"VER"} ,
		{"word":"morte","word_nosc":"morte","lemma":"mourir","pos":"VER"} ,
		{"word":"mortes","word_nosc":"mortes","lemma":"mourir","pos":"VER"} ,
		{"word":"mortifia","word_nosc":"mortifia","lemma":"mortifier","pos":"VER"} ,
		{"word":"mortifiai","word_nosc":"mortifiai","lemma":"mortifier","pos":"VER"} ,
		{"word":"mortifiaient","word_nosc":"mortifiaient","lemma":"mortifier","pos":"VER"} ,
		{"word":"mortifiait","word_nosc":"mortifiait","lemma":"mortifier","pos":"VER"} ,
		{"word":"mortifiant","word_nosc":"mortifiant","lemma":"mortifier","pos":"VER"} ,
		{"word":"mortifie","word_nosc":"mortifie","lemma":"mortifier","pos":"VER"} ,
		{"word":"mortifier","word_nosc":"mortifier","lemma":"mortifier","pos":"VER"} ,
		{"word":"mortifié","word_nosc":"mortifie","lemma":"mortifier","pos":"VER"} ,
		{"word":"mortifiée","word_nosc":"mortifiee","lemma":"mortifier","pos":"VER"} ,
		{"word":"mortifiés","word_nosc":"mortifies","lemma":"mortifier","pos":"VER"} ,
		{"word":"morts","word_nosc":"morts","lemma":"mourir","pos":"VER"} ,
		{"word":"motiva","word_nosc":"motiva","lemma":"motiver","pos":"VER"} ,
		{"word":"motivai","word_nosc":"motivai","lemma":"motiver","pos":"VER"} ,
		{"word":"motivaient","word_nosc":"motivaient","lemma":"motiver","pos":"VER"} ,
		{"word":"motivais","word_nosc":"motivais","lemma":"motiver","pos":"VER"} ,
		{"word":"motivait","word_nosc":"motivait","lemma":"motiver","pos":"VER"} ,
		{"word":"motivant","word_nosc":"motivant","lemma":"motiver","pos":"VER"} ,
		{"word":"motive","word_nosc":"motive","lemma":"motiver","pos":"VER"} ,
		{"word":"motivent","word_nosc":"motivent","lemma":"motiver","pos":"VER"} ,
		{"word":"motiver","word_nosc":"motiver","lemma":"motiver","pos":"VER"} ,
		{"word":"motivera","word_nosc":"motivera","lemma":"motiver","pos":"VER"} ,
		{"word":"motivez","word_nosc":"motivez","lemma":"motiver","pos":"VER"} ,
		{"word":"motivé","word_nosc":"motive","lemma":"motiver","pos":"VER"} ,
		{"word":"motivée","word_nosc":"motivee","lemma":"motiver","pos":"VER"} ,
		{"word":"motivées","word_nosc":"motivees","lemma":"motiver","pos":"VER"} ,
		{"word":"motivés","word_nosc":"motives","lemma":"motiver","pos":"VER"} ,
		{"word":"motoriser","word_nosc":"motoriser","lemma":"motoriser","pos":"VER"} ,
		{"word":"motorisé","word_nosc":"motorise","lemma":"motoriser","pos":"VER"} ,
		{"word":"motorisée","word_nosc":"motorisee","lemma":"motoriser","pos":"VER"} ,
		{"word":"motorisées","word_nosc":"motorisees","lemma":"motoriser","pos":"VER"} ,
		{"word":"motorisés","word_nosc":"motorises","lemma":"motoriser","pos":"VER"} ,
		{"word":"moucha","word_nosc":"moucha","lemma":"moucher","pos":"VER"} ,
		{"word":"mouchai","word_nosc":"mouchai","lemma":"moucher","pos":"VER"} ,
		{"word":"mouchaient","word_nosc":"mouchaient","lemma":"moucher","pos":"VER"} ,
		{"word":"mouchait","word_nosc":"mouchait","lemma":"moucher","pos":"VER"} ,
		{"word":"mouchant","word_nosc":"mouchant","lemma":"moucher","pos":"VER"} ,
		{"word":"moucharda","word_nosc":"moucharda","lemma":"moucharder","pos":"VER"} ,
		{"word":"mouchardait","word_nosc":"mouchardait","lemma":"moucharder","pos":"VER"} ,
		{"word":"moucharde","word_nosc":"moucharde","lemma":"moucharder","pos":"VER"} ,
		{"word":"mouchardent","word_nosc":"mouchardent","lemma":"moucharder","pos":"VER"} ,
		{"word":"moucharder","word_nosc":"moucharder","lemma":"moucharder","pos":"VER"} ,
		{"word":"mouchardera","word_nosc":"mouchardera","lemma":"moucharder","pos":"VER"} ,
		{"word":"moucharderait","word_nosc":"moucharderait","lemma":"moucharder","pos":"VER"} ,
		{"word":"mouchardes","word_nosc":"mouchardes","lemma":"moucharder","pos":"VER"} ,
		{"word":"mouchardé","word_nosc":"moucharde","lemma":"moucharder","pos":"VER"} ,
		{"word":"mouche","word_nosc":"mouche","lemma":"moucher","pos":"VER"} ,
		{"word":"mouchent","word_nosc":"mouchent","lemma":"moucher","pos":"VER"} ,
		{"word":"moucher","word_nosc":"moucher","lemma":"moucher","pos":"VER"} ,
		{"word":"mouches","word_nosc":"mouches","lemma":"moucher","pos":"VER"} ,
		{"word":"mouchetait","word_nosc":"mouchetait","lemma":"moucheter","pos":"VER"} ,
		{"word":"mouchetant","word_nosc":"mouchetant","lemma":"moucheter","pos":"VER"} ,
		{"word":"mouchette","word_nosc":"mouchette","lemma":"moucheter","pos":"VER"} ,
		{"word":"moucheté","word_nosc":"mouchete","lemma":"moucheter","pos":"VER"} ,
		{"word":"mouchetée","word_nosc":"mouchetee","lemma":"moucheter","pos":"VER"} ,
		{"word":"mouchetées","word_nosc":"mouchetees","lemma":"moucheter","pos":"VER"} ,
		{"word":"mouchetés","word_nosc":"mouchetes","lemma":"moucheter","pos":"VER"} ,
		{"word":"mouchez","word_nosc":"mouchez","lemma":"moucher","pos":"VER"} ,
		{"word":"mouchons","word_nosc":"mouchons","lemma":"moucher","pos":"VER"} ,
		{"word":"mouché","word_nosc":"mouche","lemma":"moucher","pos":"VER"} ,
		{"word":"mouchée","word_nosc":"mouchee","lemma":"moucher","pos":"VER"} ,
		{"word":"mouchés","word_nosc":"mouches","lemma":"moucher","pos":"VER"} ,
		{"word":"moud","word_nosc":"moud","lemma":"moudre","pos":"VER"} ,
		{"word":"moudrai","word_nosc":"moudrai","lemma":"moudre","pos":"VER"} ,
		{"word":"moudre","word_nosc":"moudre","lemma":"moudre","pos":"VER"} ,
		{"word":"mouds","word_nosc":"mouds","lemma":"moudre","pos":"VER"} ,
		{"word":"moufetait","word_nosc":"moufetait","lemma":"moufeter","pos":"VER"} ,
		{"word":"moufeté","word_nosc":"moufete","lemma":"moufeter","pos":"VER"} ,
		{"word":"mouftaient","word_nosc":"mouftaient","lemma":"moufter","pos":"VER"} ,
		{"word":"mouftais","word_nosc":"mouftais","lemma":"moufter","pos":"VER"} ,
		{"word":"mouftait","word_nosc":"mouftait","lemma":"moufter","pos":"VER"} ,
		{"word":"moufte","word_nosc":"moufte","lemma":"moufter","pos":"VER"} ,
		{"word":"mouftent","word_nosc":"mouftent","lemma":"moufter","pos":"VER"} ,
		{"word":"moufter","word_nosc":"moufter","lemma":"moufter","pos":"VER"} ,
		{"word":"mouftera","word_nosc":"mouftera","lemma":"moufter","pos":"VER"} ,
		{"word":"mouftes","word_nosc":"mouftes","lemma":"moufter","pos":"VER"} ,
		{"word":"mouftez","word_nosc":"mouftez","lemma":"moufter","pos":"VER"} ,
		{"word":"moufté","word_nosc":"moufte","lemma":"moufter","pos":"VER"} ,
		{"word":"mouilla","word_nosc":"mouilla","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillaient","word_nosc":"mouillaient","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillais","word_nosc":"mouillais","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillait","word_nosc":"mouillait","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillant","word_nosc":"mouillant","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillasse","word_nosc":"mouillasse","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouille","word_nosc":"mouille","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillent","word_nosc":"mouillent","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouiller","word_nosc":"mouiller","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillera","word_nosc":"mouillera","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillerai","word_nosc":"mouillerai","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillerais","word_nosc":"mouillerais","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillerait","word_nosc":"mouillerait","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouilleras","word_nosc":"mouilleras","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouilleront","word_nosc":"mouilleront","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouilles","word_nosc":"mouilles","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillez","word_nosc":"mouillez","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillons","word_nosc":"mouillons","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillèrent","word_nosc":"mouillerent","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillé","word_nosc":"mouille","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillée","word_nosc":"mouillee","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillées","word_nosc":"mouillees","lemma":"mouiller","pos":"VER"} ,
		{"word":"mouillés","word_nosc":"mouilles","lemma":"mouiller","pos":"VER"} ,
		{"word":"moula","word_nosc":"moula","lemma":"mouler","pos":"VER"} ,
		{"word":"moulaient","word_nosc":"moulaient","lemma":"mouler","pos":"VER"} ,
		{"word":"moulait","word_nosc":"moulait","lemma":"mouler","pos":"VER"} ,
		{"word":"moulant","word_nosc":"moulant","lemma":"mouler","pos":"VER"} ,
		{"word":"moule","word_nosc":"moule","lemma":"mouler","pos":"VER"} ,
		{"word":"moulent","word_nosc":"moulent","lemma":"mouler","pos":"VER"} ,
		{"word":"mouler","word_nosc":"mouler","lemma":"mouler","pos":"VER"} ,
		{"word":"mouleraient","word_nosc":"mouleraient","lemma":"mouler","pos":"VER"} ,
		{"word":"moulerait","word_nosc":"moulerait","lemma":"mouler","pos":"VER"} ,
		{"word":"moules","word_nosc":"moules","lemma":"mouler","pos":"VER"} ,
		{"word":"moulez","word_nosc":"moulez","lemma":"moudre","pos":"VER"} ,
		{"word":"moulez","word_nosc":"moulez","lemma":"mouler","pos":"VER"} ,
		{"word":"moulinait","word_nosc":"moulinait","lemma":"mouliner","pos":"VER"} ,
		{"word":"mouline","word_nosc":"mouline","lemma":"mouliner","pos":"VER"} ,
		{"word":"moulinent","word_nosc":"moulinent","lemma":"mouliner","pos":"VER"} ,
		{"word":"mouliner","word_nosc":"mouliner","lemma":"mouliner","pos":"VER"} ,
		{"word":"moulines","word_nosc":"moulines","lemma":"mouliner","pos":"VER"} ,
		{"word":"moulinez","word_nosc":"moulinez","lemma":"mouliner","pos":"VER"} ,
		{"word":"moulu","word_nosc":"moulu","lemma":"moudre","pos":"VER"} ,
		{"word":"moulue","word_nosc":"moulue","lemma":"moudre","pos":"VER"} ,
		{"word":"moulues","word_nosc":"moulues","lemma":"moudre","pos":"VER"} ,
		{"word":"mouluré","word_nosc":"moulure","lemma":"moulurer","pos":"VER"} ,
		{"word":"moulus","word_nosc":"moulus","lemma":"moudre","pos":"VER"} ,
		{"word":"moulut","word_nosc":"moulut","lemma":"moudre","pos":"VER"} ,
		{"word":"moulé","word_nosc":"moule","lemma":"mouler","pos":"VER"} ,
		{"word":"moulée","word_nosc":"moulee","lemma":"mouler","pos":"VER"} ,
		{"word":"moulées","word_nosc":"moulees","lemma":"mouler","pos":"VER"} ,
		{"word":"moulés","word_nosc":"moules","lemma":"mouler","pos":"VER"} ,
		{"word":"mouraient","word_nosc":"mouraient","lemma":"mourir","pos":"VER"} ,
		{"word":"mourais","word_nosc":"mourais","lemma":"mourir","pos":"VER"} ,
		{"word":"mourait","word_nosc":"mourait","lemma":"mourir","pos":"VER"} ,
		{"word":"mourant","word_nosc":"mourant","lemma":"mourir","pos":"VER"} ,
		{"word":"mourez","word_nosc":"mourez","lemma":"mourir","pos":"VER"} ,
		{"word":"mouriez","word_nosc":"mouriez","lemma":"mourir","pos":"VER"} ,
		{"word":"mourions","word_nosc":"mourions","lemma":"mourir","pos":"VER"} ,
		{"word":"mourir","word_nosc":"mourir","lemma":"mourir","pos":"VER"} ,
		{"word":"mouronne","word_nosc":"mouronne","lemma":"mouronner","pos":"VER"} ,
		{"word":"mouronner","word_nosc":"mouronner","lemma":"mouronner","pos":"VER"} ,
		{"word":"mourons","word_nosc":"mourons","lemma":"mourir","pos":"VER"} ,
		{"word":"mourra","word_nosc":"mourra","lemma":"mourir","pos":"VER"} ,
		{"word":"mourrai","word_nosc":"mourrai","lemma":"mourir","pos":"VER"} ,
		{"word":"mourraient","word_nosc":"mourraient","lemma":"mourir","pos":"VER"} ,
		{"word":"mourrais","word_nosc":"mourrais","lemma":"mourir","pos":"VER"} ,
		{"word":"mourrait","word_nosc":"mourrait","lemma":"mourir","pos":"VER"} ,
		{"word":"mourras","word_nosc":"mourras","lemma":"mourir","pos":"VER"} ,
		{"word":"mourrez","word_nosc":"mourrez","lemma":"mourir","pos":"VER"} ,
		{"word":"mourriez","word_nosc":"mourriez","lemma":"mourir","pos":"VER"} ,
		{"word":"mourrions","word_nosc":"mourrions","lemma":"mourir","pos":"VER"} ,
		{"word":"mourrons","word_nosc":"mourrons","lemma":"mourir","pos":"VER"} ,
		{"word":"mourront","word_nosc":"mourront","lemma":"mourir","pos":"VER"} ,
		{"word":"moururent","word_nosc":"moururent","lemma":"mourir","pos":"VER"} ,
		{"word":"mourus","word_nosc":"mourus","lemma":"mourir","pos":"VER"} ,
		{"word":"mourussent","word_nosc":"mourussent","lemma":"mourir","pos":"VER"} ,
		{"word":"mourut","word_nosc":"mourut","lemma":"mourir","pos":"VER"} ,
		{"word":"mourût","word_nosc":"mourut","lemma":"mourir","pos":"VER"} ,
		{"word":"mourûtes","word_nosc":"mourutes","lemma":"mourir","pos":"VER"} ,
		{"word":"moussaient","word_nosc":"moussaient","lemma":"mousser","pos":"VER"} ,
		{"word":"moussait","word_nosc":"moussait","lemma":"mousser","pos":"VER"} ,
		{"word":"moussant","word_nosc":"moussant","lemma":"mousser","pos":"VER"} ,
		{"word":"mousse","word_nosc":"mousse","lemma":"mousser","pos":"VER"} ,
		{"word":"moussent","word_nosc":"moussent","lemma":"mousser","pos":"VER"} ,
		{"word":"mousser","word_nosc":"mousser","lemma":"mousser","pos":"VER"} ,
		{"word":"moutardé","word_nosc":"moutarde","lemma":"moutarder","pos":"VER"} ,
		{"word":"moutonnaient","word_nosc":"moutonnaient","lemma":"moutonner","pos":"VER"} ,
		{"word":"moutonnait","word_nosc":"moutonnait","lemma":"moutonner","pos":"VER"} ,
		{"word":"moutonnant","word_nosc":"moutonnant","lemma":"moutonner","pos":"VER"} ,
		{"word":"moutonne","word_nosc":"moutonne","lemma":"moutonner","pos":"VER"} ,
		{"word":"moutonnent","word_nosc":"moutonnent","lemma":"moutonner","pos":"VER"} ,
		{"word":"moutonner","word_nosc":"moutonner","lemma":"moutonner","pos":"VER"} ,
		{"word":"mouvaient","word_nosc":"mouvaient","lemma":"mouvoir","pos":"VER"} ,
		{"word":"mouvais","word_nosc":"mouvais","lemma":"mouvoir","pos":"VER"} ,
		{"word":"mouvait","word_nosc":"mouvait","lemma":"mouvoir","pos":"VER"} ,
		{"word":"mouvant","word_nosc":"mouvant","lemma":"mouvoir","pos":"VER"} ,
		{"word":"mouvementent","word_nosc":"mouvementent","lemma":"mouvementer","pos":"VER"} ,
		{"word":"mouvementé","word_nosc":"mouvemente","lemma":"mouvementer","pos":"VER"} ,
		{"word":"mouvementée","word_nosc":"mouvementee","lemma":"mouvementer","pos":"VER"} ,
		{"word":"mouvementées","word_nosc":"mouvementees","lemma":"mouvementer","pos":"VER"} ,
		{"word":"mouvementés","word_nosc":"mouvementes","lemma":"mouvementer","pos":"VER"} ,
		{"word":"mouvoir","word_nosc":"mouvoir","lemma":"mouvoir","pos":"VER"} ,
		{"word":"moyennant","word_nosc":"moyennant","lemma":"moyenner","pos":"VER"} ,
		{"word":"moyenne","word_nosc":"moyenne","lemma":"moyenner","pos":"VER"} ,
		{"word":"moyenner","word_nosc":"moyenner","lemma":"moyenner","pos":"VER"} ,
		{"word":"moyer","word_nosc":"moyer","lemma":"moyer","pos":"VER"} ,
		{"word":"mua","word_nosc":"mua","lemma":"muer","pos":"VER"} ,
		{"word":"muai","word_nosc":"muai","lemma":"muer","pos":"VER"} ,
		{"word":"muaient","word_nosc":"muaient","lemma":"muer","pos":"VER"} ,
		{"word":"muait","word_nosc":"muait","lemma":"muer","pos":"VER"} ,
		{"word":"muant","word_nosc":"muant","lemma":"muer","pos":"VER"} ,
		{"word":"muchas","word_nosc":"muchas","lemma":"mucher","pos":"VER"} ,
		{"word":"muche","word_nosc":"muche","lemma":"mucher","pos":"VER"} ,
		{"word":"mue","word_nosc":"mue","lemma":"muer","pos":"VER"} ,
		{"word":"muent","word_nosc":"muent","lemma":"muer","pos":"VER"} ,
		{"word":"muer","word_nosc":"muer","lemma":"muer","pos":"VER"} ,
		{"word":"muera","word_nosc":"muera","lemma":"muer","pos":"VER"} ,
		{"word":"mueront","word_nosc":"mueront","lemma":"muer","pos":"VER"} ,
		{"word":"mues","word_nosc":"mues","lemma":"muer","pos":"VER"} ,
		{"word":"mugi","word_nosc":"mugi","lemma":"mugir","pos":"VER"} ,
		{"word":"mugir","word_nosc":"mugir","lemma":"mugir","pos":"VER"} ,
		{"word":"mugis","word_nosc":"mugis","lemma":"mugir","pos":"VER"} ,
		{"word":"mugissaient","word_nosc":"mugissaient","lemma":"mugir","pos":"VER"} ,
		{"word":"mugissait","word_nosc":"mugissait","lemma":"mugir","pos":"VER"} ,
		{"word":"mugissant","word_nosc":"mugissant","lemma":"mugir","pos":"VER"} ,
		{"word":"mugissent","word_nosc":"mugissent","lemma":"mugir","pos":"VER"} ,
		{"word":"mugit","word_nosc":"mugit","lemma":"mugir","pos":"VER"} ,
		{"word":"muguette","word_nosc":"muguette","lemma":"mugueter","pos":"VER"} ,
		{"word":"multiplexer","word_nosc":"multiplexer","lemma":"multiplexer","pos":"VER"} ,
		{"word":"multiplexé","word_nosc":"multiplexe","lemma":"multiplexer","pos":"VER"} ,
		{"word":"multiplia","word_nosc":"multiplia","lemma":"multiplier","pos":"VER"} ,
		{"word":"multipliai","word_nosc":"multipliai","lemma":"multiplier","pos":"VER"} ,
		{"word":"multipliaient","word_nosc":"multipliaient","lemma":"multiplier","pos":"VER"} ,
		{"word":"multipliais","word_nosc":"multipliais","lemma":"multiplier","pos":"VER"} ,
		{"word":"multipliait","word_nosc":"multipliait","lemma":"multiplier","pos":"VER"} ,
		{"word":"multipliant","word_nosc":"multipliant","lemma":"multiplier","pos":"VER"} ,
		{"word":"multiplie","word_nosc":"multiplie","lemma":"multiplier","pos":"VER"} ,
		{"word":"multiplient","word_nosc":"multiplient","lemma":"multiplier","pos":"VER"} ,
		{"word":"multiplier","word_nosc":"multiplier","lemma":"multiplier","pos":"VER"} ,
		{"word":"multiplierai","word_nosc":"multiplierai","lemma":"multiplier","pos":"VER"} ,
		{"word":"multiplieraient","word_nosc":"multiplieraient","lemma":"multiplier","pos":"VER"} ,
		{"word":"multiplierait","word_nosc":"multiplierait","lemma":"multiplier","pos":"VER"} ,
		{"word":"multiplierons","word_nosc":"multiplierons","lemma":"multiplier","pos":"VER"} ,
		{"word":"multiplieront","word_nosc":"multiplieront","lemma":"multiplier","pos":"VER"} ,
		{"word":"multiplies","word_nosc":"multiplies","lemma":"multiplier","pos":"VER"} ,
		{"word":"multipliez","word_nosc":"multipliez","lemma":"multiplier","pos":"VER"} ,
		{"word":"multipliions","word_nosc":"multipliions","lemma":"multiplier","pos":"VER"} ,
		{"word":"multipliât","word_nosc":"multipliat","lemma":"multiplier","pos":"VER"} ,
		{"word":"multiplièrent","word_nosc":"multiplierent","lemma":"multiplier","pos":"VER"} ,
		{"word":"multiplié","word_nosc":"multiplie","lemma":"multiplier","pos":"VER"} ,
		{"word":"multipliée","word_nosc":"multipliee","lemma":"multiplier","pos":"VER"} ,
		{"word":"multipliées","word_nosc":"multipliees","lemma":"multiplier","pos":"VER"} ,
		{"word":"multipliés","word_nosc":"multiplies","lemma":"multiplier","pos":"VER"} ,
		{"word":"muni","word_nosc":"muni","lemma":"munir","pos":"VER"} ,
		{"word":"munie","word_nosc":"munie","lemma":"munir","pos":"VER"} ,
		{"word":"munies","word_nosc":"munies","lemma":"munir","pos":"VER"} ,
		{"word":"munir","word_nosc":"munir","lemma":"munir","pos":"VER"} ,
		{"word":"munis","word_nosc":"munis","lemma":"munir","pos":"VER"} ,
		{"word":"munissaient","word_nosc":"munissaient","lemma":"munir","pos":"VER"} ,
		{"word":"munissait","word_nosc":"munissait","lemma":"munir","pos":"VER"} ,
		{"word":"munissant","word_nosc":"munissant","lemma":"munir","pos":"VER"} ,
		{"word":"munissent","word_nosc":"munissent","lemma":"munir","pos":"VER"} ,
		{"word":"munit","word_nosc":"munit","lemma":"munir","pos":"VER"} ,
		{"word":"mura","word_nosc":"mura","lemma":"murer","pos":"VER"} ,
		{"word":"muraient","word_nosc":"muraient","lemma":"murer","pos":"VER"} ,
		{"word":"murait","word_nosc":"murait","lemma":"murer","pos":"VER"} ,
		{"word":"murant","word_nosc":"murant","lemma":"murer","pos":"VER"} ,
		{"word":"mure","word_nosc":"mure","lemma":"murer","pos":"VER"} ,
		{"word":"murent","word_nosc":"murent","lemma":"mouvoir","pos":"VER"} ,
		{"word":"murer","word_nosc":"murer","lemma":"murer","pos":"VER"} ,
		{"word":"murerait","word_nosc":"murerait","lemma":"murer","pos":"VER"} ,
		{"word":"mures","word_nosc":"mures","lemma":"murer","pos":"VER"} ,
		{"word":"murez","word_nosc":"murez","lemma":"murer","pos":"VER"} ,
		{"word":"murmura","word_nosc":"murmura","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmurai","word_nosc":"murmurai","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmuraient","word_nosc":"murmuraient","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmurais","word_nosc":"murmurais","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmurait","word_nosc":"murmurait","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmurant","word_nosc":"murmurant","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmuras","word_nosc":"murmuras","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmure","word_nosc":"murmure","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmurent","word_nosc":"murmurent","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmurer","word_nosc":"murmurer","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmurera","word_nosc":"murmurera","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmureraient","word_nosc":"murmureraient","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmurerait","word_nosc":"murmurerait","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmureront","word_nosc":"murmureront","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmures","word_nosc":"murmures","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmurez","word_nosc":"murmurez","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmurions","word_nosc":"murmurions","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmurèrent","word_nosc":"murmurerent","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmuré","word_nosc":"murmure","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmurée","word_nosc":"murmuree","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmurées","word_nosc":"murmurees","lemma":"murmurer","pos":"VER"} ,
		{"word":"murmurés","word_nosc":"murmures","lemma":"murmurer","pos":"VER"} ,
		{"word":"murèrent","word_nosc":"murerent","lemma":"murer","pos":"VER"} ,
		{"word":"muré","word_nosc":"mure","lemma":"murer","pos":"VER"} ,
		{"word":"murée","word_nosc":"muree","lemma":"murer","pos":"VER"} ,
		{"word":"murées","word_nosc":"murees","lemma":"murer","pos":"VER"} ,
		{"word":"murés","word_nosc":"mures","lemma":"murer","pos":"VER"} ,
		{"word":"mus","word_nosc":"mus","lemma":"mouvoir","pos":"VER"} ,
		{"word":"musa","word_nosc":"musa","lemma":"muser","pos":"VER"} ,
		{"word":"musait","word_nosc":"musait","lemma":"muser","pos":"VER"} ,
		{"word":"musant","word_nosc":"musant","lemma":"muser","pos":"VER"} ,
		{"word":"musarda","word_nosc":"musarda","lemma":"musarder","pos":"VER"} ,
		{"word":"musardaient","word_nosc":"musardaient","lemma":"musarder","pos":"VER"} ,
		{"word":"musardait","word_nosc":"musardait","lemma":"musarder","pos":"VER"} ,
		{"word":"musardant","word_nosc":"musardant","lemma":"musarder","pos":"VER"} ,
		{"word":"musarde","word_nosc":"musarde","lemma":"musarder","pos":"VER"} ,
		{"word":"musarder","word_nosc":"musarder","lemma":"musarder","pos":"VER"} ,
		{"word":"musardez","word_nosc":"musardez","lemma":"musarder","pos":"VER"} ,
		{"word":"musclant","word_nosc":"musclant","lemma":"muscler","pos":"VER"} ,
		{"word":"muscle","word_nosc":"muscle","lemma":"muscler","pos":"VER"} ,
		{"word":"musclent","word_nosc":"musclent","lemma":"muscler","pos":"VER"} ,
		{"word":"muscler","word_nosc":"muscler","lemma":"muscler","pos":"VER"} ,
		{"word":"muscles","word_nosc":"muscles","lemma":"muscler","pos":"VER"} ,
		{"word":"musclé","word_nosc":"muscle","lemma":"muscler","pos":"VER"} ,
		{"word":"musclée","word_nosc":"musclee","lemma":"muscler","pos":"VER"} ,
		{"word":"musclées","word_nosc":"musclees","lemma":"muscler","pos":"VER"} ,
		{"word":"musclés","word_nosc":"muscles","lemma":"muscler","pos":"VER"} ,
		{"word":"muse","word_nosc":"muse","lemma":"muser","pos":"VER"} ,
		{"word":"muselait","word_nosc":"muselait","lemma":"museler","pos":"VER"} ,
		{"word":"muselant","word_nosc":"muselant","lemma":"museler","pos":"VER"} ,
		{"word":"museler","word_nosc":"museler","lemma":"museler","pos":"VER"} ,
		{"word":"muselle","word_nosc":"muselle","lemma":"museler","pos":"VER"} ,
		{"word":"musellerait","word_nosc":"musellerait","lemma":"museler","pos":"VER"} ,
		{"word":"muselons","word_nosc":"muselons","lemma":"museler","pos":"VER"} ,
		{"word":"muselé","word_nosc":"musele","lemma":"museler","pos":"VER"} ,
		{"word":"muselée","word_nosc":"muselee","lemma":"museler","pos":"VER"} ,
		{"word":"muselées","word_nosc":"muselees","lemma":"museler","pos":"VER"} ,
		{"word":"muselés","word_nosc":"museles","lemma":"museler","pos":"VER"} ,
		{"word":"muser","word_nosc":"muser","lemma":"muser","pos":"VER"} ,
		{"word":"musiquait","word_nosc":"musiquait","lemma":"musiquer","pos":"VER"} ,
		{"word":"musique","word_nosc":"musique","lemma":"musiquer","pos":"VER"} ,
		{"word":"musiquer","word_nosc":"musiquer","lemma":"musiquer","pos":"VER"} ,
		{"word":"musiques","word_nosc":"musiques","lemma":"musiquer","pos":"VER"} ,
		{"word":"musqué","word_nosc":"musque","lemma":"musquer","pos":"VER"} ,
		{"word":"musquée","word_nosc":"musquee","lemma":"musquer","pos":"VER"} ,
		{"word":"musquées","word_nosc":"musquees","lemma":"musquer","pos":"VER"} ,
		{"word":"mussais","word_nosc":"mussais","lemma":"musser","pos":"VER"} ,
		{"word":"musse","word_nosc":"musse","lemma":"musser","pos":"VER"} ,
		{"word":"mussent","word_nosc":"mussent","lemma":"musser","pos":"VER"} ,
		{"word":"mussée","word_nosc":"mussee","lemma":"musser","pos":"VER"} ,
		{"word":"mussés","word_nosc":"musses","lemma":"musser","pos":"VER"} ,
		{"word":"musée","word_nosc":"musee","lemma":"muser","pos":"VER"} ,
		{"word":"musées","word_nosc":"musees","lemma":"muser","pos":"VER"} ,
		{"word":"mutant","word_nosc":"mutant","lemma":"muter","pos":"VER"} ,
		{"word":"mute","word_nosc":"mute","lemma":"muter","pos":"VER"} ,
		{"word":"mutent","word_nosc":"mutent","lemma":"muter","pos":"VER"} ,
		{"word":"muter","word_nosc":"muter","lemma":"muter","pos":"VER"} ,
		{"word":"mutera","word_nosc":"mutera","lemma":"muter","pos":"VER"} ,
		{"word":"muterai","word_nosc":"muterai","lemma":"muter","pos":"VER"} ,
		{"word":"muteront","word_nosc":"muteront","lemma":"muter","pos":"VER"} ,
		{"word":"mutila","word_nosc":"mutila","lemma":"mutiler","pos":"VER"} ,
		{"word":"mutilais","word_nosc":"mutilais","lemma":"mutiler","pos":"VER"} ,
		{"word":"mutilait","word_nosc":"mutilait","lemma":"mutiler","pos":"VER"} ,
		{"word":"mutilant","word_nosc":"mutilant","lemma":"mutiler","pos":"VER"} ,
		{"word":"mutile","word_nosc":"mutile","lemma":"mutiler","pos":"VER"} ,
		{"word":"mutilent","word_nosc":"mutilent","lemma":"mutiler","pos":"VER"} ,
		{"word":"mutiler","word_nosc":"mutiler","lemma":"mutiler","pos":"VER"} ,
		{"word":"mutilera","word_nosc":"mutilera","lemma":"mutiler","pos":"VER"} ,
		{"word":"mutileraient","word_nosc":"mutileraient","lemma":"mutiler","pos":"VER"} ,
		{"word":"mutilé","word_nosc":"mutile","lemma":"mutiler","pos":"VER"} ,
		{"word":"mutilée","word_nosc":"mutilee","lemma":"mutiler","pos":"VER"} ,
		{"word":"mutilées","word_nosc":"mutilees","lemma":"mutiler","pos":"VER"} ,
		{"word":"mutilés","word_nosc":"mutiles","lemma":"mutiler","pos":"VER"} ,
		{"word":"mutina","word_nosc":"mutina","lemma":"mutiner","pos":"VER"} ,
		{"word":"mutinaient","word_nosc":"mutinaient","lemma":"mutiner","pos":"VER"} ,
		{"word":"mutine","word_nosc":"mutine","lemma":"mutiner","pos":"VER"} ,
		{"word":"mutinent","word_nosc":"mutinent","lemma":"mutiner","pos":"VER"} ,
		{"word":"mutiner","word_nosc":"mutiner","lemma":"mutiner","pos":"VER"} ,
		{"word":"mutines","word_nosc":"mutines","lemma":"mutiner","pos":"VER"} ,
		{"word":"mutinez","word_nosc":"mutinez","lemma":"mutiner","pos":"VER"} ,
		{"word":"mutiné","word_nosc":"mutine","lemma":"mutiner","pos":"VER"} ,
		{"word":"mutinée","word_nosc":"mutinee","lemma":"mutiner","pos":"VER"} ,
		{"word":"mutinés","word_nosc":"mutines","lemma":"mutiner","pos":"VER"} ,
		{"word":"mutât","word_nosc":"mutat","lemma":"muter","pos":"VER"} ,
		{"word":"muté","word_nosc":"mute","lemma":"muter","pos":"VER"} ,
		{"word":"mutée","word_nosc":"mutee","lemma":"muter","pos":"VER"} ,
		{"word":"mutées","word_nosc":"mutees","lemma":"muter","pos":"VER"} ,
		{"word":"mutés","word_nosc":"mutes","lemma":"muter","pos":"VER"} ,
		{"word":"mué","word_nosc":"mue","lemma":"muer","pos":"VER"} ,
		{"word":"muée","word_nosc":"muee","lemma":"muer","pos":"VER"} ,
		{"word":"muées","word_nosc":"muees","lemma":"muer","pos":"VER"} ,
		{"word":"mués","word_nosc":"mues","lemma":"muer","pos":"VER"} ,
		{"word":"mystifie","word_nosc":"mystifie","lemma":"mystifier","pos":"VER"} ,
		{"word":"mystifier","word_nosc":"mystifier","lemma":"mystifier","pos":"VER"} ,
		{"word":"mystifié","word_nosc":"mystifie","lemma":"mystifier","pos":"VER"} ,
		{"word":"mystifiée","word_nosc":"mystifiee","lemma":"mystifier","pos":"VER"} ,
		{"word":"mystifiées","word_nosc":"mystifiees","lemma":"mystifier","pos":"VER"} ,
		{"word":"mystifiés","word_nosc":"mystifies","lemma":"mystifier","pos":"VER"} ,
		{"word":"mythifiait","word_nosc":"mythifiait","lemma":"mythifier","pos":"VER"} ,
		{"word":"mythifier","word_nosc":"mythifier","lemma":"mythifier","pos":"VER"} ,
		{"word":"mythologisé","word_nosc":"mythologise","lemma":"mythologiser","pos":"VER"} ,
		{"word":"mâcha","word_nosc":"macha","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâchaient","word_nosc":"machaient","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâchais","word_nosc":"machais","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâchait","word_nosc":"machait","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâchant","word_nosc":"machant","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâche","word_nosc":"mache","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâchent","word_nosc":"machent","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâcher","word_nosc":"macher","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâcherai","word_nosc":"macherai","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâcherais","word_nosc":"macherais","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâcherait","word_nosc":"macherait","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâcherez","word_nosc":"macherez","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâches","word_nosc":"maches","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâchez","word_nosc":"machez","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâchonna","word_nosc":"machonna","lemma":"mâchonner","pos":"VER"} ,
		{"word":"mâchonnaient","word_nosc":"machonnaient","lemma":"mâchonner","pos":"VER"} ,
		{"word":"mâchonnais","word_nosc":"machonnais","lemma":"mâchonner","pos":"VER"} ,
		{"word":"mâchonnait","word_nosc":"machonnait","lemma":"mâchonner","pos":"VER"} ,
		{"word":"mâchonnant","word_nosc":"machonnant","lemma":"mâchonner","pos":"VER"} ,
		{"word":"mâchonne","word_nosc":"machonne","lemma":"mâchonner","pos":"VER"} ,
		{"word":"mâchonnent","word_nosc":"machonnent","lemma":"mâchonner","pos":"VER"} ,
		{"word":"mâchonner","word_nosc":"machonner","lemma":"mâchonner","pos":"VER"} ,
		{"word":"mâchonné","word_nosc":"machonne","lemma":"mâchonner","pos":"VER"} ,
		{"word":"mâchonnés","word_nosc":"machonnes","lemma":"mâchonner","pos":"VER"} ,
		{"word":"mâchons","word_nosc":"machons","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâchouilla","word_nosc":"machouilla","lemma":"mâchouiller","pos":"VER"} ,
		{"word":"mâchouillait","word_nosc":"machouillait","lemma":"mâchouiller","pos":"VER"} ,
		{"word":"mâchouillant","word_nosc":"machouillant","lemma":"mâchouiller","pos":"VER"} ,
		{"word":"mâchouille","word_nosc":"machouille","lemma":"mâchouiller","pos":"VER"} ,
		{"word":"mâchouiller","word_nosc":"machouiller","lemma":"mâchouiller","pos":"VER"} ,
		{"word":"mâchouilles","word_nosc":"machouilles","lemma":"mâchouiller","pos":"VER"} ,
		{"word":"mâchouillé","word_nosc":"machouille","lemma":"mâchouiller","pos":"VER"} ,
		{"word":"mâchouillée","word_nosc":"machouillee","lemma":"mâchouiller","pos":"VER"} ,
		{"word":"mâchuré","word_nosc":"machure","lemma":"mâchurer","pos":"VER"} ,
		{"word":"mâchurée","word_nosc":"machuree","lemma":"mâchurer","pos":"VER"} ,
		{"word":"mâchèrent","word_nosc":"macherent","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâché","word_nosc":"mache","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâchée","word_nosc":"machee","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâchés","word_nosc":"maches","lemma":"mâcher","pos":"VER"} ,
		{"word":"mâte","word_nosc":"mate","lemma":"mâter","pos":"VER"} ,
		{"word":"mâter","word_nosc":"mater","lemma":"mâter","pos":"VER"} ,
		{"word":"mâtinant","word_nosc":"matinant","lemma":"mâtiner","pos":"VER"} ,
		{"word":"mâtiné","word_nosc":"matine","lemma":"mâtiner","pos":"VER"} ,
		{"word":"mâtinée","word_nosc":"matinee","lemma":"mâtiner","pos":"VER"} ,
		{"word":"mâtinés","word_nosc":"matines","lemma":"mâtiner","pos":"VER"} ,
		{"word":"mâtée","word_nosc":"matee","lemma":"mâter","pos":"VER"} ,
		{"word":"mène","word_nosc":"mene","lemma":"mener","pos":"VER"} ,
		{"word":"mènent","word_nosc":"menent","lemma":"mener","pos":"VER"} ,
		{"word":"mènera","word_nosc":"menera","lemma":"mener","pos":"VER"} ,
		{"word":"mènerai","word_nosc":"menerai","lemma":"mener","pos":"VER"} ,
		{"word":"mèneraient","word_nosc":"meneraient","lemma":"mener","pos":"VER"} ,
		{"word":"mènerais","word_nosc":"menerais","lemma":"mener","pos":"VER"} ,
		{"word":"mènerait","word_nosc":"menerait","lemma":"mener","pos":"VER"} ,
		{"word":"mèneras","word_nosc":"meneras","lemma":"mener","pos":"VER"} ,
		{"word":"mènerez","word_nosc":"menerez","lemma":"mener","pos":"VER"} ,
		{"word":"mènerons","word_nosc":"menerons","lemma":"mener","pos":"VER"} ,
		{"word":"mèneront","word_nosc":"meneront","lemma":"mener","pos":"VER"} ,
		{"word":"mènes","word_nosc":"menes","lemma":"mener","pos":"VER"} ,
		{"word":"mètre","word_nosc":"metre","lemma":"métrer","pos":"VER"} ,
		{"word":"mètres","word_nosc":"metres","lemma":"métrer","pos":"VER"} ,
		{"word":"mécanisait","word_nosc":"mecanisait","lemma":"mécaniser","pos":"VER"} ,
		{"word":"mécanise","word_nosc":"mecanise","lemma":"mécaniser","pos":"VER"} ,
		{"word":"mécaniser","word_nosc":"mecaniser","lemma":"mécaniser","pos":"VER"} ,
		{"word":"mécanisé","word_nosc":"mecanise","lemma":"mécaniser","pos":"VER"} ,
		{"word":"mécanisée","word_nosc":"mecanisee","lemma":"mécaniser","pos":"VER"} ,
		{"word":"mécanisées","word_nosc":"mecanisees","lemma":"mécaniser","pos":"VER"} ,
		{"word":"mécanisés","word_nosc":"mecanises","lemma":"mécaniser","pos":"VER"} ,
		{"word":"méchait","word_nosc":"mechait","lemma":"mécher","pos":"VER"} ,
		{"word":"méchant","word_nosc":"mechant","lemma":"mécher","pos":"VER"} ,
		{"word":"mécher","word_nosc":"mecher","lemma":"mécher","pos":"VER"} ,
		{"word":"méconnais","word_nosc":"meconnais","lemma":"méconnaître","pos":"VER"} ,
		{"word":"méconnaissais","word_nosc":"meconnaissais","lemma":"méconnaître","pos":"VER"} ,
		{"word":"méconnaissant","word_nosc":"meconnaissant","lemma":"méconnaître","pos":"VER"} ,
		{"word":"méconnaisse","word_nosc":"meconnaisse","lemma":"méconnaître","pos":"VER"} ,
		{"word":"méconnaissent","word_nosc":"meconnaissent","lemma":"méconnaître","pos":"VER"} ,
		{"word":"méconnaîtra","word_nosc":"meconnaitra","lemma":"méconnaître","pos":"VER"} ,
		{"word":"méconnaître","word_nosc":"meconnaitre","lemma":"méconnaître","pos":"VER"} ,
		{"word":"méconnu","word_nosc":"meconnu","lemma":"méconnaître","pos":"VER"} ,
		{"word":"méconnue","word_nosc":"meconnue","lemma":"méconnaître","pos":"VER"} ,
		{"word":"méconnues","word_nosc":"meconnues","lemma":"méconnaître","pos":"VER"} ,
		{"word":"méconnus","word_nosc":"meconnus","lemma":"méconnaître","pos":"VER"} ,
		{"word":"mécontenta","word_nosc":"mecontenta","lemma":"mécontenter","pos":"VER"} ,
		{"word":"mécontentait","word_nosc":"mecontentait","lemma":"mécontenter","pos":"VER"} ,
		{"word":"mécontente","word_nosc":"mecontente","lemma":"mécontenter","pos":"VER"} ,
		{"word":"mécontenter","word_nosc":"mecontenter","lemma":"mécontenter","pos":"VER"} ,
		{"word":"mécontenté","word_nosc":"mecontente","lemma":"mécontenter","pos":"VER"} ,
		{"word":"mécontentés","word_nosc":"mecontentes","lemma":"mécontenter","pos":"VER"} ,
		{"word":"mécru","word_nosc":"mecru","lemma":"mécroire","pos":"VER"} ,
		{"word":"médaillé","word_nosc":"medaille","lemma":"médailler","pos":"VER"} ,
		{"word":"médaillée","word_nosc":"medaillee","lemma":"médailler","pos":"VER"} ,
		{"word":"médiatisant","word_nosc":"mediatisant","lemma":"médiatiser","pos":"VER"} ,
		{"word":"médiatisé","word_nosc":"mediatise","lemma":"médiatiser","pos":"VER"} ,
		{"word":"médiatisée","word_nosc":"mediatisee","lemma":"médiatiser","pos":"VER"} ,
		{"word":"médiatisées","word_nosc":"mediatisees","lemma":"médiatiser","pos":"VER"} ,
		{"word":"médiatisés","word_nosc":"mediatises","lemma":"médiatiser","pos":"VER"} ,
		{"word":"médicalisé","word_nosc":"medicalise","lemma":"médicaliser","pos":"VER"} ,
		{"word":"médicalisée","word_nosc":"medicalisee","lemma":"médicaliser","pos":"VER"} ,
		{"word":"médicamentait","word_nosc":"medicamentait","lemma":"médicamenter","pos":"VER"} ,
		{"word":"médicamenter","word_nosc":"medicamenter","lemma":"médicamenter","pos":"VER"} ,
		{"word":"médicamenté","word_nosc":"medicamente","lemma":"médicamenter","pos":"VER"} ,
		{"word":"médicine","word_nosc":"medicine","lemma":"médiciner","pos":"VER"} ,
		{"word":"médire","word_nosc":"medire","lemma":"médire","pos":"VER"} ,
		{"word":"médis","word_nosc":"medis","lemma":"médire","pos":"VER"} ,
		{"word":"médisaient","word_nosc":"medisaient","lemma":"médire","pos":"VER"} ,
		{"word":"médisait","word_nosc":"medisait","lemma":"médire","pos":"VER"} ,
		{"word":"médisant","word_nosc":"medisant","lemma":"médire","pos":"VER"} ,
		{"word":"médise","word_nosc":"medise","lemma":"médire","pos":"VER"} ,
		{"word":"médisent","word_nosc":"medisent","lemma":"médire","pos":"VER"} ,
		{"word":"médisez","word_nosc":"medisez","lemma":"médire","pos":"VER"} ,
		{"word":"médisons","word_nosc":"medisons","lemma":"médire","pos":"VER"} ,
		{"word":"médit","word_nosc":"medit","lemma":"médire","pos":"VER"} ,
		{"word":"médita","word_nosc":"medita","lemma":"méditer","pos":"VER"} ,
		{"word":"méditai","word_nosc":"meditai","lemma":"méditer","pos":"VER"} ,
		{"word":"méditaient","word_nosc":"meditaient","lemma":"méditer","pos":"VER"} ,
		{"word":"méditais","word_nosc":"meditais","lemma":"méditer","pos":"VER"} ,
		{"word":"méditait","word_nosc":"meditait","lemma":"méditer","pos":"VER"} ,
		{"word":"méditant","word_nosc":"meditant","lemma":"méditer","pos":"VER"} ,
		{"word":"médite","word_nosc":"medite","lemma":"méditer","pos":"VER"} ,
		{"word":"méditent","word_nosc":"meditent","lemma":"méditer","pos":"VER"} ,
		{"word":"méditer","word_nosc":"mediter","lemma":"méditer","pos":"VER"} ,
		{"word":"méditera","word_nosc":"meditera","lemma":"méditer","pos":"VER"} ,
		{"word":"méditerait","word_nosc":"mediterait","lemma":"méditer","pos":"VER"} ,
		{"word":"méditeras","word_nosc":"mediteras","lemma":"méditer","pos":"VER"} ,
		{"word":"médites","word_nosc":"medites","lemma":"méditer","pos":"VER"} ,
		{"word":"méditez","word_nosc":"meditez","lemma":"méditer","pos":"VER"} ,
		{"word":"méditiez","word_nosc":"meditiez","lemma":"méditer","pos":"VER"} ,
		{"word":"méditons","word_nosc":"meditons","lemma":"méditer","pos":"VER"} ,
		{"word":"méditèrent","word_nosc":"mediterent","lemma":"méditer","pos":"VER"} ,
		{"word":"médité","word_nosc":"medite","lemma":"méditer","pos":"VER"} ,
		{"word":"méditée","word_nosc":"meditee","lemma":"méditer","pos":"VER"} ,
		{"word":"méditées","word_nosc":"meditees","lemma":"méditer","pos":"VER"} ,
		{"word":"médités","word_nosc":"medites","lemma":"méditer","pos":"VER"} ,
		{"word":"médusa","word_nosc":"medusa","lemma":"méduser","pos":"VER"} ,
		{"word":"médusait","word_nosc":"medusait","lemma":"méduser","pos":"VER"} ,
		{"word":"médusant","word_nosc":"medusant","lemma":"méduser","pos":"VER"} ,
		{"word":"méduse","word_nosc":"meduse","lemma":"méduser","pos":"VER"} ,
		{"word":"médusent","word_nosc":"medusent","lemma":"méduser","pos":"VER"} ,
		{"word":"méduser","word_nosc":"meduser","lemma":"méduser","pos":"VER"} ,
		{"word":"méduses","word_nosc":"meduses","lemma":"méduser","pos":"VER"} ,
		{"word":"médusé","word_nosc":"meduse","lemma":"méduser","pos":"VER"} ,
		{"word":"médusée","word_nosc":"medusee","lemma":"méduser","pos":"VER"} ,
		{"word":"médusées","word_nosc":"medusees","lemma":"méduser","pos":"VER"} ,
		{"word":"médusés","word_nosc":"meduses","lemma":"méduser","pos":"VER"} ,
		{"word":"méfiaient","word_nosc":"mefiaient","lemma":"méfier","pos":"VER"} ,
		{"word":"méfiais","word_nosc":"mefiais","lemma":"méfier","pos":"VER"} ,
		{"word":"méfiait","word_nosc":"mefiait","lemma":"méfier","pos":"VER"} ,
		{"word":"méfiant","word_nosc":"mefiant","lemma":"méfier","pos":"VER"} ,
		{"word":"méfie","word_nosc":"mefie","lemma":"méfier","pos":"VER"} ,
		{"word":"méfient","word_nosc":"mefient","lemma":"méfier","pos":"VER"} ,
		{"word":"méfier","word_nosc":"mefier","lemma":"méfier","pos":"VER"} ,
		{"word":"méfiera","word_nosc":"mefiera","lemma":"méfier","pos":"VER"} ,
		{"word":"méfierai","word_nosc":"mefierai","lemma":"méfier","pos":"VER"} ,
		{"word":"méfierais","word_nosc":"mefierais","lemma":"méfier","pos":"VER"} ,
		{"word":"méfierait","word_nosc":"mefierait","lemma":"méfier","pos":"VER"} ,
		{"word":"méfieront","word_nosc":"mefieront","lemma":"méfier","pos":"VER"} ,
		{"word":"méfies","word_nosc":"mefies","lemma":"méfier","pos":"VER"} ,
		{"word":"méfiez","word_nosc":"mefiez","lemma":"méfier","pos":"VER"} ,
		{"word":"méfiions","word_nosc":"mefiions","lemma":"méfier","pos":"VER"} ,
		{"word":"méfions","word_nosc":"mefions","lemma":"méfier","pos":"VER"} ,
		{"word":"méfièrent","word_nosc":"mefierent","lemma":"méfier","pos":"VER"} ,
		{"word":"méfié","word_nosc":"mefie","lemma":"méfier","pos":"VER"} ,
		{"word":"méfiée","word_nosc":"mefiee","lemma":"méfier","pos":"VER"} ,
		{"word":"méfiés","word_nosc":"mefies","lemma":"méfier","pos":"VER"} ,
		{"word":"mégotaient","word_nosc":"megotaient","lemma":"mégoter","pos":"VER"} ,
		{"word":"mégotait","word_nosc":"megotait","lemma":"mégoter","pos":"VER"} ,
		{"word":"mégote","word_nosc":"megote","lemma":"mégoter","pos":"VER"} ,
		{"word":"mégoter","word_nosc":"megoter","lemma":"mégoter","pos":"VER"} ,
		{"word":"mégoté","word_nosc":"megote","lemma":"mégoter","pos":"VER"} ,
		{"word":"méjugez","word_nosc":"mejugez","lemma":"méjuger","pos":"VER"} ,
		{"word":"méjugé","word_nosc":"mejuge","lemma":"méjuger","pos":"VER"} ,
		{"word":"méjugés","word_nosc":"mejuges","lemma":"méjuger","pos":"VER"} ,
		{"word":"mélancoliser","word_nosc":"melancoliser","lemma":"mélancoliser","pos":"VER"} ,
		{"word":"mélange","word_nosc":"melange","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangea","word_nosc":"melangea","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangeaient","word_nosc":"melangeaient","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangeais","word_nosc":"melangeais","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangeait","word_nosc":"melangeait","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangeant","word_nosc":"melangeant","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangent","word_nosc":"melangent","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangeons","word_nosc":"melangeons","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélanger","word_nosc":"melanger","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangera","word_nosc":"melangera","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangerai","word_nosc":"melangerai","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangeraient","word_nosc":"melangeraient","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélanges","word_nosc":"melanges","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangez","word_nosc":"melangez","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangions","word_nosc":"melangions","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangèrent","word_nosc":"melangerent","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangé","word_nosc":"melange","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangée","word_nosc":"melangee","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangées","word_nosc":"melangees","lemma":"mélanger","pos":"VER"} ,
		{"word":"mélangés","word_nosc":"melanges","lemma":"mélanger","pos":"VER"} ,
		{"word":"mémorisaient","word_nosc":"memorisaient","lemma":"mémoriser","pos":"VER"} ,
		{"word":"mémorisait","word_nosc":"memorisait","lemma":"mémoriser","pos":"VER"} ,
		{"word":"mémorise","word_nosc":"memorise","lemma":"mémoriser","pos":"VER"} ,
		{"word":"mémorisent","word_nosc":"memorisent","lemma":"mémoriser","pos":"VER"} ,
		{"word":"mémoriser","word_nosc":"memoriser","lemma":"mémoriser","pos":"VER"} ,
		{"word":"mémoriserai","word_nosc":"memoriserai","lemma":"mémoriser","pos":"VER"} ,
		{"word":"mémoriserais","word_nosc":"memoriserais","lemma":"mémoriser","pos":"VER"} ,
		{"word":"mémorisez","word_nosc":"memorisez","lemma":"mémoriser","pos":"VER"} ,
		{"word":"mémorisiez","word_nosc":"memorisiez","lemma":"mémoriser","pos":"VER"} ,
		{"word":"mémorisé","word_nosc":"memorise","lemma":"mémoriser","pos":"VER"} ,
		{"word":"mémorisée","word_nosc":"memorisee","lemma":"mémoriser","pos":"VER"} ,
		{"word":"mémorisées","word_nosc":"memorisees","lemma":"mémoriser","pos":"VER"} ,
		{"word":"mémorisés","word_nosc":"memorises","lemma":"mémoriser","pos":"VER"} ,
		{"word":"ménage","word_nosc":"menage","lemma":"ménager","pos":"VER"} ,
		{"word":"ménagea","word_nosc":"menagea","lemma":"ménager","pos":"VER"} ,
		{"word":"ménageaient","word_nosc":"menageaient","lemma":"ménager","pos":"VER"} ,
		{"word":"ménageais","word_nosc":"menageais","lemma":"ménager","pos":"VER"} ,
		{"word":"ménageait","word_nosc":"menageait","lemma":"ménager","pos":"VER"} ,
		{"word":"ménageant","word_nosc":"menageant","lemma":"ménager","pos":"VER"} ,
		{"word":"ménagent","word_nosc":"menagent","lemma":"ménager","pos":"VER"} ,
		{"word":"ménager","word_nosc":"menager","lemma":"ménager","pos":"VER"} ,
		{"word":"ménagera","word_nosc":"menagera","lemma":"ménager","pos":"VER"} ,
		{"word":"ménagerai","word_nosc":"menagerai","lemma":"ménager","pos":"VER"} ,
		{"word":"ménagerait","word_nosc":"menagerait","lemma":"ménager","pos":"VER"} ,
		{"word":"ménageras","word_nosc":"menageras","lemma":"ménager","pos":"VER"} ,
		{"word":"ménages","word_nosc":"menages","lemma":"ménager","pos":"VER"} ,
		{"word":"ménagez","word_nosc":"menagez","lemma":"ménager","pos":"VER"} ,
		{"word":"ménageât","word_nosc":"menageat","lemma":"ménager","pos":"VER"} ,
		{"word":"ménagiez","word_nosc":"menagiez","lemma":"ménager","pos":"VER"} ,
		{"word":"ménagions","word_nosc":"menagions","lemma":"ménager","pos":"VER"} ,
		{"word":"ménagèrent","word_nosc":"menagerent","lemma":"ménager","pos":"VER"} ,
		{"word":"ménagé","word_nosc":"menage","lemma":"ménager","pos":"VER"} ,
		{"word":"ménagée","word_nosc":"menagee","lemma":"ménager","pos":"VER"} ,
		{"word":"ménagées","word_nosc":"menagees","lemma":"ménager","pos":"VER"} ,
		{"word":"ménagés","word_nosc":"menages","lemma":"ménager","pos":"VER"} ,
		{"word":"méprenais","word_nosc":"meprenais","lemma":"méprendre","pos":"VER"} ,
		{"word":"méprenait","word_nosc":"meprenait","lemma":"méprendre","pos":"VER"} ,
		{"word":"méprenant","word_nosc":"meprenant","lemma":"méprendre","pos":"VER"} ,
		{"word":"méprend","word_nosc":"meprend","lemma":"méprendre","pos":"VER"} ,
		{"word":"méprendre","word_nosc":"meprendre","lemma":"méprendre","pos":"VER"} ,
		{"word":"méprendriez","word_nosc":"meprendriez","lemma":"méprendre","pos":"VER"} ,
		{"word":"méprends","word_nosc":"meprends","lemma":"méprendre","pos":"VER"} ,
		{"word":"méprenez","word_nosc":"meprenez","lemma":"méprendre","pos":"VER"} ,
		{"word":"mépreniez","word_nosc":"mepreniez","lemma":"méprendre","pos":"VER"} ,
		{"word":"méprenne","word_nosc":"meprenne","lemma":"méprendre","pos":"VER"} ,
		{"word":"méprennent","word_nosc":"meprennent","lemma":"méprendre","pos":"VER"} ,
		{"word":"mépris","word_nosc":"mepris","lemma":"méprendre","pos":"VER"} ,
		{"word":"méprisa","word_nosc":"meprisa","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprisai","word_nosc":"meprisai","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprisaient","word_nosc":"meprisaient","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprisais","word_nosc":"meprisais","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprisait","word_nosc":"meprisait","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprisant","word_nosc":"meprisant","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprise","word_nosc":"meprise","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprisent","word_nosc":"meprisent","lemma":"mépriser","pos":"VER"} ,
		{"word":"mépriser","word_nosc":"mepriser","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprisera","word_nosc":"meprisera","lemma":"mépriser","pos":"VER"} ,
		{"word":"mépriserai","word_nosc":"mepriserai","lemma":"mépriser","pos":"VER"} ,
		{"word":"mépriseraient","word_nosc":"mepriseraient","lemma":"mépriser","pos":"VER"} ,
		{"word":"mépriserais","word_nosc":"mepriserais","lemma":"mépriser","pos":"VER"} ,
		{"word":"mépriserait","word_nosc":"mepriserait","lemma":"mépriser","pos":"VER"} ,
		{"word":"mépriseras","word_nosc":"mepriseras","lemma":"mépriser","pos":"VER"} ,
		{"word":"mépriseriez","word_nosc":"mepriseriez","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprises","word_nosc":"meprises","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprisez","word_nosc":"meprisez","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprisiez","word_nosc":"meprisiez","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprisions","word_nosc":"meprisions","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprisons","word_nosc":"meprisons","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprisé","word_nosc":"meprise","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprisée","word_nosc":"meprisee","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprisées","word_nosc":"meprisees","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprisés","word_nosc":"meprises","lemma":"mépriser","pos":"VER"} ,
		{"word":"méprit","word_nosc":"meprit","lemma":"méprendre","pos":"VER"} ,
		{"word":"méprît","word_nosc":"meprit","lemma":"méprendre","pos":"VER"} ,
		{"word":"mérita","word_nosc":"merita","lemma":"mériter","pos":"VER"} ,
		{"word":"méritaient","word_nosc":"meritaient","lemma":"mériter","pos":"VER"} ,
		{"word":"méritais","word_nosc":"meritais","lemma":"mériter","pos":"VER"} ,
		{"word":"méritait","word_nosc":"meritait","lemma":"mériter","pos":"VER"} ,
		{"word":"méritant","word_nosc":"meritant","lemma":"mériter","pos":"VER"} ,
		{"word":"méritassent","word_nosc":"meritassent","lemma":"mériter","pos":"VER"} ,
		{"word":"mérite","word_nosc":"merite","lemma":"mériter","pos":"VER"} ,
		{"word":"méritent","word_nosc":"meritent","lemma":"mériter","pos":"VER"} ,
		{"word":"mériter","word_nosc":"meriter","lemma":"mériter","pos":"VER"} ,
		{"word":"méritera","word_nosc":"meritera","lemma":"mériter","pos":"VER"} ,
		{"word":"mériterai","word_nosc":"meriterai","lemma":"mériter","pos":"VER"} ,
		{"word":"mériteraient","word_nosc":"meriteraient","lemma":"mériter","pos":"VER"} ,
		{"word":"mériterais","word_nosc":"meriterais","lemma":"mériter","pos":"VER"} ,
		{"word":"mériterait","word_nosc":"meriterait","lemma":"mériter","pos":"VER"} ,
		{"word":"mériteras","word_nosc":"meriteras","lemma":"mériter","pos":"VER"} ,
		{"word":"mériteriez","word_nosc":"meriteriez","lemma":"mériter","pos":"VER"} ,
		{"word":"mériteront","word_nosc":"meriteront","lemma":"mériter","pos":"VER"} ,
		{"word":"mérites","word_nosc":"merites","lemma":"mériter","pos":"VER"} ,
		{"word":"méritez","word_nosc":"meritez","lemma":"mériter","pos":"VER"} ,
		{"word":"méritiez","word_nosc":"meritiez","lemma":"mériter","pos":"VER"} ,
		{"word":"méritions","word_nosc":"meritions","lemma":"mériter","pos":"VER"} ,
		{"word":"méritons","word_nosc":"meritons","lemma":"mériter","pos":"VER"} ,
		{"word":"méritât","word_nosc":"meritat","lemma":"mériter","pos":"VER"} ,
		{"word":"mérité","word_nosc":"merite","lemma":"mériter","pos":"VER"} ,
		{"word":"méritée","word_nosc":"meritee","lemma":"mériter","pos":"VER"} ,
		{"word":"méritées","word_nosc":"meritees","lemma":"mériter","pos":"VER"} ,
		{"word":"mérités","word_nosc":"merites","lemma":"mériter","pos":"VER"} ,
		{"word":"mésallieras","word_nosc":"mesallieras","lemma":"mésallier","pos":"VER"} ,
		{"word":"mésallies","word_nosc":"mesallies","lemma":"mésallier","pos":"VER"} ,
		{"word":"mésalliés","word_nosc":"mesallies","lemma":"mésallier","pos":"VER"} ,
		{"word":"mésestimant","word_nosc":"mesestimant","lemma":"mésestimer","pos":"VER"} ,
		{"word":"mésestime","word_nosc":"mesestime","lemma":"mésestimer","pos":"VER"} ,
		{"word":"mésestimer","word_nosc":"mesestimer","lemma":"mésestimer","pos":"VER"} ,
		{"word":"mésestimez","word_nosc":"mesestimez","lemma":"mésestimer","pos":"VER"} ,
		{"word":"mésestimé","word_nosc":"mesestime","lemma":"mésestimer","pos":"VER"} ,
		{"word":"mésinterpréter","word_nosc":"mesinterpreter","lemma":"mésinterpréter","pos":"VER"} ,
		{"word":"mésuser","word_nosc":"mesuser","lemma":"mésuser","pos":"VER"} ,
		{"word":"métaboliser","word_nosc":"metaboliser","lemma":"métaboliser","pos":"VER"} ,
		{"word":"métallise","word_nosc":"metallise","lemma":"métalliser","pos":"VER"} ,
		{"word":"métallisent","word_nosc":"metallisent","lemma":"métalliser","pos":"VER"} ,
		{"word":"métallisé","word_nosc":"metallise","lemma":"métalliser","pos":"VER"} ,
		{"word":"métallisée","word_nosc":"metallisee","lemma":"métalliser","pos":"VER"} ,
		{"word":"métallisées","word_nosc":"metallisees","lemma":"métalliser","pos":"VER"} ,
		{"word":"métallisés","word_nosc":"metallises","lemma":"métalliser","pos":"VER"} ,
		{"word":"métamorphosa","word_nosc":"metamorphosa","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphosaient","word_nosc":"metamorphosaient","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphosait","word_nosc":"metamorphosait","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphosant","word_nosc":"metamorphosant","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphose","word_nosc":"metamorphose","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphosent","word_nosc":"metamorphosent","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphoser","word_nosc":"metamorphoser","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphoseraient","word_nosc":"metamorphoseraient","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphoserait","word_nosc":"metamorphoserait","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphoses","word_nosc":"metamorphoses","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphosez","word_nosc":"metamorphosez","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphosions","word_nosc":"metamorphosions","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphosât","word_nosc":"metamorphosat","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphosèrent","word_nosc":"metamorphoserent","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphosé","word_nosc":"metamorphose","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphosée","word_nosc":"metamorphosee","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphosées","word_nosc":"metamorphosees","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métamorphosés","word_nosc":"metamorphoses","lemma":"métamorphoser","pos":"VER"} ,
		{"word":"métastasé","word_nosc":"metastase","lemma":"métastaser","pos":"VER"} ,
		{"word":"métisser","word_nosc":"metisser","lemma":"métisser","pos":"VER"} ,
		{"word":"métissez","word_nosc":"metissez","lemma":"métisser","pos":"VER"} ,
		{"word":"métissé","word_nosc":"metisse","lemma":"métisser","pos":"VER"} ,
		{"word":"métissée","word_nosc":"metissee","lemma":"métisser","pos":"VER"} ,
		{"word":"métra","word_nosc":"metra","lemma":"métrer","pos":"VER"} ,
		{"word":"métras","word_nosc":"metras","lemma":"métrer","pos":"VER"} ,
		{"word":"mêla","word_nosc":"mela","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlai","word_nosc":"melai","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlaient","word_nosc":"melaient","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlais","word_nosc":"melais","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlait","word_nosc":"melait","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlant","word_nosc":"melant","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlasse","word_nosc":"melasse","lemma":"mêler","pos":"VER"} ,
		{"word":"mêle","word_nosc":"mele","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlent","word_nosc":"melent","lemma":"mêler","pos":"VER"} ,
		{"word":"mêler","word_nosc":"meler","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlera","word_nosc":"melera","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlerai","word_nosc":"melerai","lemma":"mêler","pos":"VER"} ,
		{"word":"mêleraient","word_nosc":"meleraient","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlerais","word_nosc":"melerais","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlerait","word_nosc":"melerait","lemma":"mêler","pos":"VER"} ,
		{"word":"mêleront","word_nosc":"meleront","lemma":"mêler","pos":"VER"} ,
		{"word":"mêles","word_nosc":"meles","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlez","word_nosc":"melez","lemma":"mêler","pos":"VER"} ,
		{"word":"mêliez","word_nosc":"meliez","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlions","word_nosc":"melions","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlons","word_nosc":"melons","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlât","word_nosc":"melat","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlèrent","word_nosc":"melerent","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlé","word_nosc":"mele","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlée","word_nosc":"melee","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlées","word_nosc":"melees","lemma":"mêler","pos":"VER"} ,
		{"word":"mêlés","word_nosc":"meles","lemma":"mêler","pos":"VER"} ,
		{"word":"mîmes","word_nosc":"mimes","lemma":"mettre","pos":"VER"} ,
		{"word":"mît","word_nosc":"mit","lemma":"mettre","pos":"VER"} ,
		{"word":"mû","word_nosc":"mu","lemma":"mouvoir","pos":"VER"} ,
		{"word":"mûri","word_nosc":"muri","lemma":"mûrir","pos":"VER"} ,
		{"word":"mûrie","word_nosc":"murie","lemma":"mûrir","pos":"VER"} ,
		{"word":"mûries","word_nosc":"muries","lemma":"mûrir","pos":"VER"} ,
		{"word":"mûrir","word_nosc":"murir","lemma":"mûrir","pos":"VER"} ,
		{"word":"mûrira","word_nosc":"murira","lemma":"mûrir","pos":"VER"} ,
		{"word":"mûriraient","word_nosc":"muriraient","lemma":"mûrir","pos":"VER"} ,
		{"word":"mûrirez","word_nosc":"murirez","lemma":"mûrir","pos":"VER"} ,
		{"word":"mûris","word_nosc":"muris","lemma":"mûrir","pos":"VER"} ,
		{"word":"mûrissaient","word_nosc":"murissaient","lemma":"mûrir","pos":"VER"} ,
		{"word":"mûrissait","word_nosc":"murissait","lemma":"mûrir","pos":"VER"} ,
		{"word":"mûrissant","word_nosc":"murissant","lemma":"mûrir","pos":"VER"} ,
		{"word":"mûrisse","word_nosc":"murisse","lemma":"mûrir","pos":"VER"} ,
		{"word":"mûrissent","word_nosc":"murissent","lemma":"mûrir","pos":"VER"} ,
		{"word":"mûrit","word_nosc":"murit","lemma":"mûrir","pos":"VER"} ,
		{"word":"nacra","word_nosc":"nacra","lemma":"nacrer","pos":"VER"} ,
		{"word":"nacraient","word_nosc":"nacraient","lemma":"nacrer","pos":"VER"} ,
		{"word":"nacré","word_nosc":"nacre","lemma":"nacrer","pos":"VER"} ,
		{"word":"nacrée","word_nosc":"nacree","lemma":"nacrer","pos":"VER"} ,
		{"word":"nacrées","word_nosc":"nacrees","lemma":"nacrer","pos":"VER"} ,
		{"word":"nacrés","word_nosc":"nacres","lemma":"nacrer","pos":"VER"} ,
		{"word":"nage","word_nosc":"nage","lemma":"nager","pos":"VER"} ,
		{"word":"nagea","word_nosc":"nagea","lemma":"nager","pos":"VER"} ,
		{"word":"nageai","word_nosc":"nageai","lemma":"nager","pos":"VER"} ,
		{"word":"nageaient","word_nosc":"nageaient","lemma":"nager","pos":"VER"} ,
		{"word":"nageais","word_nosc":"nageais","lemma":"nager","pos":"VER"} ,
		{"word":"nageait","word_nosc":"nageait","lemma":"nager","pos":"VER"} ,
		{"word":"nageant","word_nosc":"nageant","lemma":"nager","pos":"VER"} ,
		{"word":"nagent","word_nosc":"nagent","lemma":"nager","pos":"VER"} ,
		{"word":"nageons","word_nosc":"nageons","lemma":"nager","pos":"VER"} ,
		{"word":"nager","word_nosc":"nager","lemma":"nager","pos":"VER"} ,
		{"word":"nagera","word_nosc":"nagera","lemma":"nager","pos":"VER"} ,
		{"word":"nagerai","word_nosc":"nagerai","lemma":"nager","pos":"VER"} ,
		{"word":"nageraient","word_nosc":"nageraient","lemma":"nager","pos":"VER"} ,
		{"word":"nagerais","word_nosc":"nagerais","lemma":"nager","pos":"VER"} ,
		{"word":"nagerait","word_nosc":"nagerait","lemma":"nager","pos":"VER"} ,
		{"word":"nageras","word_nosc":"nageras","lemma":"nager","pos":"VER"} ,
		{"word":"nagerez","word_nosc":"nagerez","lemma":"nager","pos":"VER"} ,
		{"word":"nagerons","word_nosc":"nagerons","lemma":"nager","pos":"VER"} ,
		{"word":"nageront","word_nosc":"nageront","lemma":"nager","pos":"VER"} ,
		{"word":"nages","word_nosc":"nages","lemma":"nager","pos":"VER"} ,
		{"word":"nagez","word_nosc":"nagez","lemma":"nager","pos":"VER"} ,
		{"word":"nageât","word_nosc":"nageat","lemma":"nager","pos":"VER"} ,
		{"word":"nagiez","word_nosc":"nagiez","lemma":"nager","pos":"VER"} ,
		{"word":"nagions","word_nosc":"nagions","lemma":"nager","pos":"VER"} ,
		{"word":"nagèrent","word_nosc":"nagerent","lemma":"nager","pos":"VER"} ,
		{"word":"nagé","word_nosc":"nage","lemma":"nager","pos":"VER"} ,
		{"word":"nais","word_nosc":"nais","lemma":"naître","pos":"VER"} ,
		{"word":"naissaient","word_nosc":"naissaient","lemma":"naître","pos":"VER"} ,
		{"word":"naissais","word_nosc":"naissais","lemma":"naître","pos":"VER"} ,
		{"word":"naissait","word_nosc":"naissait","lemma":"naître","pos":"VER"} ,
		{"word":"naissant","word_nosc":"naissant","lemma":"naître","pos":"VER"} ,
		{"word":"naisse","word_nosc":"naisse","lemma":"naître","pos":"VER"} ,
		{"word":"naissent","word_nosc":"naissent","lemma":"naître","pos":"VER"} ,
		{"word":"naisses","word_nosc":"naisses","lemma":"naître","pos":"VER"} ,
		{"word":"naissez","word_nosc":"naissez","lemma":"naître","pos":"VER"} ,
		{"word":"naissions","word_nosc":"naissions","lemma":"naître","pos":"VER"} ,
		{"word":"naissons","word_nosc":"naissons","lemma":"naître","pos":"VER"} ,
		{"word":"nanti","word_nosc":"nanti","lemma":"nantir","pos":"VER"} ,
		{"word":"nantie","word_nosc":"nantie","lemma":"nantir","pos":"VER"} ,
		{"word":"nanties","word_nosc":"nanties","lemma":"nantir","pos":"VER"} ,
		{"word":"nantir","word_nosc":"nantir","lemma":"nantir","pos":"VER"} ,
		{"word":"nantis","word_nosc":"nantis","lemma":"nantir","pos":"VER"} ,
		{"word":"nappa","word_nosc":"nappa","lemma":"napper","pos":"VER"} ,
		{"word":"nappaient","word_nosc":"nappaient","lemma":"napper","pos":"VER"} ,
		{"word":"nappait","word_nosc":"nappait","lemma":"napper","pos":"VER"} ,
		{"word":"nappe","word_nosc":"nappe","lemma":"napper","pos":"VER"} ,
		{"word":"napper","word_nosc":"napper","lemma":"napper","pos":"VER"} ,
		{"word":"nappé","word_nosc":"nappe","lemma":"napper","pos":"VER"} ,
		{"word":"nappée","word_nosc":"nappee","lemma":"napper","pos":"VER"} ,
		{"word":"nappées","word_nosc":"nappees","lemma":"napper","pos":"VER"} ,
		{"word":"nappés","word_nosc":"nappes","lemma":"napper","pos":"VER"} ,
		{"word":"naquirent","word_nosc":"naquirent","lemma":"naître","pos":"VER"} ,
		{"word":"naquis","word_nosc":"naquis","lemma":"naître","pos":"VER"} ,
		{"word":"naquisse","word_nosc":"naquisse","lemma":"naître","pos":"VER"} ,
		{"word":"naquissent","word_nosc":"naquissent","lemma":"naître","pos":"VER"} ,
		{"word":"naquit","word_nosc":"naquit","lemma":"naître","pos":"VER"} ,
		{"word":"naquît","word_nosc":"naquit","lemma":"naître","pos":"VER"} ,
		{"word":"nargua","word_nosc":"nargua","lemma":"narguer","pos":"VER"} ,
		{"word":"narguaient","word_nosc":"narguaient","lemma":"narguer","pos":"VER"} ,
		{"word":"narguais","word_nosc":"narguais","lemma":"narguer","pos":"VER"} ,
		{"word":"narguait","word_nosc":"narguait","lemma":"narguer","pos":"VER"} ,
		{"word":"narguant","word_nosc":"narguant","lemma":"narguer","pos":"VER"} ,
		{"word":"nargue","word_nosc":"nargue","lemma":"narguer","pos":"VER"} ,
		{"word":"narguent","word_nosc":"narguent","lemma":"narguer","pos":"VER"} ,
		{"word":"narguer","word_nosc":"narguer","lemma":"narguer","pos":"VER"} ,
		{"word":"nargues","word_nosc":"nargues","lemma":"narguer","pos":"VER"} ,
		{"word":"narguez","word_nosc":"narguez","lemma":"narguer","pos":"VER"} ,
		{"word":"narguons","word_nosc":"narguons","lemma":"narguer","pos":"VER"} ,
		{"word":"nargué","word_nosc":"nargue","lemma":"narguer","pos":"VER"} ,
		{"word":"narguées","word_nosc":"narguees","lemma":"narguer","pos":"VER"} ,
		{"word":"narra","word_nosc":"narra","lemma":"narrer","pos":"VER"} ,
		{"word":"narrai","word_nosc":"narrai","lemma":"narrer","pos":"VER"} ,
		{"word":"narrait","word_nosc":"narrait","lemma":"narrer","pos":"VER"} ,
		{"word":"narrant","word_nosc":"narrant","lemma":"narrer","pos":"VER"} ,
		{"word":"narre","word_nosc":"narre","lemma":"narrer","pos":"VER"} ,
		{"word":"narrer","word_nosc":"narrer","lemma":"narrer","pos":"VER"} ,
		{"word":"narré","word_nosc":"narre","lemma":"narrer","pos":"VER"} ,
		{"word":"narrée","word_nosc":"narree","lemma":"narrer","pos":"VER"} ,
		{"word":"narrées","word_nosc":"narrees","lemma":"narrer","pos":"VER"} ,
		{"word":"nasilla","word_nosc":"nasilla","lemma":"nasiller","pos":"VER"} ,
		{"word":"nasillaient","word_nosc":"nasillaient","lemma":"nasiller","pos":"VER"} ,
		{"word":"nasillait","word_nosc":"nasillait","lemma":"nasiller","pos":"VER"} ,
		{"word":"nasillant","word_nosc":"nasillant","lemma":"nasiller","pos":"VER"} ,
		{"word":"nasille","word_nosc":"nasille","lemma":"nasiller","pos":"VER"} ,
		{"word":"nasillent","word_nosc":"nasillent","lemma":"nasiller","pos":"VER"} ,
		{"word":"nasiller","word_nosc":"nasiller","lemma":"nasiller","pos":"VER"} ,
		{"word":"nasillonne","word_nosc":"nasillonne","lemma":"nasillonner","pos":"VER"} ,
		{"word":"nationalisait","word_nosc":"nationalisait","lemma":"nationaliser","pos":"VER"} ,
		{"word":"nationalisent","word_nosc":"nationalisent","lemma":"nationaliser","pos":"VER"} ,
		{"word":"nationaliser","word_nosc":"nationaliser","lemma":"nationaliser","pos":"VER"} ,
		{"word":"nationalisons","word_nosc":"nationalisons","lemma":"nationaliser","pos":"VER"} ,
		{"word":"nationalisé","word_nosc":"nationalise","lemma":"nationaliser","pos":"VER"} ,
		{"word":"nationalisée","word_nosc":"nationalisee","lemma":"nationaliser","pos":"VER"} ,
		{"word":"nationalisées","word_nosc":"nationalisees","lemma":"nationaliser","pos":"VER"} ,
		{"word":"nationalisés","word_nosc":"nationalises","lemma":"nationaliser","pos":"VER"} ,
		{"word":"natta","word_nosc":"natta","lemma":"natter","pos":"VER"} ,
		{"word":"natte","word_nosc":"natte","lemma":"natter","pos":"VER"} ,
		{"word":"natter","word_nosc":"natter","lemma":"natter","pos":"VER"} ,
		{"word":"natté","word_nosc":"natte","lemma":"natter","pos":"VER"} ,
		{"word":"nattée","word_nosc":"nattee","lemma":"natter","pos":"VER"} ,
		{"word":"nattées","word_nosc":"nattees","lemma":"natter","pos":"VER"} ,
		{"word":"nattés","word_nosc":"nattes","lemma":"natter","pos":"VER"} ,
		{"word":"naturaliser","word_nosc":"naturaliser","lemma":"naturaliser","pos":"VER"} ,
		{"word":"naturalisé","word_nosc":"naturalise","lemma":"naturaliser","pos":"VER"} ,
		{"word":"naturalisés","word_nosc":"naturalises","lemma":"naturaliser","pos":"VER"} ,
		{"word":"naufrage","word_nosc":"naufrage","lemma":"naufrager","pos":"VER"} ,
		{"word":"naufrageant","word_nosc":"naufrageant","lemma":"naufrager","pos":"VER"} ,
		{"word":"naufragent","word_nosc":"naufragent","lemma":"naufrager","pos":"VER"} ,
		{"word":"naufrager","word_nosc":"naufrager","lemma":"naufrager","pos":"VER"} ,
		{"word":"naufragé","word_nosc":"naufrage","lemma":"naufrager","pos":"VER"} ,
		{"word":"naufragée","word_nosc":"naufragee","lemma":"naufrager","pos":"VER"} ,
		{"word":"naufragés","word_nosc":"naufrages","lemma":"naufrager","pos":"VER"} ,
		{"word":"navigua","word_nosc":"navigua","lemma":"naviguer","pos":"VER"} ,
		{"word":"naviguaient","word_nosc":"naviguaient","lemma":"naviguer","pos":"VER"} ,
		{"word":"naviguais","word_nosc":"naviguais","lemma":"naviguer","pos":"VER"} ,
		{"word":"naviguait","word_nosc":"naviguait","lemma":"naviguer","pos":"VER"} ,
		{"word":"naviguant","word_nosc":"naviguant","lemma":"naviguer","pos":"VER"} ,
		{"word":"navigue","word_nosc":"navigue","lemma":"naviguer","pos":"VER"} ,
		{"word":"naviguent","word_nosc":"naviguent","lemma":"naviguer","pos":"VER"} ,
		{"word":"naviguer","word_nosc":"naviguer","lemma":"naviguer","pos":"VER"} ,
		{"word":"naviguera","word_nosc":"naviguera","lemma":"naviguer","pos":"VER"} ,
		{"word":"naviguerai","word_nosc":"naviguerai","lemma":"naviguer","pos":"VER"} ,
		{"word":"navigueraient","word_nosc":"navigueraient","lemma":"naviguer","pos":"VER"} ,
		{"word":"naviguerons","word_nosc":"naviguerons","lemma":"naviguer","pos":"VER"} ,
		{"word":"navigues","word_nosc":"navigues","lemma":"naviguer","pos":"VER"} ,
		{"word":"naviguez","word_nosc":"naviguez","lemma":"naviguer","pos":"VER"} ,
		{"word":"naviguiez","word_nosc":"naviguiez","lemma":"naviguer","pos":"VER"} ,
		{"word":"naviguions","word_nosc":"naviguions","lemma":"naviguer","pos":"VER"} ,
		{"word":"naviguons","word_nosc":"naviguons","lemma":"naviguer","pos":"VER"} ,
		{"word":"naviguèrent","word_nosc":"naviguerent","lemma":"naviguer","pos":"VER"} ,
		{"word":"navigué","word_nosc":"navigue","lemma":"naviguer","pos":"VER"} ,
		{"word":"navra","word_nosc":"navra","lemma":"navrer","pos":"VER"} ,
		{"word":"navrait","word_nosc":"navrait","lemma":"navrer","pos":"VER"} ,
		{"word":"navrant","word_nosc":"navrant","lemma":"navrer","pos":"VER"} ,
		{"word":"navre","word_nosc":"navre","lemma":"navrer","pos":"VER"} ,
		{"word":"navrer","word_nosc":"navrer","lemma":"navrer","pos":"VER"} ,
		{"word":"navres","word_nosc":"navres","lemma":"navrer","pos":"VER"} ,
		{"word":"navrez","word_nosc":"navrez","lemma":"navrer","pos":"VER"} ,
		{"word":"navré","word_nosc":"navre","lemma":"navrer","pos":"VER"} ,
		{"word":"navrée","word_nosc":"navree","lemma":"navrer","pos":"VER"} ,
		{"word":"navrées","word_nosc":"navrees","lemma":"navrer","pos":"VER"} ,
		{"word":"navrés","word_nosc":"navres","lemma":"navrer","pos":"VER"} ,
		{"word":"naît","word_nosc":"nait","lemma":"naître","pos":"VER"} ,
		{"word":"naîtra","word_nosc":"naitra","lemma":"naître","pos":"VER"} ,
		{"word":"naîtrai","word_nosc":"naitrai","lemma":"naître","pos":"VER"} ,
		{"word":"naîtraient","word_nosc":"naitraient","lemma":"naître","pos":"VER"} ,
		{"word":"naîtrait","word_nosc":"naitrait","lemma":"naître","pos":"VER"} ,
		{"word":"naître","word_nosc":"naitre","lemma":"naître","pos":"VER"} ,
		{"word":"naîtrions","word_nosc":"naitrions","lemma":"naître","pos":"VER"} ,
		{"word":"naîtront","word_nosc":"naitront","lemma":"naître","pos":"VER"} ,
		{"word":"neige","word_nosc":"neige","lemma":"neiger","pos":"VER"} ,
		{"word":"neigeait","word_nosc":"neigeait","lemma":"neiger","pos":"VER"} ,
		{"word":"neiger","word_nosc":"neiger","lemma":"neiger","pos":"VER"} ,
		{"word":"neigera","word_nosc":"neigera","lemma":"neiger","pos":"VER"} ,
		{"word":"neigerait","word_nosc":"neigerait","lemma":"neiger","pos":"VER"} ,
		{"word":"neiges","word_nosc":"neiges","lemma":"neiger","pos":"VER"} ,
		{"word":"neigé","word_nosc":"neige","lemma":"neiger","pos":"VER"} ,
		{"word":"nerva","word_nosc":"nerva","lemma":"nerver","pos":"VER"} ,
		{"word":"nerver","word_nosc":"nerver","lemma":"nerver","pos":"VER"} ,
		{"word":"nervuré","word_nosc":"nervure","lemma":"nervurer","pos":"VER"} ,
		{"word":"nervurée","word_nosc":"nervuree","lemma":"nervurer","pos":"VER"} ,
		{"word":"nervurées","word_nosc":"nervurees","lemma":"nervurer","pos":"VER"} ,
		{"word":"nervé","word_nosc":"nerve","lemma":"nerver","pos":"VER"} ,
		{"word":"nettoie","word_nosc":"nettoie","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoient","word_nosc":"nettoient","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoiera","word_nosc":"nettoiera","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoierai","word_nosc":"nettoierai","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoieraient","word_nosc":"nettoieraient","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoierais","word_nosc":"nettoierais","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoierait","word_nosc":"nettoierait","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoieras","word_nosc":"nettoieras","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoierez","word_nosc":"nettoierez","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoierons","word_nosc":"nettoierons","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoies","word_nosc":"nettoies","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoya","word_nosc":"nettoya","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoyai","word_nosc":"nettoyai","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoyaient","word_nosc":"nettoyaient","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoyais","word_nosc":"nettoyais","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoyait","word_nosc":"nettoyait","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoyant","word_nosc":"nettoyant","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoyer","word_nosc":"nettoyer","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoyez","word_nosc":"nettoyez","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoyiez","word_nosc":"nettoyiez","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoyons","word_nosc":"nettoyons","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoyât","word_nosc":"nettoyat","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoyèrent","word_nosc":"nettoyerent","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoyé","word_nosc":"nettoye","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoyée","word_nosc":"nettoyee","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoyées","word_nosc":"nettoyees","lemma":"nettoyer","pos":"VER"} ,
		{"word":"nettoyés","word_nosc":"nettoyes","lemma":"nettoyer","pos":"VER"} ,
		{"word":"neutralisaient","word_nosc":"neutralisaient","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutralisait","word_nosc":"neutralisait","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutralisant","word_nosc":"neutralisant","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutralise","word_nosc":"neutralise","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutralisent","word_nosc":"neutralisent","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutraliser","word_nosc":"neutraliser","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutralisera","word_nosc":"neutralisera","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutraliserai","word_nosc":"neutraliserai","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutraliseraient","word_nosc":"neutraliseraient","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutraliserait","word_nosc":"neutraliserait","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutraliseront","word_nosc":"neutraliseront","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutralisez","word_nosc":"neutralisez","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutralisiez","word_nosc":"neutralisiez","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutralisons","word_nosc":"neutralisons","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutralisé","word_nosc":"neutralise","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutralisée","word_nosc":"neutralisee","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutralisées","word_nosc":"neutralisees","lemma":"neutraliser","pos":"VER"} ,
		{"word":"neutralisés","word_nosc":"neutralises","lemma":"neutraliser","pos":"VER"} ,
		{"word":"nia","word_nosc":"nia","lemma":"nier","pos":"VER"} ,
		{"word":"niai","word_nosc":"niai","lemma":"nier","pos":"VER"} ,
		{"word":"niaient","word_nosc":"niaient","lemma":"nier","pos":"VER"} ,
		{"word":"niais","word_nosc":"niais","lemma":"nier","pos":"VER"} ,
		{"word":"niaiser","word_nosc":"niaiser","lemma":"niaiser","pos":"VER"} ,
		{"word":"niaisé","word_nosc":"niaise","lemma":"niaiser","pos":"VER"} ,
		{"word":"niait","word_nosc":"niait","lemma":"nier","pos":"VER"} ,
		{"word":"niant","word_nosc":"niant","lemma":"nier","pos":"VER"} ,
		{"word":"nicha","word_nosc":"nicha","lemma":"nicher","pos":"VER"} ,
		{"word":"nichaient","word_nosc":"nichaient","lemma":"nicher","pos":"VER"} ,
		{"word":"nichait","word_nosc":"nichait","lemma":"nicher","pos":"VER"} ,
		{"word":"nichant","word_nosc":"nichant","lemma":"nicher","pos":"VER"} ,
		{"word":"niche","word_nosc":"niche","lemma":"nicher","pos":"VER"} ,
		{"word":"nichent","word_nosc":"nichent","lemma":"nicher","pos":"VER"} ,
		{"word":"nicher","word_nosc":"nicher","lemma":"nicher","pos":"VER"} ,
		{"word":"nichions","word_nosc":"nichions","lemma":"nicher","pos":"VER"} ,
		{"word":"nichons","word_nosc":"nichons","lemma":"nicher","pos":"VER"} ,
		{"word":"nichèrent","word_nosc":"nicherent","lemma":"nicher","pos":"VER"} ,
		{"word":"niché","word_nosc":"niche","lemma":"nicher","pos":"VER"} ,
		{"word":"nichée","word_nosc":"nichee","lemma":"nicher","pos":"VER"} ,
		{"word":"nichées","word_nosc":"nichees","lemma":"nicher","pos":"VER"} ,
		{"word":"nichés","word_nosc":"niches","lemma":"nicher","pos":"VER"} ,
		{"word":"nickelle","word_nosc":"nickelle","lemma":"nickeler","pos":"VER"} ,
		{"word":"nickelé","word_nosc":"nickele","lemma":"nickeler","pos":"VER"} ,
		{"word":"nickelée","word_nosc":"nickelee","lemma":"nickeler","pos":"VER"} ,
		{"word":"nickelées","word_nosc":"nickelees","lemma":"nickeler","pos":"VER"} ,
		{"word":"nickelés","word_nosc":"nickeles","lemma":"nickeler","pos":"VER"} ,
		{"word":"nicotinisé","word_nosc":"nicotinise","lemma":"nicotiniser","pos":"VER"} ,
		{"word":"nicotinisés","word_nosc":"nicotinises","lemma":"nicotiniser","pos":"VER"} ,
		{"word":"nidifie","word_nosc":"nidifie","lemma":"nidifier","pos":"VER"} ,
		{"word":"nie","word_nosc":"nie","lemma":"nier","pos":"VER"} ,
		{"word":"niellé","word_nosc":"nielle","lemma":"nieller","pos":"VER"} ,
		{"word":"nient","word_nosc":"nient","lemma":"nier","pos":"VER"} ,
		{"word":"nier","word_nosc":"nier","lemma":"nier","pos":"VER"} ,
		{"word":"niera","word_nosc":"niera","lemma":"nier","pos":"VER"} ,
		{"word":"nierai","word_nosc":"nierai","lemma":"nier","pos":"VER"} ,
		{"word":"nierais","word_nosc":"nierais","lemma":"nier","pos":"VER"} ,
		{"word":"nierait","word_nosc":"nierait","lemma":"nier","pos":"VER"} ,
		{"word":"nieras","word_nosc":"nieras","lemma":"nier","pos":"VER"} ,
		{"word":"nierez","word_nosc":"nierez","lemma":"nier","pos":"VER"} ,
		{"word":"nieriez","word_nosc":"nieriez","lemma":"nier","pos":"VER"} ,
		{"word":"nierons","word_nosc":"nierons","lemma":"nier","pos":"VER"} ,
		{"word":"nieront","word_nosc":"nieront","lemma":"nier","pos":"VER"} ,
		{"word":"nies","word_nosc":"nies","lemma":"nier","pos":"VER"} ,
		{"word":"niez","word_nosc":"niez","lemma":"nier","pos":"VER"} ,
		{"word":"nimba","word_nosc":"nimba","lemma":"nimber","pos":"VER"} ,
		{"word":"nimbaient","word_nosc":"nimbaient","lemma":"nimber","pos":"VER"} ,
		{"word":"nimbait","word_nosc":"nimbait","lemma":"nimber","pos":"VER"} ,
		{"word":"nimbant","word_nosc":"nimbant","lemma":"nimber","pos":"VER"} ,
		{"word":"nimbent","word_nosc":"nimbent","lemma":"nimber","pos":"VER"} ,
		{"word":"nimber","word_nosc":"nimber","lemma":"nimber","pos":"VER"} ,
		{"word":"nimbé","word_nosc":"nimbe","lemma":"nimber","pos":"VER"} ,
		{"word":"nimbée","word_nosc":"nimbee","lemma":"nimber","pos":"VER"} ,
		{"word":"nimbées","word_nosc":"nimbees","lemma":"nimber","pos":"VER"} ,
		{"word":"nimbés","word_nosc":"nimbes","lemma":"nimber","pos":"VER"} ,
		{"word":"nions","word_nosc":"nions","lemma":"nier","pos":"VER"} ,
		{"word":"nippe","word_nosc":"nippe","lemma":"nipper","pos":"VER"} ,
		{"word":"nipper","word_nosc":"nipper","lemma":"nipper","pos":"VER"} ,
		{"word":"nippes","word_nosc":"nippes","lemma":"nipper","pos":"VER"} ,
		{"word":"nippez","word_nosc":"nippez","lemma":"nipper","pos":"VER"} ,
		{"word":"nippé","word_nosc":"nippe","lemma":"nipper","pos":"VER"} ,
		{"word":"nippée","word_nosc":"nippee","lemma":"nipper","pos":"VER"} ,
		{"word":"nippées","word_nosc":"nippees","lemma":"nipper","pos":"VER"} ,
		{"word":"niquait","word_nosc":"niquait","lemma":"niquer","pos":"VER"} ,
		{"word":"nique","word_nosc":"nique","lemma":"niquer","pos":"VER"} ,
		{"word":"niquent","word_nosc":"niquent","lemma":"niquer","pos":"VER"} ,
		{"word":"niquer","word_nosc":"niquer","lemma":"niquer","pos":"VER"} ,
		{"word":"niquera","word_nosc":"niquera","lemma":"niquer","pos":"VER"} ,
		{"word":"niquerait","word_nosc":"niquerait","lemma":"niquer","pos":"VER"} ,
		{"word":"niques","word_nosc":"niques","lemma":"niquer","pos":"VER"} ,
		{"word":"niquez","word_nosc":"niquez","lemma":"niquer","pos":"VER"} ,
		{"word":"niquons","word_nosc":"niquons","lemma":"niquer","pos":"VER"} ,
		{"word":"niqué","word_nosc":"nique","lemma":"niquer","pos":"VER"} ,
		{"word":"niquée","word_nosc":"niquee","lemma":"niquer","pos":"VER"} ,
		{"word":"niqués","word_nosc":"niques","lemma":"niquer","pos":"VER"} ,
		{"word":"nitratant","word_nosc":"nitratant","lemma":"nitrater","pos":"VER"} ,
		{"word":"nivelait","word_nosc":"nivelait","lemma":"niveler","pos":"VER"} ,
		{"word":"nivelant","word_nosc":"nivelant","lemma":"niveler","pos":"VER"} ,
		{"word":"niveler","word_nosc":"niveler","lemma":"niveler","pos":"VER"} ,
		{"word":"nivelle","word_nosc":"nivelle","lemma":"niveler","pos":"VER"} ,
		{"word":"nivellent","word_nosc":"nivellent","lemma":"niveler","pos":"VER"} ,
		{"word":"nivelât","word_nosc":"nivelat","lemma":"niveler","pos":"VER"} ,
		{"word":"nivelé","word_nosc":"nivele","lemma":"niveler","pos":"VER"} ,
		{"word":"nivelée","word_nosc":"nivelee","lemma":"niveler","pos":"VER"} ,
		{"word":"nivelées","word_nosc":"nivelees","lemma":"niveler","pos":"VER"} ,
		{"word":"nivelés","word_nosc":"niveles","lemma":"niveler","pos":"VER"} ,
		{"word":"nièrent","word_nosc":"nierent","lemma":"nier","pos":"VER"} ,
		{"word":"nié","word_nosc":"nie","lemma":"nier","pos":"VER"} ,
		{"word":"niée","word_nosc":"niee","lemma":"nier","pos":"VER"} ,
		{"word":"niés","word_nosc":"nies","lemma":"nier","pos":"VER"} ,
		{"word":"nocer","word_nosc":"nocer","lemma":"nocer","pos":"VER"} ,
		{"word":"noie","word_nosc":"noie","lemma":"noyer","pos":"VER"} ,
		{"word":"noient","word_nosc":"noient","lemma":"noyer","pos":"VER"} ,
		{"word":"noiera","word_nosc":"noiera","lemma":"noyer","pos":"VER"} ,
		{"word":"noierai","word_nosc":"noierai","lemma":"noyer","pos":"VER"} ,
		{"word":"noieraient","word_nosc":"noieraient","lemma":"noyer","pos":"VER"} ,
		{"word":"noierait","word_nosc":"noierait","lemma":"noyer","pos":"VER"} ,
		{"word":"noieras","word_nosc":"noieras","lemma":"noyer","pos":"VER"} ,
		{"word":"noierez","word_nosc":"noierez","lemma":"noyer","pos":"VER"} ,
		{"word":"noieront","word_nosc":"noieront","lemma":"noyer","pos":"VER"} ,
		{"word":"noies","word_nosc":"noies","lemma":"noyer","pos":"VER"} ,
		{"word":"noirci","word_nosc":"noirci","lemma":"noircir","pos":"VER"} ,
		{"word":"noircie","word_nosc":"noircie","lemma":"noircir","pos":"VER"} ,
		{"word":"noircies","word_nosc":"noircies","lemma":"noircir","pos":"VER"} ,
		{"word":"noircir","word_nosc":"noircir","lemma":"noircir","pos":"VER"} ,
		{"word":"noircirai","word_nosc":"noircirai","lemma":"noircir","pos":"VER"} ,
		{"word":"noircirais","word_nosc":"noircirais","lemma":"noircir","pos":"VER"} ,
		{"word":"noircirait","word_nosc":"noircirait","lemma":"noircir","pos":"VER"} ,
		{"word":"noircirent","word_nosc":"noircirent","lemma":"noircir","pos":"VER"} ,
		{"word":"noircis","word_nosc":"noircis","lemma":"noircir","pos":"VER"} ,
		{"word":"noircissaient","word_nosc":"noircissaient","lemma":"noircir","pos":"VER"} ,
		{"word":"noircissais","word_nosc":"noircissais","lemma":"noircir","pos":"VER"} ,
		{"word":"noircissait","word_nosc":"noircissait","lemma":"noircir","pos":"VER"} ,
		{"word":"noircissant","word_nosc":"noircissant","lemma":"noircir","pos":"VER"} ,
		{"word":"noircisse","word_nosc":"noircisse","lemma":"noircir","pos":"VER"} ,
		{"word":"noircissent","word_nosc":"noircissent","lemma":"noircir","pos":"VER"} ,
		{"word":"noircissez","word_nosc":"noircissez","lemma":"noircir","pos":"VER"} ,
		{"word":"noircit","word_nosc":"noircit","lemma":"noircir","pos":"VER"} ,
		{"word":"nomadisait","word_nosc":"nomadisait","lemma":"nomadiser","pos":"VER"} ,
		{"word":"nomadisant","word_nosc":"nomadisant","lemma":"nomadiser","pos":"VER"} ,
		{"word":"nomadisent","word_nosc":"nomadisent","lemma":"nomadiser","pos":"VER"} ,
		{"word":"nomine","word_nosc":"nomine","lemma":"nominer","pos":"VER"} ,
		{"word":"nominer","word_nosc":"nominer","lemma":"nominer","pos":"VER"} ,
		{"word":"nominé","word_nosc":"nomine","lemma":"nominer","pos":"VER"} ,
		{"word":"nominée","word_nosc":"nominee","lemma":"nominer","pos":"VER"} ,
		{"word":"nominées","word_nosc":"nominees","lemma":"nominer","pos":"VER"} ,
		{"word":"nominés","word_nosc":"nomines","lemma":"nominer","pos":"VER"} ,
		{"word":"nomma","word_nosc":"nomma","lemma":"nommer","pos":"VER"} ,
		{"word":"nommai","word_nosc":"nommai","lemma":"nommer","pos":"VER"} ,
		{"word":"nommaient","word_nosc":"nommaient","lemma":"nommer","pos":"VER"} ,
		{"word":"nommais","word_nosc":"nommais","lemma":"nommer","pos":"VER"} ,
		{"word":"nommait","word_nosc":"nommait","lemma":"nommer","pos":"VER"} ,
		{"word":"nommant","word_nosc":"nommant","lemma":"nommer","pos":"VER"} ,
		{"word":"nomme","word_nosc":"nomme","lemma":"nommer","pos":"VER"} ,
		{"word":"nomment","word_nosc":"nomment","lemma":"nommer","pos":"VER"} ,
		{"word":"nommer","word_nosc":"nommer","lemma":"nommer","pos":"VER"} ,
		{"word":"nommera","word_nosc":"nommera","lemma":"nommer","pos":"VER"} ,
		{"word":"nommerai","word_nosc":"nommerai","lemma":"nommer","pos":"VER"} ,
		{"word":"nommerais","word_nosc":"nommerais","lemma":"nommer","pos":"VER"} ,
		{"word":"nommerait","word_nosc":"nommerait","lemma":"nommer","pos":"VER"} ,
		{"word":"nommeras","word_nosc":"nommeras","lemma":"nommer","pos":"VER"} ,
		{"word":"nommeriez","word_nosc":"nommeriez","lemma":"nommer","pos":"VER"} ,
		{"word":"nommerons","word_nosc":"nommerons","lemma":"nommer","pos":"VER"} ,
		{"word":"nommeront","word_nosc":"nommeront","lemma":"nommer","pos":"VER"} ,
		{"word":"nommes","word_nosc":"nommes","lemma":"nommer","pos":"VER"} ,
		{"word":"nommez","word_nosc":"nommez","lemma":"nommer","pos":"VER"} ,
		{"word":"nommiez","word_nosc":"nommiez","lemma":"nommer","pos":"VER"} ,
		{"word":"nommions","word_nosc":"nommions","lemma":"nommer","pos":"VER"} ,
		{"word":"nommons","word_nosc":"nommons","lemma":"nommer","pos":"VER"} ,
		{"word":"nommât","word_nosc":"nommat","lemma":"nommer","pos":"VER"} ,
		{"word":"nommèrent","word_nosc":"nommerent","lemma":"nommer","pos":"VER"} ,
		{"word":"nommé","word_nosc":"nomme","lemma":"nommer","pos":"VER"} ,
		{"word":"nommée","word_nosc":"nommee","lemma":"nommer","pos":"VER"} ,
		{"word":"nommées","word_nosc":"nommees","lemma":"nommer","pos":"VER"} ,
		{"word":"nommés","word_nosc":"nommes","lemma":"nommer","pos":"VER"} ,
		{"word":"non-savoir","word_nosc":"non-savoir","lemma":"non-savoir","pos":"VER"} ,
		{"word":"normalise","word_nosc":"normalise","lemma":"normaliser","pos":"VER"} ,
		{"word":"normalisent","word_nosc":"normalisent","lemma":"normaliser","pos":"VER"} ,
		{"word":"normaliser","word_nosc":"normaliser","lemma":"normaliser","pos":"VER"} ,
		{"word":"normalisons","word_nosc":"normalisons","lemma":"normaliser","pos":"VER"} ,
		{"word":"normalisée","word_nosc":"normalisee","lemma":"normaliser","pos":"VER"} ,
		{"word":"normalisées","word_nosc":"normalisees","lemma":"normaliser","pos":"VER"} ,
		{"word":"normalisés","word_nosc":"normalises","lemma":"normaliser","pos":"VER"} ,
		{"word":"nota","word_nosc":"nota","lemma":"noter","pos":"VER"} ,
		{"word":"notai","word_nosc":"notai","lemma":"noter","pos":"VER"} ,
		{"word":"notaient","word_nosc":"notaient","lemma":"noter","pos":"VER"} ,
		{"word":"notais","word_nosc":"notais","lemma":"noter","pos":"VER"} ,
		{"word":"notait","word_nosc":"notait","lemma":"noter","pos":"VER"} ,
		{"word":"notant","word_nosc":"notant","lemma":"noter","pos":"VER"} ,
		{"word":"notarié","word_nosc":"notarie","lemma":"notarier","pos":"VER"} ,
		{"word":"notariées","word_nosc":"notariees","lemma":"notarier","pos":"VER"} ,
		{"word":"notariés","word_nosc":"notaries","lemma":"notarier","pos":"VER"} ,
		{"word":"note","word_nosc":"note","lemma":"noter","pos":"VER"} ,
		{"word":"notent","word_nosc":"notent","lemma":"noter","pos":"VER"} ,
		{"word":"noter","word_nosc":"noter","lemma":"noter","pos":"VER"} ,
		{"word":"notera","word_nosc":"notera","lemma":"noter","pos":"VER"} ,
		{"word":"noterai","word_nosc":"noterai","lemma":"noter","pos":"VER"} ,
		{"word":"noterais","word_nosc":"noterais","lemma":"noter","pos":"VER"} ,
		{"word":"noterait","word_nosc":"noterait","lemma":"noter","pos":"VER"} ,
		{"word":"noteras","word_nosc":"noteras","lemma":"noter","pos":"VER"} ,
		{"word":"noterez","word_nosc":"noterez","lemma":"noter","pos":"VER"} ,
		{"word":"noterons","word_nosc":"noterons","lemma":"noter","pos":"VER"} ,
		{"word":"noteront","word_nosc":"noteront","lemma":"noter","pos":"VER"} ,
		{"word":"notez","word_nosc":"notez","lemma":"noter","pos":"VER"} ,
		{"word":"notiez","word_nosc":"notiez","lemma":"noter","pos":"VER"} ,
		{"word":"notifia","word_nosc":"notifia","lemma":"notifier","pos":"VER"} ,
		{"word":"notifiai","word_nosc":"notifiai","lemma":"notifier","pos":"VER"} ,
		{"word":"notifiaient","word_nosc":"notifiaient","lemma":"notifier","pos":"VER"} ,
		{"word":"notifiait","word_nosc":"notifiait","lemma":"notifier","pos":"VER"} ,
		{"word":"notifiant","word_nosc":"notifiant","lemma":"notifier","pos":"VER"} ,
		{"word":"notifier","word_nosc":"notifier","lemma":"notifier","pos":"VER"} ,
		{"word":"notifiez","word_nosc":"notifiez","lemma":"notifier","pos":"VER"} ,
		{"word":"notifions","word_nosc":"notifions","lemma":"notifier","pos":"VER"} ,
		{"word":"notifié","word_nosc":"notifie","lemma":"notifier","pos":"VER"} ,
		{"word":"notifiée","word_nosc":"notifiee","lemma":"notifier","pos":"VER"} ,
		{"word":"notifiées","word_nosc":"notifiees","lemma":"notifier","pos":"VER"} ,
		{"word":"notions","word_nosc":"notions","lemma":"noter","pos":"VER"} ,
		{"word":"notons","word_nosc":"notons","lemma":"noter","pos":"VER"} ,
		{"word":"notèrent","word_nosc":"noterent","lemma":"noter","pos":"VER"} ,
		{"word":"noté","word_nosc":"note","lemma":"noter","pos":"VER"} ,
		{"word":"notée","word_nosc":"notee","lemma":"noter","pos":"VER"} ,
		{"word":"notées","word_nosc":"notees","lemma":"noter","pos":"VER"} ,
		{"word":"notés","word_nosc":"notes","lemma":"noter","pos":"VER"} ,
		{"word":"noua","word_nosc":"noua","lemma":"nouer","pos":"VER"} ,
		{"word":"nouai","word_nosc":"nouai","lemma":"nouer","pos":"VER"} ,
		{"word":"nouaient","word_nosc":"nouaient","lemma":"nouer","pos":"VER"} ,
		{"word":"nouais","word_nosc":"nouais","lemma":"nouer","pos":"VER"} ,
		{"word":"nouait","word_nosc":"nouait","lemma":"nouer","pos":"VER"} ,
		{"word":"nouant","word_nosc":"nouant","lemma":"nouer","pos":"VER"} ,
		{"word":"nouas","word_nosc":"nouas","lemma":"nouer","pos":"VER"} ,
		{"word":"noue","word_nosc":"noue","lemma":"nouer","pos":"VER"} ,
		{"word":"nouent","word_nosc":"nouent","lemma":"nouer","pos":"VER"} ,
		{"word":"nouer","word_nosc":"nouer","lemma":"nouer","pos":"VER"} ,
		{"word":"nouera","word_nosc":"nouera","lemma":"nouer","pos":"VER"} ,
		{"word":"nouerais","word_nosc":"nouerais","lemma":"nouer","pos":"VER"} ,
		{"word":"nouerait","word_nosc":"nouerait","lemma":"nouer","pos":"VER"} ,
		{"word":"nouerez","word_nosc":"nouerez","lemma":"nouer","pos":"VER"} ,
		{"word":"noueront","word_nosc":"noueront","lemma":"nouer","pos":"VER"} ,
		{"word":"nouez","word_nosc":"nouez","lemma":"nouer","pos":"VER"} ,
		{"word":"nourri","word_nosc":"nourri","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrie","word_nosc":"nourrie","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourries","word_nosc":"nourries","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrir","word_nosc":"nourrir","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrira","word_nosc":"nourrira","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrirai","word_nosc":"nourrirai","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourriraient","word_nosc":"nourriraient","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrirais","word_nosc":"nourrirais","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrirait","word_nosc":"nourrirait","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourriras","word_nosc":"nourriras","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrirent","word_nosc":"nourrirent","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrirez","word_nosc":"nourrirez","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourririons","word_nosc":"nourririons","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrirons","word_nosc":"nourrirons","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourriront","word_nosc":"nourriront","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourris","word_nosc":"nourris","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrissaient","word_nosc":"nourrissaient","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrissais","word_nosc":"nourrissais","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrissait","word_nosc":"nourrissait","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrissant","word_nosc":"nourrissant","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrisse","word_nosc":"nourrisse","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrissent","word_nosc":"nourrissent","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrisses","word_nosc":"nourrisses","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrissez","word_nosc":"nourrissez","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrissiez","word_nosc":"nourrissiez","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrissions","word_nosc":"nourrissions","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrissons","word_nosc":"nourrissons","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrit","word_nosc":"nourrit","lemma":"nourrir","pos":"VER"} ,
		{"word":"nourrît","word_nosc":"nourrit","lemma":"nourrir","pos":"VER"} ,
		{"word":"nouèrent","word_nosc":"nouerent","lemma":"nouer","pos":"VER"} ,
		{"word":"noué","word_nosc":"noue","lemma":"nouer","pos":"VER"} ,
		{"word":"nouée","word_nosc":"nouee","lemma":"nouer","pos":"VER"} ,
		{"word":"nouées","word_nosc":"nouees","lemma":"nouer","pos":"VER"} ,
		{"word":"noués","word_nosc":"noues","lemma":"nouer","pos":"VER"} ,
		{"word":"nova","word_nosc":"nova","lemma":"nover","pos":"VER"} ,
		{"word":"nove","word_nosc":"nove","lemma":"nover","pos":"VER"} ,
		{"word":"noya","word_nosc":"noya","lemma":"noyer","pos":"VER"} ,
		{"word":"noyaient","word_nosc":"noyaient","lemma":"noyer","pos":"VER"} ,
		{"word":"noyais","word_nosc":"noyais","lemma":"noyer","pos":"VER"} ,
		{"word":"noyait","word_nosc":"noyait","lemma":"noyer","pos":"VER"} ,
		{"word":"noyant","word_nosc":"noyant","lemma":"noyer","pos":"VER"} ,
		{"word":"noyauta","word_nosc":"noyauta","lemma":"noyauter","pos":"VER"} ,
		{"word":"noyautant","word_nosc":"noyautant","lemma":"noyauter","pos":"VER"} ,
		{"word":"noyautent","word_nosc":"noyautent","lemma":"noyauter","pos":"VER"} ,
		{"word":"noyauter","word_nosc":"noyauter","lemma":"noyauter","pos":"VER"} ,
		{"word":"noyauté","word_nosc":"noyaute","lemma":"noyauter","pos":"VER"} ,
		{"word":"noyautée","word_nosc":"noyautee","lemma":"noyauter","pos":"VER"} ,
		{"word":"noyautées","word_nosc":"noyautees","lemma":"noyauter","pos":"VER"} ,
		{"word":"noyer","word_nosc":"noyer","lemma":"noyer","pos":"VER"} ,
		{"word":"noyez","word_nosc":"noyez","lemma":"noyer","pos":"VER"} ,
		{"word":"noyions","word_nosc":"noyions","lemma":"noyer","pos":"VER"} ,
		{"word":"noyons","word_nosc":"noyons","lemma":"noyer","pos":"VER"} ,
		{"word":"noyèrent","word_nosc":"noyerent","lemma":"noyer","pos":"VER"} ,
		{"word":"noyé","word_nosc":"noye","lemma":"noyer","pos":"VER"} ,
		{"word":"noyée","word_nosc":"noyee","lemma":"noyer","pos":"VER"} ,
		{"word":"noyées","word_nosc":"noyees","lemma":"noyer","pos":"VER"} ,
		{"word":"noyés","word_nosc":"noyes","lemma":"noyer","pos":"VER"} ,
		{"word":"nuance","word_nosc":"nuance","lemma":"nuancer","pos":"VER"} ,
		{"word":"nuancer","word_nosc":"nuancer","lemma":"nuancer","pos":"VER"} ,
		{"word":"nuancé","word_nosc":"nuance","lemma":"nuancer","pos":"VER"} ,
		{"word":"nuancée","word_nosc":"nuancee","lemma":"nuancer","pos":"VER"} ,
		{"word":"nuancées","word_nosc":"nuancees","lemma":"nuancer","pos":"VER"} ,
		{"word":"nuancés","word_nosc":"nuances","lemma":"nuancer","pos":"VER"} ,
		{"word":"nuança","word_nosc":"nuanca","lemma":"nuancer","pos":"VER"} ,
		{"word":"nuançaient","word_nosc":"nuancaient","lemma":"nuancer","pos":"VER"} ,
		{"word":"nuançait","word_nosc":"nuancait","lemma":"nuancer","pos":"VER"} ,
		{"word":"nuançant","word_nosc":"nuancant","lemma":"nuancer","pos":"VER"} ,
		{"word":"nuançât","word_nosc":"nuancat","lemma":"nuancer","pos":"VER"} ,
		{"word":"nuas","word_nosc":"nuas","lemma":"nuer","pos":"VER"} ,
		{"word":"nue","word_nosc":"nue","lemma":"nu","pos":"VER"} ,
		{"word":"nuer","word_nosc":"nuer","lemma":"nuer","pos":"VER"} ,
		{"word":"nui","word_nosc":"nui","lemma":"nuire","pos":"VER"} ,
		{"word":"nuira","word_nosc":"nuira","lemma":"nuire","pos":"VER"} ,
		{"word":"nuirai","word_nosc":"nuirai","lemma":"nuire","pos":"VER"} ,
		{"word":"nuirait","word_nosc":"nuirait","lemma":"nuire","pos":"VER"} ,
		{"word":"nuiras","word_nosc":"nuiras","lemma":"nuire","pos":"VER"} ,
		{"word":"nuire","word_nosc":"nuire","lemma":"nuire","pos":"VER"} ,
		{"word":"nuiront","word_nosc":"nuiront","lemma":"nuire","pos":"VER"} ,
		{"word":"nuis","word_nosc":"nuis","lemma":"nuire","pos":"VER"} ,
		{"word":"nuisaient","word_nosc":"nuisaient","lemma":"nuire","pos":"VER"} ,
		{"word":"nuisait","word_nosc":"nuisait","lemma":"nuire","pos":"VER"} ,
		{"word":"nuisant","word_nosc":"nuisant","lemma":"nuire","pos":"VER"} ,
		{"word":"nuise","word_nosc":"nuise","lemma":"nuire","pos":"VER"} ,
		{"word":"nuisent","word_nosc":"nuisent","lemma":"nuire","pos":"VER"} ,
		{"word":"nuisez","word_nosc":"nuisez","lemma":"nuire","pos":"VER"} ,
		{"word":"nuisît","word_nosc":"nuisit","lemma":"nuire","pos":"VER"} ,
		{"word":"nuit","word_nosc":"nuit","lemma":"nuire","pos":"VER"} ,
		{"word":"numérise","word_nosc":"numerise","lemma":"numériser","pos":"VER"} ,
		{"word":"numériser","word_nosc":"numeriser","lemma":"numériser","pos":"VER"} ,
		{"word":"numérisez","word_nosc":"numerisez","lemma":"numériser","pos":"VER"} ,
		{"word":"numérisé","word_nosc":"numerise","lemma":"numériser","pos":"VER"} ,
		{"word":"numérisée","word_nosc":"numerisee","lemma":"numériser","pos":"VER"} ,
		{"word":"numérisées","word_nosc":"numerisees","lemma":"numériser","pos":"VER"} ,
		{"word":"numérota","word_nosc":"numerota","lemma":"numéroter","pos":"VER"} ,
		{"word":"numérotaient","word_nosc":"numerotaient","lemma":"numéroter","pos":"VER"} ,
		{"word":"numérotait","word_nosc":"numerotait","lemma":"numéroter","pos":"VER"} ,
		{"word":"numérote","word_nosc":"numerote","lemma":"numéroter","pos":"VER"} ,
		{"word":"numérotent","word_nosc":"numerotent","lemma":"numéroter","pos":"VER"} ,
		{"word":"numéroter","word_nosc":"numeroter","lemma":"numéroter","pos":"VER"} ,
		{"word":"numérotez","word_nosc":"numerotez","lemma":"numéroter","pos":"VER"} ,
		{"word":"numéroté","word_nosc":"numerote","lemma":"numéroter","pos":"VER"} ,
		{"word":"numérotée","word_nosc":"numerotee","lemma":"numéroter","pos":"VER"} ,
		{"word":"numérotées","word_nosc":"numerotees","lemma":"numéroter","pos":"VER"} ,
		{"word":"numérotés","word_nosc":"numerotes","lemma":"numéroter","pos":"VER"} ,
		{"word":"nuées","word_nosc":"nuees","lemma":"nuer","pos":"VER"} ,
		{"word":"né","word_nosc":"ne","lemma":"naître","pos":"VER"} ,
		{"word":"néantisée","word_nosc":"neantisee","lemma":"néantiser","pos":"VER"} ,
		{"word":"nécessita","word_nosc":"necessita","lemma":"nécessiter","pos":"VER"} ,
		{"word":"nécessitaient","word_nosc":"necessitaient","lemma":"nécessiter","pos":"VER"} ,
		{"word":"nécessitait","word_nosc":"necessitait","lemma":"nécessiter","pos":"VER"} ,
		{"word":"nécessitant","word_nosc":"necessitant","lemma":"nécessiter","pos":"VER"} ,
		{"word":"nécessite","word_nosc":"necessite","lemma":"nécessiter","pos":"VER"} ,
		{"word":"nécessitent","word_nosc":"necessitent","lemma":"nécessiter","pos":"VER"} ,
		{"word":"nécessiter","word_nosc":"necessiter","lemma":"nécessiter","pos":"VER"} ,
		{"word":"nécessitera","word_nosc":"necessitera","lemma":"nécessiter","pos":"VER"} ,
		{"word":"nécessiterait","word_nosc":"necessiterait","lemma":"nécessiter","pos":"VER"} ,
		{"word":"nécessité","word_nosc":"necessite","lemma":"nécessiter","pos":"VER"} ,
		{"word":"nécessitée","word_nosc":"necessitee","lemma":"nécessiter","pos":"VER"} ,
		{"word":"nécessitées","word_nosc":"necessitees","lemma":"nécessiter","pos":"VER"} ,
		{"word":"nécessités","word_nosc":"necessites","lemma":"nécessiter","pos":"VER"} ,
		{"word":"nécrosant","word_nosc":"necrosant","lemma":"nécroser","pos":"VER"} ,
		{"word":"nécroser","word_nosc":"necroser","lemma":"nécroser","pos":"VER"} ,
		{"word":"nécrosé","word_nosc":"necrose","lemma":"nécroser","pos":"VER"} ,
		{"word":"nécrosée","word_nosc":"necrosee","lemma":"nécroser","pos":"VER"} ,
		{"word":"née","word_nosc":"nee","lemma":"naître","pos":"VER"} ,
		{"word":"nées","word_nosc":"nees","lemma":"naître","pos":"VER"} ,
		{"word":"néglige","word_nosc":"neglige","lemma":"négliger","pos":"VER"} ,
		{"word":"négligea","word_nosc":"negligea","lemma":"négliger","pos":"VER"} ,
		{"word":"négligeai","word_nosc":"negligeai","lemma":"négliger","pos":"VER"} ,
		{"word":"négligeaient","word_nosc":"negligeaient","lemma":"négliger","pos":"VER"} ,
		{"word":"négligeais","word_nosc":"negligeais","lemma":"négliger","pos":"VER"} ,
		{"word":"négligeait","word_nosc":"negligeait","lemma":"négliger","pos":"VER"} ,
		{"word":"négligeant","word_nosc":"negligeant","lemma":"négliger","pos":"VER"} ,
		{"word":"négligent","word_nosc":"negligent","lemma":"négliger","pos":"VER"} ,
		{"word":"négligeons","word_nosc":"negligeons","lemma":"négliger","pos":"VER"} ,
		{"word":"négliger","word_nosc":"negliger","lemma":"négliger","pos":"VER"} ,
		{"word":"négligera","word_nosc":"negligera","lemma":"négliger","pos":"VER"} ,
		{"word":"négligerai","word_nosc":"negligerai","lemma":"négliger","pos":"VER"} ,
		{"word":"négligerait","word_nosc":"negligerait","lemma":"négliger","pos":"VER"} ,
		{"word":"négligeriez","word_nosc":"negligeriez","lemma":"négliger","pos":"VER"} ,
		{"word":"négliges","word_nosc":"negliges","lemma":"négliger","pos":"VER"} ,
		{"word":"négligez","word_nosc":"negligez","lemma":"négliger","pos":"VER"} ,
		{"word":"négligeât","word_nosc":"negligeat","lemma":"négliger","pos":"VER"} ,
		{"word":"négligiez","word_nosc":"negligiez","lemma":"négliger","pos":"VER"} ,
		{"word":"négligions","word_nosc":"negligions","lemma":"négliger","pos":"VER"} ,
		{"word":"négligèrent","word_nosc":"negligerent","lemma":"négliger","pos":"VER"} ,
		{"word":"négligé","word_nosc":"neglige","lemma":"négliger","pos":"VER"} ,
		{"word":"négligée","word_nosc":"negligee","lemma":"négliger","pos":"VER"} ,
		{"word":"négligées","word_nosc":"negligees","lemma":"négliger","pos":"VER"} ,
		{"word":"négligés","word_nosc":"negliges","lemma":"négliger","pos":"VER"} ,
		{"word":"négocia","word_nosc":"negocia","lemma":"négocier","pos":"VER"} ,
		{"word":"négociaient","word_nosc":"negociaient","lemma":"négocier","pos":"VER"} ,
		{"word":"négociais","word_nosc":"negociais","lemma":"négocier","pos":"VER"} ,
		{"word":"négociait","word_nosc":"negociait","lemma":"négocier","pos":"VER"} ,
		{"word":"négociant","word_nosc":"negociant","lemma":"négocier","pos":"VER"} ,
		{"word":"négocie","word_nosc":"negocie","lemma":"négocier","pos":"VER"} ,
		{"word":"négocient","word_nosc":"negocient","lemma":"négocier","pos":"VER"} ,
		{"word":"négocier","word_nosc":"negocier","lemma":"négocier","pos":"VER"} ,
		{"word":"négociera","word_nosc":"negociera","lemma":"négocier","pos":"VER"} ,
		{"word":"négocierai","word_nosc":"negocierai","lemma":"négocier","pos":"VER"} ,
		{"word":"négocieraient","word_nosc":"negocieraient","lemma":"négocier","pos":"VER"} ,
		{"word":"négocierions","word_nosc":"negocierions","lemma":"négocier","pos":"VER"} ,
		{"word":"négocierons","word_nosc":"negocierons","lemma":"négocier","pos":"VER"} ,
		{"word":"négocieront","word_nosc":"negocieront","lemma":"négocier","pos":"VER"} ,
		{"word":"négociez","word_nosc":"negociez","lemma":"négocier","pos":"VER"} ,
		{"word":"négocions","word_nosc":"negocions","lemma":"négocier","pos":"VER"} ,
		{"word":"négocié","word_nosc":"negocie","lemma":"négocier","pos":"VER"} ,
		{"word":"négociée","word_nosc":"negociee","lemma":"négocier","pos":"VER"} ,
		{"word":"négociées","word_nosc":"negociees","lemma":"négocier","pos":"VER"} ,
		{"word":"négociés","word_nosc":"negocies","lemma":"négocier","pos":"VER"} ,
		{"word":"nés","word_nosc":"nes","lemma":"naître","pos":"VER"} ,
		{"word":"objecta","word_nosc":"objecta","lemma":"objecter","pos":"VER"} ,
		{"word":"objectai","word_nosc":"objectai","lemma":"objecter","pos":"VER"} ,
		{"word":"objectais","word_nosc":"objectais","lemma":"objecter","pos":"VER"} ,
		{"word":"objectait","word_nosc":"objectait","lemma":"objecter","pos":"VER"} ,
		{"word":"objectant","word_nosc":"objectant","lemma":"objecter","pos":"VER"} ,
		{"word":"objecte","word_nosc":"objecte","lemma":"objecter","pos":"VER"} ,
		{"word":"objectent","word_nosc":"objectent","lemma":"objecter","pos":"VER"} ,
		{"word":"objecter","word_nosc":"objecter","lemma":"objecter","pos":"VER"} ,
		{"word":"objectera","word_nosc":"objectera","lemma":"objecter","pos":"VER"} ,
		{"word":"objecterez","word_nosc":"objecterez","lemma":"objecter","pos":"VER"} ,
		{"word":"objecteront","word_nosc":"objecteront","lemma":"objecter","pos":"VER"} ,
		{"word":"objectez","word_nosc":"objectez","lemma":"objecter","pos":"VER"} ,
		{"word":"objections","word_nosc":"objections","lemma":"objecter","pos":"VER"} ,
		{"word":"objective","word_nosc":"objective","lemma":"objectiver","pos":"VER"} ,
		{"word":"objectivent","word_nosc":"objectivent","lemma":"objectiver","pos":"VER"} ,
		{"word":"objectèrent","word_nosc":"objecterent","lemma":"objecter","pos":"VER"} ,
		{"word":"objecté","word_nosc":"objecte","lemma":"objecter","pos":"VER"} ,
		{"word":"objurgua","word_nosc":"objurgua","lemma":"objurguer","pos":"VER"} ,
		{"word":"objurguant","word_nosc":"objurguant","lemma":"objurguer","pos":"VER"} ,
		{"word":"oblige","word_nosc":"oblige","lemma":"obliger","pos":"VER"} ,
		{"word":"obligea","word_nosc":"obligea","lemma":"obliger","pos":"VER"} ,
		{"word":"obligeai","word_nosc":"obligeai","lemma":"obliger","pos":"VER"} ,
		{"word":"obligeaient","word_nosc":"obligeaient","lemma":"obliger","pos":"VER"} ,
		{"word":"obligeais","word_nosc":"obligeais","lemma":"obliger","pos":"VER"} ,
		{"word":"obligeait","word_nosc":"obligeait","lemma":"obliger","pos":"VER"} ,
		{"word":"obligeant","word_nosc":"obligeant","lemma":"obliger","pos":"VER"} ,
		{"word":"obligent","word_nosc":"obligent","lemma":"obliger","pos":"VER"} ,
		{"word":"obligeons","word_nosc":"obligeons","lemma":"obliger","pos":"VER"} ,
		{"word":"obliger","word_nosc":"obliger","lemma":"obliger","pos":"VER"} ,
		{"word":"obligera","word_nosc":"obligera","lemma":"obliger","pos":"VER"} ,
		{"word":"obligerai","word_nosc":"obligerai","lemma":"obliger","pos":"VER"} ,
		{"word":"obligerais","word_nosc":"obligerais","lemma":"obliger","pos":"VER"} ,
		{"word":"obligerait","word_nosc":"obligerait","lemma":"obliger","pos":"VER"} ,
		{"word":"obligeras","word_nosc":"obligeras","lemma":"obliger","pos":"VER"} ,
		{"word":"obligerez","word_nosc":"obligerez","lemma":"obliger","pos":"VER"} ,
		{"word":"obligeriez","word_nosc":"obligeriez","lemma":"obliger","pos":"VER"} ,
		{"word":"obligeront","word_nosc":"obligeront","lemma":"obliger","pos":"VER"} ,
		{"word":"obliges","word_nosc":"obliges","lemma":"obliger","pos":"VER"} ,
		{"word":"obligez","word_nosc":"obligez","lemma":"obliger","pos":"VER"} ,
		{"word":"obligeât","word_nosc":"obligeat","lemma":"obliger","pos":"VER"} ,
		{"word":"obligiez","word_nosc":"obligiez","lemma":"obliger","pos":"VER"} ,
		{"word":"obligèrent","word_nosc":"obligerent","lemma":"obliger","pos":"VER"} ,
		{"word":"obligé","word_nosc":"oblige","lemma":"obliger","pos":"VER"} ,
		{"word":"obligée","word_nosc":"obligee","lemma":"obliger","pos":"VER"} ,
		{"word":"obligées","word_nosc":"obligees","lemma":"obliger","pos":"VER"} ,
		{"word":"obligés","word_nosc":"obliges","lemma":"obliger","pos":"VER"} ,
		{"word":"obliqua","word_nosc":"obliqua","lemma":"obliquer","pos":"VER"} ,
		{"word":"obliquaient","word_nosc":"obliquaient","lemma":"obliquer","pos":"VER"} ,
		{"word":"obliquait","word_nosc":"obliquait","lemma":"obliquer","pos":"VER"} ,
		{"word":"obliquant","word_nosc":"obliquant","lemma":"obliquer","pos":"VER"} ,
		{"word":"oblique","word_nosc":"oblique","lemma":"obliquer","pos":"VER"} ,
		{"word":"obliquent","word_nosc":"obliquent","lemma":"obliquer","pos":"VER"} ,
		{"word":"obliquer","word_nosc":"obliquer","lemma":"obliquer","pos":"VER"} ,
		{"word":"obliquez","word_nosc":"obliquez","lemma":"obliquer","pos":"VER"} ,
		{"word":"obliquâmes","word_nosc":"obliquames","lemma":"obliquer","pos":"VER"} ,
		{"word":"obliquèrent","word_nosc":"obliquerent","lemma":"obliquer","pos":"VER"} ,
		{"word":"obliqué","word_nosc":"oblique","lemma":"obliquer","pos":"VER"} ,
		{"word":"oblitère","word_nosc":"oblitere","lemma":"oblitérer","pos":"VER"} ,
		{"word":"oblitèrent","word_nosc":"obliterent","lemma":"oblitérer","pos":"VER"} ,
		{"word":"oblitéraient","word_nosc":"obliteraient","lemma":"oblitérer","pos":"VER"} ,
		{"word":"oblitérait","word_nosc":"obliterait","lemma":"oblitérer","pos":"VER"} ,
		{"word":"oblitérer","word_nosc":"obliterer","lemma":"oblitérer","pos":"VER"} ,
		{"word":"oblitérons","word_nosc":"obliterons","lemma":"oblitérer","pos":"VER"} ,
		{"word":"oblitérèrent","word_nosc":"oblitererent","lemma":"oblitérer","pos":"VER"} ,
		{"word":"oblitéré","word_nosc":"oblitere","lemma":"oblitérer","pos":"VER"} ,
		{"word":"oblitérée","word_nosc":"obliteree","lemma":"oblitérer","pos":"VER"} ,
		{"word":"oblitérées","word_nosc":"obliterees","lemma":"oblitérer","pos":"VER"} ,
		{"word":"oblitérés","word_nosc":"obliteres","lemma":"oblitérer","pos":"VER"} ,
		{"word":"obnubila","word_nosc":"obnubila","lemma":"obnubiler","pos":"VER"} ,
		{"word":"obnubilait","word_nosc":"obnubilait","lemma":"obnubiler","pos":"VER"} ,
		{"word":"obnubilant","word_nosc":"obnubilant","lemma":"obnubiler","pos":"VER"} ,
		{"word":"obnubile","word_nosc":"obnubile","lemma":"obnubiler","pos":"VER"} ,
		{"word":"obnubiler","word_nosc":"obnubiler","lemma":"obnubiler","pos":"VER"} ,
		{"word":"obnubilé","word_nosc":"obnubile","lemma":"obnubiler","pos":"VER"} ,
		{"word":"obnubilée","word_nosc":"obnubilee","lemma":"obnubiler","pos":"VER"} ,
		{"word":"obnubilées","word_nosc":"obnubilees","lemma":"obnubiler","pos":"VER"} ,
		{"word":"obnubilés","word_nosc":"obnubiles","lemma":"obnubiler","pos":"VER"} ,
		{"word":"obscurci","word_nosc":"obscurci","lemma":"obscurcir","pos":"VER"} ,
		{"word":"obscurcie","word_nosc":"obscurcie","lemma":"obscurcir","pos":"VER"} ,
		{"word":"obscurcies","word_nosc":"obscurcies","lemma":"obscurcir","pos":"VER"} ,
		{"word":"obscurcir","word_nosc":"obscurcir","lemma":"obscurcir","pos":"VER"} ,
		{"word":"obscurcira","word_nosc":"obscurcira","lemma":"obscurcir","pos":"VER"} ,
		{"word":"obscurcirait","word_nosc":"obscurcirait","lemma":"obscurcir","pos":"VER"} ,
		{"word":"obscurcis","word_nosc":"obscurcis","lemma":"obscurcir","pos":"VER"} ,
		{"word":"obscurcissaient","word_nosc":"obscurcissaient","lemma":"obscurcir","pos":"VER"} ,
		{"word":"obscurcissait","word_nosc":"obscurcissait","lemma":"obscurcir","pos":"VER"} ,
		{"word":"obscurcissant","word_nosc":"obscurcissant","lemma":"obscurcir","pos":"VER"} ,
		{"word":"obscurcissent","word_nosc":"obscurcissent","lemma":"obscurcir","pos":"VER"} ,
		{"word":"obscurcit","word_nosc":"obscurcit","lemma":"obscurcir","pos":"VER"} ,
		{"word":"obscurcît","word_nosc":"obscurcit","lemma":"obscurcir","pos":"VER"} ,
		{"word":"observa","word_nosc":"observa","lemma":"observer","pos":"VER"} ,
		{"word":"observai","word_nosc":"observai","lemma":"observer","pos":"VER"} ,
		{"word":"observaient","word_nosc":"observaient","lemma":"observer","pos":"VER"} ,
		{"word":"observais","word_nosc":"observais","lemma":"observer","pos":"VER"} ,
		{"word":"observait","word_nosc":"observait","lemma":"observer","pos":"VER"} ,
		{"word":"observant","word_nosc":"observant","lemma":"observer","pos":"VER"} ,
		{"word":"observe","word_nosc":"observe","lemma":"observer","pos":"VER"} ,
		{"word":"observent","word_nosc":"observent","lemma":"observer","pos":"VER"} ,
		{"word":"observer","word_nosc":"observer","lemma":"observer","pos":"VER"} ,
		{"word":"observera","word_nosc":"observera","lemma":"observer","pos":"VER"} ,
		{"word":"observerai","word_nosc":"observerai","lemma":"observer","pos":"VER"} ,
		{"word":"observeraient","word_nosc":"observeraient","lemma":"observer","pos":"VER"} ,
		{"word":"observerais","word_nosc":"observerais","lemma":"observer","pos":"VER"} ,
		{"word":"observerait","word_nosc":"observerait","lemma":"observer","pos":"VER"} ,
		{"word":"observerez","word_nosc":"observerez","lemma":"observer","pos":"VER"} ,
		{"word":"observerons","word_nosc":"observerons","lemma":"observer","pos":"VER"} ,
		{"word":"observeront","word_nosc":"observeront","lemma":"observer","pos":"VER"} ,
		{"word":"observes","word_nosc":"observes","lemma":"observer","pos":"VER"} ,
		{"word":"observez","word_nosc":"observez","lemma":"observer","pos":"VER"} ,
		{"word":"observiez","word_nosc":"observiez","lemma":"observer","pos":"VER"} ,
		{"word":"observions","word_nosc":"observions","lemma":"observer","pos":"VER"} ,
		{"word":"observons","word_nosc":"observons","lemma":"observer","pos":"VER"} ,
		{"word":"observâmes","word_nosc":"observames","lemma":"observer","pos":"VER"} ,
		{"word":"observèrent","word_nosc":"observerent","lemma":"observer","pos":"VER"} ,
		{"word":"observé","word_nosc":"observe","lemma":"observer","pos":"VER"} ,
		{"word":"observée","word_nosc":"observee","lemma":"observer","pos":"VER"} ,
		{"word":"observées","word_nosc":"observees","lemma":"observer","pos":"VER"} ,
		{"word":"observés","word_nosc":"observes","lemma":"observer","pos":"VER"} ,
		{"word":"obstina","word_nosc":"obstina","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstinai","word_nosc":"obstinai","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstinaient","word_nosc":"obstinaient","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstinais","word_nosc":"obstinais","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstinait","word_nosc":"obstinait","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstinant","word_nosc":"obstinant","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstine","word_nosc":"obstine","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstinent","word_nosc":"obstinent","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstiner","word_nosc":"obstiner","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstinera","word_nosc":"obstinera","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstineraient","word_nosc":"obstineraient","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstinerait","word_nosc":"obstinerait","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstineras","word_nosc":"obstineras","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstinerez","word_nosc":"obstinerez","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstineriez","word_nosc":"obstineriez","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstineront","word_nosc":"obstineront","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstines","word_nosc":"obstines","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstinez","word_nosc":"obstinez","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstinions","word_nosc":"obstinions","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstinons","word_nosc":"obstinons","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstinèrent","word_nosc":"obstinerent","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstiné","word_nosc":"obstine","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstinée","word_nosc":"obstinee","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstinés","word_nosc":"obstines","lemma":"obstiner","pos":"VER"} ,
		{"word":"obstrua","word_nosc":"obstrua","lemma":"obstruer","pos":"VER"} ,
		{"word":"obstruaient","word_nosc":"obstruaient","lemma":"obstruer","pos":"VER"} ,
		{"word":"obstruait","word_nosc":"obstruait","lemma":"obstruer","pos":"VER"} ,
		{"word":"obstruant","word_nosc":"obstruant","lemma":"obstruer","pos":"VER"} ,
		{"word":"obstrue","word_nosc":"obstrue","lemma":"obstruer","pos":"VER"} ,
		{"word":"obstruent","word_nosc":"obstruent","lemma":"obstruer","pos":"VER"} ,
		{"word":"obstruer","word_nosc":"obstruer","lemma":"obstruer","pos":"VER"} ,
		{"word":"obstrueraient","word_nosc":"obstrueraient","lemma":"obstruer","pos":"VER"} ,
		{"word":"obstrueront","word_nosc":"obstrueront","lemma":"obstruer","pos":"VER"} ,
		{"word":"obstruez","word_nosc":"obstruez","lemma":"obstruer","pos":"VER"} ,
		{"word":"obstrué","word_nosc":"obstrue","lemma":"obstruer","pos":"VER"} ,
		{"word":"obstruée","word_nosc":"obstruee","lemma":"obstruer","pos":"VER"} ,
		{"word":"obstruées","word_nosc":"obstruees","lemma":"obstruer","pos":"VER"} ,
		{"word":"obstrués","word_nosc":"obstrues","lemma":"obstruer","pos":"VER"} ,
		{"word":"obsède","word_nosc":"obsede","lemma":"obséder","pos":"VER"} ,
		{"word":"obsèdent","word_nosc":"obsedent","lemma":"obséder","pos":"VER"} ,
		{"word":"obsèdes","word_nosc":"obsedes","lemma":"obséder","pos":"VER"} ,
		{"word":"obséda","word_nosc":"obseda","lemma":"obséder","pos":"VER"} ,
		{"word":"obsédaient","word_nosc":"obsedaient","lemma":"obséder","pos":"VER"} ,
		{"word":"obsédais","word_nosc":"obsedais","lemma":"obséder","pos":"VER"} ,
		{"word":"obsédait","word_nosc":"obsedait","lemma":"obséder","pos":"VER"} ,
		{"word":"obsédant","word_nosc":"obsedant","lemma":"obséder","pos":"VER"} ,
		{"word":"obséder","word_nosc":"obseder","lemma":"obséder","pos":"VER"} ,
		{"word":"obsédèrent","word_nosc":"obsederent","lemma":"obséder","pos":"VER"} ,
		{"word":"obsédé","word_nosc":"obsede","lemma":"obséder","pos":"VER"} ,
		{"word":"obsédée","word_nosc":"obsedee","lemma":"obséder","pos":"VER"} ,
		{"word":"obsédées","word_nosc":"obsedees","lemma":"obséder","pos":"VER"} ,
		{"word":"obsédés","word_nosc":"obsedes","lemma":"obséder","pos":"VER"} ,
		{"word":"obtempère","word_nosc":"obtempere","lemma":"obtempérer","pos":"VER"} ,
		{"word":"obtempéra","word_nosc":"obtempera","lemma":"obtempérer","pos":"VER"} ,
		{"word":"obtempérai","word_nosc":"obtemperai","lemma":"obtempérer","pos":"VER"} ,
		{"word":"obtempéraient","word_nosc":"obtemperaient","lemma":"obtempérer","pos":"VER"} ,
		{"word":"obtempérait","word_nosc":"obtemperait","lemma":"obtempérer","pos":"VER"} ,
		{"word":"obtempérer","word_nosc":"obtemperer","lemma":"obtempérer","pos":"VER"} ,
		{"word":"obtempérez","word_nosc":"obtemperez","lemma":"obtempérer","pos":"VER"} ,
		{"word":"obtempérions","word_nosc":"obtemperions","lemma":"obtempérer","pos":"VER"} ,
		{"word":"obtempérèrent","word_nosc":"obtempererent","lemma":"obtempérer","pos":"VER"} ,
		{"word":"obtempéré","word_nosc":"obtempere","lemma":"obtempérer","pos":"VER"} ,
		{"word":"obtenaient","word_nosc":"obtenaient","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtenais","word_nosc":"obtenais","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtenait","word_nosc":"obtenait","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtenant","word_nosc":"obtenant","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtenez","word_nosc":"obtenez","lemma":"obtenir","pos":"VER"} ,
		{"word":"obteniez","word_nosc":"obteniez","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtenions","word_nosc":"obtenions","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtenir","word_nosc":"obtenir","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtenons","word_nosc":"obtenons","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtenu","word_nosc":"obtenu","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtenue","word_nosc":"obtenue","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtenues","word_nosc":"obtenues","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtenus","word_nosc":"obtenus","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtiendra","word_nosc":"obtiendra","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtiendrai","word_nosc":"obtiendrai","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtiendraient","word_nosc":"obtiendraient","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtiendrais","word_nosc":"obtiendrais","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtiendrait","word_nosc":"obtiendrait","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtiendras","word_nosc":"obtiendras","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtiendrez","word_nosc":"obtiendrez","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtiendriez","word_nosc":"obtiendriez","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtiendrions","word_nosc":"obtiendrions","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtiendrons","word_nosc":"obtiendrons","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtiendront","word_nosc":"obtiendront","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtienne","word_nosc":"obtienne","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtiennent","word_nosc":"obtiennent","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtiennes","word_nosc":"obtiennes","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtiens","word_nosc":"obtiens","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtient","word_nosc":"obtient","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtinrent","word_nosc":"obtinrent","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtins","word_nosc":"obtins","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtinsse","word_nosc":"obtinsse","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtint","word_nosc":"obtint","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtura","word_nosc":"obtura","lemma":"obturer","pos":"VER"} ,
		{"word":"obturait","word_nosc":"obturait","lemma":"obturer","pos":"VER"} ,
		{"word":"obture","word_nosc":"obture","lemma":"obturer","pos":"VER"} ,
		{"word":"obturer","word_nosc":"obturer","lemma":"obturer","pos":"VER"} ,
		{"word":"obturez","word_nosc":"obturez","lemma":"obturer","pos":"VER"} ,
		{"word":"obturé","word_nosc":"obture","lemma":"obturer","pos":"VER"} ,
		{"word":"obturée","word_nosc":"obturee","lemma":"obturer","pos":"VER"} ,
		{"word":"obtînmes","word_nosc":"obtinmes","lemma":"obtenir","pos":"VER"} ,
		{"word":"obtînt","word_nosc":"obtint","lemma":"obtenir","pos":"VER"} ,
		{"word":"obvier","word_nosc":"obvier","lemma":"obvier","pos":"VER"} ,
		{"word":"obère","word_nosc":"obere","lemma":"obérer","pos":"VER"} ,
		{"word":"obéi","word_nosc":"obei","lemma":"obéir","pos":"VER"} ,
		{"word":"obéie","word_nosc":"obeie","lemma":"obéir","pos":"VER"} ,
		{"word":"obéir","word_nosc":"obeir","lemma":"obéir","pos":"VER"} ,
		{"word":"obéira","word_nosc":"obeira","lemma":"obéir","pos":"VER"} ,
		{"word":"obéirai","word_nosc":"obeirai","lemma":"obéir","pos":"VER"} ,
		{"word":"obéiraient","word_nosc":"obeiraient","lemma":"obéir","pos":"VER"} ,
		{"word":"obéirais","word_nosc":"obeirais","lemma":"obéir","pos":"VER"} ,
		{"word":"obéirait","word_nosc":"obeirait","lemma":"obéir","pos":"VER"} ,
		{"word":"obéiras","word_nosc":"obeiras","lemma":"obéir","pos":"VER"} ,
		{"word":"obéirent","word_nosc":"obeirent","lemma":"obéir","pos":"VER"} ,
		{"word":"obéirez","word_nosc":"obeirez","lemma":"obéir","pos":"VER"} ,
		{"word":"obéiriez","word_nosc":"obeiriez","lemma":"obéir","pos":"VER"} ,
		{"word":"obéirons","word_nosc":"obeirons","lemma":"obéir","pos":"VER"} ,
		{"word":"obéiront","word_nosc":"obeiront","lemma":"obéir","pos":"VER"} ,
		{"word":"obéis","word_nosc":"obeis","lemma":"obéir","pos":"VER"} ,
		{"word":"obéissaient","word_nosc":"obeissaient","lemma":"obéir","pos":"VER"} ,
		{"word":"obéissais","word_nosc":"obeissais","lemma":"obéir","pos":"VER"} ,
		{"word":"obéissait","word_nosc":"obeissait","lemma":"obéir","pos":"VER"} ,
		{"word":"obéissant","word_nosc":"obeissant","lemma":"obéir","pos":"VER"} ,
		{"word":"obéisse","word_nosc":"obeisse","lemma":"obéir","pos":"VER"} ,
		{"word":"obéissent","word_nosc":"obeissent","lemma":"obéir","pos":"VER"} ,
		{"word":"obéisses","word_nosc":"obeisses","lemma":"obéir","pos":"VER"} ,
		{"word":"obéissez","word_nosc":"obeissez","lemma":"obéir","pos":"VER"} ,
		{"word":"obéissiez","word_nosc":"obeissiez","lemma":"obéir","pos":"VER"} ,
		{"word":"obéissions","word_nosc":"obeissions","lemma":"obéir","pos":"VER"} ,
		{"word":"obéissons","word_nosc":"obeissons","lemma":"obéir","pos":"VER"} ,
		{"word":"obéit","word_nosc":"obeit","lemma":"obéir","pos":"VER"} ,
		{"word":"obérant","word_nosc":"oberant","lemma":"obérer","pos":"VER"} ,
		{"word":"obérer","word_nosc":"oberer","lemma":"obérer","pos":"VER"} ,
		{"word":"obéîmes","word_nosc":"obeimes","lemma":"obéir","pos":"VER"} ,
		{"word":"occasionna","word_nosc":"occasionna","lemma":"occasionner","pos":"VER"} ,
		{"word":"occasionnait","word_nosc":"occasionnait","lemma":"occasionner","pos":"VER"} ,
		{"word":"occasionnant","word_nosc":"occasionnant","lemma":"occasionner","pos":"VER"} ,
		{"word":"occasionne","word_nosc":"occasionne","lemma":"occasionner","pos":"VER"} ,
		{"word":"occasionnent","word_nosc":"occasionnent","lemma":"occasionner","pos":"VER"} ,
		{"word":"occasionner","word_nosc":"occasionner","lemma":"occasionner","pos":"VER"} ,
		{"word":"occasionnèrent","word_nosc":"occasionnerent","lemma":"occasionner","pos":"VER"} ,
		{"word":"occasionné","word_nosc":"occasionne","lemma":"occasionner","pos":"VER"} ,
		{"word":"occasionnée","word_nosc":"occasionnee","lemma":"occasionner","pos":"VER"} ,
		{"word":"occasionnées","word_nosc":"occasionnees","lemma":"occasionner","pos":"VER"} ,
		{"word":"occasionnés","word_nosc":"occasionnes","lemma":"occasionner","pos":"VER"} ,
		{"word":"occidentalise","word_nosc":"occidentalise","lemma":"occidentaliser","pos":"VER"} ,
		{"word":"occire","word_nosc":"occire","lemma":"occire","pos":"VER"} ,
		{"word":"occlut","word_nosc":"occlut","lemma":"occlure","pos":"VER"} ,
		{"word":"occulta","word_nosc":"occulta","lemma":"occulter","pos":"VER"} ,
		{"word":"occultaient","word_nosc":"occultaient","lemma":"occulter","pos":"VER"} ,
		{"word":"occultait","word_nosc":"occultait","lemma":"occulter","pos":"VER"} ,
		{"word":"occultant","word_nosc":"occultant","lemma":"occulter","pos":"VER"} ,
		{"word":"occulte","word_nosc":"occulte","lemma":"occulter","pos":"VER"} ,
		{"word":"occultent","word_nosc":"occultent","lemma":"occulter","pos":"VER"} ,
		{"word":"occulter","word_nosc":"occulter","lemma":"occulter","pos":"VER"} ,
		{"word":"occultera","word_nosc":"occultera","lemma":"occulter","pos":"VER"} ,
		{"word":"occultez","word_nosc":"occultez","lemma":"occulter","pos":"VER"} ,
		{"word":"occulté","word_nosc":"occulte","lemma":"occulter","pos":"VER"} ,
		{"word":"occultée","word_nosc":"occultee","lemma":"occulter","pos":"VER"} ,
		{"word":"occupa","word_nosc":"occupa","lemma":"occuper","pos":"VER"} ,
		{"word":"occupai","word_nosc":"occupai","lemma":"occuper","pos":"VER"} ,
		{"word":"occupaient","word_nosc":"occupaient","lemma":"occuper","pos":"VER"} ,
		{"word":"occupais","word_nosc":"occupais","lemma":"occuper","pos":"VER"} ,
		{"word":"occupait","word_nosc":"occupait","lemma":"occuper","pos":"VER"} ,
		{"word":"occupant","word_nosc":"occupant","lemma":"occuper","pos":"VER"} ,
		{"word":"occupas","word_nosc":"occupas","lemma":"occuper","pos":"VER"} ,
		{"word":"occupe","word_nosc":"occupe","lemma":"occuper","pos":"VER"} ,
		{"word":"occupent","word_nosc":"occupent","lemma":"occuper","pos":"VER"} ,
		{"word":"occuper","word_nosc":"occuper","lemma":"occuper","pos":"VER"} ,
		{"word":"occupera","word_nosc":"occupera","lemma":"occuper","pos":"VER"} ,
		{"word":"occuperai","word_nosc":"occuperai","lemma":"occuper","pos":"VER"} ,
		{"word":"occuperaient","word_nosc":"occuperaient","lemma":"occuper","pos":"VER"} ,
		{"word":"occuperais","word_nosc":"occuperais","lemma":"occuper","pos":"VER"} ,
		{"word":"occuperait","word_nosc":"occuperait","lemma":"occuper","pos":"VER"} ,
		{"word":"occuperas","word_nosc":"occuperas","lemma":"occuper","pos":"VER"} ,
		{"word":"occuperez","word_nosc":"occuperez","lemma":"occuper","pos":"VER"} ,
		{"word":"occuperions","word_nosc":"occuperions","lemma":"occuper","pos":"VER"} ,
		{"word":"occuperons","word_nosc":"occuperons","lemma":"occuper","pos":"VER"} ,
		{"word":"occuperont","word_nosc":"occuperont","lemma":"occuper","pos":"VER"} ,
		{"word":"occupes","word_nosc":"occupes","lemma":"occuper","pos":"VER"} ,
		{"word":"occupez","word_nosc":"occupez","lemma":"occuper","pos":"VER"} ,
		{"word":"occupiez","word_nosc":"occupiez","lemma":"occuper","pos":"VER"} ,
		{"word":"occupions","word_nosc":"occupions","lemma":"occuper","pos":"VER"} ,
		{"word":"occupons","word_nosc":"occupons","lemma":"occuper","pos":"VER"} ,
		{"word":"occupât","word_nosc":"occupat","lemma":"occuper","pos":"VER"} ,
		{"word":"occupèrent","word_nosc":"occuperent","lemma":"occuper","pos":"VER"} ,
		{"word":"occupé","word_nosc":"occupe","lemma":"occuper","pos":"VER"} ,
		{"word":"occupée","word_nosc":"occupee","lemma":"occuper","pos":"VER"} ,
		{"word":"occupées","word_nosc":"occupees","lemma":"occuper","pos":"VER"} ,
		{"word":"occupés","word_nosc":"occupes","lemma":"occuper","pos":"VER"} ,
		{"word":"ocré","word_nosc":"ocre","lemma":"ocrer","pos":"VER"} ,
		{"word":"ocrée","word_nosc":"ocree","lemma":"ocrer","pos":"VER"} ,
		{"word":"ocrées","word_nosc":"ocrees","lemma":"ocrer","pos":"VER"} ,
		{"word":"ocrés","word_nosc":"ocres","lemma":"ocrer","pos":"VER"} ,
		{"word":"octavia","word_nosc":"octavia","lemma":"octavier","pos":"VER"} ,
		{"word":"octavie","word_nosc":"octavie","lemma":"octavier","pos":"VER"} ,
		{"word":"octroie","word_nosc":"octroie","lemma":"octroyer","pos":"VER"} ,
		{"word":"octroient","word_nosc":"octroient","lemma":"octroyer","pos":"VER"} ,
		{"word":"octroiera","word_nosc":"octroiera","lemma":"octroyer","pos":"VER"} ,
		{"word":"octroya","word_nosc":"octroya","lemma":"octroyer","pos":"VER"} ,
		{"word":"octroyaient","word_nosc":"octroyaient","lemma":"octroyer","pos":"VER"} ,
		{"word":"octroyait","word_nosc":"octroyait","lemma":"octroyer","pos":"VER"} ,
		{"word":"octroyant","word_nosc":"octroyant","lemma":"octroyer","pos":"VER"} ,
		{"word":"octroyer","word_nosc":"octroyer","lemma":"octroyer","pos":"VER"} ,
		{"word":"octroyons","word_nosc":"octroyons","lemma":"octroyer","pos":"VER"} ,
		{"word":"octroyât","word_nosc":"octroyat","lemma":"octroyer","pos":"VER"} ,
		{"word":"octroyé","word_nosc":"octroye","lemma":"octroyer","pos":"VER"} ,
		{"word":"octroyée","word_nosc":"octroyee","lemma":"octroyer","pos":"VER"} ,
		{"word":"octroyées","word_nosc":"octroyees","lemma":"octroyer","pos":"VER"} ,
		{"word":"octroyés","word_nosc":"octroyes","lemma":"octroyer","pos":"VER"} ,
		{"word":"odorant","word_nosc":"odorant","lemma":"odorer","pos":"VER"} ,
		{"word":"oeuvraient","word_nosc":"oeuvraient","lemma":"oeuvrer","pos":"VER"} ,
		{"word":"oeuvrait","word_nosc":"oeuvrait","lemma":"oeuvrer","pos":"VER"} ,
		{"word":"oeuvrant","word_nosc":"oeuvrant","lemma":"oeuvrer","pos":"VER"} ,
		{"word":"oeuvre","word_nosc":"oeuvre","lemma":"oeuvrer","pos":"VER"} ,
		{"word":"oeuvrent","word_nosc":"oeuvrent","lemma":"oeuvrer","pos":"VER"} ,
		{"word":"oeuvrer","word_nosc":"oeuvrer","lemma":"oeuvrer","pos":"VER"} ,
		{"word":"oeuvrera","word_nosc":"oeuvrera","lemma":"oeuvrer","pos":"VER"} ,
		{"word":"oeuvres","word_nosc":"oeuvres","lemma":"oeuvrer","pos":"VER"} ,
		{"word":"oeuvrez","word_nosc":"oeuvrez","lemma":"oeuvrer","pos":"VER"} ,
		{"word":"oeuvrions","word_nosc":"oeuvrions","lemma":"oeuvrer","pos":"VER"} ,
		{"word":"oeuvré","word_nosc":"oeuvre","lemma":"oeuvrer","pos":"VER"} ,
		{"word":"offensa","word_nosc":"offensa","lemma":"offenser","pos":"VER"} ,
		{"word":"offensaient","word_nosc":"offensaient","lemma":"offenser","pos":"VER"} ,
		{"word":"offensais","word_nosc":"offensais","lemma":"offenser","pos":"VER"} ,
		{"word":"offensait","word_nosc":"offensait","lemma":"offenser","pos":"VER"} ,
		{"word":"offensant","word_nosc":"offensant","lemma":"offenser","pos":"VER"} ,
		{"word":"offense","word_nosc":"offense","lemma":"offenser","pos":"VER"} ,
		{"word":"offensent","word_nosc":"offensent","lemma":"offenser","pos":"VER"} ,
		{"word":"offenser","word_nosc":"offenser","lemma":"offenser","pos":"VER"} ,
		{"word":"offensera","word_nosc":"offensera","lemma":"offenser","pos":"VER"} ,
		{"word":"offenserai","word_nosc":"offenserai","lemma":"offenser","pos":"VER"} ,
		{"word":"offenseraient","word_nosc":"offenseraient","lemma":"offenser","pos":"VER"} ,
		{"word":"offenserais","word_nosc":"offenserais","lemma":"offenser","pos":"VER"} ,
		{"word":"offenserez","word_nosc":"offenserez","lemma":"offenser","pos":"VER"} ,
		{"word":"offenseront","word_nosc":"offenseront","lemma":"offenser","pos":"VER"} ,
		{"word":"offenses","word_nosc":"offenses","lemma":"offenser","pos":"VER"} ,
		{"word":"offensez","word_nosc":"offensez","lemma":"offenser","pos":"VER"} ,
		{"word":"offensons","word_nosc":"offensons","lemma":"offenser","pos":"VER"} ,
		{"word":"offensèrent","word_nosc":"offenserent","lemma":"offenser","pos":"VER"} ,
		{"word":"offensé","word_nosc":"offense","lemma":"offenser","pos":"VER"} ,
		{"word":"offensée","word_nosc":"offensee","lemma":"offenser","pos":"VER"} ,
		{"word":"offensées","word_nosc":"offensees","lemma":"offenser","pos":"VER"} ,
		{"word":"offensés","word_nosc":"offenses","lemma":"offenser","pos":"VER"} ,
		{"word":"offert","word_nosc":"offert","lemma":"offrir","pos":"VER"} ,
		{"word":"offerte","word_nosc":"offerte","lemma":"offrir","pos":"VER"} ,
		{"word":"offertes","word_nosc":"offertes","lemma":"offrir","pos":"VER"} ,
		{"word":"offerts","word_nosc":"offerts","lemma":"offrir","pos":"VER"} ,
		{"word":"officiaient","word_nosc":"officiaient","lemma":"officier","pos":"VER"} ,
		{"word":"officiais","word_nosc":"officiais","lemma":"officier","pos":"VER"} ,
		{"word":"officiait","word_nosc":"officiait","lemma":"officier","pos":"VER"} ,
		{"word":"officialisait","word_nosc":"officialisait","lemma":"officialiser","pos":"VER"} ,
		{"word":"officialise","word_nosc":"officialise","lemma":"officialiser","pos":"VER"} ,
		{"word":"officialiser","word_nosc":"officialiser","lemma":"officialiser","pos":"VER"} ,
		{"word":"officialisez","word_nosc":"officialisez","lemma":"officialiser","pos":"VER"} ,
		{"word":"officialisé","word_nosc":"officialise","lemma":"officialiser","pos":"VER"} ,
		{"word":"officiant","word_nosc":"officiant","lemma":"officier","pos":"VER"} ,
		{"word":"officie","word_nosc":"officie","lemma":"officier","pos":"VER"} ,
		{"word":"officient","word_nosc":"officient","lemma":"officier","pos":"VER"} ,
		{"word":"officier","word_nosc":"officier","lemma":"officier","pos":"VER"} ,
		{"word":"officiez","word_nosc":"officiez","lemma":"officier","pos":"VER"} ,
		{"word":"officions","word_nosc":"officions","lemma":"officier","pos":"VER"} ,
		{"word":"officié","word_nosc":"officie","lemma":"officier","pos":"VER"} ,
		{"word":"offraient","word_nosc":"offraient","lemma":"offrir","pos":"VER"} ,
		{"word":"offrais","word_nosc":"offrais","lemma":"offrir","pos":"VER"} ,
		{"word":"offrait","word_nosc":"offrait","lemma":"offrir","pos":"VER"} ,
		{"word":"offrant","word_nosc":"offrant","lemma":"offrir","pos":"VER"} ,
		{"word":"offre","word_nosc":"offre","lemma":"offrir","pos":"VER"} ,
		{"word":"offrent","word_nosc":"offrent","lemma":"offrir","pos":"VER"} ,
		{"word":"offres","word_nosc":"offres","lemma":"offrir","pos":"VER"} ,
		{"word":"offrez","word_nosc":"offrez","lemma":"offrir","pos":"VER"} ,
		{"word":"offriez","word_nosc":"offriez","lemma":"offrir","pos":"VER"} ,
		{"word":"offrions","word_nosc":"offrions","lemma":"offrir","pos":"VER"} ,
		{"word":"offrir","word_nosc":"offrir","lemma":"offrir","pos":"VER"} ,
		{"word":"offrira","word_nosc":"offrira","lemma":"offrir","pos":"VER"} ,
		{"word":"offrirai","word_nosc":"offrirai","lemma":"offrir","pos":"VER"} ,
		{"word":"offriraient","word_nosc":"offriraient","lemma":"offrir","pos":"VER"} ,
		{"word":"offrirais","word_nosc":"offrirais","lemma":"offrir","pos":"VER"} ,
		{"word":"offrirait","word_nosc":"offrirait","lemma":"offrir","pos":"VER"} ,
		{"word":"offriras","word_nosc":"offriras","lemma":"offrir","pos":"VER"} ,
		{"word":"offrirent","word_nosc":"offrirent","lemma":"offrir","pos":"VER"} ,
		{"word":"offrirez","word_nosc":"offrirez","lemma":"offrir","pos":"VER"} ,
		{"word":"offririez","word_nosc":"offririez","lemma":"offrir","pos":"VER"} ,
		{"word":"offrirons","word_nosc":"offrirons","lemma":"offrir","pos":"VER"} ,
		{"word":"offriront","word_nosc":"offriront","lemma":"offrir","pos":"VER"} ,
		{"word":"offris","word_nosc":"offris","lemma":"offrir","pos":"VER"} ,
		{"word":"offrit","word_nosc":"offrit","lemma":"offrir","pos":"VER"} ,
		{"word":"offrons","word_nosc":"offrons","lemma":"offrir","pos":"VER"} ,
		{"word":"offrît","word_nosc":"offrit","lemma":"offrir","pos":"VER"} ,
		{"word":"offusqua","word_nosc":"offusqua","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusquaient","word_nosc":"offusquaient","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusquais","word_nosc":"offusquais","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusquait","word_nosc":"offusquait","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusquant","word_nosc":"offusquant","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusque","word_nosc":"offusque","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusquent","word_nosc":"offusquent","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusquer","word_nosc":"offusquer","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusquerai","word_nosc":"offusquerai","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusquerait","word_nosc":"offusquerait","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusquerez","word_nosc":"offusquerez","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusques","word_nosc":"offusques","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusquât","word_nosc":"offusquat","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusquèrent","word_nosc":"offusquerent","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusqué","word_nosc":"offusque","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusquée","word_nosc":"offusquee","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusquées","word_nosc":"offusquees","lemma":"offusquer","pos":"VER"} ,
		{"word":"offusqués","word_nosc":"offusques","lemma":"offusquer","pos":"VER"} ,
		{"word":"oie","word_nosc":"oie","lemma":"ouïr","pos":"VER"} ,
		{"word":"oies","word_nosc":"oies","lemma":"ouïr","pos":"VER"} ,
		{"word":"oignait","word_nosc":"oignait","lemma":"oindre","pos":"VER"} ,
		{"word":"oignant","word_nosc":"oignant","lemma":"oindre","pos":"VER"} ,
		{"word":"oigne","word_nosc":"oigne","lemma":"oindre","pos":"VER"} ,
		{"word":"oignes","word_nosc":"oignes","lemma":"oindre","pos":"VER"} ,
		{"word":"oignez","word_nosc":"oignez","lemma":"oindre","pos":"VER"} ,
		{"word":"oignit","word_nosc":"oignit","lemma":"oindre","pos":"VER"} ,
		{"word":"oignons","word_nosc":"oignons","lemma":"oindre","pos":"VER"} ,
		{"word":"oindra","word_nosc":"oindra","lemma":"oindre","pos":"VER"} ,
		{"word":"oindre","word_nosc":"oindre","lemma":"oindre","pos":"VER"} ,
		{"word":"oins","word_nosc":"oins","lemma":"oindre","pos":"VER"} ,
		{"word":"oint","word_nosc":"oint","lemma":"oindre","pos":"VER"} ,
		{"word":"ointe","word_nosc":"ointe","lemma":"oindre","pos":"VER"} ,
		{"word":"ointes","word_nosc":"ointes","lemma":"oindre","pos":"VER"} ,
		{"word":"oints","word_nosc":"oints","lemma":"oindre","pos":"VER"} ,
		{"word":"ois","word_nosc":"ois","lemma":"ouïr","pos":"VER"} ,
		{"word":"oiselez","word_nosc":"oiselez","lemma":"oiseler","pos":"VER"} ,
		{"word":"oit","word_nosc":"oit","lemma":"ouïr","pos":"VER"} ,
		{"word":"ombra","word_nosc":"ombra","lemma":"ombrer","pos":"VER"} ,
		{"word":"ombrage","word_nosc":"ombrage","lemma":"ombrager","pos":"VER"} ,
		{"word":"ombragea","word_nosc":"ombragea","lemma":"ombrager","pos":"VER"} ,
		{"word":"ombrageaient","word_nosc":"ombrageaient","lemma":"ombrager","pos":"VER"} ,
		{"word":"ombrageait","word_nosc":"ombrageait","lemma":"ombrager","pos":"VER"} ,
		{"word":"ombragent","word_nosc":"ombragent","lemma":"ombrager","pos":"VER"} ,
		{"word":"ombrager","word_nosc":"ombrager","lemma":"ombrager","pos":"VER"} ,
		{"word":"ombragé","word_nosc":"ombrage","lemma":"ombrager","pos":"VER"} ,
		{"word":"ombragée","word_nosc":"ombragee","lemma":"ombrager","pos":"VER"} ,
		{"word":"ombragées","word_nosc":"ombragees","lemma":"ombrager","pos":"VER"} ,
		{"word":"ombragés","word_nosc":"ombrages","lemma":"ombrager","pos":"VER"} ,
		{"word":"ombraient","word_nosc":"ombraient","lemma":"ombrer","pos":"VER"} ,
		{"word":"ombrait","word_nosc":"ombrait","lemma":"ombrer","pos":"VER"} ,
		{"word":"ombrant","word_nosc":"ombrant","lemma":"ombrer","pos":"VER"} ,
		{"word":"ombre","word_nosc":"ombre","lemma":"ombrer","pos":"VER"} ,
		{"word":"ombrer","word_nosc":"ombrer","lemma":"ombrer","pos":"VER"} ,
		{"word":"ombres","word_nosc":"ombres","lemma":"ombrer","pos":"VER"} ,
		{"word":"ombré","word_nosc":"ombre","lemma":"ombrer","pos":"VER"} ,
		{"word":"ombrée","word_nosc":"ombree","lemma":"ombrer","pos":"VER"} ,
		{"word":"ombrées","word_nosc":"ombrees","lemma":"ombrer","pos":"VER"} ,
		{"word":"ombrés","word_nosc":"ombres","lemma":"ombrer","pos":"VER"} ,
		{"word":"omet","word_nosc":"omet","lemma":"omettre","pos":"VER"} ,
		{"word":"omets","word_nosc":"omets","lemma":"omettre","pos":"VER"} ,
		{"word":"omettaient","word_nosc":"omettaient","lemma":"omettre","pos":"VER"} ,
		{"word":"omettais","word_nosc":"omettais","lemma":"omettre","pos":"VER"} ,
		{"word":"omettait","word_nosc":"omettait","lemma":"omettre","pos":"VER"} ,
		{"word":"omettant","word_nosc":"omettant","lemma":"omettre","pos":"VER"} ,
		{"word":"omette","word_nosc":"omette","lemma":"omettre","pos":"VER"} ,
		{"word":"omettent","word_nosc":"omettent","lemma":"omettre","pos":"VER"} ,
		{"word":"omettez","word_nosc":"omettez","lemma":"omettre","pos":"VER"} ,
		{"word":"omettiez","word_nosc":"omettiez","lemma":"omettre","pos":"VER"} ,
		{"word":"omettrai","word_nosc":"omettrai","lemma":"omettre","pos":"VER"} ,
		{"word":"omettre","word_nosc":"omettre","lemma":"omettre","pos":"VER"} ,
		{"word":"omettrez","word_nosc":"omettrez","lemma":"omettre","pos":"VER"} ,
		{"word":"omis","word_nosc":"omis","lemma":"omettre","pos":"VER"} ,
		{"word":"omise","word_nosc":"omise","lemma":"omettre","pos":"VER"} ,
		{"word":"omises","word_nosc":"omises","lemma":"omettre","pos":"VER"} ,
		{"word":"omit","word_nosc":"omit","lemma":"omettre","pos":"VER"} ,
		{"word":"omît","word_nosc":"omit","lemma":"omettre","pos":"VER"} ,
		{"word":"onder","word_nosc":"onder","lemma":"onder","pos":"VER"} ,
		{"word":"ondoie","word_nosc":"ondoie","lemma":"ondoyer","pos":"VER"} ,
		{"word":"ondoient","word_nosc":"ondoient","lemma":"ondoyer","pos":"VER"} ,
		{"word":"ondoyaient","word_nosc":"ondoyaient","lemma":"ondoyer","pos":"VER"} ,
		{"word":"ondoyait","word_nosc":"ondoyait","lemma":"ondoyer","pos":"VER"} ,
		{"word":"ondoyant","word_nosc":"ondoyant","lemma":"ondoyer","pos":"VER"} ,
		{"word":"ondoyer","word_nosc":"ondoyer","lemma":"ondoyer","pos":"VER"} ,
		{"word":"ondoyons","word_nosc":"ondoyons","lemma":"ondoyer","pos":"VER"} ,
		{"word":"ondoyé","word_nosc":"ondoye","lemma":"ondoyer","pos":"VER"} ,
		{"word":"ondula","word_nosc":"ondula","lemma":"onduler","pos":"VER"} ,
		{"word":"ondulaient","word_nosc":"ondulaient","lemma":"onduler","pos":"VER"} ,
		{"word":"ondulait","word_nosc":"ondulait","lemma":"onduler","pos":"VER"} ,
		{"word":"ondulant","word_nosc":"ondulant","lemma":"onduler","pos":"VER"} ,
		{"word":"ondule","word_nosc":"ondule","lemma":"onduler","pos":"VER"} ,
		{"word":"ondulent","word_nosc":"ondulent","lemma":"onduler","pos":"VER"} ,
		{"word":"onduler","word_nosc":"onduler","lemma":"onduler","pos":"VER"} ,
		{"word":"ondulerait","word_nosc":"ondulerait","lemma":"onduler","pos":"VER"} ,
		{"word":"ondulons","word_nosc":"ondulons","lemma":"onduler","pos":"VER"} ,
		{"word":"ondulèrent","word_nosc":"ondulerent","lemma":"onduler","pos":"VER"} ,
		{"word":"ondulé","word_nosc":"ondule","lemma":"onduler","pos":"VER"} ,
		{"word":"ondulée","word_nosc":"ondulee","lemma":"onduler","pos":"VER"} ,
		{"word":"ondulées","word_nosc":"ondulees","lemma":"onduler","pos":"VER"} ,
		{"word":"ondulés","word_nosc":"ondules","lemma":"onduler","pos":"VER"} ,
		{"word":"ont","word_nosc":"ont","lemma":"avoir","pos":"VER"} ,
		{"word":"opacifiait","word_nosc":"opacifiait","lemma":"opacifier","pos":"VER"} ,
		{"word":"opacifiant","word_nosc":"opacifiant","lemma":"opacifier","pos":"VER"} ,
		{"word":"opacifie","word_nosc":"opacifie","lemma":"opacifier","pos":"VER"} ,
		{"word":"opacifient","word_nosc":"opacifient","lemma":"opacifier","pos":"VER"} ,
		{"word":"opacifier","word_nosc":"opacifier","lemma":"opacifier","pos":"VER"} ,
		{"word":"opacifié","word_nosc":"opacifie","lemma":"opacifier","pos":"VER"} ,
		{"word":"opiacé","word_nosc":"opiace","lemma":"opiacer","pos":"VER"} ,
		{"word":"opiacées","word_nosc":"opiacees","lemma":"opiacer","pos":"VER"} ,
		{"word":"opina","word_nosc":"opina","lemma":"opiner","pos":"VER"} ,
		{"word":"opinai","word_nosc":"opinai","lemma":"opiner","pos":"VER"} ,
		{"word":"opinaient","word_nosc":"opinaient","lemma":"opiner","pos":"VER"} ,
		{"word":"opinais","word_nosc":"opinais","lemma":"opiner","pos":"VER"} ,
		{"word":"opinait","word_nosc":"opinait","lemma":"opiner","pos":"VER"} ,
		{"word":"opinant","word_nosc":"opinant","lemma":"opiner","pos":"VER"} ,
		{"word":"opine","word_nosc":"opine","lemma":"opiner","pos":"VER"} ,
		{"word":"opiner","word_nosc":"opiner","lemma":"opiner","pos":"VER"} ,
		{"word":"opines","word_nosc":"opines","lemma":"opiner","pos":"VER"} ,
		{"word":"opinions","word_nosc":"opinions","lemma":"opiner","pos":"VER"} ,
		{"word":"opiniâtra","word_nosc":"opiniatra","lemma":"opiniâtrer","pos":"VER"} ,
		{"word":"opiniâtre","word_nosc":"opiniatre","lemma":"opiniâtrer","pos":"VER"} ,
		{"word":"opiniâtrez","word_nosc":"opiniatrez","lemma":"opiniâtrer","pos":"VER"} ,
		{"word":"opinèrent","word_nosc":"opinerent","lemma":"opiner","pos":"VER"} ,
		{"word":"opiné","word_nosc":"opine","lemma":"opiner","pos":"VER"} ,
		{"word":"opposa","word_nosc":"opposa","lemma":"opposer","pos":"VER"} ,
		{"word":"opposai","word_nosc":"opposai","lemma":"opposer","pos":"VER"} ,
		{"word":"opposaient","word_nosc":"opposaient","lemma":"opposer","pos":"VER"} ,
		{"word":"opposais","word_nosc":"opposais","lemma":"opposer","pos":"VER"} ,
		{"word":"opposait","word_nosc":"opposait","lemma":"opposer","pos":"VER"} ,
		{"word":"opposant","word_nosc":"opposant","lemma":"opposer","pos":"VER"} ,
		{"word":"oppose","word_nosc":"oppose","lemma":"opposer","pos":"VER"} ,
		{"word":"opposent","word_nosc":"opposent","lemma":"opposer","pos":"VER"} ,
		{"word":"opposer","word_nosc":"opposer","lemma":"opposer","pos":"VER"} ,
		{"word":"opposera","word_nosc":"opposera","lemma":"opposer","pos":"VER"} ,
		{"word":"opposerai","word_nosc":"opposerai","lemma":"opposer","pos":"VER"} ,
		{"word":"opposeraient","word_nosc":"opposeraient","lemma":"opposer","pos":"VER"} ,
		{"word":"opposerais","word_nosc":"opposerais","lemma":"opposer","pos":"VER"} ,
		{"word":"opposerait","word_nosc":"opposerait","lemma":"opposer","pos":"VER"} ,
		{"word":"opposerez","word_nosc":"opposerez","lemma":"opposer","pos":"VER"} ,
		{"word":"opposerions","word_nosc":"opposerions","lemma":"opposer","pos":"VER"} ,
		{"word":"opposerons","word_nosc":"opposerons","lemma":"opposer","pos":"VER"} ,
		{"word":"opposeront","word_nosc":"opposeront","lemma":"opposer","pos":"VER"} ,
		{"word":"opposes","word_nosc":"opposes","lemma":"opposer","pos":"VER"} ,
		{"word":"opposez","word_nosc":"opposez","lemma":"opposer","pos":"VER"} ,
		{"word":"opposions","word_nosc":"opposions","lemma":"opposer","pos":"VER"} ,
		{"word":"opposons","word_nosc":"opposons","lemma":"opposer","pos":"VER"} ,
		{"word":"opposât","word_nosc":"opposat","lemma":"opposer","pos":"VER"} ,
		{"word":"opposèrent","word_nosc":"opposerent","lemma":"opposer","pos":"VER"} ,
		{"word":"opposé","word_nosc":"oppose","lemma":"opposer","pos":"VER"} ,
		{"word":"opposée","word_nosc":"opposee","lemma":"opposer","pos":"VER"} ,
		{"word":"opposées","word_nosc":"opposees","lemma":"opposer","pos":"VER"} ,
		{"word":"opposés","word_nosc":"opposes","lemma":"opposer","pos":"VER"} ,
		{"word":"oppressa","word_nosc":"oppressa","lemma":"oppresser","pos":"VER"} ,
		{"word":"oppressaient","word_nosc":"oppressaient","lemma":"oppresser","pos":"VER"} ,
		{"word":"oppressait","word_nosc":"oppressait","lemma":"oppresser","pos":"VER"} ,
		{"word":"oppressant","word_nosc":"oppressant","lemma":"oppresser","pos":"VER"} ,
		{"word":"oppresse","word_nosc":"oppresse","lemma":"oppresser","pos":"VER"} ,
		{"word":"oppressent","word_nosc":"oppressent","lemma":"oppresser","pos":"VER"} ,
		{"word":"oppresser","word_nosc":"oppresser","lemma":"oppresser","pos":"VER"} ,
		{"word":"oppressé","word_nosc":"oppresse","lemma":"oppresser","pos":"VER"} ,
		{"word":"oppressée","word_nosc":"oppressee","lemma":"oppresser","pos":"VER"} ,
		{"word":"oppressées","word_nosc":"oppressees","lemma":"oppresser","pos":"VER"} ,
		{"word":"opprimait","word_nosc":"opprimait","lemma":"opprimer","pos":"VER"} ,
		{"word":"opprimant","word_nosc":"opprimant","lemma":"opprimer","pos":"VER"} ,
		{"word":"opprime","word_nosc":"opprime","lemma":"opprimer","pos":"VER"} ,
		{"word":"oppriment","word_nosc":"oppriment","lemma":"opprimer","pos":"VER"} ,
		{"word":"opprimer","word_nosc":"opprimer","lemma":"opprimer","pos":"VER"} ,
		{"word":"opprimez","word_nosc":"opprimez","lemma":"opprimer","pos":"VER"} ,
		{"word":"opprimé","word_nosc":"opprime","lemma":"opprimer","pos":"VER"} ,
		{"word":"opprimée","word_nosc":"opprimee","lemma":"opprimer","pos":"VER"} ,
		{"word":"opprimées","word_nosc":"opprimees","lemma":"opprimer","pos":"VER"} ,
		{"word":"opprimés","word_nosc":"opprimes","lemma":"opprimer","pos":"VER"} ,
		{"word":"opta","word_nosc":"opta","lemma":"opter","pos":"VER"} ,
		{"word":"optai","word_nosc":"optai","lemma":"opter","pos":"VER"} ,
		{"word":"optaient","word_nosc":"optaient","lemma":"opter","pos":"VER"} ,
		{"word":"optais","word_nosc":"optais","lemma":"opter","pos":"VER"} ,
		{"word":"optait","word_nosc":"optait","lemma":"opter","pos":"VER"} ,
		{"word":"opte","word_nosc":"opte","lemma":"opter","pos":"VER"} ,
		{"word":"optent","word_nosc":"optent","lemma":"opter","pos":"VER"} ,
		{"word":"opter","word_nosc":"opter","lemma":"opter","pos":"VER"} ,
		{"word":"opterais","word_nosc":"opterais","lemma":"opter","pos":"VER"} ,
		{"word":"optes","word_nosc":"optes","lemma":"opter","pos":"VER"} ,
		{"word":"optimise","word_nosc":"optimise","lemma":"optimiser","pos":"VER"} ,
		{"word":"optimiser","word_nosc":"optimiser","lemma":"optimiser","pos":"VER"} ,
		{"word":"optimisez","word_nosc":"optimisez","lemma":"optimiser","pos":"VER"} ,
		{"word":"optimisé","word_nosc":"optimise","lemma":"optimiser","pos":"VER"} ,
		{"word":"optimisées","word_nosc":"optimisees","lemma":"optimiser","pos":"VER"} ,
		{"word":"options","word_nosc":"options","lemma":"opter","pos":"VER"} ,
		{"word":"optons","word_nosc":"optons","lemma":"opter","pos":"VER"} ,
		{"word":"optât","word_nosc":"optat","lemma":"opter","pos":"VER"} ,
		{"word":"optèrent","word_nosc":"opterent","lemma":"opter","pos":"VER"} ,
		{"word":"opté","word_nosc":"opte","lemma":"opter","pos":"VER"} ,
		{"word":"opère","word_nosc":"opere","lemma":"opérer","pos":"VER"} ,
		{"word":"opèrent","word_nosc":"operent","lemma":"opérer","pos":"VER"} ,
		{"word":"opéra","word_nosc":"opera","lemma":"opérer","pos":"VER"} ,
		{"word":"opéraient","word_nosc":"operaient","lemma":"opérer","pos":"VER"} ,
		{"word":"opérais","word_nosc":"operais","lemma":"opérer","pos":"VER"} ,
		{"word":"opérait","word_nosc":"operait","lemma":"opérer","pos":"VER"} ,
		{"word":"opérant","word_nosc":"operant","lemma":"opérer","pos":"VER"} ,
		{"word":"opérer","word_nosc":"operer","lemma":"opérer","pos":"VER"} ,
		{"word":"opérera","word_nosc":"operera","lemma":"opérer","pos":"VER"} ,
		{"word":"opérerai","word_nosc":"opererai","lemma":"opérer","pos":"VER"} ,
		{"word":"opéreraient","word_nosc":"opereraient","lemma":"opérer","pos":"VER"} ,
		{"word":"opérerait","word_nosc":"opererait","lemma":"opérer","pos":"VER"} ,
		{"word":"opéreras","word_nosc":"opereras","lemma":"opérer","pos":"VER"} ,
		{"word":"opérerez","word_nosc":"opererez","lemma":"opérer","pos":"VER"} ,
		{"word":"opérerons","word_nosc":"opererons","lemma":"opérer","pos":"VER"} ,
		{"word":"opéreront","word_nosc":"opereront","lemma":"opérer","pos":"VER"} ,
		{"word":"opérez","word_nosc":"operez","lemma":"opérer","pos":"VER"} ,
		{"word":"opériez","word_nosc":"operiez","lemma":"opérer","pos":"VER"} ,
		{"word":"opérions","word_nosc":"operions","lemma":"opérer","pos":"VER"} ,
		{"word":"opérons","word_nosc":"operons","lemma":"opérer","pos":"VER"} ,
		{"word":"opérât","word_nosc":"operat","lemma":"opérer","pos":"VER"} ,
		{"word":"opérèrent","word_nosc":"opererent","lemma":"opérer","pos":"VER"} ,
		{"word":"opéré","word_nosc":"opere","lemma":"opérer","pos":"VER"} ,
		{"word":"opérée","word_nosc":"operee","lemma":"opérer","pos":"VER"} ,
		{"word":"opérées","word_nosc":"operees","lemma":"opérer","pos":"VER"} ,
		{"word":"opérés","word_nosc":"operes","lemma":"opérer","pos":"VER"} ,
		{"word":"oranger","word_nosc":"oranger","lemma":"oranger","pos":"VER"} ,
		{"word":"orangé","word_nosc":"orange","lemma":"oranger","pos":"VER"} ,
		{"word":"orangée","word_nosc":"orangee","lemma":"oranger","pos":"VER"} ,
		{"word":"orangées","word_nosc":"orangees","lemma":"oranger","pos":"VER"} ,
		{"word":"orangés","word_nosc":"oranges","lemma":"oranger","pos":"VER"} ,
		{"word":"orbiter","word_nosc":"orbiter","lemma":"orbiter","pos":"VER"} ,
		{"word":"orbitons","word_nosc":"orbitons","lemma":"orbiter","pos":"VER"} ,
		{"word":"orchestra","word_nosc":"orchestra","lemma":"orchestrer","pos":"VER"} ,
		{"word":"orchestraient","word_nosc":"orchestraient","lemma":"orchestrer","pos":"VER"} ,
		{"word":"orchestrait","word_nosc":"orchestrait","lemma":"orchestrer","pos":"VER"} ,
		{"word":"orchestre","word_nosc":"orchestre","lemma":"orchestrer","pos":"VER"} ,
		{"word":"orchestrent","word_nosc":"orchestrent","lemma":"orchestrer","pos":"VER"} ,
		{"word":"orchestrer","word_nosc":"orchestrer","lemma":"orchestrer","pos":"VER"} ,
		{"word":"orchestres","word_nosc":"orchestres","lemma":"orchestrer","pos":"VER"} ,
		{"word":"orchestré","word_nosc":"orchestre","lemma":"orchestrer","pos":"VER"} ,
		{"word":"orchestrée","word_nosc":"orchestree","lemma":"orchestrer","pos":"VER"} ,
		{"word":"orchestrées","word_nosc":"orchestrees","lemma":"orchestrer","pos":"VER"} ,
		{"word":"orchestrés","word_nosc":"orchestres","lemma":"orchestrer","pos":"VER"} ,
		{"word":"ordonna","word_nosc":"ordonna","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnai","word_nosc":"ordonnai","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnaient","word_nosc":"ordonnaient","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnais","word_nosc":"ordonnais","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnait","word_nosc":"ordonnait","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnancer","word_nosc":"ordonnancer","lemma":"ordonnancer","pos":"VER"} ,
		{"word":"ordonnancée","word_nosc":"ordonnancee","lemma":"ordonnancer","pos":"VER"} ,
		{"word":"ordonnant","word_nosc":"ordonnant","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonne","word_nosc":"ordonne","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnent","word_nosc":"ordonnent","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonner","word_nosc":"ordonner","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnera","word_nosc":"ordonnera","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnerai","word_nosc":"ordonnerai","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnerais","word_nosc":"ordonnerais","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnerait","word_nosc":"ordonnerait","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonneront","word_nosc":"ordonneront","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnes","word_nosc":"ordonnes","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnez","word_nosc":"ordonnez","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonniez","word_nosc":"ordonniez","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnons","word_nosc":"ordonnons","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnèrent","word_nosc":"ordonnerent","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonné","word_nosc":"ordonne","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnée","word_nosc":"ordonnee","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnées","word_nosc":"ordonnees","lemma":"ordonner","pos":"VER"} ,
		{"word":"ordonnés","word_nosc":"ordonnes","lemma":"ordonner","pos":"VER"} ,
		{"word":"organisa","word_nosc":"organisa","lemma":"organiser","pos":"VER"} ,
		{"word":"organisai","word_nosc":"organisai","lemma":"organiser","pos":"VER"} ,
		{"word":"organisaient","word_nosc":"organisaient","lemma":"organiser","pos":"VER"} ,
		{"word":"organisais","word_nosc":"organisais","lemma":"organiser","pos":"VER"} ,
		{"word":"organisait","word_nosc":"organisait","lemma":"organiser","pos":"VER"} ,
		{"word":"organisant","word_nosc":"organisant","lemma":"organiser","pos":"VER"} ,
		{"word":"organise","word_nosc":"organise","lemma":"organiser","pos":"VER"} ,
		{"word":"organisent","word_nosc":"organisent","lemma":"organiser","pos":"VER"} ,
		{"word":"organiser","word_nosc":"organiser","lemma":"organiser","pos":"VER"} ,
		{"word":"organisera","word_nosc":"organisera","lemma":"organiser","pos":"VER"} ,
		{"word":"organiserai","word_nosc":"organiserai","lemma":"organiser","pos":"VER"} ,
		{"word":"organiserais","word_nosc":"organiserais","lemma":"organiser","pos":"VER"} ,
		{"word":"organiserait","word_nosc":"organiserait","lemma":"organiser","pos":"VER"} ,
		{"word":"organiserez","word_nosc":"organiserez","lemma":"organiser","pos":"VER"} ,
		{"word":"organiseriez","word_nosc":"organiseriez","lemma":"organiser","pos":"VER"} ,
		{"word":"organiserons","word_nosc":"organiserons","lemma":"organiser","pos":"VER"} ,
		{"word":"organiseront","word_nosc":"organiseront","lemma":"organiser","pos":"VER"} ,
		{"word":"organises","word_nosc":"organises","lemma":"organiser","pos":"VER"} ,
		{"word":"organisez","word_nosc":"organisez","lemma":"organiser","pos":"VER"} ,
		{"word":"organisiez","word_nosc":"organisiez","lemma":"organiser","pos":"VER"} ,
		{"word":"organisions","word_nosc":"organisions","lemma":"organiser","pos":"VER"} ,
		{"word":"organisons","word_nosc":"organisons","lemma":"organiser","pos":"VER"} ,
		{"word":"organisâmes","word_nosc":"organisames","lemma":"organiser","pos":"VER"} ,
		{"word":"organisât","word_nosc":"organisat","lemma":"organiser","pos":"VER"} ,
		{"word":"organisèrent","word_nosc":"organiserent","lemma":"organiser","pos":"VER"} ,
		{"word":"organisé","word_nosc":"organise","lemma":"organiser","pos":"VER"} ,
		{"word":"organisée","word_nosc":"organisee","lemma":"organiser","pos":"VER"} ,
		{"word":"organisées","word_nosc":"organisees","lemma":"organiser","pos":"VER"} ,
		{"word":"organisés","word_nosc":"organises","lemma":"organiser","pos":"VER"} ,
		{"word":"orienta","word_nosc":"orienta","lemma":"orienter","pos":"VER"} ,
		{"word":"orientai","word_nosc":"orientai","lemma":"orienter","pos":"VER"} ,
		{"word":"orientaient","word_nosc":"orientaient","lemma":"orienter","pos":"VER"} ,
		{"word":"orientais","word_nosc":"orientais","lemma":"orienter","pos":"VER"} ,
		{"word":"orientait","word_nosc":"orientait","lemma":"orienter","pos":"VER"} ,
		{"word":"orientant","word_nosc":"orientant","lemma":"orienter","pos":"VER"} ,
		{"word":"oriente","word_nosc":"oriente","lemma":"orienter","pos":"VER"} ,
		{"word":"orientent","word_nosc":"orientent","lemma":"orienter","pos":"VER"} ,
		{"word":"orienter","word_nosc":"orienter","lemma":"orienter","pos":"VER"} ,
		{"word":"orienterai","word_nosc":"orienterai","lemma":"orienter","pos":"VER"} ,
		{"word":"orienterait","word_nosc":"orienterait","lemma":"orienter","pos":"VER"} ,
		{"word":"orienterez","word_nosc":"orienterez","lemma":"orienter","pos":"VER"} ,
		{"word":"orientez","word_nosc":"orientez","lemma":"orienter","pos":"VER"} ,
		{"word":"orientèrent","word_nosc":"orienterent","lemma":"orienter","pos":"VER"} ,
		{"word":"orienté","word_nosc":"oriente","lemma":"orienter","pos":"VER"} ,
		{"word":"orientée","word_nosc":"orientee","lemma":"orienter","pos":"VER"} ,
		{"word":"orientées","word_nosc":"orientees","lemma":"orienter","pos":"VER"} ,
		{"word":"orientés","word_nosc":"orientes","lemma":"orienter","pos":"VER"} ,
		{"word":"orna","word_nosc":"orna","lemma":"orner","pos":"VER"} ,
		{"word":"ornaient","word_nosc":"ornaient","lemma":"orner","pos":"VER"} ,
		{"word":"ornait","word_nosc":"ornait","lemma":"orner","pos":"VER"} ,
		{"word":"ornant","word_nosc":"ornant","lemma":"orner","pos":"VER"} ,
		{"word":"orne","word_nosc":"orne","lemma":"orner","pos":"VER"} ,
		{"word":"ornementer","word_nosc":"ornementer","lemma":"ornementer","pos":"VER"} ,
		{"word":"ornementé","word_nosc":"ornemente","lemma":"ornementer","pos":"VER"} ,
		{"word":"ornementée","word_nosc":"ornementee","lemma":"ornementer","pos":"VER"} ,
		{"word":"ornementés","word_nosc":"ornementes","lemma":"ornementer","pos":"VER"} ,
		{"word":"ornent","word_nosc":"ornent","lemma":"orner","pos":"VER"} ,
		{"word":"orner","word_nosc":"orner","lemma":"orner","pos":"VER"} ,
		{"word":"ornera","word_nosc":"ornera","lemma":"orner","pos":"VER"} ,
		{"word":"ornerait","word_nosc":"ornerait","lemma":"orner","pos":"VER"} ,
		{"word":"orneront","word_nosc":"orneront","lemma":"orner","pos":"VER"} ,
		{"word":"ornez","word_nosc":"ornez","lemma":"orner","pos":"VER"} ,
		{"word":"ornât","word_nosc":"ornat","lemma":"orner","pos":"VER"} ,
		{"word":"ornèrent","word_nosc":"ornerent","lemma":"orner","pos":"VER"} ,
		{"word":"orné","word_nosc":"orne","lemma":"orner","pos":"VER"} ,
		{"word":"ornée","word_nosc":"ornee","lemma":"orner","pos":"VER"} ,
		{"word":"ornées","word_nosc":"ornees","lemma":"orner","pos":"VER"} ,
		{"word":"ornés","word_nosc":"ornes","lemma":"orner","pos":"VER"} ,
		{"word":"orthographiait","word_nosc":"orthographiait","lemma":"orthographier","pos":"VER"} ,
		{"word":"orthographie","word_nosc":"orthographie","lemma":"orthographier","pos":"VER"} ,
		{"word":"orthographier","word_nosc":"orthographier","lemma":"orthographier","pos":"VER"} ,
		{"word":"orthographié","word_nosc":"orthographie","lemma":"orthographier","pos":"VER"} ,
		{"word":"orthographiées","word_nosc":"orthographiees","lemma":"orthographier","pos":"VER"} ,
		{"word":"osa","word_nosc":"osa","lemma":"oser","pos":"VER"} ,
		{"word":"osai","word_nosc":"osai","lemma":"oser","pos":"VER"} ,
		{"word":"osaient","word_nosc":"osaient","lemma":"oser","pos":"VER"} ,
		{"word":"osais","word_nosc":"osais","lemma":"oser","pos":"VER"} ,
		{"word":"osait","word_nosc":"osait","lemma":"oser","pos":"VER"} ,
		{"word":"osant","word_nosc":"osant","lemma":"oser","pos":"VER"} ,
		{"word":"oscilla","word_nosc":"oscilla","lemma":"osciller","pos":"VER"} ,
		{"word":"oscillai","word_nosc":"oscillai","lemma":"osciller","pos":"VER"} ,
		{"word":"oscillaient","word_nosc":"oscillaient","lemma":"osciller","pos":"VER"} ,
		{"word":"oscillais","word_nosc":"oscillais","lemma":"osciller","pos":"VER"} ,
		{"word":"oscillait","word_nosc":"oscillait","lemma":"osciller","pos":"VER"} ,
		{"word":"oscillant","word_nosc":"oscillant","lemma":"osciller","pos":"VER"} ,
		{"word":"oscille","word_nosc":"oscille","lemma":"osciller","pos":"VER"} ,
		{"word":"oscillent","word_nosc":"oscillent","lemma":"osciller","pos":"VER"} ,
		{"word":"osciller","word_nosc":"osciller","lemma":"osciller","pos":"VER"} ,
		{"word":"oscillez","word_nosc":"oscillez","lemma":"osciller","pos":"VER"} ,
		{"word":"oscillèrent","word_nosc":"oscillerent","lemma":"osciller","pos":"VER"} ,
		{"word":"oscillé","word_nosc":"oscille","lemma":"osciller","pos":"VER"} ,
		{"word":"ose","word_nosc":"ose","lemma":"oser","pos":"VER"} ,
		{"word":"osent","word_nosc":"osent","lemma":"oser","pos":"VER"} ,
		{"word":"oser","word_nosc":"oser","lemma":"oser","pos":"VER"} ,
		{"word":"osera","word_nosc":"osera","lemma":"oser","pos":"VER"} ,
		{"word":"oserai","word_nosc":"oserai","lemma":"oser","pos":"VER"} ,
		{"word":"oserais","word_nosc":"oserais","lemma":"oser","pos":"VER"} ,
		{"word":"oserait","word_nosc":"oserait","lemma":"oser","pos":"VER"} ,
		{"word":"oseras","word_nosc":"oseras","lemma":"oser","pos":"VER"} ,
		{"word":"oserez","word_nosc":"oserez","lemma":"oser","pos":"VER"} ,
		{"word":"oseriez","word_nosc":"oseriez","lemma":"oser","pos":"VER"} ,
		{"word":"oserions","word_nosc":"oserions","lemma":"oser","pos":"VER"} ,
		{"word":"oserons","word_nosc":"oserons","lemma":"oser","pos":"VER"} ,
		{"word":"oseront","word_nosc":"oseront","lemma":"oser","pos":"VER"} ,
		{"word":"oses","word_nosc":"oses","lemma":"oser","pos":"VER"} ,
		{"word":"osez","word_nosc":"osez","lemma":"oser","pos":"VER"} ,
		{"word":"osiez","word_nosc":"osiez","lemma":"oser","pos":"VER"} ,
		{"word":"osions","word_nosc":"osions","lemma":"oser","pos":"VER"} ,
		{"word":"osons","word_nosc":"osons","lemma":"oser","pos":"VER"} ,
		{"word":"ossifié","word_nosc":"ossifie","lemma":"ossifier","pos":"VER"} ,
		{"word":"osâmes","word_nosc":"osames","lemma":"oser","pos":"VER"} ,
		{"word":"osât","word_nosc":"osat","lemma":"oser","pos":"VER"} ,
		{"word":"osèrent","word_nosc":"oserent","lemma":"oser","pos":"VER"} ,
		{"word":"osé","word_nosc":"ose","lemma":"oser","pos":"VER"} ,
		{"word":"osée","word_nosc":"osee","lemma":"oser","pos":"VER"} ,
		{"word":"osées","word_nosc":"osees","lemma":"oser","pos":"VER"} ,
		{"word":"osés","word_nosc":"oses","lemma":"oser","pos":"VER"} ,
		{"word":"ouata","word_nosc":"ouata","lemma":"ouater","pos":"VER"} ,
		{"word":"ouatant","word_nosc":"ouatant","lemma":"ouater","pos":"VER"} ,
		{"word":"ouatiner","word_nosc":"ouatiner","lemma":"ouatiner","pos":"VER"} ,
		{"word":"ouatiné","word_nosc":"ouatine","lemma":"ouatiner","pos":"VER"} ,
		{"word":"ouatinée","word_nosc":"ouatinee","lemma":"ouatiner","pos":"VER"} ,
		{"word":"ouatinées","word_nosc":"ouatinees","lemma":"ouatiner","pos":"VER"} ,
		{"word":"ouaté","word_nosc":"ouate","lemma":"ouater","pos":"VER"} ,
		{"word":"ouatée","word_nosc":"ouatee","lemma":"ouater","pos":"VER"} ,
		{"word":"ouatées","word_nosc":"ouatees","lemma":"ouater","pos":"VER"} ,
		{"word":"ouatés","word_nosc":"ouates","lemma":"ouater","pos":"VER"} ,
		{"word":"oublia","word_nosc":"oublia","lemma":"oublier","pos":"VER"} ,
		{"word":"oubliai","word_nosc":"oubliai","lemma":"oublier","pos":"VER"} ,
		{"word":"oubliaient","word_nosc":"oubliaient","lemma":"oublier","pos":"VER"} ,
		{"word":"oubliais","word_nosc":"oubliais","lemma":"oublier","pos":"VER"} ,
		{"word":"oubliait","word_nosc":"oubliait","lemma":"oublier","pos":"VER"} ,
		{"word":"oubliant","word_nosc":"oubliant","lemma":"oublier","pos":"VER"} ,
		{"word":"oublias","word_nosc":"oublias","lemma":"oublier","pos":"VER"} ,
		{"word":"oubliasse","word_nosc":"oubliasse","lemma":"oublier","pos":"VER"} ,
		{"word":"oublie","word_nosc":"oublie","lemma":"oublier","pos":"VER"} ,
		{"word":"oublient","word_nosc":"oublient","lemma":"oublier","pos":"VER"} ,
		{"word":"oublier","word_nosc":"oublier","lemma":"oublier","pos":"VER"} ,
		{"word":"oubliera","word_nosc":"oubliera","lemma":"oublier","pos":"VER"} ,
		{"word":"oublierai","word_nosc":"oublierai","lemma":"oublier","pos":"VER"} ,
		{"word":"oublieraient","word_nosc":"oublieraient","lemma":"oublier","pos":"VER"} ,
		{"word":"oublierais","word_nosc":"oublierais","lemma":"oublier","pos":"VER"} ,
		{"word":"oublierait","word_nosc":"oublierait","lemma":"oublier","pos":"VER"} ,
		{"word":"oublieras","word_nosc":"oublieras","lemma":"oublier","pos":"VER"} ,
		{"word":"oublierez","word_nosc":"oublierez","lemma":"oublier","pos":"VER"} ,
		{"word":"oublierions","word_nosc":"oublierions","lemma":"oublier","pos":"VER"} ,
		{"word":"oublierons","word_nosc":"oublierons","lemma":"oublier","pos":"VER"} ,
		{"word":"oublieront","word_nosc":"oublieront","lemma":"oublier","pos":"VER"} ,
		{"word":"oublies","word_nosc":"oublies","lemma":"oublier","pos":"VER"} ,
		{"word":"oubliez","word_nosc":"oubliez","lemma":"oublier","pos":"VER"} ,
		{"word":"oubliiez","word_nosc":"oubliiez","lemma":"oublier","pos":"VER"} ,
		{"word":"oubliions","word_nosc":"oubliions","lemma":"oublier","pos":"VER"} ,
		{"word":"oublions","word_nosc":"oublions","lemma":"oublier","pos":"VER"} ,
		{"word":"oubliâmes","word_nosc":"oubliames","lemma":"oublier","pos":"VER"} ,
		{"word":"oubliât","word_nosc":"oubliat","lemma":"oublier","pos":"VER"} ,
		{"word":"oublièrent","word_nosc":"oublierent","lemma":"oublier","pos":"VER"} ,
		{"word":"oublié","word_nosc":"oublie","lemma":"oublier","pos":"VER"} ,
		{"word":"oubliée","word_nosc":"oubliee","lemma":"oublier","pos":"VER"} ,
		{"word":"oubliées","word_nosc":"oubliees","lemma":"oublier","pos":"VER"} ,
		{"word":"oubliés","word_nosc":"oublies","lemma":"oublier","pos":"VER"} ,
		{"word":"ouille","word_nosc":"ouille","lemma":"ouiller","pos":"VER"} ,
		{"word":"ourdi","word_nosc":"ourdi","lemma":"ourdir","pos":"VER"} ,
		{"word":"ourdie","word_nosc":"ourdie","lemma":"ourdir","pos":"VER"} ,
		{"word":"ourdies","word_nosc":"ourdies","lemma":"ourdir","pos":"VER"} ,
		{"word":"ourdir","word_nosc":"ourdir","lemma":"ourdir","pos":"VER"} ,
		{"word":"ourdira","word_nosc":"ourdira","lemma":"ourdir","pos":"VER"} ,
		{"word":"ourdis","word_nosc":"ourdis","lemma":"ourdir","pos":"VER"} ,
		{"word":"ourdissait","word_nosc":"ourdissait","lemma":"ourdir","pos":"VER"} ,
		{"word":"ourdissant","word_nosc":"ourdissant","lemma":"ourdir","pos":"VER"} ,
		{"word":"ourdissent","word_nosc":"ourdissent","lemma":"ourdir","pos":"VER"} ,
		{"word":"ourdit","word_nosc":"ourdit","lemma":"ourdir","pos":"VER"} ,
		{"word":"ourlaient","word_nosc":"ourlaient","lemma":"ourler","pos":"VER"} ,
		{"word":"ourlait","word_nosc":"ourlait","lemma":"ourler","pos":"VER"} ,
		{"word":"ourlant","word_nosc":"ourlant","lemma":"ourler","pos":"VER"} ,
		{"word":"ourle","word_nosc":"ourle","lemma":"ourler","pos":"VER"} ,
		{"word":"ourlent","word_nosc":"ourlent","lemma":"ourler","pos":"VER"} ,
		{"word":"ourler","word_nosc":"ourler","lemma":"ourler","pos":"VER"} ,
		{"word":"ourlé","word_nosc":"ourle","lemma":"ourler","pos":"VER"} ,
		{"word":"ourlée","word_nosc":"ourlee","lemma":"ourler","pos":"VER"} ,
		{"word":"ourlées","word_nosc":"ourlees","lemma":"ourler","pos":"VER"} ,
		{"word":"ourlés","word_nosc":"ourles","lemma":"ourler","pos":"VER"} ,
		{"word":"outiller","word_nosc":"outiller","lemma":"outiller","pos":"VER"} ,
		{"word":"outillé","word_nosc":"outille","lemma":"outiller","pos":"VER"} ,
		{"word":"outillées","word_nosc":"outillees","lemma":"outiller","pos":"VER"} ,
		{"word":"outillés","word_nosc":"outilles","lemma":"outiller","pos":"VER"} ,
		{"word":"outrage","word_nosc":"outrage","lemma":"outrager","pos":"VER"} ,
		{"word":"outrageant","word_nosc":"outrageant","lemma":"outrager","pos":"VER"} ,
		{"word":"outragent","word_nosc":"outragent","lemma":"outrager","pos":"VER"} ,
		{"word":"outrager","word_nosc":"outrager","lemma":"outrager","pos":"VER"} ,
		{"word":"outragerais","word_nosc":"outragerais","lemma":"outrager","pos":"VER"} ,
		{"word":"outrages","word_nosc":"outrages","lemma":"outrager","pos":"VER"} ,
		{"word":"outragez","word_nosc":"outragez","lemma":"outrager","pos":"VER"} ,
		{"word":"outragèrent","word_nosc":"outragerent","lemma":"outrager","pos":"VER"} ,
		{"word":"outragé","word_nosc":"outrage","lemma":"outrager","pos":"VER"} ,
		{"word":"outragée","word_nosc":"outragee","lemma":"outrager","pos":"VER"} ,
		{"word":"outragées","word_nosc":"outragees","lemma":"outrager","pos":"VER"} ,
		{"word":"outragés","word_nosc":"outrages","lemma":"outrager","pos":"VER"} ,
		{"word":"outrait","word_nosc":"outrait","lemma":"outrer","pos":"VER"} ,
		{"word":"outrant","word_nosc":"outrant","lemma":"outrer","pos":"VER"} ,
		{"word":"outre","word_nosc":"outre","lemma":"outrer","pos":"VER"} ,
		{"word":"outrepassa","word_nosc":"outrepassa","lemma":"outrepasser","pos":"VER"} ,
		{"word":"outrepassaient","word_nosc":"outrepassaient","lemma":"outrepasser","pos":"VER"} ,
		{"word":"outrepassait","word_nosc":"outrepassait","lemma":"outrepasser","pos":"VER"} ,
		{"word":"outrepassant","word_nosc":"outrepassant","lemma":"outrepasser","pos":"VER"} ,
		{"word":"outrepasse","word_nosc":"outrepasse","lemma":"outrepasser","pos":"VER"} ,
		{"word":"outrepassent","word_nosc":"outrepassent","lemma":"outrepasser","pos":"VER"} ,
		{"word":"outrepasser","word_nosc":"outrepasser","lemma":"outrepasser","pos":"VER"} ,
		{"word":"outrepasserai","word_nosc":"outrepasserai","lemma":"outrepasser","pos":"VER"} ,
		{"word":"outrepasses","word_nosc":"outrepasses","lemma":"outrepasser","pos":"VER"} ,
		{"word":"outrepassez","word_nosc":"outrepassez","lemma":"outrepasser","pos":"VER"} ,
		{"word":"outrepassé","word_nosc":"outrepasse","lemma":"outrepasser","pos":"VER"} ,
		{"word":"outrer","word_nosc":"outrer","lemma":"outrer","pos":"VER"} ,
		{"word":"outres","word_nosc":"outres","lemma":"outrer","pos":"VER"} ,
		{"word":"outré","word_nosc":"outre","lemma":"outrer","pos":"VER"} ,
		{"word":"outrée","word_nosc":"outree","lemma":"outrer","pos":"VER"} ,
		{"word":"outrées","word_nosc":"outrees","lemma":"outrer","pos":"VER"} ,
		{"word":"outrés","word_nosc":"outres","lemma":"outrer","pos":"VER"} ,
		{"word":"ouvert","word_nosc":"ouvert","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouverte","word_nosc":"ouverte","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvertes","word_nosc":"ouvertes","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouverts","word_nosc":"ouverts","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrage","word_nosc":"ouvrage","lemma":"ouvrager","pos":"VER"} ,
		{"word":"ouvrageant","word_nosc":"ouvrageant","lemma":"ouvrager","pos":"VER"} ,
		{"word":"ouvrager","word_nosc":"ouvrager","lemma":"ouvrager","pos":"VER"} ,
		{"word":"ouvragé","word_nosc":"ouvrage","lemma":"ouvrager","pos":"VER"} ,
		{"word":"ouvragée","word_nosc":"ouvragee","lemma":"ouvrager","pos":"VER"} ,
		{"word":"ouvragées","word_nosc":"ouvragees","lemma":"ouvrager","pos":"VER"} ,
		{"word":"ouvraient","word_nosc":"ouvraient","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrais","word_nosc":"ouvrais","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrait","word_nosc":"ouvrait","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrant","word_nosc":"ouvrant","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvre","word_nosc":"ouvre","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrent","word_nosc":"ouvrent","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrer","word_nosc":"ouvrer","lemma":"ouvrer","pos":"VER"} ,
		{"word":"ouvres","word_nosc":"ouvres","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrez","word_nosc":"ouvrez","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvriez","word_nosc":"ouvriez","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrions","word_nosc":"ouvrions","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrir","word_nosc":"ouvrir","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrira","word_nosc":"ouvrira","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrirai","word_nosc":"ouvrirai","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvriraient","word_nosc":"ouvriraient","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrirais","word_nosc":"ouvrirais","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrirait","word_nosc":"ouvrirait","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvriras","word_nosc":"ouvriras","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrirent","word_nosc":"ouvrirent","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrirez","word_nosc":"ouvrirez","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvririez","word_nosc":"ouvririez","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvririons","word_nosc":"ouvririons","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrirons","word_nosc":"ouvrirons","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvriront","word_nosc":"ouvriront","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvris","word_nosc":"ouvris","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrissent","word_nosc":"ouvrissent","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrit","word_nosc":"ouvrit","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrons","word_nosc":"ouvrons","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvré","word_nosc":"ouvre","lemma":"ouvrer","pos":"VER"} ,
		{"word":"ouvrîmes","word_nosc":"ouvrimes","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouvrît","word_nosc":"ouvrit","lemma":"ouvrir","pos":"VER"} ,
		{"word":"ouï","word_nosc":"oui","lemma":"ouïr","pos":"VER"} ,
		{"word":"ouïe","word_nosc":"ouie","lemma":"ouïr","pos":"VER"} ,
		{"word":"ouïr","word_nosc":"ouir","lemma":"ouïr","pos":"VER"} ,
		{"word":"ouïrais","word_nosc":"ouirais","lemma":"ouïr","pos":"VER"} ,
		{"word":"ouïs","word_nosc":"ouis","lemma":"ouïr","pos":"VER"} ,
		{"word":"ouït","word_nosc":"ouit","lemma":"ouïr","pos":"VER"} ,
		{"word":"ovalisées","word_nosc":"ovalisees","lemma":"ovaliser","pos":"VER"} ,
		{"word":"ovationnaient","word_nosc":"ovationnaient","lemma":"ovationner","pos":"VER"} ,
		{"word":"ovationner","word_nosc":"ovationner","lemma":"ovationner","pos":"VER"} ,
		{"word":"ovationné","word_nosc":"ovationne","lemma":"ovationner","pos":"VER"} ,
		{"word":"ovationnés","word_nosc":"ovationnes","lemma":"ovationner","pos":"VER"} ,
		{"word":"ovule","word_nosc":"ovule","lemma":"ovuler","pos":"VER"} ,
		{"word":"ovuler","word_nosc":"ovuler","lemma":"ovuler","pos":"VER"} ,
		{"word":"ovules","word_nosc":"ovules","lemma":"ovuler","pos":"VER"} ,
		{"word":"oxyde","word_nosc":"oxyde","lemma":"oxyder","pos":"VER"} ,
		{"word":"oxyder","word_nosc":"oxyder","lemma":"oxyder","pos":"VER"} ,
		{"word":"oxydé","word_nosc":"oxyde","lemma":"oxyder","pos":"VER"} ,
		{"word":"oxydée","word_nosc":"oxydee","lemma":"oxyder","pos":"VER"} ,
		{"word":"oxydées","word_nosc":"oxydees","lemma":"oxyder","pos":"VER"} ,
		{"word":"oxygène","word_nosc":"oxygene","lemma":"oxygéner","pos":"VER"} ,
		{"word":"oxygènent","word_nosc":"oxygenent","lemma":"oxygéner","pos":"VER"} ,
		{"word":"oxygéner","word_nosc":"oxygener","lemma":"oxygéner","pos":"VER"} ,
		{"word":"oxygéné","word_nosc":"oxygene","lemma":"oxygéner","pos":"VER"} ,
		{"word":"oxygénée","word_nosc":"oxygenee","lemma":"oxygéner","pos":"VER"} ,
		{"word":"oyant","word_nosc":"oyant","lemma":"ouïr","pos":"VER"} ,
		{"word":"oyez","word_nosc":"oyez","lemma":"ouïr","pos":"VER"} ,
		{"word":"oyons","word_nosc":"oyons","lemma":"ouïr","pos":"VER"} ,
		{"word":"pacifiait","word_nosc":"pacifiait","lemma":"pacifier","pos":"VER"} ,
		{"word":"pacifiant","word_nosc":"pacifiant","lemma":"pacifier","pos":"VER"} ,
		{"word":"pacifie","word_nosc":"pacifie","lemma":"pacifier","pos":"VER"} ,
		{"word":"pacifier","word_nosc":"pacifier","lemma":"pacifier","pos":"VER"} ,
		{"word":"pacifié","word_nosc":"pacifie","lemma":"pacifier","pos":"VER"} ,
		{"word":"pacifiée","word_nosc":"pacifiee","lemma":"pacifier","pos":"VER"} ,
		{"word":"pacifiées","word_nosc":"pacifiees","lemma":"pacifier","pos":"VER"} ,
		{"word":"pacifiés","word_nosc":"pacifies","lemma":"pacifier","pos":"VER"} ,
		{"word":"pacsés","word_nosc":"pacses","lemma":"pacser","pos":"VER"} ,
		{"word":"pactisaient","word_nosc":"pactisaient","lemma":"pactiser","pos":"VER"} ,
		{"word":"pactisait","word_nosc":"pactisait","lemma":"pactiser","pos":"VER"} ,
		{"word":"pactisant","word_nosc":"pactisant","lemma":"pactiser","pos":"VER"} ,
		{"word":"pactise","word_nosc":"pactise","lemma":"pactiser","pos":"VER"} ,
		{"word":"pactiser","word_nosc":"pactiser","lemma":"pactiser","pos":"VER"} ,
		{"word":"pactiseras","word_nosc":"pactiseras","lemma":"pactiser","pos":"VER"} ,
		{"word":"pactisez","word_nosc":"pactisez","lemma":"pactiser","pos":"VER"} ,
		{"word":"pactisions","word_nosc":"pactisions","lemma":"pactiser","pos":"VER"} ,
		{"word":"pactisé","word_nosc":"pactise","lemma":"pactiser","pos":"VER"} ,
		{"word":"paddocker","word_nosc":"paddocker","lemma":"paddocker","pos":"VER"} ,
		{"word":"pagaie","word_nosc":"pagaie","lemma":"pagayer","pos":"VER"} ,
		{"word":"pagaient","word_nosc":"pagaient","lemma":"pagayer","pos":"VER"} ,
		{"word":"pagaiera","word_nosc":"pagaiera","lemma":"pagayer","pos":"VER"} ,
		{"word":"pagaies","word_nosc":"pagaies","lemma":"pagayer","pos":"VER"} ,
		{"word":"pagaya","word_nosc":"pagaya","lemma":"pagayer","pos":"VER"} ,
		{"word":"pagayait","word_nosc":"pagayait","lemma":"pagayer","pos":"VER"} ,
		{"word":"pagayant","word_nosc":"pagayant","lemma":"pagayer","pos":"VER"} ,
		{"word":"pagaye","word_nosc":"pagaye","lemma":"pagayer","pos":"VER"} ,
		{"word":"pagayer","word_nosc":"pagayer","lemma":"pagayer","pos":"VER"} ,
		{"word":"pagayez","word_nosc":"pagayez","lemma":"pagayer","pos":"VER"} ,
		{"word":"pagayèrent","word_nosc":"pagayerent","lemma":"pagayer","pos":"VER"} ,
		{"word":"pagayé","word_nosc":"pagaye","lemma":"pagayer","pos":"VER"} ,
		{"word":"pageais","word_nosc":"pageais","lemma":"pager","pos":"VER"} ,
		{"word":"pageait","word_nosc":"pageait","lemma":"pager","pos":"VER"} ,
		{"word":"pageant","word_nosc":"pageant","lemma":"pager","pos":"VER"} ,
		{"word":"pager","word_nosc":"pager","lemma":"pager","pos":"VER"} ,
		{"word":"paginer","word_nosc":"paginer","lemma":"paginer","pos":"VER"} ,
		{"word":"pagnote","word_nosc":"pagnote","lemma":"pagnoter","pos":"VER"} ,
		{"word":"pagnoter","word_nosc":"pagnoter","lemma":"pagnoter","pos":"VER"} ,
		{"word":"pagé","word_nosc":"page","lemma":"pager","pos":"VER"} ,
		{"word":"pagée","word_nosc":"pagee","lemma":"pager","pos":"VER"} ,
		{"word":"pagés","word_nosc":"pages","lemma":"pager","pos":"VER"} ,
		{"word":"paie","word_nosc":"paie","lemma":"payer","pos":"VER"} ,
		{"word":"paient","word_nosc":"paient","lemma":"payer","pos":"VER"} ,
		{"word":"paiera","word_nosc":"paiera","lemma":"payer","pos":"VER"} ,
		{"word":"paierai","word_nosc":"paierai","lemma":"payer","pos":"VER"} ,
		{"word":"paieraient","word_nosc":"paieraient","lemma":"payer","pos":"VER"} ,
		{"word":"paierais","word_nosc":"paierais","lemma":"payer","pos":"VER"} ,
		{"word":"paierait","word_nosc":"paierait","lemma":"payer","pos":"VER"} ,
		{"word":"paieras","word_nosc":"paieras","lemma":"payer","pos":"VER"} ,
		{"word":"paierez","word_nosc":"paierez","lemma":"payer","pos":"VER"} ,
		{"word":"paieriez","word_nosc":"paieriez","lemma":"payer","pos":"VER"} ,
		{"word":"paierions","word_nosc":"paierions","lemma":"payer","pos":"VER"} ,
		{"word":"paierons","word_nosc":"paierons","lemma":"payer","pos":"VER"} ,
		{"word":"paieront","word_nosc":"paieront","lemma":"payer","pos":"VER"} ,
		{"word":"paies","word_nosc":"paies","lemma":"payer","pos":"VER"} ,
		{"word":"paillardait","word_nosc":"paillardait","lemma":"paillarder","pos":"VER"} ,
		{"word":"pailler","word_nosc":"pailler","lemma":"pailler","pos":"VER"} ,
		{"word":"pailleter","word_nosc":"pailleter","lemma":"pailleter","pos":"VER"} ,
		{"word":"paillette","word_nosc":"paillette","lemma":"pailleter","pos":"VER"} ,
		{"word":"paillettes","word_nosc":"paillettes","lemma":"pailleter","pos":"VER"} ,
		{"word":"pailleté","word_nosc":"paillete","lemma":"pailleter","pos":"VER"} ,
		{"word":"pailletée","word_nosc":"pailletee","lemma":"pailleter","pos":"VER"} ,
		{"word":"pailletées","word_nosc":"pailletees","lemma":"pailleter","pos":"VER"} ,
		{"word":"pailletés","word_nosc":"pailletes","lemma":"pailleter","pos":"VER"} ,
		{"word":"paillée","word_nosc":"paillee","lemma":"pailler","pos":"VER"} ,
		{"word":"paillées","word_nosc":"paillees","lemma":"pailler","pos":"VER"} ,
		{"word":"pais","word_nosc":"pais","lemma":"paître","pos":"VER"} ,
		{"word":"paissaient","word_nosc":"paissaient","lemma":"paître","pos":"VER"} ,
		{"word":"paissait","word_nosc":"paissait","lemma":"paître","pos":"VER"} ,
		{"word":"paissant","word_nosc":"paissant","lemma":"paître","pos":"VER"} ,
		{"word":"paissent","word_nosc":"paissent","lemma":"paître","pos":"VER"} ,
		{"word":"palabra","word_nosc":"palabra","lemma":"palabrer","pos":"VER"} ,
		{"word":"palabraient","word_nosc":"palabraient","lemma":"palabrer","pos":"VER"} ,
		{"word":"palabrait","word_nosc":"palabrait","lemma":"palabrer","pos":"VER"} ,
		{"word":"palabrant","word_nosc":"palabrant","lemma":"palabrer","pos":"VER"} ,
		{"word":"palabre","word_nosc":"palabre","lemma":"palabrer","pos":"VER"} ,
		{"word":"palabrent","word_nosc":"palabrent","lemma":"palabrer","pos":"VER"} ,
		{"word":"palabrer","word_nosc":"palabrer","lemma":"palabrer","pos":"VER"} ,
		{"word":"palabré","word_nosc":"palabre","lemma":"palabrer","pos":"VER"} ,
		{"word":"palanquer","word_nosc":"palanquer","lemma":"palanquer","pos":"VER"} ,
		{"word":"palanquée","word_nosc":"palanquee","lemma":"palanquer","pos":"VER"} ,
		{"word":"palanquées","word_nosc":"palanquees","lemma":"palanquer","pos":"VER"} ,
		{"word":"palinodier","word_nosc":"palinodier","lemma":"palinodier","pos":"VER"} ,
		{"word":"palissadé","word_nosc":"palissade","lemma":"palissader","pos":"VER"} ,
		{"word":"palissadée","word_nosc":"palissadee","lemma":"palissader","pos":"VER"} ,
		{"word":"palissadés","word_nosc":"palissades","lemma":"palissader","pos":"VER"} ,
		{"word":"palissant","word_nosc":"palissant","lemma":"palisser","pos":"VER"} ,
		{"word":"palliait","word_nosc":"palliait","lemma":"pallier","pos":"VER"} ,
		{"word":"pallient","word_nosc":"pallient","lemma":"pallier","pos":"VER"} ,
		{"word":"pallier","word_nosc":"pallier","lemma":"pallier","pos":"VER"} ,
		{"word":"palliera","word_nosc":"palliera","lemma":"pallier","pos":"VER"} ,
		{"word":"pallié","word_nosc":"pallie","lemma":"pallier","pos":"VER"} ,
		{"word":"palmaient","word_nosc":"palmaient","lemma":"palmer","pos":"VER"} ,
		{"word":"palmer","word_nosc":"palmer","lemma":"palmer","pos":"VER"} ,
		{"word":"palmé","word_nosc":"palme","lemma":"palmer","pos":"VER"} ,
		{"word":"palmées","word_nosc":"palmees","lemma":"palmer","pos":"VER"} ,
		{"word":"palmés","word_nosc":"palmes","lemma":"palmer","pos":"VER"} ,
		{"word":"palpa","word_nosc":"palpa","lemma":"palper","pos":"VER"} ,
		{"word":"palpaient","word_nosc":"palpaient","lemma":"palper","pos":"VER"} ,
		{"word":"palpais","word_nosc":"palpais","lemma":"palper","pos":"VER"} ,
		{"word":"palpait","word_nosc":"palpait","lemma":"palper","pos":"VER"} ,
		{"word":"palpant","word_nosc":"palpant","lemma":"palper","pos":"VER"} ,
		{"word":"palpe","word_nosc":"palpe","lemma":"palper","pos":"VER"} ,
		{"word":"palpent","word_nosc":"palpent","lemma":"palper","pos":"VER"} ,
		{"word":"palper","word_nosc":"palper","lemma":"palper","pos":"VER"} ,
		{"word":"palpera","word_nosc":"palpera","lemma":"palper","pos":"VER"} ,
		{"word":"palperai","word_nosc":"palperai","lemma":"palper","pos":"VER"} ,
		{"word":"palpes","word_nosc":"palpes","lemma":"palper","pos":"VER"} ,
		{"word":"palpez","word_nosc":"palpez","lemma":"palper","pos":"VER"} ,
		{"word":"palpita","word_nosc":"palpita","lemma":"palpiter","pos":"VER"} ,
		{"word":"palpitaient","word_nosc":"palpitaient","lemma":"palpiter","pos":"VER"} ,
		{"word":"palpitait","word_nosc":"palpitait","lemma":"palpiter","pos":"VER"} ,
		{"word":"palpitant","word_nosc":"palpitant","lemma":"palpiter","pos":"VER"} ,
		{"word":"palpite","word_nosc":"palpite","lemma":"palpiter","pos":"VER"} ,
		{"word":"palpitent","word_nosc":"palpitent","lemma":"palpiter","pos":"VER"} ,
		{"word":"palpiter","word_nosc":"palpiter","lemma":"palpiter","pos":"VER"} ,
		{"word":"palpiterait","word_nosc":"palpiterait","lemma":"palpiter","pos":"VER"} ,
		{"word":"palpites","word_nosc":"palpites","lemma":"palpiter","pos":"VER"} ,
		{"word":"palpitèrent","word_nosc":"palpiterent","lemma":"palpiter","pos":"VER"} ,
		{"word":"palpité","word_nosc":"palpite","lemma":"palpiter","pos":"VER"} ,
		{"word":"palpons","word_nosc":"palpons","lemma":"palper","pos":"VER"} ,
		{"word":"palpèrent","word_nosc":"palperent","lemma":"palper","pos":"VER"} ,
		{"word":"palpé","word_nosc":"palpe","lemma":"palper","pos":"VER"} ,
		{"word":"palpées","word_nosc":"palpees","lemma":"palper","pos":"VER"} ,
		{"word":"palpés","word_nosc":"palpes","lemma":"palper","pos":"VER"} ,
		{"word":"paluchait","word_nosc":"paluchait","lemma":"palucher","pos":"VER"} ,
		{"word":"palucher","word_nosc":"palucher","lemma":"palucher","pos":"VER"} ,
		{"word":"pana","word_nosc":"pana","lemma":"paner","pos":"VER"} ,
		{"word":"panachait","word_nosc":"panachait","lemma":"panacher","pos":"VER"} ,
		{"word":"panache","word_nosc":"panache","lemma":"panacher","pos":"VER"} ,
		{"word":"panacher","word_nosc":"panacher","lemma":"panacher","pos":"VER"} ,
		{"word":"panaché","word_nosc":"panache","lemma":"panacher","pos":"VER"} ,
		{"word":"panachée","word_nosc":"panachee","lemma":"panacher","pos":"VER"} ,
		{"word":"pane","word_nosc":"pane","lemma":"paner","pos":"VER"} ,
		{"word":"paner","word_nosc":"paner","lemma":"paner","pos":"VER"} ,
		{"word":"paniqua","word_nosc":"paniqua","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniquaient","word_nosc":"paniquaient","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniquais","word_nosc":"paniquais","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniquait","word_nosc":"paniquait","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniquant","word_nosc":"paniquant","lemma":"paniquer","pos":"VER"} ,
		{"word":"panique","word_nosc":"panique","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniquent","word_nosc":"paniquent","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniquer","word_nosc":"paniquer","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniquera","word_nosc":"paniquera","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniquerai","word_nosc":"paniquerai","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniqueraient","word_nosc":"paniqueraient","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniquerais","word_nosc":"paniquerais","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniqueras","word_nosc":"paniqueras","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniqueront","word_nosc":"paniqueront","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniques","word_nosc":"paniques","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniquez","word_nosc":"paniquez","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniquons","word_nosc":"paniquons","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniquèrent","word_nosc":"paniquerent","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniqué","word_nosc":"panique","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniquée","word_nosc":"paniquee","lemma":"paniquer","pos":"VER"} ,
		{"word":"paniqués","word_nosc":"paniques","lemma":"paniquer","pos":"VER"} ,
		{"word":"panna","word_nosc":"panna","lemma":"panner","pos":"VER"} ,
		{"word":"pannais","word_nosc":"pannais","lemma":"panner","pos":"VER"} ,
		{"word":"pannait","word_nosc":"pannait","lemma":"panner","pos":"VER"} ,
		{"word":"panner","word_nosc":"panner","lemma":"panner","pos":"VER"} ,
		{"word":"panné","word_nosc":"panne","lemma":"panner","pos":"VER"} ,
		{"word":"panoramiquer","word_nosc":"panoramiquer","lemma":"panoramiquer","pos":"VER"} ,
		{"word":"panoramiqué","word_nosc":"panoramique","lemma":"panoramiquer","pos":"VER"} ,
		{"word":"pansa","word_nosc":"pansa","lemma":"panser","pos":"VER"} ,
		{"word":"pansait","word_nosc":"pansait","lemma":"panser","pos":"VER"} ,
		{"word":"pansant","word_nosc":"pansant","lemma":"panser","pos":"VER"} ,
		{"word":"pansas","word_nosc":"pansas","lemma":"panser","pos":"VER"} ,
		{"word":"panse","word_nosc":"panse","lemma":"panser","pos":"VER"} ,
		{"word":"pansent","word_nosc":"pansent","lemma":"panser","pos":"VER"} ,
		{"word":"panser","word_nosc":"panser","lemma":"panser","pos":"VER"} ,
		{"word":"panserai","word_nosc":"panserai","lemma":"panser","pos":"VER"} ,
		{"word":"pansez","word_nosc":"pansez","lemma":"panser","pos":"VER"} ,
		{"word":"pansiez","word_nosc":"pansiez","lemma":"panser","pos":"VER"} ,
		{"word":"pansions","word_nosc":"pansions","lemma":"panser","pos":"VER"} ,
		{"word":"pansèrent","word_nosc":"panserent","lemma":"panser","pos":"VER"} ,
		{"word":"pansé","word_nosc":"panse","lemma":"panser","pos":"VER"} ,
		{"word":"pansée","word_nosc":"pansee","lemma":"panser","pos":"VER"} ,
		{"word":"pansées","word_nosc":"pansees","lemma":"panser","pos":"VER"} ,
		{"word":"pansés","word_nosc":"panses","lemma":"panser","pos":"VER"} ,
		{"word":"pantela","word_nosc":"pantela","lemma":"panteler","pos":"VER"} ,
		{"word":"pantelaient","word_nosc":"pantelaient","lemma":"panteler","pos":"VER"} ,
		{"word":"pantelait","word_nosc":"pantelait","lemma":"panteler","pos":"VER"} ,
		{"word":"pantelant","word_nosc":"pantelant","lemma":"panteler","pos":"VER"} ,
		{"word":"panteler","word_nosc":"panteler","lemma":"panteler","pos":"VER"} ,
		{"word":"pantelle","word_nosc":"pantelle","lemma":"panteler","pos":"VER"} ,
		{"word":"pantelé","word_nosc":"pantele","lemma":"panteler","pos":"VER"} ,
		{"word":"pantoufle","word_nosc":"pantoufle","lemma":"pantoufler","pos":"VER"} ,
		{"word":"pantoufler","word_nosc":"pantoufler","lemma":"pantoufler","pos":"VER"} ,
		{"word":"pantoufles","word_nosc":"pantoufles","lemma":"pantoufler","pos":"VER"} ,
		{"word":"pané","word_nosc":"pane","lemma":"paner","pos":"VER"} ,
		{"word":"panée","word_nosc":"panee","lemma":"paner","pos":"VER"} ,
		{"word":"panées","word_nosc":"panees","lemma":"paner","pos":"VER"} ,
		{"word":"panés","word_nosc":"panes","lemma":"paner","pos":"VER"} ,
		{"word":"paperasser","word_nosc":"paperasser","lemma":"paperasser","pos":"VER"} ,
		{"word":"papillonna","word_nosc":"papillonna","lemma":"papillonner","pos":"VER"} ,
		{"word":"papillonnaient","word_nosc":"papillonnaient","lemma":"papillonner","pos":"VER"} ,
		{"word":"papillonnais","word_nosc":"papillonnais","lemma":"papillonner","pos":"VER"} ,
		{"word":"papillonnait","word_nosc":"papillonnait","lemma":"papillonner","pos":"VER"} ,
		{"word":"papillonne","word_nosc":"papillonne","lemma":"papillonner","pos":"VER"} ,
		{"word":"papillonnent","word_nosc":"papillonnent","lemma":"papillonner","pos":"VER"} ,
		{"word":"papillonner","word_nosc":"papillonner","lemma":"papillonner","pos":"VER"} ,
		{"word":"papillonnes","word_nosc":"papillonnes","lemma":"papillonner","pos":"VER"} ,
		{"word":"papillonné","word_nosc":"papillonne","lemma":"papillonner","pos":"VER"} ,
		{"word":"papillotaient","word_nosc":"papillotaient","lemma":"papilloter","pos":"VER"} ,
		{"word":"papillotait","word_nosc":"papillotait","lemma":"papilloter","pos":"VER"} ,
		{"word":"papillotant","word_nosc":"papillotant","lemma":"papilloter","pos":"VER"} ,
		{"word":"papillote","word_nosc":"papillote","lemma":"papilloter","pos":"VER"} ,
		{"word":"papillotent","word_nosc":"papillotent","lemma":"papilloter","pos":"VER"} ,
		{"word":"papilloter","word_nosc":"papilloter","lemma":"papilloter","pos":"VER"} ,
		{"word":"papillotèrent","word_nosc":"papilloterent","lemma":"papilloter","pos":"VER"} ,
		{"word":"papotaient","word_nosc":"papotaient","lemma":"papoter","pos":"VER"} ,
		{"word":"papotait","word_nosc":"papotait","lemma":"papoter","pos":"VER"} ,
		{"word":"papotant","word_nosc":"papotant","lemma":"papoter","pos":"VER"} ,
		{"word":"papote","word_nosc":"papote","lemma":"papoter","pos":"VER"} ,
		{"word":"papotent","word_nosc":"papotent","lemma":"papoter","pos":"VER"} ,
		{"word":"papoter","word_nosc":"papoter","lemma":"papoter","pos":"VER"} ,
		{"word":"papotera","word_nosc":"papotera","lemma":"papoter","pos":"VER"} ,
		{"word":"papotez","word_nosc":"papotez","lemma":"papoter","pos":"VER"} ,
		{"word":"papotèrent","word_nosc":"papoterent","lemma":"papoter","pos":"VER"} ,
		{"word":"papoté","word_nosc":"papote","lemma":"papoter","pos":"VER"} ,
		{"word":"papouillent","word_nosc":"papouillent","lemma":"papouiller","pos":"VER"} ,
		{"word":"papouiller","word_nosc":"papouiller","lemma":"papouiller","pos":"VER"} ,
		{"word":"papouillez","word_nosc":"papouillez","lemma":"papouiller","pos":"VER"} ,
		{"word":"papouillé","word_nosc":"papouille","lemma":"papouiller","pos":"VER"} ,
		{"word":"paqueta","word_nosc":"paqueta","lemma":"paqueter","pos":"VER"} ,
		{"word":"paquette","word_nosc":"paquette","lemma":"paqueter","pos":"VER"} ,
		{"word":"para","word_nosc":"para","lemma":"parer","pos":"VER"} ,
		{"word":"parachevaient","word_nosc":"parachevaient","lemma":"parachever","pos":"VER"} ,
		{"word":"parachevait","word_nosc":"parachevait","lemma":"parachever","pos":"VER"} ,
		{"word":"parachevant","word_nosc":"parachevant","lemma":"parachever","pos":"VER"} ,
		{"word":"parachever","word_nosc":"parachever","lemma":"parachever","pos":"VER"} ,
		{"word":"parachevèrent","word_nosc":"paracheverent","lemma":"parachever","pos":"VER"} ,
		{"word":"parachevé","word_nosc":"paracheve","lemma":"parachever","pos":"VER"} ,
		{"word":"parachute","word_nosc":"parachute","lemma":"parachuter","pos":"VER"} ,
		{"word":"parachutent","word_nosc":"parachutent","lemma":"parachuter","pos":"VER"} ,
		{"word":"parachuter","word_nosc":"parachuter","lemma":"parachuter","pos":"VER"} ,
		{"word":"parachuterais","word_nosc":"parachuterais","lemma":"parachuter","pos":"VER"} ,
		{"word":"parachuterons","word_nosc":"parachuterons","lemma":"parachuter","pos":"VER"} ,
		{"word":"parachutes","word_nosc":"parachutes","lemma":"parachuter","pos":"VER"} ,
		{"word":"parachutez","word_nosc":"parachutez","lemma":"parachuter","pos":"VER"} ,
		{"word":"parachutèrent","word_nosc":"parachuterent","lemma":"parachuter","pos":"VER"} ,
		{"word":"parachuté","word_nosc":"parachute","lemma":"parachuter","pos":"VER"} ,
		{"word":"parachutée","word_nosc":"parachutee","lemma":"parachuter","pos":"VER"} ,
		{"word":"parachutées","word_nosc":"parachutees","lemma":"parachuter","pos":"VER"} ,
		{"word":"parachutés","word_nosc":"parachutes","lemma":"parachuter","pos":"VER"} ,
		{"word":"parachèvent","word_nosc":"parachevent","lemma":"parachever","pos":"VER"} ,
		{"word":"parachèvera","word_nosc":"parachevera","lemma":"parachever","pos":"VER"} ,
		{"word":"parachèveraient","word_nosc":"paracheveraient","lemma":"parachever","pos":"VER"} ,
		{"word":"parada","word_nosc":"parada","lemma":"parader","pos":"VER"} ,
		{"word":"paradaient","word_nosc":"paradaient","lemma":"parader","pos":"VER"} ,
		{"word":"paradais","word_nosc":"paradais","lemma":"parader","pos":"VER"} ,
		{"word":"paradait","word_nosc":"paradait","lemma":"parader","pos":"VER"} ,
		{"word":"paradant","word_nosc":"paradant","lemma":"parader","pos":"VER"} ,
		{"word":"parade","word_nosc":"parade","lemma":"parader","pos":"VER"} ,
		{"word":"paradent","word_nosc":"paradent","lemma":"parader","pos":"VER"} ,
		{"word":"parader","word_nosc":"parader","lemma":"parader","pos":"VER"} ,
		{"word":"paradera","word_nosc":"paradera","lemma":"parader","pos":"VER"} ,
		{"word":"parades","word_nosc":"parades","lemma":"parader","pos":"VER"} ,
		{"word":"paradons","word_nosc":"paradons","lemma":"parader","pos":"VER"} ,
		{"word":"paradèrent","word_nosc":"paraderent","lemma":"parader","pos":"VER"} ,
		{"word":"paradé","word_nosc":"parade","lemma":"parader","pos":"VER"} ,
		{"word":"paradée","word_nosc":"paradee","lemma":"parader","pos":"VER"} ,
		{"word":"parafer","word_nosc":"parafer","lemma":"parafer","pos":"VER"} ,
		{"word":"parai","word_nosc":"parai","lemma":"parer","pos":"VER"} ,
		{"word":"paraient","word_nosc":"paraient","lemma":"parer","pos":"VER"} ,
		{"word":"parais","word_nosc":"parais","lemma":"paraître","pos":"VER"} ,
		{"word":"parais","word_nosc":"parais","lemma":"parer","pos":"VER"} ,
		{"word":"paraissaient","word_nosc":"paraissaient","lemma":"paraître","pos":"VER"} ,
		{"word":"paraissais","word_nosc":"paraissais","lemma":"paraître","pos":"VER"} ,
		{"word":"paraissait","word_nosc":"paraissait","lemma":"paraître","pos":"VER"} ,
		{"word":"paraissant","word_nosc":"paraissant","lemma":"paraître","pos":"VER"} ,
		{"word":"paraisse","word_nosc":"paraisse","lemma":"paraître","pos":"VER"} ,
		{"word":"paraissent","word_nosc":"paraissent","lemma":"paraître","pos":"VER"} ,
		{"word":"paraissez","word_nosc":"paraissez","lemma":"paraître","pos":"VER"} ,
		{"word":"paraissiez","word_nosc":"paraissiez","lemma":"paraître","pos":"VER"} ,
		{"word":"paraissions","word_nosc":"paraissions","lemma":"paraître","pos":"VER"} ,
		{"word":"paraissons","word_nosc":"paraissons","lemma":"paraître","pos":"VER"} ,
		{"word":"parait","word_nosc":"parait","lemma":"parait","pos":"VER"} ,
		{"word":"parait","word_nosc":"parait","lemma":"parer","pos":"VER"} ,
		{"word":"paralysa","word_nosc":"paralysa","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralysaient","word_nosc":"paralysaient","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralysais","word_nosc":"paralysais","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralysait","word_nosc":"paralysait","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralysant","word_nosc":"paralysant","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralyse","word_nosc":"paralyse","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralysent","word_nosc":"paralysent","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralyser","word_nosc":"paralyser","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralysera","word_nosc":"paralysera","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralyserait","word_nosc":"paralyserait","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralyserez","word_nosc":"paralyserez","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralysât","word_nosc":"paralysat","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralysèrent","word_nosc":"paralyserent","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralysé","word_nosc":"paralyse","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralysée","word_nosc":"paralysee","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralysées","word_nosc":"paralysees","lemma":"paralyser","pos":"VER"} ,
		{"word":"paralysés","word_nosc":"paralyses","lemma":"paralyser","pos":"VER"} ,
		{"word":"parant","word_nosc":"parant","lemma":"parer","pos":"VER"} ,
		{"word":"paraphait","word_nosc":"paraphait","lemma":"parapher","pos":"VER"} ,
		{"word":"paraphant","word_nosc":"paraphant","lemma":"parapher","pos":"VER"} ,
		{"word":"paraphe","word_nosc":"paraphe","lemma":"parapher","pos":"VER"} ,
		{"word":"parapher","word_nosc":"parapher","lemma":"parapher","pos":"VER"} ,
		{"word":"paraphes","word_nosc":"paraphes","lemma":"parapher","pos":"VER"} ,
		{"word":"paraphez","word_nosc":"paraphez","lemma":"parapher","pos":"VER"} ,
		{"word":"paraphrasa","word_nosc":"paraphrasa","lemma":"paraphraser","pos":"VER"} ,
		{"word":"paraphrasait","word_nosc":"paraphrasait","lemma":"paraphraser","pos":"VER"} ,
		{"word":"paraphrasant","word_nosc":"paraphrasant","lemma":"paraphraser","pos":"VER"} ,
		{"word":"paraphrase","word_nosc":"paraphrase","lemma":"paraphraser","pos":"VER"} ,
		{"word":"paraphraser","word_nosc":"paraphraser","lemma":"paraphraser","pos":"VER"} ,
		{"word":"paraphrases","word_nosc":"paraphrases","lemma":"paraphraser","pos":"VER"} ,
		{"word":"paraphrasé","word_nosc":"paraphrase","lemma":"paraphraser","pos":"VER"} ,
		{"word":"paraphé","word_nosc":"paraphe","lemma":"parapher","pos":"VER"} ,
		{"word":"paraphée","word_nosc":"paraphee","lemma":"parapher","pos":"VER"} ,
		{"word":"paraphés","word_nosc":"paraphes","lemma":"parapher","pos":"VER"} ,
		{"word":"parasitait","word_nosc":"parasitait","lemma":"parasiter","pos":"VER"} ,
		{"word":"parasite","word_nosc":"parasite","lemma":"parasiter","pos":"VER"} ,
		{"word":"parasitent","word_nosc":"parasitent","lemma":"parasiter","pos":"VER"} ,
		{"word":"parasiter","word_nosc":"parasiter","lemma":"parasiter","pos":"VER"} ,
		{"word":"parasites","word_nosc":"parasites","lemma":"parasiter","pos":"VER"} ,
		{"word":"parasité","word_nosc":"parasite","lemma":"parasiter","pos":"VER"} ,
		{"word":"parasitée","word_nosc":"parasitee","lemma":"parasiter","pos":"VER"} ,
		{"word":"parasités","word_nosc":"parasites","lemma":"parasiter","pos":"VER"} ,
		{"word":"paraît","word_nosc":"parait","lemma":"paraître","pos":"VER"} ,
		{"word":"paraîtra","word_nosc":"paraitra","lemma":"paraître","pos":"VER"} ,
		{"word":"paraîtrai","word_nosc":"paraitrai","lemma":"paraître","pos":"VER"} ,
		{"word":"paraîtraient","word_nosc":"paraitraient","lemma":"paraître","pos":"VER"} ,
		{"word":"paraîtrais","word_nosc":"paraitrais","lemma":"paraître","pos":"VER"} ,
		{"word":"paraîtrait","word_nosc":"paraitrait","lemma":"paraître","pos":"VER"} ,
		{"word":"paraîtras","word_nosc":"paraitras","lemma":"paraître","pos":"VER"} ,
		{"word":"paraître","word_nosc":"paraitre","lemma":"paraître","pos":"VER"} ,
		{"word":"paraîtrez","word_nosc":"paraitrez","lemma":"paraître","pos":"VER"} ,
		{"word":"paraîtront","word_nosc":"paraitront","lemma":"paraître","pos":"VER"} ,
		{"word":"parcheminé","word_nosc":"parchemine","lemma":"parcheminer","pos":"VER"} ,
		{"word":"parcheminée","word_nosc":"parcheminee","lemma":"parcheminer","pos":"VER"} ,
		{"word":"parcheminées","word_nosc":"parcheminees","lemma":"parcheminer","pos":"VER"} ,
		{"word":"parcouraient","word_nosc":"parcouraient","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourais","word_nosc":"parcourais","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourait","word_nosc":"parcourait","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourant","word_nosc":"parcourant","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcoure","word_nosc":"parcoure","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourent","word_nosc":"parcourent","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourez","word_nosc":"parcourez","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcouriez","word_nosc":"parcouriez","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourions","word_nosc":"parcourions","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourir","word_nosc":"parcourir","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourons","word_nosc":"parcourons","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourra","word_nosc":"parcourra","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourrai","word_nosc":"parcourrai","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourraient","word_nosc":"parcourraient","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourrais","word_nosc":"parcourrais","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourrait","word_nosc":"parcourrait","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourrez","word_nosc":"parcourrez","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourrions","word_nosc":"parcourrions","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourront","word_nosc":"parcourront","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcours","word_nosc":"parcours","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourt","word_nosc":"parcourt","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcouru","word_nosc":"parcouru","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourue","word_nosc":"parcourue","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourues","word_nosc":"parcourues","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcoururent","word_nosc":"parcoururent","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourus","word_nosc":"parcourus","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourut","word_nosc":"parcourut","lemma":"parcourir","pos":"VER"} ,
		{"word":"parcourûmes","word_nosc":"parcourumes","lemma":"parcourir","pos":"VER"} ,
		{"word":"pardonna","word_nosc":"pardonna","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnaient","word_nosc":"pardonnaient","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnais","word_nosc":"pardonnais","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnait","word_nosc":"pardonnait","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnant","word_nosc":"pardonnant","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonne","word_nosc":"pardonne","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnent","word_nosc":"pardonnent","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonner","word_nosc":"pardonner","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnera","word_nosc":"pardonnera","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnerai","word_nosc":"pardonnerai","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonneraient","word_nosc":"pardonneraient","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnerais","word_nosc":"pardonnerais","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnerait","word_nosc":"pardonnerait","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonneras","word_nosc":"pardonneras","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnerez","word_nosc":"pardonnerez","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonneriez","word_nosc":"pardonneriez","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnerons","word_nosc":"pardonnerons","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonneront","word_nosc":"pardonneront","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnes","word_nosc":"pardonnes","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnez","word_nosc":"pardonnez","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonniez","word_nosc":"pardonniez","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnions","word_nosc":"pardonnions","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnons","word_nosc":"pardonnons","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnât","word_nosc":"pardonnat","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnèrent","word_nosc":"pardonnerent","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonné","word_nosc":"pardonne","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnée","word_nosc":"pardonnee","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnées","word_nosc":"pardonnees","lemma":"pardonner","pos":"VER"} ,
		{"word":"pardonnés","word_nosc":"pardonnes","lemma":"pardonner","pos":"VER"} ,
		{"word":"pare","word_nosc":"pare","lemma":"parer","pos":"VER"} ,
		{"word":"parent","word_nosc":"parent","lemma":"parer","pos":"VER"} ,
		{"word":"parer","word_nosc":"parer","lemma":"parer","pos":"VER"} ,
		{"word":"parerai","word_nosc":"parerai","lemma":"parer","pos":"VER"} ,
		{"word":"parerait","word_nosc":"parerait","lemma":"parer","pos":"VER"} ,
		{"word":"paressaient","word_nosc":"paressaient","lemma":"paresser","pos":"VER"} ,
		{"word":"paressais","word_nosc":"paressais","lemma":"paresser","pos":"VER"} ,
		{"word":"paressait","word_nosc":"paressait","lemma":"paresser","pos":"VER"} ,
		{"word":"paressant","word_nosc":"paressant","lemma":"paresser","pos":"VER"} ,
		{"word":"paresse","word_nosc":"paresse","lemma":"paresser","pos":"VER"} ,
		{"word":"paresser","word_nosc":"paresser","lemma":"paresser","pos":"VER"} ,
		{"word":"paressions","word_nosc":"paressions","lemma":"paresser","pos":"VER"} ,
		{"word":"paressé","word_nosc":"paresse","lemma":"paresser","pos":"VER"} ,
		{"word":"parez","word_nosc":"parez","lemma":"parer","pos":"VER"} ,
		{"word":"parfaire","word_nosc":"parfaire","lemma":"parfaire","pos":"VER"} ,
		{"word":"parfais","word_nosc":"parfais","lemma":"parfaire","pos":"VER"} ,
		{"word":"parfaisais","word_nosc":"parfaisais","lemma":"parfaire","pos":"VER"} ,
		{"word":"parfaisait","word_nosc":"parfaisait","lemma":"parfaire","pos":"VER"} ,
		{"word":"parfait","word_nosc":"parfait","lemma":"parfaire","pos":"VER"} ,
		{"word":"parfaite","word_nosc":"parfaite","lemma":"parfaire","pos":"VER"} ,
		{"word":"parfaites","word_nosc":"parfaites","lemma":"parfaire","pos":"VER"} ,
		{"word":"parfaits","word_nosc":"parfaits","lemma":"parfaire","pos":"VER"} ,
		{"word":"parferont","word_nosc":"parferont","lemma":"parfaire","pos":"VER"} ,
		{"word":"parfit","word_nosc":"parfit","lemma":"parfaire","pos":"VER"} ,
		{"word":"parfuma","word_nosc":"parfuma","lemma":"parfumer","pos":"VER"} ,
		{"word":"parfumaient","word_nosc":"parfumaient","lemma":"parfumer","pos":"VER"} ,
		{"word":"parfumait","word_nosc":"parfumait","lemma":"parfumer","pos":"VER"} ,
		{"word":"parfume","word_nosc":"parfume","lemma":"parfumer","pos":"VER"} ,
		{"word":"parfument","word_nosc":"parfument","lemma":"parfumer","pos":"VER"} ,
		{"word":"parfumer","word_nosc":"parfumer","lemma":"parfumer","pos":"VER"} ,
		{"word":"parfumera","word_nosc":"parfumera","lemma":"parfumer","pos":"VER"} ,
		{"word":"parfumez","word_nosc":"parfumez","lemma":"parfumer","pos":"VER"} ,
		{"word":"parfumé","word_nosc":"parfume","lemma":"parfumer","pos":"VER"} ,
		{"word":"parfumée","word_nosc":"parfumee","lemma":"parfumer","pos":"VER"} ,
		{"word":"parfumées","word_nosc":"parfumees","lemma":"parfumer","pos":"VER"} ,
		{"word":"parfumés","word_nosc":"parfumes","lemma":"parfumer","pos":"VER"} ,
		{"word":"paria","word_nosc":"paria","lemma":"parier","pos":"VER"} ,
		{"word":"pariaient","word_nosc":"pariaient","lemma":"parier","pos":"VER"} ,
		{"word":"pariais","word_nosc":"pariais","lemma":"parier","pos":"VER"} ,
		{"word":"pariait","word_nosc":"pariait","lemma":"parier","pos":"VER"} ,
		{"word":"pariant","word_nosc":"pariant","lemma":"parier","pos":"VER"} ,
		{"word":"parie","word_nosc":"parie","lemma":"parier","pos":"VER"} ,
		{"word":"parient","word_nosc":"parient","lemma":"parier","pos":"VER"} ,
		{"word":"parier","word_nosc":"parier","lemma":"parier","pos":"VER"} ,
		{"word":"parierai","word_nosc":"parierai","lemma":"parier","pos":"VER"} ,
		{"word":"parierais","word_nosc":"parierais","lemma":"parier","pos":"VER"} ,
		{"word":"parierait","word_nosc":"parierait","lemma":"parier","pos":"VER"} ,
		{"word":"parieriez","word_nosc":"parieriez","lemma":"parier","pos":"VER"} ,
		{"word":"paries","word_nosc":"paries","lemma":"parier","pos":"VER"} ,
		{"word":"pariez","word_nosc":"pariez","lemma":"parier","pos":"VER"} ,
		{"word":"parions","word_nosc":"parions","lemma":"parier","pos":"VER"} ,
		{"word":"parié","word_nosc":"parie","lemma":"parier","pos":"VER"} ,
		{"word":"parjurant","word_nosc":"parjurant","lemma":"parjurer","pos":"VER"} ,
		{"word":"parjure","word_nosc":"parjure","lemma":"parjurer","pos":"VER"} ,
		{"word":"parjurer","word_nosc":"parjurer","lemma":"parjurer","pos":"VER"} ,
		{"word":"parjurera","word_nosc":"parjurera","lemma":"parjurer","pos":"VER"} ,
		{"word":"parjures","word_nosc":"parjures","lemma":"parjurer","pos":"VER"} ,
		{"word":"parjuré","word_nosc":"parjure","lemma":"parjurer","pos":"VER"} ,
		{"word":"parjurée","word_nosc":"parjuree","lemma":"parjurer","pos":"VER"} ,
		{"word":"parla","word_nosc":"parla","lemma":"parler","pos":"VER"} ,
		{"word":"parlai","word_nosc":"parlai","lemma":"parler","pos":"VER"} ,
		{"word":"parlaient","word_nosc":"parlaient","lemma":"parler","pos":"VER"} ,
		{"word":"parlais","word_nosc":"parlais","lemma":"parler","pos":"VER"} ,
		{"word":"parlait","word_nosc":"parlait","lemma":"parler","pos":"VER"} ,
		{"word":"parlant","word_nosc":"parlant","lemma":"parler","pos":"VER"} ,
		{"word":"parlas","word_nosc":"parlas","lemma":"parler","pos":"VER"} ,
		{"word":"parlassent","word_nosc":"parlassent","lemma":"parler","pos":"VER"} ,
		{"word":"parlasses","word_nosc":"parlasses","lemma":"parler","pos":"VER"} ,
		{"word":"parle","word_nosc":"parle","lemma":"parler","pos":"VER"} ,
		{"word":"parlementait","word_nosc":"parlementait","lemma":"parlementer","pos":"VER"} ,
		{"word":"parlementant","word_nosc":"parlementant","lemma":"parlementer","pos":"VER"} ,
		{"word":"parlemente","word_nosc":"parlemente","lemma":"parlementer","pos":"VER"} ,
		{"word":"parlementent","word_nosc":"parlementent","lemma":"parlementer","pos":"VER"} ,
		{"word":"parlementer","word_nosc":"parlementer","lemma":"parlementer","pos":"VER"} ,
		{"word":"parlementons","word_nosc":"parlementons","lemma":"parlementer","pos":"VER"} ,
		{"word":"parlementèrent","word_nosc":"parlementerent","lemma":"parlementer","pos":"VER"} ,
		{"word":"parlementé","word_nosc":"parlemente","lemma":"parlementer","pos":"VER"} ,
		{"word":"parlent","word_nosc":"parlent","lemma":"parler","pos":"VER"} ,
		{"word":"parler","word_nosc":"parler","lemma":"parler","pos":"VER"} ,
		{"word":"parlera","word_nosc":"parlera","lemma":"parler","pos":"VER"} ,
		{"word":"parlerai","word_nosc":"parlerai","lemma":"parler","pos":"VER"} ,
		{"word":"parleraient","word_nosc":"parleraient","lemma":"parler","pos":"VER"} ,
		{"word":"parlerais","word_nosc":"parlerais","lemma":"parler","pos":"VER"} ,
		{"word":"parlerait","word_nosc":"parlerait","lemma":"parler","pos":"VER"} ,
		{"word":"parleras","word_nosc":"parleras","lemma":"parler","pos":"VER"} ,
		{"word":"parlerez","word_nosc":"parlerez","lemma":"parler","pos":"VER"} ,
		{"word":"parleriez","word_nosc":"parleriez","lemma":"parler","pos":"VER"} ,
		{"word":"parlerions","word_nosc":"parlerions","lemma":"parler","pos":"VER"} ,
		{"word":"parlerons","word_nosc":"parlerons","lemma":"parler","pos":"VER"} ,
		{"word":"parleront","word_nosc":"parleront","lemma":"parler","pos":"VER"} ,
		{"word":"parles","word_nosc":"parles","lemma":"parler","pos":"VER"} ,
		{"word":"parlez","word_nosc":"parlez","lemma":"parler","pos":"VER"} ,
		{"word":"parliez","word_nosc":"parliez","lemma":"parler","pos":"VER"} ,
		{"word":"parlions","word_nosc":"parlions","lemma":"parler","pos":"VER"} ,
		{"word":"parlons","word_nosc":"parlons","lemma":"parler","pos":"VER"} ,
		{"word":"parlotaient","word_nosc":"parlotaient","lemma":"parloter","pos":"VER"} ,
		{"word":"parloter","word_nosc":"parloter","lemma":"parloter","pos":"VER"} ,
		{"word":"parlâmes","word_nosc":"parlames","lemma":"parler","pos":"VER"} ,
		{"word":"parlât","word_nosc":"parlat","lemma":"parler","pos":"VER"} ,
		{"word":"parlèrent","word_nosc":"parlerent","lemma":"parler","pos":"VER"} ,
		{"word":"parlé","word_nosc":"parle","lemma":"parler","pos":"VER"} ,
		{"word":"parlée","word_nosc":"parlee","lemma":"parler","pos":"VER"} ,
		{"word":"parlées","word_nosc":"parlees","lemma":"parler","pos":"VER"} ,
		{"word":"parlés","word_nosc":"parles","lemma":"parler","pos":"VER"} ,
		{"word":"parodiai","word_nosc":"parodiai","lemma":"parodier","pos":"VER"} ,
		{"word":"parodiait","word_nosc":"parodiait","lemma":"parodier","pos":"VER"} ,
		{"word":"parodiant","word_nosc":"parodiant","lemma":"parodier","pos":"VER"} ,
		{"word":"parodie","word_nosc":"parodie","lemma":"parodier","pos":"VER"} ,
		{"word":"parodier","word_nosc":"parodier","lemma":"parodier","pos":"VER"} ,
		{"word":"parquait","word_nosc":"parquait","lemma":"parquer","pos":"VER"} ,
		{"word":"parque","word_nosc":"parque","lemma":"parquer","pos":"VER"} ,
		{"word":"parquent","word_nosc":"parquent","lemma":"parquer","pos":"VER"} ,
		{"word":"parquer","word_nosc":"parquer","lemma":"parquer","pos":"VER"} ,
		{"word":"parqueté","word_nosc":"parquete","lemma":"parqueter","pos":"VER"} ,
		{"word":"parquetée","word_nosc":"parquetee","lemma":"parqueter","pos":"VER"} ,
		{"word":"parquetées","word_nosc":"parquetees","lemma":"parqueter","pos":"VER"} ,
		{"word":"parquez","word_nosc":"parquez","lemma":"parquer","pos":"VER"} ,
		{"word":"parqué","word_nosc":"parque","lemma":"parquer","pos":"VER"} ,
		{"word":"parquée","word_nosc":"parquee","lemma":"parquer","pos":"VER"} ,
		{"word":"parquées","word_nosc":"parquees","lemma":"parquer","pos":"VER"} ,
		{"word":"parqués","word_nosc":"parques","lemma":"parquer","pos":"VER"} ,
		{"word":"parrainait","word_nosc":"parrainait","lemma":"parrainer","pos":"VER"} ,
		{"word":"parrainant","word_nosc":"parrainant","lemma":"parrainer","pos":"VER"} ,
		{"word":"parraine","word_nosc":"parraine","lemma":"parrainer","pos":"VER"} ,
		{"word":"parrainent","word_nosc":"parrainent","lemma":"parrainer","pos":"VER"} ,
		{"word":"parrainer","word_nosc":"parrainer","lemma":"parrainer","pos":"VER"} ,
		{"word":"parrainerai","word_nosc":"parrainerai","lemma":"parrainer","pos":"VER"} ,
		{"word":"parrainé","word_nosc":"parraine","lemma":"parrainer","pos":"VER"} ,
		{"word":"parrainée","word_nosc":"parrainee","lemma":"parrainer","pos":"VER"} ,
		{"word":"parrainés","word_nosc":"parraines","lemma":"parrainer","pos":"VER"} ,
		{"word":"pars","word_nosc":"pars","lemma":"partir","pos":"VER"} ,
		{"word":"parsemaient","word_nosc":"parsemaient","lemma":"parsemer","pos":"VER"} ,
		{"word":"parsemant","word_nosc":"parsemant","lemma":"parsemer","pos":"VER"} ,
		{"word":"parsemer","word_nosc":"parsemer","lemma":"parsemer","pos":"VER"} ,
		{"word":"parsemé","word_nosc":"parseme","lemma":"parsemer","pos":"VER"} ,
		{"word":"parsemée","word_nosc":"parsemee","lemma":"parsemer","pos":"VER"} ,
		{"word":"parsemées","word_nosc":"parsemees","lemma":"parsemer","pos":"VER"} ,
		{"word":"parsemés","word_nosc":"parsemes","lemma":"parsemer","pos":"VER"} ,
		{"word":"parsème","word_nosc":"parseme","lemma":"parsemer","pos":"VER"} ,
		{"word":"parsèment","word_nosc":"parsement","lemma":"parsemer","pos":"VER"} ,
		{"word":"part","word_nosc":"part","lemma":"partir","pos":"VER"} ,
		{"word":"partage","word_nosc":"partage","lemma":"partager","pos":"VER"} ,
		{"word":"partagea","word_nosc":"partagea","lemma":"partager","pos":"VER"} ,
		{"word":"partageai","word_nosc":"partageai","lemma":"partager","pos":"VER"} ,
		{"word":"partageaient","word_nosc":"partageaient","lemma":"partager","pos":"VER"} ,
		{"word":"partageais","word_nosc":"partageais","lemma":"partager","pos":"VER"} ,
		{"word":"partageait","word_nosc":"partageait","lemma":"partager","pos":"VER"} ,
		{"word":"partageant","word_nosc":"partageant","lemma":"partager","pos":"VER"} ,
		{"word":"partagent","word_nosc":"partagent","lemma":"partager","pos":"VER"} ,
		{"word":"partageons","word_nosc":"partageons","lemma":"partager","pos":"VER"} ,
		{"word":"partager","word_nosc":"partager","lemma":"partager","pos":"VER"} ,
		{"word":"partagera","word_nosc":"partagera","lemma":"partager","pos":"VER"} ,
		{"word":"partagerai","word_nosc":"partagerai","lemma":"partager","pos":"VER"} ,
		{"word":"partageraient","word_nosc":"partageraient","lemma":"partager","pos":"VER"} ,
		{"word":"partagerais","word_nosc":"partagerais","lemma":"partager","pos":"VER"} ,
		{"word":"partagerait","word_nosc":"partagerait","lemma":"partager","pos":"VER"} ,
		{"word":"partageras","word_nosc":"partageras","lemma":"partager","pos":"VER"} ,
		{"word":"partagerez","word_nosc":"partagerez","lemma":"partager","pos":"VER"} ,
		{"word":"partageriez","word_nosc":"partageriez","lemma":"partager","pos":"VER"} ,
		{"word":"partagerions","word_nosc":"partagerions","lemma":"partager","pos":"VER"} ,
		{"word":"partagerons","word_nosc":"partagerons","lemma":"partager","pos":"VER"} ,
		{"word":"partageront","word_nosc":"partageront","lemma":"partager","pos":"VER"} ,
		{"word":"partages","word_nosc":"partages","lemma":"partager","pos":"VER"} ,
		{"word":"partagez","word_nosc":"partagez","lemma":"partager","pos":"VER"} ,
		{"word":"partageâmes","word_nosc":"partageames","lemma":"partager","pos":"VER"} ,
		{"word":"partageât","word_nosc":"partageat","lemma":"partager","pos":"VER"} ,
		{"word":"partagiez","word_nosc":"partagiez","lemma":"partager","pos":"VER"} ,
		{"word":"partagions","word_nosc":"partagions","lemma":"partager","pos":"VER"} ,
		{"word":"partagèrent","word_nosc":"partagerent","lemma":"partager","pos":"VER"} ,
		{"word":"partagé","word_nosc":"partage","lemma":"partager","pos":"VER"} ,
		{"word":"partagée","word_nosc":"partagee","lemma":"partager","pos":"VER"} ,
		{"word":"partagées","word_nosc":"partagees","lemma":"partager","pos":"VER"} ,
		{"word":"partagés","word_nosc":"partages","lemma":"partager","pos":"VER"} ,
		{"word":"partaient","word_nosc":"partaient","lemma":"partir","pos":"VER"} ,
		{"word":"partais","word_nosc":"partais","lemma":"partir","pos":"VER"} ,
		{"word":"partait","word_nosc":"partait","lemma":"partir","pos":"VER"} ,
		{"word":"partant","word_nosc":"partant","lemma":"partir","pos":"VER"} ,
		{"word":"parte","word_nosc":"parte","lemma":"partir","pos":"VER"} ,
		{"word":"partent","word_nosc":"partent","lemma":"partir","pos":"VER"} ,
		{"word":"partes","word_nosc":"partes","lemma":"partir","pos":"VER"} ,
		{"word":"partez","word_nosc":"partez","lemma":"partir","pos":"VER"} ,
		{"word":"parti","word_nosc":"parti","lemma":"partir","pos":"VER"} ,
		{"word":"participa","word_nosc":"participa","lemma":"participer","pos":"VER"} ,
		{"word":"participai","word_nosc":"participai","lemma":"participer","pos":"VER"} ,
		{"word":"participaient","word_nosc":"participaient","lemma":"participer","pos":"VER"} ,
		{"word":"participais","word_nosc":"participais","lemma":"participer","pos":"VER"} ,
		{"word":"participait","word_nosc":"participait","lemma":"participer","pos":"VER"} ,
		{"word":"participant","word_nosc":"participant","lemma":"participer","pos":"VER"} ,
		{"word":"participe","word_nosc":"participe","lemma":"participer","pos":"VER"} ,
		{"word":"participent","word_nosc":"participent","lemma":"participer","pos":"VER"} ,
		{"word":"participer","word_nosc":"participer","lemma":"participer","pos":"VER"} ,
		{"word":"participera","word_nosc":"participera","lemma":"participer","pos":"VER"} ,
		{"word":"participerai","word_nosc":"participerai","lemma":"participer","pos":"VER"} ,
		{"word":"participeraient","word_nosc":"participeraient","lemma":"participer","pos":"VER"} ,
		{"word":"participerais","word_nosc":"participerais","lemma":"participer","pos":"VER"} ,
		{"word":"participerait","word_nosc":"participerait","lemma":"participer","pos":"VER"} ,
		{"word":"participeras","word_nosc":"participeras","lemma":"participer","pos":"VER"} ,
		{"word":"participerez","word_nosc":"participerez","lemma":"participer","pos":"VER"} ,
		{"word":"participerons","word_nosc":"participerons","lemma":"participer","pos":"VER"} ,
		{"word":"participeront","word_nosc":"participeront","lemma":"participer","pos":"VER"} ,
		{"word":"participes","word_nosc":"participes","lemma":"participer","pos":"VER"} ,
		{"word":"participez","word_nosc":"participez","lemma":"participer","pos":"VER"} ,
		{"word":"participiez","word_nosc":"participiez","lemma":"participer","pos":"VER"} ,
		{"word":"participions","word_nosc":"participions","lemma":"participer","pos":"VER"} ,
		{"word":"participons","word_nosc":"participons","lemma":"participer","pos":"VER"} ,
		{"word":"participât","word_nosc":"participat","lemma":"participer","pos":"VER"} ,
		{"word":"participèrent","word_nosc":"participerent","lemma":"participer","pos":"VER"} ,
		{"word":"participé","word_nosc":"participe","lemma":"participer","pos":"VER"} ,
		{"word":"participés","word_nosc":"participes","lemma":"participer","pos":"VER"} ,
		{"word":"particularisées","word_nosc":"particularisees","lemma":"particulariser","pos":"VER"} ,
		{"word":"partie","word_nosc":"partie","lemma":"partir","pos":"VER"} ,
		{"word":"parties","word_nosc":"parties","lemma":"partir","pos":"VER"} ,
		{"word":"partiez","word_nosc":"partiez","lemma":"partir","pos":"VER"} ,
		{"word":"partions","word_nosc":"partions","lemma":"partir","pos":"VER"} ,
		{"word":"partir","word_nosc":"partir","lemma":"partir","pos":"VER"} ,
		{"word":"partira","word_nosc":"partira","lemma":"partir","pos":"VER"} ,
		{"word":"partirai","word_nosc":"partirai","lemma":"partir","pos":"VER"} ,
		{"word":"partiraient","word_nosc":"partiraient","lemma":"partir","pos":"VER"} ,
		{"word":"partirais","word_nosc":"partirais","lemma":"partir","pos":"VER"} ,
		{"word":"partirait","word_nosc":"partirait","lemma":"partir","pos":"VER"} ,
		{"word":"partiras","word_nosc":"partiras","lemma":"partir","pos":"VER"} ,
		{"word":"partirent","word_nosc":"partirent","lemma":"partir","pos":"VER"} ,
		{"word":"partirez","word_nosc":"partirez","lemma":"partir","pos":"VER"} ,
		{"word":"partiriez","word_nosc":"partiriez","lemma":"partir","pos":"VER"} ,
		{"word":"partirions","word_nosc":"partirions","lemma":"partir","pos":"VER"} ,
		{"word":"partirons","word_nosc":"partirons","lemma":"partir","pos":"VER"} ,
		{"word":"partiront","word_nosc":"partiront","lemma":"partir","pos":"VER"} ,
		{"word":"partis","word_nosc":"partis","lemma":"partir","pos":"VER"} ,
		{"word":"partisse","word_nosc":"partisse","lemma":"partir","pos":"VER"} ,
		{"word":"partissent","word_nosc":"partissent","lemma":"partir","pos":"VER"} ,
		{"word":"partit","word_nosc":"partit","lemma":"partir","pos":"VER"} ,
		{"word":"partons","word_nosc":"partons","lemma":"partir","pos":"VER"} ,
		{"word":"partouser","word_nosc":"partouser","lemma":"partouser","pos":"VER"} ,
		{"word":"partouzait","word_nosc":"partouzait","lemma":"partouzer","pos":"VER"} ,
		{"word":"partouzent","word_nosc":"partouzent","lemma":"partouzer","pos":"VER"} ,
		{"word":"partouzer","word_nosc":"partouzer","lemma":"partouzer","pos":"VER"} ,
		{"word":"partîmes","word_nosc":"partimes","lemma":"partir","pos":"VER"} ,
		{"word":"partît","word_nosc":"partit","lemma":"partir","pos":"VER"} ,
		{"word":"paru","word_nosc":"paru","lemma":"paraître","pos":"VER"} ,
		{"word":"parue","word_nosc":"parue","lemma":"paraître","pos":"VER"} ,
		{"word":"parurent","word_nosc":"parurent","lemma":"paraître","pos":"VER"} ,
		{"word":"parus","word_nosc":"parus","lemma":"paraître","pos":"VER"} ,
		{"word":"parussent","word_nosc":"parussent","lemma":"paraître","pos":"VER"} ,
		{"word":"parut","word_nosc":"parut","lemma":"paraître","pos":"VER"} ,
		{"word":"parvenaient","word_nosc":"parvenaient","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvenais","word_nosc":"parvenais","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvenait","word_nosc":"parvenait","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvenant","word_nosc":"parvenant","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvenez","word_nosc":"parvenez","lemma":"parvenir","pos":"VER"} ,
		{"word":"parveniez","word_nosc":"parveniez","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvenions","word_nosc":"parvenions","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvenir","word_nosc":"parvenir","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvenons","word_nosc":"parvenons","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvenu","word_nosc":"parvenu","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvenue","word_nosc":"parvenue","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvenues","word_nosc":"parvenues","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvenus","word_nosc":"parvenus","lemma":"parvenir","pos":"VER"} ,
		{"word":"parviendra","word_nosc":"parviendra","lemma":"parvenir","pos":"VER"} ,
		{"word":"parviendrai","word_nosc":"parviendrai","lemma":"parvenir","pos":"VER"} ,
		{"word":"parviendraient","word_nosc":"parviendraient","lemma":"parvenir","pos":"VER"} ,
		{"word":"parviendrais","word_nosc":"parviendrais","lemma":"parvenir","pos":"VER"} ,
		{"word":"parviendrait","word_nosc":"parviendrait","lemma":"parvenir","pos":"VER"} ,
		{"word":"parviendras","word_nosc":"parviendras","lemma":"parvenir","pos":"VER"} ,
		{"word":"parviendrez","word_nosc":"parviendrez","lemma":"parvenir","pos":"VER"} ,
		{"word":"parviendrions","word_nosc":"parviendrions","lemma":"parvenir","pos":"VER"} ,
		{"word":"parviendrons","word_nosc":"parviendrons","lemma":"parvenir","pos":"VER"} ,
		{"word":"parviendront","word_nosc":"parviendront","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvienne","word_nosc":"parvienne","lemma":"parvenir","pos":"VER"} ,
		{"word":"parviennent","word_nosc":"parviennent","lemma":"parvenir","pos":"VER"} ,
		{"word":"parviens","word_nosc":"parviens","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvient","word_nosc":"parvient","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvinrent","word_nosc":"parvinrent","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvins","word_nosc":"parvins","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvinssent","word_nosc":"parvinssent","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvint","word_nosc":"parvint","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvînmes","word_nosc":"parvinmes","lemma":"parvenir","pos":"VER"} ,
		{"word":"parvînt","word_nosc":"parvint","lemma":"parvenir","pos":"VER"} ,
		{"word":"paré","word_nosc":"pare","lemma":"parer","pos":"VER"} ,
		{"word":"parée","word_nosc":"paree","lemma":"parer","pos":"VER"} ,
		{"word":"parées","word_nosc":"parees","lemma":"parer","pos":"VER"} ,
		{"word":"parés","word_nosc":"pares","lemma":"parer","pos":"VER"} ,
		{"word":"parût","word_nosc":"parut","lemma":"paraître","pos":"VER"} ,
		{"word":"passa","word_nosc":"passa","lemma":"passer","pos":"VER"} ,
		{"word":"passai","word_nosc":"passai","lemma":"passer","pos":"VER"} ,
		{"word":"passaient","word_nosc":"passaient","lemma":"passer","pos":"VER"} ,
		{"word":"passais","word_nosc":"passais","lemma":"passer","pos":"VER"} ,
		{"word":"passait","word_nosc":"passait","lemma":"passer","pos":"VER"} ,
		{"word":"passant","word_nosc":"passant","lemma":"passer","pos":"VER"} ,
		{"word":"passas","word_nosc":"passas","lemma":"passer","pos":"VER"} ,
		{"word":"passe","word_nosc":"passe","lemma":"passer","pos":"VER"} ,
		{"word":"passementaient","word_nosc":"passementaient","lemma":"passementer","pos":"VER"} ,
		{"word":"passementent","word_nosc":"passementent","lemma":"passementer","pos":"VER"} ,
		{"word":"passementé","word_nosc":"passemente","lemma":"passementer","pos":"VER"} ,
		{"word":"passent","word_nosc":"passent","lemma":"passer","pos":"VER"} ,
		{"word":"passer","word_nosc":"passer","lemma":"passer","pos":"VER"} ,
		{"word":"passer","word_nosc":"passer","lemma":"prendre","pos":"VER"} ,
		{"word":"passera","word_nosc":"passera","lemma":"passer","pos":"VER"} ,
		{"word":"passerai","word_nosc":"passerai","lemma":"passer","pos":"VER"} ,
		{"word":"passeraient","word_nosc":"passeraient","lemma":"passer","pos":"VER"} ,
		{"word":"passerais","word_nosc":"passerais","lemma":"passer","pos":"VER"} ,
		{"word":"passerait","word_nosc":"passerait","lemma":"passer","pos":"VER"} ,
		{"word":"passeras","word_nosc":"passeras","lemma":"passer","pos":"VER"} ,
		{"word":"passerez","word_nosc":"passerez","lemma":"passer","pos":"VER"} ,
		{"word":"passeriez","word_nosc":"passeriez","lemma":"passer","pos":"VER"} ,
		{"word":"passerions","word_nosc":"passerions","lemma":"passer","pos":"VER"} ,
		{"word":"passerons","word_nosc":"passerons","lemma":"passer","pos":"VER"} ,
		{"word":"passeront","word_nosc":"passeront","lemma":"passer","pos":"VER"} ,
		{"word":"passes","word_nosc":"passes","lemma":"passer","pos":"VER"} ,
		{"word":"passez","word_nosc":"passez","lemma":"passer","pos":"VER"} ,
		{"word":"passiez","word_nosc":"passiez","lemma":"passer","pos":"VER"} ,
		{"word":"passionna","word_nosc":"passionna","lemma":"passionner","pos":"VER"} ,
		{"word":"passionnai","word_nosc":"passionnai","lemma":"passionner","pos":"VER"} ,
		{"word":"passionnaient","word_nosc":"passionnaient","lemma":"passionner","pos":"VER"} ,
		{"word":"passionnais","word_nosc":"passionnais","lemma":"passionner","pos":"VER"} ,
		{"word":"passionnait","word_nosc":"passionnait","lemma":"passionner","pos":"VER"} ,
		{"word":"passionnant","word_nosc":"passionnant","lemma":"passionner","pos":"VER"} ,
		{"word":"passionne","word_nosc":"passionne","lemma":"passionner","pos":"VER"} ,
		{"word":"passionnent","word_nosc":"passionnent","lemma":"passionner","pos":"VER"} ,
		{"word":"passionner","word_nosc":"passionner","lemma":"passionner","pos":"VER"} ,
		{"word":"passionnera","word_nosc":"passionnera","lemma":"passionner","pos":"VER"} ,
		{"word":"passionnerait","word_nosc":"passionnerait","lemma":"passionner","pos":"VER"} ,
		{"word":"passionneras","word_nosc":"passionneras","lemma":"passionner","pos":"VER"} ,
		{"word":"passionnons","word_nosc":"passionnons","lemma":"passionner","pos":"VER"} ,
		{"word":"passionnèrent","word_nosc":"passionnerent","lemma":"passionner","pos":"VER"} ,
		{"word":"passionné","word_nosc":"passionne","lemma":"passionner","pos":"VER"} ,
		{"word":"passionnée","word_nosc":"passionnee","lemma":"passionner","pos":"VER"} ,
		{"word":"passionnées","word_nosc":"passionnees","lemma":"passionner","pos":"VER"} ,
		{"word":"passionnés","word_nosc":"passionnes","lemma":"passionner","pos":"VER"} ,
		{"word":"passions","word_nosc":"passions","lemma":"passer","pos":"VER"} ,
		{"word":"passive","word_nosc":"passive","lemma":"passiver","pos":"VER"} ,
		{"word":"passiver","word_nosc":"passiver","lemma":"passiver","pos":"VER"} ,
		{"word":"passons","word_nosc":"passons","lemma":"passer","pos":"VER"} ,
		{"word":"passâmes","word_nosc":"passames","lemma":"passer","pos":"VER"} ,
		{"word":"passât","word_nosc":"passat","lemma":"passer","pos":"VER"} ,
		{"word":"passâtes","word_nosc":"passates","lemma":"passer","pos":"VER"} ,
		{"word":"passèrent","word_nosc":"passerent","lemma":"passer","pos":"VER"} ,
		{"word":"passé","word_nosc":"passe","lemma":"passer","pos":"VER"} ,
		{"word":"passée","word_nosc":"passee","lemma":"passer","pos":"VER"} ,
		{"word":"passées","word_nosc":"passees","lemma":"passer","pos":"VER"} ,
		{"word":"passés","word_nosc":"passes","lemma":"passer","pos":"VER"} ,
		{"word":"pasteuriser","word_nosc":"pasteuriser","lemma":"pasteuriser","pos":"VER"} ,
		{"word":"pasticha","word_nosc":"pasticha","lemma":"pasticher","pos":"VER"} ,
		{"word":"pastichai","word_nosc":"pastichai","lemma":"pasticher","pos":"VER"} ,
		{"word":"pastichait","word_nosc":"pastichait","lemma":"pasticher","pos":"VER"} ,
		{"word":"pasticher","word_nosc":"pasticher","lemma":"pasticher","pos":"VER"} ,
		{"word":"pastiché","word_nosc":"pastiche","lemma":"pasticher","pos":"VER"} ,
		{"word":"pastiller","word_nosc":"pastiller","lemma":"pastiller","pos":"VER"} ,
		{"word":"patafiole","word_nosc":"patafiole","lemma":"patafioler","pos":"VER"} ,
		{"word":"patauge","word_nosc":"patauge","lemma":"patauger","pos":"VER"} ,
		{"word":"pataugea","word_nosc":"pataugea","lemma":"patauger","pos":"VER"} ,
		{"word":"pataugeaient","word_nosc":"pataugeaient","lemma":"patauger","pos":"VER"} ,
		{"word":"pataugeais","word_nosc":"pataugeais","lemma":"patauger","pos":"VER"} ,
		{"word":"pataugeait","word_nosc":"pataugeait","lemma":"patauger","pos":"VER"} ,
		{"word":"pataugeant","word_nosc":"pataugeant","lemma":"patauger","pos":"VER"} ,
		{"word":"pataugent","word_nosc":"pataugent","lemma":"patauger","pos":"VER"} ,
		{"word":"pataugeons","word_nosc":"pataugeons","lemma":"patauger","pos":"VER"} ,
		{"word":"patauger","word_nosc":"patauger","lemma":"patauger","pos":"VER"} ,
		{"word":"pataugera","word_nosc":"pataugera","lemma":"patauger","pos":"VER"} ,
		{"word":"pataugerais","word_nosc":"pataugerais","lemma":"patauger","pos":"VER"} ,
		{"word":"pataugerons","word_nosc":"pataugerons","lemma":"patauger","pos":"VER"} ,
		{"word":"patauges","word_nosc":"patauges","lemma":"patauger","pos":"VER"} ,
		{"word":"pataugez","word_nosc":"pataugez","lemma":"patauger","pos":"VER"} ,
		{"word":"pataugèrent","word_nosc":"pataugerent","lemma":"patauger","pos":"VER"} ,
		{"word":"pataugé","word_nosc":"patauge","lemma":"patauger","pos":"VER"} ,
		{"word":"patentés","word_nosc":"patentes","lemma":"patenter","pos":"VER"} ,
		{"word":"patienta","word_nosc":"patienta","lemma":"patienter","pos":"VER"} ,
		{"word":"patientai","word_nosc":"patientai","lemma":"patienter","pos":"VER"} ,
		{"word":"patientaient","word_nosc":"patientaient","lemma":"patienter","pos":"VER"} ,
		{"word":"patientait","word_nosc":"patientait","lemma":"patienter","pos":"VER"} ,
		{"word":"patientant","word_nosc":"patientant","lemma":"patienter","pos":"VER"} ,
		{"word":"patiente","word_nosc":"patiente","lemma":"patienter","pos":"VER"} ,
		{"word":"patientent","word_nosc":"patientent","lemma":"patienter","pos":"VER"} ,
		{"word":"patienter","word_nosc":"patienter","lemma":"patienter","pos":"VER"} ,
		{"word":"patientera","word_nosc":"patientera","lemma":"patienter","pos":"VER"} ,
		{"word":"patienteraient","word_nosc":"patienteraient","lemma":"patienter","pos":"VER"} ,
		{"word":"patienterait","word_nosc":"patienterait","lemma":"patienter","pos":"VER"} ,
		{"word":"patienteras","word_nosc":"patienteras","lemma":"patienter","pos":"VER"} ,
		{"word":"patienterez","word_nosc":"patienterez","lemma":"patienter","pos":"VER"} ,
		{"word":"patienterons","word_nosc":"patienterons","lemma":"patienter","pos":"VER"} ,
		{"word":"patientes","word_nosc":"patientes","lemma":"patienter","pos":"VER"} ,
		{"word":"patientez","word_nosc":"patientez","lemma":"patienter","pos":"VER"} ,
		{"word":"patientions","word_nosc":"patientions","lemma":"patienter","pos":"VER"} ,
		{"word":"patientons","word_nosc":"patientons","lemma":"patienter","pos":"VER"} ,
		{"word":"patientèrent","word_nosc":"patienterent","lemma":"patienter","pos":"VER"} ,
		{"word":"patienté","word_nosc":"patiente","lemma":"patienter","pos":"VER"} ,
		{"word":"patina","word_nosc":"patina","lemma":"patiner","pos":"VER"} ,
		{"word":"patinaient","word_nosc":"patinaient","lemma":"patiner","pos":"VER"} ,
		{"word":"patinais","word_nosc":"patinais","lemma":"patiner","pos":"VER"} ,
		{"word":"patinait","word_nosc":"patinait","lemma":"patiner","pos":"VER"} ,
		{"word":"patinant","word_nosc":"patinant","lemma":"patiner","pos":"VER"} ,
		{"word":"patine","word_nosc":"patine","lemma":"patiner","pos":"VER"} ,
		{"word":"patinent","word_nosc":"patinent","lemma":"patiner","pos":"VER"} ,
		{"word":"patiner","word_nosc":"patiner","lemma":"patiner","pos":"VER"} ,
		{"word":"patinerais","word_nosc":"patinerais","lemma":"patiner","pos":"VER"} ,
		{"word":"patinerons","word_nosc":"patinerons","lemma":"patiner","pos":"VER"} ,
		{"word":"patines","word_nosc":"patines","lemma":"patiner","pos":"VER"} ,
		{"word":"patinez","word_nosc":"patinez","lemma":"patiner","pos":"VER"} ,
		{"word":"patiniez","word_nosc":"patiniez","lemma":"patiner","pos":"VER"} ,
		{"word":"patinions","word_nosc":"patinions","lemma":"patiner","pos":"VER"} ,
		{"word":"patinons","word_nosc":"patinons","lemma":"patiner","pos":"VER"} ,
		{"word":"patiné","word_nosc":"patine","lemma":"patiner","pos":"VER"} ,
		{"word":"patinée","word_nosc":"patinee","lemma":"patiner","pos":"VER"} ,
		{"word":"patinées","word_nosc":"patinees","lemma":"patiner","pos":"VER"} ,
		{"word":"patinés","word_nosc":"patines","lemma":"patiner","pos":"VER"} ,
		{"word":"patoise","word_nosc":"patoise","lemma":"patoiser","pos":"VER"} ,
		{"word":"patouille","word_nosc":"patouille","lemma":"patouiller","pos":"VER"} ,
		{"word":"patouillons","word_nosc":"patouillons","lemma":"patouiller","pos":"VER"} ,
		{"word":"patronna","word_nosc":"patronna","lemma":"patronner","pos":"VER"} ,
		{"word":"patronner","word_nosc":"patronner","lemma":"patronner","pos":"VER"} ,
		{"word":"patronné","word_nosc":"patronne","lemma":"patronner","pos":"VER"} ,
		{"word":"patronnée","word_nosc":"patronnee","lemma":"patronner","pos":"VER"} ,
		{"word":"patronnés","word_nosc":"patronnes","lemma":"patronner","pos":"VER"} ,
		{"word":"patrouilla","word_nosc":"patrouilla","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouillaient","word_nosc":"patrouillaient","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouillais","word_nosc":"patrouillais","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouillait","word_nosc":"patrouillait","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouillant","word_nosc":"patrouillant","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouille","word_nosc":"patrouille","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouillent","word_nosc":"patrouillent","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouiller","word_nosc":"patrouiller","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouillerai","word_nosc":"patrouillerai","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouilleras","word_nosc":"patrouilleras","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouillerez","word_nosc":"patrouillerez","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouilleront","word_nosc":"patrouilleront","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouilles","word_nosc":"patrouilles","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouillez","word_nosc":"patrouillez","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouilliez","word_nosc":"patrouilliez","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouillions","word_nosc":"patrouillions","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouillé","word_nosc":"patrouille","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouillée","word_nosc":"patrouillee","lemma":"patrouiller","pos":"VER"} ,
		{"word":"patrouillées","word_nosc":"patrouillees","lemma":"patrouiller","pos":"VER"} ,
		{"word":"pauma","word_nosc":"pauma","lemma":"paumer","pos":"VER"} ,
		{"word":"paumais","word_nosc":"paumais","lemma":"paumer","pos":"VER"} ,
		{"word":"paumait","word_nosc":"paumait","lemma":"paumer","pos":"VER"} ,
		{"word":"paumant","word_nosc":"paumant","lemma":"paumer","pos":"VER"} ,
		{"word":"paume","word_nosc":"paume","lemma":"paumer","pos":"VER"} ,
		{"word":"paument","word_nosc":"paument","lemma":"paumer","pos":"VER"} ,
		{"word":"paumer","word_nosc":"paumer","lemma":"paumer","pos":"VER"} ,
		{"word":"paumes","word_nosc":"paumes","lemma":"paumer","pos":"VER"} ,
		{"word":"paumé","word_nosc":"paume","lemma":"paumer","pos":"VER"} ,
		{"word":"paumée","word_nosc":"paumee","lemma":"paumer","pos":"VER"} ,
		{"word":"paumées","word_nosc":"paumees","lemma":"paumer","pos":"VER"} ,
		{"word":"paumés","word_nosc":"paumes","lemma":"paumer","pos":"VER"} ,
		{"word":"paupérise","word_nosc":"pauperise","lemma":"paupériser","pos":"VER"} ,
		{"word":"pauser","word_nosc":"pauser","lemma":"pauser","pos":"VER"} ,
		{"word":"pausez","word_nosc":"pausez","lemma":"pauser","pos":"VER"} ,
		{"word":"pavait","word_nosc":"pavait","lemma":"paver","pos":"VER"} ,
		{"word":"pavana","word_nosc":"pavana","lemma":"pavaner","pos":"VER"} ,
		{"word":"pavanaient","word_nosc":"pavanaient","lemma":"pavaner","pos":"VER"} ,
		{"word":"pavanait","word_nosc":"pavanait","lemma":"pavaner","pos":"VER"} ,
		{"word":"pavanant","word_nosc":"pavanant","lemma":"pavaner","pos":"VER"} ,
		{"word":"pavane","word_nosc":"pavane","lemma":"pavaner","pos":"VER"} ,
		{"word":"pavanent","word_nosc":"pavanent","lemma":"pavaner","pos":"VER"} ,
		{"word":"pavaner","word_nosc":"pavaner","lemma":"pavaner","pos":"VER"} ,
		{"word":"pavanera","word_nosc":"pavanera","lemma":"pavaner","pos":"VER"} ,
		{"word":"pavanerez","word_nosc":"pavanerez","lemma":"pavaner","pos":"VER"} ,
		{"word":"pavanes","word_nosc":"pavanes","lemma":"pavaner","pos":"VER"} ,
		{"word":"pavanons","word_nosc":"pavanons","lemma":"pavaner","pos":"VER"} ,
		{"word":"pavané","word_nosc":"pavane","lemma":"pavaner","pos":"VER"} ,
		{"word":"pave","word_nosc":"pave","lemma":"paver","pos":"VER"} ,
		{"word":"paver","word_nosc":"paver","lemma":"paver","pos":"VER"} ,
		{"word":"pavez","word_nosc":"pavez","lemma":"paver","pos":"VER"} ,
		{"word":"pavoisa","word_nosc":"pavoisa","lemma":"pavoiser","pos":"VER"} ,
		{"word":"pavoisaient","word_nosc":"pavoisaient","lemma":"pavoiser","pos":"VER"} ,
		{"word":"pavoisait","word_nosc":"pavoisait","lemma":"pavoiser","pos":"VER"} ,
		{"word":"pavoise","word_nosc":"pavoise","lemma":"pavoiser","pos":"VER"} ,
		{"word":"pavoisent","word_nosc":"pavoisent","lemma":"pavoiser","pos":"VER"} ,
		{"word":"pavoiser","word_nosc":"pavoiser","lemma":"pavoiser","pos":"VER"} ,
		{"word":"pavoisera","word_nosc":"pavoisera","lemma":"pavoiser","pos":"VER"} ,
		{"word":"pavoiseraient","word_nosc":"pavoiseraient","lemma":"pavoiser","pos":"VER"} ,
		{"word":"pavoisé","word_nosc":"pavoise","lemma":"pavoiser","pos":"VER"} ,
		{"word":"pavoisée","word_nosc":"pavoisee","lemma":"pavoiser","pos":"VER"} ,
		{"word":"pavoisées","word_nosc":"pavoisees","lemma":"pavoiser","pos":"VER"} ,
		{"word":"pavoisés","word_nosc":"pavoises","lemma":"pavoiser","pos":"VER"} ,
		{"word":"pavé","word_nosc":"pave","lemma":"paver","pos":"VER"} ,
		{"word":"pavés","word_nosc":"paves","lemma":"paver","pos":"VER"} ,
		{"word":"paya","word_nosc":"paya","lemma":"payer","pos":"VER"} ,
		{"word":"payai","word_nosc":"payai","lemma":"payer","pos":"VER"} ,
		{"word":"payaient","word_nosc":"payaient","lemma":"payer","pos":"VER"} ,
		{"word":"payais","word_nosc":"payais","lemma":"payer","pos":"VER"} ,
		{"word":"payait","word_nosc":"payait","lemma":"payer","pos":"VER"} ,
		{"word":"payant","word_nosc":"payant","lemma":"payer","pos":"VER"} ,
		{"word":"payassent","word_nosc":"payassent","lemma":"payer","pos":"VER"} ,
		{"word":"payassiez","word_nosc":"payassiez","lemma":"payer","pos":"VER"} ,
		{"word":"paye","word_nosc":"paye","lemma":"payer","pos":"VER"} ,
		{"word":"payent","word_nosc":"payent","lemma":"payer","pos":"VER"} ,
		{"word":"payer","word_nosc":"payer","lemma":"payer","pos":"VER"} ,
		{"word":"payera","word_nosc":"payera","lemma":"payer","pos":"VER"} ,
		{"word":"payerai","word_nosc":"payerai","lemma":"payer","pos":"VER"} ,
		{"word":"payeraient","word_nosc":"payeraient","lemma":"payer","pos":"VER"} ,
		{"word":"payerais","word_nosc":"payerais","lemma":"payer","pos":"VER"} ,
		{"word":"payerait","word_nosc":"payerait","lemma":"payer","pos":"VER"} ,
		{"word":"payeras","word_nosc":"payeras","lemma":"payer","pos":"VER"} ,
		{"word":"payerez","word_nosc":"payerez","lemma":"payer","pos":"VER"} ,
		{"word":"payeriez","word_nosc":"payeriez","lemma":"payer","pos":"VER"} ,
		{"word":"payerons","word_nosc":"payerons","lemma":"payer","pos":"VER"} ,
		{"word":"payeront","word_nosc":"payeront","lemma":"payer","pos":"VER"} ,
		{"word":"payes","word_nosc":"payes","lemma":"payer","pos":"VER"} ,
		{"word":"payez","word_nosc":"payez","lemma":"payer","pos":"VER"} ,
		{"word":"payiez","word_nosc":"payiez","lemma":"payer","pos":"VER"} ,
		{"word":"payions","word_nosc":"payions","lemma":"payer","pos":"VER"} ,
		{"word":"payons","word_nosc":"payons","lemma":"payer","pos":"VER"} ,
		{"word":"payâmes","word_nosc":"payames","lemma":"payer","pos":"VER"} ,
		{"word":"payât","word_nosc":"payat","lemma":"payer","pos":"VER"} ,
		{"word":"payèrent","word_nosc":"payerent","lemma":"payer","pos":"VER"} ,
		{"word":"payé","word_nosc":"paye","lemma":"payer","pos":"VER"} ,
		{"word":"payée","word_nosc":"payee","lemma":"payer","pos":"VER"} ,
		{"word":"payées","word_nosc":"payees","lemma":"payer","pos":"VER"} ,
		{"word":"payés","word_nosc":"payes","lemma":"payer","pos":"VER"} ,
		{"word":"paît","word_nosc":"pait","lemma":"paître","pos":"VER"} ,
		{"word":"paître","word_nosc":"paitre","lemma":"paître","pos":"VER"} ,
		{"word":"paîtront","word_nosc":"paitront","lemma":"paître","pos":"VER"} ,
		{"word":"peaufina","word_nosc":"peaufina","lemma":"peaufiner","pos":"VER"} ,
		{"word":"peaufinait","word_nosc":"peaufinait","lemma":"peaufiner","pos":"VER"} ,
		{"word":"peaufinant","word_nosc":"peaufinant","lemma":"peaufiner","pos":"VER"} ,
		{"word":"peaufiner","word_nosc":"peaufiner","lemma":"peaufiner","pos":"VER"} ,
		{"word":"peaufiné","word_nosc":"peaufine","lemma":"peaufiner","pos":"VER"} ,
		{"word":"peaufinée","word_nosc":"peaufinee","lemma":"peaufiner","pos":"VER"} ,
		{"word":"peaufinés","word_nosc":"peaufines","lemma":"peaufiner","pos":"VER"} ,
		{"word":"peigna","word_nosc":"peigna","lemma":"peigner","pos":"VER"} ,
		{"word":"peignaient","word_nosc":"peignaient","lemma":"peigner","pos":"VER"} ,
		{"word":"peignaient","word_nosc":"peignaient","lemma":"peindre","pos":"VER"} ,
		{"word":"peignais","word_nosc":"peignais","lemma":"peigner","pos":"VER"} ,
		{"word":"peignais","word_nosc":"peignais","lemma":"peindre","pos":"VER"} ,
		{"word":"peignait","word_nosc":"peignait","lemma":"peindre","pos":"VER"} ,
		{"word":"peignant","word_nosc":"peignant","lemma":"peindre","pos":"VER"} ,
		{"word":"peigne","word_nosc":"peigne","lemma":"peigner","pos":"VER"} ,
		{"word":"peignent","word_nosc":"peignent","lemma":"peigner","pos":"VER"} ,
		{"word":"peignent","word_nosc":"peignent","lemma":"peindre","pos":"VER"} ,
		{"word":"peigner","word_nosc":"peigner","lemma":"peigner","pos":"VER"} ,
		{"word":"peignerais","word_nosc":"peignerais","lemma":"peigner","pos":"VER"} ,
		{"word":"peignerait","word_nosc":"peignerait","lemma":"peigner","pos":"VER"} ,
		{"word":"peignes","word_nosc":"peignes","lemma":"peigner","pos":"VER"} ,
		{"word":"peignez","word_nosc":"peignez","lemma":"peigner","pos":"VER"} ,
		{"word":"peignez","word_nosc":"peignez","lemma":"peindre","pos":"VER"} ,
		{"word":"peigniez","word_nosc":"peigniez","lemma":"peigner","pos":"VER"} ,
		{"word":"peignions","word_nosc":"peignions","lemma":"peigner","pos":"VER"} ,
		{"word":"peignis","word_nosc":"peignis","lemma":"peindre","pos":"VER"} ,
		{"word":"peignit","word_nosc":"peignit","lemma":"peindre","pos":"VER"} ,
		{"word":"peignons","word_nosc":"peignons","lemma":"peindre","pos":"VER"} ,
		{"word":"peigné","word_nosc":"peigne","lemma":"peigner","pos":"VER"} ,
		{"word":"peignés","word_nosc":"peignes","lemma":"peigner","pos":"VER"} ,
		{"word":"peignît","word_nosc":"peignit","lemma":"peindre","pos":"VER"} ,
		{"word":"peina","word_nosc":"peina","lemma":"peiner","pos":"VER"} ,
		{"word":"peinaient","word_nosc":"peinaient","lemma":"peiner","pos":"VER"} ,
		{"word":"peinais","word_nosc":"peinais","lemma":"peiner","pos":"VER"} ,
		{"word":"peinait","word_nosc":"peinait","lemma":"peiner","pos":"VER"} ,
		{"word":"peinant","word_nosc":"peinant","lemma":"peiner","pos":"VER"} ,
		{"word":"peindra","word_nosc":"peindra","lemma":"peindre","pos":"VER"} ,
		{"word":"peindrai","word_nosc":"peindrai","lemma":"peindre","pos":"VER"} ,
		{"word":"peindrais","word_nosc":"peindrais","lemma":"peindre","pos":"VER"} ,
		{"word":"peindrait","word_nosc":"peindrait","lemma":"peindre","pos":"VER"} ,
		{"word":"peindras","word_nosc":"peindras","lemma":"peindre","pos":"VER"} ,
		{"word":"peindre","word_nosc":"peindre","lemma":"peindre","pos":"VER"} ,
		{"word":"peine","word_nosc":"peine","lemma":"peiner","pos":"VER"} ,
		{"word":"peinent","word_nosc":"peinent","lemma":"peiner","pos":"VER"} ,
		{"word":"peiner","word_nosc":"peiner","lemma":"peiner","pos":"VER"} ,
		{"word":"peinera","word_nosc":"peinera","lemma":"peiner","pos":"VER"} ,
		{"word":"peinerait","word_nosc":"peinerait","lemma":"peiner","pos":"VER"} ,
		{"word":"peinerez","word_nosc":"peinerez","lemma":"peiner","pos":"VER"} ,
		{"word":"peines","word_nosc":"peines","lemma":"peiner","pos":"VER"} ,
		{"word":"peinez","word_nosc":"peinez","lemma":"peiner","pos":"VER"} ,
		{"word":"peinions","word_nosc":"peinions","lemma":"peiner","pos":"VER"} ,
		{"word":"peinons","word_nosc":"peinons","lemma":"peiner","pos":"VER"} ,
		{"word":"peins","word_nosc":"peins","lemma":"peindre","pos":"VER"} ,
		{"word":"peint","word_nosc":"peint","lemma":"peindre","pos":"VER"} ,
		{"word":"peinte","word_nosc":"peinte","lemma":"peindre","pos":"VER"} ,
		{"word":"peintes","word_nosc":"peintes","lemma":"peindre","pos":"VER"} ,
		{"word":"peints","word_nosc":"peints","lemma":"peindre","pos":"VER"} ,
		{"word":"peinturlure","word_nosc":"peinturlure","lemma":"peinturlurer","pos":"VER"} ,
		{"word":"peinturlurent","word_nosc":"peinturlurent","lemma":"peinturlurer","pos":"VER"} ,
		{"word":"peinturlurer","word_nosc":"peinturlurer","lemma":"peinturlurer","pos":"VER"} ,
		{"word":"peinturlures","word_nosc":"peinturlures","lemma":"peinturlurer","pos":"VER"} ,
		{"word":"peinturluré","word_nosc":"peinturlure","lemma":"peinturlurer","pos":"VER"} ,
		{"word":"peinturlurée","word_nosc":"peinturluree","lemma":"peinturlurer","pos":"VER"} ,
		{"word":"peinturlurées","word_nosc":"peinturlurees","lemma":"peinturlurer","pos":"VER"} ,
		{"word":"peinturlurés","word_nosc":"peinturlures","lemma":"peinturlurer","pos":"VER"} ,
		{"word":"peinturé","word_nosc":"peinture","lemma":"peinturer","pos":"VER"} ,
		{"word":"peinturée","word_nosc":"peinturee","lemma":"peinturer","pos":"VER"} ,
		{"word":"peinât","word_nosc":"peinat","lemma":"peiner","pos":"VER"} ,
		{"word":"peinèrent","word_nosc":"peinerent","lemma":"peiner","pos":"VER"} ,
		{"word":"peiné","word_nosc":"peine","lemma":"peiner","pos":"VER"} ,
		{"word":"peinée","word_nosc":"peinee","lemma":"peiner","pos":"VER"} ,
		{"word":"peinées","word_nosc":"peinees","lemma":"peiner","pos":"VER"} ,
		{"word":"peinés","word_nosc":"peines","lemma":"peiner","pos":"VER"} ,
		{"word":"pela","word_nosc":"pela","lemma":"peler","pos":"VER"} ,
		{"word":"pelaient","word_nosc":"pelaient","lemma":"peler","pos":"VER"} ,
		{"word":"pelait","word_nosc":"pelait","lemma":"peler","pos":"VER"} ,
		{"word":"pelant","word_nosc":"pelant","lemma":"peler","pos":"VER"} ,
		{"word":"peler","word_nosc":"peler","lemma":"peler","pos":"VER"} ,
		{"word":"pelletaient","word_nosc":"pelletaient","lemma":"pelleter","pos":"VER"} ,
		{"word":"pelletait","word_nosc":"pelletait","lemma":"pelleter","pos":"VER"} ,
		{"word":"pelletant","word_nosc":"pelletant","lemma":"pelleter","pos":"VER"} ,
		{"word":"pelleter","word_nosc":"pelleter","lemma":"pelleter","pos":"VER"} ,
		{"word":"pellette","word_nosc":"pellette","lemma":"pelleter","pos":"VER"} ,
		{"word":"pelliculé","word_nosc":"pellicule","lemma":"pelliculer","pos":"VER"} ,
		{"word":"pelliculée","word_nosc":"pelliculee","lemma":"pelliculer","pos":"VER"} ,
		{"word":"pelota","word_nosc":"pelota","lemma":"peloter","pos":"VER"} ,
		{"word":"pelotaient","word_nosc":"pelotaient","lemma":"peloter","pos":"VER"} ,
		{"word":"pelotais","word_nosc":"pelotais","lemma":"peloter","pos":"VER"} ,
		{"word":"pelotait","word_nosc":"pelotait","lemma":"peloter","pos":"VER"} ,
		{"word":"pelotant","word_nosc":"pelotant","lemma":"peloter","pos":"VER"} ,
		{"word":"pelote","word_nosc":"pelote","lemma":"peloter","pos":"VER"} ,
		{"word":"pelotent","word_nosc":"pelotent","lemma":"peloter","pos":"VER"} ,
		{"word":"peloter","word_nosc":"peloter","lemma":"peloter","pos":"VER"} ,
		{"word":"peloterai","word_nosc":"peloterai","lemma":"peloter","pos":"VER"} ,
		{"word":"peloterait","word_nosc":"peloterait","lemma":"peloter","pos":"VER"} ,
		{"word":"pelotes","word_nosc":"pelotes","lemma":"peloter","pos":"VER"} ,
		{"word":"pelotez","word_nosc":"pelotez","lemma":"peloter","pos":"VER"} ,
		{"word":"pelotonna","word_nosc":"pelotonna","lemma":"pelotonner","pos":"VER"} ,
		{"word":"pelotonnai","word_nosc":"pelotonnai","lemma":"pelotonner","pos":"VER"} ,
		{"word":"pelotonnaient","word_nosc":"pelotonnaient","lemma":"pelotonner","pos":"VER"} ,
		{"word":"pelotonnais","word_nosc":"pelotonnais","lemma":"pelotonner","pos":"VER"} ,
		{"word":"pelotonnait","word_nosc":"pelotonnait","lemma":"pelotonner","pos":"VER"} ,
		{"word":"pelotonnant","word_nosc":"pelotonnant","lemma":"pelotonner","pos":"VER"} ,
		{"word":"pelotonne","word_nosc":"pelotonne","lemma":"pelotonner","pos":"VER"} ,
		{"word":"pelotonnent","word_nosc":"pelotonnent","lemma":"pelotonner","pos":"VER"} ,
		{"word":"pelotonner","word_nosc":"pelotonner","lemma":"pelotonner","pos":"VER"} ,
		{"word":"pelotonné","word_nosc":"pelotonne","lemma":"pelotonner","pos":"VER"} ,
		{"word":"pelotonnée","word_nosc":"pelotonnee","lemma":"pelotonner","pos":"VER"} ,
		{"word":"pelotonnées","word_nosc":"pelotonnees","lemma":"pelotonner","pos":"VER"} ,
		{"word":"pelotonnés","word_nosc":"pelotonnes","lemma":"pelotonner","pos":"VER"} ,
		{"word":"pelotons","word_nosc":"pelotons","lemma":"peloter","pos":"VER"} ,
		{"word":"peloté","word_nosc":"pelote","lemma":"peloter","pos":"VER"} ,
		{"word":"pelotée","word_nosc":"pelotee","lemma":"peloter","pos":"VER"} ,
		{"word":"pelotés","word_nosc":"pelotes","lemma":"peloter","pos":"VER"} ,
		{"word":"pelé","word_nosc":"pele","lemma":"peler","pos":"VER"} ,
		{"word":"pelée","word_nosc":"pelee","lemma":"peler","pos":"VER"} ,
		{"word":"pelées","word_nosc":"pelees","lemma":"peler","pos":"VER"} ,
		{"word":"pelés","word_nosc":"peles","lemma":"peler","pos":"VER"} ,
		{"word":"pencha","word_nosc":"pencha","lemma":"pencher","pos":"VER"} ,
		{"word":"penchai","word_nosc":"penchai","lemma":"pencher","pos":"VER"} ,
		{"word":"penchaient","word_nosc":"penchaient","lemma":"pencher","pos":"VER"} ,
		{"word":"penchais","word_nosc":"penchais","lemma":"pencher","pos":"VER"} ,
		{"word":"penchait","word_nosc":"penchait","lemma":"pencher","pos":"VER"} ,
		{"word":"penchant","word_nosc":"penchant","lemma":"pencher","pos":"VER"} ,
		{"word":"penche","word_nosc":"penche","lemma":"pencher","pos":"VER"} ,
		{"word":"penchent","word_nosc":"penchent","lemma":"pencher","pos":"VER"} ,
		{"word":"pencher","word_nosc":"pencher","lemma":"pencher","pos":"VER"} ,
		{"word":"penchera","word_nosc":"penchera","lemma":"pencher","pos":"VER"} ,
		{"word":"pencherai","word_nosc":"pencherai","lemma":"pencher","pos":"VER"} ,
		{"word":"pencheraient","word_nosc":"pencheraient","lemma":"pencher","pos":"VER"} ,
		{"word":"pencherais","word_nosc":"pencherais","lemma":"pencher","pos":"VER"} ,
		{"word":"pencherait","word_nosc":"pencherait","lemma":"pencher","pos":"VER"} ,
		{"word":"pencheras","word_nosc":"pencheras","lemma":"pencher","pos":"VER"} ,
		{"word":"pencherez","word_nosc":"pencherez","lemma":"pencher","pos":"VER"} ,
		{"word":"pencheriez","word_nosc":"pencheriez","lemma":"pencher","pos":"VER"} ,
		{"word":"pencherons","word_nosc":"pencherons","lemma":"pencher","pos":"VER"} ,
		{"word":"pencheront","word_nosc":"pencheront","lemma":"pencher","pos":"VER"} ,
		{"word":"penches","word_nosc":"penches","lemma":"pencher","pos":"VER"} ,
		{"word":"penchez","word_nosc":"penchez","lemma":"pencher","pos":"VER"} ,
		{"word":"penchiez","word_nosc":"penchiez","lemma":"pencher","pos":"VER"} ,
		{"word":"penchions","word_nosc":"penchions","lemma":"pencher","pos":"VER"} ,
		{"word":"penchons","word_nosc":"penchons","lemma":"pencher","pos":"VER"} ,
		{"word":"penchâmes","word_nosc":"penchames","lemma":"pencher","pos":"VER"} ,
		{"word":"penchât","word_nosc":"penchat","lemma":"pencher","pos":"VER"} ,
		{"word":"penchèrent","word_nosc":"pencherent","lemma":"pencher","pos":"VER"} ,
		{"word":"penché","word_nosc":"penche","lemma":"pencher","pos":"VER"} ,
		{"word":"penchée","word_nosc":"penchee","lemma":"pencher","pos":"VER"} ,
		{"word":"penchées","word_nosc":"penchees","lemma":"pencher","pos":"VER"} ,
		{"word":"penchés","word_nosc":"penches","lemma":"pencher","pos":"VER"} ,
		{"word":"pend","word_nosc":"pend","lemma":"pendre","pos":"VER"} ,
		{"word":"pendaient","word_nosc":"pendaient","lemma":"pendre","pos":"VER"} ,
		{"word":"pendais","word_nosc":"pendais","lemma":"pendre","pos":"VER"} ,
		{"word":"pendait","word_nosc":"pendait","lemma":"pendre","pos":"VER"} ,
		{"word":"pendant","word_nosc":"pendant","lemma":"pendre","pos":"VER"} ,
		{"word":"pende","word_nosc":"pende","lemma":"pendre","pos":"VER"} ,
		{"word":"pendent","word_nosc":"pendent","lemma":"pendre","pos":"VER"} ,
		{"word":"pendez","word_nosc":"pendez","lemma":"pendre","pos":"VER"} ,
		{"word":"pendillaient","word_nosc":"pendillaient","lemma":"pendiller","pos":"VER"} ,
		{"word":"pendillait","word_nosc":"pendillait","lemma":"pendiller","pos":"VER"} ,
		{"word":"pendillant","word_nosc":"pendillant","lemma":"pendiller","pos":"VER"} ,
		{"word":"pendille","word_nosc":"pendille","lemma":"pendiller","pos":"VER"} ,
		{"word":"pendirent","word_nosc":"pendirent","lemma":"pendre","pos":"VER"} ,
		{"word":"pendit","word_nosc":"pendit","lemma":"pendre","pos":"VER"} ,
		{"word":"pendons","word_nosc":"pendons","lemma":"pendre","pos":"VER"} ,
		{"word":"pendouillaient","word_nosc":"pendouillaient","lemma":"pendouiller","pos":"VER"} ,
		{"word":"pendouillait","word_nosc":"pendouillait","lemma":"pendouiller","pos":"VER"} ,
		{"word":"pendouillant","word_nosc":"pendouillant","lemma":"pendouiller","pos":"VER"} ,
		{"word":"pendouille","word_nosc":"pendouille","lemma":"pendouiller","pos":"VER"} ,
		{"word":"pendouillent","word_nosc":"pendouillent","lemma":"pendouiller","pos":"VER"} ,
		{"word":"pendouiller","word_nosc":"pendouiller","lemma":"pendouiller","pos":"VER"} ,
		{"word":"pendouilles","word_nosc":"pendouilles","lemma":"pendouiller","pos":"VER"} ,
		{"word":"pendra","word_nosc":"pendra","lemma":"pendre","pos":"VER"} ,
		{"word":"pendrai","word_nosc":"pendrai","lemma":"pendre","pos":"VER"} ,
		{"word":"pendrais","word_nosc":"pendrais","lemma":"pendre","pos":"VER"} ,
		{"word":"pendrait","word_nosc":"pendrait","lemma":"pendre","pos":"VER"} ,
		{"word":"pendras","word_nosc":"pendras","lemma":"pendre","pos":"VER"} ,
		{"word":"pendre","word_nosc":"pendre","lemma":"pendre","pos":"VER"} ,
		{"word":"pendrez","word_nosc":"pendrez","lemma":"pendre","pos":"VER"} ,
		{"word":"pendrons","word_nosc":"pendrons","lemma":"pendre","pos":"VER"} ,
		{"word":"pendront","word_nosc":"pendront","lemma":"pendre","pos":"VER"} ,
		{"word":"pends","word_nosc":"pends","lemma":"pendre","pos":"VER"} ,
		{"word":"pendu","word_nosc":"pendu","lemma":"pendre","pos":"VER"} ,
		{"word":"pendue","word_nosc":"pendue","lemma":"pendre","pos":"VER"} ,
		{"word":"pendues","word_nosc":"pendues","lemma":"pendre","pos":"VER"} ,
		{"word":"pendulait","word_nosc":"pendulait","lemma":"penduler","pos":"VER"} ,
		{"word":"pendus","word_nosc":"pendus","lemma":"pendre","pos":"VER"} ,
		{"word":"pensa","word_nosc":"pensa","lemma":"penser","pos":"VER"} ,
		{"word":"pensai","word_nosc":"pensai","lemma":"penser","pos":"VER"} ,
		{"word":"pensaient","word_nosc":"pensaient","lemma":"penser","pos":"VER"} ,
		{"word":"pensais","word_nosc":"pensais","lemma":"penser","pos":"VER"} ,
		{"word":"pensait","word_nosc":"pensait","lemma":"penser","pos":"VER"} ,
		{"word":"pensant","word_nosc":"pensant","lemma":"penser","pos":"VER"} ,
		{"word":"pense","word_nosc":"pense","lemma":"penser","pos":"VER"} ,
		{"word":"pensent","word_nosc":"pensent","lemma":"penser","pos":"VER"} ,
		{"word":"penser","word_nosc":"penser","lemma":"penser","pos":"VER"} ,
		{"word":"pensera","word_nosc":"pensera","lemma":"penser","pos":"VER"} ,
		{"word":"penserai","word_nosc":"penserai","lemma":"penser","pos":"VER"} ,
		{"word":"penseraient","word_nosc":"penseraient","lemma":"penser","pos":"VER"} ,
		{"word":"penserais","word_nosc":"penserais","lemma":"penser","pos":"VER"} ,
		{"word":"penserait","word_nosc":"penserait","lemma":"penser","pos":"VER"} ,
		{"word":"penseras","word_nosc":"penseras","lemma":"penser","pos":"VER"} ,
		{"word":"penserez","word_nosc":"penserez","lemma":"penser","pos":"VER"} ,
		{"word":"penseriez","word_nosc":"penseriez","lemma":"penser","pos":"VER"} ,
		{"word":"penserions","word_nosc":"penserions","lemma":"penser","pos":"VER"} ,
		{"word":"penserons","word_nosc":"penserons","lemma":"penser","pos":"VER"} ,
		{"word":"penseront","word_nosc":"penseront","lemma":"penser","pos":"VER"} ,
		{"word":"penses","word_nosc":"penses","lemma":"penser","pos":"VER"} ,
		{"word":"pensez","word_nosc":"pensez","lemma":"penser","pos":"VER"} ,
		{"word":"pensiez","word_nosc":"pensiez","lemma":"penser","pos":"VER"} ,
		{"word":"pensionna","word_nosc":"pensionna","lemma":"pensionner","pos":"VER"} ,
		{"word":"pensionner","word_nosc":"pensionner","lemma":"pensionner","pos":"VER"} ,
		{"word":"pensionné","word_nosc":"pensionne","lemma":"pensionner","pos":"VER"} ,
		{"word":"pensionnés","word_nosc":"pensionnes","lemma":"pensionner","pos":"VER"} ,
		{"word":"pensions","word_nosc":"pensions","lemma":"penser","pos":"VER"} ,
		{"word":"pensons","word_nosc":"pensons","lemma":"penser","pos":"VER"} ,
		{"word":"pensâmes","word_nosc":"pensames","lemma":"penser","pos":"VER"} ,
		{"word":"pensât","word_nosc":"pensat","lemma":"penser","pos":"VER"} ,
		{"word":"pensèrent","word_nosc":"penserent","lemma":"penser","pos":"VER"} ,
		{"word":"pensé","word_nosc":"pense","lemma":"penser","pos":"VER"} ,
		{"word":"pensée","word_nosc":"pensee","lemma":"penser","pos":"VER"} ,
		{"word":"pensées","word_nosc":"pensees","lemma":"penser","pos":"VER"} ,
		{"word":"pensés","word_nosc":"penses","lemma":"penser","pos":"VER"} ,
		{"word":"perce","word_nosc":"perce","lemma":"percer","pos":"VER"} ,
		{"word":"percent","word_nosc":"percent","lemma":"percer","pos":"VER"} ,
		{"word":"percer","word_nosc":"percer","lemma":"percer","pos":"VER"} ,
		{"word":"percera","word_nosc":"percera","lemma":"percer","pos":"VER"} ,
		{"word":"percerai","word_nosc":"percerai","lemma":"percer","pos":"VER"} ,
		{"word":"percerait","word_nosc":"percerait","lemma":"percer","pos":"VER"} ,
		{"word":"percerez","word_nosc":"percerez","lemma":"percer","pos":"VER"} ,
		{"word":"percevaient","word_nosc":"percevaient","lemma":"percevoir","pos":"VER"} ,
		{"word":"percevais","word_nosc":"percevais","lemma":"percevoir","pos":"VER"} ,
		{"word":"percevait","word_nosc":"percevait","lemma":"percevoir","pos":"VER"} ,
		{"word":"percevant","word_nosc":"percevant","lemma":"percevoir","pos":"VER"} ,
		{"word":"percevez","word_nosc":"percevez","lemma":"percevoir","pos":"VER"} ,
		{"word":"perceviez","word_nosc":"perceviez","lemma":"percevoir","pos":"VER"} ,
		{"word":"percevions","word_nosc":"percevions","lemma":"percevoir","pos":"VER"} ,
		{"word":"percevoir","word_nosc":"percevoir","lemma":"percevoir","pos":"VER"} ,
		{"word":"percevons","word_nosc":"percevons","lemma":"percevoir","pos":"VER"} ,
		{"word":"percevra","word_nosc":"percevra","lemma":"percevoir","pos":"VER"} ,
		{"word":"percevrais","word_nosc":"percevrais","lemma":"percevoir","pos":"VER"} ,
		{"word":"percevrait","word_nosc":"percevrait","lemma":"percevoir","pos":"VER"} ,
		{"word":"percevrez","word_nosc":"percevrez","lemma":"percevoir","pos":"VER"} ,
		{"word":"percez","word_nosc":"percez","lemma":"percer","pos":"VER"} ,
		{"word":"percha","word_nosc":"percha","lemma":"percher","pos":"VER"} ,
		{"word":"perchaient","word_nosc":"perchaient","lemma":"percher","pos":"VER"} ,
		{"word":"perchais","word_nosc":"perchais","lemma":"percher","pos":"VER"} ,
		{"word":"perchait","word_nosc":"perchait","lemma":"percher","pos":"VER"} ,
		{"word":"perche","word_nosc":"perche","lemma":"percher","pos":"VER"} ,
		{"word":"perchent","word_nosc":"perchent","lemma":"percher","pos":"VER"} ,
		{"word":"percher","word_nosc":"percher","lemma":"percher","pos":"VER"} ,
		{"word":"perchèrent","word_nosc":"percherent","lemma":"percher","pos":"VER"} ,
		{"word":"perché","word_nosc":"perche","lemma":"percher","pos":"VER"} ,
		{"word":"perchée","word_nosc":"perchee","lemma":"percher","pos":"VER"} ,
		{"word":"perchées","word_nosc":"perchees","lemma":"percher","pos":"VER"} ,
		{"word":"perchés","word_nosc":"perches","lemma":"percher","pos":"VER"} ,
		{"word":"percions","word_nosc":"percions","lemma":"percer","pos":"VER"} ,
		{"word":"percoler","word_nosc":"percoler","lemma":"percoler","pos":"VER"} ,
		{"word":"percuta","word_nosc":"percuta","lemma":"percuter","pos":"VER"} ,
		{"word":"percutait","word_nosc":"percutait","lemma":"percuter","pos":"VER"} ,
		{"word":"percutant","word_nosc":"percutant","lemma":"percuter","pos":"VER"} ,
		{"word":"percute","word_nosc":"percute","lemma":"percuter","pos":"VER"} ,
		{"word":"percutent","word_nosc":"percutent","lemma":"percuter","pos":"VER"} ,
		{"word":"percuter","word_nosc":"percuter","lemma":"percuter","pos":"VER"} ,
		{"word":"percuterait","word_nosc":"percuterait","lemma":"percuter","pos":"VER"} ,
		{"word":"percutes","word_nosc":"percutes","lemma":"percuter","pos":"VER"} ,
		{"word":"percutez","word_nosc":"percutez","lemma":"percuter","pos":"VER"} ,
		{"word":"percuté","word_nosc":"percute","lemma":"percuter","pos":"VER"} ,
		{"word":"percutée","word_nosc":"percutee","lemma":"percuter","pos":"VER"} ,
		{"word":"percutées","word_nosc":"percutees","lemma":"percuter","pos":"VER"} ,
		{"word":"percutés","word_nosc":"percutes","lemma":"percuter","pos":"VER"} ,
		{"word":"percèrent","word_nosc":"percerent","lemma":"percer","pos":"VER"} ,
		{"word":"percé","word_nosc":"perce","lemma":"percer","pos":"VER"} ,
		{"word":"percée","word_nosc":"percee","lemma":"percer","pos":"VER"} ,
		{"word":"percées","word_nosc":"percees","lemma":"percer","pos":"VER"} ,
		{"word":"percés","word_nosc":"perces","lemma":"percer","pos":"VER"} ,
		{"word":"perd","word_nosc":"perd","lemma":"perdre","pos":"VER"} ,
		{"word":"perdaient","word_nosc":"perdaient","lemma":"perdre","pos":"VER"} ,
		{"word":"perdais","word_nosc":"perdais","lemma":"perdre","pos":"VER"} ,
		{"word":"perdait","word_nosc":"perdait","lemma":"perdre","pos":"VER"} ,
		{"word":"perdant","word_nosc":"perdant","lemma":"perdre","pos":"VER"} ,
		{"word":"perde","word_nosc":"perde","lemma":"perdre","pos":"VER"} ,
		{"word":"perdent","word_nosc":"perdent","lemma":"perdre","pos":"VER"} ,
		{"word":"perdes","word_nosc":"perdes","lemma":"perdre","pos":"VER"} ,
		{"word":"perdez","word_nosc":"perdez","lemma":"perdre","pos":"VER"} ,
		{"word":"perdiez","word_nosc":"perdiez","lemma":"perdre","pos":"VER"} ,
		{"word":"perdions","word_nosc":"perdions","lemma":"perdre","pos":"VER"} ,
		{"word":"perdirent","word_nosc":"perdirent","lemma":"perdre","pos":"VER"} ,
		{"word":"perdis","word_nosc":"perdis","lemma":"perdre","pos":"VER"} ,
		{"word":"perdisse","word_nosc":"perdisse","lemma":"perdre","pos":"VER"} ,
		{"word":"perdit","word_nosc":"perdit","lemma":"perdre","pos":"VER"} ,
		{"word":"perdons","word_nosc":"perdons","lemma":"perdre","pos":"VER"} ,
		{"word":"perdra","word_nosc":"perdra","lemma":"perdre","pos":"VER"} ,
		{"word":"perdrai","word_nosc":"perdrai","lemma":"perdre","pos":"VER"} ,
		{"word":"perdraient","word_nosc":"perdraient","lemma":"perdre","pos":"VER"} ,
		{"word":"perdrais","word_nosc":"perdrais","lemma":"perdre","pos":"VER"} ,
		{"word":"perdrait","word_nosc":"perdrait","lemma":"perdre","pos":"VER"} ,
		{"word":"perdras","word_nosc":"perdras","lemma":"perdre","pos":"VER"} ,
		{"word":"perdre","word_nosc":"perdre","lemma":"perdre","pos":"VER"} ,
		{"word":"perdrez","word_nosc":"perdrez","lemma":"perdre","pos":"VER"} ,
		{"word":"perdriez","word_nosc":"perdriez","lemma":"perdre","pos":"VER"} ,
		{"word":"perdrions","word_nosc":"perdrions","lemma":"perdre","pos":"VER"} ,
		{"word":"perdrons","word_nosc":"perdrons","lemma":"perdre","pos":"VER"} ,
		{"word":"perdront","word_nosc":"perdront","lemma":"perdre","pos":"VER"} ,
		{"word":"perds","word_nosc":"perds","lemma":"perdre","pos":"VER"} ,
		{"word":"perdu","word_nosc":"perdu","lemma":"perdre","pos":"VER"} ,
		{"word":"perdue","word_nosc":"perdue","lemma":"perdre","pos":"VER"} ,
		{"word":"perdues","word_nosc":"perdues","lemma":"perdre","pos":"VER"} ,
		{"word":"perdurait","word_nosc":"perdurait","lemma":"perdurer","pos":"VER"} ,
		{"word":"perdure","word_nosc":"perdure","lemma":"perdurer","pos":"VER"} ,
		{"word":"perdurent","word_nosc":"perdurent","lemma":"perdurer","pos":"VER"} ,
		{"word":"perdurer","word_nosc":"perdurer","lemma":"perdurer","pos":"VER"} ,
		{"word":"perdurera","word_nosc":"perdurera","lemma":"perdurer","pos":"VER"} ,
		{"word":"perduré","word_nosc":"perdure","lemma":"perdurer","pos":"VER"} ,
		{"word":"perdus","word_nosc":"perdus","lemma":"perdre","pos":"VER"} ,
		{"word":"perdîmes","word_nosc":"perdimes","lemma":"perdre","pos":"VER"} ,
		{"word":"perdît","word_nosc":"perdit","lemma":"perdre","pos":"VER"} ,
		{"word":"perfectionna","word_nosc":"perfectionna","lemma":"perfectionner","pos":"VER"} ,
		{"word":"perfectionnaient","word_nosc":"perfectionnaient","lemma":"perfectionner","pos":"VER"} ,
		{"word":"perfectionnais","word_nosc":"perfectionnais","lemma":"perfectionner","pos":"VER"} ,
		{"word":"perfectionnait","word_nosc":"perfectionnait","lemma":"perfectionner","pos":"VER"} ,
		{"word":"perfectionnant","word_nosc":"perfectionnant","lemma":"perfectionner","pos":"VER"} ,
		{"word":"perfectionne","word_nosc":"perfectionne","lemma":"perfectionner","pos":"VER"} ,
		{"word":"perfectionnent","word_nosc":"perfectionnent","lemma":"perfectionner","pos":"VER"} ,
		{"word":"perfectionner","word_nosc":"perfectionner","lemma":"perfectionner","pos":"VER"} ,
		{"word":"perfectionnerais","word_nosc":"perfectionnerais","lemma":"perfectionner","pos":"VER"} ,
		{"word":"perfectionnions","word_nosc":"perfectionnions","lemma":"perfectionner","pos":"VER"} ,
		{"word":"perfectionnèrent","word_nosc":"perfectionnerent","lemma":"perfectionner","pos":"VER"} ,
		{"word":"perfectionné","word_nosc":"perfectionne","lemma":"perfectionner","pos":"VER"} ,
		{"word":"perfectionnée","word_nosc":"perfectionnee","lemma":"perfectionner","pos":"VER"} ,
		{"word":"perfectionnées","word_nosc":"perfectionnees","lemma":"perfectionner","pos":"VER"} ,
		{"word":"perfectionnés","word_nosc":"perfectionnes","lemma":"perfectionner","pos":"VER"} ,
		{"word":"perfora","word_nosc":"perfora","lemma":"perforer","pos":"VER"} ,
		{"word":"perforait","word_nosc":"perforait","lemma":"perforer","pos":"VER"} ,
		{"word":"perforant","word_nosc":"perforant","lemma":"perforer","pos":"VER"} ,
		{"word":"perfore","word_nosc":"perfore","lemma":"perforer","pos":"VER"} ,
		{"word":"perforer","word_nosc":"perforer","lemma":"perforer","pos":"VER"} ,
		{"word":"perforera","word_nosc":"perforera","lemma":"perforer","pos":"VER"} ,
		{"word":"perforez","word_nosc":"perforez","lemma":"perforer","pos":"VER"} ,
		{"word":"perforions","word_nosc":"perforions","lemma":"perforer","pos":"VER"} ,
		{"word":"perforé","word_nosc":"perfore","lemma":"perforer","pos":"VER"} ,
		{"word":"perforée","word_nosc":"perforee","lemma":"perforer","pos":"VER"} ,
		{"word":"perforées","word_nosc":"perforees","lemma":"perforer","pos":"VER"} ,
		{"word":"perforés","word_nosc":"perfores","lemma":"perforer","pos":"VER"} ,
		{"word":"perfuse","word_nosc":"perfuse","lemma":"perfuser","pos":"VER"} ,
		{"word":"perfuser","word_nosc":"perfuser","lemma":"perfuser","pos":"VER"} ,
		{"word":"perfusé","word_nosc":"perfuse","lemma":"perfuser","pos":"VER"} ,
		{"word":"perla","word_nosc":"perla","lemma":"perler","pos":"VER"} ,
		{"word":"perlaient","word_nosc":"perlaient","lemma":"perler","pos":"VER"} ,
		{"word":"perlait","word_nosc":"perlait","lemma":"perler","pos":"VER"} ,
		{"word":"perlant","word_nosc":"perlant","lemma":"perler","pos":"VER"} ,
		{"word":"perle","word_nosc":"perle","lemma":"perler","pos":"VER"} ,
		{"word":"perlent","word_nosc":"perlent","lemma":"perler","pos":"VER"} ,
		{"word":"perler","word_nosc":"perler","lemma":"perler","pos":"VER"} ,
		{"word":"perleront","word_nosc":"perleront","lemma":"perler","pos":"VER"} ,
		{"word":"perlèrent","word_nosc":"perlerent","lemma":"perler","pos":"VER"} ,
		{"word":"perlé","word_nosc":"perle","lemma":"perler","pos":"VER"} ,
		{"word":"perlée","word_nosc":"perlee","lemma":"perler","pos":"VER"} ,
		{"word":"perlées","word_nosc":"perlees","lemma":"perler","pos":"VER"} ,
		{"word":"perlés","word_nosc":"perles","lemma":"perler","pos":"VER"} ,
		{"word":"permane","word_nosc":"permane","lemma":"permaner","pos":"VER"} ,
		{"word":"permanente","word_nosc":"permanente","lemma":"permanenter","pos":"VER"} ,
		{"word":"permanenter","word_nosc":"permanenter","lemma":"permanenter","pos":"VER"} ,
		{"word":"permanenté","word_nosc":"permanente","lemma":"permanenter","pos":"VER"} ,
		{"word":"permanentée","word_nosc":"permanentee","lemma":"permanenter","pos":"VER"} ,
		{"word":"permanentés","word_nosc":"permanentes","lemma":"permanenter","pos":"VER"} ,
		{"word":"permet","word_nosc":"permet","lemma":"permettre","pos":"VER"} ,
		{"word":"permets","word_nosc":"permets","lemma":"permettre","pos":"VER"} ,
		{"word":"permettaient","word_nosc":"permettaient","lemma":"permettre","pos":"VER"} ,
		{"word":"permettais","word_nosc":"permettais","lemma":"permettre","pos":"VER"} ,
		{"word":"permettait","word_nosc":"permettait","lemma":"permettre","pos":"VER"} ,
		{"word":"permettant","word_nosc":"permettant","lemma":"permettre","pos":"VER"} ,
		{"word":"permette","word_nosc":"permette","lemma":"permettre","pos":"VER"} ,
		{"word":"permettent","word_nosc":"permettent","lemma":"permettre","pos":"VER"} ,
		{"word":"permettes","word_nosc":"permettes","lemma":"permettre","pos":"VER"} ,
		{"word":"permettez","word_nosc":"permettez","lemma":"permettre","pos":"VER"} ,
		{"word":"permettiez","word_nosc":"permettiez","lemma":"permettre","pos":"VER"} ,
		{"word":"permettions","word_nosc":"permettions","lemma":"permettre","pos":"VER"} ,
		{"word":"permettons","word_nosc":"permettons","lemma":"permettre","pos":"VER"} ,
		{"word":"permettra","word_nosc":"permettra","lemma":"permettre","pos":"VER"} ,
		{"word":"permettrai","word_nosc":"permettrai","lemma":"permettre","pos":"VER"} ,
		{"word":"permettraient","word_nosc":"permettraient","lemma":"permettre","pos":"VER"} ,
		{"word":"permettrais","word_nosc":"permettrais","lemma":"permettre","pos":"VER"} ,
		{"word":"permettrait","word_nosc":"permettrait","lemma":"permettre","pos":"VER"} ,
		{"word":"permettras","word_nosc":"permettras","lemma":"permettre","pos":"VER"} ,
		{"word":"permettre","word_nosc":"permettre","lemma":"permettre","pos":"VER"} ,
		{"word":"permettrez","word_nosc":"permettrez","lemma":"permettre","pos":"VER"} ,
		{"word":"permettriez","word_nosc":"permettriez","lemma":"permettre","pos":"VER"} ,
		{"word":"permettrions","word_nosc":"permettrions","lemma":"permettre","pos":"VER"} ,
		{"word":"permettrons","word_nosc":"permettrons","lemma":"permettre","pos":"VER"} ,
		{"word":"permettront","word_nosc":"permettront","lemma":"permettre","pos":"VER"} ,
		{"word":"permirent","word_nosc":"permirent","lemma":"permettre","pos":"VER"} ,
		{"word":"permis","word_nosc":"permis","lemma":"permettre","pos":"VER"} ,
		{"word":"permise","word_nosc":"permise","lemma":"permettre","pos":"VER"} ,
		{"word":"permises","word_nosc":"permises","lemma":"permettre","pos":"VER"} ,
		{"word":"permissent","word_nosc":"permissent","lemma":"permettre","pos":"VER"} ,
		{"word":"permit","word_nosc":"permit","lemma":"permettre","pos":"VER"} ,
		{"word":"permutant","word_nosc":"permutant","lemma":"permuter","pos":"VER"} ,
		{"word":"permute","word_nosc":"permute","lemma":"permuter","pos":"VER"} ,
		{"word":"permutent","word_nosc":"permutent","lemma":"permuter","pos":"VER"} ,
		{"word":"permuter","word_nosc":"permuter","lemma":"permuter","pos":"VER"} ,
		{"word":"permutèrent","word_nosc":"permuterent","lemma":"permuter","pos":"VER"} ,
		{"word":"permuté","word_nosc":"permute","lemma":"permuter","pos":"VER"} ,
		{"word":"permît","word_nosc":"permit","lemma":"permettre","pos":"VER"} ,
		{"word":"peroxydé","word_nosc":"peroxyde","lemma":"peroxyder","pos":"VER"} ,
		{"word":"perpètrent","word_nosc":"perpetrent","lemma":"perpétrer","pos":"VER"} ,
		{"word":"perpètres","word_nosc":"perpetres","lemma":"perpétrer","pos":"VER"} ,
		{"word":"perpétra","word_nosc":"perpetra","lemma":"perpétrer","pos":"VER"} ,
		{"word":"perpétrait","word_nosc":"perpetrait","lemma":"perpétrer","pos":"VER"} ,
		{"word":"perpétrer","word_nosc":"perpetrer","lemma":"perpétrer","pos":"VER"} ,
		{"word":"perpétrons","word_nosc":"perpetrons","lemma":"perpétrer","pos":"VER"} ,
		{"word":"perpétré","word_nosc":"perpetre","lemma":"perpétrer","pos":"VER"} ,
		{"word":"perpétrée","word_nosc":"perpetree","lemma":"perpétrer","pos":"VER"} ,
		{"word":"perpétrées","word_nosc":"perpetrees","lemma":"perpétrer","pos":"VER"} ,
		{"word":"perpétrés","word_nosc":"perpetres","lemma":"perpétrer","pos":"VER"} ,
		{"word":"perpétua","word_nosc":"perpetua","lemma":"perpétuer","pos":"VER"} ,
		{"word":"perpétuaient","word_nosc":"perpetuaient","lemma":"perpétuer","pos":"VER"} ,
		{"word":"perpétuait","word_nosc":"perpetuait","lemma":"perpétuer","pos":"VER"} ,
		{"word":"perpétuant","word_nosc":"perpetuant","lemma":"perpétuer","pos":"VER"} ,
		{"word":"perpétue","word_nosc":"perpetue","lemma":"perpétuer","pos":"VER"} ,
		{"word":"perpétuent","word_nosc":"perpetuent","lemma":"perpétuer","pos":"VER"} ,
		{"word":"perpétuer","word_nosc":"perpetuer","lemma":"perpétuer","pos":"VER"} ,
		{"word":"perpétuera","word_nosc":"perpetuera","lemma":"perpétuer","pos":"VER"} ,
		{"word":"perpétuerait","word_nosc":"perpetuerait","lemma":"perpétuer","pos":"VER"} ,
		{"word":"perpétué","word_nosc":"perpetue","lemma":"perpétuer","pos":"VER"} ,
		{"word":"perpétuée","word_nosc":"perpetuee","lemma":"perpétuer","pos":"VER"} ,
		{"word":"perpétués","word_nosc":"perpetues","lemma":"perpétuer","pos":"VER"} ,
		{"word":"perquisitionne","word_nosc":"perquisitionne","lemma":"perquisitionner","pos":"VER"} ,
		{"word":"perquisitionnent","word_nosc":"perquisitionnent","lemma":"perquisitionner","pos":"VER"} ,
		{"word":"perquisitionner","word_nosc":"perquisitionner","lemma":"perquisitionner","pos":"VER"} ,
		{"word":"perquisitionnera","word_nosc":"perquisitionnera","lemma":"perquisitionner","pos":"VER"} ,
		{"word":"perquisitionné","word_nosc":"perquisitionne","lemma":"perquisitionner","pos":"VER"} ,
		{"word":"persifla","word_nosc":"persifla","lemma":"persifler","pos":"VER"} ,
		{"word":"persiflait","word_nosc":"persiflait","lemma":"persifler","pos":"VER"} ,
		{"word":"persifle","word_nosc":"persifle","lemma":"persifler","pos":"VER"} ,
		{"word":"persifler","word_nosc":"persifler","lemma":"persifler","pos":"VER"} ,
		{"word":"persiflé","word_nosc":"persifle","lemma":"persifler","pos":"VER"} ,
		{"word":"persillé","word_nosc":"persille","lemma":"persiller","pos":"VER"} ,
		{"word":"persillée","word_nosc":"persillee","lemma":"persiller","pos":"VER"} ,
		{"word":"persista","word_nosc":"persista","lemma":"persister","pos":"VER"} ,
		{"word":"persistai","word_nosc":"persistai","lemma":"persister","pos":"VER"} ,
		{"word":"persistaient","word_nosc":"persistaient","lemma":"persister","pos":"VER"} ,
		{"word":"persistais","word_nosc":"persistais","lemma":"persister","pos":"VER"} ,
		{"word":"persistait","word_nosc":"persistait","lemma":"persister","pos":"VER"} ,
		{"word":"persistant","word_nosc":"persistant","lemma":"persister","pos":"VER"} ,
		{"word":"persiste","word_nosc":"persiste","lemma":"persister","pos":"VER"} ,
		{"word":"persistent","word_nosc":"persistent","lemma":"persister","pos":"VER"} ,
		{"word":"persister","word_nosc":"persister","lemma":"persister","pos":"VER"} ,
		{"word":"persistera","word_nosc":"persistera","lemma":"persister","pos":"VER"} ,
		{"word":"persisterai","word_nosc":"persisterai","lemma":"persister","pos":"VER"} ,
		{"word":"persisterait","word_nosc":"persisterait","lemma":"persister","pos":"VER"} ,
		{"word":"persisteriez","word_nosc":"persisteriez","lemma":"persister","pos":"VER"} ,
		{"word":"persistes","word_nosc":"persistes","lemma":"persister","pos":"VER"} ,
		{"word":"persistez","word_nosc":"persistez","lemma":"persister","pos":"VER"} ,
		{"word":"persistions","word_nosc":"persistions","lemma":"persister","pos":"VER"} ,
		{"word":"persistons","word_nosc":"persistons","lemma":"persister","pos":"VER"} ,
		{"word":"persistèrent","word_nosc":"persisterent","lemma":"persister","pos":"VER"} ,
		{"word":"persisté","word_nosc":"persiste","lemma":"persister","pos":"VER"} ,
		{"word":"personnalisaient","word_nosc":"personnalisaient","lemma":"personnaliser","pos":"VER"} ,
		{"word":"personnalise","word_nosc":"personnalise","lemma":"personnaliser","pos":"VER"} ,
		{"word":"personnalisent","word_nosc":"personnalisent","lemma":"personnaliser","pos":"VER"} ,
		{"word":"personnaliser","word_nosc":"personnaliser","lemma":"personnaliser","pos":"VER"} ,
		{"word":"personnalisez","word_nosc":"personnalisez","lemma":"personnaliser","pos":"VER"} ,
		{"word":"personnalisons","word_nosc":"personnalisons","lemma":"personnaliser","pos":"VER"} ,
		{"word":"personnalisé","word_nosc":"personnalise","lemma":"personnaliser","pos":"VER"} ,
		{"word":"personnalisée","word_nosc":"personnalisee","lemma":"personnaliser","pos":"VER"} ,
		{"word":"personnifiaient","word_nosc":"personnifiaient","lemma":"personnifier","pos":"VER"} ,
		{"word":"personnifiait","word_nosc":"personnifiait","lemma":"personnifier","pos":"VER"} ,
		{"word":"personnifiant","word_nosc":"personnifiant","lemma":"personnifier","pos":"VER"} ,
		{"word":"personnifie","word_nosc":"personnifie","lemma":"personnifier","pos":"VER"} ,
		{"word":"personnifier","word_nosc":"personnifier","lemma":"personnifier","pos":"VER"} ,
		{"word":"personnifié","word_nosc":"personnifie","lemma":"personnifier","pos":"VER"} ,
		{"word":"personnifiée","word_nosc":"personnifiee","lemma":"personnifier","pos":"VER"} ,
		{"word":"persuada","word_nosc":"persuada","lemma":"persuader","pos":"VER"} ,
		{"word":"persuadai","word_nosc":"persuadai","lemma":"persuader","pos":"VER"} ,
		{"word":"persuadaient","word_nosc":"persuadaient","lemma":"persuader","pos":"VER"} ,
		{"word":"persuadais","word_nosc":"persuadais","lemma":"persuader","pos":"VER"} ,
		{"word":"persuadait","word_nosc":"persuadait","lemma":"persuader","pos":"VER"} ,
		{"word":"persuadant","word_nosc":"persuadant","lemma":"persuader","pos":"VER"} ,
		{"word":"persuade","word_nosc":"persuade","lemma":"persuader","pos":"VER"} ,
		{"word":"persuadent","word_nosc":"persuadent","lemma":"persuader","pos":"VER"} ,
		{"word":"persuader","word_nosc":"persuader","lemma":"persuader","pos":"VER"} ,
		{"word":"persuadera","word_nosc":"persuadera","lemma":"persuader","pos":"VER"} ,
		{"word":"persuaderai","word_nosc":"persuaderai","lemma":"persuader","pos":"VER"} ,
		{"word":"persuaderais","word_nosc":"persuaderais","lemma":"persuader","pos":"VER"} ,
		{"word":"persuaderait","word_nosc":"persuaderait","lemma":"persuader","pos":"VER"} ,
		{"word":"persuaderas","word_nosc":"persuaderas","lemma":"persuader","pos":"VER"} ,
		{"word":"persuaderez","word_nosc":"persuaderez","lemma":"persuader","pos":"VER"} ,
		{"word":"persuaderons","word_nosc":"persuaderons","lemma":"persuader","pos":"VER"} ,
		{"word":"persuadez","word_nosc":"persuadez","lemma":"persuader","pos":"VER"} ,
		{"word":"persuadiez","word_nosc":"persuadiez","lemma":"persuader","pos":"VER"} ,
		{"word":"persuadons","word_nosc":"persuadons","lemma":"persuader","pos":"VER"} ,
		{"word":"persuadèrent","word_nosc":"persuaderent","lemma":"persuader","pos":"VER"} ,
		{"word":"persuadé","word_nosc":"persuade","lemma":"persuader","pos":"VER"} ,
		{"word":"persuadée","word_nosc":"persuadee","lemma":"persuader","pos":"VER"} ,
		{"word":"persuadées","word_nosc":"persuadees","lemma":"persuader","pos":"VER"} ,
		{"word":"persuadés","word_nosc":"persuades","lemma":"persuader","pos":"VER"} ,
		{"word":"persécutaient","word_nosc":"persecutaient","lemma":"persécuter","pos":"VER"} ,
		{"word":"persécutait","word_nosc":"persecutait","lemma":"persécuter","pos":"VER"} ,
		{"word":"persécutant","word_nosc":"persecutant","lemma":"persécuter","pos":"VER"} ,
		{"word":"persécute","word_nosc":"persecute","lemma":"persécuter","pos":"VER"} ,
		{"word":"persécutent","word_nosc":"persecutent","lemma":"persécuter","pos":"VER"} ,
		{"word":"persécuter","word_nosc":"persecuter","lemma":"persécuter","pos":"VER"} ,
		{"word":"persécuteraient","word_nosc":"persecuteraient","lemma":"persécuter","pos":"VER"} ,
		{"word":"persécuterez","word_nosc":"persecuterez","lemma":"persécuter","pos":"VER"} ,
		{"word":"persécutes","word_nosc":"persecutes","lemma":"persécuter","pos":"VER"} ,
		{"word":"persécutez","word_nosc":"persecutez","lemma":"persécuter","pos":"VER"} ,
		{"word":"persécuté","word_nosc":"persecute","lemma":"persécuter","pos":"VER"} ,
		{"word":"persécutée","word_nosc":"persecutee","lemma":"persécuter","pos":"VER"} ,
		{"word":"persécutées","word_nosc":"persecutees","lemma":"persécuter","pos":"VER"} ,
		{"word":"persécutés","word_nosc":"persecutes","lemma":"persécuter","pos":"VER"} ,
		{"word":"persévère","word_nosc":"persevere","lemma":"persévérer","pos":"VER"} ,
		{"word":"persévères","word_nosc":"perseveres","lemma":"persévérer","pos":"VER"} ,
		{"word":"persévéra","word_nosc":"persevera","lemma":"persévérer","pos":"VER"} ,
		{"word":"persévérai","word_nosc":"perseverai","lemma":"persévérer","pos":"VER"} ,
		{"word":"persévéraient","word_nosc":"perseveraient","lemma":"persévérer","pos":"VER"} ,
		{"word":"persévérait","word_nosc":"perseverait","lemma":"persévérer","pos":"VER"} ,
		{"word":"persévérant","word_nosc":"perseverant","lemma":"persévérer","pos":"VER"} ,
		{"word":"persévérer","word_nosc":"perseverer","lemma":"persévérer","pos":"VER"} ,
		{"word":"persévérerai","word_nosc":"persevererai","lemma":"persévérer","pos":"VER"} ,
		{"word":"persévérerait","word_nosc":"persevererait","lemma":"persévérer","pos":"VER"} ,
		{"word":"persévérez","word_nosc":"perseverez","lemma":"persévérer","pos":"VER"} ,
		{"word":"persévériez","word_nosc":"perseveriez","lemma":"persévérer","pos":"VER"} ,
		{"word":"persévérions","word_nosc":"perseverions","lemma":"persévérer","pos":"VER"} ,
		{"word":"persévérons","word_nosc":"perseverons","lemma":"persévérer","pos":"VER"} ,
		{"word":"persévéré","word_nosc":"persevere","lemma":"persévérer","pos":"VER"} ,
		{"word":"perturba","word_nosc":"perturba","lemma":"perturber","pos":"VER"} ,
		{"word":"perturbaient","word_nosc":"perturbaient","lemma":"perturber","pos":"VER"} ,
		{"word":"perturbait","word_nosc":"perturbait","lemma":"perturber","pos":"VER"} ,
		{"word":"perturbant","word_nosc":"perturbant","lemma":"perturber","pos":"VER"} ,
		{"word":"perturbe","word_nosc":"perturbe","lemma":"perturber","pos":"VER"} ,
		{"word":"perturbent","word_nosc":"perturbent","lemma":"perturber","pos":"VER"} ,
		{"word":"perturber","word_nosc":"perturber","lemma":"perturber","pos":"VER"} ,
		{"word":"perturbera","word_nosc":"perturbera","lemma":"perturber","pos":"VER"} ,
		{"word":"perturberait","word_nosc":"perturberait","lemma":"perturber","pos":"VER"} ,
		{"word":"perturberont","word_nosc":"perturberont","lemma":"perturber","pos":"VER"} ,
		{"word":"perturbez","word_nosc":"perturbez","lemma":"perturber","pos":"VER"} ,
		{"word":"perturbé","word_nosc":"perturbe","lemma":"perturber","pos":"VER"} ,
		{"word":"perturbée","word_nosc":"perturbee","lemma":"perturber","pos":"VER"} ,
		{"word":"perturbées","word_nosc":"perturbees","lemma":"perturber","pos":"VER"} ,
		{"word":"perturbés","word_nosc":"perturbes","lemma":"perturber","pos":"VER"} ,
		{"word":"perverti","word_nosc":"perverti","lemma":"pervertir","pos":"VER"} ,
		{"word":"pervertie","word_nosc":"pervertie","lemma":"pervertir","pos":"VER"} ,
		{"word":"perverties","word_nosc":"perverties","lemma":"pervertir","pos":"VER"} ,
		{"word":"pervertir","word_nosc":"pervertir","lemma":"pervertir","pos":"VER"} ,
		{"word":"pervertis","word_nosc":"pervertis","lemma":"pervertir","pos":"VER"} ,
		{"word":"pervertissaient","word_nosc":"pervertissaient","lemma":"pervertir","pos":"VER"} ,
		{"word":"pervertissait","word_nosc":"pervertissait","lemma":"pervertir","pos":"VER"} ,
		{"word":"pervertissant","word_nosc":"pervertissant","lemma":"pervertir","pos":"VER"} ,
		{"word":"pervertisse","word_nosc":"pervertisse","lemma":"pervertir","pos":"VER"} ,
		{"word":"pervertit","word_nosc":"pervertit","lemma":"pervertir","pos":"VER"} ,
		{"word":"perça","word_nosc":"perca","lemma":"percer","pos":"VER"} ,
		{"word":"perçaient","word_nosc":"percaient","lemma":"percer","pos":"VER"} ,
		{"word":"perçais","word_nosc":"percais","lemma":"percer","pos":"VER"} ,
		{"word":"perçait","word_nosc":"percait","lemma":"percer","pos":"VER"} ,
		{"word":"perçant","word_nosc":"percant","lemma":"percer","pos":"VER"} ,
		{"word":"perçois","word_nosc":"percois","lemma":"percevoir","pos":"VER"} ,
		{"word":"perçoit","word_nosc":"percoit","lemma":"percevoir","pos":"VER"} ,
		{"word":"perçoive","word_nosc":"percoive","lemma":"percevoir","pos":"VER"} ,
		{"word":"perçoivent","word_nosc":"percoivent","lemma":"percevoir","pos":"VER"} ,
		{"word":"perçons","word_nosc":"percons","lemma":"percer","pos":"VER"} ,
		{"word":"perçu","word_nosc":"percu","lemma":"percevoir","pos":"VER"} ,
		{"word":"perçue","word_nosc":"percue","lemma":"percevoir","pos":"VER"} ,
		{"word":"perçues","word_nosc":"percues","lemma":"percevoir","pos":"VER"} ,
		{"word":"perçurent","word_nosc":"percurent","lemma":"percevoir","pos":"VER"} ,
		{"word":"perçus","word_nosc":"percus","lemma":"percevoir","pos":"VER"} ,
		{"word":"perçut","word_nosc":"percut","lemma":"percevoir","pos":"VER"} ,
		{"word":"perçât","word_nosc":"percat","lemma":"percer","pos":"VER"} ,
		{"word":"perçût","word_nosc":"percut","lemma":"percevoir","pos":"VER"} ,
		{"word":"pesa","word_nosc":"pesa","lemma":"peser","pos":"VER"} ,
		{"word":"pesai","word_nosc":"pesai","lemma":"peser","pos":"VER"} ,
		{"word":"pesaient","word_nosc":"pesaient","lemma":"peser","pos":"VER"} ,
		{"word":"pesais","word_nosc":"pesais","lemma":"peser","pos":"VER"} ,
		{"word":"pesait","word_nosc":"pesait","lemma":"peser","pos":"VER"} ,
		{"word":"pesant","word_nosc":"pesant","lemma":"peser","pos":"VER"} ,
		{"word":"peser","word_nosc":"peser","lemma":"peser","pos":"VER"} ,
		{"word":"pesez","word_nosc":"pesez","lemma":"peser","pos":"VER"} ,
		{"word":"pesions","word_nosc":"pesions","lemma":"peser","pos":"VER"} ,
		{"word":"pesons","word_nosc":"pesons","lemma":"peser","pos":"VER"} ,
		{"word":"pesta","word_nosc":"pesta","lemma":"pester","pos":"VER"} ,
		{"word":"pestaient","word_nosc":"pestaient","lemma":"pester","pos":"VER"} ,
		{"word":"pestais","word_nosc":"pestais","lemma":"pester","pos":"VER"} ,
		{"word":"pestait","word_nosc":"pestait","lemma":"pester","pos":"VER"} ,
		{"word":"pestant","word_nosc":"pestant","lemma":"pester","pos":"VER"} ,
		{"word":"peste","word_nosc":"peste","lemma":"pester","pos":"VER"} ,
		{"word":"pestent","word_nosc":"pestent","lemma":"pester","pos":"VER"} ,
		{"word":"pester","word_nosc":"pester","lemma":"pester","pos":"VER"} ,
		{"word":"pestes","word_nosc":"pestes","lemma":"pester","pos":"VER"} ,
		{"word":"pestez","word_nosc":"pestez","lemma":"pester","pos":"VER"} ,
		{"word":"pesté","word_nosc":"peste","lemma":"pester","pos":"VER"} ,
		{"word":"pesât","word_nosc":"pesat","lemma":"peser","pos":"VER"} ,
		{"word":"pesèrent","word_nosc":"peserent","lemma":"peser","pos":"VER"} ,
		{"word":"pesé","word_nosc":"pese","lemma":"peser","pos":"VER"} ,
		{"word":"pesée","word_nosc":"pesee","lemma":"peser","pos":"VER"} ,
		{"word":"pesées","word_nosc":"pesees","lemma":"peser","pos":"VER"} ,
		{"word":"pesés","word_nosc":"peses","lemma":"peser","pos":"VER"} ,
		{"word":"petit-déjeuner","word_nosc":"petit-dejeuner","lemma":"petit-déjeuner","pos":"VER"} ,
		{"word":"peupla","word_nosc":"peupla","lemma":"peupler","pos":"VER"} ,
		{"word":"peuplaient","word_nosc":"peuplaient","lemma":"peupler","pos":"VER"} ,
		{"word":"peuplait","word_nosc":"peuplait","lemma":"peupler","pos":"VER"} ,
		{"word":"peuplant","word_nosc":"peuplant","lemma":"peupler","pos":"VER"} ,
		{"word":"peuple","word_nosc":"peuple","lemma":"peupler","pos":"VER"} ,
		{"word":"peuplent","word_nosc":"peuplent","lemma":"peupler","pos":"VER"} ,
		{"word":"peupler","word_nosc":"peupler","lemma":"peupler","pos":"VER"} ,
		{"word":"peuplera","word_nosc":"peuplera","lemma":"peupler","pos":"VER"} ,
		{"word":"peuplerait","word_nosc":"peuplerait","lemma":"peupler","pos":"VER"} ,
		{"word":"peupleront","word_nosc":"peupleront","lemma":"peupler","pos":"VER"} ,
		{"word":"peuplèrent","word_nosc":"peuplerent","lemma":"peupler","pos":"VER"} ,
		{"word":"peuplé","word_nosc":"peuple","lemma":"peupler","pos":"VER"} ,
		{"word":"peuplée","word_nosc":"peuplee","lemma":"peupler","pos":"VER"} ,
		{"word":"peuplées","word_nosc":"peuplees","lemma":"peupler","pos":"VER"} ,
		{"word":"peuplés","word_nosc":"peuples","lemma":"peupler","pos":"VER"} ,
		{"word":"peut","word_nosc":"peut","lemma":"pouvoir","pos":"VER"} ,
		{"word":"peuvent","word_nosc":"peuvent","lemma":"pouvoir","pos":"VER"} ,
		{"word":"peux","word_nosc":"peux","lemma":"pouvoir","pos":"VER"} ,
		{"word":"phagocyte","word_nosc":"phagocyte","lemma":"phagocyter","pos":"VER"} ,
		{"word":"phagocyter","word_nosc":"phagocyter","lemma":"phagocyter","pos":"VER"} ,
		{"word":"philosophaient","word_nosc":"philosophaient","lemma":"philosopher","pos":"VER"} ,
		{"word":"philosophait","word_nosc":"philosophait","lemma":"philosopher","pos":"VER"} ,
		{"word":"philosophant","word_nosc":"philosophant","lemma":"philosopher","pos":"VER"} ,
		{"word":"philosophe","word_nosc":"philosophe","lemma":"philosopher","pos":"VER"} ,
		{"word":"philosopher","word_nosc":"philosopher","lemma":"philosopher","pos":"VER"} ,
		{"word":"philosophes","word_nosc":"philosophes","lemma":"philosopher","pos":"VER"} ,
		{"word":"philosophons","word_nosc":"philosophons","lemma":"philosopher","pos":"VER"} ,
		{"word":"philosophé","word_nosc":"philosophe","lemma":"philosopher","pos":"VER"} ,
		{"word":"phosphore","word_nosc":"phosphore","lemma":"phosphorer","pos":"VER"} ,
		{"word":"phosphoré","word_nosc":"phosphore","lemma":"phosphorer","pos":"VER"} ,
		{"word":"photocopie","word_nosc":"photocopie","lemma":"photocopier","pos":"VER"} ,
		{"word":"photocopient","word_nosc":"photocopient","lemma":"photocopier","pos":"VER"} ,
		{"word":"photocopier","word_nosc":"photocopier","lemma":"photocopier","pos":"VER"} ,
		{"word":"photocopiera","word_nosc":"photocopiera","lemma":"photocopier","pos":"VER"} ,
		{"word":"photocopierai","word_nosc":"photocopierai","lemma":"photocopier","pos":"VER"} ,
		{"word":"photocopies","word_nosc":"photocopies","lemma":"photocopier","pos":"VER"} ,
		{"word":"photocopié","word_nosc":"photocopie","lemma":"photocopier","pos":"VER"} ,
		{"word":"photocopiée","word_nosc":"photocopiee","lemma":"photocopier","pos":"VER"} ,
		{"word":"photocopiées","word_nosc":"photocopiees","lemma":"photocopier","pos":"VER"} ,
		{"word":"photocopiés","word_nosc":"photocopies","lemma":"photocopier","pos":"VER"} ,
		{"word":"photographia","word_nosc":"photographia","lemma":"photographier","pos":"VER"} ,
		{"word":"photographiaient","word_nosc":"photographiaient","lemma":"photographier","pos":"VER"} ,
		{"word":"photographiais","word_nosc":"photographiais","lemma":"photographier","pos":"VER"} ,
		{"word":"photographiait","word_nosc":"photographiait","lemma":"photographier","pos":"VER"} ,
		{"word":"photographiant","word_nosc":"photographiant","lemma":"photographier","pos":"VER"} ,
		{"word":"photographie","word_nosc":"photographie","lemma":"photographier","pos":"VER"} ,
		{"word":"photographient","word_nosc":"photographient","lemma":"photographier","pos":"VER"} ,
		{"word":"photographier","word_nosc":"photographier","lemma":"photographier","pos":"VER"} ,
		{"word":"photographierai","word_nosc":"photographierai","lemma":"photographier","pos":"VER"} ,
		{"word":"photographierait","word_nosc":"photographierait","lemma":"photographier","pos":"VER"} ,
		{"word":"photographierions","word_nosc":"photographierions","lemma":"photographier","pos":"VER"} ,
		{"word":"photographierons","word_nosc":"photographierons","lemma":"photographier","pos":"VER"} ,
		{"word":"photographieront","word_nosc":"photographieront","lemma":"photographier","pos":"VER"} ,
		{"word":"photographies","word_nosc":"photographies","lemma":"photographier","pos":"VER"} ,
		{"word":"photographiez","word_nosc":"photographiez","lemma":"photographier","pos":"VER"} ,
		{"word":"photographiât","word_nosc":"photographiat","lemma":"photographier","pos":"VER"} ,
		{"word":"photographièrent","word_nosc":"photographierent","lemma":"photographier","pos":"VER"} ,
		{"word":"photographié","word_nosc":"photographie","lemma":"photographier","pos":"VER"} ,
		{"word":"photographiée","word_nosc":"photographiee","lemma":"photographier","pos":"VER"} ,
		{"word":"photographiées","word_nosc":"photographiees","lemma":"photographier","pos":"VER"} ,
		{"word":"photographiés","word_nosc":"photographies","lemma":"photographier","pos":"VER"} ,
		{"word":"phrase","word_nosc":"phrase","lemma":"phraser","pos":"VER"} ,
		{"word":"phraser","word_nosc":"phraser","lemma":"phraser","pos":"VER"} ,
		{"word":"phrases","word_nosc":"phrases","lemma":"phraser","pos":"VER"} ,
		{"word":"phrasé","word_nosc":"phrase","lemma":"phraser","pos":"VER"} ,
		{"word":"phrasée","word_nosc":"phrasee","lemma":"phraser","pos":"VER"} ,
		{"word":"piaffa","word_nosc":"piaffa","lemma":"piaffer","pos":"VER"} ,
		{"word":"piaffaient","word_nosc":"piaffaient","lemma":"piaffer","pos":"VER"} ,
		{"word":"piaffait","word_nosc":"piaffait","lemma":"piaffer","pos":"VER"} ,
		{"word":"piaffant","word_nosc":"piaffant","lemma":"piaffer","pos":"VER"} ,
		{"word":"piaffe","word_nosc":"piaffe","lemma":"piaffer","pos":"VER"} ,
		{"word":"piaffent","word_nosc":"piaffent","lemma":"piaffer","pos":"VER"} ,
		{"word":"piaffer","word_nosc":"piaffer","lemma":"piaffer","pos":"VER"} ,
		{"word":"piaffes","word_nosc":"piaffes","lemma":"piaffer","pos":"VER"} ,
		{"word":"piaffé","word_nosc":"piaffe","lemma":"piaffer","pos":"VER"} ,
		{"word":"piailla","word_nosc":"piailla","lemma":"piailler","pos":"VER"} ,
		{"word":"piaillaient","word_nosc":"piaillaient","lemma":"piailler","pos":"VER"} ,
		{"word":"piaillais","word_nosc":"piaillais","lemma":"piailler","pos":"VER"} ,
		{"word":"piaillait","word_nosc":"piaillait","lemma":"piailler","pos":"VER"} ,
		{"word":"piaillant","word_nosc":"piaillant","lemma":"piailler","pos":"VER"} ,
		{"word":"piaille","word_nosc":"piaille","lemma":"piailler","pos":"VER"} ,
		{"word":"piaillent","word_nosc":"piaillent","lemma":"piailler","pos":"VER"} ,
		{"word":"piailler","word_nosc":"piailler","lemma":"piailler","pos":"VER"} ,
		{"word":"piaillèrent","word_nosc":"piaillerent","lemma":"piailler","pos":"VER"} ,
		{"word":"pianota","word_nosc":"pianota","lemma":"pianoter","pos":"VER"} ,
		{"word":"pianotaient","word_nosc":"pianotaient","lemma":"pianoter","pos":"VER"} ,
		{"word":"pianotait","word_nosc":"pianotait","lemma":"pianoter","pos":"VER"} ,
		{"word":"pianotant","word_nosc":"pianotant","lemma":"pianoter","pos":"VER"} ,
		{"word":"pianote","word_nosc":"pianote","lemma":"pianoter","pos":"VER"} ,
		{"word":"pianoter","word_nosc":"pianoter","lemma":"pianoter","pos":"VER"} ,
		{"word":"pianotèrent","word_nosc":"pianoterent","lemma":"pianoter","pos":"VER"} ,
		{"word":"pianoté","word_nosc":"pianote","lemma":"pianoter","pos":"VER"} ,
		{"word":"pianotée","word_nosc":"pianotee","lemma":"pianoter","pos":"VER"} ,
		{"word":"piaula","word_nosc":"piaula","lemma":"piauler","pos":"VER"} ,
		{"word":"piaulaient","word_nosc":"piaulaient","lemma":"piauler","pos":"VER"} ,
		{"word":"piaulait","word_nosc":"piaulait","lemma":"piauler","pos":"VER"} ,
		{"word":"piaulant","word_nosc":"piaulant","lemma":"piauler","pos":"VER"} ,
		{"word":"piaule","word_nosc":"piaule","lemma":"piauler","pos":"VER"} ,
		{"word":"piaulent","word_nosc":"piaulent","lemma":"piauler","pos":"VER"} ,
		{"word":"piauler","word_nosc":"piauler","lemma":"piauler","pos":"VER"} ,
		{"word":"piaulera","word_nosc":"piaulera","lemma":"piauler","pos":"VER"} ,
		{"word":"piaulèrent","word_nosc":"piaulerent","lemma":"piauler","pos":"VER"} ,
		{"word":"picolaient","word_nosc":"picolaient","lemma":"picoler","pos":"VER"} ,
		{"word":"picolais","word_nosc":"picolais","lemma":"picoler","pos":"VER"} ,
		{"word":"picolait","word_nosc":"picolait","lemma":"picoler","pos":"VER"} ,
		{"word":"picolant","word_nosc":"picolant","lemma":"picoler","pos":"VER"} ,
		{"word":"picole","word_nosc":"picole","lemma":"picoler","pos":"VER"} ,
		{"word":"picolent","word_nosc":"picolent","lemma":"picoler","pos":"VER"} ,
		{"word":"picoler","word_nosc":"picoler","lemma":"picoler","pos":"VER"} ,
		{"word":"picolerait","word_nosc":"picolerait","lemma":"picoler","pos":"VER"} ,
		{"word":"picoles","word_nosc":"picoles","lemma":"picoler","pos":"VER"} ,
		{"word":"picolez","word_nosc":"picolez","lemma":"picoler","pos":"VER"} ,
		{"word":"picoliez","word_nosc":"picoliez","lemma":"picoler","pos":"VER"} ,
		{"word":"picolé","word_nosc":"picole","lemma":"picoler","pos":"VER"} ,
		{"word":"picora","word_nosc":"picora","lemma":"picorer","pos":"VER"} ,
		{"word":"picoraient","word_nosc":"picoraient","lemma":"picorer","pos":"VER"} ,
		{"word":"picorait","word_nosc":"picorait","lemma":"picorer","pos":"VER"} ,
		{"word":"picorant","word_nosc":"picorant","lemma":"picorer","pos":"VER"} ,
		{"word":"picore","word_nosc":"picore","lemma":"picorer","pos":"VER"} ,
		{"word":"picorent","word_nosc":"picorent","lemma":"picorer","pos":"VER"} ,
		{"word":"picorer","word_nosc":"picorer","lemma":"picorer","pos":"VER"} ,
		{"word":"picorez","word_nosc":"picorez","lemma":"picorer","pos":"VER"} ,
		{"word":"picoré","word_nosc":"picore","lemma":"picorer","pos":"VER"} ,
		{"word":"picorée","word_nosc":"picoree","lemma":"picorer","pos":"VER"} ,
		{"word":"picorées","word_nosc":"picorees","lemma":"picorer","pos":"VER"} ,
		{"word":"picorés","word_nosc":"picores","lemma":"picorer","pos":"VER"} ,
		{"word":"picota","word_nosc":"picota","lemma":"picoter","pos":"VER"} ,
		{"word":"picotaient","word_nosc":"picotaient","lemma":"picoter","pos":"VER"} ,
		{"word":"picotait","word_nosc":"picotait","lemma":"picoter","pos":"VER"} ,
		{"word":"picotant","word_nosc":"picotant","lemma":"picoter","pos":"VER"} ,
		{"word":"picote","word_nosc":"picote","lemma":"picoter","pos":"VER"} ,
		{"word":"picotent","word_nosc":"picotent","lemma":"picoter","pos":"VER"} ,
		{"word":"picoter","word_nosc":"picoter","lemma":"picoter","pos":"VER"} ,
		{"word":"picoté","word_nosc":"picote","lemma":"picoter","pos":"VER"} ,
		{"word":"picotées","word_nosc":"picotees","lemma":"picoter","pos":"VER"} ,
		{"word":"picté","word_nosc":"picte","lemma":"picter","pos":"VER"} ,
		{"word":"pieutait","word_nosc":"pieutait","lemma":"pieuter","pos":"VER"} ,
		{"word":"pieute","word_nosc":"pieute","lemma":"pieuter","pos":"VER"} ,
		{"word":"pieuter","word_nosc":"pieuter","lemma":"pieuter","pos":"VER"} ,
		{"word":"pieuterez","word_nosc":"pieuterez","lemma":"pieuter","pos":"VER"} ,
		{"word":"pieutes","word_nosc":"pieutes","lemma":"pieuter","pos":"VER"} ,
		{"word":"pieutez","word_nosc":"pieutez","lemma":"pieuter","pos":"VER"} ,
		{"word":"pieuté","word_nosc":"pieute","lemma":"pieuter","pos":"VER"} ,
		{"word":"pieutée","word_nosc":"pieutee","lemma":"pieuter","pos":"VER"} ,
		{"word":"pieutés","word_nosc":"pieutes","lemma":"pieuter","pos":"VER"} ,
		{"word":"pifer","word_nosc":"pifer","lemma":"pifer","pos":"VER"} ,
		{"word":"piffait","word_nosc":"piffait","lemma":"piffer","pos":"VER"} ,
		{"word":"piffer","word_nosc":"piffer","lemma":"piffer","pos":"VER"} ,
		{"word":"piffé","word_nosc":"piffe","lemma":"piffer","pos":"VER"} ,
		{"word":"pige","word_nosc":"pige","lemma":"piger","pos":"VER"} ,
		{"word":"pigeais","word_nosc":"pigeais","lemma":"piger","pos":"VER"} ,
		{"word":"pigeait","word_nosc":"pigeait","lemma":"piger","pos":"VER"} ,
		{"word":"pigent","word_nosc":"pigent","lemma":"piger","pos":"VER"} ,
		{"word":"pigeonnant","word_nosc":"pigeonnant","lemma":"pigeonner","pos":"VER"} ,
		{"word":"pigeonne","word_nosc":"pigeonne","lemma":"pigeonner","pos":"VER"} ,
		{"word":"pigeonner","word_nosc":"pigeonner","lemma":"pigeonner","pos":"VER"} ,
		{"word":"pigeonné","word_nosc":"pigeonne","lemma":"pigeonner","pos":"VER"} ,
		{"word":"pigeons","word_nosc":"pigeons","lemma":"piger","pos":"VER"} ,
		{"word":"piger","word_nosc":"piger","lemma":"piger","pos":"VER"} ,
		{"word":"pigera","word_nosc":"pigera","lemma":"piger","pos":"VER"} ,
		{"word":"pigerai","word_nosc":"pigerai","lemma":"piger","pos":"VER"} ,
		{"word":"pigeraient","word_nosc":"pigeraient","lemma":"piger","pos":"VER"} ,
		{"word":"pigerait","word_nosc":"pigerait","lemma":"piger","pos":"VER"} ,
		{"word":"pigeras","word_nosc":"pigeras","lemma":"piger","pos":"VER"} ,
		{"word":"pigerez","word_nosc":"pigerez","lemma":"piger","pos":"VER"} ,
		{"word":"piges","word_nosc":"piges","lemma":"piger","pos":"VER"} ,
		{"word":"pigez","word_nosc":"pigez","lemma":"piger","pos":"VER"} ,
		{"word":"pigner","word_nosc":"pigner","lemma":"pigner","pos":"VER"} ,
		{"word":"pignochait","word_nosc":"pignochait","lemma":"pignocher","pos":"VER"} ,
		{"word":"pigé","word_nosc":"pige","lemma":"piger","pos":"VER"} ,
		{"word":"pila","word_nosc":"pila","lemma":"piler","pos":"VER"} ,
		{"word":"pilaient","word_nosc":"pilaient","lemma":"piler","pos":"VER"} ,
		{"word":"pilait","word_nosc":"pilait","lemma":"piler","pos":"VER"} ,
		{"word":"pilant","word_nosc":"pilant","lemma":"piler","pos":"VER"} ,
		{"word":"pile","word_nosc":"pile","lemma":"piler","pos":"VER"} ,
		{"word":"pilent","word_nosc":"pilent","lemma":"piler","pos":"VER"} ,
		{"word":"piler","word_nosc":"piler","lemma":"piler","pos":"VER"} ,
		{"word":"piles","word_nosc":"piles","lemma":"piler","pos":"VER"} ,
		{"word":"pilla","word_nosc":"pilla","lemma":"piller","pos":"VER"} ,
		{"word":"pillaient","word_nosc":"pillaient","lemma":"piller","pos":"VER"} ,
		{"word":"pillais","word_nosc":"pillais","lemma":"piller","pos":"VER"} ,
		{"word":"pillait","word_nosc":"pillait","lemma":"piller","pos":"VER"} ,
		{"word":"pillant","word_nosc":"pillant","lemma":"piller","pos":"VER"} ,
		{"word":"pille","word_nosc":"pille","lemma":"piller","pos":"VER"} ,
		{"word":"pillent","word_nosc":"pillent","lemma":"piller","pos":"VER"} ,
		{"word":"piller","word_nosc":"piller","lemma":"piller","pos":"VER"} ,
		{"word":"pillera","word_nosc":"pillera","lemma":"piller","pos":"VER"} ,
		{"word":"pillerait","word_nosc":"pillerait","lemma":"piller","pos":"VER"} ,
		{"word":"pillerons","word_nosc":"pillerons","lemma":"piller","pos":"VER"} ,
		{"word":"pilleront","word_nosc":"pilleront","lemma":"piller","pos":"VER"} ,
		{"word":"pilles","word_nosc":"pilles","lemma":"piller","pos":"VER"} ,
		{"word":"pillez","word_nosc":"pillez","lemma":"piller","pos":"VER"} ,
		{"word":"pilliez","word_nosc":"pilliez","lemma":"piller","pos":"VER"} ,
		{"word":"pillons","word_nosc":"pillons","lemma":"piller","pos":"VER"} ,
		{"word":"pillèrent","word_nosc":"pillerent","lemma":"piller","pos":"VER"} ,
		{"word":"pillé","word_nosc":"pille","lemma":"piller","pos":"VER"} ,
		{"word":"pillée","word_nosc":"pillee","lemma":"piller","pos":"VER"} ,
		{"word":"pillées","word_nosc":"pillees","lemma":"piller","pos":"VER"} ,
		{"word":"pillés","word_nosc":"pilles","lemma":"piller","pos":"VER"} ,
		{"word":"pilonnaient","word_nosc":"pilonnaient","lemma":"pilonner","pos":"VER"} ,
		{"word":"pilonnait","word_nosc":"pilonnait","lemma":"pilonner","pos":"VER"} ,
		{"word":"pilonnant","word_nosc":"pilonnant","lemma":"pilonner","pos":"VER"} ,
		{"word":"pilonnent","word_nosc":"pilonnent","lemma":"pilonner","pos":"VER"} ,
		{"word":"pilonner","word_nosc":"pilonner","lemma":"pilonner","pos":"VER"} ,
		{"word":"pilonnera","word_nosc":"pilonnera","lemma":"pilonner","pos":"VER"} ,
		{"word":"pilonnez","word_nosc":"pilonnez","lemma":"pilonner","pos":"VER"} ,
		{"word":"pilonné","word_nosc":"pilonne","lemma":"pilonner","pos":"VER"} ,
		{"word":"pilonnée","word_nosc":"pilonnee","lemma":"pilonner","pos":"VER"} ,
		{"word":"pilonnées","word_nosc":"pilonnees","lemma":"pilonner","pos":"VER"} ,
		{"word":"pilonnés","word_nosc":"pilonnes","lemma":"pilonner","pos":"VER"} ,
		{"word":"pilota","word_nosc":"pilota","lemma":"piloter","pos":"VER"} ,
		{"word":"pilotaient","word_nosc":"pilotaient","lemma":"piloter","pos":"VER"} ,
		{"word":"pilotais","word_nosc":"pilotais","lemma":"piloter","pos":"VER"} ,
		{"word":"pilotait","word_nosc":"pilotait","lemma":"piloter","pos":"VER"} ,
		{"word":"pilotant","word_nosc":"pilotant","lemma":"piloter","pos":"VER"} ,
		{"word":"pilote","word_nosc":"pilote","lemma":"piloter","pos":"VER"} ,
		{"word":"pilotent","word_nosc":"pilotent","lemma":"piloter","pos":"VER"} ,
		{"word":"piloter","word_nosc":"piloter","lemma":"piloter","pos":"VER"} ,
		{"word":"pilotera","word_nosc":"pilotera","lemma":"piloter","pos":"VER"} ,
		{"word":"piloterai","word_nosc":"piloterai","lemma":"piloter","pos":"VER"} ,
		{"word":"piloterait","word_nosc":"piloterait","lemma":"piloter","pos":"VER"} ,
		{"word":"piloteras","word_nosc":"piloteras","lemma":"piloter","pos":"VER"} ,
		{"word":"piloteront","word_nosc":"piloteront","lemma":"piloter","pos":"VER"} ,
		{"word":"pilotes","word_nosc":"pilotes","lemma":"piloter","pos":"VER"} ,
		{"word":"pilotez","word_nosc":"pilotez","lemma":"piloter","pos":"VER"} ,
		{"word":"pilotiez","word_nosc":"pilotiez","lemma":"piloter","pos":"VER"} ,
		{"word":"pilotèrent","word_nosc":"piloterent","lemma":"piloter","pos":"VER"} ,
		{"word":"piloté","word_nosc":"pilote","lemma":"piloter","pos":"VER"} ,
		{"word":"pilotée","word_nosc":"pilotee","lemma":"piloter","pos":"VER"} ,
		{"word":"pilotées","word_nosc":"pilotees","lemma":"piloter","pos":"VER"} ,
		{"word":"pilotés","word_nosc":"pilotes","lemma":"piloter","pos":"VER"} ,
		{"word":"pilé","word_nosc":"pile","lemma":"piler","pos":"VER"} ,
		{"word":"pilée","word_nosc":"pilee","lemma":"piler","pos":"VER"} ,
		{"word":"pilées","word_nosc":"pilees","lemma":"piler","pos":"VER"} ,
		{"word":"pilés","word_nosc":"piles","lemma":"piler","pos":"VER"} ,
		{"word":"pimenta","word_nosc":"pimenta","lemma":"pimenter","pos":"VER"} ,
		{"word":"pimentait","word_nosc":"pimentait","lemma":"pimenter","pos":"VER"} ,
		{"word":"pimentant","word_nosc":"pimentant","lemma":"pimenter","pos":"VER"} ,
		{"word":"pimente","word_nosc":"pimente","lemma":"pimenter","pos":"VER"} ,
		{"word":"pimentent","word_nosc":"pimentent","lemma":"pimenter","pos":"VER"} ,
		{"word":"pimenter","word_nosc":"pimenter","lemma":"pimenter","pos":"VER"} ,
		{"word":"pimenté","word_nosc":"pimente","lemma":"pimenter","pos":"VER"} ,
		{"word":"pimentée","word_nosc":"pimentee","lemma":"pimenter","pos":"VER"} ,
		{"word":"pimentées","word_nosc":"pimentees","lemma":"pimenter","pos":"VER"} ,
		{"word":"pina","word_nosc":"pina","lemma":"piner","pos":"VER"} ,
		{"word":"pinaillait","word_nosc":"pinaillait","lemma":"pinailler","pos":"VER"} ,
		{"word":"pinaille","word_nosc":"pinaille","lemma":"pinailler","pos":"VER"} ,
		{"word":"pinailler","word_nosc":"pinailler","lemma":"pinailler","pos":"VER"} ,
		{"word":"pinailles","word_nosc":"pinailles","lemma":"pinailler","pos":"VER"} ,
		{"word":"pinaillé","word_nosc":"pinaille","lemma":"pinailler","pos":"VER"} ,
		{"word":"pince","word_nosc":"pince","lemma":"pincer","pos":"VER"} ,
		{"word":"pincent","word_nosc":"pincent","lemma":"pincer","pos":"VER"} ,
		{"word":"pincer","word_nosc":"pincer","lemma":"pincer","pos":"VER"} ,
		{"word":"pincer","word_nosc":"pincer","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pincera","word_nosc":"pincera","lemma":"pincer","pos":"VER"} ,
		{"word":"pincerai","word_nosc":"pincerai","lemma":"pincer","pos":"VER"} ,
		{"word":"pinceras","word_nosc":"pinceras","lemma":"pincer","pos":"VER"} ,
		{"word":"pincerez","word_nosc":"pincerez","lemma":"pincer","pos":"VER"} ,
		{"word":"pincerons","word_nosc":"pincerons","lemma":"pincer","pos":"VER"} ,
		{"word":"pinces","word_nosc":"pinces","lemma":"pincer","pos":"VER"} ,
		{"word":"pincez","word_nosc":"pincez","lemma":"pincer","pos":"VER"} ,
		{"word":"pinciez","word_nosc":"pinciez","lemma":"pincer","pos":"VER"} ,
		{"word":"pincions","word_nosc":"pincions","lemma":"pincer","pos":"VER"} ,
		{"word":"pincèrent","word_nosc":"pincerent","lemma":"pincer","pos":"VER"} ,
		{"word":"pincé","word_nosc":"pince","lemma":"pincer","pos":"VER"} ,
		{"word":"pincée","word_nosc":"pincee","lemma":"pincer","pos":"VER"} ,
		{"word":"pincées","word_nosc":"pincees","lemma":"pincer","pos":"VER"} ,
		{"word":"pincés","word_nosc":"pinces","lemma":"pincer","pos":"VER"} ,
		{"word":"pine","word_nosc":"pine","lemma":"piner","pos":"VER"} ,
		{"word":"piner","word_nosc":"piner","lemma":"piner","pos":"VER"} ,
		{"word":"pinta","word_nosc":"pinta","lemma":"pinter","pos":"VER"} ,
		{"word":"pintait","word_nosc":"pintait","lemma":"pinter","pos":"VER"} ,
		{"word":"pinte","word_nosc":"pinte","lemma":"pinter","pos":"VER"} ,
		{"word":"pinter","word_nosc":"pinter","lemma":"pinter","pos":"VER"} ,
		{"word":"pinté","word_nosc":"pinte","lemma":"pinter","pos":"VER"} ,
		{"word":"pintée","word_nosc":"pintee","lemma":"pinter","pos":"VER"} ,
		{"word":"pintés","word_nosc":"pintes","lemma":"pinter","pos":"VER"} ,
		{"word":"pinça","word_nosc":"pinca","lemma":"pincer","pos":"VER"} ,
		{"word":"pinçai","word_nosc":"pincai","lemma":"pincer","pos":"VER"} ,
		{"word":"pinçaient","word_nosc":"pincaient","lemma":"pincer","pos":"VER"} ,
		{"word":"pinçais","word_nosc":"pincais","lemma":"pincer","pos":"VER"} ,
		{"word":"pinçait","word_nosc":"pincait","lemma":"pincer","pos":"VER"} ,
		{"word":"pinçant","word_nosc":"pincant","lemma":"pincer","pos":"VER"} ,
		{"word":"pinçons","word_nosc":"pincons","lemma":"pincer","pos":"VER"} ,
		{"word":"pinçotait","word_nosc":"pincotait","lemma":"pinçoter","pos":"VER"} ,
		{"word":"pinçotant","word_nosc":"pincotant","lemma":"pinçoter","pos":"VER"} ,
		{"word":"pinçote","word_nosc":"pincote","lemma":"pinçoter","pos":"VER"} ,
		{"word":"piné","word_nosc":"pine","lemma":"piner","pos":"VER"} ,
		{"word":"piochaient","word_nosc":"piochaient","lemma":"piocher","pos":"VER"} ,
		{"word":"piochais","word_nosc":"piochais","lemma":"piocher","pos":"VER"} ,
		{"word":"piochait","word_nosc":"piochait","lemma":"piocher","pos":"VER"} ,
		{"word":"piochant","word_nosc":"piochant","lemma":"piocher","pos":"VER"} ,
		{"word":"pioche","word_nosc":"pioche","lemma":"piocher","pos":"VER"} ,
		{"word":"piochent","word_nosc":"piochent","lemma":"piocher","pos":"VER"} ,
		{"word":"piocher","word_nosc":"piocher","lemma":"piocher","pos":"VER"} ,
		{"word":"pioches","word_nosc":"pioches","lemma":"piocher","pos":"VER"} ,
		{"word":"piochez","word_nosc":"piochez","lemma":"piocher","pos":"VER"} ,
		{"word":"pioché","word_nosc":"pioche","lemma":"piocher","pos":"VER"} ,
		{"word":"piochée","word_nosc":"piochee","lemma":"piocher","pos":"VER"} ,
		{"word":"piochées","word_nosc":"piochees","lemma":"piocher","pos":"VER"} ,
		{"word":"pionce","word_nosc":"pionce","lemma":"pioncer","pos":"VER"} ,
		{"word":"pioncent","word_nosc":"pioncent","lemma":"pioncer","pos":"VER"} ,
		{"word":"pioncer","word_nosc":"pioncer","lemma":"pioncer","pos":"VER"} ,
		{"word":"pioncera","word_nosc":"pioncera","lemma":"pioncer","pos":"VER"} ,
		{"word":"pionces","word_nosc":"pionces","lemma":"pioncer","pos":"VER"} ,
		{"word":"pioncez","word_nosc":"pioncez","lemma":"pioncer","pos":"VER"} ,
		{"word":"pioncé","word_nosc":"pionce","lemma":"pioncer","pos":"VER"} ,
		{"word":"pionner","word_nosc":"pionner","lemma":"pionner","pos":"VER"} ,
		{"word":"pionçais","word_nosc":"pioncais","lemma":"pioncer","pos":"VER"} ,
		{"word":"pionçait","word_nosc":"pioncait","lemma":"pioncer","pos":"VER"} ,
		{"word":"pionçant","word_nosc":"pioncant","lemma":"pioncer","pos":"VER"} ,
		{"word":"pipa","word_nosc":"pipa","lemma":"piper","pos":"VER"} ,
		{"word":"pipai","word_nosc":"pipai","lemma":"piper","pos":"VER"} ,
		{"word":"pipais","word_nosc":"pipais","lemma":"piper","pos":"VER"} ,
		{"word":"pipait","word_nosc":"pipait","lemma":"piper","pos":"VER"} ,
		{"word":"pipant","word_nosc":"pipant","lemma":"piper","pos":"VER"} ,
		{"word":"pipasse","word_nosc":"pipasse","lemma":"piper","pos":"VER"} ,
		{"word":"pipe","word_nosc":"pipe","lemma":"piper","pos":"VER"} ,
		{"word":"piper","word_nosc":"piper","lemma":"piper","pos":"VER"} ,
		{"word":"pipera","word_nosc":"pipera","lemma":"piper","pos":"VER"} ,
		{"word":"pipes","word_nosc":"pipes","lemma":"piper","pos":"VER"} ,
		{"word":"pipé","word_nosc":"pipe","lemma":"piper","pos":"VER"} ,
		{"word":"pipés","word_nosc":"pipes","lemma":"piper","pos":"VER"} ,
		{"word":"piqua","word_nosc":"piqua","lemma":"piquer","pos":"VER"} ,
		{"word":"piquai","word_nosc":"piquai","lemma":"piquer","pos":"VER"} ,
		{"word":"piquaient","word_nosc":"piquaient","lemma":"piquer","pos":"VER"} ,
		{"word":"piquais","word_nosc":"piquais","lemma":"piquer","pos":"VER"} ,
		{"word":"piquait","word_nosc":"piquait","lemma":"piquer","pos":"VER"} ,
		{"word":"piquant","word_nosc":"piquant","lemma":"piquer","pos":"VER"} ,
		{"word":"pique","word_nosc":"pique","lemma":"piquer","pos":"VER"} ,
		{"word":"pique-niquaient","word_nosc":"pique-niquaient","lemma":"pique-niquer","pos":"VER"} ,
		{"word":"pique-nique","word_nosc":"pique-nique","lemma":"pique-niquer","pos":"VER"} ,
		{"word":"pique-niquent","word_nosc":"pique-niquent","lemma":"pique-niquer","pos":"VER"} ,
		{"word":"pique-niquer","word_nosc":"pique-niquer","lemma":"pique-niquer","pos":"VER"} ,
		{"word":"pique-niqueraient","word_nosc":"pique-niqueraient","lemma":"pique-niquer","pos":"VER"} ,
		{"word":"pique-niquerons","word_nosc":"pique-niquerons","lemma":"pique-niquer","pos":"VER"} ,
		{"word":"pique-niques","word_nosc":"pique-niques","lemma":"pique-niquer","pos":"VER"} ,
		{"word":"pique-niquez","word_nosc":"pique-niquez","lemma":"pique-niquer","pos":"VER"} ,
		{"word":"pique-niqué","word_nosc":"pique-nique","lemma":"pique-niquer","pos":"VER"} ,
		{"word":"piquent","word_nosc":"piquent","lemma":"piquer","pos":"VER"} ,
		{"word":"piquer","word_nosc":"piquer","lemma":"piquer","pos":"VER"} ,
		{"word":"piquera","word_nosc":"piquera","lemma":"piquer","pos":"VER"} ,
		{"word":"piquerai","word_nosc":"piquerai","lemma":"piquer","pos":"VER"} ,
		{"word":"piqueraient","word_nosc":"piqueraient","lemma":"piquer","pos":"VER"} ,
		{"word":"piquerais","word_nosc":"piquerais","lemma":"piquer","pos":"VER"} ,
		{"word":"piquerait","word_nosc":"piquerait","lemma":"piquer","pos":"VER"} ,
		{"word":"piqueras","word_nosc":"piqueras","lemma":"piquer","pos":"VER"} ,
		{"word":"piquerez","word_nosc":"piquerez","lemma":"piquer","pos":"VER"} ,
		{"word":"piqueront","word_nosc":"piqueront","lemma":"piquer","pos":"VER"} ,
		{"word":"piques","word_nosc":"piques","lemma":"piquer","pos":"VER"} ,
		{"word":"piquetaient","word_nosc":"piquetaient","lemma":"piqueter","pos":"VER"} ,
		{"word":"piquetait","word_nosc":"piquetait","lemma":"piqueter","pos":"VER"} ,
		{"word":"piqueter","word_nosc":"piqueter","lemma":"piqueter","pos":"VER"} ,
		{"word":"piqueté","word_nosc":"piquete","lemma":"piqueter","pos":"VER"} ,
		{"word":"piquetée","word_nosc":"piquetee","lemma":"piqueter","pos":"VER"} ,
		{"word":"piquetées","word_nosc":"piquetees","lemma":"piqueter","pos":"VER"} ,
		{"word":"piquetés","word_nosc":"piquetes","lemma":"piqueter","pos":"VER"} ,
		{"word":"piquez","word_nosc":"piquez","lemma":"piquer","pos":"VER"} ,
		{"word":"piquiez","word_nosc":"piquiez","lemma":"piquer","pos":"VER"} ,
		{"word":"piquions","word_nosc":"piquions","lemma":"piquer","pos":"VER"} ,
		{"word":"piquons","word_nosc":"piquons","lemma":"piquer","pos":"VER"} ,
		{"word":"piquouser","word_nosc":"piquouser","lemma":"piquouser","pos":"VER"} ,
		{"word":"piquèrent","word_nosc":"piquerent","lemma":"piquer","pos":"VER"} ,
		{"word":"piqué","word_nosc":"pique","lemma":"piquer","pos":"VER"} ,
		{"word":"piquée","word_nosc":"piquee","lemma":"piquer","pos":"VER"} ,
		{"word":"piquées","word_nosc":"piquees","lemma":"piquer","pos":"VER"} ,
		{"word":"piqués","word_nosc":"piques","lemma":"piquer","pos":"VER"} ,
		{"word":"pirataient","word_nosc":"pirataient","lemma":"pirater","pos":"VER"} ,
		{"word":"piratant","word_nosc":"piratant","lemma":"pirater","pos":"VER"} ,
		{"word":"pirate","word_nosc":"pirate","lemma":"pirater","pos":"VER"} ,
		{"word":"piratent","word_nosc":"piratent","lemma":"pirater","pos":"VER"} ,
		{"word":"pirater","word_nosc":"pirater","lemma":"pirater","pos":"VER"} ,
		{"word":"pirates","word_nosc":"pirates","lemma":"pirater","pos":"VER"} ,
		{"word":"piratez","word_nosc":"piratez","lemma":"pirater","pos":"VER"} ,
		{"word":"piraté","word_nosc":"pirate","lemma":"pirater","pos":"VER"} ,
		{"word":"piratée","word_nosc":"piratee","lemma":"pirater","pos":"VER"} ,
		{"word":"piratés","word_nosc":"pirates","lemma":"pirater","pos":"VER"} ,
		{"word":"pirouetta","word_nosc":"pirouetta","lemma":"pirouetter","pos":"VER"} ,
		{"word":"pirouettais","word_nosc":"pirouettais","lemma":"pirouetter","pos":"VER"} ,
		{"word":"pirouettait","word_nosc":"pirouettait","lemma":"pirouetter","pos":"VER"} ,
		{"word":"pirouettant","word_nosc":"pirouettant","lemma":"pirouetter","pos":"VER"} ,
		{"word":"pirouette","word_nosc":"pirouette","lemma":"pirouetter","pos":"VER"} ,
		{"word":"pirouetter","word_nosc":"pirouetter","lemma":"pirouetter","pos":"VER"} ,
		{"word":"pirouettes","word_nosc":"pirouettes","lemma":"pirouetter","pos":"VER"} ,
		{"word":"pisa","word_nosc":"pisa","lemma":"piser","pos":"VER"} ,
		{"word":"pissa","word_nosc":"pissa","lemma":"pisser","pos":"VER"} ,
		{"word":"pissaient","word_nosc":"pissaient","lemma":"pisser","pos":"VER"} ,
		{"word":"pissais","word_nosc":"pissais","lemma":"pisser","pos":"VER"} ,
		{"word":"pissait","word_nosc":"pissait","lemma":"pisser","pos":"VER"} ,
		{"word":"pissant","word_nosc":"pissant","lemma":"pisser","pos":"VER"} ,
		{"word":"pisse","word_nosc":"pisse","lemma":"pisser","pos":"VER"} ,
		{"word":"pissent","word_nosc":"pissent","lemma":"pisser","pos":"VER"} ,
		{"word":"pisser","word_nosc":"pisser","lemma":"pisser","pos":"VER"} ,
		{"word":"pissera","word_nosc":"pissera","lemma":"pisser","pos":"VER"} ,
		{"word":"pisserai","word_nosc":"pisserai","lemma":"pisser","pos":"VER"} ,
		{"word":"pisseraient","word_nosc":"pisseraient","lemma":"pisser","pos":"VER"} ,
		{"word":"pisserais","word_nosc":"pisserais","lemma":"pisser","pos":"VER"} ,
		{"word":"pisserait","word_nosc":"pisserait","lemma":"pisser","pos":"VER"} ,
		{"word":"pisseras","word_nosc":"pisseras","lemma":"pisser","pos":"VER"} ,
		{"word":"pisses","word_nosc":"pisses","lemma":"pisser","pos":"VER"} ,
		{"word":"pissez","word_nosc":"pissez","lemma":"pisser","pos":"VER"} ,
		{"word":"pissons","word_nosc":"pissons","lemma":"pisser","pos":"VER"} ,
		{"word":"pissât","word_nosc":"pissat","lemma":"pisser","pos":"VER"} ,
		{"word":"pissèrent","word_nosc":"pisserent","lemma":"pisser","pos":"VER"} ,
		{"word":"pissé","word_nosc":"pisse","lemma":"pisser","pos":"VER"} ,
		{"word":"pissée","word_nosc":"pissee","lemma":"pisser","pos":"VER"} ,
		{"word":"pista","word_nosc":"pista","lemma":"pister","pos":"VER"} ,
		{"word":"pistais","word_nosc":"pistais","lemma":"pister","pos":"VER"} ,
		{"word":"pistait","word_nosc":"pistait","lemma":"pister","pos":"VER"} ,
		{"word":"pistant","word_nosc":"pistant","lemma":"pister","pos":"VER"} ,
		{"word":"piste","word_nosc":"piste","lemma":"pister","pos":"VER"} ,
		{"word":"pistent","word_nosc":"pistent","lemma":"pister","pos":"VER"} ,
		{"word":"pister","word_nosc":"pister","lemma":"pister","pos":"VER"} ,
		{"word":"pistera","word_nosc":"pistera","lemma":"pister","pos":"VER"} ,
		{"word":"pisterai","word_nosc":"pisterai","lemma":"pister","pos":"VER"} ,
		{"word":"pistes","word_nosc":"pistes","lemma":"pister","pos":"VER"} ,
		{"word":"pistez","word_nosc":"pistez","lemma":"pister","pos":"VER"} ,
		{"word":"pistonnais","word_nosc":"pistonnais","lemma":"pistonner","pos":"VER"} ,
		{"word":"pistonner","word_nosc":"pistonner","lemma":"pistonner","pos":"VER"} ,
		{"word":"pistonnera","word_nosc":"pistonnera","lemma":"pistonner","pos":"VER"} ,
		{"word":"pistonnerai","word_nosc":"pistonnerai","lemma":"pistonner","pos":"VER"} ,
		{"word":"pistonnions","word_nosc":"pistonnions","lemma":"pistonner","pos":"VER"} ,
		{"word":"pistonné","word_nosc":"pistonne","lemma":"pistonner","pos":"VER"} ,
		{"word":"pistonnée","word_nosc":"pistonnee","lemma":"pistonner","pos":"VER"} ,
		{"word":"pistonnés","word_nosc":"pistonnes","lemma":"pistonner","pos":"VER"} ,
		{"word":"pisté","word_nosc":"piste","lemma":"pister","pos":"VER"} ,
		{"word":"pistée","word_nosc":"pistee","lemma":"pister","pos":"VER"} ,
		{"word":"pistées","word_nosc":"pistees","lemma":"pister","pos":"VER"} ,
		{"word":"pistés","word_nosc":"pistes","lemma":"pister","pos":"VER"} ,
		{"word":"pitancher","word_nosc":"pitancher","lemma":"pitancher","pos":"VER"} ,
		{"word":"pitancherai","word_nosc":"pitancherai","lemma":"pitancher","pos":"VER"} ,
		{"word":"pitcher","word_nosc":"pitcher","lemma":"pitcher","pos":"VER"} ,
		{"word":"pitonnant","word_nosc":"pitonnant","lemma":"pitonner","pos":"VER"} ,
		{"word":"pitonner","word_nosc":"pitonner","lemma":"pitonner","pos":"VER"} ,
		{"word":"pivota","word_nosc":"pivota","lemma":"pivoter","pos":"VER"} ,
		{"word":"pivotai","word_nosc":"pivotai","lemma":"pivoter","pos":"VER"} ,
		{"word":"pivotaient","word_nosc":"pivotaient","lemma":"pivoter","pos":"VER"} ,
		{"word":"pivotait","word_nosc":"pivotait","lemma":"pivoter","pos":"VER"} ,
		{"word":"pivotant","word_nosc":"pivotant","lemma":"pivoter","pos":"VER"} ,
		{"word":"pivote","word_nosc":"pivote","lemma":"pivoter","pos":"VER"} ,
		{"word":"pivotent","word_nosc":"pivotent","lemma":"pivoter","pos":"VER"} ,
		{"word":"pivoter","word_nosc":"pivoter","lemma":"pivoter","pos":"VER"} ,
		{"word":"pivotes","word_nosc":"pivotes","lemma":"pivoter","pos":"VER"} ,
		{"word":"pivotez","word_nosc":"pivotez","lemma":"pivoter","pos":"VER"} ,
		{"word":"pivotèrent","word_nosc":"pivoterent","lemma":"pivoter","pos":"VER"} ,
		{"word":"pivoté","word_nosc":"pivote","lemma":"pivoter","pos":"VER"} ,
		{"word":"pixellisés","word_nosc":"pixellises","lemma":"pixelliser","pos":"VER"} ,
		{"word":"piège","word_nosc":"piege","lemma":"piéger","pos":"VER"} ,
		{"word":"piègent","word_nosc":"piegent","lemma":"piéger","pos":"VER"} ,
		{"word":"pièges","word_nosc":"pieges","lemma":"piéger","pos":"VER"} ,
		{"word":"piète","word_nosc":"piete","lemma":"piéter","pos":"VER"} ,
		{"word":"piégeais","word_nosc":"piegeais","lemma":"piéger","pos":"VER"} ,
		{"word":"piégeait","word_nosc":"piegeait","lemma":"piéger","pos":"VER"} ,
		{"word":"piégeant","word_nosc":"piegeant","lemma":"piéger","pos":"VER"} ,
		{"word":"piéger","word_nosc":"pieger","lemma":"piéger","pos":"VER"} ,
		{"word":"piégerai","word_nosc":"piegerai","lemma":"piéger","pos":"VER"} ,
		{"word":"piégerais","word_nosc":"piegerais","lemma":"piéger","pos":"VER"} ,
		{"word":"piégerait","word_nosc":"piegerait","lemma":"piéger","pos":"VER"} ,
		{"word":"piégerez","word_nosc":"piegerez","lemma":"piéger","pos":"VER"} ,
		{"word":"piégeront","word_nosc":"piegeront","lemma":"piéger","pos":"VER"} ,
		{"word":"piégez","word_nosc":"piegez","lemma":"piéger","pos":"VER"} ,
		{"word":"piégé","word_nosc":"piege","lemma":"piéger","pos":"VER"} ,
		{"word":"piégée","word_nosc":"piegee","lemma":"piéger","pos":"VER"} ,
		{"word":"piégées","word_nosc":"piegees","lemma":"piéger","pos":"VER"} ,
		{"word":"piégés","word_nosc":"pieges","lemma":"piéger","pos":"VER"} ,
		{"word":"piéta","word_nosc":"pieta","lemma":"piéter","pos":"VER"} ,
		{"word":"piétait","word_nosc":"pietait","lemma":"piéter","pos":"VER"} ,
		{"word":"piétant","word_nosc":"pietant","lemma":"piéter","pos":"VER"} ,
		{"word":"piéter","word_nosc":"pieter","lemma":"piéter","pos":"VER"} ,
		{"word":"piétina","word_nosc":"pietina","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinai","word_nosc":"pietinai","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinaient","word_nosc":"pietinaient","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinais","word_nosc":"pietinais","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinait","word_nosc":"pietinait","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinant","word_nosc":"pietinant","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétine","word_nosc":"pietine","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinent","word_nosc":"pietinent","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétiner","word_nosc":"pietiner","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinera","word_nosc":"pietinera","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinerai","word_nosc":"pietinerai","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinerais","word_nosc":"pietinerais","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinerait","word_nosc":"pietinerait","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétineront","word_nosc":"pietineront","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinez","word_nosc":"pietinez","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinons","word_nosc":"pietinons","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinèrent","word_nosc":"pietinerent","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétiné","word_nosc":"pietine","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinée","word_nosc":"pietinee","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinées","word_nosc":"pietinees","lemma":"piétiner","pos":"VER"} ,
		{"word":"piétinés","word_nosc":"pietines","lemma":"piétiner","pos":"VER"} ,
		{"word":"piété","word_nosc":"piete","lemma":"piéter","pos":"VER"} ,
		{"word":"placardait","word_nosc":"placardait","lemma":"placarder","pos":"VER"} ,
		{"word":"placarde","word_nosc":"placarde","lemma":"placarder","pos":"VER"} ,
		{"word":"placarder","word_nosc":"placarder","lemma":"placarder","pos":"VER"} ,
		{"word":"placardes","word_nosc":"placardes","lemma":"placarder","pos":"VER"} ,
		{"word":"placardé","word_nosc":"placarde","lemma":"placarder","pos":"VER"} ,
		{"word":"placardée","word_nosc":"placardee","lemma":"placarder","pos":"VER"} ,
		{"word":"placardées","word_nosc":"placardees","lemma":"placarder","pos":"VER"} ,
		{"word":"placardés","word_nosc":"placardes","lemma":"placarder","pos":"VER"} ,
		{"word":"place","word_nosc":"place","lemma":"placer","pos":"VER"} ,
		{"word":"placent","word_nosc":"placent","lemma":"placer","pos":"VER"} ,
		{"word":"placer","word_nosc":"placer","lemma":"placer","pos":"VER"} ,
		{"word":"placera","word_nosc":"placera","lemma":"placer","pos":"VER"} ,
		{"word":"placerai","word_nosc":"placerai","lemma":"placer","pos":"VER"} ,
		{"word":"placeraient","word_nosc":"placeraient","lemma":"placer","pos":"VER"} ,
		{"word":"placerais","word_nosc":"placerais","lemma":"placer","pos":"VER"} ,
		{"word":"placerait","word_nosc":"placerait","lemma":"placer","pos":"VER"} ,
		{"word":"placeras","word_nosc":"placeras","lemma":"placer","pos":"VER"} ,
		{"word":"placerez","word_nosc":"placerez","lemma":"placer","pos":"VER"} ,
		{"word":"placerons","word_nosc":"placerons","lemma":"placer","pos":"VER"} ,
		{"word":"placeront","word_nosc":"placeront","lemma":"placer","pos":"VER"} ,
		{"word":"places","word_nosc":"places","lemma":"placer","pos":"VER"} ,
		{"word":"placez","word_nosc":"placez","lemma":"placer","pos":"VER"} ,
		{"word":"placiez","word_nosc":"placiez","lemma":"placer","pos":"VER"} ,
		{"word":"placions","word_nosc":"placions","lemma":"placer","pos":"VER"} ,
		{"word":"placèrent","word_nosc":"placerent","lemma":"placer","pos":"VER"} ,
		{"word":"placé","word_nosc":"place","lemma":"placer","pos":"VER"} ,
		{"word":"placée","word_nosc":"placee","lemma":"placer","pos":"VER"} ,
		{"word":"placées","word_nosc":"placees","lemma":"placer","pos":"VER"} ,
		{"word":"placés","word_nosc":"places","lemma":"placer","pos":"VER"} ,
		{"word":"plafonnant","word_nosc":"plafonnant","lemma":"plafonner","pos":"VER"} ,
		{"word":"plafonne","word_nosc":"plafonne","lemma":"plafonner","pos":"VER"} ,
		{"word":"plafonner","word_nosc":"plafonner","lemma":"plafonner","pos":"VER"} ,
		{"word":"plafonnons","word_nosc":"plafonnons","lemma":"plafonner","pos":"VER"} ,
		{"word":"plafonné","word_nosc":"plafonne","lemma":"plafonner","pos":"VER"} ,
		{"word":"plafonnée","word_nosc":"plafonnee","lemma":"plafonner","pos":"VER"} ,
		{"word":"plagiant","word_nosc":"plagiant","lemma":"plagier","pos":"VER"} ,
		{"word":"plagie","word_nosc":"plagie","lemma":"plagier","pos":"VER"} ,
		{"word":"plagier","word_nosc":"plagier","lemma":"plagier","pos":"VER"} ,
		{"word":"plagies","word_nosc":"plagies","lemma":"plagier","pos":"VER"} ,
		{"word":"plagié","word_nosc":"plagie","lemma":"plagier","pos":"VER"} ,
		{"word":"plaida","word_nosc":"plaida","lemma":"plaider","pos":"VER"} ,
		{"word":"plaidai","word_nosc":"plaidai","lemma":"plaider","pos":"VER"} ,
		{"word":"plaidaient","word_nosc":"plaidaient","lemma":"plaider","pos":"VER"} ,
		{"word":"plaidais","word_nosc":"plaidais","lemma":"plaider","pos":"VER"} ,
		{"word":"plaidait","word_nosc":"plaidait","lemma":"plaider","pos":"VER"} ,
		{"word":"plaidant","word_nosc":"plaidant","lemma":"plaider","pos":"VER"} ,
		{"word":"plaide","word_nosc":"plaide","lemma":"plaider","pos":"VER"} ,
		{"word":"plaident","word_nosc":"plaident","lemma":"plaider","pos":"VER"} ,
		{"word":"plaider","word_nosc":"plaider","lemma":"plaider","pos":"VER"} ,
		{"word":"plaidera","word_nosc":"plaidera","lemma":"plaider","pos":"VER"} ,
		{"word":"plaiderai","word_nosc":"plaiderai","lemma":"plaider","pos":"VER"} ,
		{"word":"plaiderait","word_nosc":"plaiderait","lemma":"plaider","pos":"VER"} ,
		{"word":"plaideras","word_nosc":"plaideras","lemma":"plaider","pos":"VER"} ,
		{"word":"plaiderez","word_nosc":"plaiderez","lemma":"plaider","pos":"VER"} ,
		{"word":"plaiderons","word_nosc":"plaiderons","lemma":"plaider","pos":"VER"} ,
		{"word":"plaideront","word_nosc":"plaideront","lemma":"plaider","pos":"VER"} ,
		{"word":"plaides","word_nosc":"plaides","lemma":"plaider","pos":"VER"} ,
		{"word":"plaidez","word_nosc":"plaidez","lemma":"plaider","pos":"VER"} ,
		{"word":"plaidiez","word_nosc":"plaidiez","lemma":"plaider","pos":"VER"} ,
		{"word":"plaidions","word_nosc":"plaidions","lemma":"plaider","pos":"VER"} ,
		{"word":"plaidons","word_nosc":"plaidons","lemma":"plaider","pos":"VER"} ,
		{"word":"plaidèrent","word_nosc":"plaiderent","lemma":"plaider","pos":"VER"} ,
		{"word":"plaidé","word_nosc":"plaide","lemma":"plaider","pos":"VER"} ,
		{"word":"plaidée","word_nosc":"plaidee","lemma":"plaider","pos":"VER"} ,
		{"word":"plaignaient","word_nosc":"plaignaient","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaignais","word_nosc":"plaignais","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaignait","word_nosc":"plaignait","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaignant","word_nosc":"plaignant","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaigne","word_nosc":"plaigne","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaignent","word_nosc":"plaignent","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaignes","word_nosc":"plaignes","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaignez","word_nosc":"plaignez","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaigniez","word_nosc":"plaigniez","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaignions","word_nosc":"plaignions","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaignirent","word_nosc":"plaignirent","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaignis","word_nosc":"plaignis","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaignit","word_nosc":"plaignit","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaignons","word_nosc":"plaignons","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaignît","word_nosc":"plaignit","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaindra","word_nosc":"plaindra","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaindrai","word_nosc":"plaindrai","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaindraient","word_nosc":"plaindraient","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaindrais","word_nosc":"plaindrais","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaindrait","word_nosc":"plaindrait","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaindras","word_nosc":"plaindras","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaindre","word_nosc":"plaindre","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaindrez","word_nosc":"plaindrez","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaindriez","word_nosc":"plaindriez","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaindrions","word_nosc":"plaindrions","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaindront","word_nosc":"plaindront","lemma":"plaindre","pos":"VER"} ,
		{"word":"plains","word_nosc":"plains","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaint","word_nosc":"plaint","lemma":"plaindre","pos":"VER"} ,
		{"word":"plainte","word_nosc":"plainte","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaintes","word_nosc":"plaintes","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaints","word_nosc":"plaints","lemma":"plaindre","pos":"VER"} ,
		{"word":"plaira","word_nosc":"plaira","lemma":"plaire","pos":"VER"} ,
		{"word":"plairai","word_nosc":"plairai","lemma":"plaire","pos":"VER"} ,
		{"word":"plairaient","word_nosc":"plairaient","lemma":"plaire","pos":"VER"} ,
		{"word":"plairais","word_nosc":"plairais","lemma":"plaire","pos":"VER"} ,
		{"word":"plairait","word_nosc":"plairait","lemma":"plaire","pos":"VER"} ,
		{"word":"plairas","word_nosc":"plairas","lemma":"plaire","pos":"VER"} ,
		{"word":"plaire","word_nosc":"plaire","lemma":"plaire","pos":"VER"} ,
		{"word":"plairez","word_nosc":"plairez","lemma":"plaire","pos":"VER"} ,
		{"word":"plairiez","word_nosc":"plairiez","lemma":"plaire","pos":"VER"} ,
		{"word":"plairont","word_nosc":"plairont","lemma":"plaire","pos":"VER"} ,
		{"word":"plais","word_nosc":"plais","lemma":"plaire","pos":"VER"} ,
		{"word":"plaisaient","word_nosc":"plaisaient","lemma":"plaire","pos":"VER"} ,
		{"word":"plaisais","word_nosc":"plaisais","lemma":"plaire","pos":"VER"} ,
		{"word":"plaisait","word_nosc":"plaisait","lemma":"plaire","pos":"VER"} ,
		{"word":"plaisant","word_nosc":"plaisant","lemma":"plaire","pos":"VER"} ,
		{"word":"plaisanta","word_nosc":"plaisanta","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisantai","word_nosc":"plaisantai","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisantaient","word_nosc":"plaisantaient","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisantais","word_nosc":"plaisantais","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisantait","word_nosc":"plaisantait","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisantant","word_nosc":"plaisantant","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisante","word_nosc":"plaisante","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisantent","word_nosc":"plaisantent","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisanter","word_nosc":"plaisanter","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisantera","word_nosc":"plaisantera","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisanterai","word_nosc":"plaisanterai","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisanteraient","word_nosc":"plaisanteraient","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisanterais","word_nosc":"plaisanterais","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisanteras","word_nosc":"plaisanteras","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisanterons","word_nosc":"plaisanterons","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisantes","word_nosc":"plaisantes","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisantez","word_nosc":"plaisantez","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisantiez","word_nosc":"plaisantiez","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisantions","word_nosc":"plaisantions","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisantons","word_nosc":"plaisantons","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisantèrent","word_nosc":"plaisanterent","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaisanté","word_nosc":"plaisante","lemma":"plaisanter","pos":"VER"} ,
		{"word":"plaise","word_nosc":"plaise","lemma":"plaire","pos":"VER"} ,
		{"word":"plaisent","word_nosc":"plaisent","lemma":"plaire","pos":"VER"} ,
		{"word":"plaises","word_nosc":"plaises","lemma":"plaire","pos":"VER"} ,
		{"word":"plaisez","word_nosc":"plaisez","lemma":"plaire","pos":"VER"} ,
		{"word":"plaisiez","word_nosc":"plaisiez","lemma":"plaire","pos":"VER"} ,
		{"word":"plaisions","word_nosc":"plaisions","lemma":"plaire","pos":"VER"} ,
		{"word":"plaisons","word_nosc":"plaisons","lemma":"plaire","pos":"VER"} ,
		{"word":"plana","word_nosc":"plana","lemma":"planer","pos":"VER"} ,
		{"word":"planaient","word_nosc":"planaient","lemma":"planer","pos":"VER"} ,
		{"word":"planais","word_nosc":"planais","lemma":"planer","pos":"VER"} ,
		{"word":"planait","word_nosc":"planait","lemma":"planer","pos":"VER"} ,
		{"word":"planant","word_nosc":"planant","lemma":"planer","pos":"VER"} ,
		{"word":"planas","word_nosc":"planas","lemma":"planer","pos":"VER"} ,
		{"word":"planchais","word_nosc":"planchais","lemma":"plancher","pos":"VER"} ,
		{"word":"planche","word_nosc":"planche","lemma":"plancher","pos":"VER"} ,
		{"word":"planchent","word_nosc":"planchent","lemma":"plancher","pos":"VER"} ,
		{"word":"plancher","word_nosc":"plancher","lemma":"plancher","pos":"VER"} ,
		{"word":"planches","word_nosc":"planches","lemma":"plancher","pos":"VER"} ,
		{"word":"planché","word_nosc":"planche","lemma":"plancher","pos":"VER"} ,
		{"word":"plane","word_nosc":"plane","lemma":"planer","pos":"VER"} ,
		{"word":"planent","word_nosc":"planent","lemma":"planer","pos":"VER"} ,
		{"word":"planer","word_nosc":"planer","lemma":"planer","pos":"VER"} ,
		{"word":"planera","word_nosc":"planera","lemma":"planer","pos":"VER"} ,
		{"word":"planerait","word_nosc":"planerait","lemma":"planer","pos":"VER"} ,
		{"word":"planerions","word_nosc":"planerions","lemma":"planer","pos":"VER"} ,
		{"word":"planeront","word_nosc":"planeront","lemma":"planer","pos":"VER"} ,
		{"word":"planes","word_nosc":"planes","lemma":"planer","pos":"VER"} ,
		{"word":"planez","word_nosc":"planez","lemma":"planer","pos":"VER"} ,
		{"word":"planiez","word_nosc":"planiez","lemma":"planer","pos":"VER"} ,
		{"word":"planifiaient","word_nosc":"planifiaient","lemma":"planifier","pos":"VER"} ,
		{"word":"planifiait","word_nosc":"planifiait","lemma":"planifier","pos":"VER"} ,
		{"word":"planifiant","word_nosc":"planifiant","lemma":"planifier","pos":"VER"} ,
		{"word":"planifie","word_nosc":"planifie","lemma":"planifier","pos":"VER"} ,
		{"word":"planifient","word_nosc":"planifient","lemma":"planifier","pos":"VER"} ,
		{"word":"planifier","word_nosc":"planifier","lemma":"planifier","pos":"VER"} ,
		{"word":"planifierais","word_nosc":"planifierais","lemma":"planifier","pos":"VER"} ,
		{"word":"planifiez","word_nosc":"planifiez","lemma":"planifier","pos":"VER"} ,
		{"word":"planifions","word_nosc":"planifions","lemma":"planifier","pos":"VER"} ,
		{"word":"planifié","word_nosc":"planifie","lemma":"planifier","pos":"VER"} ,
		{"word":"planifiée","word_nosc":"planifiee","lemma":"planifier","pos":"VER"} ,
		{"word":"planifiés","word_nosc":"planifies","lemma":"planifier","pos":"VER"} ,
		{"word":"planqua","word_nosc":"planqua","lemma":"planquer","pos":"VER"} ,
		{"word":"planquaient","word_nosc":"planquaient","lemma":"planquer","pos":"VER"} ,
		{"word":"planquais","word_nosc":"planquais","lemma":"planquer","pos":"VER"} ,
		{"word":"planquait","word_nosc":"planquait","lemma":"planquer","pos":"VER"} ,
		{"word":"planquant","word_nosc":"planquant","lemma":"planquer","pos":"VER"} ,
		{"word":"planque","word_nosc":"planque","lemma":"planquer","pos":"VER"} ,
		{"word":"planquent","word_nosc":"planquent","lemma":"planquer","pos":"VER"} ,
		{"word":"planquer","word_nosc":"planquer","lemma":"planquer","pos":"VER"} ,
		{"word":"planquera","word_nosc":"planquera","lemma":"planquer","pos":"VER"} ,
		{"word":"planquerai","word_nosc":"planquerai","lemma":"planquer","pos":"VER"} ,
		{"word":"planquerais","word_nosc":"planquerais","lemma":"planquer","pos":"VER"} ,
		{"word":"planquerait","word_nosc":"planquerait","lemma":"planquer","pos":"VER"} ,
		{"word":"planqueras","word_nosc":"planqueras","lemma":"planquer","pos":"VER"} ,
		{"word":"planques","word_nosc":"planques","lemma":"planquer","pos":"VER"} ,
		{"word":"planquez","word_nosc":"planquez","lemma":"planquer","pos":"VER"} ,
		{"word":"planquiez","word_nosc":"planquiez","lemma":"planquer","pos":"VER"} ,
		{"word":"planquions","word_nosc":"planquions","lemma":"planquer","pos":"VER"} ,
		{"word":"planquons","word_nosc":"planquons","lemma":"planquer","pos":"VER"} ,
		{"word":"planquèrent","word_nosc":"planquerent","lemma":"planquer","pos":"VER"} ,
		{"word":"planqué","word_nosc":"planque","lemma":"planquer","pos":"VER"} ,
		{"word":"planquée","word_nosc":"planquee","lemma":"planquer","pos":"VER"} ,
		{"word":"planquées","word_nosc":"planquees","lemma":"planquer","pos":"VER"} ,
		{"word":"planqués","word_nosc":"planques","lemma":"planquer","pos":"VER"} ,
		{"word":"planta","word_nosc":"planta","lemma":"planter","pos":"VER"} ,
		{"word":"plantai","word_nosc":"plantai","lemma":"planter","pos":"VER"} ,
		{"word":"plantaient","word_nosc":"plantaient","lemma":"planter","pos":"VER"} ,
		{"word":"plantais","word_nosc":"plantais","lemma":"planter","pos":"VER"} ,
		{"word":"plantait","word_nosc":"plantait","lemma":"planter","pos":"VER"} ,
		{"word":"plantant","word_nosc":"plantant","lemma":"planter","pos":"VER"} ,
		{"word":"plante","word_nosc":"plante","lemma":"planter","pos":"VER"} ,
		{"word":"plantent","word_nosc":"plantent","lemma":"planter","pos":"VER"} ,
		{"word":"planter","word_nosc":"planter","lemma":"planter","pos":"VER"} ,
		{"word":"plantera","word_nosc":"plantera","lemma":"planter","pos":"VER"} ,
		{"word":"planterai","word_nosc":"planterai","lemma":"planter","pos":"VER"} ,
		{"word":"planterais","word_nosc":"planterais","lemma":"planter","pos":"VER"} ,
		{"word":"planterait","word_nosc":"planterait","lemma":"planter","pos":"VER"} ,
		{"word":"planteras","word_nosc":"planteras","lemma":"planter","pos":"VER"} ,
		{"word":"planterez","word_nosc":"planterez","lemma":"planter","pos":"VER"} ,
		{"word":"planterons","word_nosc":"planterons","lemma":"planter","pos":"VER"} ,
		{"word":"planteront","word_nosc":"planteront","lemma":"planter","pos":"VER"} ,
		{"word":"plantes","word_nosc":"plantes","lemma":"planter","pos":"VER"} ,
		{"word":"plantez","word_nosc":"plantez","lemma":"planter","pos":"VER"} ,
		{"word":"plantiez","word_nosc":"plantiez","lemma":"planter","pos":"VER"} ,
		{"word":"plantions","word_nosc":"plantions","lemma":"planter","pos":"VER"} ,
		{"word":"plantons","word_nosc":"plantons","lemma":"planter","pos":"VER"} ,
		{"word":"plantèrent","word_nosc":"planterent","lemma":"planter","pos":"VER"} ,
		{"word":"planté","word_nosc":"plante","lemma":"planter","pos":"VER"} ,
		{"word":"plantée","word_nosc":"plantee","lemma":"planter","pos":"VER"} ,
		{"word":"plantées","word_nosc":"plantees","lemma":"planter","pos":"VER"} ,
		{"word":"plantés","word_nosc":"plantes","lemma":"planter","pos":"VER"} ,
		{"word":"plané","word_nosc":"plane","lemma":"planer","pos":"VER"} ,
		{"word":"plaqua","word_nosc":"plaqua","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaquai","word_nosc":"plaquai","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaquaient","word_nosc":"plaquaient","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaquais","word_nosc":"plaquais","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaquait","word_nosc":"plaquait","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaquant","word_nosc":"plaquant","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaque","word_nosc":"plaque","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaquent","word_nosc":"plaquent","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaquer","word_nosc":"plaquer","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaquera","word_nosc":"plaquera","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaquerai","word_nosc":"plaquerai","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaquerais","word_nosc":"plaquerais","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaquerait","word_nosc":"plaquerait","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaqueras","word_nosc":"plaqueras","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaques","word_nosc":"plaques","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaquez","word_nosc":"plaquez","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaquèrent","word_nosc":"plaquerent","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaqué","word_nosc":"plaque","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaquée","word_nosc":"plaquee","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaquées","word_nosc":"plaquees","lemma":"plaquer","pos":"VER"} ,
		{"word":"plaqués","word_nosc":"plaques","lemma":"plaquer","pos":"VER"} ,
		{"word":"plastifier","word_nosc":"plastifier","lemma":"plastifier","pos":"VER"} ,
		{"word":"plastifié","word_nosc":"plastifie","lemma":"plastifier","pos":"VER"} ,
		{"word":"plastifiée","word_nosc":"plastifiee","lemma":"plastifier","pos":"VER"} ,
		{"word":"plastifiées","word_nosc":"plastifiees","lemma":"plastifier","pos":"VER"} ,
		{"word":"plastifiés","word_nosc":"plastifies","lemma":"plastifier","pos":"VER"} ,
		{"word":"plastiquais","word_nosc":"plastiquais","lemma":"plastiquer","pos":"VER"} ,
		{"word":"plastique","word_nosc":"plastique","lemma":"plastiquer","pos":"VER"} ,
		{"word":"plastiquer","word_nosc":"plastiquer","lemma":"plastiquer","pos":"VER"} ,
		{"word":"plastiqué","word_nosc":"plastique","lemma":"plastiquer","pos":"VER"} ,
		{"word":"plastronnaient","word_nosc":"plastronnaient","lemma":"plastronner","pos":"VER"} ,
		{"word":"plastronnait","word_nosc":"plastronnait","lemma":"plastronner","pos":"VER"} ,
		{"word":"plastronnant","word_nosc":"plastronnant","lemma":"plastronner","pos":"VER"} ,
		{"word":"plastronne","word_nosc":"plastronne","lemma":"plastronner","pos":"VER"} ,
		{"word":"plastronnent","word_nosc":"plastronnent","lemma":"plastronner","pos":"VER"} ,
		{"word":"plastronner","word_nosc":"plastronner","lemma":"plastronner","pos":"VER"} ,
		{"word":"plastronnes","word_nosc":"plastronnes","lemma":"plastronner","pos":"VER"} ,
		{"word":"platinée","word_nosc":"platinee","lemma":"platiner","pos":"VER"} ,
		{"word":"plaça","word_nosc":"placa","lemma":"placer","pos":"VER"} ,
		{"word":"plaçai","word_nosc":"placai","lemma":"placer","pos":"VER"} ,
		{"word":"plaçaient","word_nosc":"placaient","lemma":"placer","pos":"VER"} ,
		{"word":"plaçais","word_nosc":"placais","lemma":"placer","pos":"VER"} ,
		{"word":"plaçait","word_nosc":"placait","lemma":"placer","pos":"VER"} ,
		{"word":"plaçant","word_nosc":"placant","lemma":"placer","pos":"VER"} ,
		{"word":"plaçons","word_nosc":"placons","lemma":"placer","pos":"VER"} ,
		{"word":"plaçât","word_nosc":"placat","lemma":"placer","pos":"VER"} ,
		{"word":"plaît","word_nosc":"plait","lemma":"plaire","pos":"VER"} ,
		{"word":"pleura","word_nosc":"pleura","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurai","word_nosc":"pleurai","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleuraient","word_nosc":"pleuraient","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurais","word_nosc":"pleurais","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurait","word_nosc":"pleurait","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurant","word_nosc":"pleurant","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleure","word_nosc":"pleure","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurent","word_nosc":"pleurent","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurer","word_nosc":"pleurer","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurera","word_nosc":"pleurera","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurerai","word_nosc":"pleurerai","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleureraient","word_nosc":"pleureraient","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurerais","word_nosc":"pleurerais","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurerait","word_nosc":"pleurerait","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleureras","word_nosc":"pleureras","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurerez","word_nosc":"pleurerez","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurerons","word_nosc":"pleurerons","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleureront","word_nosc":"pleureront","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleures","word_nosc":"pleures","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurez","word_nosc":"pleurez","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleuriez","word_nosc":"pleuriez","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurions","word_nosc":"pleurions","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurnicha","word_nosc":"pleurnicha","lemma":"pleurnicher","pos":"VER"} ,
		{"word":"pleurnichais","word_nosc":"pleurnichais","lemma":"pleurnicher","pos":"VER"} ,
		{"word":"pleurnichait","word_nosc":"pleurnichait","lemma":"pleurnicher","pos":"VER"} ,
		{"word":"pleurnichant","word_nosc":"pleurnichant","lemma":"pleurnicher","pos":"VER"} ,
		{"word":"pleurniche","word_nosc":"pleurniche","lemma":"pleurnicher","pos":"VER"} ,
		{"word":"pleurnichent","word_nosc":"pleurnichent","lemma":"pleurnicher","pos":"VER"} ,
		{"word":"pleurnicher","word_nosc":"pleurnicher","lemma":"pleurnicher","pos":"VER"} ,
		{"word":"pleurnicherais","word_nosc":"pleurnicherais","lemma":"pleurnicher","pos":"VER"} ,
		{"word":"pleurnicherait","word_nosc":"pleurnicherait","lemma":"pleurnicher","pos":"VER"} ,
		{"word":"pleurnicheras","word_nosc":"pleurnicheras","lemma":"pleurnicher","pos":"VER"} ,
		{"word":"pleurniches","word_nosc":"pleurniches","lemma":"pleurnicher","pos":"VER"} ,
		{"word":"pleurnichez","word_nosc":"pleurnichez","lemma":"pleurnicher","pos":"VER"} ,
		{"word":"pleurniché","word_nosc":"pleurniche","lemma":"pleurnicher","pos":"VER"} ,
		{"word":"pleurons","word_nosc":"pleurons","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurât","word_nosc":"pleurat","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurèrent","word_nosc":"pleurerent","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleuré","word_nosc":"pleure","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurée","word_nosc":"pleuree","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurées","word_nosc":"pleurees","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleurés","word_nosc":"pleures","lemma":"pleurer","pos":"VER"} ,
		{"word":"pleut","word_nosc":"pleut","lemma":"pleuvoir","pos":"VER"} ,
		{"word":"pleuvaient","word_nosc":"pleuvaient","lemma":"pleuvoir","pos":"VER"} ,
		{"word":"pleuvait","word_nosc":"pleuvait","lemma":"pleuvoir","pos":"VER"} ,
		{"word":"pleuvant","word_nosc":"pleuvant","lemma":"pleuvoir","pos":"VER"} ,
		{"word":"pleuve","word_nosc":"pleuve","lemma":"pleuvoir","pos":"VER"} ,
		{"word":"pleuvent","word_nosc":"pleuvent","lemma":"pleuvoir","pos":"VER"} ,
		{"word":"pleuvinait","word_nosc":"pleuvinait","lemma":"pleuviner","pos":"VER"} ,
		{"word":"pleuvine","word_nosc":"pleuvine","lemma":"pleuviner","pos":"VER"} ,
		{"word":"pleuviner","word_nosc":"pleuviner","lemma":"pleuviner","pos":"VER"} ,
		{"word":"pleuvioter","word_nosc":"pleuvioter","lemma":"pleuvioter","pos":"VER"} ,
		{"word":"pleuvoir","word_nosc":"pleuvoir","lemma":"pleuvoir","pos":"VER"} ,
		{"word":"pleuvra","word_nosc":"pleuvra","lemma":"pleuvoir","pos":"VER"} ,
		{"word":"pleuvrait","word_nosc":"pleuvrait","lemma":"pleuvoir","pos":"VER"} ,
		{"word":"plia","word_nosc":"plia","lemma":"plier","pos":"VER"} ,
		{"word":"pliai","word_nosc":"pliai","lemma":"plier","pos":"VER"} ,
		{"word":"pliaient","word_nosc":"pliaient","lemma":"plier","pos":"VER"} ,
		{"word":"pliais","word_nosc":"pliais","lemma":"plier","pos":"VER"} ,
		{"word":"pliait","word_nosc":"pliait","lemma":"plier","pos":"VER"} ,
		{"word":"pliant","word_nosc":"pliant","lemma":"plier","pos":"VER"} ,
		{"word":"plie","word_nosc":"plie","lemma":"plier","pos":"VER"} ,
		{"word":"plient","word_nosc":"plient","lemma":"plier","pos":"VER"} ,
		{"word":"plier","word_nosc":"plier","lemma":"plier","pos":"VER"} ,
		{"word":"pliera","word_nosc":"pliera","lemma":"plier","pos":"VER"} ,
		{"word":"plierai","word_nosc":"plierai","lemma":"plier","pos":"VER"} ,
		{"word":"plieraient","word_nosc":"plieraient","lemma":"plier","pos":"VER"} ,
		{"word":"plierait","word_nosc":"plierait","lemma":"plier","pos":"VER"} ,
		{"word":"plieras","word_nosc":"plieras","lemma":"plier","pos":"VER"} ,
		{"word":"plierez","word_nosc":"plierez","lemma":"plier","pos":"VER"} ,
		{"word":"plierons","word_nosc":"plierons","lemma":"plier","pos":"VER"} ,
		{"word":"plies","word_nosc":"plies","lemma":"plier","pos":"VER"} ,
		{"word":"pliez","word_nosc":"pliez","lemma":"plier","pos":"VER"} ,
		{"word":"plions","word_nosc":"plions","lemma":"plier","pos":"VER"} ,
		{"word":"plissa","word_nosc":"plissa","lemma":"plisser","pos":"VER"} ,
		{"word":"plissai","word_nosc":"plissai","lemma":"plisser","pos":"VER"} ,
		{"word":"plissaient","word_nosc":"plissaient","lemma":"plisser","pos":"VER"} ,
		{"word":"plissais","word_nosc":"plissais","lemma":"plisser","pos":"VER"} ,
		{"word":"plissait","word_nosc":"plissait","lemma":"plisser","pos":"VER"} ,
		{"word":"plissant","word_nosc":"plissant","lemma":"plisser","pos":"VER"} ,
		{"word":"plisse","word_nosc":"plisse","lemma":"plisser","pos":"VER"} ,
		{"word":"plissent","word_nosc":"plissent","lemma":"plisser","pos":"VER"} ,
		{"word":"plisser","word_nosc":"plisser","lemma":"plisser","pos":"VER"} ,
		{"word":"plisseront","word_nosc":"plisseront","lemma":"plisser","pos":"VER"} ,
		{"word":"plissèrent","word_nosc":"plisserent","lemma":"plisser","pos":"VER"} ,
		{"word":"plissé","word_nosc":"plisse","lemma":"plisser","pos":"VER"} ,
		{"word":"plissée","word_nosc":"plissee","lemma":"plisser","pos":"VER"} ,
		{"word":"plissées","word_nosc":"plissees","lemma":"plisser","pos":"VER"} ,
		{"word":"plissés","word_nosc":"plisses","lemma":"plisser","pos":"VER"} ,
		{"word":"pliât","word_nosc":"pliat","lemma":"plier","pos":"VER"} ,
		{"word":"plièrent","word_nosc":"plierent","lemma":"plier","pos":"VER"} ,
		{"word":"plié","word_nosc":"plie","lemma":"plier","pos":"VER"} ,
		{"word":"pliée","word_nosc":"pliee","lemma":"plier","pos":"VER"} ,
		{"word":"pliées","word_nosc":"pliees","lemma":"plier","pos":"VER"} ,
		{"word":"pliés","word_nosc":"plies","lemma":"plier","pos":"VER"} ,
		{"word":"ploie","word_nosc":"ploie","lemma":"ployer","pos":"VER"} ,
		{"word":"ploient","word_nosc":"ploient","lemma":"ployer","pos":"VER"} ,
		{"word":"plombaient","word_nosc":"plombaient","lemma":"plomber","pos":"VER"} ,
		{"word":"plombait","word_nosc":"plombait","lemma":"plomber","pos":"VER"} ,
		{"word":"plombant","word_nosc":"plombant","lemma":"plomber","pos":"VER"} ,
		{"word":"plombe","word_nosc":"plombe","lemma":"plomber","pos":"VER"} ,
		{"word":"plombent","word_nosc":"plombent","lemma":"plomber","pos":"VER"} ,
		{"word":"plomber","word_nosc":"plomber","lemma":"plomber","pos":"VER"} ,
		{"word":"plombes","word_nosc":"plombes","lemma":"plomber","pos":"VER"} ,
		{"word":"plombez","word_nosc":"plombez","lemma":"plomber","pos":"VER"} ,
		{"word":"plombé","word_nosc":"plombe","lemma":"plomber","pos":"VER"} ,
		{"word":"plombée","word_nosc":"plombee","lemma":"plomber","pos":"VER"} ,
		{"word":"plombées","word_nosc":"plombees","lemma":"plomber","pos":"VER"} ,
		{"word":"plonge","word_nosc":"plonge","lemma":"plonger","pos":"VER"} ,
		{"word":"plongea","word_nosc":"plongea","lemma":"plonger","pos":"VER"} ,
		{"word":"plongeai","word_nosc":"plongeai","lemma":"plonger","pos":"VER"} ,
		{"word":"plongeaient","word_nosc":"plongeaient","lemma":"plonger","pos":"VER"} ,
		{"word":"plongeais","word_nosc":"plongeais","lemma":"plonger","pos":"VER"} ,
		{"word":"plongeait","word_nosc":"plongeait","lemma":"plonger","pos":"VER"} ,
		{"word":"plongeant","word_nosc":"plongeant","lemma":"plonger","pos":"VER"} ,
		{"word":"plongent","word_nosc":"plongent","lemma":"plonger","pos":"VER"} ,
		{"word":"plongeons","word_nosc":"plongeons","lemma":"plonger","pos":"VER"} ,
		{"word":"plonger","word_nosc":"plonger","lemma":"plonger","pos":"VER"} ,
		{"word":"plongera","word_nosc":"plongera","lemma":"plonger","pos":"VER"} ,
		{"word":"plongerai","word_nosc":"plongerai","lemma":"plonger","pos":"VER"} ,
		{"word":"plongeraient","word_nosc":"plongeraient","lemma":"plonger","pos":"VER"} ,
		{"word":"plongerais","word_nosc":"plongerais","lemma":"plonger","pos":"VER"} ,
		{"word":"plongerait","word_nosc":"plongerait","lemma":"plonger","pos":"VER"} ,
		{"word":"plongeras","word_nosc":"plongeras","lemma":"plonger","pos":"VER"} ,
		{"word":"plongerez","word_nosc":"plongerez","lemma":"plonger","pos":"VER"} ,
		{"word":"plongeriez","word_nosc":"plongeriez","lemma":"plonger","pos":"VER"} ,
		{"word":"plongerons","word_nosc":"plongerons","lemma":"plonger","pos":"VER"} ,
		{"word":"plongeront","word_nosc":"plongeront","lemma":"plonger","pos":"VER"} ,
		{"word":"plonges","word_nosc":"plonges","lemma":"plonger","pos":"VER"} ,
		{"word":"plongez","word_nosc":"plongez","lemma":"plonger","pos":"VER"} ,
		{"word":"plongeâmes","word_nosc":"plongeames","lemma":"plonger","pos":"VER"} ,
		{"word":"plongeât","word_nosc":"plongeat","lemma":"plonger","pos":"VER"} ,
		{"word":"plongiez","word_nosc":"plongiez","lemma":"plonger","pos":"VER"} ,
		{"word":"plongions","word_nosc":"plongions","lemma":"plonger","pos":"VER"} ,
		{"word":"plongèrent","word_nosc":"plongerent","lemma":"plonger","pos":"VER"} ,
		{"word":"plongé","word_nosc":"plonge","lemma":"plonger","pos":"VER"} ,
		{"word":"plongée","word_nosc":"plongee","lemma":"plonger","pos":"VER"} ,
		{"word":"plongées","word_nosc":"plongees","lemma":"plonger","pos":"VER"} ,
		{"word":"plongés","word_nosc":"plonges","lemma":"plonger","pos":"VER"} ,
		{"word":"ploya","word_nosc":"ploya","lemma":"ployer","pos":"VER"} ,
		{"word":"ployaient","word_nosc":"ployaient","lemma":"ployer","pos":"VER"} ,
		{"word":"ployait","word_nosc":"ployait","lemma":"ployer","pos":"VER"} ,
		{"word":"ployant","word_nosc":"ployant","lemma":"ployer","pos":"VER"} ,
		{"word":"ployer","word_nosc":"ployer","lemma":"ployer","pos":"VER"} ,
		{"word":"ployé","word_nosc":"ploye","lemma":"ployer","pos":"VER"} ,
		{"word":"ployée","word_nosc":"ployee","lemma":"ployer","pos":"VER"} ,
		{"word":"ployées","word_nosc":"ployees","lemma":"ployer","pos":"VER"} ,
		{"word":"ployés","word_nosc":"ployes","lemma":"ployer","pos":"VER"} ,
		{"word":"plu","word_nosc":"plu","lemma":"pleuvoir","pos":"VER"} ,
		{"word":"pluche","word_nosc":"pluche","lemma":"plucher","pos":"VER"} ,
		{"word":"plucher","word_nosc":"plucher","lemma":"plucher","pos":"VER"} ,
		{"word":"pluma","word_nosc":"pluma","lemma":"plumer","pos":"VER"} ,
		{"word":"plumaient","word_nosc":"plumaient","lemma":"plumer","pos":"VER"} ,
		{"word":"plumait","word_nosc":"plumait","lemma":"plumer","pos":"VER"} ,
		{"word":"plumant","word_nosc":"plumant","lemma":"plumer","pos":"VER"} ,
		{"word":"plume","word_nosc":"plume","lemma":"plumer","pos":"VER"} ,
		{"word":"plument","word_nosc":"plument","lemma":"plumer","pos":"VER"} ,
		{"word":"plumer","word_nosc":"plumer","lemma":"plumer","pos":"VER"} ,
		{"word":"plumes","word_nosc":"plumes","lemma":"plumer","pos":"VER"} ,
		{"word":"plumez","word_nosc":"plumez","lemma":"plumer","pos":"VER"} ,
		{"word":"plumé","word_nosc":"plume","lemma":"plumer","pos":"VER"} ,
		{"word":"plumée","word_nosc":"plumee","lemma":"plumer","pos":"VER"} ,
		{"word":"plumées","word_nosc":"plumees","lemma":"plumer","pos":"VER"} ,
		{"word":"plumés","word_nosc":"plumes","lemma":"plumer","pos":"VER"} ,
		{"word":"plurent","word_nosc":"plurent","lemma":"plaire","pos":"VER"} ,
		{"word":"plus","word_nosc":"plus","lemma":"plaire","pos":"VER"} ,
		{"word":"plus","word_nosc":"plus","lemma":"plus","pos":"VER"} ,
		{"word":"plusse","word_nosc":"plusse","lemma":"plaire","pos":"VER"} ,
		{"word":"plut","word_nosc":"plut","lemma":"plaire","pos":"VER"} ,
		{"word":"plâtra","word_nosc":"platra","lemma":"plâtrer","pos":"VER"} ,
		{"word":"plâtrait","word_nosc":"platrait","lemma":"plâtrer","pos":"VER"} ,
		{"word":"plâtre","word_nosc":"platre","lemma":"plâtrer","pos":"VER"} ,
		{"word":"plâtrent","word_nosc":"platrent","lemma":"plâtrer","pos":"VER"} ,
		{"word":"plâtrer","word_nosc":"platrer","lemma":"plâtrer","pos":"VER"} ,
		{"word":"plâtré","word_nosc":"platre","lemma":"plâtrer","pos":"VER"} ,
		{"word":"plâtrée","word_nosc":"platree","lemma":"plâtrer","pos":"VER"} ,
		{"word":"plâtrées","word_nosc":"platrees","lemma":"plâtrer","pos":"VER"} ,
		{"word":"plâtrés","word_nosc":"platres","lemma":"plâtrer","pos":"VER"} ,
		{"word":"plébiscite","word_nosc":"plebiscite","lemma":"plébisciter","pos":"VER"} ,
		{"word":"plébisciter","word_nosc":"plebisciter","lemma":"plébisciter","pos":"VER"} ,
		{"word":"plébiscité","word_nosc":"plebiscite","lemma":"plébisciter","pos":"VER"} ,
		{"word":"plébiscités","word_nosc":"plebiscites","lemma":"plébisciter","pos":"VER"} ,
		{"word":"plût","word_nosc":"plut","lemma":"plaire","pos":"VER"} ,
		{"word":"poche","word_nosc":"poche","lemma":"pocher","pos":"VER"} ,
		{"word":"pocher","word_nosc":"pocher","lemma":"pocher","pos":"VER"} ,
		{"word":"poches","word_nosc":"poches","lemma":"pocher","pos":"VER"} ,
		{"word":"poché","word_nosc":"poche","lemma":"pocher","pos":"VER"} ,
		{"word":"pochée","word_nosc":"pochee","lemma":"pocher","pos":"VER"} ,
		{"word":"pochées","word_nosc":"pochees","lemma":"pocher","pos":"VER"} ,
		{"word":"pochés","word_nosc":"poches","lemma":"pocher","pos":"VER"} ,
		{"word":"poignaient","word_nosc":"poignaient","lemma":"poigner","pos":"VER"} ,
		{"word":"poignait","word_nosc":"poignait","lemma":"poigner","pos":"VER"} ,
		{"word":"poignant","word_nosc":"poignant","lemma":"poigner","pos":"VER"} ,
		{"word":"poignarda","word_nosc":"poignarda","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignardaient","word_nosc":"poignardaient","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignardais","word_nosc":"poignardais","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignardait","word_nosc":"poignardait","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignardant","word_nosc":"poignardant","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignarde","word_nosc":"poignarde","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignardent","word_nosc":"poignardent","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignarder","word_nosc":"poignarder","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignardera","word_nosc":"poignardera","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignarderai","word_nosc":"poignarderai","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignarderais","word_nosc":"poignarderais","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignarderait","word_nosc":"poignarderait","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignarderont","word_nosc":"poignarderont","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignardez","word_nosc":"poignardez","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignardé","word_nosc":"poignarde","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignardée","word_nosc":"poignardee","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignardées","word_nosc":"poignardees","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignardés","word_nosc":"poignardes","lemma":"poignarder","pos":"VER"} ,
		{"word":"poignent","word_nosc":"poignent","lemma":"poigner","pos":"VER"} ,
		{"word":"poigner","word_nosc":"poigner","lemma":"poigner","pos":"VER"} ,
		{"word":"poignerait","word_nosc":"poignerait","lemma":"poigner","pos":"VER"} ,
		{"word":"poignons","word_nosc":"poignons","lemma":"poigner","pos":"VER"} ,
		{"word":"poilaient","word_nosc":"poilaient","lemma":"poiler","pos":"VER"} ,
		{"word":"poilais","word_nosc":"poilais","lemma":"poiler","pos":"VER"} ,
		{"word":"poilait","word_nosc":"poilait","lemma":"poiler","pos":"VER"} ,
		{"word":"poilant","word_nosc":"poilant","lemma":"poiler","pos":"VER"} ,
		{"word":"poile","word_nosc":"poile","lemma":"poiler","pos":"VER"} ,
		{"word":"poiler","word_nosc":"poiler","lemma":"poiler","pos":"VER"} ,
		{"word":"poilé","word_nosc":"poile","lemma":"poiler","pos":"VER"} ,
		{"word":"poilés","word_nosc":"poiles","lemma":"poiler","pos":"VER"} ,
		{"word":"poindra","word_nosc":"poindra","lemma":"poindre","pos":"VER"} ,
		{"word":"poindre","word_nosc":"poindre","lemma":"poindre","pos":"VER"} ,
		{"word":"point","word_nosc":"point","lemma":"poindre","pos":"VER"} ,
		{"word":"pointa","word_nosc":"pointa","lemma":"pointer","pos":"VER"} ,
		{"word":"pointai","word_nosc":"pointai","lemma":"pointer","pos":"VER"} ,
		{"word":"pointaient","word_nosc":"pointaient","lemma":"pointer","pos":"VER"} ,
		{"word":"pointais","word_nosc":"pointais","lemma":"pointer","pos":"VER"} ,
		{"word":"pointait","word_nosc":"pointait","lemma":"pointer","pos":"VER"} ,
		{"word":"pointant","word_nosc":"pointant","lemma":"pointer","pos":"VER"} ,
		{"word":"pointe","word_nosc":"pointe","lemma":"pointer","pos":"VER"} ,
		{"word":"pointent","word_nosc":"pointent","lemma":"pointer","pos":"VER"} ,
		{"word":"pointer","word_nosc":"pointer","lemma":"pointer","pos":"VER"} ,
		{"word":"pointera","word_nosc":"pointera","lemma":"pointer","pos":"VER"} ,
		{"word":"pointerai","word_nosc":"pointerai","lemma":"pointer","pos":"VER"} ,
		{"word":"pointeraient","word_nosc":"pointeraient","lemma":"pointer","pos":"VER"} ,
		{"word":"pointerais","word_nosc":"pointerais","lemma":"pointer","pos":"VER"} ,
		{"word":"pointerait","word_nosc":"pointerait","lemma":"pointer","pos":"VER"} ,
		{"word":"pointeras","word_nosc":"pointeras","lemma":"pointer","pos":"VER"} ,
		{"word":"pointerez","word_nosc":"pointerez","lemma":"pointer","pos":"VER"} ,
		{"word":"pointeront","word_nosc":"pointeront","lemma":"pointer","pos":"VER"} ,
		{"word":"pointes","word_nosc":"pointes","lemma":"pointer","pos":"VER"} ,
		{"word":"pointez","word_nosc":"pointez","lemma":"pointer","pos":"VER"} ,
		{"word":"pointiez","word_nosc":"pointiez","lemma":"pointer","pos":"VER"} ,
		{"word":"pointillaient","word_nosc":"pointillaient","lemma":"pointiller","pos":"VER"} ,
		{"word":"pointillant","word_nosc":"pointillant","lemma":"pointiller","pos":"VER"} ,
		{"word":"pointille","word_nosc":"pointille","lemma":"pointiller","pos":"VER"} ,
		{"word":"pointillé","word_nosc":"pointille","lemma":"pointiller","pos":"VER"} ,
		{"word":"pointillée","word_nosc":"pointillee","lemma":"pointiller","pos":"VER"} ,
		{"word":"pointillées","word_nosc":"pointillees","lemma":"pointiller","pos":"VER"} ,
		{"word":"pointillés","word_nosc":"pointilles","lemma":"pointiller","pos":"VER"} ,
		{"word":"pointons","word_nosc":"pointons","lemma":"pointer","pos":"VER"} ,
		{"word":"pointâmes","word_nosc":"pointames","lemma":"pointer","pos":"VER"} ,
		{"word":"pointèrent","word_nosc":"pointerent","lemma":"pointer","pos":"VER"} ,
		{"word":"pointé","word_nosc":"pointe","lemma":"pointer","pos":"VER"} ,
		{"word":"pointée","word_nosc":"pointee","lemma":"pointer","pos":"VER"} ,
		{"word":"pointées","word_nosc":"pointees","lemma":"pointer","pos":"VER"} ,
		{"word":"pointés","word_nosc":"pointes","lemma":"pointer","pos":"VER"} ,
		{"word":"poinçonnait","word_nosc":"poinconnait","lemma":"poinçonner","pos":"VER"} ,
		{"word":"poinçonner","word_nosc":"poinconner","lemma":"poinçonner","pos":"VER"} ,
		{"word":"poinçonneraient","word_nosc":"poinconneraient","lemma":"poinçonner","pos":"VER"} ,
		{"word":"poinçonné","word_nosc":"poinconne","lemma":"poinçonner","pos":"VER"} ,
		{"word":"poinçonnée","word_nosc":"poinconnee","lemma":"poinçonner","pos":"VER"} ,
		{"word":"poinçonnés","word_nosc":"poinconnes","lemma":"poinçonner","pos":"VER"} ,
		{"word":"poireautai","word_nosc":"poireautai","lemma":"poireauter","pos":"VER"} ,
		{"word":"poireautaient","word_nosc":"poireautaient","lemma":"poireauter","pos":"VER"} ,
		{"word":"poireautais","word_nosc":"poireautais","lemma":"poireauter","pos":"VER"} ,
		{"word":"poireautait","word_nosc":"poireautait","lemma":"poireauter","pos":"VER"} ,
		{"word":"poireaute","word_nosc":"poireaute","lemma":"poireauter","pos":"VER"} ,
		{"word":"poireauter","word_nosc":"poireauter","lemma":"poireauter","pos":"VER"} ,
		{"word":"poireautes","word_nosc":"poireautes","lemma":"poireauter","pos":"VER"} ,
		{"word":"poireautions","word_nosc":"poireautions","lemma":"poireauter","pos":"VER"} ,
		{"word":"poireauté","word_nosc":"poireaute","lemma":"poireauter","pos":"VER"} ,
		{"word":"poirer","word_nosc":"poirer","lemma":"poirer","pos":"VER"} ,
		{"word":"poirota","word_nosc":"poirota","lemma":"poiroter","pos":"VER"} ,
		{"word":"poirotait","word_nosc":"poirotait","lemma":"poiroter","pos":"VER"} ,
		{"word":"poiroter","word_nosc":"poiroter","lemma":"poiroter","pos":"VER"} ,
		{"word":"poissaient","word_nosc":"poissaient","lemma":"poisser","pos":"VER"} ,
		{"word":"poissait","word_nosc":"poissait","lemma":"poisser","pos":"VER"} ,
		{"word":"poisse","word_nosc":"poisse","lemma":"poisser","pos":"VER"} ,
		{"word":"poissent","word_nosc":"poissent","lemma":"poisser","pos":"VER"} ,
		{"word":"poisser","word_nosc":"poisser","lemma":"poisser","pos":"VER"} ,
		{"word":"poissons","word_nosc":"poissons","lemma":"poisser","pos":"VER"} ,
		{"word":"poissé","word_nosc":"poisse","lemma":"poisser","pos":"VER"} ,
		{"word":"poissée","word_nosc":"poissee","lemma":"poisser","pos":"VER"} ,
		{"word":"poissées","word_nosc":"poissees","lemma":"poisser","pos":"VER"} ,
		{"word":"poissés","word_nosc":"poisses","lemma":"poisser","pos":"VER"} ,
		{"word":"poitrinant","word_nosc":"poitrinant","lemma":"poitriner","pos":"VER"} ,
		{"word":"poivra","word_nosc":"poivra","lemma":"poivrer","pos":"VER"} ,
		{"word":"poivrais","word_nosc":"poivrais","lemma":"poivrer","pos":"VER"} ,
		{"word":"poivrait","word_nosc":"poivrait","lemma":"poivrer","pos":"VER"} ,
		{"word":"poivre","word_nosc":"poivre","lemma":"poivrer","pos":"VER"} ,
		{"word":"poivrent","word_nosc":"poivrent","lemma":"poivrer","pos":"VER"} ,
		{"word":"poivrer","word_nosc":"poivrer","lemma":"poivrer","pos":"VER"} ,
		{"word":"poivres","word_nosc":"poivres","lemma":"poivrer","pos":"VER"} ,
		{"word":"poivrez","word_nosc":"poivrez","lemma":"poivrer","pos":"VER"} ,
		{"word":"poivré","word_nosc":"poivre","lemma":"poivrer","pos":"VER"} ,
		{"word":"poivrée","word_nosc":"poivree","lemma":"poivrer","pos":"VER"} ,
		{"word":"poivrées","word_nosc":"poivrees","lemma":"poivrer","pos":"VER"} ,
		{"word":"poivrés","word_nosc":"poivres","lemma":"poivrer","pos":"VER"} ,
		{"word":"polarisaient","word_nosc":"polarisaient","lemma":"polariser","pos":"VER"} ,
		{"word":"polarisait","word_nosc":"polarisait","lemma":"polariser","pos":"VER"} ,
		{"word":"polarisant","word_nosc":"polarisant","lemma":"polariser","pos":"VER"} ,
		{"word":"polarise","word_nosc":"polarise","lemma":"polariser","pos":"VER"} ,
		{"word":"polarisent","word_nosc":"polarisent","lemma":"polariser","pos":"VER"} ,
		{"word":"polariser","word_nosc":"polariser","lemma":"polariser","pos":"VER"} ,
		{"word":"polarisé","word_nosc":"polarise","lemma":"polariser","pos":"VER"} ,
		{"word":"polarisée","word_nosc":"polarisee","lemma":"polariser","pos":"VER"} ,
		{"word":"poli","word_nosc":"poli","lemma":"polir","pos":"VER"} ,
		{"word":"police","word_nosc":"police","lemma":"policer","pos":"VER"} ,
		{"word":"policer","word_nosc":"policer","lemma":"policer","pos":"VER"} ,
		{"word":"policé","word_nosc":"police","lemma":"policer","pos":"VER"} ,
		{"word":"policée","word_nosc":"policee","lemma":"policer","pos":"VER"} ,
		{"word":"policées","word_nosc":"policees","lemma":"policer","pos":"VER"} ,
		{"word":"policés","word_nosc":"polices","lemma":"policer","pos":"VER"} ,
		{"word":"polie","word_nosc":"polie","lemma":"polir","pos":"VER"} ,
		{"word":"polies","word_nosc":"polies","lemma":"polir","pos":"VER"} ,
		{"word":"polir","word_nosc":"polir","lemma":"polir","pos":"VER"} ,
		{"word":"poliras","word_nosc":"poliras","lemma":"polir","pos":"VER"} ,
		{"word":"polis","word_nosc":"polis","lemma":"polir","pos":"VER"} ,
		{"word":"polissaient","word_nosc":"polissaient","lemma":"polir","pos":"VER"} ,
		{"word":"polissais","word_nosc":"polissais","lemma":"polir","pos":"VER"} ,
		{"word":"polissait","word_nosc":"polissait","lemma":"polir","pos":"VER"} ,
		{"word":"polissant","word_nosc":"polissant","lemma":"polir","pos":"VER"} ,
		{"word":"polissent","word_nosc":"polissent","lemma":"polir","pos":"VER"} ,
		{"word":"polissonnait","word_nosc":"polissonnait","lemma":"polissonner","pos":"VER"} ,
		{"word":"polissonner","word_nosc":"polissonner","lemma":"polissonner","pos":"VER"} ,
		{"word":"polit","word_nosc":"polit","lemma":"polir","pos":"VER"} ,
		{"word":"politiser","word_nosc":"politiser","lemma":"politiser","pos":"VER"} ,
		{"word":"politisé","word_nosc":"politise","lemma":"politiser","pos":"VER"} ,
		{"word":"politisée","word_nosc":"politisee","lemma":"politiser","pos":"VER"} ,
		{"word":"politisés","word_nosc":"politises","lemma":"politiser","pos":"VER"} ,
		{"word":"polliniser","word_nosc":"polliniser","lemma":"polliniser","pos":"VER"} ,
		{"word":"pollinisé","word_nosc":"pollinise","lemma":"polliniser","pos":"VER"} ,
		{"word":"polluait","word_nosc":"polluait","lemma":"polluer","pos":"VER"} ,
		{"word":"polluant","word_nosc":"polluant","lemma":"polluer","pos":"VER"} ,
		{"word":"pollue","word_nosc":"pollue","lemma":"polluer","pos":"VER"} ,
		{"word":"polluent","word_nosc":"polluent","lemma":"polluer","pos":"VER"} ,
		{"word":"polluer","word_nosc":"polluer","lemma":"polluer","pos":"VER"} ,
		{"word":"pollueront","word_nosc":"pollueront","lemma":"polluer","pos":"VER"} ,
		{"word":"pollues","word_nosc":"pollues","lemma":"polluer","pos":"VER"} ,
		{"word":"polluez","word_nosc":"polluez","lemma":"polluer","pos":"VER"} ,
		{"word":"pollué","word_nosc":"pollue","lemma":"polluer","pos":"VER"} ,
		{"word":"polluée","word_nosc":"polluee","lemma":"polluer","pos":"VER"} ,
		{"word":"polluées","word_nosc":"polluees","lemma":"polluer","pos":"VER"} ,
		{"word":"pollués","word_nosc":"pollues","lemma":"polluer","pos":"VER"} ,
		{"word":"polycopiait","word_nosc":"polycopiait","lemma":"polycopier","pos":"VER"} ,
		{"word":"polycopier","word_nosc":"polycopier","lemma":"polycopier","pos":"VER"} ,
		{"word":"polycopiée","word_nosc":"polycopiee","lemma":"polycopier","pos":"VER"} ,
		{"word":"polycopiés","word_nosc":"polycopies","lemma":"polycopier","pos":"VER"} ,
		{"word":"polémique","word_nosc":"polemique","lemma":"polémiquer","pos":"VER"} ,
		{"word":"polémiquer","word_nosc":"polemiquer","lemma":"polémiquer","pos":"VER"} ,
		{"word":"polémiques","word_nosc":"polemiques","lemma":"polémiquer","pos":"VER"} ,
		{"word":"pommade","word_nosc":"pommade","lemma":"pommader","pos":"VER"} ,
		{"word":"pommader","word_nosc":"pommader","lemma":"pommader","pos":"VER"} ,
		{"word":"pommadé","word_nosc":"pommade","lemma":"pommader","pos":"VER"} ,
		{"word":"pommadés","word_nosc":"pommades","lemma":"pommader","pos":"VER"} ,
		{"word":"pommaient","word_nosc":"pommaient","lemma":"pommer","pos":"VER"} ,
		{"word":"pomme","word_nosc":"pomme","lemma":"pommer","pos":"VER"} ,
		{"word":"pommeler","word_nosc":"pommeler","lemma":"pommeler","pos":"VER"} ,
		{"word":"pommelé","word_nosc":"pommele","lemma":"pommeler","pos":"VER"} ,
		{"word":"pommelées","word_nosc":"pommelees","lemma":"pommeler","pos":"VER"} ,
		{"word":"pommer","word_nosc":"pommer","lemma":"pommer","pos":"VER"} ,
		{"word":"pommes","word_nosc":"pommes","lemma":"pommer","pos":"VER"} ,
		{"word":"pommé","word_nosc":"pomme","lemma":"pommer","pos":"VER"} ,
		{"word":"pommée","word_nosc":"pommee","lemma":"pommer","pos":"VER"} ,
		{"word":"pommées","word_nosc":"pommees","lemma":"pommer","pos":"VER"} ,
		{"word":"pompai","word_nosc":"pompai","lemma":"pomper","pos":"VER"} ,
		{"word":"pompaient","word_nosc":"pompaient","lemma":"pomper","pos":"VER"} ,
		{"word":"pompais","word_nosc":"pompais","lemma":"pomper","pos":"VER"} ,
		{"word":"pompait","word_nosc":"pompait","lemma":"pomper","pos":"VER"} ,
		{"word":"pompant","word_nosc":"pompant","lemma":"pomper","pos":"VER"} ,
		{"word":"pompe","word_nosc":"pompe","lemma":"pomper","pos":"VER"} ,
		{"word":"pompent","word_nosc":"pompent","lemma":"pomper","pos":"VER"} ,
		{"word":"pomper","word_nosc":"pomper","lemma":"pomper","pos":"VER"} ,
		{"word":"pompera","word_nosc":"pompera","lemma":"pomper","pos":"VER"} ,
		{"word":"pomperais","word_nosc":"pomperais","lemma":"pomper","pos":"VER"} ,
		{"word":"pomperait","word_nosc":"pomperait","lemma":"pomper","pos":"VER"} ,
		{"word":"pompes","word_nosc":"pompes","lemma":"pomper","pos":"VER"} ,
		{"word":"pompez","word_nosc":"pompez","lemma":"pomper","pos":"VER"} ,
		{"word":"pompions","word_nosc":"pompions","lemma":"pomper","pos":"VER"} ,
		{"word":"pomponna","word_nosc":"pomponna","lemma":"pomponner","pos":"VER"} ,
		{"word":"pomponnaient","word_nosc":"pomponnaient","lemma":"pomponner","pos":"VER"} ,
		{"word":"pomponnait","word_nosc":"pomponnait","lemma":"pomponner","pos":"VER"} ,
		{"word":"pomponnant","word_nosc":"pomponnant","lemma":"pomponner","pos":"VER"} ,
		{"word":"pomponne","word_nosc":"pomponne","lemma":"pomponner","pos":"VER"} ,
		{"word":"pomponnent","word_nosc":"pomponnent","lemma":"pomponner","pos":"VER"} ,
		{"word":"pomponner","word_nosc":"pomponner","lemma":"pomponner","pos":"VER"} ,
		{"word":"pomponniez","word_nosc":"pomponniez","lemma":"pomponner","pos":"VER"} ,
		{"word":"pomponné","word_nosc":"pomponne","lemma":"pomponner","pos":"VER"} ,
		{"word":"pomponnée","word_nosc":"pomponnee","lemma":"pomponner","pos":"VER"} ,
		{"word":"pomponnées","word_nosc":"pomponnees","lemma":"pomponner","pos":"VER"} ,
		{"word":"pomponnés","word_nosc":"pomponnes","lemma":"pomponner","pos":"VER"} ,
		{"word":"pompons","word_nosc":"pompons","lemma":"pomper","pos":"VER"} ,
		{"word":"pompé","word_nosc":"pompe","lemma":"pomper","pos":"VER"} ,
		{"word":"pompée","word_nosc":"pompee","lemma":"pomper","pos":"VER"} ,
		{"word":"pompés","word_nosc":"pompes","lemma":"pomper","pos":"VER"} ,
		{"word":"ponce","word_nosc":"ponce","lemma":"poncer","pos":"VER"} ,
		{"word":"poncer","word_nosc":"poncer","lemma":"poncer","pos":"VER"} ,
		{"word":"ponces","word_nosc":"ponces","lemma":"poncer","pos":"VER"} ,
		{"word":"ponctionna","word_nosc":"ponctionna","lemma":"ponctionner","pos":"VER"} ,
		{"word":"ponctionnait","word_nosc":"ponctionnait","lemma":"ponctionner","pos":"VER"} ,
		{"word":"ponctionne","word_nosc":"ponctionne","lemma":"ponctionner","pos":"VER"} ,
		{"word":"ponctionner","word_nosc":"ponctionner","lemma":"ponctionner","pos":"VER"} ,
		{"word":"ponctua","word_nosc":"ponctua","lemma":"ponctuer","pos":"VER"} ,
		{"word":"ponctuaient","word_nosc":"ponctuaient","lemma":"ponctuer","pos":"VER"} ,
		{"word":"ponctuait","word_nosc":"ponctuait","lemma":"ponctuer","pos":"VER"} ,
		{"word":"ponctuant","word_nosc":"ponctuant","lemma":"ponctuer","pos":"VER"} ,
		{"word":"ponctue","word_nosc":"ponctue","lemma":"ponctuer","pos":"VER"} ,
		{"word":"ponctuent","word_nosc":"ponctuent","lemma":"ponctuer","pos":"VER"} ,
		{"word":"ponctuer","word_nosc":"ponctuer","lemma":"ponctuer","pos":"VER"} ,
		{"word":"ponctuèrent","word_nosc":"ponctuerent","lemma":"ponctuer","pos":"VER"} ,
		{"word":"ponctué","word_nosc":"ponctue","lemma":"ponctuer","pos":"VER"} ,
		{"word":"ponctuée","word_nosc":"ponctuee","lemma":"ponctuer","pos":"VER"} ,
		{"word":"ponctuées","word_nosc":"ponctuees","lemma":"ponctuer","pos":"VER"} ,
		{"word":"ponctués","word_nosc":"ponctues","lemma":"ponctuer","pos":"VER"} ,
		{"word":"poncé","word_nosc":"ponce","lemma":"poncer","pos":"VER"} ,
		{"word":"poncée","word_nosc":"poncee","lemma":"poncer","pos":"VER"} ,
		{"word":"poncées","word_nosc":"poncees","lemma":"poncer","pos":"VER"} ,
		{"word":"poncés","word_nosc":"ponces","lemma":"poncer","pos":"VER"} ,
		{"word":"pond","word_nosc":"pond","lemma":"pondre","pos":"VER"} ,
		{"word":"pondaient","word_nosc":"pondaient","lemma":"pondre","pos":"VER"} ,
		{"word":"pondait","word_nosc":"pondait","lemma":"pondre","pos":"VER"} ,
		{"word":"pondant","word_nosc":"pondant","lemma":"pondre","pos":"VER"} ,
		{"word":"ponde","word_nosc":"ponde","lemma":"pondre","pos":"VER"} ,
		{"word":"pondent","word_nosc":"pondent","lemma":"pondre","pos":"VER"} ,
		{"word":"pondes","word_nosc":"pondes","lemma":"pondre","pos":"VER"} ,
		{"word":"pondez","word_nosc":"pondez","lemma":"pondre","pos":"VER"} ,
		{"word":"pondiez","word_nosc":"pondiez","lemma":"pondre","pos":"VER"} ,
		{"word":"pondra","word_nosc":"pondra","lemma":"pondre","pos":"VER"} ,
		{"word":"pondrai","word_nosc":"pondrai","lemma":"pondre","pos":"VER"} ,
		{"word":"pondraient","word_nosc":"pondraient","lemma":"pondre","pos":"VER"} ,
		{"word":"pondrais","word_nosc":"pondrais","lemma":"pondre","pos":"VER"} ,
		{"word":"pondras","word_nosc":"pondras","lemma":"pondre","pos":"VER"} ,
		{"word":"pondre","word_nosc":"pondre","lemma":"pondre","pos":"VER"} ,
		{"word":"pondront","word_nosc":"pondront","lemma":"pondre","pos":"VER"} ,
		{"word":"ponds","word_nosc":"ponds","lemma":"pondre","pos":"VER"} ,
		{"word":"pondu","word_nosc":"pondu","lemma":"pondre","pos":"VER"} ,
		{"word":"pondue","word_nosc":"pondue","lemma":"pondre","pos":"VER"} ,
		{"word":"pondus","word_nosc":"pondus","lemma":"pondre","pos":"VER"} ,
		{"word":"pondérer","word_nosc":"ponderer","lemma":"pondérer","pos":"VER"} ,
		{"word":"pondéré","word_nosc":"pondere","lemma":"pondérer","pos":"VER"} ,
		{"word":"pondérée","word_nosc":"ponderee","lemma":"pondérer","pos":"VER"} ,
		{"word":"ponte","word_nosc":"ponte","lemma":"ponter","pos":"VER"} ,
		{"word":"ponter","word_nosc":"ponter","lemma":"ponter","pos":"VER"} ,
		{"word":"pontifia","word_nosc":"pontifia","lemma":"pontifier","pos":"VER"} ,
		{"word":"pontifiait","word_nosc":"pontifiait","lemma":"pontifier","pos":"VER"} ,
		{"word":"pontifiant","word_nosc":"pontifiant","lemma":"pontifier","pos":"VER"} ,
		{"word":"pontifie","word_nosc":"pontifie","lemma":"pontifier","pos":"VER"} ,
		{"word":"pontifier","word_nosc":"pontifier","lemma":"pontifier","pos":"VER"} ,
		{"word":"pontifièrent","word_nosc":"pontifierent","lemma":"pontifier","pos":"VER"} ,
		{"word":"ponté","word_nosc":"ponte","lemma":"ponter","pos":"VER"} ,
		{"word":"ponçait","word_nosc":"poncait","lemma":"poncer","pos":"VER"} ,
		{"word":"ponçant","word_nosc":"poncant","lemma":"poncer","pos":"VER"} ,
		{"word":"populariser","word_nosc":"populariser","lemma":"populariser","pos":"VER"} ,
		{"word":"popularisé","word_nosc":"popularise","lemma":"populariser","pos":"VER"} ,
		{"word":"poquait","word_nosc":"poquait","lemma":"poquer","pos":"VER"} ,
		{"word":"porta","word_nosc":"porta","lemma":"porter","pos":"VER"} ,
		{"word":"portager","word_nosc":"portager","lemma":"portager","pos":"VER"} ,
		{"word":"portai","word_nosc":"portai","lemma":"porter","pos":"VER"} ,
		{"word":"portaient","word_nosc":"portaient","lemma":"porter","pos":"VER"} ,
		{"word":"portais","word_nosc":"portais","lemma":"porter","pos":"VER"} ,
		{"word":"portait","word_nosc":"portait","lemma":"porter","pos":"VER"} ,
		{"word":"portant","word_nosc":"portant","lemma":"porter","pos":"VER"} ,
		{"word":"portassent","word_nosc":"portassent","lemma":"porter","pos":"VER"} ,
		{"word":"porte","word_nosc":"porte","lemma":"porter","pos":"VER"} ,
		{"word":"portent","word_nosc":"portent","lemma":"porter","pos":"VER"} ,
		{"word":"porter","word_nosc":"porter","lemma":"porter","pos":"VER"} ,
		{"word":"portera","word_nosc":"portera","lemma":"porter","pos":"VER"} ,
		{"word":"porterai","word_nosc":"porterai","lemma":"porter","pos":"VER"} ,
		{"word":"porteraient","word_nosc":"porteraient","lemma":"porter","pos":"VER"} ,
		{"word":"porterais","word_nosc":"porterais","lemma":"porter","pos":"VER"} ,
		{"word":"porterait","word_nosc":"porterait","lemma":"porter","pos":"VER"} ,
		{"word":"porteras","word_nosc":"porteras","lemma":"porter","pos":"VER"} ,
		{"word":"porterez","word_nosc":"porterez","lemma":"porter","pos":"VER"} ,
		{"word":"porteriez","word_nosc":"porteriez","lemma":"porter","pos":"VER"} ,
		{"word":"porterons","word_nosc":"porterons","lemma":"porter","pos":"VER"} ,
		{"word":"porteront","word_nosc":"porteront","lemma":"porter","pos":"VER"} ,
		{"word":"portes","word_nosc":"portes","lemma":"porter","pos":"VER"} ,
		{"word":"portez","word_nosc":"portez","lemma":"porter","pos":"VER"} ,
		{"word":"portiez","word_nosc":"portiez","lemma":"porter","pos":"VER"} ,
		{"word":"portions","word_nosc":"portions","lemma":"porter","pos":"VER"} ,
		{"word":"portons","word_nosc":"portons","lemma":"porter","pos":"VER"} ,
		{"word":"portraire","word_nosc":"portraire","lemma":"portraire","pos":"VER"} ,
		{"word":"portrait","word_nosc":"portrait","lemma":"portraire","pos":"VER"} ,
		{"word":"portraits","word_nosc":"portraits","lemma":"portraire","pos":"VER"} ,
		{"word":"portraiturant","word_nosc":"portraiturant","lemma":"portraiturer","pos":"VER"} ,
		{"word":"portraiture","word_nosc":"portraiture","lemma":"portraiturer","pos":"VER"} ,
		{"word":"portraiturer","word_nosc":"portraiturer","lemma":"portraiturer","pos":"VER"} ,
		{"word":"portraituré","word_nosc":"portraiture","lemma":"portraiturer","pos":"VER"} ,
		{"word":"portraiturés","word_nosc":"portraitures","lemma":"portraiturer","pos":"VER"} ,
		{"word":"portâmes","word_nosc":"portames","lemma":"porter","pos":"VER"} ,
		{"word":"portât","word_nosc":"portat","lemma":"porter","pos":"VER"} ,
		{"word":"portèrent","word_nosc":"porterent","lemma":"porter","pos":"VER"} ,
		{"word":"porté","word_nosc":"porte","lemma":"porter","pos":"VER"} ,
		{"word":"portée","word_nosc":"portee","lemma":"porter","pos":"VER"} ,
		{"word":"portées","word_nosc":"portees","lemma":"porter","pos":"VER"} ,
		{"word":"portés","word_nosc":"portes","lemma":"porter","pos":"VER"} ,
		{"word":"posa","word_nosc":"posa","lemma":"poser","pos":"VER"} ,
		{"word":"posai","word_nosc":"posai","lemma":"poser","pos":"VER"} ,
		{"word":"posaient","word_nosc":"posaient","lemma":"poser","pos":"VER"} ,
		{"word":"posais","word_nosc":"posais","lemma":"poser","pos":"VER"} ,
		{"word":"posait","word_nosc":"posait","lemma":"poser","pos":"VER"} ,
		{"word":"posant","word_nosc":"posant","lemma":"poser","pos":"VER"} ,
		{"word":"posas","word_nosc":"posas","lemma":"poser","pos":"VER"} ,
		{"word":"pose","word_nosc":"pose","lemma":"poser","pos":"VER"} ,
		{"word":"posent","word_nosc":"posent","lemma":"poser","pos":"VER"} ,
		{"word":"poser","word_nosc":"poser","lemma":"poser","pos":"VER"} ,
		{"word":"posera","word_nosc":"posera","lemma":"poser","pos":"VER"} ,
		{"word":"poserai","word_nosc":"poserai","lemma":"poser","pos":"VER"} ,
		{"word":"poseraient","word_nosc":"poseraient","lemma":"poser","pos":"VER"} ,
		{"word":"poserais","word_nosc":"poserais","lemma":"poser","pos":"VER"} ,
		{"word":"poserait","word_nosc":"poserait","lemma":"poser","pos":"VER"} ,
		{"word":"poseras","word_nosc":"poseras","lemma":"poser","pos":"VER"} ,
		{"word":"poserez","word_nosc":"poserez","lemma":"poser","pos":"VER"} ,
		{"word":"poseriez","word_nosc":"poseriez","lemma":"poser","pos":"VER"} ,
		{"word":"poserions","word_nosc":"poserions","lemma":"poser","pos":"VER"} ,
		{"word":"poserons","word_nosc":"poserons","lemma":"poser","pos":"VER"} ,
		{"word":"poseront","word_nosc":"poseront","lemma":"poser","pos":"VER"} ,
		{"word":"poses","word_nosc":"poses","lemma":"poser","pos":"VER"} ,
		{"word":"posez","word_nosc":"posez","lemma":"poser","pos":"VER"} ,
		{"word":"posiez","word_nosc":"posiez","lemma":"poser","pos":"VER"} ,
		{"word":"posions","word_nosc":"posions","lemma":"poser","pos":"VER"} ,
		{"word":"positionne","word_nosc":"positionne","lemma":"positionner","pos":"VER"} ,
		{"word":"positionnent","word_nosc":"positionnent","lemma":"positionner","pos":"VER"} ,
		{"word":"positionner","word_nosc":"positionner","lemma":"positionner","pos":"VER"} ,
		{"word":"positionnera","word_nosc":"positionnera","lemma":"positionner","pos":"VER"} ,
		{"word":"positionné","word_nosc":"positionne","lemma":"positionner","pos":"VER"} ,
		{"word":"positionnée","word_nosc":"positionnee","lemma":"positionner","pos":"VER"} ,
		{"word":"positiver","word_nosc":"positiver","lemma":"positiver","pos":"VER"} ,
		{"word":"posons","word_nosc":"posons","lemma":"poser","pos":"VER"} ,
		{"word":"possède","word_nosc":"possede","lemma":"posséder","pos":"VER"} ,
		{"word":"possèdent","word_nosc":"possedent","lemma":"posséder","pos":"VER"} ,
		{"word":"possèdes","word_nosc":"possedes","lemma":"posséder","pos":"VER"} ,
		{"word":"posséda","word_nosc":"posseda","lemma":"posséder","pos":"VER"} ,
		{"word":"possédaient","word_nosc":"possedaient","lemma":"posséder","pos":"VER"} ,
		{"word":"possédais","word_nosc":"possedais","lemma":"posséder","pos":"VER"} ,
		{"word":"possédait","word_nosc":"possedait","lemma":"posséder","pos":"VER"} ,
		{"word":"possédant","word_nosc":"possedant","lemma":"posséder","pos":"VER"} ,
		{"word":"possédasse","word_nosc":"possedasse","lemma":"posséder","pos":"VER"} ,
		{"word":"posséder","word_nosc":"posseder","lemma":"posséder","pos":"VER"} ,
		{"word":"possédera","word_nosc":"possedera","lemma":"posséder","pos":"VER"} ,
		{"word":"posséderai","word_nosc":"possederai","lemma":"posséder","pos":"VER"} ,
		{"word":"posséderaient","word_nosc":"possederaient","lemma":"posséder","pos":"VER"} ,
		{"word":"posséderais","word_nosc":"possederais","lemma":"posséder","pos":"VER"} ,
		{"word":"posséderait","word_nosc":"possederait","lemma":"posséder","pos":"VER"} ,
		{"word":"posséderas","word_nosc":"possederas","lemma":"posséder","pos":"VER"} ,
		{"word":"posséderez","word_nosc":"possederez","lemma":"posséder","pos":"VER"} ,
		{"word":"posséderont","word_nosc":"possederont","lemma":"posséder","pos":"VER"} ,
		{"word":"possédez","word_nosc":"possedez","lemma":"posséder","pos":"VER"} ,
		{"word":"possédiez","word_nosc":"possediez","lemma":"posséder","pos":"VER"} ,
		{"word":"possédions","word_nosc":"possedions","lemma":"posséder","pos":"VER"} ,
		{"word":"possédons","word_nosc":"possedons","lemma":"posséder","pos":"VER"} ,
		{"word":"possédât","word_nosc":"possedat","lemma":"posséder","pos":"VER"} ,
		{"word":"possédé","word_nosc":"possede","lemma":"posséder","pos":"VER"} ,
		{"word":"possédée","word_nosc":"possedee","lemma":"posséder","pos":"VER"} ,
		{"word":"possédées","word_nosc":"possedees","lemma":"posséder","pos":"VER"} ,
		{"word":"possédés","word_nosc":"possedes","lemma":"posséder","pos":"VER"} ,
		{"word":"posta","word_nosc":"posta","lemma":"poster","pos":"VER"} ,
		{"word":"postai","word_nosc":"postai","lemma":"poster","pos":"VER"} ,
		{"word":"postaient","word_nosc":"postaient","lemma":"poster","pos":"VER"} ,
		{"word":"postais","word_nosc":"postais","lemma":"poster","pos":"VER"} ,
		{"word":"postait","word_nosc":"postait","lemma":"poster","pos":"VER"} ,
		{"word":"postant","word_nosc":"postant","lemma":"poster","pos":"VER"} ,
		{"word":"postdater","word_nosc":"postdater","lemma":"postdater","pos":"VER"} ,
		{"word":"postdaté","word_nosc":"postdate","lemma":"postdater","pos":"VER"} ,
		{"word":"poste","word_nosc":"poste","lemma":"poster","pos":"VER"} ,
		{"word":"postent","word_nosc":"postent","lemma":"poster","pos":"VER"} ,
		{"word":"poster","word_nosc":"poster","lemma":"poster","pos":"VER"} ,
		{"word":"postera","word_nosc":"postera","lemma":"poster","pos":"VER"} ,
		{"word":"posterai","word_nosc":"posterai","lemma":"poster","pos":"VER"} ,
		{"word":"posterait","word_nosc":"posterait","lemma":"poster","pos":"VER"} ,
		{"word":"posteras","word_nosc":"posteras","lemma":"poster","pos":"VER"} ,
		{"word":"posterons","word_nosc":"posterons","lemma":"poster","pos":"VER"} ,
		{"word":"postes","word_nosc":"postes","lemma":"poster","pos":"VER"} ,
		{"word":"postez","word_nosc":"postez","lemma":"poster","pos":"VER"} ,
		{"word":"postillonna","word_nosc":"postillonna","lemma":"postillonner","pos":"VER"} ,
		{"word":"postillonnait","word_nosc":"postillonnait","lemma":"postillonner","pos":"VER"} ,
		{"word":"postillonnant","word_nosc":"postillonnant","lemma":"postillonner","pos":"VER"} ,
		{"word":"postillonne","word_nosc":"postillonne","lemma":"postillonner","pos":"VER"} ,
		{"word":"postillonnent","word_nosc":"postillonnent","lemma":"postillonner","pos":"VER"} ,
		{"word":"postillonner","word_nosc":"postillonner","lemma":"postillonner","pos":"VER"} ,
		{"word":"postillonnes","word_nosc":"postillonnes","lemma":"postillonner","pos":"VER"} ,
		{"word":"postillonnez","word_nosc":"postillonnez","lemma":"postillonner","pos":"VER"} ,
		{"word":"postillonné","word_nosc":"postillonne","lemma":"postillonner","pos":"VER"} ,
		{"word":"postulai","word_nosc":"postulai","lemma":"postuler","pos":"VER"} ,
		{"word":"postulaient","word_nosc":"postulaient","lemma":"postuler","pos":"VER"} ,
		{"word":"postulait","word_nosc":"postulait","lemma":"postuler","pos":"VER"} ,
		{"word":"postulant","word_nosc":"postulant","lemma":"postuler","pos":"VER"} ,
		{"word":"postule","word_nosc":"postule","lemma":"postuler","pos":"VER"} ,
		{"word":"postulent","word_nosc":"postulent","lemma":"postuler","pos":"VER"} ,
		{"word":"postuler","word_nosc":"postuler","lemma":"postuler","pos":"VER"} ,
		{"word":"postuleras","word_nosc":"postuleras","lemma":"postuler","pos":"VER"} ,
		{"word":"postules","word_nosc":"postules","lemma":"postuler","pos":"VER"} ,
		{"word":"postulé","word_nosc":"postule","lemma":"postuler","pos":"VER"} ,
		{"word":"posté","word_nosc":"poste","lemma":"poster","pos":"VER"} ,
		{"word":"postée","word_nosc":"postee","lemma":"poster","pos":"VER"} ,
		{"word":"postées","word_nosc":"postees","lemma":"poster","pos":"VER"} ,
		{"word":"postés","word_nosc":"postes","lemma":"poster","pos":"VER"} ,
		{"word":"posâmes","word_nosc":"posames","lemma":"poser","pos":"VER"} ,
		{"word":"posât","word_nosc":"posat","lemma":"poser","pos":"VER"} ,
		{"word":"posèrent","word_nosc":"poserent","lemma":"poser","pos":"VER"} ,
		{"word":"posé","word_nosc":"pose","lemma":"poser","pos":"VER"} ,
		{"word":"posée","word_nosc":"posee","lemma":"poser","pos":"VER"} ,
		{"word":"posées","word_nosc":"posees","lemma":"poser","pos":"VER"} ,
		{"word":"posés","word_nosc":"poses","lemma":"poser","pos":"VER"} ,
		{"word":"potassa","word_nosc":"potassa","lemma":"potasser","pos":"VER"} ,
		{"word":"potassait","word_nosc":"potassait","lemma":"potasser","pos":"VER"} ,
		{"word":"potasse","word_nosc":"potasse","lemma":"potasser","pos":"VER"} ,
		{"word":"potassent","word_nosc":"potassent","lemma":"potasser","pos":"VER"} ,
		{"word":"potasser","word_nosc":"potasser","lemma":"potasser","pos":"VER"} ,
		{"word":"potasserai","word_nosc":"potasserai","lemma":"potasser","pos":"VER"} ,
		{"word":"potasses","word_nosc":"potasses","lemma":"potasser","pos":"VER"} ,
		{"word":"potassez","word_nosc":"potassez","lemma":"potasser","pos":"VER"} ,
		{"word":"potassé","word_nosc":"potasse","lemma":"potasser","pos":"VER"} ,
		{"word":"potentialiser","word_nosc":"potentialiser","lemma":"potentialiser","pos":"VER"} ,
		{"word":"poter","word_nosc":"poter","lemma":"poter","pos":"VER"} ,
		{"word":"potinais","word_nosc":"potinais","lemma":"potiner","pos":"VER"} ,
		{"word":"potine","word_nosc":"potine","lemma":"potiner","pos":"VER"} ,
		{"word":"potines","word_nosc":"potines","lemma":"potiner","pos":"VER"} ,
		{"word":"poudra","word_nosc":"poudra","lemma":"poudrer","pos":"VER"} ,
		{"word":"poudraient","word_nosc":"poudraient","lemma":"poudrer","pos":"VER"} ,
		{"word":"poudrait","word_nosc":"poudrait","lemma":"poudrer","pos":"VER"} ,
		{"word":"poudrant","word_nosc":"poudrant","lemma":"poudrer","pos":"VER"} ,
		{"word":"poudre","word_nosc":"poudre","lemma":"poudrer","pos":"VER"} ,
		{"word":"poudrent","word_nosc":"poudrent","lemma":"poudrer","pos":"VER"} ,
		{"word":"poudrer","word_nosc":"poudrer","lemma":"poudrer","pos":"VER"} ,
		{"word":"poudroie","word_nosc":"poudroie","lemma":"poudroyer","pos":"VER"} ,
		{"word":"poudroyaient","word_nosc":"poudroyaient","lemma":"poudroyer","pos":"VER"} ,
		{"word":"poudroyait","word_nosc":"poudroyait","lemma":"poudroyer","pos":"VER"} ,
		{"word":"poudré","word_nosc":"poudre","lemma":"poudrer","pos":"VER"} ,
		{"word":"poudrée","word_nosc":"poudree","lemma":"poudrer","pos":"VER"} ,
		{"word":"poudrées","word_nosc":"poudrees","lemma":"poudrer","pos":"VER"} ,
		{"word":"poudrés","word_nosc":"poudres","lemma":"poudrer","pos":"VER"} ,
		{"word":"pouffa","word_nosc":"pouffa","lemma":"pouffer","pos":"VER"} ,
		{"word":"pouffaient","word_nosc":"pouffaient","lemma":"pouffer","pos":"VER"} ,
		{"word":"pouffait","word_nosc":"pouffait","lemma":"pouffer","pos":"VER"} ,
		{"word":"pouffant","word_nosc":"pouffant","lemma":"pouffer","pos":"VER"} ,
		{"word":"pouffe","word_nosc":"pouffe","lemma":"pouffer","pos":"VER"} ,
		{"word":"pouffent","word_nosc":"pouffent","lemma":"pouffer","pos":"VER"} ,
		{"word":"pouffer","word_nosc":"pouffer","lemma":"pouffer","pos":"VER"} ,
		{"word":"poufferais","word_nosc":"poufferais","lemma":"pouffer","pos":"VER"} ,
		{"word":"poufferait","word_nosc":"poufferait","lemma":"pouffer","pos":"VER"} ,
		{"word":"pouffes","word_nosc":"pouffes","lemma":"pouffer","pos":"VER"} ,
		{"word":"pouffions","word_nosc":"pouffions","lemma":"pouffer","pos":"VER"} ,
		{"word":"pouffons","word_nosc":"pouffons","lemma":"pouffer","pos":"VER"} ,
		{"word":"pouffèrent","word_nosc":"poufferent","lemma":"pouffer","pos":"VER"} ,
		{"word":"pouffé","word_nosc":"pouffe","lemma":"pouffer","pos":"VER"} ,
		{"word":"poulottant","word_nosc":"poulottant","lemma":"poulotter","pos":"VER"} ,
		{"word":"poulotter","word_nosc":"poulotter","lemma":"poulotter","pos":"VER"} ,
		{"word":"pouponne","word_nosc":"pouponne","lemma":"pouponner","pos":"VER"} ,
		{"word":"pouponnent","word_nosc":"pouponnent","lemma":"pouponner","pos":"VER"} ,
		{"word":"pouponner","word_nosc":"pouponner","lemma":"pouponner","pos":"VER"} ,
		{"word":"pouponné","word_nosc":"pouponne","lemma":"pouponner","pos":"VER"} ,
		{"word":"pourchassa","word_nosc":"pourchassa","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchassaient","word_nosc":"pourchassaient","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchassais","word_nosc":"pourchassais","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchassait","word_nosc":"pourchassait","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchassant","word_nosc":"pourchassant","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchasse","word_nosc":"pourchasse","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchassent","word_nosc":"pourchassent","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchasser","word_nosc":"pourchasser","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchassera","word_nosc":"pourchassera","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchasserai","word_nosc":"pourchasserai","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchasseraient","word_nosc":"pourchasseraient","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchasserait","word_nosc":"pourchasserait","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchasserons","word_nosc":"pourchasserons","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchasseront","word_nosc":"pourchasseront","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchassez","word_nosc":"pourchassez","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchassons","word_nosc":"pourchassons","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchassèrent","word_nosc":"pourchasserent","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchassé","word_nosc":"pourchasse","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchassée","word_nosc":"pourchassee","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchassées","word_nosc":"pourchassees","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourchassés","word_nosc":"pourchasses","lemma":"pourchasser","pos":"VER"} ,
		{"word":"pourfendait","word_nosc":"pourfendait","lemma":"pourfendre","pos":"VER"} ,
		{"word":"pourfendant","word_nosc":"pourfendant","lemma":"pourfendre","pos":"VER"} ,
		{"word":"pourfende","word_nosc":"pourfende","lemma":"pourfendre","pos":"VER"} ,
		{"word":"pourfendions","word_nosc":"pourfendions","lemma":"pourfendre","pos":"VER"} ,
		{"word":"pourfendre","word_nosc":"pourfendre","lemma":"pourfendre","pos":"VER"} ,
		{"word":"pourfends","word_nosc":"pourfends","lemma":"pourfendre","pos":"VER"} ,
		{"word":"pourfendu","word_nosc":"pourfendu","lemma":"pourfendre","pos":"VER"} ,
		{"word":"pourlèche","word_nosc":"pourleche","lemma":"pourlécher","pos":"VER"} ,
		{"word":"pourlèchent","word_nosc":"pourlechent","lemma":"pourlécher","pos":"VER"} ,
		{"word":"pourléchaient","word_nosc":"pourlechaient","lemma":"pourlécher","pos":"VER"} ,
		{"word":"pourléchait","word_nosc":"pourlechait","lemma":"pourlécher","pos":"VER"} ,
		{"word":"pourléchant","word_nosc":"pourlechant","lemma":"pourlécher","pos":"VER"} ,
		{"word":"pourlécher","word_nosc":"pourlecher","lemma":"pourlécher","pos":"VER"} ,
		{"word":"pourléchée","word_nosc":"pourlechee","lemma":"pourlécher","pos":"VER"} ,
		{"word":"pourprée","word_nosc":"pourpree","lemma":"pourprer","pos":"VER"} ,
		{"word":"pourprées","word_nosc":"pourprees","lemma":"pourprer","pos":"VER"} ,
		{"word":"pourra","word_nosc":"pourra","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pourrai","word_nosc":"pourrai","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pourraient","word_nosc":"pourraient","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pourrais","word_nosc":"pourrais","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pourrait","word_nosc":"pourrait","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pourras","word_nosc":"pourras","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pourrez","word_nosc":"pourrez","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pourri","word_nosc":"pourri","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrie","word_nosc":"pourrie","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourries","word_nosc":"pourries","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourriez","word_nosc":"pourriez","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pourrions","word_nosc":"pourrions","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pourrir","word_nosc":"pourrir","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrira","word_nosc":"pourrira","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrirai","word_nosc":"pourrirai","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrirait","word_nosc":"pourrirait","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourriras","word_nosc":"pourriras","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrirent","word_nosc":"pourrirent","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrirez","word_nosc":"pourrirez","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourririez","word_nosc":"pourririez","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrirons","word_nosc":"pourrirons","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourriront","word_nosc":"pourriront","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourris","word_nosc":"pourris","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrissaient","word_nosc":"pourrissaient","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrissais","word_nosc":"pourrissais","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrissait","word_nosc":"pourrissait","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrissant","word_nosc":"pourrissant","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrisse","word_nosc":"pourrisse","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrissent","word_nosc":"pourrissent","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrissez","word_nosc":"pourrissez","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrissons","word_nosc":"pourrissons","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrit","word_nosc":"pourrit","lemma":"pourrir","pos":"VER"} ,
		{"word":"pourrons","word_nosc":"pourrons","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pourront","word_nosc":"pourront","lemma":"pouvoir","pos":"VER"} ,
		{"word":"poursuis","word_nosc":"poursuis","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuit","word_nosc":"poursuit","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivaient","word_nosc":"poursuivaient","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivais","word_nosc":"poursuivais","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivait","word_nosc":"poursuivait","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivant","word_nosc":"poursuivant","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuive","word_nosc":"poursuive","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivent","word_nosc":"poursuivent","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuives","word_nosc":"poursuives","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivez","word_nosc":"poursuivez","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivi","word_nosc":"poursuivi","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivie","word_nosc":"poursuivie","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivies","word_nosc":"poursuivies","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuiviez","word_nosc":"poursuiviez","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivions","word_nosc":"poursuivions","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivirent","word_nosc":"poursuivirent","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivis","word_nosc":"poursuivis","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivit","word_nosc":"poursuivit","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivons","word_nosc":"poursuivons","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivra","word_nosc":"poursuivra","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivrai","word_nosc":"poursuivrai","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivraient","word_nosc":"poursuivraient","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivrais","word_nosc":"poursuivrais","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivrait","word_nosc":"poursuivrait","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivras","word_nosc":"poursuivras","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivre","word_nosc":"poursuivre","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivrez","word_nosc":"poursuivrez","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivrions","word_nosc":"poursuivrions","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivrons","word_nosc":"poursuivrons","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivront","word_nosc":"poursuivront","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivîmes","word_nosc":"poursuivimes","lemma":"poursuivre","pos":"VER"} ,
		{"word":"poursuivît","word_nosc":"poursuivit","lemma":"poursuivre","pos":"VER"} ,
		{"word":"pourvoie","word_nosc":"pourvoie","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvoient","word_nosc":"pourvoient","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvoir","word_nosc":"pourvoir","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvoira","word_nosc":"pourvoira","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvoirai","word_nosc":"pourvoirai","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvoiraient","word_nosc":"pourvoiraient","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvoirait","word_nosc":"pourvoirait","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvoirons","word_nosc":"pourvoirons","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvoiront","word_nosc":"pourvoiront","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvois","word_nosc":"pourvois","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvoit","word_nosc":"pourvoit","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvoyaient","word_nosc":"pourvoyaient","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvoyait","word_nosc":"pourvoyait","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvoyant","word_nosc":"pourvoyant","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvoyons","word_nosc":"pourvoyons","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvu","word_nosc":"pourvu","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvue","word_nosc":"pourvue","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvues","word_nosc":"pourvues","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvus","word_nosc":"pourvus","lemma":"pourvoir","pos":"VER"} ,
		{"word":"pourvut","word_nosc":"pourvut","lemma":"pourvoir","pos":"VER"} ,
		{"word":"poussa","word_nosc":"poussa","lemma":"pousser","pos":"VER"} ,
		{"word":"poussai","word_nosc":"poussai","lemma":"pousser","pos":"VER"} ,
		{"word":"poussaient","word_nosc":"poussaient","lemma":"pousser","pos":"VER"} ,
		{"word":"poussais","word_nosc":"poussais","lemma":"pousser","pos":"VER"} ,
		{"word":"poussait","word_nosc":"poussait","lemma":"pousser","pos":"VER"} ,
		{"word":"poussant","word_nosc":"poussant","lemma":"pousser","pos":"VER"} ,
		{"word":"poussas","word_nosc":"poussas","lemma":"pousser","pos":"VER"} ,
		{"word":"pousse","word_nosc":"pousse","lemma":"pousser","pos":"VER"} ,
		{"word":"poussent","word_nosc":"poussent","lemma":"pousser","pos":"VER"} ,
		{"word":"pousser","word_nosc":"pousser","lemma":"pousser","pos":"VER"} ,
		{"word":"poussera","word_nosc":"poussera","lemma":"pousser","pos":"VER"} ,
		{"word":"pousserai","word_nosc":"pousserai","lemma":"pousser","pos":"VER"} ,
		{"word":"pousseraient","word_nosc":"pousseraient","lemma":"pousser","pos":"VER"} ,
		{"word":"pousserais","word_nosc":"pousserais","lemma":"pousser","pos":"VER"} ,
		{"word":"pousserait","word_nosc":"pousserait","lemma":"pousser","pos":"VER"} ,
		{"word":"pousseras","word_nosc":"pousseras","lemma":"pousser","pos":"VER"} ,
		{"word":"pousserez","word_nosc":"pousserez","lemma":"pousser","pos":"VER"} ,
		{"word":"pousseriez","word_nosc":"pousseriez","lemma":"pousser","pos":"VER"} ,
		{"word":"pousserions","word_nosc":"pousserions","lemma":"pousser","pos":"VER"} ,
		{"word":"pousserons","word_nosc":"pousserons","lemma":"pousser","pos":"VER"} ,
		{"word":"pousseront","word_nosc":"pousseront","lemma":"pousser","pos":"VER"} ,
		{"word":"pousses","word_nosc":"pousses","lemma":"pousser","pos":"VER"} ,
		{"word":"poussez","word_nosc":"poussez","lemma":"pousser","pos":"VER"} ,
		{"word":"poussiez","word_nosc":"poussiez","lemma":"pousser","pos":"VER"} ,
		{"word":"poussions","word_nosc":"poussions","lemma":"pousser","pos":"VER"} ,
		{"word":"poussons","word_nosc":"poussons","lemma":"pousser","pos":"VER"} ,
		{"word":"poussâmes","word_nosc":"poussames","lemma":"pousser","pos":"VER"} ,
		{"word":"poussât","word_nosc":"poussat","lemma":"pousser","pos":"VER"} ,
		{"word":"poussèrent","word_nosc":"pousserent","lemma":"pousser","pos":"VER"} ,
		{"word":"poussé","word_nosc":"pousse","lemma":"pousser","pos":"VER"} ,
		{"word":"poussée","word_nosc":"poussee","lemma":"pousser","pos":"VER"} ,
		{"word":"poussées","word_nosc":"poussees","lemma":"pousser","pos":"VER"} ,
		{"word":"poussés","word_nosc":"pousses","lemma":"pousser","pos":"VER"} ,
		{"word":"pouvaient","word_nosc":"pouvaient","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pouvais","word_nosc":"pouvais","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pouvait","word_nosc":"pouvait","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pouvant","word_nosc":"pouvant","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pouvez","word_nosc":"pouvez","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pouviez","word_nosc":"pouviez","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pouvions","word_nosc":"pouvions","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pouvoir","word_nosc":"pouvoir","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pouvons","word_nosc":"pouvons","lemma":"pouvoir","pos":"VER"} ,
		{"word":"poétisait","word_nosc":"poetisait","lemma":"poétiser","pos":"VER"} ,
		{"word":"poétisant","word_nosc":"poetisant","lemma":"poétiser","pos":"VER"} ,
		{"word":"poétise","word_nosc":"poetise","lemma":"poétiser","pos":"VER"} ,
		{"word":"poétiser","word_nosc":"poetiser","lemma":"poétiser","pos":"VER"} ,
		{"word":"poétisez","word_nosc":"poetisez","lemma":"poétiser","pos":"VER"} ,
		{"word":"poêle","word_nosc":"poele","lemma":"poêler","pos":"VER"} ,
		{"word":"poêlées","word_nosc":"poelees","lemma":"poêler","pos":"VER"} ,
		{"word":"pralinés","word_nosc":"pralines","lemma":"praliner","pos":"VER"} ,
		{"word":"pratiqua","word_nosc":"pratiqua","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquai","word_nosc":"pratiquai","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquaient","word_nosc":"pratiquaient","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquais","word_nosc":"pratiquais","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquait","word_nosc":"pratiquait","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquant","word_nosc":"pratiquant","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratique","word_nosc":"pratique","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquent","word_nosc":"pratiquent","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquer","word_nosc":"pratiquer","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquera","word_nosc":"pratiquera","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquerai","word_nosc":"pratiquerai","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquerais","word_nosc":"pratiquerais","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquerait","word_nosc":"pratiquerait","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquerons","word_nosc":"pratiquerons","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiques","word_nosc":"pratiques","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquez","word_nosc":"pratiquez","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquiez","word_nosc":"pratiquiez","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquions","word_nosc":"pratiquions","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquons","word_nosc":"pratiquons","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquât","word_nosc":"pratiquat","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquèrent","word_nosc":"pratiquerent","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiqué","word_nosc":"pratique","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquée","word_nosc":"pratiquee","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiquées","word_nosc":"pratiquees","lemma":"pratiquer","pos":"VER"} ,
		{"word":"pratiqués","word_nosc":"pratiques","lemma":"pratiquer","pos":"VER"} ,
		{"word":"prenaient","word_nosc":"prenaient","lemma":"prendre","pos":"VER"} ,
		{"word":"prenais","word_nosc":"prenais","lemma":"prendre","pos":"VER"} ,
		{"word":"prenait","word_nosc":"prenait","lemma":"prendre","pos":"VER"} ,
		{"word":"prenant","word_nosc":"prenant","lemma":"prendre","pos":"VER"} ,
		{"word":"prend","word_nosc":"prend","lemma":"prendre","pos":"VER"} ,
		{"word":"prendra","word_nosc":"prendra","lemma":"prendre","pos":"VER"} ,
		{"word":"prendrai","word_nosc":"prendrai","lemma":"prendre","pos":"VER"} ,
		{"word":"prendraient","word_nosc":"prendraient","lemma":"prendre","pos":"VER"} ,
		{"word":"prendrais","word_nosc":"prendrais","lemma":"prendre","pos":"VER"} ,
		{"word":"prendrait","word_nosc":"prendrait","lemma":"prendre","pos":"VER"} ,
		{"word":"prendras","word_nosc":"prendras","lemma":"prendre","pos":"VER"} ,
		{"word":"prendre","word_nosc":"prendre","lemma":"prendre","pos":"VER"} ,
		{"word":"prendrez","word_nosc":"prendrez","lemma":"prendre","pos":"VER"} ,
		{"word":"prendriez","word_nosc":"prendriez","lemma":"prendre","pos":"VER"} ,
		{"word":"prendrions","word_nosc":"prendrions","lemma":"prendre","pos":"VER"} ,
		{"word":"prendrons","word_nosc":"prendrons","lemma":"prendre","pos":"VER"} ,
		{"word":"prendront","word_nosc":"prendront","lemma":"prendre","pos":"VER"} ,
		{"word":"prends","word_nosc":"prends","lemma":"prendre","pos":"VER"} ,
		{"word":"prenez","word_nosc":"prenez","lemma":"prendre","pos":"VER"} ,
		{"word":"preniez","word_nosc":"preniez","lemma":"prendre","pos":"VER"} ,
		{"word":"prenions","word_nosc":"prenions","lemma":"prendre","pos":"VER"} ,
		{"word":"prenne","word_nosc":"prenne","lemma":"prendre","pos":"VER"} ,
		{"word":"prennent","word_nosc":"prennent","lemma":"prendre","pos":"VER"} ,
		{"word":"prennes","word_nosc":"prennes","lemma":"prendre","pos":"VER"} ,
		{"word":"prenons","word_nosc":"prenons","lemma":"prendre","pos":"VER"} ,
		{"word":"prescrirait","word_nosc":"prescrirait","lemma":"prescrire","pos":"VER"} ,
		{"word":"prescrire","word_nosc":"prescrire","lemma":"prescrire","pos":"VER"} ,
		{"word":"prescris","word_nosc":"prescris","lemma":"prescrire","pos":"VER"} ,
		{"word":"prescrit","word_nosc":"prescrit","lemma":"prescrire","pos":"VER"} ,
		{"word":"prescrite","word_nosc":"prescrite","lemma":"prescrire","pos":"VER"} ,
		{"word":"prescrites","word_nosc":"prescrites","lemma":"prescrire","pos":"VER"} ,
		{"word":"prescrits","word_nosc":"prescrits","lemma":"prescrire","pos":"VER"} ,
		{"word":"prescrivaient","word_nosc":"prescrivaient","lemma":"prescrire","pos":"VER"} ,
		{"word":"prescrivais","word_nosc":"prescrivais","lemma":"prescrire","pos":"VER"} ,
		{"word":"prescrivait","word_nosc":"prescrivait","lemma":"prescrire","pos":"VER"} ,
		{"word":"prescrivant","word_nosc":"prescrivant","lemma":"prescrire","pos":"VER"} ,
		{"word":"prescrive","word_nosc":"prescrive","lemma":"prescrire","pos":"VER"} ,
		{"word":"prescrivent","word_nosc":"prescrivent","lemma":"prescrire","pos":"VER"} ,
		{"word":"prescrivez","word_nosc":"prescrivez","lemma":"prescrire","pos":"VER"} ,
		{"word":"prescrivis","word_nosc":"prescrivis","lemma":"prescrire","pos":"VER"} ,
		{"word":"prescrivit","word_nosc":"prescrivit","lemma":"prescrire","pos":"VER"} ,
		{"word":"pressa","word_nosc":"pressa","lemma":"presser","pos":"VER"} ,
		{"word":"pressai","word_nosc":"pressai","lemma":"presser","pos":"VER"} ,
		{"word":"pressaient","word_nosc":"pressaient","lemma":"presser","pos":"VER"} ,
		{"word":"pressais","word_nosc":"pressais","lemma":"presser","pos":"VER"} ,
		{"word":"pressait","word_nosc":"pressait","lemma":"presser","pos":"VER"} ,
		{"word":"pressant","word_nosc":"pressant","lemma":"presser","pos":"VER"} ,
		{"word":"presse","word_nosc":"presse","lemma":"presser","pos":"VER"} ,
		{"word":"pressens","word_nosc":"pressens","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressent","word_nosc":"pressent","lemma":"presser","pos":"VER"} ,
		{"word":"pressent","word_nosc":"pressent","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentaient","word_nosc":"pressentaient","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentais","word_nosc":"pressentais","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentait","word_nosc":"pressentait","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentant","word_nosc":"pressentant","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentent","word_nosc":"pressentent","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentez","word_nosc":"pressentez","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressenti","word_nosc":"pressenti","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentie","word_nosc":"pressentie","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentiez","word_nosc":"pressentiez","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentions","word_nosc":"pressentions","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentir","word_nosc":"pressentir","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentirez","word_nosc":"pressentirez","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentis","word_nosc":"pressentis","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentit","word_nosc":"pressentit","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentons","word_nosc":"pressentons","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentîmes","word_nosc":"pressentimes","lemma":"pressentir","pos":"VER"} ,
		{"word":"pressentît","word_nosc":"pressentit","lemma":"pressentir","pos":"VER"} ,
		{"word":"presser","word_nosc":"presser","lemma":"presser","pos":"VER"} ,
		{"word":"pressera","word_nosc":"pressera","lemma":"presser","pos":"VER"} ,
		{"word":"presserai","word_nosc":"presserai","lemma":"presser","pos":"VER"} ,
		{"word":"presseraient","word_nosc":"presseraient","lemma":"presser","pos":"VER"} ,
		{"word":"presserait","word_nosc":"presserait","lemma":"presser","pos":"VER"} ,
		{"word":"presseront","word_nosc":"presseront","lemma":"presser","pos":"VER"} ,
		{"word":"presses","word_nosc":"presses","lemma":"presser","pos":"VER"} ,
		{"word":"pressez","word_nosc":"pressez","lemma":"presser","pos":"VER"} ,
		{"word":"pressiez","word_nosc":"pressiez","lemma":"presser","pos":"VER"} ,
		{"word":"pressions","word_nosc":"pressions","lemma":"presser","pos":"VER"} ,
		{"word":"pressons","word_nosc":"pressons","lemma":"presser","pos":"VER"} ,
		{"word":"pressurant","word_nosc":"pressurant","lemma":"pressurer","pos":"VER"} ,
		{"word":"pressure","word_nosc":"pressure","lemma":"pressurer","pos":"VER"} ,
		{"word":"pressurer","word_nosc":"pressurer","lemma":"pressurer","pos":"VER"} ,
		{"word":"pressures","word_nosc":"pressures","lemma":"pressurer","pos":"VER"} ,
		{"word":"pressuriser","word_nosc":"pressuriser","lemma":"pressuriser","pos":"VER"} ,
		{"word":"pressurisez","word_nosc":"pressurisez","lemma":"pressuriser","pos":"VER"} ,
		{"word":"pressurisé","word_nosc":"pressurise","lemma":"pressuriser","pos":"VER"} ,
		{"word":"pressurisée","word_nosc":"pressurisee","lemma":"pressuriser","pos":"VER"} ,
		{"word":"pressurisées","word_nosc":"pressurisees","lemma":"pressuriser","pos":"VER"} ,
		{"word":"pressurisés","word_nosc":"pressurises","lemma":"pressuriser","pos":"VER"} ,
		{"word":"pressurée","word_nosc":"pressuree","lemma":"pressurer","pos":"VER"} ,
		{"word":"pressurés","word_nosc":"pressures","lemma":"pressurer","pos":"VER"} ,
		{"word":"pressât","word_nosc":"pressat","lemma":"presser","pos":"VER"} ,
		{"word":"pressèrent","word_nosc":"presserent","lemma":"presser","pos":"VER"} ,
		{"word":"pressé","word_nosc":"presse","lemma":"presser","pos":"VER"} ,
		{"word":"pressés","word_nosc":"presses","lemma":"presser","pos":"VER"} ,
		{"word":"pria","word_nosc":"pria","lemma":"prier","pos":"VER"} ,
		{"word":"priai","word_nosc":"priai","lemma":"prier","pos":"VER"} ,
		{"word":"priaient","word_nosc":"priaient","lemma":"prier","pos":"VER"} ,
		{"word":"priais","word_nosc":"priais","lemma":"prier","pos":"VER"} ,
		{"word":"priait","word_nosc":"priait","lemma":"prier","pos":"VER"} ,
		{"word":"priant","word_nosc":"priant","lemma":"prier","pos":"VER"} ,
		{"word":"priasse","word_nosc":"priasse","lemma":"prier","pos":"VER"} ,
		{"word":"prie","word_nosc":"prie","lemma":"prier","pos":"VER"} ,
		{"word":"prient","word_nosc":"prient","lemma":"prier","pos":"VER"} ,
		{"word":"prier","word_nosc":"prier","lemma":"prier","pos":"VER"} ,
		{"word":"priera","word_nosc":"priera","lemma":"prier","pos":"VER"} ,
		{"word":"prierai","word_nosc":"prierai","lemma":"prier","pos":"VER"} ,
		{"word":"prieraient","word_nosc":"prieraient","lemma":"prier","pos":"VER"} ,
		{"word":"prierais","word_nosc":"prierais","lemma":"prier","pos":"VER"} ,
		{"word":"prierait","word_nosc":"prierait","lemma":"prier","pos":"VER"} ,
		{"word":"prieras","word_nosc":"prieras","lemma":"prier","pos":"VER"} ,
		{"word":"prierez","word_nosc":"prierez","lemma":"prier","pos":"VER"} ,
		{"word":"prierons","word_nosc":"prierons","lemma":"prier","pos":"VER"} ,
		{"word":"prieront","word_nosc":"prieront","lemma":"prier","pos":"VER"} ,
		{"word":"pries","word_nosc":"pries","lemma":"prier","pos":"VER"} ,
		{"word":"priez","word_nosc":"priez","lemma":"prier","pos":"VER"} ,
		{"word":"priions","word_nosc":"priions","lemma":"prier","pos":"VER"} ,
		{"word":"prima","word_nosc":"prima","lemma":"primer","pos":"VER"} ,
		{"word":"primait","word_nosc":"primait","lemma":"primer","pos":"VER"} ,
		{"word":"primant","word_nosc":"primant","lemma":"primer","pos":"VER"} ,
		{"word":"prime","word_nosc":"prime","lemma":"primer","pos":"VER"} ,
		{"word":"priment","word_nosc":"priment","lemma":"primer","pos":"VER"} ,
		{"word":"primer","word_nosc":"primer","lemma":"primer","pos":"VER"} ,
		{"word":"primerait","word_nosc":"primerait","lemma":"primer","pos":"VER"} ,
		{"word":"primé","word_nosc":"prime","lemma":"primer","pos":"VER"} ,
		{"word":"primée","word_nosc":"primee","lemma":"primer","pos":"VER"} ,
		{"word":"primées","word_nosc":"primees","lemma":"primer","pos":"VER"} ,
		{"word":"prions","word_nosc":"prions","lemma":"prier","pos":"VER"} ,
		{"word":"prirent","word_nosc":"prirent","lemma":"prendre","pos":"VER"} ,
		{"word":"pris","word_nosc":"pris","lemma":"prendre","pos":"VER"} ,
		{"word":"prisa","word_nosc":"prisa","lemma":"priser","pos":"VER"} ,
		{"word":"prisaient","word_nosc":"prisaient","lemma":"priser","pos":"VER"} ,
		{"word":"prisait","word_nosc":"prisait","lemma":"priser","pos":"VER"} ,
		{"word":"prisant","word_nosc":"prisant","lemma":"priser","pos":"VER"} ,
		{"word":"prise","word_nosc":"prise","lemma":"prendre","pos":"VER"} ,
		{"word":"prise","word_nosc":"prise","lemma":"priser","pos":"VER"} ,
		{"word":"prisent","word_nosc":"prisent","lemma":"priser","pos":"VER"} ,
		{"word":"priser","word_nosc":"priser","lemma":"priser","pos":"VER"} ,
		{"word":"prises","word_nosc":"prises","lemma":"prendre","pos":"VER"} ,
		{"word":"prises","word_nosc":"prises","lemma":"priser","pos":"VER"} ,
		{"word":"prisez","word_nosc":"prisez","lemma":"priser","pos":"VER"} ,
		{"word":"prisons","word_nosc":"prisons","lemma":"priser","pos":"VER"} ,
		{"word":"prisse","word_nosc":"prisse","lemma":"prendre","pos":"VER"} ,
		{"word":"prissent","word_nosc":"prissent","lemma":"prendre","pos":"VER"} ,
		{"word":"prissions","word_nosc":"prissions","lemma":"prendre","pos":"VER"} ,
		{"word":"prisé","word_nosc":"prise","lemma":"priser","pos":"VER"} ,
		{"word":"prisée","word_nosc":"prisee","lemma":"priser","pos":"VER"} ,
		{"word":"prisées","word_nosc":"prisees","lemma":"priser","pos":"VER"} ,
		{"word":"prisés","word_nosc":"prises","lemma":"priser","pos":"VER"} ,
		{"word":"prit","word_nosc":"prit","lemma":"prendre","pos":"VER"} ,
		{"word":"priva","word_nosc":"priva","lemma":"priver","pos":"VER"} ,
		{"word":"privai","word_nosc":"privai","lemma":"priver","pos":"VER"} ,
		{"word":"privaient","word_nosc":"privaient","lemma":"priver","pos":"VER"} ,
		{"word":"privais","word_nosc":"privais","lemma":"priver","pos":"VER"} ,
		{"word":"privait","word_nosc":"privait","lemma":"priver","pos":"VER"} ,
		{"word":"privant","word_nosc":"privant","lemma":"priver","pos":"VER"} ,
		{"word":"privasse","word_nosc":"privasse","lemma":"priver","pos":"VER"} ,
		{"word":"privatiser","word_nosc":"privatiser","lemma":"privatiser","pos":"VER"} ,
		{"word":"privatisé","word_nosc":"privatise","lemma":"privatiser","pos":"VER"} ,
		{"word":"privatisée","word_nosc":"privatisee","lemma":"privatiser","pos":"VER"} ,
		{"word":"privatisés","word_nosc":"privatises","lemma":"privatiser","pos":"VER"} ,
		{"word":"prive","word_nosc":"prive","lemma":"priver","pos":"VER"} ,
		{"word":"privent","word_nosc":"privent","lemma":"priver","pos":"VER"} ,
		{"word":"priver","word_nosc":"priver","lemma":"priver","pos":"VER"} ,
		{"word":"privera","word_nosc":"privera","lemma":"priver","pos":"VER"} ,
		{"word":"priverai","word_nosc":"priverai","lemma":"priver","pos":"VER"} ,
		{"word":"priveraient","word_nosc":"priveraient","lemma":"priver","pos":"VER"} ,
		{"word":"priverais","word_nosc":"priverais","lemma":"priver","pos":"VER"} ,
		{"word":"priverait","word_nosc":"priverait","lemma":"priver","pos":"VER"} ,
		{"word":"priveras","word_nosc":"priveras","lemma":"priver","pos":"VER"} ,
		{"word":"priveriez","word_nosc":"priveriez","lemma":"priver","pos":"VER"} ,
		{"word":"prives","word_nosc":"prives","lemma":"priver","pos":"VER"} ,
		{"word":"privez","word_nosc":"privez","lemma":"priver","pos":"VER"} ,
		{"word":"priviez","word_nosc":"priviez","lemma":"priver","pos":"VER"} ,
		{"word":"privilégiait","word_nosc":"privilegiait","lemma":"privilégier","pos":"VER"} ,
		{"word":"privilégiant","word_nosc":"privilegiant","lemma":"privilégier","pos":"VER"} ,
		{"word":"privilégie","word_nosc":"privilegie","lemma":"privilégier","pos":"VER"} ,
		{"word":"privilégient","word_nosc":"privilegient","lemma":"privilégier","pos":"VER"} ,
		{"word":"privilégier","word_nosc":"privilegier","lemma":"privilégier","pos":"VER"} ,
		{"word":"privilégions","word_nosc":"privilegions","lemma":"privilégier","pos":"VER"} ,
		{"word":"privilégié","word_nosc":"privilegie","lemma":"privilégier","pos":"VER"} ,
		{"word":"privilégiée","word_nosc":"privilegiee","lemma":"privilégier","pos":"VER"} ,
		{"word":"privilégiées","word_nosc":"privilegiees","lemma":"privilégier","pos":"VER"} ,
		{"word":"privilégiés","word_nosc":"privilegies","lemma":"privilégier","pos":"VER"} ,
		{"word":"privions","word_nosc":"privions","lemma":"priver","pos":"VER"} ,
		{"word":"privons","word_nosc":"privons","lemma":"priver","pos":"VER"} ,
		{"word":"privât","word_nosc":"privat","lemma":"priver","pos":"VER"} ,
		{"word":"privèrent","word_nosc":"priverent","lemma":"priver","pos":"VER"} ,
		{"word":"privé","word_nosc":"prive","lemma":"priver","pos":"VER"} ,
		{"word":"privée","word_nosc":"privee","lemma":"priver","pos":"VER"} ,
		{"word":"privées","word_nosc":"privees","lemma":"priver","pos":"VER"} ,
		{"word":"privés","word_nosc":"prives","lemma":"priver","pos":"VER"} ,
		{"word":"priâmes","word_nosc":"priames","lemma":"prier","pos":"VER"} ,
		{"word":"priât","word_nosc":"priat","lemma":"prier","pos":"VER"} ,
		{"word":"prièrent","word_nosc":"prierent","lemma":"prier","pos":"VER"} ,
		{"word":"prié","word_nosc":"prie","lemma":"prier","pos":"VER"} ,
		{"word":"priée","word_nosc":"priee","lemma":"prier","pos":"VER"} ,
		{"word":"priées","word_nosc":"priees","lemma":"prier","pos":"VER"} ,
		{"word":"priés","word_nosc":"pries","lemma":"prier","pos":"VER"} ,
		{"word":"processionnaient","word_nosc":"processionnaient","lemma":"processionner","pos":"VER"} ,
		{"word":"processionnant","word_nosc":"processionnant","lemma":"processionner","pos":"VER"} ,
		{"word":"proclama","word_nosc":"proclama","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamai","word_nosc":"proclamai","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamaient","word_nosc":"proclamaient","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamais","word_nosc":"proclamais","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamait","word_nosc":"proclamait","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamant","word_nosc":"proclamant","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclame","word_nosc":"proclame","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclament","word_nosc":"proclament","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamer","word_nosc":"proclamer","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamera","word_nosc":"proclamera","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamerai","word_nosc":"proclamerai","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclameraient","word_nosc":"proclameraient","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamerait","word_nosc":"proclamerait","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamerons","word_nosc":"proclamerons","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamez","word_nosc":"proclamez","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamiez","word_nosc":"proclamiez","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamions","word_nosc":"proclamions","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamons","word_nosc":"proclamons","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamât","word_nosc":"proclamat","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamèrent","word_nosc":"proclamerent","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamé","word_nosc":"proclame","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamée","word_nosc":"proclamee","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamées","word_nosc":"proclamees","lemma":"proclamer","pos":"VER"} ,
		{"word":"proclamés","word_nosc":"proclames","lemma":"proclamer","pos":"VER"} ,
		{"word":"procréa","word_nosc":"procrea","lemma":"procréer","pos":"VER"} ,
		{"word":"procréant","word_nosc":"procreant","lemma":"procréer","pos":"VER"} ,
		{"word":"procrée","word_nosc":"procree","lemma":"procréer","pos":"VER"} ,
		{"word":"procréent","word_nosc":"procreent","lemma":"procréer","pos":"VER"} ,
		{"word":"procréer","word_nosc":"procreer","lemma":"procréer","pos":"VER"} ,
		{"word":"procréerons","word_nosc":"procreerons","lemma":"procréer","pos":"VER"} ,
		{"word":"procréé","word_nosc":"procree","lemma":"procréer","pos":"VER"} ,
		{"word":"procura","word_nosc":"procura","lemma":"procurer","pos":"VER"} ,
		{"word":"procurai","word_nosc":"procurai","lemma":"procurer","pos":"VER"} ,
		{"word":"procuraient","word_nosc":"procuraient","lemma":"procurer","pos":"VER"} ,
		{"word":"procurais","word_nosc":"procurais","lemma":"procurer","pos":"VER"} ,
		{"word":"procurait","word_nosc":"procurait","lemma":"procurer","pos":"VER"} ,
		{"word":"procurant","word_nosc":"procurant","lemma":"procurer","pos":"VER"} ,
		{"word":"procure","word_nosc":"procure","lemma":"procurer","pos":"VER"} ,
		{"word":"procurent","word_nosc":"procurent","lemma":"procurer","pos":"VER"} ,
		{"word":"procurer","word_nosc":"procurer","lemma":"procurer","pos":"VER"} ,
		{"word":"procurera","word_nosc":"procurera","lemma":"procurer","pos":"VER"} ,
		{"word":"procurerai","word_nosc":"procurerai","lemma":"procurer","pos":"VER"} ,
		{"word":"procureraient","word_nosc":"procureraient","lemma":"procurer","pos":"VER"} ,
		{"word":"procurerais","word_nosc":"procurerais","lemma":"procurer","pos":"VER"} ,
		{"word":"procurerait","word_nosc":"procurerait","lemma":"procurer","pos":"VER"} ,
		{"word":"procureras","word_nosc":"procureras","lemma":"procurer","pos":"VER"} ,
		{"word":"procureriez","word_nosc":"procureriez","lemma":"procurer","pos":"VER"} ,
		{"word":"procurerons","word_nosc":"procurerons","lemma":"procurer","pos":"VER"} ,
		{"word":"procureront","word_nosc":"procureront","lemma":"procurer","pos":"VER"} ,
		{"word":"procures","word_nosc":"procures","lemma":"procurer","pos":"VER"} ,
		{"word":"procurez","word_nosc":"procurez","lemma":"procurer","pos":"VER"} ,
		{"word":"procuriez","word_nosc":"procuriez","lemma":"procurer","pos":"VER"} ,
		{"word":"procurions","word_nosc":"procurions","lemma":"procurer","pos":"VER"} ,
		{"word":"procurât","word_nosc":"procurat","lemma":"procurer","pos":"VER"} ,
		{"word":"procurèrent","word_nosc":"procurerent","lemma":"procurer","pos":"VER"} ,
		{"word":"procuré","word_nosc":"procure","lemma":"procurer","pos":"VER"} ,
		{"word":"procurée","word_nosc":"procuree","lemma":"procurer","pos":"VER"} ,
		{"word":"procurées","word_nosc":"procurees","lemma":"procurer","pos":"VER"} ,
		{"word":"procurés","word_nosc":"procures","lemma":"procurer","pos":"VER"} ,
		{"word":"procède","word_nosc":"procede","lemma":"procéder","pos":"VER"} ,
		{"word":"procèdent","word_nosc":"procedent","lemma":"procéder","pos":"VER"} ,
		{"word":"procéda","word_nosc":"proceda","lemma":"procéder","pos":"VER"} ,
		{"word":"procédaient","word_nosc":"procedaient","lemma":"procéder","pos":"VER"} ,
		{"word":"procédais","word_nosc":"procedais","lemma":"procéder","pos":"VER"} ,
		{"word":"procédait","word_nosc":"procedait","lemma":"procéder","pos":"VER"} ,
		{"word":"procédant","word_nosc":"procedant","lemma":"procéder","pos":"VER"} ,
		{"word":"procéder","word_nosc":"proceder","lemma":"procéder","pos":"VER"} ,
		{"word":"procédera","word_nosc":"procedera","lemma":"procéder","pos":"VER"} ,
		{"word":"procéderaient","word_nosc":"procederaient","lemma":"procéder","pos":"VER"} ,
		{"word":"procéderais","word_nosc":"procederais","lemma":"procéder","pos":"VER"} ,
		{"word":"procéderait","word_nosc":"procederait","lemma":"procéder","pos":"VER"} ,
		{"word":"procéderez","word_nosc":"procederez","lemma":"procéder","pos":"VER"} ,
		{"word":"procéderiez","word_nosc":"procederiez","lemma":"procéder","pos":"VER"} ,
		{"word":"procéderons","word_nosc":"procederons","lemma":"procéder","pos":"VER"} ,
		{"word":"procéderont","word_nosc":"procederont","lemma":"procéder","pos":"VER"} ,
		{"word":"procédez","word_nosc":"procedez","lemma":"procéder","pos":"VER"} ,
		{"word":"procédiez","word_nosc":"procediez","lemma":"procéder","pos":"VER"} ,
		{"word":"procédions","word_nosc":"procedions","lemma":"procéder","pos":"VER"} ,
		{"word":"procédons","word_nosc":"procedons","lemma":"procéder","pos":"VER"} ,
		{"word":"procédâmes","word_nosc":"procedames","lemma":"procéder","pos":"VER"} ,
		{"word":"procédât","word_nosc":"procedat","lemma":"procéder","pos":"VER"} ,
		{"word":"procédé","word_nosc":"procede","lemma":"procéder","pos":"VER"} ,
		{"word":"procédés","word_nosc":"procedes","lemma":"procéder","pos":"VER"} ,
		{"word":"prodigua","word_nosc":"prodigua","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodiguaient","word_nosc":"prodiguaient","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodiguais","word_nosc":"prodiguais","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodiguait","word_nosc":"prodiguait","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodiguant","word_nosc":"prodiguant","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodigue","word_nosc":"prodigue","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodiguent","word_nosc":"prodiguent","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodiguer","word_nosc":"prodiguer","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodiguera","word_nosc":"prodiguera","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodiguerais","word_nosc":"prodiguerais","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodigueras","word_nosc":"prodigueras","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodigueront","word_nosc":"prodigueront","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodigues","word_nosc":"prodigues","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodiguions","word_nosc":"prodiguions","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodiguèrent","word_nosc":"prodiguerent","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodigué","word_nosc":"prodigue","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodiguée","word_nosc":"prodiguee","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodiguées","word_nosc":"prodiguees","lemma":"prodiguer","pos":"VER"} ,
		{"word":"prodigués","word_nosc":"prodigues","lemma":"prodiguer","pos":"VER"} ,
		{"word":"produira","word_nosc":"produira","lemma":"produire","pos":"VER"} ,
		{"word":"produiraient","word_nosc":"produiraient","lemma":"produire","pos":"VER"} ,
		{"word":"produirais","word_nosc":"produirais","lemma":"produire","pos":"VER"} ,
		{"word":"produirait","word_nosc":"produirait","lemma":"produire","pos":"VER"} ,
		{"word":"produiras","word_nosc":"produiras","lemma":"produire","pos":"VER"} ,
		{"word":"produire","word_nosc":"produire","lemma":"produire","pos":"VER"} ,
		{"word":"produirez","word_nosc":"produirez","lemma":"produire","pos":"VER"} ,
		{"word":"produiriez","word_nosc":"produiriez","lemma":"produire","pos":"VER"} ,
		{"word":"produirons","word_nosc":"produirons","lemma":"produire","pos":"VER"} ,
		{"word":"produiront","word_nosc":"produiront","lemma":"produire","pos":"VER"} ,
		{"word":"produis","word_nosc":"produis","lemma":"produire","pos":"VER"} ,
		{"word":"produisaient","word_nosc":"produisaient","lemma":"produire","pos":"VER"} ,
		{"word":"produisais","word_nosc":"produisais","lemma":"produire","pos":"VER"} ,
		{"word":"produisait","word_nosc":"produisait","lemma":"produire","pos":"VER"} ,
		{"word":"produisant","word_nosc":"produisant","lemma":"produire","pos":"VER"} ,
		{"word":"produise","word_nosc":"produise","lemma":"produire","pos":"VER"} ,
		{"word":"produisent","word_nosc":"produisent","lemma":"produire","pos":"VER"} ,
		{"word":"produises","word_nosc":"produises","lemma":"produire","pos":"VER"} ,
		{"word":"produisez","word_nosc":"produisez","lemma":"produire","pos":"VER"} ,
		{"word":"produisions","word_nosc":"produisions","lemma":"produire","pos":"VER"} ,
		{"word":"produisirent","word_nosc":"produisirent","lemma":"produire","pos":"VER"} ,
		{"word":"produisis","word_nosc":"produisis","lemma":"produire","pos":"VER"} ,
		{"word":"produisit","word_nosc":"produisit","lemma":"produire","pos":"VER"} ,
		{"word":"produisons","word_nosc":"produisons","lemma":"produire","pos":"VER"} ,
		{"word":"produisît","word_nosc":"produisit","lemma":"produire","pos":"VER"} ,
		{"word":"produit","word_nosc":"produit","lemma":"produire","pos":"VER"} ,
		{"word":"produite","word_nosc":"produite","lemma":"produire","pos":"VER"} ,
		{"word":"produites","word_nosc":"produites","lemma":"produire","pos":"VER"} ,
		{"word":"produits","word_nosc":"produits","lemma":"produire","pos":"VER"} ,
		{"word":"profana","word_nosc":"profana","lemma":"profaner","pos":"VER"} ,
		{"word":"profanaient","word_nosc":"profanaient","lemma":"profaner","pos":"VER"} ,
		{"word":"profanait","word_nosc":"profanait","lemma":"profaner","pos":"VER"} ,
		{"word":"profanant","word_nosc":"profanant","lemma":"profaner","pos":"VER"} ,
		{"word":"profane","word_nosc":"profane","lemma":"profaner","pos":"VER"} ,
		{"word":"profanent","word_nosc":"profanent","lemma":"profaner","pos":"VER"} ,
		{"word":"profaner","word_nosc":"profaner","lemma":"profaner","pos":"VER"} ,
		{"word":"profanera","word_nosc":"profanera","lemma":"profaner","pos":"VER"} ,
		{"word":"profanes","word_nosc":"profanes","lemma":"profaner","pos":"VER"} ,
		{"word":"profanez","word_nosc":"profanez","lemma":"profaner","pos":"VER"} ,
		{"word":"profanèrent","word_nosc":"profanerent","lemma":"profaner","pos":"VER"} ,
		{"word":"profané","word_nosc":"profane","lemma":"profaner","pos":"VER"} ,
		{"word":"profanée","word_nosc":"profanee","lemma":"profaner","pos":"VER"} ,
		{"word":"profanées","word_nosc":"profanees","lemma":"profaner","pos":"VER"} ,
		{"word":"profanés","word_nosc":"profanes","lemma":"profaner","pos":"VER"} ,
		{"word":"professa","word_nosc":"professa","lemma":"professer","pos":"VER"} ,
		{"word":"professaient","word_nosc":"professaient","lemma":"professer","pos":"VER"} ,
		{"word":"professait","word_nosc":"professait","lemma":"professer","pos":"VER"} ,
		{"word":"professant","word_nosc":"professant","lemma":"professer","pos":"VER"} ,
		{"word":"professe","word_nosc":"professe","lemma":"professer","pos":"VER"} ,
		{"word":"professent","word_nosc":"professent","lemma":"professer","pos":"VER"} ,
		{"word":"professer","word_nosc":"professer","lemma":"professer","pos":"VER"} ,
		{"word":"professiez","word_nosc":"professiez","lemma":"professer","pos":"VER"} ,
		{"word":"professions","word_nosc":"professions","lemma":"professer","pos":"VER"} ,
		{"word":"professons","word_nosc":"professons","lemma":"professer","pos":"VER"} ,
		{"word":"professé","word_nosc":"professe","lemma":"professer","pos":"VER"} ,
		{"word":"professées","word_nosc":"professees","lemma":"professer","pos":"VER"} ,
		{"word":"profila","word_nosc":"profila","lemma":"profiler","pos":"VER"} ,
		{"word":"profilaient","word_nosc":"profilaient","lemma":"profiler","pos":"VER"} ,
		{"word":"profilait","word_nosc":"profilait","lemma":"profiler","pos":"VER"} ,
		{"word":"profilant","word_nosc":"profilant","lemma":"profiler","pos":"VER"} ,
		{"word":"profile","word_nosc":"profile","lemma":"profiler","pos":"VER"} ,
		{"word":"profilent","word_nosc":"profilent","lemma":"profiler","pos":"VER"} ,
		{"word":"profiler","word_nosc":"profiler","lemma":"profiler","pos":"VER"} ,
		{"word":"profilèrent","word_nosc":"profilerent","lemma":"profiler","pos":"VER"} ,
		{"word":"profilé","word_nosc":"profile","lemma":"profiler","pos":"VER"} ,
		{"word":"profilée","word_nosc":"profilee","lemma":"profiler","pos":"VER"} ,
		{"word":"profilées","word_nosc":"profilees","lemma":"profiler","pos":"VER"} ,
		{"word":"profilés","word_nosc":"profiles","lemma":"profiler","pos":"VER"} ,
		{"word":"profita","word_nosc":"profita","lemma":"profiter","pos":"VER"} ,
		{"word":"profitai","word_nosc":"profitai","lemma":"profiter","pos":"VER"} ,
		{"word":"profitaient","word_nosc":"profitaient","lemma":"profiter","pos":"VER"} ,
		{"word":"profitais","word_nosc":"profitais","lemma":"profiter","pos":"VER"} ,
		{"word":"profitait","word_nosc":"profitait","lemma":"profiter","pos":"VER"} ,
		{"word":"profitant","word_nosc":"profitant","lemma":"profiter","pos":"VER"} ,
		{"word":"profite","word_nosc":"profite","lemma":"profiter","pos":"VER"} ,
		{"word":"profitent","word_nosc":"profitent","lemma":"profiter","pos":"VER"} ,
		{"word":"profiter","word_nosc":"profiter","lemma":"profiter","pos":"VER"} ,
		{"word":"profitera","word_nosc":"profitera","lemma":"profiter","pos":"VER"} ,
		{"word":"profiterai","word_nosc":"profiterai","lemma":"profiter","pos":"VER"} ,
		{"word":"profiteraient","word_nosc":"profiteraient","lemma":"profiter","pos":"VER"} ,
		{"word":"profiterais","word_nosc":"profiterais","lemma":"profiter","pos":"VER"} ,
		{"word":"profiterait","word_nosc":"profiterait","lemma":"profiter","pos":"VER"} ,
		{"word":"profiteras","word_nosc":"profiteras","lemma":"profiter","pos":"VER"} ,
		{"word":"profiterez","word_nosc":"profiterez","lemma":"profiter","pos":"VER"} ,
		{"word":"profiteriez","word_nosc":"profiteriez","lemma":"profiter","pos":"VER"} ,
		{"word":"profiterons","word_nosc":"profiterons","lemma":"profiter","pos":"VER"} ,
		{"word":"profiteront","word_nosc":"profiteront","lemma":"profiter","pos":"VER"} ,
		{"word":"profites","word_nosc":"profites","lemma":"profiter","pos":"VER"} ,
		{"word":"profitez","word_nosc":"profitez","lemma":"profiter","pos":"VER"} ,
		{"word":"profitiez","word_nosc":"profitiez","lemma":"profiter","pos":"VER"} ,
		{"word":"profitions","word_nosc":"profitions","lemma":"profiter","pos":"VER"} ,
		{"word":"profitons","word_nosc":"profitons","lemma":"profiter","pos":"VER"} ,
		{"word":"profitâmes","word_nosc":"profitames","lemma":"profiter","pos":"VER"} ,
		{"word":"profitât","word_nosc":"profitat","lemma":"profiter","pos":"VER"} ,
		{"word":"profitèrent","word_nosc":"profiterent","lemma":"profiter","pos":"VER"} ,
		{"word":"profité","word_nosc":"profite","lemma":"profiter","pos":"VER"} ,
		{"word":"profitée","word_nosc":"profitee","lemma":"profiter","pos":"VER"} ,
		{"word":"profère","word_nosc":"profere","lemma":"proférer","pos":"VER"} ,
		{"word":"profèrent","word_nosc":"proferent","lemma":"proférer","pos":"VER"} ,
		{"word":"proféra","word_nosc":"profera","lemma":"proférer","pos":"VER"} ,
		{"word":"proféraient","word_nosc":"proferaient","lemma":"proférer","pos":"VER"} ,
		{"word":"proférait","word_nosc":"proferait","lemma":"proférer","pos":"VER"} ,
		{"word":"proférant","word_nosc":"proferant","lemma":"proférer","pos":"VER"} ,
		{"word":"proférer","word_nosc":"proferer","lemma":"proférer","pos":"VER"} ,
		{"word":"proféreraient","word_nosc":"profereraient","lemma":"proférer","pos":"VER"} ,
		{"word":"proférez","word_nosc":"proferez","lemma":"proférer","pos":"VER"} ,
		{"word":"proférions","word_nosc":"proferions","lemma":"proférer","pos":"VER"} ,
		{"word":"proférât","word_nosc":"proferat","lemma":"proférer","pos":"VER"} ,
		{"word":"proféré","word_nosc":"profere","lemma":"proférer","pos":"VER"} ,
		{"word":"proférée","word_nosc":"proferee","lemma":"proférer","pos":"VER"} ,
		{"word":"proférées","word_nosc":"proferees","lemma":"proférer","pos":"VER"} ,
		{"word":"proférés","word_nosc":"proferes","lemma":"proférer","pos":"VER"} ,
		{"word":"programma","word_nosc":"programma","lemma":"programmer","pos":"VER"} ,
		{"word":"programmaient","word_nosc":"programmaient","lemma":"programmer","pos":"VER"} ,
		{"word":"programmait","word_nosc":"programmait","lemma":"programmer","pos":"VER"} ,
		{"word":"programme","word_nosc":"programme","lemma":"programmer","pos":"VER"} ,
		{"word":"programment","word_nosc":"programment","lemma":"programmer","pos":"VER"} ,
		{"word":"programmer","word_nosc":"programmer","lemma":"programmer","pos":"VER"} ,
		{"word":"programmerai","word_nosc":"programmerai","lemma":"programmer","pos":"VER"} ,
		{"word":"programmes","word_nosc":"programmes","lemma":"programmer","pos":"VER"} ,
		{"word":"programmez","word_nosc":"programmez","lemma":"programmer","pos":"VER"} ,
		{"word":"programmons","word_nosc":"programmons","lemma":"programmer","pos":"VER"} ,
		{"word":"programmé","word_nosc":"programme","lemma":"programmer","pos":"VER"} ,
		{"word":"programmée","word_nosc":"programmee","lemma":"programmer","pos":"VER"} ,
		{"word":"programmées","word_nosc":"programmees","lemma":"programmer","pos":"VER"} ,
		{"word":"programmés","word_nosc":"programmes","lemma":"programmer","pos":"VER"} ,
		{"word":"progressa","word_nosc":"progressa","lemma":"progresser","pos":"VER"} ,
		{"word":"progressai","word_nosc":"progressai","lemma":"progresser","pos":"VER"} ,
		{"word":"progressaient","word_nosc":"progressaient","lemma":"progresser","pos":"VER"} ,
		{"word":"progressais","word_nosc":"progressais","lemma":"progresser","pos":"VER"} ,
		{"word":"progressait","word_nosc":"progressait","lemma":"progresser","pos":"VER"} ,
		{"word":"progressant","word_nosc":"progressant","lemma":"progresser","pos":"VER"} ,
		{"word":"progresse","word_nosc":"progresse","lemma":"progresser","pos":"VER"} ,
		{"word":"progressent","word_nosc":"progressent","lemma":"progresser","pos":"VER"} ,
		{"word":"progresser","word_nosc":"progresser","lemma":"progresser","pos":"VER"} ,
		{"word":"progressera","word_nosc":"progressera","lemma":"progresser","pos":"VER"} ,
		{"word":"progresseraient","word_nosc":"progresseraient","lemma":"progresser","pos":"VER"} ,
		{"word":"progresserais","word_nosc":"progresserais","lemma":"progresser","pos":"VER"} ,
		{"word":"progresserait","word_nosc":"progresserait","lemma":"progresser","pos":"VER"} ,
		{"word":"progresserez","word_nosc":"progresserez","lemma":"progresser","pos":"VER"} ,
		{"word":"progresserons","word_nosc":"progresserons","lemma":"progresser","pos":"VER"} ,
		{"word":"progresseront","word_nosc":"progresseront","lemma":"progresser","pos":"VER"} ,
		{"word":"progresses","word_nosc":"progresses","lemma":"progresser","pos":"VER"} ,
		{"word":"progressez","word_nosc":"progressez","lemma":"progresser","pos":"VER"} ,
		{"word":"progressions","word_nosc":"progressions","lemma":"progresser","pos":"VER"} ,
		{"word":"progressons","word_nosc":"progressons","lemma":"progresser","pos":"VER"} ,
		{"word":"progressèrent","word_nosc":"progresserent","lemma":"progresser","pos":"VER"} ,
		{"word":"progressé","word_nosc":"progresse","lemma":"progresser","pos":"VER"} ,
		{"word":"progressée","word_nosc":"progressee","lemma":"progresser","pos":"VER"} ,
		{"word":"prohibait","word_nosc":"prohibait","lemma":"prohiber","pos":"VER"} ,
		{"word":"prohibe","word_nosc":"prohibe","lemma":"prohiber","pos":"VER"} ,
		{"word":"prohibent","word_nosc":"prohibent","lemma":"prohiber","pos":"VER"} ,
		{"word":"prohibé","word_nosc":"prohibe","lemma":"prohiber","pos":"VER"} ,
		{"word":"prohibée","word_nosc":"prohibee","lemma":"prohiber","pos":"VER"} ,
		{"word":"prohibées","word_nosc":"prohibees","lemma":"prohiber","pos":"VER"} ,
		{"word":"prohibés","word_nosc":"prohibes","lemma":"prohiber","pos":"VER"} ,
		{"word":"projeta","word_nosc":"projeta","lemma":"projeter","pos":"VER"} ,
		{"word":"projetai","word_nosc":"projetai","lemma":"projeter","pos":"VER"} ,
		{"word":"projetaient","word_nosc":"projetaient","lemma":"projeter","pos":"VER"} ,
		{"word":"projetais","word_nosc":"projetais","lemma":"projeter","pos":"VER"} ,
		{"word":"projetait","word_nosc":"projetait","lemma":"projeter","pos":"VER"} ,
		{"word":"projetant","word_nosc":"projetant","lemma":"projeter","pos":"VER"} ,
		{"word":"projeter","word_nosc":"projeter","lemma":"projeter","pos":"VER"} ,
		{"word":"projetez","word_nosc":"projetez","lemma":"projeter","pos":"VER"} ,
		{"word":"projetiez","word_nosc":"projetiez","lemma":"projeter","pos":"VER"} ,
		{"word":"projetions","word_nosc":"projetions","lemma":"projeter","pos":"VER"} ,
		{"word":"projetons","word_nosc":"projetons","lemma":"projeter","pos":"VER"} ,
		{"word":"projette","word_nosc":"projette","lemma":"projeter","pos":"VER"} ,
		{"word":"projettent","word_nosc":"projettent","lemma":"projeter","pos":"VER"} ,
		{"word":"projettera","word_nosc":"projettera","lemma":"projeter","pos":"VER"} ,
		{"word":"projetterait","word_nosc":"projetterait","lemma":"projeter","pos":"VER"} ,
		{"word":"projetteriez","word_nosc":"projetteriez","lemma":"projeter","pos":"VER"} ,
		{"word":"projetterons","word_nosc":"projetterons","lemma":"projeter","pos":"VER"} ,
		{"word":"projettes","word_nosc":"projettes","lemma":"projeter","pos":"VER"} ,
		{"word":"projetâmes","word_nosc":"projetames","lemma":"projeter","pos":"VER"} ,
		{"word":"projetât","word_nosc":"projetat","lemma":"projeter","pos":"VER"} ,
		{"word":"projetèrent","word_nosc":"projeterent","lemma":"projeter","pos":"VER"} ,
		{"word":"projeté","word_nosc":"projete","lemma":"projeter","pos":"VER"} ,
		{"word":"projetée","word_nosc":"projetee","lemma":"projeter","pos":"VER"} ,
		{"word":"projetées","word_nosc":"projetees","lemma":"projeter","pos":"VER"} ,
		{"word":"projetés","word_nosc":"projetes","lemma":"projeter","pos":"VER"} ,
		{"word":"prolifère","word_nosc":"prolifere","lemma":"proliférer","pos":"VER"} ,
		{"word":"prolifèrent","word_nosc":"proliferent","lemma":"proliférer","pos":"VER"} ,
		{"word":"proliféraient","word_nosc":"proliferaient","lemma":"proliférer","pos":"VER"} ,
		{"word":"proliférait","word_nosc":"proliferait","lemma":"proliférer","pos":"VER"} ,
		{"word":"proliférant","word_nosc":"proliferant","lemma":"proliférer","pos":"VER"} ,
		{"word":"proliférer","word_nosc":"proliferer","lemma":"proliférer","pos":"VER"} ,
		{"word":"proliférera","word_nosc":"proliferera","lemma":"proliférer","pos":"VER"} ,
		{"word":"proliféré","word_nosc":"prolifere","lemma":"proliférer","pos":"VER"} ,
		{"word":"prolonge","word_nosc":"prolonge","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongea","word_nosc":"prolongea","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongeaient","word_nosc":"prolongeaient","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongeais","word_nosc":"prolongeais","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongeait","word_nosc":"prolongeait","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongeant","word_nosc":"prolongeant","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongent","word_nosc":"prolongent","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolonger","word_nosc":"prolonger","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongera","word_nosc":"prolongera","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongeraient","word_nosc":"prolongeraient","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongerait","word_nosc":"prolongerait","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongerons","word_nosc":"prolongerons","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolonges","word_nosc":"prolonges","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongez","word_nosc":"prolongez","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongeât","word_nosc":"prolongeat","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongèrent","word_nosc":"prolongerent","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongé","word_nosc":"prolonge","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongée","word_nosc":"prolongee","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongées","word_nosc":"prolongees","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolongés","word_nosc":"prolonges","lemma":"prolonger","pos":"VER"} ,
		{"word":"prolétariser","word_nosc":"proletariser","lemma":"prolétariser","pos":"VER"} ,
		{"word":"promena","word_nosc":"promena","lemma":"promener","pos":"VER"} ,
		{"word":"promenai","word_nosc":"promenai","lemma":"promener","pos":"VER"} ,
		{"word":"promenaient","word_nosc":"promenaient","lemma":"promener","pos":"VER"} ,
		{"word":"promenais","word_nosc":"promenais","lemma":"promener","pos":"VER"} ,
		{"word":"promenait","word_nosc":"promenait","lemma":"promener","pos":"VER"} ,
		{"word":"promenant","word_nosc":"promenant","lemma":"promener","pos":"VER"} ,
		{"word":"promener","word_nosc":"promener","lemma":"promener","pos":"VER"} ,
		{"word":"promenez","word_nosc":"promenez","lemma":"promener","pos":"VER"} ,
		{"word":"promeniez","word_nosc":"promeniez","lemma":"promener","pos":"VER"} ,
		{"word":"promenions","word_nosc":"promenions","lemma":"promener","pos":"VER"} ,
		{"word":"promenons","word_nosc":"promenons","lemma":"promener","pos":"VER"} ,
		{"word":"promenâmes","word_nosc":"promenames","lemma":"promener","pos":"VER"} ,
		{"word":"promenât","word_nosc":"promenat","lemma":"promener","pos":"VER"} ,
		{"word":"promenèrent","word_nosc":"promenerent","lemma":"promener","pos":"VER"} ,
		{"word":"promené","word_nosc":"promene","lemma":"promener","pos":"VER"} ,
		{"word":"promenée","word_nosc":"promenee","lemma":"promener","pos":"VER"} ,
		{"word":"promenées","word_nosc":"promenees","lemma":"promener","pos":"VER"} ,
		{"word":"promenés","word_nosc":"promenes","lemma":"promener","pos":"VER"} ,
		{"word":"promet","word_nosc":"promet","lemma":"promettre","pos":"VER"} ,
		{"word":"promets","word_nosc":"promets","lemma":"promettre","pos":"VER"} ,
		{"word":"promettaient","word_nosc":"promettaient","lemma":"promettre","pos":"VER"} ,
		{"word":"promettais","word_nosc":"promettais","lemma":"promettre","pos":"VER"} ,
		{"word":"promettait","word_nosc":"promettait","lemma":"promettre","pos":"VER"} ,
		{"word":"promettant","word_nosc":"promettant","lemma":"promettre","pos":"VER"} ,
		{"word":"promette","word_nosc":"promette","lemma":"promettre","pos":"VER"} ,
		{"word":"promettent","word_nosc":"promettent","lemma":"promettre","pos":"VER"} ,
		{"word":"promettes","word_nosc":"promettes","lemma":"promettre","pos":"VER"} ,
		{"word":"promettez","word_nosc":"promettez","lemma":"promettre","pos":"VER"} ,
		{"word":"promettiez","word_nosc":"promettiez","lemma":"promettre","pos":"VER"} ,
		{"word":"promettons","word_nosc":"promettons","lemma":"promettre","pos":"VER"} ,
		{"word":"promettra","word_nosc":"promettra","lemma":"promettre","pos":"VER"} ,
		{"word":"promettrai","word_nosc":"promettrai","lemma":"promettre","pos":"VER"} ,
		{"word":"promettraient","word_nosc":"promettraient","lemma":"promettre","pos":"VER"} ,
		{"word":"promettrais","word_nosc":"promettrais","lemma":"promettre","pos":"VER"} ,
		{"word":"promettrait","word_nosc":"promettrait","lemma":"promettre","pos":"VER"} ,
		{"word":"promettras","word_nosc":"promettras","lemma":"promettre","pos":"VER"} ,
		{"word":"promettre","word_nosc":"promettre","lemma":"promettre","pos":"VER"} ,
		{"word":"promettriez","word_nosc":"promettriez","lemma":"promettre","pos":"VER"} ,
		{"word":"promettront","word_nosc":"promettront","lemma":"promettre","pos":"VER"} ,
		{"word":"promeut","word_nosc":"promeut","lemma":"promouvoir","pos":"VER"} ,
		{"word":"promirent","word_nosc":"promirent","lemma":"promettre","pos":"VER"} ,
		{"word":"promis","word_nosc":"promis","lemma":"promettre","pos":"VER"} ,
		{"word":"promise","word_nosc":"promise","lemma":"promettre","pos":"VER"} ,
		{"word":"promises","word_nosc":"promises","lemma":"promettre","pos":"VER"} ,
		{"word":"promit","word_nosc":"promit","lemma":"promettre","pos":"VER"} ,
		{"word":"promotionnait","word_nosc":"promotionnait","lemma":"promotionner","pos":"VER"} ,
		{"word":"promouvaient","word_nosc":"promouvaient","lemma":"promouvoir","pos":"VER"} ,
		{"word":"promouvez","word_nosc":"promouvez","lemma":"promouvoir","pos":"VER"} ,
		{"word":"promouvoir","word_nosc":"promouvoir","lemma":"promouvoir","pos":"VER"} ,
		{"word":"promu","word_nosc":"promu","lemma":"promouvoir","pos":"VER"} ,
		{"word":"promue","word_nosc":"promue","lemma":"promouvoir","pos":"VER"} ,
		{"word":"promues","word_nosc":"promues","lemma":"promouvoir","pos":"VER"} ,
		{"word":"promulguait","word_nosc":"promulguait","lemma":"promulguer","pos":"VER"} ,
		{"word":"promulguant","word_nosc":"promulguant","lemma":"promulguer","pos":"VER"} ,
		{"word":"promulguer","word_nosc":"promulguer","lemma":"promulguer","pos":"VER"} ,
		{"word":"promulguera","word_nosc":"promulguera","lemma":"promulguer","pos":"VER"} ,
		{"word":"promulgué","word_nosc":"promulgue","lemma":"promulguer","pos":"VER"} ,
		{"word":"promulguée","word_nosc":"promulguee","lemma":"promulguer","pos":"VER"} ,
		{"word":"promulguées","word_nosc":"promulguees","lemma":"promulguer","pos":"VER"} ,
		{"word":"promulgués","word_nosc":"promulgues","lemma":"promulguer","pos":"VER"} ,
		{"word":"promus","word_nosc":"promus","lemma":"promouvoir","pos":"VER"} ,
		{"word":"promut","word_nosc":"promut","lemma":"promouvoir","pos":"VER"} ,
		{"word":"promène","word_nosc":"promene","lemma":"promener","pos":"VER"} ,
		{"word":"promènent","word_nosc":"promenent","lemma":"promener","pos":"VER"} ,
		{"word":"promènera","word_nosc":"promenera","lemma":"promener","pos":"VER"} ,
		{"word":"promènerai","word_nosc":"promenerai","lemma":"promener","pos":"VER"} ,
		{"word":"promèneraient","word_nosc":"promeneraient","lemma":"promener","pos":"VER"} ,
		{"word":"promènerais","word_nosc":"promenerais","lemma":"promener","pos":"VER"} ,
		{"word":"promènerait","word_nosc":"promenerait","lemma":"promener","pos":"VER"} ,
		{"word":"promèneras","word_nosc":"promeneras","lemma":"promener","pos":"VER"} ,
		{"word":"promènerez","word_nosc":"promenerez","lemma":"promener","pos":"VER"} ,
		{"word":"promènerions","word_nosc":"promenerions","lemma":"promener","pos":"VER"} ,
		{"word":"promènerons","word_nosc":"promenerons","lemma":"promener","pos":"VER"} ,
		{"word":"promènes","word_nosc":"promenes","lemma":"promener","pos":"VER"} ,
		{"word":"promîmes","word_nosc":"promimes","lemma":"promettre","pos":"VER"} ,
		{"word":"promît","word_nosc":"promit","lemma":"promettre","pos":"VER"} ,
		{"word":"prononce","word_nosc":"prononce","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononcent","word_nosc":"prononcent","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononcer","word_nosc":"prononcer","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononcera","word_nosc":"prononcera","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononcerai","word_nosc":"prononcerai","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononceraient","word_nosc":"prononceraient","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononcerais","word_nosc":"prononcerais","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononcerait","word_nosc":"prononcerait","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononceras","word_nosc":"prononceras","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononcerez","word_nosc":"prononcerez","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononceriez","word_nosc":"prononceriez","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononcerons","word_nosc":"prononcerons","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononceront","word_nosc":"prononceront","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononces","word_nosc":"prononces","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononcez","word_nosc":"prononcez","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononciez","word_nosc":"prononciez","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononcions","word_nosc":"prononcions","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononcèrent","word_nosc":"prononcerent","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononcé","word_nosc":"prononce","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononcée","word_nosc":"prononcee","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononcées","word_nosc":"prononcees","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononcés","word_nosc":"prononces","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononça","word_nosc":"prononca","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononçai","word_nosc":"prononcai","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononçaient","word_nosc":"prononcaient","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononçais","word_nosc":"prononcais","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononçait","word_nosc":"prononcait","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononçant","word_nosc":"prononcant","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononças","word_nosc":"prononcas","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononçons","word_nosc":"prononcons","lemma":"prononcer","pos":"VER"} ,
		{"word":"prononçât","word_nosc":"prononcat","lemma":"prononcer","pos":"VER"} ,
		{"word":"pronostiquait","word_nosc":"pronostiquait","lemma":"pronostiquer","pos":"VER"} ,
		{"word":"pronostique","word_nosc":"pronostique","lemma":"pronostiquer","pos":"VER"} ,
		{"word":"pronostiquer","word_nosc":"pronostiquer","lemma":"pronostiquer","pos":"VER"} ,
		{"word":"pronostiquiez","word_nosc":"pronostiquiez","lemma":"pronostiquer","pos":"VER"} ,
		{"word":"propage","word_nosc":"propage","lemma":"propager","pos":"VER"} ,
		{"word":"propagea","word_nosc":"propagea","lemma":"propager","pos":"VER"} ,
		{"word":"propageaient","word_nosc":"propageaient","lemma":"propager","pos":"VER"} ,
		{"word":"propageait","word_nosc":"propageait","lemma":"propager","pos":"VER"} ,
		{"word":"propageant","word_nosc":"propageant","lemma":"propager","pos":"VER"} ,
		{"word":"propagent","word_nosc":"propagent","lemma":"propager","pos":"VER"} ,
		{"word":"propager","word_nosc":"propager","lemma":"propager","pos":"VER"} ,
		{"word":"propagera","word_nosc":"propagera","lemma":"propager","pos":"VER"} ,
		{"word":"propageraient","word_nosc":"propageraient","lemma":"propager","pos":"VER"} ,
		{"word":"propagerait","word_nosc":"propagerait","lemma":"propager","pos":"VER"} ,
		{"word":"propagez","word_nosc":"propagez","lemma":"propager","pos":"VER"} ,
		{"word":"propageât","word_nosc":"propageat","lemma":"propager","pos":"VER"} ,
		{"word":"propagèrent","word_nosc":"propagerent","lemma":"propager","pos":"VER"} ,
		{"word":"propagé","word_nosc":"propage","lemma":"propager","pos":"VER"} ,
		{"word":"propagée","word_nosc":"propagee","lemma":"propager","pos":"VER"} ,
		{"word":"propagées","word_nosc":"propagees","lemma":"propager","pos":"VER"} ,
		{"word":"propagés","word_nosc":"propages","lemma":"propager","pos":"VER"} ,
		{"word":"prophétisaient","word_nosc":"prophetisaient","lemma":"prophétiser","pos":"VER"} ,
		{"word":"prophétisait","word_nosc":"prophetisait","lemma":"prophétiser","pos":"VER"} ,
		{"word":"prophétisant","word_nosc":"prophetisant","lemma":"prophétiser","pos":"VER"} ,
		{"word":"prophétise","word_nosc":"prophetise","lemma":"prophétiser","pos":"VER"} ,
		{"word":"prophétisent","word_nosc":"prophetisent","lemma":"prophétiser","pos":"VER"} ,
		{"word":"prophétiser","word_nosc":"prophetiser","lemma":"prophétiser","pos":"VER"} ,
		{"word":"prophétisez","word_nosc":"prophetisez","lemma":"prophétiser","pos":"VER"} ,
		{"word":"prophétisé","word_nosc":"prophetise","lemma":"prophétiser","pos":"VER"} ,
		{"word":"proportionner","word_nosc":"proportionner","lemma":"proportionner","pos":"VER"} ,
		{"word":"proportionné","word_nosc":"proportionne","lemma":"proportionner","pos":"VER"} ,
		{"word":"proportionnée","word_nosc":"proportionnee","lemma":"proportionner","pos":"VER"} ,
		{"word":"proportionnées","word_nosc":"proportionnees","lemma":"proportionner","pos":"VER"} ,
		{"word":"proportionnés","word_nosc":"proportionnes","lemma":"proportionner","pos":"VER"} ,
		{"word":"proposa","word_nosc":"proposa","lemma":"proposer","pos":"VER"} ,
		{"word":"proposai","word_nosc":"proposai","lemma":"proposer","pos":"VER"} ,
		{"word":"proposaient","word_nosc":"proposaient","lemma":"proposer","pos":"VER"} ,
		{"word":"proposais","word_nosc":"proposais","lemma":"proposer","pos":"VER"} ,
		{"word":"proposait","word_nosc":"proposait","lemma":"proposer","pos":"VER"} ,
		{"word":"proposant","word_nosc":"proposant","lemma":"proposer","pos":"VER"} ,
		{"word":"propose","word_nosc":"propose","lemma":"proposer","pos":"VER"} ,
		{"word":"proposent","word_nosc":"proposent","lemma":"proposer","pos":"VER"} ,
		{"word":"proposer","word_nosc":"proposer","lemma":"proposer","pos":"VER"} ,
		{"word":"proposera","word_nosc":"proposera","lemma":"proposer","pos":"VER"} ,
		{"word":"proposerai","word_nosc":"proposerai","lemma":"proposer","pos":"VER"} ,
		{"word":"proposeraient","word_nosc":"proposeraient","lemma":"proposer","pos":"VER"} ,
		{"word":"proposerais","word_nosc":"proposerais","lemma":"proposer","pos":"VER"} ,
		{"word":"proposerait","word_nosc":"proposerait","lemma":"proposer","pos":"VER"} ,
		{"word":"proposeras","word_nosc":"proposeras","lemma":"proposer","pos":"VER"} ,
		{"word":"proposerez","word_nosc":"proposerez","lemma":"proposer","pos":"VER"} ,
		{"word":"proposeriez","word_nosc":"proposeriez","lemma":"proposer","pos":"VER"} ,
		{"word":"proposerions","word_nosc":"proposerions","lemma":"proposer","pos":"VER"} ,
		{"word":"proposerons","word_nosc":"proposerons","lemma":"proposer","pos":"VER"} ,
		{"word":"proposeront","word_nosc":"proposeront","lemma":"proposer","pos":"VER"} ,
		{"word":"proposes","word_nosc":"proposes","lemma":"proposer","pos":"VER"} ,
		{"word":"proposez","word_nosc":"proposez","lemma":"proposer","pos":"VER"} ,
		{"word":"proposiez","word_nosc":"proposiez","lemma":"proposer","pos":"VER"} ,
		{"word":"proposions","word_nosc":"proposions","lemma":"proposer","pos":"VER"} ,
		{"word":"proposons","word_nosc":"proposons","lemma":"proposer","pos":"VER"} ,
		{"word":"proposâmes","word_nosc":"proposames","lemma":"proposer","pos":"VER"} ,
		{"word":"proposât","word_nosc":"proposat","lemma":"proposer","pos":"VER"} ,
		{"word":"proposèrent","word_nosc":"proposerent","lemma":"proposer","pos":"VER"} ,
		{"word":"proposé","word_nosc":"propose","lemma":"proposer","pos":"VER"} ,
		{"word":"proposée","word_nosc":"proposee","lemma":"proposer","pos":"VER"} ,
		{"word":"proposées","word_nosc":"proposees","lemma":"proposer","pos":"VER"} ,
		{"word":"proposés","word_nosc":"proposes","lemma":"proposer","pos":"VER"} ,
		{"word":"propulsa","word_nosc":"propulsa","lemma":"propulser","pos":"VER"} ,
		{"word":"propulsaient","word_nosc":"propulsaient","lemma":"propulser","pos":"VER"} ,
		{"word":"propulsais","word_nosc":"propulsais","lemma":"propulser","pos":"VER"} ,
		{"word":"propulsait","word_nosc":"propulsait","lemma":"propulser","pos":"VER"} ,
		{"word":"propulsant","word_nosc":"propulsant","lemma":"propulser","pos":"VER"} ,
		{"word":"propulse","word_nosc":"propulse","lemma":"propulser","pos":"VER"} ,
		{"word":"propulsent","word_nosc":"propulsent","lemma":"propulser","pos":"VER"} ,
		{"word":"propulser","word_nosc":"propulser","lemma":"propulser","pos":"VER"} ,
		{"word":"propulsera","word_nosc":"propulsera","lemma":"propulser","pos":"VER"} ,
		{"word":"propulserait","word_nosc":"propulserait","lemma":"propulser","pos":"VER"} ,
		{"word":"propulsât","word_nosc":"propulsat","lemma":"propulser","pos":"VER"} ,
		{"word":"propulsé","word_nosc":"propulse","lemma":"propulser","pos":"VER"} ,
		{"word":"propulsée","word_nosc":"propulsee","lemma":"propulser","pos":"VER"} ,
		{"word":"propulsées","word_nosc":"propulsees","lemma":"propulser","pos":"VER"} ,
		{"word":"propulsés","word_nosc":"propulses","lemma":"propulser","pos":"VER"} ,
		{"word":"proroger","word_nosc":"proroger","lemma":"proroger","pos":"VER"} ,
		{"word":"prorogé","word_nosc":"proroge","lemma":"proroger","pos":"VER"} ,
		{"word":"proscrire","word_nosc":"proscrire","lemma":"proscrire","pos":"VER"} ,
		{"word":"proscrit","word_nosc":"proscrit","lemma":"proscrire","pos":"VER"} ,
		{"word":"proscrite","word_nosc":"proscrite","lemma":"proscrire","pos":"VER"} ,
		{"word":"proscrites","word_nosc":"proscrites","lemma":"proscrire","pos":"VER"} ,
		{"word":"proscrits","word_nosc":"proscrits","lemma":"proscrire","pos":"VER"} ,
		{"word":"proscrivait","word_nosc":"proscrivait","lemma":"proscrire","pos":"VER"} ,
		{"word":"proscrivant","word_nosc":"proscrivant","lemma":"proscrire","pos":"VER"} ,
		{"word":"proscrivit","word_nosc":"proscrivit","lemma":"proscrire","pos":"VER"} ,
		{"word":"prospectais","word_nosc":"prospectais","lemma":"prospecter","pos":"VER"} ,
		{"word":"prospectait","word_nosc":"prospectait","lemma":"prospecter","pos":"VER"} ,
		{"word":"prospectant","word_nosc":"prospectant","lemma":"prospecter","pos":"VER"} ,
		{"word":"prospecte","word_nosc":"prospecte","lemma":"prospecter","pos":"VER"} ,
		{"word":"prospecter","word_nosc":"prospecter","lemma":"prospecter","pos":"VER"} ,
		{"word":"prospections","word_nosc":"prospections","lemma":"prospecter","pos":"VER"} ,
		{"word":"prospecté","word_nosc":"prospecte","lemma":"prospecter","pos":"VER"} ,
		{"word":"prospère","word_nosc":"prospere","lemma":"prospérer","pos":"VER"} ,
		{"word":"prospèrent","word_nosc":"prosperent","lemma":"prospérer","pos":"VER"} ,
		{"word":"prospères","word_nosc":"prosperes","lemma":"prospérer","pos":"VER"} ,
		{"word":"prospéra","word_nosc":"prospera","lemma":"prospérer","pos":"VER"} ,
		{"word":"prospéraient","word_nosc":"prosperaient","lemma":"prospérer","pos":"VER"} ,
		{"word":"prospérait","word_nosc":"prosperait","lemma":"prospérer","pos":"VER"} ,
		{"word":"prospérer","word_nosc":"prosperer","lemma":"prospérer","pos":"VER"} ,
		{"word":"prospérera","word_nosc":"prosperera","lemma":"prospérer","pos":"VER"} ,
		{"word":"prospéreraient","word_nosc":"prospereraient","lemma":"prospérer","pos":"VER"} ,
		{"word":"prospérons","word_nosc":"prosperons","lemma":"prospérer","pos":"VER"} ,
		{"word":"prospérèrent","word_nosc":"prospererent","lemma":"prospérer","pos":"VER"} ,
		{"word":"prospéré","word_nosc":"prospere","lemma":"prospérer","pos":"VER"} ,
		{"word":"prosterna","word_nosc":"prosterna","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosternaient","word_nosc":"prosternaient","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosternais","word_nosc":"prosternais","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosternait","word_nosc":"prosternait","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosternant","word_nosc":"prosternant","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosterne","word_nosc":"prosterne","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosternent","word_nosc":"prosternent","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosterner","word_nosc":"prosterner","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosternera","word_nosc":"prosternera","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosternerait","word_nosc":"prosternerait","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosterneras","word_nosc":"prosterneras","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosternerons","word_nosc":"prosternerons","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosterneront","word_nosc":"prosterneront","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosternez","word_nosc":"prosternez","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosternons","word_nosc":"prosternons","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosternèrent","word_nosc":"prosternerent","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosterné","word_nosc":"prosterne","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosternée","word_nosc":"prosternee","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosternées","word_nosc":"prosternees","lemma":"prosterner","pos":"VER"} ,
		{"word":"prosternés","word_nosc":"prosternes","lemma":"prosterner","pos":"VER"} ,
		{"word":"prostitua","word_nosc":"prostitua","lemma":"prostituer","pos":"VER"} ,
		{"word":"prostituais","word_nosc":"prostituais","lemma":"prostituer","pos":"VER"} ,
		{"word":"prostituait","word_nosc":"prostituait","lemma":"prostituer","pos":"VER"} ,
		{"word":"prostituant","word_nosc":"prostituant","lemma":"prostituer","pos":"VER"} ,
		{"word":"prostitue","word_nosc":"prostitue","lemma":"prostituer","pos":"VER"} ,
		{"word":"prostituent","word_nosc":"prostituent","lemma":"prostituer","pos":"VER"} ,
		{"word":"prostituer","word_nosc":"prostituer","lemma":"prostituer","pos":"VER"} ,
		{"word":"prostituerais","word_nosc":"prostituerais","lemma":"prostituer","pos":"VER"} ,
		{"word":"prostitué","word_nosc":"prostitue","lemma":"prostituer","pos":"VER"} ,
		{"word":"prostituée","word_nosc":"prostituee","lemma":"prostituer","pos":"VER"} ,
		{"word":"prostituées","word_nosc":"prostituees","lemma":"prostituer","pos":"VER"} ,
		{"word":"prostitués","word_nosc":"prostitues","lemma":"prostituer","pos":"VER"} ,
		{"word":"prostrant","word_nosc":"prostrant","lemma":"prostrer","pos":"VER"} ,
		{"word":"protesta","word_nosc":"protesta","lemma":"protester","pos":"VER"} ,
		{"word":"protestai","word_nosc":"protestai","lemma":"protester","pos":"VER"} ,
		{"word":"protestaient","word_nosc":"protestaient","lemma":"protester","pos":"VER"} ,
		{"word":"protestais","word_nosc":"protestais","lemma":"protester","pos":"VER"} ,
		{"word":"protestait","word_nosc":"protestait","lemma":"protester","pos":"VER"} ,
		{"word":"protestant","word_nosc":"protestant","lemma":"protester","pos":"VER"} ,
		{"word":"proteste","word_nosc":"proteste","lemma":"protester","pos":"VER"} ,
		{"word":"protestent","word_nosc":"protestent","lemma":"protester","pos":"VER"} ,
		{"word":"protester","word_nosc":"protester","lemma":"protester","pos":"VER"} ,
		{"word":"protestera","word_nosc":"protestera","lemma":"protester","pos":"VER"} ,
		{"word":"protesterai","word_nosc":"protesterai","lemma":"protester","pos":"VER"} ,
		{"word":"protesterais","word_nosc":"protesterais","lemma":"protester","pos":"VER"} ,
		{"word":"protesterait","word_nosc":"protesterait","lemma":"protester","pos":"VER"} ,
		{"word":"protesterons","word_nosc":"protesterons","lemma":"protester","pos":"VER"} ,
		{"word":"protestes","word_nosc":"protestes","lemma":"protester","pos":"VER"} ,
		{"word":"protestez","word_nosc":"protestez","lemma":"protester","pos":"VER"} ,
		{"word":"protestions","word_nosc":"protestions","lemma":"protester","pos":"VER"} ,
		{"word":"protestons","word_nosc":"protestons","lemma":"protester","pos":"VER"} ,
		{"word":"protestâmes","word_nosc":"protestames","lemma":"protester","pos":"VER"} ,
		{"word":"protestât","word_nosc":"protestat","lemma":"protester","pos":"VER"} ,
		{"word":"protestèrent","word_nosc":"protesterent","lemma":"protester","pos":"VER"} ,
		{"word":"protesté","word_nosc":"proteste","lemma":"protester","pos":"VER"} ,
		{"word":"protestés","word_nosc":"protestes","lemma":"protester","pos":"VER"} ,
		{"word":"protège","word_nosc":"protege","lemma":"protéger","pos":"VER"} ,
		{"word":"protègent","word_nosc":"protegent","lemma":"protéger","pos":"VER"} ,
		{"word":"protèges","word_nosc":"proteges","lemma":"protéger","pos":"VER"} ,
		{"word":"protégea","word_nosc":"protegea","lemma":"protéger","pos":"VER"} ,
		{"word":"protégeai","word_nosc":"protegeai","lemma":"protéger","pos":"VER"} ,
		{"word":"protégeaient","word_nosc":"protegeaient","lemma":"protéger","pos":"VER"} ,
		{"word":"protégeais","word_nosc":"protegeais","lemma":"protéger","pos":"VER"} ,
		{"word":"protégeait","word_nosc":"protegeait","lemma":"protéger","pos":"VER"} ,
		{"word":"protégeant","word_nosc":"protegeant","lemma":"protéger","pos":"VER"} ,
		{"word":"protégeons","word_nosc":"protegeons","lemma":"protéger","pos":"VER"} ,
		{"word":"protéger","word_nosc":"proteger","lemma":"protéger","pos":"VER"} ,
		{"word":"protégera","word_nosc":"protegera","lemma":"protéger","pos":"VER"} ,
		{"word":"protégerai","word_nosc":"protegerai","lemma":"protéger","pos":"VER"} ,
		{"word":"protégeraient","word_nosc":"protegeraient","lemma":"protéger","pos":"VER"} ,
		{"word":"protégerais","word_nosc":"protegerais","lemma":"protéger","pos":"VER"} ,
		{"word":"protégerait","word_nosc":"protegerait","lemma":"protéger","pos":"VER"} ,
		{"word":"protégeras","word_nosc":"protegeras","lemma":"protéger","pos":"VER"} ,
		{"word":"protégerez","word_nosc":"protegerez","lemma":"protéger","pos":"VER"} ,
		{"word":"protégeriez","word_nosc":"protegeriez","lemma":"protéger","pos":"VER"} ,
		{"word":"protégerons","word_nosc":"protegerons","lemma":"protéger","pos":"VER"} ,
		{"word":"protégeront","word_nosc":"protegeront","lemma":"protéger","pos":"VER"} ,
		{"word":"protégez","word_nosc":"protegez","lemma":"protéger","pos":"VER"} ,
		{"word":"protégeât","word_nosc":"protegeat","lemma":"protéger","pos":"VER"} ,
		{"word":"protégiez","word_nosc":"protegiez","lemma":"protéger","pos":"VER"} ,
		{"word":"protégions","word_nosc":"protegions","lemma":"protéger","pos":"VER"} ,
		{"word":"protégé","word_nosc":"protege","lemma":"protéger","pos":"VER"} ,
		{"word":"protégée","word_nosc":"protegee","lemma":"protéger","pos":"VER"} ,
		{"word":"protégées","word_nosc":"protegees","lemma":"protéger","pos":"VER"} ,
		{"word":"protégés","word_nosc":"proteges","lemma":"protéger","pos":"VER"} ,
		{"word":"prouva","word_nosc":"prouva","lemma":"prouver","pos":"VER"} ,
		{"word":"prouvai","word_nosc":"prouvai","lemma":"prouver","pos":"VER"} ,
		{"word":"prouvaient","word_nosc":"prouvaient","lemma":"prouver","pos":"VER"} ,
		{"word":"prouvais","word_nosc":"prouvais","lemma":"prouver","pos":"VER"} ,
		{"word":"prouvait","word_nosc":"prouvait","lemma":"prouver","pos":"VER"} ,
		{"word":"prouvant","word_nosc":"prouvant","lemma":"prouver","pos":"VER"} ,
		{"word":"prouve","word_nosc":"prouve","lemma":"prouver","pos":"VER"} ,
		{"word":"prouvent","word_nosc":"prouvent","lemma":"prouver","pos":"VER"} ,
		{"word":"prouver","word_nosc":"prouver","lemma":"prouver","pos":"VER"} ,
		{"word":"prouvera","word_nosc":"prouvera","lemma":"prouver","pos":"VER"} ,
		{"word":"prouverai","word_nosc":"prouverai","lemma":"prouver","pos":"VER"} ,
		{"word":"prouveraient","word_nosc":"prouveraient","lemma":"prouver","pos":"VER"} ,
		{"word":"prouverait","word_nosc":"prouverait","lemma":"prouver","pos":"VER"} ,
		{"word":"prouveras","word_nosc":"prouveras","lemma":"prouver","pos":"VER"} ,
		{"word":"prouverez","word_nosc":"prouverez","lemma":"prouver","pos":"VER"} ,
		{"word":"prouverons","word_nosc":"prouverons","lemma":"prouver","pos":"VER"} ,
		{"word":"prouveront","word_nosc":"prouveront","lemma":"prouver","pos":"VER"} ,
		{"word":"prouves","word_nosc":"prouves","lemma":"prouver","pos":"VER"} ,
		{"word":"prouvez","word_nosc":"prouvez","lemma":"prouver","pos":"VER"} ,
		{"word":"prouviez","word_nosc":"prouviez","lemma":"prouver","pos":"VER"} ,
		{"word":"prouvons","word_nosc":"prouvons","lemma":"prouver","pos":"VER"} ,
		{"word":"prouvèrent","word_nosc":"prouverent","lemma":"prouver","pos":"VER"} ,
		{"word":"prouvé","word_nosc":"prouve","lemma":"prouver","pos":"VER"} ,
		{"word":"prouvée","word_nosc":"prouvee","lemma":"prouver","pos":"VER"} ,
		{"word":"prouvées","word_nosc":"prouvees","lemma":"prouver","pos":"VER"} ,
		{"word":"prouvés","word_nosc":"prouves","lemma":"prouver","pos":"VER"} ,
		{"word":"provenaient","word_nosc":"provenaient","lemma":"provenir","pos":"VER"} ,
		{"word":"provenait","word_nosc":"provenait","lemma":"provenir","pos":"VER"} ,
		{"word":"provenant","word_nosc":"provenant","lemma":"provenir","pos":"VER"} ,
		{"word":"provenir","word_nosc":"provenir","lemma":"provenir","pos":"VER"} ,
		{"word":"provenu","word_nosc":"provenu","lemma":"provenir","pos":"VER"} ,
		{"word":"proviendrait","word_nosc":"proviendrait","lemma":"provenir","pos":"VER"} ,
		{"word":"provienne","word_nosc":"provienne","lemma":"provenir","pos":"VER"} ,
		{"word":"proviennent","word_nosc":"proviennent","lemma":"provenir","pos":"VER"} ,
		{"word":"provient","word_nosc":"provient","lemma":"provenir","pos":"VER"} ,
		{"word":"provisionner","word_nosc":"provisionner","lemma":"provisionner","pos":"VER"} ,
		{"word":"provisionnons","word_nosc":"provisionnons","lemma":"provisionner","pos":"VER"} ,
		{"word":"provoqua","word_nosc":"provoqua","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquai","word_nosc":"provoquai","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquaient","word_nosc":"provoquaient","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquais","word_nosc":"provoquais","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquait","word_nosc":"provoquait","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquant","word_nosc":"provoquant","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoque","word_nosc":"provoque","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquent","word_nosc":"provoquent","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquer","word_nosc":"provoquer","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquera","word_nosc":"provoquera","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquerai","word_nosc":"provoquerai","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoqueraient","word_nosc":"provoqueraient","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquerais","word_nosc":"provoquerais","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquerait","word_nosc":"provoquerait","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquerez","word_nosc":"provoquerez","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoqueriez","word_nosc":"provoqueriez","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquerions","word_nosc":"provoquerions","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquerons","word_nosc":"provoquerons","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoqueront","word_nosc":"provoqueront","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoques","word_nosc":"provoques","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquez","word_nosc":"provoquez","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquions","word_nosc":"provoquions","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquons","word_nosc":"provoquons","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquât","word_nosc":"provoquat","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquèrent","word_nosc":"provoquerent","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoqué","word_nosc":"provoque","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquée","word_nosc":"provoquee","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoquées","word_nosc":"provoquees","lemma":"provoquer","pos":"VER"} ,
		{"word":"provoqués","word_nosc":"provoques","lemma":"provoquer","pos":"VER"} ,
		{"word":"proéminait","word_nosc":"proeminait","lemma":"proéminer","pos":"VER"} ,
		{"word":"proémine","word_nosc":"proemine","lemma":"proéminer","pos":"VER"} ,
		{"word":"prussianisé","word_nosc":"prussianise","lemma":"prussianiser","pos":"VER"} ,
		{"word":"préchauffer","word_nosc":"prechauffer","lemma":"préchauffer","pos":"VER"} ,
		{"word":"précipita","word_nosc":"precipita","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitai","word_nosc":"precipitai","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitaient","word_nosc":"precipitaient","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitais","word_nosc":"precipitais","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitait","word_nosc":"precipitait","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitant","word_nosc":"precipitant","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipite","word_nosc":"precipite","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitent","word_nosc":"precipitent","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipiter","word_nosc":"precipiter","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitera","word_nosc":"precipitera","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipiterai","word_nosc":"precipiterai","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipiteraient","word_nosc":"precipiteraient","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipiterais","word_nosc":"precipiterais","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipiterait","word_nosc":"precipiterait","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipiteras","word_nosc":"precipiteras","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipiterez","word_nosc":"precipiterez","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipiterions","word_nosc":"precipiterions","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipiteront","word_nosc":"precipiteront","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipites","word_nosc":"precipites","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitez","word_nosc":"precipitez","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitiez","word_nosc":"precipitiez","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitions","word_nosc":"precipitions","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitons","word_nosc":"precipitons","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitâmes","word_nosc":"precipitames","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitât","word_nosc":"precipitat","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitèrent","word_nosc":"precipiterent","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipité","word_nosc":"precipite","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitée","word_nosc":"precipitee","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipitées","word_nosc":"precipitees","lemma":"précipiter","pos":"VER"} ,
		{"word":"précipités","word_nosc":"precipites","lemma":"précipiter","pos":"VER"} ,
		{"word":"précisa","word_nosc":"precisa","lemma":"préciser","pos":"VER"} ,
		{"word":"précisai","word_nosc":"precisai","lemma":"préciser","pos":"VER"} ,
		{"word":"précisaient","word_nosc":"precisaient","lemma":"préciser","pos":"VER"} ,
		{"word":"précisais","word_nosc":"precisais","lemma":"préciser","pos":"VER"} ,
		{"word":"précisait","word_nosc":"precisait","lemma":"préciser","pos":"VER"} ,
		{"word":"précisant","word_nosc":"precisant","lemma":"préciser","pos":"VER"} ,
		{"word":"précise","word_nosc":"precise","lemma":"préciser","pos":"VER"} ,
		{"word":"précisent","word_nosc":"precisent","lemma":"préciser","pos":"VER"} ,
		{"word":"préciser","word_nosc":"preciser","lemma":"préciser","pos":"VER"} ,
		{"word":"précisera","word_nosc":"precisera","lemma":"préciser","pos":"VER"} ,
		{"word":"préciserai","word_nosc":"preciserai","lemma":"préciser","pos":"VER"} ,
		{"word":"préciserais","word_nosc":"preciserais","lemma":"préciser","pos":"VER"} ,
		{"word":"préciserait","word_nosc":"preciserait","lemma":"préciser","pos":"VER"} ,
		{"word":"préciserez","word_nosc":"preciserez","lemma":"préciser","pos":"VER"} ,
		{"word":"préciseront","word_nosc":"preciseront","lemma":"préciser","pos":"VER"} ,
		{"word":"précises","word_nosc":"precises","lemma":"préciser","pos":"VER"} ,
		{"word":"précisez","word_nosc":"precisez","lemma":"préciser","pos":"VER"} ,
		{"word":"précisions","word_nosc":"precisions","lemma":"préciser","pos":"VER"} ,
		{"word":"précisons","word_nosc":"precisons","lemma":"préciser","pos":"VER"} ,
		{"word":"précisât","word_nosc":"precisat","lemma":"préciser","pos":"VER"} ,
		{"word":"précisèrent","word_nosc":"preciserent","lemma":"préciser","pos":"VER"} ,
		{"word":"précisé","word_nosc":"precise","lemma":"préciser","pos":"VER"} ,
		{"word":"précisée","word_nosc":"precisee","lemma":"préciser","pos":"VER"} ,
		{"word":"précisées","word_nosc":"precisees","lemma":"préciser","pos":"VER"} ,
		{"word":"précisés","word_nosc":"precises","lemma":"préciser","pos":"VER"} ,
		{"word":"préconisa","word_nosc":"preconisa","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconisaient","word_nosc":"preconisaient","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconisais","word_nosc":"preconisais","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconisait","word_nosc":"preconisait","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconisant","word_nosc":"preconisant","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconise","word_nosc":"preconise","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconisent","word_nosc":"preconisent","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconiser","word_nosc":"preconiser","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconiserais","word_nosc":"preconiserais","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconises","word_nosc":"preconises","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconisez","word_nosc":"preconisez","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconisiez","word_nosc":"preconisiez","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconisât","word_nosc":"preconisat","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconisé","word_nosc":"preconise","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconisée","word_nosc":"preconisee","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconisées","word_nosc":"preconisees","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconisés","word_nosc":"preconises","lemma":"préconiser","pos":"VER"} ,
		{"word":"préconçues","word_nosc":"preconcues","lemma":"préconcevoir","pos":"VER"} ,
		{"word":"précède","word_nosc":"precede","lemma":"précéder","pos":"VER"} ,
		{"word":"précèdent","word_nosc":"precedent","lemma":"précéder","pos":"VER"} ,
		{"word":"précéda","word_nosc":"preceda","lemma":"précéder","pos":"VER"} ,
		{"word":"précédai","word_nosc":"precedai","lemma":"précéder","pos":"VER"} ,
		{"word":"précédaient","word_nosc":"precedaient","lemma":"précéder","pos":"VER"} ,
		{"word":"précédais","word_nosc":"precedais","lemma":"précéder","pos":"VER"} ,
		{"word":"précédait","word_nosc":"precedait","lemma":"précéder","pos":"VER"} ,
		{"word":"précédant","word_nosc":"precedant","lemma":"précéder","pos":"VER"} ,
		{"word":"précédassent","word_nosc":"precedassent","lemma":"précéder","pos":"VER"} ,
		{"word":"précéder","word_nosc":"preceder","lemma":"précéder","pos":"VER"} ,
		{"word":"précédera","word_nosc":"precedera","lemma":"précéder","pos":"VER"} ,
		{"word":"précéderai","word_nosc":"precederai","lemma":"précéder","pos":"VER"} ,
		{"word":"précéderaient","word_nosc":"precederaient","lemma":"précéder","pos":"VER"} ,
		{"word":"précéderait","word_nosc":"precederait","lemma":"précéder","pos":"VER"} ,
		{"word":"précéderions","word_nosc":"precederions","lemma":"précéder","pos":"VER"} ,
		{"word":"précédez","word_nosc":"precedez","lemma":"précéder","pos":"VER"} ,
		{"word":"précédons","word_nosc":"precedons","lemma":"précéder","pos":"VER"} ,
		{"word":"précédèrent","word_nosc":"precederent","lemma":"précéder","pos":"VER"} ,
		{"word":"précédé","word_nosc":"precede","lemma":"précéder","pos":"VER"} ,
		{"word":"précédée","word_nosc":"precedee","lemma":"précéder","pos":"VER"} ,
		{"word":"précédées","word_nosc":"precedees","lemma":"précéder","pos":"VER"} ,
		{"word":"précédés","word_nosc":"precedes","lemma":"précéder","pos":"VER"} ,
		{"word":"prédestinaient","word_nosc":"predestinaient","lemma":"prédestiner","pos":"VER"} ,
		{"word":"prédestiné","word_nosc":"predestine","lemma":"prédestiner","pos":"VER"} ,
		{"word":"prédestinée","word_nosc":"predestinee","lemma":"prédestiner","pos":"VER"} ,
		{"word":"prédestinés","word_nosc":"predestines","lemma":"prédestiner","pos":"VER"} ,
		{"word":"prédira","word_nosc":"predira","lemma":"prédire","pos":"VER"} ,
		{"word":"prédire","word_nosc":"predire","lemma":"prédire","pos":"VER"} ,
		{"word":"prédirent","word_nosc":"predirent","lemma":"prédire","pos":"VER"} ,
		{"word":"prédirez","word_nosc":"predirez","lemma":"prédire","pos":"VER"} ,
		{"word":"prédis","word_nosc":"predis","lemma":"prédire","pos":"VER"} ,
		{"word":"prédisaient","word_nosc":"predisaient","lemma":"prédire","pos":"VER"} ,
		{"word":"prédisait","word_nosc":"predisait","lemma":"prédire","pos":"VER"} ,
		{"word":"prédisant","word_nosc":"predisant","lemma":"prédire","pos":"VER"} ,
		{"word":"prédise","word_nosc":"predise","lemma":"prédire","pos":"VER"} ,
		{"word":"prédisent","word_nosc":"predisent","lemma":"prédire","pos":"VER"} ,
		{"word":"prédisez","word_nosc":"predisez","lemma":"prédire","pos":"VER"} ,
		{"word":"prédisiez","word_nosc":"predisiez","lemma":"prédire","pos":"VER"} ,
		{"word":"prédisposait","word_nosc":"predisposait","lemma":"prédisposer","pos":"VER"} ,
		{"word":"prédisposant","word_nosc":"predisposant","lemma":"prédisposer","pos":"VER"} ,
		{"word":"prédispose","word_nosc":"predispose","lemma":"prédisposer","pos":"VER"} ,
		{"word":"prédisposé","word_nosc":"predispose","lemma":"prédisposer","pos":"VER"} ,
		{"word":"prédisposée","word_nosc":"predisposee","lemma":"prédisposer","pos":"VER"} ,
		{"word":"prédisposées","word_nosc":"predisposees","lemma":"prédisposer","pos":"VER"} ,
		{"word":"prédisposés","word_nosc":"predisposes","lemma":"prédisposer","pos":"VER"} ,
		{"word":"prédit","word_nosc":"predit","lemma":"prédire","pos":"VER"} ,
		{"word":"prédite","word_nosc":"predite","lemma":"prédire","pos":"VER"} ,
		{"word":"prédominaient","word_nosc":"predominaient","lemma":"prédominer","pos":"VER"} ,
		{"word":"prédominait","word_nosc":"predominait","lemma":"prédominer","pos":"VER"} ,
		{"word":"prédominant","word_nosc":"predominant","lemma":"prédominer","pos":"VER"} ,
		{"word":"prédomine","word_nosc":"predomine","lemma":"prédominer","pos":"VER"} ,
		{"word":"prédominer","word_nosc":"predominer","lemma":"prédominer","pos":"VER"} ,
		{"word":"prédéfini","word_nosc":"predefini","lemma":"prédéfinir","pos":"VER"} ,
		{"word":"prédéfinies","word_nosc":"predefinies","lemma":"prédéfinir","pos":"VER"} ,
		{"word":"prédéterminer","word_nosc":"predeterminer","lemma":"prédéterminer","pos":"VER"} ,
		{"word":"prédéterminé","word_nosc":"predetermine","lemma":"prédéterminer","pos":"VER"} ,
		{"word":"prédéterminée","word_nosc":"predeterminee","lemma":"prédéterminer","pos":"VER"} ,
		{"word":"prédéterminées","word_nosc":"predeterminees","lemma":"prédéterminer","pos":"VER"} ,
		{"word":"prédéterminés","word_nosc":"predetermines","lemma":"prédéterminer","pos":"VER"} ,
		{"word":"préexistaient","word_nosc":"preexistaient","lemma":"préexister","pos":"VER"} ,
		{"word":"préexistait","word_nosc":"preexistait","lemma":"préexister","pos":"VER"} ,
		{"word":"préexiste","word_nosc":"preexiste","lemma":"préexister","pos":"VER"} ,
		{"word":"préexisté","word_nosc":"preexiste","lemma":"préexister","pos":"VER"} ,
		{"word":"préface","word_nosc":"preface","lemma":"préfacer","pos":"VER"} ,
		{"word":"préfacent","word_nosc":"prefacent","lemma":"préfacer","pos":"VER"} ,
		{"word":"préfacer","word_nosc":"prefacer","lemma":"préfacer","pos":"VER"} ,
		{"word":"préfacé","word_nosc":"preface","lemma":"préfacer","pos":"VER"} ,
		{"word":"préfacées","word_nosc":"prefacees","lemma":"préfacer","pos":"VER"} ,
		{"word":"préfiguraient","word_nosc":"prefiguraient","lemma":"préfigurer","pos":"VER"} ,
		{"word":"préfigurait","word_nosc":"prefigurait","lemma":"préfigurer","pos":"VER"} ,
		{"word":"préfigurant","word_nosc":"prefigurant","lemma":"préfigurer","pos":"VER"} ,
		{"word":"préfigure","word_nosc":"prefigure","lemma":"préfigurer","pos":"VER"} ,
		{"word":"préfigurent","word_nosc":"prefigurent","lemma":"préfigurer","pos":"VER"} ,
		{"word":"préfigurer","word_nosc":"prefigurer","lemma":"préfigurer","pos":"VER"} ,
		{"word":"préfiguré","word_nosc":"prefigure","lemma":"préfigurer","pos":"VER"} ,
		{"word":"préfigurée","word_nosc":"prefiguree","lemma":"préfigurer","pos":"VER"} ,
		{"word":"préfixe","word_nosc":"prefixe","lemma":"préfixer","pos":"VER"} ,
		{"word":"préformait","word_nosc":"preformait","lemma":"préformer","pos":"VER"} ,
		{"word":"préformer","word_nosc":"preformer","lemma":"préformer","pos":"VER"} ,
		{"word":"préfère","word_nosc":"prefere","lemma":"préférer","pos":"VER"} ,
		{"word":"préfèrent","word_nosc":"preferent","lemma":"préférer","pos":"VER"} ,
		{"word":"préfères","word_nosc":"preferes","lemma":"préférer","pos":"VER"} ,
		{"word":"préféra","word_nosc":"prefera","lemma":"préférer","pos":"VER"} ,
		{"word":"préférai","word_nosc":"preferai","lemma":"préférer","pos":"VER"} ,
		{"word":"préféraient","word_nosc":"preferaient","lemma":"préférer","pos":"VER"} ,
		{"word":"préférais","word_nosc":"preferais","lemma":"préférer","pos":"VER"} ,
		{"word":"préférait","word_nosc":"preferait","lemma":"préférer","pos":"VER"} ,
		{"word":"préférant","word_nosc":"preferant","lemma":"préférer","pos":"VER"} ,
		{"word":"préférer","word_nosc":"preferer","lemma":"préférer","pos":"VER"} ,
		{"word":"préférera","word_nosc":"preferera","lemma":"préférer","pos":"VER"} ,
		{"word":"préférerai","word_nosc":"prefererai","lemma":"préférer","pos":"VER"} ,
		{"word":"préféreraient","word_nosc":"prefereraient","lemma":"préférer","pos":"VER"} ,
		{"word":"préférerais","word_nosc":"prefererais","lemma":"préférer","pos":"VER"} ,
		{"word":"préférerait","word_nosc":"prefererait","lemma":"préférer","pos":"VER"} ,
		{"word":"préféreras","word_nosc":"prefereras","lemma":"préférer","pos":"VER"} ,
		{"word":"préférerez","word_nosc":"prefererez","lemma":"préférer","pos":"VER"} ,
		{"word":"préféreriez","word_nosc":"prefereriez","lemma":"préférer","pos":"VER"} ,
		{"word":"préférerions","word_nosc":"prefererions","lemma":"préférer","pos":"VER"} ,
		{"word":"préféreront","word_nosc":"prefereront","lemma":"préférer","pos":"VER"} ,
		{"word":"préférez","word_nosc":"preferez","lemma":"préférer","pos":"VER"} ,
		{"word":"préfériez","word_nosc":"preferiez","lemma":"préférer","pos":"VER"} ,
		{"word":"préférions","word_nosc":"preferions","lemma":"préférer","pos":"VER"} ,
		{"word":"préférons","word_nosc":"preferons","lemma":"préférer","pos":"VER"} ,
		{"word":"préférâmes","word_nosc":"preferames","lemma":"préférer","pos":"VER"} ,
		{"word":"préférât","word_nosc":"preferat","lemma":"préférer","pos":"VER"} ,
		{"word":"préférèrent","word_nosc":"prefererent","lemma":"préférer","pos":"VER"} ,
		{"word":"préféré","word_nosc":"prefere","lemma":"préférer","pos":"VER"} ,
		{"word":"préférée","word_nosc":"preferee","lemma":"préférer","pos":"VER"} ,
		{"word":"préférées","word_nosc":"preferees","lemma":"préférer","pos":"VER"} ,
		{"word":"préférés","word_nosc":"preferes","lemma":"préférer","pos":"VER"} ,
		{"word":"préjuge","word_nosc":"prejuge","lemma":"préjuger","pos":"VER"} ,
		{"word":"préjugeait","word_nosc":"prejugeait","lemma":"préjuger","pos":"VER"} ,
		{"word":"préjugeant","word_nosc":"prejugeant","lemma":"préjuger","pos":"VER"} ,
		{"word":"préjuger","word_nosc":"prejuger","lemma":"préjuger","pos":"VER"} ,
		{"word":"préjugé","word_nosc":"prejuge","lemma":"préjuger","pos":"VER"} ,
		{"word":"préjugée","word_nosc":"prejugee","lemma":"préjuger","pos":"VER"} ,
		{"word":"préjugés","word_nosc":"prejuges","lemma":"préjuger","pos":"VER"} ,
		{"word":"prélassaient","word_nosc":"prelassaient","lemma":"prélasser","pos":"VER"} ,
		{"word":"prélassais","word_nosc":"prelassais","lemma":"prélasser","pos":"VER"} ,
		{"word":"prélassait","word_nosc":"prelassait","lemma":"prélasser","pos":"VER"} ,
		{"word":"prélassant","word_nosc":"prelassant","lemma":"prélasser","pos":"VER"} ,
		{"word":"prélasse","word_nosc":"prelasse","lemma":"prélasser","pos":"VER"} ,
		{"word":"prélassent","word_nosc":"prelassent","lemma":"prélasser","pos":"VER"} ,
		{"word":"prélasser","word_nosc":"prelasser","lemma":"prélasser","pos":"VER"} ,
		{"word":"prélassera","word_nosc":"prelassera","lemma":"prélasser","pos":"VER"} ,
		{"word":"prélasseront","word_nosc":"prelasseront","lemma":"prélasser","pos":"VER"} ,
		{"word":"prélassez","word_nosc":"prelassez","lemma":"prélasser","pos":"VER"} ,
		{"word":"prélassiez","word_nosc":"prelassiez","lemma":"prélasser","pos":"VER"} ,
		{"word":"prélassèrent","word_nosc":"prelasserent","lemma":"prélasser","pos":"VER"} ,
		{"word":"prélaver","word_nosc":"prelaver","lemma":"prélaver","pos":"VER"} ,
		{"word":"préleva","word_nosc":"preleva","lemma":"prélever","pos":"VER"} ,
		{"word":"prélevaient","word_nosc":"prelevaient","lemma":"prélever","pos":"VER"} ,
		{"word":"prélevais","word_nosc":"prelevais","lemma":"prélever","pos":"VER"} ,
		{"word":"prélevait","word_nosc":"prelevait","lemma":"prélever","pos":"VER"} ,
		{"word":"prélevant","word_nosc":"prelevant","lemma":"prélever","pos":"VER"} ,
		{"word":"prélever","word_nosc":"prelever","lemma":"prélever","pos":"VER"} ,
		{"word":"prélevez","word_nosc":"prelevez","lemma":"prélever","pos":"VER"} ,
		{"word":"prélevons","word_nosc":"prelevons","lemma":"prélever","pos":"VER"} ,
		{"word":"prélevèrent","word_nosc":"preleverent","lemma":"prélever","pos":"VER"} ,
		{"word":"prélevé","word_nosc":"preleve","lemma":"prélever","pos":"VER"} ,
		{"word":"prélevée","word_nosc":"prelevee","lemma":"prélever","pos":"VER"} ,
		{"word":"prélevées","word_nosc":"prelevees","lemma":"prélever","pos":"VER"} ,
		{"word":"prélevés","word_nosc":"preleves","lemma":"prélever","pos":"VER"} ,
		{"word":"préluda","word_nosc":"preluda","lemma":"préluder","pos":"VER"} ,
		{"word":"préludaient","word_nosc":"preludaient","lemma":"préluder","pos":"VER"} ,
		{"word":"préludait","word_nosc":"preludait","lemma":"préluder","pos":"VER"} ,
		{"word":"préludant","word_nosc":"preludant","lemma":"préluder","pos":"VER"} ,
		{"word":"prélude","word_nosc":"prelude","lemma":"préluder","pos":"VER"} ,
		{"word":"préludent","word_nosc":"preludent","lemma":"préluder","pos":"VER"} ,
		{"word":"préluder","word_nosc":"preluder","lemma":"préluder","pos":"VER"} ,
		{"word":"préluderait","word_nosc":"preluderait","lemma":"préluder","pos":"VER"} ,
		{"word":"préludât","word_nosc":"preludat","lemma":"préluder","pos":"VER"} ,
		{"word":"préludèrent","word_nosc":"preluderent","lemma":"préluder","pos":"VER"} ,
		{"word":"préludé","word_nosc":"prelude","lemma":"préluder","pos":"VER"} ,
		{"word":"prélève","word_nosc":"preleve","lemma":"prélever","pos":"VER"} ,
		{"word":"prélèvent","word_nosc":"prelevent","lemma":"prélever","pos":"VER"} ,
		{"word":"prélèveraient","word_nosc":"preleveraient","lemma":"prélever","pos":"VER"} ,
		{"word":"prélèverait","word_nosc":"preleverait","lemma":"prélever","pos":"VER"} ,
		{"word":"prélèverez","word_nosc":"preleverez","lemma":"prélever","pos":"VER"} ,
		{"word":"prélèverions","word_nosc":"preleverions","lemma":"prélever","pos":"VER"} ,
		{"word":"prémuni","word_nosc":"premuni","lemma":"prémunir","pos":"VER"} ,
		{"word":"prémunie","word_nosc":"premunie","lemma":"prémunir","pos":"VER"} ,
		{"word":"prémunir","word_nosc":"premunir","lemma":"prémunir","pos":"VER"} ,
		{"word":"prémuniraient","word_nosc":"premuniraient","lemma":"prémunir","pos":"VER"} ,
		{"word":"prémunis","word_nosc":"premunis","lemma":"prémunir","pos":"VER"} ,
		{"word":"prémunissent","word_nosc":"premunissent","lemma":"prémunir","pos":"VER"} ,
		{"word":"préméditait","word_nosc":"premeditait","lemma":"préméditer","pos":"VER"} ,
		{"word":"prémédite","word_nosc":"premedite","lemma":"préméditer","pos":"VER"} ,
		{"word":"préméditer","word_nosc":"premediter","lemma":"préméditer","pos":"VER"} ,
		{"word":"préméditera","word_nosc":"premeditera","lemma":"préméditer","pos":"VER"} ,
		{"word":"préméditons","word_nosc":"premeditons","lemma":"préméditer","pos":"VER"} ,
		{"word":"prémédité","word_nosc":"premedite","lemma":"préméditer","pos":"VER"} ,
		{"word":"préméditée","word_nosc":"premeditee","lemma":"préméditer","pos":"VER"} ,
		{"word":"préméditées","word_nosc":"premeditees","lemma":"préméditer","pos":"VER"} ,
		{"word":"prémédités","word_nosc":"premedites","lemma":"préméditer","pos":"VER"} ,
		{"word":"prénomma","word_nosc":"prenomma","lemma":"prénommer","pos":"VER"} ,
		{"word":"prénommaient","word_nosc":"prenommaient","lemma":"prénommer","pos":"VER"} ,
		{"word":"prénommais","word_nosc":"prenommais","lemma":"prénommer","pos":"VER"} ,
		{"word":"prénommait","word_nosc":"prenommait","lemma":"prénommer","pos":"VER"} ,
		{"word":"prénommant","word_nosc":"prenommant","lemma":"prénommer","pos":"VER"} ,
		{"word":"prénomme","word_nosc":"prenomme","lemma":"prénommer","pos":"VER"} ,
		{"word":"prénomment","word_nosc":"prenomment","lemma":"prénommer","pos":"VER"} ,
		{"word":"prénommer","word_nosc":"prenommer","lemma":"prénommer","pos":"VER"} ,
		{"word":"prénommez","word_nosc":"prenommez","lemma":"prénommer","pos":"VER"} ,
		{"word":"prénommât","word_nosc":"prenommat","lemma":"prénommer","pos":"VER"} ,
		{"word":"prénommèrent","word_nosc":"prenommerent","lemma":"prénommer","pos":"VER"} ,
		{"word":"prénommé","word_nosc":"prenomme","lemma":"prénommer","pos":"VER"} ,
		{"word":"prénommée","word_nosc":"prenommee","lemma":"prénommer","pos":"VER"} ,
		{"word":"préoccupa","word_nosc":"preoccupa","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupai","word_nosc":"preoccupai","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupaient","word_nosc":"preoccupaient","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupais","word_nosc":"preoccupais","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupait","word_nosc":"preoccupait","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupant","word_nosc":"preoccupant","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupe","word_nosc":"preoccupe","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupent","word_nosc":"preoccupent","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccuper","word_nosc":"preoccuper","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupera","word_nosc":"preoccupera","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccuperai","word_nosc":"preoccuperai","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccuperaient","word_nosc":"preoccuperaient","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccuperais","word_nosc":"preoccuperais","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccuperait","word_nosc":"preoccuperait","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupes","word_nosc":"preoccupes","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupez","word_nosc":"preoccupez","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupiez","word_nosc":"preoccupiez","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupions","word_nosc":"preoccupions","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupons","word_nosc":"preoccupons","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupât","word_nosc":"preoccupat","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupâtes","word_nosc":"preoccupates","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupèrent","word_nosc":"preoccuperent","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupé","word_nosc":"preoccupe","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupée","word_nosc":"preoccupee","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupées","word_nosc":"preoccupees","lemma":"préoccuper","pos":"VER"} ,
		{"word":"préoccupés","word_nosc":"preoccupes","lemma":"préoccuper","pos":"VER"} ,
		{"word":"prépara","word_nosc":"prepara","lemma":"préparer","pos":"VER"} ,
		{"word":"préparai","word_nosc":"preparai","lemma":"préparer","pos":"VER"} ,
		{"word":"préparaient","word_nosc":"preparaient","lemma":"préparer","pos":"VER"} ,
		{"word":"préparais","word_nosc":"preparais","lemma":"préparer","pos":"VER"} ,
		{"word":"préparait","word_nosc":"preparait","lemma":"préparer","pos":"VER"} ,
		{"word":"préparant","word_nosc":"preparant","lemma":"préparer","pos":"VER"} ,
		{"word":"prépare","word_nosc":"prepare","lemma":"préparer","pos":"VER"} ,
		{"word":"préparent","word_nosc":"preparent","lemma":"préparer","pos":"VER"} ,
		{"word":"préparer","word_nosc":"preparer","lemma":"préparer","pos":"VER"} ,
		{"word":"préparera","word_nosc":"preparera","lemma":"préparer","pos":"VER"} ,
		{"word":"préparerai","word_nosc":"preparerai","lemma":"préparer","pos":"VER"} ,
		{"word":"prépareraient","word_nosc":"prepareraient","lemma":"préparer","pos":"VER"} ,
		{"word":"préparerais","word_nosc":"preparerais","lemma":"préparer","pos":"VER"} ,
		{"word":"préparerait","word_nosc":"preparerait","lemma":"préparer","pos":"VER"} ,
		{"word":"prépareras","word_nosc":"prepareras","lemma":"préparer","pos":"VER"} ,
		{"word":"préparerez","word_nosc":"preparerez","lemma":"préparer","pos":"VER"} ,
		{"word":"préparerons","word_nosc":"preparerons","lemma":"préparer","pos":"VER"} ,
		{"word":"prépareront","word_nosc":"prepareront","lemma":"préparer","pos":"VER"} ,
		{"word":"prépares","word_nosc":"prepares","lemma":"préparer","pos":"VER"} ,
		{"word":"préparez","word_nosc":"preparez","lemma":"préparer","pos":"VER"} ,
		{"word":"prépariez","word_nosc":"prepariez","lemma":"préparer","pos":"VER"} ,
		{"word":"préparions","word_nosc":"preparions","lemma":"préparer","pos":"VER"} ,
		{"word":"préparons","word_nosc":"preparons","lemma":"préparer","pos":"VER"} ,
		{"word":"préparât","word_nosc":"preparat","lemma":"préparer","pos":"VER"} ,
		{"word":"préparèrent","word_nosc":"preparerent","lemma":"préparer","pos":"VER"} ,
		{"word":"préparé","word_nosc":"prepare","lemma":"préparer","pos":"VER"} ,
		{"word":"préparée","word_nosc":"preparee","lemma":"préparer","pos":"VER"} ,
		{"word":"préparées","word_nosc":"preparees","lemma":"préparer","pos":"VER"} ,
		{"word":"préparés","word_nosc":"prepares","lemma":"préparer","pos":"VER"} ,
		{"word":"prépayée","word_nosc":"prepayee","lemma":"prépayer","pos":"VER"} ,
		{"word":"préposé","word_nosc":"prepose","lemma":"préposer","pos":"VER"} ,
		{"word":"préposée","word_nosc":"preposee","lemma":"préposer","pos":"VER"} ,
		{"word":"préposés","word_nosc":"preposes","lemma":"préposer","pos":"VER"} ,
		{"word":"préréglé","word_nosc":"preregle","lemma":"prérégler","pos":"VER"} ,
		{"word":"présage","word_nosc":"presage","lemma":"présager","pos":"VER"} ,
		{"word":"présagea","word_nosc":"presagea","lemma":"présager","pos":"VER"} ,
		{"word":"présageaient","word_nosc":"presageaient","lemma":"présager","pos":"VER"} ,
		{"word":"présageait","word_nosc":"presageait","lemma":"présager","pos":"VER"} ,
		{"word":"présagent","word_nosc":"presagent","lemma":"présager","pos":"VER"} ,
		{"word":"présager","word_nosc":"presager","lemma":"présager","pos":"VER"} ,
		{"word":"présagerait","word_nosc":"presagerait","lemma":"présager","pos":"VER"} ,
		{"word":"présagé","word_nosc":"presage","lemma":"présager","pos":"VER"} ,
		{"word":"présenta","word_nosc":"presenta","lemma":"présenter","pos":"VER"} ,
		{"word":"présentai","word_nosc":"presentai","lemma":"présenter","pos":"VER"} ,
		{"word":"présentaient","word_nosc":"presentaient","lemma":"présenter","pos":"VER"} ,
		{"word":"présentais","word_nosc":"presentais","lemma":"présenter","pos":"VER"} ,
		{"word":"présentait","word_nosc":"presentait","lemma":"présenter","pos":"VER"} ,
		{"word":"présentant","word_nosc":"presentant","lemma":"présenter","pos":"VER"} ,
		{"word":"présentassent","word_nosc":"presentassent","lemma":"présenter","pos":"VER"} ,
		{"word":"présente","word_nosc":"presente","lemma":"présenter","pos":"VER"} ,
		{"word":"présentent","word_nosc":"presentent","lemma":"présenter","pos":"VER"} ,
		{"word":"présenter","word_nosc":"presenter","lemma":"poster","pos":"VER"} ,
		{"word":"présenter","word_nosc":"presenter","lemma":"présenter","pos":"VER"} ,
		{"word":"présentera","word_nosc":"presentera","lemma":"présenter","pos":"VER"} ,
		{"word":"présenterai","word_nosc":"presenterai","lemma":"présenter","pos":"VER"} ,
		{"word":"présenteraient","word_nosc":"presenteraient","lemma":"présenter","pos":"VER"} ,
		{"word":"présenterais","word_nosc":"presenterais","lemma":"présenter","pos":"VER"} ,
		{"word":"présenterait","word_nosc":"presenterait","lemma":"présenter","pos":"VER"} ,
		{"word":"présenteras","word_nosc":"presenteras","lemma":"présenter","pos":"VER"} ,
		{"word":"présenterez","word_nosc":"presenterez","lemma":"présenter","pos":"VER"} ,
		{"word":"présenteriez","word_nosc":"presenteriez","lemma":"présenter","pos":"VER"} ,
		{"word":"présenterons","word_nosc":"presenterons","lemma":"présenter","pos":"VER"} ,
		{"word":"présenteront","word_nosc":"presenteront","lemma":"présenter","pos":"VER"} ,
		{"word":"présentes","word_nosc":"presentes","lemma":"présenter","pos":"VER"} ,
		{"word":"présentez","word_nosc":"presentez","lemma":"présenter","pos":"VER"} ,
		{"word":"présentiez","word_nosc":"presentiez","lemma":"présenter","pos":"VER"} ,
		{"word":"présentions","word_nosc":"presentions","lemma":"présenter","pos":"VER"} ,
		{"word":"présentons","word_nosc":"presentons","lemma":"présenter","pos":"VER"} ,
		{"word":"présentât","word_nosc":"presentat","lemma":"présenter","pos":"VER"} ,
		{"word":"présentèrent","word_nosc":"presenterent","lemma":"présenter","pos":"VER"} ,
		{"word":"présenté","word_nosc":"presente","lemma":"présenter","pos":"VER"} ,
		{"word":"présentée","word_nosc":"presentee","lemma":"présenter","pos":"VER"} ,
		{"word":"présentées","word_nosc":"presentees","lemma":"présenter","pos":"VER"} ,
		{"word":"présentés","word_nosc":"presentes","lemma":"présenter","pos":"VER"} ,
		{"word":"préserva","word_nosc":"preserva","lemma":"préserver","pos":"VER"} ,
		{"word":"préservaient","word_nosc":"preservaient","lemma":"préserver","pos":"VER"} ,
		{"word":"préservais","word_nosc":"preservais","lemma":"préserver","pos":"VER"} ,
		{"word":"préservait","word_nosc":"preservait","lemma":"préserver","pos":"VER"} ,
		{"word":"préservant","word_nosc":"preservant","lemma":"préserver","pos":"VER"} ,
		{"word":"préserve","word_nosc":"preserve","lemma":"préserver","pos":"VER"} ,
		{"word":"préservent","word_nosc":"preservent","lemma":"préserver","pos":"VER"} ,
		{"word":"préserver","word_nosc":"preserver","lemma":"préserver","pos":"VER"} ,
		{"word":"préservera","word_nosc":"preservera","lemma":"préserver","pos":"VER"} ,
		{"word":"préserverai","word_nosc":"preserverai","lemma":"préserver","pos":"VER"} ,
		{"word":"préserverait","word_nosc":"preserverait","lemma":"préserver","pos":"VER"} ,
		{"word":"préservez","word_nosc":"preservez","lemma":"préserver","pos":"VER"} ,
		{"word":"préservons","word_nosc":"preservons","lemma":"préserver","pos":"VER"} ,
		{"word":"préservé","word_nosc":"preserve","lemma":"préserver","pos":"VER"} ,
		{"word":"préservée","word_nosc":"preservee","lemma":"préserver","pos":"VER"} ,
		{"word":"préservées","word_nosc":"preservees","lemma":"préserver","pos":"VER"} ,
		{"word":"préservés","word_nosc":"preserves","lemma":"préserver","pos":"VER"} ,
		{"word":"présida","word_nosc":"presida","lemma":"présider","pos":"VER"} ,
		{"word":"présidai","word_nosc":"presidai","lemma":"présider","pos":"VER"} ,
		{"word":"présidaient","word_nosc":"presidaient","lemma":"présider","pos":"VER"} ,
		{"word":"présidais","word_nosc":"presidais","lemma":"présider","pos":"VER"} ,
		{"word":"présidait","word_nosc":"presidait","lemma":"présider","pos":"VER"} ,
		{"word":"présidant","word_nosc":"presidant","lemma":"présider","pos":"VER"} ,
		{"word":"préside","word_nosc":"preside","lemma":"présider","pos":"VER"} ,
		{"word":"président","word_nosc":"president","lemma":"présider","pos":"VER"} ,
		{"word":"présider","word_nosc":"presider","lemma":"présider","pos":"VER"} ,
		{"word":"présidera","word_nosc":"presidera","lemma":"présider","pos":"VER"} ,
		{"word":"présiderai","word_nosc":"presiderai","lemma":"présider","pos":"VER"} ,
		{"word":"présiderais","word_nosc":"presiderais","lemma":"présider","pos":"VER"} ,
		{"word":"présiderait","word_nosc":"presiderait","lemma":"présider","pos":"VER"} ,
		{"word":"présideras","word_nosc":"presideras","lemma":"présider","pos":"VER"} ,
		{"word":"présiderez","word_nosc":"presiderez","lemma":"présider","pos":"VER"} ,
		{"word":"présides","word_nosc":"presides","lemma":"présider","pos":"VER"} ,
		{"word":"présidiez","word_nosc":"presidiez","lemma":"présider","pos":"VER"} ,
		{"word":"présidons","word_nosc":"presidons","lemma":"présider","pos":"VER"} ,
		{"word":"présidât","word_nosc":"presidat","lemma":"présider","pos":"VER"} ,
		{"word":"présidèrent","word_nosc":"presiderent","lemma":"présider","pos":"VER"} ,
		{"word":"présidé","word_nosc":"preside","lemma":"présider","pos":"VER"} ,
		{"word":"présidée","word_nosc":"presidee","lemma":"présider","pos":"VER"} ,
		{"word":"présidés","word_nosc":"presides","lemma":"présider","pos":"VER"} ,
		{"word":"présumai","word_nosc":"presumai","lemma":"présumer","pos":"VER"} ,
		{"word":"présumais","word_nosc":"presumais","lemma":"présumer","pos":"VER"} ,
		{"word":"présumait","word_nosc":"presumait","lemma":"présumer","pos":"VER"} ,
		{"word":"présumant","word_nosc":"presumant","lemma":"présumer","pos":"VER"} ,
		{"word":"présume","word_nosc":"presume","lemma":"présumer","pos":"VER"} ,
		{"word":"présumer","word_nosc":"presumer","lemma":"présumer","pos":"VER"} ,
		{"word":"présumerai","word_nosc":"presumerai","lemma":"présumer","pos":"VER"} ,
		{"word":"présumez","word_nosc":"presumez","lemma":"présumer","pos":"VER"} ,
		{"word":"présumons","word_nosc":"presumons","lemma":"présumer","pos":"VER"} ,
		{"word":"présumé","word_nosc":"presume","lemma":"présumer","pos":"VER"} ,
		{"word":"présumée","word_nosc":"presumee","lemma":"présumer","pos":"VER"} ,
		{"word":"présumés","word_nosc":"presumes","lemma":"présumer","pos":"VER"} ,
		{"word":"présupposant","word_nosc":"presupposant","lemma":"présupposer","pos":"VER"} ,
		{"word":"présuppose","word_nosc":"presuppose","lemma":"présupposer","pos":"VER"} ,
		{"word":"présupposent","word_nosc":"presupposent","lemma":"présupposer","pos":"VER"} ,
		{"word":"présupposée","word_nosc":"presupposee","lemma":"présupposer","pos":"VER"} ,
		{"word":"présélectionné","word_nosc":"preselectionne","lemma":"présélectionner","pos":"VER"} ,
		{"word":"présélectionnée","word_nosc":"preselectionnee","lemma":"présélectionner","pos":"VER"} ,
		{"word":"prétend","word_nosc":"pretend","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendaient","word_nosc":"pretendaient","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendais","word_nosc":"pretendais","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendait","word_nosc":"pretendait","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendant","word_nosc":"pretendant","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétende","word_nosc":"pretende","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendent","word_nosc":"pretendent","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendez","word_nosc":"pretendez","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendiez","word_nosc":"pretendiez","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendions","word_nosc":"pretendions","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendirent","word_nosc":"pretendirent","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendis","word_nosc":"pretendis","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendissent","word_nosc":"pretendissent","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendit","word_nosc":"pretendit","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendons","word_nosc":"pretendons","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendra","word_nosc":"pretendra","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendrai","word_nosc":"pretendrai","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendraient","word_nosc":"pretendraient","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendrais","word_nosc":"pretendrais","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendrait","word_nosc":"pretendrait","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendras","word_nosc":"pretendras","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendre","word_nosc":"pretendre","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendront","word_nosc":"pretendront","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétends","word_nosc":"pretends","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendu","word_nosc":"pretendu","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendue","word_nosc":"pretendue","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendues","word_nosc":"pretendues","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendus","word_nosc":"pretendus","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétendît","word_nosc":"pretendit","lemma":"prétendre","pos":"VER"} ,
		{"word":"prétexta","word_nosc":"pretexta","lemma":"prétexter","pos":"VER"} ,
		{"word":"prétextai","word_nosc":"pretextai","lemma":"prétexter","pos":"VER"} ,
		{"word":"prétextait","word_nosc":"pretextait","lemma":"prétexter","pos":"VER"} ,
		{"word":"prétextant","word_nosc":"pretextant","lemma":"prétexter","pos":"VER"} ,
		{"word":"prétexte","word_nosc":"pretexte","lemma":"prétexter","pos":"VER"} ,
		{"word":"prétextent","word_nosc":"pretextent","lemma":"prétexter","pos":"VER"} ,
		{"word":"prétexter","word_nosc":"pretexter","lemma":"prétexter","pos":"VER"} ,
		{"word":"prétexté","word_nosc":"pretexte","lemma":"prétexter","pos":"VER"} ,
		{"word":"prévalaient","word_nosc":"prevalaient","lemma":"prévaloir","pos":"VER"} ,
		{"word":"prévalait","word_nosc":"prevalait","lemma":"prévaloir","pos":"VER"} ,
		{"word":"prévalant","word_nosc":"prevalant","lemma":"prévaloir","pos":"VER"} ,
		{"word":"prévalent","word_nosc":"prevalent","lemma":"prévaloir","pos":"VER"} ,
		{"word":"prévalez","word_nosc":"prevalez","lemma":"prévaloir","pos":"VER"} ,
		{"word":"prévaloir","word_nosc":"prevaloir","lemma":"prévaloir","pos":"VER"} ,
		{"word":"prévalu","word_nosc":"prevalu","lemma":"prévaloir","pos":"VER"} ,
		{"word":"prévalut","word_nosc":"prevalut","lemma":"prévaloir","pos":"VER"} ,
		{"word":"prévaudra","word_nosc":"prevaudra","lemma":"prévaloir","pos":"VER"} ,
		{"word":"prévaudrait","word_nosc":"prevaudrait","lemma":"prévaloir","pos":"VER"} ,
		{"word":"prévaudront","word_nosc":"prevaudront","lemma":"prévaloir","pos":"VER"} ,
		{"word":"prévaut","word_nosc":"prevaut","lemma":"prévaloir","pos":"VER"} ,
		{"word":"prévenaient","word_nosc":"prevenaient","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévenais","word_nosc":"prevenais","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévenait","word_nosc":"prevenait","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévenant","word_nosc":"prevenant","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévenez","word_nosc":"prevenez","lemma":"prévenir","pos":"VER"} ,
		{"word":"préveniez","word_nosc":"preveniez","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévenions","word_nosc":"prevenions","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévenir","word_nosc":"prevenir","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévenons","word_nosc":"prevenons","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévenu","word_nosc":"prevenu","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévenue","word_nosc":"prevenue","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévenues","word_nosc":"prevenues","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévenus","word_nosc":"prevenus","lemma":"prévenir","pos":"VER"} ,
		{"word":"préviendra","word_nosc":"previendra","lemma":"prévenir","pos":"VER"} ,
		{"word":"préviendrai","word_nosc":"previendrai","lemma":"prévenir","pos":"VER"} ,
		{"word":"préviendrais","word_nosc":"previendrais","lemma":"prévenir","pos":"VER"} ,
		{"word":"préviendrait","word_nosc":"previendrait","lemma":"prévenir","pos":"VER"} ,
		{"word":"préviendras","word_nosc":"previendras","lemma":"prévenir","pos":"VER"} ,
		{"word":"préviendrez","word_nosc":"previendrez","lemma":"prévenir","pos":"VER"} ,
		{"word":"préviendrons","word_nosc":"previendrons","lemma":"prévenir","pos":"VER"} ,
		{"word":"préviendront","word_nosc":"previendront","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévienne","word_nosc":"previenne","lemma":"prévenir","pos":"VER"} ,
		{"word":"préviennent","word_nosc":"previennent","lemma":"prévenir","pos":"VER"} ,
		{"word":"préviennes","word_nosc":"previennes","lemma":"prévenir","pos":"VER"} ,
		{"word":"préviens","word_nosc":"previens","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévient","word_nosc":"previent","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévins","word_nosc":"previns","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévint","word_nosc":"prevint","lemma":"prévenir","pos":"VER"} ,
		{"word":"prévit","word_nosc":"previt","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévoie","word_nosc":"prevoie","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévoient","word_nosc":"prevoient","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévoies","word_nosc":"prevoies","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévoir","word_nosc":"prevoir","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévoira","word_nosc":"prevoira","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévoirait","word_nosc":"prevoirait","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévois","word_nosc":"prevois","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévoit","word_nosc":"prevoit","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévoyaient","word_nosc":"prevoyaient","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévoyais","word_nosc":"prevoyais","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévoyait","word_nosc":"prevoyait","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévoyant","word_nosc":"prevoyant","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévoyez","word_nosc":"prevoyez","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévoyions","word_nosc":"prevoyions","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévoyons","word_nosc":"prevoyons","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévu","word_nosc":"prevu","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévue","word_nosc":"prevue","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévues","word_nosc":"prevues","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévus","word_nosc":"prevus","lemma":"prévoir","pos":"VER"} ,
		{"word":"prévînt","word_nosc":"prevint","lemma":"prévenir","pos":"VER"} ,
		{"word":"préétabli","word_nosc":"preetabli","lemma":"préétablir","pos":"VER"} ,
		{"word":"préétablie","word_nosc":"preetablie","lemma":"préétablir","pos":"VER"} ,
		{"word":"prêcha","word_nosc":"precha","lemma":"prêcher","pos":"VER"} ,
		{"word":"prêchaient","word_nosc":"prechaient","lemma":"prêcher","pos":"VER"} ,
		{"word":"prêchais","word_nosc":"prechais","lemma":"prêcher","pos":"VER"} ,
		{"word":"prêchait","word_nosc":"prechait","lemma":"prêcher","pos":"VER"} ,
		{"word":"prêchant","word_nosc":"prechant","lemma":"prêcher","pos":"VER"} ,
		{"word":"prêche","word_nosc":"preche","lemma":"prêcher","pos":"VER"} ,
		{"word":"prêchent","word_nosc":"prechent","lemma":"prêcher","pos":"VER"} ,
		{"word":"prêcher","word_nosc":"precher","lemma":"prêcher","pos":"VER"} ,
		{"word":"prêches","word_nosc":"preches","lemma":"prêcher","pos":"VER"} ,
		{"word":"prêchez","word_nosc":"prechez","lemma":"prêcher","pos":"VER"} ,
		{"word":"prêché","word_nosc":"preche","lemma":"prêcher","pos":"VER"} ,
		{"word":"prêchée","word_nosc":"prechee","lemma":"prêcher","pos":"VER"} ,
		{"word":"prêta","word_nosc":"preta","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtai","word_nosc":"pretai","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtaient","word_nosc":"pretaient","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtais","word_nosc":"pretais","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtait","word_nosc":"pretait","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtant","word_nosc":"pretant","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtassent","word_nosc":"pretassent","lemma":"prêter","pos":"VER"} ,
		{"word":"prête","word_nosc":"prete","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtent","word_nosc":"pretent","lemma":"prêter","pos":"VER"} ,
		{"word":"prêter","word_nosc":"preter","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtera","word_nosc":"pretera","lemma":"prêter","pos":"VER"} ,
		{"word":"prêterai","word_nosc":"preterai","lemma":"prêter","pos":"VER"} ,
		{"word":"prêteraient","word_nosc":"preteraient","lemma":"prêter","pos":"VER"} ,
		{"word":"prêterais","word_nosc":"preterais","lemma":"prêter","pos":"VER"} ,
		{"word":"prêterait","word_nosc":"preterait","lemma":"prêter","pos":"VER"} ,
		{"word":"prêteras","word_nosc":"preteras","lemma":"prêter","pos":"VER"} ,
		{"word":"prêterez","word_nosc":"preterez","lemma":"prêter","pos":"VER"} ,
		{"word":"prêteriez","word_nosc":"preteriez","lemma":"prêter","pos":"VER"} ,
		{"word":"prêterions","word_nosc":"preterions","lemma":"prêter","pos":"VER"} ,
		{"word":"prêterons","word_nosc":"preterons","lemma":"prêter","pos":"VER"} ,
		{"word":"prêteront","word_nosc":"preteront","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtes","word_nosc":"pretes","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtez","word_nosc":"pretez","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtiez","word_nosc":"pretiez","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtions","word_nosc":"pretions","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtons","word_nosc":"pretons","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtâmes","word_nosc":"pretames","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtât","word_nosc":"pretat","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtèrent","word_nosc":"preterent","lemma":"prêter","pos":"VER"} ,
		{"word":"prêté","word_nosc":"prete","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtée","word_nosc":"pretee","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtées","word_nosc":"pretees","lemma":"prêter","pos":"VER"} ,
		{"word":"prêtés","word_nosc":"pretes","lemma":"prêter","pos":"VER"} ,
		{"word":"prîmes","word_nosc":"primes","lemma":"prendre","pos":"VER"} ,
		{"word":"prît","word_nosc":"prit","lemma":"prendre","pos":"VER"} ,
		{"word":"prônais","word_nosc":"pronais","lemma":"prôner","pos":"VER"} ,
		{"word":"prônait","word_nosc":"pronait","lemma":"prôner","pos":"VER"} ,
		{"word":"prônant","word_nosc":"pronant","lemma":"prôner","pos":"VER"} ,
		{"word":"prône","word_nosc":"prone","lemma":"prôner","pos":"VER"} ,
		{"word":"prônent","word_nosc":"pronent","lemma":"prôner","pos":"VER"} ,
		{"word":"prôner","word_nosc":"proner","lemma":"prôner","pos":"VER"} ,
		{"word":"prônera","word_nosc":"pronera","lemma":"prôner","pos":"VER"} ,
		{"word":"prônerez","word_nosc":"pronerez","lemma":"prôner","pos":"VER"} ,
		{"word":"prônes","word_nosc":"prones","lemma":"prôner","pos":"VER"} ,
		{"word":"prônez","word_nosc":"pronez","lemma":"prôner","pos":"VER"} ,
		{"word":"prônons","word_nosc":"pronons","lemma":"prôner","pos":"VER"} ,
		{"word":"prôné","word_nosc":"prone","lemma":"prôner","pos":"VER"} ,
		{"word":"prônée","word_nosc":"pronee","lemma":"prôner","pos":"VER"} ,
		{"word":"prônées","word_nosc":"pronees","lemma":"prôner","pos":"VER"} ,
		{"word":"prônés","word_nosc":"prones","lemma":"prôner","pos":"VER"} ,
		{"word":"psalmodia","word_nosc":"psalmodia","lemma":"psalmodier","pos":"VER"} ,
		{"word":"psalmodiaient","word_nosc":"psalmodiaient","lemma":"psalmodier","pos":"VER"} ,
		{"word":"psalmodiais","word_nosc":"psalmodiais","lemma":"psalmodier","pos":"VER"} ,
		{"word":"psalmodiait","word_nosc":"psalmodiait","lemma":"psalmodier","pos":"VER"} ,
		{"word":"psalmodiant","word_nosc":"psalmodiant","lemma":"psalmodier","pos":"VER"} ,
		{"word":"psalmodie","word_nosc":"psalmodie","lemma":"psalmodier","pos":"VER"} ,
		{"word":"psalmodient","word_nosc":"psalmodient","lemma":"psalmodier","pos":"VER"} ,
		{"word":"psalmodier","word_nosc":"psalmodier","lemma":"psalmodier","pos":"VER"} ,
		{"word":"psalmodies","word_nosc":"psalmodies","lemma":"psalmodier","pos":"VER"} ,
		{"word":"psalmodié","word_nosc":"psalmodie","lemma":"psalmodier","pos":"VER"} ,
		{"word":"psalmodiés","word_nosc":"psalmodies","lemma":"psalmodier","pos":"VER"} ,
		{"word":"psychanalyse","word_nosc":"psychanalyse","lemma":"psychanalyser","pos":"VER"} ,
		{"word":"psychanalyser","word_nosc":"psychanalyser","lemma":"psychanalyser","pos":"VER"} ,
		{"word":"psychanalyserai","word_nosc":"psychanalyserai","lemma":"psychanalyser","pos":"VER"} ,
		{"word":"psychanalyses","word_nosc":"psychanalyses","lemma":"psychanalyser","pos":"VER"} ,
		{"word":"psychanalysé","word_nosc":"psychanalyse","lemma":"psychanalyser","pos":"VER"} ,
		{"word":"pu","word_nosc":"pu","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pua","word_nosc":"pua","lemma":"puer","pos":"VER"} ,
		{"word":"puaient","word_nosc":"puaient","lemma":"puer","pos":"VER"} ,
		{"word":"puais","word_nosc":"puais","lemma":"puer","pos":"VER"} ,
		{"word":"puait","word_nosc":"puait","lemma":"puer","pos":"VER"} ,
		{"word":"puant","word_nosc":"puant","lemma":"puer","pos":"VER"} ,
		{"word":"publia","word_nosc":"publia","lemma":"publier","pos":"VER"} ,
		{"word":"publiai","word_nosc":"publiai","lemma":"publier","pos":"VER"} ,
		{"word":"publiaient","word_nosc":"publiaient","lemma":"publier","pos":"VER"} ,
		{"word":"publiais","word_nosc":"publiais","lemma":"publier","pos":"VER"} ,
		{"word":"publiait","word_nosc":"publiait","lemma":"publier","pos":"VER"} ,
		{"word":"publiant","word_nosc":"publiant","lemma":"publier","pos":"VER"} ,
		{"word":"publie","word_nosc":"publie","lemma":"publier","pos":"VER"} ,
		{"word":"publient","word_nosc":"publient","lemma":"publier","pos":"VER"} ,
		{"word":"publier","word_nosc":"publier","lemma":"publier","pos":"VER"} ,
		{"word":"publiera","word_nosc":"publiera","lemma":"publier","pos":"VER"} ,
		{"word":"publierai","word_nosc":"publierai","lemma":"publier","pos":"VER"} ,
		{"word":"publieraient","word_nosc":"publieraient","lemma":"publier","pos":"VER"} ,
		{"word":"publierais","word_nosc":"publierais","lemma":"publier","pos":"VER"} ,
		{"word":"publierait","word_nosc":"publierait","lemma":"publier","pos":"VER"} ,
		{"word":"publierez","word_nosc":"publierez","lemma":"publier","pos":"VER"} ,
		{"word":"publierions","word_nosc":"publierions","lemma":"publier","pos":"VER"} ,
		{"word":"publierons","word_nosc":"publierons","lemma":"publier","pos":"VER"} ,
		{"word":"publieront","word_nosc":"publieront","lemma":"publier","pos":"VER"} ,
		{"word":"publies","word_nosc":"publies","lemma":"publier","pos":"VER"} ,
		{"word":"publiez","word_nosc":"publiez","lemma":"publier","pos":"VER"} ,
		{"word":"publions","word_nosc":"publions","lemma":"publier","pos":"VER"} ,
		{"word":"publiât","word_nosc":"publiat","lemma":"publier","pos":"VER"} ,
		{"word":"publièrent","word_nosc":"publierent","lemma":"publier","pos":"VER"} ,
		{"word":"publié","word_nosc":"publie","lemma":"publier","pos":"VER"} ,
		{"word":"publiée","word_nosc":"publiee","lemma":"publier","pos":"VER"} ,
		{"word":"publiées","word_nosc":"publiees","lemma":"publier","pos":"VER"} ,
		{"word":"publiés","word_nosc":"publies","lemma":"publier","pos":"VER"} ,
		{"word":"pucher","word_nosc":"pucher","lemma":"pucher","pos":"VER"} ,
		{"word":"puddle","word_nosc":"puddle","lemma":"puddler","pos":"VER"} ,
		{"word":"pue","word_nosc":"pue","lemma":"puer","pos":"VER"} ,
		{"word":"puent","word_nosc":"puent","lemma":"puer","pos":"VER"} ,
		{"word":"puer","word_nosc":"puer","lemma":"puer","pos":"VER"} ,
		{"word":"puera","word_nosc":"puera","lemma":"puer","pos":"VER"} ,
		{"word":"puerait","word_nosc":"puerait","lemma":"puer","pos":"VER"} ,
		{"word":"pueras","word_nosc":"pueras","lemma":"puer","pos":"VER"} ,
		{"word":"puerez","word_nosc":"puerez","lemma":"puer","pos":"VER"} ,
		{"word":"pues","word_nosc":"pues","lemma":"puer","pos":"VER"} ,
		{"word":"puez","word_nosc":"puez","lemma":"puer","pos":"VER"} ,
		{"word":"pugiler","word_nosc":"pugiler","lemma":"pugiler","pos":"VER"} ,
		{"word":"puis","word_nosc":"puis","lemma":"pouvoir","pos":"VER"} ,
		{"word":"puisa","word_nosc":"puisa","lemma":"puiser","pos":"VER"} ,
		{"word":"puisai","word_nosc":"puisai","lemma":"puiser","pos":"VER"} ,
		{"word":"puisaient","word_nosc":"puisaient","lemma":"puiser","pos":"VER"} ,
		{"word":"puisais","word_nosc":"puisais","lemma":"puiser","pos":"VER"} ,
		{"word":"puisait","word_nosc":"puisait","lemma":"puiser","pos":"VER"} ,
		{"word":"puisant","word_nosc":"puisant","lemma":"puiser","pos":"VER"} ,
		{"word":"puise","word_nosc":"puise","lemma":"puiser","pos":"VER"} ,
		{"word":"puisent","word_nosc":"puisent","lemma":"puiser","pos":"VER"} ,
		{"word":"puiser","word_nosc":"puiser","lemma":"puiser","pos":"VER"} ,
		{"word":"puisera","word_nosc":"puisera","lemma":"puiser","pos":"VER"} ,
		{"word":"puiserai","word_nosc":"puiserai","lemma":"puiser","pos":"VER"} ,
		{"word":"puiserait","word_nosc":"puiserait","lemma":"puiser","pos":"VER"} ,
		{"word":"puiserez","word_nosc":"puiserez","lemma":"puiser","pos":"VER"} ,
		{"word":"puiseront","word_nosc":"puiseront","lemma":"puiser","pos":"VER"} ,
		{"word":"puises","word_nosc":"puises","lemma":"puiser","pos":"VER"} ,
		{"word":"puisez","word_nosc":"puisez","lemma":"puiser","pos":"VER"} ,
		{"word":"puisions","word_nosc":"puisions","lemma":"puiser","pos":"VER"} ,
		{"word":"puisons","word_nosc":"puisons","lemma":"puiser","pos":"VER"} ,
		{"word":"puisse","word_nosc":"puisse","lemma":"pouvoir","pos":"VER"} ,
		{"word":"puissent","word_nosc":"puissent","lemma":"pouvoir","pos":"VER"} ,
		{"word":"puisses","word_nosc":"puisses","lemma":"pouvoir","pos":"VER"} ,
		{"word":"puissiez","word_nosc":"puissiez","lemma":"pouvoir","pos":"VER"} ,
		{"word":"puissions","word_nosc":"puissions","lemma":"pouvoir","pos":"VER"} ,
		{"word":"puisé","word_nosc":"puise","lemma":"puiser","pos":"VER"} ,
		{"word":"puisée","word_nosc":"puisee","lemma":"puiser","pos":"VER"} ,
		{"word":"puisées","word_nosc":"puisees","lemma":"puiser","pos":"VER"} ,
		{"word":"puisés","word_nosc":"puises","lemma":"puiser","pos":"VER"} ,
		{"word":"pullula","word_nosc":"pullula","lemma":"pulluler","pos":"VER"} ,
		{"word":"pullulaient","word_nosc":"pullulaient","lemma":"pulluler","pos":"VER"} ,
		{"word":"pullulait","word_nosc":"pullulait","lemma":"pulluler","pos":"VER"} ,
		{"word":"pullulant","word_nosc":"pullulant","lemma":"pulluler","pos":"VER"} ,
		{"word":"pullule","word_nosc":"pullule","lemma":"pulluler","pos":"VER"} ,
		{"word":"pullulent","word_nosc":"pullulent","lemma":"pulluler","pos":"VER"} ,
		{"word":"pulluler","word_nosc":"pulluler","lemma":"pulluler","pos":"VER"} ,
		{"word":"pulluleront","word_nosc":"pulluleront","lemma":"pulluler","pos":"VER"} ,
		{"word":"pulsait","word_nosc":"pulsait","lemma":"pulser","pos":"VER"} ,
		{"word":"pulsant","word_nosc":"pulsant","lemma":"pulser","pos":"VER"} ,
		{"word":"pulse","word_nosc":"pulse","lemma":"pulser","pos":"VER"} ,
		{"word":"pulser","word_nosc":"pulser","lemma":"pulser","pos":"VER"} ,
		{"word":"pulsé","word_nosc":"pulse","lemma":"pulser","pos":"VER"} ,
		{"word":"pulvérisa","word_nosc":"pulverisa","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvérisaient","word_nosc":"pulverisaient","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvérisais","word_nosc":"pulverisais","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvérisait","word_nosc":"pulverisait","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvérisant","word_nosc":"pulverisant","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvérise","word_nosc":"pulverise","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvérisent","word_nosc":"pulverisent","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvériser","word_nosc":"pulveriser","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvériserai","word_nosc":"pulveriserai","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvériserait","word_nosc":"pulveriserait","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvériserons","word_nosc":"pulveriserons","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvériseront","word_nosc":"pulveriseront","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvérises","word_nosc":"pulverises","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvérisez","word_nosc":"pulverisez","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvérisé","word_nosc":"pulverise","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvérisée","word_nosc":"pulverisee","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvérisées","word_nosc":"pulverisees","lemma":"pulvériser","pos":"VER"} ,
		{"word":"pulvérisés","word_nosc":"pulverises","lemma":"pulvériser","pos":"VER"} ,
		{"word":"punaisait","word_nosc":"punaisait","lemma":"punaiser","pos":"VER"} ,
		{"word":"punaisé","word_nosc":"punaise","lemma":"punaiser","pos":"VER"} ,
		{"word":"punaisée","word_nosc":"punaisee","lemma":"punaiser","pos":"VER"} ,
		{"word":"punaisées","word_nosc":"punaisees","lemma":"punaiser","pos":"VER"} ,
		{"word":"punaisés","word_nosc":"punaises","lemma":"punaiser","pos":"VER"} ,
		{"word":"puni","word_nosc":"puni","lemma":"punir","pos":"VER"} ,
		{"word":"punie","word_nosc":"punie","lemma":"punir","pos":"VER"} ,
		{"word":"punies","word_nosc":"punies","lemma":"punir","pos":"VER"} ,
		{"word":"punir","word_nosc":"punir","lemma":"punir","pos":"VER"} ,
		{"word":"punira","word_nosc":"punira","lemma":"punir","pos":"VER"} ,
		{"word":"punirai","word_nosc":"punirai","lemma":"punir","pos":"VER"} ,
		{"word":"punirais","word_nosc":"punirais","lemma":"punir","pos":"VER"} ,
		{"word":"punirait","word_nosc":"punirait","lemma":"punir","pos":"VER"} ,
		{"word":"puniras","word_nosc":"puniras","lemma":"punir","pos":"VER"} ,
		{"word":"punirez","word_nosc":"punirez","lemma":"punir","pos":"VER"} ,
		{"word":"punirons","word_nosc":"punirons","lemma":"punir","pos":"VER"} ,
		{"word":"puniront","word_nosc":"puniront","lemma":"punir","pos":"VER"} ,
		{"word":"punis","word_nosc":"punis","lemma":"punir","pos":"VER"} ,
		{"word":"punissaient","word_nosc":"punissaient","lemma":"punir","pos":"VER"} ,
		{"word":"punissais","word_nosc":"punissais","lemma":"punir","pos":"VER"} ,
		{"word":"punissait","word_nosc":"punissait","lemma":"punir","pos":"VER"} ,
		{"word":"punissant","word_nosc":"punissant","lemma":"punir","pos":"VER"} ,
		{"word":"punisse","word_nosc":"punisse","lemma":"punir","pos":"VER"} ,
		{"word":"punissent","word_nosc":"punissent","lemma":"punir","pos":"VER"} ,
		{"word":"punisses","word_nosc":"punisses","lemma":"punir","pos":"VER"} ,
		{"word":"punissez","word_nosc":"punissez","lemma":"punir","pos":"VER"} ,
		{"word":"punissions","word_nosc":"punissions","lemma":"punir","pos":"VER"} ,
		{"word":"punissons","word_nosc":"punissons","lemma":"punir","pos":"VER"} ,
		{"word":"punit","word_nosc":"punit","lemma":"punir","pos":"VER"} ,
		{"word":"purent","word_nosc":"purent","lemma":"pouvoir","pos":"VER"} ,
		{"word":"purge","word_nosc":"purge","lemma":"purger","pos":"VER"} ,
		{"word":"purgea","word_nosc":"purgea","lemma":"purger","pos":"VER"} ,
		{"word":"purgeaient","word_nosc":"purgeaient","lemma":"purger","pos":"VER"} ,
		{"word":"purgeais","word_nosc":"purgeais","lemma":"purger","pos":"VER"} ,
		{"word":"purgeait","word_nosc":"purgeait","lemma":"purger","pos":"VER"} ,
		{"word":"purgeant","word_nosc":"purgeant","lemma":"purger","pos":"VER"} ,
		{"word":"purgent","word_nosc":"purgent","lemma":"purger","pos":"VER"} ,
		{"word":"purger","word_nosc":"purger","lemma":"purger","pos":"VER"} ,
		{"word":"purgera","word_nosc":"purgera","lemma":"purger","pos":"VER"} ,
		{"word":"purgerai","word_nosc":"purgerai","lemma":"purger","pos":"VER"} ,
		{"word":"purgerais","word_nosc":"purgerais","lemma":"purger","pos":"VER"} ,
		{"word":"purgeras","word_nosc":"purgeras","lemma":"purger","pos":"VER"} ,
		{"word":"purgerez","word_nosc":"purgerez","lemma":"purger","pos":"VER"} ,
		{"word":"purgeriez","word_nosc":"purgeriez","lemma":"purger","pos":"VER"} ,
		{"word":"purges","word_nosc":"purges","lemma":"purger","pos":"VER"} ,
		{"word":"purgez","word_nosc":"purgez","lemma":"purger","pos":"VER"} ,
		{"word":"purgiez","word_nosc":"purgiez","lemma":"purger","pos":"VER"} ,
		{"word":"purgé","word_nosc":"purge","lemma":"purger","pos":"VER"} ,
		{"word":"purgée","word_nosc":"purgee","lemma":"purger","pos":"VER"} ,
		{"word":"purgées","word_nosc":"purgees","lemma":"purger","pos":"VER"} ,
		{"word":"purgés","word_nosc":"purges","lemma":"purger","pos":"VER"} ,
		{"word":"purifiaient","word_nosc":"purifiaient","lemma":"purifier","pos":"VER"} ,
		{"word":"purifiais","word_nosc":"purifiais","lemma":"purifier","pos":"VER"} ,
		{"word":"purifiait","word_nosc":"purifiait","lemma":"purifier","pos":"VER"} ,
		{"word":"purifiant","word_nosc":"purifiant","lemma":"purifier","pos":"VER"} ,
		{"word":"purifie","word_nosc":"purifie","lemma":"purifier","pos":"VER"} ,
		{"word":"purifient","word_nosc":"purifient","lemma":"purifier","pos":"VER"} ,
		{"word":"purifier","word_nosc":"purifier","lemma":"purifier","pos":"VER"} ,
		{"word":"purifiera","word_nosc":"purifiera","lemma":"purifier","pos":"VER"} ,
		{"word":"purifiez","word_nosc":"purifiez","lemma":"purifier","pos":"VER"} ,
		{"word":"purifions","word_nosc":"purifions","lemma":"purifier","pos":"VER"} ,
		{"word":"purifiât","word_nosc":"purifiat","lemma":"purifier","pos":"VER"} ,
		{"word":"purifié","word_nosc":"purifie","lemma":"purifier","pos":"VER"} ,
		{"word":"purifiée","word_nosc":"purifiee","lemma":"purifier","pos":"VER"} ,
		{"word":"purifiées","word_nosc":"purifiees","lemma":"purifier","pos":"VER"} ,
		{"word":"purifiés","word_nosc":"purifies","lemma":"purifier","pos":"VER"} ,
		{"word":"pus","word_nosc":"pus","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pusse","word_nosc":"pusse","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pussent","word_nosc":"pussent","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pusses","word_nosc":"pusses","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pussiez","word_nosc":"pussiez","lemma":"pouvoir","pos":"VER"} ,
		{"word":"put","word_nosc":"put","lemma":"pouvoir","pos":"VER"} ,
		{"word":"putasse","word_nosc":"putasse","lemma":"putasser","pos":"VER"} ,
		{"word":"putasses","word_nosc":"putasses","lemma":"putasser","pos":"VER"} ,
		{"word":"putréfiaient","word_nosc":"putrefiaient","lemma":"putréfier","pos":"VER"} ,
		{"word":"putréfiait","word_nosc":"putrefiait","lemma":"putréfier","pos":"VER"} ,
		{"word":"putréfiant","word_nosc":"putrefiant","lemma":"putréfier","pos":"VER"} ,
		{"word":"putréfient","word_nosc":"putrefient","lemma":"putréfier","pos":"VER"} ,
		{"word":"putréfier","word_nosc":"putrefier","lemma":"putréfier","pos":"VER"} ,
		{"word":"putréfié","word_nosc":"putrefie","lemma":"putréfier","pos":"VER"} ,
		{"word":"putréfiée","word_nosc":"putrefiee","lemma":"putréfier","pos":"VER"} ,
		{"word":"putréfiées","word_nosc":"putrefiees","lemma":"putréfier","pos":"VER"} ,
		{"word":"putréfiés","word_nosc":"putrefies","lemma":"putréfier","pos":"VER"} ,
		{"word":"putter","word_nosc":"putter","lemma":"putter","pos":"VER"} ,
		{"word":"pyrogravées","word_nosc":"pyrogravees","lemma":"pyrograver","pos":"VER"} ,
		{"word":"pâli","word_nosc":"pali","lemma":"pâlir","pos":"VER"} ,
		{"word":"pâlie","word_nosc":"palie","lemma":"pâlir","pos":"VER"} ,
		{"word":"pâlies","word_nosc":"palies","lemma":"pâlir","pos":"VER"} ,
		{"word":"pâlir","word_nosc":"palir","lemma":"pâlir","pos":"VER"} ,
		{"word":"pâlirait","word_nosc":"palirait","lemma":"pâlir","pos":"VER"} ,
		{"word":"pâlirent","word_nosc":"palirent","lemma":"pâlir","pos":"VER"} ,
		{"word":"pâlis","word_nosc":"palis","lemma":"pâlir","pos":"VER"} ,
		{"word":"pâlissaient","word_nosc":"palissaient","lemma":"pâlir","pos":"VER"} ,
		{"word":"pâlissait","word_nosc":"palissait","lemma":"pâlir","pos":"VER"} ,
		{"word":"pâlissant","word_nosc":"palissant","lemma":"pâlir","pos":"VER"} ,
		{"word":"pâlissent","word_nosc":"palissent","lemma":"pâlir","pos":"VER"} ,
		{"word":"pâlissions","word_nosc":"palissions","lemma":"pâlir","pos":"VER"} ,
		{"word":"pâlit","word_nosc":"palit","lemma":"pâlir","pos":"VER"} ,
		{"word":"pâma","word_nosc":"pama","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâmaient","word_nosc":"pamaient","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâmait","word_nosc":"pamait","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâmant","word_nosc":"pamant","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâme","word_nosc":"pame","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâment","word_nosc":"pament","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâmer","word_nosc":"pamer","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâmerais","word_nosc":"pamerais","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâmerait","word_nosc":"pamerait","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâmeront","word_nosc":"pameront","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâmez","word_nosc":"pamez","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâmèrent","word_nosc":"pamerent","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâmé","word_nosc":"pame","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâmée","word_nosc":"pamee","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâmées","word_nosc":"pamees","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâmés","word_nosc":"pames","lemma":"pâmer","pos":"VER"} ,
		{"word":"pâti","word_nosc":"pati","lemma":"pâtir","pos":"VER"} ,
		{"word":"pâtir","word_nosc":"patir","lemma":"pâtir","pos":"VER"} ,
		{"word":"pâtira","word_nosc":"patira","lemma":"pâtir","pos":"VER"} ,
		{"word":"pâtiraient","word_nosc":"patiraient","lemma":"pâtir","pos":"VER"} ,
		{"word":"pâtirait","word_nosc":"patirait","lemma":"pâtir","pos":"VER"} ,
		{"word":"pâtirent","word_nosc":"patirent","lemma":"pâtir","pos":"VER"} ,
		{"word":"pâtiront","word_nosc":"patiront","lemma":"pâtir","pos":"VER"} ,
		{"word":"pâtis","word_nosc":"patis","lemma":"pâtir","pos":"VER"} ,
		{"word":"pâtissaient","word_nosc":"patissaient","lemma":"pâtisser","pos":"VER"} ,
		{"word":"pâtissais","word_nosc":"patissais","lemma":"pâtisser","pos":"VER"} ,
		{"word":"pâtissait","word_nosc":"patissait","lemma":"pâtisser","pos":"VER"} ,
		{"word":"pâtisse","word_nosc":"patisse","lemma":"pâtisser","pos":"VER"} ,
		{"word":"pâtissent","word_nosc":"patissent","lemma":"pâtisser","pos":"VER"} ,
		{"word":"pâtisses","word_nosc":"patisses","lemma":"pâtisser","pos":"VER"} ,
		{"word":"pâtissez","word_nosc":"patissez","lemma":"pâtisser","pos":"VER"} ,
		{"word":"pâtissons","word_nosc":"patissons","lemma":"pâtir","pos":"VER"} ,
		{"word":"pâtit","word_nosc":"patit","lemma":"pâtir","pos":"VER"} ,
		{"word":"pâturaient","word_nosc":"paturaient","lemma":"pâturer","pos":"VER"} ,
		{"word":"pâturent","word_nosc":"paturent","lemma":"pâturer","pos":"VER"} ,
		{"word":"pâturer","word_nosc":"paturer","lemma":"pâturer","pos":"VER"} ,
		{"word":"pâturé","word_nosc":"pature","lemma":"pâturer","pos":"VER"} ,
		{"word":"pâtît","word_nosc":"patit","lemma":"pâtir","pos":"VER"} ,
		{"word":"pèche","word_nosc":"peche","lemma":"pécher","pos":"VER"} ,
		{"word":"pèchent","word_nosc":"pechent","lemma":"pécher","pos":"VER"} ,
		{"word":"pèle","word_nosc":"pele","lemma":"peler","pos":"VER"} ,
		{"word":"pèlent","word_nosc":"pelent","lemma":"peler","pos":"VER"} ,
		{"word":"pèlerai","word_nosc":"pelerai","lemma":"peler","pos":"VER"} ,
		{"word":"pèles","word_nosc":"peles","lemma":"peler","pos":"VER"} ,
		{"word":"pèse","word_nosc":"pese","lemma":"peser","pos":"VER"} ,
		{"word":"pèsent","word_nosc":"pesent","lemma":"peser","pos":"VER"} ,
		{"word":"pèsera","word_nosc":"pesera","lemma":"peser","pos":"VER"} ,
		{"word":"pèserai","word_nosc":"peserai","lemma":"peser","pos":"VER"} ,
		{"word":"pèseraient","word_nosc":"peseraient","lemma":"peser","pos":"VER"} ,
		{"word":"pèserais","word_nosc":"peserais","lemma":"peser","pos":"VER"} ,
		{"word":"pèserait","word_nosc":"peserait","lemma":"peser","pos":"VER"} ,
		{"word":"pèseras","word_nosc":"peseras","lemma":"peser","pos":"VER"} ,
		{"word":"pèseront","word_nosc":"peseront","lemma":"peser","pos":"VER"} ,
		{"word":"pèses","word_nosc":"peses","lemma":"peser","pos":"VER"} ,
		{"word":"pète","word_nosc":"pete","lemma":"péter","pos":"VER"} ,
		{"word":"pètent","word_nosc":"petent","lemma":"péter","pos":"VER"} ,
		{"word":"pètes","word_nosc":"petes","lemma":"péter","pos":"VER"} ,
		{"word":"péchais","word_nosc":"pechais","lemma":"pécher","pos":"VER"} ,
		{"word":"péchait","word_nosc":"pechait","lemma":"pécher","pos":"VER"} ,
		{"word":"péchant","word_nosc":"pechant","lemma":"pécher","pos":"VER"} ,
		{"word":"pécher","word_nosc":"pecher","lemma":"pécher","pos":"VER"} ,
		{"word":"pécherai","word_nosc":"pecherai","lemma":"pécher","pos":"VER"} ,
		{"word":"pécherais","word_nosc":"pecherais","lemma":"pécher","pos":"VER"} ,
		{"word":"pécherait","word_nosc":"pecherait","lemma":"pécher","pos":"VER"} ,
		{"word":"péchez","word_nosc":"pechez","lemma":"pécher","pos":"VER"} ,
		{"word":"péché","word_nosc":"peche","lemma":"pécher","pos":"VER"} ,
		{"word":"péchés","word_nosc":"peches","lemma":"pécher","pos":"VER"} ,
		{"word":"pédala","word_nosc":"pedala","lemma":"pédaler","pos":"VER"} ,
		{"word":"pédalai","word_nosc":"pedalai","lemma":"pédaler","pos":"VER"} ,
		{"word":"pédalaient","word_nosc":"pedalaient","lemma":"pédaler","pos":"VER"} ,
		{"word":"pédalais","word_nosc":"pedalais","lemma":"pédaler","pos":"VER"} ,
		{"word":"pédalait","word_nosc":"pedalait","lemma":"pédaler","pos":"VER"} ,
		{"word":"pédalant","word_nosc":"pedalant","lemma":"pédaler","pos":"VER"} ,
		{"word":"pédale","word_nosc":"pedale","lemma":"pédaler","pos":"VER"} ,
		{"word":"pédalent","word_nosc":"pedalent","lemma":"pédaler","pos":"VER"} ,
		{"word":"pédaler","word_nosc":"pedaler","lemma":"pédaler","pos":"VER"} ,
		{"word":"pédalerait","word_nosc":"pedalerait","lemma":"pédaler","pos":"VER"} ,
		{"word":"pédalerons","word_nosc":"pedalerons","lemma":"pédaler","pos":"VER"} ,
		{"word":"pédales","word_nosc":"pedales","lemma":"pédaler","pos":"VER"} ,
		{"word":"pédalèrent","word_nosc":"pedalerent","lemma":"pédaler","pos":"VER"} ,
		{"word":"pédalé","word_nosc":"pedale","lemma":"pédaler","pos":"VER"} ,
		{"word":"pédalées","word_nosc":"pedalees","lemma":"pédaler","pos":"VER"} ,
		{"word":"pénalisaient","word_nosc":"penalisaient","lemma":"pénaliser","pos":"VER"} ,
		{"word":"pénalise","word_nosc":"penalise","lemma":"pénaliser","pos":"VER"} ,
		{"word":"pénaliser","word_nosc":"penaliser","lemma":"pénaliser","pos":"VER"} ,
		{"word":"pénalisera","word_nosc":"penalisera","lemma":"pénaliser","pos":"VER"} ,
		{"word":"pénaliserai","word_nosc":"penaliserai","lemma":"pénaliser","pos":"VER"} ,
		{"word":"pénalisé","word_nosc":"penalise","lemma":"pénaliser","pos":"VER"} ,
		{"word":"pénalisée","word_nosc":"penalisee","lemma":"pénaliser","pos":"VER"} ,
		{"word":"pénalisés","word_nosc":"penalises","lemma":"pénaliser","pos":"VER"} ,
		{"word":"pénètre","word_nosc":"penetre","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénètrent","word_nosc":"penetrent","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénètres","word_nosc":"penetres","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétra","word_nosc":"penetra","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrai","word_nosc":"penetrai","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétraient","word_nosc":"penetraient","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrais","word_nosc":"penetrais","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrait","word_nosc":"penetrait","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrant","word_nosc":"penetrant","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrer","word_nosc":"penetrer","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrera","word_nosc":"penetrera","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrerai","word_nosc":"penetrerai","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétreraient","word_nosc":"penetreraient","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrerait","word_nosc":"penetrerait","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrerons","word_nosc":"penetrerons","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétreront","word_nosc":"penetreront","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrez","word_nosc":"penetrez","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrions","word_nosc":"penetrions","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrons","word_nosc":"penetrons","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrâmes","word_nosc":"penetrames","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrât","word_nosc":"penetrat","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrèrent","word_nosc":"penetrerent","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétré","word_nosc":"penetre","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrée","word_nosc":"penetree","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrées","word_nosc":"penetrees","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pénétrés","word_nosc":"penetres","lemma":"pénétrer","pos":"VER"} ,
		{"word":"pépia","word_nosc":"pepia","lemma":"pépier","pos":"VER"} ,
		{"word":"pépiaient","word_nosc":"pepiaient","lemma":"pépier","pos":"VER"} ,
		{"word":"pépiait","word_nosc":"pepiait","lemma":"pépier","pos":"VER"} ,
		{"word":"pépie","word_nosc":"pepie","lemma":"pépier","pos":"VER"} ,
		{"word":"pépient","word_nosc":"pepient","lemma":"pépier","pos":"VER"} ,
		{"word":"pépier","word_nosc":"pepier","lemma":"pépier","pos":"VER"} ,
		{"word":"pépiés","word_nosc":"pepies","lemma":"pépier","pos":"VER"} ,
		{"word":"pérennise","word_nosc":"perennise","lemma":"pérenniser","pos":"VER"} ,
		{"word":"péri","word_nosc":"peri","lemma":"périr","pos":"VER"} ,
		{"word":"périclita","word_nosc":"periclita","lemma":"péricliter","pos":"VER"} ,
		{"word":"périclitait","word_nosc":"periclitait","lemma":"péricliter","pos":"VER"} ,
		{"word":"périclitant","word_nosc":"periclitant","lemma":"péricliter","pos":"VER"} ,
		{"word":"périclitent","word_nosc":"periclitent","lemma":"péricliter","pos":"VER"} ,
		{"word":"péricliter","word_nosc":"pericliter","lemma":"péricliter","pos":"VER"} ,
		{"word":"périclitèrent","word_nosc":"pericliterent","lemma":"péricliter","pos":"VER"} ,
		{"word":"périclité","word_nosc":"periclite","lemma":"péricliter","pos":"VER"} ,
		{"word":"périe","word_nosc":"perie","lemma":"périr","pos":"VER"} ,
		{"word":"périme","word_nosc":"perime","lemma":"périmer","pos":"VER"} ,
		{"word":"périment","word_nosc":"periment","lemma":"périmer","pos":"VER"} ,
		{"word":"périmer","word_nosc":"perimer","lemma":"périmer","pos":"VER"} ,
		{"word":"périmé","word_nosc":"perime","lemma":"périmer","pos":"VER"} ,
		{"word":"périmée","word_nosc":"perimee","lemma":"périmer","pos":"VER"} ,
		{"word":"périmées","word_nosc":"perimees","lemma":"périmer","pos":"VER"} ,
		{"word":"périmés","word_nosc":"perimes","lemma":"périmer","pos":"VER"} ,
		{"word":"périr","word_nosc":"perir","lemma":"périr","pos":"VER"} ,
		{"word":"périra","word_nosc":"perira","lemma":"périr","pos":"VER"} ,
		{"word":"périrais","word_nosc":"perirais","lemma":"périr","pos":"VER"} ,
		{"word":"périrait","word_nosc":"perirait","lemma":"périr","pos":"VER"} ,
		{"word":"périras","word_nosc":"periras","lemma":"périr","pos":"VER"} ,
		{"word":"périrent","word_nosc":"perirent","lemma":"périr","pos":"VER"} ,
		{"word":"périrez","word_nosc":"perirez","lemma":"périr","pos":"VER"} ,
		{"word":"péririez","word_nosc":"peririez","lemma":"périr","pos":"VER"} ,
		{"word":"périrons","word_nosc":"perirons","lemma":"périr","pos":"VER"} ,
		{"word":"périront","word_nosc":"periront","lemma":"périr","pos":"VER"} ,
		{"word":"péris","word_nosc":"peris","lemma":"périr","pos":"VER"} ,
		{"word":"périssaient","word_nosc":"perissaient","lemma":"périr","pos":"VER"} ,
		{"word":"périssait","word_nosc":"perissait","lemma":"périr","pos":"VER"} ,
		{"word":"périssant","word_nosc":"perissant","lemma":"périr","pos":"VER"} ,
		{"word":"périsse","word_nosc":"perisse","lemma":"périr","pos":"VER"} ,
		{"word":"périssent","word_nosc":"perissent","lemma":"périr","pos":"VER"} ,
		{"word":"périssez","word_nosc":"perissez","lemma":"périr","pos":"VER"} ,
		{"word":"périssons","word_nosc":"perissons","lemma":"périr","pos":"VER"} ,
		{"word":"périt","word_nosc":"perit","lemma":"périr","pos":"VER"} ,
		{"word":"pérorais","word_nosc":"perorais","lemma":"pérorer","pos":"VER"} ,
		{"word":"pérorait","word_nosc":"perorait","lemma":"pérorer","pos":"VER"} ,
		{"word":"pérorant","word_nosc":"perorant","lemma":"pérorer","pos":"VER"} ,
		{"word":"pérore","word_nosc":"perore","lemma":"pérorer","pos":"VER"} ,
		{"word":"pérorer","word_nosc":"perorer","lemma":"pérorer","pos":"VER"} ,
		{"word":"pérorons","word_nosc":"perorons","lemma":"pérorer","pos":"VER"} ,
		{"word":"pérégrinant","word_nosc":"peregrinant","lemma":"pérégriner","pos":"VER"} ,
		{"word":"pérégrine","word_nosc":"peregrine","lemma":"pérégriner","pos":"VER"} ,
		{"word":"pérégriner","word_nosc":"peregriner","lemma":"pérégriner","pos":"VER"} ,
		{"word":"pérégrines","word_nosc":"peregrines","lemma":"pérégriner","pos":"VER"} ,
		{"word":"pérît","word_nosc":"perit","lemma":"périr","pos":"VER"} ,
		{"word":"pétaient","word_nosc":"petaient","lemma":"péter","pos":"VER"} ,
		{"word":"pétais","word_nosc":"petais","lemma":"péter","pos":"VER"} ,
		{"word":"pétait","word_nosc":"petait","lemma":"péter","pos":"VER"} ,
		{"word":"pétant","word_nosc":"petant","lemma":"péter","pos":"VER"} ,
		{"word":"pétaradaient","word_nosc":"petaradaient","lemma":"pétarader","pos":"VER"} ,
		{"word":"pétaradait","word_nosc":"petaradait","lemma":"pétarader","pos":"VER"} ,
		{"word":"pétaradant","word_nosc":"petaradant","lemma":"pétarader","pos":"VER"} ,
		{"word":"pétarade","word_nosc":"petarade","lemma":"pétarader","pos":"VER"} ,
		{"word":"pétarader","word_nosc":"petarader","lemma":"pétarader","pos":"VER"} ,
		{"word":"pétarades","word_nosc":"petarades","lemma":"pétarader","pos":"VER"} ,
		{"word":"pétaradé","word_nosc":"petarade","lemma":"pétarader","pos":"VER"} ,
		{"word":"pétarde","word_nosc":"petarde","lemma":"pétarder","pos":"VER"} ,
		{"word":"pétassent","word_nosc":"petassent","lemma":"pétasser","pos":"VER"} ,
		{"word":"péter","word_nosc":"peter","lemma":"péter","pos":"VER"} ,
		{"word":"pétera","word_nosc":"petera","lemma":"péter","pos":"VER"} ,
		{"word":"péterai","word_nosc":"peterai","lemma":"péter","pos":"VER"} ,
		{"word":"péterais","word_nosc":"peterais","lemma":"péter","pos":"VER"} ,
		{"word":"péterait","word_nosc":"peterait","lemma":"péter","pos":"VER"} ,
		{"word":"péteras","word_nosc":"peteras","lemma":"péter","pos":"VER"} ,
		{"word":"pétez","word_nosc":"petez","lemma":"péter","pos":"VER"} ,
		{"word":"pétiez","word_nosc":"petiez","lemma":"péter","pos":"VER"} ,
		{"word":"pétilla","word_nosc":"petilla","lemma":"pétiller","pos":"VER"} ,
		{"word":"pétillaient","word_nosc":"petillaient","lemma":"pétiller","pos":"VER"} ,
		{"word":"pétillait","word_nosc":"petillait","lemma":"pétiller","pos":"VER"} ,
		{"word":"pétillant","word_nosc":"petillant","lemma":"pétiller","pos":"VER"} ,
		{"word":"pétille","word_nosc":"petille","lemma":"pétiller","pos":"VER"} ,
		{"word":"pétillent","word_nosc":"petillent","lemma":"pétiller","pos":"VER"} ,
		{"word":"pétiller","word_nosc":"petiller","lemma":"pétiller","pos":"VER"} ,
		{"word":"pétilles","word_nosc":"petilles","lemma":"pétiller","pos":"VER"} ,
		{"word":"pétillèrent","word_nosc":"petillerent","lemma":"pétiller","pos":"VER"} ,
		{"word":"pétillé","word_nosc":"petille","lemma":"pétiller","pos":"VER"} ,
		{"word":"pétitionnent","word_nosc":"petitionnent","lemma":"pétitionner","pos":"VER"} ,
		{"word":"pétitionner","word_nosc":"petitionner","lemma":"pétitionner","pos":"VER"} ,
		{"word":"pétochais","word_nosc":"petochais","lemma":"pétocher","pos":"VER"} ,
		{"word":"pétochait","word_nosc":"petochait","lemma":"pétocher","pos":"VER"} ,
		{"word":"pétons","word_nosc":"petons","lemma":"péter","pos":"VER"} ,
		{"word":"pétouille","word_nosc":"petouille","lemma":"pétouiller","pos":"VER"} ,
		{"word":"pétri","word_nosc":"petri","lemma":"pétrir","pos":"VER"} ,
		{"word":"pétrie","word_nosc":"petrie","lemma":"pétrir","pos":"VER"} ,
		{"word":"pétries","word_nosc":"petries","lemma":"pétrir","pos":"VER"} ,
		{"word":"pétrifia","word_nosc":"petrifia","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrifiaient","word_nosc":"petrifiaient","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrifiais","word_nosc":"petrifiais","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrifiait","word_nosc":"petrifiait","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrifiant","word_nosc":"petrifiant","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrifie","word_nosc":"petrifie","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrifient","word_nosc":"petrifient","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrifier","word_nosc":"petrifier","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrifieront","word_nosc":"petrifieront","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrifiez","word_nosc":"petrifiez","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrifiât","word_nosc":"petrifiat","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrifièrent","word_nosc":"petrifierent","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrifié","word_nosc":"petrifie","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrifiée","word_nosc":"petrifiee","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrifiées","word_nosc":"petrifiees","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrifiés","word_nosc":"petrifies","lemma":"pétrifier","pos":"VER"} ,
		{"word":"pétrir","word_nosc":"petrir","lemma":"pétrir","pos":"VER"} ,
		{"word":"pétrirai","word_nosc":"petrirai","lemma":"pétrir","pos":"VER"} ,
		{"word":"pétrirent","word_nosc":"petrirent","lemma":"pétrir","pos":"VER"} ,
		{"word":"pétris","word_nosc":"petris","lemma":"pétrir","pos":"VER"} ,
		{"word":"pétrissaient","word_nosc":"petrissaient","lemma":"pétrir","pos":"VER"} ,
		{"word":"pétrissait","word_nosc":"petrissait","lemma":"pétrir","pos":"VER"} ,
		{"word":"pétrissant","word_nosc":"petrissant","lemma":"pétrir","pos":"VER"} ,
		{"word":"pétrisse","word_nosc":"petrisse","lemma":"pétrir","pos":"VER"} ,
		{"word":"pétrissent","word_nosc":"petrissent","lemma":"pétrir","pos":"VER"} ,
		{"word":"pétrissez","word_nosc":"petrissez","lemma":"pétrir","pos":"VER"} ,
		{"word":"pétrit","word_nosc":"petrit","lemma":"pétrir","pos":"VER"} ,
		{"word":"pétunant","word_nosc":"petunant","lemma":"pétuner","pos":"VER"} ,
		{"word":"pétuner","word_nosc":"petuner","lemma":"pétuner","pos":"VER"} ,
		{"word":"pétunez","word_nosc":"petunez","lemma":"pétuner","pos":"VER"} ,
		{"word":"pétuné","word_nosc":"petune","lemma":"pétuner","pos":"VER"} ,
		{"word":"pété","word_nosc":"pete","lemma":"péter","pos":"VER"} ,
		{"word":"pétée","word_nosc":"petee","lemma":"péter","pos":"VER"} ,
		{"word":"pétés","word_nosc":"petes","lemma":"péter","pos":"VER"} ,
		{"word":"pêcha","word_nosc":"pecha","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêchaient","word_nosc":"pechaient","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêchais","word_nosc":"pechais","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêchait","word_nosc":"pechait","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêchant","word_nosc":"pechant","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêche","word_nosc":"peche","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêchent","word_nosc":"pechent","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêcher","word_nosc":"pecher","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêchera","word_nosc":"pechera","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêcherais","word_nosc":"pecherais","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêcheras","word_nosc":"pecheras","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêcherons","word_nosc":"pecherons","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêches","word_nosc":"peches","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêchez","word_nosc":"pechez","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêchions","word_nosc":"pechions","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêché","word_nosc":"peche","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêchée","word_nosc":"pechee","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêchées","word_nosc":"pechees","lemma":"pêcher","pos":"VER"} ,
		{"word":"pêchés","word_nosc":"peches","lemma":"pêcher","pos":"VER"} ,
		{"word":"pûmes","word_nosc":"pumes","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pût","word_nosc":"put","lemma":"pouvoir","pos":"VER"} ,
		{"word":"pûtes","word_nosc":"putes","lemma":"pouvoir","pos":"VER"} ,
		{"word":"quadrillaient","word_nosc":"quadrillaient","lemma":"quadriller","pos":"VER"} ,
		{"word":"quadrillais","word_nosc":"quadrillais","lemma":"quadriller","pos":"VER"} ,
		{"word":"quadrillait","word_nosc":"quadrillait","lemma":"quadriller","pos":"VER"} ,
		{"word":"quadrille","word_nosc":"quadrille","lemma":"quadriller","pos":"VER"} ,
		{"word":"quadrillent","word_nosc":"quadrillent","lemma":"quadriller","pos":"VER"} ,
		{"word":"quadriller","word_nosc":"quadriller","lemma":"quadriller","pos":"VER"} ,
		{"word":"quadrillerez","word_nosc":"quadrillerez","lemma":"quadriller","pos":"VER"} ,
		{"word":"quadrillez","word_nosc":"quadrillez","lemma":"quadriller","pos":"VER"} ,
		{"word":"quadrillons","word_nosc":"quadrillons","lemma":"quadriller","pos":"VER"} ,
		{"word":"quadrillé","word_nosc":"quadrille","lemma":"quadriller","pos":"VER"} ,
		{"word":"quadrillée","word_nosc":"quadrillee","lemma":"quadriller","pos":"VER"} ,
		{"word":"quadrillées","word_nosc":"quadrillees","lemma":"quadriller","pos":"VER"} ,
		{"word":"quadrillés","word_nosc":"quadrilles","lemma":"quadriller","pos":"VER"} ,
		{"word":"quadrupla","word_nosc":"quadrupla","lemma":"quadrupler","pos":"VER"} ,
		{"word":"quadruplait","word_nosc":"quadruplait","lemma":"quadrupler","pos":"VER"} ,
		{"word":"quadruple","word_nosc":"quadruple","lemma":"quadrupler","pos":"VER"} ,
		{"word":"quadrupler","word_nosc":"quadrupler","lemma":"quadrupler","pos":"VER"} ,
		{"word":"quadruplé","word_nosc":"quadruple","lemma":"quadrupler","pos":"VER"} ,
		{"word":"quadruplés","word_nosc":"quadruples","lemma":"quadrupler","pos":"VER"} ,
		{"word":"qualifia","word_nosc":"qualifia","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifiaient","word_nosc":"qualifiaient","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifiais","word_nosc":"qualifiais","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifiait","word_nosc":"qualifiait","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifiant","word_nosc":"qualifiant","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifiassent","word_nosc":"qualifiassent","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifie","word_nosc":"qualifie","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifient","word_nosc":"qualifient","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifier","word_nosc":"qualifier","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifiera","word_nosc":"qualifiera","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifierai","word_nosc":"qualifierai","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifierais","word_nosc":"qualifierais","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifierait","word_nosc":"qualifierait","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifieriez","word_nosc":"qualifieriez","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifiez","word_nosc":"qualifiez","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifiât","word_nosc":"qualifiat","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifié","word_nosc":"qualifie","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifiée","word_nosc":"qualifiee","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifiées","word_nosc":"qualifiees","lemma":"qualifier","pos":"VER"} ,
		{"word":"qualifiés","word_nosc":"qualifies","lemma":"qualifier","pos":"VER"} ,
		{"word":"quantifier","word_nosc":"quantifier","lemma":"quantifier","pos":"VER"} ,
		{"word":"quantifiée","word_nosc":"quantifiee","lemma":"quantifier","pos":"VER"} ,
		{"word":"quarrez","word_nosc":"quarrez","lemma":"quarrer","pos":"VER"} ,
		{"word":"quarter","word_nosc":"quarter","lemma":"quarter","pos":"VER"} ,
		{"word":"querella","word_nosc":"querella","lemma":"quereller","pos":"VER"} ,
		{"word":"querellaient","word_nosc":"querellaient","lemma":"quereller","pos":"VER"} ,
		{"word":"querellais","word_nosc":"querellais","lemma":"quereller","pos":"VER"} ,
		{"word":"querellait","word_nosc":"querellait","lemma":"quereller","pos":"VER"} ,
		{"word":"querellant","word_nosc":"querellant","lemma":"quereller","pos":"VER"} ,
		{"word":"querelle","word_nosc":"querelle","lemma":"quereller","pos":"VER"} ,
		{"word":"querellent","word_nosc":"querellent","lemma":"quereller","pos":"VER"} ,
		{"word":"quereller","word_nosc":"quereller","lemma":"quereller","pos":"VER"} ,
		{"word":"querelles","word_nosc":"querelles","lemma":"quereller","pos":"VER"} ,
		{"word":"querellez","word_nosc":"querellez","lemma":"quereller","pos":"VER"} ,
		{"word":"querellons","word_nosc":"querellons","lemma":"quereller","pos":"VER"} ,
		{"word":"querellèrent","word_nosc":"querellerent","lemma":"quereller","pos":"VER"} ,
		{"word":"querellé","word_nosc":"querelle","lemma":"quereller","pos":"VER"} ,
		{"word":"querellée","word_nosc":"querellee","lemma":"quereller","pos":"VER"} ,
		{"word":"querellées","word_nosc":"querellees","lemma":"quereller","pos":"VER"} ,
		{"word":"querellés","word_nosc":"querelles","lemma":"quereller","pos":"VER"} ,
		{"word":"questionna","word_nosc":"questionna","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnai","word_nosc":"questionnai","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnaient","word_nosc":"questionnaient","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnais","word_nosc":"questionnais","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnait","word_nosc":"questionnait","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnant","word_nosc":"questionnant","lemma":"questionner","pos":"VER"} ,
		{"word":"questionne","word_nosc":"questionne","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnent","word_nosc":"questionnent","lemma":"questionner","pos":"VER"} ,
		{"word":"questionner","word_nosc":"questionner","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnera","word_nosc":"questionnera","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnerai","word_nosc":"questionnerai","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnerait","word_nosc":"questionnerait","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnerons","word_nosc":"questionnerons","lemma":"questionner","pos":"VER"} ,
		{"word":"questionneront","word_nosc":"questionneront","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnez","word_nosc":"questionnez","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnions","word_nosc":"questionnions","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnons","word_nosc":"questionnons","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnât","word_nosc":"questionnat","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnèrent","word_nosc":"questionnerent","lemma":"questionner","pos":"VER"} ,
		{"word":"questionné","word_nosc":"questionne","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnée","word_nosc":"questionnee","lemma":"questionner","pos":"VER"} ,
		{"word":"questionnés","word_nosc":"questionnes","lemma":"questionner","pos":"VER"} ,
		{"word":"queuté","word_nosc":"queute","lemma":"queuter","pos":"VER"} ,
		{"word":"queutée","word_nosc":"queutee","lemma":"queuter","pos":"VER"} ,
		{"word":"quillai","word_nosc":"quillai","lemma":"quiller","pos":"VER"} ,
		{"word":"quiller","word_nosc":"quiller","lemma":"quiller","pos":"VER"} ,
		{"word":"quillée","word_nosc":"quillee","lemma":"quiller","pos":"VER"} ,
		{"word":"quimpe","word_nosc":"quimpe","lemma":"quimper","pos":"VER"} ,
		{"word":"quimpent","word_nosc":"quimpent","lemma":"quimper","pos":"VER"} ,
		{"word":"quimper","word_nosc":"quimper","lemma":"quimper","pos":"VER"} ,
		{"word":"quimpé","word_nosc":"quimpe","lemma":"quimper","pos":"VER"} ,
		{"word":"quintessencié","word_nosc":"quintessencie","lemma":"quintessencier","pos":"VER"} ,
		{"word":"quintupla","word_nosc":"quintupla","lemma":"quintupler","pos":"VER"} ,
		{"word":"quintuplé","word_nosc":"quintuple","lemma":"quintupler","pos":"VER"} ,
		{"word":"quintuplées","word_nosc":"quintuplees","lemma":"quintupler","pos":"VER"} ,
		{"word":"quintuplés","word_nosc":"quintuples","lemma":"quintupler","pos":"VER"} ,
		{"word":"quitta","word_nosc":"quitta","lemma":"quitter","pos":"VER"} ,
		{"word":"quittai","word_nosc":"quittai","lemma":"quitter","pos":"VER"} ,
		{"word":"quittaient","word_nosc":"quittaient","lemma":"quitter","pos":"VER"} ,
		{"word":"quittais","word_nosc":"quittais","lemma":"quitter","pos":"VER"} ,
		{"word":"quittait","word_nosc":"quittait","lemma":"quitter","pos":"VER"} ,
		{"word":"quittant","word_nosc":"quittant","lemma":"quitter","pos":"VER"} ,
		{"word":"quittas","word_nosc":"quittas","lemma":"quitter","pos":"VER"} ,
		{"word":"quittassent","word_nosc":"quittassent","lemma":"quitter","pos":"VER"} ,
		{"word":"quitte","word_nosc":"quitte","lemma":"quitter","pos":"VER"} ,
		{"word":"quittent","word_nosc":"quittent","lemma":"quitter","pos":"VER"} ,
		{"word":"quitter","word_nosc":"quitter","lemma":"quitter","pos":"VER"} ,
		{"word":"quittera","word_nosc":"quittera","lemma":"quitter","pos":"VER"} ,
		{"word":"quitterai","word_nosc":"quitterai","lemma":"quitter","pos":"VER"} ,
		{"word":"quitteraient","word_nosc":"quitteraient","lemma":"quitter","pos":"VER"} ,
		{"word":"quitterais","word_nosc":"quitterais","lemma":"quitter","pos":"VER"} ,
		{"word":"quitterait","word_nosc":"quitterait","lemma":"quitter","pos":"VER"} ,
		{"word":"quitteras","word_nosc":"quitteras","lemma":"quitter","pos":"VER"} ,
		{"word":"quitterez","word_nosc":"quitterez","lemma":"quitter","pos":"VER"} ,
		{"word":"quitteriez","word_nosc":"quitteriez","lemma":"quitter","pos":"VER"} ,
		{"word":"quitterions","word_nosc":"quitterions","lemma":"quitter","pos":"VER"} ,
		{"word":"quitterons","word_nosc":"quitterons","lemma":"quitter","pos":"VER"} ,
		{"word":"quitteront","word_nosc":"quitteront","lemma":"quitter","pos":"VER"} ,
		{"word":"quittes","word_nosc":"quittes","lemma":"quitter","pos":"VER"} ,
		{"word":"quittez","word_nosc":"quittez","lemma":"quitter","pos":"VER"} ,
		{"word":"quittiez","word_nosc":"quittiez","lemma":"quitter","pos":"VER"} ,
		{"word":"quittions","word_nosc":"quittions","lemma":"quitter","pos":"VER"} ,
		{"word":"quittons","word_nosc":"quittons","lemma":"quitter","pos":"VER"} ,
		{"word":"quittâmes","word_nosc":"quittames","lemma":"quitter","pos":"VER"} ,
		{"word":"quittât","word_nosc":"quittat","lemma":"quitter","pos":"VER"} ,
		{"word":"quittèrent","word_nosc":"quitterent","lemma":"quitter","pos":"VER"} ,
		{"word":"quitté","word_nosc":"quitte","lemma":"quitter","pos":"VER"} ,
		{"word":"quittée","word_nosc":"quittee","lemma":"quitter","pos":"VER"} ,
		{"word":"quittées","word_nosc":"quittees","lemma":"quitter","pos":"VER"} ,
		{"word":"quittés","word_nosc":"quittes","lemma":"quitter","pos":"VER"} ,
		{"word":"quoaillant","word_nosc":"quoaillant","lemma":"quoailler","pos":"VER"} ,
		{"word":"quémanda","word_nosc":"quemanda","lemma":"quémander","pos":"VER"} ,
		{"word":"quémandaient","word_nosc":"quemandaient","lemma":"quémander","pos":"VER"} ,
		{"word":"quémandait","word_nosc":"quemandait","lemma":"quémander","pos":"VER"} ,
		{"word":"quémandant","word_nosc":"quemandant","lemma":"quémander","pos":"VER"} ,
		{"word":"quémande","word_nosc":"quemande","lemma":"quémander","pos":"VER"} ,
		{"word":"quémandent","word_nosc":"quemandent","lemma":"quémander","pos":"VER"} ,
		{"word":"quémander","word_nosc":"quemander","lemma":"quémander","pos":"VER"} ,
		{"word":"quémandons","word_nosc":"quemandons","lemma":"quémander","pos":"VER"} ,
		{"word":"quémandé","word_nosc":"quemande","lemma":"quémander","pos":"VER"} ,
		{"word":"quérir","word_nosc":"querir","lemma":"quérir","pos":"VER"} ,
		{"word":"quêta","word_nosc":"queta","lemma":"quêter","pos":"VER"} ,
		{"word":"quêtai","word_nosc":"quetai","lemma":"quêter","pos":"VER"} ,
		{"word":"quêtaient","word_nosc":"quetaient","lemma":"quêter","pos":"VER"} ,
		{"word":"quêtais","word_nosc":"quetais","lemma":"quêter","pos":"VER"} ,
		{"word":"quêtait","word_nosc":"quetait","lemma":"quêter","pos":"VER"} ,
		{"word":"quêtant","word_nosc":"quetant","lemma":"quêter","pos":"VER"} ,
		{"word":"quête","word_nosc":"quete","lemma":"quêter","pos":"VER"} ,
		{"word":"quêtent","word_nosc":"quetent","lemma":"quêter","pos":"VER"} ,
		{"word":"quêter","word_nosc":"queter","lemma":"quêter","pos":"VER"} ,
		{"word":"quêtez","word_nosc":"quetez","lemma":"quêter","pos":"VER"} ,
		{"word":"quêté","word_nosc":"quete","lemma":"quêter","pos":"VER"} ,
		{"word":"quêtées","word_nosc":"quetees","lemma":"quêter","pos":"VER"} ,
		{"word":"rabaissa","word_nosc":"rabaissa","lemma":"rabaisser","pos":"VER"} ,
		{"word":"rabaissaient","word_nosc":"rabaissaient","lemma":"rabaisser","pos":"VER"} ,
		{"word":"rabaissais","word_nosc":"rabaissais","lemma":"rabaisser","pos":"VER"} ,
		{"word":"rabaissait","word_nosc":"rabaissait","lemma":"rabaisser","pos":"VER"} ,
		{"word":"rabaissant","word_nosc":"rabaissant","lemma":"rabaisser","pos":"VER"} ,
		{"word":"rabaisse","word_nosc":"rabaisse","lemma":"rabaisser","pos":"VER"} ,
		{"word":"rabaissent","word_nosc":"rabaissent","lemma":"rabaisser","pos":"VER"} ,
		{"word":"rabaisser","word_nosc":"rabaisser","lemma":"rabaisser","pos":"VER"} ,
		{"word":"rabaissera","word_nosc":"rabaissera","lemma":"rabaisser","pos":"VER"} ,
		{"word":"rabaisserait","word_nosc":"rabaisserait","lemma":"rabaisser","pos":"VER"} ,
		{"word":"rabaisses","word_nosc":"rabaisses","lemma":"rabaisser","pos":"VER"} ,
		{"word":"rabaissez","word_nosc":"rabaissez","lemma":"rabaisser","pos":"VER"} ,
		{"word":"rabaissé","word_nosc":"rabaisse","lemma":"rabaisser","pos":"VER"} ,
		{"word":"rabaissée","word_nosc":"rabaissee","lemma":"rabaisser","pos":"VER"} ,
		{"word":"rabaissées","word_nosc":"rabaissees","lemma":"rabaisser","pos":"VER"} ,
		{"word":"rabat","word_nosc":"rabat","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabats","word_nosc":"rabats","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattaient","word_nosc":"rabattaient","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattais","word_nosc":"rabattais","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattait","word_nosc":"rabattait","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattant","word_nosc":"rabattant","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabatte","word_nosc":"rabatte","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattent","word_nosc":"rabattent","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattez","word_nosc":"rabattez","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattions","word_nosc":"rabattions","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattirent","word_nosc":"rabattirent","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattis","word_nosc":"rabattis","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattit","word_nosc":"rabattit","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattra","word_nosc":"rabattra","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattraient","word_nosc":"rabattraient","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattrait","word_nosc":"rabattrait","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattras","word_nosc":"rabattras","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattre","word_nosc":"rabattre","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattrons","word_nosc":"rabattrons","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattu","word_nosc":"rabattu","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattue","word_nosc":"rabattue","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattues","word_nosc":"rabattues","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabattus","word_nosc":"rabattus","lemma":"rabattre","pos":"VER"} ,
		{"word":"rabiboche","word_nosc":"rabiboche","lemma":"rabibocher","pos":"VER"} ,
		{"word":"rabibochent","word_nosc":"rabibochent","lemma":"rabibocher","pos":"VER"} ,
		{"word":"rabibocher","word_nosc":"rabibocher","lemma":"rabibocher","pos":"VER"} ,
		{"word":"rabibocherez","word_nosc":"rabibocherez","lemma":"rabibocher","pos":"VER"} ,
		{"word":"rabiboché","word_nosc":"rabiboche","lemma":"rabibocher","pos":"VER"} ,
		{"word":"rabibochées","word_nosc":"rabibochees","lemma":"rabibocher","pos":"VER"} ,
		{"word":"rabibochés","word_nosc":"rabiboches","lemma":"rabibocher","pos":"VER"} ,
		{"word":"rabiotait","word_nosc":"rabiotait","lemma":"rabioter","pos":"VER"} ,
		{"word":"rabioter","word_nosc":"rabioter","lemma":"rabioter","pos":"VER"} ,
		{"word":"rabonnir","word_nosc":"rabonnir","lemma":"rabonnir","pos":"VER"} ,
		{"word":"rabotais","word_nosc":"rabotais","lemma":"raboter","pos":"VER"} ,
		{"word":"rabotait","word_nosc":"rabotait","lemma":"raboter","pos":"VER"} ,
		{"word":"rabotant","word_nosc":"rabotant","lemma":"raboter","pos":"VER"} ,
		{"word":"rabotent","word_nosc":"rabotent","lemma":"raboter","pos":"VER"} ,
		{"word":"raboter","word_nosc":"raboter","lemma":"raboter","pos":"VER"} ,
		{"word":"raboté","word_nosc":"rabote","lemma":"raboter","pos":"VER"} ,
		{"word":"rabotée","word_nosc":"rabotee","lemma":"raboter","pos":"VER"} ,
		{"word":"rabotées","word_nosc":"rabotees","lemma":"raboter","pos":"VER"} ,
		{"word":"rabotés","word_nosc":"rabotes","lemma":"raboter","pos":"VER"} ,
		{"word":"rabougri","word_nosc":"rabougri","lemma":"rabougrir","pos":"VER"} ,
		{"word":"rabougrie","word_nosc":"rabougrie","lemma":"rabougrir","pos":"VER"} ,
		{"word":"rabougrir","word_nosc":"rabougrir","lemma":"rabougrir","pos":"VER"} ,
		{"word":"rabougris","word_nosc":"rabougris","lemma":"rabougrir","pos":"VER"} ,
		{"word":"rabougrissent","word_nosc":"rabougrissent","lemma":"rabougrir","pos":"VER"} ,
		{"word":"rabougrit","word_nosc":"rabougrit","lemma":"rabougrir","pos":"VER"} ,
		{"word":"rabouler","word_nosc":"rabouler","lemma":"rabouler","pos":"VER"} ,
		{"word":"rabouter","word_nosc":"rabouter","lemma":"rabouter","pos":"VER"} ,
		{"word":"rabouté","word_nosc":"raboute","lemma":"rabouter","pos":"VER"} ,
		{"word":"rabroua","word_nosc":"rabroua","lemma":"rabrouer","pos":"VER"} ,
		{"word":"rabrouai","word_nosc":"rabrouai","lemma":"rabrouer","pos":"VER"} ,
		{"word":"rabrouaient","word_nosc":"rabrouaient","lemma":"rabrouer","pos":"VER"} ,
		{"word":"rabrouait","word_nosc":"rabrouait","lemma":"rabrouer","pos":"VER"} ,
		{"word":"rabrouant","word_nosc":"rabrouant","lemma":"rabrouer","pos":"VER"} ,
		{"word":"rabroue","word_nosc":"rabroue","lemma":"rabrouer","pos":"VER"} ,
		{"word":"rabrouer","word_nosc":"rabrouer","lemma":"rabrouer","pos":"VER"} ,
		{"word":"rabrouât","word_nosc":"rabrouat","lemma":"rabrouer","pos":"VER"} ,
		{"word":"rabrouèrent","word_nosc":"rabrouerent","lemma":"rabrouer","pos":"VER"} ,
		{"word":"rabroué","word_nosc":"rabroue","lemma":"rabrouer","pos":"VER"} ,
		{"word":"rabrouée","word_nosc":"rabrouee","lemma":"rabrouer","pos":"VER"} ,
		{"word":"rabâcha","word_nosc":"rabacha","lemma":"rabâcher","pos":"VER"} ,
		{"word":"rabâchaient","word_nosc":"rabachaient","lemma":"rabâcher","pos":"VER"} ,
		{"word":"rabâchais","word_nosc":"rabachais","lemma":"rabâcher","pos":"VER"} ,
		{"word":"rabâchait","word_nosc":"rabachait","lemma":"rabâcher","pos":"VER"} ,
		{"word":"rabâchant","word_nosc":"rabachant","lemma":"rabâcher","pos":"VER"} ,
		{"word":"rabâche","word_nosc":"rabache","lemma":"rabâcher","pos":"VER"} ,
		{"word":"rabâchent","word_nosc":"rabachent","lemma":"rabâcher","pos":"VER"} ,
		{"word":"rabâcher","word_nosc":"rabacher","lemma":"rabâcher","pos":"VER"} ,
		{"word":"rabâcherait","word_nosc":"rabacherait","lemma":"rabâcher","pos":"VER"} ,
		{"word":"rabâches","word_nosc":"rabaches","lemma":"rabâcher","pos":"VER"} ,
		{"word":"rabâché","word_nosc":"rabache","lemma":"rabâcher","pos":"VER"} ,
		{"word":"rabâchée","word_nosc":"rabachee","lemma":"rabâcher","pos":"VER"} ,
		{"word":"rabâchées","word_nosc":"rabachees","lemma":"rabâcher","pos":"VER"} ,
		{"word":"raccommoda","word_nosc":"raccommoda","lemma":"raccommoder","pos":"VER"} ,
		{"word":"raccommodaient","word_nosc":"raccommodaient","lemma":"raccommoder","pos":"VER"} ,
		{"word":"raccommodait","word_nosc":"raccommodait","lemma":"raccommoder","pos":"VER"} ,
		{"word":"raccommodant","word_nosc":"raccommodant","lemma":"raccommoder","pos":"VER"} ,
		{"word":"raccommode","word_nosc":"raccommode","lemma":"raccommoder","pos":"VER"} ,
		{"word":"raccommodent","word_nosc":"raccommodent","lemma":"raccommoder","pos":"VER"} ,
		{"word":"raccommoder","word_nosc":"raccommoder","lemma":"raccommoder","pos":"VER"} ,
		{"word":"raccommodera","word_nosc":"raccommodera","lemma":"raccommoder","pos":"VER"} ,
		{"word":"raccommoderai","word_nosc":"raccommoderai","lemma":"raccommoder","pos":"VER"} ,
		{"word":"raccommodez","word_nosc":"raccommodez","lemma":"raccommoder","pos":"VER"} ,
		{"word":"raccommodions","word_nosc":"raccommodions","lemma":"raccommoder","pos":"VER"} ,
		{"word":"raccommodé","word_nosc":"raccommode","lemma":"raccommoder","pos":"VER"} ,
		{"word":"raccommodée","word_nosc":"raccommodee","lemma":"raccommoder","pos":"VER"} ,
		{"word":"raccommodées","word_nosc":"raccommodees","lemma":"raccommoder","pos":"VER"} ,
		{"word":"raccommodés","word_nosc":"raccommodes","lemma":"raccommoder","pos":"VER"} ,
		{"word":"raccompagna","word_nosc":"raccompagna","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnai","word_nosc":"raccompagnai","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnaient","word_nosc":"raccompagnaient","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnais","word_nosc":"raccompagnais","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnait","word_nosc":"raccompagnait","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnant","word_nosc":"raccompagnant","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagne","word_nosc":"raccompagne","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnent","word_nosc":"raccompagnent","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagner","word_nosc":"raccompagner","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnera","word_nosc":"raccompagnera","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnerai","word_nosc":"raccompagnerai","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnerais","word_nosc":"raccompagnerais","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnerait","word_nosc":"raccompagnerait","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagneras","word_nosc":"raccompagneras","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnerez","word_nosc":"raccompagnerez","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnes","word_nosc":"raccompagnes","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnez","word_nosc":"raccompagnez","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnons","word_nosc":"raccompagnons","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnèrent","word_nosc":"raccompagnerent","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagné","word_nosc":"raccompagne","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnée","word_nosc":"raccompagnee","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccompagnés","word_nosc":"raccompagnes","lemma":"raccompagner","pos":"VER"} ,
		{"word":"raccordaient","word_nosc":"raccordaient","lemma":"raccorder","pos":"VER"} ,
		{"word":"raccordait","word_nosc":"raccordait","lemma":"raccorder","pos":"VER"} ,
		{"word":"raccordant","word_nosc":"raccordant","lemma":"raccorder","pos":"VER"} ,
		{"word":"raccorde","word_nosc":"raccorde","lemma":"raccorder","pos":"VER"} ,
		{"word":"raccordent","word_nosc":"raccordent","lemma":"raccorder","pos":"VER"} ,
		{"word":"raccorder","word_nosc":"raccorder","lemma":"raccorder","pos":"VER"} ,
		{"word":"raccorderait","word_nosc":"raccorderait","lemma":"raccorder","pos":"VER"} ,
		{"word":"raccordez","word_nosc":"raccordez","lemma":"raccorder","pos":"VER"} ,
		{"word":"raccordé","word_nosc":"raccorde","lemma":"raccorder","pos":"VER"} ,
		{"word":"raccordée","word_nosc":"raccordee","lemma":"raccorder","pos":"VER"} ,
		{"word":"raccordées","word_nosc":"raccordees","lemma":"raccorder","pos":"VER"} ,
		{"word":"raccordés","word_nosc":"raccordes","lemma":"raccorder","pos":"VER"} ,
		{"word":"raccourci","word_nosc":"raccourci","lemma":"raccourcir","pos":"VER"} ,
		{"word":"raccourcie","word_nosc":"raccourcie","lemma":"raccourcir","pos":"VER"} ,
		{"word":"raccourcies","word_nosc":"raccourcies","lemma":"raccourcir","pos":"VER"} ,
		{"word":"raccourcir","word_nosc":"raccourcir","lemma":"raccourcir","pos":"VER"} ,
		{"word":"raccourcira","word_nosc":"raccourcira","lemma":"raccourcir","pos":"VER"} ,
		{"word":"raccourciras","word_nosc":"raccourciras","lemma":"raccourcir","pos":"VER"} ,
		{"word":"raccourcirons","word_nosc":"raccourcirons","lemma":"raccourcir","pos":"VER"} ,
		{"word":"raccourcis","word_nosc":"raccourcis","lemma":"raccourcir","pos":"VER"} ,
		{"word":"raccourcissaient","word_nosc":"raccourcissaient","lemma":"raccourcir","pos":"VER"} ,
		{"word":"raccourcissait","word_nosc":"raccourcissait","lemma":"raccourcir","pos":"VER"} ,
		{"word":"raccourcissant","word_nosc":"raccourcissant","lemma":"raccourcir","pos":"VER"} ,
		{"word":"raccourcisse","word_nosc":"raccourcisse","lemma":"raccourcir","pos":"VER"} ,
		{"word":"raccourcissent","word_nosc":"raccourcissent","lemma":"raccourcir","pos":"VER"} ,
		{"word":"raccourcit","word_nosc":"raccourcit","lemma":"raccourcir","pos":"VER"} ,
		{"word":"raccrocha","word_nosc":"raccrocha","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrochai","word_nosc":"raccrochai","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrochaient","word_nosc":"raccrochaient","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrochais","word_nosc":"raccrochais","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrochait","word_nosc":"raccrochait","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrochant","word_nosc":"raccrochant","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccroche","word_nosc":"raccroche","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrochent","word_nosc":"raccrochent","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrocher","word_nosc":"raccrocher","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrochera","word_nosc":"raccrochera","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrocherai","word_nosc":"raccrocherai","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrocheraient","word_nosc":"raccrocheraient","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrocherais","word_nosc":"raccrocherais","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrocherait","word_nosc":"raccrocherait","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrocheras","word_nosc":"raccrocheras","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccroches","word_nosc":"raccroches","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrochez","word_nosc":"raccrochez","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrochons","word_nosc":"raccrochons","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrochèrent","word_nosc":"raccrocherent","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccroché","word_nosc":"raccroche","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrochée","word_nosc":"raccrochee","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrochées","word_nosc":"raccrochees","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccrochés","word_nosc":"raccroches","lemma":"raccrocher","pos":"VER"} ,
		{"word":"raccusa","word_nosc":"raccusa","lemma":"raccuser","pos":"VER"} ,
		{"word":"racheta","word_nosc":"racheta","lemma":"racheter","pos":"VER"} ,
		{"word":"rachetai","word_nosc":"rachetai","lemma":"racheter","pos":"VER"} ,
		{"word":"rachetaient","word_nosc":"rachetaient","lemma":"racheter","pos":"VER"} ,
		{"word":"rachetait","word_nosc":"rachetait","lemma":"racheter","pos":"VER"} ,
		{"word":"rachetant","word_nosc":"rachetant","lemma":"racheter","pos":"VER"} ,
		{"word":"racheter","word_nosc":"racheter","lemma":"racheter","pos":"VER"} ,
		{"word":"rachetez","word_nosc":"rachetez","lemma":"racheter","pos":"VER"} ,
		{"word":"rachetiez","word_nosc":"rachetiez","lemma":"racheter","pos":"VER"} ,
		{"word":"rachetât","word_nosc":"rachetat","lemma":"racheter","pos":"VER"} ,
		{"word":"rachetèrent","word_nosc":"racheterent","lemma":"racheter","pos":"VER"} ,
		{"word":"racheté","word_nosc":"rachete","lemma":"racheter","pos":"VER"} ,
		{"word":"rachetée","word_nosc":"rachetee","lemma":"racheter","pos":"VER"} ,
		{"word":"rachetées","word_nosc":"rachetees","lemma":"racheter","pos":"VER"} ,
		{"word":"rachetés","word_nosc":"rachetes","lemma":"racheter","pos":"VER"} ,
		{"word":"rachète","word_nosc":"rachete","lemma":"racheter","pos":"VER"} ,
		{"word":"rachètent","word_nosc":"rachetent","lemma":"racheter","pos":"VER"} ,
		{"word":"rachètera","word_nosc":"rachetera","lemma":"racheter","pos":"VER"} ,
		{"word":"rachèterai","word_nosc":"racheterai","lemma":"racheter","pos":"VER"} ,
		{"word":"rachèterais","word_nosc":"racheterais","lemma":"racheter","pos":"VER"} ,
		{"word":"rachèterait","word_nosc":"racheterait","lemma":"racheter","pos":"VER"} ,
		{"word":"rachèterez","word_nosc":"racheterez","lemma":"racheter","pos":"VER"} ,
		{"word":"rachèteront","word_nosc":"racheteront","lemma":"racheter","pos":"VER"} ,
		{"word":"racinait","word_nosc":"racinait","lemma":"raciner","pos":"VER"} ,
		{"word":"raciner","word_nosc":"raciner","lemma":"raciner","pos":"VER"} ,
		{"word":"racinée","word_nosc":"racinee","lemma":"raciner","pos":"VER"} ,
		{"word":"racinées","word_nosc":"racinees","lemma":"raciner","pos":"VER"} ,
		{"word":"racinés","word_nosc":"racines","lemma":"raciner","pos":"VER"} ,
		{"word":"rackette","word_nosc":"rackette","lemma":"racketter","pos":"VER"} ,
		{"word":"racketter","word_nosc":"racketter","lemma":"racketter","pos":"VER"} ,
		{"word":"rackettez","word_nosc":"rackettez","lemma":"racketter","pos":"VER"} ,
		{"word":"rackettons","word_nosc":"rackettons","lemma":"racketter","pos":"VER"} ,
		{"word":"racketté","word_nosc":"rackette","lemma":"racketter","pos":"VER"} ,
		{"word":"racla","word_nosc":"racla","lemma":"racler","pos":"VER"} ,
		{"word":"raclaient","word_nosc":"raclaient","lemma":"racler","pos":"VER"} ,
		{"word":"raclais","word_nosc":"raclais","lemma":"racler","pos":"VER"} ,
		{"word":"raclait","word_nosc":"raclait","lemma":"racler","pos":"VER"} ,
		{"word":"raclant","word_nosc":"raclant","lemma":"racler","pos":"VER"} ,
		{"word":"racle","word_nosc":"racle","lemma":"racler","pos":"VER"} ,
		{"word":"raclent","word_nosc":"raclent","lemma":"racler","pos":"VER"} ,
		{"word":"racler","word_nosc":"racler","lemma":"racler","pos":"VER"} ,
		{"word":"raclerai","word_nosc":"raclerai","lemma":"racler","pos":"VER"} ,
		{"word":"racleras","word_nosc":"racleras","lemma":"racler","pos":"VER"} ,
		{"word":"racles","word_nosc":"racles","lemma":"racler","pos":"VER"} ,
		{"word":"raclèrent","word_nosc":"raclerent","lemma":"racler","pos":"VER"} ,
		{"word":"raclé","word_nosc":"racle","lemma":"racler","pos":"VER"} ,
		{"word":"raclée","word_nosc":"raclee","lemma":"racler","pos":"VER"} ,
		{"word":"raclées","word_nosc":"raclees","lemma":"racler","pos":"VER"} ,
		{"word":"raclés","word_nosc":"racles","lemma":"racler","pos":"VER"} ,
		{"word":"racolaient","word_nosc":"racolaient","lemma":"racoler","pos":"VER"} ,
		{"word":"racolais","word_nosc":"racolais","lemma":"racoler","pos":"VER"} ,
		{"word":"racolait","word_nosc":"racolait","lemma":"racoler","pos":"VER"} ,
		{"word":"racolant","word_nosc":"racolant","lemma":"racoler","pos":"VER"} ,
		{"word":"racole","word_nosc":"racole","lemma":"racoler","pos":"VER"} ,
		{"word":"racolent","word_nosc":"racolent","lemma":"racoler","pos":"VER"} ,
		{"word":"racoler","word_nosc":"racoler","lemma":"racoler","pos":"VER"} ,
		{"word":"racoles","word_nosc":"racoles","lemma":"racoler","pos":"VER"} ,
		{"word":"racolé","word_nosc":"racole","lemma":"racoler","pos":"VER"} ,
		{"word":"raconta","word_nosc":"raconta","lemma":"raconter","pos":"VER"} ,
		{"word":"racontai","word_nosc":"racontai","lemma":"raconter","pos":"VER"} ,
		{"word":"racontaient","word_nosc":"racontaient","lemma":"raconter","pos":"VER"} ,
		{"word":"racontais","word_nosc":"racontais","lemma":"raconter","pos":"VER"} ,
		{"word":"racontait","word_nosc":"racontait","lemma":"raconter","pos":"VER"} ,
		{"word":"racontant","word_nosc":"racontant","lemma":"raconter","pos":"VER"} ,
		{"word":"racontas","word_nosc":"racontas","lemma":"raconter","pos":"VER"} ,
		{"word":"raconte","word_nosc":"raconte","lemma":"raconter","pos":"VER"} ,
		{"word":"racontent","word_nosc":"racontent","lemma":"raconter","pos":"VER"} ,
		{"word":"raconter","word_nosc":"raconter","lemma":"raconter","pos":"VER"} ,
		{"word":"racontera","word_nosc":"racontera","lemma":"raconter","pos":"VER"} ,
		{"word":"raconterai","word_nosc":"raconterai","lemma":"raconter","pos":"VER"} ,
		{"word":"raconteraient","word_nosc":"raconteraient","lemma":"raconter","pos":"VER"} ,
		{"word":"raconterais","word_nosc":"raconterais","lemma":"raconter","pos":"VER"} ,
		{"word":"raconterait","word_nosc":"raconterait","lemma":"raconter","pos":"VER"} ,
		{"word":"raconteras","word_nosc":"raconteras","lemma":"raconter","pos":"VER"} ,
		{"word":"raconterez","word_nosc":"raconterez","lemma":"raconter","pos":"VER"} ,
		{"word":"raconterons","word_nosc":"raconterons","lemma":"raconter","pos":"VER"} ,
		{"word":"raconteront","word_nosc":"raconteront","lemma":"raconter","pos":"VER"} ,
		{"word":"racontes","word_nosc":"racontes","lemma":"raconter","pos":"VER"} ,
		{"word":"racontez","word_nosc":"racontez","lemma":"raconter","pos":"VER"} ,
		{"word":"racontiez","word_nosc":"racontiez","lemma":"raconter","pos":"VER"} ,
		{"word":"racontions","word_nosc":"racontions","lemma":"raconter","pos":"VER"} ,
		{"word":"racontons","word_nosc":"racontons","lemma":"raconter","pos":"VER"} ,
		{"word":"racontât","word_nosc":"racontat","lemma":"raconter","pos":"VER"} ,
		{"word":"racontèrent","word_nosc":"raconterent","lemma":"raconter","pos":"VER"} ,
		{"word":"raconté","word_nosc":"raconte","lemma":"raconter","pos":"VER"} ,
		{"word":"racontée","word_nosc":"racontee","lemma":"raconter","pos":"VER"} ,
		{"word":"racontées","word_nosc":"racontees","lemma":"raconter","pos":"VER"} ,
		{"word":"racontés","word_nosc":"racontes","lemma":"raconter","pos":"VER"} ,
		{"word":"racorni","word_nosc":"racorni","lemma":"racornir","pos":"VER"} ,
		{"word":"racornie","word_nosc":"racornie","lemma":"racornir","pos":"VER"} ,
		{"word":"racornies","word_nosc":"racornies","lemma":"racornir","pos":"VER"} ,
		{"word":"racornir","word_nosc":"racornir","lemma":"racornir","pos":"VER"} ,
		{"word":"racornis","word_nosc":"racornis","lemma":"racornir","pos":"VER"} ,
		{"word":"racornissait","word_nosc":"racornissait","lemma":"racornir","pos":"VER"} ,
		{"word":"racornissent","word_nosc":"racornissent","lemma":"racornir","pos":"VER"} ,
		{"word":"racornit","word_nosc":"racornit","lemma":"racornir","pos":"VER"} ,
		{"word":"rada","word_nosc":"rada","lemma":"rader","pos":"VER"} ,
		{"word":"radasse","word_nosc":"radasse","lemma":"rader","pos":"VER"} ,
		{"word":"radasses","word_nosc":"radasses","lemma":"rader","pos":"VER"} ,
		{"word":"rader","word_nosc":"rader","lemma":"rader","pos":"VER"} ,
		{"word":"radicaliser","word_nosc":"radicaliser","lemma":"radicaliser","pos":"VER"} ,
		{"word":"radicalisé","word_nosc":"radicalise","lemma":"radicaliser","pos":"VER"} ,
		{"word":"radier","word_nosc":"radier","lemma":"radier","pos":"VER"} ,
		{"word":"radinait","word_nosc":"radinait","lemma":"radiner","pos":"VER"} ,
		{"word":"radine","word_nosc":"radine","lemma":"radiner","pos":"VER"} ,
		{"word":"radinent","word_nosc":"radinent","lemma":"radiner","pos":"VER"} ,
		{"word":"radiner","word_nosc":"radiner","lemma":"radiner","pos":"VER"} ,
		{"word":"radines","word_nosc":"radines","lemma":"radiner","pos":"VER"} ,
		{"word":"radinez","word_nosc":"radinez","lemma":"radiner","pos":"VER"} ,
		{"word":"radiné","word_nosc":"radine","lemma":"radiner","pos":"VER"} ,
		{"word":"radinée","word_nosc":"radinee","lemma":"radiner","pos":"VER"} ,
		{"word":"radiodiffusé","word_nosc":"radiodiffuse","lemma":"radiodiffuser","pos":"VER"} ,
		{"word":"radiodiffusée","word_nosc":"radiodiffusee","lemma":"radiodiffuser","pos":"VER"} ,
		{"word":"radiodiffusées","word_nosc":"radiodiffusees","lemma":"radiodiffuser","pos":"VER"} ,
		{"word":"radiographiant","word_nosc":"radiographiant","lemma":"radiographier","pos":"VER"} ,
		{"word":"radiographie","word_nosc":"radiographie","lemma":"radiographier","pos":"VER"} ,
		{"word":"radiographient","word_nosc":"radiographient","lemma":"radiographier","pos":"VER"} ,
		{"word":"radiographier","word_nosc":"radiographier","lemma":"radiographier","pos":"VER"} ,
		{"word":"radiographié","word_nosc":"radiographie","lemma":"radiographier","pos":"VER"} ,
		{"word":"radiographiée","word_nosc":"radiographiee","lemma":"radiographier","pos":"VER"} ,
		{"word":"radiographiés","word_nosc":"radiographies","lemma":"radiographier","pos":"VER"} ,
		{"word":"radioguidera","word_nosc":"radioguidera","lemma":"radioguider","pos":"VER"} ,
		{"word":"radié","word_nosc":"radie","lemma":"radier","pos":"VER"} ,
		{"word":"radiée","word_nosc":"radiee","lemma":"radier","pos":"VER"} ,
		{"word":"radiés","word_nosc":"radies","lemma":"radier","pos":"VER"} ,
		{"word":"radotait","word_nosc":"radotait","lemma":"radoter","pos":"VER"} ,
		{"word":"radotant","word_nosc":"radotant","lemma":"radoter","pos":"VER"} ,
		{"word":"radote","word_nosc":"radote","lemma":"radoter","pos":"VER"} ,
		{"word":"radotent","word_nosc":"radotent","lemma":"radoter","pos":"VER"} ,
		{"word":"radoter","word_nosc":"radoter","lemma":"radoter","pos":"VER"} ,
		{"word":"radotes","word_nosc":"radotes","lemma":"radoter","pos":"VER"} ,
		{"word":"radotez","word_nosc":"radotez","lemma":"radoter","pos":"VER"} ,
		{"word":"radoté","word_nosc":"radote","lemma":"radoter","pos":"VER"} ,
		{"word":"radoubait","word_nosc":"radoubait","lemma":"radouber","pos":"VER"} ,
		{"word":"radouber","word_nosc":"radouber","lemma":"radouber","pos":"VER"} ,
		{"word":"radouci","word_nosc":"radouci","lemma":"radoucir","pos":"VER"} ,
		{"word":"radoucie","word_nosc":"radoucie","lemma":"radoucir","pos":"VER"} ,
		{"word":"radoucir","word_nosc":"radoucir","lemma":"radoucir","pos":"VER"} ,
		{"word":"radouciraient","word_nosc":"radouciraient","lemma":"radoucir","pos":"VER"} ,
		{"word":"radoucis","word_nosc":"radoucis","lemma":"radoucir","pos":"VER"} ,
		{"word":"radoucissais","word_nosc":"radoucissais","lemma":"radoucir","pos":"VER"} ,
		{"word":"radoucissait","word_nosc":"radoucissait","lemma":"radoucir","pos":"VER"} ,
		{"word":"radoucit","word_nosc":"radoucit","lemma":"radoucir","pos":"VER"} ,
		{"word":"rafa","word_nosc":"rafa","lemma":"rafer","pos":"VER"} ,
		{"word":"rafe","word_nosc":"rafe","lemma":"rafer","pos":"VER"} ,
		{"word":"raffermi","word_nosc":"raffermi","lemma":"raffermir","pos":"VER"} ,
		{"word":"raffermie","word_nosc":"raffermie","lemma":"raffermir","pos":"VER"} ,
		{"word":"raffermir","word_nosc":"raffermir","lemma":"raffermir","pos":"VER"} ,
		{"word":"raffermira","word_nosc":"raffermira","lemma":"raffermir","pos":"VER"} ,
		{"word":"raffermis","word_nosc":"raffermis","lemma":"raffermir","pos":"VER"} ,
		{"word":"raffermissait","word_nosc":"raffermissait","lemma":"raffermir","pos":"VER"} ,
		{"word":"raffermissent","word_nosc":"raffermissent","lemma":"raffermir","pos":"VER"} ,
		{"word":"raffermit","word_nosc":"raffermit","lemma":"raffermir","pos":"VER"} ,
		{"word":"raffinait","word_nosc":"raffinait","lemma":"raffiner","pos":"VER"} ,
		{"word":"raffinant","word_nosc":"raffinant","lemma":"raffiner","pos":"VER"} ,
		{"word":"raffine","word_nosc":"raffine","lemma":"raffiner","pos":"VER"} ,
		{"word":"raffiner","word_nosc":"raffiner","lemma":"raffiner","pos":"VER"} ,
		{"word":"raffiné","word_nosc":"raffine","lemma":"raffiner","pos":"VER"} ,
		{"word":"raffinée","word_nosc":"raffinee","lemma":"raffiner","pos":"VER"} ,
		{"word":"raffinées","word_nosc":"raffinees","lemma":"raffiner","pos":"VER"} ,
		{"word":"raffinés","word_nosc":"raffines","lemma":"raffiner","pos":"VER"} ,
		{"word":"raffolaient","word_nosc":"raffolaient","lemma":"raffoler","pos":"VER"} ,
		{"word":"raffolais","word_nosc":"raffolais","lemma":"raffoler","pos":"VER"} ,
		{"word":"raffolait","word_nosc":"raffolait","lemma":"raffoler","pos":"VER"} ,
		{"word":"raffolant","word_nosc":"raffolant","lemma":"raffoler","pos":"VER"} ,
		{"word":"raffole","word_nosc":"raffole","lemma":"raffoler","pos":"VER"} ,
		{"word":"raffolent","word_nosc":"raffolent","lemma":"raffoler","pos":"VER"} ,
		{"word":"raffoler","word_nosc":"raffoler","lemma":"raffoler","pos":"VER"} ,
		{"word":"raffoleront","word_nosc":"raffoleront","lemma":"raffoler","pos":"VER"} ,
		{"word":"raffoles","word_nosc":"raffoles","lemma":"raffoler","pos":"VER"} ,
		{"word":"raffolez","word_nosc":"raffolez","lemma":"raffoler","pos":"VER"} ,
		{"word":"raffoliez","word_nosc":"raffoliez","lemma":"raffoler","pos":"VER"} ,
		{"word":"raffolé","word_nosc":"raffole","lemma":"raffoler","pos":"VER"} ,
		{"word":"rafistola","word_nosc":"rafistola","lemma":"rafistoler","pos":"VER"} ,
		{"word":"rafistolaient","word_nosc":"rafistolaient","lemma":"rafistoler","pos":"VER"} ,
		{"word":"rafistolais","word_nosc":"rafistolais","lemma":"rafistoler","pos":"VER"} ,
		{"word":"rafistolait","word_nosc":"rafistolait","lemma":"rafistoler","pos":"VER"} ,
		{"word":"rafistole","word_nosc":"rafistole","lemma":"rafistoler","pos":"VER"} ,
		{"word":"rafistoler","word_nosc":"rafistoler","lemma":"rafistoler","pos":"VER"} ,
		{"word":"rafistolez","word_nosc":"rafistolez","lemma":"rafistoler","pos":"VER"} ,
		{"word":"rafistolé","word_nosc":"rafistole","lemma":"rafistoler","pos":"VER"} ,
		{"word":"rafistolée","word_nosc":"rafistolee","lemma":"rafistoler","pos":"VER"} ,
		{"word":"rafistolées","word_nosc":"rafistolees","lemma":"rafistoler","pos":"VER"} ,
		{"word":"rafistolés","word_nosc":"rafistoles","lemma":"rafistoler","pos":"VER"} ,
		{"word":"rafla","word_nosc":"rafla","lemma":"rafler","pos":"VER"} ,
		{"word":"raflais","word_nosc":"raflais","lemma":"rafler","pos":"VER"} ,
		{"word":"raflait","word_nosc":"raflait","lemma":"rafler","pos":"VER"} ,
		{"word":"raflant","word_nosc":"raflant","lemma":"rafler","pos":"VER"} ,
		{"word":"rafle","word_nosc":"rafle","lemma":"rafler","pos":"VER"} ,
		{"word":"raflent","word_nosc":"raflent","lemma":"rafler","pos":"VER"} ,
		{"word":"rafler","word_nosc":"rafler","lemma":"rafler","pos":"VER"} ,
		{"word":"raflera","word_nosc":"raflera","lemma":"rafler","pos":"VER"} ,
		{"word":"raflerai","word_nosc":"raflerai","lemma":"rafler","pos":"VER"} ,
		{"word":"raflerait","word_nosc":"raflerait","lemma":"rafler","pos":"VER"} ,
		{"word":"rafles","word_nosc":"rafles","lemma":"rafler","pos":"VER"} ,
		{"word":"raflez","word_nosc":"raflez","lemma":"rafler","pos":"VER"} ,
		{"word":"raflé","word_nosc":"rafle","lemma":"rafler","pos":"VER"} ,
		{"word":"raflée","word_nosc":"raflee","lemma":"rafler","pos":"VER"} ,
		{"word":"raflées","word_nosc":"raflees","lemma":"rafler","pos":"VER"} ,
		{"word":"raflés","word_nosc":"rafles","lemma":"rafler","pos":"VER"} ,
		{"word":"rafraîchi","word_nosc":"rafraichi","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchie","word_nosc":"rafraichie","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchies","word_nosc":"rafraichies","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchir","word_nosc":"rafraichir","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchira","word_nosc":"rafraichira","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchirais","word_nosc":"rafraichirais","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchirait","word_nosc":"rafraichirait","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchirent","word_nosc":"rafraichirent","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchiront","word_nosc":"rafraichiront","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchis","word_nosc":"rafraichis","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchissaient","word_nosc":"rafraichissaient","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchissais","word_nosc":"rafraichissais","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchissait","word_nosc":"rafraichissait","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchissant","word_nosc":"rafraichissant","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchisse","word_nosc":"rafraichisse","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchissent","word_nosc":"rafraichissent","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchissez","word_nosc":"rafraichissez","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchissons","word_nosc":"rafraichissons","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"rafraîchit","word_nosc":"rafraichit","lemma":"rafraîchir","pos":"VER"} ,
		{"word":"ragaillardi","word_nosc":"ragaillardi","lemma":"ragaillardir","pos":"VER"} ,
		{"word":"ragaillardie","word_nosc":"ragaillardie","lemma":"ragaillardir","pos":"VER"} ,
		{"word":"ragaillardir","word_nosc":"ragaillardir","lemma":"ragaillardir","pos":"VER"} ,
		{"word":"ragaillardira","word_nosc":"ragaillardira","lemma":"ragaillardir","pos":"VER"} ,
		{"word":"ragaillardirait","word_nosc":"ragaillardirait","lemma":"ragaillardir","pos":"VER"} ,
		{"word":"ragaillardis","word_nosc":"ragaillardis","lemma":"ragaillardir","pos":"VER"} ,
		{"word":"ragaillardissaient","word_nosc":"ragaillardissaient","lemma":"ragaillardir","pos":"VER"} ,
		{"word":"ragaillardisse","word_nosc":"ragaillardisse","lemma":"ragaillardir","pos":"VER"} ,
		{"word":"ragaillardit","word_nosc":"ragaillardit","lemma":"ragaillardir","pos":"VER"} ,
		{"word":"rage","word_nosc":"rage","lemma":"rager","pos":"VER"} ,
		{"word":"ragea","word_nosc":"ragea","lemma":"rager","pos":"VER"} ,
		{"word":"rageaient","word_nosc":"rageaient","lemma":"rager","pos":"VER"} ,
		{"word":"rageais","word_nosc":"rageais","lemma":"rager","pos":"VER"} ,
		{"word":"rageait","word_nosc":"rageait","lemma":"rager","pos":"VER"} ,
		{"word":"rageant","word_nosc":"rageant","lemma":"rager","pos":"VER"} ,
		{"word":"ragent","word_nosc":"ragent","lemma":"rager","pos":"VER"} ,
		{"word":"rager","word_nosc":"rager","lemma":"rager","pos":"VER"} ,
		{"word":"rages","word_nosc":"rages","lemma":"rager","pos":"VER"} ,
		{"word":"ragez","word_nosc":"ragez","lemma":"rager","pos":"VER"} ,
		{"word":"ragota","word_nosc":"ragota","lemma":"ragoter","pos":"VER"} ,
		{"word":"ragotait","word_nosc":"ragotait","lemma":"ragoter","pos":"VER"} ,
		{"word":"ragote","word_nosc":"ragote","lemma":"ragoter","pos":"VER"} ,
		{"word":"ragoter","word_nosc":"ragoter","lemma":"ragoter","pos":"VER"} ,
		{"word":"ragoûtant","word_nosc":"ragoutant","lemma":"ragoûter","pos":"VER"} ,
		{"word":"ragrafait","word_nosc":"ragrafait","lemma":"ragrafer","pos":"VER"} ,
		{"word":"ragrafant","word_nosc":"ragrafant","lemma":"ragrafer","pos":"VER"} ,
		{"word":"ragrafe","word_nosc":"ragrafe","lemma":"ragrafer","pos":"VER"} ,
		{"word":"raidi","word_nosc":"raidi","lemma":"raidir","pos":"VER"} ,
		{"word":"raidie","word_nosc":"raidie","lemma":"raidir","pos":"VER"} ,
		{"word":"raidies","word_nosc":"raidies","lemma":"raidir","pos":"VER"} ,
		{"word":"raidir","word_nosc":"raidir","lemma":"raidir","pos":"VER"} ,
		{"word":"raidirent","word_nosc":"raidirent","lemma":"raidir","pos":"VER"} ,
		{"word":"raidis","word_nosc":"raidis","lemma":"raidir","pos":"VER"} ,
		{"word":"raidissaient","word_nosc":"raidissaient","lemma":"raidir","pos":"VER"} ,
		{"word":"raidissais","word_nosc":"raidissais","lemma":"raidir","pos":"VER"} ,
		{"word":"raidissait","word_nosc":"raidissait","lemma":"raidir","pos":"VER"} ,
		{"word":"raidissant","word_nosc":"raidissant","lemma":"raidir","pos":"VER"} ,
		{"word":"raidisse","word_nosc":"raidisse","lemma":"raidir","pos":"VER"} ,
		{"word":"raidissent","word_nosc":"raidissent","lemma":"raidir","pos":"VER"} ,
		{"word":"raidissez","word_nosc":"raidissez","lemma":"raidir","pos":"VER"} ,
		{"word":"raidit","word_nosc":"raidit","lemma":"raidir","pos":"VER"} ,
		{"word":"raie","word_nosc":"raie","lemma":"rayer","pos":"VER"} ,
		{"word":"raient","word_nosc":"raient","lemma":"rayer","pos":"VER"} ,
		{"word":"raies","word_nosc":"raies","lemma":"rayer","pos":"VER"} ,
		{"word":"railla","word_nosc":"railla","lemma":"railler","pos":"VER"} ,
		{"word":"raillaient","word_nosc":"raillaient","lemma":"railler","pos":"VER"} ,
		{"word":"raillait","word_nosc":"raillait","lemma":"railler","pos":"VER"} ,
		{"word":"raillant","word_nosc":"raillant","lemma":"railler","pos":"VER"} ,
		{"word":"raille","word_nosc":"raille","lemma":"railler","pos":"VER"} ,
		{"word":"railler","word_nosc":"railler","lemma":"railler","pos":"VER"} ,
		{"word":"raillera","word_nosc":"raillera","lemma":"railler","pos":"VER"} ,
		{"word":"raillerai","word_nosc":"raillerai","lemma":"railler","pos":"VER"} ,
		{"word":"raillez","word_nosc":"raillez","lemma":"railler","pos":"VER"} ,
		{"word":"raillèrent","word_nosc":"raillerent","lemma":"railler","pos":"VER"} ,
		{"word":"raillé","word_nosc":"raille","lemma":"railler","pos":"VER"} ,
		{"word":"raillée","word_nosc":"raillee","lemma":"railler","pos":"VER"} ,
		{"word":"raillés","word_nosc":"railles","lemma":"railler","pos":"VER"} ,
		{"word":"raine","word_nosc":"raine","lemma":"rainer","pos":"VER"} ,
		{"word":"rainer","word_nosc":"rainer","lemma":"rainer","pos":"VER"} ,
		{"word":"raines","word_nosc":"raines","lemma":"rainer","pos":"VER"} ,
		{"word":"rainurer","word_nosc":"rainurer","lemma":"rainurer","pos":"VER"} ,
		{"word":"rainuré","word_nosc":"rainure","lemma":"rainurer","pos":"VER"} ,
		{"word":"rainurés","word_nosc":"rainures","lemma":"rainurer","pos":"VER"} ,
		{"word":"raire","word_nosc":"raire","lemma":"raire","pos":"VER"} ,
		{"word":"raisonna","word_nosc":"raisonna","lemma":"raisonner","pos":"VER"} ,
		{"word":"raisonnai","word_nosc":"raisonnai","lemma":"raisonner","pos":"VER"} ,
		{"word":"raisonnaient","word_nosc":"raisonnaient","lemma":"raisonner","pos":"VER"} ,
		{"word":"raisonnais","word_nosc":"raisonnais","lemma":"raisonner","pos":"VER"} ,
		{"word":"raisonnait","word_nosc":"raisonnait","lemma":"raisonner","pos":"VER"} ,
		{"word":"raisonnant","word_nosc":"raisonnant","lemma":"raisonner","pos":"VER"} ,
		{"word":"raisonnassent","word_nosc":"raisonnassent","lemma":"raisonner","pos":"VER"} ,
		{"word":"raisonne","word_nosc":"raisonne","lemma":"raisonner","pos":"VER"} ,
		{"word":"raisonnent","word_nosc":"raisonnent","lemma":"raisonner","pos":"VER"} ,
		{"word":"raisonner","word_nosc":"raisonner","lemma":"raisonner","pos":"VER"} ,
		{"word":"raisonnes","word_nosc":"raisonnes","lemma":"raisonner","pos":"VER"} ,
		{"word":"raisonnez","word_nosc":"raisonnez","lemma":"raisonner","pos":"VER"} ,
		{"word":"raisonniez","word_nosc":"raisonniez","lemma":"raisonner","pos":"VER"} ,
		{"word":"raisonnons","word_nosc":"raisonnons","lemma":"raisonner","pos":"VER"} ,
		{"word":"raisonné","word_nosc":"raisonne","lemma":"raisonner","pos":"VER"} ,
		{"word":"raisonnée","word_nosc":"raisonnee","lemma":"raisonner","pos":"VER"} ,
		{"word":"rait","word_nosc":"rait","lemma":"raire","pos":"VER"} ,
		{"word":"raites","word_nosc":"raites","lemma":"raire","pos":"VER"} ,
		{"word":"rajeuni","word_nosc":"rajeuni","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunie","word_nosc":"rajeunie","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunies","word_nosc":"rajeunies","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunir","word_nosc":"rajeunir","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunira","word_nosc":"rajeunira","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunirai","word_nosc":"rajeunirai","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunirait","word_nosc":"rajeunirait","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunirez","word_nosc":"rajeunirez","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunis","word_nosc":"rajeunis","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunissaient","word_nosc":"rajeunissaient","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunissais","word_nosc":"rajeunissais","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunissait","word_nosc":"rajeunissait","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunissant","word_nosc":"rajeunissant","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunisse","word_nosc":"rajeunisse","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunissent","word_nosc":"rajeunissent","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunissez","word_nosc":"rajeunissez","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunissons","word_nosc":"rajeunissons","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajeunit","word_nosc":"rajeunit","lemma":"rajeunir","pos":"VER"} ,
		{"word":"rajouta","word_nosc":"rajouta","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajoutaient","word_nosc":"rajoutaient","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajoutais","word_nosc":"rajoutais","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajoutait","word_nosc":"rajoutait","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajoutant","word_nosc":"rajoutant","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajoute","word_nosc":"rajoute","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajoutent","word_nosc":"rajoutent","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajouter","word_nosc":"rajouter","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajoutera","word_nosc":"rajoutera","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajouterais","word_nosc":"rajouterais","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajouterait","word_nosc":"rajouterait","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajouteras","word_nosc":"rajouteras","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajouterez","word_nosc":"rajouterez","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajoutes","word_nosc":"rajoutes","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajoutez","word_nosc":"rajoutez","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajoutons","word_nosc":"rajoutons","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajouté","word_nosc":"rajoute","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajoutée","word_nosc":"rajoutee","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajoutées","word_nosc":"rajoutees","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajoutés","word_nosc":"rajoutes","lemma":"rajouter","pos":"VER"} ,
		{"word":"rajusta","word_nosc":"rajusta","lemma":"rajuster","pos":"VER"} ,
		{"word":"rajustaient","word_nosc":"rajustaient","lemma":"rajuster","pos":"VER"} ,
		{"word":"rajustais","word_nosc":"rajustais","lemma":"rajuster","pos":"VER"} ,
		{"word":"rajustait","word_nosc":"rajustait","lemma":"rajuster","pos":"VER"} ,
		{"word":"rajustant","word_nosc":"rajustant","lemma":"rajuster","pos":"VER"} ,
		{"word":"rajuste","word_nosc":"rajuste","lemma":"rajuster","pos":"VER"} ,
		{"word":"rajustent","word_nosc":"rajustent","lemma":"rajuster","pos":"VER"} ,
		{"word":"rajuster","word_nosc":"rajuster","lemma":"rajuster","pos":"VER"} ,
		{"word":"rajusteront","word_nosc":"rajusteront","lemma":"rajuster","pos":"VER"} ,
		{"word":"rajusté","word_nosc":"rajuste","lemma":"rajuster","pos":"VER"} ,
		{"word":"rajustée","word_nosc":"rajustee","lemma":"rajuster","pos":"VER"} ,
		{"word":"rajustées","word_nosc":"rajustees","lemma":"rajuster","pos":"VER"} ,
		{"word":"ralenti","word_nosc":"ralenti","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentie","word_nosc":"ralentie","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralenties","word_nosc":"ralenties","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentir","word_nosc":"ralentir","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentira","word_nosc":"ralentira","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentirai","word_nosc":"ralentirai","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentiraient","word_nosc":"ralentiraient","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentirais","word_nosc":"ralentirais","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentirait","word_nosc":"ralentirait","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentirent","word_nosc":"ralentirent","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentirez","word_nosc":"ralentirez","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentiriez","word_nosc":"ralentiriez","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentiront","word_nosc":"ralentiront","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentis","word_nosc":"ralentis","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentissaient","word_nosc":"ralentissaient","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentissais","word_nosc":"ralentissais","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentissait","word_nosc":"ralentissait","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentissant","word_nosc":"ralentissant","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentisse","word_nosc":"ralentisse","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentissent","word_nosc":"ralentissent","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentissez","word_nosc":"ralentissez","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentissiez","word_nosc":"ralentissiez","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentissions","word_nosc":"ralentissions","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentissons","word_nosc":"ralentissons","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentit","word_nosc":"ralentit","lemma":"ralentir","pos":"VER"} ,
		{"word":"ralentît","word_nosc":"ralentit","lemma":"ralentir","pos":"VER"} ,
		{"word":"raller","word_nosc":"raller","lemma":"raller","pos":"VER"} ,
		{"word":"rallia","word_nosc":"rallia","lemma":"rallier","pos":"VER"} ,
		{"word":"ralliai","word_nosc":"ralliai","lemma":"rallier","pos":"VER"} ,
		{"word":"ralliaient","word_nosc":"ralliaient","lemma":"rallier","pos":"VER"} ,
		{"word":"ralliais","word_nosc":"ralliais","lemma":"rallier","pos":"VER"} ,
		{"word":"ralliait","word_nosc":"ralliait","lemma":"rallier","pos":"VER"} ,
		{"word":"ralliant","word_nosc":"ralliant","lemma":"rallier","pos":"VER"} ,
		{"word":"ralliassent","word_nosc":"ralliassent","lemma":"rallier","pos":"VER"} ,
		{"word":"rallie","word_nosc":"rallie","lemma":"rallier","pos":"VER"} ,
		{"word":"rallient","word_nosc":"rallient","lemma":"rallier","pos":"VER"} ,
		{"word":"rallier","word_nosc":"rallier","lemma":"rallier","pos":"VER"} ,
		{"word":"ralliera","word_nosc":"ralliera","lemma":"rallier","pos":"VER"} ,
		{"word":"rallierai","word_nosc":"rallierai","lemma":"rallier","pos":"VER"} ,
		{"word":"rallieraient","word_nosc":"rallieraient","lemma":"rallier","pos":"VER"} ,
		{"word":"rallierait","word_nosc":"rallierait","lemma":"rallier","pos":"VER"} ,
		{"word":"rallieriez","word_nosc":"rallieriez","lemma":"rallier","pos":"VER"} ,
		{"word":"rallierons","word_nosc":"rallierons","lemma":"rallier","pos":"VER"} ,
		{"word":"rallieront","word_nosc":"rallieront","lemma":"rallier","pos":"VER"} ,
		{"word":"rallies","word_nosc":"rallies","lemma":"rallier","pos":"VER"} ,
		{"word":"ralliez","word_nosc":"ralliez","lemma":"rallier","pos":"VER"} ,
		{"word":"rallions","word_nosc":"rallions","lemma":"raller","pos":"VER"} ,
		{"word":"rallièrent","word_nosc":"rallierent","lemma":"rallier","pos":"VER"} ,
		{"word":"rallié","word_nosc":"rallie","lemma":"rallier","pos":"VER"} ,
		{"word":"ralliée","word_nosc":"ralliee","lemma":"rallier","pos":"VER"} ,
		{"word":"ralliées","word_nosc":"ralliees","lemma":"rallier","pos":"VER"} ,
		{"word":"ralliés","word_nosc":"rallies","lemma":"rallier","pos":"VER"} ,
		{"word":"rallonge","word_nosc":"rallonge","lemma":"rallonger","pos":"VER"} ,
		{"word":"rallongea","word_nosc":"rallongea","lemma":"rallonger","pos":"VER"} ,
		{"word":"rallongeaient","word_nosc":"rallongeaient","lemma":"rallonger","pos":"VER"} ,
		{"word":"rallongeait","word_nosc":"rallongeait","lemma":"rallonger","pos":"VER"} ,
		{"word":"rallongent","word_nosc":"rallongent","lemma":"rallonger","pos":"VER"} ,
		{"word":"rallonger","word_nosc":"rallonger","lemma":"rallonger","pos":"VER"} ,
		{"word":"rallongerait","word_nosc":"rallongerait","lemma":"rallonger","pos":"VER"} ,
		{"word":"rallonges","word_nosc":"rallonges","lemma":"rallonger","pos":"VER"} ,
		{"word":"rallongez","word_nosc":"rallongez","lemma":"rallonger","pos":"VER"} ,
		{"word":"rallongé","word_nosc":"rallonge","lemma":"rallonger","pos":"VER"} ,
		{"word":"rallongée","word_nosc":"rallongee","lemma":"rallonger","pos":"VER"} ,
		{"word":"ralluma","word_nosc":"ralluma","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumai","word_nosc":"rallumai","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumaient","word_nosc":"rallumaient","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumais","word_nosc":"rallumais","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumait","word_nosc":"rallumait","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumant","word_nosc":"rallumant","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallume","word_nosc":"rallume","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallument","word_nosc":"rallument","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumer","word_nosc":"rallumer","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumerai","word_nosc":"rallumerai","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumeront","word_nosc":"rallumeront","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumes","word_nosc":"rallumes","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumez","word_nosc":"rallumez","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumons","word_nosc":"rallumons","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumèrent","word_nosc":"rallumerent","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumé","word_nosc":"rallume","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumée","word_nosc":"rallumee","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumées","word_nosc":"rallumees","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallumés","word_nosc":"rallumes","lemma":"rallumer","pos":"VER"} ,
		{"word":"rallège","word_nosc":"rallege","lemma":"ralléger","pos":"VER"} ,
		{"word":"ralléger","word_nosc":"ralleger","lemma":"ralléger","pos":"VER"} ,
		{"word":"rallégé","word_nosc":"rallege","lemma":"ralléger","pos":"VER"} ,
		{"word":"rama","word_nosc":"rama","lemma":"ramer","pos":"VER"} ,
		{"word":"ramageait","word_nosc":"ramageait","lemma":"ramager","pos":"VER"} ,
		{"word":"ramager","word_nosc":"ramager","lemma":"ramager","pos":"VER"} ,
		{"word":"ramai","word_nosc":"ramai","lemma":"ramer","pos":"VER"} ,
		{"word":"ramaient","word_nosc":"ramaient","lemma":"ramer","pos":"VER"} ,
		{"word":"ramais","word_nosc":"ramais","lemma":"ramer","pos":"VER"} ,
		{"word":"ramait","word_nosc":"ramait","lemma":"ramer","pos":"VER"} ,
		{"word":"ramant","word_nosc":"ramant","lemma":"ramer","pos":"VER"} ,
		{"word":"ramarraient","word_nosc":"ramarraient","lemma":"ramarrer","pos":"VER"} ,
		{"word":"ramarrer","word_nosc":"ramarrer","lemma":"ramarrer","pos":"VER"} ,
		{"word":"ramas","word_nosc":"ramas","lemma":"ramer","pos":"VER"} ,
		{"word":"ramassa","word_nosc":"ramassa","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassai","word_nosc":"ramassai","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassaient","word_nosc":"ramassaient","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassais","word_nosc":"ramassais","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassait","word_nosc":"ramassait","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassant","word_nosc":"ramassant","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramasse","word_nosc":"ramasse","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassent","word_nosc":"ramassent","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramasser","word_nosc":"ramasser","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassera","word_nosc":"ramassera","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramasserai","word_nosc":"ramasserai","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramasseraient","word_nosc":"ramasseraient","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramasserais","word_nosc":"ramasserais","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramasserait","word_nosc":"ramasserait","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramasseras","word_nosc":"ramasseras","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramasseront","word_nosc":"ramasseront","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramasses","word_nosc":"ramasses","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassez","word_nosc":"ramassez","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassiez","word_nosc":"ramassiez","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassions","word_nosc":"ramassions","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassons","word_nosc":"ramassons","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassât","word_nosc":"ramassat","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassèrent","word_nosc":"ramasserent","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassé","word_nosc":"ramasse","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassée","word_nosc":"ramassee","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassées","word_nosc":"ramassees","lemma":"ramasser","pos":"VER"} ,
		{"word":"ramassés","word_nosc":"ramasses","lemma":"ramasser","pos":"VER"} ,
		{"word":"rambinait","word_nosc":"rambinait","lemma":"rambiner","pos":"VER"} ,
		{"word":"rambine","word_nosc":"rambine","lemma":"rambiner","pos":"VER"} ,
		{"word":"rambinent","word_nosc":"rambinent","lemma":"rambiner","pos":"VER"} ,
		{"word":"rambiner","word_nosc":"rambiner","lemma":"rambiner","pos":"VER"} ,
		{"word":"rambiné","word_nosc":"rambine","lemma":"rambiner","pos":"VER"} ,
		{"word":"rame","word_nosc":"rame","lemma":"ramer","pos":"VER"} ,
		{"word":"ramena","word_nosc":"ramena","lemma":"ramener","pos":"VER"} ,
		{"word":"ramenai","word_nosc":"ramenai","lemma":"ramener","pos":"VER"} ,
		{"word":"ramenaient","word_nosc":"ramenaient","lemma":"ramener","pos":"VER"} ,
		{"word":"ramenais","word_nosc":"ramenais","lemma":"ramener","pos":"VER"} ,
		{"word":"ramenait","word_nosc":"ramenait","lemma":"ramener","pos":"VER"} ,
		{"word":"ramenant","word_nosc":"ramenant","lemma":"ramener","pos":"VER"} ,
		{"word":"ramendé","word_nosc":"ramende","lemma":"ramender","pos":"VER"} ,
		{"word":"ramener","word_nosc":"ramener","lemma":"ramener","pos":"VER"} ,
		{"word":"ramenez","word_nosc":"ramenez","lemma":"ramener","pos":"VER"} ,
		{"word":"rameniez","word_nosc":"rameniez","lemma":"ramener","pos":"VER"} ,
		{"word":"ramenions","word_nosc":"ramenions","lemma":"ramener","pos":"VER"} ,
		{"word":"ramenons","word_nosc":"ramenons","lemma":"ramener","pos":"VER"} ,
		{"word":"rament","word_nosc":"rament","lemma":"ramer","pos":"VER"} ,
		{"word":"ramenâmes","word_nosc":"ramenames","lemma":"ramener","pos":"VER"} ,
		{"word":"ramenât","word_nosc":"ramenat","lemma":"ramener","pos":"VER"} ,
		{"word":"ramenèrent","word_nosc":"ramenerent","lemma":"ramener","pos":"VER"} ,
		{"word":"ramené","word_nosc":"ramene","lemma":"ramener","pos":"VER"} ,
		{"word":"ramenée","word_nosc":"ramenee","lemma":"ramener","pos":"VER"} ,
		{"word":"ramenées","word_nosc":"ramenees","lemma":"ramener","pos":"VER"} ,
		{"word":"ramenés","word_nosc":"ramenes","lemma":"ramener","pos":"VER"} ,
		{"word":"ramer","word_nosc":"ramer","lemma":"ramer","pos":"VER"} ,
		{"word":"ramera","word_nosc":"ramera","lemma":"ramer","pos":"VER"} ,
		{"word":"rameras","word_nosc":"rameras","lemma":"ramer","pos":"VER"} ,
		{"word":"ramerons","word_nosc":"ramerons","lemma":"ramer","pos":"VER"} ,
		{"word":"rameront","word_nosc":"rameront","lemma":"ramer","pos":"VER"} ,
		{"word":"rames","word_nosc":"rames","lemma":"ramer","pos":"VER"} ,
		{"word":"rameutais","word_nosc":"rameutais","lemma":"rameuter","pos":"VER"} ,
		{"word":"rameutait","word_nosc":"rameutait","lemma":"rameuter","pos":"VER"} ,
		{"word":"rameutant","word_nosc":"rameutant","lemma":"rameuter","pos":"VER"} ,
		{"word":"rameute","word_nosc":"rameute","lemma":"rameuter","pos":"VER"} ,
		{"word":"rameutent","word_nosc":"rameutent","lemma":"rameuter","pos":"VER"} ,
		{"word":"rameuter","word_nosc":"rameuter","lemma":"rameuter","pos":"VER"} ,
		{"word":"rameutez","word_nosc":"rameutez","lemma":"rameuter","pos":"VER"} ,
		{"word":"rameutons","word_nosc":"rameutons","lemma":"rameuter","pos":"VER"} ,
		{"word":"rameuté","word_nosc":"rameute","lemma":"rameuter","pos":"VER"} ,
		{"word":"rameutées","word_nosc":"rameutees","lemma":"rameuter","pos":"VER"} ,
		{"word":"rameutés","word_nosc":"rameutes","lemma":"rameuter","pos":"VER"} ,
		{"word":"ramez","word_nosc":"ramez","lemma":"ramer","pos":"VER"} ,
		{"word":"ramifia","word_nosc":"ramifia","lemma":"ramifier","pos":"VER"} ,
		{"word":"ramifiaient","word_nosc":"ramifiaient","lemma":"ramifier","pos":"VER"} ,
		{"word":"ramifiait","word_nosc":"ramifiait","lemma":"ramifier","pos":"VER"} ,
		{"word":"ramifie","word_nosc":"ramifie","lemma":"ramifier","pos":"VER"} ,
		{"word":"ramifient","word_nosc":"ramifient","lemma":"ramifier","pos":"VER"} ,
		{"word":"ramifier","word_nosc":"ramifier","lemma":"ramifier","pos":"VER"} ,
		{"word":"ramifié","word_nosc":"ramifie","lemma":"ramifier","pos":"VER"} ,
		{"word":"ramifiée","word_nosc":"ramifiee","lemma":"ramifier","pos":"VER"} ,
		{"word":"ramions","word_nosc":"ramions","lemma":"ramer","pos":"VER"} ,
		{"word":"ramolli","word_nosc":"ramolli","lemma":"ramollir","pos":"VER"} ,
		{"word":"ramollie","word_nosc":"ramollie","lemma":"ramollir","pos":"VER"} ,
		{"word":"ramollies","word_nosc":"ramollies","lemma":"ramollir","pos":"VER"} ,
		{"word":"ramollir","word_nosc":"ramollir","lemma":"ramollir","pos":"VER"} ,
		{"word":"ramollirent","word_nosc":"ramollirent","lemma":"ramollir","pos":"VER"} ,
		{"word":"ramollis","word_nosc":"ramollis","lemma":"ramollir","pos":"VER"} ,
		{"word":"ramollissait","word_nosc":"ramollissait","lemma":"ramollir","pos":"VER"} ,
		{"word":"ramollisse","word_nosc":"ramollisse","lemma":"ramollir","pos":"VER"} ,
		{"word":"ramollissent","word_nosc":"ramollissent","lemma":"ramollir","pos":"VER"} ,
		{"word":"ramollit","word_nosc":"ramollit","lemma":"ramollir","pos":"VER"} ,
		{"word":"ramonait","word_nosc":"ramonait","lemma":"ramoner","pos":"VER"} ,
		{"word":"ramonant","word_nosc":"ramonant","lemma":"ramoner","pos":"VER"} ,
		{"word":"ramone","word_nosc":"ramone","lemma":"ramoner","pos":"VER"} ,
		{"word":"ramonent","word_nosc":"ramonent","lemma":"ramoner","pos":"VER"} ,
		{"word":"ramoner","word_nosc":"ramoner","lemma":"ramoner","pos":"VER"} ,
		{"word":"ramones","word_nosc":"ramones","lemma":"ramoner","pos":"VER"} ,
		{"word":"ramons","word_nosc":"ramons","lemma":"ramer","pos":"VER"} ,
		{"word":"ramoné","word_nosc":"ramone","lemma":"ramoner","pos":"VER"} ,
		{"word":"ramonée","word_nosc":"ramonee","lemma":"ramoner","pos":"VER"} ,
		{"word":"ramonées","word_nosc":"ramonees","lemma":"ramoner","pos":"VER"} ,
		{"word":"rampa","word_nosc":"rampa","lemma":"ramper","pos":"VER"} ,
		{"word":"rampai","word_nosc":"rampai","lemma":"ramper","pos":"VER"} ,
		{"word":"rampaient","word_nosc":"rampaient","lemma":"ramper","pos":"VER"} ,
		{"word":"rampais","word_nosc":"rampais","lemma":"ramper","pos":"VER"} ,
		{"word":"rampait","word_nosc":"rampait","lemma":"ramper","pos":"VER"} ,
		{"word":"rampant","word_nosc":"rampant","lemma":"ramper","pos":"VER"} ,
		{"word":"rampe","word_nosc":"rampe","lemma":"ramper","pos":"VER"} ,
		{"word":"rampent","word_nosc":"rampent","lemma":"ramper","pos":"VER"} ,
		{"word":"ramper","word_nosc":"ramper","lemma":"ramper","pos":"VER"} ,
		{"word":"rampera","word_nosc":"rampera","lemma":"ramper","pos":"VER"} ,
		{"word":"ramperai","word_nosc":"ramperai","lemma":"ramper","pos":"VER"} ,
		{"word":"ramperaient","word_nosc":"ramperaient","lemma":"ramper","pos":"VER"} ,
		{"word":"ramperais","word_nosc":"ramperais","lemma":"ramper","pos":"VER"} ,
		{"word":"ramperas","word_nosc":"ramperas","lemma":"ramper","pos":"VER"} ,
		{"word":"ramperez","word_nosc":"ramperez","lemma":"ramper","pos":"VER"} ,
		{"word":"rampes","word_nosc":"rampes","lemma":"ramper","pos":"VER"} ,
		{"word":"rampez","word_nosc":"rampez","lemma":"ramper","pos":"VER"} ,
		{"word":"rampiez","word_nosc":"rampiez","lemma":"ramper","pos":"VER"} ,
		{"word":"rampions","word_nosc":"rampions","lemma":"ramper","pos":"VER"} ,
		{"word":"rampons","word_nosc":"rampons","lemma":"ramper","pos":"VER"} ,
		{"word":"rampèrent","word_nosc":"ramperent","lemma":"ramper","pos":"VER"} ,
		{"word":"rampé","word_nosc":"rampe","lemma":"ramper","pos":"VER"} ,
		{"word":"rampée","word_nosc":"rampee","lemma":"ramper","pos":"VER"} ,
		{"word":"ramser","word_nosc":"ramser","lemma":"ramser","pos":"VER"} ,
		{"word":"ramène","word_nosc":"ramene","lemma":"ramener","pos":"VER"} ,
		{"word":"ramènent","word_nosc":"ramenent","lemma":"ramener","pos":"VER"} ,
		{"word":"ramènera","word_nosc":"ramenera","lemma":"ramener","pos":"VER"} ,
		{"word":"ramènerai","word_nosc":"ramenerai","lemma":"ramener","pos":"VER"} ,
		{"word":"ramèneraient","word_nosc":"rameneraient","lemma":"ramener","pos":"VER"} ,
		{"word":"ramènerais","word_nosc":"ramenerais","lemma":"ramener","pos":"VER"} ,
		{"word":"ramènerait","word_nosc":"ramenerait","lemma":"ramener","pos":"VER"} ,
		{"word":"ramèneras","word_nosc":"rameneras","lemma":"ramener","pos":"VER"} ,
		{"word":"ramènerez","word_nosc":"ramenerez","lemma":"ramener","pos":"VER"} ,
		{"word":"ramèneriez","word_nosc":"rameneriez","lemma":"ramener","pos":"VER"} ,
		{"word":"ramènerions","word_nosc":"ramenerions","lemma":"ramener","pos":"VER"} ,
		{"word":"ramènerons","word_nosc":"ramenerons","lemma":"ramener","pos":"VER"} ,
		{"word":"ramèneront","word_nosc":"rameneront","lemma":"ramener","pos":"VER"} ,
		{"word":"ramènes","word_nosc":"ramenes","lemma":"ramener","pos":"VER"} ,
		{"word":"ramèrent","word_nosc":"ramerent","lemma":"ramer","pos":"VER"} ,
		{"word":"ramé","word_nosc":"rame","lemma":"ramer","pos":"VER"} ,
		{"word":"ramées","word_nosc":"ramees","lemma":"ramer","pos":"VER"} ,
		{"word":"ramés","word_nosc":"rames","lemma":"ramer","pos":"VER"} ,
		{"word":"rancardait","word_nosc":"rancardait","lemma":"rancarder","pos":"VER"} ,
		{"word":"rancarde","word_nosc":"rancarde","lemma":"rancarder","pos":"VER"} ,
		{"word":"rancarder","word_nosc":"rancarder","lemma":"rancarder","pos":"VER"} ,
		{"word":"rancardez","word_nosc":"rancardez","lemma":"rancarder","pos":"VER"} ,
		{"word":"rancardé","word_nosc":"rancarde","lemma":"rancarder","pos":"VER"} ,
		{"word":"rancardée","word_nosc":"rancardee","lemma":"rancarder","pos":"VER"} ,
		{"word":"rancardés","word_nosc":"rancardes","lemma":"rancarder","pos":"VER"} ,
		{"word":"rance","word_nosc":"rance","lemma":"rancer","pos":"VER"} ,
		{"word":"ranci","word_nosc":"ranci","lemma":"rancir","pos":"VER"} ,
		{"word":"rancies","word_nosc":"rancies","lemma":"rancir","pos":"VER"} ,
		{"word":"rancir","word_nosc":"rancir","lemma":"rancir","pos":"VER"} ,
		{"word":"rancis","word_nosc":"rancis","lemma":"rancir","pos":"VER"} ,
		{"word":"rancissait","word_nosc":"rancissait","lemma":"rancir","pos":"VER"} ,
		{"word":"rancit","word_nosc":"rancit","lemma":"rancir","pos":"VER"} ,
		{"word":"randonnait","word_nosc":"randonnait","lemma":"randonner","pos":"VER"} ,
		{"word":"randonne","word_nosc":"randonne","lemma":"randonner","pos":"VER"} ,
		{"word":"randonner","word_nosc":"randonner","lemma":"randonner","pos":"VER"} ,
		{"word":"randonnez","word_nosc":"randonnez","lemma":"randonner","pos":"VER"} ,
		{"word":"randonnée","word_nosc":"randonnee","lemma":"randonner","pos":"VER"} ,
		{"word":"randonnées","word_nosc":"randonnees","lemma":"randonner","pos":"VER"} ,
		{"word":"range","word_nosc":"range","lemma":"ranger","pos":"VER"} ,
		{"word":"rangea","word_nosc":"rangea","lemma":"ranger","pos":"VER"} ,
		{"word":"rangeai","word_nosc":"rangeai","lemma":"ranger","pos":"VER"} ,
		{"word":"rangeaient","word_nosc":"rangeaient","lemma":"ranger","pos":"VER"} ,
		{"word":"rangeais","word_nosc":"rangeais","lemma":"ranger","pos":"VER"} ,
		{"word":"rangeait","word_nosc":"rangeait","lemma":"ranger","pos":"VER"} ,
		{"word":"rangeant","word_nosc":"rangeant","lemma":"ranger","pos":"VER"} ,
		{"word":"rangent","word_nosc":"rangent","lemma":"ranger","pos":"VER"} ,
		{"word":"rangeons","word_nosc":"rangeons","lemma":"ranger","pos":"VER"} ,
		{"word":"ranger","word_nosc":"ranger","lemma":"ranger","pos":"VER"} ,
		{"word":"rangera","word_nosc":"rangera","lemma":"ranger","pos":"VER"} ,
		{"word":"rangerai","word_nosc":"rangerai","lemma":"ranger","pos":"VER"} ,
		{"word":"rangerais","word_nosc":"rangerais","lemma":"ranger","pos":"VER"} ,
		{"word":"rangerait","word_nosc":"rangerait","lemma":"ranger","pos":"VER"} ,
		{"word":"rangeras","word_nosc":"rangeras","lemma":"ranger","pos":"VER"} ,
		{"word":"rangerez","word_nosc":"rangerez","lemma":"ranger","pos":"VER"} ,
		{"word":"rangerons","word_nosc":"rangerons","lemma":"ranger","pos":"VER"} ,
		{"word":"rangeront","word_nosc":"rangeront","lemma":"ranger","pos":"VER"} ,
		{"word":"ranges","word_nosc":"ranges","lemma":"ranger","pos":"VER"} ,
		{"word":"rangez","word_nosc":"rangez","lemma":"ranger","pos":"VER"} ,
		{"word":"rangeâmes","word_nosc":"rangeames","lemma":"ranger","pos":"VER"} ,
		{"word":"rangeât","word_nosc":"rangeat","lemma":"ranger","pos":"VER"} ,
		{"word":"rangions","word_nosc":"rangions","lemma":"ranger","pos":"VER"} ,
		{"word":"rangèrent","word_nosc":"rangerent","lemma":"ranger","pos":"VER"} ,
		{"word":"rangé","word_nosc":"range","lemma":"ranger","pos":"VER"} ,
		{"word":"rangée","word_nosc":"rangee","lemma":"ranger","pos":"VER"} ,
		{"word":"rangées","word_nosc":"rangees","lemma":"ranger","pos":"VER"} ,
		{"word":"rangés","word_nosc":"ranges","lemma":"ranger","pos":"VER"} ,
		{"word":"ranima","word_nosc":"ranima","lemma":"ranimer","pos":"VER"} ,
		{"word":"ranimaient","word_nosc":"ranimaient","lemma":"ranimer","pos":"VER"} ,
		{"word":"ranimait","word_nosc":"ranimait","lemma":"ranimer","pos":"VER"} ,
		{"word":"ranimant","word_nosc":"ranimant","lemma":"ranimer","pos":"VER"} ,
		{"word":"ranime","word_nosc":"ranime","lemma":"ranimer","pos":"VER"} ,
		{"word":"raniment","word_nosc":"raniment","lemma":"ranimer","pos":"VER"} ,
		{"word":"ranimer","word_nosc":"ranimer","lemma":"ranimer","pos":"VER"} ,
		{"word":"ranimera","word_nosc":"ranimera","lemma":"ranimer","pos":"VER"} ,
		{"word":"ranimez","word_nosc":"ranimez","lemma":"ranimer","pos":"VER"} ,
		{"word":"ranimions","word_nosc":"ranimions","lemma":"ranimer","pos":"VER"} ,
		{"word":"ranimât","word_nosc":"ranimat","lemma":"ranimer","pos":"VER"} ,
		{"word":"ranimèrent","word_nosc":"ranimerent","lemma":"ranimer","pos":"VER"} ,
		{"word":"ranimé","word_nosc":"ranime","lemma":"ranimer","pos":"VER"} ,
		{"word":"ranimée","word_nosc":"ranimee","lemma":"ranimer","pos":"VER"} ,
		{"word":"ranimées","word_nosc":"ranimees","lemma":"ranimer","pos":"VER"} ,
		{"word":"ranimés","word_nosc":"ranimes","lemma":"ranimer","pos":"VER"} ,
		{"word":"rançonnaient","word_nosc":"ranconnaient","lemma":"rançonner","pos":"VER"} ,
		{"word":"rançonnait","word_nosc":"ranconnait","lemma":"rançonner","pos":"VER"} ,
		{"word":"rançonne","word_nosc":"ranconne","lemma":"rançonner","pos":"VER"} ,
		{"word":"rançonner","word_nosc":"ranconner","lemma":"rançonner","pos":"VER"} ,
		{"word":"rançonné","word_nosc":"ranconne","lemma":"rançonner","pos":"VER"} ,
		{"word":"rançonnée","word_nosc":"ranconnee","lemma":"rançonner","pos":"VER"} ,
		{"word":"rançonnées","word_nosc":"ranconnees","lemma":"rançonner","pos":"VER"} ,
		{"word":"rançonnés","word_nosc":"ranconnes","lemma":"rançonner","pos":"VER"} ,
		{"word":"rapatria","word_nosc":"rapatria","lemma":"rapatrier","pos":"VER"} ,
		{"word":"rapatriait","word_nosc":"rapatriait","lemma":"rapatrier","pos":"VER"} ,
		{"word":"rapatriant","word_nosc":"rapatriant","lemma":"rapatrier","pos":"VER"} ,
		{"word":"rapatrie","word_nosc":"rapatrie","lemma":"rapatrier","pos":"VER"} ,
		{"word":"rapatrient","word_nosc":"rapatrient","lemma":"rapatrier","pos":"VER"} ,
		{"word":"rapatrier","word_nosc":"rapatrier","lemma":"rapatrier","pos":"VER"} ,
		{"word":"rapatriera","word_nosc":"rapatriera","lemma":"rapatrier","pos":"VER"} ,
		{"word":"rapatriez","word_nosc":"rapatriez","lemma":"rapatrier","pos":"VER"} ,
		{"word":"rapatrié","word_nosc":"rapatrie","lemma":"rapatrier","pos":"VER"} ,
		{"word":"rapatriée","word_nosc":"rapatriee","lemma":"rapatrier","pos":"VER"} ,
		{"word":"rapatriées","word_nosc":"rapatriees","lemma":"rapatrier","pos":"VER"} ,
		{"word":"rapatriés","word_nosc":"rapatries","lemma":"rapatrier","pos":"VER"} ,
		{"word":"rapetasser","word_nosc":"rapetasser","lemma":"rapetasser","pos":"VER"} ,
		{"word":"rapetassions","word_nosc":"rapetassions","lemma":"rapetasser","pos":"VER"} ,
		{"word":"rapetassé","word_nosc":"rapetasse","lemma":"rapetasser","pos":"VER"} ,
		{"word":"rapetassées","word_nosc":"rapetassees","lemma":"rapetasser","pos":"VER"} ,
		{"word":"rapetassés","word_nosc":"rapetasses","lemma":"rapetasser","pos":"VER"} ,
		{"word":"rapetissaient","word_nosc":"rapetissaient","lemma":"rapetisser","pos":"VER"} ,
		{"word":"rapetissais","word_nosc":"rapetissais","lemma":"rapetisser","pos":"VER"} ,
		{"word":"rapetissait","word_nosc":"rapetissait","lemma":"rapetisser","pos":"VER"} ,
		{"word":"rapetissant","word_nosc":"rapetissant","lemma":"rapetisser","pos":"VER"} ,
		{"word":"rapetisse","word_nosc":"rapetisse","lemma":"rapetisser","pos":"VER"} ,
		{"word":"rapetissent","word_nosc":"rapetissent","lemma":"rapetisser","pos":"VER"} ,
		{"word":"rapetisser","word_nosc":"rapetisser","lemma":"rapetisser","pos":"VER"} ,
		{"word":"rapetissera","word_nosc":"rapetissera","lemma":"rapetisser","pos":"VER"} ,
		{"word":"rapetissez","word_nosc":"rapetissez","lemma":"rapetisser","pos":"VER"} ,
		{"word":"rapetissons","word_nosc":"rapetissons","lemma":"rapetisser","pos":"VER"} ,
		{"word":"rapetissèrent","word_nosc":"rapetisserent","lemma":"rapetisser","pos":"VER"} ,
		{"word":"rapetissé","word_nosc":"rapetisse","lemma":"rapetisser","pos":"VER"} ,
		{"word":"rapetissée","word_nosc":"rapetissee","lemma":"rapetisser","pos":"VER"} ,
		{"word":"rapetissées","word_nosc":"rapetissees","lemma":"rapetisser","pos":"VER"} ,
		{"word":"rapetissés","word_nosc":"rapetisses","lemma":"rapetisser","pos":"VER"} ,
		{"word":"rapiater","word_nosc":"rapiater","lemma":"rapiater","pos":"VER"} ,
		{"word":"rapinent","word_nosc":"rapinent","lemma":"rapiner","pos":"VER"} ,
		{"word":"rapinés","word_nosc":"rapines","lemma":"rapiner","pos":"VER"} ,
		{"word":"rapiécer","word_nosc":"rapiecer","lemma":"rapiécer","pos":"VER"} ,
		{"word":"rapiécerait","word_nosc":"rapiecerait","lemma":"rapiécer","pos":"VER"} ,
		{"word":"rapiécé","word_nosc":"rapiece","lemma":"rapiécer","pos":"VER"} ,
		{"word":"rapiécée","word_nosc":"rapiecee","lemma":"rapiécer","pos":"VER"} ,
		{"word":"rapiécées","word_nosc":"rapiecees","lemma":"rapiécer","pos":"VER"} ,
		{"word":"rapiécés","word_nosc":"rapieces","lemma":"rapiécer","pos":"VER"} ,
		{"word":"rapointir","word_nosc":"rapointir","lemma":"rapointir","pos":"VER"} ,
		{"word":"rappela","word_nosc":"rappela","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelai","word_nosc":"rappelai","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelaient","word_nosc":"rappelaient","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelais","word_nosc":"rappelais","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelait","word_nosc":"rappelait","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelant","word_nosc":"rappelant","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappeler","word_nosc":"rappeler","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelez","word_nosc":"rappelez","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappeliez","word_nosc":"rappeliez","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelions","word_nosc":"rappelions","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelle","word_nosc":"rappelle","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappellent","word_nosc":"rappellent","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappellera","word_nosc":"rappellera","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappellerai","word_nosc":"rappellerai","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelleraient","word_nosc":"rappelleraient","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappellerais","word_nosc":"rappellerais","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappellerait","word_nosc":"rappellerait","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelleras","word_nosc":"rappelleras","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappellerez","word_nosc":"rappellerez","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelleriez","word_nosc":"rappelleriez","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappellerons","word_nosc":"rappellerons","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelleront","word_nosc":"rappelleront","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelles","word_nosc":"rappelles","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelons","word_nosc":"rappelons","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelâmes","word_nosc":"rappelames","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelât","word_nosc":"rappelat","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelèrent","word_nosc":"rappelerent","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelé","word_nosc":"rappele","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelée","word_nosc":"rappelee","lemma":"rappeler","pos":"VER"} ,
		{"word":"rappelés","word_nosc":"rappeles","lemma":"rappeler","pos":"VER"} ,
		{"word":"rapper","word_nosc":"rapper","lemma":"rapper","pos":"VER"} ,
		{"word":"rappliqua","word_nosc":"rappliqua","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rappliquaient","word_nosc":"rappliquaient","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rappliquais","word_nosc":"rappliquais","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rappliquait","word_nosc":"rappliquait","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rapplique","word_nosc":"rapplique","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rappliquent","word_nosc":"rappliquent","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rappliquer","word_nosc":"rappliquer","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rappliquera","word_nosc":"rappliquera","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rappliquerais","word_nosc":"rappliquerais","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rappliquerait","word_nosc":"rappliquerait","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rappliqueriez","word_nosc":"rappliqueriez","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rappliqueront","word_nosc":"rappliqueront","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rappliques","word_nosc":"rappliques","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rappliquez","word_nosc":"rappliquez","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rappliquèrent","word_nosc":"rappliquerent","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rappliqué","word_nosc":"rapplique","lemma":"rappliquer","pos":"VER"} ,
		{"word":"rapporta","word_nosc":"rapporta","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportai","word_nosc":"rapportai","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportaient","word_nosc":"rapportaient","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportais","word_nosc":"rapportais","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportait","word_nosc":"rapportait","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportant","word_nosc":"rapportant","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapporte","word_nosc":"rapporte","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportent","word_nosc":"rapportent","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapporter","word_nosc":"rapporter","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportera","word_nosc":"rapportera","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapporterai","word_nosc":"rapporterai","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapporteraient","word_nosc":"rapporteraient","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapporterais","word_nosc":"rapporterais","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapporterait","word_nosc":"rapporterait","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapporteras","word_nosc":"rapporteras","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapporterez","word_nosc":"rapporterez","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapporteriez","word_nosc":"rapporteriez","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapporterons","word_nosc":"rapporterons","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapporteront","word_nosc":"rapporteront","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportes","word_nosc":"rapportes","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportez","word_nosc":"rapportez","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportiez","word_nosc":"rapportiez","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportions","word_nosc":"rapportions","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportons","word_nosc":"rapportons","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportât","word_nosc":"rapportat","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportèrent","word_nosc":"rapporterent","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapporté","word_nosc":"rapporte","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportée","word_nosc":"rapportee","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportées","word_nosc":"rapportees","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapportés","word_nosc":"rapportes","lemma":"rapporter","pos":"VER"} ,
		{"word":"rapprendre","word_nosc":"rapprendre","lemma":"rapprendre","pos":"VER"} ,
		{"word":"rappris","word_nosc":"rappris","lemma":"rapprendre","pos":"VER"} ,
		{"word":"rapprocha","word_nosc":"rapprocha","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochai","word_nosc":"rapprochai","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochaient","word_nosc":"rapprochaient","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochais","word_nosc":"rapprochais","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochait","word_nosc":"rapprochait","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochant","word_nosc":"rapprochant","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapproche","word_nosc":"rapproche","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochent","word_nosc":"rapprochent","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprocher","word_nosc":"rapprocher","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochera","word_nosc":"rapprochera","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprocherais","word_nosc":"rapprocherais","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprocherait","word_nosc":"rapprocherait","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprocheront","word_nosc":"rapprocheront","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochez","word_nosc":"rapprochez","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochiez","word_nosc":"rapprochiez","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochions","word_nosc":"rapprochions","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochons","word_nosc":"rapprochons","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochât","word_nosc":"rapprochat","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochèrent","word_nosc":"rapprocherent","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapproché","word_nosc":"rapproche","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochée","word_nosc":"rapprochee","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochées","word_nosc":"rapprochees","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rapprochés","word_nosc":"rapproches","lemma":"rapprocher","pos":"VER"} ,
		{"word":"rappropriait","word_nosc":"rappropriait","lemma":"rapproprier","pos":"VER"} ,
		{"word":"raquaient","word_nosc":"raquaient","lemma":"raquer","pos":"VER"} ,
		{"word":"raquait","word_nosc":"raquait","lemma":"raquer","pos":"VER"} ,
		{"word":"raque","word_nosc":"raque","lemma":"raquer","pos":"VER"} ,
		{"word":"raquent","word_nosc":"raquent","lemma":"raquer","pos":"VER"} ,
		{"word":"raquer","word_nosc":"raquer","lemma":"raquer","pos":"VER"} ,
		{"word":"raques","word_nosc":"raques","lemma":"raquer","pos":"VER"} ,
		{"word":"raquez","word_nosc":"raquez","lemma":"raquer","pos":"VER"} ,
		{"word":"raquons","word_nosc":"raquons","lemma":"raquer","pos":"VER"} ,
		{"word":"raqué","word_nosc":"raque","lemma":"raquer","pos":"VER"} ,
		{"word":"raquée","word_nosc":"raquee","lemma":"raquer","pos":"VER"} ,
		{"word":"raquées","word_nosc":"raquees","lemma":"raquer","pos":"VER"} ,
		{"word":"raréfia","word_nosc":"rarefia","lemma":"raréfier","pos":"VER"} ,
		{"word":"raréfiaient","word_nosc":"rarefiaient","lemma":"raréfier","pos":"VER"} ,
		{"word":"raréfiait","word_nosc":"rarefiait","lemma":"raréfier","pos":"VER"} ,
		{"word":"raréfiant","word_nosc":"rarefiant","lemma":"raréfier","pos":"VER"} ,
		{"word":"raréfie","word_nosc":"rarefie","lemma":"raréfier","pos":"VER"} ,
		{"word":"raréfient","word_nosc":"rarefient","lemma":"raréfier","pos":"VER"} ,
		{"word":"raréfier","word_nosc":"rarefier","lemma":"raréfier","pos":"VER"} ,
		{"word":"raréfièrent","word_nosc":"rarefierent","lemma":"raréfier","pos":"VER"} ,
		{"word":"raréfié","word_nosc":"rarefie","lemma":"raréfier","pos":"VER"} ,
		{"word":"raréfiée","word_nosc":"rarefiee","lemma":"raréfier","pos":"VER"} ,
		{"word":"raréfiés","word_nosc":"rarefies","lemma":"raréfier","pos":"VER"} ,
		{"word":"rasa","word_nosc":"rasa","lemma":"raser","pos":"VER"} ,
		{"word":"rasai","word_nosc":"rasai","lemma":"raser","pos":"VER"} ,
		{"word":"rasaient","word_nosc":"rasaient","lemma":"raser","pos":"VER"} ,
		{"word":"rasais","word_nosc":"rasais","lemma":"raser","pos":"VER"} ,
		{"word":"rasait","word_nosc":"rasait","lemma":"raser","pos":"VER"} ,
		{"word":"rasant","word_nosc":"rasant","lemma":"raser","pos":"VER"} ,
		{"word":"rase","word_nosc":"rase","lemma":"raser","pos":"VER"} ,
		{"word":"rasent","word_nosc":"rasent","lemma":"raser","pos":"VER"} ,
		{"word":"raser","word_nosc":"raser","lemma":"raser","pos":"VER"} ,
		{"word":"rasera","word_nosc":"rasera","lemma":"raser","pos":"VER"} ,
		{"word":"raserai","word_nosc":"raserai","lemma":"raser","pos":"VER"} ,
		{"word":"raserais","word_nosc":"raserais","lemma":"raser","pos":"VER"} ,
		{"word":"raserait","word_nosc":"raserait","lemma":"raser","pos":"VER"} ,
		{"word":"raseras","word_nosc":"raseras","lemma":"raser","pos":"VER"} ,
		{"word":"raserez","word_nosc":"raserez","lemma":"raser","pos":"VER"} ,
		{"word":"raseront","word_nosc":"raseront","lemma":"raser","pos":"VER"} ,
		{"word":"rases","word_nosc":"rases","lemma":"raser","pos":"VER"} ,
		{"word":"rasez","word_nosc":"rasez","lemma":"raser","pos":"VER"} ,
		{"word":"rasiez","word_nosc":"rasiez","lemma":"raser","pos":"VER"} ,
		{"word":"rasons","word_nosc":"rasons","lemma":"raser","pos":"VER"} ,
		{"word":"rassasiaient","word_nosc":"rassasiaient","lemma":"rassasier","pos":"VER"} ,
		{"word":"rassasiais","word_nosc":"rassasiais","lemma":"rassasier","pos":"VER"} ,
		{"word":"rassasiait","word_nosc":"rassasiait","lemma":"rassasier","pos":"VER"} ,
		{"word":"rassasiant","word_nosc":"rassasiant","lemma":"rassasier","pos":"VER"} ,
		{"word":"rassasie","word_nosc":"rassasie","lemma":"rassasier","pos":"VER"} ,
		{"word":"rassasient","word_nosc":"rassasient","lemma":"rassasier","pos":"VER"} ,
		{"word":"rassasier","word_nosc":"rassasier","lemma":"rassasier","pos":"VER"} ,
		{"word":"rassasieraient","word_nosc":"rassasieraient","lemma":"rassasier","pos":"VER"} ,
		{"word":"rassasiez","word_nosc":"rassasiez","lemma":"rassasier","pos":"VER"} ,
		{"word":"rassasiât","word_nosc":"rassasiat","lemma":"rassasier","pos":"VER"} ,
		{"word":"rassasié","word_nosc":"rassasie","lemma":"rassasier","pos":"VER"} ,
		{"word":"rassasiée","word_nosc":"rassasiee","lemma":"rassasier","pos":"VER"} ,
		{"word":"rassasiés","word_nosc":"rassasies","lemma":"rassasier","pos":"VER"} ,
		{"word":"rassembla","word_nosc":"rassembla","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblai","word_nosc":"rassemblai","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblaient","word_nosc":"rassemblaient","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblais","word_nosc":"rassemblais","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblait","word_nosc":"rassemblait","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblant","word_nosc":"rassemblant","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemble","word_nosc":"rassemble","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblent","word_nosc":"rassemblent","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassembler","word_nosc":"rassembler","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblera","word_nosc":"rassemblera","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblerai","word_nosc":"rassemblerai","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassembleraient","word_nosc":"rassembleraient","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblerais","word_nosc":"rassemblerais","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblerait","word_nosc":"rassemblerait","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassembleras","word_nosc":"rassembleras","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblerez","word_nosc":"rassemblerez","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblerons","word_nosc":"rassemblerons","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassembleront","word_nosc":"rassembleront","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassembles","word_nosc":"rassembles","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblez","word_nosc":"rassemblez","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblons","word_nosc":"rassemblons","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblèrent","word_nosc":"rassemblerent","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblé","word_nosc":"rassemble","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblée","word_nosc":"rassemblee","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblées","word_nosc":"rassemblees","lemma":"rassembler","pos":"VER"} ,
		{"word":"rassemblés","word_nosc":"rassembles","lemma":"rassembler","pos":"VER"} ,
		{"word":"rasseoir","word_nosc":"rasseoir","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rasseyaient","word_nosc":"rasseyaient","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rasseyais","word_nosc":"rasseyais","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rasseyait","word_nosc":"rasseyait","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rasseyant","word_nosc":"rasseyant","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rasseyent","word_nosc":"rasseyent","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rasseyez","word_nosc":"rasseyez","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rasseyons","word_nosc":"rasseyons","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rassied","word_nosc":"rassied","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rassieds","word_nosc":"rassieds","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rassir","word_nosc":"rassir","lemma":"rassir","pos":"VER"} ,
		{"word":"rassirent","word_nosc":"rassirent","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rassis","word_nosc":"rassis","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rassise","word_nosc":"rassise","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rassit","word_nosc":"rassit","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rassoie","word_nosc":"rassoie","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rassoient","word_nosc":"rassoient","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rassoies","word_nosc":"rassoies","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rassois","word_nosc":"rassois","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rassoit","word_nosc":"rassoit","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rassortir","word_nosc":"rassortir","lemma":"rassortir","pos":"VER"} ,
		{"word":"rassoté","word_nosc":"rassote","lemma":"rassoter","pos":"VER"} ,
		{"word":"rassoyait","word_nosc":"rassoyait","lemma":"rasseoir","pos":"VER"} ,
		{"word":"rassura","word_nosc":"rassura","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurai","word_nosc":"rassurai","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassuraient","word_nosc":"rassuraient","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurais","word_nosc":"rassurais","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurait","word_nosc":"rassurait","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurant","word_nosc":"rassurant","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassure","word_nosc":"rassure","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurent","word_nosc":"rassurent","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurer","word_nosc":"rassurer","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurera","word_nosc":"rassurera","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurerait","word_nosc":"rassurerait","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassureront","word_nosc":"rassureront","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassures","word_nosc":"rassures","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurez","word_nosc":"rassurez","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassuriez","word_nosc":"rassuriez","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurons","word_nosc":"rassurons","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurâmes","word_nosc":"rassurames","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurât","word_nosc":"rassurat","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurèrent","word_nosc":"rassurerent","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassuré","word_nosc":"rassure","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurée","word_nosc":"rassuree","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurées","word_nosc":"rassurees","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassurés","word_nosc":"rassures","lemma":"rassurer","pos":"VER"} ,
		{"word":"rassérène","word_nosc":"rasserene","lemma":"rasséréner","pos":"VER"} ,
		{"word":"rasséréna","word_nosc":"rasserena","lemma":"rasséréner","pos":"VER"} ,
		{"word":"rassérénai","word_nosc":"rasserenai","lemma":"rasséréner","pos":"VER"} ,
		{"word":"rassérénait","word_nosc":"rasserenait","lemma":"rasséréner","pos":"VER"} ,
		{"word":"rassérénant","word_nosc":"rasserenant","lemma":"rasséréner","pos":"VER"} ,
		{"word":"rasséréner","word_nosc":"rasserener","lemma":"rasséréner","pos":"VER"} ,
		{"word":"rassérénera","word_nosc":"rasserenera","lemma":"rasséréner","pos":"VER"} ,
		{"word":"rasséréné","word_nosc":"rasserene","lemma":"rasséréner","pos":"VER"} ,
		{"word":"rassérénée","word_nosc":"rasserenee","lemma":"rasséréner","pos":"VER"} ,
		{"word":"rassérénées","word_nosc":"rasserenees","lemma":"rasséréner","pos":"VER"} ,
		{"word":"rassérénés","word_nosc":"rasserenes","lemma":"rasséréner","pos":"VER"} ,
		{"word":"rasèrent","word_nosc":"raserent","lemma":"raser","pos":"VER"} ,
		{"word":"rasé","word_nosc":"rase","lemma":"raser","pos":"VER"} ,
		{"word":"rasée","word_nosc":"rasee","lemma":"raser","pos":"VER"} ,
		{"word":"rasées","word_nosc":"rasees","lemma":"raser","pos":"VER"} ,
		{"word":"rasés","word_nosc":"rases","lemma":"raser","pos":"VER"} ,
		{"word":"rata","word_nosc":"rata","lemma":"rater","pos":"VER"} ,
		{"word":"ratai","word_nosc":"ratai","lemma":"rater","pos":"VER"} ,
		{"word":"rataient","word_nosc":"rataient","lemma":"rater","pos":"VER"} ,
		{"word":"ratais","word_nosc":"ratais","lemma":"rater","pos":"VER"} ,
		{"word":"ratait","word_nosc":"ratait","lemma":"rater","pos":"VER"} ,
		{"word":"ratant","word_nosc":"ratant","lemma":"rater","pos":"VER"} ,
		{"word":"ratatinai","word_nosc":"ratatinai","lemma":"ratatiner","pos":"VER"} ,
		{"word":"ratatinaient","word_nosc":"ratatinaient","lemma":"ratatiner","pos":"VER"} ,
		{"word":"ratatinais","word_nosc":"ratatinais","lemma":"ratatiner","pos":"VER"} ,
		{"word":"ratatinait","word_nosc":"ratatinait","lemma":"ratatiner","pos":"VER"} ,
		{"word":"ratatine","word_nosc":"ratatine","lemma":"ratatiner","pos":"VER"} ,
		{"word":"ratatinent","word_nosc":"ratatinent","lemma":"ratatiner","pos":"VER"} ,
		{"word":"ratatiner","word_nosc":"ratatiner","lemma":"ratatiner","pos":"VER"} ,
		{"word":"ratatinerait","word_nosc":"ratatinerait","lemma":"ratatiner","pos":"VER"} ,
		{"word":"ratatineront","word_nosc":"ratatineront","lemma":"ratatiner","pos":"VER"} ,
		{"word":"ratatiné","word_nosc":"ratatine","lemma":"ratatiner","pos":"VER"} ,
		{"word":"ratatinée","word_nosc":"ratatinee","lemma":"ratatiner","pos":"VER"} ,
		{"word":"ratatinées","word_nosc":"ratatinees","lemma":"ratatiner","pos":"VER"} ,
		{"word":"ratatinés","word_nosc":"ratatines","lemma":"ratatiner","pos":"VER"} ,
		{"word":"rate","word_nosc":"rate","lemma":"rater","pos":"VER"} ,
		{"word":"ratent","word_nosc":"ratent","lemma":"rater","pos":"VER"} ,
		{"word":"rater","word_nosc":"rater","lemma":"rater","pos":"VER"} ,
		{"word":"ratera","word_nosc":"ratera","lemma":"rater","pos":"VER"} ,
		{"word":"raterai","word_nosc":"raterai","lemma":"rater","pos":"VER"} ,
		{"word":"raterais","word_nosc":"raterais","lemma":"rater","pos":"VER"} ,
		{"word":"raterait","word_nosc":"raterait","lemma":"rater","pos":"VER"} ,
		{"word":"rateras","word_nosc":"rateras","lemma":"rater","pos":"VER"} ,
		{"word":"raterez","word_nosc":"raterez","lemma":"rater","pos":"VER"} ,
		{"word":"rateriez","word_nosc":"rateriez","lemma":"rater","pos":"VER"} ,
		{"word":"raterons","word_nosc":"raterons","lemma":"rater","pos":"VER"} ,
		{"word":"rateront","word_nosc":"rateront","lemma":"rater","pos":"VER"} ,
		{"word":"rates","word_nosc":"rates","lemma":"rater","pos":"VER"} ,
		{"word":"ratez","word_nosc":"ratez","lemma":"rater","pos":"VER"} ,
		{"word":"ratiboisaient","word_nosc":"ratiboisaient","lemma":"ratiboiser","pos":"VER"} ,
		{"word":"ratiboisait","word_nosc":"ratiboisait","lemma":"ratiboiser","pos":"VER"} ,
		{"word":"ratiboiser","word_nosc":"ratiboiser","lemma":"ratiboiser","pos":"VER"} ,
		{"word":"ratiboises","word_nosc":"ratiboises","lemma":"ratiboiser","pos":"VER"} ,
		{"word":"ratiboisé","word_nosc":"ratiboise","lemma":"ratiboiser","pos":"VER"} ,
		{"word":"ratiboisés","word_nosc":"ratiboises","lemma":"ratiboiser","pos":"VER"} ,
		{"word":"ratiez","word_nosc":"ratiez","lemma":"rater","pos":"VER"} ,
		{"word":"ratifia","word_nosc":"ratifia","lemma":"ratifier","pos":"VER"} ,
		{"word":"ratifiant","word_nosc":"ratifiant","lemma":"ratifier","pos":"VER"} ,
		{"word":"ratifie","word_nosc":"ratifie","lemma":"ratifier","pos":"VER"} ,
		{"word":"ratifient","word_nosc":"ratifient","lemma":"ratifier","pos":"VER"} ,
		{"word":"ratifier","word_nosc":"ratifier","lemma":"ratifier","pos":"VER"} ,
		{"word":"ratifieront","word_nosc":"ratifieront","lemma":"ratifier","pos":"VER"} ,
		{"word":"ratifié","word_nosc":"ratifie","lemma":"ratifier","pos":"VER"} ,
		{"word":"ratifiée","word_nosc":"ratifiee","lemma":"ratifier","pos":"VER"} ,
		{"word":"ratifiés","word_nosc":"ratifies","lemma":"ratifier","pos":"VER"} ,
		{"word":"ratiocinait","word_nosc":"ratiocinait","lemma":"ratiociner","pos":"VER"} ,
		{"word":"ratiocinant","word_nosc":"ratiocinant","lemma":"ratiociner","pos":"VER"} ,
		{"word":"rationalise","word_nosc":"rationalise","lemma":"rationaliser","pos":"VER"} ,
		{"word":"rationaliser","word_nosc":"rationaliser","lemma":"rationaliser","pos":"VER"} ,
		{"word":"rationalisez","word_nosc":"rationalisez","lemma":"rationaliser","pos":"VER"} ,
		{"word":"rationalisé","word_nosc":"rationalise","lemma":"rationaliser","pos":"VER"} ,
		{"word":"rationne","word_nosc":"rationne","lemma":"rationner","pos":"VER"} ,
		{"word":"rationner","word_nosc":"rationner","lemma":"rationner","pos":"VER"} ,
		{"word":"rationnez","word_nosc":"rationnez","lemma":"rationner","pos":"VER"} ,
		{"word":"rationné","word_nosc":"rationne","lemma":"rationner","pos":"VER"} ,
		{"word":"rationnée","word_nosc":"rationnee","lemma":"rationner","pos":"VER"} ,
		{"word":"rationnées","word_nosc":"rationnees","lemma":"rationner","pos":"VER"} ,
		{"word":"rationnés","word_nosc":"rationnes","lemma":"rationner","pos":"VER"} ,
		{"word":"rations","word_nosc":"rations","lemma":"rater","pos":"VER"} ,
		{"word":"ratissa","word_nosc":"ratissa","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratissaient","word_nosc":"ratissaient","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratissais","word_nosc":"ratissais","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratissait","word_nosc":"ratissait","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratissant","word_nosc":"ratissant","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratisse","word_nosc":"ratisse","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratissent","word_nosc":"ratissent","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratisser","word_nosc":"ratisser","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratisserai","word_nosc":"ratisserai","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratisseront","word_nosc":"ratisseront","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratissez","word_nosc":"ratissez","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratissons","word_nosc":"ratissons","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratissé","word_nosc":"ratisse","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratissée","word_nosc":"ratissee","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratissées","word_nosc":"ratissees","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratissés","word_nosc":"ratisses","lemma":"ratisser","pos":"VER"} ,
		{"word":"ratonnent","word_nosc":"ratonnent","lemma":"ratonner","pos":"VER"} ,
		{"word":"ratonner","word_nosc":"ratonner","lemma":"ratonner","pos":"VER"} ,
		{"word":"ratons","word_nosc":"ratons","lemma":"rater","pos":"VER"} ,
		{"word":"rattacha","word_nosc":"rattacha","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattachaient","word_nosc":"rattachaient","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattachais","word_nosc":"rattachais","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattachait","word_nosc":"rattachait","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattachant","word_nosc":"rattachant","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattache","word_nosc":"rattache","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattachent","word_nosc":"rattachent","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattacher","word_nosc":"rattacher","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattachera","word_nosc":"rattachera","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattacherait","word_nosc":"rattacherait","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattachez","word_nosc":"rattachez","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattachions","word_nosc":"rattachions","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattachons","word_nosc":"rattachons","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattachât","word_nosc":"rattachat","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattaché","word_nosc":"rattache","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattachée","word_nosc":"rattachee","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattachées","word_nosc":"rattachees","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattachés","word_nosc":"rattaches","lemma":"rattacher","pos":"VER"} ,
		{"word":"rattrapa","word_nosc":"rattrapa","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapai","word_nosc":"rattrapai","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapaient","word_nosc":"rattrapaient","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapais","word_nosc":"rattrapais","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapait","word_nosc":"rattrapait","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapant","word_nosc":"rattrapant","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrape","word_nosc":"rattrape","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapent","word_nosc":"rattrapent","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattraper","word_nosc":"rattraper","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapera","word_nosc":"rattrapera","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattraperai","word_nosc":"rattraperai","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattraperaient","word_nosc":"rattraperaient","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattraperais","word_nosc":"rattraperais","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattraperait","word_nosc":"rattraperait","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattraperas","word_nosc":"rattraperas","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattraperez","word_nosc":"rattraperez","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattraperons","word_nosc":"rattraperons","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattraperont","word_nosc":"rattraperont","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapes","word_nosc":"rattrapes","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapez","word_nosc":"rattrapez","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapiez","word_nosc":"rattrapiez","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapions","word_nosc":"rattrapions","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapons","word_nosc":"rattrapons","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapât","word_nosc":"rattrapat","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapèrent","word_nosc":"rattraperent","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapé","word_nosc":"rattrape","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapée","word_nosc":"rattrapee","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapées","word_nosc":"rattrapees","lemma":"rattraper","pos":"VER"} ,
		{"word":"rattrapés","word_nosc":"rattrapes","lemma":"rattraper","pos":"VER"} ,
		{"word":"ratura","word_nosc":"ratura","lemma":"raturer","pos":"VER"} ,
		{"word":"raturai","word_nosc":"raturai","lemma":"raturer","pos":"VER"} ,
		{"word":"raturais","word_nosc":"raturais","lemma":"raturer","pos":"VER"} ,
		{"word":"raturait","word_nosc":"raturait","lemma":"raturer","pos":"VER"} ,
		{"word":"rature","word_nosc":"rature","lemma":"raturer","pos":"VER"} ,
		{"word":"raturer","word_nosc":"raturer","lemma":"raturer","pos":"VER"} ,
		{"word":"raturé","word_nosc":"rature","lemma":"raturer","pos":"VER"} ,
		{"word":"raturée","word_nosc":"raturee","lemma":"raturer","pos":"VER"} ,
		{"word":"raturées","word_nosc":"raturees","lemma":"raturer","pos":"VER"} ,
		{"word":"raturés","word_nosc":"ratures","lemma":"raturer","pos":"VER"} ,
		{"word":"ratèrent","word_nosc":"raterent","lemma":"rater","pos":"VER"} ,
		{"word":"raté","word_nosc":"rate","lemma":"rater","pos":"VER"} ,
		{"word":"ratée","word_nosc":"ratee","lemma":"rater","pos":"VER"} ,
		{"word":"ratées","word_nosc":"ratees","lemma":"rater","pos":"VER"} ,
		{"word":"ratés","word_nosc":"rates","lemma":"rater","pos":"VER"} ,
		{"word":"rauqua","word_nosc":"rauqua","lemma":"rauquer","pos":"VER"} ,
		{"word":"rauque","word_nosc":"rauque","lemma":"rauquer","pos":"VER"} ,
		{"word":"ravage","word_nosc":"ravage","lemma":"ravager","pos":"VER"} ,
		{"word":"ravagea","word_nosc":"ravagea","lemma":"ravager","pos":"VER"} ,
		{"word":"ravageaient","word_nosc":"ravageaient","lemma":"ravager","pos":"VER"} ,
		{"word":"ravageais","word_nosc":"ravageais","lemma":"ravager","pos":"VER"} ,
		{"word":"ravageait","word_nosc":"ravageait","lemma":"ravager","pos":"VER"} ,
		{"word":"ravagent","word_nosc":"ravagent","lemma":"ravager","pos":"VER"} ,
		{"word":"ravager","word_nosc":"ravager","lemma":"ravager","pos":"VER"} ,
		{"word":"ravagera","word_nosc":"ravagera","lemma":"ravager","pos":"VER"} ,
		{"word":"ravagerai","word_nosc":"ravagerai","lemma":"ravager","pos":"VER"} ,
		{"word":"ravageraient","word_nosc":"ravageraient","lemma":"ravager","pos":"VER"} ,
		{"word":"ravages","word_nosc":"ravages","lemma":"ravager","pos":"VER"} ,
		{"word":"ravagez","word_nosc":"ravagez","lemma":"ravager","pos":"VER"} ,
		{"word":"ravagèrent","word_nosc":"ravagerent","lemma":"ravager","pos":"VER"} ,
		{"word":"ravagé","word_nosc":"ravage","lemma":"ravager","pos":"VER"} ,
		{"word":"ravagée","word_nosc":"ravagee","lemma":"ravager","pos":"VER"} ,
		{"word":"ravagées","word_nosc":"ravagees","lemma":"ravager","pos":"VER"} ,
		{"word":"ravagés","word_nosc":"ravages","lemma":"ravager","pos":"VER"} ,
		{"word":"ravala","word_nosc":"ravala","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravalai","word_nosc":"ravalai","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravalaient","word_nosc":"ravalaient","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravalais","word_nosc":"ravalais","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravalait","word_nosc":"ravalait","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravalant","word_nosc":"ravalant","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravale","word_nosc":"ravale","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravalent","word_nosc":"ravalent","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravaler","word_nosc":"ravaler","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravalera","word_nosc":"ravalera","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravaleront","word_nosc":"ravaleront","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravales","word_nosc":"ravales","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravalez","word_nosc":"ravalez","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravalons","word_nosc":"ravalons","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravalât","word_nosc":"ravalat","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravalé","word_nosc":"ravale","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravalée","word_nosc":"ravalee","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravalées","word_nosc":"ravalees","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravalés","word_nosc":"ravales","lemma":"ravaler","pos":"VER"} ,
		{"word":"ravaudait","word_nosc":"ravaudait","lemma":"ravauder","pos":"VER"} ,
		{"word":"ravaudant","word_nosc":"ravaudant","lemma":"ravauder","pos":"VER"} ,
		{"word":"ravaude","word_nosc":"ravaude","lemma":"ravauder","pos":"VER"} ,
		{"word":"ravauder","word_nosc":"ravauder","lemma":"ravauder","pos":"VER"} ,
		{"word":"ravaudé","word_nosc":"ravaude","lemma":"ravauder","pos":"VER"} ,
		{"word":"ravaudées","word_nosc":"ravaudees","lemma":"ravauder","pos":"VER"} ,
		{"word":"ravaudés","word_nosc":"ravaudes","lemma":"ravauder","pos":"VER"} ,
		{"word":"ravi","word_nosc":"ravi","lemma":"ravir","pos":"VER"} ,
		{"word":"ravie","word_nosc":"ravie","lemma":"ravir","pos":"VER"} ,
		{"word":"ravies","word_nosc":"ravies","lemma":"ravir","pos":"VER"} ,
		{"word":"ravigotait","word_nosc":"ravigotait","lemma":"ravigoter","pos":"VER"} ,
		{"word":"ravigoter","word_nosc":"ravigoter","lemma":"ravigoter","pos":"VER"} ,
		{"word":"ravina","word_nosc":"ravina","lemma":"raviner","pos":"VER"} ,
		{"word":"ravinaient","word_nosc":"ravinaient","lemma":"raviner","pos":"VER"} ,
		{"word":"ravinant","word_nosc":"ravinant","lemma":"raviner","pos":"VER"} ,
		{"word":"ravine","word_nosc":"ravine","lemma":"raviner","pos":"VER"} ,
		{"word":"ravinent","word_nosc":"ravinent","lemma":"raviner","pos":"VER"} ,
		{"word":"raviner","word_nosc":"raviner","lemma":"raviner","pos":"VER"} ,
		{"word":"raviné","word_nosc":"ravine","lemma":"raviner","pos":"VER"} ,
		{"word":"ravinée","word_nosc":"ravinee","lemma":"raviner","pos":"VER"} ,
		{"word":"ravinées","word_nosc":"ravinees","lemma":"raviner","pos":"VER"} ,
		{"word":"ravinés","word_nosc":"ravines","lemma":"raviner","pos":"VER"} ,
		{"word":"ravir","word_nosc":"ravir","lemma":"ravir","pos":"VER"} ,
		{"word":"ravirait","word_nosc":"ravirait","lemma":"ravir","pos":"VER"} ,
		{"word":"ravirent","word_nosc":"ravirent","lemma":"ravir","pos":"VER"} ,
		{"word":"raviront","word_nosc":"raviront","lemma":"ravir","pos":"VER"} ,
		{"word":"ravis","word_nosc":"ravis","lemma":"ravir","pos":"VER"} ,
		{"word":"ravisa","word_nosc":"ravisa","lemma":"raviser","pos":"VER"} ,
		{"word":"ravisais","word_nosc":"ravisais","lemma":"raviser","pos":"VER"} ,
		{"word":"ravisait","word_nosc":"ravisait","lemma":"raviser","pos":"VER"} ,
		{"word":"ravisant","word_nosc":"ravisant","lemma":"raviser","pos":"VER"} ,
		{"word":"ravise","word_nosc":"ravise","lemma":"raviser","pos":"VER"} ,
		{"word":"ravisent","word_nosc":"ravisent","lemma":"raviser","pos":"VER"} ,
		{"word":"raviser","word_nosc":"raviser","lemma":"raviser","pos":"VER"} ,
		{"word":"ravisera","word_nosc":"ravisera","lemma":"raviser","pos":"VER"} ,
		{"word":"raviserais","word_nosc":"raviserais","lemma":"raviser","pos":"VER"} ,
		{"word":"ravises","word_nosc":"ravises","lemma":"raviser","pos":"VER"} ,
		{"word":"ravisez","word_nosc":"ravisez","lemma":"raviser","pos":"VER"} ,
		{"word":"ravissaient","word_nosc":"ravissaient","lemma":"ravir","pos":"VER"} ,
		{"word":"ravissait","word_nosc":"ravissait","lemma":"ravir","pos":"VER"} ,
		{"word":"ravissant","word_nosc":"ravissant","lemma":"ravir","pos":"VER"} ,
		{"word":"ravisse","word_nosc":"ravisse","lemma":"ravir","pos":"VER"} ,
		{"word":"ravissent","word_nosc":"ravissent","lemma":"ravir","pos":"VER"} ,
		{"word":"ravisé","word_nosc":"ravise","lemma":"raviser","pos":"VER"} ,
		{"word":"ravisée","word_nosc":"ravisee","lemma":"raviser","pos":"VER"} ,
		{"word":"ravit","word_nosc":"ravit","lemma":"ravir","pos":"VER"} ,
		{"word":"ravitaillaient","word_nosc":"ravitaillaient","lemma":"ravitailler","pos":"VER"} ,
		{"word":"ravitaillait","word_nosc":"ravitaillait","lemma":"ravitailler","pos":"VER"} ,
		{"word":"ravitaille","word_nosc":"ravitaille","lemma":"ravitailler","pos":"VER"} ,
		{"word":"ravitaillent","word_nosc":"ravitaillent","lemma":"ravitailler","pos":"VER"} ,
		{"word":"ravitailler","word_nosc":"ravitailler","lemma":"ravitailler","pos":"VER"} ,
		{"word":"ravitaillera","word_nosc":"ravitaillera","lemma":"ravitailler","pos":"VER"} ,
		{"word":"ravitaillerait","word_nosc":"ravitaillerait","lemma":"ravitailler","pos":"VER"} ,
		{"word":"ravitailleras","word_nosc":"ravitailleras","lemma":"ravitailler","pos":"VER"} ,
		{"word":"ravitaillerez","word_nosc":"ravitaillerez","lemma":"ravitailler","pos":"VER"} ,
		{"word":"ravitaillons","word_nosc":"ravitaillons","lemma":"ravitailler","pos":"VER"} ,
		{"word":"ravitaillé","word_nosc":"ravitaille","lemma":"ravitailler","pos":"VER"} ,
		{"word":"ravitaillée","word_nosc":"ravitaillee","lemma":"ravitailler","pos":"VER"} ,
		{"word":"ravitaillées","word_nosc":"ravitaillees","lemma":"ravitailler","pos":"VER"} ,
		{"word":"ravitaillés","word_nosc":"ravitailles","lemma":"ravitailler","pos":"VER"} ,
		{"word":"raviva","word_nosc":"raviva","lemma":"raviver","pos":"VER"} ,
		{"word":"ravivaient","word_nosc":"ravivaient","lemma":"raviver","pos":"VER"} ,
		{"word":"ravivait","word_nosc":"ravivait","lemma":"raviver","pos":"VER"} ,
		{"word":"ravivant","word_nosc":"ravivant","lemma":"raviver","pos":"VER"} ,
		{"word":"ravive","word_nosc":"ravive","lemma":"raviver","pos":"VER"} ,
		{"word":"ravivent","word_nosc":"ravivent","lemma":"raviver","pos":"VER"} ,
		{"word":"raviver","word_nosc":"raviver","lemma":"raviver","pos":"VER"} ,
		{"word":"ravivez","word_nosc":"ravivez","lemma":"raviver","pos":"VER"} ,
		{"word":"ravivât","word_nosc":"ravivat","lemma":"raviver","pos":"VER"} ,
		{"word":"ravivé","word_nosc":"ravive","lemma":"raviver","pos":"VER"} ,
		{"word":"ravivée","word_nosc":"ravivee","lemma":"raviver","pos":"VER"} ,
		{"word":"ravoir","word_nosc":"ravoir","lemma":"ravoir","pos":"VER"} ,
		{"word":"raya","word_nosc":"raya","lemma":"rayer","pos":"VER"} ,
		{"word":"rayaient","word_nosc":"rayaient","lemma":"rayer","pos":"VER"} ,
		{"word":"rayait","word_nosc":"rayait","lemma":"rayer","pos":"VER"} ,
		{"word":"rayant","word_nosc":"rayant","lemma":"rayer","pos":"VER"} ,
		{"word":"raye","word_nosc":"raye","lemma":"rayer","pos":"VER"} ,
		{"word":"rayent","word_nosc":"rayent","lemma":"rayer","pos":"VER"} ,
		{"word":"rayer","word_nosc":"rayer","lemma":"rayer","pos":"VER"} ,
		{"word":"rayera","word_nosc":"rayera","lemma":"rayer","pos":"VER"} ,
		{"word":"rayerai","word_nosc":"rayerai","lemma":"rayer","pos":"VER"} ,
		{"word":"rayerais","word_nosc":"rayerais","lemma":"rayer","pos":"VER"} ,
		{"word":"rayeras","word_nosc":"rayeras","lemma":"rayer","pos":"VER"} ,
		{"word":"rayeront","word_nosc":"rayeront","lemma":"rayer","pos":"VER"} ,
		{"word":"rayez","word_nosc":"rayez","lemma":"rayer","pos":"VER"} ,
		{"word":"rayions","word_nosc":"rayions","lemma":"rayer","pos":"VER"} ,
		{"word":"rayonna","word_nosc":"rayonna","lemma":"rayonner","pos":"VER"} ,
		{"word":"rayonnaient","word_nosc":"rayonnaient","lemma":"rayonner","pos":"VER"} ,
		{"word":"rayonnais","word_nosc":"rayonnais","lemma":"rayonner","pos":"VER"} ,
		{"word":"rayonnait","word_nosc":"rayonnait","lemma":"rayonner","pos":"VER"} ,
		{"word":"rayonnant","word_nosc":"rayonnant","lemma":"rayonner","pos":"VER"} ,
		{"word":"rayonne","word_nosc":"rayonne","lemma":"rayonner","pos":"VER"} ,
		{"word":"rayonnent","word_nosc":"rayonnent","lemma":"rayonner","pos":"VER"} ,
		{"word":"rayonner","word_nosc":"rayonner","lemma":"rayonner","pos":"VER"} ,
		{"word":"rayonnera","word_nosc":"rayonnera","lemma":"rayonner","pos":"VER"} ,
		{"word":"rayonnerait","word_nosc":"rayonnerait","lemma":"rayonner","pos":"VER"} ,
		{"word":"rayonnes","word_nosc":"rayonnes","lemma":"rayonner","pos":"VER"} ,
		{"word":"rayonnez","word_nosc":"rayonnez","lemma":"rayonner","pos":"VER"} ,
		{"word":"rayonné","word_nosc":"rayonne","lemma":"rayonner","pos":"VER"} ,
		{"word":"rayons","word_nosc":"rayons","lemma":"rayer","pos":"VER"} ,
		{"word":"rayât","word_nosc":"rayat","lemma":"rayer","pos":"VER"} ,
		{"word":"rayé","word_nosc":"raye","lemma":"rayer","pos":"VER"} ,
		{"word":"rayée","word_nosc":"rayee","lemma":"rayer","pos":"VER"} ,
		{"word":"rayées","word_nosc":"rayees","lemma":"rayer","pos":"VER"} ,
		{"word":"rayés","word_nosc":"rayes","lemma":"rayer","pos":"VER"} ,
		{"word":"razziais","word_nosc":"razziais","lemma":"razzier","pos":"VER"} ,
		{"word":"razzier","word_nosc":"razzier","lemma":"razzier","pos":"VER"} ,
		{"word":"re-aboie","word_nosc":"re-aboie","lemma":"reaboyer","pos":"VER"} ,
		{"word":"re-biberonner","word_nosc":"re-biberonner","lemma":"rebiberonner","pos":"VER"} ,
		{"word":"re-blinder","word_nosc":"re-blinder","lemma":"reblinder","pos":"VER"} ,
		{"word":"re-boucler","word_nosc":"re-boucler","lemma":"reboucler","pos":"VER"} ,
		{"word":"re-cabossé","word_nosc":"re-cabosse","lemma":"recabosser","pos":"VER"} ,
		{"word":"re-calibrais","word_nosc":"re-calibrais","lemma":"recalibrer","pos":"VER"} ,
		{"word":"re-cassée","word_nosc":"re-cassee","lemma":"recasser","pos":"VER"} ,
		{"word":"re-chiader","word_nosc":"re-chiader","lemma":"rechiader","pos":"VER"} ,
		{"word":"re-cisèle","word_nosc":"re-cisele","lemma":"reciseler","pos":"VER"} ,
		{"word":"re-classe","word_nosc":"re-classe","lemma":"reclasser","pos":"VER"} ,
		{"word":"re-classer","word_nosc":"re-classer","lemma":"reclasser","pos":"VER"} ,
		{"word":"re-connaître","word_nosc":"re-connaitre","lemma":"reconnaître","pos":"VER"} ,
		{"word":"re-contacte","word_nosc":"re-contacte","lemma":"recontacter","pos":"VER"} ,
		{"word":"re-contactent","word_nosc":"re-contactent","lemma":"recontacter","pos":"VER"} ,
		{"word":"re-contacter","word_nosc":"re-contacter","lemma":"recontacter","pos":"VER"} ,
		{"word":"re-contacterai","word_nosc":"re-contacterai","lemma":"recontacter","pos":"VER"} ,
		{"word":"re-convaincra","word_nosc":"re-convaincra","lemma":"reconvaincre","pos":"VER"} ,
		{"word":"re-creusés","word_nosc":"re-creuses","lemma":"recreuser","pos":"VER"} ,
		{"word":"re-créée","word_nosc":"re-creee","lemma":"recréer","pos":"VER"} ,
		{"word":"re-diffuses","word_nosc":"re-diffuses","lemma":"rediffuser","pos":"VER"} ,
		{"word":"re-déchire","word_nosc":"re-dechire","lemma":"redéchirer","pos":"VER"} ,
		{"word":"re-décore","word_nosc":"re-decore","lemma":"redécorer","pos":"VER"} ,
		{"word":"re-décorer","word_nosc":"re-decorer","lemma":"redécorer","pos":"VER"} ,
		{"word":"re-déménager","word_nosc":"re-demenager","lemma":"redéménager","pos":"VER"} ,
		{"word":"re-explique","word_nosc":"re-explique","lemma":"reexpliquer","pos":"VER"} ,
		{"word":"re-frappe","word_nosc":"re-frappe","lemma":"refrapper","pos":"VER"} ,
		{"word":"re-frappé","word_nosc":"re-frappe","lemma":"refrapper","pos":"VER"} ,
		{"word":"re-fuse","word_nosc":"re-fuse","lemma":"refuser","pos":"VER"} ,
		{"word":"re-kidnappais","word_nosc":"re-kidnappais","lemma":"rekidnapper","pos":"VER"} ,
		{"word":"re-paye","word_nosc":"re-paye","lemma":"repayer","pos":"VER"} ,
		{"word":"re-penser","word_nosc":"re-penser","lemma":"repenser","pos":"VER"} ,
		{"word":"re-potassé","word_nosc":"re-potasse","lemma":"repotasser","pos":"VER"} ,
		{"word":"re-programmée","word_nosc":"re-programmee","lemma":"reprogrammer","pos":"VER"} ,
		{"word":"re-raconte","word_nosc":"re-raconte","lemma":"reraconter","pos":"VER"} ,
		{"word":"re-racontées","word_nosc":"re-racontees","lemma":"reraconter","pos":"VER"} ,
		{"word":"re-remplir","word_nosc":"re-remplir","lemma":"reremplir","pos":"VER"} ,
		{"word":"re-rentrer","word_nosc":"re-rentrer","lemma":"rerentrer","pos":"VER"} ,
		{"word":"re-respirer","word_nosc":"re-respirer","lemma":"rerespirer","pos":"VER"} ,
		{"word":"re-ressortait","word_nosc":"re-ressortait","lemma":"reressortir","pos":"VER"} ,
		{"word":"re-retirent","word_nosc":"re-retirent","lemma":"reretirer","pos":"VER"} ,
		{"word":"re-récurait","word_nosc":"re-recurait","lemma":"rerécurer","pos":"VER"} ,
		{"word":"re-réparer","word_nosc":"re-reparer","lemma":"reréparer","pos":"VER"} ,
		{"word":"re-salué","word_nosc":"re-salue","lemma":"resaluer","pos":"VER"} ,
		{"word":"re-savaté","word_nosc":"re-savate","lemma":"resavater","pos":"VER"} ,
		{"word":"re-sculpte","word_nosc":"re-sculpte","lemma":"resculpter","pos":"VER"} ,
		{"word":"re-signer","word_nosc":"re-signer","lemma":"resigner","pos":"VER"} ,
		{"word":"re-sonne","word_nosc":"re-sonne","lemma":"resonner","pos":"VER"} ,
		{"word":"re-titille","word_nosc":"re-titille","lemma":"retitiller","pos":"VER"} ,
		{"word":"re-tuer","word_nosc":"re-tuer","lemma":"retuer","pos":"VER"} ,
		{"word":"re-veux","word_nosc":"re-veux","lemma":"revouloir","pos":"VER"} ,
		{"word":"re-visite","word_nosc":"re-visite","lemma":"revisiter","pos":"VER"} ,
		{"word":"re-vit","word_nosc":"re-vit","lemma":"revivre","pos":"VER"} ,
		{"word":"re-vérifier","word_nosc":"re-verifier","lemma":"revérifier","pos":"VER"} ,
		{"word":"re-ébranlés","word_nosc":"re-ebranles","lemma":"reébranler","pos":"VER"} ,
		{"word":"re-échanger","word_nosc":"re-echanger","lemma":"reéchanger","pos":"VER"} ,
		{"word":"rebaise","word_nosc":"rebaise","lemma":"rebaiser","pos":"VER"} ,
		{"word":"rebaisent","word_nosc":"rebaisent","lemma":"rebaiser","pos":"VER"} ,
		{"word":"rebaiser","word_nosc":"rebaiser","lemma":"rebaiser","pos":"VER"} ,
		{"word":"rebaissa","word_nosc":"rebaissa","lemma":"rebaisser","pos":"VER"} ,
		{"word":"rebaissait","word_nosc":"rebaissait","lemma":"rebaisser","pos":"VER"} ,
		{"word":"rebaisse","word_nosc":"rebaisse","lemma":"rebaisser","pos":"VER"} ,
		{"word":"rebaissé","word_nosc":"rebaisse","lemma":"rebaisser","pos":"VER"} ,
		{"word":"rebaisé","word_nosc":"rebaise","lemma":"rebaiser","pos":"VER"} ,
		{"word":"rebander","word_nosc":"rebander","lemma":"rebander","pos":"VER"} ,
		{"word":"rebaptisa","word_nosc":"rebaptisa","lemma":"rebaptiser","pos":"VER"} ,
		{"word":"rebaptise","word_nosc":"rebaptise","lemma":"rebaptiser","pos":"VER"} ,
		{"word":"rebaptiser","word_nosc":"rebaptiser","lemma":"rebaptiser","pos":"VER"} ,
		{"word":"rebaptisé","word_nosc":"rebaptise","lemma":"rebaptiser","pos":"VER"} ,
		{"word":"rebaptisée","word_nosc":"rebaptisee","lemma":"rebaptiser","pos":"VER"} ,
		{"word":"rebaptisées","word_nosc":"rebaptisees","lemma":"rebaptiser","pos":"VER"} ,
		{"word":"rebasculait","word_nosc":"rebasculait","lemma":"rebasculer","pos":"VER"} ,
		{"word":"rebascule","word_nosc":"rebascule","lemma":"rebasculer","pos":"VER"} ,
		{"word":"rebasculera","word_nosc":"rebasculera","lemma":"rebasculer","pos":"VER"} ,
		{"word":"rebat","word_nosc":"rebat","lemma":"rebattre","pos":"VER"} ,
		{"word":"rebats","word_nosc":"rebats","lemma":"rebattre","pos":"VER"} ,
		{"word":"rebattait","word_nosc":"rebattait","lemma":"rebattre","pos":"VER"} ,
		{"word":"rebattent","word_nosc":"rebattent","lemma":"rebattre","pos":"VER"} ,
		{"word":"rebattez","word_nosc":"rebattez","lemma":"rebattre","pos":"VER"} ,
		{"word":"rebattit","word_nosc":"rebattit","lemma":"rebattre","pos":"VER"} ,
		{"word":"rebattre","word_nosc":"rebattre","lemma":"rebattre","pos":"VER"} ,
		{"word":"rebattu","word_nosc":"rebattu","lemma":"rebattre","pos":"VER"} ,
		{"word":"rebattue","word_nosc":"rebattue","lemma":"rebattre","pos":"VER"} ,
		{"word":"rebattues","word_nosc":"rebattues","lemma":"rebattre","pos":"VER"} ,
		{"word":"rebattus","word_nosc":"rebattus","lemma":"rebattre","pos":"VER"} ,
		{"word":"rebecter","word_nosc":"rebecter","lemma":"rebecter","pos":"VER"} ,
		{"word":"rebecté","word_nosc":"rebecte","lemma":"rebecter","pos":"VER"} ,
		{"word":"rebella","word_nosc":"rebella","lemma":"rebeller","pos":"VER"} ,
		{"word":"rebellaient","word_nosc":"rebellaient","lemma":"rebeller","pos":"VER"} ,
		{"word":"rebellais","word_nosc":"rebellais","lemma":"rebeller","pos":"VER"} ,
		{"word":"rebellait","word_nosc":"rebellait","lemma":"rebeller","pos":"VER"} ,
		{"word":"rebellant","word_nosc":"rebellant","lemma":"rebeller","pos":"VER"} ,
		{"word":"rebelle","word_nosc":"rebelle","lemma":"rebeller","pos":"VER"} ,
		{"word":"rebellent","word_nosc":"rebellent","lemma":"rebeller","pos":"VER"} ,
		{"word":"rebeller","word_nosc":"rebeller","lemma":"rebeller","pos":"VER"} ,
		{"word":"rebelles","word_nosc":"rebelles","lemma":"rebeller","pos":"VER"} ,
		{"word":"rebellez","word_nosc":"rebellez","lemma":"rebeller","pos":"VER"} ,
		{"word":"rebellât","word_nosc":"rebellat","lemma":"rebeller","pos":"VER"} ,
		{"word":"rebellèrent","word_nosc":"rebellerent","lemma":"rebeller","pos":"VER"} ,
		{"word":"rebellé","word_nosc":"rebelle","lemma":"rebeller","pos":"VER"} ,
		{"word":"rebellée","word_nosc":"rebellee","lemma":"rebeller","pos":"VER"} ,
		{"word":"rebellés","word_nosc":"rebelles","lemma":"rebeller","pos":"VER"} ,
		{"word":"rebiffa","word_nosc":"rebiffa","lemma":"rebiffer","pos":"VER"} ,
		{"word":"rebiffai","word_nosc":"rebiffai","lemma":"rebiffer","pos":"VER"} ,
		{"word":"rebiffaient","word_nosc":"rebiffaient","lemma":"rebiffer","pos":"VER"} ,
		{"word":"rebiffais","word_nosc":"rebiffais","lemma":"rebiffer","pos":"VER"} ,
		{"word":"rebiffait","word_nosc":"rebiffait","lemma":"rebiffer","pos":"VER"} ,
		{"word":"rebiffant","word_nosc":"rebiffant","lemma":"rebiffer","pos":"VER"} ,
		{"word":"rebiffe","word_nosc":"rebiffe","lemma":"rebiffer","pos":"VER"} ,
		{"word":"rebiffent","word_nosc":"rebiffent","lemma":"rebiffer","pos":"VER"} ,
		{"word":"rebiffer","word_nosc":"rebiffer","lemma":"rebiffer","pos":"VER"} ,
		{"word":"rebifferai","word_nosc":"rebifferai","lemma":"rebiffer","pos":"VER"} ,
		{"word":"rebiffes","word_nosc":"rebiffes","lemma":"rebiffer","pos":"VER"} ,
		{"word":"rebiffèrent","word_nosc":"rebifferent","lemma":"rebiffer","pos":"VER"} ,
		{"word":"rebiffé","word_nosc":"rebiffe","lemma":"rebiffer","pos":"VER"} ,
		{"word":"rebiffée","word_nosc":"rebiffee","lemma":"rebiffer","pos":"VER"} ,
		{"word":"rebiquaient","word_nosc":"rebiquaient","lemma":"rebiquer","pos":"VER"} ,
		{"word":"rebiquait","word_nosc":"rebiquait","lemma":"rebiquer","pos":"VER"} ,
		{"word":"rebique","word_nosc":"rebique","lemma":"rebiquer","pos":"VER"} ,
		{"word":"rebiquer","word_nosc":"rebiquer","lemma":"rebiquer","pos":"VER"} ,
		{"word":"rebiquée","word_nosc":"rebiquee","lemma":"rebiquer","pos":"VER"} ,
		{"word":"reblanchir","word_nosc":"reblanchir","lemma":"reblanchir","pos":"VER"} ,
		{"word":"rebloquer","word_nosc":"rebloquer","lemma":"rebloquer","pos":"VER"} ,
		{"word":"rebobiner","word_nosc":"rebobiner","lemma":"rebobiner","pos":"VER"} ,
		{"word":"reboire","word_nosc":"reboire","lemma":"reboire","pos":"VER"} ,
		{"word":"rebois","word_nosc":"rebois","lemma":"reboire","pos":"VER"} ,
		{"word":"reboise","word_nosc":"reboise","lemma":"reboiser","pos":"VER"} ,
		{"word":"reboisées","word_nosc":"reboisees","lemma":"reboiser","pos":"VER"} ,
		{"word":"reboit","word_nosc":"reboit","lemma":"reboire","pos":"VER"} ,
		{"word":"rebondi","word_nosc":"rebondi","lemma":"rebondir","pos":"VER"} ,
		{"word":"rebondie","word_nosc":"rebondie","lemma":"rebondir","pos":"VER"} ,
		{"word":"rebondies","word_nosc":"rebondies","lemma":"rebondir","pos":"VER"} ,
		{"word":"rebondir","word_nosc":"rebondir","lemma":"rebondir","pos":"VER"} ,
		{"word":"rebondira","word_nosc":"rebondira","lemma":"rebondir","pos":"VER"} ,
		{"word":"rebondiraient","word_nosc":"rebondiraient","lemma":"rebondir","pos":"VER"} ,
		{"word":"rebondirait","word_nosc":"rebondirait","lemma":"rebondir","pos":"VER"} ,
		{"word":"rebondirent","word_nosc":"rebondirent","lemma":"rebondir","pos":"VER"} ,
		{"word":"rebondis","word_nosc":"rebondis","lemma":"rebondir","pos":"VER"} ,
		{"word":"rebondissaient","word_nosc":"rebondissaient","lemma":"rebondir","pos":"VER"} ,
		{"word":"rebondissais","word_nosc":"rebondissais","lemma":"rebondir","pos":"VER"} ,
		{"word":"rebondissait","word_nosc":"rebondissait","lemma":"rebondir","pos":"VER"} ,
		{"word":"rebondisse","word_nosc":"rebondisse","lemma":"rebondir","pos":"VER"} ,
		{"word":"rebondissent","word_nosc":"rebondissent","lemma":"rebondir","pos":"VER"} ,
		{"word":"rebondit","word_nosc":"rebondit","lemma":"rebondir","pos":"VER"} ,
		{"word":"rebooter","word_nosc":"rebooter","lemma":"rebooter","pos":"VER"} ,
		{"word":"reborder","word_nosc":"reborder","lemma":"reborder","pos":"VER"} ,
		{"word":"rebordée","word_nosc":"rebordee","lemma":"reborder","pos":"VER"} ,
		{"word":"reboucha","word_nosc":"reboucha","lemma":"reboucher","pos":"VER"} ,
		{"word":"rebouchait","word_nosc":"rebouchait","lemma":"reboucher","pos":"VER"} ,
		{"word":"rebouchant","word_nosc":"rebouchant","lemma":"reboucher","pos":"VER"} ,
		{"word":"rebouche","word_nosc":"rebouche","lemma":"reboucher","pos":"VER"} ,
		{"word":"rebouchent","word_nosc":"rebouchent","lemma":"reboucher","pos":"VER"} ,
		{"word":"reboucher","word_nosc":"reboucher","lemma":"reboucher","pos":"VER"} ,
		{"word":"reboucheront","word_nosc":"reboucheront","lemma":"reboucher","pos":"VER"} ,
		{"word":"rebouchez","word_nosc":"rebouchez","lemma":"reboucher","pos":"VER"} ,
		{"word":"rebouchèrent","word_nosc":"reboucherent","lemma":"reboucher","pos":"VER"} ,
		{"word":"rebouché","word_nosc":"rebouche","lemma":"reboucher","pos":"VER"} ,
		{"word":"rebouchée","word_nosc":"rebouchee","lemma":"reboucher","pos":"VER"} ,
		{"word":"rebouchées","word_nosc":"rebouchees","lemma":"reboucher","pos":"VER"} ,
		{"word":"rebouchés","word_nosc":"rebouches","lemma":"reboucher","pos":"VER"} ,
		{"word":"reboucla","word_nosc":"reboucla","lemma":"reboucler","pos":"VER"} ,
		{"word":"reboucle","word_nosc":"reboucle","lemma":"reboucler","pos":"VER"} ,
		{"word":"reboucler","word_nosc":"reboucler","lemma":"reboucler","pos":"VER"} ,
		{"word":"rebouclé","word_nosc":"reboucle","lemma":"reboucler","pos":"VER"} ,
		{"word":"rebouclée","word_nosc":"rebouclee","lemma":"reboucler","pos":"VER"} ,
		{"word":"rebourrer","word_nosc":"rebourrer","lemma":"rebourrer","pos":"VER"} ,
		{"word":"reboute","word_nosc":"reboute","lemma":"rebouter","pos":"VER"} ,
		{"word":"rebouter","word_nosc":"rebouter","lemma":"rebouter","pos":"VER"} ,
		{"word":"reboutez","word_nosc":"reboutez","lemma":"rebouter","pos":"VER"} ,
		{"word":"reboutonna","word_nosc":"reboutonna","lemma":"reboutonner","pos":"VER"} ,
		{"word":"reboutonnaient","word_nosc":"reboutonnaient","lemma":"reboutonner","pos":"VER"} ,
		{"word":"reboutonnais","word_nosc":"reboutonnais","lemma":"reboutonner","pos":"VER"} ,
		{"word":"reboutonnant","word_nosc":"reboutonnant","lemma":"reboutonner","pos":"VER"} ,
		{"word":"reboutonne","word_nosc":"reboutonne","lemma":"reboutonner","pos":"VER"} ,
		{"word":"reboutonner","word_nosc":"reboutonner","lemma":"reboutonner","pos":"VER"} ,
		{"word":"reboutonnerait","word_nosc":"reboutonnerait","lemma":"reboutonner","pos":"VER"} ,
		{"word":"reboutonnez","word_nosc":"reboutonnez","lemma":"reboutonner","pos":"VER"} ,
		{"word":"reboutonnèrent","word_nosc":"reboutonnerent","lemma":"reboutonner","pos":"VER"} ,
		{"word":"reboutonné","word_nosc":"reboutonne","lemma":"reboutonner","pos":"VER"} ,
		{"word":"reboutonnée","word_nosc":"reboutonnee","lemma":"reboutonner","pos":"VER"} ,
		{"word":"reboutonnés","word_nosc":"reboutonnes","lemma":"reboutonner","pos":"VER"} ,
		{"word":"rebraguetter","word_nosc":"rebraguetter","lemma":"rebraguetter","pos":"VER"} ,
		{"word":"rebraguetté","word_nosc":"rebraguette","lemma":"rebraguetter","pos":"VER"} ,
		{"word":"rebrancha","word_nosc":"rebrancha","lemma":"rebrancher","pos":"VER"} ,
		{"word":"rebranche","word_nosc":"rebranche","lemma":"rebrancher","pos":"VER"} ,
		{"word":"rebrancher","word_nosc":"rebrancher","lemma":"rebrancher","pos":"VER"} ,
		{"word":"rebranchez","word_nosc":"rebranchez","lemma":"rebrancher","pos":"VER"} ,
		{"word":"rebranché","word_nosc":"rebranche","lemma":"rebrancher","pos":"VER"} ,
		{"word":"rebranchée","word_nosc":"rebranchee","lemma":"rebrancher","pos":"VER"} ,
		{"word":"rebrodait","word_nosc":"rebrodait","lemma":"rebroder","pos":"VER"} ,
		{"word":"rebrodée","word_nosc":"rebrodee","lemma":"rebroder","pos":"VER"} ,
		{"word":"rebrodés","word_nosc":"rebrodes","lemma":"rebroder","pos":"VER"} ,
		{"word":"rebrosser","word_nosc":"rebrosser","lemma":"rebrosser","pos":"VER"} ,
		{"word":"rebroussa","word_nosc":"rebroussa","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebroussai","word_nosc":"rebroussai","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebroussaient","word_nosc":"rebroussaient","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebroussait","word_nosc":"rebroussait","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebroussant","word_nosc":"rebroussant","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebrousse","word_nosc":"rebrousse","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebroussent","word_nosc":"rebroussent","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebrousser","word_nosc":"rebrousser","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebrousserai","word_nosc":"rebrousserai","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebrousserez","word_nosc":"rebrousserez","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebroussez","word_nosc":"rebroussez","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebroussons","word_nosc":"rebroussons","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebroussâmes","word_nosc":"rebroussames","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebroussât","word_nosc":"rebroussat","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebroussèrent","word_nosc":"rebrousserent","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebroussé","word_nosc":"rebrousse","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebroussée","word_nosc":"rebroussee","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebroussées","word_nosc":"rebroussees","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebroussés","word_nosc":"rebrousses","lemma":"rebrousser","pos":"VER"} ,
		{"word":"rebrûlé","word_nosc":"rebrule","lemma":"rebrûler","pos":"VER"} ,
		{"word":"rebu","word_nosc":"rebu","lemma":"reboire","pos":"VER"} ,
		{"word":"rebus","word_nosc":"rebus","lemma":"reboire","pos":"VER"} ,
		{"word":"rebut","word_nosc":"rebut","lemma":"reboire","pos":"VER"} ,
		{"word":"rebuta","word_nosc":"rebuta","lemma":"rebuter","pos":"VER"} ,
		{"word":"rebutaient","word_nosc":"rebutaient","lemma":"rebuter","pos":"VER"} ,
		{"word":"rebutait","word_nosc":"rebutait","lemma":"rebuter","pos":"VER"} ,
		{"word":"rebutant","word_nosc":"rebutant","lemma":"rebuter","pos":"VER"} ,
		{"word":"rebute","word_nosc":"rebute","lemma":"rebuter","pos":"VER"} ,
		{"word":"rebutent","word_nosc":"rebutent","lemma":"rebuter","pos":"VER"} ,
		{"word":"rebuter","word_nosc":"rebuter","lemma":"rebuter","pos":"VER"} ,
		{"word":"rebutera","word_nosc":"rebutera","lemma":"rebuter","pos":"VER"} ,
		{"word":"rebuterait","word_nosc":"rebuterait","lemma":"rebuter","pos":"VER"} ,
		{"word":"rebutèrent","word_nosc":"rebuterent","lemma":"rebuter","pos":"VER"} ,
		{"word":"rebuté","word_nosc":"rebute","lemma":"rebuter","pos":"VER"} ,
		{"word":"rebutée","word_nosc":"rebutee","lemma":"rebuter","pos":"VER"} ,
		{"word":"rebutées","word_nosc":"rebutees","lemma":"rebuter","pos":"VER"} ,
		{"word":"rebutés","word_nosc":"rebutes","lemma":"rebuter","pos":"VER"} ,
		{"word":"rebuvait","word_nosc":"rebuvait","lemma":"reboire","pos":"VER"} ,
		{"word":"rebâti","word_nosc":"rebati","lemma":"rebâtir","pos":"VER"} ,
		{"word":"rebâtie","word_nosc":"rebatie","lemma":"rebâtir","pos":"VER"} ,
		{"word":"rebâties","word_nosc":"rebaties","lemma":"rebâtir","pos":"VER"} ,
		{"word":"rebâtir","word_nosc":"rebatir","lemma":"rebâtir","pos":"VER"} ,
		{"word":"rebâtirai","word_nosc":"rebatirai","lemma":"rebâtir","pos":"VER"} ,
		{"word":"rebâtirent","word_nosc":"rebatirent","lemma":"rebâtir","pos":"VER"} ,
		{"word":"rebâtirons","word_nosc":"rebatirons","lemma":"rebâtir","pos":"VER"} ,
		{"word":"rebâtis","word_nosc":"rebatis","lemma":"rebâtir","pos":"VER"} ,
		{"word":"rebâtissaient","word_nosc":"rebatissaient","lemma":"rebâtir","pos":"VER"} ,
		{"word":"rebâtissant","word_nosc":"rebatissant","lemma":"rebâtir","pos":"VER"} ,
		{"word":"rebâtissent","word_nosc":"rebatissent","lemma":"rebâtir","pos":"VER"} ,
		{"word":"rebâtissons","word_nosc":"rebatissons","lemma":"rebâtir","pos":"VER"} ,
		{"word":"rebâtit","word_nosc":"rebatit","lemma":"rebâtir","pos":"VER"} ,
		{"word":"rebéquer","word_nosc":"rebequer","lemma":"rebéquer","pos":"VER"} ,
		{"word":"recache","word_nosc":"recache","lemma":"recacher","pos":"VER"} ,
		{"word":"recacher","word_nosc":"recacher","lemma":"recacher","pos":"VER"} ,
		{"word":"recacheter","word_nosc":"recacheter","lemma":"recacheter","pos":"VER"} ,
		{"word":"recachetée","word_nosc":"recachetee","lemma":"recacheter","pos":"VER"} ,
		{"word":"recadre","word_nosc":"recadre","lemma":"recadrer","pos":"VER"} ,
		{"word":"recadrer","word_nosc":"recadrer","lemma":"recadrer","pos":"VER"} ,
		{"word":"recadrez","word_nosc":"recadrez","lemma":"recadrer","pos":"VER"} ,
		{"word":"recala","word_nosc":"recala","lemma":"recaler","pos":"VER"} ,
		{"word":"recalculant","word_nosc":"recalculant","lemma":"recalculer","pos":"VER"} ,
		{"word":"recalcule","word_nosc":"recalcule","lemma":"recalculer","pos":"VER"} ,
		{"word":"recalculer","word_nosc":"recalculer","lemma":"recalculer","pos":"VER"} ,
		{"word":"recalculez","word_nosc":"recalculez","lemma":"recalculer","pos":"VER"} ,
		{"word":"recalculé","word_nosc":"recalcule","lemma":"recalculer","pos":"VER"} ,
		{"word":"recaler","word_nosc":"recaler","lemma":"recaler","pos":"VER"} ,
		{"word":"recalez","word_nosc":"recalez","lemma":"recaler","pos":"VER"} ,
		{"word":"recalé","word_nosc":"recale","lemma":"recaler","pos":"VER"} ,
		{"word":"recalée","word_nosc":"recalee","lemma":"recaler","pos":"VER"} ,
		{"word":"recalés","word_nosc":"recales","lemma":"recaler","pos":"VER"} ,
		{"word":"recarreler","word_nosc":"recarreler","lemma":"recarreler","pos":"VER"} ,
		{"word":"recasa","word_nosc":"recasa","lemma":"recaser","pos":"VER"} ,
		{"word":"recaser","word_nosc":"recaser","lemma":"recaser","pos":"VER"} ,
		{"word":"recaserai","word_nosc":"recaserai","lemma":"recaser","pos":"VER"} ,
		{"word":"recasser","word_nosc":"recasser","lemma":"recasser","pos":"VER"} ,
		{"word":"recasses","word_nosc":"recasses","lemma":"recasser","pos":"VER"} ,
		{"word":"recassé","word_nosc":"recasse","lemma":"recasser","pos":"VER"} ,
		{"word":"recassée","word_nosc":"recassee","lemma":"recasser","pos":"VER"} ,
		{"word":"recasé","word_nosc":"recase","lemma":"recaser","pos":"VER"} ,
		{"word":"recelaient","word_nosc":"recelaient","lemma":"receler","pos":"VER"} ,
		{"word":"recelait","word_nosc":"recelait","lemma":"receler","pos":"VER"} ,
		{"word":"recelant","word_nosc":"recelant","lemma":"receler","pos":"VER"} ,
		{"word":"receler","word_nosc":"receler","lemma":"receler","pos":"VER"} ,
		{"word":"recelons","word_nosc":"recelons","lemma":"receler","pos":"VER"} ,
		{"word":"recelé","word_nosc":"recele","lemma":"receler","pos":"VER"} ,
		{"word":"recelée","word_nosc":"recelee","lemma":"receler","pos":"VER"} ,
		{"word":"recelées","word_nosc":"recelees","lemma":"receler","pos":"VER"} ,
		{"word":"recelés","word_nosc":"receles","lemma":"receler","pos":"VER"} ,
		{"word":"recensa","word_nosc":"recensa","lemma":"recenser","pos":"VER"} ,
		{"word":"recensais","word_nosc":"recensais","lemma":"recenser","pos":"VER"} ,
		{"word":"recensait","word_nosc":"recensait","lemma":"recenser","pos":"VER"} ,
		{"word":"recensant","word_nosc":"recensant","lemma":"recenser","pos":"VER"} ,
		{"word":"recense","word_nosc":"recense","lemma":"recenser","pos":"VER"} ,
		{"word":"recensent","word_nosc":"recensent","lemma":"recenser","pos":"VER"} ,
		{"word":"recenser","word_nosc":"recenser","lemma":"recenser","pos":"VER"} ,
		{"word":"recensâmes","word_nosc":"recensames","lemma":"recenser","pos":"VER"} ,
		{"word":"recensèrent","word_nosc":"recenserent","lemma":"recenser","pos":"VER"} ,
		{"word":"recensé","word_nosc":"recense","lemma":"recenser","pos":"VER"} ,
		{"word":"recensées","word_nosc":"recensees","lemma":"recenser","pos":"VER"} ,
		{"word":"recensés","word_nosc":"recenses","lemma":"recenser","pos":"VER"} ,
		{"word":"recentre","word_nosc":"recentre","lemma":"recentrer","pos":"VER"} ,
		{"word":"recentrer","word_nosc":"recentrer","lemma":"recentrer","pos":"VER"} ,
		{"word":"recentrons","word_nosc":"recentrons","lemma":"recentrer","pos":"VER"} ,
		{"word":"recentré","word_nosc":"recentre","lemma":"recentrer","pos":"VER"} ,
		{"word":"recentrée","word_nosc":"recentree","lemma":"recentrer","pos":"VER"} ,
		{"word":"recevaient","word_nosc":"recevaient","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevais","word_nosc":"recevais","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevait","word_nosc":"recevait","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevant","word_nosc":"recevant","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevez","word_nosc":"recevez","lemma":"recevoir","pos":"VER"} ,
		{"word":"receviez","word_nosc":"receviez","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevions","word_nosc":"recevions","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevoir","word_nosc":"recevoir","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevons","word_nosc":"recevons","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevra","word_nosc":"recevra","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevrai","word_nosc":"recevrai","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevraient","word_nosc":"recevraient","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevrais","word_nosc":"recevrais","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevrait","word_nosc":"recevrait","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevras","word_nosc":"recevras","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevrez","word_nosc":"recevrez","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevriez","word_nosc":"recevriez","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevrions","word_nosc":"recevrions","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevrons","word_nosc":"recevrons","lemma":"recevoir","pos":"VER"} ,
		{"word":"recevront","word_nosc":"recevront","lemma":"recevoir","pos":"VER"} ,
		{"word":"rechampis","word_nosc":"rechampis","lemma":"rechampir","pos":"VER"} ,
		{"word":"rechampit","word_nosc":"rechampit","lemma":"rechampir","pos":"VER"} ,
		{"word":"rechanger","word_nosc":"rechanger","lemma":"rechanger","pos":"VER"} ,
		{"word":"rechangé","word_nosc":"rechange","lemma":"rechanger","pos":"VER"} ,
		{"word":"rechanta","word_nosc":"rechanta","lemma":"rechanter","pos":"VER"} ,
		{"word":"rechanter","word_nosc":"rechanter","lemma":"rechanter","pos":"VER"} ,
		{"word":"rechanté","word_nosc":"rechante","lemma":"rechanter","pos":"VER"} ,
		{"word":"rechaper","word_nosc":"rechaper","lemma":"rechaper","pos":"VER"} ,
		{"word":"recharge","word_nosc":"recharge","lemma":"recharger","pos":"VER"} ,
		{"word":"rechargea","word_nosc":"rechargea","lemma":"recharger","pos":"VER"} ,
		{"word":"rechargeaient","word_nosc":"rechargeaient","lemma":"recharger","pos":"VER"} ,
		{"word":"rechargeais","word_nosc":"rechargeais","lemma":"recharger","pos":"VER"} ,
		{"word":"rechargeait","word_nosc":"rechargeait","lemma":"recharger","pos":"VER"} ,
		{"word":"rechargeant","word_nosc":"rechargeant","lemma":"recharger","pos":"VER"} ,
		{"word":"rechargent","word_nosc":"rechargent","lemma":"recharger","pos":"VER"} ,
		{"word":"recharger","word_nosc":"recharger","lemma":"recharger","pos":"VER"} ,
		{"word":"rechargerai","word_nosc":"rechargerai","lemma":"recharger","pos":"VER"} ,
		{"word":"rechargerait","word_nosc":"rechargerait","lemma":"recharger","pos":"VER"} ,
		{"word":"rechargerons","word_nosc":"rechargerons","lemma":"recharger","pos":"VER"} ,
		{"word":"recharges","word_nosc":"recharges","lemma":"recharger","pos":"VER"} ,
		{"word":"rechargez","word_nosc":"rechargez","lemma":"recharger","pos":"VER"} ,
		{"word":"rechargé","word_nosc":"recharge","lemma":"recharger","pos":"VER"} ,
		{"word":"rechargée","word_nosc":"rechargee","lemma":"recharger","pos":"VER"} ,
		{"word":"rechargées","word_nosc":"rechargees","lemma":"recharger","pos":"VER"} ,
		{"word":"rechargés","word_nosc":"recharges","lemma":"recharger","pos":"VER"} ,
		{"word":"rechasser","word_nosc":"rechasser","lemma":"rechasser","pos":"VER"} ,
		{"word":"rechaussaient","word_nosc":"rechaussaient","lemma":"rechausser","pos":"VER"} ,
		{"word":"rechaussant","word_nosc":"rechaussant","lemma":"rechausser","pos":"VER"} ,
		{"word":"rechausser","word_nosc":"rechausser","lemma":"rechausser","pos":"VER"} ,
		{"word":"rechausserait","word_nosc":"rechausserait","lemma":"rechausser","pos":"VER"} ,
		{"word":"rechaussée","word_nosc":"rechaussee","lemma":"rechausser","pos":"VER"} ,
		{"word":"rechercha","word_nosc":"rechercha","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherchai","word_nosc":"recherchai","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherchaient","word_nosc":"recherchaient","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherchais","word_nosc":"recherchais","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherchait","word_nosc":"recherchait","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherchant","word_nosc":"recherchant","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherche","word_nosc":"recherche","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherchent","word_nosc":"recherchent","lemma":"rechercher","pos":"VER"} ,
		{"word":"rechercher","word_nosc":"rechercher","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherchera","word_nosc":"recherchera","lemma":"rechercher","pos":"VER"} ,
		{"word":"rechercherai","word_nosc":"rechercherai","lemma":"rechercher","pos":"VER"} ,
		{"word":"rechercherais","word_nosc":"rechercherais","lemma":"rechercher","pos":"VER"} ,
		{"word":"rechercherait","word_nosc":"rechercherait","lemma":"rechercher","pos":"VER"} ,
		{"word":"rechercherions","word_nosc":"rechercherions","lemma":"rechercher","pos":"VER"} ,
		{"word":"rechercherons","word_nosc":"rechercherons","lemma":"rechercher","pos":"VER"} ,
		{"word":"rechercheront","word_nosc":"rechercheront","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherches","word_nosc":"recherches","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherchez","word_nosc":"recherchez","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherchiez","word_nosc":"recherchiez","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherchions","word_nosc":"recherchions","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherchons","word_nosc":"recherchons","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherché","word_nosc":"recherche","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherchée","word_nosc":"recherchee","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherchées","word_nosc":"recherchees","lemma":"rechercher","pos":"VER"} ,
		{"word":"recherchés","word_nosc":"recherches","lemma":"rechercher","pos":"VER"} ,
		{"word":"rechigna","word_nosc":"rechigna","lemma":"rechigner","pos":"VER"} ,
		{"word":"rechignaient","word_nosc":"rechignaient","lemma":"rechigner","pos":"VER"} ,
		{"word":"rechignais","word_nosc":"rechignais","lemma":"rechigner","pos":"VER"} ,
		{"word":"rechignait","word_nosc":"rechignait","lemma":"rechigner","pos":"VER"} ,
		{"word":"rechignant","word_nosc":"rechignant","lemma":"rechigner","pos":"VER"} ,
		{"word":"rechigne","word_nosc":"rechigne","lemma":"rechigner","pos":"VER"} ,
		{"word":"rechignent","word_nosc":"rechignent","lemma":"rechigner","pos":"VER"} ,
		{"word":"rechigner","word_nosc":"rechigner","lemma":"rechigner","pos":"VER"} ,
		{"word":"rechignerais","word_nosc":"rechignerais","lemma":"rechigner","pos":"VER"} ,
		{"word":"rechignes","word_nosc":"rechignes","lemma":"rechigner","pos":"VER"} ,
		{"word":"rechigné","word_nosc":"rechigne","lemma":"rechigner","pos":"VER"} ,
		{"word":"rechignée","word_nosc":"rechignee","lemma":"rechigner","pos":"VER"} ,
		{"word":"rechignées","word_nosc":"rechignees","lemma":"rechigner","pos":"VER"} ,
		{"word":"rechuta","word_nosc":"rechuta","lemma":"rechuter","pos":"VER"} ,
		{"word":"rechutant","word_nosc":"rechutant","lemma":"rechuter","pos":"VER"} ,
		{"word":"rechute","word_nosc":"rechute","lemma":"rechuter","pos":"VER"} ,
		{"word":"rechuter","word_nosc":"rechuter","lemma":"rechuter","pos":"VER"} ,
		{"word":"rechutera","word_nosc":"rechutera","lemma":"rechuter","pos":"VER"} ,
		{"word":"rechuterait","word_nosc":"rechuterait","lemma":"rechuter","pos":"VER"} ,
		{"word":"rechuteront","word_nosc":"rechuteront","lemma":"rechuter","pos":"VER"} ,
		{"word":"rechutes","word_nosc":"rechutes","lemma":"rechuter","pos":"VER"} ,
		{"word":"rechuté","word_nosc":"rechute","lemma":"rechuter","pos":"VER"} ,
		{"word":"reclasser","word_nosc":"reclasser","lemma":"reclasser","pos":"VER"} ,
		{"word":"reclassé","word_nosc":"reclasse","lemma":"reclasser","pos":"VER"} ,
		{"word":"reclassée","word_nosc":"reclassee","lemma":"reclasser","pos":"VER"} ,
		{"word":"reclouer","word_nosc":"reclouer","lemma":"reclouer","pos":"VER"} ,
		{"word":"reclure","word_nosc":"reclure","lemma":"reclure","pos":"VER"} ,
		{"word":"recogner","word_nosc":"recogner","lemma":"recogner","pos":"VER"} ,
		{"word":"recoiffa","word_nosc":"recoiffa","lemma":"recoiffer","pos":"VER"} ,
		{"word":"recoiffai","word_nosc":"recoiffai","lemma":"recoiffer","pos":"VER"} ,
		{"word":"recoiffaient","word_nosc":"recoiffaient","lemma":"recoiffer","pos":"VER"} ,
		{"word":"recoiffait","word_nosc":"recoiffait","lemma":"recoiffer","pos":"VER"} ,
		{"word":"recoiffant","word_nosc":"recoiffant","lemma":"recoiffer","pos":"VER"} ,
		{"word":"recoiffe","word_nosc":"recoiffe","lemma":"recoiffer","pos":"VER"} ,
		{"word":"recoiffent","word_nosc":"recoiffent","lemma":"recoiffer","pos":"VER"} ,
		{"word":"recoiffer","word_nosc":"recoiffer","lemma":"recoiffer","pos":"VER"} ,
		{"word":"recoiffez","word_nosc":"recoiffez","lemma":"recoiffer","pos":"VER"} ,
		{"word":"recoiffèrent","word_nosc":"recoifferent","lemma":"recoiffer","pos":"VER"} ,
		{"word":"recoiffé","word_nosc":"recoiffe","lemma":"recoiffer","pos":"VER"} ,
		{"word":"recoiffée","word_nosc":"recoiffee","lemma":"recoiffer","pos":"VER"} ,
		{"word":"recoiffés","word_nosc":"recoiffes","lemma":"recoiffer","pos":"VER"} ,
		{"word":"recolla","word_nosc":"recolla","lemma":"recoller","pos":"VER"} ,
		{"word":"recollait","word_nosc":"recollait","lemma":"recoller","pos":"VER"} ,
		{"word":"recolle","word_nosc":"recolle","lemma":"recoller","pos":"VER"} ,
		{"word":"recollent","word_nosc":"recollent","lemma":"recoller","pos":"VER"} ,
		{"word":"recoller","word_nosc":"recoller","lemma":"recoller","pos":"VER"} ,
		{"word":"recollera","word_nosc":"recollera","lemma":"recoller","pos":"VER"} ,
		{"word":"recollé","word_nosc":"recolle","lemma":"recoller","pos":"VER"} ,
		{"word":"recollée","word_nosc":"recollee","lemma":"recoller","pos":"VER"} ,
		{"word":"recollés","word_nosc":"recolles","lemma":"recoller","pos":"VER"} ,
		{"word":"recombinant","word_nosc":"recombinant","lemma":"recombiner","pos":"VER"} ,
		{"word":"recombiner","word_nosc":"recombiner","lemma":"recombiner","pos":"VER"} ,
		{"word":"recombiné","word_nosc":"recombine","lemma":"recombiner","pos":"VER"} ,
		{"word":"recommanda","word_nosc":"recommanda","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandai","word_nosc":"recommandai","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandaient","word_nosc":"recommandaient","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandais","word_nosc":"recommandais","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandait","word_nosc":"recommandait","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandant","word_nosc":"recommandant","lemma":"recommander","pos":"VER"} ,
		{"word":"recommande","word_nosc":"recommande","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandent","word_nosc":"recommandent","lemma":"recommander","pos":"VER"} ,
		{"word":"recommander","word_nosc":"recommander","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandera","word_nosc":"recommandera","lemma":"recommander","pos":"VER"} ,
		{"word":"recommanderai","word_nosc":"recommanderai","lemma":"recommander","pos":"VER"} ,
		{"word":"recommanderais","word_nosc":"recommanderais","lemma":"recommander","pos":"VER"} ,
		{"word":"recommanderait","word_nosc":"recommanderait","lemma":"recommander","pos":"VER"} ,
		{"word":"recommanderiez","word_nosc":"recommanderiez","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandes","word_nosc":"recommandes","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandez","word_nosc":"recommandez","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandiez","word_nosc":"recommandiez","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandons","word_nosc":"recommandons","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandât","word_nosc":"recommandat","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandèrent","word_nosc":"recommanderent","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandé","word_nosc":"recommande","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandée","word_nosc":"recommandee","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandées","word_nosc":"recommandees","lemma":"recommander","pos":"VER"} ,
		{"word":"recommandés","word_nosc":"recommandes","lemma":"recommander","pos":"VER"} ,
		{"word":"recommence","word_nosc":"recommence","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencent","word_nosc":"recommencent","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencer","word_nosc":"recommencer","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencera","word_nosc":"recommencera","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencerai","word_nosc":"recommencerai","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommenceraient","word_nosc":"recommenceraient","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencerais","word_nosc":"recommencerais","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencerait","word_nosc":"recommencerait","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommenceras","word_nosc":"recommenceras","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencerez","word_nosc":"recommencerez","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommenceriez","word_nosc":"recommenceriez","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencerions","word_nosc":"recommencerions","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencerons","word_nosc":"recommencerons","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommenceront","word_nosc":"recommenceront","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommences","word_nosc":"recommences","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencez","word_nosc":"recommencez","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommenciez","word_nosc":"recommenciez","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencions","word_nosc":"recommencions","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencèrent","word_nosc":"recommencerent","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencé","word_nosc":"recommence","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencée","word_nosc":"recommencee","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencées","word_nosc":"recommencees","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommencés","word_nosc":"recommences","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommença","word_nosc":"recommenca","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommençai","word_nosc":"recommencai","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommençaient","word_nosc":"recommencaient","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommençais","word_nosc":"recommencais","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommençait","word_nosc":"recommencait","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommençant","word_nosc":"recommencant","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommençons","word_nosc":"recommencons","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommençâmes","word_nosc":"recommencames","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommençât","word_nosc":"recommencat","lemma":"recommencer","pos":"VER"} ,
		{"word":"recommercer","word_nosc":"recommercer","lemma":"recommercer","pos":"VER"} ,
		{"word":"recompléter","word_nosc":"recompleter","lemma":"recompléter","pos":"VER"} ,
		{"word":"recomplété","word_nosc":"recomplete","lemma":"recompléter","pos":"VER"} ,
		{"word":"recomposa","word_nosc":"recomposa","lemma":"recomposer","pos":"VER"} ,
		{"word":"recomposaient","word_nosc":"recomposaient","lemma":"recomposer","pos":"VER"} ,
		{"word":"recomposais","word_nosc":"recomposais","lemma":"recomposer","pos":"VER"} ,
		{"word":"recomposait","word_nosc":"recomposait","lemma":"recomposer","pos":"VER"} ,
		{"word":"recompose","word_nosc":"recompose","lemma":"recomposer","pos":"VER"} ,
		{"word":"recomposent","word_nosc":"recomposent","lemma":"recomposer","pos":"VER"} ,
		{"word":"recomposer","word_nosc":"recomposer","lemma":"recomposer","pos":"VER"} ,
		{"word":"recomposera","word_nosc":"recomposera","lemma":"recomposer","pos":"VER"} ,
		{"word":"recomposèrent","word_nosc":"recomposerent","lemma":"recomposer","pos":"VER"} ,
		{"word":"recomposé","word_nosc":"recompose","lemma":"recomposer","pos":"VER"} ,
		{"word":"recomposée","word_nosc":"recomposee","lemma":"recomposer","pos":"VER"} ,
		{"word":"recomposés","word_nosc":"recomposes","lemma":"recomposer","pos":"VER"} ,
		{"word":"recompta","word_nosc":"recompta","lemma":"recompter","pos":"VER"} ,
		{"word":"recomptaient","word_nosc":"recomptaient","lemma":"recompter","pos":"VER"} ,
		{"word":"recomptais","word_nosc":"recomptais","lemma":"recompter","pos":"VER"} ,
		{"word":"recomptait","word_nosc":"recomptait","lemma":"recompter","pos":"VER"} ,
		{"word":"recomptant","word_nosc":"recomptant","lemma":"recompter","pos":"VER"} ,
		{"word":"recompte","word_nosc":"recompte","lemma":"recompter","pos":"VER"} ,
		{"word":"recompter","word_nosc":"recompter","lemma":"recompter","pos":"VER"} ,
		{"word":"recomptez","word_nosc":"recomptez","lemma":"recompter","pos":"VER"} ,
		{"word":"recomptons","word_nosc":"recomptons","lemma":"recompter","pos":"VER"} ,
		{"word":"recompté","word_nosc":"recompte","lemma":"recompter","pos":"VER"} ,
		{"word":"recomptées","word_nosc":"recomptees","lemma":"recompter","pos":"VER"} ,
		{"word":"reconditionne","word_nosc":"reconditionne","lemma":"reconditionner","pos":"VER"} ,
		{"word":"reconditionnons","word_nosc":"reconditionnons","lemma":"reconditionner","pos":"VER"} ,
		{"word":"reconditionné","word_nosc":"reconditionne","lemma":"reconditionner","pos":"VER"} ,
		{"word":"reconditionnée","word_nosc":"reconditionnee","lemma":"reconditionner","pos":"VER"} ,
		{"word":"reconduirai","word_nosc":"reconduirai","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduirais","word_nosc":"reconduirais","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduirait","word_nosc":"reconduirait","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduire","word_nosc":"reconduire","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduirez","word_nosc":"reconduirez","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduirons","word_nosc":"reconduirons","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduis","word_nosc":"reconduis","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduisaient","word_nosc":"reconduisaient","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduisais","word_nosc":"reconduisais","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduisait","word_nosc":"reconduisait","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduisant","word_nosc":"reconduisant","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduise","word_nosc":"reconduise","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduisent","word_nosc":"reconduisent","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduisez","word_nosc":"reconduisez","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduisions","word_nosc":"reconduisions","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduisirent","word_nosc":"reconduisirent","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduisit","word_nosc":"reconduisit","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduisît","word_nosc":"reconduisit","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduit","word_nosc":"reconduit","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduite","word_nosc":"reconduite","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconduits","word_nosc":"reconduits","lemma":"reconduire","pos":"VER"} ,
		{"word":"reconfigure","word_nosc":"reconfigure","lemma":"reconfigurer","pos":"VER"} ,
		{"word":"reconfigurer","word_nosc":"reconfigurer","lemma":"reconfigurer","pos":"VER"} ,
		{"word":"reconfigurez","word_nosc":"reconfigurez","lemma":"reconfigurer","pos":"VER"} ,
		{"word":"reconfiguré","word_nosc":"reconfigure","lemma":"reconfigurer","pos":"VER"} ,
		{"word":"reconfigurés","word_nosc":"reconfigures","lemma":"reconfigurer","pos":"VER"} ,
		{"word":"recongeler","word_nosc":"recongeler","lemma":"recongeler","pos":"VER"} ,
		{"word":"reconnais","word_nosc":"reconnais","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaissaient","word_nosc":"reconnaissaient","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaissais","word_nosc":"reconnaissais","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaissait","word_nosc":"reconnaissait","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaissant","word_nosc":"reconnaissant","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaisse","word_nosc":"reconnaisse","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaissent","word_nosc":"reconnaissent","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaisses","word_nosc":"reconnaisses","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaissez","word_nosc":"reconnaissez","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaissiez","word_nosc":"reconnaissiez","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaissions","word_nosc":"reconnaissions","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaissons","word_nosc":"reconnaissons","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaît","word_nosc":"reconnait","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaîtra","word_nosc":"reconnaitra","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaîtrai","word_nosc":"reconnaitrai","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaîtraient","word_nosc":"reconnaitraient","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaîtrais","word_nosc":"reconnaitrais","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaîtrait","word_nosc":"reconnaitrait","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaîtras","word_nosc":"reconnaitras","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaître","word_nosc":"reconnaitre","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaîtrez","word_nosc":"reconnaitrez","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaîtriez","word_nosc":"reconnaitriez","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaîtrions","word_nosc":"reconnaitrions","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaîtrons","word_nosc":"reconnaitrons","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnaîtront","word_nosc":"reconnaitront","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnecte","word_nosc":"reconnecte","lemma":"reconnecter","pos":"VER"} ,
		{"word":"reconnecter","word_nosc":"reconnecter","lemma":"reconnecter","pos":"VER"} ,
		{"word":"reconnectez","word_nosc":"reconnectez","lemma":"reconnecter","pos":"VER"} ,
		{"word":"reconnecté","word_nosc":"reconnecte","lemma":"reconnecter","pos":"VER"} ,
		{"word":"reconnectés","word_nosc":"reconnectes","lemma":"reconnecter","pos":"VER"} ,
		{"word":"reconnu","word_nosc":"reconnu","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnue","word_nosc":"reconnue","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnues","word_nosc":"reconnues","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnurent","word_nosc":"reconnurent","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnus","word_nosc":"reconnus","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnusse","word_nosc":"reconnusse","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnussent","word_nosc":"reconnussent","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnut","word_nosc":"reconnut","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnûmes","word_nosc":"reconnumes","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconnût","word_nosc":"reconnut","lemma":"reconnaître","pos":"VER"} ,
		{"word":"reconquerra","word_nosc":"reconquerra","lemma":"reconquérir","pos":"VER"} ,
		{"word":"reconquerrait","word_nosc":"reconquerrait","lemma":"reconquérir","pos":"VER"} ,
		{"word":"reconquerrons","word_nosc":"reconquerrons","lemma":"reconquérir","pos":"VER"} ,
		{"word":"reconquiert","word_nosc":"reconquiert","lemma":"reconquérir","pos":"VER"} ,
		{"word":"reconquirent","word_nosc":"reconquirent","lemma":"reconquérir","pos":"VER"} ,
		{"word":"reconquis","word_nosc":"reconquis","lemma":"reconquérir","pos":"VER"} ,
		{"word":"reconquise","word_nosc":"reconquise","lemma":"reconquérir","pos":"VER"} ,
		{"word":"reconquises","word_nosc":"reconquises","lemma":"reconquérir","pos":"VER"} ,
		{"word":"reconquit","word_nosc":"reconquit","lemma":"reconquérir","pos":"VER"} ,
		{"word":"reconquière","word_nosc":"reconquiere","lemma":"reconquérir","pos":"VER"} ,
		{"word":"reconquérais","word_nosc":"reconquerais","lemma":"reconquérir","pos":"VER"} ,
		{"word":"reconquérait","word_nosc":"reconquerait","lemma":"reconquérir","pos":"VER"} ,
		{"word":"reconquérir","word_nosc":"reconquerir","lemma":"reconquérir","pos":"VER"} ,
		{"word":"reconquérons","word_nosc":"reconquerons","lemma":"reconquérir","pos":"VER"} ,
		{"word":"reconsidère","word_nosc":"reconsidere","lemma":"reconsidérer","pos":"VER"} ,
		{"word":"reconsidèrent","word_nosc":"reconsiderent","lemma":"reconsidérer","pos":"VER"} ,
		{"word":"reconsidéra","word_nosc":"reconsidera","lemma":"reconsidérer","pos":"VER"} ,
		{"word":"reconsidérant","word_nosc":"reconsiderant","lemma":"reconsidérer","pos":"VER"} ,
		{"word":"reconsidérer","word_nosc":"reconsiderer","lemma":"reconsidérer","pos":"VER"} ,
		{"word":"reconsidérez","word_nosc":"reconsiderez","lemma":"reconsidérer","pos":"VER"} ,
		{"word":"reconsidéré","word_nosc":"reconsidere","lemma":"reconsidérer","pos":"VER"} ,
		{"word":"reconsidérée","word_nosc":"reconsideree","lemma":"reconsidérer","pos":"VER"} ,
		{"word":"reconstitua","word_nosc":"reconstitua","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstituaient","word_nosc":"reconstituaient","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstituais","word_nosc":"reconstituais","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstituait","word_nosc":"reconstituait","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstituant","word_nosc":"reconstituant","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstitue","word_nosc":"reconstitue","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstituent","word_nosc":"reconstituent","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstituer","word_nosc":"reconstituer","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstitueraient","word_nosc":"reconstitueraient","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstituerais","word_nosc":"reconstituerais","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstituerait","word_nosc":"reconstituerait","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstituerons","word_nosc":"reconstituerons","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstitues","word_nosc":"reconstitues","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstituons","word_nosc":"reconstituons","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstituâmes","word_nosc":"reconstituames","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstituât","word_nosc":"reconstituat","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstitué","word_nosc":"reconstitue","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstituée","word_nosc":"reconstituee","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstituées","word_nosc":"reconstituees","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstitués","word_nosc":"reconstitues","lemma":"reconstituer","pos":"VER"} ,
		{"word":"reconstruira","word_nosc":"reconstruira","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruirai","word_nosc":"reconstruirai","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruirais","word_nosc":"reconstruirais","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruirait","word_nosc":"reconstruirait","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruiras","word_nosc":"reconstruiras","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruire","word_nosc":"reconstruire","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruirons","word_nosc":"reconstruirons","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruiront","word_nosc":"reconstruiront","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruis","word_nosc":"reconstruis","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruisaient","word_nosc":"reconstruisaient","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruisait","word_nosc":"reconstruisait","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruisant","word_nosc":"reconstruisant","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruisent","word_nosc":"reconstruisent","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruisez","word_nosc":"reconstruisez","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruisirent","word_nosc":"reconstruisirent","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruisons","word_nosc":"reconstruisons","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruit","word_nosc":"reconstruit","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruite","word_nosc":"reconstruite","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruites","word_nosc":"reconstruites","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconstruits","word_nosc":"reconstruits","lemma":"reconstruire","pos":"VER"} ,
		{"word":"reconsulter","word_nosc":"reconsulter","lemma":"reconsulter","pos":"VER"} ,
		{"word":"recontacter","word_nosc":"recontacter","lemma":"recontacter","pos":"VER"} ,
		{"word":"reconter","word_nosc":"reconter","lemma":"reconter","pos":"VER"} ,
		{"word":"recontrer","word_nosc":"recontrer","lemma":"recontrer","pos":"VER"} ,
		{"word":"reconverti","word_nosc":"reconverti","lemma":"reconvertir","pos":"VER"} ,
		{"word":"reconvertie","word_nosc":"reconvertie","lemma":"reconvertir","pos":"VER"} ,
		{"word":"reconvertir","word_nosc":"reconvertir","lemma":"reconvertir","pos":"VER"} ,
		{"word":"reconvertirent","word_nosc":"reconvertirent","lemma":"reconvertir","pos":"VER"} ,
		{"word":"reconvertis","word_nosc":"reconvertis","lemma":"reconvertir","pos":"VER"} ,
		{"word":"reconvertissant","word_nosc":"reconvertissant","lemma":"reconvertir","pos":"VER"} ,
		{"word":"reconvertisse","word_nosc":"reconvertisse","lemma":"reconvertir","pos":"VER"} ,
		{"word":"reconvertit","word_nosc":"reconvertit","lemma":"reconvertir","pos":"VER"} ,
		{"word":"reconvoquer","word_nosc":"reconvoquer","lemma":"reconvoquer","pos":"VER"} ,
		{"word":"recopia","word_nosc":"recopia","lemma":"recopier","pos":"VER"} ,
		{"word":"recopiai","word_nosc":"recopiai","lemma":"recopier","pos":"VER"} ,
		{"word":"recopiaient","word_nosc":"recopiaient","lemma":"recopier","pos":"VER"} ,
		{"word":"recopiais","word_nosc":"recopiais","lemma":"recopier","pos":"VER"} ,
		{"word":"recopiait","word_nosc":"recopiait","lemma":"recopier","pos":"VER"} ,
		{"word":"recopiant","word_nosc":"recopiant","lemma":"recopier","pos":"VER"} ,
		{"word":"recopie","word_nosc":"recopie","lemma":"recopier","pos":"VER"} ,
		{"word":"recopient","word_nosc":"recopient","lemma":"recopier","pos":"VER"} ,
		{"word":"recopier","word_nosc":"recopier","lemma":"recopier","pos":"VER"} ,
		{"word":"recopierai","word_nosc":"recopierai","lemma":"recopier","pos":"VER"} ,
		{"word":"recopierait","word_nosc":"recopierait","lemma":"recopier","pos":"VER"} ,
		{"word":"recopies","word_nosc":"recopies","lemma":"recopier","pos":"VER"} ,
		{"word":"recopiez","word_nosc":"recopiez","lemma":"recopier","pos":"VER"} ,
		{"word":"recopiât","word_nosc":"recopiat","lemma":"recopier","pos":"VER"} ,
		{"word":"recopié","word_nosc":"recopie","lemma":"recopier","pos":"VER"} ,
		{"word":"recopiée","word_nosc":"recopiee","lemma":"recopier","pos":"VER"} ,
		{"word":"recopiées","word_nosc":"recopiees","lemma":"recopier","pos":"VER"} ,
		{"word":"recopiés","word_nosc":"recopies","lemma":"recopier","pos":"VER"} ,
		{"word":"recorder","word_nosc":"recorder","lemma":"recorder","pos":"VER"} ,
		{"word":"recoucha","word_nosc":"recoucha","lemma":"recoucher","pos":"VER"} ,
		{"word":"recouchai","word_nosc":"recouchai","lemma":"recoucher","pos":"VER"} ,
		{"word":"recouchais","word_nosc":"recouchais","lemma":"recoucher","pos":"VER"} ,
		{"word":"recouchait","word_nosc":"recouchait","lemma":"recoucher","pos":"VER"} ,
		{"word":"recouchant","word_nosc":"recouchant","lemma":"recoucher","pos":"VER"} ,
		{"word":"recouche","word_nosc":"recouche","lemma":"recoucher","pos":"VER"} ,
		{"word":"recoucher","word_nosc":"recoucher","lemma":"recoucher","pos":"VER"} ,
		{"word":"recoucherais","word_nosc":"recoucherais","lemma":"recoucher","pos":"VER"} ,
		{"word":"recoucheriez","word_nosc":"recoucheriez","lemma":"recoucher","pos":"VER"} ,
		{"word":"recouchez","word_nosc":"recouchez","lemma":"recoucher","pos":"VER"} ,
		{"word":"recouchèrent","word_nosc":"recoucherent","lemma":"recoucher","pos":"VER"} ,
		{"word":"recouché","word_nosc":"recouche","lemma":"recoucher","pos":"VER"} ,
		{"word":"recouchée","word_nosc":"recouchee","lemma":"recoucher","pos":"VER"} ,
		{"word":"recouchées","word_nosc":"recouchees","lemma":"recoucher","pos":"VER"} ,
		{"word":"recouchés","word_nosc":"recouches","lemma":"recoucher","pos":"VER"} ,
		{"word":"recoud","word_nosc":"recoud","lemma":"recoudre","pos":"VER"} ,
		{"word":"recoudra","word_nosc":"recoudra","lemma":"recoudre","pos":"VER"} ,
		{"word":"recoudrai","word_nosc":"recoudrai","lemma":"recoudre","pos":"VER"} ,
		{"word":"recoudras","word_nosc":"recoudras","lemma":"recoudre","pos":"VER"} ,
		{"word":"recoudre","word_nosc":"recoudre","lemma":"recoudre","pos":"VER"} ,
		{"word":"recouds","word_nosc":"recouds","lemma":"recoudre","pos":"VER"} ,
		{"word":"recouler","word_nosc":"recouler","lemma":"recouler","pos":"VER"} ,
		{"word":"recoupa","word_nosc":"recoupa","lemma":"recouper","pos":"VER"} ,
		{"word":"recoupaient","word_nosc":"recoupaient","lemma":"recouper","pos":"VER"} ,
		{"word":"recoupait","word_nosc":"recoupait","lemma":"recouper","pos":"VER"} ,
		{"word":"recoupant","word_nosc":"recoupant","lemma":"recouper","pos":"VER"} ,
		{"word":"recoupe","word_nosc":"recoupe","lemma":"recouper","pos":"VER"} ,
		{"word":"recoupent","word_nosc":"recoupent","lemma":"recouper","pos":"VER"} ,
		{"word":"recouper","word_nosc":"recouper","lemma":"recouper","pos":"VER"} ,
		{"word":"recouperait","word_nosc":"recouperait","lemma":"recouper","pos":"VER"} ,
		{"word":"recouperont","word_nosc":"recouperont","lemma":"recouper","pos":"VER"} ,
		{"word":"recoupez","word_nosc":"recoupez","lemma":"recouper","pos":"VER"} ,
		{"word":"recoupions","word_nosc":"recoupions","lemma":"recouper","pos":"VER"} ,
		{"word":"recoupé","word_nosc":"recoupe","lemma":"recouper","pos":"VER"} ,
		{"word":"recoupés","word_nosc":"recoupes","lemma":"recouper","pos":"VER"} ,
		{"word":"recourait","word_nosc":"recourait","lemma":"recourir","pos":"VER"} ,
		{"word":"recourant","word_nosc":"recourant","lemma":"recourir","pos":"VER"} ,
		{"word":"recourba","word_nosc":"recourba","lemma":"recourber","pos":"VER"} ,
		{"word":"recourbaient","word_nosc":"recourbaient","lemma":"recourber","pos":"VER"} ,
		{"word":"recourbait","word_nosc":"recourbait","lemma":"recourber","pos":"VER"} ,
		{"word":"recourbant","word_nosc":"recourbant","lemma":"recourber","pos":"VER"} ,
		{"word":"recourbe","word_nosc":"recourbe","lemma":"recourber","pos":"VER"} ,
		{"word":"recourber","word_nosc":"recourber","lemma":"recourber","pos":"VER"} ,
		{"word":"recourbé","word_nosc":"recourbe","lemma":"recourber","pos":"VER"} ,
		{"word":"recourbée","word_nosc":"recourbee","lemma":"recourber","pos":"VER"} ,
		{"word":"recourbées","word_nosc":"recourbees","lemma":"recourber","pos":"VER"} ,
		{"word":"recourbés","word_nosc":"recourbes","lemma":"recourber","pos":"VER"} ,
		{"word":"recoure","word_nosc":"recoure","lemma":"recourir","pos":"VER"} ,
		{"word":"recourent","word_nosc":"recourent","lemma":"recourir","pos":"VER"} ,
		{"word":"recourez","word_nosc":"recourez","lemma":"recourir","pos":"VER"} ,
		{"word":"recourir","word_nosc":"recourir","lemma":"recourir","pos":"VER"} ,
		{"word":"recourons","word_nosc":"recourons","lemma":"recourir","pos":"VER"} ,
		{"word":"recourrait","word_nosc":"recourrait","lemma":"recourir","pos":"VER"} ,
		{"word":"recours","word_nosc":"recours","lemma":"recourir","pos":"VER"} ,
		{"word":"recourt","word_nosc":"recourt","lemma":"recourir","pos":"VER"} ,
		{"word":"recouru","word_nosc":"recouru","lemma":"recourir","pos":"VER"} ,
		{"word":"recoururent","word_nosc":"recoururent","lemma":"recourir","pos":"VER"} ,
		{"word":"recourus","word_nosc":"recourus","lemma":"recourir","pos":"VER"} ,
		{"word":"recourut","word_nosc":"recourut","lemma":"recourir","pos":"VER"} ,
		{"word":"recousais","word_nosc":"recousais","lemma":"recoudre","pos":"VER"} ,
		{"word":"recousait","word_nosc":"recousait","lemma":"recoudre","pos":"VER"} ,
		{"word":"recousant","word_nosc":"recousant","lemma":"recoudre","pos":"VER"} ,
		{"word":"recouse","word_nosc":"recouse","lemma":"recoudre","pos":"VER"} ,
		{"word":"recousez","word_nosc":"recousez","lemma":"recoudre","pos":"VER"} ,
		{"word":"recousu","word_nosc":"recousu","lemma":"recoudre","pos":"VER"} ,
		{"word":"recousue","word_nosc":"recousue","lemma":"recoudre","pos":"VER"} ,
		{"word":"recousus","word_nosc":"recousus","lemma":"recoudre","pos":"VER"} ,
		{"word":"recouvert","word_nosc":"recouvert","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouverte","word_nosc":"recouverte","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvertes","word_nosc":"recouvertes","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouverts","word_nosc":"recouverts","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvra","word_nosc":"recouvra","lemma":"recouvrer","pos":"VER"} ,
		{"word":"recouvrai","word_nosc":"recouvrai","lemma":"recouvrer","pos":"VER"} ,
		{"word":"recouvraient","word_nosc":"recouvraient","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvrais","word_nosc":"recouvrais","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvrait","word_nosc":"recouvrait","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvrant","word_nosc":"recouvrant","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvre","word_nosc":"recouvre","lemma":"recouvrer","pos":"VER"} ,
		{"word":"recouvre","word_nosc":"recouvre","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvrent","word_nosc":"recouvrent","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvrer","word_nosc":"recouvrer","lemma":"recouvrer","pos":"VER"} ,
		{"word":"recouvrerai","word_nosc":"recouvrerai","lemma":"recouvrer","pos":"VER"} ,
		{"word":"recouvrerait","word_nosc":"recouvrerait","lemma":"recouvrer","pos":"VER"} ,
		{"word":"recouvrerez","word_nosc":"recouvrerez","lemma":"recouvrer","pos":"VER"} ,
		{"word":"recouvreront","word_nosc":"recouvreront","lemma":"recouvrer","pos":"VER"} ,
		{"word":"recouvrez","word_nosc":"recouvrez","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvriez","word_nosc":"recouvriez","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvrir","word_nosc":"recouvrir","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvrira","word_nosc":"recouvrira","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvrirai","word_nosc":"recouvrirai","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvriraient","word_nosc":"recouvriraient","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvrirait","word_nosc":"recouvrirait","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvrirent","word_nosc":"recouvrirent","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvris","word_nosc":"recouvris","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvrissent","word_nosc":"recouvrissent","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvrit","word_nosc":"recouvrit","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvrons","word_nosc":"recouvrons","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recouvrât","word_nosc":"recouvrat","lemma":"recouvrer","pos":"VER"} ,
		{"word":"recouvré","word_nosc":"recouvre","lemma":"recouvrer","pos":"VER"} ,
		{"word":"recouvrée","word_nosc":"recouvree","lemma":"recouvrer","pos":"VER"} ,
		{"word":"recouvrées","word_nosc":"recouvrees","lemma":"recouvrer","pos":"VER"} ,
		{"word":"recouvrés","word_nosc":"recouvres","lemma":"recouvrer","pos":"VER"} ,
		{"word":"recouvrît","word_nosc":"recouvrit","lemma":"recouvrir","pos":"VER"} ,
		{"word":"recracha","word_nosc":"recracha","lemma":"recracher","pos":"VER"} ,
		{"word":"recrachaient","word_nosc":"recrachaient","lemma":"recracher","pos":"VER"} ,
		{"word":"recrachais","word_nosc":"recrachais","lemma":"recracher","pos":"VER"} ,
		{"word":"recrachait","word_nosc":"recrachait","lemma":"recracher","pos":"VER"} ,
		{"word":"recrachant","word_nosc":"recrachant","lemma":"recracher","pos":"VER"} ,
		{"word":"recrache","word_nosc":"recrache","lemma":"recracher","pos":"VER"} ,
		{"word":"recrachent","word_nosc":"recrachent","lemma":"recracher","pos":"VER"} ,
		{"word":"recracher","word_nosc":"recracher","lemma":"recracher","pos":"VER"} ,
		{"word":"recrachera","word_nosc":"recrachera","lemma":"recracher","pos":"VER"} ,
		{"word":"recraches","word_nosc":"recraches","lemma":"recracher","pos":"VER"} ,
		{"word":"recrachez","word_nosc":"recrachez","lemma":"recracher","pos":"VER"} ,
		{"word":"recraché","word_nosc":"recrache","lemma":"recracher","pos":"VER"} ,
		{"word":"recrachée","word_nosc":"recrachee","lemma":"recracher","pos":"VER"} ,
		{"word":"recreuse","word_nosc":"recreuse","lemma":"recreuser","pos":"VER"} ,
		{"word":"recreuser","word_nosc":"recreuser","lemma":"recreuser","pos":"VER"} ,
		{"word":"recroisait","word_nosc":"recroisait","lemma":"recroiser","pos":"VER"} ,
		{"word":"recroisant","word_nosc":"recroisant","lemma":"recroiser","pos":"VER"} ,
		{"word":"recroise","word_nosc":"recroise","lemma":"recroiser","pos":"VER"} ,
		{"word":"recroiser","word_nosc":"recroiser","lemma":"recroiser","pos":"VER"} ,
		{"word":"recroiseras","word_nosc":"recroiseras","lemma":"recroiser","pos":"VER"} ,
		{"word":"recroiserons","word_nosc":"recroiserons","lemma":"recroiser","pos":"VER"} ,
		{"word":"recroisé","word_nosc":"recroise","lemma":"recroiser","pos":"VER"} ,
		{"word":"recroisés","word_nosc":"recroises","lemma":"recroiser","pos":"VER"} ,
		{"word":"recroquevilla","word_nosc":"recroquevilla","lemma":"recroqueviller","pos":"VER"} ,
		{"word":"recroquevillai","word_nosc":"recroquevillai","lemma":"recroqueviller","pos":"VER"} ,
		{"word":"recroquevillaient","word_nosc":"recroquevillaient","lemma":"recroqueviller","pos":"VER"} ,
		{"word":"recroquevillais","word_nosc":"recroquevillais","lemma":"recroqueviller","pos":"VER"} ,
		{"word":"recroquevillait","word_nosc":"recroquevillait","lemma":"recroqueviller","pos":"VER"} ,
		{"word":"recroquevillant","word_nosc":"recroquevillant","lemma":"recroqueviller","pos":"VER"} ,
		{"word":"recroqueville","word_nosc":"recroqueville","lemma":"recroqueviller","pos":"VER"} ,
		{"word":"recroquevillent","word_nosc":"recroquevillent","lemma":"recroqueviller","pos":"VER"} ,
		{"word":"recroqueviller","word_nosc":"recroqueviller","lemma":"recroqueviller","pos":"VER"} ,
		{"word":"recroquevillions","word_nosc":"recroquevillions","lemma":"recroqueviller","pos":"VER"} ,
		{"word":"recroquevillé","word_nosc":"recroqueville","lemma":"recroqueviller","pos":"VER"} ,
		{"word":"recroquevillée","word_nosc":"recroquevillee","lemma":"recroqueviller","pos":"VER"} ,
		{"word":"recroquevillées","word_nosc":"recroquevillees","lemma":"recroqueviller","pos":"VER"} ,
		{"word":"recroquevillés","word_nosc":"recroquevilles","lemma":"recroqueviller","pos":"VER"} ,
		{"word":"recru","word_nosc":"recru","lemma":"recroître","pos":"VER"} ,
		{"word":"recrus","word_nosc":"recrus","lemma":"recroître","pos":"VER"} ,
		{"word":"recruta","word_nosc":"recruta","lemma":"recruter","pos":"VER"} ,
		{"word":"recrutaient","word_nosc":"recrutaient","lemma":"recruter","pos":"VER"} ,
		{"word":"recrutais","word_nosc":"recrutais","lemma":"recruter","pos":"VER"} ,
		{"word":"recrutait","word_nosc":"recrutait","lemma":"recruter","pos":"VER"} ,
		{"word":"recrutant","word_nosc":"recrutant","lemma":"recruter","pos":"VER"} ,
		{"word":"recrute","word_nosc":"recrute","lemma":"recruter","pos":"VER"} ,
		{"word":"recrutent","word_nosc":"recrutent","lemma":"recruter","pos":"VER"} ,
		{"word":"recruter","word_nosc":"recruter","lemma":"recruter","pos":"VER"} ,
		{"word":"recrutera","word_nosc":"recrutera","lemma":"recruter","pos":"VER"} ,
		{"word":"recruterai","word_nosc":"recruterai","lemma":"recruter","pos":"VER"} ,
		{"word":"recruterez","word_nosc":"recruterez","lemma":"recruter","pos":"VER"} ,
		{"word":"recrutez","word_nosc":"recrutez","lemma":"recruter","pos":"VER"} ,
		{"word":"recrutiez","word_nosc":"recrutiez","lemma":"recruter","pos":"VER"} ,
		{"word":"recrutions","word_nosc":"recrutions","lemma":"recruter","pos":"VER"} ,
		{"word":"recrutons","word_nosc":"recrutons","lemma":"recruter","pos":"VER"} ,
		{"word":"recruté","word_nosc":"recrute","lemma":"recruter","pos":"VER"} ,
		{"word":"recrutée","word_nosc":"recrutee","lemma":"recruter","pos":"VER"} ,
		{"word":"recrutées","word_nosc":"recrutees","lemma":"recruter","pos":"VER"} ,
		{"word":"recrutés","word_nosc":"recrutes","lemma":"recruter","pos":"VER"} ,
		{"word":"recréa","word_nosc":"recrea","lemma":"recréer","pos":"VER"} ,
		{"word":"recréaient","word_nosc":"recreaient","lemma":"recréer","pos":"VER"} ,
		{"word":"recréais","word_nosc":"recreais","lemma":"recréer","pos":"VER"} ,
		{"word":"recréait","word_nosc":"recreait","lemma":"recréer","pos":"VER"} ,
		{"word":"recréant","word_nosc":"recreant","lemma":"recréer","pos":"VER"} ,
		{"word":"recrée","word_nosc":"recree","lemma":"recréer","pos":"VER"} ,
		{"word":"recréent","word_nosc":"recreent","lemma":"recréer","pos":"VER"} ,
		{"word":"recréer","word_nosc":"recreer","lemma":"recréer","pos":"VER"} ,
		{"word":"recréerait","word_nosc":"recreerait","lemma":"recréer","pos":"VER"} ,
		{"word":"recrépi","word_nosc":"recrepi","lemma":"recrépir","pos":"VER"} ,
		{"word":"recrépie","word_nosc":"recrepie","lemma":"recrépir","pos":"VER"} ,
		{"word":"recrépir","word_nosc":"recrepir","lemma":"recrépir","pos":"VER"} ,
		{"word":"recrépis","word_nosc":"recrepis","lemma":"recrépir","pos":"VER"} ,
		{"word":"recrépit","word_nosc":"recrepit","lemma":"recrépir","pos":"VER"} ,
		{"word":"recréé","word_nosc":"recree","lemma":"recréer","pos":"VER"} ,
		{"word":"recréée","word_nosc":"recreee","lemma":"recréer","pos":"VER"} ,
		{"word":"recréées","word_nosc":"recreees","lemma":"recréer","pos":"VER"} ,
		{"word":"recréés","word_nosc":"recrees","lemma":"recréer","pos":"VER"} ,
		{"word":"rectifia","word_nosc":"rectifia","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifiai","word_nosc":"rectifiai","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifiait","word_nosc":"rectifiait","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifiant","word_nosc":"rectifiant","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifie","word_nosc":"rectifie","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifient","word_nosc":"rectifient","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifier","word_nosc":"rectifier","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifiera","word_nosc":"rectifiera","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifierai","word_nosc":"rectifierai","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifierez","word_nosc":"rectifierez","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifies","word_nosc":"rectifies","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifiez","word_nosc":"rectifiez","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifions","word_nosc":"rectifions","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifièrent","word_nosc":"rectifierent","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifié","word_nosc":"rectifie","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifiée","word_nosc":"rectifiee","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifiées","word_nosc":"rectifiees","lemma":"rectifier","pos":"VER"} ,
		{"word":"rectifiés","word_nosc":"rectifies","lemma":"rectifier","pos":"VER"} ,
		{"word":"recueillaient","word_nosc":"recueillaient","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillais","word_nosc":"recueillais","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillait","word_nosc":"recueillait","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillant","word_nosc":"recueillant","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueille","word_nosc":"recueille","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillent","word_nosc":"recueillent","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillera","word_nosc":"recueillera","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillerai","word_nosc":"recueillerai","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueilleraient","word_nosc":"recueilleraient","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillerait","word_nosc":"recueillerait","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueilleront","word_nosc":"recueilleront","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillez","word_nosc":"recueillez","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueilli","word_nosc":"recueilli","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillie","word_nosc":"recueillie","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillies","word_nosc":"recueillies","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillions","word_nosc":"recueillions","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillir","word_nosc":"recueillir","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillirent","word_nosc":"recueillirent","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillis","word_nosc":"recueillis","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillit","word_nosc":"recueillit","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillons","word_nosc":"recueillons","lemma":"recueillir","pos":"VER"} ,
		{"word":"recueillît","word_nosc":"recueillit","lemma":"recueillir","pos":"VER"} ,
		{"word":"recuire","word_nosc":"recuire","lemma":"recuire","pos":"VER"} ,
		{"word":"recuit","word_nosc":"recuit","lemma":"recuire","pos":"VER"} ,
		{"word":"recuite","word_nosc":"recuite","lemma":"recuire","pos":"VER"} ,
		{"word":"recuites","word_nosc":"recuites","lemma":"recuire","pos":"VER"} ,
		{"word":"recula","word_nosc":"recula","lemma":"reculer","pos":"VER"} ,
		{"word":"reculai","word_nosc":"reculai","lemma":"reculer","pos":"VER"} ,
		{"word":"reculaient","word_nosc":"reculaient","lemma":"reculer","pos":"VER"} ,
		{"word":"reculais","word_nosc":"reculais","lemma":"reculer","pos":"VER"} ,
		{"word":"reculait","word_nosc":"reculait","lemma":"reculer","pos":"VER"} ,
		{"word":"reculant","word_nosc":"reculant","lemma":"reculer","pos":"VER"} ,
		{"word":"recule","word_nosc":"recule","lemma":"reculer","pos":"VER"} ,
		{"word":"reculent","word_nosc":"reculent","lemma":"reculer","pos":"VER"} ,
		{"word":"reculer","word_nosc":"reculer","lemma":"reculer","pos":"VER"} ,
		{"word":"reculera","word_nosc":"reculera","lemma":"reculer","pos":"VER"} ,
		{"word":"reculerai","word_nosc":"reculerai","lemma":"reculer","pos":"VER"} ,
		{"word":"reculeraient","word_nosc":"reculeraient","lemma":"reculer","pos":"VER"} ,
		{"word":"reculerais","word_nosc":"reculerais","lemma":"reculer","pos":"VER"} ,
		{"word":"reculerait","word_nosc":"reculerait","lemma":"reculer","pos":"VER"} ,
		{"word":"reculeras","word_nosc":"reculeras","lemma":"reculer","pos":"VER"} ,
		{"word":"reculerons","word_nosc":"reculerons","lemma":"reculer","pos":"VER"} ,
		{"word":"reculeront","word_nosc":"reculeront","lemma":"reculer","pos":"VER"} ,
		{"word":"recules","word_nosc":"recules","lemma":"reculer","pos":"VER"} ,
		{"word":"reculez","word_nosc":"reculez","lemma":"reculer","pos":"VER"} ,
		{"word":"reculons","word_nosc":"reculons","lemma":"reculer","pos":"VER"} ,
		{"word":"reculotta","word_nosc":"reculotta","lemma":"reculotter","pos":"VER"} ,
		{"word":"reculotte","word_nosc":"reculotte","lemma":"reculotter","pos":"VER"} ,
		{"word":"reculotter","word_nosc":"reculotter","lemma":"reculotter","pos":"VER"} ,
		{"word":"reculotté","word_nosc":"reculotte","lemma":"reculotter","pos":"VER"} ,
		{"word":"reculottée","word_nosc":"reculottee","lemma":"reculotter","pos":"VER"} ,
		{"word":"reculât","word_nosc":"reculat","lemma":"reculer","pos":"VER"} ,
		{"word":"reculèrent","word_nosc":"reculerent","lemma":"reculer","pos":"VER"} ,
		{"word":"reculé","word_nosc":"recule","lemma":"reculer","pos":"VER"} ,
		{"word":"reculée","word_nosc":"reculee","lemma":"reculer","pos":"VER"} ,
		{"word":"reculées","word_nosc":"reculees","lemma":"reculer","pos":"VER"} ,
		{"word":"reculés","word_nosc":"recules","lemma":"reculer","pos":"VER"} ,
		{"word":"recyclaient","word_nosc":"recyclaient","lemma":"recycler","pos":"VER"} ,
		{"word":"recyclait","word_nosc":"recyclait","lemma":"recycler","pos":"VER"} ,
		{"word":"recycle","word_nosc":"recycle","lemma":"recycler","pos":"VER"} ,
		{"word":"recyclent","word_nosc":"recyclent","lemma":"recycler","pos":"VER"} ,
		{"word":"recycler","word_nosc":"recycler","lemma":"recycler","pos":"VER"} ,
		{"word":"recyclerai","word_nosc":"recyclerai","lemma":"recycler","pos":"VER"} ,
		{"word":"recyclez","word_nosc":"recyclez","lemma":"recycler","pos":"VER"} ,
		{"word":"recycliez","word_nosc":"recycliez","lemma":"recycler","pos":"VER"} ,
		{"word":"recyclé","word_nosc":"recycle","lemma":"recycler","pos":"VER"} ,
		{"word":"recyclée","word_nosc":"recyclee","lemma":"recycler","pos":"VER"} ,
		{"word":"recyclées","word_nosc":"recyclees","lemma":"recycler","pos":"VER"} ,
		{"word":"recyclés","word_nosc":"recycles","lemma":"recycler","pos":"VER"} ,
		{"word":"recâbler","word_nosc":"recabler","lemma":"recâbler","pos":"VER"} ,
		{"word":"recèle","word_nosc":"recele","lemma":"receler","pos":"VER"} ,
		{"word":"recèlent","word_nosc":"recelent","lemma":"receler","pos":"VER"} ,
		{"word":"recèles","word_nosc":"receles","lemma":"receler","pos":"VER"} ,
		{"word":"recélait","word_nosc":"recelait","lemma":"recéler","pos":"VER"} ,
		{"word":"recélant","word_nosc":"recelant","lemma":"recéler","pos":"VER"} ,
		{"word":"recéler","word_nosc":"receler","lemma":"recéler","pos":"VER"} ,
		{"word":"redemanda","word_nosc":"redemanda","lemma":"redemander","pos":"VER"} ,
		{"word":"redemandai","word_nosc":"redemandai","lemma":"redemander","pos":"VER"} ,
		{"word":"redemandaient","word_nosc":"redemandaient","lemma":"redemander","pos":"VER"} ,
		{"word":"redemandais","word_nosc":"redemandais","lemma":"redemander","pos":"VER"} ,
		{"word":"redemandait","word_nosc":"redemandait","lemma":"redemander","pos":"VER"} ,
		{"word":"redemandant","word_nosc":"redemandant","lemma":"redemander","pos":"VER"} ,
		{"word":"redemande","word_nosc":"redemande","lemma":"redemander","pos":"VER"} ,
		{"word":"redemandent","word_nosc":"redemandent","lemma":"redemander","pos":"VER"} ,
		{"word":"redemander","word_nosc":"redemander","lemma":"redemander","pos":"VER"} ,
		{"word":"redemanderai","word_nosc":"redemanderai","lemma":"redemander","pos":"VER"} ,
		{"word":"redemanderait","word_nosc":"redemanderait","lemma":"redemander","pos":"VER"} ,
		{"word":"redemanderas","word_nosc":"redemanderas","lemma":"redemander","pos":"VER"} ,
		{"word":"redemanderez","word_nosc":"redemanderez","lemma":"redemander","pos":"VER"} ,
		{"word":"redemanderont","word_nosc":"redemanderont","lemma":"redemander","pos":"VER"} ,
		{"word":"redemandes","word_nosc":"redemandes","lemma":"redemander","pos":"VER"} ,
		{"word":"redemandez","word_nosc":"redemandez","lemma":"redemander","pos":"VER"} ,
		{"word":"redemandions","word_nosc":"redemandions","lemma":"redemander","pos":"VER"} ,
		{"word":"redemandé","word_nosc":"redemande","lemma":"redemander","pos":"VER"} ,
		{"word":"redescend","word_nosc":"redescend","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendaient","word_nosc":"redescendaient","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendais","word_nosc":"redescendais","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendait","word_nosc":"redescendait","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendant","word_nosc":"redescendant","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescende","word_nosc":"redescende","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendent","word_nosc":"redescendent","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendes","word_nosc":"redescendes","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendez","word_nosc":"redescendez","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendiez","word_nosc":"redescendiez","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendions","word_nosc":"redescendions","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendirent","word_nosc":"redescendirent","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendis","word_nosc":"redescendis","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendit","word_nosc":"redescendit","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendons","word_nosc":"redescendons","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendra","word_nosc":"redescendra","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendrai","word_nosc":"redescendrai","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendrais","word_nosc":"redescendrais","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendrait","word_nosc":"redescendrait","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendre","word_nosc":"redescendre","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendrons","word_nosc":"redescendrons","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendront","word_nosc":"redescendront","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescends","word_nosc":"redescends","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendu","word_nosc":"redescendu","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendue","word_nosc":"redescendue","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendues","word_nosc":"redescendues","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendus","word_nosc":"redescendus","lemma":"redescendre","pos":"VER"} ,
		{"word":"redescendît","word_nosc":"redescendit","lemma":"redescendre","pos":"VER"} ,
		{"word":"redessina","word_nosc":"redessina","lemma":"redessiner","pos":"VER"} ,
		{"word":"redessinaient","word_nosc":"redessinaient","lemma":"redessiner","pos":"VER"} ,
		{"word":"redessinait","word_nosc":"redessinait","lemma":"redessiner","pos":"VER"} ,
		{"word":"redessine","word_nosc":"redessine","lemma":"redessiner","pos":"VER"} ,
		{"word":"redessinent","word_nosc":"redessinent","lemma":"redessiner","pos":"VER"} ,
		{"word":"redessiner","word_nosc":"redessiner","lemma":"redessiner","pos":"VER"} ,
		{"word":"redessiné","word_nosc":"redessine","lemma":"redessiner","pos":"VER"} ,
		{"word":"redessinée","word_nosc":"redessinee","lemma":"redessiner","pos":"VER"} ,
		{"word":"redevais","word_nosc":"redevais","lemma":"redevoir","pos":"VER"} ,
		{"word":"redevait","word_nosc":"redevait","lemma":"redevoir","pos":"VER"} ,
		{"word":"redevenaient","word_nosc":"redevenaient","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevenais","word_nosc":"redevenais","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevenait","word_nosc":"redevenait","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevenant","word_nosc":"redevenant","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevenez","word_nosc":"redevenez","lemma":"redevenir","pos":"VER"} ,
		{"word":"redeveniez","word_nosc":"redeveniez","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevenir","word_nosc":"redevenir","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevenons","word_nosc":"redevenons","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevenu","word_nosc":"redevenu","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevenue","word_nosc":"redevenue","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevenues","word_nosc":"redevenues","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevenus","word_nosc":"redevenus","lemma":"redevenir","pos":"VER"} ,
		{"word":"redeviendra","word_nosc":"redeviendra","lemma":"redevenir","pos":"VER"} ,
		{"word":"redeviendrai","word_nosc":"redeviendrai","lemma":"redevenir","pos":"VER"} ,
		{"word":"redeviendraient","word_nosc":"redeviendraient","lemma":"redevenir","pos":"VER"} ,
		{"word":"redeviendrais","word_nosc":"redeviendrais","lemma":"redevenir","pos":"VER"} ,
		{"word":"redeviendrait","word_nosc":"redeviendrait","lemma":"redevenir","pos":"VER"} ,
		{"word":"redeviendras","word_nosc":"redeviendras","lemma":"redevenir","pos":"VER"} ,
		{"word":"redeviendrez","word_nosc":"redeviendrez","lemma":"redevenir","pos":"VER"} ,
		{"word":"redeviendriez","word_nosc":"redeviendriez","lemma":"redevenir","pos":"VER"} ,
		{"word":"redeviendrons","word_nosc":"redeviendrons","lemma":"redevenir","pos":"VER"} ,
		{"word":"redeviendront","word_nosc":"redeviendront","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevienne","word_nosc":"redevienne","lemma":"redevenir","pos":"VER"} ,
		{"word":"redeviennent","word_nosc":"redeviennent","lemma":"redevenir","pos":"VER"} ,
		{"word":"redeviennes","word_nosc":"redeviennes","lemma":"redevenir","pos":"VER"} ,
		{"word":"redeviens","word_nosc":"redeviens","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevient","word_nosc":"redevient","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevinrent","word_nosc":"redevinrent","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevins","word_nosc":"redevins","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevint","word_nosc":"redevint","lemma":"redevenir","pos":"VER"} ,
		{"word":"redevoir","word_nosc":"redevoir","lemma":"redevoir","pos":"VER"} ,
		{"word":"redevra","word_nosc":"redevra","lemma":"redevoir","pos":"VER"} ,
		{"word":"redevrez","word_nosc":"redevrez","lemma":"redevoir","pos":"VER"} ,
		{"word":"redevînt","word_nosc":"redevint","lemma":"redevenir","pos":"VER"} ,
		{"word":"rediffusent","word_nosc":"rediffusent","lemma":"rediffuser","pos":"VER"} ,
		{"word":"rediffuser","word_nosc":"rediffuser","lemma":"rediffuser","pos":"VER"} ,
		{"word":"rediffuserons","word_nosc":"rediffuserons","lemma":"rediffuser","pos":"VER"} ,
		{"word":"rediffusez","word_nosc":"rediffusez","lemma":"rediffuser","pos":"VER"} ,
		{"word":"rediffusé","word_nosc":"rediffuse","lemma":"rediffuser","pos":"VER"} ,
		{"word":"rediffusés","word_nosc":"rediffuses","lemma":"rediffuser","pos":"VER"} ,
		{"word":"redimensionner","word_nosc":"redimensionner","lemma":"redimensionner","pos":"VER"} ,
		{"word":"redimensionnées","word_nosc":"redimensionnees","lemma":"redimensionner","pos":"VER"} ,
		{"word":"redirai","word_nosc":"redirai","lemma":"redire","pos":"VER"} ,
		{"word":"redirais","word_nosc":"redirais","lemma":"redire","pos":"VER"} ,
		{"word":"redirait","word_nosc":"redirait","lemma":"redire","pos":"VER"} ,
		{"word":"redire","word_nosc":"redire","lemma":"redire","pos":"VER"} ,
		{"word":"redirez","word_nosc":"redirez","lemma":"redire","pos":"VER"} ,
		{"word":"rediriez","word_nosc":"rediriez","lemma":"redire","pos":"VER"} ,
		{"word":"redirige","word_nosc":"redirige","lemma":"rediriger","pos":"VER"} ,
		{"word":"rediriger","word_nosc":"rediriger","lemma":"rediriger","pos":"VER"} ,
		{"word":"redirigé","word_nosc":"redirige","lemma":"rediriger","pos":"VER"} ,
		{"word":"redis","word_nosc":"redis","lemma":"redire","pos":"VER"} ,
		{"word":"redisais","word_nosc":"redisais","lemma":"redire","pos":"VER"} ,
		{"word":"redisait","word_nosc":"redisait","lemma":"redire","pos":"VER"} ,
		{"word":"redisant","word_nosc":"redisant","lemma":"redire","pos":"VER"} ,
		{"word":"rediscuter","word_nosc":"rediscuter","lemma":"rediscuter","pos":"VER"} ,
		{"word":"rediscutera","word_nosc":"rediscutera","lemma":"rediscuter","pos":"VER"} ,
		{"word":"rediscuterons","word_nosc":"rediscuterons","lemma":"rediscuter","pos":"VER"} ,
		{"word":"redise","word_nosc":"redise","lemma":"redire","pos":"VER"} ,
		{"word":"redisent","word_nosc":"redisent","lemma":"redire","pos":"VER"} ,
		{"word":"redises","word_nosc":"redises","lemma":"redire","pos":"VER"} ,
		{"word":"redisposer","word_nosc":"redisposer","lemma":"redisposer","pos":"VER"} ,
		{"word":"redistribua","word_nosc":"redistribua","lemma":"redistribuer","pos":"VER"} ,
		{"word":"redistribuais","word_nosc":"redistribuais","lemma":"redistribuer","pos":"VER"} ,
		{"word":"redistribuait","word_nosc":"redistribuait","lemma":"redistribuer","pos":"VER"} ,
		{"word":"redistribue","word_nosc":"redistribue","lemma":"redistribuer","pos":"VER"} ,
		{"word":"redistribuent","word_nosc":"redistribuent","lemma":"redistribuer","pos":"VER"} ,
		{"word":"redistribuer","word_nosc":"redistribuer","lemma":"redistribuer","pos":"VER"} ,
		{"word":"redistribueras","word_nosc":"redistribueras","lemma":"redistribuer","pos":"VER"} ,
		{"word":"redistribuons","word_nosc":"redistribuons","lemma":"redistribuer","pos":"VER"} ,
		{"word":"redistribué","word_nosc":"redistribue","lemma":"redistribuer","pos":"VER"} ,
		{"word":"redistribués","word_nosc":"redistribues","lemma":"redistribuer","pos":"VER"} ,
		{"word":"redit","word_nosc":"redit","lemma":"redire","pos":"VER"} ,
		{"word":"redite","word_nosc":"redite","lemma":"redire","pos":"VER"} ,
		{"word":"redites","word_nosc":"redites","lemma":"redire","pos":"VER"} ,
		{"word":"redits","word_nosc":"redits","lemma":"redire","pos":"VER"} ,
		{"word":"redonna","word_nosc":"redonna","lemma":"redonner","pos":"VER"} ,
		{"word":"redonnai","word_nosc":"redonnai","lemma":"redonner","pos":"VER"} ,
		{"word":"redonnaient","word_nosc":"redonnaient","lemma":"redonner","pos":"VER"} ,
		{"word":"redonnais","word_nosc":"redonnais","lemma":"redonner","pos":"VER"} ,
		{"word":"redonnait","word_nosc":"redonnait","lemma":"redonner","pos":"VER"} ,
		{"word":"redonnant","word_nosc":"redonnant","lemma":"redonner","pos":"VER"} ,
		{"word":"redonne","word_nosc":"redonne","lemma":"redonner","pos":"VER"} ,
		{"word":"redonnent","word_nosc":"redonnent","lemma":"redonner","pos":"VER"} ,
		{"word":"redonner","word_nosc":"redonner","lemma":"redonner","pos":"VER"} ,
		{"word":"redonnera","word_nosc":"redonnera","lemma":"redonner","pos":"VER"} ,
		{"word":"redonnerai","word_nosc":"redonnerai","lemma":"redonner","pos":"VER"} ,
		{"word":"redonneraient","word_nosc":"redonneraient","lemma":"redonner","pos":"VER"} ,
		{"word":"redonnerais","word_nosc":"redonnerais","lemma":"redonner","pos":"VER"} ,
		{"word":"redonnerait","word_nosc":"redonnerait","lemma":"redonner","pos":"VER"} ,
		{"word":"redonneras","word_nosc":"redonneras","lemma":"redonner","pos":"VER"} ,
		{"word":"redonnerons","word_nosc":"redonnerons","lemma":"redonner","pos":"VER"} ,
		{"word":"redonneront","word_nosc":"redonneront","lemma":"redonner","pos":"VER"} ,
		{"word":"redonnes","word_nosc":"redonnes","lemma":"redonner","pos":"VER"} ,
		{"word":"redonnez","word_nosc":"redonnez","lemma":"redonner","pos":"VER"} ,
		{"word":"redonniez","word_nosc":"redonniez","lemma":"redonner","pos":"VER"} ,
		{"word":"redonnons","word_nosc":"redonnons","lemma":"redonner","pos":"VER"} ,
		{"word":"redonnât","word_nosc":"redonnat","lemma":"redonner","pos":"VER"} ,
		{"word":"redonné","word_nosc":"redonne","lemma":"redonner","pos":"VER"} ,
		{"word":"redorer","word_nosc":"redorer","lemma":"redorer","pos":"VER"} ,
		{"word":"redormir","word_nosc":"redormir","lemma":"redormir","pos":"VER"} ,
		{"word":"redors","word_nosc":"redors","lemma":"redormir","pos":"VER"} ,
		{"word":"redort","word_nosc":"redort","lemma":"redormir","pos":"VER"} ,
		{"word":"redoré","word_nosc":"redore","lemma":"redorer","pos":"VER"} ,
		{"word":"redorées","word_nosc":"redorees","lemma":"redorer","pos":"VER"} ,
		{"word":"redoubla","word_nosc":"redoubla","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublai","word_nosc":"redoublai","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublaient","word_nosc":"redoublaient","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublais","word_nosc":"redoublais","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublait","word_nosc":"redoublait","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublant","word_nosc":"redoublant","lemma":"redoubler","pos":"VER"} ,
		{"word":"redouble","word_nosc":"redouble","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublent","word_nosc":"redoublent","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoubler","word_nosc":"redoubler","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublera","word_nosc":"redoublera","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublerai","word_nosc":"redoublerai","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublerait","word_nosc":"redoublerait","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoubleront","word_nosc":"redoubleront","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublez","word_nosc":"redoublez","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublions","word_nosc":"redoublions","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublons","word_nosc":"redoublons","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublèrent","word_nosc":"redoublerent","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublé","word_nosc":"redouble","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublée","word_nosc":"redoublee","lemma":"redoubler","pos":"VER"} ,
		{"word":"redoublés","word_nosc":"redoubles","lemma":"redoubler","pos":"VER"} ,
		{"word":"redouta","word_nosc":"redouta","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutaient","word_nosc":"redoutaient","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutais","word_nosc":"redoutais","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutait","word_nosc":"redoutait","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutant","word_nosc":"redoutant","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutasse","word_nosc":"redoutasse","lemma":"redouter","pos":"VER"} ,
		{"word":"redoute","word_nosc":"redoute","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutent","word_nosc":"redoutent","lemma":"redouter","pos":"VER"} ,
		{"word":"redouter","word_nosc":"redouter","lemma":"redouter","pos":"VER"} ,
		{"word":"redouterait","word_nosc":"redouterait","lemma":"redouter","pos":"VER"} ,
		{"word":"redouteras","word_nosc":"redouteras","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutes","word_nosc":"redoutes","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutez","word_nosc":"redoutez","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutiez","word_nosc":"redoutiez","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutions","word_nosc":"redoutions","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutons","word_nosc":"redoutons","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutât","word_nosc":"redoutat","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutèrent","word_nosc":"redouterent","lemma":"redouter","pos":"VER"} ,
		{"word":"redouté","word_nosc":"redoute","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutée","word_nosc":"redoutee","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutées","word_nosc":"redoutees","lemma":"redouter","pos":"VER"} ,
		{"word":"redoutés","word_nosc":"redoutes","lemma":"redouter","pos":"VER"} ,
		{"word":"redressa","word_nosc":"redressa","lemma":"redresser","pos":"VER"} ,
		{"word":"redressai","word_nosc":"redressai","lemma":"redresser","pos":"VER"} ,
		{"word":"redressaient","word_nosc":"redressaient","lemma":"redresser","pos":"VER"} ,
		{"word":"redressais","word_nosc":"redressais","lemma":"redresser","pos":"VER"} ,
		{"word":"redressait","word_nosc":"redressait","lemma":"redresser","pos":"VER"} ,
		{"word":"redressant","word_nosc":"redressant","lemma":"redresser","pos":"VER"} ,
		{"word":"redresse","word_nosc":"redresse","lemma":"redresser","pos":"VER"} ,
		{"word":"redressent","word_nosc":"redressent","lemma":"redresser","pos":"VER"} ,
		{"word":"redresser","word_nosc":"redresser","lemma":"redresser","pos":"VER"} ,
		{"word":"redressera","word_nosc":"redressera","lemma":"redresser","pos":"VER"} ,
		{"word":"redresserai","word_nosc":"redresserai","lemma":"redresser","pos":"VER"} ,
		{"word":"redresserait","word_nosc":"redresserait","lemma":"redresser","pos":"VER"} ,
		{"word":"redresserons","word_nosc":"redresserons","lemma":"redresser","pos":"VER"} ,
		{"word":"redresses","word_nosc":"redresses","lemma":"redresser","pos":"VER"} ,
		{"word":"redressez","word_nosc":"redressez","lemma":"redresser","pos":"VER"} ,
		{"word":"redressons","word_nosc":"redressons","lemma":"redresser","pos":"VER"} ,
		{"word":"redressât","word_nosc":"redressat","lemma":"redresser","pos":"VER"} ,
		{"word":"redressèrent","word_nosc":"redresserent","lemma":"redresser","pos":"VER"} ,
		{"word":"redressé","word_nosc":"redresse","lemma":"redresser","pos":"VER"} ,
		{"word":"redressée","word_nosc":"redressee","lemma":"redresser","pos":"VER"} ,
		{"word":"redressées","word_nosc":"redressees","lemma":"redresser","pos":"VER"} ,
		{"word":"redressés","word_nosc":"redresses","lemma":"redresser","pos":"VER"} ,
		{"word":"redéconner","word_nosc":"redeconner","lemma":"redéconner","pos":"VER"} ,
		{"word":"redécouvert","word_nosc":"redecouvert","lemma":"redécouvrir","pos":"VER"} ,
		{"word":"redécouvertes","word_nosc":"redecouvertes","lemma":"redécouvrir","pos":"VER"} ,
		{"word":"redécouvraient","word_nosc":"redecouvraient","lemma":"redécouvrir","pos":"VER"} ,
		{"word":"redécouvrait","word_nosc":"redecouvrait","lemma":"redécouvrir","pos":"VER"} ,
		{"word":"redécouvrant","word_nosc":"redecouvrant","lemma":"redécouvrir","pos":"VER"} ,
		{"word":"redécouvre","word_nosc":"redecouvre","lemma":"redécouvrir","pos":"VER"} ,
		{"word":"redécouvrent","word_nosc":"redecouvrent","lemma":"redécouvrir","pos":"VER"} ,
		{"word":"redécouvrions","word_nosc":"redecouvrions","lemma":"redécouvrir","pos":"VER"} ,
		{"word":"redécouvrir","word_nosc":"redecouvrir","lemma":"redécouvrir","pos":"VER"} ,
		{"word":"redécouvrira","word_nosc":"redecouvrira","lemma":"redécouvrir","pos":"VER"} ,
		{"word":"redécouvriront","word_nosc":"redecouvriront","lemma":"redécouvrir","pos":"VER"} ,
		{"word":"redécouvris","word_nosc":"redecouvris","lemma":"redécouvrir","pos":"VER"} ,
		{"word":"redécouvrit","word_nosc":"redecouvrit","lemma":"redécouvrir","pos":"VER"} ,
		{"word":"redécouvrons","word_nosc":"redecouvrons","lemma":"redécouvrir","pos":"VER"} ,
		{"word":"redécouvrît","word_nosc":"redecouvrit","lemma":"redécouvrir","pos":"VER"} ,
		{"word":"redéfini","word_nosc":"redefini","lemma":"redéfinir","pos":"VER"} ,
		{"word":"redéfinir","word_nosc":"redefinir","lemma":"redéfinir","pos":"VER"} ,
		{"word":"redéfinis","word_nosc":"redefinis","lemma":"redéfinir","pos":"VER"} ,
		{"word":"redéfinissons","word_nosc":"redefinissons","lemma":"redéfinir","pos":"VER"} ,
		{"word":"redégringoler","word_nosc":"redegringoler","lemma":"redégringoler","pos":"VER"} ,
		{"word":"redémarra","word_nosc":"redemarra","lemma":"redémarrer","pos":"VER"} ,
		{"word":"redémarraient","word_nosc":"redemarraient","lemma":"redémarrer","pos":"VER"} ,
		{"word":"redémarrais","word_nosc":"redemarrais","lemma":"redémarrer","pos":"VER"} ,
		{"word":"redémarrait","word_nosc":"redemarrait","lemma":"redémarrer","pos":"VER"} ,
		{"word":"redémarrant","word_nosc":"redemarrant","lemma":"redémarrer","pos":"VER"} ,
		{"word":"redémarre","word_nosc":"redemarre","lemma":"redémarrer","pos":"VER"} ,
		{"word":"redémarrent","word_nosc":"redemarrent","lemma":"redémarrer","pos":"VER"} ,
		{"word":"redémarrer","word_nosc":"redemarrer","lemma":"redémarrer","pos":"VER"} ,
		{"word":"redémarrera","word_nosc":"redemarrera","lemma":"redémarrer","pos":"VER"} ,
		{"word":"redémarrerait","word_nosc":"redemarrerait","lemma":"redémarrer","pos":"VER"} ,
		{"word":"redémarrez","word_nosc":"redemarrez","lemma":"redémarrer","pos":"VER"} ,
		{"word":"redémarrons","word_nosc":"redemarrons","lemma":"redémarrer","pos":"VER"} ,
		{"word":"redémarré","word_nosc":"redemarre","lemma":"redémarrer","pos":"VER"} ,
		{"word":"redémolir","word_nosc":"redemolir","lemma":"redémolir","pos":"VER"} ,
		{"word":"redéploie","word_nosc":"redeploie","lemma":"redéployer","pos":"VER"} ,
		{"word":"redéployer","word_nosc":"redeployer","lemma":"redéployer","pos":"VER"} ,
		{"word":"redéployez","word_nosc":"redeployez","lemma":"redéployer","pos":"VER"} ,
		{"word":"redéposer","word_nosc":"redeposer","lemma":"redéposer","pos":"VER"} ,
		{"word":"redéposé","word_nosc":"redepose","lemma":"redéposer","pos":"VER"} ,
		{"word":"refabriquer","word_nosc":"refabriquer","lemma":"refabriquer","pos":"VER"} ,
		{"word":"refaire","word_nosc":"refaire","lemma":"refaire","pos":"VER"} ,
		{"word":"refais","word_nosc":"refais","lemma":"refaire","pos":"VER"} ,
		{"word":"refaisaient","word_nosc":"refaisaient","lemma":"refaire","pos":"VER"} ,
		{"word":"refaisais","word_nosc":"refaisais","lemma":"refaire","pos":"VER"} ,
		{"word":"refaisait","word_nosc":"refaisait","lemma":"refaire","pos":"VER"} ,
		{"word":"refaisant","word_nosc":"refaisant","lemma":"refaire","pos":"VER"} ,
		{"word":"refaisions","word_nosc":"refaisions","lemma":"refaire","pos":"VER"} ,
		{"word":"refaisons","word_nosc":"refaisons","lemma":"refaire","pos":"VER"} ,
		{"word":"refait","word_nosc":"refait","lemma":"refaire","pos":"VER"} ,
		{"word":"refaite","word_nosc":"refaite","lemma":"refaire","pos":"VER"} ,
		{"word":"refaites","word_nosc":"refaites","lemma":"refaire","pos":"VER"} ,
		{"word":"refaits","word_nosc":"refaits","lemma":"refaire","pos":"VER"} ,
		{"word":"refamiliariser","word_nosc":"refamiliariser","lemma":"refamiliariser","pos":"VER"} ,
		{"word":"refasse","word_nosc":"refasse","lemma":"refaire","pos":"VER"} ,
		{"word":"refassent","word_nosc":"refassent","lemma":"refaire","pos":"VER"} ,
		{"word":"refasses","word_nosc":"refasses","lemma":"refaire","pos":"VER"} ,
		{"word":"refassiez","word_nosc":"refassiez","lemma":"refaire","pos":"VER"} ,
		{"word":"refaufile","word_nosc":"refaufile","lemma":"refaufiler","pos":"VER"} ,
		{"word":"refaçonner","word_nosc":"refaconner","lemma":"refaçonner","pos":"VER"} ,
		{"word":"refendre","word_nosc":"refendre","lemma":"refendre","pos":"VER"} ,
		{"word":"refendu","word_nosc":"refendu","lemma":"refendre","pos":"VER"} ,
		{"word":"refera","word_nosc":"refera","lemma":"refaire","pos":"VER"} ,
		{"word":"referai","word_nosc":"referai","lemma":"refaire","pos":"VER"} ,
		{"word":"referais","word_nosc":"referais","lemma":"refaire","pos":"VER"} ,
		{"word":"referait","word_nosc":"referait","lemma":"refaire","pos":"VER"} ,
		{"word":"referas","word_nosc":"referas","lemma":"refaire","pos":"VER"} ,
		{"word":"referions","word_nosc":"referions","lemma":"refaire","pos":"VER"} ,
		{"word":"referma","word_nosc":"referma","lemma":"refermer","pos":"VER"} ,
		{"word":"refermai","word_nosc":"refermai","lemma":"refermer","pos":"VER"} ,
		{"word":"refermaient","word_nosc":"refermaient","lemma":"refermer","pos":"VER"} ,
		{"word":"refermais","word_nosc":"refermais","lemma":"refermer","pos":"VER"} ,
		{"word":"refermait","word_nosc":"refermait","lemma":"refermer","pos":"VER"} ,
		{"word":"refermant","word_nosc":"refermant","lemma":"refermer","pos":"VER"} ,
		{"word":"refermassent","word_nosc":"refermassent","lemma":"refermer","pos":"VER"} ,
		{"word":"referme","word_nosc":"referme","lemma":"refermer","pos":"VER"} ,
		{"word":"referment","word_nosc":"referment","lemma":"refermer","pos":"VER"} ,
		{"word":"refermer","word_nosc":"refermer","lemma":"refermer","pos":"VER"} ,
		{"word":"refermera","word_nosc":"refermera","lemma":"refermer","pos":"VER"} ,
		{"word":"refermeraient","word_nosc":"refermeraient","lemma":"refermer","pos":"VER"} ,
		{"word":"refermerais","word_nosc":"refermerais","lemma":"refermer","pos":"VER"} ,
		{"word":"refermerait","word_nosc":"refermerait","lemma":"refermer","pos":"VER"} ,
		{"word":"refermeront","word_nosc":"refermeront","lemma":"refermer","pos":"VER"} ,
		{"word":"refermes","word_nosc":"refermes","lemma":"refermer","pos":"VER"} ,
		{"word":"refermez","word_nosc":"refermez","lemma":"refermer","pos":"VER"} ,
		{"word":"refermons","word_nosc":"refermons","lemma":"refermer","pos":"VER"} ,
		{"word":"refermâmes","word_nosc":"refermames","lemma":"refermer","pos":"VER"} ,
		{"word":"refermât","word_nosc":"refermat","lemma":"refermer","pos":"VER"} ,
		{"word":"refermèrent","word_nosc":"refermerent","lemma":"refermer","pos":"VER"} ,
		{"word":"refermé","word_nosc":"referme","lemma":"refermer","pos":"VER"} ,
		{"word":"refermée","word_nosc":"refermee","lemma":"refermer","pos":"VER"} ,
		{"word":"refermées","word_nosc":"refermees","lemma":"refermer","pos":"VER"} ,
		{"word":"refermés","word_nosc":"refermes","lemma":"refermer","pos":"VER"} ,
		{"word":"referons","word_nosc":"referons","lemma":"refaire","pos":"VER"} ,
		{"word":"referont","word_nosc":"referont","lemma":"refaire","pos":"VER"} ,
		{"word":"refeuilleter","word_nosc":"refeuilleter","lemma":"refeuilleter","pos":"VER"} ,
		{"word":"reficelai","word_nosc":"reficelai","lemma":"reficeler","pos":"VER"} ,
		{"word":"reficelle","word_nosc":"reficelle","lemma":"reficeler","pos":"VER"} ,
		{"word":"refila","word_nosc":"refila","lemma":"refiler","pos":"VER"} ,
		{"word":"refilai","word_nosc":"refilai","lemma":"refiler","pos":"VER"} ,
		{"word":"refilaient","word_nosc":"refilaient","lemma":"refiler","pos":"VER"} ,
		{"word":"refilais","word_nosc":"refilais","lemma":"refiler","pos":"VER"} ,
		{"word":"refilait","word_nosc":"refilait","lemma":"refiler","pos":"VER"} ,
		{"word":"refilant","word_nosc":"refilant","lemma":"refiler","pos":"VER"} ,
		{"word":"refile","word_nosc":"refile","lemma":"refiler","pos":"VER"} ,
		{"word":"refilent","word_nosc":"refilent","lemma":"refiler","pos":"VER"} ,
		{"word":"refiler","word_nosc":"refiler","lemma":"refiler","pos":"VER"} ,
		{"word":"refilera","word_nosc":"refilera","lemma":"refiler","pos":"VER"} ,
		{"word":"refilerait","word_nosc":"refilerait","lemma":"refiler","pos":"VER"} ,
		{"word":"refiles","word_nosc":"refiles","lemma":"refiler","pos":"VER"} ,
		{"word":"refilez","word_nosc":"refilez","lemma":"refiler","pos":"VER"} ,
		{"word":"refilé","word_nosc":"refile","lemma":"refiler","pos":"VER"} ,
		{"word":"refilée","word_nosc":"refilee","lemma":"refiler","pos":"VER"} ,
		{"word":"refilées","word_nosc":"refilees","lemma":"refiler","pos":"VER"} ,
		{"word":"refilés","word_nosc":"refiles","lemma":"refiler","pos":"VER"} ,
		{"word":"refinancée","word_nosc":"refinancee","lemma":"refinancer","pos":"VER"} ,
		{"word":"refirent","word_nosc":"refirent","lemma":"refaire","pos":"VER"} ,
		{"word":"refis","word_nosc":"refis","lemma":"refaire","pos":"VER"} ,
		{"word":"refit","word_nosc":"refit","lemma":"refaire","pos":"VER"} ,
		{"word":"refleurir","word_nosc":"refleurir","lemma":"refleurir","pos":"VER"} ,
		{"word":"refleurira","word_nosc":"refleurira","lemma":"refleurir","pos":"VER"} ,
		{"word":"refleurissaient","word_nosc":"refleurissaient","lemma":"refleurir","pos":"VER"} ,
		{"word":"refleurissait","word_nosc":"refleurissait","lemma":"refleurir","pos":"VER"} ,
		{"word":"refleurissent","word_nosc":"refleurissent","lemma":"refleurir","pos":"VER"} ,
		{"word":"refleurit","word_nosc":"refleurit","lemma":"refleurir","pos":"VER"} ,
		{"word":"reflua","word_nosc":"reflua","lemma":"refluer","pos":"VER"} ,
		{"word":"refluaient","word_nosc":"refluaient","lemma":"refluer","pos":"VER"} ,
		{"word":"refluait","word_nosc":"refluait","lemma":"refluer","pos":"VER"} ,
		{"word":"refluant","word_nosc":"refluant","lemma":"refluer","pos":"VER"} ,
		{"word":"reflue","word_nosc":"reflue","lemma":"refluer","pos":"VER"} ,
		{"word":"refluent","word_nosc":"refluent","lemma":"refluer","pos":"VER"} ,
		{"word":"refluer","word_nosc":"refluer","lemma":"refluer","pos":"VER"} ,
		{"word":"refluera","word_nosc":"refluera","lemma":"refluer","pos":"VER"} ,
		{"word":"refluèrent","word_nosc":"refluerent","lemma":"refluer","pos":"VER"} ,
		{"word":"reflué","word_nosc":"reflue","lemma":"refluer","pos":"VER"} ,
		{"word":"refluée","word_nosc":"refluee","lemma":"refluer","pos":"VER"} ,
		{"word":"reflète","word_nosc":"reflete","lemma":"refléter","pos":"VER"} ,
		{"word":"reflètent","word_nosc":"refletent","lemma":"refléter","pos":"VER"} ,
		{"word":"refléta","word_nosc":"refleta","lemma":"refléter","pos":"VER"} ,
		{"word":"reflétaient","word_nosc":"refletaient","lemma":"refléter","pos":"VER"} ,
		{"word":"reflétait","word_nosc":"refletait","lemma":"refléter","pos":"VER"} ,
		{"word":"reflétant","word_nosc":"refletant","lemma":"refléter","pos":"VER"} ,
		{"word":"refléter","word_nosc":"refleter","lemma":"refléter","pos":"VER"} ,
		{"word":"reflétera","word_nosc":"refletera","lemma":"refléter","pos":"VER"} ,
		{"word":"refléteraient","word_nosc":"refleteraient","lemma":"refléter","pos":"VER"} ,
		{"word":"refléterait","word_nosc":"refleterait","lemma":"refléter","pos":"VER"} ,
		{"word":"reflétons","word_nosc":"refletons","lemma":"refléter","pos":"VER"} ,
		{"word":"reflétèrent","word_nosc":"refleterent","lemma":"refléter","pos":"VER"} ,
		{"word":"reflété","word_nosc":"reflete","lemma":"refléter","pos":"VER"} ,
		{"word":"reflétée","word_nosc":"refletee","lemma":"refléter","pos":"VER"} ,
		{"word":"reflétées","word_nosc":"refletees","lemma":"refléter","pos":"VER"} ,
		{"word":"reflétés","word_nosc":"refletes","lemma":"refléter","pos":"VER"} ,
		{"word":"refoncer","word_nosc":"refoncer","lemma":"refoncer","pos":"VER"} ,
		{"word":"refondant","word_nosc":"refondant","lemma":"refonder","pos":"VER"} ,
		{"word":"refonde","word_nosc":"refonde","lemma":"refonder","pos":"VER"} ,
		{"word":"refondre","word_nosc":"refondre","lemma":"refondre","pos":"VER"} ,
		{"word":"refonds","word_nosc":"refonds","lemma":"refondre","pos":"VER"} ,
		{"word":"refondu","word_nosc":"refondu","lemma":"refondre","pos":"VER"} ,
		{"word":"refondus","word_nosc":"refondus","lemma":"refondre","pos":"VER"} ,
		{"word":"refont","word_nosc":"refont","lemma":"refaire","pos":"VER"} ,
		{"word":"reforger","word_nosc":"reforger","lemma":"reforger","pos":"VER"} ,
		{"word":"reforgé","word_nosc":"reforge","lemma":"reforger","pos":"VER"} ,
		{"word":"reforgée","word_nosc":"reforgee","lemma":"reforger","pos":"VER"} ,
		{"word":"reforma","word_nosc":"reforma","lemma":"reformer","pos":"VER"} ,
		{"word":"reformaient","word_nosc":"reformaient","lemma":"reformer","pos":"VER"} ,
		{"word":"reformait","word_nosc":"reformait","lemma":"reformer","pos":"VER"} ,
		{"word":"reformant","word_nosc":"reformant","lemma":"reformer","pos":"VER"} ,
		{"word":"reformassent","word_nosc":"reformassent","lemma":"reformer","pos":"VER"} ,
		{"word":"reformater","word_nosc":"reformater","lemma":"reformater","pos":"VER"} ,
		{"word":"reforme","word_nosc":"reforme","lemma":"reformer","pos":"VER"} ,
		{"word":"reforment","word_nosc":"reforment","lemma":"reformer","pos":"VER"} ,
		{"word":"reformer","word_nosc":"reformer","lemma":"reformer","pos":"VER"} ,
		{"word":"reformerons","word_nosc":"reformerons","lemma":"reformer","pos":"VER"} ,
		{"word":"reformeront","word_nosc":"reformeront","lemma":"reformer","pos":"VER"} ,
		{"word":"reformez","word_nosc":"reformez","lemma":"reformer","pos":"VER"} ,
		{"word":"reformuler","word_nosc":"reformuler","lemma":"reformuler","pos":"VER"} ,
		{"word":"reformulé","word_nosc":"reformule","lemma":"reformuler","pos":"VER"} ,
		{"word":"reformulée","word_nosc":"reformulee","lemma":"reformuler","pos":"VER"} ,
		{"word":"reformèrent","word_nosc":"reformerent","lemma":"reformer","pos":"VER"} ,
		{"word":"reformé","word_nosc":"reforme","lemma":"reformer","pos":"VER"} ,
		{"word":"reformée","word_nosc":"reformee","lemma":"reformer","pos":"VER"} ,
		{"word":"reformés","word_nosc":"reformes","lemma":"reformer","pos":"VER"} ,
		{"word":"refouiller","word_nosc":"refouiller","lemma":"refouiller","pos":"VER"} ,
		{"word":"refouillé","word_nosc":"refouille","lemma":"refouiller","pos":"VER"} ,
		{"word":"refoula","word_nosc":"refoula","lemma":"refouler","pos":"VER"} ,
		{"word":"refoulai","word_nosc":"refoulai","lemma":"refouler","pos":"VER"} ,
		{"word":"refoulaient","word_nosc":"refoulaient","lemma":"refouler","pos":"VER"} ,
		{"word":"refoulais","word_nosc":"refoulais","lemma":"refouler","pos":"VER"} ,
		{"word":"refoulait","word_nosc":"refoulait","lemma":"refouler","pos":"VER"} ,
		{"word":"refoulant","word_nosc":"refoulant","lemma":"refouler","pos":"VER"} ,
		{"word":"refoule","word_nosc":"refoule","lemma":"refouler","pos":"VER"} ,
		{"word":"refoulent","word_nosc":"refoulent","lemma":"refouler","pos":"VER"} ,
		{"word":"refouler","word_nosc":"refouler","lemma":"refouler","pos":"VER"} ,
		{"word":"refoulerait","word_nosc":"refoulerait","lemma":"refouler","pos":"VER"} ,
		{"word":"refoulez","word_nosc":"refoulez","lemma":"refouler","pos":"VER"} ,
		{"word":"refoulé","word_nosc":"refoule","lemma":"refouler","pos":"VER"} ,
		{"word":"refoulée","word_nosc":"refoulee","lemma":"refouler","pos":"VER"} ,
		{"word":"refoulées","word_nosc":"refoulees","lemma":"refouler","pos":"VER"} ,
		{"word":"refoulés","word_nosc":"refoules","lemma":"refouler","pos":"VER"} ,
		{"word":"refourgua","word_nosc":"refourgua","lemma":"refourguer","pos":"VER"} ,
		{"word":"refourgue","word_nosc":"refourgue","lemma":"refourguer","pos":"VER"} ,
		{"word":"refourguer","word_nosc":"refourguer","lemma":"refourguer","pos":"VER"} ,
		{"word":"refourguera","word_nosc":"refourguera","lemma":"refourguer","pos":"VER"} ,
		{"word":"refourguerait","word_nosc":"refourguerait","lemma":"refourguer","pos":"VER"} ,
		{"word":"refourguions","word_nosc":"refourguions","lemma":"refourguer","pos":"VER"} ,
		{"word":"refourgué","word_nosc":"refourgue","lemma":"refourguer","pos":"VER"} ,
		{"word":"refourguées","word_nosc":"refourguees","lemma":"refourguer","pos":"VER"} ,
		{"word":"refourgués","word_nosc":"refourgues","lemma":"refourguer","pos":"VER"} ,
		{"word":"refourrait","word_nosc":"refourrait","lemma":"refourrer","pos":"VER"} ,
		{"word":"refous","word_nosc":"refous","lemma":"refoutre","pos":"VER"} ,
		{"word":"refout","word_nosc":"refout","lemma":"refoutre","pos":"VER"} ,
		{"word":"refoutait","word_nosc":"refoutait","lemma":"refoutre","pos":"VER"} ,
		{"word":"refoute","word_nosc":"refoute","lemma":"refoutre","pos":"VER"} ,
		{"word":"refoutent","word_nosc":"refoutent","lemma":"refoutre","pos":"VER"} ,
		{"word":"refoutes","word_nosc":"refoutes","lemma":"refoutre","pos":"VER"} ,
		{"word":"refoutez","word_nosc":"refoutez","lemma":"refoutre","pos":"VER"} ,
		{"word":"refoutre","word_nosc":"refoutre","lemma":"refoutre","pos":"VER"} ,
		{"word":"refoutu","word_nosc":"refoutu","lemma":"refoutre","pos":"VER"} ,
		{"word":"refranchies","word_nosc":"refranchies","lemma":"refranchir","pos":"VER"} ,
		{"word":"refranchir","word_nosc":"refranchir","lemma":"refranchir","pos":"VER"} ,
		{"word":"refrappe","word_nosc":"refrappe","lemma":"refrapper","pos":"VER"} ,
		{"word":"refrapper","word_nosc":"refrapper","lemma":"refrapper","pos":"VER"} ,
		{"word":"refroidi","word_nosc":"refroidi","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidie","word_nosc":"refroidie","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidies","word_nosc":"refroidies","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidir","word_nosc":"refroidir","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidira","word_nosc":"refroidira","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidiraient","word_nosc":"refroidiraient","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidirais","word_nosc":"refroidirais","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidirait","word_nosc":"refroidirait","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidis","word_nosc":"refroidis","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidissaient","word_nosc":"refroidissaient","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidissais","word_nosc":"refroidissais","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidissait","word_nosc":"refroidissait","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidissant","word_nosc":"refroidissant","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidisse","word_nosc":"refroidisse","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidissent","word_nosc":"refroidissent","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidissiez","word_nosc":"refroidissiez","lemma":"refroidir","pos":"VER"} ,
		{"word":"refroidit","word_nosc":"refroidit","lemma":"refroidir","pos":"VER"} ,
		{"word":"refrène","word_nosc":"refrene","lemma":"refréner","pos":"VER"} ,
		{"word":"refréna","word_nosc":"refrena","lemma":"refréner","pos":"VER"} ,
		{"word":"refrénai","word_nosc":"refrenai","lemma":"refréner","pos":"VER"} ,
		{"word":"refrénait","word_nosc":"refrenait","lemma":"refréner","pos":"VER"} ,
		{"word":"refrénant","word_nosc":"refrenant","lemma":"refréner","pos":"VER"} ,
		{"word":"refréner","word_nosc":"refrener","lemma":"refréner","pos":"VER"} ,
		{"word":"refrénerait","word_nosc":"refrenerait","lemma":"refréner","pos":"VER"} ,
		{"word":"refréné","word_nosc":"refrene","lemma":"refréner","pos":"VER"} ,
		{"word":"refrénés","word_nosc":"refrenes","lemma":"refréner","pos":"VER"} ,
		{"word":"refumer","word_nosc":"refumer","lemma":"refumer","pos":"VER"} ,
		{"word":"refusa","word_nosc":"refusa","lemma":"refuser","pos":"VER"} ,
		{"word":"refusai","word_nosc":"refusai","lemma":"refuser","pos":"VER"} ,
		{"word":"refusaient","word_nosc":"refusaient","lemma":"refuser","pos":"VER"} ,
		{"word":"refusais","word_nosc":"refusais","lemma":"refuser","pos":"VER"} ,
		{"word":"refusait","word_nosc":"refusait","lemma":"refuser","pos":"VER"} ,
		{"word":"refusant","word_nosc":"refusant","lemma":"refuser","pos":"VER"} ,
		{"word":"refuse","word_nosc":"refuse","lemma":"refuser","pos":"VER"} ,
		{"word":"refusent","word_nosc":"refusent","lemma":"refuser","pos":"VER"} ,
		{"word":"refuser","word_nosc":"refuser","lemma":"refuser","pos":"VER"} ,
		{"word":"refusera","word_nosc":"refusera","lemma":"refuser","pos":"VER"} ,
		{"word":"refuserai","word_nosc":"refuserai","lemma":"refuser","pos":"VER"} ,
		{"word":"refuseraient","word_nosc":"refuseraient","lemma":"refuser","pos":"VER"} ,
		{"word":"refuserais","word_nosc":"refuserais","lemma":"refuser","pos":"VER"} ,
		{"word":"refuserait","word_nosc":"refuserait","lemma":"refuser","pos":"VER"} ,
		{"word":"refuseras","word_nosc":"refuseras","lemma":"refuser","pos":"VER"} ,
		{"word":"refuserez","word_nosc":"refuserez","lemma":"refuser","pos":"VER"} ,
		{"word":"refuseriez","word_nosc":"refuseriez","lemma":"refuser","pos":"VER"} ,
		{"word":"refuserions","word_nosc":"refuserions","lemma":"refuser","pos":"VER"} ,
		{"word":"refuseront","word_nosc":"refuseront","lemma":"refuser","pos":"VER"} ,
		{"word":"refuses","word_nosc":"refuses","lemma":"refuser","pos":"VER"} ,
		{"word":"refusez","word_nosc":"refusez","lemma":"refuser","pos":"VER"} ,
		{"word":"refusiez","word_nosc":"refusiez","lemma":"refuser","pos":"VER"} ,
		{"word":"refusions","word_nosc":"refusions","lemma":"refuser","pos":"VER"} ,
		{"word":"refusons","word_nosc":"refusons","lemma":"refuser","pos":"VER"} ,
		{"word":"refusât","word_nosc":"refusat","lemma":"refuser","pos":"VER"} ,
		{"word":"refusèrent","word_nosc":"refuserent","lemma":"refuser","pos":"VER"} ,
		{"word":"refusé","word_nosc":"refuse","lemma":"refuser","pos":"VER"} ,
		{"word":"refusée","word_nosc":"refusee","lemma":"refuser","pos":"VER"} ,
		{"word":"refusées","word_nosc":"refusees","lemma":"refuser","pos":"VER"} ,
		{"word":"refusés","word_nosc":"refuses","lemma":"refuser","pos":"VER"} ,
		{"word":"refîmes","word_nosc":"refimes","lemma":"refaire","pos":"VER"} ,
		{"word":"refît","word_nosc":"refit","lemma":"refaire","pos":"VER"} ,
		{"word":"regagna","word_nosc":"regagna","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnai","word_nosc":"regagnai","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnaient","word_nosc":"regagnaient","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnais","word_nosc":"regagnais","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnait","word_nosc":"regagnait","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnant","word_nosc":"regagnant","lemma":"regagner","pos":"VER"} ,
		{"word":"regagne","word_nosc":"regagne","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnent","word_nosc":"regagnent","lemma":"regagner","pos":"VER"} ,
		{"word":"regagner","word_nosc":"regagner","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnera","word_nosc":"regagnera","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnerai","word_nosc":"regagnerai","lemma":"regagner","pos":"VER"} ,
		{"word":"regagneraient","word_nosc":"regagneraient","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnerais","word_nosc":"regagnerais","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnerait","word_nosc":"regagnerait","lemma":"regagner","pos":"VER"} ,
		{"word":"regagneras","word_nosc":"regagneras","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnerez","word_nosc":"regagnerez","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnerons","word_nosc":"regagnerons","lemma":"regagner","pos":"VER"} ,
		{"word":"regagneront","word_nosc":"regagneront","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnez","word_nosc":"regagnez","lemma":"regagner","pos":"VER"} ,
		{"word":"regagniez","word_nosc":"regagniez","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnions","word_nosc":"regagnions","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnons","word_nosc":"regagnons","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnâmes","word_nosc":"regagnames","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnât","word_nosc":"regagnat","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnèrent","word_nosc":"regagnerent","lemma":"regagner","pos":"VER"} ,
		{"word":"regagné","word_nosc":"regagne","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnée","word_nosc":"regagnee","lemma":"regagner","pos":"VER"} ,
		{"word":"regagnés","word_nosc":"regagnes","lemma":"regagner","pos":"VER"} ,
		{"word":"regarda","word_nosc":"regarda","lemma":"regarder","pos":"VER"} ,
		{"word":"regardai","word_nosc":"regardai","lemma":"regarder","pos":"VER"} ,
		{"word":"regardaient","word_nosc":"regardaient","lemma":"regarder","pos":"VER"} ,
		{"word":"regardais","word_nosc":"regardais","lemma":"regarder","pos":"VER"} ,
		{"word":"regardait","word_nosc":"regardait","lemma":"regarder","pos":"VER"} ,
		{"word":"regardant","word_nosc":"regardant","lemma":"regarder","pos":"VER"} ,
		{"word":"regarde","word_nosc":"regarde","lemma":"regarder","pos":"VER"} ,
		{"word":"regardent","word_nosc":"regardent","lemma":"regarder","pos":"VER"} ,
		{"word":"regarder","word_nosc":"regarder","lemma":"regarder","pos":"VER"} ,
		{"word":"regardera","word_nosc":"regardera","lemma":"regarder","pos":"VER"} ,
		{"word":"regarderai","word_nosc":"regarderai","lemma":"regarder","pos":"VER"} ,
		{"word":"regarderaient","word_nosc":"regarderaient","lemma":"regarder","pos":"VER"} ,
		{"word":"regarderais","word_nosc":"regarderais","lemma":"regarder","pos":"VER"} ,
		{"word":"regarderait","word_nosc":"regarderait","lemma":"regarder","pos":"VER"} ,
		{"word":"regarderas","word_nosc":"regarderas","lemma":"regarder","pos":"VER"} ,
		{"word":"regarderez","word_nosc":"regarderez","lemma":"regarder","pos":"VER"} ,
		{"word":"regarderiez","word_nosc":"regarderiez","lemma":"regarder","pos":"VER"} ,
		{"word":"regarderions","word_nosc":"regarderions","lemma":"regarder","pos":"VER"} ,
		{"word":"regarderons","word_nosc":"regarderons","lemma":"regarder","pos":"VER"} ,
		{"word":"regarderont","word_nosc":"regarderont","lemma":"regarder","pos":"VER"} ,
		{"word":"regardes","word_nosc":"regardes","lemma":"regarder","pos":"VER"} ,
		{"word":"regardez","word_nosc":"regardez","lemma":"regarder","pos":"VER"} ,
		{"word":"regardiez","word_nosc":"regardiez","lemma":"regarder","pos":"VER"} ,
		{"word":"regardions","word_nosc":"regardions","lemma":"regarder","pos":"VER"} ,
		{"word":"regardons","word_nosc":"regardons","lemma":"regarder","pos":"VER"} ,
		{"word":"regardâmes","word_nosc":"regardames","lemma":"regarder","pos":"VER"} ,
		{"word":"regardât","word_nosc":"regardat","lemma":"regarder","pos":"VER"} ,
		{"word":"regardèrent","word_nosc":"regarderent","lemma":"regarder","pos":"VER"} ,
		{"word":"regardé","word_nosc":"regarde","lemma":"regarder","pos":"VER"} ,
		{"word":"regardée","word_nosc":"regardee","lemma":"regarder","pos":"VER"} ,
		{"word":"regardées","word_nosc":"regardees","lemma":"regarder","pos":"VER"} ,
		{"word":"regardés","word_nosc":"regardes","lemma":"regarder","pos":"VER"} ,
		{"word":"regarni","word_nosc":"regarni","lemma":"regarnir","pos":"VER"} ,
		{"word":"regarnies","word_nosc":"regarnies","lemma":"regarnir","pos":"VER"} ,
		{"word":"regarnir","word_nosc":"regarnir","lemma":"regarnir","pos":"VER"} ,
		{"word":"regarnirez","word_nosc":"regarnirez","lemma":"regarnir","pos":"VER"} ,
		{"word":"regarnis","word_nosc":"regarnis","lemma":"regarnir","pos":"VER"} ,
		{"word":"regarnissez","word_nosc":"regarnissez","lemma":"regarnir","pos":"VER"} ,
		{"word":"regelés","word_nosc":"regeles","lemma":"regeler","pos":"VER"} ,
		{"word":"regimba","word_nosc":"regimba","lemma":"regimber","pos":"VER"} ,
		{"word":"regimbais","word_nosc":"regimbais","lemma":"regimber","pos":"VER"} ,
		{"word":"regimbait","word_nosc":"regimbait","lemma":"regimber","pos":"VER"} ,
		{"word":"regimbe","word_nosc":"regimbe","lemma":"regimber","pos":"VER"} ,
		{"word":"regimber","word_nosc":"regimber","lemma":"regimber","pos":"VER"} ,
		{"word":"regimbé","word_nosc":"regimbe","lemma":"regimber","pos":"VER"} ,
		{"word":"reglissé","word_nosc":"reglisse","lemma":"reglisser","pos":"VER"} ,
		{"word":"regonflait","word_nosc":"regonflait","lemma":"regonfler","pos":"VER"} ,
		{"word":"regonfle","word_nosc":"regonfle","lemma":"regonfler","pos":"VER"} ,
		{"word":"regonfler","word_nosc":"regonfler","lemma":"regonfler","pos":"VER"} ,
		{"word":"regonflera","word_nosc":"regonflera","lemma":"regonfler","pos":"VER"} ,
		{"word":"regonflerais","word_nosc":"regonflerais","lemma":"regonfler","pos":"VER"} ,
		{"word":"regonflerait","word_nosc":"regonflerait","lemma":"regonfler","pos":"VER"} ,
		{"word":"regonflé","word_nosc":"regonfle","lemma":"regonfler","pos":"VER"} ,
		{"word":"regonflés","word_nosc":"regonfles","lemma":"regonfler","pos":"VER"} ,
		{"word":"regorge","word_nosc":"regorge","lemma":"regorger","pos":"VER"} ,
		{"word":"regorgea","word_nosc":"regorgea","lemma":"regorger","pos":"VER"} ,
		{"word":"regorgeaient","word_nosc":"regorgeaient","lemma":"regorger","pos":"VER"} ,
		{"word":"regorgeais","word_nosc":"regorgeais","lemma":"regorger","pos":"VER"} ,
		{"word":"regorgeait","word_nosc":"regorgeait","lemma":"regorger","pos":"VER"} ,
		{"word":"regorgeant","word_nosc":"regorgeant","lemma":"regorger","pos":"VER"} ,
		{"word":"regorgent","word_nosc":"regorgent","lemma":"regorger","pos":"VER"} ,
		{"word":"regorger","word_nosc":"regorger","lemma":"regorger","pos":"VER"} ,
		{"word":"regorges","word_nosc":"regorges","lemma":"regorger","pos":"VER"} ,
		{"word":"regoûter","word_nosc":"regouter","lemma":"regoûter","pos":"VER"} ,
		{"word":"regoûté","word_nosc":"regoute","lemma":"regoûter","pos":"VER"} ,
		{"word":"regreffe","word_nosc":"regreffe","lemma":"regreffer","pos":"VER"} ,
		{"word":"regretta","word_nosc":"regretta","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettai","word_nosc":"regrettai","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettaient","word_nosc":"regrettaient","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettais","word_nosc":"regrettais","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettait","word_nosc":"regrettait","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettant","word_nosc":"regrettant","lemma":"regretter","pos":"VER"} ,
		{"word":"regrette","word_nosc":"regrette","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettent","word_nosc":"regrettent","lemma":"regretter","pos":"VER"} ,
		{"word":"regretter","word_nosc":"regretter","lemma":"regretter","pos":"VER"} ,
		{"word":"regretter","word_nosc":"regretter","lemma":"rester","pos":"VER"} ,
		{"word":"regrettera","word_nosc":"regrettera","lemma":"regretter","pos":"VER"} ,
		{"word":"regretterai","word_nosc":"regretterai","lemma":"regretter","pos":"VER"} ,
		{"word":"regretteraient","word_nosc":"regretteraient","lemma":"regretter","pos":"VER"} ,
		{"word":"regretterais","word_nosc":"regretterais","lemma":"regretter","pos":"VER"} ,
		{"word":"regretterait","word_nosc":"regretterait","lemma":"regretter","pos":"VER"} ,
		{"word":"regretteras","word_nosc":"regretteras","lemma":"regretter","pos":"VER"} ,
		{"word":"regretterez","word_nosc":"regretterez","lemma":"regretter","pos":"VER"} ,
		{"word":"regretteriez","word_nosc":"regretteriez","lemma":"regretter","pos":"VER"} ,
		{"word":"regretterons","word_nosc":"regretterons","lemma":"regretter","pos":"VER"} ,
		{"word":"regretteront","word_nosc":"regretteront","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettes","word_nosc":"regrettes","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettez","word_nosc":"regrettez","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettiez","word_nosc":"regrettiez","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettions","word_nosc":"regrettions","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettons","word_nosc":"regrettons","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettât","word_nosc":"regrettat","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettèrent","word_nosc":"regretterent","lemma":"regretter","pos":"VER"} ,
		{"word":"regretté","word_nosc":"regrette","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettée","word_nosc":"regrettee","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettées","word_nosc":"regrettees","lemma":"regretter","pos":"VER"} ,
		{"word":"regrettés","word_nosc":"regrettes","lemma":"regretter","pos":"VER"} ,
		{"word":"regrimpa","word_nosc":"regrimpa","lemma":"regrimper","pos":"VER"} ,
		{"word":"regrimpaient","word_nosc":"regrimpaient","lemma":"regrimper","pos":"VER"} ,
		{"word":"regrimpait","word_nosc":"regrimpait","lemma":"regrimper","pos":"VER"} ,
		{"word":"regrimpe","word_nosc":"regrimpe","lemma":"regrimper","pos":"VER"} ,
		{"word":"regrimpent","word_nosc":"regrimpent","lemma":"regrimper","pos":"VER"} ,
		{"word":"regrimper","word_nosc":"regrimper","lemma":"regrimper","pos":"VER"} ,
		{"word":"regrimperas","word_nosc":"regrimperas","lemma":"regrimper","pos":"VER"} ,
		{"word":"regrimperons","word_nosc":"regrimperons","lemma":"regrimper","pos":"VER"} ,
		{"word":"regrimpez","word_nosc":"regrimpez","lemma":"regrimper","pos":"VER"} ,
		{"word":"regrimpé","word_nosc":"regrimpe","lemma":"regrimper","pos":"VER"} ,
		{"word":"regrossir","word_nosc":"regrossir","lemma":"regrossir","pos":"VER"} ,
		{"word":"regroupa","word_nosc":"regroupa","lemma":"regrouper","pos":"VER"} ,
		{"word":"regroupaient","word_nosc":"regroupaient","lemma":"regrouper","pos":"VER"} ,
		{"word":"regroupait","word_nosc":"regroupait","lemma":"regrouper","pos":"VER"} ,
		{"word":"regroupant","word_nosc":"regroupant","lemma":"regrouper","pos":"VER"} ,
		{"word":"regroupe","word_nosc":"regroupe","lemma":"regrouper","pos":"VER"} ,
		{"word":"regroupent","word_nosc":"regroupent","lemma":"regrouper","pos":"VER"} ,
		{"word":"regrouper","word_nosc":"regrouper","lemma":"regrouper","pos":"VER"} ,
		{"word":"regrouperaient","word_nosc":"regrouperaient","lemma":"regrouper","pos":"VER"} ,
		{"word":"regrouperions","word_nosc":"regrouperions","lemma":"regrouper","pos":"VER"} ,
		{"word":"regroupez","word_nosc":"regroupez","lemma":"regrouper","pos":"VER"} ,
		{"word":"regroupèrent","word_nosc":"regrouperent","lemma":"regrouper","pos":"VER"} ,
		{"word":"regroupé","word_nosc":"regroupe","lemma":"regrouper","pos":"VER"} ,
		{"word":"regroupée","word_nosc":"regroupee","lemma":"regrouper","pos":"VER"} ,
		{"word":"regroupées","word_nosc":"regroupees","lemma":"regrouper","pos":"VER"} ,
		{"word":"regroupés","word_nosc":"regroupes","lemma":"regrouper","pos":"VER"} ,
		{"word":"regréaient","word_nosc":"regreaient","lemma":"regréer","pos":"VER"} ,
		{"word":"regréer","word_nosc":"regreer","lemma":"regréer","pos":"VER"} ,
		{"word":"regréé","word_nosc":"regree","lemma":"regréer","pos":"VER"} ,
		{"word":"rehaussaient","word_nosc":"rehaussaient","lemma":"rehausser","pos":"VER"} ,
		{"word":"rehaussait","word_nosc":"rehaussait","lemma":"rehausser","pos":"VER"} ,
		{"word":"rehaussant","word_nosc":"rehaussant","lemma":"rehausser","pos":"VER"} ,
		{"word":"rehausse","word_nosc":"rehausse","lemma":"rehausser","pos":"VER"} ,
		{"word":"rehaussent","word_nosc":"rehaussent","lemma":"rehausser","pos":"VER"} ,
		{"word":"rehausser","word_nosc":"rehausser","lemma":"rehausser","pos":"VER"} ,
		{"word":"rehaussera","word_nosc":"rehaussera","lemma":"rehausser","pos":"VER"} ,
		{"word":"rehaussez","word_nosc":"rehaussez","lemma":"rehausser","pos":"VER"} ,
		{"word":"rehaussé","word_nosc":"rehausse","lemma":"rehausser","pos":"VER"} ,
		{"word":"rehaussée","word_nosc":"rehaussee","lemma":"rehausser","pos":"VER"} ,
		{"word":"rehaussées","word_nosc":"rehaussees","lemma":"rehausser","pos":"VER"} ,
		{"word":"rehaussés","word_nosc":"rehausses","lemma":"rehausser","pos":"VER"} ,
		{"word":"rejailli","word_nosc":"rejailli","lemma":"rejaillir","pos":"VER"} ,
		{"word":"rejaillir","word_nosc":"rejaillir","lemma":"rejaillir","pos":"VER"} ,
		{"word":"rejailliraient","word_nosc":"rejailliraient","lemma":"rejaillir","pos":"VER"} ,
		{"word":"rejaillirait","word_nosc":"rejaillirait","lemma":"rejaillir","pos":"VER"} ,
		{"word":"rejaillissaient","word_nosc":"rejaillissaient","lemma":"rejaillir","pos":"VER"} ,
		{"word":"rejaillissait","word_nosc":"rejaillissait","lemma":"rejaillir","pos":"VER"} ,
		{"word":"rejaillissant","word_nosc":"rejaillissant","lemma":"rejaillir","pos":"VER"} ,
		{"word":"rejaillissent","word_nosc":"rejaillissent","lemma":"rejaillir","pos":"VER"} ,
		{"word":"rejaillit","word_nosc":"rejaillit","lemma":"rejaillir","pos":"VER"} ,
		{"word":"rejeta","word_nosc":"rejeta","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetai","word_nosc":"rejetai","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetaient","word_nosc":"rejetaient","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetais","word_nosc":"rejetais","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetait","word_nosc":"rejetait","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetant","word_nosc":"rejetant","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejeter","word_nosc":"rejeter","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetez","word_nosc":"rejetez","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetiez","word_nosc":"rejetiez","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetions","word_nosc":"rejetions","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetons","word_nosc":"rejetons","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejette","word_nosc":"rejette","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejettent","word_nosc":"rejettent","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejettera","word_nosc":"rejettera","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetterai","word_nosc":"rejetterai","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetteraient","word_nosc":"rejetteraient","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetterais","word_nosc":"rejetterais","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetterait","word_nosc":"rejetterait","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetteras","word_nosc":"rejetteras","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetteront","word_nosc":"rejetteront","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejettes","word_nosc":"rejettes","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetât","word_nosc":"rejetat","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetèrent","word_nosc":"rejeterent","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejeté","word_nosc":"rejete","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetée","word_nosc":"rejetee","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetées","word_nosc":"rejetees","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejetés","word_nosc":"rejetes","lemma":"rejeter","pos":"VER"} ,
		{"word":"rejoignaient","word_nosc":"rejoignaient","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoignais","word_nosc":"rejoignais","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoignait","word_nosc":"rejoignait","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoignant","word_nosc":"rejoignant","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoigne","word_nosc":"rejoigne","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoignent","word_nosc":"rejoignent","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoignes","word_nosc":"rejoignes","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoignez","word_nosc":"rejoignez","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoigniez","word_nosc":"rejoigniez","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoignions","word_nosc":"rejoignions","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoignirent","word_nosc":"rejoignirent","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoignis","word_nosc":"rejoignis","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoignisse","word_nosc":"rejoignisse","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoignissent","word_nosc":"rejoignissent","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoignit","word_nosc":"rejoignit","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoignons","word_nosc":"rejoignons","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoignîmes","word_nosc":"rejoignimes","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoignît","word_nosc":"rejoignit","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoindra","word_nosc":"rejoindra","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoindrai","word_nosc":"rejoindrai","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoindraient","word_nosc":"rejoindraient","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoindrais","word_nosc":"rejoindrais","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoindrait","word_nosc":"rejoindrait","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoindras","word_nosc":"rejoindras","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoindre","word_nosc":"rejoindre","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoindrez","word_nosc":"rejoindrez","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoindrions","word_nosc":"rejoindrions","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoindrons","word_nosc":"rejoindrons","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoindront","word_nosc":"rejoindront","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoins","word_nosc":"rejoins","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoint","word_nosc":"rejoint","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejointe","word_nosc":"rejointe","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejointes","word_nosc":"rejointes","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejointoyer","word_nosc":"rejointoyer","lemma":"rejointoyer","pos":"VER"} ,
		{"word":"rejointoyé","word_nosc":"rejointoye","lemma":"rejointoyer","pos":"VER"} ,
		{"word":"rejointoyés","word_nosc":"rejointoyes","lemma":"rejointoyer","pos":"VER"} ,
		{"word":"rejoints","word_nosc":"rejoints","lemma":"rejoindre","pos":"VER"} ,
		{"word":"rejoua","word_nosc":"rejoua","lemma":"rejouer","pos":"VER"} ,
		{"word":"rejouaient","word_nosc":"rejouaient","lemma":"rejouer","pos":"VER"} ,
		{"word":"rejouais","word_nosc":"rejouais","lemma":"rejouer","pos":"VER"} ,
		{"word":"rejouant","word_nosc":"rejouant","lemma":"rejouer","pos":"VER"} ,
		{"word":"rejoue","word_nosc":"rejoue","lemma":"rejouer","pos":"VER"} ,
		{"word":"rejouent","word_nosc":"rejouent","lemma":"rejouer","pos":"VER"} ,
		{"word":"rejouer","word_nosc":"rejouer","lemma":"rejouer","pos":"VER"} ,
		{"word":"rejouerait","word_nosc":"rejouerait","lemma":"rejouer","pos":"VER"} ,
		{"word":"rejoueras","word_nosc":"rejoueras","lemma":"rejouer","pos":"VER"} ,
		{"word":"rejouerez","word_nosc":"rejouerez","lemma":"rejouer","pos":"VER"} ,
		{"word":"rejouerons","word_nosc":"rejouerons","lemma":"rejouer","pos":"VER"} ,
		{"word":"rejoues","word_nosc":"rejoues","lemma":"rejouer","pos":"VER"} ,
		{"word":"rejouez","word_nosc":"rejouez","lemma":"rejouer","pos":"VER"} ,
		{"word":"rejouons","word_nosc":"rejouons","lemma":"rejouer","pos":"VER"} ,
		{"word":"rejoué","word_nosc":"rejoue","lemma":"rejouer","pos":"VER"} ,
		{"word":"rejuger","word_nosc":"rejuger","lemma":"rejuger","pos":"VER"} ,
		{"word":"rejugez","word_nosc":"rejugez","lemma":"rejuger","pos":"VER"} ,
		{"word":"rejugé","word_nosc":"rejuge","lemma":"rejuger","pos":"VER"} ,
		{"word":"rejugés","word_nosc":"rejuges","lemma":"rejuger","pos":"VER"} ,
		{"word":"relacent","word_nosc":"relacent","lemma":"relacer","pos":"VER"} ,
		{"word":"relacer","word_nosc":"relacer","lemma":"relacer","pos":"VER"} ,
		{"word":"relaie","word_nosc":"relaie","lemma":"relayer","pos":"VER"} ,
		{"word":"relaient","word_nosc":"relaient","lemma":"relayer","pos":"VER"} ,
		{"word":"relaiera","word_nosc":"relaiera","lemma":"relayer","pos":"VER"} ,
		{"word":"relaieraient","word_nosc":"relaieraient","lemma":"relayer","pos":"VER"} ,
		{"word":"relaierait","word_nosc":"relaierait","lemma":"relayer","pos":"VER"} ,
		{"word":"relaieront","word_nosc":"relaieront","lemma":"relayer","pos":"VER"} ,
		{"word":"relaisser","word_nosc":"relaisser","lemma":"relaisser","pos":"VER"} ,
		{"word":"relance","word_nosc":"relance","lemma":"relancer","pos":"VER"} ,
		{"word":"relancent","word_nosc":"relancent","lemma":"relancer","pos":"VER"} ,
		{"word":"relancer","word_nosc":"relancer","lemma":"relancer","pos":"VER"} ,
		{"word":"relancera","word_nosc":"relancera","lemma":"relancer","pos":"VER"} ,
		{"word":"relancerait","word_nosc":"relancerait","lemma":"relancer","pos":"VER"} ,
		{"word":"relanceront","word_nosc":"relanceront","lemma":"relancer","pos":"VER"} ,
		{"word":"relances","word_nosc":"relances","lemma":"relancer","pos":"VER"} ,
		{"word":"relancez","word_nosc":"relancez","lemma":"relancer","pos":"VER"} ,
		{"word":"relancèrent","word_nosc":"relancerent","lemma":"relancer","pos":"VER"} ,
		{"word":"relancé","word_nosc":"relance","lemma":"relancer","pos":"VER"} ,
		{"word":"relancée","word_nosc":"relancee","lemma":"relancer","pos":"VER"} ,
		{"word":"relancées","word_nosc":"relancees","lemma":"relancer","pos":"VER"} ,
		{"word":"relancés","word_nosc":"relances","lemma":"relancer","pos":"VER"} ,
		{"word":"relança","word_nosc":"relanca","lemma":"relancer","pos":"VER"} ,
		{"word":"relançai","word_nosc":"relancai","lemma":"relancer","pos":"VER"} ,
		{"word":"relançaient","word_nosc":"relancaient","lemma":"relancer","pos":"VER"} ,
		{"word":"relançais","word_nosc":"relancais","lemma":"relancer","pos":"VER"} ,
		{"word":"relançait","word_nosc":"relancait","lemma":"relancer","pos":"VER"} ,
		{"word":"relançant","word_nosc":"relancant","lemma":"relancer","pos":"VER"} ,
		{"word":"relançons","word_nosc":"relancons","lemma":"relancer","pos":"VER"} ,
		{"word":"relançât","word_nosc":"relancat","lemma":"relancer","pos":"VER"} ,
		{"word":"relata","word_nosc":"relata","lemma":"relater","pos":"VER"} ,
		{"word":"relatai","word_nosc":"relatai","lemma":"relater","pos":"VER"} ,
		{"word":"relataient","word_nosc":"relataient","lemma":"relater","pos":"VER"} ,
		{"word":"relatais","word_nosc":"relatais","lemma":"relater","pos":"VER"} ,
		{"word":"relatait","word_nosc":"relatait","lemma":"relater","pos":"VER"} ,
		{"word":"relatant","word_nosc":"relatant","lemma":"relater","pos":"VER"} ,
		{"word":"relate","word_nosc":"relate","lemma":"relater","pos":"VER"} ,
		{"word":"relatent","word_nosc":"relatent","lemma":"relater","pos":"VER"} ,
		{"word":"relater","word_nosc":"relater","lemma":"relater","pos":"VER"} ,
		{"word":"relateraient","word_nosc":"relateraient","lemma":"relater","pos":"VER"} ,
		{"word":"relateront","word_nosc":"relateront","lemma":"relater","pos":"VER"} ,
		{"word":"relations","word_nosc":"relations","lemma":"relater","pos":"VER"} ,
		{"word":"relativisant","word_nosc":"relativisant","lemma":"relativiser","pos":"VER"} ,
		{"word":"relativise","word_nosc":"relativise","lemma":"relativiser","pos":"VER"} ,
		{"word":"relativisent","word_nosc":"relativisent","lemma":"relativiser","pos":"VER"} ,
		{"word":"relativiser","word_nosc":"relativiser","lemma":"relativiser","pos":"VER"} ,
		{"word":"relativisé","word_nosc":"relativise","lemma":"relativiser","pos":"VER"} ,
		{"word":"relaté","word_nosc":"relate","lemma":"relater","pos":"VER"} ,
		{"word":"relatée","word_nosc":"relatee","lemma":"relater","pos":"VER"} ,
		{"word":"relatées","word_nosc":"relatees","lemma":"relater","pos":"VER"} ,
		{"word":"relatés","word_nosc":"relates","lemma":"relater","pos":"VER"} ,
		{"word":"relavait","word_nosc":"relavait","lemma":"relaver","pos":"VER"} ,
		{"word":"relave","word_nosc":"relave","lemma":"relaver","pos":"VER"} ,
		{"word":"relaver","word_nosc":"relaver","lemma":"relaver","pos":"VER"} ,
		{"word":"relavés","word_nosc":"relaves","lemma":"relaver","pos":"VER"} ,
		{"word":"relaxais","word_nosc":"relaxais","lemma":"relaxer","pos":"VER"} ,
		{"word":"relaxant","word_nosc":"relaxant","lemma":"relaxer","pos":"VER"} ,
		{"word":"relaxe","word_nosc":"relaxe","lemma":"relaxer","pos":"VER"} ,
		{"word":"relaxer","word_nosc":"relaxer","lemma":"relaxer","pos":"VER"} ,
		{"word":"relaxes","word_nosc":"relaxes","lemma":"relaxer","pos":"VER"} ,
		{"word":"relaxez","word_nosc":"relaxez","lemma":"relaxer","pos":"VER"} ,
		{"word":"relaxons","word_nosc":"relaxons","lemma":"relaxer","pos":"VER"} ,
		{"word":"relaxé","word_nosc":"relaxe","lemma":"relaxer","pos":"VER"} ,
		{"word":"relaxée","word_nosc":"relaxee","lemma":"relaxer","pos":"VER"} ,
		{"word":"relaya","word_nosc":"relaya","lemma":"relayer","pos":"VER"} ,
		{"word":"relayaient","word_nosc":"relayaient","lemma":"relayer","pos":"VER"} ,
		{"word":"relayait","word_nosc":"relayait","lemma":"relayer","pos":"VER"} ,
		{"word":"relayant","word_nosc":"relayant","lemma":"relayer","pos":"VER"} ,
		{"word":"relaye","word_nosc":"relaye","lemma":"relayer","pos":"VER"} ,
		{"word":"relayent","word_nosc":"relayent","lemma":"relayer","pos":"VER"} ,
		{"word":"relayer","word_nosc":"relayer","lemma":"relayer","pos":"VER"} ,
		{"word":"relayera","word_nosc":"relayera","lemma":"relayer","pos":"VER"} ,
		{"word":"relayerai","word_nosc":"relayerai","lemma":"relayer","pos":"VER"} ,
		{"word":"relayez","word_nosc":"relayez","lemma":"relayer","pos":"VER"} ,
		{"word":"relayions","word_nosc":"relayions","lemma":"relayer","pos":"VER"} ,
		{"word":"relayons","word_nosc":"relayons","lemma":"relayer","pos":"VER"} ,
		{"word":"relayâmes","word_nosc":"relayames","lemma":"relayer","pos":"VER"} ,
		{"word":"relayèrent","word_nosc":"relayerent","lemma":"relayer","pos":"VER"} ,
		{"word":"relayé","word_nosc":"relaye","lemma":"relayer","pos":"VER"} ,
		{"word":"relayée","word_nosc":"relayee","lemma":"relayer","pos":"VER"} ,
		{"word":"relayées","word_nosc":"relayees","lemma":"relayer","pos":"VER"} ,
		{"word":"relayés","word_nosc":"relayes","lemma":"relayer","pos":"VER"} ,
		{"word":"relaça","word_nosc":"relaca","lemma":"relacer","pos":"VER"} ,
		{"word":"relaçaient","word_nosc":"relacaient","lemma":"relacer","pos":"VER"} ,
		{"word":"relaçait","word_nosc":"relacait","lemma":"relacer","pos":"VER"} ,
		{"word":"releva","word_nosc":"releva","lemma":"relever","pos":"VER"} ,
		{"word":"relevai","word_nosc":"relevai","lemma":"relever","pos":"VER"} ,
		{"word":"relevaient","word_nosc":"relevaient","lemma":"relever","pos":"VER"} ,
		{"word":"relevais","word_nosc":"relevais","lemma":"relever","pos":"VER"} ,
		{"word":"relevait","word_nosc":"relevait","lemma":"relever","pos":"VER"} ,
		{"word":"relevant","word_nosc":"relevant","lemma":"relever","pos":"VER"} ,
		{"word":"relever","word_nosc":"relever","lemma":"relever","pos":"VER"} ,
		{"word":"relevez","word_nosc":"relevez","lemma":"relever","pos":"VER"} ,
		{"word":"releviez","word_nosc":"releviez","lemma":"relever","pos":"VER"} ,
		{"word":"relevions","word_nosc":"relevions","lemma":"relever","pos":"VER"} ,
		{"word":"relevons","word_nosc":"relevons","lemma":"relever","pos":"VER"} ,
		{"word":"relevâmes","word_nosc":"relevames","lemma":"relever","pos":"VER"} ,
		{"word":"relevât","word_nosc":"relevat","lemma":"relever","pos":"VER"} ,
		{"word":"relevèrent","word_nosc":"releverent","lemma":"relever","pos":"VER"} ,
		{"word":"relevé","word_nosc":"releve","lemma":"relever","pos":"VER"} ,
		{"word":"relevée","word_nosc":"relevee","lemma":"relever","pos":"VER"} ,
		{"word":"relevées","word_nosc":"relevees","lemma":"relever","pos":"VER"} ,
		{"word":"relevés","word_nosc":"releves","lemma":"relever","pos":"VER"} ,
		{"word":"reliaient","word_nosc":"reliaient","lemma":"relier","pos":"VER"} ,
		{"word":"reliait","word_nosc":"reliait","lemma":"relier","pos":"VER"} ,
		{"word":"reliant","word_nosc":"reliant","lemma":"relier","pos":"VER"} ,
		{"word":"relie","word_nosc":"relie","lemma":"relier","pos":"VER"} ,
		{"word":"relient","word_nosc":"relient","lemma":"relier","pos":"VER"} ,
		{"word":"relier","word_nosc":"relier","lemma":"relier","pos":"VER"} ,
		{"word":"reliera","word_nosc":"reliera","lemma":"relier","pos":"VER"} ,
		{"word":"reliez","word_nosc":"reliez","lemma":"relier","pos":"VER"} ,
		{"word":"relirai","word_nosc":"relirai","lemma":"relire","pos":"VER"} ,
		{"word":"relirais","word_nosc":"relirais","lemma":"relire","pos":"VER"} ,
		{"word":"relirait","word_nosc":"relirait","lemma":"relire","pos":"VER"} ,
		{"word":"reliras","word_nosc":"reliras","lemma":"relire","pos":"VER"} ,
		{"word":"relire","word_nosc":"relire","lemma":"relire","pos":"VER"} ,
		{"word":"relirez","word_nosc":"relirez","lemma":"relire","pos":"VER"} ,
		{"word":"relirons","word_nosc":"relirons","lemma":"relire","pos":"VER"} ,
		{"word":"relis","word_nosc":"relis","lemma":"relire","pos":"VER"} ,
		{"word":"relisaient","word_nosc":"relisaient","lemma":"relire","pos":"VER"} ,
		{"word":"relisais","word_nosc":"relisais","lemma":"relire","pos":"VER"} ,
		{"word":"relisait","word_nosc":"relisait","lemma":"relire","pos":"VER"} ,
		{"word":"relisant","word_nosc":"relisant","lemma":"relire","pos":"VER"} ,
		{"word":"relise","word_nosc":"relise","lemma":"relire","pos":"VER"} ,
		{"word":"relisez","word_nosc":"relisez","lemma":"relire","pos":"VER"} ,
		{"word":"relisions","word_nosc":"relisions","lemma":"relire","pos":"VER"} ,
		{"word":"relit","word_nosc":"relit","lemma":"relire","pos":"VER"} ,
		{"word":"reliât","word_nosc":"reliat","lemma":"relier","pos":"VER"} ,
		{"word":"relié","word_nosc":"relie","lemma":"relier","pos":"VER"} ,
		{"word":"reliée","word_nosc":"reliee","lemma":"relier","pos":"VER"} ,
		{"word":"reliées","word_nosc":"reliees","lemma":"relier","pos":"VER"} ,
		{"word":"reliés","word_nosc":"relies","lemma":"relier","pos":"VER"} ,
		{"word":"reloge","word_nosc":"reloge","lemma":"reloger","pos":"VER"} ,
		{"word":"reloger","word_nosc":"reloger","lemma":"reloger","pos":"VER"} ,
		{"word":"relogerais","word_nosc":"relogerais","lemma":"reloger","pos":"VER"} ,
		{"word":"relogé","word_nosc":"reloge","lemma":"reloger","pos":"VER"} ,
		{"word":"relooker","word_nosc":"relooker","lemma":"relooker","pos":"VER"} ,
		{"word":"relookerai","word_nosc":"relookerai","lemma":"relooker","pos":"VER"} ,
		{"word":"relooké","word_nosc":"relooke","lemma":"relooker","pos":"VER"} ,
		{"word":"relookée","word_nosc":"relookee","lemma":"relooker","pos":"VER"} ,
		{"word":"reloquer","word_nosc":"reloquer","lemma":"reloquer","pos":"VER"} ,
		{"word":"relouer","word_nosc":"relouer","lemma":"relouer","pos":"VER"} ,
		{"word":"relouerons","word_nosc":"relouerons","lemma":"relouer","pos":"VER"} ,
		{"word":"relourde","word_nosc":"relourde","lemma":"relourder","pos":"VER"} ,
		{"word":"relourder","word_nosc":"relourder","lemma":"relourder","pos":"VER"} ,
		{"word":"relourdé","word_nosc":"relourde","lemma":"relourder","pos":"VER"} ,
		{"word":"reloué","word_nosc":"reloue","lemma":"relouer","pos":"VER"} ,
		{"word":"relu","word_nosc":"relu","lemma":"relire","pos":"VER"} ,
		{"word":"relue","word_nosc":"relue","lemma":"relire","pos":"VER"} ,
		{"word":"relues","word_nosc":"relues","lemma":"relire","pos":"VER"} ,
		{"word":"relui","word_nosc":"relui","lemma":"reluire","pos":"VER"} ,
		{"word":"reluire","word_nosc":"reluire","lemma":"reluire","pos":"VER"} ,
		{"word":"reluiront","word_nosc":"reluiront","lemma":"reluire","pos":"VER"} ,
		{"word":"reluis","word_nosc":"reluis","lemma":"reluire","pos":"VER"} ,
		{"word":"reluisaient","word_nosc":"reluisaient","lemma":"reluire","pos":"VER"} ,
		{"word":"reluisais","word_nosc":"reluisais","lemma":"reluire","pos":"VER"} ,
		{"word":"reluisant","word_nosc":"reluisant","lemma":"reluire","pos":"VER"} ,
		{"word":"reluise","word_nosc":"reluise","lemma":"reluire","pos":"VER"} ,
		{"word":"reluisent","word_nosc":"reluisent","lemma":"reluire","pos":"VER"} ,
		{"word":"reluit","word_nosc":"reluit","lemma":"reluire","pos":"VER"} ,
		{"word":"reluqua","word_nosc":"reluqua","lemma":"reluquer","pos":"VER"} ,
		{"word":"reluquaient","word_nosc":"reluquaient","lemma":"reluquer","pos":"VER"} ,
		{"word":"reluquais","word_nosc":"reluquais","lemma":"reluquer","pos":"VER"} ,
		{"word":"reluquait","word_nosc":"reluquait","lemma":"reluquer","pos":"VER"} ,
		{"word":"reluquant","word_nosc":"reluquant","lemma":"reluquer","pos":"VER"} ,
		{"word":"reluque","word_nosc":"reluque","lemma":"reluquer","pos":"VER"} ,
		{"word":"reluquent","word_nosc":"reluquent","lemma":"reluquer","pos":"VER"} ,
		{"word":"reluquer","word_nosc":"reluquer","lemma":"reluquer","pos":"VER"} ,
		{"word":"reluques","word_nosc":"reluques","lemma":"reluquer","pos":"VER"} ,
		{"word":"reluquiez","word_nosc":"reluquiez","lemma":"reluquer","pos":"VER"} ,
		{"word":"reluqué","word_nosc":"reluque","lemma":"reluquer","pos":"VER"} ,
		{"word":"reluquée","word_nosc":"reluquee","lemma":"reluquer","pos":"VER"} ,
		{"word":"reluqués","word_nosc":"reluques","lemma":"reluquer","pos":"VER"} ,
		{"word":"relus","word_nosc":"relus","lemma":"relire","pos":"VER"} ,
		{"word":"relut","word_nosc":"relut","lemma":"relire","pos":"VER"} ,
		{"word":"relâcha","word_nosc":"relacha","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâchai","word_nosc":"relachai","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâchaient","word_nosc":"relachaient","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâchais","word_nosc":"relachais","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâchait","word_nosc":"relachait","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâchant","word_nosc":"relachant","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâche","word_nosc":"relache","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâchent","word_nosc":"relachent","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâcher","word_nosc":"relacher","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâchera","word_nosc":"relachera","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâcherai","word_nosc":"relacherai","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâcheraient","word_nosc":"relacheraient","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâcherait","word_nosc":"relacherait","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâcheras","word_nosc":"relacheras","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâcherez","word_nosc":"relacherez","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâcherons","word_nosc":"relacherons","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâcheront","word_nosc":"relacheront","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâchez","word_nosc":"relachez","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâchiez","word_nosc":"relachiez","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâchons","word_nosc":"relachons","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâchèrent","word_nosc":"relacherent","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâché","word_nosc":"relache","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâchée","word_nosc":"relachee","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâchées","word_nosc":"relachees","lemma":"relâcher","pos":"VER"} ,
		{"word":"relâchés","word_nosc":"relaches","lemma":"relâcher","pos":"VER"} ,
		{"word":"relègue","word_nosc":"relegue","lemma":"reléguer","pos":"VER"} ,
		{"word":"relèguent","word_nosc":"releguent","lemma":"reléguer","pos":"VER"} ,
		{"word":"relève","word_nosc":"releve","lemma":"relever","pos":"VER"} ,
		{"word":"relèvent","word_nosc":"relevent","lemma":"relever","pos":"VER"} ,
		{"word":"relèvera","word_nosc":"relevera","lemma":"relever","pos":"VER"} ,
		{"word":"relèverai","word_nosc":"releverai","lemma":"relever","pos":"VER"} ,
		{"word":"relèveraient","word_nosc":"releveraient","lemma":"relever","pos":"VER"} ,
		{"word":"relèverais","word_nosc":"releverais","lemma":"relever","pos":"VER"} ,
		{"word":"relèverait","word_nosc":"releverait","lemma":"relever","pos":"VER"} ,
		{"word":"relèveras","word_nosc":"releveras","lemma":"relever","pos":"VER"} ,
		{"word":"relèverez","word_nosc":"releverez","lemma":"relever","pos":"VER"} ,
		{"word":"relèverions","word_nosc":"releverions","lemma":"relever","pos":"VER"} ,
		{"word":"relèverons","word_nosc":"releverons","lemma":"relever","pos":"VER"} ,
		{"word":"relèveront","word_nosc":"releveront","lemma":"relever","pos":"VER"} ,
		{"word":"relèves","word_nosc":"releves","lemma":"relever","pos":"VER"} ,
		{"word":"relégua","word_nosc":"relegua","lemma":"reléguer","pos":"VER"} ,
		{"word":"reléguai","word_nosc":"releguai","lemma":"reléguer","pos":"VER"} ,
		{"word":"reléguaient","word_nosc":"releguaient","lemma":"reléguer","pos":"VER"} ,
		{"word":"reléguait","word_nosc":"releguait","lemma":"reléguer","pos":"VER"} ,
		{"word":"reléguant","word_nosc":"releguant","lemma":"reléguer","pos":"VER"} ,
		{"word":"reléguer","word_nosc":"releguer","lemma":"reléguer","pos":"VER"} ,
		{"word":"reléguera","word_nosc":"releguera","lemma":"reléguer","pos":"VER"} ,
		{"word":"relégueraient","word_nosc":"relegueraient","lemma":"reléguer","pos":"VER"} ,
		{"word":"reléguerait","word_nosc":"releguerait","lemma":"reléguer","pos":"VER"} ,
		{"word":"reléguons","word_nosc":"releguons","lemma":"reléguer","pos":"VER"} ,
		{"word":"reléguèrent","word_nosc":"releguerent","lemma":"reléguer","pos":"VER"} ,
		{"word":"relégué","word_nosc":"relegue","lemma":"reléguer","pos":"VER"} ,
		{"word":"reléguée","word_nosc":"releguee","lemma":"reléguer","pos":"VER"} ,
		{"word":"reléguées","word_nosc":"releguees","lemma":"reléguer","pos":"VER"} ,
		{"word":"relégués","word_nosc":"relegues","lemma":"reléguer","pos":"VER"} ,
		{"word":"remaigrir","word_nosc":"remaigrir","lemma":"remaigrir","pos":"VER"} ,
		{"word":"remaillaient","word_nosc":"remaillaient","lemma":"remailler","pos":"VER"} ,
		{"word":"remaillant","word_nosc":"remaillant","lemma":"remailler","pos":"VER"} ,
		{"word":"remailler","word_nosc":"remailler","lemma":"remailler","pos":"VER"} ,
		{"word":"remaillés","word_nosc":"remailles","lemma":"remailler","pos":"VER"} ,
		{"word":"remange","word_nosc":"remange","lemma":"remanger","pos":"VER"} ,
		{"word":"remanger","word_nosc":"remanger","lemma":"remanger","pos":"VER"} ,
		{"word":"remangerait","word_nosc":"remangerait","lemma":"remanger","pos":"VER"} ,
		{"word":"remania","word_nosc":"remania","lemma":"remanier","pos":"VER"} ,
		{"word":"remaniait","word_nosc":"remaniait","lemma":"remanier","pos":"VER"} ,
		{"word":"remaniant","word_nosc":"remaniant","lemma":"remanier","pos":"VER"} ,
		{"word":"remanie","word_nosc":"remanie","lemma":"remanier","pos":"VER"} ,
		{"word":"remanier","word_nosc":"remanier","lemma":"remanier","pos":"VER"} ,
		{"word":"remanié","word_nosc":"remanie","lemma":"remanier","pos":"VER"} ,
		{"word":"remaniée","word_nosc":"remaniee","lemma":"remanier","pos":"VER"} ,
		{"word":"remaniés","word_nosc":"remanies","lemma":"remanier","pos":"VER"} ,
		{"word":"remaquilla","word_nosc":"remaquilla","lemma":"remaquiller","pos":"VER"} ,
		{"word":"remaquillait","word_nosc":"remaquillait","lemma":"remaquiller","pos":"VER"} ,
		{"word":"remaquille","word_nosc":"remaquille","lemma":"remaquiller","pos":"VER"} ,
		{"word":"remaquiller","word_nosc":"remaquiller","lemma":"remaquiller","pos":"VER"} ,
		{"word":"remaquillé","word_nosc":"remaquille","lemma":"remaquiller","pos":"VER"} ,
		{"word":"remaquillée","word_nosc":"remaquillee","lemma":"remaquiller","pos":"VER"} ,
		{"word":"remarchait","word_nosc":"remarchait","lemma":"remarcher","pos":"VER"} ,
		{"word":"remarche","word_nosc":"remarche","lemma":"remarcher","pos":"VER"} ,
		{"word":"remarchent","word_nosc":"remarchent","lemma":"remarcher","pos":"VER"} ,
		{"word":"remarcher","word_nosc":"remarcher","lemma":"remarcher","pos":"VER"} ,
		{"word":"remarchera","word_nosc":"remarchera","lemma":"remarcher","pos":"VER"} ,
		{"word":"remarcheras","word_nosc":"remarcheras","lemma":"remarcher","pos":"VER"} ,
		{"word":"remaria","word_nosc":"remaria","lemma":"remarier","pos":"VER"} ,
		{"word":"remariaient","word_nosc":"remariaient","lemma":"remarier","pos":"VER"} ,
		{"word":"remariait","word_nosc":"remariait","lemma":"remarier","pos":"VER"} ,
		{"word":"remariant","word_nosc":"remariant","lemma":"remarier","pos":"VER"} ,
		{"word":"remarie","word_nosc":"remarie","lemma":"remarier","pos":"VER"} ,
		{"word":"remarient","word_nosc":"remarient","lemma":"remarier","pos":"VER"} ,
		{"word":"remarier","word_nosc":"remarier","lemma":"remarier","pos":"VER"} ,
		{"word":"remariera","word_nosc":"remariera","lemma":"remarier","pos":"VER"} ,
		{"word":"remarierai","word_nosc":"remarierai","lemma":"remarier","pos":"VER"} ,
		{"word":"remarierais","word_nosc":"remarierais","lemma":"remarier","pos":"VER"} ,
		{"word":"remarierait","word_nosc":"remarierait","lemma":"remarier","pos":"VER"} ,
		{"word":"remarieras","word_nosc":"remarieras","lemma":"remarier","pos":"VER"} ,
		{"word":"remarierez","word_nosc":"remarierez","lemma":"remarier","pos":"VER"} ,
		{"word":"remarieront","word_nosc":"remarieront","lemma":"remarier","pos":"VER"} ,
		{"word":"remaries","word_nosc":"remaries","lemma":"remarier","pos":"VER"} ,
		{"word":"remariez","word_nosc":"remariez","lemma":"remarier","pos":"VER"} ,
		{"word":"remariions","word_nosc":"remariions","lemma":"remarier","pos":"VER"} ,
		{"word":"remarié","word_nosc":"remarie","lemma":"remarier","pos":"VER"} ,
		{"word":"remariée","word_nosc":"remariee","lemma":"remarier","pos":"VER"} ,
		{"word":"remariés","word_nosc":"remaries","lemma":"remarier","pos":"VER"} ,
		{"word":"remarqua","word_nosc":"remarqua","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquai","word_nosc":"remarquai","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquaient","word_nosc":"remarquaient","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquais","word_nosc":"remarquais","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquait","word_nosc":"remarquait","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquant","word_nosc":"remarquant","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquas","word_nosc":"remarquas","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarque","word_nosc":"remarque","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquent","word_nosc":"remarquent","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquer","word_nosc":"remarquer","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquera","word_nosc":"remarquera","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquerai","word_nosc":"remarquerai","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarqueraient","word_nosc":"remarqueraient","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquerais","word_nosc":"remarquerais","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquerait","word_nosc":"remarquerait","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarqueras","word_nosc":"remarqueras","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquerez","word_nosc":"remarquerez","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarqueriez","word_nosc":"remarqueriez","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarqueront","word_nosc":"remarqueront","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarques","word_nosc":"remarques","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquez","word_nosc":"remarquez","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquiez","word_nosc":"remarquiez","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquions","word_nosc":"remarquions","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquons","word_nosc":"remarquons","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquâmes","word_nosc":"remarquames","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquât","word_nosc":"remarquat","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquèrent","word_nosc":"remarquerent","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarqué","word_nosc":"remarque","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquée","word_nosc":"remarquee","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarquées","word_nosc":"remarquees","lemma":"remarquer","pos":"VER"} ,
		{"word":"remarqués","word_nosc":"remarques","lemma":"remarquer","pos":"VER"} ,
		{"word":"remballa","word_nosc":"remballa","lemma":"remballer","pos":"VER"} ,
		{"word":"remballaient","word_nosc":"remballaient","lemma":"remballer","pos":"VER"} ,
		{"word":"remballait","word_nosc":"remballait","lemma":"remballer","pos":"VER"} ,
		{"word":"remballant","word_nosc":"remballant","lemma":"remballer","pos":"VER"} ,
		{"word":"remballe","word_nosc":"remballe","lemma":"remballer","pos":"VER"} ,
		{"word":"remballent","word_nosc":"remballent","lemma":"remballer","pos":"VER"} ,
		{"word":"remballer","word_nosc":"remballer","lemma":"remballer","pos":"VER"} ,
		{"word":"remballez","word_nosc":"remballez","lemma":"remballer","pos":"VER"} ,
		{"word":"remballé","word_nosc":"remballe","lemma":"remballer","pos":"VER"} ,
		{"word":"remballés","word_nosc":"remballes","lemma":"remballer","pos":"VER"} ,
		{"word":"rembarqua","word_nosc":"rembarqua","lemma":"rembarquer","pos":"VER"} ,
		{"word":"rembarquaient","word_nosc":"rembarquaient","lemma":"rembarquer","pos":"VER"} ,
		{"word":"rembarque","word_nosc":"rembarque","lemma":"rembarquer","pos":"VER"} ,
		{"word":"rembarquer","word_nosc":"rembarquer","lemma":"rembarquer","pos":"VER"} ,
		{"word":"rembarquez","word_nosc":"rembarquez","lemma":"rembarquer","pos":"VER"} ,
		{"word":"rembarquèrent","word_nosc":"rembarquerent","lemma":"rembarquer","pos":"VER"} ,
		{"word":"rembarqué","word_nosc":"rembarque","lemma":"rembarquer","pos":"VER"} ,
		{"word":"rembarquées","word_nosc":"rembarquees","lemma":"rembarquer","pos":"VER"} ,
		{"word":"rembarra","word_nosc":"rembarra","lemma":"rembarrer","pos":"VER"} ,
		{"word":"rembarrais","word_nosc":"rembarrais","lemma":"rembarrer","pos":"VER"} ,
		{"word":"rembarrait","word_nosc":"rembarrait","lemma":"rembarrer","pos":"VER"} ,
		{"word":"rembarre","word_nosc":"rembarre","lemma":"rembarrer","pos":"VER"} ,
		{"word":"rembarrer","word_nosc":"rembarrer","lemma":"rembarrer","pos":"VER"} ,
		{"word":"rembarres","word_nosc":"rembarres","lemma":"rembarrer","pos":"VER"} ,
		{"word":"rembarré","word_nosc":"rembarre","lemma":"rembarrer","pos":"VER"} ,
		{"word":"rembarrée","word_nosc":"rembarree","lemma":"rembarrer","pos":"VER"} ,
		{"word":"rembauche","word_nosc":"rembauche","lemma":"rembaucher","pos":"VER"} ,
		{"word":"rembauchés","word_nosc":"rembauches","lemma":"rembaucher","pos":"VER"} ,
		{"word":"remblaya","word_nosc":"remblaya","lemma":"remblayer","pos":"VER"} ,
		{"word":"remblayer","word_nosc":"remblayer","lemma":"remblayer","pos":"VER"} ,
		{"word":"remblayé","word_nosc":"remblaye","lemma":"remblayer","pos":"VER"} ,
		{"word":"remblayée","word_nosc":"remblayee","lemma":"remblayer","pos":"VER"} ,
		{"word":"rembobine","word_nosc":"rembobine","lemma":"rembobiner","pos":"VER"} ,
		{"word":"rembobiner","word_nosc":"rembobiner","lemma":"rembobiner","pos":"VER"} ,
		{"word":"rembobinez","word_nosc":"rembobinez","lemma":"rembobiner","pos":"VER"} ,
		{"word":"rembourraient","word_nosc":"rembourraient","lemma":"rembourrer","pos":"VER"} ,
		{"word":"rembourrait","word_nosc":"rembourrait","lemma":"rembourrer","pos":"VER"} ,
		{"word":"rembourrant","word_nosc":"rembourrant","lemma":"rembourrer","pos":"VER"} ,
		{"word":"rembourre","word_nosc":"rembourre","lemma":"rembourrer","pos":"VER"} ,
		{"word":"rembourrer","word_nosc":"rembourrer","lemma":"rembourrer","pos":"VER"} ,
		{"word":"rembourré","word_nosc":"rembourre","lemma":"rembourrer","pos":"VER"} ,
		{"word":"rembourrée","word_nosc":"rembourree","lemma":"rembourrer","pos":"VER"} ,
		{"word":"rembourrées","word_nosc":"rembourrees","lemma":"rembourrer","pos":"VER"} ,
		{"word":"rembourrés","word_nosc":"rembourres","lemma":"rembourrer","pos":"VER"} ,
		{"word":"remboursa","word_nosc":"remboursa","lemma":"rembourser","pos":"VER"} ,
		{"word":"remboursaient","word_nosc":"remboursaient","lemma":"rembourser","pos":"VER"} ,
		{"word":"remboursais","word_nosc":"remboursais","lemma":"rembourser","pos":"VER"} ,
		{"word":"remboursait","word_nosc":"remboursait","lemma":"rembourser","pos":"VER"} ,
		{"word":"remboursant","word_nosc":"remboursant","lemma":"rembourser","pos":"VER"} ,
		{"word":"rembourse","word_nosc":"rembourse","lemma":"rembourser","pos":"VER"} ,
		{"word":"remboursent","word_nosc":"remboursent","lemma":"rembourser","pos":"VER"} ,
		{"word":"rembourser","word_nosc":"rembourser","lemma":"rembourser","pos":"VER"} ,
		{"word":"remboursera","word_nosc":"remboursera","lemma":"rembourser","pos":"VER"} ,
		{"word":"rembourserai","word_nosc":"rembourserai","lemma":"rembourser","pos":"VER"} ,
		{"word":"rembourserais","word_nosc":"rembourserais","lemma":"rembourser","pos":"VER"} ,
		{"word":"rembourserait","word_nosc":"rembourserait","lemma":"rembourser","pos":"VER"} ,
		{"word":"rembourseras","word_nosc":"rembourseras","lemma":"rembourser","pos":"VER"} ,
		{"word":"rembourserez","word_nosc":"rembourserez","lemma":"rembourser","pos":"VER"} ,
		{"word":"rembourserons","word_nosc":"rembourserons","lemma":"rembourser","pos":"VER"} ,
		{"word":"rembourseront","word_nosc":"rembourseront","lemma":"rembourser","pos":"VER"} ,
		{"word":"rembourses","word_nosc":"rembourses","lemma":"rembourser","pos":"VER"} ,
		{"word":"remboursez","word_nosc":"remboursez","lemma":"rembourser","pos":"VER"} ,
		{"word":"remboursons","word_nosc":"remboursons","lemma":"rembourser","pos":"VER"} ,
		{"word":"remboursât","word_nosc":"remboursat","lemma":"rembourser","pos":"VER"} ,
		{"word":"remboursèrent","word_nosc":"rembourserent","lemma":"rembourser","pos":"VER"} ,
		{"word":"remboursé","word_nosc":"rembourse","lemma":"rembourser","pos":"VER"} ,
		{"word":"remboursée","word_nosc":"remboursee","lemma":"rembourser","pos":"VER"} ,
		{"word":"remboursées","word_nosc":"remboursees","lemma":"rembourser","pos":"VER"} ,
		{"word":"remboursés","word_nosc":"rembourses","lemma":"rembourser","pos":"VER"} ,
		{"word":"remboîter","word_nosc":"remboiter","lemma":"remboîter","pos":"VER"} ,
		{"word":"rembruni","word_nosc":"rembruni","lemma":"rembrunir","pos":"VER"} ,
		{"word":"rembrunir","word_nosc":"rembrunir","lemma":"rembrunir","pos":"VER"} ,
		{"word":"rembrunis","word_nosc":"rembrunis","lemma":"rembrunir","pos":"VER"} ,
		{"word":"rembrunissait","word_nosc":"rembrunissait","lemma":"rembrunir","pos":"VER"} ,
		{"word":"rembrunissent","word_nosc":"rembrunissent","lemma":"rembrunir","pos":"VER"} ,
		{"word":"rembrunit","word_nosc":"rembrunit","lemma":"rembrunir","pos":"VER"} ,
		{"word":"remembrés","word_nosc":"remembres","lemma":"remembrer","pos":"VER"} ,
		{"word":"remercia","word_nosc":"remercia","lemma":"remercier","pos":"VER"} ,
		{"word":"remerciai","word_nosc":"remerciai","lemma":"remercier","pos":"VER"} ,
		{"word":"remerciaient","word_nosc":"remerciaient","lemma":"remercier","pos":"VER"} ,
		{"word":"remerciais","word_nosc":"remerciais","lemma":"remercier","pos":"VER"} ,
		{"word":"remerciait","word_nosc":"remerciait","lemma":"remercier","pos":"VER"} ,
		{"word":"remerciant","word_nosc":"remerciant","lemma":"remercier","pos":"VER"} ,
		{"word":"remercie","word_nosc":"remercie","lemma":"remercier","pos":"VER"} ,
		{"word":"remercient","word_nosc":"remercient","lemma":"remercier","pos":"VER"} ,
		{"word":"remercier","word_nosc":"remercier","lemma":"remercier","pos":"VER"} ,
		{"word":"remerciera","word_nosc":"remerciera","lemma":"remercier","pos":"VER"} ,
		{"word":"remercierai","word_nosc":"remercierai","lemma":"remercier","pos":"VER"} ,
		{"word":"remercierais","word_nosc":"remercierais","lemma":"remercier","pos":"VER"} ,
		{"word":"remercierait","word_nosc":"remercierait","lemma":"remercier","pos":"VER"} ,
		{"word":"remercieras","word_nosc":"remercieras","lemma":"remercier","pos":"VER"} ,
		{"word":"remercierez","word_nosc":"remercierez","lemma":"remercier","pos":"VER"} ,
		{"word":"remercierons","word_nosc":"remercierons","lemma":"remercier","pos":"VER"} ,
		{"word":"remercieront","word_nosc":"remercieront","lemma":"remercier","pos":"VER"} ,
		{"word":"remercies","word_nosc":"remercies","lemma":"remercier","pos":"VER"} ,
		{"word":"remerciez","word_nosc":"remerciez","lemma":"remercier","pos":"VER"} ,
		{"word":"remercions","word_nosc":"remercions","lemma":"remercier","pos":"VER"} ,
		{"word":"remerciâmes","word_nosc":"remerciames","lemma":"remercier","pos":"VER"} ,
		{"word":"remercièrent","word_nosc":"remercierent","lemma":"remercier","pos":"VER"} ,
		{"word":"remercié","word_nosc":"remercie","lemma":"remercier","pos":"VER"} ,
		{"word":"remerciée","word_nosc":"remerciee","lemma":"remercier","pos":"VER"} ,
		{"word":"remerciés","word_nosc":"remercies","lemma":"remercier","pos":"VER"} ,
		{"word":"remet","word_nosc":"remet","lemma":"remettre","pos":"VER"} ,
		{"word":"remets","word_nosc":"remets","lemma":"remettre","pos":"VER"} ,
		{"word":"remettaient","word_nosc":"remettaient","lemma":"remettre","pos":"VER"} ,
		{"word":"remettais","word_nosc":"remettais","lemma":"remettre","pos":"VER"} ,
		{"word":"remettait","word_nosc":"remettait","lemma":"remettre","pos":"VER"} ,
		{"word":"remettant","word_nosc":"remettant","lemma":"remettre","pos":"VER"} ,
		{"word":"remette","word_nosc":"remette","lemma":"remettre","pos":"VER"} ,
		{"word":"remettent","word_nosc":"remettent","lemma":"remettre","pos":"VER"} ,
		{"word":"remettes","word_nosc":"remettes","lemma":"remettre","pos":"VER"} ,
		{"word":"remettez","word_nosc":"remettez","lemma":"remettre","pos":"VER"} ,
		{"word":"remettiez","word_nosc":"remettiez","lemma":"remettre","pos":"VER"} ,
		{"word":"remettions","word_nosc":"remettions","lemma":"remettre","pos":"VER"} ,
		{"word":"remettons","word_nosc":"remettons","lemma":"remettre","pos":"VER"} ,
		{"word":"remettra","word_nosc":"remettra","lemma":"remettre","pos":"VER"} ,
		{"word":"remettrai","word_nosc":"remettrai","lemma":"remettre","pos":"VER"} ,
		{"word":"remettraient","word_nosc":"remettraient","lemma":"remettre","pos":"VER"} ,
		{"word":"remettrais","word_nosc":"remettrais","lemma":"remettre","pos":"VER"} ,
		{"word":"remettrait","word_nosc":"remettrait","lemma":"remettre","pos":"VER"} ,
		{"word":"remettras","word_nosc":"remettras","lemma":"remettre","pos":"VER"} ,
		{"word":"remettre","word_nosc":"remettre","lemma":"remettre","pos":"VER"} ,
		{"word":"remettrez","word_nosc":"remettrez","lemma":"remettre","pos":"VER"} ,
		{"word":"remettriez","word_nosc":"remettriez","lemma":"remettre","pos":"VER"} ,
		{"word":"remettrions","word_nosc":"remettrions","lemma":"remettre","pos":"VER"} ,
		{"word":"remettrons","word_nosc":"remettrons","lemma":"remettre","pos":"VER"} ,
		{"word":"remettront","word_nosc":"remettront","lemma":"remettre","pos":"VER"} ,
		{"word":"remeublé","word_nosc":"remeuble","lemma":"remeubler","pos":"VER"} ,
		{"word":"remirent","word_nosc":"remirent","lemma":"remettre","pos":"VER"} ,
		{"word":"remis","word_nosc":"remis","lemma":"remettre","pos":"VER"} ,
		{"word":"remisa","word_nosc":"remisa","lemma":"remiser","pos":"VER"} ,
		{"word":"remisai","word_nosc":"remisai","lemma":"remiser","pos":"VER"} ,
		{"word":"remisaient","word_nosc":"remisaient","lemma":"remiser","pos":"VER"} ,
		{"word":"remisait","word_nosc":"remisait","lemma":"remiser","pos":"VER"} ,
		{"word":"remise","word_nosc":"remise","lemma":"remettre","pos":"VER"} ,
		{"word":"remise","word_nosc":"remise","lemma":"remiser","pos":"VER"} ,
		{"word":"remisent","word_nosc":"remisent","lemma":"remiser","pos":"VER"} ,
		{"word":"remiser","word_nosc":"remiser","lemma":"remiser","pos":"VER"} ,
		{"word":"remises","word_nosc":"remises","lemma":"remettre","pos":"VER"} ,
		{"word":"remises","word_nosc":"remises","lemma":"remiser","pos":"VER"} ,
		{"word":"remisez","word_nosc":"remisez","lemma":"remiser","pos":"VER"} ,
		{"word":"remisons","word_nosc":"remisons","lemma":"remiser","pos":"VER"} ,
		{"word":"remisse","word_nosc":"remisse","lemma":"remettre","pos":"VER"} ,
		{"word":"remisèrent","word_nosc":"remiserent","lemma":"remiser","pos":"VER"} ,
		{"word":"remisé","word_nosc":"remise","lemma":"remiser","pos":"VER"} ,
		{"word":"remisée","word_nosc":"remisee","lemma":"remiser","pos":"VER"} ,
		{"word":"remisés","word_nosc":"remises","lemma":"remiser","pos":"VER"} ,
		{"word":"remit","word_nosc":"remit","lemma":"remettre","pos":"VER"} ,
		{"word":"remixer","word_nosc":"remixer","lemma":"remixer","pos":"VER"} ,
		{"word":"remmenaient","word_nosc":"remmenaient","lemma":"remmener","pos":"VER"} ,
		{"word":"remmener","word_nosc":"remmener","lemma":"remmener","pos":"VER"} ,
		{"word":"remmenez","word_nosc":"remmenez","lemma":"remmener","pos":"VER"} ,
		{"word":"remmenée","word_nosc":"remmenee","lemma":"remmener","pos":"VER"} ,
		{"word":"remmène","word_nosc":"remmene","lemma":"remmener","pos":"VER"} ,
		{"word":"remmènes","word_nosc":"remmenes","lemma":"remmener","pos":"VER"} ,
		{"word":"remobilisés","word_nosc":"remobilises","lemma":"remobiliser","pos":"VER"} ,
		{"word":"remodela","word_nosc":"remodela","lemma":"remodeler","pos":"VER"} ,
		{"word":"remodelant","word_nosc":"remodelant","lemma":"remodeler","pos":"VER"} ,
		{"word":"remodeler","word_nosc":"remodeler","lemma":"remodeler","pos":"VER"} ,
		{"word":"remodelé","word_nosc":"remodele","lemma":"remodeler","pos":"VER"} ,
		{"word":"remodelée","word_nosc":"remodelee","lemma":"remodeler","pos":"VER"} ,
		{"word":"remodelées","word_nosc":"remodelees","lemma":"remodeler","pos":"VER"} ,
		{"word":"remodèle","word_nosc":"remodele","lemma":"remodeler","pos":"VER"} ,
		{"word":"remodèles","word_nosc":"remodeles","lemma":"remodeler","pos":"VER"} ,
		{"word":"remonta","word_nosc":"remonta","lemma":"remonter","pos":"VER"} ,
		{"word":"remontai","word_nosc":"remontai","lemma":"remonter","pos":"VER"} ,
		{"word":"remontaient","word_nosc":"remontaient","lemma":"remonter","pos":"VER"} ,
		{"word":"remontais","word_nosc":"remontais","lemma":"remonter","pos":"VER"} ,
		{"word":"remontait","word_nosc":"remontait","lemma":"remonter","pos":"VER"} ,
		{"word":"remontant","word_nosc":"remontant","lemma":"remonter","pos":"VER"} ,
		{"word":"remonte","word_nosc":"remonte","lemma":"remonter","pos":"VER"} ,
		{"word":"remontent","word_nosc":"remontent","lemma":"remonter","pos":"VER"} ,
		{"word":"remonter","word_nosc":"remonter","lemma":"remonter","pos":"VER"} ,
		{"word":"remontera","word_nosc":"remontera","lemma":"remonter","pos":"VER"} ,
		{"word":"remonterai","word_nosc":"remonterai","lemma":"remonter","pos":"VER"} ,
		{"word":"remonteraient","word_nosc":"remonteraient","lemma":"remonter","pos":"VER"} ,
		{"word":"remonterait","word_nosc":"remonterait","lemma":"remonter","pos":"VER"} ,
		{"word":"remonteras","word_nosc":"remonteras","lemma":"remonter","pos":"VER"} ,
		{"word":"remonterez","word_nosc":"remonterez","lemma":"remonter","pos":"VER"} ,
		{"word":"remonteriez","word_nosc":"remonteriez","lemma":"remonter","pos":"VER"} ,
		{"word":"remonterons","word_nosc":"remonterons","lemma":"remonter","pos":"VER"} ,
		{"word":"remonteront","word_nosc":"remonteront","lemma":"remonter","pos":"VER"} ,
		{"word":"remontes","word_nosc":"remontes","lemma":"remonter","pos":"VER"} ,
		{"word":"remontez","word_nosc":"remontez","lemma":"remonter","pos":"VER"} ,
		{"word":"remontiez","word_nosc":"remontiez","lemma":"remonter","pos":"VER"} ,
		{"word":"remontions","word_nosc":"remontions","lemma":"remonter","pos":"VER"} ,
		{"word":"remontons","word_nosc":"remontons","lemma":"remonter","pos":"VER"} ,
		{"word":"remontrait","word_nosc":"remontrait","lemma":"remontrer","pos":"VER"} ,
		{"word":"remontrassent","word_nosc":"remontrassent","lemma":"remontrer","pos":"VER"} ,
		{"word":"remontre","word_nosc":"remontre","lemma":"remontrer","pos":"VER"} ,
		{"word":"remontrer","word_nosc":"remontrer","lemma":"remontrer","pos":"VER"} ,
		{"word":"remontrera","word_nosc":"remontrera","lemma":"remontrer","pos":"VER"} ,
		{"word":"remontrerai","word_nosc":"remontrerai","lemma":"remontrer","pos":"VER"} ,
		{"word":"remontrerais","word_nosc":"remontrerais","lemma":"remontrer","pos":"VER"} ,
		{"word":"remontrerait","word_nosc":"remontrerait","lemma":"remontrer","pos":"VER"} ,
		{"word":"remontreras","word_nosc":"remontreras","lemma":"remontrer","pos":"VER"} ,
		{"word":"remontres","word_nosc":"remontres","lemma":"remontrer","pos":"VER"} ,
		{"word":"remontrez","word_nosc":"remontrez","lemma":"remontrer","pos":"VER"} ,
		{"word":"remontré","word_nosc":"remontre","lemma":"remontrer","pos":"VER"} ,
		{"word":"remontâmes","word_nosc":"remontames","lemma":"remonter","pos":"VER"} ,
		{"word":"remontât","word_nosc":"remontat","lemma":"remonter","pos":"VER"} ,
		{"word":"remontèrent","word_nosc":"remonterent","lemma":"remonter","pos":"VER"} ,
		{"word":"remonté","word_nosc":"remonte","lemma":"remonter","pos":"VER"} ,
		{"word":"remontée","word_nosc":"remontee","lemma":"remonter","pos":"VER"} ,
		{"word":"remontées","word_nosc":"remontees","lemma":"remonter","pos":"VER"} ,
		{"word":"remontés","word_nosc":"remontes","lemma":"remonter","pos":"VER"} ,
		{"word":"remord","word_nosc":"remord","lemma":"remordre","pos":"VER"} ,
		{"word":"remordait","word_nosc":"remordait","lemma":"remordre","pos":"VER"} ,
		{"word":"remords","word_nosc":"remords","lemma":"remordre","pos":"VER"} ,
		{"word":"remordu","word_nosc":"remordu","lemma":"remordre","pos":"VER"} ,
		{"word":"remorqua","word_nosc":"remorqua","lemma":"remorquer","pos":"VER"} ,
		{"word":"remorquaient","word_nosc":"remorquaient","lemma":"remorquer","pos":"VER"} ,
		{"word":"remorquait","word_nosc":"remorquait","lemma":"remorquer","pos":"VER"} ,
		{"word":"remorquant","word_nosc":"remorquant","lemma":"remorquer","pos":"VER"} ,
		{"word":"remorque","word_nosc":"remorque","lemma":"remorquer","pos":"VER"} ,
		{"word":"remorquer","word_nosc":"remorquer","lemma":"remorquer","pos":"VER"} ,
		{"word":"remorquera","word_nosc":"remorquera","lemma":"remorquer","pos":"VER"} ,
		{"word":"remorquerai","word_nosc":"remorquerai","lemma":"remorquer","pos":"VER"} ,
		{"word":"remorques","word_nosc":"remorques","lemma":"remorquer","pos":"VER"} ,
		{"word":"remorquez","word_nosc":"remorquez","lemma":"remorquer","pos":"VER"} ,
		{"word":"remorquions","word_nosc":"remorquions","lemma":"remorquer","pos":"VER"} ,
		{"word":"remorquons","word_nosc":"remorquons","lemma":"remorquer","pos":"VER"} ,
		{"word":"remorqué","word_nosc":"remorque","lemma":"remorquer","pos":"VER"} ,
		{"word":"remorquée","word_nosc":"remorquee","lemma":"remorquer","pos":"VER"} ,
		{"word":"remoucha","word_nosc":"remoucha","lemma":"remoucher","pos":"VER"} ,
		{"word":"remouillaient","word_nosc":"remouillaient","lemma":"remouiller","pos":"VER"} ,
		{"word":"remouiller","word_nosc":"remouiller","lemma":"remouiller","pos":"VER"} ,
		{"word":"rempaillaient","word_nosc":"rempaillaient","lemma":"rempailler","pos":"VER"} ,
		{"word":"rempailler","word_nosc":"rempailler","lemma":"rempailler","pos":"VER"} ,
		{"word":"rempaillée","word_nosc":"rempaillee","lemma":"rempailler","pos":"VER"} ,
		{"word":"rempaquette","word_nosc":"rempaquette","lemma":"rempaqueter","pos":"VER"} ,
		{"word":"rempaqueté","word_nosc":"rempaquete","lemma":"rempaqueter","pos":"VER"} ,
		{"word":"rempilait","word_nosc":"rempilait","lemma":"rempiler","pos":"VER"} ,
		{"word":"rempile","word_nosc":"rempile","lemma":"rempiler","pos":"VER"} ,
		{"word":"rempilent","word_nosc":"rempilent","lemma":"rempiler","pos":"VER"} ,
		{"word":"rempiler","word_nosc":"rempiler","lemma":"rempiler","pos":"VER"} ,
		{"word":"rempilerai","word_nosc":"rempilerai","lemma":"rempiler","pos":"VER"} ,
		{"word":"rempilerais","word_nosc":"rempilerais","lemma":"rempiler","pos":"VER"} ,
		{"word":"rempilerez","word_nosc":"rempilerez","lemma":"rempiler","pos":"VER"} ,
		{"word":"rempiles","word_nosc":"rempiles","lemma":"rempiler","pos":"VER"} ,
		{"word":"rempilez","word_nosc":"rempilez","lemma":"rempiler","pos":"VER"} ,
		{"word":"rempilé","word_nosc":"rempile","lemma":"rempiler","pos":"VER"} ,
		{"word":"remplace","word_nosc":"remplace","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplacent","word_nosc":"remplacent","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplacer","word_nosc":"remplacer","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplacera","word_nosc":"remplacera","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplacerai","word_nosc":"remplacerai","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplaceraient","word_nosc":"remplaceraient","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplacerais","word_nosc":"remplacerais","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplacerait","word_nosc":"remplacerait","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplaceras","word_nosc":"remplaceras","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplacerez","word_nosc":"remplacerez","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplaceriez","word_nosc":"remplaceriez","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplacerons","word_nosc":"remplacerons","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplaceront","word_nosc":"remplaceront","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplaces","word_nosc":"remplaces","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplacez","word_nosc":"remplacez","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplaciez","word_nosc":"remplaciez","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplacèrent","word_nosc":"remplacerent","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplacé","word_nosc":"remplace","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplacée","word_nosc":"remplacee","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplacées","word_nosc":"remplacees","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplacés","word_nosc":"remplaces","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplaça","word_nosc":"remplaca","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplaçai","word_nosc":"remplacai","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplaçaient","word_nosc":"remplacaient","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplaçais","word_nosc":"remplacais","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplaçait","word_nosc":"remplacait","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplaçant","word_nosc":"remplacant","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplaçons","word_nosc":"remplacons","lemma":"remplacer","pos":"VER"} ,
		{"word":"remplaçât","word_nosc":"remplacat","lemma":"remplacer","pos":"VER"} ,
		{"word":"rempli","word_nosc":"rempli","lemma":"remplir","pos":"VER"} ,
		{"word":"remplie","word_nosc":"remplie","lemma":"remplier","pos":"VER"} ,
		{"word":"remplies","word_nosc":"remplies","lemma":"remplier","pos":"VER"} ,
		{"word":"remplir","word_nosc":"remplir","lemma":"remplir","pos":"VER"} ,
		{"word":"remplira","word_nosc":"remplira","lemma":"remplir","pos":"VER"} ,
		{"word":"remplirai","word_nosc":"remplirai","lemma":"remplir","pos":"VER"} ,
		{"word":"rempliraient","word_nosc":"rempliraient","lemma":"remplir","pos":"VER"} ,
		{"word":"remplirais","word_nosc":"remplirais","lemma":"remplir","pos":"VER"} ,
		{"word":"remplirait","word_nosc":"remplirait","lemma":"remplir","pos":"VER"} ,
		{"word":"rempliras","word_nosc":"rempliras","lemma":"remplir","pos":"VER"} ,
		{"word":"remplirent","word_nosc":"remplirent","lemma":"remplir","pos":"VER"} ,
		{"word":"remplirez","word_nosc":"remplirez","lemma":"remplir","pos":"VER"} ,
		{"word":"remplirons","word_nosc":"remplirons","lemma":"remplir","pos":"VER"} ,
		{"word":"rempliront","word_nosc":"rempliront","lemma":"remplir","pos":"VER"} ,
		{"word":"remplis","word_nosc":"remplis","lemma":"remplir","pos":"VER"} ,
		{"word":"remplissaient","word_nosc":"remplissaient","lemma":"remplir","pos":"VER"} ,
		{"word":"remplissais","word_nosc":"remplissais","lemma":"remplir","pos":"VER"} ,
		{"word":"remplissait","word_nosc":"remplissait","lemma":"remplir","pos":"VER"} ,
		{"word":"remplissant","word_nosc":"remplissant","lemma":"remplir","pos":"VER"} ,
		{"word":"remplisse","word_nosc":"remplisse","lemma":"remplir","pos":"VER"} ,
		{"word":"remplissent","word_nosc":"remplissent","lemma":"remplir","pos":"VER"} ,
		{"word":"remplisses","word_nosc":"remplisses","lemma":"remplir","pos":"VER"} ,
		{"word":"remplissez","word_nosc":"remplissez","lemma":"remplir","pos":"VER"} ,
		{"word":"remplissiez","word_nosc":"remplissiez","lemma":"remplir","pos":"VER"} ,
		{"word":"remplissions","word_nosc":"remplissions","lemma":"remplir","pos":"VER"} ,
		{"word":"remplissons","word_nosc":"remplissons","lemma":"remplir","pos":"VER"} ,
		{"word":"remplit","word_nosc":"remplit","lemma":"remplir","pos":"VER"} ,
		{"word":"remployée","word_nosc":"remployee","lemma":"remployer","pos":"VER"} ,
		{"word":"remplumer","word_nosc":"remplumer","lemma":"remplumer","pos":"VER"} ,
		{"word":"remplumez","word_nosc":"remplumez","lemma":"remplumer","pos":"VER"} ,
		{"word":"remplumé","word_nosc":"remplume","lemma":"remplumer","pos":"VER"} ,
		{"word":"remplît","word_nosc":"remplit","lemma":"remplir","pos":"VER"} ,
		{"word":"rempochait","word_nosc":"rempochait","lemma":"rempocher","pos":"VER"} ,
		{"word":"rempoche","word_nosc":"rempoche","lemma":"rempocher","pos":"VER"} ,
		{"word":"remporta","word_nosc":"remporta","lemma":"remporter","pos":"VER"} ,
		{"word":"remportai","word_nosc":"remportai","lemma":"remporter","pos":"VER"} ,
		{"word":"remportaient","word_nosc":"remportaient","lemma":"remporter","pos":"VER"} ,
		{"word":"remportais","word_nosc":"remportais","lemma":"remporter","pos":"VER"} ,
		{"word":"remportait","word_nosc":"remportait","lemma":"remporter","pos":"VER"} ,
		{"word":"remportant","word_nosc":"remportant","lemma":"remporter","pos":"VER"} ,
		{"word":"remportassent","word_nosc":"remportassent","lemma":"remporter","pos":"VER"} ,
		{"word":"remporte","word_nosc":"remporte","lemma":"remporter","pos":"VER"} ,
		{"word":"remportent","word_nosc":"remportent","lemma":"remporter","pos":"VER"} ,
		{"word":"remporter","word_nosc":"remporter","lemma":"remporter","pos":"VER"} ,
		{"word":"remportera","word_nosc":"remportera","lemma":"remporter","pos":"VER"} ,
		{"word":"remporterai","word_nosc":"remporterai","lemma":"remporter","pos":"VER"} ,
		{"word":"remporterait","word_nosc":"remporterait","lemma":"remporter","pos":"VER"} ,
		{"word":"remporterez","word_nosc":"remporterez","lemma":"remporter","pos":"VER"} ,
		{"word":"remporterons","word_nosc":"remporterons","lemma":"remporter","pos":"VER"} ,
		{"word":"remportez","word_nosc":"remportez","lemma":"remporter","pos":"VER"} ,
		{"word":"remportiez","word_nosc":"remportiez","lemma":"remporter","pos":"VER"} ,
		{"word":"remportions","word_nosc":"remportions","lemma":"remporter","pos":"VER"} ,
		{"word":"remportât","word_nosc":"remportat","lemma":"remporter","pos":"VER"} ,
		{"word":"remportèrent","word_nosc":"remporterent","lemma":"remporter","pos":"VER"} ,
		{"word":"remporté","word_nosc":"remporte","lemma":"remporter","pos":"VER"} ,
		{"word":"remportée","word_nosc":"remportee","lemma":"remporter","pos":"VER"} ,
		{"word":"remportées","word_nosc":"remportees","lemma":"remporter","pos":"VER"} ,
		{"word":"remportés","word_nosc":"remportes","lemma":"remporter","pos":"VER"} ,
		{"word":"rempoter","word_nosc":"rempoter","lemma":"rempoter","pos":"VER"} ,
		{"word":"remprunter","word_nosc":"remprunter","lemma":"remprunter","pos":"VER"} ,
		{"word":"remua","word_nosc":"remua","lemma":"remuer","pos":"VER"} ,
		{"word":"remuai","word_nosc":"remuai","lemma":"remuer","pos":"VER"} ,
		{"word":"remuaient","word_nosc":"remuaient","lemma":"remuer","pos":"VER"} ,
		{"word":"remuais","word_nosc":"remuais","lemma":"remuer","pos":"VER"} ,
		{"word":"remuait","word_nosc":"remuait","lemma":"remuer","pos":"VER"} ,
		{"word":"remuant","word_nosc":"remuant","lemma":"remuer","pos":"VER"} ,
		{"word":"remue","word_nosc":"remue","lemma":"remuer","pos":"VER"} ,
		{"word":"remuent","word_nosc":"remuent","lemma":"remuer","pos":"VER"} ,
		{"word":"remuer","word_nosc":"remuer","lemma":"remuer","pos":"VER"} ,
		{"word":"remuera","word_nosc":"remuera","lemma":"remuer","pos":"VER"} ,
		{"word":"remuerai","word_nosc":"remuerai","lemma":"remuer","pos":"VER"} ,
		{"word":"remueraient","word_nosc":"remueraient","lemma":"remuer","pos":"VER"} ,
		{"word":"remuerais","word_nosc":"remuerais","lemma":"remuer","pos":"VER"} ,
		{"word":"remuerait","word_nosc":"remuerait","lemma":"remuer","pos":"VER"} ,
		{"word":"remueront","word_nosc":"remueront","lemma":"remuer","pos":"VER"} ,
		{"word":"remues","word_nosc":"remues","lemma":"remuer","pos":"VER"} ,
		{"word":"remuez","word_nosc":"remuez","lemma":"remuer","pos":"VER"} ,
		{"word":"remuions","word_nosc":"remuions","lemma":"remuer","pos":"VER"} ,
		{"word":"remuons","word_nosc":"remuons","lemma":"remuer","pos":"VER"} ,
		{"word":"remuscle","word_nosc":"remuscle","lemma":"remuscler","pos":"VER"} ,
		{"word":"remuscler","word_nosc":"remuscler","lemma":"remuscler","pos":"VER"} ,
		{"word":"remuâmes","word_nosc":"remuames","lemma":"remuer","pos":"VER"} ,
		{"word":"remuât","word_nosc":"remuat","lemma":"remuer","pos":"VER"} ,
		{"word":"remuèrent","word_nosc":"remuerent","lemma":"remuer","pos":"VER"} ,
		{"word":"remué","word_nosc":"remue","lemma":"remuer","pos":"VER"} ,
		{"word":"remuée","word_nosc":"remuee","lemma":"remuer","pos":"VER"} ,
		{"word":"remuées","word_nosc":"remuees","lemma":"remuer","pos":"VER"} ,
		{"word":"remués","word_nosc":"remues","lemma":"remuer","pos":"VER"} ,
		{"word":"remâcha","word_nosc":"remacha","lemma":"remâcher","pos":"VER"} ,
		{"word":"remâchaient","word_nosc":"remachaient","lemma":"remâcher","pos":"VER"} ,
		{"word":"remâchais","word_nosc":"remachais","lemma":"remâcher","pos":"VER"} ,
		{"word":"remâchait","word_nosc":"remachait","lemma":"remâcher","pos":"VER"} ,
		{"word":"remâchant","word_nosc":"remachant","lemma":"remâcher","pos":"VER"} ,
		{"word":"remâche","word_nosc":"remache","lemma":"remâcher","pos":"VER"} ,
		{"word":"remâchent","word_nosc":"remachent","lemma":"remâcher","pos":"VER"} ,
		{"word":"remâcher","word_nosc":"remacher","lemma":"remâcher","pos":"VER"} ,
		{"word":"remâchez","word_nosc":"remachez","lemma":"remâcher","pos":"VER"} ,
		{"word":"remâché","word_nosc":"remache","lemma":"remâcher","pos":"VER"} ,
		{"word":"remâchée","word_nosc":"remachee","lemma":"remâcher","pos":"VER"} ,
		{"word":"remâchées","word_nosc":"remachees","lemma":"remâcher","pos":"VER"} ,
		{"word":"remédia","word_nosc":"remedia","lemma":"remédier","pos":"VER"} ,
		{"word":"remédiait","word_nosc":"remediait","lemma":"remédier","pos":"VER"} ,
		{"word":"remédie","word_nosc":"remedie","lemma":"remédier","pos":"VER"} ,
		{"word":"remédier","word_nosc":"remedier","lemma":"remédier","pos":"VER"} ,
		{"word":"remédiera","word_nosc":"remediera","lemma":"remédier","pos":"VER"} ,
		{"word":"remédierait","word_nosc":"remedierait","lemma":"remédier","pos":"VER"} ,
		{"word":"remédierons","word_nosc":"remedierons","lemma":"remédier","pos":"VER"} ,
		{"word":"remédiez","word_nosc":"remediez","lemma":"remédier","pos":"VER"} ,
		{"word":"remédions","word_nosc":"remedions","lemma":"remédier","pos":"VER"} ,
		{"word":"remédié","word_nosc":"remedie","lemma":"remédier","pos":"VER"} ,
		{"word":"remémora","word_nosc":"rememora","lemma":"remémorer","pos":"VER"} ,
		{"word":"remémorais","word_nosc":"rememorais","lemma":"remémorer","pos":"VER"} ,
		{"word":"remémorait","word_nosc":"rememorait","lemma":"remémorer","pos":"VER"} ,
		{"word":"remémorant","word_nosc":"rememorant","lemma":"remémorer","pos":"VER"} ,
		{"word":"remémore","word_nosc":"rememore","lemma":"remémorer","pos":"VER"} ,
		{"word":"remémorent","word_nosc":"rememorent","lemma":"remémorer","pos":"VER"} ,
		{"word":"remémorer","word_nosc":"rememorer","lemma":"remémorer","pos":"VER"} ,
		{"word":"remémoré","word_nosc":"rememore","lemma":"remémorer","pos":"VER"} ,
		{"word":"remîmes","word_nosc":"remimes","lemma":"remettre","pos":"VER"} ,
		{"word":"remît","word_nosc":"remit","lemma":"remettre","pos":"VER"} ,
		{"word":"renais","word_nosc":"renais","lemma":"renaître","pos":"VER"} ,
		{"word":"renaissaient","word_nosc":"renaissaient","lemma":"renaître","pos":"VER"} ,
		{"word":"renaissais","word_nosc":"renaissais","lemma":"renaître","pos":"VER"} ,
		{"word":"renaissait","word_nosc":"renaissait","lemma":"renaître","pos":"VER"} ,
		{"word":"renaissant","word_nosc":"renaissant","lemma":"renaître","pos":"VER"} ,
		{"word":"renaisse","word_nosc":"renaisse","lemma":"renaître","pos":"VER"} ,
		{"word":"renaissent","word_nosc":"renaissent","lemma":"renaître","pos":"VER"} ,
		{"word":"renaisses","word_nosc":"renaisses","lemma":"renaître","pos":"VER"} ,
		{"word":"renaissons","word_nosc":"renaissons","lemma":"renaître","pos":"VER"} ,
		{"word":"renaquit","word_nosc":"renaquit","lemma":"renaître","pos":"VER"} ,
		{"word":"renaudais","word_nosc":"renaudais","lemma":"renauder","pos":"VER"} ,
		{"word":"renaudait","word_nosc":"renaudait","lemma":"renauder","pos":"VER"} ,
		{"word":"renaudant","word_nosc":"renaudant","lemma":"renauder","pos":"VER"} ,
		{"word":"renaude","word_nosc":"renaude","lemma":"renauder","pos":"VER"} ,
		{"word":"renaudent","word_nosc":"renaudent","lemma":"renauder","pos":"VER"} ,
		{"word":"renauder","word_nosc":"renauder","lemma":"renauder","pos":"VER"} ,
		{"word":"renaudé","word_nosc":"renaude","lemma":"renauder","pos":"VER"} ,
		{"word":"renaît","word_nosc":"renait","lemma":"renaître","pos":"VER"} ,
		{"word":"renaîtra","word_nosc":"renaitra","lemma":"renaître","pos":"VER"} ,
		{"word":"renaîtrai","word_nosc":"renaitrai","lemma":"renaître","pos":"VER"} ,
		{"word":"renaîtraient","word_nosc":"renaitraient","lemma":"renaître","pos":"VER"} ,
		{"word":"renaîtrais","word_nosc":"renaitrais","lemma":"renaître","pos":"VER"} ,
		{"word":"renaîtrait","word_nosc":"renaitrait","lemma":"renaître","pos":"VER"} ,
		{"word":"renaîtras","word_nosc":"renaitras","lemma":"renaître","pos":"VER"} ,
		{"word":"renaître","word_nosc":"renaitre","lemma":"renaître","pos":"VER"} ,
		{"word":"renaîtrez","word_nosc":"renaitrez","lemma":"renaître","pos":"VER"} ,
		{"word":"renaîtrons","word_nosc":"renaitrons","lemma":"renaître","pos":"VER"} ,
		{"word":"renaîtront","word_nosc":"renaitront","lemma":"renaître","pos":"VER"} ,
		{"word":"rencardaient","word_nosc":"rencardaient","lemma":"rencarder","pos":"VER"} ,
		{"word":"rencardait","word_nosc":"rencardait","lemma":"rencarder","pos":"VER"} ,
		{"word":"rencarde","word_nosc":"rencarde","lemma":"rencarder","pos":"VER"} ,
		{"word":"rencarder","word_nosc":"rencarder","lemma":"rencarder","pos":"VER"} ,
		{"word":"rencardé","word_nosc":"rencarde","lemma":"rencarder","pos":"VER"} ,
		{"word":"rencardée","word_nosc":"rencardee","lemma":"rencarder","pos":"VER"} ,
		{"word":"rencardées","word_nosc":"rencardees","lemma":"rencarder","pos":"VER"} ,
		{"word":"rencardés","word_nosc":"rencardes","lemma":"rencarder","pos":"VER"} ,
		{"word":"renchéri","word_nosc":"rencheri","lemma":"renchérir","pos":"VER"} ,
		{"word":"renchérir","word_nosc":"rencherir","lemma":"renchérir","pos":"VER"} ,
		{"word":"renchériront","word_nosc":"rencheriront","lemma":"renchérir","pos":"VER"} ,
		{"word":"renchéris","word_nosc":"rencheris","lemma":"renchérir","pos":"VER"} ,
		{"word":"renchérissais","word_nosc":"rencherissais","lemma":"renchérir","pos":"VER"} ,
		{"word":"renchérissait","word_nosc":"rencherissait","lemma":"renchérir","pos":"VER"} ,
		{"word":"renchérissant","word_nosc":"rencherissant","lemma":"renchérir","pos":"VER"} ,
		{"word":"renchérissent","word_nosc":"rencherissent","lemma":"renchérir","pos":"VER"} ,
		{"word":"renchérit","word_nosc":"rencherit","lemma":"renchérir","pos":"VER"} ,
		{"word":"rencogna","word_nosc":"rencogna","lemma":"rencogner","pos":"VER"} ,
		{"word":"rencognait","word_nosc":"rencognait","lemma":"rencogner","pos":"VER"} ,
		{"word":"rencognant","word_nosc":"rencognant","lemma":"rencogner","pos":"VER"} ,
		{"word":"rencogne","word_nosc":"rencogne","lemma":"rencogner","pos":"VER"} ,
		{"word":"rencogner","word_nosc":"rencogner","lemma":"rencogner","pos":"VER"} ,
		{"word":"rencognerait","word_nosc":"rencognerait","lemma":"rencogner","pos":"VER"} ,
		{"word":"rencogné","word_nosc":"rencogne","lemma":"rencogner","pos":"VER"} ,
		{"word":"rencognés","word_nosc":"rencognes","lemma":"rencogner","pos":"VER"} ,
		{"word":"rencontra","word_nosc":"rencontra","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrai","word_nosc":"rencontrai","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontraient","word_nosc":"rencontraient","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrais","word_nosc":"rencontrais","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrait","word_nosc":"rencontrait","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrant","word_nosc":"rencontrant","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrasse","word_nosc":"rencontrasse","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontre","word_nosc":"rencontre","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrent","word_nosc":"rencontrent","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrer","word_nosc":"rencontrer","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrera","word_nosc":"rencontrera","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrerai","word_nosc":"rencontrerai","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontreraient","word_nosc":"rencontreraient","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrerais","word_nosc":"rencontrerais","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrerait","word_nosc":"rencontrerait","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontreras","word_nosc":"rencontreras","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrerez","word_nosc":"rencontrerez","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontreriez","word_nosc":"rencontreriez","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrerions","word_nosc":"rencontrerions","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrerons","word_nosc":"rencontrerons","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontreront","word_nosc":"rencontreront","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontres","word_nosc":"rencontres","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrez","word_nosc":"rencontrez","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontriez","word_nosc":"rencontriez","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrions","word_nosc":"rencontrions","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrons","word_nosc":"rencontrons","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrâmes","word_nosc":"rencontrames","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrât","word_nosc":"rencontrat","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrèrent","word_nosc":"rencontrerent","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontré","word_nosc":"rencontre","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrée","word_nosc":"rencontree","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrées","word_nosc":"rencontrees","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rencontrés","word_nosc":"rencontres","lemma":"rencontrer","pos":"VER"} ,
		{"word":"rend","word_nosc":"rend","lemma":"rendre","pos":"VER"} ,
		{"word":"rendaient","word_nosc":"rendaient","lemma":"rendre","pos":"VER"} ,
		{"word":"rendais","word_nosc":"rendais","lemma":"rendre","pos":"VER"} ,
		{"word":"rendait","word_nosc":"rendait","lemma":"rendre","pos":"VER"} ,
		{"word":"rendant","word_nosc":"rendant","lemma":"rendre","pos":"VER"} ,
		{"word":"rende","word_nosc":"rende","lemma":"rendre","pos":"VER"} ,
		{"word":"rendent","word_nosc":"rendent","lemma":"rendre","pos":"VER"} ,
		{"word":"rendes","word_nosc":"rendes","lemma":"rendre","pos":"VER"} ,
		{"word":"rendez","word_nosc":"rendez","lemma":"rendre","pos":"VER"} ,
		{"word":"rendiez","word_nosc":"rendiez","lemma":"rendre","pos":"VER"} ,
		{"word":"rendions","word_nosc":"rendions","lemma":"rendre","pos":"VER"} ,
		{"word":"rendirent","word_nosc":"rendirent","lemma":"rendre","pos":"VER"} ,
		{"word":"rendis","word_nosc":"rendis","lemma":"rendre","pos":"VER"} ,
		{"word":"rendisse","word_nosc":"rendisse","lemma":"rendre","pos":"VER"} ,
		{"word":"rendissent","word_nosc":"rendissent","lemma":"rendre","pos":"VER"} ,
		{"word":"rendit","word_nosc":"rendit","lemma":"rendre","pos":"VER"} ,
		{"word":"rendons","word_nosc":"rendons","lemma":"rendre","pos":"VER"} ,
		{"word":"rendormais","word_nosc":"rendormais","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendormait","word_nosc":"rendormait","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendormant","word_nosc":"rendormant","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendorme","word_nosc":"rendorme","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendorment","word_nosc":"rendorment","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendormez","word_nosc":"rendormez","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendormi","word_nosc":"rendormi","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendormiez","word_nosc":"rendormiez","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendormir","word_nosc":"rendormir","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendormira","word_nosc":"rendormira","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendormirai","word_nosc":"rendormirai","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendormirait","word_nosc":"rendormirait","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendormis","word_nosc":"rendormis","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendormit","word_nosc":"rendormit","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendormît","word_nosc":"rendormit","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendors","word_nosc":"rendors","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendort","word_nosc":"rendort","lemma":"rendormir","pos":"VER"} ,
		{"word":"rendra","word_nosc":"rendra","lemma":"rendre","pos":"VER"} ,
		{"word":"rendrai","word_nosc":"rendrai","lemma":"rendre","pos":"VER"} ,
		{"word":"rendraient","word_nosc":"rendraient","lemma":"rendre","pos":"VER"} ,
		{"word":"rendrais","word_nosc":"rendrais","lemma":"rendre","pos":"VER"} ,
		{"word":"rendrait","word_nosc":"rendrait","lemma":"rendre","pos":"VER"} ,
		{"word":"rendras","word_nosc":"rendras","lemma":"rendre","pos":"VER"} ,
		{"word":"rendre","word_nosc":"rendre","lemma":"rendre","pos":"VER"} ,
		{"word":"rendrez","word_nosc":"rendrez","lemma":"rendre","pos":"VER"} ,
		{"word":"rendriez","word_nosc":"rendriez","lemma":"rendre","pos":"VER"} ,
		{"word":"rendrons","word_nosc":"rendrons","lemma":"rendre","pos":"VER"} ,
		{"word":"rendront","word_nosc":"rendront","lemma":"rendre","pos":"VER"} ,
		{"word":"rends","word_nosc":"rends","lemma":"rendre","pos":"VER"} ,
		{"word":"rendu","word_nosc":"rendu","lemma":"rendre","pos":"VER"} ,
		{"word":"rendue","word_nosc":"rendue","lemma":"rendre","pos":"VER"} ,
		{"word":"rendues","word_nosc":"rendues","lemma":"rendre","pos":"VER"} ,
		{"word":"rendus","word_nosc":"rendus","lemma":"rendre","pos":"VER"} ,
		{"word":"rendîmes","word_nosc":"rendimes","lemma":"rendre","pos":"VER"} ,
		{"word":"rendît","word_nosc":"rendit","lemma":"rendre","pos":"VER"} ,
		{"word":"reneige","word_nosc":"reneige","lemma":"reneiger","pos":"VER"} ,
		{"word":"reneiger","word_nosc":"reneiger","lemma":"reneiger","pos":"VER"} ,
		{"word":"renettoyer","word_nosc":"renettoyer","lemma":"renettoyer","pos":"VER"} ,
		{"word":"renferma","word_nosc":"renferma","lemma":"renfermer","pos":"VER"} ,
		{"word":"renfermaient","word_nosc":"renfermaient","lemma":"renfermer","pos":"VER"} ,
		{"word":"renfermait","word_nosc":"renfermait","lemma":"renfermer","pos":"VER"} ,
		{"word":"renfermant","word_nosc":"renfermant","lemma":"renfermer","pos":"VER"} ,
		{"word":"renferme","word_nosc":"renferme","lemma":"renfermer","pos":"VER"} ,
		{"word":"renferment","word_nosc":"renferment","lemma":"renfermer","pos":"VER"} ,
		{"word":"renfermer","word_nosc":"renfermer","lemma":"renfermer","pos":"VER"} ,
		{"word":"renfermerait","word_nosc":"renfermerait","lemma":"renfermer","pos":"VER"} ,
		{"word":"renfermez","word_nosc":"renfermez","lemma":"renfermer","pos":"VER"} ,
		{"word":"renfermé","word_nosc":"renferme","lemma":"renfermer","pos":"VER"} ,
		{"word":"renfermée","word_nosc":"renfermee","lemma":"renfermer","pos":"VER"} ,
		{"word":"renfermées","word_nosc":"renfermees","lemma":"renfermer","pos":"VER"} ,
		{"word":"renfermés","word_nosc":"renfermes","lemma":"renfermer","pos":"VER"} ,
		{"word":"renfila","word_nosc":"renfila","lemma":"renfiler","pos":"VER"} ,
		{"word":"renfilait","word_nosc":"renfilait","lemma":"renfiler","pos":"VER"} ,
		{"word":"renfilant","word_nosc":"renfilant","lemma":"renfiler","pos":"VER"} ,
		{"word":"renfile","word_nosc":"renfile","lemma":"renfiler","pos":"VER"} ,
		{"word":"renfiler","word_nosc":"renfiler","lemma":"renfiler","pos":"VER"} ,
		{"word":"renfilé","word_nosc":"renfile","lemma":"renfiler","pos":"VER"} ,
		{"word":"renflait","word_nosc":"renflait","lemma":"renfler","pos":"VER"} ,
		{"word":"renflant","word_nosc":"renflant","lemma":"renfler","pos":"VER"} ,
		{"word":"renflent","word_nosc":"renflent","lemma":"renfler","pos":"VER"} ,
		{"word":"renfloua","word_nosc":"renfloua","lemma":"renflouer","pos":"VER"} ,
		{"word":"renflouait","word_nosc":"renflouait","lemma":"renflouer","pos":"VER"} ,
		{"word":"renflouant","word_nosc":"renflouant","lemma":"renflouer","pos":"VER"} ,
		{"word":"renfloue","word_nosc":"renfloue","lemma":"renflouer","pos":"VER"} ,
		{"word":"renflouent","word_nosc":"renflouent","lemma":"renflouer","pos":"VER"} ,
		{"word":"renflouer","word_nosc":"renflouer","lemma":"renflouer","pos":"VER"} ,
		{"word":"renfloué","word_nosc":"renfloue","lemma":"renflouer","pos":"VER"} ,
		{"word":"renflouée","word_nosc":"renflouee","lemma":"renflouer","pos":"VER"} ,
		{"word":"renfloués","word_nosc":"renfloues","lemma":"renflouer","pos":"VER"} ,
		{"word":"renflé","word_nosc":"renfle","lemma":"renfler","pos":"VER"} ,
		{"word":"renflée","word_nosc":"renflee","lemma":"renfler","pos":"VER"} ,
		{"word":"renflées","word_nosc":"renflees","lemma":"renfler","pos":"VER"} ,
		{"word":"renflés","word_nosc":"renfles","lemma":"renfler","pos":"VER"} ,
		{"word":"renfonce","word_nosc":"renfonce","lemma":"renfoncer","pos":"VER"} ,
		{"word":"renfoncer","word_nosc":"renfoncer","lemma":"renfoncer","pos":"VER"} ,
		{"word":"renfoncé","word_nosc":"renfonce","lemma":"renfoncer","pos":"VER"} ,
		{"word":"renfoncée","word_nosc":"renfoncee","lemma":"renfoncer","pos":"VER"} ,
		{"word":"renfoncées","word_nosc":"renfoncees","lemma":"renfoncer","pos":"VER"} ,
		{"word":"renfoncés","word_nosc":"renfonces","lemma":"renfoncer","pos":"VER"} ,
		{"word":"renfonça","word_nosc":"renfonca","lemma":"renfoncer","pos":"VER"} ,
		{"word":"renfonçait","word_nosc":"renfoncait","lemma":"renfoncer","pos":"VER"} ,
		{"word":"renforce","word_nosc":"renforce","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforcent","word_nosc":"renforcent","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforcer","word_nosc":"renforcer","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforcera","word_nosc":"renforcera","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforcerai","word_nosc":"renforcerai","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforceraient","word_nosc":"renforceraient","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforcerait","word_nosc":"renforcerait","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforcerez","word_nosc":"renforcerez","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforcerons","word_nosc":"renforcerons","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforcez","word_nosc":"renforcez","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforciez","word_nosc":"renforciez","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforcèrent","word_nosc":"renforcerent","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforcé","word_nosc":"renforce","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforcée","word_nosc":"renforcee","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforcées","word_nosc":"renforcees","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforcés","word_nosc":"renforces","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforça","word_nosc":"renforca","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforçaient","word_nosc":"renforcaient","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforçait","word_nosc":"renforcait","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforçant","word_nosc":"renforcant","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforçons","word_nosc":"renforcons","lemma":"renforcer","pos":"VER"} ,
		{"word":"renforçât","word_nosc":"renforcat","lemma":"renforcer","pos":"VER"} ,
		{"word":"renfourché","word_nosc":"renfourche","lemma":"renfourcher","pos":"VER"} ,
		{"word":"renfournait","word_nosc":"renfournait","lemma":"renfourner","pos":"VER"} ,
		{"word":"renfrogna","word_nosc":"renfrogna","lemma":"renfrogner","pos":"VER"} ,
		{"word":"renfrognait","word_nosc":"renfrognait","lemma":"renfrogner","pos":"VER"} ,
		{"word":"renfrognant","word_nosc":"renfrognant","lemma":"renfrogner","pos":"VER"} ,
		{"word":"renfrogne","word_nosc":"renfrogne","lemma":"renfrogner","pos":"VER"} ,
		{"word":"renfrogner","word_nosc":"renfrogner","lemma":"renfrogner","pos":"VER"} ,
		{"word":"renfrognerait","word_nosc":"renfrognerait","lemma":"renfrogner","pos":"VER"} ,
		{"word":"renfrogné","word_nosc":"renfrogne","lemma":"renfrogner","pos":"VER"} ,
		{"word":"renfrognée","word_nosc":"renfrognee","lemma":"renfrogner","pos":"VER"} ,
		{"word":"renfrognés","word_nosc":"renfrognes","lemma":"renfrogner","pos":"VER"} ,
		{"word":"rengageait","word_nosc":"rengageait","lemma":"rengager","pos":"VER"} ,
		{"word":"rengager","word_nosc":"rengager","lemma":"rengager","pos":"VER"} ,
		{"word":"rengagerais","word_nosc":"rengagerais","lemma":"rengager","pos":"VER"} ,
		{"word":"rengagez","word_nosc":"rengagez","lemma":"rengager","pos":"VER"} ,
		{"word":"rengagés","word_nosc":"rengages","lemma":"rengager","pos":"VER"} ,
		{"word":"rengaina","word_nosc":"rengaina","lemma":"rengainer","pos":"VER"} ,
		{"word":"rengainait","word_nosc":"rengainait","lemma":"rengainer","pos":"VER"} ,
		{"word":"rengainant","word_nosc":"rengainant","lemma":"rengainer","pos":"VER"} ,
		{"word":"rengaine","word_nosc":"rengaine","lemma":"rengainer","pos":"VER"} ,
		{"word":"rengainer","word_nosc":"rengainer","lemma":"rengainer","pos":"VER"} ,
		{"word":"rengainez","word_nosc":"rengainez","lemma":"rengainer","pos":"VER"} ,
		{"word":"rengainèrent","word_nosc":"rengainerent","lemma":"rengainer","pos":"VER"} ,
		{"word":"rengainé","word_nosc":"rengaine","lemma":"rengainer","pos":"VER"} ,
		{"word":"rengainés","word_nosc":"rengaines","lemma":"rengainer","pos":"VER"} ,
		{"word":"rengorge","word_nosc":"rengorge","lemma":"rengorger","pos":"VER"} ,
		{"word":"rengorgea","word_nosc":"rengorgea","lemma":"rengorger","pos":"VER"} ,
		{"word":"rengorgeai","word_nosc":"rengorgeai","lemma":"rengorger","pos":"VER"} ,
		{"word":"rengorgeaient","word_nosc":"rengorgeaient","lemma":"rengorger","pos":"VER"} ,
		{"word":"rengorgeais","word_nosc":"rengorgeais","lemma":"rengorger","pos":"VER"} ,
		{"word":"rengorgeait","word_nosc":"rengorgeait","lemma":"rengorger","pos":"VER"} ,
		{"word":"rengorgeant","word_nosc":"rengorgeant","lemma":"rengorger","pos":"VER"} ,
		{"word":"rengorgent","word_nosc":"rengorgent","lemma":"rengorger","pos":"VER"} ,
		{"word":"rengorger","word_nosc":"rengorger","lemma":"rengorger","pos":"VER"} ,
		{"word":"rengorges","word_nosc":"rengorges","lemma":"rengorger","pos":"VER"} ,
		{"word":"rengorgé","word_nosc":"rengorge","lemma":"rengorger","pos":"VER"} ,
		{"word":"rengorgés","word_nosc":"rengorges","lemma":"rengorger","pos":"VER"} ,
		{"word":"rengracie","word_nosc":"rengracie","lemma":"rengracier","pos":"VER"} ,
		{"word":"rengracié","word_nosc":"rengracie","lemma":"rengracier","pos":"VER"} ,
		{"word":"renia","word_nosc":"renia","lemma":"renier","pos":"VER"} ,
		{"word":"reniai","word_nosc":"reniai","lemma":"renier","pos":"VER"} ,
		{"word":"reniaient","word_nosc":"reniaient","lemma":"renier","pos":"VER"} ,
		{"word":"reniais","word_nosc":"reniais","lemma":"renier","pos":"VER"} ,
		{"word":"reniait","word_nosc":"reniait","lemma":"renier","pos":"VER"} ,
		{"word":"reniant","word_nosc":"reniant","lemma":"renier","pos":"VER"} ,
		{"word":"renie","word_nosc":"renie","lemma":"renier","pos":"VER"} ,
		{"word":"renient","word_nosc":"renient","lemma":"renier","pos":"VER"} ,
		{"word":"renier","word_nosc":"renier","lemma":"renier","pos":"VER"} ,
		{"word":"renierai","word_nosc":"renierai","lemma":"renier","pos":"VER"} ,
		{"word":"renierais","word_nosc":"renierais","lemma":"renier","pos":"VER"} ,
		{"word":"renierait","word_nosc":"renierait","lemma":"renier","pos":"VER"} ,
		{"word":"renieras","word_nosc":"renieras","lemma":"renier","pos":"VER"} ,
		{"word":"renieront","word_nosc":"renieront","lemma":"renier","pos":"VER"} ,
		{"word":"renies","word_nosc":"renies","lemma":"renier","pos":"VER"} ,
		{"word":"reniez","word_nosc":"reniez","lemma":"renier","pos":"VER"} ,
		{"word":"renifla","word_nosc":"renifla","lemma":"renifler","pos":"VER"} ,
		{"word":"reniflai","word_nosc":"reniflai","lemma":"renifler","pos":"VER"} ,
		{"word":"reniflaient","word_nosc":"reniflaient","lemma":"renifler","pos":"VER"} ,
		{"word":"reniflais","word_nosc":"reniflais","lemma":"renifler","pos":"VER"} ,
		{"word":"reniflait","word_nosc":"reniflait","lemma":"renifler","pos":"VER"} ,
		{"word":"reniflant","word_nosc":"reniflant","lemma":"renifler","pos":"VER"} ,
		{"word":"renifle","word_nosc":"renifle","lemma":"renifler","pos":"VER"} ,
		{"word":"reniflent","word_nosc":"reniflent","lemma":"renifler","pos":"VER"} ,
		{"word":"renifler","word_nosc":"renifler","lemma":"renifler","pos":"VER"} ,
		{"word":"reniflera","word_nosc":"reniflera","lemma":"renifler","pos":"VER"} ,
		{"word":"reniflerait","word_nosc":"reniflerait","lemma":"renifler","pos":"VER"} ,
		{"word":"reniflez","word_nosc":"reniflez","lemma":"renifler","pos":"VER"} ,
		{"word":"reniflé","word_nosc":"renifle","lemma":"renifler","pos":"VER"} ,
		{"word":"reniflée","word_nosc":"reniflee","lemma":"renifler","pos":"VER"} ,
		{"word":"reniflées","word_nosc":"reniflees","lemma":"renifler","pos":"VER"} ,
		{"word":"renions","word_nosc":"renions","lemma":"renier","pos":"VER"} ,
		{"word":"renièrent","word_nosc":"renierent","lemma":"renier","pos":"VER"} ,
		{"word":"renié","word_nosc":"renie","lemma":"renier","pos":"VER"} ,
		{"word":"reniée","word_nosc":"reniee","lemma":"renier","pos":"VER"} ,
		{"word":"reniées","word_nosc":"reniees","lemma":"renier","pos":"VER"} ,
		{"word":"reniés","word_nosc":"renies","lemma":"renier","pos":"VER"} ,
		{"word":"renommait","word_nosc":"renommait","lemma":"renommer","pos":"VER"} ,
		{"word":"renommer","word_nosc":"renommer","lemma":"renommer","pos":"VER"} ,
		{"word":"renommera","word_nosc":"renommera","lemma":"renommer","pos":"VER"} ,
		{"word":"renommez","word_nosc":"renommez","lemma":"renommer","pos":"VER"} ,
		{"word":"renommé","word_nosc":"renomme","lemma":"renommer","pos":"VER"} ,
		{"word":"renommée","word_nosc":"renommee","lemma":"renommer","pos":"VER"} ,
		{"word":"renommées","word_nosc":"renommees","lemma":"renommer","pos":"VER"} ,
		{"word":"renommés","word_nosc":"renommes","lemma":"renommer","pos":"VER"} ,
		{"word":"renonce","word_nosc":"renonce","lemma":"renoncer","pos":"VER"} ,
		{"word":"renoncent","word_nosc":"renoncent","lemma":"renoncer","pos":"VER"} ,
		{"word":"renoncer","word_nosc":"renoncer","lemma":"renoncer","pos":"VER"} ,
		{"word":"renoncera","word_nosc":"renoncera","lemma":"renoncer","pos":"VER"} ,
		{"word":"renoncerai","word_nosc":"renoncerai","lemma":"renoncer","pos":"VER"} ,
		{"word":"renonceraient","word_nosc":"renonceraient","lemma":"renoncer","pos":"VER"} ,
		{"word":"renoncerais","word_nosc":"renoncerais","lemma":"renoncer","pos":"VER"} ,
		{"word":"renoncerait","word_nosc":"renoncerait","lemma":"renoncer","pos":"VER"} ,
		{"word":"renonceras","word_nosc":"renonceras","lemma":"renoncer","pos":"VER"} ,
		{"word":"renoncerez","word_nosc":"renoncerez","lemma":"renoncer","pos":"VER"} ,
		{"word":"renonceriez","word_nosc":"renonceriez","lemma":"renoncer","pos":"VER"} ,
		{"word":"renoncerions","word_nosc":"renoncerions","lemma":"renoncer","pos":"VER"} ,
		{"word":"renoncerons","word_nosc":"renoncerons","lemma":"renoncer","pos":"VER"} ,
		{"word":"renonceront","word_nosc":"renonceront","lemma":"renoncer","pos":"VER"} ,
		{"word":"renonces","word_nosc":"renonces","lemma":"renoncer","pos":"VER"} ,
		{"word":"renoncez","word_nosc":"renoncez","lemma":"renoncer","pos":"VER"} ,
		{"word":"renonciez","word_nosc":"renonciez","lemma":"renoncer","pos":"VER"} ,
		{"word":"renoncions","word_nosc":"renoncions","lemma":"renoncer","pos":"VER"} ,
		{"word":"renoncèrent","word_nosc":"renoncerent","lemma":"renoncer","pos":"VER"} ,
		{"word":"renoncé","word_nosc":"renonce","lemma":"renoncer","pos":"VER"} ,
		{"word":"renoncée","word_nosc":"renoncee","lemma":"renoncer","pos":"VER"} ,
		{"word":"renonça","word_nosc":"renonca","lemma":"renoncer","pos":"VER"} ,
		{"word":"renonçai","word_nosc":"renoncai","lemma":"renoncer","pos":"VER"} ,
		{"word":"renonçaient","word_nosc":"renoncaient","lemma":"renoncer","pos":"VER"} ,
		{"word":"renonçais","word_nosc":"renoncais","lemma":"renoncer","pos":"VER"} ,
		{"word":"renonçait","word_nosc":"renoncait","lemma":"renoncer","pos":"VER"} ,
		{"word":"renonçant","word_nosc":"renoncant","lemma":"renoncer","pos":"VER"} ,
		{"word":"renonçons","word_nosc":"renoncons","lemma":"renoncer","pos":"VER"} ,
		{"word":"renonçât","word_nosc":"renoncat","lemma":"renoncer","pos":"VER"} ,
		{"word":"renoter","word_nosc":"renoter","lemma":"renoter","pos":"VER"} ,
		{"word":"renoua","word_nosc":"renoua","lemma":"renouer","pos":"VER"} ,
		{"word":"renouai","word_nosc":"renouai","lemma":"renouer","pos":"VER"} ,
		{"word":"renouaient","word_nosc":"renouaient","lemma":"renouer","pos":"VER"} ,
		{"word":"renouais","word_nosc":"renouais","lemma":"renouer","pos":"VER"} ,
		{"word":"renouait","word_nosc":"renouait","lemma":"renouer","pos":"VER"} ,
		{"word":"renouant","word_nosc":"renouant","lemma":"renouer","pos":"VER"} ,
		{"word":"renoue","word_nosc":"renoue","lemma":"renouer","pos":"VER"} ,
		{"word":"renouent","word_nosc":"renouent","lemma":"renouer","pos":"VER"} ,
		{"word":"renouer","word_nosc":"renouer","lemma":"renouer","pos":"VER"} ,
		{"word":"renouerait","word_nosc":"renouerait","lemma":"renouer","pos":"VER"} ,
		{"word":"renoueront","word_nosc":"renoueront","lemma":"renouer","pos":"VER"} ,
		{"word":"renouons","word_nosc":"renouons","lemma":"renouer","pos":"VER"} ,
		{"word":"renouvela","word_nosc":"renouvela","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelai","word_nosc":"renouvelai","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelaient","word_nosc":"renouvelaient","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelais","word_nosc":"renouvelais","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelait","word_nosc":"renouvelait","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelant","word_nosc":"renouvelant","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouveler","word_nosc":"renouveler","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelez","word_nosc":"renouvelez","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelions","word_nosc":"renouvelions","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelle","word_nosc":"renouvelle","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvellent","word_nosc":"renouvellent","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvellera","word_nosc":"renouvellera","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvellerai","word_nosc":"renouvellerai","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvellerait","word_nosc":"renouvellerait","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvellerons","word_nosc":"renouvellerons","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelleront","word_nosc":"renouvelleront","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelles","word_nosc":"renouvelles","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelons","word_nosc":"renouvelons","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelâmes","word_nosc":"renouvelames","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelât","word_nosc":"renouvelat","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelèrent","word_nosc":"renouvelerent","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelé","word_nosc":"renouvele","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelée","word_nosc":"renouvelee","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelées","word_nosc":"renouvelees","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouvelés","word_nosc":"renouveles","lemma":"renouveler","pos":"VER"} ,
		{"word":"renouèrent","word_nosc":"renouerent","lemma":"renouer","pos":"VER"} ,
		{"word":"renoué","word_nosc":"renoue","lemma":"renouer","pos":"VER"} ,
		{"word":"renouée","word_nosc":"renouee","lemma":"renouer","pos":"VER"} ,
		{"word":"renoués","word_nosc":"renoues","lemma":"renouer","pos":"VER"} ,
		{"word":"renquillait","word_nosc":"renquillait","lemma":"renquiller","pos":"VER"} ,
		{"word":"renquille","word_nosc":"renquille","lemma":"renquiller","pos":"VER"} ,
		{"word":"renquillé","word_nosc":"renquille","lemma":"renquiller","pos":"VER"} ,
		{"word":"renseigna","word_nosc":"renseigna","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignai","word_nosc":"renseignai","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignaient","word_nosc":"renseignaient","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignais","word_nosc":"renseignais","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignait","word_nosc":"renseignait","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignant","word_nosc":"renseignant","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseigne","word_nosc":"renseigne","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignent","word_nosc":"renseignent","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseigner","word_nosc":"renseigner","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignera","word_nosc":"renseignera","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignerai","word_nosc":"renseignerai","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseigneraient","word_nosc":"renseigneraient","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignerais","word_nosc":"renseignerais","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignerait","word_nosc":"renseignerait","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseigneras","word_nosc":"renseigneras","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseigneriez","word_nosc":"renseigneriez","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignerons","word_nosc":"renseignerons","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseigneront","word_nosc":"renseigneront","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignez","word_nosc":"renseignez","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignât","word_nosc":"renseignat","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignèrent","word_nosc":"renseignerent","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseigné","word_nosc":"renseigne","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignée","word_nosc":"renseignee","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignées","word_nosc":"renseignees","lemma":"renseigner","pos":"VER"} ,
		{"word":"renseignés","word_nosc":"renseignes","lemma":"renseigner","pos":"VER"} ,
		{"word":"renta","word_nosc":"renta","lemma":"renter","pos":"VER"} ,
		{"word":"rentabilise","word_nosc":"rentabilise","lemma":"rentabiliser","pos":"VER"} ,
		{"word":"rentabiliser","word_nosc":"rentabiliser","lemma":"rentabiliser","pos":"VER"} ,
		{"word":"rentabilisé","word_nosc":"rentabilise","lemma":"rentabiliser","pos":"VER"} ,
		{"word":"rentabilisée","word_nosc":"rentabilisee","lemma":"rentabiliser","pos":"VER"} ,
		{"word":"rentamer","word_nosc":"rentamer","lemma":"rentamer","pos":"VER"} ,
		{"word":"renter","word_nosc":"renter","lemma":"renter","pos":"VER"} ,
		{"word":"rentoilée","word_nosc":"rentoilee","lemma":"rentoiler","pos":"VER"} ,
		{"word":"rentra","word_nosc":"rentra","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrai","word_nosc":"rentrai","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentraient","word_nosc":"rentraient","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrais","word_nosc":"rentrais","lemma":"rentraire","pos":"VER"} ,
		{"word":"rentrais","word_nosc":"rentrais","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrait","word_nosc":"rentrait","lemma":"rentraire","pos":"VER"} ,
		{"word":"rentrait","word_nosc":"rentrait","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrant","word_nosc":"rentrant","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentre","word_nosc":"rentre","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrent","word_nosc":"rentrent","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrer","word_nosc":"rentrer","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrera","word_nosc":"rentrera","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrerai","word_nosc":"rentrerai","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentreraient","word_nosc":"rentreraient","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrerais","word_nosc":"rentrerais","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrerait","word_nosc":"rentrerait","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentreras","word_nosc":"rentreras","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrerez","word_nosc":"rentrerez","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentreriez","word_nosc":"rentreriez","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrerions","word_nosc":"rentrerions","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrerons","word_nosc":"rentrerons","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentreront","word_nosc":"rentreront","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentres","word_nosc":"rentres","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrez","word_nosc":"rentrez","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentriez","word_nosc":"rentriez","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrions","word_nosc":"rentrions","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrons","word_nosc":"rentrons","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrâmes","word_nosc":"rentrames","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrât","word_nosc":"rentrat","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrèrent","word_nosc":"rentrerent","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentré","word_nosc":"rentre","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrée","word_nosc":"rentree","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrées","word_nosc":"rentrees","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentrés","word_nosc":"rentres","lemma":"rentrer","pos":"VER"} ,
		{"word":"rentée","word_nosc":"rentee","lemma":"renter","pos":"VER"} ,
		{"word":"renverra","word_nosc":"renverra","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renverrai","word_nosc":"renverrai","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renverraient","word_nosc":"renverraient","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renverrais","word_nosc":"renverrais","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renverrait","word_nosc":"renverrait","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renverras","word_nosc":"renverras","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renverrez","word_nosc":"renverrez","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renverrions","word_nosc":"renverrions","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renverrons","word_nosc":"renverrons","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renverront","word_nosc":"renverront","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renversa","word_nosc":"renversa","lemma":"renverser","pos":"VER"} ,
		{"word":"renversai","word_nosc":"renversai","lemma":"renverser","pos":"VER"} ,
		{"word":"renversaient","word_nosc":"renversaient","lemma":"renverser","pos":"VER"} ,
		{"word":"renversais","word_nosc":"renversais","lemma":"renverser","pos":"VER"} ,
		{"word":"renversait","word_nosc":"renversait","lemma":"renverser","pos":"VER"} ,
		{"word":"renversant","word_nosc":"renversant","lemma":"renverser","pos":"VER"} ,
		{"word":"renverse","word_nosc":"renverse","lemma":"renverser","pos":"VER"} ,
		{"word":"renversent","word_nosc":"renversent","lemma":"renverser","pos":"VER"} ,
		{"word":"renverser","word_nosc":"renverser","lemma":"renverser","pos":"VER"} ,
		{"word":"renversera","word_nosc":"renversera","lemma":"renverser","pos":"VER"} ,
		{"word":"renverserai","word_nosc":"renverserai","lemma":"renverser","pos":"VER"} ,
		{"word":"renverserais","word_nosc":"renverserais","lemma":"renverser","pos":"VER"} ,
		{"word":"renverserait","word_nosc":"renverserait","lemma":"renverser","pos":"VER"} ,
		{"word":"renverseras","word_nosc":"renverseras","lemma":"renverser","pos":"VER"} ,
		{"word":"renverseront","word_nosc":"renverseront","lemma":"renverser","pos":"VER"} ,
		{"word":"renverses","word_nosc":"renverses","lemma":"renverser","pos":"VER"} ,
		{"word":"renversez","word_nosc":"renversez","lemma":"renverser","pos":"VER"} ,
		{"word":"renversons","word_nosc":"renversons","lemma":"renverser","pos":"VER"} ,
		{"word":"renversât","word_nosc":"renversat","lemma":"renverser","pos":"VER"} ,
		{"word":"renversèrent","word_nosc":"renverserent","lemma":"renverser","pos":"VER"} ,
		{"word":"renversé","word_nosc":"renverse","lemma":"renverser","pos":"VER"} ,
		{"word":"renversée","word_nosc":"renversee","lemma":"renverser","pos":"VER"} ,
		{"word":"renversées","word_nosc":"renversees","lemma":"renverser","pos":"VER"} ,
		{"word":"renversés","word_nosc":"renverses","lemma":"renverser","pos":"VER"} ,
		{"word":"renvoie","word_nosc":"renvoie","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoient","word_nosc":"renvoient","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoies","word_nosc":"renvoies","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoya","word_nosc":"renvoya","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyai","word_nosc":"renvoyai","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyaient","word_nosc":"renvoyaient","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyais","word_nosc":"renvoyais","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyait","word_nosc":"renvoyait","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyant","word_nosc":"renvoyant","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyer","word_nosc":"renvoyer","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyez","word_nosc":"renvoyez","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyiez","word_nosc":"renvoyiez","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyions","word_nosc":"renvoyions","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyons","word_nosc":"renvoyons","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyât","word_nosc":"renvoyat","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyèrent","word_nosc":"renvoyerent","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyé","word_nosc":"renvoye","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyée","word_nosc":"renvoyee","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyées","word_nosc":"renvoyees","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renvoyés","word_nosc":"renvoyes","lemma":"renvoyer","pos":"VER"} ,
		{"word":"renâcla","word_nosc":"renacla","lemma":"renâcler","pos":"VER"} ,
		{"word":"renâclaient","word_nosc":"renaclaient","lemma":"renâcler","pos":"VER"} ,
		{"word":"renâclais","word_nosc":"renaclais","lemma":"renâcler","pos":"VER"} ,
		{"word":"renâclait","word_nosc":"renaclait","lemma":"renâcler","pos":"VER"} ,
		{"word":"renâclant","word_nosc":"renaclant","lemma":"renâcler","pos":"VER"} ,
		{"word":"renâcle","word_nosc":"renacle","lemma":"renâcler","pos":"VER"} ,
		{"word":"renâclent","word_nosc":"renaclent","lemma":"renâcler","pos":"VER"} ,
		{"word":"renâcler","word_nosc":"renacler","lemma":"renâcler","pos":"VER"} ,
		{"word":"renâcles","word_nosc":"renacles","lemma":"renâcler","pos":"VER"} ,
		{"word":"renâclez","word_nosc":"renaclez","lemma":"renâcler","pos":"VER"} ,
		{"word":"renâclions","word_nosc":"renaclions","lemma":"renâcler","pos":"VER"} ,
		{"word":"renâclé","word_nosc":"renacle","lemma":"renâcler","pos":"VER"} ,
		{"word":"rené","word_nosc":"rene","lemma":"renaître","pos":"VER"} ,
		{"word":"renée","word_nosc":"renee","lemma":"renaître","pos":"VER"} ,
		{"word":"renégocie","word_nosc":"renegocie","lemma":"renégocier","pos":"VER"} ,
		{"word":"renégocier","word_nosc":"renegocier","lemma":"renégocier","pos":"VER"} ,
		{"word":"renégociées","word_nosc":"renegociees","lemma":"renégocier","pos":"VER"} ,
		{"word":"renégociés","word_nosc":"renegocies","lemma":"renégocier","pos":"VER"} ,
		{"word":"repaie","word_nosc":"repaie","lemma":"repayer","pos":"VER"} ,
		{"word":"repaies","word_nosc":"repaies","lemma":"repayer","pos":"VER"} ,
		{"word":"repais","word_nosc":"repais","lemma":"repaître","pos":"VER"} ,
		{"word":"repaissaient","word_nosc":"repaissaient","lemma":"repaître","pos":"VER"} ,
		{"word":"repaissais","word_nosc":"repaissais","lemma":"repaître","pos":"VER"} ,
		{"word":"repaissait","word_nosc":"repaissait","lemma":"repaître","pos":"VER"} ,
		{"word":"repaissant","word_nosc":"repaissant","lemma":"repaître","pos":"VER"} ,
		{"word":"repaissent","word_nosc":"repaissent","lemma":"repaître","pos":"VER"} ,
		{"word":"reparais","word_nosc":"reparais","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparaissaient","word_nosc":"reparaissaient","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparaissait","word_nosc":"reparaissait","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparaissant","word_nosc":"reparaissant","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparaisse","word_nosc":"reparaisse","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparaissent","word_nosc":"reparaissent","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparaissez","word_nosc":"reparaissez","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparaissons","word_nosc":"reparaissons","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparaît","word_nosc":"reparait","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparaîtra","word_nosc":"reparaitra","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparaîtrait","word_nosc":"reparaitrait","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparaître","word_nosc":"reparaitre","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparcourant","word_nosc":"reparcourant","lemma":"reparcourir","pos":"VER"} ,
		{"word":"reparcourir","word_nosc":"reparcourir","lemma":"reparcourir","pos":"VER"} ,
		{"word":"reparcouru","word_nosc":"reparcouru","lemma":"reparcourir","pos":"VER"} ,
		{"word":"reparla","word_nosc":"reparla","lemma":"reparler","pos":"VER"} ,
		{"word":"reparlaient","word_nosc":"reparlaient","lemma":"reparler","pos":"VER"} ,
		{"word":"reparlais","word_nosc":"reparlais","lemma":"reparler","pos":"VER"} ,
		{"word":"reparlait","word_nosc":"reparlait","lemma":"reparler","pos":"VER"} ,
		{"word":"reparlant","word_nosc":"reparlant","lemma":"reparler","pos":"VER"} ,
		{"word":"reparle","word_nosc":"reparle","lemma":"reparler","pos":"VER"} ,
		{"word":"reparlent","word_nosc":"reparlent","lemma":"reparler","pos":"VER"} ,
		{"word":"reparler","word_nosc":"reparler","lemma":"reparler","pos":"VER"} ,
		{"word":"reparlera","word_nosc":"reparlera","lemma":"reparler","pos":"VER"} ,
		{"word":"reparlerai","word_nosc":"reparlerai","lemma":"reparler","pos":"VER"} ,
		{"word":"reparlerez","word_nosc":"reparlerez","lemma":"reparler","pos":"VER"} ,
		{"word":"reparlerions","word_nosc":"reparlerions","lemma":"reparler","pos":"VER"} ,
		{"word":"reparlerons","word_nosc":"reparlerons","lemma":"reparler","pos":"VER"} ,
		{"word":"reparles","word_nosc":"reparles","lemma":"reparler","pos":"VER"} ,
		{"word":"reparlez","word_nosc":"reparlez","lemma":"reparler","pos":"VER"} ,
		{"word":"reparliez","word_nosc":"reparliez","lemma":"reparler","pos":"VER"} ,
		{"word":"reparlons","word_nosc":"reparlons","lemma":"reparler","pos":"VER"} ,
		{"word":"reparlâmes","word_nosc":"reparlames","lemma":"reparler","pos":"VER"} ,
		{"word":"reparlé","word_nosc":"reparle","lemma":"reparler","pos":"VER"} ,
		{"word":"repars","word_nosc":"repars","lemma":"repartir","pos":"VER"} ,
		{"word":"repart","word_nosc":"repart","lemma":"repartir","pos":"VER"} ,
		{"word":"repartaient","word_nosc":"repartaient","lemma":"repartir","pos":"VER"} ,
		{"word":"repartais","word_nosc":"repartais","lemma":"repartir","pos":"VER"} ,
		{"word":"repartait","word_nosc":"repartait","lemma":"repartir","pos":"VER"} ,
		{"word":"repartant","word_nosc":"repartant","lemma":"repartir","pos":"VER"} ,
		{"word":"reparte","word_nosc":"reparte","lemma":"repartir","pos":"VER"} ,
		{"word":"repartent","word_nosc":"repartent","lemma":"repartir","pos":"VER"} ,
		{"word":"repartes","word_nosc":"repartes","lemma":"repartir","pos":"VER"} ,
		{"word":"repartez","word_nosc":"repartez","lemma":"repartir","pos":"VER"} ,
		{"word":"reparti","word_nosc":"reparti","lemma":"repartir","pos":"VER"} ,
		{"word":"repartie","word_nosc":"repartie","lemma":"repartir","pos":"VER"} ,
		{"word":"reparties","word_nosc":"reparties","lemma":"repartir","pos":"VER"} ,
		{"word":"repartiez","word_nosc":"repartiez","lemma":"repartir","pos":"VER"} ,
		{"word":"repartions","word_nosc":"repartions","lemma":"repartir","pos":"VER"} ,
		{"word":"repartir","word_nosc":"repartir","lemma":"repartir","pos":"VER"} ,
		{"word":"repartira","word_nosc":"repartira","lemma":"repartir","pos":"VER"} ,
		{"word":"repartirai","word_nosc":"repartirai","lemma":"repartir","pos":"VER"} ,
		{"word":"repartiraient","word_nosc":"repartiraient","lemma":"repartir","pos":"VER"} ,
		{"word":"repartirais","word_nosc":"repartirais","lemma":"repartir","pos":"VER"} ,
		{"word":"repartirait","word_nosc":"repartirait","lemma":"repartir","pos":"VER"} ,
		{"word":"repartiras","word_nosc":"repartiras","lemma":"repartir","pos":"VER"} ,
		{"word":"repartirent","word_nosc":"repartirent","lemma":"repartir","pos":"VER"} ,
		{"word":"repartirez","word_nosc":"repartirez","lemma":"repartir","pos":"VER"} ,
		{"word":"repartirons","word_nosc":"repartirons","lemma":"repartir","pos":"VER"} ,
		{"word":"repartiront","word_nosc":"repartiront","lemma":"repartir","pos":"VER"} ,
		{"word":"repartis","word_nosc":"repartis","lemma":"repartir","pos":"VER"} ,
		{"word":"repartisse","word_nosc":"repartisse","lemma":"repartir","pos":"VER"} ,
		{"word":"repartit","word_nosc":"repartit","lemma":"repartir","pos":"VER"} ,
		{"word":"repartons","word_nosc":"repartons","lemma":"repartir","pos":"VER"} ,
		{"word":"repartîmes","word_nosc":"repartimes","lemma":"repartir","pos":"VER"} ,
		{"word":"repartît","word_nosc":"repartit","lemma":"repartir","pos":"VER"} ,
		{"word":"reparu","word_nosc":"reparu","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparue","word_nosc":"reparue","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparurent","word_nosc":"reparurent","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparut","word_nosc":"reparut","lemma":"reparaître","pos":"VER"} ,
		{"word":"reparût","word_nosc":"reparut","lemma":"reparaître","pos":"VER"} ,
		{"word":"repassa","word_nosc":"repassa","lemma":"repasser","pos":"VER"} ,
		{"word":"repassai","word_nosc":"repassai","lemma":"repasser","pos":"VER"} ,
		{"word":"repassaient","word_nosc":"repassaient","lemma":"repasser","pos":"VER"} ,
		{"word":"repassais","word_nosc":"repassais","lemma":"repasser","pos":"VER"} ,
		{"word":"repassait","word_nosc":"repassait","lemma":"repasser","pos":"VER"} ,
		{"word":"repassant","word_nosc":"repassant","lemma":"repasser","pos":"VER"} ,
		{"word":"repasse","word_nosc":"repasse","lemma":"repasser","pos":"VER"} ,
		{"word":"repassent","word_nosc":"repassent","lemma":"repasser","pos":"VER"} ,
		{"word":"repasser","word_nosc":"repasser","lemma":"repasser","pos":"VER"} ,
		{"word":"repassera","word_nosc":"repassera","lemma":"repasser","pos":"VER"} ,
		{"word":"repasserai","word_nosc":"repasserai","lemma":"repasser","pos":"VER"} ,
		{"word":"repasseraient","word_nosc":"repasseraient","lemma":"repasser","pos":"VER"} ,
		{"word":"repasserais","word_nosc":"repasserais","lemma":"repasser","pos":"VER"} ,
		{"word":"repasserait","word_nosc":"repasserait","lemma":"repasser","pos":"VER"} ,
		{"word":"repasseras","word_nosc":"repasseras","lemma":"repasser","pos":"VER"} ,
		{"word":"repasserez","word_nosc":"repasserez","lemma":"repasser","pos":"VER"} ,
		{"word":"repasserons","word_nosc":"repasserons","lemma":"repasser","pos":"VER"} ,
		{"word":"repasseront","word_nosc":"repasseront","lemma":"repasser","pos":"VER"} ,
		{"word":"repasses","word_nosc":"repasses","lemma":"repasser","pos":"VER"} ,
		{"word":"repassez","word_nosc":"repassez","lemma":"repasser","pos":"VER"} ,
		{"word":"repassiez","word_nosc":"repassiez","lemma":"repasser","pos":"VER"} ,
		{"word":"repassions","word_nosc":"repassions","lemma":"repasser","pos":"VER"} ,
		{"word":"repassons","word_nosc":"repassons","lemma":"repasser","pos":"VER"} ,
		{"word":"repassé","word_nosc":"repasse","lemma":"repasser","pos":"VER"} ,
		{"word":"repassée","word_nosc":"repassee","lemma":"repasser","pos":"VER"} ,
		{"word":"repassées","word_nosc":"repassees","lemma":"repasser","pos":"VER"} ,
		{"word":"repassés","word_nosc":"repasses","lemma":"repasser","pos":"VER"} ,
		{"word":"repaver","word_nosc":"repaver","lemma":"repaver","pos":"VER"} ,
		{"word":"repavé","word_nosc":"repave","lemma":"repaver","pos":"VER"} ,
		{"word":"repayais","word_nosc":"repayais","lemma":"repayer","pos":"VER"} ,
		{"word":"repayait","word_nosc":"repayait","lemma":"repayer","pos":"VER"} ,
		{"word":"repaye","word_nosc":"repaye","lemma":"repayer","pos":"VER"} ,
		{"word":"repayer","word_nosc":"repayer","lemma":"repayer","pos":"VER"} ,
		{"word":"repayé","word_nosc":"repaye","lemma":"repayer","pos":"VER"} ,
		{"word":"repaît","word_nosc":"repait","lemma":"repaître","pos":"VER"} ,
		{"word":"repaître","word_nosc":"repaitre","lemma":"repaître","pos":"VER"} ,
		{"word":"repeigna","word_nosc":"repeigna","lemma":"repeigner","pos":"VER"} ,
		{"word":"repeignais","word_nosc":"repeignais","lemma":"repeigner","pos":"VER"} ,
		{"word":"repeignait","word_nosc":"repeignait","lemma":"repeigner","pos":"VER"} ,
		{"word":"repeignant","word_nosc":"repeignant","lemma":"repeigner","pos":"VER"} ,
		{"word":"repeigne","word_nosc":"repeigne","lemma":"repeigner","pos":"VER"} ,
		{"word":"repeignent","word_nosc":"repeignent","lemma":"repeigner","pos":"VER"} ,
		{"word":"repeignez","word_nosc":"repeignez","lemma":"repeigner","pos":"VER"} ,
		{"word":"repeignit","word_nosc":"repeignit","lemma":"repeindre","pos":"VER"} ,
		{"word":"repeigné","word_nosc":"repeigne","lemma":"repeigner","pos":"VER"} ,
		{"word":"repeignée","word_nosc":"repeignee","lemma":"repeigner","pos":"VER"} ,
		{"word":"repeignées","word_nosc":"repeignees","lemma":"repeigner","pos":"VER"} ,
		{"word":"repeindra","word_nosc":"repeindra","lemma":"repeindre","pos":"VER"} ,
		{"word":"repeindrai","word_nosc":"repeindrai","lemma":"repeindre","pos":"VER"} ,
		{"word":"repeindrait","word_nosc":"repeindrait","lemma":"repeindre","pos":"VER"} ,
		{"word":"repeindre","word_nosc":"repeindre","lemma":"repeindre","pos":"VER"} ,
		{"word":"repeins","word_nosc":"repeins","lemma":"repeindre","pos":"VER"} ,
		{"word":"repeint","word_nosc":"repeint","lemma":"repeindre","pos":"VER"} ,
		{"word":"repeinte","word_nosc":"repeinte","lemma":"repeindre","pos":"VER"} ,
		{"word":"repeintes","word_nosc":"repeintes","lemma":"repeindre","pos":"VER"} ,
		{"word":"repeints","word_nosc":"repeints","lemma":"repeindre","pos":"VER"} ,
		{"word":"rependra","word_nosc":"rependra","lemma":"rependre","pos":"VER"} ,
		{"word":"rependre","word_nosc":"rependre","lemma":"rependre","pos":"VER"} ,
		{"word":"repends","word_nosc":"repends","lemma":"rependre","pos":"VER"} ,
		{"word":"rependu","word_nosc":"rependu","lemma":"rependre","pos":"VER"} ,
		{"word":"rependus","word_nosc":"rependus","lemma":"rependre","pos":"VER"} ,
		{"word":"repens","word_nosc":"repens","lemma":"repentir","pos":"VER"} ,
		{"word":"repensa","word_nosc":"repensa","lemma":"repenser","pos":"VER"} ,
		{"word":"repensai","word_nosc":"repensai","lemma":"repenser","pos":"VER"} ,
		{"word":"repensais","word_nosc":"repensais","lemma":"repenser","pos":"VER"} ,
		{"word":"repensait","word_nosc":"repensait","lemma":"repenser","pos":"VER"} ,
		{"word":"repensant","word_nosc":"repensant","lemma":"repenser","pos":"VER"} ,
		{"word":"repense","word_nosc":"repense","lemma":"repenser","pos":"VER"} ,
		{"word":"repensent","word_nosc":"repensent","lemma":"repenser","pos":"VER"} ,
		{"word":"repenser","word_nosc":"repenser","lemma":"repenser","pos":"VER"} ,
		{"word":"repensera","word_nosc":"repensera","lemma":"repenser","pos":"VER"} ,
		{"word":"repenserai","word_nosc":"repenserai","lemma":"repenser","pos":"VER"} ,
		{"word":"repenserais","word_nosc":"repenserais","lemma":"repenser","pos":"VER"} ,
		{"word":"repenserez","word_nosc":"repenserez","lemma":"repenser","pos":"VER"} ,
		{"word":"repenses","word_nosc":"repenses","lemma":"repenser","pos":"VER"} ,
		{"word":"repensez","word_nosc":"repensez","lemma":"repenser","pos":"VER"} ,
		{"word":"repensons","word_nosc":"repensons","lemma":"repenser","pos":"VER"} ,
		{"word":"repensé","word_nosc":"repense","lemma":"repenser","pos":"VER"} ,
		{"word":"repensée","word_nosc":"repensee","lemma":"repenser","pos":"VER"} ,
		{"word":"repent","word_nosc":"repent","lemma":"repentir","pos":"VER"} ,
		{"word":"repentaient","word_nosc":"repentaient","lemma":"repentir","pos":"VER"} ,
		{"word":"repentait","word_nosc":"repentait","lemma":"repentir","pos":"VER"} ,
		{"word":"repentant","word_nosc":"repentant","lemma":"repentir","pos":"VER"} ,
		{"word":"repentent","word_nosc":"repentent","lemma":"repentir","pos":"VER"} ,
		{"word":"repentez","word_nosc":"repentez","lemma":"repentir","pos":"VER"} ,
		{"word":"repenti","word_nosc":"repenti","lemma":"repentir","pos":"VER"} ,
		{"word":"repentie","word_nosc":"repentie","lemma":"repentir","pos":"VER"} ,
		{"word":"repenties","word_nosc":"repenties","lemma":"repentir","pos":"VER"} ,
		{"word":"repentir","word_nosc":"repentir","lemma":"repentir","pos":"VER"} ,
		{"word":"repentira","word_nosc":"repentira","lemma":"repentir","pos":"VER"} ,
		{"word":"repentirai","word_nosc":"repentirai","lemma":"repentir","pos":"VER"} ,
		{"word":"repentirais","word_nosc":"repentirais","lemma":"repentir","pos":"VER"} ,
		{"word":"repentiras","word_nosc":"repentiras","lemma":"repentir","pos":"VER"} ,
		{"word":"repentirez","word_nosc":"repentirez","lemma":"repentir","pos":"VER"} ,
		{"word":"repentis","word_nosc":"repentis","lemma":"repentir","pos":"VER"} ,
		{"word":"repentisse","word_nosc":"repentisse","lemma":"repentir","pos":"VER"} ,
		{"word":"repentissent","word_nosc":"repentissent","lemma":"repentir","pos":"VER"} ,
		{"word":"repentit","word_nosc":"repentit","lemma":"repentir","pos":"VER"} ,
		{"word":"repentons","word_nosc":"repentons","lemma":"repentir","pos":"VER"} ,
		{"word":"reperdaient","word_nosc":"reperdaient","lemma":"reperdre","pos":"VER"} ,
		{"word":"reperdais","word_nosc":"reperdais","lemma":"reperdre","pos":"VER"} ,
		{"word":"reperdons","word_nosc":"reperdons","lemma":"reperdre","pos":"VER"} ,
		{"word":"reperdre","word_nosc":"reperdre","lemma":"reperdre","pos":"VER"} ,
		{"word":"reperds","word_nosc":"reperds","lemma":"reperdre","pos":"VER"} ,
		{"word":"reperdu","word_nosc":"reperdu","lemma":"reperdre","pos":"VER"} ,
		{"word":"reperdue","word_nosc":"reperdue","lemma":"reperdre","pos":"VER"} ,
		{"word":"reperdues","word_nosc":"reperdues","lemma":"reperdre","pos":"VER"} ,
		{"word":"repeuplaient","word_nosc":"repeuplaient","lemma":"repeupler","pos":"VER"} ,
		{"word":"repeuplait","word_nosc":"repeuplait","lemma":"repeupler","pos":"VER"} ,
		{"word":"repeuple","word_nosc":"repeuple","lemma":"repeupler","pos":"VER"} ,
		{"word":"repeupler","word_nosc":"repeupler","lemma":"repeupler","pos":"VER"} ,
		{"word":"repeuplée","word_nosc":"repeuplee","lemma":"repeupler","pos":"VER"} ,
		{"word":"repeuplées","word_nosc":"repeuplees","lemma":"repeupler","pos":"VER"} ,
		{"word":"repincer","word_nosc":"repincer","lemma":"repincer","pos":"VER"} ,
		{"word":"repincé","word_nosc":"repince","lemma":"repincer","pos":"VER"} ,
		{"word":"repiqua","word_nosc":"repiqua","lemma":"repiquer","pos":"VER"} ,
		{"word":"repiquaient","word_nosc":"repiquaient","lemma":"repiquer","pos":"VER"} ,
		{"word":"repiquait","word_nosc":"repiquait","lemma":"repiquer","pos":"VER"} ,
		{"word":"repique","word_nosc":"repique","lemma":"repiquer","pos":"VER"} ,
		{"word":"repiquer","word_nosc":"repiquer","lemma":"repiquer","pos":"VER"} ,
		{"word":"repiquerai","word_nosc":"repiquerai","lemma":"repiquer","pos":"VER"} ,
		{"word":"repiqué","word_nosc":"repique","lemma":"repiquer","pos":"VER"} ,
		{"word":"repiquées","word_nosc":"repiquees","lemma":"repiquer","pos":"VER"} ,
		{"word":"replace","word_nosc":"replace","lemma":"replacer","pos":"VER"} ,
		{"word":"replacent","word_nosc":"replacent","lemma":"replacer","pos":"VER"} ,
		{"word":"replacer","word_nosc":"replacer","lemma":"replacer","pos":"VER"} ,
		{"word":"replacerai","word_nosc":"replacerai","lemma":"replacer","pos":"VER"} ,
		{"word":"replacerez","word_nosc":"replacerez","lemma":"replacer","pos":"VER"} ,
		{"word":"replacez","word_nosc":"replacez","lemma":"replacer","pos":"VER"} ,
		{"word":"replacèrent","word_nosc":"replacerent","lemma":"replacer","pos":"VER"} ,
		{"word":"replacé","word_nosc":"replace","lemma":"replacer","pos":"VER"} ,
		{"word":"replacée","word_nosc":"replacee","lemma":"replacer","pos":"VER"} ,
		{"word":"replacés","word_nosc":"replaces","lemma":"replacer","pos":"VER"} ,
		{"word":"replanta","word_nosc":"replanta","lemma":"replanter","pos":"VER"} ,
		{"word":"replantait","word_nosc":"replantait","lemma":"replanter","pos":"VER"} ,
		{"word":"replante","word_nosc":"replante","lemma":"replanter","pos":"VER"} ,
		{"word":"replantent","word_nosc":"replantent","lemma":"replanter","pos":"VER"} ,
		{"word":"replanter","word_nosc":"replanter","lemma":"replanter","pos":"VER"} ,
		{"word":"replantez","word_nosc":"replantez","lemma":"replanter","pos":"VER"} ,
		{"word":"replanté","word_nosc":"replante","lemma":"replanter","pos":"VER"} ,
		{"word":"replantée","word_nosc":"replantee","lemma":"replanter","pos":"VER"} ,
		{"word":"replantées","word_nosc":"replantees","lemma":"replanter","pos":"VER"} ,
		{"word":"replantés","word_nosc":"replantes","lemma":"replanter","pos":"VER"} ,
		{"word":"replaça","word_nosc":"replaca","lemma":"replacer","pos":"VER"} ,
		{"word":"replaçaient","word_nosc":"replacaient","lemma":"replacer","pos":"VER"} ,
		{"word":"replaçais","word_nosc":"replacais","lemma":"replacer","pos":"VER"} ,
		{"word":"replaçait","word_nosc":"replacait","lemma":"replacer","pos":"VER"} ,
		{"word":"replaçant","word_nosc":"replacant","lemma":"replacer","pos":"VER"} ,
		{"word":"replaçons","word_nosc":"replacons","lemma":"replacer","pos":"VER"} ,
		{"word":"replia","word_nosc":"replia","lemma":"replier","pos":"VER"} ,
		{"word":"repliai","word_nosc":"repliai","lemma":"replier","pos":"VER"} ,
		{"word":"repliaient","word_nosc":"repliaient","lemma":"replier","pos":"VER"} ,
		{"word":"repliais","word_nosc":"repliais","lemma":"replier","pos":"VER"} ,
		{"word":"repliait","word_nosc":"repliait","lemma":"replier","pos":"VER"} ,
		{"word":"repliant","word_nosc":"repliant","lemma":"replier","pos":"VER"} ,
		{"word":"replie","word_nosc":"replie","lemma":"replier","pos":"VER"} ,
		{"word":"replient","word_nosc":"replient","lemma":"replier","pos":"VER"} ,
		{"word":"replier","word_nosc":"replier","lemma":"replier","pos":"VER"} ,
		{"word":"repliera","word_nosc":"repliera","lemma":"replier","pos":"VER"} ,
		{"word":"replierai","word_nosc":"replierai","lemma":"replier","pos":"VER"} ,
		{"word":"replierez","word_nosc":"replierez","lemma":"replier","pos":"VER"} ,
		{"word":"replierons","word_nosc":"replierons","lemma":"replier","pos":"VER"} ,
		{"word":"replieront","word_nosc":"replieront","lemma":"replier","pos":"VER"} ,
		{"word":"replies","word_nosc":"replies","lemma":"replier","pos":"VER"} ,
		{"word":"repliez","word_nosc":"repliez","lemma":"replier","pos":"VER"} ,
		{"word":"replions","word_nosc":"replions","lemma":"replier","pos":"VER"} ,
		{"word":"repliât","word_nosc":"repliat","lemma":"replier","pos":"VER"} ,
		{"word":"replièrent","word_nosc":"replierent","lemma":"replier","pos":"VER"} ,
		{"word":"replié","word_nosc":"replie","lemma":"replier","pos":"VER"} ,
		{"word":"repliée","word_nosc":"repliee","lemma":"replier","pos":"VER"} ,
		{"word":"repliées","word_nosc":"repliees","lemma":"replier","pos":"VER"} ,
		{"word":"repliés","word_nosc":"replies","lemma":"replier","pos":"VER"} ,
		{"word":"replonge","word_nosc":"replonge","lemma":"replonger","pos":"VER"} ,
		{"word":"replongea","word_nosc":"replongea","lemma":"replonger","pos":"VER"} ,
		{"word":"replongeai","word_nosc":"replongeai","lemma":"replonger","pos":"VER"} ,
		{"word":"replongeaient","word_nosc":"replongeaient","lemma":"replonger","pos":"VER"} ,
		{"word":"replongeais","word_nosc":"replongeais","lemma":"replonger","pos":"VER"} ,
		{"word":"replongeait","word_nosc":"replongeait","lemma":"replonger","pos":"VER"} ,
		{"word":"replongeant","word_nosc":"replongeant","lemma":"replonger","pos":"VER"} ,
		{"word":"replongent","word_nosc":"replongent","lemma":"replonger","pos":"VER"} ,
		{"word":"replonger","word_nosc":"replonger","lemma":"replonger","pos":"VER"} ,
		{"word":"replongera","word_nosc":"replongera","lemma":"replonger","pos":"VER"} ,
		{"word":"replongerai","word_nosc":"replongerai","lemma":"replonger","pos":"VER"} ,
		{"word":"replongerais","word_nosc":"replongerais","lemma":"replonger","pos":"VER"} ,
		{"word":"replongerait","word_nosc":"replongerait","lemma":"replonger","pos":"VER"} ,
		{"word":"replonges","word_nosc":"replonges","lemma":"replonger","pos":"VER"} ,
		{"word":"replongiez","word_nosc":"replongiez","lemma":"replonger","pos":"VER"} ,
		{"word":"replongé","word_nosc":"replonge","lemma":"replonger","pos":"VER"} ,
		{"word":"replongée","word_nosc":"replongee","lemma":"replonger","pos":"VER"} ,
		{"word":"replongées","word_nosc":"replongees","lemma":"replonger","pos":"VER"} ,
		{"word":"replongés","word_nosc":"replonges","lemma":"replonger","pos":"VER"} ,
		{"word":"reployait","word_nosc":"reployait","lemma":"reployer","pos":"VER"} ,
		{"word":"replâtra","word_nosc":"replatra","lemma":"replâtrer","pos":"VER"} ,
		{"word":"replâtraient","word_nosc":"replatraient","lemma":"replâtrer","pos":"VER"} ,
		{"word":"replâtrer","word_nosc":"replatrer","lemma":"replâtrer","pos":"VER"} ,
		{"word":"replâtrerait","word_nosc":"replatrerait","lemma":"replâtrer","pos":"VER"} ,
		{"word":"replâtré","word_nosc":"replatre","lemma":"replâtrer","pos":"VER"} ,
		{"word":"replâtrée","word_nosc":"replatree","lemma":"replâtrer","pos":"VER"} ,
		{"word":"replâtrées","word_nosc":"replatrees","lemma":"replâtrer","pos":"VER"} ,
		{"word":"reporta","word_nosc":"reporta","lemma":"reporter","pos":"VER"} ,
		{"word":"reportait","word_nosc":"reportait","lemma":"reporter","pos":"VER"} ,
		{"word":"reportant","word_nosc":"reportant","lemma":"reporter","pos":"VER"} ,
		{"word":"reporte","word_nosc":"reporte","lemma":"reporter","pos":"VER"} ,
		{"word":"reportent","word_nosc":"reportent","lemma":"reporter","pos":"VER"} ,
		{"word":"reporter","word_nosc":"reporter","lemma":"reporter","pos":"VER"} ,
		{"word":"reportera","word_nosc":"reportera","lemma":"reporter","pos":"VER"} ,
		{"word":"reporterai","word_nosc":"reporterai","lemma":"reporter","pos":"VER"} ,
		{"word":"reporterait","word_nosc":"reporterait","lemma":"reporter","pos":"VER"} ,
		{"word":"reportez","word_nosc":"reportez","lemma":"reporter","pos":"VER"} ,
		{"word":"reportons","word_nosc":"reportons","lemma":"reporter","pos":"VER"} ,
		{"word":"reportèrent","word_nosc":"reporterent","lemma":"reporter","pos":"VER"} ,
		{"word":"reporté","word_nosc":"reporte","lemma":"reporter","pos":"VER"} ,
		{"word":"reportée","word_nosc":"reportee","lemma":"reporter","pos":"VER"} ,
		{"word":"reportées","word_nosc":"reportees","lemma":"reporter","pos":"VER"} ,
		{"word":"reportés","word_nosc":"reportes","lemma":"reporter","pos":"VER"} ,
		{"word":"reposa","word_nosc":"reposa","lemma":"reposer","pos":"VER"} ,
		{"word":"reposai","word_nosc":"reposai","lemma":"reposer","pos":"VER"} ,
		{"word":"reposaient","word_nosc":"reposaient","lemma":"reposer","pos":"VER"} ,
		{"word":"reposais","word_nosc":"reposais","lemma":"reposer","pos":"VER"} ,
		{"word":"reposait","word_nosc":"reposait","lemma":"reposer","pos":"VER"} ,
		{"word":"reposant","word_nosc":"reposant","lemma":"reposer","pos":"VER"} ,
		{"word":"repose","word_nosc":"repose","lemma":"reposer","pos":"VER"} ,
		{"word":"reposent","word_nosc":"reposent","lemma":"reposer","pos":"VER"} ,
		{"word":"reposer","word_nosc":"reposer","lemma":"reposer","pos":"VER"} ,
		{"word":"reposera","word_nosc":"reposera","lemma":"reposer","pos":"VER"} ,
		{"word":"reposerai","word_nosc":"reposerai","lemma":"reposer","pos":"VER"} ,
		{"word":"reposeraient","word_nosc":"reposeraient","lemma":"reposer","pos":"VER"} ,
		{"word":"reposerais","word_nosc":"reposerais","lemma":"reposer","pos":"VER"} ,
		{"word":"reposerait","word_nosc":"reposerait","lemma":"reposer","pos":"VER"} ,
		{"word":"reposeras","word_nosc":"reposeras","lemma":"reposer","pos":"VER"} ,
		{"word":"reposerez","word_nosc":"reposerez","lemma":"reposer","pos":"VER"} ,
		{"word":"reposerions","word_nosc":"reposerions","lemma":"reposer","pos":"VER"} ,
		{"word":"reposerons","word_nosc":"reposerons","lemma":"reposer","pos":"VER"} ,
		{"word":"reposeront","word_nosc":"reposeront","lemma":"reposer","pos":"VER"} ,
		{"word":"reposes","word_nosc":"reposes","lemma":"reposer","pos":"VER"} ,
		{"word":"reposez","word_nosc":"reposez","lemma":"reposer","pos":"VER"} ,
		{"word":"reposiez","word_nosc":"reposiez","lemma":"reposer","pos":"VER"} ,
		{"word":"reposions","word_nosc":"reposions","lemma":"reposer","pos":"VER"} ,
		{"word":"repositionnent","word_nosc":"repositionnent","lemma":"repositionner","pos":"VER"} ,
		{"word":"repositionner","word_nosc":"repositionner","lemma":"repositionner","pos":"VER"} ,
		{"word":"repositionnez","word_nosc":"repositionnez","lemma":"repositionner","pos":"VER"} ,
		{"word":"reposons","word_nosc":"reposons","lemma":"reposer","pos":"VER"} ,
		{"word":"reposséder","word_nosc":"reposseder","lemma":"reposséder","pos":"VER"} ,
		{"word":"repostait","word_nosc":"repostait","lemma":"reposter","pos":"VER"} ,
		{"word":"reposât","word_nosc":"reposat","lemma":"reposer","pos":"VER"} ,
		{"word":"reposèrent","word_nosc":"reposerent","lemma":"reposer","pos":"VER"} ,
		{"word":"reposé","word_nosc":"repose","lemma":"reposer","pos":"VER"} ,
		{"word":"reposée","word_nosc":"reposee","lemma":"reposer","pos":"VER"} ,
		{"word":"reposées","word_nosc":"reposees","lemma":"reposer","pos":"VER"} ,
		{"word":"reposés","word_nosc":"reposes","lemma":"reposer","pos":"VER"} ,
		{"word":"repoudraient","word_nosc":"repoudraient","lemma":"repoudrer","pos":"VER"} ,
		{"word":"repoudrait","word_nosc":"repoudrait","lemma":"repoudrer","pos":"VER"} ,
		{"word":"repoudrant","word_nosc":"repoudrant","lemma":"repoudrer","pos":"VER"} ,
		{"word":"repoudre","word_nosc":"repoudre","lemma":"repoudrer","pos":"VER"} ,
		{"word":"repoudrer","word_nosc":"repoudrer","lemma":"repoudrer","pos":"VER"} ,
		{"word":"repoudré","word_nosc":"repoudre","lemma":"repoudrer","pos":"VER"} ,
		{"word":"repoudrée","word_nosc":"repoudree","lemma":"repoudrer","pos":"VER"} ,
		{"word":"repoussa","word_nosc":"repoussa","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussai","word_nosc":"repoussai","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussaient","word_nosc":"repoussaient","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussais","word_nosc":"repoussais","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussait","word_nosc":"repoussait","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussant","word_nosc":"repoussant","lemma":"repousser","pos":"VER"} ,
		{"word":"repousse","word_nosc":"repousse","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussent","word_nosc":"repoussent","lemma":"repousser","pos":"VER"} ,
		{"word":"repousser","word_nosc":"repousser","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussera","word_nosc":"repoussera","lemma":"repousser","pos":"VER"} ,
		{"word":"repousserai","word_nosc":"repousserai","lemma":"repousser","pos":"VER"} ,
		{"word":"repousseraient","word_nosc":"repousseraient","lemma":"repousser","pos":"VER"} ,
		{"word":"repousserais","word_nosc":"repousserais","lemma":"repousser","pos":"VER"} ,
		{"word":"repousserait","word_nosc":"repousserait","lemma":"repousser","pos":"VER"} ,
		{"word":"repousserons","word_nosc":"repousserons","lemma":"repousser","pos":"VER"} ,
		{"word":"repousseront","word_nosc":"repousseront","lemma":"repousser","pos":"VER"} ,
		{"word":"repousses","word_nosc":"repousses","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussez","word_nosc":"repoussez","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussions","word_nosc":"repoussions","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussons","word_nosc":"repoussons","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussâmes","word_nosc":"repoussames","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussât","word_nosc":"repoussat","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussèrent","word_nosc":"repousserent","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussé","word_nosc":"repousse","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussée","word_nosc":"repoussee","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussées","word_nosc":"repoussees","lemma":"repousser","pos":"VER"} ,
		{"word":"repoussés","word_nosc":"repousses","lemma":"repousser","pos":"VER"} ,
		{"word":"reprenaient","word_nosc":"reprenaient","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprenais","word_nosc":"reprenais","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprenait","word_nosc":"reprenait","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprenant","word_nosc":"reprenant","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprend","word_nosc":"reprend","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprendra","word_nosc":"reprendra","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprendrai","word_nosc":"reprendrai","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprendraient","word_nosc":"reprendraient","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprendrais","word_nosc":"reprendrais","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprendrait","word_nosc":"reprendrait","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprendras","word_nosc":"reprendras","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprendre","word_nosc":"reprendre","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprendrez","word_nosc":"reprendrez","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprendriez","word_nosc":"reprendriez","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprendrions","word_nosc":"reprendrions","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprendrons","word_nosc":"reprendrons","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprendront","word_nosc":"reprendront","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprends","word_nosc":"reprends","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprenez","word_nosc":"reprenez","lemma":"reprendre","pos":"VER"} ,
		{"word":"repreniez","word_nosc":"repreniez","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprenions","word_nosc":"reprenions","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprenne","word_nosc":"reprenne","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprennent","word_nosc":"reprennent","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprennes","word_nosc":"reprennes","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprenons","word_nosc":"reprenons","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprirent","word_nosc":"reprirent","lemma":"reprendre","pos":"VER"} ,
		{"word":"repris","word_nosc":"repris","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprisaient","word_nosc":"reprisaient","lemma":"repriser","pos":"VER"} ,
		{"word":"reprisait","word_nosc":"reprisait","lemma":"repriser","pos":"VER"} ,
		{"word":"reprisant","word_nosc":"reprisant","lemma":"repriser","pos":"VER"} ,
		{"word":"reprise","word_nosc":"reprise","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprise","word_nosc":"reprise","lemma":"repriser","pos":"VER"} ,
		{"word":"reprisent","word_nosc":"reprisent","lemma":"repriser","pos":"VER"} ,
		{"word":"repriser","word_nosc":"repriser","lemma":"repriser","pos":"VER"} ,
		{"word":"reprises","word_nosc":"reprises","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprises","word_nosc":"reprises","lemma":"repriser","pos":"VER"} ,
		{"word":"reprisse","word_nosc":"reprisse","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprissent","word_nosc":"reprissent","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprisé","word_nosc":"reprise","lemma":"repriser","pos":"VER"} ,
		{"word":"reprisée","word_nosc":"reprisee","lemma":"repriser","pos":"VER"} ,
		{"word":"reprisées","word_nosc":"reprisees","lemma":"repriser","pos":"VER"} ,
		{"word":"reprisés","word_nosc":"reprises","lemma":"repriser","pos":"VER"} ,
		{"word":"reprit","word_nosc":"reprit","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprocha","word_nosc":"reprocha","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochai","word_nosc":"reprochai","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochaient","word_nosc":"reprochaient","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochais","word_nosc":"reprochais","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochait","word_nosc":"reprochait","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochant","word_nosc":"reprochant","lemma":"reprocher","pos":"VER"} ,
		{"word":"reproche","word_nosc":"reproche","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochent","word_nosc":"reprochent","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprocher","word_nosc":"reprocher","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochera","word_nosc":"reprochera","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprocherai","word_nosc":"reprocherai","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprocherais","word_nosc":"reprocherais","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprocherait","word_nosc":"reprocherait","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprocheras","word_nosc":"reprocheras","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprocheriez","word_nosc":"reprocheriez","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprocherons","word_nosc":"reprocherons","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprocheront","word_nosc":"reprocheront","lemma":"reprocher","pos":"VER"} ,
		{"word":"reproches","word_nosc":"reproches","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochez","word_nosc":"reprochez","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochiez","word_nosc":"reprochiez","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochions","word_nosc":"reprochions","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochons","word_nosc":"reprochons","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochât","word_nosc":"reprochat","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochèrent","word_nosc":"reprocherent","lemma":"reprocher","pos":"VER"} ,
		{"word":"reproché","word_nosc":"reproche","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochée","word_nosc":"reprochee","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochées","word_nosc":"reprochees","lemma":"reprocher","pos":"VER"} ,
		{"word":"reprochés","word_nosc":"reproches","lemma":"reprocher","pos":"VER"} ,
		{"word":"reproduira","word_nosc":"reproduira","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduirai","word_nosc":"reproduirai","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduiraient","word_nosc":"reproduiraient","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduirait","word_nosc":"reproduirait","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduire","word_nosc":"reproduire","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduirons","word_nosc":"reproduirons","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduiront","word_nosc":"reproduiront","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduis","word_nosc":"reproduis","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduisaient","word_nosc":"reproduisaient","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduisait","word_nosc":"reproduisait","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduisant","word_nosc":"reproduisant","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduise","word_nosc":"reproduise","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduisent","word_nosc":"reproduisent","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduisez","word_nosc":"reproduisez","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduisirent","word_nosc":"reproduisirent","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduisit","word_nosc":"reproduisit","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduisons","word_nosc":"reproduisons","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduisît","word_nosc":"reproduisit","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduit","word_nosc":"reproduit","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduite","word_nosc":"reproduite","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduites","word_nosc":"reproduites","lemma":"reproduire","pos":"VER"} ,
		{"word":"reproduits","word_nosc":"reproduits","lemma":"reproduire","pos":"VER"} ,
		{"word":"reprogrammant","word_nosc":"reprogrammant","lemma":"reprogrammer","pos":"VER"} ,
		{"word":"reprogramme","word_nosc":"reprogramme","lemma":"reprogrammer","pos":"VER"} ,
		{"word":"reprogramment","word_nosc":"reprogramment","lemma":"reprogrammer","pos":"VER"} ,
		{"word":"reprogrammer","word_nosc":"reprogrammer","lemma":"reprogrammer","pos":"VER"} ,
		{"word":"reprogrammera","word_nosc":"reprogrammera","lemma":"reprogrammer","pos":"VER"} ,
		{"word":"reprogrammé","word_nosc":"reprogramme","lemma":"reprogrammer","pos":"VER"} ,
		{"word":"reprogrammée","word_nosc":"reprogrammee","lemma":"reprogrammer","pos":"VER"} ,
		{"word":"reprogrammés","word_nosc":"reprogrammes","lemma":"reprogrammer","pos":"VER"} ,
		{"word":"reproposer","word_nosc":"reproposer","lemma":"reproposer","pos":"VER"} ,
		{"word":"représenta","word_nosc":"representa","lemma":"représenter","pos":"VER"} ,
		{"word":"représentai","word_nosc":"representai","lemma":"représenter","pos":"VER"} ,
		{"word":"représentaient","word_nosc":"representaient","lemma":"représenter","pos":"VER"} ,
		{"word":"représentais","word_nosc":"representais","lemma":"représenter","pos":"VER"} ,
		{"word":"représentait","word_nosc":"representait","lemma":"représenter","pos":"VER"} ,
		{"word":"représentant","word_nosc":"representant","lemma":"représenter","pos":"VER"} ,
		{"word":"représente","word_nosc":"represente","lemma":"représenter","pos":"VER"} ,
		{"word":"représentent","word_nosc":"representent","lemma":"représenter","pos":"VER"} ,
		{"word":"représenter","word_nosc":"representer","lemma":"représenter","pos":"VER"} ,
		{"word":"représentera","word_nosc":"representera","lemma":"représenter","pos":"VER"} ,
		{"word":"représenterai","word_nosc":"representerai","lemma":"représenter","pos":"VER"} ,
		{"word":"représenteraient","word_nosc":"representeraient","lemma":"représenter","pos":"VER"} ,
		{"word":"représenterais","word_nosc":"representerais","lemma":"représenter","pos":"VER"} ,
		{"word":"représenterait","word_nosc":"representerait","lemma":"représenter","pos":"VER"} ,
		{"word":"représenteras","word_nosc":"representeras","lemma":"représenter","pos":"VER"} ,
		{"word":"représenterez","word_nosc":"representerez","lemma":"représenter","pos":"VER"} ,
		{"word":"représenteront","word_nosc":"representeront","lemma":"représenter","pos":"VER"} ,
		{"word":"représentes","word_nosc":"representes","lemma":"représenter","pos":"VER"} ,
		{"word":"représentez","word_nosc":"representez","lemma":"représenter","pos":"VER"} ,
		{"word":"représentiez","word_nosc":"representiez","lemma":"représenter","pos":"VER"} ,
		{"word":"représentions","word_nosc":"representions","lemma":"représenter","pos":"VER"} ,
		{"word":"représentons","word_nosc":"representons","lemma":"représenter","pos":"VER"} ,
		{"word":"représentât","word_nosc":"representat","lemma":"représenter","pos":"VER"} ,
		{"word":"représentèrent","word_nosc":"representerent","lemma":"représenter","pos":"VER"} ,
		{"word":"représenté","word_nosc":"represente","lemma":"représenter","pos":"VER"} ,
		{"word":"représentée","word_nosc":"representee","lemma":"représenter","pos":"VER"} ,
		{"word":"représentées","word_nosc":"representees","lemma":"représenter","pos":"VER"} ,
		{"word":"représentés","word_nosc":"representes","lemma":"représenter","pos":"VER"} ,
		{"word":"reprîmes","word_nosc":"reprimes","lemma":"reprendre","pos":"VER"} ,
		{"word":"reprît","word_nosc":"reprit","lemma":"reprendre","pos":"VER"} ,
		{"word":"republier","word_nosc":"republier","lemma":"republier","pos":"VER"} ,
		{"word":"republions","word_nosc":"republions","lemma":"republier","pos":"VER"} ,
		{"word":"repuiser","word_nosc":"repuiser","lemma":"repuiser","pos":"VER"} ,
		{"word":"repère","word_nosc":"repere","lemma":"repérer","pos":"VER"} ,
		{"word":"repèrent","word_nosc":"reperent","lemma":"repérer","pos":"VER"} ,
		{"word":"repères","word_nosc":"reperes","lemma":"repérer","pos":"VER"} ,
		{"word":"repéra","word_nosc":"repera","lemma":"repérer","pos":"VER"} ,
		{"word":"repéraient","word_nosc":"reperaient","lemma":"repérer","pos":"VER"} ,
		{"word":"repérais","word_nosc":"reperais","lemma":"repérer","pos":"VER"} ,
		{"word":"repérait","word_nosc":"reperait","lemma":"repérer","pos":"VER"} ,
		{"word":"repérant","word_nosc":"reperant","lemma":"repérer","pos":"VER"} ,
		{"word":"repérer","word_nosc":"reperer","lemma":"repérer","pos":"VER"} ,
		{"word":"repérera","word_nosc":"reperera","lemma":"repérer","pos":"VER"} ,
		{"word":"repérerai","word_nosc":"repererai","lemma":"repérer","pos":"VER"} ,
		{"word":"repéreraient","word_nosc":"repereraient","lemma":"repérer","pos":"VER"} ,
		{"word":"repérerais","word_nosc":"repererais","lemma":"repérer","pos":"VER"} ,
		{"word":"repérerait","word_nosc":"repererait","lemma":"repérer","pos":"VER"} ,
		{"word":"repérerons","word_nosc":"repererons","lemma":"repérer","pos":"VER"} ,
		{"word":"repéreront","word_nosc":"repereront","lemma":"repérer","pos":"VER"} ,
		{"word":"repérez","word_nosc":"reperez","lemma":"repérer","pos":"VER"} ,
		{"word":"repériez","word_nosc":"reperiez","lemma":"repérer","pos":"VER"} ,
		{"word":"repérions","word_nosc":"reperions","lemma":"repérer","pos":"VER"} ,
		{"word":"repérèrent","word_nosc":"repererent","lemma":"repérer","pos":"VER"} ,
		{"word":"repéré","word_nosc":"repere","lemma":"repérer","pos":"VER"} ,
		{"word":"repérée","word_nosc":"reperee","lemma":"repérer","pos":"VER"} ,
		{"word":"repérées","word_nosc":"reperees","lemma":"repérer","pos":"VER"} ,
		{"word":"repérés","word_nosc":"reperes","lemma":"repérer","pos":"VER"} ,
		{"word":"repétrir","word_nosc":"repetrir","lemma":"repétrir","pos":"VER"} ,
		{"word":"repétrissait","word_nosc":"repetrissait","lemma":"repétrir","pos":"VER"} ,
		{"word":"repétrit","word_nosc":"repetrit","lemma":"repétrir","pos":"VER"} ,
		{"word":"repêcha","word_nosc":"repecha","lemma":"repêcher","pos":"VER"} ,
		{"word":"repêchai","word_nosc":"repechai","lemma":"repêcher","pos":"VER"} ,
		{"word":"repêchait","word_nosc":"repechait","lemma":"repêcher","pos":"VER"} ,
		{"word":"repêchant","word_nosc":"repechant","lemma":"repêcher","pos":"VER"} ,
		{"word":"repêche","word_nosc":"repeche","lemma":"repêcher","pos":"VER"} ,
		{"word":"repêcher","word_nosc":"repecher","lemma":"repêcher","pos":"VER"} ,
		{"word":"repêchera","word_nosc":"repechera","lemma":"repêcher","pos":"VER"} ,
		{"word":"repêcherait","word_nosc":"repecherait","lemma":"repêcher","pos":"VER"} ,
		{"word":"repêches","word_nosc":"repeches","lemma":"repêcher","pos":"VER"} ,
		{"word":"repêchèrent","word_nosc":"repecherent","lemma":"repêcher","pos":"VER"} ,
		{"word":"repêché","word_nosc":"repeche","lemma":"repêcher","pos":"VER"} ,
		{"word":"repêchée","word_nosc":"repechee","lemma":"repêcher","pos":"VER"} ,
		{"word":"repêchées","word_nosc":"repechees","lemma":"repêcher","pos":"VER"} ,
		{"word":"repêchés","word_nosc":"repeches","lemma":"repêcher","pos":"VER"} ,
		{"word":"requalifier","word_nosc":"requalifier","lemma":"requalifier","pos":"VER"} ,
		{"word":"requiers","word_nosc":"requiers","lemma":"requérir","pos":"VER"} ,
		{"word":"requiert","word_nosc":"requiert","lemma":"requérir","pos":"VER"} ,
		{"word":"requinqua","word_nosc":"requinqua","lemma":"requinquer","pos":"VER"} ,
		{"word":"requinquait","word_nosc":"requinquait","lemma":"requinquer","pos":"VER"} ,
		{"word":"requinque","word_nosc":"requinque","lemma":"requinquer","pos":"VER"} ,
		{"word":"requinquent","word_nosc":"requinquent","lemma":"requinquer","pos":"VER"} ,
		{"word":"requinquer","word_nosc":"requinquer","lemma":"requinquer","pos":"VER"} ,
		{"word":"requinquera","word_nosc":"requinquera","lemma":"requinquer","pos":"VER"} ,
		{"word":"requinquerait","word_nosc":"requinquerait","lemma":"requinquer","pos":"VER"} ,
		{"word":"requinqué","word_nosc":"requinque","lemma":"requinquer","pos":"VER"} ,
		{"word":"requinquée","word_nosc":"requinquee","lemma":"requinquer","pos":"VER"} ,
		{"word":"requinqués","word_nosc":"requinques","lemma":"requinquer","pos":"VER"} ,
		{"word":"requis","word_nosc":"requis","lemma":"requérir","pos":"VER"} ,
		{"word":"requise","word_nosc":"requise","lemma":"requérir","pos":"VER"} ,
		{"word":"requises","word_nosc":"requises","lemma":"requérir","pos":"VER"} ,
		{"word":"requière","word_nosc":"requiere","lemma":"requérir","pos":"VER"} ,
		{"word":"requièrent","word_nosc":"requierent","lemma":"requérir","pos":"VER"} ,
		{"word":"requéraient","word_nosc":"requeraient","lemma":"requérir","pos":"VER"} ,
		{"word":"requérais","word_nosc":"requerais","lemma":"requérir","pos":"VER"} ,
		{"word":"requérait","word_nosc":"requerait","lemma":"requérir","pos":"VER"} ,
		{"word":"requérant","word_nosc":"requerant","lemma":"requérir","pos":"VER"} ,
		{"word":"requérir","word_nosc":"requerir","lemma":"requérir","pos":"VER"} ,
		{"word":"requérons","word_nosc":"requerons","lemma":"requérir","pos":"VER"} ,
		{"word":"resalir","word_nosc":"resalir","lemma":"resalir","pos":"VER"} ,
		{"word":"resape","word_nosc":"resape","lemma":"resaper","pos":"VER"} ,
		{"word":"resapent","word_nosc":"resapent","lemma":"resaper","pos":"VER"} ,
		{"word":"resapée","word_nosc":"resapee","lemma":"resaper","pos":"VER"} ,
		{"word":"resapés","word_nosc":"resapes","lemma":"resaper","pos":"VER"} ,
		{"word":"rescaper","word_nosc":"rescaper","lemma":"rescaper","pos":"VER"} ,
		{"word":"rescapes","word_nosc":"rescapes","lemma":"rescaper","pos":"VER"} ,
		{"word":"rescapé","word_nosc":"rescape","lemma":"rescaper","pos":"VER"} ,
		{"word":"rescapée","word_nosc":"rescapee","lemma":"rescaper","pos":"VER"} ,
		{"word":"rescapées","word_nosc":"rescapees","lemma":"rescaper","pos":"VER"} ,
		{"word":"rescapés","word_nosc":"rescapes","lemma":"rescaper","pos":"VER"} ,
		{"word":"reservir","word_nosc":"reservir","lemma":"reservir","pos":"VER"} ,
		{"word":"resongeait","word_nosc":"resongeait","lemma":"resonger","pos":"VER"} ,
		{"word":"resonger","word_nosc":"resonger","lemma":"resonger","pos":"VER"} ,
		{"word":"respecta","word_nosc":"respecta","lemma":"respecter","pos":"VER"} ,
		{"word":"respectai","word_nosc":"respectai","lemma":"respecter","pos":"VER"} ,
		{"word":"respectaient","word_nosc":"respectaient","lemma":"respecter","pos":"VER"} ,
		{"word":"respectais","word_nosc":"respectais","lemma":"respecter","pos":"VER"} ,
		{"word":"respectait","word_nosc":"respectait","lemma":"respecter","pos":"VER"} ,
		{"word":"respectant","word_nosc":"respectant","lemma":"respecter","pos":"VER"} ,
		{"word":"respecte","word_nosc":"respecte","lemma":"respecter","pos":"VER"} ,
		{"word":"respectent","word_nosc":"respectent","lemma":"respecter","pos":"VER"} ,
		{"word":"respecter","word_nosc":"respecter","lemma":"respecter","pos":"VER"} ,
		{"word":"respectera","word_nosc":"respectera","lemma":"respecter","pos":"VER"} ,
		{"word":"respecterai","word_nosc":"respecterai","lemma":"respecter","pos":"VER"} ,
		{"word":"respecteraient","word_nosc":"respecteraient","lemma":"respecter","pos":"VER"} ,
		{"word":"respecterais","word_nosc":"respecterais","lemma":"respecter","pos":"VER"} ,
		{"word":"respecterait","word_nosc":"respecterait","lemma":"respecter","pos":"VER"} ,
		{"word":"respecteras","word_nosc":"respecteras","lemma":"respecter","pos":"VER"} ,
		{"word":"respecterez","word_nosc":"respecterez","lemma":"respecter","pos":"VER"} ,
		{"word":"respecterons","word_nosc":"respecterons","lemma":"respecter","pos":"VER"} ,
		{"word":"respecteront","word_nosc":"respecteront","lemma":"respecter","pos":"VER"} ,
		{"word":"respectes","word_nosc":"respectes","lemma":"respecter","pos":"VER"} ,
		{"word":"respectez","word_nosc":"respectez","lemma":"respecter","pos":"VER"} ,
		{"word":"respectiez","word_nosc":"respectiez","lemma":"respecter","pos":"VER"} ,
		{"word":"respections","word_nosc":"respections","lemma":"respecter","pos":"VER"} ,
		{"word":"respectons","word_nosc":"respectons","lemma":"respecter","pos":"VER"} ,
		{"word":"respectât","word_nosc":"respectat","lemma":"respecter","pos":"VER"} ,
		{"word":"respecté","word_nosc":"respecte","lemma":"respecter","pos":"VER"} ,
		{"word":"respectée","word_nosc":"respectee","lemma":"respecter","pos":"VER"} ,
		{"word":"respectées","word_nosc":"respectees","lemma":"respecter","pos":"VER"} ,
		{"word":"respectés","word_nosc":"respectes","lemma":"respecter","pos":"VER"} ,
		{"word":"respira","word_nosc":"respira","lemma":"respirer","pos":"VER"} ,
		{"word":"respirai","word_nosc":"respirai","lemma":"respirer","pos":"VER"} ,
		{"word":"respiraient","word_nosc":"respiraient","lemma":"respirer","pos":"VER"} ,
		{"word":"respirais","word_nosc":"respirais","lemma":"respirer","pos":"VER"} ,
		{"word":"respirait","word_nosc":"respirait","lemma":"respirer","pos":"VER"} ,
		{"word":"respirant","word_nosc":"respirant","lemma":"respirer","pos":"VER"} ,
		{"word":"respire","word_nosc":"respire","lemma":"respirer","pos":"VER"} ,
		{"word":"respirent","word_nosc":"respirent","lemma":"respirer","pos":"VER"} ,
		{"word":"respirer","word_nosc":"respirer","lemma":"respirer","pos":"VER"} ,
		{"word":"respirera","word_nosc":"respirera","lemma":"respirer","pos":"VER"} ,
		{"word":"respirerai","word_nosc":"respirerai","lemma":"respirer","pos":"VER"} ,
		{"word":"respirerais","word_nosc":"respirerais","lemma":"respirer","pos":"VER"} ,
		{"word":"respirerait","word_nosc":"respirerait","lemma":"respirer","pos":"VER"} ,
		{"word":"respireras","word_nosc":"respireras","lemma":"respirer","pos":"VER"} ,
		{"word":"respirerez","word_nosc":"respirerez","lemma":"respirer","pos":"VER"} ,
		{"word":"respirerons","word_nosc":"respirerons","lemma":"respirer","pos":"VER"} ,
		{"word":"respireront","word_nosc":"respireront","lemma":"respirer","pos":"VER"} ,
		{"word":"respires","word_nosc":"respires","lemma":"respirer","pos":"VER"} ,
		{"word":"respirez","word_nosc":"respirez","lemma":"respirer","pos":"VER"} ,
		{"word":"respiriez","word_nosc":"respiriez","lemma":"respirer","pos":"VER"} ,
		{"word":"respirions","word_nosc":"respirions","lemma":"respirer","pos":"VER"} ,
		{"word":"respirons","word_nosc":"respirons","lemma":"respirer","pos":"VER"} ,
		{"word":"respirât","word_nosc":"respirat","lemma":"respirer","pos":"VER"} ,
		{"word":"respirèrent","word_nosc":"respirerent","lemma":"respirer","pos":"VER"} ,
		{"word":"respiré","word_nosc":"respire","lemma":"respirer","pos":"VER"} ,
		{"word":"respirée","word_nosc":"respiree","lemma":"respirer","pos":"VER"} ,
		{"word":"respirées","word_nosc":"respirees","lemma":"respirer","pos":"VER"} ,
		{"word":"respirés","word_nosc":"respires","lemma":"respirer","pos":"VER"} ,
		{"word":"resplendi","word_nosc":"resplendi","lemma":"resplendir","pos":"VER"} ,
		{"word":"resplendir","word_nosc":"resplendir","lemma":"resplendir","pos":"VER"} ,
		{"word":"resplendira","word_nosc":"resplendira","lemma":"resplendir","pos":"VER"} ,
		{"word":"resplendiraient","word_nosc":"resplendiraient","lemma":"resplendir","pos":"VER"} ,
		{"word":"resplendirent","word_nosc":"resplendirent","lemma":"resplendir","pos":"VER"} ,
		{"word":"resplendis","word_nosc":"resplendis","lemma":"resplendir","pos":"VER"} ,
		{"word":"resplendissaient","word_nosc":"resplendissaient","lemma":"resplendir","pos":"VER"} ,
		{"word":"resplendissait","word_nosc":"resplendissait","lemma":"resplendir","pos":"VER"} ,
		{"word":"resplendissant","word_nosc":"resplendissant","lemma":"resplendir","pos":"VER"} ,
		{"word":"resplendisse","word_nosc":"resplendisse","lemma":"resplendir","pos":"VER"} ,
		{"word":"resplendissent","word_nosc":"resplendissent","lemma":"resplendir","pos":"VER"} ,
		{"word":"resplendissez","word_nosc":"resplendissez","lemma":"resplendir","pos":"VER"} ,
		{"word":"resplendit","word_nosc":"resplendit","lemma":"resplendir","pos":"VER"} ,
		{"word":"responsabilise","word_nosc":"responsabilise","lemma":"responsabiliser","pos":"VER"} ,
		{"word":"responsabiliser","word_nosc":"responsabiliser","lemma":"responsabiliser","pos":"VER"} ,
		{"word":"resquillaient","word_nosc":"resquillaient","lemma":"resquiller","pos":"VER"} ,
		{"word":"resquillant","word_nosc":"resquillant","lemma":"resquiller","pos":"VER"} ,
		{"word":"resquille","word_nosc":"resquille","lemma":"resquiller","pos":"VER"} ,
		{"word":"resquiller","word_nosc":"resquiller","lemma":"resquiller","pos":"VER"} ,
		{"word":"resquillera","word_nosc":"resquillera","lemma":"resquiller","pos":"VER"} ,
		{"word":"resquilles","word_nosc":"resquilles","lemma":"resquiller","pos":"VER"} ,
		{"word":"resquillé","word_nosc":"resquille","lemma":"resquiller","pos":"VER"} ,
		{"word":"ressaie","word_nosc":"ressaie","lemma":"ressayer","pos":"VER"} ,
		{"word":"ressaisi","word_nosc":"ressaisi","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisie","word_nosc":"ressaisie","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisir","word_nosc":"ressaisir","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisira","word_nosc":"ressaisira","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisirais","word_nosc":"ressaisirais","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisis","word_nosc":"ressaisis","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisissaient","word_nosc":"ressaisissaient","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisissais","word_nosc":"ressaisissais","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisissait","word_nosc":"ressaisissait","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisissant","word_nosc":"ressaisissant","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisisse","word_nosc":"ressaisisse","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisisses","word_nosc":"ressaisisses","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisissez","word_nosc":"ressaisissez","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisissions","word_nosc":"ressaisissions","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisissons","word_nosc":"ressaisissons","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisit","word_nosc":"ressaisit","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressaisît","word_nosc":"ressaisit","lemma":"ressaisir","pos":"VER"} ,
		{"word":"ressassaient","word_nosc":"ressassaient","lemma":"ressasser","pos":"VER"} ,
		{"word":"ressassais","word_nosc":"ressassais","lemma":"ressasser","pos":"VER"} ,
		{"word":"ressassait","word_nosc":"ressassait","lemma":"ressasser","pos":"VER"} ,
		{"word":"ressassant","word_nosc":"ressassant","lemma":"ressasser","pos":"VER"} ,
		{"word":"ressasse","word_nosc":"ressasse","lemma":"ressasser","pos":"VER"} ,
		{"word":"ressassent","word_nosc":"ressassent","lemma":"ressasser","pos":"VER"} ,
		{"word":"ressasser","word_nosc":"ressasser","lemma":"ressasser","pos":"VER"} ,
		{"word":"ressassez","word_nosc":"ressassez","lemma":"ressasser","pos":"VER"} ,
		{"word":"ressassions","word_nosc":"ressassions","lemma":"ressasser","pos":"VER"} ,
		{"word":"ressassèrent","word_nosc":"ressasserent","lemma":"ressasser","pos":"VER"} ,
		{"word":"ressassé","word_nosc":"ressasse","lemma":"ressasser","pos":"VER"} ,
		{"word":"ressassée","word_nosc":"ressassee","lemma":"ressasser","pos":"VER"} ,
		{"word":"ressassées","word_nosc":"ressassees","lemma":"ressasser","pos":"VER"} ,
		{"word":"ressassés","word_nosc":"ressasses","lemma":"ressasser","pos":"VER"} ,
		{"word":"ressauter","word_nosc":"ressauter","lemma":"ressauter","pos":"VER"} ,
		{"word":"ressauté","word_nosc":"ressaute","lemma":"ressauter","pos":"VER"} ,
		{"word":"ressayer","word_nosc":"ressayer","lemma":"ressayer","pos":"VER"} ,
		{"word":"ressembla","word_nosc":"ressembla","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblaient","word_nosc":"ressemblaient","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblais","word_nosc":"ressemblais","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblait","word_nosc":"ressemblait","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblant","word_nosc":"ressemblant","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblassent","word_nosc":"ressemblassent","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemble","word_nosc":"ressemble","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblent","word_nosc":"ressemblent","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressembler","word_nosc":"ressembler","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblera","word_nosc":"ressemblera","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblerai","word_nosc":"ressemblerai","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressembleraient","word_nosc":"ressembleraient","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblerais","word_nosc":"ressemblerais","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblerait","word_nosc":"ressemblerait","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressembleras","word_nosc":"ressembleras","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblerez","word_nosc":"ressemblerez","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressembleront","word_nosc":"ressembleront","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressembles","word_nosc":"ressembles","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblez","word_nosc":"ressemblez","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressembliez","word_nosc":"ressembliez","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblions","word_nosc":"ressemblions","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblons","word_nosc":"ressemblons","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblât","word_nosc":"ressemblat","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblèrent","word_nosc":"ressemblerent","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemblé","word_nosc":"ressemble","lemma":"ressembler","pos":"VER"} ,
		{"word":"ressemelait","word_nosc":"ressemelait","lemma":"ressemeler","pos":"VER"} ,
		{"word":"ressemeler","word_nosc":"ressemeler","lemma":"ressemeler","pos":"VER"} ,
		{"word":"ressemellent","word_nosc":"ressemellent","lemma":"ressemeler","pos":"VER"} ,
		{"word":"ressemelée","word_nosc":"ressemelee","lemma":"ressemeler","pos":"VER"} ,
		{"word":"ressemer","word_nosc":"ressemer","lemma":"ressemer","pos":"VER"} ,
		{"word":"ressens","word_nosc":"ressens","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressent","word_nosc":"ressent","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentaient","word_nosc":"ressentaient","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentais","word_nosc":"ressentais","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentait","word_nosc":"ressentait","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentant","word_nosc":"ressentant","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressente","word_nosc":"ressente","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentent","word_nosc":"ressentent","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentes","word_nosc":"ressentes","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentez","word_nosc":"ressentez","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressenti","word_nosc":"ressenti","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentie","word_nosc":"ressentie","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressenties","word_nosc":"ressenties","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentiez","word_nosc":"ressentiez","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentions","word_nosc":"ressentions","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentir","word_nosc":"ressentir","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentira","word_nosc":"ressentira","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentirai","word_nosc":"ressentirai","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentirais","word_nosc":"ressentirais","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentirait","word_nosc":"ressentirait","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentiras","word_nosc":"ressentiras","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentirent","word_nosc":"ressentirent","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentirez","word_nosc":"ressentirez","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentiriez","word_nosc":"ressentiriez","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentirions","word_nosc":"ressentirions","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentiront","word_nosc":"ressentiront","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentis","word_nosc":"ressentis","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentit","word_nosc":"ressentit","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentons","word_nosc":"ressentons","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentîmes","word_nosc":"ressentimes","lemma":"ressentir","pos":"VER"} ,
		{"word":"ressentît","word_nosc":"ressentit","lemma":"ressentir","pos":"VER"} ,
		{"word":"resserra","word_nosc":"resserra","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserraient","word_nosc":"resserraient","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserrait","word_nosc":"resserrait","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserrant","word_nosc":"resserrant","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserre","word_nosc":"resserre","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserrent","word_nosc":"resserrent","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserrer","word_nosc":"resserrer","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserrera","word_nosc":"resserrera","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserrerai","word_nosc":"resserrerai","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserrerait","word_nosc":"resserrerait","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserres","word_nosc":"resserres","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserrez","word_nosc":"resserrez","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserrions","word_nosc":"resserrions","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserrons","word_nosc":"resserrons","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserrèrent","word_nosc":"resserrerent","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserré","word_nosc":"resserre","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserrée","word_nosc":"resserree","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserrées","word_nosc":"resserrees","lemma":"resserrer","pos":"VER"} ,
		{"word":"resserrés","word_nosc":"resserres","lemma":"resserrer","pos":"VER"} ,
		{"word":"ressers","word_nosc":"ressers","lemma":"resservir","pos":"VER"} ,
		{"word":"ressert","word_nosc":"ressert","lemma":"resservir","pos":"VER"} ,
		{"word":"resservais","word_nosc":"resservais","lemma":"resservir","pos":"VER"} ,
		{"word":"resservait","word_nosc":"resservait","lemma":"resservir","pos":"VER"} ,
		{"word":"resservez","word_nosc":"resservez","lemma":"resservir","pos":"VER"} ,
		{"word":"resservi","word_nosc":"resservi","lemma":"resservir","pos":"VER"} ,
		{"word":"resservir","word_nosc":"resservir","lemma":"resservir","pos":"VER"} ,
		{"word":"resservirai","word_nosc":"resservirai","lemma":"resservir","pos":"VER"} ,
		{"word":"resservirais","word_nosc":"resservirais","lemma":"resservir","pos":"VER"} ,
		{"word":"resservirait","word_nosc":"resservirait","lemma":"resservir","pos":"VER"} ,
		{"word":"resservis","word_nosc":"resservis","lemma":"resservir","pos":"VER"} ,
		{"word":"resservit","word_nosc":"resservit","lemma":"resservir","pos":"VER"} ,
		{"word":"ressors","word_nosc":"ressors","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressort","word_nosc":"ressort","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortaient","word_nosc":"ressortaient","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortais","word_nosc":"ressortais","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortait","word_nosc":"ressortait","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortant","word_nosc":"ressortant","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressorte","word_nosc":"ressorte","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortent","word_nosc":"ressortent","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortes","word_nosc":"ressortes","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortez","word_nosc":"ressortez","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressorti","word_nosc":"ressorti","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortie","word_nosc":"ressortie","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressorties","word_nosc":"ressorties","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortions","word_nosc":"ressortions","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortir","word_nosc":"ressortir","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortira","word_nosc":"ressortira","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortirai","word_nosc":"ressortirai","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortiraient","word_nosc":"ressortiraient","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortirais","word_nosc":"ressortirais","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortirait","word_nosc":"ressortirait","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortiras","word_nosc":"ressortiras","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortirent","word_nosc":"ressortirent","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortirez","word_nosc":"ressortirez","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortiront","word_nosc":"ressortiront","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortis","word_nosc":"ressortis","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortit","word_nosc":"ressortit","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortons","word_nosc":"ressortons","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressortîmes","word_nosc":"ressortimes","lemma":"ressortir","pos":"VER"} ,
		{"word":"ressoudant","word_nosc":"ressoudant","lemma":"ressouder","pos":"VER"} ,
		{"word":"ressoude","word_nosc":"ressoude","lemma":"ressouder","pos":"VER"} ,
		{"word":"ressouder","word_nosc":"ressouder","lemma":"ressouder","pos":"VER"} ,
		{"word":"ressoudé","word_nosc":"ressoude","lemma":"ressouder","pos":"VER"} ,
		{"word":"ressoudée","word_nosc":"ressoudee","lemma":"ressouder","pos":"VER"} ,
		{"word":"ressoudés","word_nosc":"ressoudes","lemma":"ressouder","pos":"VER"} ,
		{"word":"ressourcer","word_nosc":"ressourcer","lemma":"ressourcer","pos":"VER"} ,
		{"word":"ressourcé","word_nosc":"ressource","lemma":"ressourcer","pos":"VER"} ,
		{"word":"ressourçais","word_nosc":"ressourcais","lemma":"ressourcer","pos":"VER"} ,
		{"word":"ressourçait","word_nosc":"ressourcait","lemma":"ressourcer","pos":"VER"} ,
		{"word":"ressouvenait","word_nosc":"ressouvenait","lemma":"ressouvenir","pos":"VER"} ,
		{"word":"ressouvenant","word_nosc":"ressouvenant","lemma":"ressouvenir","pos":"VER"} ,
		{"word":"ressouvenir","word_nosc":"ressouvenir","lemma":"ressouvenir","pos":"VER"} ,
		{"word":"ressouvenue","word_nosc":"ressouvenue","lemma":"ressouvenir","pos":"VER"} ,
		{"word":"ressouviens","word_nosc":"ressouviens","lemma":"ressouvenir","pos":"VER"} ,
		{"word":"ressouvint","word_nosc":"ressouvint","lemma":"ressouvenir","pos":"VER"} ,
		{"word":"ressurgi","word_nosc":"ressurgi","lemma":"ressurgir","pos":"VER"} ,
		{"word":"ressurgie","word_nosc":"ressurgie","lemma":"ressurgir","pos":"VER"} ,
		{"word":"ressurgir","word_nosc":"ressurgir","lemma":"ressurgir","pos":"VER"} ,
		{"word":"ressurgirait","word_nosc":"ressurgirait","lemma":"ressurgir","pos":"VER"} ,
		{"word":"ressurgirent","word_nosc":"ressurgirent","lemma":"ressurgir","pos":"VER"} ,
		{"word":"ressurgiront","word_nosc":"ressurgiront","lemma":"ressurgir","pos":"VER"} ,
		{"word":"ressurgissait","word_nosc":"ressurgissait","lemma":"ressurgir","pos":"VER"} ,
		{"word":"ressurgissant","word_nosc":"ressurgissant","lemma":"ressurgir","pos":"VER"} ,
		{"word":"ressurgissent","word_nosc":"ressurgissent","lemma":"ressurgir","pos":"VER"} ,
		{"word":"ressurgit","word_nosc":"ressurgit","lemma":"ressurgir","pos":"VER"} ,
		{"word":"ressuscita","word_nosc":"ressuscita","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscitai","word_nosc":"ressuscitai","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscitaient","word_nosc":"ressuscitaient","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscitais","word_nosc":"ressuscitais","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscitait","word_nosc":"ressuscitait","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscitant","word_nosc":"ressuscitant","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscite","word_nosc":"ressuscite","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscitent","word_nosc":"ressuscitent","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressusciter","word_nosc":"ressusciter","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscitera","word_nosc":"ressuscitera","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressusciterai","word_nosc":"ressusciterai","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressusciteraient","word_nosc":"ressusciteraient","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressusciterait","word_nosc":"ressusciterait","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressusciteras","word_nosc":"ressusciteras","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressusciterez","word_nosc":"ressusciterez","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressusciterons","word_nosc":"ressusciterons","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressusciteront","word_nosc":"ressusciteront","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscites","word_nosc":"ressuscites","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscitez","word_nosc":"ressuscitez","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscitons","word_nosc":"ressuscitons","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscitât","word_nosc":"ressuscitat","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscité","word_nosc":"ressuscite","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscitée","word_nosc":"ressuscitee","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscitées","word_nosc":"ressuscitees","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuscités","word_nosc":"ressuscites","lemma":"ressusciter","pos":"VER"} ,
		{"word":"ressuyé","word_nosc":"ressuye","lemma":"ressuyer","pos":"VER"} ,
		{"word":"ressuyée","word_nosc":"ressuyee","lemma":"ressuyer","pos":"VER"} ,
		{"word":"ressué","word_nosc":"ressue","lemma":"ressuer","pos":"VER"} ,
		{"word":"resta","word_nosc":"resta","lemma":"rester","pos":"VER"} ,
		{"word":"restai","word_nosc":"restai","lemma":"rester","pos":"VER"} ,
		{"word":"restaient","word_nosc":"restaient","lemma":"rester","pos":"VER"} ,
		{"word":"restais","word_nosc":"restais","lemma":"rester","pos":"VER"} ,
		{"word":"restait","word_nosc":"restait","lemma":"rester","pos":"VER"} ,
		{"word":"restant","word_nosc":"restant","lemma":"rester","pos":"VER"} ,
		{"word":"restassent","word_nosc":"restassent","lemma":"rester","pos":"VER"} ,
		{"word":"restaura","word_nosc":"restaura","lemma":"restaurer","pos":"VER"} ,
		{"word":"restaurait","word_nosc":"restaurait","lemma":"restaurer","pos":"VER"} ,
		{"word":"restaurant","word_nosc":"restaurant","lemma":"restaurer","pos":"VER"} ,
		{"word":"restaure","word_nosc":"restaure","lemma":"restaurer","pos":"VER"} ,
		{"word":"restaurent","word_nosc":"restaurent","lemma":"restaurer","pos":"VER"} ,
		{"word":"restaurer","word_nosc":"restaurer","lemma":"restaurer","pos":"VER"} ,
		{"word":"restaurera","word_nosc":"restaurera","lemma":"restaurer","pos":"VER"} ,
		{"word":"restaurerai","word_nosc":"restaurerai","lemma":"restaurer","pos":"VER"} ,
		{"word":"restaureraient","word_nosc":"restaureraient","lemma":"restaurer","pos":"VER"} ,
		{"word":"restaurerait","word_nosc":"restaurerait","lemma":"restaurer","pos":"VER"} ,
		{"word":"restaurez","word_nosc":"restaurez","lemma":"restaurer","pos":"VER"} ,
		{"word":"restaurons","word_nosc":"restaurons","lemma":"restaurer","pos":"VER"} ,
		{"word":"restauré","word_nosc":"restaure","lemma":"restaurer","pos":"VER"} ,
		{"word":"restaurée","word_nosc":"restauree","lemma":"restaurer","pos":"VER"} ,
		{"word":"restaurées","word_nosc":"restaurees","lemma":"restaurer","pos":"VER"} ,
		{"word":"restaurés","word_nosc":"restaures","lemma":"restaurer","pos":"VER"} ,
		{"word":"reste","word_nosc":"reste","lemma":"rester","pos":"VER"} ,
		{"word":"restent","word_nosc":"restent","lemma":"rester","pos":"VER"} ,
		{"word":"restent","word_nosc":"restent","lemma":"ruer","pos":"VER"} ,
		{"word":"rester","word_nosc":"rester","lemma":"rester","pos":"VER"} ,
		{"word":"restera","word_nosc":"restera","lemma":"rester","pos":"VER"} ,
		{"word":"resterai","word_nosc":"resterai","lemma":"rester","pos":"VER"} ,
		{"word":"resteraient","word_nosc":"resteraient","lemma":"rester","pos":"VER"} ,
		{"word":"resterais","word_nosc":"resterais","lemma":"rester","pos":"VER"} ,
		{"word":"resterait","word_nosc":"resterait","lemma":"rester","pos":"VER"} ,
		{"word":"resteras","word_nosc":"resteras","lemma":"rester","pos":"VER"} ,
		{"word":"resterez","word_nosc":"resterez","lemma":"rester","pos":"VER"} ,
		{"word":"resteriez","word_nosc":"resteriez","lemma":"rester","pos":"VER"} ,
		{"word":"resterions","word_nosc":"resterions","lemma":"rester","pos":"VER"} ,
		{"word":"resterons","word_nosc":"resterons","lemma":"rester","pos":"VER"} ,
		{"word":"resteront","word_nosc":"resteront","lemma":"rester","pos":"VER"} ,
		{"word":"restes","word_nosc":"restes","lemma":"rester","pos":"VER"} ,
		{"word":"restez","word_nosc":"restez","lemma":"rester","pos":"VER"} ,
		{"word":"restiez","word_nosc":"restiez","lemma":"rester","pos":"VER"} ,
		{"word":"restions","word_nosc":"restions","lemma":"rester","pos":"VER"} ,
		{"word":"restituaient","word_nosc":"restituaient","lemma":"restituer","pos":"VER"} ,
		{"word":"restituait","word_nosc":"restituait","lemma":"restituer","pos":"VER"} ,
		{"word":"restituant","word_nosc":"restituant","lemma":"restituer","pos":"VER"} ,
		{"word":"restitue","word_nosc":"restitue","lemma":"restituer","pos":"VER"} ,
		{"word":"restituent","word_nosc":"restituent","lemma":"restituer","pos":"VER"} ,
		{"word":"restituer","word_nosc":"restituer","lemma":"restituer","pos":"VER"} ,
		{"word":"restituera","word_nosc":"restituera","lemma":"restituer","pos":"VER"} ,
		{"word":"restituerai","word_nosc":"restituerai","lemma":"restituer","pos":"VER"} ,
		{"word":"restituerait","word_nosc":"restituerait","lemma":"restituer","pos":"VER"} ,
		{"word":"restituerons","word_nosc":"restituerons","lemma":"restituer","pos":"VER"} ,
		{"word":"restituez","word_nosc":"restituez","lemma":"restituer","pos":"VER"} ,
		{"word":"restituiez","word_nosc":"restituiez","lemma":"restituer","pos":"VER"} ,
		{"word":"restitué","word_nosc":"restitue","lemma":"restituer","pos":"VER"} ,
		{"word":"restituée","word_nosc":"restituee","lemma":"restituer","pos":"VER"} ,
		{"word":"restituées","word_nosc":"restituees","lemma":"restituer","pos":"VER"} ,
		{"word":"restitués","word_nosc":"restitues","lemma":"restituer","pos":"VER"} ,
		{"word":"restons","word_nosc":"restons","lemma":"rester","pos":"VER"} ,
		{"word":"restreignait","word_nosc":"restreignait","lemma":"restreindre","pos":"VER"} ,
		{"word":"restreignant","word_nosc":"restreignant","lemma":"restreindre","pos":"VER"} ,
		{"word":"restreignent","word_nosc":"restreignent","lemma":"restreindre","pos":"VER"} ,
		{"word":"restreignez","word_nosc":"restreignez","lemma":"restreindre","pos":"VER"} ,
		{"word":"restreignit","word_nosc":"restreignit","lemma":"restreindre","pos":"VER"} ,
		{"word":"restreindre","word_nosc":"restreindre","lemma":"restreindre","pos":"VER"} ,
		{"word":"restreins","word_nosc":"restreins","lemma":"restreindre","pos":"VER"} ,
		{"word":"restreint","word_nosc":"restreint","lemma":"restreindre","pos":"VER"} ,
		{"word":"restreinte","word_nosc":"restreinte","lemma":"restreindre","pos":"VER"} ,
		{"word":"restreintes","word_nosc":"restreintes","lemma":"restreindre","pos":"VER"} ,
		{"word":"restreints","word_nosc":"restreints","lemma":"restreindre","pos":"VER"} ,
		{"word":"restructurant","word_nosc":"restructurant","lemma":"restructurer","pos":"VER"} ,
		{"word":"restructurer","word_nosc":"restructurer","lemma":"restructurer","pos":"VER"} ,
		{"word":"restructureront","word_nosc":"restructureront","lemma":"restructurer","pos":"VER"} ,
		{"word":"restructurons","word_nosc":"restructurons","lemma":"restructurer","pos":"VER"} ,
		{"word":"restructuré","word_nosc":"restructure","lemma":"restructurer","pos":"VER"} ,
		{"word":"restructurée","word_nosc":"restructuree","lemma":"restructurer","pos":"VER"} ,
		{"word":"restâmes","word_nosc":"restames","lemma":"rester","pos":"VER"} ,
		{"word":"restât","word_nosc":"restat","lemma":"rester","pos":"VER"} ,
		{"word":"restèrent","word_nosc":"resterent","lemma":"rester","pos":"VER"} ,
		{"word":"resté","word_nosc":"reste","lemma":"rester","pos":"VER"} ,
		{"word":"restée","word_nosc":"restee","lemma":"rester","pos":"VER"} ,
		{"word":"restées","word_nosc":"restees","lemma":"rester","pos":"VER"} ,
		{"word":"restés","word_nosc":"restes","lemma":"rester","pos":"VER"} ,
		{"word":"resurgi","word_nosc":"resurgi","lemma":"resurgir","pos":"VER"} ,
		{"word":"resurgies","word_nosc":"resurgies","lemma":"resurgir","pos":"VER"} ,
		{"word":"resurgir","word_nosc":"resurgir","lemma":"resurgir","pos":"VER"} ,
		{"word":"resurgira","word_nosc":"resurgira","lemma":"resurgir","pos":"VER"} ,
		{"word":"resurgirai","word_nosc":"resurgirai","lemma":"resurgir","pos":"VER"} ,
		{"word":"resurgirait","word_nosc":"resurgirait","lemma":"resurgir","pos":"VER"} ,
		{"word":"resurgirent","word_nosc":"resurgirent","lemma":"resurgir","pos":"VER"} ,
		{"word":"resurgiront","word_nosc":"resurgiront","lemma":"resurgir","pos":"VER"} ,
		{"word":"resurgis","word_nosc":"resurgis","lemma":"resurgir","pos":"VER"} ,
		{"word":"resurgissaient","word_nosc":"resurgissaient","lemma":"resurgir","pos":"VER"} ,
		{"word":"resurgissait","word_nosc":"resurgissait","lemma":"resurgir","pos":"VER"} ,
		{"word":"resurgissant","word_nosc":"resurgissant","lemma":"resurgir","pos":"VER"} ,
		{"word":"resurgisse","word_nosc":"resurgisse","lemma":"resurgir","pos":"VER"} ,
		{"word":"resurgissent","word_nosc":"resurgissent","lemma":"resurgir","pos":"VER"} ,
		{"word":"resurgit","word_nosc":"resurgit","lemma":"resurgir","pos":"VER"} ,
		{"word":"resurgît","word_nosc":"resurgit","lemma":"resurgir","pos":"VER"} ,
		{"word":"retaillaient","word_nosc":"retaillaient","lemma":"retailler","pos":"VER"} ,
		{"word":"retaillait","word_nosc":"retaillait","lemma":"retailler","pos":"VER"} ,
		{"word":"retaille","word_nosc":"retaille","lemma":"retailler","pos":"VER"} ,
		{"word":"retailler","word_nosc":"retailler","lemma":"retailler","pos":"VER"} ,
		{"word":"retaillé","word_nosc":"retaille","lemma":"retailler","pos":"VER"} ,
		{"word":"retaillée","word_nosc":"retaillee","lemma":"retailler","pos":"VER"} ,
		{"word":"retaillées","word_nosc":"retaillees","lemma":"retailler","pos":"VER"} ,
		{"word":"retaillés","word_nosc":"retailles","lemma":"retailler","pos":"VER"} ,
		{"word":"retapa","word_nosc":"retapa","lemma":"retaper","pos":"VER"} ,
		{"word":"retapaient","word_nosc":"retapaient","lemma":"retaper","pos":"VER"} ,
		{"word":"retapait","word_nosc":"retapait","lemma":"retaper","pos":"VER"} ,
		{"word":"retapant","word_nosc":"retapant","lemma":"retaper","pos":"VER"} ,
		{"word":"retape","word_nosc":"retape","lemma":"retaper","pos":"VER"} ,
		{"word":"retapent","word_nosc":"retapent","lemma":"retaper","pos":"VER"} ,
		{"word":"retaper","word_nosc":"retaper","lemma":"retaper","pos":"VER"} ,
		{"word":"retaperait","word_nosc":"retaperait","lemma":"retaper","pos":"VER"} ,
		{"word":"retapez","word_nosc":"retapez","lemma":"retaper","pos":"VER"} ,
		{"word":"retapissant","word_nosc":"retapissant","lemma":"retapisser","pos":"VER"} ,
		{"word":"retapisse","word_nosc":"retapisse","lemma":"retapisser","pos":"VER"} ,
		{"word":"retapissent","word_nosc":"retapissent","lemma":"retapisser","pos":"VER"} ,
		{"word":"retapisser","word_nosc":"retapisser","lemma":"retapisser","pos":"VER"} ,
		{"word":"retapisserait","word_nosc":"retapisserait","lemma":"retapisser","pos":"VER"} ,
		{"word":"retapisses","word_nosc":"retapisses","lemma":"retapisser","pos":"VER"} ,
		{"word":"retapissé","word_nosc":"retapisse","lemma":"retapisser","pos":"VER"} ,
		{"word":"retapissée","word_nosc":"retapissee","lemma":"retapisser","pos":"VER"} ,
		{"word":"retapissées","word_nosc":"retapissees","lemma":"retapisser","pos":"VER"} ,
		{"word":"retapé","word_nosc":"retape","lemma":"retaper","pos":"VER"} ,
		{"word":"retapée","word_nosc":"retapee","lemma":"retaper","pos":"VER"} ,
		{"word":"retapées","word_nosc":"retapees","lemma":"retaper","pos":"VER"} ,
		{"word":"retapés","word_nosc":"retapes","lemma":"retaper","pos":"VER"} ,
		{"word":"retarda","word_nosc":"retarda","lemma":"retarder","pos":"VER"} ,
		{"word":"retardaient","word_nosc":"retardaient","lemma":"retarder","pos":"VER"} ,
		{"word":"retardais","word_nosc":"retardais","lemma":"retarder","pos":"VER"} ,
		{"word":"retardait","word_nosc":"retardait","lemma":"retarder","pos":"VER"} ,
		{"word":"retardant","word_nosc":"retardant","lemma":"retarder","pos":"VER"} ,
		{"word":"retarde","word_nosc":"retarde","lemma":"retarder","pos":"VER"} ,
		{"word":"retardent","word_nosc":"retardent","lemma":"retarder","pos":"VER"} ,
		{"word":"retarder","word_nosc":"retarder","lemma":"retarder","pos":"VER"} ,
		{"word":"retardera","word_nosc":"retardera","lemma":"retarder","pos":"VER"} ,
		{"word":"retarderai","word_nosc":"retarderai","lemma":"retarder","pos":"VER"} ,
		{"word":"retarderaient","word_nosc":"retarderaient","lemma":"retarder","pos":"VER"} ,
		{"word":"retarderait","word_nosc":"retarderait","lemma":"retarder","pos":"VER"} ,
		{"word":"retarderez","word_nosc":"retarderez","lemma":"retarder","pos":"VER"} ,
		{"word":"retarderiez","word_nosc":"retarderiez","lemma":"retarder","pos":"VER"} ,
		{"word":"retardes","word_nosc":"retardes","lemma":"retarder","pos":"VER"} ,
		{"word":"retardez","word_nosc":"retardez","lemma":"retarder","pos":"VER"} ,
		{"word":"retardions","word_nosc":"retardions","lemma":"retarder","pos":"VER"} ,
		{"word":"retardons","word_nosc":"retardons","lemma":"retarder","pos":"VER"} ,
		{"word":"retardât","word_nosc":"retardat","lemma":"retarder","pos":"VER"} ,
		{"word":"retardèrent","word_nosc":"retarderent","lemma":"retarder","pos":"VER"} ,
		{"word":"retardé","word_nosc":"retarde","lemma":"retarder","pos":"VER"} ,
		{"word":"retardée","word_nosc":"retardee","lemma":"retarder","pos":"VER"} ,
		{"word":"retardées","word_nosc":"retardees","lemma":"retarder","pos":"VER"} ,
		{"word":"retardés","word_nosc":"retardes","lemma":"retarder","pos":"VER"} ,
		{"word":"reteindre","word_nosc":"reteindre","lemma":"reteindre","pos":"VER"} ,
		{"word":"reteints","word_nosc":"reteints","lemma":"reteindre","pos":"VER"} ,
		{"word":"retenaient","word_nosc":"retenaient","lemma":"retenir","pos":"VER"} ,
		{"word":"retenais","word_nosc":"retenais","lemma":"retenir","pos":"VER"} ,
		{"word":"retenait","word_nosc":"retenait","lemma":"retenir","pos":"VER"} ,
		{"word":"retenant","word_nosc":"retenant","lemma":"retenir","pos":"VER"} ,
		{"word":"retendait","word_nosc":"retendait","lemma":"retendre","pos":"VER"} ,
		{"word":"retendre","word_nosc":"retendre","lemma":"retendre","pos":"VER"} ,
		{"word":"retends","word_nosc":"retends","lemma":"retendre","pos":"VER"} ,
		{"word":"retendu","word_nosc":"retendu","lemma":"retendre","pos":"VER"} ,
		{"word":"retendues","word_nosc":"retendues","lemma":"retendre","pos":"VER"} ,
		{"word":"retenez","word_nosc":"retenez","lemma":"retenir","pos":"VER"} ,
		{"word":"reteniez","word_nosc":"reteniez","lemma":"retenir","pos":"VER"} ,
		{"word":"retenions","word_nosc":"retenions","lemma":"retenir","pos":"VER"} ,
		{"word":"retenir","word_nosc":"retenir","lemma":"retenir","pos":"VER"} ,
		{"word":"retenons","word_nosc":"retenons","lemma":"retenir","pos":"VER"} ,
		{"word":"retente","word_nosc":"retente","lemma":"retenter","pos":"VER"} ,
		{"word":"retenter","word_nosc":"retenter","lemma":"retenter","pos":"VER"} ,
		{"word":"retentes","word_nosc":"retentes","lemma":"retenter","pos":"VER"} ,
		{"word":"retenti","word_nosc":"retenti","lemma":"retentir","pos":"VER"} ,
		{"word":"retentir","word_nosc":"retentir","lemma":"retentir","pos":"VER"} ,
		{"word":"retentira","word_nosc":"retentira","lemma":"retentir","pos":"VER"} ,
		{"word":"retentirait","word_nosc":"retentirait","lemma":"retentir","pos":"VER"} ,
		{"word":"retentirent","word_nosc":"retentirent","lemma":"retentir","pos":"VER"} ,
		{"word":"retentiront","word_nosc":"retentiront","lemma":"retentir","pos":"VER"} ,
		{"word":"retentissaient","word_nosc":"retentissaient","lemma":"retentir","pos":"VER"} ,
		{"word":"retentissait","word_nosc":"retentissait","lemma":"retentir","pos":"VER"} ,
		{"word":"retentissant","word_nosc":"retentissant","lemma":"retentir","pos":"VER"} ,
		{"word":"retentisse","word_nosc":"retentisse","lemma":"retentir","pos":"VER"} ,
		{"word":"retentissent","word_nosc":"retentissent","lemma":"retentir","pos":"VER"} ,
		{"word":"retentit","word_nosc":"retentit","lemma":"retentir","pos":"VER"} ,
		{"word":"retenu","word_nosc":"retenu","lemma":"retenir","pos":"VER"} ,
		{"word":"retenue","word_nosc":"retenue","lemma":"retenir","pos":"VER"} ,
		{"word":"retenues","word_nosc":"retenues","lemma":"retenir","pos":"VER"} ,
		{"word":"retenus","word_nosc":"retenus","lemma":"retenir","pos":"VER"} ,
		{"word":"retiendra","word_nosc":"retiendra","lemma":"retenir","pos":"VER"} ,
		{"word":"retiendrai","word_nosc":"retiendrai","lemma":"retenir","pos":"VER"} ,
		{"word":"retiendraient","word_nosc":"retiendraient","lemma":"retenir","pos":"VER"} ,
		{"word":"retiendrais","word_nosc":"retiendrais","lemma":"retenir","pos":"VER"} ,
		{"word":"retiendrait","word_nosc":"retiendrait","lemma":"retenir","pos":"VER"} ,
		{"word":"retiendras","word_nosc":"retiendras","lemma":"retenir","pos":"VER"} ,
		{"word":"retiendrez","word_nosc":"retiendrez","lemma":"retenir","pos":"VER"} ,
		{"word":"retiendrions","word_nosc":"retiendrions","lemma":"retenir","pos":"VER"} ,
		{"word":"retiendrons","word_nosc":"retiendrons","lemma":"retenir","pos":"VER"} ,
		{"word":"retiendront","word_nosc":"retiendront","lemma":"retenir","pos":"VER"} ,
		{"word":"retienne","word_nosc":"retienne","lemma":"retenir","pos":"VER"} ,
		{"word":"retiennent","word_nosc":"retiennent","lemma":"retenir","pos":"VER"} ,
		{"word":"retiennes","word_nosc":"retiennes","lemma":"retenir","pos":"VER"} ,
		{"word":"retiens","word_nosc":"retiens","lemma":"retenir","pos":"VER"} ,
		{"word":"retient","word_nosc":"retient","lemma":"retenir","pos":"VER"} ,
		{"word":"retinrent","word_nosc":"retinrent","lemma":"retenir","pos":"VER"} ,
		{"word":"retins","word_nosc":"retins","lemma":"retenir","pos":"VER"} ,
		{"word":"retint","word_nosc":"retint","lemma":"retenir","pos":"VER"} ,
		{"word":"retira","word_nosc":"retira","lemma":"retirer","pos":"VER"} ,
		{"word":"retirai","word_nosc":"retirai","lemma":"retirer","pos":"VER"} ,
		{"word":"retiraient","word_nosc":"retiraient","lemma":"retirer","pos":"VER"} ,
		{"word":"retirais","word_nosc":"retirais","lemma":"retirer","pos":"VER"} ,
		{"word":"retirait","word_nosc":"retirait","lemma":"retirer","pos":"VER"} ,
		{"word":"retirant","word_nosc":"retirant","lemma":"retirer","pos":"VER"} ,
		{"word":"retire","word_nosc":"retire","lemma":"retirer","pos":"VER"} ,
		{"word":"retirent","word_nosc":"retirent","lemma":"retirer","pos":"VER"} ,
		{"word":"retirer","word_nosc":"retirer","lemma":"retirer","pos":"VER"} ,
		{"word":"retirera","word_nosc":"retirera","lemma":"retirer","pos":"VER"} ,
		{"word":"retirerai","word_nosc":"retirerai","lemma":"retirer","pos":"VER"} ,
		{"word":"retireraient","word_nosc":"retireraient","lemma":"retirer","pos":"VER"} ,
		{"word":"retirerais","word_nosc":"retirerais","lemma":"retirer","pos":"VER"} ,
		{"word":"retirerait","word_nosc":"retirerait","lemma":"retirer","pos":"VER"} ,
		{"word":"retireras","word_nosc":"retireras","lemma":"retirer","pos":"VER"} ,
		{"word":"retirerez","word_nosc":"retirerez","lemma":"retirer","pos":"VER"} ,
		{"word":"retireriez","word_nosc":"retireriez","lemma":"retirer","pos":"VER"} ,
		{"word":"retirerions","word_nosc":"retirerions","lemma":"retirer","pos":"VER"} ,
		{"word":"retirerons","word_nosc":"retirerons","lemma":"retirer","pos":"VER"} ,
		{"word":"retireront","word_nosc":"retireront","lemma":"retirer","pos":"VER"} ,
		{"word":"retires","word_nosc":"retires","lemma":"retirer","pos":"VER"} ,
		{"word":"retirez","word_nosc":"retirez","lemma":"retirer","pos":"VER"} ,
		{"word":"retiriez","word_nosc":"retiriez","lemma":"retirer","pos":"VER"} ,
		{"word":"retirions","word_nosc":"retirions","lemma":"retirer","pos":"VER"} ,
		{"word":"retirons","word_nosc":"retirons","lemma":"retirer","pos":"VER"} ,
		{"word":"retirâmes","word_nosc":"retirames","lemma":"retirer","pos":"VER"} ,
		{"word":"retirât","word_nosc":"retirat","lemma":"retirer","pos":"VER"} ,
		{"word":"retirèrent","word_nosc":"retirerent","lemma":"retirer","pos":"VER"} ,
		{"word":"retiré","word_nosc":"retire","lemma":"retirer","pos":"VER"} ,
		{"word":"retirée","word_nosc":"retiree","lemma":"retirer","pos":"VER"} ,
		{"word":"retirées","word_nosc":"retirees","lemma":"retirer","pos":"VER"} ,
		{"word":"retirés","word_nosc":"retires","lemma":"retirer","pos":"VER"} ,
		{"word":"retissaient","word_nosc":"retissaient","lemma":"retisser","pos":"VER"} ,
		{"word":"retissait","word_nosc":"retissait","lemma":"retisser","pos":"VER"} ,
		{"word":"retisser","word_nosc":"retisser","lemma":"retisser","pos":"VER"} ,
		{"word":"retomba","word_nosc":"retomba","lemma":"retomber","pos":"VER"} ,
		{"word":"retombai","word_nosc":"retombai","lemma":"retomber","pos":"VER"} ,
		{"word":"retombaient","word_nosc":"retombaient","lemma":"retomber","pos":"VER"} ,
		{"word":"retombais","word_nosc":"retombais","lemma":"retomber","pos":"VER"} ,
		{"word":"retombait","word_nosc":"retombait","lemma":"retomber","pos":"VER"} ,
		{"word":"retombant","word_nosc":"retombant","lemma":"retomber","pos":"VER"} ,
		{"word":"retombe","word_nosc":"retombe","lemma":"retomber","pos":"VER"} ,
		{"word":"retombent","word_nosc":"retombent","lemma":"retomber","pos":"VER"} ,
		{"word":"retomber","word_nosc":"retomber","lemma":"retomber","pos":"VER"} ,
		{"word":"retombera","word_nosc":"retombera","lemma":"retomber","pos":"VER"} ,
		{"word":"retomberai","word_nosc":"retomberai","lemma":"retomber","pos":"VER"} ,
		{"word":"retomberaient","word_nosc":"retomberaient","lemma":"retomber","pos":"VER"} ,
		{"word":"retomberais","word_nosc":"retomberais","lemma":"retomber","pos":"VER"} ,
		{"word":"retomberait","word_nosc":"retomberait","lemma":"retomber","pos":"VER"} ,
		{"word":"retomberas","word_nosc":"retomberas","lemma":"retomber","pos":"VER"} ,
		{"word":"retomberez","word_nosc":"retomberez","lemma":"retomber","pos":"VER"} ,
		{"word":"retomberont","word_nosc":"retomberont","lemma":"retomber","pos":"VER"} ,
		{"word":"retombes","word_nosc":"retombes","lemma":"retomber","pos":"VER"} ,
		{"word":"retombez","word_nosc":"retombez","lemma":"retomber","pos":"VER"} ,
		{"word":"retombions","word_nosc":"retombions","lemma":"retomber","pos":"VER"} ,
		{"word":"retombons","word_nosc":"retombons","lemma":"retomber","pos":"VER"} ,
		{"word":"retombâmes","word_nosc":"retombames","lemma":"retomber","pos":"VER"} ,
		{"word":"retombât","word_nosc":"retombat","lemma":"retomber","pos":"VER"} ,
		{"word":"retombèrent","word_nosc":"retomberent","lemma":"retomber","pos":"VER"} ,
		{"word":"retombé","word_nosc":"retombe","lemma":"retomber","pos":"VER"} ,
		{"word":"retombée","word_nosc":"retombee","lemma":"retomber","pos":"VER"} ,
		{"word":"retombées","word_nosc":"retombees","lemma":"retomber","pos":"VER"} ,
		{"word":"retombés","word_nosc":"retombes","lemma":"retomber","pos":"VER"} ,
		{"word":"retordre","word_nosc":"retordre","lemma":"retordre","pos":"VER"} ,
		{"word":"retoucha","word_nosc":"retoucha","lemma":"retoucher","pos":"VER"} ,
		{"word":"retouchai","word_nosc":"retouchai","lemma":"retoucher","pos":"VER"} ,
		{"word":"retouchait","word_nosc":"retouchait","lemma":"retoucher","pos":"VER"} ,
		{"word":"retouchant","word_nosc":"retouchant","lemma":"retoucher","pos":"VER"} ,
		{"word":"retouche","word_nosc":"retouche","lemma":"retoucher","pos":"VER"} ,
		{"word":"retouchent","word_nosc":"retouchent","lemma":"retoucher","pos":"VER"} ,
		{"word":"retoucher","word_nosc":"retoucher","lemma":"retoucher","pos":"VER"} ,
		{"word":"retouchera","word_nosc":"retouchera","lemma":"retoucher","pos":"VER"} ,
		{"word":"retoucherais","word_nosc":"retoucherais","lemma":"retoucher","pos":"VER"} ,
		{"word":"retoucherait","word_nosc":"retoucherait","lemma":"retoucher","pos":"VER"} ,
		{"word":"retouches","word_nosc":"retouches","lemma":"retoucher","pos":"VER"} ,
		{"word":"retouchez","word_nosc":"retouchez","lemma":"retoucher","pos":"VER"} ,
		{"word":"retouché","word_nosc":"retouche","lemma":"retoucher","pos":"VER"} ,
		{"word":"retouchée","word_nosc":"retouchee","lemma":"retoucher","pos":"VER"} ,
		{"word":"retouchées","word_nosc":"retouchees","lemma":"retoucher","pos":"VER"} ,
		{"word":"retouchés","word_nosc":"retouches","lemma":"retoucher","pos":"VER"} ,
		{"word":"retourna","word_nosc":"retourna","lemma":"retourner","pos":"VER"} ,
		{"word":"retournai","word_nosc":"retournai","lemma":"retourner","pos":"VER"} ,
		{"word":"retournaient","word_nosc":"retournaient","lemma":"retourner","pos":"VER"} ,
		{"word":"retournais","word_nosc":"retournais","lemma":"retourner","pos":"VER"} ,
		{"word":"retournait","word_nosc":"retournait","lemma":"retourner","pos":"VER"} ,
		{"word":"retournant","word_nosc":"retournant","lemma":"retourner","pos":"VER"} ,
		{"word":"retourne","word_nosc":"retourne","lemma":"retourner","pos":"VER"} ,
		{"word":"retournent","word_nosc":"retournent","lemma":"retourner","pos":"VER"} ,
		{"word":"retourner","word_nosc":"retourner","lemma":"retourner","pos":"VER"} ,
		{"word":"retournera","word_nosc":"retournera","lemma":"retourner","pos":"VER"} ,
		{"word":"retournerai","word_nosc":"retournerai","lemma":"retourner","pos":"VER"} ,
		{"word":"retourneraient","word_nosc":"retourneraient","lemma":"retourner","pos":"VER"} ,
		{"word":"retournerais","word_nosc":"retournerais","lemma":"retourner","pos":"VER"} ,
		{"word":"retournerait","word_nosc":"retournerait","lemma":"retourner","pos":"VER"} ,
		{"word":"retourneras","word_nosc":"retourneras","lemma":"retourner","pos":"VER"} ,
		{"word":"retournerez","word_nosc":"retournerez","lemma":"retourner","pos":"VER"} ,
		{"word":"retourneriez","word_nosc":"retourneriez","lemma":"retourner","pos":"VER"} ,
		{"word":"retournerions","word_nosc":"retournerions","lemma":"retourner","pos":"VER"} ,
		{"word":"retournerons","word_nosc":"retournerons","lemma":"retourner","pos":"VER"} ,
		{"word":"retourneront","word_nosc":"retourneront","lemma":"retourner","pos":"VER"} ,
		{"word":"retournes","word_nosc":"retournes","lemma":"retourner","pos":"VER"} ,
		{"word":"retournez","word_nosc":"retournez","lemma":"retourner","pos":"VER"} ,
		{"word":"retourniez","word_nosc":"retourniez","lemma":"retourner","pos":"VER"} ,
		{"word":"retournions","word_nosc":"retournions","lemma":"retourner","pos":"VER"} ,
		{"word":"retournons","word_nosc":"retournons","lemma":"retourner","pos":"VER"} ,
		{"word":"retournâmes","word_nosc":"retournames","lemma":"retourner","pos":"VER"} ,
		{"word":"retournât","word_nosc":"retournat","lemma":"retourner","pos":"VER"} ,
		{"word":"retournèrent","word_nosc":"retournerent","lemma":"retourner","pos":"VER"} ,
		{"word":"retourné","word_nosc":"retourne","lemma":"retourner","pos":"VER"} ,
		{"word":"retournée","word_nosc":"retournee","lemma":"retourner","pos":"VER"} ,
		{"word":"retournées","word_nosc":"retournees","lemma":"retourner","pos":"VER"} ,
		{"word":"retournés","word_nosc":"retournes","lemma":"retourner","pos":"VER"} ,
		{"word":"retrace","word_nosc":"retrace","lemma":"retracer","pos":"VER"} ,
		{"word":"retracent","word_nosc":"retracent","lemma":"retracer","pos":"VER"} ,
		{"word":"retracer","word_nosc":"retracer","lemma":"retracer","pos":"VER"} ,
		{"word":"retracera","word_nosc":"retracera","lemma":"retracer","pos":"VER"} ,
		{"word":"retracerais","word_nosc":"retracerais","lemma":"retracer","pos":"VER"} ,
		{"word":"retracerait","word_nosc":"retracerait","lemma":"retracer","pos":"VER"} ,
		{"word":"retraceront","word_nosc":"retraceront","lemma":"retracer","pos":"VER"} ,
		{"word":"retracez","word_nosc":"retracez","lemma":"retracer","pos":"VER"} ,
		{"word":"retracions","word_nosc":"retracions","lemma":"retracer","pos":"VER"} ,
		{"word":"retracé","word_nosc":"retrace","lemma":"retracer","pos":"VER"} ,
		{"word":"retracée","word_nosc":"retracee","lemma":"retracer","pos":"VER"} ,
		{"word":"retraduit","word_nosc":"retraduit","lemma":"retraduire","pos":"VER"} ,
		{"word":"retraire","word_nosc":"retraire","lemma":"retraire","pos":"VER"} ,
		{"word":"retrait","word_nosc":"retrait","lemma":"retraire","pos":"VER"} ,
		{"word":"retraitait","word_nosc":"retraitait","lemma":"retraiter","pos":"VER"} ,
		{"word":"retraitions","word_nosc":"retraitions","lemma":"retraiter","pos":"VER"} ,
		{"word":"retraits","word_nosc":"retraits","lemma":"retraire","pos":"VER"} ,
		{"word":"retraité","word_nosc":"retraite","lemma":"retraiter","pos":"VER"} ,
		{"word":"retraitée","word_nosc":"retraitee","lemma":"retraiter","pos":"VER"} ,
		{"word":"retraitées","word_nosc":"retraitees","lemma":"retraiter","pos":"VER"} ,
		{"word":"retraités","word_nosc":"retraites","lemma":"retraiter","pos":"VER"} ,
		{"word":"retranchai","word_nosc":"retranchai","lemma":"retrancher","pos":"VER"} ,
		{"word":"retranchaient","word_nosc":"retranchaient","lemma":"retrancher","pos":"VER"} ,
		{"word":"retranchait","word_nosc":"retranchait","lemma":"retrancher","pos":"VER"} ,
		{"word":"retranchant","word_nosc":"retranchant","lemma":"retrancher","pos":"VER"} ,
		{"word":"retranche","word_nosc":"retranche","lemma":"retrancher","pos":"VER"} ,
		{"word":"retranchent","word_nosc":"retranchent","lemma":"retrancher","pos":"VER"} ,
		{"word":"retrancher","word_nosc":"retrancher","lemma":"retrancher","pos":"VER"} ,
		{"word":"retranchera","word_nosc":"retranchera","lemma":"retrancher","pos":"VER"} ,
		{"word":"retranchez","word_nosc":"retranchez","lemma":"retrancher","pos":"VER"} ,
		{"word":"retranchions","word_nosc":"retranchions","lemma":"retrancher","pos":"VER"} ,
		{"word":"retranché","word_nosc":"retranche","lemma":"retrancher","pos":"VER"} ,
		{"word":"retranchée","word_nosc":"retranchee","lemma":"retrancher","pos":"VER"} ,
		{"word":"retranchées","word_nosc":"retranchees","lemma":"retrancher","pos":"VER"} ,
		{"word":"retranchés","word_nosc":"retranches","lemma":"retrancher","pos":"VER"} ,
		{"word":"retranscrire","word_nosc":"retranscrire","lemma":"retranscrire","pos":"VER"} ,
		{"word":"retranscris","word_nosc":"retranscris","lemma":"retranscrire","pos":"VER"} ,
		{"word":"retranscrit","word_nosc":"retranscrit","lemma":"retranscrire","pos":"VER"} ,
		{"word":"retranscrits","word_nosc":"retranscrits","lemma":"retranscrire","pos":"VER"} ,
		{"word":"retransforme","word_nosc":"retransforme","lemma":"retransformer","pos":"VER"} ,
		{"word":"retransformer","word_nosc":"retransformer","lemma":"retransformer","pos":"VER"} ,
		{"word":"retransformera","word_nosc":"retransformera","lemma":"retransformer","pos":"VER"} ,
		{"word":"retransférer","word_nosc":"retransferer","lemma":"retransférer","pos":"VER"} ,
		{"word":"retransmet","word_nosc":"retransmet","lemma":"retransmettre","pos":"VER"} ,
		{"word":"retransmettait","word_nosc":"retransmettait","lemma":"retransmettre","pos":"VER"} ,
		{"word":"retransmettant","word_nosc":"retransmettant","lemma":"retransmettre","pos":"VER"} ,
		{"word":"retransmettez","word_nosc":"retransmettez","lemma":"retransmettre","pos":"VER"} ,
		{"word":"retransmettra","word_nosc":"retransmettra","lemma":"retransmettre","pos":"VER"} ,
		{"word":"retransmettre","word_nosc":"retransmettre","lemma":"retransmettre","pos":"VER"} ,
		{"word":"retransmettrons","word_nosc":"retransmettrons","lemma":"retransmettre","pos":"VER"} ,
		{"word":"retransmis","word_nosc":"retransmis","lemma":"retransmettre","pos":"VER"} ,
		{"word":"retransmise","word_nosc":"retransmise","lemma":"retransmettre","pos":"VER"} ,
		{"word":"retransmit","word_nosc":"retransmit","lemma":"retransmettre","pos":"VER"} ,
		{"word":"retravaillait","word_nosc":"retravaillait","lemma":"retravailler","pos":"VER"} ,
		{"word":"retravaille","word_nosc":"retravaille","lemma":"retravailler","pos":"VER"} ,
		{"word":"retravaillent","word_nosc":"retravaillent","lemma":"retravailler","pos":"VER"} ,
		{"word":"retravailler","word_nosc":"retravailler","lemma":"retravailler","pos":"VER"} ,
		{"word":"retravaillera","word_nosc":"retravaillera","lemma":"retravailler","pos":"VER"} ,
		{"word":"retravaillerai","word_nosc":"retravaillerai","lemma":"retravailler","pos":"VER"} ,
		{"word":"retravaillerait","word_nosc":"retravaillerait","lemma":"retravailler","pos":"VER"} ,
		{"word":"retravaillerez","word_nosc":"retravaillerez","lemma":"retravailler","pos":"VER"} ,
		{"word":"retravailles","word_nosc":"retravailles","lemma":"retravailler","pos":"VER"} ,
		{"word":"retravaillez","word_nosc":"retravaillez","lemma":"retravailler","pos":"VER"} ,
		{"word":"retravaillé","word_nosc":"retravaille","lemma":"retravailler","pos":"VER"} ,
		{"word":"retravaillée","word_nosc":"retravaillee","lemma":"retravailler","pos":"VER"} ,
		{"word":"retraversa","word_nosc":"retraversa","lemma":"retraverser","pos":"VER"} ,
		{"word":"retraversai","word_nosc":"retraversai","lemma":"retraverser","pos":"VER"} ,
		{"word":"retraversaient","word_nosc":"retraversaient","lemma":"retraverser","pos":"VER"} ,
		{"word":"retraversais","word_nosc":"retraversais","lemma":"retraverser","pos":"VER"} ,
		{"word":"retraversait","word_nosc":"retraversait","lemma":"retraverser","pos":"VER"} ,
		{"word":"retraversant","word_nosc":"retraversant","lemma":"retraverser","pos":"VER"} ,
		{"word":"retraverse","word_nosc":"retraverse","lemma":"retraverser","pos":"VER"} ,
		{"word":"retraversent","word_nosc":"retraversent","lemma":"retraverser","pos":"VER"} ,
		{"word":"retraverser","word_nosc":"retraverser","lemma":"retraverser","pos":"VER"} ,
		{"word":"retraverserait","word_nosc":"retraverserait","lemma":"retraverser","pos":"VER"} ,
		{"word":"retraversions","word_nosc":"retraversions","lemma":"retraverser","pos":"VER"} ,
		{"word":"retraversons","word_nosc":"retraversons","lemma":"retraverser","pos":"VER"} ,
		{"word":"retraversé","word_nosc":"retraverse","lemma":"retraverser","pos":"VER"} ,
		{"word":"retraversée","word_nosc":"retraversee","lemma":"retraverser","pos":"VER"} ,
		{"word":"retraçaient","word_nosc":"retracaient","lemma":"retracer","pos":"VER"} ,
		{"word":"retraçais","word_nosc":"retracais","lemma":"retracer","pos":"VER"} ,
		{"word":"retraçait","word_nosc":"retracait","lemma":"retracer","pos":"VER"} ,
		{"word":"retraçant","word_nosc":"retracant","lemma":"retracer","pos":"VER"} ,
		{"word":"retrempai","word_nosc":"retrempai","lemma":"retremper","pos":"VER"} ,
		{"word":"retrempaient","word_nosc":"retrempaient","lemma":"retremper","pos":"VER"} ,
		{"word":"retrempait","word_nosc":"retrempait","lemma":"retremper","pos":"VER"} ,
		{"word":"retremper","word_nosc":"retremper","lemma":"retremper","pos":"VER"} ,
		{"word":"retrempé","word_nosc":"retrempe","lemma":"retremper","pos":"VER"} ,
		{"word":"retroussa","word_nosc":"retroussa","lemma":"retrousser","pos":"VER"} ,
		{"word":"retroussaient","word_nosc":"retroussaient","lemma":"retrousser","pos":"VER"} ,
		{"word":"retroussais","word_nosc":"retroussais","lemma":"retrousser","pos":"VER"} ,
		{"word":"retroussait","word_nosc":"retroussait","lemma":"retrousser","pos":"VER"} ,
		{"word":"retroussant","word_nosc":"retroussant","lemma":"retrousser","pos":"VER"} ,
		{"word":"retrousse","word_nosc":"retrousse","lemma":"retrousser","pos":"VER"} ,
		{"word":"retroussent","word_nosc":"retroussent","lemma":"retrousser","pos":"VER"} ,
		{"word":"retrousser","word_nosc":"retrousser","lemma":"retrousser","pos":"VER"} ,
		{"word":"retroussez","word_nosc":"retroussez","lemma":"retrousser","pos":"VER"} ,
		{"word":"retroussé","word_nosc":"retrousse","lemma":"retrousser","pos":"VER"} ,
		{"word":"retroussée","word_nosc":"retroussee","lemma":"retrousser","pos":"VER"} ,
		{"word":"retroussées","word_nosc":"retroussees","lemma":"retrousser","pos":"VER"} ,
		{"word":"retroussés","word_nosc":"retrousses","lemma":"retrousser","pos":"VER"} ,
		{"word":"retrouva","word_nosc":"retrouva","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvai","word_nosc":"retrouvai","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvaient","word_nosc":"retrouvaient","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvais","word_nosc":"retrouvais","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvait","word_nosc":"retrouvait","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvant","word_nosc":"retrouvant","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvas","word_nosc":"retrouvas","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouve","word_nosc":"retrouve","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvent","word_nosc":"retrouvent","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouver","word_nosc":"retrouver","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvera","word_nosc":"retrouvera","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouverai","word_nosc":"retrouverai","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouveraient","word_nosc":"retrouveraient","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouverais","word_nosc":"retrouverais","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouverait","word_nosc":"retrouverait","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouveras","word_nosc":"retrouveras","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouverez","word_nosc":"retrouverez","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouveriez","word_nosc":"retrouveriez","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouverions","word_nosc":"retrouverions","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouverons","word_nosc":"retrouverons","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouveront","word_nosc":"retrouveront","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouves","word_nosc":"retrouves","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvez","word_nosc":"retrouvez","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouviez","word_nosc":"retrouviez","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvions","word_nosc":"retrouvions","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvons","word_nosc":"retrouvons","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvâmes","word_nosc":"retrouvames","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvât","word_nosc":"retrouvat","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvèrent","word_nosc":"retrouverent","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvé","word_nosc":"retrouve","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvée","word_nosc":"retrouvee","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvées","word_nosc":"retrouvees","lemma":"retrouver","pos":"VER"} ,
		{"word":"retrouvés","word_nosc":"retrouves","lemma":"retrouver","pos":"VER"} ,
		{"word":"retuber","word_nosc":"retuber","lemma":"retuber","pos":"VER"} ,
		{"word":"retuer","word_nosc":"retuer","lemma":"retuer","pos":"VER"} ,
		{"word":"retâté","word_nosc":"retate","lemma":"retâter","pos":"VER"} ,
		{"word":"retéléphone","word_nosc":"retelephone","lemma":"retéléphoner","pos":"VER"} ,
		{"word":"retéléphoner","word_nosc":"retelephoner","lemma":"retéléphoner","pos":"VER"} ,
		{"word":"retînmes","word_nosc":"retinmes","lemma":"retenir","pos":"VER"} ,
		{"word":"retînt","word_nosc":"retint","lemma":"retenir","pos":"VER"} ,
		{"word":"revalider","word_nosc":"revalider","lemma":"revalider","pos":"VER"} ,
		{"word":"revaloir","word_nosc":"revaloir","lemma":"revaloir","pos":"VER"} ,
		{"word":"revalorisait","word_nosc":"revalorisait","lemma":"revaloriser","pos":"VER"} ,
		{"word":"revalorisent","word_nosc":"revalorisent","lemma":"revaloriser","pos":"VER"} ,
		{"word":"revaloriser","word_nosc":"revaloriser","lemma":"revaloriser","pos":"VER"} ,
		{"word":"revalorisé","word_nosc":"revalorise","lemma":"revaloriser","pos":"VER"} ,
		{"word":"revancha","word_nosc":"revancha","lemma":"revancher","pos":"VER"} ,
		{"word":"revanchaient","word_nosc":"revanchaient","lemma":"revancher","pos":"VER"} ,
		{"word":"revanchent","word_nosc":"revanchent","lemma":"revancher","pos":"VER"} ,
		{"word":"revancher","word_nosc":"revancher","lemma":"revancher","pos":"VER"} ,
		{"word":"revaudra","word_nosc":"revaudra","lemma":"revaloir","pos":"VER"} ,
		{"word":"revaudrai","word_nosc":"revaudrai","lemma":"revaloir","pos":"VER"} ,
		{"word":"revaudraient","word_nosc":"revaudraient","lemma":"revaloir","pos":"VER"} ,
		{"word":"revaudrais","word_nosc":"revaudrais","lemma":"revaloir","pos":"VER"} ,
		{"word":"revaudrait","word_nosc":"revaudrait","lemma":"revaloir","pos":"VER"} ,
		{"word":"revaudras","word_nosc":"revaudras","lemma":"revaloir","pos":"VER"} ,
		{"word":"revenaient","word_nosc":"revenaient","lemma":"revenir","pos":"VER"} ,
		{"word":"revenais","word_nosc":"revenais","lemma":"revenir","pos":"VER"} ,
		{"word":"revenait","word_nosc":"revenait","lemma":"revenir","pos":"VER"} ,
		{"word":"revenant","word_nosc":"revenant","lemma":"revenir","pos":"VER"} ,
		{"word":"revend","word_nosc":"revend","lemma":"revendre","pos":"VER"} ,
		{"word":"revendaient","word_nosc":"revendaient","lemma":"revendre","pos":"VER"} ,
		{"word":"revendais","word_nosc":"revendais","lemma":"revendre","pos":"VER"} ,
		{"word":"revendait","word_nosc":"revendait","lemma":"revendre","pos":"VER"} ,
		{"word":"revendant","word_nosc":"revendant","lemma":"revendre","pos":"VER"} ,
		{"word":"revendent","word_nosc":"revendent","lemma":"revendre","pos":"VER"} ,
		{"word":"revendes","word_nosc":"revendes","lemma":"revendre","pos":"VER"} ,
		{"word":"revendez","word_nosc":"revendez","lemma":"revendre","pos":"VER"} ,
		{"word":"revendiqua","word_nosc":"revendiqua","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendiquai","word_nosc":"revendiquai","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendiquaient","word_nosc":"revendiquaient","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendiquais","word_nosc":"revendiquais","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendiquait","word_nosc":"revendiquait","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendiquant","word_nosc":"revendiquant","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendique","word_nosc":"revendique","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendiquent","word_nosc":"revendiquent","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendiquer","word_nosc":"revendiquer","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendiquera","word_nosc":"revendiquera","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendiquerais","word_nosc":"revendiquerais","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendiquerait","word_nosc":"revendiquerait","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendiquez","word_nosc":"revendiquez","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendiquons","word_nosc":"revendiquons","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendiquât","word_nosc":"revendiquat","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendiqué","word_nosc":"revendique","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendiquée","word_nosc":"revendiquee","lemma":"revendiquer","pos":"VER"} ,
		{"word":"revendis","word_nosc":"revendis","lemma":"revendre","pos":"VER"} ,
		{"word":"revendit","word_nosc":"revendit","lemma":"revendre","pos":"VER"} ,
		{"word":"revendra","word_nosc":"revendra","lemma":"revendre","pos":"VER"} ,
		{"word":"revendrai","word_nosc":"revendrai","lemma":"revendre","pos":"VER"} ,
		{"word":"revendraient","word_nosc":"revendraient","lemma":"revendre","pos":"VER"} ,
		{"word":"revendrais","word_nosc":"revendrais","lemma":"revendre","pos":"VER"} ,
		{"word":"revendrait","word_nosc":"revendrait","lemma":"revendre","pos":"VER"} ,
		{"word":"revendre","word_nosc":"revendre","lemma":"revendre","pos":"VER"} ,
		{"word":"revendrons","word_nosc":"revendrons","lemma":"revendre","pos":"VER"} ,
		{"word":"revendront","word_nosc":"revendront","lemma":"revendre","pos":"VER"} ,
		{"word":"revends","word_nosc":"revends","lemma":"revendre","pos":"VER"} ,
		{"word":"revendu","word_nosc":"revendu","lemma":"revendre","pos":"VER"} ,
		{"word":"revendue","word_nosc":"revendue","lemma":"revendre","pos":"VER"} ,
		{"word":"revendues","word_nosc":"revendues","lemma":"revendre","pos":"VER"} ,
		{"word":"revendus","word_nosc":"revendus","lemma":"revendre","pos":"VER"} ,
		{"word":"revenez","word_nosc":"revenez","lemma":"revenir","pos":"VER"} ,
		{"word":"reveniez","word_nosc":"reveniez","lemma":"revenir","pos":"VER"} ,
		{"word":"revenions","word_nosc":"revenions","lemma":"revenir","pos":"VER"} ,
		{"word":"revenir","word_nosc":"revenir","lemma":"revenir","pos":"VER"} ,
		{"word":"revenons","word_nosc":"revenons","lemma":"revenir","pos":"VER"} ,
		{"word":"revenu","word_nosc":"revenu","lemma":"revenir","pos":"VER"} ,
		{"word":"revenue","word_nosc":"revenue","lemma":"revenir","pos":"VER"} ,
		{"word":"revenues","word_nosc":"revenues","lemma":"revenir","pos":"VER"} ,
		{"word":"revenus","word_nosc":"revenus","lemma":"revenir","pos":"VER"} ,
		{"word":"reverdi","word_nosc":"reverdi","lemma":"reverdir","pos":"VER"} ,
		{"word":"reverdie","word_nosc":"reverdie","lemma":"reverdir","pos":"VER"} ,
		{"word":"reverdies","word_nosc":"reverdies","lemma":"reverdir","pos":"VER"} ,
		{"word":"reverdir","word_nosc":"reverdir","lemma":"reverdir","pos":"VER"} ,
		{"word":"reverdiront","word_nosc":"reverdiront","lemma":"reverdir","pos":"VER"} ,
		{"word":"reverdissaient","word_nosc":"reverdissaient","lemma":"reverdir","pos":"VER"} ,
		{"word":"reverdit","word_nosc":"reverdit","lemma":"reverdir","pos":"VER"} ,
		{"word":"reverni","word_nosc":"reverni","lemma":"revernir","pos":"VER"} ,
		{"word":"revernie","word_nosc":"revernie","lemma":"revernir","pos":"VER"} ,
		{"word":"revernir","word_nosc":"revernir","lemma":"revernir","pos":"VER"} ,
		{"word":"reverra","word_nosc":"reverra","lemma":"revoir","pos":"VER"} ,
		{"word":"reverrai","word_nosc":"reverrai","lemma":"revoir","pos":"VER"} ,
		{"word":"reverraient","word_nosc":"reverraient","lemma":"revoir","pos":"VER"} ,
		{"word":"reverrais","word_nosc":"reverrais","lemma":"revoir","pos":"VER"} ,
		{"word":"reverrait","word_nosc":"reverrait","lemma":"revoir","pos":"VER"} ,
		{"word":"reverras","word_nosc":"reverras","lemma":"revoir","pos":"VER"} ,
		{"word":"reverrez","word_nosc":"reverrez","lemma":"revoir","pos":"VER"} ,
		{"word":"reverrions","word_nosc":"reverrions","lemma":"revoir","pos":"VER"} ,
		{"word":"reverrons","word_nosc":"reverrons","lemma":"revoir","pos":"VER"} ,
		{"word":"reverront","word_nosc":"reverront","lemma":"revoir","pos":"VER"} ,
		{"word":"reversa","word_nosc":"reversa","lemma":"reverser","pos":"VER"} ,
		{"word":"reversaient","word_nosc":"reversaient","lemma":"reverser","pos":"VER"} ,
		{"word":"reversait","word_nosc":"reversait","lemma":"reverser","pos":"VER"} ,
		{"word":"reversant","word_nosc":"reversant","lemma":"reverser","pos":"VER"} ,
		{"word":"reverse","word_nosc":"reverse","lemma":"reverser","pos":"VER"} ,
		{"word":"reverser","word_nosc":"reverser","lemma":"reverser","pos":"VER"} ,
		{"word":"reverserait","word_nosc":"reverserait","lemma":"reverser","pos":"VER"} ,
		{"word":"reversez","word_nosc":"reversez","lemma":"reverser","pos":"VER"} ,
		{"word":"reversé","word_nosc":"reverse","lemma":"reverser","pos":"VER"} ,
		{"word":"reveuille","word_nosc":"reveuille","lemma":"revouloir","pos":"VER"} ,
		{"word":"reveulent","word_nosc":"reveulent","lemma":"revouloir","pos":"VER"} ,
		{"word":"reveux","word_nosc":"reveux","lemma":"revouloir","pos":"VER"} ,
		{"word":"revida","word_nosc":"revida","lemma":"revider","pos":"VER"} ,
		{"word":"revidèrent","word_nosc":"reviderent","lemma":"revider","pos":"VER"} ,
		{"word":"reviendra","word_nosc":"reviendra","lemma":"revenir","pos":"VER"} ,
		{"word":"reviendrai","word_nosc":"reviendrai","lemma":"revenir","pos":"VER"} ,
		{"word":"reviendraient","word_nosc":"reviendraient","lemma":"revenir","pos":"VER"} ,
		{"word":"reviendrais","word_nosc":"reviendrais","lemma":"revenir","pos":"VER"} ,
		{"word":"reviendrait","word_nosc":"reviendrait","lemma":"revenir","pos":"VER"} ,
		{"word":"reviendras","word_nosc":"reviendras","lemma":"revenir","pos":"VER"} ,
		{"word":"reviendrez","word_nosc":"reviendrez","lemma":"revenir","pos":"VER"} ,
		{"word":"reviendriez","word_nosc":"reviendriez","lemma":"revenir","pos":"VER"} ,
		{"word":"reviendrions","word_nosc":"reviendrions","lemma":"revenir","pos":"VER"} ,
		{"word":"reviendrons","word_nosc":"reviendrons","lemma":"revenir","pos":"VER"} ,
		{"word":"reviendront","word_nosc":"reviendront","lemma":"revenir","pos":"VER"} ,
		{"word":"revienne","word_nosc":"revienne","lemma":"revenir","pos":"VER"} ,
		{"word":"reviennent","word_nosc":"reviennent","lemma":"revenir","pos":"VER"} ,
		{"word":"reviennes","word_nosc":"reviennes","lemma":"revenir","pos":"VER"} ,
		{"word":"reviens","word_nosc":"reviens","lemma":"revenir","pos":"VER"} ,
		{"word":"revient","word_nosc":"revient","lemma":"revenir","pos":"VER"} ,
		{"word":"revigoraient","word_nosc":"revigoraient","lemma":"revigorer","pos":"VER"} ,
		{"word":"revigorant","word_nosc":"revigorant","lemma":"revigorer","pos":"VER"} ,
		{"word":"revigore","word_nosc":"revigore","lemma":"revigorer","pos":"VER"} ,
		{"word":"revigorent","word_nosc":"revigorent","lemma":"revigorer","pos":"VER"} ,
		{"word":"revigorer","word_nosc":"revigorer","lemma":"revigorer","pos":"VER"} ,
		{"word":"revigoré","word_nosc":"revigore","lemma":"revigorer","pos":"VER"} ,
		{"word":"revigorée","word_nosc":"revigoree","lemma":"revigorer","pos":"VER"} ,
		{"word":"revigorées","word_nosc":"revigorees","lemma":"revigorer","pos":"VER"} ,
		{"word":"revinrent","word_nosc":"revinrent","lemma":"revenir","pos":"VER"} ,
		{"word":"revins","word_nosc":"revins","lemma":"revenir","pos":"VER"} ,
		{"word":"revinssent","word_nosc":"revinssent","lemma":"revenir","pos":"VER"} ,
		{"word":"revint","word_nosc":"revint","lemma":"revenir","pos":"VER"} ,
		{"word":"revirent","word_nosc":"revirent","lemma":"revirer","pos":"VER"} ,
		{"word":"revirent","word_nosc":"revirent","lemma":"revoir","pos":"VER"} ,
		{"word":"revirer","word_nosc":"revirer","lemma":"revirer","pos":"VER"} ,
		{"word":"revis","word_nosc":"revis","lemma":"revivre","pos":"VER"} ,
		{"word":"revisita","word_nosc":"revisita","lemma":"revisiter","pos":"VER"} ,
		{"word":"revisite","word_nosc":"revisite","lemma":"revisiter","pos":"VER"} ,
		{"word":"revisitent","word_nosc":"revisitent","lemma":"revisiter","pos":"VER"} ,
		{"word":"revisiter","word_nosc":"revisiter","lemma":"revisiter","pos":"VER"} ,
		{"word":"revisité","word_nosc":"revisite","lemma":"revisiter","pos":"VER"} ,
		{"word":"revisitée","word_nosc":"revisitee","lemma":"revisiter","pos":"VER"} ,
		{"word":"revissa","word_nosc":"revissa","lemma":"revisser","pos":"VER"} ,
		{"word":"revisse","word_nosc":"revisse","lemma":"revisser","pos":"VER"} ,
		{"word":"revisser","word_nosc":"revisser","lemma":"revisser","pos":"VER"} ,
		{"word":"revissé","word_nosc":"revisse","lemma":"revisser","pos":"VER"} ,
		{"word":"revissées","word_nosc":"revissees","lemma":"revisser","pos":"VER"} ,
		{"word":"revit","word_nosc":"revit","lemma":"revivre","pos":"VER"} ,
		{"word":"revit","word_nosc":"revit","lemma":"revoir","pos":"VER"} ,
		{"word":"revitalise","word_nosc":"revitalise","lemma":"revitaliser","pos":"VER"} ,
		{"word":"revitaliser","word_nosc":"revitaliser","lemma":"revitaliser","pos":"VER"} ,
		{"word":"revitalisera","word_nosc":"revitalisera","lemma":"revitaliser","pos":"VER"} ,
		{"word":"revivaient","word_nosc":"revivaient","lemma":"revivre","pos":"VER"} ,
		{"word":"revivais","word_nosc":"revivais","lemma":"revivre","pos":"VER"} ,
		{"word":"revivait","word_nosc":"revivait","lemma":"revivre","pos":"VER"} ,
		{"word":"revivant","word_nosc":"revivant","lemma":"revivre","pos":"VER"} ,
		{"word":"revive","word_nosc":"revive","lemma":"revivre","pos":"VER"} ,
		{"word":"revivent","word_nosc":"revivent","lemma":"revivre","pos":"VER"} ,
		{"word":"revivez","word_nosc":"revivez","lemma":"revivre","pos":"VER"} ,
		{"word":"revivifiant","word_nosc":"revivifiant","lemma":"revivifier","pos":"VER"} ,
		{"word":"revivifie","word_nosc":"revivifie","lemma":"revivifier","pos":"VER"} ,
		{"word":"revivifier","word_nosc":"revivifier","lemma":"revivifier","pos":"VER"} ,
		{"word":"revivifiée","word_nosc":"revivifiee","lemma":"revivifier","pos":"VER"} ,
		{"word":"revivifiées","word_nosc":"revivifiees","lemma":"revivifier","pos":"VER"} ,
		{"word":"revivions","word_nosc":"revivions","lemma":"revivre","pos":"VER"} ,
		{"word":"revivons","word_nosc":"revivons","lemma":"revivre","pos":"VER"} ,
		{"word":"revivra","word_nosc":"revivra","lemma":"revivre","pos":"VER"} ,
		{"word":"revivrai","word_nosc":"revivrai","lemma":"revivre","pos":"VER"} ,
		{"word":"revivraient","word_nosc":"revivraient","lemma":"revivre","pos":"VER"} ,
		{"word":"revivrais","word_nosc":"revivrais","lemma":"revivre","pos":"VER"} ,
		{"word":"revivrait","word_nosc":"revivrait","lemma":"revivre","pos":"VER"} ,
		{"word":"revivras","word_nosc":"revivras","lemma":"revivre","pos":"VER"} ,
		{"word":"revivre","word_nosc":"revivre","lemma":"revivre","pos":"VER"} ,
		{"word":"revivrez","word_nosc":"revivrez","lemma":"revivre","pos":"VER"} ,
		{"word":"revivrons","word_nosc":"revivrons","lemma":"revivre","pos":"VER"} ,
		{"word":"revoie","word_nosc":"revoie","lemma":"revoir","pos":"VER"} ,
		{"word":"revoient","word_nosc":"revoient","lemma":"revoir","pos":"VER"} ,
		{"word":"revoies","word_nosc":"revoies","lemma":"revoir","pos":"VER"} ,
		{"word":"revoir","word_nosc":"revoir","lemma":"revoir","pos":"VER"} ,
		{"word":"revois","word_nosc":"revois","lemma":"revoir","pos":"VER"} ,
		{"word":"revoit","word_nosc":"revoit","lemma":"revoir","pos":"VER"} ,
		{"word":"revolait","word_nosc":"revolait","lemma":"revoler","pos":"VER"} ,
		{"word":"revole","word_nosc":"revole","lemma":"revoler","pos":"VER"} ,
		{"word":"revolent","word_nosc":"revolent","lemma":"revoler","pos":"VER"} ,
		{"word":"revoler","word_nosc":"revoler","lemma":"revoler","pos":"VER"} ,
		{"word":"revolera","word_nosc":"revolera","lemma":"revoler","pos":"VER"} ,
		{"word":"revolé","word_nosc":"revole","lemma":"revoler","pos":"VER"} ,
		{"word":"revomir","word_nosc":"revomir","lemma":"revomir","pos":"VER"} ,
		{"word":"revomissant","word_nosc":"revomissant","lemma":"revomir","pos":"VER"} ,
		{"word":"revoter","word_nosc":"revoter","lemma":"revoter","pos":"VER"} ,
		{"word":"revoulait","word_nosc":"revoulait","lemma":"revouloir","pos":"VER"} ,
		{"word":"revoulez","word_nosc":"revoulez","lemma":"revouloir","pos":"VER"} ,
		{"word":"revouloir","word_nosc":"revouloir","lemma":"revouloir","pos":"VER"} ,
		{"word":"revoyaient","word_nosc":"revoyaient","lemma":"revoir","pos":"VER"} ,
		{"word":"revoyais","word_nosc":"revoyais","lemma":"revoir","pos":"VER"} ,
		{"word":"revoyait","word_nosc":"revoyait","lemma":"revoir","pos":"VER"} ,
		{"word":"revoyant","word_nosc":"revoyant","lemma":"revoir","pos":"VER"} ,
		{"word":"revoyez","word_nosc":"revoyez","lemma":"revoir","pos":"VER"} ,
		{"word":"revoyiez","word_nosc":"revoyiez","lemma":"revoir","pos":"VER"} ,
		{"word":"revoyions","word_nosc":"revoyions","lemma":"revoir","pos":"VER"} ,
		{"word":"revoyons","word_nosc":"revoyons","lemma":"revoir","pos":"VER"} ,
		{"word":"revu","word_nosc":"revu","lemma":"revoir","pos":"VER"} ,
		{"word":"revue","word_nosc":"revue","lemma":"revoir","pos":"VER"} ,
		{"word":"revues","word_nosc":"revues","lemma":"revoir","pos":"VER"} ,
		{"word":"revus","word_nosc":"revus","lemma":"revoir","pos":"VER"} ,
		{"word":"revécu","word_nosc":"revecu","lemma":"revivre","pos":"VER"} ,
		{"word":"revécue","word_nosc":"revecue","lemma":"revivre","pos":"VER"} ,
		{"word":"revécurent","word_nosc":"revecurent","lemma":"revivre","pos":"VER"} ,
		{"word":"revécut","word_nosc":"revecut","lemma":"revivre","pos":"VER"} ,
		{"word":"revérifier","word_nosc":"reverifier","lemma":"revérifier","pos":"VER"} ,
		{"word":"revêt","word_nosc":"revet","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtaient","word_nosc":"revetaient","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtais","word_nosc":"revetais","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtait","word_nosc":"revetait","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtant","word_nosc":"revetant","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtent","word_nosc":"revetent","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtez","word_nosc":"revetez","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtir","word_nosc":"revetir","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtira","word_nosc":"revetira","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtirait","word_nosc":"revetirait","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtirent","word_nosc":"revetirent","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtiront","word_nosc":"revetiront","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtis","word_nosc":"revetis","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtit","word_nosc":"revetit","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêts","word_nosc":"revets","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtu","word_nosc":"revetu","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtue","word_nosc":"revetue","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtues","word_nosc":"revetues","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtus","word_nosc":"revetus","lemma":"revêtir","pos":"VER"} ,
		{"word":"revêtît","word_nosc":"revetit","lemma":"revêtir","pos":"VER"} ,
		{"word":"revîmes","word_nosc":"revimes","lemma":"revoir","pos":"VER"} ,
		{"word":"revînt","word_nosc":"revint","lemma":"revenir","pos":"VER"} ,
		{"word":"rewrité","word_nosc":"rewrite","lemma":"rewriter","pos":"VER"} ,
		{"word":"reçois","word_nosc":"recois","lemma":"recevoir","pos":"VER"} ,
		{"word":"reçoit","word_nosc":"recoit","lemma":"recevoir","pos":"VER"} ,
		{"word":"reçoive","word_nosc":"recoive","lemma":"recevoir","pos":"VER"} ,
		{"word":"reçoivent","word_nosc":"recoivent","lemma":"recevoir","pos":"VER"} ,
		{"word":"reçoives","word_nosc":"recoives","lemma":"recevoir","pos":"VER"} ,
		{"word":"reçu","word_nosc":"recu","lemma":"recevoir","pos":"VER"} ,
		{"word":"reçue","word_nosc":"recue","lemma":"recevoir","pos":"VER"} ,
		{"word":"reçues","word_nosc":"recues","lemma":"recevoir","pos":"VER"} ,
		{"word":"reçurent","word_nosc":"recurent","lemma":"recevoir","pos":"VER"} ,
		{"word":"reçus","word_nosc":"recus","lemma":"recevoir","pos":"VER"} ,
		{"word":"reçut","word_nosc":"recut","lemma":"recevoir","pos":"VER"} ,
		{"word":"reçûmes","word_nosc":"recumes","lemma":"recevoir","pos":"VER"} ,
		{"word":"reçût","word_nosc":"recut","lemma":"recevoir","pos":"VER"} ,
		{"word":"rhabilla","word_nosc":"rhabilla","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabillaient","word_nosc":"rhabillaient","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabillais","word_nosc":"rhabillais","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabillait","word_nosc":"rhabillait","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabillant","word_nosc":"rhabillant","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabille","word_nosc":"rhabille","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabillent","word_nosc":"rhabillent","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabiller","word_nosc":"rhabiller","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabilles","word_nosc":"rhabilles","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabillez","word_nosc":"rhabillez","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabilliez","word_nosc":"rhabilliez","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabillons","word_nosc":"rhabillons","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabillèrent","word_nosc":"rhabillerent","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabillé","word_nosc":"rhabille","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabillée","word_nosc":"rhabillee","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabillées","word_nosc":"rhabillees","lemma":"rhabiller","pos":"VER"} ,
		{"word":"rhabillés","word_nosc":"rhabilles","lemma":"rhabiller","pos":"VER"} ,
		{"word":"ri","word_nosc":"ri","lemma":"rire","pos":"VER"} ,
		{"word":"riaient","word_nosc":"riaient","lemma":"rire","pos":"VER"} ,
		{"word":"riais","word_nosc":"riais","lemma":"rire","pos":"VER"} ,
		{"word":"riait","word_nosc":"riait","lemma":"rire","pos":"VER"} ,
		{"word":"riant","word_nosc":"riant","lemma":"rire","pos":"VER"} ,
		{"word":"rible","word_nosc":"rible","lemma":"ribler","pos":"VER"} ,
		{"word":"riboter","word_nosc":"riboter","lemma":"riboter","pos":"VER"} ,
		{"word":"riboulait","word_nosc":"riboulait","lemma":"ribouler","pos":"VER"} ,
		{"word":"riboulant","word_nosc":"riboulant","lemma":"ribouler","pos":"VER"} ,
		{"word":"ribouler","word_nosc":"ribouler","lemma":"ribouler","pos":"VER"} ,
		{"word":"ricana","word_nosc":"ricana","lemma":"ricaner","pos":"VER"} ,
		{"word":"ricanai","word_nosc":"ricanai","lemma":"ricaner","pos":"VER"} ,
		{"word":"ricanaient","word_nosc":"ricanaient","lemma":"ricaner","pos":"VER"} ,
		{"word":"ricanais","word_nosc":"ricanais","lemma":"ricaner","pos":"VER"} ,
		{"word":"ricanait","word_nosc":"ricanait","lemma":"ricaner","pos":"VER"} ,
		{"word":"ricanant","word_nosc":"ricanant","lemma":"ricaner","pos":"VER"} ,
		{"word":"ricane","word_nosc":"ricane","lemma":"ricaner","pos":"VER"} ,
		{"word":"ricanent","word_nosc":"ricanent","lemma":"ricaner","pos":"VER"} ,
		{"word":"ricaner","word_nosc":"ricaner","lemma":"ricaner","pos":"VER"} ,
		{"word":"ricaneraient","word_nosc":"ricaneraient","lemma":"ricaner","pos":"VER"} ,
		{"word":"ricaneront","word_nosc":"ricaneront","lemma":"ricaner","pos":"VER"} ,
		{"word":"ricanes","word_nosc":"ricanes","lemma":"ricaner","pos":"VER"} ,
		{"word":"ricanions","word_nosc":"ricanions","lemma":"ricaner","pos":"VER"} ,
		{"word":"ricanèrent","word_nosc":"ricanerent","lemma":"ricaner","pos":"VER"} ,
		{"word":"ricané","word_nosc":"ricane","lemma":"ricaner","pos":"VER"} ,
		{"word":"ricassant","word_nosc":"ricassant","lemma":"ricasser","pos":"VER"} ,
		{"word":"ricasser","word_nosc":"ricasser","lemma":"ricasser","pos":"VER"} ,
		{"word":"ricocha","word_nosc":"ricocha","lemma":"ricocher","pos":"VER"} ,
		{"word":"ricochaient","word_nosc":"ricochaient","lemma":"ricocher","pos":"VER"} ,
		{"word":"ricochait","word_nosc":"ricochait","lemma":"ricocher","pos":"VER"} ,
		{"word":"ricochant","word_nosc":"ricochant","lemma":"ricocher","pos":"VER"} ,
		{"word":"ricoche","word_nosc":"ricoche","lemma":"ricocher","pos":"VER"} ,
		{"word":"ricochent","word_nosc":"ricochent","lemma":"ricocher","pos":"VER"} ,
		{"word":"ricocher","word_nosc":"ricocher","lemma":"ricocher","pos":"VER"} ,
		{"word":"ricoché","word_nosc":"ricoche","lemma":"ricocher","pos":"VER"} ,
		{"word":"rida","word_nosc":"rida","lemma":"rider","pos":"VER"} ,
		{"word":"ridaient","word_nosc":"ridaient","lemma":"rider","pos":"VER"} ,
		{"word":"ridait","word_nosc":"ridait","lemma":"rider","pos":"VER"} ,
		{"word":"ridant","word_nosc":"ridant","lemma":"rider","pos":"VER"} ,
		{"word":"ride","word_nosc":"ride","lemma":"rider","pos":"VER"} ,
		{"word":"rident","word_nosc":"rident","lemma":"rider","pos":"VER"} ,
		{"word":"rider","word_nosc":"rider","lemma":"rider","pos":"VER"} ,
		{"word":"rides","word_nosc":"rides","lemma":"rider","pos":"VER"} ,
		{"word":"ridiculisa","word_nosc":"ridiculisa","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculisaient","word_nosc":"ridiculisaient","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculisais","word_nosc":"ridiculisais","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculisait","word_nosc":"ridiculisait","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculisant","word_nosc":"ridiculisant","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculise","word_nosc":"ridiculise","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculisent","word_nosc":"ridiculisent","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculiser","word_nosc":"ridiculiser","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculisera","word_nosc":"ridiculisera","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculiserai","word_nosc":"ridiculiserai","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculiserais","word_nosc":"ridiculiserais","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculiserait","word_nosc":"ridiculiserait","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculiseras","word_nosc":"ridiculiseras","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculises","word_nosc":"ridiculises","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculisez","word_nosc":"ridiculisez","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculisiez","word_nosc":"ridiculisiez","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculisé","word_nosc":"ridiculise","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculisée","word_nosc":"ridiculisee","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculisées","word_nosc":"ridiculisees","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridiculisés","word_nosc":"ridiculises","lemma":"ridiculiser","pos":"VER"} ,
		{"word":"ridé","word_nosc":"ride","lemma":"rider","pos":"VER"} ,
		{"word":"ridée","word_nosc":"ridee","lemma":"rider","pos":"VER"} ,
		{"word":"ridées","word_nosc":"ridees","lemma":"rider","pos":"VER"} ,
		{"word":"ridés","word_nosc":"rides","lemma":"rider","pos":"VER"} ,
		{"word":"rie","word_nosc":"rie","lemma":"rire","pos":"VER"} ,
		{"word":"rient","word_nosc":"rient","lemma":"rire","pos":"VER"} ,
		{"word":"ries","word_nosc":"ries","lemma":"rire","pos":"VER"} ,
		{"word":"riez","word_nosc":"riez","lemma":"rire","pos":"VER"} ,
		{"word":"riffaude","word_nosc":"riffaude","lemma":"riffauder","pos":"VER"} ,
		{"word":"riffauder","word_nosc":"riffauder","lemma":"riffauder","pos":"VER"} ,
		{"word":"riffaudé","word_nosc":"riffaude","lemma":"riffauder","pos":"VER"} ,
		{"word":"rigidifier","word_nosc":"rigidifier","lemma":"rigidifier","pos":"VER"} ,
		{"word":"rigola","word_nosc":"rigola","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigolaient","word_nosc":"rigolaient","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigolais","word_nosc":"rigolais","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigolait","word_nosc":"rigolait","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigolant","word_nosc":"rigolant","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigole","word_nosc":"rigole","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigolent","word_nosc":"rigolent","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigoler","word_nosc":"rigoler","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigolera","word_nosc":"rigolera","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigolerai","word_nosc":"rigolerai","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigoleraient","word_nosc":"rigoleraient","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigolerais","word_nosc":"rigolerais","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigolerait","word_nosc":"rigolerait","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigoleras","word_nosc":"rigoleras","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigolerez","word_nosc":"rigolerez","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigoleront","word_nosc":"rigoleront","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigoles","word_nosc":"rigoles","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigolez","word_nosc":"rigolez","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigoliez","word_nosc":"rigoliez","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigolions","word_nosc":"rigolions","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigolons","word_nosc":"rigolons","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigolèrent","word_nosc":"rigolerent","lemma":"rigoler","pos":"VER"} ,
		{"word":"rigolé","word_nosc":"rigole","lemma":"rigoler","pos":"VER"} ,
		{"word":"riiez","word_nosc":"riiez","lemma":"rire","pos":"VER"} ,
		{"word":"riions","word_nosc":"riions","lemma":"rire","pos":"VER"} ,
		{"word":"rima","word_nosc":"rima","lemma":"rimer","pos":"VER"} ,
		{"word":"rimaient","word_nosc":"rimaient","lemma":"rimer","pos":"VER"} ,
		{"word":"rimailles","word_nosc":"rimailles","lemma":"rimailler","pos":"VER"} ,
		{"word":"rimait","word_nosc":"rimait","lemma":"rimer","pos":"VER"} ,
		{"word":"rime","word_nosc":"rime","lemma":"rimer","pos":"VER"} ,
		{"word":"riment","word_nosc":"riment","lemma":"rimer","pos":"VER"} ,
		{"word":"rimer","word_nosc":"rimer","lemma":"rimer","pos":"VER"} ,
		{"word":"rimes","word_nosc":"rimes","lemma":"rimer","pos":"VER"} ,
		{"word":"rimez","word_nosc":"rimez","lemma":"rimer","pos":"VER"} ,
		{"word":"rimions","word_nosc":"rimions","lemma":"rimer","pos":"VER"} ,
		{"word":"rimé","word_nosc":"rime","lemma":"rimer","pos":"VER"} ,
		{"word":"rimée","word_nosc":"rimee","lemma":"rimer","pos":"VER"} ,
		{"word":"rimés","word_nosc":"rimes","lemma":"rimer","pos":"VER"} ,
		{"word":"rince","word_nosc":"rince","lemma":"rincer","pos":"VER"} ,
		{"word":"rincent","word_nosc":"rincent","lemma":"rincer","pos":"VER"} ,
		{"word":"rincer","word_nosc":"rincer","lemma":"rincer","pos":"VER"} ,
		{"word":"rincera","word_nosc":"rincera","lemma":"rincer","pos":"VER"} ,
		{"word":"rincerai","word_nosc":"rincerai","lemma":"rincer","pos":"VER"} ,
		{"word":"rinceraient","word_nosc":"rinceraient","lemma":"rincer","pos":"VER"} ,
		{"word":"rinces","word_nosc":"rinces","lemma":"rincer","pos":"VER"} ,
		{"word":"rincez","word_nosc":"rincez","lemma":"rincer","pos":"VER"} ,
		{"word":"rincèrent","word_nosc":"rincerent","lemma":"rincer","pos":"VER"} ,
		{"word":"rincé","word_nosc":"rince","lemma":"rincer","pos":"VER"} ,
		{"word":"rincée","word_nosc":"rincee","lemma":"rincer","pos":"VER"} ,
		{"word":"rincées","word_nosc":"rincees","lemma":"rincer","pos":"VER"} ,
		{"word":"rincés","word_nosc":"rinces","lemma":"rincer","pos":"VER"} ,
		{"word":"rinker","word_nosc":"rinker","lemma":"rinker","pos":"VER"} ,
		{"word":"rinça","word_nosc":"rinca","lemma":"rincer","pos":"VER"} ,
		{"word":"rinçaient","word_nosc":"rincaient","lemma":"rincer","pos":"VER"} ,
		{"word":"rinçais","word_nosc":"rincais","lemma":"rincer","pos":"VER"} ,
		{"word":"rinçait","word_nosc":"rincait","lemma":"rincer","pos":"VER"} ,
		{"word":"rinçant","word_nosc":"rincant","lemma":"rincer","pos":"VER"} ,
		{"word":"rions","word_nosc":"rions","lemma":"rire","pos":"VER"} ,
		{"word":"ripa","word_nosc":"ripa","lemma":"riper","pos":"VER"} ,
		{"word":"ripaillait","word_nosc":"ripaillait","lemma":"ripailler","pos":"VER"} ,
		{"word":"ripaille","word_nosc":"ripaille","lemma":"ripailler","pos":"VER"} ,
		{"word":"ripailler","word_nosc":"ripailler","lemma":"ripailler","pos":"VER"} ,
		{"word":"ripaillons","word_nosc":"ripaillons","lemma":"ripailler","pos":"VER"} ,
		{"word":"ripaillé","word_nosc":"ripaille","lemma":"ripailler","pos":"VER"} ,
		{"word":"ripant","word_nosc":"ripant","lemma":"riper","pos":"VER"} ,
		{"word":"riper","word_nosc":"riper","lemma":"riper","pos":"VER"} ,
		{"word":"ripes","word_nosc":"ripes","lemma":"riper","pos":"VER"} ,
		{"word":"ripoliner","word_nosc":"ripoliner","lemma":"ripoliner","pos":"VER"} ,
		{"word":"ripolines","word_nosc":"ripolines","lemma":"ripoliner","pos":"VER"} ,
		{"word":"ripoliné","word_nosc":"ripoline","lemma":"ripoliner","pos":"VER"} ,
		{"word":"ripolinée","word_nosc":"ripolinee","lemma":"ripoliner","pos":"VER"} ,
		{"word":"ripolinées","word_nosc":"ripolinees","lemma":"ripoliner","pos":"VER"} ,
		{"word":"ripolinés","word_nosc":"ripolines","lemma":"ripoliner","pos":"VER"} ,
		{"word":"riposta","word_nosc":"riposta","lemma":"riposter","pos":"VER"} ,
		{"word":"ripostai","word_nosc":"ripostai","lemma":"riposter","pos":"VER"} ,
		{"word":"ripostaient","word_nosc":"ripostaient","lemma":"riposter","pos":"VER"} ,
		{"word":"ripostais","word_nosc":"ripostais","lemma":"riposter","pos":"VER"} ,
		{"word":"ripostait","word_nosc":"ripostait","lemma":"riposter","pos":"VER"} ,
		{"word":"ripostant","word_nosc":"ripostant","lemma":"riposter","pos":"VER"} ,
		{"word":"riposte","word_nosc":"riposte","lemma":"riposter","pos":"VER"} ,
		{"word":"ripostent","word_nosc":"ripostent","lemma":"riposter","pos":"VER"} ,
		{"word":"riposter","word_nosc":"riposter","lemma":"riposter","pos":"VER"} ,
		{"word":"ripostera","word_nosc":"ripostera","lemma":"riposter","pos":"VER"} ,
		{"word":"riposterai","word_nosc":"riposterai","lemma":"riposter","pos":"VER"} ,
		{"word":"riposterez","word_nosc":"riposterez","lemma":"riposter","pos":"VER"} ,
		{"word":"riposterons","word_nosc":"riposterons","lemma":"riposter","pos":"VER"} ,
		{"word":"riposteront","word_nosc":"riposteront","lemma":"riposter","pos":"VER"} ,
		{"word":"ripostes","word_nosc":"ripostes","lemma":"riposter","pos":"VER"} ,
		{"word":"ripostez","word_nosc":"ripostez","lemma":"riposter","pos":"VER"} ,
		{"word":"ripostions","word_nosc":"ripostions","lemma":"riposter","pos":"VER"} ,
		{"word":"ripostons","word_nosc":"ripostons","lemma":"riposter","pos":"VER"} ,
		{"word":"riposté","word_nosc":"riposte","lemma":"riposter","pos":"VER"} ,
		{"word":"ripé","word_nosc":"ripe","lemma":"riper","pos":"VER"} ,
		{"word":"rira","word_nosc":"rira","lemma":"rire","pos":"VER"} ,
		{"word":"rirai","word_nosc":"rirai","lemma":"rire","pos":"VER"} ,
		{"word":"riraient","word_nosc":"riraient","lemma":"rire","pos":"VER"} ,
		{"word":"rirais","word_nosc":"rirais","lemma":"rire","pos":"VER"} ,
		{"word":"rirait","word_nosc":"rirait","lemma":"rire","pos":"VER"} ,
		{"word":"riras","word_nosc":"riras","lemma":"rire","pos":"VER"} ,
		{"word":"rire","word_nosc":"rire","lemma":"rire","pos":"VER"} ,
		{"word":"rirent","word_nosc":"rirent","lemma":"rire","pos":"VER"} ,
		{"word":"rirez","word_nosc":"rirez","lemma":"rire","pos":"VER"} ,
		{"word":"rirons","word_nosc":"rirons","lemma":"rire","pos":"VER"} ,
		{"word":"riront","word_nosc":"riront","lemma":"rire","pos":"VER"} ,
		{"word":"ris","word_nosc":"ris","lemma":"rire","pos":"VER"} ,
		{"word":"risqua","word_nosc":"risqua","lemma":"risquer","pos":"VER"} ,
		{"word":"risquai","word_nosc":"risquai","lemma":"risquer","pos":"VER"} ,
		{"word":"risquaient","word_nosc":"risquaient","lemma":"risquer","pos":"VER"} ,
		{"word":"risquais","word_nosc":"risquais","lemma":"risquer","pos":"VER"} ,
		{"word":"risquait","word_nosc":"risquait","lemma":"risquer","pos":"VER"} ,
		{"word":"risquant","word_nosc":"risquant","lemma":"risquer","pos":"VER"} ,
		{"word":"risque","word_nosc":"risque","lemma":"risquer","pos":"VER"} ,
		{"word":"risquent","word_nosc":"risquent","lemma":"risquer","pos":"VER"} ,
		{"word":"risquer","word_nosc":"risquer","lemma":"risquer","pos":"VER"} ,
		{"word":"risquera","word_nosc":"risquera","lemma":"risquer","pos":"VER"} ,
		{"word":"risquerai","word_nosc":"risquerai","lemma":"risquer","pos":"VER"} ,
		{"word":"risqueraient","word_nosc":"risqueraient","lemma":"risquer","pos":"VER"} ,
		{"word":"risquerais","word_nosc":"risquerais","lemma":"risquer","pos":"VER"} ,
		{"word":"risquerait","word_nosc":"risquerait","lemma":"risquer","pos":"VER"} ,
		{"word":"risqueras","word_nosc":"risqueras","lemma":"risquer","pos":"VER"} ,
		{"word":"risquerez","word_nosc":"risquerez","lemma":"risquer","pos":"VER"} ,
		{"word":"risqueriez","word_nosc":"risqueriez","lemma":"risquer","pos":"VER"} ,
		{"word":"risquerions","word_nosc":"risquerions","lemma":"risquer","pos":"VER"} ,
		{"word":"risquerons","word_nosc":"risquerons","lemma":"risquer","pos":"VER"} ,
		{"word":"risqueront","word_nosc":"risqueront","lemma":"risquer","pos":"VER"} ,
		{"word":"risques","word_nosc":"risques","lemma":"risquer","pos":"VER"} ,
		{"word":"risquez","word_nosc":"risquez","lemma":"risquer","pos":"VER"} ,
		{"word":"risquiez","word_nosc":"risquiez","lemma":"risquer","pos":"VER"} ,
		{"word":"risquions","word_nosc":"risquions","lemma":"risquer","pos":"VER"} ,
		{"word":"risquons","word_nosc":"risquons","lemma":"risquer","pos":"VER"} ,
		{"word":"risquât","word_nosc":"risquat","lemma":"risquer","pos":"VER"} ,
		{"word":"risqué","word_nosc":"risque","lemma":"risquer","pos":"VER"} ,
		{"word":"risquée","word_nosc":"risquee","lemma":"risquer","pos":"VER"} ,
		{"word":"risquées","word_nosc":"risquees","lemma":"risquer","pos":"VER"} ,
		{"word":"risqués","word_nosc":"risques","lemma":"risquer","pos":"VER"} ,
		{"word":"rissolaient","word_nosc":"rissolaient","lemma":"rissoler","pos":"VER"} ,
		{"word":"rissolait","word_nosc":"rissolait","lemma":"rissoler","pos":"VER"} ,
		{"word":"rissolent","word_nosc":"rissolent","lemma":"rissoler","pos":"VER"} ,
		{"word":"rissoler","word_nosc":"rissoler","lemma":"rissoler","pos":"VER"} ,
		{"word":"rissolé","word_nosc":"rissole","lemma":"rissoler","pos":"VER"} ,
		{"word":"rissolées","word_nosc":"rissolees","lemma":"rissoler","pos":"VER"} ,
		{"word":"ristournait","word_nosc":"ristournait","lemma":"ristourner","pos":"VER"} ,
		{"word":"ristourne","word_nosc":"ristourne","lemma":"ristourner","pos":"VER"} ,
		{"word":"rit","word_nosc":"rit","lemma":"rire","pos":"VER"} ,
		{"word":"ritualisa","word_nosc":"ritualisa","lemma":"ritualiser","pos":"VER"} ,
		{"word":"ritualisé","word_nosc":"ritualise","lemma":"ritualiser","pos":"VER"} ,
		{"word":"ritualisés","word_nosc":"ritualises","lemma":"ritualiser","pos":"VER"} ,
		{"word":"riva","word_nosc":"riva","lemma":"river","pos":"VER"} ,
		{"word":"rivaient","word_nosc":"rivaient","lemma":"river","pos":"VER"} ,
		{"word":"rivais","word_nosc":"rivais","lemma":"river","pos":"VER"} ,
		{"word":"rivait","word_nosc":"rivait","lemma":"river","pos":"VER"} ,
		{"word":"rivalisaient","word_nosc":"rivalisaient","lemma":"rivaliser","pos":"VER"} ,
		{"word":"rivalisait","word_nosc":"rivalisait","lemma":"rivaliser","pos":"VER"} ,
		{"word":"rivalisant","word_nosc":"rivalisant","lemma":"rivaliser","pos":"VER"} ,
		{"word":"rivalise","word_nosc":"rivalise","lemma":"rivaliser","pos":"VER"} ,
		{"word":"rivalisent","word_nosc":"rivalisent","lemma":"rivaliser","pos":"VER"} ,
		{"word":"rivaliser","word_nosc":"rivaliser","lemma":"rivaliser","pos":"VER"} ,
		{"word":"rivalisera","word_nosc":"rivalisera","lemma":"rivaliser","pos":"VER"} ,
		{"word":"rivaliserait","word_nosc":"rivaliserait","lemma":"rivaliser","pos":"VER"} ,
		{"word":"rivalisèrent","word_nosc":"rivaliserent","lemma":"rivaliser","pos":"VER"} ,
		{"word":"rivalisé","word_nosc":"rivalise","lemma":"rivaliser","pos":"VER"} ,
		{"word":"rivant","word_nosc":"rivant","lemma":"river","pos":"VER"} ,
		{"word":"rive","word_nosc":"rive","lemma":"river","pos":"VER"} ,
		{"word":"river","word_nosc":"river","lemma":"river","pos":"VER"} ,
		{"word":"riverait","word_nosc":"riverait","lemma":"river","pos":"VER"} ,
		{"word":"rivette","word_nosc":"rivette","lemma":"riveter","pos":"VER"} ,
		{"word":"riveté","word_nosc":"rivete","lemma":"riveter","pos":"VER"} ,
		{"word":"rivetées","word_nosc":"rivetees","lemma":"riveter","pos":"VER"} ,
		{"word":"rivé","word_nosc":"rive","lemma":"river","pos":"VER"} ,
		{"word":"rivée","word_nosc":"rivee","lemma":"river","pos":"VER"} ,
		{"word":"rivées","word_nosc":"rivees","lemma":"river","pos":"VER"} ,
		{"word":"rivés","word_nosc":"rives","lemma":"river","pos":"VER"} ,
		{"word":"rober","word_nosc":"rober","lemma":"rober","pos":"VER"} ,
		{"word":"robotisé","word_nosc":"robotise","lemma":"robotiser","pos":"VER"} ,
		{"word":"robotisée","word_nosc":"robotisee","lemma":"robotiser","pos":"VER"} ,
		{"word":"robotisées","word_nosc":"robotisees","lemma":"robotiser","pos":"VER"} ,
		{"word":"rocaillé","word_nosc":"rocaille","lemma":"rocailler","pos":"VER"} ,
		{"word":"rochas","word_nosc":"rochas","lemma":"rocher","pos":"VER"} ,
		{"word":"rocher","word_nosc":"rocher","lemma":"rocher","pos":"VER"} ,
		{"word":"roché","word_nosc":"roche","lemma":"rocher","pos":"VER"} ,
		{"word":"rodait","word_nosc":"rodait","lemma":"roder","pos":"VER"} ,
		{"word":"rode","word_nosc":"rode","lemma":"roder","pos":"VER"} ,
		{"word":"roder","word_nosc":"roder","lemma":"roder","pos":"VER"} ,
		{"word":"roderait","word_nosc":"roderait","lemma":"roder","pos":"VER"} ,
		{"word":"rodé","word_nosc":"rode","lemma":"roder","pos":"VER"} ,
		{"word":"rodée","word_nosc":"rodee","lemma":"roder","pos":"VER"} ,
		{"word":"rodées","word_nosc":"rodees","lemma":"roder","pos":"VER"} ,
		{"word":"rodés","word_nosc":"rodes","lemma":"roder","pos":"VER"} ,
		{"word":"rogna","word_nosc":"rogna","lemma":"rogner","pos":"VER"} ,
		{"word":"rognais","word_nosc":"rognais","lemma":"rogner","pos":"VER"} ,
		{"word":"rognait","word_nosc":"rognait","lemma":"rogner","pos":"VER"} ,
		{"word":"rognant","word_nosc":"rognant","lemma":"rogner","pos":"VER"} ,
		{"word":"rogne","word_nosc":"rogne","lemma":"rogner","pos":"VER"} ,
		{"word":"rognent","word_nosc":"rognent","lemma":"rogner","pos":"VER"} ,
		{"word":"rogner","word_nosc":"rogner","lemma":"rogner","pos":"VER"} ,
		{"word":"rognez","word_nosc":"rognez","lemma":"rogner","pos":"VER"} ,
		{"word":"rognonnait","word_nosc":"rognonnait","lemma":"rognonner","pos":"VER"} ,
		{"word":"rogné","word_nosc":"rogne","lemma":"rogner","pos":"VER"} ,
		{"word":"rognée","word_nosc":"rognee","lemma":"rogner","pos":"VER"} ,
		{"word":"rognées","word_nosc":"rognees","lemma":"rogner","pos":"VER"} ,
		{"word":"rognés","word_nosc":"rognes","lemma":"rogner","pos":"VER"} ,
		{"word":"roidi","word_nosc":"roidi","lemma":"roidir","pos":"VER"} ,
		{"word":"roidie","word_nosc":"roidie","lemma":"roidir","pos":"VER"} ,
		{"word":"roidir","word_nosc":"roidir","lemma":"roidir","pos":"VER"} ,
		{"word":"roidissait","word_nosc":"roidissait","lemma":"roidir","pos":"VER"} ,
		{"word":"romance","word_nosc":"romance","lemma":"romancer","pos":"VER"} ,
		{"word":"romancer","word_nosc":"romancer","lemma":"romancer","pos":"VER"} ,
		{"word":"romancé","word_nosc":"romance","lemma":"romancer","pos":"VER"} ,
		{"word":"romancée","word_nosc":"romancee","lemma":"romancer","pos":"VER"} ,
		{"word":"rompaient","word_nosc":"rompaient","lemma":"rompre","pos":"VER"} ,
		{"word":"rompais","word_nosc":"rompais","lemma":"rompre","pos":"VER"} ,
		{"word":"rompait","word_nosc":"rompait","lemma":"rompre","pos":"VER"} ,
		{"word":"rompant","word_nosc":"rompant","lemma":"rompre","pos":"VER"} ,
		{"word":"rompe","word_nosc":"rompe","lemma":"rompre","pos":"VER"} ,
		{"word":"rompent","word_nosc":"rompent","lemma":"rompre","pos":"VER"} ,
		{"word":"rompez","word_nosc":"rompez","lemma":"rompre","pos":"VER"} ,
		{"word":"rompiez","word_nosc":"rompiez","lemma":"rompre","pos":"VER"} ,
		{"word":"rompirent","word_nosc":"rompirent","lemma":"rompre","pos":"VER"} ,
		{"word":"rompis","word_nosc":"rompis","lemma":"rompre","pos":"VER"} ,
		{"word":"rompissent","word_nosc":"rompissent","lemma":"rompre","pos":"VER"} ,
		{"word":"rompit","word_nosc":"rompit","lemma":"rompre","pos":"VER"} ,
		{"word":"rompons","word_nosc":"rompons","lemma":"rompre","pos":"VER"} ,
		{"word":"rompra","word_nosc":"rompra","lemma":"rompre","pos":"VER"} ,
		{"word":"romprai","word_nosc":"romprai","lemma":"rompre","pos":"VER"} ,
		{"word":"rompraient","word_nosc":"rompraient","lemma":"rompre","pos":"VER"} ,
		{"word":"romprais","word_nosc":"romprais","lemma":"rompre","pos":"VER"} ,
		{"word":"romprait","word_nosc":"romprait","lemma":"rompre","pos":"VER"} ,
		{"word":"rompre","word_nosc":"rompre","lemma":"rompre","pos":"VER"} ,
		{"word":"romprez","word_nosc":"romprez","lemma":"rompre","pos":"VER"} ,
		{"word":"romprions","word_nosc":"romprions","lemma":"rompre","pos":"VER"} ,
		{"word":"romprons","word_nosc":"romprons","lemma":"rompre","pos":"VER"} ,
		{"word":"romps","word_nosc":"romps","lemma":"rompre","pos":"VER"} ,
		{"word":"rompt","word_nosc":"rompt","lemma":"rompre","pos":"VER"} ,
		{"word":"rompu","word_nosc":"rompu","lemma":"rompre","pos":"VER"} ,
		{"word":"rompue","word_nosc":"rompue","lemma":"rompre","pos":"VER"} ,
		{"word":"rompues","word_nosc":"rompues","lemma":"rompre","pos":"VER"} ,
		{"word":"rompus","word_nosc":"rompus","lemma":"rompre","pos":"VER"} ,
		{"word":"rompît","word_nosc":"rompit","lemma":"rompre","pos":"VER"} ,
		{"word":"ronchonna","word_nosc":"ronchonna","lemma":"ronchonner","pos":"VER"} ,
		{"word":"ronchonnaient","word_nosc":"ronchonnaient","lemma":"ronchonner","pos":"VER"} ,
		{"word":"ronchonnais","word_nosc":"ronchonnais","lemma":"ronchonner","pos":"VER"} ,
		{"word":"ronchonnait","word_nosc":"ronchonnait","lemma":"ronchonner","pos":"VER"} ,
		{"word":"ronchonnant","word_nosc":"ronchonnant","lemma":"ronchonner","pos":"VER"} ,
		{"word":"ronchonne","word_nosc":"ronchonne","lemma":"ronchonner","pos":"VER"} ,
		{"word":"ronchonnent","word_nosc":"ronchonnent","lemma":"ronchonner","pos":"VER"} ,
		{"word":"ronchonner","word_nosc":"ronchonner","lemma":"ronchonner","pos":"VER"} ,
		{"word":"ronchonnes","word_nosc":"ronchonnes","lemma":"ronchonner","pos":"VER"} ,
		{"word":"ronchonné","word_nosc":"ronchonne","lemma":"ronchonner","pos":"VER"} ,
		{"word":"ronfla","word_nosc":"ronfla","lemma":"ronfler","pos":"VER"} ,
		{"word":"ronflaient","word_nosc":"ronflaient","lemma":"ronfler","pos":"VER"} ,
		{"word":"ronflais","word_nosc":"ronflais","lemma":"ronfler","pos":"VER"} ,
		{"word":"ronflait","word_nosc":"ronflait","lemma":"ronfler","pos":"VER"} ,
		{"word":"ronflant","word_nosc":"ronflant","lemma":"ronfler","pos":"VER"} ,
		{"word":"ronfle","word_nosc":"ronfle","lemma":"ronfler","pos":"VER"} ,
		{"word":"ronflent","word_nosc":"ronflent","lemma":"ronfler","pos":"VER"} ,
		{"word":"ronfler","word_nosc":"ronfler","lemma":"ronfler","pos":"VER"} ,
		{"word":"ronflerais","word_nosc":"ronflerais","lemma":"ronfler","pos":"VER"} ,
		{"word":"ronfleras","word_nosc":"ronfleras","lemma":"ronfler","pos":"VER"} ,
		{"word":"ronfles","word_nosc":"ronfles","lemma":"ronfler","pos":"VER"} ,
		{"word":"ronflez","word_nosc":"ronflez","lemma":"ronfler","pos":"VER"} ,
		{"word":"ronfliez","word_nosc":"ronfliez","lemma":"ronfler","pos":"VER"} ,
		{"word":"ronflotait","word_nosc":"ronflotait","lemma":"ronfloter","pos":"VER"} ,
		{"word":"ronflotant","word_nosc":"ronflotant","lemma":"ronfloter","pos":"VER"} ,
		{"word":"ronflote","word_nosc":"ronflote","lemma":"ronfloter","pos":"VER"} ,
		{"word":"ronflèrent","word_nosc":"ronflerent","lemma":"ronfler","pos":"VER"} ,
		{"word":"ronflé","word_nosc":"ronfle","lemma":"ronfler","pos":"VER"} ,
		{"word":"ronge","word_nosc":"ronge","lemma":"ronger","pos":"VER"} ,
		{"word":"rongea","word_nosc":"rongea","lemma":"ronger","pos":"VER"} ,
		{"word":"rongeai","word_nosc":"rongeai","lemma":"ronger","pos":"VER"} ,
		{"word":"rongeaient","word_nosc":"rongeaient","lemma":"ronger","pos":"VER"} ,
		{"word":"rongeais","word_nosc":"rongeais","lemma":"ronger","pos":"VER"} ,
		{"word":"rongeait","word_nosc":"rongeait","lemma":"ronger","pos":"VER"} ,
		{"word":"rongeant","word_nosc":"rongeant","lemma":"ronger","pos":"VER"} ,
		{"word":"rongent","word_nosc":"rongent","lemma":"ronger","pos":"VER"} ,
		{"word":"ronger","word_nosc":"ronger","lemma":"ronger","pos":"VER"} ,
		{"word":"rongera","word_nosc":"rongera","lemma":"ronger","pos":"VER"} ,
		{"word":"rongerait","word_nosc":"rongerait","lemma":"ronger","pos":"VER"} ,
		{"word":"ronges","word_nosc":"ronges","lemma":"ronger","pos":"VER"} ,
		{"word":"rongez","word_nosc":"rongez","lemma":"ronger","pos":"VER"} ,
		{"word":"rongeât","word_nosc":"rongeat","lemma":"ronger","pos":"VER"} ,
		{"word":"rongèrent","word_nosc":"rongerent","lemma":"ronger","pos":"VER"} ,
		{"word":"rongé","word_nosc":"ronge","lemma":"ronger","pos":"VER"} ,
		{"word":"rongée","word_nosc":"rongee","lemma":"ronger","pos":"VER"} ,
		{"word":"rongées","word_nosc":"rongees","lemma":"ronger","pos":"VER"} ,
		{"word":"rongés","word_nosc":"ronges","lemma":"ronger","pos":"VER"} ,
		{"word":"ronronna","word_nosc":"ronronna","lemma":"ronronner","pos":"VER"} ,
		{"word":"ronronnaient","word_nosc":"ronronnaient","lemma":"ronronner","pos":"VER"} ,
		{"word":"ronronnais","word_nosc":"ronronnais","lemma":"ronronner","pos":"VER"} ,
		{"word":"ronronnait","word_nosc":"ronronnait","lemma":"ronronner","pos":"VER"} ,
		{"word":"ronronnant","word_nosc":"ronronnant","lemma":"ronronner","pos":"VER"} ,
		{"word":"ronronne","word_nosc":"ronronne","lemma":"ronronner","pos":"VER"} ,
		{"word":"ronronnent","word_nosc":"ronronnent","lemma":"ronronner","pos":"VER"} ,
		{"word":"ronronner","word_nosc":"ronronner","lemma":"ronronner","pos":"VER"} ,
		{"word":"ronronnerait","word_nosc":"ronronnerait","lemma":"ronronner","pos":"VER"} ,
		{"word":"ronronnons","word_nosc":"ronronnons","lemma":"ronronner","pos":"VER"} ,
		{"word":"ronronnèrent","word_nosc":"ronronnerent","lemma":"ronronner","pos":"VER"} ,
		{"word":"ronronné","word_nosc":"ronronne","lemma":"ronronner","pos":"VER"} ,
		{"word":"ronéotions","word_nosc":"roneotions","lemma":"ronéoter","pos":"VER"} ,
		{"word":"ronéotyper","word_nosc":"roneotyper","lemma":"ronéotyper","pos":"VER"} ,
		{"word":"ronéotypé","word_nosc":"roneotype","lemma":"ronéotyper","pos":"VER"} ,
		{"word":"ronéotypés","word_nosc":"roneotypes","lemma":"ronéotyper","pos":"VER"} ,
		{"word":"ronéoté","word_nosc":"roneote","lemma":"ronéoter","pos":"VER"} ,
		{"word":"roque","word_nosc":"roque","lemma":"roquer","pos":"VER"} ,
		{"word":"roquer","word_nosc":"roquer","lemma":"roquer","pos":"VER"} ,
		{"word":"rosa","word_nosc":"rosa","lemma":"roser","pos":"VER"} ,
		{"word":"rose","word_nosc":"rose","lemma":"roser","pos":"VER"} ,
		{"word":"roser","word_nosc":"roser","lemma":"roser","pos":"VER"} ,
		{"word":"rosi","word_nosc":"rosi","lemma":"rosir","pos":"VER"} ,
		{"word":"rosie","word_nosc":"rosie","lemma":"rosir","pos":"VER"} ,
		{"word":"rosies","word_nosc":"rosies","lemma":"rosir","pos":"VER"} ,
		{"word":"rosir","word_nosc":"rosir","lemma":"rosir","pos":"VER"} ,
		{"word":"rosis","word_nosc":"rosis","lemma":"rosir","pos":"VER"} ,
		{"word":"rosissaient","word_nosc":"rosissaient","lemma":"rosir","pos":"VER"} ,
		{"word":"rosissait","word_nosc":"rosissait","lemma":"rosir","pos":"VER"} ,
		{"word":"rosissant","word_nosc":"rosissant","lemma":"rosir","pos":"VER"} ,
		{"word":"rosissent","word_nosc":"rosissent","lemma":"rosir","pos":"VER"} ,
		{"word":"rosit","word_nosc":"rosit","lemma":"rosir","pos":"VER"} ,
		{"word":"rossa","word_nosc":"rossa","lemma":"rosser","pos":"VER"} ,
		{"word":"rossaient","word_nosc":"rossaient","lemma":"rosser","pos":"VER"} ,
		{"word":"rossait","word_nosc":"rossait","lemma":"rosser","pos":"VER"} ,
		{"word":"rossant","word_nosc":"rossant","lemma":"rosser","pos":"VER"} ,
		{"word":"rosse","word_nosc":"rosse","lemma":"rosser","pos":"VER"} ,
		{"word":"rossent","word_nosc":"rossent","lemma":"rosser","pos":"VER"} ,
		{"word":"rosser","word_nosc":"rosser","lemma":"rosser","pos":"VER"} ,
		{"word":"rosserai","word_nosc":"rosserai","lemma":"rosser","pos":"VER"} ,
		{"word":"rosses","word_nosc":"rosses","lemma":"rosser","pos":"VER"} ,
		{"word":"rossez","word_nosc":"rossez","lemma":"rosser","pos":"VER"} ,
		{"word":"rossé","word_nosc":"rosse","lemma":"rosser","pos":"VER"} ,
		{"word":"rossée","word_nosc":"rossee","lemma":"rosser","pos":"VER"} ,
		{"word":"rossés","word_nosc":"rosses","lemma":"rosser","pos":"VER"} ,
		{"word":"rosé","word_nosc":"rose","lemma":"roser","pos":"VER"} ,
		{"word":"rosée","word_nosc":"rosee","lemma":"roser","pos":"VER"} ,
		{"word":"rosées","word_nosc":"rosees","lemma":"roser","pos":"VER"} ,
		{"word":"rota","word_nosc":"rota","lemma":"roter","pos":"VER"} ,
		{"word":"rotaient","word_nosc":"rotaient","lemma":"roter","pos":"VER"} ,
		{"word":"rotais","word_nosc":"rotais","lemma":"roter","pos":"VER"} ,
		{"word":"rotait","word_nosc":"rotait","lemma":"roter","pos":"VER"} ,
		{"word":"rotant","word_nosc":"rotant","lemma":"roter","pos":"VER"} ,
		{"word":"rote","word_nosc":"rote","lemma":"roter","pos":"VER"} ,
		{"word":"rotent","word_nosc":"rotent","lemma":"roter","pos":"VER"} ,
		{"word":"roter","word_nosc":"roter","lemma":"roter","pos":"VER"} ,
		{"word":"rotera","word_nosc":"rotera","lemma":"roter","pos":"VER"} ,
		{"word":"rotes","word_nosc":"rotes","lemma":"roter","pos":"VER"} ,
		{"word":"rotez","word_nosc":"rotez","lemma":"roter","pos":"VER"} ,
		{"word":"roté","word_nosc":"rote","lemma":"roter","pos":"VER"} ,
		{"word":"rotées","word_nosc":"rotees","lemma":"roter","pos":"VER"} ,
		{"word":"roua","word_nosc":"roua","lemma":"rouer","pos":"VER"} ,
		{"word":"rouaient","word_nosc":"rouaient","lemma":"rouer","pos":"VER"} ,
		{"word":"rouais","word_nosc":"rouais","lemma":"rouer","pos":"VER"} ,
		{"word":"rouait","word_nosc":"rouait","lemma":"rouer","pos":"VER"} ,
		{"word":"rouant","word_nosc":"rouant","lemma":"rouer","pos":"VER"} ,
		{"word":"roublarder","word_nosc":"roublarder","lemma":"roublarder","pos":"VER"} ,
		{"word":"roucoula","word_nosc":"roucoula","lemma":"roucouler","pos":"VER"} ,
		{"word":"roucoulaient","word_nosc":"roucoulaient","lemma":"roucouler","pos":"VER"} ,
		{"word":"roucoulais","word_nosc":"roucoulais","lemma":"roucouler","pos":"VER"} ,
		{"word":"roucoulait","word_nosc":"roucoulait","lemma":"roucouler","pos":"VER"} ,
		{"word":"roucoulant","word_nosc":"roucoulant","lemma":"roucouler","pos":"VER"} ,
		{"word":"roucoule","word_nosc":"roucoule","lemma":"roucouler","pos":"VER"} ,
		{"word":"roucoulent","word_nosc":"roucoulent","lemma":"roucouler","pos":"VER"} ,
		{"word":"roucouler","word_nosc":"roucouler","lemma":"roucouler","pos":"VER"} ,
		{"word":"roucoulerez","word_nosc":"roucoulerez","lemma":"roucouler","pos":"VER"} ,
		{"word":"roucoulé","word_nosc":"roucoule","lemma":"roucouler","pos":"VER"} ,
		{"word":"roucoulés","word_nosc":"roucoules","lemma":"roucouler","pos":"VER"} ,
		{"word":"roue","word_nosc":"roue","lemma":"rouer","pos":"VER"} ,
		{"word":"rouent","word_nosc":"rouent","lemma":"rouer","pos":"VER"} ,
		{"word":"rouer","word_nosc":"rouer","lemma":"rouer","pos":"VER"} ,
		{"word":"rouerai","word_nosc":"rouerai","lemma":"rouer","pos":"VER"} ,
		{"word":"roueront","word_nosc":"roueront","lemma":"rouer","pos":"VER"} ,
		{"word":"roues","word_nosc":"roues","lemma":"rouer","pos":"VER"} ,
		{"word":"rougeoie","word_nosc":"rougeoie","lemma":"rougeoyer","pos":"VER"} ,
		{"word":"rougeoient","word_nosc":"rougeoient","lemma":"rougeoyer","pos":"VER"} ,
		{"word":"rougeoya","word_nosc":"rougeoya","lemma":"rougeoyer","pos":"VER"} ,
		{"word":"rougeoyaient","word_nosc":"rougeoyaient","lemma":"rougeoyer","pos":"VER"} ,
		{"word":"rougeoyait","word_nosc":"rougeoyait","lemma":"rougeoyer","pos":"VER"} ,
		{"word":"rougeoyant","word_nosc":"rougeoyant","lemma":"rougeoyer","pos":"VER"} ,
		{"word":"rougeoyer","word_nosc":"rougeoyer","lemma":"rougeoyer","pos":"VER"} ,
		{"word":"rougeoyé","word_nosc":"rougeoye","lemma":"rougeoyer","pos":"VER"} ,
		{"word":"rougi","word_nosc":"rougi","lemma":"rougir","pos":"VER"} ,
		{"word":"rougie","word_nosc":"rougie","lemma":"rougir","pos":"VER"} ,
		{"word":"rougies","word_nosc":"rougies","lemma":"rougir","pos":"VER"} ,
		{"word":"rougir","word_nosc":"rougir","lemma":"rougir","pos":"VER"} ,
		{"word":"rougira","word_nosc":"rougira","lemma":"rougir","pos":"VER"} ,
		{"word":"rougiraient","word_nosc":"rougiraient","lemma":"rougir","pos":"VER"} ,
		{"word":"rougirais","word_nosc":"rougirais","lemma":"rougir","pos":"VER"} ,
		{"word":"rougirait","word_nosc":"rougirait","lemma":"rougir","pos":"VER"} ,
		{"word":"rougirent","word_nosc":"rougirent","lemma":"rougir","pos":"VER"} ,
		{"word":"rougirez","word_nosc":"rougirez","lemma":"rougir","pos":"VER"} ,
		{"word":"rougis","word_nosc":"rougis","lemma":"rougir","pos":"VER"} ,
		{"word":"rougissaient","word_nosc":"rougissaient","lemma":"rougir","pos":"VER"} ,
		{"word":"rougissais","word_nosc":"rougissais","lemma":"rougir","pos":"VER"} ,
		{"word":"rougissait","word_nosc":"rougissait","lemma":"rougir","pos":"VER"} ,
		{"word":"rougissant","word_nosc":"rougissant","lemma":"rougir","pos":"VER"} ,
		{"word":"rougisse","word_nosc":"rougisse","lemma":"rougir","pos":"VER"} ,
		{"word":"rougissent","word_nosc":"rougissent","lemma":"rougir","pos":"VER"} ,
		{"word":"rougissez","word_nosc":"rougissez","lemma":"rougir","pos":"VER"} ,
		{"word":"rougit","word_nosc":"rougit","lemma":"rougir","pos":"VER"} ,
		{"word":"rougît","word_nosc":"rougit","lemma":"rougir","pos":"VER"} ,
		{"word":"roui","word_nosc":"roui","lemma":"rouir","pos":"VER"} ,
		{"word":"rouies","word_nosc":"rouies","lemma":"rouir","pos":"VER"} ,
		{"word":"rouillaient","word_nosc":"rouillaient","lemma":"rouiller","pos":"VER"} ,
		{"word":"rouillait","word_nosc":"rouillait","lemma":"rouiller","pos":"VER"} ,
		{"word":"rouillant","word_nosc":"rouillant","lemma":"rouiller","pos":"VER"} ,
		{"word":"rouille","word_nosc":"rouille","lemma":"rouiller","pos":"VER"} ,
		{"word":"rouillent","word_nosc":"rouillent","lemma":"rouiller","pos":"VER"} ,
		{"word":"rouiller","word_nosc":"rouiller","lemma":"rouiller","pos":"VER"} ,
		{"word":"rouillerait","word_nosc":"rouillerait","lemma":"rouiller","pos":"VER"} ,
		{"word":"rouilleront","word_nosc":"rouilleront","lemma":"rouiller","pos":"VER"} ,
		{"word":"rouilles","word_nosc":"rouilles","lemma":"rouiller","pos":"VER"} ,
		{"word":"rouillé","word_nosc":"rouille","lemma":"rouiller","pos":"VER"} ,
		{"word":"rouillée","word_nosc":"rouillee","lemma":"rouiller","pos":"VER"} ,
		{"word":"rouillées","word_nosc":"rouillees","lemma":"rouiller","pos":"VER"} ,
		{"word":"rouillés","word_nosc":"rouilles","lemma":"rouiller","pos":"VER"} ,
		{"word":"rouissant","word_nosc":"rouissant","lemma":"rouir","pos":"VER"} ,
		{"word":"rouissent","word_nosc":"rouissent","lemma":"rouir","pos":"VER"} ,
		{"word":"roula","word_nosc":"roula","lemma":"rouler","pos":"VER"} ,
		{"word":"roulai","word_nosc":"roulai","lemma":"rouler","pos":"VER"} ,
		{"word":"roulaient","word_nosc":"roulaient","lemma":"rouler","pos":"VER"} ,
		{"word":"roulais","word_nosc":"roulais","lemma":"rouler","pos":"VER"} ,
		{"word":"roulait","word_nosc":"roulait","lemma":"rouler","pos":"VER"} ,
		{"word":"roulant","word_nosc":"roulant","lemma":"rouler","pos":"VER"} ,
		{"word":"roule","word_nosc":"roule","lemma":"rouler","pos":"VER"} ,
		{"word":"roulent","word_nosc":"roulent","lemma":"rouler","pos":"VER"} ,
		{"word":"rouler","word_nosc":"rouler","lemma":"rouler","pos":"VER"} ,
		{"word":"roulera","word_nosc":"roulera","lemma":"rouler","pos":"VER"} ,
		{"word":"roulerai","word_nosc":"roulerai","lemma":"rouler","pos":"VER"} ,
		{"word":"rouleraient","word_nosc":"rouleraient","lemma":"rouler","pos":"VER"} ,
		{"word":"roulerais","word_nosc":"roulerais","lemma":"rouler","pos":"VER"} ,
		{"word":"roulerait","word_nosc":"roulerait","lemma":"rouler","pos":"VER"} ,
		{"word":"rouleras","word_nosc":"rouleras","lemma":"rouler","pos":"VER"} ,
		{"word":"roulerez","word_nosc":"roulerez","lemma":"rouler","pos":"VER"} ,
		{"word":"rouleriez","word_nosc":"rouleriez","lemma":"rouler","pos":"VER"} ,
		{"word":"roulerons","word_nosc":"roulerons","lemma":"rouler","pos":"VER"} ,
		{"word":"rouleront","word_nosc":"rouleront","lemma":"rouler","pos":"VER"} ,
		{"word":"roules","word_nosc":"roules","lemma":"rouler","pos":"VER"} ,
		{"word":"roulez","word_nosc":"roulez","lemma":"rouler","pos":"VER"} ,
		{"word":"rouliez","word_nosc":"rouliez","lemma":"rouler","pos":"VER"} ,
		{"word":"roulions","word_nosc":"roulions","lemma":"rouler","pos":"VER"} ,
		{"word":"roulons","word_nosc":"roulons","lemma":"rouler","pos":"VER"} ,
		{"word":"roulottait","word_nosc":"roulottait","lemma":"roulotter","pos":"VER"} ,
		{"word":"roulottent","word_nosc":"roulottent","lemma":"roulotter","pos":"VER"} ,
		{"word":"roulâmes","word_nosc":"roulames","lemma":"rouler","pos":"VER"} ,
		{"word":"roulât","word_nosc":"roulat","lemma":"rouler","pos":"VER"} ,
		{"word":"roulèrent","word_nosc":"roulerent","lemma":"rouler","pos":"VER"} ,
		{"word":"roulé","word_nosc":"roule","lemma":"rouler","pos":"VER"} ,
		{"word":"roulée","word_nosc":"roulee","lemma":"rouler","pos":"VER"} ,
		{"word":"roulées","word_nosc":"roulees","lemma":"rouler","pos":"VER"} ,
		{"word":"roulés","word_nosc":"roules","lemma":"rouler","pos":"VER"} ,
		{"word":"roupillaient","word_nosc":"roupillaient","lemma":"roupiller","pos":"VER"} ,
		{"word":"roupillais","word_nosc":"roupillais","lemma":"roupiller","pos":"VER"} ,
		{"word":"roupillait","word_nosc":"roupillait","lemma":"roupiller","pos":"VER"} ,
		{"word":"roupille","word_nosc":"roupille","lemma":"roupiller","pos":"VER"} ,
		{"word":"roupillent","word_nosc":"roupillent","lemma":"roupiller","pos":"VER"} ,
		{"word":"roupiller","word_nosc":"roupiller","lemma":"roupiller","pos":"VER"} ,
		{"word":"roupillerai","word_nosc":"roupillerai","lemma":"roupiller","pos":"VER"} ,
		{"word":"roupillerais","word_nosc":"roupillerais","lemma":"roupiller","pos":"VER"} ,
		{"word":"roupillerait","word_nosc":"roupillerait","lemma":"roupiller","pos":"VER"} ,
		{"word":"roupilles","word_nosc":"roupilles","lemma":"roupiller","pos":"VER"} ,
		{"word":"roupillez","word_nosc":"roupillez","lemma":"roupiller","pos":"VER"} ,
		{"word":"roupillé","word_nosc":"roupille","lemma":"roupiller","pos":"VER"} ,
		{"word":"rouscaillait","word_nosc":"rouscaillait","lemma":"rouscailler","pos":"VER"} ,
		{"word":"rouscaillant","word_nosc":"rouscaillant","lemma":"rouscailler","pos":"VER"} ,
		{"word":"rouscaille","word_nosc":"rouscaille","lemma":"rouscailler","pos":"VER"} ,
		{"word":"rouscaillent","word_nosc":"rouscaillent","lemma":"rouscailler","pos":"VER"} ,
		{"word":"rouscailler","word_nosc":"rouscailler","lemma":"rouscailler","pos":"VER"} ,
		{"word":"rouspète","word_nosc":"rouspete","lemma":"rouspéter","pos":"VER"} ,
		{"word":"rouspètent","word_nosc":"rouspetent","lemma":"rouspéter","pos":"VER"} ,
		{"word":"rouspètes","word_nosc":"rouspetes","lemma":"rouspéter","pos":"VER"} ,
		{"word":"rouspéta","word_nosc":"rouspeta","lemma":"rouspéter","pos":"VER"} ,
		{"word":"rouspétaient","word_nosc":"rouspetaient","lemma":"rouspéter","pos":"VER"} ,
		{"word":"rouspétais","word_nosc":"rouspetais","lemma":"rouspéter","pos":"VER"} ,
		{"word":"rouspétait","word_nosc":"rouspetait","lemma":"rouspéter","pos":"VER"} ,
		{"word":"rouspétant","word_nosc":"rouspetant","lemma":"rouspéter","pos":"VER"} ,
		{"word":"rouspéter","word_nosc":"rouspeter","lemma":"rouspéter","pos":"VER"} ,
		{"word":"rouspétera","word_nosc":"rouspetera","lemma":"rouspéter","pos":"VER"} ,
		{"word":"rouspétez","word_nosc":"rouspetez","lemma":"rouspéter","pos":"VER"} ,
		{"word":"rousselée","word_nosc":"rousselee","lemma":"rousseler","pos":"VER"} ,
		{"word":"roussi","word_nosc":"roussi","lemma":"roussir","pos":"VER"} ,
		{"word":"roussie","word_nosc":"roussie","lemma":"roussir","pos":"VER"} ,
		{"word":"roussies","word_nosc":"roussies","lemma":"roussir","pos":"VER"} ,
		{"word":"roussir","word_nosc":"roussir","lemma":"roussir","pos":"VER"} ,
		{"word":"roussis","word_nosc":"roussis","lemma":"roussir","pos":"VER"} ,
		{"word":"roussissaient","word_nosc":"roussissaient","lemma":"roussir","pos":"VER"} ,
		{"word":"roussissait","word_nosc":"roussissait","lemma":"roussir","pos":"VER"} ,
		{"word":"roussissant","word_nosc":"roussissant","lemma":"roussir","pos":"VER"} ,
		{"word":"roussit","word_nosc":"roussit","lemma":"roussir","pos":"VER"} ,
		{"word":"roussé","word_nosc":"rousse","lemma":"rousser","pos":"VER"} ,
		{"word":"rousti","word_nosc":"rousti","lemma":"roustir","pos":"VER"} ,
		{"word":"rousties","word_nosc":"rousties","lemma":"roustir","pos":"VER"} ,
		{"word":"roustis","word_nosc":"roustis","lemma":"roustir","pos":"VER"} ,
		{"word":"router","word_nosc":"router","lemma":"router","pos":"VER"} ,
		{"word":"routé","word_nosc":"route","lemma":"router","pos":"VER"} ,
		{"word":"rouvert","word_nosc":"rouvert","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouverte","word_nosc":"rouverte","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvertes","word_nosc":"rouvertes","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouverts","word_nosc":"rouverts","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvraient","word_nosc":"rouvraient","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvrais","word_nosc":"rouvrais","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvrait","word_nosc":"rouvrait","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvrant","word_nosc":"rouvrant","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvre","word_nosc":"rouvre","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvrent","word_nosc":"rouvrent","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvres","word_nosc":"rouvres","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvrez","word_nosc":"rouvrez","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvriez","word_nosc":"rouvriez","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvrir","word_nosc":"rouvrir","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvrira","word_nosc":"rouvrira","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvrirai","word_nosc":"rouvrirai","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvriraient","word_nosc":"rouvriraient","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvrirais","word_nosc":"rouvrirais","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvrirait","word_nosc":"rouvrirait","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvrirent","word_nosc":"rouvrirent","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvrirez","word_nosc":"rouvrirez","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvriront","word_nosc":"rouvriront","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvris","word_nosc":"rouvris","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvrit","word_nosc":"rouvrit","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvrons","word_nosc":"rouvrons","lemma":"rouvrir","pos":"VER"} ,
		{"word":"rouvrît","word_nosc":"rouvrit","lemma":"rouvrir","pos":"VER"} ,
		{"word":"roué","word_nosc":"roue","lemma":"rouer","pos":"VER"} ,
		{"word":"rouée","word_nosc":"rouee","lemma":"rouer","pos":"VER"} ,
		{"word":"roués","word_nosc":"roues","lemma":"rouer","pos":"VER"} ,
		{"word":"rua","word_nosc":"rua","lemma":"ruer","pos":"VER"} ,
		{"word":"ruai","word_nosc":"ruai","lemma":"ruer","pos":"VER"} ,
		{"word":"ruaient","word_nosc":"ruaient","lemma":"ruer","pos":"VER"} ,
		{"word":"ruais","word_nosc":"ruais","lemma":"ruer","pos":"VER"} ,
		{"word":"ruait","word_nosc":"ruait","lemma":"ruer","pos":"VER"} ,
		{"word":"ruant","word_nosc":"ruant","lemma":"ruer","pos":"VER"} ,
		{"word":"ruché","word_nosc":"ruche","lemma":"rucher","pos":"VER"} ,
		{"word":"rudoie","word_nosc":"rudoie","lemma":"rudoyer","pos":"VER"} ,
		{"word":"rudoient","word_nosc":"rudoient","lemma":"rudoyer","pos":"VER"} ,
		{"word":"rudoies","word_nosc":"rudoies","lemma":"rudoyer","pos":"VER"} ,
		{"word":"rudoya","word_nosc":"rudoya","lemma":"rudoyer","pos":"VER"} ,
		{"word":"rudoyais","word_nosc":"rudoyais","lemma":"rudoyer","pos":"VER"} ,
		{"word":"rudoyait","word_nosc":"rudoyait","lemma":"rudoyer","pos":"VER"} ,
		{"word":"rudoyant","word_nosc":"rudoyant","lemma":"rudoyer","pos":"VER"} ,
		{"word":"rudoyer","word_nosc":"rudoyer","lemma":"rudoyer","pos":"VER"} ,
		{"word":"rudoyèrent","word_nosc":"rudoyerent","lemma":"rudoyer","pos":"VER"} ,
		{"word":"rudoyé","word_nosc":"rudoye","lemma":"rudoyer","pos":"VER"} ,
		{"word":"rudoyés","word_nosc":"rudoyes","lemma":"rudoyer","pos":"VER"} ,
		{"word":"rue","word_nosc":"rue","lemma":"ruer","pos":"VER"} ,
		{"word":"ruent","word_nosc":"ruent","lemma":"ruer","pos":"VER"} ,
		{"word":"ruer","word_nosc":"ruer","lemma":"ruer","pos":"VER"} ,
		{"word":"rueront","word_nosc":"rueront","lemma":"ruer","pos":"VER"} ,
		{"word":"rues","word_nosc":"rues","lemma":"ruer","pos":"VER"} ,
		{"word":"rugi","word_nosc":"rugi","lemma":"rugir","pos":"VER"} ,
		{"word":"rugir","word_nosc":"rugir","lemma":"rugir","pos":"VER"} ,
		{"word":"rugiraient","word_nosc":"rugiraient","lemma":"rugir","pos":"VER"} ,
		{"word":"rugirent","word_nosc":"rugirent","lemma":"rugir","pos":"VER"} ,
		{"word":"rugiront","word_nosc":"rugiront","lemma":"rugir","pos":"VER"} ,
		{"word":"rugis","word_nosc":"rugis","lemma":"rugir","pos":"VER"} ,
		{"word":"rugissaient","word_nosc":"rugissaient","lemma":"rugir","pos":"VER"} ,
		{"word":"rugissait","word_nosc":"rugissait","lemma":"rugir","pos":"VER"} ,
		{"word":"rugissant","word_nosc":"rugissant","lemma":"rugir","pos":"VER"} ,
		{"word":"rugisse","word_nosc":"rugisse","lemma":"rugir","pos":"VER"} ,
		{"word":"rugissent","word_nosc":"rugissent","lemma":"rugir","pos":"VER"} ,
		{"word":"rugissez","word_nosc":"rugissez","lemma":"rugir","pos":"VER"} ,
		{"word":"rugit","word_nosc":"rugit","lemma":"rugir","pos":"VER"} ,
		{"word":"ruina","word_nosc":"ruina","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinaient","word_nosc":"ruinaient","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinais","word_nosc":"ruinais","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinait","word_nosc":"ruinait","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinant","word_nosc":"ruinant","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruine","word_nosc":"ruine","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinent","word_nosc":"ruinent","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruiner","word_nosc":"ruiner","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinera","word_nosc":"ruinera","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinerai","word_nosc":"ruinerai","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinerais","word_nosc":"ruinerais","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinerait","word_nosc":"ruinerait","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruineras","word_nosc":"ruineras","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinerez","word_nosc":"ruinerez","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinerons","word_nosc":"ruinerons","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruineront","word_nosc":"ruineront","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruines","word_nosc":"ruines","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinez","word_nosc":"ruinez","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruiniez","word_nosc":"ruiniez","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinât","word_nosc":"ruinat","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruiné","word_nosc":"ruine","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinée","word_nosc":"ruinee","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinées","word_nosc":"ruinees","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruinés","word_nosc":"ruines","lemma":"ruiner","pos":"VER"} ,
		{"word":"ruissela","word_nosc":"ruissela","lemma":"ruisseler","pos":"VER"} ,
		{"word":"ruisselaient","word_nosc":"ruisselaient","lemma":"ruisseler","pos":"VER"} ,
		{"word":"ruisselait","word_nosc":"ruisselait","lemma":"ruisseler","pos":"VER"} ,
		{"word":"ruisselant","word_nosc":"ruisselant","lemma":"ruisseler","pos":"VER"} ,
		{"word":"ruisseler","word_nosc":"ruisseler","lemma":"ruisseler","pos":"VER"} ,
		{"word":"ruisselle","word_nosc":"ruisselle","lemma":"ruisseler","pos":"VER"} ,
		{"word":"ruissellent","word_nosc":"ruissellent","lemma":"ruisseler","pos":"VER"} ,
		{"word":"ruissellerait","word_nosc":"ruissellerait","lemma":"ruisseler","pos":"VER"} ,
		{"word":"ruisselât","word_nosc":"ruisselat","lemma":"ruisseler","pos":"VER"} ,
		{"word":"ruisselèrent","word_nosc":"ruisselerent","lemma":"ruisseler","pos":"VER"} ,
		{"word":"ruisselé","word_nosc":"ruissele","lemma":"ruisseler","pos":"VER"} ,
		{"word":"rumina","word_nosc":"rumina","lemma":"ruminer","pos":"VER"} ,
		{"word":"ruminaient","word_nosc":"ruminaient","lemma":"ruminer","pos":"VER"} ,
		{"word":"ruminais","word_nosc":"ruminais","lemma":"ruminer","pos":"VER"} ,
		{"word":"ruminait","word_nosc":"ruminait","lemma":"ruminer","pos":"VER"} ,
		{"word":"ruminant","word_nosc":"ruminant","lemma":"ruminer","pos":"VER"} ,
		{"word":"rumine","word_nosc":"rumine","lemma":"ruminer","pos":"VER"} ,
		{"word":"ruminent","word_nosc":"ruminent","lemma":"ruminer","pos":"VER"} ,
		{"word":"ruminer","word_nosc":"ruminer","lemma":"ruminer","pos":"VER"} ,
		{"word":"ruminerez","word_nosc":"ruminerez","lemma":"ruminer","pos":"VER"} ,
		{"word":"rumineront","word_nosc":"rumineront","lemma":"ruminer","pos":"VER"} ,
		{"word":"ruminé","word_nosc":"rumine","lemma":"ruminer","pos":"VER"} ,
		{"word":"ruminée","word_nosc":"ruminee","lemma":"ruminer","pos":"VER"} ,
		{"word":"ruminées","word_nosc":"ruminees","lemma":"ruminer","pos":"VER"} ,
		{"word":"ruminés","word_nosc":"rumines","lemma":"ruminer","pos":"VER"} ,
		{"word":"ruons","word_nosc":"ruons","lemma":"ruer","pos":"VER"} ,
		{"word":"rupinasse","word_nosc":"rupinasse","lemma":"rupiner","pos":"VER"} ,
		{"word":"rupiniez","word_nosc":"rupiniez","lemma":"rupiner","pos":"VER"} ,
		{"word":"rusa","word_nosc":"rusa","lemma":"ruser","pos":"VER"} ,
		{"word":"rusais","word_nosc":"rusais","lemma":"ruser","pos":"VER"} ,
		{"word":"rusait","word_nosc":"rusait","lemma":"ruser","pos":"VER"} ,
		{"word":"rusant","word_nosc":"rusant","lemma":"ruser","pos":"VER"} ,
		{"word":"ruse","word_nosc":"ruse","lemma":"ruser","pos":"VER"} ,
		{"word":"rusent","word_nosc":"rusent","lemma":"ruser","pos":"VER"} ,
		{"word":"ruser","word_nosc":"ruser","lemma":"ruser","pos":"VER"} ,
		{"word":"rusera","word_nosc":"rusera","lemma":"ruser","pos":"VER"} ,
		{"word":"ruserai","word_nosc":"ruserai","lemma":"ruser","pos":"VER"} ,
		{"word":"ruserais","word_nosc":"ruserais","lemma":"ruser","pos":"VER"} ,
		{"word":"ruses","word_nosc":"ruses","lemma":"ruser","pos":"VER"} ,
		{"word":"russifiant","word_nosc":"russifiant","lemma":"russifier","pos":"VER"} ,
		{"word":"russifié","word_nosc":"russifie","lemma":"russifier","pos":"VER"} ,
		{"word":"russifiée","word_nosc":"russifiee","lemma":"russifier","pos":"VER"} ,
		{"word":"rustique","word_nosc":"rustique","lemma":"rustiquer","pos":"VER"} ,
		{"word":"rusâmes","word_nosc":"rusames","lemma":"ruser","pos":"VER"} ,
		{"word":"rusé","word_nosc":"ruse","lemma":"ruser","pos":"VER"} ,
		{"word":"rusée","word_nosc":"rusee","lemma":"ruser","pos":"VER"} ,
		{"word":"rusées","word_nosc":"rusees","lemma":"ruser","pos":"VER"} ,
		{"word":"rusés","word_nosc":"ruses","lemma":"ruser","pos":"VER"} ,
		{"word":"rutilaient","word_nosc":"rutilaient","lemma":"rutiler","pos":"VER"} ,
		{"word":"rutilait","word_nosc":"rutilait","lemma":"rutiler","pos":"VER"} ,
		{"word":"rutilant","word_nosc":"rutilant","lemma":"rutiler","pos":"VER"} ,
		{"word":"rutile","word_nosc":"rutile","lemma":"rutiler","pos":"VER"} ,
		{"word":"rutilent","word_nosc":"rutilent","lemma":"rutiler","pos":"VER"} ,
		{"word":"rutiler","word_nosc":"rutiler","lemma":"rutiler","pos":"VER"} ,
		{"word":"ruâmes","word_nosc":"ruames","lemma":"ruer","pos":"VER"} ,
		{"word":"ruât","word_nosc":"ruat","lemma":"ruer","pos":"VER"} ,
		{"word":"ruèrent","word_nosc":"ruerent","lemma":"ruer","pos":"VER"} ,
		{"word":"rué","word_nosc":"rue","lemma":"ruer","pos":"VER"} ,
		{"word":"ruée","word_nosc":"ruee","lemma":"ruer","pos":"VER"} ,
		{"word":"ruées","word_nosc":"ruees","lemma":"ruer","pos":"VER"} ,
		{"word":"rués","word_nosc":"rues","lemma":"ruer","pos":"VER"} ,
		{"word":"rythmaient","word_nosc":"rythmaient","lemma":"rythmer","pos":"VER"} ,
		{"word":"rythmait","word_nosc":"rythmait","lemma":"rythmer","pos":"VER"} ,
		{"word":"rythmant","word_nosc":"rythmant","lemma":"rythmer","pos":"VER"} ,
		{"word":"rythme","word_nosc":"rythme","lemma":"rythmer","pos":"VER"} ,
		{"word":"rythment","word_nosc":"rythment","lemma":"rythmer","pos":"VER"} ,
		{"word":"rythmer","word_nosc":"rythmer","lemma":"rythmer","pos":"VER"} ,
		{"word":"rythmera","word_nosc":"rythmera","lemma":"rythmer","pos":"VER"} ,
		{"word":"rythmé","word_nosc":"rythme","lemma":"rythmer","pos":"VER"} ,
		{"word":"rythmée","word_nosc":"rythmee","lemma":"rythmer","pos":"VER"} ,
		{"word":"rythmées","word_nosc":"rythmees","lemma":"rythmer","pos":"VER"} ,
		{"word":"rythmés","word_nosc":"rythmes","lemma":"rythmer","pos":"VER"} ,
		{"word":"râblé","word_nosc":"rable","lemma":"râbler","pos":"VER"} ,
		{"word":"râblés","word_nosc":"rables","lemma":"râbler","pos":"VER"} ,
		{"word":"râla","word_nosc":"rala","lemma":"râler","pos":"VER"} ,
		{"word":"râlai","word_nosc":"ralai","lemma":"râler","pos":"VER"} ,
		{"word":"râlaient","word_nosc":"ralaient","lemma":"râler","pos":"VER"} ,
		{"word":"râlais","word_nosc":"ralais","lemma":"râler","pos":"VER"} ,
		{"word":"râlait","word_nosc":"ralait","lemma":"râler","pos":"VER"} ,
		{"word":"râlant","word_nosc":"ralant","lemma":"râler","pos":"VER"} ,
		{"word":"râle","word_nosc":"rale","lemma":"râler","pos":"VER"} ,
		{"word":"râlent","word_nosc":"ralent","lemma":"râler","pos":"VER"} ,
		{"word":"râler","word_nosc":"raler","lemma":"râler","pos":"VER"} ,
		{"word":"râlera","word_nosc":"ralera","lemma":"râler","pos":"VER"} ,
		{"word":"râlerai","word_nosc":"ralerai","lemma":"râler","pos":"VER"} ,
		{"word":"râlerait","word_nosc":"ralerait","lemma":"râler","pos":"VER"} ,
		{"word":"râles","word_nosc":"rales","lemma":"râler","pos":"VER"} ,
		{"word":"râlez","word_nosc":"ralez","lemma":"râler","pos":"VER"} ,
		{"word":"râlé","word_nosc":"rale","lemma":"râler","pos":"VER"} ,
		{"word":"râpa","word_nosc":"rapa","lemma":"râper","pos":"VER"} ,
		{"word":"râpaient","word_nosc":"rapaient","lemma":"râper","pos":"VER"} ,
		{"word":"râpait","word_nosc":"rapait","lemma":"râper","pos":"VER"} ,
		{"word":"râpant","word_nosc":"rapant","lemma":"râper","pos":"VER"} ,
		{"word":"râpe","word_nosc":"rape","lemma":"râper","pos":"VER"} ,
		{"word":"râpent","word_nosc":"rapent","lemma":"râper","pos":"VER"} ,
		{"word":"râper","word_nosc":"raper","lemma":"râper","pos":"VER"} ,
		{"word":"râperait","word_nosc":"raperait","lemma":"râper","pos":"VER"} ,
		{"word":"râperas","word_nosc":"raperas","lemma":"râper","pos":"VER"} ,
		{"word":"râpé","word_nosc":"rape","lemma":"râper","pos":"VER"} ,
		{"word":"râpée","word_nosc":"rapee","lemma":"râper","pos":"VER"} ,
		{"word":"râpées","word_nosc":"rapees","lemma":"râper","pos":"VER"} ,
		{"word":"râpés","word_nosc":"rapes","lemma":"râper","pos":"VER"} ,
		{"word":"râtelait","word_nosc":"ratelait","lemma":"râteler","pos":"VER"} ,
		{"word":"râteler","word_nosc":"rateler","lemma":"râteler","pos":"VER"} ,
		{"word":"râtelées","word_nosc":"ratelees","lemma":"râteler","pos":"VER"} ,
		{"word":"règle","word_nosc":"regle","lemma":"régler","pos":"VER"} ,
		{"word":"règlent","word_nosc":"reglent","lemma":"régler","pos":"VER"} ,
		{"word":"règles","word_nosc":"regles","lemma":"régler","pos":"VER"} ,
		{"word":"règne","word_nosc":"regne","lemma":"régner","pos":"VER"} ,
		{"word":"règnent","word_nosc":"regnent","lemma":"régner","pos":"VER"} ,
		{"word":"règnes","word_nosc":"regnes","lemma":"régner","pos":"VER"} ,
		{"word":"réa","word_nosc":"rea","lemma":"réer","pos":"VER"} ,
		{"word":"réabonner","word_nosc":"reabonner","lemma":"réabonner","pos":"VER"} ,
		{"word":"réaccoutumer","word_nosc":"reaccoutumer","lemma":"réaccoutumer","pos":"VER"} ,
		{"word":"réaccoutumerait","word_nosc":"reaccoutumerait","lemma":"réaccoutumer","pos":"VER"} ,
		{"word":"réacheminer","word_nosc":"reacheminer","lemma":"réacheminer","pos":"VER"} ,
		{"word":"réactiva","word_nosc":"reactiva","lemma":"réactiver","pos":"VER"} ,
		{"word":"réactivait","word_nosc":"reactivait","lemma":"réactiver","pos":"VER"} ,
		{"word":"réactive","word_nosc":"reactive","lemma":"réactiver","pos":"VER"} ,
		{"word":"réactiver","word_nosc":"reactiver","lemma":"réactiver","pos":"VER"} ,
		{"word":"réactiveront","word_nosc":"reactiveront","lemma":"réactiver","pos":"VER"} ,
		{"word":"réactives","word_nosc":"reactives","lemma":"réactiver","pos":"VER"} ,
		{"word":"réactivez","word_nosc":"reactivez","lemma":"réactiver","pos":"VER"} ,
		{"word":"réactiviez","word_nosc":"reactiviez","lemma":"réactiver","pos":"VER"} ,
		{"word":"réactivé","word_nosc":"reactive","lemma":"réactiver","pos":"VER"} ,
		{"word":"réactivée","word_nosc":"reactivee","lemma":"réactiver","pos":"VER"} ,
		{"word":"réactivées","word_nosc":"reactivees","lemma":"réactiver","pos":"VER"} ,
		{"word":"réactualise","word_nosc":"reactualise","lemma":"réactualiser","pos":"VER"} ,
		{"word":"réactualiser","word_nosc":"reactualiser","lemma":"réactualiser","pos":"VER"} ,
		{"word":"réactualisé","word_nosc":"reactualise","lemma":"réactualiser","pos":"VER"} ,
		{"word":"réadaptait","word_nosc":"readaptait","lemma":"réadapter","pos":"VER"} ,
		{"word":"réadaptant","word_nosc":"readaptant","lemma":"réadapter","pos":"VER"} ,
		{"word":"réadapte","word_nosc":"readapte","lemma":"réadapter","pos":"VER"} ,
		{"word":"réadapter","word_nosc":"readapter","lemma":"réadapter","pos":"VER"} ,
		{"word":"réadmis","word_nosc":"readmis","lemma":"réadmettre","pos":"VER"} ,
		{"word":"réadmise","word_nosc":"readmise","lemma":"réadmettre","pos":"VER"} ,
		{"word":"réadopter","word_nosc":"readopter","lemma":"réadopter","pos":"VER"} ,
		{"word":"réaffectait","word_nosc":"reaffectait","lemma":"réaffecter","pos":"VER"} ,
		{"word":"réaffecter","word_nosc":"reaffecter","lemma":"réaffecter","pos":"VER"} ,
		{"word":"réaffectons","word_nosc":"reaffectons","lemma":"réaffecter","pos":"VER"} ,
		{"word":"réaffecté","word_nosc":"reaffecte","lemma":"réaffecter","pos":"VER"} ,
		{"word":"réaffectée","word_nosc":"reaffectee","lemma":"réaffecter","pos":"VER"} ,
		{"word":"réaffectés","word_nosc":"reaffectes","lemma":"réaffecter","pos":"VER"} ,
		{"word":"réaffichées","word_nosc":"reaffichees","lemma":"réafficher","pos":"VER"} ,
		{"word":"réaffirmai","word_nosc":"reaffirmai","lemma":"réaffirmer","pos":"VER"} ,
		{"word":"réaffirmaient","word_nosc":"reaffirmaient","lemma":"réaffirmer","pos":"VER"} ,
		{"word":"réaffirmait","word_nosc":"reaffirmait","lemma":"réaffirmer","pos":"VER"} ,
		{"word":"réaffirme","word_nosc":"reaffirme","lemma":"réaffirmer","pos":"VER"} ,
		{"word":"réaffirmer","word_nosc":"reaffirmer","lemma":"réaffirmer","pos":"VER"} ,
		{"word":"réaffirmé","word_nosc":"reaffirme","lemma":"réaffirmer","pos":"VER"} ,
		{"word":"réaffirmée","word_nosc":"reaffirmee","lemma":"réaffirmer","pos":"VER"} ,
		{"word":"réaffûtée","word_nosc":"reaffutee","lemma":"réaffûter","pos":"VER"} ,
		{"word":"réagi","word_nosc":"reagi","lemma":"réagir","pos":"VER"} ,
		{"word":"réagir","word_nosc":"reagir","lemma":"réagir","pos":"VER"} ,
		{"word":"réagira","word_nosc":"reagira","lemma":"réagir","pos":"VER"} ,
		{"word":"réagirai","word_nosc":"reagirai","lemma":"réagir","pos":"VER"} ,
		{"word":"réagiraient","word_nosc":"reagiraient","lemma":"réagir","pos":"VER"} ,
		{"word":"réagirais","word_nosc":"reagirais","lemma":"réagir","pos":"VER"} ,
		{"word":"réagirait","word_nosc":"reagirait","lemma":"réagir","pos":"VER"} ,
		{"word":"réagiras","word_nosc":"reagiras","lemma":"réagir","pos":"VER"} ,
		{"word":"réagirent","word_nosc":"reagirent","lemma":"réagir","pos":"VER"} ,
		{"word":"réagirez","word_nosc":"reagirez","lemma":"réagir","pos":"VER"} ,
		{"word":"réagiriez","word_nosc":"reagiriez","lemma":"réagir","pos":"VER"} ,
		{"word":"réagirons","word_nosc":"reagirons","lemma":"réagir","pos":"VER"} ,
		{"word":"réagiront","word_nosc":"reagiront","lemma":"réagir","pos":"VER"} ,
		{"word":"réagis","word_nosc":"reagis","lemma":"réagir","pos":"VER"} ,
		{"word":"réagissaient","word_nosc":"reagissaient","lemma":"réagir","pos":"VER"} ,
		{"word":"réagissais","word_nosc":"reagissais","lemma":"réagir","pos":"VER"} ,
		{"word":"réagissait","word_nosc":"reagissait","lemma":"réagir","pos":"VER"} ,
		{"word":"réagissant","word_nosc":"reagissant","lemma":"réagir","pos":"VER"} ,
		{"word":"réagisse","word_nosc":"reagisse","lemma":"réagir","pos":"VER"} ,
		{"word":"réagissent","word_nosc":"reagissent","lemma":"réagir","pos":"VER"} ,
		{"word":"réagisses","word_nosc":"reagisses","lemma":"réagir","pos":"VER"} ,
		{"word":"réagissez","word_nosc":"reagissez","lemma":"réagir","pos":"VER"} ,
		{"word":"réagissiez","word_nosc":"reagissiez","lemma":"réagir","pos":"VER"} ,
		{"word":"réagissions","word_nosc":"reagissions","lemma":"réagir","pos":"VER"} ,
		{"word":"réagissons","word_nosc":"reagissons","lemma":"réagir","pos":"VER"} ,
		{"word":"réagit","word_nosc":"reagit","lemma":"réagir","pos":"VER"} ,
		{"word":"réagît","word_nosc":"reagit","lemma":"réagir","pos":"VER"} ,
		{"word":"réait","word_nosc":"reait","lemma":"réer","pos":"VER"} ,
		{"word":"réajusta","word_nosc":"reajusta","lemma":"réajuster","pos":"VER"} ,
		{"word":"réajustaient","word_nosc":"reajustaient","lemma":"réajuster","pos":"VER"} ,
		{"word":"réajustait","word_nosc":"reajustait","lemma":"réajuster","pos":"VER"} ,
		{"word":"réajustant","word_nosc":"reajustant","lemma":"réajuster","pos":"VER"} ,
		{"word":"réajuste","word_nosc":"reajuste","lemma":"réajuster","pos":"VER"} ,
		{"word":"réajuster","word_nosc":"reajuster","lemma":"réajuster","pos":"VER"} ,
		{"word":"réajusté","word_nosc":"reajuste","lemma":"réajuster","pos":"VER"} ,
		{"word":"réajustées","word_nosc":"reajustees","lemma":"réajuster","pos":"VER"} ,
		{"word":"réajustés","word_nosc":"reajustes","lemma":"réajuster","pos":"VER"} ,
		{"word":"réaligne","word_nosc":"realigne","lemma":"réaligner","pos":"VER"} ,
		{"word":"réaligner","word_nosc":"realigner","lemma":"réaligner","pos":"VER"} ,
		{"word":"réalimenter","word_nosc":"realimenter","lemma":"réalimenter","pos":"VER"} ,
		{"word":"réalisa","word_nosc":"realisa","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisai","word_nosc":"realisai","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisaient","word_nosc":"realisaient","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisais","word_nosc":"realisais","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisait","word_nosc":"realisait","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisant","word_nosc":"realisant","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalise","word_nosc":"realise","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisent","word_nosc":"realisent","lemma":"réaliser","pos":"VER"} ,
		{"word":"réaliser","word_nosc":"realiser","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisera","word_nosc":"realisera","lemma":"réaliser","pos":"VER"} ,
		{"word":"réaliserai","word_nosc":"realiserai","lemma":"réaliser","pos":"VER"} ,
		{"word":"réaliseraient","word_nosc":"realiseraient","lemma":"réaliser","pos":"VER"} ,
		{"word":"réaliserais","word_nosc":"realiserais","lemma":"réaliser","pos":"VER"} ,
		{"word":"réaliserait","word_nosc":"realiserait","lemma":"réaliser","pos":"VER"} ,
		{"word":"réaliseras","word_nosc":"realiseras","lemma":"réaliser","pos":"VER"} ,
		{"word":"réaliserez","word_nosc":"realiserez","lemma":"réaliser","pos":"VER"} ,
		{"word":"réaliserions","word_nosc":"realiserions","lemma":"réaliser","pos":"VER"} ,
		{"word":"réaliseront","word_nosc":"realiseront","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalises","word_nosc":"realises","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisez","word_nosc":"realisez","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisiez","word_nosc":"realisiez","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisions","word_nosc":"realisions","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisons","word_nosc":"realisons","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisâmes","word_nosc":"realisames","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisât","word_nosc":"realisat","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisèrent","word_nosc":"realiserent","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisé","word_nosc":"realise","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisée","word_nosc":"realisee","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisées","word_nosc":"realisees","lemma":"réaliser","pos":"VER"} ,
		{"word":"réalisés","word_nosc":"realises","lemma":"réaliser","pos":"VER"} ,
		{"word":"réamorce","word_nosc":"reamorce","lemma":"réamorcer","pos":"VER"} ,
		{"word":"réamorcent","word_nosc":"reamorcent","lemma":"réamorcer","pos":"VER"} ,
		{"word":"réamorcer","word_nosc":"reamorcer","lemma":"réamorcer","pos":"VER"} ,
		{"word":"réamorçait","word_nosc":"reamorcait","lemma":"réamorcer","pos":"VER"} ,
		{"word":"réaménageait","word_nosc":"reamenageait","lemma":"réaménager","pos":"VER"} ,
		{"word":"réaménager","word_nosc":"reamenager","lemma":"réaménager","pos":"VER"} ,
		{"word":"réaménagé","word_nosc":"reamenage","lemma":"réaménager","pos":"VER"} ,
		{"word":"réanimait","word_nosc":"reanimait","lemma":"réanimer","pos":"VER"} ,
		{"word":"réanime","word_nosc":"reanime","lemma":"réanimer","pos":"VER"} ,
		{"word":"réanimer","word_nosc":"reanimer","lemma":"réanimer","pos":"VER"} ,
		{"word":"réanimeraient","word_nosc":"reanimeraient","lemma":"réanimer","pos":"VER"} ,
		{"word":"réanimez","word_nosc":"reanimez","lemma":"réanimer","pos":"VER"} ,
		{"word":"réanimions","word_nosc":"reanimions","lemma":"réanimer","pos":"VER"} ,
		{"word":"réanimé","word_nosc":"reanime","lemma":"réanimer","pos":"VER"} ,
		{"word":"réanimée","word_nosc":"reanimee","lemma":"réanimer","pos":"VER"} ,
		{"word":"réapercevoir","word_nosc":"reapercevoir","lemma":"réapercevoir","pos":"VER"} ,
		{"word":"réapparais","word_nosc":"reapparais","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparaissaient","word_nosc":"reapparaissaient","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparaissais","word_nosc":"reapparaissais","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparaissait","word_nosc":"reapparaissait","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparaissant","word_nosc":"reapparaissant","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparaisse","word_nosc":"reapparaisse","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparaissent","word_nosc":"reapparaissent","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparaisses","word_nosc":"reapparaisses","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparaît","word_nosc":"reapparait","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparaîtra","word_nosc":"reapparaitra","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparaîtrai","word_nosc":"reapparaitrai","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparaîtraient","word_nosc":"reapparaitraient","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparaîtrait","word_nosc":"reapparaitrait","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparaître","word_nosc":"reapparaitre","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparaîtront","word_nosc":"reapparaitront","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparu","word_nosc":"reapparu","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparue","word_nosc":"reapparue","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparues","word_nosc":"reapparues","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparurent","word_nosc":"reapparurent","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparus","word_nosc":"reapparus","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparut","word_nosc":"reapparut","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réapparût","word_nosc":"reapparut","lemma":"réapparaître","pos":"VER"} ,
		{"word":"réappeler","word_nosc":"reappeler","lemma":"réappeler","pos":"VER"} ,
		{"word":"réapprenaient","word_nosc":"reapprenaient","lemma":"réapprendre","pos":"VER"} ,
		{"word":"réapprenais","word_nosc":"reapprenais","lemma":"réapprendre","pos":"VER"} ,
		{"word":"réapprenait","word_nosc":"reapprenait","lemma":"réapprendre","pos":"VER"} ,
		{"word":"réapprend","word_nosc":"reapprend","lemma":"réapprendre","pos":"VER"} ,
		{"word":"réapprendraient","word_nosc":"reapprendraient","lemma":"réapprendre","pos":"VER"} ,
		{"word":"réapprendre","word_nosc":"reapprendre","lemma":"réapprendre","pos":"VER"} ,
		{"word":"réapprendrez","word_nosc":"reapprendrez","lemma":"réapprendre","pos":"VER"} ,
		{"word":"réapprends","word_nosc":"reapprends","lemma":"réapprendre","pos":"VER"} ,
		{"word":"réapprenne","word_nosc":"reapprenne","lemma":"réapprendre","pos":"VER"} ,
		{"word":"réappris","word_nosc":"reappris","lemma":"réapprendre","pos":"VER"} ,
		{"word":"réapprises","word_nosc":"reapprises","lemma":"réapprendre","pos":"VER"} ,
		{"word":"réapprit","word_nosc":"reapprit","lemma":"réapprendre","pos":"VER"} ,
		{"word":"réapprovisionne","word_nosc":"reapprovisionne","lemma":"réapprovisionner","pos":"VER"} ,
		{"word":"réapprovisionner","word_nosc":"reapprovisionner","lemma":"réapprovisionner","pos":"VER"} ,
		{"word":"réapprovisionnerait","word_nosc":"reapprovisionnerait","lemma":"réapprovisionner","pos":"VER"} ,
		{"word":"réapprovisionné","word_nosc":"reapprovisionne","lemma":"réapprovisionner","pos":"VER"} ,
		{"word":"réapprovisionnée","word_nosc":"reapprovisionnee","lemma":"réapprovisionner","pos":"VER"} ,
		{"word":"réargenter","word_nosc":"reargenter","lemma":"réargenter","pos":"VER"} ,
		{"word":"réarma","word_nosc":"rearma","lemma":"réarmer","pos":"VER"} ,
		{"word":"réarmant","word_nosc":"rearmant","lemma":"réarmer","pos":"VER"} ,
		{"word":"réarme","word_nosc":"rearme","lemma":"réarmer","pos":"VER"} ,
		{"word":"réarmer","word_nosc":"rearmer","lemma":"réarmer","pos":"VER"} ,
		{"word":"réarmé","word_nosc":"rearme","lemma":"réarmer","pos":"VER"} ,
		{"word":"réarmées","word_nosc":"rearmees","lemma":"réarmer","pos":"VER"} ,
		{"word":"réarrange","word_nosc":"rearrange","lemma":"réarranger","pos":"VER"} ,
		{"word":"réarrangeait","word_nosc":"rearrangeait","lemma":"réarranger","pos":"VER"} ,
		{"word":"réarrangeant","word_nosc":"rearrangeant","lemma":"réarranger","pos":"VER"} ,
		{"word":"réarrangent","word_nosc":"rearrangent","lemma":"réarranger","pos":"VER"} ,
		{"word":"réarranger","word_nosc":"rearranger","lemma":"réarranger","pos":"VER"} ,
		{"word":"réarrangé","word_nosc":"rearrange","lemma":"réarranger","pos":"VER"} ,
		{"word":"réassembler","word_nosc":"reassembler","lemma":"réassembler","pos":"VER"} ,
		{"word":"réassigner","word_nosc":"reassigner","lemma":"réassigner","pos":"VER"} ,
		{"word":"réassigneront","word_nosc":"reassigneront","lemma":"réassigner","pos":"VER"} ,
		{"word":"réassigné","word_nosc":"reassigne","lemma":"réassigner","pos":"VER"} ,
		{"word":"réassimilé","word_nosc":"reassimile","lemma":"réassimiler","pos":"VER"} ,
		{"word":"réassorties","word_nosc":"reassorties","lemma":"réassortir","pos":"VER"} ,
		{"word":"réassortir","word_nosc":"reassortir","lemma":"réassortir","pos":"VER"} ,
		{"word":"réassumer","word_nosc":"reassumer","lemma":"réassumer","pos":"VER"} ,
		{"word":"réassurer","word_nosc":"reassurer","lemma":"réassurer","pos":"VER"} ,
		{"word":"réattaquai","word_nosc":"reattaquai","lemma":"réattaquer","pos":"VER"} ,
		{"word":"réattaquait","word_nosc":"reattaquait","lemma":"réattaquer","pos":"VER"} ,
		{"word":"réattaque","word_nosc":"reattaque","lemma":"réattaquer","pos":"VER"} ,
		{"word":"réattaquer","word_nosc":"reattaquer","lemma":"réattaquer","pos":"VER"} ,
		{"word":"réattaqué","word_nosc":"reattaque","lemma":"réattaquer","pos":"VER"} ,
		{"word":"récapitula","word_nosc":"recapitula","lemma":"récapituler","pos":"VER"} ,
		{"word":"récapitulai","word_nosc":"recapitulai","lemma":"récapituler","pos":"VER"} ,
		{"word":"récapitulais","word_nosc":"recapitulais","lemma":"récapituler","pos":"VER"} ,
		{"word":"récapitulait","word_nosc":"recapitulait","lemma":"récapituler","pos":"VER"} ,
		{"word":"récapitulant","word_nosc":"recapitulant","lemma":"récapituler","pos":"VER"} ,
		{"word":"récapitule","word_nosc":"recapitule","lemma":"récapituler","pos":"VER"} ,
		{"word":"récapitulent","word_nosc":"recapitulent","lemma":"récapituler","pos":"VER"} ,
		{"word":"récapituler","word_nosc":"recapituler","lemma":"récapituler","pos":"VER"} ,
		{"word":"récapitulez","word_nosc":"recapitulez","lemma":"récapituler","pos":"VER"} ,
		{"word":"récapitulions","word_nosc":"recapitulions","lemma":"récapituler","pos":"VER"} ,
		{"word":"récapitulons","word_nosc":"recapitulons","lemma":"récapituler","pos":"VER"} ,
		{"word":"récapitulé","word_nosc":"recapitule","lemma":"récapituler","pos":"VER"} ,
		{"word":"récapitulée","word_nosc":"recapitulee","lemma":"récapituler","pos":"VER"} ,
		{"word":"réceptionna","word_nosc":"receptionna","lemma":"réceptionner","pos":"VER"} ,
		{"word":"réceptionnant","word_nosc":"receptionnant","lemma":"réceptionner","pos":"VER"} ,
		{"word":"réceptionne","word_nosc":"receptionne","lemma":"réceptionner","pos":"VER"} ,
		{"word":"réceptionnent","word_nosc":"receptionnent","lemma":"réceptionner","pos":"VER"} ,
		{"word":"réceptionner","word_nosc":"receptionner","lemma":"réceptionner","pos":"VER"} ,
		{"word":"réceptionnera","word_nosc":"receptionnera","lemma":"réceptionner","pos":"VER"} ,
		{"word":"réceptionné","word_nosc":"receptionne","lemma":"réceptionner","pos":"VER"} ,
		{"word":"réceptionnées","word_nosc":"receptionnees","lemma":"réceptionner","pos":"VER"} ,
		{"word":"réchappai","word_nosc":"rechappai","lemma":"réchapper","pos":"VER"} ,
		{"word":"réchappais","word_nosc":"rechappais","lemma":"réchapper","pos":"VER"} ,
		{"word":"réchappait","word_nosc":"rechappait","lemma":"réchapper","pos":"VER"} ,
		{"word":"réchappe","word_nosc":"rechappe","lemma":"réchapper","pos":"VER"} ,
		{"word":"réchappent","word_nosc":"rechappent","lemma":"réchapper","pos":"VER"} ,
		{"word":"réchapper","word_nosc":"rechapper","lemma":"réchapper","pos":"VER"} ,
		{"word":"réchappera","word_nosc":"rechappera","lemma":"réchapper","pos":"VER"} ,
		{"word":"réchapperaient","word_nosc":"rechapperaient","lemma":"réchapper","pos":"VER"} ,
		{"word":"réchapperais","word_nosc":"rechapperais","lemma":"réchapper","pos":"VER"} ,
		{"word":"réchapperait","word_nosc":"rechapperait","lemma":"réchapper","pos":"VER"} ,
		{"word":"réchappes","word_nosc":"rechappes","lemma":"réchapper","pos":"VER"} ,
		{"word":"réchappez","word_nosc":"rechappez","lemma":"réchapper","pos":"VER"} ,
		{"word":"réchappons","word_nosc":"rechappons","lemma":"réchapper","pos":"VER"} ,
		{"word":"réchappé","word_nosc":"rechappe","lemma":"réchapper","pos":"VER"} ,
		{"word":"réchappés","word_nosc":"rechappes","lemma":"réchapper","pos":"VER"} ,
		{"word":"réchauffa","word_nosc":"rechauffa","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffai","word_nosc":"rechauffai","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffaient","word_nosc":"rechauffaient","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffais","word_nosc":"rechauffais","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffait","word_nosc":"rechauffait","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffant","word_nosc":"rechauffant","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffe","word_nosc":"rechauffe","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffent","word_nosc":"rechauffent","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffer","word_nosc":"rechauffer","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffera","word_nosc":"rechauffera","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchaufferai","word_nosc":"rechaufferai","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchaufferait","word_nosc":"rechaufferait","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchaufferas","word_nosc":"rechaufferas","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchaufferez","word_nosc":"rechaufferez","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchaufferont","word_nosc":"rechaufferont","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffez","word_nosc":"rechauffez","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffions","word_nosc":"rechauffions","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffons","word_nosc":"rechauffons","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffât","word_nosc":"rechauffat","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffèrent","word_nosc":"rechaufferent","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffé","word_nosc":"rechauffe","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffée","word_nosc":"rechauffee","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffées","word_nosc":"rechauffees","lemma":"réchauffer","pos":"VER"} ,
		{"word":"réchauffés","word_nosc":"rechauffes","lemma":"réchauffer","pos":"VER"} ,
		{"word":"récidivai","word_nosc":"recidivai","lemma":"récidiver","pos":"VER"} ,
		{"word":"récidivaient","word_nosc":"recidivaient","lemma":"récidiver","pos":"VER"} ,
		{"word":"récidive","word_nosc":"recidive","lemma":"récidiver","pos":"VER"} ,
		{"word":"récidiver","word_nosc":"recidiver","lemma":"récidiver","pos":"VER"} ,
		{"word":"récidiveras","word_nosc":"recidiveras","lemma":"récidiver","pos":"VER"} ,
		{"word":"récidives","word_nosc":"recidives","lemma":"récidiver","pos":"VER"} ,
		{"word":"récidivez","word_nosc":"recidivez","lemma":"récidiver","pos":"VER"} ,
		{"word":"récidivé","word_nosc":"recidive","lemma":"récidiver","pos":"VER"} ,
		{"word":"réciproques","word_nosc":"reciproques","lemma":"réciproquer","pos":"VER"} ,
		{"word":"récita","word_nosc":"recita","lemma":"réciter","pos":"VER"} ,
		{"word":"récitai","word_nosc":"recitai","lemma":"réciter","pos":"VER"} ,
		{"word":"récitaient","word_nosc":"recitaient","lemma":"réciter","pos":"VER"} ,
		{"word":"récitais","word_nosc":"recitais","lemma":"réciter","pos":"VER"} ,
		{"word":"récitait","word_nosc":"recitait","lemma":"réciter","pos":"VER"} ,
		{"word":"récitant","word_nosc":"recitant","lemma":"réciter","pos":"VER"} ,
		{"word":"récitassent","word_nosc":"recitassent","lemma":"réciter","pos":"VER"} ,
		{"word":"récite","word_nosc":"recite","lemma":"réciter","pos":"VER"} ,
		{"word":"récitent","word_nosc":"recitent","lemma":"réciter","pos":"VER"} ,
		{"word":"réciter","word_nosc":"reciter","lemma":"réciter","pos":"VER"} ,
		{"word":"récitera","word_nosc":"recitera","lemma":"réciter","pos":"VER"} ,
		{"word":"réciterai","word_nosc":"reciterai","lemma":"réciter","pos":"VER"} ,
		{"word":"réciteraient","word_nosc":"reciteraient","lemma":"réciter","pos":"VER"} ,
		{"word":"réciterait","word_nosc":"reciterait","lemma":"réciter","pos":"VER"} ,
		{"word":"réciteras","word_nosc":"reciteras","lemma":"réciter","pos":"VER"} ,
		{"word":"réciterez","word_nosc":"reciterez","lemma":"réciter","pos":"VER"} ,
		{"word":"réciterons","word_nosc":"reciterons","lemma":"réciter","pos":"VER"} ,
		{"word":"réciteront","word_nosc":"reciteront","lemma":"réciter","pos":"VER"} ,
		{"word":"récites","word_nosc":"recites","lemma":"réciter","pos":"VER"} ,
		{"word":"récitez","word_nosc":"recitez","lemma":"réciter","pos":"VER"} ,
		{"word":"récitions","word_nosc":"recitions","lemma":"réciter","pos":"VER"} ,
		{"word":"récitons","word_nosc":"recitons","lemma":"réciter","pos":"VER"} ,
		{"word":"récitèrent","word_nosc":"reciterent","lemma":"réciter","pos":"VER"} ,
		{"word":"récité","word_nosc":"recite","lemma":"réciter","pos":"VER"} ,
		{"word":"récitée","word_nosc":"recitee","lemma":"réciter","pos":"VER"} ,
		{"word":"récitées","word_nosc":"recitees","lemma":"réciter","pos":"VER"} ,
		{"word":"récités","word_nosc":"recites","lemma":"réciter","pos":"VER"} ,
		{"word":"réclama","word_nosc":"reclama","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamai","word_nosc":"reclamai","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamaient","word_nosc":"reclamaient","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamais","word_nosc":"reclamais","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamait","word_nosc":"reclamait","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamant","word_nosc":"reclamant","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclame","word_nosc":"reclame","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclament","word_nosc":"reclament","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamer","word_nosc":"reclamer","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamera","word_nosc":"reclamera","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamerai","word_nosc":"reclamerai","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclameraient","word_nosc":"reclameraient","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamerais","word_nosc":"reclamerais","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamerait","word_nosc":"reclamerait","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclameras","word_nosc":"reclameras","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamerez","word_nosc":"reclamerez","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclameriez","word_nosc":"reclameriez","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamerons","word_nosc":"reclamerons","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclameront","word_nosc":"reclameront","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclames","word_nosc":"reclames","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamez","word_nosc":"reclamez","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamiez","word_nosc":"reclamiez","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamions","word_nosc":"reclamions","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamons","word_nosc":"reclamons","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamèrent","word_nosc":"reclamerent","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamé","word_nosc":"reclame","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamée","word_nosc":"reclamee","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamées","word_nosc":"reclamees","lemma":"réclamer","pos":"VER"} ,
		{"word":"réclamés","word_nosc":"reclames","lemma":"réclamer","pos":"VER"} ,
		{"word":"récolta","word_nosc":"recolta","lemma":"récolter","pos":"VER"} ,
		{"word":"récoltai","word_nosc":"recoltai","lemma":"récolter","pos":"VER"} ,
		{"word":"récoltaient","word_nosc":"recoltaient","lemma":"récolter","pos":"VER"} ,
		{"word":"récoltais","word_nosc":"recoltais","lemma":"récolter","pos":"VER"} ,
		{"word":"récoltait","word_nosc":"recoltait","lemma":"récolter","pos":"VER"} ,
		{"word":"récoltant","word_nosc":"recoltant","lemma":"récolter","pos":"VER"} ,
		{"word":"récolte","word_nosc":"recolte","lemma":"récolter","pos":"VER"} ,
		{"word":"récoltent","word_nosc":"recoltent","lemma":"récolter","pos":"VER"} ,
		{"word":"récolter","word_nosc":"recolter","lemma":"récolter","pos":"VER"} ,
		{"word":"récoltera","word_nosc":"recoltera","lemma":"récolter","pos":"VER"} ,
		{"word":"récolterai","word_nosc":"recolterai","lemma":"récolter","pos":"VER"} ,
		{"word":"récolteraient","word_nosc":"recolteraient","lemma":"récolter","pos":"VER"} ,
		{"word":"récolterais","word_nosc":"recolterais","lemma":"récolter","pos":"VER"} ,
		{"word":"récolterait","word_nosc":"recolterait","lemma":"récolter","pos":"VER"} ,
		{"word":"récolteras","word_nosc":"recolteras","lemma":"récolter","pos":"VER"} ,
		{"word":"récolterons","word_nosc":"recolterons","lemma":"récolter","pos":"VER"} ,
		{"word":"récolteront","word_nosc":"recolteront","lemma":"récolter","pos":"VER"} ,
		{"word":"récoltes","word_nosc":"recoltes","lemma":"récolter","pos":"VER"} ,
		{"word":"récoltez","word_nosc":"recoltez","lemma":"récolter","pos":"VER"} ,
		{"word":"récoltions","word_nosc":"recoltions","lemma":"récolter","pos":"VER"} ,
		{"word":"récoltons","word_nosc":"recoltons","lemma":"récolter","pos":"VER"} ,
		{"word":"récolté","word_nosc":"recolte","lemma":"récolter","pos":"VER"} ,
		{"word":"récoltée","word_nosc":"recoltee","lemma":"récolter","pos":"VER"} ,
		{"word":"récoltées","word_nosc":"recoltees","lemma":"récolter","pos":"VER"} ,
		{"word":"récoltés","word_nosc":"recoltes","lemma":"récolter","pos":"VER"} ,
		{"word":"récompensa","word_nosc":"recompensa","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompensaient","word_nosc":"recompensaient","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompensait","word_nosc":"recompensait","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompensant","word_nosc":"recompensant","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompense","word_nosc":"recompense","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompensent","word_nosc":"recompensent","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompenser","word_nosc":"recompenser","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompensera","word_nosc":"recompensera","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompenserai","word_nosc":"recompenserai","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompenserais","word_nosc":"recompenserais","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompenserons","word_nosc":"recompenserons","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompenseront","word_nosc":"recompenseront","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompenses","word_nosc":"recompenses","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompensez","word_nosc":"recompensez","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompensons","word_nosc":"recompensons","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompensèrent","word_nosc":"recompenserent","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompensé","word_nosc":"recompense","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompensée","word_nosc":"recompensee","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompensées","word_nosc":"recompensees","lemma":"récompenser","pos":"VER"} ,
		{"word":"récompensés","word_nosc":"recompenses","lemma":"récompenser","pos":"VER"} ,
		{"word":"réconcilia","word_nosc":"reconcilia","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconciliaient","word_nosc":"reconciliaient","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconciliait","word_nosc":"reconciliait","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconciliant","word_nosc":"reconciliant","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconcilie","word_nosc":"reconcilie","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconcilient","word_nosc":"reconcilient","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconcilier","word_nosc":"reconcilier","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconciliera","word_nosc":"reconciliera","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconcilierai","word_nosc":"reconcilierai","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconcilieraient","word_nosc":"reconcilieraient","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconcilierait","word_nosc":"reconcilierait","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconcilierez","word_nosc":"reconcilierez","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconciliez","word_nosc":"reconciliez","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconciliions","word_nosc":"reconciliions","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconcilions","word_nosc":"reconcilions","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconciliâmes","word_nosc":"reconciliames","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconcilié","word_nosc":"reconcilie","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconciliée","word_nosc":"reconciliee","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconciliées","word_nosc":"reconciliees","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconciliés","word_nosc":"reconcilies","lemma":"réconcilier","pos":"VER"} ,
		{"word":"réconforta","word_nosc":"reconforta","lemma":"réconforter","pos":"VER"} ,
		{"word":"réconfortaient","word_nosc":"reconfortaient","lemma":"réconforter","pos":"VER"} ,
		{"word":"réconfortait","word_nosc":"reconfortait","lemma":"réconforter","pos":"VER"} ,
		{"word":"réconfortant","word_nosc":"reconfortant","lemma":"réconforter","pos":"VER"} ,
		{"word":"réconforte","word_nosc":"reconforte","lemma":"réconforter","pos":"VER"} ,
		{"word":"réconfortent","word_nosc":"reconfortent","lemma":"réconforter","pos":"VER"} ,
		{"word":"réconforter","word_nosc":"reconforter","lemma":"réconforter","pos":"VER"} ,
		{"word":"réconfortera","word_nosc":"reconfortera","lemma":"réconforter","pos":"VER"} ,
		{"word":"réconforterai","word_nosc":"reconforterai","lemma":"réconforter","pos":"VER"} ,
		{"word":"réconforterait","word_nosc":"reconforterait","lemma":"réconforter","pos":"VER"} ,
		{"word":"réconforteras","word_nosc":"reconforteras","lemma":"réconforter","pos":"VER"} ,
		{"word":"réconforterez","word_nosc":"reconforterez","lemma":"réconforter","pos":"VER"} ,
		{"word":"réconfortez","word_nosc":"reconfortez","lemma":"réconforter","pos":"VER"} ,
		{"word":"réconforté","word_nosc":"reconforte","lemma":"réconforter","pos":"VER"} ,
		{"word":"réconfortée","word_nosc":"reconfortee","lemma":"réconforter","pos":"VER"} ,
		{"word":"réconfortés","word_nosc":"reconfortes","lemma":"réconforter","pos":"VER"} ,
		{"word":"récria","word_nosc":"recria","lemma":"récrier","pos":"VER"} ,
		{"word":"récriai","word_nosc":"recriai","lemma":"récrier","pos":"VER"} ,
		{"word":"récriaient","word_nosc":"recriaient","lemma":"récrier","pos":"VER"} ,
		{"word":"récriait","word_nosc":"recriait","lemma":"récrier","pos":"VER"} ,
		{"word":"récrie","word_nosc":"recrie","lemma":"récrier","pos":"VER"} ,
		{"word":"récrient","word_nosc":"recrient","lemma":"récrier","pos":"VER"} ,
		{"word":"récrier","word_nosc":"recrier","lemma":"récrier","pos":"VER"} ,
		{"word":"récriminait","word_nosc":"recriminait","lemma":"récriminer","pos":"VER"} ,
		{"word":"récriminer","word_nosc":"recriminer","lemma":"récriminer","pos":"VER"} ,
		{"word":"récrirai","word_nosc":"recrirai","lemma":"récrire","pos":"VER"} ,
		{"word":"récrire","word_nosc":"recrire","lemma":"récrire","pos":"VER"} ,
		{"word":"récris","word_nosc":"recris","lemma":"récrire","pos":"VER"} ,
		{"word":"récrit","word_nosc":"recrit","lemma":"récrire","pos":"VER"} ,
		{"word":"récrites","word_nosc":"recrites","lemma":"récrire","pos":"VER"} ,
		{"word":"récrivent","word_nosc":"recrivent","lemma":"récrire","pos":"VER"} ,
		{"word":"récrivit","word_nosc":"recrivit","lemma":"récrire","pos":"VER"} ,
		{"word":"récrièrent","word_nosc":"recrierent","lemma":"récrier","pos":"VER"} ,
		{"word":"récrié","word_nosc":"recrie","lemma":"récrier","pos":"VER"} ,
		{"word":"récriée","word_nosc":"recriee","lemma":"récrier","pos":"VER"} ,
		{"word":"récriées","word_nosc":"recriees","lemma":"récrier","pos":"VER"} ,
		{"word":"récriés","word_nosc":"recries","lemma":"récrier","pos":"VER"} ,
		{"word":"récrée","word_nosc":"recree","lemma":"récréer","pos":"VER"} ,
		{"word":"récréer","word_nosc":"recreer","lemma":"récréer","pos":"VER"} ,
		{"word":"récréé","word_nosc":"recree","lemma":"récréer","pos":"VER"} ,
		{"word":"récupère","word_nosc":"recupere","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupèrent","word_nosc":"recuperent","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupères","word_nosc":"recuperes","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupéra","word_nosc":"recupera","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérai","word_nosc":"recuperai","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupéraient","word_nosc":"recuperaient","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérais","word_nosc":"recuperais","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérait","word_nosc":"recuperait","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérant","word_nosc":"recuperant","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérer","word_nosc":"recuperer","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérera","word_nosc":"recuperera","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérerai","word_nosc":"recupererai","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérerais","word_nosc":"recupererais","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérerait","word_nosc":"recupererait","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupéreras","word_nosc":"recupereras","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérerez","word_nosc":"recupererez","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérerons","word_nosc":"recupererons","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupéreront","word_nosc":"recupereront","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérez","word_nosc":"recuperez","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupériez","word_nosc":"recuperiez","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérions","word_nosc":"recuperions","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérons","word_nosc":"recuperons","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérâmes","word_nosc":"recuperames","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérèrent","word_nosc":"recupererent","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupéré","word_nosc":"recupere","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérée","word_nosc":"recuperee","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérées","word_nosc":"recuperees","lemma":"récupérer","pos":"VER"} ,
		{"word":"récupérés","word_nosc":"recuperes","lemma":"récupérer","pos":"VER"} ,
		{"word":"récuraient","word_nosc":"recuraient","lemma":"récurer","pos":"VER"} ,
		{"word":"récurais","word_nosc":"recurais","lemma":"récurer","pos":"VER"} ,
		{"word":"récurait","word_nosc":"recurait","lemma":"récurer","pos":"VER"} ,
		{"word":"récurant","word_nosc":"recurant","lemma":"récurer","pos":"VER"} ,
		{"word":"récure","word_nosc":"recure","lemma":"récurer","pos":"VER"} ,
		{"word":"récurent","word_nosc":"recurent","lemma":"récurer","pos":"VER"} ,
		{"word":"récurer","word_nosc":"recurer","lemma":"récurer","pos":"VER"} ,
		{"word":"récurerai","word_nosc":"recurerai","lemma":"récurer","pos":"VER"} ,
		{"word":"récuré","word_nosc":"recure","lemma":"récurer","pos":"VER"} ,
		{"word":"récurée","word_nosc":"recuree","lemma":"récurer","pos":"VER"} ,
		{"word":"récurées","word_nosc":"recurees","lemma":"récurer","pos":"VER"} ,
		{"word":"récurés","word_nosc":"recures","lemma":"récurer","pos":"VER"} ,
		{"word":"récusa","word_nosc":"recusa","lemma":"récuser","pos":"VER"} ,
		{"word":"récusaient","word_nosc":"recusaient","lemma":"récuser","pos":"VER"} ,
		{"word":"récusais","word_nosc":"recusais","lemma":"récuser","pos":"VER"} ,
		{"word":"récusait","word_nosc":"recusait","lemma":"récuser","pos":"VER"} ,
		{"word":"récusant","word_nosc":"recusant","lemma":"récuser","pos":"VER"} ,
		{"word":"récuse","word_nosc":"recuse","lemma":"récuser","pos":"VER"} ,
		{"word":"récuser","word_nosc":"recuser","lemma":"récuser","pos":"VER"} ,
		{"word":"récuserait","word_nosc":"recuserait","lemma":"récuser","pos":"VER"} ,
		{"word":"récuserons","word_nosc":"recuserons","lemma":"récuser","pos":"VER"} ,
		{"word":"récusons","word_nosc":"recusons","lemma":"récuser","pos":"VER"} ,
		{"word":"récusèrent","word_nosc":"recuserent","lemma":"récuser","pos":"VER"} ,
		{"word":"récusé","word_nosc":"recuse","lemma":"récuser","pos":"VER"} ,
		{"word":"récusée","word_nosc":"recusee","lemma":"récuser","pos":"VER"} ,
		{"word":"rédige","word_nosc":"redige","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigea","word_nosc":"redigea","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigeai","word_nosc":"redigeai","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigeaient","word_nosc":"redigeaient","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigeais","word_nosc":"redigeais","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigeait","word_nosc":"redigeait","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigeant","word_nosc":"redigeant","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigent","word_nosc":"redigent","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigeons","word_nosc":"redigeons","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédiger","word_nosc":"rediger","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigera","word_nosc":"redigera","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigerai","word_nosc":"redigerai","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigerais","word_nosc":"redigerais","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigeras","word_nosc":"redigeras","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigerez","word_nosc":"redigerez","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédiges","word_nosc":"rediges","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigez","word_nosc":"redigez","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigiez","word_nosc":"redigiez","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigions","word_nosc":"redigions","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigèrent","word_nosc":"redigerent","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigé","word_nosc":"redige","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigée","word_nosc":"redigee","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigées","word_nosc":"redigees","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédigés","word_nosc":"rediges","lemma":"rédiger","pos":"VER"} ,
		{"word":"rédimaient","word_nosc":"redimaient","lemma":"rédimer","pos":"VER"} ,
		{"word":"réduira","word_nosc":"reduira","lemma":"réduire","pos":"VER"} ,
		{"word":"réduirai","word_nosc":"reduirai","lemma":"réduire","pos":"VER"} ,
		{"word":"réduiraient","word_nosc":"reduiraient","lemma":"réduire","pos":"VER"} ,
		{"word":"réduirait","word_nosc":"reduirait","lemma":"réduire","pos":"VER"} ,
		{"word":"réduire","word_nosc":"reduire","lemma":"réduire","pos":"VER"} ,
		{"word":"réduirons","word_nosc":"reduirons","lemma":"réduire","pos":"VER"} ,
		{"word":"réduiront","word_nosc":"reduiront","lemma":"réduire","pos":"VER"} ,
		{"word":"réduis","word_nosc":"reduis","lemma":"réduire","pos":"VER"} ,
		{"word":"réduisaient","word_nosc":"reduisaient","lemma":"réduire","pos":"VER"} ,
		{"word":"réduisais","word_nosc":"reduisais","lemma":"réduire","pos":"VER"} ,
		{"word":"réduisait","word_nosc":"reduisait","lemma":"réduire","pos":"VER"} ,
		{"word":"réduisant","word_nosc":"reduisant","lemma":"réduire","pos":"VER"} ,
		{"word":"réduise","word_nosc":"reduise","lemma":"réduire","pos":"VER"} ,
		{"word":"réduisent","word_nosc":"reduisent","lemma":"réduire","pos":"VER"} ,
		{"word":"réduises","word_nosc":"reduises","lemma":"réduire","pos":"VER"} ,
		{"word":"réduisez","word_nosc":"reduisez","lemma":"réduire","pos":"VER"} ,
		{"word":"réduisions","word_nosc":"reduisions","lemma":"réduire","pos":"VER"} ,
		{"word":"réduisirent","word_nosc":"reduisirent","lemma":"réduire","pos":"VER"} ,
		{"word":"réduisis","word_nosc":"reduisis","lemma":"réduire","pos":"VER"} ,
		{"word":"réduisit","word_nosc":"reduisit","lemma":"réduire","pos":"VER"} ,
		{"word":"réduisons","word_nosc":"reduisons","lemma":"réduire","pos":"VER"} ,
		{"word":"réduisît","word_nosc":"reduisit","lemma":"réduire","pos":"VER"} ,
		{"word":"réduit","word_nosc":"reduit","lemma":"réduire","pos":"VER"} ,
		{"word":"réduite","word_nosc":"reduite","lemma":"réduire","pos":"VER"} ,
		{"word":"réduites","word_nosc":"reduites","lemma":"réduire","pos":"VER"} ,
		{"word":"réduits","word_nosc":"reduits","lemma":"réduire","pos":"VER"} ,
		{"word":"réemballer","word_nosc":"reemballer","lemma":"réemballer","pos":"VER"} ,
		{"word":"réembarque","word_nosc":"reembarque","lemma":"réembarquer","pos":"VER"} ,
		{"word":"réembarquer","word_nosc":"reembarquer","lemma":"réembarquer","pos":"VER"} ,
		{"word":"réembauche","word_nosc":"reembauche","lemma":"réembaucher","pos":"VER"} ,
		{"word":"réembaucher","word_nosc":"reembaucher","lemma":"réembaucher","pos":"VER"} ,
		{"word":"réembauché","word_nosc":"reembauche","lemma":"réembaucher","pos":"VER"} ,
		{"word":"réemployant","word_nosc":"reemployant","lemma":"réemployer","pos":"VER"} ,
		{"word":"réemployer","word_nosc":"reemployer","lemma":"réemployer","pos":"VER"} ,
		{"word":"réemprunter","word_nosc":"reemprunter","lemma":"réemprunter","pos":"VER"} ,
		{"word":"réencadrer","word_nosc":"reencadrer","lemma":"réencadrer","pos":"VER"} ,
		{"word":"réenclenches","word_nosc":"reenclenches","lemma":"réenclencher","pos":"VER"} ,
		{"word":"réendossant","word_nosc":"reendossant","lemma":"réendosser","pos":"VER"} ,
		{"word":"réendosser","word_nosc":"reendosser","lemma":"réendosser","pos":"VER"} ,
		{"word":"réengage","word_nosc":"reengage","lemma":"réengager","pos":"VER"} ,
		{"word":"réengager","word_nosc":"reengager","lemma":"réengager","pos":"VER"} ,
		{"word":"réengagé","word_nosc":"reengage","lemma":"réengager","pos":"VER"} ,
		{"word":"réengagées","word_nosc":"reengagees","lemma":"réengager","pos":"VER"} ,
		{"word":"réenregistrer","word_nosc":"reenregistrer","lemma":"réenregistrer","pos":"VER"} ,
		{"word":"réenregistré","word_nosc":"reenregistre","lemma":"réenregistrer","pos":"VER"} ,
		{"word":"réentendaient","word_nosc":"reentendaient","lemma":"réentendre","pos":"VER"} ,
		{"word":"réentendais","word_nosc":"reentendais","lemma":"réentendre","pos":"VER"} ,
		{"word":"réentendons","word_nosc":"reentendons","lemma":"réentendre","pos":"VER"} ,
		{"word":"réentendre","word_nosc":"reentendre","lemma":"réentendre","pos":"VER"} ,
		{"word":"réentends","word_nosc":"reentends","lemma":"réentendre","pos":"VER"} ,
		{"word":"réentendu","word_nosc":"reentendu","lemma":"réentendre","pos":"VER"} ,
		{"word":"réenterrer","word_nosc":"reenterrer","lemma":"réenterrer","pos":"VER"} ,
		{"word":"réentraîner","word_nosc":"reentrainer","lemma":"réentraîner","pos":"VER"} ,
		{"word":"réenvahir","word_nosc":"reenvahir","lemma":"réenvahir","pos":"VER"} ,
		{"word":"réenvisager","word_nosc":"reenvisager","lemma":"réenvisager","pos":"VER"} ,
		{"word":"réessaie","word_nosc":"reessaie","lemma":"réessayer","pos":"VER"} ,
		{"word":"réessaient","word_nosc":"reessaient","lemma":"réessayer","pos":"VER"} ,
		{"word":"réessaiera","word_nosc":"reessaiera","lemma":"réessayer","pos":"VER"} ,
		{"word":"réessaierai","word_nosc":"reessaierai","lemma":"réessayer","pos":"VER"} ,
		{"word":"réessaieras","word_nosc":"reessaieras","lemma":"réessayer","pos":"VER"} ,
		{"word":"réessaieront","word_nosc":"reessaieront","lemma":"réessayer","pos":"VER"} ,
		{"word":"réessayant","word_nosc":"reessayant","lemma":"réessayer","pos":"VER"} ,
		{"word":"réessaye","word_nosc":"reessaye","lemma":"réessayer","pos":"VER"} ,
		{"word":"réessayer","word_nosc":"reessayer","lemma":"réessayer","pos":"VER"} ,
		{"word":"réessayez","word_nosc":"reessayez","lemma":"réessayer","pos":"VER"} ,
		{"word":"réessayiez","word_nosc":"reessayiez","lemma":"réessayer","pos":"VER"} ,
		{"word":"réessayons","word_nosc":"reessayons","lemma":"réessayer","pos":"VER"} ,
		{"word":"réessayé","word_nosc":"reessaye","lemma":"réessayer","pos":"VER"} ,
		{"word":"réexaminant","word_nosc":"reexaminant","lemma":"réexaminer","pos":"VER"} ,
		{"word":"réexamine","word_nosc":"reexamine","lemma":"réexaminer","pos":"VER"} ,
		{"word":"réexaminer","word_nosc":"reexaminer","lemma":"réexaminer","pos":"VER"} ,
		{"word":"réexaminerons","word_nosc":"reexaminerons","lemma":"réexaminer","pos":"VER"} ,
		{"word":"réexamines","word_nosc":"reexamines","lemma":"réexaminer","pos":"VER"} ,
		{"word":"réexaminez","word_nosc":"reexaminez","lemma":"réexaminer","pos":"VER"} ,
		{"word":"réexaminiez","word_nosc":"reexaminiez","lemma":"réexaminer","pos":"VER"} ,
		{"word":"réexaminions","word_nosc":"reexaminions","lemma":"réexaminer","pos":"VER"} ,
		{"word":"réexaminé","word_nosc":"reexamine","lemma":"réexaminer","pos":"VER"} ,
		{"word":"réexaminée","word_nosc":"reexaminee","lemma":"réexaminer","pos":"VER"} ,
		{"word":"réexaminées","word_nosc":"reexaminees","lemma":"réexaminer","pos":"VER"} ,
		{"word":"réexpédia","word_nosc":"reexpedia","lemma":"réexpédier","pos":"VER"} ,
		{"word":"réexpédiait","word_nosc":"reexpediait","lemma":"réexpédier","pos":"VER"} ,
		{"word":"réexpédiant","word_nosc":"reexpediant","lemma":"réexpédier","pos":"VER"} ,
		{"word":"réexpédier","word_nosc":"reexpedier","lemma":"réexpédier","pos":"VER"} ,
		{"word":"réexpédiez","word_nosc":"reexpediez","lemma":"réexpédier","pos":"VER"} ,
		{"word":"réexpédions","word_nosc":"reexpedions","lemma":"réexpédier","pos":"VER"} ,
		{"word":"réexpédié","word_nosc":"reexpedie","lemma":"réexpédier","pos":"VER"} ,
		{"word":"réexpédiés","word_nosc":"reexpedies","lemma":"réexpédier","pos":"VER"} ,
		{"word":"réfléchi","word_nosc":"reflechi","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchie","word_nosc":"reflechie","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchies","word_nosc":"reflechies","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchir","word_nosc":"reflechir","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchira","word_nosc":"reflechira","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchirai","word_nosc":"reflechirai","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchirais","word_nosc":"reflechirais","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchirait","word_nosc":"reflechirait","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchiras","word_nosc":"reflechiras","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchirent","word_nosc":"reflechirent","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchirez","word_nosc":"reflechirez","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchiriez","word_nosc":"reflechiriez","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchirons","word_nosc":"reflechirons","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchiront","word_nosc":"reflechiront","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchis","word_nosc":"reflechis","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchissaient","word_nosc":"reflechissaient","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchissais","word_nosc":"reflechissais","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchissait","word_nosc":"reflechissait","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchissant","word_nosc":"reflechissant","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchisse","word_nosc":"reflechisse","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchissent","word_nosc":"reflechissent","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchisses","word_nosc":"reflechisses","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchissez","word_nosc":"reflechissez","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchissiez","word_nosc":"reflechissiez","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchissons","word_nosc":"reflechissons","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchit","word_nosc":"reflechit","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réfléchît","word_nosc":"reflechit","lemma":"réfléchir","pos":"VER"} ,
		{"word":"réforma","word_nosc":"reforma","lemma":"réformer","pos":"VER"} ,
		{"word":"réforme","word_nosc":"reforme","lemma":"réformer","pos":"VER"} ,
		{"word":"réformer","word_nosc":"reformer","lemma":"réformer","pos":"VER"} ,
		{"word":"réformera","word_nosc":"reformera","lemma":"réformer","pos":"VER"} ,
		{"word":"réformons","word_nosc":"reformons","lemma":"réformer","pos":"VER"} ,
		{"word":"réformé","word_nosc":"reforme","lemma":"réformer","pos":"VER"} ,
		{"word":"réformée","word_nosc":"reformee","lemma":"réformer","pos":"VER"} ,
		{"word":"réformés","word_nosc":"reformes","lemma":"réformer","pos":"VER"} ,
		{"word":"réfractant","word_nosc":"refractant","lemma":"réfracter","pos":"VER"} ,
		{"word":"réfractera","word_nosc":"refractera","lemma":"réfracter","pos":"VER"} ,
		{"word":"réfracterait","word_nosc":"refracterait","lemma":"réfracter","pos":"VER"} ,
		{"word":"réfracté","word_nosc":"refracte","lemma":"réfracter","pos":"VER"} ,
		{"word":"réfrigèrent","word_nosc":"refrigerent","lemma":"réfrigérer","pos":"VER"} ,
		{"word":"réfrigérant","word_nosc":"refrigerant","lemma":"réfrigérer","pos":"VER"} ,
		{"word":"réfrigérer","word_nosc":"refrigerer","lemma":"réfrigérer","pos":"VER"} ,
		{"word":"réfrigéré","word_nosc":"refrigere","lemma":"réfrigérer","pos":"VER"} ,
		{"word":"réfrigérée","word_nosc":"refrigeree","lemma":"réfrigérer","pos":"VER"} ,
		{"word":"réfrigérées","word_nosc":"refrigerees","lemma":"réfrigérer","pos":"VER"} ,
		{"word":"réfrigérés","word_nosc":"refrigeres","lemma":"réfrigérer","pos":"VER"} ,
		{"word":"réfrène","word_nosc":"refrene","lemma":"réfréner","pos":"VER"} ,
		{"word":"réfréna","word_nosc":"refrena","lemma":"réfréner","pos":"VER"} ,
		{"word":"réfréner","word_nosc":"refrener","lemma":"réfréner","pos":"VER"} ,
		{"word":"réfrénez","word_nosc":"refrenez","lemma":"réfréner","pos":"VER"} ,
		{"word":"réfrénée","word_nosc":"refrenee","lemma":"réfréner","pos":"VER"} ,
		{"word":"réfugia","word_nosc":"refugia","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugiai","word_nosc":"refugiai","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugiaient","word_nosc":"refugiaient","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugiais","word_nosc":"refugiais","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugiait","word_nosc":"refugiait","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugiant","word_nosc":"refugiant","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugie","word_nosc":"refugie","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugient","word_nosc":"refugient","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugier","word_nosc":"refugier","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugiera","word_nosc":"refugiera","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugierais","word_nosc":"refugierais","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugies","word_nosc":"refugies","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugiez","word_nosc":"refugiez","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugiions","word_nosc":"refugiions","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugions","word_nosc":"refugions","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugièrent","word_nosc":"refugierent","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugié","word_nosc":"refugie","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugiée","word_nosc":"refugiee","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugiées","word_nosc":"refugiees","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfugiés","word_nosc":"refugies","lemma":"réfugier","pos":"VER"} ,
		{"word":"réfuta","word_nosc":"refuta","lemma":"réfuter","pos":"VER"} ,
		{"word":"réfutaient","word_nosc":"refutaient","lemma":"réfuter","pos":"VER"} ,
		{"word":"réfutant","word_nosc":"refutant","lemma":"réfuter","pos":"VER"} ,
		{"word":"réfute","word_nosc":"refute","lemma":"réfuter","pos":"VER"} ,
		{"word":"réfutent","word_nosc":"refutent","lemma":"réfuter","pos":"VER"} ,
		{"word":"réfuter","word_nosc":"refuter","lemma":"réfuter","pos":"VER"} ,
		{"word":"réfutons","word_nosc":"refutons","lemma":"réfuter","pos":"VER"} ,
		{"word":"réfutèrent","word_nosc":"refuterent","lemma":"réfuter","pos":"VER"} ,
		{"word":"réfuté","word_nosc":"refute","lemma":"réfuter","pos":"VER"} ,
		{"word":"réfutée","word_nosc":"refutee","lemma":"réfuter","pos":"VER"} ,
		{"word":"réfutées","word_nosc":"refutees","lemma":"réfuter","pos":"VER"} ,
		{"word":"réfère","word_nosc":"refere","lemma":"référer","pos":"VER"} ,
		{"word":"réfèrent","word_nosc":"referent","lemma":"référer","pos":"VER"} ,
		{"word":"référaient","word_nosc":"referaient","lemma":"référer","pos":"VER"} ,
		{"word":"référait","word_nosc":"referait","lemma":"référer","pos":"VER"} ,
		{"word":"référant","word_nosc":"referant","lemma":"référer","pos":"VER"} ,
		{"word":"référencer","word_nosc":"referencer","lemma":"référencer","pos":"VER"} ,
		{"word":"référencé","word_nosc":"reference","lemma":"référencer","pos":"VER"} ,
		{"word":"référencée","word_nosc":"referencee","lemma":"référencer","pos":"VER"} ,
		{"word":"référer","word_nosc":"referer","lemma":"référer","pos":"VER"} ,
		{"word":"référera","word_nosc":"referera","lemma":"référer","pos":"VER"} ,
		{"word":"référerai","word_nosc":"refererai","lemma":"référer","pos":"VER"} ,
		{"word":"référerez","word_nosc":"refererez","lemma":"référer","pos":"VER"} ,
		{"word":"référez","word_nosc":"referez","lemma":"référer","pos":"VER"} ,
		{"word":"référiez","word_nosc":"referiez","lemma":"référer","pos":"VER"} ,
		{"word":"référèrent","word_nosc":"refererent","lemma":"référer","pos":"VER"} ,
		{"word":"référé","word_nosc":"refere","lemma":"référer","pos":"VER"} ,
		{"word":"référée","word_nosc":"referee","lemma":"référer","pos":"VER"} ,
		{"word":"régala","word_nosc":"regala","lemma":"régaler","pos":"VER"} ,
		{"word":"régalai","word_nosc":"regalai","lemma":"régaler","pos":"VER"} ,
		{"word":"régalaient","word_nosc":"regalaient","lemma":"régaler","pos":"VER"} ,
		{"word":"régalais","word_nosc":"regalais","lemma":"régaler","pos":"VER"} ,
		{"word":"régalait","word_nosc":"regalait","lemma":"régaler","pos":"VER"} ,
		{"word":"régalant","word_nosc":"regalant","lemma":"régaler","pos":"VER"} ,
		{"word":"régale","word_nosc":"regale","lemma":"régaler","pos":"VER"} ,
		{"word":"régalent","word_nosc":"regalent","lemma":"régaler","pos":"VER"} ,
		{"word":"régaler","word_nosc":"regaler","lemma":"régaler","pos":"VER"} ,
		{"word":"régalera","word_nosc":"regalera","lemma":"régaler","pos":"VER"} ,
		{"word":"régaleraient","word_nosc":"regaleraient","lemma":"régaler","pos":"VER"} ,
		{"word":"régalerais","word_nosc":"regalerais","lemma":"régaler","pos":"VER"} ,
		{"word":"régalerait","word_nosc":"regalerait","lemma":"régaler","pos":"VER"} ,
		{"word":"régaleras","word_nosc":"regaleras","lemma":"régaler","pos":"VER"} ,
		{"word":"régalerez","word_nosc":"regalerez","lemma":"régaler","pos":"VER"} ,
		{"word":"régaleront","word_nosc":"regaleront","lemma":"régaler","pos":"VER"} ,
		{"word":"régalez","word_nosc":"regalez","lemma":"régaler","pos":"VER"} ,
		{"word":"régalions","word_nosc":"regalions","lemma":"régaler","pos":"VER"} ,
		{"word":"régalât","word_nosc":"regalat","lemma":"régaler","pos":"VER"} ,
		{"word":"régalèrent","word_nosc":"regalerent","lemma":"régaler","pos":"VER"} ,
		{"word":"régalé","word_nosc":"regale","lemma":"régaler","pos":"VER"} ,
		{"word":"régalée","word_nosc":"regalee","lemma":"régaler","pos":"VER"} ,
		{"word":"régalés","word_nosc":"regales","lemma":"régaler","pos":"VER"} ,
		{"word":"régentaient","word_nosc":"regentaient","lemma":"régenter","pos":"VER"} ,
		{"word":"régentait","word_nosc":"regentait","lemma":"régenter","pos":"VER"} ,
		{"word":"régentant","word_nosc":"regentant","lemma":"régenter","pos":"VER"} ,
		{"word":"régente","word_nosc":"regente","lemma":"régenter","pos":"VER"} ,
		{"word":"régentent","word_nosc":"regentent","lemma":"régenter","pos":"VER"} ,
		{"word":"régenter","word_nosc":"regenter","lemma":"régenter","pos":"VER"} ,
		{"word":"régenteraient","word_nosc":"regenteraient","lemma":"régenter","pos":"VER"} ,
		{"word":"régentes","word_nosc":"regentes","lemma":"régenter","pos":"VER"} ,
		{"word":"régenté","word_nosc":"regente","lemma":"régenter","pos":"VER"} ,
		{"word":"régentée","word_nosc":"regentee","lemma":"régenter","pos":"VER"} ,
		{"word":"régi","word_nosc":"regi","lemma":"régir","pos":"VER"} ,
		{"word":"régie","word_nosc":"regie","lemma":"régir","pos":"VER"} ,
		{"word":"régies","word_nosc":"regies","lemma":"régir","pos":"VER"} ,
		{"word":"régionaliser","word_nosc":"regionaliser","lemma":"régionaliser","pos":"VER"} ,
		{"word":"régir","word_nosc":"regir","lemma":"régir","pos":"VER"} ,
		{"word":"régira","word_nosc":"regira","lemma":"régir","pos":"VER"} ,
		{"word":"régiraient","word_nosc":"regiraient","lemma":"régir","pos":"VER"} ,
		{"word":"régirait","word_nosc":"regirait","lemma":"régir","pos":"VER"} ,
		{"word":"régiront","word_nosc":"regiront","lemma":"régir","pos":"VER"} ,
		{"word":"régis","word_nosc":"regis","lemma":"régir","pos":"VER"} ,
		{"word":"régissaient","word_nosc":"regissaient","lemma":"régir","pos":"VER"} ,
		{"word":"régissait","word_nosc":"regissait","lemma":"régir","pos":"VER"} ,
		{"word":"régissant","word_nosc":"regissant","lemma":"régir","pos":"VER"} ,
		{"word":"régissent","word_nosc":"regissent","lemma":"régir","pos":"VER"} ,
		{"word":"régit","word_nosc":"regit","lemma":"régir","pos":"VER"} ,
		{"word":"régla","word_nosc":"regla","lemma":"régler","pos":"VER"} ,
		{"word":"réglai","word_nosc":"reglai","lemma":"régler","pos":"VER"} ,
		{"word":"réglaient","word_nosc":"reglaient","lemma":"régler","pos":"VER"} ,
		{"word":"réglais","word_nosc":"reglais","lemma":"régler","pos":"VER"} ,
		{"word":"réglait","word_nosc":"reglait","lemma":"régler","pos":"VER"} ,
		{"word":"réglant","word_nosc":"reglant","lemma":"régler","pos":"VER"} ,
		{"word":"réglasse","word_nosc":"reglasse","lemma":"régler","pos":"VER"} ,
		{"word":"réglementait","word_nosc":"reglementait","lemma":"réglementer","pos":"VER"} ,
		{"word":"réglementant","word_nosc":"reglementant","lemma":"réglementer","pos":"VER"} ,
		{"word":"réglemente","word_nosc":"reglemente","lemma":"réglementer","pos":"VER"} ,
		{"word":"réglementer","word_nosc":"reglementer","lemma":"réglementer","pos":"VER"} ,
		{"word":"réglementé","word_nosc":"reglemente","lemma":"réglementer","pos":"VER"} ,
		{"word":"réglementée","word_nosc":"reglementee","lemma":"réglementer","pos":"VER"} ,
		{"word":"réglementées","word_nosc":"reglementees","lemma":"réglementer","pos":"VER"} ,
		{"word":"réglementés","word_nosc":"reglementes","lemma":"réglementer","pos":"VER"} ,
		{"word":"régler","word_nosc":"regler","lemma":"régler","pos":"VER"} ,
		{"word":"réglera","word_nosc":"reglera","lemma":"régler","pos":"VER"} ,
		{"word":"réglerai","word_nosc":"reglerai","lemma":"régler","pos":"VER"} ,
		{"word":"réglerais","word_nosc":"reglerais","lemma":"régler","pos":"VER"} ,
		{"word":"réglerait","word_nosc":"reglerait","lemma":"régler","pos":"VER"} ,
		{"word":"régleras","word_nosc":"regleras","lemma":"régler","pos":"VER"} ,
		{"word":"réglerez","word_nosc":"reglerez","lemma":"régler","pos":"VER"} ,
		{"word":"réglerions","word_nosc":"reglerions","lemma":"régler","pos":"VER"} ,
		{"word":"réglerons","word_nosc":"reglerons","lemma":"régler","pos":"VER"} ,
		{"word":"régleront","word_nosc":"regleront","lemma":"régler","pos":"VER"} ,
		{"word":"réglez","word_nosc":"reglez","lemma":"régler","pos":"VER"} ,
		{"word":"régliez","word_nosc":"regliez","lemma":"régler","pos":"VER"} ,
		{"word":"réglions","word_nosc":"reglions","lemma":"régler","pos":"VER"} ,
		{"word":"réglons","word_nosc":"reglons","lemma":"régler","pos":"VER"} ,
		{"word":"réglât","word_nosc":"reglat","lemma":"régler","pos":"VER"} ,
		{"word":"réglèrent","word_nosc":"reglerent","lemma":"régler","pos":"VER"} ,
		{"word":"réglé","word_nosc":"regle","lemma":"régler","pos":"VER"} ,
		{"word":"réglée","word_nosc":"reglee","lemma":"régler","pos":"VER"} ,
		{"word":"réglées","word_nosc":"reglees","lemma":"régler","pos":"VER"} ,
		{"word":"réglés","word_nosc":"regles","lemma":"régler","pos":"VER"} ,
		{"word":"régna","word_nosc":"regna","lemma":"régner","pos":"VER"} ,
		{"word":"régnaient","word_nosc":"regnaient","lemma":"régner","pos":"VER"} ,
		{"word":"régnais","word_nosc":"regnais","lemma":"régner","pos":"VER"} ,
		{"word":"régnait","word_nosc":"regnait","lemma":"régner","pos":"VER"} ,
		{"word":"régnant","word_nosc":"regnant","lemma":"régner","pos":"VER"} ,
		{"word":"régnas","word_nosc":"regnas","lemma":"régner","pos":"VER"} ,
		{"word":"régner","word_nosc":"regner","lemma":"régner","pos":"VER"} ,
		{"word":"régnera","word_nosc":"regnera","lemma":"régner","pos":"VER"} ,
		{"word":"régnerai","word_nosc":"regnerai","lemma":"régner","pos":"VER"} ,
		{"word":"régneraient","word_nosc":"regneraient","lemma":"régner","pos":"VER"} ,
		{"word":"régnerais","word_nosc":"regnerais","lemma":"régner","pos":"VER"} ,
		{"word":"régnerait","word_nosc":"regnerait","lemma":"régner","pos":"VER"} ,
		{"word":"régneras","word_nosc":"regneras","lemma":"régner","pos":"VER"} ,
		{"word":"régnerez","word_nosc":"regnerez","lemma":"régner","pos":"VER"} ,
		{"word":"régnerons","word_nosc":"regnerons","lemma":"régner","pos":"VER"} ,
		{"word":"régneront","word_nosc":"regneront","lemma":"régner","pos":"VER"} ,
		{"word":"régnez","word_nosc":"regnez","lemma":"régner","pos":"VER"} ,
		{"word":"régnions","word_nosc":"regnions","lemma":"régner","pos":"VER"} ,
		{"word":"régnons","word_nosc":"regnons","lemma":"régner","pos":"VER"} ,
		{"word":"régnèrent","word_nosc":"regnerent","lemma":"régner","pos":"VER"} ,
		{"word":"régné","word_nosc":"regne","lemma":"régner","pos":"VER"} ,
		{"word":"régressait","word_nosc":"regressait","lemma":"régresser","pos":"VER"} ,
		{"word":"régressant","word_nosc":"regressant","lemma":"régresser","pos":"VER"} ,
		{"word":"régresse","word_nosc":"regresse","lemma":"régresser","pos":"VER"} ,
		{"word":"régresser","word_nosc":"regresser","lemma":"régresser","pos":"VER"} ,
		{"word":"régressera","word_nosc":"regressera","lemma":"régresser","pos":"VER"} ,
		{"word":"régressé","word_nosc":"regresse","lemma":"régresser","pos":"VER"} ,
		{"word":"régulant","word_nosc":"regulant","lemma":"réguler","pos":"VER"} ,
		{"word":"régularisa","word_nosc":"regularisa","lemma":"régulariser","pos":"VER"} ,
		{"word":"régulariser","word_nosc":"regulariser","lemma":"régulariser","pos":"VER"} ,
		{"word":"régularisera","word_nosc":"regularisera","lemma":"régulariser","pos":"VER"} ,
		{"word":"régularisé","word_nosc":"regularise","lemma":"régulariser","pos":"VER"} ,
		{"word":"régulent","word_nosc":"regulent","lemma":"réguler","pos":"VER"} ,
		{"word":"réguler","word_nosc":"reguler","lemma":"réguler","pos":"VER"} ,
		{"word":"régulera","word_nosc":"regulera","lemma":"réguler","pos":"VER"} ,
		{"word":"régulé","word_nosc":"regule","lemma":"réguler","pos":"VER"} ,
		{"word":"régulée","word_nosc":"regulee","lemma":"réguler","pos":"VER"} ,
		{"word":"régulées","word_nosc":"regulees","lemma":"réguler","pos":"VER"} ,
		{"word":"régulés","word_nosc":"regules","lemma":"réguler","pos":"VER"} ,
		{"word":"régurgitaient","word_nosc":"regurgitaient","lemma":"régurgiter","pos":"VER"} ,
		{"word":"régurgitant","word_nosc":"regurgitant","lemma":"régurgiter","pos":"VER"} ,
		{"word":"régurgite","word_nosc":"regurgite","lemma":"régurgiter","pos":"VER"} ,
		{"word":"régurgiter","word_nosc":"regurgiter","lemma":"régurgiter","pos":"VER"} ,
		{"word":"régurgiteras","word_nosc":"regurgiteras","lemma":"régurgiter","pos":"VER"} ,
		{"word":"régurgité","word_nosc":"regurgite","lemma":"régurgiter","pos":"VER"} ,
		{"word":"régénère","word_nosc":"regenere","lemma":"régénérer","pos":"VER"} ,
		{"word":"régénèrent","word_nosc":"regenerent","lemma":"régénérer","pos":"VER"} ,
		{"word":"régénérait","word_nosc":"regenerait","lemma":"régénérer","pos":"VER"} ,
		{"word":"régénérant","word_nosc":"regenerant","lemma":"régénérer","pos":"VER"} ,
		{"word":"régénérer","word_nosc":"regenerer","lemma":"régénérer","pos":"VER"} ,
		{"word":"régénérera","word_nosc":"regenerera","lemma":"régénérer","pos":"VER"} ,
		{"word":"régénérerait","word_nosc":"regenererait","lemma":"régénérer","pos":"VER"} ,
		{"word":"régénéré","word_nosc":"regenere","lemma":"régénérer","pos":"VER"} ,
		{"word":"régénérée","word_nosc":"regeneree","lemma":"régénérer","pos":"VER"} ,
		{"word":"régénérés","word_nosc":"regeneres","lemma":"régénérer","pos":"VER"} ,
		{"word":"réhabilitaient","word_nosc":"rehabilitaient","lemma":"réhabiliter","pos":"VER"} ,
		{"word":"réhabilitait","word_nosc":"rehabilitait","lemma":"réhabiliter","pos":"VER"} ,
		{"word":"réhabilitant","word_nosc":"rehabilitant","lemma":"réhabiliter","pos":"VER"} ,
		{"word":"réhabilite","word_nosc":"rehabilite","lemma":"réhabiliter","pos":"VER"} ,
		{"word":"réhabilitent","word_nosc":"rehabilitent","lemma":"réhabiliter","pos":"VER"} ,
		{"word":"réhabiliter","word_nosc":"rehabiliter","lemma":"réhabiliter","pos":"VER"} ,
		{"word":"réhabilitera","word_nosc":"rehabilitera","lemma":"réhabiliter","pos":"VER"} ,
		{"word":"réhabiliterait","word_nosc":"rehabiliterait","lemma":"réhabiliter","pos":"VER"} ,
		{"word":"réhabilité","word_nosc":"rehabilite","lemma":"réhabiliter","pos":"VER"} ,
		{"word":"réhabilitée","word_nosc":"rehabilitee","lemma":"réhabiliter","pos":"VER"} ,
		{"word":"réhabilitées","word_nosc":"rehabilitees","lemma":"réhabiliter","pos":"VER"} ,
		{"word":"réhabilités","word_nosc":"rehabilites","lemma":"réhabiliter","pos":"VER"} ,
		{"word":"réhabituais","word_nosc":"rehabituais","lemma":"réhabituer","pos":"VER"} ,
		{"word":"réhabituait","word_nosc":"rehabituait","lemma":"réhabituer","pos":"VER"} ,
		{"word":"réhabitue","word_nosc":"rehabitue","lemma":"réhabituer","pos":"VER"} ,
		{"word":"réhabituer","word_nosc":"rehabituer","lemma":"réhabituer","pos":"VER"} ,
		{"word":"réhydrater","word_nosc":"rehydrater","lemma":"réhydrater","pos":"VER"} ,
		{"word":"réhydraté","word_nosc":"rehydrate","lemma":"réhydrater","pos":"VER"} ,
		{"word":"réifiés","word_nosc":"reifies","lemma":"réifier","pos":"VER"} ,
		{"word":"réimplanter","word_nosc":"reimplanter","lemma":"réimplanter","pos":"VER"} ,
		{"word":"réimplanté","word_nosc":"reimplante","lemma":"réimplanter","pos":"VER"} ,
		{"word":"réimplantée","word_nosc":"reimplantee","lemma":"réimplanter","pos":"VER"} ,
		{"word":"réimprima","word_nosc":"reimprima","lemma":"réimprimer","pos":"VER"} ,
		{"word":"réimprimer","word_nosc":"reimprimer","lemma":"réimprimer","pos":"VER"} ,
		{"word":"réimprimé","word_nosc":"reimprime","lemma":"réimprimer","pos":"VER"} ,
		{"word":"réincarcérer","word_nosc":"reincarcerer","lemma":"réincarcérer","pos":"VER"} ,
		{"word":"réincarnait","word_nosc":"reincarnait","lemma":"réincarner","pos":"VER"} ,
		{"word":"réincarne","word_nosc":"reincarne","lemma":"réincarner","pos":"VER"} ,
		{"word":"réincarnent","word_nosc":"reincarnent","lemma":"réincarner","pos":"VER"} ,
		{"word":"réincarner","word_nosc":"reincarner","lemma":"réincarner","pos":"VER"} ,
		{"word":"réincarnera","word_nosc":"reincarnera","lemma":"réincarner","pos":"VER"} ,
		{"word":"réincarnerait","word_nosc":"reincarnerait","lemma":"réincarner","pos":"VER"} ,
		{"word":"réincarneront","word_nosc":"reincarneront","lemma":"réincarner","pos":"VER"} ,
		{"word":"réincarnons","word_nosc":"reincarnons","lemma":"réincarner","pos":"VER"} ,
		{"word":"réincarnèrent","word_nosc":"reincarnerent","lemma":"réincarner","pos":"VER"} ,
		{"word":"réincarné","word_nosc":"reincarne","lemma":"réincarner","pos":"VER"} ,
		{"word":"réincarnée","word_nosc":"reincarnee","lemma":"réincarner","pos":"VER"} ,
		{"word":"réincarnés","word_nosc":"reincarnes","lemma":"réincarner","pos":"VER"} ,
		{"word":"réincorpora","word_nosc":"reincorpora","lemma":"réincorporer","pos":"VER"} ,
		{"word":"réincorporé","word_nosc":"reincorpore","lemma":"réincorporer","pos":"VER"} ,
		{"word":"réincorporés","word_nosc":"reincorpores","lemma":"réincorporer","pos":"VER"} ,
		{"word":"réinfecter","word_nosc":"reinfecter","lemma":"réinfecter","pos":"VER"} ,
		{"word":"réinfecté","word_nosc":"reinfecte","lemma":"réinfecter","pos":"VER"} ,
		{"word":"réinitialiser","word_nosc":"reinitialiser","lemma":"réinitialiser","pos":"VER"} ,
		{"word":"réinitialisez","word_nosc":"reinitialisez","lemma":"réinitialiser","pos":"VER"} ,
		{"word":"réinitialisé","word_nosc":"reinitialise","lemma":"réinitialiser","pos":"VER"} ,
		{"word":"réinjecter","word_nosc":"reinjecter","lemma":"réinjecter","pos":"VER"} ,
		{"word":"réinscrire","word_nosc":"reinscrire","lemma":"réinscrire","pos":"VER"} ,
		{"word":"réinscrit","word_nosc":"reinscrit","lemma":"réinscrire","pos":"VER"} ,
		{"word":"réinstalla","word_nosc":"reinstalla","lemma":"réinstaller","pos":"VER"} ,
		{"word":"réinstallaient","word_nosc":"reinstallaient","lemma":"réinstaller","pos":"VER"} ,
		{"word":"réinstallait","word_nosc":"reinstallait","lemma":"réinstaller","pos":"VER"} ,
		{"word":"réinstallant","word_nosc":"reinstallant","lemma":"réinstaller","pos":"VER"} ,
		{"word":"réinstalle","word_nosc":"reinstalle","lemma":"réinstaller","pos":"VER"} ,
		{"word":"réinstallent","word_nosc":"reinstallent","lemma":"réinstaller","pos":"VER"} ,
		{"word":"réinstaller","word_nosc":"reinstaller","lemma":"réinstaller","pos":"VER"} ,
		{"word":"réinstallez","word_nosc":"reinstallez","lemma":"réinstaller","pos":"VER"} ,
		{"word":"réinstallons","word_nosc":"reinstallons","lemma":"réinstaller","pos":"VER"} ,
		{"word":"réinstallé","word_nosc":"reinstalle","lemma":"réinstaller","pos":"VER"} ,
		{"word":"réinstallée","word_nosc":"reinstallee","lemma":"réinstaller","pos":"VER"} ,
		{"word":"réinstallées","word_nosc":"reinstallees","lemma":"réinstaller","pos":"VER"} ,
		{"word":"réinstallés","word_nosc":"reinstalles","lemma":"réinstaller","pos":"VER"} ,
		{"word":"réinstaurer","word_nosc":"reinstaurer","lemma":"réinstaurer","pos":"VER"} ,
		{"word":"réinsérer","word_nosc":"reinserer","lemma":"réinsérer","pos":"VER"} ,
		{"word":"réinséré","word_nosc":"reinsere","lemma":"réinsérer","pos":"VER"} ,
		{"word":"réinterprète","word_nosc":"reinterprete","lemma":"réinterpréter","pos":"VER"} ,
		{"word":"réinterpréter","word_nosc":"reinterpreter","lemma":"réinterpréter","pos":"VER"} ,
		{"word":"réinterroge","word_nosc":"reinterroge","lemma":"réinterroger","pos":"VER"} ,
		{"word":"réinterroger","word_nosc":"reinterroger","lemma":"réinterroger","pos":"VER"} ,
		{"word":"réintroduire","word_nosc":"reintroduire","lemma":"réintroduire","pos":"VER"} ,
		{"word":"réintroduisait","word_nosc":"reintroduisait","lemma":"réintroduire","pos":"VER"} ,
		{"word":"réintroduisit","word_nosc":"reintroduisit","lemma":"réintroduire","pos":"VER"} ,
		{"word":"réintroduit","word_nosc":"reintroduit","lemma":"réintroduire","pos":"VER"} ,
		{"word":"réintroduite","word_nosc":"reintroduite","lemma":"réintroduire","pos":"VER"} ,
		{"word":"réintègre","word_nosc":"reintegre","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintègrent","word_nosc":"reintegrent","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintégra","word_nosc":"reintegra","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintégrai","word_nosc":"reintegrai","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintégraient","word_nosc":"reintegraient","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintégrais","word_nosc":"reintegrais","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintégrait","word_nosc":"reintegrait","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintégrant","word_nosc":"reintegrant","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintégrer","word_nosc":"reintegrer","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintégrerait","word_nosc":"reintegrerait","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintégrez","word_nosc":"reintegrez","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintégrions","word_nosc":"reintegrions","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintégrons","word_nosc":"reintegrons","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintégrèrent","word_nosc":"reintegrerent","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintégré","word_nosc":"reintegre","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintégrée","word_nosc":"reintegree","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réintégrés","word_nosc":"reintegres","lemma":"réintégrer","pos":"VER"} ,
		{"word":"réinventa","word_nosc":"reinventa","lemma":"réinventer","pos":"VER"} ,
		{"word":"réinventaient","word_nosc":"reinventaient","lemma":"réinventer","pos":"VER"} ,
		{"word":"réinventait","word_nosc":"reinventait","lemma":"réinventer","pos":"VER"} ,
		{"word":"réinventant","word_nosc":"reinventant","lemma":"réinventer","pos":"VER"} ,
		{"word":"réinvente","word_nosc":"reinvente","lemma":"réinventer","pos":"VER"} ,
		{"word":"réinventent","word_nosc":"reinventent","lemma":"réinventer","pos":"VER"} ,
		{"word":"réinventer","word_nosc":"reinventer","lemma":"réinventer","pos":"VER"} ,
		{"word":"réinventeraient","word_nosc":"reinventeraient","lemma":"réinventer","pos":"VER"} ,
		{"word":"réinventé","word_nosc":"reinvente","lemma":"réinventer","pos":"VER"} ,
		{"word":"réinventées","word_nosc":"reinventees","lemma":"réinventer","pos":"VER"} ,
		{"word":"réinvesti","word_nosc":"reinvesti","lemma":"réinvestir","pos":"VER"} ,
		{"word":"réinvestir","word_nosc":"reinvestir","lemma":"réinvestir","pos":"VER"} ,
		{"word":"réinvestirent","word_nosc":"reinvestirent","lemma":"réinvestir","pos":"VER"} ,
		{"word":"réinvestis","word_nosc":"reinvestis","lemma":"réinvestir","pos":"VER"} ,
		{"word":"réinvestissait","word_nosc":"reinvestissait","lemma":"réinvestir","pos":"VER"} ,
		{"word":"réinvestissent","word_nosc":"reinvestissent","lemma":"réinvestir","pos":"VER"} ,
		{"word":"réinvestit","word_nosc":"reinvestit","lemma":"réinvestir","pos":"VER"} ,
		{"word":"réinvita","word_nosc":"reinvita","lemma":"réinviter","pos":"VER"} ,
		{"word":"réinvite","word_nosc":"reinvite","lemma":"réinviter","pos":"VER"} ,
		{"word":"réinviter","word_nosc":"reinviter","lemma":"réinviter","pos":"VER"} ,
		{"word":"réinviterai","word_nosc":"reinviterai","lemma":"réinviter","pos":"VER"} ,
		{"word":"réitère","word_nosc":"reitere","lemma":"réitérer","pos":"VER"} ,
		{"word":"réitéra","word_nosc":"reitera","lemma":"réitérer","pos":"VER"} ,
		{"word":"réitérait","word_nosc":"reiterait","lemma":"réitérer","pos":"VER"} ,
		{"word":"réitérant","word_nosc":"reiterant","lemma":"réitérer","pos":"VER"} ,
		{"word":"réitérer","word_nosc":"reiterer","lemma":"réitérer","pos":"VER"} ,
		{"word":"réitérera","word_nosc":"reiterera","lemma":"réitérer","pos":"VER"} ,
		{"word":"réitérèrent","word_nosc":"reitererent","lemma":"réitérer","pos":"VER"} ,
		{"word":"réitéré","word_nosc":"reitere","lemma":"réitérer","pos":"VER"} ,
		{"word":"réitérée","word_nosc":"reiteree","lemma":"réitérer","pos":"VER"} ,
		{"word":"réitérées","word_nosc":"reiterees","lemma":"réitérer","pos":"VER"} ,
		{"word":"réitérés","word_nosc":"reiteres","lemma":"réitérer","pos":"VER"} ,
		{"word":"réjoui","word_nosc":"rejoui","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouie","word_nosc":"rejouie","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouies","word_nosc":"rejouies","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouir","word_nosc":"rejouir","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouira","word_nosc":"rejouira","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouirai","word_nosc":"rejouirai","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouirais","word_nosc":"rejouirais","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouirait","word_nosc":"rejouirait","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouirent","word_nosc":"rejouirent","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouirez","word_nosc":"rejouirez","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouirons","word_nosc":"rejouirons","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouiront","word_nosc":"rejouiront","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouis","word_nosc":"rejouis","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouissaient","word_nosc":"rejouissaient","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouissais","word_nosc":"rejouissais","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouissait","word_nosc":"rejouissait","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouissant","word_nosc":"rejouissant","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouisse","word_nosc":"rejouisse","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouissent","word_nosc":"rejouissent","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouissez","word_nosc":"rejouissez","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouissiez","word_nosc":"rejouissiez","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouissions","word_nosc":"rejouissions","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouissons","word_nosc":"rejouissons","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouit","word_nosc":"rejouit","lemma":"réjouir","pos":"VER"} ,
		{"word":"réjouît","word_nosc":"rejouit","lemma":"réjouir","pos":"VER"} ,
		{"word":"rémunère","word_nosc":"remunere","lemma":"rémunérer","pos":"VER"} ,
		{"word":"rémunéraient","word_nosc":"remuneraient","lemma":"rémunérer","pos":"VER"} ,
		{"word":"rémunérer","word_nosc":"remunerer","lemma":"rémunérer","pos":"VER"} ,
		{"word":"rémunéré","word_nosc":"remunere","lemma":"rémunérer","pos":"VER"} ,
		{"word":"rémunérées","word_nosc":"remunerees","lemma":"rémunérer","pos":"VER"} ,
		{"word":"rémunérés","word_nosc":"remuneres","lemma":"rémunérer","pos":"VER"} ,
		{"word":"rénovait","word_nosc":"renovait","lemma":"rénover","pos":"VER"} ,
		{"word":"rénovant","word_nosc":"renovant","lemma":"rénover","pos":"VER"} ,
		{"word":"rénove","word_nosc":"renove","lemma":"rénover","pos":"VER"} ,
		{"word":"rénover","word_nosc":"renover","lemma":"rénover","pos":"VER"} ,
		{"word":"rénové","word_nosc":"renove","lemma":"rénover","pos":"VER"} ,
		{"word":"rénovée","word_nosc":"renovee","lemma":"rénover","pos":"VER"} ,
		{"word":"rénovées","word_nosc":"renovees","lemma":"rénover","pos":"VER"} ,
		{"word":"rénovés","word_nosc":"renoves","lemma":"rénover","pos":"VER"} ,
		{"word":"réoccupait","word_nosc":"reoccupait","lemma":"réoccuper","pos":"VER"} ,
		{"word":"réoccuper","word_nosc":"reoccuper","lemma":"réoccuper","pos":"VER"} ,
		{"word":"réoccupons","word_nosc":"reoccupons","lemma":"réoccuper","pos":"VER"} ,
		{"word":"réopère","word_nosc":"reopere","lemma":"réopérer","pos":"VER"} ,
		{"word":"réopérer","word_nosc":"reoperer","lemma":"réopérer","pos":"VER"} ,
		{"word":"réordonnant","word_nosc":"reordonnant","lemma":"réordonner","pos":"VER"} ,
		{"word":"réordonner","word_nosc":"reordonner","lemma":"réordonner","pos":"VER"} ,
		{"word":"réorganisa","word_nosc":"reorganisa","lemma":"réorganiser","pos":"VER"} ,
		{"word":"réorganisait","word_nosc":"reorganisait","lemma":"réorganiser","pos":"VER"} ,
		{"word":"réorganisant","word_nosc":"reorganisant","lemma":"réorganiser","pos":"VER"} ,
		{"word":"réorganise","word_nosc":"reorganise","lemma":"réorganiser","pos":"VER"} ,
		{"word":"réorganiser","word_nosc":"reorganiser","lemma":"réorganiser","pos":"VER"} ,
		{"word":"réorganisez","word_nosc":"reorganisez","lemma":"réorganiser","pos":"VER"} ,
		{"word":"réorganisé","word_nosc":"reorganise","lemma":"réorganiser","pos":"VER"} ,
		{"word":"réorganisées","word_nosc":"reorganisees","lemma":"réorganiser","pos":"VER"} ,
		{"word":"réorganisés","word_nosc":"reorganises","lemma":"réorganiser","pos":"VER"} ,
		{"word":"réorienter","word_nosc":"reorienter","lemma":"réorienter","pos":"VER"} ,
		{"word":"réorienté","word_nosc":"reoriente","lemma":"réorienter","pos":"VER"} ,
		{"word":"répand","word_nosc":"repand","lemma":"répandre","pos":"VER"} ,
		{"word":"répandaient","word_nosc":"repandaient","lemma":"répandre","pos":"VER"} ,
		{"word":"répandais","word_nosc":"repandais","lemma":"répandre","pos":"VER"} ,
		{"word":"répandait","word_nosc":"repandait","lemma":"répandre","pos":"VER"} ,
		{"word":"répandant","word_nosc":"repandant","lemma":"répandre","pos":"VER"} ,
		{"word":"répande","word_nosc":"repande","lemma":"répandre","pos":"VER"} ,
		{"word":"répandent","word_nosc":"repandent","lemma":"répandre","pos":"VER"} ,
		{"word":"répandez","word_nosc":"repandez","lemma":"répandre","pos":"VER"} ,
		{"word":"répandirent","word_nosc":"repandirent","lemma":"répandre","pos":"VER"} ,
		{"word":"répandit","word_nosc":"repandit","lemma":"répandre","pos":"VER"} ,
		{"word":"répandons","word_nosc":"repandons","lemma":"répandre","pos":"VER"} ,
		{"word":"répandra","word_nosc":"repandra","lemma":"répandre","pos":"VER"} ,
		{"word":"répandrai","word_nosc":"repandrai","lemma":"répandre","pos":"VER"} ,
		{"word":"répandraient","word_nosc":"repandraient","lemma":"répandre","pos":"VER"} ,
		{"word":"répandrait","word_nosc":"repandrait","lemma":"répandre","pos":"VER"} ,
		{"word":"répandras","word_nosc":"repandras","lemma":"répandre","pos":"VER"} ,
		{"word":"répandre","word_nosc":"repandre","lemma":"répandre","pos":"VER"} ,
		{"word":"répandrez","word_nosc":"repandrez","lemma":"répandre","pos":"VER"} ,
		{"word":"répandrons","word_nosc":"repandrons","lemma":"répandre","pos":"VER"} ,
		{"word":"répandront","word_nosc":"repandront","lemma":"répandre","pos":"VER"} ,
		{"word":"répands","word_nosc":"repands","lemma":"répandre","pos":"VER"} ,
		{"word":"répandu","word_nosc":"repandu","lemma":"répandre","pos":"VER"} ,
		{"word":"répandue","word_nosc":"repandue","lemma":"répandre","pos":"VER"} ,
		{"word":"répandues","word_nosc":"repandues","lemma":"répandre","pos":"VER"} ,
		{"word":"répandus","word_nosc":"repandus","lemma":"répandre","pos":"VER"} ,
		{"word":"répandît","word_nosc":"repandit","lemma":"répandre","pos":"VER"} ,
		{"word":"répara","word_nosc":"repara","lemma":"réparer","pos":"VER"} ,
		{"word":"réparaient","word_nosc":"reparaient","lemma":"réparer","pos":"VER"} ,
		{"word":"réparais","word_nosc":"reparais","lemma":"réparer","pos":"VER"} ,
		{"word":"réparait","word_nosc":"reparait","lemma":"réparer","pos":"VER"} ,
		{"word":"réparant","word_nosc":"reparant","lemma":"réparer","pos":"VER"} ,
		{"word":"répare","word_nosc":"repare","lemma":"réparer","pos":"VER"} ,
		{"word":"réparent","word_nosc":"reparent","lemma":"réparer","pos":"VER"} ,
		{"word":"réparer","word_nosc":"reparer","lemma":"réparer","pos":"VER"} ,
		{"word":"réparera","word_nosc":"reparera","lemma":"réparer","pos":"VER"} ,
		{"word":"réparerai","word_nosc":"reparerai","lemma":"réparer","pos":"VER"} ,
		{"word":"répareraient","word_nosc":"repareraient","lemma":"réparer","pos":"VER"} ,
		{"word":"réparerait","word_nosc":"reparerait","lemma":"réparer","pos":"VER"} ,
		{"word":"répareras","word_nosc":"repareras","lemma":"réparer","pos":"VER"} ,
		{"word":"réparerez","word_nosc":"reparerez","lemma":"réparer","pos":"VER"} ,
		{"word":"réparerons","word_nosc":"reparerons","lemma":"réparer","pos":"VER"} ,
		{"word":"répareront","word_nosc":"repareront","lemma":"réparer","pos":"VER"} ,
		{"word":"réparez","word_nosc":"reparez","lemma":"réparer","pos":"VER"} ,
		{"word":"répariez","word_nosc":"repariez","lemma":"réparer","pos":"VER"} ,
		{"word":"réparions","word_nosc":"reparions","lemma":"réparer","pos":"VER"} ,
		{"word":"réparons","word_nosc":"reparons","lemma":"réparer","pos":"VER"} ,
		{"word":"réparti","word_nosc":"reparti","lemma":"répartir","pos":"VER"} ,
		{"word":"répartie","word_nosc":"repartie","lemma":"répartir","pos":"VER"} ,
		{"word":"réparties","word_nosc":"reparties","lemma":"répartir","pos":"VER"} ,
		{"word":"répartir","word_nosc":"repartir","lemma":"répartir","pos":"VER"} ,
		{"word":"répartirent","word_nosc":"repartirent","lemma":"répartir","pos":"VER"} ,
		{"word":"répartiront","word_nosc":"repartiront","lemma":"répartir","pos":"VER"} ,
		{"word":"répartis","word_nosc":"repartis","lemma":"répartir","pos":"VER"} ,
		{"word":"répartissaient","word_nosc":"repartissaient","lemma":"répartir","pos":"VER"} ,
		{"word":"répartissait","word_nosc":"repartissait","lemma":"répartir","pos":"VER"} ,
		{"word":"répartissant","word_nosc":"repartissant","lemma":"répartir","pos":"VER"} ,
		{"word":"répartissent","word_nosc":"repartissent","lemma":"répartir","pos":"VER"} ,
		{"word":"répartissez","word_nosc":"repartissez","lemma":"répartir","pos":"VER"} ,
		{"word":"répartissons","word_nosc":"repartissons","lemma":"répartir","pos":"VER"} ,
		{"word":"répartit","word_nosc":"repartit","lemma":"répartir","pos":"VER"} ,
		{"word":"répartîmes","word_nosc":"repartimes","lemma":"répartir","pos":"VER"} ,
		{"word":"réparèrent","word_nosc":"reparerent","lemma":"réparer","pos":"VER"} ,
		{"word":"réparé","word_nosc":"repare","lemma":"réparer","pos":"VER"} ,
		{"word":"réparée","word_nosc":"reparee","lemma":"réparer","pos":"VER"} ,
		{"word":"réparées","word_nosc":"reparees","lemma":"réparer","pos":"VER"} ,
		{"word":"réparés","word_nosc":"repares","lemma":"réparer","pos":"VER"} ,
		{"word":"répercuta","word_nosc":"repercuta","lemma":"répercuter","pos":"VER"} ,
		{"word":"répercutaient","word_nosc":"repercutaient","lemma":"répercuter","pos":"VER"} ,
		{"word":"répercutait","word_nosc":"repercutait","lemma":"répercuter","pos":"VER"} ,
		{"word":"répercutant","word_nosc":"repercutant","lemma":"répercuter","pos":"VER"} ,
		{"word":"répercute","word_nosc":"repercute","lemma":"répercuter","pos":"VER"} ,
		{"word":"répercutent","word_nosc":"repercutent","lemma":"répercuter","pos":"VER"} ,
		{"word":"répercuter","word_nosc":"repercuter","lemma":"répercuter","pos":"VER"} ,
		{"word":"répercuterait","word_nosc":"repercuterait","lemma":"répercuter","pos":"VER"} ,
		{"word":"répercuteront","word_nosc":"repercuteront","lemma":"répercuter","pos":"VER"} ,
		{"word":"répercutons","word_nosc":"repercutons","lemma":"répercuter","pos":"VER"} ,
		{"word":"répercutèrent","word_nosc":"repercuterent","lemma":"répercuter","pos":"VER"} ,
		{"word":"répercuté","word_nosc":"repercute","lemma":"répercuter","pos":"VER"} ,
		{"word":"répercutée","word_nosc":"repercutee","lemma":"répercuter","pos":"VER"} ,
		{"word":"répercutées","word_nosc":"repercutees","lemma":"répercuter","pos":"VER"} ,
		{"word":"répercutés","word_nosc":"repercutes","lemma":"répercuter","pos":"VER"} ,
		{"word":"répertoriant","word_nosc":"repertoriant","lemma":"répertorier","pos":"VER"} ,
		{"word":"répertorier","word_nosc":"repertorier","lemma":"répertorier","pos":"VER"} ,
		{"word":"répertorié","word_nosc":"repertorie","lemma":"répertorier","pos":"VER"} ,
		{"word":"répertoriée","word_nosc":"repertoriee","lemma":"répertorier","pos":"VER"} ,
		{"word":"répertoriées","word_nosc":"repertoriees","lemma":"répertorier","pos":"VER"} ,
		{"word":"répertoriés","word_nosc":"repertories","lemma":"répertorier","pos":"VER"} ,
		{"word":"répliqua","word_nosc":"repliqua","lemma":"répliquer","pos":"VER"} ,
		{"word":"répliquai","word_nosc":"repliquai","lemma":"répliquer","pos":"VER"} ,
		{"word":"répliquaient","word_nosc":"repliquaient","lemma":"répliquer","pos":"VER"} ,
		{"word":"répliquais","word_nosc":"repliquais","lemma":"répliquer","pos":"VER"} ,
		{"word":"répliquait","word_nosc":"repliquait","lemma":"répliquer","pos":"VER"} ,
		{"word":"répliquant","word_nosc":"repliquant","lemma":"répliquer","pos":"VER"} ,
		{"word":"réplique","word_nosc":"replique","lemma":"répliquer","pos":"VER"} ,
		{"word":"répliquent","word_nosc":"repliquent","lemma":"répliquer","pos":"VER"} ,
		{"word":"répliquer","word_nosc":"repliquer","lemma":"répliquer","pos":"VER"} ,
		{"word":"répliquera","word_nosc":"repliquera","lemma":"répliquer","pos":"VER"} ,
		{"word":"répliquerons","word_nosc":"repliquerons","lemma":"répliquer","pos":"VER"} ,
		{"word":"répliqueront","word_nosc":"repliqueront","lemma":"répliquer","pos":"VER"} ,
		{"word":"répliques","word_nosc":"repliques","lemma":"répliquer","pos":"VER"} ,
		{"word":"répliquât","word_nosc":"repliquat","lemma":"répliquer","pos":"VER"} ,
		{"word":"répliquèrent","word_nosc":"repliquerent","lemma":"répliquer","pos":"VER"} ,
		{"word":"répliqué","word_nosc":"replique","lemma":"répliquer","pos":"VER"} ,
		{"word":"répond","word_nosc":"repond","lemma":"répondre","pos":"VER"} ,
		{"word":"répondaient","word_nosc":"repondaient","lemma":"répondre","pos":"VER"} ,
		{"word":"répondais","word_nosc":"repondais","lemma":"répondre","pos":"VER"} ,
		{"word":"répondait","word_nosc":"repondait","lemma":"répondre","pos":"VER"} ,
		{"word":"répondant","word_nosc":"repondant","lemma":"répondre","pos":"VER"} ,
		{"word":"réponde","word_nosc":"reponde","lemma":"répondre","pos":"VER"} ,
		{"word":"répondent","word_nosc":"repondent","lemma":"répondre","pos":"VER"} ,
		{"word":"répondes","word_nosc":"repondes","lemma":"répondre","pos":"VER"} ,
		{"word":"répondez","word_nosc":"repondez","lemma":"répondre","pos":"VER"} ,
		{"word":"répondiez","word_nosc":"repondiez","lemma":"répondre","pos":"VER"} ,
		{"word":"répondions","word_nosc":"repondions","lemma":"répondre","pos":"VER"} ,
		{"word":"répondirent","word_nosc":"repondirent","lemma":"répondre","pos":"VER"} ,
		{"word":"répondis","word_nosc":"repondis","lemma":"répondre","pos":"VER"} ,
		{"word":"répondit","word_nosc":"repondit","lemma":"répondre","pos":"VER"} ,
		{"word":"répondons","word_nosc":"repondons","lemma":"répondre","pos":"VER"} ,
		{"word":"répondra","word_nosc":"repondra","lemma":"répondre","pos":"VER"} ,
		{"word":"répondrai","word_nosc":"repondrai","lemma":"répondre","pos":"VER"} ,
		{"word":"répondraient","word_nosc":"repondraient","lemma":"répondre","pos":"VER"} ,
		{"word":"répondrais","word_nosc":"repondrais","lemma":"répondre","pos":"VER"} ,
		{"word":"répondrait","word_nosc":"repondrait","lemma":"répondre","pos":"VER"} ,
		{"word":"répondras","word_nosc":"repondras","lemma":"répondre","pos":"VER"} ,
		{"word":"répondre","word_nosc":"repondre","lemma":"répondre","pos":"VER"} ,
		{"word":"répondrez","word_nosc":"repondrez","lemma":"répondre","pos":"VER"} ,
		{"word":"répondriez","word_nosc":"repondriez","lemma":"répondre","pos":"VER"} ,
		{"word":"répondrons","word_nosc":"repondrons","lemma":"répondre","pos":"VER"} ,
		{"word":"répondront","word_nosc":"repondront","lemma":"répondre","pos":"VER"} ,
		{"word":"réponds","word_nosc":"reponds","lemma":"répondre","pos":"VER"} ,
		{"word":"répondu","word_nosc":"repondu","lemma":"répondre","pos":"VER"} ,
		{"word":"répondues","word_nosc":"repondues","lemma":"répondre","pos":"VER"} ,
		{"word":"répondîmes","word_nosc":"repondimes","lemma":"répondre","pos":"VER"} ,
		{"word":"répondît","word_nosc":"repondit","lemma":"répondre","pos":"VER"} ,
		{"word":"réprima","word_nosc":"reprima","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprimai","word_nosc":"reprimai","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprimaient","word_nosc":"reprimaient","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprimais","word_nosc":"reprimais","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprimait","word_nosc":"reprimait","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprimanda","word_nosc":"reprimanda","lemma":"réprimander","pos":"VER"} ,
		{"word":"réprimandait","word_nosc":"reprimandait","lemma":"réprimander","pos":"VER"} ,
		{"word":"réprimande","word_nosc":"reprimande","lemma":"réprimander","pos":"VER"} ,
		{"word":"réprimander","word_nosc":"reprimander","lemma":"réprimander","pos":"VER"} ,
		{"word":"réprimandiez","word_nosc":"reprimandiez","lemma":"réprimander","pos":"VER"} ,
		{"word":"réprimandé","word_nosc":"reprimande","lemma":"réprimander","pos":"VER"} ,
		{"word":"réprimandée","word_nosc":"reprimandee","lemma":"réprimander","pos":"VER"} ,
		{"word":"réprimant","word_nosc":"reprimant","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprime","word_nosc":"reprime","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprimer","word_nosc":"reprimer","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprimerai","word_nosc":"reprimerai","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprimerait","word_nosc":"reprimerait","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprimerez","word_nosc":"reprimerez","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprimâmes","word_nosc":"reprimames","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprimèrent","word_nosc":"reprimerent","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprimé","word_nosc":"reprime","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprimée","word_nosc":"reprimee","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprimées","word_nosc":"reprimees","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprimés","word_nosc":"reprimes","lemma":"réprimer","pos":"VER"} ,
		{"word":"réprouvais","word_nosc":"reprouvais","lemma":"réprouver","pos":"VER"} ,
		{"word":"réprouvait","word_nosc":"reprouvait","lemma":"réprouver","pos":"VER"} ,
		{"word":"réprouvant","word_nosc":"reprouvant","lemma":"réprouver","pos":"VER"} ,
		{"word":"réprouve","word_nosc":"reprouve","lemma":"réprouver","pos":"VER"} ,
		{"word":"réprouvent","word_nosc":"reprouvent","lemma":"réprouver","pos":"VER"} ,
		{"word":"réprouver","word_nosc":"reprouver","lemma":"réprouver","pos":"VER"} ,
		{"word":"réprouverait","word_nosc":"reprouverait","lemma":"réprouver","pos":"VER"} ,
		{"word":"réprouveront","word_nosc":"reprouveront","lemma":"réprouver","pos":"VER"} ,
		{"word":"réprouves","word_nosc":"reprouves","lemma":"réprouver","pos":"VER"} ,
		{"word":"réprouvez","word_nosc":"reprouvez","lemma":"réprouver","pos":"VER"} ,
		{"word":"réprouvons","word_nosc":"reprouvons","lemma":"réprouver","pos":"VER"} ,
		{"word":"réprouvât","word_nosc":"reprouvat","lemma":"réprouver","pos":"VER"} ,
		{"word":"réprouvé","word_nosc":"reprouve","lemma":"réprouver","pos":"VER"} ,
		{"word":"réprouvées","word_nosc":"reprouvees","lemma":"réprouver","pos":"VER"} ,
		{"word":"réprouvés","word_nosc":"reprouves","lemma":"réprouver","pos":"VER"} ,
		{"word":"répudiant","word_nosc":"repudiant","lemma":"répudier","pos":"VER"} ,
		{"word":"répudie","word_nosc":"repudie","lemma":"répudier","pos":"VER"} ,
		{"word":"répudier","word_nosc":"repudier","lemma":"répudier","pos":"VER"} ,
		{"word":"répudiera","word_nosc":"repudiera","lemma":"répudier","pos":"VER"} ,
		{"word":"répudierai","word_nosc":"repudierai","lemma":"répudier","pos":"VER"} ,
		{"word":"répudies","word_nosc":"repudies","lemma":"répudier","pos":"VER"} ,
		{"word":"répudié","word_nosc":"repudie","lemma":"répudier","pos":"VER"} ,
		{"word":"répudiée","word_nosc":"repudiee","lemma":"répudier","pos":"VER"} ,
		{"word":"répudiées","word_nosc":"repudiees","lemma":"répudier","pos":"VER"} ,
		{"word":"répudiés","word_nosc":"repudies","lemma":"répudier","pos":"VER"} ,
		{"word":"répugna","word_nosc":"repugna","lemma":"répugner","pos":"VER"} ,
		{"word":"répugnai","word_nosc":"repugnai","lemma":"répugner","pos":"VER"} ,
		{"word":"répugnaient","word_nosc":"repugnaient","lemma":"répugner","pos":"VER"} ,
		{"word":"répugnais","word_nosc":"repugnais","lemma":"répugner","pos":"VER"} ,
		{"word":"répugnait","word_nosc":"repugnait","lemma":"répugner","pos":"VER"} ,
		{"word":"répugnant","word_nosc":"repugnant","lemma":"répugner","pos":"VER"} ,
		{"word":"répugne","word_nosc":"repugne","lemma":"répugner","pos":"VER"} ,
		{"word":"répugnent","word_nosc":"repugnent","lemma":"répugner","pos":"VER"} ,
		{"word":"répugner","word_nosc":"repugner","lemma":"répugner","pos":"VER"} ,
		{"word":"répugnerait","word_nosc":"repugnerait","lemma":"répugner","pos":"VER"} ,
		{"word":"répugneront","word_nosc":"repugneront","lemma":"répugner","pos":"VER"} ,
		{"word":"répugnes","word_nosc":"repugnes","lemma":"répugner","pos":"VER"} ,
		{"word":"répugnez","word_nosc":"repugnez","lemma":"répugner","pos":"VER"} ,
		{"word":"répugniez","word_nosc":"repugniez","lemma":"répugner","pos":"VER"} ,
		{"word":"répugnons","word_nosc":"repugnons","lemma":"répugner","pos":"VER"} ,
		{"word":"répugnât","word_nosc":"repugnat","lemma":"répugner","pos":"VER"} ,
		{"word":"répugné","word_nosc":"repugne","lemma":"répugner","pos":"VER"} ,
		{"word":"répugnée","word_nosc":"repugnee","lemma":"répugner","pos":"VER"} ,
		{"word":"réputaient","word_nosc":"reputaient","lemma":"réputer","pos":"VER"} ,
		{"word":"réputait","word_nosc":"reputait","lemma":"réputer","pos":"VER"} ,
		{"word":"répute","word_nosc":"repute","lemma":"réputer","pos":"VER"} ,
		{"word":"réputent","word_nosc":"reputent","lemma":"réputer","pos":"VER"} ,
		{"word":"réputer","word_nosc":"reputer","lemma":"réputer","pos":"VER"} ,
		{"word":"réputé","word_nosc":"repute","lemma":"réputer","pos":"VER"} ,
		{"word":"réputée","word_nosc":"reputee","lemma":"réputer","pos":"VER"} ,
		{"word":"réputées","word_nosc":"reputees","lemma":"réputer","pos":"VER"} ,
		{"word":"réputés","word_nosc":"reputes","lemma":"réputer","pos":"VER"} ,
		{"word":"répète","word_nosc":"repete","lemma":"répéter","pos":"VER"} ,
		{"word":"répètent","word_nosc":"repetent","lemma":"répéter","pos":"VER"} ,
		{"word":"répètes","word_nosc":"repetes","lemma":"répéter","pos":"VER"} ,
		{"word":"répéta","word_nosc":"repeta","lemma":"répéter","pos":"VER"} ,
		{"word":"répétai","word_nosc":"repetai","lemma":"répéter","pos":"VER"} ,
		{"word":"répétaient","word_nosc":"repetaient","lemma":"répéter","pos":"VER"} ,
		{"word":"répétais","word_nosc":"repetais","lemma":"répéter","pos":"VER"} ,
		{"word":"répétait","word_nosc":"repetait","lemma":"répéter","pos":"VER"} ,
		{"word":"répétant","word_nosc":"repetant","lemma":"répéter","pos":"VER"} ,
		{"word":"répéter","word_nosc":"repeter","lemma":"répéter","pos":"VER"} ,
		{"word":"répétera","word_nosc":"repetera","lemma":"répéter","pos":"VER"} ,
		{"word":"répéterai","word_nosc":"repeterai","lemma":"répéter","pos":"VER"} ,
		{"word":"répéteraient","word_nosc":"repeteraient","lemma":"répéter","pos":"VER"} ,
		{"word":"répéterais","word_nosc":"repeterais","lemma":"répéter","pos":"VER"} ,
		{"word":"répéterait","word_nosc":"repeterait","lemma":"répéter","pos":"VER"} ,
		{"word":"répéteras","word_nosc":"repeteras","lemma":"répéter","pos":"VER"} ,
		{"word":"répéterez","word_nosc":"repeterez","lemma":"répéter","pos":"VER"} ,
		{"word":"répéteriez","word_nosc":"repeteriez","lemma":"répéter","pos":"VER"} ,
		{"word":"répétez","word_nosc":"repetez","lemma":"répéter","pos":"VER"} ,
		{"word":"répétiez","word_nosc":"repetiez","lemma":"répéter","pos":"VER"} ,
		{"word":"répétions","word_nosc":"repetions","lemma":"répéter","pos":"VER"} ,
		{"word":"répétons","word_nosc":"repetons","lemma":"répéter","pos":"VER"} ,
		{"word":"répétât","word_nosc":"repetat","lemma":"répéter","pos":"VER"} ,
		{"word":"répétèrent","word_nosc":"repeterent","lemma":"répéter","pos":"VER"} ,
		{"word":"répété","word_nosc":"repete","lemma":"répéter","pos":"VER"} ,
		{"word":"répétée","word_nosc":"repetee","lemma":"répéter","pos":"VER"} ,
		{"word":"répétées","word_nosc":"repetees","lemma":"répéter","pos":"VER"} ,
		{"word":"répétés","word_nosc":"repetes","lemma":"répéter","pos":"VER"} ,
		{"word":"réquisitionnais","word_nosc":"requisitionnais","lemma":"réquisitionner","pos":"VER"} ,
		{"word":"réquisitionnait","word_nosc":"requisitionnait","lemma":"réquisitionner","pos":"VER"} ,
		{"word":"réquisitionnant","word_nosc":"requisitionnant","lemma":"réquisitionner","pos":"VER"} ,
		{"word":"réquisitionne","word_nosc":"requisitionne","lemma":"réquisitionner","pos":"VER"} ,
		{"word":"réquisitionner","word_nosc":"requisitionner","lemma":"réquisitionner","pos":"VER"} ,
		{"word":"réquisitionnez","word_nosc":"requisitionnez","lemma":"réquisitionner","pos":"VER"} ,
		{"word":"réquisitionnons","word_nosc":"requisitionnons","lemma":"réquisitionner","pos":"VER"} ,
		{"word":"réquisitionné","word_nosc":"requisitionne","lemma":"réquisitionner","pos":"VER"} ,
		{"word":"réquisitionnée","word_nosc":"requisitionnee","lemma":"réquisitionner","pos":"VER"} ,
		{"word":"réquisitionnées","word_nosc":"requisitionnees","lemma":"réquisitionner","pos":"VER"} ,
		{"word":"réquisitionnés","word_nosc":"requisitionnes","lemma":"réquisitionner","pos":"VER"} ,
		{"word":"réserva","word_nosc":"reserva","lemma":"réserver","pos":"VER"} ,
		{"word":"réservai","word_nosc":"reservai","lemma":"réserver","pos":"VER"} ,
		{"word":"réservaient","word_nosc":"reservaient","lemma":"réserver","pos":"VER"} ,
		{"word":"réservais","word_nosc":"reservais","lemma":"réserver","pos":"VER"} ,
		{"word":"réservait","word_nosc":"reservait","lemma":"réserver","pos":"VER"} ,
		{"word":"réservant","word_nosc":"reservant","lemma":"réserver","pos":"VER"} ,
		{"word":"réserve","word_nosc":"reserve","lemma":"réserver","pos":"VER"} ,
		{"word":"réservent","word_nosc":"reservent","lemma":"réserver","pos":"VER"} ,
		{"word":"réserver","word_nosc":"reserver","lemma":"réserver","pos":"VER"} ,
		{"word":"réservera","word_nosc":"reservera","lemma":"réserver","pos":"VER"} ,
		{"word":"réserverai","word_nosc":"reserverai","lemma":"réserver","pos":"VER"} ,
		{"word":"réserverait","word_nosc":"reserverait","lemma":"réserver","pos":"VER"} ,
		{"word":"réserveriez","word_nosc":"reserveriez","lemma":"réserver","pos":"VER"} ,
		{"word":"réserveront","word_nosc":"reserveront","lemma":"réserver","pos":"VER"} ,
		{"word":"réserves","word_nosc":"reserves","lemma":"réserver","pos":"VER"} ,
		{"word":"réservez","word_nosc":"reservez","lemma":"réserver","pos":"VER"} ,
		{"word":"réserviez","word_nosc":"reserviez","lemma":"réserver","pos":"VER"} ,
		{"word":"réservions","word_nosc":"reservions","lemma":"réserver","pos":"VER"} ,
		{"word":"réservons","word_nosc":"reservons","lemma":"réserver","pos":"VER"} ,
		{"word":"réservât","word_nosc":"reservat","lemma":"réserver","pos":"VER"} ,
		{"word":"réservèrent","word_nosc":"reserverent","lemma":"réserver","pos":"VER"} ,
		{"word":"réservé","word_nosc":"reserve","lemma":"réserver","pos":"VER"} ,
		{"word":"réservée","word_nosc":"reservee","lemma":"réserver","pos":"VER"} ,
		{"word":"réservées","word_nosc":"reservees","lemma":"réserver","pos":"VER"} ,
		{"word":"réservés","word_nosc":"reserves","lemma":"réserver","pos":"VER"} ,
		{"word":"résidaient","word_nosc":"residaient","lemma":"résider","pos":"VER"} ,
		{"word":"résidais","word_nosc":"residais","lemma":"résider","pos":"VER"} ,
		{"word":"résidait","word_nosc":"residait","lemma":"résider","pos":"VER"} ,
		{"word":"résidant","word_nosc":"residant","lemma":"résider","pos":"VER"} ,
		{"word":"réside","word_nosc":"reside","lemma":"résider","pos":"VER"} ,
		{"word":"résident","word_nosc":"resident","lemma":"résider","pos":"VER"} ,
		{"word":"résider","word_nosc":"resider","lemma":"résider","pos":"VER"} ,
		{"word":"résidera","word_nosc":"residera","lemma":"résider","pos":"VER"} ,
		{"word":"résiderez","word_nosc":"residerez","lemma":"résider","pos":"VER"} ,
		{"word":"résides","word_nosc":"resides","lemma":"résider","pos":"VER"} ,
		{"word":"résidez","word_nosc":"residez","lemma":"résider","pos":"VER"} ,
		{"word":"résidons","word_nosc":"residons","lemma":"résider","pos":"VER"} ,
		{"word":"résidé","word_nosc":"reside","lemma":"résider","pos":"VER"} ,
		{"word":"résigna","word_nosc":"resigna","lemma":"résigner","pos":"VER"} ,
		{"word":"résignai","word_nosc":"resignai","lemma":"résigner","pos":"VER"} ,
		{"word":"résignaient","word_nosc":"resignaient","lemma":"résigner","pos":"VER"} ,
		{"word":"résignais","word_nosc":"resignais","lemma":"résigner","pos":"VER"} ,
		{"word":"résignait","word_nosc":"resignait","lemma":"résigner","pos":"VER"} ,
		{"word":"résignant","word_nosc":"resignant","lemma":"résigner","pos":"VER"} ,
		{"word":"résignassent","word_nosc":"resignassent","lemma":"résigner","pos":"VER"} ,
		{"word":"résigne","word_nosc":"resigne","lemma":"résigner","pos":"VER"} ,
		{"word":"résignent","word_nosc":"resignent","lemma":"résigner","pos":"VER"} ,
		{"word":"résigner","word_nosc":"resigner","lemma":"résigner","pos":"VER"} ,
		{"word":"résignera","word_nosc":"resignera","lemma":"résigner","pos":"VER"} ,
		{"word":"résignerais","word_nosc":"resignerais","lemma":"résigner","pos":"VER"} ,
		{"word":"résignerait","word_nosc":"resignerait","lemma":"résigner","pos":"VER"} ,
		{"word":"résigneront","word_nosc":"resigneront","lemma":"résigner","pos":"VER"} ,
		{"word":"résignez","word_nosc":"resignez","lemma":"résigner","pos":"VER"} ,
		{"word":"résignions","word_nosc":"resignions","lemma":"résigner","pos":"VER"} ,
		{"word":"résignâmes","word_nosc":"resignames","lemma":"résigner","pos":"VER"} ,
		{"word":"résignât","word_nosc":"resignat","lemma":"résigner","pos":"VER"} ,
		{"word":"résignèrent","word_nosc":"resignerent","lemma":"résigner","pos":"VER"} ,
		{"word":"résigné","word_nosc":"resigne","lemma":"résigner","pos":"VER"} ,
		{"word":"résignée","word_nosc":"resignee","lemma":"résigner","pos":"VER"} ,
		{"word":"résignées","word_nosc":"resignees","lemma":"résigner","pos":"VER"} ,
		{"word":"résignés","word_nosc":"resignes","lemma":"résigner","pos":"VER"} ,
		{"word":"résilia","word_nosc":"resilia","lemma":"résilier","pos":"VER"} ,
		{"word":"résilie","word_nosc":"resilie","lemma":"résilier","pos":"VER"} ,
		{"word":"résilier","word_nosc":"resilier","lemma":"résilier","pos":"VER"} ,
		{"word":"résilierait","word_nosc":"resilierait","lemma":"résilier","pos":"VER"} ,
		{"word":"résilié","word_nosc":"resilie","lemma":"résilier","pos":"VER"} ,
		{"word":"résiliée","word_nosc":"resiliee","lemma":"résilier","pos":"VER"} ,
		{"word":"résiliées","word_nosc":"resiliees","lemma":"résilier","pos":"VER"} ,
		{"word":"résinée","word_nosc":"resinee","lemma":"résiner","pos":"VER"} ,
		{"word":"résista","word_nosc":"resista","lemma":"résister","pos":"VER"} ,
		{"word":"résistai","word_nosc":"resistai","lemma":"résister","pos":"VER"} ,
		{"word":"résistaient","word_nosc":"resistaient","lemma":"résister","pos":"VER"} ,
		{"word":"résistais","word_nosc":"resistais","lemma":"résister","pos":"VER"} ,
		{"word":"résistait","word_nosc":"resistait","lemma":"résister","pos":"VER"} ,
		{"word":"résistant","word_nosc":"resistant","lemma":"résister","pos":"VER"} ,
		{"word":"résiste","word_nosc":"resiste","lemma":"résister","pos":"VER"} ,
		{"word":"résistent","word_nosc":"resistent","lemma":"résister","pos":"VER"} ,
		{"word":"résister","word_nosc":"resister","lemma":"résister","pos":"VER"} ,
		{"word":"résistera","word_nosc":"resistera","lemma":"résister","pos":"VER"} ,
		{"word":"résisterai","word_nosc":"resisterai","lemma":"résister","pos":"VER"} ,
		{"word":"résisteraient","word_nosc":"resisteraient","lemma":"résister","pos":"VER"} ,
		{"word":"résisterais","word_nosc":"resisterais","lemma":"résister","pos":"VER"} ,
		{"word":"résisterait","word_nosc":"resisterait","lemma":"résister","pos":"VER"} ,
		{"word":"résisteras","word_nosc":"resisteras","lemma":"résister","pos":"VER"} ,
		{"word":"résisterez","word_nosc":"resisterez","lemma":"résister","pos":"VER"} ,
		{"word":"résisteriez","word_nosc":"resisteriez","lemma":"résister","pos":"VER"} ,
		{"word":"résisterions","word_nosc":"resisterions","lemma":"résister","pos":"VER"} ,
		{"word":"résisterons","word_nosc":"resisterons","lemma":"résister","pos":"VER"} ,
		{"word":"résisteront","word_nosc":"resisteront","lemma":"résister","pos":"VER"} ,
		{"word":"résistes","word_nosc":"resistes","lemma":"résister","pos":"VER"} ,
		{"word":"résistez","word_nosc":"resistez","lemma":"résister","pos":"VER"} ,
		{"word":"résistiez","word_nosc":"resistiez","lemma":"résister","pos":"VER"} ,
		{"word":"résistions","word_nosc":"resistions","lemma":"résister","pos":"VER"} ,
		{"word":"résistons","word_nosc":"resistons","lemma":"résister","pos":"VER"} ,
		{"word":"résistât","word_nosc":"resistat","lemma":"résister","pos":"VER"} ,
		{"word":"résistèrent","word_nosc":"resisterent","lemma":"résister","pos":"VER"} ,
		{"word":"résisté","word_nosc":"resiste","lemma":"résister","pos":"VER"} ,
		{"word":"résolu","word_nosc":"resolu","lemma":"résoudre","pos":"VER"} ,
		{"word":"résolurent","word_nosc":"resolurent","lemma":"résoudre","pos":"VER"} ,
		{"word":"résolus","word_nosc":"resolus","lemma":"résoudre","pos":"VER"} ,
		{"word":"résolut","word_nosc":"resolut","lemma":"résoudre","pos":"VER"} ,
		{"word":"résolvaient","word_nosc":"resolvaient","lemma":"résoudre","pos":"VER"} ,
		{"word":"résolvais","word_nosc":"resolvais","lemma":"résoudre","pos":"VER"} ,
		{"word":"résolvait","word_nosc":"resolvait","lemma":"résoudre","pos":"VER"} ,
		{"word":"résolvant","word_nosc":"resolvant","lemma":"résoudre","pos":"VER"} ,
		{"word":"résolve","word_nosc":"resolve","lemma":"résoudre","pos":"VER"} ,
		{"word":"résolvent","word_nosc":"resolvent","lemma":"résoudre","pos":"VER"} ,
		{"word":"résolves","word_nosc":"resolves","lemma":"résoudre","pos":"VER"} ,
		{"word":"résolvez","word_nosc":"resolvez","lemma":"résoudre","pos":"VER"} ,
		{"word":"résolviez","word_nosc":"resolviez","lemma":"résoudre","pos":"VER"} ,
		{"word":"résolvons","word_nosc":"resolvons","lemma":"résoudre","pos":"VER"} ,
		{"word":"résonna","word_nosc":"resonna","lemma":"résonner","pos":"VER"} ,
		{"word":"résonnaient","word_nosc":"resonnaient","lemma":"résonner","pos":"VER"} ,
		{"word":"résonnait","word_nosc":"resonnait","lemma":"résonner","pos":"VER"} ,
		{"word":"résonnant","word_nosc":"resonnant","lemma":"résonner","pos":"VER"} ,
		{"word":"résonne","word_nosc":"resonne","lemma":"résonner","pos":"VER"} ,
		{"word":"résonnent","word_nosc":"resonnent","lemma":"résonner","pos":"VER"} ,
		{"word":"résonner","word_nosc":"resonner","lemma":"résonner","pos":"VER"} ,
		{"word":"résonnera","word_nosc":"resonnera","lemma":"résonner","pos":"VER"} ,
		{"word":"résonneraient","word_nosc":"resonneraient","lemma":"résonner","pos":"VER"} ,
		{"word":"résonneront","word_nosc":"resonneront","lemma":"résonner","pos":"VER"} ,
		{"word":"résonnez","word_nosc":"resonnez","lemma":"résonner","pos":"VER"} ,
		{"word":"résonnèrent","word_nosc":"resonnerent","lemma":"résonner","pos":"VER"} ,
		{"word":"résonné","word_nosc":"resonne","lemma":"résonner","pos":"VER"} ,
		{"word":"résorba","word_nosc":"resorba","lemma":"résorber","pos":"VER"} ,
		{"word":"résorbaient","word_nosc":"resorbaient","lemma":"résorber","pos":"VER"} ,
		{"word":"résorbait","word_nosc":"resorbait","lemma":"résorber","pos":"VER"} ,
		{"word":"résorbant","word_nosc":"resorbant","lemma":"résorber","pos":"VER"} ,
		{"word":"résorbe","word_nosc":"resorbe","lemma":"résorber","pos":"VER"} ,
		{"word":"résorbent","word_nosc":"resorbent","lemma":"résorber","pos":"VER"} ,
		{"word":"résorber","word_nosc":"resorber","lemma":"résorber","pos":"VER"} ,
		{"word":"résorberait","word_nosc":"resorberait","lemma":"résorber","pos":"VER"} ,
		{"word":"résorberons","word_nosc":"resorberons","lemma":"résorber","pos":"VER"} ,
		{"word":"résorbé","word_nosc":"resorbe","lemma":"résorber","pos":"VER"} ,
		{"word":"résorbée","word_nosc":"resorbee","lemma":"résorber","pos":"VER"} ,
		{"word":"résorbés","word_nosc":"resorbes","lemma":"résorber","pos":"VER"} ,
		{"word":"résoudra","word_nosc":"resoudra","lemma":"résoudre","pos":"VER"} ,
		{"word":"résoudrai","word_nosc":"resoudrai","lemma":"résoudre","pos":"VER"} ,
		{"word":"résoudraient","word_nosc":"resoudraient","lemma":"résoudre","pos":"VER"} ,
		{"word":"résoudrais","word_nosc":"resoudrais","lemma":"résoudre","pos":"VER"} ,
		{"word":"résoudrait","word_nosc":"resoudrait","lemma":"résoudre","pos":"VER"} ,
		{"word":"résoudras","word_nosc":"resoudras","lemma":"résoudre","pos":"VER"} ,
		{"word":"résoudre","word_nosc":"resoudre","lemma":"résoudre","pos":"VER"} ,
		{"word":"résoudrez","word_nosc":"resoudrez","lemma":"résoudre","pos":"VER"} ,
		{"word":"résoudrons","word_nosc":"resoudrons","lemma":"résoudre","pos":"VER"} ,
		{"word":"résoudront","word_nosc":"resoudront","lemma":"résoudre","pos":"VER"} ,
		{"word":"résous","word_nosc":"resous","lemma":"résoudre","pos":"VER"} ,
		{"word":"résout","word_nosc":"resout","lemma":"résoudre","pos":"VER"} ,
		{"word":"résulta","word_nosc":"resulta","lemma":"résulter","pos":"VER"} ,
		{"word":"résultaient","word_nosc":"resultaient","lemma":"résulter","pos":"VER"} ,
		{"word":"résultais","word_nosc":"resultais","lemma":"résulter","pos":"VER"} ,
		{"word":"résultait","word_nosc":"resultait","lemma":"résulter","pos":"VER"} ,
		{"word":"résultant","word_nosc":"resultant","lemma":"résulter","pos":"VER"} ,
		{"word":"résulte","word_nosc":"resulte","lemma":"résulter","pos":"VER"} ,
		{"word":"résultent","word_nosc":"resultent","lemma":"résulter","pos":"VER"} ,
		{"word":"résulter","word_nosc":"resulter","lemma":"résulter","pos":"VER"} ,
		{"word":"résulteraient","word_nosc":"resulteraient","lemma":"résulter","pos":"VER"} ,
		{"word":"résulterait","word_nosc":"resulterait","lemma":"résulter","pos":"VER"} ,
		{"word":"résulteront","word_nosc":"resulteront","lemma":"résulter","pos":"VER"} ,
		{"word":"résulté","word_nosc":"resulte","lemma":"résulter","pos":"VER"} ,
		{"word":"résultées","word_nosc":"resultees","lemma":"résulter","pos":"VER"} ,
		{"word":"résuma","word_nosc":"resuma","lemma":"résumer","pos":"VER"} ,
		{"word":"résumai","word_nosc":"resumai","lemma":"résumer","pos":"VER"} ,
		{"word":"résumaient","word_nosc":"resumaient","lemma":"résumer","pos":"VER"} ,
		{"word":"résumais","word_nosc":"resumais","lemma":"résumer","pos":"VER"} ,
		{"word":"résumait","word_nosc":"resumait","lemma":"résumer","pos":"VER"} ,
		{"word":"résumant","word_nosc":"resumant","lemma":"résumer","pos":"VER"} ,
		{"word":"résume","word_nosc":"resume","lemma":"résumer","pos":"VER"} ,
		{"word":"résument","word_nosc":"resument","lemma":"résumer","pos":"VER"} ,
		{"word":"résumer","word_nosc":"resumer","lemma":"résumer","pos":"VER"} ,
		{"word":"résumera","word_nosc":"resumera","lemma":"résumer","pos":"VER"} ,
		{"word":"résumerai","word_nosc":"resumerai","lemma":"résumer","pos":"VER"} ,
		{"word":"résumerait","word_nosc":"resumerait","lemma":"résumer","pos":"VER"} ,
		{"word":"résumeriez","word_nosc":"resumeriez","lemma":"résumer","pos":"VER"} ,
		{"word":"résumez","word_nosc":"resumez","lemma":"résumer","pos":"VER"} ,
		{"word":"résumons","word_nosc":"resumons","lemma":"résumer","pos":"VER"} ,
		{"word":"résumé","word_nosc":"resume","lemma":"résumer","pos":"VER"} ,
		{"word":"résumée","word_nosc":"resumee","lemma":"résumer","pos":"VER"} ,
		{"word":"résumées","word_nosc":"resumees","lemma":"résumer","pos":"VER"} ,
		{"word":"résumés","word_nosc":"resumes","lemma":"résumer","pos":"VER"} ,
		{"word":"réséquer","word_nosc":"resequer","lemma":"réséquer","pos":"VER"} ,
		{"word":"rétabli","word_nosc":"retabli","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablie","word_nosc":"retablie","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablies","word_nosc":"retablies","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablir","word_nosc":"retablir","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablira","word_nosc":"retablira","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablirai","word_nosc":"retablirai","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablirait","word_nosc":"retablirait","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétabliras","word_nosc":"retabliras","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablirent","word_nosc":"retablirent","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablirez","word_nosc":"retablirez","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablirons","word_nosc":"retablirons","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétabliront","word_nosc":"retabliront","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablis","word_nosc":"retablis","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablissaient","word_nosc":"retablissaient","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablissais","word_nosc":"retablissais","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablissait","word_nosc":"retablissait","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablissant","word_nosc":"retablissant","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablisse","word_nosc":"retablisse","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablissent","word_nosc":"retablissent","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablisses","word_nosc":"retablisses","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablissez","word_nosc":"retablissez","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablissons","word_nosc":"retablissons","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétablit","word_nosc":"retablit","lemma":"rétablir","pos":"VER"} ,
		{"word":"rétamai","word_nosc":"retamai","lemma":"rétamer","pos":"VER"} ,
		{"word":"rétamait","word_nosc":"retamait","lemma":"rétamer","pos":"VER"} ,
		{"word":"rétame","word_nosc":"retame","lemma":"rétamer","pos":"VER"} ,
		{"word":"rétamer","word_nosc":"retamer","lemma":"rétamer","pos":"VER"} ,
		{"word":"rétameras","word_nosc":"retameras","lemma":"rétamer","pos":"VER"} ,
		{"word":"rétamé","word_nosc":"retame","lemma":"rétamer","pos":"VER"} ,
		{"word":"rétamées","word_nosc":"retamees","lemma":"rétamer","pos":"VER"} ,
		{"word":"rétamés","word_nosc":"retames","lemma":"rétamer","pos":"VER"} ,
		{"word":"réticulée","word_nosc":"reticulee","lemma":"réticuler","pos":"VER"} ,
		{"word":"rétorqua","word_nosc":"retorqua","lemma":"rétorquer","pos":"VER"} ,
		{"word":"rétorquai","word_nosc":"retorquai","lemma":"rétorquer","pos":"VER"} ,
		{"word":"rétorquais","word_nosc":"retorquais","lemma":"rétorquer","pos":"VER"} ,
		{"word":"rétorquait","word_nosc":"retorquait","lemma":"rétorquer","pos":"VER"} ,
		{"word":"rétorquant","word_nosc":"retorquant","lemma":"rétorquer","pos":"VER"} ,
		{"word":"rétorque","word_nosc":"retorque","lemma":"rétorquer","pos":"VER"} ,
		{"word":"rétorquer","word_nosc":"retorquer","lemma":"rétorquer","pos":"VER"} ,
		{"word":"rétorquera","word_nosc":"retorquera","lemma":"rétorquer","pos":"VER"} ,
		{"word":"rétorquèrent","word_nosc":"retorquerent","lemma":"rétorquer","pos":"VER"} ,
		{"word":"rétorqué","word_nosc":"retorque","lemma":"rétorquer","pos":"VER"} ,
		{"word":"rétracta","word_nosc":"retracta","lemma":"rétracter","pos":"VER"} ,
		{"word":"rétractais","word_nosc":"retractais","lemma":"rétracter","pos":"VER"} ,
		{"word":"rétractait","word_nosc":"retractait","lemma":"rétracter","pos":"VER"} ,
		{"word":"rétractant","word_nosc":"retractant","lemma":"rétracter","pos":"VER"} ,
		{"word":"rétracte","word_nosc":"retracte","lemma":"rétracter","pos":"VER"} ,
		{"word":"rétractent","word_nosc":"retractent","lemma":"rétracter","pos":"VER"} ,
		{"word":"rétracter","word_nosc":"retracter","lemma":"rétracter","pos":"VER"} ,
		{"word":"rétractera","word_nosc":"retractera","lemma":"rétracter","pos":"VER"} ,
		{"word":"rétracterait","word_nosc":"retracterait","lemma":"rétracter","pos":"VER"} ,
		{"word":"rétractez","word_nosc":"retractez","lemma":"rétracter","pos":"VER"} ,
		{"word":"rétractèrent","word_nosc":"retracterent","lemma":"rétracter","pos":"VER"} ,
		{"word":"rétracté","word_nosc":"retracte","lemma":"rétracter","pos":"VER"} ,
		{"word":"rétractée","word_nosc":"retractee","lemma":"rétracter","pos":"VER"} ,
		{"word":"rétractées","word_nosc":"retractees","lemma":"rétracter","pos":"VER"} ,
		{"word":"rétractés","word_nosc":"retractes","lemma":"rétracter","pos":"VER"} ,
		{"word":"rétreint","word_nosc":"retreint","lemma":"rétreindre","pos":"VER"} ,
		{"word":"rétribuant","word_nosc":"retribuant","lemma":"rétribuer","pos":"VER"} ,
		{"word":"rétribue","word_nosc":"retribue","lemma":"rétribuer","pos":"VER"} ,
		{"word":"rétribuer","word_nosc":"retribuer","lemma":"rétribuer","pos":"VER"} ,
		{"word":"rétribué","word_nosc":"retribue","lemma":"rétribuer","pos":"VER"} ,
		{"word":"rétribuée","word_nosc":"retribuee","lemma":"rétribuer","pos":"VER"} ,
		{"word":"rétribués","word_nosc":"retribues","lemma":"rétribuer","pos":"VER"} ,
		{"word":"rétrocède","word_nosc":"retrocede","lemma":"rétrocéder","pos":"VER"} ,
		{"word":"rétrocédait","word_nosc":"retrocedait","lemma":"rétrocéder","pos":"VER"} ,
		{"word":"rétrocéder","word_nosc":"retroceder","lemma":"rétrocéder","pos":"VER"} ,
		{"word":"rétrograda","word_nosc":"retrograda","lemma":"rétrograder","pos":"VER"} ,
		{"word":"rétrograde","word_nosc":"retrograde","lemma":"rétrograder","pos":"VER"} ,
		{"word":"rétrogradent","word_nosc":"retrogradent","lemma":"rétrograder","pos":"VER"} ,
		{"word":"rétrograder","word_nosc":"retrograder","lemma":"rétrograder","pos":"VER"} ,
		{"word":"rétrogrades","word_nosc":"retrogrades","lemma":"rétrograder","pos":"VER"} ,
		{"word":"rétrogradez","word_nosc":"retrogradez","lemma":"rétrograder","pos":"VER"} ,
		{"word":"rétrogradé","word_nosc":"retrograde","lemma":"rétrograder","pos":"VER"} ,
		{"word":"rétrogradée","word_nosc":"retrogradee","lemma":"rétrograder","pos":"VER"} ,
		{"word":"rétrogradés","word_nosc":"retrogrades","lemma":"rétrograder","pos":"VER"} ,
		{"word":"rétréci","word_nosc":"retreci","lemma":"rétrécir","pos":"VER"} ,
		{"word":"rétrécie","word_nosc":"retrecie","lemma":"rétrécir","pos":"VER"} ,
		{"word":"rétrécies","word_nosc":"retrecies","lemma":"rétrécir","pos":"VER"} ,
		{"word":"rétrécir","word_nosc":"retrecir","lemma":"rétrécir","pos":"VER"} ,
		{"word":"rétrécirent","word_nosc":"retrecirent","lemma":"rétrécir","pos":"VER"} ,
		{"word":"rétrécis","word_nosc":"retrecis","lemma":"rétrécir","pos":"VER"} ,
		{"word":"rétrécissaient","word_nosc":"retrecissaient","lemma":"rétrécir","pos":"VER"} ,
		{"word":"rétrécissait","word_nosc":"retrecissait","lemma":"rétrécir","pos":"VER"} ,
		{"word":"rétrécissant","word_nosc":"retrecissant","lemma":"rétrécir","pos":"VER"} ,
		{"word":"rétrécisse","word_nosc":"retrecisse","lemma":"rétrécir","pos":"VER"} ,
		{"word":"rétrécissent","word_nosc":"retrecissent","lemma":"rétrécir","pos":"VER"} ,
		{"word":"rétrécissez","word_nosc":"retrecissez","lemma":"rétrécir","pos":"VER"} ,
		{"word":"rétrécit","word_nosc":"retrecit","lemma":"rétrécir","pos":"VER"} ,
		{"word":"réuni","word_nosc":"reuni","lemma":"réunir","pos":"VER"} ,
		{"word":"réunie","word_nosc":"reunie","lemma":"réunir","pos":"VER"} ,
		{"word":"réunies","word_nosc":"reunies","lemma":"réunir","pos":"VER"} ,
		{"word":"réunifier","word_nosc":"reunifier","lemma":"réunifier","pos":"VER"} ,
		{"word":"réunifié","word_nosc":"reunifie","lemma":"réunifier","pos":"VER"} ,
		{"word":"réunifiée","word_nosc":"reunifiee","lemma":"réunifier","pos":"VER"} ,
		{"word":"réunifiées","word_nosc":"reunifiees","lemma":"réunifier","pos":"VER"} ,
		{"word":"réunir","word_nosc":"reunir","lemma":"réunir","pos":"VER"} ,
		{"word":"réunira","word_nosc":"reunira","lemma":"réunir","pos":"VER"} ,
		{"word":"réunirai","word_nosc":"reunirai","lemma":"réunir","pos":"VER"} ,
		{"word":"réuniraient","word_nosc":"reuniraient","lemma":"réunir","pos":"VER"} ,
		{"word":"réunirais","word_nosc":"reunirais","lemma":"réunir","pos":"VER"} ,
		{"word":"réunirait","word_nosc":"reunirait","lemma":"réunir","pos":"VER"} ,
		{"word":"réuniras","word_nosc":"reuniras","lemma":"réunir","pos":"VER"} ,
		{"word":"réunirent","word_nosc":"reunirent","lemma":"réunir","pos":"VER"} ,
		{"word":"réunirons","word_nosc":"reunirons","lemma":"réunir","pos":"VER"} ,
		{"word":"réuniront","word_nosc":"reuniront","lemma":"réunir","pos":"VER"} ,
		{"word":"réunis","word_nosc":"reunis","lemma":"réunir","pos":"VER"} ,
		{"word":"réunissaient","word_nosc":"reunissaient","lemma":"réunir","pos":"VER"} ,
		{"word":"réunissais","word_nosc":"reunissais","lemma":"réunir","pos":"VER"} ,
		{"word":"réunissait","word_nosc":"reunissait","lemma":"réunir","pos":"VER"} ,
		{"word":"réunissant","word_nosc":"reunissant","lemma":"réunir","pos":"VER"} ,
		{"word":"réunisse","word_nosc":"reunisse","lemma":"réunir","pos":"VER"} ,
		{"word":"réunissent","word_nosc":"reunissent","lemma":"réunir","pos":"VER"} ,
		{"word":"réunisses","word_nosc":"reunisses","lemma":"réunir","pos":"VER"} ,
		{"word":"réunissez","word_nosc":"reunissez","lemma":"réunir","pos":"VER"} ,
		{"word":"réunissions","word_nosc":"reunissions","lemma":"réunir","pos":"VER"} ,
		{"word":"réunissons","word_nosc":"reunissons","lemma":"réunir","pos":"VER"} ,
		{"word":"réunit","word_nosc":"reunit","lemma":"réunir","pos":"VER"} ,
		{"word":"réunîmes","word_nosc":"reunimes","lemma":"réunir","pos":"VER"} ,
		{"word":"réussi","word_nosc":"reussi","lemma":"réussir","pos":"VER"} ,
		{"word":"réussie","word_nosc":"reussie","lemma":"réussir","pos":"VER"} ,
		{"word":"réussies","word_nosc":"reussies","lemma":"réussir","pos":"VER"} ,
		{"word":"réussir","word_nosc":"reussir","lemma":"réussir","pos":"VER"} ,
		{"word":"réussira","word_nosc":"reussira","lemma":"réussir","pos":"VER"} ,
		{"word":"réussirai","word_nosc":"reussirai","lemma":"réussir","pos":"VER"} ,
		{"word":"réussiraient","word_nosc":"reussiraient","lemma":"réussir","pos":"VER"} ,
		{"word":"réussirais","word_nosc":"reussirais","lemma":"réussir","pos":"VER"} ,
		{"word":"réussirait","word_nosc":"reussirait","lemma":"réussir","pos":"VER"} ,
		{"word":"réussiras","word_nosc":"reussiras","lemma":"réussir","pos":"VER"} ,
		{"word":"réussirent","word_nosc":"reussirent","lemma":"réussir","pos":"VER"} ,
		{"word":"réussirez","word_nosc":"reussirez","lemma":"réussir","pos":"VER"} ,
		{"word":"réussiriez","word_nosc":"reussiriez","lemma":"réussir","pos":"VER"} ,
		{"word":"réussirions","word_nosc":"reussirions","lemma":"réussir","pos":"VER"} ,
		{"word":"réussirons","word_nosc":"reussirons","lemma":"réussir","pos":"VER"} ,
		{"word":"réussiront","word_nosc":"reussiront","lemma":"réussir","pos":"VER"} ,
		{"word":"réussis","word_nosc":"reussis","lemma":"réussir","pos":"VER"} ,
		{"word":"réussissaient","word_nosc":"reussissaient","lemma":"réussir","pos":"VER"} ,
		{"word":"réussissais","word_nosc":"reussissais","lemma":"réussir","pos":"VER"} ,
		{"word":"réussissait","word_nosc":"reussissait","lemma":"réussir","pos":"VER"} ,
		{"word":"réussissant","word_nosc":"reussissant","lemma":"réussir","pos":"VER"} ,
		{"word":"réussisse","word_nosc":"reussisse","lemma":"réussir","pos":"VER"} ,
		{"word":"réussissent","word_nosc":"reussissent","lemma":"réussir","pos":"VER"} ,
		{"word":"réussisses","word_nosc":"reussisses","lemma":"réussir","pos":"VER"} ,
		{"word":"réussissez","word_nosc":"reussissez","lemma":"réussir","pos":"VER"} ,
		{"word":"réussissiez","word_nosc":"reussissiez","lemma":"réussir","pos":"VER"} ,
		{"word":"réussissions","word_nosc":"reussissions","lemma":"réussir","pos":"VER"} ,
		{"word":"réussissons","word_nosc":"reussissons","lemma":"réussir","pos":"VER"} ,
		{"word":"réussit","word_nosc":"reussit","lemma":"réussir","pos":"VER"} ,
		{"word":"réussîmes","word_nosc":"reussimes","lemma":"réussir","pos":"VER"} ,
		{"word":"réussît","word_nosc":"reussit","lemma":"réussir","pos":"VER"} ,
		{"word":"réutilisait","word_nosc":"reutilisait","lemma":"réutiliser","pos":"VER"} ,
		{"word":"réutilise","word_nosc":"reutilise","lemma":"réutiliser","pos":"VER"} ,
		{"word":"réutiliser","word_nosc":"reutiliser","lemma":"réutiliser","pos":"VER"} ,
		{"word":"réutilisé","word_nosc":"reutilise","lemma":"réutiliser","pos":"VER"} ,
		{"word":"réutilisés","word_nosc":"reutilises","lemma":"réutiliser","pos":"VER"} ,
		{"word":"réveilla","word_nosc":"reveilla","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillai","word_nosc":"reveillai","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillaient","word_nosc":"reveillaient","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillais","word_nosc":"reveillais","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillait","word_nosc":"reveillait","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillant","word_nosc":"reveillant","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveille","word_nosc":"reveille","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillent","word_nosc":"reveillent","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveiller","word_nosc":"reveiller","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillera","word_nosc":"reveillera","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillerai","word_nosc":"reveillerai","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveilleraient","word_nosc":"reveilleraient","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillerais","word_nosc":"reveillerais","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillerait","word_nosc":"reveillerait","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveilleras","word_nosc":"reveilleras","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillerez","word_nosc":"reveillerez","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveilleriez","word_nosc":"reveilleriez","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillerons","word_nosc":"reveillerons","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveilleront","word_nosc":"reveilleront","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveilles","word_nosc":"reveilles","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillez","word_nosc":"reveillez","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveilliez","word_nosc":"reveilliez","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillions","word_nosc":"reveillions","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillonnaient","word_nosc":"reveillonnaient","lemma":"réveillonner","pos":"VER"} ,
		{"word":"réveillonnait","word_nosc":"reveillonnait","lemma":"réveillonner","pos":"VER"} ,
		{"word":"réveillonne","word_nosc":"reveillonne","lemma":"réveillonner","pos":"VER"} ,
		{"word":"réveillonner","word_nosc":"reveillonner","lemma":"réveillonner","pos":"VER"} ,
		{"word":"réveillonnerons","word_nosc":"reveillonnerons","lemma":"réveillonner","pos":"VER"} ,
		{"word":"réveillonnons","word_nosc":"reveillonnons","lemma":"réveillonner","pos":"VER"} ,
		{"word":"réveillonnâmes","word_nosc":"reveillonnames","lemma":"réveillonner","pos":"VER"} ,
		{"word":"réveillonnèrent","word_nosc":"reveillonnerent","lemma":"réveillonner","pos":"VER"} ,
		{"word":"réveillonné","word_nosc":"reveillonne","lemma":"réveillonner","pos":"VER"} ,
		{"word":"réveillons","word_nosc":"reveillons","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillâmes","word_nosc":"reveillames","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillât","word_nosc":"reveillat","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillèrent","word_nosc":"reveillerent","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillé","word_nosc":"reveille","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillée","word_nosc":"reveillee","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillées","word_nosc":"reveillees","lemma":"réveiller","pos":"VER"} ,
		{"word":"réveillés","word_nosc":"reveilles","lemma":"réveiller","pos":"VER"} ,
		{"word":"réverbère","word_nosc":"reverbere","lemma":"réverbérer","pos":"VER"} ,
		{"word":"réverbèrent","word_nosc":"reverberent","lemma":"réverbérer","pos":"VER"} ,
		{"word":"réverbéra","word_nosc":"reverbera","lemma":"réverbérer","pos":"VER"} ,
		{"word":"réverbéraient","word_nosc":"reverberaient","lemma":"réverbérer","pos":"VER"} ,
		{"word":"réverbérait","word_nosc":"reverberait","lemma":"réverbérer","pos":"VER"} ,
		{"word":"réverbérant","word_nosc":"reverberant","lemma":"réverbérer","pos":"VER"} ,
		{"word":"réverbérer","word_nosc":"reverberer","lemma":"réverbérer","pos":"VER"} ,
		{"word":"réverbéré","word_nosc":"reverbere","lemma":"réverbérer","pos":"VER"} ,
		{"word":"réverbérée","word_nosc":"reverberee","lemma":"réverbérer","pos":"VER"} ,
		{"word":"révisa","word_nosc":"revisa","lemma":"réviser","pos":"VER"} ,
		{"word":"révisai","word_nosc":"revisai","lemma":"réviser","pos":"VER"} ,
		{"word":"révisaient","word_nosc":"revisaient","lemma":"réviser","pos":"VER"} ,
		{"word":"révisais","word_nosc":"revisais","lemma":"réviser","pos":"VER"} ,
		{"word":"révisait","word_nosc":"revisait","lemma":"réviser","pos":"VER"} ,
		{"word":"révisant","word_nosc":"revisant","lemma":"réviser","pos":"VER"} ,
		{"word":"révise","word_nosc":"revise","lemma":"réviser","pos":"VER"} ,
		{"word":"révisent","word_nosc":"revisent","lemma":"réviser","pos":"VER"} ,
		{"word":"réviser","word_nosc":"reviser","lemma":"réviser","pos":"VER"} ,
		{"word":"réviserais","word_nosc":"reviserais","lemma":"réviser","pos":"VER"} ,
		{"word":"réviserait","word_nosc":"reviserait","lemma":"réviser","pos":"VER"} ,
		{"word":"révises","word_nosc":"revises","lemma":"réviser","pos":"VER"} ,
		{"word":"révisez","word_nosc":"revisez","lemma":"réviser","pos":"VER"} ,
		{"word":"révisiez","word_nosc":"revisiez","lemma":"réviser","pos":"VER"} ,
		{"word":"révisons","word_nosc":"revisons","lemma":"réviser","pos":"VER"} ,
		{"word":"révisé","word_nosc":"revise","lemma":"réviser","pos":"VER"} ,
		{"word":"révisée","word_nosc":"revisee","lemma":"réviser","pos":"VER"} ,
		{"word":"révisées","word_nosc":"revisees","lemma":"réviser","pos":"VER"} ,
		{"word":"révisés","word_nosc":"revises","lemma":"réviser","pos":"VER"} ,
		{"word":"révolta","word_nosc":"revolta","lemma":"révolter","pos":"VER"} ,
		{"word":"révoltai","word_nosc":"revoltai","lemma":"révolter","pos":"VER"} ,
		{"word":"révoltaient","word_nosc":"revoltaient","lemma":"révolter","pos":"VER"} ,
		{"word":"révoltais","word_nosc":"revoltais","lemma":"révolter","pos":"VER"} ,
		{"word":"révoltait","word_nosc":"revoltait","lemma":"révolter","pos":"VER"} ,
		{"word":"révoltant","word_nosc":"revoltant","lemma":"révolter","pos":"VER"} ,
		{"word":"révolte","word_nosc":"revolte","lemma":"révolter","pos":"VER"} ,
		{"word":"révoltent","word_nosc":"revoltent","lemma":"révolter","pos":"VER"} ,
		{"word":"révolter","word_nosc":"revolter","lemma":"révolter","pos":"VER"} ,
		{"word":"révolteront","word_nosc":"revolteront","lemma":"révolter","pos":"VER"} ,
		{"word":"révoltes","word_nosc":"revoltes","lemma":"révolter","pos":"VER"} ,
		{"word":"révoltez","word_nosc":"revoltez","lemma":"révolter","pos":"VER"} ,
		{"word":"révoltions","word_nosc":"revoltions","lemma":"révolter","pos":"VER"} ,
		{"word":"révoltons","word_nosc":"revoltons","lemma":"révolter","pos":"VER"} ,
		{"word":"révoltât","word_nosc":"revoltat","lemma":"révolter","pos":"VER"} ,
		{"word":"révoltèrent","word_nosc":"revolterent","lemma":"révolter","pos":"VER"} ,
		{"word":"révolté","word_nosc":"revolte","lemma":"révolter","pos":"VER"} ,
		{"word":"révoltée","word_nosc":"revoltee","lemma":"révolter","pos":"VER"} ,
		{"word":"révoltées","word_nosc":"revoltees","lemma":"révolter","pos":"VER"} ,
		{"word":"révoltés","word_nosc":"revoltes","lemma":"révolter","pos":"VER"} ,
		{"word":"révolutionna","word_nosc":"revolutionna","lemma":"révolutionner","pos":"VER"} ,
		{"word":"révolutionnaient","word_nosc":"revolutionnaient","lemma":"révolutionner","pos":"VER"} ,
		{"word":"révolutionnait","word_nosc":"revolutionnait","lemma":"révolutionner","pos":"VER"} ,
		{"word":"révolutionner","word_nosc":"revolutionner","lemma":"révolutionner","pos":"VER"} ,
		{"word":"révolutionnera","word_nosc":"revolutionnera","lemma":"révolutionner","pos":"VER"} ,
		{"word":"révolutionnerait","word_nosc":"revolutionnerait","lemma":"révolutionner","pos":"VER"} ,
		{"word":"révolutionneront","word_nosc":"revolutionneront","lemma":"révolutionner","pos":"VER"} ,
		{"word":"révolutionné","word_nosc":"revolutionne","lemma":"révolutionner","pos":"VER"} ,
		{"word":"révolutionnées","word_nosc":"revolutionnees","lemma":"révolutionner","pos":"VER"} ,
		{"word":"révoquais","word_nosc":"revoquais","lemma":"révoquer","pos":"VER"} ,
		{"word":"révoque","word_nosc":"revoque","lemma":"révoquer","pos":"VER"} ,
		{"word":"révoquer","word_nosc":"revoquer","lemma":"révoquer","pos":"VER"} ,
		{"word":"révoqué","word_nosc":"revoque","lemma":"révoquer","pos":"VER"} ,
		{"word":"révoquée","word_nosc":"revoquee","lemma":"révoquer","pos":"VER"} ,
		{"word":"révoquées","word_nosc":"revoquees","lemma":"révoquer","pos":"VER"} ,
		{"word":"révoqués","word_nosc":"revoques","lemma":"révoquer","pos":"VER"} ,
		{"word":"révulsaient","word_nosc":"revulsaient","lemma":"révulser","pos":"VER"} ,
		{"word":"révulsait","word_nosc":"revulsait","lemma":"révulser","pos":"VER"} ,
		{"word":"révulsant","word_nosc":"revulsant","lemma":"révulser","pos":"VER"} ,
		{"word":"révulse","word_nosc":"revulse","lemma":"révulser","pos":"VER"} ,
		{"word":"révulser","word_nosc":"revulser","lemma":"révulser","pos":"VER"} ,
		{"word":"révulserait","word_nosc":"revulserait","lemma":"révulser","pos":"VER"} ,
		{"word":"révulsèrent","word_nosc":"revulserent","lemma":"révulser","pos":"VER"} ,
		{"word":"révulsé","word_nosc":"revulse","lemma":"révulser","pos":"VER"} ,
		{"word":"révulsée","word_nosc":"revulsee","lemma":"révulser","pos":"VER"} ,
		{"word":"révulsés","word_nosc":"revulses","lemma":"révulser","pos":"VER"} ,
		{"word":"révèle","word_nosc":"revele","lemma":"révéler","pos":"VER"} ,
		{"word":"révèlent","word_nosc":"revelent","lemma":"révéler","pos":"VER"} ,
		{"word":"révèles","word_nosc":"reveles","lemma":"révéler","pos":"VER"} ,
		{"word":"révère","word_nosc":"revere","lemma":"révérer","pos":"VER"} ,
		{"word":"révèrent","word_nosc":"reverent","lemma":"révérer","pos":"VER"} ,
		{"word":"révéla","word_nosc":"revela","lemma":"révéler","pos":"VER"} ,
		{"word":"révélai","word_nosc":"revelai","lemma":"révéler","pos":"VER"} ,
		{"word":"révélaient","word_nosc":"revelaient","lemma":"révéler","pos":"VER"} ,
		{"word":"révélais","word_nosc":"revelais","lemma":"révéler","pos":"VER"} ,
		{"word":"révélait","word_nosc":"revelait","lemma":"révéler","pos":"VER"} ,
		{"word":"révélant","word_nosc":"revelant","lemma":"révéler","pos":"VER"} ,
		{"word":"révéler","word_nosc":"reveler","lemma":"révéler","pos":"VER"} ,
		{"word":"révélera","word_nosc":"revelera","lemma":"révéler","pos":"VER"} ,
		{"word":"révélerai","word_nosc":"revelerai","lemma":"révéler","pos":"VER"} ,
		{"word":"révéleraient","word_nosc":"reveleraient","lemma":"révéler","pos":"VER"} ,
		{"word":"révélerais","word_nosc":"revelerais","lemma":"révéler","pos":"VER"} ,
		{"word":"révélerait","word_nosc":"revelerait","lemma":"révéler","pos":"VER"} ,
		{"word":"révéleras","word_nosc":"reveleras","lemma":"révéler","pos":"VER"} ,
		{"word":"révélerez","word_nosc":"revelerez","lemma":"révéler","pos":"VER"} ,
		{"word":"révélerons","word_nosc":"revelerons","lemma":"révéler","pos":"VER"} ,
		{"word":"révéleront","word_nosc":"reveleront","lemma":"révéler","pos":"VER"} ,
		{"word":"révélez","word_nosc":"revelez","lemma":"révéler","pos":"VER"} ,
		{"word":"révéliez","word_nosc":"reveliez","lemma":"révéler","pos":"VER"} ,
		{"word":"révélons","word_nosc":"revelons","lemma":"révéler","pos":"VER"} ,
		{"word":"révélât","word_nosc":"revelat","lemma":"révéler","pos":"VER"} ,
		{"word":"révélèrent","word_nosc":"revelerent","lemma":"révéler","pos":"VER"} ,
		{"word":"révélé","word_nosc":"revele","lemma":"révéler","pos":"VER"} ,
		{"word":"révélée","word_nosc":"revelee","lemma":"révéler","pos":"VER"} ,
		{"word":"révélées","word_nosc":"revelees","lemma":"révéler","pos":"VER"} ,
		{"word":"révélés","word_nosc":"reveles","lemma":"révéler","pos":"VER"} ,
		{"word":"révérait","word_nosc":"reverait","lemma":"révérer","pos":"VER"} ,
		{"word":"révérant","word_nosc":"reverant","lemma":"révérer","pos":"VER"} ,
		{"word":"révérer","word_nosc":"reverer","lemma":"révérer","pos":"VER"} ,
		{"word":"révérerait","word_nosc":"revererait","lemma":"révérer","pos":"VER"} ,
		{"word":"révérons","word_nosc":"reverons","lemma":"révérer","pos":"VER"} ,
		{"word":"révéré","word_nosc":"revere","lemma":"révérer","pos":"VER"} ,
		{"word":"révérée","word_nosc":"reveree","lemma":"révérer","pos":"VER"} ,
		{"word":"réécoutais","word_nosc":"reecoutais","lemma":"réécouter","pos":"VER"} ,
		{"word":"réécoutait","word_nosc":"reecoutait","lemma":"réécouter","pos":"VER"} ,
		{"word":"réécoute","word_nosc":"reecoute","lemma":"réécouter","pos":"VER"} ,
		{"word":"réécoutent","word_nosc":"reecoutent","lemma":"réécouter","pos":"VER"} ,
		{"word":"réécouter","word_nosc":"reecouter","lemma":"réécouter","pos":"VER"} ,
		{"word":"réécouterez","word_nosc":"reecouterez","lemma":"réécouter","pos":"VER"} ,
		{"word":"réécouté","word_nosc":"reecoute","lemma":"réécouter","pos":"VER"} ,
		{"word":"réécrira","word_nosc":"reecrira","lemma":"réécrire","pos":"VER"} ,
		{"word":"réécrirai","word_nosc":"reecrirai","lemma":"réécrire","pos":"VER"} ,
		{"word":"réécrire","word_nosc":"reecrire","lemma":"réécrire","pos":"VER"} ,
		{"word":"réécris","word_nosc":"reecris","lemma":"réécrire","pos":"VER"} ,
		{"word":"réécrit","word_nosc":"reecrit","lemma":"réécrire","pos":"VER"} ,
		{"word":"réécrite","word_nosc":"reecrite","lemma":"réécrire","pos":"VER"} ,
		{"word":"réécrits","word_nosc":"reecrits","lemma":"réécrire","pos":"VER"} ,
		{"word":"réécrivaient","word_nosc":"reecrivaient","lemma":"réécrire","pos":"VER"} ,
		{"word":"réécrive","word_nosc":"reecrive","lemma":"réécrire","pos":"VER"} ,
		{"word":"réécrivez","word_nosc":"reecrivez","lemma":"réécrire","pos":"VER"} ,
		{"word":"réédifier","word_nosc":"reedifier","lemma":"réédifier","pos":"VER"} ,
		{"word":"réédifieras","word_nosc":"reedifieras","lemma":"réédifier","pos":"VER"} ,
		{"word":"rééditait","word_nosc":"reeditait","lemma":"rééditer","pos":"VER"} ,
		{"word":"rééditant","word_nosc":"reeditant","lemma":"rééditer","pos":"VER"} ,
		{"word":"rééditer","word_nosc":"reediter","lemma":"rééditer","pos":"VER"} ,
		{"word":"réédité","word_nosc":"reedite","lemma":"rééditer","pos":"VER"} ,
		{"word":"rééditées","word_nosc":"reeditees","lemma":"rééditer","pos":"VER"} ,
		{"word":"réédités","word_nosc":"reedites","lemma":"rééditer","pos":"VER"} ,
		{"word":"rééduquais","word_nosc":"reeduquais","lemma":"rééduquer","pos":"VER"} ,
		{"word":"rééduquait","word_nosc":"reeduquait","lemma":"rééduquer","pos":"VER"} ,
		{"word":"rééduque","word_nosc":"reeduque","lemma":"rééduquer","pos":"VER"} ,
		{"word":"rééduquer","word_nosc":"reeduquer","lemma":"rééduquer","pos":"VER"} ,
		{"word":"rééduqué","word_nosc":"reeduque","lemma":"rééduquer","pos":"VER"} ,
		{"word":"rééduqués","word_nosc":"reeduques","lemma":"rééduquer","pos":"VER"} ,
		{"word":"réélira","word_nosc":"reelira","lemma":"réélire","pos":"VER"} ,
		{"word":"réélire","word_nosc":"reelire","lemma":"réélire","pos":"VER"} ,
		{"word":"réélisent","word_nosc":"reelisent","lemma":"réélire","pos":"VER"} ,
		{"word":"réélisez","word_nosc":"reelisez","lemma":"réélire","pos":"VER"} ,
		{"word":"réélu","word_nosc":"reelu","lemma":"réélire","pos":"VER"} ,
		{"word":"réélus","word_nosc":"reelus","lemma":"réélire","pos":"VER"} ,
		{"word":"réélus","word_nosc":"reelus","lemma":"réélus","pos":"VER"} ,
		{"word":"réélut","word_nosc":"reelut","lemma":"réélire","pos":"VER"} ,
		{"word":"rééquilibrait","word_nosc":"reequilibrait","lemma":"rééquilibrer","pos":"VER"} ,
		{"word":"rééquilibrant","word_nosc":"reequilibrant","lemma":"rééquilibrer","pos":"VER"} ,
		{"word":"rééquilibre","word_nosc":"reequilibre","lemma":"rééquilibrer","pos":"VER"} ,
		{"word":"rééquilibrer","word_nosc":"reequilibrer","lemma":"rééquilibrer","pos":"VER"} ,
		{"word":"rééquilibrera","word_nosc":"reequilibrera","lemma":"rééquilibrer","pos":"VER"} ,
		{"word":"rééquipent","word_nosc":"reequipent","lemma":"rééquiper","pos":"VER"} ,
		{"word":"rééquiper","word_nosc":"reequiper","lemma":"rééquiper","pos":"VER"} ,
		{"word":"rééquipé","word_nosc":"reequipe","lemma":"rééquiper","pos":"VER"} ,
		{"word":"rééquipée","word_nosc":"reequipee","lemma":"rééquiper","pos":"VER"} ,
		{"word":"réétudier","word_nosc":"reetudier","lemma":"réétudier","pos":"VER"} ,
		{"word":"réétudiez","word_nosc":"reetudiez","lemma":"réétudier","pos":"VER"} ,
		{"word":"réévalue","word_nosc":"reevalue","lemma":"réévaluer","pos":"VER"} ,
		{"word":"réévaluer","word_nosc":"reevaluer","lemma":"réévaluer","pos":"VER"} ,
		{"word":"réévaluera","word_nosc":"reevaluera","lemma":"réévaluer","pos":"VER"} ,
		{"word":"réévaluez","word_nosc":"reevaluez","lemma":"réévaluer","pos":"VER"} ,
		{"word":"réévalué","word_nosc":"reevalue","lemma":"réévaluer","pos":"VER"} ,
		{"word":"réévaluée","word_nosc":"reevaluee","lemma":"réévaluer","pos":"VER"} ,
		{"word":"réévaluées","word_nosc":"reevaluees","lemma":"réévaluer","pos":"VER"} ,
		{"word":"réévalués","word_nosc":"reevalues","lemma":"réévaluer","pos":"VER"} ,
		{"word":"rêva","word_nosc":"reva","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvai","word_nosc":"revai","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvaient","word_nosc":"revaient","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvais","word_nosc":"revais","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvait","word_nosc":"revait","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvant","word_nosc":"revant","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvassa","word_nosc":"revassa","lemma":"rêvasser","pos":"VER"} ,
		{"word":"rêvassai","word_nosc":"revassai","lemma":"rêvasser","pos":"VER"} ,
		{"word":"rêvassaient","word_nosc":"revassaient","lemma":"rêvasser","pos":"VER"} ,
		{"word":"rêvassais","word_nosc":"revassais","lemma":"rêvasser","pos":"VER"} ,
		{"word":"rêvassait","word_nosc":"revassait","lemma":"rêvasser","pos":"VER"} ,
		{"word":"rêvassant","word_nosc":"revassant","lemma":"rêvasser","pos":"VER"} ,
		{"word":"rêvasse","word_nosc":"revasse","lemma":"rêvasser","pos":"VER"} ,
		{"word":"rêvassent","word_nosc":"revassent","lemma":"rêvasser","pos":"VER"} ,
		{"word":"rêvasser","word_nosc":"revasser","lemma":"rêvasser","pos":"VER"} ,
		{"word":"rêvassé","word_nosc":"revasse","lemma":"rêvasser","pos":"VER"} ,
		{"word":"rêve","word_nosc":"reve","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvent","word_nosc":"revent","lemma":"rêver","pos":"VER"} ,
		{"word":"rêver","word_nosc":"rever","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvera","word_nosc":"revera","lemma":"rêver","pos":"VER"} ,
		{"word":"rêverai","word_nosc":"reverai","lemma":"rêver","pos":"VER"} ,
		{"word":"rêveraient","word_nosc":"reveraient","lemma":"rêver","pos":"VER"} ,
		{"word":"rêverait","word_nosc":"reverait","lemma":"rêver","pos":"VER"} ,
		{"word":"rêveront","word_nosc":"reveront","lemma":"rêver","pos":"VER"} ,
		{"word":"rêves","word_nosc":"reves","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvez","word_nosc":"revez","lemma":"rêver","pos":"VER"} ,
		{"word":"rêviez","word_nosc":"reviez","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvions","word_nosc":"revions","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvons","word_nosc":"revons","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvât","word_nosc":"revat","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvèrent","word_nosc":"reverent","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvé","word_nosc":"reve","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvée","word_nosc":"revee","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvées","word_nosc":"revees","lemma":"rêver","pos":"VER"} ,
		{"word":"rêvés","word_nosc":"reves","lemma":"rêver","pos":"VER"} ,
		{"word":"rîmes","word_nosc":"rimes","lemma":"rire","pos":"VER"} ,
		{"word":"rît","word_nosc":"rit","lemma":"rire","pos":"VER"} ,
		{"word":"rôda","word_nosc":"roda","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdai","word_nosc":"rodai","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdaient","word_nosc":"rodaient","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdailler","word_nosc":"rodailler","lemma":"rôdailler","pos":"VER"} ,
		{"word":"rôdais","word_nosc":"rodais","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdait","word_nosc":"rodait","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdant","word_nosc":"rodant","lemma":"rôder","pos":"VER"} ,
		{"word":"rôde","word_nosc":"rode","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdent","word_nosc":"rodent","lemma":"rôder","pos":"VER"} ,
		{"word":"rôder","word_nosc":"roder","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdera","word_nosc":"rodera","lemma":"rôder","pos":"VER"} ,
		{"word":"rôderai","word_nosc":"roderai","lemma":"rôder","pos":"VER"} ,
		{"word":"rôderait","word_nosc":"roderait","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdes","word_nosc":"rodes","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdez","word_nosc":"rodez","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdiez","word_nosc":"rodiez","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdions","word_nosc":"rodions","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdâmes","word_nosc":"rodames","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdèrent","word_nosc":"roderent","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdé","word_nosc":"rode","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdée","word_nosc":"rodee","lemma":"rôder","pos":"VER"} ,
		{"word":"rôdés","word_nosc":"rodes","lemma":"rôder","pos":"VER"} ,
		{"word":"rôti","word_nosc":"roti","lemma":"rôtir","pos":"VER"} ,
		{"word":"rôtie","word_nosc":"rotie","lemma":"rôtir","pos":"VER"} ,
		{"word":"rôties","word_nosc":"roties","lemma":"rôtir","pos":"VER"} ,
		{"word":"rôtir","word_nosc":"rotir","lemma":"rôtir","pos":"VER"} ,
		{"word":"rôtiront","word_nosc":"rotiront","lemma":"rôtir","pos":"VER"} ,
		{"word":"rôtis","word_nosc":"rotis","lemma":"rôtir","pos":"VER"} ,
		{"word":"rôtissaient","word_nosc":"rotissaient","lemma":"rôtir","pos":"VER"} ,
		{"word":"rôtissait","word_nosc":"rotissait","lemma":"rôtir","pos":"VER"} ,
		{"word":"rôtissant","word_nosc":"rotissant","lemma":"rôtir","pos":"VER"} ,
		{"word":"rôtissent","word_nosc":"rotissent","lemma":"rôtir","pos":"VER"} ,
		{"word":"rôtissez","word_nosc":"rotissez","lemma":"rôtir","pos":"VER"} ,
		{"word":"rôtissions","word_nosc":"rotissions","lemma":"rôtir","pos":"VER"} ,
		{"word":"rôtit","word_nosc":"rotit","lemma":"rôtir","pos":"VER"} ,
		{"word":"sabla","word_nosc":"sabla","lemma":"sabler","pos":"VER"} ,
		{"word":"sablaient","word_nosc":"sablaient","lemma":"sabler","pos":"VER"} ,
		{"word":"sablait","word_nosc":"sablait","lemma":"sabler","pos":"VER"} ,
		{"word":"sablant","word_nosc":"sablant","lemma":"sabler","pos":"VER"} ,
		{"word":"sable","word_nosc":"sable","lemma":"sabler","pos":"VER"} ,
		{"word":"sabler","word_nosc":"sabler","lemma":"sabler","pos":"VER"} ,
		{"word":"sablera","word_nosc":"sablera","lemma":"sabler","pos":"VER"} ,
		{"word":"sablerais","word_nosc":"sablerais","lemma":"sabler","pos":"VER"} ,
		{"word":"sablez","word_nosc":"sablez","lemma":"sabler","pos":"VER"} ,
		{"word":"sablé","word_nosc":"sable","lemma":"sabler","pos":"VER"} ,
		{"word":"sablée","word_nosc":"sablee","lemma":"sabler","pos":"VER"} ,
		{"word":"sablés","word_nosc":"sables","lemma":"sabler","pos":"VER"} ,
		{"word":"sabordait","word_nosc":"sabordait","lemma":"saborder","pos":"VER"} ,
		{"word":"saborde","word_nosc":"saborde","lemma":"saborder","pos":"VER"} ,
		{"word":"sabordent","word_nosc":"sabordent","lemma":"saborder","pos":"VER"} ,
		{"word":"saborder","word_nosc":"saborder","lemma":"saborder","pos":"VER"} ,
		{"word":"sabordez","word_nosc":"sabordez","lemma":"saborder","pos":"VER"} ,
		{"word":"sabordé","word_nosc":"saborde","lemma":"saborder","pos":"VER"} ,
		{"word":"sabordée","word_nosc":"sabordee","lemma":"saborder","pos":"VER"} ,
		{"word":"sabordés","word_nosc":"sabordes","lemma":"saborder","pos":"VER"} ,
		{"word":"sabotais","word_nosc":"sabotais","lemma":"saboter","pos":"VER"} ,
		{"word":"sabotait","word_nosc":"sabotait","lemma":"saboter","pos":"VER"} ,
		{"word":"sabotant","word_nosc":"sabotant","lemma":"saboter","pos":"VER"} ,
		{"word":"sabote","word_nosc":"sabote","lemma":"saboter","pos":"VER"} ,
		{"word":"sabotent","word_nosc":"sabotent","lemma":"saboter","pos":"VER"} ,
		{"word":"saboter","word_nosc":"saboter","lemma":"saboter","pos":"VER"} ,
		{"word":"saboterai","word_nosc":"saboterai","lemma":"saboter","pos":"VER"} ,
		{"word":"saboterait","word_nosc":"saboterait","lemma":"saboter","pos":"VER"} ,
		{"word":"saboteront","word_nosc":"saboteront","lemma":"saboter","pos":"VER"} ,
		{"word":"sabotez","word_nosc":"sabotez","lemma":"saboter","pos":"VER"} ,
		{"word":"sabotons","word_nosc":"sabotons","lemma":"saboter","pos":"VER"} ,
		{"word":"saboté","word_nosc":"sabote","lemma":"saboter","pos":"VER"} ,
		{"word":"sabotée","word_nosc":"sabotee","lemma":"saboter","pos":"VER"} ,
		{"word":"sabotées","word_nosc":"sabotees","lemma":"saboter","pos":"VER"} ,
		{"word":"sabotés","word_nosc":"sabotes","lemma":"saboter","pos":"VER"} ,
		{"word":"saboule","word_nosc":"saboule","lemma":"sabouler","pos":"VER"} ,
		{"word":"saboulent","word_nosc":"saboulent","lemma":"sabouler","pos":"VER"} ,
		{"word":"sabouler","word_nosc":"sabouler","lemma":"sabouler","pos":"VER"} ,
		{"word":"saboulé","word_nosc":"saboule","lemma":"sabouler","pos":"VER"} ,
		{"word":"saboulés","word_nosc":"saboules","lemma":"sabouler","pos":"VER"} ,
		{"word":"sabra","word_nosc":"sabra","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabrait","word_nosc":"sabrait","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabrant","word_nosc":"sabrant","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabre","word_nosc":"sabre","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabrent","word_nosc":"sabrent","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabrer","word_nosc":"sabrer","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabrerai","word_nosc":"sabrerai","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabreraient","word_nosc":"sabreraient","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabrerais","word_nosc":"sabrerais","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabreras","word_nosc":"sabreras","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabrerons","word_nosc":"sabrerons","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabres","word_nosc":"sabres","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabrez","word_nosc":"sabrez","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabré","word_nosc":"sabre","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabrée","word_nosc":"sabree","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabrées","word_nosc":"sabrees","lemma":"sabrer","pos":"VER"} ,
		{"word":"sabrés","word_nosc":"sabres","lemma":"sabrer","pos":"VER"} ,
		{"word":"saccade","word_nosc":"saccade","lemma":"saccader","pos":"VER"} ,
		{"word":"saccader","word_nosc":"saccader","lemma":"saccader","pos":"VER"} ,
		{"word":"saccadé","word_nosc":"saccade","lemma":"saccader","pos":"VER"} ,
		{"word":"saccadée","word_nosc":"saccadee","lemma":"saccader","pos":"VER"} ,
		{"word":"saccadés","word_nosc":"saccades","lemma":"saccader","pos":"VER"} ,
		{"word":"saccage","word_nosc":"saccage","lemma":"saccager","pos":"VER"} ,
		{"word":"saccagea","word_nosc":"saccagea","lemma":"saccager","pos":"VER"} ,
		{"word":"saccageaient","word_nosc":"saccageaient","lemma":"saccager","pos":"VER"} ,
		{"word":"saccageais","word_nosc":"saccageais","lemma":"saccager","pos":"VER"} ,
		{"word":"saccageait","word_nosc":"saccageait","lemma":"saccager","pos":"VER"} ,
		{"word":"saccageant","word_nosc":"saccageant","lemma":"saccager","pos":"VER"} ,
		{"word":"saccagent","word_nosc":"saccagent","lemma":"saccager","pos":"VER"} ,
		{"word":"saccageons","word_nosc":"saccageons","lemma":"saccager","pos":"VER"} ,
		{"word":"saccager","word_nosc":"saccager","lemma":"saccager","pos":"VER"} ,
		{"word":"saccageraient","word_nosc":"saccageraient","lemma":"saccager","pos":"VER"} ,
		{"word":"saccages","word_nosc":"saccages","lemma":"saccager","pos":"VER"} ,
		{"word":"saccagez","word_nosc":"saccagez","lemma":"saccager","pos":"VER"} ,
		{"word":"saccagiez","word_nosc":"saccagiez","lemma":"saccager","pos":"VER"} ,
		{"word":"saccagèrent","word_nosc":"saccagerent","lemma":"saccager","pos":"VER"} ,
		{"word":"saccagé","word_nosc":"saccage","lemma":"saccager","pos":"VER"} ,
		{"word":"saccagée","word_nosc":"saccagee","lemma":"saccager","pos":"VER"} ,
		{"word":"saccagées","word_nosc":"saccagees","lemma":"saccager","pos":"VER"} ,
		{"word":"saccagés","word_nosc":"saccages","lemma":"saccager","pos":"VER"} ,
		{"word":"sachant","word_nosc":"sachant","lemma":"savoir","pos":"VER"} ,
		{"word":"sache","word_nosc":"sache","lemma":"savoir","pos":"VER"} ,
		{"word":"sachent","word_nosc":"sachent","lemma":"savoir","pos":"VER"} ,
		{"word":"saches","word_nosc":"saches","lemma":"savoir","pos":"VER"} ,
		{"word":"sachez","word_nosc":"sachez","lemma":"savoir","pos":"VER"} ,
		{"word":"sachiez","word_nosc":"sachiez","lemma":"savoir","pos":"VER"} ,
		{"word":"sachions","word_nosc":"sachions","lemma":"savoir","pos":"VER"} ,
		{"word":"sachons","word_nosc":"sachons","lemma":"savoir","pos":"VER"} ,
		{"word":"sacqua","word_nosc":"sacqua","lemma":"sacquer","pos":"VER"} ,
		{"word":"sacquais","word_nosc":"sacquais","lemma":"sacquer","pos":"VER"} ,
		{"word":"sacquant","word_nosc":"sacquant","lemma":"sacquer","pos":"VER"} ,
		{"word":"sacque","word_nosc":"sacque","lemma":"sacquer","pos":"VER"} ,
		{"word":"sacquer","word_nosc":"sacquer","lemma":"sacquer","pos":"VER"} ,
		{"word":"sacqué","word_nosc":"sacque","lemma":"sacquer","pos":"VER"} ,
		{"word":"sacra","word_nosc":"sacra","lemma":"sacrer","pos":"VER"} ,
		{"word":"sacrait","word_nosc":"sacrait","lemma":"sacrer","pos":"VER"} ,
		{"word":"sacralisés","word_nosc":"sacralises","lemma":"sacraliser","pos":"VER"} ,
		{"word":"sacrant","word_nosc":"sacrant","lemma":"sacrer","pos":"VER"} ,
		{"word":"sacre","word_nosc":"sacre","lemma":"sacrer","pos":"VER"} ,
		{"word":"sacrer","word_nosc":"sacrer","lemma":"sacrer","pos":"VER"} ,
		{"word":"sacrera","word_nosc":"sacrera","lemma":"sacrer","pos":"VER"} ,
		{"word":"sacrerai","word_nosc":"sacrerai","lemma":"sacrer","pos":"VER"} ,
		{"word":"sacres","word_nosc":"sacres","lemma":"sacrer","pos":"VER"} ,
		{"word":"sacrifia","word_nosc":"sacrifia","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifiaient","word_nosc":"sacrifiaient","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifiais","word_nosc":"sacrifiais","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifiait","word_nosc":"sacrifiait","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifiant","word_nosc":"sacrifiant","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifie","word_nosc":"sacrifie","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifient","word_nosc":"sacrifient","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifier","word_nosc":"sacrifier","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifiera","word_nosc":"sacrifiera","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifierai","word_nosc":"sacrifierai","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifieraient","word_nosc":"sacrifieraient","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifierais","word_nosc":"sacrifierais","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifierait","word_nosc":"sacrifierait","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifieras","word_nosc":"sacrifieras","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifierez","word_nosc":"sacrifierez","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifieriez","word_nosc":"sacrifieriez","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifierions","word_nosc":"sacrifierions","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifierons","word_nosc":"sacrifierons","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifies","word_nosc":"sacrifies","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifiez","word_nosc":"sacrifiez","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifiiez","word_nosc":"sacrifiiez","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifions","word_nosc":"sacrifions","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifiât","word_nosc":"sacrifiat","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifièrent","word_nosc":"sacrifierent","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifié","word_nosc":"sacrifie","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifiée","word_nosc":"sacrifiee","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifiées","word_nosc":"sacrifiees","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacrifiés","word_nosc":"sacrifies","lemma":"sacrifier","pos":"VER"} ,
		{"word":"sacré","word_nosc":"sacre","lemma":"sacrer","pos":"VER"} ,
		{"word":"sacrée","word_nosc":"sacree","lemma":"sacrer","pos":"VER"} ,
		{"word":"sacrées","word_nosc":"sacrees","lemma":"sacrer","pos":"VER"} ,
		{"word":"sacrés","word_nosc":"sacres","lemma":"sacrer","pos":"VER"} ,
		{"word":"safrane","word_nosc":"safrane","lemma":"safraner","pos":"VER"} ,
		{"word":"saigna","word_nosc":"saigna","lemma":"saigner","pos":"VER"} ,
		{"word":"saignaient","word_nosc":"saignaient","lemma":"saigner","pos":"VER"} ,
		{"word":"saignais","word_nosc":"saignais","lemma":"saigner","pos":"VER"} ,
		{"word":"saignait","word_nosc":"saignait","lemma":"saigner","pos":"VER"} ,
		{"word":"saignant","word_nosc":"saignant","lemma":"saigner","pos":"VER"} ,
		{"word":"saignasse","word_nosc":"saignasse","lemma":"saigner","pos":"VER"} ,
		{"word":"saigne","word_nosc":"saigne","lemma":"saigner","pos":"VER"} ,
		{"word":"saignent","word_nosc":"saignent","lemma":"saigner","pos":"VER"} ,
		{"word":"saigner","word_nosc":"saigner","lemma":"saigner","pos":"VER"} ,
		{"word":"saignera","word_nosc":"saignera","lemma":"saigner","pos":"VER"} ,
		{"word":"saignerai","word_nosc":"saignerai","lemma":"saigner","pos":"VER"} ,
		{"word":"saignerais","word_nosc":"saignerais","lemma":"saigner","pos":"VER"} ,
		{"word":"saignerait","word_nosc":"saignerait","lemma":"saigner","pos":"VER"} ,
		{"word":"saigneras","word_nosc":"saigneras","lemma":"saigner","pos":"VER"} ,
		{"word":"saignerez","word_nosc":"saignerez","lemma":"saigner","pos":"VER"} ,
		{"word":"saigneront","word_nosc":"saigneront","lemma":"saigner","pos":"VER"} ,
		{"word":"saignes","word_nosc":"saignes","lemma":"saigner","pos":"VER"} ,
		{"word":"saignez","word_nosc":"saignez","lemma":"saigner","pos":"VER"} ,
		{"word":"saigniez","word_nosc":"saigniez","lemma":"saigner","pos":"VER"} ,
		{"word":"saignons","word_nosc":"saignons","lemma":"saigner","pos":"VER"} ,
		{"word":"saigné","word_nosc":"saigne","lemma":"saigner","pos":"VER"} ,
		{"word":"saignée","word_nosc":"saignee","lemma":"saigner","pos":"VER"} ,
		{"word":"saignées","word_nosc":"saignees","lemma":"saigner","pos":"VER"} ,
		{"word":"saignés","word_nosc":"saignes","lemma":"saigner","pos":"VER"} ,
		{"word":"saillaient","word_nosc":"saillaient","lemma":"sailler","pos":"VER"} ,
		{"word":"saillait","word_nosc":"saillait","lemma":"sailler","pos":"VER"} ,
		{"word":"saillant","word_nosc":"saillant","lemma":"sailler","pos":"VER"} ,
		{"word":"saille","word_nosc":"saille","lemma":"sailler","pos":"VER"} ,
		{"word":"saillent","word_nosc":"saillent","lemma":"sailler","pos":"VER"} ,
		{"word":"sailli","word_nosc":"sailli","lemma":"saillir","pos":"VER"} ,
		{"word":"saillie","word_nosc":"saillie","lemma":"saillir","pos":"VER"} ,
		{"word":"saillir","word_nosc":"saillir","lemma":"saillir","pos":"VER"} ,
		{"word":"saillissent","word_nosc":"saillissent","lemma":"saillir","pos":"VER"} ,
		{"word":"saillit","word_nosc":"saillit","lemma":"saillir","pos":"VER"} ,
		{"word":"sais","word_nosc":"sais","lemma":"sais","pos":"VER"} ,
		{"word":"sais","word_nosc":"sais","lemma":"savoir","pos":"VER"} ,
		{"word":"saisi","word_nosc":"saisi","lemma":"saisir","pos":"VER"} ,
		{"word":"saisie","word_nosc":"saisie","lemma":"saisir","pos":"VER"} ,
		{"word":"saisies","word_nosc":"saisies","lemma":"saisir","pos":"VER"} ,
		{"word":"saisir","word_nosc":"saisir","lemma":"saisir","pos":"VER"} ,
		{"word":"saisira","word_nosc":"saisira","lemma":"saisir","pos":"VER"} ,
		{"word":"saisirai","word_nosc":"saisirai","lemma":"saisir","pos":"VER"} ,
		{"word":"saisirais","word_nosc":"saisirais","lemma":"saisir","pos":"VER"} ,
		{"word":"saisirait","word_nosc":"saisirait","lemma":"saisir","pos":"VER"} ,
		{"word":"saisiras","word_nosc":"saisiras","lemma":"saisir","pos":"VER"} ,
		{"word":"saisirent","word_nosc":"saisirent","lemma":"saisir","pos":"VER"} ,
		{"word":"saisirez","word_nosc":"saisirez","lemma":"saisir","pos":"VER"} ,
		{"word":"saisirions","word_nosc":"saisirions","lemma":"saisir","pos":"VER"} ,
		{"word":"saisirons","word_nosc":"saisirons","lemma":"saisir","pos":"VER"} ,
		{"word":"saisiront","word_nosc":"saisiront","lemma":"saisir","pos":"VER"} ,
		{"word":"saisis","word_nosc":"saisis","lemma":"saisir","pos":"VER"} ,
		{"word":"saisissaient","word_nosc":"saisissaient","lemma":"saisir","pos":"VER"} ,
		{"word":"saisissais","word_nosc":"saisissais","lemma":"saisir","pos":"VER"} ,
		{"word":"saisissait","word_nosc":"saisissait","lemma":"saisir","pos":"VER"} ,
		{"word":"saisissant","word_nosc":"saisissant","lemma":"saisir","pos":"VER"} ,
		{"word":"saisisse","word_nosc":"saisisse","lemma":"saisir","pos":"VER"} ,
		{"word":"saisissent","word_nosc":"saisissent","lemma":"saisir","pos":"VER"} ,
		{"word":"saisisses","word_nosc":"saisisses","lemma":"saisir","pos":"VER"} ,
		{"word":"saisissez","word_nosc":"saisissez","lemma":"saisir","pos":"VER"} ,
		{"word":"saisissiez","word_nosc":"saisissiez","lemma":"saisir","pos":"VER"} ,
		{"word":"saisissions","word_nosc":"saisissions","lemma":"saisir","pos":"VER"} ,
		{"word":"saisissons","word_nosc":"saisissons","lemma":"saisir","pos":"VER"} ,
		{"word":"saisit","word_nosc":"saisit","lemma":"saisir","pos":"VER"} ,
		{"word":"saisît","word_nosc":"saisit","lemma":"saisir","pos":"VER"} ,
		{"word":"sait","word_nosc":"sait","lemma":"savoir","pos":"VER"} ,
		{"word":"sala","word_nosc":"sala","lemma":"saler","pos":"VER"} ,
		{"word":"salader","word_nosc":"salader","lemma":"salader","pos":"VER"} ,
		{"word":"salaient","word_nosc":"salaient","lemma":"saler","pos":"VER"} ,
		{"word":"salait","word_nosc":"salait","lemma":"saler","pos":"VER"} ,
		{"word":"salant","word_nosc":"salant","lemma":"saler","pos":"VER"} ,
		{"word":"salariait","word_nosc":"salariait","lemma":"salarier","pos":"VER"} ,
		{"word":"salarier","word_nosc":"salarier","lemma":"salarier","pos":"VER"} ,
		{"word":"salarié","word_nosc":"salarie","lemma":"salarier","pos":"VER"} ,
		{"word":"salariée","word_nosc":"salariee","lemma":"salarier","pos":"VER"} ,
		{"word":"salariés","word_nosc":"salaries","lemma":"salarier","pos":"VER"} ,
		{"word":"salas","word_nosc":"salas","lemma":"saler","pos":"VER"} ,
		{"word":"sale","word_nosc":"sale","lemma":"saler","pos":"VER"} ,
		{"word":"saler","word_nosc":"saler","lemma":"saler","pos":"VER"} ,
		{"word":"salera","word_nosc":"salera","lemma":"saler","pos":"VER"} ,
		{"word":"sales","word_nosc":"sales","lemma":"saler","pos":"VER"} ,
		{"word":"salez","word_nosc":"salez","lemma":"saler","pos":"VER"} ,
		{"word":"sali","word_nosc":"sali","lemma":"salir","pos":"VER"} ,
		{"word":"salie","word_nosc":"salie","lemma":"salir","pos":"VER"} ,
		{"word":"salies","word_nosc":"salies","lemma":"salir","pos":"VER"} ,
		{"word":"salir","word_nosc":"salir","lemma":"salir","pos":"VER"} ,
		{"word":"salira","word_nosc":"salira","lemma":"salir","pos":"VER"} ,
		{"word":"salirai","word_nosc":"salirai","lemma":"salir","pos":"VER"} ,
		{"word":"saliraient","word_nosc":"saliraient","lemma":"salir","pos":"VER"} ,
		{"word":"salirais","word_nosc":"salirais","lemma":"salir","pos":"VER"} ,
		{"word":"salirait","word_nosc":"salirait","lemma":"salir","pos":"VER"} ,
		{"word":"salirez","word_nosc":"salirez","lemma":"salir","pos":"VER"} ,
		{"word":"salis","word_nosc":"salis","lemma":"salir","pos":"VER"} ,
		{"word":"salissaient","word_nosc":"salissaient","lemma":"salir","pos":"VER"} ,
		{"word":"salissait","word_nosc":"salissait","lemma":"salir","pos":"VER"} ,
		{"word":"salissant","word_nosc":"salissant","lemma":"salir","pos":"VER"} ,
		{"word":"salisse","word_nosc":"salisse","lemma":"salir","pos":"VER"} ,
		{"word":"salissent","word_nosc":"salissent","lemma":"salir","pos":"VER"} ,
		{"word":"salisses","word_nosc":"salisses","lemma":"salir","pos":"VER"} ,
		{"word":"salissez","word_nosc":"salissez","lemma":"salir","pos":"VER"} ,
		{"word":"salissiez","word_nosc":"salissiez","lemma":"salir","pos":"VER"} ,
		{"word":"salit","word_nosc":"salit","lemma":"salir","pos":"VER"} ,
		{"word":"saliva","word_nosc":"saliva","lemma":"saliver","pos":"VER"} ,
		{"word":"salivaient","word_nosc":"salivaient","lemma":"saliver","pos":"VER"} ,
		{"word":"salivais","word_nosc":"salivais","lemma":"saliver","pos":"VER"} ,
		{"word":"salivait","word_nosc":"salivait","lemma":"saliver","pos":"VER"} ,
		{"word":"salivant","word_nosc":"salivant","lemma":"saliver","pos":"VER"} ,
		{"word":"salive","word_nosc":"salive","lemma":"saliver","pos":"VER"} ,
		{"word":"salivent","word_nosc":"salivent","lemma":"saliver","pos":"VER"} ,
		{"word":"saliver","word_nosc":"saliver","lemma":"saliver","pos":"VER"} ,
		{"word":"salives","word_nosc":"salives","lemma":"saliver","pos":"VER"} ,
		{"word":"salivez","word_nosc":"salivez","lemma":"saliver","pos":"VER"} ,
		{"word":"salivé","word_nosc":"salive","lemma":"saliver","pos":"VER"} ,
		{"word":"salons","word_nosc":"salons","lemma":"saler","pos":"VER"} ,
		{"word":"salopent","word_nosc":"salopent","lemma":"saloper","pos":"VER"} ,
		{"word":"saloper","word_nosc":"saloper","lemma":"saloper","pos":"VER"} ,
		{"word":"salopez","word_nosc":"salopez","lemma":"saloper","pos":"VER"} ,
		{"word":"salopé","word_nosc":"salope","lemma":"saloper","pos":"VER"} ,
		{"word":"salopée","word_nosc":"salopee","lemma":"saloper","pos":"VER"} ,
		{"word":"salopées","word_nosc":"salopees","lemma":"saloper","pos":"VER"} ,
		{"word":"salopés","word_nosc":"salopes","lemma":"saloper","pos":"VER"} ,
		{"word":"salpêtrées","word_nosc":"salpetrees","lemma":"salpêtrer","pos":"VER"} ,
		{"word":"salpêtrés","word_nosc":"salpetres","lemma":"salpêtrer","pos":"VER"} ,
		{"word":"salua","word_nosc":"salua","lemma":"saluer","pos":"VER"} ,
		{"word":"saluai","word_nosc":"saluai","lemma":"saluer","pos":"VER"} ,
		{"word":"saluaient","word_nosc":"saluaient","lemma":"saluer","pos":"VER"} ,
		{"word":"saluais","word_nosc":"saluais","lemma":"saluer","pos":"VER"} ,
		{"word":"saluait","word_nosc":"saluait","lemma":"saluer","pos":"VER"} ,
		{"word":"saluant","word_nosc":"saluant","lemma":"saluer","pos":"VER"} ,
		{"word":"salue","word_nosc":"salue","lemma":"saluer","pos":"VER"} ,
		{"word":"saluent","word_nosc":"saluent","lemma":"saluer","pos":"VER"} ,
		{"word":"saluer","word_nosc":"saluer","lemma":"saluer","pos":"VER"} ,
		{"word":"saluera","word_nosc":"saluera","lemma":"saluer","pos":"VER"} ,
		{"word":"saluerai","word_nosc":"saluerai","lemma":"saluer","pos":"VER"} ,
		{"word":"salueraient","word_nosc":"salueraient","lemma":"saluer","pos":"VER"} ,
		{"word":"saluerais","word_nosc":"saluerais","lemma":"saluer","pos":"VER"} ,
		{"word":"saluerait","word_nosc":"saluerait","lemma":"saluer","pos":"VER"} ,
		{"word":"salueras","word_nosc":"salueras","lemma":"saluer","pos":"VER"} ,
		{"word":"saluerons","word_nosc":"saluerons","lemma":"saluer","pos":"VER"} ,
		{"word":"salueront","word_nosc":"salueront","lemma":"saluer","pos":"VER"} ,
		{"word":"salues","word_nosc":"salues","lemma":"saluer","pos":"VER"} ,
		{"word":"saluez","word_nosc":"saluez","lemma":"saluer","pos":"VER"} ,
		{"word":"saluiez","word_nosc":"saluiez","lemma":"saluer","pos":"VER"} ,
		{"word":"saluions","word_nosc":"saluions","lemma":"saluer","pos":"VER"} ,
		{"word":"saluons","word_nosc":"saluons","lemma":"saluer","pos":"VER"} ,
		{"word":"saluâmes","word_nosc":"saluames","lemma":"saluer","pos":"VER"} ,
		{"word":"saluât","word_nosc":"saluat","lemma":"saluer","pos":"VER"} ,
		{"word":"saluèrent","word_nosc":"saluerent","lemma":"saluer","pos":"VER"} ,
		{"word":"salué","word_nosc":"salue","lemma":"saluer","pos":"VER"} ,
		{"word":"saluée","word_nosc":"saluee","lemma":"saluer","pos":"VER"} ,
		{"word":"saluées","word_nosc":"saluees","lemma":"saluer","pos":"VER"} ,
		{"word":"salués","word_nosc":"salues","lemma":"saluer","pos":"VER"} ,
		{"word":"salé","word_nosc":"sale","lemma":"saler","pos":"VER"} ,
		{"word":"salée","word_nosc":"salee","lemma":"saler","pos":"VER"} ,
		{"word":"salées","word_nosc":"salees","lemma":"saler","pos":"VER"} ,
		{"word":"salés","word_nosc":"sales","lemma":"saler","pos":"VER"} ,
		{"word":"sanctifiai","word_nosc":"sanctifiai","lemma":"sanctifier","pos":"VER"} ,
		{"word":"sanctifiait","word_nosc":"sanctifiait","lemma":"sanctifier","pos":"VER"} ,
		{"word":"sanctifie","word_nosc":"sanctifie","lemma":"sanctifier","pos":"VER"} ,
		{"word":"sanctifier","word_nosc":"sanctifier","lemma":"sanctifier","pos":"VER"} ,
		{"word":"sanctifiez","word_nosc":"sanctifiez","lemma":"sanctifier","pos":"VER"} ,
		{"word":"sanctifié","word_nosc":"sanctifie","lemma":"sanctifier","pos":"VER"} ,
		{"word":"sanctifiée","word_nosc":"sanctifiee","lemma":"sanctifier","pos":"VER"} ,
		{"word":"sanctifiées","word_nosc":"sanctifiees","lemma":"sanctifier","pos":"VER"} ,
		{"word":"sanctifiés","word_nosc":"sanctifies","lemma":"sanctifier","pos":"VER"} ,
		{"word":"sanctionnaient","word_nosc":"sanctionnaient","lemma":"sanctionner","pos":"VER"} ,
		{"word":"sanctionnait","word_nosc":"sanctionnait","lemma":"sanctionner","pos":"VER"} ,
		{"word":"sanctionnant","word_nosc":"sanctionnant","lemma":"sanctionner","pos":"VER"} ,
		{"word":"sanctionne","word_nosc":"sanctionne","lemma":"sanctionner","pos":"VER"} ,
		{"word":"sanctionner","word_nosc":"sanctionner","lemma":"sanctionner","pos":"VER"} ,
		{"word":"sanctionnera","word_nosc":"sanctionnera","lemma":"sanctionner","pos":"VER"} ,
		{"word":"sanctionnerait","word_nosc":"sanctionnerait","lemma":"sanctionner","pos":"VER"} ,
		{"word":"sanctionnez","word_nosc":"sanctionnez","lemma":"sanctionner","pos":"VER"} ,
		{"word":"sanctionnions","word_nosc":"sanctionnions","lemma":"sanctionner","pos":"VER"} ,
		{"word":"sanctionnèrent","word_nosc":"sanctionnerent","lemma":"sanctionner","pos":"VER"} ,
		{"word":"sanctionné","word_nosc":"sanctionne","lemma":"sanctionner","pos":"VER"} ,
		{"word":"sanctionnée","word_nosc":"sanctionnee","lemma":"sanctionner","pos":"VER"} ,
		{"word":"sanctionnées","word_nosc":"sanctionnees","lemma":"sanctionner","pos":"VER"} ,
		{"word":"sanctionnés","word_nosc":"sanctionnes","lemma":"sanctionner","pos":"VER"} ,
		{"word":"sangla","word_nosc":"sangla","lemma":"sangler","pos":"VER"} ,
		{"word":"sanglai","word_nosc":"sanglai","lemma":"sangler","pos":"VER"} ,
		{"word":"sanglait","word_nosc":"sanglait","lemma":"sangler","pos":"VER"} ,
		{"word":"sanglant","word_nosc":"sanglant","lemma":"sangler","pos":"VER"} ,
		{"word":"sangle","word_nosc":"sangle","lemma":"sangler","pos":"VER"} ,
		{"word":"sanglent","word_nosc":"sanglent","lemma":"sangler","pos":"VER"} ,
		{"word":"sangler","word_nosc":"sangler","lemma":"sangler","pos":"VER"} ,
		{"word":"sanglez","word_nosc":"sanglez","lemma":"sangler","pos":"VER"} ,
		{"word":"sanglota","word_nosc":"sanglota","lemma":"sangloter","pos":"VER"} ,
		{"word":"sanglotai","word_nosc":"sanglotai","lemma":"sangloter","pos":"VER"} ,
		{"word":"sanglotaient","word_nosc":"sanglotaient","lemma":"sangloter","pos":"VER"} ,
		{"word":"sanglotais","word_nosc":"sanglotais","lemma":"sangloter","pos":"VER"} ,
		{"word":"sanglotait","word_nosc":"sanglotait","lemma":"sangloter","pos":"VER"} ,
		{"word":"sanglotant","word_nosc":"sanglotant","lemma":"sangloter","pos":"VER"} ,
		{"word":"sanglote","word_nosc":"sanglote","lemma":"sangloter","pos":"VER"} ,
		{"word":"sanglotent","word_nosc":"sanglotent","lemma":"sangloter","pos":"VER"} ,
		{"word":"sangloter","word_nosc":"sangloter","lemma":"sangloter","pos":"VER"} ,
		{"word":"sanglotez","word_nosc":"sanglotez","lemma":"sangloter","pos":"VER"} ,
		{"word":"sanglotâmes","word_nosc":"sanglotames","lemma":"sangloter","pos":"VER"} ,
		{"word":"sangloté","word_nosc":"sanglote","lemma":"sangloter","pos":"VER"} ,
		{"word":"sanglotés","word_nosc":"sanglotes","lemma":"sangloter","pos":"VER"} ,
		{"word":"sanglé","word_nosc":"sangle","lemma":"sangler","pos":"VER"} ,
		{"word":"sanglée","word_nosc":"sanglee","lemma":"sangler","pos":"VER"} ,
		{"word":"sanglées","word_nosc":"sanglees","lemma":"sangler","pos":"VER"} ,
		{"word":"sanglés","word_nosc":"sangles","lemma":"sangler","pos":"VER"} ,
		{"word":"saoula","word_nosc":"saoula","lemma":"saouler","pos":"VER"} ,
		{"word":"saoulaient","word_nosc":"saoulaient","lemma":"saouler","pos":"VER"} ,
		{"word":"saoulais","word_nosc":"saoulais","lemma":"saouler","pos":"VER"} ,
		{"word":"saoulait","word_nosc":"saoulait","lemma":"saouler","pos":"VER"} ,
		{"word":"saoulant","word_nosc":"saoulant","lemma":"saouler","pos":"VER"} ,
		{"word":"saoule","word_nosc":"saoule","lemma":"saouler","pos":"VER"} ,
		{"word":"saoulent","word_nosc":"saoulent","lemma":"saouler","pos":"VER"} ,
		{"word":"saouler","word_nosc":"saouler","lemma":"saouler","pos":"VER"} ,
		{"word":"saoulerait","word_nosc":"saoulerait","lemma":"saouler","pos":"VER"} ,
		{"word":"saouleras","word_nosc":"saouleras","lemma":"saouler","pos":"VER"} ,
		{"word":"saoulerez","word_nosc":"saoulerez","lemma":"saouler","pos":"VER"} ,
		{"word":"saoules","word_nosc":"saoules","lemma":"saouler","pos":"VER"} ,
		{"word":"saoulez","word_nosc":"saoulez","lemma":"saouler","pos":"VER"} ,
		{"word":"saoulions","word_nosc":"saoulions","lemma":"saouler","pos":"VER"} ,
		{"word":"saoulé","word_nosc":"saoule","lemma":"saouler","pos":"VER"} ,
		{"word":"saoulée","word_nosc":"saoulee","lemma":"saouler","pos":"VER"} ,
		{"word":"saoulées","word_nosc":"saoulees","lemma":"saouler","pos":"VER"} ,
		{"word":"saoulés","word_nosc":"saoules","lemma":"saouler","pos":"VER"} ,
		{"word":"sapaient","word_nosc":"sapaient","lemma":"saper","pos":"VER"} ,
		{"word":"sapait","word_nosc":"sapait","lemma":"saper","pos":"VER"} ,
		{"word":"sape","word_nosc":"sape","lemma":"saper","pos":"VER"} ,
		{"word":"sapent","word_nosc":"sapent","lemma":"saper","pos":"VER"} ,
		{"word":"saper","word_nosc":"saper","lemma":"saper","pos":"VER"} ,
		{"word":"saperai","word_nosc":"saperai","lemma":"saper","pos":"VER"} ,
		{"word":"sapes","word_nosc":"sapes","lemma":"saper","pos":"VER"} ,
		{"word":"sapez","word_nosc":"sapez","lemma":"saper","pos":"VER"} ,
		{"word":"saponifiées","word_nosc":"saponifiees","lemma":"saponifier","pos":"VER"} ,
		{"word":"sapé","word_nosc":"sape","lemma":"saper","pos":"VER"} ,
		{"word":"sapée","word_nosc":"sapee","lemma":"saper","pos":"VER"} ,
		{"word":"sapées","word_nosc":"sapees","lemma":"saper","pos":"VER"} ,
		{"word":"sapés","word_nosc":"sapes","lemma":"saper","pos":"VER"} ,
		{"word":"saque","word_nosc":"saque","lemma":"saquer","pos":"VER"} ,
		{"word":"saquent","word_nosc":"saquent","lemma":"saquer","pos":"VER"} ,
		{"word":"saquer","word_nosc":"saquer","lemma":"saquer","pos":"VER"} ,
		{"word":"saqué","word_nosc":"saque","lemma":"saquer","pos":"VER"} ,
		{"word":"saquée","word_nosc":"saquee","lemma":"saquer","pos":"VER"} ,
		{"word":"saqués","word_nosc":"saques","lemma":"saquer","pos":"VER"} ,
		{"word":"sarclait","word_nosc":"sarclait","lemma":"sarcler","pos":"VER"} ,
		{"word":"sarclant","word_nosc":"sarclant","lemma":"sarcler","pos":"VER"} ,
		{"word":"sarcle","word_nosc":"sarcle","lemma":"sarcler","pos":"VER"} ,
		{"word":"sarclent","word_nosc":"sarclent","lemma":"sarcler","pos":"VER"} ,
		{"word":"sarcler","word_nosc":"sarcler","lemma":"sarcler","pos":"VER"} ,
		{"word":"sarclées","word_nosc":"sarclees","lemma":"sarcler","pos":"VER"} ,
		{"word":"sardinaient","word_nosc":"sardinaient","lemma":"sardiner","pos":"VER"} ,
		{"word":"sasse","word_nosc":"sasse","lemma":"sasser","pos":"VER"} ,
		{"word":"satana","word_nosc":"satana","lemma":"sataner","pos":"VER"} ,
		{"word":"satanaient","word_nosc":"satanaient","lemma":"sataner","pos":"VER"} ,
		{"word":"satanas","word_nosc":"satanas","lemma":"sataner","pos":"VER"} ,
		{"word":"sataner","word_nosc":"sataner","lemma":"sataner","pos":"VER"} ,
		{"word":"sataniser","word_nosc":"sataniser","lemma":"sataniser","pos":"VER"} ,
		{"word":"satané","word_nosc":"satane","lemma":"sataner","pos":"VER"} ,
		{"word":"satanée","word_nosc":"satanee","lemma":"sataner","pos":"VER"} ,
		{"word":"satanés","word_nosc":"satanes","lemma":"sataner","pos":"VER"} ,
		{"word":"satelliser","word_nosc":"satelliser","lemma":"satelliser","pos":"VER"} ,
		{"word":"satellisée","word_nosc":"satellisee","lemma":"satelliser","pos":"VER"} ,
		{"word":"satinait","word_nosc":"satinait","lemma":"satiner","pos":"VER"} ,
		{"word":"satine","word_nosc":"satine","lemma":"satiner","pos":"VER"} ,
		{"word":"satinée","word_nosc":"satinee","lemma":"satiner","pos":"VER"} ,
		{"word":"satinées","word_nosc":"satinees","lemma":"satiner","pos":"VER"} ,
		{"word":"satinés","word_nosc":"satines","lemma":"satiner","pos":"VER"} ,
		{"word":"satirisaient","word_nosc":"satirisaient","lemma":"satiriser","pos":"VER"} ,
		{"word":"satisfaire","word_nosc":"satisfaire","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfais","word_nosc":"satisfais","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfaisaient","word_nosc":"satisfaisaient","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfaisais","word_nosc":"satisfaisais","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfaisait","word_nosc":"satisfaisait","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfaisant","word_nosc":"satisfaisant","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfait","word_nosc":"satisfait","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfaite","word_nosc":"satisfaite","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfaites","word_nosc":"satisfaites","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfaits","word_nosc":"satisfaits","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfasse","word_nosc":"satisfasse","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfassent","word_nosc":"satisfassent","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfera","word_nosc":"satisfera","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisferai","word_nosc":"satisferai","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisferaient","word_nosc":"satisferaient","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisferais","word_nosc":"satisferais","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisferait","word_nosc":"satisferait","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisferiez","word_nosc":"satisferiez","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisferons","word_nosc":"satisferons","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisferont","word_nosc":"satisferont","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfirent","word_nosc":"satisfirent","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfis","word_nosc":"satisfis","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfit","word_nosc":"satisfit","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satisfont","word_nosc":"satisfont","lemma":"satisfaire","pos":"VER"} ,
		{"word":"satonne","word_nosc":"satonne","lemma":"satonner","pos":"VER"} ,
		{"word":"satonner","word_nosc":"satonner","lemma":"satonner","pos":"VER"} ,
		{"word":"satonné","word_nosc":"satonne","lemma":"satonner","pos":"VER"} ,
		{"word":"saturait","word_nosc":"saturait","lemma":"saturer","pos":"VER"} ,
		{"word":"saturant","word_nosc":"saturant","lemma":"saturer","pos":"VER"} ,
		{"word":"sature","word_nosc":"sature","lemma":"saturer","pos":"VER"} ,
		{"word":"saturent","word_nosc":"saturent","lemma":"saturer","pos":"VER"} ,
		{"word":"saturer","word_nosc":"saturer","lemma":"saturer","pos":"VER"} ,
		{"word":"saturons","word_nosc":"saturons","lemma":"saturer","pos":"VER"} ,
		{"word":"saturé","word_nosc":"sature","lemma":"saturer","pos":"VER"} ,
		{"word":"saturée","word_nosc":"saturee","lemma":"saturer","pos":"VER"} ,
		{"word":"saturées","word_nosc":"saturees","lemma":"saturer","pos":"VER"} ,
		{"word":"saturés","word_nosc":"satures","lemma":"saturer","pos":"VER"} ,
		{"word":"sauce","word_nosc":"sauce","lemma":"saucer","pos":"VER"} ,
		{"word":"saucent","word_nosc":"saucent","lemma":"saucer","pos":"VER"} ,
		{"word":"saucer","word_nosc":"saucer","lemma":"saucer","pos":"VER"} ,
		{"word":"sauces","word_nosc":"sauces","lemma":"saucer","pos":"VER"} ,
		{"word":"saucissonnant","word_nosc":"saucissonnant","lemma":"saucissonner","pos":"VER"} ,
		{"word":"saucissonne","word_nosc":"saucissonne","lemma":"saucissonner","pos":"VER"} ,
		{"word":"saucissonner","word_nosc":"saucissonner","lemma":"saucissonner","pos":"VER"} ,
		{"word":"saucissonné","word_nosc":"saucissonne","lemma":"saucissonner","pos":"VER"} ,
		{"word":"saucissonnée","word_nosc":"saucissonnee","lemma":"saucissonner","pos":"VER"} ,
		{"word":"saucissonnés","word_nosc":"saucissonnes","lemma":"saucissonner","pos":"VER"} ,
		{"word":"saumurer","word_nosc":"saumurer","lemma":"saumurer","pos":"VER"} ,
		{"word":"saupoudra","word_nosc":"saupoudra","lemma":"saupoudrer","pos":"VER"} ,
		{"word":"saupoudrai","word_nosc":"saupoudrai","lemma":"saupoudrer","pos":"VER"} ,
		{"word":"saupoudraient","word_nosc":"saupoudraient","lemma":"saupoudrer","pos":"VER"} ,
		{"word":"saupoudrait","word_nosc":"saupoudrait","lemma":"saupoudrer","pos":"VER"} ,
		{"word":"saupoudrant","word_nosc":"saupoudrant","lemma":"saupoudrer","pos":"VER"} ,
		{"word":"saupoudre","word_nosc":"saupoudre","lemma":"saupoudrer","pos":"VER"} ,
		{"word":"saupoudrer","word_nosc":"saupoudrer","lemma":"saupoudrer","pos":"VER"} ,
		{"word":"saupoudrez","word_nosc":"saupoudrez","lemma":"saupoudrer","pos":"VER"} ,
		{"word":"saupoudriez","word_nosc":"saupoudriez","lemma":"saupoudrer","pos":"VER"} ,
		{"word":"saupoudrons","word_nosc":"saupoudrons","lemma":"saupoudrer","pos":"VER"} ,
		{"word":"saupoudré","word_nosc":"saupoudre","lemma":"saupoudrer","pos":"VER"} ,
		{"word":"saupoudrée","word_nosc":"saupoudree","lemma":"saupoudrer","pos":"VER"} ,
		{"word":"saupoudrées","word_nosc":"saupoudrees","lemma":"saupoudrer","pos":"VER"} ,
		{"word":"saupoudrés","word_nosc":"saupoudres","lemma":"saupoudrer","pos":"VER"} ,
		{"word":"saura","word_nosc":"saura","lemma":"savoir","pos":"VER"} ,
		{"word":"saurai","word_nosc":"saurai","lemma":"savoir","pos":"VER"} ,
		{"word":"sauraient","word_nosc":"sauraient","lemma":"savoir","pos":"VER"} ,
		{"word":"saurais","word_nosc":"saurais","lemma":"saurer","pos":"VER"} ,
		{"word":"saurais","word_nosc":"saurais","lemma":"savoir","pos":"VER"} ,
		{"word":"saurait","word_nosc":"saurait","lemma":"savoir","pos":"VER"} ,
		{"word":"sauras","word_nosc":"sauras","lemma":"savoir","pos":"VER"} ,
		{"word":"saurer","word_nosc":"saurer","lemma":"saurer","pos":"VER"} ,
		{"word":"saurez","word_nosc":"saurez","lemma":"saurer","pos":"VER"} ,
		{"word":"saurez","word_nosc":"saurez","lemma":"savoir","pos":"VER"} ,
		{"word":"sauri","word_nosc":"sauri","lemma":"saurir","pos":"VER"} ,
		{"word":"sauriez","word_nosc":"sauriez","lemma":"saurer","pos":"VER"} ,
		{"word":"sauriez","word_nosc":"sauriez","lemma":"savoir","pos":"VER"} ,
		{"word":"saurions","word_nosc":"saurions","lemma":"saurer","pos":"VER"} ,
		{"word":"saurions","word_nosc":"saurions","lemma":"savoir","pos":"VER"} ,
		{"word":"saurons","word_nosc":"saurons","lemma":"saurer","pos":"VER"} ,
		{"word":"saurons","word_nosc":"saurons","lemma":"savoir","pos":"VER"} ,
		{"word":"sauront","word_nosc":"sauront","lemma":"savoir","pos":"VER"} ,
		{"word":"sauta","word_nosc":"sauta","lemma":"sauter","pos":"VER"} ,
		{"word":"sautai","word_nosc":"sautai","lemma":"sauter","pos":"VER"} ,
		{"word":"sautaient","word_nosc":"sautaient","lemma":"sauter","pos":"VER"} ,
		{"word":"sautais","word_nosc":"sautais","lemma":"sauter","pos":"VER"} ,
		{"word":"sautait","word_nosc":"sautait","lemma":"sauter","pos":"VER"} ,
		{"word":"sautant","word_nosc":"sautant","lemma":"sauter","pos":"VER"} ,
		{"word":"saute","word_nosc":"saute","lemma":"sauter","pos":"VER"} ,
		{"word":"sautelant","word_nosc":"sautelant","lemma":"sauteler","pos":"VER"} ,
		{"word":"sautent","word_nosc":"sautent","lemma":"sauter","pos":"VER"} ,
		{"word":"sauter","word_nosc":"sauter","lemma":"sauter","pos":"VER"} ,
		{"word":"sautera","word_nosc":"sautera","lemma":"sauter","pos":"VER"} ,
		{"word":"sauterai","word_nosc":"sauterai","lemma":"sauter","pos":"VER"} ,
		{"word":"sauteraient","word_nosc":"sauteraient","lemma":"sauter","pos":"VER"} ,
		{"word":"sauterais","word_nosc":"sauterais","lemma":"sauter","pos":"VER"} ,
		{"word":"sauterait","word_nosc":"sauterait","lemma":"sauter","pos":"VER"} ,
		{"word":"sauteras","word_nosc":"sauteras","lemma":"sauter","pos":"VER"} ,
		{"word":"sauterez","word_nosc":"sauterez","lemma":"sauter","pos":"VER"} ,
		{"word":"sauteriez","word_nosc":"sauteriez","lemma":"sauter","pos":"VER"} ,
		{"word":"sauterons","word_nosc":"sauterons","lemma":"sauter","pos":"VER"} ,
		{"word":"sauteront","word_nosc":"sauteront","lemma":"sauter","pos":"VER"} ,
		{"word":"sautes","word_nosc":"sautes","lemma":"sauter","pos":"VER"} ,
		{"word":"sautez","word_nosc":"sautez","lemma":"sauter","pos":"VER"} ,
		{"word":"sautiez","word_nosc":"sautiez","lemma":"sauter","pos":"VER"} ,
		{"word":"sautilla","word_nosc":"sautilla","lemma":"sautiller","pos":"VER"} ,
		{"word":"sautillaient","word_nosc":"sautillaient","lemma":"sautiller","pos":"VER"} ,
		{"word":"sautillais","word_nosc":"sautillais","lemma":"sautiller","pos":"VER"} ,
		{"word":"sautillait","word_nosc":"sautillait","lemma":"sautiller","pos":"VER"} ,
		{"word":"sautillant","word_nosc":"sautillant","lemma":"sautiller","pos":"VER"} ,
		{"word":"sautille","word_nosc":"sautille","lemma":"sautiller","pos":"VER"} ,
		{"word":"sautillent","word_nosc":"sautillent","lemma":"sautiller","pos":"VER"} ,
		{"word":"sautiller","word_nosc":"sautiller","lemma":"sautiller","pos":"VER"} ,
		{"word":"sautilles","word_nosc":"sautilles","lemma":"sautiller","pos":"VER"} ,
		{"word":"sautillez","word_nosc":"sautillez","lemma":"sautiller","pos":"VER"} ,
		{"word":"sautillèrent","word_nosc":"sautillerent","lemma":"sautiller","pos":"VER"} ,
		{"word":"sautillé","word_nosc":"sautille","lemma":"sautiller","pos":"VER"} ,
		{"word":"sautions","word_nosc":"sautions","lemma":"sauter","pos":"VER"} ,
		{"word":"sautons","word_nosc":"sautons","lemma":"sauter","pos":"VER"} ,
		{"word":"sautâmes","word_nosc":"sautames","lemma":"sauter","pos":"VER"} ,
		{"word":"sautât","word_nosc":"sautat","lemma":"sauter","pos":"VER"} ,
		{"word":"sautèrent","word_nosc":"sauterent","lemma":"sauter","pos":"VER"} ,
		{"word":"sauté","word_nosc":"saute","lemma":"sauter","pos":"VER"} ,
		{"word":"sautée","word_nosc":"sautee","lemma":"sauter","pos":"VER"} ,
		{"word":"sautées","word_nosc":"sautees","lemma":"sauter","pos":"VER"} ,
		{"word":"sautés","word_nosc":"sautes","lemma":"sauter","pos":"VER"} ,
		{"word":"sauva","word_nosc":"sauva","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvai","word_nosc":"sauvai","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvaient","word_nosc":"sauvaient","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvais","word_nosc":"sauvais","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvait","word_nosc":"sauvait","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvant","word_nosc":"sauvant","lemma":"sauver","pos":"VER"} ,
		{"word":"sauve","word_nosc":"sauve","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvegardaient","word_nosc":"sauvegardaient","lemma":"sauvegarder","pos":"VER"} ,
		{"word":"sauvegardait","word_nosc":"sauvegardait","lemma":"sauvegarder","pos":"VER"} ,
		{"word":"sauvegardant","word_nosc":"sauvegardant","lemma":"sauvegarder","pos":"VER"} ,
		{"word":"sauvegarde","word_nosc":"sauvegarde","lemma":"sauvegarder","pos":"VER"} ,
		{"word":"sauvegarder","word_nosc":"sauvegarder","lemma":"sauvegarder","pos":"VER"} ,
		{"word":"sauvegarderaient","word_nosc":"sauvegarderaient","lemma":"sauvegarder","pos":"VER"} ,
		{"word":"sauvegarderait","word_nosc":"sauvegarderait","lemma":"sauvegarder","pos":"VER"} ,
		{"word":"sauvegardes","word_nosc":"sauvegardes","lemma":"sauvegarder","pos":"VER"} ,
		{"word":"sauvegardât","word_nosc":"sauvegardat","lemma":"sauvegarder","pos":"VER"} ,
		{"word":"sauvegardé","word_nosc":"sauvegarde","lemma":"sauvegarder","pos":"VER"} ,
		{"word":"sauvegardée","word_nosc":"sauvegardee","lemma":"sauvegarder","pos":"VER"} ,
		{"word":"sauvegardées","word_nosc":"sauvegardees","lemma":"sauvegarder","pos":"VER"} ,
		{"word":"sauvegardés","word_nosc":"sauvegardes","lemma":"sauvegarder","pos":"VER"} ,
		{"word":"sauvent","word_nosc":"sauvent","lemma":"sauver","pos":"VER"} ,
		{"word":"sauver","word_nosc":"sauver","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvera","word_nosc":"sauvera","lemma":"sauver","pos":"VER"} ,
		{"word":"sauverai","word_nosc":"sauverai","lemma":"sauver","pos":"VER"} ,
		{"word":"sauveraient","word_nosc":"sauveraient","lemma":"sauver","pos":"VER"} ,
		{"word":"sauverais","word_nosc":"sauverais","lemma":"sauver","pos":"VER"} ,
		{"word":"sauverait","word_nosc":"sauverait","lemma":"sauver","pos":"VER"} ,
		{"word":"sauveras","word_nosc":"sauveras","lemma":"sauver","pos":"VER"} ,
		{"word":"sauverez","word_nosc":"sauverez","lemma":"sauver","pos":"VER"} ,
		{"word":"sauverons","word_nosc":"sauverons","lemma":"sauver","pos":"VER"} ,
		{"word":"sauveront","word_nosc":"sauveront","lemma":"sauver","pos":"VER"} ,
		{"word":"sauves","word_nosc":"sauves","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvette","word_nosc":"sauvette","lemma":"sauveter","pos":"VER"} ,
		{"word":"sauvez","word_nosc":"sauvez","lemma":"sauver","pos":"VER"} ,
		{"word":"sauviez","word_nosc":"sauviez","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvions","word_nosc":"sauvions","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvons","word_nosc":"sauvons","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvâmes","word_nosc":"sauvames","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvât","word_nosc":"sauvat","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvèrent","word_nosc":"sauverent","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvé","word_nosc":"sauve","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvée","word_nosc":"sauvee","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvées","word_nosc":"sauvees","lemma":"sauver","pos":"VER"} ,
		{"word":"sauvés","word_nosc":"sauves","lemma":"sauver","pos":"VER"} ,
		{"word":"sauça","word_nosc":"sauca","lemma":"saucer","pos":"VER"} ,
		{"word":"sauçant","word_nosc":"saucant","lemma":"saucer","pos":"VER"} ,
		{"word":"sauçons","word_nosc":"saucons","lemma":"saucer","pos":"VER"} ,
		{"word":"savaient","word_nosc":"savaient","lemma":"savoir","pos":"VER"} ,
		{"word":"savais","word_nosc":"savais","lemma":"savoir","pos":"VER"} ,
		{"word":"savait","word_nosc":"savait","lemma":"savoir","pos":"VER"} ,
		{"word":"savata","word_nosc":"savata","lemma":"savater","pos":"VER"} ,
		{"word":"savatait","word_nosc":"savatait","lemma":"savater","pos":"VER"} ,
		{"word":"savate","word_nosc":"savate","lemma":"savater","pos":"VER"} ,
		{"word":"savater","word_nosc":"savater","lemma":"savater","pos":"VER"} ,
		{"word":"savates","word_nosc":"savates","lemma":"savater","pos":"VER"} ,
		{"word":"savent","word_nosc":"savent","lemma":"savoir","pos":"VER"} ,
		{"word":"savez","word_nosc":"savez","lemma":"savoir","pos":"VER"} ,
		{"word":"saviez","word_nosc":"saviez","lemma":"savoir","pos":"VER"} ,
		{"word":"savions","word_nosc":"savions","lemma":"savoir","pos":"VER"} ,
		{"word":"savoir","word_nosc":"savoir","lemma":"savoir","pos":"VER"} ,
		{"word":"savonna","word_nosc":"savonna","lemma":"savonner","pos":"VER"} ,
		{"word":"savonnai","word_nosc":"savonnai","lemma":"savonner","pos":"VER"} ,
		{"word":"savonnaient","word_nosc":"savonnaient","lemma":"savonner","pos":"VER"} ,
		{"word":"savonnais","word_nosc":"savonnais","lemma":"savonner","pos":"VER"} ,
		{"word":"savonnait","word_nosc":"savonnait","lemma":"savonner","pos":"VER"} ,
		{"word":"savonnant","word_nosc":"savonnant","lemma":"savonner","pos":"VER"} ,
		{"word":"savonne","word_nosc":"savonne","lemma":"savonner","pos":"VER"} ,
		{"word":"savonnent","word_nosc":"savonnent","lemma":"savonner","pos":"VER"} ,
		{"word":"savonner","word_nosc":"savonner","lemma":"savonner","pos":"VER"} ,
		{"word":"savonnera","word_nosc":"savonnera","lemma":"savonner","pos":"VER"} ,
		{"word":"savonné","word_nosc":"savonne","lemma":"savonner","pos":"VER"} ,
		{"word":"savonnée","word_nosc":"savonnee","lemma":"savonner","pos":"VER"} ,
		{"word":"savonnées","word_nosc":"savonnees","lemma":"savonner","pos":"VER"} ,
		{"word":"savons","word_nosc":"savons","lemma":"savoir","pos":"VER"} ,
		{"word":"savoura","word_nosc":"savoura","lemma":"savourer","pos":"VER"} ,
		{"word":"savourai","word_nosc":"savourai","lemma":"savourer","pos":"VER"} ,
		{"word":"savouraient","word_nosc":"savouraient","lemma":"savourer","pos":"VER"} ,
		{"word":"savourais","word_nosc":"savourais","lemma":"savourer","pos":"VER"} ,
		{"word":"savourait","word_nosc":"savourait","lemma":"savourer","pos":"VER"} ,
		{"word":"savourant","word_nosc":"savourant","lemma":"savourer","pos":"VER"} ,
		{"word":"savoure","word_nosc":"savoure","lemma":"savourer","pos":"VER"} ,
		{"word":"savourent","word_nosc":"savourent","lemma":"savourer","pos":"VER"} ,
		{"word":"savourer","word_nosc":"savourer","lemma":"savourer","pos":"VER"} ,
		{"word":"savourera","word_nosc":"savourera","lemma":"savourer","pos":"VER"} ,
		{"word":"savourerai","word_nosc":"savourerai","lemma":"savourer","pos":"VER"} ,
		{"word":"savourerait","word_nosc":"savourerait","lemma":"savourer","pos":"VER"} ,
		{"word":"savourerons","word_nosc":"savourerons","lemma":"savourer","pos":"VER"} ,
		{"word":"savourez","word_nosc":"savourez","lemma":"savourer","pos":"VER"} ,
		{"word":"savourions","word_nosc":"savourions","lemma":"savourer","pos":"VER"} ,
		{"word":"savourons","word_nosc":"savourons","lemma":"savourer","pos":"VER"} ,
		{"word":"savourèrent","word_nosc":"savourerent","lemma":"savourer","pos":"VER"} ,
		{"word":"savouré","word_nosc":"savoure","lemma":"savourer","pos":"VER"} ,
		{"word":"savourée","word_nosc":"savouree","lemma":"savourer","pos":"VER"} ,
		{"word":"savourés","word_nosc":"savoures","lemma":"savourer","pos":"VER"} ,
		{"word":"scalpa","word_nosc":"scalpa","lemma":"scalper","pos":"VER"} ,
		{"word":"scalpaient","word_nosc":"scalpaient","lemma":"scalper","pos":"VER"} ,
		{"word":"scalpait","word_nosc":"scalpait","lemma":"scalper","pos":"VER"} ,
		{"word":"scalpe","word_nosc":"scalpe","lemma":"scalper","pos":"VER"} ,
		{"word":"scalpent","word_nosc":"scalpent","lemma":"scalper","pos":"VER"} ,
		{"word":"scalper","word_nosc":"scalper","lemma":"scalper","pos":"VER"} ,
		{"word":"scalpera","word_nosc":"scalpera","lemma":"scalper","pos":"VER"} ,
		{"word":"scalpez","word_nosc":"scalpez","lemma":"scalper","pos":"VER"} ,
		{"word":"scalpé","word_nosc":"scalpe","lemma":"scalper","pos":"VER"} ,
		{"word":"scalpés","word_nosc":"scalpes","lemma":"scalper","pos":"VER"} ,
		{"word":"scanda","word_nosc":"scanda","lemma":"scander","pos":"VER"} ,
		{"word":"scandaient","word_nosc":"scandaient","lemma":"scander","pos":"VER"} ,
		{"word":"scandait","word_nosc":"scandait","lemma":"scander","pos":"VER"} ,
		{"word":"scandalisa","word_nosc":"scandalisa","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandalisai","word_nosc":"scandalisai","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandalisaient","word_nosc":"scandalisaient","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandalisais","word_nosc":"scandalisais","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandalisait","word_nosc":"scandalisait","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandalisant","word_nosc":"scandalisant","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandalise","word_nosc":"scandalise","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandalisent","word_nosc":"scandalisent","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandaliser","word_nosc":"scandaliser","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandalisera","word_nosc":"scandalisera","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandaliserait","word_nosc":"scandaliserait","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandalisons","word_nosc":"scandalisons","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandalisèrent","word_nosc":"scandaliserent","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandalisé","word_nosc":"scandalise","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandalisée","word_nosc":"scandalisee","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandalisées","word_nosc":"scandalisees","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandalisés","word_nosc":"scandalises","lemma":"scandaliser","pos":"VER"} ,
		{"word":"scandant","word_nosc":"scandant","lemma":"scander","pos":"VER"} ,
		{"word":"scande","word_nosc":"scande","lemma":"scander","pos":"VER"} ,
		{"word":"scandent","word_nosc":"scandent","lemma":"scander","pos":"VER"} ,
		{"word":"scander","word_nosc":"scander","lemma":"scander","pos":"VER"} ,
		{"word":"scandera","word_nosc":"scandera","lemma":"scander","pos":"VER"} ,
		{"word":"scandé","word_nosc":"scande","lemma":"scander","pos":"VER"} ,
		{"word":"scandée","word_nosc":"scandee","lemma":"scander","pos":"VER"} ,
		{"word":"scandées","word_nosc":"scandees","lemma":"scander","pos":"VER"} ,
		{"word":"scandés","word_nosc":"scandes","lemma":"scander","pos":"VER"} ,
		{"word":"scannais","word_nosc":"scannais","lemma":"scanner","pos":"VER"} ,
		{"word":"scanne","word_nosc":"scanne","lemma":"scanner","pos":"VER"} ,
		{"word":"scannent","word_nosc":"scannent","lemma":"scanner","pos":"VER"} ,
		{"word":"scanner","word_nosc":"scanner","lemma":"scanner","pos":"VER"} ,
		{"word":"scannera","word_nosc":"scannera","lemma":"scanner","pos":"VER"} ,
		{"word":"scanneront","word_nosc":"scanneront","lemma":"scanner","pos":"VER"} ,
		{"word":"scannes","word_nosc":"scannes","lemma":"scanner","pos":"VER"} ,
		{"word":"scannez","word_nosc":"scannez","lemma":"scanner","pos":"VER"} ,
		{"word":"scannons","word_nosc":"scannons","lemma":"scanner","pos":"VER"} ,
		{"word":"scanné","word_nosc":"scanne","lemma":"scanner","pos":"VER"} ,
		{"word":"scannée","word_nosc":"scannee","lemma":"scanner","pos":"VER"} ,
		{"word":"scannés","word_nosc":"scannes","lemma":"scanner","pos":"VER"} ,
		{"word":"scarifiant","word_nosc":"scarifiant","lemma":"scarifier","pos":"VER"} ,
		{"word":"scarifié","word_nosc":"scarifie","lemma":"scarifier","pos":"VER"} ,
		{"word":"scella","word_nosc":"scella","lemma":"sceller","pos":"VER"} ,
		{"word":"scellaient","word_nosc":"scellaient","lemma":"sceller","pos":"VER"} ,
		{"word":"scellait","word_nosc":"scellait","lemma":"sceller","pos":"VER"} ,
		{"word":"scellant","word_nosc":"scellant","lemma":"sceller","pos":"VER"} ,
		{"word":"scelle","word_nosc":"scelle","lemma":"sceller","pos":"VER"} ,
		{"word":"scellent","word_nosc":"scellent","lemma":"sceller","pos":"VER"} ,
		{"word":"sceller","word_nosc":"sceller","lemma":"sceller","pos":"VER"} ,
		{"word":"scellera","word_nosc":"scellera","lemma":"sceller","pos":"VER"} ,
		{"word":"scellerait","word_nosc":"scellerait","lemma":"sceller","pos":"VER"} ,
		{"word":"scelleront","word_nosc":"scelleront","lemma":"sceller","pos":"VER"} ,
		{"word":"scellez","word_nosc":"scellez","lemma":"sceller","pos":"VER"} ,
		{"word":"scellons","word_nosc":"scellons","lemma":"sceller","pos":"VER"} ,
		{"word":"scellèrent","word_nosc":"scellerent","lemma":"sceller","pos":"VER"} ,
		{"word":"scellé","word_nosc":"scelle","lemma":"sceller","pos":"VER"} ,
		{"word":"scellée","word_nosc":"scellee","lemma":"sceller","pos":"VER"} ,
		{"word":"scellées","word_nosc":"scellees","lemma":"sceller","pos":"VER"} ,
		{"word":"scellés","word_nosc":"scelles","lemma":"sceller","pos":"VER"} ,
		{"word":"schlinguait","word_nosc":"schlinguait","lemma":"schlinguer","pos":"VER"} ,
		{"word":"schlinguant","word_nosc":"schlinguant","lemma":"schlinguer","pos":"VER"} ,
		{"word":"schlingue","word_nosc":"schlingue","lemma":"schlinguer","pos":"VER"} ,
		{"word":"schlinguer","word_nosc":"schlinguer","lemma":"schlinguer","pos":"VER"} ,
		{"word":"schlingues","word_nosc":"schlingues","lemma":"schlinguer","pos":"VER"} ,
		{"word":"schmecte","word_nosc":"schmecte","lemma":"schmecter","pos":"VER"} ,
		{"word":"schnouffe","word_nosc":"schnouffe","lemma":"schnouffer","pos":"VER"} ,
		{"word":"schématise","word_nosc":"schematise","lemma":"schématiser","pos":"VER"} ,
		{"word":"schématiser","word_nosc":"schematiser","lemma":"schématiser","pos":"VER"} ,
		{"word":"schématisée","word_nosc":"schematisee","lemma":"schématiser","pos":"VER"} ,
		{"word":"scia","word_nosc":"scia","lemma":"scier","pos":"VER"} ,
		{"word":"sciaient","word_nosc":"sciaient","lemma":"scier","pos":"VER"} ,
		{"word":"sciais","word_nosc":"sciais","lemma":"scier","pos":"VER"} ,
		{"word":"sciait","word_nosc":"sciait","lemma":"scier","pos":"VER"} ,
		{"word":"sciant","word_nosc":"sciant","lemma":"scier","pos":"VER"} ,
		{"word":"scie","word_nosc":"scie","lemma":"scier","pos":"VER"} ,
		{"word":"scient","word_nosc":"scient","lemma":"scier","pos":"VER"} ,
		{"word":"scier","word_nosc":"scier","lemma":"scier","pos":"VER"} ,
		{"word":"sciera","word_nosc":"sciera","lemma":"scier","pos":"VER"} ,
		{"word":"scierait","word_nosc":"scierait","lemma":"scier","pos":"VER"} ,
		{"word":"scieront","word_nosc":"scieront","lemma":"scier","pos":"VER"} ,
		{"word":"scies","word_nosc":"scies","lemma":"scier","pos":"VER"} ,
		{"word":"sciez","word_nosc":"sciez","lemma":"scier","pos":"VER"} ,
		{"word":"scindaient","word_nosc":"scindaient","lemma":"scinder","pos":"VER"} ,
		{"word":"scindait","word_nosc":"scindait","lemma":"scinder","pos":"VER"} ,
		{"word":"scindant","word_nosc":"scindant","lemma":"scinder","pos":"VER"} ,
		{"word":"scinde","word_nosc":"scinde","lemma":"scinder","pos":"VER"} ,
		{"word":"scinder","word_nosc":"scinder","lemma":"scinder","pos":"VER"} ,
		{"word":"scinderas","word_nosc":"scinderas","lemma":"scinder","pos":"VER"} ,
		{"word":"scindez","word_nosc":"scindez","lemma":"scinder","pos":"VER"} ,
		{"word":"scindèrent","word_nosc":"scinderent","lemma":"scinder","pos":"VER"} ,
		{"word":"scindé","word_nosc":"scinde","lemma":"scinder","pos":"VER"} ,
		{"word":"scindée","word_nosc":"scindee","lemma":"scinder","pos":"VER"} ,
		{"word":"scindés","word_nosc":"scindes","lemma":"scinder","pos":"VER"} ,
		{"word":"scintillaient","word_nosc":"scintillaient","lemma":"scintiller","pos":"VER"} ,
		{"word":"scintillait","word_nosc":"scintillait","lemma":"scintiller","pos":"VER"} ,
		{"word":"scintillant","word_nosc":"scintillant","lemma":"scintiller","pos":"VER"} ,
		{"word":"scintille","word_nosc":"scintille","lemma":"scintiller","pos":"VER"} ,
		{"word":"scintillent","word_nosc":"scintillent","lemma":"scintiller","pos":"VER"} ,
		{"word":"scintiller","word_nosc":"scintiller","lemma":"scintiller","pos":"VER"} ,
		{"word":"scintilleraient","word_nosc":"scintilleraient","lemma":"scintiller","pos":"VER"} ,
		{"word":"scintillerais","word_nosc":"scintillerais","lemma":"scintiller","pos":"VER"} ,
		{"word":"scintilleront","word_nosc":"scintilleront","lemma":"scintiller","pos":"VER"} ,
		{"word":"scintilles","word_nosc":"scintilles","lemma":"scintiller","pos":"VER"} ,
		{"word":"scintillons","word_nosc":"scintillons","lemma":"scintiller","pos":"VER"} ,
		{"word":"scintillèrent","word_nosc":"scintillerent","lemma":"scintiller","pos":"VER"} ,
		{"word":"scintillé","word_nosc":"scintille","lemma":"scintiller","pos":"VER"} ,
		{"word":"scièrent","word_nosc":"scierent","lemma":"scier","pos":"VER"} ,
		{"word":"scié","word_nosc":"scie","lemma":"scier","pos":"VER"} ,
		{"word":"sciée","word_nosc":"sciee","lemma":"scier","pos":"VER"} ,
		{"word":"sciées","word_nosc":"sciees","lemma":"scier","pos":"VER"} ,
		{"word":"sciés","word_nosc":"scies","lemma":"scier","pos":"VER"} ,
		{"word":"sclérose","word_nosc":"sclerose","lemma":"scléroser","pos":"VER"} ,
		{"word":"scléroserai","word_nosc":"scleroserai","lemma":"scléroser","pos":"VER"} ,
		{"word":"sclérosé","word_nosc":"sclerose","lemma":"scléroser","pos":"VER"} ,
		{"word":"sclérosée","word_nosc":"sclerosee","lemma":"scléroser","pos":"VER"} ,
		{"word":"scolariser","word_nosc":"scolariser","lemma":"scolariser","pos":"VER"} ,
		{"word":"scotchais","word_nosc":"scotchais","lemma":"scotcher","pos":"VER"} ,
		{"word":"scotcher","word_nosc":"scotcher","lemma":"scotcher","pos":"VER"} ,
		{"word":"scotches","word_nosc":"scotches","lemma":"scotcher","pos":"VER"} ,
		{"word":"scotchons","word_nosc":"scotchons","lemma":"scotcher","pos":"VER"} ,
		{"word":"scotché","word_nosc":"scotche","lemma":"scotcher","pos":"VER"} ,
		{"word":"scotchée","word_nosc":"scotchee","lemma":"scotcher","pos":"VER"} ,
		{"word":"scotchées","word_nosc":"scotchees","lemma":"scotcher","pos":"VER"} ,
		{"word":"scotchés","word_nosc":"scotches","lemma":"scotcher","pos":"VER"} ,
		{"word":"scotomisant","word_nosc":"scotomisant","lemma":"scotomiser","pos":"VER"} ,
		{"word":"scotomiser","word_nosc":"scotomiser","lemma":"scotomiser","pos":"VER"} ,
		{"word":"scratcher","word_nosc":"scratcher","lemma":"scratcher","pos":"VER"} ,
		{"word":"scribouille","word_nosc":"scribouille","lemma":"scribouiller","pos":"VER"} ,
		{"word":"scribouiller","word_nosc":"scribouiller","lemma":"scribouiller","pos":"VER"} ,
		{"word":"scruta","word_nosc":"scruta","lemma":"scruter","pos":"VER"} ,
		{"word":"scrutai","word_nosc":"scrutai","lemma":"scruter","pos":"VER"} ,
		{"word":"scrutaient","word_nosc":"scrutaient","lemma":"scruter","pos":"VER"} ,
		{"word":"scrutais","word_nosc":"scrutais","lemma":"scruter","pos":"VER"} ,
		{"word":"scrutait","word_nosc":"scrutait","lemma":"scruter","pos":"VER"} ,
		{"word":"scrutant","word_nosc":"scrutant","lemma":"scruter","pos":"VER"} ,
		{"word":"scrute","word_nosc":"scrute","lemma":"scruter","pos":"VER"} ,
		{"word":"scrutent","word_nosc":"scrutent","lemma":"scruter","pos":"VER"} ,
		{"word":"scruter","word_nosc":"scruter","lemma":"scruter","pos":"VER"} ,
		{"word":"scrutera","word_nosc":"scrutera","lemma":"scruter","pos":"VER"} ,
		{"word":"scruterai","word_nosc":"scruterai","lemma":"scruter","pos":"VER"} ,
		{"word":"scruterait","word_nosc":"scruterait","lemma":"scruter","pos":"VER"} ,
		{"word":"scruteront","word_nosc":"scruteront","lemma":"scruter","pos":"VER"} ,
		{"word":"scrutez","word_nosc":"scrutez","lemma":"scruter","pos":"VER"} ,
		{"word":"scrutions","word_nosc":"scrutions","lemma":"scruter","pos":"VER"} ,
		{"word":"scrutons","word_nosc":"scrutons","lemma":"scruter","pos":"VER"} ,
		{"word":"scrutâmes","word_nosc":"scrutames","lemma":"scruter","pos":"VER"} ,
		{"word":"scrutèrent","word_nosc":"scruterent","lemma":"scruter","pos":"VER"} ,
		{"word":"scruté","word_nosc":"scrute","lemma":"scruter","pos":"VER"} ,
		{"word":"scrutée","word_nosc":"scrutee","lemma":"scruter","pos":"VER"} ,
		{"word":"scrutés","word_nosc":"scrutes","lemma":"scruter","pos":"VER"} ,
		{"word":"sculpta","word_nosc":"sculpta","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculptaient","word_nosc":"sculptaient","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculptais","word_nosc":"sculptais","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculptait","word_nosc":"sculptait","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculptant","word_nosc":"sculptant","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculpte","word_nosc":"sculpte","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculptent","word_nosc":"sculptent","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculpter","word_nosc":"sculpter","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculptera","word_nosc":"sculptera","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculpterai","word_nosc":"sculpterai","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculpterait","word_nosc":"sculpterait","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculpteras","word_nosc":"sculpteras","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculpterez","word_nosc":"sculpterez","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculptes","word_nosc":"sculptes","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculptez","word_nosc":"sculptez","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculptiez","word_nosc":"sculptiez","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculptâtes","word_nosc":"sculptates","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculpté","word_nosc":"sculpte","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculptée","word_nosc":"sculptee","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculptées","word_nosc":"sculptees","lemma":"sculpter","pos":"VER"} ,
		{"word":"sculptés","word_nosc":"sculptes","lemma":"sculpter","pos":"VER"} ,
		{"word":"secondaient","word_nosc":"secondaient","lemma":"seconder","pos":"VER"} ,
		{"word":"secondais","word_nosc":"secondais","lemma":"seconder","pos":"VER"} ,
		{"word":"secondait","word_nosc":"secondait","lemma":"seconder","pos":"VER"} ,
		{"word":"secondant","word_nosc":"secondant","lemma":"seconder","pos":"VER"} ,
		{"word":"seconde","word_nosc":"seconde","lemma":"seconder","pos":"VER"} ,
		{"word":"secondent","word_nosc":"secondent","lemma":"seconder","pos":"VER"} ,
		{"word":"seconder","word_nosc":"seconder","lemma":"seconder","pos":"VER"} ,
		{"word":"secondera","word_nosc":"secondera","lemma":"seconder","pos":"VER"} ,
		{"word":"seconderai","word_nosc":"seconderai","lemma":"seconder","pos":"VER"} ,
		{"word":"seconderas","word_nosc":"seconderas","lemma":"seconder","pos":"VER"} ,
		{"word":"seconderez","word_nosc":"seconderez","lemma":"seconder","pos":"VER"} ,
		{"word":"seconderont","word_nosc":"seconderont","lemma":"seconder","pos":"VER"} ,
		{"word":"secondes","word_nosc":"secondes","lemma":"seconder","pos":"VER"} ,
		{"word":"secondé","word_nosc":"seconde","lemma":"seconder","pos":"VER"} ,
		{"word":"secondée","word_nosc":"secondee","lemma":"seconder","pos":"VER"} ,
		{"word":"secondés","word_nosc":"secondes","lemma":"seconder","pos":"VER"} ,
		{"word":"secoua","word_nosc":"secoua","lemma":"secouer","pos":"VER"} ,
		{"word":"secouai","word_nosc":"secouai","lemma":"secouer","pos":"VER"} ,
		{"word":"secouaient","word_nosc":"secouaient","lemma":"secouer","pos":"VER"} ,
		{"word":"secouais","word_nosc":"secouais","lemma":"secouer","pos":"VER"} ,
		{"word":"secouait","word_nosc":"secouait","lemma":"secouer","pos":"VER"} ,
		{"word":"secouant","word_nosc":"secouant","lemma":"secouer","pos":"VER"} ,
		{"word":"secouassent","word_nosc":"secouassent","lemma":"secouer","pos":"VER"} ,
		{"word":"secoue","word_nosc":"secoue","lemma":"secouer","pos":"VER"} ,
		{"word":"secouent","word_nosc":"secouent","lemma":"secouer","pos":"VER"} ,
		{"word":"secouer","word_nosc":"secouer","lemma":"secouer","pos":"VER"} ,
		{"word":"secouera","word_nosc":"secouera","lemma":"secouer","pos":"VER"} ,
		{"word":"secouerai","word_nosc":"secouerai","lemma":"secouer","pos":"VER"} ,
		{"word":"secoueraient","word_nosc":"secoueraient","lemma":"secouer","pos":"VER"} ,
		{"word":"secouerais","word_nosc":"secouerais","lemma":"secouer","pos":"VER"} ,
		{"word":"secouerait","word_nosc":"secouerait","lemma":"secouer","pos":"VER"} ,
		{"word":"secouerez","word_nosc":"secouerez","lemma":"secouer","pos":"VER"} ,
		{"word":"secoueront","word_nosc":"secoueront","lemma":"secouer","pos":"VER"} ,
		{"word":"secoues","word_nosc":"secoues","lemma":"secouer","pos":"VER"} ,
		{"word":"secouez","word_nosc":"secouez","lemma":"secouer","pos":"VER"} ,
		{"word":"secouions","word_nosc":"secouions","lemma":"secouer","pos":"VER"} ,
		{"word":"secouons","word_nosc":"secouons","lemma":"secouer","pos":"VER"} ,
		{"word":"secouraient","word_nosc":"secouraient","lemma":"secourir","pos":"VER"} ,
		{"word":"secourait","word_nosc":"secourait","lemma":"secourir","pos":"VER"} ,
		{"word":"secourant","word_nosc":"secourant","lemma":"secourir","pos":"VER"} ,
		{"word":"secoures","word_nosc":"secoures","lemma":"secourir","pos":"VER"} ,
		{"word":"secourez","word_nosc":"secourez","lemma":"secourir","pos":"VER"} ,
		{"word":"secourions","word_nosc":"secourions","lemma":"secourir","pos":"VER"} ,
		{"word":"secourir","word_nosc":"secourir","lemma":"secourir","pos":"VER"} ,
		{"word":"secourra","word_nosc":"secourra","lemma":"secourir","pos":"VER"} ,
		{"word":"secours","word_nosc":"secours","lemma":"secourir","pos":"VER"} ,
		{"word":"secourt","word_nosc":"secourt","lemma":"secourir","pos":"VER"} ,
		{"word":"secouru","word_nosc":"secouru","lemma":"secourir","pos":"VER"} ,
		{"word":"secourue","word_nosc":"secourue","lemma":"secourir","pos":"VER"} ,
		{"word":"secourues","word_nosc":"secourues","lemma":"secourir","pos":"VER"} ,
		{"word":"secourus","word_nosc":"secourus","lemma":"secourir","pos":"VER"} ,
		{"word":"secourut","word_nosc":"secourut","lemma":"secourir","pos":"VER"} ,
		{"word":"secourût","word_nosc":"secourut","lemma":"secourir","pos":"VER"} ,
		{"word":"secouâmes","word_nosc":"secouames","lemma":"secouer","pos":"VER"} ,
		{"word":"secouèrent","word_nosc":"secouerent","lemma":"secouer","pos":"VER"} ,
		{"word":"secoué","word_nosc":"secoue","lemma":"secouer","pos":"VER"} ,
		{"word":"secouée","word_nosc":"secouee","lemma":"secouer","pos":"VER"} ,
		{"word":"secouées","word_nosc":"secouees","lemma":"secouer","pos":"VER"} ,
		{"word":"secoués","word_nosc":"secoues","lemma":"secouer","pos":"VER"} ,
		{"word":"secrète","word_nosc":"secrete","lemma":"secréter","pos":"VER"} ,
		{"word":"secrètent","word_nosc":"secretent","lemma":"secréter","pos":"VER"} ,
		{"word":"secrètes","word_nosc":"secretes","lemma":"secréter","pos":"VER"} ,
		{"word":"secrétaient","word_nosc":"secretaient","lemma":"secréter","pos":"VER"} ,
		{"word":"secréter","word_nosc":"secreter","lemma":"secréter","pos":"VER"} ,
		{"word":"secrété","word_nosc":"secrete","lemma":"secréter","pos":"VER"} ,
		{"word":"secrétée","word_nosc":"secretee","lemma":"secréter","pos":"VER"} ,
		{"word":"sectionna","word_nosc":"sectionna","lemma":"sectionner","pos":"VER"} ,
		{"word":"sectionnaient","word_nosc":"sectionnaient","lemma":"sectionner","pos":"VER"} ,
		{"word":"sectionnant","word_nosc":"sectionnant","lemma":"sectionner","pos":"VER"} ,
		{"word":"sectionne","word_nosc":"sectionne","lemma":"sectionner","pos":"VER"} ,
		{"word":"sectionnent","word_nosc":"sectionnent","lemma":"sectionner","pos":"VER"} ,
		{"word":"sectionner","word_nosc":"sectionner","lemma":"sectionner","pos":"VER"} ,
		{"word":"sectionnera","word_nosc":"sectionnera","lemma":"sectionner","pos":"VER"} ,
		{"word":"sectionnez","word_nosc":"sectionnez","lemma":"sectionner","pos":"VER"} ,
		{"word":"sectionné","word_nosc":"sectionne","lemma":"sectionner","pos":"VER"} ,
		{"word":"sectionnée","word_nosc":"sectionnee","lemma":"sectionner","pos":"VER"} ,
		{"word":"sectionnées","word_nosc":"sectionnees","lemma":"sectionner","pos":"VER"} ,
		{"word":"sectionnés","word_nosc":"sectionnes","lemma":"sectionner","pos":"VER"} ,
		{"word":"segmentez","word_nosc":"segmentez","lemma":"segmenter","pos":"VER"} ,
		{"word":"segmenté","word_nosc":"segmente","lemma":"segmenter","pos":"VER"} ,
		{"word":"sellait","word_nosc":"sellait","lemma":"seller","pos":"VER"} ,
		{"word":"selle","word_nosc":"selle","lemma":"seller","pos":"VER"} ,
		{"word":"seller","word_nosc":"seller","lemma":"seller","pos":"VER"} ,
		{"word":"selles","word_nosc":"selles","lemma":"seller","pos":"VER"} ,
		{"word":"sellez","word_nosc":"sellez","lemma":"seller","pos":"VER"} ,
		{"word":"sellé","word_nosc":"selle","lemma":"seller","pos":"VER"} ,
		{"word":"sellée","word_nosc":"sellee","lemma":"seller","pos":"VER"} ,
		{"word":"sellées","word_nosc":"sellees","lemma":"seller","pos":"VER"} ,
		{"word":"sellés","word_nosc":"selles","lemma":"seller","pos":"VER"} ,
		{"word":"sema","word_nosc":"sema","lemma":"semer","pos":"VER"} ,
		{"word":"semaient","word_nosc":"semaient","lemma":"semer","pos":"VER"} ,
		{"word":"semait","word_nosc":"semait","lemma":"semer","pos":"VER"} ,
		{"word":"semant","word_nosc":"semant","lemma":"semer","pos":"VER"} ,
		{"word":"sembla","word_nosc":"sembla","lemma":"sembler","pos":"VER"} ,
		{"word":"semblaient","word_nosc":"semblaient","lemma":"sembler","pos":"VER"} ,
		{"word":"semblais","word_nosc":"semblais","lemma":"sembler","pos":"VER"} ,
		{"word":"semblait","word_nosc":"semblait","lemma":"sembler","pos":"VER"} ,
		{"word":"semblant","word_nosc":"semblant","lemma":"sembler","pos":"VER"} ,
		{"word":"semble","word_nosc":"semble","lemma":"sembler","pos":"VER"} ,
		{"word":"semblent","word_nosc":"semblent","lemma":"sembler","pos":"VER"} ,
		{"word":"sembler","word_nosc":"sembler","lemma":"sembler","pos":"VER"} ,
		{"word":"semblera","word_nosc":"semblera","lemma":"sembler","pos":"VER"} ,
		{"word":"sembleraient","word_nosc":"sembleraient","lemma":"sembler","pos":"VER"} ,
		{"word":"semblerait","word_nosc":"semblerait","lemma":"sembler","pos":"VER"} ,
		{"word":"sembleront","word_nosc":"sembleront","lemma":"sembler","pos":"VER"} ,
		{"word":"sembles","word_nosc":"sembles","lemma":"sembler","pos":"VER"} ,
		{"word":"semblez","word_nosc":"semblez","lemma":"sembler","pos":"VER"} ,
		{"word":"sembliez","word_nosc":"sembliez","lemma":"sembler","pos":"VER"} ,
		{"word":"semblions","word_nosc":"semblions","lemma":"sembler","pos":"VER"} ,
		{"word":"semblons","word_nosc":"semblons","lemma":"sembler","pos":"VER"} ,
		{"word":"semblât","word_nosc":"semblat","lemma":"sembler","pos":"VER"} ,
		{"word":"semblèrent","word_nosc":"semblerent","lemma":"sembler","pos":"VER"} ,
		{"word":"semblé","word_nosc":"semble","lemma":"sembler","pos":"VER"} ,
		{"word":"semer","word_nosc":"semer","lemma":"semer","pos":"VER"} ,
		{"word":"semez","word_nosc":"semez","lemma":"semer","pos":"VER"} ,
		{"word":"semoncer","word_nosc":"semoncer","lemma":"semoncer","pos":"VER"} ,
		{"word":"semoncé","word_nosc":"semonce","lemma":"semoncer","pos":"VER"} ,
		{"word":"semons","word_nosc":"semons","lemma":"semer","pos":"VER"} ,
		{"word":"semonça","word_nosc":"semonca","lemma":"semoncer","pos":"VER"} ,
		{"word":"semonçait","word_nosc":"semoncait","lemma":"semoncer","pos":"VER"} ,
		{"word":"semonçant","word_nosc":"semoncant","lemma":"semoncer","pos":"VER"} ,
		{"word":"semât","word_nosc":"semat","lemma":"semer","pos":"VER"} ,
		{"word":"semé","word_nosc":"seme","lemma":"semer","pos":"VER"} ,
		{"word":"semée","word_nosc":"semee","lemma":"semer","pos":"VER"} ,
		{"word":"semées","word_nosc":"semees","lemma":"semer","pos":"VER"} ,
		{"word":"semés","word_nosc":"semes","lemma":"semer","pos":"VER"} ,
		{"word":"sens","word_nosc":"sens","lemma":"sentir","pos":"VER"} ,
		{"word":"sensibilisait","word_nosc":"sensibilisait","lemma":"sensibiliser","pos":"VER"} ,
		{"word":"sensibilise","word_nosc":"sensibilise","lemma":"sensibiliser","pos":"VER"} ,
		{"word":"sensibiliser","word_nosc":"sensibiliser","lemma":"sensibiliser","pos":"VER"} ,
		{"word":"sensibilisé","word_nosc":"sensibilise","lemma":"sensibiliser","pos":"VER"} ,
		{"word":"sensibilisée","word_nosc":"sensibilisee","lemma":"sensibiliser","pos":"VER"} ,
		{"word":"sensibilisés","word_nosc":"sensibilises","lemma":"sensibiliser","pos":"VER"} ,
		{"word":"sent","word_nosc":"sent","lemma":"sentir","pos":"VER"} ,
		{"word":"sentaient","word_nosc":"sentaient","lemma":"sentir","pos":"VER"} ,
		{"word":"sentais","word_nosc":"sentais","lemma":"sentir","pos":"VER"} ,
		{"word":"sentait","word_nosc":"sentait","lemma":"sentir","pos":"VER"} ,
		{"word":"sentant","word_nosc":"sentant","lemma":"sentir","pos":"VER"} ,
		{"word":"sente","word_nosc":"sente","lemma":"sentir","pos":"VER"} ,
		{"word":"sentent","word_nosc":"sentent","lemma":"sentir","pos":"VER"} ,
		{"word":"sentes","word_nosc":"sentes","lemma":"sentir","pos":"VER"} ,
		{"word":"sentez","word_nosc":"sentez","lemma":"sentir","pos":"VER"} ,
		{"word":"senti","word_nosc":"senti","lemma":"sentir","pos":"VER"} ,
		{"word":"sentie","word_nosc":"sentie","lemma":"sentir","pos":"VER"} ,
		{"word":"senties","word_nosc":"senties","lemma":"sentir","pos":"VER"} ,
		{"word":"sentiez","word_nosc":"sentiez","lemma":"sentir","pos":"VER"} ,
		{"word":"sentimentaliser","word_nosc":"sentimentaliser","lemma":"sentimentaliser","pos":"VER"} ,
		{"word":"sentions","word_nosc":"sentions","lemma":"sentir","pos":"VER"} ,
		{"word":"sentir","word_nosc":"sentir","lemma":"sentir","pos":"VER"} ,
		{"word":"sentira","word_nosc":"sentira","lemma":"sentir","pos":"VER"} ,
		{"word":"sentirai","word_nosc":"sentirai","lemma":"sentir","pos":"VER"} ,
		{"word":"sentiraient","word_nosc":"sentiraient","lemma":"sentir","pos":"VER"} ,
		{"word":"sentirais","word_nosc":"sentirais","lemma":"sentir","pos":"VER"} ,
		{"word":"sentirait","word_nosc":"sentirait","lemma":"sentir","pos":"VER"} ,
		{"word":"sentiras","word_nosc":"sentiras","lemma":"sentir","pos":"VER"} ,
		{"word":"sentirent","word_nosc":"sentirent","lemma":"sentir","pos":"VER"} ,
		{"word":"sentirez","word_nosc":"sentirez","lemma":"sentir","pos":"VER"} ,
		{"word":"sentiriez","word_nosc":"sentiriez","lemma":"sentir","pos":"VER"} ,
		{"word":"sentirons","word_nosc":"sentirons","lemma":"sentir","pos":"VER"} ,
		{"word":"sentiront","word_nosc":"sentiront","lemma":"sentir","pos":"VER"} ,
		{"word":"sentis","word_nosc":"sentis","lemma":"sentir","pos":"VER"} ,
		{"word":"sentisse","word_nosc":"sentisse","lemma":"sentir","pos":"VER"} ,
		{"word":"sentissent","word_nosc":"sentissent","lemma":"sentir","pos":"VER"} ,
		{"word":"sentit","word_nosc":"sentit","lemma":"sentir","pos":"VER"} ,
		{"word":"sentons","word_nosc":"sentons","lemma":"sentir","pos":"VER"} ,
		{"word":"sentîmes","word_nosc":"sentimes","lemma":"sentir","pos":"VER"} ,
		{"word":"sentît","word_nosc":"sentit","lemma":"sentir","pos":"VER"} ,
		{"word":"sera","word_nosc":"sera","lemma":"être","pos":"VER"} ,
		{"word":"serai","word_nosc":"serai","lemma":"être","pos":"VER"} ,
		{"word":"seraient","word_nosc":"seraient","lemma":"être","pos":"VER"} ,
		{"word":"serais","word_nosc":"serais","lemma":"être","pos":"VER"} ,
		{"word":"serait","word_nosc":"serait","lemma":"être","pos":"VER"} ,
		{"word":"seras","word_nosc":"seras","lemma":"être","pos":"VER"} ,
		{"word":"serez","word_nosc":"serez","lemma":"être","pos":"VER"} ,
		{"word":"seriez","word_nosc":"seriez","lemma":"être","pos":"VER"} ,
		{"word":"serinais","word_nosc":"serinais","lemma":"seriner","pos":"VER"} ,
		{"word":"serinait","word_nosc":"serinait","lemma":"seriner","pos":"VER"} ,
		{"word":"serine","word_nosc":"serine","lemma":"seriner","pos":"VER"} ,
		{"word":"seriner","word_nosc":"seriner","lemma":"seriner","pos":"VER"} ,
		{"word":"serinera","word_nosc":"serinera","lemma":"seriner","pos":"VER"} ,
		{"word":"serines","word_nosc":"serines","lemma":"seriner","pos":"VER"} ,
		{"word":"seringuaient","word_nosc":"seringuaient","lemma":"seringuer","pos":"VER"} ,
		{"word":"seringuait","word_nosc":"seringuait","lemma":"seringuer","pos":"VER"} ,
		{"word":"seringuer","word_nosc":"seringuer","lemma":"seringuer","pos":"VER"} ,
		{"word":"seringuée","word_nosc":"seringuee","lemma":"seringuer","pos":"VER"} ,
		{"word":"serinions","word_nosc":"serinions","lemma":"seriner","pos":"VER"} ,
		{"word":"seriné","word_nosc":"serine","lemma":"seriner","pos":"VER"} ,
		{"word":"serinées","word_nosc":"serinees","lemma":"seriner","pos":"VER"} ,
		{"word":"serions","word_nosc":"serions","lemma":"être","pos":"VER"} ,
		{"word":"sermonna","word_nosc":"sermonna","lemma":"sermonner","pos":"VER"} ,
		{"word":"sermonnaient","word_nosc":"sermonnaient","lemma":"sermonner","pos":"VER"} ,
		{"word":"sermonnait","word_nosc":"sermonnait","lemma":"sermonner","pos":"VER"} ,
		{"word":"sermonnant","word_nosc":"sermonnant","lemma":"sermonner","pos":"VER"} ,
		{"word":"sermonne","word_nosc":"sermonne","lemma":"sermonner","pos":"VER"} ,
		{"word":"sermonner","word_nosc":"sermonner","lemma":"sermonner","pos":"VER"} ,
		{"word":"sermonnerai","word_nosc":"sermonnerai","lemma":"sermonner","pos":"VER"} ,
		{"word":"sermonnes","word_nosc":"sermonnes","lemma":"sermonner","pos":"VER"} ,
		{"word":"sermonnez","word_nosc":"sermonnez","lemma":"sermonner","pos":"VER"} ,
		{"word":"sermonné","word_nosc":"sermonne","lemma":"sermonner","pos":"VER"} ,
		{"word":"sermonnée","word_nosc":"sermonnee","lemma":"sermonner","pos":"VER"} ,
		{"word":"serons","word_nosc":"serons","lemma":"être","pos":"VER"} ,
		{"word":"seront","word_nosc":"seront","lemma":"être","pos":"VER"} ,
		{"word":"serpentaient","word_nosc":"serpentaient","lemma":"serpenter","pos":"VER"} ,
		{"word":"serpentait","word_nosc":"serpentait","lemma":"serpenter","pos":"VER"} ,
		{"word":"serpentant","word_nosc":"serpentant","lemma":"serpenter","pos":"VER"} ,
		{"word":"serpente","word_nosc":"serpente","lemma":"serpenter","pos":"VER"} ,
		{"word":"serpentent","word_nosc":"serpentent","lemma":"serpenter","pos":"VER"} ,
		{"word":"serpenter","word_nosc":"serpenter","lemma":"serpenter","pos":"VER"} ,
		{"word":"serpentons","word_nosc":"serpentons","lemma":"serpenter","pos":"VER"} ,
		{"word":"serpenté","word_nosc":"serpente","lemma":"serpenter","pos":"VER"} ,
		{"word":"serra","word_nosc":"serra","lemma":"serrer","pos":"VER"} ,
		{"word":"serrai","word_nosc":"serrai","lemma":"serrer","pos":"VER"} ,
		{"word":"serraient","word_nosc":"serraient","lemma":"serrer","pos":"VER"} ,
		{"word":"serrais","word_nosc":"serrais","lemma":"serrer","pos":"VER"} ,
		{"word":"serrait","word_nosc":"serrait","lemma":"serrer","pos":"VER"} ,
		{"word":"serrant","word_nosc":"serrant","lemma":"serrer","pos":"VER"} ,
		{"word":"serre","word_nosc":"serre","lemma":"serrer","pos":"VER"} ,
		{"word":"serrent","word_nosc":"serrent","lemma":"serrer","pos":"VER"} ,
		{"word":"serrer","word_nosc":"serrer","lemma":"serrer","pos":"VER"} ,
		{"word":"serrera","word_nosc":"serrera","lemma":"serrer","pos":"VER"} ,
		{"word":"serrerai","word_nosc":"serrerai","lemma":"serrer","pos":"VER"} ,
		{"word":"serreraient","word_nosc":"serreraient","lemma":"serrer","pos":"VER"} ,
		{"word":"serrerais","word_nosc":"serrerais","lemma":"serrer","pos":"VER"} ,
		{"word":"serrerait","word_nosc":"serrerait","lemma":"serrer","pos":"VER"} ,
		{"word":"serrerez","word_nosc":"serrerez","lemma":"serrer","pos":"VER"} ,
		{"word":"serrerons","word_nosc":"serrerons","lemma":"serrer","pos":"VER"} ,
		{"word":"serreront","word_nosc":"serreront","lemma":"serrer","pos":"VER"} ,
		{"word":"serres","word_nosc":"serres","lemma":"serrer","pos":"VER"} ,
		{"word":"serrez","word_nosc":"serrez","lemma":"serrer","pos":"VER"} ,
		{"word":"serriez","word_nosc":"serriez","lemma":"serrer","pos":"VER"} ,
		{"word":"serrions","word_nosc":"serrions","lemma":"serrer","pos":"VER"} ,
		{"word":"serrons","word_nosc":"serrons","lemma":"serrer","pos":"VER"} ,
		{"word":"serrâmes","word_nosc":"serrames","lemma":"serrer","pos":"VER"} ,
		{"word":"serrât","word_nosc":"serrat","lemma":"serrer","pos":"VER"} ,
		{"word":"serrèrent","word_nosc":"serrerent","lemma":"serrer","pos":"VER"} ,
		{"word":"serré","word_nosc":"serre","lemma":"serrer","pos":"VER"} ,
		{"word":"serrée","word_nosc":"serree","lemma":"serrer","pos":"VER"} ,
		{"word":"serrées","word_nosc":"serrees","lemma":"serrer","pos":"VER"} ,
		{"word":"serrés","word_nosc":"serres","lemma":"serrer","pos":"VER"} ,
		{"word":"sers","word_nosc":"sers","lemma":"servir","pos":"VER"} ,
		{"word":"sert","word_nosc":"sert","lemma":"servir","pos":"VER"} ,
		{"word":"serti","word_nosc":"serti","lemma":"sertir","pos":"VER"} ,
		{"word":"sertie","word_nosc":"sertie","lemma":"sertir","pos":"VER"} ,
		{"word":"serties","word_nosc":"serties","lemma":"sertir","pos":"VER"} ,
		{"word":"sertir","word_nosc":"sertir","lemma":"sertir","pos":"VER"} ,
		{"word":"sertirait","word_nosc":"sertirait","lemma":"sertir","pos":"VER"} ,
		{"word":"sertis","word_nosc":"sertis","lemma":"sertir","pos":"VER"} ,
		{"word":"sertissaient","word_nosc":"sertissaient","lemma":"sertir","pos":"VER"} ,
		{"word":"sertissait","word_nosc":"sertissait","lemma":"sertir","pos":"VER"} ,
		{"word":"sertissant","word_nosc":"sertissant","lemma":"sertir","pos":"VER"} ,
		{"word":"sertissent","word_nosc":"sertissent","lemma":"sertir","pos":"VER"} ,
		{"word":"servaient","word_nosc":"servaient","lemma":"servir","pos":"VER"} ,
		{"word":"servais","word_nosc":"servais","lemma":"servir","pos":"VER"} ,
		{"word":"servait","word_nosc":"servait","lemma":"servir","pos":"VER"} ,
		{"word":"servant","word_nosc":"servant","lemma":"servir","pos":"VER"} ,
		{"word":"serve","word_nosc":"serve","lemma":"servir","pos":"VER"} ,
		{"word":"servent","word_nosc":"servent","lemma":"servir","pos":"VER"} ,
		{"word":"serves","word_nosc":"serves","lemma":"servir","pos":"VER"} ,
		{"word":"servez","word_nosc":"servez","lemma":"servir","pos":"VER"} ,
		{"word":"servi","word_nosc":"servi","lemma":"servir","pos":"VER"} ,
		{"word":"servie","word_nosc":"servie","lemma":"servir","pos":"VER"} ,
		{"word":"servies","word_nosc":"servies","lemma":"servir","pos":"VER"} ,
		{"word":"serviez","word_nosc":"serviez","lemma":"servir","pos":"VER"} ,
		{"word":"servions","word_nosc":"servions","lemma":"servir","pos":"VER"} ,
		{"word":"servir","word_nosc":"servir","lemma":"servir","pos":"VER"} ,
		{"word":"servira","word_nosc":"servira","lemma":"servir","pos":"VER"} ,
		{"word":"servirai","word_nosc":"servirai","lemma":"servir","pos":"VER"} ,
		{"word":"serviraient","word_nosc":"serviraient","lemma":"servir","pos":"VER"} ,
		{"word":"servirais","word_nosc":"servirais","lemma":"servir","pos":"VER"} ,
		{"word":"servirait","word_nosc":"servirait","lemma":"servir","pos":"VER"} ,
		{"word":"serviras","word_nosc":"serviras","lemma":"servir","pos":"VER"} ,
		{"word":"servirent","word_nosc":"servirent","lemma":"servir","pos":"VER"} ,
		{"word":"servirez","word_nosc":"servirez","lemma":"servir","pos":"VER"} ,
		{"word":"serviriez","word_nosc":"serviriez","lemma":"servir","pos":"VER"} ,
		{"word":"servirions","word_nosc":"servirions","lemma":"servir","pos":"VER"} ,
		{"word":"servirons","word_nosc":"servirons","lemma":"servir","pos":"VER"} ,
		{"word":"serviront","word_nosc":"serviront","lemma":"servir","pos":"VER"} ,
		{"word":"servis","word_nosc":"servis","lemma":"servir","pos":"VER"} ,
		{"word":"servissent","word_nosc":"servissent","lemma":"servir","pos":"VER"} ,
		{"word":"servit","word_nosc":"servit","lemma":"servir","pos":"VER"} ,
		{"word":"servons","word_nosc":"servons","lemma":"servir","pos":"VER"} ,
		{"word":"servîmes","word_nosc":"servimes","lemma":"servir","pos":"VER"} ,
		{"word":"servît","word_nosc":"servit","lemma":"servir","pos":"VER"} ,
		{"word":"sevrait","word_nosc":"sevrait","lemma":"sevrer","pos":"VER"} ,
		{"word":"sevrer","word_nosc":"sevrer","lemma":"sevrer","pos":"VER"} ,
		{"word":"sevré","word_nosc":"sevre","lemma":"sevrer","pos":"VER"} ,
		{"word":"sevrée","word_nosc":"sevree","lemma":"sevrer","pos":"VER"} ,
		{"word":"sevrées","word_nosc":"sevrees","lemma":"sevrer","pos":"VER"} ,
		{"word":"sevrés","word_nosc":"sevres","lemma":"sevrer","pos":"VER"} ,
		{"word":"sexualiser","word_nosc":"sexualiser","lemma":"sexualiser","pos":"VER"} ,
		{"word":"sexualisé","word_nosc":"sexualise","lemma":"sexualiser","pos":"VER"} ,
		{"word":"seyait","word_nosc":"seyait","lemma":"seoir","pos":"VER"} ,
		{"word":"shampooiner","word_nosc":"shampooiner","lemma":"shampooiner","pos":"VER"} ,
		{"word":"shampouine","word_nosc":"shampouine","lemma":"shampouiner","pos":"VER"} ,
		{"word":"shampouiner","word_nosc":"shampouiner","lemma":"shampouiner","pos":"VER"} ,
		{"word":"shampouiné","word_nosc":"shampouine","lemma":"shampouiner","pos":"VER"} ,
		{"word":"shoota","word_nosc":"shoota","lemma":"shooter","pos":"VER"} ,
		{"word":"shootai","word_nosc":"shootai","lemma":"shooter","pos":"VER"} ,
		{"word":"shootais","word_nosc":"shootais","lemma":"shooter","pos":"VER"} ,
		{"word":"shootait","word_nosc":"shootait","lemma":"shooter","pos":"VER"} ,
		{"word":"shootant","word_nosc":"shootant","lemma":"shooter","pos":"VER"} ,
		{"word":"shoote","word_nosc":"shoote","lemma":"shooter","pos":"VER"} ,
		{"word":"shootent","word_nosc":"shootent","lemma":"shooter","pos":"VER"} ,
		{"word":"shooter","word_nosc":"shooter","lemma":"shooter","pos":"VER"} ,
		{"word":"shootera","word_nosc":"shootera","lemma":"shooter","pos":"VER"} ,
		{"word":"shooterai","word_nosc":"shooterai","lemma":"shooter","pos":"VER"} ,
		{"word":"shooteraient","word_nosc":"shooteraient","lemma":"shooter","pos":"VER"} ,
		{"word":"shooteras","word_nosc":"shooteras","lemma":"shooter","pos":"VER"} ,
		{"word":"shootes","word_nosc":"shootes","lemma":"shooter","pos":"VER"} ,
		{"word":"shootiez","word_nosc":"shootiez","lemma":"shooter","pos":"VER"} ,
		{"word":"shootions","word_nosc":"shootions","lemma":"shooter","pos":"VER"} ,
		{"word":"shootons","word_nosc":"shootons","lemma":"shooter","pos":"VER"} ,
		{"word":"shooté","word_nosc":"shoote","lemma":"shooter","pos":"VER"} ,
		{"word":"shootée","word_nosc":"shootee","lemma":"shooter","pos":"VER"} ,
		{"word":"shootés","word_nosc":"shootes","lemma":"shooter","pos":"VER"} ,
		{"word":"shunte","word_nosc":"shunte","lemma":"shunter","pos":"VER"} ,
		{"word":"shunter","word_nosc":"shunter","lemma":"shunter","pos":"VER"} ,
		{"word":"sidère","word_nosc":"sidere","lemma":"sidérer","pos":"VER"} ,
		{"word":"sidérais","word_nosc":"siderais","lemma":"sidérer","pos":"VER"} ,
		{"word":"sidérait","word_nosc":"siderait","lemma":"sidérer","pos":"VER"} ,
		{"word":"sidérant","word_nosc":"siderant","lemma":"sidérer","pos":"VER"} ,
		{"word":"sidérer","word_nosc":"siderer","lemma":"sidérer","pos":"VER"} ,
		{"word":"sidéré","word_nosc":"sidere","lemma":"sidérer","pos":"VER"} ,
		{"word":"sidérée","word_nosc":"sideree","lemma":"sidérer","pos":"VER"} ,
		{"word":"sidérées","word_nosc":"siderees","lemma":"sidérer","pos":"VER"} ,
		{"word":"sidérés","word_nosc":"sideres","lemma":"sidérer","pos":"VER"} ,
		{"word":"sied","word_nosc":"sied","lemma":"seoir","pos":"VER"} ,
		{"word":"siesta","word_nosc":"siesta","lemma":"siester","pos":"VER"} ,
		{"word":"siestant","word_nosc":"siestant","lemma":"siester","pos":"VER"} ,
		{"word":"siffla","word_nosc":"siffla","lemma":"siffler","pos":"VER"} ,
		{"word":"sifflai","word_nosc":"sifflai","lemma":"siffler","pos":"VER"} ,
		{"word":"sifflaient","word_nosc":"sifflaient","lemma":"siffler","pos":"VER"} ,
		{"word":"sifflais","word_nosc":"sifflais","lemma":"siffler","pos":"VER"} ,
		{"word":"sifflait","word_nosc":"sifflait","lemma":"siffler","pos":"VER"} ,
		{"word":"sifflant","word_nosc":"sifflant","lemma":"siffler","pos":"VER"} ,
		{"word":"siffle","word_nosc":"siffle","lemma":"siffler","pos":"VER"} ,
		{"word":"sifflent","word_nosc":"sifflent","lemma":"siffler","pos":"VER"} ,
		{"word":"siffler","word_nosc":"siffler","lemma":"siffler","pos":"VER"} ,
		{"word":"sifflera","word_nosc":"sifflera","lemma":"siffler","pos":"VER"} ,
		{"word":"sifflerai","word_nosc":"sifflerai","lemma":"siffler","pos":"VER"} ,
		{"word":"siffleraient","word_nosc":"siffleraient","lemma":"siffler","pos":"VER"} ,
		{"word":"sifflerait","word_nosc":"sifflerait","lemma":"siffler","pos":"VER"} ,
		{"word":"siffleras","word_nosc":"siffleras","lemma":"siffler","pos":"VER"} ,
		{"word":"siffles","word_nosc":"siffles","lemma":"siffler","pos":"VER"} ,
		{"word":"sifflez","word_nosc":"sifflez","lemma":"siffler","pos":"VER"} ,
		{"word":"siffliez","word_nosc":"siffliez","lemma":"siffler","pos":"VER"} ,
		{"word":"sifflota","word_nosc":"sifflota","lemma":"siffloter","pos":"VER"} ,
		{"word":"sifflotais","word_nosc":"sifflotais","lemma":"siffloter","pos":"VER"} ,
		{"word":"sifflotait","word_nosc":"sifflotait","lemma":"siffloter","pos":"VER"} ,
		{"word":"sifflotant","word_nosc":"sifflotant","lemma":"siffloter","pos":"VER"} ,
		{"word":"sifflote","word_nosc":"sifflote","lemma":"siffloter","pos":"VER"} ,
		{"word":"siffloter","word_nosc":"siffloter","lemma":"siffloter","pos":"VER"} ,
		{"word":"siffloterai","word_nosc":"siffloterai","lemma":"siffloter","pos":"VER"} ,
		{"word":"sifflotiez","word_nosc":"sifflotiez","lemma":"siffloter","pos":"VER"} ,
		{"word":"sifflotèrent","word_nosc":"siffloterent","lemma":"siffloter","pos":"VER"} ,
		{"word":"siffloté","word_nosc":"sifflote","lemma":"siffloter","pos":"VER"} ,
		{"word":"sifflèrent","word_nosc":"sifflerent","lemma":"siffler","pos":"VER"} ,
		{"word":"sifflé","word_nosc":"siffle","lemma":"siffler","pos":"VER"} ,
		{"word":"sifflée","word_nosc":"sifflee","lemma":"siffler","pos":"VER"} ,
		{"word":"sifflées","word_nosc":"sifflees","lemma":"siffler","pos":"VER"} ,
		{"word":"sifflés","word_nosc":"siffles","lemma":"siffler","pos":"VER"} ,
		{"word":"signa","word_nosc":"signa","lemma":"signer","pos":"VER"} ,
		{"word":"signai","word_nosc":"signai","lemma":"signer","pos":"VER"} ,
		{"word":"signaient","word_nosc":"signaient","lemma":"signer","pos":"VER"} ,
		{"word":"signais","word_nosc":"signais","lemma":"signer","pos":"VER"} ,
		{"word":"signait","word_nosc":"signait","lemma":"signer","pos":"VER"} ,
		{"word":"signala","word_nosc":"signala","lemma":"signaler","pos":"VER"} ,
		{"word":"signalai","word_nosc":"signalai","lemma":"signaler","pos":"VER"} ,
		{"word":"signalaient","word_nosc":"signalaient","lemma":"signaler","pos":"VER"} ,
		{"word":"signalais","word_nosc":"signalais","lemma":"signaler","pos":"VER"} ,
		{"word":"signalait","word_nosc":"signalait","lemma":"signaler","pos":"VER"} ,
		{"word":"signalant","word_nosc":"signalant","lemma":"signaler","pos":"VER"} ,
		{"word":"signale","word_nosc":"signale","lemma":"signaler","pos":"VER"} ,
		{"word":"signalent","word_nosc":"signalent","lemma":"signaler","pos":"VER"} ,
		{"word":"signaler","word_nosc":"signaler","lemma":"signaler","pos":"VER"} ,
		{"word":"signalera","word_nosc":"signalera","lemma":"signaler","pos":"VER"} ,
		{"word":"signalerai","word_nosc":"signalerai","lemma":"signaler","pos":"VER"} ,
		{"word":"signalerait","word_nosc":"signalerait","lemma":"signaler","pos":"VER"} ,
		{"word":"signaleront","word_nosc":"signaleront","lemma":"signaler","pos":"VER"} ,
		{"word":"signales","word_nosc":"signales","lemma":"signaler","pos":"VER"} ,
		{"word":"signalez","word_nosc":"signalez","lemma":"signaler","pos":"VER"} ,
		{"word":"signaliser","word_nosc":"signaliser","lemma":"signaliser","pos":"VER"} ,
		{"word":"signalons","word_nosc":"signalons","lemma":"signaler","pos":"VER"} ,
		{"word":"signalât","word_nosc":"signalat","lemma":"signaler","pos":"VER"} ,
		{"word":"signalé","word_nosc":"signale","lemma":"signaler","pos":"VER"} ,
		{"word":"signalée","word_nosc":"signalee","lemma":"signaler","pos":"VER"} ,
		{"word":"signalées","word_nosc":"signalees","lemma":"signaler","pos":"VER"} ,
		{"word":"signalés","word_nosc":"signales","lemma":"signaler","pos":"VER"} ,
		{"word":"signant","word_nosc":"signant","lemma":"signer","pos":"VER"} ,
		{"word":"signasse","word_nosc":"signasse","lemma":"signer","pos":"VER"} ,
		{"word":"signe","word_nosc":"signe","lemma":"signer","pos":"VER"} ,
		{"word":"signent","word_nosc":"signent","lemma":"signer","pos":"VER"} ,
		{"word":"signer","word_nosc":"signer","lemma":"signer","pos":"VER"} ,
		{"word":"signera","word_nosc":"signera","lemma":"signer","pos":"VER"} ,
		{"word":"signerai","word_nosc":"signerai","lemma":"signer","pos":"VER"} ,
		{"word":"signeraient","word_nosc":"signeraient","lemma":"signer","pos":"VER"} ,
		{"word":"signerais","word_nosc":"signerais","lemma":"signer","pos":"VER"} ,
		{"word":"signerait","word_nosc":"signerait","lemma":"signer","pos":"VER"} ,
		{"word":"signeras","word_nosc":"signeras","lemma":"signer","pos":"VER"} ,
		{"word":"signerez","word_nosc":"signerez","lemma":"signer","pos":"VER"} ,
		{"word":"signeriez","word_nosc":"signeriez","lemma":"signer","pos":"VER"} ,
		{"word":"signerions","word_nosc":"signerions","lemma":"signer","pos":"VER"} ,
		{"word":"signerons","word_nosc":"signerons","lemma":"signer","pos":"VER"} ,
		{"word":"signeront","word_nosc":"signeront","lemma":"signer","pos":"VER"} ,
		{"word":"signes","word_nosc":"signes","lemma":"signer","pos":"VER"} ,
		{"word":"signez","word_nosc":"signez","lemma":"signer","pos":"VER"} ,
		{"word":"signiez","word_nosc":"signiez","lemma":"signer","pos":"VER"} ,
		{"word":"signifia","word_nosc":"signifia","lemma":"signifier","pos":"VER"} ,
		{"word":"signifiaient","word_nosc":"signifiaient","lemma":"signifier","pos":"VER"} ,
		{"word":"signifiais","word_nosc":"signifiais","lemma":"signifier","pos":"VER"} ,
		{"word":"signifiait","word_nosc":"signifiait","lemma":"signifier","pos":"VER"} ,
		{"word":"signifiant","word_nosc":"signifiant","lemma":"signifier","pos":"VER"} ,
		{"word":"signifie","word_nosc":"signifie","lemma":"signifier","pos":"VER"} ,
		{"word":"signifient","word_nosc":"signifient","lemma":"signifier","pos":"VER"} ,
		{"word":"signifier","word_nosc":"signifier","lemma":"signifier","pos":"VER"} ,
		{"word":"signifiera","word_nosc":"signifiera","lemma":"signifier","pos":"VER"} ,
		{"word":"signifierai","word_nosc":"signifierai","lemma":"signifier","pos":"VER"} ,
		{"word":"signifierait","word_nosc":"signifierait","lemma":"signifier","pos":"VER"} ,
		{"word":"signifiât","word_nosc":"signifiat","lemma":"signifier","pos":"VER"} ,
		{"word":"signifièrent","word_nosc":"signifierent","lemma":"signifier","pos":"VER"} ,
		{"word":"signifié","word_nosc":"signifie","lemma":"signifier","pos":"VER"} ,
		{"word":"signifiée","word_nosc":"signifiee","lemma":"signifier","pos":"VER"} ,
		{"word":"signifiées","word_nosc":"signifiees","lemma":"signifier","pos":"VER"} ,
		{"word":"signons","word_nosc":"signons","lemma":"signer","pos":"VER"} ,
		{"word":"signâmes","word_nosc":"signames","lemma":"signer","pos":"VER"} ,
		{"word":"signât","word_nosc":"signat","lemma":"signer","pos":"VER"} ,
		{"word":"signèrent","word_nosc":"signerent","lemma":"signer","pos":"VER"} ,
		{"word":"signé","word_nosc":"signe","lemma":"signer","pos":"VER"} ,
		{"word":"signée","word_nosc":"signee","lemma":"signer","pos":"VER"} ,
		{"word":"signées","word_nosc":"signees","lemma":"signer","pos":"VER"} ,
		{"word":"signés","word_nosc":"signes","lemma":"signer","pos":"VER"} ,
		{"word":"silhouettait","word_nosc":"silhouettait","lemma":"silhouetter","pos":"VER"} ,
		{"word":"silhouettant","word_nosc":"silhouettant","lemma":"silhouetter","pos":"VER"} ,
		{"word":"silhouette","word_nosc":"silhouette","lemma":"silhouetter","pos":"VER"} ,
		{"word":"silhouettent","word_nosc":"silhouettent","lemma":"silhouetter","pos":"VER"} ,
		{"word":"silhouetter","word_nosc":"silhouetter","lemma":"silhouetter","pos":"VER"} ,
		{"word":"silhouetté","word_nosc":"silhouette","lemma":"silhouetter","pos":"VER"} ,
		{"word":"silhouettée","word_nosc":"silhouettee","lemma":"silhouetter","pos":"VER"} ,
		{"word":"siliconer","word_nosc":"siliconer","lemma":"siliconer","pos":"VER"} ,
		{"word":"siliconé","word_nosc":"silicone","lemma":"siliconer","pos":"VER"} ,
		{"word":"siliconée","word_nosc":"siliconee","lemma":"siliconer","pos":"VER"} ,
		{"word":"siliconées","word_nosc":"siliconees","lemma":"siliconer","pos":"VER"} ,
		{"word":"sillonna","word_nosc":"sillonna","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillonnaient","word_nosc":"sillonnaient","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillonnais","word_nosc":"sillonnais","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillonnait","word_nosc":"sillonnait","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillonnant","word_nosc":"sillonnant","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillonne","word_nosc":"sillonne","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillonnent","word_nosc":"sillonnent","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillonner","word_nosc":"sillonner","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillonnes","word_nosc":"sillonnes","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillonnez","word_nosc":"sillonnez","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillonnons","word_nosc":"sillonnons","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillonnèrent","word_nosc":"sillonnerent","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillonné","word_nosc":"sillonne","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillonnée","word_nosc":"sillonnee","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillonnées","word_nosc":"sillonnees","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillonnés","word_nosc":"sillonnes","lemma":"sillonner","pos":"VER"} ,
		{"word":"sillons","word_nosc":"sillons","lemma":"siller","pos":"VER"} ,
		{"word":"simplifiaient","word_nosc":"simplifiaient","lemma":"simplifier","pos":"VER"} ,
		{"word":"simplifiais","word_nosc":"simplifiais","lemma":"simplifier","pos":"VER"} ,
		{"word":"simplifiait","word_nosc":"simplifiait","lemma":"simplifier","pos":"VER"} ,
		{"word":"simplifiant","word_nosc":"simplifiant","lemma":"simplifier","pos":"VER"} ,
		{"word":"simplifie","word_nosc":"simplifie","lemma":"simplifier","pos":"VER"} ,
		{"word":"simplifient","word_nosc":"simplifient","lemma":"simplifier","pos":"VER"} ,
		{"word":"simplifier","word_nosc":"simplifier","lemma":"simplifier","pos":"VER"} ,
		{"word":"simplifiera","word_nosc":"simplifiera","lemma":"simplifier","pos":"VER"} ,
		{"word":"simplifierait","word_nosc":"simplifierait","lemma":"simplifier","pos":"VER"} ,
		{"word":"simplifiez","word_nosc":"simplifiez","lemma":"simplifier","pos":"VER"} ,
		{"word":"simplifions","word_nosc":"simplifions","lemma":"simplifier","pos":"VER"} ,
		{"word":"simplifié","word_nosc":"simplifie","lemma":"simplifier","pos":"VER"} ,
		{"word":"simplifiée","word_nosc":"simplifiee","lemma":"simplifier","pos":"VER"} ,
		{"word":"simplifiées","word_nosc":"simplifiees","lemma":"simplifier","pos":"VER"} ,
		{"word":"simplifiés","word_nosc":"simplifies","lemma":"simplifier","pos":"VER"} ,
		{"word":"simula","word_nosc":"simula","lemma":"simuler","pos":"VER"} ,
		{"word":"simulaient","word_nosc":"simulaient","lemma":"simuler","pos":"VER"} ,
		{"word":"simulais","word_nosc":"simulais","lemma":"simuler","pos":"VER"} ,
		{"word":"simulait","word_nosc":"simulait","lemma":"simuler","pos":"VER"} ,
		{"word":"simulant","word_nosc":"simulant","lemma":"simuler","pos":"VER"} ,
		{"word":"simule","word_nosc":"simule","lemma":"simuler","pos":"VER"} ,
		{"word":"simulent","word_nosc":"simulent","lemma":"simuler","pos":"VER"} ,
		{"word":"simuler","word_nosc":"simuler","lemma":"simuler","pos":"VER"} ,
		{"word":"simulera","word_nosc":"simulera","lemma":"simuler","pos":"VER"} ,
		{"word":"simulerai","word_nosc":"simulerai","lemma":"simuler","pos":"VER"} ,
		{"word":"simulerez","word_nosc":"simulerez","lemma":"simuler","pos":"VER"} ,
		{"word":"simules","word_nosc":"simules","lemma":"simuler","pos":"VER"} ,
		{"word":"simulez","word_nosc":"simulez","lemma":"simuler","pos":"VER"} ,
		{"word":"simuliez","word_nosc":"simuliez","lemma":"simuler","pos":"VER"} ,
		{"word":"simulons","word_nosc":"simulons","lemma":"simuler","pos":"VER"} ,
		{"word":"simulèrent","word_nosc":"simulerent","lemma":"simuler","pos":"VER"} ,
		{"word":"simulé","word_nosc":"simule","lemma":"simuler","pos":"VER"} ,
		{"word":"simulée","word_nosc":"simulee","lemma":"simuler","pos":"VER"} ,
		{"word":"simulées","word_nosc":"simulees","lemma":"simuler","pos":"VER"} ,
		{"word":"simulés","word_nosc":"simules","lemma":"simuler","pos":"VER"} ,
		{"word":"singe","word_nosc":"singe","lemma":"singer","pos":"VER"} ,
		{"word":"singea","word_nosc":"singea","lemma":"singer","pos":"VER"} ,
		{"word":"singeaient","word_nosc":"singeaient","lemma":"singer","pos":"VER"} ,
		{"word":"singeait","word_nosc":"singeait","lemma":"singer","pos":"VER"} ,
		{"word":"singeant","word_nosc":"singeant","lemma":"singer","pos":"VER"} ,
		{"word":"singent","word_nosc":"singent","lemma":"singer","pos":"VER"} ,
		{"word":"singer","word_nosc":"singer","lemma":"singer","pos":"VER"} ,
		{"word":"singes","word_nosc":"singes","lemma":"singer","pos":"VER"} ,
		{"word":"singiez","word_nosc":"singiez","lemma":"singer","pos":"VER"} ,
		{"word":"singularisait","word_nosc":"singularisait","lemma":"singulariser","pos":"VER"} ,
		{"word":"singularise","word_nosc":"singularise","lemma":"singulariser","pos":"VER"} ,
		{"word":"singulariser","word_nosc":"singulariser","lemma":"singulariser","pos":"VER"} ,
		{"word":"singularises","word_nosc":"singularises","lemma":"singulariser","pos":"VER"} ,
		{"word":"singé","word_nosc":"singe","lemma":"singer","pos":"VER"} ,
		{"word":"singées","word_nosc":"singees","lemma":"singer","pos":"VER"} ,
		{"word":"sinuaient","word_nosc":"sinuaient","lemma":"sinuer","pos":"VER"} ,
		{"word":"sinuait","word_nosc":"sinuait","lemma":"sinuer","pos":"VER"} ,
		{"word":"sinuant","word_nosc":"sinuant","lemma":"sinuer","pos":"VER"} ,
		{"word":"sinue","word_nosc":"sinue","lemma":"sinuer","pos":"VER"} ,
		{"word":"sinuent","word_nosc":"sinuent","lemma":"sinuer","pos":"VER"} ,
		{"word":"sinuer","word_nosc":"sinuer","lemma":"sinuer","pos":"VER"} ,
		{"word":"sinué","word_nosc":"sinue","lemma":"sinuer","pos":"VER"} ,
		{"word":"siphonnait","word_nosc":"siphonnait","lemma":"siphonner","pos":"VER"} ,
		{"word":"siphonne","word_nosc":"siphonne","lemma":"siphonner","pos":"VER"} ,
		{"word":"siphonner","word_nosc":"siphonner","lemma":"siphonner","pos":"VER"} ,
		{"word":"siphonné","word_nosc":"siphonne","lemma":"siphonner","pos":"VER"} ,
		{"word":"siphonnée","word_nosc":"siphonnee","lemma":"siphonner","pos":"VER"} ,
		{"word":"siphonnés","word_nosc":"siphonnes","lemma":"siphonner","pos":"VER"} ,
		{"word":"sirota","word_nosc":"sirota","lemma":"siroter","pos":"VER"} ,
		{"word":"sirotaient","word_nosc":"sirotaient","lemma":"siroter","pos":"VER"} ,
		{"word":"sirotais","word_nosc":"sirotais","lemma":"siroter","pos":"VER"} ,
		{"word":"sirotait","word_nosc":"sirotait","lemma":"siroter","pos":"VER"} ,
		{"word":"sirotant","word_nosc":"sirotant","lemma":"siroter","pos":"VER"} ,
		{"word":"sirote","word_nosc":"sirote","lemma":"siroter","pos":"VER"} ,
		{"word":"sirotent","word_nosc":"sirotent","lemma":"siroter","pos":"VER"} ,
		{"word":"siroter","word_nosc":"siroter","lemma":"siroter","pos":"VER"} ,
		{"word":"siroterais","word_nosc":"siroterais","lemma":"siroter","pos":"VER"} ,
		{"word":"sirotiez","word_nosc":"sirotiez","lemma":"siroter","pos":"VER"} ,
		{"word":"sirotions","word_nosc":"sirotions","lemma":"siroter","pos":"VER"} ,
		{"word":"siroté","word_nosc":"sirote","lemma":"siroter","pos":"VER"} ,
		{"word":"situa","word_nosc":"situa","lemma":"situer","pos":"VER"} ,
		{"word":"situaient","word_nosc":"situaient","lemma":"situer","pos":"VER"} ,
		{"word":"situais","word_nosc":"situais","lemma":"situer","pos":"VER"} ,
		{"word":"situait","word_nosc":"situait","lemma":"situer","pos":"VER"} ,
		{"word":"situant","word_nosc":"situant","lemma":"situer","pos":"VER"} ,
		{"word":"situasse","word_nosc":"situasse","lemma":"situer","pos":"VER"} ,
		{"word":"situe","word_nosc":"situe","lemma":"situer","pos":"VER"} ,
		{"word":"situent","word_nosc":"situent","lemma":"situer","pos":"VER"} ,
		{"word":"situer","word_nosc":"situer","lemma":"situer","pos":"VER"} ,
		{"word":"situera","word_nosc":"situera","lemma":"situer","pos":"VER"} ,
		{"word":"situerai","word_nosc":"situerai","lemma":"situer","pos":"VER"} ,
		{"word":"situeraient","word_nosc":"situeraient","lemma":"situer","pos":"VER"} ,
		{"word":"situerais","word_nosc":"situerais","lemma":"situer","pos":"VER"} ,
		{"word":"situerait","word_nosc":"situerait","lemma":"situer","pos":"VER"} ,
		{"word":"situes","word_nosc":"situes","lemma":"situer","pos":"VER"} ,
		{"word":"situez","word_nosc":"situez","lemma":"situer","pos":"VER"} ,
		{"word":"situions","word_nosc":"situions","lemma":"situer","pos":"VER"} ,
		{"word":"situons","word_nosc":"situons","lemma":"situer","pos":"VER"} ,
		{"word":"situât","word_nosc":"situat","lemma":"situer","pos":"VER"} ,
		{"word":"situèrent","word_nosc":"situerent","lemma":"situer","pos":"VER"} ,
		{"word":"situé","word_nosc":"situe","lemma":"situer","pos":"VER"} ,
		{"word":"située","word_nosc":"situee","lemma":"situer","pos":"VER"} ,
		{"word":"situées","word_nosc":"situees","lemma":"situer","pos":"VER"} ,
		{"word":"situés","word_nosc":"situes","lemma":"situer","pos":"VER"} ,
		{"word":"siège","word_nosc":"siege","lemma":"siéger","pos":"VER"} ,
		{"word":"siègent","word_nosc":"siegent","lemma":"siéger","pos":"VER"} ,
		{"word":"sièges","word_nosc":"sieges","lemma":"siéger","pos":"VER"} ,
		{"word":"siéent","word_nosc":"sieent","lemma":"seoir","pos":"VER"} ,
		{"word":"siégeaient","word_nosc":"siegeaient","lemma":"siéger","pos":"VER"} ,
		{"word":"siégeais","word_nosc":"siegeais","lemma":"siéger","pos":"VER"} ,
		{"word":"siégeait","word_nosc":"siegeait","lemma":"siéger","pos":"VER"} ,
		{"word":"siégeant","word_nosc":"siegeant","lemma":"siéger","pos":"VER"} ,
		{"word":"siégeons","word_nosc":"siegeons","lemma":"siéger","pos":"VER"} ,
		{"word":"siéger","word_nosc":"sieger","lemma":"siéger","pos":"VER"} ,
		{"word":"siégera","word_nosc":"siegera","lemma":"siéger","pos":"VER"} ,
		{"word":"siégerai","word_nosc":"siegerai","lemma":"siéger","pos":"VER"} ,
		{"word":"siégeraient","word_nosc":"siegeraient","lemma":"siéger","pos":"VER"} ,
		{"word":"siégerait","word_nosc":"siegerait","lemma":"siéger","pos":"VER"} ,
		{"word":"siégeras","word_nosc":"siegeras","lemma":"siéger","pos":"VER"} ,
		{"word":"siégerons","word_nosc":"siegerons","lemma":"siéger","pos":"VER"} ,
		{"word":"siégeront","word_nosc":"siegeront","lemma":"siéger","pos":"VER"} ,
		{"word":"siégez","word_nosc":"siegez","lemma":"siéger","pos":"VER"} ,
		{"word":"siégiez","word_nosc":"siegiez","lemma":"siéger","pos":"VER"} ,
		{"word":"siégions","word_nosc":"siegions","lemma":"siéger","pos":"VER"} ,
		{"word":"siégé","word_nosc":"siege","lemma":"siéger","pos":"VER"} ,
		{"word":"siéra","word_nosc":"siera","lemma":"seoir","pos":"VER"} ,
		{"word":"siérait","word_nosc":"sierait","lemma":"seoir","pos":"VER"} ,
		{"word":"skiais","word_nosc":"skiais","lemma":"skier","pos":"VER"} ,
		{"word":"skiait","word_nosc":"skiait","lemma":"skier","pos":"VER"} ,
		{"word":"skiant","word_nosc":"skiant","lemma":"skier","pos":"VER"} ,
		{"word":"skie","word_nosc":"skie","lemma":"skier","pos":"VER"} ,
		{"word":"skient","word_nosc":"skient","lemma":"skier","pos":"VER"} ,
		{"word":"skier","word_nosc":"skier","lemma":"skier","pos":"VER"} ,
		{"word":"skierai","word_nosc":"skierai","lemma":"skier","pos":"VER"} ,
		{"word":"skierait","word_nosc":"skierait","lemma":"skier","pos":"VER"} ,
		{"word":"skies","word_nosc":"skies","lemma":"skier","pos":"VER"} ,
		{"word":"skiez","word_nosc":"skiez","lemma":"skier","pos":"VER"} ,
		{"word":"skipper","word_nosc":"skipper","lemma":"skipper","pos":"VER"} ,
		{"word":"skié","word_nosc":"skie","lemma":"skier","pos":"VER"} ,
		{"word":"slalomant","word_nosc":"slalomant","lemma":"slalomer","pos":"VER"} ,
		{"word":"slalomer","word_nosc":"slalomer","lemma":"slalomer","pos":"VER"} ,
		{"word":"slalomé","word_nosc":"slalome","lemma":"slalomer","pos":"VER"} ,
		{"word":"slavisant","word_nosc":"slavisant","lemma":"slaviser","pos":"VER"} ,
		{"word":"smashe","word_nosc":"smashe","lemma":"smasher","pos":"VER"} ,
		{"word":"smasher","word_nosc":"smasher","lemma":"smasher","pos":"VER"} ,
		{"word":"snifer","word_nosc":"snifer","lemma":"snifer","pos":"VER"} ,
		{"word":"sniffaient","word_nosc":"sniffaient","lemma":"sniffer","pos":"VER"} ,
		{"word":"sniffais","word_nosc":"sniffais","lemma":"sniffer","pos":"VER"} ,
		{"word":"sniffait","word_nosc":"sniffait","lemma":"sniffer","pos":"VER"} ,
		{"word":"sniffant","word_nosc":"sniffant","lemma":"sniffer","pos":"VER"} ,
		{"word":"sniffe","word_nosc":"sniffe","lemma":"sniffer","pos":"VER"} ,
		{"word":"sniffent","word_nosc":"sniffent","lemma":"sniffer","pos":"VER"} ,
		{"word":"sniffer","word_nosc":"sniffer","lemma":"sniffer","pos":"VER"} ,
		{"word":"snifferai","word_nosc":"snifferai","lemma":"sniffer","pos":"VER"} ,
		{"word":"sniffes","word_nosc":"sniffes","lemma":"sniffer","pos":"VER"} ,
		{"word":"sniffez","word_nosc":"sniffez","lemma":"sniffer","pos":"VER"} ,
		{"word":"sniffé","word_nosc":"sniffe","lemma":"sniffer","pos":"VER"} ,
		{"word":"sniffée","word_nosc":"sniffee","lemma":"sniffer","pos":"VER"} ,
		{"word":"sniffées","word_nosc":"sniffees","lemma":"sniffer","pos":"VER"} ,
		{"word":"snobaient","word_nosc":"snobaient","lemma":"snober","pos":"VER"} ,
		{"word":"snobais","word_nosc":"snobais","lemma":"snober","pos":"VER"} ,
		{"word":"snobe","word_nosc":"snobe","lemma":"snober","pos":"VER"} ,
		{"word":"snobent","word_nosc":"snobent","lemma":"snober","pos":"VER"} ,
		{"word":"snober","word_nosc":"snober","lemma":"snober","pos":"VER"} ,
		{"word":"snoberez","word_nosc":"snoberez","lemma":"snober","pos":"VER"} ,
		{"word":"snobez","word_nosc":"snobez","lemma":"snober","pos":"VER"} ,
		{"word":"snobé","word_nosc":"snobe","lemma":"snober","pos":"VER"} ,
		{"word":"snobée","word_nosc":"snobee","lemma":"snober","pos":"VER"} ,
		{"word":"snobés","word_nosc":"snobes","lemma":"snober","pos":"VER"} ,
		{"word":"sociabiliser","word_nosc":"sociabiliser","lemma":"sociabiliser","pos":"VER"} ,
		{"word":"socialise","word_nosc":"socialise","lemma":"socialiser","pos":"VER"} ,
		{"word":"socialiser","word_nosc":"socialiser","lemma":"socialiser","pos":"VER"} ,
		{"word":"socialisez","word_nosc":"socialisez","lemma":"socialiser","pos":"VER"} ,
		{"word":"sodomisa","word_nosc":"sodomisa","lemma":"sodomiser","pos":"VER"} ,
		{"word":"sodomisait","word_nosc":"sodomisait","lemma":"sodomiser","pos":"VER"} ,
		{"word":"sodomisant","word_nosc":"sodomisant","lemma":"sodomiser","pos":"VER"} ,
		{"word":"sodomise","word_nosc":"sodomise","lemma":"sodomiser","pos":"VER"} ,
		{"word":"sodomiser","word_nosc":"sodomiser","lemma":"sodomiser","pos":"VER"} ,
		{"word":"sodomiseront","word_nosc":"sodomiseront","lemma":"sodomiser","pos":"VER"} ,
		{"word":"sodomisez","word_nosc":"sodomisez","lemma":"sodomiser","pos":"VER"} ,
		{"word":"sodomisé","word_nosc":"sodomise","lemma":"sodomiser","pos":"VER"} ,
		{"word":"sodomisée","word_nosc":"sodomisee","lemma":"sodomiser","pos":"VER"} ,
		{"word":"sodomisés","word_nosc":"sodomises","lemma":"sodomiser","pos":"VER"} ,
		{"word":"soient","word_nosc":"soient","lemma":"être","pos":"VER"} ,
		{"word":"soigna","word_nosc":"soigna","lemma":"soigner","pos":"VER"} ,
		{"word":"soignai","word_nosc":"soignai","lemma":"soigner","pos":"VER"} ,
		{"word":"soignaient","word_nosc":"soignaient","lemma":"soigner","pos":"VER"} ,
		{"word":"soignais","word_nosc":"soignais","lemma":"soigner","pos":"VER"} ,
		{"word":"soignait","word_nosc":"soignait","lemma":"soigner","pos":"VER"} ,
		{"word":"soignant","word_nosc":"soignant","lemma":"soigner","pos":"VER"} ,
		{"word":"soigne","word_nosc":"soigne","lemma":"soigner","pos":"VER"} ,
		{"word":"soignent","word_nosc":"soignent","lemma":"soigner","pos":"VER"} ,
		{"word":"soigner","word_nosc":"soigner","lemma":"saler","pos":"VER"} ,
		{"word":"soigner","word_nosc":"soigner","lemma":"soigner","pos":"VER"} ,
		{"word":"soignera","word_nosc":"soignera","lemma":"soigner","pos":"VER"} ,
		{"word":"soignerai","word_nosc":"soignerai","lemma":"soigner","pos":"VER"} ,
		{"word":"soigneraient","word_nosc":"soigneraient","lemma":"soigner","pos":"VER"} ,
		{"word":"soignerais","word_nosc":"soignerais","lemma":"soigner","pos":"VER"} ,
		{"word":"soignerait","word_nosc":"soignerait","lemma":"soigner","pos":"VER"} ,
		{"word":"soigneras","word_nosc":"soigneras","lemma":"soigner","pos":"VER"} ,
		{"word":"soignerez","word_nosc":"soignerez","lemma":"soigner","pos":"VER"} ,
		{"word":"soignerons","word_nosc":"soignerons","lemma":"soigner","pos":"VER"} ,
		{"word":"soigneront","word_nosc":"soigneront","lemma":"soigner","pos":"VER"} ,
		{"word":"soignes","word_nosc":"soignes","lemma":"soigner","pos":"VER"} ,
		{"word":"soignez","word_nosc":"soignez","lemma":"soigner","pos":"VER"} ,
		{"word":"soigniez","word_nosc":"soigniez","lemma":"soigner","pos":"VER"} ,
		{"word":"soignons","word_nosc":"soignons","lemma":"soigner","pos":"VER"} ,
		{"word":"soignèrent","word_nosc":"soignerent","lemma":"soigner","pos":"VER"} ,
		{"word":"soigné","word_nosc":"soigne","lemma":"soigner","pos":"VER"} ,
		{"word":"soignée","word_nosc":"soignee","lemma":"soigner","pos":"VER"} ,
		{"word":"soignées","word_nosc":"soignees","lemma":"soigner","pos":"VER"} ,
		{"word":"soignés","word_nosc":"soignes","lemma":"soigner","pos":"VER"} ,
		{"word":"sois","word_nosc":"sois","lemma":"être","pos":"VER"} ,
		{"word":"soit","word_nosc":"soit","lemma":"être","pos":"VER"} ,
		{"word":"solda","word_nosc":"solda","lemma":"solder","pos":"VER"} ,
		{"word":"soldaient","word_nosc":"soldaient","lemma":"solder","pos":"VER"} ,
		{"word":"soldait","word_nosc":"soldait","lemma":"solder","pos":"VER"} ,
		{"word":"solde","word_nosc":"solde","lemma":"solder","pos":"VER"} ,
		{"word":"soldent","word_nosc":"soldent","lemma":"solder","pos":"VER"} ,
		{"word":"solder","word_nosc":"solder","lemma":"solder","pos":"VER"} ,
		{"word":"soldera","word_nosc":"soldera","lemma":"solder","pos":"VER"} ,
		{"word":"solderais","word_nosc":"solderais","lemma":"solder","pos":"VER"} ,
		{"word":"solderait","word_nosc":"solderait","lemma":"solder","pos":"VER"} ,
		{"word":"soldez","word_nosc":"soldez","lemma":"solder","pos":"VER"} ,
		{"word":"soldât","word_nosc":"soldat","lemma":"solder","pos":"VER"} ,
		{"word":"soldèrent","word_nosc":"solderent","lemma":"solder","pos":"VER"} ,
		{"word":"soldé","word_nosc":"solde","lemma":"solder","pos":"VER"} ,
		{"word":"soldée","word_nosc":"soldee","lemma":"solder","pos":"VER"} ,
		{"word":"soldées","word_nosc":"soldees","lemma":"solder","pos":"VER"} ,
		{"word":"soldés","word_nosc":"soldes","lemma":"solder","pos":"VER"} ,
		{"word":"solenniser","word_nosc":"solenniser","lemma":"solenniser","pos":"VER"} ,
		{"word":"solennisèrent","word_nosc":"solenniserent","lemma":"solenniser","pos":"VER"} ,
		{"word":"solidariser","word_nosc":"solidariser","lemma":"solidariser","pos":"VER"} ,
		{"word":"solidarisez","word_nosc":"solidarisez","lemma":"solidariser","pos":"VER"} ,
		{"word":"solidifia","word_nosc":"solidifia","lemma":"solidifier","pos":"VER"} ,
		{"word":"solidifiait","word_nosc":"solidifiait","lemma":"solidifier","pos":"VER"} ,
		{"word":"solidifie","word_nosc":"solidifie","lemma":"solidifier","pos":"VER"} ,
		{"word":"solidifient","word_nosc":"solidifient","lemma":"solidifier","pos":"VER"} ,
		{"word":"solidifier","word_nosc":"solidifier","lemma":"solidifier","pos":"VER"} ,
		{"word":"solidifié","word_nosc":"solidifie","lemma":"solidifier","pos":"VER"} ,
		{"word":"solidifiée","word_nosc":"solidifiee","lemma":"solidifier","pos":"VER"} ,
		{"word":"solidifiées","word_nosc":"solidifiees","lemma":"solidifier","pos":"VER"} ,
		{"word":"solidifiés","word_nosc":"solidifies","lemma":"solidifier","pos":"VER"} ,
		{"word":"soliloqua","word_nosc":"soliloqua","lemma":"soliloquer","pos":"VER"} ,
		{"word":"soliloquait","word_nosc":"soliloquait","lemma":"soliloquer","pos":"VER"} ,
		{"word":"soliloque","word_nosc":"soliloque","lemma":"soliloquer","pos":"VER"} ,
		{"word":"soliloquer","word_nosc":"soliloquer","lemma":"soliloquer","pos":"VER"} ,
		{"word":"sollicita","word_nosc":"sollicita","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicitai","word_nosc":"sollicitai","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicitaient","word_nosc":"sollicitaient","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicitais","word_nosc":"sollicitais","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicitait","word_nosc":"sollicitait","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicitant","word_nosc":"sollicitant","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicite","word_nosc":"sollicite","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicitent","word_nosc":"sollicitent","lemma":"solliciter","pos":"VER"} ,
		{"word":"solliciter","word_nosc":"solliciter","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicitera","word_nosc":"sollicitera","lemma":"solliciter","pos":"VER"} ,
		{"word":"solliciteraient","word_nosc":"solliciteraient","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicitez","word_nosc":"sollicitez","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicitons","word_nosc":"sollicitons","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicitât","word_nosc":"sollicitat","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicitèrent","word_nosc":"solliciterent","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicité","word_nosc":"sollicite","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicitée","word_nosc":"sollicitee","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicitées","word_nosc":"sollicitees","lemma":"solliciter","pos":"VER"} ,
		{"word":"sollicités","word_nosc":"sollicites","lemma":"solliciter","pos":"VER"} ,
		{"word":"solutionnait","word_nosc":"solutionnait","lemma":"solutionner","pos":"VER"} ,
		{"word":"solutionner","word_nosc":"solutionner","lemma":"solutionner","pos":"VER"} ,
		{"word":"solutionnons","word_nosc":"solutionnons","lemma":"solutionner","pos":"VER"} ,
		{"word":"somatisant","word_nosc":"somatisant","lemma":"somatiser","pos":"VER"} ,
		{"word":"somatise","word_nosc":"somatise","lemma":"somatiser","pos":"VER"} ,
		{"word":"somatiser","word_nosc":"somatiser","lemma":"somatiser","pos":"VER"} ,
		{"word":"somatiserez","word_nosc":"somatiserez","lemma":"somatiser","pos":"VER"} ,
		{"word":"somatisée","word_nosc":"somatisee","lemma":"somatiser","pos":"VER"} ,
		{"word":"sombra","word_nosc":"sombra","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrai","word_nosc":"sombrai","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombraient","word_nosc":"sombraient","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrais","word_nosc":"sombrais","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrait","word_nosc":"sombrait","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrant","word_nosc":"sombrant","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombre","word_nosc":"sombre","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrent","word_nosc":"sombrent","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrer","word_nosc":"sombrer","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrera","word_nosc":"sombrera","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombreraient","word_nosc":"sombreraient","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrerais","word_nosc":"sombrerais","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrerait","word_nosc":"sombrerait","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrerez","word_nosc":"sombrerez","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrerons","word_nosc":"sombrerons","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombres","word_nosc":"sombres","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrez","word_nosc":"sombrez","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrions","word_nosc":"sombrions","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrons","word_nosc":"sombrons","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrèrent","word_nosc":"sombrerent","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombré","word_nosc":"sombre","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrée","word_nosc":"sombree","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrées","word_nosc":"sombrees","lemma":"sombrer","pos":"VER"} ,
		{"word":"sombrés","word_nosc":"sombres","lemma":"sombrer","pos":"VER"} ,
		{"word":"somma","word_nosc":"somma","lemma":"sommer","pos":"VER"} ,
		{"word":"sommai","word_nosc":"sommai","lemma":"sommer","pos":"VER"} ,
		{"word":"sommais","word_nosc":"sommais","lemma":"sommer","pos":"VER"} ,
		{"word":"sommait","word_nosc":"sommait","lemma":"sommer","pos":"VER"} ,
		{"word":"sommant","word_nosc":"sommant","lemma":"sommer","pos":"VER"} ,
		{"word":"somme","word_nosc":"somme","lemma":"sommer","pos":"VER"} ,
		{"word":"sommeilla","word_nosc":"sommeilla","lemma":"sommeiller","pos":"VER"} ,
		{"word":"sommeillaient","word_nosc":"sommeillaient","lemma":"sommeiller","pos":"VER"} ,
		{"word":"sommeillais","word_nosc":"sommeillais","lemma":"sommeiller","pos":"VER"} ,
		{"word":"sommeillait","word_nosc":"sommeillait","lemma":"sommeiller","pos":"VER"} ,
		{"word":"sommeillant","word_nosc":"sommeillant","lemma":"sommeiller","pos":"VER"} ,
		{"word":"sommeille","word_nosc":"sommeille","lemma":"sommeiller","pos":"VER"} ,
		{"word":"sommeillent","word_nosc":"sommeillent","lemma":"sommeiller","pos":"VER"} ,
		{"word":"sommeiller","word_nosc":"sommeiller","lemma":"sommeiller","pos":"VER"} ,
		{"word":"sommeillèrent","word_nosc":"sommeillerent","lemma":"sommeiller","pos":"VER"} ,
		{"word":"sommeillé","word_nosc":"sommeille","lemma":"sommeiller","pos":"VER"} ,
		{"word":"somment","word_nosc":"somment","lemma":"sommer","pos":"VER"} ,
		{"word":"sommer","word_nosc":"sommer","lemma":"sommer","pos":"VER"} ,
		{"word":"sommes","word_nosc":"sommes","lemma":"sommer","pos":"VER"} ,
		{"word":"sommes","word_nosc":"sommes","lemma":"être","pos":"VER"} ,
		{"word":"sommiez","word_nosc":"sommiez","lemma":"sommer","pos":"VER"} ,
		{"word":"sommons","word_nosc":"sommons","lemma":"sommer","pos":"VER"} ,
		{"word":"sommèrent","word_nosc":"sommerent","lemma":"sommer","pos":"VER"} ,
		{"word":"sommé","word_nosc":"somme","lemma":"sommer","pos":"VER"} ,
		{"word":"sommée","word_nosc":"sommee","lemma":"sommer","pos":"VER"} ,
		{"word":"sommés","word_nosc":"sommes","lemma":"sommer","pos":"VER"} ,
		{"word":"somnambulant","word_nosc":"somnambulant","lemma":"somnambuler","pos":"VER"} ,
		{"word":"somnola","word_nosc":"somnola","lemma":"somnoler","pos":"VER"} ,
		{"word":"somnolai","word_nosc":"somnolai","lemma":"somnoler","pos":"VER"} ,
		{"word":"somnolaient","word_nosc":"somnolaient","lemma":"somnoler","pos":"VER"} ,
		{"word":"somnolais","word_nosc":"somnolais","lemma":"somnoler","pos":"VER"} ,
		{"word":"somnolait","word_nosc":"somnolait","lemma":"somnoler","pos":"VER"} ,
		{"word":"somnolant","word_nosc":"somnolant","lemma":"somnoler","pos":"VER"} ,
		{"word":"somnole","word_nosc":"somnole","lemma":"somnoler","pos":"VER"} ,
		{"word":"somnolent","word_nosc":"somnolent","lemma":"somnoler","pos":"VER"} ,
		{"word":"somnoler","word_nosc":"somnoler","lemma":"somnoler","pos":"VER"} ,
		{"word":"somnolerais","word_nosc":"somnolerais","lemma":"somnoler","pos":"VER"} ,
		{"word":"somnolez","word_nosc":"somnolez","lemma":"somnoler","pos":"VER"} ,
		{"word":"somnolé","word_nosc":"somnole","lemma":"somnoler","pos":"VER"} ,
		{"word":"sonda","word_nosc":"sonda","lemma":"sonder","pos":"VER"} ,
		{"word":"sondaient","word_nosc":"sondaient","lemma":"sonder","pos":"VER"} ,
		{"word":"sondait","word_nosc":"sondait","lemma":"sonder","pos":"VER"} ,
		{"word":"sondant","word_nosc":"sondant","lemma":"sonder","pos":"VER"} ,
		{"word":"sondas","word_nosc":"sondas","lemma":"sonder","pos":"VER"} ,
		{"word":"sonde","word_nosc":"sonde","lemma":"sonder","pos":"VER"} ,
		{"word":"sondent","word_nosc":"sondent","lemma":"sonder","pos":"VER"} ,
		{"word":"sonder","word_nosc":"sonder","lemma":"sonder","pos":"VER"} ,
		{"word":"sonderai","word_nosc":"sonderai","lemma":"sonder","pos":"VER"} ,
		{"word":"sondez","word_nosc":"sondez","lemma":"sonder","pos":"VER"} ,
		{"word":"sondions","word_nosc":"sondions","lemma":"sonder","pos":"VER"} ,
		{"word":"sondons","word_nosc":"sondons","lemma":"sonder","pos":"VER"} ,
		{"word":"sondèrent","word_nosc":"sonderent","lemma":"sonder","pos":"VER"} ,
		{"word":"sondé","word_nosc":"sonde","lemma":"sonder","pos":"VER"} ,
		{"word":"sondée","word_nosc":"sondee","lemma":"sonder","pos":"VER"} ,
		{"word":"sondés","word_nosc":"sondes","lemma":"sonder","pos":"VER"} ,
		{"word":"songe","word_nosc":"songe","lemma":"songer","pos":"VER"} ,
		{"word":"songea","word_nosc":"songea","lemma":"songer","pos":"VER"} ,
		{"word":"songeai","word_nosc":"songeai","lemma":"songer","pos":"VER"} ,
		{"word":"songeaient","word_nosc":"songeaient","lemma":"songer","pos":"VER"} ,
		{"word":"songeais","word_nosc":"songeais","lemma":"songer","pos":"VER"} ,
		{"word":"songeait","word_nosc":"songeait","lemma":"songer","pos":"VER"} ,
		{"word":"songeant","word_nosc":"songeant","lemma":"songer","pos":"VER"} ,
		{"word":"songeasse","word_nosc":"songeasse","lemma":"songer","pos":"VER"} ,
		{"word":"songent","word_nosc":"songent","lemma":"songer","pos":"VER"} ,
		{"word":"songeons","word_nosc":"songeons","lemma":"songer","pos":"VER"} ,
		{"word":"songer","word_nosc":"songer","lemma":"songer","pos":"VER"} ,
		{"word":"songera","word_nosc":"songera","lemma":"songer","pos":"VER"} ,
		{"word":"songerai","word_nosc":"songerai","lemma":"songer","pos":"VER"} ,
		{"word":"songeraient","word_nosc":"songeraient","lemma":"songer","pos":"VER"} ,
		{"word":"songerais","word_nosc":"songerais","lemma":"songer","pos":"VER"} ,
		{"word":"songerait","word_nosc":"songerait","lemma":"songer","pos":"VER"} ,
		{"word":"songeriez","word_nosc":"songeriez","lemma":"songer","pos":"VER"} ,
		{"word":"songerons","word_nosc":"songerons","lemma":"songer","pos":"VER"} ,
		{"word":"songes","word_nosc":"songes","lemma":"songer","pos":"VER"} ,
		{"word":"songez","word_nosc":"songez","lemma":"songer","pos":"VER"} ,
		{"word":"songeât","word_nosc":"songeat","lemma":"songer","pos":"VER"} ,
		{"word":"songiez","word_nosc":"songiez","lemma":"songer","pos":"VER"} ,
		{"word":"songions","word_nosc":"songions","lemma":"songer","pos":"VER"} ,
		{"word":"songèrent","word_nosc":"songerent","lemma":"songer","pos":"VER"} ,
		{"word":"songé","word_nosc":"songe","lemma":"songer","pos":"VER"} ,
		{"word":"sonna","word_nosc":"sonna","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnai","word_nosc":"sonnai","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnaient","word_nosc":"sonnaient","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnaillaient","word_nosc":"sonnaillaient","lemma":"sonnailler","pos":"VER"} ,
		{"word":"sonnailler","word_nosc":"sonnailler","lemma":"sonnailler","pos":"VER"} ,
		{"word":"sonnais","word_nosc":"sonnais","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnait","word_nosc":"sonnait","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnant","word_nosc":"sonnant","lemma":"sonner","pos":"VER"} ,
		{"word":"sonne","word_nosc":"sonne","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnent","word_nosc":"sonnent","lemma":"sonner","pos":"VER"} ,
		{"word":"sonner","word_nosc":"sonner","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnera","word_nosc":"sonnera","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnerai","word_nosc":"sonnerai","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnerais","word_nosc":"sonnerais","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnerait","word_nosc":"sonnerait","lemma":"sonner","pos":"VER"} ,
		{"word":"sonneras","word_nosc":"sonneras","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnerez","word_nosc":"sonnerez","lemma":"sonner","pos":"VER"} ,
		{"word":"sonneront","word_nosc":"sonneront","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnes","word_nosc":"sonnes","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnez","word_nosc":"sonnez","lemma":"sonner","pos":"VER"} ,
		{"word":"sonniez","word_nosc":"sonniez","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnons","word_nosc":"sonnons","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnât","word_nosc":"sonnat","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnèrent","word_nosc":"sonnerent","lemma":"sonner","pos":"VER"} ,
		{"word":"sonné","word_nosc":"sonne","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnée","word_nosc":"sonnee","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnées","word_nosc":"sonnees","lemma":"sonner","pos":"VER"} ,
		{"word":"sonnés","word_nosc":"sonnes","lemma":"sonner","pos":"VER"} ,
		{"word":"sonorise","word_nosc":"sonorise","lemma":"sonoriser","pos":"VER"} ,
		{"word":"sonoriser","word_nosc":"sonoriser","lemma":"sonoriser","pos":"VER"} ,
		{"word":"sonorisé","word_nosc":"sonorise","lemma":"sonoriser","pos":"VER"} ,
		{"word":"sonorisées","word_nosc":"sonorisees","lemma":"sonoriser","pos":"VER"} ,
		{"word":"sont","word_nosc":"sont","lemma":"être","pos":"VER"} ,
		{"word":"sophistiqué","word_nosc":"sophistique","lemma":"sophistiquer","pos":"VER"} ,
		{"word":"sophistiquée","word_nosc":"sophistiquee","lemma":"sophistiquer","pos":"VER"} ,
		{"word":"sophistiquées","word_nosc":"sophistiquees","lemma":"sophistiquer","pos":"VER"} ,
		{"word":"sophistiqués","word_nosc":"sophistiques","lemma":"sophistiquer","pos":"VER"} ,
		{"word":"sors","word_nosc":"sors","lemma":"sortir","pos":"VER"} ,
		{"word":"sort","word_nosc":"sort","lemma":"sortir","pos":"VER"} ,
		{"word":"sortaient","word_nosc":"sortaient","lemma":"sortir","pos":"VER"} ,
		{"word":"sortais","word_nosc":"sortais","lemma":"sortir","pos":"VER"} ,
		{"word":"sortait","word_nosc":"sortait","lemma":"sortir","pos":"VER"} ,
		{"word":"sortant","word_nosc":"sortant","lemma":"sortir","pos":"VER"} ,
		{"word":"sorte","word_nosc":"sorte","lemma":"sortir","pos":"VER"} ,
		{"word":"sortent","word_nosc":"sortent","lemma":"sortir","pos":"VER"} ,
		{"word":"sortes","word_nosc":"sortes","lemma":"sortir","pos":"VER"} ,
		{"word":"sortez","word_nosc":"sortez","lemma":"sortir","pos":"VER"} ,
		{"word":"sorti","word_nosc":"sorti","lemma":"sortir","pos":"VER"} ,
		{"word":"sortie","word_nosc":"sortie","lemma":"sortir","pos":"VER"} ,
		{"word":"sorties","word_nosc":"sorties","lemma":"sortir","pos":"VER"} ,
		{"word":"sortiez","word_nosc":"sortiez","lemma":"sortir","pos":"VER"} ,
		{"word":"sortions","word_nosc":"sortions","lemma":"sortir","pos":"VER"} ,
		{"word":"sortir","word_nosc":"sortir","lemma":"sortir","pos":"VER"} ,
		{"word":"sortira","word_nosc":"sortira","lemma":"sortir","pos":"VER"} ,
		{"word":"sortirai","word_nosc":"sortirai","lemma":"sortir","pos":"VER"} ,
		{"word":"sortiraient","word_nosc":"sortiraient","lemma":"sortir","pos":"VER"} ,
		{"word":"sortirais","word_nosc":"sortirais","lemma":"sortir","pos":"VER"} ,
		{"word":"sortirait","word_nosc":"sortirait","lemma":"sortir","pos":"VER"} ,
		{"word":"sortiras","word_nosc":"sortiras","lemma":"sortir","pos":"VER"} ,
		{"word":"sortirent","word_nosc":"sortirent","lemma":"sortir","pos":"VER"} ,
		{"word":"sortirez","word_nosc":"sortirez","lemma":"sortir","pos":"VER"} ,
		{"word":"sortiriez","word_nosc":"sortiriez","lemma":"sortir","pos":"VER"} ,
		{"word":"sortirions","word_nosc":"sortirions","lemma":"sortir","pos":"VER"} ,
		{"word":"sortirons","word_nosc":"sortirons","lemma":"sortir","pos":"VER"} ,
		{"word":"sortiront","word_nosc":"sortiront","lemma":"sortir","pos":"VER"} ,
		{"word":"sortis","word_nosc":"sortis","lemma":"sortir","pos":"VER"} ,
		{"word":"sortit","word_nosc":"sortit","lemma":"sortir","pos":"VER"} ,
		{"word":"sortons","word_nosc":"sortons","lemma":"sortir","pos":"VER"} ,
		{"word":"sortîmes","word_nosc":"sortimes","lemma":"sortir","pos":"VER"} ,
		{"word":"sortît","word_nosc":"sortit","lemma":"sortir","pos":"VER"} ,
		{"word":"soubresautaient","word_nosc":"soubresautaient","lemma":"soubresauter","pos":"VER"} ,
		{"word":"soubresautait","word_nosc":"soubresautait","lemma":"soubresauter","pos":"VER"} ,
		{"word":"soucia","word_nosc":"soucia","lemma":"soucier","pos":"VER"} ,
		{"word":"souciai","word_nosc":"souciai","lemma":"soucier","pos":"VER"} ,
		{"word":"souciaient","word_nosc":"souciaient","lemma":"soucier","pos":"VER"} ,
		{"word":"souciais","word_nosc":"souciais","lemma":"soucier","pos":"VER"} ,
		{"word":"souciait","word_nosc":"souciait","lemma":"soucier","pos":"VER"} ,
		{"word":"souciant","word_nosc":"souciant","lemma":"soucier","pos":"VER"} ,
		{"word":"soucie","word_nosc":"soucie","lemma":"soucier","pos":"VER"} ,
		{"word":"soucient","word_nosc":"soucient","lemma":"soucier","pos":"VER"} ,
		{"word":"soucier","word_nosc":"soucier","lemma":"soucier","pos":"VER"} ,
		{"word":"souciera","word_nosc":"souciera","lemma":"soucier","pos":"VER"} ,
		{"word":"soucierai","word_nosc":"soucierai","lemma":"soucier","pos":"VER"} ,
		{"word":"soucieraient","word_nosc":"soucieraient","lemma":"soucier","pos":"VER"} ,
		{"word":"soucierais","word_nosc":"soucierais","lemma":"soucier","pos":"VER"} ,
		{"word":"soucierait","word_nosc":"soucierait","lemma":"soucier","pos":"VER"} ,
		{"word":"soucierez","word_nosc":"soucierez","lemma":"soucier","pos":"VER"} ,
		{"word":"soucies","word_nosc":"soucies","lemma":"soucier","pos":"VER"} ,
		{"word":"souciez","word_nosc":"souciez","lemma":"soucier","pos":"VER"} ,
		{"word":"soucions","word_nosc":"soucions","lemma":"soucier","pos":"VER"} ,
		{"word":"souciât","word_nosc":"souciat","lemma":"soucier","pos":"VER"} ,
		{"word":"soucièrent","word_nosc":"soucierent","lemma":"soucier","pos":"VER"} ,
		{"word":"soucié","word_nosc":"soucie","lemma":"soucier","pos":"VER"} ,
		{"word":"souciée","word_nosc":"souciee","lemma":"soucier","pos":"VER"} ,
		{"word":"souciées","word_nosc":"souciees","lemma":"soucier","pos":"VER"} ,
		{"word":"souciés","word_nosc":"soucies","lemma":"soucier","pos":"VER"} ,
		{"word":"soudaient","word_nosc":"soudaient","lemma":"souder","pos":"VER"} ,
		{"word":"soudait","word_nosc":"soudait","lemma":"souder","pos":"VER"} ,
		{"word":"soudant","word_nosc":"soudant","lemma":"souder","pos":"VER"} ,
		{"word":"soude","word_nosc":"soude","lemma":"souder","pos":"VER"} ,
		{"word":"soudent","word_nosc":"soudent","lemma":"souder","pos":"VER"} ,
		{"word":"souder","word_nosc":"souder","lemma":"souder","pos":"VER"} ,
		{"word":"souderait","word_nosc":"souderait","lemma":"souder","pos":"VER"} ,
		{"word":"soudez","word_nosc":"soudez","lemma":"souder","pos":"VER"} ,
		{"word":"soudoie","word_nosc":"soudoie","lemma":"soudoyer","pos":"VER"} ,
		{"word":"soudoya","word_nosc":"soudoya","lemma":"soudoyer","pos":"VER"} ,
		{"word":"soudoyaient","word_nosc":"soudoyaient","lemma":"soudoyer","pos":"VER"} ,
		{"word":"soudoyait","word_nosc":"soudoyait","lemma":"soudoyer","pos":"VER"} ,
		{"word":"soudoyant","word_nosc":"soudoyant","lemma":"soudoyer","pos":"VER"} ,
		{"word":"soudoyer","word_nosc":"soudoyer","lemma":"soudoyer","pos":"VER"} ,
		{"word":"soudoyez","word_nosc":"soudoyez","lemma":"soudoyer","pos":"VER"} ,
		{"word":"soudoyé","word_nosc":"soudoye","lemma":"soudoyer","pos":"VER"} ,
		{"word":"soudoyée","word_nosc":"soudoyee","lemma":"soudoyer","pos":"VER"} ,
		{"word":"soudoyés","word_nosc":"soudoyes","lemma":"soudoyer","pos":"VER"} ,
		{"word":"soudèrent","word_nosc":"souderent","lemma":"souder","pos":"VER"} ,
		{"word":"soudé","word_nosc":"soude","lemma":"souder","pos":"VER"} ,
		{"word":"soudée","word_nosc":"soudee","lemma":"souder","pos":"VER"} ,
		{"word":"soudées","word_nosc":"soudees","lemma":"souder","pos":"VER"} ,
		{"word":"soudés","word_nosc":"soudes","lemma":"souder","pos":"VER"} ,
		{"word":"souffert","word_nosc":"souffert","lemma":"souffrir","pos":"VER"} ,
		{"word":"soufferte","word_nosc":"soufferte","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffertes","word_nosc":"souffertes","lemma":"souffrir","pos":"VER"} ,
		{"word":"soufferts","word_nosc":"soufferts","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffla","word_nosc":"souffla","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflai","word_nosc":"soufflai","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflaient","word_nosc":"soufflaient","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflais","word_nosc":"soufflais","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflait","word_nosc":"soufflait","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflant","word_nosc":"soufflant","lemma":"souffler","pos":"VER"} ,
		{"word":"souffle","word_nosc":"souffle","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflent","word_nosc":"soufflent","lemma":"souffler","pos":"VER"} ,
		{"word":"souffler","word_nosc":"souffler","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflera","word_nosc":"soufflera","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflerai","word_nosc":"soufflerai","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflerais","word_nosc":"soufflerais","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflerait","word_nosc":"soufflerait","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflerez","word_nosc":"soufflerez","lemma":"souffler","pos":"VER"} ,
		{"word":"souffleront","word_nosc":"souffleront","lemma":"souffler","pos":"VER"} ,
		{"word":"souffles","word_nosc":"souffles","lemma":"souffler","pos":"VER"} ,
		{"word":"souffletai","word_nosc":"souffletai","lemma":"souffleter","pos":"VER"} ,
		{"word":"souffletaient","word_nosc":"souffletaient","lemma":"souffleter","pos":"VER"} ,
		{"word":"souffletait","word_nosc":"souffletait","lemma":"souffleter","pos":"VER"} ,
		{"word":"souffletant","word_nosc":"souffletant","lemma":"souffleter","pos":"VER"} ,
		{"word":"souffleter","word_nosc":"souffleter","lemma":"souffleter","pos":"VER"} ,
		{"word":"soufflette","word_nosc":"soufflette","lemma":"souffleter","pos":"VER"} ,
		{"word":"soufflettent","word_nosc":"soufflettent","lemma":"souffleter","pos":"VER"} ,
		{"word":"soufflettes","word_nosc":"soufflettes","lemma":"souffleter","pos":"VER"} ,
		{"word":"souffleté","word_nosc":"soufflete","lemma":"souffleter","pos":"VER"} ,
		{"word":"souffletée","word_nosc":"souffletee","lemma":"souffleter","pos":"VER"} ,
		{"word":"souffletés","word_nosc":"souffletes","lemma":"souffleter","pos":"VER"} ,
		{"word":"soufflez","word_nosc":"soufflez","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflons","word_nosc":"soufflons","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflât","word_nosc":"soufflat","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflèrent","word_nosc":"soufflerent","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflé","word_nosc":"souffle","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflée","word_nosc":"soufflee","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflées","word_nosc":"soufflees","lemma":"souffler","pos":"VER"} ,
		{"word":"soufflés","word_nosc":"souffles","lemma":"souffler","pos":"VER"} ,
		{"word":"souffraient","word_nosc":"souffraient","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrais","word_nosc":"souffrais","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrait","word_nosc":"souffrait","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrant","word_nosc":"souffrant","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffre","word_nosc":"souffre","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrent","word_nosc":"souffrent","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffres","word_nosc":"souffres","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrez","word_nosc":"souffrez","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffriez","word_nosc":"souffriez","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrions","word_nosc":"souffrions","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrir","word_nosc":"souffrir","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrira","word_nosc":"souffrira","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrirai","word_nosc":"souffrirai","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffriraient","word_nosc":"souffriraient","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrirais","word_nosc":"souffrirais","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrirait","word_nosc":"souffrirait","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffriras","word_nosc":"souffriras","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrirent","word_nosc":"souffrirent","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrirez","word_nosc":"souffrirez","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffririez","word_nosc":"souffririez","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffririons","word_nosc":"souffririons","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrirons","word_nosc":"souffrirons","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffriront","word_nosc":"souffriront","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffris","word_nosc":"souffris","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrisse","word_nosc":"souffrisse","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrissent","word_nosc":"souffrissent","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrit","word_nosc":"souffrit","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrons","word_nosc":"souffrons","lemma":"souffrir","pos":"VER"} ,
		{"word":"souffrît","word_nosc":"souffrit","lemma":"souffrir","pos":"VER"} ,
		{"word":"soufre","word_nosc":"soufre","lemma":"soufrer","pos":"VER"} ,
		{"word":"soufrée","word_nosc":"soufree","lemma":"soufrer","pos":"VER"} ,
		{"word":"soufrées","word_nosc":"soufrees","lemma":"soufrer","pos":"VER"} ,
		{"word":"souhaita","word_nosc":"souhaita","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitai","word_nosc":"souhaitai","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitaient","word_nosc":"souhaitaient","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitais","word_nosc":"souhaitais","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitait","word_nosc":"souhaitait","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitant","word_nosc":"souhaitant","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaite","word_nosc":"souhaite","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitent","word_nosc":"souhaitent","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaiter","word_nosc":"souhaiter","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitera","word_nosc":"souhaitera","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaiterai","word_nosc":"souhaiterai","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaiteraient","word_nosc":"souhaiteraient","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaiterais","word_nosc":"souhaiterais","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaiterait","word_nosc":"souhaiterait","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaiteras","word_nosc":"souhaiteras","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaiterez","word_nosc":"souhaiterez","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaiteriez","word_nosc":"souhaiteriez","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaiterions","word_nosc":"souhaiterions","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaiterons","word_nosc":"souhaiterons","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaiteront","word_nosc":"souhaiteront","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaites","word_nosc":"souhaites","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitez","word_nosc":"souhaitez","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitiez","word_nosc":"souhaitiez","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitions","word_nosc":"souhaitions","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitons","word_nosc":"souhaitons","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitâmes","word_nosc":"souhaitames","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitât","word_nosc":"souhaitat","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitèrent","word_nosc":"souhaiterent","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaité","word_nosc":"souhaite","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitée","word_nosc":"souhaitee","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaitées","word_nosc":"souhaitees","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souhaités","word_nosc":"souhaites","lemma":"souhaiter","pos":"VER"} ,
		{"word":"souilla","word_nosc":"souilla","lemma":"souiller","pos":"VER"} ,
		{"word":"souillaient","word_nosc":"souillaient","lemma":"souiller","pos":"VER"} ,
		{"word":"souillait","word_nosc":"souillait","lemma":"souiller","pos":"VER"} ,
		{"word":"souillant","word_nosc":"souillant","lemma":"souiller","pos":"VER"} ,
		{"word":"souille","word_nosc":"souille","lemma":"souiller","pos":"VER"} ,
		{"word":"souillent","word_nosc":"souillent","lemma":"souiller","pos":"VER"} ,
		{"word":"souiller","word_nosc":"souiller","lemma":"souiller","pos":"VER"} ,
		{"word":"souillerai","word_nosc":"souillerai","lemma":"souiller","pos":"VER"} ,
		{"word":"souilleraient","word_nosc":"souilleraient","lemma":"souiller","pos":"VER"} ,
		{"word":"souillerait","word_nosc":"souillerait","lemma":"souiller","pos":"VER"} ,
		{"word":"souilleras","word_nosc":"souilleras","lemma":"souiller","pos":"VER"} ,
		{"word":"souilleront","word_nosc":"souilleront","lemma":"souiller","pos":"VER"} ,
		{"word":"souilles","word_nosc":"souilles","lemma":"souiller","pos":"VER"} ,
		{"word":"souillez","word_nosc":"souillez","lemma":"souiller","pos":"VER"} ,
		{"word":"souillonnes","word_nosc":"souillonnes","lemma":"souillonner","pos":"VER"} ,
		{"word":"souillé","word_nosc":"souille","lemma":"souiller","pos":"VER"} ,
		{"word":"souillée","word_nosc":"souillee","lemma":"souiller","pos":"VER"} ,
		{"word":"souillées","word_nosc":"souillees","lemma":"souiller","pos":"VER"} ,
		{"word":"souillés","word_nosc":"souilles","lemma":"souiller","pos":"VER"} ,
		{"word":"soulage","word_nosc":"soulage","lemma":"soulager","pos":"VER"} ,
		{"word":"soulagea","word_nosc":"soulagea","lemma":"soulager","pos":"VER"} ,
		{"word":"soulageai","word_nosc":"soulageai","lemma":"soulager","pos":"VER"} ,
		{"word":"soulageaient","word_nosc":"soulageaient","lemma":"soulager","pos":"VER"} ,
		{"word":"soulageait","word_nosc":"soulageait","lemma":"soulager","pos":"VER"} ,
		{"word":"soulageant","word_nosc":"soulageant","lemma":"soulager","pos":"VER"} ,
		{"word":"soulagent","word_nosc":"soulagent","lemma":"soulager","pos":"VER"} ,
		{"word":"soulageons","word_nosc":"soulageons","lemma":"soulager","pos":"VER"} ,
		{"word":"soulager","word_nosc":"soulager","lemma":"soulager","pos":"VER"} ,
		{"word":"soulagera","word_nosc":"soulagera","lemma":"soulager","pos":"VER"} ,
		{"word":"soulagerai","word_nosc":"soulagerai","lemma":"soulager","pos":"VER"} ,
		{"word":"soulageraient","word_nosc":"soulageraient","lemma":"soulager","pos":"VER"} ,
		{"word":"soulagerait","word_nosc":"soulagerait","lemma":"soulager","pos":"VER"} ,
		{"word":"soulagerez","word_nosc":"soulagerez","lemma":"soulager","pos":"VER"} ,
		{"word":"soulages","word_nosc":"soulages","lemma":"soulager","pos":"VER"} ,
		{"word":"soulagez","word_nosc":"soulagez","lemma":"soulager","pos":"VER"} ,
		{"word":"soulageâmes","word_nosc":"soulageames","lemma":"soulager","pos":"VER"} ,
		{"word":"soulagèrent","word_nosc":"soulagerent","lemma":"soulager","pos":"VER"} ,
		{"word":"soulagé","word_nosc":"soulage","lemma":"soulager","pos":"VER"} ,
		{"word":"soulagée","word_nosc":"soulagee","lemma":"soulager","pos":"VER"} ,
		{"word":"soulagées","word_nosc":"soulagees","lemma":"soulager","pos":"VER"} ,
		{"word":"soulagés","word_nosc":"soulages","lemma":"soulager","pos":"VER"} ,
		{"word":"souleva","word_nosc":"souleva","lemma":"soulever","pos":"VER"} ,
		{"word":"soulevai","word_nosc":"soulevai","lemma":"soulever","pos":"VER"} ,
		{"word":"soulevaient","word_nosc":"soulevaient","lemma":"soulever","pos":"VER"} ,
		{"word":"soulevais","word_nosc":"soulevais","lemma":"soulever","pos":"VER"} ,
		{"word":"soulevait","word_nosc":"soulevait","lemma":"soulever","pos":"VER"} ,
		{"word":"soulevant","word_nosc":"soulevant","lemma":"soulever","pos":"VER"} ,
		{"word":"soulever","word_nosc":"soulever","lemma":"soulever","pos":"VER"} ,
		{"word":"soulevez","word_nosc":"soulevez","lemma":"soulever","pos":"VER"} ,
		{"word":"soulevions","word_nosc":"soulevions","lemma":"soulever","pos":"VER"} ,
		{"word":"soulevons","word_nosc":"soulevons","lemma":"soulever","pos":"VER"} ,
		{"word":"soulevât","word_nosc":"soulevat","lemma":"soulever","pos":"VER"} ,
		{"word":"soulevèrent","word_nosc":"souleverent","lemma":"soulever","pos":"VER"} ,
		{"word":"soulevé","word_nosc":"souleve","lemma":"soulever","pos":"VER"} ,
		{"word":"soulevée","word_nosc":"soulevee","lemma":"soulever","pos":"VER"} ,
		{"word":"soulevées","word_nosc":"soulevees","lemma":"soulever","pos":"VER"} ,
		{"word":"soulevés","word_nosc":"souleves","lemma":"soulever","pos":"VER"} ,
		{"word":"souligna","word_nosc":"souligna","lemma":"souligner","pos":"VER"} ,
		{"word":"soulignai","word_nosc":"soulignai","lemma":"souligner","pos":"VER"} ,
		{"word":"soulignaient","word_nosc":"soulignaient","lemma":"souligner","pos":"VER"} ,
		{"word":"soulignais","word_nosc":"soulignais","lemma":"souligner","pos":"VER"} ,
		{"word":"soulignait","word_nosc":"soulignait","lemma":"souligner","pos":"VER"} ,
		{"word":"soulignant","word_nosc":"soulignant","lemma":"souligner","pos":"VER"} ,
		{"word":"souligne","word_nosc":"souligne","lemma":"souligner","pos":"VER"} ,
		{"word":"soulignent","word_nosc":"soulignent","lemma":"souligner","pos":"VER"} ,
		{"word":"souligner","word_nosc":"souligner","lemma":"souligner","pos":"VER"} ,
		{"word":"soulignera","word_nosc":"soulignera","lemma":"souligner","pos":"VER"} ,
		{"word":"souligneraient","word_nosc":"souligneraient","lemma":"souligner","pos":"VER"} ,
		{"word":"soulignerait","word_nosc":"soulignerait","lemma":"souligner","pos":"VER"} ,
		{"word":"soulignez","word_nosc":"soulignez","lemma":"souligner","pos":"VER"} ,
		{"word":"souligniez","word_nosc":"souligniez","lemma":"souligner","pos":"VER"} ,
		{"word":"soulignions","word_nosc":"soulignions","lemma":"souligner","pos":"VER"} ,
		{"word":"souligné","word_nosc":"souligne","lemma":"souligner","pos":"VER"} ,
		{"word":"soulignée","word_nosc":"soulignee","lemma":"souligner","pos":"VER"} ,
		{"word":"soulignées","word_nosc":"soulignees","lemma":"souligner","pos":"VER"} ,
		{"word":"soulignés","word_nosc":"soulignes","lemma":"souligner","pos":"VER"} ,
		{"word":"soulève","word_nosc":"souleve","lemma":"soulever","pos":"VER"} ,
		{"word":"soulèvent","word_nosc":"soulevent","lemma":"soulever","pos":"VER"} ,
		{"word":"soulèvera","word_nosc":"soulevera","lemma":"soulever","pos":"VER"} ,
		{"word":"soulèverai","word_nosc":"souleverai","lemma":"soulever","pos":"VER"} ,
		{"word":"soulèveraient","word_nosc":"souleveraient","lemma":"soulever","pos":"VER"} ,
		{"word":"soulèverais","word_nosc":"souleverais","lemma":"soulever","pos":"VER"} ,
		{"word":"soulèverait","word_nosc":"souleverait","lemma":"soulever","pos":"VER"} ,
		{"word":"soulèverez","word_nosc":"souleverez","lemma":"soulever","pos":"VER"} ,
		{"word":"soulèveriez","word_nosc":"souleveriez","lemma":"soulever","pos":"VER"} ,
		{"word":"soulèverons","word_nosc":"souleverons","lemma":"soulever","pos":"VER"} ,
		{"word":"soulèveront","word_nosc":"souleveront","lemma":"soulever","pos":"VER"} ,
		{"word":"soulèves","word_nosc":"souleves","lemma":"soulever","pos":"VER"} ,
		{"word":"soumet","word_nosc":"soumet","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumets","word_nosc":"soumets","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettaient","word_nosc":"soumettaient","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettais","word_nosc":"soumettais","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettait","word_nosc":"soumettait","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettant","word_nosc":"soumettant","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumette","word_nosc":"soumette","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettent","word_nosc":"soumettent","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettez","word_nosc":"soumettez","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettions","word_nosc":"soumettions","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettons","word_nosc":"soumettons","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettra","word_nosc":"soumettra","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettrai","word_nosc":"soumettrai","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettraient","word_nosc":"soumettraient","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettrais","word_nosc":"soumettrais","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettrait","word_nosc":"soumettrait","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettras","word_nosc":"soumettras","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettre","word_nosc":"soumettre","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettrez","word_nosc":"soumettrez","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumettront","word_nosc":"soumettront","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumirent","word_nosc":"soumirent","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumis","word_nosc":"soumis","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumise","word_nosc":"soumise","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumises","word_nosc":"soumises","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumissionner","word_nosc":"soumissionner","lemma":"soumissionner","pos":"VER"} ,
		{"word":"soumit","word_nosc":"soumit","lemma":"soumettre","pos":"VER"} ,
		{"word":"soumît","word_nosc":"soumit","lemma":"soumettre","pos":"VER"} ,
		{"word":"soupa","word_nosc":"soupa","lemma":"souper","pos":"VER"} ,
		{"word":"soupaient","word_nosc":"soupaient","lemma":"souper","pos":"VER"} ,
		{"word":"soupais","word_nosc":"soupais","lemma":"souper","pos":"VER"} ,
		{"word":"soupait","word_nosc":"soupait","lemma":"souper","pos":"VER"} ,
		{"word":"soupant","word_nosc":"soupant","lemma":"souper","pos":"VER"} ,
		{"word":"soupasse","word_nosc":"soupasse","lemma":"souper","pos":"VER"} ,
		{"word":"soupe","word_nosc":"soupe","lemma":"souper","pos":"VER"} ,
		{"word":"soupent","word_nosc":"soupent","lemma":"souper","pos":"VER"} ,
		{"word":"souper","word_nosc":"souper","lemma":"souper","pos":"VER"} ,
		{"word":"soupera","word_nosc":"soupera","lemma":"souper","pos":"VER"} ,
		{"word":"souperaient","word_nosc":"souperaient","lemma":"souper","pos":"VER"} ,
		{"word":"souperait","word_nosc":"souperait","lemma":"souper","pos":"VER"} ,
		{"word":"souperons","word_nosc":"souperons","lemma":"souper","pos":"VER"} ,
		{"word":"soupes","word_nosc":"soupes","lemma":"souper","pos":"VER"} ,
		{"word":"soupesa","word_nosc":"soupesa","lemma":"soupeser","pos":"VER"} ,
		{"word":"soupesait","word_nosc":"soupesait","lemma":"soupeser","pos":"VER"} ,
		{"word":"soupesant","word_nosc":"soupesant","lemma":"soupeser","pos":"VER"} ,
		{"word":"soupeser","word_nosc":"soupeser","lemma":"soupeser","pos":"VER"} ,
		{"word":"soupesez","word_nosc":"soupesez","lemma":"soupeser","pos":"VER"} ,
		{"word":"soupesé","word_nosc":"soupese","lemma":"soupeser","pos":"VER"} ,
		{"word":"soupesée","word_nosc":"soupesee","lemma":"soupeser","pos":"VER"} ,
		{"word":"soupesés","word_nosc":"soupeses","lemma":"soupeser","pos":"VER"} ,
		{"word":"soupez","word_nosc":"soupez","lemma":"souper","pos":"VER"} ,
		{"word":"soupira","word_nosc":"soupira","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupirai","word_nosc":"soupirai","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupiraient","word_nosc":"soupiraient","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupirais","word_nosc":"soupirais","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupirait","word_nosc":"soupirait","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupirant","word_nosc":"soupirant","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupire","word_nosc":"soupire","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupirent","word_nosc":"soupirent","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupirer","word_nosc":"soupirer","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupirerait","word_nosc":"soupirerait","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupires","word_nosc":"soupires","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupirez","word_nosc":"soupirez","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupirions","word_nosc":"soupirions","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupirons","word_nosc":"soupirons","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupirât","word_nosc":"soupirat","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupirèrent","word_nosc":"soupirerent","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupiré","word_nosc":"soupire","lemma":"soupirer","pos":"VER"} ,
		{"word":"soupons","word_nosc":"soupons","lemma":"souper","pos":"VER"} ,
		{"word":"soupâmes","word_nosc":"soupames","lemma":"souper","pos":"VER"} ,
		{"word":"soupçonna","word_nosc":"soupconna","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnai","word_nosc":"soupconnai","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnaient","word_nosc":"soupconnaient","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnais","word_nosc":"soupconnais","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnait","word_nosc":"soupconnait","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnant","word_nosc":"soupconnant","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonne","word_nosc":"soupconne","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnent","word_nosc":"soupconnent","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonner","word_nosc":"soupconner","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnera","word_nosc":"soupconnera","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnerais","word_nosc":"soupconnerais","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnerait","word_nosc":"soupconnerait","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonneriez","word_nosc":"soupconneriez","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonneront","word_nosc":"soupconneront","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnes","word_nosc":"soupconnes","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnez","word_nosc":"soupconnez","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonniez","word_nosc":"soupconniez","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnions","word_nosc":"soupconnions","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnons","word_nosc":"soupconnons","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnât","word_nosc":"soupconnat","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnèrent","word_nosc":"soupconnerent","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonné","word_nosc":"soupconne","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnée","word_nosc":"soupconnee","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnées","word_nosc":"soupconnees","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupçonnés","word_nosc":"soupconnes","lemma":"soupçonner","pos":"VER"} ,
		{"word":"soupèrent","word_nosc":"souperent","lemma":"souper","pos":"VER"} ,
		{"word":"soupèse","word_nosc":"soupese","lemma":"soupeser","pos":"VER"} ,
		{"word":"soupèserait","word_nosc":"soupeserait","lemma":"soupeser","pos":"VER"} ,
		{"word":"soupé","word_nosc":"soupe","lemma":"souper","pos":"VER"} ,
		{"word":"souquaient","word_nosc":"souquaient","lemma":"souquer","pos":"VER"} ,
		{"word":"souque","word_nosc":"souque","lemma":"souquer","pos":"VER"} ,
		{"word":"souquer","word_nosc":"souquer","lemma":"souquer","pos":"VER"} ,
		{"word":"souquez","word_nosc":"souquez","lemma":"souquer","pos":"VER"} ,
		{"word":"souqué","word_nosc":"souque","lemma":"souquer","pos":"VER"} ,
		{"word":"sourcilla","word_nosc":"sourcilla","lemma":"sourciller","pos":"VER"} ,
		{"word":"sourcillai","word_nosc":"sourcillai","lemma":"sourciller","pos":"VER"} ,
		{"word":"sourcille","word_nosc":"sourcille","lemma":"sourciller","pos":"VER"} ,
		{"word":"sourciller","word_nosc":"sourciller","lemma":"sourciller","pos":"VER"} ,
		{"word":"sourcillé","word_nosc":"sourcille","lemma":"sourciller","pos":"VER"} ,
		{"word":"sourd","word_nosc":"sourd","lemma":"sourdre","pos":"VER"} ,
		{"word":"sourdaient","word_nosc":"sourdaient","lemma":"sourdaient","pos":"VER"} ,
		{"word":"sourdait","word_nosc":"sourdait","lemma":"sourdait","pos":"VER"} ,
		{"word":"sourdant","word_nosc":"sourdant","lemma":"sourdre","pos":"VER"} ,
		{"word":"sourde","word_nosc":"sourde","lemma":"sourdre","pos":"VER"} ,
		{"word":"sourdre","word_nosc":"sourdre","lemma":"sourdre","pos":"VER"} ,
		{"word":"sourdront","word_nosc":"sourdront","lemma":"sourdre","pos":"VER"} ,
		{"word":"sourds","word_nosc":"sourds","lemma":"sourdre","pos":"VER"} ,
		{"word":"souri","word_nosc":"souri","lemma":"sourire","pos":"VER"} ,
		{"word":"souriaient","word_nosc":"souriaient","lemma":"sourire","pos":"VER"} ,
		{"word":"souriais","word_nosc":"souriais","lemma":"sourire","pos":"VER"} ,
		{"word":"souriait","word_nosc":"souriait","lemma":"sourire","pos":"VER"} ,
		{"word":"souriant","word_nosc":"souriant","lemma":"sourire","pos":"VER"} ,
		{"word":"sourie","word_nosc":"sourie","lemma":"sourire","pos":"VER"} ,
		{"word":"sourient","word_nosc":"sourient","lemma":"sourire","pos":"VER"} ,
		{"word":"souries","word_nosc":"souries","lemma":"sourire","pos":"VER"} ,
		{"word":"souriez","word_nosc":"souriez","lemma":"sourire","pos":"VER"} ,
		{"word":"souriions","word_nosc":"souriions","lemma":"sourire","pos":"VER"} ,
		{"word":"sourions","word_nosc":"sourions","lemma":"sourire","pos":"VER"} ,
		{"word":"sourira","word_nosc":"sourira","lemma":"sourire","pos":"VER"} ,
		{"word":"sourirai","word_nosc":"sourirai","lemma":"sourire","pos":"VER"} ,
		{"word":"souriraient","word_nosc":"souriraient","lemma":"sourire","pos":"VER"} ,
		{"word":"sourirais","word_nosc":"sourirais","lemma":"sourire","pos":"VER"} ,
		{"word":"sourirait","word_nosc":"sourirait","lemma":"sourire","pos":"VER"} ,
		{"word":"souriras","word_nosc":"souriras","lemma":"sourire","pos":"VER"} ,
		{"word":"sourire","word_nosc":"sourire","lemma":"sourire","pos":"VER"} ,
		{"word":"sourirent","word_nosc":"sourirent","lemma":"sourire","pos":"VER"} ,
		{"word":"sourirons","word_nosc":"sourirons","lemma":"sourire","pos":"VER"} ,
		{"word":"souriront","word_nosc":"souriront","lemma":"sourire","pos":"VER"} ,
		{"word":"souris","word_nosc":"souris","lemma":"sourire","pos":"VER"} ,
		{"word":"sourit","word_nosc":"sourit","lemma":"sourire","pos":"VER"} ,
		{"word":"sourîmes","word_nosc":"sourimes","lemma":"sourire","pos":"VER"} ,
		{"word":"sourît","word_nosc":"sourit","lemma":"sourire","pos":"VER"} ,
		{"word":"sous-alimenté","word_nosc":"sous-alimente","lemma":"sous-alimenter","pos":"VER"} ,
		{"word":"sous-alimentée","word_nosc":"sous-alimentee","lemma":"sous-alimenter","pos":"VER"} ,
		{"word":"sous-entend","word_nosc":"sous-entend","lemma":"sous-entendre","pos":"VER"} ,
		{"word":"sous-entendais","word_nosc":"sous-entendais","lemma":"sous-entendre","pos":"VER"} ,
		{"word":"sous-entendait","word_nosc":"sous-entendait","lemma":"sous-entendre","pos":"VER"} ,
		{"word":"sous-entendant","word_nosc":"sous-entendant","lemma":"sous-entendre","pos":"VER"} ,
		{"word":"sous-entendent","word_nosc":"sous-entendent","lemma":"sous-entendre","pos":"VER"} ,
		{"word":"sous-entendez","word_nosc":"sous-entendez","lemma":"sous-entendre","pos":"VER"} ,
		{"word":"sous-entendiez","word_nosc":"sous-entendiez","lemma":"sous-entendre","pos":"VER"} ,
		{"word":"sous-entendrait","word_nosc":"sous-entendrait","lemma":"sous-entendre","pos":"VER"} ,
		{"word":"sous-entendre","word_nosc":"sous-entendre","lemma":"sous-entendre","pos":"VER"} ,
		{"word":"sous-entends","word_nosc":"sous-entends","lemma":"sous-entendre","pos":"VER"} ,
		{"word":"sous-entendu","word_nosc":"sous-entendu","lemma":"sous-entendre","pos":"VER"} ,
		{"word":"sous-entendue","word_nosc":"sous-entendue","lemma":"sous-entendre","pos":"VER"} ,
		{"word":"sous-entendus","word_nosc":"sous-entendus","lemma":"sous-entendre","pos":"VER"} ,
		{"word":"sous-estimais","word_nosc":"sous-estimais","lemma":"sous-estimer","pos":"VER"} ,
		{"word":"sous-estimait","word_nosc":"sous-estimait","lemma":"sous-estimer","pos":"VER"} ,
		{"word":"sous-estime","word_nosc":"sous-estime","lemma":"sous-estimer","pos":"VER"} ,
		{"word":"sous-estiment","word_nosc":"sous-estiment","lemma":"sous-estimer","pos":"VER"} ,
		{"word":"sous-estimer","word_nosc":"sous-estimer","lemma":"sous-estimer","pos":"VER"} ,
		{"word":"sous-estimerai","word_nosc":"sous-estimerai","lemma":"sous-estimer","pos":"VER"} ,
		{"word":"sous-estimes","word_nosc":"sous-estimes","lemma":"sous-estimer","pos":"VER"} ,
		{"word":"sous-estimez","word_nosc":"sous-estimez","lemma":"sous-estimer","pos":"VER"} ,
		{"word":"sous-estimons","word_nosc":"sous-estimons","lemma":"sous-estimer","pos":"VER"} ,
		{"word":"sous-estimé","word_nosc":"sous-estime","lemma":"sous-estimer","pos":"VER"} ,
		{"word":"sous-estimée","word_nosc":"sous-estimee","lemma":"sous-estimer","pos":"VER"} ,
		{"word":"sous-estimées","word_nosc":"sous-estimees","lemma":"sous-estimer","pos":"VER"} ,
		{"word":"sous-estimés","word_nosc":"sous-estimes","lemma":"sous-estimer","pos":"VER"} ,
		{"word":"sous-exposé","word_nosc":"sous-expose","lemma":"sous-exposer","pos":"VER"} ,
		{"word":"sous-exposées","word_nosc":"sous-exposees","lemma":"sous-exposer","pos":"VER"} ,
		{"word":"sous-loua","word_nosc":"sous-loua","lemma":"sous-louer","pos":"VER"} ,
		{"word":"sous-louait","word_nosc":"sous-louait","lemma":"sous-louer","pos":"VER"} ,
		{"word":"sous-loue","word_nosc":"sous-loue","lemma":"sous-louer","pos":"VER"} ,
		{"word":"sous-louer","word_nosc":"sous-louer","lemma":"sous-louer","pos":"VER"} ,
		{"word":"sous-louerai","word_nosc":"sous-louerai","lemma":"sous-louer","pos":"VER"} ,
		{"word":"sous-louez","word_nosc":"sous-louez","lemma":"sous-louer","pos":"VER"} ,
		{"word":"sous-louèrent","word_nosc":"sous-louerent","lemma":"sous-louer","pos":"VER"} ,
		{"word":"sous-loué","word_nosc":"sous-loue","lemma":"sous-louer","pos":"VER"} ,
		{"word":"sous-payer","word_nosc":"sous-payer","lemma":"sous-payer","pos":"VER"} ,
		{"word":"sous-payé","word_nosc":"sous-paye","lemma":"sous-payer","pos":"VER"} ,
		{"word":"sous-payés","word_nosc":"sous-payes","lemma":"sous-payer","pos":"VER"} ,
		{"word":"sous-tend","word_nosc":"sous-tend","lemma":"sous-tendre","pos":"VER"} ,
		{"word":"sous-tendaient","word_nosc":"sous-tendaient","lemma":"sous-tendre","pos":"VER"} ,
		{"word":"sous-tendait","word_nosc":"sous-tendait","lemma":"sous-tendre","pos":"VER"} ,
		{"word":"sous-tendent","word_nosc":"sous-tendent","lemma":"sous-tendre","pos":"VER"} ,
		{"word":"sous-tendu","word_nosc":"sous-tendu","lemma":"sous-tendre","pos":"VER"} ,
		{"word":"sous-tendue","word_nosc":"sous-tendue","lemma":"sous-tendre","pos":"VER"} ,
		{"word":"sous-titrer","word_nosc":"sous-titrer","lemma":"sous-titrer","pos":"VER"} ,
		{"word":"sous-titré","word_nosc":"sous-titre","lemma":"sous-titrer","pos":"VER"} ,
		{"word":"sous-titrée","word_nosc":"sous-titree","lemma":"sous-titrer","pos":"VER"} ,
		{"word":"sous-titrés","word_nosc":"sous-titres","lemma":"sous-titrer","pos":"VER"} ,
		{"word":"sous-traitant","word_nosc":"sous-traitant","lemma":"sous-traiter","pos":"VER"} ,
		{"word":"sous-traite","word_nosc":"sous-traite","lemma":"sous-traiter","pos":"VER"} ,
		{"word":"sous-traitent","word_nosc":"sous-traitent","lemma":"sous-traiter","pos":"VER"} ,
		{"word":"sous-traiter","word_nosc":"sous-traiter","lemma":"sous-traiter","pos":"VER"} ,
		{"word":"sous-traités","word_nosc":"sous-traites","lemma":"sous-traiter","pos":"VER"} ,
		{"word":"sous-évaluait","word_nosc":"sous-evaluait","lemma":"sous-évaluer","pos":"VER"} ,
		{"word":"sous-évalué","word_nosc":"sous-evalue","lemma":"sous-évaluer","pos":"VER"} ,
		{"word":"sous-évaluées","word_nosc":"sous-evaluees","lemma":"sous-évaluer","pos":"VER"} ,
		{"word":"souscrira","word_nosc":"souscrira","lemma":"souscrire","pos":"VER"} ,
		{"word":"souscrirai","word_nosc":"souscrirai","lemma":"souscrire","pos":"VER"} ,
		{"word":"souscrirait","word_nosc":"souscrirait","lemma":"souscrire","pos":"VER"} ,
		{"word":"souscrire","word_nosc":"souscrire","lemma":"souscrire","pos":"VER"} ,
		{"word":"souscris","word_nosc":"souscris","lemma":"souscrire","pos":"VER"} ,
		{"word":"souscrit","word_nosc":"souscrit","lemma":"souscrire","pos":"VER"} ,
		{"word":"souscrite","word_nosc":"souscrite","lemma":"souscrire","pos":"VER"} ,
		{"word":"souscrites","word_nosc":"souscrites","lemma":"souscrire","pos":"VER"} ,
		{"word":"souscrits","word_nosc":"souscrits","lemma":"souscrire","pos":"VER"} ,
		{"word":"souscrivais","word_nosc":"souscrivais","lemma":"souscrire","pos":"VER"} ,
		{"word":"souscrivait","word_nosc":"souscrivait","lemma":"souscrire","pos":"VER"} ,
		{"word":"souscrivez","word_nosc":"souscrivez","lemma":"souscrire","pos":"VER"} ,
		{"word":"souscrivons","word_nosc":"souscrivons","lemma":"souscrire","pos":"VER"} ,
		{"word":"soussigner","word_nosc":"soussigner","lemma":"sous-signer","pos":"VER"} ,
		{"word":"soustrairait","word_nosc":"soustrairait","lemma":"soustraire","pos":"VER"} ,
		{"word":"soustraire","word_nosc":"soustraire","lemma":"soustraire","pos":"VER"} ,
		{"word":"soustrais","word_nosc":"soustrais","lemma":"soustraire","pos":"VER"} ,
		{"word":"soustrait","word_nosc":"soustrait","lemma":"soustraire","pos":"VER"} ,
		{"word":"soustraite","word_nosc":"soustraite","lemma":"soustraire","pos":"VER"} ,
		{"word":"soustraites","word_nosc":"soustraites","lemma":"soustraire","pos":"VER"} ,
		{"word":"soustraits","word_nosc":"soustraits","lemma":"soustraire","pos":"VER"} ,
		{"word":"soustrayaient","word_nosc":"soustrayaient","lemma":"soustraire","pos":"VER"} ,
		{"word":"soustrayait","word_nosc":"soustrayait","lemma":"soustraire","pos":"VER"} ,
		{"word":"soustrayant","word_nosc":"soustrayant","lemma":"soustraire","pos":"VER"} ,
		{"word":"soustrayez","word_nosc":"soustrayez","lemma":"soustraire","pos":"VER"} ,
		{"word":"soutachait","word_nosc":"soutachait","lemma":"soutacher","pos":"VER"} ,
		{"word":"soutaché","word_nosc":"soutache","lemma":"soutacher","pos":"VER"} ,
		{"word":"soutachée","word_nosc":"soutachee","lemma":"soutacher","pos":"VER"} ,
		{"word":"soutachées","word_nosc":"soutachees","lemma":"soutacher","pos":"VER"} ,
		{"word":"soutachés","word_nosc":"soutaches","lemma":"soutacher","pos":"VER"} ,
		{"word":"soutenaient","word_nosc":"soutenaient","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutenais","word_nosc":"soutenais","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutenait","word_nosc":"soutenait","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutenant","word_nosc":"soutenant","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutenez","word_nosc":"soutenez","lemma":"soutenir","pos":"VER"} ,
		{"word":"souteniez","word_nosc":"souteniez","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutenions","word_nosc":"soutenions","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutenir","word_nosc":"soutenir","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutenons","word_nosc":"soutenons","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutenu","word_nosc":"soutenu","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutenue","word_nosc":"soutenue","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutenues","word_nosc":"soutenues","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutenus","word_nosc":"soutenus","lemma":"soutenir","pos":"VER"} ,
		{"word":"souter","word_nosc":"souter","lemma":"souter","pos":"VER"} ,
		{"word":"soutiendra","word_nosc":"soutiendra","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutiendrai","word_nosc":"soutiendrai","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutiendraient","word_nosc":"soutiendraient","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutiendrais","word_nosc":"soutiendrais","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutiendrait","word_nosc":"soutiendrait","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutiendras","word_nosc":"soutiendras","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutiendrez","word_nosc":"soutiendrez","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutiendriez","word_nosc":"soutiendriez","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutiendrons","word_nosc":"soutiendrons","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutiendront","word_nosc":"soutiendront","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutienne","word_nosc":"soutienne","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutiennent","word_nosc":"soutiennent","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutiennes","word_nosc":"soutiennes","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutiens","word_nosc":"soutiens","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutient","word_nosc":"soutient","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutinrent","word_nosc":"soutinrent","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutins","word_nosc":"soutins","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutint","word_nosc":"soutint","lemma":"soutenir","pos":"VER"} ,
		{"word":"soutiraient","word_nosc":"soutiraient","lemma":"soutirer","pos":"VER"} ,
		{"word":"soutire","word_nosc":"soutire","lemma":"soutirer","pos":"VER"} ,
		{"word":"soutirent","word_nosc":"soutirent","lemma":"soutirer","pos":"VER"} ,
		{"word":"soutirer","word_nosc":"soutirer","lemma":"soutirer","pos":"VER"} ,
		{"word":"soutirerai","word_nosc":"soutirerai","lemma":"soutirer","pos":"VER"} ,
		{"word":"soutirez","word_nosc":"soutirez","lemma":"soutirer","pos":"VER"} ,
		{"word":"soutiré","word_nosc":"soutire","lemma":"soutirer","pos":"VER"} ,
		{"word":"soutirée","word_nosc":"soutiree","lemma":"soutirer","pos":"VER"} ,
		{"word":"soutirées","word_nosc":"soutirees","lemma":"soutirer","pos":"VER"} ,
		{"word":"soutînt","word_nosc":"soutint","lemma":"soutenir","pos":"VER"} ,
		{"word":"souvenaient","word_nosc":"souvenaient","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvenais","word_nosc":"souvenais","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvenait","word_nosc":"souvenait","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvenant","word_nosc":"souvenant","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvenez","word_nosc":"souvenez","lemma":"souvenir","pos":"VER"} ,
		{"word":"souveniez","word_nosc":"souveniez","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvenions","word_nosc":"souvenions","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvenir","word_nosc":"souvenir","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvenons","word_nosc":"souvenons","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvenu","word_nosc":"souvenu","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvenue","word_nosc":"souvenue","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvenus","word_nosc":"souvenus","lemma":"souvenir","pos":"VER"} ,
		{"word":"souviendra","word_nosc":"souviendra","lemma":"souvenir","pos":"VER"} ,
		{"word":"souviendrai","word_nosc":"souviendrai","lemma":"souvenir","pos":"VER"} ,
		{"word":"souviendraient","word_nosc":"souviendraient","lemma":"souvenir","pos":"VER"} ,
		{"word":"souviendrais","word_nosc":"souviendrais","lemma":"souvenir","pos":"VER"} ,
		{"word":"souviendrait","word_nosc":"souviendrait","lemma":"souvenir","pos":"VER"} ,
		{"word":"souviendras","word_nosc":"souviendras","lemma":"souvenir","pos":"VER"} ,
		{"word":"souviendrez","word_nosc":"souviendrez","lemma":"souvenir","pos":"VER"} ,
		{"word":"souviendriez","word_nosc":"souviendriez","lemma":"souvenir","pos":"VER"} ,
		{"word":"souviendrons","word_nosc":"souviendrons","lemma":"souvenir","pos":"VER"} ,
		{"word":"souviendront","word_nosc":"souviendront","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvienne","word_nosc":"souvienne","lemma":"souvenir","pos":"VER"} ,
		{"word":"souviennent","word_nosc":"souviennent","lemma":"souvenir","pos":"VER"} ,
		{"word":"souviennes","word_nosc":"souviennes","lemma":"souvenir","pos":"VER"} ,
		{"word":"souviens","word_nosc":"souviens","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvient","word_nosc":"souvient","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvinrent","word_nosc":"souvinrent","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvins","word_nosc":"souvins","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvinssent","word_nosc":"souvinssent","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvint","word_nosc":"souvint","lemma":"souvenir","pos":"VER"} ,
		{"word":"souvînt","word_nosc":"souvint","lemma":"souvenir","pos":"VER"} ,
		{"word":"soyez","word_nosc":"soyez","lemma":"être","pos":"VER"} ,
		{"word":"soyons","word_nosc":"soyons","lemma":"être","pos":"VER"} ,
		{"word":"soûlait","word_nosc":"soulait","lemma":"soûler","pos":"VER"} ,
		{"word":"soûlant","word_nosc":"soulant","lemma":"soûler","pos":"VER"} ,
		{"word":"soûle","word_nosc":"soule","lemma":"soûler","pos":"VER"} ,
		{"word":"soûlent","word_nosc":"soulent","lemma":"soûler","pos":"VER"} ,
		{"word":"soûler","word_nosc":"souler","lemma":"soûler","pos":"VER"} ,
		{"word":"soûlerais","word_nosc":"soulerais","lemma":"soûler","pos":"VER"} ,
		{"word":"soûlerait","word_nosc":"soulerait","lemma":"soûler","pos":"VER"} ,
		{"word":"soûlez","word_nosc":"soulez","lemma":"soûler","pos":"VER"} ,
		{"word":"soûlons","word_nosc":"soulons","lemma":"soûler","pos":"VER"} ,
		{"word":"soûlèrent","word_nosc":"soulerent","lemma":"soûler","pos":"VER"} ,
		{"word":"soûlé","word_nosc":"soule","lemma":"soûler","pos":"VER"} ,
		{"word":"soûlée","word_nosc":"soulee","lemma":"soûler","pos":"VER"} ,
		{"word":"soûlées","word_nosc":"soulees","lemma":"soûler","pos":"VER"} ,
		{"word":"soûlés","word_nosc":"soules","lemma":"soûler","pos":"VER"} ,
		{"word":"spatuler","word_nosc":"spatuler","lemma":"spatuler","pos":"VER"} ,
		{"word":"speede","word_nosc":"speede","lemma":"speeder","pos":"VER"} ,
		{"word":"speeder","word_nosc":"speeder","lemma":"speeder","pos":"VER"} ,
		{"word":"speedes","word_nosc":"speedes","lemma":"speeder","pos":"VER"} ,
		{"word":"speedé","word_nosc":"speede","lemma":"speeder","pos":"VER"} ,
		{"word":"speedée","word_nosc":"speedee","lemma":"speeder","pos":"VER"} ,
		{"word":"speedés","word_nosc":"speedes","lemma":"speeder","pos":"VER"} ,
		{"word":"spiraler","word_nosc":"spiraler","lemma":"spiraler","pos":"VER"} ,
		{"word":"spiritualisaient","word_nosc":"spiritualisaient","lemma":"spiritualiser","pos":"VER"} ,
		{"word":"spiritualise","word_nosc":"spiritualise","lemma":"spiritualiser","pos":"VER"} ,
		{"word":"spiritualiser","word_nosc":"spiritualiser","lemma":"spiritualiser","pos":"VER"} ,
		{"word":"spiritualisé","word_nosc":"spiritualise","lemma":"spiritualiser","pos":"VER"} ,
		{"word":"splittaient","word_nosc":"splittaient","lemma":"splitter","pos":"VER"} ,
		{"word":"splitter","word_nosc":"splitter","lemma":"splitter","pos":"VER"} ,
		{"word":"spoliait","word_nosc":"spoliait","lemma":"spolier","pos":"VER"} ,
		{"word":"spolie","word_nosc":"spolie","lemma":"spolier","pos":"VER"} ,
		{"word":"spolier","word_nosc":"spolier","lemma":"spolier","pos":"VER"} ,
		{"word":"spolié","word_nosc":"spolie","lemma":"spolier","pos":"VER"} ,
		{"word":"spoliée","word_nosc":"spoliee","lemma":"spolier","pos":"VER"} ,
		{"word":"spoliés","word_nosc":"spolies","lemma":"spolier","pos":"VER"} ,
		{"word":"sponsorisait","word_nosc":"sponsorisait","lemma":"sponsoriser","pos":"VER"} ,
		{"word":"sponsorise","word_nosc":"sponsorise","lemma":"sponsoriser","pos":"VER"} ,
		{"word":"sponsoriser","word_nosc":"sponsoriser","lemma":"sponsoriser","pos":"VER"} ,
		{"word":"sponsorisera","word_nosc":"sponsorisera","lemma":"sponsoriser","pos":"VER"} ,
		{"word":"sponsorisé","word_nosc":"sponsorise","lemma":"sponsoriser","pos":"VER"} ,
		{"word":"sponsorisée","word_nosc":"sponsorisee","lemma":"sponsoriser","pos":"VER"} ,
		{"word":"sponsorisés","word_nosc":"sponsorises","lemma":"sponsoriser","pos":"VER"} ,
		{"word":"sprinta","word_nosc":"sprinta","lemma":"sprinter","pos":"VER"} ,
		{"word":"sprintait","word_nosc":"sprintait","lemma":"sprinter","pos":"VER"} ,
		{"word":"sprintant","word_nosc":"sprintant","lemma":"sprinter","pos":"VER"} ,
		{"word":"sprinte","word_nosc":"sprinte","lemma":"sprinter","pos":"VER"} ,
		{"word":"sprinter","word_nosc":"sprinter","lemma":"sprinter","pos":"VER"} ,
		{"word":"spécialisa","word_nosc":"specialisa","lemma":"spécialiser","pos":"VER"} ,
		{"word":"spécialisaient","word_nosc":"specialisaient","lemma":"spécialiser","pos":"VER"} ,
		{"word":"spécialisait","word_nosc":"specialisait","lemma":"spécialiser","pos":"VER"} ,
		{"word":"spécialisant","word_nosc":"specialisant","lemma":"spécialiser","pos":"VER"} ,
		{"word":"spécialise","word_nosc":"specialise","lemma":"spécialiser","pos":"VER"} ,
		{"word":"spécialiser","word_nosc":"specialiser","lemma":"spécialiser","pos":"VER"} ,
		{"word":"spécialisé","word_nosc":"specialise","lemma":"spécialiser","pos":"VER"} ,
		{"word":"spécialisée","word_nosc":"specialisee","lemma":"spécialiser","pos":"VER"} ,
		{"word":"spécialisées","word_nosc":"specialisees","lemma":"spécialiser","pos":"VER"} ,
		{"word":"spécialisés","word_nosc":"specialises","lemma":"spécialiser","pos":"VER"} ,
		{"word":"spécifiais","word_nosc":"specifiais","lemma":"spécifier","pos":"VER"} ,
		{"word":"spécifiait","word_nosc":"specifiait","lemma":"spécifier","pos":"VER"} ,
		{"word":"spécifiant","word_nosc":"specifiant","lemma":"spécifier","pos":"VER"} ,
		{"word":"spécifie","word_nosc":"specifie","lemma":"spécifier","pos":"VER"} ,
		{"word":"spécifient","word_nosc":"specifient","lemma":"spécifier","pos":"VER"} ,
		{"word":"spécifier","word_nosc":"specifier","lemma":"spécifier","pos":"VER"} ,
		{"word":"spécifiera","word_nosc":"specifiera","lemma":"spécifier","pos":"VER"} ,
		{"word":"spécifiez","word_nosc":"specifiez","lemma":"spécifier","pos":"VER"} ,
		{"word":"spécifié","word_nosc":"specifie","lemma":"spécifier","pos":"VER"} ,
		{"word":"spécifiées","word_nosc":"specifiees","lemma":"spécifier","pos":"VER"} ,
		{"word":"spéculaient","word_nosc":"speculaient","lemma":"spéculer","pos":"VER"} ,
		{"word":"spéculait","word_nosc":"speculait","lemma":"spéculer","pos":"VER"} ,
		{"word":"spéculant","word_nosc":"speculant","lemma":"spéculer","pos":"VER"} ,
		{"word":"spécule","word_nosc":"specule","lemma":"spéculer","pos":"VER"} ,
		{"word":"spéculent","word_nosc":"speculent","lemma":"spéculer","pos":"VER"} ,
		{"word":"spéculer","word_nosc":"speculer","lemma":"spéculer","pos":"VER"} ,
		{"word":"spéculeraient","word_nosc":"speculeraient","lemma":"spéculer","pos":"VER"} ,
		{"word":"spéculez","word_nosc":"speculez","lemma":"spéculer","pos":"VER"} ,
		{"word":"spéculé","word_nosc":"specule","lemma":"spéculer","pos":"VER"} ,
		{"word":"squattait","word_nosc":"squattait","lemma":"squatter","pos":"VER"} ,
		{"word":"squatte","word_nosc":"squatte","lemma":"squatter","pos":"VER"} ,
		{"word":"squatter","word_nosc":"squatter","lemma":"squatter","pos":"VER"} ,
		{"word":"squatteras","word_nosc":"squatteras","lemma":"squatter","pos":"VER"} ,
		{"word":"squattez","word_nosc":"squattez","lemma":"squatter","pos":"VER"} ,
		{"word":"squatté","word_nosc":"squatte","lemma":"squatter","pos":"VER"} ,
		{"word":"squattée","word_nosc":"squattee","lemma":"squatter","pos":"VER"} ,
		{"word":"squattées","word_nosc":"squattees","lemma":"squatter","pos":"VER"} ,
		{"word":"squattérisé","word_nosc":"squatterise","lemma":"squattériser","pos":"VER"} ,
		{"word":"squattés","word_nosc":"squattes","lemma":"squatter","pos":"VER"} ,
		{"word":"squeezent","word_nosc":"squeezent","lemma":"squeezer","pos":"VER"} ,
		{"word":"squeezer","word_nosc":"squeezer","lemma":"squeezer","pos":"VER"} ,
		{"word":"stabilisa","word_nosc":"stabilisa","lemma":"stabiliser","pos":"VER"} ,
		{"word":"stabilisait","word_nosc":"stabilisait","lemma":"stabiliser","pos":"VER"} ,
		{"word":"stabilisant","word_nosc":"stabilisant","lemma":"stabiliser","pos":"VER"} ,
		{"word":"stabilise","word_nosc":"stabilise","lemma":"stabiliser","pos":"VER"} ,
		{"word":"stabilisent","word_nosc":"stabilisent","lemma":"stabiliser","pos":"VER"} ,
		{"word":"stabiliser","word_nosc":"stabiliser","lemma":"stabiliser","pos":"VER"} ,
		{"word":"stabilisez","word_nosc":"stabilisez","lemma":"stabiliser","pos":"VER"} ,
		{"word":"stabilisons","word_nosc":"stabilisons","lemma":"stabiliser","pos":"VER"} ,
		{"word":"stabilisèrent","word_nosc":"stabiliserent","lemma":"stabiliser","pos":"VER"} ,
		{"word":"stabilisé","word_nosc":"stabilise","lemma":"stabiliser","pos":"VER"} ,
		{"word":"stabilisée","word_nosc":"stabilisee","lemma":"stabiliser","pos":"VER"} ,
		{"word":"stabilisés","word_nosc":"stabilises","lemma":"stabiliser","pos":"VER"} ,
		{"word":"stagna","word_nosc":"stagna","lemma":"stagner","pos":"VER"} ,
		{"word":"stagnaient","word_nosc":"stagnaient","lemma":"stagner","pos":"VER"} ,
		{"word":"stagnait","word_nosc":"stagnait","lemma":"stagner","pos":"VER"} ,
		{"word":"stagnant","word_nosc":"stagnant","lemma":"stagner","pos":"VER"} ,
		{"word":"stagne","word_nosc":"stagne","lemma":"stagner","pos":"VER"} ,
		{"word":"stagnent","word_nosc":"stagnent","lemma":"stagner","pos":"VER"} ,
		{"word":"stagner","word_nosc":"stagner","lemma":"stagner","pos":"VER"} ,
		{"word":"stagnez","word_nosc":"stagnez","lemma":"stagner","pos":"VER"} ,
		{"word":"stagné","word_nosc":"stagne","lemma":"stagner","pos":"VER"} ,
		{"word":"stalinisée","word_nosc":"stalinisee","lemma":"staliniser","pos":"VER"} ,
		{"word":"standardisé","word_nosc":"standardise","lemma":"standardiser","pos":"VER"} ,
		{"word":"standardisée","word_nosc":"standardisee","lemma":"standardiser","pos":"VER"} ,
		{"word":"standardisées","word_nosc":"standardisees","lemma":"standardiser","pos":"VER"} ,
		{"word":"state","word_nosc":"state","lemma":"stater","pos":"VER"} ,
		{"word":"stater","word_nosc":"stater","lemma":"stater","pos":"VER"} ,
		{"word":"stationna","word_nosc":"stationna","lemma":"stationner","pos":"VER"} ,
		{"word":"stationnaient","word_nosc":"stationnaient","lemma":"stationner","pos":"VER"} ,
		{"word":"stationnait","word_nosc":"stationnait","lemma":"stationner","pos":"VER"} ,
		{"word":"stationnant","word_nosc":"stationnant","lemma":"stationner","pos":"VER"} ,
		{"word":"stationne","word_nosc":"stationne","lemma":"stationner","pos":"VER"} ,
		{"word":"stationnent","word_nosc":"stationnent","lemma":"stationner","pos":"VER"} ,
		{"word":"stationner","word_nosc":"stationner","lemma":"stationner","pos":"VER"} ,
		{"word":"stationnera","word_nosc":"stationnera","lemma":"stationner","pos":"VER"} ,
		{"word":"stationnez","word_nosc":"stationnez","lemma":"stationner","pos":"VER"} ,
		{"word":"stationnions","word_nosc":"stationnions","lemma":"stationner","pos":"VER"} ,
		{"word":"stationnèrent","word_nosc":"stationnerent","lemma":"stationner","pos":"VER"} ,
		{"word":"stationné","word_nosc":"stationne","lemma":"stationner","pos":"VER"} ,
		{"word":"stationnée","word_nosc":"stationnee","lemma":"stationner","pos":"VER"} ,
		{"word":"stationnées","word_nosc":"stationnees","lemma":"stationner","pos":"VER"} ,
		{"word":"stationnés","word_nosc":"stationnes","lemma":"stationner","pos":"VER"} ,
		{"word":"statistiquer","word_nosc":"statistiquer","lemma":"statistiquer","pos":"VER"} ,
		{"word":"statuaient","word_nosc":"statuaient","lemma":"statuer","pos":"VER"} ,
		{"word":"statuant","word_nosc":"statuant","lemma":"statuer","pos":"VER"} ,
		{"word":"statue","word_nosc":"statue","lemma":"statuer","pos":"VER"} ,
		{"word":"statuer","word_nosc":"statuer","lemma":"statuer","pos":"VER"} ,
		{"word":"statuera","word_nosc":"statuera","lemma":"statuer","pos":"VER"} ,
		{"word":"statuerai","word_nosc":"statuerai","lemma":"statuer","pos":"VER"} ,
		{"word":"statuerait","word_nosc":"statuerait","lemma":"statuer","pos":"VER"} ,
		{"word":"statues","word_nosc":"statues","lemma":"statuer","pos":"VER"} ,
		{"word":"statufia","word_nosc":"statufia","lemma":"statufier","pos":"VER"} ,
		{"word":"statufiais","word_nosc":"statufiais","lemma":"statufier","pos":"VER"} ,
		{"word":"statufiait","word_nosc":"statufiait","lemma":"statufier","pos":"VER"} ,
		{"word":"statufiant","word_nosc":"statufiant","lemma":"statufier","pos":"VER"} ,
		{"word":"statufier","word_nosc":"statufier","lemma":"statufier","pos":"VER"} ,
		{"word":"statufièrent","word_nosc":"statufierent","lemma":"statufier","pos":"VER"} ,
		{"word":"statufié","word_nosc":"statufie","lemma":"statufier","pos":"VER"} ,
		{"word":"statufiée","word_nosc":"statufiee","lemma":"statufier","pos":"VER"} ,
		{"word":"statufiées","word_nosc":"statufiees","lemma":"statufier","pos":"VER"} ,
		{"word":"statufiés","word_nosc":"statufies","lemma":"statufier","pos":"VER"} ,
		{"word":"statué","word_nosc":"statue","lemma":"statuer","pos":"VER"} ,
		{"word":"stigmatisaient","word_nosc":"stigmatisaient","lemma":"stigmatiser","pos":"VER"} ,
		{"word":"stigmatisait","word_nosc":"stigmatisait","lemma":"stigmatiser","pos":"VER"} ,
		{"word":"stigmatisant","word_nosc":"stigmatisant","lemma":"stigmatiser","pos":"VER"} ,
		{"word":"stigmatiser","word_nosc":"stigmatiser","lemma":"stigmatiser","pos":"VER"} ,
		{"word":"stigmatisé","word_nosc":"stigmatise","lemma":"stigmatiser","pos":"VER"} ,
		{"word":"stigmatisée","word_nosc":"stigmatisee","lemma":"stigmatiser","pos":"VER"} ,
		{"word":"stigmatisés","word_nosc":"stigmatises","lemma":"stigmatiser","pos":"VER"} ,
		{"word":"stimula","word_nosc":"stimula","lemma":"stimuler","pos":"VER"} ,
		{"word":"stimulaient","word_nosc":"stimulaient","lemma":"stimuler","pos":"VER"} ,
		{"word":"stimulait","word_nosc":"stimulait","lemma":"stimuler","pos":"VER"} ,
		{"word":"stimulant","word_nosc":"stimulant","lemma":"stimuler","pos":"VER"} ,
		{"word":"stimule","word_nosc":"stimule","lemma":"stimuler","pos":"VER"} ,
		{"word":"stimulent","word_nosc":"stimulent","lemma":"stimuler","pos":"VER"} ,
		{"word":"stimuler","word_nosc":"stimuler","lemma":"stimuler","pos":"VER"} ,
		{"word":"stimulera","word_nosc":"stimulera","lemma":"stimuler","pos":"VER"} ,
		{"word":"stimulerait","word_nosc":"stimulerait","lemma":"stimuler","pos":"VER"} ,
		{"word":"stimuleront","word_nosc":"stimuleront","lemma":"stimuler","pos":"VER"} ,
		{"word":"stimulez","word_nosc":"stimulez","lemma":"stimuler","pos":"VER"} ,
		{"word":"stimulons","word_nosc":"stimulons","lemma":"stimuler","pos":"VER"} ,
		{"word":"stimulé","word_nosc":"stimule","lemma":"stimuler","pos":"VER"} ,
		{"word":"stimulée","word_nosc":"stimulee","lemma":"stimuler","pos":"VER"} ,
		{"word":"stimulées","word_nosc":"stimulees","lemma":"stimuler","pos":"VER"} ,
		{"word":"stimulés","word_nosc":"stimules","lemma":"stimuler","pos":"VER"} ,
		{"word":"stipendiés","word_nosc":"stipendies","lemma":"stipendier","pos":"VER"} ,
		{"word":"stipulait","word_nosc":"stipulait","lemma":"stipuler","pos":"VER"} ,
		{"word":"stipulant","word_nosc":"stipulant","lemma":"stipuler","pos":"VER"} ,
		{"word":"stipule","word_nosc":"stipule","lemma":"stipuler","pos":"VER"} ,
		{"word":"stipulent","word_nosc":"stipulent","lemma":"stipuler","pos":"VER"} ,
		{"word":"stipuler","word_nosc":"stipuler","lemma":"stipuler","pos":"VER"} ,
		{"word":"stipulé","word_nosc":"stipule","lemma":"stipuler","pos":"VER"} ,
		{"word":"stipulée","word_nosc":"stipulee","lemma":"stipuler","pos":"VER"} ,
		{"word":"stipulées","word_nosc":"stipulees","lemma":"stipuler","pos":"VER"} ,
		{"word":"stipulés","word_nosc":"stipules","lemma":"stipuler","pos":"VER"} ,
		{"word":"stockaient","word_nosc":"stockaient","lemma":"stocker","pos":"VER"} ,
		{"word":"stockait","word_nosc":"stockait","lemma":"stocker","pos":"VER"} ,
		{"word":"stocke","word_nosc":"stocke","lemma":"stocker","pos":"VER"} ,
		{"word":"stockent","word_nosc":"stockent","lemma":"stocker","pos":"VER"} ,
		{"word":"stocker","word_nosc":"stocker","lemma":"stocker","pos":"VER"} ,
		{"word":"stockez","word_nosc":"stockez","lemma":"stocker","pos":"VER"} ,
		{"word":"stockons","word_nosc":"stockons","lemma":"stocker","pos":"VER"} ,
		{"word":"stocké","word_nosc":"stocke","lemma":"stocker","pos":"VER"} ,
		{"word":"stockée","word_nosc":"stockee","lemma":"stocker","pos":"VER"} ,
		{"word":"stockées","word_nosc":"stockees","lemma":"stocker","pos":"VER"} ,
		{"word":"stockés","word_nosc":"stockes","lemma":"stocker","pos":"VER"} ,
		{"word":"stoppa","word_nosc":"stoppa","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppai","word_nosc":"stoppai","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppaient","word_nosc":"stoppaient","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppais","word_nosc":"stoppais","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppait","word_nosc":"stoppait","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppant","word_nosc":"stoppant","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppe","word_nosc":"stoppe","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppent","word_nosc":"stoppent","lemma":"stopper","pos":"VER"} ,
		{"word":"stopper","word_nosc":"stopper","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppera","word_nosc":"stoppera","lemma":"stopper","pos":"VER"} ,
		{"word":"stopperai","word_nosc":"stopperai","lemma":"stopper","pos":"VER"} ,
		{"word":"stopperiez","word_nosc":"stopperiez","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppes","word_nosc":"stoppes","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppez","word_nosc":"stoppez","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppions","word_nosc":"stoppions","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppons","word_nosc":"stoppons","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppât","word_nosc":"stoppat","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppèrent","word_nosc":"stopperent","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppé","word_nosc":"stoppe","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppée","word_nosc":"stoppee","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppées","word_nosc":"stoppees","lemma":"stopper","pos":"VER"} ,
		{"word":"stoppés","word_nosc":"stoppes","lemma":"stopper","pos":"VER"} ,
		{"word":"strangula","word_nosc":"strangula","lemma":"stranguler","pos":"VER"} ,
		{"word":"strangulant","word_nosc":"strangulant","lemma":"stranguler","pos":"VER"} ,
		{"word":"stranguler","word_nosc":"stranguler","lemma":"stranguler","pos":"VER"} ,
		{"word":"strangulé","word_nosc":"strangule","lemma":"stranguler","pos":"VER"} ,
		{"word":"strangulées","word_nosc":"strangulees","lemma":"stranguler","pos":"VER"} ,
		{"word":"stratifiée","word_nosc":"stratifiee","lemma":"stratifier","pos":"VER"} ,
		{"word":"stressais","word_nosc":"stressais","lemma":"stresser","pos":"VER"} ,
		{"word":"stressait","word_nosc":"stressait","lemma":"stresser","pos":"VER"} ,
		{"word":"stressant","word_nosc":"stressant","lemma":"stresser","pos":"VER"} ,
		{"word":"stresse","word_nosc":"stresse","lemma":"stresser","pos":"VER"} ,
		{"word":"stressent","word_nosc":"stressent","lemma":"stresser","pos":"VER"} ,
		{"word":"stresser","word_nosc":"stresser","lemma":"stresser","pos":"VER"} ,
		{"word":"stressera","word_nosc":"stressera","lemma":"stresser","pos":"VER"} ,
		{"word":"stresserai","word_nosc":"stresserai","lemma":"stresser","pos":"VER"} ,
		{"word":"stressé","word_nosc":"stresse","lemma":"stresser","pos":"VER"} ,
		{"word":"stressée","word_nosc":"stressee","lemma":"stresser","pos":"VER"} ,
		{"word":"stressées","word_nosc":"stressees","lemma":"stresser","pos":"VER"} ,
		{"word":"stressés","word_nosc":"stresses","lemma":"stresser","pos":"VER"} ,
		{"word":"striaient","word_nosc":"striaient","lemma":"strier","pos":"VER"} ,
		{"word":"striait","word_nosc":"striait","lemma":"strier","pos":"VER"} ,
		{"word":"strider","word_nosc":"strider","lemma":"strider","pos":"VER"} ,
		{"word":"stridulait","word_nosc":"stridulait","lemma":"striduler","pos":"VER"} ,
		{"word":"stridule","word_nosc":"stridule","lemma":"striduler","pos":"VER"} ,
		{"word":"striduler","word_nosc":"striduler","lemma":"striduler","pos":"VER"} ,
		{"word":"strient","word_nosc":"strient","lemma":"strier","pos":"VER"} ,
		{"word":"strier","word_nosc":"strier","lemma":"strier","pos":"VER"} ,
		{"word":"stripper","word_nosc":"stripper","lemma":"stripper","pos":"VER"} ,
		{"word":"strié","word_nosc":"strie","lemma":"strier","pos":"VER"} ,
		{"word":"striée","word_nosc":"striee","lemma":"strier","pos":"VER"} ,
		{"word":"striées","word_nosc":"striees","lemma":"strier","pos":"VER"} ,
		{"word":"striés","word_nosc":"stries","lemma":"strier","pos":"VER"} ,
		{"word":"structura","word_nosc":"structura","lemma":"structurer","pos":"VER"} ,
		{"word":"structuraient","word_nosc":"structuraient","lemma":"structurer","pos":"VER"} ,
		{"word":"structurait","word_nosc":"structurait","lemma":"structurer","pos":"VER"} ,
		{"word":"structurant","word_nosc":"structurant","lemma":"structurer","pos":"VER"} ,
		{"word":"structure","word_nosc":"structure","lemma":"structurer","pos":"VER"} ,
		{"word":"structurent","word_nosc":"structurent","lemma":"structurer","pos":"VER"} ,
		{"word":"structurer","word_nosc":"structurer","lemma":"structurer","pos":"VER"} ,
		{"word":"structures","word_nosc":"structures","lemma":"structurer","pos":"VER"} ,
		{"word":"structuré","word_nosc":"structure","lemma":"structurer","pos":"VER"} ,
		{"word":"structurée","word_nosc":"structuree","lemma":"structurer","pos":"VER"} ,
		{"word":"stupéfia","word_nosc":"stupefia","lemma":"stupéfier","pos":"VER"} ,
		{"word":"stupéfiaient","word_nosc":"stupefiaient","lemma":"stupéfier","pos":"VER"} ,
		{"word":"stupéfiait","word_nosc":"stupefiait","lemma":"stupéfier","pos":"VER"} ,
		{"word":"stupéfiant","word_nosc":"stupefiant","lemma":"stupéfier","pos":"VER"} ,
		{"word":"stupéfie","word_nosc":"stupefie","lemma":"stupéfier","pos":"VER"} ,
		{"word":"stupéfient","word_nosc":"stupefient","lemma":"stupéfier","pos":"VER"} ,
		{"word":"stupéfier","word_nosc":"stupefier","lemma":"stupéfier","pos":"VER"} ,
		{"word":"stupéfierait","word_nosc":"stupefierait","lemma":"stupéfier","pos":"VER"} ,
		{"word":"stupéfiez","word_nosc":"stupefiez","lemma":"stupéfier","pos":"VER"} ,
		{"word":"stupéfié","word_nosc":"stupefie","lemma":"stupéfier","pos":"VER"} ,
		{"word":"stupéfiée","word_nosc":"stupefiee","lemma":"stupéfier","pos":"VER"} ,
		{"word":"stupéfiées","word_nosc":"stupefiees","lemma":"stupéfier","pos":"VER"} ,
		{"word":"stupéfiés","word_nosc":"stupefies","lemma":"stupéfier","pos":"VER"} ,
		{"word":"style","word_nosc":"style","lemma":"styler","pos":"VER"} ,
		{"word":"styler","word_nosc":"styler","lemma":"styler","pos":"VER"} ,
		{"word":"stylisa","word_nosc":"stylisa","lemma":"styliser","pos":"VER"} ,
		{"word":"stylise","word_nosc":"stylise","lemma":"styliser","pos":"VER"} ,
		{"word":"stylisé","word_nosc":"stylise","lemma":"styliser","pos":"VER"} ,
		{"word":"stylisée","word_nosc":"stylisee","lemma":"styliser","pos":"VER"} ,
		{"word":"stylisées","word_nosc":"stylisees","lemma":"styliser","pos":"VER"} ,
		{"word":"stylisés","word_nosc":"stylises","lemma":"styliser","pos":"VER"} ,
		{"word":"stylé","word_nosc":"style","lemma":"styler","pos":"VER"} ,
		{"word":"stylée","word_nosc":"stylee","lemma":"styler","pos":"VER"} ,
		{"word":"stylées","word_nosc":"stylees","lemma":"styler","pos":"VER"} ,
		{"word":"stylés","word_nosc":"styles","lemma":"styler","pos":"VER"} ,
		{"word":"sténographiai","word_nosc":"stenographiai","lemma":"sténographier","pos":"VER"} ,
		{"word":"stérilisa","word_nosc":"sterilisa","lemma":"stériliser","pos":"VER"} ,
		{"word":"stérilisait","word_nosc":"sterilisait","lemma":"stériliser","pos":"VER"} ,
		{"word":"stérilisant","word_nosc":"sterilisant","lemma":"stériliser","pos":"VER"} ,
		{"word":"stérilise","word_nosc":"sterilise","lemma":"stériliser","pos":"VER"} ,
		{"word":"stérilisent","word_nosc":"sterilisent","lemma":"stériliser","pos":"VER"} ,
		{"word":"stériliser","word_nosc":"steriliser","lemma":"stériliser","pos":"VER"} ,
		{"word":"stérilisez","word_nosc":"sterilisez","lemma":"stériliser","pos":"VER"} ,
		{"word":"stérilisé","word_nosc":"sterilise","lemma":"stériliser","pos":"VER"} ,
		{"word":"stérilisée","word_nosc":"sterilisee","lemma":"stériliser","pos":"VER"} ,
		{"word":"stérilisées","word_nosc":"sterilisees","lemma":"stériliser","pos":"VER"} ,
		{"word":"stérilisés","word_nosc":"sterilises","lemma":"stériliser","pos":"VER"} ,
		{"word":"stéréotyper","word_nosc":"stereotyper","lemma":"stéréotyper","pos":"VER"} ,
		{"word":"stéréotypé","word_nosc":"stereotype","lemma":"stéréotyper","pos":"VER"} ,
		{"word":"stéréotypée","word_nosc":"stereotypee","lemma":"stéréotyper","pos":"VER"} ,
		{"word":"stéréotypées","word_nosc":"stereotypees","lemma":"stéréotyper","pos":"VER"} ,
		{"word":"stéréotypés","word_nosc":"stereotypes","lemma":"stéréotyper","pos":"VER"} ,
		{"word":"su","word_nosc":"su","lemma":"savoir","pos":"VER"} ,
		{"word":"sua","word_nosc":"sua","lemma":"suer","pos":"VER"} ,
		{"word":"suaient","word_nosc":"suaient","lemma":"suer","pos":"VER"} ,
		{"word":"suais","word_nosc":"suais","lemma":"suer","pos":"VER"} ,
		{"word":"suait","word_nosc":"suait","lemma":"suer","pos":"VER"} ,
		{"word":"suant","word_nosc":"suant","lemma":"suer","pos":"VER"} ,
		{"word":"subdivisa","word_nosc":"subdivisa","lemma":"subdiviser","pos":"VER"} ,
		{"word":"subdivisaient","word_nosc":"subdivisaient","lemma":"subdiviser","pos":"VER"} ,
		{"word":"subdivisait","word_nosc":"subdivisait","lemma":"subdiviser","pos":"VER"} ,
		{"word":"subdivisent","word_nosc":"subdivisent","lemma":"subdiviser","pos":"VER"} ,
		{"word":"subdivisé","word_nosc":"subdivise","lemma":"subdiviser","pos":"VER"} ,
		{"word":"subdivisée","word_nosc":"subdivisee","lemma":"subdiviser","pos":"VER"} ,
		{"word":"subdivisés","word_nosc":"subdivises","lemma":"subdiviser","pos":"VER"} ,
		{"word":"subi","word_nosc":"subi","lemma":"subir","pos":"VER"} ,
		{"word":"subie","word_nosc":"subie","lemma":"subir","pos":"VER"} ,
		{"word":"subies","word_nosc":"subies","lemma":"subir","pos":"VER"} ,
		{"word":"subir","word_nosc":"subir","lemma":"subir","pos":"VER"} ,
		{"word":"subira","word_nosc":"subira","lemma":"subir","pos":"VER"} ,
		{"word":"subirai","word_nosc":"subirai","lemma":"subir","pos":"VER"} ,
		{"word":"subiraient","word_nosc":"subiraient","lemma":"subir","pos":"VER"} ,
		{"word":"subirais","word_nosc":"subirais","lemma":"subir","pos":"VER"} ,
		{"word":"subirait","word_nosc":"subirait","lemma":"subir","pos":"VER"} ,
		{"word":"subiras","word_nosc":"subiras","lemma":"subir","pos":"VER"} ,
		{"word":"subirent","word_nosc":"subirent","lemma":"subir","pos":"VER"} ,
		{"word":"subirez","word_nosc":"subirez","lemma":"subir","pos":"VER"} ,
		{"word":"subirions","word_nosc":"subirions","lemma":"subir","pos":"VER"} ,
		{"word":"subirons","word_nosc":"subirons","lemma":"subir","pos":"VER"} ,
		{"word":"subiront","word_nosc":"subiront","lemma":"subir","pos":"VER"} ,
		{"word":"subis","word_nosc":"subis","lemma":"subir","pos":"VER"} ,
		{"word":"subissaient","word_nosc":"subissaient","lemma":"subir","pos":"VER"} ,
		{"word":"subissais","word_nosc":"subissais","lemma":"subir","pos":"VER"} ,
		{"word":"subissait","word_nosc":"subissait","lemma":"subir","pos":"VER"} ,
		{"word":"subissant","word_nosc":"subissant","lemma":"subir","pos":"VER"} ,
		{"word":"subisse","word_nosc":"subisse","lemma":"subir","pos":"VER"} ,
		{"word":"subissent","word_nosc":"subissent","lemma":"subir","pos":"VER"} ,
		{"word":"subisses","word_nosc":"subisses","lemma":"subir","pos":"VER"} ,
		{"word":"subissez","word_nosc":"subissez","lemma":"subir","pos":"VER"} ,
		{"word":"subissiez","word_nosc":"subissiez","lemma":"subir","pos":"VER"} ,
		{"word":"subissions","word_nosc":"subissions","lemma":"subir","pos":"VER"} ,
		{"word":"subissons","word_nosc":"subissons","lemma":"subir","pos":"VER"} ,
		{"word":"subit","word_nosc":"subit","lemma":"subir","pos":"VER"} ,
		{"word":"subjective","word_nosc":"subjective","lemma":"subjectiver","pos":"VER"} ,
		{"word":"subjugua","word_nosc":"subjugua","lemma":"subjuguer","pos":"VER"} ,
		{"word":"subjuguaient","word_nosc":"subjuguaient","lemma":"subjuguer","pos":"VER"} ,
		{"word":"subjuguait","word_nosc":"subjuguait","lemma":"subjuguer","pos":"VER"} ,
		{"word":"subjuguant","word_nosc":"subjuguant","lemma":"subjuguer","pos":"VER"} ,
		{"word":"subjugue","word_nosc":"subjugue","lemma":"subjuguer","pos":"VER"} ,
		{"word":"subjuguer","word_nosc":"subjuguer","lemma":"subjuguer","pos":"VER"} ,
		{"word":"subjuguerais","word_nosc":"subjuguerais","lemma":"subjuguer","pos":"VER"} ,
		{"word":"subjuguerait","word_nosc":"subjuguerait","lemma":"subjuguer","pos":"VER"} ,
		{"word":"subjugues","word_nosc":"subjugues","lemma":"subjuguer","pos":"VER"} ,
		{"word":"subjugué","word_nosc":"subjugue","lemma":"subjuguer","pos":"VER"} ,
		{"word":"subjuguée","word_nosc":"subjuguee","lemma":"subjuguer","pos":"VER"} ,
		{"word":"subjuguées","word_nosc":"subjuguees","lemma":"subjuguer","pos":"VER"} ,
		{"word":"subjugués","word_nosc":"subjugues","lemma":"subjuguer","pos":"VER"} ,
		{"word":"sublimais","word_nosc":"sublimais","lemma":"sublimer","pos":"VER"} ,
		{"word":"sublimait","word_nosc":"sublimait","lemma":"sublimer","pos":"VER"} ,
		{"word":"sublimant","word_nosc":"sublimant","lemma":"sublimer","pos":"VER"} ,
		{"word":"sublime","word_nosc":"sublime","lemma":"sublimer","pos":"VER"} ,
		{"word":"subliment","word_nosc":"subliment","lemma":"sublimer","pos":"VER"} ,
		{"word":"sublimer","word_nosc":"sublimer","lemma":"sublimer","pos":"VER"} ,
		{"word":"sublimes","word_nosc":"sublimes","lemma":"sublimer","pos":"VER"} ,
		{"word":"sublimé","word_nosc":"sublime","lemma":"sublimer","pos":"VER"} ,
		{"word":"sublimée","word_nosc":"sublimee","lemma":"sublimer","pos":"VER"} ,
		{"word":"sublimées","word_nosc":"sublimees","lemma":"sublimer","pos":"VER"} ,
		{"word":"sublimés","word_nosc":"sublimes","lemma":"sublimer","pos":"VER"} ,
		{"word":"submerge","word_nosc":"submerge","lemma":"submerger","pos":"VER"} ,
		{"word":"submergea","word_nosc":"submergea","lemma":"submerger","pos":"VER"} ,
		{"word":"submergeaient","word_nosc":"submergeaient","lemma":"submerger","pos":"VER"} ,
		{"word":"submergeait","word_nosc":"submergeait","lemma":"submerger","pos":"VER"} ,
		{"word":"submergeant","word_nosc":"submergeant","lemma":"submerger","pos":"VER"} ,
		{"word":"submergent","word_nosc":"submergent","lemma":"submerger","pos":"VER"} ,
		{"word":"submerger","word_nosc":"submerger","lemma":"submerger","pos":"VER"} ,
		{"word":"submergera","word_nosc":"submergera","lemma":"submerger","pos":"VER"} ,
		{"word":"submergerait","word_nosc":"submergerait","lemma":"submerger","pos":"VER"} ,
		{"word":"submergeât","word_nosc":"submergeat","lemma":"submerger","pos":"VER"} ,
		{"word":"submergèrent","word_nosc":"submergerent","lemma":"submerger","pos":"VER"} ,
		{"word":"submergé","word_nosc":"submerge","lemma":"submerger","pos":"VER"} ,
		{"word":"submergée","word_nosc":"submergee","lemma":"submerger","pos":"VER"} ,
		{"word":"submergées","word_nosc":"submergees","lemma":"submerger","pos":"VER"} ,
		{"word":"submergés","word_nosc":"submerges","lemma":"submerger","pos":"VER"} ,
		{"word":"subodora","word_nosc":"subodora","lemma":"subodorer","pos":"VER"} ,
		{"word":"subodorais","word_nosc":"subodorais","lemma":"subodorer","pos":"VER"} ,
		{"word":"subodorait","word_nosc":"subodorait","lemma":"subodorer","pos":"VER"} ,
		{"word":"subodorant","word_nosc":"subodorant","lemma":"subodorer","pos":"VER"} ,
		{"word":"subodore","word_nosc":"subodore","lemma":"subodorer","pos":"VER"} ,
		{"word":"subodorent","word_nosc":"subodorent","lemma":"subodorer","pos":"VER"} ,
		{"word":"subodorer","word_nosc":"subodorer","lemma":"subodorer","pos":"VER"} ,
		{"word":"subodorèrent","word_nosc":"subodorerent","lemma":"subodorer","pos":"VER"} ,
		{"word":"subodoré","word_nosc":"subodore","lemma":"subodorer","pos":"VER"} ,
		{"word":"subodorés","word_nosc":"subodores","lemma":"subodorer","pos":"VER"} ,
		{"word":"subordonnaient","word_nosc":"subordonnaient","lemma":"subordonner","pos":"VER"} ,
		{"word":"subordonnais","word_nosc":"subordonnais","lemma":"subordonner","pos":"VER"} ,
		{"word":"subordonnant","word_nosc":"subordonnant","lemma":"subordonner","pos":"VER"} ,
		{"word":"subordonne","word_nosc":"subordonne","lemma":"subordonner","pos":"VER"} ,
		{"word":"subordonner","word_nosc":"subordonner","lemma":"subordonner","pos":"VER"} ,
		{"word":"subordonné","word_nosc":"subordonne","lemma":"subordonner","pos":"VER"} ,
		{"word":"subordonnée","word_nosc":"subordonnee","lemma":"subordonner","pos":"VER"} ,
		{"word":"subordonnées","word_nosc":"subordonnees","lemma":"subordonner","pos":"VER"} ,
		{"word":"subordonnés","word_nosc":"subordonnes","lemma":"subordonner","pos":"VER"} ,
		{"word":"suborner","word_nosc":"suborner","lemma":"suborner","pos":"VER"} ,
		{"word":"suborné","word_nosc":"suborne","lemma":"suborner","pos":"VER"} ,
		{"word":"subornée","word_nosc":"subornee","lemma":"suborner","pos":"VER"} ,
		{"word":"subornés","word_nosc":"subornes","lemma":"suborner","pos":"VER"} ,
		{"word":"subrogé","word_nosc":"subroge","lemma":"subroger","pos":"VER"} ,
		{"word":"subsista","word_nosc":"subsista","lemma":"subsister","pos":"VER"} ,
		{"word":"subsistaient","word_nosc":"subsistaient","lemma":"subsister","pos":"VER"} ,
		{"word":"subsistait","word_nosc":"subsistait","lemma":"subsister","pos":"VER"} ,
		{"word":"subsistant","word_nosc":"subsistant","lemma":"subsister","pos":"VER"} ,
		{"word":"subsiste","word_nosc":"subsiste","lemma":"subsister","pos":"VER"} ,
		{"word":"subsistent","word_nosc":"subsistent","lemma":"subsister","pos":"VER"} ,
		{"word":"subsister","word_nosc":"subsister","lemma":"subsister","pos":"VER"} ,
		{"word":"subsistera","word_nosc":"subsistera","lemma":"subsister","pos":"VER"} ,
		{"word":"subsisteraient","word_nosc":"subsisteraient","lemma":"subsister","pos":"VER"} ,
		{"word":"subsisterait","word_nosc":"subsisterait","lemma":"subsister","pos":"VER"} ,
		{"word":"subsisteront","word_nosc":"subsisteront","lemma":"subsister","pos":"VER"} ,
		{"word":"subsistions","word_nosc":"subsistions","lemma":"subsister","pos":"VER"} ,
		{"word":"subsistons","word_nosc":"subsistons","lemma":"subsister","pos":"VER"} ,
		{"word":"subsistâmes","word_nosc":"subsistames","lemma":"subsister","pos":"VER"} ,
		{"word":"subsistât","word_nosc":"subsistat","lemma":"subsister","pos":"VER"} ,
		{"word":"subsistèrent","word_nosc":"subsisterent","lemma":"subsister","pos":"VER"} ,
		{"word":"subsisté","word_nosc":"subsiste","lemma":"subsister","pos":"VER"} ,
		{"word":"substitua","word_nosc":"substitua","lemma":"substituer","pos":"VER"} ,
		{"word":"substituai","word_nosc":"substituai","lemma":"substituer","pos":"VER"} ,
		{"word":"substituaient","word_nosc":"substituaient","lemma":"substituer","pos":"VER"} ,
		{"word":"substituait","word_nosc":"substituait","lemma":"substituer","pos":"VER"} ,
		{"word":"substituant","word_nosc":"substituant","lemma":"substituer","pos":"VER"} ,
		{"word":"substitue","word_nosc":"substitue","lemma":"substituer","pos":"VER"} ,
		{"word":"substituent","word_nosc":"substituent","lemma":"substituer","pos":"VER"} ,
		{"word":"substituer","word_nosc":"substituer","lemma":"substituer","pos":"VER"} ,
		{"word":"substituerait","word_nosc":"substituerait","lemma":"substituer","pos":"VER"} ,
		{"word":"substituerez","word_nosc":"substituerez","lemma":"substituer","pos":"VER"} ,
		{"word":"substituez","word_nosc":"substituez","lemma":"substituer","pos":"VER"} ,
		{"word":"substituons","word_nosc":"substituons","lemma":"substituer","pos":"VER"} ,
		{"word":"substitué","word_nosc":"substitue","lemma":"substituer","pos":"VER"} ,
		{"word":"substituées","word_nosc":"substituees","lemma":"substituer","pos":"VER"} ,
		{"word":"substitués","word_nosc":"substitues","lemma":"substituer","pos":"VER"} ,
		{"word":"subtilisa","word_nosc":"subtilisa","lemma":"subtiliser","pos":"VER"} ,
		{"word":"subtilisait","word_nosc":"subtilisait","lemma":"subtiliser","pos":"VER"} ,
		{"word":"subtilisant","word_nosc":"subtilisant","lemma":"subtiliser","pos":"VER"} ,
		{"word":"subtilisassent","word_nosc":"subtilisassent","lemma":"subtiliser","pos":"VER"} ,
		{"word":"subtilise","word_nosc":"subtilise","lemma":"subtiliser","pos":"VER"} ,
		{"word":"subtiliser","word_nosc":"subtiliser","lemma":"subtiliser","pos":"VER"} ,
		{"word":"subtilisera","word_nosc":"subtilisera","lemma":"subtiliser","pos":"VER"} ,
		{"word":"subtilisé","word_nosc":"subtilise","lemma":"subtiliser","pos":"VER"} ,
		{"word":"subtilisée","word_nosc":"subtilisee","lemma":"subtiliser","pos":"VER"} ,
		{"word":"subtilisées","word_nosc":"subtilisees","lemma":"subtiliser","pos":"VER"} ,
		{"word":"subtilisés","word_nosc":"subtilises","lemma":"subtiliser","pos":"VER"} ,
		{"word":"subvenant","word_nosc":"subvenant","lemma":"subvenir","pos":"VER"} ,
		{"word":"subvenez","word_nosc":"subvenez","lemma":"subvenir","pos":"VER"} ,
		{"word":"subvenir","word_nosc":"subvenir","lemma":"subvenir","pos":"VER"} ,
		{"word":"subventionnant","word_nosc":"subventionnant","lemma":"subventionner","pos":"VER"} ,
		{"word":"subventionne","word_nosc":"subventionne","lemma":"subventionner","pos":"VER"} ,
		{"word":"subventionnent","word_nosc":"subventionnent","lemma":"subventionner","pos":"VER"} ,
		{"word":"subventionner","word_nosc":"subventionner","lemma":"subventionner","pos":"VER"} ,
		{"word":"subventionnera","word_nosc":"subventionnera","lemma":"subventionner","pos":"VER"} ,
		{"word":"subventionné","word_nosc":"subventionne","lemma":"subventionner","pos":"VER"} ,
		{"word":"subventionnée","word_nosc":"subventionnee","lemma":"subventionner","pos":"VER"} ,
		{"word":"subventionnés","word_nosc":"subventionnes","lemma":"subventionner","pos":"VER"} ,
		{"word":"subvenu","word_nosc":"subvenu","lemma":"subvenir","pos":"VER"} ,
		{"word":"subvertir","word_nosc":"subvertir","lemma":"subvertir","pos":"VER"} ,
		{"word":"subviendra","word_nosc":"subviendra","lemma":"subvenir","pos":"VER"} ,
		{"word":"subviendrait","word_nosc":"subviendrait","lemma":"subvenir","pos":"VER"} ,
		{"word":"subviens","word_nosc":"subviens","lemma":"subvenir","pos":"VER"} ,
		{"word":"subvient","word_nosc":"subvient","lemma":"subvenir","pos":"VER"} ,
		{"word":"subît","word_nosc":"subit","lemma":"subir","pos":"VER"} ,
		{"word":"subîtes","word_nosc":"subites","lemma":"subir","pos":"VER"} ,
		{"word":"succomba","word_nosc":"succomba","lemma":"succomber","pos":"VER"} ,
		{"word":"succombai","word_nosc":"succombai","lemma":"succomber","pos":"VER"} ,
		{"word":"succombaient","word_nosc":"succombaient","lemma":"succomber","pos":"VER"} ,
		{"word":"succombais","word_nosc":"succombais","lemma":"succomber","pos":"VER"} ,
		{"word":"succombait","word_nosc":"succombait","lemma":"succomber","pos":"VER"} ,
		{"word":"succombant","word_nosc":"succombant","lemma":"succomber","pos":"VER"} ,
		{"word":"succombe","word_nosc":"succombe","lemma":"succomber","pos":"VER"} ,
		{"word":"succombent","word_nosc":"succombent","lemma":"succomber","pos":"VER"} ,
		{"word":"succomber","word_nosc":"succomber","lemma":"succomber","pos":"VER"} ,
		{"word":"succombera","word_nosc":"succombera","lemma":"succomber","pos":"VER"} ,
		{"word":"succomberai","word_nosc":"succomberai","lemma":"succomber","pos":"VER"} ,
		{"word":"succomberaient","word_nosc":"succomberaient","lemma":"succomber","pos":"VER"} ,
		{"word":"succomberais","word_nosc":"succomberais","lemma":"succomber","pos":"VER"} ,
		{"word":"succomberait","word_nosc":"succomberait","lemma":"succomber","pos":"VER"} ,
		{"word":"succomberont","word_nosc":"succomberont","lemma":"succomber","pos":"VER"} ,
		{"word":"succombes","word_nosc":"succombes","lemma":"succomber","pos":"VER"} ,
		{"word":"succombez","word_nosc":"succombez","lemma":"succomber","pos":"VER"} ,
		{"word":"succombions","word_nosc":"succombions","lemma":"succomber","pos":"VER"} ,
		{"word":"succombèrent","word_nosc":"succomberent","lemma":"succomber","pos":"VER"} ,
		{"word":"succombé","word_nosc":"succombe","lemma":"succomber","pos":"VER"} ,
		{"word":"succède","word_nosc":"succede","lemma":"succéder","pos":"VER"} ,
		{"word":"succèdent","word_nosc":"succedent","lemma":"succéder","pos":"VER"} ,
		{"word":"succéda","word_nosc":"succeda","lemma":"succéder","pos":"VER"} ,
		{"word":"succédaient","word_nosc":"succedaient","lemma":"succéder","pos":"VER"} ,
		{"word":"succédait","word_nosc":"succedait","lemma":"succéder","pos":"VER"} ,
		{"word":"succédant","word_nosc":"succedant","lemma":"succéder","pos":"VER"} ,
		{"word":"succédassent","word_nosc":"succedassent","lemma":"succéder","pos":"VER"} ,
		{"word":"succéder","word_nosc":"succeder","lemma":"succéder","pos":"VER"} ,
		{"word":"succédera","word_nosc":"succedera","lemma":"succéder","pos":"VER"} ,
		{"word":"succéderai","word_nosc":"succederai","lemma":"succéder","pos":"VER"} ,
		{"word":"succéderaient","word_nosc":"succederaient","lemma":"succéder","pos":"VER"} ,
		{"word":"succéderait","word_nosc":"succederait","lemma":"succéder","pos":"VER"} ,
		{"word":"succéderas","word_nosc":"succederas","lemma":"succéder","pos":"VER"} ,
		{"word":"succéderez","word_nosc":"succederez","lemma":"succéder","pos":"VER"} ,
		{"word":"succéderiez","word_nosc":"succederiez","lemma":"succéder","pos":"VER"} ,
		{"word":"succéderont","word_nosc":"succederont","lemma":"succéder","pos":"VER"} ,
		{"word":"succédions","word_nosc":"succedions","lemma":"succéder","pos":"VER"} ,
		{"word":"succédâmes","word_nosc":"succedames","lemma":"succéder","pos":"VER"} ,
		{"word":"succédât","word_nosc":"succedat","lemma":"succéder","pos":"VER"} ,
		{"word":"succédèrent","word_nosc":"succederent","lemma":"succéder","pos":"VER"} ,
		{"word":"succédé","word_nosc":"succede","lemma":"succéder","pos":"VER"} ,
		{"word":"succédées","word_nosc":"succedees","lemma":"succéder","pos":"VER"} ,
		{"word":"suce","word_nosc":"suce","lemma":"sucer","pos":"VER"} ,
		{"word":"sucent","word_nosc":"sucent","lemma":"sucer","pos":"VER"} ,
		{"word":"sucer","word_nosc":"sucer","lemma":"sucer","pos":"VER"} ,
		{"word":"sucera","word_nosc":"sucera","lemma":"sucer","pos":"VER"} ,
		{"word":"sucerai","word_nosc":"sucerai","lemma":"sucer","pos":"VER"} ,
		{"word":"suceraient","word_nosc":"suceraient","lemma":"sucer","pos":"VER"} ,
		{"word":"sucerais","word_nosc":"sucerais","lemma":"sucer","pos":"VER"} ,
		{"word":"sucerait","word_nosc":"sucerait","lemma":"sucer","pos":"VER"} ,
		{"word":"suces","word_nosc":"suces","lemma":"sucer","pos":"VER"} ,
		{"word":"sucez","word_nosc":"sucez","lemma":"sucer","pos":"VER"} ,
		{"word":"sucra","word_nosc":"sucra","lemma":"sucrer","pos":"VER"} ,
		{"word":"sucrait","word_nosc":"sucrait","lemma":"sucrer","pos":"VER"} ,
		{"word":"sucrant","word_nosc":"sucrant","lemma":"sucrer","pos":"VER"} ,
		{"word":"sucre","word_nosc":"sucre","lemma":"sucrer","pos":"VER"} ,
		{"word":"sucrent","word_nosc":"sucrent","lemma":"sucrer","pos":"VER"} ,
		{"word":"sucrer","word_nosc":"sucrer","lemma":"sucrer","pos":"VER"} ,
		{"word":"sucrerait","word_nosc":"sucrerait","lemma":"sucrer","pos":"VER"} ,
		{"word":"sucres","word_nosc":"sucres","lemma":"sucrer","pos":"VER"} ,
		{"word":"sucré","word_nosc":"sucre","lemma":"sucrer","pos":"VER"} ,
		{"word":"sucrée","word_nosc":"sucree","lemma":"sucrer","pos":"VER"} ,
		{"word":"sucrées","word_nosc":"sucrees","lemma":"sucrer","pos":"VER"} ,
		{"word":"sucrés","word_nosc":"sucres","lemma":"sucrer","pos":"VER"} ,
		{"word":"sucèrent","word_nosc":"sucerent","lemma":"sucer","pos":"VER"} ,
		{"word":"sucé","word_nosc":"suce","lemma":"sucer","pos":"VER"} ,
		{"word":"sucée","word_nosc":"sucee","lemma":"sucer","pos":"VER"} ,
		{"word":"sucées","word_nosc":"sucees","lemma":"sucer","pos":"VER"} ,
		{"word":"sucés","word_nosc":"suces","lemma":"sucer","pos":"VER"} ,
		{"word":"sue","word_nosc":"sue","lemma":"savoir","pos":"VER"} ,
		{"word":"sue","word_nosc":"sue","lemma":"suer","pos":"VER"} ,
		{"word":"suent","word_nosc":"suent","lemma":"suer","pos":"VER"} ,
		{"word":"suer","word_nosc":"suer","lemma":"suer","pos":"VER"} ,
		{"word":"suerai","word_nosc":"suerai","lemma":"suer","pos":"VER"} ,
		{"word":"suerais","word_nosc":"suerais","lemma":"suer","pos":"VER"} ,
		{"word":"suerait","word_nosc":"suerait","lemma":"suer","pos":"VER"} ,
		{"word":"sues","word_nosc":"sues","lemma":"savoir","pos":"VER"} ,
		{"word":"sues","word_nosc":"sues","lemma":"suer","pos":"VER"} ,
		{"word":"suez","word_nosc":"suez","lemma":"suer","pos":"VER"} ,
		{"word":"suffi","word_nosc":"suffi","lemma":"suffire","pos":"VER"} ,
		{"word":"suffira","word_nosc":"suffira","lemma":"suffire","pos":"VER"} ,
		{"word":"suffirai","word_nosc":"suffirai","lemma":"suffire","pos":"VER"} ,
		{"word":"suffiraient","word_nosc":"suffiraient","lemma":"suffire","pos":"VER"} ,
		{"word":"suffirais","word_nosc":"suffirais","lemma":"suffire","pos":"VER"} ,
		{"word":"suffirait","word_nosc":"suffirait","lemma":"suffire","pos":"VER"} ,
		{"word":"suffire","word_nosc":"suffire","lemma":"suffire","pos":"VER"} ,
		{"word":"suffirent","word_nosc":"suffirent","lemma":"suffire","pos":"VER"} ,
		{"word":"suffiront","word_nosc":"suffiront","lemma":"suffire","pos":"VER"} ,
		{"word":"suffis","word_nosc":"suffis","lemma":"suffire","pos":"VER"} ,
		{"word":"suffisaient","word_nosc":"suffisaient","lemma":"suffire","pos":"VER"} ,
		{"word":"suffisais","word_nosc":"suffisais","lemma":"suffire","pos":"VER"} ,
		{"word":"suffisait","word_nosc":"suffisait","lemma":"suffire","pos":"VER"} ,
		{"word":"suffisant","word_nosc":"suffisant","lemma":"suffire","pos":"VER"} ,
		{"word":"suffise","word_nosc":"suffise","lemma":"suffire","pos":"VER"} ,
		{"word":"suffisent","word_nosc":"suffisent","lemma":"suffire","pos":"VER"} ,
		{"word":"suffisez","word_nosc":"suffisez","lemma":"suffire","pos":"VER"} ,
		{"word":"suffisions","word_nosc":"suffisions","lemma":"suffire","pos":"VER"} ,
		{"word":"suffisons","word_nosc":"suffisons","lemma":"suffire","pos":"VER"} ,
		{"word":"suffit","word_nosc":"suffit","lemma":"suffire","pos":"VER"} ,
		{"word":"suffoqua","word_nosc":"suffoqua","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoquai","word_nosc":"suffoquai","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoquaient","word_nosc":"suffoquaient","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoquais","word_nosc":"suffoquais","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoquait","word_nosc":"suffoquait","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoquant","word_nosc":"suffoquant","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoque","word_nosc":"suffoque","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoquent","word_nosc":"suffoquent","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoquer","word_nosc":"suffoquer","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoquera","word_nosc":"suffoquera","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoquerait","word_nosc":"suffoquerait","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoqueront","word_nosc":"suffoqueront","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoquez","word_nosc":"suffoquez","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoquions","word_nosc":"suffoquions","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoquons","word_nosc":"suffoquons","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoquèrent","word_nosc":"suffoquerent","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoqué","word_nosc":"suffoque","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoquée","word_nosc":"suffoquee","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffoqués","word_nosc":"suffoques","lemma":"suffoquer","pos":"VER"} ,
		{"word":"suffît","word_nosc":"suffit","lemma":"suffire","pos":"VER"} ,
		{"word":"suggestionner","word_nosc":"suggestionner","lemma":"suggestionner","pos":"VER"} ,
		{"word":"suggestionné","word_nosc":"suggestionne","lemma":"suggestionner","pos":"VER"} ,
		{"word":"suggère","word_nosc":"suggere","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggèrent","word_nosc":"suggerent","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggères","word_nosc":"suggeres","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggéra","word_nosc":"suggera","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérai","word_nosc":"suggerai","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggéraient","word_nosc":"suggeraient","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérais","word_nosc":"suggerais","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérait","word_nosc":"suggerait","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérant","word_nosc":"suggerant","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérer","word_nosc":"suggerer","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérera","word_nosc":"suggerera","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérerai","word_nosc":"suggererai","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérerais","word_nosc":"suggererais","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérerait","word_nosc":"suggererait","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérez","word_nosc":"suggerez","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggériez","word_nosc":"suggeriez","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérions","word_nosc":"suggerions","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérons","word_nosc":"suggerons","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérât","word_nosc":"suggerat","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérèrent","word_nosc":"suggererent","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggéré","word_nosc":"suggere","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérée","word_nosc":"suggeree","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérées","word_nosc":"suggerees","lemma":"suggérer","pos":"VER"} ,
		{"word":"suggérés","word_nosc":"suggeres","lemma":"suggérer","pos":"VER"} ,
		{"word":"suicida","word_nosc":"suicida","lemma":"suicider","pos":"VER"} ,
		{"word":"suicidaient","word_nosc":"suicidaient","lemma":"suicider","pos":"VER"} ,
		{"word":"suicidait","word_nosc":"suicidait","lemma":"suicider","pos":"VER"} ,
		{"word":"suicide","word_nosc":"suicide","lemma":"suicider","pos":"VER"} ,
		{"word":"suicident","word_nosc":"suicident","lemma":"suicider","pos":"VER"} ,
		{"word":"suicider","word_nosc":"suicider","lemma":"suicider","pos":"VER"} ,
		{"word":"suicidera","word_nosc":"suicidera","lemma":"suicider","pos":"VER"} ,
		{"word":"suiciderai","word_nosc":"suiciderai","lemma":"suicider","pos":"VER"} ,
		{"word":"suiciderais","word_nosc":"suiciderais","lemma":"suicider","pos":"VER"} ,
		{"word":"suiciderait","word_nosc":"suiciderait","lemma":"suicider","pos":"VER"} ,
		{"word":"suiciderez","word_nosc":"suiciderez","lemma":"suicider","pos":"VER"} ,
		{"word":"suicideriez","word_nosc":"suicideriez","lemma":"suicider","pos":"VER"} ,
		{"word":"suicides","word_nosc":"suicides","lemma":"suicider","pos":"VER"} ,
		{"word":"suicidez","word_nosc":"suicidez","lemma":"suicider","pos":"VER"} ,
		{"word":"suicidiez","word_nosc":"suicidiez","lemma":"suicider","pos":"VER"} ,
		{"word":"suicidons","word_nosc":"suicidons","lemma":"suicider","pos":"VER"} ,
		{"word":"suicidât","word_nosc":"suicidat","lemma":"suicider","pos":"VER"} ,
		{"word":"suicidèrent","word_nosc":"suiciderent","lemma":"suicider","pos":"VER"} ,
		{"word":"suicidé","word_nosc":"suicide","lemma":"suicider","pos":"VER"} ,
		{"word":"suicidée","word_nosc":"suicidee","lemma":"suicider","pos":"VER"} ,
		{"word":"suicidées","word_nosc":"suicidees","lemma":"suicider","pos":"VER"} ,
		{"word":"suicidés","word_nosc":"suicides","lemma":"suicider","pos":"VER"} ,
		{"word":"suifer","word_nosc":"suifer","lemma":"suifer","pos":"VER"} ,
		{"word":"suiffé","word_nosc":"suiffe","lemma":"suiffer","pos":"VER"} ,
		{"word":"suiffée","word_nosc":"suiffee","lemma":"suiffer","pos":"VER"} ,
		{"word":"suiffés","word_nosc":"suiffes","lemma":"suiffer","pos":"VER"} ,
		{"word":"suifés","word_nosc":"suifes","lemma":"suifer","pos":"VER"} ,
		{"word":"suinta","word_nosc":"suinta","lemma":"suinter","pos":"VER"} ,
		{"word":"suintaient","word_nosc":"suintaient","lemma":"suinter","pos":"VER"} ,
		{"word":"suintait","word_nosc":"suintait","lemma":"suinter","pos":"VER"} ,
		{"word":"suintant","word_nosc":"suintant","lemma":"suinter","pos":"VER"} ,
		{"word":"suinte","word_nosc":"suinte","lemma":"suinter","pos":"VER"} ,
		{"word":"suintent","word_nosc":"suintent","lemma":"suinter","pos":"VER"} ,
		{"word":"suinter","word_nosc":"suinter","lemma":"suinter","pos":"VER"} ,
		{"word":"suinté","word_nosc":"suinte","lemma":"suinter","pos":"VER"} ,
		{"word":"suis","word_nosc":"suis","lemma":"suivre","pos":"VER"} ,
		{"word":"suis","word_nosc":"suis","lemma":"être","pos":"VER"} ,
		{"word":"suit","word_nosc":"suit","lemma":"suivre","pos":"VER"} ,
		{"word":"suivaient","word_nosc":"suivaient","lemma":"suivre","pos":"VER"} ,
		{"word":"suivais","word_nosc":"suivais","lemma":"suivre","pos":"VER"} ,
		{"word":"suivait","word_nosc":"suivait","lemma":"suivre","pos":"VER"} ,
		{"word":"suivant","word_nosc":"suivant","lemma":"suivre","pos":"VER"} ,
		{"word":"suive","word_nosc":"suive","lemma":"suivre","pos":"VER"} ,
		{"word":"suivent","word_nosc":"suivent","lemma":"suivre","pos":"VER"} ,
		{"word":"suives","word_nosc":"suives","lemma":"suivre","pos":"VER"} ,
		{"word":"suivez","word_nosc":"suivez","lemma":"suivre","pos":"VER"} ,
		{"word":"suivi","word_nosc":"suivi","lemma":"suivre","pos":"VER"} ,
		{"word":"suivie","word_nosc":"suivie","lemma":"suivre","pos":"VER"} ,
		{"word":"suivies","word_nosc":"suivies","lemma":"suivre","pos":"VER"} ,
		{"word":"suiviez","word_nosc":"suiviez","lemma":"suivre","pos":"VER"} ,
		{"word":"suivions","word_nosc":"suivions","lemma":"suivre","pos":"VER"} ,
		{"word":"suivirent","word_nosc":"suivirent","lemma":"suivre","pos":"VER"} ,
		{"word":"suivis","word_nosc":"suivis","lemma":"suivre","pos":"VER"} ,
		{"word":"suivissent","word_nosc":"suivissent","lemma":"suivre","pos":"VER"} ,
		{"word":"suivit","word_nosc":"suivit","lemma":"suivre","pos":"VER"} ,
		{"word":"suivons","word_nosc":"suivons","lemma":"suivre","pos":"VER"} ,
		{"word":"suivra","word_nosc":"suivra","lemma":"suivre","pos":"VER"} ,
		{"word":"suivrai","word_nosc":"suivrai","lemma":"suivre","pos":"VER"} ,
		{"word":"suivraient","word_nosc":"suivraient","lemma":"suivre","pos":"VER"} ,
		{"word":"suivrais","word_nosc":"suivrais","lemma":"suivre","pos":"VER"} ,
		{"word":"suivrait","word_nosc":"suivrait","lemma":"suivre","pos":"VER"} ,
		{"word":"suivras","word_nosc":"suivras","lemma":"suivre","pos":"VER"} ,
		{"word":"suivre","word_nosc":"suivre","lemma":"suivre","pos":"VER"} ,
		{"word":"suivrez","word_nosc":"suivrez","lemma":"suivre","pos":"VER"} ,
		{"word":"suivriez","word_nosc":"suivriez","lemma":"suivre","pos":"VER"} ,
		{"word":"suivrions","word_nosc":"suivrions","lemma":"suivre","pos":"VER"} ,
		{"word":"suivrons","word_nosc":"suivrons","lemma":"suivre","pos":"VER"} ,
		{"word":"suivront","word_nosc":"suivront","lemma":"suivre","pos":"VER"} ,
		{"word":"suivîmes","word_nosc":"suivimes","lemma":"suivre","pos":"VER"} ,
		{"word":"suivît","word_nosc":"suivit","lemma":"suivre","pos":"VER"} ,
		{"word":"sulfatant","word_nosc":"sulfatant","lemma":"sulfater","pos":"VER"} ,
		{"word":"sulfate","word_nosc":"sulfate","lemma":"sulfater","pos":"VER"} ,
		{"word":"sulfater","word_nosc":"sulfater","lemma":"sulfater","pos":"VER"} ,
		{"word":"sulfonée","word_nosc":"sulfonee","lemma":"sulfoner","pos":"VER"} ,
		{"word":"sulfonés","word_nosc":"sulfones","lemma":"sulfoner","pos":"VER"} ,
		{"word":"suons","word_nosc":"suons","lemma":"suer","pos":"VER"} ,
		{"word":"superposa","word_nosc":"superposa","lemma":"superposer","pos":"VER"} ,
		{"word":"superposaient","word_nosc":"superposaient","lemma":"superposer","pos":"VER"} ,
		{"word":"superposait","word_nosc":"superposait","lemma":"superposer","pos":"VER"} ,
		{"word":"superposant","word_nosc":"superposant","lemma":"superposer","pos":"VER"} ,
		{"word":"superpose","word_nosc":"superpose","lemma":"superposer","pos":"VER"} ,
		{"word":"superposent","word_nosc":"superposent","lemma":"superposer","pos":"VER"} ,
		{"word":"superposer","word_nosc":"superposer","lemma":"superposer","pos":"VER"} ,
		{"word":"superposez","word_nosc":"superposez","lemma":"superposer","pos":"VER"} ,
		{"word":"superposé","word_nosc":"superpose","lemma":"superposer","pos":"VER"} ,
		{"word":"superposée","word_nosc":"superposee","lemma":"superposer","pos":"VER"} ,
		{"word":"superposées","word_nosc":"superposees","lemma":"superposer","pos":"VER"} ,
		{"word":"superposés","word_nosc":"superposes","lemma":"superposer","pos":"VER"} ,
		{"word":"supervisa","word_nosc":"supervisa","lemma":"superviser","pos":"VER"} ,
		{"word":"supervisais","word_nosc":"supervisais","lemma":"superviser","pos":"VER"} ,
		{"word":"supervisait","word_nosc":"supervisait","lemma":"superviser","pos":"VER"} ,
		{"word":"supervisant","word_nosc":"supervisant","lemma":"superviser","pos":"VER"} ,
		{"word":"supervise","word_nosc":"supervise","lemma":"superviser","pos":"VER"} ,
		{"word":"supervisent","word_nosc":"supervisent","lemma":"superviser","pos":"VER"} ,
		{"word":"superviser","word_nosc":"superviser","lemma":"superviser","pos":"VER"} ,
		{"word":"supervisera","word_nosc":"supervisera","lemma":"superviser","pos":"VER"} ,
		{"word":"superviserai","word_nosc":"superviserai","lemma":"superviser","pos":"VER"} ,
		{"word":"superviserais","word_nosc":"superviserais","lemma":"superviser","pos":"VER"} ,
		{"word":"superviserait","word_nosc":"superviserait","lemma":"superviser","pos":"VER"} ,
		{"word":"superviseras","word_nosc":"superviseras","lemma":"superviser","pos":"VER"} ,
		{"word":"superviserez","word_nosc":"superviserez","lemma":"superviser","pos":"VER"} ,
		{"word":"superviserons","word_nosc":"superviserons","lemma":"superviser","pos":"VER"} ,
		{"word":"superviseront","word_nosc":"superviseront","lemma":"superviser","pos":"VER"} ,
		{"word":"supervisez","word_nosc":"supervisez","lemma":"superviser","pos":"VER"} ,
		{"word":"supervisiez","word_nosc":"supervisiez","lemma":"superviser","pos":"VER"} ,
		{"word":"supervisons","word_nosc":"supervisons","lemma":"superviser","pos":"VER"} ,
		{"word":"supervisé","word_nosc":"supervise","lemma":"superviser","pos":"VER"} ,
		{"word":"supervisée","word_nosc":"supervisee","lemma":"superviser","pos":"VER"} ,
		{"word":"supervisées","word_nosc":"supervisees","lemma":"superviser","pos":"VER"} ,
		{"word":"supervisés","word_nosc":"supervises","lemma":"superviser","pos":"VER"} ,
		{"word":"supplanta","word_nosc":"supplanta","lemma":"supplanter","pos":"VER"} ,
		{"word":"supplantait","word_nosc":"supplantait","lemma":"supplanter","pos":"VER"} ,
		{"word":"supplantant","word_nosc":"supplantant","lemma":"supplanter","pos":"VER"} ,
		{"word":"supplante","word_nosc":"supplante","lemma":"supplanter","pos":"VER"} ,
		{"word":"supplantent","word_nosc":"supplantent","lemma":"supplanter","pos":"VER"} ,
		{"word":"supplanter","word_nosc":"supplanter","lemma":"supplanter","pos":"VER"} ,
		{"word":"supplanté","word_nosc":"supplante","lemma":"supplanter","pos":"VER"} ,
		{"word":"supplantée","word_nosc":"supplantee","lemma":"supplanter","pos":"VER"} ,
		{"word":"supplia","word_nosc":"supplia","lemma":"supplier","pos":"VER"} ,
		{"word":"suppliai","word_nosc":"suppliai","lemma":"supplier","pos":"VER"} ,
		{"word":"suppliaient","word_nosc":"suppliaient","lemma":"supplier","pos":"VER"} ,
		{"word":"suppliais","word_nosc":"suppliais","lemma":"supplier","pos":"VER"} ,
		{"word":"suppliait","word_nosc":"suppliait","lemma":"supplier","pos":"VER"} ,
		{"word":"suppliant","word_nosc":"suppliant","lemma":"supplier","pos":"VER"} ,
		{"word":"supplicia","word_nosc":"supplicia","lemma":"supplicier","pos":"VER"} ,
		{"word":"supplicient","word_nosc":"supplicient","lemma":"supplicier","pos":"VER"} ,
		{"word":"supplicier","word_nosc":"supplicier","lemma":"supplicier","pos":"VER"} ,
		{"word":"supplicierez","word_nosc":"supplicierez","lemma":"supplicier","pos":"VER"} ,
		{"word":"supplicié","word_nosc":"supplicie","lemma":"supplicier","pos":"VER"} ,
		{"word":"suppliciées","word_nosc":"suppliciees","lemma":"supplicier","pos":"VER"} ,
		{"word":"suppliciés","word_nosc":"supplicies","lemma":"supplicier","pos":"VER"} ,
		{"word":"supplie","word_nosc":"supplie","lemma":"supplier","pos":"VER"} ,
		{"word":"supplient","word_nosc":"supplient","lemma":"supplier","pos":"VER"} ,
		{"word":"supplier","word_nosc":"supplier","lemma":"supplier","pos":"VER"} ,
		{"word":"suppliera","word_nosc":"suppliera","lemma":"supplier","pos":"VER"} ,
		{"word":"supplierai","word_nosc":"supplierai","lemma":"supplier","pos":"VER"} ,
		{"word":"supplieraient","word_nosc":"supplieraient","lemma":"supplier","pos":"VER"} ,
		{"word":"supplierais","word_nosc":"supplierais","lemma":"supplier","pos":"VER"} ,
		{"word":"supplierait","word_nosc":"supplierait","lemma":"supplier","pos":"VER"} ,
		{"word":"supplieras","word_nosc":"supplieras","lemma":"supplier","pos":"VER"} ,
		{"word":"supplierez","word_nosc":"supplierez","lemma":"supplier","pos":"VER"} ,
		{"word":"supplieriez","word_nosc":"supplieriez","lemma":"supplier","pos":"VER"} ,
		{"word":"supplieront","word_nosc":"supplieront","lemma":"supplier","pos":"VER"} ,
		{"word":"supplies","word_nosc":"supplies","lemma":"supplier","pos":"VER"} ,
		{"word":"suppliez","word_nosc":"suppliez","lemma":"supplier","pos":"VER"} ,
		{"word":"supplions","word_nosc":"supplions","lemma":"supplier","pos":"VER"} ,
		{"word":"suppliât","word_nosc":"suppliat","lemma":"supplier","pos":"VER"} ,
		{"word":"supplié","word_nosc":"supplie","lemma":"supplier","pos":"VER"} ,
		{"word":"suppliée","word_nosc":"suppliee","lemma":"supplier","pos":"VER"} ,
		{"word":"suppliés","word_nosc":"supplies","lemma":"supplier","pos":"VER"} ,
		{"word":"suppléaient","word_nosc":"suppleaient","lemma":"suppléer","pos":"VER"} ,
		{"word":"suppléait","word_nosc":"suppleait","lemma":"suppléer","pos":"VER"} ,
		{"word":"suppléant","word_nosc":"suppleant","lemma":"suppléer","pos":"VER"} ,
		{"word":"supplée","word_nosc":"supplee","lemma":"suppléer","pos":"VER"} ,
		{"word":"suppléent","word_nosc":"suppleent","lemma":"suppléer","pos":"VER"} ,
		{"word":"suppléer","word_nosc":"suppleer","lemma":"suppléer","pos":"VER"} ,
		{"word":"suppléeraient","word_nosc":"suppleeraient","lemma":"suppléer","pos":"VER"} ,
		{"word":"suppléerait","word_nosc":"suppleerait","lemma":"suppléer","pos":"VER"} ,
		{"word":"suppléerez","word_nosc":"suppleerez","lemma":"suppléer","pos":"VER"} ,
		{"word":"supplémenter","word_nosc":"supplementer","lemma":"supplémenter","pos":"VER"} ,
		{"word":"supplémentées","word_nosc":"supplementees","lemma":"supplémenter","pos":"VER"} ,
		{"word":"suppléé","word_nosc":"supplee","lemma":"suppléer","pos":"VER"} ,
		{"word":"supporta","word_nosc":"supporta","lemma":"supporter","pos":"VER"} ,
		{"word":"supportai","word_nosc":"supportai","lemma":"supporter","pos":"VER"} ,
		{"word":"supportaient","word_nosc":"supportaient","lemma":"supporter","pos":"VER"} ,
		{"word":"supportais","word_nosc":"supportais","lemma":"supporter","pos":"VER"} ,
		{"word":"supportait","word_nosc":"supportait","lemma":"supporter","pos":"VER"} ,
		{"word":"supportant","word_nosc":"supportant","lemma":"supporter","pos":"VER"} ,
		{"word":"supporte","word_nosc":"supporte","lemma":"supporter","pos":"VER"} ,
		{"word":"supportent","word_nosc":"supportent","lemma":"supporter","pos":"VER"} ,
		{"word":"supporter","word_nosc":"supporter","lemma":"supporter","pos":"VER"} ,
		{"word":"supportera","word_nosc":"supportera","lemma":"supporter","pos":"VER"} ,
		{"word":"supporterai","word_nosc":"supporterai","lemma":"supporter","pos":"VER"} ,
		{"word":"supporteraient","word_nosc":"supporteraient","lemma":"supporter","pos":"VER"} ,
		{"word":"supporterais","word_nosc":"supporterais","lemma":"supporter","pos":"VER"} ,
		{"word":"supporterait","word_nosc":"supporterait","lemma":"supporter","pos":"VER"} ,
		{"word":"supporteras","word_nosc":"supporteras","lemma":"supporter","pos":"VER"} ,
		{"word":"supporterez","word_nosc":"supporterez","lemma":"supporter","pos":"VER"} ,
		{"word":"supporteriez","word_nosc":"supporteriez","lemma":"supporter","pos":"VER"} ,
		{"word":"supporterons","word_nosc":"supporterons","lemma":"supporter","pos":"VER"} ,
		{"word":"supporteront","word_nosc":"supporteront","lemma":"supporter","pos":"VER"} ,
		{"word":"supportes","word_nosc":"supportes","lemma":"supporter","pos":"VER"} ,
		{"word":"supportez","word_nosc":"supportez","lemma":"supporter","pos":"VER"} ,
		{"word":"supportiez","word_nosc":"supportiez","lemma":"supporter","pos":"VER"} ,
		{"word":"supportions","word_nosc":"supportions","lemma":"supporter","pos":"VER"} ,
		{"word":"supportons","word_nosc":"supportons","lemma":"supporter","pos":"VER"} ,
		{"word":"supportât","word_nosc":"supportat","lemma":"supporter","pos":"VER"} ,
		{"word":"supportèrent","word_nosc":"supporterent","lemma":"supporter","pos":"VER"} ,
		{"word":"supporté","word_nosc":"supporte","lemma":"supporter","pos":"VER"} ,
		{"word":"supportée","word_nosc":"supportee","lemma":"supporter","pos":"VER"} ,
		{"word":"supportées","word_nosc":"supportees","lemma":"supporter","pos":"VER"} ,
		{"word":"supportés","word_nosc":"supportes","lemma":"supporter","pos":"VER"} ,
		{"word":"supposa","word_nosc":"supposa","lemma":"supposer","pos":"VER"} ,
		{"word":"supposai","word_nosc":"supposai","lemma":"supposer","pos":"VER"} ,
		{"word":"supposaient","word_nosc":"supposaient","lemma":"supposer","pos":"VER"} ,
		{"word":"supposais","word_nosc":"supposais","lemma":"supposer","pos":"VER"} ,
		{"word":"supposait","word_nosc":"supposait","lemma":"supposer","pos":"VER"} ,
		{"word":"supposant","word_nosc":"supposant","lemma":"supposer","pos":"VER"} ,
		{"word":"suppose","word_nosc":"suppose","lemma":"supposer","pos":"VER"} ,
		{"word":"supposent","word_nosc":"supposent","lemma":"supposer","pos":"VER"} ,
		{"word":"supposer","word_nosc":"supposer","lemma":"supposer","pos":"VER"} ,
		{"word":"supposera","word_nosc":"supposera","lemma":"supposer","pos":"VER"} ,
		{"word":"supposerai","word_nosc":"supposerai","lemma":"supposer","pos":"VER"} ,
		{"word":"supposerait","word_nosc":"supposerait","lemma":"supposer","pos":"VER"} ,
		{"word":"supposeront","word_nosc":"supposeront","lemma":"supposer","pos":"VER"} ,
		{"word":"supposes","word_nosc":"supposes","lemma":"supposer","pos":"VER"} ,
		{"word":"supposez","word_nosc":"supposez","lemma":"supposer","pos":"VER"} ,
		{"word":"supposiez","word_nosc":"supposiez","lemma":"supposer","pos":"VER"} ,
		{"word":"supposions","word_nosc":"supposions","lemma":"supposer","pos":"VER"} ,
		{"word":"supposons","word_nosc":"supposons","lemma":"supposer","pos":"VER"} ,
		{"word":"supposâmes","word_nosc":"supposames","lemma":"supposer","pos":"VER"} ,
		{"word":"supposèrent","word_nosc":"supposerent","lemma":"supposer","pos":"VER"} ,
		{"word":"supposé","word_nosc":"suppose","lemma":"supposer","pos":"VER"} ,
		{"word":"supposée","word_nosc":"supposee","lemma":"supposer","pos":"VER"} ,
		{"word":"supposées","word_nosc":"supposees","lemma":"supposer","pos":"VER"} ,
		{"word":"supposés","word_nosc":"supposes","lemma":"supposer","pos":"VER"} ,
		{"word":"supprima","word_nosc":"supprima","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimai","word_nosc":"supprimai","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimaient","word_nosc":"supprimaient","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimais","word_nosc":"supprimais","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimait","word_nosc":"supprimait","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimant","word_nosc":"supprimant","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprime","word_nosc":"supprime","lemma":"supprimer","pos":"VER"} ,
		{"word":"suppriment","word_nosc":"suppriment","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimer","word_nosc":"supprimer","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimera","word_nosc":"supprimera","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimerai","word_nosc":"supprimerai","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimeraient","word_nosc":"supprimeraient","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimerait","word_nosc":"supprimerait","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimerons","word_nosc":"supprimerons","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimez","word_nosc":"supprimez","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimons","word_nosc":"supprimons","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimât","word_nosc":"supprimat","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimé","word_nosc":"supprime","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimée","word_nosc":"supprimee","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimées","word_nosc":"supprimees","lemma":"supprimer","pos":"VER"} ,
		{"word":"supprimés","word_nosc":"supprimes","lemma":"supprimer","pos":"VER"} ,
		{"word":"suppuraient","word_nosc":"suppuraient","lemma":"suppurer","pos":"VER"} ,
		{"word":"suppurait","word_nosc":"suppurait","lemma":"suppurer","pos":"VER"} ,
		{"word":"suppurant","word_nosc":"suppurant","lemma":"suppurer","pos":"VER"} ,
		{"word":"suppure","word_nosc":"suppure","lemma":"suppurer","pos":"VER"} ,
		{"word":"suppurent","word_nosc":"suppurent","lemma":"suppurer","pos":"VER"} ,
		{"word":"suppurer","word_nosc":"suppurer","lemma":"suppurer","pos":"VER"} ,
		{"word":"suppurerait","word_nosc":"suppurerait","lemma":"suppurer","pos":"VER"} ,
		{"word":"supputa","word_nosc":"supputa","lemma":"supputer","pos":"VER"} ,
		{"word":"supputai","word_nosc":"supputai","lemma":"supputer","pos":"VER"} ,
		{"word":"supputaient","word_nosc":"supputaient","lemma":"supputer","pos":"VER"} ,
		{"word":"supputais","word_nosc":"supputais","lemma":"supputer","pos":"VER"} ,
		{"word":"supputait","word_nosc":"supputait","lemma":"supputer","pos":"VER"} ,
		{"word":"supputant","word_nosc":"supputant","lemma":"supputer","pos":"VER"} ,
		{"word":"suppute","word_nosc":"suppute","lemma":"supputer","pos":"VER"} ,
		{"word":"supputent","word_nosc":"supputent","lemma":"supputer","pos":"VER"} ,
		{"word":"supputer","word_nosc":"supputer","lemma":"supputer","pos":"VER"} ,
		{"word":"supputé","word_nosc":"suppute","lemma":"supputer","pos":"VER"} ,
		{"word":"supputées","word_nosc":"supputees","lemma":"supputer","pos":"VER"} ,
		{"word":"surabondaient","word_nosc":"surabondaient","lemma":"surabonder","pos":"VER"} ,
		{"word":"surabondent","word_nosc":"surabondent","lemma":"surabonder","pos":"VER"} ,
		{"word":"suractiver","word_nosc":"suractiver","lemma":"suractiver","pos":"VER"} ,
		{"word":"suradapté","word_nosc":"suradapte","lemma":"suradapté","pos":"VER"} ,
		{"word":"surajoute","word_nosc":"surajoute","lemma":"surajouter","pos":"VER"} ,
		{"word":"surajouter","word_nosc":"surajouter","lemma":"surajouter","pos":"VER"} ,
		{"word":"surajouté","word_nosc":"surajoute","lemma":"surajouter","pos":"VER"} ,
		{"word":"surajoutée","word_nosc":"surajoutee","lemma":"surajouter","pos":"VER"} ,
		{"word":"surajoutés","word_nosc":"surajoutes","lemma":"surajouter","pos":"VER"} ,
		{"word":"suralimenter","word_nosc":"suralimenter","lemma":"suralimenter","pos":"VER"} ,
		{"word":"suralimentes","word_nosc":"suralimentes","lemma":"suralimenter","pos":"VER"} ,
		{"word":"suralimentés","word_nosc":"suralimentes","lemma":"suralimenter","pos":"VER"} ,
		{"word":"surarmé","word_nosc":"surarme","lemma":"surarmer","pos":"VER"} ,
		{"word":"surarmée","word_nosc":"surarmee","lemma":"surarmer","pos":"VER"} ,
		{"word":"surarmés","word_nosc":"surarmes","lemma":"surarmer","pos":"VER"} ,
		{"word":"surbaissé","word_nosc":"surbaisse","lemma":"surbaisser","pos":"VER"} ,
		{"word":"surbaissée","word_nosc":"surbaissee","lemma":"surbaisser","pos":"VER"} ,
		{"word":"surcharge","word_nosc":"surcharge","lemma":"surcharger","pos":"VER"} ,
		{"word":"surchargeaient","word_nosc":"surchargeaient","lemma":"surcharger","pos":"VER"} ,
		{"word":"surchargeait","word_nosc":"surchargeait","lemma":"surcharger","pos":"VER"} ,
		{"word":"surchargeant","word_nosc":"surchargeant","lemma":"surcharger","pos":"VER"} ,
		{"word":"surcharger","word_nosc":"surcharger","lemma":"surcharger","pos":"VER"} ,
		{"word":"surcharges","word_nosc":"surcharges","lemma":"surcharger","pos":"VER"} ,
		{"word":"surchargé","word_nosc":"surcharge","lemma":"surcharger","pos":"VER"} ,
		{"word":"surchargée","word_nosc":"surchargee","lemma":"surcharger","pos":"VER"} ,
		{"word":"surchargées","word_nosc":"surchargees","lemma":"surcharger","pos":"VER"} ,
		{"word":"surchargés","word_nosc":"surcharges","lemma":"surcharger","pos":"VER"} ,
		{"word":"surchauffait","word_nosc":"surchauffait","lemma":"surchauffer","pos":"VER"} ,
		{"word":"surchauffe","word_nosc":"surchauffe","lemma":"surchauffer","pos":"VER"} ,
		{"word":"surchauffer","word_nosc":"surchauffer","lemma":"surchauffer","pos":"VER"} ,
		{"word":"surchauffez","word_nosc":"surchauffez","lemma":"surchauffer","pos":"VER"} ,
		{"word":"surchauffé","word_nosc":"surchauffe","lemma":"surchauffer","pos":"VER"} ,
		{"word":"surchauffée","word_nosc":"surchauffee","lemma":"surchauffer","pos":"VER"} ,
		{"word":"surchauffées","word_nosc":"surchauffees","lemma":"surchauffer","pos":"VER"} ,
		{"word":"surchauffés","word_nosc":"surchauffes","lemma":"surchauffer","pos":"VER"} ,
		{"word":"surclassait","word_nosc":"surclassait","lemma":"surclasser","pos":"VER"} ,
		{"word":"surclassant","word_nosc":"surclassant","lemma":"surclasser","pos":"VER"} ,
		{"word":"surclasse","word_nosc":"surclasse","lemma":"surclasser","pos":"VER"} ,
		{"word":"surclassent","word_nosc":"surclassent","lemma":"surclasser","pos":"VER"} ,
		{"word":"surclasser","word_nosc":"surclasser","lemma":"surclasser","pos":"VER"} ,
		{"word":"surclasserais","word_nosc":"surclasserais","lemma":"surclasser","pos":"VER"} ,
		{"word":"surclasserait","word_nosc":"surclasserait","lemma":"surclasser","pos":"VER"} ,
		{"word":"surclasseras","word_nosc":"surclasseras","lemma":"surclasser","pos":"VER"} ,
		{"word":"surclassé","word_nosc":"surclasse","lemma":"surclasser","pos":"VER"} ,
		{"word":"surclassés","word_nosc":"surclasses","lemma":"surclasser","pos":"VER"} ,
		{"word":"surcompenser","word_nosc":"surcompenser","lemma":"surcompenser","pos":"VER"} ,
		{"word":"surcompensé","word_nosc":"surcompense","lemma":"surcompenser","pos":"VER"} ,
		{"word":"surcomprimée","word_nosc":"surcomprimee","lemma":"surcomprimer","pos":"VER"} ,
		{"word":"surconsomme","word_nosc":"surconsomme","lemma":"surconsommer","pos":"VER"} ,
		{"word":"surcoupé","word_nosc":"surcoupe","lemma":"surcouper","pos":"VER"} ,
		{"word":"surdoré","word_nosc":"surdore","lemma":"surdorer","pos":"VER"} ,
		{"word":"surdorée","word_nosc":"surdoree","lemma":"surdorer","pos":"VER"} ,
		{"word":"surdéterminé","word_nosc":"surdetermine","lemma":"surdéterminer","pos":"VER"} ,
		{"word":"surdévelopper","word_nosc":"surdevelopper","lemma":"surdévelopper","pos":"VER"} ,
		{"word":"surenchéri","word_nosc":"surencheri","lemma":"surenchérir","pos":"VER"} ,
		{"word":"surenchérir","word_nosc":"surencherir","lemma":"surenchérir","pos":"VER"} ,
		{"word":"surenchérissant","word_nosc":"surencherissant","lemma":"surenchérir","pos":"VER"} ,
		{"word":"surenchérissent","word_nosc":"surencherissent","lemma":"surenchérir","pos":"VER"} ,
		{"word":"surenchérit","word_nosc":"surencherit","lemma":"surenchérir","pos":"VER"} ,
		{"word":"surent","word_nosc":"surent","lemma":"savoir","pos":"VER"} ,
		{"word":"surentraînées","word_nosc":"surentrainees","lemma":"surentraîner","pos":"VER"} ,
		{"word":"surentraînés","word_nosc":"surentraines","lemma":"surentraîner","pos":"VER"} ,
		{"word":"surestime","word_nosc":"surestime","lemma":"surestimer","pos":"VER"} ,
		{"word":"surestimer","word_nosc":"surestimer","lemma":"surestimer","pos":"VER"} ,
		{"word":"surestimerais","word_nosc":"surestimerais","lemma":"surestimer","pos":"VER"} ,
		{"word":"surestimez","word_nosc":"surestimez","lemma":"surestimer","pos":"VER"} ,
		{"word":"surestimé","word_nosc":"surestime","lemma":"surestimer","pos":"VER"} ,
		{"word":"surestimée","word_nosc":"surestimee","lemma":"surestimer","pos":"VER"} ,
		{"word":"surestimés","word_nosc":"surestimes","lemma":"surestimer","pos":"VER"} ,
		{"word":"surexcitait","word_nosc":"surexcitait","lemma":"surexciter","pos":"VER"} ,
		{"word":"surexcitant","word_nosc":"surexcitant","lemma":"surexciter","pos":"VER"} ,
		{"word":"surexcite","word_nosc":"surexcite","lemma":"surexciter","pos":"VER"} ,
		{"word":"surexciter","word_nosc":"surexciter","lemma":"surexciter","pos":"VER"} ,
		{"word":"surexcité","word_nosc":"surexcite","lemma":"surexciter","pos":"VER"} ,
		{"word":"surexcitée","word_nosc":"surexcitee","lemma":"surexciter","pos":"VER"} ,
		{"word":"surexcitées","word_nosc":"surexcitees","lemma":"surexciter","pos":"VER"} ,
		{"word":"surexcités","word_nosc":"surexcites","lemma":"surexciter","pos":"VER"} ,
		{"word":"surexploité","word_nosc":"surexploite","lemma":"surexploiter","pos":"VER"} ,
		{"word":"surexposé","word_nosc":"surexpose","lemma":"surexposer","pos":"VER"} ,
		{"word":"surexposée","word_nosc":"surexposee","lemma":"surexposer","pos":"VER"} ,
		{"word":"surexposées","word_nosc":"surexposees","lemma":"surexposer","pos":"VER"} ,
		{"word":"surexposés","word_nosc":"surexposes","lemma":"surexposer","pos":"VER"} ,
		{"word":"surfacer","word_nosc":"surfacer","lemma":"surfacer","pos":"VER"} ,
		{"word":"surfacture","word_nosc":"surfacture","lemma":"surfacturer","pos":"VER"} ,
		{"word":"surfacturer","word_nosc":"surfacturer","lemma":"surfacturer","pos":"VER"} ,
		{"word":"surfacturé","word_nosc":"surfacture","lemma":"surfacturer","pos":"VER"} ,
		{"word":"surfacturés","word_nosc":"surfactures","lemma":"surfacturer","pos":"VER"} ,
		{"word":"surfaire","word_nosc":"surfaire","lemma":"surfaire","pos":"VER"} ,
		{"word":"surfais","word_nosc":"surfais","lemma":"surfer","pos":"VER"} ,
		{"word":"surfaisait","word_nosc":"surfaisait","lemma":"surfaire","pos":"VER"} ,
		{"word":"surfait","word_nosc":"surfait","lemma":"surfer","pos":"VER"} ,
		{"word":"surfaite","word_nosc":"surfaite","lemma":"surfaire","pos":"VER"} ,
		{"word":"surfaits","word_nosc":"surfaits","lemma":"surfaire","pos":"VER"} ,
		{"word":"surfant","word_nosc":"surfant","lemma":"surfer","pos":"VER"} ,
		{"word":"surfe","word_nosc":"surfe","lemma":"surfer","pos":"VER"} ,
		{"word":"surfent","word_nosc":"surfent","lemma":"surfer","pos":"VER"} ,
		{"word":"surfer","word_nosc":"surfer","lemma":"surfer","pos":"VER"} ,
		{"word":"surferas","word_nosc":"surferas","lemma":"surfer","pos":"VER"} ,
		{"word":"surfes","word_nosc":"surfes","lemma":"surfer","pos":"VER"} ,
		{"word":"surfez","word_nosc":"surfez","lemma":"surfer","pos":"VER"} ,
		{"word":"surfons","word_nosc":"surfons","lemma":"surfer","pos":"VER"} ,
		{"word":"surfé","word_nosc":"surfe","lemma":"surfer","pos":"VER"} ,
		{"word":"surgeler","word_nosc":"surgeler","lemma":"surgeler","pos":"VER"} ,
		{"word":"surgelé","word_nosc":"surgele","lemma":"surgeler","pos":"VER"} ,
		{"word":"surgelée","word_nosc":"surgelee","lemma":"surgeler","pos":"VER"} ,
		{"word":"surgelées","word_nosc":"surgelees","lemma":"surgeler","pos":"VER"} ,
		{"word":"surgelés","word_nosc":"surgeles","lemma":"surgeler","pos":"VER"} ,
		{"word":"surgeonnent","word_nosc":"surgeonnent","lemma":"surgeonner","pos":"VER"} ,
		{"word":"surgi","word_nosc":"surgi","lemma":"surgir","pos":"VER"} ,
		{"word":"surgie","word_nosc":"surgie","lemma":"surgir","pos":"VER"} ,
		{"word":"surgies","word_nosc":"surgies","lemma":"surgir","pos":"VER"} ,
		{"word":"surgir","word_nosc":"surgir","lemma":"surgir","pos":"VER"} ,
		{"word":"surgira","word_nosc":"surgira","lemma":"surgir","pos":"VER"} ,
		{"word":"surgiraient","word_nosc":"surgiraient","lemma":"surgir","pos":"VER"} ,
		{"word":"surgirait","word_nosc":"surgirait","lemma":"surgir","pos":"VER"} ,
		{"word":"surgirent","word_nosc":"surgirent","lemma":"surgir","pos":"VER"} ,
		{"word":"surgiront","word_nosc":"surgiront","lemma":"surgir","pos":"VER"} ,
		{"word":"surgis","word_nosc":"surgis","lemma":"surgir","pos":"VER"} ,
		{"word":"surgissaient","word_nosc":"surgissaient","lemma":"surgir","pos":"VER"} ,
		{"word":"surgissais","word_nosc":"surgissais","lemma":"surgir","pos":"VER"} ,
		{"word":"surgissait","word_nosc":"surgissait","lemma":"surgir","pos":"VER"} ,
		{"word":"surgissant","word_nosc":"surgissant","lemma":"surgir","pos":"VER"} ,
		{"word":"surgisse","word_nosc":"surgisse","lemma":"surgir","pos":"VER"} ,
		{"word":"surgissent","word_nosc":"surgissent","lemma":"surgir","pos":"VER"} ,
		{"word":"surgissez","word_nosc":"surgissez","lemma":"surgir","pos":"VER"} ,
		{"word":"surgit","word_nosc":"surgit","lemma":"surgir","pos":"VER"} ,
		{"word":"surgèle","word_nosc":"surgele","lemma":"surgeler","pos":"VER"} ,
		{"word":"surgît","word_nosc":"surgit","lemma":"surgir","pos":"VER"} ,
		{"word":"suri","word_nosc":"suri","lemma":"surir","pos":"VER"} ,
		{"word":"surinait","word_nosc":"surinait","lemma":"suriner","pos":"VER"} ,
		{"word":"surine","word_nosc":"surine","lemma":"suriner","pos":"VER"} ,
		{"word":"surinent","word_nosc":"surinent","lemma":"suriner","pos":"VER"} ,
		{"word":"suriner","word_nosc":"suriner","lemma":"suriner","pos":"VER"} ,
		{"word":"surinerais","word_nosc":"surinerais","lemma":"suriner","pos":"VER"} ,
		{"word":"surinformé","word_nosc":"surinforme","lemma":"surinformer","pos":"VER"} ,
		{"word":"suriné","word_nosc":"surine","lemma":"suriner","pos":"VER"} ,
		{"word":"surir","word_nosc":"surir","lemma":"surir","pos":"VER"} ,
		{"word":"surit","word_nosc":"surit","lemma":"surir","pos":"VER"} ,
		{"word":"surligner","word_nosc":"surligner","lemma":"surligner","pos":"VER"} ,
		{"word":"surligné","word_nosc":"surligne","lemma":"surligner","pos":"VER"} ,
		{"word":"surmenait","word_nosc":"surmenait","lemma":"surmener","pos":"VER"} ,
		{"word":"surmenant","word_nosc":"surmenant","lemma":"surmener","pos":"VER"} ,
		{"word":"surmener","word_nosc":"surmener","lemma":"surmener","pos":"VER"} ,
		{"word":"surmenez","word_nosc":"surmenez","lemma":"surmener","pos":"VER"} ,
		{"word":"surmené","word_nosc":"surmene","lemma":"surmener","pos":"VER"} ,
		{"word":"surmenée","word_nosc":"surmenee","lemma":"surmener","pos":"VER"} ,
		{"word":"surmenées","word_nosc":"surmenees","lemma":"surmener","pos":"VER"} ,
		{"word":"surmenés","word_nosc":"surmenes","lemma":"surmener","pos":"VER"} ,
		{"word":"surmonta","word_nosc":"surmonta","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmontai","word_nosc":"surmontai","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmontaient","word_nosc":"surmontaient","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmontais","word_nosc":"surmontais","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmontait","word_nosc":"surmontait","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmontant","word_nosc":"surmontant","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmonte","word_nosc":"surmonte","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmontent","word_nosc":"surmontent","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmonter","word_nosc":"surmonter","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmontera","word_nosc":"surmontera","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmonterai","word_nosc":"surmonterai","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmonteraient","word_nosc":"surmonteraient","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmonterait","word_nosc":"surmonterait","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmonteras","word_nosc":"surmonteras","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmonterez","word_nosc":"surmonterez","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmonterons","word_nosc":"surmonterons","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmontez","word_nosc":"surmontez","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmontions","word_nosc":"surmontions","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmontèrent","word_nosc":"surmonterent","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmonté","word_nosc":"surmonte","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmontée","word_nosc":"surmontee","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmontées","word_nosc":"surmontees","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmontés","word_nosc":"surmontes","lemma":"surmonter","pos":"VER"} ,
		{"word":"surmultipliée","word_nosc":"surmultipliee","lemma":"surmultiplier","pos":"VER"} ,
		{"word":"surmène","word_nosc":"surmene","lemma":"surmener","pos":"VER"} ,
		{"word":"surnage","word_nosc":"surnage","lemma":"surnager","pos":"VER"} ,
		{"word":"surnagea","word_nosc":"surnagea","lemma":"surnager","pos":"VER"} ,
		{"word":"surnageaient","word_nosc":"surnageaient","lemma":"surnager","pos":"VER"} ,
		{"word":"surnageait","word_nosc":"surnageait","lemma":"surnager","pos":"VER"} ,
		{"word":"surnageant","word_nosc":"surnageant","lemma":"surnager","pos":"VER"} ,
		{"word":"surnagent","word_nosc":"surnagent","lemma":"surnager","pos":"VER"} ,
		{"word":"surnageons","word_nosc":"surnageons","lemma":"surnager","pos":"VER"} ,
		{"word":"surnager","word_nosc":"surnager","lemma":"surnager","pos":"VER"} ,
		{"word":"surnagera","word_nosc":"surnagera","lemma":"surnager","pos":"VER"} ,
		{"word":"surnages","word_nosc":"surnages","lemma":"surnager","pos":"VER"} ,
		{"word":"surnagèrent","word_nosc":"surnagerent","lemma":"surnager","pos":"VER"} ,
		{"word":"surnagé","word_nosc":"surnage","lemma":"surnager","pos":"VER"} ,
		{"word":"surnomma","word_nosc":"surnomma","lemma":"surnommer","pos":"VER"} ,
		{"word":"surnommaient","word_nosc":"surnommaient","lemma":"surnommer","pos":"VER"} ,
		{"word":"surnommais","word_nosc":"surnommais","lemma":"surnommer","pos":"VER"} ,
		{"word":"surnommait","word_nosc":"surnommait","lemma":"surnommer","pos":"VER"} ,
		{"word":"surnommant","word_nosc":"surnommant","lemma":"surnommer","pos":"VER"} ,
		{"word":"surnomme","word_nosc":"surnomme","lemma":"surnommer","pos":"VER"} ,
		{"word":"surnomment","word_nosc":"surnomment","lemma":"surnommer","pos":"VER"} ,
		{"word":"surnommer","word_nosc":"surnommer","lemma":"surnommer","pos":"VER"} ,
		{"word":"surnommera","word_nosc":"surnommera","lemma":"surnommer","pos":"VER"} ,
		{"word":"surnommerais","word_nosc":"surnommerais","lemma":"surnommer","pos":"VER"} ,
		{"word":"surnommions","word_nosc":"surnommions","lemma":"surnommer","pos":"VER"} ,
		{"word":"surnommèrent","word_nosc":"surnommerent","lemma":"surnommer","pos":"VER"} ,
		{"word":"surnommé","word_nosc":"surnomme","lemma":"surnommer","pos":"VER"} ,
		{"word":"surnommée","word_nosc":"surnommee","lemma":"surnommer","pos":"VER"} ,
		{"word":"surnommés","word_nosc":"surnommes","lemma":"surnommer","pos":"VER"} ,
		{"word":"suroxygéner","word_nosc":"suroxygener","lemma":"suroxygéner","pos":"VER"} ,
		{"word":"surpassa","word_nosc":"surpassa","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpassaient","word_nosc":"surpassaient","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpassais","word_nosc":"surpassais","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpassait","word_nosc":"surpassait","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpassant","word_nosc":"surpassant","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpasse","word_nosc":"surpasse","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpassent","word_nosc":"surpassent","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpasser","word_nosc":"surpasser","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpassera","word_nosc":"surpassera","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpasserai","word_nosc":"surpasserai","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpasseraient","word_nosc":"surpasseraient","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpasserait","word_nosc":"surpasserait","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpasseras","word_nosc":"surpasseras","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpasses","word_nosc":"surpasses","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpassez","word_nosc":"surpassez","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpassons","word_nosc":"surpassons","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpassât","word_nosc":"surpassat","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpassé","word_nosc":"surpasse","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpassée","word_nosc":"surpassee","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpassées","word_nosc":"surpassees","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpassés","word_nosc":"surpasses","lemma":"surpasser","pos":"VER"} ,
		{"word":"surpaye","word_nosc":"surpaye","lemma":"surpayer","pos":"VER"} ,
		{"word":"surpayé","word_nosc":"surpaye","lemma":"surpayer","pos":"VER"} ,
		{"word":"surpayés","word_nosc":"surpayes","lemma":"surpayer","pos":"VER"} ,
		{"word":"surpeupler","word_nosc":"surpeupler","lemma":"surpeupler","pos":"VER"} ,
		{"word":"surplomba","word_nosc":"surplomba","lemma":"surplomber","pos":"VER"} ,
		{"word":"surplombaient","word_nosc":"surplombaient","lemma":"surplomber","pos":"VER"} ,
		{"word":"surplombait","word_nosc":"surplombait","lemma":"surplomber","pos":"VER"} ,
		{"word":"surplombant","word_nosc":"surplombant","lemma":"surplomber","pos":"VER"} ,
		{"word":"surplombe","word_nosc":"surplombe","lemma":"surplomber","pos":"VER"} ,
		{"word":"surplombent","word_nosc":"surplombent","lemma":"surplomber","pos":"VER"} ,
		{"word":"surplomber","word_nosc":"surplomber","lemma":"surplomber","pos":"VER"} ,
		{"word":"surplomberait","word_nosc":"surplomberait","lemma":"surplomber","pos":"VER"} ,
		{"word":"surplombions","word_nosc":"surplombions","lemma":"surplomber","pos":"VER"} ,
		{"word":"surplombé","word_nosc":"surplombe","lemma":"surplomber","pos":"VER"} ,
		{"word":"surplombée","word_nosc":"surplombee","lemma":"surplomber","pos":"VER"} ,
		{"word":"surplombés","word_nosc":"surplombes","lemma":"surplomber","pos":"VER"} ,
		{"word":"surprenaient","word_nosc":"surprenaient","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprenais","word_nosc":"surprenais","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprenait","word_nosc":"surprenait","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprenant","word_nosc":"surprenant","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprend","word_nosc":"surprend","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprendra","word_nosc":"surprendra","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprendrai","word_nosc":"surprendrai","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprendraient","word_nosc":"surprendraient","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprendrais","word_nosc":"surprendrais","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprendrait","word_nosc":"surprendrait","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprendras","word_nosc":"surprendras","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprendre","word_nosc":"surprendre","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprendrez","word_nosc":"surprendrez","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprendrons","word_nosc":"surprendrons","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprendront","word_nosc":"surprendront","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprends","word_nosc":"surprends","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprenez","word_nosc":"surprenez","lemma":"surprendre","pos":"VER"} ,
		{"word":"surpreniez","word_nosc":"surpreniez","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprenions","word_nosc":"surprenions","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprenne","word_nosc":"surprenne","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprennent","word_nosc":"surprennent","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprenons","word_nosc":"surprenons","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprirent","word_nosc":"surprirent","lemma":"surprendre","pos":"VER"} ,
		{"word":"surpris","word_nosc":"surpris","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprise","word_nosc":"surprise","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprises","word_nosc":"surprises","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprit","word_nosc":"surprit","lemma":"surprendre","pos":"VER"} ,
		{"word":"surprotège","word_nosc":"surprotege","lemma":"surprotéger","pos":"VER"} ,
		{"word":"surprotégeais","word_nosc":"surprotegeais","lemma":"surprotéger","pos":"VER"} ,
		{"word":"surprotéger","word_nosc":"surproteger","lemma":"surprotéger","pos":"VER"} ,
		{"word":"surprotégé","word_nosc":"surprotege","lemma":"surprotéger","pos":"VER"} ,
		{"word":"surprît","word_nosc":"surprit","lemma":"surprendre","pos":"VER"} ,
		{"word":"sursaturé","word_nosc":"sursature","lemma":"sursaturer","pos":"VER"} ,
		{"word":"sursauta","word_nosc":"sursauta","lemma":"sursauter","pos":"VER"} ,
		{"word":"sursautai","word_nosc":"sursautai","lemma":"sursauter","pos":"VER"} ,
		{"word":"sursautaient","word_nosc":"sursautaient","lemma":"sursauter","pos":"VER"} ,
		{"word":"sursautais","word_nosc":"sursautais","lemma":"sursauter","pos":"VER"} ,
		{"word":"sursautait","word_nosc":"sursautait","lemma":"sursauter","pos":"VER"} ,
		{"word":"sursautant","word_nosc":"sursautant","lemma":"sursauter","pos":"VER"} ,
		{"word":"sursaute","word_nosc":"sursaute","lemma":"sursauter","pos":"VER"} ,
		{"word":"sursautent","word_nosc":"sursautent","lemma":"sursauter","pos":"VER"} ,
		{"word":"sursauter","word_nosc":"sursauter","lemma":"sursauter","pos":"VER"} ,
		{"word":"sursauteraient","word_nosc":"sursauteraient","lemma":"sursauter","pos":"VER"} ,
		{"word":"sursauterais","word_nosc":"sursauterais","lemma":"sursauter","pos":"VER"} ,
		{"word":"sursautez","word_nosc":"sursautez","lemma":"sursauter","pos":"VER"} ,
		{"word":"sursautons","word_nosc":"sursautons","lemma":"sursauter","pos":"VER"} ,
		{"word":"sursautât","word_nosc":"sursautat","lemma":"sursauter","pos":"VER"} ,
		{"word":"sursautèrent","word_nosc":"sursauterent","lemma":"sursauter","pos":"VER"} ,
		{"word":"sursauté","word_nosc":"sursaute","lemma":"sursauter","pos":"VER"} ,
		{"word":"surseoir","word_nosc":"surseoir","lemma":"surseoir","pos":"VER"} ,
		{"word":"sursis","word_nosc":"sursis","lemma":"surseoir","pos":"VER"} ,
		{"word":"surtaxer","word_nosc":"surtaxer","lemma":"surtaxer","pos":"VER"} ,
		{"word":"surtaxés","word_nosc":"surtaxes","lemma":"surtaxer","pos":"VER"} ,
		{"word":"surveilla","word_nosc":"surveilla","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillaient","word_nosc":"surveillaient","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillais","word_nosc":"surveillais","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillait","word_nosc":"surveillait","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillant","word_nosc":"surveillant","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveille","word_nosc":"surveille","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillent","word_nosc":"surveillent","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveiller","word_nosc":"surveiller","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillera","word_nosc":"surveillera","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillerai","word_nosc":"surveillerai","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveilleraient","word_nosc":"surveilleraient","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillerais","word_nosc":"surveillerais","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillerait","word_nosc":"surveillerait","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveilleras","word_nosc":"surveilleras","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillerez","word_nosc":"surveillerez","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillerons","word_nosc":"surveillerons","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveilleront","word_nosc":"surveilleront","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveilles","word_nosc":"surveilles","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillez","word_nosc":"surveillez","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveilliez","word_nosc":"surveilliez","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillions","word_nosc":"surveillions","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillons","word_nosc":"surveillons","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillât","word_nosc":"surveillat","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillé","word_nosc":"surveille","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillée","word_nosc":"surveillee","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillées","word_nosc":"surveillees","lemma":"surveiller","pos":"VER"} ,
		{"word":"surveillés","word_nosc":"surveilles","lemma":"surveiller","pos":"VER"} ,
		{"word":"survenaient","word_nosc":"survenaient","lemma":"survenir","pos":"VER"} ,
		{"word":"survenais","word_nosc":"survenais","lemma":"survenir","pos":"VER"} ,
		{"word":"survenait","word_nosc":"survenait","lemma":"survenir","pos":"VER"} ,
		{"word":"survenant","word_nosc":"survenant","lemma":"survenir","pos":"VER"} ,
		{"word":"survenir","word_nosc":"survenir","lemma":"survenir","pos":"VER"} ,
		{"word":"survenu","word_nosc":"survenu","lemma":"survenir","pos":"VER"} ,
		{"word":"survenue","word_nosc":"survenue","lemma":"survenir","pos":"VER"} ,
		{"word":"survenues","word_nosc":"survenues","lemma":"survenir","pos":"VER"} ,
		{"word":"survenus","word_nosc":"survenus","lemma":"survenir","pos":"VER"} ,
		{"word":"surviendra","word_nosc":"surviendra","lemma":"survenir","pos":"VER"} ,
		{"word":"surviendraient","word_nosc":"surviendraient","lemma":"survenir","pos":"VER"} ,
		{"word":"surviendrait","word_nosc":"surviendrait","lemma":"survenir","pos":"VER"} ,
		{"word":"surviendront","word_nosc":"surviendront","lemma":"survenir","pos":"VER"} ,
		{"word":"survienne","word_nosc":"survienne","lemma":"survenir","pos":"VER"} ,
		{"word":"surviennent","word_nosc":"surviennent","lemma":"survenir","pos":"VER"} ,
		{"word":"survient","word_nosc":"survient","lemma":"survenir","pos":"VER"} ,
		{"word":"survinrent","word_nosc":"survinrent","lemma":"survenir","pos":"VER"} ,
		{"word":"survint","word_nosc":"survint","lemma":"survenir","pos":"VER"} ,
		{"word":"survire","word_nosc":"survire","lemma":"survirer","pos":"VER"} ,
		{"word":"survis","word_nosc":"survis","lemma":"survivre","pos":"VER"} ,
		{"word":"survit","word_nosc":"survit","lemma":"survivre","pos":"VER"} ,
		{"word":"survivaient","word_nosc":"survivaient","lemma":"survivre","pos":"VER"} ,
		{"word":"survivais","word_nosc":"survivais","lemma":"survivre","pos":"VER"} ,
		{"word":"survivait","word_nosc":"survivait","lemma":"survivre","pos":"VER"} ,
		{"word":"survivant","word_nosc":"survivant","lemma":"survivre","pos":"VER"} ,
		{"word":"survive","word_nosc":"survive","lemma":"survivre","pos":"VER"} ,
		{"word":"survivent","word_nosc":"survivent","lemma":"survivre","pos":"VER"} ,
		{"word":"survives","word_nosc":"survives","lemma":"survivre","pos":"VER"} ,
		{"word":"survivez","word_nosc":"survivez","lemma":"survivre","pos":"VER"} ,
		{"word":"surviviez","word_nosc":"surviviez","lemma":"survivre","pos":"VER"} ,
		{"word":"survivions","word_nosc":"survivions","lemma":"survivre","pos":"VER"} ,
		{"word":"survivons","word_nosc":"survivons","lemma":"survivre","pos":"VER"} ,
		{"word":"survivra","word_nosc":"survivra","lemma":"survivre","pos":"VER"} ,
		{"word":"survivrai","word_nosc":"survivrai","lemma":"survivre","pos":"VER"} ,
		{"word":"survivraient","word_nosc":"survivraient","lemma":"survivre","pos":"VER"} ,
		{"word":"survivrais","word_nosc":"survivrais","lemma":"survivre","pos":"VER"} ,
		{"word":"survivrait","word_nosc":"survivrait","lemma":"survivre","pos":"VER"} ,
		{"word":"survivras","word_nosc":"survivras","lemma":"survivre","pos":"VER"} ,
		{"word":"survivre","word_nosc":"survivre","lemma":"survivre","pos":"VER"} ,
		{"word":"survivrez","word_nosc":"survivrez","lemma":"survivre","pos":"VER"} ,
		{"word":"survivriez","word_nosc":"survivriez","lemma":"survivre","pos":"VER"} ,
		{"word":"survivrons","word_nosc":"survivrons","lemma":"survivre","pos":"VER"} ,
		{"word":"survivront","word_nosc":"survivront","lemma":"survivre","pos":"VER"} ,
		{"word":"survola","word_nosc":"survola","lemma":"survoler","pos":"VER"} ,
		{"word":"survolaient","word_nosc":"survolaient","lemma":"survoler","pos":"VER"} ,
		{"word":"survolais","word_nosc":"survolais","lemma":"survoler","pos":"VER"} ,
		{"word":"survolait","word_nosc":"survolait","lemma":"survoler","pos":"VER"} ,
		{"word":"survolant","word_nosc":"survolant","lemma":"survoler","pos":"VER"} ,
		{"word":"survole","word_nosc":"survole","lemma":"survoler","pos":"VER"} ,
		{"word":"survolent","word_nosc":"survolent","lemma":"survoler","pos":"VER"} ,
		{"word":"survoler","word_nosc":"survoler","lemma":"survoler","pos":"VER"} ,
		{"word":"survolera","word_nosc":"survolera","lemma":"survoler","pos":"VER"} ,
		{"word":"survolerai","word_nosc":"survolerai","lemma":"survoler","pos":"VER"} ,
		{"word":"survolerez","word_nosc":"survolerez","lemma":"survoler","pos":"VER"} ,
		{"word":"survolerons","word_nosc":"survolerons","lemma":"survoler","pos":"VER"} ,
		{"word":"survolez","word_nosc":"survolez","lemma":"survoler","pos":"VER"} ,
		{"word":"survolions","word_nosc":"survolions","lemma":"survoler","pos":"VER"} ,
		{"word":"survolons","word_nosc":"survolons","lemma":"survoler","pos":"VER"} ,
		{"word":"survoltait","word_nosc":"survoltait","lemma":"survolter","pos":"VER"} ,
		{"word":"survolté","word_nosc":"survolte","lemma":"survolter","pos":"VER"} ,
		{"word":"survoltée","word_nosc":"survoltee","lemma":"survolter","pos":"VER"} ,
		{"word":"survoltés","word_nosc":"survoltes","lemma":"survolter","pos":"VER"} ,
		{"word":"survolâmes","word_nosc":"survolames","lemma":"survoler","pos":"VER"} ,
		{"word":"survolé","word_nosc":"survole","lemma":"survoler","pos":"VER"} ,
		{"word":"survolée","word_nosc":"survolee","lemma":"survoler","pos":"VER"} ,
		{"word":"survolées","word_nosc":"survolees","lemma":"survoler","pos":"VER"} ,
		{"word":"survécu","word_nosc":"survecu","lemma":"survivre","pos":"VER"} ,
		{"word":"survécurent","word_nosc":"survecurent","lemma":"survivre","pos":"VER"} ,
		{"word":"survécus","word_nosc":"survecus","lemma":"survivre","pos":"VER"} ,
		{"word":"survécut","word_nosc":"survecut","lemma":"survivre","pos":"VER"} ,
		{"word":"survécûmes","word_nosc":"survecumes","lemma":"survivre","pos":"VER"} ,
		{"word":"survécût","word_nosc":"survecut","lemma":"survivre","pos":"VER"} ,
		{"word":"survînt","word_nosc":"survint","lemma":"survenir","pos":"VER"} ,
		{"word":"surélevait","word_nosc":"surelevait","lemma":"surélever","pos":"VER"} ,
		{"word":"surélever","word_nosc":"surelever","lemma":"surélever","pos":"VER"} ,
		{"word":"surélevez","word_nosc":"surelevez","lemma":"surélever","pos":"VER"} ,
		{"word":"surélevé","word_nosc":"sureleve","lemma":"surélever","pos":"VER"} ,
		{"word":"surélevée","word_nosc":"surelevee","lemma":"surélever","pos":"VER"} ,
		{"word":"surélevées","word_nosc":"surelevees","lemma":"surélever","pos":"VER"} ,
		{"word":"surélevés","word_nosc":"sureleves","lemma":"surélever","pos":"VER"} ,
		{"word":"surélève","word_nosc":"sureleve","lemma":"surélever","pos":"VER"} ,
		{"word":"suréquipée","word_nosc":"surequipee","lemma":"suréquiper","pos":"VER"} ,
		{"word":"surévalué","word_nosc":"surevalue","lemma":"surévaluer","pos":"VER"} ,
		{"word":"sus","word_nosc":"sus","lemma":"savoir","pos":"VER"} ,
		{"word":"suscita","word_nosc":"suscita","lemma":"susciter","pos":"VER"} ,
		{"word":"suscitaient","word_nosc":"suscitaient","lemma":"susciter","pos":"VER"} ,
		{"word":"suscitais","word_nosc":"suscitais","lemma":"susciter","pos":"VER"} ,
		{"word":"suscitait","word_nosc":"suscitait","lemma":"susciter","pos":"VER"} ,
		{"word":"suscitant","word_nosc":"suscitant","lemma":"susciter","pos":"VER"} ,
		{"word":"suscite","word_nosc":"suscite","lemma":"susciter","pos":"VER"} ,
		{"word":"suscitent","word_nosc":"suscitent","lemma":"susciter","pos":"VER"} ,
		{"word":"susciter","word_nosc":"susciter","lemma":"susciter","pos":"VER"} ,
		{"word":"susciteraient","word_nosc":"susciteraient","lemma":"susciter","pos":"VER"} ,
		{"word":"susciterait","word_nosc":"susciterait","lemma":"susciter","pos":"VER"} ,
		{"word":"susciteront","word_nosc":"susciteront","lemma":"susciter","pos":"VER"} ,
		{"word":"suscites","word_nosc":"suscites","lemma":"susciter","pos":"VER"} ,
		{"word":"suscité","word_nosc":"suscite","lemma":"susciter","pos":"VER"} ,
		{"word":"suscitée","word_nosc":"suscitee","lemma":"susciter","pos":"VER"} ,
		{"word":"suscitées","word_nosc":"suscitees","lemma":"susciter","pos":"VER"} ,
		{"word":"suscités","word_nosc":"suscites","lemma":"susciter","pos":"VER"} ,
		{"word":"suspectaient","word_nosc":"suspectaient","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspectais","word_nosc":"suspectais","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspectait","word_nosc":"suspectait","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspectant","word_nosc":"suspectant","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspecte","word_nosc":"suspecte","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspectent","word_nosc":"suspectent","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspecter","word_nosc":"suspecter","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspectera","word_nosc":"suspectera","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspecteraient","word_nosc":"suspecteraient","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspecterait","word_nosc":"suspecterait","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspecteront","word_nosc":"suspecteront","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspectes","word_nosc":"suspectes","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspectez","word_nosc":"suspectez","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspectiez","word_nosc":"suspectiez","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspectons","word_nosc":"suspectons","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspecté","word_nosc":"suspecte","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspectée","word_nosc":"suspectee","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspectées","word_nosc":"suspectees","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspectés","word_nosc":"suspectes","lemma":"suspecter","pos":"VER"} ,
		{"word":"suspend","word_nosc":"suspend","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendaient","word_nosc":"suspendaient","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendais","word_nosc":"suspendais","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendait","word_nosc":"suspendait","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendant","word_nosc":"suspendant","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspende","word_nosc":"suspende","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendent","word_nosc":"suspendent","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendez","word_nosc":"suspendez","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendirent","word_nosc":"suspendirent","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendit","word_nosc":"suspendit","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendons","word_nosc":"suspendons","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendra","word_nosc":"suspendra","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendrai","word_nosc":"suspendrai","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendrait","word_nosc":"suspendrait","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendre","word_nosc":"suspendre","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendrez","word_nosc":"suspendrez","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendrons","word_nosc":"suspendrons","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendront","word_nosc":"suspendront","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspends","word_nosc":"suspends","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendu","word_nosc":"suspendu","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendue","word_nosc":"suspendue","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendues","word_nosc":"suspendues","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendus","word_nosc":"suspendus","lemma":"suspendre","pos":"VER"} ,
		{"word":"suspendît","word_nosc":"suspendit","lemma":"suspendre","pos":"VER"} ,
		{"word":"susse","word_nosc":"susse","lemma":"savoir","pos":"VER"} ,
		{"word":"sussent","word_nosc":"sussent","lemma":"savoir","pos":"VER"} ,
		{"word":"sustente","word_nosc":"sustente","lemma":"sustenter","pos":"VER"} ,
		{"word":"sustenter","word_nosc":"sustenter","lemma":"sustenter","pos":"VER"} ,
		{"word":"sustentés","word_nosc":"sustentes","lemma":"sustenter","pos":"VER"} ,
		{"word":"susurra","word_nosc":"susurra","lemma":"susurrer","pos":"VER"} ,
		{"word":"susurrai","word_nosc":"susurrai","lemma":"susurrer","pos":"VER"} ,
		{"word":"susurraient","word_nosc":"susurraient","lemma":"susurrer","pos":"VER"} ,
		{"word":"susurrait","word_nosc":"susurrait","lemma":"susurrer","pos":"VER"} ,
		{"word":"susurrant","word_nosc":"susurrant","lemma":"susurrer","pos":"VER"} ,
		{"word":"susurre","word_nosc":"susurre","lemma":"susurrer","pos":"VER"} ,
		{"word":"susurrent","word_nosc":"susurrent","lemma":"susurrer","pos":"VER"} ,
		{"word":"susurrer","word_nosc":"susurrer","lemma":"susurrer","pos":"VER"} ,
		{"word":"susurrerai","word_nosc":"susurrerai","lemma":"susurrer","pos":"VER"} ,
		{"word":"susurré","word_nosc":"susurre","lemma":"susurrer","pos":"VER"} ,
		{"word":"susurrée","word_nosc":"susurree","lemma":"susurrer","pos":"VER"} ,
		{"word":"susurrées","word_nosc":"susurrees","lemma":"susurrer","pos":"VER"} ,
		{"word":"susurrés","word_nosc":"susurres","lemma":"susurrer","pos":"VER"} ,
		{"word":"sut","word_nosc":"sut","lemma":"savoir","pos":"VER"} ,
		{"word":"suturant","word_nosc":"suturant","lemma":"suturer","pos":"VER"} ,
		{"word":"suturer","word_nosc":"suturer","lemma":"suturer","pos":"VER"} ,
		{"word":"suturé","word_nosc":"suture","lemma":"suturer","pos":"VER"} ,
		{"word":"suturée","word_nosc":"suturee","lemma":"suturer","pos":"VER"} ,
		{"word":"suât","word_nosc":"suat","lemma":"suer","pos":"VER"} ,
		{"word":"suça","word_nosc":"suca","lemma":"sucer","pos":"VER"} ,
		{"word":"suçaient","word_nosc":"sucaient","lemma":"sucer","pos":"VER"} ,
		{"word":"suçais","word_nosc":"sucais","lemma":"sucer","pos":"VER"} ,
		{"word":"suçait","word_nosc":"sucait","lemma":"sucer","pos":"VER"} ,
		{"word":"suçant","word_nosc":"sucant","lemma":"sucer","pos":"VER"} ,
		{"word":"suçota","word_nosc":"sucota","lemma":"suçoter","pos":"VER"} ,
		{"word":"suçotait","word_nosc":"sucotait","lemma":"suçoter","pos":"VER"} ,
		{"word":"suçotant","word_nosc":"sucotant","lemma":"suçoter","pos":"VER"} ,
		{"word":"suçote","word_nosc":"sucote","lemma":"suçoter","pos":"VER"} ,
		{"word":"suçotent","word_nosc":"sucotent","lemma":"suçoter","pos":"VER"} ,
		{"word":"suçoter","word_nosc":"sucoter","lemma":"suçoter","pos":"VER"} ,
		{"word":"suçoté","word_nosc":"sucote","lemma":"suçoter","pos":"VER"} ,
		{"word":"sué","word_nosc":"sue","lemma":"suer","pos":"VER"} ,
		{"word":"suée","word_nosc":"suee","lemma":"suer","pos":"VER"} ,
		{"word":"swinguaient","word_nosc":"swinguaient","lemma":"swinguer","pos":"VER"} ,
		{"word":"symbolisa","word_nosc":"symbolisa","lemma":"symboliser","pos":"VER"} ,
		{"word":"symbolisaient","word_nosc":"symbolisaient","lemma":"symboliser","pos":"VER"} ,
		{"word":"symbolisait","word_nosc":"symbolisait","lemma":"symboliser","pos":"VER"} ,
		{"word":"symbolisant","word_nosc":"symbolisant","lemma":"symboliser","pos":"VER"} ,
		{"word":"symbolise","word_nosc":"symbolise","lemma":"symboliser","pos":"VER"} ,
		{"word":"symbolisent","word_nosc":"symbolisent","lemma":"symboliser","pos":"VER"} ,
		{"word":"symboliser","word_nosc":"symboliser","lemma":"symboliser","pos":"VER"} ,
		{"word":"symbolisera","word_nosc":"symbolisera","lemma":"symboliser","pos":"VER"} ,
		{"word":"symboliserai","word_nosc":"symboliserai","lemma":"symboliser","pos":"VER"} ,
		{"word":"symboliserait","word_nosc":"symboliserait","lemma":"symboliser","pos":"VER"} ,
		{"word":"symbolisât","word_nosc":"symbolisat","lemma":"symboliser","pos":"VER"} ,
		{"word":"symbolisé","word_nosc":"symbolise","lemma":"symboliser","pos":"VER"} ,
		{"word":"symbolisée","word_nosc":"symbolisee","lemma":"symboliser","pos":"VER"} ,
		{"word":"symbolisés","word_nosc":"symbolises","lemma":"symboliser","pos":"VER"} ,
		{"word":"sympathisai","word_nosc":"sympathisai","lemma":"sympathiser","pos":"VER"} ,
		{"word":"sympathisaient","word_nosc":"sympathisaient","lemma":"sympathiser","pos":"VER"} ,
		{"word":"sympathisait","word_nosc":"sympathisait","lemma":"sympathiser","pos":"VER"} ,
		{"word":"sympathisant","word_nosc":"sympathisant","lemma":"sympathiser","pos":"VER"} ,
		{"word":"sympathise","word_nosc":"sympathise","lemma":"sympathiser","pos":"VER"} ,
		{"word":"sympathiser","word_nosc":"sympathiser","lemma":"sympathiser","pos":"VER"} ,
		{"word":"sympathisera","word_nosc":"sympathisera","lemma":"sympathiser","pos":"VER"} ,
		{"word":"sympathiserez","word_nosc":"sympathiserez","lemma":"sympathiser","pos":"VER"} ,
		{"word":"sympathisez","word_nosc":"sympathisez","lemma":"sympathiser","pos":"VER"} ,
		{"word":"sympathisions","word_nosc":"sympathisions","lemma":"sympathiser","pos":"VER"} ,
		{"word":"sympathisons","word_nosc":"sympathisons","lemma":"sympathiser","pos":"VER"} ,
		{"word":"sympathisé","word_nosc":"sympathise","lemma":"sympathiser","pos":"VER"} ,
		{"word":"synchronisaient","word_nosc":"synchronisaient","lemma":"synchroniser","pos":"VER"} ,
		{"word":"synchronise","word_nosc":"synchronise","lemma":"synchroniser","pos":"VER"} ,
		{"word":"synchroniser","word_nosc":"synchroniser","lemma":"synchroniser","pos":"VER"} ,
		{"word":"synchronisera","word_nosc":"synchronisera","lemma":"synchroniser","pos":"VER"} ,
		{"word":"synchronisez","word_nosc":"synchronisez","lemma":"synchroniser","pos":"VER"} ,
		{"word":"synchronisons","word_nosc":"synchronisons","lemma":"synchroniser","pos":"VER"} ,
		{"word":"synchronisé","word_nosc":"synchronise","lemma":"synchroniser","pos":"VER"} ,
		{"word":"synchronisée","word_nosc":"synchronisee","lemma":"synchroniser","pos":"VER"} ,
		{"word":"synchronisées","word_nosc":"synchronisees","lemma":"synchroniser","pos":"VER"} ,
		{"word":"synchronisés","word_nosc":"synchronises","lemma":"synchroniser","pos":"VER"} ,
		{"word":"syncoper","word_nosc":"syncoper","lemma":"syncoper","pos":"VER"} ,
		{"word":"syncopé","word_nosc":"syncope","lemma":"syncoper","pos":"VER"} ,
		{"word":"syncopée","word_nosc":"syncopee","lemma":"syncoper","pos":"VER"} ,
		{"word":"syncopés","word_nosc":"syncopes","lemma":"syncoper","pos":"VER"} ,
		{"word":"syndicaliser","word_nosc":"syndicaliser","lemma":"syndicaliser","pos":"VER"} ,
		{"word":"syndique","word_nosc":"syndique","lemma":"syndiquer","pos":"VER"} ,
		{"word":"syndiquer","word_nosc":"syndiquer","lemma":"syndiquer","pos":"VER"} ,
		{"word":"syndiquez","word_nosc":"syndiquez","lemma":"syndiquer","pos":"VER"} ,
		{"word":"syndiqué","word_nosc":"syndique","lemma":"syndiquer","pos":"VER"} ,
		{"word":"syndiquée","word_nosc":"syndiquee","lemma":"syndiquer","pos":"VER"} ,
		{"word":"syndiqués","word_nosc":"syndiques","lemma":"syndiquer","pos":"VER"} ,
		{"word":"synthétise","word_nosc":"synthetise","lemma":"synthétiser","pos":"VER"} ,
		{"word":"synthétisent","word_nosc":"synthetisent","lemma":"synthétiser","pos":"VER"} ,
		{"word":"synthétiser","word_nosc":"synthetiser","lemma":"synthétiser","pos":"VER"} ,
		{"word":"synthétisera","word_nosc":"synthetisera","lemma":"synthétiser","pos":"VER"} ,
		{"word":"synthétisé","word_nosc":"synthetise","lemma":"synthétiser","pos":"VER"} ,
		{"word":"synthétisée","word_nosc":"synthetisee","lemma":"synthétiser","pos":"VER"} ,
		{"word":"synthétisés","word_nosc":"synthetises","lemma":"synthétiser","pos":"VER"} ,
		{"word":"systématiser","word_nosc":"systematiser","lemma":"systématiser","pos":"VER"} ,
		{"word":"sèche","word_nosc":"seche","lemma":"sécher","pos":"VER"} ,
		{"word":"sèchent","word_nosc":"sechent","lemma":"sécher","pos":"VER"} ,
		{"word":"sèches","word_nosc":"seches","lemma":"sécher","pos":"VER"} ,
		{"word":"sème","word_nosc":"seme","lemma":"semer","pos":"VER"} ,
		{"word":"sèment","word_nosc":"sement","lemma":"semer","pos":"VER"} ,
		{"word":"sèmera","word_nosc":"semera","lemma":"semer","pos":"VER"} ,
		{"word":"sèmerai","word_nosc":"semerai","lemma":"semer","pos":"VER"} ,
		{"word":"sèmerais","word_nosc":"semerais","lemma":"semer","pos":"VER"} ,
		{"word":"sèmerait","word_nosc":"semerait","lemma":"semer","pos":"VER"} ,
		{"word":"sèmeras","word_nosc":"semeras","lemma":"semer","pos":"VER"} ,
		{"word":"sèmerez","word_nosc":"semerez","lemma":"semer","pos":"VER"} ,
		{"word":"sèmerions","word_nosc":"semerions","lemma":"semer","pos":"VER"} ,
		{"word":"sèmerons","word_nosc":"semerons","lemma":"semer","pos":"VER"} ,
		{"word":"sèmeront","word_nosc":"semeront","lemma":"semer","pos":"VER"} ,
		{"word":"sèmes","word_nosc":"semes","lemma":"semer","pos":"VER"} ,
		{"word":"sèvres","word_nosc":"sevres","lemma":"sevrer","pos":"VER"} ,
		{"word":"sécha","word_nosc":"secha","lemma":"sécher","pos":"VER"} ,
		{"word":"séchai","word_nosc":"sechai","lemma":"sécher","pos":"VER"} ,
		{"word":"séchaient","word_nosc":"sechaient","lemma":"sécher","pos":"VER"} ,
		{"word":"séchais","word_nosc":"sechais","lemma":"sécher","pos":"VER"} ,
		{"word":"séchait","word_nosc":"sechait","lemma":"sécher","pos":"VER"} ,
		{"word":"séchant","word_nosc":"sechant","lemma":"sécher","pos":"VER"} ,
		{"word":"sécher","word_nosc":"secher","lemma":"sécher","pos":"VER"} ,
		{"word":"séchera","word_nosc":"sechera","lemma":"sécher","pos":"VER"} ,
		{"word":"sécherai","word_nosc":"secherai","lemma":"sécher","pos":"VER"} ,
		{"word":"sécheraient","word_nosc":"secheraient","lemma":"sécher","pos":"VER"} ,
		{"word":"sécherais","word_nosc":"secherais","lemma":"sécher","pos":"VER"} ,
		{"word":"sécherait","word_nosc":"secherait","lemma":"sécher","pos":"VER"} ,
		{"word":"sécherez","word_nosc":"secherez","lemma":"sécher","pos":"VER"} ,
		{"word":"sécheront","word_nosc":"secheront","lemma":"sécher","pos":"VER"} ,
		{"word":"séchez","word_nosc":"sechez","lemma":"sécher","pos":"VER"} ,
		{"word":"séchiez","word_nosc":"sechiez","lemma":"sécher","pos":"VER"} ,
		{"word":"séchions","word_nosc":"sechions","lemma":"sécher","pos":"VER"} ,
		{"word":"séchons","word_nosc":"sechons","lemma":"sécher","pos":"VER"} ,
		{"word":"séchât","word_nosc":"sechat","lemma":"sécher","pos":"VER"} ,
		{"word":"séchèrent","word_nosc":"secherent","lemma":"sécher","pos":"VER"} ,
		{"word":"séché","word_nosc":"seche","lemma":"sécher","pos":"VER"} ,
		{"word":"séchée","word_nosc":"sechee","lemma":"sécher","pos":"VER"} ,
		{"word":"séchées","word_nosc":"sechees","lemma":"sécher","pos":"VER"} ,
		{"word":"séchés","word_nosc":"seches","lemma":"sécher","pos":"VER"} ,
		{"word":"sécrète","word_nosc":"secrete","lemma":"sécréter","pos":"VER"} ,
		{"word":"sécrètent","word_nosc":"secretent","lemma":"sécréter","pos":"VER"} ,
		{"word":"sécrètes","word_nosc":"secretes","lemma":"sécréter","pos":"VER"} ,
		{"word":"sécrétaient","word_nosc":"secretaient","lemma":"sécréter","pos":"VER"} ,
		{"word":"sécrétais","word_nosc":"secretais","lemma":"sécréter","pos":"VER"} ,
		{"word":"sécrétait","word_nosc":"secretait","lemma":"sécréter","pos":"VER"} ,
		{"word":"sécrétant","word_nosc":"secretant","lemma":"sécréter","pos":"VER"} ,
		{"word":"sécréter","word_nosc":"secreter","lemma":"sécréter","pos":"VER"} ,
		{"word":"sécrétez","word_nosc":"secretez","lemma":"sécréter","pos":"VER"} ,
		{"word":"sécrétât","word_nosc":"secretat","lemma":"sécréter","pos":"VER"} ,
		{"word":"sécrété","word_nosc":"secrete","lemma":"sécréter","pos":"VER"} ,
		{"word":"sécrétée","word_nosc":"secretee","lemma":"sécréter","pos":"VER"} ,
		{"word":"sécrétés","word_nosc":"secretes","lemma":"sécréter","pos":"VER"} ,
		{"word":"séculariser","word_nosc":"seculariser","lemma":"séculariser","pos":"VER"} ,
		{"word":"sécularisées","word_nosc":"secularisees","lemma":"séculariser","pos":"VER"} ,
		{"word":"sécurisait","word_nosc":"securisait","lemma":"sécuriser","pos":"VER"} ,
		{"word":"sécurisant","word_nosc":"securisant","lemma":"sécuriser","pos":"VER"} ,
		{"word":"sécuriser","word_nosc":"securiser","lemma":"sécuriser","pos":"VER"} ,
		{"word":"sécurisez","word_nosc":"securisez","lemma":"sécuriser","pos":"VER"} ,
		{"word":"sécurisé","word_nosc":"securise","lemma":"sécuriser","pos":"VER"} ,
		{"word":"sécurisée","word_nosc":"securisee","lemma":"sécuriser","pos":"VER"} ,
		{"word":"sécurisées","word_nosc":"securisees","lemma":"sécuriser","pos":"VER"} ,
		{"word":"sécurisés","word_nosc":"securises","lemma":"sécuriser","pos":"VER"} ,
		{"word":"sédentarisaient","word_nosc":"sedentarisaient","lemma":"sédentariser","pos":"VER"} ,
		{"word":"sédentarise","word_nosc":"sedentarise","lemma":"sédentariser","pos":"VER"} ,
		{"word":"sédentariser","word_nosc":"sedentariser","lemma":"sédentariser","pos":"VER"} ,
		{"word":"sédimente","word_nosc":"sedimente","lemma":"sédimenter","pos":"VER"} ,
		{"word":"sédimentée","word_nosc":"sedimentee","lemma":"sédimenter","pos":"VER"} ,
		{"word":"séduira","word_nosc":"seduira","lemma":"séduire","pos":"VER"} ,
		{"word":"séduirait","word_nosc":"seduirait","lemma":"séduire","pos":"VER"} ,
		{"word":"séduiras","word_nosc":"seduiras","lemma":"séduire","pos":"VER"} ,
		{"word":"séduire","word_nosc":"seduire","lemma":"séduire","pos":"VER"} ,
		{"word":"séduis","word_nosc":"seduis","lemma":"séduire","pos":"VER"} ,
		{"word":"séduisaient","word_nosc":"seduisaient","lemma":"séduire","pos":"VER"} ,
		{"word":"séduisait","word_nosc":"seduisait","lemma":"séduire","pos":"VER"} ,
		{"word":"séduisant","word_nosc":"seduisant","lemma":"séduire","pos":"VER"} ,
		{"word":"séduise","word_nosc":"seduise","lemma":"séduire","pos":"VER"} ,
		{"word":"séduisent","word_nosc":"seduisent","lemma":"séduire","pos":"VER"} ,
		{"word":"séduises","word_nosc":"seduises","lemma":"séduire","pos":"VER"} ,
		{"word":"séduisez","word_nosc":"seduisez","lemma":"séduire","pos":"VER"} ,
		{"word":"séduisirent","word_nosc":"seduisirent","lemma":"séduire","pos":"VER"} ,
		{"word":"séduisit","word_nosc":"seduisit","lemma":"séduire","pos":"VER"} ,
		{"word":"séduit","word_nosc":"seduit","lemma":"séduire","pos":"VER"} ,
		{"word":"séduite","word_nosc":"seduite","lemma":"séduire","pos":"VER"} ,
		{"word":"séduites","word_nosc":"seduites","lemma":"séduire","pos":"VER"} ,
		{"word":"séduits","word_nosc":"seduits","lemma":"séduire","pos":"VER"} ,
		{"word":"séjourna","word_nosc":"sejourna","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjournaient","word_nosc":"sejournaient","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjournais","word_nosc":"sejournais","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjournait","word_nosc":"sejournait","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjournant","word_nosc":"sejournant","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjourne","word_nosc":"sejourne","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjournent","word_nosc":"sejournent","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjourner","word_nosc":"sejourner","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjourneraient","word_nosc":"sejourneraient","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjournerez","word_nosc":"sejournerez","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjourneriez","word_nosc":"sejourneriez","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjournerons","word_nosc":"sejournerons","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjournes","word_nosc":"sejournes","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjournez","word_nosc":"sejournez","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjourniez","word_nosc":"sejourniez","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjournions","word_nosc":"sejournions","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjournâmes","word_nosc":"sejournames","lemma":"séjourner","pos":"VER"} ,
		{"word":"séjourné","word_nosc":"sejourne","lemma":"séjourner","pos":"VER"} ,
		{"word":"sélectionna","word_nosc":"selectionna","lemma":"sélectionner","pos":"VER"} ,
		{"word":"sélectionnaient","word_nosc":"selectionnaient","lemma":"sélectionner","pos":"VER"} ,
		{"word":"sélectionnait","word_nosc":"selectionnait","lemma":"sélectionner","pos":"VER"} ,
		{"word":"sélectionnant","word_nosc":"selectionnant","lemma":"sélectionner","pos":"VER"} ,
		{"word":"sélectionne","word_nosc":"selectionne","lemma":"sélectionner","pos":"VER"} ,
		{"word":"sélectionner","word_nosc":"selectionner","lemma":"sélectionner","pos":"VER"} ,
		{"word":"sélectionnera","word_nosc":"selectionnera","lemma":"sélectionner","pos":"VER"} ,
		{"word":"sélectionnerai","word_nosc":"selectionnerai","lemma":"sélectionner","pos":"VER"} ,
		{"word":"sélectionnerais","word_nosc":"selectionnerais","lemma":"sélectionner","pos":"VER"} ,
		{"word":"sélectionnez","word_nosc":"selectionnez","lemma":"sélectionner","pos":"VER"} ,
		{"word":"sélectionné","word_nosc":"selectionne","lemma":"sélectionner","pos":"VER"} ,
		{"word":"sélectionnée","word_nosc":"selectionnee","lemma":"sélectionner","pos":"VER"} ,
		{"word":"sélectionnées","word_nosc":"selectionnees","lemma":"sélectionner","pos":"VER"} ,
		{"word":"sélectionnés","word_nosc":"selectionnes","lemma":"sélectionner","pos":"VER"} ,
		{"word":"sélections","word_nosc":"selections","lemma":"sélecter","pos":"VER"} ,
		{"word":"sépara","word_nosc":"separa","lemma":"séparer","pos":"VER"} ,
		{"word":"séparai","word_nosc":"separai","lemma":"séparer","pos":"VER"} ,
		{"word":"séparaient","word_nosc":"separaient","lemma":"séparer","pos":"VER"} ,
		{"word":"séparais","word_nosc":"separais","lemma":"séparer","pos":"VER"} ,
		{"word":"séparait","word_nosc":"separait","lemma":"séparer","pos":"VER"} ,
		{"word":"séparant","word_nosc":"separant","lemma":"séparer","pos":"VER"} ,
		{"word":"sépare","word_nosc":"separe","lemma":"séparer","pos":"VER"} ,
		{"word":"séparent","word_nosc":"separent","lemma":"séparer","pos":"VER"} ,
		{"word":"séparer","word_nosc":"separer","lemma":"séparer","pos":"VER"} ,
		{"word":"séparera","word_nosc":"separera","lemma":"séparer","pos":"VER"} ,
		{"word":"séparerai","word_nosc":"separerai","lemma":"séparer","pos":"VER"} ,
		{"word":"sépareraient","word_nosc":"separeraient","lemma":"séparer","pos":"VER"} ,
		{"word":"séparerais","word_nosc":"separerais","lemma":"séparer","pos":"VER"} ,
		{"word":"séparerait","word_nosc":"separerait","lemma":"séparer","pos":"VER"} ,
		{"word":"sépareras","word_nosc":"separeras","lemma":"séparer","pos":"VER"} ,
		{"word":"séparerez","word_nosc":"separerez","lemma":"séparer","pos":"VER"} ,
		{"word":"séparerons","word_nosc":"separerons","lemma":"séparer","pos":"VER"} ,
		{"word":"sépareront","word_nosc":"separeront","lemma":"séparer","pos":"VER"} ,
		{"word":"séparez","word_nosc":"separez","lemma":"séparer","pos":"VER"} ,
		{"word":"sépariez","word_nosc":"separiez","lemma":"séparer","pos":"VER"} ,
		{"word":"séparions","word_nosc":"separions","lemma":"séparer","pos":"VER"} ,
		{"word":"séparons","word_nosc":"separons","lemma":"séparer","pos":"VER"} ,
		{"word":"séparâmes","word_nosc":"separames","lemma":"séparer","pos":"VER"} ,
		{"word":"séparât","word_nosc":"separat","lemma":"séparer","pos":"VER"} ,
		{"word":"séparèrent","word_nosc":"separerent","lemma":"séparer","pos":"VER"} ,
		{"word":"séparé","word_nosc":"separe","lemma":"séparer","pos":"VER"} ,
		{"word":"séparée","word_nosc":"separee","lemma":"séparer","pos":"VER"} ,
		{"word":"séparées","word_nosc":"separees","lemma":"séparer","pos":"VER"} ,
		{"word":"séparés","word_nosc":"separes","lemma":"séparer","pos":"VER"} ,
		{"word":"séquencer","word_nosc":"sequencer","lemma":"séquencer","pos":"VER"} ,
		{"word":"séquestraient","word_nosc":"sequestraient","lemma":"séquestrer","pos":"VER"} ,
		{"word":"séquestrait","word_nosc":"sequestrait","lemma":"séquestrer","pos":"VER"} ,
		{"word":"séquestre","word_nosc":"sequestre","lemma":"séquestrer","pos":"VER"} ,
		{"word":"séquestrer","word_nosc":"sequestrer","lemma":"séquestrer","pos":"VER"} ,
		{"word":"séquestrez","word_nosc":"sequestrez","lemma":"séquestrer","pos":"VER"} ,
		{"word":"séquestré","word_nosc":"sequestre","lemma":"séquestrer","pos":"VER"} ,
		{"word":"séquestrée","word_nosc":"sequestree","lemma":"séquestrer","pos":"VER"} ,
		{"word":"séquestrés","word_nosc":"sequestres","lemma":"séquestrer","pos":"VER"} ,
		{"word":"sériant","word_nosc":"seriant","lemma":"sérier","pos":"VER"} ,
		{"word":"sérier","word_nosc":"serier","lemma":"sérier","pos":"VER"} ,
		{"word":"sévi","word_nosc":"sevi","lemma":"sévir","pos":"VER"} ,
		{"word":"sévir","word_nosc":"sevir","lemma":"sévir","pos":"VER"} ,
		{"word":"sévira","word_nosc":"sevira","lemma":"sévir","pos":"VER"} ,
		{"word":"sévirai","word_nosc":"sevirai","lemma":"sévir","pos":"VER"} ,
		{"word":"sévissaient","word_nosc":"sevissaient","lemma":"sévir","pos":"VER"} ,
		{"word":"sévissais","word_nosc":"sevissais","lemma":"sévir","pos":"VER"} ,
		{"word":"sévissait","word_nosc":"sevissait","lemma":"sévir","pos":"VER"} ,
		{"word":"sévissant","word_nosc":"sevissant","lemma":"sévir","pos":"VER"} ,
		{"word":"sévisse","word_nosc":"sevisse","lemma":"sévir","pos":"VER"} ,
		{"word":"sévissent","word_nosc":"sevissent","lemma":"sévir","pos":"VER"} ,
		{"word":"sévit","word_nosc":"sevit","lemma":"sévir","pos":"VER"} ,
		{"word":"sévît","word_nosc":"sevit","lemma":"sévir","pos":"VER"} ,
		{"word":"sûmes","word_nosc":"sumes","lemma":"savoir","pos":"VER"} ,
		{"word":"sût","word_nosc":"sut","lemma":"savoir","pos":"VER"} ,
		{"word":"tabassaient","word_nosc":"tabassaient","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabassais","word_nosc":"tabassais","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabassait","word_nosc":"tabassait","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabassant","word_nosc":"tabassant","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabasse","word_nosc":"tabasse","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabassent","word_nosc":"tabassent","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabasser","word_nosc":"tabasser","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabassera","word_nosc":"tabassera","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabasseraient","word_nosc":"tabasseraient","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabasserais","word_nosc":"tabasserais","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabasses","word_nosc":"tabasses","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabassez","word_nosc":"tabassez","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabassons","word_nosc":"tabassons","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabassèrent","word_nosc":"tabasserent","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabassé","word_nosc":"tabasse","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabassée","word_nosc":"tabassee","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabassés","word_nosc":"tabasses","lemma":"tabasser","pos":"VER"} ,
		{"word":"tabla","word_nosc":"tabla","lemma":"tabler","pos":"VER"} ,
		{"word":"tablais","word_nosc":"tablais","lemma":"tabler","pos":"VER"} ,
		{"word":"tablant","word_nosc":"tablant","lemma":"tabler","pos":"VER"} ,
		{"word":"table","word_nosc":"table","lemma":"tabler","pos":"VER"} ,
		{"word":"tabler","word_nosc":"tabler","lemma":"tabler","pos":"VER"} ,
		{"word":"tablerez","word_nosc":"tablerez","lemma":"tabler","pos":"VER"} ,
		{"word":"tables","word_nosc":"tables","lemma":"tabler","pos":"VER"} ,
		{"word":"tablons","word_nosc":"tablons","lemma":"tabler","pos":"VER"} ,
		{"word":"tablé","word_nosc":"table","lemma":"tabler","pos":"VER"} ,
		{"word":"tablées","word_nosc":"tablees","lemma":"tabler","pos":"VER"} ,
		{"word":"taboue","word_nosc":"taboue","lemma":"tabouer","pos":"VER"} ,
		{"word":"tabula","word_nosc":"tabula","lemma":"tabuler","pos":"VER"} ,
		{"word":"tachaient","word_nosc":"tachaient","lemma":"tacher","pos":"VER"} ,
		{"word":"tachait","word_nosc":"tachait","lemma":"tacher","pos":"VER"} ,
		{"word":"tachant","word_nosc":"tachant","lemma":"tacher","pos":"VER"} ,
		{"word":"tache","word_nosc":"tache","lemma":"tacher","pos":"VER"} ,
		{"word":"tachent","word_nosc":"tachent","lemma":"tacher","pos":"VER"} ,
		{"word":"tacher","word_nosc":"tacher","lemma":"tacher","pos":"VER"} ,
		{"word":"tachera","word_nosc":"tachera","lemma":"tacher","pos":"VER"} ,
		{"word":"tacherai","word_nosc":"tacherai","lemma":"tacher","pos":"VER"} ,
		{"word":"tacherais","word_nosc":"tacherais","lemma":"tacher","pos":"VER"} ,
		{"word":"tacherait","word_nosc":"tacherait","lemma":"tacher","pos":"VER"} ,
		{"word":"taches","word_nosc":"taches","lemma":"tacher","pos":"VER"} ,
		{"word":"tachetaient","word_nosc":"tachetaient","lemma":"tacheter","pos":"VER"} ,
		{"word":"tachetant","word_nosc":"tachetant","lemma":"tacheter","pos":"VER"} ,
		{"word":"tacheter","word_nosc":"tacheter","lemma":"tacheter","pos":"VER"} ,
		{"word":"tacheté","word_nosc":"tachete","lemma":"tacheter","pos":"VER"} ,
		{"word":"tachetée","word_nosc":"tachetee","lemma":"tacheter","pos":"VER"} ,
		{"word":"tachetées","word_nosc":"tachetees","lemma":"tacheter","pos":"VER"} ,
		{"word":"tachetés","word_nosc":"tachetes","lemma":"tacheter","pos":"VER"} ,
		{"word":"tachez","word_nosc":"tachez","lemma":"tacher","pos":"VER"} ,
		{"word":"tachons","word_nosc":"tachons","lemma":"tacher","pos":"VER"} ,
		{"word":"tachycarde","word_nosc":"tachycarde","lemma":"tachycarder","pos":"VER"} ,
		{"word":"taché","word_nosc":"tache","lemma":"tacher","pos":"VER"} ,
		{"word":"tachée","word_nosc":"tachee","lemma":"tacher","pos":"VER"} ,
		{"word":"tachées","word_nosc":"tachees","lemma":"tacher","pos":"VER"} ,
		{"word":"tachés","word_nosc":"taches","lemma":"tacher","pos":"VER"} ,
		{"word":"tacler","word_nosc":"tacler","lemma":"tacler","pos":"VER"} ,
		{"word":"taguais","word_nosc":"taguais","lemma":"taguer","pos":"VER"} ,
		{"word":"tague","word_nosc":"tague","lemma":"taguer","pos":"VER"} ,
		{"word":"taguer","word_nosc":"taguer","lemma":"taguer","pos":"VER"} ,
		{"word":"tailla","word_nosc":"tailla","lemma":"tailler","pos":"VER"} ,
		{"word":"taillada","word_nosc":"taillada","lemma":"taillader","pos":"VER"} ,
		{"word":"tailladait","word_nosc":"tailladait","lemma":"taillader","pos":"VER"} ,
		{"word":"tailladant","word_nosc":"tailladant","lemma":"taillader","pos":"VER"} ,
		{"word":"taillade","word_nosc":"taillade","lemma":"taillader","pos":"VER"} ,
		{"word":"tailladent","word_nosc":"tailladent","lemma":"taillader","pos":"VER"} ,
		{"word":"taillader","word_nosc":"taillader","lemma":"taillader","pos":"VER"} ,
		{"word":"tailladez","word_nosc":"tailladez","lemma":"taillader","pos":"VER"} ,
		{"word":"tailladé","word_nosc":"taillade","lemma":"taillader","pos":"VER"} ,
		{"word":"tailladée","word_nosc":"tailladee","lemma":"taillader","pos":"VER"} ,
		{"word":"tailladées","word_nosc":"tailladees","lemma":"taillader","pos":"VER"} ,
		{"word":"tailladés","word_nosc":"taillades","lemma":"taillader","pos":"VER"} ,
		{"word":"taillaient","word_nosc":"taillaient","lemma":"tailler","pos":"VER"} ,
		{"word":"taillais","word_nosc":"taillais","lemma":"tailler","pos":"VER"} ,
		{"word":"taillait","word_nosc":"taillait","lemma":"tailler","pos":"VER"} ,
		{"word":"taillant","word_nosc":"taillant","lemma":"tailler","pos":"VER"} ,
		{"word":"taille","word_nosc":"taille","lemma":"tailler","pos":"VER"} ,
		{"word":"taillent","word_nosc":"taillent","lemma":"tailler","pos":"VER"} ,
		{"word":"tailler","word_nosc":"tailler","lemma":"tailler","pos":"VER"} ,
		{"word":"taillera","word_nosc":"taillera","lemma":"tailler","pos":"VER"} ,
		{"word":"taillerai","word_nosc":"taillerai","lemma":"tailler","pos":"VER"} ,
		{"word":"taillerais","word_nosc":"taillerais","lemma":"tailler","pos":"VER"} ,
		{"word":"taillerait","word_nosc":"taillerait","lemma":"tailler","pos":"VER"} ,
		{"word":"taillerez","word_nosc":"taillerez","lemma":"tailler","pos":"VER"} ,
		{"word":"tailleront","word_nosc":"tailleront","lemma":"tailler","pos":"VER"} ,
		{"word":"tailles","word_nosc":"tailles","lemma":"tailler","pos":"VER"} ,
		{"word":"taillez","word_nosc":"taillez","lemma":"tailler","pos":"VER"} ,
		{"word":"taillons","word_nosc":"taillons","lemma":"tailler","pos":"VER"} ,
		{"word":"taillèrent","word_nosc":"taillerent","lemma":"tailler","pos":"VER"} ,
		{"word":"taillé","word_nosc":"taille","lemma":"tailler","pos":"VER"} ,
		{"word":"taillée","word_nosc":"taillee","lemma":"tailler","pos":"VER"} ,
		{"word":"taillées","word_nosc":"taillees","lemma":"tailler","pos":"VER"} ,
		{"word":"taillés","word_nosc":"tailles","lemma":"tailler","pos":"VER"} ,
		{"word":"taira","word_nosc":"taira","lemma":"taire","pos":"VER"} ,
		{"word":"tairai","word_nosc":"tairai","lemma":"taire","pos":"VER"} ,
		{"word":"tairaient","word_nosc":"tairaient","lemma":"taire","pos":"VER"} ,
		{"word":"tairais","word_nosc":"tairais","lemma":"taire","pos":"VER"} ,
		{"word":"tairait","word_nosc":"tairait","lemma":"taire","pos":"VER"} ,
		{"word":"tairas","word_nosc":"tairas","lemma":"taire","pos":"VER"} ,
		{"word":"taire","word_nosc":"taire","lemma":"taire","pos":"VER"} ,
		{"word":"tairez","word_nosc":"tairez","lemma":"taire","pos":"VER"} ,
		{"word":"tairions","word_nosc":"tairions","lemma":"taire","pos":"VER"} ,
		{"word":"tairons","word_nosc":"tairons","lemma":"taire","pos":"VER"} ,
		{"word":"tairont","word_nosc":"tairont","lemma":"taire","pos":"VER"} ,
		{"word":"tais","word_nosc":"tais","lemma":"taire","pos":"VER"} ,
		{"word":"taisaient","word_nosc":"taisaient","lemma":"taire","pos":"VER"} ,
		{"word":"taisais","word_nosc":"taisais","lemma":"taire","pos":"VER"} ,
		{"word":"taisait","word_nosc":"taisait","lemma":"taire","pos":"VER"} ,
		{"word":"taisant","word_nosc":"taisant","lemma":"taire","pos":"VER"} ,
		{"word":"taise","word_nosc":"taise","lemma":"taire","pos":"VER"} ,
		{"word":"taisent","word_nosc":"taisent","lemma":"taire","pos":"VER"} ,
		{"word":"taises","word_nosc":"taises","lemma":"taire","pos":"VER"} ,
		{"word":"taisez","word_nosc":"taisez","lemma":"taire","pos":"VER"} ,
		{"word":"taisiez","word_nosc":"taisiez","lemma":"taire","pos":"VER"} ,
		{"word":"taisions","word_nosc":"taisions","lemma":"taire","pos":"VER"} ,
		{"word":"taisons","word_nosc":"taisons","lemma":"taire","pos":"VER"} ,
		{"word":"tait","word_nosc":"tait","lemma":"taire","pos":"VER"} ,
		{"word":"talait","word_nosc":"talait","lemma":"taler","pos":"VER"} ,
		{"word":"tale","word_nosc":"tale","lemma":"taler","pos":"VER"} ,
		{"word":"talent","word_nosc":"talent","lemma":"taler","pos":"VER"} ,
		{"word":"taler","word_nosc":"taler","lemma":"taler","pos":"VER"} ,
		{"word":"taller","word_nosc":"taller","lemma":"taller","pos":"VER"} ,
		{"word":"tallons","word_nosc":"tallons","lemma":"taller","pos":"VER"} ,
		{"word":"talochaient","word_nosc":"talochaient","lemma":"talocher","pos":"VER"} ,
		{"word":"talochait","word_nosc":"talochait","lemma":"talocher","pos":"VER"} ,
		{"word":"taloche","word_nosc":"taloche","lemma":"talocher","pos":"VER"} ,
		{"word":"talocher","word_nosc":"talocher","lemma":"talocher","pos":"VER"} ,
		{"word":"talonna","word_nosc":"talonna","lemma":"talonner","pos":"VER"} ,
		{"word":"talonnait","word_nosc":"talonnait","lemma":"talonner","pos":"VER"} ,
		{"word":"talonnant","word_nosc":"talonnant","lemma":"talonner","pos":"VER"} ,
		{"word":"talonne","word_nosc":"talonne","lemma":"talonner","pos":"VER"} ,
		{"word":"talonnent","word_nosc":"talonnent","lemma":"talonner","pos":"VER"} ,
		{"word":"talonner","word_nosc":"talonner","lemma":"talonner","pos":"VER"} ,
		{"word":"talonnons","word_nosc":"talonnons","lemma":"talonner","pos":"VER"} ,
		{"word":"talonné","word_nosc":"talonne","lemma":"talonner","pos":"VER"} ,
		{"word":"talonnée","word_nosc":"talonnee","lemma":"talonner","pos":"VER"} ,
		{"word":"talonnés","word_nosc":"talonnes","lemma":"talonner","pos":"VER"} ,
		{"word":"talons","word_nosc":"talons","lemma":"taler","pos":"VER"} ,
		{"word":"talqua","word_nosc":"talqua","lemma":"talquer","pos":"VER"} ,
		{"word":"talquer","word_nosc":"talquer","lemma":"talquer","pos":"VER"} ,
		{"word":"talquât","word_nosc":"talquat","lemma":"talquer","pos":"VER"} ,
		{"word":"talqué","word_nosc":"talque","lemma":"talquer","pos":"VER"} ,
		{"word":"talquées","word_nosc":"talquees","lemma":"talquer","pos":"VER"} ,
		{"word":"talée","word_nosc":"talee","lemma":"taler","pos":"VER"} ,
		{"word":"tambourina","word_nosc":"tambourina","lemma":"tambouriner","pos":"VER"} ,
		{"word":"tambourinaient","word_nosc":"tambourinaient","lemma":"tambouriner","pos":"VER"} ,
		{"word":"tambourinais","word_nosc":"tambourinais","lemma":"tambouriner","pos":"VER"} ,
		{"word":"tambourinait","word_nosc":"tambourinait","lemma":"tambouriner","pos":"VER"} ,
		{"word":"tambourinant","word_nosc":"tambourinant","lemma":"tambouriner","pos":"VER"} ,
		{"word":"tambourine","word_nosc":"tambourine","lemma":"tambouriner","pos":"VER"} ,
		{"word":"tambourinent","word_nosc":"tambourinent","lemma":"tambouriner","pos":"VER"} ,
		{"word":"tambouriner","word_nosc":"tambouriner","lemma":"tambouriner","pos":"VER"} ,
		{"word":"tambourinèrent","word_nosc":"tambourinerent","lemma":"tambouriner","pos":"VER"} ,
		{"word":"tambouriné","word_nosc":"tambourine","lemma":"tambouriner","pos":"VER"} ,
		{"word":"tambourinée","word_nosc":"tambourinee","lemma":"tambouriner","pos":"VER"} ,
		{"word":"tamisaient","word_nosc":"tamisaient","lemma":"tamiser","pos":"VER"} ,
		{"word":"tamisais","word_nosc":"tamisais","lemma":"tamiser","pos":"VER"} ,
		{"word":"tamisait","word_nosc":"tamisait","lemma":"tamiser","pos":"VER"} ,
		{"word":"tamisant","word_nosc":"tamisant","lemma":"tamiser","pos":"VER"} ,
		{"word":"tamise","word_nosc":"tamise","lemma":"tamiser","pos":"VER"} ,
		{"word":"tamisent","word_nosc":"tamisent","lemma":"tamiser","pos":"VER"} ,
		{"word":"tamiser","word_nosc":"tamiser","lemma":"tamiser","pos":"VER"} ,
		{"word":"tamisez","word_nosc":"tamisez","lemma":"tamiser","pos":"VER"} ,
		{"word":"tamisé","word_nosc":"tamise","lemma":"tamiser","pos":"VER"} ,
		{"word":"tamisée","word_nosc":"tamisee","lemma":"tamiser","pos":"VER"} ,
		{"word":"tamisées","word_nosc":"tamisees","lemma":"tamiser","pos":"VER"} ,
		{"word":"tamisés","word_nosc":"tamises","lemma":"tamiser","pos":"VER"} ,
		{"word":"tamponna","word_nosc":"tamponna","lemma":"tamponner","pos":"VER"} ,
		{"word":"tamponnai","word_nosc":"tamponnai","lemma":"tamponner","pos":"VER"} ,
		{"word":"tamponnaient","word_nosc":"tamponnaient","lemma":"tamponner","pos":"VER"} ,
		{"word":"tamponnais","word_nosc":"tamponnais","lemma":"tamponner","pos":"VER"} ,
		{"word":"tamponnait","word_nosc":"tamponnait","lemma":"tamponner","pos":"VER"} ,
		{"word":"tamponnant","word_nosc":"tamponnant","lemma":"tamponner","pos":"VER"} ,
		{"word":"tamponne","word_nosc":"tamponne","lemma":"tamponner","pos":"VER"} ,
		{"word":"tamponnent","word_nosc":"tamponnent","lemma":"tamponner","pos":"VER"} ,
		{"word":"tamponner","word_nosc":"tamponner","lemma":"tamponner","pos":"VER"} ,
		{"word":"tamponnera","word_nosc":"tamponnera","lemma":"tamponner","pos":"VER"} ,
		{"word":"tamponnez","word_nosc":"tamponnez","lemma":"tamponner","pos":"VER"} ,
		{"word":"tamponné","word_nosc":"tamponne","lemma":"tamponner","pos":"VER"} ,
		{"word":"tamponnée","word_nosc":"tamponnee","lemma":"tamponner","pos":"VER"} ,
		{"word":"tamponnés","word_nosc":"tamponnes","lemma":"tamponner","pos":"VER"} ,
		{"word":"tance","word_nosc":"tance","lemma":"tancer","pos":"VER"} ,
		{"word":"tancer","word_nosc":"tancer","lemma":"tancer","pos":"VER"} ,
		{"word":"tancé","word_nosc":"tance","lemma":"tancer","pos":"VER"} ,
		{"word":"tancée","word_nosc":"tancee","lemma":"tancer","pos":"VER"} ,
		{"word":"tancés","word_nosc":"tances","lemma":"tancer","pos":"VER"} ,
		{"word":"tangua","word_nosc":"tangua","lemma":"tanguer","pos":"VER"} ,
		{"word":"tanguaient","word_nosc":"tanguaient","lemma":"tanguer","pos":"VER"} ,
		{"word":"tanguait","word_nosc":"tanguait","lemma":"tanguer","pos":"VER"} ,
		{"word":"tanguant","word_nosc":"tanguant","lemma":"tanguer","pos":"VER"} ,
		{"word":"tangue","word_nosc":"tangue","lemma":"tanguer","pos":"VER"} ,
		{"word":"tanguent","word_nosc":"tanguent","lemma":"tanguer","pos":"VER"} ,
		{"word":"tanguer","word_nosc":"tanguer","lemma":"tanguer","pos":"VER"} ,
		{"word":"tangueraient","word_nosc":"tangueraient","lemma":"tanguer","pos":"VER"} ,
		{"word":"tangué","word_nosc":"tangue","lemma":"tanguer","pos":"VER"} ,
		{"word":"tanna","word_nosc":"tanna","lemma":"tanner","pos":"VER"} ,
		{"word":"tannaient","word_nosc":"tannaient","lemma":"tanner","pos":"VER"} ,
		{"word":"tannait","word_nosc":"tannait","lemma":"tanner","pos":"VER"} ,
		{"word":"tannant","word_nosc":"tannant","lemma":"tanner","pos":"VER"} ,
		{"word":"tannent","word_nosc":"tannent","lemma":"tanner","pos":"VER"} ,
		{"word":"tanner","word_nosc":"tanner","lemma":"tanner","pos":"VER"} ,
		{"word":"tannerai","word_nosc":"tannerai","lemma":"tanner","pos":"VER"} ,
		{"word":"tanné","word_nosc":"tanne","lemma":"tanner","pos":"VER"} ,
		{"word":"tannée","word_nosc":"tannee","lemma":"tanner","pos":"VER"} ,
		{"word":"tannées","word_nosc":"tannees","lemma":"tanner","pos":"VER"} ,
		{"word":"tannés","word_nosc":"tannes","lemma":"tanner","pos":"VER"} ,
		{"word":"tançai","word_nosc":"tancai","lemma":"tancer","pos":"VER"} ,
		{"word":"tançait","word_nosc":"tancait","lemma":"tancer","pos":"VER"} ,
		{"word":"tançant","word_nosc":"tancant","lemma":"tancer","pos":"VER"} ,
		{"word":"tapa","word_nosc":"tapa","lemma":"taper","pos":"VER"} ,
		{"word":"tapageait","word_nosc":"tapageait","lemma":"tapager","pos":"VER"} ,
		{"word":"tapai","word_nosc":"tapai","lemma":"taper","pos":"VER"} ,
		{"word":"tapaient","word_nosc":"tapaient","lemma":"taper","pos":"VER"} ,
		{"word":"tapais","word_nosc":"tapais","lemma":"taper","pos":"VER"} ,
		{"word":"tapait","word_nosc":"tapait","lemma":"taper","pos":"VER"} ,
		{"word":"tapant","word_nosc":"tapant","lemma":"taper","pos":"VER"} ,
		{"word":"tapas","word_nosc":"tapas","lemma":"taper","pos":"VER"} ,
		{"word":"tape","word_nosc":"tape","lemma":"taper","pos":"VER"} ,
		{"word":"tapent","word_nosc":"tapent","lemma":"taper","pos":"VER"} ,
		{"word":"taper","word_nosc":"taper","lemma":"taper","pos":"VER"} ,
		{"word":"tapera","word_nosc":"tapera","lemma":"taper","pos":"VER"} ,
		{"word":"taperai","word_nosc":"taperai","lemma":"taper","pos":"VER"} ,
		{"word":"taperaient","word_nosc":"taperaient","lemma":"taper","pos":"VER"} ,
		{"word":"taperais","word_nosc":"taperais","lemma":"taper","pos":"VER"} ,
		{"word":"taperait","word_nosc":"taperait","lemma":"taper","pos":"VER"} ,
		{"word":"taperas","word_nosc":"taperas","lemma":"taper","pos":"VER"} ,
		{"word":"taperez","word_nosc":"taperez","lemma":"taper","pos":"VER"} ,
		{"word":"taperiez","word_nosc":"taperiez","lemma":"taper","pos":"VER"} ,
		{"word":"taperont","word_nosc":"taperont","lemma":"taper","pos":"VER"} ,
		{"word":"tapes","word_nosc":"tapes","lemma":"taper","pos":"VER"} ,
		{"word":"tapez","word_nosc":"tapez","lemma":"taper","pos":"VER"} ,
		{"word":"tapi","word_nosc":"tapi","lemma":"tapir","pos":"VER"} ,
		{"word":"tapie","word_nosc":"tapie","lemma":"tapir","pos":"VER"} ,
		{"word":"tapies","word_nosc":"tapies","lemma":"tapir","pos":"VER"} ,
		{"word":"tapiez","word_nosc":"tapiez","lemma":"taper","pos":"VER"} ,
		{"word":"tapinaient","word_nosc":"tapinaient","lemma":"tapiner","pos":"VER"} ,
		{"word":"tapinais","word_nosc":"tapinais","lemma":"tapiner","pos":"VER"} ,
		{"word":"tapinait","word_nosc":"tapinait","lemma":"tapiner","pos":"VER"} ,
		{"word":"tapinant","word_nosc":"tapinant","lemma":"tapiner","pos":"VER"} ,
		{"word":"tapine","word_nosc":"tapine","lemma":"tapiner","pos":"VER"} ,
		{"word":"tapinent","word_nosc":"tapinent","lemma":"tapiner","pos":"VER"} ,
		{"word":"tapiner","word_nosc":"tapiner","lemma":"tapiner","pos":"VER"} ,
		{"word":"tapinera","word_nosc":"tapinera","lemma":"tapiner","pos":"VER"} ,
		{"word":"tapinerai","word_nosc":"tapinerai","lemma":"tapiner","pos":"VER"} ,
		{"word":"tapinerais","word_nosc":"tapinerais","lemma":"tapiner","pos":"VER"} ,
		{"word":"tapines","word_nosc":"tapines","lemma":"tapiner","pos":"VER"} ,
		{"word":"tapiné","word_nosc":"tapine","lemma":"tapiner","pos":"VER"} ,
		{"word":"tapinée","word_nosc":"tapinee","lemma":"tapiner","pos":"VER"} ,
		{"word":"tapir","word_nosc":"tapir","lemma":"tapir","pos":"VER"} ,
		{"word":"tapira","word_nosc":"tapira","lemma":"tapir","pos":"VER"} ,
		{"word":"tapis","word_nosc":"tapis","lemma":"tapir","pos":"VER"} ,
		{"word":"tapissa","word_nosc":"tapissa","lemma":"tapisser","pos":"VER"} ,
		{"word":"tapissaient","word_nosc":"tapissaient","lemma":"tapisser","pos":"VER"} ,
		{"word":"tapissais","word_nosc":"tapissais","lemma":"tapisser","pos":"VER"} ,
		{"word":"tapissait","word_nosc":"tapissait","lemma":"tapisser","pos":"VER"} ,
		{"word":"tapissant","word_nosc":"tapissant","lemma":"tapisser","pos":"VER"} ,
		{"word":"tapisse","word_nosc":"tapisse","lemma":"tapisser","pos":"VER"} ,
		{"word":"tapissent","word_nosc":"tapissent","lemma":"tapir","pos":"VER"} ,
		{"word":"tapissent","word_nosc":"tapissent","lemma":"tapisser","pos":"VER"} ,
		{"word":"tapisser","word_nosc":"tapisser","lemma":"tapisser","pos":"VER"} ,
		{"word":"tapisserai","word_nosc":"tapisserai","lemma":"tapisser","pos":"VER"} ,
		{"word":"tapissé","word_nosc":"tapisse","lemma":"tapisser","pos":"VER"} ,
		{"word":"tapissée","word_nosc":"tapissee","lemma":"tapisser","pos":"VER"} ,
		{"word":"tapissées","word_nosc":"tapissees","lemma":"tapisser","pos":"VER"} ,
		{"word":"tapissés","word_nosc":"tapisses","lemma":"tapisser","pos":"VER"} ,
		{"word":"tapit","word_nosc":"tapit","lemma":"tapir","pos":"VER"} ,
		{"word":"tapons","word_nosc":"tapons","lemma":"taper","pos":"VER"} ,
		{"word":"tapota","word_nosc":"tapota","lemma":"tapoter","pos":"VER"} ,
		{"word":"tapotai","word_nosc":"tapotai","lemma":"tapoter","pos":"VER"} ,
		{"word":"tapotaient","word_nosc":"tapotaient","lemma":"tapoter","pos":"VER"} ,
		{"word":"tapotais","word_nosc":"tapotais","lemma":"tapoter","pos":"VER"} ,
		{"word":"tapotait","word_nosc":"tapotait","lemma":"tapoter","pos":"VER"} ,
		{"word":"tapotant","word_nosc":"tapotant","lemma":"tapoter","pos":"VER"} ,
		{"word":"tapote","word_nosc":"tapote","lemma":"tapoter","pos":"VER"} ,
		{"word":"tapotent","word_nosc":"tapotent","lemma":"tapoter","pos":"VER"} ,
		{"word":"tapoter","word_nosc":"tapoter","lemma":"tapoter","pos":"VER"} ,
		{"word":"tapotez","word_nosc":"tapotez","lemma":"tapoter","pos":"VER"} ,
		{"word":"tapotons","word_nosc":"tapotons","lemma":"tapoter","pos":"VER"} ,
		{"word":"tapotèrent","word_nosc":"tapoterent","lemma":"tapoter","pos":"VER"} ,
		{"word":"tapoté","word_nosc":"tapote","lemma":"tapoter","pos":"VER"} ,
		{"word":"tapâmes","word_nosc":"tapames","lemma":"taper","pos":"VER"} ,
		{"word":"tapèrent","word_nosc":"taperent","lemma":"taper","pos":"VER"} ,
		{"word":"tapé","word_nosc":"tape","lemma":"taper","pos":"VER"} ,
		{"word":"tapée","word_nosc":"tapee","lemma":"taper","pos":"VER"} ,
		{"word":"tapées","word_nosc":"tapees","lemma":"taper","pos":"VER"} ,
		{"word":"tapés","word_nosc":"tapes","lemma":"taper","pos":"VER"} ,
		{"word":"taquina","word_nosc":"taquina","lemma":"taquiner","pos":"VER"} ,
		{"word":"taquinaient","word_nosc":"taquinaient","lemma":"taquiner","pos":"VER"} ,
		{"word":"taquinais","word_nosc":"taquinais","lemma":"taquiner","pos":"VER"} ,
		{"word":"taquinait","word_nosc":"taquinait","lemma":"taquiner","pos":"VER"} ,
		{"word":"taquinant","word_nosc":"taquinant","lemma":"taquiner","pos":"VER"} ,
		{"word":"taquine","word_nosc":"taquine","lemma":"taquiner","pos":"VER"} ,
		{"word":"taquinent","word_nosc":"taquinent","lemma":"taquiner","pos":"VER"} ,
		{"word":"taquiner","word_nosc":"taquiner","lemma":"taquiner","pos":"VER"} ,
		{"word":"taquinerait","word_nosc":"taquinerait","lemma":"taquiner","pos":"VER"} ,
		{"word":"taquines","word_nosc":"taquines","lemma":"taquiner","pos":"VER"} ,
		{"word":"taquinez","word_nosc":"taquinez","lemma":"taquiner","pos":"VER"} ,
		{"word":"taquiné","word_nosc":"taquine","lemma":"taquiner","pos":"VER"} ,
		{"word":"taquinée","word_nosc":"taquinee","lemma":"taquiner","pos":"VER"} ,
		{"word":"tara","word_nosc":"tara","lemma":"tarer","pos":"VER"} ,
		{"word":"tarabiscotée","word_nosc":"tarabiscotee","lemma":"tarabiscoter","pos":"VER"} ,
		{"word":"tarabiscotés","word_nosc":"tarabiscotes","lemma":"tarabiscoter","pos":"VER"} ,
		{"word":"tarabustait","word_nosc":"tarabustait","lemma":"tarabuster","pos":"VER"} ,
		{"word":"tarabuste","word_nosc":"tarabuste","lemma":"tarabuster","pos":"VER"} ,
		{"word":"tarabustent","word_nosc":"tarabustent","lemma":"tarabuster","pos":"VER"} ,
		{"word":"tarabuster","word_nosc":"tarabuster","lemma":"tarabuster","pos":"VER"} ,
		{"word":"tarabustes","word_nosc":"tarabustes","lemma":"tarabuster","pos":"VER"} ,
		{"word":"tarabustèrent","word_nosc":"tarabusterent","lemma":"tarabuster","pos":"VER"} ,
		{"word":"tarabusté","word_nosc":"tarabuste","lemma":"tarabuster","pos":"VER"} ,
		{"word":"tarabustés","word_nosc":"tarabustes","lemma":"tarabuster","pos":"VER"} ,
		{"word":"taraudaient","word_nosc":"taraudaient","lemma":"tarauder","pos":"VER"} ,
		{"word":"taraudait","word_nosc":"taraudait","lemma":"tarauder","pos":"VER"} ,
		{"word":"taraude","word_nosc":"taraude","lemma":"tarauder","pos":"VER"} ,
		{"word":"taraudent","word_nosc":"taraudent","lemma":"tarauder","pos":"VER"} ,
		{"word":"tarauder","word_nosc":"tarauder","lemma":"tarauder","pos":"VER"} ,
		{"word":"taraudèrent","word_nosc":"tarauderent","lemma":"tarauder","pos":"VER"} ,
		{"word":"taraudé","word_nosc":"taraude","lemma":"tarauder","pos":"VER"} ,
		{"word":"taraudée","word_nosc":"taraudee","lemma":"tarauder","pos":"VER"} ,
		{"word":"taraudées","word_nosc":"taraudees","lemma":"tarauder","pos":"VER"} ,
		{"word":"taraudés","word_nosc":"taraudes","lemma":"tarauder","pos":"VER"} ,
		{"word":"tarda","word_nosc":"tarda","lemma":"tarder","pos":"VER"} ,
		{"word":"tardai","word_nosc":"tardai","lemma":"tarder","pos":"VER"} ,
		{"word":"tardaient","word_nosc":"tardaient","lemma":"tarder","pos":"VER"} ,
		{"word":"tardais","word_nosc":"tardais","lemma":"tarder","pos":"VER"} ,
		{"word":"tardait","word_nosc":"tardait","lemma":"tarder","pos":"VER"} ,
		{"word":"tardant","word_nosc":"tardant","lemma":"tarder","pos":"VER"} ,
		{"word":"tarde","word_nosc":"tarde","lemma":"tarder","pos":"VER"} ,
		{"word":"tardent","word_nosc":"tardent","lemma":"tarder","pos":"VER"} ,
		{"word":"tarder","word_nosc":"tarder","lemma":"tarder","pos":"VER"} ,
		{"word":"tardera","word_nosc":"tardera","lemma":"tarder","pos":"VER"} ,
		{"word":"tarderai","word_nosc":"tarderai","lemma":"tarder","pos":"VER"} ,
		{"word":"tarderaient","word_nosc":"tarderaient","lemma":"tarder","pos":"VER"} ,
		{"word":"tarderais","word_nosc":"tarderais","lemma":"tarder","pos":"VER"} ,
		{"word":"tarderait","word_nosc":"tarderait","lemma":"tarder","pos":"VER"} ,
		{"word":"tarderas","word_nosc":"tarderas","lemma":"tarder","pos":"VER"} ,
		{"word":"tarderez","word_nosc":"tarderez","lemma":"tarder","pos":"VER"} ,
		{"word":"tarderiez","word_nosc":"tarderiez","lemma":"tarder","pos":"VER"} ,
		{"word":"tarderions","word_nosc":"tarderions","lemma":"tarder","pos":"VER"} ,
		{"word":"tarderons","word_nosc":"tarderons","lemma":"tarder","pos":"VER"} ,
		{"word":"tarderont","word_nosc":"tarderont","lemma":"tarder","pos":"VER"} ,
		{"word":"tardes","word_nosc":"tardes","lemma":"tarder","pos":"VER"} ,
		{"word":"tardez","word_nosc":"tardez","lemma":"tarder","pos":"VER"} ,
		{"word":"tardiez","word_nosc":"tardiez","lemma":"tarder","pos":"VER"} ,
		{"word":"tardions","word_nosc":"tardions","lemma":"tarder","pos":"VER"} ,
		{"word":"tardons","word_nosc":"tardons","lemma":"tarder","pos":"VER"} ,
		{"word":"tardâmes","word_nosc":"tardames","lemma":"tarder","pos":"VER"} ,
		{"word":"tardèrent","word_nosc":"tarderent","lemma":"tarder","pos":"VER"} ,
		{"word":"tardé","word_nosc":"tarde","lemma":"tarder","pos":"VER"} ,
		{"word":"tardée","word_nosc":"tardee","lemma":"tarder","pos":"VER"} ,
		{"word":"tares","word_nosc":"tares","lemma":"tarer","pos":"VER"} ,
		{"word":"targua","word_nosc":"targua","lemma":"targuer","pos":"VER"} ,
		{"word":"targuaient","word_nosc":"targuaient","lemma":"targuer","pos":"VER"} ,
		{"word":"targuais","word_nosc":"targuais","lemma":"targuer","pos":"VER"} ,
		{"word":"targuait","word_nosc":"targuait","lemma":"targuer","pos":"VER"} ,
		{"word":"targuant","word_nosc":"targuant","lemma":"targuer","pos":"VER"} ,
		{"word":"targue","word_nosc":"targue","lemma":"targuer","pos":"VER"} ,
		{"word":"targuent","word_nosc":"targuent","lemma":"targuer","pos":"VER"} ,
		{"word":"targuer","word_nosc":"targuer","lemma":"targuer","pos":"VER"} ,
		{"word":"targuez","word_nosc":"targuez","lemma":"targuer","pos":"VER"} ,
		{"word":"targuât","word_nosc":"targuat","lemma":"targuer","pos":"VER"} ,
		{"word":"targué","word_nosc":"targue","lemma":"targuer","pos":"VER"} ,
		{"word":"tari","word_nosc":"tari","lemma":"tarir","pos":"VER"} ,
		{"word":"tarie","word_nosc":"tarie","lemma":"tarir","pos":"VER"} ,
		{"word":"taries","word_nosc":"taries","lemma":"tarir","pos":"VER"} ,
		{"word":"tarifié","word_nosc":"tarifie","lemma":"tarifier","pos":"VER"} ,
		{"word":"tarifée","word_nosc":"tarifee","lemma":"tarifer","pos":"VER"} ,
		{"word":"tarifées","word_nosc":"tarifees","lemma":"tarifer","pos":"VER"} ,
		{"word":"tarifés","word_nosc":"tarifes","lemma":"tarifer","pos":"VER"} ,
		{"word":"tarir","word_nosc":"tarir","lemma":"tarir","pos":"VER"} ,
		{"word":"tarira","word_nosc":"tarira","lemma":"tarir","pos":"VER"} ,
		{"word":"tarirent","word_nosc":"tarirent","lemma":"tarir","pos":"VER"} ,
		{"word":"taris","word_nosc":"taris","lemma":"tarir","pos":"VER"} ,
		{"word":"tarissaient","word_nosc":"tarissaient","lemma":"tarir","pos":"VER"} ,
		{"word":"tarissait","word_nosc":"tarissait","lemma":"tarir","pos":"VER"} ,
		{"word":"tarissant","word_nosc":"tarissant","lemma":"tarir","pos":"VER"} ,
		{"word":"tarisse","word_nosc":"tarisse","lemma":"tarir","pos":"VER"} ,
		{"word":"tarissent","word_nosc":"tarissent","lemma":"tarir","pos":"VER"} ,
		{"word":"tarissez","word_nosc":"tarissez","lemma":"tarir","pos":"VER"} ,
		{"word":"tarit","word_nosc":"tarit","lemma":"tarir","pos":"VER"} ,
		{"word":"tartina","word_nosc":"tartina","lemma":"tartiner","pos":"VER"} ,
		{"word":"tartinaient","word_nosc":"tartinaient","lemma":"tartiner","pos":"VER"} ,
		{"word":"tartinais","word_nosc":"tartinais","lemma":"tartiner","pos":"VER"} ,
		{"word":"tartinait","word_nosc":"tartinait","lemma":"tartiner","pos":"VER"} ,
		{"word":"tartinant","word_nosc":"tartinant","lemma":"tartiner","pos":"VER"} ,
		{"word":"tartine","word_nosc":"tartine","lemma":"tartiner","pos":"VER"} ,
		{"word":"tartinent","word_nosc":"tartinent","lemma":"tartiner","pos":"VER"} ,
		{"word":"tartiner","word_nosc":"tartiner","lemma":"tartiner","pos":"VER"} ,
		{"word":"tartinerait","word_nosc":"tartinerait","lemma":"tartiner","pos":"VER"} ,
		{"word":"tartines","word_nosc":"tartines","lemma":"tartiner","pos":"VER"} ,
		{"word":"tartinez","word_nosc":"tartinez","lemma":"tartiner","pos":"VER"} ,
		{"word":"tartiné","word_nosc":"tartine","lemma":"tartiner","pos":"VER"} ,
		{"word":"tartinée","word_nosc":"tartinee","lemma":"tartiner","pos":"VER"} ,
		{"word":"tartinées","word_nosc":"tartinees","lemma":"tartiner","pos":"VER"} ,
		{"word":"tartir","word_nosc":"tartir","lemma":"tartir","pos":"VER"} ,
		{"word":"tartissent","word_nosc":"tartissent","lemma":"tartir","pos":"VER"} ,
		{"word":"tartisses","word_nosc":"tartisses","lemma":"tartir","pos":"VER"} ,
		{"word":"taré","word_nosc":"tare","lemma":"tarer","pos":"VER"} ,
		{"word":"tarée","word_nosc":"taree","lemma":"tarer","pos":"VER"} ,
		{"word":"tarées","word_nosc":"tarees","lemma":"tarer","pos":"VER"} ,
		{"word":"tarés","word_nosc":"tares","lemma":"tarer","pos":"VER"} ,
		{"word":"tassa","word_nosc":"tassa","lemma":"tasser","pos":"VER"} ,
		{"word":"tassai","word_nosc":"tassai","lemma":"tasser","pos":"VER"} ,
		{"word":"tassaient","word_nosc":"tassaient","lemma":"tasser","pos":"VER"} ,
		{"word":"tassais","word_nosc":"tassais","lemma":"tasser","pos":"VER"} ,
		{"word":"tassait","word_nosc":"tassait","lemma":"tasser","pos":"VER"} ,
		{"word":"tassant","word_nosc":"tassant","lemma":"tasser","pos":"VER"} ,
		{"word":"tasse","word_nosc":"tasse","lemma":"tasser","pos":"VER"} ,
		{"word":"tassent","word_nosc":"tassent","lemma":"tasser","pos":"VER"} ,
		{"word":"tasser","word_nosc":"tasser","lemma":"tasser","pos":"VER"} ,
		{"word":"tassera","word_nosc":"tassera","lemma":"tasser","pos":"VER"} ,
		{"word":"tasserai","word_nosc":"tasserai","lemma":"tasser","pos":"VER"} ,
		{"word":"tasserait","word_nosc":"tasserait","lemma":"tasser","pos":"VER"} ,
		{"word":"tasses","word_nosc":"tasses","lemma":"tasser","pos":"VER"} ,
		{"word":"tassez","word_nosc":"tassez","lemma":"tasser","pos":"VER"} ,
		{"word":"tassiez","word_nosc":"tassiez","lemma":"tasser","pos":"VER"} ,
		{"word":"tassons","word_nosc":"tassons","lemma":"tasser","pos":"VER"} ,
		{"word":"tassèrent","word_nosc":"tasserent","lemma":"tasser","pos":"VER"} ,
		{"word":"tassé","word_nosc":"tasse","lemma":"tasser","pos":"VER"} ,
		{"word":"tassée","word_nosc":"tassee","lemma":"tasser","pos":"VER"} ,
		{"word":"tassées","word_nosc":"tassees","lemma":"tasser","pos":"VER"} ,
		{"word":"tassés","word_nosc":"tasses","lemma":"tasser","pos":"VER"} ,
		{"word":"tatillonner","word_nosc":"tatillonner","lemma":"tatillonner","pos":"VER"} ,
		{"word":"tatouaient","word_nosc":"tatouaient","lemma":"tatouer","pos":"VER"} ,
		{"word":"tatouait","word_nosc":"tatouait","lemma":"tatouer","pos":"VER"} ,
		{"word":"tatoue","word_nosc":"tatoue","lemma":"tatouer","pos":"VER"} ,
		{"word":"tatouent","word_nosc":"tatouent","lemma":"tatouer","pos":"VER"} ,
		{"word":"tatouer","word_nosc":"tatouer","lemma":"tatouer","pos":"VER"} ,
		{"word":"tatouez","word_nosc":"tatouez","lemma":"tatouer","pos":"VER"} ,
		{"word":"tatoué","word_nosc":"tatoue","lemma":"tatouer","pos":"VER"} ,
		{"word":"tatouée","word_nosc":"tatouee","lemma":"tatouer","pos":"VER"} ,
		{"word":"tatouées","word_nosc":"tatouees","lemma":"tatouer","pos":"VER"} ,
		{"word":"tatoués","word_nosc":"tatoues","lemma":"tatouer","pos":"VER"} ,
		{"word":"tavelant","word_nosc":"tavelant","lemma":"taveler","pos":"VER"} ,
		{"word":"tavelé","word_nosc":"tavele","lemma":"taveler","pos":"VER"} ,
		{"word":"tavelée","word_nosc":"tavelee","lemma":"taveler","pos":"VER"} ,
		{"word":"tavelées","word_nosc":"tavelees","lemma":"taveler","pos":"VER"} ,
		{"word":"taxa","word_nosc":"taxa","lemma":"taxer","pos":"VER"} ,
		{"word":"taxaient","word_nosc":"taxaient","lemma":"taxer","pos":"VER"} ,
		{"word":"taxait","word_nosc":"taxait","lemma":"taxer","pos":"VER"} ,
		{"word":"taxant","word_nosc":"taxant","lemma":"taxer","pos":"VER"} ,
		{"word":"taxe","word_nosc":"taxe","lemma":"taxer","pos":"VER"} ,
		{"word":"taxent","word_nosc":"taxent","lemma":"taxer","pos":"VER"} ,
		{"word":"taxer","word_nosc":"taxer","lemma":"taxer","pos":"VER"} ,
		{"word":"taxerait","word_nosc":"taxerait","lemma":"taxer","pos":"VER"} ,
		{"word":"taxes","word_nosc":"taxes","lemma":"taxer","pos":"VER"} ,
		{"word":"taxez","word_nosc":"taxez","lemma":"taxer","pos":"VER"} ,
		{"word":"taxé","word_nosc":"taxe","lemma":"taxer","pos":"VER"} ,
		{"word":"taxée","word_nosc":"taxee","lemma":"taxer","pos":"VER"} ,
		{"word":"taxés","word_nosc":"taxes","lemma":"taxer","pos":"VER"} ,
		{"word":"taylorisé","word_nosc":"taylorise","lemma":"tayloriser","pos":"VER"} ,
		{"word":"tchatchant","word_nosc":"tchatchant","lemma":"tchatcher","pos":"VER"} ,
		{"word":"tchatche","word_nosc":"tchatche","lemma":"tchatcher","pos":"VER"} ,
		{"word":"tchatchent","word_nosc":"tchatchent","lemma":"tchatcher","pos":"VER"} ,
		{"word":"tchatcher","word_nosc":"tchatcher","lemma":"tchatcher","pos":"VER"} ,
		{"word":"tchatches","word_nosc":"tchatches","lemma":"tchatcher","pos":"VER"} ,
		{"word":"tchatché","word_nosc":"tchatche","lemma":"tchatcher","pos":"VER"} ,
		{"word":"teignais","word_nosc":"teignais","lemma":"teindre","pos":"VER"} ,
		{"word":"teignait","word_nosc":"teignait","lemma":"teindre","pos":"VER"} ,
		{"word":"teignant","word_nosc":"teignant","lemma":"teindre","pos":"VER"} ,
		{"word":"teigne","word_nosc":"teigne","lemma":"teindre","pos":"VER"} ,
		{"word":"teignent","word_nosc":"teignent","lemma":"teindre","pos":"VER"} ,
		{"word":"teignit","word_nosc":"teignit","lemma":"teindre","pos":"VER"} ,
		{"word":"teille","word_nosc":"teille","lemma":"teiller","pos":"VER"} ,
		{"word":"teindra","word_nosc":"teindra","lemma":"teindre","pos":"VER"} ,
		{"word":"teindrai","word_nosc":"teindrai","lemma":"teindre","pos":"VER"} ,
		{"word":"teindrait","word_nosc":"teindrait","lemma":"teindre","pos":"VER"} ,
		{"word":"teindre","word_nosc":"teindre","lemma":"teindre","pos":"VER"} ,
		{"word":"teins","word_nosc":"teins","lemma":"teindre","pos":"VER"} ,
		{"word":"teint","word_nosc":"teint","lemma":"teindre","pos":"VER"} ,
		{"word":"teinta","word_nosc":"teinta","lemma":"teinter","pos":"VER"} ,
		{"word":"teintaient","word_nosc":"teintaient","lemma":"teinter","pos":"VER"} ,
		{"word":"teintait","word_nosc":"teintait","lemma":"teinter","pos":"VER"} ,
		{"word":"teintant","word_nosc":"teintant","lemma":"teinter","pos":"VER"} ,
		{"word":"teinte","word_nosc":"teinte","lemma":"teinter","pos":"VER"} ,
		{"word":"teintent","word_nosc":"teintent","lemma":"teinter","pos":"VER"} ,
		{"word":"teinter","word_nosc":"teinter","lemma":"teinter","pos":"VER"} ,
		{"word":"teinteraient","word_nosc":"teinteraient","lemma":"teinter","pos":"VER"} ,
		{"word":"teints","word_nosc":"teints","lemma":"teindre","pos":"VER"} ,
		{"word":"teinté","word_nosc":"teinte","lemma":"teinter","pos":"VER"} ,
		{"word":"teintée","word_nosc":"teintee","lemma":"teinter","pos":"VER"} ,
		{"word":"teintées","word_nosc":"teintees","lemma":"teinter","pos":"VER"} ,
		{"word":"teintés","word_nosc":"teintes","lemma":"teinter","pos":"VER"} ,
		{"word":"temporisaient","word_nosc":"temporisaient","lemma":"temporiser","pos":"VER"} ,
		{"word":"temporisait","word_nosc":"temporisait","lemma":"temporiser","pos":"VER"} ,
		{"word":"temporise","word_nosc":"temporise","lemma":"temporiser","pos":"VER"} ,
		{"word":"temporisent","word_nosc":"temporisent","lemma":"temporiser","pos":"VER"} ,
		{"word":"temporiser","word_nosc":"temporiser","lemma":"temporiser","pos":"VER"} ,
		{"word":"temporiserai","word_nosc":"temporiserai","lemma":"temporiser","pos":"VER"} ,
		{"word":"temporisez","word_nosc":"temporisez","lemma":"temporiser","pos":"VER"} ,
		{"word":"temporisons","word_nosc":"temporisons","lemma":"temporiser","pos":"VER"} ,
		{"word":"temporisé","word_nosc":"temporise","lemma":"temporiser","pos":"VER"} ,
		{"word":"temporisée","word_nosc":"temporisee","lemma":"temporiser","pos":"VER"} ,
		{"word":"tempère","word_nosc":"tempere","lemma":"tempérer","pos":"VER"} ,
		{"word":"tempéra","word_nosc":"tempera","lemma":"tempérer","pos":"VER"} ,
		{"word":"tempéraient","word_nosc":"temperaient","lemma":"tempérer","pos":"VER"} ,
		{"word":"tempérait","word_nosc":"temperait","lemma":"tempérer","pos":"VER"} ,
		{"word":"tempérer","word_nosc":"temperer","lemma":"tempérer","pos":"VER"} ,
		{"word":"tempéreraient","word_nosc":"tempereraient","lemma":"tempérer","pos":"VER"} ,
		{"word":"tempérerait","word_nosc":"tempererait","lemma":"tempérer","pos":"VER"} ,
		{"word":"tempéré","word_nosc":"tempere","lemma":"tempérer","pos":"VER"} ,
		{"word":"tempérée","word_nosc":"temperee","lemma":"tempérer","pos":"VER"} ,
		{"word":"tempérées","word_nosc":"temperees","lemma":"tempérer","pos":"VER"} ,
		{"word":"tempérés","word_nosc":"temperes","lemma":"tempérer","pos":"VER"} ,
		{"word":"tempêta","word_nosc":"tempeta","lemma":"tempêter","pos":"VER"} ,
		{"word":"tempêtaient","word_nosc":"tempetaient","lemma":"tempêter","pos":"VER"} ,
		{"word":"tempêtais","word_nosc":"tempetais","lemma":"tempêter","pos":"VER"} ,
		{"word":"tempêtait","word_nosc":"tempetait","lemma":"tempêter","pos":"VER"} ,
		{"word":"tempêtant","word_nosc":"tempetant","lemma":"tempêter","pos":"VER"} ,
		{"word":"tempête","word_nosc":"tempete","lemma":"tempêter","pos":"VER"} ,
		{"word":"tempêtent","word_nosc":"tempetent","lemma":"tempêter","pos":"VER"} ,
		{"word":"tempêter","word_nosc":"tempeter","lemma":"tempêter","pos":"VER"} ,
		{"word":"tempêté","word_nosc":"tempete","lemma":"tempêter","pos":"VER"} ,
		{"word":"tenaient","word_nosc":"tenaient","lemma":"tenir","pos":"VER"} ,
		{"word":"tenaillaient","word_nosc":"tenaillaient","lemma":"tenailler","pos":"VER"} ,
		{"word":"tenaillait","word_nosc":"tenaillait","lemma":"tenailler","pos":"VER"} ,
		{"word":"tenaillant","word_nosc":"tenaillant","lemma":"tenailler","pos":"VER"} ,
		{"word":"tenaille","word_nosc":"tenaille","lemma":"tenailler","pos":"VER"} ,
		{"word":"tenaillent","word_nosc":"tenaillent","lemma":"tenailler","pos":"VER"} ,
		{"word":"tenailler","word_nosc":"tenailler","lemma":"tenailler","pos":"VER"} ,
		{"word":"tenaillé","word_nosc":"tenaille","lemma":"tenailler","pos":"VER"} ,
		{"word":"tenaillée","word_nosc":"tenaillee","lemma":"tenailler","pos":"VER"} ,
		{"word":"tenais","word_nosc":"tenais","lemma":"tenir","pos":"VER"} ,
		{"word":"tenait","word_nosc":"tenait","lemma":"tenir","pos":"VER"} ,
		{"word":"tenant","word_nosc":"tenant","lemma":"tenir","pos":"VER"} ,
		{"word":"tend","word_nosc":"tend","lemma":"tendre","pos":"VER"} ,
		{"word":"tendaient","word_nosc":"tendaient","lemma":"tendre","pos":"VER"} ,
		{"word":"tendais","word_nosc":"tendais","lemma":"tendre","pos":"VER"} ,
		{"word":"tendait","word_nosc":"tendait","lemma":"tendre","pos":"VER"} ,
		{"word":"tendant","word_nosc":"tendant","lemma":"tendre","pos":"VER"} ,
		{"word":"tende","word_nosc":"tende","lemma":"tendre","pos":"VER"} ,
		{"word":"tendent","word_nosc":"tendent","lemma":"tendre","pos":"VER"} ,
		{"word":"tendez","word_nosc":"tendez","lemma":"tendre","pos":"VER"} ,
		{"word":"tendiez","word_nosc":"tendiez","lemma":"tendre","pos":"VER"} ,
		{"word":"tendions","word_nosc":"tendions","lemma":"tendre","pos":"VER"} ,
		{"word":"tendirent","word_nosc":"tendirent","lemma":"tendre","pos":"VER"} ,
		{"word":"tendis","word_nosc":"tendis","lemma":"tendre","pos":"VER"} ,
		{"word":"tendissent","word_nosc":"tendissent","lemma":"tendre","pos":"VER"} ,
		{"word":"tendit","word_nosc":"tendit","lemma":"tendre","pos":"VER"} ,
		{"word":"tendons","word_nosc":"tendons","lemma":"tendre","pos":"VER"} ,
		{"word":"tendra","word_nosc":"tendra","lemma":"tendre","pos":"VER"} ,
		{"word":"tendrai","word_nosc":"tendrai","lemma":"tendre","pos":"VER"} ,
		{"word":"tendraient","word_nosc":"tendraient","lemma":"tendre","pos":"VER"} ,
		{"word":"tendrais","word_nosc":"tendrais","lemma":"tendre","pos":"VER"} ,
		{"word":"tendrait","word_nosc":"tendrait","lemma":"tendre","pos":"VER"} ,
		{"word":"tendre","word_nosc":"tendre","lemma":"tendre","pos":"VER"} ,
		{"word":"tendrons","word_nosc":"tendrons","lemma":"tendre","pos":"VER"} ,
		{"word":"tendront","word_nosc":"tendront","lemma":"tendre","pos":"VER"} ,
		{"word":"tends","word_nosc":"tends","lemma":"tendre","pos":"VER"} ,
		{"word":"tendu","word_nosc":"tendu","lemma":"tendre","pos":"VER"} ,
		{"word":"tendue","word_nosc":"tendue","lemma":"tendre","pos":"VER"} ,
		{"word":"tendues","word_nosc":"tendues","lemma":"tendre","pos":"VER"} ,
		{"word":"tendus","word_nosc":"tendus","lemma":"tendre","pos":"VER"} ,
		{"word":"tendît","word_nosc":"tendit","lemma":"tendre","pos":"VER"} ,
		{"word":"tenez","word_nosc":"tenez","lemma":"tenir","pos":"VER"} ,
		{"word":"teniez","word_nosc":"teniez","lemma":"tenir","pos":"VER"} ,
		{"word":"tenions","word_nosc":"tenions","lemma":"tenir","pos":"VER"} ,
		{"word":"tenir","word_nosc":"tenir","lemma":"tenir","pos":"VER"} ,
		{"word":"tenons","word_nosc":"tenons","lemma":"tenir","pos":"VER"} ,
		{"word":"tenta","word_nosc":"tenta","lemma":"tenter","pos":"VER"} ,
		{"word":"tentai","word_nosc":"tentai","lemma":"tenter","pos":"VER"} ,
		{"word":"tentaient","word_nosc":"tentaient","lemma":"tenter","pos":"VER"} ,
		{"word":"tentais","word_nosc":"tentais","lemma":"tenter","pos":"VER"} ,
		{"word":"tentait","word_nosc":"tentait","lemma":"tenter","pos":"VER"} ,
		{"word":"tentant","word_nosc":"tentant","lemma":"tenter","pos":"VER"} ,
		{"word":"tente","word_nosc":"tente","lemma":"tenter","pos":"VER"} ,
		{"word":"tentent","word_nosc":"tentent","lemma":"tenter","pos":"VER"} ,
		{"word":"tenter","word_nosc":"tenter","lemma":"tenter","pos":"VER"} ,
		{"word":"tentera","word_nosc":"tentera","lemma":"tenter","pos":"VER"} ,
		{"word":"tenterai","word_nosc":"tenterai","lemma":"tenter","pos":"VER"} ,
		{"word":"tenteraient","word_nosc":"tenteraient","lemma":"tenter","pos":"VER"} ,
		{"word":"tenterais","word_nosc":"tenterais","lemma":"tenter","pos":"VER"} ,
		{"word":"tenterait","word_nosc":"tenterait","lemma":"tenter","pos":"VER"} ,
		{"word":"tenteras","word_nosc":"tenteras","lemma":"tenter","pos":"VER"} ,
		{"word":"tenterez","word_nosc":"tenterez","lemma":"tenter","pos":"VER"} ,
		{"word":"tenteriez","word_nosc":"tenteriez","lemma":"tenter","pos":"VER"} ,
		{"word":"tenterons","word_nosc":"tenterons","lemma":"tenter","pos":"VER"} ,
		{"word":"tenteront","word_nosc":"tenteront","lemma":"tenter","pos":"VER"} ,
		{"word":"tentes","word_nosc":"tentes","lemma":"tenter","pos":"VER"} ,
		{"word":"tentez","word_nosc":"tentez","lemma":"tenter","pos":"VER"} ,
		{"word":"tentiez","word_nosc":"tentiez","lemma":"tenter","pos":"VER"} ,
		{"word":"tentions","word_nosc":"tentions","lemma":"tenter","pos":"VER"} ,
		{"word":"tentons","word_nosc":"tentons","lemma":"tenter","pos":"VER"} ,
		{"word":"tentâmes","word_nosc":"tentames","lemma":"tenter","pos":"VER"} ,
		{"word":"tentât","word_nosc":"tentat","lemma":"tenter","pos":"VER"} ,
		{"word":"tentèrent","word_nosc":"tenterent","lemma":"tenter","pos":"VER"} ,
		{"word":"tenté","word_nosc":"tente","lemma":"tenter","pos":"VER"} ,
		{"word":"tentée","word_nosc":"tentee","lemma":"tenter","pos":"VER"} ,
		{"word":"tentées","word_nosc":"tentees","lemma":"tenter","pos":"VER"} ,
		{"word":"tentés","word_nosc":"tentes","lemma":"tenter","pos":"VER"} ,
		{"word":"tenu","word_nosc":"tenu","lemma":"tenir","pos":"VER"} ,
		{"word":"tenue","word_nosc":"tenue","lemma":"tenir","pos":"VER"} ,
		{"word":"tenues","word_nosc":"tenues","lemma":"tenir","pos":"VER"} ,
		{"word":"tenus","word_nosc":"tenus","lemma":"tenir","pos":"VER"} ,
		{"word":"terce","word_nosc":"terce","lemma":"tercer","pos":"VER"} ,
		{"word":"tercer","word_nosc":"tercer","lemma":"tercer","pos":"VER"} ,
		{"word":"tergiversaient","word_nosc":"tergiversaient","lemma":"tergiverser","pos":"VER"} ,
		{"word":"tergiversais","word_nosc":"tergiversais","lemma":"tergiverser","pos":"VER"} ,
		{"word":"tergiversant","word_nosc":"tergiversant","lemma":"tergiverser","pos":"VER"} ,
		{"word":"tergiverse","word_nosc":"tergiverse","lemma":"tergiverser","pos":"VER"} ,
		{"word":"tergiverser","word_nosc":"tergiverser","lemma":"tergiverser","pos":"VER"} ,
		{"word":"tergiverses","word_nosc":"tergiverses","lemma":"tergiverser","pos":"VER"} ,
		{"word":"tergiversez","word_nosc":"tergiversez","lemma":"tergiverser","pos":"VER"} ,
		{"word":"tergiversons","word_nosc":"tergiversons","lemma":"tergiverser","pos":"VER"} ,
		{"word":"tergiversé","word_nosc":"tergiverse","lemma":"tergiverser","pos":"VER"} ,
		{"word":"termina","word_nosc":"termina","lemma":"terminer","pos":"VER"} ,
		{"word":"terminai","word_nosc":"terminai","lemma":"terminer","pos":"VER"} ,
		{"word":"terminaient","word_nosc":"terminaient","lemma":"terminer","pos":"VER"} ,
		{"word":"terminais","word_nosc":"terminais","lemma":"terminer","pos":"VER"} ,
		{"word":"terminait","word_nosc":"terminait","lemma":"terminer","pos":"VER"} ,
		{"word":"terminant","word_nosc":"terminant","lemma":"terminer","pos":"VER"} ,
		{"word":"termine","word_nosc":"termine","lemma":"terminer","pos":"VER"} ,
		{"word":"terminent","word_nosc":"terminent","lemma":"terminer","pos":"VER"} ,
		{"word":"terminer","word_nosc":"terminer","lemma":"terminer","pos":"VER"} ,
		{"word":"terminera","word_nosc":"terminera","lemma":"terminer","pos":"VER"} ,
		{"word":"terminerai","word_nosc":"terminerai","lemma":"terminer","pos":"VER"} ,
		{"word":"termineraient","word_nosc":"termineraient","lemma":"terminer","pos":"VER"} ,
		{"word":"terminerait","word_nosc":"terminerait","lemma":"terminer","pos":"VER"} ,
		{"word":"termineras","word_nosc":"termineras","lemma":"terminer","pos":"VER"} ,
		{"word":"terminerez","word_nosc":"terminerez","lemma":"terminer","pos":"VER"} ,
		{"word":"terminerons","word_nosc":"terminerons","lemma":"terminer","pos":"VER"} ,
		{"word":"termineront","word_nosc":"termineront","lemma":"terminer","pos":"VER"} ,
		{"word":"termines","word_nosc":"termines","lemma":"terminer","pos":"VER"} ,
		{"word":"terminez","word_nosc":"terminez","lemma":"terminer","pos":"VER"} ,
		{"word":"terminiez","word_nosc":"terminiez","lemma":"terminer","pos":"VER"} ,
		{"word":"terminions","word_nosc":"terminions","lemma":"terminer","pos":"VER"} ,
		{"word":"terminons","word_nosc":"terminons","lemma":"terminer","pos":"VER"} ,
		{"word":"terminât","word_nosc":"terminat","lemma":"terminer","pos":"VER"} ,
		{"word":"terminèrent","word_nosc":"terminerent","lemma":"terminer","pos":"VER"} ,
		{"word":"terminé","word_nosc":"termine","lemma":"terminer","pos":"VER"} ,
		{"word":"terminée","word_nosc":"terminee","lemma":"terminer","pos":"VER"} ,
		{"word":"terminées","word_nosc":"terminees","lemma":"terminer","pos":"VER"} ,
		{"word":"terminés","word_nosc":"termines","lemma":"terminer","pos":"VER"} ,
		{"word":"terni","word_nosc":"terni","lemma":"ternir","pos":"VER"} ,
		{"word":"ternie","word_nosc":"ternie","lemma":"ternir","pos":"VER"} ,
		{"word":"ternies","word_nosc":"ternies","lemma":"ternir","pos":"VER"} ,
		{"word":"ternir","word_nosc":"ternir","lemma":"ternir","pos":"VER"} ,
		{"word":"ternirai","word_nosc":"ternirai","lemma":"ternir","pos":"VER"} ,
		{"word":"ternirait","word_nosc":"ternirait","lemma":"ternir","pos":"VER"} ,
		{"word":"ternis","word_nosc":"ternis","lemma":"ternir","pos":"VER"} ,
		{"word":"ternissaient","word_nosc":"ternissaient","lemma":"ternir","pos":"VER"} ,
		{"word":"ternissait","word_nosc":"ternissait","lemma":"ternir","pos":"VER"} ,
		{"word":"ternissant","word_nosc":"ternissant","lemma":"ternir","pos":"VER"} ,
		{"word":"ternisse","word_nosc":"ternisse","lemma":"ternir","pos":"VER"} ,
		{"word":"ternissent","word_nosc":"ternissent","lemma":"ternir","pos":"VER"} ,
		{"word":"ternit","word_nosc":"ternit","lemma":"ternir","pos":"VER"} ,
		{"word":"terra","word_nosc":"terra","lemma":"terrer","pos":"VER"} ,
		{"word":"terrai","word_nosc":"terrai","lemma":"terrer","pos":"VER"} ,
		{"word":"terraient","word_nosc":"terraient","lemma":"terrer","pos":"VER"} ,
		{"word":"terrait","word_nosc":"terrait","lemma":"terrer","pos":"VER"} ,
		{"word":"terrant","word_nosc":"terrant","lemma":"terrer","pos":"VER"} ,
		{"word":"terrassa","word_nosc":"terrassa","lemma":"terrasser","pos":"VER"} ,
		{"word":"terrassai","word_nosc":"terrassai","lemma":"terrasser","pos":"VER"} ,
		{"word":"terrassaient","word_nosc":"terrassaient","lemma":"terrasser","pos":"VER"} ,
		{"word":"terrassait","word_nosc":"terrassait","lemma":"terrasser","pos":"VER"} ,
		{"word":"terrassant","word_nosc":"terrassant","lemma":"terrasser","pos":"VER"} ,
		{"word":"terrasse","word_nosc":"terrasse","lemma":"terrasser","pos":"VER"} ,
		{"word":"terrasser","word_nosc":"terrasser","lemma":"terrasser","pos":"VER"} ,
		{"word":"terrasserait","word_nosc":"terrasserait","lemma":"terrasser","pos":"VER"} ,
		{"word":"terrasses","word_nosc":"terrasses","lemma":"terrasser","pos":"VER"} ,
		{"word":"terrassât","word_nosc":"terrassat","lemma":"terrasser","pos":"VER"} ,
		{"word":"terrassèrent","word_nosc":"terrasserent","lemma":"terrasser","pos":"VER"} ,
		{"word":"terrassé","word_nosc":"terrasse","lemma":"terrasser","pos":"VER"} ,
		{"word":"terrassée","word_nosc":"terrassee","lemma":"terrasser","pos":"VER"} ,
		{"word":"terrassées","word_nosc":"terrassees","lemma":"terrasser","pos":"VER"} ,
		{"word":"terrassés","word_nosc":"terrasses","lemma":"terrasser","pos":"VER"} ,
		{"word":"terre","word_nosc":"terre","lemma":"terrer","pos":"VER"} ,
		{"word":"terrent","word_nosc":"terrent","lemma":"terrer","pos":"VER"} ,
		{"word":"terrer","word_nosc":"terrer","lemma":"terrer","pos":"VER"} ,
		{"word":"terrerait","word_nosc":"terrerait","lemma":"terrer","pos":"VER"} ,
		{"word":"terreront","word_nosc":"terreront","lemma":"terrer","pos":"VER"} ,
		{"word":"terres","word_nosc":"terres","lemma":"terrer","pos":"VER"} ,
		{"word":"terrez","word_nosc":"terrez","lemma":"terrer","pos":"VER"} ,
		{"word":"terri","word_nosc":"terri","lemma":"terrir","pos":"VER"} ,
		{"word":"terrifia","word_nosc":"terrifia","lemma":"terrifier","pos":"VER"} ,
		{"word":"terrifiaient","word_nosc":"terrifiaient","lemma":"terrifier","pos":"VER"} ,
		{"word":"terrifiait","word_nosc":"terrifiait","lemma":"terrifier","pos":"VER"} ,
		{"word":"terrifiant","word_nosc":"terrifiant","lemma":"terrifier","pos":"VER"} ,
		{"word":"terrifie","word_nosc":"terrifie","lemma":"terrifier","pos":"VER"} ,
		{"word":"terrifient","word_nosc":"terrifient","lemma":"terrifier","pos":"VER"} ,
		{"word":"terrifier","word_nosc":"terrifier","lemma":"terrifier","pos":"VER"} ,
		{"word":"terrifierait","word_nosc":"terrifierait","lemma":"terrifier","pos":"VER"} ,
		{"word":"terrifiez","word_nosc":"terrifiez","lemma":"terrifier","pos":"VER"} ,
		{"word":"terrifions","word_nosc":"terrifions","lemma":"terrifier","pos":"VER"} ,
		{"word":"terrifié","word_nosc":"terrifie","lemma":"terrifier","pos":"VER"} ,
		{"word":"terrifiée","word_nosc":"terrifiee","lemma":"terrifier","pos":"VER"} ,
		{"word":"terrifiées","word_nosc":"terrifiees","lemma":"terrifier","pos":"VER"} ,
		{"word":"terrifiés","word_nosc":"terrifies","lemma":"terrifier","pos":"VER"} ,
		{"word":"terrons","word_nosc":"terrons","lemma":"terrer","pos":"VER"} ,
		{"word":"terrorisa","word_nosc":"terrorisa","lemma":"terroriser","pos":"VER"} ,
		{"word":"terrorisaient","word_nosc":"terrorisaient","lemma":"terroriser","pos":"VER"} ,
		{"word":"terrorisait","word_nosc":"terrorisait","lemma":"terroriser","pos":"VER"} ,
		{"word":"terrorisant","word_nosc":"terrorisant","lemma":"terroriser","pos":"VER"} ,
		{"word":"terrorise","word_nosc":"terrorise","lemma":"terroriser","pos":"VER"} ,
		{"word":"terrorisent","word_nosc":"terrorisent","lemma":"terroriser","pos":"VER"} ,
		{"word":"terroriser","word_nosc":"terroriser","lemma":"terroriser","pos":"VER"} ,
		{"word":"terroriseront","word_nosc":"terroriseront","lemma":"terroriser","pos":"VER"} ,
		{"word":"terrorises","word_nosc":"terrorises","lemma":"terroriser","pos":"VER"} ,
		{"word":"terrorisez","word_nosc":"terrorisez","lemma":"terroriser","pos":"VER"} ,
		{"word":"terrorisé","word_nosc":"terrorise","lemma":"terroriser","pos":"VER"} ,
		{"word":"terrorisée","word_nosc":"terrorisee","lemma":"terroriser","pos":"VER"} ,
		{"word":"terrorisées","word_nosc":"terrorisees","lemma":"terroriser","pos":"VER"} ,
		{"word":"terrorisés","word_nosc":"terrorises","lemma":"terroriser","pos":"VER"} ,
		{"word":"terré","word_nosc":"terre","lemma":"terrer","pos":"VER"} ,
		{"word":"terrée","word_nosc":"terree","lemma":"terrer","pos":"VER"} ,
		{"word":"terrées","word_nosc":"terrees","lemma":"terrer","pos":"VER"} ,
		{"word":"terrés","word_nosc":"terres","lemma":"terrer","pos":"VER"} ,
		{"word":"testa","word_nosc":"testa","lemma":"tester","pos":"VER"} ,
		{"word":"testais","word_nosc":"testais","lemma":"tester","pos":"VER"} ,
		{"word":"testait","word_nosc":"testait","lemma":"tester","pos":"VER"} ,
		{"word":"testant","word_nosc":"testant","lemma":"tester","pos":"VER"} ,
		{"word":"teste","word_nosc":"teste","lemma":"tester","pos":"VER"} ,
		{"word":"testent","word_nosc":"testent","lemma":"tester","pos":"VER"} ,
		{"word":"tester","word_nosc":"tester","lemma":"tester","pos":"VER"} ,
		{"word":"testera","word_nosc":"testera","lemma":"tester","pos":"VER"} ,
		{"word":"testeras","word_nosc":"testeras","lemma":"tester","pos":"VER"} ,
		{"word":"testerons","word_nosc":"testerons","lemma":"tester","pos":"VER"} ,
		{"word":"testeront","word_nosc":"testeront","lemma":"tester","pos":"VER"} ,
		{"word":"testes","word_nosc":"testes","lemma":"tester","pos":"VER"} ,
		{"word":"testez","word_nosc":"testez","lemma":"tester","pos":"VER"} ,
		{"word":"testiez","word_nosc":"testiez","lemma":"tester","pos":"VER"} ,
		{"word":"testions","word_nosc":"testions","lemma":"tester","pos":"VER"} ,
		{"word":"testons","word_nosc":"testons","lemma":"tester","pos":"VER"} ,
		{"word":"testèrent","word_nosc":"testerent","lemma":"tester","pos":"VER"} ,
		{"word":"testé","word_nosc":"teste","lemma":"tester","pos":"VER"} ,
		{"word":"testée","word_nosc":"testee","lemma":"tester","pos":"VER"} ,
		{"word":"testées","word_nosc":"testees","lemma":"tester","pos":"VER"} ,
		{"word":"testés","word_nosc":"testes","lemma":"tester","pos":"VER"} ,
		{"word":"théorise","word_nosc":"theorise","lemma":"théoriser","pos":"VER"} ,
		{"word":"théoriser","word_nosc":"theoriser","lemma":"théoriser","pos":"VER"} ,
		{"word":"thésaurise","word_nosc":"thesaurise","lemma":"thésauriser","pos":"VER"} ,
		{"word":"thésauriser","word_nosc":"thesauriser","lemma":"thésauriser","pos":"VER"} ,
		{"word":"thésaurisés","word_nosc":"thesaurises","lemma":"thésauriser","pos":"VER"} ,
		{"word":"théâtralisant","word_nosc":"theatralisant","lemma":"théâtraliser","pos":"VER"} ,
		{"word":"théâtralisé","word_nosc":"theatralise","lemma":"théâtraliser","pos":"VER"} ,
		{"word":"théâtralisée","word_nosc":"theatralisee","lemma":"théâtraliser","pos":"VER"} ,
		{"word":"tictaquer","word_nosc":"tictaquer","lemma":"tictaquer","pos":"VER"} ,
		{"word":"tiendra","word_nosc":"tiendra","lemma":"tenir","pos":"VER"} ,
		{"word":"tiendrai","word_nosc":"tiendrai","lemma":"tenir","pos":"VER"} ,
		{"word":"tiendraient","word_nosc":"tiendraient","lemma":"tenir","pos":"VER"} ,
		{"word":"tiendrais","word_nosc":"tiendrais","lemma":"tenir","pos":"VER"} ,
		{"word":"tiendrait","word_nosc":"tiendrait","lemma":"tenir","pos":"VER"} ,
		{"word":"tiendras","word_nosc":"tiendras","lemma":"tenir","pos":"VER"} ,
		{"word":"tiendrez","word_nosc":"tiendrez","lemma":"tenir","pos":"VER"} ,
		{"word":"tiendriez","word_nosc":"tiendriez","lemma":"tenir","pos":"VER"} ,
		{"word":"tiendrons","word_nosc":"tiendrons","lemma":"tenir","pos":"VER"} ,
		{"word":"tiendront","word_nosc":"tiendront","lemma":"tenir","pos":"VER"} ,
		{"word":"tienne","word_nosc":"tienne","lemma":"tenir","pos":"VER"} ,
		{"word":"tiennent","word_nosc":"tiennent","lemma":"tenir","pos":"VER"} ,
		{"word":"tiennes","word_nosc":"tiennes","lemma":"tenir","pos":"VER"} ,
		{"word":"tiens","word_nosc":"tiens","lemma":"tenir","pos":"VER"} ,
		{"word":"tient","word_nosc":"tient","lemma":"tenir","pos":"VER"} ,
		{"word":"tigré","word_nosc":"tigre","lemma":"tigrer","pos":"VER"} ,
		{"word":"tigrée","word_nosc":"tigree","lemma":"tigrer","pos":"VER"} ,
		{"word":"tigrés","word_nosc":"tigres","lemma":"tigrer","pos":"VER"} ,
		{"word":"tiller","word_nosc":"tiller","lemma":"tiller","pos":"VER"} ,
		{"word":"timbrait","word_nosc":"timbrait","lemma":"timbrer","pos":"VER"} ,
		{"word":"timbre","word_nosc":"timbre","lemma":"timbrer","pos":"VER"} ,
		{"word":"timbrer","word_nosc":"timbrer","lemma":"timbrer","pos":"VER"} ,
		{"word":"timbrât","word_nosc":"timbrat","lemma":"timbrer","pos":"VER"} ,
		{"word":"timbré","word_nosc":"timbre","lemma":"timbrer","pos":"VER"} ,
		{"word":"timbrée","word_nosc":"timbree","lemma":"timbrer","pos":"VER"} ,
		{"word":"timbrées","word_nosc":"timbrees","lemma":"timbrer","pos":"VER"} ,
		{"word":"timbrés","word_nosc":"timbres","lemma":"timbrer","pos":"VER"} ,
		{"word":"tinrent","word_nosc":"tinrent","lemma":"tenir","pos":"VER"} ,
		{"word":"tins","word_nosc":"tins","lemma":"tenir","pos":"VER"} ,
		{"word":"tinssent","word_nosc":"tinssent","lemma":"tenir","pos":"VER"} ,
		{"word":"tint","word_nosc":"tint","lemma":"tenir","pos":"VER"} ,
		{"word":"tinta","word_nosc":"tinta","lemma":"tinter","pos":"VER"} ,
		{"word":"tintaient","word_nosc":"tintaient","lemma":"tinter","pos":"VER"} ,
		{"word":"tintait","word_nosc":"tintait","lemma":"tinter","pos":"VER"} ,
		{"word":"tintamarrent","word_nosc":"tintamarrent","lemma":"tintamarrer","pos":"VER"} ,
		{"word":"tintant","word_nosc":"tintant","lemma":"tinter","pos":"VER"} ,
		{"word":"tinte","word_nosc":"tinte","lemma":"tinter","pos":"VER"} ,
		{"word":"tintent","word_nosc":"tintent","lemma":"tinter","pos":"VER"} ,
		{"word":"tinter","word_nosc":"tinter","lemma":"tinter","pos":"VER"} ,
		{"word":"tintinnabula","word_nosc":"tintinnabula","lemma":"tintinnabuler","pos":"VER"} ,
		{"word":"tintinnabulaient","word_nosc":"tintinnabulaient","lemma":"tintinnabuler","pos":"VER"} ,
		{"word":"tintinnabulant","word_nosc":"tintinnabulant","lemma":"tintinnabuler","pos":"VER"} ,
		{"word":"tintinnabule","word_nosc":"tintinnabule","lemma":"tintinnabuler","pos":"VER"} ,
		{"word":"tintinnabulent","word_nosc":"tintinnabulent","lemma":"tintinnabuler","pos":"VER"} ,
		{"word":"tintinnabuler","word_nosc":"tintinnabuler","lemma":"tintinnabuler","pos":"VER"} ,
		{"word":"tintèrent","word_nosc":"tinterent","lemma":"tinter","pos":"VER"} ,
		{"word":"tinté","word_nosc":"tinte","lemma":"tinter","pos":"VER"} ,
		{"word":"tintés","word_nosc":"tintes","lemma":"tinter","pos":"VER"} ,
		{"word":"tipe","word_nosc":"tipe","lemma":"tiper","pos":"VER"} ,
		{"word":"tipper","word_nosc":"tipper","lemma":"tipper","pos":"VER"} ,
		{"word":"tiqua","word_nosc":"tiqua","lemma":"tiquer","pos":"VER"} ,
		{"word":"tiquai","word_nosc":"tiquai","lemma":"tiquer","pos":"VER"} ,
		{"word":"tiquaient","word_nosc":"tiquaient","lemma":"tiquer","pos":"VER"} ,
		{"word":"tiquais","word_nosc":"tiquais","lemma":"tiquer","pos":"VER"} ,
		{"word":"tiquait","word_nosc":"tiquait","lemma":"tiquer","pos":"VER"} ,
		{"word":"tiquant","word_nosc":"tiquant","lemma":"tiquer","pos":"VER"} ,
		{"word":"tique","word_nosc":"tique","lemma":"tiquer","pos":"VER"} ,
		{"word":"tiquent","word_nosc":"tiquent","lemma":"tiquer","pos":"VER"} ,
		{"word":"tiquer","word_nosc":"tiquer","lemma":"tiquer","pos":"VER"} ,
		{"word":"tiquerais","word_nosc":"tiquerais","lemma":"tiquer","pos":"VER"} ,
		{"word":"tiqué","word_nosc":"tique","lemma":"tiquer","pos":"VER"} ,
		{"word":"tira","word_nosc":"tira","lemma":"tirer","pos":"VER"} ,
		{"word":"tirai","word_nosc":"tirai","lemma":"tirer","pos":"VER"} ,
		{"word":"tiraient","word_nosc":"tiraient","lemma":"tirer","pos":"VER"} ,
		{"word":"tirailla","word_nosc":"tirailla","lemma":"tirailler","pos":"VER"} ,
		{"word":"tiraillaient","word_nosc":"tiraillaient","lemma":"tirailler","pos":"VER"} ,
		{"word":"tiraillait","word_nosc":"tiraillait","lemma":"tirailler","pos":"VER"} ,
		{"word":"tiraillant","word_nosc":"tiraillant","lemma":"tirailler","pos":"VER"} ,
		{"word":"tiraille","word_nosc":"tiraille","lemma":"tirailler","pos":"VER"} ,
		{"word":"tiraillent","word_nosc":"tiraillent","lemma":"tirailler","pos":"VER"} ,
		{"word":"tirailler","word_nosc":"tirailler","lemma":"tirailler","pos":"VER"} ,
		{"word":"tiraillerait","word_nosc":"tiraillerait","lemma":"tirailler","pos":"VER"} ,
		{"word":"tiraillez","word_nosc":"tiraillez","lemma":"tirailler","pos":"VER"} ,
		{"word":"tiraillons","word_nosc":"tiraillons","lemma":"tirailler","pos":"VER"} ,
		{"word":"tiraillé","word_nosc":"tiraille","lemma":"tirailler","pos":"VER"} ,
		{"word":"tiraillée","word_nosc":"tiraillee","lemma":"tirailler","pos":"VER"} ,
		{"word":"tiraillées","word_nosc":"tiraillees","lemma":"tirailler","pos":"VER"} ,
		{"word":"tiraillés","word_nosc":"tirailles","lemma":"tirailler","pos":"VER"} ,
		{"word":"tirais","word_nosc":"tirais","lemma":"tirer","pos":"VER"} ,
		{"word":"tirait","word_nosc":"tirait","lemma":"tirer","pos":"VER"} ,
		{"word":"tirant","word_nosc":"tirant","lemma":"tirer","pos":"VER"} ,
		{"word":"tiras","word_nosc":"tiras","lemma":"tirer","pos":"VER"} ,
		{"word":"tirassent","word_nosc":"tirassent","lemma":"tirasser","pos":"VER"} ,
		{"word":"tire","word_nosc":"tire","lemma":"tirer","pos":"VER"} ,
		{"word":"tire-bouchonnaient","word_nosc":"tire-bouchonnaient","lemma":"tire-bouchonner","pos":"VER"} ,
		{"word":"tire-bouchonnant","word_nosc":"tire-bouchonnant","lemma":"tire-bouchonner","pos":"VER"} ,
		{"word":"tire-bouchonne","word_nosc":"tire-bouchonne","lemma":"tire-bouchonner","pos":"VER"} ,
		{"word":"tire-bouchonnent","word_nosc":"tire-bouchonnent","lemma":"tire-bouchonner","pos":"VER"} ,
		{"word":"tire-bouchonné","word_nosc":"tire-bouchonne","lemma":"tire-bouchonner","pos":"VER"} ,
		{"word":"tire-bouchonnés","word_nosc":"tire-bouchonnes","lemma":"tire-bouchonner","pos":"VER"} ,
		{"word":"tirebouchonner","word_nosc":"tirebouchonner","lemma":"tirebouchonner","pos":"VER"} ,
		{"word":"tirebouchonné","word_nosc":"tirebouchonne","lemma":"tirebouchonner","pos":"VER"} ,
		{"word":"tirebouchonnée","word_nosc":"tirebouchonnee","lemma":"tirebouchonner","pos":"VER"} ,
		{"word":"tirelirent","word_nosc":"tirelirent","lemma":"tirelirer","pos":"VER"} ,
		{"word":"tirent","word_nosc":"tirent","lemma":"tirer","pos":"VER"} ,
		{"word":"tirer","word_nosc":"tirer","lemma":"tirer","pos":"VER"} ,
		{"word":"tirera","word_nosc":"tirera","lemma":"tirer","pos":"VER"} ,
		{"word":"tirerai","word_nosc":"tirerai","lemma":"tirer","pos":"VER"} ,
		{"word":"tireraient","word_nosc":"tireraient","lemma":"tirer","pos":"VER"} ,
		{"word":"tirerais","word_nosc":"tirerais","lemma":"tirer","pos":"VER"} ,
		{"word":"tirerait","word_nosc":"tirerait","lemma":"tirer","pos":"VER"} ,
		{"word":"tireras","word_nosc":"tireras","lemma":"tirer","pos":"VER"} ,
		{"word":"tirerez","word_nosc":"tirerez","lemma":"tirer","pos":"VER"} ,
		{"word":"tireriez","word_nosc":"tireriez","lemma":"tirer","pos":"VER"} ,
		{"word":"tirerions","word_nosc":"tirerions","lemma":"tirer","pos":"VER"} ,
		{"word":"tirerons","word_nosc":"tirerons","lemma":"tirer","pos":"VER"} ,
		{"word":"tireront","word_nosc":"tireront","lemma":"tirer","pos":"VER"} ,
		{"word":"tires","word_nosc":"tires","lemma":"tirer","pos":"VER"} ,
		{"word":"tirez","word_nosc":"tirez","lemma":"tirer","pos":"VER"} ,
		{"word":"tiriez","word_nosc":"tiriez","lemma":"tirer","pos":"VER"} ,
		{"word":"tirions","word_nosc":"tirions","lemma":"tirer","pos":"VER"} ,
		{"word":"tirons","word_nosc":"tirons","lemma":"tirer","pos":"VER"} ,
		{"word":"tirâmes","word_nosc":"tirames","lemma":"tirer","pos":"VER"} ,
		{"word":"tirât","word_nosc":"tirat","lemma":"tirer","pos":"VER"} ,
		{"word":"tirèrent","word_nosc":"tirerent","lemma":"tirer","pos":"VER"} ,
		{"word":"tiré","word_nosc":"tire","lemma":"tirer","pos":"VER"} ,
		{"word":"tirée","word_nosc":"tiree","lemma":"tirer","pos":"VER"} ,
		{"word":"tirées","word_nosc":"tirees","lemma":"tirer","pos":"VER"} ,
		{"word":"tirés","word_nosc":"tires","lemma":"tirer","pos":"VER"} ,
		{"word":"tisa","word_nosc":"tisa","lemma":"tiser","pos":"VER"} ,
		{"word":"tisané","word_nosc":"tisane","lemma":"tisaner","pos":"VER"} ,
		{"word":"tiser","word_nosc":"tiser","lemma":"tiser","pos":"VER"} ,
		{"word":"tisonna","word_nosc":"tisonna","lemma":"tisonner","pos":"VER"} ,
		{"word":"tisonnait","word_nosc":"tisonnait","lemma":"tisonner","pos":"VER"} ,
		{"word":"tisonnant","word_nosc":"tisonnant","lemma":"tisonner","pos":"VER"} ,
		{"word":"tisonne","word_nosc":"tisonne","lemma":"tisonner","pos":"VER"} ,
		{"word":"tisonnent","word_nosc":"tisonnent","lemma":"tisonner","pos":"VER"} ,
		{"word":"tisonner","word_nosc":"tisonner","lemma":"tisonner","pos":"VER"} ,
		{"word":"tisonné","word_nosc":"tisonne","lemma":"tisonner","pos":"VER"} ,
		{"word":"tissa","word_nosc":"tissa","lemma":"tisser","pos":"VER"} ,
		{"word":"tissaient","word_nosc":"tissaient","lemma":"tisser","pos":"VER"} ,
		{"word":"tissais","word_nosc":"tissais","lemma":"tisser","pos":"VER"} ,
		{"word":"tissait","word_nosc":"tissait","lemma":"tisser","pos":"VER"} ,
		{"word":"tissant","word_nosc":"tissant","lemma":"tisser","pos":"VER"} ,
		{"word":"tisse","word_nosc":"tisse","lemma":"tisser","pos":"VER"} ,
		{"word":"tissent","word_nosc":"tissent","lemma":"tisser","pos":"VER"} ,
		{"word":"tisser","word_nosc":"tisser","lemma":"tisser","pos":"VER"} ,
		{"word":"tisserai","word_nosc":"tisserai","lemma":"tisser","pos":"VER"} ,
		{"word":"tisserais","word_nosc":"tisserais","lemma":"tisser","pos":"VER"} ,
		{"word":"tisserait","word_nosc":"tisserait","lemma":"tisser","pos":"VER"} ,
		{"word":"tisserons","word_nosc":"tisserons","lemma":"tisser","pos":"VER"} ,
		{"word":"tissez","word_nosc":"tissez","lemma":"tisser","pos":"VER"} ,
		{"word":"tissons","word_nosc":"tissons","lemma":"tisser","pos":"VER"} ,
		{"word":"tissât","word_nosc":"tissat","lemma":"tisser","pos":"VER"} ,
		{"word":"tissèrent","word_nosc":"tisserent","lemma":"tisser","pos":"VER"} ,
		{"word":"tissé","word_nosc":"tisse","lemma":"tisser","pos":"VER"} ,
		{"word":"tissée","word_nosc":"tissee","lemma":"tisser","pos":"VER"} ,
		{"word":"tissées","word_nosc":"tissees","lemma":"tisser","pos":"VER"} ,
		{"word":"tissés","word_nosc":"tisses","lemma":"tisser","pos":"VER"} ,
		{"word":"titilla","word_nosc":"titilla","lemma":"titiller","pos":"VER"} ,
		{"word":"titillaient","word_nosc":"titillaient","lemma":"titiller","pos":"VER"} ,
		{"word":"titillais","word_nosc":"titillais","lemma":"titiller","pos":"VER"} ,
		{"word":"titillait","word_nosc":"titillait","lemma":"titiller","pos":"VER"} ,
		{"word":"titillant","word_nosc":"titillant","lemma":"titiller","pos":"VER"} ,
		{"word":"titille","word_nosc":"titille","lemma":"titiller","pos":"VER"} ,
		{"word":"titillent","word_nosc":"titillent","lemma":"titiller","pos":"VER"} ,
		{"word":"titiller","word_nosc":"titiller","lemma":"titiller","pos":"VER"} ,
		{"word":"titillera","word_nosc":"titillera","lemma":"titiller","pos":"VER"} ,
		{"word":"titillé","word_nosc":"titille","lemma":"titiller","pos":"VER"} ,
		{"word":"titillées","word_nosc":"titillees","lemma":"titiller","pos":"VER"} ,
		{"word":"titillés","word_nosc":"titilles","lemma":"titiller","pos":"VER"} ,
		{"word":"titra","word_nosc":"titra","lemma":"titrer","pos":"VER"} ,
		{"word":"titrait","word_nosc":"titrait","lemma":"titrer","pos":"VER"} ,
		{"word":"titre","word_nosc":"titre","lemma":"titrer","pos":"VER"} ,
		{"word":"titrer","word_nosc":"titrer","lemma":"titrer","pos":"VER"} ,
		{"word":"titres","word_nosc":"titres","lemma":"titrer","pos":"VER"} ,
		{"word":"titré","word_nosc":"titre","lemma":"titrer","pos":"VER"} ,
		{"word":"titrée","word_nosc":"titree","lemma":"titrer","pos":"VER"} ,
		{"word":"titrés","word_nosc":"titres","lemma":"titrer","pos":"VER"} ,
		{"word":"tituba","word_nosc":"tituba","lemma":"tituber","pos":"VER"} ,
		{"word":"titubai","word_nosc":"titubai","lemma":"tituber","pos":"VER"} ,
		{"word":"titubaient","word_nosc":"titubaient","lemma":"tituber","pos":"VER"} ,
		{"word":"titubais","word_nosc":"titubais","lemma":"tituber","pos":"VER"} ,
		{"word":"titubait","word_nosc":"titubait","lemma":"tituber","pos":"VER"} ,
		{"word":"titubant","word_nosc":"titubant","lemma":"tituber","pos":"VER"} ,
		{"word":"titube","word_nosc":"titube","lemma":"tituber","pos":"VER"} ,
		{"word":"titubent","word_nosc":"titubent","lemma":"tituber","pos":"VER"} ,
		{"word":"tituber","word_nosc":"tituber","lemma":"tituber","pos":"VER"} ,
		{"word":"titubez","word_nosc":"titubez","lemma":"tituber","pos":"VER"} ,
		{"word":"titubiez","word_nosc":"titubiez","lemma":"tituber","pos":"VER"} ,
		{"word":"titubions","word_nosc":"titubions","lemma":"tituber","pos":"VER"} ,
		{"word":"titubèrent","word_nosc":"tituberent","lemma":"tituber","pos":"VER"} ,
		{"word":"titubé","word_nosc":"titube","lemma":"tituber","pos":"VER"} ,
		{"word":"titulariser","word_nosc":"titulariser","lemma":"titulariser","pos":"VER"} ,
		{"word":"titularisé","word_nosc":"titularise","lemma":"titulariser","pos":"VER"} ,
		{"word":"titularisée","word_nosc":"titularisee","lemma":"titulariser","pos":"VER"} ,
		{"word":"tiédi","word_nosc":"tiedi","lemma":"tiédir","pos":"VER"} ,
		{"word":"tiédie","word_nosc":"tiedie","lemma":"tiédir","pos":"VER"} ,
		{"word":"tiédies","word_nosc":"tiedies","lemma":"tiédir","pos":"VER"} ,
		{"word":"tiédir","word_nosc":"tiedir","lemma":"tiédir","pos":"VER"} ,
		{"word":"tiédirait","word_nosc":"tiedirait","lemma":"tiédir","pos":"VER"} ,
		{"word":"tiédis","word_nosc":"tiedis","lemma":"tiédir","pos":"VER"} ,
		{"word":"tiédissaient","word_nosc":"tiedissaient","lemma":"tiédir","pos":"VER"} ,
		{"word":"tiédissait","word_nosc":"tiedissait","lemma":"tiédir","pos":"VER"} ,
		{"word":"tiédissant","word_nosc":"tiedissant","lemma":"tiédir","pos":"VER"} ,
		{"word":"tiédissent","word_nosc":"tiedissent","lemma":"tiédir","pos":"VER"} ,
		{"word":"tiédit","word_nosc":"tiedit","lemma":"tiédir","pos":"VER"} ,
		{"word":"toaster","word_nosc":"toaster","lemma":"toaster","pos":"VER"} ,
		{"word":"toasté","word_nosc":"toaste","lemma":"toaster","pos":"VER"} ,
		{"word":"toilasses","word_nosc":"toilasses","lemma":"toiler","pos":"VER"} ,
		{"word":"toiletta","word_nosc":"toiletta","lemma":"toiletter","pos":"VER"} ,
		{"word":"toilettait","word_nosc":"toilettait","lemma":"toiletter","pos":"VER"} ,
		{"word":"toiletter","word_nosc":"toiletter","lemma":"toiletter","pos":"VER"} ,
		{"word":"toiletté","word_nosc":"toilette","lemma":"toiletter","pos":"VER"} ,
		{"word":"toilettées","word_nosc":"toilettees","lemma":"toiletter","pos":"VER"} ,
		{"word":"toilettés","word_nosc":"toilettes","lemma":"toiletter","pos":"VER"} ,
		{"word":"toilé","word_nosc":"toile","lemma":"toiler","pos":"VER"} ,
		{"word":"toilée","word_nosc":"toilee","lemma":"toiler","pos":"VER"} ,
		{"word":"toilés","word_nosc":"toiles","lemma":"toiler","pos":"VER"} ,
		{"word":"toisa","word_nosc":"toisa","lemma":"toiser","pos":"VER"} ,
		{"word":"toisaient","word_nosc":"toisaient","lemma":"toiser","pos":"VER"} ,
		{"word":"toisait","word_nosc":"toisait","lemma":"toiser","pos":"VER"} ,
		{"word":"toisant","word_nosc":"toisant","lemma":"toiser","pos":"VER"} ,
		{"word":"toise","word_nosc":"toise","lemma":"toiser","pos":"VER"} ,
		{"word":"toisent","word_nosc":"toisent","lemma":"toiser","pos":"VER"} ,
		{"word":"toiser","word_nosc":"toiser","lemma":"toiser","pos":"VER"} ,
		{"word":"toisez","word_nosc":"toisez","lemma":"toiser","pos":"VER"} ,
		{"word":"toisèrent","word_nosc":"toiserent","lemma":"toiser","pos":"VER"} ,
		{"word":"toisé","word_nosc":"toise","lemma":"toiser","pos":"VER"} ,
		{"word":"toisée","word_nosc":"toisee","lemma":"toiser","pos":"VER"} ,
		{"word":"toisés","word_nosc":"toises","lemma":"toiser","pos":"VER"} ,
		{"word":"tolère","word_nosc":"tolere","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolèrent","word_nosc":"tolerent","lemma":"tolérer","pos":"VER"} ,
		{"word":"toléra","word_nosc":"tolera","lemma":"tolérer","pos":"VER"} ,
		{"word":"toléraient","word_nosc":"toleraient","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérais","word_nosc":"tolerais","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérait","word_nosc":"tolerait","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérant","word_nosc":"tolerant","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérer","word_nosc":"tolerer","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérera","word_nosc":"tolerera","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérerai","word_nosc":"tolererai","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérerais","word_nosc":"tolererais","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérerait","word_nosc":"tolererait","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérerions","word_nosc":"tolererions","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérerons","word_nosc":"tolererons","lemma":"tolérer","pos":"VER"} ,
		{"word":"toléreront","word_nosc":"tolereront","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérez","word_nosc":"tolerez","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolériez","word_nosc":"toleriez","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérions","word_nosc":"tolerions","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérons","word_nosc":"tolerons","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérât","word_nosc":"tolerat","lemma":"tolérer","pos":"VER"} ,
		{"word":"toléré","word_nosc":"tolere","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérée","word_nosc":"toleree","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérées","word_nosc":"tolerees","lemma":"tolérer","pos":"VER"} ,
		{"word":"tolérés","word_nosc":"toleres","lemma":"tolérer","pos":"VER"} ,
		{"word":"toma","word_nosc":"toma","lemma":"tomer","pos":"VER"} ,
		{"word":"tomba","word_nosc":"tomba","lemma":"tomber","pos":"VER"} ,
		{"word":"tombai","word_nosc":"tombai","lemma":"tomber","pos":"VER"} ,
		{"word":"tombaient","word_nosc":"tombaient","lemma":"tomber","pos":"VER"} ,
		{"word":"tombais","word_nosc":"tombais","lemma":"tomber","pos":"VER"} ,
		{"word":"tombait","word_nosc":"tombait","lemma":"tomber","pos":"VER"} ,
		{"word":"tombant","word_nosc":"tombant","lemma":"tomber","pos":"VER"} ,
		{"word":"tombe","word_nosc":"tombe","lemma":"tomber","pos":"VER"} ,
		{"word":"tombent","word_nosc":"tombent","lemma":"tomber","pos":"VER"} ,
		{"word":"tomber","word_nosc":"tomber","lemma":"tomber","pos":"VER"} ,
		{"word":"tombera","word_nosc":"tombera","lemma":"tomber","pos":"VER"} ,
		{"word":"tomberai","word_nosc":"tomberai","lemma":"tomber","pos":"VER"} ,
		{"word":"tomberaient","word_nosc":"tomberaient","lemma":"tomber","pos":"VER"} ,
		{"word":"tomberais","word_nosc":"tomberais","lemma":"tomber","pos":"VER"} ,
		{"word":"tomberait","word_nosc":"tomberait","lemma":"tomber","pos":"VER"} ,
		{"word":"tomberas","word_nosc":"tomberas","lemma":"tomber","pos":"VER"} ,
		{"word":"tomberez","word_nosc":"tomberez","lemma":"tomber","pos":"VER"} ,
		{"word":"tomberiez","word_nosc":"tomberiez","lemma":"tomber","pos":"VER"} ,
		{"word":"tomberions","word_nosc":"tomberions","lemma":"tomber","pos":"VER"} ,
		{"word":"tomberons","word_nosc":"tomberons","lemma":"tomber","pos":"VER"} ,
		{"word":"tomberont","word_nosc":"tomberont","lemma":"tomber","pos":"VER"} ,
		{"word":"tombes","word_nosc":"tombes","lemma":"tomber","pos":"VER"} ,
		{"word":"tombez","word_nosc":"tombez","lemma":"tomber","pos":"VER"} ,
		{"word":"tombiez","word_nosc":"tombiez","lemma":"tomber","pos":"VER"} ,
		{"word":"tombions","word_nosc":"tombions","lemma":"tomber","pos":"VER"} ,
		{"word":"tombons","word_nosc":"tombons","lemma":"tomber","pos":"VER"} ,
		{"word":"tombâmes","word_nosc":"tombames","lemma":"tomber","pos":"VER"} ,
		{"word":"tombât","word_nosc":"tombat","lemma":"tomber","pos":"VER"} ,
		{"word":"tombèrent","word_nosc":"tomberent","lemma":"tomber","pos":"VER"} ,
		{"word":"tombé","word_nosc":"tombe","lemma":"tomber","pos":"VER"} ,
		{"word":"tombée","word_nosc":"tombee","lemma":"tomber","pos":"VER"} ,
		{"word":"tombées","word_nosc":"tombees","lemma":"tomber","pos":"VER"} ,
		{"word":"tombés","word_nosc":"tombes","lemma":"tomber","pos":"VER"} ,
		{"word":"tond","word_nosc":"tond","lemma":"tondre","pos":"VER"} ,
		{"word":"tondais","word_nosc":"tondais","lemma":"tondre","pos":"VER"} ,
		{"word":"tondait","word_nosc":"tondait","lemma":"tondre","pos":"VER"} ,
		{"word":"tondant","word_nosc":"tondant","lemma":"tondre","pos":"VER"} ,
		{"word":"tonde","word_nosc":"tonde","lemma":"tondre","pos":"VER"} ,
		{"word":"tondent","word_nosc":"tondent","lemma":"tondre","pos":"VER"} ,
		{"word":"tondes","word_nosc":"tondes","lemma":"tondre","pos":"VER"} ,
		{"word":"tondit","word_nosc":"tondit","lemma":"tondre","pos":"VER"} ,
		{"word":"tondons","word_nosc":"tondons","lemma":"tondre","pos":"VER"} ,
		{"word":"tondrai","word_nosc":"tondrai","lemma":"tondre","pos":"VER"} ,
		{"word":"tondrais","word_nosc":"tondrais","lemma":"tondre","pos":"VER"} ,
		{"word":"tondre","word_nosc":"tondre","lemma":"tondre","pos":"VER"} ,
		{"word":"tonds","word_nosc":"tonds","lemma":"tondre","pos":"VER"} ,
		{"word":"tondu","word_nosc":"tondu","lemma":"tondre","pos":"VER"} ,
		{"word":"tondue","word_nosc":"tondue","lemma":"tondre","pos":"VER"} ,
		{"word":"tondues","word_nosc":"tondues","lemma":"tondre","pos":"VER"} ,
		{"word":"tondus","word_nosc":"tondus","lemma":"tondre","pos":"VER"} ,
		{"word":"tonifiaient","word_nosc":"tonifiaient","lemma":"tonifier","pos":"VER"} ,
		{"word":"tonifiant","word_nosc":"tonifiant","lemma":"tonifier","pos":"VER"} ,
		{"word":"tonifie","word_nosc":"tonifie","lemma":"tonifier","pos":"VER"} ,
		{"word":"tonifient","word_nosc":"tonifient","lemma":"tonifier","pos":"VER"} ,
		{"word":"tonifier","word_nosc":"tonifier","lemma":"tonifier","pos":"VER"} ,
		{"word":"tonitrua","word_nosc":"tonitrua","lemma":"tonitruer","pos":"VER"} ,
		{"word":"tonitruaient","word_nosc":"tonitruaient","lemma":"tonitruer","pos":"VER"} ,
		{"word":"tonitruais","word_nosc":"tonitruais","lemma":"tonitruer","pos":"VER"} ,
		{"word":"tonitruait","word_nosc":"tonitruait","lemma":"tonitruer","pos":"VER"} ,
		{"word":"tonitruant","word_nosc":"tonitruant","lemma":"tonitruer","pos":"VER"} ,
		{"word":"tonitrue","word_nosc":"tonitrue","lemma":"tonitruer","pos":"VER"} ,
		{"word":"tonitruent","word_nosc":"tonitruent","lemma":"tonitruer","pos":"VER"} ,
		{"word":"tonitruer","word_nosc":"tonitruer","lemma":"tonitruer","pos":"VER"} ,
		{"word":"tonitrué","word_nosc":"tonitrue","lemma":"tonitruer","pos":"VER"} ,
		{"word":"tonna","word_nosc":"tonna","lemma":"tonner","pos":"VER"} ,
		{"word":"tonnaient","word_nosc":"tonnaient","lemma":"tonner","pos":"VER"} ,
		{"word":"tonnais","word_nosc":"tonnais","lemma":"tonner","pos":"VER"} ,
		{"word":"tonnait","word_nosc":"tonnait","lemma":"tonner","pos":"VER"} ,
		{"word":"tonnant","word_nosc":"tonnant","lemma":"tonner","pos":"VER"} ,
		{"word":"tonne","word_nosc":"tonne","lemma":"tonner","pos":"VER"} ,
		{"word":"tonnent","word_nosc":"tonnent","lemma":"tonner","pos":"VER"} ,
		{"word":"tonner","word_nosc":"tonner","lemma":"tonner","pos":"VER"} ,
		{"word":"tonnerait","word_nosc":"tonnerait","lemma":"tonner","pos":"VER"} ,
		{"word":"tonnes","word_nosc":"tonnes","lemma":"tonner","pos":"VER"} ,
		{"word":"tonnez","word_nosc":"tonnez","lemma":"tonner","pos":"VER"} ,
		{"word":"tonné","word_nosc":"tonne","lemma":"tonner","pos":"VER"} ,
		{"word":"tonsuré","word_nosc":"tonsure","lemma":"tonsurer","pos":"VER"} ,
		{"word":"topa","word_nosc":"topa","lemma":"toper","pos":"VER"} ,
		{"word":"tope","word_nosc":"tope","lemma":"toper","pos":"VER"} ,
		{"word":"toper","word_nosc":"toper","lemma":"toper","pos":"VER"} ,
		{"word":"topez","word_nosc":"topez","lemma":"toper","pos":"VER"} ,
		{"word":"topions","word_nosc":"topions","lemma":"toper","pos":"VER"} ,
		{"word":"topons","word_nosc":"topons","lemma":"toper","pos":"VER"} ,
		{"word":"topé","word_nosc":"tope","lemma":"toper","pos":"VER"} ,
		{"word":"topés","word_nosc":"topes","lemma":"toper","pos":"VER"} ,
		{"word":"toqua","word_nosc":"toqua","lemma":"toquer","pos":"VER"} ,
		{"word":"toquais","word_nosc":"toquais","lemma":"toquer","pos":"VER"} ,
		{"word":"toquait","word_nosc":"toquait","lemma":"toquer","pos":"VER"} ,
		{"word":"toquant","word_nosc":"toquant","lemma":"toquer","pos":"VER"} ,
		{"word":"toque","word_nosc":"toque","lemma":"toquer","pos":"VER"} ,
		{"word":"toquer","word_nosc":"toquer","lemma":"toquer","pos":"VER"} ,
		{"word":"toques","word_nosc":"toques","lemma":"toquer","pos":"VER"} ,
		{"word":"toquez","word_nosc":"toquez","lemma":"toquer","pos":"VER"} ,
		{"word":"toqué","word_nosc":"toque","lemma":"toquer","pos":"VER"} ,
		{"word":"toquée","word_nosc":"toquee","lemma":"toquer","pos":"VER"} ,
		{"word":"toquées","word_nosc":"toquees","lemma":"toquer","pos":"VER"} ,
		{"word":"toqués","word_nosc":"toques","lemma":"toquer","pos":"VER"} ,
		{"word":"torcha","word_nosc":"torcha","lemma":"torcher","pos":"VER"} ,
		{"word":"torchaient","word_nosc":"torchaient","lemma":"torcher","pos":"VER"} ,
		{"word":"torchais","word_nosc":"torchais","lemma":"torcher","pos":"VER"} ,
		{"word":"torchait","word_nosc":"torchait","lemma":"torcher","pos":"VER"} ,
		{"word":"torchant","word_nosc":"torchant","lemma":"torcher","pos":"VER"} ,
		{"word":"torchassions","word_nosc":"torchassions","lemma":"torcher","pos":"VER"} ,
		{"word":"torche","word_nosc":"torche","lemma":"torcher","pos":"VER"} ,
		{"word":"torchent","word_nosc":"torchent","lemma":"torcher","pos":"VER"} ,
		{"word":"torcher","word_nosc":"torcher","lemma":"torcher","pos":"VER"} ,
		{"word":"torcherais","word_nosc":"torcherais","lemma":"torcher","pos":"VER"} ,
		{"word":"torcheras","word_nosc":"torcheras","lemma":"torcher","pos":"VER"} ,
		{"word":"torches","word_nosc":"torches","lemma":"torcher","pos":"VER"} ,
		{"word":"torchez","word_nosc":"torchez","lemma":"torcher","pos":"VER"} ,
		{"word":"torchonnait","word_nosc":"torchonnait","lemma":"torchonner","pos":"VER"} ,
		{"word":"torchonne","word_nosc":"torchonne","lemma":"torchonner","pos":"VER"} ,
		{"word":"torchonner","word_nosc":"torchonner","lemma":"torchonner","pos":"VER"} ,
		{"word":"torché","word_nosc":"torche","lemma":"torcher","pos":"VER"} ,
		{"word":"torchée","word_nosc":"torchee","lemma":"torcher","pos":"VER"} ,
		{"word":"torchés","word_nosc":"torches","lemma":"torcher","pos":"VER"} ,
		{"word":"tord","word_nosc":"tord","lemma":"tordre","pos":"VER"} ,
		{"word":"tordaient","word_nosc":"tordaient","lemma":"tordre","pos":"VER"} ,
		{"word":"tordais","word_nosc":"tordais","lemma":"tordre","pos":"VER"} ,
		{"word":"tordait","word_nosc":"tordait","lemma":"tordre","pos":"VER"} ,
		{"word":"tordant","word_nosc":"tordant","lemma":"tordre","pos":"VER"} ,
		{"word":"torde","word_nosc":"torde","lemma":"tordre","pos":"VER"} ,
		{"word":"tordent","word_nosc":"tordent","lemma":"tordre","pos":"VER"} ,
		{"word":"tordez","word_nosc":"tordez","lemma":"tordre","pos":"VER"} ,
		{"word":"tordiez","word_nosc":"tordiez","lemma":"tordre","pos":"VER"} ,
		{"word":"tordirent","word_nosc":"tordirent","lemma":"tordre","pos":"VER"} ,
		{"word":"tordis","word_nosc":"tordis","lemma":"tordre","pos":"VER"} ,
		{"word":"tordit","word_nosc":"tordit","lemma":"tordre","pos":"VER"} ,
		{"word":"tordra","word_nosc":"tordra","lemma":"tordre","pos":"VER"} ,
		{"word":"tordrai","word_nosc":"tordrai","lemma":"tordre","pos":"VER"} ,
		{"word":"tordraient","word_nosc":"tordraient","lemma":"tordre","pos":"VER"} ,
		{"word":"tordrais","word_nosc":"tordrais","lemma":"tordre","pos":"VER"} ,
		{"word":"tordrait","word_nosc":"tordrait","lemma":"tordre","pos":"VER"} ,
		{"word":"tordras","word_nosc":"tordras","lemma":"tordre","pos":"VER"} ,
		{"word":"tordre","word_nosc":"tordre","lemma":"tordre","pos":"VER"} ,
		{"word":"tordrez","word_nosc":"tordrez","lemma":"tordre","pos":"VER"} ,
		{"word":"tordront","word_nosc":"tordront","lemma":"tordre","pos":"VER"} ,
		{"word":"tords","word_nosc":"tords","lemma":"tordre","pos":"VER"} ,
		{"word":"tordu","word_nosc":"tordu","lemma":"tordre","pos":"VER"} ,
		{"word":"tordue","word_nosc":"tordue","lemma":"tordre","pos":"VER"} ,
		{"word":"tordues","word_nosc":"tordues","lemma":"tordre","pos":"VER"} ,
		{"word":"tordus","word_nosc":"tordus","lemma":"tordre","pos":"VER"} ,
		{"word":"tordît","word_nosc":"tordit","lemma":"tordre","pos":"VER"} ,
		{"word":"torpillaient","word_nosc":"torpillaient","lemma":"torpiller","pos":"VER"} ,
		{"word":"torpillait","word_nosc":"torpillait","lemma":"torpiller","pos":"VER"} ,
		{"word":"torpille","word_nosc":"torpille","lemma":"torpiller","pos":"VER"} ,
		{"word":"torpiller","word_nosc":"torpiller","lemma":"torpiller","pos":"VER"} ,
		{"word":"torpillera","word_nosc":"torpillera","lemma":"torpiller","pos":"VER"} ,
		{"word":"torpilles","word_nosc":"torpilles","lemma":"torpiller","pos":"VER"} ,
		{"word":"torpillé","word_nosc":"torpille","lemma":"torpiller","pos":"VER"} ,
		{"word":"torpillée","word_nosc":"torpillee","lemma":"torpiller","pos":"VER"} ,
		{"word":"torpillés","word_nosc":"torpilles","lemma":"torpiller","pos":"VER"} ,
		{"word":"torréfiait","word_nosc":"torrefiait","lemma":"torréfier","pos":"VER"} ,
		{"word":"torréfiant","word_nosc":"torrefiant","lemma":"torréfier","pos":"VER"} ,
		{"word":"torréfie","word_nosc":"torrefie","lemma":"torréfier","pos":"VER"} ,
		{"word":"torréfient","word_nosc":"torrefient","lemma":"torréfier","pos":"VER"} ,
		{"word":"torréfié","word_nosc":"torrefie","lemma":"torréfier","pos":"VER"} ,
		{"word":"torsada","word_nosc":"torsada","lemma":"torsader","pos":"VER"} ,
		{"word":"torsadé","word_nosc":"torsade","lemma":"torsader","pos":"VER"} ,
		{"word":"torsadée","word_nosc":"torsadee","lemma":"torsader","pos":"VER"} ,
		{"word":"torsadées","word_nosc":"torsadees","lemma":"torsader","pos":"VER"} ,
		{"word":"torsadés","word_nosc":"torsades","lemma":"torsader","pos":"VER"} ,
		{"word":"tortilla","word_nosc":"tortilla","lemma":"tortiller","pos":"VER"} ,
		{"word":"tortillaient","word_nosc":"tortillaient","lemma":"tortiller","pos":"VER"} ,
		{"word":"tortillais","word_nosc":"tortillais","lemma":"tortiller","pos":"VER"} ,
		{"word":"tortillait","word_nosc":"tortillait","lemma":"tortiller","pos":"VER"} ,
		{"word":"tortillant","word_nosc":"tortillant","lemma":"tortiller","pos":"VER"} ,
		{"word":"tortille","word_nosc":"tortille","lemma":"tortiller","pos":"VER"} ,
		{"word":"tortillent","word_nosc":"tortillent","lemma":"tortiller","pos":"VER"} ,
		{"word":"tortiller","word_nosc":"tortiller","lemma":"tortiller","pos":"VER"} ,
		{"word":"tortillez","word_nosc":"tortillez","lemma":"tortiller","pos":"VER"} ,
		{"word":"tortillé","word_nosc":"tortille","lemma":"tortiller","pos":"VER"} ,
		{"word":"tortillée","word_nosc":"tortillee","lemma":"tortiller","pos":"VER"} ,
		{"word":"tortillées","word_nosc":"tortillees","lemma":"tortiller","pos":"VER"} ,
		{"word":"tortillés","word_nosc":"tortilles","lemma":"tortiller","pos":"VER"} ,
		{"word":"tortora","word_nosc":"tortora","lemma":"tortorer","pos":"VER"} ,
		{"word":"tortorait","word_nosc":"tortorait","lemma":"tortorer","pos":"VER"} ,
		{"word":"tortorant","word_nosc":"tortorant","lemma":"tortorer","pos":"VER"} ,
		{"word":"tortorent","word_nosc":"tortorent","lemma":"tortorer","pos":"VER"} ,
		{"word":"tortorer","word_nosc":"tortorer","lemma":"tortorer","pos":"VER"} ,
		{"word":"tortoré","word_nosc":"tortore","lemma":"tortorer","pos":"VER"} ,
		{"word":"tortura","word_nosc":"tortura","lemma":"torturer","pos":"VER"} ,
		{"word":"torturai","word_nosc":"torturai","lemma":"torturer","pos":"VER"} ,
		{"word":"torturaient","word_nosc":"torturaient","lemma":"torturer","pos":"VER"} ,
		{"word":"torturais","word_nosc":"torturais","lemma":"torturer","pos":"VER"} ,
		{"word":"torturait","word_nosc":"torturait","lemma":"torturer","pos":"VER"} ,
		{"word":"torturant","word_nosc":"torturant","lemma":"torturer","pos":"VER"} ,
		{"word":"torture","word_nosc":"torture","lemma":"torturer","pos":"VER"} ,
		{"word":"torturent","word_nosc":"torturent","lemma":"torturer","pos":"VER"} ,
		{"word":"torturer","word_nosc":"torturer","lemma":"torturer","pos":"VER"} ,
		{"word":"torturera","word_nosc":"torturera","lemma":"torturer","pos":"VER"} ,
		{"word":"torturerai","word_nosc":"torturerai","lemma":"torturer","pos":"VER"} ,
		{"word":"tortureraient","word_nosc":"tortureraient","lemma":"torturer","pos":"VER"} ,
		{"word":"torturerez","word_nosc":"torturerez","lemma":"torturer","pos":"VER"} ,
		{"word":"tortureront","word_nosc":"tortureront","lemma":"torturer","pos":"VER"} ,
		{"word":"tortures","word_nosc":"tortures","lemma":"torturer","pos":"VER"} ,
		{"word":"torturez","word_nosc":"torturez","lemma":"torturer","pos":"VER"} ,
		{"word":"torturions","word_nosc":"torturions","lemma":"torturer","pos":"VER"} ,
		{"word":"torturons","word_nosc":"torturons","lemma":"torturer","pos":"VER"} ,
		{"word":"torturèrent","word_nosc":"torturerent","lemma":"torturer","pos":"VER"} ,
		{"word":"torturé","word_nosc":"torture","lemma":"torturer","pos":"VER"} ,
		{"word":"torturée","word_nosc":"torturee","lemma":"torturer","pos":"VER"} ,
		{"word":"torturées","word_nosc":"torturees","lemma":"torturer","pos":"VER"} ,
		{"word":"torturés","word_nosc":"tortures","lemma":"torturer","pos":"VER"} ,
		{"word":"torée","word_nosc":"toree","lemma":"toréer","pos":"VER"} ,
		{"word":"toréer","word_nosc":"toreer","lemma":"toréer","pos":"VER"} ,
		{"word":"tossé","word_nosc":"tosse","lemma":"tosser","pos":"VER"} ,
		{"word":"totalisait","word_nosc":"totalisait","lemma":"totaliser","pos":"VER"} ,
		{"word":"totalisant","word_nosc":"totalisant","lemma":"totaliser","pos":"VER"} ,
		{"word":"totalise","word_nosc":"totalise","lemma":"totaliser","pos":"VER"} ,
		{"word":"totalisent","word_nosc":"totalisent","lemma":"totaliser","pos":"VER"} ,
		{"word":"totaliser","word_nosc":"totaliser","lemma":"totaliser","pos":"VER"} ,
		{"word":"totalisé","word_nosc":"totalise","lemma":"totaliser","pos":"VER"} ,
		{"word":"toucha","word_nosc":"toucha","lemma":"toucher","pos":"VER"} ,
		{"word":"touchai","word_nosc":"touchai","lemma":"toucher","pos":"VER"} ,
		{"word":"touchaient","word_nosc":"touchaient","lemma":"toucher","pos":"VER"} ,
		{"word":"touchais","word_nosc":"touchais","lemma":"toucher","pos":"VER"} ,
		{"word":"touchait","word_nosc":"touchait","lemma":"toucher","pos":"VER"} ,
		{"word":"touchant","word_nosc":"touchant","lemma":"toucher","pos":"VER"} ,
		{"word":"touche","word_nosc":"touche","lemma":"toucher","pos":"VER"} ,
		{"word":"touchent","word_nosc":"touchent","lemma":"toucher","pos":"VER"} ,
		{"word":"toucher","word_nosc":"toucher","lemma":"toucher","pos":"VER"} ,
		{"word":"touchera","word_nosc":"touchera","lemma":"toucher","pos":"VER"} ,
		{"word":"toucherai","word_nosc":"toucherai","lemma":"toucher","pos":"VER"} ,
		{"word":"toucheraient","word_nosc":"toucheraient","lemma":"toucher","pos":"VER"} ,
		{"word":"toucherais","word_nosc":"toucherais","lemma":"toucher","pos":"VER"} ,
		{"word":"toucherait","word_nosc":"toucherait","lemma":"toucher","pos":"VER"} ,
		{"word":"toucheras","word_nosc":"toucheras","lemma":"toucher","pos":"VER"} ,
		{"word":"toucherez","word_nosc":"toucherez","lemma":"toucher","pos":"VER"} ,
		{"word":"toucherions","word_nosc":"toucherions","lemma":"toucher","pos":"VER"} ,
		{"word":"toucherons","word_nosc":"toucherons","lemma":"toucher","pos":"VER"} ,
		{"word":"toucheront","word_nosc":"toucheront","lemma":"toucher","pos":"VER"} ,
		{"word":"touches","word_nosc":"touches","lemma":"toucher","pos":"VER"} ,
		{"word":"touchez","word_nosc":"touchez","lemma":"toucher","pos":"VER"} ,
		{"word":"touchiez","word_nosc":"touchiez","lemma":"toucher","pos":"VER"} ,
		{"word":"touchions","word_nosc":"touchions","lemma":"toucher","pos":"VER"} ,
		{"word":"touchons","word_nosc":"touchons","lemma":"toucher","pos":"VER"} ,
		{"word":"touchât","word_nosc":"touchat","lemma":"toucher","pos":"VER"} ,
		{"word":"touchèrent","word_nosc":"toucherent","lemma":"toucher","pos":"VER"} ,
		{"word":"touché","word_nosc":"touche","lemma":"toucher","pos":"VER"} ,
		{"word":"touchée","word_nosc":"touchee","lemma":"toucher","pos":"VER"} ,
		{"word":"touchées","word_nosc":"touchees","lemma":"toucher","pos":"VER"} ,
		{"word":"touchés","word_nosc":"touches","lemma":"toucher","pos":"VER"} ,
		{"word":"toue","word_nosc":"toue","lemma":"touer","pos":"VER"} ,
		{"word":"touer","word_nosc":"touer","lemma":"touer","pos":"VER"} ,
		{"word":"touillais","word_nosc":"touillais","lemma":"touiller","pos":"VER"} ,
		{"word":"touillait","word_nosc":"touillait","lemma":"touiller","pos":"VER"} ,
		{"word":"touillant","word_nosc":"touillant","lemma":"touiller","pos":"VER"} ,
		{"word":"touille","word_nosc":"touille","lemma":"touiller","pos":"VER"} ,
		{"word":"touillent","word_nosc":"touillent","lemma":"touiller","pos":"VER"} ,
		{"word":"touiller","word_nosc":"touiller","lemma":"touiller","pos":"VER"} ,
		{"word":"touillé","word_nosc":"touille","lemma":"touiller","pos":"VER"} ,
		{"word":"touillées","word_nosc":"touillees","lemma":"touiller","pos":"VER"} ,
		{"word":"touillés","word_nosc":"touilles","lemma":"touiller","pos":"VER"} ,
		{"word":"toupinant","word_nosc":"toupinant","lemma":"toupiner","pos":"VER"} ,
		{"word":"tourbillonna","word_nosc":"tourbillonna","lemma":"tourbillonner","pos":"VER"} ,
		{"word":"tourbillonnaient","word_nosc":"tourbillonnaient","lemma":"tourbillonner","pos":"VER"} ,
		{"word":"tourbillonnais","word_nosc":"tourbillonnais","lemma":"tourbillonner","pos":"VER"} ,
		{"word":"tourbillonnait","word_nosc":"tourbillonnait","lemma":"tourbillonner","pos":"VER"} ,
		{"word":"tourbillonnant","word_nosc":"tourbillonnant","lemma":"tourbillonner","pos":"VER"} ,
		{"word":"tourbillonne","word_nosc":"tourbillonne","lemma":"tourbillonner","pos":"VER"} ,
		{"word":"tourbillonnent","word_nosc":"tourbillonnent","lemma":"tourbillonner","pos":"VER"} ,
		{"word":"tourbillonner","word_nosc":"tourbillonner","lemma":"tourbillonner","pos":"VER"} ,
		{"word":"tourbillonnez","word_nosc":"tourbillonnez","lemma":"tourbillonner","pos":"VER"} ,
		{"word":"tourbillonnèrent","word_nosc":"tourbillonnerent","lemma":"tourbillonner","pos":"VER"} ,
		{"word":"tourbillonné","word_nosc":"tourbillonne","lemma":"tourbillonner","pos":"VER"} ,
		{"word":"tourmenta","word_nosc":"tourmenta","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmentai","word_nosc":"tourmentai","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmentaient","word_nosc":"tourmentaient","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmentais","word_nosc":"tourmentais","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmentait","word_nosc":"tourmentait","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmentant","word_nosc":"tourmentant","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmente","word_nosc":"tourmente","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmentent","word_nosc":"tourmentent","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmenter","word_nosc":"tourmenter","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmentera","word_nosc":"tourmentera","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmenterai","word_nosc":"tourmenterai","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmenteraient","word_nosc":"tourmenteraient","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmenteront","word_nosc":"tourmenteront","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmentes","word_nosc":"tourmentes","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmentez","word_nosc":"tourmentez","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmentiez","word_nosc":"tourmentiez","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmentât","word_nosc":"tourmentat","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmentèrent","word_nosc":"tourmenterent","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmenté","word_nosc":"tourmente","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmentée","word_nosc":"tourmentee","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmentées","word_nosc":"tourmentees","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourmentés","word_nosc":"tourmentes","lemma":"tourmenter","pos":"VER"} ,
		{"word":"tourna","word_nosc":"tourna","lemma":"tourner","pos":"VER"} ,
		{"word":"tournai","word_nosc":"tournai","lemma":"tourner","pos":"VER"} ,
		{"word":"tournaient","word_nosc":"tournaient","lemma":"tourner","pos":"VER"} ,
		{"word":"tournaillaient","word_nosc":"tournaillaient","lemma":"tournailler","pos":"VER"} ,
		{"word":"tournailler","word_nosc":"tournailler","lemma":"tournailler","pos":"VER"} ,
		{"word":"tournaillé","word_nosc":"tournaille","lemma":"tournailler","pos":"VER"} ,
		{"word":"tournais","word_nosc":"tournais","lemma":"tourner","pos":"VER"} ,
		{"word":"tournait","word_nosc":"tournait","lemma":"tourner","pos":"VER"} ,
		{"word":"tournant","word_nosc":"tournant","lemma":"tourner","pos":"VER"} ,
		{"word":"tournas","word_nosc":"tournas","lemma":"tourner","pos":"VER"} ,
		{"word":"tourne","word_nosc":"tourne","lemma":"tourner","pos":"VER"} ,
		{"word":"tourneboulant","word_nosc":"tourneboulant","lemma":"tournebouler","pos":"VER"} ,
		{"word":"tourneboule","word_nosc":"tourneboule","lemma":"tournebouler","pos":"VER"} ,
		{"word":"tourneboulé","word_nosc":"tourneboule","lemma":"tournebouler","pos":"VER"} ,
		{"word":"tourneboulée","word_nosc":"tourneboulee","lemma":"tournebouler","pos":"VER"} ,
		{"word":"tourneboulés","word_nosc":"tourneboules","lemma":"tournebouler","pos":"VER"} ,
		{"word":"tournent","word_nosc":"tournent","lemma":"tourner","pos":"VER"} ,
		{"word":"tourner","word_nosc":"tourner","lemma":"tourner","pos":"VER"} ,
		{"word":"tournera","word_nosc":"tournera","lemma":"tourner","pos":"VER"} ,
		{"word":"tournerai","word_nosc":"tournerai","lemma":"tourner","pos":"VER"} ,
		{"word":"tourneraient","word_nosc":"tourneraient","lemma":"tourner","pos":"VER"} ,
		{"word":"tournerais","word_nosc":"tournerais","lemma":"tourner","pos":"VER"} ,
		{"word":"tournerait","word_nosc":"tournerait","lemma":"tourner","pos":"VER"} ,
		{"word":"tourneras","word_nosc":"tourneras","lemma":"tourner","pos":"VER"} ,
		{"word":"tournerez","word_nosc":"tournerez","lemma":"tourner","pos":"VER"} ,
		{"word":"tourneriez","word_nosc":"tourneriez","lemma":"tourner","pos":"VER"} ,
		{"word":"tournerions","word_nosc":"tournerions","lemma":"tourner","pos":"VER"} ,
		{"word":"tournerons","word_nosc":"tournerons","lemma":"tourner","pos":"VER"} ,
		{"word":"tourneront","word_nosc":"tourneront","lemma":"tourner","pos":"VER"} ,
		{"word":"tournes","word_nosc":"tournes","lemma":"tourner","pos":"VER"} ,
		{"word":"tournez","word_nosc":"tournez","lemma":"tourner","pos":"VER"} ,
		{"word":"tournicota","word_nosc":"tournicota","lemma":"tournicoter","pos":"VER"} ,
		{"word":"tournicotais","word_nosc":"tournicotais","lemma":"tournicoter","pos":"VER"} ,
		{"word":"tournicotait","word_nosc":"tournicotait","lemma":"tournicoter","pos":"VER"} ,
		{"word":"tournicotant","word_nosc":"tournicotant","lemma":"tournicoter","pos":"VER"} ,
		{"word":"tournicote","word_nosc":"tournicote","lemma":"tournicoter","pos":"VER"} ,
		{"word":"tournicotent","word_nosc":"tournicotent","lemma":"tournicoter","pos":"VER"} ,
		{"word":"tournicoter","word_nosc":"tournicoter","lemma":"tournicoter","pos":"VER"} ,
		{"word":"tournicoterais","word_nosc":"tournicoterais","lemma":"tournicoter","pos":"VER"} ,
		{"word":"tournicoté","word_nosc":"tournicote","lemma":"tournicoter","pos":"VER"} ,
		{"word":"tournicotés","word_nosc":"tournicotes","lemma":"tournicoter","pos":"VER"} ,
		{"word":"tournillant","word_nosc":"tournillant","lemma":"tourniller","pos":"VER"} ,
		{"word":"tournions","word_nosc":"tournions","lemma":"tourner","pos":"VER"} ,
		{"word":"tourniquant","word_nosc":"tourniquant","lemma":"tourniquer","pos":"VER"} ,
		{"word":"tournique","word_nosc":"tournique","lemma":"tourniquer","pos":"VER"} ,
		{"word":"tourniquer","word_nosc":"tourniquer","lemma":"tourniquer","pos":"VER"} ,
		{"word":"tournoie","word_nosc":"tournoie","lemma":"tournoyer","pos":"VER"} ,
		{"word":"tournoient","word_nosc":"tournoient","lemma":"tournoyer","pos":"VER"} ,
		{"word":"tournoieraient","word_nosc":"tournoieraient","lemma":"tournoyer","pos":"VER"} ,
		{"word":"tournoieront","word_nosc":"tournoieront","lemma":"tournoyer","pos":"VER"} ,
		{"word":"tournons","word_nosc":"tournons","lemma":"tourner","pos":"VER"} ,
		{"word":"tournoya","word_nosc":"tournoya","lemma":"tournoyer","pos":"VER"} ,
		{"word":"tournoyaient","word_nosc":"tournoyaient","lemma":"tournoyer","pos":"VER"} ,
		{"word":"tournoyais","word_nosc":"tournoyais","lemma":"tournoyer","pos":"VER"} ,
		{"word":"tournoyait","word_nosc":"tournoyait","lemma":"tournoyer","pos":"VER"} ,
		{"word":"tournoyant","word_nosc":"tournoyant","lemma":"tournoyer","pos":"VER"} ,
		{"word":"tournoyer","word_nosc":"tournoyer","lemma":"tournoyer","pos":"VER"} ,
		{"word":"tournoyèrent","word_nosc":"tournoyerent","lemma":"tournoyer","pos":"VER"} ,
		{"word":"tournoyé","word_nosc":"tournoye","lemma":"tournoyer","pos":"VER"} ,
		{"word":"tournâmes","word_nosc":"tournames","lemma":"tourner","pos":"VER"} ,
		{"word":"tournât","word_nosc":"tournat","lemma":"tourner","pos":"VER"} ,
		{"word":"tournèrent","word_nosc":"tournerent","lemma":"tourner","pos":"VER"} ,
		{"word":"tourné","word_nosc":"tourne","lemma":"tourner","pos":"VER"} ,
		{"word":"tournée","word_nosc":"tournee","lemma":"tourner","pos":"VER"} ,
		{"word":"tournées","word_nosc":"tournees","lemma":"tourner","pos":"VER"} ,
		{"word":"tournés","word_nosc":"tournes","lemma":"tourner","pos":"VER"} ,
		{"word":"toussa","word_nosc":"toussa","lemma":"tousser","pos":"VER"} ,
		{"word":"toussai","word_nosc":"toussai","lemma":"tousser","pos":"VER"} ,
		{"word":"toussaient","word_nosc":"toussaient","lemma":"tousser","pos":"VER"} ,
		{"word":"toussais","word_nosc":"toussais","lemma":"tousser","pos":"VER"} ,
		{"word":"toussait","word_nosc":"toussait","lemma":"tousser","pos":"VER"} ,
		{"word":"toussant","word_nosc":"toussant","lemma":"tousser","pos":"VER"} ,
		{"word":"tousse","word_nosc":"tousse","lemma":"tousser","pos":"VER"} ,
		{"word":"toussent","word_nosc":"toussent","lemma":"tousser","pos":"VER"} ,
		{"word":"tousser","word_nosc":"tousser","lemma":"tousser","pos":"VER"} ,
		{"word":"toussera","word_nosc":"toussera","lemma":"tousser","pos":"VER"} ,
		{"word":"tousserait","word_nosc":"tousserait","lemma":"tousser","pos":"VER"} ,
		{"word":"tousses","word_nosc":"tousses","lemma":"tousser","pos":"VER"} ,
		{"word":"toussez","word_nosc":"toussez","lemma":"tousser","pos":"VER"} ,
		{"word":"toussions","word_nosc":"toussions","lemma":"tousser","pos":"VER"} ,
		{"word":"toussons","word_nosc":"toussons","lemma":"tousser","pos":"VER"} ,
		{"word":"toussota","word_nosc":"toussota","lemma":"toussoter","pos":"VER"} ,
		{"word":"toussotai","word_nosc":"toussotai","lemma":"toussoter","pos":"VER"} ,
		{"word":"toussotaient","word_nosc":"toussotaient","lemma":"toussoter","pos":"VER"} ,
		{"word":"toussotait","word_nosc":"toussotait","lemma":"toussoter","pos":"VER"} ,
		{"word":"toussotant","word_nosc":"toussotant","lemma":"toussoter","pos":"VER"} ,
		{"word":"toussote","word_nosc":"toussote","lemma":"toussoter","pos":"VER"} ,
		{"word":"toussotent","word_nosc":"toussotent","lemma":"toussoter","pos":"VER"} ,
		{"word":"toussoter","word_nosc":"toussoter","lemma":"toussoter","pos":"VER"} ,
		{"word":"toussotiez","word_nosc":"toussotiez","lemma":"toussoter","pos":"VER"} ,
		{"word":"toussoté","word_nosc":"toussote","lemma":"toussoter","pos":"VER"} ,
		{"word":"toussé","word_nosc":"tousse","lemma":"tousser","pos":"VER"} ,
		{"word":"toué","word_nosc":"toue","lemma":"touer","pos":"VER"} ,
		{"word":"tracassa","word_nosc":"tracassa","lemma":"tracasser","pos":"VER"} ,
		{"word":"tracassaient","word_nosc":"tracassaient","lemma":"tracasser","pos":"VER"} ,
		{"word":"tracassait","word_nosc":"tracassait","lemma":"tracasser","pos":"VER"} ,
		{"word":"tracassant","word_nosc":"tracassant","lemma":"tracasser","pos":"VER"} ,
		{"word":"tracasse","word_nosc":"tracasse","lemma":"tracasser","pos":"VER"} ,
		{"word":"tracassent","word_nosc":"tracassent","lemma":"tracasser","pos":"VER"} ,
		{"word":"tracasser","word_nosc":"tracasser","lemma":"tracasser","pos":"VER"} ,
		{"word":"tracassera","word_nosc":"tracassera","lemma":"tracasser","pos":"VER"} ,
		{"word":"tracasserais","word_nosc":"tracasserais","lemma":"tracasser","pos":"VER"} ,
		{"word":"tracasserait","word_nosc":"tracasserait","lemma":"tracasser","pos":"VER"} ,
		{"word":"tracasserons","word_nosc":"tracasserons","lemma":"tracasser","pos":"VER"} ,
		{"word":"tracasses","word_nosc":"tracasses","lemma":"tracasser","pos":"VER"} ,
		{"word":"tracassez","word_nosc":"tracassez","lemma":"tracasser","pos":"VER"} ,
		{"word":"tracassé","word_nosc":"tracasse","lemma":"tracasser","pos":"VER"} ,
		{"word":"tracassée","word_nosc":"tracassee","lemma":"tracasser","pos":"VER"} ,
		{"word":"tracassées","word_nosc":"tracassees","lemma":"tracasser","pos":"VER"} ,
		{"word":"trace","word_nosc":"trace","lemma":"tracer","pos":"VER"} ,
		{"word":"tracent","word_nosc":"tracent","lemma":"tracer","pos":"VER"} ,
		{"word":"tracer","word_nosc":"tracer","lemma":"tracer","pos":"VER"} ,
		{"word":"tracera","word_nosc":"tracera","lemma":"tracer","pos":"VER"} ,
		{"word":"tracerez","word_nosc":"tracerez","lemma":"tracer","pos":"VER"} ,
		{"word":"tracerons","word_nosc":"tracerons","lemma":"tracer","pos":"VER"} ,
		{"word":"traces","word_nosc":"traces","lemma":"tracer","pos":"VER"} ,
		{"word":"tracez","word_nosc":"tracez","lemma":"tracer","pos":"VER"} ,
		{"word":"tracions","word_nosc":"tracions","lemma":"tracer","pos":"VER"} ,
		{"word":"tractait","word_nosc":"tractait","lemma":"tracter","pos":"VER"} ,
		{"word":"tractant","word_nosc":"tractant","lemma":"tracter","pos":"VER"} ,
		{"word":"tracter","word_nosc":"tracter","lemma":"tracter","pos":"VER"} ,
		{"word":"tracté","word_nosc":"tracte","lemma":"tracter","pos":"VER"} ,
		{"word":"tractée","word_nosc":"tractee","lemma":"tracter","pos":"VER"} ,
		{"word":"tracèrent","word_nosc":"tracerent","lemma":"tracer","pos":"VER"} ,
		{"word":"tracé","word_nosc":"trace","lemma":"tracer","pos":"VER"} ,
		{"word":"tracée","word_nosc":"tracee","lemma":"tracer","pos":"VER"} ,
		{"word":"tracées","word_nosc":"tracees","lemma":"tracer","pos":"VER"} ,
		{"word":"tracés","word_nosc":"traces","lemma":"tracer","pos":"VER"} ,
		{"word":"traduira","word_nosc":"traduira","lemma":"traduire","pos":"VER"} ,
		{"word":"traduirai","word_nosc":"traduirai","lemma":"traduire","pos":"VER"} ,
		{"word":"traduiraient","word_nosc":"traduiraient","lemma":"traduire","pos":"VER"} ,
		{"word":"traduirais","word_nosc":"traduirais","lemma":"traduire","pos":"VER"} ,
		{"word":"traduirait","word_nosc":"traduirait","lemma":"traduire","pos":"VER"} ,
		{"word":"traduire","word_nosc":"traduire","lemma":"traduire","pos":"VER"} ,
		{"word":"traduirez","word_nosc":"traduirez","lemma":"traduire","pos":"VER"} ,
		{"word":"traduis","word_nosc":"traduis","lemma":"traduire","pos":"VER"} ,
		{"word":"traduisaient","word_nosc":"traduisaient","lemma":"traduire","pos":"VER"} ,
		{"word":"traduisais","word_nosc":"traduisais","lemma":"traduire","pos":"VER"} ,
		{"word":"traduisait","word_nosc":"traduisait","lemma":"traduire","pos":"VER"} ,
		{"word":"traduisant","word_nosc":"traduisant","lemma":"traduire","pos":"VER"} ,
		{"word":"traduise","word_nosc":"traduise","lemma":"traduire","pos":"VER"} ,
		{"word":"traduisent","word_nosc":"traduisent","lemma":"traduire","pos":"VER"} ,
		{"word":"traduises","word_nosc":"traduises","lemma":"traduire","pos":"VER"} ,
		{"word":"traduisez","word_nosc":"traduisez","lemma":"traduire","pos":"VER"} ,
		{"word":"traduisions","word_nosc":"traduisions","lemma":"traduire","pos":"VER"} ,
		{"word":"traduisis","word_nosc":"traduisis","lemma":"traduire","pos":"VER"} ,
		{"word":"traduisit","word_nosc":"traduisit","lemma":"traduire","pos":"VER"} ,
		{"word":"traduisons","word_nosc":"traduisons","lemma":"traduire","pos":"VER"} ,
		{"word":"traduisît","word_nosc":"traduisit","lemma":"traduire","pos":"VER"} ,
		{"word":"traduit","word_nosc":"traduit","lemma":"traduire","pos":"VER"} ,
		{"word":"traduite","word_nosc":"traduite","lemma":"traduire","pos":"VER"} ,
		{"word":"traduites","word_nosc":"traduites","lemma":"traduire","pos":"VER"} ,
		{"word":"traduits","word_nosc":"traduits","lemma":"traduire","pos":"VER"} ,
		{"word":"traficotaient","word_nosc":"traficotaient","lemma":"traficoter","pos":"VER"} ,
		{"word":"traficotait","word_nosc":"traficotait","lemma":"traficoter","pos":"VER"} ,
		{"word":"traficote","word_nosc":"traficote","lemma":"traficoter","pos":"VER"} ,
		{"word":"traficoter","word_nosc":"traficoter","lemma":"traficoter","pos":"VER"} ,
		{"word":"traficotes","word_nosc":"traficotes","lemma":"traficoter","pos":"VER"} ,
		{"word":"traficotez","word_nosc":"traficotez","lemma":"traficoter","pos":"VER"} ,
		{"word":"traficoté","word_nosc":"traficote","lemma":"traficoter","pos":"VER"} ,
		{"word":"trafiquaient","word_nosc":"trafiquaient","lemma":"trafiquer","pos":"VER"} ,
		{"word":"trafiquais","word_nosc":"trafiquais","lemma":"trafiquer","pos":"VER"} ,
		{"word":"trafiquait","word_nosc":"trafiquait","lemma":"trafiquer","pos":"VER"} ,
		{"word":"trafiquant","word_nosc":"trafiquant","lemma":"trafiquer","pos":"VER"} ,
		{"word":"trafique","word_nosc":"trafique","lemma":"trafiquer","pos":"VER"} ,
		{"word":"trafiquent","word_nosc":"trafiquent","lemma":"trafiquer","pos":"VER"} ,
		{"word":"trafiquer","word_nosc":"trafiquer","lemma":"trafiquer","pos":"VER"} ,
		{"word":"trafiquerais","word_nosc":"trafiquerais","lemma":"trafiquer","pos":"VER"} ,
		{"word":"trafiques","word_nosc":"trafiques","lemma":"trafiquer","pos":"VER"} ,
		{"word":"trafiquez","word_nosc":"trafiquez","lemma":"trafiquer","pos":"VER"} ,
		{"word":"trafiquions","word_nosc":"trafiquions","lemma":"trafiquer","pos":"VER"} ,
		{"word":"trafiqué","word_nosc":"trafique","lemma":"trafiquer","pos":"VER"} ,
		{"word":"trafiquée","word_nosc":"trafiquee","lemma":"trafiquer","pos":"VER"} ,
		{"word":"trafiquées","word_nosc":"trafiquees","lemma":"trafiquer","pos":"VER"} ,
		{"word":"trafiqués","word_nosc":"trafiques","lemma":"trafiquer","pos":"VER"} ,
		{"word":"trahi","word_nosc":"trahi","lemma":"trahir","pos":"VER"} ,
		{"word":"trahie","word_nosc":"trahie","lemma":"trahir","pos":"VER"} ,
		{"word":"trahies","word_nosc":"trahies","lemma":"trahir","pos":"VER"} ,
		{"word":"trahir","word_nosc":"trahir","lemma":"trahir","pos":"VER"} ,
		{"word":"trahira","word_nosc":"trahira","lemma":"trahir","pos":"VER"} ,
		{"word":"trahirai","word_nosc":"trahirai","lemma":"trahir","pos":"VER"} ,
		{"word":"trahiraient","word_nosc":"trahiraient","lemma":"trahir","pos":"VER"} ,
		{"word":"trahirais","word_nosc":"trahirais","lemma":"trahir","pos":"VER"} ,
		{"word":"trahirait","word_nosc":"trahirait","lemma":"trahir","pos":"VER"} ,
		{"word":"trahiras","word_nosc":"trahiras","lemma":"trahir","pos":"VER"} ,
		{"word":"trahirent","word_nosc":"trahirent","lemma":"trahir","pos":"VER"} ,
		{"word":"trahirez","word_nosc":"trahirez","lemma":"trahir","pos":"VER"} ,
		{"word":"trahiriez","word_nosc":"trahiriez","lemma":"trahir","pos":"VER"} ,
		{"word":"trahirons","word_nosc":"trahirons","lemma":"trahir","pos":"VER"} ,
		{"word":"trahiront","word_nosc":"trahiront","lemma":"trahir","pos":"VER"} ,
		{"word":"trahis","word_nosc":"trahis","lemma":"trahir","pos":"VER"} ,
		{"word":"trahissaient","word_nosc":"trahissaient","lemma":"trahir","pos":"VER"} ,
		{"word":"trahissais","word_nosc":"trahissais","lemma":"trahir","pos":"VER"} ,
		{"word":"trahissait","word_nosc":"trahissait","lemma":"trahir","pos":"VER"} ,
		{"word":"trahissant","word_nosc":"trahissant","lemma":"trahir","pos":"VER"} ,
		{"word":"trahisse","word_nosc":"trahisse","lemma":"trahir","pos":"VER"} ,
		{"word":"trahissent","word_nosc":"trahissent","lemma":"trahir","pos":"VER"} ,
		{"word":"trahissez","word_nosc":"trahissez","lemma":"trahir","pos":"VER"} ,
		{"word":"trahissiez","word_nosc":"trahissiez","lemma":"trahir","pos":"VER"} ,
		{"word":"trahissions","word_nosc":"trahissions","lemma":"trahir","pos":"VER"} ,
		{"word":"trahissons","word_nosc":"trahissons","lemma":"trahir","pos":"VER"} ,
		{"word":"trahit","word_nosc":"trahit","lemma":"trahir","pos":"VER"} ,
		{"word":"traie","word_nosc":"traie","lemma":"traire","pos":"VER"} ,
		{"word":"traira","word_nosc":"traira","lemma":"traire","pos":"VER"} ,
		{"word":"trairait","word_nosc":"trairait","lemma":"traire","pos":"VER"} ,
		{"word":"traire","word_nosc":"traire","lemma":"traire","pos":"VER"} ,
		{"word":"trais","word_nosc":"trais","lemma":"traire","pos":"VER"} ,
		{"word":"trait","word_nosc":"trait","lemma":"traire","pos":"VER"} ,
		{"word":"traita","word_nosc":"traita","lemma":"traiter","pos":"VER"} ,
		{"word":"traitai","word_nosc":"traitai","lemma":"traiter","pos":"VER"} ,
		{"word":"traitaient","word_nosc":"traitaient","lemma":"traiter","pos":"VER"} ,
		{"word":"traitais","word_nosc":"traitais","lemma":"traiter","pos":"VER"} ,
		{"word":"traitait","word_nosc":"traitait","lemma":"traiter","pos":"VER"} ,
		{"word":"traitant","word_nosc":"traitant","lemma":"traiter","pos":"VER"} ,
		{"word":"traitassent","word_nosc":"traitassent","lemma":"traiter","pos":"VER"} ,
		{"word":"traite","word_nosc":"traite","lemma":"traiter","pos":"VER"} ,
		{"word":"traitent","word_nosc":"traitent","lemma":"traiter","pos":"VER"} ,
		{"word":"traiter","word_nosc":"traiter","lemma":"traiter","pos":"VER"} ,
		{"word":"traitera","word_nosc":"traitera","lemma":"traiter","pos":"VER"} ,
		{"word":"traiterai","word_nosc":"traiterai","lemma":"traiter","pos":"VER"} ,
		{"word":"traiteraient","word_nosc":"traiteraient","lemma":"traiter","pos":"VER"} ,
		{"word":"traiterais","word_nosc":"traiterais","lemma":"traiter","pos":"VER"} ,
		{"word":"traiterait","word_nosc":"traiterait","lemma":"traiter","pos":"VER"} ,
		{"word":"traiteras","word_nosc":"traiteras","lemma":"traiter","pos":"VER"} ,
		{"word":"traiterez","word_nosc":"traiterez","lemma":"traiter","pos":"VER"} ,
		{"word":"traiteriez","word_nosc":"traiteriez","lemma":"traiter","pos":"VER"} ,
		{"word":"traiterons","word_nosc":"traiterons","lemma":"traiter","pos":"VER"} ,
		{"word":"traiteront","word_nosc":"traiteront","lemma":"traiter","pos":"VER"} ,
		{"word":"traites","word_nosc":"traites","lemma":"traiter","pos":"VER"} ,
		{"word":"traitez","word_nosc":"traitez","lemma":"traiter","pos":"VER"} ,
		{"word":"traitiez","word_nosc":"traitiez","lemma":"traiter","pos":"VER"} ,
		{"word":"traitions","word_nosc":"traitions","lemma":"traiter","pos":"VER"} ,
		{"word":"traitons","word_nosc":"traitons","lemma":"traiter","pos":"VER"} ,
		{"word":"traits","word_nosc":"traits","lemma":"traire","pos":"VER"} ,
		{"word":"traitât","word_nosc":"traitat","lemma":"traiter","pos":"VER"} ,
		{"word":"traitèrent","word_nosc":"traiterent","lemma":"traiter","pos":"VER"} ,
		{"word":"traité","word_nosc":"traite","lemma":"traiter","pos":"VER"} ,
		{"word":"traitée","word_nosc":"traitee","lemma":"traiter","pos":"VER"} ,
		{"word":"traitées","word_nosc":"traitees","lemma":"traiter","pos":"VER"} ,
		{"word":"traités","word_nosc":"traites","lemma":"traiter","pos":"VER"} ,
		{"word":"tramaient","word_nosc":"tramaient","lemma":"tramer","pos":"VER"} ,
		{"word":"tramait","word_nosc":"tramait","lemma":"tramer","pos":"VER"} ,
		{"word":"tramant","word_nosc":"tramant","lemma":"tramer","pos":"VER"} ,
		{"word":"trame","word_nosc":"trame","lemma":"tramer","pos":"VER"} ,
		{"word":"trament","word_nosc":"trament","lemma":"tramer","pos":"VER"} ,
		{"word":"tramer","word_nosc":"tramer","lemma":"tramer","pos":"VER"} ,
		{"word":"trames","word_nosc":"trames","lemma":"tramer","pos":"VER"} ,
		{"word":"tramez","word_nosc":"tramez","lemma":"tramer","pos":"VER"} ,
		{"word":"tramé","word_nosc":"trame","lemma":"tramer","pos":"VER"} ,
		{"word":"tramés","word_nosc":"trames","lemma":"tramer","pos":"VER"} ,
		{"word":"trancha","word_nosc":"trancha","lemma":"trancher","pos":"VER"} ,
		{"word":"tranchai","word_nosc":"tranchai","lemma":"trancher","pos":"VER"} ,
		{"word":"tranchaient","word_nosc":"tranchaient","lemma":"trancher","pos":"VER"} ,
		{"word":"tranchais","word_nosc":"tranchais","lemma":"trancher","pos":"VER"} ,
		{"word":"tranchait","word_nosc":"tranchait","lemma":"trancher","pos":"VER"} ,
		{"word":"tranchant","word_nosc":"tranchant","lemma":"trancher","pos":"VER"} ,
		{"word":"tranche","word_nosc":"tranche","lemma":"trancher","pos":"VER"} ,
		{"word":"tranchent","word_nosc":"tranchent","lemma":"trancher","pos":"VER"} ,
		{"word":"trancher","word_nosc":"trancher","lemma":"trancher","pos":"VER"} ,
		{"word":"tranchera","word_nosc":"tranchera","lemma":"trancher","pos":"VER"} ,
		{"word":"trancherai","word_nosc":"trancherai","lemma":"trancher","pos":"VER"} ,
		{"word":"trancherais","word_nosc":"trancherais","lemma":"trancher","pos":"VER"} ,
		{"word":"trancherait","word_nosc":"trancherait","lemma":"trancher","pos":"VER"} ,
		{"word":"trancheras","word_nosc":"trancheras","lemma":"trancher","pos":"VER"} ,
		{"word":"trancheront","word_nosc":"trancheront","lemma":"trancher","pos":"VER"} ,
		{"word":"tranches","word_nosc":"tranches","lemma":"trancher","pos":"VER"} ,
		{"word":"tranchez","word_nosc":"tranchez","lemma":"trancher","pos":"VER"} ,
		{"word":"tranchiez","word_nosc":"tranchiez","lemma":"trancher","pos":"VER"} ,
		{"word":"tranchons","word_nosc":"tranchons","lemma":"trancher","pos":"VER"} ,
		{"word":"tranchât","word_nosc":"tranchat","lemma":"trancher","pos":"VER"} ,
		{"word":"tranchèrent","word_nosc":"trancherent","lemma":"trancher","pos":"VER"} ,
		{"word":"tranché","word_nosc":"tranche","lemma":"trancher","pos":"VER"} ,
		{"word":"tranchée","word_nosc":"tranchee","lemma":"trancher","pos":"VER"} ,
		{"word":"tranchées","word_nosc":"tranchees","lemma":"trancher","pos":"VER"} ,
		{"word":"tranchés","word_nosc":"tranches","lemma":"trancher","pos":"VER"} ,
		{"word":"tranquillisa","word_nosc":"tranquillisa","lemma":"tranquilliser","pos":"VER"} ,
		{"word":"tranquillisai","word_nosc":"tranquillisai","lemma":"tranquilliser","pos":"VER"} ,
		{"word":"tranquillisaient","word_nosc":"tranquillisaient","lemma":"tranquilliser","pos":"VER"} ,
		{"word":"tranquillisait","word_nosc":"tranquillisait","lemma":"tranquilliser","pos":"VER"} ,
		{"word":"tranquillisant","word_nosc":"tranquillisant","lemma":"tranquilliser","pos":"VER"} ,
		{"word":"tranquillise","word_nosc":"tranquillise","lemma":"tranquilliser","pos":"VER"} ,
		{"word":"tranquillisent","word_nosc":"tranquillisent","lemma":"tranquilliser","pos":"VER"} ,
		{"word":"tranquilliser","word_nosc":"tranquilliser","lemma":"tranquilliser","pos":"VER"} ,
		{"word":"tranquillisera","word_nosc":"tranquillisera","lemma":"tranquilliser","pos":"VER"} ,
		{"word":"tranquilliserait","word_nosc":"tranquilliserait","lemma":"tranquilliser","pos":"VER"} ,
		{"word":"tranquillises","word_nosc":"tranquillises","lemma":"tranquilliser","pos":"VER"} ,
		{"word":"tranquillisez","word_nosc":"tranquillisez","lemma":"tranquilliser","pos":"VER"} ,
		{"word":"tranquillisé","word_nosc":"tranquillise","lemma":"tranquilliser","pos":"VER"} ,
		{"word":"tranquillisée","word_nosc":"tranquillisee","lemma":"tranquilliser","pos":"VER"} ,
		{"word":"tranquillisés","word_nosc":"tranquillises","lemma":"tranquilliser","pos":"VER"} ,
		{"word":"transbahutaient","word_nosc":"transbahutaient","lemma":"transbahuter","pos":"VER"} ,
		{"word":"transbahutant","word_nosc":"transbahutant","lemma":"transbahuter","pos":"VER"} ,
		{"word":"transbahute","word_nosc":"transbahute","lemma":"transbahuter","pos":"VER"} ,
		{"word":"transbahutent","word_nosc":"transbahutent","lemma":"transbahuter","pos":"VER"} ,
		{"word":"transbahuter","word_nosc":"transbahuter","lemma":"transbahuter","pos":"VER"} ,
		{"word":"transbahutez","word_nosc":"transbahutez","lemma":"transbahuter","pos":"VER"} ,
		{"word":"transbahuté","word_nosc":"transbahute","lemma":"transbahuter","pos":"VER"} ,
		{"word":"transbahutée","word_nosc":"transbahutee","lemma":"transbahuter","pos":"VER"} ,
		{"word":"transbahutés","word_nosc":"transbahutes","lemma":"transbahuter","pos":"VER"} ,
		{"word":"transborder","word_nosc":"transborder","lemma":"transborder","pos":"VER"} ,
		{"word":"transbordé","word_nosc":"transborde","lemma":"transborder","pos":"VER"} ,
		{"word":"transcendant","word_nosc":"transcendant","lemma":"transcender","pos":"VER"} ,
		{"word":"transcende","word_nosc":"transcende","lemma":"transcender","pos":"VER"} ,
		{"word":"transcendent","word_nosc":"transcendent","lemma":"transcender","pos":"VER"} ,
		{"word":"transcender","word_nosc":"transcender","lemma":"transcender","pos":"VER"} ,
		{"word":"transcendons","word_nosc":"transcendons","lemma":"transcender","pos":"VER"} ,
		{"word":"transcendé","word_nosc":"transcende","lemma":"transcender","pos":"VER"} ,
		{"word":"transcendée","word_nosc":"transcendee","lemma":"transcender","pos":"VER"} ,
		{"word":"transcrire","word_nosc":"transcrire","lemma":"transcrire","pos":"VER"} ,
		{"word":"transcris","word_nosc":"transcris","lemma":"transcrire","pos":"VER"} ,
		{"word":"transcrit","word_nosc":"transcrit","lemma":"transcrire","pos":"VER"} ,
		{"word":"transcrite","word_nosc":"transcrite","lemma":"transcrire","pos":"VER"} ,
		{"word":"transcrites","word_nosc":"transcrites","lemma":"transcrire","pos":"VER"} ,
		{"word":"transcrits","word_nosc":"transcrits","lemma":"transcrire","pos":"VER"} ,
		{"word":"transcrivaient","word_nosc":"transcrivaient","lemma":"transcrire","pos":"VER"} ,
		{"word":"transcrivais","word_nosc":"transcrivais","lemma":"transcrire","pos":"VER"} ,
		{"word":"transcrivait","word_nosc":"transcrivait","lemma":"transcrire","pos":"VER"} ,
		{"word":"transcrivant","word_nosc":"transcrivant","lemma":"transcrire","pos":"VER"} ,
		{"word":"transcrive","word_nosc":"transcrive","lemma":"transcrire","pos":"VER"} ,
		{"word":"transcrivez","word_nosc":"transcrivez","lemma":"transcrire","pos":"VER"} ,
		{"word":"transcrivis","word_nosc":"transcrivis","lemma":"transcrire","pos":"VER"} ,
		{"word":"transcrivit","word_nosc":"transcrivit","lemma":"transcrire","pos":"VER"} ,
		{"word":"transfigura","word_nosc":"transfigura","lemma":"transfigurer","pos":"VER"} ,
		{"word":"transfiguraient","word_nosc":"transfiguraient","lemma":"transfigurer","pos":"VER"} ,
		{"word":"transfigurait","word_nosc":"transfigurait","lemma":"transfigurer","pos":"VER"} ,
		{"word":"transfigurant","word_nosc":"transfigurant","lemma":"transfigurer","pos":"VER"} ,
		{"word":"transfigure","word_nosc":"transfigure","lemma":"transfigurer","pos":"VER"} ,
		{"word":"transfigurent","word_nosc":"transfigurent","lemma":"transfigurer","pos":"VER"} ,
		{"word":"transfigurer","word_nosc":"transfigurer","lemma":"transfigurer","pos":"VER"} ,
		{"word":"transfigurerait","word_nosc":"transfigurerait","lemma":"transfigurer","pos":"VER"} ,
		{"word":"transfigurez","word_nosc":"transfigurez","lemma":"transfigurer","pos":"VER"} ,
		{"word":"transfiguré","word_nosc":"transfigure","lemma":"transfigurer","pos":"VER"} ,
		{"word":"transfigurée","word_nosc":"transfiguree","lemma":"transfigurer","pos":"VER"} ,
		{"word":"transfigurées","word_nosc":"transfigurees","lemma":"transfigurer","pos":"VER"} ,
		{"word":"transfigurés","word_nosc":"transfigures","lemma":"transfigurer","pos":"VER"} ,
		{"word":"transfilaient","word_nosc":"transfilaient","lemma":"transfiler","pos":"VER"} ,
		{"word":"transfilée","word_nosc":"transfilee","lemma":"transfiler","pos":"VER"} ,
		{"word":"transforma","word_nosc":"transforma","lemma":"transformer","pos":"VER"} ,
		{"word":"transformai","word_nosc":"transformai","lemma":"transformer","pos":"VER"} ,
		{"word":"transformaient","word_nosc":"transformaient","lemma":"transformer","pos":"VER"} ,
		{"word":"transformais","word_nosc":"transformais","lemma":"transformer","pos":"VER"} ,
		{"word":"transformait","word_nosc":"transformait","lemma":"transformer","pos":"VER"} ,
		{"word":"transformant","word_nosc":"transformant","lemma":"transformer","pos":"VER"} ,
		{"word":"transformas","word_nosc":"transformas","lemma":"transformer","pos":"VER"} ,
		{"word":"transforme","word_nosc":"transforme","lemma":"transformer","pos":"VER"} ,
		{"word":"transforment","word_nosc":"transforment","lemma":"transformer","pos":"VER"} ,
		{"word":"transformer","word_nosc":"transformer","lemma":"transformer","pos":"VER"} ,
		{"word":"transformera","word_nosc":"transformera","lemma":"transformer","pos":"VER"} ,
		{"word":"transformerai","word_nosc":"transformerai","lemma":"transformer","pos":"VER"} ,
		{"word":"transformeraient","word_nosc":"transformeraient","lemma":"transformer","pos":"VER"} ,
		{"word":"transformerais","word_nosc":"transformerais","lemma":"transformer","pos":"VER"} ,
		{"word":"transformerait","word_nosc":"transformerait","lemma":"transformer","pos":"VER"} ,
		{"word":"transformeras","word_nosc":"transformeras","lemma":"transformer","pos":"VER"} ,
		{"word":"transformeriez","word_nosc":"transformeriez","lemma":"transformer","pos":"VER"} ,
		{"word":"transformerons","word_nosc":"transformerons","lemma":"transformer","pos":"VER"} ,
		{"word":"transformeront","word_nosc":"transformeront","lemma":"transformer","pos":"VER"} ,
		{"word":"transformes","word_nosc":"transformes","lemma":"transformer","pos":"VER"} ,
		{"word":"transformez","word_nosc":"transformez","lemma":"transformer","pos":"VER"} ,
		{"word":"transformiez","word_nosc":"transformiez","lemma":"transformer","pos":"VER"} ,
		{"word":"transformons","word_nosc":"transformons","lemma":"transformer","pos":"VER"} ,
		{"word":"transformât","word_nosc":"transformat","lemma":"transformer","pos":"VER"} ,
		{"word":"transformèrent","word_nosc":"transformerent","lemma":"transformer","pos":"VER"} ,
		{"word":"transformé","word_nosc":"transforme","lemma":"transformer","pos":"VER"} ,
		{"word":"transformée","word_nosc":"transformee","lemma":"transformer","pos":"VER"} ,
		{"word":"transformées","word_nosc":"transformees","lemma":"transformer","pos":"VER"} ,
		{"word":"transformés","word_nosc":"transformes","lemma":"transformer","pos":"VER"} ,
		{"word":"transfusait","word_nosc":"transfusait","lemma":"transfuser","pos":"VER"} ,
		{"word":"transfuse","word_nosc":"transfuse","lemma":"transfuser","pos":"VER"} ,
		{"word":"transfuser","word_nosc":"transfuser","lemma":"transfuser","pos":"VER"} ,
		{"word":"transfusé","word_nosc":"transfuse","lemma":"transfuser","pos":"VER"} ,
		{"word":"transfusés","word_nosc":"transfuses","lemma":"transfuser","pos":"VER"} ,
		{"word":"transfère","word_nosc":"transfere","lemma":"transférer","pos":"VER"} ,
		{"word":"transfèrent","word_nosc":"transferent","lemma":"transférer","pos":"VER"} ,
		{"word":"transféra","word_nosc":"transfera","lemma":"transférer","pos":"VER"} ,
		{"word":"transféraient","word_nosc":"transferaient","lemma":"transférer","pos":"VER"} ,
		{"word":"transférais","word_nosc":"transferais","lemma":"transférer","pos":"VER"} ,
		{"word":"transférait","word_nosc":"transferait","lemma":"transférer","pos":"VER"} ,
		{"word":"transférant","word_nosc":"transferant","lemma":"transférer","pos":"VER"} ,
		{"word":"transférer","word_nosc":"transferer","lemma":"transférer","pos":"VER"} ,
		{"word":"transférera","word_nosc":"transferera","lemma":"transférer","pos":"VER"} ,
		{"word":"transférerai","word_nosc":"transfererai","lemma":"transférer","pos":"VER"} ,
		{"word":"transférerait","word_nosc":"transfererait","lemma":"transférer","pos":"VER"} ,
		{"word":"transféreras","word_nosc":"transfereras","lemma":"transférer","pos":"VER"} ,
		{"word":"transférerez","word_nosc":"transfererez","lemma":"transférer","pos":"VER"} ,
		{"word":"transférerons","word_nosc":"transfererons","lemma":"transférer","pos":"VER"} ,
		{"word":"transféreront","word_nosc":"transfereront","lemma":"transférer","pos":"VER"} ,
		{"word":"transférez","word_nosc":"transferez","lemma":"transférer","pos":"VER"} ,
		{"word":"transfériez","word_nosc":"transferiez","lemma":"transférer","pos":"VER"} ,
		{"word":"transférons","word_nosc":"transferons","lemma":"transférer","pos":"VER"} ,
		{"word":"transférâmes","word_nosc":"transferames","lemma":"transférer","pos":"VER"} ,
		{"word":"transférèrent","word_nosc":"transfererent","lemma":"transférer","pos":"VER"} ,
		{"word":"transféré","word_nosc":"transfere","lemma":"transférer","pos":"VER"} ,
		{"word":"transférée","word_nosc":"transferee","lemma":"transférer","pos":"VER"} ,
		{"word":"transférées","word_nosc":"transferees","lemma":"transférer","pos":"VER"} ,
		{"word":"transférés","word_nosc":"transferes","lemma":"transférer","pos":"VER"} ,
		{"word":"transgressait","word_nosc":"transgressait","lemma":"transgresser","pos":"VER"} ,
		{"word":"transgresse","word_nosc":"transgresse","lemma":"transgresser","pos":"VER"} ,
		{"word":"transgressent","word_nosc":"transgressent","lemma":"transgresser","pos":"VER"} ,
		{"word":"transgresser","word_nosc":"transgresser","lemma":"transgresser","pos":"VER"} ,
		{"word":"transgressez","word_nosc":"transgressez","lemma":"transgresser","pos":"VER"} ,
		{"word":"transgressons","word_nosc":"transgressons","lemma":"transgresser","pos":"VER"} ,
		{"word":"transgressé","word_nosc":"transgresse","lemma":"transgresser","pos":"VER"} ,
		{"word":"transgressée","word_nosc":"transgressee","lemma":"transgresser","pos":"VER"} ,
		{"word":"transgressées","word_nosc":"transgressees","lemma":"transgresser","pos":"VER"} ,
		{"word":"transhumait","word_nosc":"transhumait","lemma":"transhumer","pos":"VER"} ,
		{"word":"transhumés","word_nosc":"transhumes","lemma":"transhumer","pos":"VER"} ,
		{"word":"transi","word_nosc":"transi","lemma":"transir","pos":"VER"} ,
		{"word":"transie","word_nosc":"transie","lemma":"transir","pos":"VER"} ,
		{"word":"transies","word_nosc":"transies","lemma":"transir","pos":"VER"} ,
		{"word":"transige","word_nosc":"transige","lemma":"transiger","pos":"VER"} ,
		{"word":"transigeait","word_nosc":"transigeait","lemma":"transiger","pos":"VER"} ,
		{"word":"transigeant","word_nosc":"transigeant","lemma":"transiger","pos":"VER"} ,
		{"word":"transigent","word_nosc":"transigent","lemma":"transiger","pos":"VER"} ,
		{"word":"transigeons","word_nosc":"transigeons","lemma":"transiger","pos":"VER"} ,
		{"word":"transiger","word_nosc":"transiger","lemma":"transiger","pos":"VER"} ,
		{"word":"transigera","word_nosc":"transigera","lemma":"transiger","pos":"VER"} ,
		{"word":"transigerai","word_nosc":"transigerai","lemma":"transiger","pos":"VER"} ,
		{"word":"transigé","word_nosc":"transige","lemma":"transiger","pos":"VER"} ,
		{"word":"transir","word_nosc":"transir","lemma":"transir","pos":"VER"} ,
		{"word":"transis","word_nosc":"transis","lemma":"transir","pos":"VER"} ,
		{"word":"transistorisés","word_nosc":"transistorises","lemma":"transistoriser","pos":"VER"} ,
		{"word":"transit","word_nosc":"transit","lemma":"transir","pos":"VER"} ,
		{"word":"transitaient","word_nosc":"transitaient","lemma":"transiter","pos":"VER"} ,
		{"word":"transitais","word_nosc":"transitais","lemma":"transiter","pos":"VER"} ,
		{"word":"transitait","word_nosc":"transitait","lemma":"transiter","pos":"VER"} ,
		{"word":"transitant","word_nosc":"transitant","lemma":"transiter","pos":"VER"} ,
		{"word":"transite","word_nosc":"transite","lemma":"transiter","pos":"VER"} ,
		{"word":"transitent","word_nosc":"transitent","lemma":"transiter","pos":"VER"} ,
		{"word":"transiter","word_nosc":"transiter","lemma":"transiter","pos":"VER"} ,
		{"word":"transité","word_nosc":"transite","lemma":"transiter","pos":"VER"} ,
		{"word":"translater","word_nosc":"translater","lemma":"translater","pos":"VER"} ,
		{"word":"translates","word_nosc":"translates","lemma":"translater","pos":"VER"} ,
		{"word":"transmet","word_nosc":"transmet","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmets","word_nosc":"transmets","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettaient","word_nosc":"transmettaient","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettais","word_nosc":"transmettais","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettait","word_nosc":"transmettait","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettant","word_nosc":"transmettant","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmette","word_nosc":"transmette","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettent","word_nosc":"transmettent","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettez","word_nosc":"transmettez","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettions","word_nosc":"transmettions","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettons","word_nosc":"transmettons","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettra","word_nosc":"transmettra","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettrai","word_nosc":"transmettrai","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettrait","word_nosc":"transmettrait","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettras","word_nosc":"transmettras","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettre","word_nosc":"transmettre","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettrez","word_nosc":"transmettrez","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettrons","word_nosc":"transmettrons","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmettront","word_nosc":"transmettront","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmigrait","word_nosc":"transmigrait","lemma":"transmigrer","pos":"VER"} ,
		{"word":"transmigrent","word_nosc":"transmigrent","lemma":"transmigrer","pos":"VER"} ,
		{"word":"transmirent","word_nosc":"transmirent","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmis","word_nosc":"transmis","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmise","word_nosc":"transmise","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmises","word_nosc":"transmises","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmit","word_nosc":"transmit","lemma":"transmettre","pos":"VER"} ,
		{"word":"transmuait","word_nosc":"transmuait","lemma":"transmuer","pos":"VER"} ,
		{"word":"transmuer","word_nosc":"transmuer","lemma":"transmuer","pos":"VER"} ,
		{"word":"transmutait","word_nosc":"transmutait","lemma":"transmuter","pos":"VER"} ,
		{"word":"transmutant","word_nosc":"transmutant","lemma":"transmuter","pos":"VER"} ,
		{"word":"transmute","word_nosc":"transmute","lemma":"transmuter","pos":"VER"} ,
		{"word":"transmutent","word_nosc":"transmutent","lemma":"transmuter","pos":"VER"} ,
		{"word":"transmuter","word_nosc":"transmuter","lemma":"transmuter","pos":"VER"} ,
		{"word":"transmuté","word_nosc":"transmute","lemma":"transmuter","pos":"VER"} ,
		{"word":"transmué","word_nosc":"transmue","lemma":"transmuer","pos":"VER"} ,
		{"word":"transmuée","word_nosc":"transmuee","lemma":"transmuer","pos":"VER"} ,
		{"word":"transmuées","word_nosc":"transmuees","lemma":"transmuer","pos":"VER"} ,
		{"word":"transmués","word_nosc":"transmues","lemma":"transmuer","pos":"VER"} ,
		{"word":"transparaissaient","word_nosc":"transparaissaient","lemma":"transparaître","pos":"VER"} ,
		{"word":"transparaissait","word_nosc":"transparaissait","lemma":"transparaître","pos":"VER"} ,
		{"word":"transparaisse","word_nosc":"transparaisse","lemma":"transparaître","pos":"VER"} ,
		{"word":"transparaissent","word_nosc":"transparaissent","lemma":"transparaître","pos":"VER"} ,
		{"word":"transparaît","word_nosc":"transparait","lemma":"transparaître","pos":"VER"} ,
		{"word":"transparaître","word_nosc":"transparaitre","lemma":"transparaître","pos":"VER"} ,
		{"word":"transperce","word_nosc":"transperce","lemma":"transpercer","pos":"VER"} ,
		{"word":"transpercent","word_nosc":"transpercent","lemma":"transpercer","pos":"VER"} ,
		{"word":"transpercer","word_nosc":"transpercer","lemma":"transpercer","pos":"VER"} ,
		{"word":"transpercerai","word_nosc":"transpercerai","lemma":"transpercer","pos":"VER"} ,
		{"word":"transpercerait","word_nosc":"transpercerait","lemma":"transpercer","pos":"VER"} ,
		{"word":"transpercez","word_nosc":"transpercez","lemma":"transpercer","pos":"VER"} ,
		{"word":"transpercèrent","word_nosc":"transpercerent","lemma":"transpercer","pos":"VER"} ,
		{"word":"transpercé","word_nosc":"transperce","lemma":"transpercer","pos":"VER"} ,
		{"word":"transpercée","word_nosc":"transpercee","lemma":"transpercer","pos":"VER"} ,
		{"word":"transpercées","word_nosc":"transpercees","lemma":"transpercer","pos":"VER"} ,
		{"word":"transpercés","word_nosc":"transperces","lemma":"transpercer","pos":"VER"} ,
		{"word":"transperça","word_nosc":"transperca","lemma":"transpercer","pos":"VER"} ,
		{"word":"transperçaient","word_nosc":"transpercaient","lemma":"transpercer","pos":"VER"} ,
		{"word":"transperçait","word_nosc":"transpercait","lemma":"transpercer","pos":"VER"} ,
		{"word":"transperçant","word_nosc":"transpercant","lemma":"transpercer","pos":"VER"} ,
		{"word":"transpira","word_nosc":"transpira","lemma":"transpirer","pos":"VER"} ,
		{"word":"transpiraient","word_nosc":"transpiraient","lemma":"transpirer","pos":"VER"} ,
		{"word":"transpirais","word_nosc":"transpirais","lemma":"transpirer","pos":"VER"} ,
		{"word":"transpirait","word_nosc":"transpirait","lemma":"transpirer","pos":"VER"} ,
		{"word":"transpirant","word_nosc":"transpirant","lemma":"transpirer","pos":"VER"} ,
		{"word":"transpire","word_nosc":"transpire","lemma":"transpirer","pos":"VER"} ,
		{"word":"transpirent","word_nosc":"transpirent","lemma":"transpirer","pos":"VER"} ,
		{"word":"transpirer","word_nosc":"transpirer","lemma":"transpirer","pos":"VER"} ,
		{"word":"transpirera","word_nosc":"transpirera","lemma":"transpirer","pos":"VER"} ,
		{"word":"transpirerait","word_nosc":"transpirerait","lemma":"transpirer","pos":"VER"} ,
		{"word":"transpires","word_nosc":"transpires","lemma":"transpirer","pos":"VER"} ,
		{"word":"transpirez","word_nosc":"transpirez","lemma":"transpirer","pos":"VER"} ,
		{"word":"transpiré","word_nosc":"transpire","lemma":"transpirer","pos":"VER"} ,
		{"word":"transplantait","word_nosc":"transplantait","lemma":"transplanter","pos":"VER"} ,
		{"word":"transplante","word_nosc":"transplante","lemma":"transplanter","pos":"VER"} ,
		{"word":"transplanter","word_nosc":"transplanter","lemma":"transplanter","pos":"VER"} ,
		{"word":"transplantons","word_nosc":"transplantons","lemma":"transplanter","pos":"VER"} ,
		{"word":"transplanté","word_nosc":"transplante","lemma":"transplanter","pos":"VER"} ,
		{"word":"transplantée","word_nosc":"transplantee","lemma":"transplanter","pos":"VER"} ,
		{"word":"transplantés","word_nosc":"transplantes","lemma":"transplanter","pos":"VER"} ,
		{"word":"transporta","word_nosc":"transporta","lemma":"transporter","pos":"VER"} ,
		{"word":"transportai","word_nosc":"transportai","lemma":"transporter","pos":"VER"} ,
		{"word":"transportaient","word_nosc":"transportaient","lemma":"transporter","pos":"VER"} ,
		{"word":"transportais","word_nosc":"transportais","lemma":"transporter","pos":"VER"} ,
		{"word":"transportait","word_nosc":"transportait","lemma":"transporter","pos":"VER"} ,
		{"word":"transportant","word_nosc":"transportant","lemma":"transporter","pos":"VER"} ,
		{"word":"transporte","word_nosc":"transporte","lemma":"transporter","pos":"VER"} ,
		{"word":"transportent","word_nosc":"transportent","lemma":"transporter","pos":"VER"} ,
		{"word":"transporter","word_nosc":"transporter","lemma":"transporter","pos":"VER"} ,
		{"word":"transportera","word_nosc":"transportera","lemma":"transporter","pos":"VER"} ,
		{"word":"transporteraient","word_nosc":"transporteraient","lemma":"transporter","pos":"VER"} ,
		{"word":"transporterais","word_nosc":"transporterais","lemma":"transporter","pos":"VER"} ,
		{"word":"transporterait","word_nosc":"transporterait","lemma":"transporter","pos":"VER"} ,
		{"word":"transporterons","word_nosc":"transporterons","lemma":"transporter","pos":"VER"} ,
		{"word":"transportez","word_nosc":"transportez","lemma":"transporter","pos":"VER"} ,
		{"word":"transportiez","word_nosc":"transportiez","lemma":"transporter","pos":"VER"} ,
		{"word":"transportions","word_nosc":"transportions","lemma":"transporter","pos":"VER"} ,
		{"word":"transportons","word_nosc":"transportons","lemma":"transporter","pos":"VER"} ,
		{"word":"transportâmes","word_nosc":"transportames","lemma":"transporter","pos":"VER"} ,
		{"word":"transportât","word_nosc":"transportat","lemma":"transporter","pos":"VER"} ,
		{"word":"transportèrent","word_nosc":"transporterent","lemma":"transporter","pos":"VER"} ,
		{"word":"transporté","word_nosc":"transporte","lemma":"transporter","pos":"VER"} ,
		{"word":"transportée","word_nosc":"transportee","lemma":"transporter","pos":"VER"} ,
		{"word":"transportées","word_nosc":"transportees","lemma":"transporter","pos":"VER"} ,
		{"word":"transportés","word_nosc":"transportes","lemma":"transporter","pos":"VER"} ,
		{"word":"transposaient","word_nosc":"transposaient","lemma":"transposer","pos":"VER"} ,
		{"word":"transposait","word_nosc":"transposait","lemma":"transposer","pos":"VER"} ,
		{"word":"transposant","word_nosc":"transposant","lemma":"transposer","pos":"VER"} ,
		{"word":"transpose","word_nosc":"transpose","lemma":"transposer","pos":"VER"} ,
		{"word":"transposent","word_nosc":"transposent","lemma":"transposer","pos":"VER"} ,
		{"word":"transposer","word_nosc":"transposer","lemma":"transposer","pos":"VER"} ,
		{"word":"transposez","word_nosc":"transposez","lemma":"transposer","pos":"VER"} ,
		{"word":"transposons","word_nosc":"transposons","lemma":"transposer","pos":"VER"} ,
		{"word":"transposé","word_nosc":"transpose","lemma":"transposer","pos":"VER"} ,
		{"word":"transposée","word_nosc":"transposee","lemma":"transposer","pos":"VER"} ,
		{"word":"transposés","word_nosc":"transposes","lemma":"transposer","pos":"VER"} ,
		{"word":"transsudait","word_nosc":"transsudait","lemma":"transsuder","pos":"VER"} ,
		{"word":"transsudant","word_nosc":"transsudant","lemma":"transsuder","pos":"VER"} ,
		{"word":"transvasait","word_nosc":"transvasait","lemma":"transvaser","pos":"VER"} ,
		{"word":"transvase","word_nosc":"transvase","lemma":"transvaser","pos":"VER"} ,
		{"word":"transvaser","word_nosc":"transvaser","lemma":"transvaser","pos":"VER"} ,
		{"word":"transvasons","word_nosc":"transvasons","lemma":"transvaser","pos":"VER"} ,
		{"word":"transvasèrent","word_nosc":"transvaserent","lemma":"transvaser","pos":"VER"} ,
		{"word":"transvasées","word_nosc":"transvasees","lemma":"transvaser","pos":"VER"} ,
		{"word":"traqua","word_nosc":"traqua","lemma":"traquer","pos":"VER"} ,
		{"word":"traquaient","word_nosc":"traquaient","lemma":"traquer","pos":"VER"} ,
		{"word":"traquais","word_nosc":"traquais","lemma":"traquer","pos":"VER"} ,
		{"word":"traquait","word_nosc":"traquait","lemma":"traquer","pos":"VER"} ,
		{"word":"traquant","word_nosc":"traquant","lemma":"traquer","pos":"VER"} ,
		{"word":"traque","word_nosc":"traque","lemma":"traquer","pos":"VER"} ,
		{"word":"traquent","word_nosc":"traquent","lemma":"traquer","pos":"VER"} ,
		{"word":"traquer","word_nosc":"traquer","lemma":"traquer","pos":"VER"} ,
		{"word":"traquera","word_nosc":"traquera","lemma":"traquer","pos":"VER"} ,
		{"word":"traquerai","word_nosc":"traquerai","lemma":"traquer","pos":"VER"} ,
		{"word":"traquerais","word_nosc":"traquerais","lemma":"traquer","pos":"VER"} ,
		{"word":"traquerait","word_nosc":"traquerait","lemma":"traquer","pos":"VER"} ,
		{"word":"traquerons","word_nosc":"traquerons","lemma":"traquer","pos":"VER"} ,
		{"word":"traqueront","word_nosc":"traqueront","lemma":"traquer","pos":"VER"} ,
		{"word":"traques","word_nosc":"traques","lemma":"traquer","pos":"VER"} ,
		{"word":"traquez","word_nosc":"traquez","lemma":"traquer","pos":"VER"} ,
		{"word":"traquiez","word_nosc":"traquiez","lemma":"traquer","pos":"VER"} ,
		{"word":"traquions","word_nosc":"traquions","lemma":"traquer","pos":"VER"} ,
		{"word":"traquons","word_nosc":"traquons","lemma":"traquer","pos":"VER"} ,
		{"word":"traquèrent","word_nosc":"traquerent","lemma":"traquer","pos":"VER"} ,
		{"word":"traqué","word_nosc":"traque","lemma":"traquer","pos":"VER"} ,
		{"word":"traquée","word_nosc":"traquee","lemma":"traquer","pos":"VER"} ,
		{"word":"traquées","word_nosc":"traquees","lemma":"traquer","pos":"VER"} ,
		{"word":"traqués","word_nosc":"traques","lemma":"traquer","pos":"VER"} ,
		{"word":"traumatisant","word_nosc":"traumatisant","lemma":"traumatiser","pos":"VER"} ,
		{"word":"traumatise","word_nosc":"traumatise","lemma":"traumatiser","pos":"VER"} ,
		{"word":"traumatiser","word_nosc":"traumatiser","lemma":"traumatiser","pos":"VER"} ,
		{"word":"traumatisé","word_nosc":"traumatise","lemma":"traumatiser","pos":"VER"} ,
		{"word":"traumatisée","word_nosc":"traumatisee","lemma":"traumatiser","pos":"VER"} ,
		{"word":"traumatisées","word_nosc":"traumatisees","lemma":"traumatiser","pos":"VER"} ,
		{"word":"traumatisés","word_nosc":"traumatises","lemma":"traumatiser","pos":"VER"} ,
		{"word":"travailla","word_nosc":"travailla","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillai","word_nosc":"travaillai","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillaient","word_nosc":"travaillaient","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillais","word_nosc":"travaillais","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillait","word_nosc":"travaillait","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillant","word_nosc":"travaillant","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillassent","word_nosc":"travaillassent","lemma":"travailler","pos":"VER"} ,
		{"word":"travaille","word_nosc":"travaille","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillent","word_nosc":"travaillent","lemma":"travailler","pos":"VER"} ,
		{"word":"travailler","word_nosc":"travailler","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillera","word_nosc":"travaillera","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillerai","word_nosc":"travaillerai","lemma":"travailler","pos":"VER"} ,
		{"word":"travailleraient","word_nosc":"travailleraient","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillerais","word_nosc":"travaillerais","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillerait","word_nosc":"travaillerait","lemma":"travailler","pos":"VER"} ,
		{"word":"travailleras","word_nosc":"travailleras","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillerez","word_nosc":"travaillerez","lemma":"travailler","pos":"VER"} ,
		{"word":"travailleriez","word_nosc":"travailleriez","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillerions","word_nosc":"travaillerions","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillerons","word_nosc":"travaillerons","lemma":"travailler","pos":"VER"} ,
		{"word":"travailleront","word_nosc":"travailleront","lemma":"travailler","pos":"VER"} ,
		{"word":"travailles","word_nosc":"travailles","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillez","word_nosc":"travaillez","lemma":"travailler","pos":"VER"} ,
		{"word":"travailliez","word_nosc":"travailliez","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillions","word_nosc":"travaillions","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillons","word_nosc":"travaillons","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillâmes","word_nosc":"travaillames","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillât","word_nosc":"travaillat","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillèrent","word_nosc":"travaillerent","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillé","word_nosc":"travaille","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillée","word_nosc":"travaillee","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillées","word_nosc":"travaillees","lemma":"travailler","pos":"VER"} ,
		{"word":"travaillés","word_nosc":"travailles","lemma":"travailler","pos":"VER"} ,
		{"word":"traversa","word_nosc":"traversa","lemma":"traverser","pos":"VER"} ,
		{"word":"traversai","word_nosc":"traversai","lemma":"traverser","pos":"VER"} ,
		{"word":"traversaient","word_nosc":"traversaient","lemma":"traverser","pos":"VER"} ,
		{"word":"traversais","word_nosc":"traversais","lemma":"traverser","pos":"VER"} ,
		{"word":"traversait","word_nosc":"traversait","lemma":"traverser","pos":"VER"} ,
		{"word":"traversant","word_nosc":"traversant","lemma":"traverser","pos":"VER"} ,
		{"word":"traverse","word_nosc":"traverse","lemma":"traverser","pos":"VER"} ,
		{"word":"traversent","word_nosc":"traversent","lemma":"traverser","pos":"VER"} ,
		{"word":"traverser","word_nosc":"traverser","lemma":"traverser","pos":"VER"} ,
		{"word":"traversera","word_nosc":"traversera","lemma":"traverser","pos":"VER"} ,
		{"word":"traverserai","word_nosc":"traverserai","lemma":"traverser","pos":"VER"} ,
		{"word":"traverseraient","word_nosc":"traverseraient","lemma":"traverser","pos":"VER"} ,
		{"word":"traverserais","word_nosc":"traverserais","lemma":"traverser","pos":"VER"} ,
		{"word":"traverserait","word_nosc":"traverserait","lemma":"traverser","pos":"VER"} ,
		{"word":"traverseras","word_nosc":"traverseras","lemma":"traverser","pos":"VER"} ,
		{"word":"traverserez","word_nosc":"traverserez","lemma":"traverser","pos":"VER"} ,
		{"word":"traverserons","word_nosc":"traverserons","lemma":"traverser","pos":"VER"} ,
		{"word":"traverseront","word_nosc":"traverseront","lemma":"traverser","pos":"VER"} ,
		{"word":"traverses","word_nosc":"traverses","lemma":"traverser","pos":"VER"} ,
		{"word":"traversez","word_nosc":"traversez","lemma":"traverser","pos":"VER"} ,
		{"word":"traversiez","word_nosc":"traversiez","lemma":"traverser","pos":"VER"} ,
		{"word":"traversions","word_nosc":"traversions","lemma":"traverser","pos":"VER"} ,
		{"word":"traversons","word_nosc":"traversons","lemma":"traverser","pos":"VER"} ,
		{"word":"traversâmes","word_nosc":"traversames","lemma":"traverser","pos":"VER"} ,
		{"word":"traversèrent","word_nosc":"traverserent","lemma":"traverser","pos":"VER"} ,
		{"word":"traversé","word_nosc":"traverse","lemma":"traverser","pos":"VER"} ,
		{"word":"traversée","word_nosc":"traversee","lemma":"traverser","pos":"VER"} ,
		{"word":"traversées","word_nosc":"traversees","lemma":"traverser","pos":"VER"} ,
		{"word":"traversés","word_nosc":"traverses","lemma":"traverser","pos":"VER"} ,
		{"word":"travesti","word_nosc":"travesti","lemma":"travestir","pos":"VER"} ,
		{"word":"travestie","word_nosc":"travestie","lemma":"travestir","pos":"VER"} ,
		{"word":"travesties","word_nosc":"travesties","lemma":"travestir","pos":"VER"} ,
		{"word":"travestir","word_nosc":"travestir","lemma":"travestir","pos":"VER"} ,
		{"word":"travestis","word_nosc":"travestis","lemma":"travestir","pos":"VER"} ,
		{"word":"travestissais","word_nosc":"travestissais","lemma":"travestir","pos":"VER"} ,
		{"word":"travestissant","word_nosc":"travestissant","lemma":"travestir","pos":"VER"} ,
		{"word":"travestissez","word_nosc":"travestissez","lemma":"travestir","pos":"VER"} ,
		{"word":"travestit","word_nosc":"travestit","lemma":"travestir","pos":"VER"} ,
		{"word":"trayait","word_nosc":"trayait","lemma":"traire","pos":"VER"} ,
		{"word":"trayant","word_nosc":"trayant","lemma":"traire","pos":"VER"} ,
		{"word":"traça","word_nosc":"traca","lemma":"tracer","pos":"VER"} ,
		{"word":"traçai","word_nosc":"tracai","lemma":"tracer","pos":"VER"} ,
		{"word":"traçaient","word_nosc":"tracaient","lemma":"tracer","pos":"VER"} ,
		{"word":"traçais","word_nosc":"tracais","lemma":"tracer","pos":"VER"} ,
		{"word":"traçait","word_nosc":"tracait","lemma":"tracer","pos":"VER"} ,
		{"word":"traçant","word_nosc":"tracant","lemma":"tracer","pos":"VER"} ,
		{"word":"traçons","word_nosc":"tracons","lemma":"tracer","pos":"VER"} ,
		{"word":"traçât","word_nosc":"tracat","lemma":"tracer","pos":"VER"} ,
		{"word":"traîna","word_nosc":"traina","lemma":"traîner","pos":"VER"} ,
		{"word":"traînai","word_nosc":"trainai","lemma":"traîner","pos":"VER"} ,
		{"word":"traînaient","word_nosc":"trainaient","lemma":"traîner","pos":"VER"} ,
		{"word":"traînaillait","word_nosc":"trainaillait","lemma":"traînailler","pos":"VER"} ,
		{"word":"traînaillant","word_nosc":"trainaillant","lemma":"traînailler","pos":"VER"} ,
		{"word":"traînaille","word_nosc":"trainaille","lemma":"traînailler","pos":"VER"} ,
		{"word":"traînailler","word_nosc":"trainailler","lemma":"traînailler","pos":"VER"} ,
		{"word":"traînaillons","word_nosc":"trainaillons","lemma":"traînailler","pos":"VER"} ,
		{"word":"traînaillé","word_nosc":"trainaille","lemma":"traînailler","pos":"VER"} ,
		{"word":"traînais","word_nosc":"trainais","lemma":"traîner","pos":"VER"} ,
		{"word":"traînait","word_nosc":"trainait","lemma":"traîner","pos":"VER"} ,
		{"word":"traînant","word_nosc":"trainant","lemma":"traîner","pos":"VER"} ,
		{"word":"traînassaient","word_nosc":"trainassaient","lemma":"traînasser","pos":"VER"} ,
		{"word":"traînassais","word_nosc":"trainassais","lemma":"traînasser","pos":"VER"} ,
		{"word":"traînassait","word_nosc":"trainassait","lemma":"traînasser","pos":"VER"} ,
		{"word":"traînassant","word_nosc":"trainassant","lemma":"traînasser","pos":"VER"} ,
		{"word":"traînasse","word_nosc":"trainasse","lemma":"traînasser","pos":"VER"} ,
		{"word":"traînassent","word_nosc":"trainassent","lemma":"traînasser","pos":"VER"} ,
		{"word":"traînasser","word_nosc":"trainasser","lemma":"traînasser","pos":"VER"} ,
		{"word":"traînasserai","word_nosc":"trainasserai","lemma":"traînasser","pos":"VER"} ,
		{"word":"traînasses","word_nosc":"trainasses","lemma":"traînasser","pos":"VER"} ,
		{"word":"traînassez","word_nosc":"trainassez","lemma":"traînasser","pos":"VER"} ,
		{"word":"traînassons","word_nosc":"trainassons","lemma":"traînasser","pos":"VER"} ,
		{"word":"traînassé","word_nosc":"trainasse","lemma":"traînasser","pos":"VER"} ,
		{"word":"traîne","word_nosc":"traine","lemma":"traîner","pos":"VER"} ,
		{"word":"traînent","word_nosc":"trainent","lemma":"traîner","pos":"VER"} ,
		{"word":"traîner","word_nosc":"trainer","lemma":"traîner","pos":"VER"} ,
		{"word":"traînera","word_nosc":"trainera","lemma":"traîner","pos":"VER"} ,
		{"word":"traînerai","word_nosc":"trainerai","lemma":"traîner","pos":"VER"} ,
		{"word":"traîneraient","word_nosc":"traineraient","lemma":"traîner","pos":"VER"} ,
		{"word":"traînerais","word_nosc":"trainerais","lemma":"traîner","pos":"VER"} ,
		{"word":"traînerait","word_nosc":"trainerait","lemma":"traîner","pos":"VER"} ,
		{"word":"traîneras","word_nosc":"traineras","lemma":"traîner","pos":"VER"} ,
		{"word":"traînerez","word_nosc":"trainerez","lemma":"traîner","pos":"VER"} ,
		{"word":"traînerons","word_nosc":"trainerons","lemma":"traîner","pos":"VER"} ,
		{"word":"traîneront","word_nosc":"traineront","lemma":"traîner","pos":"VER"} ,
		{"word":"traînes","word_nosc":"traines","lemma":"traîner","pos":"VER"} ,
		{"word":"traînez","word_nosc":"trainez","lemma":"traîner","pos":"VER"} ,
		{"word":"traîniez","word_nosc":"trainiez","lemma":"traîner","pos":"VER"} ,
		{"word":"traînions","word_nosc":"trainions","lemma":"traîner","pos":"VER"} ,
		{"word":"traînons","word_nosc":"trainons","lemma":"traîner","pos":"VER"} ,
		{"word":"traînâmes","word_nosc":"trainames","lemma":"traîner","pos":"VER"} ,
		{"word":"traînât","word_nosc":"trainat","lemma":"traîner","pos":"VER"} ,
		{"word":"traînèrent","word_nosc":"trainerent","lemma":"traîner","pos":"VER"} ,
		{"word":"traîné","word_nosc":"traine","lemma":"traîner","pos":"VER"} ,
		{"word":"traînée","word_nosc":"trainee","lemma":"traîner","pos":"VER"} ,
		{"word":"traînées","word_nosc":"trainees","lemma":"traîner","pos":"VER"} ,
		{"word":"traînés","word_nosc":"traines","lemma":"traîner","pos":"VER"} ,
		{"word":"treillissé","word_nosc":"treillisse","lemma":"treillisser","pos":"VER"} ,
		{"word":"treillissée","word_nosc":"treillissee","lemma":"treillisser","pos":"VER"} ,
		{"word":"trembla","word_nosc":"trembla","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblai","word_nosc":"tremblai","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblaient","word_nosc":"tremblaient","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblais","word_nosc":"tremblais","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblait","word_nosc":"tremblait","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblant","word_nosc":"tremblant","lemma":"trembler","pos":"VER"} ,
		{"word":"tremble","word_nosc":"tremble","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblent","word_nosc":"tremblent","lemma":"trembler","pos":"VER"} ,
		{"word":"trembler","word_nosc":"trembler","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblera","word_nosc":"tremblera","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblerai","word_nosc":"tremblerai","lemma":"trembler","pos":"VER"} ,
		{"word":"trembleraient","word_nosc":"trembleraient","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblerais","word_nosc":"tremblerais","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblerait","word_nosc":"tremblerait","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblerons","word_nosc":"tremblerons","lemma":"trembler","pos":"VER"} ,
		{"word":"trembleront","word_nosc":"trembleront","lemma":"trembler","pos":"VER"} ,
		{"word":"trembles","word_nosc":"trembles","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblez","word_nosc":"tremblez","lemma":"trembler","pos":"VER"} ,
		{"word":"trembliez","word_nosc":"trembliez","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblions","word_nosc":"tremblions","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblochant","word_nosc":"tremblochant","lemma":"tremblocher","pos":"VER"} ,
		{"word":"tremblons","word_nosc":"tremblons","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblota","word_nosc":"tremblota","lemma":"trembloter","pos":"VER"} ,
		{"word":"tremblotaient","word_nosc":"tremblotaient","lemma":"trembloter","pos":"VER"} ,
		{"word":"tremblotait","word_nosc":"tremblotait","lemma":"trembloter","pos":"VER"} ,
		{"word":"tremblotant","word_nosc":"tremblotant","lemma":"trembloter","pos":"VER"} ,
		{"word":"tremblote","word_nosc":"tremblote","lemma":"trembloter","pos":"VER"} ,
		{"word":"tremblotent","word_nosc":"tremblotent","lemma":"trembloter","pos":"VER"} ,
		{"word":"trembloter","word_nosc":"trembloter","lemma":"trembloter","pos":"VER"} ,
		{"word":"trembloté","word_nosc":"tremblote","lemma":"trembloter","pos":"VER"} ,
		{"word":"tremblât","word_nosc":"tremblat","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblèrent","word_nosc":"tremblerent","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblé","word_nosc":"tremble","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblée","word_nosc":"tremblee","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblées","word_nosc":"tremblees","lemma":"trembler","pos":"VER"} ,
		{"word":"tremblés","word_nosc":"trembles","lemma":"trembler","pos":"VER"} ,
		{"word":"trempa","word_nosc":"trempa","lemma":"tremper","pos":"VER"} ,
		{"word":"trempai","word_nosc":"trempai","lemma":"tremper","pos":"VER"} ,
		{"word":"trempaient","word_nosc":"trempaient","lemma":"tremper","pos":"VER"} ,
		{"word":"trempais","word_nosc":"trempais","lemma":"tremper","pos":"VER"} ,
		{"word":"trempait","word_nosc":"trempait","lemma":"tremper","pos":"VER"} ,
		{"word":"trempant","word_nosc":"trempant","lemma":"tremper","pos":"VER"} ,
		{"word":"trempe","word_nosc":"trempe","lemma":"tremper","pos":"VER"} ,
		{"word":"trempent","word_nosc":"trempent","lemma":"tremper","pos":"VER"} ,
		{"word":"tremper","word_nosc":"tremper","lemma":"tremper","pos":"VER"} ,
		{"word":"trempera","word_nosc":"trempera","lemma":"tremper","pos":"VER"} ,
		{"word":"tremperaient","word_nosc":"tremperaient","lemma":"tremper","pos":"VER"} ,
		{"word":"tremperais","word_nosc":"tremperais","lemma":"tremper","pos":"VER"} ,
		{"word":"tremperait","word_nosc":"tremperait","lemma":"tremper","pos":"VER"} ,
		{"word":"trempes","word_nosc":"trempes","lemma":"tremper","pos":"VER"} ,
		{"word":"trempez","word_nosc":"trempez","lemma":"tremper","pos":"VER"} ,
		{"word":"trempions","word_nosc":"trempions","lemma":"tremper","pos":"VER"} ,
		{"word":"trempons","word_nosc":"trempons","lemma":"tremper","pos":"VER"} ,
		{"word":"trempèrent","word_nosc":"tremperent","lemma":"tremper","pos":"VER"} ,
		{"word":"trempé","word_nosc":"trempe","lemma":"tremper","pos":"VER"} ,
		{"word":"trempée","word_nosc":"trempee","lemma":"tremper","pos":"VER"} ,
		{"word":"trempées","word_nosc":"trempees","lemma":"tremper","pos":"VER"} ,
		{"word":"trempés","word_nosc":"trempes","lemma":"tremper","pos":"VER"} ,
		{"word":"tressa","word_nosc":"tressa","lemma":"tresser","pos":"VER"} ,
		{"word":"tressaient","word_nosc":"tressaient","lemma":"tresser","pos":"VER"} ,
		{"word":"tressaillaient","word_nosc":"tressaillaient","lemma":"tressaillir","pos":"VER"} ,
		{"word":"tressaillais","word_nosc":"tressaillais","lemma":"tressaillir","pos":"VER"} ,
		{"word":"tressaillait","word_nosc":"tressaillait","lemma":"tressaillir","pos":"VER"} ,
		{"word":"tressaillant","word_nosc":"tressaillant","lemma":"tressaillir","pos":"VER"} ,
		{"word":"tressaille","word_nosc":"tressaille","lemma":"tressaillir","pos":"VER"} ,
		{"word":"tressaillent","word_nosc":"tressaillent","lemma":"tressaillir","pos":"VER"} ,
		{"word":"tressaillez","word_nosc":"tressaillez","lemma":"tressaillir","pos":"VER"} ,
		{"word":"tressailli","word_nosc":"tressailli","lemma":"tressaillir","pos":"VER"} ,
		{"word":"tressaillir","word_nosc":"tressaillir","lemma":"tressaillir","pos":"VER"} ,
		{"word":"tressaillira","word_nosc":"tressaillira","lemma":"tressaillir","pos":"VER"} ,
		{"word":"tressaillirait","word_nosc":"tressaillirait","lemma":"tressaillir","pos":"VER"} ,
		{"word":"tressaillirent","word_nosc":"tressaillirent","lemma":"tressaillir","pos":"VER"} ,
		{"word":"tressaillis","word_nosc":"tressaillis","lemma":"tressaillir","pos":"VER"} ,
		{"word":"tressaillit","word_nosc":"tressaillit","lemma":"tressaillir","pos":"VER"} ,
		{"word":"tressait","word_nosc":"tressait","lemma":"tresser","pos":"VER"} ,
		{"word":"tressant","word_nosc":"tressant","lemma":"tresser","pos":"VER"} ,
		{"word":"tressauta","word_nosc":"tressauta","lemma":"tressauter","pos":"VER"} ,
		{"word":"tressautaient","word_nosc":"tressautaient","lemma":"tressauter","pos":"VER"} ,
		{"word":"tressautait","word_nosc":"tressautait","lemma":"tressauter","pos":"VER"} ,
		{"word":"tressautant","word_nosc":"tressautant","lemma":"tressauter","pos":"VER"} ,
		{"word":"tressaute","word_nosc":"tressaute","lemma":"tressauter","pos":"VER"} ,
		{"word":"tressautent","word_nosc":"tressautent","lemma":"tressauter","pos":"VER"} ,
		{"word":"tressauter","word_nosc":"tressauter","lemma":"tressauter","pos":"VER"} ,
		{"word":"tressautera","word_nosc":"tressautera","lemma":"tressauter","pos":"VER"} ,
		{"word":"tressautés","word_nosc":"tressautes","lemma":"tressauter","pos":"VER"} ,
		{"word":"tresse","word_nosc":"tresse","lemma":"tresser","pos":"VER"} ,
		{"word":"tressent","word_nosc":"tressent","lemma":"tresser","pos":"VER"} ,
		{"word":"tresser","word_nosc":"tresser","lemma":"tresser","pos":"VER"} ,
		{"word":"tresseraient","word_nosc":"tresseraient","lemma":"tresser","pos":"VER"} ,
		{"word":"tresseras","word_nosc":"tresseras","lemma":"tresser","pos":"VER"} ,
		{"word":"tressons","word_nosc":"tressons","lemma":"tresser","pos":"VER"} ,
		{"word":"tressèrent","word_nosc":"tresserent","lemma":"tresser","pos":"VER"} ,
		{"word":"tressé","word_nosc":"tresse","lemma":"tresser","pos":"VER"} ,
		{"word":"tressée","word_nosc":"tressee","lemma":"tresser","pos":"VER"} ,
		{"word":"tressées","word_nosc":"tressees","lemma":"tresser","pos":"VER"} ,
		{"word":"tressés","word_nosc":"tresses","lemma":"tresser","pos":"VER"} ,
		{"word":"treuiller","word_nosc":"treuiller","lemma":"treuiller","pos":"VER"} ,
		{"word":"tria","word_nosc":"tria","lemma":"trier","pos":"VER"} ,
		{"word":"triaient","word_nosc":"triaient","lemma":"trier","pos":"VER"} ,
		{"word":"triais","word_nosc":"triais","lemma":"trier","pos":"VER"} ,
		{"word":"triait","word_nosc":"triait","lemma":"trier","pos":"VER"} ,
		{"word":"trianguler","word_nosc":"trianguler","lemma":"trianguler","pos":"VER"} ,
		{"word":"triangulez","word_nosc":"triangulez","lemma":"trianguler","pos":"VER"} ,
		{"word":"triant","word_nosc":"triant","lemma":"trier","pos":"VER"} ,
		{"word":"tricha","word_nosc":"tricha","lemma":"tricher","pos":"VER"} ,
		{"word":"trichaient","word_nosc":"trichaient","lemma":"tricher","pos":"VER"} ,
		{"word":"trichais","word_nosc":"trichais","lemma":"tricher","pos":"VER"} ,
		{"word":"trichait","word_nosc":"trichait","lemma":"tricher","pos":"VER"} ,
		{"word":"trichant","word_nosc":"trichant","lemma":"tricher","pos":"VER"} ,
		{"word":"triche","word_nosc":"triche","lemma":"tricher","pos":"VER"} ,
		{"word":"trichent","word_nosc":"trichent","lemma":"tricher","pos":"VER"} ,
		{"word":"tricher","word_nosc":"tricher","lemma":"tricher","pos":"VER"} ,
		{"word":"trichera","word_nosc":"trichera","lemma":"tricher","pos":"VER"} ,
		{"word":"tricherai","word_nosc":"tricherai","lemma":"tricher","pos":"VER"} ,
		{"word":"tricherais","word_nosc":"tricherais","lemma":"tricher","pos":"VER"} ,
		{"word":"tricherait","word_nosc":"tricherait","lemma":"tricher","pos":"VER"} ,
		{"word":"tricheront","word_nosc":"tricheront","lemma":"tricher","pos":"VER"} ,
		{"word":"triches","word_nosc":"triches","lemma":"tricher","pos":"VER"} ,
		{"word":"trichez","word_nosc":"trichez","lemma":"tricher","pos":"VER"} ,
		{"word":"trichiez","word_nosc":"trichiez","lemma":"tricher","pos":"VER"} ,
		{"word":"trichons","word_nosc":"trichons","lemma":"tricher","pos":"VER"} ,
		{"word":"triché","word_nosc":"triche","lemma":"tricher","pos":"VER"} ,
		{"word":"trichée","word_nosc":"trichee","lemma":"tricher","pos":"VER"} ,
		{"word":"trichées","word_nosc":"trichees","lemma":"tricher","pos":"VER"} ,
		{"word":"tricota","word_nosc":"tricota","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricotai","word_nosc":"tricotai","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricotaient","word_nosc":"tricotaient","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricotais","word_nosc":"tricotais","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricotait","word_nosc":"tricotait","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricotant","word_nosc":"tricotant","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricote","word_nosc":"tricote","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricotent","word_nosc":"tricotent","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricoter","word_nosc":"tricoter","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricotera","word_nosc":"tricotera","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricoterai","word_nosc":"tricoterai","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricoterait","word_nosc":"tricoterait","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricoteras","word_nosc":"tricoteras","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricoterons","word_nosc":"tricoterons","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricotez","word_nosc":"tricotez","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricoté","word_nosc":"tricote","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricotée","word_nosc":"tricotee","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricotées","word_nosc":"tricotees","lemma":"tricoter","pos":"VER"} ,
		{"word":"tricotés","word_nosc":"tricotes","lemma":"tricoter","pos":"VER"} ,
		{"word":"trie","word_nosc":"trie","lemma":"trier","pos":"VER"} ,
		{"word":"trient","word_nosc":"trient","lemma":"trier","pos":"VER"} ,
		{"word":"trier","word_nosc":"trier","lemma":"trier","pos":"VER"} ,
		{"word":"triera","word_nosc":"triera","lemma":"trier","pos":"VER"} ,
		{"word":"trierait","word_nosc":"trierait","lemma":"trier","pos":"VER"} ,
		{"word":"triez","word_nosc":"triez","lemma":"trier","pos":"VER"} ,
		{"word":"trifouillaient","word_nosc":"trifouillaient","lemma":"trifouiller","pos":"VER"} ,
		{"word":"trifouillait","word_nosc":"trifouillait","lemma":"trifouiller","pos":"VER"} ,
		{"word":"trifouille","word_nosc":"trifouille","lemma":"trifouiller","pos":"VER"} ,
		{"word":"trifouiller","word_nosc":"trifouiller","lemma":"trifouiller","pos":"VER"} ,
		{"word":"trifouillez","word_nosc":"trifouillez","lemma":"trifouiller","pos":"VER"} ,
		{"word":"trifouillé","word_nosc":"trifouille","lemma":"trifouiller","pos":"VER"} ,
		{"word":"trillant","word_nosc":"trillant","lemma":"triller","pos":"VER"} ,
		{"word":"trillions","word_nosc":"trillions","lemma":"triller","pos":"VER"} ,
		{"word":"trillé","word_nosc":"trille","lemma":"triller","pos":"VER"} ,
		{"word":"trimait","word_nosc":"trimait","lemma":"trimer","pos":"VER"} ,
		{"word":"trimant","word_nosc":"trimant","lemma":"trimer","pos":"VER"} ,
		{"word":"trimarde","word_nosc":"trimarde","lemma":"trimarder","pos":"VER"} ,
		{"word":"trimarder","word_nosc":"trimarder","lemma":"trimarder","pos":"VER"} ,
		{"word":"trimbala","word_nosc":"trimbala","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimbalaient","word_nosc":"trimbalaient","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimbalais","word_nosc":"trimbalais","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimbalait","word_nosc":"trimbalait","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimbalant","word_nosc":"trimbalant","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimbale","word_nosc":"trimbale","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimbalent","word_nosc":"trimbalent","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimbaler","word_nosc":"trimbaler","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimbalerai","word_nosc":"trimbalerai","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimbalerait","word_nosc":"trimbalerait","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimbalerez","word_nosc":"trimbalerez","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimbales","word_nosc":"trimbales","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimbalez","word_nosc":"trimbalez","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimballaient","word_nosc":"trimballaient","lemma":"trimballer","pos":"VER"} ,
		{"word":"trimballais","word_nosc":"trimballais","lemma":"trimballer","pos":"VER"} ,
		{"word":"trimballait","word_nosc":"trimballait","lemma":"trimballer","pos":"VER"} ,
		{"word":"trimballe","word_nosc":"trimballe","lemma":"trimballer","pos":"VER"} ,
		{"word":"trimballent","word_nosc":"trimballent","lemma":"trimballer","pos":"VER"} ,
		{"word":"trimballer","word_nosc":"trimballer","lemma":"trimballer","pos":"VER"} ,
		{"word":"trimballera","word_nosc":"trimballera","lemma":"trimballer","pos":"VER"} ,
		{"word":"trimballes","word_nosc":"trimballes","lemma":"trimballer","pos":"VER"} ,
		{"word":"trimballez","word_nosc":"trimballez","lemma":"trimballer","pos":"VER"} ,
		{"word":"trimballât","word_nosc":"trimballat","lemma":"trimballer","pos":"VER"} ,
		{"word":"trimballé","word_nosc":"trimballe","lemma":"trimballer","pos":"VER"} ,
		{"word":"trimballée","word_nosc":"trimballee","lemma":"trimballer","pos":"VER"} ,
		{"word":"trimballés","word_nosc":"trimballes","lemma":"trimballer","pos":"VER"} ,
		{"word":"trimbalons","word_nosc":"trimbalons","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimbalé","word_nosc":"trimbale","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimbalée","word_nosc":"trimbalee","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trimbalés","word_nosc":"trimbales","lemma":"trimbaler","pos":"VER"} ,
		{"word":"trime","word_nosc":"trime","lemma":"trimer","pos":"VER"} ,
		{"word":"triment","word_nosc":"triment","lemma":"trimer","pos":"VER"} ,
		{"word":"trimer","word_nosc":"trimer","lemma":"trimer","pos":"VER"} ,
		{"word":"trimes","word_nosc":"trimes","lemma":"trimer","pos":"VER"} ,
		{"word":"trimons","word_nosc":"trimons","lemma":"trimer","pos":"VER"} ,
		{"word":"trimé","word_nosc":"trime","lemma":"trimer","pos":"VER"} ,
		{"word":"tringlaient","word_nosc":"tringlaient","lemma":"tringler","pos":"VER"} ,
		{"word":"tringlais","word_nosc":"tringlais","lemma":"tringler","pos":"VER"} ,
		{"word":"tringlait","word_nosc":"tringlait","lemma":"tringler","pos":"VER"} ,
		{"word":"tringle","word_nosc":"tringle","lemma":"tringler","pos":"VER"} ,
		{"word":"tringler","word_nosc":"tringler","lemma":"tringler","pos":"VER"} ,
		{"word":"tringlerais","word_nosc":"tringlerais","lemma":"tringler","pos":"VER"} ,
		{"word":"tringles","word_nosc":"tringles","lemma":"tringler","pos":"VER"} ,
		{"word":"tringlé","word_nosc":"tringle","lemma":"tringler","pos":"VER"} ,
		{"word":"tringlée","word_nosc":"tringlee","lemma":"tringler","pos":"VER"} ,
		{"word":"trinqua","word_nosc":"trinqua","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquaient","word_nosc":"trinquaient","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquais","word_nosc":"trinquais","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquait","word_nosc":"trinquait","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquant","word_nosc":"trinquant","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinque","word_nosc":"trinque","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquent","word_nosc":"trinquent","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquer","word_nosc":"trinquer","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquera","word_nosc":"trinquera","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquerai","word_nosc":"trinquerai","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquerais","word_nosc":"trinquerais","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquerait","word_nosc":"trinquerait","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinqueras","word_nosc":"trinqueras","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquerez","word_nosc":"trinquerez","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquerons","word_nosc":"trinquerons","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinques","word_nosc":"trinques","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquez","word_nosc":"trinquez","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquions","word_nosc":"trinquions","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquons","word_nosc":"trinquons","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquâmes","word_nosc":"trinquames","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinquèrent","word_nosc":"trinquerent","lemma":"trinquer","pos":"VER"} ,
		{"word":"trinqué","word_nosc":"trinque","lemma":"trinquer","pos":"VER"} ,
		{"word":"triompha","word_nosc":"triompha","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphai","word_nosc":"triomphai","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphaient","word_nosc":"triomphaient","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphais","word_nosc":"triomphais","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphait","word_nosc":"triomphait","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphant","word_nosc":"triomphant","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphe","word_nosc":"triomphe","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphent","word_nosc":"triomphent","lemma":"triompher","pos":"VER"} ,
		{"word":"triompher","word_nosc":"triompher","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphera","word_nosc":"triomphera","lemma":"triompher","pos":"VER"} ,
		{"word":"triompherai","word_nosc":"triompherai","lemma":"triompher","pos":"VER"} ,
		{"word":"triompherais","word_nosc":"triompherais","lemma":"triompher","pos":"VER"} ,
		{"word":"triompherait","word_nosc":"triompherait","lemma":"triompher","pos":"VER"} ,
		{"word":"triompheras","word_nosc":"triompheras","lemma":"triompher","pos":"VER"} ,
		{"word":"triompherez","word_nosc":"triompherez","lemma":"triompher","pos":"VER"} ,
		{"word":"triompherions","word_nosc":"triompherions","lemma":"triompher","pos":"VER"} ,
		{"word":"triompherons","word_nosc":"triompherons","lemma":"triompher","pos":"VER"} ,
		{"word":"triompheront","word_nosc":"triompheront","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphes","word_nosc":"triomphes","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphez","word_nosc":"triomphez","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphions","word_nosc":"triomphions","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphons","word_nosc":"triomphons","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphâmes","word_nosc":"triomphames","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphât","word_nosc":"triomphat","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphèrent","word_nosc":"triompherent","lemma":"triompher","pos":"VER"} ,
		{"word":"triomphé","word_nosc":"triomphe","lemma":"triompher","pos":"VER"} ,
		{"word":"tripatouillant","word_nosc":"tripatouillant","lemma":"tripatouiller","pos":"VER"} ,
		{"word":"tripatouillent","word_nosc":"tripatouillent","lemma":"tripatouiller","pos":"VER"} ,
		{"word":"tripatouiller","word_nosc":"tripatouiller","lemma":"tripatouiller","pos":"VER"} ,
		{"word":"tripatouillez","word_nosc":"tripatouillez","lemma":"tripatouiller","pos":"VER"} ,
		{"word":"tripatouillé","word_nosc":"tripatouille","lemma":"tripatouiller","pos":"VER"} ,
		{"word":"tripatouillée","word_nosc":"tripatouillee","lemma":"tripatouiller","pos":"VER"} ,
		{"word":"triper","word_nosc":"triper","lemma":"triper","pos":"VER"} ,
		{"word":"tripla","word_nosc":"tripla","lemma":"tripler","pos":"VER"} ,
		{"word":"triplai","word_nosc":"triplai","lemma":"tripler","pos":"VER"} ,
		{"word":"triplaient","word_nosc":"triplaient","lemma":"tripler","pos":"VER"} ,
		{"word":"triplait","word_nosc":"triplait","lemma":"tripler","pos":"VER"} ,
		{"word":"triplant","word_nosc":"triplant","lemma":"tripler","pos":"VER"} ,
		{"word":"triple","word_nosc":"triple","lemma":"tripler","pos":"VER"} ,
		{"word":"triplent","word_nosc":"triplent","lemma":"tripler","pos":"VER"} ,
		{"word":"tripler","word_nosc":"tripler","lemma":"tripler","pos":"VER"} ,
		{"word":"triplerais","word_nosc":"triplerais","lemma":"tripler","pos":"VER"} ,
		{"word":"triplerait","word_nosc":"triplerait","lemma":"tripler","pos":"VER"} ,
		{"word":"triplions","word_nosc":"triplions","lemma":"tripler","pos":"VER"} ,
		{"word":"triplons","word_nosc":"triplons","lemma":"tripler","pos":"VER"} ,
		{"word":"triplèrent","word_nosc":"triplerent","lemma":"tripler","pos":"VER"} ,
		{"word":"triplé","word_nosc":"triple","lemma":"tripler","pos":"VER"} ,
		{"word":"triplée","word_nosc":"triplee","lemma":"tripler","pos":"VER"} ,
		{"word":"tripota","word_nosc":"tripota","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripotaient","word_nosc":"tripotaient","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripotais","word_nosc":"tripotais","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripotait","word_nosc":"tripotait","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripotant","word_nosc":"tripotant","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripote","word_nosc":"tripote","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripotent","word_nosc":"tripotent","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripoter","word_nosc":"tripoter","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripoterai","word_nosc":"tripoterai","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripotes","word_nosc":"tripotes","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripotez","word_nosc":"tripotez","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripotiez","word_nosc":"tripotiez","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripotèrent","word_nosc":"tripoterent","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripoté","word_nosc":"tripote","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripotée","word_nosc":"tripotee","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripotées","word_nosc":"tripotees","lemma":"tripoter","pos":"VER"} ,
		{"word":"tripotés","word_nosc":"tripotes","lemma":"tripoter","pos":"VER"} ,
		{"word":"triquais","word_nosc":"triquais","lemma":"triquer","pos":"VER"} ,
		{"word":"triquait","word_nosc":"triquait","lemma":"triquer","pos":"VER"} ,
		{"word":"trique","word_nosc":"trique","lemma":"triquer","pos":"VER"} ,
		{"word":"triquer","word_nosc":"triquer","lemma":"triquer","pos":"VER"} ,
		{"word":"trissait","word_nosc":"trissait","lemma":"trisser","pos":"VER"} ,
		{"word":"trisse","word_nosc":"trisse","lemma":"trisser","pos":"VER"} ,
		{"word":"trissent","word_nosc":"trissent","lemma":"trisser","pos":"VER"} ,
		{"word":"trisser","word_nosc":"trisser","lemma":"trisser","pos":"VER"} ,
		{"word":"trissés","word_nosc":"trisses","lemma":"trisser","pos":"VER"} ,
		{"word":"tritura","word_nosc":"tritura","lemma":"triturer","pos":"VER"} ,
		{"word":"trituraient","word_nosc":"trituraient","lemma":"triturer","pos":"VER"} ,
		{"word":"triturais","word_nosc":"triturais","lemma":"triturer","pos":"VER"} ,
		{"word":"triturait","word_nosc":"triturait","lemma":"triturer","pos":"VER"} ,
		{"word":"triturant","word_nosc":"triturant","lemma":"triturer","pos":"VER"} ,
		{"word":"triture","word_nosc":"triture","lemma":"triturer","pos":"VER"} ,
		{"word":"triturent","word_nosc":"triturent","lemma":"triturer","pos":"VER"} ,
		{"word":"triturer","word_nosc":"triturer","lemma":"triturer","pos":"VER"} ,
		{"word":"triturera","word_nosc":"triturera","lemma":"triturer","pos":"VER"} ,
		{"word":"triturons","word_nosc":"triturons","lemma":"triturer","pos":"VER"} ,
		{"word":"trituré","word_nosc":"triture","lemma":"triturer","pos":"VER"} ,
		{"word":"triturée","word_nosc":"trituree","lemma":"triturer","pos":"VER"} ,
		{"word":"triturées","word_nosc":"triturees","lemma":"triturer","pos":"VER"} ,
		{"word":"triturés","word_nosc":"tritures","lemma":"triturer","pos":"VER"} ,
		{"word":"trivialiser","word_nosc":"trivialiser","lemma":"trivialiser","pos":"VER"} ,
		{"word":"trièrent","word_nosc":"trierent","lemma":"trier","pos":"VER"} ,
		{"word":"trié","word_nosc":"trie","lemma":"trier","pos":"VER"} ,
		{"word":"triée","word_nosc":"triee","lemma":"trier","pos":"VER"} ,
		{"word":"triées","word_nosc":"triees","lemma":"trier","pos":"VER"} ,
		{"word":"triés","word_nosc":"tries","lemma":"trier","pos":"VER"} ,
		{"word":"trompa","word_nosc":"trompa","lemma":"tromper","pos":"VER"} ,
		{"word":"trompai","word_nosc":"trompai","lemma":"tromper","pos":"VER"} ,
		{"word":"trompaient","word_nosc":"trompaient","lemma":"tromper","pos":"VER"} ,
		{"word":"trompais","word_nosc":"trompais","lemma":"tromper","pos":"VER"} ,
		{"word":"trompait","word_nosc":"trompait","lemma":"tromper","pos":"VER"} ,
		{"word":"trompant","word_nosc":"trompant","lemma":"tromper","pos":"VER"} ,
		{"word":"trompe","word_nosc":"trompe","lemma":"tromper","pos":"VER"} ,
		{"word":"trompent","word_nosc":"trompent","lemma":"tromper","pos":"VER"} ,
		{"word":"tromper","word_nosc":"tromper","lemma":"tromper","pos":"VER"} ,
		{"word":"trompera","word_nosc":"trompera","lemma":"tromper","pos":"VER"} ,
		{"word":"tromperai","word_nosc":"tromperai","lemma":"tromper","pos":"VER"} ,
		{"word":"tromperaient","word_nosc":"tromperaient","lemma":"tromper","pos":"VER"} ,
		{"word":"tromperais","word_nosc":"tromperais","lemma":"tromper","pos":"VER"} ,
		{"word":"tromperait","word_nosc":"tromperait","lemma":"tromper","pos":"VER"} ,
		{"word":"tromperas","word_nosc":"tromperas","lemma":"tromper","pos":"VER"} ,
		{"word":"tromperez","word_nosc":"tromperez","lemma":"tromper","pos":"VER"} ,
		{"word":"tromperiez","word_nosc":"tromperiez","lemma":"tromper","pos":"VER"} ,
		{"word":"tromperons","word_nosc":"tromperons","lemma":"tromper","pos":"VER"} ,
		{"word":"tromperont","word_nosc":"tromperont","lemma":"tromper","pos":"VER"} ,
		{"word":"trompes","word_nosc":"trompes","lemma":"tromper","pos":"VER"} ,
		{"word":"trompeter","word_nosc":"trompeter","lemma":"trompeter","pos":"VER"} ,
		{"word":"trompez","word_nosc":"trompez","lemma":"tromper","pos":"VER"} ,
		{"word":"trompiez","word_nosc":"trompiez","lemma":"tromper","pos":"VER"} ,
		{"word":"trompions","word_nosc":"trompions","lemma":"tromper","pos":"VER"} ,
		{"word":"trompons","word_nosc":"trompons","lemma":"tromper","pos":"VER"} ,
		{"word":"trompâmes","word_nosc":"trompames","lemma":"tromper","pos":"VER"} ,
		{"word":"trompât","word_nosc":"trompat","lemma":"tromper","pos":"VER"} ,
		{"word":"trompèrent","word_nosc":"tromperent","lemma":"tromper","pos":"VER"} ,
		{"word":"trompé","word_nosc":"trompe","lemma":"tromper","pos":"VER"} ,
		{"word":"trompée","word_nosc":"trompee","lemma":"tromper","pos":"VER"} ,
		{"word":"trompées","word_nosc":"trompees","lemma":"tromper","pos":"VER"} ,
		{"word":"trompés","word_nosc":"trompes","lemma":"tromper","pos":"VER"} ,
		{"word":"tronchais","word_nosc":"tronchais","lemma":"troncher","pos":"VER"} ,
		{"word":"troncher","word_nosc":"troncher","lemma":"troncher","pos":"VER"} ,
		{"word":"tronché","word_nosc":"tronche","lemma":"troncher","pos":"VER"} ,
		{"word":"tronchée","word_nosc":"tronchee","lemma":"troncher","pos":"VER"} ,
		{"word":"tronquer","word_nosc":"tronquer","lemma":"tronquer","pos":"VER"} ,
		{"word":"tronqué","word_nosc":"tronque","lemma":"tronquer","pos":"VER"} ,
		{"word":"tronquée","word_nosc":"tronquee","lemma":"tronquer","pos":"VER"} ,
		{"word":"tronquées","word_nosc":"tronquees","lemma":"tronquer","pos":"VER"} ,
		{"word":"tronçonnaient","word_nosc":"tronconnaient","lemma":"tronçonner","pos":"VER"} ,
		{"word":"tronçonnait","word_nosc":"tronconnait","lemma":"tronçonner","pos":"VER"} ,
		{"word":"tronçonne","word_nosc":"tronconne","lemma":"tronçonner","pos":"VER"} ,
		{"word":"tronçonner","word_nosc":"tronconner","lemma":"tronçonner","pos":"VER"} ,
		{"word":"tronçonné","word_nosc":"tronconne","lemma":"tronçonner","pos":"VER"} ,
		{"word":"tronçonnée","word_nosc":"tronconnee","lemma":"tronçonner","pos":"VER"} ,
		{"word":"tronçonnées","word_nosc":"tronconnees","lemma":"tronçonner","pos":"VER"} ,
		{"word":"tronçonnés","word_nosc":"tronconnes","lemma":"tronçonner","pos":"VER"} ,
		{"word":"troqua","word_nosc":"troqua","lemma":"troquer","pos":"VER"} ,
		{"word":"troquaient","word_nosc":"troquaient","lemma":"troquer","pos":"VER"} ,
		{"word":"troquais","word_nosc":"troquais","lemma":"troquer","pos":"VER"} ,
		{"word":"troquait","word_nosc":"troquait","lemma":"troquer","pos":"VER"} ,
		{"word":"troquant","word_nosc":"troquant","lemma":"troquer","pos":"VER"} ,
		{"word":"troquas","word_nosc":"troquas","lemma":"troquer","pos":"VER"} ,
		{"word":"troque","word_nosc":"troque","lemma":"troquer","pos":"VER"} ,
		{"word":"troquer","word_nosc":"troquer","lemma":"troquer","pos":"VER"} ,
		{"word":"troquera","word_nosc":"troquera","lemma":"troquer","pos":"VER"} ,
		{"word":"troquerai","word_nosc":"troquerai","lemma":"troquer","pos":"VER"} ,
		{"word":"troquerais","word_nosc":"troquerais","lemma":"troquer","pos":"VER"} ,
		{"word":"troquez","word_nosc":"troquez","lemma":"troquer","pos":"VER"} ,
		{"word":"troquèrent","word_nosc":"troquerent","lemma":"troquer","pos":"VER"} ,
		{"word":"troqué","word_nosc":"troque","lemma":"troquer","pos":"VER"} ,
		{"word":"troquée","word_nosc":"troquee","lemma":"troquer","pos":"VER"} ,
		{"word":"troquées","word_nosc":"troquees","lemma":"troquer","pos":"VER"} ,
		{"word":"troqués","word_nosc":"troques","lemma":"troquer","pos":"VER"} ,
		{"word":"trotta","word_nosc":"trotta","lemma":"trotter","pos":"VER"} ,
		{"word":"trottaient","word_nosc":"trottaient","lemma":"trotter","pos":"VER"} ,
		{"word":"trottais","word_nosc":"trottais","lemma":"trotter","pos":"VER"} ,
		{"word":"trottait","word_nosc":"trottait","lemma":"trotter","pos":"VER"} ,
		{"word":"trottant","word_nosc":"trottant","lemma":"trotter","pos":"VER"} ,
		{"word":"trotte","word_nosc":"trotte","lemma":"trotter","pos":"VER"} ,
		{"word":"trottent","word_nosc":"trottent","lemma":"trotter","pos":"VER"} ,
		{"word":"trotter","word_nosc":"trotter","lemma":"trotter","pos":"VER"} ,
		{"word":"trottera","word_nosc":"trottera","lemma":"trotter","pos":"VER"} ,
		{"word":"trotterions","word_nosc":"trotterions","lemma":"trotter","pos":"VER"} ,
		{"word":"trottes","word_nosc":"trottes","lemma":"trotter","pos":"VER"} ,
		{"word":"trottez","word_nosc":"trottez","lemma":"trotter","pos":"VER"} ,
		{"word":"trottina","word_nosc":"trottina","lemma":"trottiner","pos":"VER"} ,
		{"word":"trottinais","word_nosc":"trottinais","lemma":"trottiner","pos":"VER"} ,
		{"word":"trottinait","word_nosc":"trottinait","lemma":"trottiner","pos":"VER"} ,
		{"word":"trottinant","word_nosc":"trottinant","lemma":"trottiner","pos":"VER"} ,
		{"word":"trottine","word_nosc":"trottine","lemma":"trottiner","pos":"VER"} ,
		{"word":"trottinent","word_nosc":"trottinent","lemma":"trottiner","pos":"VER"} ,
		{"word":"trottiner","word_nosc":"trottiner","lemma":"trottiner","pos":"VER"} ,
		{"word":"trottinerait","word_nosc":"trottinerait","lemma":"trottiner","pos":"VER"} ,
		{"word":"trottinez","word_nosc":"trottinez","lemma":"trottiner","pos":"VER"} ,
		{"word":"trottinions","word_nosc":"trottinions","lemma":"trottiner","pos":"VER"} ,
		{"word":"trottiné","word_nosc":"trottine","lemma":"trottiner","pos":"VER"} ,
		{"word":"trottions","word_nosc":"trottions","lemma":"trotter","pos":"VER"} ,
		{"word":"trottons","word_nosc":"trottons","lemma":"trotter","pos":"VER"} ,
		{"word":"trotté","word_nosc":"trotte","lemma":"trotter","pos":"VER"} ,
		{"word":"troua","word_nosc":"troua","lemma":"trouer","pos":"VER"} ,
		{"word":"trouai","word_nosc":"trouai","lemma":"trouer","pos":"VER"} ,
		{"word":"trouaient","word_nosc":"trouaient","lemma":"trouer","pos":"VER"} ,
		{"word":"trouais","word_nosc":"trouais","lemma":"trouer","pos":"VER"} ,
		{"word":"trouait","word_nosc":"trouait","lemma":"trouer","pos":"VER"} ,
		{"word":"trouant","word_nosc":"trouant","lemma":"trouer","pos":"VER"} ,
		{"word":"troubla","word_nosc":"troubla","lemma":"troubler","pos":"VER"} ,
		{"word":"troublai","word_nosc":"troublai","lemma":"troubler","pos":"VER"} ,
		{"word":"troublaient","word_nosc":"troublaient","lemma":"troubler","pos":"VER"} ,
		{"word":"troublais","word_nosc":"troublais","lemma":"troubler","pos":"VER"} ,
		{"word":"troublait","word_nosc":"troublait","lemma":"troubler","pos":"VER"} ,
		{"word":"troublant","word_nosc":"troublant","lemma":"troubler","pos":"VER"} ,
		{"word":"trouble","word_nosc":"trouble","lemma":"troubler","pos":"VER"} ,
		{"word":"troublent","word_nosc":"troublent","lemma":"troubler","pos":"VER"} ,
		{"word":"troubler","word_nosc":"troubler","lemma":"troubler","pos":"VER"} ,
		{"word":"troublera","word_nosc":"troublera","lemma":"troubler","pos":"VER"} ,
		{"word":"troublerais","word_nosc":"troublerais","lemma":"troubler","pos":"VER"} ,
		{"word":"troublerait","word_nosc":"troublerait","lemma":"troubler","pos":"VER"} ,
		{"word":"troubleront","word_nosc":"troubleront","lemma":"troubler","pos":"VER"} ,
		{"word":"troubles","word_nosc":"troubles","lemma":"troubler","pos":"VER"} ,
		{"word":"troublez","word_nosc":"troublez","lemma":"troubler","pos":"VER"} ,
		{"word":"troublât","word_nosc":"troublat","lemma":"troubler","pos":"VER"} ,
		{"word":"troublèrent","word_nosc":"troublerent","lemma":"troubler","pos":"VER"} ,
		{"word":"troublé","word_nosc":"trouble","lemma":"troubler","pos":"VER"} ,
		{"word":"troublée","word_nosc":"troublee","lemma":"troubler","pos":"VER"} ,
		{"word":"troublées","word_nosc":"troublees","lemma":"troubler","pos":"VER"} ,
		{"word":"troublés","word_nosc":"troubles","lemma":"troubler","pos":"VER"} ,
		{"word":"troue","word_nosc":"troue","lemma":"trouer","pos":"VER"} ,
		{"word":"trouent","word_nosc":"trouent","lemma":"trouer","pos":"VER"} ,
		{"word":"trouer","word_nosc":"trouer","lemma":"trouer","pos":"VER"} ,
		{"word":"trouerai","word_nosc":"trouerai","lemma":"trouer","pos":"VER"} ,
		{"word":"trouerais","word_nosc":"trouerais","lemma":"trouer","pos":"VER"} ,
		{"word":"trouez","word_nosc":"trouez","lemma":"trouer","pos":"VER"} ,
		{"word":"trouillait","word_nosc":"trouillait","lemma":"trouiller","pos":"VER"} ,
		{"word":"troussa","word_nosc":"troussa","lemma":"trousser","pos":"VER"} ,
		{"word":"troussaient","word_nosc":"troussaient","lemma":"trousser","pos":"VER"} ,
		{"word":"troussait","word_nosc":"troussait","lemma":"trousser","pos":"VER"} ,
		{"word":"troussant","word_nosc":"troussant","lemma":"trousser","pos":"VER"} ,
		{"word":"trousse","word_nosc":"trousse","lemma":"trousser","pos":"VER"} ,
		{"word":"trousser","word_nosc":"trousser","lemma":"trousser","pos":"VER"} ,
		{"word":"trousses","word_nosc":"trousses","lemma":"trousser","pos":"VER"} ,
		{"word":"troussèrent","word_nosc":"trousserent","lemma":"trousser","pos":"VER"} ,
		{"word":"troussé","word_nosc":"trousse","lemma":"trousser","pos":"VER"} ,
		{"word":"troussée","word_nosc":"troussee","lemma":"trousser","pos":"VER"} ,
		{"word":"troussées","word_nosc":"troussees","lemma":"trousser","pos":"VER"} ,
		{"word":"trouva","word_nosc":"trouva","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvai","word_nosc":"trouvai","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvaient","word_nosc":"trouvaient","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvais","word_nosc":"trouvais","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvait","word_nosc":"trouvait","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvant","word_nosc":"trouvant","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvas","word_nosc":"trouvas","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvasse","word_nosc":"trouvasse","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvassent","word_nosc":"trouvassent","lemma":"trouver","pos":"VER"} ,
		{"word":"trouve","word_nosc":"trouve","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvent","word_nosc":"trouvent","lemma":"trouver","pos":"VER"} ,
		{"word":"trouver","word_nosc":"trouver","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvera","word_nosc":"trouvera","lemma":"trouver","pos":"VER"} ,
		{"word":"trouverai","word_nosc":"trouverai","lemma":"trouver","pos":"VER"} ,
		{"word":"trouveraient","word_nosc":"trouveraient","lemma":"trouver","pos":"VER"} ,
		{"word":"trouverais","word_nosc":"trouverais","lemma":"trouver","pos":"VER"} ,
		{"word":"trouverait","word_nosc":"trouverait","lemma":"trouver","pos":"VER"} ,
		{"word":"trouveras","word_nosc":"trouveras","lemma":"trouver","pos":"VER"} ,
		{"word":"trouverez","word_nosc":"trouverez","lemma":"trouver","pos":"VER"} ,
		{"word":"trouveriez","word_nosc":"trouveriez","lemma":"trouver","pos":"VER"} ,
		{"word":"trouverions","word_nosc":"trouverions","lemma":"trouver","pos":"VER"} ,
		{"word":"trouverons","word_nosc":"trouverons","lemma":"trouver","pos":"VER"} ,
		{"word":"trouveront","word_nosc":"trouveront","lemma":"trouver","pos":"VER"} ,
		{"word":"trouves","word_nosc":"trouves","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvez","word_nosc":"trouvez","lemma":"trouver","pos":"VER"} ,
		{"word":"trouviez","word_nosc":"trouviez","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvions","word_nosc":"trouvions","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvons","word_nosc":"trouvons","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvâmes","word_nosc":"trouvames","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvât","word_nosc":"trouvat","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvèrent","word_nosc":"trouverent","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvé","word_nosc":"trouve","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvée","word_nosc":"trouvee","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvées","word_nosc":"trouvees","lemma":"trouver","pos":"VER"} ,
		{"word":"trouvés","word_nosc":"trouves","lemma":"trouver","pos":"VER"} ,
		{"word":"trouèrent","word_nosc":"trouerent","lemma":"trouer","pos":"VER"} ,
		{"word":"troué","word_nosc":"troue","lemma":"trouer","pos":"VER"} ,
		{"word":"trouée","word_nosc":"trouee","lemma":"trouer","pos":"VER"} ,
		{"word":"trouées","word_nosc":"trouees","lemma":"trouer","pos":"VER"} ,
		{"word":"troués","word_nosc":"troues","lemma":"trouer","pos":"VER"} ,
		{"word":"truande","word_nosc":"truande","lemma":"truander","pos":"VER"} ,
		{"word":"truander","word_nosc":"truander","lemma":"truander","pos":"VER"} ,
		{"word":"truandes","word_nosc":"truandes","lemma":"truander","pos":"VER"} ,
		{"word":"truandé","word_nosc":"truande","lemma":"truander","pos":"VER"} ,
		{"word":"trucida","word_nosc":"trucida","lemma":"trucider","pos":"VER"} ,
		{"word":"trucidais","word_nosc":"trucidais","lemma":"trucider","pos":"VER"} ,
		{"word":"trucidant","word_nosc":"trucidant","lemma":"trucider","pos":"VER"} ,
		{"word":"trucident","word_nosc":"trucident","lemma":"trucider","pos":"VER"} ,
		{"word":"trucider","word_nosc":"trucider","lemma":"trucider","pos":"VER"} ,
		{"word":"truciderai","word_nosc":"truciderai","lemma":"trucider","pos":"VER"} ,
		{"word":"trucides","word_nosc":"trucides","lemma":"trucider","pos":"VER"} ,
		{"word":"trucidons","word_nosc":"trucidons","lemma":"trucider","pos":"VER"} ,
		{"word":"trucidé","word_nosc":"trucide","lemma":"trucider","pos":"VER"} ,
		{"word":"trucidée","word_nosc":"trucidee","lemma":"trucider","pos":"VER"} ,
		{"word":"trucidés","word_nosc":"trucides","lemma":"trucider","pos":"VER"} ,
		{"word":"truffaient","word_nosc":"truffaient","lemma":"truffer","pos":"VER"} ,
		{"word":"truffait","word_nosc":"truffait","lemma":"truffer","pos":"VER"} ,
		{"word":"truffant","word_nosc":"truffant","lemma":"truffer","pos":"VER"} ,
		{"word":"truffe","word_nosc":"truffe","lemma":"truffer","pos":"VER"} ,
		{"word":"truffer","word_nosc":"truffer","lemma":"truffer","pos":"VER"} ,
		{"word":"truffons","word_nosc":"truffons","lemma":"truffer","pos":"VER"} ,
		{"word":"truffé","word_nosc":"truffe","lemma":"truffer","pos":"VER"} ,
		{"word":"truffée","word_nosc":"truffee","lemma":"truffer","pos":"VER"} ,
		{"word":"truffées","word_nosc":"truffees","lemma":"truffer","pos":"VER"} ,
		{"word":"truffés","word_nosc":"truffes","lemma":"truffer","pos":"VER"} ,
		{"word":"truqua","word_nosc":"truqua","lemma":"truquer","pos":"VER"} ,
		{"word":"truquaient","word_nosc":"truquaient","lemma":"truquer","pos":"VER"} ,
		{"word":"truquait","word_nosc":"truquait","lemma":"truquer","pos":"VER"} ,
		{"word":"truquant","word_nosc":"truquant","lemma":"truquer","pos":"VER"} ,
		{"word":"truque","word_nosc":"truque","lemma":"truquer","pos":"VER"} ,
		{"word":"truquer","word_nosc":"truquer","lemma":"truquer","pos":"VER"} ,
		{"word":"truquerai","word_nosc":"truquerai","lemma":"truquer","pos":"VER"} ,
		{"word":"truquerez","word_nosc":"truquerez","lemma":"truquer","pos":"VER"} ,
		{"word":"truquez","word_nosc":"truquez","lemma":"truquer","pos":"VER"} ,
		{"word":"truquions","word_nosc":"truquions","lemma":"truquer","pos":"VER"} ,
		{"word":"truqué","word_nosc":"truque","lemma":"truquer","pos":"VER"} ,
		{"word":"truquée","word_nosc":"truquee","lemma":"truquer","pos":"VER"} ,
		{"word":"truquées","word_nosc":"truquees","lemma":"truquer","pos":"VER"} ,
		{"word":"truqués","word_nosc":"truques","lemma":"truquer","pos":"VER"} ,
		{"word":"trustais","word_nosc":"trustais","lemma":"truster","pos":"VER"} ,
		{"word":"trustait","word_nosc":"trustait","lemma":"truster","pos":"VER"} ,
		{"word":"truste","word_nosc":"truste","lemma":"truster","pos":"VER"} ,
		{"word":"truster","word_nosc":"truster","lemma":"truster","pos":"VER"} ,
		{"word":"trusterait","word_nosc":"trusterait","lemma":"truster","pos":"VER"} ,
		{"word":"trébucha","word_nosc":"trebucha","lemma":"trébucher","pos":"VER"} ,
		{"word":"trébuchai","word_nosc":"trebuchai","lemma":"trébucher","pos":"VER"} ,
		{"word":"trébuchaient","word_nosc":"trebuchaient","lemma":"trébucher","pos":"VER"} ,
		{"word":"trébuchais","word_nosc":"trebuchais","lemma":"trébucher","pos":"VER"} ,
		{"word":"trébuchait","word_nosc":"trebuchait","lemma":"trébucher","pos":"VER"} ,
		{"word":"trébuchant","word_nosc":"trebuchant","lemma":"trébucher","pos":"VER"} ,
		{"word":"trébuche","word_nosc":"trebuche","lemma":"trébucher","pos":"VER"} ,
		{"word":"trébuchent","word_nosc":"trebuchent","lemma":"trébucher","pos":"VER"} ,
		{"word":"trébucher","word_nosc":"trebucher","lemma":"trébucher","pos":"VER"} ,
		{"word":"trébuches","word_nosc":"trebuches","lemma":"trébucher","pos":"VER"} ,
		{"word":"trébuchez","word_nosc":"trebuchez","lemma":"trébucher","pos":"VER"} ,
		{"word":"trébuchons","word_nosc":"trebuchons","lemma":"trébucher","pos":"VER"} ,
		{"word":"trébuché","word_nosc":"trebuche","lemma":"trébucher","pos":"VER"} ,
		{"word":"tréfilés","word_nosc":"trefiles","lemma":"tréfiler","pos":"VER"} ,
		{"word":"trémoussa","word_nosc":"tremoussa","lemma":"trémousser","pos":"VER"} ,
		{"word":"trémoussaient","word_nosc":"tremoussaient","lemma":"trémousser","pos":"VER"} ,
		{"word":"trémoussait","word_nosc":"tremoussait","lemma":"trémousser","pos":"VER"} ,
		{"word":"trémoussant","word_nosc":"tremoussant","lemma":"trémousser","pos":"VER"} ,
		{"word":"trémousse","word_nosc":"tremousse","lemma":"trémousser","pos":"VER"} ,
		{"word":"trémoussent","word_nosc":"tremoussent","lemma":"trémousser","pos":"VER"} ,
		{"word":"trémousser","word_nosc":"tremousser","lemma":"trémousser","pos":"VER"} ,
		{"word":"trémousses","word_nosc":"tremousses","lemma":"trémousser","pos":"VER"} ,
		{"word":"trémoussât","word_nosc":"tremoussat","lemma":"trémousser","pos":"VER"} ,
		{"word":"trémoussèrent","word_nosc":"tremousserent","lemma":"trémousser","pos":"VER"} ,
		{"word":"trémulant","word_nosc":"tremulant","lemma":"trémuler","pos":"VER"} ,
		{"word":"trémuler","word_nosc":"tremuler","lemma":"trémuler","pos":"VER"} ,
		{"word":"trépaner","word_nosc":"trepaner","lemma":"trépaner","pos":"VER"} ,
		{"word":"trépané","word_nosc":"trepane","lemma":"trépaner","pos":"VER"} ,
		{"word":"trépassaient","word_nosc":"trepassaient","lemma":"trépasser","pos":"VER"} ,
		{"word":"trépassait","word_nosc":"trepassait","lemma":"trépasser","pos":"VER"} ,
		{"word":"trépassant","word_nosc":"trepassant","lemma":"trépasser","pos":"VER"} ,
		{"word":"trépasse","word_nosc":"trepasse","lemma":"trépasser","pos":"VER"} ,
		{"word":"trépassent","word_nosc":"trepassent","lemma":"trépasser","pos":"VER"} ,
		{"word":"trépasser","word_nosc":"trepasser","lemma":"trépasser","pos":"VER"} ,
		{"word":"trépasserait","word_nosc":"trepasserait","lemma":"trépasser","pos":"VER"} ,
		{"word":"trépasseront","word_nosc":"trepasseront","lemma":"trépasser","pos":"VER"} ,
		{"word":"trépassé","word_nosc":"trepasse","lemma":"trépasser","pos":"VER"} ,
		{"word":"trépassée","word_nosc":"trepassee","lemma":"trépasser","pos":"VER"} ,
		{"word":"trépidant","word_nosc":"trepidant","lemma":"trépider","pos":"VER"} ,
		{"word":"trépide","word_nosc":"trepide","lemma":"trépider","pos":"VER"} ,
		{"word":"trépident","word_nosc":"trepident","lemma":"trépider","pos":"VER"} ,
		{"word":"trépider","word_nosc":"trepider","lemma":"trépider","pos":"VER"} ,
		{"word":"trépigna","word_nosc":"trepigna","lemma":"trépigner","pos":"VER"} ,
		{"word":"trépignaient","word_nosc":"trepignaient","lemma":"trépigner","pos":"VER"} ,
		{"word":"trépignais","word_nosc":"trepignais","lemma":"trépigner","pos":"VER"} ,
		{"word":"trépignait","word_nosc":"trepignait","lemma":"trépigner","pos":"VER"} ,
		{"word":"trépigne","word_nosc":"trepigne","lemma":"trépigner","pos":"VER"} ,
		{"word":"trépignent","word_nosc":"trepignent","lemma":"trépigner","pos":"VER"} ,
		{"word":"trépigner","word_nosc":"trepigner","lemma":"trépigner","pos":"VER"} ,
		{"word":"trépignerai","word_nosc":"trepignerai","lemma":"trépigner","pos":"VER"} ,
		{"word":"trépignez","word_nosc":"trepignez","lemma":"trépigner","pos":"VER"} ,
		{"word":"trépigné","word_nosc":"trepigne","lemma":"trépigner","pos":"VER"} ,
		{"word":"trépignée","word_nosc":"trepignee","lemma":"trépigner","pos":"VER"} ,
		{"word":"trôler","word_nosc":"troler","lemma":"trôler","pos":"VER"} ,
		{"word":"trôna","word_nosc":"trona","lemma":"trôner","pos":"VER"} ,
		{"word":"trônaient","word_nosc":"tronaient","lemma":"trôner","pos":"VER"} ,
		{"word":"trônais","word_nosc":"tronais","lemma":"trôner","pos":"VER"} ,
		{"word":"trônait","word_nosc":"tronait","lemma":"trôner","pos":"VER"} ,
		{"word":"trônant","word_nosc":"tronant","lemma":"trôner","pos":"VER"} ,
		{"word":"trône","word_nosc":"trone","lemma":"trôner","pos":"VER"} ,
		{"word":"trônent","word_nosc":"tronent","lemma":"trôner","pos":"VER"} ,
		{"word":"trôner","word_nosc":"troner","lemma":"trôner","pos":"VER"} ,
		{"word":"trônerait","word_nosc":"tronerait","lemma":"trôner","pos":"VER"} ,
		{"word":"trôné","word_nosc":"trone","lemma":"trôner","pos":"VER"} ,
		{"word":"tu","word_nosc":"tu","lemma":"taire","pos":"VER"} ,
		{"word":"tua","word_nosc":"tua","lemma":"tuer","pos":"VER"} ,
		{"word":"tuai","word_nosc":"tuai","lemma":"tuer","pos":"VER"} ,
		{"word":"tuaient","word_nosc":"tuaient","lemma":"tuer","pos":"VER"} ,
		{"word":"tuais","word_nosc":"tuais","lemma":"tuer","pos":"VER"} ,
		{"word":"tuait","word_nosc":"tuait","lemma":"tuer","pos":"VER"} ,
		{"word":"tuant","word_nosc":"tuant","lemma":"tuer","pos":"VER"} ,
		{"word":"tuas","word_nosc":"tuas","lemma":"tuer","pos":"VER"} ,
		{"word":"tuba","word_nosc":"tuba","lemma":"tuber","pos":"VER"} ,
		{"word":"tuber","word_nosc":"tuber","lemma":"tuber","pos":"VER"} ,
		{"word":"tubé","word_nosc":"tube","lemma":"tuber","pos":"VER"} ,
		{"word":"tue","word_nosc":"tue","lemma":"tuer","pos":"VER"} ,
		{"word":"tuent","word_nosc":"tuent","lemma":"tuer","pos":"VER"} ,
		{"word":"tuer","word_nosc":"tuer","lemma":"tuer","pos":"VER"} ,
		{"word":"tuera","word_nosc":"tuera","lemma":"tuer","pos":"VER"} ,
		{"word":"tuerai","word_nosc":"tuerai","lemma":"tuer","pos":"VER"} ,
		{"word":"tuerais","word_nosc":"tuerais","lemma":"tuer","pos":"VER"} ,
		{"word":"tuerait","word_nosc":"tuerait","lemma":"tuer","pos":"VER"} ,
		{"word":"tueras","word_nosc":"tueras","lemma":"tuer","pos":"VER"} ,
		{"word":"tuerez","word_nosc":"tuerez","lemma":"tuer","pos":"VER"} ,
		{"word":"tueriez","word_nosc":"tueriez","lemma":"tuer","pos":"VER"} ,
		{"word":"tuerions","word_nosc":"tuerions","lemma":"tuer","pos":"VER"} ,
		{"word":"tuerons","word_nosc":"tuerons","lemma":"tuer","pos":"VER"} ,
		{"word":"tueront","word_nosc":"tueront","lemma":"tuer","pos":"VER"} ,
		{"word":"tues","word_nosc":"tues","lemma":"tuer","pos":"VER"} ,
		{"word":"tuez","word_nosc":"tuez","lemma":"tuer","pos":"VER"} ,
		{"word":"tuiez","word_nosc":"tuiez","lemma":"tuer","pos":"VER"} ,
		{"word":"tuions","word_nosc":"tuions","lemma":"tuer","pos":"VER"} ,
		{"word":"tuméfie","word_nosc":"tumefie","lemma":"tuméfier","pos":"VER"} ,
		{"word":"tuméfier","word_nosc":"tumefier","lemma":"tuméfier","pos":"VER"} ,
		{"word":"tuméfié","word_nosc":"tumefie","lemma":"tuméfier","pos":"VER"} ,
		{"word":"tuméfiée","word_nosc":"tumefiee","lemma":"tuméfier","pos":"VER"} ,
		{"word":"tuméfiés","word_nosc":"tumefies","lemma":"tuméfier","pos":"VER"} ,
		{"word":"tuons","word_nosc":"tuons","lemma":"tuer","pos":"VER"} ,
		{"word":"turbina","word_nosc":"turbina","lemma":"turbiner","pos":"VER"} ,
		{"word":"turbinaient","word_nosc":"turbinaient","lemma":"turbiner","pos":"VER"} ,
		{"word":"turbinait","word_nosc":"turbinait","lemma":"turbiner","pos":"VER"} ,
		{"word":"turbine","word_nosc":"turbine","lemma":"turbiner","pos":"VER"} ,
		{"word":"turbiner","word_nosc":"turbiner","lemma":"turbiner","pos":"VER"} ,
		{"word":"turbines","word_nosc":"turbines","lemma":"turbiner","pos":"VER"} ,
		{"word":"turbinez","word_nosc":"turbinez","lemma":"turbiner","pos":"VER"} ,
		{"word":"turbiné","word_nosc":"turbine","lemma":"turbiner","pos":"VER"} ,
		{"word":"turent","word_nosc":"turent","lemma":"taire","pos":"VER"} ,
		{"word":"turlupinaient","word_nosc":"turlupinaient","lemma":"turlupiner","pos":"VER"} ,
		{"word":"turlupinait","word_nosc":"turlupinait","lemma":"turlupiner","pos":"VER"} ,
		{"word":"turlupine","word_nosc":"turlupine","lemma":"turlupiner","pos":"VER"} ,
		{"word":"turlupinent","word_nosc":"turlupinent","lemma":"turlupiner","pos":"VER"} ,
		{"word":"turlupiner","word_nosc":"turlupiner","lemma":"turlupiner","pos":"VER"} ,
		{"word":"turlupinera","word_nosc":"turlupinera","lemma":"turlupiner","pos":"VER"} ,
		{"word":"turlupiné","word_nosc":"turlupine","lemma":"turlupiner","pos":"VER"} ,
		{"word":"turlupinée","word_nosc":"turlupinee","lemma":"turlupiner","pos":"VER"} ,
		{"word":"turlute","word_nosc":"turlute","lemma":"turluter","pos":"VER"} ,
		{"word":"turlutent","word_nosc":"turlutent","lemma":"turluter","pos":"VER"} ,
		{"word":"turluter","word_nosc":"turluter","lemma":"turluter","pos":"VER"} ,
		{"word":"turlutes","word_nosc":"turlutes","lemma":"turluter","pos":"VER"} ,
		{"word":"tus","word_nosc":"tus","lemma":"taire","pos":"VER"} ,
		{"word":"tussent","word_nosc":"tussent","lemma":"taire","pos":"VER"} ,
		{"word":"tut","word_nosc":"tut","lemma":"taire","pos":"VER"} ,
		{"word":"tutoie","word_nosc":"tutoie","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoient","word_nosc":"tutoient","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoiera","word_nosc":"tutoiera","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoierai","word_nosc":"tutoierai","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoies","word_nosc":"tutoies","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoya","word_nosc":"tutoya","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoyaient","word_nosc":"tutoyaient","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoyais","word_nosc":"tutoyais","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoyait","word_nosc":"tutoyait","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoyant","word_nosc":"tutoyant","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoyer","word_nosc":"tutoyer","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoyez","word_nosc":"tutoyez","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoyions","word_nosc":"tutoyions","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoyons","word_nosc":"tutoyons","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoyât","word_nosc":"tutoyat","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoyèrent","word_nosc":"tutoyerent","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoyé","word_nosc":"tutoye","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoyée","word_nosc":"tutoyee","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tutoyés","word_nosc":"tutoyes","lemma":"tutoyer","pos":"VER"} ,
		{"word":"tuyautait","word_nosc":"tuyautait","lemma":"tuyauter","pos":"VER"} ,
		{"word":"tuyautent","word_nosc":"tuyautent","lemma":"tuyauter","pos":"VER"} ,
		{"word":"tuyauter","word_nosc":"tuyauter","lemma":"tuyauter","pos":"VER"} ,
		{"word":"tuyautera","word_nosc":"tuyautera","lemma":"tuyauter","pos":"VER"} ,
		{"word":"tuyauté","word_nosc":"tuyaute","lemma":"tuyauter","pos":"VER"} ,
		{"word":"tuyautée","word_nosc":"tuyautee","lemma":"tuyauter","pos":"VER"} ,
		{"word":"tuyautées","word_nosc":"tuyautees","lemma":"tuyauter","pos":"VER"} ,
		{"word":"tuyautés","word_nosc":"tuyautes","lemma":"tuyauter","pos":"VER"} ,
		{"word":"tuât","word_nosc":"tuat","lemma":"tuer","pos":"VER"} ,
		{"word":"tuèrent","word_nosc":"tuerent","lemma":"tuer","pos":"VER"} ,
		{"word":"tué","word_nosc":"tue","lemma":"tuer","pos":"VER"} ,
		{"word":"tuée","word_nosc":"tuee","lemma":"tuer","pos":"VER"} ,
		{"word":"tuées","word_nosc":"tuees","lemma":"tuer","pos":"VER"} ,
		{"word":"tués","word_nosc":"tues","lemma":"tuer","pos":"VER"} ,
		{"word":"twistant","word_nosc":"twistant","lemma":"twister","pos":"VER"} ,
		{"word":"twister","word_nosc":"twister","lemma":"twister","pos":"VER"} ,
		{"word":"twistées","word_nosc":"twistees","lemma":"twister","pos":"VER"} ,
		{"word":"typer","word_nosc":"typer","lemma":"typer","pos":"VER"} ,
		{"word":"typographier","word_nosc":"typographier","lemma":"typographier","pos":"VER"} ,
		{"word":"typé","word_nosc":"type","lemma":"typer","pos":"VER"} ,
		{"word":"typés","word_nosc":"types","lemma":"typer","pos":"VER"} ,
		{"word":"tyrannisais","word_nosc":"tyrannisais","lemma":"tyranniser","pos":"VER"} ,
		{"word":"tyrannisait","word_nosc":"tyrannisait","lemma":"tyranniser","pos":"VER"} ,
		{"word":"tyrannisant","word_nosc":"tyrannisant","lemma":"tyranniser","pos":"VER"} ,
		{"word":"tyrannise","word_nosc":"tyrannise","lemma":"tyranniser","pos":"VER"} ,
		{"word":"tyrannisent","word_nosc":"tyrannisent","lemma":"tyranniser","pos":"VER"} ,
		{"word":"tyranniser","word_nosc":"tyranniser","lemma":"tyranniser","pos":"VER"} ,
		{"word":"tyrannisèrent","word_nosc":"tyranniserent","lemma":"tyranniser","pos":"VER"} ,
		{"word":"tyrannisé","word_nosc":"tyrannise","lemma":"tyranniser","pos":"VER"} ,
		{"word":"tyrannisée","word_nosc":"tyrannisee","lemma":"tyranniser","pos":"VER"} ,
		{"word":"tâcha","word_nosc":"tacha","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâchai","word_nosc":"tachai","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâchaient","word_nosc":"tachaient","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâchais","word_nosc":"tachais","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâchait","word_nosc":"tachait","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâchant","word_nosc":"tachant","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâche","word_nosc":"tache","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâchent","word_nosc":"tachent","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâcher","word_nosc":"tacher","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâchera","word_nosc":"tachera","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâcherai","word_nosc":"tacherai","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâcheraient","word_nosc":"tacheraient","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâcherait","word_nosc":"tacherait","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâcheras","word_nosc":"tacheras","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâcherez","word_nosc":"tacherez","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâcherons","word_nosc":"tacherons","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâcheront","word_nosc":"tacheront","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâches","word_nosc":"taches","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâchez","word_nosc":"tachez","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâchions","word_nosc":"tachions","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâchons","word_nosc":"tachons","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâchât","word_nosc":"tachat","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâchèrent","word_nosc":"tacherent","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâché","word_nosc":"tache","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâchée","word_nosc":"tachee","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâchés","word_nosc":"taches","lemma":"tâcher","pos":"VER"} ,
		{"word":"tâta","word_nosc":"tata","lemma":"tâter","pos":"VER"} ,
		{"word":"tâtai","word_nosc":"tatai","lemma":"tâter","pos":"VER"} ,
		{"word":"tâtaient","word_nosc":"tataient","lemma":"tâter","pos":"VER"} ,
		{"word":"tâtais","word_nosc":"tatais","lemma":"tâter","pos":"VER"} ,
		{"word":"tâtait","word_nosc":"tatait","lemma":"tâter","pos":"VER"} ,
		{"word":"tâtant","word_nosc":"tatant","lemma":"tâter","pos":"VER"} ,
		{"word":"tâte","word_nosc":"tate","lemma":"tâter","pos":"VER"} ,
		{"word":"tâtent","word_nosc":"tatent","lemma":"tâter","pos":"VER"} ,
		{"word":"tâter","word_nosc":"tater","lemma":"tâter","pos":"VER"} ,
		{"word":"tâtera","word_nosc":"tatera","lemma":"tâter","pos":"VER"} ,
		{"word":"tâterai","word_nosc":"taterai","lemma":"tâter","pos":"VER"} ,
		{"word":"tâteras","word_nosc":"tateras","lemma":"tâter","pos":"VER"} ,
		{"word":"tâterez","word_nosc":"taterez","lemma":"tâter","pos":"VER"} ,
		{"word":"tâtez","word_nosc":"tatez","lemma":"tâter","pos":"VER"} ,
		{"word":"tâtiez","word_nosc":"tatiez","lemma":"tâter","pos":"VER"} ,
		{"word":"tâtonna","word_nosc":"tatonna","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonnai","word_nosc":"tatonnai","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonnaient","word_nosc":"tatonnaient","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonnais","word_nosc":"tatonnais","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonnait","word_nosc":"tatonnait","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonnant","word_nosc":"tatonnant","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonne","word_nosc":"tatonne","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonnent","word_nosc":"tatonnent","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonner","word_nosc":"tatonner","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonnera","word_nosc":"tatonnera","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonnerais","word_nosc":"tatonnerais","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonnions","word_nosc":"tatonnions","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonnâmes","word_nosc":"tatonnames","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonnât","word_nosc":"tatonnat","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonnèrent","word_nosc":"tatonnerent","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonné","word_nosc":"tatonne","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtonnée","word_nosc":"tatonnee","lemma":"tâtonner","pos":"VER"} ,
		{"word":"tâtons","word_nosc":"tatons","lemma":"tâter","pos":"VER"} ,
		{"word":"tâtèrent","word_nosc":"taterent","lemma":"tâter","pos":"VER"} ,
		{"word":"tâté","word_nosc":"tate","lemma":"tâter","pos":"VER"} ,
		{"word":"tâtés","word_nosc":"tates","lemma":"tâter","pos":"VER"} ,
		{"word":"tète","word_nosc":"tete","lemma":"téter","pos":"VER"} ,
		{"word":"tètent","word_nosc":"tetent","lemma":"téter","pos":"VER"} ,
		{"word":"télescopait","word_nosc":"telescopait","lemma":"télescoper","pos":"VER"} ,
		{"word":"télescope","word_nosc":"telescope","lemma":"télescoper","pos":"VER"} ,
		{"word":"télescopent","word_nosc":"telescopent","lemma":"télescoper","pos":"VER"} ,
		{"word":"télescoper","word_nosc":"telescoper","lemma":"télescoper","pos":"VER"} ,
		{"word":"télescopes","word_nosc":"telescopes","lemma":"télescoper","pos":"VER"} ,
		{"word":"télescopèrent","word_nosc":"telescoperent","lemma":"télescoper","pos":"VER"} ,
		{"word":"télescopé","word_nosc":"telescope","lemma":"télescoper","pos":"VER"} ,
		{"word":"télescopée","word_nosc":"telescopee","lemma":"télescoper","pos":"VER"} ,
		{"word":"télescopées","word_nosc":"telescopees","lemma":"télescoper","pos":"VER"} ,
		{"word":"télexa","word_nosc":"telexa","lemma":"télexer","pos":"VER"} ,
		{"word":"télexer","word_nosc":"telexer","lemma":"télexer","pos":"VER"} ,
		{"word":"télexes","word_nosc":"telexes","lemma":"télexer","pos":"VER"} ,
		{"word":"télexé","word_nosc":"telexe","lemma":"télexer","pos":"VER"} ,
		{"word":"télécharge","word_nosc":"telecharge","lemma":"télécharger","pos":"VER"} ,
		{"word":"téléchargent","word_nosc":"telechargent","lemma":"télécharger","pos":"VER"} ,
		{"word":"téléchargeons","word_nosc":"telechargeons","lemma":"télécharger","pos":"VER"} ,
		{"word":"télécharger","word_nosc":"telecharger","lemma":"télécharger","pos":"VER"} ,
		{"word":"téléchargera","word_nosc":"telechargera","lemma":"télécharger","pos":"VER"} ,
		{"word":"téléchargerai","word_nosc":"telechargerai","lemma":"télécharger","pos":"VER"} ,
		{"word":"téléchargerons","word_nosc":"telechargerons","lemma":"télécharger","pos":"VER"} ,
		{"word":"téléchargez","word_nosc":"telechargez","lemma":"télécharger","pos":"VER"} ,
		{"word":"téléchargé","word_nosc":"telecharge","lemma":"télécharger","pos":"VER"} ,
		{"word":"téléchargée","word_nosc":"telechargee","lemma":"télécharger","pos":"VER"} ,
		{"word":"téléchargées","word_nosc":"telechargees","lemma":"télécharger","pos":"VER"} ,
		{"word":"téléchargés","word_nosc":"telecharges","lemma":"télécharger","pos":"VER"} ,
		{"word":"télécommande","word_nosc":"telecommande","lemma":"télécommander","pos":"VER"} ,
		{"word":"télécommander","word_nosc":"telecommander","lemma":"télécommander","pos":"VER"} ,
		{"word":"télécommandes","word_nosc":"telecommandes","lemma":"télécommander","pos":"VER"} ,
		{"word":"télécommandé","word_nosc":"telecommande","lemma":"télécommander","pos":"VER"} ,
		{"word":"télécommandée","word_nosc":"telecommandee","lemma":"télécommander","pos":"VER"} ,
		{"word":"télécommandées","word_nosc":"telecommandees","lemma":"télécommander","pos":"VER"} ,
		{"word":"télécommandés","word_nosc":"telecommandes","lemma":"télécommander","pos":"VER"} ,
		{"word":"télédiffuser","word_nosc":"telediffuser","lemma":"télédiffuser","pos":"VER"} ,
		{"word":"téléfaxe","word_nosc":"telefaxe","lemma":"téléfaxer","pos":"VER"} ,
		{"word":"téléfaxez","word_nosc":"telefaxez","lemma":"téléfaxer","pos":"VER"} ,
		{"word":"télégraphia","word_nosc":"telegraphia","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphiai","word_nosc":"telegraphiai","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphiait","word_nosc":"telegraphiait","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphiant","word_nosc":"telegraphiant","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphie","word_nosc":"telegraphie","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphient","word_nosc":"telegraphient","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphier","word_nosc":"telegraphier","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphierai","word_nosc":"telegraphierai","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphierais","word_nosc":"telegraphierais","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphierait","word_nosc":"telegraphierait","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphiez","word_nosc":"telegraphiez","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphions","word_nosc":"telegraphions","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphiâmes","word_nosc":"telegraphiames","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphié","word_nosc":"telegraphie","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphiée","word_nosc":"telegraphiee","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphiées","word_nosc":"telegraphiees","lemma":"télégraphier","pos":"VER"} ,
		{"word":"télégraphiés","word_nosc":"telegraphies","lemma":"télégraphier","pos":"VER"} ,
		{"word":"téléguider","word_nosc":"teleguider","lemma":"téléguider","pos":"VER"} ,
		{"word":"téléguidé","word_nosc":"teleguide","lemma":"téléguider","pos":"VER"} ,
		{"word":"téléguidée","word_nosc":"teleguidee","lemma":"téléguider","pos":"VER"} ,
		{"word":"téléguidées","word_nosc":"teleguidees","lemma":"téléguider","pos":"VER"} ,
		{"word":"téléguidés","word_nosc":"teleguides","lemma":"téléguider","pos":"VER"} ,
		{"word":"téléphona","word_nosc":"telephona","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonai","word_nosc":"telephonai","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonaient","word_nosc":"telephonaient","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonais","word_nosc":"telephonais","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonait","word_nosc":"telephonait","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonant","word_nosc":"telephonant","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphone","word_nosc":"telephone","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonent","word_nosc":"telephonent","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphoner","word_nosc":"telephoner","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonera","word_nosc":"telephonera","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonerai","word_nosc":"telephonerai","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphoneraient","word_nosc":"telephoneraient","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonerais","word_nosc":"telephonerais","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonerait","word_nosc":"telephonerait","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphoneras","word_nosc":"telephoneras","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonerez","word_nosc":"telephonerez","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphoneriez","word_nosc":"telephoneriez","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonerons","word_nosc":"telephonerons","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphoneront","word_nosc":"telephoneront","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphones","word_nosc":"telephones","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonez","word_nosc":"telephonez","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphoniez","word_nosc":"telephoniez","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonions","word_nosc":"telephonions","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonons","word_nosc":"telephonons","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonât","word_nosc":"telephonat","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphoné","word_nosc":"telephone","lemma":"téléphoner","pos":"VER"} ,
		{"word":"téléphonés","word_nosc":"telephones","lemma":"téléphoner","pos":"VER"} ,
		{"word":"télévise","word_nosc":"televise","lemma":"téléviser","pos":"VER"} ,
		{"word":"télévisera","word_nosc":"televisera","lemma":"téléviser","pos":"VER"} ,
		{"word":"télévises","word_nosc":"televises","lemma":"téléviser","pos":"VER"} ,
		{"word":"télévisé","word_nosc":"televise","lemma":"téléviser","pos":"VER"} ,
		{"word":"télévisée","word_nosc":"televisee","lemma":"téléviser","pos":"VER"} ,
		{"word":"télévisées","word_nosc":"televisees","lemma":"téléviser","pos":"VER"} ,
		{"word":"télévisés","word_nosc":"televises","lemma":"téléviser","pos":"VER"} ,
		{"word":"témoigna","word_nosc":"temoigna","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignaient","word_nosc":"temoignaient","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignais","word_nosc":"temoignais","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignait","word_nosc":"temoignait","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignant","word_nosc":"temoignant","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoigne","word_nosc":"temoigne","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignent","word_nosc":"temoignent","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoigner","word_nosc":"temoigner","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignera","word_nosc":"temoignera","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignerai","word_nosc":"temoignerai","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoigneraient","word_nosc":"temoigneraient","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignerais","word_nosc":"temoignerais","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignerait","word_nosc":"temoignerait","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoigneras","word_nosc":"temoigneras","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignerez","word_nosc":"temoignerez","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoigneront","word_nosc":"temoigneront","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignes","word_nosc":"temoignes","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignez","word_nosc":"temoignez","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoigniez","word_nosc":"temoigniez","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignât","word_nosc":"temoignat","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignèrent","word_nosc":"temoignerent","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoigné","word_nosc":"temoigne","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignée","word_nosc":"temoignee","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignées","word_nosc":"temoignees","lemma":"témoigner","pos":"VER"} ,
		{"word":"témoignés","word_nosc":"temoignes","lemma":"témoigner","pos":"VER"} ,
		{"word":"téta","word_nosc":"teta","lemma":"téter","pos":"VER"} ,
		{"word":"tétaient","word_nosc":"tetaient","lemma":"téter","pos":"VER"} ,
		{"word":"tétais","word_nosc":"tetais","lemma":"téter","pos":"VER"} ,
		{"word":"tétait","word_nosc":"tetait","lemma":"téter","pos":"VER"} ,
		{"word":"tétanisait","word_nosc":"tetanisait","lemma":"tétaniser","pos":"VER"} ,
		{"word":"tétanisant","word_nosc":"tetanisant","lemma":"tétaniser","pos":"VER"} ,
		{"word":"tétanisent","word_nosc":"tetanisent","lemma":"tétaniser","pos":"VER"} ,
		{"word":"tétaniser","word_nosc":"tetaniser","lemma":"tétaniser","pos":"VER"} ,
		{"word":"tétanisé","word_nosc":"tetanise","lemma":"tétaniser","pos":"VER"} ,
		{"word":"tétanisée","word_nosc":"tetanisee","lemma":"tétaniser","pos":"VER"} ,
		{"word":"tétant","word_nosc":"tetant","lemma":"téter","pos":"VER"} ,
		{"word":"tétasses","word_nosc":"tetasses","lemma":"téter","pos":"VER"} ,
		{"word":"téter","word_nosc":"teter","lemma":"téter","pos":"VER"} ,
		{"word":"tétiez","word_nosc":"tetiez","lemma":"téter","pos":"VER"} ,
		{"word":"tétons","word_nosc":"tetons","lemma":"téter","pos":"VER"} ,
		{"word":"tétèrent","word_nosc":"teterent","lemma":"téter","pos":"VER"} ,
		{"word":"tété","word_nosc":"tete","lemma":"téter","pos":"VER"} ,
		{"word":"tétée","word_nosc":"tetee","lemma":"téter","pos":"VER"} ,
		{"word":"tétées","word_nosc":"tetees","lemma":"téter","pos":"VER"} ,
		{"word":"tînmes","word_nosc":"tinmes","lemma":"tenir","pos":"VER"} ,
		{"word":"tînt","word_nosc":"tint","lemma":"tenir","pos":"VER"} ,
		{"word":"tûmes","word_nosc":"tumes","lemma":"taire","pos":"VER"} ,
		{"word":"tût","word_nosc":"tut","lemma":"taire","pos":"VER"} ,
		{"word":"ulcère","word_nosc":"ulcere","lemma":"ulcérer","pos":"VER"} ,
		{"word":"ulcéra","word_nosc":"ulcera","lemma":"ulcérer","pos":"VER"} ,
		{"word":"ulcérait","word_nosc":"ulcerait","lemma":"ulcérer","pos":"VER"} ,
		{"word":"ulcérant","word_nosc":"ulcerant","lemma":"ulcérer","pos":"VER"} ,
		{"word":"ulcéré","word_nosc":"ulcere","lemma":"ulcérer","pos":"VER"} ,
		{"word":"ulcérée","word_nosc":"ulceree","lemma":"ulcérer","pos":"VER"} ,
		{"word":"ulcérées","word_nosc":"ulcerees","lemma":"ulcérer","pos":"VER"} ,
		{"word":"ulcérés","word_nosc":"ulceres","lemma":"ulcérer","pos":"VER"} ,
		{"word":"ulula","word_nosc":"ulula","lemma":"ululer","pos":"VER"} ,
		{"word":"ululait","word_nosc":"ululait","lemma":"ululer","pos":"VER"} ,
		{"word":"ululant","word_nosc":"ululant","lemma":"ululer","pos":"VER"} ,
		{"word":"ulule","word_nosc":"ulule","lemma":"ululer","pos":"VER"} ,
		{"word":"ululer","word_nosc":"ululer","lemma":"ululer","pos":"VER"} ,
		{"word":"uni","word_nosc":"uni","lemma":"unir","pos":"VER"} ,
		{"word":"unie","word_nosc":"unie","lemma":"unir","pos":"VER"} ,
		{"word":"unies","word_nosc":"unies","lemma":"unir","pos":"VER"} ,
		{"word":"unifie","word_nosc":"unifie","lemma":"unifier","pos":"VER"} ,
		{"word":"unifient","word_nosc":"unifient","lemma":"unifier","pos":"VER"} ,
		{"word":"unifier","word_nosc":"unifier","lemma":"unifier","pos":"VER"} ,
		{"word":"unifiez","word_nosc":"unifiez","lemma":"unifier","pos":"VER"} ,
		{"word":"unifié","word_nosc":"unifie","lemma":"unifier","pos":"VER"} ,
		{"word":"unifiée","word_nosc":"unifiee","lemma":"unifier","pos":"VER"} ,
		{"word":"unifiées","word_nosc":"unifiees","lemma":"unifier","pos":"VER"} ,
		{"word":"unifiés","word_nosc":"unifies","lemma":"unifier","pos":"VER"} ,
		{"word":"uniformisait","word_nosc":"uniformisait","lemma":"uniformiser","pos":"VER"} ,
		{"word":"uniformise","word_nosc":"uniformise","lemma":"uniformiser","pos":"VER"} ,
		{"word":"uniformiser","word_nosc":"uniformiser","lemma":"uniformiser","pos":"VER"} ,
		{"word":"uniformisée","word_nosc":"uniformisee","lemma":"uniformiser","pos":"VER"} ,
		{"word":"uniformisés","word_nosc":"uniformises","lemma":"uniformiser","pos":"VER"} ,
		{"word":"unir","word_nosc":"unir","lemma":"unir","pos":"VER"} ,
		{"word":"unira","word_nosc":"unira","lemma":"unir","pos":"VER"} ,
		{"word":"unirai","word_nosc":"unirai","lemma":"unir","pos":"VER"} ,
		{"word":"uniraient","word_nosc":"uniraient","lemma":"unir","pos":"VER"} ,
		{"word":"unirait","word_nosc":"unirait","lemma":"unir","pos":"VER"} ,
		{"word":"unirent","word_nosc":"unirent","lemma":"unir","pos":"VER"} ,
		{"word":"unirez","word_nosc":"unirez","lemma":"unir","pos":"VER"} ,
		{"word":"unirions","word_nosc":"unirions","lemma":"unir","pos":"VER"} ,
		{"word":"unirons","word_nosc":"unirons","lemma":"unir","pos":"VER"} ,
		{"word":"uniront","word_nosc":"uniront","lemma":"unir","pos":"VER"} ,
		{"word":"unis","word_nosc":"unis","lemma":"unir","pos":"VER"} ,
		{"word":"unissaient","word_nosc":"unissaient","lemma":"unir","pos":"VER"} ,
		{"word":"unissais","word_nosc":"unissais","lemma":"unir","pos":"VER"} ,
		{"word":"unissait","word_nosc":"unissait","lemma":"unir","pos":"VER"} ,
		{"word":"unissant","word_nosc":"unissant","lemma":"unir","pos":"VER"} ,
		{"word":"unisse","word_nosc":"unisse","lemma":"unir","pos":"VER"} ,
		{"word":"unissent","word_nosc":"unissent","lemma":"unir","pos":"VER"} ,
		{"word":"unisses","word_nosc":"unisses","lemma":"unir","pos":"VER"} ,
		{"word":"unissez","word_nosc":"unissez","lemma":"unir","pos":"VER"} ,
		{"word":"unissiez","word_nosc":"unissiez","lemma":"unir","pos":"VER"} ,
		{"word":"unissions","word_nosc":"unissions","lemma":"unir","pos":"VER"} ,
		{"word":"unissons","word_nosc":"unissons","lemma":"unir","pos":"VER"} ,
		{"word":"unit","word_nosc":"unit","lemma":"unir","pos":"VER"} ,
		{"word":"universalisais","word_nosc":"universalisais","lemma":"universaliser","pos":"VER"} ,
		{"word":"universalise","word_nosc":"universalise","lemma":"universaliser","pos":"VER"} ,
		{"word":"unît","word_nosc":"unit","lemma":"unir","pos":"VER"} ,
		{"word":"updater","word_nosc":"updater","lemma":"updater","pos":"VER"} ,
		{"word":"upgradée","word_nosc":"upgradee","lemma":"upgrader","pos":"VER"} ,
		{"word":"urbanisé","word_nosc":"urbanise","lemma":"urbaniser","pos":"VER"} ,
		{"word":"urbanisée","word_nosc":"urbanisee","lemma":"urbaniser","pos":"VER"} ,
		{"word":"urge","word_nosc":"urge","lemma":"urger","pos":"VER"} ,
		{"word":"urgeait","word_nosc":"urgeait","lemma":"urger","pos":"VER"} ,
		{"word":"urgent","word_nosc":"urgent","lemma":"urger","pos":"VER"} ,
		{"word":"urger","word_nosc":"urger","lemma":"urger","pos":"VER"} ,
		{"word":"urina","word_nosc":"urina","lemma":"uriner","pos":"VER"} ,
		{"word":"urinaient","word_nosc":"urinaient","lemma":"uriner","pos":"VER"} ,
		{"word":"urinais","word_nosc":"urinais","lemma":"uriner","pos":"VER"} ,
		{"word":"urinait","word_nosc":"urinait","lemma":"uriner","pos":"VER"} ,
		{"word":"urinant","word_nosc":"urinant","lemma":"uriner","pos":"VER"} ,
		{"word":"urine","word_nosc":"urine","lemma":"uriner","pos":"VER"} ,
		{"word":"urinent","word_nosc":"urinent","lemma":"uriner","pos":"VER"} ,
		{"word":"uriner","word_nosc":"uriner","lemma":"uriner","pos":"VER"} ,
		{"word":"urinera","word_nosc":"urinera","lemma":"uriner","pos":"VER"} ,
		{"word":"urines","word_nosc":"urines","lemma":"uriner","pos":"VER"} ,
		{"word":"urinez","word_nosc":"urinez","lemma":"uriner","pos":"VER"} ,
		{"word":"urinons","word_nosc":"urinons","lemma":"uriner","pos":"VER"} ,
		{"word":"uriné","word_nosc":"urine","lemma":"uriner","pos":"VER"} ,
		{"word":"usa","word_nosc":"usa","lemma":"user","pos":"VER"} ,
		{"word":"usai","word_nosc":"usai","lemma":"user","pos":"VER"} ,
		{"word":"usaient","word_nosc":"usaient","lemma":"user","pos":"VER"} ,
		{"word":"usais","word_nosc":"usais","lemma":"user","pos":"VER"} ,
		{"word":"usait","word_nosc":"usait","lemma":"user","pos":"VER"} ,
		{"word":"usant","word_nosc":"usant","lemma":"user","pos":"VER"} ,
		{"word":"use","word_nosc":"use","lemma":"user","pos":"VER"} ,
		{"word":"usent","word_nosc":"usent","lemma":"user","pos":"VER"} ,
		{"word":"user","word_nosc":"user","lemma":"user","pos":"VER"} ,
		{"word":"usera","word_nosc":"usera","lemma":"user","pos":"VER"} ,
		{"word":"userai","word_nosc":"userai","lemma":"user","pos":"VER"} ,
		{"word":"userais","word_nosc":"userais","lemma":"user","pos":"VER"} ,
		{"word":"userait","word_nosc":"userait","lemma":"user","pos":"VER"} ,
		{"word":"useriez","word_nosc":"useriez","lemma":"user","pos":"VER"} ,
		{"word":"userons","word_nosc":"userons","lemma":"user","pos":"VER"} ,
		{"word":"useront","word_nosc":"useront","lemma":"user","pos":"VER"} ,
		{"word":"uses","word_nosc":"uses","lemma":"user","pos":"VER"} ,
		{"word":"usez","word_nosc":"usez","lemma":"user","pos":"VER"} ,
		{"word":"usinaient","word_nosc":"usinaient","lemma":"usiner","pos":"VER"} ,
		{"word":"usinant","word_nosc":"usinant","lemma":"usiner","pos":"VER"} ,
		{"word":"usine","word_nosc":"usine","lemma":"usiner","pos":"VER"} ,
		{"word":"usiner","word_nosc":"usiner","lemma":"usiner","pos":"VER"} ,
		{"word":"usiné","word_nosc":"usine","lemma":"usiner","pos":"VER"} ,
		{"word":"usinée","word_nosc":"usinee","lemma":"usiner","pos":"VER"} ,
		{"word":"usinées","word_nosc":"usinees","lemma":"usiner","pos":"VER"} ,
		{"word":"usinés","word_nosc":"usines","lemma":"usiner","pos":"VER"} ,
		{"word":"usions","word_nosc":"usions","lemma":"user","pos":"VER"} ,
		{"word":"usons","word_nosc":"usons","lemma":"user","pos":"VER"} ,
		{"word":"usurpaient","word_nosc":"usurpaient","lemma":"usurper","pos":"VER"} ,
		{"word":"usurpait","word_nosc":"usurpait","lemma":"usurper","pos":"VER"} ,
		{"word":"usurpant","word_nosc":"usurpant","lemma":"usurper","pos":"VER"} ,
		{"word":"usurpent","word_nosc":"usurpent","lemma":"usurper","pos":"VER"} ,
		{"word":"usurper","word_nosc":"usurper","lemma":"usurper","pos":"VER"} ,
		{"word":"usurperont","word_nosc":"usurperont","lemma":"usurper","pos":"VER"} ,
		{"word":"usurpé","word_nosc":"usurpe","lemma":"usurper","pos":"VER"} ,
		{"word":"usurpée","word_nosc":"usurpee","lemma":"usurper","pos":"VER"} ,
		{"word":"usurpées","word_nosc":"usurpees","lemma":"usurper","pos":"VER"} ,
		{"word":"usurpés","word_nosc":"usurpes","lemma":"usurper","pos":"VER"} ,
		{"word":"usât","word_nosc":"usat","lemma":"user","pos":"VER"} ,
		{"word":"usèrent","word_nosc":"userent","lemma":"user","pos":"VER"} ,
		{"word":"usé","word_nosc":"use","lemma":"user","pos":"VER"} ,
		{"word":"usée","word_nosc":"usee","lemma":"user","pos":"VER"} ,
		{"word":"usées","word_nosc":"usees","lemma":"user","pos":"VER"} ,
		{"word":"usés","word_nosc":"uses","lemma":"user","pos":"VER"} ,
		{"word":"utilisa","word_nosc":"utilisa","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisai","word_nosc":"utilisai","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisaient","word_nosc":"utilisaient","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisais","word_nosc":"utilisais","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisait","word_nosc":"utilisait","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisant","word_nosc":"utilisant","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilise","word_nosc":"utilise","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisent","word_nosc":"utilisent","lemma":"utiliser","pos":"VER"} ,
		{"word":"utiliser","word_nosc":"utiliser","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisera","word_nosc":"utilisera","lemma":"utiliser","pos":"VER"} ,
		{"word":"utiliserai","word_nosc":"utiliserai","lemma":"utiliser","pos":"VER"} ,
		{"word":"utiliseraient","word_nosc":"utiliseraient","lemma":"utiliser","pos":"VER"} ,
		{"word":"utiliserais","word_nosc":"utiliserais","lemma":"utiliser","pos":"VER"} ,
		{"word":"utiliserait","word_nosc":"utiliserait","lemma":"utiliser","pos":"VER"} ,
		{"word":"utiliseras","word_nosc":"utiliseras","lemma":"utiliser","pos":"VER"} ,
		{"word":"utiliserez","word_nosc":"utiliserez","lemma":"utiliser","pos":"VER"} ,
		{"word":"utiliseriez","word_nosc":"utiliseriez","lemma":"utiliser","pos":"VER"} ,
		{"word":"utiliserions","word_nosc":"utiliserions","lemma":"utiliser","pos":"VER"} ,
		{"word":"utiliserons","word_nosc":"utiliserons","lemma":"utiliser","pos":"VER"} ,
		{"word":"utiliseront","word_nosc":"utiliseront","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilises","word_nosc":"utilises","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisez","word_nosc":"utilisez","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisiez","word_nosc":"utilisiez","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisions","word_nosc":"utilisions","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisons","word_nosc":"utilisons","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisât","word_nosc":"utilisat","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisèrent","word_nosc":"utiliserent","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisé","word_nosc":"utilise","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisée","word_nosc":"utilisee","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisées","word_nosc":"utilisees","lemma":"utiliser","pos":"VER"} ,
		{"word":"utilisés","word_nosc":"utilises","lemma":"utiliser","pos":"VER"} ,
		{"word":"va","word_nosc":"va","lemma":"aller","pos":"VER"} ,
		{"word":"vaccine","word_nosc":"vaccine","lemma":"vacciner","pos":"VER"} ,
		{"word":"vaccinent","word_nosc":"vaccinent","lemma":"vacciner","pos":"VER"} ,
		{"word":"vacciner","word_nosc":"vacciner","lemma":"vacciner","pos":"VER"} ,
		{"word":"vacciné","word_nosc":"vaccine","lemma":"vacciner","pos":"VER"} ,
		{"word":"vaccinée","word_nosc":"vaccinee","lemma":"vacciner","pos":"VER"} ,
		{"word":"vaccinées","word_nosc":"vaccinees","lemma":"vacciner","pos":"VER"} ,
		{"word":"vaccinés","word_nosc":"vaccines","lemma":"vacciner","pos":"VER"} ,
		{"word":"vacilla","word_nosc":"vacilla","lemma":"vaciller","pos":"VER"} ,
		{"word":"vacillai","word_nosc":"vacillai","lemma":"vaciller","pos":"VER"} ,
		{"word":"vacillaient","word_nosc":"vacillaient","lemma":"vaciller","pos":"VER"} ,
		{"word":"vacillait","word_nosc":"vacillait","lemma":"vaciller","pos":"VER"} ,
		{"word":"vacillant","word_nosc":"vacillant","lemma":"vaciller","pos":"VER"} ,
		{"word":"vacille","word_nosc":"vacille","lemma":"vaciller","pos":"VER"} ,
		{"word":"vacillent","word_nosc":"vacillent","lemma":"vaciller","pos":"VER"} ,
		{"word":"vaciller","word_nosc":"vaciller","lemma":"vaciller","pos":"VER"} ,
		{"word":"vacillât","word_nosc":"vacillat","lemma":"vaciller","pos":"VER"} ,
		{"word":"vacillèrent","word_nosc":"vacillerent","lemma":"vaciller","pos":"VER"} ,
		{"word":"vacillé","word_nosc":"vacille","lemma":"vaciller","pos":"VER"} ,
		{"word":"vadrouillais","word_nosc":"vadrouillais","lemma":"vadrouiller","pos":"VER"} ,
		{"word":"vadrouillait","word_nosc":"vadrouillait","lemma":"vadrouiller","pos":"VER"} ,
		{"word":"vadrouille","word_nosc":"vadrouille","lemma":"vadrouiller","pos":"VER"} ,
		{"word":"vadrouillent","word_nosc":"vadrouillent","lemma":"vadrouiller","pos":"VER"} ,
		{"word":"vadrouiller","word_nosc":"vadrouiller","lemma":"vadrouiller","pos":"VER"} ,
		{"word":"vadrouilles","word_nosc":"vadrouilles","lemma":"vadrouiller","pos":"VER"} ,
		{"word":"vadrouillez","word_nosc":"vadrouillez","lemma":"vadrouiller","pos":"VER"} ,
		{"word":"vadrouillé","word_nosc":"vadrouille","lemma":"vadrouiller","pos":"VER"} ,
		{"word":"vagabonda","word_nosc":"vagabonda","lemma":"vagabonder","pos":"VER"} ,
		{"word":"vagabondaient","word_nosc":"vagabondaient","lemma":"vagabonder","pos":"VER"} ,
		{"word":"vagabondais","word_nosc":"vagabondais","lemma":"vagabonder","pos":"VER"} ,
		{"word":"vagabondait","word_nosc":"vagabondait","lemma":"vagabonder","pos":"VER"} ,
		{"word":"vagabondant","word_nosc":"vagabondant","lemma":"vagabonder","pos":"VER"} ,
		{"word":"vagabonde","word_nosc":"vagabonde","lemma":"vagabonder","pos":"VER"} ,
		{"word":"vagabondent","word_nosc":"vagabondent","lemma":"vagabonder","pos":"VER"} ,
		{"word":"vagabonder","word_nosc":"vagabonder","lemma":"vagabonder","pos":"VER"} ,
		{"word":"vagabondes","word_nosc":"vagabondes","lemma":"vagabonder","pos":"VER"} ,
		{"word":"vagabondiez","word_nosc":"vagabondiez","lemma":"vagabonder","pos":"VER"} ,
		{"word":"vagabondèrent","word_nosc":"vagabonderent","lemma":"vagabonder","pos":"VER"} ,
		{"word":"vagabondé","word_nosc":"vagabonde","lemma":"vagabonder","pos":"VER"} ,
		{"word":"vagi","word_nosc":"vagi","lemma":"vagir","pos":"VER"} ,
		{"word":"vagir","word_nosc":"vagir","lemma":"vagir","pos":"VER"} ,
		{"word":"vagis","word_nosc":"vagis","lemma":"vagir","pos":"VER"} ,
		{"word":"vagissaient","word_nosc":"vagissaient","lemma":"vagir","pos":"VER"} ,
		{"word":"vagissais","word_nosc":"vagissais","lemma":"vagir","pos":"VER"} ,
		{"word":"vagissait","word_nosc":"vagissait","lemma":"vagir","pos":"VER"} ,
		{"word":"vagissant","word_nosc":"vagissant","lemma":"vagir","pos":"VER"} ,
		{"word":"vagissent","word_nosc":"vagissent","lemma":"vagir","pos":"VER"} ,
		{"word":"vagissons","word_nosc":"vagissons","lemma":"vagir","pos":"VER"} ,
		{"word":"vagit","word_nosc":"vagit","lemma":"vagir","pos":"VER"} ,
		{"word":"vagua","word_nosc":"vagua","lemma":"vaguer","pos":"VER"} ,
		{"word":"vaguait","word_nosc":"vaguait","lemma":"vaguer","pos":"VER"} ,
		{"word":"vaguant","word_nosc":"vaguant","lemma":"vaguer","pos":"VER"} ,
		{"word":"vague","word_nosc":"vague","lemma":"vaguer","pos":"VER"} ,
		{"word":"vaguent","word_nosc":"vaguent","lemma":"vaguer","pos":"VER"} ,
		{"word":"vaguer","word_nosc":"vaguer","lemma":"vaguer","pos":"VER"} ,
		{"word":"vaille","word_nosc":"vaille","lemma":"valoir","pos":"VER"} ,
		{"word":"vaillent","word_nosc":"vaillent","lemma":"valoir","pos":"VER"} ,
		{"word":"vainc","word_nosc":"vainc","lemma":"vaincre","pos":"VER"} ,
		{"word":"vaincra","word_nosc":"vaincra","lemma":"vaincre","pos":"VER"} ,
		{"word":"vaincrai","word_nosc":"vaincrai","lemma":"vaincre","pos":"VER"} ,
		{"word":"vaincraient","word_nosc":"vaincraient","lemma":"vaincre","pos":"VER"} ,
		{"word":"vaincrais","word_nosc":"vaincrais","lemma":"vaincre","pos":"VER"} ,
		{"word":"vaincrait","word_nosc":"vaincrait","lemma":"vaincre","pos":"VER"} ,
		{"word":"vaincras","word_nosc":"vaincras","lemma":"vaincre","pos":"VER"} ,
		{"word":"vaincre","word_nosc":"vaincre","lemma":"vaincre","pos":"VER"} ,
		{"word":"vaincrez","word_nosc":"vaincrez","lemma":"vaincre","pos":"VER"} ,
		{"word":"vaincrons","word_nosc":"vaincrons","lemma":"vaincre","pos":"VER"} ,
		{"word":"vaincront","word_nosc":"vaincront","lemma":"vaincre","pos":"VER"} ,
		{"word":"vaincs","word_nosc":"vaincs","lemma":"vaincre","pos":"VER"} ,
		{"word":"vaincu","word_nosc":"vaincu","lemma":"vaincre","pos":"VER"} ,
		{"word":"vaincue","word_nosc":"vaincue","lemma":"vaincre","pos":"VER"} ,
		{"word":"vaincues","word_nosc":"vaincues","lemma":"vaincre","pos":"VER"} ,
		{"word":"vaincus","word_nosc":"vaincus","lemma":"vaincre","pos":"VER"} ,
		{"word":"vainquant","word_nosc":"vainquant","lemma":"vaincre","pos":"VER"} ,
		{"word":"vainquent","word_nosc":"vainquent","lemma":"vaincre","pos":"VER"} ,
		{"word":"vainquez","word_nosc":"vainquez","lemma":"vaincre","pos":"VER"} ,
		{"word":"vainquions","word_nosc":"vainquions","lemma":"vaincre","pos":"VER"} ,
		{"word":"vainquirent","word_nosc":"vainquirent","lemma":"vaincre","pos":"VER"} ,
		{"word":"vainquit","word_nosc":"vainquit","lemma":"vaincre","pos":"VER"} ,
		{"word":"vainquons","word_nosc":"vainquons","lemma":"vaincre","pos":"VER"} ,
		{"word":"vais","word_nosc":"vais","lemma":"aller","pos":"VER"} ,
		{"word":"valaient","word_nosc":"valaient","lemma":"valoir","pos":"VER"} ,
		{"word":"valais","word_nosc":"valais","lemma":"valoir","pos":"VER"} ,
		{"word":"valait","word_nosc":"valait","lemma":"valoir","pos":"VER"} ,
		{"word":"valant","word_nosc":"valant","lemma":"valoir","pos":"VER"} ,
		{"word":"valdingua","word_nosc":"valdingua","lemma":"valdinguer","pos":"VER"} ,
		{"word":"valdingue","word_nosc":"valdingue","lemma":"valdinguer","pos":"VER"} ,
		{"word":"valdinguer","word_nosc":"valdinguer","lemma":"valdinguer","pos":"VER"} ,
		{"word":"valdingues","word_nosc":"valdingues","lemma":"valdinguer","pos":"VER"} ,
		{"word":"valdingué","word_nosc":"valdingue","lemma":"valdinguer","pos":"VER"} ,
		{"word":"valdingués","word_nosc":"valdingues","lemma":"valdinguer","pos":"VER"} ,
		{"word":"valent","word_nosc":"valent","lemma":"valoir","pos":"VER"} ,
		{"word":"valeter","word_nosc":"valeter","lemma":"valeter","pos":"VER"} ,
		{"word":"valez","word_nosc":"valez","lemma":"valoir","pos":"VER"} ,
		{"word":"valida","word_nosc":"valida","lemma":"valider","pos":"VER"} ,
		{"word":"validait","word_nosc":"validait","lemma":"valider","pos":"VER"} ,
		{"word":"validant","word_nosc":"validant","lemma":"valider","pos":"VER"} ,
		{"word":"valide","word_nosc":"valide","lemma":"valider","pos":"VER"} ,
		{"word":"valident","word_nosc":"valident","lemma":"valider","pos":"VER"} ,
		{"word":"valider","word_nosc":"valider","lemma":"valider","pos":"VER"} ,
		{"word":"valideront","word_nosc":"valideront","lemma":"valider","pos":"VER"} ,
		{"word":"validé","word_nosc":"valide","lemma":"valider","pos":"VER"} ,
		{"word":"validée","word_nosc":"validee","lemma":"valider","pos":"VER"} ,
		{"word":"valiez","word_nosc":"valiez","lemma":"valoir","pos":"VER"} ,
		{"word":"valions","word_nosc":"valions","lemma":"valoir","pos":"VER"} ,
		{"word":"valisé","word_nosc":"valise","lemma":"valiser","pos":"VER"} ,
		{"word":"vallonnaient","word_nosc":"vallonnaient","lemma":"vallonner","pos":"VER"} ,
		{"word":"vallonnait","word_nosc":"vallonnait","lemma":"vallonner","pos":"VER"} ,
		{"word":"vallonner","word_nosc":"vallonner","lemma":"vallonner","pos":"VER"} ,
		{"word":"vallonnée","word_nosc":"vallonnee","lemma":"vallonner","pos":"VER"} ,
		{"word":"valoir","word_nosc":"valoir","lemma":"valoir","pos":"VER"} ,
		{"word":"valons","word_nosc":"valons","lemma":"valoir","pos":"VER"} ,
		{"word":"valorisait","word_nosc":"valorisait","lemma":"valoriser","pos":"VER"} ,
		{"word":"valorise","word_nosc":"valorise","lemma":"valoriser","pos":"VER"} ,
		{"word":"valoriser","word_nosc":"valoriser","lemma":"valoriser","pos":"VER"} ,
		{"word":"valorisez","word_nosc":"valorisez","lemma":"valoriser","pos":"VER"} ,
		{"word":"valorisé","word_nosc":"valorise","lemma":"valoriser","pos":"VER"} ,
		{"word":"valorisée","word_nosc":"valorisee","lemma":"valoriser","pos":"VER"} ,
		{"word":"valorisés","word_nosc":"valorises","lemma":"valoriser","pos":"VER"} ,
		{"word":"valsa","word_nosc":"valsa","lemma":"valser","pos":"VER"} ,
		{"word":"valsaient","word_nosc":"valsaient","lemma":"valser","pos":"VER"} ,
		{"word":"valsait","word_nosc":"valsait","lemma":"valser","pos":"VER"} ,
		{"word":"valsant","word_nosc":"valsant","lemma":"valser","pos":"VER"} ,
		{"word":"valse","word_nosc":"valse","lemma":"valser","pos":"VER"} ,
		{"word":"valsent","word_nosc":"valsent","lemma":"valser","pos":"VER"} ,
		{"word":"valser","word_nosc":"valser","lemma":"valser","pos":"VER"} ,
		{"word":"valsera","word_nosc":"valsera","lemma":"valser","pos":"VER"} ,
		{"word":"valses","word_nosc":"valses","lemma":"valser","pos":"VER"} ,
		{"word":"valsez","word_nosc":"valsez","lemma":"valser","pos":"VER"} ,
		{"word":"valsons","word_nosc":"valsons","lemma":"valser","pos":"VER"} ,
		{"word":"valsèrent","word_nosc":"valserent","lemma":"valser","pos":"VER"} ,
		{"word":"valsé","word_nosc":"valse","lemma":"valser","pos":"VER"} ,
		{"word":"valu","word_nosc":"valu","lemma":"valoir","pos":"VER"} ,
		{"word":"value","word_nosc":"value","lemma":"valoir","pos":"VER"} ,
		{"word":"values","word_nosc":"values","lemma":"valoir","pos":"VER"} ,
		{"word":"valurent","word_nosc":"valurent","lemma":"valoir","pos":"VER"} ,
		{"word":"valut","word_nosc":"valut","lemma":"valoir","pos":"VER"} ,
		{"word":"valût","word_nosc":"valut","lemma":"valoir","pos":"VER"} ,
		{"word":"vampe","word_nosc":"vampe","lemma":"vamper","pos":"VER"} ,
		{"word":"vamper","word_nosc":"vamper","lemma":"vamper","pos":"VER"} ,
		{"word":"vampirisant","word_nosc":"vampirisant","lemma":"vampiriser","pos":"VER"} ,
		{"word":"vampirise","word_nosc":"vampirise","lemma":"vampiriser","pos":"VER"} ,
		{"word":"vampirisé","word_nosc":"vampirise","lemma":"vampiriser","pos":"VER"} ,
		{"word":"vampirisée","word_nosc":"vampirisee","lemma":"vampiriser","pos":"VER"} ,
		{"word":"vampirisées","word_nosc":"vampirisees","lemma":"vampiriser","pos":"VER"} ,
		{"word":"vampé","word_nosc":"vampe","lemma":"vamper","pos":"VER"} ,
		{"word":"vandalisent","word_nosc":"vandalisent","lemma":"vandaliser","pos":"VER"} ,
		{"word":"vandaliser","word_nosc":"vandaliser","lemma":"vandaliser","pos":"VER"} ,
		{"word":"vandalisé","word_nosc":"vandalise","lemma":"vandaliser","pos":"VER"} ,
		{"word":"vanna","word_nosc":"vanna","lemma":"vanner","pos":"VER"} ,
		{"word":"vannais","word_nosc":"vannais","lemma":"vanner","pos":"VER"} ,
		{"word":"vannait","word_nosc":"vannait","lemma":"vanner","pos":"VER"} ,
		{"word":"vanne","word_nosc":"vanne","lemma":"vanner","pos":"VER"} ,
		{"word":"vannent","word_nosc":"vannent","lemma":"vanner","pos":"VER"} ,
		{"word":"vanner","word_nosc":"vanner","lemma":"vanner","pos":"VER"} ,
		{"word":"vannes","word_nosc":"vannes","lemma":"vanner","pos":"VER"} ,
		{"word":"vannèrent","word_nosc":"vannerent","lemma":"vanner","pos":"VER"} ,
		{"word":"vanné","word_nosc":"vanne","lemma":"vanner","pos":"VER"} ,
		{"word":"vannée","word_nosc":"vannee","lemma":"vanner","pos":"VER"} ,
		{"word":"vannés","word_nosc":"vannes","lemma":"vanner","pos":"VER"} ,
		{"word":"vanta","word_nosc":"vanta","lemma":"vanter","pos":"VER"} ,
		{"word":"vantai","word_nosc":"vantai","lemma":"vanter","pos":"VER"} ,
		{"word":"vantaient","word_nosc":"vantaient","lemma":"vanter","pos":"VER"} ,
		{"word":"vantais","word_nosc":"vantais","lemma":"vanter","pos":"VER"} ,
		{"word":"vantait","word_nosc":"vantait","lemma":"vanter","pos":"VER"} ,
		{"word":"vantant","word_nosc":"vantant","lemma":"vanter","pos":"VER"} ,
		{"word":"vante","word_nosc":"vante","lemma":"vanter","pos":"VER"} ,
		{"word":"vantent","word_nosc":"vantent","lemma":"vanter","pos":"VER"} ,
		{"word":"vanter","word_nosc":"vanter","lemma":"vanter","pos":"VER"} ,
		{"word":"vantera","word_nosc":"vantera","lemma":"vanter","pos":"VER"} ,
		{"word":"vanterai","word_nosc":"vanterai","lemma":"vanter","pos":"VER"} ,
		{"word":"vanterais","word_nosc":"vanterais","lemma":"vanter","pos":"VER"} ,
		{"word":"vanterait","word_nosc":"vanterait","lemma":"vanter","pos":"VER"} ,
		{"word":"vanteras","word_nosc":"vanteras","lemma":"vanter","pos":"VER"} ,
		{"word":"vanteront","word_nosc":"vanteront","lemma":"vanter","pos":"VER"} ,
		{"word":"vantes","word_nosc":"vantes","lemma":"vanter","pos":"VER"} ,
		{"word":"vantez","word_nosc":"vantez","lemma":"vanter","pos":"VER"} ,
		{"word":"vantiez","word_nosc":"vantiez","lemma":"vanter","pos":"VER"} ,
		{"word":"vantions","word_nosc":"vantions","lemma":"vanter","pos":"VER"} ,
		{"word":"vantons","word_nosc":"vantons","lemma":"vanter","pos":"VER"} ,
		{"word":"vantât","word_nosc":"vantat","lemma":"vanter","pos":"VER"} ,
		{"word":"vanté","word_nosc":"vante","lemma":"vanter","pos":"VER"} ,
		{"word":"vantée","word_nosc":"vantee","lemma":"vanter","pos":"VER"} ,
		{"word":"vantées","word_nosc":"vantees","lemma":"vanter","pos":"VER"} ,
		{"word":"vantés","word_nosc":"vantes","lemma":"vanter","pos":"VER"} ,
		{"word":"vaporisa","word_nosc":"vaporisa","lemma":"vaporiser","pos":"VER"} ,
		{"word":"vaporisais","word_nosc":"vaporisais","lemma":"vaporiser","pos":"VER"} ,
		{"word":"vaporisait","word_nosc":"vaporisait","lemma":"vaporiser","pos":"VER"} ,
		{"word":"vaporisant","word_nosc":"vaporisant","lemma":"vaporiser","pos":"VER"} ,
		{"word":"vaporise","word_nosc":"vaporise","lemma":"vaporiser","pos":"VER"} ,
		{"word":"vaporiser","word_nosc":"vaporiser","lemma":"vaporiser","pos":"VER"} ,
		{"word":"vaporiserait","word_nosc":"vaporiserait","lemma":"vaporiser","pos":"VER"} ,
		{"word":"vaporises","word_nosc":"vaporises","lemma":"vaporiser","pos":"VER"} ,
		{"word":"vaporisez","word_nosc":"vaporisez","lemma":"vaporiser","pos":"VER"} ,
		{"word":"vaporisé","word_nosc":"vaporise","lemma":"vaporiser","pos":"VER"} ,
		{"word":"vaporisée","word_nosc":"vaporisee","lemma":"vaporiser","pos":"VER"} ,
		{"word":"vaquai","word_nosc":"vaquai","lemma":"vaquer","pos":"VER"} ,
		{"word":"vaquaient","word_nosc":"vaquaient","lemma":"vaquer","pos":"VER"} ,
		{"word":"vaquais","word_nosc":"vaquais","lemma":"vaquer","pos":"VER"} ,
		{"word":"vaquait","word_nosc":"vaquait","lemma":"vaquer","pos":"VER"} ,
		{"word":"vaquant","word_nosc":"vaquant","lemma":"vaquer","pos":"VER"} ,
		{"word":"vaque","word_nosc":"vaque","lemma":"vaquer","pos":"VER"} ,
		{"word":"vaquent","word_nosc":"vaquent","lemma":"vaquer","pos":"VER"} ,
		{"word":"vaquer","word_nosc":"vaquer","lemma":"vaquer","pos":"VER"} ,
		{"word":"vaquerait","word_nosc":"vaquerait","lemma":"vaquer","pos":"VER"} ,
		{"word":"vaquions","word_nosc":"vaquions","lemma":"vaquer","pos":"VER"} ,
		{"word":"vaquons","word_nosc":"vaquons","lemma":"vaquer","pos":"VER"} ,
		{"word":"vaqué","word_nosc":"vaque","lemma":"vaquer","pos":"VER"} ,
		{"word":"varapper","word_nosc":"varapper","lemma":"varapper","pos":"VER"} ,
		{"word":"variaient","word_nosc":"variaient","lemma":"varier","pos":"VER"} ,
		{"word":"variais","word_nosc":"variais","lemma":"varier","pos":"VER"} ,
		{"word":"variait","word_nosc":"variait","lemma":"varier","pos":"VER"} ,
		{"word":"variant","word_nosc":"variant","lemma":"varier","pos":"VER"} ,
		{"word":"varie","word_nosc":"varie","lemma":"varier","pos":"VER"} ,
		{"word":"varient","word_nosc":"varient","lemma":"varier","pos":"VER"} ,
		{"word":"varier","word_nosc":"varier","lemma":"varier","pos":"VER"} ,
		{"word":"varierai","word_nosc":"varierai","lemma":"varier","pos":"VER"} ,
		{"word":"varieraient","word_nosc":"varieraient","lemma":"varier","pos":"VER"} ,
		{"word":"varierait","word_nosc":"varierait","lemma":"varier","pos":"VER"} ,
		{"word":"varieront","word_nosc":"varieront","lemma":"varier","pos":"VER"} ,
		{"word":"variât","word_nosc":"variat","lemma":"varier","pos":"VER"} ,
		{"word":"varié","word_nosc":"varie","lemma":"varier","pos":"VER"} ,
		{"word":"variée","word_nosc":"variee","lemma":"varier","pos":"VER"} ,
		{"word":"variées","word_nosc":"variees","lemma":"varier","pos":"VER"} ,
		{"word":"variés","word_nosc":"varies","lemma":"varier","pos":"VER"} ,
		{"word":"vas","word_nosc":"vas","lemma":"aller","pos":"VER"} ,
		{"word":"vasa","word_nosc":"vasa","lemma":"vaser","pos":"VER"} ,
		{"word":"vasait","word_nosc":"vasait","lemma":"vaser","pos":"VER"} ,
		{"word":"vaseliner","word_nosc":"vaseliner","lemma":"vaseliner","pos":"VER"} ,
		{"word":"vaselinez","word_nosc":"vaselinez","lemma":"vaseliner","pos":"VER"} ,
		{"word":"vaseliné","word_nosc":"vaseline","lemma":"vaseliner","pos":"VER"} ,
		{"word":"vaser","word_nosc":"vaser","lemma":"vaser","pos":"VER"} ,
		{"word":"vasouille","word_nosc":"vasouille","lemma":"vasouiller","pos":"VER"} ,
		{"word":"vasouiller","word_nosc":"vasouiller","lemma":"vasouiller","pos":"VER"} ,
		{"word":"vasouilles","word_nosc":"vasouilles","lemma":"vasouiller","pos":"VER"} ,
		{"word":"vaticinait","word_nosc":"vaticinait","lemma":"vaticiner","pos":"VER"} ,
		{"word":"vaticinant","word_nosc":"vaticinant","lemma":"vaticiner","pos":"VER"} ,
		{"word":"vaticine","word_nosc":"vaticine","lemma":"vaticiner","pos":"VER"} ,
		{"word":"vaticiner","word_nosc":"vaticiner","lemma":"vaticiner","pos":"VER"} ,
		{"word":"vaudra","word_nosc":"vaudra","lemma":"valoir","pos":"VER"} ,
		{"word":"vaudrai","word_nosc":"vaudrai","lemma":"valoir","pos":"VER"} ,
		{"word":"vaudraient","word_nosc":"vaudraient","lemma":"valoir","pos":"VER"} ,
		{"word":"vaudrais","word_nosc":"vaudrais","lemma":"valoir","pos":"VER"} ,
		{"word":"vaudrait","word_nosc":"vaudrait","lemma":"valoir","pos":"VER"} ,
		{"word":"vaudras","word_nosc":"vaudras","lemma":"valoir","pos":"VER"} ,
		{"word":"vaudrez","word_nosc":"vaudrez","lemma":"valoir","pos":"VER"} ,
		{"word":"vaudrions","word_nosc":"vaudrions","lemma":"valoir","pos":"VER"} ,
		{"word":"vaudrons","word_nosc":"vaudrons","lemma":"valoir","pos":"VER"} ,
		{"word":"vaudront","word_nosc":"vaudront","lemma":"valoir","pos":"VER"} ,
		{"word":"vaut","word_nosc":"vaut","lemma":"valoir","pos":"VER"} ,
		{"word":"vautra","word_nosc":"vautra","lemma":"vautrer","pos":"VER"} ,
		{"word":"vautraient","word_nosc":"vautraient","lemma":"vautrer","pos":"VER"} ,
		{"word":"vautrais","word_nosc":"vautrais","lemma":"vautrer","pos":"VER"} ,
		{"word":"vautrait","word_nosc":"vautrait","lemma":"vautrer","pos":"VER"} ,
		{"word":"vautrant","word_nosc":"vautrant","lemma":"vautrer","pos":"VER"} ,
		{"word":"vautre","word_nosc":"vautre","lemma":"vautrer","pos":"VER"} ,
		{"word":"vautrent","word_nosc":"vautrent","lemma":"vautrer","pos":"VER"} ,
		{"word":"vautrer","word_nosc":"vautrer","lemma":"vautrer","pos":"VER"} ,
		{"word":"vautreraient","word_nosc":"vautreraient","lemma":"vautrer","pos":"VER"} ,
		{"word":"vautrerais","word_nosc":"vautrerais","lemma":"vautrer","pos":"VER"} ,
		{"word":"vautrez","word_nosc":"vautrez","lemma":"vautrer","pos":"VER"} ,
		{"word":"vautrèrent","word_nosc":"vautrerent","lemma":"vautrer","pos":"VER"} ,
		{"word":"vautré","word_nosc":"vautre","lemma":"vautrer","pos":"VER"} ,
		{"word":"vautrée","word_nosc":"vautree","lemma":"vautrer","pos":"VER"} ,
		{"word":"vautrées","word_nosc":"vautrees","lemma":"vautrer","pos":"VER"} ,
		{"word":"vautrés","word_nosc":"vautres","lemma":"vautrer","pos":"VER"} ,
		{"word":"vaux","word_nosc":"vaux","lemma":"valoir","pos":"VER"} ,
		{"word":"veilla","word_nosc":"veilla","lemma":"veiller","pos":"VER"} ,
		{"word":"veillai","word_nosc":"veillai","lemma":"veiller","pos":"VER"} ,
		{"word":"veillaient","word_nosc":"veillaient","lemma":"veiller","pos":"VER"} ,
		{"word":"veillais","word_nosc":"veillais","lemma":"veiller","pos":"VER"} ,
		{"word":"veillait","word_nosc":"veillait","lemma":"veiller","pos":"VER"} ,
		{"word":"veillant","word_nosc":"veillant","lemma":"veiller","pos":"VER"} ,
		{"word":"veille","word_nosc":"veille","lemma":"veiller","pos":"VER"} ,
		{"word":"veillent","word_nosc":"veillent","lemma":"veiller","pos":"VER"} ,
		{"word":"veiller","word_nosc":"veiller","lemma":"veiller","pos":"VER"} ,
		{"word":"veillera","word_nosc":"veillera","lemma":"veiller","pos":"VER"} ,
		{"word":"veillerai","word_nosc":"veillerai","lemma":"veiller","pos":"VER"} ,
		{"word":"veillerais","word_nosc":"veillerais","lemma":"veiller","pos":"VER"} ,
		{"word":"veillerait","word_nosc":"veillerait","lemma":"veiller","pos":"VER"} ,
		{"word":"veilleras","word_nosc":"veilleras","lemma":"veiller","pos":"VER"} ,
		{"word":"veillerez","word_nosc":"veillerez","lemma":"veiller","pos":"VER"} ,
		{"word":"veillerons","word_nosc":"veillerons","lemma":"veiller","pos":"VER"} ,
		{"word":"veilleront","word_nosc":"veilleront","lemma":"veiller","pos":"VER"} ,
		{"word":"veilles","word_nosc":"veilles","lemma":"veiller","pos":"VER"} ,
		{"word":"veillez","word_nosc":"veillez","lemma":"veiller","pos":"VER"} ,
		{"word":"veillions","word_nosc":"veillions","lemma":"veiller","pos":"VER"} ,
		{"word":"veillons","word_nosc":"veillons","lemma":"veiller","pos":"VER"} ,
		{"word":"veillâmes","word_nosc":"veillames","lemma":"veiller","pos":"VER"} ,
		{"word":"veillât","word_nosc":"veillat","lemma":"veiller","pos":"VER"} ,
		{"word":"veillèrent","word_nosc":"veillerent","lemma":"veiller","pos":"VER"} ,
		{"word":"veillé","word_nosc":"veille","lemma":"veiller","pos":"VER"} ,
		{"word":"veillée","word_nosc":"veillee","lemma":"veiller","pos":"VER"} ,
		{"word":"veillés","word_nosc":"veilles","lemma":"veiller","pos":"VER"} ,
		{"word":"veinaient","word_nosc":"veinaient","lemma":"veiner","pos":"VER"} ,
		{"word":"veinait","word_nosc":"veinait","lemma":"veiner","pos":"VER"} ,
		{"word":"veinent","word_nosc":"veinent","lemma":"veiner","pos":"VER"} ,
		{"word":"veiné","word_nosc":"veine","lemma":"veiner","pos":"VER"} ,
		{"word":"veinée","word_nosc":"veinee","lemma":"veiner","pos":"VER"} ,
		{"word":"veinées","word_nosc":"veinees","lemma":"veiner","pos":"VER"} ,
		{"word":"veinés","word_nosc":"veines","lemma":"veiner","pos":"VER"} ,
		{"word":"veloutaient","word_nosc":"veloutaient","lemma":"velouter","pos":"VER"} ,
		{"word":"veloutait","word_nosc":"veloutait","lemma":"velouter","pos":"VER"} ,
		{"word":"veloute","word_nosc":"veloute","lemma":"velouter","pos":"VER"} ,
		{"word":"veloutent","word_nosc":"veloutent","lemma":"velouter","pos":"VER"} ,
		{"word":"velouter","word_nosc":"velouter","lemma":"velouter","pos":"VER"} ,
		{"word":"velouté","word_nosc":"veloute","lemma":"velouter","pos":"VER"} ,
		{"word":"veloutée","word_nosc":"veloutee","lemma":"velouter","pos":"VER"} ,
		{"word":"veloutées","word_nosc":"veloutees","lemma":"velouter","pos":"VER"} ,
		{"word":"venaient","word_nosc":"venaient","lemma":"venir","pos":"VER"} ,
		{"word":"venais","word_nosc":"venais","lemma":"venir","pos":"VER"} ,
		{"word":"venait","word_nosc":"venait","lemma":"venir","pos":"VER"} ,
		{"word":"venant","word_nosc":"venant","lemma":"venir","pos":"VER"} ,
		{"word":"vend","word_nosc":"vend","lemma":"vendre","pos":"VER"} ,
		{"word":"vendaient","word_nosc":"vendaient","lemma":"vendre","pos":"VER"} ,
		{"word":"vendais","word_nosc":"vendais","lemma":"vendre","pos":"VER"} ,
		{"word":"vendait","word_nosc":"vendait","lemma":"vendre","pos":"VER"} ,
		{"word":"vendange","word_nosc":"vendange","lemma":"vendanger","pos":"VER"} ,
		{"word":"vendangeait","word_nosc":"vendangeait","lemma":"vendanger","pos":"VER"} ,
		{"word":"vendanger","word_nosc":"vendanger","lemma":"vendanger","pos":"VER"} ,
		{"word":"vendanges","word_nosc":"vendanges","lemma":"vendanger","pos":"VER"} ,
		{"word":"vendangé","word_nosc":"vendange","lemma":"vendanger","pos":"VER"} ,
		{"word":"vendant","word_nosc":"vendant","lemma":"vendre","pos":"VER"} ,
		{"word":"vende","word_nosc":"vende","lemma":"vendre","pos":"VER"} ,
		{"word":"vendent","word_nosc":"vendent","lemma":"vendre","pos":"VER"} ,
		{"word":"vendes","word_nosc":"vendes","lemma":"vendre","pos":"VER"} ,
		{"word":"vendez","word_nosc":"vendez","lemma":"vendre","pos":"VER"} ,
		{"word":"vendiez","word_nosc":"vendiez","lemma":"vendre","pos":"VER"} ,
		{"word":"vendions","word_nosc":"vendions","lemma":"vendre","pos":"VER"} ,
		{"word":"vendirent","word_nosc":"vendirent","lemma":"vendre","pos":"VER"} ,
		{"word":"vendis","word_nosc":"vendis","lemma":"vendre","pos":"VER"} ,
		{"word":"vendisse","word_nosc":"vendisse","lemma":"vendre","pos":"VER"} ,
		{"word":"vendit","word_nosc":"vendit","lemma":"vendre","pos":"VER"} ,
		{"word":"vendons","word_nosc":"vendons","lemma":"vendre","pos":"VER"} ,
		{"word":"vendra","word_nosc":"vendra","lemma":"vendre","pos":"VER"} ,
		{"word":"vendrai","word_nosc":"vendrai","lemma":"vendre","pos":"VER"} ,
		{"word":"vendraient","word_nosc":"vendraient","lemma":"vendre","pos":"VER"} ,
		{"word":"vendrais","word_nosc":"vendrais","lemma":"vendre","pos":"VER"} ,
		{"word":"vendrait","word_nosc":"vendrait","lemma":"vendre","pos":"VER"} ,
		{"word":"vendras","word_nosc":"vendras","lemma":"vendre","pos":"VER"} ,
		{"word":"vendre","word_nosc":"vendre","lemma":"vendre","pos":"VER"} ,
		{"word":"vendrez","word_nosc":"vendrez","lemma":"vendre","pos":"VER"} ,
		{"word":"vendriez","word_nosc":"vendriez","lemma":"vendre","pos":"VER"} ,
		{"word":"vendrons","word_nosc":"vendrons","lemma":"vendre","pos":"VER"} ,
		{"word":"vendront","word_nosc":"vendront","lemma":"vendre","pos":"VER"} ,
		{"word":"vends","word_nosc":"vends","lemma":"vendre","pos":"VER"} ,
		{"word":"vendu","word_nosc":"vendu","lemma":"vendre","pos":"VER"} ,
		{"word":"vendue","word_nosc":"vendue","lemma":"vendre","pos":"VER"} ,
		{"word":"vendues","word_nosc":"vendues","lemma":"vendre","pos":"VER"} ,
		{"word":"vendus","word_nosc":"vendus","lemma":"vendre","pos":"VER"} ,
		{"word":"vendît","word_nosc":"vendit","lemma":"vendre","pos":"VER"} ,
		{"word":"venez","word_nosc":"venez","lemma":"venir","pos":"VER"} ,
		{"word":"venge","word_nosc":"venge","lemma":"venger","pos":"VER"} ,
		{"word":"vengea","word_nosc":"vengea","lemma":"venger","pos":"VER"} ,
		{"word":"vengeai","word_nosc":"vengeai","lemma":"venger","pos":"VER"} ,
		{"word":"vengeaient","word_nosc":"vengeaient","lemma":"venger","pos":"VER"} ,
		{"word":"vengeais","word_nosc":"vengeais","lemma":"venger","pos":"VER"} ,
		{"word":"vengeait","word_nosc":"vengeait","lemma":"venger","pos":"VER"} ,
		{"word":"vengeant","word_nosc":"vengeant","lemma":"venger","pos":"VER"} ,
		{"word":"vengent","word_nosc":"vengent","lemma":"venger","pos":"VER"} ,
		{"word":"vengeons","word_nosc":"vengeons","lemma":"venger","pos":"VER"} ,
		{"word":"venger","word_nosc":"venger","lemma":"venger","pos":"VER"} ,
		{"word":"vengera","word_nosc":"vengera","lemma":"venger","pos":"VER"} ,
		{"word":"vengerai","word_nosc":"vengerai","lemma":"venger","pos":"VER"} ,
		{"word":"vengerais","word_nosc":"vengerais","lemma":"venger","pos":"VER"} ,
		{"word":"vengerait","word_nosc":"vengerait","lemma":"venger","pos":"VER"} ,
		{"word":"vengerez","word_nosc":"vengerez","lemma":"venger","pos":"VER"} ,
		{"word":"vengerons","word_nosc":"vengerons","lemma":"venger","pos":"VER"} ,
		{"word":"vengeront","word_nosc":"vengeront","lemma":"venger","pos":"VER"} ,
		{"word":"venges","word_nosc":"venges","lemma":"venger","pos":"VER"} ,
		{"word":"vengez","word_nosc":"vengez","lemma":"venger","pos":"VER"} ,
		{"word":"vengeât","word_nosc":"vengeat","lemma":"venger","pos":"VER"} ,
		{"word":"vengiez","word_nosc":"vengiez","lemma":"venger","pos":"VER"} ,
		{"word":"vengèrent","word_nosc":"vengerent","lemma":"venger","pos":"VER"} ,
		{"word":"vengé","word_nosc":"venge","lemma":"venger","pos":"VER"} ,
		{"word":"vengée","word_nosc":"vengee","lemma":"venger","pos":"VER"} ,
		{"word":"vengées","word_nosc":"vengees","lemma":"venger","pos":"VER"} ,
		{"word":"vengés","word_nosc":"venges","lemma":"venger","pos":"VER"} ,
		{"word":"veniez","word_nosc":"veniez","lemma":"venir","pos":"VER"} ,
		{"word":"venions","word_nosc":"venions","lemma":"venir","pos":"VER"} ,
		{"word":"venir","word_nosc":"venir","lemma":"venir","pos":"VER"} ,
		{"word":"venons","word_nosc":"venons","lemma":"venir","pos":"VER"} ,
		{"word":"venta","word_nosc":"venta","lemma":"venter","pos":"VER"} ,
		{"word":"ventas","word_nosc":"ventas","lemma":"venter","pos":"VER"} ,
		{"word":"vente","word_nosc":"vente","lemma":"venter","pos":"VER"} ,
		{"word":"venter","word_nosc":"venter","lemma":"venter","pos":"VER"} ,
		{"word":"ventilant","word_nosc":"ventilant","lemma":"ventiler","pos":"VER"} ,
		{"word":"ventile","word_nosc":"ventile","lemma":"ventiler","pos":"VER"} ,
		{"word":"ventiler","word_nosc":"ventiler","lemma":"ventiler","pos":"VER"} ,
		{"word":"ventilez","word_nosc":"ventilez","lemma":"ventiler","pos":"VER"} ,
		{"word":"ventilé","word_nosc":"ventile","lemma":"ventiler","pos":"VER"} ,
		{"word":"ventilée","word_nosc":"ventilee","lemma":"ventiler","pos":"VER"} ,
		{"word":"ventilés","word_nosc":"ventiles","lemma":"ventiler","pos":"VER"} ,
		{"word":"ventousent","word_nosc":"ventousent","lemma":"ventouser","pos":"VER"} ,
		{"word":"ventousé","word_nosc":"ventouse","lemma":"ventouser","pos":"VER"} ,
		{"word":"ventousée","word_nosc":"ventousee","lemma":"ventouser","pos":"VER"} ,
		{"word":"ventés","word_nosc":"ventes","lemma":"venter","pos":"VER"} ,
		{"word":"venu","word_nosc":"venu","lemma":"venir","pos":"VER"} ,
		{"word":"venue","word_nosc":"venue","lemma":"venir","pos":"VER"} ,
		{"word":"venues","word_nosc":"venues","lemma":"venir","pos":"VER"} ,
		{"word":"venus","word_nosc":"venus","lemma":"venir","pos":"VER"} ,
		{"word":"verbalise","word_nosc":"verbalise","lemma":"verbaliser","pos":"VER"} ,
		{"word":"verbaliser","word_nosc":"verbaliser","lemma":"verbaliser","pos":"VER"} ,
		{"word":"verbalisez","word_nosc":"verbalisez","lemma":"verbaliser","pos":"VER"} ,
		{"word":"verbalisé","word_nosc":"verbalise","lemma":"verbaliser","pos":"VER"} ,
		{"word":"verbalisée","word_nosc":"verbalisee","lemma":"verbaliser","pos":"VER"} ,
		{"word":"verdi","word_nosc":"verdi","lemma":"verdir","pos":"VER"} ,
		{"word":"verdie","word_nosc":"verdie","lemma":"verdir","pos":"VER"} ,
		{"word":"verdies","word_nosc":"verdies","lemma":"verdir","pos":"VER"} ,
		{"word":"verdir","word_nosc":"verdir","lemma":"verdir","pos":"VER"} ,
		{"word":"verdis","word_nosc":"verdis","lemma":"verdir","pos":"VER"} ,
		{"word":"verdissaient","word_nosc":"verdissaient","lemma":"verdir","pos":"VER"} ,
		{"word":"verdissait","word_nosc":"verdissait","lemma":"verdir","pos":"VER"} ,
		{"word":"verdissent","word_nosc":"verdissent","lemma":"verdir","pos":"VER"} ,
		{"word":"verdit","word_nosc":"verdit","lemma":"verdir","pos":"VER"} ,
		{"word":"verdoie","word_nosc":"verdoie","lemma":"verdoyer","pos":"VER"} ,
		{"word":"verdoient","word_nosc":"verdoient","lemma":"verdoyer","pos":"VER"} ,
		{"word":"verdoyant","word_nosc":"verdoyant","lemma":"verdoyer","pos":"VER"} ,
		{"word":"verdoyer","word_nosc":"verdoyer","lemma":"verdoyer","pos":"VER"} ,
		{"word":"verglacé","word_nosc":"verglace","lemma":"verglacer","pos":"VER"} ,
		{"word":"verglacée","word_nosc":"verglacee","lemma":"verglacer","pos":"VER"} ,
		{"word":"verglacées","word_nosc":"verglacees","lemma":"verglacer","pos":"VER"} ,
		{"word":"vermillonnait","word_nosc":"vermillonnait","lemma":"vermillonner","pos":"VER"} ,
		{"word":"vermillonnée","word_nosc":"vermillonnee","lemma":"vermillonner","pos":"VER"} ,
		{"word":"vermillonnés","word_nosc":"vermillonnes","lemma":"vermillonner","pos":"VER"} ,
		{"word":"verni","word_nosc":"verni","lemma":"vernir","pos":"VER"} ,
		{"word":"vernie","word_nosc":"vernie","lemma":"vernir","pos":"VER"} ,
		{"word":"vernies","word_nosc":"vernies","lemma":"vernir","pos":"VER"} ,
		{"word":"vernir","word_nosc":"vernir","lemma":"vernir","pos":"VER"} ,
		{"word":"vernis","word_nosc":"vernis","lemma":"vernir","pos":"VER"} ,
		{"word":"vernissaient","word_nosc":"vernissaient","lemma":"vernir","pos":"VER"} ,
		{"word":"vernissait","word_nosc":"vernissait","lemma":"vernir","pos":"VER"} ,
		{"word":"vernissant","word_nosc":"vernissant","lemma":"vernir","pos":"VER"} ,
		{"word":"vernissent","word_nosc":"vernissent","lemma":"vernir","pos":"VER"} ,
		{"word":"vernisser","word_nosc":"vernisser","lemma":"vernisser","pos":"VER"} ,
		{"word":"vernissé","word_nosc":"vernisse","lemma":"vernisser","pos":"VER"} ,
		{"word":"vernissée","word_nosc":"vernissee","lemma":"vernisser","pos":"VER"} ,
		{"word":"vernissées","word_nosc":"vernissees","lemma":"vernisser","pos":"VER"} ,
		{"word":"vernissés","word_nosc":"vernisses","lemma":"vernisser","pos":"VER"} ,
		{"word":"vernit","word_nosc":"vernit","lemma":"vernir","pos":"VER"} ,
		{"word":"verra","word_nosc":"verra","lemma":"voir","pos":"VER"} ,
		{"word":"verrai","word_nosc":"verrai","lemma":"voir","pos":"VER"} ,
		{"word":"verraient","word_nosc":"verraient","lemma":"voir","pos":"VER"} ,
		{"word":"verrais","word_nosc":"verrais","lemma":"voir","pos":"VER"} ,
		{"word":"verrait","word_nosc":"verrait","lemma":"voir","pos":"VER"} ,
		{"word":"verras","word_nosc":"verras","lemma":"voir","pos":"VER"} ,
		{"word":"verrez","word_nosc":"verrez","lemma":"voir","pos":"VER"} ,
		{"word":"verriez","word_nosc":"verriez","lemma":"voir","pos":"VER"} ,
		{"word":"verrions","word_nosc":"verrions","lemma":"voir","pos":"VER"} ,
		{"word":"verrons","word_nosc":"verrons","lemma":"voir","pos":"VER"} ,
		{"word":"verront","word_nosc":"verront","lemma":"voir","pos":"VER"} ,
		{"word":"verrouilla","word_nosc":"verrouilla","lemma":"verrouiller","pos":"VER"} ,
		{"word":"verrouillai","word_nosc":"verrouillai","lemma":"verrouiller","pos":"VER"} ,
		{"word":"verrouillait","word_nosc":"verrouillait","lemma":"verrouiller","pos":"VER"} ,
		{"word":"verrouillant","word_nosc":"verrouillant","lemma":"verrouiller","pos":"VER"} ,
		{"word":"verrouille","word_nosc":"verrouille","lemma":"verrouiller","pos":"VER"} ,
		{"word":"verrouillent","word_nosc":"verrouillent","lemma":"verrouiller","pos":"VER"} ,
		{"word":"verrouiller","word_nosc":"verrouiller","lemma":"verrouiller","pos":"VER"} ,
		{"word":"verrouilles","word_nosc":"verrouilles","lemma":"verrouiller","pos":"VER"} ,
		{"word":"verrouillez","word_nosc":"verrouillez","lemma":"verrouiller","pos":"VER"} ,
		{"word":"verrouillons","word_nosc":"verrouillons","lemma":"verrouiller","pos":"VER"} ,
		{"word":"verrouillèrent","word_nosc":"verrouillerent","lemma":"verrouiller","pos":"VER"} ,
		{"word":"verrouillé","word_nosc":"verrouille","lemma":"verrouiller","pos":"VER"} ,
		{"word":"verrouillée","word_nosc":"verrouillee","lemma":"verrouiller","pos":"VER"} ,
		{"word":"verrouillées","word_nosc":"verrouillees","lemma":"verrouiller","pos":"VER"} ,
		{"word":"verrouillés","word_nosc":"verrouilles","lemma":"verrouiller","pos":"VER"} ,
		{"word":"versa","word_nosc":"versa","lemma":"verser","pos":"VER"} ,
		{"word":"versai","word_nosc":"versai","lemma":"verser","pos":"VER"} ,
		{"word":"versaient","word_nosc":"versaient","lemma":"verser","pos":"VER"} ,
		{"word":"versais","word_nosc":"versais","lemma":"verser","pos":"VER"} ,
		{"word":"versait","word_nosc":"versait","lemma":"verser","pos":"VER"} ,
		{"word":"versant","word_nosc":"versant","lemma":"verser","pos":"VER"} ,
		{"word":"verse","word_nosc":"verse","lemma":"verser","pos":"VER"} ,
		{"word":"versent","word_nosc":"versent","lemma":"verser","pos":"VER"} ,
		{"word":"verser","word_nosc":"verser","lemma":"verser","pos":"VER"} ,
		{"word":"versera","word_nosc":"versera","lemma":"verser","pos":"VER"} ,
		{"word":"verserai","word_nosc":"verserai","lemma":"verser","pos":"VER"} ,
		{"word":"verserais","word_nosc":"verserais","lemma":"verser","pos":"VER"} ,
		{"word":"verserait","word_nosc":"verserait","lemma":"verser","pos":"VER"} ,
		{"word":"verseras","word_nosc":"verseras","lemma":"verser","pos":"VER"} ,
		{"word":"verserez","word_nosc":"verserez","lemma":"verser","pos":"VER"} ,
		{"word":"verseriez","word_nosc":"verseriez","lemma":"verser","pos":"VER"} ,
		{"word":"verserons","word_nosc":"verserons","lemma":"verser","pos":"VER"} ,
		{"word":"verseront","word_nosc":"verseront","lemma":"verser","pos":"VER"} ,
		{"word":"verses","word_nosc":"verses","lemma":"verser","pos":"VER"} ,
		{"word":"versez","word_nosc":"versez","lemma":"verser","pos":"VER"} ,
		{"word":"versiez","word_nosc":"versiez","lemma":"verser","pos":"VER"} ,
		{"word":"versifier","word_nosc":"versifier","lemma":"versifier","pos":"VER"} ,
		{"word":"versions","word_nosc":"versions","lemma":"verser","pos":"VER"} ,
		{"word":"versons","word_nosc":"versons","lemma":"verser","pos":"VER"} ,
		{"word":"versâmes","word_nosc":"versames","lemma":"verser","pos":"VER"} ,
		{"word":"versât","word_nosc":"versat","lemma":"verser","pos":"VER"} ,
		{"word":"versèrent","word_nosc":"verserent","lemma":"verser","pos":"VER"} ,
		{"word":"versé","word_nosc":"verse","lemma":"verser","pos":"VER"} ,
		{"word":"versée","word_nosc":"versee","lemma":"verser","pos":"VER"} ,
		{"word":"versées","word_nosc":"versees","lemma":"verser","pos":"VER"} ,
		{"word":"versés","word_nosc":"verses","lemma":"verser","pos":"VER"} ,
		{"word":"veuille","word_nosc":"veuille","lemma":"vouloir","pos":"VER"} ,
		{"word":"veuillent","word_nosc":"veuillent","lemma":"vouloir","pos":"VER"} ,
		{"word":"veuilles","word_nosc":"veuilles","lemma":"vouloir","pos":"VER"} ,
		{"word":"veuillez","word_nosc":"veuillez","lemma":"vouloir","pos":"VER"} ,
		{"word":"veulent","word_nosc":"veulent","lemma":"vouloir","pos":"VER"} ,
		{"word":"veut","word_nosc":"veut","lemma":"vouloir","pos":"VER"} ,
		{"word":"veux","word_nosc":"veux","lemma":"vouloir","pos":"VER"} ,
		{"word":"vexa","word_nosc":"vexa","lemma":"vexer","pos":"VER"} ,
		{"word":"vexai","word_nosc":"vexai","lemma":"vexer","pos":"VER"} ,
		{"word":"vexait","word_nosc":"vexait","lemma":"vexer","pos":"VER"} ,
		{"word":"vexant","word_nosc":"vexant","lemma":"vexer","pos":"VER"} ,
		{"word":"vexe","word_nosc":"vexe","lemma":"vexer","pos":"VER"} ,
		{"word":"vexent","word_nosc":"vexent","lemma":"vexer","pos":"VER"} ,
		{"word":"vexer","word_nosc":"vexer","lemma":"vexer","pos":"VER"} ,
		{"word":"vexera","word_nosc":"vexera","lemma":"vexer","pos":"VER"} ,
		{"word":"vexerai","word_nosc":"vexerai","lemma":"vexer","pos":"VER"} ,
		{"word":"vexerait","word_nosc":"vexerait","lemma":"vexer","pos":"VER"} ,
		{"word":"vexeras","word_nosc":"vexeras","lemma":"vexer","pos":"VER"} ,
		{"word":"vexerez","word_nosc":"vexerez","lemma":"vexer","pos":"VER"} ,
		{"word":"vexerions","word_nosc":"vexerions","lemma":"vexer","pos":"VER"} ,
		{"word":"vexes","word_nosc":"vexes","lemma":"vexer","pos":"VER"} ,
		{"word":"vexez","word_nosc":"vexez","lemma":"vexer","pos":"VER"} ,
		{"word":"vexons","word_nosc":"vexons","lemma":"vexer","pos":"VER"} ,
		{"word":"vexé","word_nosc":"vexe","lemma":"vexer","pos":"VER"} ,
		{"word":"vexée","word_nosc":"vexee","lemma":"vexer","pos":"VER"} ,
		{"word":"vexées","word_nosc":"vexees","lemma":"vexer","pos":"VER"} ,
		{"word":"vexés","word_nosc":"vexes","lemma":"vexer","pos":"VER"} ,
		{"word":"viandasse","word_nosc":"viandasse","lemma":"viander","pos":"VER"} ,
		{"word":"viander","word_nosc":"viander","lemma":"viander","pos":"VER"} ,
		{"word":"viandé","word_nosc":"viande","lemma":"viander","pos":"VER"} ,
		{"word":"vibra","word_nosc":"vibra","lemma":"vibrer","pos":"VER"} ,
		{"word":"vibraient","word_nosc":"vibraient","lemma":"vibrer","pos":"VER"} ,
		{"word":"vibrais","word_nosc":"vibrais","lemma":"vibrer","pos":"VER"} ,
		{"word":"vibrait","word_nosc":"vibrait","lemma":"vibrer","pos":"VER"} ,
		{"word":"vibrant","word_nosc":"vibrant","lemma":"vibrer","pos":"VER"} ,
		{"word":"vibre","word_nosc":"vibre","lemma":"vibrer","pos":"VER"} ,
		{"word":"vibrent","word_nosc":"vibrent","lemma":"vibrer","pos":"VER"} ,
		{"word":"vibrer","word_nosc":"vibrer","lemma":"vibrer","pos":"VER"} ,
		{"word":"vibrera","word_nosc":"vibrera","lemma":"vibrer","pos":"VER"} ,
		{"word":"vibrez","word_nosc":"vibrez","lemma":"vibrer","pos":"VER"} ,
		{"word":"vibrionnaient","word_nosc":"vibrionnaient","lemma":"vibrionner","pos":"VER"} ,
		{"word":"vibrionnait","word_nosc":"vibrionnait","lemma":"vibrionner","pos":"VER"} ,
		{"word":"vibrionnant","word_nosc":"vibrionnant","lemma":"vibrionner","pos":"VER"} ,
		{"word":"vibrionne","word_nosc":"vibrionne","lemma":"vibrionner","pos":"VER"} ,
		{"word":"vibré","word_nosc":"vibre","lemma":"vibrer","pos":"VER"} ,
		{"word":"vibrée","word_nosc":"vibree","lemma":"vibrer","pos":"VER"} ,
		{"word":"vibrées","word_nosc":"vibrees","lemma":"vibrer","pos":"VER"} ,
		{"word":"vibura","word_nosc":"vibura","lemma":"viburer","pos":"VER"} ,
		{"word":"viburait","word_nosc":"viburait","lemma":"viburer","pos":"VER"} ,
		{"word":"vibure","word_nosc":"vibure","lemma":"viburer","pos":"VER"} ,
		{"word":"vicier","word_nosc":"vicier","lemma":"vicier","pos":"VER"} ,
		{"word":"vicié","word_nosc":"vicie","lemma":"vicier","pos":"VER"} ,
		{"word":"viciée","word_nosc":"viciee","lemma":"vicier","pos":"VER"} ,
		{"word":"vida","word_nosc":"vida","lemma":"vider","pos":"VER"} ,
		{"word":"vidai","word_nosc":"vidai","lemma":"vider","pos":"VER"} ,
		{"word":"vidaient","word_nosc":"vidaient","lemma":"vider","pos":"VER"} ,
		{"word":"vidais","word_nosc":"vidais","lemma":"vider","pos":"VER"} ,
		{"word":"vidait","word_nosc":"vidait","lemma":"vider","pos":"VER"} ,
		{"word":"vidange","word_nosc":"vidange","lemma":"vidanger","pos":"VER"} ,
		{"word":"vidanger","word_nosc":"vidanger","lemma":"vidanger","pos":"VER"} ,
		{"word":"vidangez","word_nosc":"vidangez","lemma":"vidanger","pos":"VER"} ,
		{"word":"vidangé","word_nosc":"vidange","lemma":"vidanger","pos":"VER"} ,
		{"word":"vidangée","word_nosc":"vidangee","lemma":"vidanger","pos":"VER"} ,
		{"word":"vidant","word_nosc":"vidant","lemma":"vider","pos":"VER"} ,
		{"word":"vidas","word_nosc":"vidas","lemma":"vider","pos":"VER"} ,
		{"word":"vide","word_nosc":"vide","lemma":"vider","pos":"VER"} ,
		{"word":"vident","word_nosc":"vident","lemma":"vider","pos":"VER"} ,
		{"word":"vider","word_nosc":"vider","lemma":"vider","pos":"VER"} ,
		{"word":"videra","word_nosc":"videra","lemma":"vider","pos":"VER"} ,
		{"word":"viderai","word_nosc":"viderai","lemma":"vider","pos":"VER"} ,
		{"word":"viderais","word_nosc":"viderais","lemma":"vider","pos":"VER"} ,
		{"word":"viderait","word_nosc":"viderait","lemma":"vider","pos":"VER"} ,
		{"word":"videras","word_nosc":"videras","lemma":"vider","pos":"VER"} ,
		{"word":"viderions","word_nosc":"viderions","lemma":"vider","pos":"VER"} ,
		{"word":"videront","word_nosc":"videront","lemma":"vider","pos":"VER"} ,
		{"word":"vides","word_nosc":"vides","lemma":"vider","pos":"VER"} ,
		{"word":"videz","word_nosc":"videz","lemma":"vider","pos":"VER"} ,
		{"word":"vidiez","word_nosc":"vidiez","lemma":"vider","pos":"VER"} ,
		{"word":"vidons","word_nosc":"vidons","lemma":"vider","pos":"VER"} ,
		{"word":"vidèrent","word_nosc":"viderent","lemma":"vider","pos":"VER"} ,
		{"word":"vidé","word_nosc":"vide","lemma":"vider","pos":"VER"} ,
		{"word":"vidée","word_nosc":"videe","lemma":"vider","pos":"VER"} ,
		{"word":"vidées","word_nosc":"videes","lemma":"vider","pos":"VER"} ,
		{"word":"vidés","word_nosc":"vides","lemma":"vider","pos":"VER"} ,
		{"word":"vieilli","word_nosc":"vieilli","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillie","word_nosc":"vieillie","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillies","word_nosc":"vieillies","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillir","word_nosc":"vieillir","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillira","word_nosc":"vieillira","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillirai","word_nosc":"vieillirai","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieilliraient","word_nosc":"vieilliraient","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillirais","word_nosc":"vieillirais","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillirait","word_nosc":"vieillirait","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieilliras","word_nosc":"vieilliras","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillirent","word_nosc":"vieillirent","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillirez","word_nosc":"vieillirez","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillirons","word_nosc":"vieillirons","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieilliront","word_nosc":"vieilliront","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillis","word_nosc":"vieillis","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillissaient","word_nosc":"vieillissaient","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillissais","word_nosc":"vieillissais","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillissait","word_nosc":"vieillissait","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillissant","word_nosc":"vieillissant","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillisse","word_nosc":"vieillisse","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillissent","word_nosc":"vieillissent","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillissez","word_nosc":"vieillissez","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillissiez","word_nosc":"vieillissiez","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillissions","word_nosc":"vieillissions","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillissons","word_nosc":"vieillissons","lemma":"vieillir","pos":"VER"} ,
		{"word":"vieillit","word_nosc":"vieillit","lemma":"vieillir","pos":"VER"} ,
		{"word":"viendra","word_nosc":"viendra","lemma":"venir","pos":"VER"} ,
		{"word":"viendrai","word_nosc":"viendrai","lemma":"venir","pos":"VER"} ,
		{"word":"viendraient","word_nosc":"viendraient","lemma":"venir","pos":"VER"} ,
		{"word":"viendrais","word_nosc":"viendrais","lemma":"venir","pos":"VER"} ,
		{"word":"viendrait","word_nosc":"viendrait","lemma":"venir","pos":"VER"} ,
		{"word":"viendras","word_nosc":"viendras","lemma":"venir","pos":"VER"} ,
		{"word":"viendrez","word_nosc":"viendrez","lemma":"venir","pos":"VER"} ,
		{"word":"viendriez","word_nosc":"viendriez","lemma":"venir","pos":"VER"} ,
		{"word":"viendrions","word_nosc":"viendrions","lemma":"venir","pos":"VER"} ,
		{"word":"viendrons","word_nosc":"viendrons","lemma":"venir","pos":"VER"} ,
		{"word":"viendront","word_nosc":"viendront","lemma":"venir","pos":"VER"} ,
		{"word":"vienne","word_nosc":"vienne","lemma":"venir","pos":"VER"} ,
		{"word":"viennent","word_nosc":"viennent","lemma":"venir","pos":"VER"} ,
		{"word":"viennes","word_nosc":"viennes","lemma":"venir","pos":"VER"} ,
		{"word":"viens","word_nosc":"viens","lemma":"venir","pos":"VER"} ,
		{"word":"vient","word_nosc":"vient","lemma":"venir","pos":"VER"} ,
		{"word":"vigilant","word_nosc":"vigilant","lemma":"vigiler","pos":"VER"} ,
		{"word":"vigiler","word_nosc":"vigiler","lemma":"vigiler","pos":"VER"} ,
		{"word":"vilipendaient","word_nosc":"vilipendaient","lemma":"vilipender","pos":"VER"} ,
		{"word":"vilipendant","word_nosc":"vilipendant","lemma":"vilipender","pos":"VER"} ,
		{"word":"vilipender","word_nosc":"vilipender","lemma":"vilipender","pos":"VER"} ,
		{"word":"vilipendé","word_nosc":"vilipende","lemma":"vilipender","pos":"VER"} ,
		{"word":"vilipendées","word_nosc":"vilipendees","lemma":"vilipender","pos":"VER"} ,
		{"word":"vilipendés","word_nosc":"vilipendes","lemma":"vilipender","pos":"VER"} ,
		{"word":"villégiaturait","word_nosc":"villegiaturait","lemma":"villégiaturer","pos":"VER"} ,
		{"word":"villégiaturer","word_nosc":"villegiaturer","lemma":"villégiaturer","pos":"VER"} ,
		{"word":"villégiaturé","word_nosc":"villegiature","lemma":"villégiaturer","pos":"VER"} ,
		{"word":"vinaigre","word_nosc":"vinaigre","lemma":"vinaigrer","pos":"VER"} ,
		{"word":"vinaigrer","word_nosc":"vinaigrer","lemma":"vinaigrer","pos":"VER"} ,
		{"word":"vinaigré","word_nosc":"vinaigre","lemma":"vinaigrer","pos":"VER"} ,
		{"word":"vinaigrée","word_nosc":"vinaigree","lemma":"vinaigrer","pos":"VER"} ,
		{"word":"vine","word_nosc":"vine","lemma":"viner","pos":"VER"} ,
		{"word":"viner","word_nosc":"viner","lemma":"viner","pos":"VER"} ,
		{"word":"vines","word_nosc":"vines","lemma":"viner","pos":"VER"} ,
		{"word":"vinez","word_nosc":"vinez","lemma":"viner","pos":"VER"} ,
		{"word":"vinrent","word_nosc":"vinrent","lemma":"venir","pos":"VER"} ,
		{"word":"vins","word_nosc":"vins","lemma":"venir","pos":"VER"} ,
		{"word":"vinsse","word_nosc":"vinsse","lemma":"venir","pos":"VER"} ,
		{"word":"vinssent","word_nosc":"vinssent","lemma":"venir","pos":"VER"} ,
		{"word":"vint","word_nosc":"vint","lemma":"venir","pos":"VER"} ,
		{"word":"viola","word_nosc":"viola","lemma":"violer","pos":"VER"} ,
		{"word":"violacer","word_nosc":"violacer","lemma":"violacer","pos":"VER"} ,
		{"word":"violacé","word_nosc":"violace","lemma":"violacer","pos":"VER"} ,
		{"word":"violacée","word_nosc":"violacee","lemma":"violacer","pos":"VER"} ,
		{"word":"violacées","word_nosc":"violacees","lemma":"violacer","pos":"VER"} ,
		{"word":"violacés","word_nosc":"violaces","lemma":"violacer","pos":"VER"} ,
		{"word":"violaient","word_nosc":"violaient","lemma":"violer","pos":"VER"} ,
		{"word":"violais","word_nosc":"violais","lemma":"violer","pos":"VER"} ,
		{"word":"violait","word_nosc":"violait","lemma":"violer","pos":"VER"} ,
		{"word":"violant","word_nosc":"violant","lemma":"violer","pos":"VER"} ,
		{"word":"violaçait","word_nosc":"violacait","lemma":"violacer","pos":"VER"} ,
		{"word":"viole","word_nosc":"viole","lemma":"violer","pos":"VER"} ,
		{"word":"violent","word_nosc":"violent","lemma":"violer","pos":"VER"} ,
		{"word":"violentaient","word_nosc":"violentaient","lemma":"violenter","pos":"VER"} ,
		{"word":"violentait","word_nosc":"violentait","lemma":"violenter","pos":"VER"} ,
		{"word":"violente","word_nosc":"violente","lemma":"violenter","pos":"VER"} ,
		{"word":"violenter","word_nosc":"violenter","lemma":"violenter","pos":"VER"} ,
		{"word":"violenté","word_nosc":"violente","lemma":"violenter","pos":"VER"} ,
		{"word":"violentée","word_nosc":"violentee","lemma":"violenter","pos":"VER"} ,
		{"word":"violer","word_nosc":"violer","lemma":"violer","pos":"VER"} ,
		{"word":"violerai","word_nosc":"violerai","lemma":"violer","pos":"VER"} ,
		{"word":"violerais","word_nosc":"violerais","lemma":"violer","pos":"VER"} ,
		{"word":"violerait","word_nosc":"violerait","lemma":"violer","pos":"VER"} ,
		{"word":"violeras","word_nosc":"violeras","lemma":"violer","pos":"VER"} ,
		{"word":"violeront","word_nosc":"violeront","lemma":"violer","pos":"VER"} ,
		{"word":"violes","word_nosc":"violes","lemma":"violer","pos":"VER"} ,
		{"word":"violeta","word_nosc":"violeta","lemma":"violeter","pos":"VER"} ,
		{"word":"violez","word_nosc":"violez","lemma":"violer","pos":"VER"} ,
		{"word":"violone","word_nosc":"violone","lemma":"violoner","pos":"VER"} ,
		{"word":"violons","word_nosc":"violons","lemma":"violer","pos":"VER"} ,
		{"word":"violé","word_nosc":"viole","lemma":"violer","pos":"VER"} ,
		{"word":"violée","word_nosc":"violee","lemma":"violer","pos":"VER"} ,
		{"word":"violées","word_nosc":"violees","lemma":"violer","pos":"VER"} ,
		{"word":"violés","word_nosc":"violes","lemma":"violer","pos":"VER"} ,
		{"word":"vira","word_nosc":"vira","lemma":"virer","pos":"VER"} ,
		{"word":"virai","word_nosc":"virai","lemma":"virer","pos":"VER"} ,
		{"word":"viraient","word_nosc":"viraient","lemma":"virer","pos":"VER"} ,
		{"word":"virais","word_nosc":"virais","lemma":"virer","pos":"VER"} ,
		{"word":"virait","word_nosc":"virait","lemma":"virer","pos":"VER"} ,
		{"word":"virant","word_nosc":"virant","lemma":"virer","pos":"VER"} ,
		{"word":"vire","word_nosc":"vire","lemma":"virer","pos":"VER"} ,
		{"word":"virent","word_nosc":"virent","lemma":"virer","pos":"VER"} ,
		{"word":"virer","word_nosc":"virer","lemma":"virer","pos":"VER"} ,
		{"word":"virera","word_nosc":"virera","lemma":"virer","pos":"VER"} ,
		{"word":"virerai","word_nosc":"virerai","lemma":"virer","pos":"VER"} ,
		{"word":"virerais","word_nosc":"virerais","lemma":"virer","pos":"VER"} ,
		{"word":"virerait","word_nosc":"virerait","lemma":"virer","pos":"VER"} ,
		{"word":"vireras","word_nosc":"vireras","lemma":"virer","pos":"VER"} ,
		{"word":"virerez","word_nosc":"virerez","lemma":"virer","pos":"VER"} ,
		{"word":"vireront","word_nosc":"vireront","lemma":"virer","pos":"VER"} ,
		{"word":"vires","word_nosc":"vires","lemma":"virer","pos":"VER"} ,
		{"word":"virevolta","word_nosc":"virevolta","lemma":"virevolter","pos":"VER"} ,
		{"word":"virevoltaient","word_nosc":"virevoltaient","lemma":"virevolter","pos":"VER"} ,
		{"word":"virevoltait","word_nosc":"virevoltait","lemma":"virevolter","pos":"VER"} ,
		{"word":"virevoltant","word_nosc":"virevoltant","lemma":"virevolter","pos":"VER"} ,
		{"word":"virevolte","word_nosc":"virevolte","lemma":"virevolter","pos":"VER"} ,
		{"word":"virevoltent","word_nosc":"virevoltent","lemma":"virevolter","pos":"VER"} ,
		{"word":"virevolter","word_nosc":"virevolter","lemma":"virevolter","pos":"VER"} ,
		{"word":"virevoltes","word_nosc":"virevoltes","lemma":"virevolter","pos":"VER"} ,
		{"word":"virevolté","word_nosc":"virevolte","lemma":"virevolter","pos":"VER"} ,
		{"word":"virez","word_nosc":"virez","lemma":"virer","pos":"VER"} ,
		{"word":"viriez","word_nosc":"viriez","lemma":"virer","pos":"VER"} ,
		{"word":"virilise","word_nosc":"virilise","lemma":"viriliser","pos":"VER"} ,
		{"word":"viriliser","word_nosc":"viriliser","lemma":"viriliser","pos":"VER"} ,
		{"word":"virilisé","word_nosc":"virilise","lemma":"viriliser","pos":"VER"} ,
		{"word":"virilisés","word_nosc":"virilises","lemma":"viriliser","pos":"VER"} ,
		{"word":"virons","word_nosc":"virons","lemma":"virer","pos":"VER"} ,
		{"word":"virât","word_nosc":"virat","lemma":"virer","pos":"VER"} ,
		{"word":"virèrent","word_nosc":"virerent","lemma":"virer","pos":"VER"} ,
		{"word":"viré","word_nosc":"vire","lemma":"virer","pos":"VER"} ,
		{"word":"virée","word_nosc":"viree","lemma":"virer","pos":"VER"} ,
		{"word":"virées","word_nosc":"virees","lemma":"virer","pos":"VER"} ,
		{"word":"virés","word_nosc":"vires","lemma":"virer","pos":"VER"} ,
		{"word":"vis","word_nosc":"vis","lemma":"vivre","pos":"VER"} ,
		{"word":"vis","word_nosc":"vis","lemma":"voir","pos":"VER"} ,
		{"word":"visa","word_nosc":"visa","lemma":"viser","pos":"VER"} ,
		{"word":"visai","word_nosc":"visai","lemma":"viser","pos":"VER"} ,
		{"word":"visaient","word_nosc":"visaient","lemma":"viser","pos":"VER"} ,
		{"word":"visais","word_nosc":"visais","lemma":"viser","pos":"VER"} ,
		{"word":"visait","word_nosc":"visait","lemma":"viser","pos":"VER"} ,
		{"word":"visant","word_nosc":"visant","lemma":"viser","pos":"VER"} ,
		{"word":"visas","word_nosc":"visas","lemma":"viser","pos":"VER"} ,
		{"word":"vise","word_nosc":"vise","lemma":"viser","pos":"VER"} ,
		{"word":"visent","word_nosc":"visent","lemma":"viser","pos":"VER"} ,
		{"word":"viser","word_nosc":"viser","lemma":"viser","pos":"VER"} ,
		{"word":"visera","word_nosc":"visera","lemma":"viser","pos":"VER"} ,
		{"word":"viserai","word_nosc":"viserai","lemma":"viser","pos":"VER"} ,
		{"word":"viseraient","word_nosc":"viseraient","lemma":"viser","pos":"VER"} ,
		{"word":"viserais","word_nosc":"viserais","lemma":"viser","pos":"VER"} ,
		{"word":"viserait","word_nosc":"viserait","lemma":"viser","pos":"VER"} ,
		{"word":"viseront","word_nosc":"viseront","lemma":"viser","pos":"VER"} ,
		{"word":"vises","word_nosc":"vises","lemma":"viser","pos":"VER"} ,
		{"word":"visez","word_nosc":"visez","lemma":"viser","pos":"VER"} ,
		{"word":"visiez","word_nosc":"visiez","lemma":"viser","pos":"VER"} ,
		{"word":"visionna","word_nosc":"visionna","lemma":"visionner","pos":"VER"} ,
		{"word":"visionnant","word_nosc":"visionnant","lemma":"visionner","pos":"VER"} ,
		{"word":"visionne","word_nosc":"visionne","lemma":"visionner","pos":"VER"} ,
		{"word":"visionnent","word_nosc":"visionnent","lemma":"visionner","pos":"VER"} ,
		{"word":"visionner","word_nosc":"visionner","lemma":"visionner","pos":"VER"} ,
		{"word":"visionnera","word_nosc":"visionnera","lemma":"visionner","pos":"VER"} ,
		{"word":"visionneront","word_nosc":"visionneront","lemma":"visionner","pos":"VER"} ,
		{"word":"visionnez","word_nosc":"visionnez","lemma":"visionner","pos":"VER"} ,
		{"word":"visionnons","word_nosc":"visionnons","lemma":"visionner","pos":"VER"} ,
		{"word":"visionné","word_nosc":"visionne","lemma":"visionner","pos":"VER"} ,
		{"word":"visionnés","word_nosc":"visionnes","lemma":"visionner","pos":"VER"} ,
		{"word":"visions","word_nosc":"visions","lemma":"viser","pos":"VER"} ,
		{"word":"visita","word_nosc":"visita","lemma":"visiter","pos":"VER"} ,
		{"word":"visitai","word_nosc":"visitai","lemma":"visiter","pos":"VER"} ,
		{"word":"visitaient","word_nosc":"visitaient","lemma":"visiter","pos":"VER"} ,
		{"word":"visitais","word_nosc":"visitais","lemma":"visiter","pos":"VER"} ,
		{"word":"visitait","word_nosc":"visitait","lemma":"visiter","pos":"VER"} ,
		{"word":"visitant","word_nosc":"visitant","lemma":"visiter","pos":"VER"} ,
		{"word":"visite","word_nosc":"visite","lemma":"visiter","pos":"VER"} ,
		{"word":"visitent","word_nosc":"visitent","lemma":"visiter","pos":"VER"} ,
		{"word":"visiter","word_nosc":"visiter","lemma":"visiter","pos":"VER"} ,
		{"word":"visitera","word_nosc":"visitera","lemma":"visiter","pos":"VER"} ,
		{"word":"visiterai","word_nosc":"visiterai","lemma":"visiter","pos":"VER"} ,
		{"word":"visiteraient","word_nosc":"visiteraient","lemma":"visiter","pos":"VER"} ,
		{"word":"visiterait","word_nosc":"visiterait","lemma":"visiter","pos":"VER"} ,
		{"word":"visiteras","word_nosc":"visiteras","lemma":"visiter","pos":"VER"} ,
		{"word":"visiterez","word_nosc":"visiterez","lemma":"visiter","pos":"VER"} ,
		{"word":"visiterons","word_nosc":"visiterons","lemma":"visiter","pos":"VER"} ,
		{"word":"visites","word_nosc":"visites","lemma":"visiter","pos":"VER"} ,
		{"word":"visitez","word_nosc":"visitez","lemma":"visiter","pos":"VER"} ,
		{"word":"visitiez","word_nosc":"visitiez","lemma":"visiter","pos":"VER"} ,
		{"word":"visitions","word_nosc":"visitions","lemma":"visiter","pos":"VER"} ,
		{"word":"visitons","word_nosc":"visitons","lemma":"visiter","pos":"VER"} ,
		{"word":"visitâmes","word_nosc":"visitames","lemma":"visiter","pos":"VER"} ,
		{"word":"visitât","word_nosc":"visitat","lemma":"visiter","pos":"VER"} ,
		{"word":"visitèrent","word_nosc":"visiterent","lemma":"visiter","pos":"VER"} ,
		{"word":"visité","word_nosc":"visite","lemma":"visiter","pos":"VER"} ,
		{"word":"visitée","word_nosc":"visitee","lemma":"visiter","pos":"VER"} ,
		{"word":"visitées","word_nosc":"visitees","lemma":"visiter","pos":"VER"} ,
		{"word":"visités","word_nosc":"visites","lemma":"visiter","pos":"VER"} ,
		{"word":"visons","word_nosc":"visons","lemma":"viser","pos":"VER"} ,
		{"word":"vissa","word_nosc":"vissa","lemma":"visser","pos":"VER"} ,
		{"word":"vissaient","word_nosc":"vissaient","lemma":"visser","pos":"VER"} ,
		{"word":"vissait","word_nosc":"vissait","lemma":"visser","pos":"VER"} ,
		{"word":"vissant","word_nosc":"vissant","lemma":"visser","pos":"VER"} ,
		{"word":"visse","word_nosc":"visse","lemma":"visser","pos":"VER"} ,
		{"word":"vissent","word_nosc":"vissent","lemma":"visser","pos":"VER"} ,
		{"word":"vissent","word_nosc":"vissent","lemma":"voir","pos":"VER"} ,
		{"word":"visser","word_nosc":"visser","lemma":"visser","pos":"VER"} ,
		{"word":"visserait","word_nosc":"visserait","lemma":"visser","pos":"VER"} ,
		{"word":"vissez","word_nosc":"vissez","lemma":"visser","pos":"VER"} ,
		{"word":"vissèrent","word_nosc":"visserent","lemma":"visser","pos":"VER"} ,
		{"word":"vissé","word_nosc":"visse","lemma":"visser","pos":"VER"} ,
		{"word":"vissée","word_nosc":"vissee","lemma":"visser","pos":"VER"} ,
		{"word":"vissées","word_nosc":"vissees","lemma":"visser","pos":"VER"} ,
		{"word":"vissés","word_nosc":"visses","lemma":"visser","pos":"VER"} ,
		{"word":"visualisaient","word_nosc":"visualisaient","lemma":"visualiser","pos":"VER"} ,
		{"word":"visualisais","word_nosc":"visualisais","lemma":"visualiser","pos":"VER"} ,
		{"word":"visualisant","word_nosc":"visualisant","lemma":"visualiser","pos":"VER"} ,
		{"word":"visualise","word_nosc":"visualise","lemma":"visualiser","pos":"VER"} ,
		{"word":"visualiser","word_nosc":"visualiser","lemma":"visualiser","pos":"VER"} ,
		{"word":"visualiserai","word_nosc":"visualiserai","lemma":"visualiser","pos":"VER"} ,
		{"word":"visualises","word_nosc":"visualises","lemma":"visualiser","pos":"VER"} ,
		{"word":"visualisez","word_nosc":"visualisez","lemma":"visualiser","pos":"VER"} ,
		{"word":"visualisé","word_nosc":"visualise","lemma":"visualiser","pos":"VER"} ,
		{"word":"visualisée","word_nosc":"visualisee","lemma":"visualiser","pos":"VER"} ,
		{"word":"visualisées","word_nosc":"visualisees","lemma":"visualiser","pos":"VER"} ,
		{"word":"visualisés","word_nosc":"visualises","lemma":"visualiser","pos":"VER"} ,
		{"word":"visé","word_nosc":"vise","lemma":"viser","pos":"VER"} ,
		{"word":"visée","word_nosc":"visee","lemma":"viser","pos":"VER"} ,
		{"word":"visées","word_nosc":"visees","lemma":"viser","pos":"VER"} ,
		{"word":"visés","word_nosc":"vises","lemma":"viser","pos":"VER"} ,
		{"word":"vit","word_nosc":"vit","lemma":"vivre","pos":"VER"} ,
		{"word":"vit","word_nosc":"vit","lemma":"voir","pos":"VER"} ,
		{"word":"vitaliser","word_nosc":"vitaliser","lemma":"vitaliser","pos":"VER"} ,
		{"word":"vitaminer","word_nosc":"vitaminer","lemma":"vitaminer","pos":"VER"} ,
		{"word":"vitre","word_nosc":"vitre","lemma":"vitrer","pos":"VER"} ,
		{"word":"vitres","word_nosc":"vitres","lemma":"vitrer","pos":"VER"} ,
		{"word":"vitrifia","word_nosc":"vitrifia","lemma":"vitrifier","pos":"VER"} ,
		{"word":"vitrifie","word_nosc":"vitrifie","lemma":"vitrifier","pos":"VER"} ,
		{"word":"vitrifient","word_nosc":"vitrifient","lemma":"vitrifier","pos":"VER"} ,
		{"word":"vitrifier","word_nosc":"vitrifier","lemma":"vitrifier","pos":"VER"} ,
		{"word":"vitrifié","word_nosc":"vitrifie","lemma":"vitrifier","pos":"VER"} ,
		{"word":"vitrifiée","word_nosc":"vitrifiee","lemma":"vitrifier","pos":"VER"} ,
		{"word":"vitrifiées","word_nosc":"vitrifiees","lemma":"vitrifier","pos":"VER"} ,
		{"word":"vitrifiés","word_nosc":"vitrifies","lemma":"vitrifier","pos":"VER"} ,
		{"word":"vitrioler","word_nosc":"vitrioler","lemma":"vitrioler","pos":"VER"} ,
		{"word":"vitriolé","word_nosc":"vitriole","lemma":"vitrioler","pos":"VER"} ,
		{"word":"vitré","word_nosc":"vitre","lemma":"vitrer","pos":"VER"} ,
		{"word":"vitrée","word_nosc":"vitree","lemma":"vitrer","pos":"VER"} ,
		{"word":"vitrées","word_nosc":"vitrees","lemma":"vitrer","pos":"VER"} ,
		{"word":"vitrés","word_nosc":"vitres","lemma":"vitrer","pos":"VER"} ,
		{"word":"vitupère","word_nosc":"vitupere","lemma":"vitupérer","pos":"VER"} ,
		{"word":"vitupéraient","word_nosc":"vituperaient","lemma":"vitupérer","pos":"VER"} ,
		{"word":"vitupérait","word_nosc":"vituperait","lemma":"vitupérer","pos":"VER"} ,
		{"word":"vitupérant","word_nosc":"vituperant","lemma":"vitupérer","pos":"VER"} ,
		{"word":"vitupérer","word_nosc":"vituperer","lemma":"vitupérer","pos":"VER"} ,
		{"word":"vivaient","word_nosc":"vivaient","lemma":"vivre","pos":"VER"} ,
		{"word":"vivais","word_nosc":"vivais","lemma":"vivre","pos":"VER"} ,
		{"word":"vivait","word_nosc":"vivait","lemma":"vivre","pos":"VER"} ,
		{"word":"vivant","word_nosc":"vivant","lemma":"vivre","pos":"VER"} ,
		{"word":"vive","word_nosc":"vive","lemma":"vivre","pos":"VER"} ,
		{"word":"vivent","word_nosc":"vivent","lemma":"vivre","pos":"VER"} ,
		{"word":"vives","word_nosc":"vives","lemma":"vivre","pos":"VER"} ,
		{"word":"vivez","word_nosc":"vivez","lemma":"vivre","pos":"VER"} ,
		{"word":"viviez","word_nosc":"viviez","lemma":"vivre","pos":"VER"} ,
		{"word":"vivifiait","word_nosc":"vivifiait","lemma":"vivifier","pos":"VER"} ,
		{"word":"vivifiant","word_nosc":"vivifiant","lemma":"vivifier","pos":"VER"} ,
		{"word":"vivifie","word_nosc":"vivifie","lemma":"vivifier","pos":"VER"} ,
		{"word":"vivifier","word_nosc":"vivifier","lemma":"vivifier","pos":"VER"} ,
		{"word":"vivifié","word_nosc":"vivifie","lemma":"vivifier","pos":"VER"} ,
		{"word":"vivifiée","word_nosc":"vivifiee","lemma":"vivifier","pos":"VER"} ,
		{"word":"vivifiées","word_nosc":"vivifiees","lemma":"vivifier","pos":"VER"} ,
		{"word":"vivions","word_nosc":"vivions","lemma":"vivre","pos":"VER"} ,
		{"word":"vivons","word_nosc":"vivons","lemma":"vivre","pos":"VER"} ,
		{"word":"vivota","word_nosc":"vivota","lemma":"vivoter","pos":"VER"} ,
		{"word":"vivotaient","word_nosc":"vivotaient","lemma":"vivoter","pos":"VER"} ,
		{"word":"vivotais","word_nosc":"vivotais","lemma":"vivoter","pos":"VER"} ,
		{"word":"vivotait","word_nosc":"vivotait","lemma":"vivoter","pos":"VER"} ,
		{"word":"vivotant","word_nosc":"vivotant","lemma":"vivoter","pos":"VER"} ,
		{"word":"vivote","word_nosc":"vivote","lemma":"vivoter","pos":"VER"} ,
		{"word":"vivotent","word_nosc":"vivotent","lemma":"vivoter","pos":"VER"} ,
		{"word":"vivoter","word_nosc":"vivoter","lemma":"vivoter","pos":"VER"} ,
		{"word":"vivotèrent","word_nosc":"vivoterent","lemma":"vivoter","pos":"VER"} ,
		{"word":"vivoté","word_nosc":"vivote","lemma":"vivoter","pos":"VER"} ,
		{"word":"vivra","word_nosc":"vivra","lemma":"vivre","pos":"VER"} ,
		{"word":"vivrai","word_nosc":"vivrai","lemma":"vivre","pos":"VER"} ,
		{"word":"vivraient","word_nosc":"vivraient","lemma":"vivre","pos":"VER"} ,
		{"word":"vivrais","word_nosc":"vivrais","lemma":"vivre","pos":"VER"} ,
		{"word":"vivrait","word_nosc":"vivrait","lemma":"vivre","pos":"VER"} ,
		{"word":"vivras","word_nosc":"vivras","lemma":"vivre","pos":"VER"} ,
		{"word":"vivre","word_nosc":"vivre","lemma":"vivre","pos":"VER"} ,
		{"word":"vivrez","word_nosc":"vivrez","lemma":"vivre","pos":"VER"} ,
		{"word":"vivriez","word_nosc":"vivriez","lemma":"vivre","pos":"VER"} ,
		{"word":"vivrions","word_nosc":"vivrions","lemma":"vivre","pos":"VER"} ,
		{"word":"vivrons","word_nosc":"vivrons","lemma":"vivre","pos":"VER"} ,
		{"word":"vivront","word_nosc":"vivront","lemma":"vivre","pos":"VER"} ,
		{"word":"vocalisait","word_nosc":"vocalisait","lemma":"vocaliser","pos":"VER"} ,
		{"word":"vocalisant","word_nosc":"vocalisant","lemma":"vocaliser","pos":"VER"} ,
		{"word":"vocalise","word_nosc":"vocalise","lemma":"vocaliser","pos":"VER"} ,
		{"word":"vocaliser","word_nosc":"vocaliser","lemma":"vocaliser","pos":"VER"} ,
		{"word":"vocifère","word_nosc":"vocifere","lemma":"vociférer","pos":"VER"} ,
		{"word":"vocifèrent","word_nosc":"vociferent","lemma":"vociférer","pos":"VER"} ,
		{"word":"vociféra","word_nosc":"vocifera","lemma":"vociférer","pos":"VER"} ,
		{"word":"vociférai","word_nosc":"vociferai","lemma":"vociférer","pos":"VER"} ,
		{"word":"vociféraient","word_nosc":"vociferaient","lemma":"vociférer","pos":"VER"} ,
		{"word":"vociférais","word_nosc":"vociferais","lemma":"vociférer","pos":"VER"} ,
		{"word":"vociférait","word_nosc":"vociferait","lemma":"vociférer","pos":"VER"} ,
		{"word":"vociférant","word_nosc":"vociferant","lemma":"vociférer","pos":"VER"} ,
		{"word":"vociférer","word_nosc":"vociferer","lemma":"vociférer","pos":"VER"} ,
		{"word":"vociférés","word_nosc":"vociferes","lemma":"vociférer","pos":"VER"} ,
		{"word":"vogua","word_nosc":"vogua","lemma":"voguer","pos":"VER"} ,
		{"word":"voguaient","word_nosc":"voguaient","lemma":"voguer","pos":"VER"} ,
		{"word":"voguais","word_nosc":"voguais","lemma":"voguer","pos":"VER"} ,
		{"word":"voguait","word_nosc":"voguait","lemma":"voguer","pos":"VER"} ,
		{"word":"voguant","word_nosc":"voguant","lemma":"voguer","pos":"VER"} ,
		{"word":"vogue","word_nosc":"vogue","lemma":"voguer","pos":"VER"} ,
		{"word":"voguent","word_nosc":"voguent","lemma":"voguer","pos":"VER"} ,
		{"word":"voguer","word_nosc":"voguer","lemma":"voguer","pos":"VER"} ,
		{"word":"voguera","word_nosc":"voguera","lemma":"voguer","pos":"VER"} ,
		{"word":"voguerai","word_nosc":"voguerai","lemma":"voguer","pos":"VER"} ,
		{"word":"voguerait","word_nosc":"voguerait","lemma":"voguer","pos":"VER"} ,
		{"word":"voguerions","word_nosc":"voguerions","lemma":"voguer","pos":"VER"} ,
		{"word":"voguerons","word_nosc":"voguerons","lemma":"voguer","pos":"VER"} ,
		{"word":"vogues","word_nosc":"vogues","lemma":"voguer","pos":"VER"} ,
		{"word":"voguions","word_nosc":"voguions","lemma":"voguer","pos":"VER"} ,
		{"word":"voguons","word_nosc":"voguons","lemma":"voguer","pos":"VER"} ,
		{"word":"vogué","word_nosc":"vogue","lemma":"voguer","pos":"VER"} ,
		{"word":"voie","word_nosc":"voie","lemma":"voir","pos":"VER"} ,
		{"word":"voient","word_nosc":"voient","lemma":"voir","pos":"VER"} ,
		{"word":"voies","word_nosc":"voies","lemma":"voir","pos":"VER"} ,
		{"word":"voila","word_nosc":"voila","lemma":"voiler","pos":"VER"} ,
		{"word":"voilaient","word_nosc":"voilaient","lemma":"voiler","pos":"VER"} ,
		{"word":"voilais","word_nosc":"voilais","lemma":"voiler","pos":"VER"} ,
		{"word":"voilait","word_nosc":"voilait","lemma":"voiler","pos":"VER"} ,
		{"word":"voilant","word_nosc":"voilant","lemma":"voiler","pos":"VER"} ,
		{"word":"voile","word_nosc":"voile","lemma":"voiler","pos":"VER"} ,
		{"word":"voilent","word_nosc":"voilent","lemma":"voiler","pos":"VER"} ,
		{"word":"voiler","word_nosc":"voiler","lemma":"voiler","pos":"VER"} ,
		{"word":"voilera","word_nosc":"voilera","lemma":"voiler","pos":"VER"} ,
		{"word":"voileront","word_nosc":"voileront","lemma":"voiler","pos":"VER"} ,
		{"word":"voiles","word_nosc":"voiles","lemma":"voiler","pos":"VER"} ,
		{"word":"voilez","word_nosc":"voilez","lemma":"voiler","pos":"VER"} ,
		{"word":"voilons","word_nosc":"voilons","lemma":"voiler","pos":"VER"} ,
		{"word":"voilèrent","word_nosc":"voilerent","lemma":"voiler","pos":"VER"} ,
		{"word":"voilé","word_nosc":"voile","lemma":"voiler","pos":"VER"} ,
		{"word":"voilée","word_nosc":"voilee","lemma":"voiler","pos":"VER"} ,
		{"word":"voilées","word_nosc":"voilees","lemma":"voiler","pos":"VER"} ,
		{"word":"voilés","word_nosc":"voiles","lemma":"voiler","pos":"VER"} ,
		{"word":"voir","word_nosc":"voir","lemma":"voir","pos":"VER"} ,
		{"word":"vois","word_nosc":"vois","lemma":"voir","pos":"VER"} ,
		{"word":"voisinaient","word_nosc":"voisinaient","lemma":"voisiner","pos":"VER"} ,
		{"word":"voisinait","word_nosc":"voisinait","lemma":"voisiner","pos":"VER"} ,
		{"word":"voisinant","word_nosc":"voisinant","lemma":"voisiner","pos":"VER"} ,
		{"word":"voisine","word_nosc":"voisine","lemma":"voisiner","pos":"VER"} ,
		{"word":"voisinent","word_nosc":"voisinent","lemma":"voisiner","pos":"VER"} ,
		{"word":"voisiner","word_nosc":"voisiner","lemma":"voisiner","pos":"VER"} ,
		{"word":"voisiné","word_nosc":"voisine","lemma":"voisiner","pos":"VER"} ,
		{"word":"voit","word_nosc":"voit","lemma":"voir","pos":"VER"} ,
		{"word":"voituraient","word_nosc":"voituraient","lemma":"voiturer","pos":"VER"} ,
		{"word":"voiturait","word_nosc":"voiturait","lemma":"voiturer","pos":"VER"} ,
		{"word":"voiture","word_nosc":"voiture","lemma":"voiturer","pos":"VER"} ,
		{"word":"voiturer","word_nosc":"voiturer","lemma":"voiturer","pos":"VER"} ,
		{"word":"voitureront","word_nosc":"voitureront","lemma":"voiturer","pos":"VER"} ,
		{"word":"voitures","word_nosc":"voitures","lemma":"voiturer","pos":"VER"} ,
		{"word":"voituré","word_nosc":"voiture","lemma":"voiturer","pos":"VER"} ,
		{"word":"vola","word_nosc":"vola","lemma":"voler","pos":"VER"} ,
		{"word":"volai","word_nosc":"volai","lemma":"voler","pos":"VER"} ,
		{"word":"volaient","word_nosc":"volaient","lemma":"voler","pos":"VER"} ,
		{"word":"volais","word_nosc":"volais","lemma":"voler","pos":"VER"} ,
		{"word":"volait","word_nosc":"volait","lemma":"voler","pos":"VER"} ,
		{"word":"volant","word_nosc":"volant","lemma":"voler","pos":"VER"} ,
		{"word":"volante","word_nosc":"volante","lemma":"volanter","pos":"VER"} ,
		{"word":"volantes","word_nosc":"volantes","lemma":"volanter","pos":"VER"} ,
		{"word":"volanté","word_nosc":"volante","lemma":"volanter","pos":"VER"} ,
		{"word":"volassent","word_nosc":"volassent","lemma":"voler","pos":"VER"} ,
		{"word":"volatilisa","word_nosc":"volatilisa","lemma":"volatiliser","pos":"VER"} ,
		{"word":"volatilisaient","word_nosc":"volatilisaient","lemma":"volatiliser","pos":"VER"} ,
		{"word":"volatilisait","word_nosc":"volatilisait","lemma":"volatiliser","pos":"VER"} ,
		{"word":"volatilisant","word_nosc":"volatilisant","lemma":"volatiliser","pos":"VER"} ,
		{"word":"volatilise","word_nosc":"volatilise","lemma":"volatiliser","pos":"VER"} ,
		{"word":"volatilisent","word_nosc":"volatilisent","lemma":"volatiliser","pos":"VER"} ,
		{"word":"volatiliser","word_nosc":"volatiliser","lemma":"volatiliser","pos":"VER"} ,
		{"word":"volatilisé","word_nosc":"volatilise","lemma":"volatiliser","pos":"VER"} ,
		{"word":"volatilisée","word_nosc":"volatilisee","lemma":"volatiliser","pos":"VER"} ,
		{"word":"volatilisées","word_nosc":"volatilisees","lemma":"volatiliser","pos":"VER"} ,
		{"word":"volatilisés","word_nosc":"volatilises","lemma":"volatiliser","pos":"VER"} ,
		{"word":"vole","word_nosc":"vole","lemma":"voler","pos":"VER"} ,
		{"word":"volent","word_nosc":"volent","lemma":"voler","pos":"VER"} ,
		{"word":"voler","word_nosc":"voler","lemma":"voler","pos":"VER"} ,
		{"word":"volera","word_nosc":"volera","lemma":"voler","pos":"VER"} ,
		{"word":"volerai","word_nosc":"volerai","lemma":"voler","pos":"VER"} ,
		{"word":"voleraient","word_nosc":"voleraient","lemma":"voler","pos":"VER"} ,
		{"word":"volerais","word_nosc":"volerais","lemma":"voler","pos":"VER"} ,
		{"word":"volerait","word_nosc":"volerait","lemma":"voler","pos":"VER"} ,
		{"word":"voleras","word_nosc":"voleras","lemma":"voler","pos":"VER"} ,
		{"word":"volerez","word_nosc":"volerez","lemma":"voler","pos":"VER"} ,
		{"word":"voleriez","word_nosc":"voleriez","lemma":"voler","pos":"VER"} ,
		{"word":"volerons","word_nosc":"volerons","lemma":"voler","pos":"VER"} ,
		{"word":"voleront","word_nosc":"voleront","lemma":"voler","pos":"VER"} ,
		{"word":"voles","word_nosc":"voles","lemma":"voler","pos":"VER"} ,
		{"word":"voleta","word_nosc":"voleta","lemma":"voleter","pos":"VER"} ,
		{"word":"voletaient","word_nosc":"voletaient","lemma":"voleter","pos":"VER"} ,
		{"word":"voletait","word_nosc":"voletait","lemma":"voleter","pos":"VER"} ,
		{"word":"voletant","word_nosc":"voletant","lemma":"voleter","pos":"VER"} ,
		{"word":"voleter","word_nosc":"voleter","lemma":"voleter","pos":"VER"} ,
		{"word":"voletez","word_nosc":"voletez","lemma":"voleter","pos":"VER"} ,
		{"word":"volette","word_nosc":"volette","lemma":"voleter","pos":"VER"} ,
		{"word":"volettent","word_nosc":"volettent","lemma":"voleter","pos":"VER"} ,
		{"word":"volez","word_nosc":"volez","lemma":"voler","pos":"VER"} ,
		{"word":"voliez","word_nosc":"voliez","lemma":"voler","pos":"VER"} ,
		{"word":"volions","word_nosc":"volions","lemma":"voler","pos":"VER"} ,
		{"word":"volons","word_nosc":"volons","lemma":"voler","pos":"VER"} ,
		{"word":"voltaient","word_nosc":"voltaient","lemma":"volter","pos":"VER"} ,
		{"word":"volter","word_nosc":"volter","lemma":"volter","pos":"VER"} ,
		{"word":"voltige","word_nosc":"voltige","lemma":"voltiger","pos":"VER"} ,
		{"word":"voltigea","word_nosc":"voltigea","lemma":"voltiger","pos":"VER"} ,
		{"word":"voltigeaient","word_nosc":"voltigeaient","lemma":"voltiger","pos":"VER"} ,
		{"word":"voltigeait","word_nosc":"voltigeait","lemma":"voltiger","pos":"VER"} ,
		{"word":"voltigeant","word_nosc":"voltigeant","lemma":"voltiger","pos":"VER"} ,
		{"word":"voltigent","word_nosc":"voltigent","lemma":"voltiger","pos":"VER"} ,
		{"word":"voltiger","word_nosc":"voltiger","lemma":"voltiger","pos":"VER"} ,
		{"word":"voltigèrent","word_nosc":"voltigerent","lemma":"voltiger","pos":"VER"} ,
		{"word":"voltigé","word_nosc":"voltige","lemma":"voltiger","pos":"VER"} ,
		{"word":"volât","word_nosc":"volat","lemma":"voler","pos":"VER"} ,
		{"word":"volèrent","word_nosc":"volerent","lemma":"voler","pos":"VER"} ,
		{"word":"volé","word_nosc":"vole","lemma":"voler","pos":"VER"} ,
		{"word":"volée","word_nosc":"volee","lemma":"voler","pos":"VER"} ,
		{"word":"volées","word_nosc":"volees","lemma":"voler","pos":"VER"} ,
		{"word":"volés","word_nosc":"voles","lemma":"voler","pos":"VER"} ,
		{"word":"vomi","word_nosc":"vomi","lemma":"vomir","pos":"VER"} ,
		{"word":"vomie","word_nosc":"vomie","lemma":"vomir","pos":"VER"} ,
		{"word":"vomies","word_nosc":"vomies","lemma":"vomir","pos":"VER"} ,
		{"word":"vomir","word_nosc":"vomir","lemma":"vomir","pos":"VER"} ,
		{"word":"vomira","word_nosc":"vomira","lemma":"vomir","pos":"VER"} ,
		{"word":"vomirai","word_nosc":"vomirai","lemma":"vomir","pos":"VER"} ,
		{"word":"vomirais","word_nosc":"vomirais","lemma":"vomir","pos":"VER"} ,
		{"word":"vomirait","word_nosc":"vomirait","lemma":"vomir","pos":"VER"} ,
		{"word":"vomiras","word_nosc":"vomiras","lemma":"vomir","pos":"VER"} ,
		{"word":"vomirent","word_nosc":"vomirent","lemma":"vomir","pos":"VER"} ,
		{"word":"vomirez","word_nosc":"vomirez","lemma":"vomir","pos":"VER"} ,
		{"word":"vomiront","word_nosc":"vomiront","lemma":"vomir","pos":"VER"} ,
		{"word":"vomis","word_nosc":"vomis","lemma":"vomir","pos":"VER"} ,
		{"word":"vomissaient","word_nosc":"vomissaient","lemma":"vomir","pos":"VER"} ,
		{"word":"vomissais","word_nosc":"vomissais","lemma":"vomir","pos":"VER"} ,
		{"word":"vomissait","word_nosc":"vomissait","lemma":"vomir","pos":"VER"} ,
		{"word":"vomissant","word_nosc":"vomissant","lemma":"vomir","pos":"VER"} ,
		{"word":"vomisse","word_nosc":"vomisse","lemma":"vomir","pos":"VER"} ,
		{"word":"vomissent","word_nosc":"vomissent","lemma":"vomir","pos":"VER"} ,
		{"word":"vomissez","word_nosc":"vomissez","lemma":"vomir","pos":"VER"} ,
		{"word":"vomissions","word_nosc":"vomissions","lemma":"vomir","pos":"VER"} ,
		{"word":"vomit","word_nosc":"vomit","lemma":"vomir","pos":"VER"} ,
		{"word":"vont","word_nosc":"vont","lemma":"aller","pos":"VER"} ,
		{"word":"vota","word_nosc":"vota","lemma":"voter","pos":"VER"} ,
		{"word":"votaient","word_nosc":"votaient","lemma":"voter","pos":"VER"} ,
		{"word":"votais","word_nosc":"votais","lemma":"voter","pos":"VER"} ,
		{"word":"votait","word_nosc":"votait","lemma":"voter","pos":"VER"} ,
		{"word":"votant","word_nosc":"votant","lemma":"voter","pos":"VER"} ,
		{"word":"vote","word_nosc":"vote","lemma":"voter","pos":"VER"} ,
		{"word":"votent","word_nosc":"votent","lemma":"voter","pos":"VER"} ,
		{"word":"voter","word_nosc":"voter","lemma":"voter","pos":"VER"} ,
		{"word":"votera","word_nosc":"votera","lemma":"voter","pos":"VER"} ,
		{"word":"voterai","word_nosc":"voterai","lemma":"voter","pos":"VER"} ,
		{"word":"voterais","word_nosc":"voterais","lemma":"voter","pos":"VER"} ,
		{"word":"voterait","word_nosc":"voterait","lemma":"voter","pos":"VER"} ,
		{"word":"voteras","word_nosc":"voteras","lemma":"voter","pos":"VER"} ,
		{"word":"voterez","word_nosc":"voterez","lemma":"voter","pos":"VER"} ,
		{"word":"voterons","word_nosc":"voterons","lemma":"voter","pos":"VER"} ,
		{"word":"voteront","word_nosc":"voteront","lemma":"voter","pos":"VER"} ,
		{"word":"votes","word_nosc":"votes","lemma":"voter","pos":"VER"} ,
		{"word":"votez","word_nosc":"votez","lemma":"voter","pos":"VER"} ,
		{"word":"votiez","word_nosc":"votiez","lemma":"voter","pos":"VER"} ,
		{"word":"votions","word_nosc":"votions","lemma":"voter","pos":"VER"} ,
		{"word":"votons","word_nosc":"votons","lemma":"voter","pos":"VER"} ,
		{"word":"votèrent","word_nosc":"voterent","lemma":"voter","pos":"VER"} ,
		{"word":"voté","word_nosc":"vote","lemma":"voter","pos":"VER"} ,
		{"word":"votée","word_nosc":"votee","lemma":"voter","pos":"VER"} ,
		{"word":"votées","word_nosc":"votees","lemma":"voter","pos":"VER"} ,
		{"word":"votés","word_nosc":"votes","lemma":"voter","pos":"VER"} ,
		{"word":"voua","word_nosc":"voua","lemma":"vouer","pos":"VER"} ,
		{"word":"vouai","word_nosc":"vouai","lemma":"vouer","pos":"VER"} ,
		{"word":"vouaient","word_nosc":"vouaient","lemma":"vouer","pos":"VER"} ,
		{"word":"vouais","word_nosc":"vouais","lemma":"vouer","pos":"VER"} ,
		{"word":"vouait","word_nosc":"vouait","lemma":"vouer","pos":"VER"} ,
		{"word":"vouant","word_nosc":"vouant","lemma":"vouer","pos":"VER"} ,
		{"word":"voudra","word_nosc":"voudra","lemma":"vouloir","pos":"VER"} ,
		{"word":"voudrai","word_nosc":"voudrai","lemma":"vouloir","pos":"VER"} ,
		{"word":"voudraient","word_nosc":"voudraient","lemma":"vouloir","pos":"VER"} ,
		{"word":"voudrais","word_nosc":"voudrais","lemma":"vouloir","pos":"VER"} ,
		{"word":"voudrait","word_nosc":"voudrait","lemma":"vouloir","pos":"VER"} ,
		{"word":"voudras","word_nosc":"voudras","lemma":"vouloir","pos":"VER"} ,
		{"word":"voudrez","word_nosc":"voudrez","lemma":"vouloir","pos":"VER"} ,
		{"word":"voudriez","word_nosc":"voudriez","lemma":"vouloir","pos":"VER"} ,
		{"word":"voudrions","word_nosc":"voudrions","lemma":"vouloir","pos":"VER"} ,
		{"word":"voudrons","word_nosc":"voudrons","lemma":"vouloir","pos":"VER"} ,
		{"word":"voudront","word_nosc":"voudront","lemma":"vouloir","pos":"VER"} ,
		{"word":"voue","word_nosc":"voue","lemma":"vouer","pos":"VER"} ,
		{"word":"vouent","word_nosc":"vouent","lemma":"vouer","pos":"VER"} ,
		{"word":"vouer","word_nosc":"vouer","lemma":"vouer","pos":"VER"} ,
		{"word":"vouera","word_nosc":"vouera","lemma":"vouer","pos":"VER"} ,
		{"word":"vouerait","word_nosc":"vouerait","lemma":"vouer","pos":"VER"} ,
		{"word":"voulaient","word_nosc":"voulaient","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulais","word_nosc":"voulais","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulait","word_nosc":"voulait","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulant","word_nosc":"voulant","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulez","word_nosc":"voulez","lemma":"vouler","pos":"VER"} ,
		{"word":"voulez","word_nosc":"voulez","lemma":"vouloir","pos":"VER"} ,
		{"word":"vouliez","word_nosc":"vouliez","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulions","word_nosc":"voulions","lemma":"vouloir","pos":"VER"} ,
		{"word":"vouloir","word_nosc":"vouloir","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulons","word_nosc":"voulons","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulu","word_nosc":"voulu","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulue","word_nosc":"voulue","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulues","word_nosc":"voulues","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulurent","word_nosc":"voulurent","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulus","word_nosc":"voulus","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulusse","word_nosc":"voulusse","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulussent","word_nosc":"voulussent","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulut","word_nosc":"voulut","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulûmes","word_nosc":"voulumes","lemma":"vouloir","pos":"VER"} ,
		{"word":"voulût","word_nosc":"voulut","lemma":"vouloir","pos":"VER"} ,
		{"word":"vouons","word_nosc":"vouons","lemma":"vouer","pos":"VER"} ,
		{"word":"vousoyait","word_nosc":"vousoyait","lemma":"vousoyer","pos":"VER"} ,
		{"word":"vousoyez","word_nosc":"vousoyez","lemma":"vousoyer","pos":"VER"} ,
		{"word":"voussoie","word_nosc":"voussoie","lemma":"voussoyer","pos":"VER"} ,
		{"word":"voussoient","word_nosc":"voussoient","lemma":"voussoyer","pos":"VER"} ,
		{"word":"voussoyait","word_nosc":"voussoyait","lemma":"voussoyer","pos":"VER"} ,
		{"word":"voussoyer","word_nosc":"voussoyer","lemma":"voussoyer","pos":"VER"} ,
		{"word":"voussoyez","word_nosc":"voussoyez","lemma":"voussoyer","pos":"VER"} ,
		{"word":"vouvoie","word_nosc":"vouvoie","lemma":"vouvoyer","pos":"VER"} ,
		{"word":"vouvoient","word_nosc":"vouvoient","lemma":"vouvoyer","pos":"VER"} ,
		{"word":"vouvoies","word_nosc":"vouvoies","lemma":"vouvoyer","pos":"VER"} ,
		{"word":"vouvoya","word_nosc":"vouvoya","lemma":"vouvoyer","pos":"VER"} ,
		{"word":"vouvoyait","word_nosc":"vouvoyait","lemma":"vouvoyer","pos":"VER"} ,
		{"word":"vouvoyant","word_nosc":"vouvoyant","lemma":"vouvoyer","pos":"VER"} ,
		{"word":"vouvoyer","word_nosc":"vouvoyer","lemma":"vouvoyer","pos":"VER"} ,
		{"word":"vouèrent","word_nosc":"vouerent","lemma":"vouer","pos":"VER"} ,
		{"word":"voué","word_nosc":"voue","lemma":"vouer","pos":"VER"} ,
		{"word":"vouée","word_nosc":"vouee","lemma":"vouer","pos":"VER"} ,
		{"word":"vouées","word_nosc":"vouees","lemma":"vouer","pos":"VER"} ,
		{"word":"voués","word_nosc":"voues","lemma":"vouer","pos":"VER"} ,
		{"word":"voyage","word_nosc":"voyage","lemma":"voyager","pos":"VER"} ,
		{"word":"voyagea","word_nosc":"voyagea","lemma":"voyager","pos":"VER"} ,
		{"word":"voyageaient","word_nosc":"voyageaient","lemma":"voyager","pos":"VER"} ,
		{"word":"voyageais","word_nosc":"voyageais","lemma":"voyager","pos":"VER"} ,
		{"word":"voyageait","word_nosc":"voyageait","lemma":"voyager","pos":"VER"} ,
		{"word":"voyageant","word_nosc":"voyageant","lemma":"voyager","pos":"VER"} ,
		{"word":"voyagent","word_nosc":"voyagent","lemma":"voyager","pos":"VER"} ,
		{"word":"voyageons","word_nosc":"voyageons","lemma":"voyager","pos":"VER"} ,
		{"word":"voyager","word_nosc":"voyager","lemma":"voyager","pos":"VER"} ,
		{"word":"voyagera","word_nosc":"voyagera","lemma":"voyager","pos":"VER"} ,
		{"word":"voyagerai","word_nosc":"voyagerai","lemma":"voyager","pos":"VER"} ,
		{"word":"voyagerais","word_nosc":"voyagerais","lemma":"voyager","pos":"VER"} ,
		{"word":"voyagerait","word_nosc":"voyagerait","lemma":"voyager","pos":"VER"} ,
		{"word":"voyageras","word_nosc":"voyageras","lemma":"voyager","pos":"VER"} ,
		{"word":"voyagerez","word_nosc":"voyagerez","lemma":"voyager","pos":"VER"} ,
		{"word":"voyageriez","word_nosc":"voyageriez","lemma":"voyager","pos":"VER"} ,
		{"word":"voyagerons","word_nosc":"voyagerons","lemma":"voyager","pos":"VER"} ,
		{"word":"voyageront","word_nosc":"voyageront","lemma":"voyager","pos":"VER"} ,
		{"word":"voyages","word_nosc":"voyages","lemma":"voyager","pos":"VER"} ,
		{"word":"voyagez","word_nosc":"voyagez","lemma":"voyager","pos":"VER"} ,
		{"word":"voyagiez","word_nosc":"voyagiez","lemma":"voyager","pos":"VER"} ,
		{"word":"voyagions","word_nosc":"voyagions","lemma":"voyager","pos":"VER"} ,
		{"word":"voyagèrent","word_nosc":"voyagerent","lemma":"voyager","pos":"VER"} ,
		{"word":"voyagé","word_nosc":"voyage","lemma":"voyager","pos":"VER"} ,
		{"word":"voyaient","word_nosc":"voyaient","lemma":"voir","pos":"VER"} ,
		{"word":"voyais","word_nosc":"voyais","lemma":"voir","pos":"VER"} ,
		{"word":"voyait","word_nosc":"voyait","lemma":"voir","pos":"VER"} ,
		{"word":"voyant","word_nosc":"voyant","lemma":"voir","pos":"VER"} ,
		{"word":"voyez","word_nosc":"voyez","lemma":"voir","pos":"VER"} ,
		{"word":"voyiez","word_nosc":"voyiez","lemma":"voir","pos":"VER"} ,
		{"word":"voyions","word_nosc":"voyions","lemma":"voir","pos":"VER"} ,
		{"word":"voyons","word_nosc":"voyons","lemma":"voir","pos":"VER"} ,
		{"word":"voûta","word_nosc":"vouta","lemma":"voûter","pos":"VER"} ,
		{"word":"voûtaient","word_nosc":"voutaient","lemma":"voûter","pos":"VER"} ,
		{"word":"voûtait","word_nosc":"voutait","lemma":"voûter","pos":"VER"} ,
		{"word":"voûtant","word_nosc":"voutant","lemma":"voûter","pos":"VER"} ,
		{"word":"voûte","word_nosc":"voute","lemma":"voûter","pos":"VER"} ,
		{"word":"voûtent","word_nosc":"voutent","lemma":"voûter","pos":"VER"} ,
		{"word":"voûter","word_nosc":"vouter","lemma":"voûter","pos":"VER"} ,
		{"word":"voûtes","word_nosc":"voutes","lemma":"voûter","pos":"VER"} ,
		{"word":"voûtât","word_nosc":"voutat","lemma":"voûter","pos":"VER"} ,
		{"word":"voûtèrent","word_nosc":"vouterent","lemma":"voûter","pos":"VER"} ,
		{"word":"voûté","word_nosc":"voute","lemma":"voûter","pos":"VER"} ,
		{"word":"voûtée","word_nosc":"voutee","lemma":"voûter","pos":"VER"} ,
		{"word":"voûtées","word_nosc":"voutees","lemma":"voûter","pos":"VER"} ,
		{"word":"voûtés","word_nosc":"voutes","lemma":"voûter","pos":"VER"} ,
		{"word":"vrilla","word_nosc":"vrilla","lemma":"vriller","pos":"VER"} ,
		{"word":"vrillaient","word_nosc":"vrillaient","lemma":"vriller","pos":"VER"} ,
		{"word":"vrillait","word_nosc":"vrillait","lemma":"vriller","pos":"VER"} ,
		{"word":"vrillant","word_nosc":"vrillant","lemma":"vriller","pos":"VER"} ,
		{"word":"vrille","word_nosc":"vrille","lemma":"vriller","pos":"VER"} ,
		{"word":"vrillent","word_nosc":"vrillent","lemma":"vriller","pos":"VER"} ,
		{"word":"vriller","word_nosc":"vriller","lemma":"vriller","pos":"VER"} ,
		{"word":"vrilles","word_nosc":"vrilles","lemma":"vriller","pos":"VER"} ,
		{"word":"vrillèrent","word_nosc":"vrillerent","lemma":"vriller","pos":"VER"} ,
		{"word":"vrillé","word_nosc":"vrille","lemma":"vriller","pos":"VER"} ,
		{"word":"vrillées","word_nosc":"vrillees","lemma":"vriller","pos":"VER"} ,
		{"word":"vrillés","word_nosc":"vrilles","lemma":"vriller","pos":"VER"} ,
		{"word":"vrombir","word_nosc":"vrombir","lemma":"vrombir","pos":"VER"} ,
		{"word":"vrombis","word_nosc":"vrombis","lemma":"vrombir","pos":"VER"} ,
		{"word":"vrombissaient","word_nosc":"vrombissaient","lemma":"vrombir","pos":"VER"} ,
		{"word":"vrombissait","word_nosc":"vrombissait","lemma":"vrombir","pos":"VER"} ,
		{"word":"vrombissant","word_nosc":"vrombissant","lemma":"vrombir","pos":"VER"} ,
		{"word":"vrombissent","word_nosc":"vrombissent","lemma":"vrombir","pos":"VER"} ,
		{"word":"vrombissions","word_nosc":"vrombissions","lemma":"vrombir","pos":"VER"} ,
		{"word":"vrombit","word_nosc":"vrombit","lemma":"vrombir","pos":"VER"} ,
		{"word":"vu","word_nosc":"vu","lemma":"voir","pos":"VER"} ,
		{"word":"vue","word_nosc":"vue","lemma":"voir","pos":"VER"} ,
		{"word":"vues","word_nosc":"vues","lemma":"voir","pos":"VER"} ,
		{"word":"vulcanisait","word_nosc":"vulcanisait","lemma":"vulcaniser","pos":"VER"} ,
		{"word":"vulcaniser","word_nosc":"vulcaniser","lemma":"vulcaniser","pos":"VER"} ,
		{"word":"vulgariser","word_nosc":"vulgariser","lemma":"vulgariser","pos":"VER"} ,
		{"word":"vus","word_nosc":"vus","lemma":"voir","pos":"VER"} ,
		{"word":"vécu","word_nosc":"vecu","lemma":"vivre","pos":"VER"} ,
		{"word":"vécue","word_nosc":"vecue","lemma":"vivre","pos":"VER"} ,
		{"word":"vécues","word_nosc":"vecues","lemma":"vivre","pos":"VER"} ,
		{"word":"vécurent","word_nosc":"vecurent","lemma":"vivre","pos":"VER"} ,
		{"word":"vécus","word_nosc":"vecus","lemma":"vivre","pos":"VER"} ,
		{"word":"vécussent","word_nosc":"vecussent","lemma":"vivre","pos":"VER"} ,
		{"word":"vécut","word_nosc":"vecut","lemma":"vivre","pos":"VER"} ,
		{"word":"vécûmes","word_nosc":"vecumes","lemma":"vivre","pos":"VER"} ,
		{"word":"vécût","word_nosc":"vecut","lemma":"vivre","pos":"VER"} ,
		{"word":"végète","word_nosc":"vegete","lemma":"végéter","pos":"VER"} ,
		{"word":"végètent","word_nosc":"vegetent","lemma":"végéter","pos":"VER"} ,
		{"word":"végètes","word_nosc":"vegetes","lemma":"végéter","pos":"VER"} ,
		{"word":"végéta","word_nosc":"vegeta","lemma":"végéter","pos":"VER"} ,
		{"word":"végétaient","word_nosc":"vegetaient","lemma":"végéter","pos":"VER"} ,
		{"word":"végétais","word_nosc":"vegetais","lemma":"végéter","pos":"VER"} ,
		{"word":"végétait","word_nosc":"vegetait","lemma":"végéter","pos":"VER"} ,
		{"word":"végéter","word_nosc":"vegeter","lemma":"végéter","pos":"VER"} ,
		{"word":"végéteras","word_nosc":"vegeteras","lemma":"végéter","pos":"VER"} ,
		{"word":"végéterez","word_nosc":"vegeterez","lemma":"végéter","pos":"VER"} ,
		{"word":"végétons","word_nosc":"vegetons","lemma":"végéter","pos":"VER"} ,
		{"word":"végétât","word_nosc":"vegetat","lemma":"végéter","pos":"VER"} ,
		{"word":"végété","word_nosc":"vegete","lemma":"végéter","pos":"VER"} ,
		{"word":"véhiculaient","word_nosc":"vehiculaient","lemma":"véhiculer","pos":"VER"} ,
		{"word":"véhiculait","word_nosc":"vehiculait","lemma":"véhiculer","pos":"VER"} ,
		{"word":"véhiculant","word_nosc":"vehiculant","lemma":"véhiculer","pos":"VER"} ,
		{"word":"véhicule","word_nosc":"vehicule","lemma":"véhiculer","pos":"VER"} ,
		{"word":"véhiculent","word_nosc":"vehiculent","lemma":"véhiculer","pos":"VER"} ,
		{"word":"véhiculer","word_nosc":"vehiculer","lemma":"véhiculer","pos":"VER"} ,
		{"word":"véhicules","word_nosc":"vehicules","lemma":"véhiculer","pos":"VER"} ,
		{"word":"véhiculez","word_nosc":"vehiculez","lemma":"véhiculer","pos":"VER"} ,
		{"word":"véhiculé","word_nosc":"vehicule","lemma":"véhiculer","pos":"VER"} ,
		{"word":"véhiculée","word_nosc":"vehiculee","lemma":"véhiculer","pos":"VER"} ,
		{"word":"vénère","word_nosc":"venere","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénèrent","word_nosc":"venerent","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénères","word_nosc":"veneres","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénérai","word_nosc":"venerai","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénéraient","word_nosc":"veneraient","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénérais","word_nosc":"venerais","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénérait","word_nosc":"venerait","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénérant","word_nosc":"venerant","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénéras","word_nosc":"veneras","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénérer","word_nosc":"venerer","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénérera","word_nosc":"venerera","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénéreraient","word_nosc":"venereraient","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénérerais","word_nosc":"venererais","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénérez","word_nosc":"venerez","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénérions","word_nosc":"venerions","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénérons","word_nosc":"venerons","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénéré","word_nosc":"venere","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénérée","word_nosc":"veneree","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénérées","word_nosc":"venerees","lemma":"vénérer","pos":"VER"} ,
		{"word":"vénérés","word_nosc":"veneres","lemma":"vénérer","pos":"VER"} ,
		{"word":"vérifia","word_nosc":"verifia","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifiai","word_nosc":"verifiai","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifiaient","word_nosc":"verifiaient","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifiais","word_nosc":"verifiais","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifiait","word_nosc":"verifiait","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifiant","word_nosc":"verifiant","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifie","word_nosc":"verifie","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifient","word_nosc":"verifient","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifier","word_nosc":"verifier","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifiera","word_nosc":"verifiera","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifierai","word_nosc":"verifierai","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifierais","word_nosc":"verifierais","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifierait","word_nosc":"verifierait","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifieras","word_nosc":"verifieras","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifierons","word_nosc":"verifierons","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifieront","word_nosc":"verifieront","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifies","word_nosc":"verifies","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifiez","word_nosc":"verifiez","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifiions","word_nosc":"verifiions","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifions","word_nosc":"verifions","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifièrent","word_nosc":"verifierent","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifié","word_nosc":"verifie","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifiée","word_nosc":"verifiee","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifiées","word_nosc":"verifiees","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérifiés","word_nosc":"verifies","lemma":"vérifier","pos":"VER"} ,
		{"word":"vérolé","word_nosc":"verole","lemma":"véroler","pos":"VER"} ,
		{"word":"vérolée","word_nosc":"verolee","lemma":"véroler","pos":"VER"} ,
		{"word":"vérolées","word_nosc":"verolees","lemma":"véroler","pos":"VER"} ,
		{"word":"vérolés","word_nosc":"veroles","lemma":"véroler","pos":"VER"} ,
		{"word":"vêler","word_nosc":"veler","lemma":"vêler","pos":"VER"} ,
		{"word":"vêlé","word_nosc":"vele","lemma":"vêler","pos":"VER"} ,
		{"word":"vêt","word_nosc":"vet","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtaient","word_nosc":"vetaient","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtait","word_nosc":"vetait","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtant","word_nosc":"vetant","lemma":"vêtir","pos":"VER"} ,
		{"word":"vête","word_nosc":"vete","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtent","word_nosc":"vetent","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtez","word_nosc":"vetez","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtir","word_nosc":"vetir","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtira","word_nosc":"vetira","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtirais","word_nosc":"vetirais","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtirait","word_nosc":"vetirait","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtirons","word_nosc":"vetirons","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtis","word_nosc":"vetis","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtit","word_nosc":"vetit","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtons","word_nosc":"vetons","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêts","word_nosc":"vets","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtu","word_nosc":"vetu","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtue","word_nosc":"vetue","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtues","word_nosc":"vetues","lemma":"vêtir","pos":"VER"} ,
		{"word":"vêtus","word_nosc":"vetus","lemma":"vêtir","pos":"VER"} ,
		{"word":"vîmes","word_nosc":"vimes","lemma":"voir","pos":"VER"} ,
		{"word":"vînt","word_nosc":"vint","lemma":"venir","pos":"VER"} ,
		{"word":"vît","word_nosc":"vit","lemma":"voir","pos":"VER"} ,
		{"word":"y","word_nosc":"y","lemma":"y","pos":"VER"} ,
		{"word":"yodlait","word_nosc":"yodlait","lemma":"yodler","pos":"VER"} ,
		{"word":"yodler","word_nosc":"yodler","lemma":"yodler","pos":"VER"} ,
		{"word":"zadé","word_nosc":"zade","lemma":"zader","pos":"VER"} ,
		{"word":"zappant","word_nosc":"zappant","lemma":"zapper","pos":"VER"} ,
		{"word":"zappe","word_nosc":"zappe","lemma":"zapper","pos":"VER"} ,
		{"word":"zapper","word_nosc":"zapper","lemma":"zapper","pos":"VER"} ,
		{"word":"zapperai","word_nosc":"zapperai","lemma":"zapper","pos":"VER"} ,
		{"word":"zapperait","word_nosc":"zapperait","lemma":"zapper","pos":"VER"} ,
		{"word":"zappes","word_nosc":"zappes","lemma":"zapper","pos":"VER"} ,
		{"word":"zappez","word_nosc":"zappez","lemma":"zapper","pos":"VER"} ,
		{"word":"zappé","word_nosc":"zappe","lemma":"zapper","pos":"VER"} ,
		{"word":"zester","word_nosc":"zester","lemma":"zester","pos":"VER"} ,
		{"word":"zieute","word_nosc":"zieute","lemma":"zieuter","pos":"VER"} ,
		{"word":"zieutent","word_nosc":"zieutent","lemma":"zieuter","pos":"VER"} ,
		{"word":"zieuter","word_nosc":"zieuter","lemma":"zieuter","pos":"VER"} ,
		{"word":"zieutes","word_nosc":"zieutes","lemma":"zieuter","pos":"VER"} ,
		{"word":"zieutez","word_nosc":"zieutez","lemma":"zieuter","pos":"VER"} ,
		{"word":"zigouillais","word_nosc":"zigouillais","lemma":"zigouiller","pos":"VER"} ,
		{"word":"zigouillait","word_nosc":"zigouillait","lemma":"zigouiller","pos":"VER"} ,
		{"word":"zigouille","word_nosc":"zigouille","lemma":"zigouiller","pos":"VER"} ,
		{"word":"zigouillent","word_nosc":"zigouillent","lemma":"zigouiller","pos":"VER"} ,
		{"word":"zigouiller","word_nosc":"zigouiller","lemma":"zigouiller","pos":"VER"} ,
		{"word":"zigouillez","word_nosc":"zigouillez","lemma":"zigouiller","pos":"VER"} ,
		{"word":"zigouillé","word_nosc":"zigouille","lemma":"zigouiller","pos":"VER"} ,
		{"word":"zigouillées","word_nosc":"zigouillees","lemma":"zigouiller","pos":"VER"} ,
		{"word":"zigouillés","word_nosc":"zigouilles","lemma":"zigouiller","pos":"VER"} ,
		{"word":"zigzagua","word_nosc":"zigzagua","lemma":"zigzaguer","pos":"VER"} ,
		{"word":"zigzaguai","word_nosc":"zigzaguai","lemma":"zigzaguer","pos":"VER"} ,
		{"word":"zigzaguaient","word_nosc":"zigzaguaient","lemma":"zigzaguer","pos":"VER"} ,
		{"word":"zigzaguait","word_nosc":"zigzaguait","lemma":"zigzaguer","pos":"VER"} ,
		{"word":"zigzaguant","word_nosc":"zigzaguant","lemma":"zigzaguer","pos":"VER"} ,
		{"word":"zigzague","word_nosc":"zigzague","lemma":"zigzaguer","pos":"VER"} ,
		{"word":"zigzaguent","word_nosc":"zigzaguent","lemma":"zigzaguer","pos":"VER"} ,
		{"word":"zigzaguer","word_nosc":"zigzaguer","lemma":"zigzaguer","pos":"VER"} ,
		{"word":"zigzaguèrent","word_nosc":"zigzaguerent","lemma":"zigzaguer","pos":"VER"} ,
		{"word":"zigzagué","word_nosc":"zigzague","lemma":"zigzaguer","pos":"VER"} ,
		{"word":"zigzaguée","word_nosc":"zigzaguee","lemma":"zigzaguer","pos":"VER"} ,
		{"word":"zingué","word_nosc":"zingue","lemma":"zinguer","pos":"VER"} ,
		{"word":"zingués","word_nosc":"zingues","lemma":"zinguer","pos":"VER"} ,
		{"word":"zippa","word_nosc":"zippa","lemma":"zipper","pos":"VER"} ,
		{"word":"zippait","word_nosc":"zippait","lemma":"zipper","pos":"VER"} ,
		{"word":"zipper","word_nosc":"zipper","lemma":"zipper","pos":"VER"} ,
		{"word":"zippé","word_nosc":"zippe","lemma":"zipper","pos":"VER"} ,
		{"word":"zona","word_nosc":"zona","lemma":"zoner","pos":"VER"} ,
		{"word":"zonait","word_nosc":"zonait","lemma":"zoner","pos":"VER"} ,
		{"word":"zonant","word_nosc":"zonant","lemma":"zoner","pos":"VER"} ,
		{"word":"zoner","word_nosc":"zoner","lemma":"zoner","pos":"VER"} ,
		{"word":"zonera","word_nosc":"zonera","lemma":"zoner","pos":"VER"} ,
		{"word":"zoné","word_nosc":"zone","lemma":"zoner","pos":"VER"} ,
		{"word":"zoome","word_nosc":"zoome","lemma":"zoomer","pos":"VER"} ,
		{"word":"zoomer","word_nosc":"zoomer","lemma":"zoomer","pos":"VER"} ,
		{"word":"zoomes","word_nosc":"zoomes","lemma":"zoomer","pos":"VER"} ,
		{"word":"zoomez","word_nosc":"zoomez","lemma":"zoomer","pos":"VER"} ,
		{"word":"zozotait","word_nosc":"zozotait","lemma":"zozoter","pos":"VER"} ,
		{"word":"zozotant","word_nosc":"zozotant","lemma":"zozoter","pos":"VER"} ,
		{"word":"zozote","word_nosc":"zozote","lemma":"zozoter","pos":"VER"} ,
		{"word":"zozotes","word_nosc":"zozotes","lemma":"zozoter","pos":"VER"} ,
		{"word":"zozotez","word_nosc":"zozotez","lemma":"zozoter","pos":"VER"} ,
		{"word":"zyeuta","word_nosc":"zyeuta","lemma":"zyeuter","pos":"VER"} ,
		{"word":"zyeutais","word_nosc":"zyeutais","lemma":"zyeuter","pos":"VER"} ,
		{"word":"zyeutait","word_nosc":"zyeutait","lemma":"zyeuter","pos":"VER"} ,
		{"word":"zyeutant","word_nosc":"zyeutant","lemma":"zyeuter","pos":"VER"} ,
		{"word":"zyeute","word_nosc":"zyeute","lemma":"zyeuter","pos":"VER"} ,
		{"word":"zyeuter","word_nosc":"zyeuter","lemma":"zyeuter","pos":"VER"} ,
		{"word":"zyeutes","word_nosc":"zyeutes","lemma":"zyeuter","pos":"VER"} ,
		{"word":"zyeuté","word_nosc":"zyeute","lemma":"zyeuter","pos":"VER"} ,
		{"word":"zèbre","word_nosc":"zebre","lemma":"zébrer","pos":"VER"} ,
		{"word":"zèbrent","word_nosc":"zebrent","lemma":"zébrer","pos":"VER"} ,
		{"word":"zèbres","word_nosc":"zebres","lemma":"zébrer","pos":"VER"} ,
		{"word":"zébra","word_nosc":"zebra","lemma":"zébrer","pos":"VER"} ,
		{"word":"zébraient","word_nosc":"zebraient","lemma":"zébrer","pos":"VER"} ,
		{"word":"zébrait","word_nosc":"zebrait","lemma":"zébrer","pos":"VER"} ,
		{"word":"zébrant","word_nosc":"zebrant","lemma":"zébrer","pos":"VER"} ,
		{"word":"zébrer","word_nosc":"zebrer","lemma":"zébrer","pos":"VER"} ,
		{"word":"zébré","word_nosc":"zebre","lemma":"zébrer","pos":"VER"} ,
		{"word":"zébrée","word_nosc":"zebree","lemma":"zébrer","pos":"VER"} ,
		{"word":"zébrées","word_nosc":"zebrees","lemma":"zébrer","pos":"VER"} ,
		{"word":"zébrés","word_nosc":"zebres","lemma":"zébrer","pos":"VER"} ,
		{"word":"zézaie","word_nosc":"zezaie","lemma":"zézayer","pos":"VER"} ,
		{"word":"zézaient","word_nosc":"zezaient","lemma":"zézayer","pos":"VER"} ,
		{"word":"zézayaient","word_nosc":"zezayaient","lemma":"zézayer","pos":"VER"} ,
		{"word":"zézayant","word_nosc":"zezayant","lemma":"zézayer","pos":"VER"} ,
		{"word":"zézaye","word_nosc":"zezaye","lemma":"zézayer","pos":"VER"} ,
		{"word":"zézayer","word_nosc":"zezayer","lemma":"zézayer","pos":"VER"} ,
		{"word":"ânonna","word_nosc":"anonna","lemma":"ânonner","pos":"VER"} ,
		{"word":"ânonnaient","word_nosc":"anonnaient","lemma":"ânonner","pos":"VER"} ,
		{"word":"ânonnait","word_nosc":"anonnait","lemma":"ânonner","pos":"VER"} ,
		{"word":"ânonnant","word_nosc":"anonnant","lemma":"ânonner","pos":"VER"} ,
		{"word":"ânonne","word_nosc":"anonne","lemma":"ânonner","pos":"VER"} ,
		{"word":"ânonner","word_nosc":"anonner","lemma":"ânonner","pos":"VER"} ,
		{"word":"ânonnions","word_nosc":"anonnions","lemma":"ânonner","pos":"VER"} ,
		{"word":"ânonnés","word_nosc":"anonnes","lemma":"ânonner","pos":"VER"} ,
		{"word":"ébahi","word_nosc":"ebahi","lemma":"ébahir","pos":"VER"} ,
		{"word":"ébahie","word_nosc":"ebahie","lemma":"ébahir","pos":"VER"} ,
		{"word":"ébahir","word_nosc":"ebahir","lemma":"ébahir","pos":"VER"} ,
		{"word":"ébahirons","word_nosc":"ebahirons","lemma":"ébahir","pos":"VER"} ,
		{"word":"ébahis","word_nosc":"ebahis","lemma":"ébahir","pos":"VER"} ,
		{"word":"ébahissait","word_nosc":"ebahissait","lemma":"ébahir","pos":"VER"} ,
		{"word":"ébahissant","word_nosc":"ebahissant","lemma":"ébahir","pos":"VER"} ,
		{"word":"ébahissent","word_nosc":"ebahissent","lemma":"ébahir","pos":"VER"} ,
		{"word":"ébahissons","word_nosc":"ebahissons","lemma":"ébahir","pos":"VER"} ,
		{"word":"ébahit","word_nosc":"ebahit","lemma":"ébahir","pos":"VER"} ,
		{"word":"ébarbait","word_nosc":"ebarbait","lemma":"ébarber","pos":"VER"} ,
		{"word":"ébarber","word_nosc":"ebarber","lemma":"ébarber","pos":"VER"} ,
		{"word":"ébarbé","word_nosc":"ebarbe","lemma":"ébarber","pos":"VER"} ,
		{"word":"ébat","word_nosc":"ebat","lemma":"ébattre","pos":"VER"} ,
		{"word":"ébattaient","word_nosc":"ebattaient","lemma":"ébattre","pos":"VER"} ,
		{"word":"ébattait","word_nosc":"ebattait","lemma":"ébattre","pos":"VER"} ,
		{"word":"ébattant","word_nosc":"ebattant","lemma":"ébattre","pos":"VER"} ,
		{"word":"ébattent","word_nosc":"ebattent","lemma":"ébattre","pos":"VER"} ,
		{"word":"ébattit","word_nosc":"ebattit","lemma":"ébattre","pos":"VER"} ,
		{"word":"ébattons","word_nosc":"ebattons","lemma":"ébattre","pos":"VER"} ,
		{"word":"ébattre","word_nosc":"ebattre","lemma":"ébattre","pos":"VER"} ,
		{"word":"ébattît","word_nosc":"ebattit","lemma":"ébattre","pos":"VER"} ,
		{"word":"ébaubi","word_nosc":"ebaubi","lemma":"ébaubir","pos":"VER"} ,
		{"word":"ébaubie","word_nosc":"ebaubie","lemma":"ébaubir","pos":"VER"} ,
		{"word":"ébaubis","word_nosc":"ebaubis","lemma":"ébaubir","pos":"VER"} ,
		{"word":"ébaubissaient","word_nosc":"ebaubissaient","lemma":"ébaubir","pos":"VER"} ,
		{"word":"ébaucha","word_nosc":"ebaucha","lemma":"ébaucher","pos":"VER"} ,
		{"word":"ébauchai","word_nosc":"ebauchai","lemma":"ébaucher","pos":"VER"} ,
		{"word":"ébauchaient","word_nosc":"ebauchaient","lemma":"ébaucher","pos":"VER"} ,
		{"word":"ébauchais","word_nosc":"ebauchais","lemma":"ébaucher","pos":"VER"} ,
		{"word":"ébauchait","word_nosc":"ebauchait","lemma":"ébaucher","pos":"VER"} ,
		{"word":"ébauchant","word_nosc":"ebauchant","lemma":"ébaucher","pos":"VER"} ,
		{"word":"ébauche","word_nosc":"ebauche","lemma":"ébaucher","pos":"VER"} ,
		{"word":"ébauchent","word_nosc":"ebauchent","lemma":"ébaucher","pos":"VER"} ,
		{"word":"ébaucher","word_nosc":"ebaucher","lemma":"ébaucher","pos":"VER"} ,
		{"word":"ébauchons","word_nosc":"ebauchons","lemma":"ébaucher","pos":"VER"} ,
		{"word":"ébauchât","word_nosc":"ebauchat","lemma":"ébaucher","pos":"VER"} ,
		{"word":"ébauchèrent","word_nosc":"ebaucherent","lemma":"ébaucher","pos":"VER"} ,
		{"word":"ébauché","word_nosc":"ebauche","lemma":"ébaucher","pos":"VER"} ,
		{"word":"ébauchée","word_nosc":"ebauchee","lemma":"ébaucher","pos":"VER"} ,
		{"word":"ébaudi","word_nosc":"ebaudi","lemma":"ébaudir","pos":"VER"} ,
		{"word":"éberlua","word_nosc":"eberlua","lemma":"éberluer","pos":"VER"} ,
		{"word":"éberluait","word_nosc":"eberluait","lemma":"éberluer","pos":"VER"} ,
		{"word":"éberlue","word_nosc":"eberlue","lemma":"éberluer","pos":"VER"} ,
		{"word":"éberluer","word_nosc":"eberluer","lemma":"éberluer","pos":"VER"} ,
		{"word":"éberlué","word_nosc":"eberlue","lemma":"éberluer","pos":"VER"} ,
		{"word":"éberluée","word_nosc":"eberluee","lemma":"éberluer","pos":"VER"} ,
		{"word":"éberlués","word_nosc":"eberlues","lemma":"éberluer","pos":"VER"} ,
		{"word":"ébloui","word_nosc":"ebloui","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouie","word_nosc":"eblouie","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouies","word_nosc":"eblouies","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouir","word_nosc":"eblouir","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouira","word_nosc":"eblouira","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouirai","word_nosc":"eblouirai","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouirait","word_nosc":"eblouirait","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouirent","word_nosc":"eblouirent","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouirez","word_nosc":"eblouirez","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouis","word_nosc":"eblouis","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouissaient","word_nosc":"eblouissaient","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouissait","word_nosc":"eblouissait","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouissant","word_nosc":"eblouissant","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouisse","word_nosc":"eblouisse","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouissent","word_nosc":"eblouissent","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouisses","word_nosc":"eblouisses","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouissez","word_nosc":"eblouissez","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouissions","word_nosc":"eblouissions","lemma":"éblouir","pos":"VER"} ,
		{"word":"éblouit","word_nosc":"eblouit","lemma":"éblouir","pos":"VER"} ,
		{"word":"éborgna","word_nosc":"eborgna","lemma":"éborgner","pos":"VER"} ,
		{"word":"éborgne","word_nosc":"eborgne","lemma":"éborgner","pos":"VER"} ,
		{"word":"éborgner","word_nosc":"eborgner","lemma":"éborgner","pos":"VER"} ,
		{"word":"éborgné","word_nosc":"eborgne","lemma":"éborgner","pos":"VER"} ,
		{"word":"ébouillanta","word_nosc":"ebouillanta","lemma":"ébouillanter","pos":"VER"} ,
		{"word":"ébouillante","word_nosc":"ebouillante","lemma":"ébouillanter","pos":"VER"} ,
		{"word":"ébouillanter","word_nosc":"ebouillanter","lemma":"ébouillanter","pos":"VER"} ,
		{"word":"ébouillanté","word_nosc":"ebouillante","lemma":"ébouillanter","pos":"VER"} ,
		{"word":"ébouillantée","word_nosc":"ebouillantee","lemma":"ébouillanter","pos":"VER"} ,
		{"word":"ébouillantés","word_nosc":"ebouillantes","lemma":"ébouillanter","pos":"VER"} ,
		{"word":"éboula","word_nosc":"eboula","lemma":"ébouler","pos":"VER"} ,
		{"word":"éboulaient","word_nosc":"eboulaient","lemma":"ébouler","pos":"VER"} ,
		{"word":"éboulait","word_nosc":"eboulait","lemma":"ébouler","pos":"VER"} ,
		{"word":"éboulant","word_nosc":"eboulant","lemma":"ébouler","pos":"VER"} ,
		{"word":"éboule","word_nosc":"eboule","lemma":"ébouler","pos":"VER"} ,
		{"word":"éboulent","word_nosc":"eboulent","lemma":"ébouler","pos":"VER"} ,
		{"word":"ébouler","word_nosc":"ebouler","lemma":"ébouler","pos":"VER"} ,
		{"word":"éboulèrent","word_nosc":"eboulerent","lemma":"ébouler","pos":"VER"} ,
		{"word":"éboulé","word_nosc":"eboule","lemma":"ébouler","pos":"VER"} ,
		{"word":"éboulée","word_nosc":"eboulee","lemma":"ébouler","pos":"VER"} ,
		{"word":"éboulées","word_nosc":"eboulees","lemma":"ébouler","pos":"VER"} ,
		{"word":"éboulés","word_nosc":"eboules","lemma":"ébouler","pos":"VER"} ,
		{"word":"ébouriffa","word_nosc":"ebouriffa","lemma":"ébouriffer","pos":"VER"} ,
		{"word":"ébouriffaient","word_nosc":"ebouriffaient","lemma":"ébouriffer","pos":"VER"} ,
		{"word":"ébouriffait","word_nosc":"ebouriffait","lemma":"ébouriffer","pos":"VER"} ,
		{"word":"ébouriffant","word_nosc":"ebouriffant","lemma":"ébouriffer","pos":"VER"} ,
		{"word":"ébouriffe","word_nosc":"ebouriffe","lemma":"ébouriffer","pos":"VER"} ,
		{"word":"ébouriffent","word_nosc":"ebouriffent","lemma":"ébouriffer","pos":"VER"} ,
		{"word":"ébouriffer","word_nosc":"ebouriffer","lemma":"ébouriffer","pos":"VER"} ,
		{"word":"ébouriffé","word_nosc":"ebouriffe","lemma":"ébouriffer","pos":"VER"} ,
		{"word":"ébouriffée","word_nosc":"ebouriffee","lemma":"ébouriffer","pos":"VER"} ,
		{"word":"ébouriffées","word_nosc":"ebouriffees","lemma":"ébouriffer","pos":"VER"} ,
		{"word":"ébouriffés","word_nosc":"ebouriffes","lemma":"ébouriffer","pos":"VER"} ,
		{"word":"ébouser","word_nosc":"ebouser","lemma":"ébouser","pos":"VER"} ,
		{"word":"ébouzer","word_nosc":"ebouzer","lemma":"ébouzer","pos":"VER"} ,
		{"word":"ébouzé","word_nosc":"ebouze","lemma":"ébouzer","pos":"VER"} ,
		{"word":"éboué","word_nosc":"eboue","lemma":"ébouer","pos":"VER"} ,
		{"word":"ébrancha","word_nosc":"ebrancha","lemma":"ébrancher","pos":"VER"} ,
		{"word":"ébranchait","word_nosc":"ebranchait","lemma":"ébrancher","pos":"VER"} ,
		{"word":"ébranchant","word_nosc":"ebranchant","lemma":"ébrancher","pos":"VER"} ,
		{"word":"ébrancher","word_nosc":"ebrancher","lemma":"ébrancher","pos":"VER"} ,
		{"word":"ébrancherez","word_nosc":"ebrancherez","lemma":"ébrancher","pos":"VER"} ,
		{"word":"ébranchés","word_nosc":"ebranches","lemma":"ébrancher","pos":"VER"} ,
		{"word":"ébranla","word_nosc":"ebranla","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranlaient","word_nosc":"ebranlaient","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranlait","word_nosc":"ebranlait","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranlant","word_nosc":"ebranlant","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranle","word_nosc":"ebranle","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranlent","word_nosc":"ebranlent","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranler","word_nosc":"ebranler","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranlera","word_nosc":"ebranlera","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranlerai","word_nosc":"ebranlerai","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranleraient","word_nosc":"ebranleraient","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranlerais","word_nosc":"ebranlerais","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranlerait","word_nosc":"ebranlerait","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranlât","word_nosc":"ebranlat","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranlèrent","word_nosc":"ebranlerent","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranlé","word_nosc":"ebranle","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranlée","word_nosc":"ebranlee","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranlées","word_nosc":"ebranlees","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébranlés","word_nosc":"ebranles","lemma":"ébranler","pos":"VER"} ,
		{"word":"ébroua","word_nosc":"ebroua","lemma":"ébrouer","pos":"VER"} ,
		{"word":"ébrouaient","word_nosc":"ebrouaient","lemma":"ébrouer","pos":"VER"} ,
		{"word":"ébrouais","word_nosc":"ebrouais","lemma":"ébrouer","pos":"VER"} ,
		{"word":"ébrouait","word_nosc":"ebrouait","lemma":"ébrouer","pos":"VER"} ,
		{"word":"ébrouant","word_nosc":"ebrouant","lemma":"ébrouer","pos":"VER"} ,
		{"word":"ébroue","word_nosc":"ebroue","lemma":"ébrouer","pos":"VER"} ,
		{"word":"ébrouent","word_nosc":"ebrouent","lemma":"ébrouer","pos":"VER"} ,
		{"word":"ébrouer","word_nosc":"ebrouer","lemma":"ébrouer","pos":"VER"} ,
		{"word":"ébrouèrent","word_nosc":"ebrouerent","lemma":"ébrouer","pos":"VER"} ,
		{"word":"ébroué","word_nosc":"ebroue","lemma":"ébrouer","pos":"VER"} ,
		{"word":"ébruita","word_nosc":"ebruita","lemma":"ébruiter","pos":"VER"} ,
		{"word":"ébruite","word_nosc":"ebruite","lemma":"ébruiter","pos":"VER"} ,
		{"word":"ébruitent","word_nosc":"ebruitent","lemma":"ébruiter","pos":"VER"} ,
		{"word":"ébruiter","word_nosc":"ebruiter","lemma":"ébruiter","pos":"VER"} ,
		{"word":"ébruitera","word_nosc":"ebruitera","lemma":"ébruiter","pos":"VER"} ,
		{"word":"ébruiterait","word_nosc":"ebruiterait","lemma":"ébruiter","pos":"VER"} ,
		{"word":"ébruitez","word_nosc":"ebruitez","lemma":"ébruiter","pos":"VER"} ,
		{"word":"ébruitons","word_nosc":"ebruitons","lemma":"ébruiter","pos":"VER"} ,
		{"word":"ébruitât","word_nosc":"ebruitat","lemma":"ébruiter","pos":"VER"} ,
		{"word":"ébruité","word_nosc":"ebruite","lemma":"ébruiter","pos":"VER"} ,
		{"word":"ébruitée","word_nosc":"ebruitee","lemma":"ébruiter","pos":"VER"} ,
		{"word":"ébrèche","word_nosc":"ebreche","lemma":"ébrécher","pos":"VER"} ,
		{"word":"ébréchaient","word_nosc":"ebrechaient","lemma":"ébrécher","pos":"VER"} ,
		{"word":"ébrécher","word_nosc":"ebrecher","lemma":"ébrécher","pos":"VER"} ,
		{"word":"ébréché","word_nosc":"ebreche","lemma":"ébrécher","pos":"VER"} ,
		{"word":"ébréchée","word_nosc":"ebrechee","lemma":"ébrécher","pos":"VER"} ,
		{"word":"ébréchées","word_nosc":"ebrechees","lemma":"ébrécher","pos":"VER"} ,
		{"word":"ébréchés","word_nosc":"ebreches","lemma":"ébrécher","pos":"VER"} ,
		{"word":"écaillaient","word_nosc":"ecaillaient","lemma":"écailler","pos":"VER"} ,
		{"word":"écaillait","word_nosc":"ecaillait","lemma":"écailler","pos":"VER"} ,
		{"word":"écaillant","word_nosc":"ecaillant","lemma":"écailler","pos":"VER"} ,
		{"word":"écaille","word_nosc":"ecaille","lemma":"écailler","pos":"VER"} ,
		{"word":"écaillent","word_nosc":"ecaillent","lemma":"écailler","pos":"VER"} ,
		{"word":"écailler","word_nosc":"ecailler","lemma":"écailler","pos":"VER"} ,
		{"word":"écaillera","word_nosc":"ecaillera","lemma":"écailler","pos":"VER"} ,
		{"word":"écaillerai","word_nosc":"ecaillerai","lemma":"écailler","pos":"VER"} ,
		{"word":"écailles","word_nosc":"ecailles","lemma":"écailler","pos":"VER"} ,
		{"word":"écaillé","word_nosc":"ecaille","lemma":"écailler","pos":"VER"} ,
		{"word":"écaillée","word_nosc":"ecaillee","lemma":"écailler","pos":"VER"} ,
		{"word":"écaillées","word_nosc":"ecaillees","lemma":"écailler","pos":"VER"} ,
		{"word":"écaillés","word_nosc":"ecailles","lemma":"écailler","pos":"VER"} ,
		{"word":"écalait","word_nosc":"ecalait","lemma":"écaler","pos":"VER"} ,
		{"word":"écaler","word_nosc":"ecaler","lemma":"écaler","pos":"VER"} ,
		{"word":"écarquilla","word_nosc":"ecarquilla","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarquillai","word_nosc":"ecarquillai","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarquillaient","word_nosc":"ecarquillaient","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarquillais","word_nosc":"ecarquillais","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarquillait","word_nosc":"ecarquillait","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarquillant","word_nosc":"ecarquillant","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarquille","word_nosc":"ecarquille","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarquillent","word_nosc":"ecarquillent","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarquiller","word_nosc":"ecarquiller","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarquillez","word_nosc":"ecarquillez","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarquillions","word_nosc":"ecarquillions","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarquillèrent","word_nosc":"ecarquillerent","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarquillé","word_nosc":"ecarquille","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarquillée","word_nosc":"ecarquillee","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarquillées","word_nosc":"ecarquillees","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarquillés","word_nosc":"ecarquilles","lemma":"écarquiller","pos":"VER"} ,
		{"word":"écarta","word_nosc":"ecarta","lemma":"écarter","pos":"VER"} ,
		{"word":"écartai","word_nosc":"ecartai","lemma":"écarter","pos":"VER"} ,
		{"word":"écartaient","word_nosc":"ecartaient","lemma":"écarter","pos":"VER"} ,
		{"word":"écartais","word_nosc":"ecartais","lemma":"écarter","pos":"VER"} ,
		{"word":"écartait","word_nosc":"ecartait","lemma":"écarter","pos":"VER"} ,
		{"word":"écartant","word_nosc":"ecartant","lemma":"écarter","pos":"VER"} ,
		{"word":"écarte","word_nosc":"ecarte","lemma":"écarter","pos":"VER"} ,
		{"word":"écartela","word_nosc":"ecartela","lemma":"écarteler","pos":"VER"} ,
		{"word":"écartelaient","word_nosc":"ecartelaient","lemma":"écarteler","pos":"VER"} ,
		{"word":"écartelait","word_nosc":"ecartelait","lemma":"écarteler","pos":"VER"} ,
		{"word":"écartelant","word_nosc":"ecartelant","lemma":"écarteler","pos":"VER"} ,
		{"word":"écarteler","word_nosc":"ecarteler","lemma":"écarteler","pos":"VER"} ,
		{"word":"écartelé","word_nosc":"ecartele","lemma":"écarteler","pos":"VER"} ,
		{"word":"écartelée","word_nosc":"ecartelee","lemma":"écarteler","pos":"VER"} ,
		{"word":"écartelées","word_nosc":"ecartelees","lemma":"écarteler","pos":"VER"} ,
		{"word":"écartelés","word_nosc":"ecarteles","lemma":"écarteler","pos":"VER"} ,
		{"word":"écartent","word_nosc":"ecartent","lemma":"écarter","pos":"VER"} ,
		{"word":"écarter","word_nosc":"ecarter","lemma":"écarter","pos":"VER"} ,
		{"word":"écartera","word_nosc":"ecartera","lemma":"écarter","pos":"VER"} ,
		{"word":"écarteraient","word_nosc":"ecarteraient","lemma":"écarter","pos":"VER"} ,
		{"word":"écarterait","word_nosc":"ecarterait","lemma":"écarter","pos":"VER"} ,
		{"word":"écarteras","word_nosc":"ecarteras","lemma":"écarter","pos":"VER"} ,
		{"word":"écarterez","word_nosc":"ecarterez","lemma":"écarter","pos":"VER"} ,
		{"word":"écarteront","word_nosc":"ecarteront","lemma":"écarter","pos":"VER"} ,
		{"word":"écartes","word_nosc":"ecartes","lemma":"écarter","pos":"VER"} ,
		{"word":"écartez","word_nosc":"ecartez","lemma":"écarter","pos":"VER"} ,
		{"word":"écartions","word_nosc":"ecartions","lemma":"écarter","pos":"VER"} ,
		{"word":"écartons","word_nosc":"ecartons","lemma":"écarter","pos":"VER"} ,
		{"word":"écartâmes","word_nosc":"ecartames","lemma":"écarter","pos":"VER"} ,
		{"word":"écartât","word_nosc":"ecartat","lemma":"écarter","pos":"VER"} ,
		{"word":"écartèle","word_nosc":"ecartele","lemma":"écarteler","pos":"VER"} ,
		{"word":"écartèlent","word_nosc":"ecartelent","lemma":"écarteler","pos":"VER"} ,
		{"word":"écartèleront","word_nosc":"ecarteleront","lemma":"écarteler","pos":"VER"} ,
		{"word":"écartèrent","word_nosc":"ecarterent","lemma":"écarter","pos":"VER"} ,
		{"word":"écarté","word_nosc":"ecarte","lemma":"écarter","pos":"VER"} ,
		{"word":"écartée","word_nosc":"ecartee","lemma":"écarter","pos":"VER"} ,
		{"word":"écartées","word_nosc":"ecartees","lemma":"écarter","pos":"VER"} ,
		{"word":"écartés","word_nosc":"ecartes","lemma":"écarter","pos":"VER"} ,
		{"word":"échafauda","word_nosc":"echafauda","lemma":"échafauder","pos":"VER"} ,
		{"word":"échafaudaient","word_nosc":"echafaudaient","lemma":"échafauder","pos":"VER"} ,
		{"word":"échafaudais","word_nosc":"echafaudais","lemma":"échafauder","pos":"VER"} ,
		{"word":"échafaudait","word_nosc":"echafaudait","lemma":"échafauder","pos":"VER"} ,
		{"word":"échafaudant","word_nosc":"echafaudant","lemma":"échafauder","pos":"VER"} ,
		{"word":"échafaude","word_nosc":"echafaude","lemma":"échafauder","pos":"VER"} ,
		{"word":"échafaudent","word_nosc":"echafaudent","lemma":"échafauder","pos":"VER"} ,
		{"word":"échafauder","word_nosc":"echafauder","lemma":"échafauder","pos":"VER"} ,
		{"word":"échafaudons","word_nosc":"echafaudons","lemma":"échafauder","pos":"VER"} ,
		{"word":"échafaudèrent","word_nosc":"echafauderent","lemma":"échafauder","pos":"VER"} ,
		{"word":"échafaudé","word_nosc":"echafaude","lemma":"échafauder","pos":"VER"} ,
		{"word":"échafaudées","word_nosc":"echafaudees","lemma":"échafauder","pos":"VER"} ,
		{"word":"échafaudés","word_nosc":"echafaudes","lemma":"échafauder","pos":"VER"} ,
		{"word":"échancraient","word_nosc":"echancraient","lemma":"échancrer","pos":"VER"} ,
		{"word":"échancre","word_nosc":"echancre","lemma":"échancrer","pos":"VER"} ,
		{"word":"échancrer","word_nosc":"echancrer","lemma":"échancrer","pos":"VER"} ,
		{"word":"échancré","word_nosc":"echancre","lemma":"échancrer","pos":"VER"} ,
		{"word":"échancrée","word_nosc":"echancree","lemma":"échancrer","pos":"VER"} ,
		{"word":"échange","word_nosc":"echange","lemma":"échanger","pos":"VER"} ,
		{"word":"échangea","word_nosc":"echangea","lemma":"échanger","pos":"VER"} ,
		{"word":"échangeai","word_nosc":"echangeai","lemma":"échanger","pos":"VER"} ,
		{"word":"échangeaient","word_nosc":"echangeaient","lemma":"échanger","pos":"VER"} ,
		{"word":"échangeais","word_nosc":"echangeais","lemma":"échanger","pos":"VER"} ,
		{"word":"échangeait","word_nosc":"echangeait","lemma":"échanger","pos":"VER"} ,
		{"word":"échangeant","word_nosc":"echangeant","lemma":"échanger","pos":"VER"} ,
		{"word":"échangent","word_nosc":"echangent","lemma":"échanger","pos":"VER"} ,
		{"word":"échangeons","word_nosc":"echangeons","lemma":"échanger","pos":"VER"} ,
		{"word":"échanger","word_nosc":"echanger","lemma":"échanger","pos":"VER"} ,
		{"word":"échangera","word_nosc":"echangera","lemma":"échanger","pos":"VER"} ,
		{"word":"échangerai","word_nosc":"echangerai","lemma":"échanger","pos":"VER"} ,
		{"word":"échangeraient","word_nosc":"echangeraient","lemma":"échanger","pos":"VER"} ,
		{"word":"échangerais","word_nosc":"echangerais","lemma":"échanger","pos":"VER"} ,
		{"word":"échangerait","word_nosc":"echangerait","lemma":"échanger","pos":"VER"} ,
		{"word":"échangeras","word_nosc":"echangeras","lemma":"échanger","pos":"VER"} ,
		{"word":"échangerez","word_nosc":"echangerez","lemma":"échanger","pos":"VER"} ,
		{"word":"échangeriez","word_nosc":"echangeriez","lemma":"échanger","pos":"VER"} ,
		{"word":"échangerions","word_nosc":"echangerions","lemma":"échanger","pos":"VER"} ,
		{"word":"échangerons","word_nosc":"echangerons","lemma":"échanger","pos":"VER"} ,
		{"word":"échangeront","word_nosc":"echangeront","lemma":"échanger","pos":"VER"} ,
		{"word":"échanges","word_nosc":"echanges","lemma":"échanger","pos":"VER"} ,
		{"word":"échangez","word_nosc":"echangez","lemma":"échanger","pos":"VER"} ,
		{"word":"échangeâmes","word_nosc":"echangeames","lemma":"échanger","pos":"VER"} ,
		{"word":"échangiez","word_nosc":"echangiez","lemma":"échanger","pos":"VER"} ,
		{"word":"échangions","word_nosc":"echangions","lemma":"échanger","pos":"VER"} ,
		{"word":"échangèrent","word_nosc":"echangerent","lemma":"échanger","pos":"VER"} ,
		{"word":"échangé","word_nosc":"echange","lemma":"échanger","pos":"VER"} ,
		{"word":"échangée","word_nosc":"echangee","lemma":"échanger","pos":"VER"} ,
		{"word":"échangées","word_nosc":"echangees","lemma":"échanger","pos":"VER"} ,
		{"word":"échangés","word_nosc":"echanges","lemma":"échanger","pos":"VER"} ,
		{"word":"échantillonne","word_nosc":"echantillonne","lemma":"échantillonner","pos":"VER"} ,
		{"word":"échappa","word_nosc":"echappa","lemma":"échapper","pos":"VER"} ,
		{"word":"échappai","word_nosc":"echappai","lemma":"échapper","pos":"VER"} ,
		{"word":"échappaient","word_nosc":"echappaient","lemma":"échapper","pos":"VER"} ,
		{"word":"échappais","word_nosc":"echappais","lemma":"échapper","pos":"VER"} ,
		{"word":"échappait","word_nosc":"echappait","lemma":"échapper","pos":"VER"} ,
		{"word":"échappant","word_nosc":"echappant","lemma":"échapper","pos":"VER"} ,
		{"word":"échappe","word_nosc":"echappe","lemma":"échapper","pos":"VER"} ,
		{"word":"échappent","word_nosc":"echappent","lemma":"échapper","pos":"VER"} ,
		{"word":"échapper","word_nosc":"echapper","lemma":"échapper","pos":"VER"} ,
		{"word":"échappera","word_nosc":"echappera","lemma":"échapper","pos":"VER"} ,
		{"word":"échapperai","word_nosc":"echapperai","lemma":"échapper","pos":"VER"} ,
		{"word":"échapperaient","word_nosc":"echapperaient","lemma":"échapper","pos":"VER"} ,
		{"word":"échapperais","word_nosc":"echapperais","lemma":"échapper","pos":"VER"} ,
		{"word":"échapperait","word_nosc":"echapperait","lemma":"échapper","pos":"VER"} ,
		{"word":"échapperas","word_nosc":"echapperas","lemma":"échapper","pos":"VER"} ,
		{"word":"échapperez","word_nosc":"echapperez","lemma":"échapper","pos":"VER"} ,
		{"word":"échapperiez","word_nosc":"echapperiez","lemma":"échapper","pos":"VER"} ,
		{"word":"échapperions","word_nosc":"echapperions","lemma":"échapper","pos":"VER"} ,
		{"word":"échapperons","word_nosc":"echapperons","lemma":"échapper","pos":"VER"} ,
		{"word":"échapperont","word_nosc":"echapperont","lemma":"échapper","pos":"VER"} ,
		{"word":"échappes","word_nosc":"echappes","lemma":"échapper","pos":"VER"} ,
		{"word":"échappez","word_nosc":"echappez","lemma":"échapper","pos":"VER"} ,
		{"word":"échappiez","word_nosc":"echappiez","lemma":"échapper","pos":"VER"} ,
		{"word":"échappions","word_nosc":"echappions","lemma":"échapper","pos":"VER"} ,
		{"word":"échappons","word_nosc":"echappons","lemma":"échapper","pos":"VER"} ,
		{"word":"échappâmes","word_nosc":"echappames","lemma":"échapper","pos":"VER"} ,
		{"word":"échappât","word_nosc":"echappat","lemma":"échapper","pos":"VER"} ,
		{"word":"échappèrent","word_nosc":"echapperent","lemma":"échapper","pos":"VER"} ,
		{"word":"échappé","word_nosc":"echappe","lemma":"échapper","pos":"VER"} ,
		{"word":"échappée","word_nosc":"echappee","lemma":"échapper","pos":"VER"} ,
		{"word":"échappées","word_nosc":"echappees","lemma":"échapper","pos":"VER"} ,
		{"word":"échappés","word_nosc":"echappes","lemma":"échapper","pos":"VER"} ,
		{"word":"écharnait","word_nosc":"echarnait","lemma":"écharner","pos":"VER"} ,
		{"word":"écharner","word_nosc":"echarner","lemma":"écharner","pos":"VER"} ,
		{"word":"écharpe","word_nosc":"echarpe","lemma":"écharper","pos":"VER"} ,
		{"word":"écharpent","word_nosc":"echarpent","lemma":"écharper","pos":"VER"} ,
		{"word":"écharper","word_nosc":"echarper","lemma":"écharper","pos":"VER"} ,
		{"word":"écharperaient","word_nosc":"echarperaient","lemma":"écharper","pos":"VER"} ,
		{"word":"écharpes","word_nosc":"echarpes","lemma":"écharper","pos":"VER"} ,
		{"word":"écharpé","word_nosc":"echarpe","lemma":"écharper","pos":"VER"} ,
		{"word":"écharpée","word_nosc":"echarpee","lemma":"écharper","pos":"VER"} ,
		{"word":"échauder","word_nosc":"echauder","lemma":"échauder","pos":"VER"} ,
		{"word":"échauderont","word_nosc":"echauderont","lemma":"échauder","pos":"VER"} ,
		{"word":"échaudé","word_nosc":"echaude","lemma":"échauder","pos":"VER"} ,
		{"word":"échaudés","word_nosc":"echaudes","lemma":"échauder","pos":"VER"} ,
		{"word":"échauffa","word_nosc":"echauffa","lemma":"échauffer","pos":"VER"} ,
		{"word":"échauffaient","word_nosc":"echauffaient","lemma":"échauffer","pos":"VER"} ,
		{"word":"échauffais","word_nosc":"echauffais","lemma":"échauffer","pos":"VER"} ,
		{"word":"échauffait","word_nosc":"echauffait","lemma":"échauffer","pos":"VER"} ,
		{"word":"échauffant","word_nosc":"echauffant","lemma":"échauffer","pos":"VER"} ,
		{"word":"échauffe","word_nosc":"echauffe","lemma":"échauffer","pos":"VER"} ,
		{"word":"échauffent","word_nosc":"echauffent","lemma":"échauffer","pos":"VER"} ,
		{"word":"échauffer","word_nosc":"echauffer","lemma":"échauffer","pos":"VER"} ,
		{"word":"échauffez","word_nosc":"echauffez","lemma":"échauffer","pos":"VER"} ,
		{"word":"échauffons","word_nosc":"echauffons","lemma":"échauffer","pos":"VER"} ,
		{"word":"échauffé","word_nosc":"echauffe","lemma":"échauffer","pos":"VER"} ,
		{"word":"échauffée","word_nosc":"echauffee","lemma":"échauffer","pos":"VER"} ,
		{"word":"échauffées","word_nosc":"echauffees","lemma":"échauffer","pos":"VER"} ,
		{"word":"échauffés","word_nosc":"echauffes","lemma":"échauffer","pos":"VER"} ,
		{"word":"échelonnaient","word_nosc":"echelonnaient","lemma":"échelonner","pos":"VER"} ,
		{"word":"échelonnait","word_nosc":"echelonnait","lemma":"échelonner","pos":"VER"} ,
		{"word":"échelonnant","word_nosc":"echelonnant","lemma":"échelonner","pos":"VER"} ,
		{"word":"échelonne","word_nosc":"echelonne","lemma":"échelonner","pos":"VER"} ,
		{"word":"échelonnent","word_nosc":"echelonnent","lemma":"échelonner","pos":"VER"} ,
		{"word":"échelonner","word_nosc":"echelonner","lemma":"échelonner","pos":"VER"} ,
		{"word":"échelonnerons","word_nosc":"echelonnerons","lemma":"échelonner","pos":"VER"} ,
		{"word":"échelonné","word_nosc":"echelonne","lemma":"échelonner","pos":"VER"} ,
		{"word":"échelonnée","word_nosc":"echelonnee","lemma":"échelonner","pos":"VER"} ,
		{"word":"échelonnées","word_nosc":"echelonnees","lemma":"échelonner","pos":"VER"} ,
		{"word":"échelonnés","word_nosc":"echelonnes","lemma":"échelonner","pos":"VER"} ,
		{"word":"écher","word_nosc":"echer","lemma":"écher","pos":"VER"} ,
		{"word":"échevelait","word_nosc":"echevelait","lemma":"écheveler","pos":"VER"} ,
		{"word":"échevelant","word_nosc":"echevelant","lemma":"écheveler","pos":"VER"} ,
		{"word":"écheveler","word_nosc":"echeveler","lemma":"écheveler","pos":"VER"} ,
		{"word":"échevelé","word_nosc":"echevele","lemma":"écheveler","pos":"VER"} ,
		{"word":"échevelée","word_nosc":"echevelee","lemma":"écheveler","pos":"VER"} ,
		{"word":"échevelées","word_nosc":"echevelees","lemma":"écheveler","pos":"VER"} ,
		{"word":"échevelés","word_nosc":"echeveles","lemma":"écheveler","pos":"VER"} ,
		{"word":"échina","word_nosc":"echina","lemma":"échiner","pos":"VER"} ,
		{"word":"échinaient","word_nosc":"echinaient","lemma":"échiner","pos":"VER"} ,
		{"word":"échinais","word_nosc":"echinais","lemma":"échiner","pos":"VER"} ,
		{"word":"échinait","word_nosc":"echinait","lemma":"échiner","pos":"VER"} ,
		{"word":"échinant","word_nosc":"echinant","lemma":"échiner","pos":"VER"} ,
		{"word":"échine","word_nosc":"echine","lemma":"échiner","pos":"VER"} ,
		{"word":"échinent","word_nosc":"echinent","lemma":"échiner","pos":"VER"} ,
		{"word":"échiner","word_nosc":"echiner","lemma":"échiner","pos":"VER"} ,
		{"word":"échines","word_nosc":"echines","lemma":"échiner","pos":"VER"} ,
		{"word":"échiné","word_nosc":"echine","lemma":"échiner","pos":"VER"} ,
		{"word":"échinée","word_nosc":"echinee","lemma":"échiner","pos":"VER"} ,
		{"word":"échographier","word_nosc":"echographier","lemma":"échographier","pos":"VER"} ,
		{"word":"échoir","word_nosc":"echoir","lemma":"échoir","pos":"VER"} ,
		{"word":"échoirait","word_nosc":"echoirait","lemma":"échoir","pos":"VER"} ,
		{"word":"échoit","word_nosc":"echoit","lemma":"échoir","pos":"VER"} ,
		{"word":"échoua","word_nosc":"echoua","lemma":"échouer","pos":"VER"} ,
		{"word":"échouai","word_nosc":"echouai","lemma":"échouer","pos":"VER"} ,
		{"word":"échouaient","word_nosc":"echouaient","lemma":"échouer","pos":"VER"} ,
		{"word":"échouais","word_nosc":"echouais","lemma":"échouer","pos":"VER"} ,
		{"word":"échouait","word_nosc":"echouait","lemma":"échouer","pos":"VER"} ,
		{"word":"échouant","word_nosc":"echouant","lemma":"échouer","pos":"VER"} ,
		{"word":"échoue","word_nosc":"echoue","lemma":"échouer","pos":"VER"} ,
		{"word":"échouent","word_nosc":"echouent","lemma":"échouer","pos":"VER"} ,
		{"word":"échouer","word_nosc":"echouer","lemma":"échouer","pos":"VER"} ,
		{"word":"échouera","word_nosc":"echouera","lemma":"échouer","pos":"VER"} ,
		{"word":"échouerai","word_nosc":"echouerai","lemma":"échouer","pos":"VER"} ,
		{"word":"échoueraient","word_nosc":"echoueraient","lemma":"échouer","pos":"VER"} ,
		{"word":"échouerais","word_nosc":"echouerais","lemma":"échouer","pos":"VER"} ,
		{"word":"échouerait","word_nosc":"echouerait","lemma":"échouer","pos":"VER"} ,
		{"word":"échoueras","word_nosc":"echoueras","lemma":"échouer","pos":"VER"} ,
		{"word":"échouerez","word_nosc":"echouerez","lemma":"échouer","pos":"VER"} ,
		{"word":"échouerons","word_nosc":"echouerons","lemma":"échouer","pos":"VER"} ,
		{"word":"échoueront","word_nosc":"echoueront","lemma":"échouer","pos":"VER"} ,
		{"word":"échouez","word_nosc":"echouez","lemma":"échouer","pos":"VER"} ,
		{"word":"échouiez","word_nosc":"echouiez","lemma":"échouer","pos":"VER"} ,
		{"word":"échouions","word_nosc":"echouions","lemma":"échouer","pos":"VER"} ,
		{"word":"échouons","word_nosc":"echouons","lemma":"échouer","pos":"VER"} ,
		{"word":"échouâmes","word_nosc":"echouames","lemma":"échouer","pos":"VER"} ,
		{"word":"échouât","word_nosc":"echouat","lemma":"échouer","pos":"VER"} ,
		{"word":"échouèrent","word_nosc":"echouerent","lemma":"échouer","pos":"VER"} ,
		{"word":"échoué","word_nosc":"echoue","lemma":"échouer","pos":"VER"} ,
		{"word":"échouée","word_nosc":"echouee","lemma":"échouer","pos":"VER"} ,
		{"word":"échouées","word_nosc":"echouees","lemma":"échouer","pos":"VER"} ,
		{"word":"échoués","word_nosc":"echoues","lemma":"échouer","pos":"VER"} ,
		{"word":"échu","word_nosc":"echu","lemma":"échoir","pos":"VER"} ,
		{"word":"échue","word_nosc":"echue","lemma":"échoir","pos":"VER"} ,
		{"word":"échues","word_nosc":"echues","lemma":"échoir","pos":"VER"} ,
		{"word":"échut","word_nosc":"echut","lemma":"échoir","pos":"VER"} ,
		{"word":"échût","word_nosc":"echut","lemma":"échoir","pos":"VER"} ,
		{"word":"éclaboussa","word_nosc":"eclaboussa","lemma":"éclabousser","pos":"VER"} ,
		{"word":"éclaboussaient","word_nosc":"eclaboussaient","lemma":"éclabousser","pos":"VER"} ,
		{"word":"éclaboussait","word_nosc":"eclaboussait","lemma":"éclabousser","pos":"VER"} ,
		{"word":"éclaboussant","word_nosc":"eclaboussant","lemma":"éclabousser","pos":"VER"} ,
		{"word":"éclabousse","word_nosc":"eclabousse","lemma":"éclabousser","pos":"VER"} ,
		{"word":"éclaboussent","word_nosc":"eclaboussent","lemma":"éclabousser","pos":"VER"} ,
		{"word":"éclabousser","word_nosc":"eclabousser","lemma":"éclabousser","pos":"VER"} ,
		{"word":"éclaboussera","word_nosc":"eclaboussera","lemma":"éclabousser","pos":"VER"} ,
		{"word":"éclabousses","word_nosc":"eclabousses","lemma":"éclabousser","pos":"VER"} ,
		{"word":"éclaboussez","word_nosc":"eclaboussez","lemma":"éclabousser","pos":"VER"} ,
		{"word":"éclaboussèrent","word_nosc":"eclabousserent","lemma":"éclabousser","pos":"VER"} ,
		{"word":"éclaboussé","word_nosc":"eclabousse","lemma":"éclabousser","pos":"VER"} ,
		{"word":"éclaboussée","word_nosc":"eclaboussee","lemma":"éclabousser","pos":"VER"} ,
		{"word":"éclaboussées","word_nosc":"eclaboussees","lemma":"éclabousser","pos":"VER"} ,
		{"word":"éclaboussés","word_nosc":"eclabousses","lemma":"éclabousser","pos":"VER"} ,
		{"word":"éclaira","word_nosc":"eclaira","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairai","word_nosc":"eclairai","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairaient","word_nosc":"eclairaient","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairais","word_nosc":"eclairais","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairait","word_nosc":"eclairait","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairant","word_nosc":"eclairant","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairci","word_nosc":"eclairci","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircie","word_nosc":"eclaircie","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircies","word_nosc":"eclaircies","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircir","word_nosc":"eclaircir","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircira","word_nosc":"eclaircira","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircirai","word_nosc":"eclaircirai","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircirait","word_nosc":"eclaircirait","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclairciras","word_nosc":"eclairciras","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircirez","word_nosc":"eclaircirez","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircirons","word_nosc":"eclaircirons","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclairciront","word_nosc":"eclairciront","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircis","word_nosc":"eclaircis","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircissaient","word_nosc":"eclaircissaient","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircissait","word_nosc":"eclaircissait","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircissant","word_nosc":"eclaircissant","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircisse","word_nosc":"eclaircisse","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircissent","word_nosc":"eclaircissent","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircissez","word_nosc":"eclaircissez","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircissons","word_nosc":"eclaircissons","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaircit","word_nosc":"eclaircit","lemma":"éclaircir","pos":"VER"} ,
		{"word":"éclaire","word_nosc":"eclaire","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairent","word_nosc":"eclairent","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairer","word_nosc":"eclairer","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairera","word_nosc":"eclairera","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclaireraient","word_nosc":"eclaireraient","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairerait","word_nosc":"eclairerait","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclaireront","word_nosc":"eclaireront","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclaires","word_nosc":"eclaires","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairez","word_nosc":"eclairez","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairiez","word_nosc":"eclairiez","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairons","word_nosc":"eclairons","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairât","word_nosc":"eclairat","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairèrent","word_nosc":"eclairerent","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairé","word_nosc":"eclaire","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairée","word_nosc":"eclairee","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairées","word_nosc":"eclairees","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclairés","word_nosc":"eclaires","lemma":"éclairer","pos":"VER"} ,
		{"word":"éclata","word_nosc":"eclata","lemma":"éclater","pos":"VER"} ,
		{"word":"éclatai","word_nosc":"eclatai","lemma":"éclater","pos":"VER"} ,
		{"word":"éclataient","word_nosc":"eclataient","lemma":"éclater","pos":"VER"} ,
		{"word":"éclatais","word_nosc":"eclatais","lemma":"éclater","pos":"VER"} ,
		{"word":"éclatait","word_nosc":"eclatait","lemma":"éclater","pos":"VER"} ,
		{"word":"éclatant","word_nosc":"eclatant","lemma":"éclater","pos":"VER"} ,
		{"word":"éclate","word_nosc":"eclate","lemma":"éclater","pos":"VER"} ,
		{"word":"éclatent","word_nosc":"eclatent","lemma":"éclater","pos":"VER"} ,
		{"word":"éclater","word_nosc":"eclater","lemma":"éclater","pos":"VER"} ,
		{"word":"éclatera","word_nosc":"eclatera","lemma":"éclater","pos":"VER"} ,
		{"word":"éclaterai","word_nosc":"eclaterai","lemma":"éclater","pos":"VER"} ,
		{"word":"éclateraient","word_nosc":"eclateraient","lemma":"éclater","pos":"VER"} ,
		{"word":"éclaterais","word_nosc":"eclaterais","lemma":"éclater","pos":"VER"} ,
		{"word":"éclaterait","word_nosc":"eclaterait","lemma":"éclater","pos":"VER"} ,
		{"word":"éclateras","word_nosc":"eclateras","lemma":"éclater","pos":"VER"} ,
		{"word":"éclateriez","word_nosc":"eclateriez","lemma":"éclater","pos":"VER"} ,
		{"word":"éclateront","word_nosc":"eclateront","lemma":"éclater","pos":"VER"} ,
		{"word":"éclates","word_nosc":"eclates","lemma":"éclater","pos":"VER"} ,
		{"word":"éclatez","word_nosc":"eclatez","lemma":"éclater","pos":"VER"} ,
		{"word":"éclatiez","word_nosc":"eclatiez","lemma":"éclater","pos":"VER"} ,
		{"word":"éclatons","word_nosc":"eclatons","lemma":"éclater","pos":"VER"} ,
		{"word":"éclatât","word_nosc":"eclatat","lemma":"éclater","pos":"VER"} ,
		{"word":"éclatèrent","word_nosc":"eclaterent","lemma":"éclater","pos":"VER"} ,
		{"word":"éclaté","word_nosc":"eclate","lemma":"éclater","pos":"VER"} ,
		{"word":"éclatée","word_nosc":"eclatee","lemma":"éclater","pos":"VER"} ,
		{"word":"éclatées","word_nosc":"eclatees","lemma":"éclater","pos":"VER"} ,
		{"word":"éclatés","word_nosc":"eclates","lemma":"éclater","pos":"VER"} ,
		{"word":"éclipsa","word_nosc":"eclipsa","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipsai","word_nosc":"eclipsai","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipsaient","word_nosc":"eclipsaient","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipsait","word_nosc":"eclipsait","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipsant","word_nosc":"eclipsant","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipse","word_nosc":"eclipse","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipsent","word_nosc":"eclipsent","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipser","word_nosc":"eclipser","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipsera","word_nosc":"eclipsera","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipserais","word_nosc":"eclipserais","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipserait","word_nosc":"eclipserait","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipses","word_nosc":"eclipses","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipsez","word_nosc":"eclipsez","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipsions","word_nosc":"eclipsions","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipsèrent","word_nosc":"eclipserent","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipsé","word_nosc":"eclipse","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipsée","word_nosc":"eclipsee","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipsées","word_nosc":"eclipsees","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclipsés","word_nosc":"eclipses","lemma":"éclipser","pos":"VER"} ,
		{"word":"éclopait","word_nosc":"eclopait","lemma":"écloper","pos":"VER"} ,
		{"word":"écloper","word_nosc":"ecloper","lemma":"écloper","pos":"VER"} ,
		{"word":"éclopé","word_nosc":"eclope","lemma":"écloper","pos":"VER"} ,
		{"word":"éclopés","word_nosc":"eclopes","lemma":"écloper","pos":"VER"} ,
		{"word":"éclore","word_nosc":"eclore","lemma":"éclore","pos":"VER"} ,
		{"word":"éclose","word_nosc":"eclose","lemma":"éclore","pos":"VER"} ,
		{"word":"éclusa","word_nosc":"eclusa","lemma":"écluser","pos":"VER"} ,
		{"word":"éclusaient","word_nosc":"eclusaient","lemma":"écluser","pos":"VER"} ,
		{"word":"éclusais","word_nosc":"eclusais","lemma":"écluser","pos":"VER"} ,
		{"word":"éclusait","word_nosc":"eclusait","lemma":"écluser","pos":"VER"} ,
		{"word":"éclusant","word_nosc":"eclusant","lemma":"écluser","pos":"VER"} ,
		{"word":"écluse","word_nosc":"ecluse","lemma":"écluser","pos":"VER"} ,
		{"word":"éclusent","word_nosc":"eclusent","lemma":"écluser","pos":"VER"} ,
		{"word":"écluser","word_nosc":"ecluser","lemma":"écluser","pos":"VER"} ,
		{"word":"éclusé","word_nosc":"ecluse","lemma":"écluser","pos":"VER"} ,
		{"word":"éclusée","word_nosc":"eclusee","lemma":"écluser","pos":"VER"} ,
		{"word":"éclusées","word_nosc":"eclusees","lemma":"écluser","pos":"VER"} ,
		{"word":"écoeura","word_nosc":"ecoeura","lemma":"écoeurer","pos":"VER"} ,
		{"word":"écoeuraient","word_nosc":"ecoeuraient","lemma":"écoeurer","pos":"VER"} ,
		{"word":"écoeurais","word_nosc":"ecoeurais","lemma":"écoeurer","pos":"VER"} ,
		{"word":"écoeurait","word_nosc":"ecoeurait","lemma":"écoeurer","pos":"VER"} ,
		{"word":"écoeurant","word_nosc":"ecoeurant","lemma":"écoeurer","pos":"VER"} ,
		{"word":"écoeure","word_nosc":"ecoeure","lemma":"écoeurer","pos":"VER"} ,
		{"word":"écoeurent","word_nosc":"ecoeurent","lemma":"écoeurer","pos":"VER"} ,
		{"word":"écoeurer","word_nosc":"ecoeurer","lemma":"écoeurer","pos":"VER"} ,
		{"word":"écoeurerais","word_nosc":"ecoeurerais","lemma":"écoeurer","pos":"VER"} ,
		{"word":"écoeurez","word_nosc":"ecoeurez","lemma":"écoeurer","pos":"VER"} ,
		{"word":"écoeurèrent","word_nosc":"ecoeurerent","lemma":"écoeurer","pos":"VER"} ,
		{"word":"écoeuré","word_nosc":"ecoeure","lemma":"écoeurer","pos":"VER"} ,
		{"word":"écoeurée","word_nosc":"ecoeuree","lemma":"écoeurer","pos":"VER"} ,
		{"word":"écoeurées","word_nosc":"ecoeurees","lemma":"écoeurer","pos":"VER"} ,
		{"word":"écoeurés","word_nosc":"ecoeures","lemma":"écoeurer","pos":"VER"} ,
		{"word":"éconduira","word_nosc":"econduira","lemma":"éconduire","pos":"VER"} ,
		{"word":"éconduire","word_nosc":"econduire","lemma":"éconduire","pos":"VER"} ,
		{"word":"éconduis","word_nosc":"econduis","lemma":"éconduire","pos":"VER"} ,
		{"word":"éconduisirent","word_nosc":"econduisirent","lemma":"éconduire","pos":"VER"} ,
		{"word":"éconduisit","word_nosc":"econduisit","lemma":"éconduire","pos":"VER"} ,
		{"word":"éconduit","word_nosc":"econduit","lemma":"éconduire","pos":"VER"} ,
		{"word":"éconduite","word_nosc":"econduite","lemma":"éconduire","pos":"VER"} ,
		{"word":"éconduits","word_nosc":"econduits","lemma":"éconduire","pos":"VER"} ,
		{"word":"économisaient","word_nosc":"economisaient","lemma":"économiser","pos":"VER"} ,
		{"word":"économisais","word_nosc":"economisais","lemma":"économiser","pos":"VER"} ,
		{"word":"économisait","word_nosc":"economisait","lemma":"économiser","pos":"VER"} ,
		{"word":"économisant","word_nosc":"economisant","lemma":"économiser","pos":"VER"} ,
		{"word":"économise","word_nosc":"economise","lemma":"économiser","pos":"VER"} ,
		{"word":"économisent","word_nosc":"economisent","lemma":"économiser","pos":"VER"} ,
		{"word":"économiser","word_nosc":"economiser","lemma":"économiser","pos":"VER"} ,
		{"word":"économisera","word_nosc":"economisera","lemma":"économiser","pos":"VER"} ,
		{"word":"économiserai","word_nosc":"economiserai","lemma":"économiser","pos":"VER"} ,
		{"word":"économiserais","word_nosc":"economiserais","lemma":"économiser","pos":"VER"} ,
		{"word":"économiserait","word_nosc":"economiserait","lemma":"économiser","pos":"VER"} ,
		{"word":"économiserez","word_nosc":"economiserez","lemma":"économiser","pos":"VER"} ,
		{"word":"économiserons","word_nosc":"economiserons","lemma":"économiser","pos":"VER"} ,
		{"word":"économiseront","word_nosc":"economiseront","lemma":"économiser","pos":"VER"} ,
		{"word":"économisez","word_nosc":"economisez","lemma":"économiser","pos":"VER"} ,
		{"word":"économisons","word_nosc":"economisons","lemma":"économiser","pos":"VER"} ,
		{"word":"économisé","word_nosc":"economise","lemma":"économiser","pos":"VER"} ,
		{"word":"économisée","word_nosc":"economisee","lemma":"économiser","pos":"VER"} ,
		{"word":"économisées","word_nosc":"economisees","lemma":"économiser","pos":"VER"} ,
		{"word":"économisés","word_nosc":"economises","lemma":"économiser","pos":"VER"} ,
		{"word":"écopa","word_nosc":"ecopa","lemma":"écoper","pos":"VER"} ,
		{"word":"écopai","word_nosc":"ecopai","lemma":"écoper","pos":"VER"} ,
		{"word":"écopais","word_nosc":"ecopais","lemma":"écoper","pos":"VER"} ,
		{"word":"écopait","word_nosc":"ecopait","lemma":"écoper","pos":"VER"} ,
		{"word":"écope","word_nosc":"ecope","lemma":"écoper","pos":"VER"} ,
		{"word":"écopent","word_nosc":"ecopent","lemma":"écoper","pos":"VER"} ,
		{"word":"écoper","word_nosc":"ecoper","lemma":"écoper","pos":"VER"} ,
		{"word":"écopera","word_nosc":"ecopera","lemma":"écoper","pos":"VER"} ,
		{"word":"écoperai","word_nosc":"ecoperai","lemma":"écoper","pos":"VER"} ,
		{"word":"écoperait","word_nosc":"ecoperait","lemma":"écoper","pos":"VER"} ,
		{"word":"écoperas","word_nosc":"ecoperas","lemma":"écoper","pos":"VER"} ,
		{"word":"écoperez","word_nosc":"ecoperez","lemma":"écoper","pos":"VER"} ,
		{"word":"écoperont","word_nosc":"ecoperont","lemma":"écoper","pos":"VER"} ,
		{"word":"écopes","word_nosc":"ecopes","lemma":"écoper","pos":"VER"} ,
		{"word":"écopez","word_nosc":"ecopez","lemma":"écoper","pos":"VER"} ,
		{"word":"écopiez","word_nosc":"ecopiez","lemma":"écoper","pos":"VER"} ,
		{"word":"écopons","word_nosc":"ecopons","lemma":"écoper","pos":"VER"} ,
		{"word":"écopé","word_nosc":"ecope","lemma":"écoper","pos":"VER"} ,
		{"word":"écopée","word_nosc":"ecopee","lemma":"écoper","pos":"VER"} ,
		{"word":"écorce","word_nosc":"ecorce","lemma":"écorcer","pos":"VER"} ,
		{"word":"écorcer","word_nosc":"ecorcer","lemma":"écorcer","pos":"VER"} ,
		{"word":"écorcha","word_nosc":"ecorcha","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorchaient","word_nosc":"ecorchaient","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorchais","word_nosc":"ecorchais","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorchait","word_nosc":"ecorchait","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorchant","word_nosc":"ecorchant","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorche","word_nosc":"ecorche","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorchent","word_nosc":"ecorchent","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorcher","word_nosc":"ecorcher","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorchera","word_nosc":"ecorchera","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorcherai","word_nosc":"ecorcherai","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorcherais","word_nosc":"ecorcherais","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorcherait","word_nosc":"ecorcherait","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorcheront","word_nosc":"ecorcheront","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorchez","word_nosc":"ecorchez","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorchiez","word_nosc":"ecorchiez","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorchions","word_nosc":"ecorchions","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorché","word_nosc":"ecorche","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorchée","word_nosc":"ecorchee","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorchées","word_nosc":"ecorchees","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorchés","word_nosc":"ecorches","lemma":"écorcher","pos":"VER"} ,
		{"word":"écorcé","word_nosc":"ecorce","lemma":"écorcer","pos":"VER"} ,
		{"word":"écornait","word_nosc":"ecornait","lemma":"écorner","pos":"VER"} ,
		{"word":"écornent","word_nosc":"ecornent","lemma":"écorner","pos":"VER"} ,
		{"word":"écorner","word_nosc":"ecorner","lemma":"écorner","pos":"VER"} ,
		{"word":"écornifler","word_nosc":"ecornifler","lemma":"écornifler","pos":"VER"} ,
		{"word":"écorné","word_nosc":"ecorne","lemma":"écorner","pos":"VER"} ,
		{"word":"écornée","word_nosc":"ecornee","lemma":"écorner","pos":"VER"} ,
		{"word":"écornées","word_nosc":"ecornees","lemma":"écorner","pos":"VER"} ,
		{"word":"écornés","word_nosc":"ecornes","lemma":"écorner","pos":"VER"} ,
		{"word":"écossa","word_nosc":"ecossa","lemma":"écosser","pos":"VER"} ,
		{"word":"écossaient","word_nosc":"ecossaient","lemma":"écosser","pos":"VER"} ,
		{"word":"écossais","word_nosc":"ecossais","lemma":"écosser","pos":"VER"} ,
		{"word":"écossait","word_nosc":"ecossait","lemma":"écosser","pos":"VER"} ,
		{"word":"écossant","word_nosc":"ecossant","lemma":"écosser","pos":"VER"} ,
		{"word":"écosse","word_nosc":"ecosse","lemma":"écosser","pos":"VER"} ,
		{"word":"écossent","word_nosc":"ecossent","lemma":"écosser","pos":"VER"} ,
		{"word":"écosser","word_nosc":"ecosser","lemma":"écosser","pos":"VER"} ,
		{"word":"écossé","word_nosc":"ecosse","lemma":"écosser","pos":"VER"} ,
		{"word":"écossés","word_nosc":"ecosses","lemma":"écosser","pos":"VER"} ,
		{"word":"écoula","word_nosc":"ecoula","lemma":"écouler","pos":"VER"} ,
		{"word":"écoulaient","word_nosc":"ecoulaient","lemma":"écouler","pos":"VER"} ,
		{"word":"écoulait","word_nosc":"ecoulait","lemma":"écouler","pos":"VER"} ,
		{"word":"écoulant","word_nosc":"ecoulant","lemma":"écouler","pos":"VER"} ,
		{"word":"écoule","word_nosc":"ecoule","lemma":"écouler","pos":"VER"} ,
		{"word":"écoulent","word_nosc":"ecoulent","lemma":"écouler","pos":"VER"} ,
		{"word":"écouler","word_nosc":"ecouler","lemma":"écouler","pos":"VER"} ,
		{"word":"écoulera","word_nosc":"ecoulera","lemma":"écouler","pos":"VER"} ,
		{"word":"écouleraient","word_nosc":"ecouleraient","lemma":"écouler","pos":"VER"} ,
		{"word":"écoulerait","word_nosc":"ecoulerait","lemma":"écouler","pos":"VER"} ,
		{"word":"écouleront","word_nosc":"ecouleront","lemma":"écouler","pos":"VER"} ,
		{"word":"écoulât","word_nosc":"ecoulat","lemma":"écouler","pos":"VER"} ,
		{"word":"écoulèrent","word_nosc":"ecoulerent","lemma":"écouler","pos":"VER"} ,
		{"word":"écoulé","word_nosc":"ecoule","lemma":"écouler","pos":"VER"} ,
		{"word":"écoulée","word_nosc":"ecoulee","lemma":"écouler","pos":"VER"} ,
		{"word":"écoulées","word_nosc":"ecoulees","lemma":"écouler","pos":"VER"} ,
		{"word":"écoulés","word_nosc":"ecoules","lemma":"écouler","pos":"VER"} ,
		{"word":"écourta","word_nosc":"ecourta","lemma":"écourter","pos":"VER"} ,
		{"word":"écourtaient","word_nosc":"ecourtaient","lemma":"écourter","pos":"VER"} ,
		{"word":"écourtais","word_nosc":"ecourtais","lemma":"écourter","pos":"VER"} ,
		{"word":"écourtant","word_nosc":"ecourtant","lemma":"écourter","pos":"VER"} ,
		{"word":"écourte","word_nosc":"ecourte","lemma":"écourter","pos":"VER"} ,
		{"word":"écourter","word_nosc":"ecourter","lemma":"écourter","pos":"VER"} ,
		{"word":"écourtera","word_nosc":"ecourtera","lemma":"écourter","pos":"VER"} ,
		{"word":"écourteraient","word_nosc":"ecourteraient","lemma":"écourter","pos":"VER"} ,
		{"word":"écourté","word_nosc":"ecourte","lemma":"écourter","pos":"VER"} ,
		{"word":"écourtée","word_nosc":"ecourtee","lemma":"écourter","pos":"VER"} ,
		{"word":"écourtées","word_nosc":"ecourtees","lemma":"écourter","pos":"VER"} ,
		{"word":"écourtés","word_nosc":"ecourtes","lemma":"écourter","pos":"VER"} ,
		{"word":"écouta","word_nosc":"ecouta","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutai","word_nosc":"ecoutai","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutaient","word_nosc":"ecoutaient","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutais","word_nosc":"ecoutais","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutait","word_nosc":"ecoutait","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutant","word_nosc":"ecoutant","lemma":"écouter","pos":"VER"} ,
		{"word":"écoute","word_nosc":"ecoute","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutent","word_nosc":"ecoutent","lemma":"écouter","pos":"VER"} ,
		{"word":"écouter","word_nosc":"ecouter","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutera","word_nosc":"ecoutera","lemma":"écouter","pos":"VER"} ,
		{"word":"écouterai","word_nosc":"ecouterai","lemma":"écouter","pos":"VER"} ,
		{"word":"écouteraient","word_nosc":"ecouteraient","lemma":"écouter","pos":"VER"} ,
		{"word":"écouterais","word_nosc":"ecouterais","lemma":"écouter","pos":"VER"} ,
		{"word":"écouterait","word_nosc":"ecouterait","lemma":"écouter","pos":"VER"} ,
		{"word":"écouteras","word_nosc":"ecouteras","lemma":"écouter","pos":"VER"} ,
		{"word":"écouterez","word_nosc":"ecouterez","lemma":"écouter","pos":"VER"} ,
		{"word":"écouteriez","word_nosc":"ecouteriez","lemma":"écouter","pos":"VER"} ,
		{"word":"écouterons","word_nosc":"ecouterons","lemma":"écouter","pos":"VER"} ,
		{"word":"écouteront","word_nosc":"ecouteront","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutes","word_nosc":"ecoutes","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutez","word_nosc":"ecoutez","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutiez","word_nosc":"ecoutiez","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutions","word_nosc":"ecoutions","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutons","word_nosc":"ecoutons","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutâmes","word_nosc":"ecoutames","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutât","word_nosc":"ecoutat","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutèrent","word_nosc":"ecouterent","lemma":"écouter","pos":"VER"} ,
		{"word":"écouté","word_nosc":"ecoute","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutée","word_nosc":"ecoutee","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutées","word_nosc":"ecoutees","lemma":"écouter","pos":"VER"} ,
		{"word":"écoutés","word_nosc":"ecoutes","lemma":"écouter","pos":"VER"} ,
		{"word":"écouvillonne","word_nosc":"ecouvillonne","lemma":"écouvillonner","pos":"VER"} ,
		{"word":"écrabouilla","word_nosc":"ecrabouilla","lemma":"écrabouiller","pos":"VER"} ,
		{"word":"écrabouillait","word_nosc":"ecrabouillait","lemma":"écrabouiller","pos":"VER"} ,
		{"word":"écrabouillant","word_nosc":"ecrabouillant","lemma":"écrabouiller","pos":"VER"} ,
		{"word":"écrabouille","word_nosc":"ecrabouille","lemma":"écrabouiller","pos":"VER"} ,
		{"word":"écrabouiller","word_nosc":"ecrabouiller","lemma":"écrabouiller","pos":"VER"} ,
		{"word":"écrabouillera","word_nosc":"ecrabouillera","lemma":"écrabouiller","pos":"VER"} ,
		{"word":"écrabouillons","word_nosc":"ecrabouillons","lemma":"écrabouiller","pos":"VER"} ,
		{"word":"écrabouillèrent","word_nosc":"ecrabouillerent","lemma":"écrabouiller","pos":"VER"} ,
		{"word":"écrabouillé","word_nosc":"ecrabouille","lemma":"écrabouiller","pos":"VER"} ,
		{"word":"écrabouillée","word_nosc":"ecrabouillee","lemma":"écrabouiller","pos":"VER"} ,
		{"word":"écrabouillées","word_nosc":"ecrabouillees","lemma":"écrabouiller","pos":"VER"} ,
		{"word":"écrabouillés","word_nosc":"ecrabouilles","lemma":"écrabouiller","pos":"VER"} ,
		{"word":"écrasa","word_nosc":"ecrasa","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasai","word_nosc":"ecrasai","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasaient","word_nosc":"ecrasaient","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasais","word_nosc":"ecrasais","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasait","word_nosc":"ecrasait","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasant","word_nosc":"ecrasant","lemma":"écraser","pos":"VER"} ,
		{"word":"écrase","word_nosc":"ecrase","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasent","word_nosc":"ecrasent","lemma":"écraser","pos":"VER"} ,
		{"word":"écraser","word_nosc":"ecraser","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasera","word_nosc":"ecrasera","lemma":"écraser","pos":"VER"} ,
		{"word":"écraserai","word_nosc":"ecraserai","lemma":"écraser","pos":"VER"} ,
		{"word":"écraseraient","word_nosc":"ecraseraient","lemma":"écraser","pos":"VER"} ,
		{"word":"écraserais","word_nosc":"ecraserais","lemma":"écraser","pos":"VER"} ,
		{"word":"écraserait","word_nosc":"ecraserait","lemma":"écraser","pos":"VER"} ,
		{"word":"écraseras","word_nosc":"ecraseras","lemma":"écraser","pos":"VER"} ,
		{"word":"écraserez","word_nosc":"ecraserez","lemma":"écraser","pos":"VER"} ,
		{"word":"écraserons","word_nosc":"ecraserons","lemma":"écraser","pos":"VER"} ,
		{"word":"écraseront","word_nosc":"ecraseront","lemma":"écraser","pos":"VER"} ,
		{"word":"écrases","word_nosc":"ecrases","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasez","word_nosc":"ecrasez","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasiez","word_nosc":"ecrasiez","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasions","word_nosc":"ecrasions","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasons","word_nosc":"ecrasons","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasât","word_nosc":"ecrasat","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasèrent","word_nosc":"ecraserent","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasé","word_nosc":"ecrase","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasée","word_nosc":"ecrasee","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasées","word_nosc":"ecrasees","lemma":"écraser","pos":"VER"} ,
		{"word":"écrasés","word_nosc":"ecrases","lemma":"écraser","pos":"VER"} ,
		{"word":"écria","word_nosc":"ecria","lemma":"écrier","pos":"VER"} ,
		{"word":"écriai","word_nosc":"ecriai","lemma":"écrier","pos":"VER"} ,
		{"word":"écriaient","word_nosc":"ecriaient","lemma":"écrier","pos":"VER"} ,
		{"word":"écriais","word_nosc":"ecriais","lemma":"écrier","pos":"VER"} ,
		{"word":"écriait","word_nosc":"ecriait","lemma":"écrier","pos":"VER"} ,
		{"word":"écriant","word_nosc":"ecriant","lemma":"écrier","pos":"VER"} ,
		{"word":"écrie","word_nosc":"ecrie","lemma":"écrier","pos":"VER"} ,
		{"word":"écrient","word_nosc":"ecrient","lemma":"écrier","pos":"VER"} ,
		{"word":"écrier","word_nosc":"ecrier","lemma":"écrier","pos":"VER"} ,
		{"word":"écriera","word_nosc":"ecriera","lemma":"écrier","pos":"VER"} ,
		{"word":"écrierait","word_nosc":"ecrierait","lemma":"écrier","pos":"VER"} ,
		{"word":"écries","word_nosc":"ecries","lemma":"écrier","pos":"VER"} ,
		{"word":"écrions","word_nosc":"ecrions","lemma":"écrier","pos":"VER"} ,
		{"word":"écrira","word_nosc":"ecrira","lemma":"écrire","pos":"VER"} ,
		{"word":"écrirai","word_nosc":"ecrirai","lemma":"écrire","pos":"VER"} ,
		{"word":"écriraient","word_nosc":"ecriraient","lemma":"écrire","pos":"VER"} ,
		{"word":"écrirais","word_nosc":"ecrirais","lemma":"écrire","pos":"VER"} ,
		{"word":"écrirait","word_nosc":"ecrirait","lemma":"écrire","pos":"VER"} ,
		{"word":"écriras","word_nosc":"ecriras","lemma":"écrire","pos":"VER"} ,
		{"word":"écrire","word_nosc":"ecrire","lemma":"écrire","pos":"VER"} ,
		{"word":"écrirez","word_nosc":"ecrirez","lemma":"écrire","pos":"VER"} ,
		{"word":"écririez","word_nosc":"ecririez","lemma":"écrire","pos":"VER"} ,
		{"word":"écrirons","word_nosc":"ecrirons","lemma":"écrire","pos":"VER"} ,
		{"word":"écriront","word_nosc":"ecriront","lemma":"écrire","pos":"VER"} ,
		{"word":"écris","word_nosc":"ecris","lemma":"écrire","pos":"VER"} ,
		{"word":"écrit","word_nosc":"ecrit","lemma":"écrire","pos":"VER"} ,
		{"word":"écrite","word_nosc":"ecrite","lemma":"écrire","pos":"VER"} ,
		{"word":"écrites","word_nosc":"ecrites","lemma":"écrire","pos":"VER"} ,
		{"word":"écrits","word_nosc":"ecrits","lemma":"écrire","pos":"VER"} ,
		{"word":"écrivaient","word_nosc":"ecrivaient","lemma":"écrire","pos":"VER"} ,
		{"word":"écrivailler","word_nosc":"ecrivailler","lemma":"écrivailler","pos":"VER"} ,
		{"word":"écrivaillé","word_nosc":"ecrivaille","lemma":"écrivailler","pos":"VER"} ,
		{"word":"écrivais","word_nosc":"ecrivais","lemma":"écrire","pos":"VER"} ,
		{"word":"écrivait","word_nosc":"ecrivait","lemma":"écrire","pos":"VER"} ,
		{"word":"écrivant","word_nosc":"ecrivant","lemma":"écrire","pos":"VER"} ,
		{"word":"écrive","word_nosc":"ecrive","lemma":"écrire","pos":"VER"} ,
		{"word":"écrivent","word_nosc":"ecrivent","lemma":"écrire","pos":"VER"} ,
		{"word":"écrives","word_nosc":"ecrives","lemma":"écrire","pos":"VER"} ,
		{"word":"écrivez","word_nosc":"ecrivez","lemma":"écrire","pos":"VER"} ,
		{"word":"écriviez","word_nosc":"ecriviez","lemma":"écrire","pos":"VER"} ,
		{"word":"écrivions","word_nosc":"ecrivions","lemma":"écrire","pos":"VER"} ,
		{"word":"écrivirent","word_nosc":"ecrivirent","lemma":"écrire","pos":"VER"} ,
		{"word":"écrivis","word_nosc":"ecrivis","lemma":"écrire","pos":"VER"} ,
		{"word":"écrivisse","word_nosc":"ecrivisse","lemma":"écrire","pos":"VER"} ,
		{"word":"écrivit","word_nosc":"ecrivit","lemma":"écrire","pos":"VER"} ,
		{"word":"écrivons","word_nosc":"ecrivons","lemma":"écrire","pos":"VER"} ,
		{"word":"écrivîmes","word_nosc":"ecrivimes","lemma":"écrire","pos":"VER"} ,
		{"word":"écrivît","word_nosc":"ecrivit","lemma":"écrire","pos":"VER"} ,
		{"word":"écriât","word_nosc":"ecriat","lemma":"écrier","pos":"VER"} ,
		{"word":"écrièrent","word_nosc":"ecrierent","lemma":"écrier","pos":"VER"} ,
		{"word":"écrié","word_nosc":"ecrie","lemma":"écrier","pos":"VER"} ,
		{"word":"écriée","word_nosc":"ecriee","lemma":"écrier","pos":"VER"} ,
		{"word":"écriés","word_nosc":"ecries","lemma":"écrier","pos":"VER"} ,
		{"word":"écrouer","word_nosc":"ecrouer","lemma":"écrouer","pos":"VER"} ,
		{"word":"écroueront","word_nosc":"ecroueront","lemma":"écrouer","pos":"VER"} ,
		{"word":"écroula","word_nosc":"ecroula","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulai","word_nosc":"ecroulai","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulaient","word_nosc":"ecroulaient","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulais","word_nosc":"ecroulais","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulait","word_nosc":"ecroulait","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulant","word_nosc":"ecroulant","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroule","word_nosc":"ecroule","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulent","word_nosc":"ecroulent","lemma":"écrouler","pos":"VER"} ,
		{"word":"écrouler","word_nosc":"ecrouler","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulera","word_nosc":"ecroulera","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulerai","word_nosc":"ecroulerai","lemma":"écrouler","pos":"VER"} ,
		{"word":"écrouleraient","word_nosc":"ecrouleraient","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulerais","word_nosc":"ecroulerais","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulerait","word_nosc":"ecroulerait","lemma":"écrouler","pos":"VER"} ,
		{"word":"écrouleras","word_nosc":"ecrouleras","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulerions","word_nosc":"ecroulerions","lemma":"écrouler","pos":"VER"} ,
		{"word":"écrouleront","word_nosc":"ecrouleront","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulions","word_nosc":"ecroulions","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulons","word_nosc":"ecroulons","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulât","word_nosc":"ecroulat","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulèrent","word_nosc":"ecroulerent","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulé","word_nosc":"ecroule","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulée","word_nosc":"ecroulee","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulées","word_nosc":"ecroulees","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroulés","word_nosc":"ecroules","lemma":"écrouler","pos":"VER"} ,
		{"word":"écroué","word_nosc":"ecroue","lemma":"écrouer","pos":"VER"} ,
		{"word":"écrouée","word_nosc":"ecrouee","lemma":"écrouer","pos":"VER"} ,
		{"word":"écrème","word_nosc":"ecreme","lemma":"écrémer","pos":"VER"} ,
		{"word":"écrémer","word_nosc":"ecremer","lemma":"écrémer","pos":"VER"} ,
		{"word":"écrémé","word_nosc":"ecreme","lemma":"écrémer","pos":"VER"} ,
		{"word":"écrémées","word_nosc":"ecremees","lemma":"écrémer","pos":"VER"} ,
		{"word":"écrêtait","word_nosc":"ecretait","lemma":"écrêter","pos":"VER"} ,
		{"word":"écrêter","word_nosc":"ecreter","lemma":"écrêter","pos":"VER"} ,
		{"word":"écrêtée","word_nosc":"ecretee","lemma":"écrêter","pos":"VER"} ,
		{"word":"écuissé","word_nosc":"ecuisse","lemma":"écuisser","pos":"VER"} ,
		{"word":"écuissées","word_nosc":"ecuissees","lemma":"écuisser","pos":"VER"} ,
		{"word":"éculer","word_nosc":"eculer","lemma":"éculer","pos":"VER"} ,
		{"word":"éculé","word_nosc":"ecule","lemma":"éculer","pos":"VER"} ,
		{"word":"éculée","word_nosc":"eculee","lemma":"éculer","pos":"VER"} ,
		{"word":"éculées","word_nosc":"eculees","lemma":"éculer","pos":"VER"} ,
		{"word":"éculés","word_nosc":"ecules","lemma":"éculer","pos":"VER"} ,
		{"word":"écuma","word_nosc":"ecuma","lemma":"écumer","pos":"VER"} ,
		{"word":"écumai","word_nosc":"ecumai","lemma":"écumer","pos":"VER"} ,
		{"word":"écumaient","word_nosc":"ecumaient","lemma":"écumer","pos":"VER"} ,
		{"word":"écumais","word_nosc":"ecumais","lemma":"écumer","pos":"VER"} ,
		{"word":"écumait","word_nosc":"ecumait","lemma":"écumer","pos":"VER"} ,
		{"word":"écumant","word_nosc":"ecumant","lemma":"écumer","pos":"VER"} ,
		{"word":"écume","word_nosc":"ecume","lemma":"écumer","pos":"VER"} ,
		{"word":"écument","word_nosc":"ecument","lemma":"écumer","pos":"VER"} ,
		{"word":"écumer","word_nosc":"ecumer","lemma":"écumer","pos":"VER"} ,
		{"word":"écumera","word_nosc":"ecumera","lemma":"écumer","pos":"VER"} ,
		{"word":"écumeront","word_nosc":"ecumeront","lemma":"écumer","pos":"VER"} ,
		{"word":"écumez","word_nosc":"ecumez","lemma":"écumer","pos":"VER"} ,
		{"word":"écumiez","word_nosc":"ecumiez","lemma":"écumer","pos":"VER"} ,
		{"word":"écumâmes","word_nosc":"ecumames","lemma":"écumer","pos":"VER"} ,
		{"word":"écumèrent","word_nosc":"ecumerent","lemma":"écumer","pos":"VER"} ,
		{"word":"écumé","word_nosc":"ecume","lemma":"écumer","pos":"VER"} ,
		{"word":"écumée","word_nosc":"ecumee","lemma":"écumer","pos":"VER"} ,
		{"word":"écussonné","word_nosc":"ecussonne","lemma":"écussonner","pos":"VER"} ,
		{"word":"écussonnées","word_nosc":"ecussonnees","lemma":"écussonner","pos":"VER"} ,
		{"word":"écussonnés","word_nosc":"ecussonnes","lemma":"écussonner","pos":"VER"} ,
		{"word":"édenté","word_nosc":"edente","lemma":"édenter","pos":"VER"} ,
		{"word":"édentée","word_nosc":"edentee","lemma":"édenter","pos":"VER"} ,
		{"word":"édentées","word_nosc":"edentees","lemma":"édenter","pos":"VER"} ,
		{"word":"édentés","word_nosc":"edentes","lemma":"édenter","pos":"VER"} ,
		{"word":"édicte","word_nosc":"edicte","lemma":"édicter","pos":"VER"} ,
		{"word":"édicter","word_nosc":"edicter","lemma":"édicter","pos":"VER"} ,
		{"word":"édicté","word_nosc":"edicte","lemma":"édicter","pos":"VER"} ,
		{"word":"édictée","word_nosc":"edictee","lemma":"édicter","pos":"VER"} ,
		{"word":"édictées","word_nosc":"edictees","lemma":"édicter","pos":"VER"} ,
		{"word":"édifia","word_nosc":"edifia","lemma":"édifier","pos":"VER"} ,
		{"word":"édifiaient","word_nosc":"edifiaient","lemma":"édifier","pos":"VER"} ,
		{"word":"édifiait","word_nosc":"edifiait","lemma":"édifier","pos":"VER"} ,
		{"word":"édifiant","word_nosc":"edifiant","lemma":"édifier","pos":"VER"} ,
		{"word":"édifie","word_nosc":"edifie","lemma":"édifier","pos":"VER"} ,
		{"word":"édifient","word_nosc":"edifient","lemma":"édifier","pos":"VER"} ,
		{"word":"édifier","word_nosc":"edifier","lemma":"édifier","pos":"VER"} ,
		{"word":"édifierait","word_nosc":"edifierait","lemma":"édifier","pos":"VER"} ,
		{"word":"édifierons","word_nosc":"edifierons","lemma":"édifier","pos":"VER"} ,
		{"word":"édifions","word_nosc":"edifions","lemma":"édifier","pos":"VER"} ,
		{"word":"édifièrent","word_nosc":"edifierent","lemma":"édifier","pos":"VER"} ,
		{"word":"édifié","word_nosc":"edifie","lemma":"édifier","pos":"VER"} ,
		{"word":"édifiée","word_nosc":"edifiee","lemma":"édifier","pos":"VER"} ,
		{"word":"édifiées","word_nosc":"edifiees","lemma":"édifier","pos":"VER"} ,
		{"word":"édifiés","word_nosc":"edifies","lemma":"édifier","pos":"VER"} ,
		{"word":"éditait","word_nosc":"editait","lemma":"éditer","pos":"VER"} ,
		{"word":"édite","word_nosc":"edite","lemma":"éditer","pos":"VER"} ,
		{"word":"éditer","word_nosc":"editer","lemma":"éditer","pos":"VER"} ,
		{"word":"éditera","word_nosc":"editera","lemma":"éditer","pos":"VER"} ,
		{"word":"éditerais","word_nosc":"editerais","lemma":"éditer","pos":"VER"} ,
		{"word":"éditions","word_nosc":"editions","lemma":"éditer","pos":"VER"} ,
		{"word":"éditons","word_nosc":"editons","lemma":"éditer","pos":"VER"} ,
		{"word":"édité","word_nosc":"edite","lemma":"éditer","pos":"VER"} ,
		{"word":"éditée","word_nosc":"editee","lemma":"éditer","pos":"VER"} ,
		{"word":"éditées","word_nosc":"editees","lemma":"éditer","pos":"VER"} ,
		{"word":"édités","word_nosc":"edites","lemma":"éditer","pos":"VER"} ,
		{"word":"édulcorais","word_nosc":"edulcorais","lemma":"édulcorer","pos":"VER"} ,
		{"word":"édulcorer","word_nosc":"edulcorer","lemma":"édulcorer","pos":"VER"} ,
		{"word":"édulcoré","word_nosc":"edulcore","lemma":"édulcorer","pos":"VER"} ,
		{"word":"édulcorée","word_nosc":"edulcoree","lemma":"édulcorer","pos":"VER"} ,
		{"word":"édulcorées","word_nosc":"edulcorees","lemma":"édulcorer","pos":"VER"} ,
		{"word":"éduquais","word_nosc":"eduquais","lemma":"éduquer","pos":"VER"} ,
		{"word":"éduquant","word_nosc":"eduquant","lemma":"éduquer","pos":"VER"} ,
		{"word":"éduque","word_nosc":"eduque","lemma":"éduquer","pos":"VER"} ,
		{"word":"éduquer","word_nosc":"eduquer","lemma":"éduquer","pos":"VER"} ,
		{"word":"éduquera","word_nosc":"eduquera","lemma":"éduquer","pos":"VER"} ,
		{"word":"éduquez","word_nosc":"eduquez","lemma":"éduquer","pos":"VER"} ,
		{"word":"éduquons","word_nosc":"eduquons","lemma":"éduquer","pos":"VER"} ,
		{"word":"éduqué","word_nosc":"eduque","lemma":"éduquer","pos":"VER"} ,
		{"word":"éduquée","word_nosc":"eduquee","lemma":"éduquer","pos":"VER"} ,
		{"word":"éduquées","word_nosc":"eduquees","lemma":"éduquer","pos":"VER"} ,
		{"word":"éduqués","word_nosc":"eduques","lemma":"éduquer","pos":"VER"} ,
		{"word":"égaie","word_nosc":"egaie","lemma":"égayer","pos":"VER"} ,
		{"word":"égaieraient","word_nosc":"egaieraient","lemma":"égayer","pos":"VER"} ,
		{"word":"égaierait","word_nosc":"egaierait","lemma":"égayer","pos":"VER"} ,
		{"word":"égaieront","word_nosc":"egaieront","lemma":"égayer","pos":"VER"} ,
		{"word":"égailla","word_nosc":"egailla","lemma":"égailler","pos":"VER"} ,
		{"word":"égaillaient","word_nosc":"egaillaient","lemma":"égailler","pos":"VER"} ,
		{"word":"égaillait","word_nosc":"egaillait","lemma":"égailler","pos":"VER"} ,
		{"word":"égaillant","word_nosc":"egaillant","lemma":"égailler","pos":"VER"} ,
		{"word":"égaillent","word_nosc":"egaillent","lemma":"égailler","pos":"VER"} ,
		{"word":"égailler","word_nosc":"egailler","lemma":"égailler","pos":"VER"} ,
		{"word":"égaillèrent","word_nosc":"egaillerent","lemma":"égailler","pos":"VER"} ,
		{"word":"égaillée","word_nosc":"egaillee","lemma":"égailler","pos":"VER"} ,
		{"word":"égaillées","word_nosc":"egaillees","lemma":"égailler","pos":"VER"} ,
		{"word":"égaillés","word_nosc":"egailles","lemma":"égailler","pos":"VER"} ,
		{"word":"égala","word_nosc":"egala","lemma":"égaler","pos":"VER"} ,
		{"word":"égalaient","word_nosc":"egalaient","lemma":"égaler","pos":"VER"} ,
		{"word":"égalais","word_nosc":"egalais","lemma":"égaler","pos":"VER"} ,
		{"word":"égalait","word_nosc":"egalait","lemma":"égaler","pos":"VER"} ,
		{"word":"égalant","word_nosc":"egalant","lemma":"égaler","pos":"VER"} ,
		{"word":"égale","word_nosc":"egale","lemma":"égaler","pos":"VER"} ,
		{"word":"égalent","word_nosc":"egalent","lemma":"égaler","pos":"VER"} ,
		{"word":"égaler","word_nosc":"egaler","lemma":"égaler","pos":"VER"} ,
		{"word":"égalera","word_nosc":"egalera","lemma":"égaler","pos":"VER"} ,
		{"word":"égalerai","word_nosc":"egalerai","lemma":"égaler","pos":"VER"} ,
		{"word":"égalerais","word_nosc":"egalerais","lemma":"égaler","pos":"VER"} ,
		{"word":"égalerait","word_nosc":"egalerait","lemma":"égaler","pos":"VER"} ,
		{"word":"égaleras","word_nosc":"egaleras","lemma":"égaler","pos":"VER"} ,
		{"word":"égaleront","word_nosc":"egaleront","lemma":"égaler","pos":"VER"} ,
		{"word":"égalisa","word_nosc":"egalisa","lemma":"égaliser","pos":"VER"} ,
		{"word":"égalisais","word_nosc":"egalisais","lemma":"égaliser","pos":"VER"} ,
		{"word":"égalisait","word_nosc":"egalisait","lemma":"égaliser","pos":"VER"} ,
		{"word":"égalisant","word_nosc":"egalisant","lemma":"égaliser","pos":"VER"} ,
		{"word":"égalise","word_nosc":"egalise","lemma":"égaliser","pos":"VER"} ,
		{"word":"égalisent","word_nosc":"egalisent","lemma":"égaliser","pos":"VER"} ,
		{"word":"égaliser","word_nosc":"egaliser","lemma":"égaliser","pos":"VER"} ,
		{"word":"égalisez","word_nosc":"egalisez","lemma":"égaliser","pos":"VER"} ,
		{"word":"égalisèrent","word_nosc":"egaliserent","lemma":"égaliser","pos":"VER"} ,
		{"word":"égalisé","word_nosc":"egalise","lemma":"égaliser","pos":"VER"} ,
		{"word":"égalisées","word_nosc":"egalisees","lemma":"égaliser","pos":"VER"} ,
		{"word":"égalisés","word_nosc":"egalises","lemma":"égaliser","pos":"VER"} ,
		{"word":"égalât","word_nosc":"egalat","lemma":"égaler","pos":"VER"} ,
		{"word":"égalé","word_nosc":"egale","lemma":"égaler","pos":"VER"} ,
		{"word":"égalée","word_nosc":"egalee","lemma":"égaler","pos":"VER"} ,
		{"word":"égalées","word_nosc":"egalees","lemma":"égaler","pos":"VER"} ,
		{"word":"égara","word_nosc":"egara","lemma":"égarer","pos":"VER"} ,
		{"word":"égarai","word_nosc":"egarai","lemma":"égarer","pos":"VER"} ,
		{"word":"égaraient","word_nosc":"egaraient","lemma":"égarer","pos":"VER"} ,
		{"word":"égarais","word_nosc":"egarais","lemma":"égarer","pos":"VER"} ,
		{"word":"égarait","word_nosc":"egarait","lemma":"égarer","pos":"VER"} ,
		{"word":"égarant","word_nosc":"egarant","lemma":"égarer","pos":"VER"} ,
		{"word":"égare","word_nosc":"egare","lemma":"égarer","pos":"VER"} ,
		{"word":"égarent","word_nosc":"egarent","lemma":"égarer","pos":"VER"} ,
		{"word":"égarer","word_nosc":"egarer","lemma":"égarer","pos":"VER"} ,
		{"word":"égareraient","word_nosc":"egareraient","lemma":"égarer","pos":"VER"} ,
		{"word":"égarerais","word_nosc":"egarerais","lemma":"égarer","pos":"VER"} ,
		{"word":"égarerait","word_nosc":"egarerait","lemma":"égarer","pos":"VER"} ,
		{"word":"égarerions","word_nosc":"egarerions","lemma":"égarer","pos":"VER"} ,
		{"word":"égares","word_nosc":"egares","lemma":"égarer","pos":"VER"} ,
		{"word":"égarez","word_nosc":"egarez","lemma":"égarer","pos":"VER"} ,
		{"word":"égarons","word_nosc":"egarons","lemma":"égarer","pos":"VER"} ,
		{"word":"égarât","word_nosc":"egarat","lemma":"égarer","pos":"VER"} ,
		{"word":"égarèrent","word_nosc":"egarerent","lemma":"égarer","pos":"VER"} ,
		{"word":"égaré","word_nosc":"egare","lemma":"égarer","pos":"VER"} ,
		{"word":"égarée","word_nosc":"egaree","lemma":"égarer","pos":"VER"} ,
		{"word":"égarées","word_nosc":"egarees","lemma":"égarer","pos":"VER"} ,
		{"word":"égarés","word_nosc":"egares","lemma":"égarer","pos":"VER"} ,
		{"word":"égaya","word_nosc":"egaya","lemma":"égayer","pos":"VER"} ,
		{"word":"égayaient","word_nosc":"egayaient","lemma":"égayer","pos":"VER"} ,
		{"word":"égayait","word_nosc":"egayait","lemma":"égayer","pos":"VER"} ,
		{"word":"égayant","word_nosc":"egayant","lemma":"égayer","pos":"VER"} ,
		{"word":"égaye","word_nosc":"egaye","lemma":"égayer","pos":"VER"} ,
		{"word":"égayer","word_nosc":"egayer","lemma":"égayer","pos":"VER"} ,
		{"word":"égayerais","word_nosc":"egayerais","lemma":"égayer","pos":"VER"} ,
		{"word":"égayez","word_nosc":"egayez","lemma":"égayer","pos":"VER"} ,
		{"word":"égayât","word_nosc":"egayat","lemma":"égayer","pos":"VER"} ,
		{"word":"égayèrent","word_nosc":"egayerent","lemma":"égayer","pos":"VER"} ,
		{"word":"égayé","word_nosc":"egaye","lemma":"égayer","pos":"VER"} ,
		{"word":"égayée","word_nosc":"egayee","lemma":"égayer","pos":"VER"} ,
		{"word":"égayées","word_nosc":"egayees","lemma":"égayer","pos":"VER"} ,
		{"word":"égayés","word_nosc":"egayes","lemma":"égayer","pos":"VER"} ,
		{"word":"égorge","word_nosc":"egorge","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgea","word_nosc":"egorgea","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgeaient","word_nosc":"egorgeaient","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgeais","word_nosc":"egorgeais","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgeait","word_nosc":"egorgeait","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgeant","word_nosc":"egorgeant","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgent","word_nosc":"egorgent","lemma":"égorger","pos":"VER"} ,
		{"word":"égorger","word_nosc":"egorger","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgerai","word_nosc":"egorgerai","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgeraient","word_nosc":"egorgeraient","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgerais","word_nosc":"egorgerais","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgeras","word_nosc":"egorgeras","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgeront","word_nosc":"egorgeront","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgez","word_nosc":"egorgez","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgèrent","word_nosc":"egorgerent","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgé","word_nosc":"egorge","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgée","word_nosc":"egorgee","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgées","word_nosc":"egorgees","lemma":"égorger","pos":"VER"} ,
		{"word":"égorgés","word_nosc":"egorges","lemma":"égorger","pos":"VER"} ,
		{"word":"égosilla","word_nosc":"egosilla","lemma":"égosiller","pos":"VER"} ,
		{"word":"égosillaient","word_nosc":"egosillaient","lemma":"égosiller","pos":"VER"} ,
		{"word":"égosillait","word_nosc":"egosillait","lemma":"égosiller","pos":"VER"} ,
		{"word":"égosillant","word_nosc":"egosillant","lemma":"égosiller","pos":"VER"} ,
		{"word":"égosille","word_nosc":"egosille","lemma":"égosiller","pos":"VER"} ,
		{"word":"égosillent","word_nosc":"egosillent","lemma":"égosiller","pos":"VER"} ,
		{"word":"égosiller","word_nosc":"egosiller","lemma":"égosiller","pos":"VER"} ,
		{"word":"égoutta","word_nosc":"egoutta","lemma":"égoutter","pos":"VER"} ,
		{"word":"égouttaient","word_nosc":"egouttaient","lemma":"égoutter","pos":"VER"} ,
		{"word":"égouttait","word_nosc":"egouttait","lemma":"égoutter","pos":"VER"} ,
		{"word":"égouttant","word_nosc":"egouttant","lemma":"égoutter","pos":"VER"} ,
		{"word":"égoutte","word_nosc":"egoutte","lemma":"égoutter","pos":"VER"} ,
		{"word":"égouttent","word_nosc":"egouttent","lemma":"égoutter","pos":"VER"} ,
		{"word":"égoutter","word_nosc":"egoutter","lemma":"égoutter","pos":"VER"} ,
		{"word":"égoutterait","word_nosc":"egoutterait","lemma":"égoutter","pos":"VER"} ,
		{"word":"égouttez","word_nosc":"egouttez","lemma":"égoutter","pos":"VER"} ,
		{"word":"égouttèrent","word_nosc":"egoutterent","lemma":"égoutter","pos":"VER"} ,
		{"word":"égoutté","word_nosc":"egoutte","lemma":"égoutter","pos":"VER"} ,
		{"word":"égrainer","word_nosc":"egrainer","lemma":"égrainer","pos":"VER"} ,
		{"word":"égrappait","word_nosc":"egrappait","lemma":"égrapper","pos":"VER"} ,
		{"word":"égratigna","word_nosc":"egratigna","lemma":"égratigner","pos":"VER"} ,
		{"word":"égratignaient","word_nosc":"egratignaient","lemma":"égratigner","pos":"VER"} ,
		{"word":"égratignait","word_nosc":"egratignait","lemma":"égratigner","pos":"VER"} ,
		{"word":"égratignant","word_nosc":"egratignant","lemma":"égratigner","pos":"VER"} ,
		{"word":"égratigne","word_nosc":"egratigne","lemma":"égratigner","pos":"VER"} ,
		{"word":"égratignent","word_nosc":"egratignent","lemma":"égratigner","pos":"VER"} ,
		{"word":"égratigner","word_nosc":"egratigner","lemma":"égratigner","pos":"VER"} ,
		{"word":"égratignes","word_nosc":"egratignes","lemma":"égratigner","pos":"VER"} ,
		{"word":"égratigné","word_nosc":"egratigne","lemma":"égratigner","pos":"VER"} ,
		{"word":"égratignée","word_nosc":"egratignee","lemma":"égratigner","pos":"VER"} ,
		{"word":"égratignés","word_nosc":"egratignes","lemma":"égratigner","pos":"VER"} ,
		{"word":"égrena","word_nosc":"egrena","lemma":"égrener","pos":"VER"} ,
		{"word":"égrenaient","word_nosc":"egrenaient","lemma":"égrener","pos":"VER"} ,
		{"word":"égrenais","word_nosc":"egrenais","lemma":"égrener","pos":"VER"} ,
		{"word":"égrenait","word_nosc":"egrenait","lemma":"égrener","pos":"VER"} ,
		{"word":"égrenant","word_nosc":"egrenant","lemma":"égrener","pos":"VER"} ,
		{"word":"égrener","word_nosc":"egrener","lemma":"égrener","pos":"VER"} ,
		{"word":"égrenèrent","word_nosc":"egrenerent","lemma":"égrener","pos":"VER"} ,
		{"word":"égrené","word_nosc":"egrene","lemma":"égrener","pos":"VER"} ,
		{"word":"égrenée","word_nosc":"egrenee","lemma":"égrener","pos":"VER"} ,
		{"word":"égrenées","word_nosc":"egrenees","lemma":"égrener","pos":"VER"} ,
		{"word":"égrenés","word_nosc":"egrenes","lemma":"égrener","pos":"VER"} ,
		{"word":"égrène","word_nosc":"egrene","lemma":"égrener","pos":"VER"} ,
		{"word":"égrènent","word_nosc":"egrenent","lemma":"égrener","pos":"VER"} ,
		{"word":"éjacula","word_nosc":"ejacula","lemma":"éjaculer","pos":"VER"} ,
		{"word":"éjaculait","word_nosc":"ejaculait","lemma":"éjaculer","pos":"VER"} ,
		{"word":"éjaculant","word_nosc":"ejaculant","lemma":"éjaculer","pos":"VER"} ,
		{"word":"éjacule","word_nosc":"ejacule","lemma":"éjaculer","pos":"VER"} ,
		{"word":"éjaculent","word_nosc":"ejaculent","lemma":"éjaculer","pos":"VER"} ,
		{"word":"éjaculer","word_nosc":"ejaculer","lemma":"éjaculer","pos":"VER"} ,
		{"word":"éjaculé","word_nosc":"ejacule","lemma":"éjaculer","pos":"VER"} ,
		{"word":"éjaculés","word_nosc":"ejacules","lemma":"éjaculer","pos":"VER"} ,
		{"word":"éjecta","word_nosc":"ejecta","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjectaient","word_nosc":"ejectaient","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjectait","word_nosc":"ejectait","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjectant","word_nosc":"ejectant","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjectas","word_nosc":"ejectas","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjecte","word_nosc":"ejecte","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjectent","word_nosc":"ejectent","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjecter","word_nosc":"ejecter","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjectera","word_nosc":"ejectera","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjecterais","word_nosc":"ejecterais","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjectez","word_nosc":"ejectez","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjectons","word_nosc":"ejectons","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjectèrent","word_nosc":"ejecterent","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjecté","word_nosc":"ejecte","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjectée","word_nosc":"ejectee","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjectées","word_nosc":"ejectees","lemma":"éjecter","pos":"VER"} ,
		{"word":"éjectés","word_nosc":"ejectes","lemma":"éjecter","pos":"VER"} ,
		{"word":"élabora","word_nosc":"elabora","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaborai","word_nosc":"elaborai","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaboraient","word_nosc":"elaboraient","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaborait","word_nosc":"elaborait","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaborant","word_nosc":"elaborant","lemma":"élaborer","pos":"VER"} ,
		{"word":"élabore","word_nosc":"elabore","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaborent","word_nosc":"elaborent","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaborer","word_nosc":"elaborer","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaboreraient","word_nosc":"elaboreraient","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaboriez","word_nosc":"elaboriez","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaborions","word_nosc":"elaborions","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaborons","word_nosc":"elaborons","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaborèrent","word_nosc":"elaborerent","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaboré","word_nosc":"elabore","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaborée","word_nosc":"elaboree","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaborées","word_nosc":"elaborees","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaborés","word_nosc":"elabores","lemma":"élaborer","pos":"VER"} ,
		{"word":"élaguais","word_nosc":"elaguais","lemma":"élaguer","pos":"VER"} ,
		{"word":"élaguait","word_nosc":"elaguait","lemma":"élaguer","pos":"VER"} ,
		{"word":"élague","word_nosc":"elague","lemma":"élaguer","pos":"VER"} ,
		{"word":"élaguer","word_nosc":"elaguer","lemma":"élaguer","pos":"VER"} ,
		{"word":"élaguez","word_nosc":"elaguez","lemma":"élaguer","pos":"VER"} ,
		{"word":"élaguons","word_nosc":"elaguons","lemma":"élaguer","pos":"VER"} ,
		{"word":"élagué","word_nosc":"elague","lemma":"élaguer","pos":"VER"} ,
		{"word":"élagués","word_nosc":"elagues","lemma":"élaguer","pos":"VER"} ,
		{"word":"élance","word_nosc":"elance","lemma":"élancer","pos":"VER"} ,
		{"word":"élancent","word_nosc":"elancent","lemma":"élancer","pos":"VER"} ,
		{"word":"élancer","word_nosc":"elancer","lemma":"élancer","pos":"VER"} ,
		{"word":"élancera","word_nosc":"elancera","lemma":"élancer","pos":"VER"} ,
		{"word":"élanceraient","word_nosc":"elanceraient","lemma":"élancer","pos":"VER"} ,
		{"word":"élancerait","word_nosc":"elancerait","lemma":"élancer","pos":"VER"} ,
		{"word":"élancerions","word_nosc":"elancerions","lemma":"élancer","pos":"VER"} ,
		{"word":"élanceront","word_nosc":"elanceront","lemma":"élancer","pos":"VER"} ,
		{"word":"élancions","word_nosc":"elancions","lemma":"élancer","pos":"VER"} ,
		{"word":"élancèrent","word_nosc":"elancerent","lemma":"élancer","pos":"VER"} ,
		{"word":"élancé","word_nosc":"elance","lemma":"élancer","pos":"VER"} ,
		{"word":"élancée","word_nosc":"elancee","lemma":"élancer","pos":"VER"} ,
		{"word":"élancées","word_nosc":"elancees","lemma":"élancer","pos":"VER"} ,
		{"word":"élancés","word_nosc":"elances","lemma":"élancer","pos":"VER"} ,
		{"word":"élança","word_nosc":"elanca","lemma":"élancer","pos":"VER"} ,
		{"word":"élançai","word_nosc":"elancai","lemma":"élancer","pos":"VER"} ,
		{"word":"élançaient","word_nosc":"elancaient","lemma":"élancer","pos":"VER"} ,
		{"word":"élançais","word_nosc":"elancais","lemma":"élancer","pos":"VER"} ,
		{"word":"élançait","word_nosc":"elancait","lemma":"élancer","pos":"VER"} ,
		{"word":"élançant","word_nosc":"elancant","lemma":"élancer","pos":"VER"} ,
		{"word":"élargi","word_nosc":"elargi","lemma":"élargir","pos":"VER"} ,
		{"word":"élargie","word_nosc":"elargie","lemma":"élargir","pos":"VER"} ,
		{"word":"élargies","word_nosc":"elargies","lemma":"élargir","pos":"VER"} ,
		{"word":"élargir","word_nosc":"elargir","lemma":"élargir","pos":"VER"} ,
		{"word":"élargira","word_nosc":"elargira","lemma":"élargir","pos":"VER"} ,
		{"word":"élargirait","word_nosc":"elargirait","lemma":"élargir","pos":"VER"} ,
		{"word":"élargirent","word_nosc":"elargirent","lemma":"élargir","pos":"VER"} ,
		{"word":"élargirez","word_nosc":"elargirez","lemma":"élargir","pos":"VER"} ,
		{"word":"élargirons","word_nosc":"elargirons","lemma":"élargir","pos":"VER"} ,
		{"word":"élargis","word_nosc":"elargis","lemma":"élargir","pos":"VER"} ,
		{"word":"élargissaient","word_nosc":"elargissaient","lemma":"élargir","pos":"VER"} ,
		{"word":"élargissait","word_nosc":"elargissait","lemma":"élargir","pos":"VER"} ,
		{"word":"élargissant","word_nosc":"elargissant","lemma":"élargir","pos":"VER"} ,
		{"word":"élargissent","word_nosc":"elargissent","lemma":"élargir","pos":"VER"} ,
		{"word":"élargissez","word_nosc":"elargissez","lemma":"élargir","pos":"VER"} ,
		{"word":"élargissions","word_nosc":"elargissions","lemma":"élargir","pos":"VER"} ,
		{"word":"élargit","word_nosc":"elargit","lemma":"élargir","pos":"VER"} ,
		{"word":"élargît","word_nosc":"elargit","lemma":"élargir","pos":"VER"} ,
		{"word":"électrifia","word_nosc":"electrifia","lemma":"électrifier","pos":"VER"} ,
		{"word":"électrifiait","word_nosc":"electrifiait","lemma":"électrifier","pos":"VER"} ,
		{"word":"électrifiant","word_nosc":"electrifiant","lemma":"électrifier","pos":"VER"} ,
		{"word":"électrifier","word_nosc":"electrifier","lemma":"électrifier","pos":"VER"} ,
		{"word":"électrifié","word_nosc":"electrifie","lemma":"électrifier","pos":"VER"} ,
		{"word":"électrifiée","word_nosc":"electrifiee","lemma":"électrifier","pos":"VER"} ,
		{"word":"électrifiées","word_nosc":"electrifiees","lemma":"électrifier","pos":"VER"} ,
		{"word":"électrifiés","word_nosc":"electrifies","lemma":"électrifier","pos":"VER"} ,
		{"word":"électrisa","word_nosc":"electrisa","lemma":"électriser","pos":"VER"} ,
		{"word":"électrisaient","word_nosc":"electrisaient","lemma":"électriser","pos":"VER"} ,
		{"word":"électrisais","word_nosc":"electrisais","lemma":"électriser","pos":"VER"} ,
		{"word":"électrisait","word_nosc":"electrisait","lemma":"électriser","pos":"VER"} ,
		{"word":"électrisant","word_nosc":"electrisant","lemma":"électriser","pos":"VER"} ,
		{"word":"électrise","word_nosc":"electrise","lemma":"électriser","pos":"VER"} ,
		{"word":"électrisent","word_nosc":"electrisent","lemma":"électriser","pos":"VER"} ,
		{"word":"électriser","word_nosc":"electriser","lemma":"électriser","pos":"VER"} ,
		{"word":"électrisèrent","word_nosc":"electriserent","lemma":"électriser","pos":"VER"} ,
		{"word":"électrisé","word_nosc":"electrise","lemma":"électriser","pos":"VER"} ,
		{"word":"électrisée","word_nosc":"electrisee","lemma":"électriser","pos":"VER"} ,
		{"word":"électrisées","word_nosc":"electrisees","lemma":"électriser","pos":"VER"} ,
		{"word":"électrocutant","word_nosc":"electrocutant","lemma":"électrocuter","pos":"VER"} ,
		{"word":"électrocute","word_nosc":"electrocute","lemma":"électrocuter","pos":"VER"} ,
		{"word":"électrocutent","word_nosc":"electrocutent","lemma":"électrocuter","pos":"VER"} ,
		{"word":"électrocuter","word_nosc":"electrocuter","lemma":"électrocuter","pos":"VER"} ,
		{"word":"électrocutera","word_nosc":"electrocutera","lemma":"électrocuter","pos":"VER"} ,
		{"word":"électrocutes","word_nosc":"electrocutes","lemma":"électrocuter","pos":"VER"} ,
		{"word":"électrocutez","word_nosc":"electrocutez","lemma":"électrocuter","pos":"VER"} ,
		{"word":"électrocuté","word_nosc":"electrocute","lemma":"électrocuter","pos":"VER"} ,
		{"word":"électrocutée","word_nosc":"electrocutee","lemma":"électrocuter","pos":"VER"} ,
		{"word":"électrocutés","word_nosc":"electrocutes","lemma":"électrocuter","pos":"VER"} ,
		{"word":"électrolyse","word_nosc":"electrolyse","lemma":"électrolyser","pos":"VER"} ,
		{"word":"éleva","word_nosc":"eleva","lemma":"élever","pos":"VER"} ,
		{"word":"élevai","word_nosc":"elevai","lemma":"élever","pos":"VER"} ,
		{"word":"élevaient","word_nosc":"elevaient","lemma":"élever","pos":"VER"} ,
		{"word":"élevais","word_nosc":"elevais","lemma":"élever","pos":"VER"} ,
		{"word":"élevait","word_nosc":"elevait","lemma":"élever","pos":"VER"} ,
		{"word":"élevant","word_nosc":"elevant","lemma":"élever","pos":"VER"} ,
		{"word":"élever","word_nosc":"elever","lemma":"élever","pos":"VER"} ,
		{"word":"élevez","word_nosc":"elevez","lemma":"élever","pos":"VER"} ,
		{"word":"éleviez","word_nosc":"eleviez","lemma":"élever","pos":"VER"} ,
		{"word":"élevions","word_nosc":"elevions","lemma":"élever","pos":"VER"} ,
		{"word":"élevons","word_nosc":"elevons","lemma":"élever","pos":"VER"} ,
		{"word":"élevât","word_nosc":"elevat","lemma":"élever","pos":"VER"} ,
		{"word":"élevèrent","word_nosc":"eleverent","lemma":"élever","pos":"VER"} ,
		{"word":"élevé","word_nosc":"eleve","lemma":"élever","pos":"VER"} ,
		{"word":"élevée","word_nosc":"elevee","lemma":"élever","pos":"VER"} ,
		{"word":"élevées","word_nosc":"elevees","lemma":"élever","pos":"VER"} ,
		{"word":"élevés","word_nosc":"eleves","lemma":"élever","pos":"VER"} ,
		{"word":"élia","word_nosc":"elia","lemma":"élier","pos":"VER"} ,
		{"word":"élias","word_nosc":"elias","lemma":"élier","pos":"VER"} ,
		{"word":"éliciter","word_nosc":"eliciter","lemma":"éliciter","pos":"VER"} ,
		{"word":"élie","word_nosc":"elie","lemma":"élier","pos":"VER"} ,
		{"word":"élime","word_nosc":"elime","lemma":"élimer","pos":"VER"} ,
		{"word":"élimer","word_nosc":"elimer","lemma":"élimer","pos":"VER"} ,
		{"word":"élimina","word_nosc":"elimina","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminais","word_nosc":"eliminais","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminait","word_nosc":"eliminait","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminant","word_nosc":"eliminant","lemma":"éliminer","pos":"VER"} ,
		{"word":"élimine","word_nosc":"elimine","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminent","word_nosc":"eliminent","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminer","word_nosc":"eliminer","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminera","word_nosc":"eliminera","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminerai","word_nosc":"eliminerai","lemma":"éliminer","pos":"VER"} ,
		{"word":"élimineraient","word_nosc":"elimineraient","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminerais","word_nosc":"eliminerais","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminerait","word_nosc":"eliminerait","lemma":"éliminer","pos":"VER"} ,
		{"word":"élimineras","word_nosc":"elimineras","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminerez","word_nosc":"eliminerez","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminerons","word_nosc":"eliminerons","lemma":"éliminer","pos":"VER"} ,
		{"word":"élimineront","word_nosc":"elimineront","lemma":"éliminer","pos":"VER"} ,
		{"word":"élimines","word_nosc":"elimines","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminez","word_nosc":"eliminez","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminiez","word_nosc":"eliminiez","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminons","word_nosc":"eliminons","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminèrent","word_nosc":"eliminerent","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminé","word_nosc":"elimine","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminée","word_nosc":"eliminee","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminées","word_nosc":"eliminees","lemma":"éliminer","pos":"VER"} ,
		{"word":"éliminés","word_nosc":"elimines","lemma":"éliminer","pos":"VER"} ,
		{"word":"élimâmes","word_nosc":"elimames","lemma":"élimer","pos":"VER"} ,
		{"word":"élimé","word_nosc":"elime","lemma":"élimer","pos":"VER"} ,
		{"word":"élimée","word_nosc":"elimee","lemma":"élimer","pos":"VER"} ,
		{"word":"élimées","word_nosc":"elimees","lemma":"élimer","pos":"VER"} ,
		{"word":"élimés","word_nosc":"elimes","lemma":"élimer","pos":"VER"} ,
		{"word":"élirait","word_nosc":"elirait","lemma":"élire","pos":"VER"} ,
		{"word":"élire","word_nosc":"elire","lemma":"élire","pos":"VER"} ,
		{"word":"élirez","word_nosc":"elirez","lemma":"élire","pos":"VER"} ,
		{"word":"éliront","word_nosc":"eliront","lemma":"élire","pos":"VER"} ,
		{"word":"élis","word_nosc":"elis","lemma":"élire","pos":"VER"} ,
		{"word":"élisaient","word_nosc":"elisaient","lemma":"élire","pos":"VER"} ,
		{"word":"élisais","word_nosc":"elisais","lemma":"élire","pos":"VER"} ,
		{"word":"élisait","word_nosc":"elisait","lemma":"élire","pos":"VER"} ,
		{"word":"élisant","word_nosc":"elisant","lemma":"élire","pos":"VER"} ,
		{"word":"élise","word_nosc":"elise","lemma":"élire","pos":"VER"} ,
		{"word":"élisent","word_nosc":"elisent","lemma":"élire","pos":"VER"} ,
		{"word":"élisez","word_nosc":"elisez","lemma":"élire","pos":"VER"} ,
		{"word":"élit","word_nosc":"elit","lemma":"élire","pos":"VER"} ,
		{"word":"éloigna","word_nosc":"eloigna","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignai","word_nosc":"eloignai","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignaient","word_nosc":"eloignaient","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignais","word_nosc":"eloignais","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignait","word_nosc":"eloignait","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignant","word_nosc":"eloignant","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloigne","word_nosc":"eloigne","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignent","word_nosc":"eloignent","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloigner","word_nosc":"eloigner","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignera","word_nosc":"eloignera","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignerai","word_nosc":"eloignerai","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloigneraient","word_nosc":"eloigneraient","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignerais","word_nosc":"eloignerais","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignerait","word_nosc":"eloignerait","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloigneras","word_nosc":"eloigneras","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignerez","word_nosc":"eloignerez","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignerons","word_nosc":"eloignerons","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloigneront","word_nosc":"eloigneront","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignes","word_nosc":"eloignes","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignez","word_nosc":"eloignez","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignions","word_nosc":"eloignions","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignons","word_nosc":"eloignons","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignâmes","word_nosc":"eloignames","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignât","word_nosc":"eloignat","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignèrent","word_nosc":"eloignerent","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloigné","word_nosc":"eloigne","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignée","word_nosc":"eloignee","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignées","word_nosc":"eloignees","lemma":"éloigner","pos":"VER"} ,
		{"word":"éloignés","word_nosc":"eloignes","lemma":"éloigner","pos":"VER"} ,
		{"word":"élu","word_nosc":"elu","lemma":"élire","pos":"VER"} ,
		{"word":"élucida","word_nosc":"elucida","lemma":"élucider","pos":"VER"} ,
		{"word":"élucidai","word_nosc":"elucidai","lemma":"élucider","pos":"VER"} ,
		{"word":"élucide","word_nosc":"elucide","lemma":"élucider","pos":"VER"} ,
		{"word":"élucider","word_nosc":"elucider","lemma":"élucider","pos":"VER"} ,
		{"word":"élucideraient","word_nosc":"elucideraient","lemma":"élucider","pos":"VER"} ,
		{"word":"éluciderait","word_nosc":"eluciderait","lemma":"élucider","pos":"VER"} ,
		{"word":"élucidé","word_nosc":"elucide","lemma":"élucider","pos":"VER"} ,
		{"word":"élucidée","word_nosc":"elucidee","lemma":"élucider","pos":"VER"} ,
		{"word":"élucidées","word_nosc":"elucidees","lemma":"élucider","pos":"VER"} ,
		{"word":"élucidés","word_nosc":"elucides","lemma":"élucider","pos":"VER"} ,
		{"word":"élucubre","word_nosc":"elucubre","lemma":"élucubrer","pos":"VER"} ,
		{"word":"élucubrent","word_nosc":"elucubrent","lemma":"élucubrer","pos":"VER"} ,
		{"word":"élucubrer","word_nosc":"elucubrer","lemma":"élucubrer","pos":"VER"} ,
		{"word":"éluda","word_nosc":"eluda","lemma":"éluder","pos":"VER"} ,
		{"word":"éludai","word_nosc":"eludai","lemma":"éluder","pos":"VER"} ,
		{"word":"éludaient","word_nosc":"eludaient","lemma":"éluder","pos":"VER"} ,
		{"word":"éludais","word_nosc":"eludais","lemma":"éluder","pos":"VER"} ,
		{"word":"éludait","word_nosc":"eludait","lemma":"éluder","pos":"VER"} ,
		{"word":"éludant","word_nosc":"eludant","lemma":"éluder","pos":"VER"} ,
		{"word":"élude","word_nosc":"elude","lemma":"éluder","pos":"VER"} ,
		{"word":"éludent","word_nosc":"eludent","lemma":"éluder","pos":"VER"} ,
		{"word":"éluder","word_nosc":"eluder","lemma":"éluder","pos":"VER"} ,
		{"word":"éludes","word_nosc":"eludes","lemma":"éluder","pos":"VER"} ,
		{"word":"éludez","word_nosc":"eludez","lemma":"éluder","pos":"VER"} ,
		{"word":"éludât","word_nosc":"eludat","lemma":"éluder","pos":"VER"} ,
		{"word":"éludé","word_nosc":"elude","lemma":"éluder","pos":"VER"} ,
		{"word":"éludée","word_nosc":"eludee","lemma":"éluder","pos":"VER"} ,
		{"word":"éludées","word_nosc":"eludees","lemma":"éluder","pos":"VER"} ,
		{"word":"élue","word_nosc":"elue","lemma":"éluer","pos":"VER"} ,
		{"word":"élus","word_nosc":"elus","lemma":"élire","pos":"VER"} ,
		{"word":"élut","word_nosc":"elut","lemma":"élire","pos":"VER"} ,
		{"word":"élève","word_nosc":"eleve","lemma":"élever","pos":"VER"} ,
		{"word":"élèvent","word_nosc":"elevent","lemma":"élever","pos":"VER"} ,
		{"word":"élèvera","word_nosc":"elevera","lemma":"élever","pos":"VER"} ,
		{"word":"élèverai","word_nosc":"eleverai","lemma":"élever","pos":"VER"} ,
		{"word":"élèveraient","word_nosc":"eleveraient","lemma":"élever","pos":"VER"} ,
		{"word":"élèverais","word_nosc":"eleverais","lemma":"élever","pos":"VER"} ,
		{"word":"élèverait","word_nosc":"eleverait","lemma":"élever","pos":"VER"} ,
		{"word":"élèveras","word_nosc":"eleveras","lemma":"élever","pos":"VER"} ,
		{"word":"élèveriez","word_nosc":"eleveriez","lemma":"élever","pos":"VER"} ,
		{"word":"élèverons","word_nosc":"eleverons","lemma":"élever","pos":"VER"} ,
		{"word":"élèveront","word_nosc":"eleveront","lemma":"élever","pos":"VER"} ,
		{"word":"élèves","word_nosc":"eleves","lemma":"élever","pos":"VER"} ,
		{"word":"émaciait","word_nosc":"emaciait","lemma":"émacier","pos":"VER"} ,
		{"word":"émacie","word_nosc":"emacie","lemma":"émacier","pos":"VER"} ,
		{"word":"émacié","word_nosc":"emacie","lemma":"émacier","pos":"VER"} ,
		{"word":"émaciée","word_nosc":"emaciee","lemma":"émacier","pos":"VER"} ,
		{"word":"émaciées","word_nosc":"emaciees","lemma":"émacier","pos":"VER"} ,
		{"word":"émaciés","word_nosc":"emacies","lemma":"émacier","pos":"VER"} ,
		{"word":"émailla","word_nosc":"emailla","lemma":"émailler","pos":"VER"} ,
		{"word":"émaillaient","word_nosc":"emaillaient","lemma":"émailler","pos":"VER"} ,
		{"word":"émaillait","word_nosc":"emaillait","lemma":"émailler","pos":"VER"} ,
		{"word":"émaillant","word_nosc":"emaillant","lemma":"émailler","pos":"VER"} ,
		{"word":"émaille","word_nosc":"emaille","lemma":"émailler","pos":"VER"} ,
		{"word":"émaillent","word_nosc":"emaillent","lemma":"émailler","pos":"VER"} ,
		{"word":"émailler","word_nosc":"emailler","lemma":"émailler","pos":"VER"} ,
		{"word":"émaillé","word_nosc":"emaille","lemma":"émailler","pos":"VER"} ,
		{"word":"émaillée","word_nosc":"emaillee","lemma":"émailler","pos":"VER"} ,
		{"word":"émaillées","word_nosc":"emaillees","lemma":"émailler","pos":"VER"} ,
		{"word":"émaillés","word_nosc":"emailles","lemma":"émailler","pos":"VER"} ,
		{"word":"émanaient","word_nosc":"emanaient","lemma":"émaner","pos":"VER"} ,
		{"word":"émanait","word_nosc":"emanait","lemma":"émaner","pos":"VER"} ,
		{"word":"émanant","word_nosc":"emanant","lemma":"émaner","pos":"VER"} ,
		{"word":"émancipait","word_nosc":"emancipait","lemma":"émanciper","pos":"VER"} ,
		{"word":"émancipe","word_nosc":"emancipe","lemma":"émanciper","pos":"VER"} ,
		{"word":"émancipent","word_nosc":"emancipent","lemma":"émanciper","pos":"VER"} ,
		{"word":"émanciper","word_nosc":"emanciper","lemma":"émanciper","pos":"VER"} ,
		{"word":"émancipé","word_nosc":"emancipe","lemma":"émanciper","pos":"VER"} ,
		{"word":"émancipée","word_nosc":"emancipee","lemma":"émanciper","pos":"VER"} ,
		{"word":"émancipées","word_nosc":"emancipees","lemma":"émanciper","pos":"VER"} ,
		{"word":"émancipés","word_nosc":"emancipes","lemma":"émanciper","pos":"VER"} ,
		{"word":"émane","word_nosc":"emane","lemma":"émaner","pos":"VER"} ,
		{"word":"émanent","word_nosc":"emanent","lemma":"émaner","pos":"VER"} ,
		{"word":"émaner","word_nosc":"emaner","lemma":"émaner","pos":"VER"} ,
		{"word":"émanera","word_nosc":"emanera","lemma":"émaner","pos":"VER"} ,
		{"word":"émanerait","word_nosc":"emanerait","lemma":"émaner","pos":"VER"} ,
		{"word":"émané","word_nosc":"emane","lemma":"émaner","pos":"VER"} ,
		{"word":"émanée","word_nosc":"emanee","lemma":"émaner","pos":"VER"} ,
		{"word":"émanées","word_nosc":"emanees","lemma":"émaner","pos":"VER"} ,
		{"word":"émanés","word_nosc":"emanes","lemma":"émaner","pos":"VER"} ,
		{"word":"émarge","word_nosc":"emarge","lemma":"émarger","pos":"VER"} ,
		{"word":"émargeaient","word_nosc":"emargeaient","lemma":"émarger","pos":"VER"} ,
		{"word":"émargeait","word_nosc":"emargeait","lemma":"émarger","pos":"VER"} ,
		{"word":"émargeant","word_nosc":"emargeant","lemma":"émarger","pos":"VER"} ,
		{"word":"émarger","word_nosc":"emarger","lemma":"émarger","pos":"VER"} ,
		{"word":"émasculaient","word_nosc":"emasculaient","lemma":"émasculer","pos":"VER"} ,
		{"word":"émascule","word_nosc":"emascule","lemma":"émasculer","pos":"VER"} ,
		{"word":"émasculer","word_nosc":"emasculer","lemma":"émasculer","pos":"VER"} ,
		{"word":"émasculé","word_nosc":"emascule","lemma":"émasculer","pos":"VER"} ,
		{"word":"émasculée","word_nosc":"emasculee","lemma":"émasculer","pos":"VER"} ,
		{"word":"émasculées","word_nosc":"emasculees","lemma":"émasculer","pos":"VER"} ,
		{"word":"émasculés","word_nosc":"emascules","lemma":"émasculer","pos":"VER"} ,
		{"word":"émerge","word_nosc":"emerge","lemma":"émerger","pos":"VER"} ,
		{"word":"émergea","word_nosc":"emergea","lemma":"émerger","pos":"VER"} ,
		{"word":"émergeai","word_nosc":"emergeai","lemma":"émerger","pos":"VER"} ,
		{"word":"émergeaient","word_nosc":"emergeaient","lemma":"émerger","pos":"VER"} ,
		{"word":"émergeais","word_nosc":"emergeais","lemma":"émerger","pos":"VER"} ,
		{"word":"émergeait","word_nosc":"emergeait","lemma":"émerger","pos":"VER"} ,
		{"word":"émergeant","word_nosc":"emergeant","lemma":"émerger","pos":"VER"} ,
		{"word":"émergent","word_nosc":"emergent","lemma":"émerger","pos":"VER"} ,
		{"word":"émergeons","word_nosc":"emergeons","lemma":"émerger","pos":"VER"} ,
		{"word":"émerger","word_nosc":"emerger","lemma":"émerger","pos":"VER"} ,
		{"word":"émergera","word_nosc":"emergera","lemma":"émerger","pos":"VER"} ,
		{"word":"émergerait","word_nosc":"emergerait","lemma":"émerger","pos":"VER"} ,
		{"word":"émergeras","word_nosc":"emergeras","lemma":"émerger","pos":"VER"} ,
		{"word":"émergerons","word_nosc":"emergerons","lemma":"émerger","pos":"VER"} ,
		{"word":"émergeront","word_nosc":"emergeront","lemma":"émerger","pos":"VER"} ,
		{"word":"émerges","word_nosc":"emerges","lemma":"émerger","pos":"VER"} ,
		{"word":"émergeât","word_nosc":"emergeat","lemma":"émerger","pos":"VER"} ,
		{"word":"émergions","word_nosc":"emergions","lemma":"émerger","pos":"VER"} ,
		{"word":"émergèrent","word_nosc":"emergerent","lemma":"émerger","pos":"VER"} ,
		{"word":"émergé","word_nosc":"emerge","lemma":"émerger","pos":"VER"} ,
		{"word":"émergée","word_nosc":"emergee","lemma":"émerger","pos":"VER"} ,
		{"word":"émergés","word_nosc":"emerges","lemma":"émerger","pos":"VER"} ,
		{"word":"émerisé","word_nosc":"emerise","lemma":"émeriser","pos":"VER"} ,
		{"word":"émerisées","word_nosc":"emerisees","lemma":"émeriser","pos":"VER"} ,
		{"word":"émerveilla","word_nosc":"emerveilla","lemma":"émerveiller","pos":"VER"} ,
		{"word":"émerveillai","word_nosc":"emerveillai","lemma":"émerveiller","pos":"VER"} ,
		{"word":"émerveillaient","word_nosc":"emerveillaient","lemma":"émerveiller","pos":"VER"} ,
		{"word":"émerveillais","word_nosc":"emerveillais","lemma":"émerveiller","pos":"VER"} ,
		{"word":"émerveillait","word_nosc":"emerveillait","lemma":"émerveiller","pos":"VER"} ,
		{"word":"émerveillant","word_nosc":"emerveillant","lemma":"émerveiller","pos":"VER"} ,
		{"word":"émerveille","word_nosc":"emerveille","lemma":"émerveiller","pos":"VER"} ,
		{"word":"émerveillent","word_nosc":"emerveillent","lemma":"émerveiller","pos":"VER"} ,
		{"word":"émerveiller","word_nosc":"emerveiller","lemma":"émerveiller","pos":"VER"} ,
		{"word":"émerveillerait","word_nosc":"emerveillerait","lemma":"émerveiller","pos":"VER"} ,
		{"word":"émerveillèrent","word_nosc":"emerveillerent","lemma":"émerveiller","pos":"VER"} ,
		{"word":"émerveillé","word_nosc":"emerveille","lemma":"émerveiller","pos":"VER"} ,
		{"word":"émerveillée","word_nosc":"emerveillee","lemma":"émerveiller","pos":"VER"} ,
		{"word":"émerveillées","word_nosc":"emerveillees","lemma":"émerveiller","pos":"VER"} ,
		{"word":"émerveillés","word_nosc":"emerveilles","lemma":"émerveiller","pos":"VER"} ,
		{"word":"émet","word_nosc":"emet","lemma":"émettre","pos":"VER"} ,
		{"word":"émets","word_nosc":"emets","lemma":"émettre","pos":"VER"} ,
		{"word":"émettaient","word_nosc":"emettaient","lemma":"émettre","pos":"VER"} ,
		{"word":"émettait","word_nosc":"emettait","lemma":"émettre","pos":"VER"} ,
		{"word":"émettant","word_nosc":"emettant","lemma":"émettre","pos":"VER"} ,
		{"word":"émette","word_nosc":"emette","lemma":"émettre","pos":"VER"} ,
		{"word":"émettent","word_nosc":"emettent","lemma":"émettre","pos":"VER"} ,
		{"word":"émettez","word_nosc":"emettez","lemma":"émettre","pos":"VER"} ,
		{"word":"émettons","word_nosc":"emettons","lemma":"émettre","pos":"VER"} ,
		{"word":"émettra","word_nosc":"emettra","lemma":"émettre","pos":"VER"} ,
		{"word":"émettrai","word_nosc":"emettrai","lemma":"émettre","pos":"VER"} ,
		{"word":"émettre","word_nosc":"emettre","lemma":"émettre","pos":"VER"} ,
		{"word":"émettrons","word_nosc":"emettrons","lemma":"émettre","pos":"VER"} ,
		{"word":"émettront","word_nosc":"emettront","lemma":"émettre","pos":"VER"} ,
		{"word":"émeus","word_nosc":"emeus","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émeut","word_nosc":"emeut","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émeuve","word_nosc":"emeuve","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émeuvent","word_nosc":"emeuvent","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émietta","word_nosc":"emietta","lemma":"émietter","pos":"VER"} ,
		{"word":"émiettaient","word_nosc":"emiettaient","lemma":"émietter","pos":"VER"} ,
		{"word":"émiettait","word_nosc":"emiettait","lemma":"émietter","pos":"VER"} ,
		{"word":"émiette","word_nosc":"emiette","lemma":"émietter","pos":"VER"} ,
		{"word":"émiettent","word_nosc":"emiettent","lemma":"émietter","pos":"VER"} ,
		{"word":"émietter","word_nosc":"emietter","lemma":"émietter","pos":"VER"} ,
		{"word":"émietterait","word_nosc":"emietterait","lemma":"émietter","pos":"VER"} ,
		{"word":"émiettez","word_nosc":"emiettez","lemma":"émietter","pos":"VER"} ,
		{"word":"émiettèrent","word_nosc":"emietterent","lemma":"émietter","pos":"VER"} ,
		{"word":"émietté","word_nosc":"emiette","lemma":"émietter","pos":"VER"} ,
		{"word":"émiettée","word_nosc":"emiettee","lemma":"émietter","pos":"VER"} ,
		{"word":"émiettés","word_nosc":"emiettes","lemma":"émietter","pos":"VER"} ,
		{"word":"émigra","word_nosc":"emigra","lemma":"émigrer","pos":"VER"} ,
		{"word":"émigrai","word_nosc":"emigrai","lemma":"émigrer","pos":"VER"} ,
		{"word":"émigraient","word_nosc":"emigraient","lemma":"émigrer","pos":"VER"} ,
		{"word":"émigrais","word_nosc":"emigrais","lemma":"émigrer","pos":"VER"} ,
		{"word":"émigrait","word_nosc":"emigrait","lemma":"émigrer","pos":"VER"} ,
		{"word":"émigrant","word_nosc":"emigrant","lemma":"émigrer","pos":"VER"} ,
		{"word":"émigre","word_nosc":"emigre","lemma":"émigrer","pos":"VER"} ,
		{"word":"émigrent","word_nosc":"emigrent","lemma":"émigrer","pos":"VER"} ,
		{"word":"émigrer","word_nosc":"emigrer","lemma":"émigrer","pos":"VER"} ,
		{"word":"émigrèrent","word_nosc":"emigrerent","lemma":"émigrer","pos":"VER"} ,
		{"word":"émigré","word_nosc":"emigre","lemma":"émigrer","pos":"VER"} ,
		{"word":"émigrée","word_nosc":"emigree","lemma":"émigrer","pos":"VER"} ,
		{"word":"émigrés","word_nosc":"emigres","lemma":"émigrer","pos":"VER"} ,
		{"word":"émincer","word_nosc":"emincer","lemma":"émincer","pos":"VER"} ,
		{"word":"émincez","word_nosc":"emincez","lemma":"émincer","pos":"VER"} ,
		{"word":"émincé","word_nosc":"emince","lemma":"émincer","pos":"VER"} ,
		{"word":"émincée","word_nosc":"emincee","lemma":"émincer","pos":"VER"} ,
		{"word":"émincées","word_nosc":"emincees","lemma":"émincer","pos":"VER"} ,
		{"word":"émincés","word_nosc":"eminces","lemma":"émincer","pos":"VER"} ,
		{"word":"émis","word_nosc":"emis","lemma":"émettre","pos":"VER"} ,
		{"word":"émise","word_nosc":"emise","lemma":"émettre","pos":"VER"} ,
		{"word":"émises","word_nosc":"emises","lemma":"émettre","pos":"VER"} ,
		{"word":"émissions","word_nosc":"emissions","lemma":"émettre","pos":"VER"} ,
		{"word":"émit","word_nosc":"emit","lemma":"émettre","pos":"VER"} ,
		{"word":"émondait","word_nosc":"emondait","lemma":"émonder","pos":"VER"} ,
		{"word":"émonder","word_nosc":"emonder","lemma":"émonder","pos":"VER"} ,
		{"word":"émondera","word_nosc":"emondera","lemma":"émonder","pos":"VER"} ,
		{"word":"émondons","word_nosc":"emondons","lemma":"émonder","pos":"VER"} ,
		{"word":"émondé","word_nosc":"emonde","lemma":"émonder","pos":"VER"} ,
		{"word":"émondée","word_nosc":"emondee","lemma":"émonder","pos":"VER"} ,
		{"word":"émondées","word_nosc":"emondees","lemma":"émonder","pos":"VER"} ,
		{"word":"émotionne","word_nosc":"emotionne","lemma":"émotionner","pos":"VER"} ,
		{"word":"émotionné","word_nosc":"emotionne","lemma":"émotionner","pos":"VER"} ,
		{"word":"émoulu","word_nosc":"emoulu","lemma":"émoudre","pos":"VER"} ,
		{"word":"émoulue","word_nosc":"emoulue","lemma":"émoudre","pos":"VER"} ,
		{"word":"émoulus","word_nosc":"emoulus","lemma":"émoudre","pos":"VER"} ,
		{"word":"émoussa","word_nosc":"emoussa","lemma":"émousser","pos":"VER"} ,
		{"word":"émoussai","word_nosc":"emoussai","lemma":"émousser","pos":"VER"} ,
		{"word":"émoussaient","word_nosc":"emoussaient","lemma":"émousser","pos":"VER"} ,
		{"word":"émoussait","word_nosc":"emoussait","lemma":"émousser","pos":"VER"} ,
		{"word":"émoussant","word_nosc":"emoussant","lemma":"émousser","pos":"VER"} ,
		{"word":"émousse","word_nosc":"emousse","lemma":"émousser","pos":"VER"} ,
		{"word":"émoussent","word_nosc":"emoussent","lemma":"émousser","pos":"VER"} ,
		{"word":"émousser","word_nosc":"emousser","lemma":"émousser","pos":"VER"} ,
		{"word":"émousseront","word_nosc":"emousseront","lemma":"émousser","pos":"VER"} ,
		{"word":"émoussèrent","word_nosc":"emousserent","lemma":"émousser","pos":"VER"} ,
		{"word":"émoussé","word_nosc":"emousse","lemma":"émousser","pos":"VER"} ,
		{"word":"émoussée","word_nosc":"emoussee","lemma":"émousser","pos":"VER"} ,
		{"word":"émoussées","word_nosc":"emoussees","lemma":"émousser","pos":"VER"} ,
		{"word":"émoussés","word_nosc":"emousses","lemma":"émousser","pos":"VER"} ,
		{"word":"émoustilla","word_nosc":"emoustilla","lemma":"émoustiller","pos":"VER"} ,
		{"word":"émoustillait","word_nosc":"emoustillait","lemma":"émoustiller","pos":"VER"} ,
		{"word":"émoustillant","word_nosc":"emoustillant","lemma":"émoustiller","pos":"VER"} ,
		{"word":"émoustiller","word_nosc":"emoustiller","lemma":"émoustiller","pos":"VER"} ,
		{"word":"émoustillera","word_nosc":"emoustillera","lemma":"émoustiller","pos":"VER"} ,
		{"word":"émoustillé","word_nosc":"emoustille","lemma":"émoustiller","pos":"VER"} ,
		{"word":"émoustillée","word_nosc":"emoustillee","lemma":"émoustiller","pos":"VER"} ,
		{"word":"émoustillées","word_nosc":"emoustillees","lemma":"émoustiller","pos":"VER"} ,
		{"word":"émoustillés","word_nosc":"emoustilles","lemma":"émoustiller","pos":"VER"} ,
		{"word":"émouvaient","word_nosc":"emouvaient","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émouvais","word_nosc":"emouvais","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émouvait","word_nosc":"emouvait","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émouvant","word_nosc":"emouvant","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émouvez","word_nosc":"emouvez","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émouvoir","word_nosc":"emouvoir","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émouvrait","word_nosc":"emouvrait","lemma":"émouvoir","pos":"VER"} ,
		{"word":"ému","word_nosc":"emu","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émue","word_nosc":"emue","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émues","word_nosc":"emues","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émuler","word_nosc":"emuler","lemma":"émuler","pos":"VER"} ,
		{"word":"émulsifier","word_nosc":"emulsifier","lemma":"émulsifier","pos":"VER"} ,
		{"word":"émulsionné","word_nosc":"emulsionne","lemma":"émulsionner","pos":"VER"} ,
		{"word":"émurent","word_nosc":"emurent","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émus","word_nosc":"emus","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émussent","word_nosc":"emussent","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émut","word_nosc":"emut","lemma":"émouvoir","pos":"VER"} ,
		{"word":"émèchent","word_nosc":"emechent","lemma":"émécher","pos":"VER"} ,
		{"word":"éméché","word_nosc":"emeche","lemma":"émécher","pos":"VER"} ,
		{"word":"éméchée","word_nosc":"emechee","lemma":"émécher","pos":"VER"} ,
		{"word":"éméchées","word_nosc":"emechees","lemma":"émécher","pos":"VER"} ,
		{"word":"éméchés","word_nosc":"emeches","lemma":"émécher","pos":"VER"} ,
		{"word":"émût","word_nosc":"emut","lemma":"émouvoir","pos":"VER"} ,
		{"word":"énamourant","word_nosc":"enamourant","lemma":"énamourer","pos":"VER"} ,
		{"word":"énamouré","word_nosc":"enamoure","lemma":"énamourer","pos":"VER"} ,
		{"word":"énamourée","word_nosc":"enamouree","lemma":"énamourer","pos":"VER"} ,
		{"word":"énamourées","word_nosc":"enamourees","lemma":"énamourer","pos":"VER"} ,
		{"word":"énerva","word_nosc":"enerva","lemma":"énerver","pos":"VER"} ,
		{"word":"énervai","word_nosc":"enervai","lemma":"énerver","pos":"VER"} ,
		{"word":"énervaient","word_nosc":"enervaient","lemma":"énerver","pos":"VER"} ,
		{"word":"énervais","word_nosc":"enervais","lemma":"énerver","pos":"VER"} ,
		{"word":"énervait","word_nosc":"enervait","lemma":"énerver","pos":"VER"} ,
		{"word":"énervant","word_nosc":"enervant","lemma":"énerver","pos":"VER"} ,
		{"word":"énerve","word_nosc":"enerve","lemma":"énerver","pos":"VER"} ,
		{"word":"énervent","word_nosc":"enervent","lemma":"énerver","pos":"VER"} ,
		{"word":"énerver","word_nosc":"enerver","lemma":"énerver","pos":"VER"} ,
		{"word":"énervera","word_nosc":"enervera","lemma":"énerver","pos":"VER"} ,
		{"word":"énerverai","word_nosc":"enerverai","lemma":"énerver","pos":"VER"} ,
		{"word":"énerveraient","word_nosc":"enerveraient","lemma":"énerver","pos":"VER"} ,
		{"word":"énerverait","word_nosc":"enerverait","lemma":"énerver","pos":"VER"} ,
		{"word":"énerveront","word_nosc":"enerveront","lemma":"énerver","pos":"VER"} ,
		{"word":"énerves","word_nosc":"enerves","lemma":"énerver","pos":"VER"} ,
		{"word":"énervez","word_nosc":"enervez","lemma":"énerver","pos":"VER"} ,
		{"word":"énerviez","word_nosc":"enerviez","lemma":"énerver","pos":"VER"} ,
		{"word":"énervions","word_nosc":"enervions","lemma":"énerver","pos":"VER"} ,
		{"word":"énervons","word_nosc":"enervons","lemma":"énerver","pos":"VER"} ,
		{"word":"énervâmes","word_nosc":"enervames","lemma":"énerver","pos":"VER"} ,
		{"word":"énervé","word_nosc":"enerve","lemma":"énerver","pos":"VER"} ,
		{"word":"énervée","word_nosc":"enervee","lemma":"énerver","pos":"VER"} ,
		{"word":"énervées","word_nosc":"enervees","lemma":"énerver","pos":"VER"} ,
		{"word":"énervés","word_nosc":"enerves","lemma":"énerver","pos":"VER"} ,
		{"word":"énonce","word_nosc":"enonce","lemma":"énoncer","pos":"VER"} ,
		{"word":"énoncent","word_nosc":"enoncent","lemma":"énoncer","pos":"VER"} ,
		{"word":"énoncer","word_nosc":"enoncer","lemma":"énoncer","pos":"VER"} ,
		{"word":"énonceront","word_nosc":"enonceront","lemma":"énoncer","pos":"VER"} ,
		{"word":"énoncé","word_nosc":"enonce","lemma":"énoncer","pos":"VER"} ,
		{"word":"énoncée","word_nosc":"enoncee","lemma":"énoncer","pos":"VER"} ,
		{"word":"énoncées","word_nosc":"enoncees","lemma":"énoncer","pos":"VER"} ,
		{"word":"énonça","word_nosc":"enonca","lemma":"énoncer","pos":"VER"} ,
		{"word":"énonçai","word_nosc":"enoncai","lemma":"énoncer","pos":"VER"} ,
		{"word":"énonçaient","word_nosc":"enoncaient","lemma":"énoncer","pos":"VER"} ,
		{"word":"énonçais","word_nosc":"enoncais","lemma":"énoncer","pos":"VER"} ,
		{"word":"énonçait","word_nosc":"enoncait","lemma":"énoncer","pos":"VER"} ,
		{"word":"énonçant","word_nosc":"enoncant","lemma":"énoncer","pos":"VER"} ,
		{"word":"énucléer","word_nosc":"enucleer","lemma":"énucléer","pos":"VER"} ,
		{"word":"énucléé","word_nosc":"enuclee","lemma":"énucléer","pos":"VER"} ,
		{"word":"énucléés","word_nosc":"enuclees","lemma":"énucléer","pos":"VER"} ,
		{"word":"énumère","word_nosc":"enumere","lemma":"énumérer","pos":"VER"} ,
		{"word":"énumèrent","word_nosc":"enumerent","lemma":"énumérer","pos":"VER"} ,
		{"word":"énumères","word_nosc":"enumeres","lemma":"énumérer","pos":"VER"} ,
		{"word":"énuméra","word_nosc":"enumera","lemma":"énumérer","pos":"VER"} ,
		{"word":"énumérai","word_nosc":"enumerai","lemma":"énumérer","pos":"VER"} ,
		{"word":"énuméraient","word_nosc":"enumeraient","lemma":"énumérer","pos":"VER"} ,
		{"word":"énumérais","word_nosc":"enumerais","lemma":"énumérer","pos":"VER"} ,
		{"word":"énumérait","word_nosc":"enumerait","lemma":"énumérer","pos":"VER"} ,
		{"word":"énumérant","word_nosc":"enumerant","lemma":"énumérer","pos":"VER"} ,
		{"word":"énumérer","word_nosc":"enumerer","lemma":"énumérer","pos":"VER"} ,
		{"word":"énumérerai","word_nosc":"enumererai","lemma":"énumérer","pos":"VER"} ,
		{"word":"énumérerais","word_nosc":"enumererais","lemma":"énumérer","pos":"VER"} ,
		{"word":"énumérez","word_nosc":"enumerez","lemma":"énumérer","pos":"VER"} ,
		{"word":"énumérons","word_nosc":"enumerons","lemma":"énumérer","pos":"VER"} ,
		{"word":"énuméré","word_nosc":"enumere","lemma":"énumérer","pos":"VER"} ,
		{"word":"énumérées","word_nosc":"enumerees","lemma":"énumérer","pos":"VER"} ,
		{"word":"épaissi","word_nosc":"epaissi","lemma":"épaissir","pos":"VER"} ,
		{"word":"épaissie","word_nosc":"epaissie","lemma":"épaissir","pos":"VER"} ,
		{"word":"épaissies","word_nosc":"epaissies","lemma":"épaissir","pos":"VER"} ,
		{"word":"épaissir","word_nosc":"epaissir","lemma":"épaissir","pos":"VER"} ,
		{"word":"épaissirais","word_nosc":"epaissirais","lemma":"épaissir","pos":"VER"} ,
		{"word":"épaissirent","word_nosc":"epaissirent","lemma":"épaissir","pos":"VER"} ,
		{"word":"épaissis","word_nosc":"epaissis","lemma":"épaissir","pos":"VER"} ,
		{"word":"épaississaient","word_nosc":"epaississaient","lemma":"épaissir","pos":"VER"} ,
		{"word":"épaississait","word_nosc":"epaississait","lemma":"épaissir","pos":"VER"} ,
		{"word":"épaississant","word_nosc":"epaississant","lemma":"épaissir","pos":"VER"} ,
		{"word":"épaississe","word_nosc":"epaississe","lemma":"épaissir","pos":"VER"} ,
		{"word":"épaississent","word_nosc":"epaississent","lemma":"épaissir","pos":"VER"} ,
		{"word":"épaissit","word_nosc":"epaissit","lemma":"épaissir","pos":"VER"} ,
		{"word":"épancha","word_nosc":"epancha","lemma":"épancher","pos":"VER"} ,
		{"word":"épanchaient","word_nosc":"epanchaient","lemma":"épancher","pos":"VER"} ,
		{"word":"épanchais","word_nosc":"epanchais","lemma":"épancher","pos":"VER"} ,
		{"word":"épanchait","word_nosc":"epanchait","lemma":"épancher","pos":"VER"} ,
		{"word":"épanchant","word_nosc":"epanchant","lemma":"épancher","pos":"VER"} ,
		{"word":"épanche","word_nosc":"epanche","lemma":"épancher","pos":"VER"} ,
		{"word":"épanchent","word_nosc":"epanchent","lemma":"épancher","pos":"VER"} ,
		{"word":"épancher","word_nosc":"epancher","lemma":"épancher","pos":"VER"} ,
		{"word":"épancherait","word_nosc":"epancherait","lemma":"épancher","pos":"VER"} ,
		{"word":"épanché","word_nosc":"epanche","lemma":"épancher","pos":"VER"} ,
		{"word":"épand","word_nosc":"epand","lemma":"épandre","pos":"VER"} ,
		{"word":"épandaient","word_nosc":"epandaient","lemma":"épandre","pos":"VER"} ,
		{"word":"épandait","word_nosc":"epandait","lemma":"épandre","pos":"VER"} ,
		{"word":"épandant","word_nosc":"epandant","lemma":"épandre","pos":"VER"} ,
		{"word":"épandent","word_nosc":"epandent","lemma":"épandre","pos":"VER"} ,
		{"word":"épandez","word_nosc":"epandez","lemma":"épandre","pos":"VER"} ,
		{"word":"épandre","word_nosc":"epandre","lemma":"épandre","pos":"VER"} ,
		{"word":"épands","word_nosc":"epands","lemma":"épandre","pos":"VER"} ,
		{"word":"épandu","word_nosc":"epandu","lemma":"épandre","pos":"VER"} ,
		{"word":"épandue","word_nosc":"epandue","lemma":"épandre","pos":"VER"} ,
		{"word":"épandues","word_nosc":"epandues","lemma":"épandre","pos":"VER"} ,
		{"word":"épanoui","word_nosc":"epanoui","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouie","word_nosc":"epanouie","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouies","word_nosc":"epanouies","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouir","word_nosc":"epanouir","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouira","word_nosc":"epanouira","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouirait","word_nosc":"epanouirait","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouirent","word_nosc":"epanouirent","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouis","word_nosc":"epanouis","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouissaient","word_nosc":"epanouissaient","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouissais","word_nosc":"epanouissais","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouissait","word_nosc":"epanouissait","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouissant","word_nosc":"epanouissant","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouisse","word_nosc":"epanouisse","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouissent","word_nosc":"epanouissent","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouisses","word_nosc":"epanouisses","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouit","word_nosc":"epanouit","lemma":"épanouir","pos":"VER"} ,
		{"word":"épanouît","word_nosc":"epanouit","lemma":"épanouir","pos":"VER"} ,
		{"word":"épargna","word_nosc":"epargna","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnaient","word_nosc":"epargnaient","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnais","word_nosc":"epargnais","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnait","word_nosc":"epargnait","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnant","word_nosc":"epargnant","lemma":"épargner","pos":"VER"} ,
		{"word":"épargne","word_nosc":"epargne","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnent","word_nosc":"epargnent","lemma":"épargner","pos":"VER"} ,
		{"word":"épargner","word_nosc":"epargner","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnera","word_nosc":"epargnera","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnerai","word_nosc":"epargnerai","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnerais","word_nosc":"epargnerais","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnerait","word_nosc":"epargnerait","lemma":"épargner","pos":"VER"} ,
		{"word":"épargneras","word_nosc":"epargneras","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnerez","word_nosc":"epargnerez","lemma":"épargner","pos":"VER"} ,
		{"word":"épargneriez","word_nosc":"epargneriez","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnerons","word_nosc":"epargnerons","lemma":"épargner","pos":"VER"} ,
		{"word":"épargneront","word_nosc":"epargneront","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnes","word_nosc":"epargnes","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnez","word_nosc":"epargnez","lemma":"épargner","pos":"VER"} ,
		{"word":"épargniez","word_nosc":"epargniez","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnions","word_nosc":"epargnions","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnons","word_nosc":"epargnons","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnât","word_nosc":"epargnat","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnèrent","word_nosc":"epargnerent","lemma":"épargner","pos":"VER"} ,
		{"word":"épargné","word_nosc":"epargne","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnée","word_nosc":"epargnee","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnées","word_nosc":"epargnees","lemma":"épargner","pos":"VER"} ,
		{"word":"épargnés","word_nosc":"epargnes","lemma":"épargner","pos":"VER"} ,
		{"word":"éparpilla","word_nosc":"eparpilla","lemma":"éparpiller","pos":"VER"} ,
		{"word":"éparpillaient","word_nosc":"eparpillaient","lemma":"éparpiller","pos":"VER"} ,
		{"word":"éparpillait","word_nosc":"eparpillait","lemma":"éparpiller","pos":"VER"} ,
		{"word":"éparpillant","word_nosc":"eparpillant","lemma":"éparpiller","pos":"VER"} ,
		{"word":"éparpille","word_nosc":"eparpille","lemma":"éparpiller","pos":"VER"} ,
		{"word":"éparpillent","word_nosc":"eparpillent","lemma":"éparpiller","pos":"VER"} ,
		{"word":"éparpiller","word_nosc":"eparpiller","lemma":"éparpiller","pos":"VER"} ,
		{"word":"éparpillera","word_nosc":"eparpillera","lemma":"éparpiller","pos":"VER"} ,
		{"word":"éparpilleraient","word_nosc":"eparpilleraient","lemma":"éparpiller","pos":"VER"} ,
		{"word":"éparpillez","word_nosc":"eparpillez","lemma":"éparpiller","pos":"VER"} ,
		{"word":"éparpillons","word_nosc":"eparpillons","lemma":"éparpiller","pos":"VER"} ,
		{"word":"éparpillèrent","word_nosc":"eparpillerent","lemma":"éparpiller","pos":"VER"} ,
		{"word":"éparpillé","word_nosc":"eparpille","lemma":"éparpiller","pos":"VER"} ,
		{"word":"éparpillée","word_nosc":"eparpillee","lemma":"éparpiller","pos":"VER"} ,
		{"word":"éparpillées","word_nosc":"eparpillees","lemma":"éparpiller","pos":"VER"} ,
		{"word":"éparpillés","word_nosc":"eparpilles","lemma":"éparpiller","pos":"VER"} ,
		{"word":"épastrouillera","word_nosc":"epastrouillera","lemma":"épastrouiller","pos":"VER"} ,
		{"word":"épata","word_nosc":"epata","lemma":"épater","pos":"VER"} ,
		{"word":"épataient","word_nosc":"epataient","lemma":"épater","pos":"VER"} ,
		{"word":"épatais","word_nosc":"epatais","lemma":"épater","pos":"VER"} ,
		{"word":"épatait","word_nosc":"epatait","lemma":"épater","pos":"VER"} ,
		{"word":"épatant","word_nosc":"epatant","lemma":"épater","pos":"VER"} ,
		{"word":"épate","word_nosc":"epate","lemma":"épater","pos":"VER"} ,
		{"word":"épatent","word_nosc":"epatent","lemma":"épater","pos":"VER"} ,
		{"word":"épater","word_nosc":"epater","lemma":"épater","pos":"VER"} ,
		{"word":"épatera","word_nosc":"epatera","lemma":"épater","pos":"VER"} ,
		{"word":"épaterait","word_nosc":"epaterait","lemma":"épater","pos":"VER"} ,
		{"word":"épateras","word_nosc":"epateras","lemma":"épater","pos":"VER"} ,
		{"word":"épates","word_nosc":"epates","lemma":"épater","pos":"VER"} ,
		{"word":"épatez","word_nosc":"epatez","lemma":"épater","pos":"VER"} ,
		{"word":"épaté","word_nosc":"epate","lemma":"épater","pos":"VER"} ,
		{"word":"épatée","word_nosc":"epatee","lemma":"épater","pos":"VER"} ,
		{"word":"épatés","word_nosc":"epates","lemma":"épater","pos":"VER"} ,
		{"word":"épaula","word_nosc":"epaula","lemma":"épauler","pos":"VER"} ,
		{"word":"épaulaient","word_nosc":"epaulaient","lemma":"épauler","pos":"VER"} ,
		{"word":"épaulait","word_nosc":"epaulait","lemma":"épauler","pos":"VER"} ,
		{"word":"épaulant","word_nosc":"epaulant","lemma":"épauler","pos":"VER"} ,
		{"word":"épaule","word_nosc":"epaule","lemma":"épauler","pos":"VER"} ,
		{"word":"épaulent","word_nosc":"epaulent","lemma":"épauler","pos":"VER"} ,
		{"word":"épauler","word_nosc":"epauler","lemma":"épauler","pos":"VER"} ,
		{"word":"épauleras","word_nosc":"epauleras","lemma":"épauler","pos":"VER"} ,
		{"word":"épaules","word_nosc":"epaules","lemma":"épauler","pos":"VER"} ,
		{"word":"épaulez","word_nosc":"epaulez","lemma":"épauler","pos":"VER"} ,
		{"word":"épaulèrent","word_nosc":"epaulerent","lemma":"épauler","pos":"VER"} ,
		{"word":"épaulé","word_nosc":"epaule","lemma":"épauler","pos":"VER"} ,
		{"word":"épaulée","word_nosc":"epaulee","lemma":"épauler","pos":"VER"} ,
		{"word":"épaulées","word_nosc":"epaulees","lemma":"épauler","pos":"VER"} ,
		{"word":"épaulés","word_nosc":"epaules","lemma":"épauler","pos":"VER"} ,
		{"word":"épela","word_nosc":"epela","lemma":"épeler","pos":"VER"} ,
		{"word":"épelais","word_nosc":"epelais","lemma":"épeler","pos":"VER"} ,
		{"word":"épelait","word_nosc":"epelait","lemma":"épeler","pos":"VER"} ,
		{"word":"épelant","word_nosc":"epelant","lemma":"épeler","pos":"VER"} ,
		{"word":"épeler","word_nosc":"epeler","lemma":"épeler","pos":"VER"} ,
		{"word":"épelez","word_nosc":"epelez","lemma":"épeler","pos":"VER"} ,
		{"word":"épeliez","word_nosc":"epeliez","lemma":"épeler","pos":"VER"} ,
		{"word":"épelions","word_nosc":"epelions","lemma":"épeler","pos":"VER"} ,
		{"word":"épelle","word_nosc":"epelle","lemma":"épeler","pos":"VER"} ,
		{"word":"épellent","word_nosc":"epellent","lemma":"épeler","pos":"VER"} ,
		{"word":"épellerais","word_nosc":"epellerais","lemma":"épeler","pos":"VER"} ,
		{"word":"épelles","word_nosc":"epelles","lemma":"épeler","pos":"VER"} ,
		{"word":"épelé","word_nosc":"epele","lemma":"épeler","pos":"VER"} ,
		{"word":"épelés","word_nosc":"epeles","lemma":"épeler","pos":"VER"} ,
		{"word":"éperdu","word_nosc":"eperdu","lemma":"éperdre","pos":"VER"} ,
		{"word":"éperdue","word_nosc":"eperdue","lemma":"éperdre","pos":"VER"} ,
		{"word":"éperdues","word_nosc":"eperdues","lemma":"éperdre","pos":"VER"} ,
		{"word":"éperdus","word_nosc":"eperdus","lemma":"éperdre","pos":"VER"} ,
		{"word":"éperonna","word_nosc":"eperonna","lemma":"éperonner","pos":"VER"} ,
		{"word":"éperonnant","word_nosc":"eperonnant","lemma":"éperonner","pos":"VER"} ,
		{"word":"éperonne","word_nosc":"eperonne","lemma":"éperonner","pos":"VER"} ,
		{"word":"éperonner","word_nosc":"eperonner","lemma":"éperonner","pos":"VER"} ,
		{"word":"éperonnera","word_nosc":"eperonnera","lemma":"éperonner","pos":"VER"} ,
		{"word":"éperonnez","word_nosc":"eperonnez","lemma":"éperonner","pos":"VER"} ,
		{"word":"éperonnèrent","word_nosc":"eperonnerent","lemma":"éperonner","pos":"VER"} ,
		{"word":"éperonné","word_nosc":"eperonne","lemma":"éperonner","pos":"VER"} ,
		{"word":"éperonnée","word_nosc":"eperonnee","lemma":"éperonner","pos":"VER"} ,
		{"word":"éperonnés","word_nosc":"eperonnes","lemma":"éperonner","pos":"VER"} ,
		{"word":"épeurant","word_nosc":"epeurant","lemma":"épeurer","pos":"VER"} ,
		{"word":"épeurer","word_nosc":"epeurer","lemma":"épeurer","pos":"VER"} ,
		{"word":"épeuré","word_nosc":"epeure","lemma":"épeurer","pos":"VER"} ,
		{"word":"épeurée","word_nosc":"epeuree","lemma":"épeurer","pos":"VER"} ,
		{"word":"épia","word_nosc":"epia","lemma":"épier","pos":"VER"} ,
		{"word":"épiai","word_nosc":"epiai","lemma":"épier","pos":"VER"} ,
		{"word":"épiaient","word_nosc":"epiaient","lemma":"épier","pos":"VER"} ,
		{"word":"épiais","word_nosc":"epiais","lemma":"épier","pos":"VER"} ,
		{"word":"épiait","word_nosc":"epiait","lemma":"épier","pos":"VER"} ,
		{"word":"épiant","word_nosc":"epiant","lemma":"épier","pos":"VER"} ,
		{"word":"épice","word_nosc":"epice","lemma":"épicer","pos":"VER"} ,
		{"word":"épicer","word_nosc":"epicer","lemma":"épicer","pos":"VER"} ,
		{"word":"épices","word_nosc":"epices","lemma":"épicer","pos":"VER"} ,
		{"word":"épicé","word_nosc":"epice","lemma":"épicer","pos":"VER"} ,
		{"word":"épicée","word_nosc":"epicee","lemma":"épicer","pos":"VER"} ,
		{"word":"épicés","word_nosc":"epices","lemma":"épicer","pos":"VER"} ,
		{"word":"épie","word_nosc":"epie","lemma":"épier","pos":"VER"} ,
		{"word":"épient","word_nosc":"epient","lemma":"épier","pos":"VER"} ,
		{"word":"épier","word_nosc":"epier","lemma":"épier","pos":"VER"} ,
		{"word":"épierais","word_nosc":"epierais","lemma":"épier","pos":"VER"} ,
		{"word":"épierait","word_nosc":"epierait","lemma":"épier","pos":"VER"} ,
		{"word":"épierrer","word_nosc":"epierrer","lemma":"épierrer","pos":"VER"} ,
		{"word":"épies","word_nosc":"epies","lemma":"épier","pos":"VER"} ,
		{"word":"épiez","word_nosc":"epiez","lemma":"épier","pos":"VER"} ,
		{"word":"épilais","word_nosc":"epilais","lemma":"épiler","pos":"VER"} ,
		{"word":"épilait","word_nosc":"epilait","lemma":"épiler","pos":"VER"} ,
		{"word":"épilant","word_nosc":"epilant","lemma":"épiler","pos":"VER"} ,
		{"word":"épile","word_nosc":"epile","lemma":"épiler","pos":"VER"} ,
		{"word":"épilent","word_nosc":"epilent","lemma":"épiler","pos":"VER"} ,
		{"word":"épiler","word_nosc":"epiler","lemma":"épiler","pos":"VER"} ,
		{"word":"épilez","word_nosc":"epilez","lemma":"épiler","pos":"VER"} ,
		{"word":"épiloguaient","word_nosc":"epiloguaient","lemma":"épiloguer","pos":"VER"} ,
		{"word":"épiloguer","word_nosc":"epiloguer","lemma":"épiloguer","pos":"VER"} ,
		{"word":"épiloguions","word_nosc":"epiloguions","lemma":"épiloguer","pos":"VER"} ,
		{"word":"épiloguèrent","word_nosc":"epiloguerent","lemma":"épiloguer","pos":"VER"} ,
		{"word":"épilogué","word_nosc":"epilogue","lemma":"épiloguer","pos":"VER"} ,
		{"word":"épilé","word_nosc":"epile","lemma":"épiler","pos":"VER"} ,
		{"word":"épilée","word_nosc":"epilee","lemma":"épiler","pos":"VER"} ,
		{"word":"épilées","word_nosc":"epilees","lemma":"épiler","pos":"VER"} ,
		{"word":"épilés","word_nosc":"epiles","lemma":"épiler","pos":"VER"} ,
		{"word":"épingla","word_nosc":"epingla","lemma":"épingler","pos":"VER"} ,
		{"word":"épinglai","word_nosc":"epinglai","lemma":"épingler","pos":"VER"} ,
		{"word":"épinglaient","word_nosc":"epinglaient","lemma":"épingler","pos":"VER"} ,
		{"word":"épinglait","word_nosc":"epinglait","lemma":"épingler","pos":"VER"} ,
		{"word":"épingle","word_nosc":"epingle","lemma":"épingler","pos":"VER"} ,
		{"word":"épinglent","word_nosc":"epinglent","lemma":"épingler","pos":"VER"} ,
		{"word":"épingler","word_nosc":"epingler","lemma":"épingler","pos":"VER"} ,
		{"word":"épinglera","word_nosc":"epinglera","lemma":"épingler","pos":"VER"} ,
		{"word":"épinglerai","word_nosc":"epinglerai","lemma":"épingler","pos":"VER"} ,
		{"word":"épingleront","word_nosc":"epingleront","lemma":"épingler","pos":"VER"} ,
		{"word":"épingles","word_nosc":"epingles","lemma":"épingler","pos":"VER"} ,
		{"word":"épinglez","word_nosc":"epinglez","lemma":"épingler","pos":"VER"} ,
		{"word":"épinglons","word_nosc":"epinglons","lemma":"épingler","pos":"VER"} ,
		{"word":"épinglèrent","word_nosc":"epinglerent","lemma":"épingler","pos":"VER"} ,
		{"word":"épinglé","word_nosc":"epingle","lemma":"épingler","pos":"VER"} ,
		{"word":"épinglée","word_nosc":"epinglee","lemma":"épingler","pos":"VER"} ,
		{"word":"épinglées","word_nosc":"epinglees","lemma":"épingler","pos":"VER"} ,
		{"word":"épinglés","word_nosc":"epingles","lemma":"épingler","pos":"VER"} ,
		{"word":"épions","word_nosc":"epions","lemma":"épier","pos":"VER"} ,
		{"word":"épisser","word_nosc":"episser","lemma":"épisser","pos":"VER"} ,
		{"word":"épissé","word_nosc":"episse","lemma":"épisser","pos":"VER"} ,
		{"word":"épièrent","word_nosc":"epierent","lemma":"épier","pos":"VER"} ,
		{"word":"épié","word_nosc":"epie","lemma":"épier","pos":"VER"} ,
		{"word":"épiée","word_nosc":"epiee","lemma":"épier","pos":"VER"} ,
		{"word":"épiées","word_nosc":"epiees","lemma":"épier","pos":"VER"} ,
		{"word":"épiés","word_nosc":"epies","lemma":"épier","pos":"VER"} ,
		{"word":"éploie","word_nosc":"eploie","lemma":"éployer","pos":"VER"} ,
		{"word":"éploient","word_nosc":"eploient","lemma":"éployer","pos":"VER"} ,
		{"word":"éployai","word_nosc":"eployai","lemma":"éployer","pos":"VER"} ,
		{"word":"éployait","word_nosc":"eployait","lemma":"éployer","pos":"VER"} ,
		{"word":"éployer","word_nosc":"eployer","lemma":"éployer","pos":"VER"} ,
		{"word":"éployé","word_nosc":"eploye","lemma":"éployer","pos":"VER"} ,
		{"word":"éployée","word_nosc":"eployee","lemma":"éployer","pos":"VER"} ,
		{"word":"éployées","word_nosc":"eployees","lemma":"éployer","pos":"VER"} ,
		{"word":"éployés","word_nosc":"eployes","lemma":"éployer","pos":"VER"} ,
		{"word":"éplucha","word_nosc":"eplucha","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluchaient","word_nosc":"epluchaient","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluchais","word_nosc":"epluchais","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluchait","word_nosc":"epluchait","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluchant","word_nosc":"epluchant","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluche","word_nosc":"epluche","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluchent","word_nosc":"epluchent","lemma":"éplucher","pos":"VER"} ,
		{"word":"éplucher","word_nosc":"eplucher","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluchera","word_nosc":"epluchera","lemma":"éplucher","pos":"VER"} ,
		{"word":"éplucherai","word_nosc":"eplucherai","lemma":"éplucher","pos":"VER"} ,
		{"word":"éplucherons","word_nosc":"eplucherons","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluches","word_nosc":"epluches","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluchez","word_nosc":"epluchez","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluchions","word_nosc":"epluchions","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluchons","word_nosc":"epluchons","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluchât","word_nosc":"epluchat","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluché","word_nosc":"epluche","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluchée","word_nosc":"epluchee","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluchées","word_nosc":"epluchees","lemma":"éplucher","pos":"VER"} ,
		{"word":"épluchés","word_nosc":"epluches","lemma":"éplucher","pos":"VER"} ,
		{"word":"épointé","word_nosc":"epointe","lemma":"épointer","pos":"VER"} ,
		{"word":"épointées","word_nosc":"epointees","lemma":"épointer","pos":"VER"} ,
		{"word":"épointés","word_nosc":"epointes","lemma":"épointer","pos":"VER"} ,
		{"word":"éponge","word_nosc":"eponge","lemma":"éponger","pos":"VER"} ,
		{"word":"épongea","word_nosc":"epongea","lemma":"éponger","pos":"VER"} ,
		{"word":"épongeaient","word_nosc":"epongeaient","lemma":"éponger","pos":"VER"} ,
		{"word":"épongeais","word_nosc":"epongeais","lemma":"éponger","pos":"VER"} ,
		{"word":"épongeait","word_nosc":"epongeait","lemma":"éponger","pos":"VER"} ,
		{"word":"épongeant","word_nosc":"epongeant","lemma":"éponger","pos":"VER"} ,
		{"word":"épongent","word_nosc":"epongent","lemma":"éponger","pos":"VER"} ,
		{"word":"épongeons","word_nosc":"epongeons","lemma":"éponger","pos":"VER"} ,
		{"word":"éponger","word_nosc":"eponger","lemma":"éponger","pos":"VER"} ,
		{"word":"éponges","word_nosc":"eponges","lemma":"éponger","pos":"VER"} ,
		{"word":"épongez","word_nosc":"epongez","lemma":"éponger","pos":"VER"} ,
		{"word":"épongiez","word_nosc":"epongiez","lemma":"éponger","pos":"VER"} ,
		{"word":"épongèrent","word_nosc":"epongerent","lemma":"éponger","pos":"VER"} ,
		{"word":"épongé","word_nosc":"eponge","lemma":"éponger","pos":"VER"} ,
		{"word":"épongée","word_nosc":"epongee","lemma":"éponger","pos":"VER"} ,
		{"word":"épongés","word_nosc":"eponges","lemma":"éponger","pos":"VER"} ,
		{"word":"épouillaient","word_nosc":"epouillaient","lemma":"épouiller","pos":"VER"} ,
		{"word":"épouillait","word_nosc":"epouillait","lemma":"épouiller","pos":"VER"} ,
		{"word":"épouille","word_nosc":"epouille","lemma":"épouiller","pos":"VER"} ,
		{"word":"épouillent","word_nosc":"epouillent","lemma":"épouiller","pos":"VER"} ,
		{"word":"épouiller","word_nosc":"epouiller","lemma":"épouiller","pos":"VER"} ,
		{"word":"épouillé","word_nosc":"epouille","lemma":"épouiller","pos":"VER"} ,
		{"word":"épouillés","word_nosc":"epouilles","lemma":"épouiller","pos":"VER"} ,
		{"word":"époumonaient","word_nosc":"epoumonaient","lemma":"époumoner","pos":"VER"} ,
		{"word":"époumonait","word_nosc":"epoumonait","lemma":"époumoner","pos":"VER"} ,
		{"word":"époumonant","word_nosc":"epoumonant","lemma":"époumoner","pos":"VER"} ,
		{"word":"époumone","word_nosc":"epoumone","lemma":"époumoner","pos":"VER"} ,
		{"word":"époumoner","word_nosc":"epoumoner","lemma":"époumoner","pos":"VER"} ,
		{"word":"époumonèrent","word_nosc":"epoumonerent","lemma":"époumoner","pos":"VER"} ,
		{"word":"époumonés","word_nosc":"epoumones","lemma":"époumoner","pos":"VER"} ,
		{"word":"épousa","word_nosc":"epousa","lemma":"épouser","pos":"VER"} ,
		{"word":"épousai","word_nosc":"epousai","lemma":"épouser","pos":"VER"} ,
		{"word":"épousaient","word_nosc":"epousaient","lemma":"épouser","pos":"VER"} ,
		{"word":"épousais","word_nosc":"epousais","lemma":"épouser","pos":"VER"} ,
		{"word":"épousait","word_nosc":"epousait","lemma":"épouser","pos":"VER"} ,
		{"word":"épousant","word_nosc":"epousant","lemma":"épouser","pos":"VER"} ,
		{"word":"épouse","word_nosc":"epouse","lemma":"épouser","pos":"VER"} ,
		{"word":"épousent","word_nosc":"epousent","lemma":"épouser","pos":"VER"} ,
		{"word":"épouser","word_nosc":"epouser","lemma":"épouser","pos":"VER"} ,
		{"word":"épousera","word_nosc":"epousera","lemma":"épouser","pos":"VER"} ,
		{"word":"épouserai","word_nosc":"epouserai","lemma":"épouser","pos":"VER"} ,
		{"word":"épouseraient","word_nosc":"epouseraient","lemma":"épouser","pos":"VER"} ,
		{"word":"épouserais","word_nosc":"epouserais","lemma":"épouser","pos":"VER"} ,
		{"word":"épouserait","word_nosc":"epouserait","lemma":"épouser","pos":"VER"} ,
		{"word":"épouseras","word_nosc":"epouseras","lemma":"épouser","pos":"VER"} ,
		{"word":"épouserez","word_nosc":"epouserez","lemma":"épouser","pos":"VER"} ,
		{"word":"épouseriez","word_nosc":"epouseriez","lemma":"épouser","pos":"VER"} ,
		{"word":"épouserons","word_nosc":"epouserons","lemma":"épouser","pos":"VER"} ,
		{"word":"épouseront","word_nosc":"epouseront","lemma":"épouser","pos":"VER"} ,
		{"word":"épouses","word_nosc":"epouses","lemma":"épouser","pos":"VER"} ,
		{"word":"épousez","word_nosc":"epousez","lemma":"épouser","pos":"VER"} ,
		{"word":"épousiez","word_nosc":"epousiez","lemma":"épouser","pos":"VER"} ,
		{"word":"épousions","word_nosc":"epousions","lemma":"épouser","pos":"VER"} ,
		{"word":"épousons","word_nosc":"epousons","lemma":"épouser","pos":"VER"} ,
		{"word":"épousseta","word_nosc":"epousseta","lemma":"épousseter","pos":"VER"} ,
		{"word":"époussetaient","word_nosc":"epoussetaient","lemma":"épousseter","pos":"VER"} ,
		{"word":"époussetais","word_nosc":"epoussetais","lemma":"épousseter","pos":"VER"} ,
		{"word":"époussetait","word_nosc":"epoussetait","lemma":"épousseter","pos":"VER"} ,
		{"word":"époussetant","word_nosc":"epoussetant","lemma":"épousseter","pos":"VER"} ,
		{"word":"épousseter","word_nosc":"epousseter","lemma":"épousseter","pos":"VER"} ,
		{"word":"époussetez","word_nosc":"epoussetez","lemma":"épousseter","pos":"VER"} ,
		{"word":"époussette","word_nosc":"epoussette","lemma":"épousseter","pos":"VER"} ,
		{"word":"époussettent","word_nosc":"epoussettent","lemma":"épousseter","pos":"VER"} ,
		{"word":"époussetèrent","word_nosc":"epousseterent","lemma":"épousseter","pos":"VER"} ,
		{"word":"épousseté","word_nosc":"epoussete","lemma":"épousseter","pos":"VER"} ,
		{"word":"époussetée","word_nosc":"epoussetee","lemma":"épousseter","pos":"VER"} ,
		{"word":"époussetés","word_nosc":"epoussetes","lemma":"épousseter","pos":"VER"} ,
		{"word":"époustouflait","word_nosc":"epoustouflait","lemma":"époustoufler","pos":"VER"} ,
		{"word":"époustouflant","word_nosc":"epoustouflant","lemma":"époustoufler","pos":"VER"} ,
		{"word":"époustoufle","word_nosc":"epoustoufle","lemma":"époustoufler","pos":"VER"} ,
		{"word":"époustoufler","word_nosc":"epoustoufler","lemma":"époustoufler","pos":"VER"} ,
		{"word":"époustouflé","word_nosc":"epoustoufle","lemma":"époustoufler","pos":"VER"} ,
		{"word":"époustouflée","word_nosc":"epoustouflee","lemma":"époustoufler","pos":"VER"} ,
		{"word":"époustouflés","word_nosc":"epoustoufles","lemma":"époustoufler","pos":"VER"} ,
		{"word":"épousât","word_nosc":"epousat","lemma":"épouser","pos":"VER"} ,
		{"word":"épousèrent","word_nosc":"epouserent","lemma":"épouser","pos":"VER"} ,
		{"word":"épousé","word_nosc":"epouse","lemma":"épouser","pos":"VER"} ,
		{"word":"épousée","word_nosc":"epousee","lemma":"épouser","pos":"VER"} ,
		{"word":"épousées","word_nosc":"epousees","lemma":"épouser","pos":"VER"} ,
		{"word":"épousés","word_nosc":"epouses","lemma":"épouser","pos":"VER"} ,
		{"word":"épouvanta","word_nosc":"epouvanta","lemma":"épouvanter","pos":"VER"} ,
		{"word":"épouvantaient","word_nosc":"epouvantaient","lemma":"épouvanter","pos":"VER"} ,
		{"word":"épouvantait","word_nosc":"epouvantait","lemma":"épouvanter","pos":"VER"} ,
		{"word":"épouvante","word_nosc":"epouvante","lemma":"épouvanter","pos":"VER"} ,
		{"word":"épouvantent","word_nosc":"epouvantent","lemma":"épouvanter","pos":"VER"} ,
		{"word":"épouvanter","word_nosc":"epouvanter","lemma":"épouvanter","pos":"VER"} ,
		{"word":"épouvanteraient","word_nosc":"epouvanteraient","lemma":"épouvanter","pos":"VER"} ,
		{"word":"épouvantes","word_nosc":"epouvantes","lemma":"épouvanter","pos":"VER"} ,
		{"word":"épouvantez","word_nosc":"epouvantez","lemma":"épouvanter","pos":"VER"} ,
		{"word":"épouvantèrent","word_nosc":"epouvanterent","lemma":"épouvanter","pos":"VER"} ,
		{"word":"épouvanté","word_nosc":"epouvante","lemma":"épouvanter","pos":"VER"} ,
		{"word":"épouvantée","word_nosc":"epouvantee","lemma":"épouvanter","pos":"VER"} ,
		{"word":"épouvantées","word_nosc":"epouvantees","lemma":"épouvanter","pos":"VER"} ,
		{"word":"épouvantés","word_nosc":"epouvantes","lemma":"épouvanter","pos":"VER"} ,
		{"word":"éprenait","word_nosc":"eprenait","lemma":"éprendre","pos":"VER"} ,
		{"word":"éprenant","word_nosc":"eprenant","lemma":"éprendre","pos":"VER"} ,
		{"word":"éprend","word_nosc":"eprend","lemma":"éprendre","pos":"VER"} ,
		{"word":"éprendre","word_nosc":"eprendre","lemma":"éprendre","pos":"VER"} ,
		{"word":"éprends","word_nosc":"eprends","lemma":"éprendre","pos":"VER"} ,
		{"word":"éprenne","word_nosc":"eprenne","lemma":"éprendre","pos":"VER"} ,
		{"word":"éprennent","word_nosc":"eprennent","lemma":"éprendre","pos":"VER"} ,
		{"word":"épris","word_nosc":"epris","lemma":"éprendre","pos":"VER"} ,
		{"word":"éprise","word_nosc":"eprise","lemma":"éprendre","pos":"VER"} ,
		{"word":"éprises","word_nosc":"eprises","lemma":"éprendre","pos":"VER"} ,
		{"word":"éprit","word_nosc":"eprit","lemma":"éprendre","pos":"VER"} ,
		{"word":"éprouva","word_nosc":"eprouva","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvai","word_nosc":"eprouvai","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvaient","word_nosc":"eprouvaient","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvais","word_nosc":"eprouvais","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvait","word_nosc":"eprouvait","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvant","word_nosc":"eprouvant","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouve","word_nosc":"eprouve","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvent","word_nosc":"eprouvent","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouver","word_nosc":"eprouver","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvera","word_nosc":"eprouvera","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouverai","word_nosc":"eprouverai","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouveraient","word_nosc":"eprouveraient","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouverais","word_nosc":"eprouverais","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouverait","word_nosc":"eprouverait","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouveras","word_nosc":"eprouveras","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouverez","word_nosc":"eprouverez","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouveront","word_nosc":"eprouveront","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouves","word_nosc":"eprouves","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvez","word_nosc":"eprouvez","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouviez","word_nosc":"eprouviez","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvions","word_nosc":"eprouvions","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvons","word_nosc":"eprouvons","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvâmes","word_nosc":"eprouvames","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvât","word_nosc":"eprouvat","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvèrent","word_nosc":"eprouverent","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvé","word_nosc":"eprouve","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvée","word_nosc":"eprouvee","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvées","word_nosc":"eprouvees","lemma":"éprouver","pos":"VER"} ,
		{"word":"éprouvés","word_nosc":"eprouves","lemma":"éprouver","pos":"VER"} ,
		{"word":"épucer","word_nosc":"epucer","lemma":"épucer","pos":"VER"} ,
		{"word":"épuisa","word_nosc":"epuisa","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisai","word_nosc":"epuisai","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisaient","word_nosc":"epuisaient","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisais","word_nosc":"epuisais","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisait","word_nosc":"epuisait","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisant","word_nosc":"epuisant","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuise","word_nosc":"epuise","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisent","word_nosc":"epuisent","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuiser","word_nosc":"epuiser","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisera","word_nosc":"epuisera","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuiseraient","word_nosc":"epuiseraient","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuiserait","word_nosc":"epuiserait","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuiseras","word_nosc":"epuiseras","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuiserons","word_nosc":"epuiserons","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuiseront","word_nosc":"epuiseront","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuises","word_nosc":"epuises","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisez","word_nosc":"epuisez","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisiez","word_nosc":"epuisiez","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisons","word_nosc":"epuisons","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisât","word_nosc":"epuisat","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisèrent","word_nosc":"epuiserent","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisé","word_nosc":"epuise","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisée","word_nosc":"epuisee","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisées","word_nosc":"epuisees","lemma":"épuiser","pos":"VER"} ,
		{"word":"épuisés","word_nosc":"epuises","lemma":"épuiser","pos":"VER"} ,
		{"word":"épurait","word_nosc":"epurait","lemma":"épurer","pos":"VER"} ,
		{"word":"épurant","word_nosc":"epurant","lemma":"épurer","pos":"VER"} ,
		{"word":"épure","word_nosc":"epure","lemma":"épurer","pos":"VER"} ,
		{"word":"épurer","word_nosc":"epurer","lemma":"épurer","pos":"VER"} ,
		{"word":"épuré","word_nosc":"epure","lemma":"épurer","pos":"VER"} ,
		{"word":"épurée","word_nosc":"epuree","lemma":"épurer","pos":"VER"} ,
		{"word":"épurées","word_nosc":"epurees","lemma":"épurer","pos":"VER"} ,
		{"word":"épurés","word_nosc":"epures","lemma":"épurer","pos":"VER"} ,
		{"word":"épuçât","word_nosc":"epucat","lemma":"épucer","pos":"VER"} ,
		{"word":"épépine","word_nosc":"epepine","lemma":"épépiner","pos":"VER"} ,
		{"word":"équarri","word_nosc":"equarri","lemma":"équarrir","pos":"VER"} ,
		{"word":"équarries","word_nosc":"equarries","lemma":"équarrir","pos":"VER"} ,
		{"word":"équarrir","word_nosc":"equarrir","lemma":"équarrir","pos":"VER"} ,
		{"word":"équarris","word_nosc":"equarris","lemma":"équarrir","pos":"VER"} ,
		{"word":"équarrissait","word_nosc":"equarrissait","lemma":"équarrir","pos":"VER"} ,
		{"word":"équeuter","word_nosc":"equeuter","lemma":"équeuter","pos":"VER"} ,
		{"word":"équilibra","word_nosc":"equilibra","lemma":"équilibrer","pos":"VER"} ,
		{"word":"équilibraient","word_nosc":"equilibraient","lemma":"équilibrer","pos":"VER"} ,
		{"word":"équilibrais","word_nosc":"equilibrais","lemma":"équilibrer","pos":"VER"} ,
		{"word":"équilibrait","word_nosc":"equilibrait","lemma":"équilibrer","pos":"VER"} ,
		{"word":"équilibrant","word_nosc":"equilibrant","lemma":"équilibrer","pos":"VER"} ,
		{"word":"équilibre","word_nosc":"equilibre","lemma":"équilibrer","pos":"VER"} ,
		{"word":"équilibrent","word_nosc":"equilibrent","lemma":"équilibrer","pos":"VER"} ,
		{"word":"équilibrer","word_nosc":"equilibrer","lemma":"équilibrer","pos":"VER"} ,
		{"word":"équilibrèrent","word_nosc":"equilibrerent","lemma":"équilibrer","pos":"VER"} ,
		{"word":"équilibré","word_nosc":"equilibre","lemma":"équilibrer","pos":"VER"} ,
		{"word":"équilibrée","word_nosc":"equilibree","lemma":"équilibrer","pos":"VER"} ,
		{"word":"équilibrées","word_nosc":"equilibrees","lemma":"équilibrer","pos":"VER"} ,
		{"word":"équilibrés","word_nosc":"equilibres","lemma":"équilibrer","pos":"VER"} ,
		{"word":"équipa","word_nosc":"equipa","lemma":"équiper","pos":"VER"} ,
		{"word":"équipai","word_nosc":"equipai","lemma":"équiper","pos":"VER"} ,
		{"word":"équipait","word_nosc":"equipait","lemma":"équiper","pos":"VER"} ,
		{"word":"équipant","word_nosc":"equipant","lemma":"équiper","pos":"VER"} ,
		{"word":"équipe","word_nosc":"equipe","lemma":"équiper","pos":"VER"} ,
		{"word":"équipent","word_nosc":"equipent","lemma":"équiper","pos":"VER"} ,
		{"word":"équiper","word_nosc":"equiper","lemma":"équiper","pos":"VER"} ,
		{"word":"équipera","word_nosc":"equipera","lemma":"équiper","pos":"VER"} ,
		{"word":"équiperai","word_nosc":"equiperai","lemma":"équiper","pos":"VER"} ,
		{"word":"équiperais","word_nosc":"equiperais","lemma":"équiper","pos":"VER"} ,
		{"word":"équipes","word_nosc":"equipes","lemma":"équiper","pos":"VER"} ,
		{"word":"équipez","word_nosc":"equipez","lemma":"équiper","pos":"VER"} ,
		{"word":"équipons","word_nosc":"equipons","lemma":"équiper","pos":"VER"} ,
		{"word":"équipèrent","word_nosc":"equiperent","lemma":"équiper","pos":"VER"} ,
		{"word":"équipé","word_nosc":"equipe","lemma":"équiper","pos":"VER"} ,
		{"word":"équipée","word_nosc":"equipee","lemma":"équiper","pos":"VER"} ,
		{"word":"équipées","word_nosc":"equipees","lemma":"équiper","pos":"VER"} ,
		{"word":"équipés","word_nosc":"equipes","lemma":"équiper","pos":"VER"} ,
		{"word":"équivalaient","word_nosc":"equivalaient","lemma":"équivaloir","pos":"VER"} ,
		{"word":"équivalait","word_nosc":"equivalait","lemma":"équivaloir","pos":"VER"} ,
		{"word":"équivalant","word_nosc":"equivalant","lemma":"équivaloir","pos":"VER"} ,
		{"word":"équivalent","word_nosc":"equivalent","lemma":"équivaloir","pos":"VER"} ,
		{"word":"équivaloir","word_nosc":"equivaloir","lemma":"équivaloir","pos":"VER"} ,
		{"word":"équivalu","word_nosc":"equivalu","lemma":"équivaloir","pos":"VER"} ,
		{"word":"équivalût","word_nosc":"equivalut","lemma":"équivaloir","pos":"VER"} ,
		{"word":"équivaudra","word_nosc":"equivaudra","lemma":"équivaloir","pos":"VER"} ,
		{"word":"équivaudrait","word_nosc":"equivaudrait","lemma":"équivaloir","pos":"VER"} ,
		{"word":"équivaut","word_nosc":"equivaut","lemma":"équivaloir","pos":"VER"} ,
		{"word":"équivoquer","word_nosc":"equivoquer","lemma":"équivoquer","pos":"VER"} ,
		{"word":"éradiquer","word_nosc":"eradiquer","lemma":"éradiquer","pos":"VER"} ,
		{"word":"éradiquerai","word_nosc":"eradiquerai","lemma":"éradiquer","pos":"VER"} ,
		{"word":"éradiqueront","word_nosc":"eradiqueront","lemma":"éradiquer","pos":"VER"} ,
		{"word":"éradiquons","word_nosc":"eradiquons","lemma":"éradiquer","pos":"VER"} ,
		{"word":"éradiqué","word_nosc":"eradique","lemma":"éradiquer","pos":"VER"} ,
		{"word":"éradiquée","word_nosc":"eradiquee","lemma":"éradiquer","pos":"VER"} ,
		{"word":"érafla","word_nosc":"erafla","lemma":"érafler","pos":"VER"} ,
		{"word":"éraflait","word_nosc":"eraflait","lemma":"érafler","pos":"VER"} ,
		{"word":"érafle","word_nosc":"erafle","lemma":"érafler","pos":"VER"} ,
		{"word":"érafler","word_nosc":"erafler","lemma":"érafler","pos":"VER"} ,
		{"word":"éraflez","word_nosc":"eraflez","lemma":"érafler","pos":"VER"} ,
		{"word":"éraflé","word_nosc":"erafle","lemma":"érafler","pos":"VER"} ,
		{"word":"éraflée","word_nosc":"eraflee","lemma":"érafler","pos":"VER"} ,
		{"word":"éraflées","word_nosc":"eraflees","lemma":"érafler","pos":"VER"} ,
		{"word":"éraflés","word_nosc":"erafles","lemma":"érafler","pos":"VER"} ,
		{"word":"érailla","word_nosc":"erailla","lemma":"érailler","pos":"VER"} ,
		{"word":"éraillaient","word_nosc":"eraillaient","lemma":"érailler","pos":"VER"} ,
		{"word":"éraillait","word_nosc":"eraillait","lemma":"érailler","pos":"VER"} ,
		{"word":"éraillant","word_nosc":"eraillant","lemma":"érailler","pos":"VER"} ,
		{"word":"éraille","word_nosc":"eraille","lemma":"érailler","pos":"VER"} ,
		{"word":"éraillent","word_nosc":"eraillent","lemma":"érailler","pos":"VER"} ,
		{"word":"érailler","word_nosc":"erailler","lemma":"érailler","pos":"VER"} ,
		{"word":"éraillé","word_nosc":"eraille","lemma":"érailler","pos":"VER"} ,
		{"word":"éraillée","word_nosc":"eraillee","lemma":"érailler","pos":"VER"} ,
		{"word":"éraillées","word_nosc":"eraillees","lemma":"érailler","pos":"VER"} ,
		{"word":"éraillés","word_nosc":"erailles","lemma":"érailler","pos":"VER"} ,
		{"word":"éreintait","word_nosc":"ereintait","lemma":"éreinter","pos":"VER"} ,
		{"word":"éreintant","word_nosc":"ereintant","lemma":"éreinter","pos":"VER"} ,
		{"word":"éreinte","word_nosc":"ereinte","lemma":"éreinter","pos":"VER"} ,
		{"word":"éreintent","word_nosc":"ereintent","lemma":"éreinter","pos":"VER"} ,
		{"word":"éreinter","word_nosc":"ereinter","lemma":"éreinter","pos":"VER"} ,
		{"word":"éreinté","word_nosc":"ereinte","lemma":"éreinter","pos":"VER"} ,
		{"word":"éreintée","word_nosc":"ereintee","lemma":"éreinter","pos":"VER"} ,
		{"word":"éreintées","word_nosc":"ereintees","lemma":"éreinter","pos":"VER"} ,
		{"word":"éreintés","word_nosc":"ereintes","lemma":"éreinter","pos":"VER"} ,
		{"word":"érige","word_nosc":"erige","lemma":"ériger","pos":"VER"} ,
		{"word":"érigea","word_nosc":"erigea","lemma":"ériger","pos":"VER"} ,
		{"word":"érigeaient","word_nosc":"erigeaient","lemma":"ériger","pos":"VER"} ,
		{"word":"érigeait","word_nosc":"erigeait","lemma":"ériger","pos":"VER"} ,
		{"word":"érigeant","word_nosc":"erigeant","lemma":"ériger","pos":"VER"} ,
		{"word":"érigent","word_nosc":"erigent","lemma":"ériger","pos":"VER"} ,
		{"word":"ériger","word_nosc":"eriger","lemma":"ériger","pos":"VER"} ,
		{"word":"érigeât","word_nosc":"erigeat","lemma":"ériger","pos":"VER"} ,
		{"word":"érigé","word_nosc":"erige","lemma":"ériger","pos":"VER"} ,
		{"word":"érigée","word_nosc":"erigee","lemma":"ériger","pos":"VER"} ,
		{"word":"érigées","word_nosc":"erigees","lemma":"ériger","pos":"VER"} ,
		{"word":"érigés","word_nosc":"eriges","lemma":"ériger","pos":"VER"} ,
		{"word":"érode","word_nosc":"erode","lemma":"éroder","pos":"VER"} ,
		{"word":"érodent","word_nosc":"erodent","lemma":"éroder","pos":"VER"} ,
		{"word":"éroder","word_nosc":"eroder","lemma":"éroder","pos":"VER"} ,
		{"word":"érodé","word_nosc":"erode","lemma":"éroder","pos":"VER"} ,
		{"word":"érodée","word_nosc":"erodee","lemma":"éroder","pos":"VER"} ,
		{"word":"érodées","word_nosc":"erodees","lemma":"éroder","pos":"VER"} ,
		{"word":"érodés","word_nosc":"erodes","lemma":"éroder","pos":"VER"} ,
		{"word":"érotise","word_nosc":"erotise","lemma":"érotiser","pos":"VER"} ,
		{"word":"érotiser","word_nosc":"erotiser","lemma":"érotiser","pos":"VER"} ,
		{"word":"érotisé","word_nosc":"erotise","lemma":"érotiser","pos":"VER"} ,
		{"word":"érotisée","word_nosc":"erotisee","lemma":"érotiser","pos":"VER"} ,
		{"word":"éructa","word_nosc":"eructa","lemma":"éructer","pos":"VER"} ,
		{"word":"éructait","word_nosc":"eructait","lemma":"éructer","pos":"VER"} ,
		{"word":"éructant","word_nosc":"eructant","lemma":"éructer","pos":"VER"} ,
		{"word":"éructe","word_nosc":"eructe","lemma":"éructer","pos":"VER"} ,
		{"word":"éructer","word_nosc":"eructer","lemma":"éructer","pos":"VER"} ,
		{"word":"éructes","word_nosc":"eructes","lemma":"éructer","pos":"VER"} ,
		{"word":"éructé","word_nosc":"eructe","lemma":"éructer","pos":"VER"} ,
		{"word":"éructées","word_nosc":"eructees","lemma":"éructer","pos":"VER"} ,
		{"word":"établi","word_nosc":"etabli","lemma":"établir","pos":"VER"} ,
		{"word":"établie","word_nosc":"etablie","lemma":"établir","pos":"VER"} ,
		{"word":"établies","word_nosc":"etablies","lemma":"établir","pos":"VER"} ,
		{"word":"établir","word_nosc":"etablir","lemma":"établir","pos":"VER"} ,
		{"word":"établira","word_nosc":"etablira","lemma":"établir","pos":"VER"} ,
		{"word":"établirai","word_nosc":"etablirai","lemma":"établir","pos":"VER"} ,
		{"word":"établiraient","word_nosc":"etabliraient","lemma":"établir","pos":"VER"} ,
		{"word":"établirais","word_nosc":"etablirais","lemma":"établir","pos":"VER"} ,
		{"word":"établirait","word_nosc":"etablirait","lemma":"établir","pos":"VER"} ,
		{"word":"établirent","word_nosc":"etablirent","lemma":"établir","pos":"VER"} ,
		{"word":"établirez","word_nosc":"etablirez","lemma":"établir","pos":"VER"} ,
		{"word":"établirions","word_nosc":"etablirions","lemma":"établir","pos":"VER"} ,
		{"word":"établirons","word_nosc":"etablirons","lemma":"établir","pos":"VER"} ,
		{"word":"établiront","word_nosc":"etabliront","lemma":"établir","pos":"VER"} ,
		{"word":"établis","word_nosc":"etablis","lemma":"établir","pos":"VER"} ,
		{"word":"établissaient","word_nosc":"etablissaient","lemma":"établir","pos":"VER"} ,
		{"word":"établissais","word_nosc":"etablissais","lemma":"établir","pos":"VER"} ,
		{"word":"établissait","word_nosc":"etablissait","lemma":"établir","pos":"VER"} ,
		{"word":"établissant","word_nosc":"etablissant","lemma":"établir","pos":"VER"} ,
		{"word":"établisse","word_nosc":"etablisse","lemma":"établir","pos":"VER"} ,
		{"word":"établissent","word_nosc":"etablissent","lemma":"établir","pos":"VER"} ,
		{"word":"établisses","word_nosc":"etablisses","lemma":"établir","pos":"VER"} ,
		{"word":"établissez","word_nosc":"etablissez","lemma":"établir","pos":"VER"} ,
		{"word":"établissions","word_nosc":"etablissions","lemma":"établir","pos":"VER"} ,
		{"word":"établissons","word_nosc":"etablissons","lemma":"établir","pos":"VER"} ,
		{"word":"établit","word_nosc":"etablit","lemma":"établir","pos":"VER"} ,
		{"word":"établît","word_nosc":"etablit","lemma":"établir","pos":"VER"} ,
		{"word":"étage","word_nosc":"etage","lemma":"étager","pos":"VER"} ,
		{"word":"étagea","word_nosc":"etagea","lemma":"étager","pos":"VER"} ,
		{"word":"étageaient","word_nosc":"etageaient","lemma":"étager","pos":"VER"} ,
		{"word":"étageait","word_nosc":"etageait","lemma":"étager","pos":"VER"} ,
		{"word":"étageant","word_nosc":"etageant","lemma":"étager","pos":"VER"} ,
		{"word":"étagent","word_nosc":"etagent","lemma":"étager","pos":"VER"} ,
		{"word":"étages","word_nosc":"etages","lemma":"étager","pos":"VER"} ,
		{"word":"étagée","word_nosc":"etagee","lemma":"étager","pos":"VER"} ,
		{"word":"étagées","word_nosc":"etagees","lemma":"étager","pos":"VER"} ,
		{"word":"étagés","word_nosc":"etages","lemma":"étager","pos":"VER"} ,
		{"word":"étaie","word_nosc":"etaie","lemma":"étayer","pos":"VER"} ,
		{"word":"étaient","word_nosc":"etaient","lemma":"être","pos":"VER"} ,
		{"word":"étais","word_nosc":"etais","lemma":"être","pos":"VER"} ,
		{"word":"était","word_nosc":"etait","lemma":"être","pos":"VER"} ,
		{"word":"étala","word_nosc":"etala","lemma":"étaler","pos":"VER"} ,
		{"word":"étalai","word_nosc":"etalai","lemma":"étaler","pos":"VER"} ,
		{"word":"étalaient","word_nosc":"etalaient","lemma":"étaler","pos":"VER"} ,
		{"word":"étalais","word_nosc":"etalais","lemma":"étaler","pos":"VER"} ,
		{"word":"étalait","word_nosc":"etalait","lemma":"étaler","pos":"VER"} ,
		{"word":"étalant","word_nosc":"etalant","lemma":"étaler","pos":"VER"} ,
		{"word":"étale","word_nosc":"etale","lemma":"étaler","pos":"VER"} ,
		{"word":"étalent","word_nosc":"etalent","lemma":"étaler","pos":"VER"} ,
		{"word":"étaler","word_nosc":"etaler","lemma":"étaler","pos":"VER"} ,
		{"word":"étalera","word_nosc":"etalera","lemma":"étaler","pos":"VER"} ,
		{"word":"étalerai","word_nosc":"etalerai","lemma":"étaler","pos":"VER"} ,
		{"word":"étaleraient","word_nosc":"etaleraient","lemma":"étaler","pos":"VER"} ,
		{"word":"étalerait","word_nosc":"etalerait","lemma":"étaler","pos":"VER"} ,
		{"word":"étaleras","word_nosc":"etaleras","lemma":"étaler","pos":"VER"} ,
		{"word":"étaleront","word_nosc":"etaleront","lemma":"étaler","pos":"VER"} ,
		{"word":"étales","word_nosc":"etales","lemma":"étaler","pos":"VER"} ,
		{"word":"étalez","word_nosc":"etalez","lemma":"étaler","pos":"VER"} ,
		{"word":"étalions","word_nosc":"etalions","lemma":"étaler","pos":"VER"} ,
		{"word":"étalonne","word_nosc":"etalonne","lemma":"étalonner","pos":"VER"} ,
		{"word":"étalonner","word_nosc":"etalonner","lemma":"étalonner","pos":"VER"} ,
		{"word":"étalonné","word_nosc":"etalonne","lemma":"étalonner","pos":"VER"} ,
		{"word":"étalons","word_nosc":"etalons","lemma":"étaler","pos":"VER"} ,
		{"word":"étalât","word_nosc":"etalat","lemma":"étaler","pos":"VER"} ,
		{"word":"étalèrent","word_nosc":"etalerent","lemma":"étaler","pos":"VER"} ,
		{"word":"étalé","word_nosc":"etale","lemma":"étaler","pos":"VER"} ,
		{"word":"étalée","word_nosc":"etalee","lemma":"étaler","pos":"VER"} ,
		{"word":"étalées","word_nosc":"etalees","lemma":"étaler","pos":"VER"} ,
		{"word":"étalés","word_nosc":"etales","lemma":"étaler","pos":"VER"} ,
		{"word":"étamaient","word_nosc":"etamaient","lemma":"étamer","pos":"VER"} ,
		{"word":"étamé","word_nosc":"etame","lemma":"étamer","pos":"VER"} ,
		{"word":"étanchaient","word_nosc":"etanchaient","lemma":"étancher","pos":"VER"} ,
		{"word":"étanchait","word_nosc":"etanchait","lemma":"étancher","pos":"VER"} ,
		{"word":"étanche","word_nosc":"etanche","lemma":"étancher","pos":"VER"} ,
		{"word":"étanchent","word_nosc":"etanchent","lemma":"étancher","pos":"VER"} ,
		{"word":"étancher","word_nosc":"etancher","lemma":"étancher","pos":"VER"} ,
		{"word":"étancherait","word_nosc":"etancherait","lemma":"étancher","pos":"VER"} ,
		{"word":"étanches","word_nosc":"etanches","lemma":"étancher","pos":"VER"} ,
		{"word":"étanché","word_nosc":"etanche","lemma":"étancher","pos":"VER"} ,
		{"word":"étanchée","word_nosc":"etanchee","lemma":"étancher","pos":"VER"} ,
		{"word":"étant","word_nosc":"etant","lemma":"être","pos":"VER"} ,
		{"word":"étayaient","word_nosc":"etayaient","lemma":"étayer","pos":"VER"} ,
		{"word":"étayait","word_nosc":"etayait","lemma":"étayer","pos":"VER"} ,
		{"word":"étayant","word_nosc":"etayant","lemma":"étayer","pos":"VER"} ,
		{"word":"étaye","word_nosc":"etaye","lemma":"étayer","pos":"VER"} ,
		{"word":"étayer","word_nosc":"etayer","lemma":"étayer","pos":"VER"} ,
		{"word":"étayez","word_nosc":"etayez","lemma":"étayer","pos":"VER"} ,
		{"word":"étayé","word_nosc":"etaye","lemma":"étayer","pos":"VER"} ,
		{"word":"étayée","word_nosc":"etayee","lemma":"étayer","pos":"VER"} ,
		{"word":"étayées","word_nosc":"etayees","lemma":"étayer","pos":"VER"} ,
		{"word":"étayés","word_nosc":"etayes","lemma":"étayer","pos":"VER"} ,
		{"word":"éteignaient","word_nosc":"eteignaient","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteignais","word_nosc":"eteignais","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteignait","word_nosc":"eteignait","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteignant","word_nosc":"eteignant","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteigne","word_nosc":"eteigne","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteignent","word_nosc":"eteignent","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteignes","word_nosc":"eteignes","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteignez","word_nosc":"eteignez","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteignions","word_nosc":"eteignions","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteignirent","word_nosc":"eteignirent","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteignis","word_nosc":"eteignis","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteignit","word_nosc":"eteignit","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteignons","word_nosc":"eteignons","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteignît","word_nosc":"eteignit","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteindra","word_nosc":"eteindra","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteindrai","word_nosc":"eteindrai","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteindraient","word_nosc":"eteindraient","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteindrais","word_nosc":"eteindrais","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteindrait","word_nosc":"eteindrait","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteindras","word_nosc":"eteindras","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteindre","word_nosc":"eteindre","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteindrez","word_nosc":"eteindrez","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteindrons","word_nosc":"eteindrons","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteindront","word_nosc":"eteindront","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteins","word_nosc":"eteins","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteint","word_nosc":"eteint","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteinte","word_nosc":"eteinte","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteintes","word_nosc":"eteintes","lemma":"éteindre","pos":"VER"} ,
		{"word":"éteints","word_nosc":"eteints","lemma":"éteindre","pos":"VER"} ,
		{"word":"étend","word_nosc":"etend","lemma":"étendre","pos":"VER"} ,
		{"word":"étendaient","word_nosc":"etendaient","lemma":"étendre","pos":"VER"} ,
		{"word":"étendais","word_nosc":"etendais","lemma":"étendre","pos":"VER"} ,
		{"word":"étendait","word_nosc":"etendait","lemma":"étendre","pos":"VER"} ,
		{"word":"étendant","word_nosc":"etendant","lemma":"étendre","pos":"VER"} ,
		{"word":"étende","word_nosc":"etende","lemma":"étendre","pos":"VER"} ,
		{"word":"étendent","word_nosc":"etendent","lemma":"étendre","pos":"VER"} ,
		{"word":"étendez","word_nosc":"etendez","lemma":"étendre","pos":"VER"} ,
		{"word":"étendions","word_nosc":"etendions","lemma":"étendre","pos":"VER"} ,
		{"word":"étendirent","word_nosc":"etendirent","lemma":"étendre","pos":"VER"} ,
		{"word":"étendis","word_nosc":"etendis","lemma":"étendre","pos":"VER"} ,
		{"word":"étendit","word_nosc":"etendit","lemma":"étendre","pos":"VER"} ,
		{"word":"étendons","word_nosc":"etendons","lemma":"étendre","pos":"VER"} ,
		{"word":"étendra","word_nosc":"etendra","lemma":"étendre","pos":"VER"} ,
		{"word":"étendrai","word_nosc":"etendrai","lemma":"étendre","pos":"VER"} ,
		{"word":"étendraient","word_nosc":"etendraient","lemma":"étendre","pos":"VER"} ,
		{"word":"étendrais","word_nosc":"etendrais","lemma":"étendre","pos":"VER"} ,
		{"word":"étendrait","word_nosc":"etendrait","lemma":"étendre","pos":"VER"} ,
		{"word":"étendre","word_nosc":"etendre","lemma":"étendre","pos":"VER"} ,
		{"word":"étendrez","word_nosc":"etendrez","lemma":"étendre","pos":"VER"} ,
		{"word":"étendrions","word_nosc":"etendrions","lemma":"étendre","pos":"VER"} ,
		{"word":"étendrons","word_nosc":"etendrons","lemma":"étendre","pos":"VER"} ,
		{"word":"étendront","word_nosc":"etendront","lemma":"étendre","pos":"VER"} ,
		{"word":"étends","word_nosc":"etends","lemma":"étendre","pos":"VER"} ,
		{"word":"étendu","word_nosc":"etendu","lemma":"étendre","pos":"VER"} ,
		{"word":"étendue","word_nosc":"etendue","lemma":"étendre","pos":"VER"} ,
		{"word":"étendues","word_nosc":"etendues","lemma":"étendre","pos":"VER"} ,
		{"word":"étendus","word_nosc":"etendus","lemma":"étendre","pos":"VER"} ,
		{"word":"étendîmes","word_nosc":"etendimes","lemma":"étendre","pos":"VER"} ,
		{"word":"étendît","word_nosc":"etendit","lemma":"étendre","pos":"VER"} ,
		{"word":"éternisa","word_nosc":"eternisa","lemma":"éterniser","pos":"VER"} ,
		{"word":"éternisaient","word_nosc":"eternisaient","lemma":"éterniser","pos":"VER"} ,
		{"word":"éternisais","word_nosc":"eternisais","lemma":"éterniser","pos":"VER"} ,
		{"word":"éternisait","word_nosc":"eternisait","lemma":"éterniser","pos":"VER"} ,
		{"word":"éternise","word_nosc":"eternise","lemma":"éterniser","pos":"VER"} ,
		{"word":"éternisent","word_nosc":"eternisent","lemma":"éterniser","pos":"VER"} ,
		{"word":"éterniser","word_nosc":"eterniser","lemma":"éterniser","pos":"VER"} ,
		{"word":"éterniserai","word_nosc":"eterniserai","lemma":"éterniser","pos":"VER"} ,
		{"word":"éterniserait","word_nosc":"eterniserait","lemma":"éterniser","pos":"VER"} ,
		{"word":"éterniserons","word_nosc":"eterniserons","lemma":"éterniser","pos":"VER"} ,
		{"word":"éternisez","word_nosc":"eternisez","lemma":"éterniser","pos":"VER"} ,
		{"word":"éternisons","word_nosc":"eternisons","lemma":"éterniser","pos":"VER"} ,
		{"word":"éternisât","word_nosc":"eternisat","lemma":"éterniser","pos":"VER"} ,
		{"word":"éternisé","word_nosc":"eternise","lemma":"éterniser","pos":"VER"} ,
		{"word":"éternisée","word_nosc":"eternisee","lemma":"éterniser","pos":"VER"} ,
		{"word":"éternisés","word_nosc":"eternises","lemma":"éterniser","pos":"VER"} ,
		{"word":"éternua","word_nosc":"eternua","lemma":"éternuer","pos":"VER"} ,
		{"word":"éternuaient","word_nosc":"eternuaient","lemma":"éternuer","pos":"VER"} ,
		{"word":"éternuais","word_nosc":"eternuais","lemma":"éternuer","pos":"VER"} ,
		{"word":"éternuait","word_nosc":"eternuait","lemma":"éternuer","pos":"VER"} ,
		{"word":"éternuant","word_nosc":"eternuant","lemma":"éternuer","pos":"VER"} ,
		{"word":"éternue","word_nosc":"eternue","lemma":"éternuer","pos":"VER"} ,
		{"word":"éternuent","word_nosc":"eternuent","lemma":"éternuer","pos":"VER"} ,
		{"word":"éternuer","word_nosc":"eternuer","lemma":"éternuer","pos":"VER"} ,
		{"word":"éternuerait","word_nosc":"eternuerait","lemma":"éternuer","pos":"VER"} ,
		{"word":"éternues","word_nosc":"eternues","lemma":"éternuer","pos":"VER"} ,
		{"word":"éternuez","word_nosc":"eternuez","lemma":"éternuer","pos":"VER"} ,
		{"word":"éternué","word_nosc":"eternue","lemma":"éternuer","pos":"VER"} ,
		{"word":"étiez","word_nosc":"etiez","lemma":"être","pos":"VER"} ,
		{"word":"étincela","word_nosc":"etincela","lemma":"étinceler","pos":"VER"} ,
		{"word":"étincelaient","word_nosc":"etincelaient","lemma":"étinceler","pos":"VER"} ,
		{"word":"étincelait","word_nosc":"etincelait","lemma":"étinceler","pos":"VER"} ,
		{"word":"étincelant","word_nosc":"etincelant","lemma":"étinceler","pos":"VER"} ,
		{"word":"étinceler","word_nosc":"etinceler","lemma":"étinceler","pos":"VER"} ,
		{"word":"étincelle","word_nosc":"etincelle","lemma":"étinceler","pos":"VER"} ,
		{"word":"étincellent","word_nosc":"etincellent","lemma":"étinceler","pos":"VER"} ,
		{"word":"étincellera","word_nosc":"etincellera","lemma":"étinceler","pos":"VER"} ,
		{"word":"étincelèrent","word_nosc":"etincelerent","lemma":"étinceler","pos":"VER"} ,
		{"word":"étincelé","word_nosc":"etincele","lemma":"étinceler","pos":"VER"} ,
		{"word":"étiolaient","word_nosc":"etiolaient","lemma":"étioler","pos":"VER"} ,
		{"word":"étiolais","word_nosc":"etiolais","lemma":"étioler","pos":"VER"} ,
		{"word":"étiolait","word_nosc":"etiolait","lemma":"étioler","pos":"VER"} ,
		{"word":"étiolant","word_nosc":"etiolant","lemma":"étioler","pos":"VER"} ,
		{"word":"étiole","word_nosc":"etiole","lemma":"étioler","pos":"VER"} ,
		{"word":"étiolent","word_nosc":"etiolent","lemma":"étioler","pos":"VER"} ,
		{"word":"étioler","word_nosc":"etioler","lemma":"étioler","pos":"VER"} ,
		{"word":"étiolé","word_nosc":"etiole","lemma":"étioler","pos":"VER"} ,
		{"word":"étiolée","word_nosc":"etiolee","lemma":"étioler","pos":"VER"} ,
		{"word":"étiolées","word_nosc":"etiolees","lemma":"étioler","pos":"VER"} ,
		{"word":"étions","word_nosc":"etions","lemma":"être","pos":"VER"} ,
		{"word":"étiquetaient","word_nosc":"etiquetaient","lemma":"étiqueter","pos":"VER"} ,
		{"word":"étiqueter","word_nosc":"etiqueter","lemma":"étiqueter","pos":"VER"} ,
		{"word":"étiquetez","word_nosc":"etiquetez","lemma":"étiqueter","pos":"VER"} ,
		{"word":"étiquette","word_nosc":"etiquette","lemma":"étiqueter","pos":"VER"} ,
		{"word":"étiquettent","word_nosc":"etiquettent","lemma":"étiqueter","pos":"VER"} ,
		{"word":"étiquettes","word_nosc":"etiquettes","lemma":"étiqueter","pos":"VER"} ,
		{"word":"étiqueté","word_nosc":"etiquete","lemma":"étiqueter","pos":"VER"} ,
		{"word":"étiquetée","word_nosc":"etiquetee","lemma":"étiqueter","pos":"VER"} ,
		{"word":"étiquetées","word_nosc":"etiquetees","lemma":"étiqueter","pos":"VER"} ,
		{"word":"étiquetés","word_nosc":"etiquetes","lemma":"étiqueter","pos":"VER"} ,
		{"word":"étira","word_nosc":"etira","lemma":"étirer","pos":"VER"} ,
		{"word":"étirai","word_nosc":"etirai","lemma":"étirer","pos":"VER"} ,
		{"word":"étiraient","word_nosc":"etiraient","lemma":"étirer","pos":"VER"} ,
		{"word":"étirais","word_nosc":"etirais","lemma":"étirer","pos":"VER"} ,
		{"word":"étirait","word_nosc":"etirait","lemma":"étirer","pos":"VER"} ,
		{"word":"étirant","word_nosc":"etirant","lemma":"étirer","pos":"VER"} ,
		{"word":"étire","word_nosc":"etire","lemma":"étirer","pos":"VER"} ,
		{"word":"étirent","word_nosc":"etirent","lemma":"étirer","pos":"VER"} ,
		{"word":"étirer","word_nosc":"etirer","lemma":"étirer","pos":"VER"} ,
		{"word":"étirerait","word_nosc":"etirerait","lemma":"étirer","pos":"VER"} ,
		{"word":"étirez","word_nosc":"etirez","lemma":"étirer","pos":"VER"} ,
		{"word":"étirèrent","word_nosc":"etirerent","lemma":"étirer","pos":"VER"} ,
		{"word":"étiré","word_nosc":"etire","lemma":"étirer","pos":"VER"} ,
		{"word":"étirée","word_nosc":"etiree","lemma":"étirer","pos":"VER"} ,
		{"word":"étirées","word_nosc":"etirees","lemma":"étirer","pos":"VER"} ,
		{"word":"étirés","word_nosc":"etires","lemma":"étirer","pos":"VER"} ,
		{"word":"étoffaient","word_nosc":"etoffaient","lemma":"étoffer","pos":"VER"} ,
		{"word":"étoffait","word_nosc":"etoffait","lemma":"étoffer","pos":"VER"} ,
		{"word":"étoffe","word_nosc":"etoffe","lemma":"étoffer","pos":"VER"} ,
		{"word":"étoffer","word_nosc":"etoffer","lemma":"étoffer","pos":"VER"} ,
		{"word":"étoffes","word_nosc":"etoffes","lemma":"étoffer","pos":"VER"} ,
		{"word":"étoffé","word_nosc":"etoffe","lemma":"étoffer","pos":"VER"} ,
		{"word":"étoffée","word_nosc":"etoffee","lemma":"étoffer","pos":"VER"} ,
		{"word":"étoffés","word_nosc":"etoffes","lemma":"étoffer","pos":"VER"} ,
		{"word":"étoila","word_nosc":"etoila","lemma":"étoiler","pos":"VER"} ,
		{"word":"étoilaient","word_nosc":"etoilaient","lemma":"étoiler","pos":"VER"} ,
		{"word":"étoilait","word_nosc":"etoilait","lemma":"étoiler","pos":"VER"} ,
		{"word":"étoile","word_nosc":"etoile","lemma":"étoiler","pos":"VER"} ,
		{"word":"étoilent","word_nosc":"etoilent","lemma":"étoiler","pos":"VER"} ,
		{"word":"étoiles","word_nosc":"etoiles","lemma":"étoiler","pos":"VER"} ,
		{"word":"étoilé","word_nosc":"etoile","lemma":"étoiler","pos":"VER"} ,
		{"word":"étoilée","word_nosc":"etoilee","lemma":"étoiler","pos":"VER"} ,
		{"word":"étoilées","word_nosc":"etoilees","lemma":"étoiler","pos":"VER"} ,
		{"word":"étoilés","word_nosc":"etoiles","lemma":"étoiler","pos":"VER"} ,
		{"word":"étonna","word_nosc":"etonna","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnai","word_nosc":"etonnai","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnaient","word_nosc":"etonnaient","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnais","word_nosc":"etonnais","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnait","word_nosc":"etonnait","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnant","word_nosc":"etonnant","lemma":"étonner","pos":"VER"} ,
		{"word":"étonne","word_nosc":"etonne","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnent","word_nosc":"etonnent","lemma":"étonner","pos":"VER"} ,
		{"word":"étonner","word_nosc":"etonner","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnera","word_nosc":"etonnera","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnerai","word_nosc":"etonnerai","lemma":"étonner","pos":"VER"} ,
		{"word":"étonneraient","word_nosc":"etonneraient","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnerais","word_nosc":"etonnerais","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnerait","word_nosc":"etonnerait","lemma":"étonner","pos":"VER"} ,
		{"word":"étonneras","word_nosc":"etonneras","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnerez","word_nosc":"etonnerez","lemma":"étonner","pos":"VER"} ,
		{"word":"étonneriez","word_nosc":"etonneriez","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnerons","word_nosc":"etonnerons","lemma":"étonner","pos":"VER"} ,
		{"word":"étonneront","word_nosc":"etonneront","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnes","word_nosc":"etonnes","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnez","word_nosc":"etonnez","lemma":"étonner","pos":"VER"} ,
		{"word":"étonniez","word_nosc":"etonniez","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnions","word_nosc":"etonnions","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnons","word_nosc":"etonnons","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnâmes","word_nosc":"etonnames","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnât","word_nosc":"etonnat","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnèrent","word_nosc":"etonnerent","lemma":"étonner","pos":"VER"} ,
		{"word":"étonné","word_nosc":"etonne","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnée","word_nosc":"etonnee","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnées","word_nosc":"etonnees","lemma":"étonner","pos":"VER"} ,
		{"word":"étonnés","word_nosc":"etonnes","lemma":"étonner","pos":"VER"} ,
		{"word":"étouffa","word_nosc":"etouffa","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffai","word_nosc":"etouffai","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffaient","word_nosc":"etouffaient","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffais","word_nosc":"etouffais","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffait","word_nosc":"etouffait","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffant","word_nosc":"etouffant","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffassent","word_nosc":"etouffassent","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffe","word_nosc":"etouffe","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffent","word_nosc":"etouffent","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffer","word_nosc":"etouffer","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffera","word_nosc":"etouffera","lemma":"étouffer","pos":"VER"} ,
		{"word":"étoufferai","word_nosc":"etoufferai","lemma":"étouffer","pos":"VER"} ,
		{"word":"étoufferaient","word_nosc":"etoufferaient","lemma":"étouffer","pos":"VER"} ,
		{"word":"étoufferais","word_nosc":"etoufferais","lemma":"étouffer","pos":"VER"} ,
		{"word":"étoufferait","word_nosc":"etoufferait","lemma":"étouffer","pos":"VER"} ,
		{"word":"étoufferez","word_nosc":"etoufferez","lemma":"étouffer","pos":"VER"} ,
		{"word":"étoufferiez","word_nosc":"etoufferiez","lemma":"étouffer","pos":"VER"} ,
		{"word":"étoufferons","word_nosc":"etoufferons","lemma":"étouffer","pos":"VER"} ,
		{"word":"étoufferont","word_nosc":"etoufferont","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffes","word_nosc":"etouffes","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffez","word_nosc":"etouffez","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffiez","word_nosc":"etouffiez","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffions","word_nosc":"etouffions","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffons","word_nosc":"etouffons","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffât","word_nosc":"etouffat","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffèrent","word_nosc":"etoufferent","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffé","word_nosc":"etouffe","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffée","word_nosc":"etouffee","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffées","word_nosc":"etouffees","lemma":"étouffer","pos":"VER"} ,
		{"word":"étouffés","word_nosc":"etouffes","lemma":"étouffer","pos":"VER"} ,
		{"word":"étourdi","word_nosc":"etourdi","lemma":"étourdir","pos":"VER"} ,
		{"word":"étourdie","word_nosc":"etourdie","lemma":"étourdir","pos":"VER"} ,
		{"word":"étourdir","word_nosc":"etourdir","lemma":"étourdir","pos":"VER"} ,
		{"word":"étourdira","word_nosc":"etourdira","lemma":"étourdir","pos":"VER"} ,
		{"word":"étourdirait","word_nosc":"etourdirait","lemma":"étourdir","pos":"VER"} ,
		{"word":"étourdirent","word_nosc":"etourdirent","lemma":"étourdir","pos":"VER"} ,
		{"word":"étourdis","word_nosc":"etourdis","lemma":"étourdir","pos":"VER"} ,
		{"word":"étourdissaient","word_nosc":"etourdissaient","lemma":"étourdir","pos":"VER"} ,
		{"word":"étourdissait","word_nosc":"etourdissait","lemma":"étourdir","pos":"VER"} ,
		{"word":"étourdissant","word_nosc":"etourdissant","lemma":"étourdir","pos":"VER"} ,
		{"word":"étourdissent","word_nosc":"etourdissent","lemma":"étourdir","pos":"VER"} ,
		{"word":"étourdissez","word_nosc":"etourdissez","lemma":"étourdir","pos":"VER"} ,
		{"word":"étourdissons","word_nosc":"etourdissons","lemma":"étourdir","pos":"VER"} ,
		{"word":"étourdit","word_nosc":"etourdit","lemma":"étourdir","pos":"VER"} ,
		{"word":"étrangla","word_nosc":"etrangla","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglai","word_nosc":"etranglai","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglaient","word_nosc":"etranglaient","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglais","word_nosc":"etranglais","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglait","word_nosc":"etranglait","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglant","word_nosc":"etranglant","lemma":"étrangler","pos":"VER"} ,
		{"word":"étrangle","word_nosc":"etrangle","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglent","word_nosc":"etranglent","lemma":"étrangler","pos":"VER"} ,
		{"word":"étrangler","word_nosc":"etrangler","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglera","word_nosc":"etranglera","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglerai","word_nosc":"etranglerai","lemma":"étrangler","pos":"VER"} ,
		{"word":"étrangleraient","word_nosc":"etrangleraient","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglerais","word_nosc":"etranglerais","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglerait","word_nosc":"etranglerait","lemma":"étrangler","pos":"VER"} ,
		{"word":"étrangleront","word_nosc":"etrangleront","lemma":"étrangler","pos":"VER"} ,
		{"word":"étrangles","word_nosc":"etrangles","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglez","word_nosc":"etranglez","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglât","word_nosc":"etranglat","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglé","word_nosc":"etrangle","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglée","word_nosc":"etranglee","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglées","word_nosc":"etranglees","lemma":"étrangler","pos":"VER"} ,
		{"word":"étranglés","word_nosc":"etrangles","lemma":"étrangler","pos":"VER"} ,
		{"word":"étreignaient","word_nosc":"etreignaient","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreignais","word_nosc":"etreignais","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreignait","word_nosc":"etreignait","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreignant","word_nosc":"etreignant","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreigne","word_nosc":"etreigne","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreignent","word_nosc":"etreignent","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreignions","word_nosc":"etreignions","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreignirent","word_nosc":"etreignirent","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreignit","word_nosc":"etreignit","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreignons","word_nosc":"etreignons","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreignîmes","word_nosc":"etreignimes","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreindrait","word_nosc":"etreindrait","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreindre","word_nosc":"etreindre","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreindrons","word_nosc":"etreindrons","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreindront","word_nosc":"etreindront","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreins","word_nosc":"etreins","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreint","word_nosc":"etreint","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreinte","word_nosc":"etreinte","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreintes","word_nosc":"etreintes","lemma":"étreindre","pos":"VER"} ,
		{"word":"étreints","word_nosc":"etreints","lemma":"étreindre","pos":"VER"} ,
		{"word":"étrenna","word_nosc":"etrenna","lemma":"étrenner","pos":"VER"} ,
		{"word":"étrennait","word_nosc":"etrennait","lemma":"étrenner","pos":"VER"} ,
		{"word":"étrennant","word_nosc":"etrennant","lemma":"étrenner","pos":"VER"} ,
		{"word":"étrenne","word_nosc":"etrenne","lemma":"étrenner","pos":"VER"} ,
		{"word":"étrennent","word_nosc":"etrennent","lemma":"étrenner","pos":"VER"} ,
		{"word":"étrenner","word_nosc":"etrenner","lemma":"étrenner","pos":"VER"} ,
		{"word":"étrennera","word_nosc":"etrennera","lemma":"étrenner","pos":"VER"} ,
		{"word":"étrennerais","word_nosc":"etrennerais","lemma":"étrenner","pos":"VER"} ,
		{"word":"étrennes","word_nosc":"etrennes","lemma":"étrenner","pos":"VER"} ,
		{"word":"étrenné","word_nosc":"etrenne","lemma":"étrenner","pos":"VER"} ,
		{"word":"étrennée","word_nosc":"etrennee","lemma":"étrenner","pos":"VER"} ,
		{"word":"étrilla","word_nosc":"etrilla","lemma":"étriller","pos":"VER"} ,
		{"word":"étrillaient","word_nosc":"etrillaient","lemma":"étriller","pos":"VER"} ,
		{"word":"étrillait","word_nosc":"etrillait","lemma":"étriller","pos":"VER"} ,
		{"word":"étrille","word_nosc":"etrille","lemma":"étriller","pos":"VER"} ,
		{"word":"étriller","word_nosc":"etriller","lemma":"étriller","pos":"VER"} ,
		{"word":"étrillerez","word_nosc":"etrillerez","lemma":"étriller","pos":"VER"} ,
		{"word":"étrillé","word_nosc":"etrille","lemma":"étriller","pos":"VER"} ,
		{"word":"étrillée","word_nosc":"etrillee","lemma":"étriller","pos":"VER"} ,
		{"word":"étrillés","word_nosc":"etrilles","lemma":"étriller","pos":"VER"} ,
		{"word":"étripaient","word_nosc":"etripaient","lemma":"étriper","pos":"VER"} ,
		{"word":"étripe","word_nosc":"etripe","lemma":"étriper","pos":"VER"} ,
		{"word":"étripent","word_nosc":"etripent","lemma":"étriper","pos":"VER"} ,
		{"word":"étriper","word_nosc":"etriper","lemma":"étriper","pos":"VER"} ,
		{"word":"étripera","word_nosc":"etripera","lemma":"étriper","pos":"VER"} ,
		{"word":"étriperai","word_nosc":"etriperai","lemma":"étriper","pos":"VER"} ,
		{"word":"étriperaient","word_nosc":"etriperaient","lemma":"étriper","pos":"VER"} ,
		{"word":"étriperais","word_nosc":"etriperais","lemma":"étriper","pos":"VER"} ,
		{"word":"étripèrent","word_nosc":"etriperent","lemma":"étriper","pos":"VER"} ,
		{"word":"étripé","word_nosc":"etripe","lemma":"étriper","pos":"VER"} ,
		{"word":"étripée","word_nosc":"etripee","lemma":"étriper","pos":"VER"} ,
		{"word":"étripés","word_nosc":"etripes","lemma":"étriper","pos":"VER"} ,
		{"word":"étriqua","word_nosc":"etriqua","lemma":"étriquer","pos":"VER"} ,
		{"word":"étriquaient","word_nosc":"etriquaient","lemma":"étriquer","pos":"VER"} ,
		{"word":"étriquant","word_nosc":"etriquant","lemma":"étriquer","pos":"VER"} ,
		{"word":"étriqué","word_nosc":"etrique","lemma":"étriquer","pos":"VER"} ,
		{"word":"étriquée","word_nosc":"etriquee","lemma":"étriquer","pos":"VER"} ,
		{"word":"étriquées","word_nosc":"etriquees","lemma":"étriquer","pos":"VER"} ,
		{"word":"étriqués","word_nosc":"etriques","lemma":"étriquer","pos":"VER"} ,
		{"word":"étréci","word_nosc":"etreci","lemma":"étrécir","pos":"VER"} ,
		{"word":"étrécie","word_nosc":"etrecie","lemma":"étrécir","pos":"VER"} ,
		{"word":"étrécir","word_nosc":"etrecir","lemma":"étrécir","pos":"VER"} ,
		{"word":"étrécit","word_nosc":"etrecit","lemma":"étrécir","pos":"VER"} ,
		{"word":"étudia","word_nosc":"etudia","lemma":"étudier","pos":"VER"} ,
		{"word":"étudiai","word_nosc":"etudiai","lemma":"étudier","pos":"VER"} ,
		{"word":"étudiaient","word_nosc":"etudiaient","lemma":"étudier","pos":"VER"} ,
		{"word":"étudiais","word_nosc":"etudiais","lemma":"étudier","pos":"VER"} ,
		{"word":"étudiait","word_nosc":"etudiait","lemma":"étudier","pos":"VER"} ,
		{"word":"étudiant","word_nosc":"etudiant","lemma":"étudier","pos":"VER"} ,
		{"word":"étudie","word_nosc":"etudie","lemma":"étudier","pos":"VER"} ,
		{"word":"étudient","word_nosc":"etudient","lemma":"étudier","pos":"VER"} ,
		{"word":"étudier","word_nosc":"etudier","lemma":"étudier","pos":"VER"} ,
		{"word":"étudiera","word_nosc":"etudiera","lemma":"étudier","pos":"VER"} ,
		{"word":"étudierai","word_nosc":"etudierai","lemma":"étudier","pos":"VER"} ,
		{"word":"étudierais","word_nosc":"etudierais","lemma":"étudier","pos":"VER"} ,
		{"word":"étudierait","word_nosc":"etudierait","lemma":"étudier","pos":"VER"} ,
		{"word":"étudieras","word_nosc":"etudieras","lemma":"étudier","pos":"VER"} ,
		{"word":"étudierez","word_nosc":"etudierez","lemma":"étudier","pos":"VER"} ,
		{"word":"étudierions","word_nosc":"etudierions","lemma":"étudier","pos":"VER"} ,
		{"word":"étudierons","word_nosc":"etudierons","lemma":"étudier","pos":"VER"} ,
		{"word":"étudies","word_nosc":"etudies","lemma":"étudier","pos":"VER"} ,
		{"word":"étudiez","word_nosc":"etudiez","lemma":"étudier","pos":"VER"} ,
		{"word":"étudions","word_nosc":"etudions","lemma":"étudier","pos":"VER"} ,
		{"word":"étudièrent","word_nosc":"etudierent","lemma":"étudier","pos":"VER"} ,
		{"word":"étudié","word_nosc":"etudie","lemma":"étudier","pos":"VER"} ,
		{"word":"étudiée","word_nosc":"etudiee","lemma":"étudier","pos":"VER"} ,
		{"word":"étudiées","word_nosc":"etudiees","lemma":"étudier","pos":"VER"} ,
		{"word":"étudiés","word_nosc":"etudies","lemma":"étudier","pos":"VER"} ,
		{"word":"étuver","word_nosc":"etuver","lemma":"étuver","pos":"VER"} ,
		{"word":"étuvé","word_nosc":"etuve","lemma":"étuver","pos":"VER"} ,
		{"word":"été","word_nosc":"ete","lemma":"être","pos":"VER"} ,
		{"word":"étêta","word_nosc":"eteta","lemma":"étêter","pos":"VER"} ,
		{"word":"étêtait","word_nosc":"etetait","lemma":"étêter","pos":"VER"} ,
		{"word":"étêté","word_nosc":"etete","lemma":"étêter","pos":"VER"} ,
		{"word":"étêtés","word_nosc":"etetes","lemma":"étêter","pos":"VER"} ,
		{"word":"évacua","word_nosc":"evacua","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuai","word_nosc":"evacuai","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuaient","word_nosc":"evacuaient","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuais","word_nosc":"evacuais","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuait","word_nosc":"evacuait","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuant","word_nosc":"evacuant","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacue","word_nosc":"evacue","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuent","word_nosc":"evacuent","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuer","word_nosc":"evacuer","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuera","word_nosc":"evacuera","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuerai","word_nosc":"evacuerai","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacueraient","word_nosc":"evacueraient","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuerais","word_nosc":"evacuerais","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuerait","word_nosc":"evacuerait","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuerez","word_nosc":"evacuerez","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuerions","word_nosc":"evacuerions","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuerons","word_nosc":"evacuerons","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacueront","word_nosc":"evacueront","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuez","word_nosc":"evacuez","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuons","word_nosc":"evacuons","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuèrent","word_nosc":"evacuerent","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacué","word_nosc":"evacue","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuée","word_nosc":"evacuee","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacuées","word_nosc":"evacuees","lemma":"évacuer","pos":"VER"} ,
		{"word":"évacués","word_nosc":"evacues","lemma":"évacuer","pos":"VER"} ,
		{"word":"évada","word_nosc":"evada","lemma":"évader","pos":"VER"} ,
		{"word":"évadaient","word_nosc":"evadaient","lemma":"évader","pos":"VER"} ,
		{"word":"évadais","word_nosc":"evadais","lemma":"évader","pos":"VER"} ,
		{"word":"évadait","word_nosc":"evadait","lemma":"évader","pos":"VER"} ,
		{"word":"évadant","word_nosc":"evadant","lemma":"évader","pos":"VER"} ,
		{"word":"évade","word_nosc":"evade","lemma":"évader","pos":"VER"} ,
		{"word":"évadent","word_nosc":"evadent","lemma":"évader","pos":"VER"} ,
		{"word":"évader","word_nosc":"evader","lemma":"évader","pos":"VER"} ,
		{"word":"évadera","word_nosc":"evadera","lemma":"évader","pos":"VER"} ,
		{"word":"évaderai","word_nosc":"evaderai","lemma":"évader","pos":"VER"} ,
		{"word":"évaderaient","word_nosc":"evaderaient","lemma":"évader","pos":"VER"} ,
		{"word":"évaderais","word_nosc":"evaderais","lemma":"évader","pos":"VER"} ,
		{"word":"évaderait","word_nosc":"evaderait","lemma":"évader","pos":"VER"} ,
		{"word":"évaderas","word_nosc":"evaderas","lemma":"évader","pos":"VER"} ,
		{"word":"évaderont","word_nosc":"evaderont","lemma":"évader","pos":"VER"} ,
		{"word":"évades","word_nosc":"evades","lemma":"évader","pos":"VER"} ,
		{"word":"évadez","word_nosc":"evadez","lemma":"évader","pos":"VER"} ,
		{"word":"évadèrent","word_nosc":"evaderent","lemma":"évader","pos":"VER"} ,
		{"word":"évadé","word_nosc":"evade","lemma":"évader","pos":"VER"} ,
		{"word":"évadée","word_nosc":"evadee","lemma":"évader","pos":"VER"} ,
		{"word":"évadés","word_nosc":"evades","lemma":"évader","pos":"VER"} ,
		{"word":"évalua","word_nosc":"evalua","lemma":"évaluer","pos":"VER"} ,
		{"word":"évaluaient","word_nosc":"evaluaient","lemma":"évaluer","pos":"VER"} ,
		{"word":"évaluais","word_nosc":"evaluais","lemma":"évaluer","pos":"VER"} ,
		{"word":"évaluait","word_nosc":"evaluait","lemma":"évaluer","pos":"VER"} ,
		{"word":"évaluant","word_nosc":"evaluant","lemma":"évaluer","pos":"VER"} ,
		{"word":"évalue","word_nosc":"evalue","lemma":"évaluer","pos":"VER"} ,
		{"word":"évaluent","word_nosc":"evaluent","lemma":"évaluer","pos":"VER"} ,
		{"word":"évaluer","word_nosc":"evaluer","lemma":"évaluer","pos":"VER"} ,
		{"word":"évaluerez","word_nosc":"evaluerez","lemma":"évaluer","pos":"VER"} ,
		{"word":"évalueront","word_nosc":"evalueront","lemma":"évaluer","pos":"VER"} ,
		{"word":"évalues","word_nosc":"evalues","lemma":"évaluer","pos":"VER"} ,
		{"word":"évaluez","word_nosc":"evaluez","lemma":"évaluer","pos":"VER"} ,
		{"word":"évaluons","word_nosc":"evaluons","lemma":"évaluer","pos":"VER"} ,
		{"word":"évaluât","word_nosc":"evaluat","lemma":"évaluer","pos":"VER"} ,
		{"word":"évaluèrent","word_nosc":"evaluerent","lemma":"évaluer","pos":"VER"} ,
		{"word":"évalué","word_nosc":"evalue","lemma":"évaluer","pos":"VER"} ,
		{"word":"évaluée","word_nosc":"evaluee","lemma":"évaluer","pos":"VER"} ,
		{"word":"évaluées","word_nosc":"evaluees","lemma":"évaluer","pos":"VER"} ,
		{"word":"évalués","word_nosc":"evalues","lemma":"évaluer","pos":"VER"} ,
		{"word":"évangéliser","word_nosc":"evangeliser","lemma":"évangéliser","pos":"VER"} ,
		{"word":"évangéliseront","word_nosc":"evangeliseront","lemma":"évangéliser","pos":"VER"} ,
		{"word":"évangélisons","word_nosc":"evangelisons","lemma":"évangéliser","pos":"VER"} ,
		{"word":"évangélisé","word_nosc":"evangelise","lemma":"évangéliser","pos":"VER"} ,
		{"word":"évangélisés","word_nosc":"evangelises","lemma":"évangéliser","pos":"VER"} ,
		{"word":"évanoui","word_nosc":"evanoui","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouie","word_nosc":"evanouie","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouies","word_nosc":"evanouies","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouir","word_nosc":"evanouir","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouira","word_nosc":"evanouira","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouirai","word_nosc":"evanouirai","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouirait","word_nosc":"evanouirait","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouirent","word_nosc":"evanouirent","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouirez","word_nosc":"evanouirez","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouiront","word_nosc":"evanouiront","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouis","word_nosc":"evanouis","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouissaient","word_nosc":"evanouissaient","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouissais","word_nosc":"evanouissais","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouissait","word_nosc":"evanouissait","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouissant","word_nosc":"evanouissant","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouisse","word_nosc":"evanouisse","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouissent","word_nosc":"evanouissent","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouisses","word_nosc":"evanouisses","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouissez","word_nosc":"evanouissez","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouit","word_nosc":"evanouit","lemma":"évanouir","pos":"VER"} ,
		{"word":"évanouît","word_nosc":"evanouit","lemma":"évanouir","pos":"VER"} ,
		{"word":"évapora","word_nosc":"evapora","lemma":"évaporer","pos":"VER"} ,
		{"word":"évaporaient","word_nosc":"evaporaient","lemma":"évaporer","pos":"VER"} ,
		{"word":"évaporais","word_nosc":"evaporais","lemma":"évaporer","pos":"VER"} ,
		{"word":"évaporait","word_nosc":"evaporait","lemma":"évaporer","pos":"VER"} ,
		{"word":"évapore","word_nosc":"evapore","lemma":"évaporer","pos":"VER"} ,
		{"word":"évaporent","word_nosc":"evaporent","lemma":"évaporer","pos":"VER"} ,
		{"word":"évaporer","word_nosc":"evaporer","lemma":"évaporer","pos":"VER"} ,
		{"word":"évaporerait","word_nosc":"evaporerait","lemma":"évaporer","pos":"VER"} ,
		{"word":"évaporeront","word_nosc":"evaporeront","lemma":"évaporer","pos":"VER"} ,
		{"word":"évaporé","word_nosc":"evapore","lemma":"évaporer","pos":"VER"} ,
		{"word":"évaporés","word_nosc":"evapores","lemma":"évaporer","pos":"VER"} ,
		{"word":"évasaient","word_nosc":"evasaient","lemma":"évaser","pos":"VER"} ,
		{"word":"évasait","word_nosc":"evasait","lemma":"évaser","pos":"VER"} ,
		{"word":"évasant","word_nosc":"evasant","lemma":"évaser","pos":"VER"} ,
		{"word":"évase","word_nosc":"evase","lemma":"évaser","pos":"VER"} ,
		{"word":"évasent","word_nosc":"evasent","lemma":"évaser","pos":"VER"} ,
		{"word":"évaser","word_nosc":"evaser","lemma":"évaser","pos":"VER"} ,
		{"word":"évasé","word_nosc":"evase","lemma":"évaser","pos":"VER"} ,
		{"word":"évasée","word_nosc":"evasee","lemma":"évaser","pos":"VER"} ,
		{"word":"évasées","word_nosc":"evasees","lemma":"évaser","pos":"VER"} ,
		{"word":"évasés","word_nosc":"evases","lemma":"évaser","pos":"VER"} ,
		{"word":"éveilla","word_nosc":"eveilla","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillai","word_nosc":"eveillai","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillaient","word_nosc":"eveillaient","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillais","word_nosc":"eveillais","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillait","word_nosc":"eveillait","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillant","word_nosc":"eveillant","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveille","word_nosc":"eveille","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillent","word_nosc":"eveillent","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveiller","word_nosc":"eveiller","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillera","word_nosc":"eveillera","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillerai","word_nosc":"eveillerai","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveilleraient","word_nosc":"eveilleraient","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillerait","word_nosc":"eveillerait","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveilleras","word_nosc":"eveilleras","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillerez","word_nosc":"eveillerez","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveilleront","word_nosc":"eveilleront","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveilles","word_nosc":"eveilles","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillez","word_nosc":"eveillez","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillions","word_nosc":"eveillions","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillons","word_nosc":"eveillons","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillât","word_nosc":"eveillat","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillèrent","word_nosc":"eveillerent","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillé","word_nosc":"eveille","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillée","word_nosc":"eveillee","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillées","word_nosc":"eveillees","lemma":"éveiller","pos":"VER"} ,
		{"word":"éveillés","word_nosc":"eveilles","lemma":"éveiller","pos":"VER"} ,
		{"word":"éventa","word_nosc":"eventa","lemma":"éventer","pos":"VER"} ,
		{"word":"éventai","word_nosc":"eventai","lemma":"éventer","pos":"VER"} ,
		{"word":"éventaient","word_nosc":"eventaient","lemma":"éventer","pos":"VER"} ,
		{"word":"éventait","word_nosc":"eventait","lemma":"éventer","pos":"VER"} ,
		{"word":"éventant","word_nosc":"eventant","lemma":"éventer","pos":"VER"} ,
		{"word":"évente","word_nosc":"evente","lemma":"éventer","pos":"VER"} ,
		{"word":"éventent","word_nosc":"eventent","lemma":"éventer","pos":"VER"} ,
		{"word":"éventer","word_nosc":"eventer","lemma":"éventer","pos":"VER"} ,
		{"word":"éventerai","word_nosc":"eventerai","lemma":"éventer","pos":"VER"} ,
		{"word":"éventez","word_nosc":"eventez","lemma":"éventer","pos":"VER"} ,
		{"word":"éventons","word_nosc":"eventons","lemma":"éventer","pos":"VER"} ,
		{"word":"éventra","word_nosc":"eventra","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventraient","word_nosc":"eventraient","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventrait","word_nosc":"eventrait","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventrant","word_nosc":"eventrant","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventre","word_nosc":"eventre","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventrent","word_nosc":"eventrent","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventrer","word_nosc":"eventrer","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventrerai","word_nosc":"eventrerai","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventrerait","word_nosc":"eventrerait","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventrerez","word_nosc":"eventrerez","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventres","word_nosc":"eventres","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventrez","word_nosc":"eventrez","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventrions","word_nosc":"eventrions","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventrât","word_nosc":"eventrat","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventré","word_nosc":"eventre","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventrée","word_nosc":"eventree","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventrées","word_nosc":"eventrees","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventrés","word_nosc":"eventres","lemma":"éventrer","pos":"VER"} ,
		{"word":"éventé","word_nosc":"evente","lemma":"éventer","pos":"VER"} ,
		{"word":"éventée","word_nosc":"eventee","lemma":"éventer","pos":"VER"} ,
		{"word":"éventées","word_nosc":"eventees","lemma":"éventer","pos":"VER"} ,
		{"word":"éventés","word_nosc":"eventes","lemma":"éventer","pos":"VER"} ,
		{"word":"évertua","word_nosc":"evertua","lemma":"évertuer","pos":"VER"} ,
		{"word":"évertuaient","word_nosc":"evertuaient","lemma":"évertuer","pos":"VER"} ,
		{"word":"évertuais","word_nosc":"evertuais","lemma":"évertuer","pos":"VER"} ,
		{"word":"évertuait","word_nosc":"evertuait","lemma":"évertuer","pos":"VER"} ,
		{"word":"évertuant","word_nosc":"evertuant","lemma":"évertuer","pos":"VER"} ,
		{"word":"évertue","word_nosc":"evertue","lemma":"évertuer","pos":"VER"} ,
		{"word":"évertuent","word_nosc":"evertuent","lemma":"évertuer","pos":"VER"} ,
		{"word":"évertuer","word_nosc":"evertuer","lemma":"évertuer","pos":"VER"} ,
		{"word":"évertué","word_nosc":"evertue","lemma":"évertuer","pos":"VER"} ,
		{"word":"évida","word_nosc":"evida","lemma":"évider","pos":"VER"} ,
		{"word":"évidaient","word_nosc":"evidaient","lemma":"évider","pos":"VER"} ,
		{"word":"évide","word_nosc":"evide","lemma":"évider","pos":"VER"} ,
		{"word":"évident","word_nosc":"evident","lemma":"évider","pos":"VER"} ,
		{"word":"évider","word_nosc":"evider","lemma":"évider","pos":"VER"} ,
		{"word":"évidé","word_nosc":"evide","lemma":"évider","pos":"VER"} ,
		{"word":"évidée","word_nosc":"evidee","lemma":"évider","pos":"VER"} ,
		{"word":"évidées","word_nosc":"evidees","lemma":"évider","pos":"VER"} ,
		{"word":"évidés","word_nosc":"evides","lemma":"évider","pos":"VER"} ,
		{"word":"évince","word_nosc":"evince","lemma":"évincer","pos":"VER"} ,
		{"word":"évincer","word_nosc":"evincer","lemma":"évincer","pos":"VER"} ,
		{"word":"évincerait","word_nosc":"evincerait","lemma":"évincer","pos":"VER"} ,
		{"word":"évincez","word_nosc":"evincez","lemma":"évincer","pos":"VER"} ,
		{"word":"évincé","word_nosc":"evince","lemma":"évincer","pos":"VER"} ,
		{"word":"évincée","word_nosc":"evincee","lemma":"évincer","pos":"VER"} ,
		{"word":"évincés","word_nosc":"evinces","lemma":"évincer","pos":"VER"} ,
		{"word":"évinçai","word_nosc":"evincai","lemma":"évincer","pos":"VER"} ,
		{"word":"évinçant","word_nosc":"evincant","lemma":"évincer","pos":"VER"} ,
		{"word":"éviscère","word_nosc":"eviscere","lemma":"éviscérer","pos":"VER"} ,
		{"word":"éviscèrent","word_nosc":"eviscerent","lemma":"éviscérer","pos":"VER"} ,
		{"word":"éviscérant","word_nosc":"eviscerant","lemma":"éviscérer","pos":"VER"} ,
		{"word":"éviscérer","word_nosc":"eviscerer","lemma":"éviscérer","pos":"VER"} ,
		{"word":"éviscérée","word_nosc":"evisceree","lemma":"éviscérer","pos":"VER"} ,
		{"word":"éviscérés","word_nosc":"evisceres","lemma":"éviscérer","pos":"VER"} ,
		{"word":"évita","word_nosc":"evita","lemma":"éviter","pos":"VER"} ,
		{"word":"évitai","word_nosc":"evitai","lemma":"éviter","pos":"VER"} ,
		{"word":"évitaient","word_nosc":"evitaient","lemma":"éviter","pos":"VER"} ,
		{"word":"évitais","word_nosc":"evitais","lemma":"éviter","pos":"VER"} ,
		{"word":"évitait","word_nosc":"evitait","lemma":"éviter","pos":"VER"} ,
		{"word":"évitant","word_nosc":"evitant","lemma":"éviter","pos":"VER"} ,
		{"word":"évite","word_nosc":"evite","lemma":"éviter","pos":"VER"} ,
		{"word":"évitent","word_nosc":"evitent","lemma":"éviter","pos":"VER"} ,
		{"word":"éviter","word_nosc":"eviter","lemma":"éviter","pos":"VER"} ,
		{"word":"évitera","word_nosc":"evitera","lemma":"éviter","pos":"VER"} ,
		{"word":"éviterai","word_nosc":"eviterai","lemma":"éviter","pos":"VER"} ,
		{"word":"éviteraient","word_nosc":"eviteraient","lemma":"éviter","pos":"VER"} ,
		{"word":"éviterais","word_nosc":"eviterais","lemma":"éviter","pos":"VER"} ,
		{"word":"éviterait","word_nosc":"eviterait","lemma":"éviter","pos":"VER"} ,
		{"word":"éviteras","word_nosc":"eviteras","lemma":"éviter","pos":"VER"} ,
		{"word":"éviterez","word_nosc":"eviterez","lemma":"éviter","pos":"VER"} ,
		{"word":"éviteriez","word_nosc":"eviteriez","lemma":"éviter","pos":"VER"} ,
		{"word":"éviterons","word_nosc":"eviterons","lemma":"éviter","pos":"VER"} ,
		{"word":"éviteront","word_nosc":"eviteront","lemma":"éviter","pos":"VER"} ,
		{"word":"évites","word_nosc":"evites","lemma":"éviter","pos":"VER"} ,
		{"word":"évitez","word_nosc":"evitez","lemma":"éviter","pos":"VER"} ,
		{"word":"évitiez","word_nosc":"evitiez","lemma":"éviter","pos":"VER"} ,
		{"word":"évitions","word_nosc":"evitions","lemma":"éviter","pos":"VER"} ,
		{"word":"évitons","word_nosc":"evitons","lemma":"éviter","pos":"VER"} ,
		{"word":"évitâmes","word_nosc":"evitames","lemma":"éviter","pos":"VER"} ,
		{"word":"évitât","word_nosc":"evitat","lemma":"éviter","pos":"VER"} ,
		{"word":"évitèrent","word_nosc":"eviterent","lemma":"éviter","pos":"VER"} ,
		{"word":"évité","word_nosc":"evite","lemma":"éviter","pos":"VER"} ,
		{"word":"évitée","word_nosc":"evitee","lemma":"éviter","pos":"VER"} ,
		{"word":"évitées","word_nosc":"evitees","lemma":"éviter","pos":"VER"} ,
		{"word":"évités","word_nosc":"evites","lemma":"éviter","pos":"VER"} ,
		{"word":"évolua","word_nosc":"evolua","lemma":"évoluer","pos":"VER"} ,
		{"word":"évoluaient","word_nosc":"evoluaient","lemma":"évoluer","pos":"VER"} ,
		{"word":"évoluais","word_nosc":"evoluais","lemma":"évoluer","pos":"VER"} ,
		{"word":"évoluait","word_nosc":"evoluait","lemma":"évoluer","pos":"VER"} ,
		{"word":"évoluant","word_nosc":"evoluant","lemma":"évoluer","pos":"VER"} ,
		{"word":"évolue","word_nosc":"evolue","lemma":"évoluer","pos":"VER"} ,
		{"word":"évoluent","word_nosc":"evoluent","lemma":"évoluer","pos":"VER"} ,
		{"word":"évoluer","word_nosc":"evoluer","lemma":"évoluer","pos":"VER"} ,
		{"word":"évoluera","word_nosc":"evoluera","lemma":"évoluer","pos":"VER"} ,
		{"word":"évoluerait","word_nosc":"evoluerait","lemma":"évoluer","pos":"VER"} ,
		{"word":"évolueront","word_nosc":"evolueront","lemma":"évoluer","pos":"VER"} ,
		{"word":"évoluez","word_nosc":"evoluez","lemma":"évoluer","pos":"VER"} ,
		{"word":"évoluons","word_nosc":"evoluons","lemma":"évoluer","pos":"VER"} ,
		{"word":"évoluèrent","word_nosc":"evoluerent","lemma":"évoluer","pos":"VER"} ,
		{"word":"évolué","word_nosc":"evolue","lemma":"évoluer","pos":"VER"} ,
		{"word":"évoluée","word_nosc":"evoluee","lemma":"évoluer","pos":"VER"} ,
		{"word":"évoluées","word_nosc":"evoluees","lemma":"évoluer","pos":"VER"} ,
		{"word":"évolués","word_nosc":"evolues","lemma":"évoluer","pos":"VER"} ,
		{"word":"évoqua","word_nosc":"evoqua","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquai","word_nosc":"evoquai","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquaient","word_nosc":"evoquaient","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquais","word_nosc":"evoquais","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquait","word_nosc":"evoquait","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquant","word_nosc":"evoquant","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoque","word_nosc":"evoque","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquent","word_nosc":"evoquent","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquer","word_nosc":"evoquer","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquera","word_nosc":"evoquera","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquerai","word_nosc":"evoquerai","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoqueraient","word_nosc":"evoqueraient","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquerait","word_nosc":"evoquerait","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquerez","word_nosc":"evoquerez","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquerons","word_nosc":"evoquerons","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoqueront","word_nosc":"evoqueront","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoques","word_nosc":"evoques","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquez","word_nosc":"evoquez","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquiez","word_nosc":"evoquiez","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquions","word_nosc":"evoquions","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquons","word_nosc":"evoquons","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquâmes","word_nosc":"evoquames","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquât","word_nosc":"evoquat","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquèrent","word_nosc":"evoquerent","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoqué","word_nosc":"evoque","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquée","word_nosc":"evoquee","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoquées","word_nosc":"evoquees","lemma":"évoquer","pos":"VER"} ,
		{"word":"évoqués","word_nosc":"evoques","lemma":"évoquer","pos":"VER"} ,
		{"word":"êtes","word_nosc":"etes","lemma":"être","pos":"VER"} ,
		{"word":"être","word_nosc":"etre","lemma":"être","pos":"VER"} ,
		{"word":"ôta","word_nosc":"ota","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtai","word_nosc":"otai","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtaient","word_nosc":"otaient","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtais","word_nosc":"otais","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtait","word_nosc":"otait","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtant","word_nosc":"otant","lemma":"ôter","pos":"VER"} ,
		{"word":"ôte","word_nosc":"ote","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtent","word_nosc":"otent","lemma":"ôter","pos":"VER"} ,
		{"word":"ôter","word_nosc":"oter","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtera","word_nosc":"otera","lemma":"ôter","pos":"VER"} ,
		{"word":"ôterai","word_nosc":"oterai","lemma":"ôter","pos":"VER"} ,
		{"word":"ôterais","word_nosc":"oterais","lemma":"ôter","pos":"VER"} ,
		{"word":"ôterait","word_nosc":"oterait","lemma":"ôter","pos":"VER"} ,
		{"word":"ôteras","word_nosc":"oteras","lemma":"ôter","pos":"VER"} ,
		{"word":"ôterez","word_nosc":"oterez","lemma":"ôter","pos":"VER"} ,
		{"word":"ôterions","word_nosc":"oterions","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtes","word_nosc":"otes","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtez","word_nosc":"otez","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtiez","word_nosc":"otiez","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtons","word_nosc":"otons","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtât","word_nosc":"otat","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtèrent","word_nosc":"oterent","lemma":"ôter","pos":"VER"} ,
		{"word":"ôté","word_nosc":"ote","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtée","word_nosc":"otee","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtées","word_nosc":"otees","lemma":"ôter","pos":"VER"} ,
		{"word":"ôtés","word_nosc":"otes","lemma":"ôter","pos":"VER"} 
	]
}